import { isBjme, isFocusEnv } from '@/utils';
import {
    UserSelectionOptions,
    UserSelectionResult,
} from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import i18next from 'i18next';
import { Modals } from '@jd/focus-desktop-comps';
import logger from '@/utils/logger';
import { getEbookDeptInfo, getEbookOrgInfo } from '@/api/ebook';
import { getSearchGlobal, searchExternalContacts, searchGlobal } from '@/api/search';
import {
    getBusinessData,
    getExternalContacts,
    getGroupData,
    getRecentChatSession,
    getRecentIncludeGroup,
    getTopChatSession,
    onGetRecommendInfo,
    searchSessions,
    getGroupMembersData,
    onSearchGroupMember,
} from '@/api/chat';
import { UserModel } from '@/models/user';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { JoinMeetingModalProps } from '@jd/focus-desktop-comps/lib/es/JoinMeetingModal';
import {
    BusinessTabParam,
    RecommendParam,
    SearchGlobalParams,
} from '@jd/focus-desktop-comps/lib/es/UserSelection';
const log = logger.getLogger('utils/modals');

export function openJoinMeetingModal(options: Partial<JoinMeetingModalProps>) {
    if (isFocusEnv()) {
        //
    } else {
        const { destroy } = Modals.JoinMeeting({
            ...options,
            onCancel: () => {
                destroy();
            },
        });
    }
}

export function openUserSelectionModal(
    options: UserSelectionOptions & { propAgentForData?: any[] },
    cb: (
        data: UserSelectionResult,
        close: () => void,
        update: (newConfig: any) => void,
        cancelLoadingFn: () => void
    ) => void,
    user: UserModel | { teamUserInfo: { teamId: any }; ddAppId: string; userId: string }
) {
    const { propAgentForData } = options;
    const { destroy, update } = Modals.UserSelecton(
        options,
        (result: any) => {
            return new Promise((resolve) => {
                cb(
                    result,
                    () => {
                        resolve();
                        destroy();
                    },
                    update,
                    () => {
                        resolve();
                    }
                );
            });
        },
        {
            t: (...args: any[]) => {
                // @ts-ignore
                // 暂时区分一下专班 项目
                const param = args;
                if (!isBjme() && param[0].indexOf('specialProject') > -1) {
                    param[0] = param[0].replace('specialProject', 'project');
                }
                return i18next.t(param);
            },
            onGetAvatarURL: (url: string) => {
                return getHeadImgThumbUrl(url, 150, 150);
            },
            onGetEBookDeptInfo: async (params: {
                deptId?: string | undefined;
                displaySubDept?: boolean | undefined;
            }) => {
                let result = null;
                try {
                    result = await getEbookDeptInfo(params);
                } catch (e) {
                    // if (e.message.indexOf('10230004')) {}
                    result = {};
                }
                return result as any;
            },
            onGetEBookOrgInfo: async (params: {
                appId?: string;
                deptId?: string;
                displaySubDept?: boolean;
            }) => {
                let result = null;
                try {
                    result = await getEbookOrgInfo(params);
                } catch (e) {
                    // if (e.message.indexOf('10230004')) {}
                    result = {};
                }
                return result as any;
            },
            onSearchGlobal: async (params: SearchGlobalParams) => {
                try {
                    const result = await getSearchGlobal(params);
                    // if (params.searchType === SearchType.Contacts) {
                    //     const externalContacts = await searchExternalContacts(params);
                    //     let contactsList = externalContacts.filter(
                    //         (item: any) =>
                    //             !result.contacts.some(
                    //                 (ele: any) =>
                    //                     ele.userId === item.userId &&
                    //                     ele.app === item.app &&
                    //                     ele.teamId === item.teamId
                    //             )
                    //     );
                    //     result.contacts.unshift(...contactsList);
                    // }
                    // if (params.needSession) {
                    //     result.sessions = await searchSessions(params);
                    // }
                    log.debug('onSearchGlobal', result);
                    return result as any;
                } catch (e) {
                    return {};
                }
            },
            onGetRecentData: () => getRecentChatSession(user.teamUserInfo.teamId),
            onGetRecentIncludeGroup: async () => await getRecentIncludeGroup(),
            onGetAgentForData: propAgentForData
                ? () => Promise.resolve(propAgentForData)
                : () => getRecentChatSession(user.teamUserInfo.teamId),
            onGetTopData: () => getTopChatSession(user.teamUserInfo.teamId),
            onGetExternalContacts: () => getExternalContacts(),
            onGetGroupData: () => getGroupData(),
            onGetGroupMembersData: (groupId: string) => getGroupMembersData(groupId),
            onGetBusinessData: (param: BusinessTabParam) => getBusinessData(param, user),
            onGetRecommendInfo: (param: RecommendParam) => onGetRecommendInfo(param, user),
            onSearchGroupMember: (keyword: string) =>
                onSearchGroupMember(keyword, options.fixedUsers), // 模糊搜索群成员
        }
    );
}

// export function openUserSelectionModal(
//     options: UserSelectionOptions,
//     cb: (data: UserSelectionResult, close: () => void) => void,
//     user: UserModel
// ) {
//     if (isFocusEnv() && !isSupportEnv()) {
//         FocusSDK.userSelection(options, (data: UserSelectionResult, close: () => void) => {
//             cb(data, close);
//         });
//     } else {
//         const { destroy } = Modals.UserSelecton(
//             options,
//             (result: any) => {
//                 return new Promise((resolve) => {
//                     cb(result, () => {
//                         resolve();
//                         destroy();
//                     });
//                 });
//             },
//             {
//                 t: (...args: any[]) => {
//                     // @ts-ignore
//                     return i18next.t(...args);
//                 },
//                 onGetAvatarURL: (url: string) => {
//                     return getHeadImgThumbUrl(url, 150, 150);
//                 },
//                 onGetEBookDeptInfo: async (params: {
//                     deptId?: string | undefined;
//                     displaySubDept?: boolean | undefined;
//                 }) => {
//                     let result = null;
//                     try {
//                         result = await getEbookDeptInfo(params);
//                     } catch (e) {
//                         // if (e.message.indexOf('10230004')) {}
//                         result = {};
//                     }
//                     return result as any;
//                 },
//                 onGetEBookOrgInfo: async (params: {
//                     appId?: string;
//                     deptId?: string;
//                     displaySubDept?: boolean;
//                 }) => {
//                     let result = null;
//                     try {
//                         result = await getEbookOrgInfo(params);
//                     } catch (e) {
//                         // if (e.message.indexOf('10230004')) {}
//                         result = {};
//                     }
//                     return result as any;
//                 },
//                 onSearchGlobal: async (params: SearchGlobalParams) => {
//                     const result = await searchGlobal(params);
//                     if (params.searchType === SearchType.Contacts) {
//                         const externalContacts = await searchExternalContacts(params);
//                         result.contacts.unshift(...externalContacts);
//                     }
//                     log.debug('onSearchGlobal', result);
//                     return result as any;
//                 },
//                 onGetRecentData: () => getRecentChatSession(user.selectedTeamId),
//                 onGetTopData: () => getTopChatSession(user.selectedTeamId),
//                 onGetExternalContacts: () => getExternalContacts(),
//                 onGetGroupData: () => getGroupData(),
//             }
//         );
//     }
// }
