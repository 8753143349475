import { executePut } from '@/server/dvaHelper';

export interface ThirdAppModel {
    name: string;
    adminHomeUrl?: string;
    applicationFlag?: number;
    applicationId: string;
    applicationKey: string;
    applicationSecret?: string;
    authType: 1;
    brief?: string;
    csEmail?: string;
    csJzbAccount?: string;
    csTelephone?: string;
    deeplink?: string;
    deploymentEnvironment: number;
    description?: string;
    developer?: string;
    developmentType?: number;
    favorite?: number;
    homeUrl?: string;
    icon?: string;
    maxVersion?: string;
    minVersion?: string;
    mobileHomeUrl?: string;
    nativeIdentifier?: string;
    omCompany?: string;
    openApiType?: number;
    preview?: string;
    recommended?: number;
    resourceUrl?: string;
    trustedDomains?: string;
    version?: string;
    webviewKernel: number;
}

export interface ThirdAppInfo {
    [key: string]: ThirdAppModel;
}

export interface ThirdAppState {
    thirdAppMap: ThirdAppInfo;
}

export default {
    namespace: 'thirdapp',
    state: {
        thirdAppMap: {},
    },
    reducers: {
        setThirdAppData(
            state: ThirdAppState,
            { payload }: { payload: { appId: string; appData: ThirdAppModel } }
        ) {
            const thirdAppMap = state.thirdAppMap;
            const { appId, appData } = payload;
            thirdAppMap[appId] = appData;
            return { ...state, thirdAppMap };
        },
    },
    effects: {
        *updatehirdAppList({ payload }: any, { put }: any) {
            const { thirdAppList } = payload;
            if (!thirdAppList || !thirdAppList.length) {
                return;
            }
            for (let item of thirdAppList) {
                yield executePut(put, 'setThirdAppData', {
                    appId: item.applicationId,
                    appData: item,
                });
            }
        },
    },
};
