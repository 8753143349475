import UserCenter from '@/layout/UserCenter';
import NavTab from '@/layout/NavTab';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavItemData } from '@/layout/NavItem';
import TeamList, { TeamItemInfo } from '@/layout/TeamList';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getIconFromString } from '@/components/icon';
import { useLocation, useHistory } from 'react-router-dom';
import ExtendNavs from './ExtendNav/';
import _ from 'lodash';
import logger from '@/utils/logger';
import ChatState from '@/types/chat/State';
import { Employee, Session } from '@/types/chat';
import { connect } from 'dva';
import './index.less';
import { EbookState } from '@/models/ebook';
import chatConfig from '@/components/chat/config';
import lifecycle from 'page-lifecycle';
import notifications from '@/notifications';
import { isFocusEnv } from '@/utils';
import { MessageNotifyType } from '@/types/chat/enum';
import { isAINoticeChildSession, isAINoticeSession } from '@/components/chat/utils/session';
import { message } from 'antd';
const { REACT_APP_X_FLAG } = process.env;

function LeftNav({
    newContactsCount,
    clearSelectSession,
    allSessionList,
    sessionUnReadList,
    sessionScrollIndex,
    selectedSessionId,
    // getNewContactsCount,
    setSessionScrollIndex,
    selectedNotifyType,
}: {
    selectedSessionId: string;
    newContactsCount: number;
    clearSelectSession: Function;
    // getNewContactsCount: Function;
    allSessionList: Session[];
    sessionUnReadList: [];
    sessionScrollIndex: number;
    setSessionScrollIndex: Function;
    selectedNotifyType: MessageNotifyType;
}) {
    const location = useLocation();
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('');
    const { authInfo, appRuntimeEnv, focusSDK, onGlobalError } = useContext<GlobalContextConfig>(
        GlobalContext
    );
    const [messageTip, setMessageTip] = useState(0);
    const enableDuConfig = appRuntimeEnv.getUIConfig('enableDu');
    const enableDu = typeof enableDuConfig === 'undefined' || enableDuConfig === true;
    const onTeamItemClick = useCallback(
        (team: TeamItemInfo) => {
            console.log(team);
            if (team.emplEnabled !== 'DIS_ENABLED') {
                focusSDK.switchTeam(team.teamId);
            } else {
                message.destroy();
                message.warn('该账号已被冻结，如有疑问请联系所在单位管理员进行核查。');
            }
        },
        [focusSDK]
    );
    const navItems: NavItemData[] = [];
    let navData = appRuntimeEnv.ui.layout.nav;
    if (focusSDK.getDeviceInfo().platform === 'web') {
        navData = _.filter(
            navData,
            (item) =>
                [
                    'layout.nav.messages',
                    'layout.nav.ebook',
                    'layout.nav.calendar',
                    'layout.nav.joywork',
                    'layout.nav.joyspace',
                    'layout.nav.thirdApp',
                ].includes(item.name)
            // (item) =>
            //     ['layout.nav.messages', 'layout.nav.ebook', 'layout.nav.calendar'].includes(
            //         item.name
            //     )
        );
    }
    // const newContactsCountTip = useMemo(() => {
    //     const search: any = appRuntimeEnv.getUIConfig('ebook.search') || {};
    //     if (search.addContract) {
    //         // 只有开启添加联系人的功能才会有提示
    //         return newContactsCount <= 0
    //             ? ''
    //             : newContactsCount >= 100
    //             ? '...'
    //             : `${newContactsCount}`;
    //     }
    //     return '';
    // }, [appRuntimeEnv, newContactsCount]);

    // // 拉取新的申请数量
    // useEffect(() => {
    //     getNewContactsCount();
    // }, [getNewContactsCount]);

    const sTimer = React.useRef<any>();
    const messageCount = React.useRef<any>({
        time: Date.now(),
    });
    const lastMessageCount = React.useRef<any>({
        time: Date.now(),
    });

    const DURATION_TIME = 60 * 1000;

    // 当前总条数
    const unReadTotal = React.useRef(0);
    // 上次总条数
    const lastUnReadTotal = React.useRef(0);
    const notifyText = '您有新消息，请及时查看';

    // 发完通知1分钟后将未发的通知再发出来
    const timer = useCallback(() => {
        clearTimeout(sTimer.current);
        sTimer.current = setTimeout(() => {
            if (lifecycle.state === 'hidden') {
                // 消息数有变化且当前消息数不为0
                if (unReadTotal.current > 0 && unReadTotal.current !== lastUnReadTotal.current) {
                    let result = notifications('消息提醒', {
                        body: notifyText,
                    });
                    // 如果没发通知不更新时间
                    if (!result) {
                        return;
                    }
                    console.log(
                        `延迟到间隔1分钟，上次总条数${lastUnReadTotal.current}，当前总条数${unReadTotal.current}`
                    );
                    lastUnReadTotal.current = unReadTotal.current;
                    lastMessageCount.current = {
                        time: Date.now(),
                    };
                    timer();
                }
            }
        }, DURATION_TIME);
    }, [DURATION_TIME]);

    // 页面状态变化时改变定时器计时及进入hidden一分钟后要通知
    useEffect(() => {
        const changeHidden = (event: any) => {
            if (isFocusEnv()) {
                return;
            }
            let { newState } = event;
            // 变为失去焦点状态
            if (newState === 'hidden') {
                messageCount.current = { ...messageCount.current, ...{ time: Date.now() } };
                timer();
            }
            // 变为激活状态
            if (newState === 'active' || newState === 'passive') {
                let time = Date.now();
                messageCount.current = {
                    time,
                };
                lastMessageCount.current = {
                    time,
                };
            }
        };
        lifecycle.addEventListener('statechange', changeHidden);
        return () => {
            lifecycle.removeEventListener('statechange', changeHidden);
        };
    }, [timer]);

    useEffect(() => {
        if (allSessionList.length > 0) {
            const aiSession = allSessionList.find((item) => isAINoticeSession(item));
            const unReadCount = allSessionList.reduce((total, s) => {
                let num = total;
                if (
                    selectedSessionId !== s.sessionId &&
                    (s.settings || {}).shield !== 1 &&
                    !(
                        s.isSingle &&
                        chatConfig['chat-session-invisible-list'].includes(
                            (s.info as Employee).userId
                        )
                    )
                ) {
                    if (
                        aiSession?.settings.shield === 1 &&
                        s.isNotice &&
                        isAINoticeChildSession(s)
                    ) {
                        return num;
                    }
                    num += s.unreadCount;
                }
                return num;
            }, 0);
            unReadTotal.current = unReadCount;
            if (unReadCount > 0) {
                console.log(`当前未读总数为:${unReadCount}条`);
                if (lifecycle.state === 'hidden' && !isFocusEnv()) {
                    // 来了一条新消息
                    let { time } = messageCount.current;
                    time = Date.now();
                    messageCount.current = {
                        time, // 新消息时间
                    };
                    let { time: lastTime } = lastMessageCount.current;
                    // 超过60秒发起通知
                    if (
                        time - lastTime >= DURATION_TIME &&
                        lastUnReadTotal.current !== unReadCount
                    ) {
                        let result = notifications('消息提醒', {
                            body: notifyText,
                        });
                        if (!result) {
                            return;
                        }
                        console.log(
                            `来了一条新消息，上次总条数${lastUnReadTotal.current}，当前总条数${unReadCount}`
                        );
                        lastUnReadTotal.current = unReadCount;
                        // 记录上次通知时间
                        lastMessageCount.current = {
                            time,
                        };
                        timer();
                    }
                }
            }
            setMessageTip(unReadCount);
        }
    }, [allSessionList, selectedSessionId]); //eslint-disable-line
    navData.forEach((item: any) => {
        const { icon, name, role, ...rest } = item;
        const title = appRuntimeEnv.getI18N(name);
        const count = Array.isArray(newContactsCount) ? newContactsCount.length : newContactsCount;

        const showNewContactsCountText = count >= 100 ? '...' : count;

        switch (role) {
            case 'messages': {
                navItems.push({
                    icon: getIconFromString(icon),
                    path: '/messages',
                    navName: 'messages',
                    title,
                    // tip: '',
                    tip: messageTip <= 0 ? '' : messageTip >= 100 ? '...' : `${messageTip}`,
                });
                break;
            }
            case 'calendar': {
                navItems.push({
                    icon: getIconFromString(icon),
                    path: '/calendar',
                    navName: 'calendar',
                    title,
                });
                break;
            }
            case 'ebook': {
                navItems.push({
                    icon: getIconFromString(icon),
                    path: '/address_list',
                    navName: 'address_list',
                    title,
                    // 冬奥没有新申请数量
                    tip:
                        newContactsCount && REACT_APP_X_FLAG !== 'olympic'
                            ? newContactsCount >= 100
                                ? '...'
                                : `${newContactsCount}`
                            : '',
                });
                break;
            }
            case 'thirdApp': {
                navItems.push({
                    icon: getIconFromString(icon),
                    path: '/third_apps',
                    navName: 'third_apps',
                    title,
                });
                break;
            }
            case 'meeting': {
                navItems.push({
                    icon: getIconFromString(icon),
                    path: '/meeting',
                    navName: 'meeting',
                    title,
                });
                break;
            }
            // case 'third_email': {
            //     navItems.push({
            //         // icon: getIconFromString('iconapp_btn_pc_mail'),
            //         // title: '邮箱',
            //         icon: getIconFromString(icon),
            //         path: '/third_email',
            //         title,
            //     });
            //     break;
            // }
            default:
                navItems.push({
                    icon: getIconFromString(icon),
                    title,
                    role,
                    ...rest,
                });
        }
    });
    // navItems.push({
    //     path: '/xylink',
    //     title: '会议',
    //     icon: getIconFromString('iconzw_app_project'),
    // });
    if (process.env.NODE_ENV !== 'production') {
        navItems.push({
            icon: getIconFromString('iconzw_app_project'),
            title: '测试页',
            path: '/tests',
        });
    }
    // const setUnread = useCallback(({ count }: { count: number }) => {
    //     setMessageTip(count);
    // }, []);
    //
    // const unreadCb = useRef(_.debounce(setUnread, 300)).current;

    const doClearSelectSession = useCallback(() => {
        clearSelectSession();
    }, [clearSelectSession]);

    // useEffect(() => {
    //     EventBus.on('chat_message_unread_count', unreadCb);
    //     return () => {
    //         EventBus.off('chat_message_unread_count', unreadCb);
    //     };
    // }, []); // eslint-disable-line

    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location]);
    // let winLeftSideStyle = {};
    // // Windows 头像到边界的距离过高
    // const platform = navigator.platform;
    // if (platform.toLowerCase().indexOf('win') >= 0) {
    //     winLeftSideStyle = {
    //         marginTop: 19,
    //     };
    // }

    // 双击未读消息定位
    const doDoubleClick = useCallback(
        (item) => {
            if (item.path !== `/messages` || selectedNotifyType !== MessageNotifyType.ALL) {
                return;
            }
            const sessionUnReadCount = sessionUnReadList.length;
            if (sessionScrollIndex < sessionUnReadCount - 1) {
                setSessionScrollIndex({ sessionScrollIndex: sessionScrollIndex + 1 });
            } else {
                setSessionScrollIndex({ sessionScrollIndex: 0 });
            }
        },
        [sessionScrollIndex, sessionUnReadList, setSessionScrollIndex, selectedNotifyType]
    );

    return (
        <div className="leftSide draggable">
            <UserCenter />
            <NavTab
                activeKey={activeTab}
                navItems={navItems}
                onItemClick={(item) => {
                    // 清空当前会话数据
                    doClearSelectSession();
                    if (item.role === 'openNewWindow') {
                        try {
                            const url = new URL(item.link);
                            window.open(url.toString());
                        } catch (e) {
                            onGlobalError(`Invalid url: ${item.link}`);
                        }
                    } else {
                        history.push(item.path);
                    }
                    // setActiveTab(path);
                }}
                onDoubleClick={doDoubleClick}
            />

            <div className="no-draggable no-draggable-color">
                {enableDu && (
                    <ExtendNavs
                        active={activeTab.indexOf('supervise') !== -1}
                        clickHandle={(p) => {
                            history.push(p);
                        }}
                    />
                )}
                <TeamList
                    selectedTeamId={appRuntimeEnv.selectedTeamId}
                    teams={authInfo.teamUserInfoList}
                    onItemClick={onTeamItemClick}
                />
            </div>
        </div>
    );
}

function mapStateToProps({ ebook, chat }: { chat: ChatState; ebook: EbookState }) {
    const { newContactsCount } = ebook;
    const {
        allSessionList,
        selectedSession,
        sessionUnReadList,
        sessionScrollIndex,
        selectedNotifyType,
    } = chat;
    return {
        newContactsCount,
        allSessionList,
        sessionUnReadList,
        sessionScrollIndex,
        selectedNotifyType,
        selectedSessionId: selectedSession.sessionId || '',
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        clearSelectSession: () => {
            dispatch({ type: 'chat/clearSelectSession', payload: {} });
        },
        setSessionScrollIndex: (data: any) => {
            dispatch({ type: 'chat/setSessionScrollIndex', payload: data });
        },
        // getNewContactsCount(payload: {}) {
        //     return dispatch({ type: 'ebook/getNewContactsCount', payload });
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
