import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchGlobalParams, SearchGlobalRes, SearchType } from '@/services/SearchService';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import Tabs, { TabData } from '@/components/Modals/SearchGlobalModal/Tabs';
import { ListItemData } from '@/components/ListItem';
import logger from '@/utils/logger';
import { getJoySpacePageLink } from '@jd/focus-desktop-comps/lib/es/utils';
import bus from '@/utils/bus';
import './index.less';
import { connect } from 'dva';
import dayjs, { Dayjs } from 'dayjs';
import { AddressNavKey } from '@/types/common';
import { EbookState } from '@/models/ebook';
import { getDeptCrumbList } from '@/services/EBookService';
import config, { ConfigEnum } from '@/config/config';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';

interface GlobalSearchType {
    keyWord: string;
    activeKey?: SearchType;
    isChange?: boolean;
}
const log = logger.getLogger('SearchGlobalModal');
export default connect(
    function (ebook: EbookState) {
        return {};
    },
    function (dispatch: any) {
        return {
            changeScheduleInfoDraw: (payload: {
                visible: boolean;
                scheduleId: string;
                selectedScheduleTime: Dayjs;
                from: number;
                openTime: Dayjs;
            }) =>
                dispatch({
                    type: 'calendar/changeScheduleInfoDraw',
                    payload,
                }),
            updateAddressNavKey(data: { addressNavKey: AddressNavKey }) {
                dispatch({ type: 'ebook/updateAddressNavKey', payload: data });
            },
            updateTreeDataInfo(data: any) {
                dispatch({ type: 'ebook/updateTreeDataInfo', payload: data });
            },
            setSelectedEbookId(data: any) {
                dispatch({ type: 'ebook/setSelectedEbookId', payload: data });
            },
            setDeptCrumbList(data: any) {
                dispatch({ type: 'ebook/setDeptCrumbList', payload: data });
            },
        };
    }
)(
    (
        props: GlobalSearchType & {
            changeScheduleInfoDraw: (payload: {
                visible: boolean;
                scheduleId: string;
                selectedScheduleTime: Dayjs;
                from: number;
                openTime: Dayjs;
            }) => void;
            updateAddressNavKey: Function;
            setSelectedEbookId: Function;
            updateTreeDataInfo: Function;
            setDeptCrumbList: Function;
        }
    ) => {
        const {
            activeKey = SearchType.All,
            keyWord = '',
            isChange,
            changeScheduleInfoDraw,
            updateAddressNavKey,
            setSelectedEbookId,
            updateTreeDataInfo,
            setDeptCrumbList,
        } = props;
        const { authInfo: authData, focusSDK, service, appRuntimeEnv } = useContext<
            GlobalContextConfig
        >(GlobalContext);
        const history = useHistory();
        const [_activeKey, setActiveKey] = useState<SearchType>(activeKey);
        useMemo(() => {
            setActiveKey(activeKey);
        }, [activeKey]);
        // start 特殊处理
        const onChangeKey = useCallback((path) => {
            if (path === '/third_apps') {
                setActiveKey(SearchType.App);
            }
        }, []);
        useEffect(() => {
            bus.on('onHideGlobalSearch', onChangeKey);
            return () => {
                bus.off('onHideGlobalSearch', onChangeKey);
            };
        }, [onChangeKey]);
        // end
        const tabData: any = [
            { key: SearchType.All },
            { key: SearchType.Contacts },
            { key: SearchType.Department },
            { key: SearchType.Group },
            { key: SearchType.JoySpace },
            { key: SearchType.Task },
            { key: SearchType.Schedule },
            { key: SearchType.App },
            { key: SearchType.MessageHistory },
        ];
        const tabDataFilterDept = tabData.filter((item: any) => item.key !== SearchType.Department);

        const [tab, setTab] = useState<TabData[]>(
            config[ConfigEnum.EXTERNAL_CONTACTS_SEARCH_DEPARTMENT] ? tabData : tabDataFilterDept
        );
        const onItemClick = useCallback(
            async (type: SearchType, dataParam: ListItemData) => {
                try {
                    const { origin: data } = dataParam;
                    bus.emit('onHideGlobalSearch', false);
                    switch (type) {
                        case SearchType.Schedule:
                            console.log('😄触发搜索日程item点击事件', data);
                            changeScheduleInfoDraw({
                                visible: true,
                                scheduleId: data.scheduleId,
                                selectedScheduleTime: dayjs(data.beginTime),
                                from: ScheduleDetailFrom.Search,
                                openTime: dayjs(),
                            });
                            break;
                        case SearchType.JoySpace:
                            window.open(getJoySpacePageLink(appRuntimeEnv.joyspaceHost, data));
                            break;
                        case SearchType.App:
                            // 有个BUG，当打开的页面和跳转页面一样是，不触发 useLocation
                            history.push({
                                pathname: `/third_apps`,
                                // state: { applicationInfo: data.ext },
                            });
                            // 临时解决方案
                            setTimeout(() => {
                                bus.emit('openThirdApp', data.ext, data);
                            }, 700);
                            break;
                        case SearchType.Contacts:
                            log.debug('handleSearchType contacts ==>', type, data);
                            const { userId, teamId, app } = data; // eslint-disable-line
                            // TODO: 单聊密聊会话跳转 参数添加isSecret
                            history.push(`/messages/s/${userId}?app=${app}&tid=${teamId}&top=true`);
                            break;
                        case SearchType.Group:
                            logger.debug('handleSearchType group ==>', type, data);
                            history.push(`/messages/g/${data.groupId}?top=true`);
                            break;
                        case SearchType.Task:
                            break;
                        case SearchType.Department:
                            logger.debug('handleSearchType Department ==>', dataParam);
                            // eslint-disable-next-line no-case-declarations
                            const DeptList = await getDeptCrumbList(dataParam);
                            console.log(DeptList, dataParam?.id, 'DeptList');
                            setDeptCrumbList(DeptList);
                            setSelectedEbookId(dataParam?.id);
                            updateAddressNavKey({ addressNavKey: AddressNavKey.ORG });
                            history.push(`/address_list`);
                            break;
                        default: {
                            return;
                        }
                    }
                } catch (e) {
                    log.error('item click error', e);
                    return;
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [changeScheduleInfoDraw, appRuntimeEnv.joyspaceHost, history]
        );
        const onSearchGlobal = useCallback(
            (params: SearchGlobalParams): Promise<SearchGlobalRes> => {
                return service.searchService.searchGlobal(params);
            },
            [service.searchService]
        );
        return (
            <div className="global-search-content">
                <Tabs
                    authData={authData}
                    keyword={keyWord.trim()} // 应 沈判 需求，全局搜索的input可以输入空格
                    onSearchGlobal={onSearchGlobal}
                    config={tab}
                    activeKey={_activeKey}
                    onItemClick={onItemClick}
                    onActiveKeyChange={(key) => {
                        setActiveKey(key);
                    }}
                    isChange={isChange}
                />
            </div>
        );
    }
);
