import React, { useEffect, useState, useCallback } from 'react';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'dvajs';
import { useTranslation } from 'react-i18next';
import './index.less';
import { createTeamAndEmployee, CreateTeamAndEmployee } from '@/api/joinMe';
import { UserModel } from '@/models/user';
import { FocusSDK, isFocusEnv, IFocusSDK } from '@/utils';
import Cookies from 'js-cookie';
import Teams from '@/components/TeamManagement';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { AuthDataRes, TeamUserInfo } from '@/types/LoginPage';
import { changeEgovUrl } from '@/utils/tools';

const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
const createSuccessDefault = `${REACT_APP_OSS_BASE_URL}/statics/images/join-success.png`;
const createSuccessMap: any = {
    mebj: `${REACT_APP_OSS_BASE_URL}/statics/images/join-success-mebj.png`,
    jdcloud: createSuccessDefault,
};
enum CreateTeamStatus {
    Init, // 团队名称输入页面
    Finish, // 加入完成页面
}

function CreateTeam({
    userData,
    getWebUserData,
    goBack,
    appRuntimeEnv,
    focusSDK,
}: {
    userData: AuthDataRes;
    getWebUserData: (data: { teamUserInfo: UserModel }) => void;
    goBack?: () => void;
    appRuntimeEnv: AppRuntimeEnv;
    focusSDK: IFocusSDK;
}) {
    const [teamName, setTeamName] = useState<string | undefined>('');
    const [createStatus, setCreateStatus] = useState(CreateTeamStatus.Init);
    const [newTeamInfo, setNewTeamInfo] = useState<CreateTeamAndEmployee | null>(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('joinMe');
    function onTeamNameChange(v: string | undefined) {
        if (v && v.length > 30) {
            return;
        }
        setTeamName(v);
    }
    let teamCanCreated = true;
    function create() {
        if (!teamName || loading || !teamCanCreated) {
            return;
        }
        const { teamUserInfo: team } = userData;
        setLoading(true);
        teamCanCreated = false;
        createTeamAndEmployee({
            mobile: team.mobile,
            userTeamName: team.realName,
            teamName,
            countryCode: '86',
        })
            .then((res) => {
                if (res) {
                    setCreateStatus(CreateTeamStatus.Finish);
                    setNewTeamInfo(res as CreateTeamAndEmployee);
                }
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
                teamCanCreated = true;
            });
        // setCreateStatus(CreateTeamStatus.Finish);
        // setNewTeamInfo({
        //     account: 'houqingxiang1',
        //     ddAppId: 'me',
        //     headImg: '',
        //     mobile: '13683071593',
        //     realName: '侯庆祥',
        //     teamFullName: 'dadadadsac后',
        //     teamId: '2daFVAYKwiBm1VV4gWcy6',
        //     teamShortName: 'dadadadsac后',
        // });
    }

    const enterApplication = (teamInfo: CreateTeamAndEmployee | TeamUserInfo | null) => {
        if (!teamInfo) {
            // onGlobalError(new Error('Can not get team data when login.'));
            return;
        }
        if (!userData) {
            // onGlobalError(new Error('Can not get user data when login.'));
            return;
        }
        focusSDK.login(
            {
                // ...userData,
                ...userData,
                selectedTeamId: teamInfo.teamId,
                teamUserInfo: teamInfo,
                teamUserInfoList: [...userData.teamUserInfoList, teamInfo],
            },
            teamInfo.teamId,
            () => {
                focusSDK.switchTeam(teamInfo.teamId);
            }
        );
    };

    // function enterApplication(teamInfo: CreateTeamAndEmployee | null) {
    //     if (isFocusEnv() && teamInfo) {
    //         //
    //         FocusSDK.setSelectedTeamId(teamInfo);
    //     } else {
    //         if (teamInfo) {
    //             const { team } = userData;
    //             Cookies.set('focus-team-id', teamInfo?.teamId, {
    //                 domain: '.jdcloud.com',
    //             });

    //             getWebUserData({
    //                 teamUserInfo: {
    //                     ...userData,
    //                     teamUserInfo: teamInfo,
    //                     selectedTeamId: teamInfo.teamId,
    //                     teamUserInfoList: [teamInfo],
    //                 },
    //             });
    //             location.reload();
    //         }
    //     }
    // }
    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );
    const teamNameInput = (
        <>
            <div>
                <h4>{t('inputTeamName')}</h4>
                <p
                    style={{
                        fontSize: 12,
                        lineHeight: 1,

                        marginBottom: 52,
                    }}
                >
                    {t(`teamDesc${appRuntimeEnv.runtimeConfig.key === 'mebj' ? 'mebj' : ''}`)}
                </p>
            </div>
            <Input
                autoFocus
                allowClear
                placeholder={t('inputTeamNamePlaceholder')}
                value={teamName}
                onKeyDown={(e: any) => {
                    const { keyCode } = e;
                    if (keyCode === 13) {
                        // checkMobileCb();
                    }
                }}
                onChange={(e: any) => {
                    onTeamNameChange(e.target.value);
                }}
            />
            <div
                onKeyDown={() => {}}
                onClick={() => {
                    create();
                }}
                className="btn big normal"
                style={{
                    marginTop: 162,
                    height: 42,
                    opacity: teamName?.length ? 1 : 0.5,
                    cursor: teamName?.length ? 'pointer' : 'not-allowed',
                }}
            >
                {loading && <div>{loadingComp}</div>}
                <div
                    style={{
                        fontSize: 14,
                        color: 'rgb(255, 255, 255)',
                    }}
                >
                    {t('nextStep')}
                </div>
            </div>
        </>
    );
    const createFinish = (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <img
                style={{
                    width: 121,
                    height: 111,
                    marginBottom: 37,
                }}
                src={changeEgovUrl(
                    createSuccessMap[appRuntimeEnv.runtimeConfig.key] || createSuccessDefault
                )}
                alt=""
            />
            <p
                style={{
                    fontSize: 16,
                    lineHeight: 1,
                    margin: 0,
                }}
            >
                {t('createSuccessAdmin')}
            </p>
            <p>{appRuntimeEnv.meMainEntry}</p>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div
                    className="btn box small"
                    style={{
                        height: 42,
                        width: 223,
                        margin: '57px 14px 0 0',
                        cursor: 'pointer',
                    }}
                    onClick={() => enterApplication(newTeamInfo)}
                >
                    {t('enterApplication')}
                </div>
                <div
                    className="btn normal small"
                    style={{
                        height: 42,
                        width: 223,
                        marginTop: 57,
                        cursor: 'pointer',
                    }}
                    onClick={() =>
                        Teams.TeamInvite({
                            teamId: newTeamInfo?.teamId || 'IgLMaQ0qDYr9IIpQ2ewYH',
                            userData,
                            appRuntimeEnv,
                        })
                    }
                >
                    {t('invite')}
                </div>
            </div>
        </div>
    );
    return (
        <div>
            {createStatus === CreateTeamStatus.Init ? teamNameInput : null}
            {createStatus === CreateTeamStatus.Finish ? createFinish : null}
        </div>
    );
}
// function mapStateToProps({ user }: any) {
//     return {
//         userData: user.userData,
//     };
// }
// function mapDispatchToProps(dispatch: any) {
//     return {
//         getWebUserData(data: { teamUserInfo: UserModel }) {
//             dispatch({ type: 'user/getWebUserData', payload: { userData: data } });
//         },
//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(ChatLeft));

export default CreateTeam;
