import { Button, Input, List, message, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'dvajs';
import { useTranslation } from 'react-i18next';
import { EbookState } from '@/models/ebook';
import { getPageExternalContactsApprInfoReq } from '@/api/ebook';
import { ApprExternalContacts, PageExternalContactsApprInfoReqParam } from '@/types/chat/State';
import './index.less';
import externalContactsSuccess from '@/assets/img/externalContacts/success.png';
import externalContactsFail from '@/assets/img/externalContacts/fail.png';
import externalContactsInvalid from '@/assets/img/externalContacts/invalid.png';
import { updateApprExternalContacts } from '@/api/chat';
import { ExternalContactsStatus } from '@/types/chat/enum';
import * as util from '@/components/Joyspace/util';
import { setExternalContactsTipsTool } from '@/utils/chat/index';
import dayjs from 'dayjs';
import { changeEgovUrl } from '@/utils/tools';

export interface contactsListInfo {
    deptId: string; // 部门ID
    unitName: string; // 单位名称
    employeeType: string; // 人员类型1-正式人员 2-外部联系人
    employeeTypeName: string; // 人员类型描述
    appId: string;
    apprId: number;
    apprOpinion: string;
    apprStatus: number;
    deptName: string;
    gmtCreate: number;
    gmtModify: number;
    inviterUserId: string;
    mobile: string;
    name: string;
    status: number;
    teamId: string;
    titleName: string;
}

const limit = 10;

function ApplyExternalContactsList({
    openExternalContactsModal,
    setOpenExternalContactsModal,
}: {
    openExternalContactsModal: boolean;
    setOpenExternalContactsModal: Function;
}) {
    const [t] = useTranslation('chat');
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [externalContactsList, setExternalContactsList] = useState<contactsListInfo[]>([]);
    const [refusalCause, setRefusalCause] = useState('');

    useEffect(() => {
        async function getExternalContactsList() {
            const param: PageExternalContactsApprInfoReqParam = {
                appId: '', // 应用ID
                teamId: '', // 团队ID
                inviterUserId: '', // 邀请人用户ID
                page: page, // 页数
                limit: limit, // 每页数量
            };
            const [result, error] = await getPageExternalContactsApprInfoReq(param);

            if (error) {
                message.error(error);
                return;
            }

            const {
                apprInfos: { total, list },
            } = result;

            setTotal(total);

            const newList: any = externalContactsList.concat(list).map((item: any) => {
                return {
                    ...item,
                    refuse: false,
                    loading: false,
                };
            });

            setExternalContactsList(newList);
        }

        if (openExternalContactsModal) {
            getExternalContactsList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openExternalContactsModal, page]);

    const handleAdd = useCallback(
        async (item: any, apprStatus: number) => {
            item.loading = true;
            const param: ApprExternalContacts = {
                apprId: item.apprId, // 审核ID不能为空
                apprStatus: apprStatus, // 审核状态不能为空 1-审核成功 2-审核失败
                apprOpinion: refusalCause, // 审核意见最大支持100个长度
            };

            const [result, error] = await updateApprExternalContacts(param);

            item.loading = false;
            handleClouseRefuse(item, false);

            if (error) {
                message.error(error);
                return;
            }

            const newExternalContactsList: any = externalContactsList.map((n: any) => {
                if (n.apprId === item.apprId) {
                    n.apprStatus = result;
                }
                return n;
            });
            setExternalContactsList(newExternalContactsList);
            setExternalContactsTipsTool(result, t);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [externalContactsList, refusalCause]
    );

    function handleClose() {
        setOpenExternalContactsModal(false);
        setExternalContactsList([]);
        setPage(1);
        setTotal(0);
        setRefusalCause('');
    }
    function handleClouseRefuse(item: any, type: boolean) {
        const newList: any = externalContactsList.map((n: any) => {
            if (item.apprId === n.apprId) {
                item.refuse = type;
            }
            return n;
        });
        setExternalContactsList(newList);
        setRefusalCause('');
    }

    return (
        <Modal
            title={t('applicationList')}
            centered={true}
            visible={openExternalContactsModal}
            onCancel={() => {
                handleClose();
            }}
            footer={null}
            width="720px"
            bodyStyle={{ height: 580, overflow: 'auto' }}
        >
            <div className="external-contacts">
                {externalContactsList.length ? (
                    externalContactsList.map((item: any, key) => {
                        return (
                            <div className="external-contacts-list-item " key={key}>
                                {/* 蒙层 */}
                                {(item.apprStatus === ExternalContactsStatus.INVALID ||
                                    item.apprStatus === ExternalContactsStatus.ALREADY_APPR ||
                                    item.apprStatus === ExternalContactsStatus.ALREADY_EXSIT) && (
                                    <div className="external-contacts-list-item-Mantle"> </div>
                                )}
                                {/* <div className="external-contacts-item-title">
                                    {util.time.format(item.gmtCreate)}
                                </div> */}
                                <div className="external-contacts-item-detail">
                                    <div className="external-contacts-content">
                                        <div className="content-title">
                                            <span>{item.name}</span> 申请加入
                                            {process.env.REACT_APP_NAME},请审核
                                        </div>
                                        <div>
                                            <span className="label">{t('application_type')}：</span>
                                            <span className="container">
                                                {item.employeeTypeName}
                                            </span>
                                        </div>
                                        {item.employeeType === 2 && (
                                            <>
                                                <div>
                                                    <span className="label">{t('unit')}：</span>
                                                    <span className="container">
                                                        {item.deptName}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="label">{t('post')}：</span>
                                                    <span className="container">
                                                        {item.titleName}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                        {item.employeeType === 1 && (
                                            <>
                                                <div>
                                                    <span className="label">{t('unit')}：</span>
                                                    <span className="container">
                                                        {item.unitName}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="label">{t('dept')}：</span>
                                                    <span className="container">
                                                        {item.deptName}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                        {/* <div>
                                            <span className="label">{t('name')}：</span>
                                            <span className="container">{item.name}</span>
                                        </div> */}
                                        <div>
                                            <span className="label">{t('time')}：</span>
                                            <span className="container">
                                                {util.chineseTime.format(item.gmtCreate)}
                                                {/* {dayjs(item.gmtCreate).format('M')}
                                                {t('month')}
                                                {dayjs(item.gmtCreate).format('DD')}
                                                {t('day')}
                                                {`${t(`week_${dayjs(item.gmtCreate).day()}`)}`}
                                                {dayjs(item.gmtCreate).format('HH:mm')} */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="external-contacts-status">
                                        {item.apprStatus === ExternalContactsStatus.SUCCESS && (
                                            <img
                                                src={changeEgovUrl(externalContactsSuccess)}
                                                alt=""
                                            />
                                        )}
                                        {item.apprStatus === ExternalContactsStatus.FAIL && (
                                            <img src={changeEgovUrl(externalContactsFail)} alt="" />
                                        )}
                                        {item.apprStatus === ExternalContactsStatus.INVALID && (
                                            <img
                                                src={changeEgovUrl(externalContactsInvalid)}
                                                alt=""
                                            />
                                        )}
                                    </div>
                                </div>
                                {item.refuse && (
                                    <div className="refusal-cause">
                                        <Input.TextArea
                                            placeholder={t('请输入驳回原因')}
                                            rows={4}
                                            defaultValue={refusalCause}
                                            onChange={(e) => {
                                                setRefusalCause(e.target.value);
                                            }}
                                            maxLength={100}
                                        />
                                        <p className="refusal-cause-length">
                                            <span>{refusalCause.length || 0}</span>
                                            /100
                                        </p>
                                        <p className="refusal-cause-btns">
                                            <Button
                                                className="refusal-cause-btns-cancel"
                                                onClick={() => {
                                                    handleClouseRefuse(item, false);
                                                }}
                                            >
                                                {t('cancelText')}
                                            </Button>
                                            <Button
                                                disabled={!refusalCause}
                                                className="refusal-cause-btns-ok"
                                                type="primary"
                                                loading={item.loading}
                                                onClick={() => {
                                                    handleAdd(item, 2);
                                                }}
                                            >
                                                {t('confirm')}
                                            </Button>
                                        </p>
                                    </div>
                                )}
                                {item.apprStatus === ExternalContactsStatus.WAIT && !item.refuse && (
                                    <div className="external-contacts-item-fun">
                                        <Button
                                            className="refuse"
                                            onClick={() => {
                                                handleClouseRefuse(item, true);
                                            }}
                                        >
                                            {t('not approved')}
                                        </Button>
                                        <Button
                                            className="agreed"
                                            type="primary"
                                            loading={item.loading}
                                            onClick={() => {
                                                handleAdd(item, 1);
                                            }}
                                        >
                                            {t('approved')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <p style={{ margin: '20px auto' }}>暂无数据</p>
                )}

                {page * limit < total ? (
                    <p
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                        onClick={() => {
                            setPage(page + 1);
                        }}
                    >
                        {t('Load more')}
                    </p>
                ) : (
                    ''
                )}
            </div>
        </Modal>
    );
}

function mapStateToProps({ ebook }: { ebook: EbookState }) {
    return { openExternalContactsModal: ebook.openExternalContactsModal };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setOpenExternalContactsModal(payload: boolean) {
            dispatch({ type: 'ebook/setOpenExternalContactsModal', payload });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyExternalContactsList);
