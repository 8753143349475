import React, { ReactNode, ReactElement, SFC, useState, useEffect, useCallback } from 'react';
import { Drawer, message, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { connect } from 'dvajs';
import {
    CalendarType,
    getScheduleInfoByScheduleId,
    getScheduleInfo,
    Scope,
    getScheduleList,
    Schedule,
} from '@/api/calendar';

import './index.less';
import CalendarDetail, { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';
import { useTranslation } from 'react-i18next';
import CalendarImService from '@/components/Calendars/services/CalendarImService';

/**
 *  用来渲染日程item
 * @export
 * @returns
 */
interface IProps {
    visible: boolean;
    scheduleId: string;
    calendarId: string;
    selectedScheduleTime: Dayjs;
    beginTime: Dayjs;
    endTime: Dayjs;
    from: number;
    setScheduleInfo: any;
    scheduleInfo: any;
    changeScheduleInfoDrawOther: (payload: { visible: boolean; scheduleId: string }) => void;
    allSessionList: any[];
    launchGroupSession: any;
}

const ScheduleDrawOther: SFC<IProps> = ({
    selectedScheduleTime,
    setScheduleInfo,
    scheduleInfo,
    changeScheduleInfoDrawOther,
    visible,
    scheduleId,
    beginTime,
    endTime,
    from,
    allSessionList,
    launchGroupSession,
}) => {
    const [content, setContent] = useState<any>(null);
    const [imDrawerVisible, setImDrawerVisible] = useState(false);
    const [t] = useTranslation('calendar');
    const calendarImService = CalendarImService.getInstance();
    calendarImService.setSessionList(allSessionList);
    calendarImService.setLaunchGroupSession(launchGroupSession);
    const [drawerStyle, setDrawerStyle]: [any, any] = useState({});

    useEffect(() => {
        if (imDrawerVisible && visible) {
            setDrawerStyle({ transform: 'translateX(-443px)' });
        } else {
            setDrawerStyle({});
            // 如果在日程视图-日程详情页的聊天窗口点击另一个日常的立即沟通关闭上一个日程的立即沟通
            if (!visible) {
                setImDrawerVisible(false);
            }
        }
    }, [imDrawerVisible, visible]);
    useEffect(() => {
        if (!visible || !scheduleId) {
            return;
        }

        getScheduleInfo({
            scheduleId: scheduleId,
        })
            .then((result: Schedule) => {
                if (!result) {
                    changeScheduleInfoDrawOther({
                        visible: false,
                        scheduleId: '',
                    });
                    message.warning(t('schedule is not found'));
                    return;
                }
                // if (result.beginTime) {
                //     result.scheduleBeginTime = dayjs(result.beginTime);
                // }
                // if (result.endTime) {
                //     result.scheduleEndTime = dayjs(result.endTime);
                // }
                // 日程卡片的时候以deeplink的开始和结束时间为准；
                if (beginTime) {
                    result.scheduleBeginTime = dayjs(beginTime);
                } else if (result.beginTime) {
                    result.scheduleBeginTime = dayjs(result.beginTime);
                }
                if (endTime) {
                    result.scheduleEndTime = dayjs(endTime);
                } else if (result.endTime) {
                    result.scheduleEndTime = dayjs(result.endTime);
                }
                setContent(result);
            })
            .catch((err) => {
                if (err === '未查询到对应的日程') {
                    message.error(t('schedule has already been deleted'));
                } else if (err.message) {
                    message.error(err.message?.toString() || t('schedule is not found'));
                }
                changeScheduleInfoDrawOther({
                    visible: false,
                    scheduleId: '',
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        visible,
        scheduleId,
        changeScheduleInfoDrawOther,
        scheduleInfo,
        selectedScheduleTime,
        beginTime,
        endTime,
        t,
    ]);
    const refreshSchedule = useCallback(() => {
        if (!visible || !scheduleId) {
            return;
        }

        getScheduleInfo({
            scheduleId: scheduleId,
        })
            .then((result: Schedule) => {
                if (result.beginTime) {
                    result.scheduleBeginTime = dayjs(result.beginTime);
                }
                if (result.endTime) {
                    result.scheduleEndTime = dayjs(result.endTime);
                }
                // 日程卡片的时候以deeplink的开始和结束时间为准；
                // if (beginTime) {
                //     result.scheduleBeginTime = dayjs(beginTime);
                // }
                // if (endTime) {
                //     result.scheduleEndTime = dayjs(endTime);
                // }
                setContent(result);
            })
            .catch((err) => {
                if (err === '未查询到对应的日程') {
                    message.error(t('schedule has already been deleted'));
                } else if (err.message) {
                    message.error(err.message?.toString() || t('schedule is not found'));
                }
                changeScheduleInfoDrawOther({
                    visible: false,
                    scheduleId: '',
                });
            });
    }, [visible, scheduleId, changeScheduleInfoDrawOther, t]);

    const onClose = useCallback(
        (event) => {
            changeScheduleInfoDrawOther({
                visible: false,
                scheduleId: '',
            });
            setContent(null);
            event?.nativeEvent?.stopImmediatePropagation();
        },
        [changeScheduleInfoDrawOther]
    );

    return (
        <div>
            {visible && (
                <Drawer
                    visible={visible}
                    className="calendar-schedule-draw"
                    closable={false}
                    onClose={onClose}
                    destroyOnClose={true}
                    style={drawerStyle}
                    // maskStyle={{
                    //     // opacity: 0,
                    //     animation: 'none',
                    // }}
                >
                    {content ? (
                        <CalendarDetail
                            scheduleId={scheduleId}
                            content={content}
                            schedule={content}
                            from={from}
                            closeHandle={onClose}
                            selectedScheduleTime={selectedScheduleTime}
                            refreshSchedule={refreshSchedule}
                            imDrawerVisible={imDrawerVisible}
                            setImDrawerVisible={setImDrawerVisible}
                            setScheduleInfo={setScheduleInfo}
                        />
                    ) : (
                        <Spin />
                    )}
                </Drawer>
            )}
        </div>
    );
};
function mapStateToProps({ calendar, chat }: any) {
    const { scheduleInfoDrawOther } = calendar;
    return {
        visible: scheduleInfoDrawOther.visible,
        scheduleId: scheduleInfoDrawOther.scheduleId,
        calendarId: scheduleInfoDrawOther.calendarId,
        selectedScheduleTime: scheduleInfoDrawOther.selectedScheduleTime,
        beginTime: scheduleInfoDrawOther.beginTime,
        endTime: scheduleInfoDrawOther.endTime,
        from: scheduleInfoDrawOther.from,
        scheduleInfo: calendar.scheduleInfo,
        allSessionList: chat.allSessionList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setScheduleInfo(data: any) {
            return dispatch({ type: 'calendar/setScheduleInfo', payload: data });
        },
        changeScheduleInfoDrawOther: (payload: { visible: boolean; scheduleId: string }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDrawOther',
                payload,
            }),
        launchGroupSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchGroupSession', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDrawOther);
