/* eslint-disable max-params */
import React, { ReactNode } from 'react';
import Revoke from '@/components/chat/message/Revoke';
import Text from '@/components/chat/message/Text';
import HttpCard from '@/components/chat/message/HttpCard';
import Image from '@/components/chat/message/Image';
import UploadFile from '@/components/chat/message/File/Upload';
import DownloadFile from '@/baseComponents/Chat/message/File/Download';
import Video from '@/components/chat/message/Video';
import Voice from '@/components/chat/message/Voice';
import Share from '@/components/chat/message/share';
import NoticeCard from '@/components/chat/message/NoticeCard';
import SysMessage from '@/components/chat/message/Sys';
import JoyspaceFile from '../message-components/JoyspaceFile';
import UnSupport from '../UnSupport';
import { ChatMessageType, Session } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import { checkMessageSupport, isSameClient } from '../../utils/message';
import { isFocusEnv } from '@/utils';
import GroupNotificationCard from '../GroupNotificationCard';
import SolitaireCard from '../SolitaireCard';

function getMessageItemProps(
    message: any,
    sendType: MessageSendType,
    currentSession: Session
): any {
    return {
        message: message,
        sendType: sendType,
        sessionType: currentSession.sessionType,
        // onReady: messageReady,
    };
}

/**
 * 消息已准备好发送
 * @param param
 */
// eslint-disable-next-line complexity
export function getChatMessageNode(
    sendType: MessageSendType,
    message: any,
    currentSession: Session,
    // Text 聊天记录搜索参数
    curClickItem?: any,
    searchKey?: string
): ReactNode {
    const isSupport = checkMessageSupport(message);
    if (!isSupport) {
        return <UnSupport message={message} />;
    }
    const props = getMessageItemProps(message, sendType, currentSession);
    if (message.revoke === 1) {
        // 该消息已撤回
        return <Revoke {...props} />;
    }
    switch (message.type) {
        case ChatMessageType.TEXT:
            if ((message as any).nativeId === 'share_link') {
                return <HttpCard {...props} />;
            }
            return <Text {...props} curSearchMessgae={curClickItem} searchKey={searchKey} />;
        case ChatMessageType.IMAGE:
            return <Image {...props} />;
        case ChatMessageType.FILE: {
            if (
                props.sendType === MessageSendType.OWNER ||
                props.sendType === MessageSendType.SELF
            ) {
                if (
                    !isSameClient(message) ||
                    (isFocusEnv() && !message.downLocalPath && !message.upLocalPath)
                ) {
                    return <DownloadFile {...props} />;
                } else {
                    return <UploadFile {...props} />;
                }
            } else {
                return <DownloadFile {...props} />;
            }
        }
        case ChatMessageType.VIDEO:
            return <Video {...props} />;
        case ChatMessageType.VOICE:
            return <Voice {...props} />;
        case ChatMessageType.TEMPLATE2:
            return <Share {...props} />;
        case ChatMessageType.JOYSPACEFILE:
            return <JoyspaceFile {...props} />;
        // 组通知
        case ChatMessageType.GROUPNOTICE:
            return <GroupNotificationCard selectedSession={currentSession} {...props} />;
        // 组接龙
        case ChatMessageType.SOLITAIRE:
            return <SolitaireCard selectedSession={currentSession} {...props} />;
        // @ts-ignore
        case 'unified_notice_message':
            return <NoticeCard {...props} />;
        case ChatMessageType.SYS: {
            if (message.flag === 100) {
                return <NoticeCard {...props} />;
            }
            return <SysMessage {...props} />;
        }
        default:
            return null;
    }
}
