import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.less';
import { MessageSendType } from '@/types/chat/enum';
import IconFont from '@/components/icon';
import { isReadMessage } from '@/utils/chat/message';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { ChatMessage, ChatMessageType } from '@/types/chat';
import { getTime } from '@/utils/date';
import ImService from '@/server/ImService';
import { FocusSDK } from '@/utils';
export interface CountTimeInfo {
    time: number;
    unit: string;
    remainSec: number;
}

interface CountDownProps {
    message: any;
    sendType: MessageSendType;
    refreshMessageBox: any;
}

interface DvaDispatchProps {
    deleteSessionMessage: (data: { message: ChatMessage }) => void;
}

type ICountDownProps = Readonly<CountDownProps & DvaDispatchProps & ChatState>;
function CountDown(props: ICountDownProps) {
    const {
        sendType,
        message,
        currentEmployee,
        selectedSession,
        refreshMessageBox,
        deleteSessionMessage,
    } = props;
    const [countTimeInfo, setCountTimeInfo] = useState<CountTimeInfo | null>(null);
    // console.log('countDown', message);
    // TODO: 这里需要处理
    const isStartBurn = useMemo(() => {
        if (sendType !== MessageSendType.SELF && sendType === MessageSendType.OWNER) {
            const lastMsg = selectedSession.lastMsg || {};
            return isReadMessage(message, currentEmployee, lastMsg);
        } else {
            // console.log('倒计时状态', message.readTimeOfMessage || message.readTime);
            return message.readTimeOfMessage || message.readTime;
        }
    }, [sendType, message, currentEmployee, selectedSession.lastMsg]);

    const deg = useMemo(() => {
        // TODO:角度计算为：已过去时间/总时间 * 360deg remainSec为剩余时间
        const expire = message.expire;
        return ((expire - (countTimeInfo?.remainSec || 0)) / expire) * 360;
    }, [message, countTimeInfo]);

    const deleteMessage = useCallback(async () => {
        // TODO: 删除这条消息
        const imService = ImService.getInstance();
        const result = await imService.deleteMessage(message);
        if (result) {
            // 删除 dva 中的 数据
            if (message.type === ChatMessageType.FILE || message.type === ChatMessageType.VIDEO) {
                // console.log('ddd', message);
                const path = message.downLocalPath || '';
                if (path) {
                    FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                        if (exists) {
                            FocusSDK.deleteLocalFile(path);
                        } else {
                            console.log('不存在');
                        }
                    });
                }
            }
            deleteSessionMessage({ message });
            // console.log('删除成功');
        } else {
            // console.log('删除失败');
        }
        refreshMessageBox?.off(message.mid);
    }, [message, refreshMessageBox, deleteSessionMessage]);

    useEffect(() => {
        const expire = message.expire;
        if (refreshMessageBox) {
            if (expire && isStartBurn) {
                // console.log('88888');
                refreshMessageBox?.on(message.mid, async () => {
                    const tempReadTime = message.readTimeOfMessage || message.readTime || 0;
                    const diffTime = (new Date().getTime() - tempReadTime) / 1000;
                    if (diffTime > expire) {
                        console.log('早已结束');
                        deleteMessage();
                        return;
                    }
                    const countDownTime = expire - diffTime;
                    const data = getTime(countDownTime);
                    // console.log('data', data);
                    // 只判断data.time === 0 也行吧
                    if (data.unit === '秒' && data.time <= 0) {
                        // console.log('倒计时结束');
                        deleteMessage();
                        return;
                    }
                    setCountTimeInfo(data);
                });
            }
        }
    }, [refreshMessageBox, message, isStartBurn, deleteMessage]);

    return (
        <div>
            {isStartBurn && countTimeInfo ? (
                <div
                    className={`count-down-container ${
                        sendType === MessageSendType.OTHER ? '' : 'owner'
                    }`}
                >
                    {countTimeInfo.unit === '秒' ? (
                        <div className="sec">{countTimeInfo.time}</div>
                    ) : (
                        <>
                            <div className="time-img">
                                <IconFont type="iconicon_app_fire_red" />
                            </div>
                            <div
                                className="time-mask"
                                style={{
                                    backgroundImage: `conic-gradient(rgba(255, 255, 255, 0.7) ${deg}deg, rgba(245, 34, 45, 0.24) ${deg}deg)`,
                                }}
                            />
                            <div className="time-content">
                                {countTimeInfo.time}
                                {countTimeInfo.unit}
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div
                    className={`time-img gray-fire ${
                        sendType === MessageSendType.OTHER ? '' : 'owner'
                    }`}
                >
                    <IconFont type="iconicon_app_fire_red" />
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { currentEmployee, selectedSession } = chat;
    return {
        currentEmployee,
        selectedSession,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async deleteSessionMessage(data: { message: ChatMessage }) {
            await dispatch({ type: 'chat/deleteSessionMessage', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CountDown);
