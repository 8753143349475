import {
    SuperviseType,
    EsendType,
    SuperviseUserInfo,
    EsuperviseSourceType,
    ICreateSuperviseData,
} from '@/types/supervise';
import { FocusSDK } from '@/utils';
export interface IsuperviceState {
    detailDrawerVisible: boolean;
    globalSuperviseId: string;
    globalSuperviseType: SuperviseType | undefined;
    createModalVisible: boolean;
    createSuperviseData: any;
    createData: any;
}
export default {
    namespace: 'supervise',
    state: {
        detailDrawerVisible: false,
        globalSuperviseId: '',
        globalSuperviseType: undefined,
        createModalVisible: false,
        createData: null,
    },
    reducers: {
        removeReceiveUser(state: IsuperviceState, { payload }: { payload: any }) {
            // console.log(payload);
            if (state.createData && state.createData.userList) {
                state.createData.userList = payload || [];
            }
            // console.log(state, 'statestate');
            return state;
        },
        setDetailDrawerVisible(
            state: IsuperviceState,
            {
                payload,
            }: {
                payload: {
                    visible: boolean;
                    superviseId: string;
                    globalSuperviseType?: SuperviseType;
                };
            }
        ) {
            const { visible, superviseId, globalSuperviseType } = payload;
            return {
                ...state,
                detailDrawerVisible: visible, // 控制是否显示
                globalSuperviseId: superviseId, // 需要显示的督办id
                globalSuperviseType, // 是按接受到的类型展示 还是 发送的类型展示
            };
        },
        setGlobalSuperviseId(
            state: IsuperviceState,
            { payload }: { payload: { superviseId: string } }
        ) {
            const { superviseId } = payload;
            return { ...state, globalSuperviseId: superviseId };
        },
        setCreateModalVisible(
            state: IsuperviceState,
            {
                payload,
            }: {
                payload: {
                    visible: boolean;
                    createData?: ICreateSuperviseData;
                    selectedSession: any;
                };
            }
        ) {
            const { visible, createData, selectedSession } = payload;
            return visible
                ? { ...state, createModalVisible: visible, createData, selectedSession }
                : { ...state, createModalVisible: visible, createData: null };
        },
        setCreateData(state: IsuperviceState, { payload }: { payload: any }) {
            const { data } = payload;
            return { ...state, createData: payload };
        },
    },
    subscriptions: {
        'session-event'({ dispatch }: any, onError: (reason?: any) => void) {
            FocusSDK.on('openCreateRemind', (data: any) => {
                // console.log('openCreateSchedule ==>', data);
                dispatch({ type: 'setCreateModalVisible', payload: data });
            });
        },
    },
};
