/**
 * work 个人页面 时间块
 * 今天、七天内、稍后、逾期、临时等
 * 用于展示每个时间块的的任务列表
 * @author zhangpengcheng15
 */
import React, { useState, useMemo, useEffect } from 'react';
import './index.less';
import { Droppable } from 'react-beautiful-dnd';
import { ITimeBlock } from '@/models/work';
import { useTranslation } from 'react-i18next';
import { IcBtnPack, IcBtnUnfold } from '@/assets/svg';
import TaskItem from '../TaskItem';
import { TimeClassifyEnum, TaskModel } from '@/types/work';
import { generateTimeRange } from '../../utils';
import { Dayjs } from 'dayjs';
import IconFont from '@/components/icon';
import localCache from '@/utils/localCache';
import WorkEvent from '../../WorkEvent';
const TIMEBLOCK_HEAD_EXPAND_PREFIX = 'timeblock.head.expand.prefix';
interface ITimeBlockProps {
    data: ITimeBlock;
    userData: any;
    getTaskList: (opts: {
        endTimeFrom?: string;
        endTimeTo?: string;
        timeBlockId?: string;
        offset: number;
        limit: number;
        timeClassify?: number;
        projectId: string;
        taskClassify?: number;
        priorityTypes?: number[];
    }) => void;
    // updatePersonalTaskItem: (timeBlockId: string, task: TaskModel) => void;
    changePersonalTaskDraw: (taskId: string) => void;
    // delPersonalTaskInTimeBlock: (timeBlockId: string, taskId: string) => void;
    isDropDisabled: boolean;
    projectId: string;
    isReadOnly?: boolean;
    changeSelectedTaskItem?: (checkedTaskItem: TaskModel) => void;
    priorityTypes?: number[];
    taskClassify?: number;
}

function TimeBlock({
    data,
    userData,
    projectId,
    getTaskList,
    isDropDisabled,
    // updatePersonalTaskItem,
    changePersonalTaskDraw,
    changeSelectedTaskItem,
    isReadOnly,
    taskClassify,
    priorityTypes,
}: // delPersonalTaskInTimeBlock,
ITimeBlockProps) {
    const { title, timeClassify, list, color, id, total } = data;
    useEffect(() => {
        if (timeClassify === TimeClassifyEnum.Temporary) {
            return;
        }
        const result = generateTimeRange(timeClassify);

        getTaskList({
            ...result,
            offset: 0,
            limit: 10,
            timeBlockId: id,
            projectId,
            taskClassify,
            priorityTypes,
        });
    }, [getTaskList, id, projectId, timeClassify, taskClassify, priorityTypes]);

    const {
        updatePersonalTaskItem,
        delPersonalTaskInTimeBlock,
        addPersonalTemporyTask,
    } = useMemo(() => {
        return {
            updatePersonalTaskItem: (timeBlockId: string, task: Partial<TaskModel>) => {
                WorkEvent.emit('updatePersonalTaskItem', { timeBlockId: '', task });
                // timeClassify 1已逾期 2今天 3未来7天 4未安排 5已完成
                WorkEvent.emit('reloadTimeBlock', {
                    timeClassifys: [1, 2, 3, 4, 5],
                    projectId: task.projectId,
                });
            },
            delPersonalTaskInTimeBlock: (timeBlockId: string, taskId: string) =>
                WorkEvent.emit('delPersonalTaskInTimeBlock', { timeBlockId: '', taskId }),
            addPersonalTemporyTask: (task: TaskModel) =>
                WorkEvent.emit('addPersonalTemporyTask', { task }),
        };
    }, []);

    const length = total || list.length;
    const { t } = useTranslation('work');
    const [expand, setExpand] = useState(
        localCache.getItem(TIMEBLOCK_HEAD_EXPAND_PREFIX + timeClassify) === null
            ? true
            : localCache.getItem(TIMEBLOCK_HEAD_EXPAND_PREFIX + timeClassify) === 'true'
    );
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        const flag = localCache.getItem(TIMEBLOCK_HEAD_EXPAND_PREFIX + timeClassify);
        if (flag && !isReadOnly) {
            setExpand(flag === 'true');
        }
    }, [timeClassify, isReadOnly]);

    useEffect(() => {
        localCache.setItem(TIMEBLOCK_HEAD_EXPAND_PREFIX + timeClassify, expand.toString());
    }, [expand, timeClassify]);

    useEffect(() => {
        if (list.length < total) {
            setHasMore(true);
        } else {
            setHasMore(false);
        }
    }, [list.length, total]);

    /**
     * 不足10条，则进行补全
     */
    // useEffect(() => {
    //     if (!hasMore) {
    //         return;
    //     }
    //     if (timeClassify === TimeClassifyEnum.Temporary) {
    //         return;
    //     }
    //     if (list.length >= 10) {
    //         return;
    //     }
    //     const result = generateTimeRange(timeClassify);
    //     getTaskList({ ...result, offset: list.length, limit: 10 - list.length, timeBlockId: id });
    // }, [getTaskList, hasMore, id, list, timeClassify]);

    const getTitle = useMemo(() => {
        return (
            <div className={`block-title-container `}>
                <div
                    className={`prefix ${expand ? 'prefix-circle' : 'prefix-rect'} ${
                        timeClassify === TimeClassifyEnum.Past && expand ? 'prefix-loading' : ''
                    }`}
                    style={{ backgroundColor: color }}
                />
                <div className="title">{t(title)}</div>
                <div className="total">{length}</div>
                <div
                    className="action-expand"
                    onClick={() => {
                        setExpand(!expand);
                    }}
                >
                    {expand ? <IcBtnUnfold /> : <IcBtnPack />}
                </div>
            </div>
        );
    }, [color, expand, length, t, timeClassify, title]);

    function getList(clear = false) {
        const result = generateTimeRange(timeClassify);
        let offset = list.length;
        if (clear) {
            offset = 0;
        }
        getTaskList({
            ...result,
            offset,
            limit: 10,
            timeBlockId: id,
            projectId,
            taskClassify,
            priorityTypes,
        });
    }
    function getMoreButton() {
        if (!hasMore) {
            return null;
        }
        return (
            expand && (
                <div className="block-footer" onClick={() => getList()}>
                    {t('Load More')}
                    <IconFont type="iconbtn_top_up" />
                </div>
            )
        );
    }

    // 临时和逾期，如果没有就不显示
    if (
        [TimeClassifyEnum.Temporary, TimeClassifyEnum.Past].includes(timeClassify) &&
        list.length === 0
    ) {
        return null;
    }
    return (
        <div
            className={`work-personal-timeblock ${
                expand ? '' : 'work-personal-timeblock-unexpand'
            }`}
            style={{
                height:
                    timeClassify === TimeClassifyEnum.Temporary // 临时区域没有收起和加载更多操作
                        ? (list.length + 1) * 42
                        : expand
                        ? (list.length + 2 + Number(hasMore) * 1.5) * 42
                        : 36,
            }}
        >
            {timeClassify !== 0 && getTitle}
            <Droppable
                droppableId={id}
                isDropDisabled={isDropDisabled || timeClassify === TimeClassifyEnum.Finished}
            >
                {(provided: any) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ minHeight: 60 }}
                    >
                        <div
                            className="block-content-container"
                            style={{ display: expand ? 'block' : 'none' }}
                        >
                            {list.map((task, index) => (
                                <TaskItem
                                    key={task.taskId}
                                    task={task}
                                    userData={userData}
                                    timeBlockId={id}
                                    sort={index}
                                    labelColor={color}
                                    projectId={projectId}
                                    isReadOnly={isReadOnly}
                                    updatePersonalTaskItem={updatePersonalTaskItem}
                                    changePersonalTaskDraw={changePersonalTaskDraw}
                                    delPersonalTaskInTimeBlock={delPersonalTaskInTimeBlock}
                                    changeSelectedTaskItem={changeSelectedTaskItem}
                                    isDropDisabled={
                                        isDropDisabled || timeClassify === TimeClassifyEnum.Finished
                                    }
                                />
                            ))}
                        </div>

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {getMoreButton()}
        </div>
    );
}

export default TimeBlock;
