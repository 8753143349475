/**
 * 视频消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { ReactNode } from 'react';
import { MessageSendType } from '@/types/chat/enum';
import { VideoChatMessage } from '@/types/chat';
import './index.less';
import {
    dealImageWidthHeight,
    getFileUrl,
    getImgSrc,
    getMessageFileName,
    getFileFilter,
    checkDownloadFilePathValid,
    downloadMessageFile,
    pushAutoDownload,
} from '@/utils/chat/index';
import IconFont from '@/components/icon';
import { getVideoModal } from '../../utils/commonMessageModal';
import { detectOS, FocusSDK, isFocusEnv } from '@/utils';
import { message as toast, Spin, Space } from 'antd';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import ImService from '@/server/ImService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { changeEgovUrl } from '@/utils/tools';
import { SaveOption } from '@/utils/DownloadFileQueue';
import { LoadingOutlined } from '@ant-design/icons';
import errImg from '@/assets/img/empty/err-img.png';
import { checkImgExists } from '@/components/chat/utils/message';
import TreeUsers from '@/components/AddressBook/right/TreeUsers';

interface VideoProps {
    isMergeForward?: boolean;
    message: VideoChatMessage;
    sendType: MessageSendType;
    MessageStatus?: ReactNode;
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type IVideoProps = Readonly<VideoProps & WithTranslation & ChatState>;

interface IState {
    width: number;
    height: number;
    imgSrc: string;
    progress: number;
    viewModal: boolean;
    videoSrc: string;
    isClick: Boolean;
    isDragging: Boolean;
    path: string;
    isShow: boolean;
    isLoading: boolean;
    isVideoImg: boolean;
}

// 打开消息窗口时触发
class Video extends React.Component<IVideoProps, IState> {
    imageRef: React.RefObject<HTMLImageElement> = React.createRef();

    constructor(props: IVideoProps) {
        super(props);
        const [width, height] = dealImageWidthHeight(
            [props.message.thumbWidth, props.message.thumbHeight],
            false
        );
        const imgSrc = getImgSrc(props.message.thumbnail);
        checkImgExists(imgSrc)
            .then((res) => {
                this.setState({
                    isVideoImg: true,
                });
            })
            .catch(() => {
                this.setState({
                    isVideoImg: false,
                });
            });
        // 处理视频地址
        const videoSrc = this.props.message?.downLocalPath
            ? this.props.message.downLocalPath
            : getFileUrl(this.props.message.url);
        this.state = {
            width: width as number,
            height: height as number,
            imgSrc: imgSrc,
            progress: 0,
            viewModal: false,
            videoSrc: videoSrc,
            isClick: true,
            isDragging: false,
            path: '',
            isShow: true,
            isLoading: false,
            isVideoImg: true,
        };
    }

    public componentDidMount() {
        const { message, currentEmployee, selectedSession, sendType } = this.props;
        const downLocalPath = message.downLocalPath;
        // console.log('downLocalPath', downLocalPath);
        if (isFocusEnv() && downLocalPath) {
            FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                if (exists) {
                    this.setState({ path: downLocalPath });
                } else {
                    this.setState({ path: '' });
                }
            });
        }
        if (
            selectedSession?.isSecret &&
            message?.expire &&
            !message.readTimeOfMessage &&
            sendType === MessageSendType.OTHER
        ) {
            this.setState({ isShow: false });
        }
        if (!selectedSession?.isSecret) {
            pushAutoDownload(message, currentEmployee, this.doDownloadMessageFile);
        }
    }

    handleSetReadTime = async () => {
        // TODO: 单聊密聊消息开启倒计时
        const { message, updateSessionMessageMid, updateSessionMessagesTimestamp } = this.props;
        // console.log('设置视频读取时间', this.state.isShow, message.readTimeOfMessage);
        const readTimeOfMessage = new Date().getTime();
        const imService = ImService.getInstance();
        const result = await imService.updateReadTime(message.sessionId, message.mid, {
            ...message,
            readTimeOfMessage,
        });
        if (result) {
            await updateSessionMessageMid({
                sessionId: message.sessionId,
                sendResult: {
                    body: { ...message, readTimeOfMessage },
                },
            });
            await updateSessionMessagesTimestamp({
                timestamp: new Date().getTime(),
            });
        }
    };

    downloadVideo = () => {
        this.setState({
            isLoading: true,
        });
        const { message } = this.props;
        console.log('1-playVideo');
        this.doDownloadMessageFile({
            viewFile: false,
            saveAs: false,
            auto: true,
            message,
        }).then((res: any) => {
            !this.state.isShow && this.handleSetReadTime();
            this.setState({ isShow: true });
            // console.log('res:', res);
            const { filename, saveAs } = res;
            if (filename && saveAs) {
                console.log('download-cb-true');
                const downLocalPath = `${saveAs}/${filename}`;
                getVideoModal(Object.assign(message, { downLocalPath }));
            } else {
                console.log('download-cb-false');
                getVideoModal(message);
            }
            this.setState({
                isLoading: false,
            });
        });
    };

    isExistDownLocalVideoPath = () => {
        const { message } = this.props;
        const downLocalPath = message.downLocalPath || '';

        return new Promise((resolve, reject) => {
            FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                if (exists) {
                    this.setState({ path: downLocalPath });
                    resolve(true);
                } else {
                    this.setState({ path: '' });
                    resolve(false);
                }
            });
        });
    };

    playVideo = async () => {
        const { isClick, isShow } = this.state;
        const { message } = this.props;
        const downLocalPath = message.downLocalPath || '';

        if (isClick) {
            console.log('视频被点击-playVideo:', this.props.message);
            this.setState({ isClick: false });
            if (isFocusEnv()) {
                if (!downLocalPath) {
                    this.downloadVideo();
                } else {
                    const isExistDownLocalVideoPath = await this.isExistDownLocalVideoPath();
                    if (isExistDownLocalVideoPath) {
                        getVideoModal(message);
                    } else {
                        this.downloadVideo();
                    }
                }
            } else {
                getVideoModal(message);
            }

            setTimeout(() => {
                this.setState({ isClick: true });
            }, 2000);
        }
    };

    getDownloadOptions = ({ viewFile, saveAs, message, auto }: SaveOption) => {
        const { t, updateSessionMessageMid } = this.props;
        const saveName = getMessageFileName(message);
        return {
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                // console.log('finish path', path, 'message id', message.mid);
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(
                    (message as VideoChatMessage).sessionId,
                    (message as VideoChatMessage).mid,
                    {
                        downLocalPath: path,
                    }
                );
                if (result) {
                    updateSessionMessageMid({
                        sessionId: (message as VideoChatMessage).sessionId,
                        sendResult: { body: { ...message, downLocalPath: path } },
                    });
                    this.setState({
                        path: path,
                        isDragging: false,
                    });
                    // toast.success('下载完成');
                } else {
                    if (!auto) {
                        toast.error(t('failed'));
                    }
                    this.setState({ isDragging: false });
                }
            },
        };
    };

    doDownloadMessageFile = ({
        viewFile,
        saveAs,
        message,
        auto = false,
    }: SaveOption): Promise<void> => {
        const options = this.getDownloadOptions({ viewFile, saveAs, message, auto });
        return downloadMessageFile({ viewFile, saveAs, message, auto }, options);
    };

    onDragStart = (e: any) => {
        if (!isFocusEnv()) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        const { connectState, message, t } = this.props;
        if (connectState && connectState.state === ConnectState.READY) {
            const { path, isDragging } = this.state;
            // console.log('new message', message, '哈哈哈', path);
            if (isDragging) {
                toast.warning(t('file-downloading-drag-save'));
                return;
            }
            if (!path) {
                this.setState({ isDragging: true });
                toast.warning(t('file-downloading-drag-save'));
                this.doDownloadMessageFile({ viewFile: false, saveAs: false, message }).then();
            } else {
                FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.startDrag(path);
                    } else {
                        if (isDragging) {
                            toast.warning(t('file-downloading-drag-save'));
                            return;
                        }
                        this.setState({ isDragging: true });
                        toast.warning(t('file-downloading-drag-save'));
                        this.doDownloadMessageFile({
                            viewFile: false,
                            saveAs: false,
                            message,
                        }).then();
                        // toast.warning(t('find-file-failed-and-upload'));
                    }
                });
            }
        } else {
            toast.warning(t('drag-after-the-network-is-normal'));
            return;
        }
    };
    public render() {
        const { width, height, imgSrc, isShow, isLoading, isVideoImg } = this.state;
        const { isMergeForward, selectedSession } = this.props;
        const isDrag =
            FocusSDK.getDeviceInfo().platform !== 'web' &&
            !isMergeForward &&
            !selectedSession?.isSecret;
        return (
            <div className={`content video ${!isShow ? 'filter-blur' : ''}`}>
                {!isShow && (
                    <div className="video-message-mask">
                        <IconFont
                            className="download-icon"
                            type="iconapp_btn_play_page"
                            onClick={this.playVideo}
                        />
                    </div>
                )}
                <div className="pre-view-video" style={{ width, height }}>
                    {isVideoImg === true ? (
                        <img
                            ref={this.imageRef}
                            alt=""
                            src={changeEgovUrl(imgSrc)}
                            width={width}
                            height={height}
                            draggable={isDrag}
                            onDragStart={this.onDragStart}
                        />
                    ) : (
                        <div className="err_video_img">
                            <img
                                ref={this.imageRef}
                                alt=""
                                src={changeEgovUrl(errImg)}
                                width={60}
                                height={54}
                                draggable={isDrag}
                                onDragStart={this.onDragStart}
                            />
                            <span>视频加载失败</span>
                        </div>
                    )}

                    {isLoading ? (
                        <div className="loading-layout">
                            <Space>
                                <Spin
                                    indicator={
                                        <LoadingOutlined
                                            style={{
                                                fontSize: 30,
                                                color: (window as any).styleSass.primaryColor,
                                            }}
                                            spin
                                        />
                                    }
                                />
                            </Space>
                        </div>
                    ) : (
                        <IconFont
                            className="download-icon"
                            type="iconapp_btn_play_page"
                            onClick={this.playVideo}
                        />
                    )}
                </div>
            </div>
        );
    }
}
function mapStateToProps({ chat }: any) {
    const { connectState, currentEmployee, selectedSession } = chat as ChatState;
    return { connectState, currentEmployee, selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            return dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
    };
}

// export default Video;
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(Video));
