import { TaskModel, TimeClassifyEnum, TaskPermissionEnum } from '@/types/work';
import React, { useEffect, useCallback, useMemo, useState, useReducer } from 'react';
import './index.less';
import { connect } from 'dvajs';
import {
    DragDropContext,
    Droppable,
    DropResult,
    ResponderProvided,
    DraggableLocation,
} from 'react-beautiful-dnd';
import TimeBlock from '../BaseComponents/TimeBlock';
import {
    decoratePersonalTask,
    generateTimeRange,
    generateEndTimeByTimeClassify,
    findFrontAndAfter,
    generateDefaultTimeFormatByEndLogic,
    findPersonalTaskInTimeBlock,
    getTimeClassifyByTime,
    findSelfInExecutors,
} from '../utils';
import {
    taskSortUpdate,
    updateTaskProcess,
    getPersonalList,
    createPersonalTaskApi,
} from '@/api/work';
import { message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import WorkHandle, { reorder, move } from './WorkHandle';
import { useTranslation } from 'react-i18next';
import TaskListViewHeader from './TaskListViewHeader';
import WorkEvent from '../WorkEvent';

export interface ITimeBlock {
    id: string;
    title: string;
    timeClassify: number;
    color: string;
    list: TaskModel[];
    total: number;
    lastTaskId: string;
}

/**
 * 从personalTimeBlocks里更新任务
 * @param param0
 */
function updateTaskFromTimeBlocks({
    timeBlocks,
    task,
    timeBlockId,
}: {
    timeBlocks: ITimeBlock[];
    task: TaskModel;
    timeBlockId: string;
}) {
    const index0 = timeBlocks.findIndex((t) => {
        if (timeBlockId) {
            return t.id === timeBlockId;
        }
        const index = t.list.findIndex((l) => l.taskId === task.taskId);
        return index > -1;
    });
    if (index0 === -1) {
        return timeBlocks;
    }
    const timeBlock = { ...timeBlocks[index0] };

    const { list } = timeBlock;
    const index = list.findIndex((l) => l.taskId === task.taskId);
    if (index === -1) {
        return timeBlocks;
    }
    timeBlock.list = [...list];
    timeBlock.list.splice(index, 1, { ...task });
    const newTimeBlocks = [...timeBlocks];
    newTimeBlocks.splice(index0, 1, timeBlock);
    return newTimeBlocks;
}

function deleteTaskFromTimeBlocks({
    timeBlocks,
    timeBlockId,
    taskId,
}: {
    timeBlocks: ITimeBlock[];
    timeBlockId?: string;
    taskId: string;
}) {
    const index0 = timeBlocks.findIndex((t) => {
        if (timeBlockId) {
            return t.id === timeBlockId;
        }
        const index = t.list.findIndex((l) => l.taskId === taskId);
        return index > -1;
    });
    if (index0 === -1) {
        return timeBlocks;
    }
    const timeBlock = { ...timeBlocks[index0] };
    const list = [...timeBlock.list];
    const index = list.findIndex((l) => l.taskId === taskId);
    if (index === -1) {
        return timeBlocks;
    }
    timeBlock.list = list;
    timeBlock.list.splice(index, 1);
    timeBlock.total = timeBlock.total - 1;
    const newtimeBlocks = [...timeBlocks];
    newtimeBlocks.splice(index0, 1, timeBlock);
    return newtimeBlocks;
}

function addTemporyTaskFromTimeBlocks({
    timeBlocks,
    task,
}: {
    timeBlocks: ITimeBlock[];
    task: TaskModel;
}) {
    const timeBlock = { ...timeBlocks[0] };
    timeBlock.list = [task, ...timeBlock.list];
    const newTimeBlocks = [...timeBlocks];
    newTimeBlocks.splice(0, 1, timeBlock);
    return newTimeBlocks;
}

function addTaskListFromTimeBlocks({
    timeBlockId,
    timeBlocks,
    tasks,
    offset,
    total,
    limit,
    lastTaskId,
    timeClassify,
}: {
    timeBlocks: ITimeBlock[];
    timeBlockId: string;
    tasks: TaskModel[];
    offset: number;
    total: number;
    lastTaskId: string;
    limit: number;
    timeClassify: number;
}) {
    const timeBlock = timeBlocks[Number(timeBlockId)];
    const newList = offset === 0 ? [] : [...timeBlock.list];

    // 2. 更新对应的列表, 去重一下
    decoratePersonalTask(tasks).forEach((t) => {
        const index = newList.findIndex((n) => n.taskId === t.taskId);
        if (index === -1) {
            newList.push(t);
        } else {
            newList.splice(index, 1, t);
        }
    });
    timeBlock.list = newList;
    timeBlock.total = total;
    timeBlock.lastTaskId = lastTaskId;
    timeBlocks[Number(timeBlockId)] = { ...timeBlock };

    const tempBlock = timeBlocks[0];

    // 如果加载回来的数据少于请求数据，默认已经全部加载完成，更新total为本地总数，
    // 防止由于自动补齐10条的逻辑导致死循环加载数据，一般不会触发此操作，但是后台分页偶尔会出问题，导致拉取数量小于limit
    if (tasks.length < limit) {
        timeBlock.total = timeBlock.list.length;
    }

    // 如果有7天后时间块的更新，清空临时区域
    if (timeClassify === TimeClassifyEnum.LaterOrUntime) {
        tempBlock.list = [];
        tempBlock.total = 0;
    }
    return [...timeBlocks];
}

const functionObj: any = {
    /**
     * 更新任务，传入更新后的任务对象
     * 如果可以传入任务所在时间块，否则会遍历所有时间块查询
     */
    updatePersonalTaskItem: function (
        state: State,
        { payload }: { payload: { task: TaskModel; timeBlockId: string } }
    ) {
        const { timeBlocks } = state;

        const { task, timeBlockId } = payload;
        const newTimeBlocks = updateTaskFromTimeBlocks({ timeBlocks, task, timeBlockId });
        return {
            ...state,
            timeBlocks: newTimeBlocks,
        };
    },
    /**
     * 删除任务
     */
    delPersonalTaskInTimeBlock: function (
        state: State,
        { payload }: { payload: { taskId: string; timeBlockId?: string } }
    ) {
        const { taskId, timeBlockId } = payload;
        const { timeBlocks } = state;

        const newTimeBlocks = deleteTaskFromTimeBlocks({
            timeBlockId,
            taskId,
            timeBlocks: timeBlocks,
        });

        return {
            ...state,
            timeBlocks: newTimeBlocks,
        };
    },
    /**
     * 新建临时任务
     */
    addPersonalTemporyTask: function (state: State, { payload }: { payload: { task: TaskModel } }) {
        const { task } = payload;
        const { timeBlocks } = state;

        const newTimeBlocks = addTemporyTaskFromTimeBlocks({
            timeBlocks: timeBlocks,
            task,
        });
        return {
            ...state,
            timeBlocks: newTimeBlocks,
        };
    },
    /**
     * 对列表进行重新排序，传回处理后的新时间块数组
     */
    changPersonalTaskOrder: function (
        state: State,
        { payload }: { payload: { timeBlocks: ITimeBlock[] } }
    ) {
        return { ...state, timeBlocks: payload.timeBlocks };
    },
    /**
     * 添加列表到对应时间块
     */
    setPersonalTaskList: function (
        state: State,
        {
            payload,
        }: {
            payload: {
                timeBlockId: string;
                timeClassify: number;
                tasks: TaskModel[];
                total: number;
                lastTaskId: string;
                offset: number;
                limit: number;
            };
        }
    ) {
        const { timeBlocks } = state;
        const newTimeBlocks = addTaskListFromTimeBlocks({
            timeBlocks,
            ...payload,
        });

        return {
            ...state,
            timeBlocks: newTimeBlocks,
        };
    },
};

interface State {
    timeBlocks: ITimeBlock[];
}

function reducer(state: State, action: { type: string; payload?: any }) {
    try {
        const { type, payload } = action;
        return functionObj[type](state, { payload });
    } catch (error) {
        return state;
    }
}

function TaskListView({
    userData,
    changePersonalTaskDraw,
    projectId,
    soureceName,
    disableHeader,
    disableTitle,
    disableCreate,
    disableDrag,
    isReadOnly = false,
    changeSelectedTaskItem,
    taskClassify = 1,
    priorityTypes,
}: {
    userData: any;
    changePersonalTaskDraw: (taskId: string) => void;
    projectId: string;
    soureceName: string;
    disableHeader?: boolean;
    disableTitle?: boolean;
    disableCreate?: boolean;
    disableDrag?: boolean;
    isReadOnly?: boolean;
    changeSelectedTaskItem?: (checkedTastItem: TaskModel) => void;
    taskClassify?: number;
    priorityTypes?: number[];
}) {
    const initialState = {
        timeBlocks: [
            {
                id: '0',
                title: 'temporaryBlock',
                timeClassify: 0,
                list: [],
                total: 0, // 临时位置不需要总数
                color: '#EEEEEE',
            },
            {
                id: '1',
                title: 'overdue',
                timeClassify: 1,
                list: [],
                total: 0,
                color: '#F6642D',
            },
            {
                id: '2',
                title: 'today',
                color: '#8CCC48',
                timeClassify: 2,
                list: [],
                total: 0,
            },
            {
                id: '3',
                title: 'in week',
                color: '#379AFF',
                timeClassify: 3,
                list: [],
                total: 0,
            },
            {
                id: '4',
                title: 'later or untime',
                color: '#4751C8',
                timeClassify: 4,
                list: [],
                total: 0,
            },
            {
                id: '5',
                title: 'finished',
                color: '#8899A4',
                timeClassify: 5,
                list: [],
                total: 0,
            },
        ],
    };
    const [state, dispatch]: [State, any] = useReducer(reducer, initialState);
    const {
        changPersonalTaskOrder,
        updatePersonalTaskItem,
        delPersonalTaskInTimeBlock,
        addPersonalTemporyTask,
    } = useMemo(() => {
        return {
            changPersonalTaskOrder: (timeBlocks: ITimeBlock[]) =>
                dispatch({ type: 'changPersonalTaskOrder', payload: { timeBlocks } }),
            updatePersonalTaskItem: (timeBlockId: string, task: Partial<TaskModel>) => {
                dispatch({ type: 'updatePersonalTaskItem', payload: { timeBlockId, task } });
            },
            delPersonalTaskInTimeBlock: (timeBlockId: string, taskId: string) =>
                dispatch({ type: 'delPersonalTaskInTimeBlock', payload: { timeBlockId, taskId } }),
            addPersonalTemporyTask: (task: TaskModel) =>
                dispatch({ type: 'addPersonalTemporyTask', payload: { task } }),
        };
    }, []);

    const getMyTasksByTime = useCallback(
        async (opts: {
            endTimeFrom?: string;
            endTimeTo?: string;
            timeBlockId?: string;
            offset: number;
            limit: number;
            timeClassify?: number;
            taskClassify?: number;
            priorityTypes?: number[];
            projectId: string;
        }) => {
            const [data, err] = await getPersonalList(opts);
            if (err || !data) {
                message.error(err);
                return;
            }
            dispatch({
                type: 'setPersonalTaskList',
                payload: {
                    ...opts,
                    ...data,
                    timeClassify: Number(opts.timeBlockId),
                },
            });
        },
        []
    );

    const createPersonalTask = useCallback(
        async (opt: { title: string; projectId: string; endTimeFrom?: string; callback?: any }) => {
            const { title, endTimeFrom, callback, projectId } = opt;
            const [data, err] = await createPersonalTaskApi({
                title,
                endTimeFrom,
                callback,
                projectId,
            });
            if (err) {
                callback?.(err);
                return;
            }

            const { tasks } = data;
            const task = tasks[0];

            dispatch({
                type: 'addPersonalTemporyTask',
                payload: {
                    task: decoratePersonalTask([task as any])[0],
                },
            });
            // WorkEvent.emit('addPersonalTemporyTask', {task:decoratePersonalTask([task as any])[0]})
            if (task.projectId) {
                WorkEvent.emit('reloadTimeBlock', {
                    timeClassifys: [TimeClassifyEnum.LaterOrUntime],
                    projectId: '',
                });
            }
        },
        []
    );

    const total = useMemo(() => {
        return state.timeBlocks.reduce((p, v) => {
            if (v.id !== '5') {
                return p + (v.total || v.list.length);
            }
            return p;
        }, 0);
    }, [state.timeBlocks]);

    const [t_calendar] = useTranslation('calendar');
    const { t } = useTranslation('work');

    const [timeBlockDropDisable, setTimeBlockDropDisable] = useState<any>({});

    function onDragStart(result: DropResult, provided: ResponderProvided) {
        const { source } = result;
        const { droppableId, index } = source;
        const task = state.timeBlocks[Number(droppableId)].list[index];
        const newTimeBlockDropDisable: { [key: string]: Boolean } = {};
        if (Number(source.droppableId) !== TimeClassifyEnum.Temporary) {
            newTimeBlockDropDisable['0'] = true;
        }
        if (task?.enablePermission) {
            const { permission } = task;

            if (!permission.includes(TaskPermissionEnum.TASK_UPDATE)) {
                const timeClassify = getTimeClassifyByTime(task.workStopTime);
                for (let i = 1; i <= 5; i++) {
                    if (i === timeClassify) {
                        continue;
                    }
                    newTimeBlockDropDisable[i.toString()] = true;
                }
            }
        }
        setTimeBlockDropDisable(newTimeBlockDropDisable);
    }
    function onDragEnd(result: DropResult, provided: ResponderProvided) {
        setTimeBlockDropDisable({
            // ...timeBlockDropDisable,
            // // '0': true,
        });
        const { source, destination, draggableId } = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            if (source.index === destination.index) {
                return;
            }

            // 计算出新的顺序并应用
            const result = reorder(state.timeBlocks, source, destination);
            changPersonalTaskOrder(result);
            if (
                // 临时区域的拖动不发送请求，前端自己处理就好
                source.droppableId === destination.droppableId &&
                Number(source.droppableId) === TimeClassifyEnum.Temporary
            ) {
                return;
            }
            // 计算出目标位置的上下任务id
            const { front, after } = findFrontAndAfter(result, source, destination);
            // 构造请求
            const timeRange = generateTimeRange(Number(destination.droppableId));
            taskSortUpdate({
                front,
                after,
                ...timeRange,
                moveTaskId: draggableId,
                projectId,
            }).then(([result, err]) => {
                if (err) {
                    changPersonalTaskOrder(state.timeBlocks);
                    message.error(t('reorder failed info'));
                    return;
                }
            });
            return;
        }
        // 跨区域拖动
        const newResult = move(state.timeBlocks, source, destination);
        changPersonalTaskOrder(newResult);

        const { front, after } = findFrontAndAfter(newResult, source, destination);
        const timeClassify = Number(destination.droppableId);
        const timeRange = generateTimeRange(timeClassify);
        // 生成新时间
        const task = newResult[timeClassify].list[destination.index];
        const newTime = generateEndTimeByTimeClassify(timeClassify, task.workStopTime);
        if (task.workStartTime && newTime?.isSameOrBefore(task.workStartTime)) {
            changPersonalTaskOrder(state.timeBlocks);
            return message.warn(t('The deadline must not be earlier than the start time'));
        }
        taskSortUpdate({
            front,
            after,
            ...timeRange,
            moveTaskId: draggableId,
            endTime: newTime ? newTime.valueOf().toString() : undefined,
            projectId,
        }).then(([result, err]) => {
            if (err) {
                changPersonalTaskOrder(state.timeBlocks);
                message.error(err as string);
                return;
            }
            if (newTime) {
                message.info(
                    t('time change info').replace(
                        '%s',
                        generateDefaultTimeFormatByEndLogic(newTime, t_calendar)
                    )
                );
            }

            updatePersonalTaskItem(destination.droppableId, {
                ...task,
                endTime: newTime ? newTime.valueOf().toString() : undefined,
                workStopTime: newTime,
            });
            if (!projectId && task.projectId) {
                WorkEvent.emit('reloadTimeBlock', {
                    timeClassifys: [Number(source.droppableId), Number(destination.droppableId)],
                    projectId: task.projectId,
                });
            }
            if (projectId) {
                const header = findSelfInExecutors(task.owners, userData);
                const self = findSelfInExecutors(task.executors, userData);
                if (header || self) {
                    WorkEvent.emit('reloadTimeBlock', {
                        timeClassifys: [
                            Number(source.droppableId),
                            Number(destination.droppableId),
                        ],
                        projectId: '',
                    });
                }
            }
        });
    }
    return (
        <div className="work-tasklist-view">
            {!disableHeader && !isReadOnly && (
                <TaskListViewHeader
                    total={total}
                    projectId={projectId}
                    sourceName={soureceName}
                    createPersonalTask={createPersonalTask}
                    disableTitle={disableTitle}
                    disableCreate={disableCreate}
                    taskClassify={taskClassify}
                    sortType={1}
                />
            )}
            <div className={`work-tasklist-view-content ${isReadOnly ? 'read-only' : ''}`}>
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    {state.timeBlocks.map((timeBlock) => (
                        <TimeBlock
                            key={timeBlock.id}
                            data={timeBlock}
                            userData={userData}
                            projectId={projectId}
                            taskClassify={taskClassify}
                            priorityTypes={priorityTypes}
                            isReadOnly={isReadOnly}
                            getTaskList={getMyTasksByTime}
                            isDropDisabled={timeBlockDropDisable[timeBlock.id] || disableDrag}
                            // updatePersonalTaskItem={updatePersonalTaskItem}
                            changePersonalTaskDraw={changePersonalTaskDraw}
                            changeSelectedTaskItem={changeSelectedTaskItem}
                            // delPersonalTaskInTimeBlock={delPersonalTaskInTimeBlock}
                        />
                    ))}
                </DragDropContext>
            </div>
            <WorkHandle
                timeBlocks={state.timeBlocks}
                changPersonalTaskOrder={changPersonalTaskOrder}
                updatePersonalTaskItem={updatePersonalTaskItem}
                delPersonalTaskInTimeBlock={delPersonalTaskInTimeBlock}
                addPersonalTemporyTask={addPersonalTemporyTask}
                taskClassify={taskClassify}
                priorityTypes={priorityTypes}
                userData={userData}
                getMyTasksByTime={getMyTasksByTime}
                projectId={projectId}
            />
        </div>
    );
}

function mapStateToProps({ user }: any) {
    return {
        userData: user.userData.user,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changePersonalTaskDraw: (taskId: string) =>
            dispatch({
                type: 'work/changePersonalTaskDraw',
                payload: { taskId, drawVisible: true },
            }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskListView);
