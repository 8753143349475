/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from '@/components/Joyspace/context';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Folder, ListItem, Page, TabPanelCommonProps } from '../types';
import '../index.less';
import DataList from '../DataList';
import { getUserInfo } from '@/components/Joyspace/api';
import { request as joyspaceRequest } from '@/components/Joyspace/api/request';
import { PAGE_SIZE, transformOriginToPage } from '../utils';
import folderPng from '@/assets/img/folder.png';
import folderSharePng from '@/assets/img/folder-share.png';
import GlobalContext from '@/context/GlobalContext';
export default function TabPanelPersonal(props: TabPanelCommonProps) {
    const t = useTranslation();
    const [hasMore, setHasMore] = useState(false);
    const [currentFolder, setCurrentFolder] = useState<Folder | null>(null);
    const [routes, setRoutes] = useState<Folder[]>([]);
    const [loading, setLoading] = useState(true);
    const {
        appRuntimeEnv: { joyspaceApiHost },
    } = useContext(GlobalContext);
    const [listItems, setListItems] = useState<Array<Page | Folder>>([]);
    const loadFunc = useCallback(
        (params: Record<string, string | number>) => {
            if (!currentFolder) {
                return Promise.resolve({ total: 0, pages: [] });
            }
            setLoading(true);
            return joyspaceRequest({
                api: 'joyspace.spaces.pages.list',
                method: 'GET',
                path: `/v1/spaces/${currentFolder.spaceId}/pages`,
                params: {
                    categoryId: currentFolder.id === 'myspace' ? '' : currentFolder.id,
                    ...params,
                },
            }).then((info) => {
                const rs = { total: 0, pages: [] };
                if (info?.pages) {
                    rs.total = info.total || 0;
                    rs.pages = (info.pages || []).map((page: any) =>
                        transformOriginToPage(page, `${joyspaceApiHost}-api`)
                    );
                }
                return rs;
            });
        },
        [currentFolder, joyspaceApiHost]
    );

    const loadSubFolders = useCallback(() => {
        if (!currentFolder) {
            return Promise.resolve({ total: 0, folders: [] });
        }
        setLoading(true);
        const categoryId = currentFolder.id === 'myspace' ? 'root' : currentFolder.id;
        return joyspaceRequest({
            api: 'joyspace.folder.sub',
            method: 'GET',
            path: `/v1/folders/${categoryId}/children`,
        }).then((info) => {
            const rs = { total: 0, folders: [] };
            rs.folders = (info || []).map((item: any) => ({
                type: 'folder',
                id: item.id || '',
                title: item.name,
                iconUrl: item.isShared ? folderSharePng : folderPng,
                spaceId: item.space_id,
            }));
            return rs;
        });
    }, [currentFolder]);

    const loadMoreFunc = useCallback(() => {
        loadFunc({
            start: listItems.filter((item) => item.type === 'page').length,
            length: PAGE_SIZE,
        }).then((info) => {
            const pages: Page[] = info.pages || [];
            const newItems = listItems.slice();
            for (const page of pages) {
                const index = listItems.findIndex(
                    (item) => item.type === page.type && item.id === page.id
                );
                if (index < 0) {
                    newItems.push(page);
                }
            }
            setListItems(newItems);
            setLoading(false);
            setHasMore(info.pages.length === PAGE_SIZE);
        });
    }, [listItems, loadFunc, setListItems]);

    useEffect(() => {
        Promise.all([loadSubFolders(), loadFunc({ length: PAGE_SIZE })]).then(
            ([folders, pages]) => {
                let items: ListItem[] = [];
                if (folders) {
                    items = items.concat(folders.folders);
                }
                if (pages) {
                    items = items.concat(pages.pages);
                    setLoading(false);
                    setHasMore(pages.pages.length === PAGE_SIZE);
                }
                setListItems(items);
            }
        );
        // eslint-disable-next-line prettier/prettier
    }, [currentFolder]);

    // 第一次加载用户信息
    useEffect(() => {
        if (!currentFolder) {
            setLoading(true);
            getUserInfo().then((user) => {
                const root: Folder = {
                    type: 'folder',
                    id: 'root',
                    title: '',
                    spaceId: '$' + user.id,
                    iconUrl: 'https://storage.jd.com/hub-static/images/folderIcon.png',
                };
                root.id = 'myspace';
                setCurrentFolder(root);
                // setRoutes([root]);
                setLoading(false);
            });
        }
    }, [currentFolder]);

    // 处理
    const handleFolderClick = useCallback((folder: any) => {
        console.log(folder, 'folderfolderfolderfolder');
        setListItems([]);
        setLoading(true);
        setHasMore(false);
        setCurrentFolder(folder);
        // setRoutes([...routes, folder]);
    }, []);

    // const handleBack = useCallback(() => {
    //     if (routes.length > 0) {
    //         const newRoutes = routes.slice(0, -1);
    //         setListItems([]);
    //         setLoading(true);
    //         setHasMore(false);
    //         setCurrentFolder(newRoutes[newRoutes.length - 1]);
    //         setRoutes(newRoutes);
    //     }
    // }, [routes]);

    return (
        <DataList
            title={currentFolder?.title || ''}
            currentFolder={currentFolder}
            items={listItems}
            // onBack={handleBack}
            hasMore={hasMore}
            loading={loading}
            onFolderClick={handleFolderClick}
            loadMore={loadMoreFunc}
        />
    );
}
