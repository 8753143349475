/* eslint-disable complexity */
import React, { useCallback, useState } from 'react';
import './index.less';
import { WorkUserInfo } from '@/types/work';
import { Popover, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { UserListEmptyImg } from '@/assets/img';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import { connect } from 'dva';
import Bus from '@/utils/bus';
import { processStatusOptions } from '@/types/newWork';
import IconFont from '@/components/icon';
import { ICreateSuperviseData } from '@/types/supervise';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { Avatar } from '@/baseComponents/Avatar';
import MyParagraph from './MyParagraph';
import { changeEgovUrl } from '@/utils/tools';

import { ResStatus } from '@/api/calendar';
const { TabPane } = Tabs;

function UserStatusListPopover({
    list,
    onRemove,
    children,
    disable,
    toggleUserCardModal,
    openCreateSuperviseModal,
    userCardVisible,
    label,
    userPopover,
    userData,
    taskDetail,
    mode,
    listObj,
}: {
    onRemove: (user: any[]) => void;
    children: any;
    disable?: boolean;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    userCardVisible: boolean;
    list: WorkUserInfo[];
    label?: string; // 区分日程参与人
    userPopover?: string;
    userData: any;
    taskDetail: any;
    mode?: string;
    listObj: any;
}) {
    const { t } = useTranslation('work');
    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [advanceVisible, setAdvanceVisible] = useState(false);
    const [advanceUser, setAdvanceUser] = useState<WorkUserInfo>();
    const currentIsEdit = useCallback(
        (item: any) => {
            if (
                userData?.team?.teamId === item?.teamId &&
                userData?.team?.ddAppId === item?.app &&
                userData?.userId === item?.userId
            ) {
                return true;
            }
        },
        [userData]
    );
    function visibleChange(visible: boolean) {
        if (modalVisible) {
            return;
        }
        if (userCardVisible) {
            return;
        }
        setVisible(visible);
        setTimeout(() => {
            setAdvanceVisible(false);
        }, 500);
    }
    const [t_common] = useTranslation('common');
    function handleRemoveMember(user: WorkUserInfo) {
        setModalVisible(true);
        // 删除提醒
        prompt({
            title: t('remove executor'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: () => {
                setModalVisible(false);
                onRemove([
                    {
                        userId: user.userId,
                        teamId: user.teamId,
                        app: user.app,
                        taskUserRole: label ? undefined : user.userRole,
                    },
                ]);
            },
            onCancel: () => {
                setModalVisible(false);
            },
            content: label
                ? t('remove participant desc').replace('%s', user.realName)
                : t('remove executor desc').replace('%s', user.realName),
            okText: t_common('button.ok'),
            cancelText: t_common('button.cancel'),
        });
    }

    function handleAdvanceRecord(user: WorkUserInfo) {
        setAdvanceVisible(true);
        setAdvanceUser(user as any);
    }

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId: userData?.ddAppId || appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    const getProcessStatusTarget = (status: number) => {
        return processStatusOptions.find((item) => item.status === status) || null;
    };
    const getContent = (list: any) => {
        const getList = (list: any[]) => {
            return list.map((item) => {
                const { labelInfoList } = item;
                let isExternal = false;
                let exTagColor = '#000000';
                let dept = '';
                let proTitle = '';

                if (labelInfoList?.[0]) {
                    exTagColor = '#' + labelInfoList[0].color;
                    if (labelInfoList[0].isShow === '1') {
                        isExternal = true;
                    }
                }
                if (labelInfoList?.[1]) {
                    dept = labelInfoList[1].name;
                }
                if (labelInfoList?.[2]) {
                    proTitle = labelInfoList[2].name;
                }
                return (
                    // eslint-disable-next-line react/jsx-key
                    <div key={item.userId + item.teamId + item.app}>
                        <div className="user-status-list-item" onClick={() => openUserCard(item)}>
                            {item.imageUrl ? (
                                <Avatar
                                    src={changeEgovUrl(
                                        getHeadImgThumbUrl(item?.imageUrl, 150, 150)
                                    )}
                                    className="avatar avatarNoDraggable"
                                    name={item.user?.realName || ''}
                                    styleObj={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '4px',
                                    }}
                                    // onClick={() => openUserCard(item)}
                                />
                            ) : (
                                <div className="avatar" onClick={() => openUserCard(item)}>
                                    {(item.realName || '').slice(0, 1)}
                                </div>
                            )}
                            {!userPopover ? (
                                <div className="container">
                                    <div className="title-content">
                                        <span className="title">
                                            <div className="name">{item.realName}</div>
                                            {isExternal && (
                                                <div
                                                    className="external-tag"
                                                    style={{
                                                        borderColor: exTagColor,
                                                        color: exTagColor,
                                                    }}
                                                >
                                                    {t('external')}
                                                </div>
                                            )}
                                        </span>
                                        <span style={{ flex: 1 }} />
                                        {!disable && (
                                            <span
                                                className="action-remove"
                                                onClick={() => handleRemoveMember(item)}
                                            >
                                                {t('remove')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="desc">
                                        {dept && proTitle && (
                                            <span className="desc-org">{`${dept}-${proTitle}`}</span>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="task-container">
                                    <div className="title-content">
                                        <span className="title">{item.realName}</span>
                                        <span className="duty">{item.titleName}</span>
                                    </div>
                                    <div className="status-content">
                                        {item.processStatus && (
                                            <span
                                                className="process-status"
                                                style={{
                                                    padding: '2px 6px',
                                                    color: `${
                                                        getProcessStatusTarget(item.processStatus)
                                                            ?.color || ''
                                                    }`,
                                                    border: `1px solid ${
                                                        getProcessStatusTarget(item.processStatus)
                                                            ?.color || ''
                                                    }`,
                                                }}
                                            >
                                                {currentIsEdit(item)
                                                    ? getProcessStatusTarget(item.processStatus)
                                                          ?.currentLabel || ''
                                                    : getProcessStatusTarget(item.processStatus)
                                                          ?.label || ''}
                                            </span>
                                        )}

                                        {!disable && !mode && (
                                            <span
                                                className="action-remove"
                                                onClick={() => handleAdvanceRecord(item)}
                                            >
                                                {t('advance-record')}
                                            </span>
                                        )}
                                        {!disable && item.isRemove && (
                                            <span
                                                className="action-remove"
                                                onClick={() => handleRemoveMember(item)}
                                            >
                                                {t('remove')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {item.rejectReason && item.resStatus === ResStatus.Reject && (
                            <div className="reject-reason">
                                <MyParagraph>拒绝原因：{item.rejectReason}</MyParagraph>
                            </div>
                        )}
                    </div>
                );
            });
        };
        const getEmpty = () => (
            <div className="user-status-list-empty">
                <img src={changeEgovUrl(UserListEmptyImg)} />
                <div className="label">{label ? t('no participant') : t('no executors')}</div>
            </div>
        );
        return (
            <div className="user-status-list">{list.length > 0 ? getList(list) : getEmpty()}</div>
        );
    };

    const getTabs = () => {
        return (
            <Tabs>
                {listObj.map((item: any, index: number) => {
                    const list = item.list || [];
                    const title = item.title + ` ${list.length}`;
                    return (
                        <TabPane tab={<span clstag={item.clstag}>{title}</span>} key={index}>
                            {getContent(list)}
                        </TabPane>
                    );
                })}
            </Tabs>
        );
    };

    return (
        <Popover
            overlayClassName="work-user-status-popover work-user-status-popover-detail calendar-user-popover"
            trigger="click"
            placement="leftTop"
            content={getTabs}
            visible={visible}
            onVisibleChange={visibleChange}
            getPopupContainer={(trigger) => {
                return trigger as HTMLElement;
            }}
            style={{ width: '352px' }}
        >
            {children}
        </Popover>
    );
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStatusListPopover);
