import { message } from 'antd';
import Api from '../api';
import { ICategoryAddCallback } from './index';
import { lang } from '../context';
import {
    folderTypeEnum,
    getJoySpaceReadAllPermission,
    getJoySpaceReadFolderPermission,
    getJoySpaceReadOnlyPermission,
    getJoySpaceReadPagePermission,
    getJoySpaceReadWritePermission,
} from '../config/permissions.config';
import openFolderModal from '../contextModal/FolderModal';
import events from '../context/events';
import { FileType, OpenFolderStatus } from '../types';
const Setname: ICategoryAddCallback = (props, isAllow, isTeam) => {
    const { data, setData } = props;
    let type = data.page_type ? FileType.Page : FileType.Folder;
    let permissionType = getJoySpaceReadOnlyPermission();
    if (type === FileType.Folder && data.type && data.type === folderTypeEnum.MY) {
        permissionType = getJoySpaceReadAllPermission();
    } else if (type === FileType.Page && data.type && data.type === folderTypeEnum.MY) {
        permissionType = getJoySpaceReadWritePermission();
    } else if (data.type && data.type === folderTypeEnum.SHARE) {
        permissionType = getJoySpaceReadFolderPermission(data.permissions);
    } else if (type === FileType.Page) {
        permissionType = getJoySpaceReadPagePermission(data.permissions);
    }
    // 设置文件标题回调
    const setFolderTitle = (name: string, desc?: string) => {
        Api.categories
            .setFolderApi(data.id, name, desc)
            .then((res: any) => {
                if (res) {
                    message.success('设置成功');
                    // reload();
                    const newData = {
                        ...data,
                        name,
                        desc,
                    };
                    events.emit('list.reload');
                    events.emit('list.removeOrSetData', [newData], 'setData');

                    events.emit('changeFolderInfoById', data.id, name, desc);
                } else {
                    message.error('无权限操作');
                }
            })
            .catch((err: any) => {
                message.error(err);
                // setLoading(false);
            });
    };
    return [
        {
            children: lang('ctx.setname.title'),
            icon: 'pc_yundoc_set',
            splitCode: 1,
            callbackOrModal: () =>
                openFolderModal({
                    id: data.id,
                    title: lang('ctx.setname.title'),
                    name: data.name,
                    isModalVisible: true,
                    description: data.desc || data.description,
                    isRoot: true,
                    permissionsType: permissionType,
                    folderType: data.type,
                    isOpenFolder: data.open === 2 ? OpenFolderStatus.OPEN : OpenFolderStatus.CLOSE,
                    onOk: setFolderTitle,
                }),
            isAllow,
            type: 'setName',
        },
    ];
};

export default Setname;
