/**
 * @author sunzhiguang
 * @date 2020/8/31
 */
import React, { useContext } from 'react';
import './index.less';
import { Tag, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Session, Employee, Group } from '@/types/chat/';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import IconFont from '@/components/icon';
import {
    isCrossPlatformGroupSession,
    isCrossPlatformGroup,
    isEqualApp,
    isEqualTeam,
    isExternalGroup,
    isExternalGroupSession,
    isSpecialProjectGroup,
    isSpecialProjectSession,
} from '@/components/chat/utils/message';
import { isBjme } from '@/utils';
import { useTabs } from '@/components/Projects/components/Tabs';
import { useHistory } from 'react-router-dom';
import Details from '@/components/Projects/pages/details';
import { getProjectDetails } from '@/components/Projects/api';
import { GroupAttribute } from '@jd/jdee.im.sdk/lib/es/model/Group';

/**
 * 是否已归档
 * 0代表未归档，1代表已归档
 */
enum Archived {
    True = 1,
    False = 0,
}

/**
 * 临时： 在 IM SDK 的类型未增加 archived 之前，使用该 interface
 * 当其补充完成后记得删掉这个
 */
interface GroupType extends GroupAttribute {
    archived?: number;
}

export function NoticeTag() {
    const { t } = useTranslation();
    return <Tag color="gold">{t('notice')}</Tag>;
}

// 外部标签
export function ExternalTag() {
    const { t } = useTranslation();
    return <Tag color="orange">{t('external')}</Tag>;
}
// 日程标签
export function ScheduleTag() {
    return <Tag color="orange">日程</Tag>;
}
// 任务标签
export function WorkTag() {
    return <Tag color="orange">任务</Tag>;
}

// 跨平台标签
export function CrossPlatformTag() {
    const { t } = useTranslation();
    return <Tag color="geekblue">{t('crossPlatform')}</Tag>;
}

export function SpecialProject({
    needJump,
    session,
    group,
}: {
    needJump?: boolean;
    session?: Session;
    group?: Group;
}) {
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const isGovEnv = ['mebj', 'mebjJZB', 'jgswy'].includes(appRuntimeEnv.runtimeConfig.key); // 是否为 京办 应用环境
    const { t } = useTranslation();
    const tabs = useTabs();
    const history = useHistory();
    function goToProject() {
        // @ts-ignore
        if (session?.info?.businessId) {
            // @ts-ignore
            getProjectDetails(session.info.businessId, true)
                .then((res: any) => {
                    if (res.errCode === 1104904) {
                        let p = isGovEnv ? '专班' : '项目';
                        message.error(`非${p}人员无权限查看${p}，请先加入${p}`);
                        return;
                    }
                    const info = session?.info;
                    history.push(`/projects`);
                    setTimeout(() => {
                        tabs.open({
                            title: info.name,
                            // icon: { color: item.iconColor, icon: Number(item.icon) },
                            // @ts-ignore
                            children: <Details projectId={info.businessId} />,
                            // @ts-ignore
                            id: info.businessId,
                            // newicon: item.icon,
                        });
                    }, 1000);
                })
                .catch((err) => {
                    console.log('goToProject-----err', err);
                });
        }
    }
    function clickHandle() {
        // @ts-ignore
        if (needJump && session?.info?.businessId) {
            goToProject();
        }
    }

    function isArchive() {
        const groupData: GroupType | undefined = group;
        if (groupData?.archived) {
            return groupData?.archived === Archived.True;
        }
        // 部分（非 IM 接口） group 的 ext 为字符串
        const extString = group?.ext || (session?.info as GroupAttribute)?.ext;
        if (extString) {
            try {
                const extJSON = JSON.parse(extString);
                if (extJSON.archive) {
                    return true;
                }
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    const isArchived = isArchive();

    const tagStyleSet = {
        normal: {
            color: '#4C7CFF',
            bgColor: 'rgba(76,124,255,0.2)',
        },
        archived: {
            color: '#8f959e',
            bgColor: '#e8ebed',
        },
    };

    // 当前需求下，仅修改 京办 的状态，不对 ME行业版进行调整
    const tagStyle = isGovEnv && isArchived ? tagStyleSet.archived : tagStyleSet.normal;
    return (
        <Tag onClick={clickHandle} style={{ color: tagStyle.color }} color={tagStyle.bgColor}>
            {isGovEnv ? (isArchived ? '已归档' : t('specialProject')) : t('project')}{' '}
            {needJump ? <IconFont style={{ fontSize: 10 }} type="iconlog_enter" /> : ''}
        </Tag>
    );
}

export function GroupTag({ group }: { group: Group }) {
    if (group.business === 'work') {
        return <WorkTag />;
    } else if (group.business === 'schedule') {
        return <ScheduleTag />;
    } else if (isSpecialProjectGroup(group)) {
        return <SpecialProject group={group} />;
    } else if (isExternalGroup(group)) {
        return <ExternalTag />;
    } else if (isCrossPlatformGroup(group)) {
        return <CrossPlatformTag />;
    }
    return null;
}

export function GroupSessionTag({ session, needJump }: { session: Session; needJump?: boolean }) {
    // 日程群 schedule  任务群  work 专班主群 project_default 专班子群project
    if (session.isGroup) {
        if ((session.info as any).business === 'work') {
            return <WorkTag />;
        } else if ((session.info as any).business === 'schedule') {
            return <ScheduleTag />;
        } else if (isSpecialProjectSession(session)) {
            return <SpecialProject needJump={needJump} session={session} />;
        } else if (isCrossPlatformGroupSession(session)) {
            return <CrossPlatformTag />;
        } else if (isExternalGroupSession(session)) {
            return <ExternalTag />;
        }
    }
    return null;
}

// 单聊会话 标签
export function SingleSessionTag({
    session,
    currentEmployee,
}: {
    session: Session;
    currentEmployee: Employee;
}) {
    if (session.isNotice) {
        return <NoticeTag />;
    } else if (session.isSingle && !session.isNotice) {
        const info = session.info as Employee;
        if (!isEqualApp(info, currentEmployee) && currentEmployee.app) {
            return <CrossPlatformTag />;
        } else if (!isEqualTeam(info, currentEmployee) && currentEmployee.teamId) {
            return <ExternalTag />;
        }
    }
    return null;
}

export function UserTag({
    userInfo,
    currentEmployee,
}: {
    userInfo: Employee;
    currentEmployee: Employee;
}) {
    if (!isEqualApp(userInfo, currentEmployee)) {
        return <CrossPlatformTag />;
    } else if (!isEqualTeam(userInfo, currentEmployee)) {
        return <ExternalTag />;
    }
    return null;
}

export function SessionTag({
    session,
    currentEmployee,
    needJump,
}: {
    session: Session;
    currentEmployee: Employee;
    needJump?: boolean;
}) {
    if (!session || !session.info || !currentEmployee) {
        return null;
    }
    if (session.isGroup) {
        return <GroupSessionTag session={session} needJump={needJump} />;
    } else if (session.isSingle) {
        return <SingleSessionTag session={session} currentEmployee={currentEmployee} />;
    }
    return null;
}

// 搜索 联系人标签
export function ContactsTag({ userI, userII }: { userI: any; userII: any }) {
    if (!userI || !userII) {
        return null;
    }
    if (userI.app !== userII.app) {
        return <CrossPlatformTag />;
    } else if (userI.teamId !== userII.teamId) {
        return <ExternalTag />;
    }
    return null;
}
