import React, { Component, useCallback, useContext, useEffect } from 'react';
import DeeplinkEvent, { DeeplinkPathEnum } from './DeeplinkEvent';
import { connect } from 'dvajs';
import dayjs, { Dayjs } from 'dayjs';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { genThirdAppUrl } from '@/components/ThirdApp';
import { isFocusEnv, FocusSDK } from '@/utils';
import logger from '@/utils/logger';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';
import { scheduleInfoDrawProp } from '@/models/calendar';

const log = logger.getLogger('DeeplinkHandler');

interface IProps {
    changeScheduleInfoDraw: (payload: {
        visible: boolean;
        scheduleId: string;
        selectedScheduleTime: Dayjs;
        beginTime: Dayjs;
        endTime: Dayjs;
        from: number;
        openTime: Dayjs;
    }) => void;
    changeScheduleInfoDrawOther: (payload: {
        visible: boolean;
        scheduleId: string;
        selectedScheduleTime: Dayjs;
        beginTime: Dayjs;
        endTime: Dayjs;
        from: number;
        openTime: Dayjs;
    }) => void;
    changePersonalTaskDraw: (
        taskId: string,
        forceGet?: boolean,
        chat?: boolean,
        taskComeFrom?: string
    ) => void;
    changeFullIframeModal: (visible: boolean, url: string) => void;
    history: any;
    scheduleInfoDraw: scheduleInfoDrawProp;
    scheduleInfoDrawOther: scheduleInfoDrawProp;
}

// function DeeplinkHandler(props: IProps) {
//     const { authInfo, appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
//     const {
//         changeScheduleInfoDraw,
//         changePersonalTaskDraw,
//         history,
//         changeFullIframeModal,
//     } = props;
//     const openScheduleDraw = useCallback(
//         ({
//             data,
//         }: {
//             data: { scheduleId: string; beginTime: string; endTime: string; calendatId: string };
//         }) => {
//             const { scheduleId, beginTime } = data;
//             changeScheduleInfoDraw({
//                 visible: true,
//                 scheduleId,
//                 selectedScheduleTime: dayjs(Number(beginTime)),
//             });
//         },
//         [changeScheduleInfoDraw]
//     );
//     const openDeeplink = useCallback(
//         ({
//             data,
//             pathname,
//             external,
//         }: {
//             data: any;
//             pathname: DeeplinkPathEnum;
//             external?: { source: string };
//         }) => {
//             log.debug('openDeeplink', { data, pathname, external });
//             switch (pathname) {
//                 case DeeplinkPathEnum.Task_Draw:
//                     if (external?.source === 'imShareCard') {
//                         return changePersonalTaskDraw(data.taskId, true);
//                     }
//                     changePersonalTaskDraw(data.taskId);
//                     break;
//                 case DeeplinkPathEnum.Calendar_Tab:
//                     history?.push('/calendar');
//                     break;
//                 case DeeplinkPathEnum.AppCenter: {
//                     const { applicationKey } = data;
//                     const redirectUrl = genThirdAppUrl(
//                         applicationKey,
//                         authInfo.ddAppId,
//                         authInfo.accessToken,
//                         appRuntimeEnv
//                     );
//                     log.debug('openDeeplink appcenter', { redirectUrl, focusEnv: isFocusEnv() });
//                     if (isFocusEnv()) {
//                         window.open(redirectUrl);
//                     } else {
//                         changeFullIframeModal(true, redirectUrl);
//                     }
//                     break;
//                 }
//                 default:
//                     break;
//             }
//         },
//         [
//             appRuntimeEnv,
//             authInfo.accessToken,
//             authInfo.ddAppId,
//             changeFullIframeModal,
//             changePersonalTaskDraw,
//             history,
//         ]
//     );
//     useEffect(() => {
//         DeeplinkEvent.on('open-schedule-draw', openScheduleDraw);
//         DeeplinkEvent.on('open-deeplink', openDeeplink);
//         return () => {
//             DeeplinkEvent.off('open-schedule-draw', openScheduleDraw);
//             DeeplinkEvent.off('open-deeplink', openDeeplink);
//         };
//     }, [openDeeplink, openScheduleDraw]);
//     return null;
// }

class DeeplinkHandler extends Component<IProps> {
    static contextType = GlobalContext;
    componentDidMount() {
        this.bindEvent();
    }
    componentWillUnmount() {
        this.unbindEvent();
    }

    bindEvent = () => {
        DeeplinkEvent.on('open-deeplink', this.openDeeplink);
        FocusSDK.on('handleDeeplinkParams', this.openDeeplink);
    };
    unbindEvent = () => {
        DeeplinkEvent.off('open-deeplink', this.openDeeplink);
        FocusSDK.off('handleDeeplinkParams', this.openDeeplink);
    };

    openDeeplink = ({
        data,
        pathname,
        external,
    }: {
        data: any;
        pathname: DeeplinkPathEnum;
        external?: { source: string; from?: number; taskComeFrom?: string; chat?: boolean };
    }) => {
        switch (pathname) {
            case DeeplinkPathEnum.Task_Draw:
                if (external?.source === 'imShareCard') {
                    return this.props.changePersonalTaskDraw(
                        data.taskId,
                        true,
                        external.chat ?? true,
                        external?.taskComeFrom || ''
                    );
                }
                this.props.changePersonalTaskDraw(data.taskId);
                break;
            case DeeplinkPathEnum.Calendar_Tab:
                this.props.history?.push('/calendar');
                break;
            case DeeplinkPathEnum.Schedule_Draw: {
                const { scheduleId, beginTime, endTime } = data;
                // 如果是弹窗提醒查看日程详情，如已有打开的日程详情，则替换已打开的详情页内容，如有已打开的双页详情，则通过openTime比较替换最外层的双页详情
                // 如果在详情右侧抽屉沟通组中再次点击分享卡片，如果非本日程，则会打开两个日程详情
                if (
                    (this.props.scheduleInfoDraw.visible &&
                        external?.from === ScheduleDetailFrom.ScheduleChatShare) ||
                    (external?.from === ScheduleDetailFrom.WinNoticeCard &&
                        ((!this.props.scheduleInfoDraw.visible &&
                            this.props.scheduleInfoDrawOther.visible) ||
                            (this.props.scheduleInfoDraw.visible &&
                                this.props.scheduleInfoDrawOther.visible &&
                                this.props.scheduleInfoDraw.openTime.isBefore(
                                    this.props.scheduleInfoDrawOther.openTime
                                ))))
                ) {
                    this.props.changeScheduleInfoDrawOther({
                        visible: true,
                        scheduleId,
                        selectedScheduleTime: dayjs(Number(beginTime)),
                        beginTime: beginTime,
                        endTime,
                        from: external?.from || ScheduleDetailFrom.Unknown,
                        openTime: dayjs(),
                    });
                } else {
                    this.props.changeScheduleInfoDraw({
                        visible: true,
                        scheduleId,
                        selectedScheduleTime: dayjs(Number(beginTime)),
                        beginTime: beginTime,
                        endTime,
                        from: external?.from || ScheduleDetailFrom.Unknown,
                        openTime: dayjs(),
                    });
                }
                break;
            }
            case DeeplinkPathEnum.AppCenter: {
                const { applicationKey, mobileHomeUrl } = data;
                const { authInfo, appRuntimeEnv } = this.context;
                const redirectUrl = genThirdAppUrl(
                    applicationKey,
                    authInfo.ddAppId,
                    authInfo.accessToken,
                    appRuntimeEnv,
                    {
                        redirectUrl: mobileHomeUrl,
                    }
                );
                log.debug('openDeeplink appcenter', { redirectUrl, focusEnv: isFocusEnv() });
                if (isFocusEnv()) {
                    window.open(redirectUrl);
                } else {
                    this.props.changeFullIframeModal(true, redirectUrl);
                }
                break;
            }
            default:
                break;
        }
    };

    render() {
        return null;
    }
}

function mapStateToProps({ calendar, user }: any) {
    const { scheduleInfoDraw, scheduleInfoDrawOther } = calendar;
    return {
        userData: user.userData.user,
        scheduleInfoDraw: scheduleInfoDraw,
        scheduleInfoDrawOther: scheduleInfoDrawOther,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeScheduleInfoDraw: (payload: {
            visible: boolean;
            scheduleId: string;
            selectedScheduleTime: Dayjs;
            from: number;
            openTime: Dayjs;
        }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDraw',
                payload,
            }),
        changeScheduleInfoDrawOther: (payload: {
            visible: boolean;
            scheduleId: string;
            selectedScheduleTime: Dayjs;
            from: number;
            openTime: Dayjs;
        }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDrawOther',
                payload,
            }),
        openCreateModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
        // eslint-disable-next-line
        changePersonalTaskDraw: ( taskId: string, forceGet = false, chat = false, taskComeFrom = '' ) =>
            dispatch({
                type: 'work/changePersonalTaskDraw',
                payload: { taskId, drawVisible: true, forceGet, chat, taskComeFrom },
            }),
        changeFullIframeModal: (visible: boolean, url: string) =>
            dispatch({
                type: 'work/changeFullIframeModal',
                payload: { fullIframeModalVisible: visible, fullIframeModalUrl: url },
            }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeeplinkHandler);
