import { createContext } from 'react';
import { GroupRosterIdentity } from '@jd/jdee.im.sdk/lib/es/model/Roster';
import { GroupRoster, Session, Employee } from '@/types/chat';
import { UserModel } from '@/models/user';
import { DrawTypeEnum } from '@/components/chat/enum';

export default createContext<{
    currentEmployee: Employee;
    groupRosters: Partial<GroupRoster>[];
    groupRosterIdentity: GroupRosterIdentity | '';
    userData: UserModel;
    onToggleSetup?: (type: DrawTypeEnum) => void;
    closeSetting?: () => void;
    chatAggregationStyle?: any;
    isSmall?: boolean;
}>({} as any);
