// 新建群接龙接口
import SolitaireMessageBody, {
    SOLITAIRE_TYPE,
} from '@jd/jdee.im.sdk/lib/es/protocol/message/solitaire';
import { gatewayInstance } from '../server/gateway';

// 新建群接龙
export async function newSolitaire(opt: {
    appId: string;
    teamId: string;
    userId: string;
    solitaireId: string;
    businessId: string;
    businessType: number;
    title: string;
    deadLine: number;
    open: number;
    example: string;
    desc: string;
    status: number;
    solitaireUserList?: [
        {
            solitaireUserId: string;
            appId: string;
            teamId: string;
            solitaireId: string;
            name: string;
            value: string;
            userId: string;
            status: number;
        }
    ];
    groupChatMessage: any;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.createOrUpdateSolitaire',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 更新群接龙
export async function uptSolitaire(opt: {
    appId: string;
    teamId: string;
    userId: string;
    solitaireId: string;
    solitaireUserList: [
        {
            solitaireUserId: string;
            appId: string;
            teamId: string;
            solitaireId: string;
            name: string;
            value: string;
            userId: string;
            status: number;
            gmtCreate: string;
            createBy: string;
            gmtModify: string;
            modifyBy: string;
        }
    ];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.createOrUpdateSolitaireUser',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 我发起得
export async function pageCreateSolitaireList(opt: {
    businessType: number;
    businessId: string;
    page?: number;
    size: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.pageCreateSolitaireList',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 我参与得
export async function pageReceiveSolitaireList(opt: {
    businessType: number;
    businessId: string;
    page?: number;
    size: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.pageReceiveSolitaireList',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 未参与
export async function pageNotReceiveSolitaireList(opt: {
    businessType: number;
    businessId: string;
    page?: number;
    size: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.pageNotReceiveSolitaireList',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 查询群接龙
export async function getSolitaire(opt: {
    appId: string;
    teamId: string;
    userId: string;
    solitaireId: string;
    fromPage?: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.querySolitaire',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
