import React from 'react';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@/utils';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { changeEgovUrl } from '@/utils/tools';

const SuperviseNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            setDetailDrawerVisible: (superviseId: string) =>
                dispatch({
                    type: 'supervise/setDetailDrawerVisible',
                    payload: {
                        visible: true,
                        superviseId,
                    },
                }),
        };
    }
)(function ({
    message,
    setDetailDrawerVisible,
}: {
    message: any;
    setDetailDrawerVisible: (s: any) => void;
}) {
    const { title, content, infox, extend } = message;
    const [t] = useTranslation('chat');
    const handleClick = useDebounce(() => {
        setDetailDrawerVisible(infox?.superviseId);
    }, 250);

    return (
        <ComNoticeCard
            avatarSrc={changeEgovUrl(message.sender.avatar)}
            type="催办"
            contentList={extend.fields}
            title={title}
            handleDetail={() => handleClick()}
            loadButtonsFn={() => (
                <div className="button_item" onClick={() => handleClick()}>
                    {t('click-to-view')}
                </div>
            )}
        />
    );
});

export default SuperviseNoticeCard;
