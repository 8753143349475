import { Modal } from 'antd';
import i18n from '@/i18n';

export default function syncConfirm(content: string, title = '提示'): Promise<boolean> {
    return new Promise((resolve) => {
        Modal.confirm({
            title,
            content,
            okText: i18n.t('common:button.ok'),
            cancelText: i18n.t('common:button.cancel'),
            onOk() {
                resolve(true);
            },
            onCancel() {
                resolve(false);
            },
        });
    });
}
