// getRecommendedApplications

import BaseService from './BaseService';
import { PagerParams } from './utils';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { AuthData } from '@/types/LocalConfig';
import { IFocusSDK } from '@/utils';
import { Method, pwaAttribute } from '@/attributes/pwaAttribute';
import ListThirdAppControllers from '@/components/ThirdApp/components/controllers/ListThirdAppControllers';
import { gatewayInstance } from '@/server/gateway';

// 应用类型
export enum OpenApiType {
    Inner = 1, // 内部应用
    Outer = 2, // 外部应用
}

export enum WebviewKernel {
    WebBrowser = 5,
    MainWindow = 6,
    NewWindow = 7,
}

export enum FavoriteType {
    FavoriteNo = 0, // 不是常用
    FavoriteYes = 1, // 常用
}

export enum RecommendedType {
    RecommendedNo = 0, // 不是推荐
    RecommendedYes = 1, // 推荐
}
export const RecommendedTypeTitle = {
    [RecommendedType.RecommendedNo]: '非推荐', // 不是推荐
    [RecommendedType.RecommendedYes]: '推荐', // 推荐
};
export enum StatusLabels {
    VPN = 0,
    INTERNET = 1,
    EGOV = 2,
    BETA = 3,
    UNDER_MAINTENANCE = 4,
    CORE = 5,
    BASIC = 6,
    ACCESS = 7,
}
export const StatusLabelsText: any = {
    [StatusLabels.VPN]: 'vpn',
    [StatusLabels.INTERNET]: '互联网',
    [StatusLabels.EGOV]: '政务外网',
    [StatusLabels.BETA]: '测试版',
    [StatusLabels.UNDER_MAINTENANCE]: '维护中',
    [StatusLabels.CORE]: '核心',
    [StatusLabels.BASIC]: '基础',
    [StatusLabels.ACCESS]: '接入',
};

export const StatusLabelsStyles: any = {
    [StatusLabels.BETA]: {
        backgroundColor: '#005AF5',
        color: '#fff',
    },
    [StatusLabels.UNDER_MAINTENANCE]: {
        backgroundColor: '#FF6E6E',
        color: '#fff',
    },
    default: {
        backgroundColor: '#FF923B',
        color: '#fff',
    },
};
export interface AppStoreApplication {
    applicationId: string;
    applicationKey: string;
    applicationSecret: string;
    developmentType: number;
    openApiType: OpenApiType;
    name: string;
    appName?: string;
    icon: string;
    appIcon?: string;
    maxVersion: string;
    minVersion: string;
    mobileHomeUrl: string;
    pcHomeUrl: string;
    homeUrl: string;
    iosWebviewKernel: string;
    androidWebviewKernel: string;
    padosWebviewKernel: string;
    nativeIdentifier: string;
    webviewKernel: WebviewKernel; // web_browser:5, mainWindow:6, newWindow:7
    brief: string;
    description?: string;
    developer: string;
    builder?: string;
    favorite: FavoriteType;
    recommended: RecommendedType;
    applicationStatusLabels: any;
    networkEnvLabels: any;
    supportMode?: boolean;
    needAuth?: boolean;
    authorize?: boolean;
    // androidWebviewKernel: 2
    // applicationId: "SlCoVtwaeM7yXTIpEWpd"
    // applicationKey: "dnADq9PvlSnhurxaUayM"
    // applicationSecret: null
    // developmentType: 1
    // icon: "http://storage.jd.com/jd.jme.file.cache/OA.png"
    // iosWebviewKernel: 2
    // maxVersion: "999.999.999.999"
    // minVersion: "000.000.000.000"
    // mobileHomeUrl: "https://moa.jdcloud.com"
    // name: "审批"
    // nativeIdentifier: null
    // openApiType: 1
    // padosWebviewKernel: 2
    // pcHomeUrl: "https://moa.jdcloud.com"
}

export interface AppStoreGroupApplication {
    groupId: string;
    name: string;
    version: string;

    applications: AppStoreApplication[];
}

export interface AppStoreRecommendRes {
    groupApplication: AppStoreGroupApplication;
}

export interface AppStoreGroupRes {
    groupApplications: AppStoreGroupApplication[];
}

export interface AuthCodeRes {
    code: string;
}

export interface batchExchangeOpenUserInfo {
    appKey: string;
    openUserTeamIds: {
        openUserId: string;
        openTeamId: string;
    }[];
}

export interface batchExchangeUserInfo {
    appKey: string;
    userTeamIds: {
        userId: string;
        teamId: string;
    }[];
}

class AppStoreService extends BaseService {
    // appKey: string;

    // constructor(appRuntime: AppRuntimeEnv, focusSDK: IFocusSDK) {
    //     super(appRuntime, focusSDK);
    //     // this.appKey = appRuntime.config.app.appStoreAppKey;
    // }
    // @pwaAttribute(
    //     Method.post,
    //     ListThirdAppControllers,
    //     'getGroupApplications',
    //     'setGroupApplications',
    //     'groupApplications'
    // )
    getGroupApplications(
        params: {
            userId: string;
            teamId: string;
            groupApplicationVersions?: any;
            version?: string;
        } & PagerParams
    ) {
        return gatewayInstance.request<AppStoreGroupRes>({
            api: 'appstore.getGroupApplications',
            method: 'POST',
            body: params,
        });
        // return this.fetch<AppStoreGroupRes>('appstore', 'getGroupApplications', {
        //     // appKey: this.appKey,
        //     // indexName: 'global',
        //     // lang: 'zh_CN',
        //     // client: getClientType(),
        //     // version: '1.0.0',
        //     ...params,
        // });
    }

    getFavoriteApplications(
        params: {
            userId: string;
            teamId: string;
        } & PagerParams
    ) {
        return this.fetch<AppStoreGroupApplication>('appstore', 'getFavoriteApplications', {
            // appKey: this.appKey,
            // indexName: 'global',
            // lang: 'zh_CN',
            // client: getClientType(),
            // version: '1.0.0',
            ...params,
        });
    }

    checkApplicationNeedAuth(
        params: {
            userId: string;
            teamId: string;
            mode: string;
            appKey: string;
        } & PagerParams
    ) {
        return this.fetch<AppStoreApplication>('eopen', 'checkAppAuth', {
            // appKey: this.appKey,
            // indexName: 'global',
            // lang: 'zh_CN',
            // client: getClientType(),
            // version: '1.0.0',
            ...params,
        });
    }

    submitApplicationAuth(
        params: {
            userId: string;
            teamId: string;
            mode: string;
            appKey: string;
            authorize: boolean;
        } & PagerParams
    ) {
        return this.fetch<AppStoreApplication>('eopen', 'submitAppAuth', {
            // appKey: this.appKey,
            // indexName: 'global',
            // lang: 'zh_CN',
            // client: getClientType(),
            // version: '1.0.0',
            ...params,
        });
    }

    getRecommendedApplications(
        params: {
            userId: string;
            teamId: string;
        } & PagerParams
    ) {
        return this.fetch<AppStoreRecommendRes>('appstore', 'getRecommendedApplications', {
            // appKey: this.appKey,
            // indexName: 'global',
            // lang: 'zh_CN',
            // client: 'IOS', // getClientType(),
            // version: '1.0.0',
            ...params,
        });
    }

    getAuthCode(appKey: string) {
        return this.fetch<AuthCodeRes>('focus', 'getOpenPlatformCode', {
            appKey,
        });
    }
    removeFavoriteApplication(
        params: {
            // appKey: string;
            userId: string;
            teamId: string;
            applicationId: string;
        } & PagerParams
    ) {
        return this.fetch<boolean>('appstore', 'removeFavoriteApplication', {
            ...params,
        });
    }

    addFavoriteApplication(
        params: {
            // appKey: string;
            userId: string;
            teamId: string;
            applicationId: string;
        } & PagerParams
    ) {
        return this.fetch<boolean>('appstore', 'addFavoriteApplication', {
            ...params,
        });
    }

    // 批量编辑
    editFavoriteApplication(
        params: {
            // appKey: string;
            userId: string;
            teamId: string;
            applicationIds: [];
        } & PagerParams
    ) {
        return this.fetch<boolean>('appstore', 'editFavoriteApplication', {
            ...params,
        });
    }

    verifyUserToken(params: { userAccessToken: string }) {
        return this.fetch<any>('eopen', 'verifyUserToken', {
            ...params,
        });
    }

    batchExchangeUser(params: batchExchangeOpenUserInfo) {
        return this.fetch<batchExchangeUserInfo>('eopen', 'batchExchangeUser', {
            ...params,
        });
    }

    batchExchangeOpenUser(params: batchExchangeUserInfo) {
        return this.fetch<batchExchangeOpenUserInfo>('eopen', 'batchExchangeOpenUser', {
            ...params,
        });
    }
}

export default AppStoreService;
