import React from 'react';
import { changeEgovUrl } from '@/utils/tools';

interface propTypes {
    headImg?: string;
    userName?: string;
}

export default function UserAvatar(props: propTypes) {
    return props.headImg ? (
        <img
            className="pm-mm-list-avatar"
            style={{ overflow: 'hidden' }}
            src={changeEgovUrl(props.headImg)}
        />
    ) : (
        <span className="pm-mm-list-avatar" style={{ overflow: 'hidden' }}>
            {props.userName?.[0]}
        </span>
    );
}
