import React, { useMemo, useState } from 'react';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { FocusSDK } from '@/utils';
import { handleLinkClick } from './utils';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { changeEgovUrl } from '@/utils/tools';
import { getSelected, isShowFakeNoticeCard, isShowOldNoticeCard } from '../../utils/message';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { addClickCardRead } from '@/api/chat';
import { message as toast } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';

const DefaultNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {};
    }
)(({ message }: { message: any }) => {
    const { title, content, infox, extend } = message;
    const [isShow, setIsShow] = useState(true);
    const src = infox?.imgUrl || infox?.pic;
    const [t] = useTranslation('chat');
    let history = useHistory();
    // console.log('default notice card', message);
    const fields = !isShowOldNoticeCard(message)
        ? extend.fields
        : isShowFakeNoticeCard(message)
        ? [
              { value: content, key: 'text' },
              { value: src, key: 'image' },
          ]
        : null;
    const { authInfo, appRuntimeEnv } = React.useContext<GlobalContextConfig>(GlobalContext);
    const theContent = useMemo(() => {
        const data: any = {};
        const messageContent: any[] = [];
        fields?.forEach((fieldsItem: any) => {
            const contentData: any = cloneDeep(fieldsItem);
            switch (fieldsItem.key) {
                case 'image':
                    if (fieldsItem.value) {
                        contentData.value = (
                            <div className="img-wrapper">
                                <img
                                    onLoad={() => {
                                        setIsShow(true);
                                    }}
                                    onError={() => {
                                        setIsShow(false);
                                    }}
                                    onClick={() => FocusSDK.openUrl(fieldsItem.value)}
                                    src={changeEgovUrl(fieldsItem.value)}
                                />
                            </div>
                        );
                        if (isShow) {
                            contentData.isComponent = true;
                            messageContent.push(contentData);
                        }
                    }
                    break;
                default:
                    messageContent.push(contentData);
            }
        });
        data.contentData = messageContent;
        return data;
    }, [fields, isShow]);

    async function handleClickDetail() {
        const isPrivateNet = FocusSDK.isPrivateNet();
        // console.log('当前使用网络', isPrivateNet ? '政务外网' : '互联网');
        // console.log('extend info', JSON.stringify(extend));
        const url = isPrivateNet ? extend.egovUrl : extend.url;
        if (!url) {
            toast.warn('该应用不支持在当前网络环境下打开');
            return;
        }
        if (url) {
            handleLinkClick(url || '', message, authInfo, appRuntimeEnv);
            // console.log('开放平台', message);
            // 点击已读统计、从服务端永权那里了解到端上每次点击都去请求接口，服务端做去重处理2022.4.7
            if (extend.bizId && extend.readRecord === true) {
                await addClickCardRead(message);
            }
        }
    }

    if (!isShowOldNoticeCard(message) || isShowFakeNoticeCard(message)) {
        return (
            <ComNoticeCard
                avatarSrc={changeEgovUrl(message.sender.avatar)}
                type={extend.applicationName}
                contentList={theContent.contentData}
                title={title}
                wrapClassName={
                    isShowFakeNoticeCard(message)
                        ? 'default-notice-card-container_fake'
                        : 'userSelect'
                }
                handleDetail={extend.url ? () => handleClickDetail() : null}
                loadButtonsFn={
                    extend.url
                        ? () => (
                              <div className="button_item" onClick={() => handleClickDetail()}>
                                  {t('click-to-view')}
                              </div>
                          )
                        : null
                }
            />
        );
    } else {
        return (
            <div className="notice-card">
                <div className="notice-card-title">{title}</div>
                {content && <div className="notice-card-content">{content}</div>}
                {src && isShow ? (
                    <div className="img-wrapper">
                        <img
                            alt=""
                            onLoad={() => {
                                setIsShow(true);
                            }}
                            onError={() => {
                                setIsShow(false);
                            }}
                            onClick={() => FocusSDK.openUrl(src)}
                            src={changeEgovUrl(src)}
                        />
                    </div>
                ) : null}
                {extend?.url && (
                    <div className="notice-card-action">
                        <div
                            className="notice-card-action-view"
                            onClick={() => handleLinkClick(extend.url || '')}
                        >
                            {t('click-to-view')}
                        </div>
                    </div>
                )}
            </div>
        );
    }
});

export default DefaultNoticeCard;
