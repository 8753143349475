/**
 * 加载图片
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React from 'react';
import { LoadingOutlined, createFromIconfontCN } from '@ant-design/icons';
import './index.less';
import CircleProgress from '@/components/CircleProgress';
import {
    dealImageWidthHeight2,
    downLoadFile,
    getMessageFileName,
    getImgSrc,
    getThumbUrl,
    getXToken,
    getFileFilter,
    checkDownloadFilePathValid,
    downloadMessageFile,
    pushAutoDownload,
} from '@/utils/chat/index';
import { WithTranslation, withTranslation } from 'react-i18next';
import WebPreView from '@/components/chat/message/Preview/web/View';
import PreviewImage from '@/components/chat/message/Preview/web/view_3';
import { FileStatus, ImageChatMessage, VideoChatMessage } from '@/types/chat';
import { nanoid } from 'nanoid';

import { detectOS, FocusSDK, isFocusEnv } from '@/utils';
import ImService from '@/server/ImService';
import { nyrsfENStr } from '@/utils/date';
import { getPreviewImageModal } from '../../utils/commonMessageModal';
import { message, message as toast } from 'antd';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { changeEgovUrl } from '@/utils/tools';
import imgLoading from '@/assets/img/img-loading.png';
import { DownloadOption, SaveOption } from '@/utils/DownloadFileQueue';
import bus from '@/utils/bus';
import { checkImgExists } from '@/components/chat/utils/message';
import { MessageSendType } from '@/types/chat/enum';
import errImg from '@/assets/img/empty/err-img.png';
import { addXtoken } from '@/utils/addToken';
import { isGifImage } from '../../utils/image';
interface ImagePreviewProps {
    sendType: MessageSendType;
    message: ImageChatMessage;
    isUpload: boolean;
    uploadProgress: number;
    file?: Blob;
    onComplete: Function;
    onFailed: Function;
    isMergeForward?: boolean;
    modalMessages?: any[];
    updateSessionMessageMid: Function;
    status?: FileStatus;
    updateSessionMessagesTimestamp: Function;
}

type IImagePreviewProps = Readonly<ImagePreviewProps & WithTranslation & ChatState>;

interface MessageOperateState {
    width: number;
    height: number;
    thumbWidth: number;
    progress: number;
    display: string;
    imgSrc: string;
    thumbUrl: string;
    viewModal: boolean;
    modalLoading: boolean;
    isDragging: Boolean;
    path: string;
    dataUrl: any;
    isShow: boolean;
    newImgSrc: boolean;
}
class ImagePreview extends React.Component<IImagePreviewProps, MessageOperateState> {
    imageRef: React.RefObject<HTMLImageElement> = React.createRef();
    preImageRef: React.RefObject<HTMLImageElement> = React.createRef();
    thumbWidth = 600;
    thumbHeight = 400;
    constructor(props: IImagePreviewProps) {
        super(props);
        const { width: imageWidth, height: imageHeight, url } = props.message;
        // const [width, height] = dealImageWidthHeight(
        //     [imageWidth || 480, imageHeight || 1],
        //     false,
        //     480
        // );
        const [width, height] = dealImageWidthHeight2([imageWidth || 378, imageHeight || 180]);
        let thumbWidth = imageWidth || 100;
        if (!(imageWidth && imageWidth < 200 && imageHeight && imageHeight < 200)) {
            thumbWidth = this.thumbWidth;
        }
        this.state = {
            width: width,
            height: height,
            thumbWidth: thumbWidth,
            progress: 0,
            display: 'none',
            imgSrc: '',
            thumbUrl: '',
            viewModal: false,
            modalLoading: true,
            isDragging: false,
            isShow: true,
            path: '',
            dataUrl: '',
            newImgSrc: true,
        };
    }
    public componentDidMount() {
        const {
            message,
            currentEmployee,
            sessionMessageMap,
            messageUploadStateMap,
            selectedSession,
            sendType,
        } = this.props;
        let tempImageUrl: any;
        let MessageMap = sessionMessageMap[selectedSession.sessionId];
        MessageMap?.forEach((item) => {
            if (message.id === item.id) {
                tempImageUrl = messageUploadStateMap[item.id];
            }
            if (!tempImageUrl) return;
        });
        if (message.url) {
            const imgSrc = getImgSrc(message.url);
            let thumbUrl = '';
            // 小于100 * 100的图 直接显示原图
            if (message.width && message.width < 200 && message.height && message.height < 200) {
                thumbUrl = getThumbUrl(this.props.message.url, 100, 100);
            } else {
                thumbUrl = getThumbUrl(this.props.message.url, this.thumbWidth, this.thumbHeight);
            }
            if (
                (message as any).fileType?.includes('image/gif') ||
                isGifImage(message.url) ||
                detectOS() === 'Mac'
            ) {
                thumbUrl = imgSrc;
            }
            thumbUrl = addXtoken(thumbUrl);
            const downLocalPath = message.downLocalPath;
            // console.log('downLocalPath', downLocalPath);
            if (isFocusEnv() && downLocalPath) {
                FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                    if (exists) {
                        this.setState({ path: downLocalPath });
                    } else {
                        this.setState({ path: '' });
                    }
                });
            }
            if (
                selectedSession?.isSecret &&
                message?.expire &&
                !message.readTimeOfMessage &&
                sendType === MessageSendType.OTHER
            ) {
                this.setState({ isShow: false });
            }
            if (!selectedSession?.isSecret) {
                pushAutoDownload(message, currentEmployee, this.doDownloadMessageFile);
            }
            this.setState({
                display: 'block',
                imgSrc: imgSrc,
                thumbUrl: thumbUrl,
                dataUrl: tempImageUrl?.message.url ? tempImageUrl?.message.url : thumbUrl,
            });
            checkImgExists(thumbUrl)
                .then((res) => {
                    this.setState({
                        newImgSrc: true,
                    });
                })
                .catch(() => {
                    this.setState({
                        newImgSrc: false,
                    });
                });
        } else if (this.props.file) {
            this.preViewLocalImage(this.props.file).catch(() => {
                this.setDefaultSrc();
            });
            return;
        }
    }
    shouldComponentUpdate(
        nextProps: IImagePreviewProps,
        nextState: MessageOperateState,
        nextContext: any
    ): boolean {
        const { message, status } = this.props;
        const { message: newMessage, status: newStatus } = nextProps;
        if (status === FileStatus.COMPLETED && newStatus === status) {
            return false;
        }
        if (message?.url?.includes(`base64`) && !newMessage?.url?.includes(`base64`)) {
            return false;
        }
        return true;
    }
    setDefaultSrc = () => {
        const imgRef = this.imageRef.current as any;
        if (imgRef) {
            imgRef.src = '';
        }
        this.props.onFailed();
    };

    preViewLocalImage = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const imgRef = this.imageRef.current as any;
                if (evt.target?.result && imgRef) {
                    imgRef.src = evt.target.result;
                    if (!this.state.imgSrc) {
                        this.setState({ imgSrc: imgRef.src });
                    }
                    this.props.onComplete();
                    resolve();
                } else {
                    reject(new Error(this.props.t('failed-to-load-picture')));
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            if (file instanceof Blob) {
                reader.readAsDataURL(file);
            }
        }).then(() => {
            this.setState({ display: 'block' });
        });
    };

    loadImageProgressCallback = (progress: number) => {
        this.setState({ progress });
    };

    getDownloadOptions = ({ viewFile, saveAs, message, auto }: SaveOption): DownloadOption => {
        const { t, updateSessionMessageMid } = this.props;
        return {
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(message.sessionId, message.mid, {
                    downLocalPath: path,
                });
                if (result) {
                    updateSessionMessageMid({
                        sessionId: (message as ImageChatMessage).sessionId,
                        sendResult: { body: { ...message, downLocalPath: path } },
                    });
                    this.setState({
                        path: path,
                        isDragging: false,
                    });
                } else {
                    if (!auto) {
                        toast.error(t('failed'));
                    }
                    this.setState({ isDragging: false });
                }
            },
        };
    };

    doDownloadMessageFile = ({
        viewFile,
        saveAs,
        message,
        auto = false,
    }: SaveOption): Promise<void> => {
        const options = this.getDownloadOptions({ viewFile, saveAs, message, auto });
        return downloadMessageFile({ viewFile, saveAs, message, auto }, options);
    };

    previewImage = async () => {
        const { isShow } = this.state;
        const { message, isMergeForward, modalMessages } = this.props;
        !isShow && this.handleSetReadTime();
        this.setState({ isShow: true });
        getPreviewImageModal({ message, isMergeForward, modalMessages });
    };

    handleSetReadTime = async () => {
        const { message, updateSessionMessageMid, updateSessionMessagesTimestamp } = this.props;
        const readTimeOfMessage = new Date().getTime();
        const imService = ImService.getInstance();
        const result = await imService.updateReadTime(message.sessionId, message.mid, {
            ...message,
            readTimeOfMessage,
        });
        if (result) {
            await updateSessionMessageMid({
                sessionId: message.sessionId,
                sendResult: {
                    body: { ...message, readTimeOfMessage },
                },
            });
            await updateSessionMessagesTimestamp({
                timestamp: new Date().getTime(),
            });
        }
    };

    getFileName = () => {
        const message = this.props.message as any;
        const name = getMessageFileName(message);
        if (name) {
            return name;
        }
        return `${process.env.REACT_APP_NAME || '京办'}${nyrsfENStr(new Date().getTime())}.jpg`;
    };

    onImageLoad = () => {
        this.setState({ modalLoading: false });
    };

    onDragStart = (e: any) => {
        const config = JSON.parse(FocusSDK.getConfigData());
        if (!isFocusEnv()) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        const { connectState, message, t } = this.props;
        if (connectState && connectState.state === ConnectState.READY) {
            const { path, isDragging } = this.state;
            // console.log('new message', message, '哈哈哈', path);
            if (isDragging) {
                toast.warning(t('file-downloading-drag-save'));
                return;
            }
            if (!path) {
                this.setState({ isDragging: true });
                toast.warning(t('file-downloading-drag-save'));
                this.doDownloadMessageFile({
                    viewFile: false,
                    saveAs: false,
                    message,
                }).then();
            } else {
                FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.startDrag(path);
                    } else {
                        if (isDragging) {
                            toast.warning(t('file-downloading-drag-save'));
                            return;
                        }
                        this.setState({ isDragging: true });
                        toast.warning(t('file-downloading-drag-save'));
                        this.doDownloadMessageFile({
                            viewFile: false,
                            saveAs: false,
                            message,
                        }).then();
                    }
                });
            }
        } else {
            toast.warning(t('drag-after-the-network-is-normal'));
            return;
        }
    };

    public render() {
        const {
            width,
            height,
            display,
            viewModal,
            imgSrc,
            thumbUrl,
            modalLoading,
            thumbWidth,
            dataUrl,
            isShow,
            newImgSrc,
        } = this.state;
        const {
            isUpload,
            uploadProgress,
            message,
            isMergeForward,
            modalMessages,
            status,
        } = this.props;
        const progress = isUpload ? (uploadProgress as number) : this.state.progress;
        const isDrag = FocusSDK.getDeviceInfo().platform !== 'web' && !isMergeForward;
        // console.log('是正则', message);
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        // console.log('thumUrl', this.imageRef);
        // const reg = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i;
        // let isBase64 = false;
        // if (dataUrl) {
        //     isBase64 = reg.test(dataUrl);
        // }
        return (
            <div
                className={`pre-view-box ${!isShow ? 'filter-blur' : ''}`}
                style={{
                    width: newImgSrc === true ? width : 'auto',
                    // width: thumbWidth,
                    height: 'auto',
                }}
            >
                {!isShow && (
                    <div className="image-message-mask" onClick={this.previewImage}>
                        <div className="image-message-mask_btn">点击查看</div>
                    </div>
                )}
                {newImgSrc === true ? (
                    <img
                        id="img"
                        className={`${(message as any).fileType === 'image/gif' && 'imgGif'}`}
                        ref={this.imageRef}
                        src={changeEgovUrl(dataUrl)}
                        alt=""
                        onClick={() => this.previewImage()}
                        width={width}
                        height={height}
                        // width={thumbWidth}
                        // height="auto"
                        style={{ display: display }}
                        data-id={message.id}
                        draggable={isDrag}
                        onDragStart={this.onDragStart}
                    />
                ) : (
                    <div className="errimg">
                        <img
                            id="img"
                            ref={this.imageRef}
                            src={changeEgovUrl(errImg)}
                            onClick={() => this.previewImage()}
                            alt=""
                            width={60}
                            height={54}
                            data-id={message.id}
                            draggable={isDrag}
                            onDragStart={this.onDragStart}
                        />
                        <span>图片加载失败</span>
                    </div>
                )}
                {/* {!isBase64 ? (
                    <img
                        id="img"
                        ref={this.imageRef}
                        src={changeEgovUrl(dataUrl)}
                        alt=""
                        onClick={() => this.previewImage()}
                        width={width}
                        height={height}
                        // width={thumbWidth}
                        // height="auto"
                        style={{ display: display }}
                        data-id={message.id}
                        draggable={isDrag}
                        onDragStart={this.onDragStart}
                    />
                ) : (
                    <div
                        className="pre-view-box"
                        style={{
                            width,
                            height,
                            display: display === 'block' ? 'block' : 'none',
                        }}
                    >
                        <img
                            id="img"
                            ref={this.imageRef}
                            src={changeEgovUrl(dataUrl)}
                            alt=""
                            onClick={() => this.previewImage()}
                            width={width}
                            height={height}
                            // width={thumbWidth}
                            // height="auto"
                            style={{ display: display }}
                            data-id={message.id}
                            draggable={isDrag}
                            onDragStart={this.onDragStart}
                        />
                    </div>
                )} */}
                <div
                    className="circle-progress"
                    style={{
                        // display: modalLoading ? 'block' : 'none',
                        display: display === 'block' ? 'none' : 'block',
                    }}
                >
                    {/* <Progress type="circle" percent={progress} width={20} /> */}
                    <CircleProgress percent={progress} />
                </div>
                {(status === FileStatus.UPLOADING || status === FileStatus.CANCELED) && (
                    <div className="file-image-mask">
                        <img className="imgLoading" src={imgLoading} alt="" />
                    </div>
                )}
            </div>
        );
    }
}
function mapStateToProps({ chat }: any) {
    const {
        connectState,
        currentEmployee,
        selectedSession,
        messageUploadStateMap,
        sessionMessageMap,
    } = chat as ChatState;
    return {
        connectState,
        currentEmployee,
        selectedSession,
        messageUploadStateMap,
        sessionMessageMap,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            return dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(ImagePreview));
