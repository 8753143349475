import FileService from '@jd/jdee-fs-desktop';
import { getAppId, getAuthInfo } from '@/utils';
import { lookup, contentType } from 'mime-types';
import { createUploadTask } from '@/server/FileService';

class UploadImageControler {
    fs: any;
    task: any;
    uploadSuccess?: Function;
    constructor(appRuntimeEnv: any, uploadSuccess?: Function) {
        this.fs = null;
        this.task = null;
        this.uploadSuccess = uploadSuccess;

        // this.initFs(appRuntimeEnv);
    }
    async initFs(appRuntimeEnv: any) {
        const { fileService, gateway } = appRuntimeEnv;
        const { appKey, hostOrigin } = fileService;
        const { host, stage, protocol } = gateway;
        const authInfo = await getAuthInfo();
        // this.fs = new FileService({
        //     appKey: appKey || '', // 文件服务appkey
        //     host: hostOrigin,
        //     janus: {
        //         host: `${protocol}://${host}`,
        //         appId: getAppId() || '', // janus网关appid
        //         // @ts-ignore
        //         stage: stage,
        //     },
        //     auth: {
        //         // 认证信息
        //         xToken: authInfo['x-token'], // 用户登录token
        //         xTeamId: authInfo['x-team-id'], // 用户所在teamId
        //         xClient: authInfo['x-client'], // 客户端类型
        //     },
        // });
    }
    setUploadSuccess(uploadSuccess: Function) {
        this.uploadSuccess = uploadSuccess;
    }
    addFile(file: any) {
        const blob: any = file.getAsFile ? file.getAsFile() : file;
        if (blob.type.indexOf('image') < 0) {
            return;
        }
        this.task = createUploadTask('id', blob, {
            needAuthn: 0, // 是否需要下载健全，可选参数默认需要
            fileName: blob.name, // 文件名称
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream', // 文件类型
            fileSize: blob.size, // 文件大小
        });

        this.bindTaskEvent();

        this.task.start();
    }

    asyncAddFile(file: any) {
        const blob: any = file.getAsFile ? file.getAsFile() : file;

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return new Promise((resolve, reject) => {
            self.task = createUploadTask('id', blob, {
                needAuthn: 0, // 是否需要下载健全，可选参数默认需要
                fileName: blob.name, // 文件名称
                fileType: blob.type || lookup(blob.name) || 'application/octet-stream', // 文件类型
                fileSize: blob.size, // 文件大小
            });

            self.bindTaskEvent(resolve, reject);

            self.task.start();
        });
    }

    bindTaskEvent(resolve?: any, reject?: any) {
        this.task.on('progress', (progress: any) => {
            // eslint-disable-next-line
            console.log('this is progress', progress);
        });
        this.task.on('state', (state: any) => {
            if (state.state === 'completed') {
                // eslint-disable-next-line
                // console.log('this  is state completed ', state);
                !resolve && this.uploadSuccess && this.uploadSuccess(state);
                resolve?.(state);
            }
            if (state.state === 'failed') {
                // eslint-disable-next-line
                console.log('this is state failed ', state);
                reject?.(state);
            }
        });
    }
}

export default UploadImageControler;
