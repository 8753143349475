import AppRuntimeEnv from '@/types/AppRuntimeEnv';

export function getTranslationName(appRuntimeEnv: AppRuntimeEnv, name: string) {
    const key = appRuntimeEnv.tenantKey;
    if (key === 'jdcloud') {
        return name;
    }
    return `${name}_${key}`;
}
export const duccConfigI18 = {
    joyspace: '文档',
};
