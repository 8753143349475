import React from 'react';
import {
    AuthDataRes,
    CreateTeamAndEmployee,
    TeamUserInfo,
    BizTypeEnum,
    ValidateAccountRes,
} from '@/types/LoginPage';

export function isValidMobile(mobile: string) {
    return /^1\d{10}$/.test(mobile);
}
export function isValidPassword(pwd: string) {
    return /\d{1,}/.test(pwd) && /[a-z]{1,}/.test(pwd) && /[A-Z]{1,}/.test(pwd);
}

export function isValidEmail(email: string) {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
    );
}

export function isValidVerifyCodes(verifyCodes: string[]): boolean {
    const invalid = verifyCodes.find((val) => {
        if (val.length !== 1) {
            return true;
        }
        if (isNaN(Number(val))) {
            // eslint-disable-line
            return true;
        }
        return false;
    });
    return typeof invalid === 'undefined';
}

export interface LoginFormContextType {
    step2: boolean;
    mobile: string;
    email: string;
    account: string;
    onLoginTypeChange: (type: string) => void;
    onTeamIdChange: (teamId: string) => void;
    onMobileChange: (mobile: string) => void;
    onEmailChange: (email: string) => void;
    onAccountChange: (account: string) => void;
    userName: string; // 新用户 创建用户时输入的名字
    onUserNameChange: (userName: string) => void;
    onLoginModeChange: (mode: string) => void;
    autoLogin: boolean;
    onAutoLoginChange: (autoLogin: boolean) => void;
    resendLastTs: number;
    setResendLastTs: (ts: number) => void;
    onSendCode: (teamId?: string) => Promise<boolean>;
    sendVerifyCode: (params?: any) => void;
    onLoginError: (err: Error) => void;
    navigatePath: string;
    navigate: (path: string) => void;
    onSelectTeam: () => void;
    selectedTeam: (data: AuthDataRes) => void;
    enterApplication: (teamInfo: CreateTeamAndEmployee | TeamUserInfo | null) => void;
    authInfo: AuthDataRes;
    loginType: string;
    loginMode: string;
    teamId: string;
    loginName: string;
    bizType: BizTypeEnum;
    accountInfo: ValidateAccountRes;
}
// @ts-ignore
export const LoginFormContext = React.createContext<LoginFormContextType>({});
