import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IconArrowLeft } from '@/components/icon';
export const Btn = ({ loading, onClick, text, disabled }: any) => {
    const loadingComp = (
        <Spin
            style={{
                display: loading ? 'block' : 'none',
            }}
            indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />}
        />
    );
    return (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={onClick}
            className="btn"
            style={
                !disabled
                    ? {
                          opacity: 1,
                          cursor: 'pointer',
                      }
                    : {}
            }
        >
            <div>{loadingComp}</div>
            <div
                style={{
                    margin: 12,
                    fontSize: 14,
                    color: 'rgb(255, 255, 255)',
                }}
            >
                {text}
            </div>
        </div>
    );
};

export const BackNav = ({
    clickHandle,
    title,
    desc,
    subtitle,
    icon,
}: {
    clickHandle: () => void;
    subtitle?: string;
    desc?: string;
    title: string;
    icon?: any;
}) => {
    return (
        <>
            <div
                style={{
                    margin: '10px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
                className="hover-color"
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setTimeout(() => {
                            clickHandle();
                        }, 0);
                    }}
                >
                    {icon || IconArrowLeft}
                    <span
                        style={{
                            marginLeft: 5,
                            verticalAlign: 'middle',
                        }}
                    >
                        {title}
                    </span>
                </div>
            </div>
            <h4 style={{ margin: '20px 0 0 0', fontSize: 24 }}>{subtitle}</h4>
            <p
                style={{
                    margin: 0,
                    paddingBottom: 20,
                }}
            >
                {desc}
            </p>
        </>
    );
};
