import { gatewayInstance } from '../server/gateway';
import { FocusSDK, getAppId } from '@/utils';
import { SearchGlobalParams, SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import ImService from '@/server/ImService';
import PinyinMatch from 'pinyin-match';
import cookies from 'js-cookie';
import { getDeptPositionDesc } from '@/models/user';
import { changeEgovUrl } from '@/utils/tools';

// export enum SearchType {
//     All = '0',
//     Contacts = '1',
//     Group = '2',
//     App = '3',
//     JoySpace = '4',
//     MessageHistory = '-1',
// }

// export interface SearchGlobalParams {
//     keyword: string;
//     searchType?: SearchType;
//     pageNo?: number;
//     pageSize?: number;
// }

export async function searchExternalContacts({
    keyword,
    searchType = SearchType.Contacts,
    pageNo = 1,
    pageSize = 20,
}: SearchGlobalParams): Promise<any> {
    const imService = ImService.getInstance();
    const externalContacts = await imService.getExternalContacts();
    return externalContacts?.reduce((result, item) => {
        const employee = item.employee;
        const matched = PinyinMatch.match(employee.name || '', keyword);
        if (matched) {
            result.push({
                app: item.app,
                deptName: item.employee.station,
                avatar: changeEgovUrl(item.employee.avatar),
                userId: item.userId,
                realName: item.employee.name || item.employee.nickName,
                teamId: item.teamId,
                highlight: [
                    {
                        name: 'realName',
                        value: item.employee.name || item.employee.nickName,
                        key: Array.isArray(matched)
                            ? item.employee.name.slice(matched[0], matched[1] + 1)
                            : keyword,
                    },
                ],
                desc: getDeptPositionDesc(
                    item.employee.e_dept_full_name || item.employee.deptName || '',
                    item.employee.e_position || item.employee.titleName || ''
                ),
            });
        }
        return result;
    }, [] as any);
}

export async function getSearchGlobal(params: SearchGlobalParams): Promise<any> {
    const searchGlobalList = await searchGlobal(params);
    return {
        contacts: searchGlobalList?.contacts
            ? searchGlobalList?.contacts.map((item: any) => {
                  return {
                      ...item,
                      desc: getDeptPositionDesc(item?.deptName || '', item?.titleName || ''),
                  };
              })
            : [],
        groups: searchGlobalList?.groups ? searchGlobalList?.groups : [],
        depts: searchGlobalList?.depts
            ? searchGlobalList?.depts
            : { data: [], message: '', code: '' },
        hasMoreDepts: searchGlobalList.hasMoreDepts,
        hasMoreContacts: searchGlobalList.hasMoreContacts,
        hasMoreGroups: searchGlobalList.hasMoreGroups,
    };
}

/**
 * 添加联系人
 *
 * @export
 * @param {*} opts
 * @returns
 */
export async function searchContact({
    keyword,
    pageNo = 1,
    pageSize = 10,
}: {
    keyword: string;
    pageNo: number;
    pageSize: number;
}): Promise<any> {
    return gatewayInstance.request({
        api: 'search.searchTeamConcatByKeyword',
        method: 'POST',
        body: {
            appKey: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
            indexName: 'global',
            keyword,
            pageNo,
            pageSize,
        },
    });
}

/**
 * 搜索用户
 *
 * @export
 * @param {*} opts
 * @returns
 */
export async function searchGlobal({
    keyword,
    searchType = SearchType.All,
    pageNo = 1,
    pageSize = 10,
}: SearchGlobalParams): Promise<any> {
    return gatewayInstance.request({
        api: 'search.searchGlobal',
        method: 'POST',
        body: {
            appId: getAppId(), // isFocus ? process.env.REACT_APP_DESKTOP_APPID : process.env.REACT_APP_WEB_APPID,
            appKey: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
            indexName: 'global',
            keyword,
            searchType,
            pageNo,
            pageSize,
        },
    });
}

export async function searchContactByTelephone(telephone: string) {
    return gatewayInstance.request({
        api: 'search.searchConcatByKeyword',
        method: 'POST',
        body: {
            appId: getAppId(), // isFocus ? process.env.REACT_APP_DESKTOP_APPID : process.env.REACT_APP_WEB_APPID,
            appKey: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
            keyword: telephone,
            indexName: 'account_t_empl',
        },
    });
}

export async function searchGroupByGroupNo(groupNo: string) {
    return gatewayInstance.request({
        api: 'search.searchGroupByGroupId',
        method: 'POST',
        body: {
            appId: getAppId(), // isFocus ? process.env.REACT_APP_DESKTOP_APPID : process.env.REACT_APP_WEB_APPID,
            appKey: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
            groupId: groupNo,
            indexName: 'timline_t_group',
        },
    });
}
