import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { Popover, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { openJoinMeetingModal } from '@/utils/modals';
import useGroupUserSelector from '@/components/chat/GroupUserSelector';
import EventBus from '@/utils/bus';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import { isFocusEnv } from '@/utils';
import cache from '@/utils/cache';
import Cookies from 'js-cookie';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import log from '@/utils/logger';
import config, { ConfigEnum } from '@/config/config';
import { IconAdd, IconGroup, IconJoyMeetingGroup } from '@/components/icon';
import './AddPopover.less';
const logger = log.getLogger('Search');

export enum CreateBtnType {
    Group = 'group',
    Zhuanban = 'zhuanban',
    Meeting = 'meeting',
    JoinMeeting = 'join-meeting',
    AppointmentMeeting = 'appointment-meeting',
    Contact = 'Contact',
}

function AddPopover({
    userData,
    currentEmployee,
    openCreateScheduleModal,
    openCreateContactModal,
    getCurrentEmployee,
}: {
    userData: any;
    currentEmployee: any;
    openCreateScheduleModal: (data: any) => void;
    openCreateContactModal: (data: any) => void;
    getCurrentEmployee: Function;
}) {
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation('common');
    const { focusSDK, imService, authInfo = {} as any, permissions } = useContext<
        GlobalContextConfig
    >(GlobalContext);
    const { teamUserInfo, userId } = authInfo;

    useEffect(() => {
        if (!currentEmployee) {
            getCurrentEmployee();
        }
    }, [currentEmployee, getCurrentEmployee]);

    useEffect(() => {
        function joyMeetingInviteCb(message: any) {
            const host = process.env.REACT_APP_ZOOM_WEB_HOST;
            if (!message) {
                return;
            }
            const { meetingNumber, meetingPassword, meetingID, meetingTopic } = message.ext || {};
            if (!host) {
                return;
            }
            const meetingUrl = new URL(host);
            meetingUrl.searchParams.append('meetingNum', meetingNumber);
            meetingUrl.searchParams.append('meetingPass', meetingPassword || '');
            if (cache.token) {
                const { focusToken, focusTeamId, focusClient } = cache.token.token || {};
                meetingUrl.searchParams.append('focus-token', focusToken || '');
                meetingUrl.searchParams.append('focus-team-id', focusTeamId || '');
                meetingUrl.searchParams.append('focus-client', focusClient || 'WEB');
            } else {
                const focusToken = Cookies.get('focus-token');
                const focusTeamId = Cookies.get('focus-team-id');
                meetingUrl.searchParams.append('focus-token', focusToken || '');
                meetingUrl.searchParams.append('focus-team-id', focusTeamId || '');
                meetingUrl.searchParams.append('focus-client', 'WEB');
            }
            window.open(meetingUrl.toString(), 'jd_join_meeting');
            const { sessionId, from, ext, mid } = message;
            const { fromUser, toUsers } = ext || {};
            if (!sessionId || !mid || !Array.isArray(toUsers)) {
                return;
            }
            const newMessage = {
                ext: {
                    id: generateMessageId(),
                    startTime: Date.now(),
                    sessionState: 200,
                    fromUser: toUsers.find((item: any) => item.pin === userId),
                    sessionType: 1,
                    toUsers: toUsers.filter((item: any) => item.pin !== userId),
                    type: ChatMessageType.SYS,
                    enableVideo: 1,
                    meetingID,
                    meetingDuration: 0,
                    meetingNumber,
                    avsdk: 'zoom',
                    meetingPassword,
                    projectType: 0,
                    enableMicphone: 1,
                    displayName: 'displayName',
                    meetingTopic,
                },
                content: '用户接听',
                requestData: {
                    sessionId,
                },
                noPush: 1,
                state: 0,
                type: ChatMessageType.SYS,
                flag: 100,
            } as any;
            logger.debug('send join message', { oldMessage: message, newMessage });
            // imService.getChatManager()?.method.message.sendRawMessage(newMessage);
            imService
                .sendChatMessage(sessionId, mid, newMessage)
                .then((res: any) => {
                    logger.debug('send chat message ok: ', res);
                })
                .catch((e: any) => {
                    logger.error('send chat message err', e);
                });
        }
        EventBus.on('chat_joymeeting_invite', joyMeetingInviteCb);
        return () => {
            EventBus.off('chat_joymeeting_invite', joyMeetingInviteCb);
        };
    }, [userId, imService]);

    // console.log(currentEmployee, 'currentEmployee===>');

    const openCreateGroupModal = useGroupUserSelector({
        currentEmployee,
        userData: userData ? userData.user : null,
        sessionMembers: [currentEmployee],
    });
    const onCreateBtnClick = useCallback(
        (btnType) => {
            switch (btnType) {
                case CreateBtnType.JoinMeeting:
                    if (isFocusEnv()) {
                        EventBus.emit('meeting:join-show');
                        return;
                    }
                    openJoinMeetingModal({
                        visible: true,
                        t,
                        async onOk({ meetingNum, meetingPass }: any) {
                            EventBus.emit('chat_joymeeting_invite', {
                                ext: {
                                    meetingNumber: meetingNum,
                                    meetingPassword: meetingPass,
                                },
                            });
                            return true;
                        },
                    });
                    break;
                case CreateBtnType.Group:
                    openCreateGroupModal();
                    break;
                case CreateBtnType.Contact:
                    openCreateContactModal({ visible: true });
                    break;
                // 创建专班
                // 需求变更：隐藏专班入口：2021.3.22
                // case CreateBtnType.Zhuanban:
                //     history.push({
                //         pathname: 'Projects',
                //         search: qs.stringify({ from: 'message' }),
                //     });
                //     break;
                case CreateBtnType.Meeting:
                    EventBus.emit('meeting:create-show');
                    break;
                case CreateBtnType.AppointmentMeeting:
                    openCreateScheduleModal({ visible: true, defaultAppointment: true });
                    break;
                default:
                    return;
            }
        },
        [t, openCreateGroupModal, openCreateContactModal, openCreateScheduleModal]
    );
    const getCreateButtons = useCallback(() => {
        const buttons = [CreateBtnType.Group, CreateBtnType.Contact];
        // 京办显示创建专班入口
        // isGovEnv && buttons.push(CreateBtnType.Zhuanban); 需求变更：隐藏专班入口
        const isFocus = isFocusEnv();
        if (config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN]) {
            buttons.push(CreateBtnType.JoinMeeting);
        }
        if (isFocus && config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE]) {
            buttons.push(CreateBtnType.Meeting);
        }
        if (isFocus && config[ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT]) {
            buttons.push(CreateBtnType.AppointmentMeeting);
        }
        return buttons;
    }, []);
    let buttons = [
        {
            key: CreateBtnType.Group,
            icon: IconGroup,
            text: t('createButton.createGroup'),
            clstag: 'Xtbg_Msg_Home|AddGroup',
        },
        {
            key: CreateBtnType.Contact,
            icon: IconJoyMeetingGroup,
            text: t('createButton.addContact'),
            clstag: 'Xtbg_Msg_Home|AddContact',
        },
        // { key: CreateBtnType.Zhuanban, icon: IconZhuanban, text: t('createButton.createZhuanban') },
        // {
        //     key: CreateBtnType.Meeting,
        //     icon: IconMeeting,
        //     text: t('createButton.createMeeting'),
        //     clstag: 'pageclick|keycount|focus_home_01_1615797361152|3',
        // },
        // {
        //     key: CreateBtnType.JoinMeeting,
        //     icon: IconJoinMeeting,
        //     text: t('createButton.joinMeeting'),
        //     clstag: 'pageclick|keycount|focus_home_01_1615797361152|4',
        // },
        // {
        //     key: CreateBtnType.AppointmentMeeting,
        //     icon: IconAppointMeeting,
        //     text: t('createButton.appointMeeting'),
        //     clstag: 'pageclick|keycount|focus_home_01_1615797361152|5',
        // },
    ];
    if (Array.isArray(getCreateButtons)) {
        buttons = buttons.filter((item) => getCreateButtons.findIndex((k) => k === item.key) >= 0);
    }
    if (!config[ConfigEnum.EXTERNAL_CONTACTS_ADD_CONTACTS]) {
        buttons = buttons.filter((item) => item.key !== CreateBtnType.Contact);
    }
    const content = (
        <div className="create-buttons">
            {buttons.map((btn) => (
                <div
                    key={btn.key}
                    className="create-btn-item create-btn-item1"
                    onClick={() => {
                        onCreateBtnClick(btn.key);
                        setVisible(false);
                    }}
                    clstag={`pageclick|keycount|${btn.clstag}`}
                >
                    <div>{btn.icon}</div>
                    <div style={{ marginLeft: 8 }}>{btn.text}</div>
                </div>
            ))}
        </div>
    );

    return (
        <Popover
            content={content}
            overlayClassName="focus-create-button"
            trigger="click"
            visible={visible}
            onVisibleChange={(visible) => setVisible(visible)}
        >
            <Button onClick={() => setVisible(!visible)} className="popoverButton" size="small">
                {IconAdd}
            </Button>
        </Popover>
    );
}

function mapStateToProps(props: { user: any; chat: ChatState }) {
    const { user, chat } = props;
    return {
        userData: user.userData,
        currentEmployee: chat.currentEmployee,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateScheduleModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
        openCreateContactModal(data: any) {
            dispatch({ type: 'ebook/openCreateContactModal', payload: data });
        },
        getCurrentEmployee: () => {
            dispatch({ type: 'chat/getCurrentEmployee', payload: {} });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPopover);
