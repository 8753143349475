import React from 'react';
import './index.less';

interface IProps {
    isShared: boolean;
    size?: string;
}
const ShareIcon = (props: IProps) => {
    // console.log(props, 'propspropsprops');
    const { isShared = false, size = '12px' } = props;
    return (
        <span className="joyspace-share-icon">
            <span
                data-icon
                data-category
                className={isShared ? ' joyspace-data-category-share_icon' : ''}
            />
        </span>
    );
};

export default ShareIcon;
