import React, { useState, useEffect } from 'react';
import { Icons } from '../../../Icon';
import './index.less';

type menuItem<T> = {
    text: string;
} & T;

interface Props<T> {
    onChange: (o: menuItem<T>) => void;
    restoreOrder?: () => void;
    menu: menuItem<T>[];
}

export default function <T>(props: Props<T>) {
    const [menu, select] = useState(props.menu[0]);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const close = () => {
            document.removeEventListener('mousedown', close, false);
            setStatus(false);
        };
        document.addEventListener('mousedown', close, false);
    }, [status]);
    return (
        <div className="joyspace-list-filter">
            <div
                onClick={(e) => {
                    setStatus(true);
                }}
            >
                <span data-title>{menu.text}</span>
                {Icons.dropdownMenu}
            </div>
            {status && (
                <div data-menu className="joyspace-layer-menu joypsace-filter-menu-lists">
                    <ul>
                        {props.menu.map((option, index) => {
                            return (
                                <li
                                    onMouseDown={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        select(option);
                                        setStatus(false);
                                        if (index === 0 && props.restoreOrder) {
                                            props.restoreOrder();
                                        } else {
                                            props.onChange(option);
                                        }
                                    }}
                                    key={index}
                                >
                                    {option.text}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
}
