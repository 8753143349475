import React, { useMemo, useState } from 'react';
// import HIGH_PRIORITY_IMG from '@/assets/img/priority/high.svg';
// import MIDDLE_PRIORITY_IMG from '@/assets/img/priority/middle.svg';
// import NORMAL_PRIORITY_IMG from '@/assets/img/priority/normal.svg';
// import LOW_PRIORITY_IMG from '@/assets/img/priority/low.svg';
import PNG_HIGH_PRIORITY_IMG from '@/assets/img/priority/high.png';
import PNG_MIDDLE_PRIORITY_IMG from '@/assets/img/priority/middle.png';
import PNG_NORMAL_PRIORITY_IMG from '@/assets/img/priority/normal.png';
import PNG_LOW_PRIORITY_IMG from '@/assets/img/priority/low.png';
import { changeEgovUrl } from '@/utils/tools';
import { EsuperviseSourceType } from '@/types/supervise';
// enum From {
//     Task = 'task',
//     Calendars = 'calendars',
// }

interface IProps {
    from?: EsuperviseSourceType;
    type: any;
}

const Tab = (props: IProps) => {
    const { from, type } = props;
    console.log(from, type, '=============>');
    const [imgArr, setImgArr] = useState([
        {
            title: 'A1',
            code: 4,
            // img: <HIGH_PRIORITY_IMG />,
            png: <img src={PNG_HIGH_PRIORITY_IMG} />,
            // selected: defaultAllChecked,
            text: '非常紧急',
        },
        {
            title: 'A2',
            code: 3,
            // img: <MIDDLE_PRIORITY_IMG />,
            png: <img src={PNG_MIDDLE_PRIORITY_IMG} />,
            // selected: defaultAllChecked,
            text: '紧急',
        },
        {
            title: 'B1',
            code: 2,
            // img: <NORMAL_PRIORITY_IMG />,
            png: <img src={PNG_NORMAL_PRIORITY_IMG} />,
            // selected: defaultAllChecked,
            text: '普通',
        },
        {
            title: 'B2',
            code: 1,
            // img: <LOW_PRIORITY_IMG />,
            png: <img src={PNG_LOW_PRIORITY_IMG} />,
            // selected: defaultAllChecked,
            text: '低',
        },
    ]);
    const [currentItem, setCurrentItem] = useState<any>();
    useMemo(() => {
        const item: any = imgArr.find((item) => item.code === type) || null;
        console.log(item, 'itemitemitemitem');
        setCurrentItem(item);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, from]);
    return currentItem ? currentItem?.png : null;
};
export default Tab;
