import { gatewayInstance } from '@/server/gateway';

// 创建通知
export async function createOrUpdateNotification(opt: {
    notificationId: string;
    content: string;
    sourceType: number;
    sourceBizId: string;
    status: number;
    messageId: string;
    optionList: [
        {
            optionId: string;
            optionContent: string;
            sort: number;
        }
    ];
    attachmentList: [
        {
            notificationId: string;
            fileName: string;
            fileType: string;
            fileId: string;
            fileUrl: string;
        }
    ];
    groupChatMessage: any;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.createOrUpdateNotification',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 查看历史记录
export async function pageNotificationList(opt: {
    sourceType: number;
    sourceBizId: string;
    offset: number;
    limit: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.pageNotificationList',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 查询通知详情
export async function queryNotificationDetail(opt: {
    notificationId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.queryNotificationDetail',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 用户响应通知
export async function RespondToNotificationReq(opt: {
    notificationId: string;
    optionId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.respondToNotification',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取未处理通知
 * @param opt
 * @returns
 */
export async function findGroupRelatedInfo(opt: {
    groupId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.findGroupRelatedInfo',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 删除一条通知
 * @param opt
 * @returns
 */
export async function deleteNotification(opt: {
    notificationId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.deleteNotification',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
