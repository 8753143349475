import Bus from '@/utils/bus';
import { UserCardAction, UserCardType } from '@/types/UserCard';
// import log from '@/utils/logger';
// const logger = log.getLogger('modal:app');

export interface UserCardState {
    type: UserCardType;
    visible: boolean;
    appId: string;
    userId: string;
    teamId: string;
    rootOrgId?: string;
    group?: any;
    actions?: UserCardAction[];
    applyInfos?: string[];
    currentUser?: {
        userId: string;
        teamId: string;
        appId: string;
        realName: string;
        station: string;
    };
    deptId?: string;
}

export interface ModalsState {
    userCard: UserCardState;
}

export interface UserCardPayload {
    type?: UserCardType;
    visible: boolean;
    appId: string;
    userId: string;
    teamId: string;
    rootOrgId?: string;
    group?: any;
    actions?: UserCardAction[];
    applyInfos?: string[];
    currentUser?: {
        userId: string;
        teamId: string;
        appId: string;
        realName: string;
        station: string;
    };
    deptId?: string;
    friendErrMsg?: string;
    isfriend?: number;
}

export interface AppState {
    rightLoadState: CompDataLoadState;
    rightPageType: DefaultPageType;
    modals: ModalsState;
    toggleUserCardModal: Function;
    updatePageLoadState: Function;
}

export enum DefaultPageType {
    NO_DATA = 'no_data', // 统一 无数据
    DARK_NO_DATA = 'dark_no_data', // 统一 无数据
    KEYWORD = 'keyword', // 搜索页 关键词
    GROUP = 'group', // 通讯录 我的群组
    CREATE_GROUP = 'create_group', // 通讯录  我创建的组
    JOIN_GROUP = 'join_group', // 通讯录  我加入的组
    SESSION = 'session', // 会话
    MESSAGE = 'message', // 消息
    AT = 'at', // at
    SEARCH_HISTORY = 'search_history', // 搜索聊天记录
    CHAT_HISTORY_FILE = 'chat_history_file', // 历史记录 没有文件
    CHAT_HISTORY_RECORD = 'chat_history_record', // 历史访录 聊天记录
    CHAT_HISTORY_IMG_VIDEO = 'chat_history_img_video', // 历史记录 图片视频
    CONTACT_ADMIN = 'contact_admin', // 联系管理员配置应用
    APP_SETTING = 'app_setting', // 请前往管理后台配置应用
    LOAD_FAILED = 'load_failed', // 加载失败
    LOW_VERSION = 'low_version', // 低版本
    CHECK_NETWORK_CONN = 'check_network_conn', // 网络连接失败
    EXTERNAL_CONTACT = 'external_contact', // 外部联系人
    NEW_CONTACT = 'new_contact', // 外部联系人
    HIDE_IMAGE = 'hide_image', // 空图片
    IMG_DEFAULT = 'img_default', // 只展示京办图片
    MY_DEPARTMENT = 'my_department', // 我的部门
    MY_LABELS = 'my_labels', // 我的标签
    NO_USERS_DATA = 'no_users_data',
    NODETA_DEPT = 'nodata_dept',
    ORG = 'org',
    REQUEST_FAIL = 'request_fail', // 请求接口失败
}

export enum CompDataLoadState {
    INIT = 'init', // 初始化
    LOADING = 'loading', // 加载中
    ERROR = 'error', // 调用数据接口失败
    NODATA = 'nodata', // 数据接口没有返回数据
    NORMAL = 'normal', // 正常显示
    NOUSERSDATA = 'NOUSERSDATA', // 通讯录大屏版 暂无人员、设备
    NODETA_DEPT = 'nodata_dept', // 大屏暂无部门数据
}

export default {
    namespace: 'app',
    state: {
        modals: {
            userCard: {
                type: UserCardType.User,
                visible: false,
                userId: '',
                teamId: '',
                appId: '',
                group: {},
                actions: [],
                applyInfos: [],
                currentUser: {},
            },
        },
        rightLoadState: CompDataLoadState.INIT,
        rightPageType: null,
    },
    reducers: {
        toggleUserCardModal(state: AppState, { payload }: { payload: UserCardPayload }) {
            const { modals } = state;
            if (!payload.visible) {
                payload.group = {};
                payload.teamId = '';
                payload.userId = '';
                payload.appId = '';
                payload.rootOrgId = undefined;
                payload.applyInfos = [];
                payload.actions = [];
                payload.deptId = '';
                payload.friendErrMsg = '';
            }
            const userCard = Object.assign(modals.userCard, payload);
            return { ...state, modals: { ...modals, userCard } };
        },
        updatePageLoadState(state: AppState, { payload }: any) {
            const { rightLoadState, rightPageType } = payload;
            return {
                ...state,
                rightLoadState,
                rightPageType,
            };
        },
    },
    subscriptions: {
        'app-event'({ dispatch }: any) {
            Bus.on('app:default-page-nodata', (rightPageType) => {
                const payload = {
                    rightLoadState: CompDataLoadState.NODATA,
                    rightPageType,
                };
                dispatch({ type: 'updatePageLoadState', payload: payload });
            });
            Bus.on('app:default-page-loading', () => {
                const payload = {
                    rightLoadState: CompDataLoadState.LOADING,
                    rightPageType: null,
                };
                dispatch({ type: 'updatePageLoadState', payload: payload });
            });
            Bus.on('app:default-page-init', () => {
                const payload = {
                    rightLoadState: CompDataLoadState.INIT,
                    rightPageType: null,
                };
                dispatch({ type: 'updatePageLoadState', payload: payload });
            });
            Bus.on('app:default-page-normal', () => {
                const payload = {
                    rightLoadState: CompDataLoadState.NORMAL,
                    rightPageType: null,
                };
                dispatch({ type: 'updatePageLoadState', payload: payload });
            });

            Bus.on('app:default-page-error', () => {
                const payload = {
                    rightLoadState: CompDataLoadState.ERROR,
                    rightPageType: DefaultPageType.LOAD_FAILED,
                };
                dispatch({ type: 'updatePageLoadState', payload: payload });
            });

            Bus.on('app:toggle-user-card-modal:show', (data) => {
                dispatch({ type: 'toggleUserCardModal', payload: data });
            });
            Bus.on('app:toggle-user-card-modal:hide', (data) => {
                dispatch({ type: 'toggleUserCardModal', payload: { visible: false } });
            });
        },
    },
};
