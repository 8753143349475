import { processJoyspaceUrl } from '@/components/Joyspace/winOpen';
import { judgeHttpCardLinkUrl } from '@/utils/tools';
import React, { useEffect, useRef, useState } from 'react';
import CopyClipboard from 'copy-to-clipboard';
import { Menu, message as toast, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import IconFont from '@/components/icon';
import Bus from '@/utils/bus';
import { ContentItem } from '@/components/chat/utils/message';
import './index.less';
import { getTextHighlightMessageContent } from '@/components/chat/utils/commonMessageModal';

interface IProps {
    shortLinkItem: ContentItem;
    isFusion?: boolean;
    parentRef?: any;
    searchKey?: string;
    curSearchMid?: any;
    curMessageMid?: any;
}
function ShortLink(props: IProps) {
    const { shortLinkItem, isFusion, parentRef, searchKey, curSearchMid, curMessageMid } = props;
    const { url, content } = shortLinkItem;
    const { t } = useTranslation('chat');
    const shortLinkRef = useRef(null);
    const [realUrl, setRealUrl] = useState(url || content);

    const [showCopy, setShowCopy] = useState(false); // 中间显示省略号
    const [width, setWidth] = useState(0);

    const [visible, setVisible] = useState(false); // 是否显示右键复制链接
    const [styles, setStyles] = useState({ left: 0, top: 0 }); // 右键弹出复制链接菜单操作位置

    useEffect(() => {
        if (realUrl && realUrl.indexOf('joyspace') > -1) {
            const joyspaceUrl = processJoyspaceUrl(realUrl as string);
            setRealUrl(joyspaceUrl);
        }
    }, [realUrl]);

    // useEffect(() => {
    //     // console.log('333');
    //     if (parentRef) {
    //         console.log('parentRef.current', parentRef);
    //         const parentNode = parentRef.current.parentNode;
    //         const parentNodeWidth = parentNode.offsetWidth;
    //         setWidth(parentNodeWidth);
    //         const ancestorNodeWidth = parentNode.parentNode.offsetWidth;
    //         console.log('宽度', parentNodeWidth, '祖先宽度', ancestorNodeWidth);
    //         if (parentNodeWidth < ancestorNodeWidth) {
    //             setShowCopy(false);
    //         } else {
    //             setShowCopy(true);
    //         }
    //     }
    // }, [parentRef]);

    // useEffect(() => {
    //     // console.log('222');
    //     let handelResize = () => {
    //         if (parentRef) {
    //             // console.log('parentRef.current', parentRef);
    //             const parentNode = parentRef.current.parentNode;
    //             const parentNodeWidth = parentNode.offsetWidth;
    //             const ancestorNodeWidth = parentNode.parentNode.offsetWidth;
    //             // console.log(
    //             //     '当前宽度',
    //             //     width,
    //             //     '父宽度',
    //             //     parentNodeWidth,
    //             //     '祖先宽度',
    //             //     ancestorNodeWidth
    //             // );
    //             if (parentNodeWidth < ancestorNodeWidth || width < parentNodeWidth) {
    //                 setShowCopy(false);
    //             }
    //             if (parentNodeWidth >= ancestorNodeWidth && !showCopy) {
    //                 setWidth(parentNodeWidth);
    //                 setShowCopy(true);
    //             }
    //         }
    //     };
    //     const throttleHandleResize = _.throttle(handelResize, 200);
    //     handelResize();
    //     window.addEventListener('resize', throttleHandleResize);
    //     return () => {
    //         window.removeEventListener('resize', throttleHandleResize);
    //     };
    // }, [parentRef, width, showCopy]);

    function handleClick(e: any) {
        e.stopPropagation();
        e.preventDefault();
        const extUrl = judgeHttpCardLinkUrl(realUrl as string);
        if (extUrl) {
            window.open(realUrl);
        } else {
            copyUrl(e, '地址已复制到剪切板，请在浏览器中打开。');
        }
        // handleVisible();
    }
    function copyUrl(e: any, msg: any) {
        e.stopPropagation();
        e.preventDefault();
        if (CopyClipboard(realUrl as string)) {
            toast.success(msg);
        } else {
            toast.error(t('copy-failed'));
        }
        setVisible(false);
    }
    // const getDropdownMenu = () => {
    //     return (
    //         <Menu>
    //             <Menu.Item>
    //                 <div className="operate-menu-item" onClick={(e) => copyUrl(e, '复制成功')}>
    //                     <IconFont className="icon" type="iconapp_btn_copy" />
    //                     <span>复制链接</span>
    //                 </div>
    //             </Menu.Item>
    //         </Menu>
    //     );
    // };

    // const visibleChange = (e: any) => {
    //     Bus.emit('contextmenu:short-link:hide');
    //     Bus.emit('contextmenu:message-operate:hide');
    //     e.stopPropagation();
    //     e.preventDefault();
    //     setStyles({ left: e.clientX, top: e.clientY + 10 });
    //     setVisible(true);
    // };
    // function handleVisible() {
    //     setVisible(false);
    // }

    // useEffect(() => {
    //     const cur: any = shortLinkRef.current;
    //     cur.addEventListener('contextmenu', visibleChange);
    //     Bus.on('contextmenu:short-link:hide', handleVisible);
    //     return () => {
    //         cur.removeEventListener('contextmenu', visibleChange);
    //         Bus.off('contextmenu:short-link:hide', handleVisible);
    //     };
    // }, [shortLinkRef]);
    const popoverContent = () => {
        return (
            <div className="short-link-popover-container">
                <span className="container_content">{realUrl}</span>
                <span className="container_operate" onClick={(e) => copyUrl(e, '复制成功')}>
                    <IconFont className="icon" type="iconapp_btn_copy" />
                    复制链接
                </span>
            </div>
        );
    };

    return (
        <div
            className={`short-link-box ${isFusion ? 'fusion' : ''}`}
            ref={shortLinkRef}
            onClick={handleClick}
        >
            <Popover
                content={popoverContent}
                // trigger="click"
                overlayClassName="short-link-popover"
                destroyTooltipOnHide={true}
                placement="bottomRight"
                // getPopupContainer={(trigger: any) => {
                //     return trigger.parentNode as HTMLElement;
                // }}
            >
                <div
                    className="short-link-box-content"
                    dangerouslySetInnerHTML={{
                        __html: getTextHighlightMessageContent({
                            keyWord: searchKey || '',
                            content: realUrl,
                            sMid: curSearchMid || -1,
                            mid: curMessageMid,
                        }),
                    }}
                />
            </Popover>
            {/* {showCopy && <span className="short-link-box-content copy">{realUrl}</span>} */}
            {/* {visible && (
                <div className="short-link-operate" style={styles}>
                    {getDropdownMenu()}
                </div>
            )} */}
        </div>
    );
}

export default ShortLink;
