import React, { useMemo, useState, useEffect, useReducer } from 'react';
import './index.less';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { IcBtnWorkMoreClick, IcBtnWorkMoreDefault } from '@/assets/svg';
import prompt from '@/baseComponents/ModalComponent/prompt';
import IconFont from '@/components/icon';

function TaskItemMoreBtn({
    onRemove,
    isHead,
    disable,
}: {
    onRemove: () => void;
    isHead: boolean;
    disable: boolean;
}) {
    const [hover, setHover] = useState(false);
    const [visible, setVisible] = useState(false);
    const [t] = useTranslation('work');
    const [t_common] = useTranslation('common');

    function handleDelTask() {
        setVisible(false);
        setHover(false);
        prompt({
            title: t(`${isHead ? 'delete task' : 'exit task'}`),
            // eslint-disable-next-line react/jsx-no-undef
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: () => {
                onRemove();
            },
            content: t(`${isHead ? 'delete task info' : 'exit task info'}`),
            okText: t_common('button.ok'),
            cancelText: t_common('button.cancel'),
        });
    }
    function handleHover() {
        setHover(true);
    }
    function handleMove() {
        if (visible) {
            return;
        }
        setHover(false);
    }
    function handleVisibleChange(visible: boolean) {
        setVisible(visible);
        if (!visible) {
            setHover(false);
        }
    }
    return (
        <Dropdown
            placement="bottomLeft"
            overlay={
                <Menu className="work-taskitem-menu">
                    <Menu.Item onClick={handleDelTask}>
                        {t(`${isHead ? 'delete task' : 'exit task'}`)}
                    </Menu.Item>
                </Menu>
            }
            className="work-detail-menu-dropdown"
            trigger={['click']}
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <div
                className={`taks-item-more-btn ${visible ? 'taks-item-more-btn-menuVisible' : ''}`}
                onMouseEnter={handleHover}
                onMouseLeave={handleMove}
            >
                {!disable && (hover ? <IcBtnWorkMoreClick /> : <IcBtnWorkMoreDefault />)}
            </div>
        </Dropdown>
    );
}

export default TaskItemMoreBtn;
