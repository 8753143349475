import React, { useState } from 'react';
import './index.less';
import { connect } from 'dva';
import { ChatMessage, SessionProps } from '@/types/chat';
import ChatState, { AITabState } from '@/types/chat/State';
import { Modal } from 'antd';
import { useHandleAITabClick } from '@/components/chat/Session/common';
import { useHistory } from 'react-router-dom';
import IconFont from '@/components/icon';
import cls from 'classnames';
import CHAT_NOTICE from '@/contant/chat';
import { duccConfigI18 } from '@/utils/i18n';

interface IProps {
    // 会话对象
    session: SessionProps & { pin: string };
    // 群组会话消息条数
    pieces?: string;
    // 是否有@
    cue?: boolean;
    index: number;
    launchAINoticeTabSession: Function;
    setCallBack: Function;
    changeSelectMore: Function;
    updateMessages: Function;
    selectedAITab: AITabState;
    onTabDelete: Function;
}

// eslint-disable-next-line complexity
function AINoticeTab(props: IProps) {
    // const [session, setSession] = useState<SessionProps | null>(null);
    const {
        session,
        launchAINoticeTabSession,
        setCallBack,
        changeSelectMore,
        updateMessages,
        selectedAITab,
        index,
        onTabDelete,
    } = props;
    const [hoverKey, setHoverKey] = useState<null | number>(null);
    const [visible, setVisible] = useState(false);
    const history = useHistory();

    // 点击选中某一条会话
    const handleClick = useHandleAITabClick({
        selectedAITab,
        session,
        launchAINoticeTabSession,
        setCallBack,
        changeSelectMore,
        updateMessages,
        history,
    });

    const handleMouseOver = (item: any, index: number) => {
        setHoverKey(index);
    };

    const deleteTab = () => {
        onTabDelete(session, index);
    };

    return (
        <div
            className={cls('notice-tab-item', 'p16', {
                'notice-tab-item-active': session.pin === selectedAITab.pin,
                'all-tab': session.pin === CHAT_NOTICE.ALL_PIN,
            })}
            key={index}
            onClick={handleClick}
            // onMouseOver={() => handleMouseOver(session, index)}
            id={session.pin}
        >
            <span>{session.pin.includes('joyspace') ? duccConfigI18.joyspace : session.name}</span>
        </div>
        // 暂时隐藏的删除按钮的逻辑（删除按钮影响操作）
        // <>
        //     {hoverKey === index && session.pin !== CHAT_NOTICE.ALL_PIN ? (
        //         <div
        //             className="notice-tab-item hover-item"
        //             onMouseLeave={() => setHoverKey(null)}
        //             key={index}
        //             id={session.pin}
        //         >
        //             <div className="tab-text" onClick={handleClick}>
        //                 {session.name}
        //             </div>
        //             <div className="tab-del-icon" onClick={() => setVisible(true)}>
        //                 <IconFont type="iconlog_del" />
        //             </div>
        //         </div>
        //     ) : (
        //         <div
        //             className={cls('notice-tab-item', 'p16', {
        //                 'notice-tab-item-active': session.pin === selectedAITab.pin,
        //                 'all-tab': session.pin === CHAT_NOTICE.ALL_PIN,
        //             })}
        //             key={index}
        //             onClick={handleClick}
        //             onMouseOver={() => handleMouseOver(session, index)}
        //             id={session.pin}
        //         >
        //             {session.name}
        //         </div>
        //     )}
        //     <Modal
        //         title="标签移除提醒"
        //         visible={visible}
        //         onOk={() => deleteTab()}
        //         onCancel={() => setVisible(false)}
        //         className="delete-confirm-modal"
        //         cancelText="取消"
        //         okText="确定"
        //         width="400px"
        //     >
        //         <h3 className="main-text">确定要移除当前标签吗？</h3>
        //         <p className="second-text">移除后，可在“编辑标签”中重新添加</p>
        //     </Modal>
        // </>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, sessionStatusMap, administrator, selectedAITab } = chat as ChatState;
    return {
        selectedSession,
        sessionStatusMap,
        administrator,
        selectedAITab,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        launchAINoticeTabSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchAINoticeTabSession', payload: data });
        },

        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AINoticeTab);
