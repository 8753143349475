/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import './index.less';
import { message } from 'antd';
import A1Img from '@/assets/img/taskType/A1.png';
import A1ActiveImg from '@/assets/img/taskType/A1Active.png';
import A2Img from '@/assets/img/taskType/A2.png';
import A2ActiveImg from '@/assets/img/taskType/A2Active.png';
import B1Img from '@/assets/img/taskType/B1.png';
import B1ActiveImg from '@/assets/img/taskType/B1Active.png';
import B2Img from '@/assets/img/taskType/B2.png';
import B2ActiveImg from '@/assets/img/taskType/B2Active.png';
import C2Img from '@/assets/img/taskType/C2.png';
import C2ActiveImg from '@/assets/img/taskType/C2Active.png';
import { changeEgovUrl } from '@/utils/tools';
import IconFont from '@/components/icon';
import HIGH_PRIORITY_IMG from '@/assets/img/priority/high.svg';
import MIDDLE_PRIORITY_IMG from '@/assets/img/priority/middle.svg';
import NORMAL_PRIORITY_IMG from '@/assets/img/priority/normal.svg';
import LOW_PRIORITY_IMG from '@/assets/img/priority/low.svg';
import PNG_HIGH_PRIORITY_IMG from '@/assets/img/priority/high.png';
import PNG_MIDDLE_PRIORITY_IMG from '@/assets/img/priority/middle.png';
import PNG_NORMAL_PRIORITY_IMG from '@/assets/img/priority/normal.png';
import PNG_LOW_PRIORITY_IMG from '@/assets/img/priority/low.png';
interface ItemType {
    title: string;
    code: number;
    img: any;
    png: any;
    selected: boolean;
    text: string;
}

function TypeSelect({
    priority,
    onRadioChange,
    onCheckboxChange,
    disable,
    type,
    min,
    customClass,
    defaultAllChecked = false,
    defaultChecked,
    hide,
    imgType = 'png',
}: {
    priority?: number;
    onRadioChange?: (priority: number) => void;
    onCheckboxChange?: (priority: number[]) => void;
    disable?: boolean;
    min?: number;
    type: string; // 单选radio 多选 checkbox
    customClass?: string;
    defaultChecked?: number[];
    defaultAllChecked?: boolean;
    sourceChangeType?: string;
    hide?: boolean;
    imgType?: string;
}) {
    console.info(HIGH_PRIORITY_IMG);
    // 任务类型
    const [arr, setArr] = useState<ItemType[]>([
        {
            title: 'A1',
            code: 4,
            img: <HIGH_PRIORITY_IMG />,
            png: <img src={PNG_HIGH_PRIORITY_IMG} />,
            selected: defaultAllChecked,
            text: '非常紧急',
        },
        {
            title: 'A2',
            code: 3,
            img: <MIDDLE_PRIORITY_IMG />,
            png: <img src={PNG_MIDDLE_PRIORITY_IMG} />,
            selected: defaultAllChecked,
            text: '紧急',
        },
        {
            title: 'B1',
            code: 2,
            img: <NORMAL_PRIORITY_IMG />,
            png: <img src={PNG_NORMAL_PRIORITY_IMG} />,
            selected: defaultAllChecked,
            text: '普通',
        },
        {
            title: 'B2',
            code: 1,
            img: <LOW_PRIORITY_IMG />,
            png: <img src={PNG_LOW_PRIORITY_IMG} />,
            selected: defaultAllChecked,
            text: '低',
        },
    ]);

    if (defaultChecked && defaultChecked.length > 0) {
        arr.forEach((item) => {
            if (defaultChecked.includes(item.code)) {
                item.selected = true;
            }
        });
    }

    const [radio, setRadio] = useState(priority);
    useEffect(() => {
        setRadio(priority);
    }, [priority]);

    function handleClick(item: ItemType) {
        if (disable) {
            return;
        }
        if (type === 'radio') {
            if (!onRadioChange) {
                return;
            }
            // 任务类型不能为空
            if (item.code === radio) {
                return;
            }
            setRadio(item.code);
            onRadioChange(item.code);
        } else {
            if (!onCheckboxChange) {
                return;
            }

            if (min && arr.filter((item) => item.selected).length <= min && item.selected) {
                message.warn(`至少选择${min}项`);
                return;
            }
            const targetList = arr.map((ele) => {
                if (item.code === ele.code) {
                    ele.selected = !item.selected;
                }
                return ele;
            });
            setArr(targetList);
            onCheckboxChange(arr.filter((item) => item.selected).map((ele) => ele.code));
        }
    }

    let isPng = imgType === 'png';
    return (
        <div>
            <ul className={`${customClass || 'tabs'}`}>
                {arr.map((item) => (
                    <li
                        key={item.code}
                        onClick={() => handleClick(item)}
                        style={{
                            cursor: disable ? '' : 'pointer',
                            display: hide && radio !== item.code ? 'none' : 'initial',
                        }}
                    >
                        <i
                            className={`${
                                (type === 'radio' && radio === item.code) ||
                                (type === 'checkbox' && item.selected)
                                    ? 'active'
                                    : ''
                            } type type-${item.code}`}
                            title={item.text}
                        >
                            <IconFont className="icon-check" type="iconic_check" />
                            {changeEgovUrl(isPng ? item.png : item.img)}
                        </i>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default TypeSelect;
