import React from 'react';
import IconFont from '@/components/icon';
import { Popover } from 'antd';
import './index.less';
import { AITabState } from '@/types/chat/State';

interface IProps {
    showControllerModal: Function;
    hiddenTabs: AITabState[];
    scrollToTargetTab: Function;
}

function TabControllerBtn({ showControllerModal, hiddenTabs, scrollToTargetTab }: IProps) {
    const content = (
        <div className="tab-propover-list">
            {hiddenTabs.map((item: AITabState, index: number) => (
                <div
                    className="tab-popover-item"
                    key={index}
                    onClick={() => scrollToTargetTab(item)}
                >
                    {item.name}
                </div>
            ))}
            <div
                className="controller-modal-btn tab-popover-item"
                onClick={() => showControllerModal()}
            >
                <IconFont type="iconic_pc_im_editor" style={{ marginRight: '6px' }} />
                编辑标签
            </div>
        </div>
    );

    return (
        <Popover
            content={content}
            overlayClassName="tab-controller-popover"
            placement="bottom"
            getPopupContainer={(trigger) => trigger as HTMLElement}
        >
            <div className="tab-list-controller">
                <IconFont type="icona-ic_pc_im_screen" />
            </div>
        </Popover>
    );
}

export default TabControllerBtn;
