import React, { useEffect, useState } from 'react';
import './previewImage/index.less';
import { getImgSrc, getPreviewImageUrl } from '@/utils/chat/index';
import Viewer from 'viewerjs';
import { Spin } from 'antd';
import IconFont from '@/components/icon';
import { LoadingOutlined } from '@ant-design/icons';
import { changeEgovUrl } from '@/utils/tools';
import { isGifImage } from '@/components/chat/utils/image';
interface PreviewProps {
    fileUrl: string;
    fileName: string;
    closeModal: () => void;
    isDownload?: boolean;
}

function ImagePreviewModal(props: PreviewProps) {
    const [viewer, setViewer] = useState<Viewer | null>(null);
    const [loading, setLoading] = useState(true);
    const downloadFile = (viewer: any) => {
        const a = document.createElement('a');
        document.body.append(a);
        a.download = props.fileName;
        a.href = getImgSrc(props.fileUrl);
        a.click();
    };

    const moveRange = (event: any, viewer: any) => {
        // const maxOffsetPercentage = 0.8;
        let viewerData = viewer.viewerData;
        let imageData = viewer.imageData;
        let maxOffsetHorizontal = viewerData.width - imageData.width;
        let maxOffsetVertical = viewerData.height - imageData.height;
        let detail = event.detail;
        let left = detail.x;
        let top = detail.y;
        let right = viewerData.width - (left + imageData.width);
        let bottom = viewerData.height - (top + imageData.height);
        // console.log('container', viewerData, imageData, detail)
        // console.log('move', left, top, right, bottom);
        if (
            // Move left
            (detail.x < detail.oldX && right > 0 && right > maxOffsetHorizontal) ||
            // Move right
            (detail.x > detail.oldX && left > 0 && left > maxOffsetHorizontal) ||
            // Move up 30代表上面toolbar的高度
            (detail.y < detail.oldY && bottom > 0 && bottom > maxOffsetVertical - 50) ||
            // Move down 42代表下面footer的高度
            (detail.y > detail.oldY && top > 0 && top > maxOffsetVertical - 42)
        ) {
            event.preventDefault();
        }
    };

    const zoomFn = (event: any, viewer: any) => {
        // console.log(event, viewer);
        const ratio = event.detail.ratio;
        // 若为滚轮滑动，则阻止默认事件，执行zoomTo方法，使其以图片中心缩放
        if (event.detail.originalEvent && event.detail.originalEvent.type === 'wheel') {
            event.preventDefault();
            viewer.zoomTo(ratio);
        }
        // console.log(ratio, 'ratio');
        // oneToOne提示按钮
        const oneToOne = document.getElementsByClassName('viewer-one-to-one')[0];
        if (ratio === 1) {
            oneToOne.classList.add('unClickable');
        } else {
            oneToOne.classList.remove('unClickable');
        }
    };

    useEffect(() => {
        const viewer = new Viewer(document.getElementById('media-box') as HTMLElement, {
            navbar: false, // 不显示图片列表
            button: false, // 不显示关闭按钮
            backdrop: false, // 不可点击modal关闭
            title: false,
            initialViewIndex: 0,
            maxZoomRatio: 2, // 最大放大到200%
            minZoomRatio: 0.1, // 最小缩放到10%
            keyboard: false,
            loop: false,
            zIndex: 2000,
            toolbar: {
                zoomIn: true,
                zoomOut: true,
                oneToOne: true,
                rotateRight: true,
                download: !props.isDownload
                    ? false
                    : function () {
                          downloadFile(viewer);
                      },
            },
            move: function (event) {
                moveRange(event, viewer);
            },
            zoom: function (event) {
                zoomFn(event, viewer);
            },
        });
        viewer.show();
        setViewer(viewer);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 500);
    }, []); // eslint-disable-line

    const closePreviewModal = () => {
        if (viewer) {
            viewer.destroy();
            props.closeModal();
        }
    };
    return (
        <div className="image-preview-window-modal">
            <div className="image-preview-window-modal-close" onClick={closePreviewModal}>
                <IconFont type="iconapp_btn_file_cancel" />
            </div>
            <div id="media-box">
                <img
                    alt={props.fileName}
                    style={{ display: 'none' }}
                    onLoad={() => setLoading(false)}
                    src={changeEgovUrl(
                        isGifImage(props.fileUrl)
                            ? getImgSrc(props.fileUrl)
                            : getPreviewImageUrl(props.fileUrl, true)
                    )}
                />
            </div>
            {loading && (
                <div className="image-preview-window-modal-loading">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default ImagePreviewModal;
