// 设置展开栏创建群组

import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { Employee, Session, SessionProps } from '@/types/chat';
import { isEqualEmployee } from '@/components/chat/utils/message';
import { isGroupAdmin, isGroupOwner } from '@/components/chat/utils/group';
import { SessionSettingsType } from '@/types/chat/enum';
// import useUserSelector from '@/components/chat/GroupUserSelector';
import GroupManager, {
    GroupSettingItem,
    SettingSwitch,
} from '@/components/chat/SessionSetting/GroupManager';
import Context from '@/components/chat/context';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { teamCreates, spaceCreate, groupCreates } from '@/components/Joyspace/api';
import { message } from 'antd';
import { FocusSDK } from '@/utils';
import { isAINoticeSession } from '../utils/session';
interface Props {
    selectedSession: SessionProps;
    openSetup: boolean;
    sessionMembers: Partial<Employee>[];
    newSession: any;
}

type IProps = Readonly<Props>;

function CreateGroup(props: IProps) {
    const { selectedSession } = props;
    const { currentEmployee } = React.useContext(Context);
    const { t } = useTranslation('chat');

    // const createGroup = useUserSelector({
    //     selectedSession,
    //     currentEmployee,
    //     groupRosters: [],
    //     sessionMembers,
    //     userData,
    // });

    if (selectedSession.isSingle && !selectedSession.isNotice) {
        // 自聊
        const info = selectedSession.info as Employee;
        if (isEqualEmployee(info, currentEmployee)) {
            return null;
        }
        // 2020-09-22 关闭非一租户不可以创建群聊，支持外部群 跨平台群
        // // 不是同一租户
        // if (!isEqualTeam(info, currentEmployee)) {
        //     return null;
        // }
        // return (
        //     <div
        //         className="create"
        //         onClick={createGroup}
        //         clstag="pageclick|keycount|focus_chat_01_1615797500283|71"
        //     >
        //         <IconFont type="iconapp_btn_more" className="create_img" />
        //         <button>{t('create_group')}</button>
        //     </div>
        // );
    }

    return null;
}

function GroupManagerEntry(props: IProps) {
    const { selectedSession, openSetup, sessionMembers } = props;
    const [groupManagerVisible, setGroupManagerVisible] = useState(false);
    const { t } = useTranslation('chat');

    useEffect(() => {
        if (!openSetup) {
            setGroupManagerVisible(false);
        }
    }, [openSetup]);

    if (!selectedSession.isGroup) {
        return null;
    }

    return (
        <div>
            {/* 群管理入口 */}
            <GroupSettingItem
                title={t('group_management')}
                onClick={() => {
                    setGroupManagerVisible(true);
                }}
                clstag="pageclick|keycount|Xtbg_Msg_Setting|GroupManageMent"
            >
                <IconFont
                    type="iconjs_ilist_arrowright"
                    className="group-management-icon-arrow"
                    onClick={() => {
                        setGroupManagerVisible(true);
                    }}
                    clstag="pageclick|keycount|Xtbg_Msg_Setting|GroupManageMent"
                />
            </GroupSettingItem>
            {/* 弹出群管理页签 */}
            <GroupManager
                selectedSession={selectedSession}
                sessionMembers={sessionMembers}
                visible={groupManagerVisible}
                onClose={() => {
                    setGroupManagerVisible(false);
                }}
            />
        </div>
    );
}

/**
 * 会话设置主入口
 * @param props
 * @constructor
 */
function SessionSetting(props: IProps) {
    const { selectedSession, newSession } = props;
    const { groupRosterIdentity, currentEmployee, groupRosters } = React.useContext(Context);
    const { appRuntimeEnv } = React.useContext<GlobalContextConfig>(GlobalContext);
    const { t } = useTranslation('chat');
    const owenr = (selectedSession as any)?.info || {};
    const isUserSelf =
        currentEmployee.teamId === owenr.teamId && currentEmployee.userId === owenr.userId;
    const isGroup = selectedSession.isGroup;

    // console.log(
    //     'owenrowenrowenrowenr',
    //     newSession?.sessionId,
    //     newSession?.settings,
    //     selectedSession.sessionId,
    //     selectedSession?.settings
    // );
    const launchGroupSpace = useCallback(async () => {
        console.log('组空间点击');
        try {
            let gid = owenr.gid;
            let gname = owenr.name;
            let gavatar = owenr.avatar;
            let gownerId = owenr.owner?.pin;
            let description = owenr.intro;
            let admins: any = [];
            for (let item of groupRosters) {
                if (item.identity === 'administrator' || item.identity === 'sub_administrator') {
                    admins.push((item as any).info.userId);
                }
            }
            let result = await groupCreates({
                admins,
                folderId: gid,
                // team_id: gid,
                name: gname,
                // avatar_url: gavatar,
                // adminUid: gownerId,
                // type: 1,
                description,
                parent: 'groupRoot',
            });
            if (result?.errMsg) {
                throw t('create_group_team_space_error');
            }
            // let spaceResult = await spaceCreate({
            //     team_id: gid,
            //     name: gname + '的空间',
            //     avatar_url: gavatar,
            //     admin_uid: gownerId,
            //     space_id: gid,
            //     description,
            //     type: 1,
            // });
            if (result?.errMsg) {
                throw t('create_group_team_space_error');
            }
            FocusSDK.printLog('云空间', '云空间按钮点击--start');
            // window.open(
            //     'http://desktop.jdcloud.com:7002/teamspace/1080000731?category=1080000731&type=groupRoot',
            //     '_blank'
            // );
            const { accessToken = '', selectedTeamId = '' } =
                appRuntimeEnv?.registryConfig?.auth || {};
            const client = appRuntimeEnv?.registryConfig?.client || '';
            const openUrl = `${appRuntimeEnv.joyspaceHost}/teamspace/${gid}?category=${gid}&type=groupRoot&focus-token=${accessToken}&focus-team-id=${selectedTeamId}&focus-client=${client}`;
            window.open(openUrl, '_blank');
            FocusSDK.printLog('云空间', '云空间按钮点击--end');
        } catch (e) {
            FocusSDK.printLog('云空间--error', e.toString());
            e && message.error(e);
        }
    }, [appRuntimeEnv, owenr, t, groupRosters]);

    return (
        <div className="group-settings">
            {/* 创建群组 */}
            {/* <CreateGroup {...props} /> */}
            {/* 置顶 */}
            <GroupSettingItem title={t('add_to_top')}>
                <SettingSwitch
                    selectedSession={selectedSession}
                    type={SessionSettingsType.TOP}
                    clsTag={[
                        `pageclick|keycount|${
                            isAINoticeSession(selectedSession) ? 'Xtbg_Msg_AI' : 'Xtbg_Msg_Setting'
                        }|SetTop_True`,
                        `pageclick|keycount|${
                            isAINoticeSession(selectedSession) ? 'Xtbg_Msg_AI' : 'Xtbg_Msg_Setting'
                        }|SetTop_False`,
                    ]}
                />
            </GroupSettingItem>
            {/* 消息提醒 */}
            {!isUserSelf && (
                <GroupSettingItem title={t('message_remind')}>
                    <SettingSwitch
                        selectedSession={
                            newSession?.sessionId !== selectedSession.sessionId
                                ? selectedSession
                                : newSession
                        }
                        type={SessionSettingsType.SHIELD}
                        clsTag={[
                            `pageclick|keycount|${
                                isAINoticeSession(selectedSession)
                                    ? 'Xtbg_Msg_AI'
                                    : 'Xtbg_Msg_Setting'
                            }|SetRemind_True`,
                            `pageclick|keycount|${
                                isAINoticeSession(selectedSession)
                                    ? 'Xtbg_Msg_AI'
                                    : 'Xtbg_Msg_Setting'
                            }|SetRemind_False`,
                        ]}
                    />
                </GroupSettingItem>
            )}
            {/* 群管理入口 */}
            {(isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity)) && (
                <GroupManagerEntry {...props} />
            )}
            {isGroup && (
                <GroupSettingItem
                    title={t('group_team_space')}
                    onClick={launchGroupSpace}
                    clstag="pageclick|keycount|Xtbg_Msg_Setting|GroupSpace"
                >
                    <IconFont
                        type="iconjs_ilist_arrowright"
                        className="group-team-space-icon-arrow"
                        clstag="pageclick|keycount|Xtbg_Msg_Setting|GroupSpace"
                    />
                </GroupSettingItem>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionSetting);
