import { gatewayInstance } from '../server/gateway';
import { FocusSDK, getAppId, isFocusEnv } from '@/utils';
import { Dayjs } from 'dayjs';
import cookies from 'js-cookie';
import { changeGovUrl } from '@/utils/tools';
// const gatewayInstance = new Gateway();
// 日程提醒设置

export const remindText: { [propName: string]: any } = {
    '0': '不提醒',
    '-0': '开始时提醒',
    '-5': '提前5分钟通知',
    '-10': '提前10分钟通知',
    '-15': '提前15分钟通知',
};

export enum RemindMode {
    No = '0',
    Start0 = '-0',
    Start5 = '-5', // 开始前5分钟
    Start10 = '-10',
    Start15 = '-15',
}
// 日程阅读状态
export enum ResStatus {
    Unread = 0,
    Agree = 1,
    Reject = 2,
}

// 日程重复方式
export enum RepeatMode {
    No = 0,
    WorkDay,
    EveryDay,
    EveryWeek,
    EveryTwoWeeks,
    EveryMonth,
    EveryYear,
}

// 日程来源
export enum ScheduleSource {
    Create = 1, // 自建
    Email, // 邮件
    DongDong, // 咚咚
}

export enum ScheduleDeleteType {
    All = 1,
    Some = 2,
    FromNow = 3,
    RepeatAll = 5,
}

export enum Canceled {
    Delete = 0, // 消除
    Normal, // 正常
    Single, // 回源单一
    Repeat, // 回源重复
}
export enum ModifyType {
    // 变更方式 1. 重复-只更新单条 2. 重复-从当前时间更新 3. 更新（不重复的更新，由不重复到重复的更新） 4. 仅支持时间更新
    RepeatOne = 1,
    RepeatFromNow = 2,
    Update = 3,
    TimeOnly = 4,
}

// 公开范围
export enum Scope {
    Private = 1,
    Busy,
    Public,
}

// 创建的公共日历  在添加共享人时 access字段不用设置
// 个人日历在添加共享人时 需要配置access字段，只配置 2，3
export enum Access {
    // 1. 公开 2. 可查看详情 3. 只显示忙碌'
    Public = 1,
    DetailAllow,
    OnlyBusy,
}

// User Type
export enum UserType {
    Creator = 1, // 创建者
    PersonalSubscriber, // 个人日历订阅者
    PersonalSubscribed, // 个人日历被动订阅
    TeamManager, // 团队日历管理者
    TeamEditor, // 团队日历编辑者
    TeamSubscriber, // 团队日历订阅者
}

// Agent
export enum Agent {
    Default = 0,
    AgentAllowed = 1,
}
// 视图模式
export enum ViewMode {
    DayView = 1,
    ListView,
}

// 日历类型
export enum CalendarType {
    PersonalType = 1, // 个人日历
    PublicType, // 公开日历
    AllType, // 全员
}

/**
 * 日程与登录用户关系
 */
export enum ScheduleRelatedType {
    Created = 1, // 我创建的
    Participate = 2, // 我参与的
    Subscribe = 3, // 我订阅的
}

// 备注业务类型
export enum BizType {
    calendar = 'CALENDAR', // 日历
}

// 用户
export interface User {
    teamId: string;
    userId: string;
    tenantCode?: string;
    account?: string;
    imageUrl?: string;
    realName?: string;
    ddAppId?: string;
    userType?: UserType;
    agent?: Agent;
}
// 参与人
export interface Participant {
    user: User;
    resStatus: number;
}

// 附件
export interface Attachment {
    fileId: string;
    fileName: string;
    fileType: string;
    fileUrl: string;
}

// 订阅人信息
export interface Subscriber {
    user: User;
    access: number;
    userType: number;
}

// 日程
export interface Schedule {
    scheduleId?: string;
    repeatScheduleId?: string;
    title: string;
    beginTime: number;
    endTime: number;
    user: User;
    ownerUser?: User;
    ownerUserId?: string;
    remind: RemindMode;
    color: string;
    repeat: RepeatMode;
    repeatBeginTime: number;
    repeatEndTime: number;
    description: string;
    address: string;
    isAlldate: number;
    isAcross: number;
    groupId: string;
    calendarId: string;
    participant: Participant[] | null;
    attachment: Attachment[] | null;
    meetingMinutes: Attachment[] | null;
    content: string;
    calendarInfo?: CalendarInfo;
    scheduleBeginTime: Dayjs;
    scheduleEndTime: Dayjs;
    isBusy?: boolean;
    archive?: number; // 0 已归档  其他为未归档，包括null
    agent?: number; // 是否为代建日程
    comeFrom: number; // 1.自己创建的2.被动订阅而来的3.主动订阅而来的4.作为参与人而来5.节日日程
    realName: string;
}
// 日程 （含 日程与登录用户关系）
export interface ScheduleRelated extends Schedule {
    relateType: number;
}

// 分享接收人信息
export interface ShareRecipientDto {
    recipientType: string; // 接收人类型 1.个人 2.群组
    recipientId: string; // 接收人Id，个人即为用户Id，群组则为群id
    recipientName: string; // 接收人名称
    teamId?: string;
    app: string;
}

// 日历
export interface CalendarInfo {
    checked: boolean;
    calendarId: string;
    title: string;
    scope: Scope;
    view?: ViewMode;
    type: CalendarType;
    description?: string;
    color: string;
    userType: number;
    access: number;
    unsubscribe?: number;
    diaphaneity?: string;
    subscriber: any[];
    createTime?: number;
    user?: any;
    extended?: any;
    agent?: number;
}

// 备注
export interface Remark {
    bizId: string;
    bizType: BizType;
    content: string;
    remarkId?: string;
}

export function getErrMessage(str: string) {
    return str.substr(str.indexOf(']') + 1);
}

/**
 *
 * 获取用户日程列表 updateTime 表示查询增量，不传就是查所有
 * @param {GetScheduleListProps} opts
 * @param {User} opts.user.teamId
 * @returns
 */
export async function getScheduleList(opts: {
    calendarId?: string;
    beginDate: number;
    endDate: number;
    color?: string;
    updateTime?: number;
}) {
    try {
        return gatewayInstance.request({
            api: 'calendar.getNewScheduleList',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取日程列表报错');
    }
}

/**
 *
 * 获取用户日程列表 可以根据用户信息获取 也可以根据 日历id获取，两者是互斥的。
 * 基于 getScheduleList 而来，区别在于增加了 日程与登录用户关系，其余完全相同
 * 由于上面的接口调用频率太过频繁，便给了新的接口
 * 日程与登录用户关系 1：我创建的 2：我参与的 3：我订阅的
 * @param {GetScheduleListProps} opts
 * @param {User} opts.user.teamId
 * @returns
 */
export async function getRelatedScheduleList(opts: {
    user?: User;
    calendarId?: string;
    beginDate: number;
    endDate: number;
}) {
    try {
        return gatewayInstance.request({
            api: 'getRelatedScheduleList',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取日程列表报错');
    }
}

/**
 *
 * 获取日程详情简略信息
 * @param {string} scheduleId
 * @returns
 */
export async function getScheduleInfoByScheduleId(opts: { scheduleId: string }) {
    try {
        return gatewayInstance.request({
            api: 'getScheduleInfoByScheduleId',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取日程详情简略信息报错');
    }
}

/**
 *
 *
 * @export
 * @param {*} opts
 * @returns
 */
export async function getScheduleInfo(opts: { scheduleId: string }): Promise<any> {
    try {
        return gatewayInstance.request({
            api: 'calendar.getNewScheduleInfo',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取日程信息报错');
    }
}

export async function askSchedule(opts: {
    scheduleId: string;
    user: any;
    resStatus: ResStatus;
    beforeBeginTime: number;
    beforeEndTime: number;
    message: string;
    resType: number;
}) {
    try {
        return gatewayInstance.request({
            api: 'calendar.askNewSchedule',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, 'askSchedule');
    }
}

/**
 *
 * 变更日程
 * @param {string} scheduleId
 * @returns
 */
export async function modifySchedule(opts: Schedule) {
    // return gatewayInstance.request({
    //     api: 'modifySchedule',
    //     method: 'POST',
    //     body: opts,
    // });
    try {
        const data = await gatewayInstance.request({
            api: 'calendar.modifyNewSchedule',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 *
 * 添加日程
 * @param {Schedule} schedule
 * @returns
 */
export async function addSchedule(opts: Schedule) {
    // return gatewayInstance.request({
    //     api: 'addSchedule',
    //     method: 'POST',
    //     body: opts,
    // });
    try {
        if (opts.attachment && opts.attachment.length) {
            let attachment = opts.attachment;
            opts.attachment = [];
            attachment.forEach((newItem: any) => {
                if (newItem.fileUrl) {
                    newItem.fileUrl = changeGovUrl(newItem.fileUrl);
                }
                opts.attachment?.push(newItem);
            });
        }
        const data = await gatewayInstance.request({
            api: 'calendar.addNewSchedule',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

export async function removeSchedule(opts: {
    scheduleId: string;
    beginTime: number;
    endTime: number;
    optType: ScheduleDeleteType;
    source?: number;
    delGroup?: boolean;
}) {
    try {
        return gatewayInstance.request({
            api: 'calendar.removeNewSchedule',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '删除日程接口报错');
    }
}

export async function removeParticipant(opts: { scheduleId: string; user: User }) {
    try {
        return gatewayInstance.request({
            api: 'removeParticipant',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '删除日程接口报错');
    }
}

/**
 *
 * 获取我的日历列表
 * @param {Schedule} schedule
 * @returns
 */
// 为所有的接口添加try-catch 20210403
/*
    解决浏览器报错
    复现场景：快速切换登录账号，因为无异常处理导致调取该接口报错和此逻辑下面的代码为正常运行
    uncaught at _callee3 at _callee3
    at _callee6 
    at takeEvery(calendar/getCalendarList, sagaWithCatch) 
    at sagaWithCatch 
    Error: Error: 无效token
*/
export async function getCalendarList(body = {}) {
    try {
        return gatewayInstance
            .request({
                api: 'calendar.getCalendarList',
                method: 'POST',
                body,
                headers: {
                    'force-x-client': 'IPAD', // 强制设置x-client
                },
            })
            .then((res) => res)
            .catch((err) => {
                console.log('获取日历接口报错', err);
            });
    } catch (error) {
        console.log(error, '获取日历接口报错');
        throw error;
    }
}

/**
 *
 * 通过日历ID获取日历列表
 * @export
 * @param {{calendarIds: string[]}} body
 * @returns
 */
export async function getMultipleCalendar(body: { calendarIds: string[] }) {
    try {
        return gatewayInstance
            .request({
                api: 'calendar.getMultipleCalendar',
                method: 'POST',
                body,
                headers: {
                    'force-x-client': 'IPAD', // 强制设置x-client
                },
            })
            .then((res) => res)
            .catch((err) => {
                console.log(err);
                return {};
            });
    } catch (error) {
        console.log(error, '通过日历ID获取日历列表报错');
    }
}

/**
 * 该接口的作用有两个
 * 1：根据calendarId获取日历详情
 * 2:根据用户信息获取日历信息，当不存在个人日历时会创建
 *
 * @export
 * @param {*} [body={}]
 * @returns
 */
export async function getCalendar(body: { calendarId?: string; user?: any } = {}) {
    try {
        return gatewayInstance
            .request({
                api: 'getCalendar',
                method: 'POST',
                body,
                headers: {
                    'force-x-client': 'IPAD', // 强制设置x-client
                },
            })
            .then((res) => res)
            .catch((err) => {
                console.log(err);
                return {};
            });
    } catch (error) {
        console.log(error, '获取日历信息报错');
    }
}

/**
 *
 * 获取我的订阅日历列表
 * @param {Schedule} schedule
 * @returns
 */
export async function getSubscriptionCalendarList() {
    try {
        return gatewayInstance.request({
            api: 'getSubscriptionCalendarList',
            method: 'POST',
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取我的订阅日历列表报错');
    }
}

/**
 *
 * 获取可搜索的公共日历列表
 * @param {Schedule} schedule
 * @returns
 */
export async function getPublicCalendarListForSearch(body: {
    title?: string;
    pageSize: number;
    pageNumber: number;
}) {
    try {
        return gatewayInstance.request({
            api: 'calendar.getPublicCalendarListForSearch',
            method: 'POST',
            body,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取可搜索的公共日历列表报错');
    }
}

/**
 *
 * 获取可搜索的所有日历列表,目前用于查询公共日历
 * @param {Schedule} schedule
 * @returns
 */
export async function queryCalendarList(body: {
    title?: string;
    pageSize: number;
    pageNumber: number;
}) {
    try {
        return gatewayInstance.request({
            api: 'calendar.queryCalendarList',
            method: 'POST',
            body,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '获取可搜索的公共日历列表报错');
    }
}

/**
 *
 * 添加团队日历
 * @param {Schedule} schedule
 * @returns
 */
export async function addCalendar(opts: any) {
    try {
        return gatewayInstance.request({
            api: 'addCalendar',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '添加团队日历报错');
    }
}

/**
 *
 * 修改日历
 * @param {Schedule} schedule
 * @returns
 */
export async function modifyCalendar(opts: any) {
    // return gatewayInstance.request({
    //     api: 'modifyCalendar',
    //     method: 'POST',
    //     body: opts,
    // });
    try {
        const data = await gatewayInstance.request({
            api: 'modifyCalendar',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 *
 * 删除日历
 * @export
 * @param {{ calendarId?: string }} opts
 * @returns
 */
export async function removeCalendar(opts: { calendarId?: string }) {
    try {
        return gatewayInstance.request({
            api: 'calendar.removeCalendar',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '删除日历报错');
    }
}

/**
 *
 * 用户订阅
 * @param {Schedule} schedule
 * @returns
 */
export async function addSubscriber(opts: { user?: User; calendarId?: string }) {
    try {
        return gatewayInstance.request({
            api: 'addSubscriber',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '用户订阅报错');
    }
}

/**
 *
 * 取消订阅
 * @param opts
 * @returns
 */
export async function cancelSubscriber(opts: { user?: User; calendarId?: string }) {
    try {
        return gatewayInstance.request({
            api: 'cancelSubscriber',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '取消订阅报错');
    }
}

/**
 * 搜索用户
 *
 * @export
 * @param {*} opts
 * @returns
 */
export async function searchGlobal(opts: any) {
    const isFocus = isFocusEnv();
    try {
        return gatewayInstance.request({
            api: 'search.searchGlobal',
            method: 'POST',
            body: {
                appId: getAppId(), // isFocus ? process.env.REACT_APP_DESKTOP_APPID : process.env.REACT_APP_WEB_APPID,
                appKey: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
                ...opts,
            },
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '搜索用户报错');
    }
}

/**
 * 该接口的作用有两个
 * 1：根据calendarId获取日历详情
 * 2:根据用户信息获取日历信息，当不存在个人日历时会创建
 *
 * @export
 * @param {*} [body={}]
 * @returns
 */
export async function getCalendarInfo(
    body: { calendarId?: string; user?: any } = {}
): Promise<[CalendarInfo | null, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'getCalendar',
            method: 'POST',
            body,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * @deprecated 发送消息的实现已挪到后端，该函数返回为空
 */
export async function sendCalendarCard(
    body: {
        toUser: { app: string; pin: string; teamId: string }[];
        deepLink: string;
        languageContents: { language: string; title: string; content: string }[];
    } = {} as any
): Promise<[CalendarInfo | null, string | null]> {
    // try {
    //     const data: any = await gatewayInstance.request({
    //         api: 'sendMessage',
    //         method: 'POST',
    //         body,
    //     });
    //     return [data, null];
    // } catch (error) {
    //     return [null, error.message.toString()];
    // }
    return [null, '不再发送消息'];
}

/**
 * @description 导出日程列表
 */
export async function exportScheduleList(opts: {
    user?: User;
    calendarId?: string;
    beginDate: number;
    endDate: number;
    color: string;
}) {
    try {
        return gatewayInstance.request({
            api: 'calendar.getScheduleListStream',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
    } catch (error) {
        console.log(error, '导出日程列表报错');
    }
}

/**
 *
 * 添加日程备注
 * @param {Remark} remark
 * @returns
 */
export async function saveRemark(opts: Remark) {
    try {
        const data = await gatewayInstance.request({
            api: 'remark.save',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 *
 * 添加会议纪要
 * @param {any} meetingMinutes
 * @returns
 */
export async function createMeetingMinutes(opts: any) {
    try {
        const data = await gatewayInstance.request({
            api: 'calendar.createMeetingMinutes',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 *
 * 分享日程同时赋予文档权限
 * @param {any} opts
 * @returns
 */
export async function shareScheduleAttachment(opts: {
    bizId: string; // 日程或任务id
    bizType: number; // 业务类型：1.任务 2.日程
    title?: string; // 标题 接口本想把消息推送逻辑也接了但是现在还没，所以这个暂时无用
    content?: string; // 内容，对分享的描述信息 接口本想把消息推送逻辑也接了但是现在还没，所以这个暂时无用
    priorityType?: number; // 优先级类型 1低 2普通 3紧急 4非常紧急 接口本想把消息推送逻辑也接了但是现在还没，所以这个暂时无用
    shareRecipientDtos: ShareRecipientDto[];
}): Promise<[any | null, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.share',
            method: 'POST',
            body: opts,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

export interface CreateGroupType {
    scheduleId: string;
    from: {
        app: string;
        pin: string;
        clientType?: string;
        teamId: string;
        userId: string;
    };
    body: {
        modifyFiled: number;
        nickName: string;
        name: string;
        notice: string;
        gid: string;
        invitees: [
            {
                pin: string;
                app: string;
                nickName: string;
                teamId: string;
                userId: string;
            }
        ];
    };
    kind: number;
    subKind: number;
}

export async function createGroup(params: CreateGroupType) {
    try {
        const data = await gatewayInstance.request({
            api: 'createGroup',
            method: 'POST',
            body: params,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}
export interface AddParticipantType {
    scheduleId: string;
    user: {
        teamId: string;
        userId: string;
        tenantCode?: string;
        account?: string;
        imageUrl?: string;
        realName: string;
        ddAppId?: string;
        userType?: string;
        labelInfoList?: [];
    };
    acceptFlag: boolean;
}
export async function addParticipant(params: AddParticipantType) {
    try {
        const data = await gatewayInstance.request({
            api: 'addParticipant',
            method: 'POST',
            body: params,
            headers: {
                'force-x-client': 'IPAD', // 强制设置x-client
            },
        });

        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}
