/**
 * 声音消息组件
 */
import React, { useState } from 'react';
import { MessageSendType } from '@/types/chat/enum';
import './index.less';
import { VoiceChatMessage } from '@/types/chat';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getFileUrl } from '@/utils/chat/index';
import getCurVoiceHandler, { VoiceStatus } from '@/baseComponents/Chat/handler/voiceHandler';
import classNames from 'classnames';
import { IcBtnVoice } from '@/assets/svg';
import { connect } from 'dvajs';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { ChatConnectState } from '@/types/chat/State';
interface VoiceProps {
    message: VoiceChatMessage;
    sendType: MessageSendType;
}

interface DvaProps {
    connectState: ChatConnectState;
}

type IVoiceProps = Readonly<VoiceProps & DvaProps & WithTranslation>;

function Voice({ message, sendType, connectState }: IVoiceProps) {
    const [voiceStatus, setVoiceStatus] = useState(VoiceStatus.PLAY);

    function handleClickVoice(curMsg: VoiceChatMessage) {
        if (connectState && connectState.state === ConnectState.READY) {
            const { id, url } = curMsg;
            const src = getFileUrl(url);
            const voiceHandler = getCurVoiceHandler(id, src, {
                afterPlay: () => {
                    // console.log('afterPlay');
                    setVoiceStatus(VoiceStatus.STOP);
                },
                afterStop: () => {
                    // console.log('afterStop');
                    setVoiceStatus(VoiceStatus.PLAY);
                },
            });
            console.log('voiceHandler', voiceHandler);
            // 绑定事件
            // voiceHandler?.voiceAmr?.onPlay(function () {
            //     console.log('event play');
            //     setVoiceStatus(VoiceStatus.STOP);
            // });
            // voiceHandler?.voiceAmr?.onStop(function () {
            //     console.log('event stop');
            //     setVoiceStatus(VoiceStatus.PLAY);
            // });
        }
    }
    function getClass() {
        return classNames({
            'voice-box': true,
            owner: sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF,
        });
    }

    function getVoiceStatus() {
        if (voiceStatus === VoiceStatus.STOP) {
            return <div className="bg voicePlay" />;
        }

        if (voiceStatus === VoiceStatus.PLAY) {
            return <IcBtnVoice />;
        }
    }
    return (
        <div className={getClass()} data-id={message.id} onClick={() => handleClickVoice(message)}>
            <div className="icon">{getVoiceStatus()}</div>
            <div className="time">{message.duration}&quot;</div>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    return {
        connectState: chat.connectState,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(Voice));
