import React, { useCallback, useEffect, useState } from 'react';
import { Button, List, message, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'dvajs';
import { ListItem, Loading } from '@jd/focus-desktop-comps';
import { openUserCardModal } from '../../AddressBook/common';
import { debounce, trim as lodashTrim } from 'lodash';
import IconFont, { IconSearch } from '../../icon';
import './index.less';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { searchContact } from '@/api/search';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { getDeptPositionDesc } from '@/models/user';
import { changeEgovUrl } from '@/utils/tools';

const pageSize = 50;
const pageNo = 1;

function ContactModal({
    createModalVisible,
    openCreateContactModal,
    createModalInputValue,
    setCreateModalInputValue,
}: {
    createModalVisible: boolean;
    openCreateContactModal: Function;
    createModalInputValue: string;
    setCreateModalInputValue: Function;
}) {
    const { t } = useTranslation('common');
    const [contactList, setContactList] = useState<ListItemData[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(createModalInputValue);
    function cancelHandler() {
        openCreateContactModal({ visible: false });
    }
    useEffect(() => {
        if (!createModalVisible) {
            setSearchValue('');
            setContactList(null);
        }
        if (searchValue) {
            setCreateModalInputValue('');
        }
    }, [createModalVisible, searchValue, setCreateModalInputValue]);

    const handleSearch = useCallback(() => {
        if (!searchValue) {
            return;
        }
        setLoading(true);
        setContactList(null);
        searchContact({
            keyword: lodashTrim(searchValue),
            pageNo: pageNo,
            pageSize: pageSize,
        })
            .then((res: any) => {
                if (!res || !res?.contactList) {
                    setContactList([]);
                    return;
                }
                setContactList((origin) => {
                    return (origin || []).concat(
                        // eslint-disable-next-line max-nested-callbacks
                        (res.contactList || []).map((item: any) => {
                            return {
                                ...item,
                                id: item?.titleId,
                                name: item?.realName,
                                avatar: item?.avatar,
                                desc: getDeptPositionDesc(
                                    item?.deptName || '',
                                    item?.titleName || ''
                                ),
                                isLeader: item?.isleader,
                                labelInfoList: item?.labelInfoList || [],
                            };
                        })
                    );
                });
            })
            .catch((e) => {
                message.error({
                    content: `${e}`,
                });
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (createModalInputValue) {
            setSearchValue(createModalInputValue);
            handleSearch();
        }
    }, [createModalInputValue, handleSearch]);

    return (
        <Modal
            title={t('createButton.addContact')}
            width="480px"
            bodyStyle={{ width: 480, maxHeight: 340, padding: 24, minHeight: 46 }}
            visible={createModalVisible}
            footer={null}
            onCancel={cancelHandler}
            centered={true}
        >
            <div
                className="addContact-wrap"
                style={{ minHeight: contactList && contactList.length > 0 ? 'auto' : '72px' }}
            >
                <div className="addContactSearchInput ">
                    <div className="search-contact-container-input">
                        <Input
                            prefix={
                                <IconFont type="iconapp_ic_search" style={{ color: '#848484' }} />
                            }
                            placeholder={t('search.iconTextContact')}
                            className="input-box"
                            value={searchValue}
                            allowClear
                            onChange={(e) => {
                                if (!e.target.value) setContactList(null);
                                setSearchValue(lodashTrim(e.target.value));
                            }}
                            maxLength={100}
                            onPressEnter={handleSearch}
                        />
                    </div>
                    <Button
                        className="addContact-btn"
                        type="primary"
                        loading={loading}
                        onClick={handleSearch}
                        disabled={!searchValue}
                    >
                        {t('search.iconText')}
                    </Button>
                </div>
                <div className="contact-body">
                    {loading && <Loading style={{ marginTop: 10 }} />}
                    {contactList ? (
                        contactList.length > 0 ? (
                            contactList?.map((item, key) => {
                                let newItem = item;
                                if (newItem.avatar) {
                                    newItem.avatar = changeEgovUrl(newItem.avatar);
                                }
                                return (
                                    <div key={key}>
                                        <ListItem
                                            data={newItem}
                                            onClick={debounce(() => {
                                                openUserCardModal(item);
                                            }, 500)}
                                        />
                                    </div>
                                );
                            })
                        ) : (
                            <div className="contact-nodata">
                                <h4>无法找到该用户</h4>
                                <p>
                                    1、仅支持精准搜索姓名或手机号，如“张三”或者“13800000000”，请检查关键词是否输入正确
                                    <br />
                                    2、对方可能未使用{process.env.REACT_APP_NAME}
                                    或因隐私设置无法被搜索
                                </p>
                            </div>
                        )
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </Modal>
    );
}
function mapStateToProps({ ebook }: any) {
    return {
        createModalVisible: ebook.createModalVisible,
        createModalInputValue: ebook.createModalInputValue,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateContactModal(data: any) {
            dispatch({ type: 'ebook/openCreateContactModal', payload: data });
        },
        setCreateModalInputValue(data: any) {
            dispatch({ type: 'ebook/setCreateModalInputValue', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
