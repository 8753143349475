import api from '../api';
import { PermissionEnum } from '../const';
export { PermissionEnum };

const permissionsCache = new Map<string, Set<PermissionEnum>>();
type IGettype = Parameters<typeof api.permissions.get>[1];

function get(id?: string, type?: IGettype) {
    if (permissionsCache.has(id ?? '$')) {
        return Promise.resolve(permissionsCache.get(id ?? '$'));
    }
    return api.permissions
        .get(id, type)
        .catch(() => ({
            permissions: [],
        }))
        .then((data) => {
            const items = new Set<PermissionEnum>(data?.permissions ?? []);
            permissionsCache.set(id ?? '$', items);
            return items;
        });
}

export type IPermissions = {
    -readonly [key in keyof typeof PermissionEnum]?: boolean;
};

export function toPermissionsMap(items: PermissionEnum[]) {
    const permissions: IPermissions = {};
    items?.forEach(function (name) {
        permissions[name] = true;
    });
    return permissions;
}

export async function getPermissions(id?: string, type?: IGettype) {
    const items = await get(id, type);
    const permissions: IPermissions = {};
    items?.forEach(function (name) {
        permissions[name] = true;
    });
    return permissions;
}
