/**
 * 上传文件 button
 */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button, Upload, message } from 'antd';

import { UploadControlCenterInstance, joyspaceKey } from '@/components/UploadBox/UploadControl';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadFile } from './types';
// import isFunction from 'lodash/isFunction';
// import './index.less';
import context, { useTranslation } from '../../context';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { BusinessKey } from '../../const';
import Bus from '@/utils/bus';
import events from '../../context/events';
import { debounce } from 'lodash';
import { notUseJoySpaceOffline } from '../../util';

export interface OwnProps {
    className?: string;
    fromPage?: string;
    categoryId?: string;
    onUploadFile?: (result: any) => void;
    teamId?: string;
    folderId?: string;
    spaceId?: string;
    projectId?: string;
    archive?: boolean;
    gid?: string;
}

export interface OwnState {
    ProgressHandler: null;
}

type IProps = Readonly<OwnProps>;

function UploadButton(props: IProps) {
    const lang = useTranslation();
    const uploadControlInstance = UploadControlCenterInstance.get(joyspaceKey);
    const { fromPage, onUploadFile, archive = false, gid } = props;
    const [projectId, setProjectId] = useState<string>('');
    const [folderId, setFolderId] = useState<string>('');
    const [spaceId, setSpaceId] = useState<string>(props.spaceId || '');
    const [categoryId, setCategoryId] = useState<string>(props.folderId || '');
    const [disabled, setDisabled] = useState(false);
    const { location } = useHistory();
    const { currentRouterInfo } = useContext(context);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);

    useEffect(() => {
        let doUpload = debounce((data: Document) => {
            // Bus.emit('joyspace:documentCreated');
            // events.emit('list.add', data);
            events.emit('list.reload');
        }, 1000);
        uploadControlInstance?.on('list.add', doUpload);
        return () => {
            uploadControlInstance?.off('list.add', doUpload);
        };
    }, [uploadControlInstance]);
    useMemo(() => {
        if (notUseJoySpaceOffline()) {
            return;
        }
        setDisabled(!navigator.onLine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.onLine]);

    // useEffect(() => {
    //     setCategoryId(props.folderId || '');
    // }, [props.folderId]);

    useEffect(() => {
        if (currentRouterInfo !== null && currentRouterInfo.id !== 'myspace') {
            setCategoryId(currentRouterInfo.space_id);
            setFolderId(currentRouterInfo.id);
        } else {
            setCategoryId('');
            setFolderId('');
        }
    }, [currentRouterInfo]);
    useEffect(() => {
        setProjectId(props.projectId || '');
    }, [props.projectId]);

    const beforeUpload = async (_file: RcFile, _fileList: RcFile[]) => {
        const file = _file as UploadFile;
        Object.assign(file, { folderId, spaceId, categoryId, projectId });
        const { fileService, joyspaceWpsTarget } = appRuntimeEnv;
        if (!uploadControlInstance) {
            console.log('joyspaceuploadControlInstance获取出错');
            return;
        }
        // uploadControlInstance.once(`${FILEUPLOADER_UPFILE_END}:${file.uid}`, uploadListener);
        try {
            await uploadControlInstance.addFile(file, fileService, { joyspaceWpsTarget }, gid);
        } catch (e) {
            if ((e as any).message === 'zeroSize') {
                message.warn(lang('myspace.noZeroSize'));
            }
        }
    };

    // beforeUpload 接收的fileList是当前用户选择的文件

    const uploadProps = {
        multiple: true, // 支持多文件上传 IE10+
        name: 'files', // 传到后台的文件参数名称
        showUploadList: false, // 是否展示上传文件列表
        onChange: () => {},
        beforeUpload,
        customRequest: (_param: any) => {},
    };
    return (
        <Upload {...uploadProps} className="ant-upload-custom">
            <Button
                type="default"
                disabled={archive || disabled}
                clstag="pageclick|keycount|focus_joyspace_01_1615797631505|4"
            >
                {lang('myspace.upload')}
            </Button>
        </Upload>
    );
}

export default UploadButton;
