import ServiceList from '../service/ServiceList';

class InitThirdAppControllers {
    static List: any;
    static init() {
        this.List = this.List ? this.List : new ServiceList('thirdApp.db', 'lists');
    }
    static clearServiceAll() {
        this.List?.destory();
        this.List = null;
    }
    static GetListService() {
        return this.List || new ServiceList('thirdApp.db', 'lists');
    }
}

export default InitThirdAppControllers;
