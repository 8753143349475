import { connect } from 'dva';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import '../TaskDetailEditDraw/index.less'; // TODO: 这里编辑和新建使用一套css文件，后续考虑把新建和编辑合并，都使用编辑的页面来做
import { Button, Drawer, Input, message, Modal, Select, Collapse } from 'antd';
import { nanoid } from 'nanoid';
import IconFont from '@/components/icon';
import UserList from '../BaseComponents/UserList';
import UserStatusListPopover from '../UserStatusListPopover';
import DatePicker from '@/components/Calendars/BaseComponents/DatePicker';
import TypeSelect from '../BaseComponents/TypeSelect';
import WorkAttachmentComp from '../BaseComponents/WorkAttachmentComp/workEdit';
import { findIndexExecutorInTask } from '../utils';
import {
    NewTaskModel,
    PRIORITY_TYPE,
    PROCESS_STATUS,
    TaskListParams,
    TASK_STATUS,
} from '@/types/newWork';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { openUserSelectionModal } from '@/utils/modals';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { TaskResourceBizType, TaskResourcePermissionType } from '@/types/work';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import { createTask } from '@/api/work';
import { decorateQuickCreateTaskResource } from '../newUtils';
import { ChatMessageBody } from '@/types/chat';
import lodashCompact from 'lodash/compact';
import { shareScheduleAttachment } from '@/api/calendar';

export enum FileStatus {
    Init = 1,
    Uploading,
    Done,
    failed,
}
import syncConfirm from '@/utils/syncConfirm';
import { getEBookUserDetail } from '@/api/ebook';
const { Panel } = Collapse;
const { Option } = Select;
export const remindList = [
    {
        value: '0',
        label: '不提醒',
    },
    {
        value: '-0',
        label: '任务截止时',
    },
    {
        value: '-5',
        label: '截止前5分钟',
    },
    {
        value: '-10',
        label: '截止前10分钟',
    },
    {
        value: '-15',
        label: '截止前15分钟',
    },
    {
        value: '-30',
        label: '截止前30分钟',
    },
    {
        value: '-60',
        label: '截止前1小时',
    },
    {
        value: `-${60 * 2}`,
        label: '截止前2小时',
    },
    {
        value: `-${60 * 24}`,
        label: '截止前1天',
    },
    {
        value: `-${60 * 24 * 2}`,
        label: '截止前2天',
    },
];
import ScrollView from '@/baseComponents/ScrollView';
import config, { ConfigEnum } from '@/config/config';

const initTaskDetail: NewTaskModel = {
    title: '',
    taskId: nanoid(),
    owner: {
        userId: '',
        teamId: '',
        app: '',
        headImg: '',
        realName: '',
        processStatus: 1,
    },
    executors: [],
    remind: '0',
    priorityType: PRIORITY_TYPE.NORMAL_PRIORITY,
    workStartTime: null,
    workStopTime: null,
    startTime: -1,
    endTime: -1,
    content: '',
    remark: {
        id: '',
        remark: '',
    },
    resources: [],
};

let clicksign = false; // 记录是不是点击 label里的 ‘+ 添加附件’
// eslint-disable-next-line complexity
function CreateTaskDraw({
    userData,
    getTasks,
    offset,
    sortType,
    offsets,
    processStatuses,
    taskStatuses,
    priorityTypes,
    userRole,
    isMessageModule,
}: {
    userData: any;
    offset: number;
    offsets: number[];
    processStatuses: PROCESS_STATUS[];
    taskStatuses: TASK_STATUS[];
    priorityTypes: PRIORITY_TYPE[];
    getTasks: (params: TaskListParams) => void;
    userRole: number;
    sortType: number;
    isMessageModule: boolean;
}) {
    const { t } = useTranslation('work');
    const [t_common] = useTranslation('common');
    const [t_chat] = useTranslation('chat');
    const [drawVisible, setDrawVisible] = useState<boolean>(false);
    const [taskDetail, setTaskDetail] = useState<NewTaskModel>(initTaskDetail);
    const [fileList, setFileList] = useState<any[]>([]);
    const [textareaContentFocus, setTextareaContentFocus] = useState<boolean>(false);
    const [textareaRemarkFocus, setTextareaRemarkFocus] = useState<boolean>(false);
    const [textareaTitleFocus, setTextareaTitleFocus] = useState<boolean>(false);
    const [selectedSession, setSelectedSession] = useState<any>(null);
    const [submitting, setSubmitting] = useState(false);
    const { authInfo, imService } = useContext(GlobalContext);
    const [remindArr, setRemindArr] = useState<string[]>([]);
    const [attachmentMenuShowFn, setAttachmentMenuShowFn] = useState<Function>(() => () => {});

    const resetData = useCallback(() => {
        setDrawVisible(false);
        setTaskDetail(initTaskDetail);
        setFileList([]);
        setRemindArr([]);
    }, []);

    const closeDrawConfirm = useCallback(() => {
        function cancleAtt(status: boolean) {
            bus.off(`cancle-attachment-upload`, cancleAtt);
            // if (status) {
            //     message.warning(t('attachment uploading'));
            //     return;
            // } else {
            try {
                // 判断是否有编辑内容
                if (JSON.stringify(initTaskDetail) !== JSON.stringify(taskDetail) || status) {
                    Modal.confirm({
                        title: t('tip'),
                        content: <span className="nimble-con">{t('tip content')}</span>,
                        transitionName: 'notransition',
                        maskTransitionName: 'notransition',
                        maskStyle: { opacity: '0' },
                        okText: t('confirm'),
                        cancelText: t('cancel'),
                        className: 'task-common-modal-lb',
                        onOk() {
                            resetData();
                            status && bus.emit('close-task-detail-with-cancle-attachment-upload');
                            // document
                            //     .getElementsByClassName('ant-modal-root')[0]
                            //     ?.setAttribute('id', 'confirmmodal');
                            // const ele = document.getElementById('confirmmodal');
                            // if (ele) ele.style.display = 'none';
                        },
                    });
                } else {
                    resetData();
                }
            } catch (error) {
                resetData();
            }
            // }
        }
        bus.on(`cancle-attachment-upload`, cancleAtt);

        bus.emit('close-task-detail');

        return () => {
            bus.off(`cancle-attachment-upload`, cancleAtt);
        };
    }, [resetData, t, taskDetail]);
    const scrollRef = useRef(null);

    // 初始化数据
    useEffect(() => {
        async function handleOpenCreateDrawVisible(data: any) {
            const currUser: any = await getEBookUserDetail({
                userId: authInfo.userId,
                teamId: authInfo.teamUserInfo.teamId,
                appId: authInfo.ddAppId,
            });
            const titleName =
                currUser.fields.find((item: any) => item.key === 'e_position')?.val || '';
            const curAuthInfo = {
                userId: authInfo.userId,
                teamId: authInfo.teamUserInfo.teamId,
                app: authInfo.ddAppId,
                processStatus: data.processStatus,
                headImg: authInfo.teamUserInfo.headImg || '',
                realName: authInfo.teamUserInfo.realName || authInfo.account,
                deptName: authInfo.teamUserInfo.teamShortName,
                titleName,
            };
            setSelectedSession(data.selectedSession ? data.selectedSession : null);
            const resource = data.messageResource;
            // 消息图片文件转任务
            const initResource = resource ? decorateQuickCreateTaskResource(resource) : null;

            setFileList(initResource ? [initResource] : []);
            setDrawVisible(true);

            initTaskDetail.owner = { ...curAuthInfo };
            initTaskDetail.executors = [{ weight: 5, ...curAuthInfo }];

            setTaskDetail((task) => {
                return {
                    ...task,
                    title: data.title?.length > 100 ? data.title.substr(0, 100) : data.title || '',
                    resources: initResource ? [initResource] : [],
                    owner: { ...curAuthInfo },
                    executors: [{ weight: 5, ...curAuthInfo }],
                };
            });
        }
        bus.on('task:open-create', handleOpenCreateDrawVisible);
        return () => {
            bus.off('task:open-create', handleOpenCreateDrawVisible);
        };
    }, [authInfo]);

    async function handleInputChange(e: any, key: string) {
        let value = e.target.value;
        // 多行文本输入的时候，如果是拼音，输入的时候，不按空格或选择，点击空白，拼音会上屏，这里只有备注和任务描述是textarea
        if (value.length > 500) {
            value = value.slice(0, 500);
        }
        if (key === 'remark') {
            value = {
                id: taskDetail.remark.id,
                remark: e.target.value,
            };
        }
        const task = {
            ...taskDetail,
            [`${key}`]: value,
        };
        setTaskDetail(task);
    }
    const handleUpdateOwner = useCallback(() => {
        const { userId, teamUserInfo } = userData;
        const { ddAppId, teamId } = teamUserInfo;
        const isGroup = selectedSession?.['isGroup'];
        const sessionId = selectedSession?.['sessionId'];
        let tabs = ['recent', 'org', 'group', 'externalContacts'];
        if (!config[ConfigEnum.EXTERNAL_CONTACTS_ORG]) {
            tabs = ['recent', 'group', 'externalContacts'];
        }
        if (isGroup) {
            tabs.push('business');
        }
        openUserSelectionModal(
            {
                title: t_chat('add_member'),
                tabs: JSON.parse(JSON.stringify(tabs)),
                // tabs: isGroup
                //     ? ['recent', 'org', 'group', 'externalContacts', 'business']
                //     : ['recent', 'org', 'group', 'externalContacts'],
                single: true,
                recommendParam: {
                    keyWords: taskDetail.title || '',
                    aiType: ['empl'],
                },
                contactsFilterFlag: 'external',
                currentUser: {
                    app: ddAppId,
                    pin: userId,
                    teamId: teamId,
                },
                businessTab: {
                    tabName: '当前组成员',
                    tabParam: {
                        businessType: isGroup ? 'group' : 'project',
                        businessId: isGroup ? (sessionId ? sessionId : '') : '',
                    },
                },

                resultUsers: lodashCompact([taskDetail.owner]).map((item: any) => ({
                    id: item.userId + ':' + item.app + ':' + item.teamId,
                    name: item.realName,
                    avatar: item.headImg,
                    userId: item.userId,
                    teamId: item.teamId,
                    checked: true,
                    visible: true,
                })),
            },
            (data, close) => {
                // 过滤掉已选中的
                const { result } = data.data;
                if (!result.length) {
                    return close();
                }
                const addArr: any[] = [];
                result.forEach((r: any) => {
                    if (r.id) {
                        const strs = r.id.split(':');
                        return addArr.push({
                            userId: strs[0],
                            teamId: strs[2],
                            app: strs[1],
                            headImg: r.avatar,
                            realName: r.name,
                        });
                    }
                    const { origin } = r;
                    if (origin.info) {
                        addArr.push({
                            userId: origin.info.userId,
                            teamId: origin.info.teamId,
                            app: origin.info.appId || origin.info.app,
                            headImg: origin.info.headImg,
                            realName: origin.info.realName,
                        });
                    } else {
                        addArr.push({
                            userId: origin.userId,
                            teamId: origin.teamId,
                            app: origin.app,
                            headImg: origin.headImg,
                            realName: origin.realName,
                        });
                    }
                });
                prompt({
                    title: t(
                        `${
                            !taskDetail.owner
                                ? 'Set the person in charge'
                                : 'Person in charge of update'
                        }`
                    ),
                    icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                    onOk: async () => {
                        setTaskDetail((task: any) => {
                            return { ...task, owner: addArr[0] };
                        });
                    },
                    content: t(`update owner desc`).replace('%s', addArr[0].realName),
                    okText: t_common('button.ok'),
                    cancelText: t_common('button.cancel'),
                });

                close();
            },
            userData
        );
    }, [t, t_chat, t_common, selectedSession, taskDetail, userData]);

    const onVisibleChange = (visable: boolean) => {
        if (visable) {
            // 显示的时候，高低太低会使时间选择框错位，手动向下滚动一下
            const detailElement: HTMLElement | null = document.querySelector('.detail-content');
            if (detailElement && detailElement.offsetHeight + detailElement.scrollTop < 710) {
                detailElement.scrollTo(0, 710 - detailElement.offsetHeight);
            }
        }
    };

    async function handleAddTaskMembers(data: any[]) {
        const temp: any[] = [];
        const executors = [...taskDetail.executors];
        data.forEach((m: any) => {
            const index = findIndexExecutorInTask(executors, m);
            if (index === -1) {
                temp.push(m);
            } else {
                executors[index] = { ...executors[index], ...m };
            }
        });
        if (temp.length === 0) {
            return;
        }
        if ([...executors, ...temp]?.length > 200) {
            message.warn(t('200 person limit'));
            return;
        }
        setTaskDetail((task: any) => {
            return { ...task, executors: [...executors, ...temp] };
        });
    }
    async function handledelMember(members: any[]) {
        const executors = [...taskDetail.executors];
        members.forEach((m) => {
            const index = findIndexExecutorInTask(executors, m);
            if (index > -1) {
                executors.splice(index, 1);
            }
        });
        const newTaskDetail = { ...taskDetail, executors };
        setTaskDetail(newTaskDetail);
    }
    // eslint-disable-next-line complexity
    async function handleTimeChange(time: Dayjs | null, start = true) {
        const unix = time ? time.valueOf() : null;
        let offset = 0;
        if (start) {
            if (time && taskDetail.workStopTime?.isSameOrBefore(time)) {
                if (Math.abs((unix || 0) - taskDetail.workStopTime.valueOf()) < 6e4) {
                    offset = 1e3 * 300;
                } else {
                    return message.warn(t('The start time must not be later than the deadline'));
                }
            }
            if (unix === taskDetail.startTime) {
                return;
            }
            const task = {
                ...taskDetail,
                startTime: unix && unix - offset,
                workStartTime: unix ? dayjs(Number(unix) - offset) : null,
            };
            setTaskDetail(task);
            return;
        }
        if (unix === taskDetail.endTime) {
            return;
        }
        if (time && taskDetail.workStartTime?.isSameOrAfter(time)) {
            if (Math.abs((unix || 0) - taskDetail.workStartTime.valueOf()) < 6e4) {
                offset = 1e3 * 300;
            } else {
                return message.warn(t('The start time must not be later than the deadline'));
            }
        }
        const task = {
            ...taskDetail,
            endTime: unix && unix + offset,
            workStopTime: unix ? dayjs(Number(unix) + offset) : null,
        };
        if (!start && time && remindArr.length === 0) {
            let arr = ['-0', '-60', `-${60 * 24}`];
            setRemindArr(arr);
            task.remind = arr.join(',');
        }
        if (!start && !time) {
            setRemindArr([]);
        }
        setTaskDetail(task);
        return;
    }
    async function handlePriorityChange(priority: number) {
        if (taskDetail.priorityType === priority) {
            return;
        }
        const task = { ...taskDetail, priorityType: priority };
        setTaskDetail(task);
    }

    function onAttachmentRemove(fileId: string) {
        if (!taskDetail) {
            return;
        }
        const index = taskDetail.resources.findIndex((item) => item.bizId === fileId);
        if (index < -1) {
            return;
        }
        const temp = taskDetail.resources.map((item) => {
            return {
                ...item,
                fileName: item.name,
                fileId: item.bizId || nanoid(20),
                fileUrl: item.url,
                status: FileStatus.Done,
            };
        });
        temp.splice(index, 1);

        setFileList(temp as any);
        setTaskDetail({ ...taskDetail, resources: temp });
    }
    async function onAttachmentUploadFinish(file: any): Promise<null | any> {
        if (Array.isArray(file)) {
            const files = file;
            let newArr = files.map((f) => {
                return {
                    ...f,
                    name: f.fileName,
                    size: f.size,
                    url: f.fileUrl,
                    resourceId: nanoid(),
                    bizId: f.fileId,
                    bizType: f.bizType || TaskResourceBizType.FILE,
                    resourceType: TaskResourcePermissionType.COMMON,
                    fileType: f.fileType,
                    creatorUserId: f?.creatorUserId,
                };
            });
            const resources = taskDetail.resources;
            resources.push(...newArr);
            resources.forEach((item) => {
                Object.assign(item, {
                    fileName: item.name,
                    fileId: item.bizId || nanoid(20),
                    fileUrl: item.url,
                    status: FileStatus.Done,
                });
            });
            setFileList(resources as any);

            setTaskDetail((task: NewTaskModel) => {
                return {
                    ...task,
                    resources,
                };
            });
            message.success(t('attachment upload success'));
            return newArr;
        }
        let newFile = {
            name: file.fileName,
            size: file.size,
            url: file.fileUrl,
            resourceId: nanoid(),
            bizId: file.fileId,
            bizType: file.bizType || TaskResourceBizType.FILE,
            resourceType: TaskResourcePermissionType.COMMON,
            fileType: file.fileType,
            creatorUserId: file?.creatorUserId,
        };
        const resourceId = nanoid();

        const temp = {
            ...file,
            ...newFile,
            percent: 0,
            fileUrl: file.fileUrl,
            resourceId,
            status: FileStatus.Done,
        };
        // 移除不必要的字段，否则网路请求会报错
        delete temp.icon;
        delete temp.taskInstance;

        const resources = taskDetail.resources;
        resources.push(temp);
        setFileList(resources);
        setTaskDetail((task: NewTaskModel) => {
            return {
                ...task,
                resources,
            };
        });

        // message.success(t('attachment upload success'));
        return { resourceId: resourceId };
    }
    const handleShareClick = useCallback(
        (data, taskId) => {
            const { teamUserInfo } = userData;
            const { ddAppId, teamId, realName } = teamUserInfo;
            const sharelink = {
                ext: ``,
                icon:
                    'http://storage.360buyimg.com/jd.jme.photo/message_icon_task2x.png?Expires=3757406611&AccessKey=93c0d2d5a6cf315c3d4c52c5f549a9a886b59f76&Signature=pTm0aXa1Azq0eea7K91GNDWDypo%3D',
                // source: 'joyWork',
                category: 'joywork',
                title: t('share card title').replace('%s', realName),
                content: data.title,
                url: DeeplinkEvent.strify({
                    appId: ddAppId,
                    path: DeeplinkPathEnum.Task_Draw,
                    mparam: {
                        taskId: taskId,
                        taskName: data.title,
                        projectId: data.projectId ? data.projectId : '',
                        from: 'mobile',
                        priorityType: data.priorityType,
                        dueTag: data.endTime && dayjs(data.endTime).isBefore(dayjs()),
                        endTime: data.endTime,
                        startTime: data.startTime,
                        content: data.content,
                    },
                }),
            };
            const msg: ChatMessageBody = {
                type: ChatMessageType.TEMPLATE2,
                template: {
                    nativeId: 'share_link',
                },
                code: 0,
                data: [
                    {
                        sharelink: sharelink,
                        type: 0,
                    },
                ],
            };
            const messageId = generateMessageId();
            imService.sendChatMessage(selectedSession?.['sessionId'] || '', messageId, msg);
            const [toUserId] = selectedSession?.sessionId.split(':');
            shareScheduleAttachment({
                bizId: taskId,
                bizType: 1,
                shareRecipientDtos: [
                    {
                        recipientType: selectedSession.isGroup ? '2' : '1',
                        recipientId: toUserId,
                        recipientName: selectedSession.info.name,
                        teamId: authInfo.teamUserInfo.teamId,
                        app: authInfo.teamUserInfo.ddAppId,
                    },
                ],
            });
        },
        [
            userData,
            t,
            selectedSession,
            imService,
            authInfo.teamUserInfo.teamId,
            authInfo.teamUserInfo.ddAppId,
        ]
    );
    const getTaskList = useCallback(async () => {
        getTasks({
            limit: offsets ? offsets.length * 20 : 20,
            offset: 0,
            // offsets: offsets,
            processStatuses: processStatuses,
            taskStatuses: taskStatuses,
            priorityTypes: priorityTypes,
            userRole: userRole,
            sortType,
        });
    }, [getTasks, offsets, priorityTypes, processStatuses, sortType, taskStatuses, userRole]);

    const handleTaskReminChange = function (val: string[]) {
        if (val.length === 2 && val[0] === '0') {
            val.shift();
        }
        if (val.length >= 1 && val[val.length - 1] === '0') {
            setTaskDetail({
                ...taskDetail,
                remind: '0',
            });
            setRemindArr(['0']);
        } else {
            setTaskDetail({
                ...taskDetail,
                remind: val.join(','),
            });
            setRemindArr([...val]);
        }
    };

    const addTask = useCallback(
        async (share = false) => {
            if (!taskDetail.title) {
                message.error(t('create task err title'));
                return;
            }
            if (!taskDetail.priorityType) {
                message.error(t('create task err priorityType'));
                return;
            }
            if (taskDetail.workStartTime && dayjs().isAfter(taskDetail.workStartTime)) {
                const confirm = await syncConfirm('是否创建一个过去时间的任务');
                if (!confirm) {
                    return;
                }
            }

            setSubmitting(true);
            const [data, err]: any = await createTask({
                ...taskDetail,
                // FIXME: 这里新建任务的时候，接口请求参数remark是字符串，传RemarkInfo对象保存不上! →_→
                // @ts-ignore
                remark: taskDetail.remark?.remark || '',
            });
            if (err) {
                message.error(err as string);
                setSubmitting(false);
                return;
            }
            resetData();
            setSubmitting(false);
            if (selectedSession && share) {
                handleShareClick(taskDetail, data.taskId);
                return;
            }
            // await getTaskList();
            message.success(t('create task success'));
            setTimeout(() => {
                bus.emit('task:leftSideTabsReset', data);
            }, 0);
            return;
        },
        [/* getTaskList */ handleShareClick, resetData, selectedSession, t, taskDetail]
    );

    const addTaskValidate = useCallback(
        (_e, share = false) => {
            function cancleAtt(status: boolean) {
                bus.off(`cancle-attachment-upload`, cancleAtt);
                if (status) {
                    message.warning(t('attachment uploading'));
                    return;
                } else {
                    addTask(share);
                }
            }
            bus.on(`cancle-attachment-upload`, cancleAtt);
            bus.emit('close-task-detail');
            return () => {
                bus.off(`cancle-attachment-upload`, cancleAtt);
            };
        },
        [addTask, t]
    );

    const addAndShare = function (_e: any) {
        addTaskValidate(_e, true);
    };

    const handleList = useCallback((data) => {
        const setData = data.map((item: any) => {
            return {
                ...item,
                isRemove: true,
            };
        });

        return setData;
    }, []);
    const DrawerFooter = (
        <div>
            {selectedSession && (
                <Button
                    loading={submitting}
                    type="primary"
                    disabled={!taskDetail.title.trim()}
                    onClick={addAndShare}
                    clstag={
                        isMessageModule
                            ? 'pageclick|keycount|Xtbg_Msg_SendToIM|MessTask'
                            : undefined
                    }
                >
                    {t('create task and share')}
                </Button>
            )}
            <Button
                loading={submitting}
                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_createtask_complete"
                type="primary"
                disabled={!taskDetail.title.trim()}
                onClick={addTaskValidate}
            >
                {t('confirm')}
            </Button>
            <Button onClick={closeDrawConfirm}>取 消</Button>
        </div>
    );

    if (!drawVisible) {
        return null;
    }
    return (
        <Drawer
            width="450"
            keyboard={false}
            className="work-create-draw work-edit-draw"
            visible={true}
            destroyOnClose={true}
            footer={DrawerFooter}
            placement="right"
            onClose={closeDrawConfirm}
            closable={false}
        >
            <div className="detail-header">
                <div>{t('new task')}</div>
                <div className="header-btn-wrapper">
                    <div className="action-close action-button" onClick={closeDrawConfirm}>
                        <IconFont type="iconapp_btn_file_cancel" />
                    </div>
                </div>
            </div>
            <div className="detail-outer">
                <ScrollView ref={scrollRef} autoHide>
                    <div className="detail-content">
                        <div className="content-part-top">
                            <div className="detail-content-item task-title-item">
                                <div
                                    className={`title-container ${
                                        textareaTitleFocus ? 'textarea-container-focus' : ''
                                    }`}
                                >
                                    {/* <Input
                                        className="cursor-outline"
                                        placeholder={t('input task content')}
                                        value={taskDetail.title}
                                        onChange={(e) => handleInputChange(e, 'title')}
                                        maxLength={100}
                                    /> */}
                                    <Input.TextArea
                                        className={`cursor-outline ${
                                            taskDetail.title && taskDetail.title.length > 30
                                                ? 'padding-bottom-20'
                                                : ''
                                        }`}
                                        style={{ resize: 'none' }}
                                        autoSize={{ minRows: 1, maxRows: 3 }}
                                        placeholder={t('input task content')}
                                        value={taskDetail.title}
                                        onChange={(e) => handleInputChange(e, 'title')}
                                        onFocus={() => setTextareaTitleFocus(true)}
                                        onBlur={() => setTextareaTitleFocus(false)}
                                        maxLength={100}
                                    />
                                    {taskDetail.title && taskDetail.title.length > 30 && (
                                        <p className="content-length">
                                            <span>{taskDetail.title?.length || 0}</span>/100
                                        </p>
                                    )}
                                    {/* <p className="content-length">
                                        <span>{taskDetail.title?.length || 0}</span>/100
                                    </p> */}
                                </div>
                            </div>
                            {/* <div className="detail-content-type">{t('members info')}</div> */}
                            <div className="detail-content-item task-owner-item">
                                <div className="label">
                                    <span>*</span>
                                    {t('task head')}
                                </div>
                                <div className="owner-container">
                                    <UserList
                                        disable={taskDetail.owner}
                                        list={[taskDetail.owner]}
                                        maxLength={8}
                                        isShowName={true}
                                        notAvatar={true}
                                        notTooltip={true}
                                        addMemberCb={handleUpdateOwner}
                                        title={taskDetail.title}
                                    />
                                    {taskDetail.owner && (
                                        <div
                                            className="trans-owner"
                                            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_createtask_transferowner"
                                            onClick={handleUpdateOwner}
                                        >
                                            <span>{t('update owner')}</span>
                                            {/* <IconFont type="iconlog_enter" className="icon-more" /> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="detail-content-item task-executor-item">
                                <div className="label">{t('executor')}</div>
                                <div className="executor-container">
                                    <UserList
                                        list={handleList(taskDetail.executors)}
                                        maxLength={8}
                                        isShowName={true}
                                        // hasWeightSet={true}
                                        notAvatar={true}
                                        title={taskDetail.title}
                                        addMemberDisable={taskDetail.executors.length >= 200}
                                        handleAddTaskMembers={handleAddTaskMembers}
                                        onRemove={handledelMember}
                                        isGroup={selectedSession?.['isGroup']}
                                        sessionId={selectedSession?.['sessionId']}
                                        userPopover="task"
                                        mode="create"
                                    />
                                    <UserStatusListPopover
                                        list={handleList(taskDetail.executors)}
                                        onRemove={handledelMember}
                                        userPopover="task"
                                        mode="create"
                                    >
                                        <IconFont type="iconlog_enter" className="icon-more" />
                                    </UserStatusListPopover>
                                </div>
                            </div>
                            {/* <div className="detail-content-type">{t('detail info')}</div> */}
                            <div className="detail-content-item task-type-item">
                                <div className="label">
                                    <span>*</span>
                                    {t('type')}
                                </div>
                                <TypeSelect
                                    priority={taskDetail.priorityType}
                                    onRadioChange={handlePriorityChange}
                                    type="radio"
                                />
                            </div>
                        </div>

                        <div className="content-part-other">
                            <div className="detail-content-item task-timerange-item common-single-item">
                                <div className="label">{t('Start-stop time')}</div>
                                <div className="timerange-container">
                                    <div className="time-button time-button-star">
                                        <DatePicker
                                            onVisibleChange={onVisibleChange}
                                            time={taskDetail.workStartTime || null}
                                            onChange={(time: Dayjs | null) =>
                                                handleTimeChange(time)
                                            }
                                            placeholder={t('set start time')}
                                            configOfPopover={{ getPopupContainer: undefined }} // 处理：Popover 组件错位
                                        />
                                    </div>

                                    <div className="button-split" />
                                    <div className="time-button time-button-end">
                                        <DatePicker
                                            onVisibleChange={onVisibleChange}
                                            time={taskDetail.workStopTime || null}
                                            onChange={(time: Dayjs | null) =>
                                                handleTimeChange(time, false)
                                            }
                                            placeholder={t('set end time')}
                                            configOfPopover={{ getPopupContainer: undefined }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="detail-content-item task-remind-item common-single-item"
                                style={{ display: taskDetail.workStopTime ? '' : 'none' }}
                            >
                                <div className="label">逾期提醒</div>
                                <Select
                                    disabled={!taskDetail.endTime || taskDetail.endTime === -1}
                                    mode="multiple"
                                    allowClear={false}
                                    size="small"
                                    showArrow={!!taskDetail.endTime && taskDetail.endTime !== -1}
                                    showSearch={false}
                                    value={remindArr}
                                    placeholder="请选择提醒时间"
                                    className="timerange-container"
                                    onChange={handleTaskReminChange}
                                >
                                    {remindList.map((item: any) => {
                                        return (
                                            <Option key={item.value} value={item.value}>
                                                {item.label}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div className="detail-content-item task-textarea-item">
                                <div className="label" style={{ alignSelf: 'flex-start' }}>
                                    任务描述
                                </div>
                                <div
                                    className={`textarea-container ${
                                        textareaContentFocus ? 'textarea-container-focus' : ''
                                    }`}
                                >
                                    <Input.TextArea
                                        className={`cursor-outline ${
                                            taskDetail.content && taskDetail.content.length > 40
                                                ? 'padding-bottom-20'
                                                : ''
                                        }`}
                                        placeholder={t('add description')}
                                        autoSize
                                        defaultValue={taskDetail.content || ''}
                                        onChange={(e) => handleInputChange(e, 'content')}
                                        onFocus={() => setTextareaContentFocus(true)}
                                        onBlur={() => setTextareaContentFocus(false)}
                                        maxLength={500}
                                    />
                                    {taskDetail.content && taskDetail.content.length > 40 && (
                                        <p className="content-length">
                                            <span>{taskDetail.content?.length || 0}</span>/500
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="content-part-other">
                            <div className="detail-content-item task-attachment-item">
                                <Collapse
                                    defaultActiveKey={[0]}
                                    bordered={false}
                                    expandIconPosition="right"
                                    expandIcon={({ isActive }) => (
                                        <div style={{ display: !fileList?.length ? 'none' : '' }}>
                                            <IconFont
                                                type="iconlog_enter"
                                                className="icon-more"
                                                rotate={isActive ? 270 : 90}
                                            />
                                        </div>
                                    )}
                                    onChange={function name(params: any) {
                                        if (clicksign && params.length) {
                                            attachmentMenuShowFn(100);
                                        }
                                        clicksign = false;
                                    }}
                                >
                                    <Panel
                                        header={
                                            <div
                                                className="label"
                                                style={{ alignSelf: 'flex-start' }}
                                            >
                                                <b>{t('attachment')}</b>({fileList?.length || 0})
                                                <div
                                                    className="rg-handle"
                                                    style={{
                                                        marginRight: !fileList?.length
                                                            ? '-6px'
                                                            : '',
                                                    }}
                                                    onClick={function (a) {
                                                        clicksign = true;
                                                    }}
                                                >
                                                    <IconFont
                                                        className="lb-conclude"
                                                        type="iconapp_btn_add2"
                                                        style={{
                                                            color: (window as any).styleSass
                                                                .primaryColor,
                                                            position: 'static',
                                                        }}
                                                    />
                                                    <em>添加附件</em>
                                                </div>
                                            </div>
                                        }
                                        key={0}
                                        forceRender={true}
                                    >
                                        <WorkAttachmentComp
                                            disabled={false}
                                            mode="create"
                                            uploaderProxy={true}
                                            multiple={true}
                                            onRemove={onAttachmentRemove}
                                            onFinish={onAttachmentUploadFinish}
                                            fileList={handleList(fileList)}
                                            autoShowMenuFnSetter={setAttachmentMenuShowFn}
                                        >
                                            <p>+添加附件</p>
                                        </WorkAttachmentComp>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className="content-part-other">
                            <div className="detail-content-item task-textarea-item task-mynote-item">
                                <Collapse
                                    defaultActiveKey={[0]}
                                    bordered={false}
                                    expandIconPosition="right"
                                    expandIcon={({ isActive }) => (
                                        <IconFont
                                            type="iconlog_enter"
                                            className="icon-more"
                                            rotate={isActive ? 270 : 90}
                                        />
                                    )}
                                >
                                    <Panel
                                        header={
                                            <div
                                                className="label"
                                                style={{ alignSelf: 'flex-start' }}
                                            >
                                                <b>{t('my note')}</b>
                                                <em>(仅您自己可见)</em>
                                            </div>
                                        }
                                        key={0}
                                    >
                                        <div
                                            className={`textarea-container ${
                                                textareaRemarkFocus
                                                    ? 'textarea-container-focus'
                                                    : ''
                                            }`}
                                        >
                                            <Input.TextArea
                                                className={`cursor-outline ${
                                                    taskDetail.remark?.remark &&
                                                    taskDetail.remark.remark.length > 40
                                                        ? 'padding-bottom-20'
                                                        : ''
                                                }`}
                                                placeholder={t('add remark content')}
                                                autoSize
                                                value={taskDetail.remark.remark}
                                                onChange={(e) => handleInputChange(e, 'remark')}
                                                onFocus={() => setTextareaRemarkFocus(true)}
                                                onBlur={() => setTextareaRemarkFocus(false)}
                                                maxLength={500}
                                            />
                                            {taskDetail.remark?.remark &&
                                                taskDetail.remark.remark.length > 40 && (
                                                    <p className="content-length">
                                                        <span>
                                                            {taskDetail.remark?.remark?.length || 0}
                                                        </span>
                                                        /500
                                                    </p>
                                                )}
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </div>
        </Drawer>
    );
}
function mapStateToProps({ user, work, chat }: any) {
    return {
        userData: user.userData.user,
        offset: work.offset,
        offsets: work.offsets,
        processStatuses: work.processStatuses,
        taskStatuses: work.taskStatuses,
        priorityTypes: work.priorityTypes,
        userRole: work.userRole,
        sortType: work.sortType,
        isMessageModule: chat.isMessageModule,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getTasks(payload: TaskListParams) {
            return dispatch({
                type: 'work/fetchTaskList',
                payload,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskDraw);
