/* eslint-disable max-params */
/* eslint-disable no-param-reassign */
import { UserInfo } from '@/models/user';
import cookies from 'js-cookie';
import { ChatMessage, Employee, GroupRosterIdentity, Session } from '@/types/chat';
import {
    cloudPng,
    compressionPng,
    exPng,
    filePng,
    webPng,
    musicPng,
    otherPng,
    pdfPng,
    picturePng,
    pptPng,
    videoPng,
    wordPng,
} from '@/assets/img/file-icon/';
import { useCallback, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { extension, lookup } from 'mime-types';
import cache from '@/utils/cache';
import { convertUidToString } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import {
    convertEmployeeToString,
    isEqualEmployee,
    isCardViewGroupSession,
} from '@/utils/chat/message';
import lodashPick from 'lodash/pick';
import { UserCardAction, UserCardType } from '@/types/UserCard';
import { FocusSDK, isFocusEnv, DownloadItem, detectOS } from '@/utils';
import Bus from '@/utils/bus';
import { toPng } from 'html-to-image';
import { message, message as toast } from 'antd';
import { ExternalContactsStatus, DownloadTypeEnum } from '@/types/chat/enum';
import { nyrssfENStr } from '@/utils/date';
import { processJoyspaceUrl } from '@/components/Joyspace/winOpen';
import jsxss from 'xss';
import { changeEgovUrl, judgeHttpCardLinkUrl } from '@/utils/tools';
// import { UAParser } from 'ua-parser-js';
import DownloadFileQueue, { DownloadOption, SaveOption } from '@/utils/DownloadFileQueue';
import { addXtoken } from '@/utils/addToken';
// import { request } from '../../components/Joyspace/api/index';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { urlPatt } from '@/components/chat/message/message-components/utiles/joyspace-file';
// import { request } from '../../components/Joyspace/api/index';
import { request } from '@/components/Joyspace/api/request';
import { isGroupOrdinary } from '@/utils/chat/group';
import CopyClipboard from 'copy-to-clipboard';

/**
 * 消息组件工具类
 * @param apprStatus 返回状态值
 * @param t
 * @date 2021/6/25
 */
export function setExternalContactsTipsTool(apprStatus: number, t: any) {
    if (apprStatus === ExternalContactsStatus.SUCCESS) {
        message.success(t('examine_apply'));
        return;
    }
    if (apprStatus === ExternalContactsStatus.FAIL) {
        message.success(t('submit_success'));
        return;
    }
    if (apprStatus === ExternalContactsStatus.INVALID) {
        message.error(t('invalid_tips'));
        return;
    }
    if (apprStatus === ExternalContactsStatus.ALREADY_APPR) {
        message.error(t('already_appr'));
        return;
    }
    if (apprStatus === ExternalContactsStatus.ALREADY_EXSIT) {
        message.error(t('already_exsit'));
        return;
    }
}

/**
 * 利用浏览器底层转义字符串，防止xss攻击
 * 禁止服务端渲染部分调用
 * @param str
 */
export function stringEncode(str: string) {
    const div = document.createElement('div');
    if (div.innerText) {
        div.innerText = str;
    } else {
        div.textContent = str; // Support firefox
    }
    const result = div.innerHTML.replace(/&amp;/g, '&'); // 去掉&的转义，保证url可以正常替换
    return result;
}

export function formatAtName(name: string): string {
    return `${decodeURIComponent(String('@' + name + '%e2%80%85'))}`;
}

export function handleOpenMentionUserInfo(
    e: any,
    t: any,
    params: {
        currentEmployee: Employee;
        selectedSession: Session;
        groupRosterIdentity: GroupRosterIdentity | '';
    }
) {
    let data = null;
    try {
        // console.log(e.target.dataset);
        data = JSON.parse(
            e.target.getAttribute('data-event-params') || e.target.dataset.eventParams
        );
    } catch (e) {}
    if (e.target.dataset.eventClick === 'http-link-click') {
        if (!data || (data && !data.url)) {
            return;
        }
        // console.log(data, '555');
        if (CopyClipboard(data.url)) {
            toast.success('地址已复制到剪切板，请在浏览器中打开。');
        } else {
            toast.error(t('copy-failed'));
        }
        return;
    }
    if (
        e.target.getAttribute('data-event-click') !== 'mention-link' ||
        !data ||
        (data && data.id === 'all') ||
        (data && !data.id)
    ) {
        return;
    } else {
        Object.assign(data, { userId: data.id });
        // 如果群成员并且禁止了查看群成员名片
        if (
            !isEqualEmployee(params.currentEmployee, data) &&
            isGroupOrdinary(params.groupRosterIdentity) &&
            !isCardViewGroupSession(params.selectedSession)
        ) {
            toast.warn(t('can_not_open_user_card_tip'));
            return false;
        }

        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId: data.id,
            teamId: data.teamId,
            appId: data.app,
        });
    }
}

/**
 * 格式化文本内容中的@用户
 * @param content
 * @param atUsers
 * @param loginUser
 */
export function formatAtUser(content: string, atUsers: any, loginUser: UserInfo): string {
    let result = content;
    if (atUsers && atUsers?.length > 0) {
        let tempIndex = 0;
        let temp = [];
        for (const u of atUsers) {
            let mentionItem = u;
            if (typeof u === 'string') {
                mentionItem = JSON.parse(u);
            }
            const atStr = `@${mentionItem.nickname}`;
            tempIndex = result.indexOf(atStr);
            if (tempIndex > 0) {
                temp.push(result.substring(0, tempIndex));
            }
            if (tempIndex === -1) {
                continue;
            }
            const domNode = document.createElement('a');
            if (mentionItem.pin && mentionItem.app && mentionItem.teamId) {
                domNode.setAttribute('mention-id', convertUidToString(mentionItem));
            }
            domNode.setAttribute('data-event-click', 'mention-link');
            domNode.setAttribute(
                'data-event-params',
                JSON.stringify({
                    id: mentionItem?.pin,
                    type: 'user',
                    name: mentionItem?.nickname,
                    app: mentionItem?.app,
                    teamId: mentionItem?.teamId,
                })
            );
            domNode.innerText = `@${mentionItem.nickname}`;
            domNode.classList.add(`at`);
            domNode.classList.add(`e-mention-user`);
            domNode.classList.add(`e-mention-link`);
            domNode.setAttribute('contenteditable', 'false');
            const employee = lodashPick(loginUser, [
                'userId',
                'app',
                'teamId',
                'deptId',
                'unitDeptId',
            ]) as Employee;
            if (
                convertEmployeeToString(employee) === convertUidToString(mentionItem) ||
                atStr === '@全体成员' ||
                employee.unitDeptId === mentionItem.unitId ||
                employee.deptId === mentionItem.deptId
            ) {
                domNode.classList.add('my_at');
            }
            temp.push(domNode.outerHTML);
            result = result.substr(tempIndex + atStr.length);
        }
        result = temp.join('') + result;
    }
    return result;
}

export function mattchUrl(content: string, rn?: boolean) {
    let result = content;
    // const patt = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const re = result.replace(urlPatt, (r) => {
        if (!r) {
            return '';
        }
        let newUrl = r;
        if (r.indexOf('joyspace') > -1) {
            newUrl = processJoyspaceUrl(r);
        }
        // newUrl = changeEgovUrl(newUrl);
        if (!r.startsWith('http')) {
            const idx = r.indexOf('http');
            const text = r.substring(0, idx);
            const src = r.substring(idx);
            if (judgeHttpCardLinkUrl(src)) {
                return `${text}<a href="${src}" target="_blank">${src}</a>${rn ? '' : '\r\n'}`;
            }
            return `${text}<a href="javascript:void(0)" data-event-params='${JSON.stringify({
                url: src,
            })}' data-event-click="http-link-click">${src}</a>${rn ? '' : '\r\n'}`;
        } else {
            if (judgeHttpCardLinkUrl(newUrl)) {
                return `<a href="${newUrl}" target="_blank">${r}</a>${rn ? '' : '\r\n'}`;
            }
            return `<a href="javascript:void(0)" data-event-params='${JSON.stringify({
                url: newUrl,
            })}' data-event-click="http-link-click">${r}</a>${rn ? '' : '\r\n'}`;
        }
    });
    return re;
}

export function sizeToStr(size: number): string {
    if (size < 1024) {
        return size + 'B';
    }
    if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + 'KB';
    }
    if (size < 1024 * 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + 'MB';
    }

    if (size < 1024 * 1024 * 1024 * 1024) {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
    }
    return '';
}

const getStrLength = (str: string) => {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
            len += 2;
        } else {
            len++;
        }
    }
    return len;
};

/**
 * 处理文件名太长
 * @param name
 */
export function formatFileName(name: string): string {
    const len = getStrLength(name || '');
    if (len < 32) {
        return name;
    } else {
        const before = name.substring(0, 12);
        const after = name.substring(name.length - 12, name.length);
        return `${before}...${after}`;
    }
}

export function shortFileName(name: string): string {
    // const len = getStrLength(name);
    if (name.length <= 20) {
        return name;
    } else {
        const before = name.substring(0, 8);
        const after = name.substring(name.length - 8, name.length);
        return `${before}...${after}`;
    }
}

export function dealImageWidthHeight(
    [max, min]: number[],
    needReverse = false,
    maxWidth = 320
): any {
    if (!max || !min) {
        return [0, 0];
    }
    if (max >= min) {
        const scale = Math.floor(max / min);
        if (scale <= 2.5) {
            if (50 <= max && max <= maxWidth && 50 <= min && min <= maxWidth) {
                return !needReverse ? [max, min] : [min, max];
            } else if (max > maxWidth) {
                let a = Math.floor((min * maxWidth) / max);
                // return !needReverse ? [maxWidth, 'auto'] : ['auto', maxWidth];
                return !needReverse ? [maxWidth, a] : [a, maxWidth];
            } else if (min < 50) {
                let a = Math.floor((50 * max) / min);
                // return !needReverse ? ['auto', 50] : [50, 'auto'];
                return !needReverse ? [a, 50] : [50, a];
            }
        } else {
            let max = min * 2.5;
            let tempMin = min;
            if (max < maxWidth) {
                max = maxWidth;
                tempMin = maxWidth / 2.5;
            }
            return dealImageWidthHeight([max, tempMin], false, maxWidth);
        }
    } else {
        return dealImageWidthHeight([min, max], true, maxWidth);
    }
}

export function dealImageWidthHeight2([max, min]: number[], fixWidth = 378, fixHeight = 180): any {
    // console.log('width', max, 'height', min);
    if (!max || !min) {
        return [0, 0];
    }
    const scale = Number((max / min).toFixed(1));
    // console.log('dealImageWidthHeight2', scale);
    if (scale < 0.1) {
        return [18, fixHeight];
    } else if (scale < 0.3) {
        return [scale * fixHeight, fixHeight];
    } else if (scale < 1) {
        return [240 * scale, 240];
    } else if (scale < 3) {
        if (scale * fixHeight > 480) {
            return [480, 480 / scale];
        }
        return [scale * fixHeight, fixHeight];
    } else if (scale <= 18) {
        const tempH = fixWidth / scale;
        return [fixWidth, tempH < 45 ? 45 : tempH];
    } else {
        return [fixWidth, 45];
    }
}

/**
 * 通过文件的mini type 获取icon
 * @param mimeType
 */
export function getFileIconByMime(mimeType: string) {
    // console.log(mimeType, '文件格式');
    const iconMap: any = {
        [picturePng]: {
            ic: picturePng,
            color: '#188AFF',
            types: ['image/png', 'image/gif', 'image/jpeg', 'image/pjpeg', 'image/svg+xml'],
        },
        [videoPng]: {
            ic: videoPng,
            color: '#CC3AEA',
            types: [
                'video/mp4',
                'video/avi',
                'application/x-troff-msvideo',
                'application/x-troff-msvideo',
                'video/msvideo',
                'video/x-msvideo',
                'application/vnd.rn-realmedia-vbr',
                'video/quicktime',
                'video/x-sgi-movie',
            ],
        },
        [musicPng]: {
            ic: musicPng,
            color: '#CC3AEA',
            types: [
                'audio/mpeg',
                'audio/mp3',
                'audio/x-mp3',
                'audio/x-mpg',
                'audio/x-mpeg-3',
                'audio/mpeg3',
                'audio/x-mpeg-3',
                'audio/x-flac',
                'audio/flac',
                'application/x-ape',
                'audio/x-ape',
                'audio/wav',
                'audio/x-wav',
            ],
        },
        [webPng]: {
            ic: webPng,
            color: 'F6C544#',
            types: ['text/html', 'text/webviewhtml', 'application/xml', 'application/xhtml+xml'],
        },
        [filePng]: {
            ic: filePng,
            color: '#F6C544',
            types: [
                'text/plain',
                'text/rtf',
                'text/csv',
                'application/kswps',
                'application/vnd.ms-works',
                'application/rtf',
                'application/x-rtf',
                'text/richtext',
                'application/vnd.apple.key',
                'application/vnd.apple.numbers',
                'application/x-iwork-pages-sffpages',
                'application/vnd.apple.pages',
            ],
        },
        [wordPng]: {
            ic: wordPng,
            color: '#318CE3',
            types: [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/wps-office.doc',
                'application/wps-office.docx',
            ],
        },
        [exPng]: {
            ic: exPng,
            color: '#62C00D',
            types: [
                'application/excel',
                'application/vnd.ms-excel',
                'application/x-excel',
                'application/x-msexcel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/wps-office.xls',
                'application/wps-office.xlsx',
            ],
        },
        [pptPng]: {
            ic: pptPng,
            color: '#E66221',
            types: [
                'application/vnd.ms-powerpoint',
                'application/mspowerpoint',
                'application/powerpoint',
                'application/x-mspowerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/wps-office.ppt',
                'application/wps-office.pptx',
            ],
        },
        [pdfPng]: {
            ic: pdfPng,
            color: '#F65F4E',
            types: ['application/pdf'],
        },
        [compressionPng]: {
            ic: compressionPng,
            color: '#F6C544',
            types: [
                'application/x-compressed',
                'application/x-rar-compressed',
                'multipart/x-zip',
                'application/vnd.rar',
                'application/zip',
                'application/x-7z-compressed',
                'application/x-zip-compressed',
            ],
        },
        [cloudPng]: {
            ic: cloudPng,
            color: '#188AFF',
            types: [
                'application/vnd.android.package-archive',
                'application/x-newton-compatible-pkg',
                'application/octet-stream',
                'application/x-apple-diskimage',
                'application/x-msdownload',
                'application/x-msdos-program',
            ],
        },
        other: {
            ic: otherPng,
            color: '#A7AAAE',
            types: [],
        },
    };

    if (!mimeType) {
        return [otherPng, iconMap['other'].color, iconMap['other'].ic];
    }

    for (const key in iconMap) {
        if (iconMap.hasOwnProperty(key) && iconMap[key].types.includes(mimeType)) {
            return [key, iconMap[key].color, iconMap[key].ic];
        }
    }

    return [otherPng, iconMap['other'].color, iconMap['other'].ic];
}

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useThrottle(fn: any, delay: any, dep: any = []) {
    const { current } = useRef({ fn, timer: null });
    useEffect(
        function () {
            current.fn = fn;
        },
        [current, fn]
    );

    return useCallback(
        function f(...args) {
            let time: any = current.timer;
            if (!time) {
                time = setTimeout(() => {
                    delete (current as any).timer;
                }, delay);
                current.timer = time;
                current.fn.call(...args);
            }
        },
        [current, delay]
    );
}

export function useIntersectionObserver({
    target,
    onIntersect,
    threshold = 0.1,
    rootMargin = '0px',
}: {
    target: any;
    onIntersect: IntersectionObserverCallback;
    threshold?: number;
    rootMargin?: string;
}) {
    return useEffect(() => {
        const observer = new IntersectionObserver(onIntersect, {
            rootMargin,
            threshold,
        });
        const current = target.current;
        observer.observe(current);
        return () => {
            observer.unobserve(current);
        };
    });
}
export function unescapeHTML(str: string) {
    return str
        .replace(/&lt;?/g, '<')
        .replace(/&gt;?/g, '>')
        .replace(/&amp;?/g, '&')
        .replace(/&quot;?/g, '"')
        .replace(/&apos;?/g, "'");
}

// 用户的输入
export const userEscapeString = (data: string): string => {
    return jsxss(data, {
        whiteList: {
            em: [],
        },
    });
};

export const getXToken = () => {
    const xToken = cookies.get('focus-token');
    if (xToken) {
        return xToken;
    } else if (cache.token) {
        return cache.token.token.focusToken || '';
    }
};

export const browserType = () => {
    // 内核和载体
    const ua = navigator.userAgent.toLowerCase();
    const testUa = (regexp: RegExp) => regexp.test(ua);
    let engine = 'unknow';
    let supporter = 'unknow';
    if (testUa(/applewebkit/g)) {
        engine = 'webkit'; // webkit内核
        if (testUa(/edge/g)) {
            supporter = 'edge'; // edge浏览器
        } else if (testUa(/opr/g)) {
            supporter = 'opera'; // opera浏览器
        } else if (testUa(/chrome/g)) {
            supporter = 'chrome'; // chrome浏览器
        } else if (testUa(/safari/g)) {
            supporter = 'safari'; // safari浏览器
        }
    } else if (testUa(/gecko/g) && testUa(/firefox/g)) {
        engine = 'gecko'; // gecko内核
        supporter = 'firefox'; // firefox浏览器
    } else if (testUa(/presto/g)) {
        engine = 'presto'; // presto内核
        supporter = 'opera'; // opera浏览器
    } else if (testUa(/trident|compatible|msie/g)) {
        engine = 'trident'; // trident内核
        supporter = 'iexplore'; // iexplore浏览器
    }
    return supporter;
};

/**
 * @description 高压缩低失真地址
 * @param url
 * http://localhost:8080/res/download/wWCs0HCMMEdEAtLZt3G4qysKJ.jpg/image/webp/scale/0.6?appKey=bf62d70d71658da9&quality=0.7默认
 */
export const getPreviewImageUrl = (url: string, isWeb?: boolean): string => {
    let compressionType = isWeb ? (browserType() === 'chrome' ? 'webp' : 'png') : 'webp';
    if (!url) {
        return '';
    }
    url = changeEgovUrl(url);
    if (url.indexOf('eefs') > -1) {
        const [partI, partII] = url.split('?');
        let newUrl = `${partI}/image/${compressionType}/scale/1.0?${partII}&quality=0.7`;
        newUrl = addXtoken(newUrl);
        return newUrl;
    }

    const domain = FocusSDK.getApplicationInfo().domain || 'jdcloud.com'; // process.env.REACT_APP_TOP_LEVEL_DOMAIN as any;
    if (url && url.indexOf(domain) === -1) {
        return url;
    }

    if (url.indexOf('x-token') > -1) {
        return url;
    }

    if (/-\d+_\d+\?/.test(url)) {
        return url;
    }
    return url;
};

export const getFileUrl = (url: string): string => {
    // 下载连接处理x-token
    if (url && url.indexOf('eefs') > -1) {
        url = addXtoken(`${url}&isAttachment=true`);
    }
    return changeEgovUrl(url);
};

export const getImgSrc = getFileUrl;

export const getThumbUrl = (url: string, thumbWidth: number, thumbHeight: number): string => {
    // if (!config[ChatConfig.CHAT_MESSAGE_IMAGE_THUMB_URL_ENABLE]) {
    //     return url;
    // }
    if (!url) {
        return '';
    }
    // if (url.indexOf('eefs.jdcloud.com') === -1) {
    //     return url;
    // }
    // if (detectOS() === 'Mac') {
    //     return url;
    // }
    url = changeEgovUrl(url);
    if (url.indexOf('eefs') > -1) {
        const [partI, partII] = url.split('?');
        return addXtoken(
            `${partI}-${Math.round(thumbWidth)}_${Math.round(thumbHeight)}${
                partII ? '?' + partII : ''
            }`
        );
    }
    const domain = FocusSDK.getApplicationInfo().domain || 'jdcloud.com'; // process.env.REACT_APP_TOP_LEVEL_DOMAIN as any;
    if (url && url.indexOf(domain) === -1) {
        return url;
    }

    if (url.indexOf('x-token') > -1) {
        return url;
    }

    if (/-\d+_\d+\?/.test(url)) {
        return url;
    }

    // if (url.indexOf('eefs') > -1) {
    //     const xToken = getXToken();
    //     if (url.lastIndexOf('?') > -1) {
    //         const [partI, partII] = url.split('?');
    //         return `${partI}-${Math.round(thumbWidth)}_${Math.round(
    //             thumbHeight
    //         )}?${partII}&x-token=${xToken}`;
    //     } else {
    //         return `${url}-${Math.round(thumbWidth)}_${Math.round(thumbHeight)}?x-token=${xToken}`;
    //     }
    // }
    return url;
};

export const getHeadImgThumbUrl = (
    url: string,
    thumbWidth: number,
    thumbHeight: number
): string => {
    // 已是缩略图
    if (!url) {
        return '';
    }
    url = changeEgovUrl(url);
    if (/-\d+_\d+\?/.test(url)) {
        return url;
    }
    if (url.indexOf('eefs') > -1) {
        if (url.lastIndexOf('?') > -1) {
            const [partI, partII] = url?.split('?');
            return `${partI}-${thumbWidth}_${thumbHeight}?${partII}&ver=2`;
        } else {
            return `${url}-${thumbWidth}_${thumbHeight}?ver=2`;
        }
    }
    return url;
};

export const downLoadFile = (
    fileName: string,
    url: string,
    defaultPath?: string,
    opts?: {
        onProgress?: any;
        onFinish?: any;
    }
) => {
    if (isFocusEnv()) {
        FocusSDK.downloadFile(changeEgovUrl(url), {
            saveAs: true,
            defaultPath: defaultPath,
            filename: fileName,
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: (file: { path: string; filename: string }, data: any) => {
                // console.log('file==============>', file, data);
                message.success('下载成功');
            },
        });
        return {};
    } else {
        const getDownload = function (url: any, callback?: () => void) {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true); // 也可用POST方式
            xhr.responseType = 'blob';
            xhr.addEventListener(
                'progress',
                function (evt) {
                    if (evt.lengthComputable) {
                        let percentComplete = (evt.loaded / evt.total) * 100;
                        opts?.onProgress?.(percentComplete);
                    }
                },
                false
            );
            xhr.onload = function () {
                if (this.status === 200) {
                    let blob = this.response;
                    try {
                        if (!navigator.msSaveBlob) {
                            let a = document.createElement('a');
                            a.download = fileName;
                            a.href = URL.createObjectURL(blob);
                            document.body.append(a); // 修复firefox中无法触发click
                            a.click();
                            URL.revokeObjectURL(a.href);
                            a.remove();
                        } else {
                            navigator.msSaveBlob(blob, fileName);
                        }
                    } catch (e) {
                        message.error('文件下载异常');
                        return;
                    }
                    callback?.();
                } else if (this.status === 506) {
                    doWarningDownload();
                }
            };
            xhr.send();
            return xhr;
        };
        return getDownload(url, opts?.onFinish);
    }
};
// DownloadTypeMap
export const downloadFileLog = (fileId: String, type: DownloadTypeEnum) => {
    return request({
        api: 'joyspace.downloadRecord.create',
        path: `/v1/pages/downloadRecord`,
        method: 'POST',
        body: { downloadType: type, pageId: fileId },
    })
        .then((data: any) => data)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

export const html2Canvas = async (elem: HTMLElement, isScale?: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (!elem) {
            reject(new Error('生成失败'));
        } else {
            const width = elem.offsetWidth; // dom宽
            const height = elem.offsetHeight; // dom高
            const scale = 2;
            const opts = {
                width,
                height,
                pixelRatio: scale,
                backgroundColor: '#ffffff',
            };
            // console.log(elem, opts);
            try {
                toPng(elem, opts).then((url) => {
                    if (url) {
                        resolve(url);
                    } else {
                        reject(new Error('生成失败'));
                    }
                });
            } catch (e) {
                reject(e);
            }
        }
    });
};

// export const downLoadFile = (fileName: string, url: string) => {
//     const iframe = document.createElement('iframe');
//     iframe.src = url;
//     iframe.id = 'iframe-downLoadFile';
//     iframe.style.display = 'none';
//     document.body.appendChild(iframe);
//     setTimeout(() => {
//         document.body.removeChild(iframe);
//     }, 1000);
// };

export const getFileName = (message: any): string => {
    if (message.fileName) {
        return message.fileName;
    } else if (message.name) {
        return message.name;
    } else if (message.fileType) {
        return `${nanoid()}.${extension(message.fileType)}`;
    } else if (message.type === 'image') {
        return `${nanoid()}.jpg`;
    } else if (message.format) {
        return `${nanoid()}.${message.format}`;
    } else {
        return '';
    }
};

export const getMessageFileName = (message: any): string => {
    const name = `${process.env.REACT_APP_NAME || '京办'}${nyrssfENStr(new Date().getTime())}`;
    if (message.name) {
        return message.name;
    } else if (message.fileName === 'image.png') {
        return `${name}.png`;
    } else if (message.fileName) {
        return message.fileName;
    } else if (message.type === 'image') {
        return `${name}.jpg`;
    } else if (message.format) {
        return `${name}.${message.format === 'video' ? 'mp4' : message.format}`;
    } else if (message.fileType) {
        return `${name}.${extension(message.fileType)}`;
    } else {
        return '';
    }
};

export const getExtName = (name: string): string => {
    return name.substring(name.lastIndexOf('.') + 1);
};

export const renameFileName = (name: string): string => {
    const ext = getExtName(name);
    const idx = name.lastIndexOf('.');
    const newName = name.slice(0, idx) + `_${nanoid()}.${ext}`;
    return newName;
};

export const getFileFilter = (message: any): any => {
    const name = getMessageFileName(message);
    const ext = getExtName(name);
    return [{ name: message.type, extensions: [ext] }];
};

export const checkDownloadFilePathValid = (
    saveName: string,
    file: { path: string; filename: string }
): boolean => {
    const path = file.path || '';
    const filename = file.filename || '';
    return getExtName(saveName) === getExtName(filename) && !path.includes(`Cache\\avatars`);
};

export function openUserCardModal(employee: Employee, actions?: UserCardAction[]) {
    const { userId, teamId, app: appId } = employee;
    Bus.emit('app:toggle-user-card-modal:show', {
        visible: true,
        type: UserCardType.User,
        userId,
        teamId,
        appId,
        applyInfos: [],
        actions,
    });
}

export function isNewOpenChatWin() {
    const href = window.location.href;
    const url = new URL(href);
    const page = url.searchParams.get('page');
    return page === 'chat';
}

export function closeChatWindow() {
    if (isNewOpenChatWin()) {
        if (isFocusEnv()) {
            FocusSDK.closeWindow();
        } else {
            window.close();
        }
    }
}

export function closeWindow() {
    if (isFocusEnv()) {
        FocusSDK.closeWindow();
    } else {
        window.close();
    }
}

export const openCreateSchedule = ({
    title,
    messageResource,
    calendarId,
    selectedSession,
}: {
    title: string;
    messageResource?: any;
    calendarId: string;
    selectedSession: Session;
}) => {
    FocusSDK.sendToMainWin({
        type: 'openCreateSchedule',
        data: {
            visible: true,
            title: title || '',
            messageResource: messageResource,
            frozenCalendarId: calendarId,
            selectedSession: selectedSession,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
};

export const openCreateTask = ({
    title,
    projectId,
    sourceName,
    selectedSession,
}: {
    title: string;
    projectId: string | undefined;
    sourceName: string;
    selectedSession: Session;
}) => {
    FocusSDK.sendToMainWin({
        type: 'openCreateTask',
        data: {
            visible: true,
            title: title || '',
            projectId,
            sourceName,
            selectedSession,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
};

export const openCreateRemind = ({
    selectedSession,
    createData,
}: {
    selectedSession: Session;
    createData: any;
}) => {
    FocusSDK.sendToMainWin({
        type: 'openCreateRemind',
        data: {
            visible: true,
            selectedSession: selectedSession,
            createData: createData,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
};
export const openGroupNotClick = ({
    selectedSession,
    createData,
}: {
    selectedSession: Session;
    createData: any;
}) => {
    FocusSDK.sendToMainWin({
        type: 'openGroupNotClick',
        data: {
            visible: true,
            selectedSession: selectedSession,
            createData: createData,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
};

// 组接龙
export const openCreateSolitaire = ({
    selectedSession,
    createData,
}: {
    selectedSession: Session;
    createData: any;
}) => {
    FocusSDK.sendToMainWin({
        type: 'openCreateSolitaire',
        data: {
            visible: true,
            selectedSession: selectedSession,
            createData: createData,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
};

export const undateGroupNot = ({
    selectedSession,
    createData,
}: {
    selectedSession: Session;
    createData: any;
}) => {
    FocusSDK.sendToMainWin({
        type: 'undateGroupNot',
        data: {
            visible: true,
            selectedSession: selectedSession,
            createData: createData,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
};

export function dragAndSave(e: any, message: any, count?: any) {
    e.stopPropagation();
    // console.log('drag start');
    // console.log('dragMessage', message);
    const name = `${getMessageFileName(message)}`;
    const mimeType = lookup(name);
    const arr = name.split('.');
    const typeName = arr.pop();
    const newName = `${arr.join('.')}${count ? `(${count})` : ''}.${typeName}`;
    // console.log('dragName', newName);
    const url = getFileUrl(message.url);
    e.dataTransfer.setData('DownloadURL', `${mimeType}:${newName}:${url}`);
}

export const getDownloadFileStatus = async (url: string): Promise<number> => {
    return new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onload = function () {
            resolve(this.status);
        };
        xhr.send();
    });
};

let lockTimer = 0;
const TIMER_SHOW_DOWNLOAD = 10 * 60 * 1000;
export const doWarningDownload = () => {
    if (new Date().getTime() - lockTimer < TIMER_SHOW_DOWNLOAD) {
        return;
    }
    prompt({
        title: '当前下载人数较多，请稍后重试',
        cancelText: '',
        okText: '我知道了',
        showCancel: false,
        cancelButtonProps: { style: { display: 'none' } },
    });
    lockTimer = new Date().getTime();
};

// 下载
export const downloadMessageFile = (
    { viewFile, saveAs, message, auto = false, alreadySaveAs }: SaveOption,
    options: DownloadOption
): Promise<void> => {
    return new Promise((resolve, reject) => {
        let config = JSON.parse(FocusSDK.getConfigData());
        const saveName = getMessageFileName(message);
        const defaultPath = isFocusEnv()
            ? config.defaultDownloadPath || FocusSDK.getDownloadPath()
            : localStorage.getItem('downloadFilePath') || undefined;
        const url = getFileUrl(message.url);
        let currDownload: DownloadItem;
        function doDownload(filename: string) {
            try {
                FocusSDK.downloadFile(url, {
                    saveAs,
                    viewFile,
                    defaultPath: saveAs
                        ? localStorage.getItem('downloadFilePath') || defaultPath
                        : defaultPath,
                    filename,
                    alreadySaveAs,
                    filter: getFileFilter(message),
                    onStarted: (downloadItem: DownloadItem) => {
                        options.onStarted(downloadItem);
                        currDownload = downloadItem;
                    },
                    onProgress: async (progress) => {
                        if (progress.totalBytes === 0 && progress.status === 'interrupted') {
                            // 下载中断，获取状态
                            const code = await getDownloadFileStatus(url);
                            if (code === 506) {
                                DownloadFileQueue.getInstance().clearQueue();
                                doWarningDownload();
                                currDownload?.pause();
                                options.onCancel();
                                reject(new Error('download 506'));
                            }
                        } else if (progress.totalBytes === 0) {
                            reject(new Error('total bytes is empty'));
                        } else if (progress.status === 'interrupted') {
                            currDownload?.resume();
                        } else {
                            options.onProgress(progress);
                        }
                    },
                    onCancel: () => {
                        options.onCancel(alreadySaveAs);
                        reject(new Error('cancel'));
                    },
                    onPause: () => {
                        if (options.onPause) {
                            options.onPause();
                        }
                    },
                    onResume: options.onResume,
                    onFinish: (files, data) => {
                        options.onFinish(files, data);
                        resolve(data);
                    },
                    onError: (error) => {
                        console.log(`download error`, error);
                        reject(new Error(JSON.stringify({ error })));
                    },
                });
            } catch (e) {
                reject(e);
                console.log('catch error', e);
            }
        }
        if (auto) {
            const path = `${defaultPath}${detectOS() === 'Win' ? '\\' : '/'}${saveName}`;
            FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                if (exists) {
                    doDownload(renameFileName(saveName));
                } else {
                    doDownload(saveName);
                }
            });
        } else {
            doDownload(saveName);
        }
    });
};

// 加入自动下载
export const pushAutoDownload = (
    message: any,
    currentEmployee: Employee,
    doDownloadMessageFile: (options: SaveOption) => Promise<void>
) => {
    const downloadQueues = DownloadFileQueue.getInstance();
    const config = JSON.parse(FocusSDK.getConfigData());
    const downLocalPath = message.downLocalPath;
    if (
        !downLocalPath &&
        config?.autoDownload &&
        isFocusEnv() &&
        !isEqualEmployee(message.sender, currentEmployee)
    ) {
        downloadQueues.pushQueue({
            data: message,
            dowanload: (options: SaveOption) => {
                return doDownloadMessageFile(options);
            },
            resolve: (res) => {
                console.log(`auto-download--`, res);
            },
            reject: (err) => {
                console.log(`auto-download-error-`, err);
            },
        });
    }
};
