/* eslint-disable no-console */
/* eslint-disable complexity */
import React, { ReactNode } from 'react';
import ImService from '@/server/ImService';
import { message } from 'antd';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import {
    emptySessionMessage,
    SessionSettingService,
    removeChatSession,
} from '@/server/im/SessionService';
import { ClickParam } from 'antd/lib/menu';
import {
    Employee,
    Session,
    MessageStatus,
    ChatMessage,
    EmployeeAttribute,
    SessionType,
} from '@/types/chat';
import { employeeToUid, getLastMessageDesc } from '@/components/chat/utils/message';
import trim from 'lodash/trim';
import IconFont from '@/components/icon';
import { DrawTypeEnum } from '@/components/chat/enum';
import { mattchEmoji } from '@/components/chat/message/Input/Emoji';
import { convertStringToEmoji } from '@/components/chat/utils/ddemoji';
import { stringEncode } from '@/utils/chat/index';
import moment from 'moment';
import { isAINoticeChildSession, isAINoticeSession, isSYSNoticeSession } from '../utils/session';
import { analysisLog } from '@/utils/logAnalytics';

export function useHandleContextMenuClick({
    sessionId,
    selectedSessionId,
    t,
    clearSessionMessage,
    history,
    msg,
}: {
    sessionId: string;
    selectedSessionId: string;
    t: any;
    clearSessionMessage: Function;
    history: any;
    msg: ChatMessage;
}) {
    const removeSession = async () => {
        const result = await removeChatSession(sessionId);
        if (result) {
            message.success(t('success'));
            if (sessionId === selectedSessionId) {
                history.push('/messages');
            }
        } else {
            message.error(t('failed'));
        }
    };

    const emptyMessage = () => {
        const okAction = async () => {
            const result = await emptySessionMessage(sessionId);
            if (result) {
                message.success(t('success'));
                // 清空消息
                // if (sessionId === selectedSessionId) {
                //     await clearSessionMessage({ sessionId });
                // }
                // TODO cxz
                // if (msg) {
                //     const imService = ImService.getInstance();
                //     await imService.deleteMessage(msg as any);
                // }
                // TODO wzp
                if (sessionId) {
                    await clearSessionMessage({ sessionId });
                }
            } else {
                message.error(t('failed'));
            }
        };

        Prompt({
            title: t('session.menus.empty_prompt_title'),
            content: t('session.menus.empty_prompt'),
            okText: t('determine'),
            cancelText: t('cancelText'),
            onOk: okAction,
        });
    };

    const setSessionTop = async (top: boolean) => {
        const result = await SessionSettingService.setSessionTop.call(
            ImService.getInstance(),
            sessionId,
            top // true
        );
        if (result) {
            const tip = top ? 'top_success_tip' : 'untop_success_tip';
            message.success(t(`session.menus.${tip}`));
        } else {
            message.error(t('failed'));
        }
    };

    const setSessionShield = async (shield: boolean) => {
        const result = await SessionSettingService.setSessionShield.call(
            ImService.getInstance(),
            sessionId,
            shield
        );
        if (result) {
            const tip = shield ? 'unshield_success_tip' : 'shield_success_tip';
            message.success(t(`session.menus.${tip}`));
        } else {
            message.error(t('failed'));
        }
    };

    return async (params: ClickParam) => {
        switch (params.key) {
            case 'remove':
                await removeSession();
                break;
            case 'empty':
                await emptyMessage();
                break;
            case 'top':
                await setSessionTop(true);
                break;
            case 'untop':
                await setSessionTop(false);
                break;
            case 'shield':
                await setSessionShield(true);
                break;
            case 'unshield':
                await setSessionShield(false);
                break;
            default:
                break;
        }
    };
}

export function useGetMessageDesc({
    sessionStatusMap,
    currentEmployee,
    selectedSession,
    session,
    allSessionList,
}: {
    sessionStatusMap: any;
    currentEmployee: Employee;
    selectedSession: Session;
    session: Session | null;
    allSessionList: Session[];
}) {
    return (): ReactNode => {
        if (!session) {
            return null;
        }
        let _session = session;
        if (isAINoticeSession(session)) {
            const noticeSessionList = allSessionList.filter(
                (item) => item.isNotice && isAINoticeChildSession(item) && item.lastMsg
            );
            let targetSession: any = noticeSessionList[0];
            if (targetSession) {
                noticeSessionList.forEach((item) => {
                    if (item.lastMsg && (item.lastMsg as any).content) {
                        if (
                            moment(targetSession.lastMsg?.datetime).unix() <
                            moment(item.lastMsg?.datetime).unix()
                        ) {
                            targetSession = item;
                        }
                    }
                });
                _session = {
                    ...targetSession,
                    lastMsg: {
                        ...(targetSession.lastMsg || {}),
                        pin: (_session.info as Employee).userId,
                    },
                };
            }
        }
        const sessionStatusInfo = sessionStatusMap[_session.sessionId] || {};
        if (selectedSession.sessionId === _session.sessionId) {
            if (!_session.lastMsg) {
                return null;
            }
            return (
                <p
                    className="dangerous-inner-html"
                    dangerouslySetInnerHTML={{
                        __html: getLastMessageDesc(
                            _session?.lastMsg || null,
                            _session.sessionType,
                            currentEmployee,
                            false
                        ),
                    }}
                />
            );
        } else if (
            sessionStatusInfo &&
            sessionStatusInfo.status === MessageStatus.EDITING &&
            trim(sessionStatusInfo.info.content)
        ) {
            return (
                <div className="edit-icon">
                    <IconFont type="iconapp_ic_draft" />
                    <p
                        className="edit-content"
                        dangerouslySetInnerHTML={{
                            __html: mattchEmoji(
                                convertStringToEmoji(stringEncode(sessionStatusInfo.info.content))
                            ),
                        }}
                    />
                </div>
            );
        } else {
            if (!_session.lastMsg) {
                return null;
            }
            const lastMsg = _session?.lastMsg as ChatMessage;
            return (
                <p
                    className="dangerous-inner-html"
                    dangerouslySetInnerHTML={{
                        __html: getLastMessageDesc(
                            lastMsg || null,
                            session.sessionType,
                            currentEmployee,
                            false
                        ),
                    }}
                />
            );
        }
    };
}

export function useHandleSessionClick({
    selectedSession,
    session,
    launchSingleSession,
    initSelectedSession,
    launchGroupSession,
    launchNoticeSession,
    setCallBack,
    changeSelectMore,
    updateMessages,
    history,
    closeSetting,
    basename,
}: {
    selectedSession: Session;
    session: Session | null;
    launchSingleSession: Function;
    initSelectedSession: Function;
    launchGroupSession: Function;
    launchNoticeSession: Function;
    setCallBack?: Function;
    changeSelectMore: Function;
    updateMessages: Function;
    history: any;
    closeSetting?: () => void;
    basename: string;
}) {
    return async () => {
        if (!session) {
            return null;
        }
        // console.log('common session', selectedSession, session);
        if (isAINoticeSession(session)) {
            analysisLog('Xtbg_Msg_AI', 'Home');
        }
        if (selectedSession.sessionId !== session.sessionId) {
            changeSelectMore({ selectMore: false });
            updateMessages({ messages: [] });
            const { isSingle, info, sessionId, id, isNotice, isSecret } = session;
            const { REACT_APP_ROUTE_BASE, REACT_APP_BUILD_ELECTRON } = process.env;
            let currentBasename = '';
            if (REACT_APP_BUILD_ELECTRON === 'true') {
                currentBasename = basename;
            } else {
                currentBasename = REACT_APP_ROUTE_BASE || basename || '';
            }
            if (isSingle && !isNotice) {
                const { app, teamId, userId } = info as EmployeeAttribute;
                if (!userId || !app || !teamId) {
                    return;
                }
                const prevInfo = selectedSession.info as EmployeeAttribute;
                // if (
                //     prevInfo &&
                //     prevInfo.app === app &&
                //     prevInfo.teamId === teamId &&
                //     prevInfo.userId === userId &&
                //     selectedSession.isSecret !== isSecret
                // ) {
                //     await initSelectedSession({ selectedSession: {} });
                // }
                const type = isSecret ? SessionType.SECRET_SINGLE : SessionType.SINGLE;
                launchSingleSession({ user: employeeToUid(info as EmployeeAttribute), type });
                // window.history.pushState(
                //     {},
                //     '',
                //     `${currentBasename}/messages/s/${userId}${
                //         isSecret ? ':secret' : ''
                //     }?app=${app}&tid=${teamId}&secret=${isSecret}`
                // );
                history.push(
                    `/messages/s/${userId}${
                        isSecret ? ':secret' : ''
                    }?app=${app}&tid=${teamId}&secret=${isSecret}`
                );
                closeSetting?.();
            } else if (session.isGroup) {
                if (!sessionId) {
                    return;
                }
                launchGroupSession({ sid: sessionId || id });
                // window.history.pushState(
                //     {},
                //     '',
                //     `${currentBasename}/messages/g/${sessionId || id}`
                // );
                history.push(`/messages/g/${sessionId || id}`);
                closeSetting?.();
            } else if (isNotice) {
                launchNoticeSession({ sid: sessionId || id });
                // window.history.pushState(
                //     {},
                //     '',
                //     `${currentBasename}/messages/n/${sessionId || id}`
                // );
                history.push(`/messages/n/${sessionId || id}`);
                closeSetting?.();
            }
        }
        if (setCallBack) {
            setCallBack(session);
        }
    };
}

export function useHandleAITabClick({
    selectedAITab,
    session,
    launchAINoticeTabSession,
    setCallBack,
    changeSelectMore,
    updateMessages,
    history,
    closeSetting,
}: {
    selectedAITab: Session & { pin: string };
    session: (Session & { pin: string }) | null;
    launchAINoticeTabSession: Function;
    setCallBack?: Function;
    changeSelectMore: Function;
    updateMessages: Function;
    history: any;
    closeSetting?: () => void;
}) {
    return () => {
        if (!session) {
            return null;
        }
        if (selectedAITab.pin !== session.pin) {
            const { sessionId, id, pin } = session;
            // changeSelectMore({ selectMore: false });
            // updateMessages({ messages: [] });
            launchAINoticeTabSession({ pin, sessionId });
            // window.history.pushState({}, '', `/messages/n/${sessionId || id}`);
            // history.push(`/messages/n/${sessionId || id}`);
            closeSetting?.();
        }
        if (setCallBack) {
            setCallBack(session);
        }
    };
}

// 创建密聊 以及密聊和普通聊天切换
export function useHandleSessionTypeClick({
    selectedSession,
    launchSingleSession,
    initSelectedSession,
    closeConfirmModal,
    updateMessages,
    changeSelectMore,
}: {
    selectedSession: Session;
    launchSingleSession: Function;
    initSelectedSession: Function;
    closeConfirmModal?: () => void;
    changeSelectMore: Function;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}) {
    return async () => {
        if (!selectedSession) {
            return null;
        }
        const info = selectedSession.info as EmployeeAttribute;
        const { app, teamId, userId } = info;
        if (!userId || !app || !teamId) {
            return;
        }
        const type = selectedSession.isSecret ? SessionType.SINGLE : SessionType.SECRET_SINGLE;
        // await initSelectedSession({ selectedSession: {} });
        await launchSingleSession({
            user: employeeToUid(info),
            type: type,
        });
        changeSelectMore({ selectMore: false });
        updateMessages({ messages: [] });
        closeConfirmModal?.();
    };
}

// const removeSession = async () => {
//     const instance = ImService.getInstance();
//     const result = await instance.removeSession(sessionProps.sessionId);
//     if (result) {
//         message.success(t('success'));
//         if (sessionProps.sessionId === selectedSession.sessionId) {
//             window.history.pushState({}, '', `/messages`);
//         }
//     } else {
//         message.error(t('failed'));
//     }
// };
//
// const emptyMessage = () => {
//     const okAction = async () => {
//         const result = ImService.getInstance().emptySession(sessionProps.sessionId);
//         if (result) {
//             message.success(t('success'));
//             // 清空消息
//             if (sessionProps.sessionId === selectedSession.sessionId) {
//                 await clearSessionMessage({ sessionId: sessionProps.sessionId });
//             }
//         } else {
//             message.error(t('failed'));
//         }
//     };
//
//     Prompt({
//         title: t('session.menus.empty_prompt_title'),
//         content: t('session.menus.empty_prompt'),
//         okText: t('determine'),
//         cancelText: t('cancelText'),
//         onOk: okAction,
//     });
// };
//
// const setSessionTop = async (top: boolean) => {
//     const result = await SessionSettingService.setSessionTop.call(
//         ImService.getInstance(),
//         sessionProps.sessionId,
//         top // true
//     );
//     if (result) {
//         const tip = top ? 'top_success_tip' : 'untop_success_tip';
//         message.success(t(`session.menus.${tip}`));
//     } else {
//         message.error(t('failed'));
//     }
// };
//
// const setSessionShield = async (shield: boolean) => {
//     const result = await SessionSettingService.setSessionShield.call(
//         ImService.getInstance(),
//         sessionProps.sessionId,
//         true
//     );
//     if (result) {
//         const tip = shield ? 'unshield_success_tip' : 'shield_success_tip';
//         message.success(t(`session.menus.${tip}`));
//     } else {
//         message.error(t('failed'));
//     }
// };
//
// const handleContextMenuClick = async (params: ClickParam) => {
//     switch (params.key) {
//         case 'remove':
//             await removeSession();
//             break;
//         case 'empty':
//             emptyMessage();
//             break;
//         case 'top':
//             await setSessionTop(true);
//             break;
//         case 'untop':
//             await setSessionTop(false);
//             break;
//         case 'shield':
//             await setSessionShield(true);
//             break;
//         case 'unshield':
//             await setSessionShield(false);
//             break;
//         default:
//             break;
//     }
// };
// const getMessageDesc = (): ReactNode => {
//     const sessionStatusInfo = sessionStatusMap[sessionProps.sessionId] || {};
//     if (selectedSession.sessionId === sessionProps.sessionId) {
//         return (
//             <p
//                 className="dangerous-inner-html"
//                 dangerouslySetInnerHTML={{
//                     __html: getLastMessageDesc(
//                         session?.lastMsg || null,
//                         sessionProps.sessionType,
//                         currentEmployee,
//                         false
//                     ),
//                 }}
//             />
//         );
//     } else if (
//         sessionStatusInfo &&
//         sessionStatusInfo.status === MessageStatus.EDITING &&
//         trim(sessionStatusInfo.info.content)
//     ) {
//         return (
//             <div className="edit-icon">
//                 <IconFont type="iconapp_ic_draft" />
//                 <span className="edit-content">{sessionStatusInfo.info.content}</span>
//             </div>
//         );
//     } else {
//         const lastMsg = session?.lastMsg as ChatMessage;
//         return (
//             <p
//                 className="dangerous-inner-html"
//                 dangerouslySetInnerHTML={{
//                     __html: getLastMessageDesc(
//                         lastMsg || null,
//                         sessionProps.sessionType,
//                         currentEmployee,
//                         false
//                     ),
//                 }}
//             />
//         );
//     }
// };

// const handleClick = () => {
//     if (!session) {
//         return null;
//     }
//     if (selectedSession.sessionId !== session.sessionId) {
//         const { isSingle, info, sessionId, id, isNotice } = session;
//         if (isSingle && !isNotice) {
//             const { app, teamId, userId } = info as EmployeeAttribute;
//             if (!userId || !app || !teamId) {
//                 return;
//             }
//             launchSingleSession({ user: employeeToUid(info as EmployeeAttribute) });
//             window.history.pushState({}, '', `/messages/s/${userId}?app=${app}&tid=${teamId}`);
//         } else if (session.isGroup) {
//             if (!sessionId) {
//                 return;
//             }
//             launchGroupSession({ sid: sessionId || id });
//             window.history.pushState({}, '', `/messages/g/${sessionId || id}`);
//         } else if (isNotice) {
//             launchNoticeSession({ sid: sessionId || id });
//             window.history.pushState({}, '', `/messages/n/${sessionId || id}`);
//         }
//     }
//     if (setCallBack) {
//         setCallBack();
//     }
// };
