import React, { useContext } from 'react';
import { LoginFormContext } from './utils';
import { useTranslation } from 'react-i18next';
import styles from '@/components/styles';

export default function ResendCode(props?: { callback?: any }) {
    const { navigatePath, resendLastTs, sendVerifyCode, setResendLastTs } = React.useContext(
        LoginFormContext
    );
    const { t } = useTranslation('common');
    React.useEffect(() => {
        if (resendLastTs < -1) {
            return;
        }

        setTimeout(() => {
            setResendLastTs(resendLastTs - 1);
        }, 1000);
    }, [resendLastTs, navigatePath, setResendLastTs]);

    if (resendLastTs > 0) {
        return (
            <div
                style={{
                    textAlign: 'left',
                }}
            >
                {t('login.resendVerifyCodePl', { ts: resendLastTs })}
            </div>
        );
    }

    if (navigatePath !== 'step2') {
        return null;
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    cursor: 'pointer',
                    color: styles.color.primary2,
                }}
                onClick={async () => {
                    let a = await sendVerifyCode();
                    props?.callback?.();
                }}
            >
                {t('login.resend') + t('login.verifyCode')}
            </div>
        </div>
    );
}
