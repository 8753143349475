import React, { useEffect, useState } from 'react';
import UIComp from './UIComp';
import { getTaskDetail } from '@/api/work';
import { PRIORITY_TYPE_MAP } from '@/components/Projects/emnu';
import { PRIORITY_TYPE } from '@/types/newWork';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { replaceGovUrl } from '@/utils/joyspace';
import { connect } from 'dvajs';
import { dealTime } from './util';
import { getTaskStatus } from '@/components/Work/newUtils';
import style from './index.module.less';

export interface IProps {
    // 任务或者日程id
    businessId?: any;
    userData: any;
}

function WorkCard({ businessId, userData }: IProps) {
    const [title, setTitle] = useState('');
    const [timeInfo, setTimeInfo] = useState('');
    const [icon, setIcon] = useState('');
    const [visable, setVisable] = useState(false);
    const [businessInfo, setBusinessInfo]: [any, any] = useState(null);

    function clickHandler() {
        const { teamUserInfo, userId } = userData;
        const { ddAppId, teamId, realName } = teamUserInfo;
        const url = DeeplinkEvent.strify({
            appId: ddAppId,
            path: DeeplinkPathEnum.Task_Draw,
            mparam: {
                taskId: businessId,
                taskName: businessInfo.title,
                from: 'mobile',
                priorityType: businessInfo.priorityType,
                dueTag: businessInfo.dueTag,
                endTime: businessInfo.endTime,
                startTime: businessInfo.startTime,
                content: businessInfo.content,
            },
        });
        DeeplinkEvent.open(replaceGovUrl(url), { source: 'imShareCard', chat: false });
    }
    useEffect(() => {
        getTaskDetail(businessId)
            .then(([res]: any[]) => {
                setTitle(`任务：${res.title}`);
                setBusinessInfo(res);
                setTimeInfo(dealTime(res.startTime, res.endTime));
                const priorityType: PRIORITY_TYPE = res.priorityType;
                priorityType !== PRIORITY_TYPE.NO_PRIORITY &&
                    setIcon(PRIORITY_TYPE_MAP[priorityType].url);
                setVisable(true);
            })
            .catch((err) => {
                setVisable(false);
            });
    }, [businessId]);
    return visable ? (
        <UIComp
            title={title}
            icon={icon}
            clickHandler={clickHandler}
            timeInfoRender={() => {
                const showRe = getTaskStatus(businessInfo.taskStatus);
                return (
                    <>
                        {timeInfo ? timeInfo : businessInfo.taskId ? '暂无起止时间' : ''}
                        {businessInfo.taskId && (
                            <span
                                className={style.tag}
                                style={{
                                    marginLeft: 15,
                                    backgroundColor: `${showRe?.bgcolor}`,
                                    color: `${showRe?.color}`,
                                }}
                            >
                                {showRe?.title}
                            </span>
                        )}
                        {businessInfo.dueTag && (
                            <span
                                className={style.tag}
                                style={{
                                    marginLeft: 15,
                                    backgroundColor: 'rgba(238,10,36,.1)',
                                    color: '#EE0A24',
                                }}
                            >
                                逾期
                            </span>
                        )}
                    </>
                );
            }}
        />
    ) : null;
}

function mapStateToProps({ calendar, user }: any) {
    return {
        userData: user.userData?.user,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkCard);
