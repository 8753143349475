/**
 * 单人呼叫页面
 * @author zhangpengcheng15
 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Input, Modal, message } from 'antd';
import AudioMp3 from '@/assets/ringoutdou.mp3';
import { ToUserType, sendCloudLinkCallMessage } from '../utils';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { FocusSDK } from '@/utils';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';
import {
    changeCloudLinkStatus,
    CloudLinkSessionStateEnum,
    CloudLinkStatusEnum,
    getCloudLinkStatus,
} from '../Connector';
import { createMeeting } from '@/api/meeting';
import dayjs from 'dayjs';
import { MeetingPlatform } from '@/types/common';
import logger from '@/utils/logger';
import { changeEgovUrl } from '@/utils/tools';
import { sessionStateContentMap } from '@/baseComponents/Meeting/common';

interface CreateMeetingResult {
    meetingId: string;
    uuid: string;
    topic: string;
    startTime: string;
    duration: number;
    password: string;
    joinUrl: string;
}

export interface JoinMeetingResult {
    meetingNum: string;
    meetingPass: string;
}

interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
    toUser: any;
    meetingData: CreateMeetingResult | null;
}

export default function MeetingCallModal() {
    const [visible, setVisible] = useState(false);
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo, imService, currentEmployee } = useContext(GlobalContext);
    const { teamUserInfo, selectedTeamId, userId } = authInfo;
    const [t_common] = useTranslation('common');
    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
        toUser: null,
        meetingData: null,
    });
    const ref = useRef<any>(null);

    useEffect(() => {
        function handleChatMeetingMessage(data: any) {
            // 没有会议信息时，不处理所有逻辑
            if (!ref.current) {
                return;
            }
            changeCloudLinkStatus(CloudLinkStatusEnum.BUSY);
            const { ext = {} } = data;
            const { sessionState } = ext;
            if (sessionState === CloudLinkSessionStateEnum.Call) {
                return;
            }
            const { toUser, meetingData, videoDisable, audioDisable } = ref.current;
            // 如果消息的发送方
            if (ext.fromUser.pin === toUser.userId) {
                if (sessionState === CloudLinkSessionStateEnum.UserReceive) {
                    // 加入会议
                    FocusSDK.printLog(
                        `message-content: ${data.content} ${sessionStateContentMap[sessionState]}`
                    );
                    bus.off('cloudlink:receive-chat-message', handleChatMeetingMessage);
                    bus.emit('cloudlink:init');
                    closeModal();

                    FocusSDK.sendIpcMessage('cloudlink.joinMeeting', {
                        meetingInfo: {
                            password: '',
                            meetingNumber: meetingData.meetingId,
                            isvideooff: videoDisable,
                            isaudiooff: audioDisable,
                            uuid: meetingData.uuid,
                        },
                    });
                    closeModal();
                } else if (
                    sessionState === CloudLinkSessionStateEnum.UserReject ||
                    sessionState === CloudLinkSessionStateEnum.TIMEOUT ||
                    sessionState === CloudLinkSessionStateEnum.Busy
                ) {
                    message.info(`${ext.fromUser.name}${sessionStateContentMap[sessionState]}`);
                    bus.off('cloudlink:receive-chat-message', handleChatMeetingMessage);
                    changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                    closeModal();
                }
            }
        }

        async function handleMeetingCreate({
            toUser,
            meetingInfo,
        }: {
            toUser: ToUserType;
            meetingInfo: {
                meetingTopic: string;
                meetingPassword?: string;
                isvideooff?: boolean;
                isaudiooff?: boolean;
            };
        }) {
            if (toUser.userId === authInfo.userId) {
                message.error('无法呼叫自己');
                return;
            }
            if (getCloudLinkStatus() !== CloudLinkStatusEnum.FREE) {
                message.error('请先结束其他会议');
                return;
            }
            if (ref.current?.visible) {
                return;
            }
            ref.current = {
                ...ref.current,
                toUser,
                visible: true,
                audioDisable: true,
                videoDisable: true,
            };
            setVisible(true);

            const [meetingData, err] = await createMeeting({
                startTime: (dayjs().valueOf() + 5000).toString(),
                duration: 2,
                topic: meetingInfo.meetingTopic,
                password: meetingInfo.meetingPassword || '',
                participantIds: [
                    {
                        userId: userId,
                        teamId: authInfo.selectedTeamId,
                        appId: authInfo.ddAppId,
                        realName: currentEmployee.name || '',
                        headImg: authInfo.headImg || '',
                    },
                    {
                        userId: toUser.userId,
                        teamId: toUser.teamId || '',
                        appId: toUser.appId || '',
                        realName: toUser.userName || '',
                        headImg: '',
                    },
                ],
                platform: MeetingPlatform.HW,
            });
            if (err) {
                message.error(err);
                closeModal();
                return;
            }
            ref.current = {
                ...ref.current,
                toUser,
                meetingData,
                audioDisable: true,
                videoDisable: true,
            };
            setState((currentState: any) => {
                return {
                    ...currentState,
                    toUser,
                    meetingData,
                };
            });
            bus.on('cloudlink:receive-chat-message', handleChatMeetingMessage);
            sendCloudLinkCallMessage(
                {
                    meetingTopic: meetingData?.topic || '',
                    meetingNumber: meetingData?.meetingId || '',
                    password: meetingData?.password || '',
                },
                [
                    {
                        name: toUser.userName,
                        userId: toUser.userId,
                        appId: toUser.appId,
                        teamId: toUser.teamId,
                        avatar: toUser.avatar,
                    },
                ]
            );
        }
        bus.off('cloudlink:create single call', handleMeetingCreate);
        bus.on('cloudlink:create single call', handleMeetingCreate);
        return () => {
            bus.off('cloudlink:create single call', handleMeetingCreate);
            FocusSDK.removeAllListeners('cloudlink:single call message');
        };
    }, [authInfo, currentEmployee, imService, selectedTeamId, t_common, teamUserInfo, userId]);

    function closeModal() {
        setVisible(false);
        ref.current = null;
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
            toUser: null,
            meetingData: null,
        });
    }
    function handleMenuClick(key: string) {
        if (key === 'audio') {
            ref.current = {
                ...ref.current,
                audioDisable: !state.audioDisable,
            };
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            ref.current = {
                ...ref.current,
                videoDisable: !state.videoDisable,
            };
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
    }

    function onCancel() {
        // 如果已经发出呼叫消息，关闭的时候发起取消消息
        if (ref.current.meetingData) {
            const { meetingData, toUser } = ref.current;
            logger.debug(ref.current.sendMessage);
            sendCloudLinkCallMessage(
                {
                    meetingTopic: meetingData?.topic || '',
                    meetingNumber: meetingData?.meetingId || '',
                    password: meetingData?.password || '',
                },
                [
                    {
                        name: toUser.userName,
                        userId: toUser.userId,
                        appId: toUser.appId,
                        teamId: toUser.teamId,
                        avatar: toUser.avatar,
                    },
                ],
                CloudLinkSessionStateEnum.Cancel
            );
            changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
        }
        closeModal();
    }
    if (!state.toUser) {
        return null;
    }
    const { avatar, userName: name } = state.toUser;
    return (
        <Modal
            className="desk-me-meeting-call-modal"
            width={780}
            visible={visible}
            maskClosable={false}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            destroyOnClose
            footer={null}
        >
            <div className="me-meeting-call-body">
                <div className="me-meeting-call-container">
                    <div className="avatar-outter">
                        <div className="avatar-inner">
                            <Avatar
                                className="meeting-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name || ''}
                                styleObj={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    lineHeight: 80,
                                    color: '#fff',
                                    fontSize: 22,
                                }}
                            />
                        </div>
                    </div>
                    <div className="meeting-call-name">{name}</div>
                    <div className="meeting-call-desc">{t_common('joinMeeting.connecting')}</div>
                </div>
                <div className="me-meeting-call-actions">
                    <div className="me-meeting-call-menu">
                        <div className="me-meeting-call-menu-container">
                            <span
                                className={`me-meeting-call-menu-item
                                    ${
                                        state.audioDisable
                                            ? 'me-meeting-call-menu-item-disable'
                                            : ''
                                    }`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>

                        <div className="me-meeting-call-menu-container">
                            <span
                                className="me-meeting-call-menu-item me-meeting-call-menu-close"
                                onClick={onCancel}
                            >
                                <IconFont type="iconapp_btn_joymerting_hangup" />
                            </span>
                            <span className="label">{t_common('joinMeeting.Hang up')}</span>
                        </div>

                        <div className="me-meeting-call-menu-container">
                            <span
                                className={`me-meeting-call-menu-item ${
                                    state.videoDisable ? 'me-meeting-call-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <audio src={AudioMp3} loop autoPlay />
            </div>
        </Modal>
    );
}
