import React from 'react';
import { AuthInfo, IFocusSDK } from '@/utils';
import Gateway from '@/server/gateway';
import { TFunction } from 'i18next';
import { GlobalErrorConfig } from '@/utils/GError';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import Service from '@/services';
import { AuthData } from '@/types/LocalConfig';
import ImService from '@/server/ImService';
import Eventemitter3 from 'eventemitter3';
import { IPermissions } from '../types/common';
import { UserCardData } from '@/types/UserCard';
export interface PreviewImageInfo {
    id: string;
    sessionId: string;
}
export interface GlobalContextConfig {
    t: TFunction;
    authInfo: AuthData;
    setAuthInfo: (d: AuthData) => void;
    appRuntimeEnv: AppRuntimeEnv;
    gatewayInstance: Gateway;
    service: Service;
    focusSDK: IFocusSDK;
    imService: ImService;
    isDesktop: boolean;
    onGlobalError: (config: GlobalErrorConfig) => void;
    onReLogin: (d: AuthData) => void;
    events3: Eventemitter3;
    permissions: IPermissions[];
    previewImageInfo: PreviewImageInfo;
    currentEmployee: any;
    notifyStatus?: any;
    notifyDis?: boolean;
    isWebpSupported?: boolean;
    isInJBRTCMeeting?: boolean;
    jbrtcMeetingNumber?: any;
}
// @ts-ignore
const GlobalContext = React.createContext<GlobalContextConfig>({});

export default GlobalContext;
