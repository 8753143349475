import React, { useState, useRef, useReducer, ChangeEvent } from 'react';
import ReactDOM from 'react-dom';
import IconFont from '@/components/icon';
import { Modal, Input, Button, message } from 'antd';
import openAddMembersModal from '../../components/Member/selector';
import SelectAvatar from '../../components/Avatar/SelectAvatar';
import { IMember as memberInterface, IGroupChatItem } from '../../types';
import { addGroupChat } from '../../api';
import './index.less';
import { isBjme } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';

interface propTypes {
    projectId: string;
    callback: (value: IGroupChatItem) => void;
    oncancel?: () => void;
    user?: any;
    t: any;
}

function memberReducer(state: memberInterface[], action: { payload: any; type: string }) {
    switch (action.type) {
        case 'add':
            return state.concat(action.payload);
        case 'remove':
            return state.filter((item: memberInterface) => item.userId !== action.payload);
        default:
            return [];
    }
}

export default function CreateGroupChat(props: propTypes) {
    const { user, projectId, callback, t } = props;
    const [visible, setVisible] = useState(true);
    const avatarRef = useRef('');
    const [members, memberDispatch] = useReducer(memberReducer, [
        {
            name: user.teamUserInfo.realName,
            avatar: user.teamUserInfo.headImg,
            userId: user.userId,
            teamId: user.teamUserInfo.teamId,
            app: user.teamUserInfo.ddAppId,
        },
    ]);
    const [groupName, setGroupName] = useState('');
    const [groupNameLength, setGroupNameLength] = useState(20);

    function handleOk() {
        const icon = avatarRef.current as any;
        const users = members.map((item) => ({
            userId: item.userId,
            teamId: item.teamId,
            app: item.app,
        }));
        addGroupChat({
            users,
            projectId,
            groupName,
            icon,
        }).then((res: any) => {
            if (res.errCode) return;
            callback({
                name: groupName,
                gid: res.groupId,
                avatar: icon,
            });
            setVisible(false);
        });
    }
    function handleCancel() {
        setVisible(false);
    }
    function avatarChange(url: string) {
        avatarRef.current = url;
    }

    function addMembers() {
        openAddMembersModal(
            {
                title: t('basic_setting.add_member'),
                teamId: user.teamUserInfo.teamId,
                selected: members,
                currentUser: {
                    app: user.ddAppId,
                    pin: user.userId,
                    teamId: user.selectedTeamId,
                },
            },
            (data: memberInterface[], close: () => void) => {
                memberDispatch({
                    type: 'add',
                    payload: data,
                });
                close();
            }
        );
    }

    function groupNameChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setGroupName(newValue);
        setGroupNameLength(newValue.length - compactValue.length + 20);
    }

    return (
        <Modal
            visible={visible}
            title={t('detail.chat.create')}
            destroyOnClose={true}
            cancelText={t('cancel')}
            okText={t('confirm')}
            onOk={handleOk}
            width={550}
            bodyStyle={{
                height: 330,
                overflowY: 'auto',
            }}
            onCancel={handleCancel}
            okButtonProps={{
                disabled: groupName.trim().length === 0,
            }}
        >
            <div className="pm-createchat-content">
                {/* <span>{t('detail.chat.avatar')}</span>
                <div className="pm-createchat-select-avatar">
                    <SelectAvatar
                        className="pm-createchat-select-avatar-content"
                        onChange={avatarChange}
                        onInit={avatarChange}
                    />
                </div> */}
                <span>{t('detail.chat.name')}</span>
                <div>
                    <Input
                        maxLength={groupNameLength}
                        value={groupName}
                        onChange={groupNameChange}
                    />
                </div>
                <span>{t('detail.chat.member')}</span>
                <div>
                    <Button onClick={addMembers}>{t('basic_setting.add_member')}</Button>
                </div>
                <ul className="pm-createchat-list">
                    {members.map((item) => (
                        <li key={item.userId}>
                            <span className="pm-createchat-user">
                                <span
                                    className="pm-mm-list-avatar"
                                    style={
                                        item.avatar
                                            ? {
                                                  backgroundImage: `url(${changeEgovUrl(
                                                      item.avatar
                                                  )})`,
                                              }
                                            : {}
                                    }
                                >
                                    {item.avatar ? '' : item.name?.[0]}
                                </span>
                                {item.name}
                            </span>
                            {item.userId !== user.userId && (
                                <Button
                                    type="link"
                                    className="pm-createchat-remove-member"
                                    onClick={() =>
                                        memberDispatch({
                                            type: 'remove',
                                            payload: item.userId,
                                        })
                                    }
                                >
                                    <IconFont
                                        type="iconClose"
                                        className="pm-createchat-remove-icon"
                                    />
                                </Button>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
}

export function openCreateGroupChat(props: propTypes) {
    return new Promise((resolve) => {
        ReactDOM.render(
            <CreateGroupChat {...props} oncancel={resolve} />,
            document.createDocumentFragment()
        );
    });
}
