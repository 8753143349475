import { request } from '@/components/Joyspace/api/request';
export function download(id: string) {
    return request({
        api: 'joyspace.file.presign',
        path: `/v1/files/${id}/presign?redirect=0`,
        method: 'GET',
    });
}

export function getFileIconUrl({
    title,
    pageType,
    type,
}: {
    title: string;
    pageType: number;
    type: string;
}) {
    return request({
        api: 'joyspace.common.icons',
        path: `/v1/common/icons?title=${title}&pageType=${pageType}&type=${type}&client=web`,
        method: 'GET',
    });
}

export function postInitFile(body: any) {
    return request({
        api: 'joyspace.file.fileUploadinit',
        path: `/v1/files/init`,
        method: 'POST',
        body,
    });
}

export function postAddFile(body: any) {
    return request({
        api: 'joyspace.file.add',
        path: `/v1/files`,
        method: 'POST',
        body,
    });
}

export async function startUploadWpsTransaction(body: {
    file: { name: string; size: number; md5: string; type: string };
    spaceId?: string;
    categoryId?: string;
    folderId: string;
    attachment: boolean;
}) {
    try {
        const result = await request({
            api: 'joyspace.wps.startUploadTransaction',
            path: `/v1/wps/startUploadTransaction`,
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error];
    }
}
export async function finishUploadWpsTransaction(body: {
    fileType: string;
    transactionId: string;
    parentId: string;
    status: string;
    spaceId?: string;
    categoryId?: string;
    folderId: string;
    feedback: string;
    attachment: boolean;
    pageId: string;
    to_groups?: Array<any>;
}) {
    try {
        const result = await request({
            api: 'joyspace.wps.finishUploadTransaction',
            path: `/v1/wps/finishUploadTransaction`,
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error];
    }
}
