import { JoyspaceState } from '@/models/joyspace';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import events from '../../../context/events';
import Icon, { Icons } from '../../../Icon';
import { Order, Sort } from './config';
import { connect } from 'dvajs';
import './index.less';
import { Dropdown } from 'antd';
import { notUseJoySpaceOffline } from '@/components/Joyspace/util';

export type menuItem<T> = {
    text?: string;
    order: Order;
    sort: string;
} & T;

interface Props<T> {
    onChange?: (o: menuItem<T>) => void;
    restoreOrder?: () => void;
    menu: menuItem<T>[];
    isArrowDownIcon?: boolean;
    filterInfo: any;
    setFilterInfo: Function;
}

function Filters<T>(props: Props<T>) {
    const { isArrowDownIcon = false, filterInfo, setFilterInfo, onChange } = props;
    const [menu, select] = useState({ ...props.menu[0] });
    const [status, setStatus] = useState(false);
    const [isShowDropupIcon, setIsShowDropupIcon] = useState(false);
    const [disabled, setDisabled] = useState(false);
    useMemo(() => {
        if (notUseJoySpaceOffline()) {
            return;
        }
        setDisabled(!navigator.onLine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.onLine]);
    const onChangeMenu = useCallback(
        (parames: menuItem<T>) => {
            const result = props.menu.find((item) => item.sort === parames.sort);
            if (result) {
                const title = result?.text || props.menu[0].text;
                parames.text = title;
                select(parames);
                return;
            }
            select({ ...props.menu[0] });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [menu]
    );
    useEffect(() => {
        onChangeMenu(filterInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterInfo]);
    const menus = useMemo(() => {
        return (
            <div data-menu className="joyspace-layer-menu joypsace-filter-menu-lists">
                <ul>
                    {props.menu.map((option, index) => {
                        return (
                            <li
                                onClick={(e) => {
                                    if (disabled) {
                                        return;
                                    }
                                    e.preventDefault();
                                    e.stopPropagation();
                                    select(option);
                                    // setSelectIndex(index);
                                    setStatus(false);
                                    onChange?.(option);
                                    // console.log(option, 'optionoptionoption');
                                    setFilterInfo(option.order, option.sort);
                                }}
                                key={index}
                            >
                                <span
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {option.text}
                                </span>
                                {/* {index === selectIndex && Icons.dropfinishMenu} */}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }, [disabled, onChange, props.menu, setFilterInfo]);
    return (
        <div
            className={
                disabled
                    ? 'joyspace-list-filter joyspace-select-filter_disabled'
                    : 'joyspace-list-filter '
            }
        >
            <Dropdown trigger={['click']} overlay={menus} disabled={disabled}>
                <div className="joyspace-select-filter">
                    <span data-title>{menu.text?.substring(0, menu.text.length - 2)}</span>
                    {isArrowDownIcon ? (
                        <Icon
                            className={menu.order === Order.ASC ? 'icon' : ''}
                            type="iconjs_ilist_arrowdown"
                        />
                    ) : (
                        <Icon
                            className={menu.order === Order.ASC ? 'icon' : ''}
                            type="iconic_app_paixu"
                        />
                    )}
                </div>
            </Dropdown>
        </div>
    );
}

function mapStateToProps({ joyspace }: { joyspace: JoyspaceState }) {
    // console.log(joyspace, ' joyspace.folderInfo===>');
    return {
        filterInfo: joyspace.filterInfo,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setFilterInfo(order = Order.DESC, sort = Sort.UpDateTime) {
            return dispatch({
                type: 'joyspace/setFilterInfo',
                payload: {
                    order,
                    sort,
                },
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
