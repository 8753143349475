// 左侧头像

import React, { useCallback, useMemo } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { SessionType } from '@/types/chat';
import ownerPng from './pic/badge@3x.png';
import adminPng from './pic/admin@3x.png';
import { HeadShowType } from '@/types/chat/enum';
import { Avatar } from '@/baseComponents/Avatar';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { changeEgovUrl } from '@/utils/tools';
interface HeadPortraitProps {
    width: any; // 宽
    height: any; // 高
    imgUrl: string; // 头像地址
    name: string; // 名称
    showType?: HeadShowType;
    type?: SessionType; // 事件
    numPieces?: number; // 右上角消息数量
    isLeader?: boolean; // 是否是群主
    isUnFollow?: boolean; // 是否右击出发取消置顶，true为取消
    onOpenSession?: Function;
    onItemClick?: Function;
    mentions?: any;
    currentEmployee?: any;
    clstag?: string;
    isAdmin?: boolean;
    desc?: string; // IM组成员列表显示单位/部门+职位
}

type IProps = Readonly<HeadPortraitProps>;
function HeadPortrait(props: IProps) {
    const {
        numPieces,
        name,
        showType,
        width,
        height,
        imgUrl,
        onOpenSession,
        onItemClick,
        mentions,
        currentEmployee,
        desc,
        type,
    } = props;

    const getNumPiecesPart = () => {
        if (mentions && Object.values(mentions).length > 0) {
            const index = Object.values(mentions).findIndex((item: any) => {
                return (
                    item.users.findIndex((user: any) => {
                        return user?.userId === currentEmployee.userId || user?.userId === 'all';
                    }) > -1
                );
            });
            if (index > -1) {
                return (
                    <div className="num-pieces">
                        <div className="number" id="aite">
                            @
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } else if (numPieces) {
            return (
                <div
                    className={classNames({
                        'num-pieces': true,
                        more: Number(numPieces) > 9,
                    })}
                >
                    {Number(numPieces) > 99 ? (
                        <IconFont className="number" type="iconapp_btn_joymerting_more" />
                    ) : (
                        <div className="number">{numPieces}</div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };
    const onClick = useCallback(() => {
        if (showType === HeadShowType.TOP) {
            if (onOpenSession) {
                onOpenSession();
            }
        } else if (showType === HeadShowType.GROUP_MEMBER || HeadShowType.SESSION_LIST) {
            if (onItemClick) {
                onItemClick();
            }
        }
    }, [onItemClick, onOpenSession, showType]);

    const getNamePart = useCallback(() => {
        if (showType === HeadShowType.GROUP_MEMBER || (showType === HeadShowType.TOP && name)) {
            return (
                <div className="name-box">
                    <div className="user-name">{name}</div>
                </div>
            );
        }
    }, [name, showType]);

    const getImagePart = useCallback(() => {
        const headImgThumbUrl = getHeadImgThumbUrl(imgUrl, 150, 150);
        // console.log('0000000', headImgThumbUrl);

        if (!headImgThumbUrl) {
            return <Avatar name={name} styleObj={{ width, height }} onClick={onClick} />;
        }
        // console.log(headImgThumbUrl, 115);
        return (
            <span clstag={props.clstag}>
                <Avatar
                    src={changeEgovUrl(headImgThumbUrl)}
                    name={name}
                    styleObj={{ width, height }}
                    onClick={onClick}
                    className="avatarNoDraggable"
                />
            </span>
        );
    }, [imgUrl, width, height, onClick, name, props.clstag]);

    const toolTipTitle = useMemo(() => {
        if (showType === HeadShowType.TOP) {
            if (name.length >= 13) {
                return name.slice(0, 13).concat('...');
            } else {
                return name;
            }
        }
    }, [name, showType]);

    return (
        <Tooltip title={toolTipTitle} overlayClassName="head-portrait-tooltip">
            <div className="head-portrait-container">
                <div className="head-img">
                    {showType === HeadShowType.TOP && type === SessionType.SECRET_SINGLE && (
                        <div className="secret-head-img_mask">
                            <IconFont type="icona-ic_app_im_Secretchat_facet" />
                        </div>
                    )}
                    {getImagePart()}
                    {(props.isLeader || props.isAdmin) && (
                        <img
                            onDragStart={(e) => {
                                return false;
                            }}
                            src={changeEgovUrl(props.isLeader ? ownerPng : adminPng)}
                            alt=""
                            className="especially"
                            draggable="false"
                        />
                    )}
                    {getNumPiecesPart()}
                </div>
                {/* {getNamePart()} */}
                {desc ? (
                    <div className="name-wrapper">
                        {getNamePart()}
                        <div className="desc">{desc}</div>
                    </div>
                ) : (
                    getNamePart()
                )}
            </div>
        </Tooltip>
    );
}

export default HeadPortrait;
