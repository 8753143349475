import { request } from '@/components/Joyspace/api/request';

export function add(id: string, type: number) {
    return request({
        api: 'joyspace.shortcut.create',
        path: `/v1/shortcuts`,
        method: 'POST',
        body: {
            target_id: id,
            target_type: type,
        },
    }).then(() => true);
}

export function remove(id: string, type: number) {
    return request({
        api: 'joyspace.shortcut.remove',
        path: `/v1/shortcuts/${id}/${type}`,
        method: 'DELETE',
    })
        .then(() => true)
        .catch(() => false);
}
