import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'dvajs';
import { getThirdEmail } from '@/api/email';
import Loading from '@/components/Loading';
import { UserState } from '@/models/user';
import GlobalContext from '@/context/GlobalContext';
import IconFont from '@/components/icon';
import { message, Tabs } from 'antd';
import './thirdEmailApp.less';
import { changeEgovUrl } from '@/utils/tools';
import { getAuthorizationCode } from '@/api/user';
import { FocusSDK } from '@/utils';
// export const ThirdEmailAppId = 'TfC2XSfEOyuey2PHyY2O';
const ThirdEmailAppKey = 'WxzhJMH8RYsLkwupQXu7';

function ThirdEmailApp(props: any) {
    const { TabPane } = Tabs;
    const { userData } = props;
    const { appRuntimeEnv } = useContext(GlobalContext);
    const [emailUrl, setEmailUrl] = useState<string>('');

    useEffect(() => {
        if (!userData?.user?.userId) {
            return;
        }
        getAuthorizationCode({
            appKey: ThirdEmailAppKey,
        }).then(([res, err]) => {
            if (err || !res) {
                let msg = err;
                msg && message.error(msg);
                return;
            }
            getThirdEmail(res.code).then(
                (data: any) => {
                    let url = data.url;
                    if (FocusSDK.isPrivateNet()) {
                        url = url.replace('beijing.gov.cn', 'beijing.egov.cn');
                    }
                    setEmailUrl(url);
                },
                (error) => {
                    error?.message && message.error(error.message);
                }
            );
        });
    }, [appRuntimeEnv, userData]);

    if (!emailUrl) {
        return <Loading />;
    }

    return (
        <div
            id="thirdEmailContainer"
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Tabs type="card" defaultActiveKey="1" style={{ height: '100%' }}>
                <TabPane
                    id="thirdEmailTabPane"
                    style={{ height: '100%', flex: '1 1 auto' }}
                    tab={
                        <span>
                            <IconFont type="iconapp_btn_pc_mail" />
                            邮箱
                        </span>
                    }
                    key="1"
                >
                    <iframe
                        width="100%"
                        height="100%"
                        src={changeEgovUrl(emailUrl)}
                        frameBorder="0"
                    />
                </TabPane>
            </Tabs>
        </div>
    );
}

function mapStateToProps({ user }: { user: UserState }) {
    return {
        userData: user.userData,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdEmailApp);
