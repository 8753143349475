import BaseService from '@/attributes/BaseService';
// interface BaseService {
//     getList: Function;
//     setList: Function;
// }
class ServiceList extends BaseService {
    /* eslint-disable max-params */
    async getPagingDBList(propertyKey: string, start: number, length = 10, val?: string) {
        try {
            const doc = await this.dbService.getFromDB(propertyKey);
            let value = doc?.value || [];
            const data: any = {};
            data.total = value.length;
            data[val || 'value'] = value.splice(start, length);
            return data;
        } catch (e) {
            throw new Error(e);
        }
    }
    async setPagingDBList(propertyKey: string, list: any, start: number, length: number) {
        return new Promise((resolve, reject) => {
            this.dbService.getFromDB(propertyKey).then((doc: any) => {
                if (doc && start > 0) {
                    const value = doc.value;
                    value.splice(start, length, ...list);
                    doc.value = value;
                    this.dbService.saveToDB(doc);
                    // eslint-disable-next-line no-debugger
                    // debugger;
                    resolve('操作成功');
                } else {
                    const doc = {
                        _id: propertyKey,
                        value: list,
                    };
                    console.log(doc, 'doc=====>');
                    this.dbService.saveToDB(doc);
                    // eslint-disable-next-line no-debugger
                    // debugger;
                    resolve('操作成功');
                }
            });
        });
    }

    setDBList(propertyKey: string, data: any) {
        return new Promise((resolve, reject) => {
            const doc = {
                _id: propertyKey,
                value: data,
            };
            this.dbService.saveToDB(doc);
            resolve('操作成功');
        });
    }

    getDBList(propertyKey: string) {
        return new Promise((resolve, reject) => {
            this.dbService
                .getFromDB(propertyKey)
                .then((doc: any) => {
                    resolve(doc?.value);
                })
                .catch((e: any) => {
                    console.error(e);
                    reject(e);
                });
        });
    }
    async remove(propertyKey: string, id: string) {
        try {
            const doc = await this.dbService.getFromDB(propertyKey);
            let value = doc?.value;
            let index = value.findIndex((item: any) => item.id === id);
            if (index !== -1) {
                value.splice(index, 1);
                this.dbService.setPagingDBList(propertyKey, value, 0, value.length);
            }
            return true;
        } catch (error) {
            return false;
        }
    }
}

// new ServiceList('joyspace.db', 'lists')
export default ServiceList;
