import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
type Type = 'phone' | 'code';
export default function InputWithCtr(props: any) {
    const { value, onChange, type } = props;
    function onChangeHandl(e: any) {
        const rawVal = e.target.value;
        const preVal = value;
        let ctrlValue = rawVal.split(' ').join('');
        let valueLen = ctrlValue.length;
        let el = e.target;
        let prePos = 0;
        try {
            // some input type do not support selection, see https://html.spec.whatwg.org/multipage/input.html#do-not-apply
            prePos = el.selectionEnd || 0;
        } catch (error) {
            console.warn('Get selection error:', error);
        }
        switch (type) {
            case 'phone':
                if (valueLen > 3 && valueLen < 8) {
                    ctrlValue = ctrlValue.substr(0, 3) + ' ' + ctrlValue.substr(3);
                } else if (valueLen >= 8) {
                    ctrlValue =
                        ctrlValue.substr(0, 3) +
                        ' ' +
                        ctrlValue.substr(3, 4) +
                        ' ' +
                        ctrlValue.substr(7);
                }
                break;
            case 'code':
                if (valueLen > 4) {
                    ctrlValue = ctrlValue.substr(0, 4) + ' ' + ctrlValue.substr(4);
                }
                break;
        }
        handleOnChange(ctrlValue, ctrlValue !== rawVal, function () {
            switch (type) {
                case 'phone':
                    // controlled input type needs to adjust the position of the caret
                    try {
                        // some input type do not support selection, see https://html.spec.whatwg.org/multipage/input.html#do-not-apply
                        let pos = calcPos(prePos, value, rawVal, ctrlValue, [' '], /\D/g);
                        el.selectionStart = el.selectionEnd = pos;
                    } catch (error) {
                        console.warn('Set selection error:', error);
                    }
                    break;
                case 'code':
                    // controlled input type needs to adjust the position of the caret
                    try {
                        // some input type do not support selection, see https://html.spec.whatwg.org/multipage/input.html#do-not-apply
                        let pos = calcPos(prePos, value, rawVal, ctrlValue, [' '], /\*/g);
                        el.selectionStart = el.selectionEnd = pos;
                    } catch (error) {
                        console.warn('Set selection error:', error);
                    }
                    break;
                default:
                    break;
            }
        });
    }
    function handleOnChange(value: string, a: any, b: any) {
        let isMutated = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        let adjustPos =
            arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
        // let onChange = onChange;

        // if (!('value' in _this.props)) {
        //     _this.setState({ value: value });
        // } else {
        //     _this.setState({ value: _this.props.value });
        // }
        if (onChange) {
            if (isMutated) {
                setTimeout(function () {
                    onChange(value);
                    adjustPos();
                });
            } else {
                onChange(value);
                adjustPos();
            }
        } else {
            adjustPos();
        }
    }
    // eslint-disable-next-line max-params
    function calcPos(
        prePos: number,
        preCtrlVal: string,
        rawVal: string,
        ctrlVal: string,
        placeholderChars: any[],
        maskReg: any
    ) {
        let editLength = rawVal.length - preCtrlVal.length;
        let isAddition = editLength > 0;
        let pos = prePos;
        if (isAddition) {
            let additionStr = rawVal.substr(pos - editLength, editLength);
            let ctrlCharCount = additionStr.replace(maskReg, '').length;
            pos -= editLength - ctrlCharCount;
            let placeholderCharCount = 0;
            while (ctrlCharCount > 0) {
                if (
                    placeholderChars.indexOf(
                        ctrlVal.charAt(pos - ctrlCharCount + placeholderCharCount)
                    ) === -1
                ) {
                    ctrlCharCount--;
                } else {
                    placeholderCharCount++;
                }
            }
            pos += placeholderCharCount;
        }
        return pos;
    }
    return <Input {...props} value={value} onChange={onChangeHandl} />;
}
