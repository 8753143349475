import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import ChatState, { ApprExternalContacts } from '@/types/chat/State';
import { Employee, Session } from '@/types/chat';
import { ExternalContactsStatus } from '@/types/chat/enum';
import { Modal, message as toast, Input, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateApprExternalContacts } from '@/api/chat';
import ImService from '@/server/ImService';
import { setExternalContactsTipsTool } from '@/utils/chat/index';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { changeEgovUrl } from '@/utils/tools';
import RefusalCause from '@/components/Modals/ApplyExternalContactsList/RefusalCause';

const ExternalNewUserNoticeCard = connect(
    function ({ chat }: { chat: ChatState }) {
        const { selectedSession } = chat;
        return { selectedSession };
    },
    function (dispatch: any) {
        return {
            setOpenExternalContactsModal(payload: boolean) {
                dispatch({ type: 'ebook/setOpenExternalContactsModal', payload });
            },
            setOpenRefusalCauseModal(payload: boolean) {
                dispatch({ type: 'ebook/setOpenRefusalCauseModal', payload });
            },
            updateCurrentEmployee(payload: any) {
                dispatch({ type: 'chat/updateCurrentEmployee', payload });
            },
        };
    }
)(function ({
    message,
    selectedSession,
    setOpenExternalContactsModal,
    setOpenRefusalCauseModal,
}: {
    message: any;
    toggleUserCardModal: Function;
    selectedSession: Session;
    currentEmployee: Employee;
    setOpenExternalContactsModal: Function;
    setOpenRefusalCauseModal: Function;
}) {
    const { t } = useTranslation('chat');
    const { title, content, infox, extend } = message;
    const [externalContacts, setExternalContacts] = useState(ExternalContactsStatus.WAIT);

    // 审核通过
    const approvedAction = useCallback(async () => {
        Modal.confirm({
            title: t('approved'),
            icon: <CheckCircleFilled />,
            className: 'team-apply-modal',
            content: t('approvedConfirm'),
            okText: t('button.ok'),
            cancelText: t('button.cancel'),
            onOk: async () => {
                const param: ApprExternalContacts = {
                    apprId: message.infox.apprId, // 审核ID不能为空
                    apprStatus: 1, // 审核状态不能为空 1-审核成功 2-审核失败
                    apprOpinion: '', // 审核意见最大支持100个长度
                };

                // 邀请正式用户和外部用户审核通过
                const [result, error] = await updateApprExternalContacts(param);

                if (error) {
                    toast.error(error);
                    return;
                }

                const imService = ImService.getInstance();
                let updateResult = await imService.updateApplyExternalContactsList(
                    selectedSession?.sessionId,
                    message,
                    result
                );

                if (updateResult) {
                    setExternalContacts(result);
                }

                if (result === ExternalContactsStatus.DEPT_NOT_EXSIT) {
                    return deptNotNode();
                }

                setExternalContactsTipsTool(result, t);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, selectedSession?.sessionId, t]);

    const deptNotNode = useCallback(() => {
        Modal.confirm({
            // title: t('approved'),
            // icon: <CheckCircleFilled />,
            // className: 'team-apply-modal',
            content: '未查找到申请的单位/部门，请驳回当前申请',
            okText: '驳回',
            cancelText: t('button.cancel'),
            onOk: async () => {
                const param: ApprExternalContacts = {
                    apprId: message.infox.apprId, // 审核ID不能为空
                    apprStatus: 2, // 审核状态不能为空 1-审核成功 2-审核失败
                    apprOpinion: '您申请加入的单位/部门已被删除或有变更，请您重新申请', // 审核意见最大支持100个长度
                };

                // 邀请正式用户和外部用户审核通过
                const [result, error] = await updateApprExternalContacts(param);

                if (error) {
                    toast.error(error);
                    return;
                }

                const imService = ImService.getInstance();
                let updateResult = await imService.updateApplyExternalContactsList(
                    selectedSession?.sessionId,
                    message,
                    result
                );

                if (updateResult) {
                    setExternalContacts(result);
                }

                if (result === ExternalContactsStatus.DEPT_NOT_EXSIT) {
                    return deptNotNode;
                }

                setExternalContactsTipsTool(result, t);
            },
        });
    }, [message, selectedSession?.sessionId, t]);

    // 查看更多
    const seeMoreAction = useCallback(async () => {
        setOpenExternalContactsModal(true);
    }, [setOpenExternalContactsModal]);

    // 驳回
    const regularCheckFail = useCallback(() => {
        setOpenRefusalCauseModal({
            visible: true,
            message: message,
            selectedSession: selectedSession,
        });
    }, [message, selectedSession, setOpenRefusalCauseModal]);

    useEffect(() => {
        const { applyState } = message.infox;
        if (applyState) {
            setExternalContacts(applyState);
        }
    }, [message.infox]);

    const status = useMemo(() => {
        return (
            externalContacts === ExternalContactsStatus.INVALID ||
            externalContacts === ExternalContactsStatus.SUCCESS ||
            externalContacts === ExternalContactsStatus.ALREADY_APPR ||
            externalContacts === ExternalContactsStatus.ALREADY_EXSIT
        );
    }, [externalContacts]);

    const handleClick = useCallback(
        (btn) => {
            // 正式用户：驳回：regularEmployeeCheckFail，
            //           通过：regularEmployeeCheckPass，
            //           已通过：regularEmployeePass，
            //           已驳回：regularEmployeeFail
            switch (btn.action) {
                case 'checkMore':
                    return seeMoreAction();
                case 'checkPass':
                    return approvedAction();
                case 'checkFail':
                    return regularCheckFail();
                case 'regularEmployeeCheckFail':
                    return regularCheckFail();
                case 'regularEmployeeCheckPass':
                    return approvedAction();
            }
        },
        [approvedAction, regularCheckFail, seeMoreAction]
    );

    console.log(extend, 9999);

    return (
        <ComNoticeCard
            avatarSrc={changeEgovUrl(message.sender.avatar)}
            type={extend.applicationName}
            contentList={extend.fields}
            title={title}
            handleDetail={() => {
                seeMoreAction();
            }}
            loadButtonsFn={
                extend?.btns?.length > 0
                    ? () => {
                          return (
                              <div className="button_items">
                                  {extend.btns.map((btn: any, idx: number) => {
                                      return (
                                          <button
                                              className={btn.status === '0' ? 'agreed' : ''}
                                              key={idx}
                                              onClick={() => handleClick(btn)}
                                              disabled={btn.status === '0' ? true : false}
                                              style={{
                                                  color: btn.status === '0' ? '#8F959EFF' : '',
                                                  cursor:
                                                      btn.status === '0' ? 'default' : 'pointer',
                                              }}
                                          >
                                              {btn.name}
                                          </button>
                                      );
                                  })}
                              </div>
                          );
                      }
                    : null
            }
        />
    );
});

export default ExternalNewUserNoticeCard;
