/* eslint-disable complexity */
/* eslint-disable no-console */
// 左侧消息条

import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import './index.less';
import { connect } from 'dva';
import { ChatMessage, Employee, Group, MessageStatus, SessionProps } from '@/types/chat';
import ChatState from '@/types/chat/State';
import { sessionDateFormat } from '@/utils/date';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import { Dropdown, Menu } from 'antd';
import { useTranslation, WithTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { FocusSDK as focusSDK } from '@/utils';
import {
    isEqualEmployee,
    uidToEmployee,
    mySendMessage,
    isEqualUid,
    employeeToUid,
    computeAITimestamp,
} from '@/components/chat/utils/message';
import { HeadShowType } from '@/types/chat/enum';
import { SessionTag } from '@/components/Tags';
import Context from '@/components/chat/context';
import Bus from '@/utils/bus';
import {
    useGetMessageDesc,
    useHandleContextMenuClick,
    useHandleSessionClick,
} from '@/components/chat/Session/common';
import GroupRoster from '@jd/jdee.im.sdk/lib/es/model/Roster';
import { isAINoticeSession, isUnnecessarySession } from '@/components/chat/utils/session';
import { useHistory } from 'react-router-dom';
import OpenAnnounceInfo from '../../../components/Projects/components/AnnounceTab/announceInfo';
import { findAlertAnnouncement } from '../../Projects/api';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import colors from '@/components/styles/colors';
import { AI_NOTICE_EXCLUDE_PINS } from '@/contant/chat';
import { usePrevious, userEscapeString } from '@/utils/chat/index';
import { checkMeetingSysMessage, isTopSession } from '@/utils/chat/message';
import { analysisLog } from '@/utils/logAnalytics';

interface SessionCompProps {
    // 会话对象
    session: SessionProps;
    // 群组会话消息条数
    pieces?: string;
    // 是否有@
    cue?: boolean;

    last: boolean;
    setCallBack?: () => void;
    history: any;
    onClick?: () => void;
    withoutContextMenu?: boolean;
    withoutUnreadTips?: boolean;
    withoutTimeStamp?: boolean;
    withoutBorder?: boolean;
    withoutClick?: boolean;
    right?: React.ReactNode;
    descTextCls?: string;
    style?: React.CSSProperties;
}

interface DvaDispatchProps {
    launchSingleSession: Function;
    initSelectedSession: Function;
    launchGroupSession: Function;
    launchNoticeSession: Function;
    updateSelectedSession: Function;
    clearSessionMessage: Function;
    changeGroupAdmin: Function;
    changeSelectMore: Function;
    updateMessages: Function;
}

type IProps = Readonly<SessionCompProps & ChatState & DvaDispatchProps & WithTranslation>;

// eslint-disable-next-line complexity
function Session(props: IProps) {
    const [session, setSession] = useState<SessionProps | null>(null);
    const {
        session: sessionProps,
        selectedSession,
        launchGroupSession,
        launchSingleSession,
        initSelectedSession,
        launchNoticeSession,
        setCallBack,
        sessionStatusMap,
        updateSelectedSession,
        clearSessionMessage,
        administrator,
        ordinary,
        changeGroupAdmin,
        onClick,
        withoutContextMenu,
        withoutUnreadTips,
        withoutTimeStamp,
        withoutClick,
        right,
        descTextCls,
        withoutBorder,
        changeSelectMore,
        updateMessages,
        aiNoticeSessionList,
        allSessionList,
        style,
    } = props;
    const { t } = useTranslation('chat');
    const { authInfo, appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const basename = appRuntimeEnv?.webConfig?.routeBase || '';
    const projectType = `project${
        ['mebj', 'jgswy', 'mebjJZB'].includes(appRuntimeEnv.runtimeConfig.key)
            ? '_' + appRuntimeEnv.runtimeConfig.key
            : ''
    }`;
    const t1 = useTranslation(projectType).t;
    const history = useHistory();

    const { currentEmployee, closeSetting } = React.useContext(Context);

    const [visible, setVisible] = useState<boolean>(true);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    useEffect(() => {
        setSession(sessionProps);
        if (isAINoticeSession(sessionProps)) {
            let unreadCount = 0;
            aiNoticeSessionList.forEach((item) => {
                unreadCount += item.unreadCount || 0;
            });
            setUnreadCount(unreadCount || 0);
        } else {
            // 设置未读数
            setUnreadCount(sessionProps.unreadCount || 0);
        }

        // 不显示不必要的会话
        if (isUnnecessarySession(sessionProps)) {
            setVisible(false);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        // 更新session
        setSession(sessionProps);
    }, [sessionProps]);

    // 更新会话未读数量
    useEffect(() => {
        // tj: 处理unreadCount为0不更新, 点击闪动
        const info: any = session?.info || {};
        if (selectedSession?.sessionId !== session?.sessionId) {
            // 当前session不是ai助手的内置会话
            if (session?.isNotice && !AI_NOTICE_EXCLUDE_PINS.includes(info.userId)) {
                // 当前选中的session是否是ai助手
                if (selectedSession.isNotice && isAINoticeSession(selectedSession)) {
                    setUnreadCount(0);
                } else {
                    let unreadCount = 0;
                    aiNoticeSessionList.forEach((item) => {
                        unreadCount += item.unreadCount || 0;
                    });
                    setUnreadCount(unreadCount || 0);
                }
            } else {
                setUnreadCount(session?.unreadCount || 0);
            }
        } else if (
            isAINoticeSession(selectedSession) &&
            !AI_NOTICE_EXCLUDE_PINS.includes(info.userId) &&
            session?.isNotice
        ) {
            setUnreadCount(0);
        }
    }, [session, aiNoticeSessionList, selectedSession]);

    const updateGroupRoster = useCallback(async () => {
        if (administrator.length === 0) {
            return;
        }
        const { owner } = sessionProps.info as Group;
        const newAdmin = uidToEmployee(owner) as Employee;
        const oldAdmin = administrator[0].info;
        // 新旧管理员不一样
        if (oldAdmin && newAdmin && !isEqualEmployee(newAdmin, oldAdmin)) {
            // 进行替换管理员操作
            const newAdminRosterIndex = ordinary.findIndex((item: any) =>
                isEqualEmployee(newAdmin, item.info)
            );
            const newAdminRoster = ordinary[newAdminRosterIndex];
            // 替换
            ordinary[newAdminRosterIndex] = {
                ...administrator[0],
                identity: 'ordinary',
            };
            administrator[0] = { ...newAdminRoster, identity: 'administrator' };
            await changeGroupAdmin({
                ordinary: [...(ordinary || [])],
                administrator: [...(administrator || [])],
            });
        }
    }, [administrator, changeGroupAdmin, ordinary, sessionProps.info]);

    const updateSession = useCallback(
        async (session: SessionProps) => {
            setSession(session);
            setUnreadCount(session.unreadCount || 0);
            if (session.sessionId === selectedSession.sessionId) {
                if (isAINoticeSession(selectedSession)) {
                    aiNoticeSessionList.forEach((item) => {
                        item.unreadCount = 0;
                    });
                }
                setUnreadCount(0);

                if (session.isGroup) {
                    const group = session.info as Group;
                    if (group.rosterSize > 0) {
                        await updateGroupRoster();
                    }
                }
                // await updateSelectedSession({ selectedSession: session });
            }
        },

        [selectedSession, updateGroupRoster, aiNoticeSessionList] // eslint-disable-line
    );

    useEffect(() => {
        Bus.on(`chat:session-update:${sessionProps.sessionId}`, updateSession);
        return () => {
            Bus.off(`chat:session-update:${sessionProps.sessionId}`, updateSession);
        };
    }, []); //eslint-disable-line

    const sessionRemoveListener = useCallback(async () => {
        setSession(null);
        if (sessionProps.sessionId === selectedSession.sessionId) {
            history.push(`/messages`);
        }
        focusSDK.sendIpcMessage('flashSessionForceClear', { sessionId: sessionProps.sessionId });
    }, [history, selectedSession.sessionId, sessionProps.sessionId]);

    useEffect(() => {
        Bus.on(`chat:session-remove:${sessionProps.sessionId}`, sessionRemoveListener);
        return () => {
            Bus.off(`chat:session-remove:${sessionProps.sessionId}`, sessionRemoveListener);
        };
    }, []); //eslint-disable-line

    // 点击选中某一条会话
    const handleClick = useHandleSessionClick({
        selectedSession,
        session,
        launchSingleSession,
        initSelectedSession,
        launchNoticeSession,
        launchGroupSession,
        setCallBack,
        changeSelectMore,
        updateMessages,
        history,
        closeSetting,
        basename,
    });

    const getAnnouncement = useCallback(() => {
        if (!session) {
            return;
        }
        const sessionInfo = session.info as any;
        if (session.isGroup) {
            if (sessionInfo.gid) {
                // 组公告弹窗
                findAlertAnnouncement(sessionInfo.gid).then((res) => {
                    if (!res?.announcementDetailInfo) return;
                    const announcementDetailInfo = res.announcementDetailInfo;

                    // 当前登录人发的公告 不弹窗
                    if (
                        announcementDetailInfo.senderUserId === authInfo.userId &&
                        announcementDetailInfo.senderTeamId === authInfo.selectedTeamId &&
                        announcementDetailInfo.senderUserAppId === authInfo.ddAppId
                    ) {
                        return;
                    }
                    const el = document.getElementById('pm-announceinfo-wrap');
                    if (el) {
                        return;
                    }
                    OpenAnnounceInfo({
                        content: announcementDetailInfo?.content || '',
                        pictures:
                            typeof announcementDetailInfo.attachmentUrl === 'string'
                                ? JSON.parse(announcementDetailInfo.attachmentUrl) || []
                                : announcementDetailInfo.attachmentUrl,
                        projectId: sessionInfo.gid,
                        announceId: announcementDetailInfo?.announcementId || '',
                        t: t1,
                    });
                });
            } else if (sessionInfo.businessId) {
                findAlertAnnouncement(sessionInfo.businessId).then((res) => {
                    if (!res?.announcementDetailInfo) return;
                    const announcementDetailInfo = res.announcementDetailInfo;
                    const el = document.getElementById('pm-announceinfo-wrap');
                    if (el) {
                        return;
                    }
                    OpenAnnounceInfo({
                        content: announcementDetailInfo?.content || '',
                        pictures:
                            typeof announcementDetailInfo.attachmentUrl === 'string'
                                ? JSON.parse(announcementDetailInfo.attachmentUrl) || []
                                : announcementDetailInfo.attachmentUrl,
                        projectId: announcementDetailInfo?.announcementTypeId || session.gid,
                        announceId: announcementDetailInfo?.announcementId || '',
                        t: t1,
                    });
                });
            } else {
                console.warn('session ===> findAlertAnnouncement', session);
            }
        }
    }, [authInfo.ddAppId, authInfo.selectedTeamId, authInfo.userId, session, t1]);

    const getMessageDesc = useGetMessageDesc({
        sessionStatusMap,
        currentEmployee,
        selectedSession,
        session: sessionProps, // 王振朋修改
        allSessionList,
    });

    const lastMessageDesc = useMemo(() => {
        return getMessageDesc();
    }, [getMessageDesc]);

    const handleContextMenuClick = useHandleContextMenuClick({
        sessionId: sessionProps.sessionId,
        selectedSessionId: selectedSession.sessionId,
        t,
        clearSessionMessage,
        history,
        msg: session?.lastMsg as ChatMessage,
    });

    const mention = useMemo(() => {
        if (!session) {
            return null;
        }
        const mentions = Object.entries(session.mentions || {}).map(([, m]) => m);
        mentions.sort((a, b) => b.mid - a.mid);
        // if (!mentions[0]?.sender?.name) {
        //     console.log('mentions', mentions[0]);
        // }
        return mentions[0];
    }, [session]);

    // 会话列表单聊增加对方未读的状态
    const isShowStatus = useMemo(() => {
        if (!session) {
            return null;
        }
        if (session?.isNotice) {
            return '';
        }
        const lastMsg = session?.lastMsg as ChatMessage;
        if (!lastMsg) {
            return '';
        }
        if (lastMsg.revoke === 1) {
            return '';
        }
        const sessionStatusInfo = sessionStatusMap[session.sessionId];
        if (sessionStatusInfo && sessionStatusInfo.status === MessageStatus.EDITING) {
            return '';
        }
        // 最后一条消息是自己发的、 readUids存在当前对话人信息匹配成功则已读
        if (mySendMessage({ message: lastMsg, currentEmployee })) {
            if (lastMsg.statusType === MessageStatus.SENDING) {
                return <IconFont type="iconic_app_im_sending" style={{ color: '#8F959E' }} />;
            }
            if (lastMsg.statusType === MessageStatus.FAILED) {
                return <IconFont type="iconic_failure" style={{ color: '#F96137' }} />;
            }
        }
    }, [session, currentEmployee, sessionStatusMap]);

    const isRead = useMemo(() => {
        if (!session) {
            return null;
        }
        if (!session?.isSingle || session?.isNotice) {
            return '';
        }
        const lastMsg = session?.lastMsg as ChatMessage;
        if (!lastMsg) {
            return '';
        }
        const employee = session?.info as Employee;
        if (!employee) {
            return '';
        }
        if ((session?.info as Employee)?.userId === authInfo?.userId) {
            return '';
        }
        if (lastMsg.revoke === 1) {
            return '';
        }
        const sessionStatusInfo = sessionStatusMap[session.sessionId];
        if (sessionStatusInfo && sessionStatusInfo.status === MessageStatus.EDITING) {
            return '';
        }
        // 最后一条消息是自己发的、 readUids存在当前对话人信息匹配成功则已读
        if (mySendMessage({ message: lastMsg, currentEmployee })) {
            if (isShowStatus) {
                return isShowStatus;
            }
            if (lastMsg?.readUids && lastMsg.readUids.length > 0) {
                const index = lastMsg.readUids.findIndex((item: any) => {
                    return !item || isEqualUid(item, employeeToUid(employee));
                });
                if (index > -1) {
                    return '';
                } else {
                    return '[未读]';
                }
            } else {
                return '[未读]';
            }
        }
    }, [session, authInfo?.userId, sessionStatusMap, currentEmployee, isShowStatus]);

    const aiSessionTimestamp = useMemo(() => {
        return computeAITimestamp(aiNoticeSessionList);
    }, [aiNoticeSessionList]);

    const sessionTimestamp = useMemo(() => {
        if (isAINoticeSession(session || ({} as any))) {
            return sessionDateFormat(aiSessionTimestamp);
        } else {
            return sessionDateFormat(session?.lastMsg?.timestamp || session?.timestamp || 0);
        }
    }, [session, aiSessionTimestamp]);

    const hoverCls = useMemo(() => {
        return withoutClick ? 'withoutHover' : '';
    }, [withoutClick]);

    const sessionClass = useMemo(() => {
        return [
            'session-container',
            (session?.settings || {}).shield === 1 ? 'session-container_shield' : '',
            isTopSession(session || ({} as any)) ? 'session-container_top' : '',
            hoverCls,
            session?.sessionId === selectedSession.sessionId ? 'selected' : '',
        ].join(' ');
    }, [hoverCls, selectedSession.sessionId, session]);

    const handleSessionClick = useCallback(() => {
        if (withoutClick) {
            return;
        }
        if (onClick) {
            onClick();
            return;
        }
        handleClick();
        getAnnouncement();
    }, [handleClick, getAnnouncement, onClick, withoutClick]);

    const renderTopCom = useMemo(() => {
        let { settings } = session || {};
        return ((settings || {}).top || 0) > 0 ? <div className="top-icon" /> : null;
    }, [session]);

    const renderMute = useMemo(() => {
        let { settings } = session || {};
        return settings?.shield === 1 ? (
            <div>
                <IconFont type="iconmute" />
            </div>
        ) : null;
    }, [session]);

    const sessionName = useMemo(() => {
        return userEscapeString(session?.info?.name || '');
    }, [session?.info?.name]);

    const sessionHeightClass = useMemo(() => {
        return ['messageDesc', lastMessageDesc !== null ? 'showHeight' : ''].join(' ');
    }, [lastMessageDesc]);

    const memoComp = useMemo(() => {
        const count = session && isAINoticeSession(session) ? unreadCount : session?.unreadCount;
        return session?.timestamp ? (
            <div
                id={session?.sessionId}
                className={sessionClass}
                onClick={handleSessionClick}
                style={style}
                // clstag="pageclick|keycount|focus_chat_01_1615797500283|12"
            >
                {renderTopCom}
                {/* 头像组件 */}
                <HeadPortrait
                    width="32px"
                    height="32px"
                    imgUrl={session?.info?.avatar || ''}
                    name={session?.info?.name || ''}
                    showType={HeadShowType.SESSION}
                    type={session?.sessionType}
                    numPieces={withoutUnreadTips ? 0 : count || 0}
                />
                <div className="message">
                    <div className="name-time">
                        <div className="name-tag">
                            {session?.isSecret && (
                                <div className="secret">
                                    <IconFont
                                        style={{ color: '#F96137' }}
                                        type="iconic_app_im_burnafterreading_facet"
                                    />
                                </div>
                            )}
                            <div className={`name ${session?.isSecret ? 'secret-name' : ''}`}>
                                {sessionName}
                            </div>
                            <SessionTag session={session} currentEmployee={currentEmployee} />
                        </div>
                        {!withoutTimeStamp && <div className="time">{sessionTimestamp}</div>}
                    </div>
                    {session?.isSingle && (
                        <div className="content">
                            <div className={descTextCls || 'text'} id="istext">
                                {isRead && (
                                    <span
                                        style={{
                                            color: (window as any).styleSass.primaryColor,
                                            marginRight: 4,
                                        }}
                                    >
                                        {isRead}
                                    </span>
                                )}
                                <div className={sessionHeightClass}>{lastMessageDesc}</div>
                            </div>
                            {selectedSession.sessionId !== session?.sessionId && mention && (
                                // <div className="cueName">{mention?.sender?.name}@</div>
                                <div className="cueName">
                                    <span>
                                        {mention?.sender?.name || mention?.sender?.nickName}
                                    </span>
                                    <span>@</span>
                                </div>
                            )}
                            {renderMute}
                        </div>
                    )}
                    {session?.isGroup && (
                        <div className="content">
                            {props.pieces && <div className="pieces">[{props.pieces}]</div>}
                            <div className={descTextCls || 'text'} id="istext">
                                {isShowStatus && (
                                    <span
                                        style={{
                                            color: (window as any).styleSass.primaryColor,
                                            marginRight: 4,
                                        }}
                                    >
                                        {isShowStatus}
                                    </span>
                                )}
                                {lastMessageDesc}
                            </div>
                            {selectedSession.sessionId !== session?.sessionId && mention && (
                                // <div className="cueName">{mention.sender.name}@</div>
                                <div className="cueName">
                                    <span>{mention.sender.name || mention.sender.nickName}</span>
                                    <span>@</span>
                                </div>
                            )}
                            {renderMute}
                        </div>
                    )}
                </div>
                {right && <div className="message-right">{right}</div>}
                {!withoutBorder}
                {/* {!props.last && <div className="bottom-line" />} */}
            </div>
        ) : null;
    }, [
        session,
        unreadCount,
        sessionClass,
        handleSessionClick,
        style,
        renderTopCom,
        withoutUnreadTips,
        sessionName,
        currentEmployee,
        withoutTimeStamp,
        sessionTimestamp,
        descTextCls,
        isRead,
        sessionHeightClass,
        lastMessageDesc,
        selectedSession.sessionId,
        mention,
        renderMute,
        props.pieces,
        isShowStatus,
        right,
        withoutBorder,
    ]);

    // 鼠标右键展示内容
    const renderContextMenu = () => {
        if (!session) {
            return <div />;
        }
        const settings = session.settings || {};
        return (
            <Menu onClick={handleContextMenuClick}>
                {(settings.top || 0) === 0 && (
                    <Menu.Item
                        key="top"
                        onClick={maskHide}
                        clstag="pageclick|keycount|Xtbg_Msg_Home|InfoMoreTop_True"
                    >
                        <IconFont type="iconapp_ic_focus" />
                        {t('session.menus.top')}
                    </Menu.Item>
                )}
                {(settings.top || 0) > 0 && (
                    <Menu.Item
                        key="untop"
                        onClick={maskHide}
                        clstag="pageclick|keycount|Xtbg_Msg_Home|InfoMoreTop_False"
                    >
                        <IconFont type="iconapp_ic_cancel_focus" />
                        {t('session.menus.untop')}
                    </Menu.Item>
                )}
                {(settings.shield || 0) !== 0 && (
                    <Menu.Item
                        key="unshield"
                        onClick={maskHide}
                        clstag="pageclick|keycount|Xtbg_Msg_Home|InfoMoreRemind_True"
                    >
                        <IconFont type="iconapp_ic_remind" />
                        {t('session.menus.shield')}
                    </Menu.Item>
                )}
                {(settings.shield || 0) === 0 && (
                    <Menu.Item
                        key="shield"
                        onClick={maskHide}
                        clstag="pageclick|keycount|Xtbg_Msg_Home|InfoMoreRemind_False"
                    >
                        <IconFont type="iconapp_ic_cancel_remind" />
                        {t('session.menus.unshield')}
                    </Menu.Item>
                )}
                {!isAINoticeSession(session) && (
                    <Menu.Item
                        key="remove"
                        onClick={maskHide}
                        clstag="pageclick|keycount|Xtbg_Msg_Home|InfoMoreRemove"
                    >
                        <IconFont type="iconapp_ic_delete" />
                        {t('session.menus.remove')}
                    </Menu.Item>
                )}
                <Menu.Item
                    key="empty"
                    onClick={maskHide}
                    clstag="pageclick|keycount|Xtbg_Msg_Home|InfoMoreDelete"
                >
                    <IconFont type="iconapp_ic_empty" />
                    {t('session.menus.empty')}
                </Menu.Item>
            </Menu>
        );
    };

    if (!session) {
        return null;
    }

    if (!visible) {
        return null;
    }

    if (withoutContextMenu) {
        return memoComp;
    }

    const maskShow = () => {
        const mask = document.getElementById('chat-left-mask');
        if (!mask) return;
        mask.style.display = 'block';
    };
    const maskHide = () => {
        const mask = document.getElementById('chat-left-mask');
        if (!mask) return;
        mask.style.display = 'none';
    };
    const visibleChange = (visible: Boolean) => {
        if (visible) {
            maskShow();
            analysisLog('Xtbg_Msg_Home', 'InfoMore');
        } else {
            maskHide();
        }
    };
    return memoComp ? (
        <Dropdown
            overlayClassName="sessionContextMenu"
            overlay={renderContextMenu}
            trigger={['contextMenu']}
            onVisibleChange={visibleChange}
        >
            {memoComp}
        </Dropdown>
    ) : (
        <div />
    );
}

function mapStateToProps({ chat }: any) {
    const {
        selectedSession,
        currentEmployee,
        sessionStatusMap,
        administrator,
        ordinary,
        aiNoticeSessionList,
        allSessionList,
        sessionMessageMap,
    } = chat as ChatState;
    return {
        selectedSession,
        currentEmployee,
        sessionStatusMap,
        administrator,
        ordinary,
        aiNoticeSessionList,
        allSessionList,
        sessionMessageMap,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async changeGroupAdmin(data: {
            ordinary: Partial<GroupRoster>[];
            administrator: Partial<GroupRoster>[];
        }) {
            await dispatch({ type: 'chat/changeGroupAdmin', payload: data });
        },
        async clearSessionMessage(data: { sessionId: string }) {
            await dispatch({ type: 'chat/clearSessionMessage', payload: data });
            focusSDK.sendIpcMessage('flashSessionForceClear', data);
        },
        async updateSelectedSession(data: { selectedSession: SessionProps }) {
            await dispatch({ type: 'chat/updateSelectedSession', payload: data });
        },
        launchSingleSession(data: { employee: Employee }) {
            dispatch({ type: 'chat/launchSingleSession', payload: data });
        },
        initSelectedSession(data: { selectedSession: any }) {
            dispatch({ type: 'chat/initSelectedSession', payload: data });
        },
        launchGroupSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchGroupSession', payload: data });
        },
        launchNoticeSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchNoticeSession', payload: data });
        },

        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Session);
