/* eslint-disable no-case-declarations */
import React from 'react';
import { StateActionNames, StoreAction, StoreState } from './types';

export const initState: StoreState = {
    selectedItems: [],
    max: -1,
    errorType: '',
};

export function reducer(state: StoreState, action: StoreAction) {
    switch (action.action) {
        case StateActionNames.TOGGLE_SELECTED:
            let newSelectedItems = (state.selectedItems || []).slice();
            let index = newSelectedItems.findIndex(
                (item) => item.type === action.item.type && item.id === action.item.id
            );
            if (index !== -1) {
                newSelectedItems.splice(index, 1);
            } else {
                if (state.max !== -1 && state.selectedItems.length >= state.max) {
                    return { ...state, errorType: 'selector.error.max' };
                }
                if (!action.item.pageType) {
                    return { ...state, errorType: '不支持的文件格式' };
                }
                newSelectedItems.push({
                    ...action.item,
                    title: action.item.title
                        ? action.item.title.replace(/<em>/g, '').replace(/<\/em>/g, '')
                        : '无标题',
                });
            }
            return { ...state, selectedItems: newSelectedItems, errorType: '' };
        case StateActionNames.CLEAR_SELECTED:
            return { ...state, selectedItems: [], errorType: '' };
        case StateActionNames.SET_MAX:
            return { ...state, max: action.max, errorType: '' };
        case StateActionNames.CLEAR_ERROR_TYPE:
            return { ...state, errorType: '' };
        case StateActionNames.EDIT_PERMISSIONTYPE:
            let _newSelectedItems = (state.selectedItems || []).slice();
            let item = _newSelectedItems.find(
                (item) => item.type === action.item.type && item.id === action.item.id
            );
            if (item) {
                item.permissionType = action.permissionType;
            }
            return { ...state, selectedItems: _newSelectedItems, errorType: '' };
        case StateActionNames.EDIT_PERMISSIONTYPE_ALL:
            let newSelectedList = (state.selectedItems || []).slice();
            for (let item of newSelectedList) {
                item.permissionType = action.permissionType;
            }
            return { ...state, selectedItems: newSelectedList, errorType: '' };
        case StateActionNames.DELETE_SELECTED:
            let _newSelectedList = (state.selectedItems || []).slice();
            const idx = _newSelectedList.findIndex(
                (item) => item.type === action.item.type && item.id === action.item.id
            );
            if (idx !== -1) {
                _newSelectedList.splice(idx, 1);
            }
            return { ...state, selectedItems: _newSelectedList, errorType: '' };

        default:
            return state;
    }
}

export const StoreContext = React.createContext<any[]>([]);
