/* eslint-disable complexity */
/* eslint-disable no-console */
/**
 * 聊天消息输入组件
 * @author sunzhiguang
 * @date 2020/6/16
 */
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import {
    Tooltip,
    message as AntMessage,
    message as toast,
    Menu,
    Button,
    Space,
    Dropdown,
    Modal,
} from 'antd';
import IconFont from '@/components/icon';
import { useTranslation, WithTranslation } from 'react-i18next';
import './index.less';
import JEditor from '../JEditor';
import MentionUserPlugin from '../JEditor/Plugins/MentionUser';
import { connect } from 'dvajs';
import ChatState, { QuickReply, SessionStatusInfo } from '@/types/chat/State';
import {
    ChatMessage,
    ChatMessageType,
    Employee,
    MessageStatus,
    Session,
    SessionType,
} from '@/types/chat';
import { isFocusEnv, detectOS, FocusSDK } from '@/utils';
import PinyinMatch from 'pinyin-match';
import { UpLoadImg } from './UpLoadImg';
import EmojiPopover from './Emoji';
import {
    buildJoyspaceLinkMessageBody,
    buildTextMessageBody,
    convertEmployeeToString,
    filterDeptFullName,
    getMessageTypeByText,
    getReplyMessageDesc,
    initChatMessageInfo,
    isBannedPostGroupSession,
    isGroupVisibleUnit,
    JouspaceLinkTypeOption,
    employeeToUid,
    buildImageMessageBody,
    extraConvertEmployeeToString,
    HttpCardLinkTypeOption,
    sendMessageBody,
    convertContentToArray,
    nonExistentPageToText,
} from '@/components/chat/utils/message';
import { getOrgList, isGroupOrdinary } from '@/components/chat/utils/group';
import MoreDropdown from './MoreDropdown';
import ChatEvent from '@/components/chat/view/ChatEvent';
import config, { ChatConfig, ConfigEnum } from '@/config/config';
import { usePrevious } from '@/utils/chat/index';
import Quill, { Sources } from 'quill';
import BannedInput from './BannedInput';
import Context from '@/components/chat/context';
import bus from '@/utils/bus';
import QuickReplyComp from './QuickReply';
import { DownOutlined } from '@ant-design/icons';
import {
    dealImagesData,
    dataURLtoFile,
    isHeicImage,
    heicToPng,
    filterImages,
} from '../../utils/image';
import { lookup } from 'mime-types';
import CopyClipboard from 'copy-to-clipboard';
import { getToMergeMessage } from '@/server/im/MessageService';
import ScreenCapture from './ScreenCapture';
import { openUserSelectionModal } from '@/utils/modals';
import { getSelectorSessions } from '@/components/chat/utils/session';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { debounce, get, omit, cloneDeep } from 'lodash';
import { UserModel } from '@/models/user';
import ImService from '@/server/ImService';
import { createUploadTask } from '@/server/FileService';
import log from '@/utils/logger';
import { filterDirectory } from '@/components/chat/utils/image';
import chatConfig from '@/components/chat/config';
import { stickers_sets } from '@/components/chat/utils/stickers-config';
import { analysisLog } from '@/utils/logAnalytics';

let count = 0;
const Delta = Quill.import('delta');

const SendMessageIsBanned = 'banned_post_tip';
let offCaptureHandle: any = null;
interface MessageInputProps {
    Upload?: string;
}

type IMessageInputProps = Readonly<MessageInputProps & WithTranslation>;

interface MessageInputState {
    type: string;
}

function mapStateToProps({ user, chat }: { user: any; chat: ChatState }) {
    return {
        ...user,
        selectedSession: chat.selectedSession,
        singleSessionMembers: chat.sessionMembers,
        sessionStatusMap: chat.sessionStatusMap,
        currentEmployee: chat.currentEmployee,
        sessionMessageMap: chat.sessionMessageMap,
        captureType: chat.captureType,
        allSessionList: chat.allSessionList,
        userData: user.userData ? user.userData.user : {},
        quickReplys: chat.quickReplys,
        sendMsgType: chat.sendMsgType,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeViewTab(viewTab: string) {
            dispatch({ type: 'calendar/changeViewTab', payload: { viewTab } });
        },
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
        setSessionStatus(data: { sessionId: string; sessionStatusInfo: SessionStatusInfo }) {
            dispatch({ type: 'chat/setSessionStatus', payload: data });
        },
        setMessageInputFocus(data: boolean) {
            dispatch({ type: 'chat/setMessageInputFocus', payload: data });
        },
        async setMsgtureType(data: { sendMsgType: string }) {
            await dispatch({ type: 'chat/setMsgtureType', payload: data });
        },
    };
}

export const InitImgState: {
    base64: any;
    title: string;
    width: number;
    height: number;
    size: number;
} = {
    title: '',
    base64: '',
    width: 0,
    height: 0,
    size: 0,
};

interface IState {
    imgState: typeof InitImgState;
    imgUploadModalVisible: boolean;
    file: any;
    fileList: any[];

    replyVisible: boolean;
    replyText: string;
    replyUser: Partial<Employee> | null;
    replyMessage: Partial<ChatMessage>;

    quickReplyVisible: boolean; // 常用语
    emojiVisible: boolean;
}

const initialState: IState = {
    imgState: InitImgState,
    imgUploadModalVisible: false,
    file: null,
    fileList: [],

    replyVisible: false,
    replyText: '',
    replyUser: null,
    replyMessage: {},

    quickReplyVisible: false,
    emojiVisible: false,
};

function reducer(state: IState, action: any) {
    const { payload } = action;

    switch (action.type) {
        case 'openUploadImgModal':
            // eslint-disable-next-line no-case-declarations
            const { imgState, file, fileList } = payload;
            return { ...state, imgUploadModalVisible: true, imgState, file, fileList };
        case 'closeUploadImgModal':
            return { ...state, imgUploadModalVisible: false };
        case 'clearImageState':
            return { ...state, imgState: {}, file: null, fileList: [] };
        case 'closeReply':
            return {
                ...state,
                replyText: '',
                replyVisible: false,
                replyUser: null,
                replyMessage: {},
            };
        case 'showReply':
            return {
                ...state,
                replyVisible: true,
                replyText: payload.replyText,
                replyUser: payload.replyUser,
                replyMessage: payload.replyMessage,
            };
        case 'changeQuickReplyVisible':
            if (payload.quickReplyVisible) {
                analysisLog('Xtbg_Msg_Info_MsgDialog', 'AddTerm');
                return {
                    ...state,
                    quickReplyVisible: true,
                    emojiVisible: false,
                };
            }
            return {
                ...state,
                quickReplyVisible: false,
            };
        case 'changeEmojiVisible':
            if (payload.visible) {
                analysisLog('Xtbg_Msg_Info_MsgDialog', 'AddEmo');
                return {
                    ...state,
                    quickReplyVisible: false,
                    emojiVisible: true,
                };
            }
            return {
                ...state,
                emojiVisible: false,
            };
        case 'handleAtClick':
            return {
                ...state,
                quickReplyVisible: false,
                emojiVisible: false,
            };
        case 'handleImgClick':
            return {
                ...state,
                quickReplyVisible: false,
                emojiVisible: false,
            };
        default:
            throw new Error();
    }
}

/**
 * 判断最后一个
 * @param {*} delta
 */
function isEndwithEnter(delta: any) {
    if (delta.ops.length < 1) {
        return false;
    }
    let length = delta.ops.length;
    const op = delta.ops[length - 1];
    if (typeof op.insert === 'string' && op.insert.endsWith('\n')) {
        return true;
    }
    return false;
}

function MessageInput({
    currentEmployee,
    selectedSession,
    singleSessionMembers,
    uploadFile,
    uploadImg,
    uploadMultiImg,
    pushChatMessage,
    sendJoyspaceMessage,
    setSessionStatus,
    setMessageInputFocus,
    setMsgtureType,
    sessionStatusMap,
    sessionMessageMap,
    captureType,
    allSessionList,
    userData,
    quickReplys,
    sendMsgType,
}: {
    currentEmployee: Employee;
    selectedSession: Session;
    singleSessionMembers: Partial<Employee>[];
    sessionStatusMap: any;
    sessionMessageMap: any;
    uploadFile: (file: any, multiple?: any, path?: any) => void;
    uploadImg: (imgState: typeof InitImgState, file: any) => void;
    uploadMultiImg: (uploadList: any) => void;
    pushChatMessage: (data: { sessionId: string; message: Partial<ChatMessage> }) => void;
    sendJoyspaceMessage: (data: any[]) => void;
    setSessionStatus: (data: { sessionId: string; sessionStatusInfo: SessionStatusInfo }) => void;
    setMessageInputFocus: (data: boolean) => void;
    setMsgtureType: Function;
    captureType: string;
    allSessionList: Session[];
    userData: UserModel;
    quickReplys: QuickReply[];
    sendMsgType: string;
}) {
    let weizhi: any = document.getElementById('input-control-container'); // 输入框
    let domMessageScroll: any = document.getElementById('message-box-container-scroll');
    let isIpt: any = document.getElementById('input-box-multiline');
    let onMousedown: any = document.getElementById('input-line'); // 透明线
    let body: any = document.querySelector('body');

    const { groupRosters, groupRosterIdentity, closeSetting } = React.useContext(Context);
    const sessionMembers: Partial<Employee>[] = useMemo(
        function () {
            if (
                [SessionType.SINGLE, SessionType.SECRET_SINGLE].includes(
                    selectedSession.sessionType
                )
            ) {
                const tempSingleSessionMembers = singleSessionMembers.map((item: any) => {
                    if (item.userId === 'all') {
                        item.role = 'all';
                    } else {
                        item.role = 'user';
                    }
                    return item;
                });
                // console.log(tempSingleSessionMembers);
                return (tempSingleSessionMembers || []) as Partial<Employee>[];
            } else if (selectedSession.sessionType === SessionType.GROUP) {
                const rosters: any[] = groupRosters
                    .map((item) => {
                        const user = { ...item?.info, role: 'user' } as any;
                        return user;
                    })
                    .filter((item) => item?.userId && item?.app && item?.teamId && item?.name);
                const all = {
                    app: currentEmployee.app || '',
                    name: '全体成员',
                    userId: 'all',
                    teamId: currentEmployee.teamId,
                    role: 'all',
                };
                const result = getOrgList(groupRosters, selectedSession);
                // console.log('222', result);
                // console.log('333', rosters);
                // rosters.unshift(all);
                // console.log('rosters111', [all, ...rosters]);
                // console.log('rosters222', [all, ...result, ...rosters]);
                return result.length > 1 ? [all, ...result, ...rosters] : [all, ...rosters];
                // return rosters;
            }
            return [];
        },
        [selectedSession, groupRosters, singleSessionMembers, currentEmployee]
    );

    const divEl = useRef(null);
    const inputRef = useRef<JEditor | null>(null);
    const [inputWidth, setInputWidth] = useState(100);
    const [expand, setExpand] = useState(false);
    const [visible, setVisible] = useState(false);
    const [pasteVisible, setpasteVisible] = useState(false);
    const [copyVisible, setcopyVisible] = useState(false);
    const preSelectedId = usePrevious(selectedSession.id);
    const [state, dispatch] = useReducer(reducer, initialState);
    const draftRef = useRef<any>(null);
    const [t] = useTranslation('chat');
    const countRef = useRef(50);
    // cxz修改
    const [isenter, setIsenter] = useState('');
    const [isctrl, setIsctrl] = useState('');
    const [keyNum, setKeyNum] = useState('');
    const [copyTexts, setcopyText] = useState(false);

    // lyj 是否发送多张图片
    const [isMulti, setMulti] = useState(false);

    const handleResize = useCallback(() => {
        if (
            ![SessionType.SINGLE, SessionType.SECRET_SINGLE, SessionType.GROUP].includes(
                selectedSession.sessionType
            )
        ) {
            return;
        }
        const cu: any = divEl.current;
        if (cu) {
            const width = cu.clientWidth;

            let rightWidth = 14;

            const right = cu.querySelector('.input-control-container-right');
            if (right) {
                rightWidth += right.clientWidth;
            }

            const inputWidth = width - rightWidth;
            setInputWidth(inputWidth);
            const div = cu.querySelector('.ql-editor');
            if (!div) {
                return;
            }
            const editWidth = div.scrollWidth;
            if (editWidth > inputWidth) {
                setExpand(true);
            } else {
                setExpand(false);
            }
        }
    }, [selectedSession]);

    // 在输入时保存一下草稿信息
    function saveDraft(session: any) {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const { text, userIds, delta } = jeditor.getTextMessage();
        if (text === '') {
            setcopyText(false);
        } else {
            setcopyText(true);
        }
        // console.log('保存草稿', text);
        const uids: any[] = [];
        userIds.forEach((u: string) => {
            const temp: any = sessionMembers.find((i: any) => {
                if (i.role === 'unit' || i.role === 'dept') {
                    return extraConvertEmployeeToString(i as Employee, selectedSession) === u;
                }
                return convertEmployeeToString(i as Employee) === u;
            });
            // console.log('遍历结果', temp);
            if (temp) {
                let uid: any;
                if (temp.role === 'unit') {
                    uid = {
                        nickname: temp.name,
                        unitId: temp.unitDeptId,
                    };
                } else if (temp.role === 'dept') {
                    uid = {
                        nickname: temp.name,
                        deptId: temp.deptId,
                    };
                } else {
                    uid = {
                        app: temp.app || '',
                        teamId: temp.teamId || '',
                        nickname: temp.name,
                        pin: temp.userId || '',
                    };
                }
                uids.push(uid);
            }
        });
        // userIds.forEach((u: string) => {
        //     const temp = sessionMembers.find((i) => i.userId === u);
        //     console.log('保存草稿匹配结果', temp);
        //     if (temp) {
        //         const uid: UID = {
        //             app: temp.app || '',
        //             teamId: temp.teamId || '',
        //             nickname: temp.name,
        //             pin: temp.userId || '',
        //         };
        //         uids.push(uid);
        //     }
        // });

        draftRef.current = {
            sessionId: session.id || '',
            sessionStatusInfo: {
                status: MessageStatus.EDITING,
                // timestamp: Date.now(),
                timestamp: new Date().getTime(),
                info: {
                    atUsers: uids,
                    content: text,
                    delta,
                },
            },
        };
    }

    const onMainWinHidden = useCallback(() => {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const { text } = jeditor.getTextMessage();
        if (text?.trim()) {
            console.log(`tj:---selectedSession?.sessionId-:${text}-`, draftRef.current);
            saveDraft(selectedSession);
        }
        console.log(
            `tj:---selectedSession?.sessionId-:${selectedSession?.sessionId}-`,
            draftRef.current
        );
        if (selectedSession?.sessionId && draftRef.current) {
            setSessionStatus(draftRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession]);

    /**
     * 切换聊天窗口时清空输入框
     */
    useEffect(() => {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        if (preSelectedId !== selectedSession.id) {
            if (draftRef.current) {
                setSessionStatus(draftRef.current);
                draftRef.current = null;
            }
            jeditor.clearMessage();
            // 从草稿中提取信息
            const { info } = sessionStatusMap[selectedSession.id] || {};
            if (info) {
                // jeditor.insertText(info.content);
                const quill = jeditor.getQuill();
                if (quill) {
                    quill.setContents(info.delta);
                    setTimeout(() => {
                        const length = quill.getLength();
                        quill.setSelection(length);
                    });
                }
            }
            // jeditor.changePlaceholder(t('send_to_sb').replace('%s', selectedSession.info.name));
            dispatch({ type: 'closeReply' });
            jeditor.focus();
            if (detectOS() === 'Win') {
                setMessageInputFocus(true);
            }
            jeditor.changePlaceholder('请输入消息');
        }
    }, [
        selectedSession.id,
        selectedSession.info.name,
        setSessionStatus,
        setMessageInputFocus,
        t,
        preSelectedId,
        sessionStatusMap,
    ]);

    /**
     * 消息输入框是否焦点
     */
    const onEditorFocus = useCallback(
        (status: boolean) => {
            if (detectOS() === 'Win') {
                setMessageInputFocus(status);
            }
        },
        [setMessageInputFocus]
    );

    useEffect(() => {
        const jeditor: any = inputRef.current;
        if (!jeditor || !jeditor.events) {
            return;
        }
        jeditor.events.on('editor-on-focus', onEditorFocus);
        return () => {
            jeditor.events.off('editor-on-focus', onEditorFocus);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            onMainWinHidden();
        };
    }, [onMainWinHidden]);

    const [inputDisable, setInputDisable] = useState(false);

    const isBanned = useMemo(() => {
        return isGroupOrdinary(groupRosterIdentity) && isBannedPostGroupSession(selectedSession);
    }, [groupRosterIdentity, selectedSession]);

    // 群禁言时处理一下交互
    useEffect(() => {
        if (isBanned) {
            const jeditor: any = inputRef.current;
            if (!jeditor) {
                // eslint-disable-next-line no-console
                console.log('can not find jeditor');
                return;
            }
            const { text } = jeditor.getTextMessage();
            if (text) {
                return;
            }
            setInputDisable(true);
            setTimeout(() => {
                jeditor.changePlaceholder(t('Group manager has been banned'));
            });
        } else {
            setInputDisable(false);
        }
    }, [isBanned, t]);

    /**
     * 设置回复的数据
     * @param props
     */
    let qlContainer: any = document.querySelector('.ql-container');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleReply(props: any) {
        let weizhi: any = document.getElementById('input-control-container'); // 输入框
        const { replyText, replyUser, replyMessage } = props;
        dispatch({ type: 'showReply', payload: { replyText, replyUser, replyMessage } });
        const jeditor: any = inputRef.current;
        if (weizhi.style.height <= 160 + 'px') {
            qlContainer.style.maxHeight = 36 + 'px';
        }
        if (weizhi.style.height >= 266 + 'px') {
            qlContainer.style.maxHeight = 138 + 'px';
        }
        if (!jeditor) {
            return;
        }
        jeditor.focus();
    }
    // 撤回编辑
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line complexity
    function SetMessageFromReEdit(props: any) {
        const { message } = props;
        let { atUsers, content } = message;
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        let range = { index: 0, length: 0 };
        let { delta } = jeditor.getTextMessage();
        if (isEndwithEnter(delta)) {
            const length = delta.length();
            delta = delta.compose(new Delta().retain(length - 1).delete(1));
        }
        let result = content;
        if (atUsers && atUsers?.length > 0) {
            let tempIndex = 0;
            for (const u of atUsers) {
                const atStr = `@${u.nickname}`;
                tempIndex = result.indexOf(atStr);
                if (tempIndex >= 0) {
                    delta = delta.insert(result.substring(0, tempIndex)).insert({
                        'mention-link': {
                            id: `${u?.pin || u?.unitId || u?.deptId || ''}:${
                                u?.app || currentEmployee.app || ''
                            }:${u?.teamId || currentEmployee.teamId || ''}`,
                            // id: `${u?.pin}:${u?.app}:${u?.teamId}`,
                            type: 'user',
                            name: u.nickname,
                        },
                    });
                    result = result.substr(tempIndex + atStr.length);
                }
            }
            delta = delta.insert(result);
        } else {
            const patt = /\[.*?\]/g;
            const re = content.match(patt);
            let result = content;
            if (re && re.length > 0) {
                const reSet = new Set([...re]);
                reSet.forEach((r: string) => {
                    if (!r) {
                        return;
                    }
                    if (!stickers_sets[r]) {
                        return;
                    }
                    let tmpIndex = r.indexOf(']');
                    let tmpStr = r.substring(1, tmpIndex);
                    let regexp = new RegExp(`\\[${tmpStr}\\]`, 'g');
                    result = result.replace(
                        regexp,
                        `<span class="e-emoji" contenteditable="false" data-value="${r}">&#65279;<span contenteditable="false">${r}</span>&#65279;</span>`
                    );
                });
            }
            const pattern1 = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
            const specialEmojiRegex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;
            let rtnResult = '';
            for (const temp of result) {
                if (pattern1.test(temp) && stickers_sets[temp]) {
                    rtnResult += `<span class="e-emoji" contenteditable="false" data-value="${temp}">&#65279;<span contenteditable="false">${temp}</span>&#65279;</span>`;
                } else {
                    if (
                        (specialEmojiRegex.test(temp) && stickers_sets[temp]) ||
                        specialEmojiRegex.test(temp)
                    ) {
                        // console.log('2', specialEmojiRegex.test(temp));
                        rtnResult += `<span class="e-emoji" contenteditable="false" data-value="${temp}">&#65279;<span contenteditable="false">${temp}</span>&#65279;</span>`;
                    } else {
                        rtnResult += temp;
                    }
                }
            }
            const contents = rtnResult.replace(/\n/g, '<br/>');
            const p = `<p>${contents}</p>`;
            delta = quill.clipboard.convert(p).delete(range.length);
            let NewResult = new Delta().retain(range.index);
            delta.forEach((op: any) => {
                if (op.insert && (typeof op.insert === 'string' || op.insert['custom-emoji'])) {
                    NewResult.ops.push({ insert: op.insert });
                }
            });
            NewResult = NewResult.delete(range.length);
            quill.updateContents(result, 'user');
        }
        // quill.updateContents(result, 'user');
        // // range.length contributes to delta.length()
        // quill.setSelection(result.length() - range.length, 'silent');
        if (quill) {
            quill.setContents(delta);
            setTimeout(() => {
                const length = quill.getLength();
                quill.setSelection(length);
            });
        }
    }
    const onVisibleChange = (flag: boolean) => {
        setVisible(flag);
    };
    function doPaste(e: any) {
        // e.stopPropagation();
        // e.preventDefault();
        let Path: any = null;
        let dataFile: any = null;
        document.addEventListener('paste', onPaste);
        async function onPaste(e: any) {
            // e.preventDefault();
            // e.stopPropagation();
            // 禁言的会话
            if (isBannedPostGroupSession(selectedSession)) {
                return false;
            }
            const usefilePath = await FocusSDK.getClipboardPaths();
            // 使用 new File 生成可用于Web端的 File 对象
            const fileList = usefilePath.map(({ fileName, filePath, data }) => {
                Path = filePath;
                dataFile = data;
                // 适配咚咚复制图片
                if (!data && usefilePath !== []) {
                    return dataURLtoFile(usefilePath.toString(), 'image.png');
                }
                return new window.File([data], fileName, {
                    type: lookup(fileName) as any,
                    lastModified: Date.now(),
                });
            });
            if (
                fileList &&
                fileList.length > 0 &&
                dataFile &&
                !fileList[0].type.match(
                    /^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i
                )
            ) {
                uploadFile(fileList, 'single', Path);
            } else {
                handleUploadImgs(fileList);
            }
            document.removeEventListener('paste', onPaste);
        }
        focus();
        document.execCommand('paste');
        setpasteVisible(false);
        setcopyVisible(false);
    }
    function doCopy(e: any) {
        // e.stopPropagation();
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        let { text } = jeditor.getTextMessage();
        let userSelection;
        let str = text;
        if (typeof window.getSelection === 'function') {
            userSelection = window.getSelection();
        }
        if (userSelection?.toString()) {
            str = userSelection.toString();
        }
        if ((str || '').length > (text || '').length) {
            str = text;
        }
        if (str && CopyClipboard(str)) {
            toast.success(t('copy-succeeded'));
        } else {
            toast.error(t('copy-failed'));
        }
        jeditor.focus();
        setcopyVisible(false);
        setpasteVisible(false);
    }
    function focus() {
        const jeditor: any = inputRef.current;
        state.replyVisible = false;
        qlContainer.style.maxHeight = null;
        if (!jeditor) {
            return;
        }
        jeditor.focus();
    }

    function setContent(delta: any) {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (quill) {
            quill.setContents(delta);
            setTimeout(() => {
                const length = quill.getLength();
                quill.setSelection(length);
            });
        }
    }

    /**
     * 初始化数据及监听
     */
    useEffect(() => {
        const stt = setTimeout(() => {
            clearTimeout(stt);
            handleResize();
        }, 200);

        window.addEventListener('resize', handleResize);

        if (ChatEvent) {
            // 处理右侧展开
            ChatEvent.on('rightSide-change', handleResize);
            // 处理reply
            ChatEvent.on('chat-reply', handleReply);
            ChatEvent.on('close-mention', closeMention);
            ChatEvent.on('reedit-message', SetMessageFromReEdit);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (ChatEvent) {
                ChatEvent.removeListener('rightSide-change', handleResize);
                ChatEvent.removeListener('chat-reply', handleReply);
                ChatEvent.removeListener('close-mention', closeMention);
                ChatEvent.removeListener('reedit-message', SetMessageFromReEdit);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleReply, handleResize]);

    const handleSearch = useCallback(
        (input: string) => {
            if (!input) {
                return sessionMembers;
            }
            const temp = sessionMembers.filter((item: any) => item.role !== 'all');
            return temp.filter((s) => {
                if (!isGroupVisibleUnit(selectedSession)) {
                    const unitName = s?.unitName || filterDeptFullName(s.e_dept_full_name);
                    return (
                        PinyinMatch.match(s.name || '', input) ||
                        unitName.includes(input) ||
                        PinyinMatch.match(unitName, input)
                    );
                } else {
                    const deptName = s?.deptName || '';
                    return (
                        PinyinMatch.match(s.name || '', input) ||
                        deptName.includes(input) ||
                        PinyinMatch.match(deptName, input)
                    );
                    // return PinyinMatch.match(s.name || '', input);
                }
            }); // [1, 3])
        },
        [sessionMembers, selectedSession]
    );

    function closeMention() {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.events.emit('close-mention');
    }

    /**
     * 内容变更事件
     * @param delta
     */
    function contentChange(delta: any, oldDelta: any, source: Sources) {
        handleInputStyleChange();
        saveDraft(selectedSession);
        if (visible) {
            setVisible(false);
        }
    }

    useEffect(() => {
        let config = JSON.parse(FocusSDK.getConfigData());
        setMsgtureType({ sendMsgType: config.sendMsgType || '1' });
        if (config.sendMsgType === '1') {
            setIsenter('Enter');
            setIsctrl('Ctrl + Enter');
        } else if (config.sendMsgType === '2') {
            setIsenter('Ctrl + Enter');
            setIsctrl('Enter');
        }
    }, [setMsgtureType]);

    /**
     * 回车发送消息 和 alt + 回车插入新行
     * 拦截包围框的按键事件，在内部处理事件之后，不会与@ 的回车混叠
     * @param e
     */
    // eslint-disable-next-line complexity
    function handleKeyDown(e: any) {
        // 屏蔽pageDonw，
        if (e.key === 'PageDown' || e.key === 'PageUp') {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (sendMsgType === '1') {
            if (e.key === 'Enter' && (e.ctrlKey || e.shiftkey || e.optionkey)) {
                e.preventDefault();
                e.stopPropagation();
                insertText('\n');
                return;
            }
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                sendMessage();
                return;
            }
        }
        if (sendMsgType === '2') {
            if (e.key === 'Enter' && (e.ctrlKey || e.shiftkey || e.optionkey)) {
                e.preventDefault();
                e.stopPropagation();
                sendMessage();
                return;
            }
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                insertText('\n');
            }
        }
        // if (!keyNum) {
        //     if (e.key === 'Enter' && (e.ctrlKey || e.shiftkey || e.optionkey)) {
        //         e.preventDefault();
        //         e.stopPropagation();
        //         insertText('\n');
        //         return;
        //     }
        //     if (e.key === 'Enter') {
        //         e.preventDefault();
        //         e.stopPropagation();
        //         sendMessage();
        //     }
        // }
    }

    // 手动发送消息
    const handleSendmessage = (e: any) => {
        e.persist();
        sendMessage();
        focus();
    };

    const doHandleSendmessage = debounce(handleSendmessage, 300);

    const handleEnterClick = (e: any) => {
        setKeyNum(e.key);
        isFocusEnv() && FocusSDK.setConfigData('sendMsgType', e.key);
        setMsgtureType({ sendMsgType: e.key });
        setIsenter('Enter');
        setIsctrl('Ctrl + Enter');
    };
    const handleCtrlClick = (e: any) => {
        setKeyNum(e.key);
        isFocusEnv() && FocusSDK.setConfigData('sendMsgType', e.key);
        setMsgtureType({ sendMsgType: e.key });
        setIsenter('Ctrl + Enter');
        setIsctrl('Enter');
    };
    // 右下角切换方式
    const menu = (
        <Menu selectable={true} defaultSelectedKeys={[sendMsgType]}>
            <Menu.Item
                key="1"
                onClick={handleEnterClick}
                clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|Send_Enter"
            >
                按Enter发送消息
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={handleCtrlClick}
                clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|Send_Ctrl_Enter"
            >
                按Ctrl+Enter发送消息
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        let weizhi: any = document.getElementById('input-control-container'); // 输入框
        let domMessageScroll: any = document.getElementById('message-box-container-scroll');
        let isIpt: any = document.getElementById('input-box-multiline');
        let onMousedown: any = document.getElementById('input-line'); // 透明线
        let body: any = document.querySelector('body');
        let a = 0;
        const mouseDownDBClick = function (e: any) {
            const weizhiHeight = weizhi?.getBoundingClientRect()?.height;
            console.log(weizhiHeight, 'weizhiHeight');
            console.log('dblclick');
            if (weizhiHeight === 266) {
                weizhi.style.height = 160 + 'px';
                weizhi.style.minHeight = 160 + 'px';
                weizhi.style.maxHeight = 'auto';
                isIpt.style.height = 100 + 'px';
                isIpt.style.maxHeight = 50 + '%';
                state.replyVisible === true && (qlContainer.style.maxHeight = 36 + 'px');
            } else {
                weizhi.style.maxHeight = 266 + 'px';
                weizhi.style.height = 266 + 'px';
                weizhi.style.minHeight = 'auto';
                isIpt.style.height = 200 + 'px';
                isIpt.style.maxHeight = 70 + '%';
                weizhi.style.minHeight = 160 + 'px';
                state.replyVisible === true && (qlContainer.style.maxHeight = 138 + 'px');
            }
        };
        // 透明线双击，文本区域变最高
        onMousedown?.addEventListener('dblclick', mouseDownDBClick);
        // 获取鼠标点击位置
        const mouseDownClick = function (e: any) {
            if (domMessageScroll === null) {
                return;
            }
            console.log('mousedown');
            let mouseDownY = e.clientY;
            let H = weizhi.offsetHeight;
            body.style.cursor = 'n-resize';
            body.style.opacity = '1.1';
            body.style.position = 'absolute';
            body.style.top = '0';
            body.style.left = '0';
            body.style.width = '100%';
            body.style.backgroundColor = '#fff';
            body.style.zIndex = '5000';
            document.addEventListener('mousemove', move);
            function move(e: any) {
                console.log('mousemove');
                let mouseMoveY = e.clientY;
                const weizhiHeight = mouseDownY - mouseMoveY + H;
                weizhi.style.height = `${weizhiHeight}px`;
                if (weizhi) {
                    domMessageScroll.scrollTop = domMessageScroll.scrollHeight;
                }
                body.style.cursor = 'n-resize';
                if (parseInt(weizhi.style.height, 10) >= 266) {
                    weizhi.style.maxHeight = 266 + 'px';
                    isIpt.style.height = 200 + 'px';
                    isIpt.style.maxHeight = 70 + '%';
                    state.replyVisible === true && (qlContainer.style.maxHeight = 138 + 'px');
                } else if (parseInt(weizhi.style.height, 10) <= 160) {
                    weizhi.style.height = 160 + 'px';
                    weizhi.style.minHeight = 160 + 'px';
                    isIpt.style.height = 100 + 'px';
                    isIpt.style.maxHeight = 50 + '%';
                    state.replyVisible === true && (qlContainer.style.maxHeight = 36 + 'px');
                } else {
                    isIpt.style.maxHeight = '';
                    state.replyVisible === true &&
                        (qlContainer.style.maxHeight = ` ${weizhiHeight - 132}px`);
                }
            }
            // (3) 鼠标弹起，就让鼠标移动事件移除
            document.addEventListener('mouseup', mouseUpEvent);
            function mouseUpEvent(evt: any) {
                console.log('mouseup');
                evt.stopPropagation();
                evt.preventDefault();
                document.onmousemove = null;
                document.onmouseup = null;
                body.style.cursor = 'auto';
                document.removeEventListener('mousemove', move);
                document.removeEventListener('mouseup', mouseUpEvent);
            }
        };
        onMousedown?.addEventListener('mousedown', mouseDownClick);
        return () => {
            onMousedown?.removeEventListener('dblclick', mouseDownDBClick);
            onMousedown?.removeEventListener('mousedown', mouseDownClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.replyVisible]);

    /**
     * 处理输入框样式，判断单行还是多行
     */
    function handleInputStyleChange() {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (!quill) {
            return;
        }

        // 如果有回车或者超出一行，则变更为多行样式
        const length = quill.getLength();
        const text = quill.getText(0, length - 1);
        const index = text.indexOf('\n');
        if (index > -1) {
            setExpand(true);
            return;
        }
        // todo
        const cu: any = divEl.current;
        if (cu) {
            const div = cu.querySelector('.ql-editor');
            const width = div.scrollWidth;
            if (width > inputWidth) {
                setExpand(true);
            } else {
                setExpand(false);
            }
        }
    }
    // const node = document.querySelectorAll('#need-merge');
    async function sendMessage() {
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        let { text, userIds } = jeditor.getTextMessage();
        text = text.trim();
        while (text[text.length - 1] === '\n') {
            text = text.slice(0, -1);
        }
        if (!text) {
            return;
        }
        // console.log('发送消息', userIds, sessionMembers);
        const uids: any[] = [];
        userIds.forEach((u: string) => {
            const temp: any = sessionMembers.find((i: any) => {
                if (i.role === 'unit' || i.role === 'dept') {
                    return extraConvertEmployeeToString(i as Employee, selectedSession) === u;
                }
                return convertEmployeeToString(i as Employee) === u;
            });
            // console.log('遍历结果', temp);
            if (temp) {
                let uid: any;
                if (temp.role === 'unit') {
                    uid = {
                        nickname: temp.name,
                        unitId: temp.unitDeptId,
                    };
                } else if (temp.role === 'dept') {
                    uid = {
                        nickname: temp.name,
                        deptId: temp.deptId,
                    };
                } else {
                    uid = {
                        app: temp.app || '',
                        teamId: temp.teamId || '',
                        nickname: temp.name,
                        pin: temp.userId || '',
                    };
                }
                uids.push(uid);
            }
        });
        let messageTypeOption = (await getMessageTypeByText(text)) as any;
        messageTypeOption = await nonExistentPageToText(messageTypeOption);
        let messageBody;
        let displayContent;
        switch (messageTypeOption.type) {
            case ChatMessageType.JOYSPACEFILE:
                messageBody = await buildJoyspaceLinkMessageBody(
                    text,
                    messageTypeOption as JouspaceLinkTypeOption,
                    uids,
                    selectedSession,
                    state.replyMessage
                );
                break;
            case ChatMessageType.TEXT:
            default:
                displayContent = !messageTypeOption?.is
                    ? await sendMessageBody(text, [selectedSession])
                    : messageTypeOption?.nativeId === 'share_link'
                    ? undefined
                    : convertContentToArray(text);
                messageBody = buildTextMessageBody(
                    text,
                    uids,
                    state.replyMessage,
                    messageTypeOption as HttpCardLinkTypeOption,
                    displayContent
                );
        }
        // console.log('messageBody', messageBody);
        const message: Partial<ChatMessage> = initChatMessageInfo({
            selectedSession: selectedSession,
            currentEmployee: currentEmployee,
            message: messageBody,
        });
        const { needMerge } = getToMergeMessage(
            selectedSession,
            sessionMessageMap[selectedSession.id],
            message as ChatMessage
        );
        bus.emit(`message:Box`, needMerge);
        bus.emit(`message:Container`, needMerge);
        pushChatMessage({ sessionId: selectedSession.sessionId, message });
        dispatch({ type: 'closeReply' });
        jeditor.clearMessage();
        jeditor.events.emit('close-mention');
    }

    async function handleUploadImgs(files: any, type?: string) {
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        let noFolderFiles: any[] = [];
        if (
            ![SessionType.SINGLE, SessionType.SECRET_SINGLE, SessionType.GROUP].includes(
                selectedSession.sessionType
            )
        ) {
            return;
        }
        if (!files || (files && !files.length)) {
            return;
        } else {
            let allFilesPromise = [].slice.call(files, 0).map((item: any) => {
                return filterDirectory(item, false);
            });

            let result = await Promise.all(allFilesPromise);
            result.forEach((itemFlag, index) => {
                if (itemFlag) {
                    noFolderFiles.push(files[index]);
                }
            });
            if (!noFolderFiles.length) {
                AntMessage.warn('暂不支持发送文件夹，您可以将文件夹压缩后发送');
                return;
            }
        }
        dispatch({ type: 'clearImageState' });
        // console.log('选择图片', files, state.imgState);
        if (noFolderFiles.length > 1) {
            setMulti(true);
            let typeList: any[] = [];
            noFolderFiles.forEach(async (file: any) => {
                const promise = filterImages(file);
                typeList.push(promise);
            });
            // console.log('typeList', typeList);
            Promise.all(typeList).then((result) => {
                const isAllImages = result.every((item) => item); // 是否全部选择的图片类型
                if (isAllImages) {
                    // 先弹框，在处理旋转问题
                    dispatch({
                        type: 'openUploadImgModal',
                        payload: {
                            imgState: state.imgState,
                            file: state.file,
                            fileList: noFolderFiles,
                        },
                    });
                } else {
                    uploadFile(noFolderFiles);
                }
            });
        } else {
            setMulti(false);
            const file = noFolderFiles[0];
            // console.log('单张图片', file);
            const isImage = await filterImages(file);
            // console.log('isImage', isImage);
            if (!isImage) {
                uploadFile([file]);
                return;
            }
            let tempFile = file;
            // const isHeic = await isHeicImage(tempFile);
            // if (isHeic) {
            //     tempFile = await heicToPng(tempFile);
            // }
            const data = await dealImagesData(tempFile);
            // console.log('data', data);
            if (type !== 'captureImg') {
                dispatch({
                    type: 'openUploadImgModal',
                    payload: { ...data, fileList: [] },
                });
            } else {
                return data;
            }
        }
    }

    function closeUploadImgModal() {
        dispatch({ type: 'closeUploadImgModal' });
        setTimeout(() => {
            dispatch({ type: 'clearImageState' });
        }, 0);
    }

    async function sendImg(selectedImgs?: any, withParam?: boolean, params?: any) {
        dispatch({ type: 'closeUploadImgModal' });
        if (isMulti) {
            uploadMultiImg(selectedImgs);
        } else {
            if (withParam) {
                uploadImg(params.imgState, params.file);
            } else {
                uploadImg(state.imgState, state.file);
            }
        }
        // setTimeout(() => {
        //     dispatch({ type: 'clearImageState' });
        // }, 2000);
    }

    function insertText(str: string) {
        const jeditor = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.insertText(str);
    }

    function handleAtClick(e: any) {
        insertText('@');
        dispatch({
            type: 'handleAtClick',
        });
    }

    function handleQuickReplyClick(visible: boolean) {
        // 打开常用语时 关闭@
        if (visible) {
            closeMention();
        }
        dispatch({
            type: 'changeQuickReplyVisible',
            payload: { quickReplyVisible: visible },
        });
    }

    useEffect(() => {
        if (!offCaptureHandle && isFocusEnv()) {
            offCaptureHandle = FocusSDK.onIpcMessage('captureHandle', async (data: any) => {
                const { type, url } = data;
                if (type === 'share') {
                    // 暂时处理方案，截屏分享操作监听会触发多次
                    forwardOperate(url);
                } else if (type === 'complete') {
                    // 群禁言或者是系统消息不弹出发送弹窗
                    if (
                        !isBanned ||
                        [SessionType.SINGLE, SessionType.SECRET_SINGLE, SessionType.GROUP].includes(
                            selectedSession.sessionType
                        )
                    ) {
                        const file = dataURLtoFile(url, 'image.png');
                        handleUploadImgs([file]);
                    }
                } else {
                    console.log(data);
                }
            });
        }
        return () => {
            if (offCaptureHandle) {
                offCaptureHandle();
            }
            offCaptureHandle = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBanned, offCaptureHandle, selectedSession.sessionType]);

    // 截屏按钮点击
    function handleCaptureClick() {
        setTimeout(() => {
            // 是否隐藏当前窗口
            if (captureType === '1') {
                FocusSDK.sendMainMessage('mainHide', {}, () => {
                    console.log('发送了mainHide');
                });
            }

            // 截屏api调用
            FocusSDK.captureScreen((data) => {
                console.log(data);
            });
        }, 300);
    }

    // 生成截屏图片的消息体
    const buildCaptureImageMessage = async (url: string, item: any) => {
        const file = dataURLtoFile(url, 'image.png');
        const fileData = await handleUploadImgs([file], 'captureImg');
        const { imgState } = fileData;
        // 创建上传任务，将截屏图片上传
        const imService = ImService.getInstance();
        // TODO: 目前只处理单聊
        const type = item.isSecret ? SessionType.SECRET_SINGLE : SessionType.SINGLE;
        const to = {
            ...item,
            ...(item.origin?.uid || {}),
        };
        const sessionId = item.isGroup
            ? item.id
            : imService.generateSingleSessionId(currentEmployee, to, type);
        const session: any = {
            sessionId,
        };
        const task = createUploadTask(sessionId, fileData.file);
        if (task) {
            task.on('state', async (state1: any) => {
                if (state1.state === 'completed') {
                    if (!state1.downloadUrl) return;
                    const message: Partial<ChatMessage> = buildImageMessageBody(
                        state1.downloadUrl,
                        file.size,
                        imgState.width,
                        imgState.height
                    );
                    const chatMessage = initChatMessageInfo({
                        selectedSession: session,
                        currentEmployee: currentEmployee,
                        message: message,
                        task: task,
                    });
                    const messageBody = omit(cloneDeep(chatMessage), [
                        'status',
                        'taskId',
                        'task',
                        'mid',
                        'beforeMid',
                        'sessionId',
                        'groupId',
                        'statusType',
                    ]) as any;

                    imService.sendChatMessage(
                        session.sessionId,
                        chatMessage.id as string,
                        messageBody
                    );
                }
            });
            task?.start();
        } else {
            log.warn('创建上传任务失败=======>', JSON.stringify(file));
        }
    };

    // 人员选择器调起
    const forwardOperate = (url: string) => {
        // props.unLock();
        const fixedUsers = getSelectorSessions(allSessionList);
        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                // mode: SelectorMode.forward,
                mode: SelectorMode.user,
                tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recentIncludeGroup', 'org', 'groupCanBeSel', 'externalContacts']
                    : ['recentIncludeGroup', 'groupCanBeSel', 'externalContacts'],
            },
            (data, close) => {
                doExitAndForward(data, close, url);
            },
            userData
        );
    };

    const exitAndForward = async (data: any, url: string) => {
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        console.log(list, 'sessions');
        list.forEach((item: any) => {
            buildCaptureImageMessage(url, item);
        });
    };

    const doExitAndForward = debounce(async (data: any, close, url: string) => {
        // 等待消息转发完成以后关闭窗口
        await exitAndForward(data, url);
        close();
    }, 500);

    function handleAtVisibleChange(visible: boolean) {
        if (visible) {
            dispatch({
                type: 'handleAtClick',
            });
        }
    }

    function handleQuickReplyItemClick(detail: any) {
        // 需求变更 常用语会变三行，同意类，中性类，反对类。点击后到输入框中，不是直接发送
        const jeditor = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (!quill) {
            return;
        }
        quill.focus();
        setTimeout(() => {
            const range = quill.getSelection();
            if (!range) return;
            quill.setText(detail);
            quill.setSelection(detail.length + 1, 0);
        });
        dispatch({ type: 'closeReply' });
        focus();
    }

    function handleImgClick() {
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        // 点击图片关闭常用语、表情、@modal框
        dispatch({
            type: 'handleImgClick',
        });
        closeMention();
        const input = document.createElement('INPUT');
        document.body.appendChild(input);
        input.setAttribute('type', 'file');
        input.setAttribute('multiple', 'true');
        input.setAttribute('accept', 'image/*');
        document.body.appendChild(input);
        input.onchange = (ev: any) => {
            if (ev.target.files) {
                handleUploadImgs(ev.target.files);
            }
        };
        input.click();
        document.body.removeChild(input);
    }

    function handleEmojiClick(str: string) {
        const jeditor = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (!quill) {
            return;
        }
        quill.focus();
        setTimeout(() => {
            const range = quill.getSelection();
            if (!range) return;
            const { index } = range;
            const Delta = Quill.import('delta');
            quill.updateContents(
                new Delta().retain(index).insert({
                    'custom-emoji': str,
                }),
                'user'
            );
            quill.setSelection(index + 1, 0);
        });
    }
    function handleFileClick() {
        closeOtherModal();
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        const input = document.createElement('INPUT');
        input.setAttribute('type', 'file');
        input.onchange = (ev: any) => {
            if (ev.target.files && ev.target.files.length > 0) {
                uploadFile(ev.target.files[0]);
            }
        };
        input.click();
    }

    function handleEmojiVisible(visible: boolean) {
        // 点击表情关闭@modal框
        if (visible) {
            closeMention();
        }
        dispatch({
            type: 'changeEmojiVisible',
            payload: { visible },
        });
    }

    // 点击+号关闭常用语和@modal框
    function closeOtherModal() {
        closeMention();
    }
    // 鼠标右键
    function onContextMenu(e: any) {
        e.preventDefault();
        e.stopPropagation();
        const clientX = e.clientX;
        const clientY = e.clientY;
        const copyText = window.getSelection()?.toString();
        const dom: any = document.getElementsByClassName('input-copy-paste')[0] || null;
        if (dom) {
            dom.style.top = clientY + 'px';
            dom.style.left = clientX + 'px';
            setpasteVisible(true);
            if (copyText || copyTexts) {
                setcopyVisible(true);
            }
        }
    }
    // 关闭复制粘贴div
    function closeContextMenu() {
        setpasteVisible(false);
        setcopyVisible(false);
    }
    // 鼠标左键
    function doMouseDown(e: any) {
        e.stopPropagation();
        if (e.target.className === 'paste-space') {
            doPaste(e);
            return;
        } else if (e.target.className === 'paste-copy') {
            doCopy(e);
            return;
        }
        closeContextMenu();
    }
    function getRightMenu() {
        const menus: any[] = [];
        // 表情
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_EMOJI]) {
            menus.push(
                <EmojiPopover
                    key="1"
                    onclick={handleEmojiClick}
                    visible={state.emojiVisible}
                    onVisibleChange={handleEmojiVisible}
                >
                    <Tooltip title={t('emoji')} placement="bottom">
                        <IconFont
                            className="expression input-button"
                            type="iconic_app_im_expression"
                        />
                    </Tooltip>
                </EmojiPopover>
            );
        }
        // 图片
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_IMAGE]) {
            menus.push(
                <Tooltip key="3" title={t('image')} placement="bottom">
                    <IconFont
                        className="pic input-button"
                        type="iconic_app_im_picture"
                        onClick={handleImgClick}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|AddPic"
                    />
                </Tooltip>
            );
        }
        // 截屏
        if (
            config[ChatConfig.CHAT_MESSAGE_INPUT_CAPTURE] &&
            isFocusEnv() &&
            !selectedSession.isSecret
        ) {
            menus.push(
                <ScreenCapture>
                    <IconFont
                        className="at input-button capture-icon"
                        type="iconic_pc_im_screenshot1"
                        onClick={handleCaptureClick}
                        clstag="pageclick|keycount|focus_chat_01_1602584371145|42"
                    />
                    {/* <Tooltip
                        key="8"
                        title={t('capture')}
                        placement="bottom"
                        mouseLeaveDelay={100}
                        visible={true}
                    >
                        <IconFont
                            className="at input-button"
                            type="iconic_pc_im_screenshot1"
                            onClick={handleCaptureClick}
                            clstag="pageclick|keycount|focus_chat_01_1602584371145|42"
                        />
                    </Tooltip> */}
                </ScreenCapture>
            );
        }
        // @
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_AT] && !selectedSession.isSingle) {
            menus.push(
                <Tooltip key="10" title={t('@remind')} placement="bottom">
                    <IconFont
                        className="at input-button"
                        type="iconic_app_im_mention"
                        onClick={handleAtClick}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|AtService"
                    />
                </Tooltip>
            );
        }
        // 常用语
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_CHANG]) {
            menus.push(
                <QuickReplyComp
                    visible={state.quickReplyVisible}
                    onItemClick={handleQuickReplyItemClick}
                    key={selectedSession.id}
                    onVisibleChange={handleQuickReplyClick}
                    quickReplys={quickReplys}
                >
                    <Tooltip key="7" title={t('quickReply')} placement="bottom">
                        <IconFont className="at input-button" type="icona-ic_app_im_Commonlyused" />
                    </Tooltip>
                </QuickReplyComp>
            );
        }
        // +号
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_FILE_IN_MENU]) {
            if (config[ChatConfig.CHAT_MESSAGE_INPUT_FILE]) {
                if (menus.length > 0) {
                    menus.push(<div key="4" className="sort-line" />);
                }
                menus.push(
                    <Tooltip key="5" title={t('file')} placement="bottom">
                        <IconFont
                            className="chat-input-more-menu-icon"
                            type="iconapp_btn_folder"
                            onClick={handleFileClick}
                        />
                    </Tooltip>
                );
            }
        } else {
            if (config[ChatConfig.CHAT_MESSAGE_INPUT_MORE]) {
                if (menus.length > 0) {
                    menus.push(<div key="4" className="sort-line" />);
                }
                menus.push(
                    <MoreDropdown
                        key="6"
                        // handleUploadImgs={handleUploadImgs}
                        uploadFile={
                            isBanned
                                ? () => {
                                      AntMessage.warn(t(SendMessageIsBanned));
                                  }
                                : uploadFile
                        }
                        sendJoyspaceMessage={sendJoyspaceMessage}
                        closeOtherModal={closeOtherModal}
                    >
                        <IconFont className="add input-button" type="icona-ic_app_im_Addto" />
                    </MoreDropdown>
                );
            }
        }

        return (
            <div className={`${!expand ? '' : 'input-control-container-multRight'} `}>
                <div className="input-control-container-right">{menus}</div>
            </div>
        );
    }

    if (inputDisable) {
        return (
            <BannedInput
                currentEmployee={currentEmployee}
                selectedSession={selectedSession}
                config={config}
                divEl={divEl}
            />
        );
    }

    return (
        <div
            id="input-control-container"
            className={`input-control-container ${
                inputDisable ? 'input-control-container-disable' : ''
            }`}
            ref={divEl}
            onKeyDown={handleKeyDown}
            onContextMenu={isFocusEnv() ? onContextMenu : undefined}
            onMouseDown={isFocusEnv() ? doMouseDown : undefined}
            onMouseLeave={isFocusEnv() ? closeContextMenu : undefined}
            onClick={(e) => {
                e.stopPropagation();
                closeSetting?.();
            }}
        >
            <div className="input-line" id="input-line" />
            {getRightMenu()}
            {state.replyVisible && (
                <div className="input-replay">
                    <div
                        className="action"
                        clstag="pageclick|keycount|Xtbg_Msg|InfoCancelQuote"
                        onClick={() => {
                            dispatch({ type: 'closeReply' });
                            focus();
                        }}
                    >
                        <IconFont className="icon-close" type="iconapp_btn_alert_cancel" />
                    </div>
                    <div className="sort-line" />
                    <div className="reply-text">
                        <span>
                            {t('input_quote_text')
                                .replace(
                                    '%name',
                                    (state.replyUser || {}).nickName || (state.replyUser || {}).name
                                )
                                .replace('%s', '')}
                        </span>
                        <p
                            className="dangerous-inner-html"
                            dangerouslySetInnerHTML={{
                                __html: state.replyText,
                            }}
                        />
                    </div>
                </div>
            )}
            {isFocusEnv() ? (
                <>
                    <div className="input-copy-paste">
                        {pasteVisible && (
                            <div
                                className="paste-space"
                                onClick={(e) => {
                                    doPaste(e);
                                }}
                            >
                                {t('paste')}
                            </div>
                        )}
                        {copyVisible && (
                            <div
                                className="paste-copy"
                                onClick={(e) => {
                                    doCopy(e);
                                }}
                            >
                                {t('copy')}
                            </div>
                        )}
                    </div>
                    <div
                        id="input-box-multiline"
                        className={`input-box ${
                            !expand ? 'input-box-singleline' : 'input-box-multiline'
                        }`}
                        style={
                            expand
                                ? {}
                                : {
                                      width: `${inputWidth}px`,
                                  }
                        }
                    >
                        <JEditor
                            contentChange={contentChange}
                            ref={inputRef}
                            upload={handleUploadImgs}
                            placeholder="请输入消息"
                            disableEnter={true}
                            maxLength={2000}
                            // onEditorKeyDown={handleKeyDown}
                        />
                    </div>
                </>
            ) : (
                <div
                    id="input-box-multiline"
                    className={`input-box ${
                        !expand ? 'input-box-singleline' : 'input-box-multiline'
                    }`}
                    style={
                        expand
                            ? {}
                            : {
                                  width: `${inputWidth}px`,
                              }
                    }
                >
                    <JEditor
                        contentChange={contentChange}
                        ref={inputRef}
                        upload={handleUploadImgs}
                        placeholder="请输入消息"
                        disableEnter={true}
                        maxLength={2000}
                        // onEditorKeyDown={handleKeyDown}
                    />
                </div>
            )}
            {/* {getRightMenu()} */}
            {config[ChatConfig.CHAT_MESSAGE_INPUT_AT] &&
                !selectedSession.isSingle &&
                inputRef.current && (
                    <MentionUserPlugin
                        editor={inputRef.current}
                        members={sessionMembers}
                        handleSearch={handleSearch}
                        t={t}
                        onVisibleChange={handleAtVisibleChange}
                    />
                )}
            <UpLoadImg
                visible={state.imgUploadModalVisible}
                onCancel={closeUploadImgModal}
                onClick={sendImg}
                isMulti={isMulti}
                fileList={state.fileList}
                file={state.file}
                base64={state.imgState.base64}
                title={state.imgState.title}
                size={state.imgState.size}
                width={state.imgState.width}
                height={state.imgState.height}
            />
            {/* 切换发送按钮 */}
            <div className="enter-container">
                <span className="enter-txt">
                    按{isenter === '' ? 'Enter' : isenter}发送消息，
                    {isctrl === '' ? 'Ctrl + Enter' : isctrl}换行
                </span>
                <div className="enter-btn1">
                    <Button
                        type="primary"
                        size="small"
                        block
                        onClick={doHandleSendmessage}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|Send"
                    >
                        发 送
                    </Button>
                </div>

                <div className="enter-btn2">
                    <Space>
                        <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            onVisibleChange={(visible) => {
                                if (visible) {
                                    analysisLog('Xtbg_Msg_Info_MsgDialog', 'SendKey');
                                }
                            }}
                        >
                            <Button type="primary" size="small">
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Space>
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);
