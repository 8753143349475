/**
 * 通讯录通用组件
 * @author sunzhiguang
 * @date 2020/9/11
 */

import React, { ReactNode } from 'react';
import { AddressNavKey } from '@/types/common';
import IconFont, { IconDefualtOrg } from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { UserCardType, UserCardAction, TitleDeptInfo, userDetailListPrams } from '@/types/UserCard';
import Bus from '@/utils/bus';
import { Apply, Employee, Friend, RosterApplyStatus, UID } from '@/types/chat';
import {
    EBookDeptInfoRes,
    EBookDeptUserInfo,
    EBookSubDeptInfo,
    Project,
    SimpleDeptInfo,
} from '@/models/ebook';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import ImService from '@/server/ImService';
import { Button, message } from 'antd';
import { getLabels } from '@/api/elabel';
import { EGroupGroupInfo, getGroupInfo, getGroupsByLabel } from '@/api/egroup';
import i18n from '@/i18n';
import { v4 } from 'uuid';
import { getDeptPositionDesc } from '@/models/user';
import { getEbookOrgInfo, getEbookSimpleDetailList, getLabelsList } from '@/api/ebook';
import { DeptCrumbLisInfo } from '@/services/EBookService';
import icon_jzb from '@/assets/img/icon-jzb.png';
import GlobalSearch from '../GlobalSearch';
import { SearchType } from '@/services/SearchService';
import { uniqBy, flattenDeep, last, omitBy, isEmpty, debounce } from 'lodash';
import { gatewayInstance } from '@/server/gateway';
import { ListItemData, labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';
import {
    createCloudLinkCall,
    createCloudLinkSingleCall,
    createCloudLinkTerminalCall,
} from '../MeetingCloudLink/utils';
import logger from '@/utils/logger';
import { changeEgovUrl } from '@/utils/tools';
import { getBigScreenMeetingTopic } from '@/baseComponents/Meeting/utils';
export function mapResultUsers(
    rosters: Partial<ListItemData>[],
    opts?: { checked?: boolean; visible?: boolean }
): ListItemData[] {
    return rosters.map((roster) => {
        return {
            ...roster,
            id: `${roster.userId}:${roster.app}:${roster.teamId}`,
            name: roster?.name,
            avatar: roster?.avatar,
            checked: opts?.checked || false,
            userId: roster?.userId,
            teamId: roster?.teamId,
            visible: opts?.visible || false,
        };
    });
}
const log = logger.getLogger('addressBook');

// 菜单配置
export const menuItems = [
    {
        id: AddressNavKey.NEW_CONTACTS,
        label: 'nav.new_contacts',
        avatar: <IconFont className="address-contacts-icon-color" type="iconic_pc_xinshenqing" />,
        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|4',
    },
    {
        id: AddressNavKey.EXTERNAL_CONTACTS,
        label: 'nav.external_contacts',
        avatar: <IconFont className="address-contacts-icon-color" type="iconic_pc_tongxunlu" />,
        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|5',
    },
    // {
    //     id: AddressNavKey.SPECIAL_PROJECTS,
    //     label: 'nav.my_projects',
    //     avatar: <IconFont className="address-group-icon-color" type="iconzw_app_project" />,
    //     clstag: 'pageclick|keycount|focus_contacts_01_1615797764654|4',
    // },
    {
        id: AddressNavKey.GROUP,
        label: 'nav.group',
        avatar: <IconFont className="address-group-icon-color" type="iconic_pc_wodezu" />,
        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|6',
    },
    // {
    //     id: AddressNavKey.MY_LABELS,
    //     label: 'nav.my_labels',
    //     avatar: <IconFont className="address-labels-icon-color" type="iconbiaoqian" />,
    // },
    // {
    //     id: AddressNavKey.ORG,
    //     label: 'nav.org',
    //     avatar: <IconFont className="address-org-icon-color" type="iconzuzhijiagou" />,
    // },
    // {
    //     id: AddressNavKey.MY_DEPARTMENT,
    //     label: 'nav.my_department',
    //     avatar: <IconFont className="address-my-department-icon-color" type="iconwodebumen" />,
    //     clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|7',

    // },
    // {
    //     id: AddressNavKey.ADD_EXTERNAL_CONTACTS_GROUP,
    //     label: 'nav.add_external_contacts_group',
    //     avatar: <IconFont className="address-add-contacts-icon" type="iconic_shared" />,
    // },
];

export function handleCreateGroupXCMeeting(dataList: any[], memberType?: number) {
    log.debug('信创视频会议', dataList, memberType);
    if (memberType === 1) {
        createCloudLinkSingleCall(
            {
                ...dataList[0],
                titleName: '',
                userName: dataList[0].fields.filter((item: any) => item.key === 'name')[0].val,
                avatar: dataList[0].fields.filter((item: any) => item.key === 'avatar')[0].val,
            },
            getBigScreenMeetingTopic()
        ).catch((err) => {
            message.error(err.message || err);
        });
        return;
    } else if (memberType === 2) {
        createCloudLinkTerminalCall([dataList[0].terminalId], getBigScreenMeetingTopic()).catch(
            (err) => {
                message.error(err.message || err || '会议创建失败');
            }
        );
        return;
    }

    const users = dataList?.filter((item: any) => item.memberType === 1) || [];
    const terminalIds = dataList?.filter((item: any) => item.memberType === 2) || [];
    const toUsers = users.map((item) => {
        return {
            teamId: item?.teamId,
            userId: item?.userId,
            appId: item?.appId || item?.app,
            userName: item?.fields?.filter((item: any) => item.key === 'name')[0].val,
            avatar: item?.fields?.filter((item: any) => item.key === 'avatar')[0].val,
        };
    });
    const terminals: any[] = terminalIds.map((item: any) => {
        return {
            terminalId: item?.terminalId,
        };
    });
    log.debug(toUsers, { terminalId: terminals }, 'terminalsterminalsterminals');
    createCloudLinkCall({
        toUsers: toUsers,
        terminals: { terminalId: terminals },
        opts: { meetingTopic: getBigScreenMeetingTopic() },
    }).catch((err) => {
        message.error(err.message || err || '会议创建失败');
    });
}

// 头
export function ContentHeader({
    title,
    handleSwitchClick,
    activeKey,
    extra,
    extraClick,
    TabSwitchNode,
    deptNavigation,
    creatMeeting,
    bigScreenTitle = false,
}: {
    title: string;
    handleSwitchClick?: (key: string) => void;
    activeKey?: string;
    extra?: string;
    extraClick?: () => void;
    TabSwitchNode?: ReactNode;
    deptNavigation?: any;
    creatMeeting?: ReactNode;
    bigScreenTitle?: boolean;
}) {
    const { t } = useTranslation('addressList');
    const [t_common] = useTranslation('common');
    const fileBtnClsTag = (extra: string): { btnName: string; clstag: string } => {
        switch (extra) {
            case t('nav.new_contacts'):
                return {
                    btnName: t_common('createButton.addContact'),
                    clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|10',
                };
            case t('nav.external_contacts'):
                return {
                    btnName: t_common('createButton.addContact'),
                    clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|13',
                };
            case t('nav.group'):
                return {
                    btnName: t_common('createButton.createGroup'),
                    clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|15',
                };
            case t('nav.tile'):
                return {
                    btnName: t('nav.tile'),
                    clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|37',
                };
            case t('nav.treeDiagram'):
                return {
                    btnName: t('nav.treeDiagram'),
                    clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|36',
                };
            default:
                return { btnName: '', clstag: '' };
        }
    };

    return (
        <div className="address-content-header">
            {bigScreenTitle ? (
                <>
                    <img className="address-content-header-img" src={icon_jzb} alt="" />
                    <div className="address-content-header-title">{deptNavigation}</div>
                    <div className="address-content-header-meeting">{creatMeeting}</div>
                </>
            ) : (
                <div className="address-content-header-title">{title}</div>
            )}
            <div className="switch_btn">
                {TabSwitchNode}
                {extra && (
                    <Button
                        className="address-nav-button"
                        onClick={debounce(() => {
                            extraClick?.();
                        }, 200)}
                        clstag={fileBtnClsTag(extra).clstag}
                    >
                        {fileBtnClsTag(extra).btnName}
                    </Button>
                )}

                {handleSwitchClick && activeKey && (
                    <>
                        <Button
                            className={activeKey === 'org' ? 'activeBtn btn' : 'unActiveBtn btn'}
                            onClick={() => {
                                handleSwitchClick('org');
                            }}
                            clstag={fileBtnClsTag(t('nav.tile')).clstag}
                        >
                            {fileBtnClsTag(t('nav.tile')).btnName}
                        </Button>
                        <Button
                            className={activeKey !== 'org' ? 'activeBtn btn' : 'unActiveBtn btn'}
                            onClick={() => {
                                handleSwitchClick('tree');
                            }}
                            clstag={fileBtnClsTag(t('nav.treeDiagram')).clstag}
                        >
                            {fileBtnClsTag(t('nav.treeDiagram')).btnName}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
}

// title
export function useRightListTitle(addressNavKey: AddressNavKey | string): string {
    const { t } = useTranslation('addressList');
    const selectedItem = menuItems.find((item) => item.id === addressNavKey);
    if (!selectedItem) {
        return '';
    }
    return t(selectedItem.label);
}

export interface SearchGroup {
    realName: string;
    app: string;
    avatar: string;
    teamId: string;
    userId: string;
    pin: string;
    mobile: string;
    deptName: string;
}

export interface LabelInfoRes {
    hostUserId?: string;
    labelId: string;
    teamId?: string;
    labelName: string;
    emplCount?: number;
    labelEmplPojoList: labelEmplPojoList[];
    status?: string;
    checked?: boolean;
}

export interface updateLabelInfo {
    id: string;
    labelName: string;
    labelEmpls: labelEmplPojoList[];
}

export interface NewLabelModel {
    labelEmplPojoList: labelEmplPojoList[];
    labelName: string;
    labelId?: string;
}

export interface LabelForContactLabelsInfo {
    labelName: string; // 需要创建的标签
    labelId?: string;
}

export interface LabelForContactInfo {
    userId: string; // 联系人id
    labels: LabelForContactLabelsInfo[];
}

export enum currentStatus {
    edit = 'edit',
    creat = 'creat',
    check = 'check',
}

export enum tagType {
    hidden = 'hidden',
    close = 'close',
    open = 'open',
}

export interface labelEmplPojoList {
    userName: string;
    userId: string;
}

export interface DelLabelInfo {
    labelId?: string;
}

// /**
//  * 映射搜索联系人结果
//  * @param searchContactResult 联系人搜索结果
//  */
// export function mapSearchContact(searchContactResult: any): ListItemData[] {
//     return searchContactResult.map(
//         (item: any): ListItemData => {
//             return {
//                 id: `${item.userId}:${item.app}:${item.teamId}`,
//                 name: item.realName,
//                 app: item.app,
//                 avatar: item.avatar || '',
//                 teamId: item.teamId,
//                 userId: item.userId,
//                 pin: item.userId,
//                 mobile: item.mobile,
//                 teamName: item.teamName,
//                 desc: item.teamName,
//             };
//         }
//     );
// }

// 联系人关系
export enum ContactShipType {
    COLLEAGUES = 'colleagues', // 同事
    SELF = 'self', // 自已
    FRIEND = 'friend', // 好友关系 双向
    BLACKLIST = 'blacklist', // 黑名单
    STRANGER = 'stranger', // 陌生人
    FOLLOW = 'follow', // 被关注 B好友列表有A，A好友列表没有B
    ONE_WAY = 'one_way', // 单向好友 A好友列表有B   B好友列表没有A
}

// 添加联系人状态
export enum AddContactsState {
    INIT = 'init', // 初始状态
    DONE = 'done', // 已添加
    Applied = 'applied', // 已申请
}

export function getContactShipType({
    relation,
    friendship,
}: {
    relation: number;
    friendship: number;
}) {
    if (relation === 1) {
        return ContactShipType.COLLEAGUES; // 同事
    }
    if (relation === 2) {
        switch (friendship) {
            case 0:
                return ContactShipType.STRANGER; // 没有好友关系
            case 1:
                return ContactShipType.FRIEND; // 好友关系
            case 2:
                return ContactShipType.BLACKLIST; // 黑名单
            case 4:
                return ContactShipType.FOLLOW; // 被关注
            case 8:
                return ContactShipType.ONE_WAY; // 单向好友关系， 单相思
            default:
                return ContactShipType.STRANGER; // 没有好友关系
        }
    }
}

export function getAddContactsState({ contactShipType }: ListItemData) {
    if (contactShipType === ContactShipType.FRIEND || contactShipType === ContactShipType.ONE_WAY) {
        return AddContactsState.DONE;
    }
    return AddContactsState.INIT;
}

export function getUserCardActions({ contactsState }: ListItemData) {
    // 已添加好友以后 可以直接发起会话
    if (contactsState === AddContactsState.DONE) {
        return [UserCardAction.Chat];
    } else {
        // 未添加好友时， 显示添加联系人
        return [UserCardAction.Add];
    }
}

// // 人员选择器返回数据
// export function mapDetailListData(labelEmplPojoList: any) {
//     const imService = ImService.getInstance();
//     const currentEmployee = imService.getCurrentEmployee();
//     const detailList = labelEmplPojoList.map((item: any) => {
//         return {
//             ...item,
//             desc: getDeptPositionDesc(
//                 item?.e_dept_full_name,
//                 item?.e_position,
//                 item?.userId,
//                 currentEmployee
//             ),
//             labelInfoList: item?.labelInfoList || [],
//         };
//     });
//     return detailList;
// }

export async function mapLabelsData(res: any, index: number | 0) {
    if (!res || res.length === 0) {
        return [];
    }
    const imService = ImService.getInstance();
    const currentEmployee = await imService.getCurrentEmployee();
    const usersUniqBy: userDetailListPrams[] = uniqBy(
        flattenDeep(
            res[index]?.labelEmplPojoList?.map((ele: any) => {
                return {
                    userId: ele.userId,
                    appId: currentEmployee?.app,
                    teamId: currentEmployee?.teamId,
                    isMulti: true,
                };
            }) || []
        ),
        'userId'
    );

    const userDetaiList = await getEbookSimpleDetailList(usersUniqBy);
    if (!userDetaiList || !userDetaiList.length) {
        return res;
    }
    res.forEach(async (item: any, key: any) => {
        if (item.labelEmplPojoList.length > 0) {
            item.labelEmplPojoList = item.labelEmplPojoList.map((item: any) => {
                return {
                    ...item,
                    ...userDetaiList.filter(
                        // eslint-disable-next-line max-nested-callbacks
                        (ele: any) => ele.userId === item.userId
                    )[0],
                };
            });
        }
    });
    return res;
}

export function mapEbookSimpleDetailList(dataList: any) {
    return (dataList || []).map((item: any) => {
        let fields: any = {};
        (item.fields || []).forEach((fieldItem: any) => {
            fields[fieldItem.key] = fieldItem.val;
        });
        return {
            id: `${item.userId}:${item.appId}:${item.teamId}`,
            appId: item.appId,
            app: item.appId,
            avatar: changeEgovUrl(fields['avatar']) || '',
            name: fields['name'] || '',
            userId: item.userId,
            nickName: fields['name'] || '',
            realName: item?.realName || '',
            teamId: item.teamId,
            desc: getDeptPositionDesc(
                fields['e_dept_full_name'] ||
                    fields['deptName'] ||
                    item.partTimeTitleDeptInfo?.[0].deptName,
                fields['e_position']
                //     fields['titleName'] ||
                // item.partTimeTitleDeptInfo?.[0].titleName
            ),
            labelInfoList: item?.labelInfoList || [],
            pin: item.userId,
            sessionId: item.sessionId || '',
            sessionType: 1,
            settings: {},
            origin: {
                info: { app: item.appId, userId: item.userId, teamId: item.teamId },
                uid: { pin: item.userId, app: item.appId, teamId: item.teamId },
            },
        };
    });
}

/**
 * 合并与联系有用户关系数据
 * @param contacts
 * @param ships
 */
export function mergeContactShip(contacts: ListItemData[], ships: any) {
    for (let ship of ships) {
        const id = `${ship?.pin}:${ship?.app}:${ship?.teamId}`;
        const index = contacts.findIndex((item) => item.id === id);
        if (index > -1) {
            contacts[index].relation = ship.relationship.relation;
            contacts[index].friendship = ship.relationship.friendship;
            contacts[index].contactShipType = getContactShipType({ ...ship.relationship });
            contacts[index].contactsState = getAddContactsState(contacts[index]);
        }
    }
    return contacts;
}

/**
 * 映射搜索联系人结果, 同时 去掉和自已相同 teamId app的用户
 * @param searchContactResult 联系人搜索结果
 * @param userInfo
 */
export function mapSearchContact(
    searchContactResult: any,
    userInfo: { teamId: string; app: string; userId: string }
): ListItemData[] {
    return searchContactResult.reduce((result: ListItemData[], item: any): ListItemData[] => {
        result.push({
            id: `${item.userId}:${item.app}:${item.teamId}`,
            name: item.realName,
            app: item.app,
            avatar: item.avatar || '',
            teamId: item.teamId,
            userId: item.userId,
            pin: item.userId,
            mobile: item.mobile,
            teamName: item.teamName,
            desc: item.teamName,
            labelInfoList: item?.labelInfoList || [],
        });
        return result;
    }, []);
}

export function mapSearchGroup(searchGroupResult: any): ListItemData {
    return {
        id: searchGroupResult.groupId,
        name: searchGroupResult.groupName,
        app: searchGroupResult.app,
        avatar: searchGroupResult.avatar || '',
        kind: searchGroupResult.kind,
        subKind: searchGroupResult.subKind,
        business: searchGroupResult.business,
        owner: searchGroupResult.owner || {},
    };
}

export function mapNewContacts(newContacts: Apply[]): ListItemData[] {
    console.log(newContacts, 'newContacts');
    return newContacts.map(
        (item): ListItemData => {
            const applyInfoArr = (item.items || []).slice(0, 3);
            return {
                id: item.id,
                name: item?.name || '',
                app: item.app,
                avatar: item.headImage || '',
                teamId: item.teamId,
                userId: item.userId || item.pin,
                pin: item.userId || item.pin,
                desc: item.info || last(item.items)?.info || '',
                // labelInfoList: item?.labelInfoList || [],
                applyInfos: item?.items?.length > 0 ? (item.items || []).slice(0, 3) : [item.info],
                status: item.status,
                labelId: item.labelId,
                applyTime: item.applyTime,
                timestamp: item.timestamp,
            };
        }
    );
}

export async function mapLabelContacts(externalContacts: ListItemData[]) {
    const imService = ImService.getInstance();
    const currentEmployee = await imService.getCurrentEmployee();
    return externalContacts.reduce((result: ListItemData[], item: ListItemData): ListItemData[] => {
        // 去掉同租户，同团队
        const index = result.findIndex((listItem) => {
            return `${item.id}` === `${listItem.userId}:${listItem.app}:${listItem.teamId}`;
        });
        if (index > -1) {
            return result;
        }
        result.push({
            id: item.id,
            name: item.name,
            app: item.app,
            avatar: item.avatar || '',
            teamId: item.teamId,
            userId: item.userId,
            pin: item.userId,
            // desc: item.employee.station,
            desc: getDeptPositionDesc(
                item?.e_dept_full_name || item?.deptName,
                item?.e_position || item?.titleName,
                item?.userId,
                currentEmployee
            ),
            labelInfoList: item?.labelInfoList || [],
        });
        return result;
    }, []);
}

export async function mapExternalContacts(
    externalContacts: Friend[],
    userInfo: { teamId: string; app: string }
) {
    const imService = ImService.getInstance();
    const currentEmployee = await imService.getCurrentEmployee();
    return externalContacts.reduce((result: ListItemData[], item: Friend): ListItemData[] => {
        // 去掉同租户，同团队
        const index = result.findIndex((listItem) => {
            return `${item.id}` === `${listItem.userId}:${listItem.app}:${listItem.teamId}`;
        });
        if (index > -1) {
            return result;
        }
        result.push({
            id: item.id,
            name: item?.employee?.name || '',
            app: item.app || item?.employee?.app,
            avatar: item.employee.avatar || '',
            teamId: item.teamId || item?.employee?.teamId,
            userId: item.userId || item?.employee?.userId,
            pin: item.userId || item?.employee?.userId,
            // desc: item.employee.station,
            desc: getDeptPositionDesc(
                item?.employee?.e_dept_full_name || item?.employee?.deptName || '',
                item?.employee?.e_position || item?.employee?.titleName || '',
                item?.employee?.userId,
                currentEmployee
            ),
            labelInfoList: item.employee?.labelInfoList || [],
        });
        return result;
    }, []);
}

export function getTreeList(
    deptInfo: EBookDeptInfoRes,
    isVirtualOrg: boolean,
    selectedEbookId: string
) {
    let ebookUsers;
    if (!isVirtualOrg) {
        ebookUsers = mapEbookUsers(deptInfo?.ebookInfoResList || [], undefined, selectedEbookId);
    } else {
        const rootOrgId = deptInfo?.simpleDeptInfoList[0]?.deptId;
        ebookUsers = mapEbookUsers(deptInfo?.ebookInfoResList || [], rootOrgId);
    }
    let ebookDeptInfoList = mapEbookSubDeptInfoList(deptInfo?.ebookSubDeptInfoList || []);
    let ebookList = ebookUsers.concat(ebookDeptInfoList).map((item, index) => {
        return {
            ...item,
            key: item.isLeaf ? v4() : item.id,
        };
    });

    return ebookList || [];
}

export function firstUpdateTreeList(simpleDeptInfo: DeptCrumbLisInfo[] | any) {
    return simpleDeptInfo?.map((item: any) => {
        return (
            {
                ...item,
                id: item?.unitDeptId || item?.deptId,
                key: item?.unitDeptId || item?.deptId,
                name: item?.unitName || item?.deptName,
                title: item?.unitName || item?.deptName,
                isLeaf: false,
                desc: '',
                avatar: '',
            } || []
        );
    });
}

// eslint-disable-next-line max-params
export function mapUserDetailTarget(
    list: any[],
    currentUserData: any,
    groupList?: any[],
    fullName?: string
) {
    return (
        // eslint-disable-next-line complexity
        list?.map((item: any, index: number) => {
            const name = item?.name || item?.realName || item?.memberName || '';
            const fullDeptName = item.fullDeptName || fullName || '';
            const memberType = item.memberType ? item.memberType : item?.terminalId ? 2 : 1;
            const titleName = item.titleName || item.positionName || '';
            return {
                ...item,
                userId: item?.userId || item?.terminalId || item?.memberId,
                appId: currentUserData?.app || currentUserData?.appId || '',
                teamId: currentUserData?.teamId || '',
                fields: item?.fields
                    ? item?.fields
                    : [
                          {
                              key: 'name',
                              val: titleName ? titleName + '-' + name : name,
                              hide: 0,
                              index: 2,
                              name: '姓名',
                          },
                          {
                              key: 'avatar',
                              val: item?.avatar || '',
                              hide: 0,
                              index: 0,
                              name: '头像',
                          },
                      ],
                name: name,
                partTimeTitleDeptInfo:
                    item?.partTimeTitleDeptInfo?.length > 0
                        ? item?.partTimeTitleDeptInfo
                        : [
                              {
                                  fullDeptName: fullDeptName,
                                  //   titleName:
                                  //       (item.titleName || item.positionName) === '暂未填写'
                                  //           ? '-'
                                  //           : titleName,
                              },
                          ],
                memberType: memberType,
                groupList: item.groupList || groupList?.[index]?.groupList || [],
                labelInfoList: [],
            };
        }) || []
    );
}

export function mapEbookUsers(
    users: EBookDeptUserInfo[],
    rootOrgId?: string,
    deptId?: string
): ListItemData[] {
    return users.map(
        (user): ListItemData => {
            if (user.avatar) {
                user.avatar = getHeadImgThumbUrl(user.avatar, 150, 150);
            }
            return {
                id: `${user.userId}:${user.appId}:${user.teamId}`,
                app: user.appId,
                userId: user.userId,
                teamId: user.teamId,
                name: user.realName,
                title: user.realName,
                avatar: user.avatar || '',
                desc: user.positionName,
                rootOrgId,
                deptId,
                isLeaf: true,
                isLeader: user.isLeader,
                administratorType: user.administratorType,
                key: v4(),
                labelInfoList: user.labelInfoList,
                appId: user.appId,
            };
        }
    );
}

export function openUserCardModal(
    contact: ListItemData,
    actions?: UserCardAction[],
    deptId?: string
) {
    const { userId, teamId, app: appId, rootOrgId } = contact;
    Bus.emit('app:toggle-user-card-modal:show', {
        visible: true,
        type: UserCardType.User,
        userId,
        teamId,
        appId,
        rootOrgId,
        applyInfos: (contact.applyInfos || []).map((item: any) => item.info).slice(0, 3),
        actions,
        deptId,
    });
}

export function openGroupCardModal(group: ListItemData, actions?: UserCardAction[]) {
    Bus.emit('app:toggle-user-card-modal:show', {
        visible: true,
        appId: group.app,
        type: UserCardType.Group,
        group: group,
        applyInfos: (group.applyInfos || []).map((item: any) => item.info).slice(0, 3),
        actions,
    });
}

export interface letterItemsMap {
    [key: string]: ListItemData[];
}

// eslint-disable-next-line @typescript-eslint/no-require-imports
let pinyin = require('js-pinyin');
const letters = '*abcdefghjklmnopqrstwxyz#'.split('');

export function groupListItem(list: ListItemData[]): letterItemsMap {
    if ((list || []).length === 0) {
        return {};
    }

    const letterItemsMap: letterItemsMap = letters.reduce((result: letterItemsMap, letter) => {
        result[letter] = [];
        return result;
    }, {});

    // for (let item of list) {
    //     const pinName = pinyin.getCamelChars(item.name).toLowerCase();
    //     let letter = pinName.charAt(0);
    //     if (!letters.includes(letter)) {
    //         letter = '#';
    //     }
    //     if (letterItemsMap.hasOwnProperty(letter)) {
    //         letterItemsMap[letter].push(item);
    //     } else {
    //         letterItemsMap[letter] = [item];
    //     }
    // }
    const newList = list.map((item: any) => {
        return {
            ...item,
            pinName: pinyin.getFullChars(item.name).toLowerCase(),
        };
    });

    newList.sort((a, b) => a.pinName.localeCompare(b.pinName, 'zh')); // a~z 排序

    for (let item of newList) {
        let letter = item.pinName.charAt(0);
        if (!letters.includes(letter)) {
            letter = '#';
        }
        if (letterItemsMap.hasOwnProperty(letter)) {
            letterItemsMap[letter].push(item);
        } else {
            letterItemsMap[letter] = [item];
        }
    }

    // 去掉值为空数组的项
    return omitBy(letterItemsMap, isEmpty);
}

export function LetterHeader({ title }: { title: string }) {
    return (
        <div className="addressListItemGroupHeader" id={`letter_${title}`}>
            {title.toUpperCase()}
        </div>
    );
}

export function HeaderPreviewer({ letters }: { letters: string[] }) {
    const letterClick = (letter: string) => {
        const ele = document.getElementById(`letter_${letter}`);
        if (ele) {
            ele.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    };
    return (
        <div className="addressListHeaderPreviewer">
            <div>
                {letters.map((letter) => {
                    return (
                        <div
                            key={letter}
                            // onMouseDown={letterClick.bind(null, letter)}
                            onClick={letterClick.bind(null, letter)}
                        >
                            {letter.toUpperCase()}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export async function getExternalContacts(userInfo: {
    teamId: string;
    userId: string;
    app: string;
}): Promise<ListItemData[] | null> {
    try {
        const imService = ImService.getInstance();
        const externalContacts = await imService.getExternalContacts();
        if (!externalContacts) {
            return null;
        }
        return mapExternalContacts(externalContacts, userInfo);
    } catch (e) {
        return null;
    }
}

export async function getNewContacts(): Promise<ListItemData[] | null> {
    try {
        const imService = ImService.getInstance();
        const newContacts = await imService.getNewContacts();
        if (!newContacts) {
            return null;
        }
        if (!newContacts.length) {
            return [];
        }
        return mapNewContacts(newContacts);
    } catch (e) {
        return null;
    }
}

export function mapGetEbookDeptInfoList(memberIds: string[]) {
    try {
        const result = gatewayInstance.request({
            api: 'screen.queryGroupListBatch',
            method: 'POST',
            // @ts-ignore
            body: { memberIds: memberIds },
        });
        return result;
    } catch (error) {
        return null;
    }
}
export async function appliesNewContacts(): Promise<ListItemData[] | null> {
    try {
        const imService = ImService.getInstance();
        const newContacts = await imService.getAppliesNewContacts();
        if (!newContacts) {
            return null;
        }
        if (!newContacts.length) {
            return [];
        }
        return newContacts;
    } catch (e) {
        return null;
    }
}

export async function getEbookDeptInfoList(payload: {
    orgId?: string;
    appId?: string;
    deptId?: string;
    displaySubDept?: boolean;
    orgTree?: string;
}): Promise<any> {
    let res = null;
    if (payload.orgId) {
        try {
            res = await getEbookOrgInfo(payload);
        } catch (e) {}
    } else {
        const imService = ImService.getInstance();
        res = await imService.getDeptInfo(payload);
    }
    return res || null;
}

export function mapEbookGroups(groups: EGroupGroupInfo[]): ListItemData[] {
    return (groups || []).map((group: any) => {
        if (group.avatar) {
            group.avatar = getHeadImgThumbUrl(group.avatar, 150, 150);
        }
        const { gid, name, avatar, rosterSize } = group;
        return {
            id: gid,
            name,
            avatar,
            desc: `${rosterSize} ${i18n.t('addressList:people')}`,
            origin: group,
        };
    });
}

export function mapMyProjects(projects: Project[]): ListItemData[] {
    return projects.map((project: Project) => {
        const { projectId, name, icon } = project;
        return {
            id: projectId.toString(),
            name,
            avatar: icon,
            desc: '',
        };
    });
}

// ebookType '1' 我创建的    '2' 我加入的
export async function getEbookGroups(ebookType: string): Promise<ListItemData[] | null> {
    try {
        const data = await ImService.getInstance().getGroupList(ebookType);
        return mapEbookGroups(data);
        // const labelsRes = await getLabels();
        // const { labels } = labelsRes;
        // if (!labels.length) {
        //     return [];
        // }
        // const label = labels[0];
        // const groupsRes = await getGroupsByLabel({ labelId: '1', ebookType: ebookType });
        // const { groups } = groupsRes;
        // if ((groups || []).length === 0) {
        //     return [];
        // }
        // const groupIds = groups.map((item: any) => {
        //     return { groupId: item.gid };
        // });
        // const groupInfoRes = await getGroupInfo({ groupIds });
        // if (!groupInfoRes.groups || groupInfoRes.groups.length === 0) {
        //     return [];
        // }
        // return mapEbookGroups(groupInfoRes.groups);
    } catch (e) {
        return null;
    }
}

export async function getLabelsInfoList(): Promise<LabelInfoRes[] | null> {
    try {
        const labelsRes = await getLabelsList();
        if (!labelsRes || labelsRes.length === 0) {
            return [];
        }

        return mapLabelsData(labelsRes, 0);
    } catch (e) {
        return [];
    }
}

export function mapEbookSubDeptInfoList(ebookSubDeptInfoList: EBookSubDeptInfo[]): ListItemData[] {
    return ebookSubDeptInfoList.map((item) => {
        const { deptId, deptName, contactNumber } = item;
        // const desc = isNull(contactNumber)
        //     ? ''
        //     : `${contactNumber} ${i18n.t('addressList:people')}`;
        return {
            id: deptId,
            name: deptName,
            desc: '',
            avatar: IconDefualtOrg,
            isLeaf: false,
            title: deptName,
            key: deptId,
        };
    });
}

export async function acceptFriend(user: UID, status: RosterApplyStatus, accessPhone?: string) {
    const imService = ImService.getInstance();
    return imService.confirmFriendApply(user, status, accessPhone);
}

export function getOrgActive(addressNavKey: AddressNavKey | string, deptId?: string) {
    return addressNavKey === `${AddressNavKey.ORG}-${deptId}`;
}

// 人员标签属性展示 （未认证、外部、单位领导、管理员等）
export function tagsNode(labelInfoList: labelInfoList[]) {
    return labelInfoList?.map((label: labelInfoList, key: number) => {
        return label.isShow === '1' ? (
            <span
                key={key}
                style={{
                    color: `#${label.color}`,
                    marginRight: '5px',
                    fontSize: 10,
                    padding: '0 4px',
                    border: `.5px solid #${label.color}`,
                    borderRadius: '2px',
                }}
            >
                {label.name}
            </span>
        ) : null;
    });
}
