import { useTranslation } from '@/components/Joyspace/context';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Folder, Page, TabPanelSearchProps } from '../types';
import '../index.less';
import DataList from '../DataList';
import { request as joyspaceRequest } from '@/components/Joyspace/api/request';
import { PAGE_SIZE, transformOriginToPage } from '../utils';
import GlobalContext from '@/context/GlobalContext';
export default function TabPanelSearch(props: TabPanelSearchProps) {
    const { search } = props;
    const t = useTranslation();
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listItems, setListItems] = useState<Array<Page | Folder>>([]);
    const {
        appRuntimeEnv: { joyspaceApiHost },
    } = useContext(GlobalContext);
    const loadFunc = useCallback(
        (search: string, params: Record<string, string | number>) => {
            setLoading(true);
            if (search) {
                return joyspaceRequest({
                    api: 'joyspace.search.global',
                    method: 'POST',
                    path: '/v1/search/global',
                    body: {
                        search: search,
                        mode: 1,
                        t: 'page',
                        ...params,
                    },
                }).then((info) => {
                    const rs = { total: 0, pages: [] };
                    if (info?.data) {
                        rs.total = info.total || 0;
                        rs.pages = (info.data || []).map((page: any) =>
                            transformOriginToPage(page, `${joyspaceApiHost}-api`)
                        );
                    }
                    return rs;
                });
            }
            return Promise.resolve({ total: 0, pages: [] });
        },
        [joyspaceApiHost]
    );

    const loadMoreFunc = useCallback(() => {
        loadFunc(props.search, {
            start: listItems.filter((item) => item.type === 'page').length,
            length: PAGE_SIZE,
        }).then((info) => {
            const pages: Page[] = info.pages || [];
            const newItems = listItems.slice();
            for (const page of pages) {
                const index = listItems.findIndex(
                    (item) => item.type === page.type && item.id === page.id
                );
                if (index < 0) {
                    newItems.push(page);
                }
            }
            console.log(newItems, 'newItemsnewItemsnewItems');
            setListItems(newItems);
            setLoading(false);
            setHasMore(info.pages.length === PAGE_SIZE);
        });
    }, [props, listItems, loadFunc]);

    useEffect(() => {
        loadFunc(search, { length: PAGE_SIZE }).then((info) => {
            if (info) {
                setListItems(info.pages || []);
                setLoading(false);
                setHasMore(info.pages.length === PAGE_SIZE);
            }
        });
    }, [search, loadFunc]);

    return (
        <DataList
            items={listItems}
            searchValue={props.search}
            onBack={() => {
                setLoading(true);
                setHasMore(false);
                setListItems([]);
            }}
            hasMore={hasMore}
            loading={loading}
            loadMore={loadMoreFunc}
        />
    );
}
