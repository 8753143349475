import { duccConfigI18 } from '@/utils/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export enum OriginType {
    JoySpace = 'JoySpace',
    Work = 'Work',
    IM = 'IM',
    Schedule = 'Schedule',
    Supervise = 'Supervise',
}

interface IProps {
    origin: OriginType; // 来源
    title?: string; // 标题
    desc?: string; // 辅助说明文案
    children?: any; // 自定义内容 和 title/desc互斥
    imageSrc: string; // 文档图标src 或 其它本地图片
    footerChildren?: any; // 底部操作区域
    handleDetail?: Function; // 热区点击事件
}

const MessageCard = (props: IProps) => {
    const { origin, title, desc, children, imageSrc, footerChildren, handleDetail } = props;
    const [t] = useTranslation('chat');
    return (
        <div className="message-card">
            {/* 来源 */}
            <div className="message-card-header">
                {t('messageCard.' + origin, {
                    joyspace: duccConfigI18.joyspace,
                })}
            </div>
            {/* 内容 */}
            <div
                className={`message-card-main ${handleDetail ? 'message-card-main-cursor' : ''}`}
                onClick={(e: any) => handleDetail?.(e)}
            >
                <div className="message-card-main-left">
                    {children}
                    {title && <p className="message-card-main-left_title">{title}</p>}
                    {desc && <p className="message-card-main-left_desc">{desc}</p>}
                </div>
                <div className="message-card-main-right">
                    <img src={imageSrc} className="img" />
                </div>
            </div>
            {/* 底部 */}
            {footerChildren() && <div className="message-card-footer">{footerChildren()}</div>}
        </div>
    );
};

export default MessageCard;
