/* eslint-disable array-callback-return */
/**
 * 消息盒子组件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { MessageDateFormType } from '@/types/chat/enum';
import './index.less';
import { ChatMessage, MessageStatus, Session, UID } from '@/types/chat';
import ChatMessageItem from '@/components/chat/message/Item/ChatItem';
import { getMessageDateFormat, getTime } from '@/utils/date';
import { MessageEmitCategory } from '@jd/jdee.im.sdk/lib/es/enum/MessageEmitType';
import SysMessageItem from '@/components/chat/message/Item/SysItem';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import SysMessage from '@/components/chat/message/Sys';
import { useTranslation } from 'react-i18next';
import Revoke from '@/components/chat/message/Revoke';
import Context from '@/components/chat/context';
import bus from '@/utils/bus';
import RefreshMessageBox from '../../handler/countDownHandler';
import ImService from '@/server/ImService';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
interface MessageBoxProps {
    messages: ChatMessage[];
    session?: Session;
    sText?: string;
    sMid?: number;
    getLock: Function;
    unLock: Function;
    getCurrentMessageId: Function;
    setCurrentMessageId: Function;
}
interface DvaDispatchProps {
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
}

let visbol: boolean;
type IProps = Readonly<MessageBoxProps & DvaDispatchProps & ChatState>;
function MessageBox(props: IProps) {
    // const { messages, session, sText = '', sMid = -1 } = props;
    const {
        messages,
        session,
        updateSessionMessageMid,
        updateSessionMessagesTimestamp,
        selectedSession,
        sText = '',
        sMid = -1,
    } = props;
    // console.log('message box', messages, session);
    const { groupRosterIdentity } = React.useContext(Context);
    const { t } = useTranslation('chat');
    /**
     * strat 阅后即焚
     * TODO: 只有当前会话是单聊密聊会话，才去初始化倒计时实例和可见区域监听实例
     */
    const imService = ImService.getInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refreshMessageBox = session?.isSecret ? new RefreshMessageBox() : null;
    // const scrollEle = document.getElementById('message-box-container-scroll');
    // let io: any = null;
    // if (scrollEle) {
    //     io = session?.isSecret
    //         ? new IntersectionObserver(getMessages, {
    //               root: scrollEle,
    //               threshold: [0.5],
    //           })
    //         : null;
    // }

    // function getMessages(entries: any[]) {
    //     console.log('离线消息', entries);
    //     entries.forEach(async (entry) => {
    //         if (entry.isIntersecting) {
    //             // 当前元素可见 设置消息读取时间
    //             const message = JSON.parse(entry.target.dataset.message);
    //             console.log('message', message);
    //             if (!message.readTimeOfMessage) {
    //                 // console.log('entry', message);
    //                 // setTimeout(async () => {
    //                 const readTimeOfMessage = new Date().getTime();
    //                 const result = await imService.updateReadTime(message.sessionId, message.mid, {
    //                     ...message,
    //                     readTimeOfMessage,
    //                 });
    //                 if (result) {
    //                     await updateSessionMessageMid({
    //                         sessionId: message.sessionId,
    //                         sendResult: {
    //                             body: { ...message, readTimeOfMessage },
    //                         },
    //                     });
    //                     await updateSessionMessagesTimestamp({
    //                         timestamp: new Date().getTime(),
    //                     });
    //                 }
    //                 io?.unobserve(entry.target); // 停止观察当前元素 避免不可见时候再次调用callback函数
    //                 // }, 1000);
    //             }
    //         }
    //     });
    // }

    useEffect(() => {
        refreshMessageBox?.startTimer();
        return () => {
            refreshMessageBox?.clearTimer();
            refreshMessageBox?.destroy();
        };
    }, [refreshMessageBox]);

    /**
     * end 阅后即焚
     */

    const getSendTime = useCallback(
        (message) => {
            const sendTime = getMessageDateFormat(
                message.timestamp,
                MessageDateFormType.DATE_AGGREGATION
            );
            return <div className="time">{sendTime}</div>;
        },
        [t] // eslint-disable-line
    );

    useEffect(() => {
        bus.off(`message:Box`);
        bus.on(`message:Box`, (data) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            visbol = data;
        });
    }, []);
    if (messages.length > 0) {
        return (
            <div
                className="message-node"
                style={{ marginTop: messages[0].type === 'unified_notice_message' ? '0px' : '8px' }}
            >
                {messages.map((message: any, index) => {
                    if (message.category === MessageEmitCategory.SYSTEM) {
                        if (
                            message.type === ChatMessageType.SYS &&
                            message.flag !== 100 &&
                            !message.data?._sys_msg_type_
                        ) {
                            return <SysMessage key={`s-2-${message.id}`} message={message} />;
                        } else {
                            return <SysMessageItem key={`s-${message.id}`} message={message} />;
                        }
                    } else if (message.category === MessageEmitCategory.CHAT_MESSAGE) {
                        if (message.revoke === 1 && !message.plusList) {
                            return (
                                <Revoke
                                    key={`r-${message.id}`}
                                    message={message}
                                    sessionType={session?.sessionType}
                                />
                            );
                        } else {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                    }}
                                    className={
                                        visbol === true &&
                                        !message.mid &&
                                        message.type === ChatMessageType.TEXT
                                            ? 'mergeUp'
                                            : ''
                                    }
                                >
                                    <ChatMessageItem
                                        key={`c-${message.id}-${message.timestamp}`}
                                        groupRosterIdentity={groupRosterIdentity}
                                        sendTime={getSendTime(messages[0])}
                                        prevMessage={index > 0 ? messages[index - 1] : null}
                                        message={message}
                                        getLock={props.getLock}
                                        unLock={props.unLock}
                                        getCurrentMessageId={props.getCurrentMessageId}
                                        setCurrentMessageId={props.setCurrentMessageId}
                                        sText={sText}
                                        sMid={sMid}
                                        refreshMessageBox={refreshMessageBox}
                                        updateSessionMessageMid={props.updateSessionMessageMid}
                                        updateSessionMessagesTimestamp={
                                            props.updateSessionMessagesTimestamp
                                        }
                                    />
                                </div>
                            );
                        }
                    } else {
                        return null;
                    }
                })}
            </div>
        );
    }
    return null;
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;

    return { selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
