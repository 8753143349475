import React from 'react';
import { message as Message } from 'antd';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { handleLinkClick } from '@/components/chat/message/AINoticeCard/utils';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { applyApprove, applyReject } from '../api/pageApply';

enum BtnsType {
    APPROVE = 'approve',
    REJECT = 'reject',
    DETAIL = 'detail',
}
import { changeEgovUrl } from '@/utils/tools';
import { replaceGovUrl } from '@/utils/joyspace';
import { processJoyspaceUrl } from '../winOpen';
import { duccConfigI18 } from '@/utils/i18n';

const JoyspaceNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {};
    }
)(({ message }: { message: any }) => {
    const { title, extend, infox, id } = message;
    // console.log(message, 'messagemessagemessage');
    const [t] = useTranslation('chat');
    // console.log('joyspace notice card', extend);
    const handleClick = async (btn: any) => {
        // console.log(btn, 'btnbtnbtnbtnbtn');
        let reslut: any = null;
        // 同意
        if (btn.action === BtnsType.APPROVE) {
            reslut = await applyApprove(
                btn.params.applyCode,
                // btn.params.applyUserId,
                // btn.params.permissionType,
                // btn.params.applyReason,
                id
            );
            // 拒绝
        } else if (btn.action === BtnsType.REJECT) {
            reslut = await applyReject(
                btn.params.applyCode,
                // btn.params.applyUserId,
                // btn.params.permissionType,
                // btn.params.applyReason,
                id
            );
            // 查看
        } else if (btn.action === BtnsType.DETAIL) {
            handleLinkClick(btn.params.deepLink);
            return;
        }
        if (!reslut) {
            Message.error('操作失败');
        }
    };
    const buttons: any[] = extend.btns;
    return (
        <ComNoticeCard
            avatarSrc={changeEgovUrl(message.sender.avatar)}
            type={duccConfigI18.joyspace}
            contentList={extend.fields}
            title={title}
            handleDetail={() => handleLinkClick(extend.url || '')}
            loadButtonsFn={
                buttons
                    ? () => {
                          return (
                              <div className="button_items">
                                  {buttons.map((btn: any = {}, idx) => (
                                      <button
                                          className={btn.status === '0' ? 'disable' : ''}
                                          disabled={btn.status === '0' ? true : false}
                                          key={idx}
                                          onClick={() => handleClick(btn)}
                                      >
                                          {btn.name}
                                      </button>
                                  ))}
                              </div>
                          );
                      }
                    : () => (
                          <div
                              className="button_item"
                              onClick={() => handleLinkClick(extend.url || '')}
                          >
                              {t('click-to-view')}
                          </div>
                      )
            }
            // loadButtonsFn={() => (
            //     <div className="button_item" onClick={() => handleLinkClick(extend.url || '')}>
            //         {t('click-to-view')}
            //     </div>
            // )}
        />
    );
});

export default JoyspaceNoticeCard;
