/**
 * 人员卡片分享
 */
import React, { useCallback } from 'react';
import { JoySpaceDocumentMessage, SessionType } from '@/types/chat';
import { HeadShowType, MessageSendType } from '@/types/chat/enum';
import lodashGet from 'lodash/get';
import './index.less';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import { getUserCardActions, openUserCardModal } from '@/components/AddressBook/common';
import Context from '@/components/chat/context';
import { UserCardAction } from '@/types/UserCard';

interface ShareUserCardProps {
    message: JoySpaceDocumentMessage;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

function ShareUserCard(props: ShareUserCardProps) {
    const { message, sessionType } = props;

    const { currentEmployee, userData } = React.useContext(Context);

    const cardInfo = lodashGet(message, 'data.0.sharecard', {});

    const openUserCard = useCallback(() => {
        const data = {
            userId: cardInfo?.pin,
            teamId: cardInfo?.teamId,
            app: cardInfo?.app,
        } as any;

        openUserCardModal(data);
    }, [cardInfo?.app, cardInfo?.pin, cardInfo?.teamId]);

    if (!cardInfo.nickName) {
        return null;
    }

    return (
        <div className="content">
            <div className="s-user-card" onClick={openUserCard}>
                <div className="s-user-card-h-n">
                    <HeadPortrait
                        width="40px"
                        height="40px"
                        imgUrl={cardInfo.avatar}
                        name={cardInfo.nickName}
                        type={sessionType}
                        isUnFollow={true}
                        showType={HeadShowType.SHARE_USER_CARD}
                        onItemClick={() => {
                            return null;
                        }}
                    />
                    <div className="name">{cardInfo.nickName}</div>
                </div>
                <div className="s-user-card-l" />
                <div className="s-user-card-s">个人名片</div>
            </div>
        </div>
    );
}

export default ShareUserCard;
