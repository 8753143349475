import color from './colors';

const multiLineOverflow = (lines = 3): React.CSSProperties => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
});

// const color = {
//   gray: '#8F959E',
//   lightGray: '#F8F9FA',
//   primary: '#31C4CC',
//   second: '#EAF9F9',
//   black: '#242931',
//   splitLine: '#DEE0E3', // #DEE0E3 F0F3F3
//   blue: '#4C7CFF',
//   line: {
//     light: '#E0E1E3'
//   },
//   white: '#fff'
// };

const draggable = (drag?: boolean) => ({
    webkitAppRegion: drag ? 'drag' : 'no-drag',
});

const layout = (
    position: 'center' | 'centerLeft' | 'centerRight' | 'centerBetween',
    direction: 'v' | 'h'
): React.CSSProperties => {
    const data: React.CSSProperties = {
        display: 'flex',
    };

    switch (position) {
        case 'center':
            data.alignItems = 'center';
            data.justifyContent = 'center';
            break;
        case 'centerLeft':
            data.alignItems = 'center';
            data.justifyContent = 'flex-start';
            break;
        case 'centerRight':
            data.alignItems = 'center';
            data.justifyContent = 'flex-end';
            break;
        case 'centerBetween':
            data.alignItems = 'center';
            data.justifyContent = 'space-between';
            break;
        default:
    }

    switch (direction) {
        case 'v':
            data.flexDirection = 'column';
            break;
        case 'h':
        default:
            data.flexDirection = 'row';
    }
    return data;
};

const border = {
    bottom: {
        borderBottom: `1px solid ${color.gray}`,
    },
};

export default {
    multiLineOverflow,
    color,
    draggable,
    borderLine: `1px solid ${color.splitLine}`,
    layout,
    border,
};
