import React, { useRef, useState } from 'react';
import DefualtAvatarImg from '../assets/img/default_avatar.png';
import IconFont from '@/components/icon';
import '@/assets/less/common.less';
import { changeEgovUrl } from '@/utils/tools';
import { combGroupSetField } from './chat/message/Item/common';
export default function Avatar({
    style,
    src,
    alt,
}: {
    style?: React.CSSProperties;
    src?: string;
    alt?: string;
}) {
    const [source, setSource] = useState(DefualtAvatarImg);
    const refs: any = useRef();

    return (
        <img
            style={{
                width: 36,
                height: 36,
                borderRadius: 6,
                backgroundColor: '#ddd',
                overflow: 'hidden',
                ...style,
            }}
            ref={refs}
            onError={(err) => {
                try {
                    const img = refs?.current;
                    img.src = DefualtAvatarImg;
                } catch (err) {
                    console.log(err, 'errr==========>');
                }
            }}
            onLoad={() => {
                setSource(changeEgovUrl(src) || DefualtAvatarImg);
            }}
            src={changeEgovUrl(source)}
            alt={alt}
            draggable="false"
        />
    );
}

export function AvatarIcon({
    size,
    styles,
    className,
    clickHandle,
}: {
    size?: number;
    styles?: React.CSSProperties;
    className?: string;
    clickHandle?: Function;
}) {
    return (
        <div
            style={{
                width: size || 36,
                height: size || 36,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 18,
                borderRadius: '50%',
                ...styles,
            }}
            className={`primary-bg ${className}`}
            onClick={(e) => {
                clickHandle?.(e);
            }}
        >
            <IconFont
                style={{ color: '#fff' }}
                className="primary-color"
                type="iconapp_icon_mine"
            />
        </div>
    );
}
