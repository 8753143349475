import React from 'react';
import { SearchGlobalContext } from '@/components/Modals/SearchGlobalModal/utils';
import ListEmptyPlaceholder from '@jd/focus-desktop-comps/lib/es/ListEmptyPlaceholder';
import DataList from '@/components/Modals/SearchGlobalModal/DataList';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { ListItemClickFn } from '@/components/Modals/SearchGlobalModal/Tabs';
import lodashTrim from 'lodash/trim';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { analysisLog } from '@/utils/logAnalytics';
import { duccConfigI18 } from '@/utils/i18n';

export default function TabPaneJoySpace({ onClick }: { onClick?: ListItemClickFn }) {
    const { keyword, t } = React.useContext(SearchGlobalContext);
    if (!lodashTrim(keyword)) {
        return (
            <ListEmptyPlaceholder
                placeholder={t('search.emptyPlJoySpace', {
                    joyspace: duccConfigI18.joyspace,
                })}
            />
        );
    }
    return (
        <DataList
            searchType={SearchType.JoySpace}
            onClick={(data: ListItemData) => {
                analysisLog('xtbg_home_search_1641454991771', '29');
                if (onClick) {
                    onClick(SearchType.JoySpace, data);
                }
            }}
        />
    );
}
