// 设置展开栏底部按钮

import React, { useCallback, useMemo } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { Employee, GroupAdminSetAction, GroupAttribute, Session } from '@/types/chat';
import { message } from 'antd';
import ImService from '@/server/ImService';
import { ListItemData } from '@/components/ListItem';
import { connect } from 'dva';
import { UserSelectionResult } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import { openUserSelectionModal } from '@/utils/modals';
import { removeChatSession } from '@/server/im/SessionService';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import {
    convertEmployeeToString,
    employeeToUid,
    isEqualEmployee,
    isEqualUid,
    isGroupVisibleUnit,
} from '@/components/chat/utils/message';
import {
    checkCanDissolutionGroup,
    checkCanExitGroup,
    isGroupOwner,
} from '@/components/chat/utils/group';
import { useHistory } from 'react-router-dom';
import { TransferGroupAdminSource } from '@/types/chat/enum';
import lodashGet from 'lodash/get';
import isFunction from 'lodash/isFunction';
import UID from '@jd/jdee.im.sdk/lib/es/types/UID';
import log from '@/utils/logger';
import { parseUidFromString } from '@jd/focus-desktop-comps/lib/es/utils';
import IconFont from '@/components/icon';
import Context from '@/components/chat/context';
import ChatState from '@/types/chat/State';
import { closeChatWindow } from '@/utils/chat/index';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import _, { differenceWith, intersectionWith } from 'lodash';
import bus from '@/utils/bus';
import { isBusinessGroup } from '../utils/session';
import { getDeptPositionDesc } from '@/models/user';

const logger = log.getLogger('comp:GroupOperation');

interface GroupOperationProps {
    selectedSession: Session;
    history: any;
}

interface dvaProps {
    updateSelectedSession: Function;
    updateSessionData: Function;
}
type IProps = Readonly<GroupOperationProps & dvaProps>;

// 获取群成员数据
export function getFixedUsers(currentEmployee: Employee, groupRosters: any, isShowUnit: boolean) {
    if (!currentEmployee) {
        return [];
    }
    return groupRosters.reduce((result: any, session: any) => {
        const employee = session.info as Employee;
        if (isEqualEmployee(employee, currentEmployee)) {
            return result;
        }
        const name = isShowUnit ? employee.unitName || '' : employee.deptName || '';
        const user: ListItemData = {
            id: convertEmployeeToString(employee),
            ...employeeToUid(employee),
            name: employee.name,
            avatar: employee.avatar,
            desc: getDeptPositionDesc(name, employee.titleName || ''),
        };
        result.push(user);
        return result;
    }, [] as ListItemData[]);
}

/**
 * 转让群主操作 这里会包括会话所有转让群主的功能
 * 退出转让（1，3，5）EXIT_GROUP_OPERATE
 * 群成员操作转让（2，4）GROUP_MEMBER_OPERATE
 * 群管理转让 （3，4）GROUP_MANAGER_OPERATE
 * 1, 打开群主退出群时转让确认弹框
 * 2, 打开成员管理转让操作的确认弹框
 * 3, 打开人员选择器
 * 4，确认操作 （群成员转让的确认） （群管理转让的确认）
 * 5，确认操作 并 退群
 */
export function useTransferGroupOwner({
    selectedSession,
    currentEmployee,
    updateSelectedSession,
    groupRosters,
    groupRoster,
    userData,
    operateType,
    callback,
}: {
    operateType: TransferGroupAdminSource;
    selectedSession: Session;
    currentEmployee?: Employee;
    updateSelectedSession?: Function;
    groupRosters?: any;
    groupRoster?: any;
    userData?: any;
    callback?: Function;
}) {
    const { t } = useTranslation('chat');
    const history = useHistory();
    const next = (step: number, ...args: any[]) => {
        switch (step) {
            case 1:
                logger.debug('useTransferGroupAdmin ==> step1 => exitConfirmModal');
                exitConfirmModal();
                break;
            case 2:
                logger.debug('useTransferGroupAdmin ==> step2 => memberConfirmModal');
                memberConfirmModal();
                break;
            case 3:
                logger.debug('useTransferGroupAdmin ==> step3 => openSelectionModal');
                openSelectionModal();
                break;
            case 4:
                logger.debug('useTransferGroupAdmin ==> step4 => transferGroupOwner ');
                transferGroupOwner(args[0]).then();
                break;
            case 5:
                logger.debug('useTransferGroupAdmin ==> step5 => transferGroupAndExit');
                transferGroupAndExit(args[0]).then();
        }
    };

    // step: 1, 打开群主退出群时转让确认弹框
    const exitConfirmModal = () => {
        Prompt({
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            title: t('exit-group-reminder'),
            content: t('leader-exit-group-prompt'),
            cancelText: t('cancelText'),
            onOk: next.bind(null, 3),
            okText: t('determine'),
        });
    };

    // step: 2 打开成员管理转让操作的确认弹框
    const memberConfirmModal = () => {
        if (!groupRoster) {
            return;
        }
        Prompt({
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            title: t('transfer-group-owner'),
            content: t('transfer-group-owner-reminder', {
                memberName: groupRoster.info.name,
            }),
            cancelText: t('cancelText'),
            onOk: next.bind(null, 4),
            okText: t('determine'),
        });
    };

    // step: 3 打开人员选择器
    const openSelectionModal = () => {
        if (!currentEmployee || !userData) {
            return null;
        }
        const isShowUnit = !isGroupVisibleUnit(selectedSession);
        openUserSelectionModal(
            {
                title: t('transfer-group-owner'),
                frozenIds: [],
                fixedUsers: getFixedUsers(currentEmployee, groupRosters, isShowUnit),
                single: true,
                currentUser: employeeToUid(currentEmployee),
                mode: SelectorMode.transfer,
            },
            (data, close) => {
                if (operateType === TransferGroupAdminSource.EXIT_GROUP_OPERATE) {
                    // 会有退出群组的操作
                    next(5, data);
                } else {
                    next(4, data);
                }
                close();
            },
            userData
        );
    };

    // 获取新的群主
    const getNewGroupOwner = (data?: any): UID | undefined => {
        if (!groupRosters || groupRosters.length === 0) {
            message.error(t('operation-error-please-operate-again'));
            return;
        }
        let rosterIndex = -1;
        if (groupRoster) {
            // 群成员操作是会带有groupRoster
            rosterIndex = groupRosters.findIndex((item: any) => {
                if (!item.info) {
                    return false;
                } else {
                    return isEqualUid(employeeToUid(item.info), employeeToUid(groupRoster.info));
                }
            });
        } else if (data) {
            // 打开人员选择器时会带有 data
            rosterIndex = groupRosters.findIndex((item: any) => {
                const selectId = lodashGet(data, 'data.result[0].id', '');
                const uid = parseUidFromString(selectId);
                return isEqualUid(uid, employeeToUid(item.info));
            });
        }
        if (rosterIndex < 0) {
            message.error(t('operation-error-please-operate-again'));
            return;
        }
        const { info: rosterInfo } = groupRosters[rosterIndex] as any;
        const { app, userId, teamId } = rosterInfo;
        if (!app || !userId || !teamId) {
            message.error(t('operation-error-please-operate-again'));
            return;
        }
        return employeeToUid(rosterInfo);
    };
    // step: 4 群成员转让操作的确认
    const transferGroupOwner = async (data?: UserSelectionResult) => {
        if (!selectedSession) {
            return;
        }
        const newOwner = getNewGroupOwner(data);
        if (!newOwner) {
            return;
        }
        const instance = ImService.getInstance();
        const result = await instance.changeGroupOwner(selectedSession.sessionId, newOwner);
        if (result) {
            callback?.();
            // console.log('转让群组', selectedSession.gid || selectedSession.sessionId);
            await instance.getDbGroup({ gid: selectedSession.gid || selectedSession.sessionId });
            message.success(t('successful-transfer'));
        } else {
            message.error(t('transfer-failure'));
        }
    };

    // stop: 5 退群时转让操作的确认
    const transferGroupAndExit = async (data: UserSelectionResult) => {
        if (!selectedSession) {
            return;
        }
        const newOwner = getNewGroupOwner(data);
        if (!newOwner) {
            return;
        }
        const instance = ImService.getInstance();
        const result = await instance.changeGroupOwnerAndExit(selectedSession.sessionId, newOwner);
        if (result) {
            closeChatWindow();
            if (isFunction(updateSelectedSession)) {
                updateSelectedSession({ selectedSession: {} });
            }
            history.push('/messages');
            message.success(t('success'));
        } else {
            message.info(t('failed'));
        }
    };

    return () => {
        switch (operateType) {
            // 退出群 转让群主操作
            case TransferGroupAdminSource.EXIT_GROUP_OPERATE:
                next(1); // 打开群主退出群时转让确认弹框
                break;
            // 群成员 转让群主操作
            case TransferGroupAdminSource.GROUP_MEMBER_OPERATE:
                next(2); // 打开成员管理转让操作的确认弹框
                break;
            // 群管理转让群组操作
            case TransferGroupAdminSource.GROUP_MANAGER_OPERATE:
                next(3); // 打开联系人选择器
                break;
            default:
                return () => {};
        }
    };
}

// 方案1 组管理员设置
export function useSetGroupAdmin({
    selectedSession,
    currentEmployee,
    groupRosters,
    userData,
    callback,
    loadSessionMembers,
}: {
    selectedSession: Session;
    currentEmployee?: Employee;
    groupRosters?: any;
    userData?: any;
    callback?: Function;
    loadSessionMembers: Function;
}) {
    const { t } = useTranslation('chat');
    const isShowUnit = !isGroupVisibleUnit(selectedSession);
    const openSelectionModal = () => {
        if (!currentEmployee || !userData) {
            return null;
        }
        const resultUsers = getCurrentAdminUsers();
        openUserSelectionModal(
            {
                title: t('set-group-admin'),
                resultUsers: resultUsers,
                fixedUsers: getFixedUsers(currentEmployee, groupRosters, isShowUnit),
                currentUser: employeeToUid(currentEmployee),
                mode: SelectorMode.group,
            },
            async (data, close) => {
                const list = lodashGet(data, 'data.result', []);
                const intersectionList = intersectionWith(resultUsers, list, _.isEqual);
                // console.log('admin', resultUsers, list, intersectionList);
                const removeList = differenceWith(resultUsers, intersectionList, _.isEqual);
                const addList = differenceWith(list, intersectionList, _.isEqual);
                // console.log('remove and add', removeList, addList);
                // 提示次数处理逻辑
                let requestList = [];
                if (removeList.length) {
                    requestList.push(setAdmin(removeList, 'DELETE_ADMIN'));
                }
                if (addList.length) {
                    requestList.push(setAdmin(addList, 'ADD_ADMIN'));
                }
                if (requestList.length) {
                    Promise.all(requestList).then((result) => {
                        result.forEach((item: any) => {
                            if (item?.includes('成功')) {
                                message.success(item);
                            } else {
                                message.error(item);
                            }
                        });
                    });
                }
                close();
            },
            userData
        );
    };
    // 添加管理员操作
    const setAdmin = (data: any, action: GroupAdminSetAction) => {
        return new Promise((resolve) => {
            if (!selectedSession) {
                return;
            }
            const members = data?.map((user: ListItemData) => {
                return {
                    app: user?.app,
                    pin: user?.pin,
                    teamId: user?.teamId,
                };
            });
            // console.log('管理员选择', members);
            const instance = ImService.getInstance();
            instance.setGroupAdmin(selectedSession.sessionId, members, action).then((result) => {
                if (result) {
                    callback?.();
                    loadSessionMembers({ selectedSession: selectedSession });
                    resolve(action === 'DELETE_ADMIN' ? '移除成功' : '添加成功');
                } else {
                    resolve(action === 'DELETE_ADMIN' ? '移除失败' : '添加失败');
                }
            });
        });
    };

    // 获取群管理员数据
    const getCurrentAdminUsers = () => {
        if (!currentEmployee) {
            return [];
        }
        const resultUsers = groupRosters
            ?.filter((session: any) => session.identity === 'sub_administrator')
            ?.map((item: any) => {
                const employee = item.info as Employee;
                // return convertEmployeeToString(employee);
                const name = isShowUnit ? employee.unitName || '' : employee.deptName || '';
                return {
                    id: convertEmployeeToString(employee),
                    ...employeeToUid(employee),
                    name: employee.name,
                    avatar: employee.avatar,
                    desc: getDeptPositionDesc(name, employee.titleName || ''),
                };
            });
        return resultUsers || [];
    };

    return () => {
        openSelectionModal();
    };
}

function GroupOperation(props: IProps) {
    const { selectedSession, updateSelectedSession, updateSessionData } = props;

    const { currentEmployee, groupRosters, groupRosterIdentity, userData } = React.useContext(
        Context
    );
    // console.log(groupRosters, 'groupRostersgroupRosters');

    const { t } = useTranslation('chat');
    const history = useHistory();

    // 转让群主业务逻辑
    const transferGroupAdmin = useTransferGroupOwner({
        selectedSession,
        currentEmployee,
        groupRosters,
        updateSelectedSession,
        userData,
        operateType: TransferGroupAdminSource.EXIT_GROUP_OPERATE,
    });

    const groupAdmin = useMemo(() => {
        return isGroupOwner(groupRosterIdentity);
    }, [groupRosterIdentity]);

    const canDissolutionGroup = useMemo(() => {
        return checkCanDissolutionGroup({ selectedSession, groupRosterIdentity });
    }, [groupRosterIdentity, selectedSession]);

    const canExitGroup = useMemo(() => {
        return checkCanExitGroup({ selectedSession, groupRosterIdentity });
    }, [groupRosterIdentity, selectedSession]);

    // 解散普通组
    const dissolutionNormalGroup = () => {
        Prompt({
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            title: t('dismiss-group-reminder'),
            content: t('dismiss-group-prompt'),
            cancelText: t('cancelText'),
            onOk: confirmDissolution,
            okText: t('determine'),
        });
    };

    // 触发解散群组弹框
    const dissolutionGroup = () => {
        if (isBusinessGroup(selectedSession)) {
            // 解散相关日程、任务组
            const info = selectedSession.info as any;
            if (info.businessId) {
                bus.emit('im_special_disband_group_event', {
                    businessId: info.businessId,
                    businessType: info.business,
                    callback: confirmDissolution,
                    type: 'dismiss',
                });
                return;
            }
        }
        dissolutionNormalGroup();
    };

    const confirmDissolution = useCallback(async () => {
        if (selectedSession.sessionId || selectedSession.gid) {
            const instance = ImService.getInstance();
            const result = await instance.disbandGroup(
                selectedSession.sessionId || selectedSession.gid || ''
            );
            if (result) {
                // console.log('解散群', selectedSession.sessionId || selectedSession.gid || '');
                await instance.getDbGroup({
                    gid: selectedSession.sessionId || selectedSession.gid || '',
                });
                closeChatWindow();
                const session = { ...selectedSession };
                session.info = { ...((selectedSession.info || {}) as GroupAttribute), flag: 1 };
                updateSessionData({ session });
                updateSelectedSession({ selectedSession: {} });
                history.push('/messages');
                message.success(t('success'));
            }
        }
    }, [selectedSession.gid, selectedSession.sessionId, t]); // eslint-disable-line

    // 确认退群操作
    const confirmExitGroup = useCallback(async () => {
        const instance = ImService.getInstance();
        const result = await instance.exitGroup(selectedSession.sessionId);
        if (result) {
            await instance.getDbGroup({ gid: selectedSession.gid || selectedSession.sessionId });
            // console.log('确认退群操作', selectedSession.gid || selectedSession.sessionId);
            closeChatWindow();
            updateSelectedSession({ selectedSession: {} });
            history.push('/messages');
            message.success(t('success'));
        } else {
            message.info(t('exit-failed'));
        }
    }, [selectedSession.sessionId, t]); //eslint-disable-line

    // 退出群组提示弹框
    const openExitGroupModal = useCallback(() => {
        Prompt({
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            title: t('exit-group-reminder'),
            content: t('exit-group-prompt'),
            cancelText: t('cancelText'),
            onOk: confirmExitGroup,
            okText: t('determine'),
        });
    }, [confirmExitGroup, t]);

    const exitNormalGroup = useCallback(() => {
        if (groupAdmin && groupRosters.length > 2) {
            // 是管理员 并且群成员人数大于2时，打开转让群主 弹框
            transferGroupAdmin();
        } else {
            // 打开退出群提示弹框
            openExitGroupModal();
        }
    }, [groupAdmin, groupRosters.length, transferGroupAdmin, openExitGroupModal]);

    const onSpecialGroupExit = useCallback(() => {
        if (groupAdmin && groupRosters.length > 2) {
            // 是管理员 并且群成员人数大于2时，打开转让群主 弹框
            transferGroupAdmin();
        } else {
            // 打开退出群提示弹框
            confirmExitGroup();
        }
    }, [groupAdmin, groupRosters.length, confirmExitGroup, transferGroupAdmin]);

    // 退出群组事件
    const exitGroup = useCallback(() => {
        if (isBusinessGroup(selectedSession)) {
            // 解散相关日程、任务组
            const info = selectedSession.info as any;
            if (info.businessId && info.business === 'schedule') {
                bus.emit('im_special_disband_group_event', {
                    businessId: info.businessId,
                    businessType: info.business,
                    callback: onSpecialGroupExit,
                    type: 'group_out',
                });
                return;
            }
        }
        exitNormalGroup();
    }, [onSpecialGroupExit, exitNormalGroup, selectedSession]);

    return (
        <div className="group-operation">
            {selectedSession.isGroup && (
                <div className="group-out">
                    {canDissolutionGroup && (
                        <button
                            onClick={dissolutionGroup}
                            clstag="pageclick|keycount|Xtbg_Msg_Setting|DisbandGroup"
                        >
                            {t('disband_group')}
                        </button>
                    )}
                    {canExitGroup && (
                        <button
                            onClick={exitGroup}
                            clstag="pageclick|keycount|Xtbg_Msg_Setting|QuitGroup"
                        >
                            {t('exit_group')}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSelectedSession(data: { selectedSession: Session }) {
            dispatch({ type: 'chat/updateSelectedSession', payload: data });
        },
        updateSessionData(data: { session: Session }) {
            dispatch({ type: 'chat/updateSessionData', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupOperation);
