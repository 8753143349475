import cookies from 'js-cookie';
import { ICache } from '@jd/jdee.im.sdk/lib/es/Cache';
import {
    UserSelectionOptions,
    UserSelectionResult,
} from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import cache from './cache';
import { useCallback, useEffect, useRef } from 'react';
import { UserCardAction, UserCardType } from '@/types/UserCard';
import { PromptOptions } from '@jd/focus-desktop-comps/lib/es/PromptModal';
import Emitter from '@jd/jdee.im.sdk/lib/es/core/channel/emitter/Emitter';
import { UID } from '@/types/chat';

import { EventEmitter } from 'events';
import { GatewayProps } from '@/server/types';
import AppRuntimeEnv, { AppRuntimeConfig, AppRuntimeEnvConfig } from '@/types/AppRuntimeEnv';
import { AuthData, RegistryConfigData } from '@/types/LocalConfig';
import { AuthDataRes } from '@/types/LoginPage';
import WebFocusSDK, { ApplicationInfo } from './FocusSDK';
import { changeEgovUrl } from './tools';
import pako from 'pako';
import logger from '@/utils/logger';
import ICON from '@/assets/templateImgs/logo.png';
const log = logger.getLogger('index');
import { NetType } from '@/components/Modals/ChangeNetModal';
import { UAParser } from 'ua-parser-js';
import { CheckCurrentNetOptions } from '@/components/Modals/NetTable/types';

export interface DownloadItem {
    pause: () => void;
    resume: () => void;
    cancel: () => void;
    cid: string;
}

export interface DownloadProgress {
    // 上传百分比
    percent: number;
    // 已传输字节数
    transferredBytes: number;
    // 总字节数
    totalBytes: number;
    filename?: string;
    status?: string;
}

interface TeamInfo {
    account: string;
    ddAppId: string;
    headImg: string | null;
    mobile: string;
    realName: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
}

export interface IUrlOnline {
    type: 'changeNetType' | 'zoomNet' | 'netSetting';
    isPrivateNet: boolean;
}

interface ThirdAppUserInfo {
    app: {
        appId: string;
        deviceId: string;
        client: string;
    };
    user: {
        account: string;
        ddAppId: string;
        headImg: string;
        nickName: string;
        realName: string;
        userId: string;
        teamUserInfo: TeamInfo;
        selectedTeamId: string;
    };
}

export enum JoyMeetingStatusEnum {
    SingleCall = 1, // 点对点主叫
    GroupCall = 2, // 多人主叫
    Called = 3, // 被叫
    InMeeting = 4, // 在会议中
    WaitingInMeeting = 5, // 初始化过程中 比如点击卡片加入会议
    None = -1, // 无状态
}

export interface UserInfo {
    account: string;
    ddAppId: string;
    headImg: string;
    nickName: string;
    realName: string;
    userId: string;
    team: TeamInfo;
    teamUserInfo: TeamInfo;
    selectedTeamId: string;
    teamUserInfoList: TeamInfo[];
}

export interface AuthInfo {
    accessToken: string;
    refreshToken?: string;
    user: UserInfo;
}

// 消息体 -- TODO  @孙志广 需要完善一下这里的数据结构，里面一些不必要字段去掉就行
interface Message {
    msg_id: string;
    message_type: number;

    text?: string;
    local_path?: string;
    url?: string;

    sender_name: string;
    sender_username: string;
    sender_id: string;

    receiver_name: string;
    receiver_username: string;
    receiver_id: string;

    created_at: number;
    updated_at: number;
}

interface MessageNotify {
    avatar: string;
    title: string;
    desc?: string;
}

export interface CommonChannelContent {
    event: string;
    data: {
        [key: string]: any;
    };
    callback: (data: { event?: string; data?: any }) => void;
}

export interface toMainWinMessage {
    type: string;
    data: any;
}

type UserSelectionTabType = 'recent' | 'top' | 'org' | 'externalContacts' | 'group';

export interface GatewayConfig {
    baseUrl: string;
    appId: string;
    appKey: string;
    version: number;
    stage: string;
}

export interface WebConfig {
    routeBase: string;
    ssoLoginUrl: string;
    ssoClientId: string;
}

/* eslint-disable */
export interface IFocusSDK extends EventEmitter {
    appInfo: { version: string };
    cache?: ICache;
    /**
     * ipc 消息处理器
     */
    ipcEventEmitter: Emitter;
    isNewWindow: boolean;
    webContentId: number;
    authInfo: AuthDataRes | null;
    DbService: any;
    // 连接IM服务的方式
    IM_MODE?: 'client' | 'server';
    FOCUS_MODE?: 'main' | 'support';
    getTheme(): string;
    getLogFile(filename: string): { blob: string; path: string };
    getFilePath(filepath: string, filename: string): string;
    getFile(filepath: string): string;
    getApplicationInfo(): ApplicationInfo;
    getDeviceInfo(): { platform: 'win32' | 'darwin' | 'web' };
    onIpcMessage(event: string, cb: Function): () => void;
    sendIpcMessage(event: string, ...args: any[]): void;
    // 发送消息到主窗口
    sendToMainWin(data: toMainWinMessage): void;
    addIpcEventListener(channel: string, cb: (...args: any[]) => void): () => void;
    closeCurrentWindow(options?: { hide: boolean }): void;
    getWindowFrame(frame: 'min' | 'max' | 'visible'): boolean;
    setWindowFrame(frame: 'min' | 'max' | 'unMax' | 'hide' | 'close'): void;
    ready(): void;
    checkUpdate(auto?: boolean, version?: string, forceUpdate?: number): void;
    // 向主进程发送 mainMessage 消息
    sendMainMessage(type: string, data: any, cb?: (...args: any[]) => void): void;
    // 向主进程发送消息转发到webview
    sendGlobalWebviewMessage(type: string, data: any): void;
    switchTeam(teamId: string): void;
    removeBuiltinBrowserCookies(): Promise<void>;
    setBuiltInBrowserCookies(
        cookies: { domain: string; url: string; name: string; value: string }[]
    ): Promise<void>;
    setCookies(name: string, value: string, expired?: any): Promise<void>;
    clearCookies(): void;
    reloadApp(url?: string): void;
    relaunchApp(): void;
    checkDnsIsTrue(): void;
    setConfigData(key: string, value: any, cb?: (config: RegistryConfigData) => void): void;
    getAppRuntimeEnv(
        cb: (config: { runtimeEnv: AppRuntimeConfig; localConfig: RegistryConfigData }) => void,
        force?: boolean
    ): void;
    isBeta(): boolean;
    sendToMain(type: string, data: any): void;
    // 全局搜索
    openGlobalSearch(): void;
    sendTo(webContentId: string | number, channel: string, ...args: any[]): void;
    getAppPreloadJSPath(version?: string | undefined): string;
    getInnerAppPreloadJSPath(version?: string | undefined): string;
    // 关闭窗口
    closeWindow(): void;
    // 获取用户信息
    getAuthInfo(cb: (userData: AuthData) => void): void;
    getThirdAppUserInfo(cb: (thirdAppUserData: ThirdAppUserInfo) => void): void;
    // 截屏
    captureScreen(cb: ({ data }: { data: string }) => void): void;
    // 截屏快捷键
    captureScreenShortcut(isSecert: boolean): void;
    // 获取window复制内容
    getClipboardPaths(): [];
    // 图片预览
    viewImages(files: string[]): void;
    // 打开文件
    openItem(path: string): void;
    // 打开文件对应的文件夹并选中该文件
    showItemInFolder(path: string): void;
    // 视频预览
    viewVideo(data: { source: string; poster: string }): void;
    checkLocalPathExist(localPath: string, cb: (exists: boolean) => void): void;
    // // 存储消息, todo 消息字段待完善
    // saveMessages(msgs: DBMessage | DBMessage[], cb?: (result?: any) => void): void;
    // // 搜索消息, todo 搜索条件待完善
    // searchMessages(data: any, cb?: () => void): void;
    // 打开人员卡片
    openUserCard(
        data: {
            type: UserCardType;
            userId: string;
            teamId: string;
            appId: string;
            rootOrgId?: string;
            group?: any;
            actions?: UserCardAction[];
            applyInfos?: string[];
            currentUser?: {
                userId: string;
                teamId: string;
                appId: string;
                realName: string;
                station: string;
            };
        },
        cb?: () => void
    ): void;
    clipboardImage(base64Url: string): boolean;
    getImageDataFromServer(url: string, cb: Function): void;
    startDrag(filePath: string): void;
    // 下载文件
    downloadFile(
        url: string,
        options?: {
            saveAs?: boolean;
            defaultPath?: string;
            viewFile?: boolean;
            filename?: string;
            filter?: any[];
            alreadySaveAs?: boolean;
            onStarted: (downloadItem: DownloadItem) => void;
            onProgress: (progress: {
                // 上传百分比
                percent: number;
                // 已传输字节数
                transferredBytes: number;
                // 总字节数
                totalBytes: number;
                status?: string;
            }) => void;
            onCancel: (alreadySaveAs?: boolean) => void;
            onPause?: () => void;
            onResume?: () => void;
            onFinish: (file: { path: string; filename: string }, data: any) => void;
            onError?: (error: any) => void;
        }
    ): void;
    // 任务日程导出文件
    downloadExportFile(
        url: string,
        options?: {
            saveAs?: boolean;
            filename?: string;
            extensionName?: string;
            onStarted: (downloadItem: DownloadItem) => void;
            onProgress: (progress: {
                // 上传百分比
                percent: number;
                // 已传输字节数
                transferredBytes: number;
                // 总字节数
                totalBytes: number;
            }) => void;
            onCancel: () => void;
            onPause?: () => void;
            onResume?: () => void;
            onFinish: (file: { path: string; filename: string }) => void;
        }
    ): void;
    showOpenDialog(cb: (filePath: string) => void, options?: any): void;
    locationChange(data: { path: string }): void;
    // 打开链接，只支持新窗口
    openUrl(url: string): void;
    // 打开确认弹框
    openPrompt(props: PromptOptions, cb?: () => void): void;
    // 工具加载完毕后，调用该方法，通知 sdk，之后才能调用其他方法
    userSelection(
        options: UserSelectionOptions,
        cb?: (data: UserSelectionResult, close: () => void) => void
    ): void;
    // 分享文档
    shareDocuments(
        options: {
            pin: string;
            sessionType: number;
            teamId: string;
            app: string;
        },
        cb?: (result: any) => void
    ): void;
    unreadCountChange(data: { count: number }): void;
    newContactsCountChange(data: { count: number }): void;
    login(userData: AuthDataRes, selectedTeamId: string, cb: () => void, autoLogin?: boolean): void;
    // 登出
    logout(reason?: string): void;
    // 刷新 token
    autoLogin(cb?: () => void): void;
    // 新消息通知
    messageNotify(data: MessageNotify): void;
    // 打开 deeplink
    openDeeplink(link: string): void;
    // 创建团队后进入工具
    setSelectedTeamId(team: TeamInfo): void;
    // FOCUS VIEW关闭全局弹窗
    focusViewCloseModal(): void;
    // 获取最近聊天列表信息
    // on(event: 'chat:recentList', cb: (reply: (data: ListItemData[]) => void) => void): void;

    // // 监听事件
    // on(event: string, cb: Function): void;
    // off(event: string, cb: Function): void;
    // 通用事件消息通道
    emitCommonChannelEvent(content: CommonChannelContent): void;

    // 发起群聊会话
    launchGroupSession(gid: string, cb?: Function): void;
    // 发起单聊会话
    launchSingleSession(uid: UID, cb?: Function): void;
    printLog(...arg: any[]): void;
    getVersion(): { version: string };
    getDownloadPath(): string;
    setDownloadPath(path: string): void;
    getConfigData(): string;
    isPrivateNet(): boolean;
    getCurrentNetType(): NetType;
    privateDnsIsTrue(): boolean;
    newPrivateDnsIsTrue: boolean;
    canSetPrivateDns(): boolean;
    getMeMainEntry(): string;
    getWebConfig(): WebConfig;
    appExit?(): void;
    deleteLocalFile(path: string): boolean;
    checkUrlOnline(data: IUrlOnline): boolean;
    closeOtherWin(): void;
    dbService?: any;
    checkCurrentNet(options: CheckCurrentNetOptions): void;
    getGlobalAppRuntimeEnv(): any;
}
/* eslint-enable */

let focusSDK: IFocusSDK;

console.log('typeof window.FocusSDK', typeof (window as any).FocusSDK);
// @ts-ignore
if (typeof window.FocusSDK !== 'undefined' && window.FocusSDK.isPrivateNet) {
    // @ts-ignore
    focusSDK = window.FocusSDK;
} else {
    focusSDK = new WebFocusSDK() as any;
}
console.log('focusSDK~~~', focusSDK);
console.log('😄', process.env);
// @ts-ignore
export const FocusSDK: IFocusSDK = focusSDK;

export function isFocusEnv() {
    // @ts-ignore
    return typeof window.FocusSDK !== 'undefined';
}

export function isSupportEnv() {
    return typeof FocusSDK !== 'undefined' && FocusSDK.FOCUS_MODE === 'support';
}

export function relogin() {
    // 优先使用token provider 参数
    log.info('cache.tokenProvider', cache.tokenProvider, FocusSDK);
    FocusSDK.printLog('relogin-tokenProvider', JSON.stringify(cache.tokenProvider));
    if (cache.tokenProvider) {
        cache.tokenProvider.logout();
    } else if (!isFocusEnv()) {
        // 更改为统一退出
        FocusSDK.logout();
        // location.href = getLoginURL();
    } else {
        FocusSDK.autoLogin();
    }
}

export function loginOut() {
    FocusSDK.printLog('loginOut-tokenProvider', JSON.stringify(cache.tokenProvider));
    if (cache.tokenProvider) {
        cache.tokenProvider.logout();
    } else if (!isFocusEnv()) {
        // TODO 调用后台登录接口
        const domain = process.env.REACT_APP_TOP_LEVEL_DOMAIN;
        cookies.set('focus-token', '', { domain: domain });
        cookies.set('focus-team-id', '', { domain: domain });
        location.reload();
    } else {
        FocusSDK.logout();
    }
}

export function getExtKey(key: string) {
    // let ddAppId = cache?.ddAppId;
    // if (!ddAppId) {
    //     ddAppId = cookies.get('focus-app-id');
    // }
    // if (ddAppId && ddAppId !== 'me') {
    //     return `${key}-${ddAppId.toUpperCase()}`;
    // }
    return key;
}

export function getUserAppId() {
    if (isFocusEnv()) {
        return FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork';
    } else {
        return cookies.get('focus-app-id') || 'gwork';
    }
}

/**
 * 获取网关 appId
 */
export function getAppId() {
    if (isFocusEnv()) {
        const appId = cache?.gateWayAppId;
        if (appId) {
            return appId;
        }
        return '';
    } else {
        const key = getExtKey('REACT_APP_WEB_APPID');
        return process.env[key] || process.env.REACT_APP_WEB_APPID;
    }
}

/**
 * 获取网关 SecretKey
 *
 * @export
 * @returns
 */
export function getSecretKey() {
    if (isFocusEnv()) {
        // todo 暂时和web端取值方式一样
        const key = getExtKey('REACT_APP_DESKTOP_SECRETKEY');
        return process.env[key] || process.env.REACT_APP_WEB_SECRETKEY;
    } else {
        const key = getExtKey('REACT_APP_WEB_SECRETKEY');
        return process.env[key] || process.env.REACT_APP_WEB_SECRETKEY;
    }
}

export function getWsHost(): string | undefined {
    // return `shb.jdcloud.com${process.env.REACT_APP_IM_WS_HOST}`;
    return `${process.env.REACT_APP_WSS_URL}${process.env.REACT_APP_IM_WS_HOST}`;
}

export function getWsTls(): boolean {
    return process.env.REACT_APP_IM_WS_TLS !== 'false';
}

/**
 * 获取网关host参数
 */
export function getJanusHost(): string | undefined {
    return process.env.REACT_APP_JANUS_HOST;
}

/**
 * 获取网关stage 参数
 */
export function getJanusStage(): string | undefined {
    return process.env.REACT_APP_JANUS_STAGE;
}

/**
 * 获取验证信息
 * x-token
 * x-team-id
 * x-client
 */
export async function getAuthInfo() {
    const result: any = {};
    let info: AuthData;
    let appRuntimeEnv: AppRuntimeEnv;
    await new Promise<void>((resolve) => {
        FocusSDK.getAppRuntimeEnv((data) => {
            appRuntimeEnv = new AppRuntimeEnv(data.runtimeEnv, data.localConfig);
            resolve();
        });
    });
    await new Promise<void>((resolve) => {
        FocusSDK.getAuthInfo((userData: AuthData) => {
            info = userData;
            resolve();
        });
    });
    // @ts-ignore
    if (!info) {
        return result;
    }
    // @ts-ignore
    if (!appRuntimeEnv) {
        return result;
    }
    result['x-client'] = (!isFocusEnv() && 'WEB') || appRuntimeEnv.registryConfig.client;
    result['x-token'] = info.accessToken;
    result['x-team-id'] = info.teamUserInfo?.teamId || '';
    result['x-gateway-id'] = appRuntimeEnv.gatewayAppId;
    return result;
}
export async function getAuthInfo1() {
    const result: any = {};
    if (isFocusEnv()) {
        let info: AuthData;
        let appRuntimeEnv: AppRuntimeEnv;
        await new Promise<void>((resolve) => {
            FocusSDK.getAppRuntimeEnv((data) => {
                appRuntimeEnv = new AppRuntimeEnv(data.runtimeEnv, data.localConfig);
                resolve();
            });
        });
        await new Promise<void>((resolve) => {
            FocusSDK.getAuthInfo((userData: AuthData) => {
                info = userData;
                resolve();
            });
        });
        // @ts-ignore
        if (!info) {
            return;
        }
        // @ts-ignore
        if (!appRuntimeEnv) {
            return;
        }
        result['x-client'] = (!isFocusEnv() && 'WEB') || appRuntimeEnv.registryConfig.client;
        result['x-token'] = info.accessToken;
        result['x-team-id'] = appRuntimeEnv.selectedTeamId;
        result['x-gateway-id'] = appRuntimeEnv.gatewayAppId;
    } else if (cache.tokenProvider) {
        if (!cache.token) {
            try {
                cache.token = await cache.tokenProvider.token();
            } catch (e) {}
        }
        // 查询token
        if (cache.token) {
            result['x-client'] = (!isFocusEnv() && 'WEB') || cache.token.token.focusClient;
            result['x-token'] = cache.token.token.focusToken;
            result['x-team-id'] = cache.token.token.focusTeamId;
        }
    } else {
        result['x-client'] = 'WEB';
        result['x-token'] = cookies.get('focus-token') || '';
        result['x-team-id'] = cookies.get('focus-team-id') || '';
    }
    return result;
}

/**
 * 获取用户信息
 */
export async function getUser() {
    const result: any = {};
    let info: AuthData;
    let appRuntimeEnv: AppRuntimeEnv;
    await new Promise<void>((resolve) => {
        FocusSDK.getAppRuntimeEnv((data) => {
            appRuntimeEnv = new AppRuntimeEnv(data.runtimeEnv, data.localConfig);
            resolve();
        });
    });
    await new Promise<void>((resolve) => {
        FocusSDK.getAuthInfo((userData: AuthData) => {
            console.log(userData, 'FocusSDK.getAuthInfo====>');
            info = userData;
            resolve();
        });
    });
    // @ts-ignore
    if (!info) {
        return result;
    }
    // @ts-ignore
    if (!appRuntimeEnv) {
        return result;
    }
    return info;
}

export function isLogin(isLogin: boolean) {
    // if (REACT_APP_SSO_DISABLE === 'true') {
    //     return true;
    // }
    return isLogin;
}

export function logout() {
    FocusSDK.printLog('src-utils-logout');
    if (isFocusEnv()) {
        FocusSDK.logout();
    } else {
        loginOut();
    }
}

export function getLoginURL() {
    // eslint
    // debugger;
    const {
        routeBase: REACT_APP_ROUTE_BASE,
        ssoLoginUrl: REACT_APP_SSO_LOGIN_URL,
        ssoClientId: REACT_APP_SSO_CLIENTID,
    } = FocusSDK.getWebConfig();
    const url = `${location.origin}${REACT_APP_SSO_LOGIN_URL}?ReturnUrl=${encodeURIComponent(
        location.origin + REACT_APP_ROUTE_BASE
    )}&clientId=${REACT_APP_SSO_CLIENTID}`;
    console.log('_____url', url);
    return url;
}

export function getUserInfo(userData: any): any {
    let userInfo = {} as any;
    userInfo.userId = userData.userId || '';
    userInfo.teamId = userData.team.teamId || '';
    userInfo.app = userData.team.ddAppId || '';
    if (isFocusEnv() && userData) {
        userInfo.accessToken = userData.accessToken || '';
        userInfo.appId = userData.app?.appId || '';
        userInfo.client = (userData.app?.client || '').toLowerCase();
        userInfo.deviceId = userData.app?.deviceId || '';
    }
    return userInfo;
}

export function getClientType(): string {
    if (!isFocusEnv()) {
        return 'Web';
    }
    const isWin = navigator.platform === 'Win32' || navigator.platform === 'Windows';
    const isMac =
        navigator.platform === 'Mac68K' ||
        navigator.platform === 'MacPPC' ||
        navigator.platform === 'Macintosh' ||
        navigator.platform === 'MacIntel';
    if (isMac) return 'Mac';
    if (isWin) return 'Windows';
    return 'other';
}

/**
 * 检测当前系统
 */
export function detectOS() {
    // const userAgent = navigator.userAgent;
    const isWin = navigator.platform === 'Win32' || navigator.platform === 'Windows';
    const isMac =
        navigator.platform === 'Mac68K' ||
        navigator.platform === 'MacPPC' ||
        navigator.platform === 'Macintosh' ||
        navigator.platform === 'MacIntel';
    if (isMac) return 'Mac';
    if (isWin) return 'Win';
    return 'other';
}

/**
 * 检测浏览器
 */
export function detectBrowser() {
    if (navigator.userAgent.indexOf('Firefox') > -1) {
        return 'Firefox';
    }

    if (navigator.userAgent.indexOf('Chrome') > -1) {
        return 'Chrome';
    }
    if (navigator.userAgent.indexOf('MEIS') > -1 && navigator.userAgent.indexOf('Trident') > -1) {
        return 'IE';
    }
    return 'Other';
}

export const storageLocal = {
    add: function (key: string, value: any) {
        try {
            localStorage.setItem(key, JSON.stringify({ data: value }));
        } catch (e) {}
    },
    get: function (key: string) {
        let data = localStorage.getItem(key);
        if (!data) {
            return null;
        }
        let dataObj = JSON.parse(data);
        let _data = dataObj.data;
        return _data;
    },
    remove: function (key: string) {
        localStorage.removeItem(key);
    },
    // clear: function () {
    //     localStorage.clear();
    // },
};

export function guid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string): string => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function useDebounce(fn: Function, delay: number, dep = []) {
    const { current } = useRef<any>({ fn, timer: null });
    useEffect(
        function () {
            current.fn = fn;
        },
        [current, fn]
    );

    return useCallback(
        function f(...args) {
            if (current.timer) {
                clearTimeout(current.timer);
            }
            current.timer = setTimeout(() => {
                current.fn(...args);
            }, delay);
        },
        [current, delay]
    );
}

export function openGroupSession({ gid }: { gid: string }) {
    if (isFocusEnv()) {
        return FocusSDK.launchGroupSession(gid);
    } else {
        const { origin } = new URL(window.location.href);
        // window.open(`${origin}/chat/g/${gid}`);
        window.open(`${origin}?page=chat&gid=${gid}&type=g`);
    }
}

export function openSingleSession(uid: UID) {
    if (isFocusEnv()) {
        return FocusSDK.launchSingleSession(uid);
    } else {
        const { origin } = new URL(window.location.href);
        window.open(`${origin}?page=chat&type=n&gid=${uid?.pin}:${uid?.app}:${uid?.teamId}`);
    }
}

export function getAppLogoConfig(runtimeConfig: AppRuntimeConfig) {
    // const key = runtimeConfig.key;
    // let REACT_APP_OSS_BASE_URL = FocusSDK.getApplicationInfo()?.ossBaseUrl;
    // const url = changeEgovUrl(`${REACT_APP_OSS_BASE_URL}/statics/icons/${key}/icon.png`);
    const url = ICON;
    return {
        logoImg: url,
        appName: runtimeConfig.config.productName,
    };
}

export function isBjme() {
    // return true;
    return FocusSDK.getApplicationInfo().isSpecialProjects;
}

export function getAppSecretKey(name: string): string {
    if (!isFocusEnv()) {
        return process.env.REACT_APP_WEB_SECRETKEY || '';
    }
    return process.env.REACT_APP_DESKTOP_SECRETKEY || '';
}

export function unzipData(b64Data: string): string {
    let strData = atob(b64Data);
    const charData = strData.split('').map(function (x) {
        return x.charCodeAt(0);
    });
    const binData = new Uint8Array(charData);
    const res = pako.inflate(binData, { to: 'string' });
    return res;
}

export function isIE() {
    return new UAParser().getBrowser().name === 'IE';
}

export function getSafariVer() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/safari/)) {
        const ver = ua.match(/version\/([\d.]+)/) || [];
        return ver[1] ? parseInt(ver[1], 10) : 0;
    } else {
        return 0;
    }
}

export function getBroName() {
    let broName = 'Runing';
    let strStart = 0;
    let strStop = 0;
    let temp = '';
    let userAgent = window.navigator.userAgent; // 包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
    // FireFox
    if (userAgent.indexOf('Firefox') !== -1) {
        strStart = userAgent.indexOf('Firefox');
        temp = userAgent.substring(strStart);
        broName = temp.replace('/', ' 版本号');
    }
    // Edge
    if (userAgent.indexOf('Edge') !== -1) {
        /* broName = 'Edge浏览器'; */
        strStart = userAgent.indexOf('Edge');
        temp = userAgent.substring(strStart);
        broName = temp.replace('/', ' 版本号');
    }
    // IE浏览器
    if (userAgent.match(/msie ([\d.]+)/)) {
        /* broName = 'IE浏览器'; */
        let s: any = userAgent.match(/msie ([\d.]+)/);
        let res = 'IE ' + s[1];
        broName = res + res.replace(/[^0-9.]/gi, '');
    }
    // 360极速模式可以区分360安全浏览器和360极速浏览器
    if (
        userAgent.indexOf('WOW') !== -1 &&
        userAgent.indexOf('NET') < 0 &&
        userAgent.indexOf('Firefox') < 0
    ) {
        if (navigator.javaEnabled()) {
            broName = '360安全浏览器-极速模式';
        } else {
            broName = '360极速浏览器-极速模式';
        }
    }
    // 360兼容
    if (
        userAgent.indexOf('WOW') !== -1 &&
        userAgent.indexOf('NET') !== -1 &&
        userAgent.indexOf('MSIE') !== -1 &&
        userAgent.indexOf('rv') < 0
    ) {
        broName = '360兼容模式';
    }
    // Chrome浏览器
    if (userAgent.match(/Chrome\/([\d.]+)/)) {
        /* broName = 'Chrome浏览器'; */
        strStart = userAgent.indexOf('Chrome');
        strStop = userAgent.indexOf('Safari');
        temp = userAgent.substring(strStart, strStop);
        broName = temp.replace('/', ' 版本号');
    }
    // safari
    if (userAgent.match(/Version\/([\d.]+).*Safari/)) {
        strStop = userAgent.indexOf('Safari');
        temp = userAgent.substring(strStop);
        broName = temp.replace('/', ' 版本号');
    }
    // opera
    if (userAgent.match(/Opera.([\d.]+)/)) {
        strStop = userAgent.indexOf('Opera');
        temp = userAgent.substring(strStop);
        broName = temp.replace('/', ' 版本号');
    }
    return broName;
}

export function currentIsProd() {
    if (!isFocusEnv()) {
        return process.env.REACT_APP_NODE_ENV === 'production';
    }
    const currentConfig = FocusSDK?.getConfigData() || '';
    const configObj = JSON.parse(currentConfig) || {};
    const currentEnv = configObj?.runtimeConfig?.env;
    console.log('currentEnv', currentEnv);
    return currentEnv === 'prod';
}

export default {};
