import React from 'react';
import { removeSchedule, getScheduleInfo, ScheduleDeleteType, RepeatMode } from '@/api/calendar';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import IconFont from '@/components/icon';
import { Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';

function CalendarDisbandGroupPrompt({
    onChange,
    isRepeat,
    isShowCalendarInfo,
}: {
    onChange: (e: any) => void;
    isRepeat: boolean;
    isShowCalendarInfo: boolean;
}) {
    const { t } = useTranslation('chat');
    return (
        <span>
            <span>{t('dismiss-group-prompt')}</span>
            <span style={{ display: 'block' }}>
                {isShowCalendarInfo ? (
                    <Checkbox style={{ margin: '5px 5px 0 0' }} onChange={onChange} />
                ) : (
                    ''
                )}
                {isShowCalendarInfo ? (isRepeat ? '重复日程删除所有日程' : '同时删除日程') : ''}
            </span>
        </span>
    );
}

export default function calendarDisbandGroupPrompt(
    businessId: string,
    disbandGroup: Function,
    type?: string
) {
    let checkState = false;
    let isRepeat = false;

    if (type === 'group_out') {
        promptGroupOut();
    } else {
        getScheduleInfo({ scheduleId: businessId })
            .then((res: any) => {
                if (!res) {
                    message.warning(i18n.t('calendar:schedule is not found'));
                    return;
                }
                isRepeat = res.repeat !== RepeatMode.No;
                promptDisbandGroup(true);
            })
            .catch((err) => {
                message.error(err.message?.toString() || i18n.t('calendar:schedule is not found'));
                promptDisbandGroup(false);
            });
    }

    function onChange(e: any) {
        checkState = e.target.checked;
    }

    async function deleteCalendar() {
        try {
            const result = await removeSchedule({
                scheduleId: businessId,
                beginTime: Date.now(),
                endTime: Date.now(),
                optType: isRepeat ? ScheduleDeleteType.RepeatAll : ScheduleDeleteType.All,
            });
        } catch (err) {}
    }

    function promptDisbandGroup(isShowCalendarInfo: boolean) {
        Prompt({
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            title: '解散组提醒',
            content: (
                <CalendarDisbandGroupPrompt
                    onChange={onChange}
                    isRepeat={isRepeat}
                    isShowCalendarInfo={isShowCalendarInfo}
                />
            ),
            cancelText: '取消',
            onOk: () => {
                if (checkState) {
                    deleteCalendar();
                }
                disbandGroup();
            },
            okText: '确定',
        });
    }

    function promptGroupOut() {
        getScheduleInfo({ scheduleId: businessId })
            .then((res: any) => {
                if (!res) {
                    message.warning(i18n.t('calendar:schedule is not found'));
                    return;
                }
                isRepeat = res.repeat !== RepeatMode.No;
                if (isRepeat) {
                    Prompt({
                        icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                        title: '退出组提醒',
                        content: '退出该组后，将不在接收该组的消息，确定要退出吗？',
                        cancelText: '取消',
                        onOk: () => {
                            disbandGroup();
                        },
                        okText: '确定',
                    });
                } else {
                    Prompt({
                        icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                        title: '退出组提醒',
                        content: '退出该组时，您将会同时退出该日程，请确认是否退出。',
                        cancelText: '取消',
                        onOk: () => {
                            disbandGroup();
                        },
                        okText: '确定',
                    });
                }
            })
            .catch((err) => {
                // message.warning('群组退出异常');
                // message.error(err.message?.toString() || i18n.t('calendar:schedule is not found'));
                Prompt({
                    icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                    title: '退出组提醒',
                    content: '退出该组后，将不在接收该组的消息，确定要退出吗？',
                    cancelText: '取消',
                    onOk: () => {
                        disbandGroup();
                    },
                    okText: '确定',
                });
            });
    }
}
