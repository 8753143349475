import { IPageAddCallback, api } from './index';
import { message, Modal } from 'antd';
import { lang } from '../context';
import events from '../context/events';
const { confirm } = Modal;

const remove: IPageAddCallback = (props, isAllow) => {
    const { data, remove } = props;
    return [
        {
            children: lang('common.list-remove'),
            callbackOrModal: () => {
                confirm({
                    title: lang('remove-recent-title'),
                    content: lang('remove-recent-content'),
                    okText: lang('common.confirm'),
                    cancelText: lang('common.cancel'),
                    onOk() {
                        api.page.recent.remove(data.id).then((data) => {
                            if (data) {
                                message.success(lang('common.remove-success'));
                            } else {
                                message.error(lang('common.fail'));
                            }
                            events.emit('list.reload');
                            remove();
                        });
                    },
                });
            },
            isAllow,
            type: 'delete',
            icon: 'pc_yundoc_yichu',
            splitCode: 3,
        },
    ];
};

export default remove;
