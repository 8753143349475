// 群接龙历史
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Drawer, Tabs, Card, Popover, Empty, Button, message as toast } from 'antd';
import './solitaireHistory.less';
import { Avatar } from '@/baseComponents/Avatar';
import noticeImg from '@/assets/img/no-content.png';
import { changeEgovUrl } from '@/utils/tools';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import { EsuperviseSourceType, ICreateSuperviseData } from '@/types/supervise';
import { debounce } from 'lodash';
import {
    getSolitaire,
    pageCreateSolitaireList,
    pageNotReceiveSolitaireList,
    pageReceiveSolitaireList,
} from '@/api/Solitaire';
import { Jlmate, newDate } from '@/utils/date';
import SolitaireDetails from '@/components/chat/message/SolitaireCard/SolitaireDetails';
import ScrollView from '@/baseComponents/ScrollView';
import bus from '@/utils/bus';

const { TabPane } = Tabs;
interface SlitaireHisProps {
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    openSolitaireDetails: (data: { isvisibles: boolean; solitaire: any }) => void;
    openSolitaireHis: (data: { isvisibles: boolean; solitaireSid: any }) => void;
}
type IProps = Readonly<SlitaireHisProps & ChatState>;
let getSolitaires: any;
let solitaireList: any;
let count = 1;
function SlitaireHis(props: IProps) {
    const {
        currentEmployee,
        openCreateSuperviseModal,
        openSolitaireDetails,
        SolitaireHisVisible,
        solitaireSessionId,
        openSolitaireHis,
    } = props;
    const [isvisible, setIsvisible] = useState(false);
    const [show, setShow] = useState(0);
    const [Tab, setTab] = useState('participate');
    const [solitaireSoli, setsolitaireSoli] = useState(false);
    const [historyList, sethistoryList] = useState<any>([]);
    const [ReceiveSolitaire, setReceiveSolitaire] = useState<any>([]);
    const [pageCreate, setpageCreateSolitaire] = useState<any>([]);
    const [btnstyle, setbtnstyle] = useState({ display: 'block' });
    const [scrollEnd, setScrollEnd] = useState(false);
    const scrollRef = useRef(null);
    const dates = new Date().getTime();

    useEffect(() => {
        bus.off('work:Solitaire:solitaireHistory').on(
            'work:Solitaire:solitaireHistory',
            async (data) => {
                if (data) {
                    if (Tab === 'participate') {
                        sethistoryList(
                            historyList.filter((item: any) => {
                                return item.solitaireId !== data;
                            })
                        );
                    }
                    if (Tab === 'participated') {
                        setReceiveSolitaire(
                            ReceiveSolitaire.filter((item: any) => {
                                return item.solitaireId !== data;
                            })
                        );
                    }

                    if (Tab === 'initiated') {
                        const [data, err] = await pageCreateSolitaireList({
                            businessType: 1,
                            businessId: solitaireSessionId,
                            page: 1,
                            size: 15,
                        });
                        if (data) {
                            setpageCreateSolitaire(data.solitaireList);
                        }
                        if (err) {
                            console.log(err);
                        }
                    }
                }
            }
        );
    });

    const onClose = () => {
        openSolitaireHis({
            isvisibles: false,
            solitaireSid: '',
        });
        setsolitaireSoli(false);
        setScrollEnd(true);
        setTab('participate');
        sethistoryList([]);
        setReceiveSolitaire([]);
        setpageCreateSolitaire([]);
    };

    async function visibleChange(visible: boolean, key: any, solitaireId: any) {
        if (visible) {
            const [result, err] = await getSolitaire({
                appId: currentEmployee.app,
                teamId: currentEmployee.teamId,
                userId: currentEmployee.userId,
                solitaireId: solitaireId,
                fromPage: 1,
            });
            if (result) {
                getSolitaires = result.solitaire;
            }
            setIsvisible(visible);
            setShow(key);
            tabList();
            return;
        } else {
            setIsvisible(visible);
            setShow(0);
        }
    }

    // 接龙历史 待参与
    const openSlitaireHis = useCallback(async () => {
        const [data, err] = await pageNotReceiveSolitaireList({
            businessType: 1,
            businessId: solitaireSessionId,
            page: count,
            size: 15,
        });
        if (err) {
            console.log(err);
            return;
        }
        if (data.solitaireList.length === 0) {
            setScrollEnd(true);
            return;
        }
        if (data) {
            solitaireList = solitaireList
                ? solitaireList.concat(data.solitaireList)
                : data.solitaireList;
            sethistoryList(solitaireList);
        }
    }, [solitaireSessionId]);

    // 接龙历史 已参与
    const pageReceiveSolitaire = useCallback(async () => {
        const [data, err] = await pageReceiveSolitaireList({
            businessType: 1,
            businessId: solitaireSessionId,
            page: count,
            size: 15,
        });
        if (err) {
            console.log(err);
            return;
        }
        if (data.solitaireList.length === 0) {
            setScrollEnd(true);
            return;
        }
        if (data) {
            solitaireList = solitaireList
                ? solitaireList.concat(data.solitaireList)
                : data.solitaireList;
            setReceiveSolitaire(solitaireList);
        }
    }, [solitaireSessionId]);

    // 接龙历史 我发起的
    const pageCreateSolitaire = useCallback(async () => {
        const [data, err] = await pageCreateSolitaireList({
            businessType: 1,
            businessId: solitaireSessionId,
            page: count,
            size: 15,
        });
        if (err) {
            console.log(err);
            return;
        }
        if (data.solitaireList.length === 0) {
            setScrollEnd(true);
            return;
        }
        if (data) {
            solitaireList = solitaireList
                ? solitaireList.concat(data.solitaireList)
                : data.solitaireList;
            setpageCreateSolitaire(solitaireList);
        }
    }, [solitaireSessionId]);

    const onTabClick = useCallback(
        async (key: string) => {
            setTab(key);
            if (key === 'participate') {
                count = 1;
                solitaireList = [];
                setScrollEnd(false);
                openSlitaireHis();
            } else if (key === 'participated') {
                count = 1;
                solitaireList = [];
                setScrollEnd(false);
                pageReceiveSolitaire();
            } else if (key === 'initiated') {
                count = 1;
                solitaireList = [];
                setScrollEnd(false);
                pageCreateSolitaire();
            }
        },
        [openSlitaireHis, pageCreateSolitaire, pageReceiveSolitaire]
    );

    useEffect(() => {
        if (SolitaireHisVisible) {
            onTabClick('participate');
        }
    }, [SolitaireHisVisible, onTabClick]);

    const onHandle = (item: any) => {
        setsolitaireSoli(true);
        openSolitaireDetails({
            isvisibles: true,
            solitaire: item.solitaireId,
        });
    };

    // 打开催办
    function handleOpenRecord(advanceUser: any, value: any, id: any) {
        const userList: any[] = [];
        if (advanceUser.length === undefined) {
            // eslint-disable-next-line array-callback-return
            // advanceUser.map((item: any) => {
            userList.push({
                userId: advanceUser?.userId,
                teamId: advanceUser?.teamId,
                appId: advanceUser?.appId,
                headImg: advanceUser?.headImg,
                realName: advanceUser?.realName,
            });
            // });
        } else {
            // eslint-disable-next-line array-callback-return
            advanceUser.map((item: any) => {
                userList.push({
                    userId: item?.userId,
                    teamId: item?.teamId,
                    appId: item?.appId,
                    headImg: item?.headImg,
                    realName: item?.realName,
                });
            });
        }
        openCreateSuperviseModal({
            visible: true,
            createData: {
                id: id,
                title: value,
                userList,
                from: EsuperviseSourceType.SOLITAIRE,
            },
        });
        setIsvisible(false);
    }

    const onTabchange = (activeKey: any) => {
        if (activeKey === '2') {
            setbtnstyle({ display: 'block' });
        } else {
            setbtnstyle({ display: 'none' });
        }
    };
    // 滚动
    const onScroll = () => {
        const cu: any = scrollRef.current;
        if (!cu) {
            return;
        }
        const scrollHeight = cu.getScrollHeight();
        const scrollTop = cu.getScrollTop();
        const { clientHeight } = cu.getValues();
        if (scrollHeight - clientHeight - scrollTop === 0) {
            if (scrollEnd) return;
            if (Tab === 'participate') {
                count++;
                openSlitaireHis();
                return;
            }
            if (Tab === 'participated') {
                count++;
                pageReceiveSolitaire();
                return false;
            }
            if (Tab === 'initiated') {
                count++;
                pageCreateSolitaire();
                return;
            }
        }
    };

    // 待参与
    const ToParticipate = () => {
        if (historyList.length === 0) {
            return (
                <div className="Empty_item">
                    <Empty
                        image={noticeImg}
                        imageStyle={{
                            height: 100,
                        }}
                        description={<span style={{ color: '#62656D' }}>暂无待参与的接龙</span>}
                    />
                </div>
            );
        }
        return historyList.map((item: any, inx: any) => {
            return (
                <Card
                    className="SlitaireHisCard"
                    onClick={() => {
                        onHandle(item);
                    }}
                    key={inx}
                >
                    <div className="SlitaireHis_box_title">
                        <p className="SlitaireHis_box_title_txt">{item.title}</p>
                        <span className={`haveHand ${item.status === 2 && 'UpTo'}`}>
                            {item.status === 2 ? '已截止' : '进行中'}
                        </span>
                    </div>
                    <div className="SlitaireHis_box_con">
                        <span style={{ display: 'flex', color: '#8F959E' }}>
                            <span className="ellipsis">{item.createName}</span>
                            发布于 {Jlmate(item.gmtCreate)}
                        </span>
                        <Popover
                            overlayClassName="SlitaireHis_Popover"
                            trigger="click"
                            placement="bottomLeft"
                            content={show === inx && tabList()}
                            onVisibleChange={(visible) => {
                                visibleChange(visible, inx, item.solitaireId);
                            }}
                            visible={show === inx && isvisible}
                        >
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                参与&nbsp;&nbsp;
                                <span className="active">
                                    <span className="number">{item.repliedNum}</span>/
                                    {item.totalNum}
                                </span>
                            </span>
                        </Popover>
                    </div>
                </Card>
            );
        });
    };
    // 已参与
    const PageReceiveSolitaire = () => {
        if (ReceiveSolitaire.length === 0) {
            return (
                <div className="Empty_item">
                    <Empty
                        image={noticeImg}
                        imageStyle={{
                            height: 100,
                        }}
                        description={<span style={{ color: '#62656D' }}>暂未参与接龙</span>}
                    />
                </div>
            );
        }
        return ReceiveSolitaire.map((item: any, inx: any) => {
            return (
                <Card
                    className="SlitaireHisCard"
                    onClick={() => {
                        onHandle(item);
                    }}
                    key={inx}
                >
                    <div className="SlitaireHis_box_title">
                        <p className="SlitaireHis_box_title_txt">{item.title}</p>
                        <span className={`haveHand ${item.status === 2 && 'UpTo'}`}>
                            {item.status === 2 ? '已截止' : '进行中'}
                        </span>
                    </div>
                    <div className="SlitaireHis_box_con">
                        <span style={{ display: 'flex', color: '#8F959E' }}>
                            <span className="ellipsis">{item.createName}</span>
                            发布于 {Jlmate(item.gmtCreate)}
                        </span>
                        <Popover
                            overlayClassName="SlitaireHis_Popover"
                            trigger="click"
                            placement="bottomLeft"
                            content={show === inx && tabList()}
                            onVisibleChange={(visible) => {
                                visibleChange(visible, inx, item.solitaireId);
                            }}
                            visible={show === inx && Tab === 'participated' && isvisible}
                        >
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                参与&nbsp;&nbsp;
                                <span className="active">
                                    <span className="number">{item.repliedNum}</span>/
                                    {item.totalNum}
                                </span>
                            </span>
                        </Popover>
                    </div>
                </Card>
            );
        });
    };
    // 我发起的
    function PageCreateSolitaire() {
        if (pageCreate.length === 0) {
            return (
                <div className="Empty_item">
                    <Empty
                        image={noticeImg}
                        imageStyle={{
                            height: 100,
                        }}
                        description={<span style={{ color: '#62656D' }}>暂未发起接龙</span>}
                    />
                </div>
            );
        }
        return pageCreate.map((item: any, inx: any) => {
            return (
                <Card
                    className="SlitaireHisCard"
                    onClick={() => {
                        onHandle(item);
                    }}
                    key={inx}
                >
                    <div className="SlitaireHis_box_title">
                        <p className="SlitaireHis_box_title_txt">{item.title}</p>
                        <span className={`haveHand ${item.status === 2 && 'UpTo'}`}>
                            {item.status === 2 ? '已截止' : '进行中'}
                        </span>
                    </div>
                    <div className="SlitaireHis_box_con">
                        <span style={{ display: 'flex', color: '#8F959E' }}>
                            <span className="ellipsis">{item.createName}</span>
                            发布于 {Jlmate(item.gmtCreate)}
                        </span>
                        <Popover
                            overlayClassName="SlitaireHis_Popover"
                            trigger="click"
                            placement="bottomLeft"
                            content={show === inx && tabList()}
                            onVisibleChange={(visible) => {
                                visibleChange(visible, inx, item.solitaireId);
                            }}
                            visible={show === inx && isvisible}
                        >
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                参与&nbsp;&nbsp;
                                <span className="active">
                                    <span className="number">{item.repliedNum}</span>/
                                    {item.totalNum}
                                </span>
                            </span>
                        </Popover>
                    </div>
                </Card>
            );
        });
    }

    function tabList() {
        if (isvisible) {
            return (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {historyList && (
                        <Tabs
                            defaultActiveKey="1"
                            tabBarGutter={55}
                            onChange={(activeKey) => onTabchange(activeKey)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <TabPane tab={`已参与 ${getSolitaires.readMemberList?.length}`} key="1">
                                {getSolitaires.readMemberList?.length === 0 ? (
                                    <div className="Empty_item">
                                        <Empty
                                            image={noticeImg}
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={
                                                <span style={{ color: '#62656D' }}>
                                                    暂无人员参与
                                                </span>
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="NoticeDetails_unReadMember">
                                        {getSolitaires.readMemberList?.map(
                                            (item: any, index: any) => {
                                                if (item === undefined) return null;
                                                let dept = '';
                                                let proTitle = '';
                                                if (item.labelInfoList?.[1]) {
                                                    dept = item.labelInfoList[1].name;
                                                }
                                                if (item.labelInfoList?.[2]) {
                                                    proTitle = item.labelInfoList[2].name;
                                                }
                                                return (
                                                    <div className="NoticeDetails_user" key={index}>
                                                        <div className="NoticeDetails_left">
                                                            <Avatar
                                                                className="imgAvatar"
                                                                name={item.realName || ''}
                                                                src={changeEgovUrl(item.headImg)}
                                                            />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    marginLeft: '6px',
                                                                }}
                                                            >
                                                                <span
                                                                    className="ellipsis"
                                                                    style={{
                                                                        color: '#232930',
                                                                        width: '160px',
                                                                    }}
                                                                >
                                                                    {item.realName}
                                                                </span>
                                                                <span
                                                                    className="ellipsis"
                                                                    style={{
                                                                        color: '#6E6F71',
                                                                        fontSize: '12px',
                                                                        width: '160px',
                                                                    }}
                                                                >{`${dept}-${proTitle}`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                            </TabPane>
                            <TabPane
                                tab={`未参与 ${getSolitaires.unReadMemberList?.length}`}
                                key="2"
                            >
                                {getSolitaires.unReadMemberList === null ||
                                getSolitaires.unReadMemberList?.length === 0 ? (
                                    <div className="Empty_item">
                                        <Empty
                                            image={noticeImg}
                                            imageStyle={{
                                                height: 100,
                                            }}
                                            description={
                                                <span style={{ color: '#62656D' }}>
                                                    全部人员已参与
                                                </span>
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                            paddingBottom: '3px',
                                        }}
                                    >
                                        <div
                                            className={`NoticeDetails_unReadMember ${
                                                getSolitaires?.createBy ===
                                                    currentEmployee.userId &&
                                                getSolitaires.status !== 2 &&
                                                currentEmployee.teamId === getSolitaires?.teamId &&
                                                'NoticeDetails_unReadMember_true'
                                            }`}
                                        >
                                            {getSolitaires.unReadMemberList?.map(
                                                (item: any, index: any) => {
                                                    if (item === undefined) return null;
                                                    let dept = '';
                                                    let proTitle = '';
                                                    if (item.labelInfoList?.[1]) {
                                                        dept = item.labelInfoList[1].name;
                                                    }
                                                    if (item.labelInfoList?.[2]) {
                                                        proTitle = item.labelInfoList[2].name;
                                                    }
                                                    return (
                                                        <div
                                                            className="NoticeDetails_user"
                                                            key={index}
                                                        >
                                                            <div className="NoticeDetails_left">
                                                                <Avatar
                                                                    className="imgAvatar"
                                                                    name={item.realName || ''}
                                                                    src={changeEgovUrl(
                                                                        item.headImg
                                                                    )}
                                                                />
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        marginLeft: '6px',
                                                                    }}
                                                                >
                                                                    <span
                                                                        className="ellipsis"
                                                                        style={{
                                                                            color: '#232930',
                                                                            width: '160px',
                                                                        }}
                                                                    >
                                                                        {item.realName}
                                                                    </span>
                                                                    <span
                                                                        className="ellipsis"
                                                                        style={{
                                                                            color: '#6E6F71',
                                                                            fontSize: '12px',
                                                                            width: '160px',
                                                                        }}
                                                                    >{`${dept}-${proTitle}`}</span>
                                                                </div>
                                                            </div>
                                                            {getSolitaires?.createBy ===
                                                                currentEmployee.userId &&
                                                                getSolitaires.status !== 2 &&
                                                                currentEmployee.teamId ===
                                                                    getSolitaires?.teamId && (
                                                                    <div
                                                                        className="NoticeDetails_right"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();
                                                                            handleOpenRecord(
                                                                                item,
                                                                                getSolitaires?.title,
                                                                                getSolitaires.solitaireId
                                                                            );
                                                                        }}
                                                                    >
                                                                        催办
                                                                    </div>
                                                                )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>

                                        {getSolitaires?.createBy === currentEmployee.userId &&
                                            getSolitaires.status !== 2 &&
                                            currentEmployee.teamId === getSolitaires?.teamId && (
                                                <div
                                                    className="NoticeDetails_button"
                                                    style={btnstyle}
                                                >
                                                    <Button
                                                        block
                                                        type="primary"
                                                        htmlType="submit"
                                                        onClick={debounce(() => {
                                                            handleOpenRecord(
                                                                getSolitaires.unReadMemberList,
                                                                getSolitaires?.title,
                                                                getSolitaires.solitaireId
                                                            );
                                                        }, 500)}
                                                    >
                                                        全部催办
                                                    </Button>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </TabPane>
                        </Tabs>
                    )}
                </>
            );
        } else return null;
    }

    return (
        <Drawer
            title="接龙历史"
            width={400}
            placement="right"
            onClose={onClose}
            visible={SolitaireHisVisible}
            className="SlitaireHis"
        >
            <ScrollView onScroll={onScroll} ref={scrollRef}>
                <div className="SlitaireHis_box">
                    <Tabs onChange={onTabClick} activeKey={Tab}>
                        <TabPane tab="待参与" key="participate">
                            {Tab === 'participate' && ToParticipate()}
                        </TabPane>
                        <TabPane tab="已参与" key="participated">
                            {Tab === 'participated' && PageReceiveSolitaire()}
                        </TabPane>
                        <TabPane tab="我发起的" key="initiated">
                            {Tab === 'initiated' && PageCreateSolitaire()}
                        </TabPane>
                    </Tabs>
                </div>
            </ScrollView>
            {/* <SolitaireDetails solitaireSoli={solitaireSoli} /> */}
        </Drawer>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { currentEmployee, selectedSession, SolitaireHisVisible, solitaireSessionId } = chat;
    return {
        currentEmployee,
        selectedSession,
        SolitaireHisVisible,
        solitaireSessionId,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
        openSolitaireDetails(data: { isvisibles: boolean; solitaire: any }) {
            dispatch({ type: 'chat/openSolitaireDetails', payload: data });
        },
        openSolitaireHis(data: { isvisibles: boolean; solitaireSid: any }) {
            dispatch({ type: 'chat/openSolitaireHis', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SlitaireHis);
