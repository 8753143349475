import BaseService from '@/attributes/BaseService';
import PageModel from '../datamodel/PageModel';
class PageService extends BaseService {
    async createPageInFile(propertyKey: string, pageModel: PageModel) {
        await this.saveToDB({
            _id: `base-data-${pageModel.id}`,
            value: { page: pageModel, permissions: pageModel.permissions },
        });
    }
    async updatePageInFile(pageId: string, pageModel: PageModel) {
        this.getFromDB(`base-data-${pageId}`).then((res: any) => {
            console.log('updatePageInFile1', res?.value);
            const { page, permissions } = res?.value || {};
            const newData = {
                page: {
                    ...page,
                    ...pageModel,
                },
                permissions,
            };

            this.saveToDB({
                _id: `base-data-${pageModel.id}`,
                // @ts-ignore
                value: newData,
            });
        });
    }
    async setParentFolder(folderInfo: any, pageId: string) {
        setTimeout(async () => {
            await this.saveToDB({ _id: `folderInfo-${pageId}`, value: [folderInfo] });
        }, 400);
    }

    concatList2Page(list: any[]): any {
        return new Promise((resolve, reject) => {
            const result: Array<PageModel> = [];
            const p: Array<Promise<any>> = [];
            list.forEach((id: any) => {
                p.push(
                    this.getFromDB(`base-data-${id}`).then((doc: any) => {
                        // console.log(doc, 'docdocdocdocdocdoc===>', `base-data-${id}`);
                        const _isNew = doc.page_id ? doc.isNew : true;
                        doc && result.push({ ...doc.value.page, id, isNew: _isNew });
                    })
                );
            });
            Promise.all(p)
                .then((res: any) => {
                    result.sort((a: PageModel, b: PageModel) => b.updated_at - a.updated_at);
                    resolve(result);
                })
                .catch((e: any) => {
                    reject(e);
                });
        });
    }
    async remove(id: string) {
        try {
            this.deleteFromDB(`base-data-${id}`);
            this.deleteFromDB(`folderInfo-${id}`);
            return true;
        } catch (error) {
            return false;
        }
    }
}
// new PageService('joyspace.db', 'apis');
export default PageService;
