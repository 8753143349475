import React from 'react';
import { BrowserViewContext, BrowserViewItem } from './utils';
import WebView from './Webview';
import { changeEgovUrl } from '@/utils/tools';

export default function BrowserViewBody() {
    const { items, selectedId, defaultPage } = React.useContext(BrowserViewContext);

    const startupBody = defaultPage ? <div>{defaultPage.body}</div> : null;
    return (
        <div
            style={{
                position: 'relative',
                flex: 1,
                backgroundColor: '#fff',
                display: 'flex',
            }}
        >
            {startupBody}
            {items.map((item: BrowserViewItem) => (
                <WebView
                    key={item.id}
                    visible={item.id === selectedId}
                    item={item}
                    src={changeEgovUrl(item.link)}
                />
            ))}
        </div>
    );
}
