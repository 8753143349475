import BaseService from './BaseService';
export interface IBaseController {
    dbService: any;
    initDb: () => void;
    getFromDB: (id: string) => any;
    saveToDB: (data: any) => void;
}

class BaseController implements IBaseController {
    dbService: any;
    dbName: string | undefined;
    schemaName: string | undefined;
    [type: string]: any;
    /**
     * @params dbName 数据库名称
     * @params schemaName 数据表名称
     * @params services 可以创建多个数据库服务，如果改参数传值则dbName和schemaName无效
     */
    // eslint-disable-next-line max-params
    constructor(dbName?: string, schemaName?: string) {
        this.dbName = dbName;
        this.schemaName = schemaName;
        // this.initDb();
    }
    // 如果一个controller需要用到多个service，则controller子类可以重写改方法，在改方法内部再次初始化所有的service示例，因为，每次断网service实例会变为undefined
    initDb() {
        console.log(this.dbName, this.schemaName, 'this.schemaNamethis.schemaNamethis.schemaName');
        this.dbService = new BaseService(this.dbName, this.schemaName);
        console.log(this.dbService, 'this.dbServicethis.dbServicethis.dbService');
    }
    // 如果一个controller需要用到多个service，则controller子类可以重写改方法
    saveToDB(data: any) {
        this.dbService.saveToDB(data);
    }
    // 如果一个controller需要用到多个service，则controller子类可以重写改方法
    getFromDB(id: string) {
        return this.dbService.getFromDB(id);
    }
}

export default BaseController;
