//  左侧消息头部组件

import React, { useMemo } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { connect } from 'dva';
import { MessageCategoryType, MessageNotifyType } from '@/types/chat/enum';
import ChatState from '@/types/chat/State';
import { WithTranslation, withTranslation } from 'react-i18next';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { Menu } from 'antd';
interface MsgNotificationProps {
    isAllFollow?: boolean;
    closeAllFollow?: (isAllFollow: boolean) => void;
}
interface DvaDispatchProps {
    changeNotifyType: Function;
    changeCategory: Function;
}

type IProps = Readonly<MsgNotificationProps & DvaDispatchProps & WithTranslation & ChatState>;

function MsgNotification(props: IProps) {
    const { topCount, selectedNotifyType, notifyType, t, isAllFollow, connectState } = props;

    // 点击消息提醒类型 全部 已读 重要
    const changeNotifyTypeFn = (key: MessageNotifyType) => {
        props.changeNotifyType({ selectedNotifyType: key });
    };

    // 点击关闭全部置顶消息列表
    const closeAllFollow = () => {
        if (props.closeAllFollow) {
            props.closeAllFollow(false);
        }
    };

    let msgTitle = useMemo(() => {
        let msgTitle = t('connection-status.on-connection');
        switch (connectState.state) {
            case ConnectState.OFFLINE:
            case ConnectState.ERROR:
                msgTitle = t('connection-status.connection-closed');
                break;
            case ConnectState.CLOSED:
            case ConnectState.CONNECTING:
            case ConnectState.CONNECTED:
                msgTitle = t('connection-status.on-connection');
                break;
            case ConnectState.AUTHING:
                msgTitle = t('connection-status.on-receive');
                break;
            // case ConnectState.AUTH_FAILED:
            //     msgTitle = t('connection-status.authentication-failed');
            //     break;
            case ConnectState.AUTHED:
            case ConnectState.READY:
                // msgTitle = t(notifyType[selectedNotifyType].title);
                msgTitle = '';

                break;
        }
        return msgTitle;
    }, [connectState.state, notifyType, selectedNotifyType, t]); // eslint-disable-line
    return (
        <div className="msg-notification">
            {!isAllFollow ? (
                <div className="MsgHeader">
                    {connectState.state === 'ready' ? (
                        <div className="notify-type-container">
                            {Object.keys(notifyType).map((key, index) => {
                                const nt = notifyType[key];
                                return (
                                    nt.enable && (
                                        <div
                                            key={index}
                                            onClick={changeNotifyTypeFn.bind(
                                                null,
                                                key as MessageNotifyType
                                            )}
                                            clstag={nt.clstag}
                                            className={`notify-type-item ${
                                                selectedNotifyType === key
                                                    ? 'notify-type-item-selected'
                                                    : ''
                                            }`}
                                        >
                                            <div className="notify-type-item_title">
                                                <span>{t(notifyType[key].title)}</span>
                                            </div>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    ) : (
                        <div className="msgTitle">{msgTitle ? msgTitle : ''}</div>
                    )}
                </div>
            ) : (
                <div className="MsgHeader MsgHeaders">
                    <div className="msgTitle">
                        {t('top-all')}({topCount})
                    </div>
                    <IconFont type="iconwin_Close" className="wrong" onClick={closeAllFollow} />
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const {
        selectedNotifyType,
        selectedCategory,
        category,
        notifyType,
        connectState,
        topCount,
        unReadCount,
        atCount,
    } = chat;
    return {
        selectedNotifyType,
        selectedCategory,
        category,
        notifyType,
        connectState,
        topCount,
        unReadCount,
        atCount,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeNotifyType(data: { selectedNotifyType: MessageNotifyType }) {
            dispatch({ type: 'chat/changeNotifyType', payload: data });
            // (偶现)桌面端筛选未读时后台切换其他应用在切换回来会话页面闪 --hujun
            // 点击消息列表的<全部><组><未读><@我>,右侧对话框清空显示空白
            // dispatch({ type: 'chat/updateSelectedSession', payload: {} });
        },
        changeCategory(data: { selectedCategory: MessageCategoryType }) {
            dispatch({ type: 'chat/changeCategory', payload: data });
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('chat')(MsgNotification));
