import { Modal } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './index.less';

const PermissionConfirm = (props: any) => {
    const { text = '', visibleModal, removeNode, onOk } = props;
    const [_visibleModal, setVisibleModal] = useState(visibleModal);
    return (
        <Modal
            title="权限变更"
            width={355}
            visible={_visibleModal}
            destroyOnClose={true}
            okText="确定"
            cancelText="取消"
            onOk={() => {
                onOk?.();
                setVisibleModal(false);
            }}
            onCancel={() => {
                setVisibleModal(false);
                // removeNode?.();
            }}
        >
            <p className="joyspace-permission-confirm-text">{text}</p>
        </Modal>
    );
};

export default function PermissionModal(props: any) {
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    function removeNode() {
        if (ele) {
            ReactDOM.unmountComponentAtNode(ele);
            ele.remove();
        }
    }
    ReactDOM.render(<PermissionConfirm {...{ ...props }} removeNode={removeNode} />, ele);
}
