// import weekOfYear from 'dayjs/plugin/weekOfYear';
import { TotalConlum, TotalRow } from './../utils/constant';
import dayjs, { Dayjs } from 'dayjs';
// dayjs.extend(weekOfYear);
import isoWeek from 'dayjs/plugin/isoWeek';
import { getColor } from './ComputedElement';
import { CalendarDefaultColor } from './constant';
import { Canceled, Schedule, RepeatMode } from '@/api/calendar';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { IMeetingModal } from '../Schedule/Detail';
import { MeetingPlatform } from '@/types/common';
import config, { ConfigEnum } from '@/config/config';

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
/**
 *
 * 根据行，列以及当前天获得要展示的日历列表
 * @param {dayjs.Dayjs} current
 * @returns {dayjs.Dayjs[]}
 */
// export function getCalendarList(current: Dayjs): Dayjs[] {
//     let calendarList = [];
//     const day = current.day() || 7; // 星期几
//     const row = Math.ceil(current.date() / TotalConlum); // 传入时间所在行
//     const colum = day; // 星期几表示列
//     const total = TotalConlum * TotalRow;
//     const currentIndex = (row - 1) * TotalConlum + colum - 1; // 传入时间在整个展示列表中的位置
//     for (let i = 0; i < currentIndex; i++) {
//         calendarList[i] = current.subtract((currentIndex - i) * 24, 'hour');
//     }
//     calendarList[currentIndex] = current;
//     for (let i = currentIndex + 1; i < total; i++) {
//         calendarList[i] = current.add((i - currentIndex) * 24, 'hour');
//     }
//     // for (let i = 0; i < total; i++) {
//     //     let diff = currentIndex - i >= 0 ? currentIndex - i : i - currentIndex;
//     //     calendarList[i] = current.subtract(diff * 24, 'hour');
//     // }
//     // console.log(calendarList);
//     return calendarList;
// }
export function isCrossDate(beginTime: any, endTime: any) {
    return (
        dayjs(beginTime).format('YYYY-MM-DD') !== dayjs(endTime).format('YYYY-MM-DD') &&
        dayjs(dayjs(beginTime).format('YYYY-MM-DD')).add(1, 'day').format('YYYY-MM-DD HH:mm:ss') !==
            dayjs(endTime).format('YYYY-MM-DD HH:mm:ss')
    );
}
export function getCalendarList(current: Dayjs, mode = true): Dayjs[] {
    let calendarList = [];
    const monthStart = current.startOf('month'); // 1号
    const monthEnd = current.endOf('month'); // 1号
    let listStart = dayjs(monthStart).startOf('week');
    let listEnd = dayjs(monthEnd).endOf('week');

    if (mode) {
        listStart = listStart.add(24, 'hour');
        listEnd = listEnd.add(24, 'hour');
    }

    for (let i = listStart; i.isSameOrBefore(listEnd); ) {
        calendarList.push(i);
        i = i.add(24, 'hour');
    }

    return calendarList;
}

/**
 *
 *
 * @export
 * @param {Dayjs} current
 * @param {boolean} [mode=true] 默认周一为一周的第一天， 设置false表示周日为一周的第一天。
 * @returns {Dayjs[]}
 */
export function getWeekList(current: Dayjs, mode = true): Dayjs[] {
    let weekList = [];
    let day = current.day();
    if (mode && day === 0) {
        day = 7;
    }
    if (mode) {
        // day1-7
        for (let i = 1; i <= 7; i++) {
            if (i <= day) {
                weekList[i - 1] = current.subtract((day - i) * 24, 'hour');
            } else {
                weekList[i - 1] = current.add((i - day) * 24, 'hour');
            }
        }
    } else {
        // day 0 - 6
        for (let i = 0; i <= 6; i++) {
            if (i <= day) {
                weekList[i] = current.subtract((day - i) * 24, 'hour');
            } else {
                weekList[i] = current.add((i - day) * 24, 'hour');
            }
        }
    }
    return weekList;
}

/**
 *
 *
 * @export
 * @param {Dayjs} current
 * @returns {Dayjs}
 */
export function getStartOfDay(current: Dayjs): Dayjs {
    return current.startOf('date');
}

/**
 *
 *
 * @export
 * @param {Dayjs} current
 * @returns {Dayjs}
 */
export function getEndOfDay(current: Dayjs): Dayjs {
    return current.endOf('date');
}

/**
 *
 *
 * @export
 * @param {Dayjs} current
 * @returns
 */
export function getStartEndOfDay(current: Dayjs) {
    return [current.startOf('date'), current.endOf('date')];
}

/**
 * 依据传入的时间和单位，返回对应的时间维度
 * @param current
 * @param unit
 * @returns {[start, end]} result
 */
export function getStartEndByTime(current: Dayjs, unit: 'month' | 'week' | 'day') {
    switch (unit) {
        case 'month':
            return [current.startOf('month'), current.endOf('month')];
        case 'week':
            return [current.startOf('week'), current.endOf('week')];
        case 'day':
            return [current.startOf('date'), current.endOf('date')];
    }
}

/**
 * 依据传入color返回前端可用的color值
 * @param color
 */
export function getRealColor(color: string | undefined) {
    if (!color) {
        return getColor();
    }
    if (color.length === 6) {
        return '#' + color;
    }
    // if (color.length === 8) {
    //     return '#' + color.slice(2);
    // }
    if (color.length === 8) {
        let newColor = getNewColorByOld(color);
        return '#' + newColor.slice(2);
    }
    if (color.length === 7) {
        return color;
    }
}

/**
 * 依据传入color返回前端可用的color值
 * @param color
 */
export function getRealColorWithoutPre(color: string | undefined) {
    if (!color) {
        return '';
    }
    if (color?.length === 6) {
        return color;
    }
    if (color?.length === 8) {
        return color.slice(2);
    }
}

export function assignTime(
    target: Dayjs,
    source: Dayjs,
    unit: Array<'month' | 'week' | 'day' | 'hour' | 'minute' | 'second'>
) {
    if (!target || !source || !unit) {
        return dayjs();
    }
    unit.forEach((u) => {
        target[u](source[u]());
    });
    return target;
}

export function getDateScopeList(current: Dayjs, count: number): Dayjs[] {
    // 获取三个月的数据
    let dateList = [];
    let beginTime = current.startOf('month').subtract(count, 'month');
    let endTime = current.endOf('month').add(count, 'month');

    let i = 0;
    for (let time = beginTime; time.isBefore(current); i++) {
        dateList[i] = time;
        time = time.add(24, 'hour');
    }
    // dateList[++i] = current;
    for (let time = current; time.isSameOrBefore(endTime); i++) {
        dateList[i] = time;
        time = time.add(24, 'hour');
    }
    // for (let i = 0; i < count; i++) {
    //     dateList[i] = current.subtract((count - i) * 24, 'hour');
    // }
    // dateList[count] = current;
    // for (let i = 1; i < count; i++) {
    //     dateList[count + i] = current.add(i * 24, 'hour');
    // }

    return dateList;
}

export function getStartEnd(element: any, dateList: any, i: Dayjs) {
    let diff = element.endTime - element.beginTime;
    let o: any = dateList.length ? Object.values(dateList[dateList.length - 1])[0] : {};
    let b = dateList.length ? o.beginTime : dayjs(element.beginTime);
    let e = dateList.length ? o.endTime : dayjs(element.endTime);
    // assignTime(dateList.length?dateList[dateList.length - 1].)
    let a = assignTime(i, b, ['hour', 'minute']);
    let b1;
    let e1;
    b1 = a;
    e1 = dayjs(a.valueOf() + diff);
    // b1 = a;
    // e1 = dayjs(a.valueOf() + diff);
    return {
        beginTime: b1,
        endTime: e1,
    };
}

export function getCrossDayStartEnd(element: any, dateList: any, i: Dayjs) {
    let diff = element.endTime - element.beginTime;
    let o: any = dateList.length ? Object.values(dateList[dateList.length - 1])[0] : {};
    let b = dateList.length ? o.beginTime : dayjs(element.beginTime);
    let e = dateList.length ? o.endTime : dayjs(element.endTime);
    // assignTime(dateList.length?dateList[dateList.length - 1].)
    let a = assignTime(i, b, ['hour', 'minute']);
    let b1;
    let e1;
    if (a.isBetween(b, e)) {
        b1 = b;
        e1 = e;
    } else {
        b1 = a;
        e1 = dayjs(a.valueOf() + diff);
    }
    // b1 = a;
    // e1 = dayjs(a.valueOf() + diff);
    return {
        beginTime: b1,
        endTime: e1,
    };
}

/**
 * 根据重复模式 和 开始结束时间获得日期 列表
 *
 * @param  element
 * @param {Dayjs} beginTime
 * @param {Dayjs} endTime
 */
export function getDateListByRepeatMode(element: any, beginTime: Dayjs, endTime: Dayjs) {
    let dateList: any[] = [];
    let mode = element.repeat;
    let diff = element.endTime - element.beginTime;
    let isSameDay = dayjs(element.beginTime).isSame(element.endTime, 'date');
    let crossDays =
        dayjs(element.endTime)
            .startOf('date')
            .diff(dayjs(element.beginTime).startOf('date'), 'hour') / 24;
    switch (mode) {
        case RepeatMode.WorkDay: {
            let count = 0;
            for (let i = beginTime; i.isBefore(endTime); ) {
                // console.log('i.day:', i.day());
                if (i.day() === 0 || i.day() === 6) {
                    i = i.add(24, 'hour');
                    continue;
                    // if (!isSameDay) {
                    //     // 跨天的情况
                    //     // 每一段的第一天不能落在周六周日
                    //     let num = count % crossDays;
                    //     // console.log('num:', num);
                    //     // console.log('i.day:', i.day());
                    //     if (num === 0) {
                    //         i = i.add(24, 'hour');
                    //         // count++;
                    //         continue;
                    //     }
                    //     if (num === 1 && i.day() === 0) {
                    //         i = i.add(24, 'hour');
                    //         // count++;
                    //         continue;
                    //     }
                    //     // console.log('没跳过');
                    // } else {
                    //     // 非跨天的情况
                    //     // count++;
                    //     i = i.add(24, 'hour');
                    //     continue;
                    // }
                }
                const key = i.format('YYYY-MM-DD');
                let obj = getStartEnd(element, dateList, i);
                dateList.push({
                    [key]: obj,
                });
                count++;
                i = i.add(24, 'hour');
            }
            break;
        }
        case RepeatMode.EveryDay: {
            for (let i = beginTime; i.isBefore(endTime); i) {
                const key = i.format('YYYY-MM-DD');
                let obj = getStartEnd(element, dateList, i);
                dateList.push({
                    [key]: obj,
                });
                i = i.add(24, 'hour');
            }
            break;
        }
        case RepeatMode.EveryWeek: {
            for (let i = beginTime; i.isBefore(endTime); i) {
                const key = i.format('YYYY-MM-DD');
                let obj = getStartEnd(element, dateList, i);

                dateList.push({
                    [key]: obj,
                });
                i = i.add(1, 'week');
            }
            break;
        }
        case RepeatMode.EveryTwoWeeks: {
            for (let i = beginTime; i.isBefore(endTime); i) {
                const key = i.format('YYYY-MM-DD');
                let obj = getStartEnd(element, dateList, i);

                dateList.push({
                    [key]: obj,
                });
                i = i.add(2, 'week');
            }
            break;
        }
        case RepeatMode.EveryMonth: {
            for (let i = beginTime; i.isBefore(endTime); i) {
                const key = i.format('YYYY-MM-DD');
                let obj = getStartEnd(element, dateList, i);

                dateList.push({
                    [key]: obj,
                });
                i = i.add(1, 'month');
            }
            break;
        }
        case RepeatMode.EveryYear: {
            for (let i = beginTime; i.isBefore(endTime); i) {
                const key = i.format('YYYY-MM-DD');
                let obj = getStartEnd(element, dateList, i);

                dateList.push({
                    [key]: obj,
                });
                i = i.add(1, 'year');
            }
            break;
        }
    }
    return dateList;
}

/**
 * 装饰一下获取的日程列表，将ScheduleList映射为map
 * @param list
 * @param beginTime
 * @param endTime
 * @param archiveIgnore
 */
// eslint-disable-next-line max-params
export function handleScheduleList(
    list: Schedule[],
    beginTime: Dayjs,
    endTime: Dayjs,
    archiveIgnore?: boolean // 为true时 不考虑归档
) {
    if (!list || list.length === 0) {
        return {};
    }
    // todo 处理
    const temp: { [key: string]: any } = {};

    list.forEach((element: Schedule) => {
        // 重复数据处理
        // @ts-ignore
        if (element.repeat !== RepeatMode.No) {
            let dateList = getDateListByRepeatMode(
                element,
                element.repeatBeginTime ? dayjs(element.repeatBeginTime) : dayjs(element.beginTime), // todo 传beginTime与element.beginTime之后的数据
                element.repeatEndTime ? dayjs(element.repeatEndTime) : endTime
            );

            dateList.forEach((obj) => {
                let key = Object.keys(obj)[0];
                let values: any = Object.values(obj)[0];

                // 有可能存在跨天的日程
                for (
                    let i = values.beginTime;
                    i.isBefore(values.endTime) ||
                    (values.beginTime.format('YYYY-MM-DD') !==
                        values.endTime.format('YYYY-MM-DD') &&
                        dayjs(i.format('YYYY-MM-DD')).isSameOrBefore(
                            dayjs(values.endTime.format('YYYY-MM-DD'))
                        ));
                    i
                ) {
                    if (i.isBefore(beginTime)) {
                        i = i.add(24, 'hour');
                        continue;
                    }
                    if (i.isSameOrAfter(endTime)) {
                        break;
                    }
                    const key = i.format('YYYY-MM-DD');
                    if (temp[key]) {
                        if (values.endTime.format('YYYY-MM-DD HH:mm') !== `${key} 00:00`) {
                            temp[key].push({
                                ...element,
                                repeatScheduleId: element.scheduleId + values.beginTime,
                                scheduleBeginTime: values.beginTime,
                                scheduleEndTime: values.endTime,
                            });
                        }
                    } else {
                        if (values.endTime.format('YYYY-MM-DD HH:mm') !== `${key} 00:00`) {
                            temp[key] = [
                                {
                                    ...element,
                                    repeatScheduleId: element.scheduleId + values.beginTime,
                                    scheduleBeginTime: values.beginTime,
                                    scheduleEndTime: values.endTime,
                                },
                            ];
                        }
                    }

                    i = i.add(24, 'hour');
                }
            });
        } else {
            element.scheduleBeginTime = dayjs(element.beginTime);
            element.scheduleEndTime = dayjs(element.endTime);
            const start = dayjs(element.beginTime);
            const end = dayjs(element.endTime);
            for (
                let i = start;
                i.isBefore(end) ||
                (start.format('YYYY-MM-DD') !== end.format('YYYY-MM-DD') &&
                    dayjs(i.format('YYYY-MM-DD')).isSameOrBefore(dayjs(end.format('YYYY-MM-DD'))));
                i
            ) {
                if (i.isBefore(beginTime)) {
                    i = i.add(24, 'hour');
                    continue;
                }
                if (i.isSameOrAfter(endTime)) {
                    break;
                }
                const key = i.format('YYYY-MM-DD');
                // 只填充请求的时间段，非本次请求不填充，否则会覆盖原本的缓存数据
                if (temp[key]) {
                    if (dayjs(element.endTime).format('YYYY-MM-DD HH:mm') !== `${key} 00:00`) {
                        temp[key].push(element);
                    }
                } else {
                    if (dayjs(element.endTime).format('YYYY-MM-DD HH:mm') !== `${key} 00:00`) {
                        temp[key] = [element];
                    }
                }

                i = i.add(24, 'hour');
            }
            return;
        }
    });

    for (let i = beginTime; i.isBefore(endTime); i) {
        if (i.isBefore(beginTime)) {
            i = i.add(24, 'hour');
            continue;
        }
        if (i.isSameOrAfter(endTime)) {
            break;
        }
        const key = i.format('YYYY-MM-DD');
        if (!temp[key]) {
            temp[key] = [];
        }

        i = i.add(24, 'hour');
    }
    return temp;
}

/**
 * 装饰一下获取的日程列表，将ScheduleList映射为map
 * @param list
 * @param beginTime
 * @param endTime
 * @param archiveIgnore
 */
// eslint-disable-next-line max-params
export function handleExportScheduleList(
    list: Schedule[],
    beginTime: Dayjs,
    endTime: Dayjs,
    archiveIgnore?: boolean // 为true时 不考虑归档
) {
    if (!list || list.length === 0) {
        return {};
    }
    // todo 处理
    const temp: { [key: string]: any } = {};

    list.forEach((element: Schedule) => {
        // 重复数据处理
        // @ts-ignore
        if (element.repeat !== RepeatMode.No) {
            let dateList = getDateListByRepeatMode(
                element,
                element.repeatBeginTime ? dayjs(element.repeatBeginTime) : dayjs(element.beginTime), // todo 传beginTime与element.beginTime之后的数据
                // 结束时间取repeatEndTime和endTIme的最小值
                element.repeatEndTime
                    ? dayjs(element.repeatEndTime)
                        ? element.repeatEndTime < endTime.valueOf()
                            ? dayjs(element.repeatEndTime)
                            : endTime
                        : endTime
                    : endTime
            );

            dateList.forEach((obj) => {
                let key = Object.keys(obj)[0];
                let values: any = Object.values(obj)[0];
                for (let i = values.beginTime; i.isBefore(values.endTime); i) {
                    if (i.isBefore(beginTime)) {
                        i = i.add(24, 'hour');
                        continue;
                    }
                    // if (i.isSameOrAfter(endTime)) {
                    //     break;
                    // }
                    const key = i.format('YYYY-MM-DD');
                    if (temp[key]) {
                        temp[key].push({
                            ...element,
                            repeatScheduleId: element.scheduleId + values.beginTime,
                            scheduleBeginTime: values.beginTime,
                            scheduleEndTime: values.endTime,
                        });
                    } else {
                        temp[key] = [
                            {
                                ...element,
                                repeatScheduleId: element.scheduleId + values.beginTime,
                                scheduleBeginTime: values.beginTime,
                                scheduleEndTime: values.endTime,
                            },
                        ];
                    }

                    i = i.add(24, 'hour');
                    // 跨天日程只留一个
                    break;
                }
            });
        } else {
            element.scheduleBeginTime = dayjs(element.beginTime);
            element.scheduleEndTime = dayjs(element.endTime);
            const start = dayjs(element.beginTime);
            const end = dayjs(element.endTime);
            let key = start.format('YYYY-MM-DD');
            if (start.isBefore(beginTime)) {
                key = beginTime.format('YYYY-MM-DD');
            }

            // 只填充请求的时间段，非本次请求不填充，否则会覆盖原本的缓存数据
            if (temp[key]) {
                temp[key].push(element);
            } else {
                temp[key] = [element];
            }
            // 跨天日程只留一个
            return;
        }
    });

    for (let i = beginTime; i.isBefore(endTime); i) {
        if (i.isBefore(beginTime)) {
            i = i.add(24, 'hour');
            continue;
        }
        if (i.isSameOrAfter(endTime)) {
            break;
        }
        const key = i.format('YYYY-MM-DD');
        if (!temp[key]) {
            temp[key] = [];
        }

        i = i.add(24, 'hour');
        // 跨天日程只留一个
        break;
    }
    return temp;
}

export function getNewColorByOld(color: string) {
    const oldColors = ['FFF23030', 'FFFFB416', 'FF5EA7F8', 'FF8ADE30', 'FFE070FF'];
    const newColors = ['FFE86E83', 'FFFEC443', 'FF7CA8EF', 'FF90D984', 'FF6CA7FF'];
    if (oldColors.indexOf(color) > -1) {
        return newColors[oldColors.indexOf(color.toUpperCase())];
    } else {
        return color.toUpperCase();
    }
}

export function getCurrentTimeObj() {
    const time = dayjs();
    const star = time.startOf('day');
    const timeObj = {
        curtime: time,
        top: Math.floor(((time.unix() - star.unix()) * 24 * 72) / (60 * (60 * 24))),
    };
    return timeObj;
}

export function formatTime(time: Dayjs, t: any, isShowAllTime?: Boolean) {
    const today = dayjs();
    const yesterday = today.add(-1, 'day');
    const tomorrow = today.add(1, 'day');

    const isToday = today.isSame(time, 'day');
    const isYesterday = yesterday.isSame(time, 'day');
    const isTomorrow = tomorrow.isSame(time, 'day');

    let dayInfo = '';

    if (isToday) {
        dayInfo = t('today');
    } else if (isYesterday) {
        dayInfo = t('yesterday');
    } else if (isTomorrow) {
        dayInfo = t('tomorrow');
    } else {
        dayInfo = t('week_' + time.day());
    }

    if (isShowAllTime) {
        return `${dayInfo}`;
    }

    return `${time.format('YYYY年M月D日')} ${dayInfo} ${time.format('HH:mm')}`;
}

/**
 * 按时间分为：单日时段(当年/非当年)、单整天(当年/非当年)、跨天时段(同年/不同年)、跨整天(同年/不同年)；
 * 同年时间，当年日期不显示“年份”
 * “昨天/今天/明天”，星期特殊显示；
 * @param
 * @returns result
 */
// eslint-disable-next-line max-params
export function formatScheduleTime(beginTime: Dayjs, endTime: Dayjs, isAlldate: number, t: any) {
    let scheduleBeginTime = '';
    let scheduleEndTime = '';
    let scheduleEndTimeSubSecond = dayjs(endTime).subtract(1, 'second');
    // 如果显示结束时间 x月x日(xx)必显示
    let schEndTime = isAlldate ? scheduleEndTimeSubSecond : dayjs(endTime);

    // 先判断是整天的还是跨时段的 但整天 跨整天
    // 开始时间非当年的 或者 跨年则开始时间必然显示年份
    if (
        !dayjs(beginTime).isSame(dayjs(), 'year') ||
        !dayjs(beginTime).isSame(dayjs(schEndTime), 'year')
    ) {
        scheduleBeginTime = scheduleBeginTime + dayjs(beginTime).format('YYYY') + `年`;
    }

    scheduleBeginTime =
        scheduleBeginTime +
        dayjs(beginTime).format('M') +
        t('month') +
        dayjs(beginTime).format('D') +
        t('day') +
        `(` +
        formatTime(dayjs(beginTime), t, true) +
        `)`;

    // 跨天必有结束时间 否则 单整天 单时段的没有结束时间
    if (!dayjs(beginTime).isSame(dayjs(schEndTime), 'day')) {
        // 这是开始结束不在同一天的情况 跨天
        // 如果结束时间是整天型的 则结束时间减去1秒
        // 开始时间和结束时间不在同一年的 则结束时间显示年
        if (!dayjs(beginTime).isSame(dayjs(schEndTime), 'year')) {
            scheduleEndTime = scheduleEndTime + schEndTime.format('YYYY') + `年`;
        }

        scheduleEndTime =
            scheduleEndTime +
            schEndTime.format('M') +
            t('month') +
            schEndTime.format('D') +
            t('day') +
            `(` +
            formatTime(schEndTime, t, true) +
            `)`;
    }

    if (!isAlldate) {
        // 跨时段的 开始时间 结束时间（如果展示）均显示分钟
        scheduleBeginTime = scheduleBeginTime + ` ` + dayjs(beginTime).format('HH:mm');
        scheduleEndTime =
            scheduleEndTime + (scheduleEndTime ? ` ` : ``) + dayjs(endTime).format('HH:mm');
    }

    let inkSymbol = isAlldate
        ? ` - `
        : !dayjs(beginTime).isSame(dayjs(schEndTime), 'day')
        ? ` - `
        : `-`;

    return (
        scheduleBeginTime +
        (scheduleEndTime ? inkSymbol + scheduleEndTime : ``) +
        (isAlldate ? `(${t('allday')})` : ``)
    );
}

/**
 * 日程周视图header显示当周的日期时间格式
 * @param
 * @returns result
 */
export function formatWeekDate(beginDate: Dayjs, endDate: Dayjs, t: any) {
    const weekBeginDate =
        beginDate.year() +
        t('year') +
        (beginDate.month() + 1) +
        t('month') +
        beginDate.date() +
        t('day');
    let weekEndDate =
        endDate.year() + t('year') + (endDate.month() + 1) + t('month') + endDate.date() + t('day');
    if (beginDate.isSame(endDate, 'year')) {
        weekEndDate = endDate.month() + 1 + t('month') + endDate.date() + t('day');
    }

    return weekBeginDate + '-' + weekEndDate;
}

/**
 * 日程周视图header显示当周的日期时间格式
 * @param
 * @returns result
 */
// eslint-disable-next-line max-params
export function getScheduleTimeForTooltip(
    schedule: Schedule,
    onlyBeginTime: boolean,
    time: Dayjs,
    t: any
) {
    const isCoverWholeDay =
        schedule.scheduleBeginTime.isSameOrBefore(dayjs(time.format('YYYY-MM-DD'))) &&
        schedule.scheduleEndTime.isSameOrAfter(dayjs(time.add(24, 'hour').format('YYYY-MM-DD')));
    const isinit = schedule.scheduleBeginTime.isSame(time, 'date');
    const isEndDay = schedule.scheduleEndTime.subtract(1, 'second').isSame(time, 'date');
    if (schedule.isAcross || schedule.isAlldate) {
        if (onlyBeginTime) {
            return isCoverWholeDay || !!schedule.isAlldate
                ? t('allday')
                : isinit
                ? dayjs(schedule.beginTime).format('HH:mm')
                : isEndDay
                ? '00:00'
                : `${dayjs(schedule.beginTime).format('HH:mm')}`;
        } else {
            return isCoverWholeDay || !!schedule.isAlldate
                ? t('allday')
                : isinit
                ? dayjs(schedule.beginTime).format('HH:mm') + '-24:00'
                : isEndDay
                ? '00:00-' + dayjs(schedule.endTime).format('HH:mm')
                : `${dayjs(schedule.beginTime).format('HH:mm')}-${dayjs(schedule.endTime).format(
                      'HH:mm'
                  )}`;
        }
    } else {
        if (onlyBeginTime) {
            return dayjs(schedule.beginTime).format('HH:mm');
        } else {
            return `${dayjs(schedule.beginTime).format('HH:mm')}-${dayjs(schedule.endTime).format(
                'HH:mm'
            )}`;
        }
    }
}

/**
 * 解析会议信息
 */
export function parsingMeetingInfo(info: string) {
    let minfo = info;
    const index = minfo.indexOf('<p style="display:none;">');
    if (index === -1) {
        return null;
    }
    minfo = minfo.slice(index);
    minfo = minfo.replace('<p style="display:none;">', '').replace('</p>', '').trim();
    if (!minfo) {
        return null;
    }
    try {
        const meetingInfo: IMeetingModal = JSON.parse(minfo);
        if (
            meetingInfo.meetingId &&
            meetingInfo.meetingType === 0 &&
            meetingInfo.type === 'meeting'
        ) {
            if (
                config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] ||
                meetingInfo.meetingPlatform === MeetingPlatform.HW
            ) {
                return meetingInfo;
            }
            return null;
        }
        return null;
    } catch (error) {
        return null;
    }
}
