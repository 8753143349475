import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import Header from './Header';
import Tabs, { TabData } from './Tabs';
import { SearchGlobalParams, SearchGlobalRes, SearchType } from '@/services/SearchService';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { ListItemData } from '@/components/ListItem';
import logger from '@/utils/logger';
import { useHistory } from 'react-router-dom';
import { getJoySpacePageLink } from '@jd/focus-desktop-comps/lib/es/utils';
import lodashTrim from 'lodash/trim';
import bus from '@/utils/bus';

const log = logger.getLogger('SearchGlobalModal');
export default function SearchGlobalModal() {
    const { authInfo: authData, focusSDK, service, appRuntimeEnv } = useContext<
        GlobalContextConfig
    >(GlobalContext);
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const [config, setConfig] = useState<TabData[]>([
        { key: SearchType.All },
        { key: SearchType.Contacts },
        { key: SearchType.Department },
        { key: SearchType.Group },
        { key: SearchType.JoySpace },
        { key: SearchType.App },
        // { key: SearchType.MessageHistory },
    ]);
    const [activeKey, setActiveKey] = useState<SearchType>(SearchType.All);
    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchGlobal = useCallback(
        (params: SearchGlobalParams): Promise<SearchGlobalRes> => {
            return service.searchService.searchGlobal(params);
        },
        [service.searchService]
    );
    const onItemClick = useCallback(
        (type: SearchType, dataParam: ListItemData) => {
            try {
                const { origin: data } = dataParam;
                switch (type) {
                    case SearchType.JoySpace:
                        window.open(getJoySpacePageLink(appRuntimeEnv.joyspaceHost, data));
                        break;
                    case SearchType.App:
                        // 有个BUG，当打开的页面和跳转页面一样是，不触发 useLocation
                        history.push({
                            pathname: `/third_apps`,
                            // state: { applicationInfo: data.ext },
                        });
                        // 临时解决方案
                        setTimeout(() => {
                            bus.emit('openThirdApp', data.ext, data);
                        }, 700);
                        break;
                    case SearchType.Contacts:
                        log.debug('handleSearchType contacts11 ==>', type, data);
                        const { userId, teamId, app } = data; // eslint-disable-line
                        history.push(`/messages/s/${userId}?app=${app}&tid=${teamId}&top=true`);
                        break;
                    case SearchType.Group:
                        logger.debug('handleSearchType group ==>', type, data);
                        history.push(`/messages/g/${data.groupId}?top=true`);
                        break;
                    default: {
                        return;
                    }
                }
            } catch (e) {
                log.error('item click error', e);
                return;
            }
            setVisible(false);
            setSearchValue('');
        },
        [appRuntimeEnv.joyspaceHost, history]
    );
    const onClose = useCallback(() => {
        setVisible(false);
        setSearchValue('');
        setActiveKey(SearchType.All);
    }, []);

    const openGlobalSearch = useCallback(() => {
        setVisible(true);
    }, []);
    // useEffect(() => {
    //     focusSDK.on('openGlobalSearch', openGlobalSearch);
    //     return () => {
    //         focusSDK.off('openGlobalSearch', openGlobalSearch);
    //     };
    // }, [focusSDK, openGlobalSearch]);

    return (
        <Modal
            style={{
                borderRadius: 6,
                maxWidth: '768px',
                height: '70vh',
                maxHeight: '600px',
            }}
            destroyOnClose
            transitionName=""
            width="75vw"
            bodyStyle={{
                padding: 0,
                flex: 1,
                display: 'flex',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }}
            closable={false}
            visible={visible}
            footer={null}
            onCancel={onClose}
            wrapClassName="modalAutoFillHeight focus-search-global-modal"
        >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Header
                    onClose={onClose}
                    searchValue={searchValue}
                    onSearchValueChange={(val) => setSearchValue(val)}
                />
                <div
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                        display: 'flex',
                    }}
                >
                    <Tabs
                        authData={authData}
                        keyword={searchValue}
                        onSearchGlobal={onSearchGlobal}
                        // onGetAvatarURL={onGetAvatarURL}
                        config={config}
                        // resultAll={resultAll}
                        activeKey={activeKey}
                        onItemClick={onItemClick}
                        onActiveKeyChange={(key) => {
                            setActiveKey(key);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
}
