import { PageType } from '@/components/Joyspace/const';
import { IContextRender } from '../components/List/ContextMenu';
import { ICategory, IPage } from '../components/List/types';
import { getPermissions, IPermissions, PermissionEnum } from '../permissions';
// 页面控制模块
import addto from './page.addto';
import edit from './page.edit';
import download from './page.download';
import shortcut from './page.shortcut';
import remove from './page.remove';
import removeRecent from './page.remove.recent';
import clone from './page.clone';
import shared from './page.shared';
import pageMove from './page.move';
import rename from './page.rename';
import copy from './page.copy';
import sync from './page.sync';
// import teamCreate from './page.create.team';
// 类目控制模块
// import categoryShortcut from './category.shortcut';
import categoryMove from './category.move';
import categoryRemove from './category.remove';
import categoryRename from './category.rename';
import categorySetname from './category.setname';
import { GlobalContextConfig } from '@/context/GlobalContext';
export { default as api } from '../api';
import openPageQrCode from './page.qrcode';
import collect from './page.collect';
import { SyncStatusEunm } from '../controllers/JoyspaceController';
import { notUseJoySpaceOffline } from '../util';

type IContextParameters<T> = Parameters<IContextRender<T>>;

export type IPageAddCallback = (
    o: IContextParameters<IPage>[1],
    isAllow: boolean,
    isTeam?: boolean,
    globalContext?: GlobalContextConfig,
    currentRouterInfo?: any
) => Parameters<IContextParameters<IPage>[0]> | undefined;

export type ICategoryAddCallback = (
    o: IContextParameters<ICategory>[1],
    isAllow: boolean,
    isTeam: boolean
) => Parameters<IContextParameters<ICategory>[0]> | undefined;

// FOLDER_CREATE("FOLDER_CREATE", "创建文件夹"),
// FOLDER_REMOVE("FOLDER_REMOVE", "删除文件夹"),
// FOLDER_EDIT("FOLDER_EDIT", "修改文件夹"),
// FOLDER_MOVE("FOLDER_MOVE", "文件夹移动"),
// FOLDER_SHARE_CREATE("FOLDER_SHARE_CREATE", "新增协作者"),
// FOLDER_SHARE_REMOVE("FOLDER_SHARE_REMOVE", "删除协作者"),
// FOLDER_PUBLIC_SET("FOLDER_PUBLIC_SET", "设置公开文件夹"),

// // 文件相关 (8)
// PAGE_READ("PAGE_READ", "阅读文档"),
// PAGE_EDIT("PAGE_EDIT", "编辑文档"),
// PAGE_REMOVE("PAGE_REMOVE", "删除文档"),
// PAGE_MOVE("PAGE_MOVE", "文件移动"),
// PAGE_COPY("PAGE_COPY", "复制"),
// PAGE_COPY_CREATE("PAGE_COPY_CREATE", "创建副本"),
// // 文件分享
// SHARE_CREATE("SHARE_CREATE", "新增分享"),
// SHARE_REMOVE("SHARE_REMOVE", "删除分享"),

export enum Names {
    'RECENT' = 'RECENT',
    'SHARES' = 'SHARES',
    'MY_SHARE' = 'MY_SHARE',
    'MY_SPACE' = 'MY_SPACE',
    'TEAM_SPACE' = 'TEAM_SPACE',
    'ZHUANBAN' = 'ZHUANBAN',
}
// 文档离线其它按钮控制，（不包括打开、删除、同步）
function isAllowPageFn(data: any) {
    return notUseJoySpaceOffline() ? true : navigator.onLine && !data.isNew;
}

// 同步按钮控制
function isAllowPageSyncFn(data: any) {
    return notUseJoySpaceOffline() ? true : navigator.onLine;
}

// 打开按钮控制
function isAllowPageOpenFn(data: any) {
    if (notUseJoySpaceOffline()) {
        return true;
    }
    if (
        data.syncStatus === SyncStatusEunm.DATAERROR ||
        data.syncStatus === SyncStatusEunm.PROCESS
    ) {
        return false;
    }
    return true;
}
// 删除按钮控制
function isAllowPageDelFn(data: any) {
    if (notUseJoySpaceOffline()) {
        return true;
    }
    // console.log(data, 'data===>', !navigator.onLine && !data.isNew);
    if (data.isNew && !navigator.onLine && data.syncStatus === SyncStatusEunm.WAITING) {
        return true;
    } else if (
        (data.isNew && data.syncStatus === SyncStatusEunm.WAITING) ||
        data.syncStatus === SyncStatusEunm.PROCESS
    ) {
        return false;
    } else if (!navigator.onLine && !data.isNew) {
        return false;
    }
    return true;
}
function page(
    name?: keyof typeof Names,
    globalContext?: GlobalContextConfig,
    currentRouterInfo?: any
): IContextRender<IPage> {
    // eslint-disable-next-line complexity
    return (addOption, props) => {
        // console.log(props, 'propspropspropspropsprops');
        const option = (cb: IPageAddCallback, isAllow = true) => {
            const res = cb(
                props,
                isAllow,
                name === Names.TEAM_SPACE || name === Names.ZHUANBAN,
                globalContext,
                currentRouterInfo
            );
            res && addOption.apply(null, res);
        };
        const permissions = props.data.permissions || [];
        if (props.data.page_type === PageType.Form) {
            option(edit, isAllowPageOpenFn(props.data));
            // option(shortcut);
            // option(addto);
            if (name === Names.RECENT) {
                option(removeRecent, isAllowPageFn(props.data));
            }
        } else {
            // (name === Names.MY_SPACE || name === Names.TEAM_SPACE || e) &&
            //     option(
            //         edit,
            //         name === Names.MY_SPACE ||
            //             (permissions.includes(PermissionEnum.PAGE_EDIT) &&
            //                 name === Names.TEAM_SPACE)
            //     );
            option(edit, isAllowPageOpenFn(props.data));
            // 分享
            // (name === Names.MY_SPACE || name === Names.TEAM_SPACE) &&
            option(shared as any, isAllowPageFn(props.data));
            option(
                rename,
                (name === Names.MY_SPACE || permissions.includes(PermissionEnum.PAGE_EDIT)) &&
                    isAllowPageFn(props.data)
            );
            option(download, isAllowPageFn(props.data));
            // 收藏
            option(collect as any, isAllowPageFn(props.data));
            option(shortcut, isAllowPageFn(props.data));

            option(
                copy,
                (name === Names.MY_SPACE || permissions.includes(PermissionEnum.PAGE_COPY)) &&
                    isAllowPageFn(props.data)
            );

            (name === Names.MY_SPACE || name === Names.TEAM_SPACE) &&
                option(
                    pageMove,
                    (name === Names.MY_SPACE ||
                        (permissions.includes(PermissionEnum.PAGE_MOVE) &&
                            name === Names.TEAM_SPACE)) &&
                        isAllowPageFn(props.data)
                );
            (name === Names.MY_SPACE || name === Names.TEAM_SPACE) &&
                option(
                    clone,
                    (name === Names.MY_SPACE ||
                        (permissions.includes(PermissionEnum.PAGE_CREATE) &&
                            name === Names.TEAM_SPACE)) &&
                        isAllowPageFn(props.data)
                );

            if (name === Names.RECENT) {
                option(removeRecent, isAllowPageFn(props.data));
            }
            (name === Names.MY_SPACE || name === Names.TEAM_SPACE) &&
                option(
                    remove,
                    (name === Names.MY_SPACE ||
                        (permissions.includes(PermissionEnum.PAGE_REMOVE) &&
                            name === Names.TEAM_SPACE)) &&
                        isAllowPageDelFn(props.data)
                );
            // 只有 同步失败，在显示同步按钮，并且 离线时置灰，在线在可以点击。
            props.data.syncStatus === SyncStatusEunm.ERROR &&
                option(sync, isAllowPageSyncFn(props.data));

            // option(openPageQrCode);
        }
    };
}

// 文档离线其它按钮控制，（不包括打开、删除、同步）
function isAllowCategoryFn(data = null) {
    return navigator.onLine;
}

function category(name?: keyof typeof Names, currentRouterInfo?: any): IContextRender<ICategory> {
    // let permissions: IPermissions = {};
    // // console.log(name, spaceId, 'spaceIdspaceIdspaceId');
    // getPermissions(spaceId).then((data) => {
    //     permissions = data;
    // });
    /* eslint complexity: ["error", 30] */
    // eslint-disable-next-line complexity
    return (addOption, props) => {
        // console.log(addOption, 'addOption', props);
        const option = (cb: ICategoryAddCallback, isAllow = true) => {
            const res = cb(props, isAllow, name === Names.TEAM_SPACE);
            // console.log(res, 'resresresresresresres');
            res && addOption.apply(null, res);
        };
        const permissions = props.data.permissions || [];

        // 分享
        ((name === Names.MY_SPACE && props.data.parent === props.data.space_id) ||
            (name === Names.TEAM_SPACE && !props.data.parent)) &&
            option(
                shared as any,
                (name === Names.MY_SPACE || name === Names.TEAM_SPACE) && isAllowCategoryFn()
            );

        // 设置
        ((name === Names.MY_SPACE && props.data.parent === props.data.space_id) ||
            (name === Names.TEAM_SPACE && !props.data.parent)) &&
            option(
                categorySetname,
                (name === Names.MY_SPACE ||
                    (name === Names.TEAM_SPACE &&
                        permissions.includes(PermissionEnum.FOLDER_EDIT))) &&
                    isAllowCategoryFn()
            );
        // 重命名
        ((name === Names.MY_SPACE && props.data.parent !== props.data.space_id) ||
            (name === Names.TEAM_SPACE && props.data.parent)) &&
            option(
                categoryRename,
                (name === Names.MY_SPACE ||
                    (name === Names.TEAM_SPACE &&
                        permissions.includes(PermissionEnum.FOLDER_EDIT))) &&
                    isAllowCategoryFn()
            );
        // 收藏
        option(collect as any, isAllowCategoryFn());
        option(shortcut as any, isAllowCategoryFn());
        (name === Names.MY_SPACE || name === Names.TEAM_SPACE) &&
            option(
                categoryMove,
                (name === Names.MY_SPACE ||
                    (name === Names.TEAM_SPACE &&
                        permissions.includes(PermissionEnum.FOLDER_MOVE))) &&
                    isAllowCategoryFn()
            );

        (name === Names.MY_SPACE || name === Names.TEAM_SPACE) &&
            option(
                categoryRemove,
                (name === Names.MY_SPACE ||
                    (name === Names.TEAM_SPACE &&
                        permissions.includes(PermissionEnum.FOLDER_REMOVE))) &&
                    isAllowCategoryFn()
            );
    };
}

export default {
    page,
    category,
};
