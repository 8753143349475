import { IPageAddCallback, api } from './index';
import { message } from 'antd';
import { lang } from '../context';
import events from '../context/events';
import { isBjme } from '@/utils';
import { FileType } from '../types';

const shortcut: IPageAddCallback = (props, isAllow) => {
    const { data, setData } = props;

    const title = data.isShortcuted ? '取消置顶' : '置顶';
    let type = data.page_type ? FileType.Page : FileType.Folder;
    const callback = () => {
        message.success(lang('common.success'));
        events.emit('shortcut.change');
        const newData = {
            ...data,
            isShortcuted: !data.isShortcuted,
        };

        events.emit('list.removeOrSetData', [newData], 'setData');
        events.emit('list.reload');
    };
    return [
        {
            children: title,
            splitCode: 1,
            icon: data.isShortcuted ? 'pc_yundoc_retop' : 'pc_yundoc_top',
            callbackOrModal: () => {
                if (data.isShortcuted) {
                    api.page.shortcuts.remove(data.id, type).then(() => {
                        callback();
                    });
                } else {
                    api.page.shortcuts.add(data.id, type).then(() => {
                        callback();
                    });
                }
            },
            isAllow,
            type: 'shortcut',
        },
    ];
};

export default shortcut;
