import React from 'react';
import ImService from '@/server/ImService';
import Bus from '@/utils/bus';
import { createGroup, CreateGroupType } from '@/api/calendar';
import ChatMain from '@/baseComponents/Chat/view/Main';
import calendarDisbandGroupPrompt from '@/components/Calendars/CalendarDisbandGroupPrompt';

type SessionIdType = string;

export interface IDisbandGroupEventProps {
    businessId: string;
    businessType: string;
    callback: Function;
    type?: string;
}

export interface ICalendarImService {
    sessionId: SessionIdType;
    calendarGroupMap: Map<string, string>;

    setSessionId: (id: string) => void;
    setSessionList: (sessionList: any[]) => void;
    clearSessionId: () => void;
    setScheduleId: (id: string) => void;
    addMembers: (members: string[]) => void;
    removeMembers: (members: string[]) => void;
    setLaunchGroupSession: (fn: Function) => void;
    deleteMemberById: () => void;
    disbandGroupEvent: ({ businessId, businessType, callback }: IDisbandGroupEventProps) => void;
    disbandGroupByCalendarId: (calendarId: string) => void;
    createCalendarGroup: (params: CreateGroupType) => any;
    forceCreateCalendarGroup: (params: CreateGroupType) => any;
    joinGroup: (gid: any, userData: any) => any;
    render: () => any;
    bindEvents: () => void;
    offEvents: () => void;
    getGroupInfo: () => any;
    destroy: () => void;
}

export type CalendarImServiceType = Readonly<ICalendarImService | null>;
class CalendarImService implements ICalendarImService {
    private static instance: CalendarImServiceType;
    public static getInstance(sessionList?: any[], launchGroupSession?: Function) {
        if (!this.instance) {
            this.instance = new CalendarImService(sessionList, launchGroupSession);
        }
        return this.instance;
    }
    sessionId: SessionIdType;
    scheduleId: string;
    sessionList: any[] | undefined;
    imService: ImService | null;
    calendarGroupMap: Map<string, string>;
    launchGroupSession: Function | undefined;
    disbandGroupPrompt: Function | undefined;
    private constructor(sessionList?: any[], launchGroupSession?: Function) {
        this.sessionId = '';
        this.scheduleId = '';
        this.sessionList = sessionList;
        this.calendarGroupMap = new Map();
        this.launchGroupSession = launchGroupSession;

        this.imService = ImService.getInstance();
        this.bindEvents();
    }

    setSessionId(id: string | undefined) {
        if (this.sessionId !== id && id) {
            this.sessionId = id;
        }
        this.launchGroupSession?.({ sid: this.sessionId });
    }
    getGroupInfo() {
        if (this.sessionId) {
            return this.imService?.getGroupInfo(this.sessionId);
        } else {
            return null;
        }
    }
    clearSessionId() {
        this.sessionId = '';
    }

    setScheduleId(id: string) {
        this.scheduleId = id;
    }

    setSessionList(sessionList: any[]) {
        this.sessionList = sessionList;
    }

    setLaunchGroupSession(launchGroupSession: Function) {
        this.launchGroupSession = launchGroupSession;
    }

    // setDisbandGroupPrompt(callback: Function) {
    //     this.disbandGroupPrompt = callback;
    // }

    addMembers(members: any[], sessionId?: string) {}

    removeMembers(members: any[], sessionId?: string) {
        const theSessionId = sessionId || this.sessionId;
        if (theSessionId) this.imService?.removeGroupMembers(theSessionId, members);
    }

    deleteMemberById() {}

    disbandGroupByCalendarId(calendarId: string) {}

    disbandGroupEvent = ({ businessId, businessType, callback, type }: IDisbandGroupEventProps) => {
        if (businessType === 'schedule') {
            calendarDisbandGroupPrompt(businessId, callback, type);
        }
    };

    async createCalendarGroup(params: CreateGroupType) {
        try {
            if (!this.sessionId) {
                const result = await createGroup(params);
                return result;
            } else {
                this.launchGroupSession?.({ sid: this.sessionId });
                return [{ groupId: this.sessionId }, null];
            }
        } catch (err) {
            console.error('this is createGroup', err);
        }
    }
    async forceCreateCalendarGroup(params: CreateGroupType) {
        try {
            const result = await createGroup(params);
            if (result[0]) {
                return result;
            }
            // this.sessionId = result.groupId;
            return [{ groupId: this.sessionId }, null];
        } catch (err) {
            console.error('this is createGroup', err);
        }
    }

    async joinGroup(gid: any, userData: any) {
        try {
            const result = await this.imService?.joinGroup(gid, userData);
            return result;
        } catch (err) {
            console.error('joinGroup error:', err);
        }
    }

    render() {
        return <ChatMain hideSetting={true} singleLine={true} />;
    }

    bindEvents = () => {
        // im_special_disband_group_event事件需要全局监听，组件销毁时不能注销im_special_disband_group_event这个事件
        Bus.off('im_special_disband_group_event').on(
            'im_special_disband_group_event',
            this.disbandGroupEvent
        );
    };
    offEvents = () => {
        // Bus.off('im_special_disband_group_event', this.disbandGroupEvent);
    };
    destroy() {
        console.log('触发了-------------->destroy');
        this.sessionId = '';
        this.scheduleId = '';
        this.sessionList = [];
        this.calendarGroupMap = new Map();

        this.imService = null;
        this.offEvents();
        CalendarImService.instance = null;
    }
}

export default CalendarImService;
