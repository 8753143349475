export default {
    canEditStatus: [
        {
            id: 1,
            color: '#59D661',
            text: '正常',
        },
        {
            id: 4,
            color: '#FFB416',
            text: '风险',
        },
        {
            id: 3,
            color: '#EB5B56',
            text: '延期',
        },
        {
            id: 2,
            color: '#8899A4',
            text: '暂停',
        },
    ],
    allStatus: [
        {
            id: 1,
            color: '#59D661',
            text: '正常',
        },
        {
            id: 4,
            color: '#FFB416',
            text: '风险',
        },
        {
            id: 3,
            color: '#EB5B56',
            text: '延期',
        },
        {
            id: 2,
            color: '#8899A4',
            text: '暂停',
        },
        {
            id: 5,
            color: '#8f959e',
            text: '已归档',
        },
    ],
};
