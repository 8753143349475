import React, { useState } from 'react';
import { Upload, Menu, Dropdown, Modal, Collapse, message } from 'antd';
// import FileService from '@jd/jdee-fs-desktop';
import { createUploadTask } from '@/server/FileService';

import { getAppId, getAuthInfo, isFocusEnv, FocusSDK } from '@/utils';
import log from '@/utils/logger';
import './index.less';
import filePng from '@/assets/img/file-icon/bg_file@3x.png';
import { CalendarIcon } from '@/components/Calendars/Common';
// import { attachmentPng, closePng } from '@/assets/img/calendar';
// import { lookup } from 'mime-types';
import {
    attachmentPng,
    closePng,
    localfilePng,
    cloudfilePng,
    uploadingPng,
} from '@/assets/img/calendar';
import { replaceGovUrl, Excel_3xImg, wordImg, Word_3xImg } from '@/utils/joyspace';
import { lookup, contentType } from 'mime-types';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getFileIconByMime, downLoadFile, getFileUrl } from '@/utils/chat/index';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { nanoid } from 'nanoid';
import {
    buildPagePathKey,
    buildJoyspaceIconUrl,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import { PageType } from '@/components/Joyspace/const';
import IconFont from '@/components/icon';
import createDoc, {
    createDocByTemplate,
} from '@/components/Joyspace/components/CreateMenu/createDoc';
import { ICurrentRouteInfo } from '@/components/Joyspace/types';
import { createWPSWord } from '@/components/Joyspace/components/CreateMenu/createWPSWord';
import { createWPSExcel } from '@/components/Joyspace/components/CreateMenu/createWPSExcel';
import bus from '@/utils/bus';
import { TaskResourceBizType } from '@/types/work';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { changeEgovUrl } from '@/utils/tools';
import { duccConfigI18 } from '@/utils/i18n';
import UploadBox from '@/components/UploadBox';

const {
    REACT_APP_FILE_SERVICE_APPKEY,
    REACT_APP_FILE_SERVICE_HOST,
    REACT_APP_JANUS_HOST,
} = process.env;

export enum FileStatus {
    Init = 1,
    Uploading,
    Done,
    failed,
    completed,
}

interface FileModal {
    fileName: string;
    fileId: string;
    fileUrl: string;
    fileType: string;
    fileSize: number;
    extended: string;
}
interface IProps {
    onChange: (p: any) => void;
    defaultList?: any[];
    className?: string;
    // 组通知，日程类型
    titleName?: string;
    mitileType?: boolean;
    LocalFile?: boolean;
}
interface IState {
    fileList: any[];
    joyspaceList: any;
    uploadControlInstance: any;
}

type Props = Readonly<WithTranslation & IProps>;

class Attachment extends React.Component<Props, IState> {
    static contextType = GlobalContext;
    state: IState;
    uploadRef: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            fileList: props.defaultList ? props.defaultList : [],
            joyspaceList: [],
            uploadControlInstance: undefined,
        };
        this.uploadRef = React.createRef<null | any>();
    }
    componentWillUnmount() {
        const { fileList, uploadControlInstance } = this.state;
        fileList.forEach((item: any) => {
            if (item.status === FileStatus.Init || item.status === FileStatus.Uploading) {
                item.taskInstance?.cancel();
            }
        });
        bus.off('joyspace:documentCreated', this.handleCreateDocument);
        if (uploadControlInstance) {
            uploadControlInstance?.off('upload-control-state-event', this.stateListener);
        }
    }
    componentDidMount() {
        bus.off('Calendars:Attachment').on('Calendars:Attachment', (data) => {
            const { fileList } = this.state;
            if (data) {
                fileList.forEach((item: any) => {
                    item.taskInstance?.cancel();
                });
                this.setState({
                    fileList: [],
                });
                if (!this.uploadRef.current) return;
                // this.uploadRef.current?.close();
            }
        });
        bus.on('joyspace:documentCreated', this.handleCreateDocument);
        const attachmentDom = document.getElementById('calendar-attachment-container');
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        attachmentDom?.addEventListener(
            'click',
            function (e) {
                const targetDom: any = e.target;
                if (targetDom?.getAttribute('class')?.indexOf('ant-dropdown-trigger') > -1) {
                    e.stopPropagation();
                }
            },
            true
        );
    }
    componentDidUpdate() {
        const uploadJoyspaceOn = this.uploadRef.current?.getUploadInstance();
        if (!uploadJoyspaceOn?.on) {
            return;
        }
        uploadJoyspaceOn.on('upload-control-state-event', this.stateListener);
        return () => {
            uploadJoyspaceOn.off('upload-control-state-event', this.stateListener);
        };
    }
    downloadFile(item: any) {
        const {
            appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
        } = this.context;
        if (item.status === FileStatus.Uploading) {
            return;
        }
        if (item.fileType.startsWith('joyspace')) {
            const path = buildPagePathKey(Number(item.fileType.split('/')[1])).subPath;
            let newUrl = `${joyspaceHost}/${path}/${item.fileId}`;
            let realUrl = replaceGovUrl(newUrl);
            return DeeplinkEvent.open(realUrl);
        }
        if (isFocusEnv()) {
            FocusSDK.downloadFile(changeEgovUrl(item.fileUrl), {
                saveAs: true,
                filename: item.fileName,
                onStarted: () => {},
                onProgress: () => {},
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                },
                onFinish: (file: { path: string; filename: string }) => {},
            });
        } else {
            // web 端下载文件
            const name = item.fileName;
            const url = getFileUrl(item.fileUrl);
            downLoadFile(name, url);
        }
    }
    handleCreateDocument = (res: any) => {
        const { t } = this.props;
        const { appRuntimeEnv } = this.context as GlobalContextConfig;
        const { joyspaceHost, joyspaceApiHost } = appRuntimeEnv;
        const path = buildPagePathKey(res.page_type).subPath;
        const link = `${joyspaceHost}/${path}/${res.id}`;
        const { title, page_type, type } = res;
        this.addDocumentToFileList([res]);
    };
    addDocumentToFileList = (fileLists: any) => {
        const list = fileLists.map((r: any) => {
            const path = buildPagePathKey(r.pageType).subPath;
            const { appRuntimeEnv } = this.context as GlobalContextConfig;
            const { joyspaceHost } = appRuntimeEnv;
            const { t } = this.props;
            const link = r.pageType === PageType.Form ? r.link : `${joyspaceHost}/${path}/${r.id}`;
            return {
                fileName: r.title || t('unname'),
                size: 0,
                fileSize: 0,
                fileType: `joyspace/${r.pageType || r.page_type}/${r.type}`,
                fileId: r.id,
                fileUrl: `${link}?jdme_router=${encodeURIComponent(r.linkH5)}`,
                percent: 100,
                status: FileStatus.Done,
                taskInstance: null,
                // extended: JSON.stringify(r),
                permission: r.permissionType || null,
            };
        });
        const newList = [...this.state.fileList, ...list];
        this.setState((state) => {
            return {
                fileList: newList,
            };
        });
        this.props.onChange(newList);
    };
    async upload(file: any, fileList: any = []) {
        const { appRuntimeEnv } = this.context as GlobalContextConfig;
        const { fileService, gateway } = appRuntimeEnv;
        const { appKey, hostOrigin } = fileService;
        const { host, stage, protocol } = gateway;
        const authInfo = await getAuthInfo();
        const appId = getAppId();

        // const fs = new FileService({
        //     appKey: appKey || '', // 文件服务appkey
        //     host: hostOrigin,
        //     janus: {
        //         host: `${protocol}://${host}`,
        //         appId: getAppId() || '', // janus网关appid
        //         // @ts-ignore
        //         stage: stage,
        //     },
        //     auth: {
        //         // 认证信息
        //         xToken: authInfo['x-token'], // 用户登录token
        //         xTeamId: authInfo['x-team-id'], // 用户所在teamId
        //         xClient: authInfo['x-client'], // 客户端类型
        //     },
        // });
        const blob: any = file.getAsFile ? file.getAsFile() : file;

        const task = createUploadTask('id', blob, {
            needAuthn: 1, // 是否需要下载健全，可选参数默认需要
            fileName: blob.name, // 文件名称
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream', // 文件类型
            fileSize: blob.size, // 文件大小
        });
        let fileObj: any = {
            fileName: blob.name,
            size: blob.size,
            fileSize: blob.size,
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream',
            fileId: blob.uid || nanoid(),
            fileUrl: '',
            percent: 0,
            status: FileStatus.Init,
            taskInstance: task,
        };

        this.setState((state) => {
            return {
                fileList: [...this.state.fileList, fileObj],
            };
        });
        this.props.onChange(this.state.fileList);
        bus.emit('chat:message-groupnotification', fileObj);
        task?.on('progress', (progress: any) => {
            bus.emit('chat:message-groupnotification', fileObj);
            let percent = progress.progress * 100;
            let idx = this.state.fileList.findIndex((a: any) => a.fileId === fileObj.fileId);
            if (idx >= 0) {
                let newFileList = [
                    ...this.state.fileList.slice(0, idx),
                    { ...fileObj, percent, status: FileStatus.Uploading },
                    ...this.state.fileList.slice(idx + 1),
                ];
                this.setState((state) => {
                    return {
                        fileList: newFileList,
                    };
                });
            }
        });
        task?.on('state', (state: any) => {
            if (state.state === 'completed') {
                // 处理上传完成状态变化
                log.info('上传完成', state);
                log.debug('3', fileObj, state);

                const result = state;
                fileObj.fileUrl = result.downloadUrl;
                let idx = this.state.fileList.findIndex((a: any) => a.fileId === fileObj.fileId);
                let newFileList = [
                    ...this.state.fileList.slice(0, idx),
                    { ...fileObj, percent: 100, status: FileStatus.Done },
                    ...this.state.fileList.slice(idx + 1),
                ];
                bus.emit('chat:message-groupnotification', ...newFileList);
                this.setState((state) => {
                    return {
                        fileList: newFileList,
                    };
                });
                this.props.onChange(newFileList);
            }
            if (state.state === 'failed') {
                // 处理上传失败状态变化
                log.warn('upload img failed');
                let idx = this.state.fileList.findIndex((a) => a.fileId === fileObj.fileId);
                if (idx >= 0) {
                    let newAttachment = [
                        ...this.state.fileList.slice(0, idx),
                        ...this.state.fileList.slice(idx + 1),
                    ];
                    this.setState({
                        fileList: newAttachment,
                    });
                }
            }
        });
        task?.start();
    }
    beforeUpload = (file: any, fileList: any) => {
        this.upload(file, fileList);
        return false;
    };

    deleteItem = (e: any, item: any) => {
        e.stopPropagation();
        const { t } = this.props;
        const { uploadControlInstance } = this.state;
        // if (item.status === FileStatus.Uploading) {
        //     return;
        // }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        Modal.confirm({
            title: t('tip delete'),
            content: t('tip task delete Attachment'),
            okText: t('confirm'),
            cancelText: t('cancel'),
            onOk() {
                let idx = that.state.fileList.findIndex((a) => a.fileId === item.fileId);
                if (idx >= 0) {
                    // 要取消文件上传
                    item.taskInstance?.cancel();
                    let newAttachment = [
                        ...that.state.fileList.slice(0, idx),
                        ...that.state.fileList.slice(idx + 1),
                    ];
                    that.setState({
                        fileList: newAttachment,
                    });
                    that.props.onChange(newAttachment);
                }
                uploadControlInstance?.deleteFile(item.fileId || item.uid);
            },
        });
    };

    stateListener = (stateParams: any) => {
        const { fileId, state, progress, file } = stateParams;
        const { titleName } = this.props;
        if (titleName === '组通知附件') {
            bus.emit('chat:message-groupnotification', progress);
        } else if (titleName === '日程附件') {
            // bus.emit('Calendars:Create', progress);
        }
    };

    upoadJoyBox = (files: any) => {
        let result;
        if (
            Array.from(files).length > 10 ||
            this.state.fileList.length + Array.from(files).length > 10
        ) {
            message.warning('最多上传10个');
            return;
        }
        const uploadJoyspaceOn = this.uploadRef.current?.getUploadInstance();
        if (!uploadJoyspaceOn?.on) {
            return;
        }
        this.setState({
            uploadControlInstance: uploadJoyspaceOn,
        });
        // uploadJoyspaceOn.on('upload-control-state-event', this.stateListener);
        try {
            result = uploadJoyspaceOn.addFiles(Array.from(files));
        } catch (e) {
            if ((e as any).message === 'zeroSize') {
                message.warn('不能上传大小为0的文件');
            }
        }
        // uploadJoyspaceOn.off('upload-control-state-event', this.stateListener);
        return result;
    };

    handleFileClick = () => {
        const { fileList } = this.state;
        const { mitileType, LocalFile } = this.props;
        if (fileList.length >= 10) {
            message.warning('最多上传10个');
            return;
        }
        const input = document.createElement('INPUT');
        input.setAttribute('type', 'file');
        if (mitileType && LocalFile) {
            input.setAttribute('multiple', 'multiple');
        }
        // input.setAttribute('multiple', 'false');
        input.onchange = (ev: any) => {
            if (ev.target.files && ev.target.files.length > 0) {
                let fileArr = Array.from(ev.target.files);
                if (LocalFile) {
                    fileArr = fileArr.filter((file: any) => {
                        if (file.size > 300 * 1024 * 1024) {
                            message.warn('添加失败，最大支持300MB文件');
                            return false;
                        }
                        if (file?.size === 0) {
                            message.warn('不能添加空文件');
                            return false;
                        }
                        return true;
                    });
                    fileArr.length && this.upoadJoyBox(fileArr);
                    return;
                }
                const localfile = ev.target.files[0];
                const blob = localfile.getAsFile ? localfile.getAsFile() : localfile;
                if (blob.size === 0) {
                    return message.error('不能添加空文件');
                }
                if (blob.size > 300 * 1024 * 1024) {
                    return message.error('添加失败，最大支持300MB文件');
                }
                this.upload(localfile);
            }
        };
        input.click();
    };

    handleUploadJoyspace = () => {
        const { focusSDK, appRuntimeEnv } = this.context as GlobalContextConfig;
        const { joyspaceHost } = appRuntimeEnv;
        const { fileList } = this.state;
        const { t } = this.props;
        if (fileList.length === 10) {
            message.warning('最多上传10个');
            return;
        }
        const id = nanoid();
        const callback = ({
            status,
            callbackId,
            results,
        }: {
            status: 'ok' | 'cancel';
            callbackId: string;
            results: {
                id: string;
                title: string;
                pageType: number;
                type: string;
                link: string;
                linkH5: string;
                permissionType: number;
            }[];
        }) => {
            if (id !== callbackId) {
                return;
            }
            focusSDK.off('openJoyspaceDocSelector:result', callback);
            if (status === 'cancel') {
                return;
            }
            if (results.length === 0) {
                return;
            }
            console.log('😄', results);
            this.addDocumentToFileList(results);
        };

        focusSDK.on('openJoyspaceDocSelector:result', callback);
        focusSDK.emit('openJoyspaceDocSelector', { callbackId: id, max: 10 - fileList.length });
    };

    getAttachmentIcon = (item: any) => {
        const {
            appRuntimeEnv: { joyspaceApiHost },
        } = this.context as GlobalContextConfig;
        const { fileType, fileName } = item;
        if (fileType.startsWith('joyspace')) {
            try {
                const strs = fileType.split('/');
                if (strs[1] === '6') {
                    return getFileIconByMime(
                        strs.slice(2).join('/') || 'application/octet-stream'
                    )[0];
                } else {
                    return buildJoyspaceIconUrl({
                        title: fileName,
                        pageType: strs[1],
                        type: strs.slice(2).join('/') || '',
                        apiHost: `${joyspaceApiHost}-api`,
                    });
                }
            } catch (error) {
                return getFileIconByMime(fileType || 'application/octet-stream')[0];
            }
        }
        return getFileIconByMime(fileType || 'application/octet-stream')[0];
    };
    // 上传成功
    uploadSuccessCallBackFunc = (files: any) => {
        const { file, fileId, progress, state } = files;
        let obj = {
            ...file,
            fileId,
            percent: progress,
            status: state,
            fileType: file.type || lookup(file.name) || 'application/octet-stream',
            fileName: file.name,
            fileSize: file.size,
        };
        let idx = this.state.fileList.findIndex((a) => a.fileId === obj.fileId);
        let newFileList = [
            // ...this.state.fileList.slice(0, idx),
            { ...obj, status: FileStatus.Done },
            ...this.state.fileList.slice(idx + 1),
        ];
        this.setState((state) => {
            return {
                fileList: newFileList,
            };
        });
        this.props.onChange(newFileList);
    };
    limitCallBackFunc = () => {
        // 获取正在上传以及等待上传的任务数量
        // @ts-ignore
        const count =
            this.state.fileList.length +
            this.uploadRef.current?.getUploadInstance().getUploadingCount();
        if (count >= 10) {
            message.warning('最多上传10个');
            return false;
        }
        return true;
    };
    // 上传失败
    uploadFailCallBackFunc = (fild: any) => {
        console.log('上传失败', fild);
    };

    render() {
        const { fileList } = this.state;
        const { t, titleName } = this.props;
        const { focusSDK, appRuntimeEnv } = this.context as GlobalContextConfig;
        const currentRouteInfo: ICurrentRouteInfo = {
            categoryId: '',
            spaceId: '',
            teamId: '',
        };
        const disabled = false;
        const createDoc = createDocByTemplate({
            globalContext: this.context as GlobalContextConfig,
        });
        return (
            <div
                id="calendar-attachment-container"
                className="calendar-attachment calendar-attachment-container"
            >
                <Collapse
                    defaultActiveKey="1"
                    expandIconPosition="right"
                    bordered={false}
                    expandIcon={() => (
                        <IconFont
                            type="iconicon_down"
                            className="icon-toggle icon-toggle-attachment"
                        />
                    )}
                    onChange={(aKey) => {
                        let svg = document.getElementsByClassName('icon-toggle-attachment')[0]
                            ?.firstElementChild;
                        if (aKey.length > 0) {
                            svg?.setAttribute('style', 'transform: rotate(-180deg)');
                        } else {
                            svg?.setAttribute('style', 'transform: rotate(0deg)');
                        }
                    }}
                >
                    <Collapse.Panel
                        header={
                            <>
                                <p className="calendar-attachment-title">{t('attachment')}</p>
                                <div
                                    className="calendar-attachment-wrapper"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <Dropdown
                                        trigger={['hover']}
                                        overlay={
                                            <Menu>
                                                <Menu.ItemGroup title={t('select')}>
                                                    <Menu.Item
                                                        onClick={(e) => {
                                                            e.domEvent.stopPropagation();
                                                            this.handleFileClick();
                                                        }}
                                                        // disabled={fileList.length >= 10}
                                                    >
                                                        <div className="doc-menu">
                                                            <img
                                                                className="doc-img"
                                                                src={localfilePng}
                                                            />
                                                            <span>{t('Add Local File')}</span>
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        onClick={(e) => {
                                                            e.domEvent.stopPropagation();
                                                            if (fileList.length >= 10) {
                                                                message.warning('最多上传10个');
                                                                return;
                                                            }
                                                            this.handleUploadJoyspace();
                                                        }}
                                                        // disabled={fileList.length >= 10}
                                                    >
                                                        <div className="doc-menu">
                                                            <img
                                                                className="doc-img"
                                                                src={cloudfilePng}
                                                            />
                                                            <span>
                                                                {t('Add joyspace document', {
                                                                    joyspace:
                                                                        duccConfigI18.joyspace,
                                                                })}
                                                            </span>
                                                        </div>
                                                    </Menu.Item>
                                                </Menu.ItemGroup>
                                                <Menu.ItemGroup
                                                    className="new_create"
                                                    title={t('new create')}
                                                >
                                                    <Menu.Item
                                                        data-name="doc"
                                                        onClick={(e) => {
                                                            e.domEvent.stopPropagation();
                                                            if (fileList.length >= 10) {
                                                                message.warning('最多上传10个');
                                                                return;
                                                            }
                                                            createDoc();
                                                        }}
                                                    >
                                                        <div className="doc-menu">
                                                            <img
                                                                className="doc-img"
                                                                src={changeEgovUrl(wordImg())}
                                                            />
                                                            <span>
                                                                {t('create')}
                                                                {t('joyspace document')}
                                                            </span>
                                                        </div>
                                                    </Menu.Item>
                                                    {
                                                        // 通过ducc配置wpsTarget来控制 joyspaceWpsTarget
                                                    }
                                                    {/* {appRuntimeEnv?.joyspaceWpsTarget ===
                                                        'private' && (
                                                        <Menu.Item
                                                            data-name="wpsdoc"
                                                            onClick={(e) => {
                                                                e.domEvent.stopPropagation();
                                                                if (fileList.length >= 10) {
                                                                    message.warning('最多上传10个');
                                                                    return;
                                                                }
                                                                createWPSWord(appRuntimeEnv);
                                                            }}
                                                        >
                                                            <div className="doc-menu">
                                                                <img
                                                                    className="doc-img"
                                                                    src={changeEgovUrl(
                                                                        Word_3xImg()
                                                                    )}
                                                                />
                                                                <span>
                                                                    {t('create')}
                                                                    {t('WPS document')}
                                                                </span>
                                                            </div>
                                                        </Menu.Item>
                                                    )} */}
                                                    {/* {appRuntimeEnv?.joyspaceWpsTarget ===
                                                        'private' && (
                                                        <Menu.Item
                                                            data-name="wpsspreadsheet"
                                                            onClick={(e) => {
                                                                e.domEvent.stopPropagation();
                                                                if (fileList.length >= 10) {
                                                                    message.warning('最多上传10个');
                                                                    return;
                                                                }
                                                                createWPSExcel(appRuntimeEnv);
                                                            }}
                                                        >
                                                            <div className="doc-menu">
                                                                <img
                                                                    className="doc-img"
                                                                    src={changeEgovUrl(
                                                                        Excel_3xImg()
                                                                    )}
                                                                />
                                                                <span>
                                                                    {t('create')}
                                                                    {t('WPS excel')}
                                                                </span>
                                                            </div>
                                                        </Menu.Item>
                                                    )} */}
                                                </Menu.ItemGroup>
                                            </Menu>
                                        }
                                    >
                                        <div
                                            className={`attachment-inline ${this.props.className}`}
                                        >
                                            {this.props.className && (
                                                <IconFont
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                    className="add_attachments"
                                                    type="iconapp_ic_doc_new"
                                                    style={{ marginRight: 3 }}
                                                />
                                            )}
                                            {t('add_attachment')}
                                        </div>
                                    </Dropdown>
                                </div>
                            </>
                        }
                        key="1"
                    >
                        {fileList.length > 0 && (
                            <div className="custom-upload-list">
                                {fileList.map((item, index) => {
                                    return (
                                        <div
                                            className="file-item"
                                            key={index}
                                            onClick={() => this.downloadFile(item)}
                                            style={{
                                                cursor: `pointer`,
                                            }}
                                        >
                                            {/* <CalendarIcon
                                                className={`left ${
                                                    item.status === FileStatus.Done
                                                        ? ''
                                                        : 'file-icon-loading'
                                                }`}
                                                src={
                                                    item.status === FileStatus.Done
                                                        ? changeEgovUrl(
                                                              this.getAttachmentIcon(item)
                                                          )
                                                        : uploadingPng
                                                }
                                            /> */}
                                            <CalendarIcon
                                                className="left"
                                                src={changeEgovUrl(this.getAttachmentIcon(item))}
                                            />
                                            <span className="name ellipsis">{item.fileName}</span>
                                            {/* <IconFont
                                            type="iconic_pc_calender_download"
                                            onClick={() => this.downloadFile(item)}
                                            className="download-icon"
                                            style={{
                                                width: '14px',
                                                height: '14px',
                                                marginRight: '12px',
                                            }}
                                        /> */}
                                            <IconFont
                                                type="iconic_pc_calender_delete"
                                                onClick={(e) => this.deleteItem(e, item)}
                                                className="delete-icon"
                                                style={{ width: '16px', height: '16px' }}
                                            />
                                            {/* {item.status !== FileStatus.Done && (
                                                <div className="process">
                                                    <div
                                                        style={{
                                                            width:
                                                                item.status === FileStatus.Done
                                                                    ? '100%'
                                                                    : `${item.percent}%`,
                                                        }}
                                                    />
                                                </div>
                                            )} */}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Collapse.Panel>
                </Collapse>
                <UploadBox
                    ref={this.uploadRef}
                    uploadControlName={`${
                        titleName === '日程附件'
                            ? 'joyspace-schedule-task'
                            : 'joyspace-notification-task'
                    }`}
                    toJoysSapce={false}
                    headerTitle={titleName}
                    retryLimitCallBackFunc={this.limitCallBackFunc}
                    uploadSuccessCallBackFunc={this.uploadSuccessCallBackFunc}
                    uploadFailCallBackFunc={this.uploadFailCallBackFunc}
                    className="calendar-hybrid-task"
                />
            </div>
        );
    }
}
export default withTranslation('calendar')(Attachment);
