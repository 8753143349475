/**
 * 会话相关工具类
 */
import { Employee, Group, Session } from '@/types/chat';
import chatConfig from '@/components/chat/config';
import { convertEmployeeToString, employeeToUid } from '@/components/chat/utils/message';
import pick from 'lodash/pick';
import { ListItemData } from '@/components/ListItem';
import isFunction from 'lodash/isFunction';
import { getDeptPositionDesc } from '@/models/user';
import ImService from '@/server/ImService';
import CHAT_NOTICE from '@/contant/chat';

/**
 * 校验是否为AI助手会话
 * @param session
 * @returns boolean
 */
export function isAINoticeSession(session: Session) {
    const { userId } = (session.info as Employee) || {};
    return userId === CHAT_NOTICE.AI_PIN;
}

/**
 * 是否是业务组
 * @param session
 * @returns
 */
export function isBusinessGroup(session: Session) {
    if (!session || !session.isGroup || !session.info) {
        return false;
    }
    const info = session.info as any;
    return !!info.businessId;
}

export function isAINoticeSessionKey(sessionId: string) {
    return sessionId.includes(CHAT_NOTICE.AI_PIN);
}

/**
 * 校验是否为系统通知会话
 * @param session
 * @returns boolean
 */
export function isSYSNoticeSession(session: Session) {
    const { userId } = session.info as Employee;
    return userId === CHAT_NOTICE.SYS_PIN;
}

/**
 * 校验是否为专班通知
 * @param session
 * @returns boolean
 */
export function isAnnNoticeSession(session: Session) {
    const { userId } = session.info as Employee;
    return userId === CHAT_NOTICE.ANNOUNCEMENT_PIN;
}

/**
 * 检验是否为ai助手的子会话
 * @param session
 * @returns boolean
 */
export function isAINoticeChildSession(session: Session) {
    const { userId } = session.info as Employee;
    return (
        session.isNotice &&
        userId !== CHAT_NOTICE.SYS_PIN &&
        userId !== CHAT_NOTICE.ANNOUNCEMENT_PIN &&
        userId !== CHAT_NOTICE.AI_PIN
    );
}

/**
 * 过滤不必要的会话
 * @param session
 */
export function isUnnecessarySession(session: Session) {
    if (session.isSingle) {
        const { userId } = session.info as Employee;
        return chatConfig['chat-session-invisible-list'].includes(userId);
    }
}

// /**
//  * 过滤出通知类的会话列表
//  * @param allSessionList
//  * @returns
//  */
// export function filterNoticeSession(allSessionList: Session[]) {
//     return allSessionList.filter((item) => item.isNotice);
// }

export function getSelectorSessions(
    allSessionList: Session[],
    filter?: (item: Session) => boolean
): ListItemData[] {
    const imService = ImService.getInstance();
    const currentEmployee = imService.getCurrentEmployee();
    return allSessionList.reduce((result: any, item: Session) => {
        if (isFunction(filter) && !filter(item)) {
            return result;
        }
        if (
            item.isGroup ||
            (item.isSingle && !item.isNotice && item.info?.name && !item.isSecret)
        ) {
            const data = {
                id: item.sessionId,
                isGroup: item.isGroup,
                name: item.info.name,
                avatar: item.info.avatar,
                sessionId: item.sessionId,
            };
            if (!data.isGroup) {
                const employee = item.info as Employee;
                Object.assign(data, {
                    id: convertEmployeeToString(employee),
                    sessionId: item.sessionId,
                    desc: getDeptPositionDesc(
                        employee?.e_dept_full_name || employee.deptName || '',
                        employee?.e_position || employee.titleName || '',
                        employee?.userId,
                        currentEmployee
                    ),
                    labelInfoList: employee?.labelInfoList || [],
                    ...employeeToUid(employee),
                });
            } else {
                const group = item.info as Group;
                Object.assign(data, pick(group, ['gid', 'kind', 'subKind', 'business']));
            }
            result.push(data);
        }
        return result;
    }, [] as ListItemData[]);
}

export const mapSessions = getSelectorSessions;
