import IconFont from '@/components/icon';
import { useTranslation } from '@/components/Joyspace/context';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Folder, Page, TabPanelCommonProps } from '../types';
import '../index.less';
import DataList from '../DataList';
import { request as joyspaceRequest } from '@/components/Joyspace/api/request';
import { PAGE_SIZE, transformOriginToPage } from '../utils';
import GlobalContext from '@/context/GlobalContext';
export default function TabPanelCloud(props: TabPanelCommonProps) {
    const t = useTranslation();
    const [activeTab, setActiveTab] = useState('recent');
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [listItems, setListItems] = useState<Array<Page | Folder>>([]);
    const {
        appRuntimeEnv: { joyspaceApiHost },
    } = useContext(GlobalContext);
    const loadFunc = useCallback(
        (params: Record<string, string | number>) => {
            setLoading(true);
            if (activeTab === 'recent') {
                return joyspaceRequest({
                    api: 'joyspace.pages.recent',
                    method: 'GET',
                    path: '/v1/pages/recent',
                    params,
                }).then((info) => {
                    const rs = { total: 0, pages: [] };
                    if (info?.pages) {
                        rs.total = info.total || 0;
                        rs.pages = (info.pages || []).map((page: any) =>
                            transformOriginToPage(page, `${joyspaceApiHost}-api`)
                        );
                    }
                    return rs;
                });
            } else if (activeTab === 'shares') {
                return joyspaceRequest({
                    api: 'joyspace.pages.share',
                    method: 'GET',
                    path: '/v1/pages/shares',
                    params,
                }).then((info) => {
                    const rs = { total: 0, pages: [] };
                    if (info?.shares) {
                        rs.total = info.total || 0;
                        rs.pages = (info.shares || []).map((page: any) =>
                            transformOriginToPage(page, joyspaceApiHost)
                        );
                    }
                    return rs;
                });
            } else if (activeTab === 'myshare') {
                return joyspaceRequest({
                    api: 'joyspace.pages.shareBy',
                    method: 'GET',
                    path: '/v1/pages/share-by-self',
                    params,
                }).then((info) => {
                    const rs = { total: 0, pages: [] };
                    if (info?.pages) {
                        rs.total = info.total || 0;
                        rs.pages = (info.pages || []).map((page: any) =>
                            transformOriginToPage(page, joyspaceApiHost)
                        );
                    }
                    return rs;
                });
            }
            return Promise.resolve({ total: 0, pages: [] });
        },
        [activeTab, joyspaceApiHost]
    );

    const loadMoreFunc = useCallback(() => {
        loadFunc({
            start: listItems.filter((item) => item.type === 'page').length,
            length: PAGE_SIZE,
        }).then((info) => {
            const pages: Page[] = info.pages || [];
            const newItems = listItems.slice();
            for (const page of pages) {
                const index = listItems.findIndex(
                    (item) => item.type === page.type && item.id === page.id
                );
                if (index < 0) {
                    newItems.push(page);
                }
            }
            setListItems(newItems);
            setLoading(false);
            setHasMore(info.pages.length === PAGE_SIZE);
        });
    }, [listItems, loadFunc, setListItems]);

    useEffect(() => {
        loadFunc({ length: PAGE_SIZE }).then((info) => {
            if (info) {
                setListItems(info.pages || []);
                setLoading(false);
                setHasMore(info.pages.length === PAGE_SIZE);
            }
        });
    }, [loadFunc, setActiveTab, setListItems]);

    return (
        <DataList
            title={t(
                activeTab === 'recent'
                    ? 'home.tabs.recent'
                    : activeTab === 'myshare'
                    ? 'home.tabs.myshare'
                    : 'home.tabs.shares'
            )}
            items={listItems}
            onBack={() => {
                setActiveTab('');
                setLoading(true);
                setHasMore(false);
                setListItems([]);
            }}
            hasMore={hasMore}
            loading={loading}
            loadMore={loadMoreFunc}
        />
    );
    // if (activeTab) {
    //     return (
    //         <DataList
    //             title={t(
    //                 activeTab === 'recent'
    //                     ? 'home.tabs.recent'
    //                     : activeTab === 'myshare'
    //                     ? 'home.tabs.myshare'
    //                     : 'home.tabs.shares'
    //             )}
    //             items={listItems}
    //             onBack={() => {
    //                 setActiveTab('');
    //                 setLoading(true);
    //                 setHasMore(false);
    //                 setListItems([]);
    //             }}
    //             hasMore={hasMore}
    //             loading={loading}
    //             loadMore={loadMoreFunc}
    //         />
    //     );
    // }
    // return (
    //     <ul className="joyspace-tabs">
    //         <li className="joyspace-tabs-item" onClick={() => setActiveTab('recent')} key="recent">
    //             <span className="joyspace-tabs-item__label">{t('home.tabs.recent')}</span>
    //             <IconFont className="joyspace-tabs-item__arrow" type="iconapp_ic_arrow_right" />
    //         </li>
    //         <li
    //             className="joyspace-tabs-item"
    //             onClick={() => setActiveTab('myshare')}
    //             key="myshare"
    //         >
    //             <span className="joyspace-tabs-item__label">{t('home.tabs.myshare')}</span>
    //             <IconFont className="joyspace-tabs-item__arrow" type="iconapp_ic_arrow_right" />
    //         </li>
    //         <li className="joyspace-tabs-item" onClick={() => setActiveTab('shares')} key="shares">
    //             <span className="joyspace-tabs-item__label">{t('home.tabs.shares')}</span>
    //             <IconFont className="joyspace-tabs-item__arrow" type="iconapp_ic_arrow_right" />
    //         </li>
    //     </ul>
    // );
}
