import { request } from '@/components/Joyspace/api/request';

export function get(
    id?: string,
    type: 'page' | 'space' | 'team' | 'folder' | 'document' = 'space'
) {
    return request({
        api: 'joyspace.permission.get',
        path: 'v1/permissions',
        method: 'POST',
        body: {
            [type + 'Id']: id,
        },
    });
}

interface MembersType {
    groups: [
        {
            gid: string;
            id: string;
            name: string;
            permission_type: 0;
            share_by: string;
        }
    ];
    orgs: [
        {
            code: string;
            name: string;
            permission_type: 0;
            share_by: string;
        }
    ];
    permissionType: 0;
    total: 0;
    users: [
        {
            avatar_url: string;
            full_org_name: string;
            full_org_path: string;
            id: string;
            is_link: 0;
            mobile: string;
            name: string;
            org_code: string;
            org_name: string;
            permission_type: 0;
            position_name: string;
            share_by: string;
            tenant_id: string;
            user_id: string;
            user_type: 0;
            username: string;
        }
    ];
}

import { joyspacePermissionType } from '../config/permissions.config';
import { OpenFolderStatus, SharesMembers } from '../types';
const handleData = (item: any) => {
    let defaultAvatar = '';
    if (!item.avatar_url) {
        defaultAvatar = item.name ? item.name.substring(0, 1) : item.name.substring(0, 1);
    }
    return {
        avatar: item.avatar_url || '',
        defaultAvatar,
        name: item.name,
        shareId: item.id,
        permissionType: item.permission_type,
        desc: item.position_name || '',
        orgName: item.org_name || '',
    };
};

export enum FileType {
    'Page' = 1,
    'Folder' = 4,
}

export interface ParamesType {
    id: string;
    search?: string;
    type: FileType;
    pageIndex: number;
    pageSize: number;
}

export enum UserType {
    // All = 'All',
    // RecentlyContact = 'recentlyContact',
    Contact = 'contact',
    Department = 'department',
    Groups = 'groups',
}
// 协作人管理
export async function getUserPermissionList(parames: ParamesType) {
    return request({
        api: 'joyspace.share.members',
        path: `v1/shares/members`,
        method: 'POST',
        body: {
            page_id: parames.id,
            search: parames.search || '',
            type: parames.type,
            start: parames.pageIndex || 1,
            length: parames.pageSize || 30,
        },
    })
        .then((res) => {
            let groups =
                (res.groups &&
                    res.groups.map((item: any) => {
                        const _item: any = handleData(item);
                        _item.id = item.gid;
                        _item.type = UserType.Groups;
                        return _item;
                    })) ||
                [];
            let orgs =
                (res.orgs &&
                    res.orgs.map((item: any) => {
                        const _item: any = handleData(item);
                        _item.id = item.code;
                        _item.type = UserType.Department;
                        return _item;
                    })) ||
                [];
            let user =
                (res.users &&
                    res.users.map((item: any) => {
                        const _item: any = handleData(item);
                        _item.id = item.user_id;
                        _item.type = UserType.Contact;
                        return _item;
                    })) ||
                [];
            return groups.concat(orgs, user);
        })
        .catch(() => []);
}

interface PermissionListType {
    permission: joyspacePermissionType;
    shareId: string;
}
// 批量设置协作人管理
export function setBatchPrmission(permissionList: PermissionListType[]) {
    return request({
        api: 'joyspace.share.batchPrmission',
        path: `v1/shares/batchPrmission`,
        method: 'PUT',
        body: permissionList,
    }).catch(() => false);
}
// 单个
export function setPrmission(shareId = '', permission = 2) {
    return request({
        api: 'joyspace.share.batchPrmission',
        path: `v1/shares/${shareId}/permission`,
        method: 'PUT',
        body: { permission },
    }).catch(() => false);
}

// 删除权限
export function removePrmission(shareId: string) {
    return request({
        api: 'joyspace.share.remove',
        path: `v1/shares/${shareId}`,
        method: 'DELETE',
    }).catch(() => false);
}

// shares/members
// 分享和添加权限管理
// 删除权限
export interface GroupsParamesType {
    gid: string;
    permission_type: number | string;
}
export interface GroupsParamesType {
    gid: string;
    permission_type: number | string;
}
export interface OrgsParamesType {
    code: string;
    permission_type: number | string;
}
export interface UserParamesType {
    id: string;
    permission_type: number | string;
}
export interface AddMembersParamesType {
    id: string;
    type: FileType;
    toGroups: GroupsParamesType[];
    toOrgs: OrgsParamesType[];
    toUsers: UserParamesType[];
    toEpssUsers: any;
}
export function addMembers(parames: AddMembersParamesType) {
    return request({
        api: 'joyspace.share.create',
        path: `v1/shares`,
        method: 'POST',
        body: {
            page_id: parames.id,
            type: parames.type,
            to_groups: parames.toGroups,
            to_orgs: parames.toOrgs,
            to_eepaas_users: parames.toEpssUsers,
            to_users: parames.toUsers,
        },
    }).catch(() => false);
}

// 设置公开文件夹
export function setOpenFolder(id: string, open: OpenFolderStatus) {
    return request({
        api: 'joyspace.folder.open',
        path: `v1/folders/${id}/open?open=${open}`,
        method: 'PUT',
    }).catch(() => false);
}

// 获取协同人数量
export function getShareCount(id: string) {
    return request({
        api: 'joyspace.share.shareCount',
        path: `/v1/shares/${id}`,
        method: 'GET',
    }).catch(() => false);
}

// 权限管理列表分组查询
export function sharesMembers(parames: SharesMembers) {
    return request({
        api: 'joyspace.share.sharesMembers',
        path: `/v1/shares/sharesMembers`,
        method: 'POST',
        body: {
            length: parames.length,
            memberType: parames.memberType,
            page_id: parames.pageId,
            search: parames.search,
            start: parames.start,
            type: parames.fileType,
        },
    }).catch(() => false);
}

// 权限管理列表分组查询
export function sharesCount(pageId = '') {
    return request({
        api: 'joyspace.share.sharesCount',
        path: `/v1/shares/${pageId}/sharesCount`,
        method: 'GET',
    }).catch(() => false);
}
