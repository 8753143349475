import BaseService from '@/attributes/BaseService';
import { getUser } from '@/utils';
import PageModel from '../datamodel/PageModel';
class UserService extends BaseService {
    async _getUserInfo() {
        const userInfo = await getUser();
        // console.log(
        //     userInfo,
        //     'userInfouserInfo==>',
        //     `${userInfo.userId}_${userInfo.selectedTeamId}_user`
        // );
        const doc = await this.getFromDB(`${userInfo.userId}_${userInfo.selectedTeamId}_user`);
        console.log(doc, 'docdocdoc');
        return doc?.value;
    }
    setUserInfo(data: any) {
        // console.log(data, 'datadatadatadatadata', {
        //     _id: `${data.user_id}_${data.tenant_id}_user`,
        //     value: data,
        // });
        console.log(data, 'setUserInfo', `${data.user_id}_${data.tenant_id}_user`);
        this.saveToDB({
            _id: `${data.user_id}_${data.tenant_id}_user`,
            value: data,
        });
    }
}
// new UserService('joyspace.db', 'user');
export default UserService;
