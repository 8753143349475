/**
 * 国际化数据源
 * @Description:
 * @author sunzhiguang
 * @date 2020/6/10
 */
import chat_en_us from './chat/en_US.json';
import chat_zh_cn from './chat/zh_CN.json';

import common_en_us from './common/en_US.json';
import common_zh_cn from './common/zh_CN.json';

import calendar_en_us from './calendar/en_US.json';
import calendar_zh_cn from './calendar/zh_CN.json';

import addressList_en from './addressList/en_US.json';
import addressList_zh from './addressList/zh_CN.json';

import joyspace_en_us from './joyspace/en_US.json';
import joyspace_zh_cn from './joyspace/zh_CN.json';

import joinMe_en from './joinMe/en_US.json';
import joinMe_zh from './joinMe/zh_CN.json';
import work_en_us from './work/en_US.json';
import work_zh_cn from './work/zh_CN.json';

import comment_en_us from './comment/en_US.json';
import comment_zh_cn from './comment/zh_CN.json';

import meeting_en_us from './meeting/en_US.json';
import meeting_zh_cn from './meeting/zh_CN.json';

import supervise_zh_cn from './supervise/zh_CN.json';
import supervise_en_US from './supervise/en_US.json';

import project_zh_cn from './project/zh_CN.json';
import project_en_US from './project/en_US.json';

import project_zh_cn_mebj from './project_mebj/zh_CN_mebj.json';
import project_en_US_mebj from './project_mebj/en_US_mebj.json';

import project_zh_cn_mebjJZB from './project_mebjJZB/zh_CN_mebjJZB.json';
import project_en_US_mebjJZB from './project_mebjJZB/en_US_mebjJZB.json';

import project_zh_cn_jgswy from './project_jgswy/zh_CN_jgswy.json';
import project_en_US_jgswy from './project_jgswy/en_US_jgswy.json';

const resources = {
    en: {
        common: common_en_us,
        chat: chat_en_us,
        calendar: calendar_en_us,
        addressList: addressList_en,
        joyspace: joyspace_en_us,
        joinMe: joinMe_en,
        work: work_en_us,
        comment: comment_en_us,
        supervise: supervise_en_US,
        project: project_en_US,
        project_mebj: project_en_US_mebj,
        project_mebjJZB: project_en_US_mebjJZB,
        project_jgswy: project_en_US_jgswy,
        meeting: meeting_en_us,
    },
    zh: {
        common: common_zh_cn,
        chat: chat_zh_cn,
        calendar: calendar_zh_cn,
        addressList: addressList_zh,
        joyspace: joyspace_zh_cn,
        joinMe: joinMe_zh,
        work: work_zh_cn,
        comment: comment_zh_cn,
        supervise: supervise_zh_cn,
        project: project_zh_cn,
        project_mebj: project_zh_cn_mebj,
        project_mebjJZB: project_zh_cn_mebjJZB,
        project_jgswy: project_zh_cn_jgswy,
        meeting: meeting_zh_cn,
    },
};

export default resources;
