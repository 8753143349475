/**
 * 用户部门岗位
 */
import React, { useMemo, useContext } from 'react';
import { Tooltip } from 'antd';
import { SplitLine } from './common';
import { TitleDeptInfo } from '@/types/UserCard';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';

export default function UserDeptTitle(props: {
    t: any;
    mainTitleDeptInfo: TitleDeptInfo;
    partTimeTitleDeptInfo: TitleDeptInfo[];
}) {
    const { mainTitleDeptInfo, partTimeTitleDeptInfo, t } = props;
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const isGovEnv = ['mebj', 'jgswy', 'mebjJZB'].includes(appRuntimeEnv.runtimeConfig.key);
    // const list = useMemo(() => {
    //     // return [mainTitleDeptInfo].concat(partTimeTitleDeptInfo || []).filter((item) => {
    //     //     return item.deptName && item.titleName;
    //     // });
    //     return (partTimeTitleDeptInfo || []).filter((item) => {
    //         // return item.deptName && item.titleName;
    //         // 查看外部联系人卡片只有单位和职务
    //         return item?.fullDeptName && item.titleName;
    //     });
    // }, [partTimeTitleDeptInfo]);
    return (
        <div>
            {partTimeTitleDeptInfo?.map((item, index) => {
                return (
                    <div className="me-user-card-item" key={`${item.deptId}-${item.titleId}`}>
                        {index > 0 && <SplitLine />}
                        {/* 1.9.0去掉单位 fullDeptName表示部门全链路信息，是外部联系人时：fullDetpName表示单位； 管理后台去掉主兼岗 */}
                        {/* {item.unitName && (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title">{t('userCard.unitName')}</h5>
                                <p className="usercard-item-desc">{item.unitName}</p>
                            </div>
                        )}
                        {item.deptName && (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title inner">
                                    {t('userCard.dept')}
                                    {!item.major && <div className="dept-item-j">兼</div>}
                                </h5>
                                <p className="usercard-item-desc">{item.deptName}</p>
                            </div>
                        )} */}
                        {item?.fullDeptName && (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title inner">
                                    {t('userCard.dept')}
                                    {/* {!item.major && <div className="dept-item-j">兼</div>} */}
                                </h5>
                                <p className="usercard-item-desc">{item?.fullDeptName}</p>
                            </div>
                        )}
                        {item?.titleName && (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title inner">{t('userCard.title')}</h5>
                                <p className="usercard-item-desc">{item.titleName}</p>
                            </div>
                        )}
                        {item?.telephone && (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title inner">
                                    {t('userCard.landline')}
                                </h5>
                                <p className="usercard-item-desc">{item.telephone}</p>
                            </div>
                        )}
                        {/* {isGovEnv && item.titleDutyName && (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title inner">职责</h5>
                                <p className="usercard-item-desc">{item.titleDutyName}</p>
                            </div>
                        )} */}
                    </div>
                );
            })}
        </div>
    );
}
