import { FocusSDK } from '@/utils';
import { changeEgovUrl } from './tools';

/**
 * 替换地址适配gov地址
 * @param url  要替换的地址
 * @param FocusSDK  获取环境的sdk
 * @returns url
 */
export const replaceGovUrl = (url: string) => {
    let newUrl = changeEgovUrl(url);
    return newUrl;
};

export const wordImg = () => {
    const REACT_APP_OSS_BASE_URL = FocusSDK?.getApplicationInfo()?.ossBaseUrl;
    return `${REACT_APP_OSS_BASE_URL}/statics/images/joyspace/word.png`;
};
export const Word_3xImg = () => {
    const REACT_APP_OSS_BASE_URL = FocusSDK?.getApplicationInfo()?.ossBaseUrl;
    return `${REACT_APP_OSS_BASE_URL}/statics/images/joyspace/Word_3x.png`;
};
export const Excel_3xImg = () => {
    const REACT_APP_OSS_BASE_URL = FocusSDK?.getApplicationInfo()?.ossBaseUrl;
    return `${REACT_APP_OSS_BASE_URL}/statics/images/joyspace/Excel_3x.png`;
};
