import React, { useState, Dispatch, SetStateAction, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { IprojectListType, Project } from '../../types';
import BasicSetting from './BasicSetting';
import ZhuanbanOperate from './ZhuanbanOperate';
import Member from '../../components/Member';
import context from '../../context';
import './index.less';

interface IProps {
    isOpenSettingModal: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
    project: IprojectListType;
    reloadProjectList: () => void;
    activeTab?: number;
}

export default function (props: IProps) {
    const { onClose, isOpenSettingModal, project, reloadProjectList, activeTab } = props;
    const [currentTab, setCurrentTab] = useState<number>(activeTab ?? 1);
    const { t } = useContext(context);
    const tabs = [
        t('basic_setting.title'),
        t('basic_setting.member_supervise'),
        t('basic_setting.operate'),
    ];

    useEffect(() => {
        setCurrentTab(activeTab ?? 1);
    }, [activeTab]);

    const memberProject: Project = {
        ...project,
        id: project?.projectId || '',
        archive: project?.archive || false,
    };

    return (
        <Modal
            title={t('list.setting')}
            destroyOnClose
            visible={isOpenSettingModal}
            footer={null}
            width="800px"
            bodyStyle={{ padding: 0 }}
            onCancel={() => onClose(false)}
        >
            <div className="pm-project-setting">
                <div className="pm-project-setting-left">
                    <ul>
                        {tabs.map((item, index) => (
                            <li
                                key={item}
                                data-current-tab={index === currentTab}
                                onClick={() => setCurrentTab(index)}
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="pm-project-setting-right">
                    {currentTab === 0 && (
                        <BasicSetting
                            project={project}
                            onClose={onClose}
                            reloadProjectList={reloadProjectList}
                        />
                    )}
                    {currentTab === 1 && (
                        <Member
                            project={memberProject}
                            isZhuanbanSetting={true}
                            onClose={onClose}
                        />
                    )}
                    {currentTab === 2 && (
                        <ZhuanbanOperate
                            project={project}
                            onClose={onClose}
                            reloadProjectList={reloadProjectList}
                            setCurrentTab={setCurrentTab}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
}
