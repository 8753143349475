import React from 'react';
import { IconKeep, IconCancelKeep } from '@/components/icon';
import './index.less';

interface ReteType {
    total?: number; // star 总数
    value?: number; // 设置星的指数
    style?: any;
}

export default function Rete(props: ReteType) {
    const { total = 5, value = 0 } = props;
    const CreaterKeepIcon = () => {
        const keepList = [];
        for (let i = 1; i <= total; i++) {
            keepList.push(<span key={i}>{i <= value ? IconKeep : IconCancelKeep}</span>);
        }
        return keepList;
    };

    return value > 0 ? (
        <div className="rate" style={props.style}>
            推荐{CreaterKeepIcon()}
        </div>
    ) : (
        <div className="rate" style={{ width: 'auto', ...props.style }}>
            推荐
        </div>
    );
}
