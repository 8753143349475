import { message, Modal } from 'antd';
import { ICategoryAddCallback, api } from './index';
import { lang } from '../context';
import events from '../context/events';
const Remove: ICategoryAddCallback = (props, isAllow, isTeam?: boolean) => {
    const { data, remove } = props;
    return [
        {
            children: lang('common.delete'),
            icon: 'pc_yundoc_recycle',
            splitCode: 3,
            callbackOrModal: () => {
                Modal.confirm({
                    title: `是否确认删除"${data.name ? data.name : '无标题'}" 文件夹？`,
                    content: '删除的文件夹可在回收站找回',
                    cancelText: lang('common.cancel'),
                    okText: lang('common.confirm'),
                    onOk: () => {
                        api.categories
                            .remove(props.data.id, isTeam)
                            .then((data1) => {
                                console.log(data1, 'data1data1data1');
                                if (data1) {
                                    events.emit('list.reload');
                                    events.emit('list.removeOrSetData', [data], 'delete');

                                    events.emit('deleteFolderById', props.data.id);
                                    events.emit('shortcut.remove', [props.data.id]);
                                    message.success(lang('common.success'));
                                } else {
                                    message.error(lang('common.error'));
                                }
                            })
                            .catch((err) => {
                                console.log(err, 'errerrerrerr');
                                message.error(lang('common.fail'));
                            });
                        // deleteFolderById(props.data.id)
                        //     .then((data: any) => {
                        //         if (data) {
                        //             message.success(lang('common.success'));
                        //             props.reload();
                        //         } else {
                        //             message.error(lang('common.error'));
                        //         }
                        //     })
                        //     .catch((err: any) => {
                        //         console.log('err', err);
                        //         message.error(lang('common.error'));
                        //     });
                    },
                });
            },
            type: 'delete',
            isAllow,
        },
    ];
};
export default Remove;
