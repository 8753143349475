/* eslint-disable array-callback-return */
// 群接龙卡片
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import './index.less';
import { Card, Button } from 'antd';
import { ChatMessage, Session } from '@/types/chat';
import IconFont from '@/components/icon';
import ChatState from '@/types/chat/State';
import solitaireMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/message/solitaire';
import { connect } from 'dva';
import { isBannedPostGroupSession } from '@/components/chat/utils/message';
import Context from '@/components/chat/context';
import ImService from '@/server/ImService';

interface MessageOperateProps {
    message: solitaireMessageBody & ChatMessage;
    selectedSession: Session;
    updateSessionMessageMid: Function;
    openSolitaireDetails: (data: { isvisibles: boolean; solitaire: any }) => void;
}
type IProps = Readonly<MessageOperateProps & ChatState>;

function SolitaireCard(props: IProps) {
    const {
        message,
        selectedSession,
        openSolitaireDetails,
        currentEmployee,
        updateSessionMessageMid,
    } = props;
    const { groupRosterIdentity } = React.useContext(Context);
    const [disabled, setDisabled] = useState(false);
    const [userInfo, setUserInfo] = useState<any>();
    const [notification, setNotification] = useState<any[]>([]);
    const instance = ImService.getInstance();
    const newDate = new Date().getTime();

    // const userList = useMemo(() => {
    //     if ((message?.userList as any).length === 0 || !message?.userList) return [];
    //     return message?.userList as any;
    // }, [message?.userList]);

    // useEffect(() => {
    //     // 查询缓存
    //     instance.getSolitaireMessageExt(message.sessionId, message.mid).then((data) => {
    //         const msgContent = data?.msgContent || [];
    //         let msgcontent: any = msgContent;
    //         let users: any = [];
    //         if (msgcontent.length > 0 || userList.length > 0) {
    //             if (msgcontent.length > 0) {
    //                 users = users.concat(msgcontent[0].users);
    //             }
    //             if (userList.length > 0) {
    //                 users = users.concat(userList[0]);
    //             }
    //             setNotification(
    //                 users.filter((item: any) => {
    //                     return item.action === 0;
    //                 })
    //             );
    //         }
    //         if (users.length === 0) {
    //             setDisabled(false);
    //         }
    //         // eslint-disable-next-line max-nested-callbacks
    //         users.filter((item1: any) => {
    //             if (
    //                 item1.pin === currentEmployee.userId &&
    //                 item1.action === 0 &&
    //                 item1.teamId === currentEmployee.teamId
    //             ) {
    //                 setUserInfo(item1);
    //                 setDisabled(true);
    //                 // setshowUser(true);
    //             }
    //             // else {
    //             //     setshowUser(false);
    //             // }
    //         });
    //     });
    // }, [message, currentEmployee, instance, userList]);

    // 打开组接龙
    const handleSolitaireClick = useCallback(async () => {
        openSolitaireDetails({
            isvisibles: true,
            solitaire: message.solitaireId,
        });
    }, [message, openSolitaireDetails]);

    // const undateMessageExtListener = useCallback(() => {
    //     const instance = ImService.getInstance();
    //     instance.getSolitaireMessageExt(message.sessionId, message.mid).then((data) => {
    //         const msgContent = data?.msgContent || [];
    //         let msgcontent: any = msgContent;
    //         let users: any = [];
    //         console.log('响应1', msgcontent, userList);
    //         // msgcontent = msgcontent.concat(userList);
    //         if (msgcontent.length > 0 || userList.length > 0) {
    //             if (msgcontent.length > 0) {
    //                 users = users.concat(msgcontent[0].users);
    //             }
    //             if (userList.length > 0) {
    //                 users = users.concat(userList[0]);
    //             }
    //             setNotification(
    //                 users.filter((item: any) => {
    //                     return item?.action === 0;
    //                 })
    //             );
    //         }
    //         if (users.length === 0) {
    //             setDisabled(false);
    //         }
    //         // eslint-disable-next-line max-nested-callbacks
    //         users.map((item1: any) => {
    //             if (!item1 || item1?.action === 1) {
    //                 setDisabled(false);
    //             }
    //             if (
    //                 (item1?.pin === currentEmployee.userId && item1?.action === 0) ||
    //                 (item1?.pin === currentEmployee.userId &&
    //                     item1?.teamId === currentEmployee.teamId)
    //             ) {
    //                 setUserInfo(item1);
    //                 setDisabled(true);
    //             } else {
    //                 // setDisabled(false);
    //             }
    //         });
    //     });
    // }, [message, currentEmployee, userList]);

    // useEffect(() => {
    //     const instance = ImService.getInstance();
    //     const manager = instance.getChatManager();
    //     if (manager) {
    //         manager.on(
    //             `chat:message-solitairecard:${message.sessionId}:${message.mid}`,
    //             undateMessageExtListener
    //         );
    //         return () => {
    //             manager.off(
    //                 `chat:message-solitairecard:${message.sessionId}:${message.mid}`,
    //                 undateMessageExtListener
    //             );
    //         };
    //     }
    // }, [message, undateMessageExtListener]);

    return (
        <div className="SolitaireCard">
            {message && (
                <Card
                    title={
                        <div className="titleIcon">
                            <div className="titleIcon_icon">
                                <IconFont
                                    type="iconic_app_btn_groupsolitaire1"
                                    className="titleIcon-icon-small-icon"
                                />
                                <span
                                    style={{ fontSize: '14px', color: '#333333', fontWeight: 700 }}
                                >
                                    组接龙
                                </span>
                            </div>
                            <div style={{ display: 'flex', height: '24px', lineHeight: '23px' }}>
                                <span style={{ width: '160px' }}>{message.nickName}</span>
                                <span>发布</span>
                            </div>
                        </div>
                    }
                    bordered={false}
                    style={{ width: 300 }}
                    // eslint-disable-next-line react/jsx-key
                    actions={[
                        <Button
                            className="connect"
                            disabled={disabled === true ? true : false}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (
                                    isBannedPostGroupSession(selectedSession) &&
                                    groupRosterIdentity === 'ordinary'
                                ) {
                                    return false;
                                }
                                handleSolitaireClick();
                            }}
                            key=""
                        >
                            立即接龙
                        </Button>,
                    ]}
                >
                    <div>
                        <p
                            onClick={(e) => {
                                e.stopPropagation();
                                if (
                                    isBannedPostGroupSession(selectedSession) &&
                                    groupRosterIdentity === 'ordinary'
                                ) {
                                    return false;
                                }
                                handleSolitaireClick();
                            }}
                        >
                            {message.title}
                        </p>
                        {/* {notification.length > 0 ? (
                            <div className="solitaireName">
                                <div
                                    // style={{
                                    //     // width: notification?.length >= 3 ? '208px' : 'auto',
                                    //     maxWidth: '233px',
                                    // }}
                                    className="solitaireName_nam"
                                >
                                    {notification.map((item: any, index: any) => {
                                        if (index > 5) return;
                                        return (
                                            <div key={index} style={{ display: 'inline' }}>
                                                <span>{item.name}</span>
                                                {notification?.length === 1 ||
                                                notification[notification?.length - 1] === item ||
                                                index >= 5
                                                    ? ''
                                                    : '、'}
                                            </div>
                                        );
                                    })}
                                    <span>
                                        {notification?.length > 5 && `等${notification?.length}人`}
                                        {notification?.length > 0 && '已参与'}
                                    </span>
                                </div>
                            </div>
                        ) : null} */}
                    </div>
                </Card>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { sessionMessageMap, sessionMessages, messages, selectedSession, currentEmployee } = chat;
    return {
        sessionMessageMap,
        sessionMessages,
        selectedSession,
        currentEmployee,
        messages,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        openSolitaireDetails(data: { isvisibles: boolean; solitaire: any }) {
            dispatch({ type: 'chat/openSolitaireDetails', payload: data });
        },
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SolitaireCard);
