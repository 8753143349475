import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import Bus from '@/utils/bus';
import config, { ConfigEnum } from '@/config/config';
import { message as toast } from 'antd';
import { joinCloudLink } from '@/components/MeetingCloudLink/utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { AVSDK } from '@/baseComponents/Meeting/common';
import { joinMeeting } from '@/baseComponents/Meeting/utils';

export const avsdkNameMap = {
    [AVSDK.HUAWEI]: '信创视频会议',
    [AVSDK.JBRTC]: '智能会议',
    [AVSDK.XYLINK]: '政务视频会议',
    [AVSDK.ZOOM]: '视频会议',
};

export function NoticeMeetingCard({ message: data, t }: { message: any; t: any }) {
    const [message, setMessage] = React.useState(data);
    const [avsdk, setAvsdk] = React.useState(AVSDK.JBRTC);
    const { ext = {}, infox, content, title } = message;
    const { authInfo } = useContext<GlobalContextConfig>(GlobalContext);

    const { sessionState } = ext || {};
    const isCancel = sessionState === 1;
    const messageChangedCb = React.useCallback(
        (newMessage: any) => {
            if (!newMessage.ext) {
                return;
            }
            if (newMessage.ext.meetingNumber !== message.ext.meetingNumber) {
                return;
            }
            const avsdk = message.ext.avsdk as AVSDK;
            setAvsdk(avsdk);
            setMessage(newMessage);
        },
        [message]
    );
    React.useEffect(() => {
        Bus.on('chat:message-change', messageChangedCb);
        return () => {
            Bus.off('chat:message-change', messageChangedCb);
        };
    }, [messageChangedCb]);
    return (
        <div className="notice-card-joymeeting">
            <div
                className={['notice-card-joymeeting-title', isCancel ? 'meeting-close' : ''].join(
                    ' '
                )}
            >
                <div className="title-title">
                    [{avsdkNameMap[avsdk]}]{title}
                </div>
                <div>{content}</div>
            </div>
            <div className="notice-card-action">
                <div
                    className={['notice-card-action-view', isCancel ? 'meeting-close' : ''].join(
                        ' '
                    )}
                    onClick={() => {
                        if (isCancel) {
                            return;
                        }
                        joinMeeting(avsdk, {
                            meetingNumber: infox.meetingNumber,
                            meetingPassword: infox.meetingPassword,
                        }).catch((err) => {
                            toast.error(err.message || err);
                        });
                    }}
                >
                    {t(`joyMeeting.${isCancel ? 'meetingCancel' : 'joinMeeting'}`)}
                </div>
            </div>
        </div>
    );
}

export function SysMeetingCard({ message: data }: { message: any; t: any }) {
    const [message, setMessage] = React.useState(data);
    const avsdk = message.ext.avsdk as AVSDK;

    return (
        <div className="text-message">
            <span>{`[${avsdkNameMap[avsdk]}] ${message.content}`}</span>
        </div>
    );
}
