/**
 * joy space 分享文档, 文件入品
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React from 'react';
import { JoySpaceDocumentMessage, SessionType } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import { WithTranslation, withTranslation } from 'react-i18next';
import ShareJdoc from '@/components/chat/message/share/Jdoc';
import ShareJFile from '@/components/chat/message/share/JFile';
import UnSupport from '@/components/chat/message/UnSupport';
import CommonCard from './CommonCard';
import ShareUserCard from '@/components/chat/message/share/UserCard';
import MergeForward from './MergeForward';
import JoyspaceFile from '../message-components/JoyspaceFile';
import Text from '../Text';

interface ShareProps {
    message: JoySpaceDocumentMessage;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
    count: number;
}

type IShareProps = Readonly<ShareProps & WithTranslation>;

export default function Share(props: IShareProps) {
    const { message, count } = props;
    const sharelink =
        message.data && message.data[0] ? message.data[0].sharelink : { category: '', url: '' };
    const { template } = message;
    if (template.nativeId === 'merge_forward') {
        if (typeof count === 'undefined' || (typeof count === 'number' && count < 2)) {
            return <MergeForward {...props} />;
        } else {
            return <UnSupport message={message} />;
        }
    }
    // template: {nativeId: "share_link"}  merge_forward
    if (template.nativeId === 'share_link') {
        if (sharelink?.category === 'jd_doc') {
            return <ShareJdoc {...props} />;
        } else {
            return <CommonCard {...props} />;
        }
    } else if (template.nativeId === 'share_card') {
        // 用户卡片
        return <ShareUserCard {...props} />;
    } else if (template.nativeId === 'voip_hint') {
        // 语音电话
        const _message = { ...props.message, content: (props.message as any).text };
        const _props = { ...props, message: _message };
        return <Text {..._props} />;
    } else {
        return <UnSupport message={message} />;
    }
}
