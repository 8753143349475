import React, { useState } from 'react';
import { TabKeyType } from '../../config';
import { changeEgovUrl } from '@/utils/tools';
import './index.less';
import IconFont from '@/components/icon';
import { SharesMembersType } from '@/components/Joyspace/types';
interface IProps {
    avatar: string;
    name: string;
    type: TabKeyType | SharesMembersType;
}
export const IconDefualtOrg = (
    <div
        style={{
            color: '#fff',
            backgroundColor: (window as any).styleSass.primaryColor,
            borderRadius: 8,
            height: 32,
            width: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <IconFont type="iconapp_btn_cha" />
    </div>
);
export default function Avatar(props: IProps) {
    const { avatar = '', name = '', type } = props;
    const text = name ? name.substring(0, 1) : '';
    return (
        <div>
            {avatar ? (
                <img className="avatar-img" src={changeEgovUrl(avatar)} />
            ) : type === TabKeyType.Department || type === SharesMembersType.DEPARTMENT ? (
                IconDefualtOrg
            ) : (
                <span className="avatar-img">{text}</span>
            )}
        </div>
    );
}
