import React from 'react';
import { Input, message } from 'antd';
import Icon from '../../Icon';
import * as api from '../../api';
import { IMember, Project, ProjectRole } from '../../types';

interface Props {
    value: string;
    project: Project;
    member: IMember;
}

export default function (props: Props) {
    const { project, member } = props;
    const [edit, setEdit] = React.useState(false);
    const [value, setValue] = React.useState(props.value);
    return (
        <div className="pm-mm-list-title">
            {!edit || project.archive ? (
                <>
                    <span>{value}</span>
                    {(project.userInfo?.userRole === ProjectRole.ADMIN ||
                        project.userInfo?.userRole === ProjectRole.OWNER) &&
                        !project.archive && (
                            <Icon type="iconjs_ic_edit" onClick={() => setEdit(true)} />
                        )}
                </>
            ) : (
                <Input
                    autoFocus={true}
                    type="text"
                    onBlur={() => {
                        setEdit(false);
                        const _value = value?.replace(/^\s+|\s+$/, '');
                        if (_value !== props.value) {
                            api.updateUser(project.id, {
                                userId: member.userId,
                                teamId: member.teamId,
                                app: member.app,
                                duty: _value || '',
                            }).then((data) => {
                                if (data.errCode) return;
                                message.success('操作成功');
                            });
                        }
                    }}
                    maxLength={20}
                    defaultValue={props.value}
                    onChange={(e) => setValue(e.target.value)}
                />
            )}
        </div>
    );
}
