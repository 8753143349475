import { scanCodeInstance } from '@/server/scanCode';

export async function getQRCode(opts: { polling: boolean }) {
    const data = {
        url: `api/general/get/qrcode`,
        clientId: '824cd7bf40af4a2892e11d781533208f',
        polling: opts.polling,
    };
    return await scanCodeInstance.request(data);
}

export async function pollingQrcode(opts: { polling: boolean; pollingUrl: string }) {
    try {
        const data = {
            url: opts.pollingUrl,
            polling: opts.polling,
            clientId: '824cd7bf40af4a2892e11d781533208f',
        };
        return await scanCodeInstance.request(data);
    } catch (error) {
        return error;
    }
}
