import React, { useMemo, useState, useContext } from 'react';
import './index.less';
import { connect } from 'dva';
import { UserCardPayload } from '@/models';
import { FocusSDK, isFocusEnv } from '@/utils';
import { Tooltip } from 'antd';
import IconFont from '@/components/icon';
import { openUserSelectionModal } from '@/utils/modals';
import { useTranslation } from 'react-i18next';
import { WorkUserInfo } from '@/types/work';
import UserStatusListPopover from '../../UserStatusListPopover';
import { UserCardType } from '@/types/UserCard';
import ChatState from '@/types/chat/State';
import Employee from '@jd/jdee.im.sdk/lib/es/model/Employee';
import Bus from '@/utils/bus';
import { Avatar } from '@/baseComponents/Avatar';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import lodashCompact from 'lodash/compact';
import { changeEgovUrl } from '@/utils/tools';
import config, { ConfigEnum } from '@/config/config';
import Terminal from '@/components/MeetingCloudLink/Invites/Terminals';
import NPCList from '@/components/MeetingCloudLink/Invites/NPC';
import { MeetingPlatform } from '@/types/common';
import WorkUserListWeight from '../WorkUserListWeightSet';
import cookies from 'js-cookie';

let npcData = {};
let terminalData: any = [];

function mapStateToProps({ user, chat }: any) {
    const { currentEmployee } = chat as ChatState;

    return {
        userData: user.userData ? user.userData.user : {},
        currentEmployee,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
    };
}

export function UserItem({
    item,
    onClick,
    isShowName,
    notAvatar,
    notTooltip,
}: {
    item: WorkUserInfo;
    onClick?: (item: WorkUserInfo) => void;
    isShowName?: boolean;
    notAvatar?: boolean;
    notTooltip?: boolean;
}) {
    return (
        <Tooltip title={notTooltip ? '' : item.realName}>
            <div
                className={`work-userlist-item${notAvatar ? ' only-show-name' : ''}`}
                onClick={() => onClick?.(item)}
            >
                {/* {item.headImg ? (
                    <img src={changeEgovUrl(item.headImg || '')} className="avatar" />
                ) : (
                    <div className="avatar">{(item.realName || '').slice(0, 1)}</div>
                    )} */}
                {notAvatar ? null : (
                    <Avatar
                        src={changeEgovUrl(getHeadImgThumbUrl(item.headImg, 150, 150))}
                        styleObj={{
                            width: '32px',
                            height: '32px',
                            borderRadius: 6,
                            cursor: 'pointer',
                        }}
                        className="avatar"
                        name={item.realName || ''}
                    />
                )}
                {isShowName ? (
                    <span className="work-userlist-item-realname">{item.realName}</span>
                ) : (
                    ''
                )}
            </div>
        </Tooltip>
    );
}

function WorkUserList({
    owner,
    list = [],
    toggleUserCardModal,
    userData,
    maxLength,
    disable,
    handleAddTaskMembers,
    onRemove,
    addMemberDisable,
    currentEmployee,
    addMemberCb,
    projectId,
    title,
    isGroup,
    sessionId,
    label,
    userPopover,
    platform,
    isShowName,
    notAvatar = false,
    hasWeightSet = false,
    mode,
    notTooltip = false,
}: {
    owner: WorkUserInfo;
    platform?: string;
    mode?: string;
    list?: WorkUserInfo[];
    toggleUserCardModal: (payload: UserCardPayload) => void;
    userData: any;
    maxLength: number;
    disable?: boolean;
    handleAddTaskMembers: (members: any, custom?: any) => void;
    onRemove: (user: any[]) => void;
    addMemberDisable?: boolean;
    currentEmployee: Employee;
    addMemberCb?: Function; // 如果有，则添加成员时走此函数
    projectId?: string;
    title: string; // 智能推荐需要用到
    isGroup?: boolean;
    sessionId?: string;
    label?: string; // 区分日程参与人
    userPopover?: string;
    isShowName?: boolean;
    notAvatar?: boolean;
    hasWeightSet?: boolean;
    notTooltip?: boolean;
}) {
    list.forEach((item: any) => {
        item.app = cookies.get('focus-app-id') || process.env.REACT_APP_DD_APPID;
    });
    const [visibleOfWeight, setVisibleOfWeight] = useState(false);
    const [weightModalConfig, setWeightModalConfig] = useState<any | object>({});

    // 新版UI：只显示用户名，不显示头像，且最多4个
    if (isShowName && notAvatar) {
        // eslint-disable-next-line
        maxLength = 5;
    }
    // console.log('收到的', list);
    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    const [t_chat] = useTranslation('chat');
    console.info(t_chat('userSelection.contacts'), t_chat('userSelection.groups'));
    const onNpcDataChange = (val: any) => {
        npcData = val;
    };
    const onTerminalDataChange = (val: any) => {
        terminalData = val;
    };
    function openUserSelect(tempList?: WorkUserInfo[] | any) {
        let temp;
        if (Array.isArray(tempList)) {
            temp = tempList;
        }
        if (addMemberCb) {
            return addMemberCb();
        }
        const { userId, teamUserInfo } = userData;
        const { ddAppId, teamId } = teamUserInfo;
        const option: any = {
            t: t_chat,
            title: t_chat('add_member'),
            tabs: !config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                ? ['recent', 'group', 'externalContacts']
                : ['recent', 'org', 'group', 'externalContacts'],
            recommendParam: {
                keyWords: title || '',
                aiType: ['empl'],
            },
            businessTab: {},
            frozenIds: (temp || list).map(
                (item) => item.userId + ':' + item.app + ':' + item.teamId
            ),
            resultUsers: lodashCompact(temp || list || []).map((item: any) => ({
                id: item.userId + ':' + item.app + ':' + item.teamId,
                name: item.realName,
                avatar: item.headImg,
                checked: true,
                userId: item.userId,
                teamId: item.teamId,
                visible: true,
                desc: item.titleName,
                weight: item.weight,
                processStatus: item.processStatus,
            })),
            contactsFilterFlag: 'external',
            currentUser: {
                app: ddAppId,
                pin: userId,
                teamId: teamId,
            },
        };
        if (projectId || isGroup) {
            option.tabs.unshift('business');
            option.businessTab = {
                tabName: '当前组成员',
                tabParam: {
                    businessType: isGroup ? 'group' : 'project',
                    businessId: isGroup ? (sessionId ? sessionId : '') : projectId,
                },
            };
        }
        if (platform === MeetingPlatform.HW) {
            option.tabs.push('custom', 'custom2');
            option.customTab = {
                tabName: '人大会议',
                dataList: [],
                renderer: () => {
                    return <NPCList onChange={onNpcDataChange} />;
                },
            };
            option.customTab2 = {
                tabName: '会议终端',
                dataList: [],
                renderer: () => {
                    return <Terminal onSelectChange={onTerminalDataChange} initVal={[]} />;
                },
            };
        }
        openUserSelectionModal(
            option,
            (data: any, close: any) => {
                // 过滤掉已选中的
                const { result } = data.data;
                console.log(result);
                if (result.length || platform === MeetingPlatform.HW) {
                    const addArr: any[] = [];
                    result.forEach((r: any) => {
                        if (r.id) {
                            const strs = r.id.split(':');
                            return addArr.push({
                                userId: strs[0],
                                teamId: strs[2],
                                app: strs[1],
                                ddAppId: label ? strs[1] : undefined, // 区分日程参与人
                                taskUserRole: label ? undefined : 2, // 区分日程参与人
                                headImg: r.avatar,
                                realName: r.name,
                                titleName: r.desc,
                                weight: r.weight,
                                processStatus: r.processStatus,
                            });
                        }
                        const { origin } = r;
                        if (origin.info) {
                            addArr.push({
                                userId: origin.info.userId,
                                teamId: origin.info.teamId,
                                app: origin.info.appId || origin.info.app,
                                ddAppId: label ? origin.info.appId || origin.info.app : undefined, // 区分日程参与人
                                taskUserRole: label ? undefined : 2, // 区分日程参与人
                                headImg: origin.info.headImg,
                                realName: origin.info.realName,
                                titleName: r.desc,
                                weight: r.weight,
                                processStatus: r.processStatus,
                            });
                        } else {
                            addArr.push({
                                userId: origin.userId,
                                teamId: origin.teamId,
                                app: origin.app,
                                ddAppId: label ? origin.info.appId || origin.info.app : undefined, // 区分日程参与人
                                taskUserRole: label ? undefined : 2, // 区分日程参与人
                                headImg: origin.headImg,
                                realName: origin.realName,
                                titleName: r.desc,
                                weight: r.weight,
                                processStatus: r.processStatus,
                            });
                        }
                    });
                    // 给任务协同人分配‘任务权重’
                    if (hasWeightSet) {
                        // updateModalFn({ visible: false });
                        close();
                        setWeightModalConfig({
                            close: function () {
                                setVisibleOfWeight(false);
                                // close();
                            },
                            backShowUserSelectionModal: function () {
                                setVisibleOfWeight(false);
                                // updateModalFn({ visible: true });
                                openUserSelect(addArr);
                            },
                            userArr: addArr,
                            complete: function (addArr: any) {
                                handleAddTaskMembers(addArr);
                            },
                        });
                        setVisibleOfWeight(true);
                        return true;
                    }
                    if (platform === MeetingPlatform.HW) {
                        handleAddTaskMembers(addArr, {
                            room: npcData,
                            terminalId: terminalData,
                        });
                    } else {
                        handleAddTaskMembers(addArr);
                    }
                }
                close();
            },
            userData
        );
    }

    const operators = useMemo(() => {
        if (maxLength <= 1) {
            return [];
        }
        if (list.length > maxLength) {
            return list.slice(0, maxLength - 1);
        }
        return [...list];
    }, [list, maxLength]);

    return (
        <>
            <div className="work-userlist">
                {owner && (
                    <UserItem
                        isShowName={isShowName}
                        notAvatar={notAvatar}
                        item={owner}
                        onClick={openUserCard}
                    />
                )}
                {owner && list.length > 0 && <div className="work-userlist-split" />}
                {operators.length > 0 &&
                    operators.map((l) => (
                        <UserItem
                            isShowName={isShowName}
                            key={l.userId + l.teamId + l.app}
                            item={l}
                            notAvatar={notAvatar}
                            onClick={openUserCard}
                            notTooltip={notTooltip}
                        />
                    ))}
                {list.length > maxLength && (
                    <div
                        className="work-userlist-total"
                        dangerouslySetInnerHTML={{ __html: `&nbsp;等${list.length}人` }}
                    />
                )}
                {!disable && !addMemberDisable && (
                    <div
                        className="work-userlist-add"
                        clstag={`pageclick|keycount|${
                            mode === 'create'
                                ? 'xtbg_add_task|xtbg_add_task_createtask_addcolaborator'
                                : 'xtbg_add_task|xtbg_add_task_taskdetail_addcolaborator'
                        }`}
                        onClick={openUserSelect}
                    >
                        {/* <IconFont
                            type="iconapp_btn_add2"
                            style={{ fontSize: '10px', color: (window as any).styleSass.primaryColor }}
                        /> */}
                        <span>添加</span>
                    </div>
                )}
            </div>
            {hasWeightSet && visibleOfWeight && (
                <WorkUserListWeight weightModalConfig={weightModalConfig} />
            )}
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkUserList);
