import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppStoreGroupApplication } from '@/services/AppStore';
import ModalAppTool from '@/components/ThirdApp/components/ModalAppTool';
import { IconAddApp } from '@/components/icon';
import AppItem from '@/components/ThirdApp/components/RightContent/AppItem';
import Loading from '@/components/Loading';
import { favoriteGroupId } from '@/components/ThirdApp';
import { isBjme } from '@/utils';
import './index.less';
import ScrollView from '@/baseComponents/ScrollView';
import NewVersionGuide from './NewVersionGuide';

const Label = ({ name }: { name: string }) => (
    <div className="third-content-item_title">{name}</div>
);

export default function Content({
    groups,
    loading,
}: {
    groups: AppStoreGroupApplication[];
    loading: boolean;
}) {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { t } = useTranslation('common');
    const onCancelModalAppTool = () => {
        setIsModalVisible(false);
    };
    const scrollRef: any = useRef(null);
    const Group = ({ group }: { group: AppStoreGroupApplication }) => (
        <div style={{ marginBottom: 10 }}>
            <div id={group.name}>
                <Label name={group.name} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {group.applications.map((item, index) => (
                    <AppItem key={index} appInfo={item} />
                ))}
                {/* 添加常用工具 */}
                {group.groupId === favoriteGroupId && (
                    <span
                        className="third-content-item-btn"
                        style={{ justifyContent: 'center', fontSize: 14, color: '#999999' }}
                        onClick={() => {
                            setIsModalVisible(true);
                        }}
                    >
                        <span className="third-content-item-icon">{IconAddApp}</span>
                        <span style={{ color: '#999999' }}>管理常用应用</span>
                    </span>
                )}
            </div>
        </div>
    );
    return (
        <div className="third-right-content">
            <NewVersionGuide />
            <ScrollView ref={scrollRef} autoHide>
                {!loading &&
                    groups.length > 0 &&
                    groups.map((group, i) => <Group group={group} key={i} />)}
                {/* no-data */}
                {!loading && groups.length === 0 && (
                    <div style={{ textAlign: 'center' }}>
                        {isBjme() ? t('thirdapp.no-tools') : t('thirdapp.no-application')}
                    </div>
                )}
                {/* 提示 */}
                {/* {!loading && groups.length > 0 && (
                    <p
                        style={{
                            fontSize: '12px',
                            color: '#8F959E',
                            textAlign: 'center',
                            marginBottom: 20,
                        }}
                    >
                        说明：浅蓝色为部署在政务云上的工具、浅黄色为部署在非政务云上的工具
                    </p>
                )} */}
                {/* loading中 */}
                {loading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Loading />
                    </div>
                )}
                {/* 弹窗 */}
                <ModalAppTool
                    isModalVisible={isModalVisible}
                    groups={groups}
                    onCancel={onCancelModalAppTool}
                />
            </ScrollView>
        </div>
    );
}
