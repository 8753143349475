import React from 'react';
import { ListItemData, ListItemProps } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { getRealColor, assignTime } from '@/components/Calendars/utils';
import { getTypeImage, getDefaultColor } from '@/components/Calendars/utils/getTypeImage';
import './SearchScheduleItem.less';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { showTime } from '@/components/chat/message/share/CalendarCard/showTime';
import dayjs from 'dayjs';
import { getRepeatName } from '@/components/Calendars/Enums';
const SearchScheduleItem = (
    props: { data: ListItemData; onClick?: (data: ListItemData) => void; t: any } & ListItemProps
) => {
    const { data, onClick, ...rest } = props;
    const [t] = useTranslation('calendar');
    return (
        <div
            className="search-schedule-item"
            onClick={() => {
                if (onClick) {
                    onClick(data);
                }
            }}
        >
            <div
                className={`search-schedule-item-icon ${
                    data.origin.color === 'FFF23030' ? 'search-schedule-item-icon-star' : ''
                }`}
                style={{
                    backgroundImage: `url(${getTypeImage(data.origin.color)})`,
                }}
            />
            <div className="search-schedule-item-content">
                <p className="search-schedule-item_title color-primary">{data.name}</p>
                <p>
                    <span className="search-schedule-item_text">
                        {showTime(dayjs(data.origin.beginTime), dayjs(data.origin.endTime), t)}
                    </span>
                    {!!data.origin.repeat && (
                        <div className="repeat-tag">{`${t(
                            getRepeatName(data.origin.repeat)
                        )}重复`}</div>
                    )}
                </p>
            </div>
        </div>
    );
};

export default SearchScheduleItem;
