import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import dayjs from 'dayjs';
import logger from '@/utils/logger';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { replaceGovUrl } from '@/utils/joyspace';
import { PRIORITY_TYPE_MAP } from '@/components/Projects/emnu';
import { formatTime } from '@/components/Calendars/utils';
import { cloneDeep } from 'lodash';
import { changeEgovUrl } from '@/utils/tools';

const log = logger.getLogger('NoticeCard');

export default function ({ message }: { message: any }) {
    const { title, extend, infox } = message;
    const [t] = useTranslation('chat');
    const [calendarT] = useTranslation('calendar');
    const content = extend.fields;
    const theContent = useMemo(() => {
        const data: any = {};
        const messageContent: any[] = [];
        let typeImg: null | ReactElement = null;
        content?.find((i: any) => {
            if (i.key === 'type') {
                typeImg = (
                    <img
                        style={{
                            width: '15px',
                            height: '15px',
                            verticalAlign: 'text-top',
                            marginRight: '5px',
                        }}
                        // @ts-ignore
                        src={changeEgovUrl(PRIORITY_TYPE_MAP[i.value]?.url)}
                        // @ts-ignore
                        alt={PRIORITY_TYPE_MAP[i.value]?.alt}
                        // @ts-ignore
                        title={PRIORITY_TYPE_MAP[i.value]?.alt}
                    />
                );
                return true;
            }
        });
        content?.forEach((i: any) => {
            const contentData: any = cloneDeep(i);
            switch (contentData.key) {
                case 'type': // 类型图标放到了标题里面
                    // contentData.value = (
                    //     <img
                    //         style={{ width: '15px', height: '15px' }}
                    //         // @ts-ignore
                    //         src={changeEgovUrl(PRIORITY_TYPE_MAP[i.value]?.url)}
                    //         // @ts-ignore
                    //         alt={PRIORITY_TYPE_MAP[i.value]?.alt}
                    //         // @ts-ignore
                    //         title={PRIORITY_TYPE_MAP[i.value]?.alt}
                    //     />
                    // );
                    // contentData.isComponent = true;
                    // messageContent.push(contentData);
                    break;
                case 'startTime':
                case 'endTime':
                    contentData.value =
                        contentData.value === 'null' ||
                        contentData.value === '-1' ||
                        contentData.value === -1 ||
                        !contentData.value
                            ? '无截止时间'
                            : formatTime(dayjs(Number(contentData.value)), calendarT);
                    messageContent.push(contentData);
                    break;
                case 'title':
                    contentData.value = (
                        <p>
                            {typeImg}
                            <span>{contentData.value}</span>
                        </p>
                    );
                    contentData.isComponent = true;
                    messageContent.push(contentData);
                    break;
                default:
                    messageContent.push(contentData);
            }
        });
        data.contentData = messageContent;
        return data;
    }, [calendarT, content]);

    return (
        <ComNoticeCard
            avatarSrc={changeEgovUrl(message.sender.avatar)}
            type="任务"
            contentList={theContent.contentData}
            title={title}
            handleDetail={() => {
                DeeplinkEvent.open(replaceGovUrl(infox.deepLink), {
                    source: 'imShareCard',
                });
            }}
            /* @LB-TODO */
            loadButtonsFn={() => (
                <div
                    className="button_item lbb-sign-mark-aaa"
                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_Aiassistant_viewdetail"
                    onClick={() => {
                        DeeplinkEvent.open(replaceGovUrl(infox.deepLink), {
                            source: 'imShareCard',
                        });
                    }}
                >
                    {t('click-to-view')}
                </div>
            )}
        />
    );
}
