// 任务详情页面使用
import React, { useCallback, useMemo, useRef, useState } from 'react';
import './index.less';
import { WorkUserInfo } from '@/types/work';
import { Button, Popover, Tabs, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserListEmptyImg } from '@/assets/img';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import { connect } from 'dva';
import Bus from '@/utils/bus';
import { processStatusOptions, PROCESS_STATUS, TASK_STATUS } from '@/types/newWork';
import AdvanceCom from '../TaskDetailDraw/components/AdvanceCom';
import IconFont, { IconArrowLeft } from '@/components/icon';
import { EsuperviseSourceType, ICreateSuperviseData } from '@/types/supervise';
import { PROCESS_STATUS_EXECUTORS } from '@/components/Projects/emnu';
import { labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';
import ScrollView from '@/baseComponents/ScrollView';
import { changeEgovUrl } from '@/utils/tools';
import noDataPicture from '@/assets/img/no-content.png';
import WorkUserListWeight, {
    number2percentum,
    percentum2number,
} from '../BaseComponents/WorkUserListWeightSet';
const { TabPane } = Tabs;

function UserStatusListContent({
    list,
    openCreateSuperviseModal,
    taskDetail,
    showAdvance,
}: {
    toggleUserCardModal: (payload: UserCardPayload) => void;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    list: any;
    taskDetail: any;
    showAdvance?: boolean;
}) {
    const { t } = useTranslation('work');

    const [advanceVisible, setAdvanceVisible] = useState(false);
    const [advanceUser, setAdvanceUser] = useState<WorkUserInfo>();
    const scrollRef = useRef(null);

    function handleAdvanceRecord(user: WorkUserInfo) {
        setAdvanceVisible(true);
        setAdvanceUser(user as any);
    }
    function handleSupervise(advanceUser: WorkUserInfo) {
        const userList: any[] = [];
        userList.push({
            userId: advanceUser?.userId,
            teamId: advanceUser?.teamId,
            appId: advanceUser?.app,
            headImg: advanceUser?.headImg,
            realName: advanceUser?.realName,
        });

        openCreateSuperviseModal({
            visible: true,
            createData: {
                id: taskDetail?.taskId,
                title: taskDetail?.title,
                userList,
                from: EsuperviseSourceType.WORK,
            },
        });
    }

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
    }
    const getProcessStatusTarget = (status: number) => {
        return processStatusOptions.find((item) => item.status === status) || null;
    };
    const getContent = () => {
        // 显示 “协调人列表“
        const getList = (list: any[], noUrge?: boolean) => {
            return list.map((item) => (
                <div className="user-status-list-item" key={item.userId + item.teamId + item.app}>
                    {item.headImg ? (
                        <img
                            src={changeEgovUrl(item.headImg || '')}
                            className="avatar"
                            onClick={() => openUserCard(item)}
                        />
                    ) : (
                        <div className="avatar" onClick={() => openUserCard(item)}>
                            {(item.realName || '').slice(0, 1)}
                        </div>
                    )}
                    <div className="task-container mark-lb-bbb">
                        <div className="title-content">
                            <span className="realname-wrap">
                                <Popover placement="top" content={item.realName} trigger="hover">
                                    <span className="name" style={{ paddingLeft: 0 }}>
                                        {item.realName}
                                    </span>
                                </Popover>
                                <span className="label-0">
                                    {item.labelInfoList &&
                                        item.labelInfoList.map((label: labelInfoList) => {
                                            return label.isShow === '1' ? (
                                                <span
                                                    style={{
                                                        color: `#${label.color}`,
                                                        marginLeft: '5px',
                                                        fontSize: 12,
                                                        padding: '0 3px',
                                                        border: `1px solid #${label.color}`,
                                                    }}
                                                >
                                                    {label.name}
                                                </span>
                                            ) : null;
                                        })}
                                </span>
                            </span>
                            <span className="duty">
                                {item.deptName
                                    ? item.deptName + (item.titleName ? '-' + item.titleName : '')
                                    : ''}
                            </span>
                        </div>
                        <div className="status-content">
                            {showAdvance && (
                                <span
                                    className="action-remove"
                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_collaborator_view_Advancerecord_urge"
                                    onClick={() => handleAdvanceRecord(item)}
                                >
                                    {t('advance-record')}
                                </span>
                            )}
                            {!noUrge && taskDetail.taskStatus === 1 && (
                                <span
                                    className="action-remove"
                                    onClick={() => handleSupervise(item)}
                                >
                                    催办
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            ));
        };
        const getEmpty = () => (
            <div className="user-status-list-empty">
                <img src={changeEgovUrl(UserListEmptyImg)} />
                <div className="label-0">{t('no executors')}</div>
            </div>
        );
        return (
            <Tabs defaultActiveKey="4" tabBarGutter={0}>
                {[
                    PROCESS_STATUS.NOT_VIEWED,
                    PROCESS_STATUS.PUSHING,
                    PROCESS_STATUS.DIFFICULT,
                    PROCESS_STATUS.FINISHED,
                ].map((index, i) => {
                    const itemList = list[index];
                    return (
                        <TabPane
                            className="task-process-list-tab"
                            // tab={`${itemList.length}人${PROCESS_STATUS_EXECUTORS[index]}`}
                            tab={`${PROCESS_STATUS_EXECUTORS[index]} ${itemList.length}`}
                            key={index}
                        >
                            <div className="user-status-list aaa">
                                <ScrollView
                                    ref={scrollRef}
                                    autoHide
                                    style={{ position: '' }}
                                    // style={{ position: '', marginRight: '0'}}
                                >
                                    {itemList.length > 0 ? getList(itemList, i === 3) : getEmpty()}
                                </ScrollView>
                            </div>
                        </TabPane>
                    );
                })}
            </Tabs>
        );
    };
    const getAdvanceContent = () => {
        // 显示 “推进记录”
        return (
            <div className="user-status-advance-record new-edition-version">
                <div className="user-status-advance-record-title1">
                    <span
                        style={{ cursor: 'pointer', color: '#999' }}
                        onClick={() => {
                            setAdvanceVisible(false);
                        }}
                    >
                        <IconFont type="iconapp_btn_file_cancel" />
                    </span>
                    <p>{advanceUser?.realName + t('advance-record')}</p>
                </div>
                {/* <div className="advance-record-wrapper aaa">
                    {advanceUser?.processStatus !== 4 ? (
                        <AdvanceCom className="as-to-someone" advanceUser={advanceUser} />
                    ) : (
                        <div className="non-seen">
                            {advanceUser?.realName +
                                (getProcessStatusTarget(advanceUser?.processStatus)?.label || '')}
                        </div>
                    )}
                </div> */}
                <div className="personal-situation">
                    <div>
                        <em>个人状态</em>
                        <span
                            // style={{ opacity: advanceUser?.processStatus !== 4 ? '1' : '0' }}
                            className={`personal-process-status tag-process-status-${advanceUser?.processStatus}`}
                        >
                            {PROCESS_STATUS_EXECUTORS[advanceUser?.processStatus as TASK_STATUS]}
                        </span>
                    </div>
                    {/* <p>
                        权重占比：
                        {advanceUser?.weight &&
                            number2percentum(advanceUser.weight! / taskDetail.totalWeight)}
                    </p> */}
                    {/* {advanceUser?.processStatus !== 4 && (
                        <div
                            className={`process-status-${advanceUser?.processStatus}`}
                            style={{
                                width: '100%',
                                display: advanceUser?.processStatus !== 4 ? 'flex' : 'none',
                                justifyContent: 'space-between',
                                marginTop: '15px',
                                alignItems: 'center',
                            }}
                        >
                            <em>推进进度</em>
                            <Progress
                                percent={advanceUser?.progress}
                                size="small"
                                trailColor="rgba(0,0,0,0.08)"
                                showInfo={false}
                                strokeWidth={4}
                            />
                            <article
                                style={{
                                    color:
                                        advanceUser?.progress && advanceUser.progress! > 0
                                            ? ''
                                            : '#8F959E',
                                }}
                            >
                                {advanceUser?.progress &&
                                    number2percentum(advanceUser.progress / 100)}
                            </article>
                        </div>
                    )} */}
                </div>
                <div className="advance-record-wrapper bbb-1">
                    {advanceUser?.processStatus !== 4 ? (
                        <AdvanceCom className="as-to-someone" advanceUser={advanceUser} />
                    ) : (
                        <div className="non-seen">
                            <img
                                style={{ width: 120 }}
                                src={changeEgovUrl(noDataPicture)}
                                alt="暂无数据"
                            />
                            {/* {advanceUser?.realName +
                                (getProcessStatusTarget(advanceUser?.processStatus)?.label || '')} */}
                            <p>暂无推进记录</p>
                        </div>
                    )}
                </div>
                {advanceUser?.processStatus === 2 || taskDetail.taskStatus !== 1 ? null : (
                    <Button
                        type="link"
                        block
                        onClick={() => {
                            const userList: any[] = [];
                            userList.push({
                                userId: advanceUser?.userId,
                                teamId: advanceUser?.teamId,
                                appId: advanceUser?.app,
                                headImg: advanceUser?.headImg,
                                realName: advanceUser?.realName,
                            });

                            openCreateSuperviseModal({
                                visible: true,
                                createData: {
                                    id: taskDetail?.taskId,
                                    title: taskDetail?.title,
                                    userList,
                                    from: EsuperviseSourceType.WORK,
                                },
                            });
                        }}
                    >
                        {t('urge immediately')}
                    </Button>
                )}
            </div>
        );
    };
    return advanceVisible ? getAdvanceContent() : getContent();
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStatusListContent);
