/* eslint-disable no-debugger */
/* eslint-disable max-params */
/**
 * @author sunzhiguang
 * @date 2020/7/2
 */
import {
    ChatMessage,
    ChatMessageKindBody,
    ChatMessageType,
    ClientType,
    Employee,
    EmployeeAttribute,
    Group,
    GroupApprovalRule,
    GroupAttribute,
    GroupKind,
    GroupRoster,
    MessageEmitCategory,
    MessageRespType,
    MessageStatus,
    Session,
    SessionType,
    UID,
} from '@/types/chat';
import { lookup } from 'mime-types';
import { shortFileName, stringEncode, userEscapeString } from '@/utils/chat/index';
import { convertUidToString, generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import { MessageSendType, SessionSettingsType } from '@/types/chat/enum';
import sysMsgConfig from '@/config/chat';
import i18n from '@/i18n';
import get from 'lodash/get';
import lodashGroupBy from 'lodash/groupBy';

import config, { ChatConfig } from '@/config/config';
import { convertStringToEmoji, convertStringToNoticeEmoji } from '@/components/chat/utils/ddemoji';
import pick from 'lodash/pick';
import { UserCardData } from '@/types/UserCard';
import { mattchEmoji } from '../message/Input/Emoji';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import { combGroupSetField } from '@/components/chat/message/Item/common';
import {
    EmojiMessageContentItem,
    MessageExtAction,
    ChatMessageExtType,
    GroupNoticeMessageContentItem,
} from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/Type';
import QuickEmojiMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/QuickEmoji';
import GroupNoticeExtMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/GroupNotice';
import { FocusSDK, isBjme } from '@/utils';
import { User } from '@/types/supervise';
import { urlReg, urlPatt } from '@/components/chat/message/message-components/utiles/joyspace-file';
import CHAT_NOTICE, { OTHER_EXCLUDE_TABS_PIN } from '@/contant/chat';
import { checkJoyspaceUrl, legalJoyspaceUrlReg } from '@/components/Joyspace/util';
import { getPageStatus, shareJoyspaceFiles } from '@/components/Joyspace/api/page';
import { PermissionEnum } from '@/components/Joyspace/const';
import JoyspaceFileMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/message/JoyspaceFile';
import TextMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/message/Text';
import SolitaireMessageBody, {
    SOLITAIRE_TYPE,
} from '@jd/jdee.im.sdk/lib/es/protocol/message/solitaire';
import { joyspacePermissionType } from '@/components/Joyspace/config/permissions.config';
import { changeGovUrl } from '@/utils/tools';

import { AVSDK } from '@/baseComponents/Meeting/common';
import { duccConfigI18 } from '@/utils/i18n';
import { getTitleByWXLink } from '@/api/chat';
export const initChatMessageInfo = ({
    selectedSession,
    currentEmployee,
    message,
    task,
}: {
    selectedSession: Session;
    currentEmployee: Employee;
    message: Partial<ChatMessage>;
    task?: any;
}): Partial<ChatMessage> => {
    const timestamp = new Date().getTime();
    const id = generateMessageId();
    let _currentEmployee = currentEmployee;
    if (!currentEmployee?.userId) {
        _currentEmployee = getCurrentUserFromLocal() as Employee;
    }
    return {
        ...message,
        category: MessageEmitCategory.CHAT_MESSAGE,
        sessionId: selectedSession.sessionId,
        taskId: task?.id,
        task,
        mid: 0,
        beforeMid: 0,
        sender: _currentEmployee,
        timestamp,
        id,
        status: MessageStatus.INIT, // 需要发送的消息内容需初始化状态为INIT
    };
};

/**
 * 构建 文本消息体
 * @param content
 * @param atUsers
 * @param replyMessage
 */
export function buildTextMessageBody(
    content: string,
    atUsers: UID[],
    replyMessage?: ChatMessage,
    messageTypeOption?: HttpCardLinkTypeOption,
    displayContent?: ContentItem[]
): Partial<TextMessageBody> {
    const isPlainText = displayContent
        ? displayContent.every((item) => item.type === MessageCardType.Text)
        : true;
    const messageBody: any = {
        type: ChatMessageType.TEXT,
        content: changeGovUrl(content),
        atUsers: atUsers,
        ...messageTypeOption,
        displayContent: isPlainText ? undefined : displayContent,
    };
    if (replyMessage?.mid) {
        const replayMid = Math.floor(replyMessage?.mid || 0);
        const rootId = Math.floor(replyMessage.reply?.root || 0);
        const sender = replyMessage.sender as Employee;
        const root = rootId || replayMid;
        Object.assign(messageBody, {
            reply: {
                mid: replayMid,
                root: root,
                uid: { ...employeeToUid(sender), nickName: sender?.name || sender?.nickName },
            },
        });
    }
    return messageBody;
}

/**
 * 构建 接龙消息体
 * @param title
 * @param content
 * @param remark
 * @param deadline
 * @param open
 * @param solitaireId
 * @param solitaire
 */
export function buildsolitaireMessageBody(
    content: string,
    title: string,
    remark: string,
    open: number,
    solitaireId: string,
    solitaire: SOLITAIRE_TYPE,
    deadline?: number
): Partial<SolitaireMessageBody> {
    return {
        type: ChatMessageType.SOLITAIRE,
        content: content,
        title: title,
        remark: remark,
        open: open,
        solitaireId: solitaireId,
        solitaire: solitaire,
        deadline: deadline,
    };
}
// 处理接口返回值
export function handleJoyspaceLinkPermission(page: any, permissionType: any) {
    let currentPermission = joyspacePermissionType.ReadNothing;
    let permission = -1;
    if (!page) {
        return {
            currentPermission,
            permission,
        };
    }
    let permissions = page.permissions;
    if (permissions?.includes(PermissionEnum.PAGE_EDIT)) {
        permission = 1;
    } else if (page.permissions) {
        permission = 2;
    }
    if (permissions) {
        currentPermission = permissionType ? permissionType : 2;
    }
    return {
        // @ts-ignore
        currentPermission,
        permission,
    };
}
/**
 * 构建 joyspace 链接识别成卡片的消息体
 * @param content
 * @param atUsers
 * @param url
 * @param replyMessage
 */
// eslint-disable-next-line max-params
/**
 * 场景一: 点击+号，选择云文档发送
 * 场景二: input输入，发送消息
 */
export function buildJoyspaceLinkMessageBody(
    content: string,
    messageTypeOption: JouspaceLinkTypeOption,
    atUsers: UID[],
    selectedSession: Session,
    replyMessage?: ChatMessage,
    permissionType?: joyspacePermissionType
): Promise<Partial<JoyspaceFileMessageBody | any>> {
    return new Promise((resolve) => {
        const messageBody = buildTextMessageBody(content, atUsers, replyMessage);
        let { webUrl, fileId } = messageTypeOption;
        webUrl = changeGovUrl(webUrl);
        const info = selectedSession.info;
        // console.log(permissionType, 'permissionTypepermissionType');
        shareJoyspaceFiles({
            page_ids: [fileId],
            to_eepaas_users: !selectedSession.isGroup
                ? [
                      {
                          teamId: (info as EmployeeAttribute).teamId,
                          userId: (info as EmployeeAttribute).userId,
                          permission_type: permissionType || 2,
                      },
                  ]
                : [],
            to_groups: selectedSession.isGroup
                ? [{ gid: (info as GroupAttribute).gid, permission_type: permissionType || 2 }]
                : [],
        })
            .then((res) => {
                // console.log(res, 'res===============>');
                const resObj = res[fileId] || {};
                const fileName = resObj.page.title;
                const pageType = resObj.page.page_type || messageTypeOption.pageType;
                console.log(
                    {
                        ...messageBody,
                        type: ChatMessageType.JOYSPACEFILE,
                        fileName,
                        fileId,
                        // permission,
                        webUrl,
                        iconUrl: resObj.page.icon_url || '',
                        // @ts-ignore
                        ...handleJoyspaceLinkPermission(resObj.page, permissionType),
                        pageType,
                    },
                    '=======================================>'
                );
                // @ts-ignore
                resolve({
                    ...messageBody,
                    type: ChatMessageType.JOYSPACEFILE,
                    fileName,
                    fileId,
                    webUrl,
                    iconUrl: resObj.page.icon_url || '',
                    // @ts-ignore
                    pageType,
                    ...handleJoyspaceLinkPermission(resObj.page, permissionType),
                });
            })
            .catch((err) => {
                console.log(err, 'errerrerr');
                resolve({
                    ...messageBody,
                    type: ChatMessageType.JOYSPACEFILE,
                    webUrl,
                    fileId,
                    iconUrl: '',
                    // @ts-ignore
                    currentPermission: -1,
                    permission: -1,
                    pageType: messageTypeOption.pageType || 1,
                });
            });
    });
}
/**
 * 构建 图片消息体
 * @param url
 * @param size
 * @param width
 * @param height
 */
// eslint-disable-next-line max-params
export function buildImageMessageBody(
    url: string,
    size: number,
    width: number,
    height: number
): Partial<ChatMessage> {
    return {
        url: url,
        size: size,
        width: width,
        height: height,
        type: ChatMessageType.IMAGE,
    };
}

/**
 * 构建 文件消息体
 * @param url
 * @param name
 * @param size
 * @param fileType
 * @param localPath
 */
// eslint-disable-next-line max-params
export function buildFileMessageBody(
    url: string,
    name: string,
    size: number,
    fileType: string,
    localPath?: string
): Partial<ChatMessage> {
    return {
        type: ChatMessageType.FILE,
        url: url,
        name: name,
        size: size,
        fileType: fileType.length > 0 ? fileType : lookup(name) || '',
        upLocalPath: localPath || '111',
    };
}

export function buildTemplate2MessageBody({ nativeId, data, currentEmployee, id }: any) {
    return {
        type: ChatMessageType.TEMPLATE2,
        template: {
            nativeId: nativeId,
        },
        // code: 0,
        data: data,
        // mid: 0,
        // beforeMid: 0,
        sender: currentEmployee,
        timestamp: Date.now(),
        id: id,
    };
}

export function buildJoySpaceDocShareBody(data: any): Partial<ChatMessage> {
    let type = 'document';
    switch (data.pageType) {
        case 1:
            type = 'document';
            break;
        case 2:
            type = 'week_report';
            break;
        case 4:
            type = 'table';
            break;
        case 6:
            type = 'file';
            break;
        default:
            break;
    }
    const sharelink: any = {
        ext: `{"type":"${type}"}`,
        icon: data.icon,
        // source: '云空间',
        source: isBjme() ? '云文件' : duccConfigI18.joyspace,
        // category: 'jd_doc',
        title: data.title,
        content: data.content,
        url: data.url,
        type: data.type,
        pageType: data.pageType,
    };

    if (type === 'file') delete sharelink.category;
    return {
        type: ChatMessageType.TEMPLATE2,
        template: {
            nativeId: 'share_link',
        },
        code: 0,
        data: [
            {
                sharelink: sharelink,
                type: 0,
            },
        ],
    };
}

/**
 * 构建 文本消息体
 */
// action: "add"
// deviceCode: "71eac0a4-e7b7-4c2c-9799-f6fc6773c8c4"
// mid: 223067597
// msgContent: [{…}]
// msgType: "plusOne"
// sender: {app: "me", clientType: "ios", pin: "X1AaVWkcGRMdVVvoB0mGN", nickName: "侯庆祥", teamId: "mS2CKU8HN3UFxdliJ1AxR"}
// sessionGroup: 2
// sessionId: "1080002362"
// sessionType: 2
export function buildQuickEmojiMessageBody({
    msgType,
    session,
    mid,
    action,
    msgContentItem,
}: {
    msgType: ChatMessageExtType.PIUS_ONE;
    session: Partial<Session>;
    mid: number;
    action: MessageExtAction;
    msgContentItem: EmojiMessageContentItem;
}): Partial<QuickEmojiMessageBody> {
    return {
        msgType: ChatMessageExtType.PIUS_ONE,
        action,
        mid: mid,
        msgContent: [msgContentItem],
        sessionGroup: 1,
        sessionId: session.sessionId,
        sessionType: session.sessionType,
    };
}
/**
 * 构建 组通知回复
 */
export function buildGroupMessageBody({
    msgType,
    session,
    mid,
    msgContentItem,
}: {
    msgType: ChatMessageExtType.GROUP_NOTICE;
    session: Partial<Session>;
    mid: number;
    msgContentItem: GroupNoticeMessageContentItem;
}): Partial<GroupNoticeExtMessageBody> {
    return {
        msgType,
        mid: mid,
        msgContent: [msgContentItem],
        sessionGroup: 1,
        sessionId: session.sessionId,
        sessionType: session.sessionType,
    };
}

/**
 * 构建 组接龙回复
 */
// export function buildSolitaireMessageBody({
//     msgType,
//     action,
//     session,
//     mid,
//     msgContentItem,
// }: {
//     msgType: ChatMessageExtType.GROUP_SOLITAIRE_NEW;
//     session: Partial<Session>;
//     mid: number;
//     action: MessageExtAction;
//     msgContentItem: GroupSolitaireMessageContentItem;
// }): Partial<GroupSolitaireExtMessageBody> {
//     return {
//         msgType,
//         mid: mid,
//         msgContent: [msgContentItem],
//         action: action,
//         sessionGroup: 1,
//         sessionId: session.sessionId,
//         sessionType: session.sessionType,
//     };
// }

/**
 * 判断AI助手卡片通知是否展示老样式
 * @param message
 * @returns boolean
 */
export function isShowOldNoticeCard(message: any) {
    const isOldStyle =
        !message.extend?.messageFormatVer || message.extend?.messageFormatVer === 'v1';
    return isOldStyle;
}

/**
 * 判断AI助手卡片通知是否有老样式转为新样式（前提是调取的老接口）
 * @param message
 * @returns boolean
 */
export function isShowFakeNoticeCard(message: any) {
    const isFakeStyle = isShowOldNoticeCard(message) && message.extend?.adapteNewStyle === 'true';
    return isFakeStyle;
}

/**
 * 获取joyspace的链接，有且只有一个链接且是joyspace链接时才返回
 * @param text
 * @returns
 */
export function getJoyspaceUrl(text: string): string {
    let joyspaceCount = 0;
    let url = '';
    const matchUrls = text.match(urlReg) || [];
    matchUrls.forEach((item) => {
        if (legalJoyspaceUrlReg(item)) {
            url = item;
            joyspaceCount++;
        }
    });
    if (joyspaceCount === 1) return url;
    return '';
}

/**
 * 判断是否是joyspace_link 类型
 * @param text
 * @returns
 */
export function checkJoyspaceLink(url: string): any {
    // const joyspaceUrl = getJoyspaceUrl(url);
    const joyspaceUrl = legalJoyspaceUrlReg(url) ? url : ' ';
    if (joyspaceUrl) {
        const result = checkJoyspaceUrl(joyspaceUrl);

        return result;
    }
    return {
        is: false,
        url: '',
    };
}

export enum MessageCardType {
    Text = 'Text', // 纯文本
    Card = 'Card', // 卡片格式  无此格式
    JoyspaceTopic = 'JoyspaceTopic', // joyspace链接请求服务端返回了标题
    HttpTopic = 'HttpTopic', // http链接请求服务端返回了标题 暂时没有这个类型，涉及不到请求服务器
    ShortLink = 'ShortLink', // 短链（包含http链接、安装包链接、joyspace请求服务端错误返回的原链接等等）
}
export interface ContentItem {
    content: string; // 标题或内容
    url?: string;
    type: MessageCardType;
    startIndex?: number; // 如果是一个链接的话，这个stratIndex应该没有用 没有一个链接的情况，因为手动选择复制的结果全部是文本，可能这个字段没有用了。
    ext?: string; // json字符串
}
/**
 * 创建链接消息体
 * @param body
 * @returns
 */
// export function checkJoyspaceLink(text: any): any {
//     const joyspaceUrl = legalJoyspaceUrlReg(text) ? text : ' ';
//     if (joyspaceUrl) {
//         const result = checkJoyspaceUrl(joyspaceUrl);

//         return result;
//     }
//     return {
//         is: false,
//         url: '',
//     }
// }
export function buildUrlMessageBody(body: ContentItem): ContentItem {
    return {
        content: body.content,
        url: body.url,
        type: body.type,
        startIndex: body.startIndex,
        ext: body.ext,
    };
}

/**
 * 字符串根据url正则转换成数组
 * @param content 将要被转换成数组的字符串
 */
export function convertContentToArray(content: any) {
    const regexp = new RegExp(urlPatt);
    const matches = content.matchAll(regexp);
    // console.log(matches, 'matchesmatchesmatchesmatchesmatches');
    const subIndexArr: any[] = [];
    const result: ContentItem[] = [];
    for (const match of matches) {
        const sIndex = match.index;
        let input = match[0];
        let endChar = match[0].substr(-1);
        // console.log('input', input, 'endChar', endChar);
        if (!/[A-Za-z0-9+&#\/%=~_|]/.test(endChar)) {
            // console.log(222);
            input = match[0].substring(0, match[0].length - 1);
            endChar = input.substr(-1);
        }
        // console.log(input);
        subIndexArr.push({ sIndex, eIndex: sIndex + input.length });
    }
    // console.log(subIndexArr, 'subIndexArrsubIndexArrsubIndexArrsubIndexArr');
    if (!subIndexArr.length) {
        result.push({
            content,
            url: undefined,
            type: MessageCardType.Text,
            startIndex: 0,
            ext: undefined,
        });
    } else {
        // console.log('subIndexArr', subIndexArr);
        const con = content as string;
        subIndexArr.forEach((item, idx) => {
            if (idx === 0) {
                const content = con.substring(item.sIndex, item.eIndex);
                result.push(
                    buildUrlMessageBody({
                        content,
                        url: content,
                        type: MessageCardType.ShortLink,
                        startIndex: item.sIndex,
                        ext: undefined,
                    })
                );
                if (item.sIndex !== 0) {
                    result.unshift(
                        buildUrlMessageBody({
                            content: con.substring(0, item.sIndex),
                            url: undefined,
                            type: MessageCardType.Text,
                            startIndex: 0,
                            ext: undefined,
                        })
                    );
                }
            } else {
                const textIdx = subIndexArr[idx - 1].eIndex;
                // console.log('中间段文本', con.substring(textIdx, item.sIndex));
                result.push(
                    buildUrlMessageBody({
                        content: con.substring(textIdx, item.sIndex),
                        url: undefined,
                        type: MessageCardType.Text,
                        startIndex: textIdx,
                        ext: undefined,
                    })
                );
                result.push(
                    buildUrlMessageBody({
                        content: con.substring(item.sIndex, item.eIndex),
                        url: con.substring(item.sIndex, item.eIndex),
                        type: MessageCardType.ShortLink,
                        startIndex: item.sIndex,
                        ext: undefined,
                    })
                );
            }
        });
        const lastItem = subIndexArr[subIndexArr.length - 1];
        if (lastItem.eIndex < content.length) {
            result.push(
                buildUrlMessageBody({
                    content: con.substring(lastItem.eIndex, content.length),
                    url: undefined,
                    type: MessageCardType.Text,
                    startIndex: lastItem.eIndex,
                    ext: undefined,
                })
            );
        }
    }
    // console.log('转换后的数组', result);
    return result;
}
/**
 * 发送消息
 */
/**
 * 将消息里的joyspace链接替换标题，ext填充文档信息
 */
export async function contentArrayReplaceJoyspaceTopic(
    arr: ContentItem[] = [],
    // tmpArr: ContentItem[] = [],
    sessions: Session[] = []
) {
    if (!arr.length) {
        return [];
    }
    const ids = arr.map((item: ContentItem) => {
        return JSON.parse(item?.ext || '{}').pageId;
    });
    let user: any[] = [];
    let groups: any[] = [];
    for (let item of sessions) {
        // console.log(item, 'sadsadsasdadssadadad');
        const info: any = item.info;
        if (item.isGroup) {
            groups.push({ gid: info.gid, permission_type: 2 });
        } else if (item.isSingle) {
            // console.log(info, 'infoinfoinfoinfoinfoinfoinfo=>');
            user.push({ userId: info.userId, teamId: info.teamId, permission_type: 2 });
        }
    }
    // console.log(ids, user, groups, 'contentArrayReplaceJoyspaceTopic=====>');
    const result = await shareJoyspaceFiles({
        page_ids: ids,
        to_eepaas_users: user,
        to_groups: groups,
    });
    return result;
}
function getDisplayContentArr(contentArr = [], joyspaceAll: any) {
    return contentArr.map((item: ContentItem) => {
        if (item.type === MessageCardType.JoyspaceTopic) {
            item.ext = JSON.parse((item as any).ext);
            const pageId = (item.ext as any).pageId;
            const page = joyspaceAll[pageId].page;
            let _item: any;
            if (!page) {
                _item = buildUrlMessageBody({
                    content: item.content,
                    url: item.content,
                    type: MessageCardType.ShortLink,
                    startIndex: item.startIndex,
                    ext: undefined,
                });
                return _item;
            }
            // page有文档信息 但是包含删除无权限
            const _ext: any = item.ext;
            _ext.currentPermission = -1;
            _ext.permission = -1;
            _ext.iconUrl = page.icon_url;
            _ext.title = page.title;
            if (Array.isArray(page.permissions)) {
                _ext.currentPermission = 2;
                _ext.permission = page.permissions.includes(PermissionEnum.PAGE_EDIT) ? 1 : 2; // 默认 -1v
            }
            item.content = page.title;
            item.ext = _ext;
        }
        if (item.ext) {
            item.ext = JSON.stringify({ ...(item as any).ext });
            // console.log(JSON.parse(item.ext), 'itemitemitemitem=>');
        }
        return item;
    });
}
/**
 * 将消息里的joyspace链接替换标题
 */
export async function sendMessageBody(content: any, sessions: Session[] = []) {
    let contentArr: any = convertContentToArray(content);
    contentArr = contentArr.map((item: ContentItem) => {
        if (item.type === MessageCardType.ShortLink) {
            const _item = checkJoyspaceLink(item.content);
            // 是否是joyspace链接
            if (_item.is) {
                item.url = item.content;
                item.type = MessageCardType.JoyspaceTopic;
                item.ext = JSON.stringify({
                    ..._item,
                    pageId: _item.fileId,
                    currentPermission: -1,
                    permission: -1,
                    title: '无标题',
                    iconUrl: '',
                });
                // ext：{pageId, currentPermission(1可编辑 2仅阅读 -1无权限或者已删除), iconUrl, permission(1可编辑 2仅阅读 -1无权限或者已删除), title}
            }
            // 是否是Http链接,再次扩展
        }
        return item;
    });
    const joyspaceLinkArr = contentArr.filter(
        (item: ContentItem) => item.type === MessageCardType.JoyspaceTopic
    );
    // 请求Joyspace批量接口
    const joyspaceAll = await contentArrayReplaceJoyspaceTopic(joyspaceLinkArr, sessions);
    const _contentArr = getDisplayContentArr(contentArr, joyspaceAll);
    // console.log(_contentArr, 'contentArrcontentArrcontentArr');
    return _contentArr;
}
/**
 *
 * @param message 消息体
 * @param options 人员id或者组id
 * @param type 消息类型
 * @param permissionType 保留参数
 * @returns Object
 */
export async function forwardJoySpaceCard(
    message: any,
    options: any,
    type: ChatMessageType,
    permissionType = 2
) {
    // console.log(message, 'messagemessagemessagemessage');
    if (!message.displayContent && type !== ChatMessageType.JOYSPACEFILE) {
        // eslint-disable-next-line no-debugger
        // debugger;
        return {};
    }
    let users: Array<any> = [];
    (options.uids || []).forEach((user: any) => {
        users.push({
            userId: user.pin,
            teamId: user.teamId,
            permission_type: 2,
        });
    });
    let groups: Array<any> = [];
    (options.gids || []).forEach((id: any) => {
        groups.push({ gid: id, permission_type: 2 });
    });
    let pageIds = [];
    switch (type) {
        case ChatMessageType.JOYSPACEFILE:
            pageIds = [message?.fileId];
            break;
        case ChatMessageType.TEXT:
            for (let item of message.displayContent) {
                if (item.type === MessageCardType.JoyspaceTopic && item.ext) {
                    const ext = JSON.parse(item.ext);
                    pageIds.push(ext.pageId);
                }
            }
            break;
        default:
            pageIds = [];
    }
    const result = await shareJoyspaceFiles({
        page_ids: pageIds,
        to_eepaas_users: users,
        to_groups: groups,
    });
    if (type === ChatMessageType.JOYSPACEFILE) {
        const isDelPage = !Array.isArray(result[pageIds[0]].page.permissions);
        return {
            currentPermission: isDelPage ? -1 : 2,
            permission: isDelPage ? -1 : (message as any).permission,
        };
    }
    if (type === ChatMessageType.TEXT) {
        const _displayContent: any = JSON.parse(JSON.stringify(message.displayContent)); // 深克隆数组 否则会影响原消息
        const displayContent = getDisplayContentArr(_displayContent, result);
        return { displayContent };
    }
}
/**
 * 当文档解析失败将
 * joySpaceLink消息转text消息
 */
export async function nonExistentPageToText(messageTypeOption: MessageTypeOption) {
    // console.log(messageTypeOption, 'messageTypeOptionmessageTypeOption');
    const { type, fileId } = messageTypeOption as any;
    if (type === ChatMessageType.JOYSPACEFILE) {
        const result = await getPageStatus([fileId]); // -2 文档不存在
        if (result[fileId] === -2) {
            (messageTypeOption as any).type = ChatMessageType.TEXT;
            return messageTypeOption;
        }
    }
    return messageTypeOption;
}
/**
 * 根据文本获取消息类型
 * @param text
 * @returns
 */
export interface TextTypeOption {
    type: string;
}

export interface HttpCardLinkTypeOption extends TextTypeOption {
    nativeId: string;
    data: { fileName: string; fileContent: string; webUrl: string; iconUrl?: string };
}
export interface JouspaceLinkTypeOption extends TextTypeOption {
    url: string;
    webUrl: string;
    fileId: string;
    pageType: number;
}

type MessageTypeOption = Readonly<TextTypeOption | JouspaceLinkTypeOption | HttpCardLinkTypeOption>;

export async function getMessageTypeByText(text: string): Promise<MessageTypeOption> {
    const matchUrls = text.match(urlPatt) || [];
    let result;
    let isWXLink = false;
    if (matchUrls.length === 1) {
        const url = matchUrls[0];
        result = checkJoyspaceLink(url);
        isWXLink = /^https?:\/\/mp.weixin.qq.com/.test(url) && text === url;
    }
    const typeOption = { type: ChatMessageType.TEXT };
    if (text.includes(`组通知：`)) {
        return { type: ChatMessageType.GROUPNOTICE };
    }
    if (result?.is) {
        return {
            type: ChatMessageType.JOYSPACEFILE,
            ...result,
        };
    }

    // 新版本去掉message.type = text，nativeId = share_link 的http链接消息卡片类型
    // 如果输入内容是一个纯微信公众号链接（mp.weixin.qq.com，按照这个域名匹配），则按照卡片方式发送，其余按照文本消息发送。
    if (isWXLink) {
        const [data, error] = await getTitleByWXLink(matchUrls[0]);
        if (data) {
            return {
                ...typeOption,
                nativeId: 'share_link',
                data: {
                    fileName: (data as any).postTitle,
                    fileContent: '请点击卡片，查看内容详情。',
                    webUrl: matchUrls[0],
                },
            };
        }
    }
    return typeOption;
}

export function getRevokeMessageDesc(
    message: any,
    sessionType: SessionType,
    currentEmployee: Employee
): string {
    if (!message) {
        return '';
    }
    if (mySendMessage({ message, currentEmployee })) {
        return '您撤回了一条消息';
    } else if (sessionType === SessionType.SINGLE || sessionType === SessionType.SECRET_SINGLE) {
        return '对方撤回了一条消息';
    } else if (sessionType === SessionType.GROUP) {
        return i18n.t('chat:someone-revoke-message', { name: message?.sender?.name || '' });
    } else {
        return '';
    }
}

// eslint-disable-next-line max-params,complexity
export function getLastMessageDesc(
    message: any,
    sessionType: SessionType,
    currentEmployee: Employee,
    isNotice?: boolean
): string {
    if (!message) {
        return '';
    }

    if (message.revoke > 0) {
        return getRevokeMessageDesc(message, sessionType, currentEmployee);
    }
    if (message.type === 'unified_notice_message') {
        // 群组手
        if (message.from?.pin === 'eopen-push_uUyYexheoyRkjp6DpVtx') {
            return userEscapeString(message.title);
        }
        // 新用户申请
        if (message.from?.pin === 'eopen-push_yQXxq4WhIpF2CAdw01Ti') {
            return userEscapeString(message.title);
        }
        if (!isShowOldNoticeCard(message)) {
            return userEscapeString(message.title);
        }
        if (message.pin === CHAT_NOTICE.AI_PIN) {
            return userEscapeString(message.content);
        }
        return userEscapeString(message.content);
    }
    if (sessionType === SessionType.SECRET_SINGLE && message?.expire) {
        return '[消息]';
    }
    if (message?.type) {
        let content = '';
        switch (message.type) {
            case 'revoke_message':
                content = getRevokeMessageDesc(message, sessionType, currentEmployee);
                return content;
            case ChatMessageType.JOYSPACEFILE:
                if (message.content === message.webUrl) {
                    content = `[${i18n.t('chat:document')}]:${
                        message.fileName ? message.fileName : '无标题'
                    }`;
                } else if (message.content) {
                    content = message.content.replace(urlReg, `[${i18n.t('chat:link')}]`);
                }
                break;
            case ChatMessageType.TEXT:
                content = `${message.content}`;
                break;
            case ChatMessageType.IMAGE:
                content = `[${i18n.t('chat:image')}]`;
                break;
            case ChatMessageType.VOICE:
                // content = `[${i18n.t('chat:no_support_message_type')}]`;
                content = `[${i18n.t('chat:voice')}]`;
                break;
            case ChatMessageType.VIDEO:
                content = `[${i18n.t('chat:video')}]`;
                break;
            case ChatMessageType.FILE:
                content = `[${i18n.t('chat:file')}]${message.name}`;
                break;
            case ChatMessageType.GROUPNOTICE:
                content = `[组通知]`;
                break;
            case ChatMessageType.TEMPLATE2:
                if (message.template.nativeId === 'merge_forward') {
                    content = `[${i18n.t('chat:communicate_record')}]`;
                    // content = `[${i18n.t('chat:no_support_message_type')}]`;
                } else if (message.template.nativeId === 'share_card') {
                    if (sessionType === SessionType.GROUP) {
                        content = `[${i18n.t('chat:user_card')}]`;
                    } else if (mySendMessage({ message, currentEmployee })) {
                        content = `[${i18n.t('chat:user_card')}]`;
                    } else {
                        let name = ':';
                        if (message.sender) {
                            name = (message.sender?.name || '') + name;
                        } else if (message.body) {
                            name = (message.body.sender?.name || '') + name;
                        }
                        const second = get(message, 'data.0.sharecard.nickName', '');
                        content = `${i18n.t('chat:share_card_desc', { name, second })}`;
                    }
                } else {
                    const title = get(message, 'data.0.sharelink.title', '');
                    content = `[${i18n.t('chat:link')}]${title}`;
                }
                break;
            case ChatMessageType.SOLITAIRE:
                content = `[组接龙]`;
                break;
            case 'group_delete':
                content = `组已解散`;
                break;
            default:
                content = `[${i18n.t('chat:no_support_message_type')}]`;
                break;
        }
        if (message.type === ChatMessageType.SYS) {
            if (message.flag === 100) {
                content = message.content;
            } else if (message.sys_msg_type === 'SetRosterSuccessNotice') {
                content = message.content;
            }
            content = userEscapeString(content);
        } else if (isNotice) {
            // 转换咚咚表情
            content = userEscapeString(content);
            content = convertStringToNoticeEmoji(content);
        } else {
            content = userEscapeString(content);
            content = mattchEmoji(convertStringToEmoji(stringEncode(content)));
        }

        if (content && sessionType === SessionType.GROUP) {
            let name = '';
            if (message.sender) {
                name = (message.sender?.name || '') + name;
            } else if (message.body) {
                name = (message.body.sender?.name || '') + name;
            }
            // if (message.sender.userId === currentEmployee?.userId) {
            if (isEqualEmployee(message.sender, currentEmployee)) {
                name = '';
            }
            content = `${name ? `${name}:` : ''} ${content}`;
        }
        return content;
    } else {
        // 辅助消息提示
        return '';
    }
}

export function isEqualApp(
    employeeI: Employee | EmployeeAttribute,
    employeeII: Employee | EmployeeAttribute
): boolean {
    return employeeI.app === employeeII.app;
}

export function isEqualTeam(
    employeeI: Employee | EmployeeAttribute,
    employeeII: Employee | EmployeeAttribute
): boolean {
    return employeeI.teamId === employeeII.teamId;
}

export function isEqualEmployee(
    employeeI: Employee | EmployeeAttribute,
    employeeII: Employee | EmployeeAttribute
): boolean {
    if (!employeeI) {
        return false;
    }
    if (!employeeII) {
        return false;
    }
    return (
        employeeI.app === employeeII.app &&
        employeeI.userId === employeeII.userId &&
        employeeI.teamId === employeeII.teamId
    );
}

export function isEqualUid(uidI: UID, uidII: UID): boolean {
    return (
        uidI &&
        uidII &&
        uidI.app === uidII.app &&
        uidI.pin === uidII.pin &&
        uidI.teamId === uidII.teamId
    );
}

export function isSameApp(
    user: { app?: string; ddAppId?: string },
    employee: { app?: string; ddAppId?: string }
): boolean {
    return (user.app || user.ddAppId) === (employee.app || employee.ddAppId);
}

export function isGroupMemberDeleteMessage(message: any) {
    return message.type === MessageRespType.GROUP_MEMBER_DELETE;
}

/**
 * 是不是系统类消息
 * @param message
 */
export function isSysMessage(message: any): boolean {
    return message && message.type === ChatMessageType.SYS;
}

export function isGroupNoticeMsg(message: any): boolean {
    return message && message.from.pin === 'eopen-push_gc1EijDXzxUehGW1ydK5';
}

export function isRevokeMessage(message: any): boolean {
    return message && message.revoke === 1;
}

export function isShareCardMessage(message: any) {
    return message.type === ChatMessageType.TEMPLATE2 && message.template.nativeId === 'share_card';
}

export function isDownloadSysMesssage(message: any): boolean {
    return message && message.category === MessageEmitCategory.SYSTEM && message.flag === 1;
}

export function isSnapchatTimeSysMesssage(message: any): boolean {
    return message && message.category === MessageEmitCategory.SYSTEM && message.flag === 3;
}

/**
 * 是否通知类消息
 * @param message
 */
export function isNoticeMessage(message: any): boolean {
    return message && message.type === 'unified_notice_message';
}

/**
 * 是不是已读的消息
 * @param message
 * @param currentEmployee
 */

export function isReadMessage(message: any, currentEmployee: Employee, lastMsg?: any): boolean {
    if (checkMeetingSysMessage({ message })) {
        return false;
    }
    if (mySendMessage({ message, currentEmployee }) && !message.revoke) {
        if (message?.readUids && message.readUids.length > 0) {
            const index = message.readUids.findIndex((item: any) => {
                return !isEqualUid(item, employeeToUid(currentEmployee));
            });
            return index > -1;
        }
    }
    // 最后一条消息已读
    if (lastMsg && lastMsg.mid > message.mid) {
        return (
            !isEqualEmployee(lastMsg.sender, currentEmployee) ||
            isReadMessage(lastMsg, currentEmployee)
        );
    }
    return false;
}

/**
 * 获取单聊的对方用户信息
 */
export function getSingleInfo(session: Session): Employee | null {
    if (session.isSingle) {
        return session.info as Employee;
    }
    return null;
}

/**
 * 判断是不是外部联系人
 */
export function isOuterContacts(session: Session, currentEmployee: Employee) {
    const info = getSingleInfo(session);
    if (info) {
        return !isSameApp(info, currentEmployee);
    }
    return false;
}

/**
 * 转  uid
 * @param employee
 */
export function employeeToUid(employee: Employee | EmployeeAttribute): UID {
    return {
        app: employee?.app,
        pin: employee?.userId,
        teamId: employee?.teamId,
    };
}

/**
 * 转 Employee
 * @param uid
 */
export function uidToEmployee(uid: UID): Partial<Employee> {
    return {
        app: uid?.app,
        userId: uid?.pin,
        teamId: uid?.teamId,
    };
}

export function setMessageGroupId(
    message: ChatMessage,
    prevMessage: ChatMessage | null
): ChatMessage {
    if (prevMessage) {
        let { sender, groupId, timestamp, category } = prevMessage;
        // 是同一个人发送且在 1 (可配置) 分钟之内的，标识为一个分组
        if (
            isEqualEmployee(message.sender, sender) &&
            Math.abs(message.timestamp - timestamp) < 60 * 1000 &&
            groupId &&
            category !== 'system'
        ) {
            message.groupId = groupId;
        } else {
            message.groupId = message.id;
        }
        return message;
    }
    message.groupId = message.id;
    return message;
}

/**
 * 是否群管理员
 * @param session
 * @param currentEmployee
 */
// export function isGroupAdmin({
//     session,
//     currentEmployee,
// }: {
//     session: Session;
//     currentEmployee: Employee;
// }): boolean {
//     if (session.isGroup) {
//         const owner = (session.info as Group).owner || {};
//         const user = employeeToUid(currentEmployee);
//         return isEqualUid(owner, user);
//     }
//     return false;
// }

export function isGroupOwner({
    session,
    currentEmployee,
}: {
    session: Session;
    currentEmployee: Employee | EmployeeAttribute;
}): boolean {
    if (session.isGroup) {
        const owner = (session.info as Group).owner || {};
        const user = employeeToUid(currentEmployee);
        return isEqualUid(owner, user);
    }
    return false;
}

/**
 * 是否是群管理员
 * @param groupRosters
 * @param currentEmployee
 */
export function isGroupAdmin(groupRosters: Partial<GroupRoster>[], currentEmployee: Employee) {
    const roster = (groupRosters || []).find((item) => {
        if (item.info) {
            return isEqualEmployee(item.info, currentEmployee);
        }
        return false;
    });
    return ['administrator', 'sub_administrator'].includes(roster?.identity || '');
}

/**
 * 是否被邀请者
 * @param session
 * @param currentEmployee
 */
export function isInvitee({
    message,
    currentEmployee,
}: {
    message: any;
    currentEmployee: Employee;
}): boolean {
    const members = message.members;
    if (members && members.length > 0) {
        const index = message.members.findIndex((item: any) => {
            return isEqualUid(item, employeeToUid(currentEmployee));
        });
        return index > -1;
    }
    return false;
}

export function toMe({ session, employee }: { session: Session; employee: Employee }) {
    if (session.isSingle) {
        const info = session.info as Employee;
        return isEqualEmployee(info, employee);
    } else {
        return false;
    }
}

export function isSameTeam({ session, employee }: { session: Session; employee: Employee }) {
    if (session.isSingle) {
        const info = session.info as Employee;
        return info.teamId === employee.teamId;
    } else {
        return false;
    }
}

/**
 * 获取消息发送方
 * @param chatMessage
 * @param employee
 * @param session
 */
export const getMessageSendType = (
    { sender }: ChatMessage,
    employee: Employee,
    session?: Session
): MessageSendType => {
    let _employee = employee;
    // 当前用户未获取到数据兼容
    if (!_employee?.userId) {
        _employee = getCurrentUserFromLocal() as Employee;
    }
    if (isEqualEmployee(sender, _employee)) {
        if (
            session &&
            [SessionType.SINGLE, SessionType.SECRET_SINGLE].includes(session.sessionType)
        ) {
            const info = session.info as Employee;
            if (isEqualEmployee(info, _employee)) {
                // 自已给自已发
                return MessageSendType.SELF;
            }
        }
        return MessageSendType.OWNER;
    } else {
        return MessageSendType.OTHER;
    }
};

export const getCurrentUserFromLocal = () => {
    let config = FocusSDK.getConfigData() || '' ? JSON.parse(FocusSDK.getConfigData()) : {};
    let userCache = config?.userCache;
    if (!userCache && config.auth) {
        userCache = { ...config.auth.teamUserInfo, userId: config.auth.userId };
    }
    if (userCache) {
        return {
            userId: userCache.userId,
            teamId: userCache.teamId,
            app: userCache.ddAppId,
            nickName: userCache.realName,
            name: userCache.realName,
            avatar: userCache.headImg,
        };
    } else {
        return {};
    }
};

export function senderIsInvitee({ message }: { message: any }) {
    if (message.type === 'group_member_in' && message.invitees && message.invitees.length === 1) {
        const { sender, invitees } = message;
        return isEqualUid(employeeToUid(sender), invitees[0]);
    }
    return false;
}

/**
 * 我发送的消息
 * @param message
 * @param currentEmployee
 */
export function mySendMessage({
    message,
    currentEmployee,
}: {
    message: any;
    currentEmployee: Employee;
}) {
    return isEqualEmployee(message?.sender, currentEmployee);
}

/**
 * 我接收的消息
 * @param message
 * @param currentEmployee
 */
export function myReceiveMessage({
    message,
    currentEmployee,
}: {
    message: any;
    currentEmployee: Employee;
}) {
    return isEqualEmployee(message.to || {}, currentEmployee);
}

// eslint-disable-next-line max-params
export function checkGroupSysMessage({ message }: { message: any }): any {
    const sysMsgGroupConfig: any = sysMsgConfig['system-message-group'];
    const receiveRoles = sysMsgGroupConfig[message.type];
    if (message.type === MessageRespType.GROUP_SET) {
        // 如果是群设置的消息通知 检查一下 是否有相关配置接收用户
        if (!combGroupSetField(message)) {
            return null;
        }
    }
    if (message.flag === 1 || message.flag === 99) {
        return message;
    }
    if (!receiveRoles) {
        // 没有配置项，群里任何角色都不可以接收这个系统消息
        return null;
    }
    return message;
}

export function checkSingleSysMessage({ message }: { message: any }) {
    return message;
}

export function checkMeetingSysMessage({ message }: { message: any }) {
    if (message.flag === 100) {
        return true;
    }
    return false;
}

export function checkNoticeSysMessage({ message }: { message: any }) {
    return message;
}

export function checkSysMessage({ message, session }: { message: any; session: Session }): any {
    if (session.isGroup) {
        // 判断 群聊 系统类消息显示权限
        return checkGroupSysMessage({ message });
    } else if (session.isSingle && !session.isNotice) {
        // 判断 单聊 系统类消息显示权限
        return checkSingleSysMessage({ message });
    } else if (session.isNotice) {
        return checkNoticeSysMessage({ message });
    }
    return null;
}

export function getReplyMessageDesc(message: ChatMessage) {
    let content = '';
    switch (message.type) {
        case ChatMessageType.TEXT:
            content = `${message.content}`;
            break;
        case ChatMessageType.IMAGE:
            content = `[${i18n.t('chat:image')}]`;
            break;
        case ChatMessageType.VOICE:
            // content = `[${i18n.t('chat:voice')}]`;
            content = `[${i18n.t('chat:no_support_message_type')}]`;
            break;
        case ChatMessageType.VIDEO:
            content = `[${i18n.t('chat:video')}]`;
            break;
        case ChatMessageType.FILE:
            content = `[${i18n.t('chat:file')}]${shortFileName(message.name)}`;
            break;
        case ChatMessageType.JOYSPACEFILE:
            if (message.content === message.webUrl) {
                content = `[${i18n.t('chat:document')}]:${message.fileName}`;
            } else {
                const _con = message.content || message.webUrl || message.fileName || '';
                content = _con.replace(urlReg, `[${i18n.t('chat:link')}]`);
            }
            break;
        case ChatMessageType.TEMPLATE2:
            if (!message.template) {
                debugger;
            }
            if (message.template.nativeId === 'merge_forward') {
                content = `[${i18n.t('chat:communicate_record')}]`;
            } else if (message.template.nativeId === 'share_card') {
                content = `[${i18n.t('chat:user_card')}]`;
            } else {
                content = `[${i18n.t('chat:link')}]${get(message, 'data.0.sharelink.title', '')}`;
            }
            break;
        case ChatMessageType.GROUPNOTICE:
            content = `[${i18n.t('chat:group-notice')}]`;
            break;
        default:
            content = `[${i18n.t('chat:no_support_message_type')}]`;
            break;
    }

    return mattchEmoji(convertStringToEmoji(stringEncode(content)));
}

export function checkMessageSupport(message: ChatMessage) {
    // if ((message?.expire || 0) > 0) {
    //     return false;
    // }

    const supportConfig: any = {
        [ChatMessageType.TEXT]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_TEXT],
        revoke_message: config[ChatConfig.CHAT_MESSAGE_SUPPORT_REVOKE],
        [ChatMessageType.IMAGE]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_IMAGE],
        [ChatMessageType.FILE]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_FILE],
        [ChatMessageType.VOICE]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_VOICE],
        [ChatMessageType.VIDEO]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_VIDEO],
        [ChatMessageType.TEMPLATE2]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_TEMPLATE2],
        [ChatMessageType.SYS]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_SYS],
        [ChatMessageType.JOYSPACEFILE]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_JOYSPACE_FILE],
        [ChatMessageType.SOLITAIRE]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_GROUPSOLITAIRE],
        [ChatMessageType.GROUPNOTICE]: config[ChatConfig.CHAT_MESSAGE_SUPPORT_GROUP_NOTICE],
        unified_notice_message: config[ChatConfig.CHAT_MESSAGE_SUPPORT_NOTICE],
    };
    return supportConfig[message.type] || false;
}

export function sleep(ms: number) {
    return new Promise<void>(function (resolve) {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export function checkSelectUserData(selectedResult: any) {
    return get(selectedResult, 'data.result.0.origin.info.userId', '');
}

/**
 * 获取人员选择器， 选择的人员
 * 返回 app userId teamId
 * @param selectedResult 选择的结果集
 */
export function getInvitees(selectedResult: any): UID[] {
    const array = get(selectedResult, 'data.result', []);
    if (array.length === 0) {
        return [] as UID[];
    }
    return array.map((item: any) => {
        const info = item?.origin?.info || item?.origin;
        return {
            ...info,
            ...{
                avatar: item.avatar,
                name: item.name,
                pin: info?.userId || info?.pin || item?.userId || item?.pin,
                app: info?.app || info?.appId || item?.app || item?.appId,
                teamId: item?.teamId || info?.teamId,
            },
        };
    });
}

/**
 * 构建群成员信息
 * @param rosters
 */
export function mapRosters(rosters: any): any {
    return rosters.map((item: any) => {
        return {
            identity: item.identity,
            info: pick(item.info, [
                'app',
                'avatar',
                'name',
                'userId',
                'teamId',
                'e_dept_full_name',
                'unitName',
                'unitDeptId',
                'deptId',
                'deptName',
                'titleName',
            ]),
            user: item.user,
        };
    });
}

/**
 * 查找管理员
 * @param rosters
 */
export function getGroupAdmin(rosters: any): any {
    return (
        rosters.find((item: any) => {
            return item.identity === 'administrator';
        })?.info || {}
    );
}

/**
 * 查找管理员位置
 * @param rosters
 */
export function getGroupOwnerIndex(rosters: any): any {
    return rosters.findIndex((item: any) => {
        return item.identity === 'administrator';
    });
}

/**
 * 查找群成员位置
 */

/**
 * 查找管理员位置
 * @param rosters
 * @param currentEmployee
 */
export function getRosterIndex(rosters: any, currentEmployee: Employee): any {
    return rosters.findIndex((item: any) => {
        return isEqualEmployee(item.info, currentEmployee);
    });
}

/**
 * 变换管理员位置
 * @param groupRosters
 */
export function sortGroupRosters(groupRosters: any): any {
    const group = lodashGroupBy(groupRosters, 'identity');
    // administrator: [{…}]
    // ordinary: (2) [{…}, {…}]
    // sub_administrator: (3) [{…}, {…}, {…}]
    return [
        ...(group.administrator || []),
        ...(group.sub_administrator || []),
        ...(group.ordinary || []),
    ];
}

/**
 * 变换管理员位置
 * @param groupRosters
 */
export function replaceGroupAdminIndex(groupRosters: any): any {
    const adminIndex = getGroupOwnerIndex(groupRosters);
    if (adminIndex > 0) {
        const admin = groupRosters[adminIndex];
        groupRosters[adminIndex] = groupRosters[0];
        groupRosters[0] = admin;
    }
    return groupRosters;
}

export function getSessionIndex(sessionList: any, session: any): any {
    return sessionList.findIndex((s: Session) => {
        return s.sessionId === session.sessionId;
    });
}

export function addOneSession(allSessionList: any, session: any) {
    const index = getSessionIndex(allSessionList, session);
    if (index >= 0) {
        allSessionList[index] = session;
    } else {
        allSessionList.unshift(session);
    }
    return allSessionList.sort((a: { timestamp: any }, b: { timestamp: any }) => {
        return (b.timestamp || 0) - (a.timestamp || 0);
    });
}

export function mapUserDataToUid(userData: UserCardData): UID {
    const fields = userData.fields || [];
    const avatarInfo = fields.find((item: any) => {
        return item.key === 'avatar';
    });
    const nameInfo = fields.find((item: any) => {
        return item.key === 'name';
    });
    return {
        app: userData.appId,
        pin: userData.userId,
        teamId: userData.teamId,
        avatar: avatarInfo?.val || '',
        name: nameInfo?.val || '',
    };
}

export function mapWebSearchDataToUid(data: any) {
    const userInfo = data.origin;
    return {
        app: userInfo.app,
        pin: userInfo.userId,
        teamId: userInfo.teamId,
        avatar: userInfo.avatar || '',
        name: userInfo.realName || '',
    };
}

export function mapDeskSearchDataToUid(data: any) {
    return {
        app: data.app,
        pin: data.userId,
        teamId: data.teamId,
        avatar: data.avatar || '',
        name: data.realName || '',
    };
}

/**
 * 判断是否是一端
 * @param message
 */
export function isSameClient(message: any) {
    const from = message.from || {};
    if (from.clientType) {
        return (
            from.clientType === ClientType.IPAD ||
            from.clientType === ClientType.MAC ||
            from.clientType === ClientType.PC
        );
    } else {
        const artStr = get(message, 'from.art', '');
        if (!artStr) {
            return true;
        }
        const art = attempt(JSON.parse, artStr);
        if (isError(art)) {
            return true;
        }
        const clientType = art.clientType || '';
        return (
            clientType === ClientType.IPAD ||
            clientType === ClientType.MAC ||
            clientType === ClientType.PC
        );
    }
}

export function getNoticeNotifyMessage(
    session: Session,
    message: any,
    currentEmployee: Employee
): any {
    return {
        name: session.info.name,
        avatar: session.info.avatar,
        content: message.title,
    };
}

export function getSingleNotifyMessage(
    session: Session,
    message: any,
    currentEmployee: Employee
): any {
    return {
        name: session.info.name,
        avatar: session.info.avatar,
        content: getLastMessageDesc(message, session.sessionType, currentEmployee, true),
    };
}

export function getGroupNotifyMessage(
    session: Session,
    message: any,
    currentEmployee: Employee
): any {
    return {
        name: session.info.name,
        avatar: session.info.avatar,
        content: getLastMessageDesc(message, session.sessionType, currentEmployee, true),
    };
}

/**
 * 无效session
 * @param session
 */
export function isInValidSession(session: Session): boolean {
    const { timestamp, info } = session || {};
    return !(timestamp && info && info.name);
}

export const isInValidSessionInfo = isInValidSession;

/**
 * 是置定的会话
 * @param session
 */
export function isTopSession(session: Session): boolean {
    return ((session.settings || {}).top || 0) > 0;
}

/**
 * 是屏蔽消息提醒的会话
 * @param session
 */
export function isShieldSession(session: Session): boolean {
    return ((session.settings || {}).shield || 0) === 1;
}

/**
 * 阅后即焚会话焚毁时间
 * @param session
 */
export function messageBurnOfSession(session: Session): number {
    return (session.settings || {}).messageBurn || 0;
}

/**
 * 不是屏蔽消息的会话
 */
export function isNotShieldSession(session: Session): boolean {
    return ((session.settings || {}).shield || 0) > 0;
}

/**
 * 是否开启权限检查，全员可邀请入群
 * @param session
 */
export function isPermissionCheckSession(session: Session): boolean {
    if (session.isGroup) {
        const info = session.info as Group;
        return info.permissionCheck === 0;
    }
    return false;
}

/**
 * 加群验证
 * @param session
 */
export function isNeedVerifySession(session: Session): boolean {
    if (session.isGroup) {
        const info = session.info as Group;
        return info.needVerify === 1;
    }
    return false;
}

/**
 * 是可搜索的群会话
 * @param session
 */
export function isCanSearchGroupSession(session: Session): boolean {
    return session.isGroup && (session.info as Group).canSearch === 1;
}

/**
 * 是按单位选择成员的会话 群组 反着来 0可见  1不可见
 * @param session
 */
export function isGroupVisibleUnit(session: Session): boolean {
    return session.isGroup && (session.info as Group).visibleUnit === 1;
}

/**
 * 显示成员单位名称的会话
 * @param session
 */
export function isGroupChatVisibleUnit(session: Session): boolean {
    // console.log('session.info', session.info);
    return session.isGroup && (session.info as Group).chatVisibleUnit === 1;
}

/**
 * 是加群验证的群会话
 * @param session
 * GroupApprovalRule.IDENTITY 只能群主或管理员能主动邀请进群，主动进群和普通成员邀请会被拒绝
 */
export function isIdentityApproveRuleGroupSession(session: Session): boolean {
    return (
        session.isGroup && (session.info as Group).approvalRule === GroupApprovalRule.PRE_APPROVAL
    );
}

/**
 * 是禁言全体群成员（不包括群主）的群会话
 * @param session
 * forbidAll 1全员禁言 0或空 正常发言状态
 */
export function isBannedPostGroupSession(session: Session): boolean {
    return session.isGroup && (session.info as Group).forbidAll === 1;
}

/**
 * 是可以查看全体成员群成名
 * @param session
 * forbidAll 2 可以查看，其它不可以
 */
export function isCardViewGroupSession(session: Session): boolean {
    return ((session.isGroup && (session.info as Group).forbidViewCard) || 2) === 2;
}

// 判断是不是专班群
// export function

/**
 * 是否同一会话
 * @param sessionI
 * @param sessionII
 */
export function isEqualSession(sessionI: Session, sessionII: Session): boolean {
    return sessionI.sessionId === sessionII.sessionId;
}

export function isSpecialProjectGroup(group: Group) {
    return group.business === 'project' || group.business === 'project_default';
}

export function isSpecialProjectMasterGroup(group: Group) {
    return group.business === 'project_default';
}

/**
 * 判断是不是外部群 app一样  teamId不一样
 * @param group
 */
export function isExternalGroup(group: Group): boolean {
    return group.kind === GroupKind.EXTERNAL && group.subKind === 6;
}

/**
 * 判断是不是跨平台群会话 app不一样
 * @param group
 */
export function isCrossPlatformGroup(group: Group): boolean {
    return group.kind === GroupKind.EXTERNAL && group.subKind === 3;
}

/**
 * 判断是不是外部群会话
 * @param session
 */
export function isExternalGroupSession(session: Session): boolean {
    if (session.isGroup) {
        const info = session.info as Group;
        return isExternalGroup(info);
        // return info.kind === GroupKind.EXTERNAL && info.subKind === 6;
    }
    return false;
}

/**
 * 判断是不是专班群会话
 * @param session
 */
export function isSpecialProjectSession(session: Session): boolean {
    if (session.isGroup) {
        const info = session.info as Group;
        return isSpecialProjectGroup(info) || isSpecialProjectMasterGroup(info);
    }
    return false;
}

/**
 * 判断是不是专班大群会话
 * @param session
 */
export function isSpecialProjectMasterSession(session: Session): boolean {
    if (session.isGroup) {
        const info = session.info as Group;
        return isSpecialProjectMasterGroup(info);
    }
    return false;
}

/**
 * 判断是不是跨平台群会话
 * @param session
 */
export function isCrossPlatformGroupSession(session: Session): boolean {
    if (session.isGroup) {
        const info = session.info as Group;
        return isCrossPlatformGroup(info);
        // return info.kind === GroupKind.EXTERNAL && info.subKind === 3;
    }
    return false;
}

/**
 * 获取会话配置项，开关值
 * @param session
 * @param type
 */
export function getSessionSettingValue(session: Session, type: SessionSettingsType): boolean {
    switch (type) {
        case SessionSettingsType.TOP:
            return isTopSession(session);
        case SessionSettingsType.SHIELD:
            return isNotShieldSession(session); // (session.settings || {}).shield || 0) !== 1;
        case SessionSettingsType.PERMISSION_CHECK:
            return isPermissionCheckSession(session); // (session.settings || {}).shield || 0) !== 1;
        case SessionSettingsType.VISIBLE_UNIT:
            return !isGroupVisibleUnit(session);
        case SessionSettingsType.CHAT_VISIBLE_UNIT:
            return isGroupChatVisibleUnit(session);
        case SessionSettingsType.NEED_VERIFY:
            return isNeedVerifySession(session); // (session.settings || {}).shield || 0) !== 1;
        case SessionSettingsType.CAN_SEARCH:
            return isCanSearchGroupSession(session);
        case SessionSettingsType.APPROVAL_RULE:
            return isIdentityApproveRuleGroupSession(session);
        case SessionSettingsType.BANNED_POST:
            return isBannedPostGroupSession(session);
        case SessionSettingsType.CARD_VIEW:
            return isCardViewGroupSession(session);
        default:
            return false;
    }
}

/**
 * 不可以打开用户名片
 * @param session 当前的会话
 * @param currentEmployee 当前操作用户
 * @param targetEmployee 要点开的用户
 */
export function canNotOpenUserCard(
    session: Session,
    currentEmployee: Employee | EmployeeAttribute,
    targetEmployee: Employee | EmployeeAttribute
) {
    if (isGroupOwner({ session, currentEmployee })) {
        // 如果是群管理员，没有此限制，可以查看。
        return false;
    }
    return !isEqualEmployee(currentEmployee, targetEmployee) && !isCardViewGroupSession(session);
}

/**
 * 拒绝入群 群助手卡片提示内容
 * xxx 拒绝 你 邀请 xxx 加入 xxx
 */
// eslint-disable-next-line max-params
export function rejectAddGroupApplyContent(
    groupOwner: Employee,
    inviter: Employee,
    invitee: Employee[],
    group: Group
): string {
    const tags = [];
    tags.push(
        `<span data-label="groupOwner" data-value="${convertUidToString(
            employeeToUid(groupOwner)
        )}" class="apply-details-content-link groupOwner">${groupOwner.name}</span>`
    );
    tags.push(`<span>${i18n.t('chat:apply.refuse')}</span>`);
    tags.push(
        `<span data-label="inviter" data-value="${convertUidToString(
            employeeToUid(inviter)
        )}" class="apply-details-content-link inviter">${i18n.t('chat:apply.you')}</span>`
    );
    tags.push(`<span>${i18n.t('chat:apply.invite')}</span>`);
    tags.push(
        `<span data-label="invitee" data-value="${convertUidToString(
            employeeToUid(invitee[0])
        )}" class="apply-details-content-link invitee">${invitee[0].name}</span>`
    );
    tags.push(`<span >${i18n.t('chat:apply.add')}</span>`);
    tags.push(
        `<span data-label="group" data-value="${group.gid}" class="apply-details-content-link group">${group.name}</span>`
    );
    return tags.join('');
}

export function convertEmployeeToString(employee: Employee): string {
    return `${employee.userId}:${employee.app}:${employee.teamId}`;
}

// @单位/部门匹配人员
export function extraConvertEmployeeToString(employee: Employee, session: Session): string {
    if (!isGroupVisibleUnit(session)) {
        return `${employee.unitDeptId}:${employee.app}:${employee.teamId}`;
    } else {
        return `${employee.deptId}:${employee.app}:${employee.teamId}`;
    }
}

export function filterDeptFullName(deptFullName: string | undefined): string {
    if (!deptFullName) {
        return '';
    }
    const fileds = deptFullName.split('-');
    const length = fileds.length;
    return length > 2 ? fileds[length - 2] : fileds[length - 1];
}

/**
 * userId转换成
 * @export
 * @param {User} user
 * @return {*}  {string}
 */
export function convertUserToString(user: User): string {
    return `${user.userId}:${user.appId}:${user.teamId}`;
}

export function parseEmployeeFromString(id: string): Employee {
    const seeds = id.split(':');
    const employee = {
        userId: seeds[0],
        app: seeds[1],
        teamId: seeds[2],
    };
    return employee as Employee;
}

export function addContactsSuccess(message: any): boolean {
    return (
        message &&
        ((message.type === ChatMessageType.SYS &&
            message.sys_msg_type === 'SetRosterSuccessNotice') ||
            (message.type === ChatMessageType.TEXT && message?.extend?.addRoster))
    );
}

export function isMergeForwardMessage(message: any, selectedSession: Session) {
    return (
        message?.template?.nativeId === 'merge_forward' &&
        message?.sessionId !== selectedSession.sessionId
    );
}

export function isJoyWorkMessage(message: any): boolean {
    return message && message.flag === 10000;
}

export function isJoyMeetingMessage(message: any): boolean {
    return message && message.flag === 100 && message.ext && message.ext.avsdk === AVSDK.ZOOM;
}

export function isCloudLinkMeetingMessage(message: any): boolean {
    return message?.ext && message.ext.avsdk === AVSDK.HUAWEI;
}

export function isJAVSDKMeetingMessage(message: any): boolean {
    const avsdk = message?.ext?.avsdk;
    if (!avsdk) {
        return false;
    }
    return [AVSDK.JBRTC, AVSDK.XYLINK].includes(avsdk);
}

export function isSuperviseMessage(message: any): boolean {
    return message?.from?.pin === 'eopen-push_0Gl8b1I3si21n0VVNkrF';
}

export function isTaskMessage(message: any): boolean {
    return message?.from?.pin === 'eopen-push_PZzzvgDrpZVAefKImEfo';
}

// export function isCalendarMessage(message: any): boolean {
//     return message?.from?.pin === '~me69';
// }

export function isAnnounceMessage(message: any): boolean {
    return message?.from?.pin === 'eopen-push_gc1EijDXzxUehGW1ydK5';
}

export function isCloudFileFunc(message: any): boolean {
    let isCloudFile = false;
    if (message.type === ChatMessageType.TEMPLATE2) {
        const sharelink =
            message.data && message.data[0] ? message.data[0].sharelink : { category: '', url: '' };
        const { template } = message;
        if (template.nativeId === 'share_link') {
            if (sharelink?.category === 'jd_doc') {
                isCloudFile = true;
            } else {
                isCloudFile = sharelink.url.includes('joyspace');
            }
        }
    } else if (message.type === ChatMessageType.JOYSPACEFILE) {
        return true;
    }
    return isCloudFile;
}

/**
 *
 * 经协商，多端同步消息使用flag为10000
 * 具体应用使用appname区分
 */
export function isMultiSyncMessage(message: any): boolean {
    return message && message.flag === 10000;
}

/**
 * message 排序算法
 * @param a
 * @param b
 */
export function messageCompare(a: ChatMessage, b: ChatMessage) {
    // system message
    if (!a.mid || !b.mid) {
        return a.timestamp - b.timestamp;
    }
    if (a.mid <= 0 && b.mid <= 0) {
        if (a.beforeMid === b.beforeMid) {
            return a.timestamp - b.timestamp;
        } else {
            return a.beforeMid - b.beforeMid;
        }
    } else if (a.mid <= 0) {
        if ((a.state && a.state & 4) === 4) {
            return a.timestamp - b.timestamp;
        } else {
            return a.beforeMid - b.mid;
        }
    } else if (b.mid <= 0) {
        if (b.state && (b.state & 4) === 4) {
            return a.timestamp - b.timestamp;
        } else {
            return a.mid - b.beforeMid;
        }
    }
    return a.timestamp - b.timestamp;
}

export function getLatestMessages(messages: any[]) {
    // console.log('getLatestMessages', messages);
    let tempMessagesMap = new Map();
    messages.forEach((msg: any) => {
        if (msg.plusList) {
            if (!tempMessagesMap.has(msg.mid)) {
                tempMessagesMap.set(msg.mid, msg);
            }
        } else {
            if (tempMessagesMap.has(msg.id)) {
                const message = tempMessagesMap.get(msg.id);
                if (message.timestamp <= msg.timestamp) {
                    // tempMessagesMap.set(msg.id, {
                    //     ...msg,
                    //     timestamp: message.timestamp,
                    // });
                    tempMessagesMap.set(msg.id, msg);
                }
            } else {
                tempMessagesMap.set(msg.id, msg);
            }
        }
    });
    let tempMessages: any[] = [];
    tempMessagesMap.forEach((value: any) => {
        tempMessages.push(value);
    });
    // console.log('tempMessages', tempMessages);
    return tempMessages;
}

export function sendGroupSessionNotify(allSessionList: Session[]) {
    const payload = allSessionList.reduce((result: any, session: Session) => {
        if (session.isGroup) {
            result.push({
                sessionId: session.sessionId,
                gid: session.gid,
                unreadCount: session.unreadCount,
                lastMsg: session.lastMsg,
            });
        }
        return result;
    }, []);
    if (payload.length === 0) {
        return;
    }
    const data = {
        source: 'group-session-info',
        payload,
    };

    window.postMessage(data, location.origin);
}

/**
 * 根据selectedPin筛选内容
 * @param allSessionMessages 会话内容
 * @param selectedPin 选中的tab类型
 */
export function filterNoticeMassagesByType(allSessionMessages: ChatMessage[], selectedPin: string) {
    let sessionMessages: ChatMessage[] = [];
    switch (selectedPin) {
        case CHAT_NOTICE.ALL_PIN:
            sessionMessages = allSessionMessages;
            break;
        case CHAT_NOTICE.OTHER_PIN:
            sessionMessages = allSessionMessages.filter(
                (message) => !OTHER_EXCLUDE_TABS_PIN.includes(message.from.pin)
            );
            break;
        default:
            sessionMessages = allSessionMessages.filter(
                (message) => message.from.pin === selectedPin
            );
            break;
    }
    return sessionMessages.sort((a: { timestamp: any }, b: { timestamp: any }) => {
        return (a.timestamp || 0) - (b.timestamp || 0);
    });
}

/**
 * 计算ai助手会话最新的时间戳
 * @param aiNoticeSessionList ai助手列表
 * @returns 最大的时间戳
 */
export function computeAITimestamp(aiNoticeSessionList: Session[]) {
    return aiNoticeSessionList.length
        ? aiNoticeSessionList.reduce((a, b) => {
              if (a.timestamp < b.timestamp) {
                  return b;
              } else {
                  return a;
              }
          }).timestamp || 0
        : 0;
}

/**
 * 发送图片、文件支持Enter发送条件校验
 * @param isMulti 是否发送多个
 * @param selectedList 选中列表
 */
export function sendMessageByKeyboard(e: any, isMulti: boolean, selectedList: any[]) {
    e.preventDefault();
    e.stopPropagation();
    // console.log('mention keydown', e.key);
    if (e.key === 'Enter') {
        if (isMulti) {
            if (!selectedList?.length) {
                return false;
            }
            return true;
        }
        return true;
    }
}

// 判断是否有选中的文字
export function getSelected() {
    if (window.getSelection) {
        return window.getSelection()?.toString();
    } else {
        let documents: any = document;
        const selection = documents.selection?.createRange();
        if (selection.text) {
            return selection.text.toString();
        }
        return '';
    }
}
// 判断图片地址是否有效
export function checkImgExists(imgurl: any) {
    return new Promise(function (resolve, reject) {
        let ImgObj = new Image();
        ImgObj.src = imgurl;
        ImgObj.onload = function (res) {
            resolve(res);
        };
        ImgObj.onerror = function (err) {
            reject(err);
        };
    });
}
