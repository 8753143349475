import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { UserCardPayload } from '@/models';
import { Employee, Session, UID } from '@/types/chat';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ApplyGroupState } from '@/types/chat/enum';
import ImService from '@/server/ImService';
import { isEqualUid } from '@/utils/chat/message';
import { message as toast } from 'antd';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import Bus from '@/utils/bus';
import { UserCardType } from '@/types/UserCard';
import i18n from '@/i18n';
function ApplyGroupCard({
    message,
    toggleUserCardModal,
    updateSessionMessageMid,
    selectedSession,
    currentEmployee,
}: {
    message: any;
    toggleUserCardModal: Function;
    updateSessionMessageMid: Function;
    selectedSession: Session;
    currentEmployee: Employee;
}) {
    const { t } = useTranslation('chat');
    const [invitee, setInvitee] = useState<UID>({ app: '', name: '', pin: '', teamId: '' });
    const [applyState, setApplyState] = useState(ApplyGroupState.INIT);

    const findGroupRoster = useCallback(
        async (invitee: UID): Promise<any> => {
            const imService = ImService.getInstance();
            const rosters = await imService.getGroupRosters(message.infox.groupId, true);
            return rosters.find((item) => {
                if (!item.user) return false;
                return isEqualUid(item.user, invitee);
            });
        },
        [message.infox.groupId]
    );

    // 同意操作 直接拉用户进群
    const agreeAction = useCallback(async () => {
        if (message.infox?.groupId && invitee) {
            // 查询群成员中有没有这个成员
            const imService = ImService.getInstance();
            const roster = await findGroupRoster(invitee);
            if (roster) {
                toast.warn(
                    t('apply.apply-user-done', {
                        first: roster.info.name,
                        second: message.infox.groupName,
                    })
                );
                setApplyState(ApplyGroupState.AGREED);
                await imService.updateAddGroupApplyState(
                    selectedSession.sessionId,
                    message,
                    ApplyGroupState.AGREED
                );
                return false;
            }
            let result = await imService.applyUser(message, [invitee], '');
            if (result.success) {
                let result = await imService.updateAddGroupApplyState(
                    selectedSession.sessionId,
                    message,
                    ApplyGroupState.AGREED
                );
                if (result) {
                    setApplyState(ApplyGroupState.AGREED);
                    toast.success(t('added-successfully'));
                    return;
                }
            } else {
                const msg = result.data?.msg || '';
                toast.error(`${t('failed')}:${msg}`);
                if (result.data?.reviewStatus === 2) {
                    imService.updateAddGroupApplyState(
                        selectedSession.sessionId,
                        message,
                        ApplyGroupState.REJECTED
                    );
                    setApplyState(ApplyGroupState.REJECTED);
                }
            }
        }
    }, [invitee, t, selectedSession.sessionId, message, findGroupRoster]);

    const refuseAction = useCallback(async () => {
        const roster = await findGroupRoster(invitee);
        const imService = ImService.getInstance();
        if (roster) {
            toast.warn(
                t('apply.apply-user-done', {
                    first: roster.info.name,
                    second: message.infox.groupName,
                })
            );
            setApplyState(ApplyGroupState.AGREED);
            await imService.updateAddGroupApplyState(
                selectedSession.sessionId,
                message,
                ApplyGroupState.AGREED
            );
            return false;
        }
        const result = await imService.updateAddGroupApplyState(
            selectedSession.sessionId,
            message,
            ApplyGroupState.REJECTED
        );
        if (result) {
            const { noticeType, groupId } = message.infox;
            if (noticeType === 'application' && groupId) {
                const res = await imService.refuseUser(currentEmployee, groupId, message);
                if (res.success) {
                    toast.success(t('success'));
                } else {
                    const msg = res.data?.msg || '';
                    toast.error(`${t('failed')}:${msg}`);
                }
            }
            message.infox = {
                ...message.infox,
                applyState: ApplyGroupState.REJECTED,
            };
            updateSessionMessageMid({
                sessionId: message.sessionId,
                sendResult: {
                    body: message,
                },
            });
            setApplyState(ApplyGroupState.REJECTED);
        } else {
            toast.error(t('failed'));
        }
    }, [
        selectedSession.sessionId,
        message,
        t,
        invitee,
        findGroupRoster,
        currentEmployee,
        updateSessionMessageMid,
    ]);

    useEffect(() => {
        const { applyState } = message.infox;
        if (applyState) {
            setApplyState(applyState);
        }
        const { from, noticeType } = message.infox;

        if (from) {
            const fromJson = attempt(JSON.parse, from);
            if (isError(fromJson)) {
                return;
            }
            if (noticeType !== 'reply') {
                setInvitee(fromJson);
            }
        }
    }, [message]);

    // 发送再次申请
    // const reapply = useCallback(() => {
    // const instance = ImService.getInstance();
    // // 需要加群验证
    // const groupOwner = isGroupOwner({ session: selectedSession, currentEmployee });
    // if (!groupOwner && isIdentityApproveRuleGroupSession(selectedSession)) {
    //     const result = await instance.inviteAddGroupApply(
    //         selectedSession.sessionId,
    //         currentEmployee,
    //         invitees,
    //         'application_noreply' // application_noreply 拒绝后不可以再次审请
    //     );
    //     if (result) {
    //         message.warn(t('apply.send_to_admin'));
    //     } else {
    //         message.error(t('add-failed'));
    //     }
    //     return false;
    // }
    // }, [message]);

    const stateNode = useMemo(() => {
        // console.log('stateNode ===》', message);
        if (message.infox.noticeType === 'reply') {
            // 可以再次申请
            return <div className="agreed">{t('apply.rejected')}</div>;
            // return (
            // <div className="apply-setting-action">
            //     <button className="agree" onClick={reapply}>
            //         {t('apply.reapply')}
            //     </button>
            // </div>
            // );
        } else if (applyState === ApplyGroupState.AGREED) {
            return <div className="agreed">{t('apply.agreed')}</div>;
        } else if (applyState === ApplyGroupState.REJECTED) {
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else {
            return (
                <div className="apply-setting-action">
                    <button className="refuse" onClick={refuseAction}>
                        {t('apply.refuse')}
                    </button>
                    <button className="agree" onClick={agreeAction}>
                        {t('apply.agree')}
                    </button>
                </div>
            );
        }
    }, [applyState, message, t, refuseAction, agreeAction]);

    const openUserCard = useCallback(() => {
        const from = attempt(JSON.parse, message.infox.from);
        if (isError(from)) {
            return;
        }
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId: from?.pin,
            teamId: from?.teamId,
            appId: from?.app,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({
        //         type: UserCardType.User,
        //         userId: from.pin,
        //         teamId: from.teamId,
        //         appId: from.app,
        //     });
        // } else {
        //     toggleUserCardModal({
        //         visible: true,
        //         userId: from.pin,
        //         teamId: from.teamId,
        //         appId: from.app,
        //     });
        // }
    }, [message.infox.from]);

    const applyContent = (
        <div className="apply-details-content">
            {/* <span */}
            {/*    className="apply-details-content-link inviter" */}
            {/*    onClick={openUserCard.bind(null, employeeToUid(inviter as Employee))} */}
            {/* > */}
            {/*    {inviter.name} */}
            {/* </span> */}
            {/* <span>{i18n.t('chat:apply.invite')}</span> */}
            {/* <span */}
            {/*    className="apply-details-content-link invitee" */}
            {/*    onClick={openUserCard.bind(null, invitee)} */}
            {/* > */}
            {/*    {invitee.name} */}
            {/* </span> */}
            {/* <span>{i18n.t('chat:apply.add')}</span> */}
            {/* <span className="apply-details-content-link group">{group.name}</span> */}
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.apply-join')}</span>
            <span className="apply-details-content-link group">{message.infox.groupName}</span>
            {message.infox.reason && (
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;{t('apply.reason')}: {message.infox.reason}
                </span>
            )}
        </div>
    );

    const replyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.refuse_you_add')}</span>
            <span>{message.infox.groupName}</span>
        </div>
    );

    const contentNode = useMemo(() => {
        if (message.infox.noticeType === 'reply') {
            return replyContent;
        } else {
            return applyContent;
        }
    }, [applyContent, message.infox.noticeType, replyContent]);

    return (
        <div className="apply-group-card">
            <div className="apply-details">
                <div className="apply-details-title">{message.title}</div>
                {contentNode}
            </div>
            <div className="apply-setting">{stateNode}</div>
        </div>
    );
}

export default connect(
    function ({ chat }: { chat: ChatState }) {
        const { selectedSession, currentEmployee } = chat;
        return { selectedSession, currentEmployee };
    },
    function (dispatch: any) {
        return {
            toggleUserCardModal(payload: UserCardPayload) {
                dispatch({ type: 'app/toggleUserCardModal', payload });
            },
            updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
                dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
            },
        };
    }
)(ApplyGroupCard);
