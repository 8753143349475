/* eslint-disable max-nested-callbacks */
/* eslint-disable complexity */
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import log from '@/utils/logger';
import i18n from 'i18next';
import { message as toast, Button, notification } from 'antd';
import ImService from '@/server/ImService';

import { FocusSDK, getClientType, isFocusEnv } from '@/utils';
import { Employee, SysChatMessage } from '@/types/chat';
import {
    AVSDK,
    BasicUserInfo,
    MeetingInfoType,
    sessionStateContentMap,
    SessionStateEnum,
} from './common';
import { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import dayjs from 'dayjs';
import bus from '@/utils/bus';
import {
    getConferenceByCode,
    createMeeting as requestCreateMeeting,
    getJRTCToken,
    conferenceCall,
    sendConferenceCardMsg,
} from '@/api/meeting';
import { MeetingPlatform } from '@/types/common';
import { getNoticeBot, sendNoticeCardMessage } from '@/api/chat';

import { getStatus as getJDMeetStatus } from '@/components/MeetingJD/utils';
import { getStatus as getXYLinkMeetStatus } from '@/components/MeetingXYLink/utils';
import { joinCloudLink } from '@/components/MeetingCloudLink/utils';
import cache from '@/utils/cache';

const isBigScreen = process.env.REACT_APP_X_FLAG === 'olympic';
const logger = log.getLogger('PortalLayout');

export function getPlatform(avsdk: AVSDK): MeetingPlatform {
    if (avsdk === AVSDK.JBRTC) {
        return MeetingPlatform.JBRTC;
    }
    if (avsdk === AVSDK.HUAWEI) {
        return MeetingPlatform.HW;
    }
    if (avsdk === AVSDK.XYLINK) {
        return MeetingPlatform.XYLINK;
    }
    return MeetingPlatform.CLOUD_UNION;
}
export function getAvsdk(platform: MeetingPlatform): AVSDK {
    if (platform === MeetingPlatform.JBRTC) {
        return AVSDK.JBRTC;
    }
    if (platform === MeetingPlatform.HW) {
        return AVSDK.HUAWEI;
    }
    if (platform === MeetingPlatform.XYLINK) {
        return AVSDK.XYLINK;
    }
    return AVSDK.ZOOM;
}

let currentMeetingInfo: {
    [index: string]: any;
} = {
    [AVSDK.HUAWEI]: null,
    [AVSDK.XYLINK]: null,
    [AVSDK.JBRTC]: null,
    [AVSDK.ZOOM]: null,
};
export function getCurrentMeetingInfo(avsdk: AVSDK) {
    return currentMeetingInfo[avsdk];
}

export function getBigScreenMeetingTopic() {
    if (!isBigScreen) {
        return '线上会议';
    }
    return JSON.parse(FocusSDK.getConfigData())?.settings?.meetingTopic || '调度会议';
}

export function setCurrentMeetingInfo(avsdk: AVSDK, meetingInfo: any) {
    currentMeetingInfo[avsdk] = meetingInfo;
    logger.debug('currentMeetingInfo', currentMeetingInfo);
}

export async function createMeeting(
    avsdk: AVSDK,
    {
        meetingPassword = '',
        topic,
        isaudiooff = true,
        isvideooff = true,
    }: {
        meetingPassword?: string;
        topic: string;
        isaudiooff?: boolean;
        isvideooff?: boolean;
    },
    toUsers?: BasicUserInfo[]
) {
    // if (!isFocusEnv()) {
    //     return;
    // }
    if (!isFocusEnv() && avsdk !== 'jbrtc') {
        return;
    }
    const timeout = setTimeout(() => {
        return Promise.reject(new Error('创建会议超时'));
    }, 30000);
    const currentStatus = await getStatus(avsdk);
    if (currentStatus) {
        return Promise.reject(new Error('会议进行中'));
    }
    const user = await ImService.getInstance().getCurrentEmployee();
    if (!user || !user.userId) {
        return Promise.reject(new Error('获取用户信息失败'));
    }

    const [meetingInfo, err] = await requestCreateMeeting({
        startTime: (dayjs().valueOf() + 5000).toString(),
        duration: 2,
        topic: topic,
        password: meetingPassword,
        platform: getPlatform(avsdk),
        participantIds: [
            {
                realName: user.name || '',
                teamId: user.teamId || '',
                userId: user.userId || '',
                appId: user.app || '',
                headImg: user.avatar || '',
            },
        ],
    });
    if (err || !meetingInfo) {
        return Promise.reject(new Error(err || ''));
    }
    if (avsdk === AVSDK.JBRTC) {
        const [jrcToken, err] = await getJRTCToken({
            nickname: user.name || user.nickName || '',
            unit: user.deptName || '',
            avatar: user.avatar || '',
            deviceType: getClientType(),
        });
        if (err) {
            return Promise.reject(new Error(err));
        }
        return new Promise((resolve, reject) => {
            const userInfo = {
                token: jrcToken.token,
                deviceType: jrcToken.deviceType,
                jrtcUserId: jrcToken.jrtcUserId,
                appId: jrcToken.appId,
                app: user.app,
                pin: user.userId,
                teamId: user.teamId,
                name: user.name || user.nickName,
                avatar: user.avatar,
                deptName: user.deptName,
            };
            FocusSDK.once(
                'jdmeet:joinMeetingCb',
                ({ status, data, error }: { status: number; data: any; error: any }) => {
                    clearTimeout(timeout);
                    if (status) {
                        if (toUsers && toUsers.length > 0) {
                            handleMeetingSendMessage({
                                avsdk,
                                meetingInfo: {
                                    password: meetingPassword,
                                    meetingTopic: topic,
                                    meetingNumber: meetingInfo.meetingId.toString(),
                                },
                                sessionState: SessionStateEnum.Call,
                                toUsers,
                            })
                                .then(resolve)
                                .catch(reject);
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject(new Error(error || '加入会议失败'));
                    }
                }
            );

            FocusSDK.sendIpcMessage('jdmeet.joinMeeting', {
                meetingInfo: {
                    meetingNum: meetingInfo.meetingId.toString(),
                    password: meetingPassword,
                    isvideooff: isvideooff,
                    isaudiooff: isaudiooff,
                },
                userInfo,
            });
        });
    } else if (avsdk === AVSDK.XYLINK) {
        try {
            const data = await joinMeeting(avsdk, {
                meetingNumber: meetingInfo.meetingId.toString(),
                meetingPassword: meetingPassword,
                isvideooff: isvideooff,
                isaudiooff: isaudiooff,
            });
            clearTimeout(timeout);
            return new Promise((resolve, reject) => {
                if (toUsers && toUsers.length > 0) {
                    handleMeetingSendMessage({
                        avsdk,
                        meetingInfo: {
                            password: meetingPassword,
                            meetingTopic: topic,
                            meetingNumber: meetingInfo.meetingId.toString(),
                        },
                        sessionState: SessionStateEnum.Call,
                        toUsers,
                    })
                        .then(resolve)
                        .catch(reject);
                } else {
                    resolve(data);
                }
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export function getStatus(avsdk: AVSDK) {
    if (avsdk === AVSDK.XYLINK) {
        return getXYLinkMeetStatus();
    } else if (avsdk === AVSDK.JBRTC) {
        return getJDMeetStatus();
    } else {
        return Promise.reject(new Error('未知类型'));
    }
}

export function joinMeeting(
    avsdk: AVSDK,
    {
        meetingNumber,
        meetingPassword = '',
        isaudiooff = true,
        isvideooff = true,
    }: {
        meetingNumber: string;
        meetingPassword?: string;
        isaudiooff?: boolean;
        isvideooff?: boolean;
    }
) {
    return new Promise((resolve, reject) => {
        if (!isFocusEnv() && avsdk !== 'jbrtc') {
            reject(new Error('不支持非客户端环境'));
            return;
        }
        ImService.getInstance()
            .getCurrentEmployee()
            .then((user) => {
                if (!user?.userId) {
                    reject(new Error('获取当前用户失败'));
                    return;
                }
                const timeout = setTimeout(() => {
                    if (isFocusEnv()) {
                        reject(new Error('加入会议超时'));
                    }
                }, 30000);
                const userInfo = {
                    app: user.app,
                    pin: user.userId,
                    teamId: user.teamId,
                    name: user.nickName || user.name,
                    avatar: user.avatar,
                    titleName: user.titleName,
                    deptName: user.deptName,
                };
                function handle({
                    status,
                    data,
                    error,
                }: {
                    status: number;
                    data: any;
                    error: any;
                }) {
                    clearTimeout(timeout);
                    if (status) {
                        resolve(data);
                    } else {
                        if (isFocusEnv()) {
                            reject(new Error(error || '加入会议失败'));
                        }
                    }
                }
                if (avsdk === AVSDK.XYLINK) {
                    getConferenceByCode({
                        meetingId: meetingNumber,
                        platform: MeetingPlatform.XYLINK,
                    }).then(([info, err]: any) => {
                        if (!info || err) {
                            clearTimeout(timeout);
                            reject(new Error(err));
                            return;
                        }
                        setCurrentMeetingInfo(avsdk, info);
                        FocusSDK.sendIpcMessage('xylink.joinMeeting', {
                            meetingInfo: {
                                meetingNumber: meetingNumber,
                                topic: info.topic || '信创会议',
                                controlPassword: info.controlPassword,
                                password: meetingPassword,
                                isvideooff: isvideooff,
                                isaudiooff: isaudiooff,
                            },
                            userInfo,
                        });
                        FocusSDK.once('xylink:joinMeetingCb', handle);
                    });
                } else if (avsdk === AVSDK.HUAWEI) {
                    return joinCloudLink(user.userId, {
                        password: meetingPassword,
                        meetingNumber: meetingNumber,
                        username: user.name || user.nickName || '',
                        isvideooff: true,
                        isaudiooff: true,
                    });
                } else if (avsdk === AVSDK.JBRTC) {
                    getJRTCToken({
                        nickname: user.name || user.nickName || '',
                        unit: user.deptName || '',
                        avatar: user.avatar || '',
                        deviceType: getClientType(),
                    }).then(([jrcToken, err]) => {
                        if (err) {
                            reject(new Error(err));
                            return;
                        }

                        const userInfo = {
                            token: jrcToken.token,
                            deviceType: jrcToken.deviceType,
                            jrtcUserId: jrcToken.jrtcUserId,
                            appId: jrcToken.appId,
                            app: user.app,
                            pin: user.userId,
                            teamId: user.teamId,
                            name: user.name || user.nickName,
                            avatar: user.avatar,
                            deptName: user.deptName,
                        };
                        FocusSDK.once('jdmeet:joinMeetingCb', handle);

                        FocusSDK.sendIpcMessage('jdmeet.joinMeeting', {
                            meetingInfo: {
                                meetingNum: meetingNumber,
                                password: meetingPassword,
                                isvideooff: isvideooff,
                                isaudiooff: isaudiooff,
                            },
                            userInfo,
                        });
                        if (!isFocusEnv()) {
                            const meetingInfo = {
                                meetingNum: meetingNumber,
                                password: meetingPassword,
                                isvideooff: isvideooff,
                                isaudiooff: isaudiooff,
                            };
                            const meetingData = {
                                meetingInfo,
                                userInfo,
                            };
                            localStorage.setItem('jdmeetUserInfo', JSON.stringify(userInfo));
                            localStorage.setItem('currentMeetInfo', JSON.stringify(meetingInfo));
                            localStorage.setItem('localUserInfo', JSON.stringify(userInfo));
                            bus.emit('meeting:meeting-list-hidden', {
                                isShowMeetingModal: false,
                                ...meetingData,
                            });
                            resolve(true);
                        }
                    });
                } else {
                    clearTimeout(timeout);
                    reject(new Error('未知会议类型'));
                }
            });
    });
}

export function handleMeetingMessage(message: SysChatMessage) {
    if (!isFocusEnv() && message?.ext?.avsdk !== 'jbrtc') {
        // log.debug('非桌面端不处理');
        return;
    }

    if (window.location.href.includes('?page=')) {
        return;
    }
    let platform = FocusSDK.getDeviceInfo().platform;

    if (!message?.ext?.avsdk) {
        log.error('不支持的消息类型');
        return;
    }

    if (dayjs(message.timestamp).add(1, 'minute').isBefore(dayjs())) {
        log.info('一分钟前的消息不处理', message);
        return;
    }

    const avsdk: AVSDK = message.ext.avsdk as AVSDK;
    const sessionState = message.ext.sessionState as SessionStateEnum;
    const fromUser = message.ext.fromUser as BasicUserInfo;
    const { ext = {} } = message;
    function handleInvited(data: any) {
        if (data.meetingStatus === 'InMeeting') {
            handleMeetingSendMessage({
                avsdk,
                meetingInfo: {
                    meetingTopic: String(ext.meetingTopic),
                    meetingNumber: String(ext.meetingNumber),
                    password: String(ext.password),
                },
                sessionState: SessionStateEnum.Busy,
                toUsers: [fromUser],
            });
        }
    }
    FocusSDK.off('meeting:invitedCb', handleInvited).once('meeting:invitedCb', handleInvited);

    switch (avsdk) {
        case AVSDK.JBRTC:
            if (sessionState === SessionStateEnum.Call) {
                FocusSDK.sendIpcMessage('meeting.invited', message);
                if (!isFocusEnv()) {
                    bus.emit('jdmeeting:show-receive-modal', message);
                }
            } else {
                if (sessionState === SessionStateEnum.Cancel) {
                    FocusSDK.sendIpcMessage('meeting.invitedClose');
                }
                const notifyMsg = `${fromUser.name}${sessionStateContentMap[sessionState]}`;
                FocusSDK.sendIpcMessage('jdmeet.showNotification', notifyMsg);
                if (!isFocusEnv()) {
                    logger.debug('jdmeeting:showNotification~~~');
                    bus.emit('jdmeeting:showNotification', notifyMsg);
                    bus.emit('jdmeeting:hide-receive-modal', false);
                }
                toast.info(notifyMsg);
            }
            break;
        case AVSDK.XYLINK:
            if (platform === 'win32') {
                if (sessionState === SessionStateEnum.Call) {
                    FocusSDK.sendIpcMessage('meeting.invited', message);
                } else {
                    if (
                        sessionState === SessionStateEnum.Busy ||
                        sessionState === SessionStateEnum.UserReject ||
                        sessionState === SessionStateEnum.TIMEOUT
                    ) {
                        sendNoticeCard(message, message.ext.fromUser);
                    }
                    toast.info(`${fromUser.name}${sessionStateContentMap[sessionState]}`);
                }
            }
            break;
        default:
            break;
    }
}

export function getSdkType(avsdk: AVSDK) {
    if (avsdk === AVSDK.HUAWEI) {
        return 'huawei';
    }
    if (avsdk === AVSDK.XYLINK) {
        return 'xiaoyu';
    }
    if (avsdk === AVSDK.ZOOM) {
        return 'zoom';
    }
    return '';
}

export async function handleMeetingSendMessage(data: {
    avsdk: AVSDK;
    meetingInfo: MeetingInfoType;
    sessionState: SessionStateEnum;
    toUsers: BasicUserInfo[];
}) {
    if (!isFocusEnv() && data.avsdk !== 'jbrtc') {
        // log.debug('非桌面端不处理');
        return;
    }
    log.debug('handleMeetingSendMessage', data);
    const imService = ImService.getInstance();
    const userInfo: Partial<Employee> = await imService.getCurrentEmployee();
    const { avsdk, sessionState, meetingInfo } = data;
    const toUsers = data.toUsers.filter((item: BasicUserInfo) => item.pin !== userInfo.userId);
    if (!toUsers || toUsers?.length === 0) {
        log.debug('noneed send');
        return;
    }
    const body = {
        ext: {
            displayName: '',
            avsdk,
            projectType: 0,
            fromUser: {
                pin: userInfo.userId || '',
                teamId: userInfo.teamId || '',
                name: userInfo.name || '',
                app: userInfo.app || '',
                avatar: userInfo.avatar || '',
            },
            enableVideo: 0,
            meetingID: '',
            meetingTopic: meetingInfo.meetingTopic,
            meetingDuration: 120,
            meetingNumber: String(meetingInfo.meetingNumber),
            enableMicphone: 0,
            clientType: getClientType(),
            meetingPassword: meetingInfo.password || '',
            sessionState,
            sessionType: 0,
            startTime: new Date().getTime().toString(),
        },
        flag: 100,
        state: 0,
        noPush: 0,
        pushSms: 0,
        type: ChatMessageType.SYS,
        content: meetingInfo.meetingTopic,
    };
    FocusSDK.sendIpcMessage('voip.sendVoipCall', {
        toUsers: toUsers.map((item) => {
            return {
                ...item,
                userId: item.pin,
                appId: item.app,
            };
        }),
        body,
    });

    if (sessionState === SessionStateEnum.Call) {
        cache.telCall &&
            conferenceCall({
                userIds: toUsers.map((item: any) => item.pin),
                meetingId: body.ext.meetingNumber.toString(),
                password: body.ext.meetingPassword,
                topic: body.ext.meetingTopic,
                sdkType: getSdkType(avsdk),
            }).then(([res, err]) => {
                if (err) {
                    logger.error(err);
                }
            });
        if (avsdk === AVSDK.HUAWEI || avsdk === AVSDK.XYLINK || avsdk === AVSDK.JBRTC) {
            // sendNoticeCard(body, toUsers, body.ext.fromUser);
            const ext = body.ext;
            sendConferenceCardMsg({
                noticeId: `eopen-push_${getNoticeBot(avsdk)}`,
                title: ext.meetingTopic,
                receivers: toUsers.map((user) => {
                    return {
                        app: user.app,
                        pin: user.pin,
                        teamId: user.teamId,
                    };
                }),
                meetingInfoReq: {
                    meetingNumber: ext.meetingNumber,
                    initiator: ext.fromUser.name || '',
                },
                infox: {
                    password: ext.meetingPassword,
                    deepLink: generateRequestCardMsg(ext.fromUser, toUsers, body).infox.deepLink,
                    btnName: '立即入会',
                    action: 'joinmeeting',
                    type: avsdk,
                },
            });
        }
    }

    const sendResult = await Promise.all(
        toUsers.map((user: any) => {
            return new Promise((resolve) => {
                const sessionId = imService.generateSingleSessionId(
                    {
                        app: userInfo.app,
                        userId: userInfo.userId,
                        teamId: userInfo.teamId,
                    } as any,
                    {
                        pin: user.pin,
                        app: user.app,
                        teamId: user.teamId,
                    }
                );
                const newMessage = {
                    sessionId,
                    ...body,
                    requestData: {
                        sessionId,
                    },
                    timestamp: new Date().getTime(),
                    type: ChatMessageType.SYS,
                    flag: 100,
                } as any;
                const messageId = generateMessageId();
                imService
                    .sendChatMessage(sessionId, messageId, newMessage, {
                        skipCheckSession: true,
                    })
                    .then((res: any) => {
                        logger.debug('send chat message ok: ', res);
                        resolve({
                            message: '消息发送成功',
                            status: 1,
                        });
                    })
                    .catch((e: any) => {
                        logger.error('send chat message err', e);
                        resolve({
                            message: '消息发送失败',
                            status: 0,
                            error: e,
                        });
                    });
            });
        })
    );
    return sendResult;
}

export async function sendNoticeCard(body: any, toUsers: any, fromUser?: any) {
    const { ext } = body;
    const avsdk: AVSDK = ext.avsdk;
    if (!avsdk) return;
    let from = fromUser;
    if (!fromUser) {
        const userInfo = await ImService.getInstance().getCurrentEmployee();
        from = {
            pin: userInfo.userId,
            teamId: userInfo.teamId,
            name: userInfo.name,
            app: userInfo.app,
            avatar: userInfo.avatar,
        };
    }
    ext.fromUser = from;
    const appPathMap = {
        [AVSDK.HUAWEI]: 'cloudlink',
        [AVSDK.ZOOM]: 'joymeeting',
        [AVSDK.JBRTC]: 'jbmeeting',
        [AVSDK.XYLINK]: 'joymeeting',
    };
    const deepLink =
        `openapp.jdfocus://jm/biz/appcenter/${appPathMap[avsdk]}?mparam=` +
        encodeURIComponent(
            JSON.stringify({
                device: null,
                ...ext,
                fromUser: {
                    clientType: getClientType(),
                    info: null,
                    intro: null,
                    nameIndex: null,
                    xToken: null,
                    ...from,
                },
            })
        );
    const msg = {
        title: `${ext.meetingTopic}`,
        content: `会议号：${ext.meetingNumber}`,
        extend: {
            url: '',
        },
        from: fromUser,
        imgUrl: '',
        infox: {
            ...ext,
            imgUrl: '',
            deepLink,
        },
        tos: toUsers,
    };
    sendNoticeCardMessage(msg, getNoticeBot(avsdk));
}

const deepLinkPath = {
    [AVSDK.HUAWEI]: 'cloudlink',
    [AVSDK.JBRTC]: 'jbmeeting',
    [AVSDK.ZOOM]: 'joymeeting',
    [AVSDK.XYLINK]: 'xiaoyu',
};
export function generateRequestCardMsg(
    fromUser: BasicUserInfo,
    toUsers: BasicUserInfo[],
    message: any
) {
    const { ext } = message;
    const avsdk: AVSDK = ext.avsdk;
    // if (!avsdk) {
    //     return '无效的AVSDK';
    // }
    const { meetingNumber, meetingTopic } = ext;

    const deepLink =
        `openapp.jdfocus://${DeeplinkPathEnum.AppCenter}/${deepLinkPath[avsdk]}?mparam=` +
        encodeURIComponent(
            JSON.stringify({
                device: null,
                displayName: null,
                enableMicphone: 0,
                enableVideo: 0,
                ...ext,
                fromUser: {
                    clientType: getClientType(),
                    info: null,
                    intro: null,
                    nameIndex: null,
                    xToken: null,
                    ...fromUser,
                },
            })
        );
    return {
        title: `${meetingTopic}`,
        content: `${i18n.t('common:joinMeeting.meetingNumber')}: ${meetingNumber}`,
        extend: {
            url: '',
        },
        from: fromUser,
        imgUrl: '',
        infox: {
            ...ext,
            imgUrl: '',
            deepLink,
        },
        tos: toUsers.map((item: any) => {
            return {
                ...item,
                pin: item.pin || item.userId,
                name: item.name || item.realName,
            };
        }),
    };
}
