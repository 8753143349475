import axios from 'axios';
import { gatewayInstance } from '@/server/gateway';
import { GatewayRequestOptions } from '@/server/types';
import { User } from '../types';
import cache from '@/utils/cache';
import * as collections from './collections';
import * as sharedDocuments from './sharedDocuments';
import * as page from './page';
import * as pages from './pages';
import * as trashes from './trashes';
import * as follow from './follow';
import * as shortcut from './shortcuts';
import * as space from './space';
import * as file from './file';
import * as banner from './banner';
import * as categories from './categories';
import * as batch from './batch';
import * as permissions from './permissions';
import { isFocusEnv, FocusSDK } from '@/utils';
const { REACT_APP_JOYSPACE_API_HOST } = process.env;
// eslint-disable-next-line no-duplicate-imports
import * as teamCreate from './space';
import { message } from 'antd';
import { request } from './request';
// import { _shortcuts } from './pages';
export default {
    permissions,
    collections,
    page,
    pages,
    trashes,
    follow,
    shortcut,
    space,
    file,
    banner,
    categories,
    teamCreates,
    spaceCreate,
    batch,
    sharedDocuments,
};

export interface IGetHistoryResult {
    pages: {
        title: string;
    }[];
    total: number;
}

export function getHistory() {
    return request<IGetHistoryResult>({
        api: 'joyspace.pages.recent',
        path: '/v1/pages/recent',
    });
}

export function getUserInfo() {
    return pages.getUserInfoApi();
    // return request<User>({
    //     api: 'joyspace.user.info',
    //     path: '/v1/user/info',
    // });
}

// 快速访问
export function getShortcuts(start = 1, length = 30) {
    // const params = {
    //     start,
    //     length,
    // };
    return pages.shortcuts(start, length);
    // return request({
    //     api: 'joyspace.shortcut.page',
    //     path: '/v1/shortcuts/page',
    //     method: 'GET',
    //     params: {
    //         start,
    //         length,
    //     },
    // });
}

// 快速访问--删除
export function shortcutRemove(target_id: number, target_type: number) {
    return request({
        api: 'joyspace.shortcut.remove',
        path: `/v1/shortcuts/${target_id}/${target_type}`,
        method: 'DELETE',
    });
}

// 最近访问
export function getShares(start: number) {
    return request({
        api: 'joyspace.pages.share',
        path: '/v1/pages/shares',
        method: 'GET',
        params: {
            start,
        },
    });
}

// 获取团队下空间
export function getSpaces(teamId: any) {
    return request({
        api: 'joyspace.team.spaces',
        path: `/v1/teams/${teamId}/spaces`,
        method: 'GET',
    });
}

// 获取关注空间
export function getFollow(limit: number, start: number, target_type: number) {
    return request({
        api: 'joyspace.follow.list',
        path: '/v1/follow',
        method: 'GET',
        params: {
            limit,
            start,
            target_type,
        },
    });
}

export async function getFileIcon(title: string, type: string) {
    // 此接口目前没有网关配置
    const url = `${REACT_APP_JOYSPACE_API_HOST}/v1/common/icons?title=${title}&pageType=6&type=${type}&client=web`;
    const data = axios({
        url,
    });
}

export async function getUserProfile(body: any) {
    return request({
        api: 'login.getUserProfile',
        method: 'POST',
        body,
    });
}

// 新建团队
export async function teamCreates(body: any) {
    for (const [key, value] of Object.entries(body)) {
        if (value === null) {
            body[key] = '';
        }
    }
    return request({
        api: 'joyspace.team.create',
        path: 'v1/teams',
        method: 'POST',
        body,
    });
}
// 新建空间
export async function spaceCreate(body: any) {
    for (const [key, value] of Object.entries(body)) {
        if (value === null) {
            body[key] = '';
        }
    }
    return request({
        api: 'joyspace.space.create',
        path: 'v1/spaces',
        method: 'POST',
        body,
    });
}

// 新建组空间
export async function groupCreates(body: any) {
    return request({
        api: 'joyspace.team.create',
        path: 'v1/teams',
        method: 'POST',
        body,
    });
}
