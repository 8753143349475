import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { Table, message, Modal, Button, Select } from 'antd';
import { FocusSDK as focusSDK, isFocusEnv, getBroName, getUserAppId } from '@/utils';
import { GlobalOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import Loading from '@/components/Loading';
import styles from './index.module.less';
import { CurrentType } from '@/components/Modals/NetTable/types';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import UploadImageControler from '@/layout/UploadImage/UploadIamgeControler';
import JSZip from 'jszip';
import { v4 } from 'uuid';
import { UploadFile } from 'antd/lib/upload/interface';
import getVersionBySdk from '@/utils/getVersion';
import { UAParser } from 'ua-parser-js';
import { submitFeedback } from '@/api/feedback';
import { ColumnsType } from 'antd/es/table';

const LOG_CONTENT = '网络检测结果';
const { Option } = Select;
const columns: ColumnsType<any> = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        width: 100,
    },
    {
        title: '服务名称',
        dataIndex: 'serverName',
        align: 'center',
    },
    {
        title: '最大延时(毫秒)',
        dataIndex: 'maxTime',
        align: 'left',
        render: (value: number) => (isNaN(value) ? '-' : value.toFixed(2)),
    },
    {
        title: '最小延时(毫秒)',
        dataIndex: 'minTime',
        align: 'left',
        render: (value: number) => (isNaN(value) ? '-' : value.toFixed(2)),
    },
    {
        title: '平均延时(毫秒)',
        dataIndex: 'avgTime',
        align: 'left',
        render: (value: number) => (isNaN(value) ? '-' : value.toFixed(2)),
    },
    {
        title: '丢包率(%)',
        dataIndex: 'packetLoss',
        align: 'left',
        render: (value: number) =>
            isNaN(value) ? (
                '-'
            ) : value > 0 ? (
                <span className={styles['error']}>{value.toFixed(2)}</span>
            ) : (
                value.toFixed(2)
            ),
    },
];

const selectOptions = [
    {
        text: `互联网${!focusSDK.isPrivateNet() ? '（使用中）' : ''}`,
        value: 'public',
    },
    {
        text: `政务外网${focusSDK.isPrivateNet() ? '（使用中）' : ''}`,
        value: 'private',
    },
];

const initValue = focusSDK.isPrivateNet() ? 'private' : 'public';
type NetResultType = 'CURRENT_NET_OK' | 'CURRENT_NET_ERROR' | 'OTHER_NET_RESULT';

export default function NetTable(props: any) {
    const { visible: netVisible, fromType, appRuntimeEnv, authInfo: authData } = props;

    // const [visible, setVisible] = useState(netVisible);
    const [netList, setNetList] = useState<any[]>([]);
    const [loadingValue, setLoadingValue] = useState(false);
    const [currentType, setCurrentType] = useState<CurrentType>(() => initValue);
    const isQuerying = useRef(false);
    const [checkText, setCheckText] = useState('开始检测');
    const [netResultType, setNetResultType] = useState<NetResultType | ''>();
    const [canSendLog, setCanSendLog] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [sendLoading, setSendLoading] = useState(false);

    const getCurrentNetResult = useCallback(
        (netResult: any[]) => {
            console.log('耗费时长', Date.now() - currentTime);
            isQuerying.current = false;
            setLoadingValue(false);
            setCheckText('重新检测');
            setNetList(netResult);
            let flag = true;
            netResult.forEach((item) => {
                // 网关接口，是否可发送日志
                if (item.id === '3' && fromType !== 'entry') {
                    if (item.packetLoss > 0 || isNaN(item.packetLoss)) {
                        setCanSendLog(false);
                    } else {
                        setCanSendLog(true);
                    }
                }
                if (item.packetLoss > 0 || isNaN(item.packetLoss)) {
                    flag = false;
                }
            });
            if (
                (focusSDK.isPrivateNet() && currentType !== 'private') ||
                (!focusSDK.isPrivateNet() && currentType !== 'public')
            ) {
                setNetResultType('OTHER_NET_RESULT');
                return;
            }
            if (flag) {
                setNetResultType('CURRENT_NET_OK');
            } else {
                setNetResultType('CURRENT_NET_ERROR');
            }
        },
        [currentType, currentTime, fromType]
    );

    const checkCurrentNet = useCallback(() => {
        if (!isQuerying.current) {
            setCanSendLog(false);
            setCurrentTime(Date.now());
            setLoadingValue(true);
            setNetResultType('');
            isQuerying.current = true;
            setCheckText('检测中...');
            focusSDK.checkCurrentNet({
                fromType: fromType || '',
                currentType,
            });
        }
    }, [currentType, fromType]);

    const retryCheck = useCallback(() => {
        checkCurrentNet();
    }, [checkCurrentNet]);

    const uploadController = useMemo(() => {
        return new UploadImageControler(appRuntimeEnv);
    }, [appRuntimeEnv]);

    const getLogFiles = () => {
        return ['main.log', 'main.old.log', 'uncaughtException-latest.log']
            .map((item) => {
                const file = focusSDK.getLogFile(item);
                if (!file) {
                    return null;
                }
                return {
                    ...file,
                    name: item,
                };
            })
            .filter((item) => !!item);
    };

    const initFileData = (data: any): UploadFile => {
        return {
            uid: data.uploadId || v4(),
            name: data.name,
            status: 'done',
            url: data.downloadUrl,
            size: data.size,
            type: data.type,
        };
    };

    const getLogsUrl = async () => {
        const zip = new JSZip();
        getLogFiles().forEach((item: any) => {
            zip.file(item.name, item.blob, { binary: true });
        });
        const content = await zip.generateAsync({
            type: 'blob',
            compression: 'DEFLATE', // STORE：默认不压缩 DEFLATE：需要压缩
            compressionOptions: {
                level: 9, // 压缩等级1~9 1压缩速度最快，9最优压缩方式
            },
        });
        const userName = authData.realName;
        const currentDate = new Date().getTime();
        const logFile = new File([content], `logs-${userName}-${currentDate}.zip`);
        try {
            let state = await uploadController.asyncAddFile(logFile);
            return [initFileData(state)].map((i: UploadFile) => i.url);
        } catch (e) {
            return [];
        }
    };

    const ver = useMemo(() => getBroName(), []);

    const sendLog = async () => {
        setSendLoading(true);
        const { name } = focusSDK.getApplicationInfo();
        const appVersion = isFocusEnv()
            ? getVersionBySdk(focusSDK)
            : appRuntimeEnv.registryConfig.pkgVersion.split('-')[0];
        const appName = `${name} ${isFocusEnv() ? 'desktop' : 'web'}`;
        let uaParser = new UAParser();
        let os = uaParser.getOS();
        let system_version = '1.0.0';
        if (os.name && os.version) {
            system_version = `${os.name}-${os.version}`;
        }
        const params = {
            expression: '',
            content: `${LOG_CONTENT}-${authData.realName || authData.teamUserInfo.realName}`,
            type: '1',
            system_name: 'focus-desktop',
            system_version: system_version,
            device_model: ver,
            name: authData.realName || authData.teamUserInfo.realName,
            mobile: authData.teamUserInfo.mobile,
            company:
                authData.teamUserInfo.teamFullName || authData.teamUserInfo.teamShortName || '',
            logs: await getLogsUrl(),
        };
        const app = getUserAppId();
        return submitFeedback({
            params: { ...params, appName, app, appVersion, system_version },
        }).then((result) => {
            setSendLoading(false);
            if (!result) {
                message.success('提交成功');
            } else {
                message.info('提交失败');
            }
        });
    };

    useEffect(() => {
        focusSDK.on('checkCurrentNet:reply', getCurrentNetResult);
        return () => {
            focusSDK.off('checkCurrentNet:reply', getCurrentNetResult);
        };
    }, [getCurrentNetResult, checkCurrentNet]);

    useEffect(() => {
        if (netVisible) {
            setNetList([]);
            setLoadingValue(false);
            isQuerying.current = false;
            setCheckText('开始检测');
            setNetResultType('');
            setCanSendLog(false);
            setSendLoading(false);
            setCurrentType(focusSDK.isPrivateNet() ? 'private' : 'public');
        }
    }, [netVisible]);

    return (
        <Modal
            title={
                <div className={styles['title-container']}>
                    <GlobalOutlined />
                    <div className={styles['modal-title']}>网络检测</div>
                </div>
            }
            bodyStyle={{
                padding: '0 0 8px',
                // height: '496px',
                display: 'flex',
                flexDirection: 'column',
            }}
            width={800}
            closable={true}
            visible={netVisible}
            maskClosable={false}
            keyboard={false}
            zIndex={2000}
            footer={null}
            onCancel={() => focusSDK.emit('hideNetTable')}
        >
            <div className={styles['operation-container']}>
                <div className={styles['table-operation']}>
                    <div className="current-net-tag">
                        <span>当前检测网络：</span>
                        <Select
                            className={styles['select-net']}
                            dropdownClassName={styles['dropdown-net']}
                            value={currentType}
                            onChange={(val) => setCurrentType(val)}
                        >
                            {selectOptions.map((item) => (
                                <Option key={item.value} value={item.value}>
                                    {item.text}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className={styles['btn-container']}>
                        {canSendLog && (
                            <Button
                                className={styles['send-btn']}
                                type="primary"
                                loading={sendLoading}
                                disabled={sendLoading}
                                onClick={debounce(sendLog, 300)}
                            >
                                发送日志
                            </Button>
                        )}
                        <Button
                            className={styles['check-btn']}
                            type="primary"
                            disabled={isQuerying.current}
                            onClick={debounce(retryCheck, 300)}
                        >
                            {checkText}
                        </Button>
                    </div>
                </div>
                <div className={styles['table-result']}>
                    <span>检测结果：</span>
                    {netResultType === 'CURRENT_NET_OK' && (
                        <span className={styles['ok']}>您当前的网络服务连接正常。</span>
                    )}
                    {netResultType === 'CURRENT_NET_ERROR' && (
                        <span className={styles['error']}>
                            您当前的网络服务连接异常，如有疑问请联系所在单位网络管理员或拨打客服电话：400-615-1212。
                        </span>
                    )}
                    {netResultType === 'OTHER_NET_RESULT' && (
                        <span>您当前正在检测未使用的网络，检测结果并不代表您真实的网络状况。</span>
                    )}
                </div>
            </div>
            <Table
                rowClassName={styles['net-list-table-row']}
                className={styles['net-list-table']}
                columns={columns}
                dataSource={netList}
                loading={
                    loadingValue
                        ? {
                              indicator: (
                                  <div
                                      style={{
                                          width: '100%',
                                          height: '100%',
                                          display: 'flex',
                                          top: 0,
                                          left: 0,
                                      }}
                                  >
                                      <Loading />
                                  </div>
                              ),
                          }
                        : false
                }
                rowKey="id"
                pagination={false}
                scroll={{ y: 300 }}
            />
        </Modal>
    );
}
