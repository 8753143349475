// 1.文档 2.会话 3.任务 4.日程
export const ShareType = {
    '1': '文档分享',
    '2': '会话分享',
    '3': '任务附件',
    '4': '日程附件',
};

export enum MenuEnum {
    PATH = 'path',
    SHARES_TYPE = 'sharesType',
    SHARES = 'shares',
}

export const menu = [
    {
        type: MenuEnum.PATH,
        name: '路径',
        default: true,
        children: [
            // {
            //     type: 'sharesAll',
            //     name: '全部分享文件',
            // },
        ],
    },
    {
        type: MenuEnum.SHARES_TYPE,
        name: '分享方式',
        children: [],
    },
    {
        type: MenuEnum.SHARES,
        name: '分享人',
        children: [],
    },
];

export const menu2 = [
    {
        type: MenuEnum.SHARES_TYPE,
        name: '分享方式',
        children: [],
    },
];
