/**
 * 加入视频会议页面
 * @author zhangpengcheng15
 */
import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { FocusSDK, JoyMeetingStatusEnum } from '@/utils';
import { ZoomMeetingStatus } from '@/types/common';
import { createMeeting, getUserSimple } from '@/api/meeting';
import {
    ZOOM_PASS_LENGTH,
    handleMeetingSendMessage,
    setCurrentCloudUnionMeetingInfo,
} from '../utils';
import IconFont from '@/components/icon';
import { openUserSelectionModal } from '@/utils/modals';
import cache from '@/utils/cache';
import _ from 'lodash';
import lodashCompact from 'lodash/compact';
import { connect as dvaConnect } from 'dvajs';
import { deleteEmojiStr } from '@/components/chat/message/Input/Emoji';
import { NetType } from '@/components/Modals/ChangeNetModal';

import { analysisLog } from '@/utils/logAnalytics';
export interface JoinMeetingResult {
    meetingNum: string;
    meetingPass: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
    userList: {
        realName: string;
        teamId: string;
        userId: string;
        appId: string;
        headImg: string;
    }[];
}

function CreateMeetingModal(props: any) {
    const [showPass, setShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [meetingTopic, setMeetingTopic] = useState('');
    const [pass, setPass] = useState('');
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo = {} as any, imService, currentEmployee } = useContext(GlobalContext);
    const { selectedTeamId, teamUserInfo, userId } = authInfo;
    let ddAppId = teamUserInfo?.ddAppId || '';
    let teamId = teamUserInfo?.teamId || '';
    const [t_common] = useTranslation('common');
    const [t_chat] = useTranslation('chat');
    const { newPublicResult, newPrivateResult } = props;

    const [ownerInfo, setOwnerInfo] = useState<{
        deptName: string; // 部门
        superiorDeptName: string | null; // 次级部门
        titleName: string | null; // 岗位名称
        userName: string;
    } | null>(null);

    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
        userList: [],
    });
    const inputRef = React.useRef<Input | null>(null);

    useEffect(() => {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
    }, []);

    useEffect(() => {
        function handleModalVisible(data: any = {}) {
            const { list = [] } = data;
            const { headImg } = teamUserInfo;
            const realName = currentEmployee.name;
            const user = {
                realName,
                userId,
                teamId: teamUserInfo?.teamId,
                appId: ddAppId,
                headImg,
            };
            if (list.length === 0) {
                setState((s: any) => ({
                    ...s,
                    userList: [user],
                }));
            } else {
                setState((s: any) => ({
                    ...s,
                    userList: list,
                }));
            }
            setVisible(true);
            (async () => {
                const [result, err] = await getUserSimple({ userId, teamId, appId: ddAppId });
                if (err || !result) {
                    return;
                }
                setOwnerInfo(result);
            })();
        }
        FocusSDK.on('meeting:create-show', handleModalVisible);
        bus.on('meeting:create-show', handleModalVisible);
        return () => {
            FocusSDK.off('meeting:create-show', handleModalVisible);
            bus.off('meeting:create-show', handleModalVisible);
        };
        // eslint-disable-next-line react/destructuring-assignment
    }, [authInfo, currentEmployee.name, ddAppId, teamId, teamUserInfo, userId]);

    function closeModal() {
        setMeetingTopic('');
        setPass('');
        setVisible(false);
        setShowPass(false);
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
            userList: [],
        });
    }
    let messageShow = false;
    const showValidate = _.debounce(function () {
        if (messageShow) return;
        message.error(t_common('joinMeeting.Exceed the word limit'), undefined, () => {
            messageShow = false;
        });
        messageShow = true;
    }, 200);
    function onMeetingTopicChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (value.length > 30) {
            showValidate();
            return;
        }
        setMeetingTopic(deleteEmojiStr(value));
    }

    function handleCreateMeeting() {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            if (newPublicResult) {
                message.warn(t_common('joinMeeting.change public net'));
            } else {
                message.warn(t_common('joinMeeting.private net'));
            }
            return;
        }
        if (connect) {
            return;
        }
        // todo
        let meetingData: any = null;
        /**
         * 成功或失败后，移除监听函数
         */
        function removeListener() {
            FocusSDK.removeAllListeners('zoom.meetingStatusCb');
            FocusSDK.removeAllListeners('meeting:group call message');
        }

        function sendCallMessage(opts: {
            meetingID: string;
            meetingTopic: string;
            toUsers: {
                name: string;
                teamId: string;
                userId: string;
                appId: string;
                headImg: string;
            }[];
            meetingNumber: string;
            meetingPassword?: string;
            content: string;
        }) {
            const fromUser = {
                app: teamUserInfo.ddAppId,
                pin: authInfo.userId,
                teamId: selectedTeamId,
                name: teamUserInfo.realName,
                avatar: teamUserInfo.headImg,
                titleName: ownerInfo?.titleName || '',
                superiorDeptName: ownerInfo?.superiorDeptName || '',
                deptName: ownerInfo?.deptName || '',
            };
            const body = {
                ext: {
                    fromUser,
                    displayName: 'displayName',
                    projectType: 0,
                    enableVideo: 0,
                    avsdk: 'zoom',
                    meetingID: opts.meetingID,
                    meetingTopic: opts.meetingTopic,
                    meetingDuration: 2,
                    meetingNumber: opts.meetingNumber.toString(),
                    enableMicphone: 0,
                    meetingPassword: opts.meetingPassword || '',
                    sessionState: 0,
                    sessionType: 0,
                    startTime: new Date().getTime().toString(),
                },
                flag: 100,
                state: 0,
                noPush: 0,
                type: 'sys',
                content: opts.content,
            };
            const { realName, ddAppId, headImg, teamId } = teamUserInfo;
            handleMeetingSendMessage({
                imService,
                currentEmployee: { app: ddAppId, userId, teamId },
                data: {
                    toUsers: opts.toUsers.reduce((p: any[], v) => {
                        if (
                            v.userId === fromUser?.pin &&
                            v.teamId === fromUser?.teamId &&
                            v.appId === fromUser?.app
                        ) {
                            return p;
                        }
                        p.push({
                            ...v,
                            app: v.appId,
                            pin: v.userId,
                            username: v.name,
                            avatar: v.headImg,
                        });
                        return p;
                    }, []),
                    body,
                },
                t: t_common,
            });
        }

        function handleChatMeetingMessage(data: any) {
            // 不处理所有逻辑
        }

        function handleMeetingStatusCb(status: number) {
            switch (status) {
                case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                    closeModal();
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_WAITINGFORHOST:
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_INMEETING:
                    removeListener();
                    break;
                // case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                //   setConnect(true);
                //   break;
                case ZoomMeetingStatus.MEETING_STATUS_DISCONNECTING:
                case ZoomMeetingStatus.MEETING_STATUS_FAILED:
                    message.error(t_common('joinMeeting.meeting connect failed'));
                    setConnect(false);
                    removeListener();
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_ENDED:
                    message.error(t_common('joinMeeting.meeting has already ended'));
                    setConnect(false);
                    removeListener();
                    break;
                default:
                    message.error(t_common('joinMeeting.meeting connect failed'));
                    setConnect(false);
                    removeListener();
                    break;
            }
        }

        function handleInitCb(result: string) {
            if (!visible) {
                setConnect(false);
                return removeListener();
            }
            if (result !== 'success') {
                console.info('zoom init status', result);
                if (result === '14') {
                    message.error(
                        t_common('joinMeeting.The other instance of the SDK is in process')
                    );
                } else {
                    message.error(t_common('joinMeeting.meeting initialization failure'));
                }
                removeListener();
                setConnect(false);
                return;
            }

            sendCallMessage({
                meetingNumber: meetingData.meetingId,
                toUsers: state.userList.map((u) => ({ ...u, name: u.realName })),
                meetingTopic: meetingData.topic,
                meetingID: meetingData.uuid,
                content: meetingData.topic,
                meetingPassword: meetingData.password,
            });

            FocusSDK.on('meeting:group call message', handleChatMeetingMessage);
            const username = teamUserInfo.realName;
            setCurrentCloudUnionMeetingInfo(meetingData);
            FocusSDK.sendIpcMessage('zoom.joinUnlogin', {
                meetingnum: meetingData.meetingId,
                psw: meetingData.password,
                username,
                isvideooff: state.videoDisable,
                isaudiooff: state.audioDisable,
            });
        }
        async function changeJoyMeetingStatusCb(args: {
            code: JoyMeetingStatusEnum;
            isFree: boolean;
        }) {
            const { isFree } = args;
            if (!isFree) {
                setConnect(false);
                message.error(t_common('joinMeeting.There is a meeting going on'));
                return;
            }
            const [result, err] = await createMeeting({
                startTime: (new Date().getTime() + 5000).toString(),
                duration: 2,
                topic: meetingTopic,
                password: pass,
                participantIds: state.userList,
            });

            if (err) {
                FocusSDK.sendIpcMessage(
                    'MeetingControl:changeJoyMeetingStatus',
                    JoyMeetingStatusEnum.None
                );
                message.error(t_common('joinMeeting.create meeting failed'));
                setConnect(false);
                removeListener();
                return;
            }
            meetingData = result;
            FocusSDK.once('zoom.initCb', handleInitCb);
            FocusSDK.on('zoom.meetingStatusCb', handleMeetingStatusCb);
            FocusSDK.sendIpcMessage('zoom.initSdk');
        }
        setConnect(true);

        FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', changeJoyMeetingStatusCb);
        FocusSDK.sendIpcMessage(
            'MeetingControl:changeJoyMeetingStatus',
            JoyMeetingStatusEnum.GroupCall
        );
    }

    function onMeetingPassChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        // @ts-ignore
        if (!/^\d*$/.test(value)) {
            return;
        }
        if (value && value.length > ZOOM_PASS_LENGTH) {
            return;
        }
        setPass(value);
    }

    const isValidMeeting = () => {
        return (
            meetingTopic &&
            (pass.length === 0 || pass.length === ZOOM_PASS_LENGTH) &&
            state.userList.length > 0
        );
    };

    function handlePassBlur() {
        if (!pass) {
            setShowPass(false);
        }
    }

    function handleAddMember() {
        const options: any = {
            frozenIds: [],
        };

        const userInfo: any = {
            id: `${userId}:${teamUserInfo.ddAppId}:${teamUserInfo.teamId}`,
            avatar: teamUserInfo.headImg,
            name: teamUserInfo.realName,
            app: teamUserInfo.ddAppId,
            userId,
            teamId: teamUserInfo.teamId,
            pin: userId,
        };
        const members = lodashCompact(state.userList || []).map((l: any) => ({
            id: `${l.userId}:${l.appId}:${l.teamId}`,
            name: l.realName,
            avatar: l.headImg,
            checked: true,
            userId: l.userId,
            teamId: l.teamId,
            visible: true,
        }));
        options.title = t_chat('add_member');
        options.resultUsers = members;
        options.frozenIds = [userInfo.id];
        options.currentUser = {
            app: userInfo?.app,
            pin: userInfo?.pin,
            teamId: userInfo?.teamId,
        };
        options.tabs = ['org', 'group'];
        openUserSelectionModal(
            options,
            (data, close) => {
                try {
                    const { result } = data.data;
                    if (result.length) {
                        setState((s) => {
                            return {
                                ...s,
                                userList: result.map((r: any) => {
                                    const tem = r.id.split(':');
                                    return {
                                        realName: r.name,
                                        userId: tem[0],
                                        teamId: tem[2],
                                        appId: tem[1],
                                        headImg: r.avatar as string,
                                    };
                                }),
                            };
                        });
                    }

                    close();
                } catch (error) {
                    // message.error(t('chat.failedToCreateGroup'));
                    if (close) {
                        close();
                    }
                }
            },
            authInfo as any
        );
    }

    function handleMenuClick(key: string) {
        if (connect) {
            return;
        }
        if (key === 'audio') {
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
        if (key === 'addUser') {
            handleAddMember();
        }
    }

    const passComp = showPass ? (
        <Input
            value={pass}
            placeholder={t_common('joinMeeting.Set a 6-digit password')}
            autoFocus
            onChange={onMeetingPassChange}
            onBlur={handlePassBlur}
        />
    ) : (
        <div
            className="input-label"
            onClick={() => {
                setShowPass(true);
            }}
        >
            <IconFont style={{ marginRight: 5 }} type="iconic_editor" />
            {t_common('joinMeeting.Set a 6-digit password')}
        </div>
    );

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    function onCancel() {
        // if (connect) {
        //     return;
        // }
        analysisLog('xtbg_PCMeeting', 'Create_Cancel');
        closeModal();
    }
    return (
        <Modal
            className="desk-me-join-meeting-modal desk-me-create-meeting-modal"
            width={780}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
            destroyOnClose
        >
            <div className="me-join-meeting-body">
                <div className="me-join-meeting-form">
                    <div className="me-join-meeting-input">
                        <Input
                            type="text"
                            value={meetingTopic}
                            ref={inputRef}
                            autoFocus
                            onPressEnter={() => {
                                if (isValidMeeting() && !connect) {
                                    handleCreateMeeting();
                                }
                            }}
                            placeholder={t_common(
                                'joinMeeting.Please enter the topic of the meeting'
                            )}
                            onChange={onMeetingTopicChange}
                        />
                    </div>
                    <div className="me-join-meeting-input">{passComp}</div>
                </div>
                <div className="me-join-meeting-actions">
                    <div className="me-join-meeting-menu">
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCMeeting|Create_Voice"
                                className={`me-join-meeting-menu-item ${
                                    state.audioDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCMeeting|Create_Video"
                                className={`me-join-meeting-menu-item ${
                                    state.videoDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCMeeting|Create_Invite"
                                className="me-join-meeting-menu-item me-join-meeting-menu-addUser"
                                onClick={() => handleMenuClick('addUser')}
                            >
                                <IconFont type="iconapp_btn_addcontacts" />
                            </span>
                            <span className="label">
                                {t_common('joinMeeting.%s person to attend').replace(
                                    '%s',
                                    state.userList.length.toString()
                                )}
                            </span>
                        </div>
                    </div>
                    <Button
                        disabled={!isValidMeeting()}
                        style={{ opacity: isValidMeeting() ? 1 : 0.5, width: 232 }}
                        type="primary"
                        clstag="pageclick|keycount|xtbg_PCMeeting|Create_New"
                        onClick={handleCreateMeeting}
                        loading={connect}
                        className="me-join-meeting-start-button"
                    >
                        {t_common('joinMeeting.Start the meeting')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default dvaConnect(mapStateToProps, mapDispatchToProps)(CreateMeetingModal);
