/* eslint-disable complexity */
/* eslint-disable no-console */
/**
 * 聊天会话状态管理
 * @author sunzhiguang
 * @date 2020/6/18
 */
import ImService from '@/server/ImService';
import ChatState from '@/types/chat/State';
import { MessageCategoryType, MessageNotifyType } from '@/types/chat/enum';
import {
    ChatMessage,
    ChatMessageType,
    Employee,
    MessageStatus,
    Session,
    SessionType,
    UID,
} from '@/types/chat';
import { executePut } from '@/server/dvaHelper';
import Bus from '@/utils/bus';
import findLastIndex from 'lodash/findLastIndex';
import remove from 'lodash/remove';
import lodashPick from 'lodash/pick';
import lodashGroupBy from 'lodash/groupBy';
import {
    employeeToUid,
    getSessionIndex,
    isEqualEmployee,
    isEqualUid,
    isInValidSessionInfo,
    messageCompare,
    sendGroupSessionNotify,
    setMessageGroupId,
    sleep,
    addOneSession,
    toMe,
    isNoticeMessage,
    isSysMessage,
    computeAITimestamp,
    getLatestMessages,
    uniqMergedMessage,
} from '@/utils/chat//message';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import MessageReadScene from '@jd/jdee.im.sdk/lib/es/enum/MessageReadScene';

import config, { ChatConfig } from '@/config/config';
import log from '@/utils/logger';
import { sessionAttrs, SessionSettingService, sortSessions } from '@/server/im/SessionService';
import {
    dealSessionNewMessage,
    dealSessionBatchMessage,
    dealMessageMerged,
} from '@/server/im/MessageService';
import {
    isAINoticeChildSession,
    isAINoticeSession,
    isAINoticeSessionKey,
    isUnnecessarySession,
} from '@/components/chat/utils/session';
import { message as AntMessage, message } from 'antd';
import { cloneDeep, flattenDeep } from 'lodash';
import CHAT_NOTICE from '@/contant/chat';
import { getGroupInfo } from '@/api/egroup';
import { addBatchViewCardRead } from '@/api/chat';

const logger = log.getLogger('modal:chat');

function defaultSelectedCategory(): MessageCategoryType {
    const types: MessageCategoryType[] = [];
    if (config[ChatConfig.CHAT_SESSION_LIST_GROUP_ALL_ENABLE]) {
        types.push(MessageCategoryType.ALL);
    }
    if (config[ChatConfig.CHAT_SESSION_LIST_GROUP_TIME_ENABLE]) {
        types.push(MessageCategoryType.TIME);
    }
    if (config[ChatConfig.CHAT_SESSION_LIST_GROUP_TYPE_ENABLE]) {
        types.push(MessageCategoryType.TYPE);
    }
    return types[0] || MessageCategoryType.ALL;
}

const defaultState = {
    topCount: 0,
    unReadCount: 0,
    atCount: 0,

    connectState: { state: ConnectState.CONNECTING },
    // 消息提醒类型：全部消息 未读消息 重要消息
    selectedNotifyType: MessageNotifyType.ALL, // 当前选中消息提醒类型
    notifyType: {
        [MessageNotifyType.ALL]: {
            count: 0,
            enable: true,
            // normalIcon: 'iconic_all_normal',
            // selectedIcon: 'iconic_all_hover',
            clstag: 'pageclick|keycount|Xtbg_Msg_Home|All',
            title: 'remind.all_message', // '全部消息',
        },
        [MessageNotifyType.GROUPS]: {
            count: 8,
            enable:
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE] &&
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_UNREAD_ENABLE],
            // normalIcon: 'iconapp_btn_peoplegroup',
            clstag: 'pageclick|keycount|Xtbg_Msg_Home|Group',
            // selectedIcon: 'iconapp_btn_peoplegroup',
            title: 'remind.all_group', // '组'
        },
        [MessageNotifyType.UNREAD]: {
            count: 89,
            enable:
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE] &&
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_UNREAD_ENABLE],
            // normalIcon: 'iconic_unreadl_normal',
            clstag: 'pageclick|keycount|Xtbg_Msg_Home|Unread',
            // selectedIcon: 'iconic_unreadl_hover',
            title: 'remind.unread_message', // '未读消息'
        },
        [MessageNotifyType.IMPORTANT]: {
            count: 8,
            enable:
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE] &&
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_IMPORTANT_ENABLE],
            // normalIcon: 'iconic_important_normal',
            clstag: 'pageclick|keycount|Xtbg_Msg_Home|AtMe',
            // selectedIcon: 'iconic_important_hover',
            title: 'remind.important_message', // '重要消息'
        },
    }, // 消息提醒配置
    // 消息分类： 时间分类，类型分类
    selectedCategory: defaultSelectedCategory(), // 当前选中消息分类 默认时间类型
    category: {
        [MessageCategoryType.ALL]: {
            enable:
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE] &&
                config[ChatConfig.CHAT_SESSION_LIST_GROUP_ALL_ENABLE],
            normalIcon: 'iconic_all_normal',
            clstag: 'pageclick|keycount|focus_chat_01_1615797500283|6',
            selectedIcon: 'iconic_all_hover',
            title: 'remind.all_message', // '全部消息',
        },
        // [MessageCategoryType.TIME]: {
        //     enable:
        //         config[ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE] &&
        //         config[ChatConfig.CHAT_SESSION_LIST_GROUP_TIME_ENABLE],
        //     normalIcon: 'iconic_time_',
        //     clstag: 'pageclick|keycount|focus_chat_01_1615797500283|7',
        //     selectedIcon: 'iconic_time_honer',
        //     title: 'category.time_message', // '时间',
        // },
        // [MessageCategoryType.TYPE]: {
        //     enable:
        //         config[ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE] &&
        //         config[ChatConfig.CHAT_SESSION_LIST_GROUP_TYPE_ENABLE],
        //     normalIcon: 'iconic_type_normal',
        //     selectedIcon: 'iconic_type_honer',
        //     title: 'category.type_message', // '类型'
        // },
    },

    currentEmployee: {},

    // 当前会话成员
    sessionMembers: [],
    // 群成员
    groupRosters: [],

    // 群主信息
    administrator: [],
    // 群管理员信息
    sub_administrator: [],
    // 群普通成员信息
    ordinary: [],
    groupMemberSessionId: '',
    // 消息分类配置
    selectedSession: {},
    preSelectedSession: {},

    // 消息上传文件的状态
    messageUploadStateMap: {},

    // 会活消息状态 key 为 sessionId
    sessionStatusMap: {},

    // 输入焦点状态
    messageInputFocus: false,

    // 当前会话监听到的最新消息数据 key 为 sessionId
    sessionMessageMap: {},
    prevAISessionMessages: [],
    // 存放所有临时会话消息 key 为 sessionId
    tempSessionMessageMap: {},
    // 所有会话列表
    allSessionList: [],
    sessionPage: 0,
    sessionLoadEnd: false,
    // AI助手通知消息聚合列表
    aiNoticeSessionList: [],
    selectedAITab: {
        pin: 'all-notice',
    },

    loadAllSessionListEnd: false,
    globalDrawerVisible: false,
    messageDrawerVisible: false,
    appcationApplyMessageId: '',
    selectMore: false,
    messages: [],
    type: null,
    imageMessages: [],
    mainWinShow: true,
    identityInfoModal: false, // 北京通号身份信息验证
    filterTabList: [], // AI助手内容tab筛选列表
    sessionScrollIndex: -1, // 会话跳转位置
    sessionUnReadList: [], // 会话未读数
    isDragging: false,
    captureType: '1', // 截屏类型 1：隐藏当前窗口截屏； 2：不隐藏当前窗口
    sendMsgType: '1', // 发送消息快捷键
    // 消息定位
    sMid: -1,
    sText: '',
    NoticeDetailsVisible: false, // 组通知详情
    notificationId: '', // 组通知ID
    solitaireId: '', // 组接龙
    SolitaireDetailsVisible: false,
    solitaireSessionId: '', // 组接龙历史
    SolitaireHisVisible: false,
    updateSessionMessagesTimestamp: 0,
    isMessageModule: false,
    dbVersion: '',
    migrateVisiable: false,
    quickReplyVersion: 0,
    quickReplys: [],
    downloadingMids: [], // 正在下载的mid队列，用于更新下载组件
};

export default {
    namespace: 'chat',
    state: { ...cloneDeep(defaultState) },
    reducers: {
        setIsDragging(state: ChatState, { payload }: any) {
            return { ...state, isDragging: payload };
        },
        setIsMessageModule(state: ChatState, { payload }: any) {
            return { ...state, isMessageModule: payload };
        },
        setQuickReplyInfo(state: ChatState, { payload }: any) {
            return {
                ...state,
                quickReplyVersion: payload.quickReplyVersion || 0,
                quickReplys: payload.quickReplys || [],
            };
        },
        setIdentityInfoModal(state: ChatState, { payload }: any) {
            return { ...state, identityInfoModal: payload };
        },
        updateMessages(state: ChatState, { payload }: any) {
            const { messages } = payload;
            return {
                ...state,
                messages: [...messages],
            };
        },
        changeSelectMore(state: ChatState, { payload }: any) {
            const { selectMore, type } = payload;
            return {
                ...state,
                selectMore,
                type,
            };
        },
        clearData(state: ChatState, { payload }: any) {
            return { ...defaultState };
        },
        setTopCount(state: ChatState, { payload }: any) {
            const { topCount } = payload;
            return {
                ...state,
                topCount,
            };
        },
        setUnReadCount(state: ChatState, { payload }: any) {
            const { unReadCount } = payload;
            return {
                ...state,
                unReadCount,
            };
        },
        setAtCount(state: ChatState, { payload }: any) {
            const { atCount } = payload;
            return {
                ...state,
                atCount,
            };
        },

        updateCount(state: ChatState, { payload }: any) {
            const {
                allSessionList = [],
                currentEmployee,
                selectedSession,
                aiNoticeSessionList = [],
            } = state;
            let topCount = 0;
            let atCount = 0;
            allSessionList.forEach((s: Session) => {
                if (((s.settings || {}).top || 0) > 0) {
                    topCount++;
                }
                if (s.sessionId === selectedSession?.sessionId) {
                    return false;
                }
                const messages = Object.values(s.mentions || {});
                if (messages.length === 0) {
                    return false;
                }
                for (let message of messages) {
                    const index = message.users.findIndex((user) => {
                        return (
                            isEqualEmployee(user as Employee, currentEmployee) ||
                            user.userId === 'all'
                        );
                    });
                    if (index > -1) atCount++;
                }
            });
            const nomalSession = allSessionList.filter(
                (item: Session) =>
                    aiNoticeSessionList.findIndex((s) => s.sessionId === item.sessionId) === -1
            );
            const sessionUnReadList = nomalSession
                .filter((item: Session) => item.unreadCount > 0)
                .map((item) => {
                    return {
                        id: item.sessionId,
                        index: nomalSession.findIndex((i) => i.sessionId === item.sessionId),
                    };
                });
            const noticeCount = aiNoticeSessionList.reduce((total, s) => {
                let num = total;
                num += s.unreadCount;
                return num;
            }, 0);
            if (noticeCount > 0) {
                const idx = allSessionList.findIndex((s) => isAINoticeSession(s));
                if (idx > -1) {
                    sessionUnReadList.unshift({
                        id: allSessionList[idx].sessionId,
                        index: 0,
                    });
                }
            }
            // 发送数量变更通知
            return { ...state, atCount, topCount, sessionUnReadList };
        },

        updateNotifyType(state: ChatState, { payload }: any) {
            const { selectedNotifyType } = payload;
            return {
                ...state,
                notifyTypeTitle: state.notifyType[selectedNotifyType].title,
                selectedNotifyType,
            };
        },
        updateCategory(state: ChatState, { payload }: any) {
            const { selectedCategory } = payload;
            return {
                ...state,
                categoryTitle: state.category[selectedCategory].title,
                selectedCategory,
            };
        },
        // 更新当前会话session 并初始化
        initSelectedSession(state: ChatState, { payload }: any) {
            const { selectedSession } = payload;
            const {
                allSessionList,
                sessionMessageMap,
                tempSessionMessageMap,
                selectedSession: currSession,
            } = state;
            // tj: 消息数据缓存
            if (selectedSession.sessionId && !sessionMessageMap[selectedSession.sessionId]) {
                sessionMessageMap[selectedSession.sessionId] = [];
            }
            // 把不是当前会话的数据置空
            if (selectedSession.sessionId !== currSession?.sessionId) {
                for (let key of Object.keys(sessionMessageMap)) {
                    if (key !== selectedSession.sessionId) {
                        tempSessionMessageMap[key] = [];
                        const msgs = sessionMessageMap[key];
                        const msgLen = msgs.length;
                        if (isAINoticeSessionKey(key)) {
                            sessionMessageMap[key] = [];
                        } else if (msgLen) {
                            const index = getSessionIndex(allSessionList, { sessionId: key });
                            if (index < 0) {
                                continue;
                            }
                            const session = allSessionList[index];
                            if (msgs[msgLen - 1].mid !== session?.lastMsg?.mid) {
                                sessionMessageMap[key] = [];
                            } else {
                                const msg = sessionMessageMap[key]
                                    .slice(-8)
                                    .filter((m: any) => m.statusType !== MessageStatus.TEMP);
                                sessionMessageMap[key] = msg;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                mainWinShow: true,
                selectedSession: selectedSession,
                sessionMessageMap,
                tempSessionMessageMap,
            };
        },

        initSelectedAITab(state: ChatState, { payload }: any) {
            const { selectedAITab } = payload;
            // 初始化 sessionMessageMap
            const { sessionMessageMap, selectedSession } = state;
            if (selectedAITab.pin === CHAT_NOTICE.ALL_PIN) {
                sessionMessageMap[selectedSession.sessionId] = (
                    sessionMessageMap[selectedSession.sessionId] || []
                ).slice(-5);
            }
            return {
                ...state,
                mainWinShow: true,
                selectedAITab,
                sessionMessageMap,
            };
        },

        updateSessionMembers(state: ChatState, { payload }: any) {
            const { sessionMembers } = payload;
            return {
                ...state,
                sessionMembers: sessionMembers || [],
            };
        },

        updateGroupRosters(state: ChatState, { payload }: any) {
            const { administrator, sub_administrator, ordinary, groupMemberSessionId } = payload;
            return {
                ...state,
                administrator: administrator || [],
                sub_administrator: sub_administrator || [],
                ordinary: ordinary || [],
                groupMemberSessionId,
            };
        },

        removeGroupMember(state: ChatState, { payload }: any) {
            const groupRosters = state.groupRosters;
            const member: UID = payload.member;
            remove(groupRosters, (item) => item.info?.userId === member?.pin);
            return {
                ...state,
                groupRosters: [...groupRosters],
            };
        },

        changeGroupAdmin(state: ChatState, { payload }: any) {
            const { administrator, ordinary } = payload;
            return {
                ...state,
                administrator: administrator || [],
                ordinary: ordinary || [],
            };
        },

        updateGroupMembers(state: ChatState, { payload }: any) {
            const { groupRosters } = payload;
            return {
                ...state,
                groupRosters: groupRosters,
            };
        },

        updateSelectedSession(state: ChatState, { payload }: any) {
            const { selectedSession } = payload;
            return {
                ...state,
                selectedSession: { ...selectedSession },
            };
        },

        updateSessionData(state: ChatState, { payload }: any) {
            // eslint-disable-next-line no-debugger
            // debugger;
            const { session } = payload;
            const { allSessionList } = state;
            const index = getSessionIndex(allSessionList, { sessionId: session.sessionId });
            const newState: any = {};
            if (index >= 0) {
                allSessionList[index] = session;
                newState.allSessionList = [...allSessionList]; // 暂时先更新列表，因为置顶需要同步改变
            }
            return {
                ...state,
                ...newState,
            };
        },

        updateSelectedAITab(state: ChatState, { payload }: any) {
            const { selectedAITab } = payload;
            return {
                ...state,
                selectedAITab: { ...selectedAITab },
            };
        },

        setCurrentEmployee(state: ChatState, { payload }: any) {
            return { ...state, currentEmployee: payload.currentEmployee };
        },
        updateCurrentEmployee(state: ChatState, { payload }: any) {
            return {
                ...state,
                currentEmployee: { ...state.currentEmployee, ...payload },
            };
        },
        setStartMessageId(state: ChatState, { payload }: any) {
            const { sMid, sText } = payload;
            return {
                ...state,
                sMid,
                sText,
            };
        },
        clearSessionAllMessage(state: ChatState, { payload }: any) {
            const newState = state;
            const {
                allSessionList = [],
                sessionMessageMap = {},
                tempSessionMessageMap = {},
                sessionStatusMap = {},
            } = newState;
            const { sessionId } = payload;
            // TODO 去掉 allSessionList中的lastMsg wzp 2021-03-19
            // start
            try {
                for (let item of allSessionList) {
                    if (item.sessionId === sessionId) {
                        item.lastMsg = undefined;
                    }
                }
            } catch (e) {
                console.warn(e);
            }

            sessionMessageMap[sessionId] = [];
            tempSessionMessageMap[sessionId] = [];
            delete tempSessionMessageMap[sessionId];
            delete sessionStatusMap[sessionId];
            newState.tempSessionMessageMap = tempSessionMessageMap;
            newState.sessionMessageMap = sessionMessageMap;
            newState.sessionStatusMap = sessionStatusMap;
            // console.log(newState, 'newStatenewStatenewState');
            return {
                ...newState,
            };
        },
        clearAISessionAllMessage(state: ChatState, { payload }: any) {
            const newState = state;
            const { aiNoticeSessionList = [], selectedSession } = newState;
            // 初始化 sessionMessageMap
            const sessionMessageMap = newState.sessionMessageMap || {};
            aiNoticeSessionList.forEach((item: any) => {
                item.lastMsg = undefined;
                sessionMessageMap[item.sessionId] = [];
            });
            const selectedSessionId = selectedSession?.sessionId;
            sessionMessageMap[selectedSessionId] = [];
            return {
                ...newState,
                sessionMessageMap,
            };
        },
        clearSessionStatusMap(state: ChatState) {
            const newState = state;
            newState.sessionStatusMap = {};
            newState.tempSessionMessageMap = {};
            newState.sessionMessageMap = {};
            return {
                ...newState,
            };
        },
        updateSessionMessage(state: ChatState, { payload }: any) {
            const newState = state;
            newState.sessionMessageMap = payload.sessionMessageMap;
            return { ...newState };
        },

        updateSessionMessagesTimestamp(state: ChatState, { payload }: any) {
            const newState = state;
            newState.updateSessionMessagesTimestamp = payload.timestamp;
            return { ...newState };
        },

        deleteSessionMessage(state: ChatState, { payload }: any) {
            const { sessionMessageMap, tempSessionMessageMap } = state;
            const message = payload.message as ChatMessage;
            const sessionMessage = sessionMessageMap[message.sessionId];
            const tempMessage = tempSessionMessageMap[message.sessionId] || [];
            remove(sessionMessage, (item) => {
                return item.id === message.id;
            });
            remove(tempMessage, (item) => {
                return item.id === message.id;
            });
            sessionMessageMap[message.sessionId] = [...sessionMessage];
            tempSessionMessageMap[message.sessionId] = [...tempMessage];
            return {
                ...state,
                sessionMessageMap,
                tempSessionMessageMap,
            };
        },

        pushChatMessage(state: ChatState, { payload }: any) {
            const { message, sessionId } = payload;
            if (sessionId && message) {
                const {
                    sessionMessageMap = {},
                    tempSessionMessageMap = {},
                    messageUploadStateMap = {},
                } = state;
                const sessionMessage = sessionMessageMap[sessionId] || [];
                const prev = sessionMessage[sessionMessage.length - 1];

                message.beforeMid = prev?.mid || prev?.beforeMid || -1;
                // tj: 增加消息排序
                setMessageGroupId(message, prev || null);
                sessionMessageMap[sessionId] = [...sessionMessage, message].sort(messageCompare);

                // 临时消息，发送的消息体暂时存放在这里
                const tempSessionMessage = tempSessionMessageMap[sessionId] || [];
                tempSessionMessage.push(message);
                tempSessionMessageMap[sessionId] = tempSessionMessage.sort(messageCompare);

                const newState = {
                    ...state,
                    sessionMessageMap,
                    tempSessionMessageMap,
                };
                if (message.task) {
                    // 文件消息需要将消息放入上传状态进管理
                    messageUploadStateMap[message.id] = {
                        message: message,
                        task: message.task,
                        progress: 0,
                        uploadResult: {},
                    };
                    Object.assign(newState, { tempSessionMessageMap });
                }
                return newState;
            }
            return state;
        },

        // 设置消息会话状态数据
        setSessionStatus(state: ChatState, { payload }: any) {
            const sessionId = payload.sessionId;
            const newSessionStatusInfo = payload.sessionStatusInfo;
            const sessionStatusMap = state.sessionStatusMap || {};
            const oldSessionStatusInfo = sessionStatusMap[sessionId] || {};
            if (newSessionStatusInfo.info.content) {
                sessionStatusMap[sessionId] = { ...oldSessionStatusInfo, ...newSessionStatusInfo };
            } else {
                delete sessionStatusMap[sessionId];
            }
            return {
                ...state,
                sessionStatusMap: { ...sessionStatusMap },
            };
        },

        // 消息焦点状态
        setMessageInputFocus(state: ChatState, { payload }: any) {
            return {
                ...state,
                messageInputFocus: payload,
            };
        },

        reSortAllSessionList(state: ChatState, { payload }: any) {
            const allSessionList: Session[] = payload.allSessionList;
            // console.log('排序前', allSessionList);
            const list = sortSessions(allSessionList);
            // console.log('排序后', list);
            // const list = allSessionList.sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0));
            const aiSessionIndex = list.findIndex((item: Session) => isAINoticeSession(item));
            if (aiSessionIndex > -1 && (list[aiSessionIndex].settings.top || 0) > 0) {
                const [aiSessionItem] = list.splice(
                    list.findIndex((item: Session) => isAINoticeSession(item)),
                    1
                );
                list.splice(0, 0, aiSessionItem);
            }
            return {
                ...state,
                allSessionList: [...list],
            };
        },

        // 通知消息会话列表
        updateSessionList(state: ChatState, { payload }: any) {
            const allSessionList: Session[] = payload.allSessionList;
            const oldSessionList = state.allSessionList;
            for (const s of allSessionList) {
                const idx = oldSessionList.findIndex((i) => i.sessionId === s.sessionId);
                if (idx > -1) {
                    oldSessionList[idx] = s;
                } else {
                    oldSessionList.push(s);
                }
            }
            const list = sortSessions(oldSessionList).filter((s) => !isUnnecessarySession(s));
            const aiSessionIndex = list.findIndex((item: Session) => isAINoticeSession(item));
            if (aiSessionIndex > -1 && (list[aiSessionIndex].settings.top || 0) > 0) {
                const [aiSessionItem] = list.splice(
                    list.findIndex((item: Session) => isAINoticeSession(item)),
                    1
                );
                list.splice(0, 0, aiSessionItem);
            }
            const aiNoticeSessionList = oldSessionList.filter(
                (session: Session) => session.isNotice && isAINoticeChildSession(session)
            );
            // console.log('排序后111', list);
            return {
                ...state,
                allSessionList: [...list],
                aiNoticeSessionList: aiNoticeSessionList,
            };
        },
        // ai消息会话列表
        updateAISessionList(state: ChatState, { payload }: any) {
            return {
                ...state,
                aiNoticeSessionList: [...payload.aiNoticeSessionList],
            };
        },
        // 更新拉到列表是否已完成状态
        updateSessionListState(state: ChatState, { payload }: any) {
            return {
                ...state,
                loadAllSessionListEnd: true,
            };
        },

        // 拉取会话状态
        updateSessionLoadStatus(state: ChatState, { payload }: any) {
            // eslint-disable-next-line no-debugger
            // debugger;
            console.log('updateSessionLoadStatus', state, payload);
            return {
                ...state,
                sessionPage: payload.sessionPage,
                sessionLoadEnd: payload.sessionLoadEnd,
            };
        },

        removeSession(state: ChatState, { payload }: any) {
            const { allSessionList, selectedSession } = state;
            const { sessionId } = payload;
            const index = getSessionIndex(allSessionList, { sessionId });
            const newState: any = {};
            if (index >= 0) {
                allSessionList.splice(index, 1);
                newState.allSessionList = [...allSessionList]; // 暂时先更新列表，因为置顶需要同步改变
            }
            if (selectedSession && sessionId === selectedSession.sessionId) {
                newState.selectedSession = {};
            }
            if (Object.keys(newState).length > 0) {
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        },

        updatePreSelectedSession(state: ChatState, { payload }: any) {
            const { preSelectedSession } = payload;
            state.preSelectedSession = { ...preSelectedSession };
            return state;
        },

        updatePreAISessionMsg(state: ChatState, { payload }: any) {
            const { prevAISessionMessages = [] } = payload;
            state.prevAISessionMessages = [...prevAISessionMessages];
            return state;
        },

        updateConnectState(state: ChatState, { payload }: any) {
            if (!navigator.onLine && payload.connectState?.state !== ConnectState.OFFLINE) {
                return { ...state };
            }
            return { ...state, connectState: payload.connectState };
        },

        updateTempMessageStatus(state: ChatState, { payload }: any) {
            const { status, sessionId, id } = payload;
            const tempSessionMessageMap = state.tempSessionMessageMap;
            const tempSessionMessage = tempSessionMessageMap[sessionId] || [];
            // console.log('tempSessionMessage', tempSessionMessage, id, sessionId, status);
            const index = tempSessionMessage.findIndex((item) => item.id === id);
            if (index > -1) {
                tempSessionMessage[index] = { ...tempSessionMessage[index], status };
                tempSessionMessageMap[sessionId] = tempSessionMessage;
                return {
                    ...state,
                    tempSessionMessageMap: { ...tempSessionMessageMap },
                };
            }
            return state;
        },

        setHistoryMessage(state: ChatState, { payload }: any) {
            const { currentEmployee, tempSessionMessageMap, sessionMessageMap } = state;
            const { sessionId } = payload;
            let { historyMessage } = payload;
            if (historyMessage?.length) {
                historyMessage = historyMessage.filter((msg: any) => {
                    return msg?.type !== 'image' || msg?.url;
                });
            }
            const oldSessionMessages = sessionMessageMap[sessionId] || [];
            const tempSessionMessage = (tempSessionMessageMap[sessionId] || []).filter((item) => {
                return isEqualEmployee(item.sender, currentEmployee);
            });
            if ((tempSessionMessage || []).length > 0) {
                oldSessionMessages.push(...tempSessionMessage);
            }
            // console.log('historyMessage', historyMessage);
            // 解决合并转发消息重复问题
            let messages = getLatestMessages([...oldSessionMessages, ...historyMessage]).sort(
                messageCompare
            );
            // console.log('setHistoryMessage111', messages);
            messages = uniqMergedMessage(messages);
            // console.log('setHistoryMessage222', messages);
            sessionMessageMap[sessionId] = messages;
            return {
                ...state,
                sessionMessageMap,
            };
        },

        updateMessageUploadState(state: ChatState, { payload }: any) {
            const progress = payload.progress;
            const msgId = payload.msgId;
            const uploadResult = payload.uploadResult || {};
            const messageUploadStateMap = state.messageUploadStateMap;
            const data = messageUploadStateMap[msgId] || {};
            messageUploadStateMap[msgId] = {
                ...data,
                ...{ progress: progress || data.progress, uploadResult },
            };
            return {
                ...state,
                messageUploadStateMap: { ...messageUploadStateMap },
            };
        },

        clearMessageUploadState(state: ChatState, { payload }: any) {
            const msgId = payload.msgId;
            const messageUploadStateMap = state.messageUploadStateMap;
            delete messageUploadStateMap[msgId];
            return {
                ...state,
                messageUploadStateMap: { ...messageUploadStateMap },
            };
        },

        clearTempMessage(state: ChatState, { payload }: any) {
            const { sessionId, msgId } = payload;
            const tempSessionMessageMap = state.tempSessionMessageMap;
            const tempSessionMessages = tempSessionMessageMap[sessionId] || [];
            const sessionStatusMap = state.sessionStatusMap;
            // 通过 message 的 id 获取昨时消息是否存在 如果存在临时消息 执行清除操作
            const newTempSessionMessages = tempSessionMessages.filter((item) => item.id !== msgId);
            tempSessionMessageMap[sessionId] = [...newTempSessionMessages];
            delete sessionStatusMap[sessionId];
            return {
                ...state,
                ...sessionStatusMap,
                tempSessionMessageMap: tempSessionMessageMap,
            };
        },
        setglobalDrawerVisible(state: ChatState, { payload }: any) {
            return {
                ...state,
                globalDrawerVisible: payload,
            };
        },
        setmessageDrawerVisible(state: ChatState, { payload }: any) {
            return {
                ...state,
                messageDrawerVisible: payload,
            };
        },
        setappcationApplyMessageId(state: ChatState, { payload }: any) {
            return {
                ...state,
                appcationApplyMessageId: payload,
            };
        },
        clearSelectSession(state: ChatState) {
            return {
                ...state,
                setSessionScrollIndex: -1,
                selectedSession: {},
                preSelectedSession: {},
                // sessionMessageMap: {},
                administrator: [],
                sub_administrator: [],
                ordinary: [],
                sessionMessages: [],
            };
        },
        setImageMessages(state: ChatState, { payload }: any) {
            const filterImageMessages = payload.messages.filter(
                (message: any) => message.type === ChatMessageType.IMAGE
            );
            return {
                ...state,
                imageMessages: filterImageMessages,
            };
        },
        setMainWinShow(state: ChatState, { payload }: any) {
            return {
                ...state,
                mainWinShow: payload.show,
            };
        },
        updateFilterTabList(state: ChatState, { payload }: any) {
            const { filterTabList } = payload;
            return {
                ...state,
                filterTabList,
            };
        },
        setSessionScrollIndex(state: ChatState, { payload }: any) {
            return {
                ...state,
                sessionScrollIndex: payload.sessionScrollIndex,
            };
        },
        // 设置截屏类型
        setCaptureType(state: ChatState, { payload }: any) {
            return {
                ...state,
                captureType: payload.captureType,
            };
        },
        // 发送消息快捷键
        setMsgtureType(state: ChatState, { payload }: any) {
            return {
                ...state,
                sendMsgType: payload.sendMsgType,
            };
        },
        // 组通知详情
        openNoticeDetails(state: ChatState, { payload }: any) {
            const { isvisibles, notificationId, type } = payload;
            return isvisibles
                ? {
                      ...state,
                      NoticeDetailsVisible: isvisibles,
                      notificationId: notificationId,
                      type: type,
                  }
                : {
                      ...state,
                      NoticeDetailsVisible: false,
                      notificationId: '',
                      type: '',
                  };
        },
        // 组接龙详情
        openSolitaireDetails(state: ChatState, { payload }: any) {
            const { isvisibles, solitaire } = payload;
            return isvisibles
                ? {
                      ...state,
                      SolitaireDetailsVisible: isvisibles,
                      solitaireId: solitaire,
                  }
                : {
                      ...state,
                      SolitaireDetailsVisible: false,
                      solitaireId: '',
                  };
        },
        // 组接龙历史
        openSolitaireHis(state: ChatState, { payload }: any) {
            const { isvisibles, solitaireSid } = payload;
            return isvisibles
                ? {
                      ...state,
                      SolitaireHisVisible: isvisibles,
                      solitaireSessionId: solitaireSid,
                  }
                : {
                      ...state,
                      SolitaireHisVisible: false,
                      solitaireSessionId: '',
                  };
        },
        // 数据迁移
        setMigrateVisiable(state: ChatState, { payload }: any) {
            return {
                ...state,
                migrateVisiable: payload.migrateVisiable,
            };
        },
        setDbVersion(state: ChatState, { payload }: any) {
            return {
                ...state,
                dbVersion: payload.dbVersion,
            };
        },
        updateDownloadingMids(state: ChatState, { payload }: any) {
            return {
                ...state,
                downloadingMids: payload,
            };
        },
    },
    effects: {
        // 获取当前会话的图片消息类型
        *getImageMessagesOfCurrentSession({ payload }: any, { put }: any) {
            const imService = ImService.getInstance();
            const params = {
                sessionId: payload.sessionId,
                searchKey: '',
                searchType: ChatMessageType.IMAGE,
                composeVideo: true,
            };
            const messages = yield imService.search(params);
            yield put({
                type: 'setImageMessages',
                payload: {
                    messages,
                },
            });
        },
        // 选择消息分类 全部 未读 重要
        *changeNotifyType({ payload: { selectedNotifyType } }: any, { put }: any) {
            yield executePut(put, 'updateNotifyType', { selectedNotifyType });
        },
        // 选择类型分类 时间 类别
        *changeCategory({ payload: { selectedCategory } }: any, { put }: any) {
            yield executePut(put, 'updateCategory', { selectedCategory });
        },

        *getCurrentEmployee({ payload }: any, { put, select }: any) {
            const imService = ImService.getInstance();
            const currentEmployee = yield imService.getCurrentEmployee();
            yield executePut(put, 'setCurrentEmployee', { currentEmployee });
        },

        // 获取所有消息
        *getAllChatSessions({ payload }: any, { put, select }: any) {
            // 用户选择的消息展示选项
            // eslint-disable-next-line no-debugger
            // debugger;
            const imService = ImService.getInstance();
            const { options = { offset: 0, count: 20 } } = payload;
            const { allSessionList, page, end } = yield imService.loadSessionPageList(
                {},
                false,
                options
            );
            // console.log('allSessionList=====>', payload, allSessionList);
            // console.log('allSesion =>', allSessionList);
            // 筛选出ai助手聚合的session;

            yield executePut(put, 'updateSessionList', { allSessionList: allSessionList || [] });
            yield executePut(put, 'updateSessionListState', {});
            yield executePut(put, 'updateSessionLoadStatus', {
                sessionPage: page,
                sessionLoadEnd: end,
            });
            // 更新会话相关的记数
            yield executePut(put, 'updateCount', {});
            // 异步处理
            if (options.offset === 0) {
                imService?.getOfflineMessage();
                imService?.getNoticeSessionList();
            }
        },

        // 清空聊天记录
        *clearSessionMessage({ payload }: any, { put, select }: any) {
            const { sessionId } = payload;
            if (sessionId.indexOf(CHAT_NOTICE.AI_PIN) > -1) {
                yield executePut(put, 'clearAISessionAllMessage', payload);
            } else {
                yield executePut(put, 'clearSessionAllMessage', payload);
            }
        },
        /**
         * 接收一条新消息 交给 ImService 处理
         * @param chatMessage
         * @param put
         * @param select
         */
        // eslint-disable-next-line complexity
        *receiveSessionNewMessage({ payload: { chatMessage } }: any, { put, select }: any) {
            // console.log('receiveSessionNewMessage', chatMessage);
            // eslint-disable-next-line no-debugger
            // debugger;
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const show = chatState.mainWinShow;
            Bus.emit('calendar_im_message', chatMessage);
            if (chatMessage.statusType === MessageStatus.FAILED && chatMessage.localSendTimestamp) {
                if (!chatMessage.belong && !chatMessage.sender?.userId) {
                    chatMessage.sender = { ...chatMessage.sender, ...chatState.currentEmployee };
                }
            }

            // 接收当前会话新消息
            const imService = ImService.getInstance();
            // console.log('接收到一条新消息', chatMessage);
            const result = yield dealSessionNewMessage(chatState, chatMessage);
            // console.log('接收到一条新消息111', chatMessage);

            // eslint-disable-next-line no-debugger
            const { selectedSession = {} } = chatState;
            const currentSessionId =
                chatState.selectedSession && chatState.selectedSession.sessionId;
            const currentMessageSessionId = chatMessage?.sessionId;
            if (result.changed) {
                // console.log('chatMessage => ', chatMessage);
                // 当前会话是否为ai助手，如果是需判断接收的消息是否为ai助手的集合消息，然后控制读取
                if (selectedSession.isNotice && isAINoticeSession(selectedSession)) {
                    if (isNoticeMessage(chatMessage) && !isSysMessage(chatMessage)) {
                        const _chatMessage = {
                            ...chatMessage,
                            sessionId: chatMessage?.requestData?.sessionId,
                        };
                        // console.log('AI助手接收到一条新消息', _chatMessage);
                        imService.readOneMessage(_chatMessage).then(() => {
                            if (
                                _chatMessage.extend.bizId &&
                                _chatMessage.extend.readRecord === true
                            ) {
                                addBatchViewCardRead([_chatMessage]);
                            }
                        });
                    }
                } else if (currentMessageSessionId === currentSessionId && show) {
                    imService.readOneMessage(chatMessage).then();
                    // 群解散之后再发消息给出提示
                    if (
                        chatMessage.statusType === MessageStatus.FAILED &&
                        chatMessage.localSendTimestamp &&
                        chatMessage.sessionId &&
                        chatMessage.sessionId.indexOf(':') === -1
                    ) {
                        yield getGroupInfo({
                            groupIds: [{ groupId: chatMessage.sessionId || chatMessage.gid || '' }],
                        })
                            .then((res) => {
                                if (res.groups[0].flag !== 3) {
                                    message.error('该组已解散！');
                                }
                            })
                            .catch((err) => {
                                console.error('发送消息失败----err', err);
                            });
                    }
                }
                const sessionMessageMap = result.sessionMessageMap;
                yield executePut(put, 'updateSessionMessage', { sessionMessageMap });
            }
            if (
                chatMessage.type === MessageStatus.GROUPDELETE &&
                chatMessage.sessionId === currentSessionId
            ) {
                Bus.emit('chat:group_delete', chatMessage.sessionId);
            }
        },

        /**
         * 接收到批量消息
         * @param chatMessages
         * @param put
         * @param select
         */
        *receiveOneSessionBatchMessage(
            { payload: { chatMessages, sessionId } }: any,
            { put, select }: any
        ) {
            // eslint-disable-next-line no-debugger
            // debugger;
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const show = chatState.mainWinShow;
            const currentSessionId = chatState.selectedSession?.sessionId;
            const currentEmployee = chatState.currentEmployee;
            // 接收当前会话新消息
            const len = chatMessages.length;
            if (!len) {
                return;
            }
            const imService = ImService.getInstance();
            const result = yield dealSessionBatchMessage(chatState, chatMessages, sessionId);
            if (result.changed) {
                const sessionMessageMap = result.sessionMessageMap;
                yield executePut(put, 'updateSessionMessage', { sessionMessageMap });
                yield executePut(put, 'updateSessionMessagesTimestamp', {
                    timestamp: new Date().getTime(),
                });
                if (sessionId === currentSessionId && show) {
                    chatMessages.forEach((m: any) => {
                        if (!isEqualEmployee(m.sender, currentEmployee) && !m.isRead) {
                            imService.readOneMessage(m).then();
                        }
                    });
                }
            }
        },

        *receiveSessionBatchMessage({ payload: { chatMessages } }: any, { put, select }: any) {
            // 接收当前会话新消息
            const len = chatMessages.length;
            if (!len) {
                return;
            }
            const groups = lodashGroupBy(chatMessages, 'sessionId');
            for (const key of Object.keys(groups)) {
                yield executePut(put, 'receiveOneSessionBatchMessage', {
                    chatMessages: groups[key],
                    sessionId: key,
                });
            }
        },

        *deleteSessionMessage({ payload }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const { sessionMessageMap, selectedSession } = chatState;
            const message = payload.message as ChatMessage;
            const sessionMessage = sessionMessageMap[message.sessionId];
            remove(sessionMessage, (item: ChatMessage) => {
                return item.id === message.id;
            });
            if (selectedSession.isGroup) {
                const result = yield dealMessageMerged(selectedSession, sessionMessage);
                sessionMessageMap[message.sessionId] = [...result];
            } else {
                sessionMessageMap[message.sessionId] = sessionMessage;
            }
            yield executePut(put, 'updateSessionMessage', { sessionMessageMap });
        },

        *destroySessionMessage({ payload }: any, { put, select }: any) {
            for (let message of payload.messages) {
                // console.log(66666, message);
                yield executePut(put, 'deleteSessionMessage', { message });
            }
            yield executePut(put, 'updateMessages', { messages: [] });
            yield executePut(put, 'changeSelectMore', { selectMore: false });
        },

        // 选中会话进行的操作
        // *selectOneSession({ payload: { selectedSession } }: any, { put, select }: any) {
        //     const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
        //     // 设置当前会话
        //     yield executePut(put, 'initSelectedSession', { selectedSession });
        // },

        // 更新选中会话信息
        *updateSelectSessionInfo({ payload: { session } }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const { selectedSession } = chatState;
            if (selectedSession?.sessionId !== session?.sessionId) {
                return false;
            }
            // 设置当前会话
            yield executePut(put, 'updateSelectedSession', { selectedSession: session });
        },

        *loadSessionMembers({ payload: { selectedSession } }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const imService = ImService.getInstance();
            const { currentEmployee } = chatState;
            if (selectedSession.isGroup) {
                // 群组会话
                const rosters = yield imService.getGroupRosterInfo(selectedSession.sessionId, true);
                if (rosters && rosters.length > 0) {
                    const data = lodashGroupBy(rosters, 'identity');
                    // console.log('分组', data);
                    yield executePut(put, 'updateGroupRosters', {
                        administrator: data.administrator || [],
                        sub_administrator: data.sub_administrator || [],
                        ordinary: data.ordinary || [],
                        groupMemberSessionId: selectedSession.sessionId,
                    });
                }
            } else if (selectedSession.isSingle) {
                if (toMe({ session: selectedSession, employee: currentEmployee })) {
                    yield executePut(put, 'updateSessionMembers', { sessionMembers: [] });
                    return;
                }
                // 单人会话
                const sessionMembers = yield imService.getSingleMembers(
                    selectedSession,
                    currentEmployee
                );
                yield executePut(put, 'updateSessionMembers', {
                    sessionMembers: [...sessionMembers],
                });
            }
        },

        *launchGroupSession({ payload: { sid, top } }: any, { put, select }: any) {
            if (!sid) {
                return null;
            }
            const imService = ImService.getInstance();
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);

            const { selectedSession, connectState } = chatState;
            // 拉起会话， 如果会话id 与 当前 selectedSession 的id相同
            if (sid === selectedSession.sessionId) {
                return null;
            }
            const allSessionList = chatState.allSessionList || [];
            let index = -1;
            if (chatState.allSessionList?.length > 0) {
                index = getSessionIndex(chatState.allSessionList, { sessionId: sid });
            }
            let session = allSessionList[index];
            if (!session || isInValidSessionInfo(session)) {
                // 先获取群会话
                session = yield imService.launchGroupSession(sid);
            }
            if (!session) {
                return null;
            }
            // 清空状态
            session.unreadCount = 0;
            session.mentions = {};
            session.lastReadMid = session.lastMid || 0;
            Bus.emit(`chat:session-update:${session.sessionId}`, session);
            yield executePut(put, 'initSelectedSession', { selectedSession: session });
            yield executePut(put, 'updateGroupRosters', {
                administrator: [],
                sub_administrator: [],
                ordinary: [],
                groupMemberSessionId: '',
            });
            // 去缓存中查一下
            if (!session.info || !session.info.flag) {
                let group = yield imService.getGroup(session.sessionId);
                if (!group?.name || !group?.flag) {
                    let groupInfo = yield getGroupInfo({
                        groupIds: [{ groupId: session.sessionId || session.gid || '' }],
                    }).catch((err) => {
                        console.error('reloadSession-err', err);
                        return { groups: [] };
                    });
                    if (groupInfo?.groups) {
                        group = groupInfo.groups[0] || {};
                    }
                }
                session.info = group || {};
            }
            // 更新信息
            yield executePut(put, 'updateSelectSessionInfo', { session });
            yield executePut(put, 'loadSessionMembers', { selectedSession: session });
            // 检测是否在组
            // 判断info
            if (session.info && session.info.flag && session.info.flag !== 3) {
                AntMessage.error('该组已解散', 2);
                yield executePut(put, 'updateSelectSessionInfo', { session });
                return null;
            } else if (connectState?.state === ConnectState.READY) {
                try {
                    let _group;
                    const _groupInfo = yield getGroupInfo({
                        groupIds: [{ groupId: session.sessionId || session.gid || '' }],
                    }).catch((err) => {
                        console.error('getGroupInfo----err', err);
                    });
                    if (_groupInfo?.groups) {
                        _group = _groupInfo.groups[0] || undefined;
                    }
                    if (_group) {
                        session.info = _group;
                        yield executePut(put, 'updateSelectSessionInfo', { session });
                    }
                    if (_group && _group.flag !== 3) {
                        AntMessage.error('该组已解散', 2);
                        return null;
                    }
                } catch (e) {
                    console.error(`launchGroupSession checkInGroup error: `, e);
                }
            }
            if (index < 0) {
                // 解决[bug] 群聊重复 --hujun
                const isExistence = allSessionList.some((item: { id: any }) => {
                    if (session && item.id === session.id) {
                        return true;
                    }
                    return false;
                });
                // 如果session不存在allSessionList中则添加
                if (!isExistence) {
                    allSessionList.push(session);
                }
                // 解决[bug] 群聊重复 --end
                yield executePut(put, 'updateSessionList', { allSessionList });
                return;
            }
            // console.log('拉取群组', allSessionList, session);
            if (top && index > 0) {
                // 如果需要置定并且存在当前的会话列表 列表进行重新排序
                allSessionList[index].timestamp = Date.now();
                yield executePut(put, 'reSortAllSessionList', {
                    allSessionList: allSessionList,
                });
            }
            return true;
        },
        *launchNoticeSession({ payload: { sid, top } }: any, { put, select }: any) {
            if (!sid) {
                return;
            }
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const { selectedSession, aiNoticeSessionList } = chatState;
            if (sid === selectedSession.sessionId) {
                return;
            }
            const allSessionList = chatState.allSessionList;
            let index = -1;
            if (chatState.allSessionList?.length > 0) {
                index = getSessionIndex(chatState.allSessionList, { sessionId: sid });
            }
            let session = allSessionList[index];
            const imService = ImService.getInstance();
            if (!session) {
                session = yield imService.loadNoticeSession(sid);
            }

            if (session && !isInValidSessionInfo(session)) {
                yield executePut(put, 'initSelectedSession', {
                    selectedSession: session,
                });
                // 异步更新头像
                const { info } = session;
                const { app, userId, teamId } = info || {};
                if (!session.sessionId.includes(CHAT_NOTICE.AI_PIN)) {
                    imService.getEmployeeDetail({
                        appId: app,
                        userId,
                        teamId,
                    });
                }
            } else {
                return;
            }
            if (isAINoticeSession(session)) {
                if (!chatState.filterTabList.length) {
                    console.log('session.ext=>', session.ext);
                    const { filterTabList = [] } = session.ext ? JSON.parse(session.ext) : {};
                    console.log(JSON.parse(JSON.stringify(filterTabList)), 'sssss');
                    yield executePut(put, 'updateFilterTabList', {
                        filterTabList: [...filterTabList],
                    });
                }
                session.timestamp = computeAITimestamp(aiNoticeSessionList);
            }

            if (top && index > 0) {
                // 如果需要置定并且存在当前的会话列表 列表进行重新排序
                allSessionList[index].timestamp = Date.now();
                yield executePut(put, 'reSortAllSessionList', {
                    allSessionList: allSessionList,
                });
            }
        },
        *launchAINoticeTabSession({ payload: { pin, sessionId, top } }: any, { put, select }: any) {
            if (!pin) {
                return;
            }
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const { selectedAITab } = chatState;
            if (pin === selectedAITab.pin) {
                return;
            }
            yield executePut(put, 'initSelectedAITab', { selectedAITab: { pin, sessionId } });
        },
        *launchSingleSession(
            { payload: { user, top, type } }: { payload: { user: UID; top?: boolean; type: any } },
            { put, select }: any
        ) {
            console.log('launchSingleSession');
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            console.log('launchSingleSession2', chatState);
            const { selectedSession, currentEmployee } = chatState;
            if (selectedSession.isSingle && !selectedSession.isNotice) {
                const info = selectedSession.info as Employee;
                console.log('launchSingleSession3', info);
                // 和同一个人聊天 普通聊天和密聊切换会话，不走这里
                if (
                    isEqualUid(employeeToUid(info), user) &&
                    type === SessionType.SINGLE &&
                    !selectedSession.isSecret
                ) {
                    console.log('launchSingleSession4');
                    yield executePut(put, 'initSelectedSession', {
                        selectedSession: selectedSession,
                    });
                    return;
                }
            }
            const imService = ImService.getInstance();
            const allSessionList = chatState.allSessionList || [];
            let index = -1;
            // tj: 保证大于一屏可以自动排序
            if (allSessionList?.length > 0) {
                const sid = imService.generateSingleSessionId(currentEmployee, user, type);
                index = getSessionIndex(allSessionList, { sessionId: sid });
                console.log('launchSingleSession5', index);
            }

            let session = allSessionList[index];
            console.log('---lyj---launchSingleSession111', allSessionList, index, session);
            if (!session) {
                console.log('创建单聊', session);
                // 创建单聊session
                session = yield imService.createSingleSession(user, type);
                console.log('创建单聊', session);
                // 解决[bug] 单聊重复 --hujun
                const isExistence = allSessionList.some((item: { id: any }) => {
                    if (session && item.id === session.id) {
                        return true;
                    }
                    return false;
                });
                console.log('---lyj---launchSingleSession222', isExistence);

                if (session && !isInValidSessionInfo(session) && !isExistence) {
                    allSessionList.push(session);
                }
                console.log('---lyj---launchSingleSession333', allSessionList);

                // 解决[bug] 单聊重复 --end
                if (!isExistence) {
                    yield executePut(put, 'updateSessionList', { allSessionList });
                }
            }

            if (!session || isInValidSessionInfo(session || {})) {
                return;
            }
            // 清空状态
            session.unreadCount = 0;
            session.mentions = {};
            session.lastReadMid = session.lastMid || 0;
            console.log('---lyj---launchSingleSession444', session);
            Bus.emit(`chat:session-update:${session.sessionId}`, session);
            yield executePut(put, 'initSelectedSession', { selectedSession: session });
            yield sleep(20);
            yield executePut(put, 'loadSessionMembers', { selectedSession: session });
            console.log('拉起单聊', allSessionList, session);
            if (index > 0 && top) {
                console.log('index > 0 && top', top, index);
                // 如果需要置定并且存在当前的会话列表 列表进行重新排序
                allSessionList[index].timestamp = Date.now();
                yield executePut(put, 'reSortAllSessionList', {
                    allSessionList: allSessionList,
                });
            }
        },

        *updateSessionMessageMid(
            { payload: { sessionId, sendResult } }: any,
            { put, select }: any
        ) {
            console.log('updateSessionMessageMid', sendResult);
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const sessionMessageMap = chatState.sessionMessageMap;
            const sessionMessage = sessionMessageMap[sessionId] || [];
            const messageBody = sendResult.body;
            const msgIndex = findLastIndex(
                sessionMessage,
                (item: ChatMessage) => item.id === messageBody.id
            );
            if (msgIndex > -1) {
                Object.assign(messageBody, lodashPick(sendResult, ['mid', 'timestamp']));
                sessionMessage[msgIndex] = messageBody;
                sessionMessageMap[sessionId] = sessionMessage;
                // console.log('updateSessionMessageMid', sessionMessageMap);
                yield executePut(put, 'updateSessionMessage', {
                    sessionMessageMap,
                });
                // yield executePut(put, 'updateSessionMessagesTimestamp', {
                //     timestamp: new Date().getTime(),
                // });
            }
        },

        *upsertSession({ payload }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            if (!chatState.loadAllSessionListEnd) {
                return;
            }
            let { session } = payload;
            // 过滤不需要的会话更新
            if (isUnnecessarySession(session)) {
                return;
            }
            if (session && !session.timestamp) {
                session.timestamp = Date.now();
            }
            let allSessionList = chatState.allSessionList || [];
            const selectedSession = chatState.selectedSession || {};
            const selectedSessionId = selectedSession.sessionId;
            // 更新session时。如果没有时间戳 或者名称 或者头像的
            if (isInValidSessionInfo(session)) {
                const imService = ImService.getInstance();
                session = yield imService.reloadSession(session);
                if (isInValidSessionInfo(session)) {
                    return;
                }
            }
            // console.log('---lyj---upsertSession111', allSessionList, session);
            session = lodashPick(session, sessionAttrs);
            // 更新会话
            Bus.emit(`chat:session-update:${session.sessionId}`, session);
            if (selectedSessionId === session.sessionId) {
                yield executePut(put, 'updateSelectedSession', { selectedSession: session });
            }
            const index = getSessionIndex(allSessionList, session);
            if (index >= 0) {
                // 低效
                const oldTimer = allSessionList[index].timestamp;
                const newTimer = session.timestamp;
                allSessionList[index] = {
                    ...allSessionList[index],
                    ...session,
                    timestamp: Math.max(oldTimer, newTimer),
                };
                // todo 暂时处理，不清楚之前的逻辑考虑
                if (session.lastMsg === undefined) {
                    allSessionList[index].lastMsg = undefined;
                }
                // allSessionList.splice(index, 1, { ...allSessionList[index], ...session });
                sortSessions(allSessionList);
                sendGroupSessionNotify(allSessionList);
            } else {
                allSessionList.push(session);
            }
            if (
                session.isNotice &&
                (isAINoticeChildSession(session) || isAINoticeSession(session))
            ) {
                const aiNoticeSessionList = allSessionList.filter(
                    (session: Session) => session.isNotice && isAINoticeChildSession(session)
                );
                yield executePut(put, 'updateAISessionList', {
                    aiNoticeSessionList,
                });
                if (isAINoticeSession(session)) {
                    allSessionList.find((item: Session) =>
                        isAINoticeSession(item)
                    ).timestamp = computeAITimestamp(aiNoticeSessionList);
                    // session.timestamp =

                    sortSessions(allSessionList);
                }
            }
            // console.log('---lyj---upsertSession222', allSessionList, session);
            yield executePut(put, 'updateSessionList', { allSessionList });
            // 触发更新未读数量功能；
            yield executePut(put, 'updateCount', {});
            // 发送通知（未读个数）消息
        },

        *upsertSessionBatch({ payload }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            if (!chatState.loadAllSessionListEnd) {
                return;
            }
            let { sessions } = payload;
            let allSessionList = chatState.allSessionList || [];
            let aiNoticeSessionList = chatState.aiNoticeSessionList || [];
            for (let session of sessions) {
                // 过滤不需要的会话更新
                if (isUnnecessarySession(session)) {
                    continue;
                }
                if (session && !session.timestamp) {
                    session.timestamp = Date.now();
                }
                // 更新session时。如果没有时间戳 或者名称 或者头像的
                if (isInValidSessionInfo(session)) {
                    const imService = ImService.getInstance();
                    session = yield imService.reloadSession(session);
                    if (isInValidSessionInfo(session)) {
                        continue;
                    }
                }
                session = lodashPick(session, sessionAttrs);
                // 更新会话
                Bus.emit(`chat:session-update:${session.sessionId}`, session);

                const index = getSessionIndex(allSessionList, session);
                // logger.debug('upsertSession ===>', session, index);
                if (index >= 0) {
                    // 低效
                    const oldTimer = allSessionList[index].timestamp;
                    const newTimer = session.timestamp;
                    allSessionList[index] = {
                        ...allSessionList[index],
                        ...session,
                        timestamp: Math.max(oldTimer, newTimer),
                    };
                    // todo 暂时处理，不清楚之前的逻辑考虑
                    if (session.lastMsg === undefined) {
                        allSessionList[index].lastMsg = undefined;
                    }
                    sendGroupSessionNotify(allSessionList);
                } else {
                    allSessionList.push(session);
                }
                if (session.isNotice && isAINoticeChildSession(session)) {
                    const aiSessionIndex = getSessionIndex(aiNoticeSessionList, session);
                    if (aiSessionIndex > -1) {
                        aiNoticeSessionList[aiSessionIndex] = {
                            ...aiNoticeSessionList[aiSessionIndex],
                            ...session,
                        };
                    } else {
                        aiNoticeSessionList.unshift(session);
                    }
                    const aiSession =
                        allSessionList.find((item: Session) => isAINoticeSession(item)) || {};
                    aiSession.timestamp = computeAITimestamp(aiNoticeSessionList);
                }
            }
            sortSessions(allSessionList);
            yield executePut(put, 'updateAISessionList', {
                aiNoticeSessionList,
            });
            yield executePut(put, 'updateSessionList', { allSessionList });
            yield executePut(put, 'updateCount', {});
        },

        *updateChatUser({ payload }: any, { put, select }: any) {
            const { user } = payload;
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const allSessionList = chatState.allSessionList || [];
            const sessionMessageMap = chatState.sessionMessageMap || {};
            const selectedSession = chatState.selectedSession;

            const selectedSessionId = selectedSession.sessionId;
            const idx = findLastIndex(allSessionList, (item: any) => {
                const info = item.info as Employee;
                if (info && isEqualEmployee(info, user)) {
                    return true;
                }
            });
            if (idx > -1) {
                allSessionList[idx].info = user;
                const item = allSessionList[idx];
                if (selectedSessionId === item.sessionId) {
                    selectedSession.info = user;
                    yield executePut(put, 'updateSelectedSession', { selectedSession });
                }
                if (sessionMessageMap[item.sessionId]?.length) {
                    sessionMessageMap[item.sessionId] = sessionMessageMap[item.sessionId].map(
                        (msg: any) => {
                            const sender = msg.sender as Employee;
                            if (sender && isEqualEmployee(sender, user)) {
                                msg.sender = user;
                            }
                            return msg;
                        }
                    );
                }
                yield executePut(put, 'updateSessionList', { allSessionList });
            }
            if (selectedSession?.isGroup) {
                if (sessionMessageMap[selectedSessionId]?.length) {
                    sessionMessageMap[selectedSessionId] = sessionMessageMap[selectedSessionId].map(
                        (msg: any) => {
                            const sender = msg.sender as Employee;
                            if (sender && isEqualEmployee(sender, user)) {
                                msg.sender = user;
                            }
                            return msg;
                        }
                    );
                }
            }
            yield executePut(put, 'updateSessionMessage', { sessionMessageMap });
        },

        *addSession({ payload }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            //    新群解散之后无法显示在会话列表中
            // if (!chatState.loadAllSessionListEnd) {
            //     return;
            // }
            let { session } = payload;
            if (session && !session.timestamp) {
                session.timestamp = Date.now();
            }
            let { allSessionList = [] } = chatState;
            // 更新session时。如果没有时间戳 或者名称 或者头像的
            if (isInValidSessionInfo(session)) {
                const imService = ImService.getInstance();
                session = yield imService.reloadSession(session);
                if (isInValidSessionInfo(session)) {
                    return;
                }
            }
            session = lodashPick(session, sessionAttrs);
            // console.log('---lyj---addSession111', allSessionList, session);
            // allSessionList = allSessionList || [];
            if (allSessionList.length === 0) {
                allSessionList.unshift(session);
            } else {
                // 进行重新排序
                session.timestamp = Date.now();
                allSessionList = addOneSession(allSessionList, session);
            }
            if (session.isGroup) {
                sendGroupSessionNotify(allSessionList);
            }
            // console.log('---lyj---addSession222', allSessionList, session);
            yield executePut(put, 'updateSessionList', { allSessionList });
            // 触发更新未读数量功能；
            yield executePut(put, 'updateCount', {});
        },

        *viewCardReadBatch({ payload }: any, { put, select }: any) {
            const chatState = yield select(({ chat }: { chat: ChatState }) => chat);
            const { selectedSession = {} } = chatState;
            // console.log('进入会话', selectedSession);
            if (isAINoticeSession(selectedSession)) {
                const filterMessgaes = payload.messages.filter(
                    (message: any) =>
                        isNoticeMessage(message) &&
                        !isSysMessage(message) &&
                        message?.extend.bizId &&
                        message?.extend.readRecord === true
                );
                // console.log('过滤', filterMessgaes);
                if (filterMessgaes?.length > 0) {
                    addBatchViewCardRead(filterMessgaes).then();
                }
            }
        },
    },

    subscriptions: {
        'session-event'({ dispatch }: any, onError: (reason?: any) => void) {
            Bus.on('chat:session-update', (session) => {
                // console.log('chat:session-update', session);
                dispatch({ type: 'upsertSession', payload: { session, type: 'update' } });
            });
            Bus.on('chat:session-update-batch', (sessions) => {
                dispatch({ type: 'upsertSessionBatch', payload: { sessions, type: 'update' } });
            });
            Bus.on('chat:session-add', (session) => {
                // logger.debug('chat:session-add', session);
                dispatch({ type: 'addSession', payload: { session } });
            });
            Bus.on('chat:session-del', (session) => {
                Bus.emit(`chat:session-remove:${session.sessionId}`, session.sessionId);
                dispatch({ type: 'removeSession', payload: { sessionId: session.sessionId } });
                dispatch({ type: 'updateCount', payload: {} });
            });
            Bus.on('chat:session-list', (sessionList) => {
                // 更新sessionList
                dispatch({ type: 'updateSessionList', payload: { allSessionList: sessionList } });
            });
            Bus.on('chat:state', (state) => {
                Bus.emit('chat:connect_state_change', { connectState: state });
                dispatch({ type: 'updateConnectState', payload: { connectState: state } });
            });
            Bus.on('chat:top-search-group-session', (data) => {
                dispatch({ type: 'launchGroupSession', payload: { sid: data.id } });
            });
            Bus.on('chat:top-search-single-session', (data) => {
                dispatch({ type: 'launchSingleSession', payload: { user: data } });
            });
            Bus.on('employee:user-update', (user) => {
                dispatch({ type: 'updateChatUser', payload: { user } });
            });
            Bus.on('chat:message-del', (messages) => {
                dispatch({ type: 'destroySessionMessage', payload: { messages } });
            });
            Bus.on('chat:card-read-batch', (messages) => {
                // console.log('模糊已读', messages);
                dispatch({ type: 'viewCardReadBatch', payload: { messages } });
            });
        },
    },
};
