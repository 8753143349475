import React, { useEffect } from 'react';
import Quill, { RangeStatic, Sources } from 'quill';
import MentionBlot from './Modules/Mention';
import 'quill/dist/quill.core.css';
import './index.less';
import { EventEmitter } from 'events';
import { IRect } from '@/types/chat/Base';
import CustomClipboard from './Modules/clipboard';
import Emoji from './Modules/emoji';
import { formatAtName } from '@/utils/chat/index';
const Delta = Quill.import('delta');
interface IProps {
    content?: string;
    contentChange?: (delta: any, oldDelta: any, source: Sources) => void;
    upload?: (files: any[], range: any) => void;
    onEditorKeyDown?: any;
    placeholder?: string;
    disableEnter?: boolean;
    maxLength?: number;
    readOnly?: boolean;
}

interface IState {
    atStartIndex: number;
    atEndIndex: number;
}

Quill.register({
    'formats/mention-link': MentionBlot,
    'formats/custom-emoji': Emoji,
});
Quill.register('modules/clipboard', CustomClipboard, true);

export const Events = new EventEmitter();
class JEditor extends React.Component<IProps, IState> {
    quill: Quill | null = null;
    atActive = false;
    disableEnter = false;
    events: EventEmitter;
    isCompositionMode = false;

    constructor(props: IProps) {
        super(props);
        this.events = Events;
        this.state = {
            atStartIndex: 0,
            atEndIndex: 0,
        };
        this.disableEnter = props.disableEnter || false;
    }

    getQuill = () => {
        return this.quill;
    };
    getText(index?: number, length?: number) {
        if (!this.quill) {
            return '';
        }
        return this.quill.getText(index, length);
    }

    clearMessage = () => {
        if (!this.quill) {
            return '';
        }
        this.quill.setContents(new Delta());
    };

    /**
     * 获取文本消息，包括@ 人的数据
     */
    getTextMessage = () => {
        if (!this.quill) {
            return '';
        }
        const delta = this.quill.getContents();
        let text = '';
        const userIds: string[] = [];
        delta.forEach((op) => {
            if (!op.insert) {
                return;
            }
            switch (typeof op.insert) {
                case 'string':
                    text += op.insert;
                    break;
                case 'object':
                    // eslint-disable-next-line no-case-declarations
                    const obj: any = op.insert;
                    if (obj['mention-link']) {
                        const value = obj['mention-link'];
                        // text += '@' + value.name; custom-emoji
                        text += formatAtName(value.name);
                        userIds.push(value.id);
                    }
                    if (obj['custom-emoji']) {
                        const value = obj['custom-emoji'];
                        text += value;
                    }
                    break;
                default:
                    break;
            }
        });
        if (text[text.length - 1] === '\n') {
            text = text.slice(0, -1);
        }
        return { text, userIds, delta };
    };

    public changeDisableEnter = (flag: boolean) => {
        this.disableEnter = flag;
    };

    public insertText = (str: string, source: any = 'user') => {
        if (!this.quill) {
            return;
        }
        this.quill.focus();
        if (!this.quill) {
            return;
        }
        const range = this.quill.getSelection();
        if (!range) {
            return;
        }
        this.quill.insertText(range.index, str, source);
        this.quill.setSelection(range.index + str.length, 0);
    };

    public changeAtActive = (flag: boolean) => {
        this.atActive = flag;
    };

    public changePlaceholder = (placeholder: string) => {
        if (!this.quill) {
            return;
        }
        this.quill.root.setAttribute('data-placeholder', placeholder);
    };

    // 获取选择文本的位置维度信息， 如果是多条表明选择了多行
    public getSelectionRects(range?: RangeStatic): IRect[] {
        const quill = this.getQuill();
        const results: IRect[] = [];
        // if (!quill || !quill.container) {
        //     return results;
        // }
        // if (!range) {
        //     const r = quill.getSelection();
        //     if (!r) {
        //         return results;
        //     }
        //     // eslint-disable-next-line no-param-reassign
        //     range = r;
        // }
        // const containerRect = quill.container.getBoundingClientRect();
        // const startLeaf = quill.getLeaf(range.index);
        // const endLeaf = quill.getLeaf(range.index + range.length);
        // const dRange = document.createRange();
        // dRange.setStart(startLeaf[0].domNode, startLeaf[1]);
        // dRange.setEnd(endLeaf[0].domNode, endLeaf[1]);
        // const rects = rangefix.getClientRects(dRange);
        // const l = rects.length;
        // if (!l) {
        //     return results;
        // }
        // for (let i = 0; i < l; i++) {
        //     const item = rects[i];
        //     results.push({
        //         top: item.top - containerRect.top,
        //         left: item.left - containerRect.left,
        //         width: item.width,
        //         height: item.height,
        //     });
        // }
        results.push({
            top: 0,
            left: 0,
            width: 0,
            height: 0,
        });
        return results;
    }

    /**
     * 处理是否拦截回车默认事件
     */
    handleEnterDefault = () => {
        if (this.disableEnter) {
            return false;
        }
        return true;
    };

    getModule(name: string): any {
        if (!this.quill) {
            return null;
        }
        return this.quill.getModule(name);
    }

    componentDidMount() {
        this.initQuill();
    }

    componentWillUnmount() {
        this.removeEventBind();
    }

    initQuill() {
        const options = {
            // debug: 'info',
            modules: {
                toolbar: null,
                keyboard: {
                    bindings: {
                        enter: {
                            key: 13,
                            handler: this.handleEnterDefault,
                        },
                    },
                },
                clipboard: {
                    upload: (range: any, files: any) => {
                        //   uploader(this.quill, this.props.page.id, range, files);
                        if (this.props.upload) {
                            this.props.upload(files, range);
                        }
                    },
                    matchVisual: false,
                },
            },
            formats: [
                'background',
                'bold',
                'color',
                'font',
                'code',
                'italic',
                'link',
                'size',
                'strike',
                'script',
                'underline',
                'blockquote',
                'header',
                'indent',
                // 'list', // <-- commented-out to suppress auto bullets
                'align',
                'direction',
                'code-block',
                'formula',
                'image',
                'video',
                'mention-link',
                'custom-emoji',
            ],
            placeholder: this.props.placeholder || '',
            readOnly: this.props.readOnly,
            theme: 'snow',
            scrollingContainer: '#jeditor',
        };
        const editor = new Quill('#jeditor', options);
        this.quill = editor;
        this.addEventBind();
        this.addClipboard();
        const aa: any = window;
        aa.quill = editor;
    }

    addClipboard = () => {
        if (!this.quill) {
            return null;
        }
        // AddClipboard(this.quill);
    };

    onTextChange = (delta: any, oldDelta: any, source: Sources) => {
        if (this.props.contentChange) {
            this.props.contentChange(delta, oldDelta, source);
        }
        if (source !== 'user') {
            return;
        }
        // 限制输入长度
        if (this.props.maxLength) {
            const { maxLength } = this.props;
            const length = this.quill?.getLength();

            if (length && length > maxLength + 1) {
                const delta = new Delta().retain(maxLength).delete(length - maxLength - 1);
                this.quill?.updateContents(delta, 'api');
            }
        }
    };

    onSelectionChange = (
        range: { index: number; length: Number },
        oldRange: { index: Number; length: Number },
        source: String
    ) => {
        if (!range) {
            return;
        }
        if (this.events) {
            this.events.emit('editor-selection-change', range, source);
        }
    };

    onInputBlur = () => {
        if (this.events) {
            this.events.emit('editor-on-focus', false);
        }
    };

    onInputFocus = () => {
        if (this.events) {
            this.events.emit('editor-on-focus', true);
        }
        // const range = this.getSelection();
        // setTimeout(() => this.quill?.setSelection(range?.index || 0, 0), 0);
    };

    getSelection = () => {
        if (!this.quill) {
            return null;
        }
        const range = this.quill.getSelection();
        return range;
    };

    focus = () => {
        if (!this.quill) {
            return null;
        }
        this.quill.focus();
    };

    addEventBind() {
        if (!this.quill) {
            return;
        }
        this.quill.on('text-change', this.onTextChange);
        this.quill.on('selection-change', this.onSelectionChange);
        this.quill.root.addEventListener('blur', this.onInputBlur);
        this.quill.root.addEventListener('focus', this.onInputFocus);
    }

    removeEventBind() {
        if (!this.quill) {
            return;
        }
        this.quill.off('text-change', this.onTextChange);
        this.quill.off('selection-change', this.onSelectionChange);
        this.quill.root.removeEventListener('blur', this.onInputBlur);
        this.quill.root.removeEventListener('focus', this.onInputFocus);
    }

    onEditorKeyDown = (e: React.KeyboardEvent) => {
        // log.debug('editor key down', e.keyCode);
        if (this.events) {
            this.events.emit('editor-keyDown', e);
        }

        if (this.props.onEditorKeyDown) {
            this.props.onEditorKeyDown(e);
        }

        // 如果是中文模式，拦截一下回车，不发送消息
        if (this.isCompositionMode && e.key === 'Enter') {
            e.stopPropagation();
        }
    };

    onCompositionStart = (e: any) => {
        this.isCompositionMode = true;
    };
    onCompositionEnd = (e: any) => {
        this.isCompositionMode = false;
    };
    render() {
        return (
            <div
                className="jeditor"
                id="jeditor"
                onKeyDown={this.onEditorKeyDown}
                onCompositionStart={this.onCompositionStart}
                onCompositionEnd={this.onCompositionEnd}
            />
        );
    }
}

export default JEditor;
