import ImService from '@/server/ImService';
import logger from '@/utils/logger';
export interface TeamUserInfo {
    displayDeptName: string;
    account: string;
    ddAppId: string;
    headImg: string | null;
    mobile: string;
    realName: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
}

export interface UserModel {
    refreshToken: string;
    refreshTokenExpireIn: number;
    accessToken: string;
    accessTokenExpireIn: number;
    account: string;
    ddAppId: string;
    headImg: string | null;
    nickName: string | null;
    realName: string;
    userId: string;
    selectedTeamId: string;
    teamUserInfoList: TeamUserInfo[];
    teamUserInfo: TeamUserInfo;
    team: TeamUserInfo;
}
// eslint-disable-next-line max-params
export function getDeptPositionDesc(
    departName: string,
    positionName: string,
    userId?: string,
    currentEmployee?: any
) {
    let desc;
    let e_dept_full_name = departName;
    let e_position = positionName;
    // 唤起联系人选择器不显示当前账户自己的部门和岗位信息
    if (userId && currentEmployee && userId === currentEmployee.userId) {
        e_dept_full_name = currentEmployee.e_dept_full_name || '';
        e_position = currentEmployee.e_position || '';
    }

    if (e_dept_full_name?.includes('-')) {
        desc = e_dept_full_name.split('-').pop() + '-' + e_position;
    } else if (e_dept_full_name && !e_dept_full_name?.includes('-') && e_position) {
        desc = e_dept_full_name + '-' + e_position;
    } else if (!e_dept_full_name && e_position) {
        desc = e_position;
    } else if (e_dept_full_name && !e_position) {
        desc = e_dept_full_name + '-暂无岗位';
    } else {
        desc = '暂未填写';
    }
    return desc;
}

export function isSelf(self: any, other: Partial<UserModel>) {
    return (
        self.userId === other.userId &&
        self.ddAppId === other.ddAppId &&
        self?.teamUserInfo?.teamId === other.selectedTeamId
    );
}

export interface AppModel {
    appId: string;
    deviceId: string;
    client:
        | 'MAC'
        | 'WINDOWS'
        | 'LINUX'
        | 'AIX'
        | 'ANDROID'
        | 'FREEBSD'
        | 'OPENBSD'
        | 'SUNOS'
        | 'CYGWIN'
        | 'NETBSD';
}
// 用户 该结构的用户信息用于网络请求
export interface UserInfo {
    app: string;
    teamId: string;
    userId: string;
    tenantCode?: string;
    account?: string;
    imageUrl?: string;
    realName?: string;
    ddAppId?: string;
    userType?: string;
    accessToken?: string;
    appId?: string;
    deviceId?: string;
    client?: string;
}

export interface UserState {
    isLogin: boolean;
    userData: {
        user: UserModel;
        app: AppModel;
    };
    userInfo: UserInfo;
}

export default {
    namespace: 'user',
    state: {
        isLogin: false,
        userData: {},
        userInfo: null,
    },
    reducers: {
        setUserData(state: UserState, { payload: userData }: { payload: UserModel }) {
            logger.debug('setUserData,', userData);
            return { ...state, userData, isLogin: true };
        },
        setUserInfo(state: UserState, { payload }: { payload: any }) {
            return {
                ...state,
                userInfo: {
                    teamId: payload?.teamUserInfo?.teamId,
                    userId: payload?.userId,
                },
            };
        },
    },
    effects: {
        *getWebUserData({ payload }: any, { call, put }: any) {
            const result = payload.userData;
            yield put({
                type: 'setUserData',
                payload: {
                    user: {
                        ...result,
                        realName: result.teamUserInfo.realName,
                        team: result.teamUserInfo,
                        teamUserInfoList: [result.teamUserInfo],
                        selectedTeamId: result.teamUserInfo.teamId,
                    },
                },
            });
        },
    },
};
