import React from 'react';
import ListEmptyPlaceholder from '@jd/focus-desktop-comps/lib/es/ListEmptyPlaceholder';
import { ListItemClickFn } from '@/components/Modals/SearchGlobalModal/Tabs';
import { SearchGlobalContext } from '@/components/Modals/SearchGlobalModal/utils';
import DataList from '@/components/Modals/SearchGlobalModal/DataList';
import lodashTrim from 'lodash/trim';
import { Empty } from 'antd';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { SearchType } from '@/services/SearchService';
import { analysisLog } from '@/utils/logAnalytics';

export default function TabPaneDepartment({ t, onClick }: { t: any; onClick?: ListItemClickFn }) {
    const { keyword } = React.useContext(SearchGlobalContext);
    if (!lodashTrim(keyword)) {
        return <ListEmptyPlaceholder placeholder={t('search.emptyDepartment')} />;
    }

    return (
        <div>
            <DataList
                searchType={SearchType.Department}
                onClick={(data: ListItemData) => {
                    analysisLog('xtbg_home_search_1641454991771', '27');
                    if (onClick) {
                        onClick(SearchType.Department, data);
                    }
                }}
            />
        </div>
    );
}
