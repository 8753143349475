export default {
    status: [
        {
            id: '1',
            color: '#5EA7F8',
            bgColor: '#DAE9F9',
            text: '正常',
        },
        {
            id: '4',
            color: '#F3AC56',
            bgColor: '#FDEFDE',
            text: '风险',
        },
        {
            id: '3',
            color: '#EB5B56',
            bgColor: '#FBDFDE',
            text: '延期',
        },
        {
            id: '2',
            color: '#8899A4',
            bgColor: '#E8EBED',
            text: '暂停',
        },
        {
            id: '5',
            color: '#8F959E',
            bgColor: '#E8EBED',
            text: '已归档',
        },
    ],
    canEditStatus: [
        {
            id: '1',
            color: '#5EA7F8',
            bgColor: '#DAE9F9',
            text: '正常',
        },
        {
            id: '4',
            color: '#F3AC56',
            bgColor: '#FDEFDE',
            text: '风险',
        },
        {
            id: '3',
            color: '#EB5B56',
            bgColor: '#FBDFDE',
            text: '延期',
        },
        {
            id: '2',
            color: '#8899A4',
            bgColor: '#E8EBED',
            text: '暂停',
        },
    ],
    statusColors: ['', '#59D661', '#8899A4', '#EB5B56', '#FFB416', '#8F959E'],
    allStatus: ['', '正常', '暂停', '延期', '风险', '已归档'],
};
