import React from 'react';
import ReactDOM from 'react-dom';
import { Icons } from '../../../Icon';
import { IColumnRenderProps } from '../Column';
import { lang } from '@/components/Joyspace/context';
import { Button, Dropdown, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
// import { AuthData } from '@/types/LocalConfig';
// import { GlobalContextConfig } from '@/context/GlobalContext';
import './index.less';
import { SyncStatusEunm } from '@/components/Joyspace/controllers/JoyspaceController';
import IconFont from '@/components/icon';

export interface IProps<T> {
    callbackProps: IColumnRenderProps<T>;
    render: IContextRender<T>;
}

export type ContextCallbackProps<T> = IColumnRenderProps<T>;

export type IContextRender<T> = (option: Option, callbackProps: IColumnRenderProps<T>) => void;

export type Option = (props: {
    children: React.ReactElement | string;
    callbackOrModal?: (() => any) | JSX.Element;
    type?: string;
    isAllow?: boolean;
    splitCode?: number;
    icon?: string;
    tip?: string;
}) => void;

export default function <T>(props: IProps<T>) {
    const { data } = props.callbackProps;
    const [status, setStatus] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const dropdown = React.useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = React.useState(false);
    // console.log(disabled, 'statusstatusstatusstatusstatus', data);
    React.useEffect(() => {
        const destroy = () => document.removeEventListener('mouseenter', close, false);
        const close = () => {
            destroy();
            setStatus(false);
        };
        document.addEventListener('mouseleave', close, false);
        return destroy;
    }, []);

    React.useEffect(() => {
        const menu = dropdown.current;
        if (menu) {
            const top = getElementTop(menu);
            const height = menu.offsetHeight;
            if (top + height > getClientHeight()) {
                menu.style.top = -height + 'px';
            }
        }
    }, [status]);
    const handleVisibleChange = (visible: boolean) => {
        // if (disabled) {
        //     return;
        // }
        setVisible(visible);
    };
    const create = () => {
        const { callbackProps, render } = props;
        // console.log(props, 'propspropspropsprops');
        const items: JSX.Element[] = [];
        let storeSplitCode = 1;
        const option: Option = ({
            children: name,
            callbackOrModal,
            isAllow = true,
            type,
            icon,
            splitCode,
            tip,
        }) => {
            // let _disabled = false;
            // // 在断网时，只有离线新建的文档才删除按钮功能，其他所有的按钮操作都要禁止。
            // if (disabled) {
            //     if ((data as any).isNew) {
            //         _disabled = type !== 'delete';
            //     } else {
            //         _disabled = true;
            //     }
            //     if (type === 'edit') {
            //         _disabled = false;
            //     }
            // } else {
            //     // 在有网时，离线新建的文档更多操作禁止
            //     if ((data as any).isNew) {
            //         _disabled = true;
            //     }
            // }
            if (splitCode && storeSplitCode < splitCode) {
                storeSplitCode = splitCode;
                items.push(<div className="joyspace-operate-split-line" />);
            }
            items.push(
                <MenuItem
                    key={items.length}
                    setVisible={setVisible}
                    disabled={
                        !isAllow ||
                        ((data as any).status === 0 && name !== lang('common.list-remove'))
                    }
                    icon={icon}
                    name={name}
                    tip={tip}
                    callbackOrModal={callbackOrModal}
                />
            );
        };
        // console.log(items, 'itemsitemsitemsitems==>');
        render(option, callbackProps);
        return items;
    };

    const content = <ul className="joyspace-operate-lists">{create()}</ul>;
    React.useMemo(() => {
        setDisabled(!navigator.onLine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.onLine]);
    return (
        <Popover
            trigger={['click']}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            content={content}
        >
            <span className="joyspace-trigger-icon">{Icons.trigger}</span>
        </Popover>
    );
}

function MenuItem(props: {
    disabled: boolean;
    callbackOrModal: (() => any) | React.ReactElement | undefined;
    name: string | React.ReactElement;
    icon?: string;
    tip?: string;
    setVisible: ((v: boolean) => any) | React.ReactElement | undefined;
}) {
    const { callbackOrModal, setVisible, disabled, icon, tip } = props;
    return (
        <li
            onClick={(e) => {
                if (disabled) return;
                e.stopPropagation();
                e.preventDefault();
                if (typeof callbackOrModal === 'function' && typeof setVisible === 'function') {
                    setVisible(false);
                    callbackOrModal();
                } else if (React.isValidElement(callbackOrModal)) {
                    ReactDOM.render(callbackOrModal, document.createDocumentFragment());
                }
            }}
        >
            <Button type="text" disabled={disabled}>
                {icon && <IconFont type={`icon${icon}`} className="joyspace-operate-icon" />}
                {props.name}
            </Button>
            {tip && (
                <Tooltip placement="top" title={tip} className="joyspace-operate-tip">
                    <QuestionCircleOutlined className="tip" />
                </Tooltip>
            )}
        </li>
    );
}

function getClientHeight() {
    return document.documentElement.clientHeight ?? document.body.clientHeight;
}

function getElementTop(element: HTMLElement) {
    let parent: HTMLElement = element;
    let total = 0;
    do {
        total += parent.offsetTop;
        parent = parent.offsetParent as HTMLElement;
    } while (parent);
    return total;
}
