import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './index.less';
import CreateTeam from './CreateTeam';
import JoinOtherTeam from './JoinOtherteam';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { IFocusSDK } from '@/utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getTranslationName } from '@/utils/i18n';
import { changeEgovUrl } from '@/utils/tools';
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
const createTeamImg = `${REACT_APP_OSS_BASE_URL}/statics/images/create_team.png`;
const joinTeamImg = `${REACT_APP_OSS_BASE_URL}/statics/images/join_team.png`;

enum JoinPath {
    Init,
    JoinOtherTeam,
    CreateTeam,
}

function EntryItem({
    title,
    desc,
    btnStr,
    styles,
    icon,
    className,
    clickHandle,
}: {
    styles: any;
    title: string;
    desc: string;
    btnStr: string;
    icon: any;
    className?: any;
    clickHandle: () => void;
}) {
    return (
        <div className="entry">
            <img
                src={changeEgovUrl(icon)}
                style={{
                    width: 154,
                    height: 170,
                    marginRight: 48,
                }}
            />
            <div>
                <p className="title" style={{ ...styles }}>
                    {title}
                </p>
                <p className="desc">{desc}</p>
                <div
                    className={`btn ${className}`}
                    style={{
                        height: 32,
                    }}
                    onClick={clickHandle}
                >
                    {btnStr}
                </div>
            </div>
        </div>
    );
}
export default function JoinMe({
    getWebUserData,
    userData,
    appRuntimeEnv,
    focusSDK,
}: {
    getWebUserData: any;
    userData: any;
    appRuntimeEnv: AppRuntimeEnv;
    focusSDK: IFocusSDK;
}) {
    const { t } = useTranslation('joinMe');
    const [joinPath, setJoinPath] = useState(JoinPath.Init);
    const joinOrCreateTeamI18NName = getTranslationName(appRuntimeEnv, 'joinOrCreate');
    const [title, setTitle] = useState(t(joinOrCreateTeamI18NName));
    const canCreateTeamConfig = appRuntimeEnv.getUIConfig('canCreateTeam');
    const canCreateTeam =
        typeof canCreateTeamConfig === 'undefined' || canCreateTeamConfig === true;
    const [valueCtr, setValueCtr] = useState('');
    useEffect(() => {
        if (joinPath === JoinPath.Init) {
            setTitle(t(joinOrCreateTeamI18NName));
        } else if (joinPath === JoinPath.CreateTeam) {
            setTitle(t('createTeam'));
        } else {
            setTitle(t('inputInviteCode'));
        }
    }, [joinOrCreateTeamI18NName, joinPath, t]);
    let ele = null;
    if (joinPath === JoinPath.Init) {
        ele = (
            <div>
                {canCreateTeam && (
                    <EntryItem
                        styles={{}}
                        title={t('createNewTeam')}
                        desc={t('createTeamDesc').replace('%s', appRuntimeEnv.productName)}
                        btnStr={t('lijiCreate')}
                        className="normal small"
                        icon={createTeamImg}
                        clickHandle={() => setJoinPath(JoinPath.CreateTeam)}
                    />
                )}
                <EntryItem
                    styles={{}}
                    title={t('joinOtherTeam')}
                    desc={t('jointTeamDesc').replace('%s', appRuntimeEnv.productName)}
                    btnStr={t('joinTeam')}
                    className="box small"
                    icon={joinTeamImg}
                    clickHandle={() => setJoinPath(JoinPath.JoinOtherTeam)}
                />
            </div>
        );
    } else if (joinPath === JoinPath.CreateTeam) {
        ele = (
            <CreateTeam
                userData={userData}
                appRuntimeEnv={appRuntimeEnv}
                focusSDK={focusSDK}
                getWebUserData={getWebUserData}
                goBack={() => {}}
            />
        );
    } else {
        ele = (
            <JoinOtherTeam
                // userData={userData}
                // getWebUserData={getWebUserData}
                goBack={() => setJoinPath(JoinPath.Init)}
            />
        );
    }
    return (
        <div className="join-me">
            <div
                className="header"
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => setJoinPath(JoinPath.Init)}
            >
                {joinPath !== JoinPath.Init ? <LeftOutlined style={{ marginRight: 6 }} /> : null}
                {title}
            </div>
            <div className="content">{ele}</div>
        </div>
    );
}
