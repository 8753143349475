interface Chat_Contant {
    AI_PIN: string;
    ALL_PIN: string;
    OTHER_PIN: string;
    SCHEDULE_PIN: string;
    TASK_PIN: string;
    SYS_PIN: string;
    JOYSPACE_PIN: string;
    SUPERVISE_PIN: string;
    VOICE_PIN: string;
    MEETING_PIN: string;
    GROUP_PIN: string;
    ANNOUNCEMENT_PIN: string;
    GROUP_NOTICE_PIN: string;
    GROUP_SOLITAIRE_PIN: string;
}

export const CHAT_NOTICE: Chat_Contant = {
    // AI助手对应的PIN写死
    AI_PIN: 'aiRobot',
    ALL_PIN: 'all-notice',
    OTHER_PIN: 'other-notice',
    GROUP_PIN: 'group-notice',
    SCHEDULE_PIN: '~me69', // 日程
    TASK_PIN: 'eopen-push_PZzzvgDrpZVAefKImEfo', // 任务
    SYS_PIN: 'eopen-push_9IdJIcxdZmWRmXNLw3Qc', // 系统通知
    JOYSPACE_PIN: '~joyspace', // 云空间
    SUPERVISE_PIN: 'eopen-push_0Gl8b1I3si21n0VVNkrF', // 催办
    VOICE_PIN: 'eopen-push_qTJ02iME29yeA8JwYuU8', // 语音
    MEETING_PIN: 'eopen-push_jif5OZ0gf6qNotBVAraI', // 视频
    ANNOUNCEMENT_PIN: 'eopen-push_gc1EijDXzxUehGW1ydK5', // 专班公告
    GROUP_NOTICE_PIN: 'eopen-push_qhTSqJ4pYYjuK7ZlYXg5', // 组通知
    GROUP_SOLITAIRE_PIN: 'eopen-push_qhTSqJ4pYYjuK7ZlYXg6', // 组接龙
};

export const AI_NOTICE_EXCLUDE_PINS = [CHAT_NOTICE.ANNOUNCEMENT_PIN, CHAT_NOTICE.SYS_PIN];

export const ALL_NOTICE_TAB_LIST = [
    {
        pin: 'eopen-push_qTJ02iME29yeA8JwYuU8',
        name: '语音会议',
    },
    {
        pin: 'eopen-push_jif5OZ0gf6qNotBVAraI',
        name: '视频会议',
    },
    {
        pin: 'eopen-push_PZzzvgDrpZVAefKImEfo',
        name: '任务',
    },
    {
        pin: '~me69',
        name: '日程',
    },
    {
        pin: '~joyspace',
        name: '文档',
    },
    {
        pin: 'eopen-push_0Gl8b1I3si21n0VVNkrF',
        name: '催办',
    },
    {
        pin: 'eopen-push_qhTSqJ4pYYjuK7ZlYXg5',
        name: '组通知',
    },
    {
        pin: 'eopen-push_qhTSqJ4pYYjuK7ZlYXg6',
        name: '组接龙',
    },
    {
        pin: 'other-notice',
        name: '其他',
    },
];

// TAB消息控制器内不能展示的tab的pin集合
export const UNABLE_SHOW_TABS_PIN = [CHAT_NOTICE.ALL_PIN];

// TAB消息控制器内默认不可编辑的tab pin集合
export const UNABLE_EDIT_TABS_PIN: string[] = [];

// 其他项tab不包含的tab项pin
export const OTHER_EXCLUDE_TABS_PIN = [
    CHAT_NOTICE.SCHEDULE_PIN,
    CHAT_NOTICE.TASK_PIN,
    CHAT_NOTICE.JOYSPACE_PIN,
    CHAT_NOTICE.SUPERVISE_PIN,
    CHAT_NOTICE.VOICE_PIN,
    CHAT_NOTICE.MEETING_PIN,
    CHAT_NOTICE.ANNOUNCEMENT_PIN,
    CHAT_NOTICE.GROUP_NOTICE_PIN,
    CHAT_NOTICE.GROUP_SOLITAIRE_PIN,
];

export default CHAT_NOTICE;
