/**
 * 大搜消息展示
 * 说明：
 * 组件分为:SearchMessageHistoryListUI(列表组件) SearchMessageItemUI(列表项组件) SearchMessageHighlightUI(高亮组件) SearchMessageHeaderUI(列表头部组件) 四部分
 * loaing(加载组件) LoadingOutlined(无数据组件) Avatar(头像组件) LoadMore(加载更多组件)
 * 以上组件共用一个样式表
 * 该文件下的组件主要是渲染逻辑，数据逻辑不在此处理。
 */
import React from 'react';
import Loading from '@/components/Loading';
import { Empty } from 'antd';
import noContent from '@/assets/img/search-nodata.png';
import { LoadMore } from '@jd/focus-desktop-comps/lib/es/List';
import Avatar from '@/components/Joyspace/components/Avatar';
import './SearchMessageHistoryUI.less';
import ListEmptyPlaceholder from '@jd/focus-desktop-comps/lib/es/ListEmptyPlaceholder';
import IconFont from '@/components/icon';
import { userEscapeString } from '@/utils/chat/index';

interface HeaderIProps {
    isShowBackBtn: boolean;
    backFn: () => void;
    name?: string;
}
// 列表头部
const SearchMessageHeaderUI = (props: HeaderIProps) => {
    const { isShowBackBtn = false, name = '', backFn } = props;
    return (
        <div className="search-message-header">
            {/* <div style={{ width: 24 }} /> */}
            {!isShowBackBtn && <p className="search-message-header-text">聊天记录</p>}
            {isShowBackBtn && (
                <div
                    className="search-message-header-text"
                    onClick={() => {
                        backFn?.();
                    }}
                >
                    <span className="search-message-header-back">
                        <IconFont
                            className="search-message-header-back_icon"
                            type="iconapp_btn_nb_return"
                        />{' '}
                        返回
                    </span>
                    <span className="search-message-header-title" title={name}>
                        {name}的聊天记录
                    </span>
                </div>
            )}
        </div>
    );
};

interface HighlightIProps {
    keyWord: string;
    str: string;
}
// 高亮
const SearchMessageHighlightUI = (props: HighlightIProps) => {
    const { str = '', keyWord = '' } = props;
    const index = str.indexOf(keyWord);
    const beforeStr = str.substr(0, index);
    const afterStr = str.substr(index + keyWord.length);
    const highlight =
        index > -1 ? (
            <span className="search-message-ellipsis">
                {beforeStr}
                <span className="search-highlight">{keyWord}</span>
                {afterStr}
            </span>
        ) : (
            <span className="search-message-ellipsis">{str}</span>
        );
    return highlight;
};

interface Item {
    avatar: string;
    name: string;
    desc: string;
    mid: number;
    sessionId: string;
    isGroup: boolean;
    url: string;
    onChangeDate?: (list: Item[]) => void;
    onOpenMessage?: (item: Item) => void;
}

interface ItemProps {
    children: Item[];
    keyWord?: string;
    history: any;
}

// 列表项
const SearchMessageItemUI = (props: ItemProps & Item) => {
    const {
        avatar = '',
        name = '',
        desc = '',
        isGroup = false,
        keyWord = '',
        mid = -1,
        children = [],
        sessionId = '',
        url = '',
        onChangeDate,
        onOpenMessage,
    } = props;
    return (
        <div
            className="search-message-history-item"
            onClick={() => {
                if (!isGroup) {
                    onOpenMessage?.(props);
                    return;
                }
                // 修改数据
                onChangeDate?.(children);
            }}
        >
            <Avatar avatar={avatar} name={name} />
            <div className="search-message-history-item_info">
                <p
                    className="search-message-history-item_info_text"
                    dangerouslySetInnerHTML={{
                        __html: userEscapeString(name),
                    }}
                />
                <div>
                    {isGroup && (
                        <p className="search-message-ellipsis">{children.length}条相关聊天记录</p>
                    )}
                    {!isGroup && <SearchMessageHighlightUI str={desc} keyWord={keyWord} />}
                </div>
            </div>
        </div>
    );
};

interface IProps {
    list: any[];
    loading: boolean;
    loadMore: boolean;
    isShowBackBtn: boolean;
    keyWord?: string;
    backFn?: () => void;
    t: any;
    loadmoreFn?: () => void;
    onChangeDate?: (list: any) => void;
    onOpenMessage?: (item: Item) => void;
}
// 消息列表
const SearchMessageHistoryListUI = (props: IProps) => {
    const {
        list = [],
        loading = true,
        loadMore = false,
        isShowBackBtn = false,
        keyWord = '',
        onChangeDate,
        onOpenMessage,
        backFn,
        t,
        loadmoreFn,
    } = props;
    // console.log(history, 'historyhistoryhistory');
    return (
        <div className="search-message-history-list">
            {/* 加载 */}
            {!keyWord && loading && (
                <ListEmptyPlaceholder placeholder={t('search.emptyPlMessageHistory')} />
            )}
            {keyWord && loading && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '200px',
                    }}
                >
                    <Loading />
                </div>
            )}
            {/* 头部展示 */}
            {!loading && list.length > 0 && (
                <SearchMessageHeaderUI
                    backFn={() => {
                        backFn?.();
                    }}
                    name={list[0].name}
                    isShowBackBtn={isShowBackBtn}
                />
            )}
            {/* 数据 */}
            {!loading &&
                list.map((item, index) => (
                    <SearchMessageItemUI
                        key={index}
                        keyWord={keyWord}
                        {...item}
                        onOpenMessage={onOpenMessage}
                        onChangeDate={onChangeDate}
                    />
                ))}
            {!loading && list.length > 0 && (
                <div className="search-no-more">{t('list.noMore')}</div>
            )}
            {/* 暂无数据 */}
            {!loading && list.length === 0 && (
                <Empty image={noContent} description={t('search.nodata')} />
            )}
            {/* 加载更多 */}
            {loadMore && (
                <LoadMore
                    onClick={() => {
                        loadmoreFn?.();
                    }}
                >
                    {t('list.loadMore')}
                </LoadMore>
            )}
        </div>
    );
};

export { SearchMessageHistoryListUI, SearchMessageItemUI, SearchMessageHighlightUI };
