import PouchdbSDK from '@jd/pouchdb.sdk';

class DbService {
    db: any;
    userId: string;
    teamId: string;
    dbName: string;
    schemaName: string;
    /**
     * @param dbName 数据库名称
     * @param schemaName 表名，网页版不使用，pc端使用，pc端需要分表
     */
    // eslint-disable-next-line max-params
    constructor(dbName: string, schemaName: string, teamId: string, userId: string) {
        this.dbName = dbName;
        this.schemaName = schemaName;
        this.userId = userId;
        this.teamId = teamId;
        if (!this.db)
            this.db = new PouchdbSDK(
                `${this.teamId}_${this.userId}_${this.dbName}_${schemaName}`,
                'idb'
            );
    }

    // isDivided是否根据用户id分开
    getFromDB(id: string) {
        if (!this.db) {
            throw new Error('请先创建数据库');
        }
        return this.db.getFromDB(id);
    }
    // isDivided是否根据用户id分开
    saveToDB(data: any) {
        if (!this.db) {
            throw new Error('请先创建数据库');
        }
        let id: string = data._id;

        return new Promise((resolve, reject) => {
            this.getFromDB(id)
                .then((res: any) => {
                    this.deleteFromDB(id)
                        .then((res: any) => {
                            this.db.saveToDB(data);
                            resolve('更新成功');
                        })
                        .catch((e: any) => {
                            reject(e);
                        });
                })
                .catch((e: any) => {
                    this.db.saveToDB(data);
                    resolve('创建成功');
                });
        });
    }

    deleteFromDB(id: string) {
        if (!this.db) {
            throw new Error('请先创建数据库');
        }
        return this.db.deleteFromDB(id);
    }

    getAllDocsFromDB(params: any) {
        if (!this.db) {
            throw new Error('请先创建数据库');
        }
        return this.db.getAllDocsFromDB(params);
    }
}

export default DbService;
