import React, { useEffect } from 'react';
import './index.less';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import Bus from '@/utils/bus.ts';
import { Button } from 'antd';
import { ChatMessage } from '@/types/chat';

interface ForwardingProps {
    messages: any[];
    math: any;
}
interface DvaDispatchProps {
    changeSelectMore: Function;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}

type IProps = Readonly<ChatState & DvaDispatchProps & ForwardingProps>;

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { selectMore, messages } = chat as ChatState;
    return {
        selectMore,
        messages,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

function ForwardSelect(props: IProps) {
    const { messages, updateMessages, changeSelectMore } = props;
    const changeDom = () => {
        if (document) {
            let allMask = document.querySelectorAll('.ant-modal-mask');
            let allWrap = document.querySelectorAll('.ant-modal-wrap');
            let lastMask: any = allMask[allMask.length - 1];
            let lastWrap: any = allWrap[allWrap.length - 1];
            lastMask.style.zIndex = 1000;
            lastWrap.style.zIndex = 1000;
        }
    };
    const handleCancel = () => {
        changeDom();
        // 清除信息
        updateMessages({ messages: [] });
        changeSelectMore({ selectMore: false, type: null });
    };
    const handleOk = () => {
        changeDom();
        if (messages.length > 0) {
            Bus.emit('chat:changeContent', { content: `分享${messages.length}条聊天记录` });
        }
    };
    const clearMessages = () => {
        updateMessages({ messages: [] });
        Bus.emit('chat:clearSelect', { clearSelect: true });
    };

    return (
        <div className="forwardSelect">
            <div className="clear-select">
                <span onClick={clearMessages}>清空</span>
                <span>已选择{messages.length}条聊天记录</span>
            </div>
            <div className="operate">
                <Button onClick={handleCancel}>取消</Button>
                <Button type="primary" onClick={handleOk}>
                    完成
                </Button>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ForwardSelect);
