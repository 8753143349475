import React, { useEffect, useMemo, useState } from 'react';
import Bus from '@/utils/bus';
import './index.less';
import { UserCardType } from '@/types/UserCard';

interface IProps {
    list: any[];
    maxShowLength?: number;
    isDetail?: boolean;
}

const UserNameList = (props: IProps) => {
    const { list = [], maxShowLength = 8, isDetail } = props;
    // const maxLen = list.length;
    const [userList, setUserList] = useState<any[]>(list);
    const [maxLen, setMaxLen] = useState(list.length);
    // useEffect(() => {
    //     setMaxLen(list.length);
    //     if (list.length > maxShowLength) {
    //         const users = list.slice(0, maxShowLength);
    //         setUserList(users);
    //     }
    // }, [list, maxShowLength]);

    const openUserCard = (item: any) => {
        const { userId, teamId, appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
    };

    function RealName({ user }: { user: any }) {
        return (
            <span
                className="supervise-user-item"
                onClick={(event) => {
                    event.stopPropagation();
                    openUserCard(user);
                }}
            >
                {user.realName}
            </span>
        );
    }

    return (
        <div className="supervise-user-list">
            <div className="supervise-user-item-box">
                {userList.map((item: any, index: number) => {
                    return <RealName key={index} user={item} />;
                })}
            </div>
            {!isDetail && maxLen > maxShowLength && (
                <div className="supervise-user-more">等{maxLen}人</div>
            )}
        </div>
    );
};
export default UserNameList;
