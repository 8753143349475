/**
 * 加入视频会议页面
 * @author zhangpengcheng15
 */
import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import bus from '@/utils/bus';
import GlobalContext from '@/context/GlobalContext';
import { FocusSDK, JoyMeetingStatusEnum, useDebounce } from '@/utils';
import { ZoomMeetingStatus } from '@/types/common';
import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { ZOOM_PASS_LENGTH } from '../utils';
import cache from '@/utils/cache';
import { connect as dvaConnect } from 'dvajs';
import { NetType } from '@/components/Modals/ChangeNetModal';
import { analysisLog } from '@/utils/logAnalytics';

export interface JoinMeetingResult {
    meetingNum: string;
    meetingPass: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
}

function JoinMeetingModal(props: any) {
    const [showPass, setShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [meetingNum, setMeetingNum] = useState('');
    const [pass, setPass] = useState('');
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const [t_common] = useTranslation('common');
    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
    });
    const { authInfo = {} as any, currentEmployee } = useContext(GlobalContext);
    const { teamUserInfo } = authInfo;
    const inputRef = React.useRef<Input | null>(null);
    const { newPublicResult, newPrivateResult } = props;

    useEffect(() => {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
    }, []);

    useEffect(() => {
        function handleModalVisible() {
            setVisible(true);
        }
        bus.on('meeting:join-show', handleModalVisible);
        return () => {
            bus.off('meeting:join-show', handleModalVisible);
        };
    }, []);

    function closeModal() {
        setMeetingNum('');
        setPass('');
        setVisible(false);
        setShowPass(false);
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
        });
    }
    function onMeetingNumChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.trim().replace(/\s+/g, '');
        if (connect) {
            return;
        }
        if (!/^\d{0,8}$/.test(value)) {
            return;
        }
        setMeetingNum(value);
    }

    const debounceToast = useDebounce((str: string, action: 'error') => {
        message[action](str);
    }, 1500);

    function onJoinMeeting() {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            if (newPublicResult) {
                message.warn(t_common('joinMeeting.change public net'));
            } else {
                message.warn(t_common('joinMeeting.private net'));
            }
            return;
        }
        if (connect) {
            return;
        }
        setConnect(true);
        function handleInitCb(result: string) {
            if (result !== 'success') {
                if (result === '14') {
                    message.error(
                        t_common('joinMeeting.The other instance of the SDK is in process')
                    );
                } else {
                    message.error(t_common('joinMeeting.meeting initialization failure'));
                }
                removeListener();
                setConnect(false);
                return;
            }
            FocusSDK.sendIpcMessage('zoom.joinUnlogin', {
                meetingnum: meetingNum,
                psw: pass,
                username: currentEmployee.name,
                isvideooff: state.videoDisable,
                isaudiooff: state.audioDisable,
            });
        }

        /**
         * 处理一下加入会议的回调
         * 连接中 显示一下loading组件
         * 失败则弹出错误提示，并重置一些状态
         * 连接成功 关闭当前modal
         * @param event
         * @param status
         */
        function handleMeetingStatusCb(status: number) {
            switch (status) {
                case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                    closeModal();
                    // removeListener();
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_WAITINGFORHOST:
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_INMEETING:
                    removeListener();
                    break;
                // case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                //   setConnect(true);
                //   break;
                case ZoomMeetingStatus.MEETING_STATUS_DISCONNECTING:
                case ZoomMeetingStatus.MEETING_STATUS_FAILED:
                    debounceToast(t_common('joinMeeting.meeting connect failed'), 'error');
                    setConnect(false);
                    setTimeout(() => {
                        removeListener();
                    }, 2000);

                    break;
                case ZoomMeetingStatus.MEETING_STATUS_ENDED:
                    debounceToast(t_common('joinMeeting.meeting has already ended'), 'error');
                    setConnect(false);
                    removeListener();
                    break;
                default:
                    debounceToast(t_common('joinMeeting.meeting connect failed'), 'error');
                    setConnect(false);
                    setTimeout(() => {
                        removeListener();
                    }, 2000);
                    break;
            }
        }

        /**
         * 成功或失败后，移除监听函数
         */
        function removeListener() {
            FocusSDK.removeAllListeners('zoom.initCb');
            FocusSDK.removeAllListeners('zoom.meetingStatusCb');
            FocusSDK.removeAllListeners('MeetingControl:changeJoyMeetingStatusCb');
        }

        function changeJoyMeetingStatusCb(args: { code: JoyMeetingStatusEnum; isFree: boolean }) {
            const { isFree } = args;
            if (!isFree) {
                setConnect(false);
                message.error(t_common('joinMeeting.There is a meeting going on'));
                return;
            }
            FocusSDK.once('zoom.initCb', handleInitCb);
            FocusSDK.on('zoom.meetingStatusCb', handleMeetingStatusCb);
            FocusSDK.sendIpcMessage('zoom.initSdk');
        }
        FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', changeJoyMeetingStatusCb);
        FocusSDK.sendIpcMessage(
            'MeetingControl:changeJoyMeetingStatus',
            JoyMeetingStatusEnum.WaitingInMeeting
        );
    }

    function handleMeetingKeyEnter() {
        if (connect) {
            return;
        }
        if (!/^\d{0,8}$/.test(meetingNum)) {
            return;
        }
        onJoinMeeting();
    }

    function onMeetingPassChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        // @ts-ignore
        if (!/^\d*$/.test(value)) {
            return;
        }
        if (value && value.length > ZOOM_PASS_LENGTH) {
            return;
        }
        setPass(value);
    }

    const isValidMeetingNum = () => {
        return meetingNum.length === 8 && (pass.length === 0 || pass.length === ZOOM_PASS_LENGTH);
    };

    function handlePassBlur() {
        if (!pass) {
            setShowPass(false);
        }
    }

    function handleMenuClick(key: string) {
        if (connect) {
            return;
        }
        if (key === 'audio') {
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
    }

    const passComp = showPass ? (
        <Input
            value={pass}
            placeholder={t_common('joinMeeting.meetingPass')}
            autoFocus
            //   onKeyDown={onMeetingKeyDown}
            onPressEnter={handleMeetingKeyEnter}
            onChange={onMeetingPassChange}
            onBlur={handlePassBlur}
        />
    ) : (
        <div
            className="input-label"
            onClick={() => {
                setShowPass(true);
            }}
        >
            <IconFont style={{ marginRight: 5 }} type="iconic_editor" />
            {t_common('joinMeeting.meetingPassLabel')}
        </div>
    );

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    function onCancel() {
        analysisLog('xtbg_PCMeeting', 'Joinin_Cancel');
        closeModal();
    }
    return (
        <Modal
            className="desk-me-join-meeting-modal"
            width={780}
            visible={visible}
            onCancel={onCancel}
            closeIcon={<IconFont type="iconapp_btn_file_cancel" />}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div className="me-join-meeting-body">
                <div className="me-join-meeting-form">
                    <div className="me-join-meeting-input">
                        <Input
                            type="text"
                            value={meetingNum}
                            ref={inputRef}
                            autoFocus
                            placeholder={t_common('joinMeeting.meetingNum')}
                            onPressEnter={handleMeetingKeyEnter}
                            onChange={onMeetingNumChange}
                        />
                    </div>
                    <div className="me-join-meeting-input">{passComp}</div>
                </div>
                <div className="me-join-meeting-actions">
                    <div className="me-join-meeting-menu">
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCMeeting|Joinin_Voice"
                                className={`me-join-meeting-menu-item ${
                                    state.audioDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCMeeting|Joinin_Video"
                                className={`me-join-meeting-menu-item ${
                                    state.videoDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                    </div>
                    <Button
                        disabled={!isValidMeetingNum()}
                        style={{ opacity: isValidMeetingNum() ? 1 : 0.5 }}
                        type="primary"
                        clstag="pageclick|keycount|xtbg_PCMeeting|Joinin_Confirm"
                        onClick={onJoinMeeting}
                        loading={connect}
                    >
                        {t_common('joinMeeting.join')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default dvaConnect(mapStateToProps, mapDispatchToProps)(JoinMeetingModal);
