import Bus from '@/utils/bus';
import config, { ConfigEnum } from '@/config/config';
import React from 'react';

function JoyMeetingCard({ message: data, t }: { message: any; t: any }) {
    const [message, setMessage] = React.useState(data);
    const { ext, content } = message;
    const { meetingNumber, sessionState } = ext || {};
    const isCancel = sessionState === 1;
    const messageChangedCb = React.useCallback(
        (newMessage: any) => {
            if (!newMessage.ext) {
                return;
            }
            // console.log('JoyMeetingCard ->', { message, newMessage });
            if (newMessage.ext.meetingNumber !== message.ext.meetingNumber) {
                return;
            }
            setMessage(newMessage);
        },
        [message]
    );
    React.useEffect(() => {
        Bus.on('chat:message-change', messageChangedCb);
        return () => {
            Bus.off('chat:message-change', messageChangedCb);
        };
    }, [messageChangedCb]);
    return (
        <div className="notice-card-joymeeting">
            <div
                className={['notice-card-joymeeting-title', isCancel ? 'meeting-close' : ''].join(
                    ' '
                )}
            >
                <div className="title-title">{content}</div>
                <div>
                    {t('joyMeeting.meetingNum')}: {meetingNumber}
                </div>
            </div>
            {config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] && (
                <div className="notice-card-action">
                    <div
                        className={[
                            'notice-card-action-view',
                            isCancel ? 'meeting-close' : '',
                        ].join(' ')}
                        onClick={() => {
                            if (isCancel) {
                                return;
                            }
                            Bus.emit('chat_joymeeting_invite', message);
                        }}
                    >
                        {t(`joyMeeting.${isCancel ? 'meetingCancel' : 'joinMeeting'}`)}
                    </div>
                </div>
            )}
        </div>
    );
}

export default JoyMeetingCard;
