/**
 * 聊天会话右侧功能合并组件
 * @author sunzhiguang
 * @date 2020/6/16
 */

import React from 'react';
import './index.less';
import GroupHead from '@/components/chat/GroupHead';
import SessionSetting from '@/components/chat/SessionSetting';
import GroupMembers from '@/components/chat/GroupMembers/index';
import GroupOperation from '@/components/chat/GroupOperation';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import SessionRecordSearch from '@/components/chat/MessageHistorySearch';
import { DrawTypeEnum } from '@/components/chat/enum';

interface Props {
    openSetup: boolean;
    historySearchOpen: boolean;
    shareJoyspaceOpen: boolean;
    userData: any;
    session?: any;
    onToggleSetup?: (type: DrawTypeEnum) => void;
    onClickClose?: () => void;
}

type IProps = Readonly<Props & ChatState>;

function ChatRight(props: IProps) {
    const {
        openSetup,
        historySearchOpen,
        shareJoyspaceOpen,
        sessionMembers,
        selectedSession,
        onClickClose,
        session,
    } = props;
    if (!selectedSession.sessionId) {
        return <div />;
    }

    return (
        <div className="chat-right-container">
            <div className="change-set" id="chat-right-container-change-set">
                <div className="change-set-box">
                    {/* 龙芯浏览器bug处理：默认不把‘右侧设置栏’挂入dom树（原来是挂在dom树但默认不显示- width为0），改动后的效果就是：右侧设置栏的更新 变为 创建 */}
                    {openSetup ? (
                        <>
                            <GroupHead />
                            <SessionSetting
                                newSession={session}
                                sessionMembers={sessionMembers}
                                openSetup={openSetup}
                            />
                            <GroupMembers />
                            <GroupOperation />
                        </>
                    ) : null}
                    <SessionRecordSearch
                        historySearchOpen={historySearchOpen}
                        onClickClose={onClickClose}
                    />
                    {/* <ShareJoyspace shareJoyspaceOpen={shareJoyspaceOpen} /> */}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { sessionMembers, selectedSession } = chat as ChatState;
    return { sessionMembers, selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRight);
