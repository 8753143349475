import React, { useState, useEffect } from 'react';
import './index.less';
import A1Img from '@/assets/img/taskType/A1.png';
import A1ActiveImg from '@/assets/img/taskType/A1Active.png';
import A1HoverImg from '@/assets/img/taskType/A1Hover.png';
import A2Img from '@/assets/img/taskType/A2.png';
import A2ActiveImg from '@/assets/img/taskType/A2Active.png';
import A2HoverImg from '@/assets/img/taskType/A2Hover.png';
import B1Img from '@/assets/img/taskType/B1.png';
import B1ActiveImg from '@/assets/img/taskType/B1Active.png';
import B1HoverImg from '@/assets/img/taskType/B1Hover.png';
import B2Img from '@/assets/img/taskType/B2.png';
import B2ActiveImg from '@/assets/img/taskType/B2Active.png';
import B2HoverImg from '@/assets/img/taskType/B2Hover.png';
import { message, Tooltip } from 'antd';
import { changeEgovUrl } from '@/utils/tools';

// 日程类型
export const scheduleTypeList = [
    {
        color: 'FFF23030',
        url: A1Img,
        activeUrl: A1ActiveImg,
        hoverUrl: A1HoverImg,
        title: 'A1',
    },
    {
        color: 'FFFFB416',
        url: A2Img,
        activeUrl: A2ActiveImg,
        hoverUrl: A2HoverImg,
        title: 'A2',
    },
    {
        color: 'FF5EA7F8',
        url: B1Img,
        activeUrl: B1ActiveImg,
        hoverUrl: B1HoverImg,
        title: 'B1',
    },
    {
        color: 'FF8ADE30',
        url: B2Img,
        activeUrl: B2ActiveImg,
        hoverUrl: B2HoverImg,
        title: 'B2',
    },
];

interface Iprops {
    value: string;
    isMultiply?: boolean;
    handleSelect: (p: string) => void;
    showTooltip?: boolean;
}
export default function ScheduleTypeSelect({
    value,
    isMultiply,
    handleSelect,
    showTooltip = true,
}: Iprops) {
    const [current, setCurrent] = useState(value.split(','));
    useEffect(() => {
        if (isMultiply) {
            setCurrent(scheduleTypeList.map((item) => item.color));
        }
    }, [isMultiply]);
    useEffect(() => {
        if (value.split(',').indexOf('FFE070FF') === -1) {
            setCurrent(value.split(',').concat(['FFE070FF']));
        } else {
            setCurrent(value.split(','));
        }
    }, [value]);
    function handleColorSelect(color: string) {
        const arr = current;
        if (!isMultiply) {
            arr[0] = color;
        } else {
            if (arr.indexOf(color) === -1) {
                arr.push(color);
            } else {
                if (arr.length === 2) {
                    return message.warn('至少选择一种类型');
                }
                arr.splice(arr.indexOf(color), 1);
            }
        }
        setCurrent(arr);
        if (!isMultiply) {
            // 创建/编辑页面处理不要把节日的紫色传进去
            handleSelect(current.filter((item) => item !== 'FFE070FF').join(','));
        } else {
            handleSelect(current.join(','));
        }
    }
    return (
        <div className="schedule-type-wrapper">
            {scheduleTypeList.map((item) => (
                <div
                    className="schedule-type-item"
                    key={item.color}
                    onClick={() => handleColorSelect(item.color)}
                    style={{ cursor: 'pointer' }}
                >
                    <Tooltip title={showTooltip === false ? '' : item.title}>
                        {current.includes(item.color) ? (
                            <img src={changeEgovUrl(item.activeUrl)} alt="" />
                        ) : (
                            <img
                                onMouseOver={(e) => {
                                    let img: any = e.target;
                                    img.src = item.hoverUrl;
                                }}
                                onMouseOut={(e) => {
                                    let img: any = e.target;
                                    img.src = item.url;
                                }}
                                src={changeEgovUrl(item.url)}
                                alt=""
                            />
                        )}
                    </Tooltip>
                </div>
            ))}
        </div>
    );
}
