import React, { useEffect, useMemo, useState } from 'react';
import MyModal from '@/components/MyConfirm';
import './index.less';
import { useTranslation } from 'react-i18next';
import { formatFileName, sizeToStr, getFileIconByMime } from '@/utils/chat/index';
import { lookup } from 'mime-types';
import { Button, message as toast, Table, Tooltip } from 'antd';
import { changeEgovUrl } from '@/utils/tools';
import { sendMessageByKeyboard } from '@/components/chat/utils/message';
import { formatFileType } from '@/components/chat/utils/image';
import { nanoid } from 'nanoid';

const LIMIT_SIZE = 5;
export function UpLoadFile({
    isMulti,
    title,
    size,
    fileType,
    file,
    fileList,
    visible,
    onCancel,
    onClick,
}: {
    isMulti: boolean;
    title?: string;
    size?: number;
    fileType: string;
    file?: any;
    fileList: any[];
    visible: boolean;
    onCancel: () => void;
    onClick: (list?: any) => void;
}) {
    const [t] = useTranslation('chat');
    // console.log('fileType', fileType, fileList);
    const [fileIcon, progressColor, ic_fileIcon] = useMemo(() => {
        return getFileIconByMime(fileType || (title && lookup(title)) || '');
    }, [fileType, title]);
    const size_str = useMemo(() => {
        return sizeToStr(size || 0);
    }, [size]);

    // 多文件发送
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [selectedRow, setSelectedRow] = useState<Array<any>>([]);
    const [tableData, setTableData] = useState(fileList);
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleKeyDown = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log('mention keydown', e.key);
        if (e.key !== 'Enter') return;
        const isCanSend = sendMessageByKeyboard(e, isMulti, selectedRow);
        if (!isCanSend) {
            toast.warning('至少选择一个文件发送');
            return;
        }
        onClick(selectedRow);
    };

    useEffect(() => {
        formatFileType(Array.from(fileList)).then((data) => {
            const newFiles = data.map((file: any) => {
                return {
                    file,
                    title: file.name,
                    size: file.size,
                    fileType: file.type || lookup(file.name) || '',
                    fileId: nanoid(20),
                };
            });
            setTableData(newFiles);
            setLoading(!newFiles.length);
            if (newFiles.length <= LIMIT_SIZE) {
                const ids = newFiles.map((item: any) => item.fileId);
                setSelectedRowKeys(ids);
                setSelectedRow(newFiles);
            } else {
                setSelectedRowKeys([]);
                setSelectedRowKeys([]);
            }
        });
    }, [fileList]);

    // 设置class类
    useEffect(() => {
        if (tableData.length > LIMIT_SIZE) {
            const disbleRows = document.querySelectorAll('.ant-checkbox-wrapper-disabled');
            if (disbleRows.length) {
                disbleRows.forEach((ele) => {
                    const tr = ele.parentNode?.parentNode as Element;
                    tr.classList.add('over-max-num-disabled');
                    setIsDisabled(true);
                });
            } else {
                if (isDisabled) {
                    const disbleTr = document.querySelectorAll('.over-max-num-disabled');
                    if (disbleTr.length) {
                        disbleTr.forEach((ele) => {
                            ele.classList.remove('over-max-num-disabled');
                        });
                    }
                    setIsDisabled(false);
                }
            }
        }
    }, [tableData, selectedRowKeys, isDisabled]);

    function getFileType(record: any) {
        const [fileIcon, progressColor, ic_fileIcon] = getFileIconByMime(
            record.fileType || (record.title && lookup(record.title)) || ''
        );
        return ic_fileIcon;
    }

    function getCheckboxProps(record: any) {
        return {
            disabled:
                selectedRowKeys.length >= LIMIT_SIZE && !selectedRowKeys.includes(record.fileId),
        };
    }

    function rowSelectChange(selectedkeys: any, selectedRows: any) {
        // console.log('选中的值', selectedkeys, selectedRows);
        if (tableData.length > LIMIT_SIZE && selectedkeys.length > LIMIT_SIZE) {
            // message.warn('最多选择5个文件');
            return;
        } else {
            setSelectedRowKeys(selectedkeys);
            setSelectedRow(selectedRows);
        }
    }

    const columns: any = [
        {
            title: '文件名称',
            width: '260px',
            dataIndex: 'title',
            align: 'left',
            ellipsis: true,
            render: (title: string, record: any) => {
                const icon_src = getFileType(record);
                return (
                    <div className="file_title">
                        <img src={changeEgovUrl(icon_src)} draggable="false" />
                        <Tooltip
                            title={title}
                            overlayClassName="multi-file-tooltip"
                            placement="bottom"
                        >
                            <div className="file_title_text">{title}</div>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: '文件大小',
            width: '120px',
            dataIndex: 'size',
            align: 'center',
            render: (size: number) => {
                const sizeText = sizeToStr(size || 0);
                return <div>{sizeText}</div>;
            },
        },
    ];

    if (!isMulti) {
        return (
            <MyModal
                title={t('send_file')}
                onCancel={onCancel}
                onClick={onClick}
                onKeyDown={handleKeyDown}
                isKeyboard={true}
                visible={visible}
            >
                <div className="chat-uploadfile">
                    <div className="file-container">
                        <img src={changeEgovUrl(ic_fileIcon)} draggable="false" />
                    </div>
                    {title && (
                        <div className="file-title">
                            {title}
                            {/* {formatFileName(title)} */}
                        </div>
                    )}
                    {size ? <div className="file-size">{size_str}</div> : ''}
                </div>
            </MyModal>
        );
    } else {
        return (
            <MyModal
                title={t('send_content')}
                toolTip={t('max_file_num').replace('%s', `${LIMIT_SIZE}`)}
                onCancel={onCancel}
                onClick={() => onClick(selectedRow)}
                onKeyDown={handleKeyDown}
                isKeyboard={true}
                visible={visible}
                disabled={!selectedRowKeys.length || !tableData.length}
                selfClass="multi-file"
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    locale={{ emptyText: '暂无数据' }}
                    pagination={false}
                    scroll={{ y: 308 }}
                    rowKey="fileId"
                    rowClassName="multi-file-list-table-row"
                    rowSelection={{
                        columnWidth: 40,
                        getCheckboxProps,
                        hideSelectAll: tableData.length > LIMIT_SIZE ? true : false,
                        selectedRowKeys,
                        onChange: rowSelectChange,
                    }}
                />
            </MyModal>
        );
    }
}
