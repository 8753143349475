import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import './index.less';
import JEditor from './JEditor';
import { useTranslation } from 'react-i18next';
import Quill, { Sources } from 'quill';
import { FocusSDK, isFocusEnv, useDebounce } from '@/utils';
import { connect } from 'dva';
import { UserCardPayload } from '@/models';
import { UserItem } from '../../BaseComponents/UserList';
import { WorkUserInfo, TaskComment } from '@/types/work';
import { UserCardType } from '@/types/UserCard';
import Bus from '@/utils/bus';
import { Button, message } from 'antd';
const Delta = Quill.import('delta');

function mapStateToProps({ work, user }: any) {
    const { personalState } = work;
    return {
        userData: user.userData ? user.userData.user : {},
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
    };
}
function InputComment({
    onSend,
    toggleUserCardModal,
    userData,
    replyComment,
    onReply,
    directAt,
}: {
    onSend: (content: string) => Promise<boolean>;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    userData: any;
    replyComment: TaskComment | null;
    onReply: (item: TaskComment | null) => void;
    directAt?: string;
}) {
    const inputRef = useRef<JEditor | null>(null);
    const [t] = useTranslation('work');
    const [expand, setExpand] = useState(true);
    const [content, setContent] = useState('');

    useEffect(() => {
        if (replyComment) {
            focus();
        } else {
            setExpand(false);
        }
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.changePlaceholder(
            replyComment ? `${t('reply')} ${replyComment?.realName}` : t('comment placeholder')
        );
    }, [replyComment, t]);

    function focus() {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.focus();
    }
    function closeMention() {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.events.emit('close-mention');
    }
    const handleSearch = useCallback((input: string) => {
        if (!input) {
            return [];
        }

        return [];
    }, []);

    const [t_common] = useTranslation('common');

    /**
     * 内容变更事件
     * @param delta
     */
    function contentChange(delta: any, oldDelta: any, source: Sources) {
        setTimeout(() => {
            const jeditor: any = inputRef.current;
            if (!jeditor) return;
            let delta = jeditor.getContent();
            while (isEndwithEnter(delta)) {
                const length = delta.length();
                delta = delta.compose(new Delta().retain(length - 1).delete(1));
            }
            if (!delta.ops?.length) {
                setContent('');
                return;
            }
            setContent(delta.ops[0].insert);
        }, 0);
    }

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ userId, teamId, appId, type: UserCardType.User });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }

    async function handleClick(e: any) {
        e.persist();
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        let delta = jeditor.getContent();
        while (isEndwithEnter(delta)) {
            const length = delta.length();
            delta = delta.compose(new Delta().retain(length - 1).delete(1));
        }
        if (!delta.ops?.length) {
            message.warning('请输入评论');
            return;
        }
        const result = await onSend(JSON.stringify(delta.ops));
        if (result) {
            jeditor.clearMessage();
        }
    }

    const debounceClick = useDebounce(handleClick, 300);

    /**
     * 判断最后一个
     * @param {*} delta
     */
    function isEndwithEnter(delta: any) {
        if (delta.ops.length < 1) {
            return false;
        }
        let length = delta.ops.length;
        const op = delta.ops[length - 1];
        if (typeof op.insert === 'string' && op.insert.endsWith('\n')) {
            return true;
        }
        return false;
    }

    function handleExpand() {
        if (expand) {
            return;
        }
        setExpand(true);
    }

    function handleCancel(e: any) {
        e.stopPropagation();
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.clearMessage();
        onReply(null);
        setExpand(false);
    }
    const { teamUserInfo } = userData;

    return (
        <div
            className={`comment-input-container ${
                !replyComment ? 'comment-input-container-bottom' : ''
            }`}
        >
            <UserItem item={teamUserInfo} />
            <div
                // className={`comment-input-content ${expand ? '' : 'comment-input-noExpand'}`}
                className="comment-input-content"
                onClick={handleExpand}
            >
                <JEditor
                    key="work-jeditor"
                    contentChange={contentChange}
                    ref={inputRef}
                    // upload={handleUploadImgs}
                    placeholder={
                        replyComment
                            ? `${t('reply')} ${replyComment?.realName}`
                            : t('comment placeholder')
                    }
                    // disableEnter={true}
                    maxLength={500}
                    // onEditorKeyDown={handleKeyDown}
                    handleSearch={handleSearch}
                    t={t_common}
                    disableAt={true}
                />
                <div className="text-length">
                    <span>{content.length}</span>/500
                </div>
                <div className="comment-input-footer" onClick={focus}>
                    <Button
                        size="middle"
                        className="comment-input-footer-cancel"
                        onClick={handleCancel}
                    >
                        {t_common('button.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        clstag={`pageclick|keycount|${
                            directAt === 'task'
                                ? 'xtbg_add_task|xtbg_add_task_taskdetail_comment_send'
                                : 'xtbg_add_task|xtbg_add_task_taskdetail_comment_reply'
                        }`}
                        size="middle"
                        className="comment-input-footer-ok"
                        onClick={debounceClick}
                    >
                        {t_common('button.send')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(InputComment);
