import React, { useCallback, useEffect, useMemo, useState } from 'react';
import events from '../../../context/events';
import { Checkbox } from 'antd';
import './index.less';
import { title } from 'process';
import SharesFilters from '../SharesFilters';
import { DownOutlined } from '@ant-design/icons';
import { menu, menu2, MenuEnum } from '../SharesFilters/config';
import Filters from '../Filters';
import { timeFilter, titleFilter } from '../Filters/config';
export interface IProps<T, C> {
    name: string;
    headers?: IHeader;
    batch: boolean;
    selecteds: Set<any>;
    select: (set: Set<any>) => void;
    source: (T & { id?: string })[];
    categories: (C & { id?: string })[];
    columnProps: any;
    onChangeTableHeaderKey?: Function;
    defaultActiveKey?: MenuEnum;
}
export interface IHeader {
    name: string;
    title: string;
}
export default function Headers<T, C>(props: IProps<T, C>) {
    const {
        headers,
        name,
        columnProps,
        onChangeTableHeaderKey,
        defaultActiveKey = MenuEnum.SHARES,
    } = props;
    // console.log(name, 'namenamenamenamenamename', columnProps);
    const title = useMemo(() => {
        return (
            <div
                style={{ paddingRight: '50px', maxWidth: 142 }}
                // data-colname={headers?.name || 'update_time'}
            >
                {name === 'trashes' ? (
                    '删除时间'
                ) : name === 'collections' ? (
                    '收藏时间'
                ) : name === 'recent' ? (
                    '最近打开时间'
                ) : name === 'homeMyshare' || name === 'homeshare' ? (
                    '分享时间'
                ) : (
                    <Filters
                        onChange={(e: any) => {
                            events.emit('onChangeFilter', e);
                        }}
                        menu={timeFilter()}
                    />
                )}
            </div>
        );
    }, [name]);
    return (
        <div data-header>
            <div data-row>
                <div data-colname="title">
                    {props.batch && (
                        <Checkbox
                            indeterminate={
                                props.selecteds.size > 0 &&
                                props.selecteds.size < props.source.length + props.categories.length
                            }
                            checked={
                                props.selecteds.size ===
                                    props.source.length + props.categories.length &&
                                props.source?.length + props.categories.length !== 0
                            }
                            className="joyspace-table-header-select-all"
                            onChange={(e) => {
                                const _set = new Set(props.selecteds);
                                if (e.target.checked) {
                                    props.source.forEach((item: T) => _set.add(item));
                                    props.categories.forEach((item: C) => _set.add(item));
                                } else {
                                    _set.clear();
                                }
                                props.select(_set);
                            }}
                        />
                    )}
                    {!name ? (
                        <Filters
                            onChange={(e: any) => {
                                events.emit('onChangeFilter', e);
                            }}
                            menu={titleFilter()}
                        />
                    ) : (
                        <div data-colname="title">标题</div>
                    )}
                </div>
                {columnProps.some((item: any) => item.name === 'status') && (
                    <div style={{ marginRight: '65px' }} data-colname="status">
                        {/* 所有者 */}
                        <SharesFilters
                            menuList={menu2}
                            arrow={false}
                            defaultActiveKey={defaultActiveKey}
                            onChange={(key: string) => {
                                onChangeTableHeaderKey?.(key);
                            }}
                        />
                    </div>
                )}
                {columnProps.some((item: any) => item.name === 'owner') && (
                    <div style={{ marginRight: '65px' }} data-colname="status">
                        {/* 所有者 */}
                        <SharesFilters
                            menuList={menu}
                            defaultActiveKey={defaultActiveKey}
                            onChange={(key: string) => {
                                onChangeTableHeaderKey?.(key);
                            }}
                        />
                    </div>
                )}
                {title}
            </div>
        </div>
    );
}
