/* eslint-disable no-console */

/**
 * 消息盒子容器组件， 所有消息记录会在这里
 */
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MessageBox from '@/baseComponents/Chat/message/Box';
import Loading from '@/components/Loading';
import { MessageSendType } from '@/types/chat/enum';
import SimpleTime from '@/baseComponents/Chat/message/Time/Simple';

import ChatState, { AITabState, SessionMessageMap } from '@/types/chat/State';
import { connect } from 'dva';
import TimeLine from '@/baseComponents/Chat/message/Time/Line';
import ImService from '@/server/ImService';
import { isSameDay } from '@/utils/date';
import {
    ChatMessage,
    Employee,
    MessageEmitCategory,
    MessageReadScene,
    Session,
} from '@/types/chat';
import { usePrevious } from '@/utils/chat/index';
import {
    checkSysMessage,
    filterNoticeMassagesByType,
    isEqualEmployee,
    isDownloadSysMesssage,
} from '@/utils/chat/message';
import Context from '@/context/ChatContext';
import bus from '@/utils/bus';
import ScrollView from '@/baseComponents/ScrollView';

import CHAT_NOTICE from '@/contant/chat';
import classNames from 'classnames';
import { isAINoticeSession } from '@/utils/chat/session';
import { MessageStatus } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import ChatEvent from '@/baseComponents/Chat/view/ChatEvent';
import './index.less';
import debounce from 'lodash/debounce';

import aiEmpty from '@/assets/img/empty/ai-empty.png';
import aiArrows from '@/assets/img/empty/ai-arrows.png';
import aiNoData from '@/assets/img/empty/ai-no-data.png';
interface MessageBoxContainerProps {
    msgNodes: Array<ReactNode>;
    sendType: MessageSendType;
    selectedAITab: AITabState;
    sessionMessageMap: SessionMessageMap;
    storePreSelectedSession: Session;
}

interface DvaDispatchProps {
    setHistoryMessage: Function;
    updateSelectedAITab: Function;
    updatePreSelectedSession: Function;
    updatePreAISessionMsg: Function;
}

type IMessageBoxContainerProps = Readonly<MessageBoxContainerProps & ChatState & DvaDispatchProps>;

const getSimpleTimeNode = (messages: ChatMessage[], prevMessages: ChatMessage[]): ReactNode => {
    const timestamp = messages[0].timestamp;
    let prev_timestamp = Date.now();
    if (prevMessages?.length) {
        prev_timestamp = prevMessages[prevMessages.length - 1].timestamp;
    }
    if (!isSameDay(timestamp, prev_timestamp)) {
        return (
            <div>
                <TimeLine timeStamp={timestamp} />
                <SimpleTime timeStamp={timestamp} />
            </div>
        );
    } else {
        if (timestamp - prev_timestamp > 60 * 1000) {
            return <SimpleTime timeStamp={timestamp} />;
        } else {
            return <div />;
        }
    }
};

const getMessageNode = (
    messages: ChatMessage[],
    getLock: Function,
    unLock: Function,
    getCurrentMessageId: Function,
    setCurrentMessageId: Function,
    session: Session,
    currentEmployee: Employee,
    updateSessionMessagesTimestamp: number
    // eslint-disable-next-line max-params
) => {
    // console.log(updateSessionMessagesTimestamp, 'updateSessionMessagesTimestamp');
    if (!messages.length) {
        return [];
    }
    const groups: Array<ChatMessage[]> = [];
    let temp: ChatMessage[] = [];
    messages.forEach((m: ChatMessage) => {
        if (m && m.category === MessageEmitCategory.SYSTEM) {
            if (!checkSysMessage({ message: m, session })) return;
        }
        // 解决群成员可以看到本地发送失败的消息问题
        if (m.statusType === MessageStatus.FAILED && m.localSendTimestamp) {
            if (!m.sender?.userId) {
                const user = (m.belong || '').split(':') || [];
                m.sender = { ...m.sender, userId: user[0], app: user[1], teamId: user[2] };
            }
            if (!isEqualEmployee(m.sender, currentEmployee)) {
                return;
            }
        }
        if (!temp.length) {
            temp.push(m);
            return;
        }
        if (m.groupId !== temp[temp.length - 1].groupId) {
            groups.push([...temp]);
            temp = [m];
            return;
        }
        temp.push(m);
    });
    if (temp.length) {
        groups.push([...temp]);
    }
    return groups.map((messages, index) => {
        if (!messages[0].id) {
            return <div key={index} />;
        }
        return (
            <div key={`key-${messages[0].id}`} id={'id-' + messages[0].id}>
                {index > 0 ? (
                    getSimpleTimeNode(messages, groups[index - 1])
                ) : (
                    <SimpleTime timeStamp={messages[0].timestamp} />
                )}
                <MessageBox
                    key={`k-${messages[0].id}`}
                    messages={messages}
                    getLock={getLock}
                    unLock={unLock}
                    getCurrentMessageId={getCurrentMessageId}
                    setCurrentMessageId={setCurrentMessageId}
                    session={session}
                />
            </div>
        );
    });
};

function MessageBoxContainer(props: IMessageBoxContainerProps) {
    const scrollRef = useRef(null);
    const wrapRef = useRef(null);
    const {
        selectedSession,
        sessionMessages,
        setHistoryMessage,
        mainWinShow,
        updateSelectedAITab,
        selectedAITab,
        sessionMessageMap,
        storePreSelectedSession,
        updatePreSelectedSession,
        updatePreAISessionMsg,
        prevAISessionMessages,
        updateSessionMessagesTimestamp,
    } = props;
    const { currentEmployee } = React.useContext(Context);

    const sessionId = selectedSession.sessionId;
    // console.log(sessionMessages, 'sessionMessages===>');
    // const meSessionMessages = [...sessionMessages];

    const [end, setEnd] = useState(0);

    const currPage = useRef(0);

    const [loadingHistory, setLoadingHistory] = useState(false);

    const prevSessionMessages = usePrevious(sessionMessages || []);
    if (isAINoticeSession(selectedSession) && !prevAISessionMessages.length) {
        updatePreAISessionMsg({
            prevAISessionMessages: selectedSession
                ? sessionMessageMap[selectedSession.sessionId]
                : [],
        });
    }
    const preSelectSession = usePrevious(selectedSession || {});
    const preMainWinShow = usePrevious(mainWinShow);
    updatePreSelectedSession({ preSelectSession });
    const scrollInToView = useCallback((options?: any) => {
        const curr: any = scrollRef.current;
        if (!curr) {
            return;
        }
        const scrollHeight = curr.getScrollHeight();
        if (curr?.view?.scroll) {
            curr.view.scroll({ top: options?.top || scrollHeight, ...options });
        }
    }, []);

    const currentMessageIdRef = useRef('');

    const setCurrentMessageId = useCallback((messageId) => {
        currentMessageIdRef.current = messageId;
    }, []);

    const getCurrentMessageId = useCallback(() => {
        return currentMessageIdRef.current;
    }, []);

    const lockRef = useRef(false);

    const getLock = useCallback((lock?: boolean): boolean => {
        if (lockRef.current) {
            return false;
        }
        if (lock) {
            lockRef.current = true;
        }
        return true;
    }, []);

    const unLock = useCallback(() => {
        lockRef.current = false;
    }, []);

    const scrollBottom = useCallback((force = true) => {
        const curr: any = scrollRef.current;
        if (!curr) {
            return;
        }
        const scrollHeight = curr.getScrollHeight();
        const scrollTop = curr.getScrollTop();
        const { clientHeight } = curr.getValues();
        // 超出阈值则不滚动，默认用户在自己浏览消息
        if (!force && scrollHeight - clientHeight - scrollTop > clientHeight / 2) {
            return;
        }
        if (curr.view?.scroll) {
            curr.view.scroll({ top: scrollHeight - 10 });
        }
    }, []);

    const maintainScroll = useCallback((id: string) => {
        const curr: any = scrollRef.current;
        const wrap: any = wrapRef.current;
        if (!curr || !wrap) {
            return;
        }
        const scrollTop = curr.getScrollTop();
        if (scrollTop > 10) {
            return;
        }
        try {
            let ele = wrap.querySelector(`#id-${id}`);
            if (!ele) {
                ele = wrap.querySelectorAll(`div[id^='item-${id}`);
                if (ele?.length) {
                    ele = ele[0];
                }
            }
            if (!ele) {
                return;
            }
            ele.scrollIntoView();
            setLoadingHistory(false);
        } catch (e) {}
    }, []);

    /**
     * 处理滚动事件
     */
    useEffect(() => {
        if (!prevSessionMessages?.length && !sessionMessages?.length) {
            return;
        }
        // 如果前置状态为空，当前状态有值，假定为页面初始化， 后续加入页面缓存之后需要变更本逻辑
        if (!prevSessionMessages?.length && sessionMessages.length) {
            scrollBottom(false);
            return;
        }

        const firstPre = (prevSessionMessages || [])[0] || {};
        const first = (sessionMessages || [])[0] || {};
        const lastPre = (prevSessionMessages || []).slice(-1)[0] || {};
        const last = (sessionMessages || []).slice(-1)[0] || {};
        // 如果消息长度没变化
        if (
            (prevSessionMessages &&
                sessionMessages &&
                prevSessionMessages.length === sessionMessages.length) ||
            isDownloadSysMesssage(last)
        ) {
            return;
        }
        // 如果前置状态头部一致尾部不一致，假定有新消息
        if (firstPre.id === first.id && lastPre.id !== last.id) {
            setTimeout(() => {
                scrollBottom(true);
            }, 10);
        }

        if (firstPre.id !== first.id) {
            maintainScroll(firstPre.id);
        }
    }, [maintainScroll, prevSessionMessages, scrollBottom, sessionMessages, selectedAITab]);

    // tj: 增加loading
    const loadHistory = useCallback(
        (startMid, offset = 0) => {
            const imService = ImService.getInstance();
            return imService.loadHistory(sessionId, startMid, offset);
        },
        [sessionId]
    );

    // 计算是否有新的ai消息
    const computeHasNewAIMessage = useCallback(
        (nextAIAllMessage: ChatMessage[]) => {
            if (isAINoticeSession(selectedSession)) {
                if (prevAISessionMessages.length !== nextAIAllMessage.length) {
                    updatePreAISessionMsg({ prevAISessionMessages: nextAIAllMessage });
                    return true;
                }
            }
            return false;
        },
        [selectedSession, prevAISessionMessages, updatePreAISessionMsg]
    );

    // 如果有新ai消息，同步更新tab
    const syncSelectedAITab = useCallback(
        (nextAIAllMessage: ChatMessage[]) => {
            if (
                computeHasNewAIMessage(nextAIAllMessage) &&
                selectedAITab.pin !== CHAT_NOTICE.ALL_PIN &&
                storePreSelectedSession?.sessionId !== selectedSession.sessionId
            ) {
                updateSelectedAITab({ selectedAITab: { pin: CHAT_NOTICE.ALL_PIN } });
            }
        },
        [
            selectedAITab,
            computeHasNewAIMessage,
            updateSelectedAITab,
            storePreSelectedSession,
            selectedSession,
        ]
    );

    useEffect(() => {
        const curr: any = scrollRef.current;
        const scrollHeight = curr.getScrollHeight();
        curr.view.scroll({ top: scrollHeight });
    }, [selectedAITab]);

    useEffect(() => {
        // 页面加载以后 加载历史消息 只有会话第一次打开以后加载历史数据
        if (sessionMessages.length > 0) {
            // 如果存在消息数据，不加载历史记录, 初始化end状态
            setEnd(0);
            scrollBottom(true);
            currPage.current = 0;
        } else {
            setLoadingHistory(true);
        }
        loadHistory(-1).then(({ messages, end }: any) => {
            if (messages?.length > 0) {
                setHistoryMessage({ sessionId, historyMessage: messages });
                setEnd(end);
                if (isAINoticeSession(selectedSession)) {
                    currPage.current += 1;
                }
                scrollInToView();
            } else {
                setHistoryMessage({ sessionId, historyMessage: [] });
            }
            if (sessionId.indexOf(CHAT_NOTICE.AI_PIN) > -1) {
                syncSelectedAITab(messages || []);
            }
            setLoadingHistory(false);
        });
    }, [sessionId, scrollInToView]); // eslint-disable-line

    function onScroll() {
        if (!sessionMessages || sessionMessages?.length <= 0) {
            return false;
        }
        if (loadingHistory) {
            return;
        }
        if (end > 0) {
            return;
        }
        const cu: any = scrollRef.current;
        if (!cu) {
            return;
        }
        const scrollTop = cu.getScrollTop();

        if (scrollTop < 10) {
            if (end > 0) {
                return;
            }
            const messages = sessionMessages.filter((i) => i.mid > 0);
            const startMid = isAINoticeSession(selectedSession)
                ? -1
                : messages[0]
                ? messages[0].mid - 1
                : -1;
            setLoadingHistory(true);
            loadHistory(startMid, currPage.current).then(({ messages, end }: any) => {
                if (messages?.length > 0) {
                    setHistoryMessage({ sessionId, historyMessage: messages });
                    setEnd(end);
                    if (isAINoticeSession(selectedSession)) {
                        currPage.current += 1;
                    }
                }
                setLoadingHistory(false);
            });
        }
    }

    // 优化计算
    const content = useMemo(() => {
        return getMessageNode(
            sessionMessages || [],
            getLock,
            unLock,
            getCurrentMessageId,
            setCurrentMessageId,
            selectedSession,
            currentEmployee,
            updateSessionMessagesTimestamp
        );
    }, [
        sessionMessages,
        getLock,
        unLock,
        getCurrentMessageId,
        setCurrentMessageId,
        selectedSession,
        currentEmployee,
        updateSessionMessagesTimestamp,
    ]);

    /* eslint-disable */
    const onMainWinShow = useCallback(
        debounce(() => {
            if (!selectedSession.sessionId || !mainWinShow) {
                return;
            }
            const count = selectedSession.unreadCount;
            // 设用imService readAll接口
            const imService = ImService.getInstance();
            setTimeout(() => {
                imService
                    .readAllMessage(selectedSession.sessionId, MessageReadScene.ENTER, count !== 0)
                    .catch((err) => console.warn('imService-readAllMessage-err', err));
            }, 10);
        }, 15),
        [selectedSession, mainWinShow]
    );
    /* eslint-enable */

    useEffect(() => {
        if (!preMainWinShow && mainWinShow) {
            onMainWinShow();
        }
    }, [preMainWinShow, mainWinShow, onMainWinShow]);

    useEffect(() => {
        if (
            selectedSession?.sessionId &&
            preSelectSession?.sessionId !== selectedSession?.sessionId
        ) {
            onMainWinShow();
        }
    }, [preSelectSession, selectedSession, onMainWinShow]);

    const handleContainerClick = useCallback(() => {
        // 点击聊天框时关闭更多操作
        lockRef.current = false;
    }, []);

    // 屏蔽tab按键
    const onKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.keyCode === 9) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            return false;
        }
    }, []);
    useEffect(() => {
        // 按键处理
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="message-box-container"
            onClick={handleContainerClick}
            ref={wrapRef}
            // style={{
            //     paddingBottom:
            //         (selectedSession.lastMsg?.type as any) === 'group_notice' ? '27px' : '0px',
            // }}
        >
            <ScrollView
                className={classNames('message-box-container-scroll')}
                id="message-box-container-scroll"
                ref={scrollRef}
                nohorizontal="true"
                onScroll={onScroll}
                autoHideTimeout={30000}
            >
                <div>
                    {sessionMessages?.length > 0 ? (
                        <div className="today-messages-nodes" id="today-messages-nodes">
                            {end > 0 && <TimeLine timeStamp={sessionMessages[0].timestamp} />}
                            {content}
                        </div>
                    ) : isAINoticeSession(selectedSession) && !loadingHistory ? (
                        <div className="ai-empty-wrapper">
                            {/* <div className="empty-png">
                                <img src={aiEmpty} alt="" width="100%" />
                            </div>
                            <div className="arrows-png">
                                <img src={aiArrows} alt="" width="100%" />
                            </div> */}
                            <div className="no-data-png">
                                <img src={aiNoData} alt="" width="100%" />
                            </div>
                            <p className="empty-text">
                                暂无内容
                                <br />
                                请切换至“全部”查看
                            </p>
                        </div>
                    ) : null}
                    {loadingHistory && (
                        <div className="loading-wrap">
                            <Loading type="message" />
                        </div>
                    )}
                </div>
            </ScrollView>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const {
        selectedSession,
        sessionMessageMap,
        mainWinShow,
        selectedAITab,
        allSessionList,
        preSelectedSession,
        prevAISessionMessages,
        updateSessionMessagesTimestamp,
    } = chat as ChatState;
    let sessionMessages: any[] = sessionMessageMap[selectedSession.sessionId] || [];
    if (isAINoticeSession(selectedSession)) {
        const selectedPin = selectedAITab.pin || CHAT_NOTICE.ALL_PIN;
        sessionMessages = filterNoticeMassagesByType(sessionMessages, selectedPin);
    }
    return {
        sessionMessages,
        selectedSession,
        mainWinShow,
        allSessionList,
        selectedAITab,
        sessionMessageMap,
        storePreSelectedSession: preSelectedSession,
        prevAISessionMessages,
        updateSessionMessagesTimestamp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async setHistoryMessage(data: { sessionId: string; historyMessage: ChatMessage }) {
            await dispatch({ type: 'chat/setHistoryMessage', payload: data });
        },
        async updateSelectedAITab(data: { selectedAITab: AITabState }) {
            await dispatch({ type: 'chat/updateSelectedAITab', payload: data });
        },
        async updatePreSelectedSession(data: { preSelectedSession: Session }) {
            await dispatch({ type: 'chat/updatePreSelectedSession', payload: data });
        },
        async updatePreAISessionMsg(data: { prevAISessionMessages: ChatMessage[] }) {
            await dispatch({ type: 'chat/updatePreAISessionMsg', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoxContainer);
