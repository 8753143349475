import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
    getTemplateCategories,
    getTemplateListByCategories,
} from '@/components/Joyspace/api/createmenu';
import { TemplateCategory, Template } from '@/components/Joyspace/types';
import './index.less';
import Modal from 'antd/lib/modal/Modal';
import default_chat_png from '@/assets/img/no-content.png';
import { GlobalContextConfig } from '@/context/GlobalContext';
import { Empty, Tabs } from 'antd';
import Loading from '@/components/Loading';
import { changeEgovUrl } from '@/utils/tools';

const { TabPane } = Tabs;
const style = { margin: 0 };
const TempType = {
    selfDefine: 'selfDefine',
    stander: 'stander',
};

interface ITState {
    tempItemData: any;
    selfDefine: boolean;
    handleTemplateClick: (id: string) => {};
}
function TempItem(props: ITState) {
    const { tempItemData = {}, handleTemplateClick, selfDefine = false } = props;
    return (
        <div className="temp-item" onClick={() => handleTemplateClick(tempItemData.id)}>
            <img
                src={changeEgovUrl(tempItemData.cover_img_url)}
                alt={tempItemData.title}
                className="temp-item-img"
            />
            {selfDefine && <div className="temp-item-title">{tempItemData.title}</div>}
            <div className="temp-item-name">使用该模版</div>
        </div>
    );
}

interface TState {
    tempData: any;
    selfDefine?: boolean;
    handleTemplateClick: Function;
}
export function TempContent(props: TState) {
    const { tempData = [], handleTemplateClick, selfDefine = false } = props;
    const length = tempData.length;
    let allTempList: any[] = [];
    tempData.forEach((item: any) => {
        if (item.templateList) {
            allTempList = [...allTempList, ...item.templateList];
        }
    });
    return (
        <>
            {length >= 0 && (
                <Tabs tabPosition="left" defaultActiveKey="all" className="temp-nav-left">
                    <TabPane className="temp-item-pane" tab="全部模版" key="all">
                        <div
                            className="temp-item"
                            key="all-add"
                            onClick={() => handleTemplateClick('')}
                        >
                            <div className="temp-item-img add-new">+</div>
                            <div className="temp-item-name add-new-name">创建空白文档</div>
                            <div className="temp-item-name add-new-hover">创建空白文档</div>
                        </div>
                        {allTempList.map((temp: any, index: number) => {
                            return (
                                <TempItem
                                    selfDefine={selfDefine}
                                    key={temp + index}
                                    tempItemData={temp}
                                    handleTemplateClick={() => handleTemplateClick(temp.id)}
                                />
                            );
                        })}
                    </TabPane>
                    {tempData.length > 1 &&
                        tempData.map((item: any) => {
                            return (
                                <TabPane className="temp-item-pane" tab={item.name} key={item.id}>
                                    {item.templateList &&
                                        item.templateList.map((temp: any, index: number) => {
                                            return (
                                                <TempItem
                                                    key={temp + index}
                                                    tempItemData={temp}
                                                    selfDefine={selfDefine}
                                                    handleTemplateClick={() =>
                                                        handleTemplateClick(temp.id)
                                                    }
                                                />
                                            );
                                        })}
                                </TabPane>
                            );
                        })}
                </Tabs>
            )}
            {/* {length === 0 && (
                <div className="no-data">
                    <Empty description="暂无模版" imageStyle={style} />
                </div>
            )} */}
        </>
    );
}

interface IProps {
    globalContext: GlobalContextConfig;
    onClose?: () => void;
    callback: (id: string) => void;
    pageType: number;
    focusToken?: String;
}
interface IState {
    selectTemplateId: string;
    selectTemplate: Template | null;
    categories: null;
    visible: boolean;
    pageType: number;
    init: boolean;
    isloading: boolean;
}

export function CreateByTemplate(props: IProps) {
    const { onClose, callback } = props;
    const [state, setState] = useState<IState>({
        visible: true,
        selectTemplateId: '',
        categories: null,
        pageType: props.pageType || 1,
        selectTemplate: null,
        init: false,
        isloading: true,
    });
    useEffect(() => {
        if (state.init) {
            return;
        }
        setState((s: IState) => ({
            ...s,
            isloading: true,
        }));
        getTemplateCategories(state.pageType)
            .then((data) => {
                if (data) {
                    setState((s: IState) => ({
                        ...s,
                        categories: data,
                        init: true,
                        isloading: false,
                    }));
                }
            })
            .catch(() => {
                const data = {
                    selfDefine: [],
                    stander: [],
                };
                // @ts-ignore
                setState((s: IState) => ({
                    ...s,
                    categories: data,
                    isloading: false,
                }));
            });
    }, [state.init, state.pageType]);
    const handleTemplateClick = (id: string) => {
        setState((s: IState) => ({
            ...s,
            visible: false,
        }));
        callback?.(id);
    };
    const handleCancel = () => {
        setState((s: IState) => ({
            ...s,
            visible: false,
        }));
        onClose?.();
    };
    const { categories, visible, isloading } = state;
    return (
        <Modal
            visible={visible}
            className="joyspace-create-template-modal"
            width={760}
            onCancel={handleCancel}
            cancelText="取消"
            title="选择模版"
            footer={null}
        >
            {isloading && (
                <div className="loading">
                    <Loading />
                </div>
            )}
            {!isloading && categories && (
                <Tabs defaultActiveKey={TempType.stander} className="template-list-nav">
                    <TabPane className="template-list-tab-pane" tab="模版库" key={TempType.stander}>
                        <TempContent
                            handleTemplateClick={handleTemplateClick}
                            tempData={(categories as any)[TempType.stander]}
                        />
                    </TabPane>
                    <TabPane
                        className="template-list-tab-pane"
                        tab="自定义模版"
                        key={TempType.selfDefine}
                    >
                        <TempContent
                            handleTemplateClick={handleTemplateClick}
                            selfDefine={true}
                            tempData={(categories as any)[TempType.selfDefine]}
                        />
                    </TabPane>
                </Tabs>
            )}
        </Modal>
    );
}

export default function openTemplateModal(props: IProps) {
    const { globalContext } = props;
    const { authInfo } = globalContext;
    // service.authService.exchangeWebToken().then((res) => {
    const focusToken = authInfo?.accessToken;
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    function onClose() {
        if (ele) {
            ReactDOM.unmountComponentAtNode(ele);
            ele.remove();
        }
    }
    ReactDOM.render(<CreateByTemplate {...{ ...props, focusToken, onClose }} />, ele);
    // });
}
