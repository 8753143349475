// focus 主服务 快速创建用户 创建团队
import BaseService from './BaseService';
import {
    ApprovaTeamUserInfo,
    InviteUserInfo,
    SendJoinApproveRes,
    CreateTeamAndEmployee,
} from '@/types/LoginPage';

export type ApprovaTeamUserInfoList = ApprovaTeamUserInfo[];

class FocusService extends BaseService {
    token = '';
    createUser(params: {
        mobile: string;
        userName: string;
        authCode: string;
        countryCode?: string;
    }) {
        return this.fetch('focus', 'createUser', {
            ...params,
        });
    }

    setToken(token: string) {
        this.token = token;
    }

    customHeaders() {
        return {
            'x-token': this.token,
        };
    }

    getApproveStatus() {
        return this.fetch<ApprovaTeamUserInfoList>(
            'focus',
            'getApproveStatus',
            {},
            { headers: this.customHeaders() }
        );
    }

    getInviteUserByTeamCode(params: { teamCode: string }) {
        return this.fetch<InviteUserInfo>(
            'focus',
            'getInviteUserByTeamCode',
            {
                ...params,
            },
            {
                headers: this.customHeaders(),
                ignoreErrMessageAlert: true,
            }
        );
    }

    sendJoinApprove(params: { inviteTeamId: string; inviteUserId: string }) {
        return this.fetch<SendJoinApproveRes>(
            'focus',
            'sendJoinApprove',
            {
                ...params,
            },
            { headers: this.customHeaders() }
        );
    }

    createTeamAndEmployee(params: {
        mobile: string;
        userTeamName: string;
        teamName: string;
        countryCode: string;
    }) {
        return this.fetch<CreateTeamAndEmployee>(
            'focus',
            'createTeamAndEmployee',
            {
                ...params,
            },
            { headers: this.customHeaders() }
        );
    }

    commonConfig(params?: any) {
        return this.fetch<{ focus_public_key: string }>('focus', 'commonConfig', params);
    }
}

export default FocusService;
