import React, { useEffect, useReducer, useCallback } from 'react';
import { Avatar, Empty, Select, Tooltip } from 'antd';
import { StateActionNames } from './types';
import { useTranslation } from '@/components/Joyspace/context';
import IconFont from '@/components/icon';
import { getJoySpaceReadWritePermission } from '../../Joyspace/config/permissions.config';
import { changeEgovUrl } from '@/utils/tools';
import PermissionSelect from '@/components/Joyspace/components/PermissionSelect';
const { Option } = Select;

export default function PageList(props: any) {
    const t = useTranslation();
    const [state, dispatch] = props.value;
    // 修改所有权限
    // const editPermissionAll = useCallback(
    //     (value: any, index: any) => {
    //         const tmpList: any = [...userlist];
    //         for (let item of tmpList) {
    //             item.permissionType = value;
    //         }
    //         setUserlist(tmpList);
    //     },
    //     [userlist]
    // );
    // 单次修改权限
    // const editPermission = useCallback(
    //     (value: any, index: any) => {
    //         const tmpList: any = [...userlist];
    //         tmpList[index].permissionType = value;
    //         setUserlist(tmpList);
    //     },
    //     [userlist]
    // );
    const getPermissionMembers = (item: any) => {
        return item.permissions.some(
            (_item: any) => _item === 'PAGE_EDIT' || item === 'FOLDER_EDIT'
        );
    };
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="selected-files-total">
                    <span className="selected-files-total-label">{t('selector.total')}</span>
                    <span className="selected-files-total-number">
                        {state.selectedItems.length}
                    </span>
                </div>
                <div>
                    {/* onChange={editPermissionAll} */}
                    {/* <Select
                        size="small"
                        defaultValue="权限统一设置"
                        onChange={(values) => {
                            dispatch({
                                action: StateActionNames.EDIT_PERMISSIONTYPE_ALL,
                                permissionType: values,
                            });
                        }}
                    >
                        {getTeamWorkMenus().map((item, index) => {
                            return (
                                <Option key={index} value={item.permissions}>
                                    {item.title}
                                </Option>
                            );
                        })}
                    </Select> */}
                </div>
            </div>
            <div style={{ overflowY: 'auto', height: 'calc(70vh - 190px)' }}>
                {state.selectedItems.map((item: any) => {
                    return (
                        <div className="page-item" key={item.id}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                }}
                            >
                                <Tooltip title={t('common.remove')} placement="top">
                                    <IconFont
                                        key="remove selected"
                                        className="iconapp_btn_alert_cancel"
                                        type="iconapp_btn_alert_cancel"
                                        onClick={() => {
                                            dispatch({
                                                action: StateActionNames.TOGGLE_SELECTED,
                                                item,
                                            });
                                        }}
                                    />
                                </Tooltip>
                                {/* <Avatar
                                className="page-item-img"
                                size={40}
                                shape="square"
                                src={changeEgovUrl(item.iconUrl)}
                            /> */}
                                <img
                                    className="page-item-img"
                                    src={changeEgovUrl(item.iconUrl)}
                                    alt=""
                                />
                                <h4 className="page-item-title">{item.title}</h4>
                            </div>
                            {getPermissionMembers(item) ? (
                                // <Select
                                //     size="small"
                                //     value={item.permissionType}
                                //     onChange={(values) => {
                                //         dispatch({
                                //             action: StateActionNames.EDIT_PERMISSIONTYPE,
                                //             item,
                                //             permissionType: values,
                                //         });
                                //     }}
                                // >
                                //     {getTeamWorkMenus().map((_item, _index) => {
                                //         return (
                                //             <Option key={_index} value={_item.permissions}>
                                //                 {_item.title}
                                //             </Option>
                                //         );
                                //     })}
                                // </Select>
                                <PermissionSelect
                                    onChange={(values: any) => {
                                        dispatch({
                                            action: StateActionNames.EDIT_PERMISSIONTYPE,
                                            item,
                                            permissionType: values,
                                        });
                                    }}
                                    permissionType={getJoySpaceReadWritePermission()}
                                    propsValue={item.permissionType}
                                />
                            ) : (
                                <span style={{ paddingRight: 28, whiteSpace: 'nowrap' }}>
                                    仅阅读
                                </span>
                            )}
                        </div>
                    );
                })}
                {state.selectedItems.length === 0 && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no data')} />
                )}
            </div>
        </div>
    );
}
