import React, { useState, useEffect, useContext } from 'react';
import { Radio, Input, message, List, Form, Checkbox } from 'antd';
import './index.less';

enum RoomType {
    MAIN = 'MAIN',
    BACKUP = 'BACKUP',
}

export interface NPCRoom {
    type: RoomType | null;
    code: string;
    pwd: string;
}

export default function NPCList({ onChange }: { onChange: Function }) {
    const [formDate, setFormDate] = useState<NPCRoom>({
        type: null,
        code: '',
        pwd: '',
    });
    const options = [
        { label: '主用', value: RoomType.MAIN },
        { label: '备用', value: RoomType.BACKUP },
    ];
    const onValueChange = (type: string, e: any) => {
        setFormDate({
            ...formDate,
            [type]: e.target.value,
        });
        onChange({
            ...formDate,
            [type]: e.target.value,
        });
    };

    return (
        <div className="cloudlink-npc-select">
            <Form>
                <Form.Item>
                    <Radio.Group
                        options={options}
                        onChange={onValueChange.bind(null, 'type')}
                        value={formDate.type}
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        maxLength={20}
                        value={formDate.code}
                        onChange={onValueChange.bind(null, 'code')}
                        placeholder="会议号"
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        maxLength={10}
                        value={formDate.pwd}
                        onChange={onValueChange.bind(null, 'pwd')}
                        placeholder="密码"
                    />
                </Form.Item>
            </Form>
        </div>
    );
}
