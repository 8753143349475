import React, { useCallback, useState } from 'react';
import { EventEmitter } from 'events';
import { Modals } from '@jd/focus-desktop-comps';
import { CommonModalCbType, CommonModalProps } from '@jd/focus-desktop-comps/lib/es/CommonModal';
import Tabs from './Tabs';
import BrowserViewBody from './Body';
import ChatMain from '@/baseComponents/Chat/view/Main';
import WebViewModal from '../WebViewModal';
import { changeEgovUrl } from '@/utils/tools';
import { gatewayInstance } from '@/server/gateway';
import { SessionType, UID } from '@/types/chat';
import cookies from 'js-cookie';

import logger from '@/utils/logger';

import {
    BrowserViewContext,
    BrowserViewItem,
    createBrowserViewItems,
    PageMetaData,
    BrowserViewConfigData,
    DefaultPageConfig,
} from './utils';
import './index.less';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { Button, Drawer } from 'antd';
import CalendarImService, { CalendarImServiceType } from '../Calendars/services/CalendarImService';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { getGroupInfo } from '@/api/egroup';
import { connect } from 'dvajs';
import { analysisLog } from '@/utils/logAnalytics';
import { debug } from 'console';
import { FocusSDK } from '@/utils';

const log = logger.getLogger('BrowserView');

// const notifyer = new EventEmitter();
function BrowserView({
    configData,
    defaultPage,
    notifier,
    onTabChange,
    launchGroupSession,
    launchSingleSession,
    callback,
}: {
    configData: BrowserViewConfigData;
    defaultPage?: DefaultPageConfig;
    notifier: EventEmitter;
    onTabChange?: (selectedId: string) => void;
    launchGroupSession: Function;
    launchSingleSession: Function;
    callback: Function;
}) {
    const { appRuntimeEnv, focusSDK } = React.useContext<GlobalContextConfig>(GlobalContext);
    const [items, setItems] = React.useState<BrowserViewItem[]>(
        createBrowserViewItems(configData.urls)
    );
    const [webviewVisible, setWebViewVisible] = useState(false);
    const [ChatviewVisible, setChatViewVisible] = useState(false);
    const [webviewModalData, setWebViewModalData] = useState<any>(null);
    const itemsRef = React.useRef<BrowserViewItem[]>([]);
    // const calendarImService: CalendarImServiceType | null = CalendarImService.getInstance();
    itemsRef.current = items;
    const [selectedId, setSelectedId] = React.useState(
        // eslint-disable-next-line no-nested-ternary
        defaultPage ? defaultPage.id : items[0] ? items[0].id : ''
    );
    // 获取打开窗口的id值,并发送通知
    const getOpenLinks = React.useCallback(
        (list: BrowserViewItem[]) => {
            notifier.emit('getOpenLinks', [...list]);
        },
        [notifier]
    );
    const onTabChangeCb = React.useCallback(
        (id) => {
            setSelectedId(id);
            if (onTabChange) {
                onTabChange(id);
            }
        },
        [onTabChange]
    );
    const onItemCloseCb = React.useCallback(
        (id) => {
            const idx = items.findIndex((item) => item.id === id);
            if (idx < 0) {
                return;
            }
            const currentItem = items[idx] as BrowserViewItem;
            currentItem.isThirdApp &&
                analysisLog(
                    'xtbg_workbench_1',
                    '2',
                    currentItem.appKey || '',
                    currentItem?.name || ''
                );
            if (items.length === 1) {
                if (!defaultPage) {
                    notifier.emit('closeWindow');
                } else {
                    setItems([]);
                    getOpenLinks([]);
                    setTimeout(() => {
                        setSelectedId(defaultPage.id);
                    }, 0);
                }
                return;
            }
            let selItem = items[idx - 1];
            if (!selItem) {
                selItem = items[idx + 1];
            }
            if (!selItem) {
                return;
            }
            const list = [...items.slice(0, idx), ...items.slice(idx + 1)];
            setItems(list);
            getOpenLinks(list);
            setTimeout(() => {
                setSelectedId(selItem.id);
            }, 0);
        },
        [defaultPage, items, notifier, getOpenLinks]
    );
    const onNewWindowOpen = React.useCallback(
        (url: string, appKey?: string, version?: string) => {
            const newItems = createBrowserViewItems([url], appKey || undefined, version);
            const list = [...itemsRef.current, ...newItems];
            setItems(list);
            setSelectedId(newItems[0].id);
            getOpenLinks(list);
        },
        [getOpenLinks]
    );

    const onPageMetaDataChanged = React.useCallback(
        (data: PageMetaData) => {
            const { title, favicon, id } = data;
            const pages = itemsRef.current;
            const idx = pages.findIndex((item) => item.id === id);
            if (idx < 0) {
                return;
            }

            const item = pages[idx];
            const newItem = { ...item };
            if (!item.isThirdApp && title) {
                newItem.name = title;
            }
            if (favicon) {
                newItem.icon = favicon;
            }
            const list = [...pages.slice(0, idx), newItem, ...pages.slice(idx + 1)];
            setItems(list);
            getOpenLinks(list);
        },
        [getOpenLinks]
    );

    const onClientMessage = React.useCallback(
        (data: any) => {
            log.debug('clientMessage', { data });
            const { event, payload } = data;
            switch (event) {
                case 'openNewWindow': {
                    const {
                        urls,
                        name,
                        phone,
                        company,
                        appKey,
                        trustedDomains,
                        version,
                        isThirdApp,
                    } = payload;
                    if (!Array.isArray(urls) || !urls.length) {
                        return;
                    }
                    const newItems = createBrowserViewItems(urls, appKey);
                    const _newItems = [
                        {
                            ...newItems[0],
                            name,
                            phone,
                            company,
                            trustedDomains,
                            version,
                            isThirdApp,
                        },
                    ];
                    let list: any = null;
                    let activeIdx = -1;
                    try {
                        if (_newItems[0].link.startsWith('http')) {
                            const urlObj = new URL(_newItems[0].link);
                            if (urlObj.pathname.includes('/app_store')) {
                                const idx = itemsRef.current.findIndex((item) =>
                                    item.link?.split('?')[0]?.includes('/app_store')
                                );
                                if (idx !== -1) {
                                    // eslint-disable-next-line no-const-assign
                                    list = [...itemsRef.current.splice(idx, 1, _newItems[0])];
                                    activeIdx = idx;
                                }
                            }
                        }
                    } catch (error) {}
                    if (activeIdx === -1) {
                        list = [...itemsRef.current, ..._newItems];
                    } else {
                        list = itemsRef.current;
                    }
                    setItems(list);
                    setSelectedId(list[activeIdx === -1 ? list.length - 1 : activeIdx].id);
                    getOpenLinks(list);
                    break;
                }
                default:
            }
        },
        [getOpenLinks]
    );
    // 打开已打开的应用
    const onOpenApp = React.useCallback((id) => setSelectedId(id), []);
    React.useEffect(() => {
        notifier.on('onOpenApp', onOpenApp);
        notifier.on('clientMessage', onClientMessage);
        return () => {
            notifier.off('onOpenApp', onOpenApp);
            notifier.off('clientMessage', onClientMessage);
        };
    }, [notifier, onClientMessage, onOpenApp]);

    React.useEffect(() => {
        if (onTabChange && defaultPage) {
            onTabChange(selectedId);
        }
    }, [onTabChange, defaultPage, selectedId]);
    function commonModalOnOk(data: CommonModalProps) {
        if (data && data.onOkType === CommonModalCbType.MainWindow) {
            const urls = [data.externalUrl || ''];
            if (!Array.isArray(urls) || !urls.length) {
                return;
            }
            const newItems = createBrowserViewItems(urls);
            const list = [...itemsRef.current, ...newItems];
            setItems(list);
            setSelectedId(newItems[newItems.length - 1].id);
            getOpenLinks(list);
        }
        if (data && data.onOkType === CommonModalCbType.NewWindow) {
            if (data.externalUrl) {
                // window.open(data.externalUrl || '');
                focusSDK.openUrl(data.externalUrl);
            }
        }
    }
    const handleLoadChat = useCallback(
        async (data) => {
            // 判断群是否已解散
            const groupInfo = await getGroupInfo({
                groupIds: [{ groupId: data.gid }],
            });

            const isDisband = groupInfo.groups[0].status !== 1;
            const isOutOfGroup = groupInfo.groups[0].flag !== 3;
            if (isDisband) {
                setChatViewVisible(false);
                return;
            } else {
                console.log('openWebViewModal', data, isDisband);
                await launchGroupSession({
                    sid: data.gid,
                });
                setChatViewVisible(true);
                return;
            }
        },
        [launchGroupSession]
    );
    const handleSingleChat = useCallback(
        async (data) => {
            const userInfo: any = await gatewayInstance.request({
                api: 'eopen.batchExchangeUser',
                method: 'POST',
                body: {
                    appKey: data.appKey,
                    openUserTeamIds: [
                        {
                            openUserId: data.openUserId,
                            openTeamId: data.openTeamId,
                        },
                    ],
                },
            });
            let userId = null;
            let teamId = null;
            const userTeamIds = userInfo.userTeamIds;
            const userTeamId = userTeamIds.length > 0 && userTeamIds[0];
            userId = userTeamId?.userId;
            teamId = userTeamId?.teamId;
            const opts = {
                user: {
                    app: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
                    pin: userId,
                    teamId: teamId,
                },
                type: SessionType.SINGLE,
            };
            await launchSingleSession(opts);
            setChatViewVisible(true);
            return;
        },
        [launchSingleSession]
    );
    return (
        <BrowserViewContext.Provider
            value={{
                registryConfigData: appRuntimeEnv.registryConfig,
                configData,
                onNewWindowOpen,
                onWindowClose: onItemCloseCb,
                items,
                selectedId,
                onPageMetaDataChanged,
                defaultPage,
                notifier,
                webviewModalVisible: webviewVisible,
                openWebViewModal: (data: any) => {
                    if (data) {
                        setWebViewModalData(data);
                        setWebViewVisible(true);
                    }
                },
                closeWebViewModal: (id: string) => {
                    onItemCloseCb(id);
                    setWebViewVisible(false);
                    setWebViewModalData(null);
                },
                openCommonModal: (data: any) => {
                    const d = JSON.parse(data) as CommonModalProps;
                    const { destroy } = Modals.CommonModal({
                        ...d,
                        onCancel: () => {
                            destroy();
                        },
                        onOk: () => {
                            destroy();
                            commonModalOnOk(d);
                        },
                    });
                },
                openExternal: (data: any) => {
                    if (data.url) {
                        window.open(data.url || '');
                    }
                },
                viewImages: (data: string[]) => {
                    // ImageViewer.viewImages(data);
                    log.debug(data);
                },
                openGroupChat: (data: any) => {
                    handleLoadChat(data);
                },
                openSingleChat: (data: any) => {
                    handleSingleChat(data);
                },
            }}
        >
            <Tabs onTabChange={onTabChangeCb} />
            <BrowserViewBody />
            <WebViewModal
                visible={webviewVisible}
                src={changeEgovUrl(webviewModalData?.src)}
                styles={webviewModalData?.styles}
                title={webviewModalData?.title}
                closeable={webviewModalData?.closeable}
            />
            <Drawer // 聊天抽屉
                title={null}
                width={543}
                className="task-chat-drawer"
                // mask={false}
                // closable={false}
                onClose={() => {
                    setChatViewVisible(false);
                }}
                visible={ChatviewVisible}
            >
                {ChatviewVisible && <ChatMain hideSetting={true} singleLine={true} />}
                {ChatviewVisible && (
                    <Button
                        className="unfold-btn"
                        onClick={() => {
                            setChatViewVisible(false);
                        }}
                    >
                        <CaretRightOutlined className="unfold-icon" />
                    </Button>
                )}
            </Drawer>
        </BrowserViewContext.Provider>
    );
}
function mapStateToProps({ work, user, chat }: any) {
    const { detailState } = work;
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {
        launchGroupSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchGroupSession', payload: data });
        },
        launchSingleSession(data: { user: UID; top?: boolean; type: SessionType }) {
            dispatch({ type: 'chat/launchSingleSession', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrowserView);
