import React, { useEffect, useCallback, useMemo, useState } from 'react';
import './index.less';

import TaskListView from '../TaskListView';

function TeamWorkView({
    projectId,
    soureceName,
    archive,
}: {
    projectId: string;
    soureceName: string;
    archive: boolean;
}) {
    return (
        <div className="work-team-view">
            <TaskListView
                projectId={projectId}
                soureceName={soureceName}
                disableTitle={true}
                key={projectId}
                disableCreate={archive}
                disableDrag={archive}
            />
        </div>
    );
}

export default TeamWorkView;
