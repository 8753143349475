import React, { useMemo, useCallback, useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import './index.less';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import { getRealColor } from '@/components/Calendars/utils';
import { ScheduleDefaultColor } from '@/components/Calendars/utils/constant';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { CalendarInfo, ScheduleRelated, ScheduleRelatedType } from '@/api/calendar';
dayjs.extend(isSameOrBefore);

const { TabPane } = Tabs;

function getDateScopeList(current: Dayjs, count: number): Dayjs[] {
    // 获取三个月的数据
    let dateList = [];
    let endTime = current.endOf('month').add(count, 'month');

    let i = 0;

    for (let time = current; time.isSameOrBefore(endTime); i++) {
        dateList[i] = time;
        time = time.add(1, 'day');
    }
    return dateList;
}

// 月 周 日

interface IProps {
    scheduleMap: any;
    selectedTime: Dayjs;
    calendarMap: any;
    myCalendar: CalendarInfo;
    otherUser: any;
    setSelectedTime: (data: any) => void;
}
interface State {
    dateScopeList: Dayjs[];
}
interface IScheduleDay {
    day: Dayjs;
    listFull: ScheduleRelated[];
    listMe: ScheduleRelated[];
}
type Props = Readonly<IProps & WithTranslation>;

export default function ScheduleList({
    selectedTime,
    scheduleMap,
    calendarInfo,
    onItemClick,
}: {
    selectedTime: Dayjs;
    scheduleMap: { [key: string]: ScheduleRelated[] };
    calendarInfo: CalendarInfo;
    onItemClick: (schedule: ScheduleRelated) => void;
}) {
    const [t] = useTranslation('calendar');
    const dateScopeList = useMemo(() => {
        return getDateScopeList(selectedTime || dayjs(), 4);
    }, [selectedTime]);

    const [countFullList, setCountFull] = useState(0);
    const [countMeList, setCountMe] = useState(0);
    const [dataList, setDataList] = useState<IScheduleDay[]>([]);

    useEffect(() => {
        /**
         * 数据整理 —— 遍历每一天，过滤出可用数据
         */
        const dateListFiltered = dateScopeList.filter((day: Dayjs, index: number) => {
            const list = scheduleMap[day.format('YYYY-MM-DD')] || [];
            const isCurrent = day.isSame(selectedTime, 'day');
            if (!isCurrent && list.length === 0) {
                return false;
            }
            return true;
        });
        let tempCounter = {
            full: 0,
            me: 0,
        };

        /**
         * 数据整理 —— 包裹并统计数据
         */
        const dataAll = dateListFiltered.map((day: Dayjs, index: number) => {
            const listFull = scheduleMap[day.format('YYYY-MM-DD')] || [];
            tempCounter.full += listFull.length;
            const listMe = listFull.filter((listItem) => {
                // 日程与登录用户关系 1：我创建的 2：我参与的 3：我订阅的
                return (
                    listItem.relateType === ScheduleRelatedType.Created ||
                    listItem.relateType === ScheduleRelatedType.Participate
                );
            });
            tempCounter.me += listMe.length;
            return {
                day,
                listFull,
                listMe,
            };
        });
        setCountFull(tempCounter.full);
        setCountMe(tempCounter.me);
        setDataList(dataAll);
    }, [dateScopeList, scheduleMap, selectedTime]);

    /**
     * DOM
     * @param isFull 是否为全部数据
     */
    function domList(isFull = true) {
        return dataList.map((item: IScheduleDay, index: number) => {
            const day = item.day;
            let list = isFull ? item.listFull : item.listMe;
            const isCurrent = day.isSame(selectedTime, 'day');
            return (
                <div
                    className={`project-calendar-list-day ${isCurrent ? 'day-current' : ''}`}
                    key={index}
                >
                    <div className="day-header">{day.format('MM-DD')}</div>
                    {list.length === 0 && (
                        <div className="project-calendar-list-day-item-null">
                            {t('no relative schedule')}
                        </div>
                    )}
                    {list.length > 0 &&
                        list.map((schedule) => {
                            return (
                                <div
                                    className="project-calendar-list-day-item"
                                    key={schedule.scheduleId}
                                    onClick={() => {
                                        onItemClick(schedule);
                                    }}
                                >
                                    <div className="schedule-item-wrapper df">
                                        <p
                                            className="indicator"
                                            style={{
                                                // 默认显示日历颜色，没有时显示我的日历的日历颜色
                                                backgroundColor: calendarInfo.color
                                                    ? getRealColor(calendarInfo.color)
                                                    : `#${ScheduleDefaultColor}`,
                                            }}
                                        />
                                        <div className="content">
                                            <p className="title ellipsis">{schedule?.title}</p>
                                            <p className="info df">
                                                <span className="s1">
                                                    {dayjs(schedule.beginTime).format('HH:mm')}-
                                                    {dayjs(schedule.endTime).format('HH:mm')}
                                                </span>
                                            </p>
                                            <p className="address info ellipsis df">
                                                <span className="s1">
                                                    {t('address')}:{schedule?.address}
                                                </span>
                                                <span className="s2">
                                                    {t('from')}：{schedule.user?.realName}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            );
        });
    }

    return (
        <Tabs className="project-calendar-list-wrapper" defaultActiveKey="1">
            <TabPane tab={`${t('All')}(${countFullList})`} key="1">
                <div className="project-calendar-list">{domList()}</div>
            </TabPane>
            <TabPane tab={`${t('Related to me')}(${countMeList})`} key="2">
                <div className="project-calendar-list">{domList(false)}</div>
            </TabPane>
        </Tabs>
    );
}
