/* 详情页在使用 */
import React, { useState, useRef, useEffect, useContext } from 'react';
import './index.less';
import { message, Dropdown, Menu, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAuthInfo, getAppId, isFocusEnv, FocusSDK } from '@/utils';
import FileService from '@jd/jdee-fs-desktop';
import { lookup } from 'mime-types';
import { FileStatus } from '@/components/Calendars/Attachment';
import { getFileIconByMime, getFileUrl, downLoadFile } from '@/utils/chat/index';
import { TaskResourceBizType } from '@/types/work';
import { CalendarIcon } from '@/components/Calendars/Common';
import { searchDeletePng } from '@/assets/img/calendar';
import GlobalContext from '@/context/GlobalContext';
import { nanoid } from 'nanoid';
import { changeEgovUrl } from '@/utils/tools';
import {
    buildPagePathKey,
    buildJoyspaceIconUrl,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import { PageType } from '@/components/Joyspace/const';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import IconFont from '@/components/icon';
import { createWPSWord } from '@/components/Joyspace/components/CreateMenu/createWPSWord';
import createDoc, {
    createDocByTemplate,
} from '@/components/Joyspace/components/CreateMenu/createDoc';
import { createWPSExcel } from '@/components/Joyspace/components/CreateMenu/createWPSExcel';
import { ICurrentRouteInfo } from '@/components/Joyspace/types';
import bus from '@/utils/bus';
import { createUploadTask } from '@/server/FileService';
import { Excel_3xImg, wordImg, Word_3xImg } from '@/utils/joyspace';
import { duccConfigI18 } from '@/utils/i18n';

const LIMIT_SIZE = 300 * 1024 * 1024;
const {
    REACT_APP_FILE_SERVICE_APPKEY,
    REACT_APP_FILE_SERVICE_HOST,
    REACT_APP_JANUS_HOST,
} = process.env;

function WorkAttachmentComp({
    disabled,
    fileList,
    onFinish,
    onRemove,
    mode,
    isHead,
}: {
    disabled: boolean;
    fileList: any[];
    onFinish: (file: any) => Promise<null | { resourceId: string } | any>;
    onRemove: (resourceIds: string[]) => Promise<boolean>;
    mode?: string;
    isHead?: boolean;
}) {
    const { t } = useTranslation('work');
    const [currentFileList, setCurrentFileList] = useState<any[]>(fileList);
    const queueRef = useRef<{ list: any[]; runing: boolean }>({ list: [], runing: false });
    const { appRuntimeEnv, focusSDK } = useContext(GlobalContext);
    const { fileService, gateway, joyspaceApiHost } = appRuntimeEnv;
    const { appKey, hostOrigin } = fileService;
    const { host, stage, protocol } = gateway;
    const currentRouteInfo: ICurrentRouteInfo = {
        categoryId: '',
        spaceId: '',
        teamId: '',
    };
    useEffect(() => {
        setCurrentFileList((list) => {
            const newList: any[] = list.filter((file: any) =>
                [FileStatus.Uploading, FileStatus.Init].includes(file.status)
            );
            return fileList.concat(newList);
        });
    }, [fileList]);
    useEffect(() => {
        function handle() {
            if (mode === 'create') {
                currentFileList.forEach((file: any) => {
                    if (file.status === FileStatus.Init || file.status === FileStatus.Uploading) {
                        file.taskInstance?.cancel();
                    }
                });
            } else {
                const target = currentFileList.find((item: any) =>
                    [FileStatus.Uploading, FileStatus.Init].includes(item.status)
                );
                // console.log(target, '查找是否有附件正在上传');
                if (target) {
                    // console.log('附件正在上传，不可关闭');
                    bus.emit('cancle-attachment-upload', true);
                } else {
                    // console.log('没有附件正在上传，可关闭');
                    bus.emit('cancle-attachment-upload', false);
                }
            }
        }
        bus.on(`close-task-detail`, handle);
        return () => {
            bus.off(`close-task-detail`, handle);
        };
    }, [currentFileList, mode]);
    useEffect(() => {
        async function handleCreateDocument(res: any) {
            const { joyspaceHost } = appRuntimeEnv;
            const path = buildPagePathKey(res.page_type).subPath;
            const link = `${joyspaceHost}/${path}/${res.id}`;
            const { title, page_type, type } = res;
            const file = {
                fileName: res.title || t('unname'),
                size: 0,
                fileSize: 0,
                fileType: `joyspace/${res.page_type}/${res.type}`,
                fileId: res.id,
                fileUrl: `${link}?jdme_router=${encodeURIComponent(res.linkH5)}`,
                percent: 100,
                status: FileStatus.Done,
                taskInstance: null,
                // extended: JSON.stringify(res),
                bizType: TaskResourceBizType.JOYSPACE,
                icon: buildJoyspaceIconUrl({
                    title,
                    pageType: page_type.toString(),
                    type,
                    apiHost: joyspaceApiHost,
                }),
                resourceId: nanoid(),
                isRemove: true,
                permission: res.permissionType || null,
            };

            const rs = await onFinish([file]);
            setCurrentFileList((fileList: any[]) => {
                const newlist = rs.map((r: any) => ({
                    ...r,
                    percent: 0,
                    fileName: r.name,
                    size: r.size,
                    fileId: r.bizId || nanoid(20),
                    fileUrl: r.url,
                    status: FileStatus.Done,
                    creatorUserId: r?.creatorUserId,
                    isRemove: true,
                    permission: r.permissionType || null,
                }));
                return [...fileList, ...newlist];
            });
        }
        bus.on('joyspace:documentCreated', handleCreateDocument);

        return () => {
            bus.off('joyspace:documentCreated', handleCreateDocument);
        };
    }, [t, onFinish, appRuntimeEnv, joyspaceApiHost]);

    function handleDeleteQueue() {
        const { list, runing } = queueRef.current;
        if (list.length === 0) {
            return;
        }
        if (runing) {
            return;
        }

        queueRef.current = {
            list,
            runing: true,
        };
        const item: any = list[0];
        onRemove([item.fileId || item.bizId]).then((result) => {
            if (!result) {
                queueRef.current = {
                    list: [...queueRef.current.list].slice(1),
                    runing: false,
                };
                return handleDeleteQueue();
            }
            setCurrentFileList((list: any[]) => {
                let idx = list.findIndex((a: any) => a.fileId === item.fileId);
                if (idx >= 0) {
                    let newAttachment = [...list.slice(0, idx), ...list.slice(idx + 1)];
                    return newAttachment;
                }

                return list;
            });
            setTimeout(() => {
                const { list } = queueRef.current;
                queueRef.current = {
                    list: [...list].slice(1),
                    runing: false,
                };
                handleDeleteQueue();
            }, 50);
        });
    }
    function deleteConfirm(e: any, item: any) {
        item.taskInstance?.cancel();
        e.stopPropagation();
        Modal.confirm({
            title: t('tip delete'),
            content: <span className="nimble-con">{t('tip task delete Attachment')}</span>,
            okText: t('confirm'),
            cancelText: t('cancel'),
            className: 'task-common-modal-lb',
            onOk() {
                deleteAttachment(e, item);
            },
        });
    }
    async function deleteAttachment(e: any, item: any) {
        // 要取消文件上传
        // item.taskInstance?.cancel();
        // e.stopPropagation();
        if (item.status === FileStatus.Uploading || item.status === FileStatus.Init) {
            return setCurrentFileList((list: any[]) => {
                let idx = list.findIndex((a: any) => a.fileId === item.fileId);
                if (idx >= 0) {
                    let newAttachment = [...list.slice(0, idx), ...list.slice(idx + 1)];
                    return newAttachment;
                }
                return list;
            });
        }
        const { list, runing } = queueRef.current;
        const newList = [...list];
        if (newList.findIndex((l) => l.resourceId === item.resourceId) === -1) {
            newList.push(item);
        }
        queueRef.current = {
            list: newList,
            runing,
        };
        handleDeleteQueue();
    }
    async function uploadFile(file: any) {
        if (file.size > LIMIT_SIZE) {
            return message.warn(t('A single attachment cannot be greater than 300M'));
        }
        const authInfo = await getAuthInfo();
        // const fs = new FileService({
        //     appKey: appKey || '', // 文件服务appkey
        //     host: hostOrigin,
        //     janus: {
        //         host: `${protocol}://${host}`,
        //         appId: getAppId() || '', // janus网关appid
        //         // @ts-ignore
        //         stage: stage,
        //     },
        //     auth: {
        //         // 认证信息
        //         xToken: authInfo['x-token'], // 用户登录token
        //         xTeamId: authInfo['x-team-id'], // 用户所在teamId
        //         xClient: authInfo['x-client'], // 客户端类型
        //     },
        // });
        const blob: any = file.getAsFile ? file.getAsFile() : file;

        const task = createUploadTask('id', blob, {
            needAuthn: 1, // 是否需要下载健全，可选参数默认需要
            fileName: blob.name, // 文件名称
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream', // 文件类型
            fileSize: blob.size, // 文件大小
        });
        let fileObj: any = {
            fileName: blob.name,
            size: blob.size,
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream',
            fileId: blob.uid || nanoid(20),
            fileUrl: '',
            percent: 0,
            status: FileStatus.Init,
            taskInstance: task,
            icon: getFileIconByMime(blob.type || lookup(blob.name) || 'application/octet-stream'),
            isRemove: true,
        };
        setCurrentFileList((list: any[]) => {
            return [...list, fileObj];
        });

        task?.on('progress', (progress: any) => {
            let percent = progress.progress * 10;
            setCurrentFileList((list: any[]) => {
                let idx1 = list.findIndex((a: any) => a.fileId === fileObj.fileId);
                if (idx1 >= 0) {
                    let newFileList = [
                        ...list.slice(0, idx1),
                        {
                            ...list[idx1],
                            percent: percent,
                            status: FileStatus.Uploading,
                            isRemove: true,
                        },
                        ...list.slice(idx1 + 1),
                    ];
                    return newFileList;
                }
                return list;
            });
        });
        task?.on('state', async (state1: any) => {
            if (state1.state === 'completed') {
                // 处理上传完成状态变化
                const result = state1;
                fileObj.fileUrl = result.downloadUrl;

                const rs = await onFinish(fileObj);
                if (!rs) {
                    return setCurrentFileList((list: any[]) => {
                        let idx = list.findIndex((a: any) => a.fileId === fileObj.fileId);
                        if (idx >= 0) {
                            let newAttachment = [...list.slice(0, idx), ...list.slice(idx + 1)];
                            return newAttachment;
                        }
                        return list;
                    });
                }
                const { resourceId } = rs;
                setCurrentFileList((list: any[]) => {
                    let idx2 = list.findIndex((a: any) => a.fileId === fileObj.fileId);
                    if (idx2 >= 0) {
                        let newFileList = [
                            ...list.slice(0, idx2),
                            {
                                ...list[idx2],
                                percent: 0,
                                status: FileStatus.Done,
                                fileUrl: result.downloadUrl,
                                resourceId,
                                isRemove: true,
                            },
                            ...list.slice(idx2 + 1),
                        ];
                        return newFileList;
                    }
                    return list;
                });
            }
            if (state1.state === 'failed') {
                return setCurrentFileList((list: any[]) => {
                    let idx = list.findIndex((a: any) => a.fileId === fileObj.fileId);
                    if (idx >= 0) {
                        let newAttachment = [...list.slice(0, idx), ...list.slice(idx + 1)];
                        return newAttachment;
                    }
                    message.error(t('attachment upload failed'));
                    return list;
                });
            }
        });
        task?.start();
    }

    function handleBeforeUpload(file: any, FileList: any[]) {
        if (!file.type) {
            const reader = new FileReader();
            reader.onload = function () {
                uploadFile(file);
            };
            reader.onerror = function () {
                // it's a directory!
                message.warn(t('Upload folders are not supported'));
            };
            reader.readAsArrayBuffer(file.slice(0, 1));
            return false;
        }
        uploadFile(file);
        return false;
    }

    function downloadFile(item: any) {
        if (item.status === FileStatus.Uploading) {
            return;
        }
        if (item.fileType.startsWith('joyspace')) {
            let fileUrl = item.fileUrl;
            try {
                const { joyspaceHost } = appRuntimeEnv;
                const path = buildPagePathKey(Number(item.fileType.split('/')[1])).subPath;
                fileUrl = `${joyspaceHost}/${path}/${item.fileId}`;
            } catch (error) {
                console.error(error);
            }
            return DeeplinkEvent.open(changeEgovUrl(fileUrl));
        }
        if (isFocusEnv()) {
            FocusSDK.downloadFile(changeEgovUrl(item.fileUrl), {
                saveAs: true,
                filename: item.fileName,
                onStarted: () => {},
                onProgress: () => {},
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                },
                onFinish: (file: { path: string; filename: string }) => {},
            });
        } else {
            // web 端下载文件
            const name = item.fileName;
            const url = getFileUrl(item.fileUrl);
            downLoadFile(name, url);
        }
    }

    function handleFileClick() {
        const input = document.createElement('INPUT');
        input.setAttribute('type', 'file');
        // input.setAttribute('multiple', 'false');
        input.onchange = (ev: any) => {
            if (ev.target.files && ev.target.files.length > 0) {
                // console.log('本地文件', ev.target.files);
                handleBeforeUpload(ev.target.files[0], []);
            }
        };
        input.click();
    }

    function handleUploadJoyspace() {
        const { joyspaceHost } = appRuntimeEnv;
        const id = nanoid();
        const callback = async ({
            status,
            callbackId,
            results,
        }: {
            status: 'ok' | 'cancel';
            callbackId: string;
            results: {
                id: string;
                title: string;
                pageType: number;
                type: string;
                link: string;
                linkH5: string;
            }[];
        }) => {
            if (id !== callbackId) {
                return;
            }
            focusSDK.off('openJoyspaceDocSelector:result', callback);
            if (status === 'cancel') {
                return;
            }
            if (results.length === 0) {
                return;
            }
            const list = results.map((r) => {
                const path = buildPagePathKey(r.pageType).subPath;
                const link =
                    r.pageType === PageType.Form ? r.link : `${joyspaceHost}/${path}/${r.id}`;
                const { title, pageType, type } = r;
                return {
                    fileName: r.title || t('unname'),
                    size: 0,
                    fileSize: 0,
                    fileType: `joyspace/${r.pageType}/${r.type}`,
                    fileId: r.id,
                    fileUrl: `${link}?jdme_router=${encodeURIComponent(r.linkH5)}`,
                    percent: 100,
                    status: FileStatus.Done,
                    taskInstance: null,
                    // extended: JSON.stringify(r),
                    bizType: TaskResourceBizType.JOYSPACE,
                    icon: buildJoyspaceIconUrl({
                        title,
                        pageType: pageType.toString(),
                        type,
                        apiHost: joyspaceApiHost,
                    }),
                    resourceId: nanoid(),
                    isRemove: true,
                };
            });
            const rs = await onFinish(list);
            setCurrentFileList((fileList: any[]) => {
                const newlist = rs.map((r: any) => ({
                    ...r,
                    percent: 0,
                    fileName: r.name,
                    size: r.size,
                    fileId: r.bizId || nanoid(20),
                    fileUrl: r.url,
                    status: FileStatus.Done,
                    creatorUserId: r?.creatorUserId,
                    isRemove: true,
                }));
                return [...fileList, ...newlist];
            });
            // setCurrentFileList((fileList: any[]) => {
            //     let idx2 = fileList.findIndex((a: any) => a.fileId === fileObj.fileId);
            //     if (idx2 >= 0) {
            //         return fileList;
            //     }
            //     return [...fileList, { ...fileObj, resourceId }];
            // });
            // const tempList: any[] = [];
            // await Promise.all(
            //     list.map(async (fileObj) => {
            //         const rs = await onFinish(fileObj);
            //         if (!rs) {
            //             return;
            //         }
            //         const { resourceId } = rs;
            //         tempList.push({ ...fileObj, resourceId });
            //         // setCurrentFileList((fileList: any[]) => {
            //         //     let idx2 = fileList.findIndex((a: any) => a.fileId === fileObj.fileId);
            //         //     if (idx2 >= 0) {
            //         //         return;
            //         //     }
            //         //     //     ...fileList,
            //         //     //     { ...fileObj, resourceId },
            //         //     // ]);
            //         //     return [...fileList, { ...fileObj, resourceId }];
            //         // });
            //     })
            // );
            // setCurrentFileList((fileList: any[]) => {
            //     return [...fileList, ...tempList];
            // });
        };

        focusSDK.on('openJoyspaceDocSelector:result', callback);
        focusSDK.emit('openJoyspaceDocSelector', {
            callbackId: id,
            max: 10 - currentFileList.length,
        });
    }

    function getAttachmentIcon(item: any) {
        const { fileType, fileName } = item;
        if (fileType.startsWith('joyspace')) {
            try {
                const strs = fileType.split('/');
                if (strs[1] === '6') {
                    if (!isNaN(Number(strs[2]))) {
                        let ext = fileName.split('.').pop();
                        switch (ext) {
                            case 'doc':
                                return getFileIconByMime('application/msword')[0];
                            case 'xls':
                                return getFileIconByMime('application/vnd.ms-excel')[0];
                            default:
                                break;
                        }
                    }
                    return getFileIconByMime(
                        strs.slice(2).join('/') || 'application/octet-stream'
                    )[0];
                } else {
                    return buildJoyspaceIconUrl({
                        title: fileName,
                        pageType: strs[1],
                        type: strs.slice(2).join('/') || '',
                        apiHost: `${joyspaceApiHost}-api`,
                    });
                }
            } catch (error) {
                return getFileIconByMime(fileType || 'application/octet-stream')[0];
            }
        }
        return getFileIconByMime(fileType || 'application/octet-stream')[0];
    }
    const createDoc = createDocByTemplate({
        globalContext: useContext(GlobalContext),
    });
    return (
        <div className="attachment-container base-attachment-container">
            {/* <Dragger
                name="file"
                multiple={false}
                beforeUpload={handleBeforeUpload}
                showUploadList={false}
                disabled={disabled || currentFileList.length >= 10}
            >
                <div className="attachment-button">{t('add attachment')}</div>
            </Dragger> */}
            {/* 附件样式调整sxl  ---start */}
            {disabled ? null : (
                <div className="flex-box">
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item data-name="doc" onClick={() => createDoc()}>
                                    <div className="doc-menu">
                                        <img className="doc-img" src={changeEgovUrl(wordImg())} />
                                        <span>{t('joyspace document')}</span>
                                    </div>
                                </Menu.Item>
                                {
                                    // 通过ducc配置wpsTarget来控制 joyspaceWpsTarget
                                }
                                {/* {appRuntimeEnv?.joyspaceWpsTarget === 'private' && (
                                    <Menu.Item
                                        data-name="wpsdoc"
                                        onClick={() => createWPSWord(appRuntimeEnv)}
                                    >
                                        <div className="doc-menu">
                                            <img
                                                className="doc-img"
                                                src={changeEgovUrl(Word_3xImg())}
                                            />
                                            <span>{t('WPS document')}</span>
                                        </div>
                                    </Menu.Item>
                                )} */}
                                {/* {appRuntimeEnv?.joyspaceWpsTarget === 'private' && (
                                    <Menu.Item
                                        data-name="wpsspreadsheet"
                                        onClick={() => createWPSExcel(appRuntimeEnv)}
                                    >
                                        <div className="doc-menu">
                                            <img
                                                className="doc-img"
                                                src={changeEgovUrl(Excel_3xImg())}
                                            />
                                            <span>{t('WPS excel')}</span>
                                        </div>
                                    </Menu.Item>
                                )} */}
                            </Menu>
                        }
                        overlayStyle={{ minWidth: '120px', width: '120px' }}
                        disabled={disabled || currentFileList.length >= 10}
                        className={`${
                            disabled || currentFileList.length >= 10
                                ? 'attachment-item-disable'
                                : ''
                        }`}
                    >
                        <div
                            style={{ marginRight: 24 }}
                            className={`attachment-item ${
                                disabled ? 'attachment-item-disable' : ''
                            }`}
                        >
                            <div className="attachment-button">
                                <IconFont type="iconapp_btn_newfile" style={{ marginRight: 8 }} />
                                {t('create document')}
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item onClick={handleFileClick}>
                                    {t('Add Local File')}
                                </Menu.Item>
                                <Menu.Item onClick={handleUploadJoyspace}>
                                    {t('Add joyspace document', {
                                        joyspace: duccConfigI18.joyspace,
                                    })}
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{ minWidth: '120px', width: '120px' }}
                        disabled={disabled || currentFileList.length >= 10}
                    >
                        <div
                            className={`attachment-item ${
                                disabled || currentFileList.length >= 10
                                    ? 'attachment-item-disable'
                                    : ''
                            }`}
                        >
                            <div className="attachment-button">
                                <IconFont type="iconwin_link" style={{ marginRight: 8 }} />
                                {t('add attachment')}
                            </div>
                        </div>
                    </Dropdown>
                </div>
            )}
            {/* 附件样式调整sxl  ---end */}

            <div className="task-custom-upload-list">
                {currentFileList.map((item: any, index: number) => {
                    return (
                        <div
                            className="file-item of-detail-page"
                            key={item.resourceId || item.fileId}
                            onClick={() => downloadFile(item)}
                        >
                            <CalendarIcon
                                className="left"
                                width="auto"
                                src={changeEgovUrl(getAttachmentIcon(item))}
                            />
                            <span className="name ellipsis">{item.fileName}</span>
                            <IconFont
                                type="iconic_pc_calender_download"
                                className="download-icon"
                                style={{
                                    width: '14px',
                                    height: '14px',
                                }}
                            />
                            {!disabled && item.status === FileStatus.Done && item.isRemove && (
                                <CalendarIcon
                                    onClick={(e) => deleteConfirm(e, item)}
                                    src={changeEgovUrl(searchDeletePng)}
                                    className="delete-icon"
                                    style={{
                                        marginLeft: '8px',
                                    }}
                                />
                            )}
                            <div
                                className="process"
                                style={{
                                    width:
                                        item.status === FileStatus.Done
                                            ? '100%'
                                            : `${item.percent}%`,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default WorkAttachmentComp;
