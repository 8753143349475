const thirdAppAnalyticsMap: { [index: string]: { title: string; click: string; close: string } } = {
    zy4O9bNimHXFolVwFFQ9: {
        title: '天气',
        click: 'pageclick|keycount|third_application_01_1626401996045|1',
        close: 'pageclick|keycount|third_application_01_1626401996045|2',
    },
    m6qfQ4LwWf8RFId4Txd6: {
        title: '英汉互译',
        click: 'pageclick|keycount|third_application_01_1626401996045|3',
        close: 'pageclick|keycount|third_application_01_1626401996045|4',
    },
    '9hPhF2625RORjG9dPfZh': {
        title: '尾号限行查询',
        click: 'pageclick|keycount|third_application_01_1626401996045|5',
        close: 'pageclick|keycount|third_application_01_1626401996045|6',
    },
    gG5jh5L2VrCY32Yk7Lwz: {
        title: '网络强国',
        click: 'pageclick|keycount|third_application_01_1626401996045|7',
        close: 'pageclick|keycount|third_application_01_1626401996045|8',
    },
    LEWxMZg2bvb7QwtpTHEd: {
        title: '领导驾驶舱',
        click: 'pageclick|keycount|third_application_01_1626401996045|9',
        close: 'pageclick|keycount|third_application_01_1626401996045|10',
    },
    '4NXD43PzdL0w3dEqVfEH': {
        title: '政务云监管',
        click: 'pageclick|keycount|third_application_01_1626401996045|11',
        close: 'pageclick|keycount|third_application_01_1626401996045|12',
    },
    WxzhJMH8RYsLkwupQXu7: {
        title: '邮箱',
        click: 'pageclick|keycount|third_application_01_1626401996045|13',
        close: 'pageclick|keycount|third_application_01_1626401996045|14',
    },
    '708GSBGDBRmdY3Q2fipu': {
        title: '微环境监测',
        click: 'pageclick|keycount|third_application_01_1626401996045|15',
        close: 'pageclick|keycount|third_application_01_1626401996045|16',
    },
    whViGYpzuxZYNowy96H5: {
        title: '周报日报',
        click: 'pageclick|keycount|third_application_01_1626401996045|17',
        close: 'pageclick|keycount|third_application_01_1626401996045|18',
    },
    kV2F7WT6IEwWDdTag4BR: {
        title: '统计分析',
        click: 'pageclick|keycount|third_application_01_1626401996045|19',
        close: 'pageclick|keycount|third_application_01_1626401996045|20',
    },
    '3f9XjEiOcUjfSlnoj0no': {
        title: '工单处理',
        click: 'pageclick|keycount|third_application_01_1626401996045|21',
        close: 'pageclick|keycount|third_application_01_1626401996045|22',
    },
    U0gSUhJAOoZwl57g0SvW: {
        title: '综合检查',
        click: 'pageclick|keycount|third_application_01_1626401996045|23',
        close: 'pageclick|keycount|third_application_01_1626401996045|24',
    },
    '9kPJMeGJ62Kn12vTzOR6': {
        title: '即时呼叫',
        click: 'pageclick|keycount|third_application_01_1626401996045|25',
        close: 'pageclick|keycount|third_application_01_1626401996045|26',
    },
    fPJpggJ20bM7jHguIIP5: {
        title: '掌上12345',
        click: 'pageclick|keycount|third_application_01_1626401996045|27',
        close: 'pageclick|keycount|third_application_01_1626401996045|28',
    },
    I2ADTeaP8mg6N0haUAeI: {
        title: '共享单车治理',
        click: 'pageclick|keycount|third_application_01_1626401996045|29',
        close: 'pageclick|keycount|third_application_01_1626401996045|30',
    },
    f7r5dlBk5sVn0KPZOOXG: {
        title: '舆情',
        click: 'pageclick|keycount|third_application_01_1626401996045|31',
        close: 'pageclick|keycount|third_application_01_1626401996045|32',
    },
    jib19cstnVTtphJiRY0E: {
        title: '平时考核',
        click: 'pageclick|keycount|third_application_01_1626401996045|33',
        close: 'pageclick|keycount|third_application_01_1626401996045|34',
    },
    HXFymdSUu2deCWO5jprE: {
        title: '隔离人群管控',
        click: 'pageclick|keycount|third_application_01_1626401996045|35',
        close: 'pageclick|keycount|third_application_01_1626401996045|36',
    },
};

export default thirdAppAnalyticsMap;
