import { RcFile } from 'antd/lib/upload/interface';
import Task from '@jd/jdee-fs-desktop/lib/es/Task';
import { TaskState } from '@jd/jdee-fs-desktop';

// // 文件上传方式， ALL表示一次上传， BLOCK表示分块上线
export enum UploadType {
    ALL = 'all',
    BLOCK = 'block',
}

export * from '@jd/jdee-fs-desktop';

export interface UploadInfo {
    fileUploadUrl: string; // 上传文件地址
    sliceSize: number; // 建议分片大小
}

export interface InitResult {
    uploadId: string; // 上传文件ID
    hash: string;
    secondPass: number; // 是否是秒传：0不是，1秒传文档
    teamId: string;
    info: UploadInfo; // 上传信息
}

export interface UploadFile extends RcFile {
    task?: Task;
    fileType?: string; // 标记wps文档
    transactionId?: string; // wps文档transactionId
    state: TaskState;
    initResult: InitResult;
    eid: string | symbol;
    reader: FileReader;
    sizeOffset: number;
    pageId: string; // page id
    fileId: string; // joyspace 文件ID
    document: FileDocument;
    projectId: string; // 我的专班 专班 id
    folderId: string; // 我的空间 文件夹 id
    spaceId: string; // 团队空间  id
    categoryId: string; // 团队空间  分类ID
    hash: string; // hash值
    uploadId: string; // 上传文件ID
    info: UploadInfo; // 上传信息
    secondPass: number; // 是否是秒传：0不是，1秒传文档
    // accessToken: string; // 调用java接口的token
    uploadType: UploadType; // 上传类型 分块传，还是一次性传
    teamId: string; // teamId
    endCallback: (file: UploadFile, signal: AbortSignal) => Promise<UploadFile>;
    cancelUpload: (file: UploadFile, status: string) => void;
    retryUpload: (file: UploadFile, status: string) => void;
    attachment?: boolean;
    progress?: number;
}

export enum PageType {
    DOCUMENT = 1,
    REPORT = 2,
    NOTE = 3,
    XLSSHEET = 4,
    LINK = 5,
    FILE = 6,
}

export interface Block {
    blob: Blob;
    no: number;
    hash?: string;
    done?: boolean;
}

export interface UploadConfig {
    fileList: UploadFile[];
    waitFileList: UploadFile[];
    started: boolean;
    maxUploadCount: number;
    currentUploadCount: number;
    concurrency: number;
    currentBlockCount: number;
}

export interface FileDocument {
    id: string;
    page_id: string;
    tenant_id: string;
    status: number;
    name: string;
    title: string;
    preview_text: string;
    updated_by: string;
    created_by: string;
    created_at: string;
    updated_at: string;
    space_page_id?: number;
    document_id: string;
    spaceId: string;
    space_id: string;
    editing_content_id: string;
    published_at: string;
    owner_id: string;
    folder_id: string;
}

export enum OpenFileMode {
    IMAGE = 'IMAGE',
    WPS = 'WPS',
    VIDEO = 'VIDEO',
    OTHER = 'OTHER',
}

export interface WPSUploadTransactionModel {
    id: string;
    storage: 's3';
    upload_requests: {
        value: [
            {
                type: 'post_object';
                request: {
                    method: string;
                    url: string;
                    inner_url: string;
                    header: [
                        {
                            name: string;
                            value: string;
                            type: string;
                        }
                    ];
                    form: [
                        {
                            name: string;
                            value: string;
                            type: string;
                        }
                    ];
                };
            }
        ];
        next: string;
    };
    file_hashes: {
        sha1?: string;
        md5?: string;
    };
    feedback: string;
}

//
// export interface Attachment {
//     id: string;
// }
//
// export interface WPSUploadTransactionModel {
//     id: string;
//     storage: 's3';
//     upload_requests: {
//         value: [
//             {
//                 type: 'post_object';
//                 request: {
//                     method: string;
//                     url: string;
//                     header: [
//                         {
//                             name: string;
//                             value: string;
//                             type: string;
//                         }
//                     ];
//                     form: [
//                         {
//                             name: string;
//                             value: string;
//                             type: string;
//                         }
//                     ];
//                 };
//             }
//         ];
//         next: string;
//     };
//     file_hashes: {
//         sha1?: string;
//         md5?: string;
//     };
//     feedback: string;
// }
