/**
 * work 个人页面 头部组件
 * 负责筛选和新建任务
 * @author zhangpengcheng15
 */
import React, { useEffect, useState } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { Input, message, Radio, Button } from 'antd';
import { TimeClassifyEnum } from '@/types/work';
import { generateTimeRange } from '../../utils';
import bus from '@/utils/bus';
import ExportTaskDraw from '@/components/Work/ExportTaskDraw';

function handelOpenCreate({
    value,
    projectId,
    sourceName,
}: {
    value: string;
    projectId: string;
    sourceName: string;
}) {
    bus.emit('task:open-create', {
        title: value?.trim(),
        projectId: projectId,
        sourceName: sourceName,
    });
}

function handelExportOpenCreate({
    value,
    projectId,
    sourceName,
}: {
    value: string;
    projectId: string;
    sourceName: string;
}) {
    bus.emit('task:open-exporet-task', {
        title: value?.trim(),
        projectId: projectId,
        sourceName: sourceName,
    });
}

function CreateComp({
    projectId,
    sourceName,
    onCreate,
}: {
    projectId: string;
    sourceName: string;
    onCreate: (title: any) => void;
}) {
    const [value, setValue] = useState('');
    const { t } = useTranslation('work');
    useEffect(() => {
        function handleResetTitle() {
            setValue('');
        }
        bus.on('task:clear-title', handleResetTitle);
        return () => {
            bus.off('task:clear-title', handleResetTitle);
        };
    }, []);
    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setValue(value);
    }
    function handleCreate() {
        if (!value) {
            return;
        }
        onCreate(value);
        setValue('');
    }
    function openPersonTaskDraw() {
        handelOpenCreate({
            value: value?.trim(),
            projectId: projectId,
            sourceName: sourceName,
        });
        setValue('');
    }
    function getCreateButton() {
        let disable = true;
        if (value?.trim()) {
            disable = false;
        }
        return (
            <div className="suffix-box">
                <IconFont
                    className="extend-icon"
                    type="iconbtn_editor_max"
                    onClick={openPersonTaskDraw}
                />
                <div
                    className={`create-button ${disable ? 'create-button-disable' : ''}`}
                    onClick={handleCreate}
                >
                    创建
                </div>
            </div>
        );
    }
    return (
        <div className="work-tasklist-view-header-create">
            <Input
                // prefix={<IconFont type="iconapp_btn_add2" />}
                onChange={handleInputChange}
                onPressEnter={handleCreate}
                // onBlur={handleCreate}
                // placeholder={t('new task')}
                placeholder={t('input placeholder')}
                suffix={getCreateButton()}
                value={value}
                maxLength={100}
            />
        </div>
    );
}

enum TaskClassify {
    myTask = 1,
    initiated,
    participated,
}

function TaskListViewHeader({
    total,
    createPersonalTask,
    disableTitle,
    projectId,
    sourceName,
    disableCreate,
    taskClassify,
    sortType,
}: {
    total: number;
    projectId: string;
    sourceName: string;
    disableTitle?: boolean;
    disableCreate?: boolean;
    createPersonalTask: (opt: {
        title: string;
        projectId: string;
        endTimeFrom?: string;
        callback?: any;
    }) => void;
    taskClassify?: TaskClassify;
    sortType?: number;
}) {
    const { t } = useTranslation('work');
    const [title, setTitle] = useState<string>(t('My to-do'));
    const taskSortList = [
        {
            value: 1,
            label: t('sort by time'),
        },
        {
            value: 2,
            label: t('sort by priority'),
        },
    ];
    useEffect(() => {
        switch (taskClassify) {
            case TaskClassify.myTask:
                setTitle(t('My to-do'));
                break;
            case TaskClassify.initiated:
                setTitle(t('send'));
                break;
            case TaskClassify.participated:
                setTitle(t('participated'));
                break;
            default:
                setTitle(t('My to-do'));
        }
    }, [t, taskClassify]);
    async function onCreate(title: any) {
        createPersonalTask({
            title,
            projectId,
            ...generateTimeRange(TimeClassifyEnum.LaterOrUntime),
            callback: (err: any) => {
                message.error(t('create task err info'));
            },
        });
    }
    return (
        <div className="work-tasklist-view-header">
            {!disableTitle && (
                <div className="work-tasklist-view-header-title">
                    <div className="label">{title}</div>
                    <div className="total">{total || 0}</div>
                    <div style={{ flex: 1 }} />
                    {/* <div className="screening">
                    <IconFont type="iconapp_ic_filter" />
                    {t('filter')}
                </div> */}
                </div>
            )}
            <div className="work-tasklist-view-header-action-line">
                <Radio.Group style={{ display: 'none' }} className="left">
                    {taskSortList.map((i) => (
                        <Radio value={i.value} key={i.value}>
                            {i.label}
                        </Radio>
                    ))}
                </Radio.Group>
                <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        handelExportOpenCreate({ value: '', projectId, sourceName });
                    }}
                >
                    {t('export task')}
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        handelOpenCreate({ value: '', projectId, sourceName });
                    }}
                >
                    {t('new task')}
                </Button>
                <ExportTaskDraw />
            </div>
            {!disableCreate && (
                <CreateComp projectId={projectId} sourceName={sourceName} onCreate={onCreate} />
            )}
        </div>
    );
}

export default TaskListViewHeader;
