import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactDom from 'react-dom';
import AINoticeTab from '../AINoticeTab';
import cls from 'classnames';
import './index.less';
import IconFont from '@/components/icon';
import { debounce } from 'lodash';
import CHAT_NOTICE from '@/contant/chat';
interface IProps {
    readonly tabList: any[];
    activeKey: string;
    wrapperWidth: number;
    wrapperLeft: number;
    computeHiddenTabs: Function;
    onTabDelete: Function;
    updateTabsOffset: Function;
}
function AINoticeTabList(props: IProps) {
    const [wrapperScrollLeft, setWrapperScrollLeft] = useState(0);
    const wrapperScrollRef = useRef<number | null>(null);
    const prevTabList = useRef<any>(null);
    const tabListRef = useRef<any>();
    const windowWidth = useRef<number>(document.body.clientWidth);
    const { tabList, wrapperWidth, computeHiddenTabs, onTabDelete, updateTabsOffset } = props;

    const initTabOffset = useCallback(() => {
        console.log('computeOffset');
        const tabOffsets: any = {};
        let initLeft = 0;
        const unit = 'width';
        const position = 'left';
        const transformSize = Math.abs(wrapperScrollLeft);
        if (wrapperScrollRef.current === transformSize && prevTabList.current === tabList) return;
        for (let ele of tabListRef.current?.children) {
            if (!initLeft) {
                initLeft = ele.getBoundingClientRect().left;
            }
            tabOffsets[ele.id] = {
                left: ele.getBoundingClientRect().left - initLeft,
                width: ele.getBoundingClientRect().width,
            };
        }

        wrapperScrollRef.current = transformSize;
        prevTabList.current = tabList;
        const basicSize = wrapperWidth;
        const mergedBasicSize = basicSize;
        const len = tabListRef.current?.children.length;
        let endIndex = len;
        for (let i = 0; i < len; i += 1) {
            const offset = tabOffsets[(tabListRef.current?.children[i] as any).id] || {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
                right: 0,
            };
            if (offset[position] + offset[unit] > transformSize + mergedBasicSize + 10) {
                endIndex = i - 1;
                break;
            }
        }

        let startIndex = 0;
        for (let i = len - 1; i >= 0; i -= 1) {
            const offset = tabOffsets[(tabListRef.current?.children[i] as any).id] || {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
                right: 0,
            };
            if (offset[position] < transformSize - 10) {
                startIndex = i + 1;
                break;
            }
        }
        computeHiddenTabs(startIndex, endIndex);
        updateTabsOffset(tabOffsets);
    }, [computeHiddenTabs, tabList, updateTabsOffset, wrapperScrollLeft, wrapperWidth]);

    useEffect(() => {
        initTabOffset();
    }, [initTabOffset]);

    const displayWindowSize = debounce((e: any) => {
        const w = document.documentElement.clientWidth;
        if (w && w !== windowWidth.current) {
            windowWidth.current = w;
            initTabOffset();
        }
        // console.log(w);
    });

    const onTabListScroll = debounce(() => {
        const tabListContent = document.getElementById('tabListContent');
        setWrapperScrollLeft(tabListContent?.scrollLeft || 0);
    }, 300);

    const onWheel = (e: any) => {
        const tabListContent = document.getElementById('tabListContent');
        e.preventDefault();
        const { deltaX, deltaY } = e;
        const absX = Math.abs(deltaX);
        const absY = Math.abs(deltaY);
        if (absX > absY) {
            if (deltaX < -3) {
                (tabListContent as HTMLElement).scrollLeft =
                    (tabListContent as HTMLElement).scrollLeft - 50;
            }
            if (deltaX > 3) {
                (tabListContent as HTMLElement).scrollLeft =
                    (tabListContent as HTMLElement).scrollLeft + 50;
            }
        } else {
            if (deltaY < -3) {
                (tabListContent as HTMLElement).scrollLeft =
                    (tabListContent as HTMLElement).scrollLeft - 50;
            }
            if (deltaY > 3) {
                (tabListContent as HTMLElement).scrollLeft =
                    (tabListContent as HTMLElement).scrollLeft + 50;
            }
        }
    };

    useEffect(() => {
        const tabListContent = document.getElementById('tabListContent');
        tabListContent?.addEventListener('scroll', onTabListScroll);

        window.addEventListener('resize', displayWindowSize);
        tabListRef.current?.addEventListener('wheel', onWheel);
        return () => {
            tabListContent?.removeEventListener('scroll', onTabListScroll);

            window.removeEventListener('resize', displayWindowSize);
        };
    }, [displayWindowSize, onTabListScroll]);

    return (
        <div className="notice-tab-list" ref={tabListRef} id="noticeTabList">
            {tabList.map((item, index) => (
                <AINoticeTab
                    index={index}
                    session={item}
                    key={item.pin}
                    onTabDelete={onTabDelete}
                />
            ))}
        </div>
    );
}

export default AINoticeTabList;
