import 'url-search-params-polyfill';
import TokenProvider from './TokenProvider';
import Janus from '@jd/jzb.jdee-janus';
import { getAppId } from '@/utils';
import { Modal } from 'antd';
import { postMessageToParent } from '@/utils/messages';
import bus from '@/utils/bus';
import { OpenChat, Token } from './Types';
import { getChatToFromSearchParams } from './utils';
import cache from '@/utils/cache';

export const AUTH_HOST = 'https://me.jdcloud.com';

export interface ExchangeTokenReq {
    clientId: string;
    focusToken: string;
}

export interface ExchangeToken {
    accessToken: string; //	ME 行业版的 accessToken
    accessTokenExpireIn: number; // accessToken 有效时长
    refreshToken: string; //	当 accessToken 过期时，可以用 refreshToken 去换取新的 accessToken + refreshToken（当业务方为客户端时，这个值不为空）
    refreshTokenExpireIn: number; //	appRefreshToken 有效时长（当业务方为客户端时，这个值不为空）
    expand: string; //	业务方自定义的拓展字段，
}

export interface ExchangeTokenResp {
    data: ExchangeToken | null;
    code: string;
    msg: string;
}

export interface ExchangeTeamIdResp {
    msg: string;
    code: number | string;
    data: { teamId: string | null };
}

export default class extends TokenProvider {
    // accessToken
    private authHost: string;
    private clientId: string;
    private focusToken: string;
    private focusTeamId: string;
    private janus: Janus;
    private chatTo?: OpenChat;
    constructor() {
        super();
        const { REACT_APP_JANUS_HOST = '' } = process.env;
        this.janus = new Janus({
            appId: getAppId() || '',
            host: REACT_APP_JANUS_HOST,
        });
        const searchParam = new URLSearchParams(window.location.search);
        this.clientId = (searchParam.get('clientId') || '').trim();
        cache.clientId = this.clientId;
        this.focusToken = (searchParam.get('focusToken') || '').trim();
        this.focusTeamId = (searchParam.get('focusTeamId') || '').trim();
        this.chatTo = getChatToFromSearchParams(searchParam);
        this.authHost =
            (searchParam.get('authHost') || '').trim() || window.location.origin || AUTH_HOST;
        this.bindGlobalEvents();
    }

    // eslint-disable-next-line complexity
    async token(): Promise<Token> {
        if (this.focusToken && this.focusTeamId) {
            // 返回认证结果
            const rs: Token = {
                token: {
                    focusToken: this.focusToken,
                    focusTeamId: this.focusTeamId,
                    focusClient: 'ipad',
                },
                deviceCode: '',
            };
            if (this.chatTo) {
                if (this.chatTo.type === 'group') {
                    rs.gid = this.chatTo.gid;
                } else if (this.chatTo.type === 'single') {
                    rs.uid = {
                        pin: this.chatTo.userId,
                        teamId: this.chatTo.teamId,
                        app: this.chatTo.app || 'me',
                    };
                }
            }
            return rs;
        }
        return new Promise((resolve) => {});
    }
    failed(url: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    logout(): Promise<void> {
        postMessageToParent({ event: 'logout' });
        this.modal({ title: '您已登出' });
        return new Promise((resolve) => {});
    }

    private modal(options: { title?: string; content?: string }) {
        Modal.warning({
            ...options,
            onOk: (x) => false,
        });
    }

    private buildError(name: string, message = ''): Error {
        const err = new Error(message);
        err.name = name;
        return err;
    }

    private bindGlobalEvents() {
        bus.on('login:eepaas', (msg) => {
            this.clientId = msg.clientId;
            this.focusToken = msg.focusToken;
            this.focusTeamId = msg.focusTeamId;
        });
        bus.on('chat:open:eepaas', (chatTo: OpenChat) => {
            this.emitChatTo(chatTo);
        });
    }
}
