import { FocusSDK } from '@/utils';
import React from 'react';

interface IState {
    hasError: boolean;
}
type IProps = Readonly<{}>;

class ErrorCatch extends React.Component<IProps, IState> {
    static getDerivedStateFromError(error: any) {
        FocusSDK.printLog(`ErrorBoundary`, JSON.stringify({ error }));
        // 重新加载
        return { hasError: true };
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error: any, info: any) {
        FocusSDK.printLog(`ErrorBoundary`, JSON.stringify({ error, info }));
    }

    render() {
        return this.props.children;
    }
}
export default ErrorCatch;
