import React, { useState, useRef, useCallback } from 'react';
import { Button, Input } from 'antd';
import { AvatarIcon } from '@/components/Avatar';
import i18n from '@/i18n';
import { Avatar } from '@/baseComponents/Avatar';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
import CopyFrame from '@/components/CopyFrame';
interface Iu {
    realName: string;
    headImg: string | null;
    teamUserInfo?: object | any;
}
export default function CommentInput({
    onOk,
    user,
}: {
    onOk: (content: string) => void;
    user: Iu;
}) {
    const focusRef = useRef(null);
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);

    const focusHandle = useCallback((flag) => {
        if (flag) {
            setIsFocus(true);
        } else {
            setIsFocus(false);
        }
    }, []);
    const onOkHandle = () => {
        if (value?.trim().length) {
            onOk(value);
            setValue('');
            focusHandle(false);
        }
    };
    return (
        <div className="supervise-comment-box">
            <div className="supervise-comment-input">
                <Avatar
                    className="sci-avator"
                    styleObj={{
                        width: '32px',
                        height: '32px',
                    }}
                    src={changeEgovUrl(user?.teamUserInfo?.headImg || '')}
                    name={user.realName || ''}
                />
                <div className="comment-input-wrapper">
                    <CopyFrame>
                        <Input.TextArea
                            ref={focusRef}
                            value={value}
                            defaultValue={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                            maxLength={500}
                            style={{
                                height: 120,
                                background: '#ffffff',
                                border: '1px solid #DEE0E3',
                            }}
                            onFocus={() => focusHandle(true)}
                            placeholder={i18n.t('supervise:commentPlaceholder')}
                            className="comment-input"
                        />
                    </CopyFrame>
                </div>
            </div>
            <div className="sci-len">
                <span />
                <div>
                    <span className="primary">{value.length}</span>/500
                </div>
            </div>
            <div className="sci-buttons-box">
                <div />
                <div className="sci-buttons">
                    <div
                        onClick={() => {
                            setValue('');
                            focusHandle(false);
                        }}
                        style={{ marginRight: 8 }}
                        className="sci-button-cancel"
                    >
                        {i18n.t('supervise:cancel')}
                    </div>
                    <div
                        onClick={onOkHandle}
                        className={`sci-primary-bg sci-button-send ${
                            !value?.trim().length ? 'disable' : ''
                        }`}
                    >
                        {i18n.t('supervise:publish')}
                    </div>
                </div>
            </div>
        </div>
    );
    // return (
    //     <div className="supervise-comment-input">
    //         <Avatar
    //             className="sci-avator"
    //             styleObj={{
    //                 width: '36px',
    //                 height: '36px',
    //             }}
    //             src={changeEgovUrl(user?.teamUserInfo?.headImg || '')}
    //             name={user.realName || ''}
    //         />
    //         <div
    //             className={`comment-input-wrapper ${isFocus ? 'comment-input-wrapper_focus' : ''}`}
    //         >
    //             <CopyFrame>
    //                 <Input.TextArea
    //                     ref={focusRef}
    //                     value={value}
    //                     defaultValue={value}
    //                     onChange={(e) => {
    //                         setValue(e.target.value);
    //                     }}
    //                     maxLength={500}
    //                     style={{
    //                         height: isFocus ? 70 : 34,
    //                         background: isFocus ? '#ffffff' : '#f8f8f8',
    //                     }}
    //                     onFocus={() => focusHandle(true)}
    //                     placeholder={i18n.t('supervise:commentPlaceholder')}
    //                     className="comment-input"
    //                 />
    //             </CopyFrame>
    //             {isFocus && (
    //                 <div className="sci-footer">
    //                     <span style={{ textAlign: 'right' }}>
    //                         <span className="primary">{value.length}</span>/500
    //                     </span>
    //                     <div className="sci-button">
    //                         <p
    //                             onClick={() => {
    //                                 setValue('');
    //                                 focusHandle(false);
    //                             }}
    //                             style={{ marginRight: 8 }}
    //                             className="sci-button-cancel"
    //                         >
    //                             {i18n.t('supervise:cancel')}
    //                         </p>
    //                         <p
    //                             onClick={onOkHandle}
    //                             className={`sci-primary-bg ${
    //                                 !value?.trim().length ? 'disable' : ''
    //                             }`}
    //                         >
    //                             {i18n.t('supervise:publish')}
    //                         </p>
    //                     </div>
    //                 </div>
    //             )}
    //         </div>
    //     </div>
    // );
}
