/* eslint-disable react-hooks/exhaustive-deps */
import { getTaskUpdateRecords } from '@/api/work';
import { Button, Empty, Input, message, Radio } from 'antd';
import Steps from 'antd/lib/steps';
import dayjs from 'dayjs';
import { Loading } from '@jd/focus-desktop-comps';
import { connect } from 'dva';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UniqBy from 'lodash/uniqBy';
import { mattchUrl } from '@/utils/chat/index';
import { changeEgovUrl, htmlUnescape } from '@/utils/tools';
import { productBriefTimeStr } from '../../utils';
import {
    ACTION_TYPE,
    AdvanceRecordModel,
    PriorityList,
    RecordsParams,
    WorkUserInfo,
} from '@/types/newWork';
import ScrollView from '@/baseComponents/ScrollView';
import { number2percentum } from '../../BaseComponents/WorkUserListWeightSet';

const { Step } = Steps;

function AdvanceCom({
    taskId,
    advanceUser,
    pullRecords,
    className,
}: {
    taskId: string;
    advanceUser: WorkUserInfo;
    pullRecords?: boolean | number;
    className?: string;
}) {
    const { t } = useTranslation('work');
    const [RecordsData, setRecordsData] = useState<AdvanceRecordModel[]>([]);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(1); // 页码(1页/20条)
    const [gotRecordsInitially, setGotRecordsInitially] = useState(false);
    const [loading, setLoading] = useState(false);
    const scrollRef = useRef(null);
    /**
     * 初始化数据
     */
    useEffect(() => {
        if (!taskId) {
            return;
        }
        getRecords();
        // return () => {
        //     setRecordsData([]);
        // };
    }, [count]);
    useEffect(() => {
        if (!taskId) {
            return;
        }
        if (gotRecordsInitially && pullRecords) {
            setCount(1);
            getRecords();
        }
    }, [pullRecords]);

    function getRecords() {
        let params: RecordsParams = { taskId: taskId, offset: (count - 1) * 20, limit: 20 };
        if (advanceUser) {
            params = {
                taskId: taskId,
                offset: (count - 1) * 20,
                limit: 20,
                userId: advanceUser?.userId || '',
                teamId: advanceUser?.teamId || '',
                appId: advanceUser?.app || '',
            };
        }
        setLoading(true);
        getTaskUpdateRecords(params).then(([result, err]: any) => {
            setLoading(false);
            if (err) {
                message.error(err as string);
                return;
            }
            if (!result && !result.records.length) {
                return;
            }
            setTotal(result?.total);
            let _RecordsData = RecordsData;
            if (count === 1) {
                _RecordsData = [];
            }
            const data = [..._RecordsData, ...result?.records].sort(function (a: any, b: any) {
                return b.time - a.time;
            });
            setRecordsData(data);
            setGotRecordsInitially(true);
        });
    }
    const changePriorityType = useCallback(
        (priority: string) => {
            const priorityItem =
                PriorityList.find((p) => p.code.toString() === priority) || ({} as any);
            return priority ? (
                <img className="priority-img" src={changeEgovUrl(priorityItem.label)} />
            ) : (
                '  无'
            );
        },
        [PriorityList]
    );
    const description = useCallback((r: AdvanceRecordModel) => {
        switch (r.action) {
            case ACTION_TYPE.UPDATE_PROCESS_STATUS: // "状态"
            case ACTION_TYPE.UPDATE_TASK_STATUS: // "状态"
                return r.comment ? `情况说明：${htmlUnescape(mattchUrl(r.comment))}` : '';
            case ACTION_TYPE.UPDATE_CONTENT: // 任务描述
                return r.newContent ? `任务描述：${r.newContent}` : '';
            case ACTION_TYPE.UPDATE_TITLE: // 任务名称
                return r.newContent ? `任务名称：${r.newContent}` : '';
            case ACTION_TYPE.UPDATE_TIME: // 任务时间
                return r.newContent ? `起止时间：${r.newContent}` : '';
            default:
                return '';
        }
    }, []);

    const SetTitle = useCallback(
        // eslint-disable-next-line complexity
        (r: AdvanceRecordModel) => {
            switch (r.action) {
                case ACTION_TYPE.UPDATE_PROCESS_STATUS: // "推进状态"
                    /* if (r.newContent !== r.oldContent) {
                        return (
                            <div>
                                {r.operator} 将 {r.item} 改为 {r.newContent}
                                <br />
                                当前其个人推进进度为
                                {number2percentum(Number(r.userProgress || 0) / 100)}
                            </div>
                        );
                    } else {
                        return (
                            <div>
                                {r.operator} 将 个人推进进度 改为
                                {number2percentum(Number(r.userProgress || 0) / 100)} <br />
                                当前其{r.item}为 {r.newContent}
                            </div>
                        );
                    } */
                    return (
                        <div>
                            {/* {`${r.operator}：推进状态为${
                                r.newContent ?? r.oldContent
                            },当前个人的推进进度为${number2percentum(
                                Number(r.userProgress || 0) / 100
                            )}`} */}
                            {`${r.operator}：推进状态为${r.newContent ?? r.oldContent}`}
                        </div>
                    );
                case ACTION_TYPE.UPDATE_TASK_STATUS: // "任务状态"
                    return (
                        <div>
                            {r.operator} 将 {r.item} 改为 {r.newContent}
                        </div>
                    );
                case ACTION_TYPE.UPDATE_OWNER: // 负责人
                    return (
                        <div>
                            {r.operator} 将 {r.item} 改为 {r.newContent}
                        </div>
                    );
                case ACTION_TYPE.UPDATE_EXECUTOR: // 协同人
                    return !r.oldContent && r.newContent ? (
                        <div>
                            {r.operator} 将 {r.newContent} 添加为 {r.item}
                        </div>
                    ) : (
                        <div>
                            {r.operator} 将 {r.oldContent} 从 {r.item} 中移除
                        </div>
                    );
                case ACTION_TYPE.UPDATE_PRIORITY_TYPE: // 任务类型
                    return (
                        <div>
                            {r.operator} 将 {r.item} 调整为 {changePriorityType(r.newContent)}
                        </div>
                    );
                case ACTION_TYPE.UPDATE_CONTENT: // 任务描述
                    return !r.oldContent && r.newContent ? (
                        <div>{r.operator} 新增了任务描述</div>
                    ) : (
                        <div>{r.operator} 更改了任务描述</div>
                    );
                case ACTION_TYPE.UPDATE_RESOURCE: // 附件
                    return !r.oldContent && r.newContent ? (
                        <div>
                            {r.operator} 新增了任务附件{r.newContent}
                        </div>
                    ) : (
                        <div>
                            {r.operator} 删除了任务附件{r.oldContent}
                        </div>
                    );
                case ACTION_TYPE.UPDATE_TITLE: // 任务名称
                    return <div>{r.operator} 更改了任务名称</div>;
                case ACTION_TYPE.UPDATE_TIME: // 任务时间
                    return <div>{r.operator} 更改了任务时间</div>;
                case ACTION_TYPE.CREATE_TASK: // 创建任务
                    return <div>{r.operator} 创建了任务</div>;

                case ACTION_TYPE.RESUME_TASK: // 重新启动任务
                    return (
                        <div>
                            {r.operator} {r.item} 了任务
                        </div>
                    );
                case ACTION_TYPE.FINISH_TASK: // 整体完成任务
                    return (
                        <div>
                            {r.operator} 将任务标记为 {r.item}
                        </div>
                    );
                case ACTION_TYPE.CREATE_SUPERVISE: // 我 对 谁 发起了 催办
                    return (
                        <div>
                            {r.operator} 对 {r.newContent} 发起了 {r.item}
                        </div>
                    );
                case ACTION_TYPE.UPDATE_EXECUTOR_WEIGHT: // 权重更改
                    const persons = (r.newContent || r.oldContent || '').split(','); // eslint-disable-line
                    return persons.length > 1 || persons[0] ? (
                        <div>
                            {r.operator}：修改了
                            {persons.length < 3
                                ? persons.toString()
                                : persons.slice(0, 2).toString() + '等' + persons.length + '人'}
                            的权重
                        </div>
                    ) : null;
            }
        },
        [RecordsData]
    );

    if (loading) {
        return <Loading />;
    }

    const recordsArr = UniqBy(RecordsData, 'recordId');
    // 处理 数据无totalProgress的情况
    recordsArr.reduceRight((tmp: number | string, v) => {
        v.totalProgress = v.totalProgress ?? tmp;
        return v.totalProgress;
    }, 0);
    return (
        <div
            className={'advance-record ' + (className || '')}
            style={{ flex: 1, overflow: 'auto' }}
        >
            <ScrollView ref={scrollRef} autoHide style={{ position: '' }}>
                {RecordsData.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no data')} />
                ) : (
                    <>
                        <Steps
                            className="lb-decorate"
                            progressDot={(dot: any, { index }: any) => (
                                <>
                                    <b className="lt-whole-progress">
                                        {!recordsArr[index].totalProgress
                                            ? '0%'
                                            : number2percentum(
                                                  Number(recordsArr[index].totalProgress) / 100
                                              )}
                                    </b>
                                    {dot}
                                </>
                            )}
                            direction="vertical"
                            status="wait"
                            current={1}
                        >
                            {recordsArr.map((item: AdvanceRecordModel) => {
                                return (
                                    <Step
                                        key={item.recordId}
                                        title={
                                            <div className="user-select-text">{SetTitle(item)}</div>
                                        }
                                        subTitle={productBriefTimeStr(item.time)}
                                        description={
                                            <div
                                                className="user-select-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: description(item),
                                                }}
                                            />
                                        }
                                    />
                                );
                            })}
                        </Steps>
                        <div className="more">
                            {count * 20 < total && (
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCount(count + 1);
                                    }}
                                >
                                    加载更多
                                </span>
                            )}
                        </div>
                    </>
                )}
            </ScrollView>
        </div>
    );
}
function mapStateToProps({ work, user }: any) {
    const { detailState } = work;
    return {
        taskId: detailState.taskId,
        userData: user.userData.user,
    };
}

function mapDispatchToProps(_dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceCom);
