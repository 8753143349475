/* eslint-disable complexity */
import React, {
    ReactChild,
    ReactNode,
    SFC,
    useEffect,
    useState,
    useCallback,
    useContext,
    useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import Quill from 'quill';
const Clipboard = Quill.import('modules/clipboard');
import { connect } from 'dvajs';
import { IndicatorContent, ColorIndicator } from './../../Common/index';
import { CommentOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { openUserSelectionModal } from '@/utils/modals';
import './index.less';
import dayjs, { Dayjs } from 'dayjs';
import { getUserDefaultAvatar } from '@/utils/oss';
import { changeEgovUrl } from '@/utils/tools';
import {
    askSchedule,
    removeSchedule,
    removeParticipant,
    ScheduleDeleteType,
    remindText,
    ResStatus,
    User,
    Scope,
    CalendarType,
    UserType,
    Schedule,
    sendCalendarCard,
    saveRemark,
    Remark,
    BizType,
    shareScheduleAttachment,
    ShareRecipientDto,
    Agent,
    getScheduleInfo,
    createMeetingMinutes,
    CreateGroupType,
    // modifySchedule,
} from '@/api/calendar';
import BaseButton from './../../Button';
import Item from 'antd/lib/list/Item';
import { Modal, Tooltip, message as toast, Input, message, Drawer, Button, Collapse } from 'antd';
import BasicModal from '@/components/Calendars/Modal/BasicModal';
import {
    deletePng,
    draftPng,
    closePng,
    timesPng,
    remindPng,
    repeatPng,
    userPng,
    descriptionPng,
    attachmentPng,
    putawayPng,
    communicationPng,
    summaryPng,
} from '@/assets/img/calendar';
import { CalendarIcon } from '@/components/Calendars/Common';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { formatScheduleTime, getRealColor, isCrossDate } from '@/components/Calendars/utils';
import filePng from '@/assets/img/file-icon/bg_file@3x.png';
import { isFocusEnv, FocusSDK, DownloadItem, useDebounce } from '@/utils';
import {
    getFileUrl,
    downLoadFile,
    getFileIconByMime,
    useThrottle,
    getHeadImgThumbUrl,
    mattchUrl,
} from '@/utils/chat/index';
import IconFont from '@/components/icon';
import bus from '@/utils/bus';
import { ICreateSuperviseData, EsuperviseSourceType, SuperviseUserInfo } from '@/types/supervise';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { useAppId } from '@/components/Supervise/envirment';
import {
    buildJoyspaceIconUrl,
    buildPagePathKey,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import GlobalContext from '@/context/GlobalContext';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { ChatMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';

import { lookup } from 'mime-types';
import config, { ConfigEnum } from '@/config/config';
import { Avatar } from '@/baseComponents/Avatar';
import { ScheduleDateScope } from '@/components/Calendars/utils/constant';
import { getTypeImage } from '@/components/Calendars/utils/getTypeImage';
import RejectReason from './RejectReason';

import { replaceGovUrl } from '@/utils/joyspace';
import Downloader from './Downloader';
import ParticipantIndicator from './ParticipantIndicator';
import style from './index.module.less';
import { getRepeatName } from '../../Enums';
import { MeetingPlatform } from '@/types/common';
import { WorkUserInfo } from '@/types/work';
import { UserCardType } from '@/types/UserCard';
import ScrollView from '@/baseComponents/ScrollView';
import CalendarImService, {
    CalendarImServiceType,
} from '@/components/Calendars/services/CalendarImService';
import { getGroupInfo } from '@/api/egroup';
import { AddToMyCalendar } from './AddToMyCalendar';
import { analysisLog } from '@/utils/logAnalytics';
import CopyFrame from '@/components/CopyFrame';
import { joinMeetingOperation } from '@/components/Calendars/utils/operation';
import { handleOpenUrl } from '@/utils/openUrl';
import logger from '@/utils/logger';
import { joinMeeting as joinMeetingBase } from '@/baseComponents/Meeting/utils';
import { AVSDK } from '@/baseComponents/Meeting/common';
import { generateDefaultTimeFormat } from '@/components/Calendars/BaseComponents/DatePicker//utils';

// 只操作单条日程 或者 处理从这条以后的所有调
enum ModifyType {
    OnlyThisSchedule = 1,
    EveryAfter = 2,
}

enum ScheduleChangeMode {
    Delete = 0,
    Modify,
}
export enum ScheduleDetailFrom {
    // Card = 0,
    // Calendar = 1,
    Unknown = -1, // 来源未知
    Calendar = 0, // 来源日程 1
    Supervise = 1, // 来源催办
    Meeting = 2, // 来源视频会议 1
    AINoticeCard = 3, // 来源消息-AI助手 1
    ChatBanner = 4, // 消息会话-日程联结横条 1
    ChatShare = 5, // 消息会话-日程分享卡片 1
    ScheduleChatShare = 6, // 日程详情-沟通组 1
    WinNoticeCard = 7, // 日程提醒-弹窗
    Search = 8, // 来源搜索 1
}
export interface IMeetingModal {
    meetingId: string;
    password: string;
    title: string;
    type: string; // live:直播 meeting:会议
    pushUrl: string; // 预约直播 推流地址
    meetingUuid: string; // 预约会议 服务端用的会议唯一表示
    applicationId: string; // 应用id
    meetingType: number; // 0主app,1标识债权人应用
    applicationFlag: number; // //0，不需要实名，1需要实名 } | null>(
    meetingPlatform: MeetingPlatform; // 会议类型
    meetingPlatformName: string; // 会议类型名称
}

/**
 * 日程详情信息
 *  @param detail 详情数据
 * @returns
 */
interface IProps {
    // askSchedule: string;
    scheduleId: string;
    content: any; // 日程详情信息
    schedule: Schedule; // 日程列表中的数据
    calendarMap: any;
    userData: any;
    from: ScheduleDetailFrom; // 用于判断来源入口
    calendarList: any[];
    selectedScheduleTime: Dayjs; // 该条日程的时间，对于重复日程，需要在点击的时候传入
    setScheduleInfo?: any;
    refreshSchedule?: any;
    closeHandle: () => void;
    openCreateModal: (data: any) => void;
    setCalendarLoading: (data: boolean) => void;
    clearScheduleMap: (data: any) => void;
    imDrawerVisible?: boolean;
    setImDrawerVisible?: (flag: boolean) => void;
    getScheduleListByTime: (data: {
        user?: User;
        calendarId?: string;
        beginTime: Dayjs;
        endTime: Dayjs;
    }) => void;
    selectedCalendarList: any[];
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    isBusy?: number;
    busyRealName?: string;
    changeScheduleInfoDraw: (payload: { visible: boolean; scheduleId: string }) => void;
    changeScheduleInfoDrawOther: (payload: { visible: boolean; scheduleId: string }) => void;
    setScheduleInfoDrawVisible: Function;
    changePersonalTaskDraw: (payload: {
        taskId: string;
        drawVisible: boolean;
        chat: boolean;
    }) => void;
    scheduleInfoDraw: any;
    scheduleInfoDrawOther: any;
    workDrawVisible: boolean;
    workTaskId: string;
    newPublicResult: boolean;
    clearSelectSession: Function;
    conferencePlatforms: any[];
}

type Props = Readonly<WithTranslation & IProps>;
export const CalendarDetail: SFC<Props> = ({
    content,
    schedule,
    calendarMap,
    userData,
    scheduleId = '',
    from,
    selectedScheduleTime = dayjs(),
    closeHandle,
    openCreateModal,
    setCalendarLoading,
    getScheduleListByTime,
    selectedCalendarList,
    clearScheduleMap,
    openCreateSuperviseModal,
    calendarList,
    imDrawerVisible,
    setImDrawerVisible,
    setScheduleInfo,
    refreshSchedule,
    isBusy = 0,
    busyRealName = '',
    changeScheduleInfoDraw,
    changeScheduleInfoDrawOther,
    setScheduleInfoDrawVisible,
    changePersonalTaskDraw,
    scheduleInfoDraw,
    scheduleInfoDrawOther,
    workDrawVisible,
    workTaskId = '',
    newPublicResult,
    clearSelectSession,
    conferencePlatforms,
}: IProps) => {
    // const myCalendarIds = Object.keys(calendarMap).filter((key: any) => {
    //     return calendarMap[key].type === 1; // 获取我的个人日历
    // });
    // const myCalendar = calendarMap[myCalendarIds[0]] || {};

    // console.log('😂😄😂😂😄😂😂😄😂😂😄😂', content);
    const calendarImService: CalendarImServiceType | null = CalendarImService.getInstance();
    // useEffect(() => {
    //     calendarImService?.setScheduleId(scheduleId);
    //     if (content.groupId) {
    //         calendarImService.setSessionId(content.groupId);
    //         setImDrawerVisible?.(true);
    //     }
    //     // export default interface UID {
    //     //     app: string;
    //     //     pin: string;
    //     //     teamId: string;
    //     //     name?: string;
    //     //     realName?: string;
    //     //     nickname?: string;
    //     //     nickName?: string;
    //     //     avatar?: string;
    //     //     clientType?: ClientType;
    //     //     art?: string;
    //     //     dvc?: string;
    //     //     action?: number;
    //     //     department?: string;
    //     //     timeStamp?: number;
    //     // }
    //     // calendarImService?.joinGroup(content.groupId, {
    //     //     app: userData.ddAppId,
    //     //     pin: userData.userId,
    //     //     teamId: userData.team.teamId,
    //     // });
    //     // eslint-disable-next-line
    // }, [content]);

    const myCalendarIds = Object.keys(calendarMap).filter((key: any) => {
        return (
            calendarMap[key].type === CalendarType.PersonalType &&
            calendarMap[key].userType === UserType.Creator
        ); // 获取我的个人日历
    });
    const {
        appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
    } = useContext(GlobalContext);
    const myCalendar = calendarMap[myCalendarIds[0]] || {};
    const [dismissGroupModalVisible, setDismissGroupModalVisible] = useState(false);
    const [dismissGroupModalParams, setDismissGroupModalParams]: [any, any] = useState({});
    //
    // console.log('calendarMap', calendarMap);
    const { t } = useTranslation('calendar');
    const [t_common] = useTranslation('common');
    let unreadCount = 0;
    let rejectCount = 0;
    let acceptCount = 0;
    content?.participant?.forEach((item: any) => {
        if (item.resStatus === ResStatus.Unread) unreadCount++;
        if (item.resStatus === ResStatus.Reject) rejectCount++;
        if (item.resStatus === ResStatus.Agree) acceptCount++;
    });
    const history = useHistory();
    // const [isChatInited, setIsChatInited] = useState(false);
    // 是否是一个仅自己且没关联聊天群的基础日程
    const [isNoChatSelfSchedule, setIsNoChatSelfSchedule] = useState(true);
    const [isGroupCreating, setIsGroupCreating] = useState(false);
    // 群是否已解散
    // const [isGroupDismissed, setIsGroupDismissed] = useState(false);
    // 是否在群中
    // const [isInGroup, setIsInGroup] = useState(false);
    // 是否为创建者
    const [isCreator, setIsCreator] = useState(false);
    // 是否已接受日程
    const [resStatus, setResStatus] = useState(ResStatus.Unread);
    // 是否为参会人员
    const [isParticipant, setIsParticipant] = useState(false);
    // 是否为代建人
    const [isAgentFlag, setIsAgentFlag] = useState(false);
    // 修改或删除日程确认弹窗
    const [modalVisible, setModalVisible] = useState(false);
    // 是修改该是删除日程
    const [scheduleChangeMode, setScheduleChangeMode] = useState(ScheduleChangeMode.Modify);
    // 是否可以删除该日程
    const [canDelete, setCanDelete] = useState(false);
    // 是否可编辑日程
    const [canModify, setCanModify] = useState(false);
    const [meetingInfo, setMeetingInfo] = useState<IMeetingModal | null>(null);
    // 当前我的备注id，仅在日程无备注新加备注场景使用，用于暂存remarkId做修改用，日程存在旧的备注修改时remarkId可以直接从content中取到
    const [curRemarkId, setCurRemarkId] = useState('');
    // 我的备注内容
    const [remarkInfo, setRemarkInfo] = useState(content.remark ? content.remark : '');
    const [isReasonVisible, setIsReasonVisible] = useState(false);
    // 我的备注内容备份
    const [remarkInfoBackup, setRemarkInfoBackup] = useState('');
    // 是否可以编辑我的备注
    const [canModifyRemark, setCanModifyRemark] = useState(false);
    const remarkTARef: any = useRef(null);
    const appId = useAppId();
    const [descriptionStr, setDescriptionStr] = useState('');
    const { imService, appRuntimeEnv, authInfo, permissions } = useContext(GlobalContext);
    const scrollRef = useRef(null);
    let isCreateMeetingBtn = false;
    useEffect(() => {
        analysisLog('xtbg_calhome_1640073907694', '9');
        calendarImService?.setScheduleId(scheduleId);
        return () => {
            setImDrawerVisible?.(false);
            calendarImService?.clearSessionId();
            calendarImService?.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // const info = <p style="display:none;">{"type":"meeting","meetingType":0,"title":"测试创建视频会议","meetingId":"65690069","meetingUuid":"fwEYbfNpS2GpFKaSb6e/fA==","password":"123456"} </p>
        let info = schedule.description || '';
        const index = info.indexOf('<p style="display:none;">');
        let descriptionStr = info.split('<p style="display:none;">')[0] || '';
        setDescriptionStr(descriptionStr);
        if (index === -1) {
            setMeetingInfo(null);
            return;
        }
        info = info.slice(index);
        info = info.replace('<p style="display:none;">', '').replace('</p>', '').trim();
        if (!info) {
            setMeetingInfo(null);
            return;
        }
        try {
            FocusSDK.printLog?.('打印meetingInfo信息：', info);
            const meetingInfo: IMeetingModal = JSON.parse(info);

            if (
                meetingInfo.meetingId &&
                meetingInfo.meetingType === 0 &&
                meetingInfo.type === 'meeting'
            ) {
                if (
                    config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] ||
                    meetingInfo.meetingPlatform === MeetingPlatform.HW
                ) {
                    if (!meetingInfo.meetingPlatformName && !!conferencePlatforms.length) {
                        const platform = conferencePlatforms.find(
                            (item) => item.platformType === meetingInfo.meetingPlatform
                        );
                        meetingInfo.meetingPlatformName = platform?.platformName || '';
                    }
                    setMeetingInfo(meetingInfo);
                }
            }
        } catch (error) {
            FocusSDK.printLog?.('meetingInfo解析报错：', error);
        }
    }, [conferencePlatforms, schedule.description]);
    useEffect(() => {
        let isCreatorFlag = false;
        let isParticipantFlag = false;
        // 判断我是代建人
        let isAgent =
            content.agent === Agent.AgentAllowed && content.ownerUser?.userId === userData.userId;
        setIsAgentFlag(isAgent);
        if (userData?.userId === content.user?.userId) {
            setIsCreator(true);
            isCreatorFlag = true;
        } else {
            setIsCreator(false);
            // 若不是创建者需要判断是否已经接受该日程
            let yourStatus = content.participant?.filter((item: any) => {
                return item.user.userId === userData?.userId;
            });
            if (yourStatus?.length) {
                // 表示你存在与参会人员列表中
                setResStatus(yourStatus[0].resStatus);
                setIsParticipant(true);
                isParticipantFlag = true;
            } else if (yourStatus) {
                // 表示你不存在于参会人员列表中，在订阅了别人的日历的情况下会出现
                setIsParticipant(false);
            }
        }
        let { participant } = content;
        participant = participant.filter((p: any) => {
            return p.user.userId !== content.user?.userId;
        });
        let onlySelf = true;
        if (content.agent !== Agent.AgentAllowed && participant.length === 0) {
            setIsNoChatSelfSchedule(true);
        } else {
            onlySelf = false;
            setIsNoChatSelfSchedule(false);
        }
        setRemarkInfo(content.remark || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        content,
        userData,
        setImDrawerVisible,
        calendarImService,
        from,
        schedule,
        scheduleId,
        selectedScheduleTime,
        setScheduleInfo,
        isNoChatSelfSchedule,
        // isChatInited,
        closeHandle,
        history,
        workDrawVisible,
        changePersonalTaskDraw,
        workTaskId,
    ]);
    useEffect(() => {
        let calendar = calendarMap[schedule.calendarId] || {};
        const { userType } = calendar;
        // 只有查看详情权限的用户不能删除该日程
        let notMy = false;
        const idx = calendarList?.findIndex((item: any) => item.calendarId === calendar.calendarId);
        if (idx < 0) {
            notMy = true;
        }
        // if (
        //     userType !== UserType.TeamSubscriber &&
        //     userType !== UserType.PersonalSubscribed &&
        //     userType !== UserType.PersonalSubscriber &&
        //     !notMy
        // ) {
        //     setCanDelete(true);
        // } else {
        if (
            (content.agent === Agent.AgentAllowed &&
                content.ownerUser?.userId === userData.userId) ||
            content.user?.userId === userData.userId ||
            isParticipant
        ) {
            setCanDelete(true);
        } else {
            setCanDelete(false);
        }
        // }
        // 专班成员都是日历编辑者，可编辑日历下的所有日程
        if (userType === UserType.TeamEditor) {
            setCanModify(true);
        }
    }, [schedule, calendarMap, calendarList, content, userData, isParticipant]);

    // 点击编辑备注按钮自动聚焦文本域
    useEffect(() => {
        remarkTARef.current?.focus();
    }, [canModifyRemark]);

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId: userData?.ddAppId || appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    function setScheduleRemarkOfMine(remark: string) {
        let value = remark;
        content.remark = value;
        setRemarkInfo(value || '');
    }

    function askScheduleHandle(type: ResStatus) {
        if (type === ResStatus.Reject) {
            setIsReasonVisible(true);
        } else {
            saveAskSchedule(type);
        }
        // closeHandle();
    }

    function onOkHandler(reason: string) {
        if (!reason) {
            toast.error('请填写拒绝原因');
            return;
        }
        saveAskSchedule(ResStatus.Reject, reason);
        setIsReasonVisible(false);
    }

    function onCancelHandler() {
        setIsReasonVisible(false);
    }

    function saveAskSchedule(type: ResStatus, rejectReason = '') {
        askSchedule({
            scheduleId: scheduleId,
            user: {
                userId: userData?.userId,
                teamId: userData?.team.teamId,
            },
            resStatus: type,
            beforeBeginTime: content.beginTime,
            beforeEndTime: content.endTime,
            message: rejectReason,
            resType: 1,
        })
            .then(() => {
                clearScheduleMap({});
                getScheduleListByTime({
                    calendarId: selectedCalendarList.join(','),
                    beginTime: selectedScheduleTime.subtract(24 * ScheduleDateScope, 'hour'),
                    endTime: selectedScheduleTime.add(24 * ScheduleDateScope, 'hour'),
                });
                bus.emit('calendar:reload', {
                    calendarId: schedule.calendarId,
                    beginTime: schedule.scheduleBeginTime,
                });
                setResStatus(type);
                const info =
                    type === ResStatus.Agree
                        ? t('accepted')
                        : type === ResStatus.Reject
                        ? t('rejected')
                        : t('unread');
                toast.success(`${info}${content.repeat !== 0 ? '该日程的所有重复日程' : ''}`);
                // 如果来源是卡片消息 点击接受或拒绝后关闭详情页
                if (from === ScheduleDetailFrom.Calendar) {
                    closeHandle();
                }
            })
            .catch(() => {
                toast.error('操作失败');
            });
    }

    async function handleSendCard(schedule: Schedule) {
        const { calendarId, beginTime, title, endTime } = schedule;
        let participant = schedule.participant || [];
        // 判断我是代建人
        const isAgent =
            content.agent === Agent.AgentAllowed && content.ownerUser?.userId === userData.userId;
        // 判断我是被代建人
        const isClient =
            content.agent === Agent.AgentAllowed && content.user?.userId === userData.userId;
        // 判断我是参与人
        let isParticipant = false;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < participant?.length; i++) {
            if (participant[i].user.userId === userData.userId) {
                isParticipant = true;
                break;
            }
        }
        const { teamUserInfo } = userData;
        const { realName, ddAppId } = teamUserInfo;

        const params = { scheduleId, calendarId, beginTime, endTime };
        const deepLink =
            'jdme://jm/page_schedule_detail?mparam=' + encodeURIComponent(JSON.stringify(params));
        const languageContents = [
            {
                language: 'zh_CN',
                title: '日程删除',
                content: `${realName}删除了日程【${title}】`,
            },
            {
                language: 'en_US',
                title: 'ScheduleDelete',
                content: `${realName} deleted the schedule [${title}], please check it out.`,
            },
        ];
        // 代建场景给代建/被代建人发通知消息
        if (content.agent === Agent.AgentAllowed) {
            const agentLangContents = [
                {
                    language: 'zh_CN',
                    title: '日程删除',
                    content: `${realName}删除了日程【${title}】`,
                },
                {
                    language: 'en_US',
                    title: 'ScheduleDelete',
                    content: `${realName} deleted the schedule [${title}], please check it out.`,
                },
            ];
            // 我是代建人，给被代建人发消息
            if (isAgent) {
                const toClientUser: { app: string; pin: string; teamId: string }[] = [
                    {
                        app: content.user?.ddAppId || userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                        pin: content.user?.userId,
                        teamId: content.user?.teamId,
                    },
                ];
                sendCalendarCard({
                    toUser: toClientUser,
                    deepLink,
                    languageContents: agentLangContents,
                });
            }
            // 我是被代建人，通知代建人
            else if (isClient) {
                const toAgentUser: { app: string; pin: string; teamId: string }[] = [
                    {
                        app: content.ownerUser?.ddAppId || userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                        pin: content.ownerUser?.userId,
                        teamId: content.ownerUser?.teamId,
                    },
                ];
                participant =
                    participant?.filter((item) => {
                        return item.user.userId !== content.ownerUser.userId;
                    }) || [];
                sendCalendarCard({
                    toUser: toAgentUser,
                    deepLink,
                    languageContents: agentLangContents,
                });
            }
        }
        // 我是参与人，通知创建人
        if (isParticipant) {
            const participantLanguageContents = [
                {
                    language: 'zh_CN',
                    title: '日程退出',
                    content: `${realName}退出了日程【${title}】`,
                },
                {
                    language: 'en_US',
                    title: 'ScheduleQuit',
                    content: `${realName} quitted the schedule [${title}], please check it out.`,
                },
            ];
            let sendToUsers: { app: string; pin: string; teamId: string }[] = [
                {
                    app: content.user?.ddAppId || userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                    pin: content.user?.userId,
                    teamId: content.user?.teamId,
                },
            ];
            // 日程是代建日程，且代建人不是我的情况，给代建人发消息卡片
            if (content.agent === Agent.AgentAllowed && !isAgent) {
                sendToUsers.push({
                    app: content.ownerUser?.ddAppId || userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                    pin: content.ownerUser?.userId,
                    teamId: content.ownerUser?.teamId,
                });
            }
            sendCalendarCard({
                toUser: sendToUsers,
                deepLink,
                languageContents: participantLanguageContents,
            });
        }

        // 给参与人发消息逻辑，若我是单纯的参与人(非代建且参与场景)则不发消息
        if (!participant || participant.length === 0 || (isParticipant && !isAgent)) {
            return;
        }
        const toUser = (participant as any).map((p: any) => ({
            app: p.user.appId || p.user.ddAppId || ddAppId || '',
            pin: p.user.userId,
            teamId: p.user.teamId,
        }));
        sendCalendarCard({ toUser, deepLink, languageContents });
    }
    function removeScheduleHandle(isRepeat: boolean, type?: ModifyType) {
        if (isParticipant) {
            removeTheParticipant();
        } else {
            removeTheSchedule(isRepeat, type);
        }
    }

    async function removeTheSchedule(isRepeat: boolean, type?: ModifyType) {
        let beginTime: any;
        let endTime: any;
        let optType;
        // 只删除单条
        if (!isRepeat) {
            beginTime = content.beginTime;
            endTime = content.endTime;
            optType = ScheduleDeleteType.All;
        } else {
            if (type === ModifyType.OnlyThisSchedule) {
                beginTime = schedule?.scheduleBeginTime?.valueOf();
                endTime = schedule?.scheduleEndTime?.valueOf();
                optType = ScheduleDeleteType.Some;
            } else {
                // 删除从现在开始的日程
                beginTime = schedule?.scheduleBeginTime?.valueOf();
                endTime = schedule?.scheduleEndTime?.valueOf();
                optType = ScheduleDeleteType.FromNow;
            }
        }
        const source = calendarMap?.[content.calendarId]?.type || 1;
        // 删除的时候需要先查一下日程关联的群组状态
        let groupInfo: any = {};
        if (content.groupId) {
            groupInfo = await getGroupInfo({
                groupIds: [{ groupId: content.groupId }],
            });
        }
        const isGroupDismissed = groupInfo?.groups?.[0]?.status !== 1;
        if (content.groupId && Object.keys(groupInfo).length && !isGroupDismissed) {
            setDismissGroupModalParams({
                scheduleId: scheduleId,
                beginTime: beginTime,
                endTime: endTime,
                // 这里的some即删除这一条
                optType: optType,
                source: source,
                isRepeat: isRepeat,
                type: type,
            });
            setDismissGroupModalVisible(true);
        } else {
            setCalendarLoading(true);
            removeSchedule({
                scheduleId: scheduleId,
                beginTime: beginTime,
                endTime: endTime,
                // 这里的some即删除这一条
                optType: optType,
                source,
            }).then(() => {
                handleSendCard(content);
                clearScheduleMap({});
                getScheduleListByTime({
                    calendarId: selectedCalendarList.join(','),
                    beginTime: selectedScheduleTime.subtract(24 * ScheduleDateScope, 'hour'),
                    endTime: selectedScheduleTime.add(24 * ScheduleDateScope, 'hour'),
                });
                bus.emit('calendar:reload', {
                    calendarId: schedule.calendarId,
                    beginTime: schedule.scheduleBeginTime,
                });
                closeHandle();
                // calendarImService?.disbandGroupByCalendarId(scheduleId);
                setTimeout(() => {
                    setCalendarLoading(false);
                }, 300);
            });
        }
    }
    function delScheduleSycDismissGroup(dismissGroup?: boolean) {
        const {
            scheduleId,
            beginTime,
            endTime,
            optType,
            source,
            isRepeat,
            type,
        } = dismissGroupModalParams;
        setDismissGroupModalVisible(false);
        let opts: any = {
            scheduleId: scheduleId,
            beginTime: beginTime,
            endTime: endTime,
            // 这里的some即删除这一条
            optType: optType,
            source,
        };
        if (dismissGroup) {
            opts.delGroup = true;
        }
        setCalendarLoading(true);
        removeSchedule(opts).then(() => {
            handleSendCard(content);
            clearScheduleMap({});
            setDismissGroupModalParams({});
            getScheduleListByTime({
                calendarId: selectedCalendarList.join(','),
                beginTime: selectedScheduleTime.subtract(24 * ScheduleDateScope, 'hour'),
                endTime: selectedScheduleTime.add(24 * ScheduleDateScope, 'hour'),
            });
            bus.emit('calendar:reload', {
                calendarId: schedule.calendarId,
                beginTime: schedule.scheduleBeginTime,
            });
            closeHandle();
            // calendarImService?.disbandGroupByCalendarId(scheduleId);
            setTimeout(() => {
                setCalendarLoading(false);
            }, 300);
        });
    }

    function removeTheParticipant() {
        const participant = content.participant?.filter(
            (item: any) => item.user.userId === userData?.userId
        );
        removeParticipant({
            scheduleId: scheduleId,
            user: participant.length > 0 && participant[0].user,
        })
            .then((res) => {
                handleSendCard(content);
                clearScheduleMap({});
                getScheduleListByTime({
                    calendarId: selectedCalendarList.join(','),
                    beginTime: selectedScheduleTime.subtract(24 * ScheduleDateScope, 'hour'),
                    endTime: selectedScheduleTime.add(24 * ScheduleDateScope, 'hour'),
                });
                bus.emit('calendar:reload', {
                    calendarId: schedule.calendarId,
                    beginTime: schedule.scheduleBeginTime,
                });
                closeHandle();
                // calendarImService?.removeMembers([
                //     participant.length > 0 && participant[0].user.userId,
                // ]);
                setTimeout(() => {
                    setCalendarLoading(false);
                }, 300);
            })
            .catch((e) => {
                toast.error('日程删除失败！');
            });
    }

    function modifySchedule(isRepeat: boolean, type?: ModifyType) {
        openCreateModal({
            content: {
                ...content,
                scheduleId: scheduleId,
                scheduleBeginTime: schedule?.scheduleBeginTime,
                scheduleEndTime: schedule?.scheduleEndTime,
                modifyType: type,
            },
            visible: true,
        });
        closeHandle();
    }

    function openConfirmModal(mode: ScheduleChangeMode) {
        setScheduleChangeMode(mode);
        // confirm({
        //     maskStyle: { zIndex: 2000 },
        //     title: t('Do you Want to delete this schedule'),
        //     // content: 'Some descriptions',
        //     okText: t('confirm'),
        //     cancelText: t('cancel'),
        //     className: 'delete-confirm-popup',
        //     onOk() {
        //         if (content.repeat) {
        //             setModalVisible(true);
        //         } else {
        //             // 非重复数据直接进行操作
        //             if (mode === ScheduleChangeMode.Modify) {
        //                 // 设置
        //                 modifySchedule(false);
        //             } else {
        //                 removeScheduleHandle(false);
        //             }
        //         }
        //     },
        //     zIndex: 2300,
        //     centered: true,
        // });
        const contentInfo = isParticipant
            ? t('Do participant want to delete this schedule')
            : t('Do you Want to delete this schedule');
        if (content.repeat) {
            if (mode === ScheduleChangeMode.Modify) {
                setModalVisible(true);
            } else {
                if (isParticipant) {
                    prompt({
                        title: t('Delete schedule'),
                        icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                        onOk: async () => {
                            removeScheduleHandle(false);
                        },
                        content: t('Do participant want to delete this repeat schedule'),
                        okText: t_common('button.ok'),
                        cancelText: t_common('button.cancel'),
                    });
                } else {
                    prompt({
                        title: t('Delete schedule'),
                        icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                        onOk: async () => {
                            setModalVisible(true);
                        },
                        content: contentInfo,
                        okText: t_common('button.ok'),
                        cancelText: t_common('button.cancel'),
                    });
                }
            }
        } else {
            // 非重复数据直接进行操作
            if (mode === ScheduleChangeMode.Modify) {
                // 设置
                modifySchedule(false);
            } else {
                prompt({
                    title: t('Delete schedule'),
                    icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                    onOk: async () => {
                        removeScheduleHandle(false);
                    },
                    content: contentInfo,
                    okText: t_common('button.ok'),
                    cancelText: t_common('button.cancel'),
                });
            }
        }
    }

    function closeConfirmModal() {
        setModalVisible(false);
    }
    function closeDismissGroupModal() {
        setDismissGroupModalVisible(false);
        setDismissGroupModalParams({});
    }

    function scheduleModifyHub(type: ModifyType) {
        closeConfirmModal();
        if (scheduleChangeMode === ScheduleChangeMode.Modify) {
            // 设置
            modifySchedule(true, type);
        } else {
            removeScheduleHandle(true, type);
        }
    }
    // 弹窗确认是否替换当前日程详情页
    function replaceCalendarDetailDraw() {
        if (
            (workDrawVisible && scheduleInfoDraw.visible) ||
            (scheduleInfoDraw.visible && scheduleInfoDrawOther.visible)
        ) {
            Modal.confirm({
                content: (
                    <>
                        是否进入该日程的沟通组？
                        <br />
                        确认后原{workDrawVisible ? '任务' : '日程'}详情和沟通组将关闭。
                    </>
                ),
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    // 如果是从任务沟通群组分享过来的 则先关闭任务详情Draw
                    if (workDrawVisible) {
                        changePersonalTaskDraw({
                            taskId: workTaskId,
                            drawVisible: false,
                            chat: false,
                        });
                        // calendarImService?.destroy();
                    }
                    if (scheduleInfoDraw.visible && scheduleInfoDrawOther.visible) {
                        if (scheduleInfoDraw.scheduleId !== scheduleId) {
                            changeScheduleInfoDraw({
                                visible: false,
                                scheduleId: '',
                            });
                            calendarImService?.destroy();
                        }
                        if (scheduleInfoDrawOther.scheduleId !== scheduleId) {
                            changeScheduleInfoDrawOther({
                                visible: false,
                                scheduleId: '',
                            });
                            calendarImService?.destroy();
                        }
                    }
                    handleChat();
                },
            });
        } else {
            handleChat();
        }
    }

    function createGroupDraw() {
        // 首先清空原来的selectSession 防止接口请求慢的时候看到其他的群组信息
        clearSelectSession();
        calendarImService?.setSessionId(content.groupId);
        if (
            history.location?.pathname.indexOf('/messages') > -1 ||
            from === ScheduleDetailFrom.AINoticeCard ||
            from === ScheduleDetailFrom.ChatShare
        ) {
            closeHandle();
            // history.push(`/messages/g/${content.groupId}?top=true`);
            setIsGroupCreating(false);
        } else {
            setTimeout(() => {
                setImDrawerVisible?.(true);
                setIsGroupCreating(false);
            }, 150);
        }
    }

    async function createGroupConfirm(createParams: CreateGroupType) {
        const { ddAppId } = userData;
        Modal.confirm({
            content: '该组已解散，是否重新创建组？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                setIsGroupCreating(true);
                const result = await calendarImService?.forceCreateCalendarGroup(createParams);
                if (result?.[0]) {
                    content.groupId = result?.[0]?.groupId;
                    createGroupDraw();
                    setIsGroupCreating(false);
                } else {
                    setIsGroupCreating(false);
                    message.destroy();
                    message.warning(JSON.stringify(result?.[1]));
                }
            },
        });
        setIsGroupCreating(false);
    }

    async function joinGroupConfirm() {
        Modal.confirm({
            content: '您不在组内，是否选择加入？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                setIsGroupCreating(true);
                const result = await calendarImService?.joinGroup(content.groupId, {
                    app: userData.ddAppId,
                    pin: userData.userId,
                    teamId: userData.team.teamId,
                });
                console.log('加入群组result', result);
                if (result === 0) {
                    createGroupDraw();
                    setIsGroupCreating(false);
                } else {
                    setIsGroupCreating(false);
                    message.destroy();
                    message.warning('加入群组异常');
                }
            },
        });
        setIsGroupCreating(false);
    }

    async function createCalendarGroup(createParams: CreateGroupType) {
        const result = await calendarImService?.createCalendarGroup(createParams);
        if (result?.[0]) {
            content.groupId = result?.[0]?.groupId;
            createGroupDraw();
            // setIsChatInited(true);
            setIsGroupCreating(false);
        } else {
            setIsGroupCreating(false);
            message.destroy();
            message.warning(JSON.stringify(result?.[1]));
        }
    }

    async function handleChat() {
        setIsGroupCreating(true);
        if (imDrawerVisible) {
            setIsGroupCreating(false);
            setImDrawerVisible?.(false);
            // setIsChatInited(false);
            return;
        } else {
            const { ownerUser } = content;
            const { ddAppId } = userData;
            let createParams = {
                scheduleId,
                from: {
                    app: ddAppId || process.env.REACT_APP_DD_APPID || 'shb',
                    pin: content.user.userId,
                    teamId: content.user.teamId,
                    userId: content.user.userId,
                },
                body: {
                    modifyFiled: 1,
                    nickName: content.user.realName,
                    name: schedule.title,
                    notice: '群公告',
                    gid: '',
                    invitees: content.participant.map((i: any) => {
                        return {
                            pin: i.user.userId,
                            app: ddAppId,
                            nickName: i.user.realName,
                            teamId: i.user.teamId,
                            userId: i.user.userId,
                        };
                    }),
                },
                kind: 1024,
                subKind: 6,
            };
            if (content.agent === Agent.AgentAllowed) {
                createParams.body.invitees.push({
                    pin: ownerUser?.userId,
                    app: ddAppId,
                    nickName: ownerUser?.realName,
                    teamId: ownerUser?.teamId,
                    userId: ownerUser?.userId,
                });
            }
            // 查询群组状态
            if (content.groupId) {
                getGroupInfo({
                    groupIds: [{ groupId: content.groupId }],
                }).then((res) => {
                    let isGD = res?.groups?.[0]?.status !== 1;
                    let isIG = res?.groups?.[0]?.flag === 3;
                    // setIsGroupDismissed(isGD);
                    // setIsInGroup(isIG);
                    if (!isGD && isIG) {
                        createGroupDraw();
                        return;
                    } else if (isGD) {
                        createGroupConfirm(createParams);
                        return;
                    } else if (!isGD && !isIG) {
                        joinGroupConfirm();
                        return;
                    }
                });
            } else {
                createCalendarGroup(createParams);
            }
        }
    }

    // 立即沟通或收起群组
    function handleChatNow() {
        switch (from) {
            case ScheduleDetailFrom.Calendar:
            case ScheduleDetailFrom.Supervise:
            case ScheduleDetailFrom.Meeting:
            case ScheduleDetailFrom.AINoticeCard:
            case ScheduleDetailFrom.ChatShare:
            case ScheduleDetailFrom.Search:
                handleChat();
                break;
            case ScheduleDetailFrom.WinNoticeCard:
            case ScheduleDetailFrom.ScheduleChatShare:
                if (imDrawerVisible) {
                    setIsGroupCreating(false);
                    setImDrawerVisible?.(false);
                    // setIsChatInited(false);
                    return;
                } else {
                    // 场景1.已经打开了一个日程详情沟通组双页，通过提醒又点击了一个日程详情
                    //
                    if (
                        ((scheduleInfoDraw.visible && !scheduleInfoDrawOther?.visible) ||
                            (scheduleInfoDrawOther.visible && !scheduleInfoDraw?.visible)) &&
                        !workDrawVisible
                    ) {
                        // 没有打开着任何日程详情页
                        handleChat();
                    } else if (
                        scheduleInfoDraw.visible &&
                        scheduleInfoDrawOther.visible &&
                        scheduleInfoDraw.scheduleId === scheduleInfoDrawOther.scheduleId
                    ) {
                        // 打开着两个日程详情页，并且两个日程详情页是同一个日程
                        closeHandle();
                    } else {
                        // 打开着两个日程详情页，并且两个日程详情页是不是同一个日程，确认提示后关闭当前日程详情，当前日程详情内容替换已有双页详情内容
                        replaceCalendarDetailDraw();
                    }
                }
                break;
            case ScheduleDetailFrom.ChatBanner:
                closeHandle();
                break;
            default:
                break;
        }
    }

    function getRemindText(v: any) {
        let text: any = {
            '0': t('no-remind'),
            '-0': t('when-begin'),
            '-5': t('schedule-start-minute').replace('%s', '5'),
            '-10': t('schedule-start-minute').replace('%s', '10'),
            '-15': t('schedule-start-minute').replace('%s', '15'),
        };
        text['-30'] = t('schedule-start-minute').replace('%s', '30');
        text['-60'] = t('开始前1小时');
        text[`-${60 * 2}`] = t('开始前2小时');
        text[`-${60 * 24}`] = t('开始前1天');
        text[`-${60 * 24 * 2}`] = t('开始前2天');

        text[`${60 * 9}`] = '当天 9:00';
        text[`-${60 * (24 - 9)}`] = '提前1天 9:00';
        text[`-${60 * (24 * 2 - 9)}`] = '提前2天 9:00';
        text[`-${60 * (24 * 7 - 9)}`] = '提前1周 9:00';

        return text[v] || '';
    }

    const debounceToast = useDebounce((str: string, action: 'error') => {
        toast[action](str);
    }, 1500);

    const handleJoinMeeting = useThrottle(() => {
        if (!meetingInfo) {
            return;
        }
        joinMeetingOperation(authInfo, meetingInfo, newPublicResult, () => {
            closeHandle();
        });
    }, 2000);

    const handleShareClick = useCallback(() => {
        const projectId = calendarMap?.[content.calendarId]?.extended?.projectId;
        const { teamUserInfo, userId } = userData;
        const { ddAppId, teamId, realName } = teamUserInfo;
        if (!schedule) {
            return;
        }
        closeHandle();
        openUserSelectionModal(
            {
                title: t('share to friend'),
                tabs: !config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recent', 'groupCanBeSel', 'externalContacts']
                    : ['recent', 'org', 'groupCanBeSel', 'externalContacts'],
                // frozenIds: list.map((item) => item.userId + ':' + item.app + ':' + item.teamId),
                // resultUsers: list.map((item) => ({
                //     id: item.userId + ':' + item.app + ':' + item.teamId,
                //     name: item.realName,
                //     avatar: item.headImg,
                //     checked: true,
                //     userId: item.userId,
                //     teamId: item.teamId,
                //     visible: false,
                // })),
                contactsFilterFlag: 'external',
                currentUser: {
                    app: ddAppId,
                    pin: userId,
                    teamId: teamId,
                },
            },
            async (data, close) => {
                // 过滤掉已选中的
                const { result } = data.data;
                if (!result.length) {
                    return close();
                }
                if (!schedule) {
                    return;
                }
                const addArr: any[] = [];
                const groupArr: any[] = [];
                const originGroupArr: any[] = [];
                result.forEach((r) => {
                    if (r.isGroup) {
                        originGroupArr.push(r);
                        return groupArr.push(r.id);
                    }
                    if (r.id) {
                        const strs = r.id.split(':');
                        return addArr.push({
                            userId: strs[0],
                            teamId: strs[2],
                            app: strs[1],
                            headImg: r.avatar,
                            realName: r.name,
                        });
                    }
                });
                close();
                const sharelink = {
                    ext: ``,
                    icon:
                        'http://storage.jd.com/jd.jme.photo/message_icon_schedule2x.png?Expires=3687851871&AccessKey=93c0d2d5a6cf315c3d4c52c5f549a9a886b59f76&Signature=eADExHDPxJlqpqGBhAWpXUo6nmw%3D',
                    // source: 'joyWork',
                    title: t('share card title').replace('%s', realName),
                    content: `${schedule.title}\n开始时间:${dayjs(
                        schedule.scheduleBeginTime
                    ).format('YYYY-MM-DD HH:mm')}\n结束时间:${dayjs(
                        schedule.scheduleEndTime
                    ).format('YYYY-MM-DD HH:mm')}`,
                    url: DeeplinkEvent.strify({
                        appId: ddAppId,
                        path: DeeplinkPathEnum.Schedule_Draw,
                        mparam: {
                            projectId: projectId || '',
                            content: schedule.description,
                            scheduleId: schedule.scheduleId || scheduleId,
                            beginTime: schedule.scheduleBeginTime.valueOf(),
                            endTime: schedule.scheduleEndTime.valueOf(),
                            isNeedCheck: true,
                            calendarId: schedule.calendarId,
                            color: schedule.color,
                        },
                    }),
                    category: 'schedule',
                };
                const msg: ChatMessageBody = {
                    type: ChatMessageType.TEMPLATE2,
                    template: {
                        nativeId: 'share_link',
                    },
                    code: 0,
                    data: [
                        {
                            sharelink: sharelink,
                            type: 0,
                        },
                    ],
                };
                let shareRecipientDtos: ShareRecipientDto[] = [];

                for (let item of addArr) {
                    shareRecipientDtos.push({
                        recipientType: '1',
                        recipientId: item.userId,
                        recipientName: item.realName,
                        teamId: item.teamId,
                        app: item.app,
                    });
                    const session = await imService.createSingleSession({
                        app: item.app,
                        pin: item.userId,
                        teamId: item.teamId,
                    });

                    if (session) {
                        const messageId = generateMessageId();
                        await imService.sendChatMessage(session.sessionId, messageId, msg);
                    }
                }
                originGroupArr.forEach((og) => {
                    shareRecipientDtos.push({
                        recipientType: '2',
                        recipientId: og.id,
                        recipientName: og.name,
                        app: ddAppId,
                    });
                    // const strs = r.id.split(':');
                    //     return addArr.push({
                    //         userId: strs[0],
                    //         teamId: strs[2],
                    //         app: strs[1],
                    //         headImg: r.avatar,
                    //         realName: r.name,
                    //     });
                });
                groupArr.forEach((a) => {
                    const sessionId = a;
                    const messageId = generateMessageId();
                    imService.sendChatMessage(sessionId, messageId, msg);
                });
                await shareScheduleAttachment({
                    bizId: schedule.scheduleId || scheduleId,
                    bizType: 2,
                    shareRecipientDtos: shareRecipientDtos,
                }).then((res: any[]) => {
                    console.log(res);
                    // 报错处理
                    if (res[1]) {
                        console.log('分享日程文档权限报错:', res);
                        message.error(res[1].toString());
                    }
                    // 正常返回
                    else {
                        toast.success(t('share success'));
                    }
                });
            },
            userData
        );
    }, [
        calendarMap,
        content.calendarId,
        userData,
        schedule,
        closeHandle,
        t,
        scheduleId,
        imService,
    ]);
    function handleFileDown(item: any) {
        if (item.fileType.startsWith('joyspace')) {
            const path = buildPagePathKey(Number(item.fileType.split('/')[1])).subPath;
            let newUrl = `${joyspaceHost}/${path}/${item.fileId}`;
            let realUrl = replaceGovUrl(newUrl);
            return DeeplinkEvent.open(realUrl);
        }
    }

    const handleDuClick = useCallback(() => {
        const { participant = [], calendarId, beginTime, title, endTime, user } = content;
        const { teamUserInfo, userId } = userData;
        const { realName, ddAppId, teamId, headImg } = teamUserInfo;
        const userList: SuperviseUserInfo[] = (participant as any).map((p: any) => ({
            appId: p.user.appId || p.user.ddAppId || ddAppId || '',
            userId: p.user.userId,
            teamId: p.user.teamId,
            realName: p.user.realName,
            headImg: p.user.imageUrl,
        }));
        userList.push({
            appId: user.appId || user.ddAppId || ddAppId || '',
            userId: user.userId,
            teamId: user.teamId,
            realName: user.realName,
            headImg: user.imageUrl,
        });
        // 添加当前登陆人
        // const index = userList.findIndex(
        //     (u) => `${u.teamId}:${u.appId}:${u.userId}` === `${teamId}:${ddAppId}:${userId}`
        // );
        // if (index === -1) {
        //     userList.push({
        //         appId: ddAppId,
        //         userId,
        //         teamId,
        //         realName,
        //         headImg,
        //     });
        // }
        const projectSchedule = calendarMap?.[content.calendarId]?.extended?.projectId;
        closeHandle();
        openCreateSuperviseModal({
            visible: true,
            createData: {
                title,
                userList,
                from: EsuperviseSourceType.SCHEDULE,
                shceduleInfo: {
                    beginTime,
                    endTime,
                    calendarId,
                    scheduleId,
                },
                projectId: projectSchedule || '',
                sourceBizType: projectSchedule ? 'PROJECT' : '',
            },
        });
    }, [content, openCreateSuperviseModal, scheduleId, userData, closeHandle, calendarMap]);
    const isAgent =
        content.agent === Agent.AgentAllowed && content.ownerUser?.userId === userData.userId;
    const isClient =
        content.agent === Agent.AgentAllowed && content.user?.userId === userData.userId;
    const isDebtee = /,"meetingType":1,/.test(content?.description);
    console.log('---isDebtee:', isDebtee);
    const [headerFixedBgOpacity, setHeaderFixedBgOpacity] = useState(1);
    return (
        <div
            className={`${style['calendar-tooltip-render-wrapper']} calendar-tooltip-render-wrapper`}
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
            onDoubleClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <ScrollView
                className={`calendar-tooltip-content ${
                    isAgent || isClient ? 'calendar-detail-tooltip-content' : ''
                }`}
                ref={scrollRef}
                onScroll={(e: any) => {
                    const headerContentEl = document.getElementById(
                        'calendar-detail-header-content'
                    );
                    const headerContentHeight: number = headerContentEl?.offsetHeight || 0;
                    if (e.target.scrollTop > 0) {
                        if (e.target.scrollTop < headerContentHeight) {
                            setHeaderFixedBgOpacity(
                                1 - Number(e.target.scrollTop) / headerContentHeight
                            );
                        } else {
                            setHeaderFixedBgOpacity(0);
                        }
                    } else {
                        setHeaderFixedBgOpacity(1);
                    }
                }}
            >
                <div className="calendar-header-fixed-bg">
                    <div id="calendar-header-fixed" className="calendar-header-fixed">
                        <div
                            className="calendar-tooltip-header"
                            style={{
                                background: `linear-gradient(180deg, rgba(${
                                    207 + (255 - 207) * (1 - headerFixedBgOpacity)
                                },${
                                    219 + (255 - 219) * (1 - headerFixedBgOpacity)
                                },255,1) 0%, rgba(${
                                    209 + (255 - 209) * (1 - headerFixedBgOpacity)
                                },${220 + (255 - 220) * (1 - headerFixedBgOpacity)},255,1) 100%)`,
                            }}
                        >
                            <div className="calendar-tooltip-header-title">日程详情</div>
                            <div>
                                {(isAgent || isCreator || canModify) &&
                                content.archive !== 0 &&
                                !isDebtee ? (
                                    <IconFont
                                        type="iconrdit"
                                        onClick={() => openConfirmModal(ScheduleChangeMode.Modify)}
                                    />
                                ) : null}
                                {content.archive !== 0 &&
                                    content.comeFrom !== 5 &&
                                    !isBusy &&
                                    !isDebtee && (
                                        <IconFont
                                            type="iconzw_app_remind1"
                                            onClick={handleDuClick}
                                            clstag="pageclick|keycount|xtbg_calhome_1640073907694|19"
                                        />
                                    )}
                                {content.archive !== 0 && !isBusy && (
                                    <IconFont
                                        type="iconshare"
                                        onClick={handleShareClick}
                                        clstag="pageclick|keycount|xtbg_calhome_1640073907694|16"
                                    />
                                )}
                                {canDelete && !isBusy && content.archive !== 0 ? (
                                    <IconFont
                                        type="icondelect"
                                        onClick={() => openConfirmModal(ScheduleChangeMode.Delete)}
                                        clstag="pageclick|keycount|xtbg_calhome_1640073907694|17"
                                    />
                                ) : null}
                                <IconFont
                                    type="iconClose"
                                    style={{ fontSize: '14px', color: 'BFC1C4' }}
                                    onClick={closeHandle}
                                    clstag="pageclick|keycount|xtbg_calhome_1640073907694|18"
                                />
                            </div>
                        </div>
                        {(isAgent || isClient) && (
                            <div className="schedule-agent-info">
                                <p>
                                    {t('agent-person')}：{content.ownerUser?.realName}
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        id="calendar-detail-header-content"
                        className="calendar-detail-header-content"
                        style={{
                            marginTop: `${isAgent || isClient ? '93px' : '53px'}`,
                        }}
                    >
                        <div className="title-content">
                            {content.comeFrom !== 5 && (
                                <span
                                    className="icon"
                                    style={{
                                        backgroundImage: `url(${getTypeImage(schedule.color)})`,
                                    }}
                                />
                            )}
                            <Tooltip
                                placement="bottom"
                                arrowPointAtCenter
                                overlayClassName="calendar-detail-title-tooltip"
                                title={isBusy ? busyRealName + ` ` + t('busy') : content.title}
                            >
                                <p
                                    className="title"
                                    style={{
                                        // 优先显示日程颜色，当为空时显示所在日历的颜色，还为空显示我的日历的日历颜色
                                        color: '#000000',
                                        userSelect: 'text',
                                    }}
                                >
                                    {isBusy ? (
                                        <>
                                            {busyRealName + ` `}
                                            {t('busy')}
                                        </>
                                    ) : (
                                        <>
                                            {content.title}
                                            {(isClient || isAgent) && (
                                                <span className="agent-tag">{t('agent')}</span>
                                            )}
                                        </>
                                    )}
                                </p>
                            </Tooltip>
                        </div>
                        {/* 时间 */}
                        <p className="header-info info info-important">
                            <IconFont
                                className="icon repeat-time-icon"
                                type="iconic_pc_calender_time"
                            />
                            <span>
                                {formatScheduleTime(
                                    schedule.scheduleBeginTime,
                                    schedule.scheduleEndTime,
                                    schedule.isAlldate,
                                    t
                                )}
                            </span>
                        </p>
                        {/* 重复 */}
                        {content.repeat !== 0 && (
                            <p className="header-info info info-important">
                                <IconFont
                                    className="icon repeat-content-icon"
                                    type="iconic_pc_calender_repeat1"
                                />
                                <span>
                                    {t(getRepeatName(content?.repeat))}重复
                                    {content?.repeatEndTime
                                        ? '，' +
                                          generateDefaultTimeFormat(
                                              dayjs(content?.repeatEndTime),
                                              t
                                          ).date +
                                          '结束'
                                        : ''}
                                </span>
                            </p>
                        )}
                        {/* 立即沟通与会议纪要 */}
                        <div className="calendar-detail-chat-summary">
                            {/* 立即沟通 */}
                            {setImDrawerVisible &&
                                !isBusy &&
                                !isNoChatSelfSchedule &&
                                !isDebtee &&
                                (isCreator || isParticipant || isAgent) && (
                                    <div className="chat-summary">
                                        <Button
                                            className="chat-btn"
                                            onClick={handleChatNow}
                                            loading={isGroupCreating}
                                            clstag={
                                                imDrawerVisible
                                                    ? ''
                                                    : 'pageclick|keycount|xtbg_calhome_1640073907694|15'
                                            }
                                            icon={
                                                imDrawerVisible ? (
                                                    // <IconFont
                                                    //     type="iconic_pc_task_collapsegroup"
                                                    //     className="chat-collapse-group"
                                                    // />
                                                    <CalendarIcon
                                                        src={putawayPng}
                                                        width={32}
                                                        height={32}
                                                    />
                                                ) : (
                                                    // <CommentOutlined />
                                                    <CalendarIcon
                                                        src={communicationPng}
                                                        width={32}
                                                        height={32}
                                                    />
                                                )
                                            }
                                        >
                                            {imDrawerVisible ? '收起聊天' : '立即沟通'}
                                        </Button>
                                    </div>
                                )}
                            {/* 会议纪要 */}
                            {content.comeFrom !== 5 && !isBusy && (
                                <>
                                    {/* 会议纪要 */}
                                    {(isCreator || isAgentFlag || isParticipant) &&
                                        content.meetingMinutes &&
                                        content.meetingMinutes.length === 0 &&
                                        !isDebtee && (
                                            <div className="chat-summary">
                                                <Button
                                                    className="chat-btn"
                                                    disabled={isCreateMeetingBtn}
                                                    onClick={() => {
                                                        isCreateMeetingBtn = true;
                                                        createMeetingMinutes({
                                                            scheduleId: scheduleId,
                                                        })
                                                            .then((res: any[]) => {
                                                                console.log(res);
                                                                if (res[1]) {
                                                                    isCreateMeetingBtn = false;
                                                                    message.error(
                                                                        res[1].toString()
                                                                    );
                                                                }
                                                                // 正常返回
                                                                else if (res[0]) {
                                                                    if (
                                                                        res[0].meetingMinutes &&
                                                                        res[0].meetingMinutes
                                                                            .length > 0
                                                                    ) {
                                                                        handleFileDown(
                                                                            res[0].meetingMinutes[0]
                                                                        );
                                                                    }
                                                                    if (setScheduleInfo) {
                                                                        getScheduleInfo({
                                                                            scheduleId: scheduleId,
                                                                        })
                                                                            .then((result: any) => {
                                                                                if (!result) {
                                                                                    message.warning(
                                                                                        t(
                                                                                            'schedule is not found'
                                                                                        )
                                                                                    );
                                                                                    isCreateMeetingBtn = false;
                                                                                    return;
                                                                                }
                                                                                // if (
                                                                                //     !imDrawerVisible &&
                                                                                //     isChatInited
                                                                                // ) {
                                                                                //     setIsChatInited(
                                                                                //         false
                                                                                //     );
                                                                                // }
                                                                                setScheduleInfo({
                                                                                    schedule: schedule,
                                                                                    selectedScheduleTime: selectedScheduleTime,
                                                                                    content: result,
                                                                                });
                                                                                isCreateMeetingBtn = false;
                                                                            })
                                                                            .catch((err) => {
                                                                                isCreateMeetingBtn = false;
                                                                                message.error(
                                                                                    err.message?.toString() ||
                                                                                        t(
                                                                                            'schedule is not found'
                                                                                        )
                                                                                );
                                                                            });
                                                                    } else {
                                                                        refreshSchedule();
                                                                        isCreateMeetingBtn = false;
                                                                    }
                                                                }
                                                            })
                                                            .catch((e) => {
                                                                isCreateMeetingBtn = false;
                                                                console.log(e);
                                                            });
                                                    }}
                                                    clstag="pageclick|keycount|xtbg_calhome_1640073907694|10"
                                                    icon={
                                                        <CalendarIcon
                                                            src={summaryPng}
                                                            width={32}
                                                            height={32}
                                                        />
                                                    }
                                                >
                                                    {t('record the minutes')}
                                                </Button>
                                            </div>
                                        )}
                                    {/* 会议纪要 */}
                                    {(isCreator || isAgentFlag || isParticipant) &&
                                        content.meetingMinutes &&
                                        content.meetingMinutes.length > 0 && (
                                            <div className="chat-summary">
                                                {content.meetingMinutes?.map((item: any) => {
                                                    return (
                                                        <Button
                                                            className="chat-btn"
                                                            clstag="pageclick|keycount|xtbg_calhome_1640073907694|10"
                                                        >
                                                            <Downloader
                                                                content={item}
                                                                key={item.fileId}
                                                                style={{ height: '24px' }}
                                                                source="summary"
                                                            />
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* 创建人 参与人 */}
                {content.comeFrom !== 5 && !isBusy && (
                    <>
                        {/* TODO：为谁代建和谁为我代建 创建人 */}
                        <IndicatorContent
                            indicator={() => (
                                <div className="calendar-tooltip-content-label">{`${t(
                                    'creator'
                                )}`}</div>
                            )}
                            className={style['creator']}
                        >
                            {content.user ? (
                                <p className={`info info-important ${style['name']}`}>
                                    {content.user?.realName}
                                </p>
                            ) : null}
                        </IndicatorContent>
                        {/* 参与人 */}
                        {content.participant?.length > 0 && (
                            <ParticipantIndicator
                                acceptCount={acceptCount}
                                unreadCount={unreadCount}
                                rejectCount={rejectCount}
                                content={content}
                                userData={userData}
                            />
                        )}
                    </>
                )}
                {/* 会议 提醒 地点 描述 */}
                {content.comeFrom !== 5 && !isBusy && (
                    <>
                        {/* 日程详情视频会议 - 日历 */}
                        {meetingInfo && (
                            <>
                                <IndicatorContent
                                    className="metting-title"
                                    indicator={() => (
                                        <div className="calendar-tooltip-content-label">
                                            {t('online metting')}
                                        </div>
                                    )}
                                >
                                    {meetingInfo.meetingPlatformName && (
                                        <p className={`info info-important ${style['name']}`}>
                                            {meetingInfo.meetingPlatformName}
                                        </p>
                                    )}
                                </IndicatorContent>
                                <IndicatorContent>
                                    <div className={`${style['info-bg-metting']}`}>
                                        <p>
                                            {t('meeting_id')}：
                                            <span style={{ display: 'inline-block' }}>
                                                {meetingInfo.meetingId}
                                                <IconFont
                                                    className="copy-icon"
                                                    type="iconic_pc_calender_copy"
                                                    onClick={() => {
                                                        const input = document.createElement(
                                                            'input'
                                                        );
                                                        document.body.appendChild(input);
                                                        input.setAttribute(
                                                            'value',
                                                            meetingInfo.meetingId
                                                        );
                                                        input.select();
                                                        if (document.execCommand('copy')) {
                                                            document.execCommand('copy');
                                                            message.success('复制成功');
                                                        }
                                                        document.body.removeChild(input);
                                                    }}
                                                />
                                            </span>
                                        </p>
                                        <p>
                                            {t('calendar password')}：
                                            {meetingInfo.password && (
                                                <span style={{ display: 'inline-block' }}>
                                                    {meetingInfo.password}{' '}
                                                    <IconFont
                                                        className="copy-icon"
                                                        type="iconic_pc_calender_copy"
                                                        onClick={() => {
                                                            const input = document.createElement(
                                                                'input'
                                                            );
                                                            document.body.appendChild(input);
                                                            input.setAttribute(
                                                                'value',
                                                                meetingInfo.password
                                                            );
                                                            input.select();
                                                            if (document.execCommand('copy')) {
                                                                document.execCommand('copy');
                                                                message.success('复制成功');
                                                            }
                                                            document.body.removeChild(input);
                                                        }}
                                                    />
                                                </span>
                                            )}
                                            {!meetingInfo.password && (
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        color: '#8f959e',
                                                        marginLeft: '2px',
                                                    }}
                                                >
                                                    {t('no_password')}
                                                </span>
                                            )}
                                        </p>
                                        {meetingInfo &&
                                        isFocusEnv() &&
                                        (config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] ||
                                            meetingInfo.meetingPlatform === MeetingPlatform.HW) ? (
                                            <BaseButton
                                                clstag="pageclick|keycount|xtbg_Pcschdule|JoininNow"
                                                className={style['button-join-meeting']}
                                                onClick={handleJoinMeeting}
                                                size="small"
                                            >
                                                {t('joinMeeting')}
                                            </BaseButton>
                                        ) : null}
                                        {/* web端京东会议-加入会议入口 */}
                                        {meetingInfo &&
                                            !isFocusEnv() &&
                                            config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] &&
                                            meetingInfo.meetingPlatform ===
                                                MeetingPlatform.JBRTC && (
                                                <BaseButton
                                                    clstag="pageclick|keycount|xtbg_Pcschdule|JoininNow"
                                                    className={style['button-join-meeting']}
                                                    onClick={handleJoinMeeting}
                                                    size="small"
                                                >
                                                    {t('joinMeeting')}
                                                </BaseButton>
                                            )}
                                    </div>
                                </IndicatorContent>
                            </>
                        )}
                        {/* 提醒 */}
                        {content.remind && (
                            <IndicatorContent
                                indicator={() => (
                                    <div
                                        className="calendar-tooltip-content-label"
                                        style={{ letterSpacing: '14px' }}
                                    >
                                        {t('remind')}
                                    </div>
                                )}
                            >
                                <p className="info info-important">
                                    {content.remind
                                        .split(',')
                                        .sort((a: string, b: string) => {
                                            return Number(b) - Number(a);
                                        })
                                        .filter((item: any) => {
                                            return !!getRemindText(item);
                                        })
                                        .map(
                                            (item: any, index: number) =>
                                                `${index === 0 ? '' : ','}${getRemindText(item)}`
                                        )}
                                </p>
                            </IndicatorContent>
                        )}
                        {/* 地点 */}
                        {content.address && (
                            <IndicatorContent
                                indicator={() => (
                                    <div
                                        className="calendar-tooltip-content-label"
                                        style={{ letterSpacing: '14px' }}
                                    >
                                        {t('address')}
                                    </div>
                                )}
                            >
                                <p className="info info-important">
                                    <span
                                        className="info info-normal"
                                        style={{ userSelect: 'text' }}
                                    >
                                        {/* {content.address || (
                                        <span className={style['info-placeholder']}>
                                            {t('no data')}
                                        </span>
                                    )} */}
                                        {content.address}
                                    </span>
                                </p>
                            </IndicatorContent>
                        )}
                    </>
                )}
                {/* 描述 */}
                {descriptionStr && !isBusy && (
                    <IndicatorContent
                        indicator={() => (
                            <div
                                className="calendar-tooltip-content-label"
                                style={{ letterSpacing: '14px' }}
                            >
                                {t('calendar description')}
                            </div>
                        )}
                    >
                        <p className="info info-important" style={{ userSelect: 'text' }}>
                            <span className="info info-normal">
                                {descriptionStr || (
                                    <span className={style['info-placeholder']}>
                                        {t('no data')}
                                    </span>
                                )}
                            </span>
                        </p>
                    </IndicatorContent>
                )}
                {/* 附件 */}
                {!isBusy && content.attachment?.length ? (
                    <>
                        <div className="module-cross-line" />
                        <div className="schedule-collapse-panel">
                            <Collapse
                                defaultActiveKey={['1']}
                                expandIconPosition="right"
                                bordered={false}
                                expandIcon={() => (
                                    <IconFont
                                        type="iconicon_down"
                                        className="icon-toggle icon-toggle-attachment"
                                    />
                                )}
                                onChange={(aKey) => {
                                    let svg = document.getElementsByClassName(
                                        'icon-toggle-attachment'
                                    )[0]?.firstElementChild;
                                    if (aKey.length > 0) {
                                        svg?.setAttribute('style', 'transform: rotate(-180deg)');
                                    } else {
                                        svg?.setAttribute('style', '');
                                    }
                                }}
                            >
                                <Collapse.Panel
                                    header={
                                        <div className="collapse-panel-title">
                                            {`${t('attachment')}` +
                                                `(` +
                                                `${content.attachment?.length}` +
                                                `)`}
                                        </div>
                                    }
                                    key="1"
                                >
                                    <IndicatorContent className="content-attachment">
                                        <div className="file-list-detail file-list df">
                                            {content.attachment?.map((item: any) => {
                                                return (
                                                    <div
                                                        className={`${style['info-bg']} ${style['info-attachment']} info-attachment-detail`}
                                                    >
                                                        <Downloader
                                                            content={item}
                                                            key={item.fileId}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </IndicatorContent>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    </>
                ) : null}
                {/* 备注 */}
                <div className="module-cross-line" />
                <div className="schedule-collapse-panel schedule-collapse-panel-remark">
                    <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        bordered={false}
                        expandIcon={() => (
                            <IconFont
                                type="iconicon_down"
                                className="icon-toggle icon-toggle-remark"
                            />
                        )}
                        onChange={(aKey) => {
                            let svg = document.getElementsByClassName('icon-toggle-remark')[0]
                                ?.firstElementChild;
                            if (aKey.length > 0) {
                                svg?.setAttribute('style', 'transform: rotate(-180deg)');
                            } else {
                                svg?.setAttribute('style', '');
                            }
                        }}
                    >
                        <Collapse.Panel
                            header={
                                <div className="collapse-panel-title">
                                    {t('my remark')}
                                    <label className="sub-title">{t('remark visible')}</label>
                                </div>
                            }
                            key="1"
                        >
                            <IndicatorContent>
                                <div className="remark-info">
                                    {!canModifyRemark && (
                                        <div className={style['info-bg']}>
                                            <div className="remark-icon-wrapper">
                                                <span className="info info-normal remark-icon-span">
                                                    <IconFont
                                                        type="iconrdit"
                                                        onClick={() => {
                                                            setCanModifyRemark(true);
                                                            setRemarkInfoBackup(remarkInfo);
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                            <div className="remark-info-span-wrapper">
                                                <CopyFrame>
                                                    <span
                                                        className={`info info-normal remark-span ${style['remark-span']}`}
                                                    >
                                                        {(
                                                            <span
                                                                className="mattch-url"
                                                                onClick={handleOpenUrl}
                                                                style={{ userSelect: 'text' }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: mattchUrl(
                                                                        remarkInfo,
                                                                        true
                                                                    ),
                                                                }}
                                                            />
                                                        ) || (
                                                            <span
                                                                className={
                                                                    style['info-placeholder']
                                                                }
                                                            >
                                                                {t('no data')}
                                                            </span>
                                                        )}
                                                    </span>
                                                </CopyFrame>
                                            </div>
                                        </div>
                                    )}
                                    {canModifyRemark && (
                                        <CopyFrame>
                                            <div className="remark-textarea-wrapper">
                                                <Input.TextArea
                                                    className="remark-textarea info"
                                                    ref={remarkTARef}
                                                    autoSize={{ minRows: 1, maxRows: 7 }}
                                                    maxLength={500}
                                                    value={remarkInfo}
                                                    placeholder={t('plzInputRemark')}
                                                    onChange={(e) => {
                                                        setScheduleRemarkOfMine(e.target.value);
                                                    }}
                                                    onPressEnter={async (e) => {
                                                        // 考虑回车触发备注数据保存接口调用,换行使用ctrl + enter
                                                        // eslint-disable-next-line no-console
                                                        if (!e.ctrlKey) {
                                                            saveRemark({
                                                                bizId: scheduleId,
                                                                bizType: BizType.calendar,
                                                                content: remarkInfo,
                                                                remarkId: curRemarkId
                                                                    ? curRemarkId
                                                                    : content.remarkId,
                                                            }).then((res: any[]) => {
                                                                // 报错处理
                                                                if (res[1]) {
                                                                    message.error(
                                                                        res[1].toString()
                                                                    );
                                                                    setScheduleRemarkOfMine(
                                                                        remarkInfoBackup
                                                                    );
                                                                }
                                                                // 正常返回
                                                                else if (res[0]) {
                                                                    if (
                                                                        !content.remarkId &&
                                                                        !curRemarkId
                                                                    ) {
                                                                        setCurRemarkId(res[0]);
                                                                    }
                                                                }
                                                            });
                                                            setCanModifyRemark(false);
                                                        } else if (
                                                            e.ctrlKey &&
                                                            remarkInfo.length < 500
                                                        ) {
                                                            // console.log(e.ctrlKey);
                                                            setScheduleRemarkOfMine(
                                                                remarkInfo + '\n'
                                                            );
                                                        }
                                                    }}
                                                    onBlur={async (e) => {
                                                        // 考虑用onBlur触发备注数据保存接口调用
                                                        let value = remarkInfo;
                                                        if (
                                                            value.trim() &&
                                                            value.trim().length > 500
                                                        ) {
                                                            value = value.slice(0, 500);
                                                            content.remark = value;
                                                            setRemarkInfo(value || '');
                                                        }
                                                        saveRemark({
                                                            bizId: scheduleId,
                                                            bizType: BizType.calendar,
                                                            content: value,
                                                            remarkId: curRemarkId
                                                                ? curRemarkId
                                                                : content.remarkId,
                                                        }).then((res: any[]) => {
                                                            // 报错处理
                                                            if (res[1]) {
                                                                message.error(res[1].toString());
                                                                setScheduleRemarkOfMine(
                                                                    remarkInfoBackup
                                                                );
                                                            }
                                                            // 正常返回
                                                            else if (res[0]) {
                                                                if (
                                                                    !content.remarkId &&
                                                                    !curRemarkId
                                                                ) {
                                                                    setCurRemarkId(res[0]);
                                                                }
                                                            }
                                                        });
                                                        setCanModifyRemark(false);
                                                    }}
                                                />
                                            </div>
                                        </CopyFrame>
                                    )}
                                </div>
                                {/* </p> */}
                            </IndicatorContent>
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </ScrollView>

            {/* 参会人员在未读状态下可以选择拒绝该日程 */}
            {content.archive !== 0 && !isBusy && (
                <div className="fix-bottom df">
                    {isParticipant && resStatus === ResStatus.Unread ? (
                        <>
                            <BaseButton
                                className="button-reject"
                                onClick={() => askScheduleHandle(ResStatus.Reject)}
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|14"
                                size="small"
                            >
                                {t('calendar reject')}
                            </BaseButton>
                            <BaseButton
                                className="button-accept"
                                onClick={() => askScheduleHandle(ResStatus.Agree)}
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|11"
                                size="small"
                                type="primary"
                            >
                                {t('calendar accept')}
                            </BaseButton>
                        </>
                    ) : isParticipant && resStatus === ResStatus.Agree ? (
                        <>
                            <BaseButton
                                className="button-reject"
                                onClick={() => askScheduleHandle(ResStatus.Reject)}
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|14"
                                size="small"
                            >
                                {t('reject')}
                            </BaseButton>
                            {/* <span style={{ marginRight: '24px' }}>{t('accepted')}</span> */}
                            <BaseButton
                                className="button-accept"
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|11"
                                size="small"
                                type="primary"
                                disabled={true}
                            >
                                {t('accepted')}
                            </BaseButton>
                        </>
                    ) : isParticipant && resStatus === ResStatus.Reject ? (
                        <>
                            {/* <span style={{ marginRight: '24px' }}>{t('rejected')}</span> */}
                            <BaseButton
                                className="button-rejected"
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|11"
                                size="small"
                            >
                                {t('rejected')}
                            </BaseButton>
                            <BaseButton
                                className="button-accept"
                                onClick={() => askScheduleHandle(ResStatus.Agree)}
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|11"
                                size="small"
                                type="primary"
                            >
                                {t('accept')}
                            </BaseButton>
                        </>
                    ) : null}
                    <AddToMyCalendar
                        scheduleId={scheduleId}
                        userData={userData}
                        isCreator={isCreator}
                        isParticipant={isParticipant}
                        isAgent={
                            content.agent === Agent.AgentAllowed &&
                            content.ownerUser?.userId === userData.userId
                        }
                        calendarType={content.calendarType}
                        closeHandle={closeHandle}
                    />
                    <BasicModal
                        title={t('remind')}
                        centered={true}
                        visible={modalVisible}
                        className="schedule-change-confirm-modal"
                        onCancel={() => closeConfirmModal()}
                        footer={
                            <div className="footer df">
                                <BaseButton
                                    size="small"
                                    type="primary"
                                    onClick={() => scheduleModifyHub(ModifyType.OnlyThisSchedule)}
                                    clstag="pageclick|keycount|xtbg_calhome_1640073907694|12"
                                >
                                    {t('only-this-schedule')}
                                </BaseButton>
                                <BaseButton
                                    size="small"
                                    className="ml24"
                                    type="primary"
                                    onClick={() => scheduleModifyHub(ModifyType.EveryAfter)}
                                    clstag="pageclick|keycount|xtbg_calhome_1640073907694|13"
                                >
                                    {t('every-after')}
                                </BaseButton>
                            </div>
                        }
                    >
                        <p>{t('repeat-schedule-change-remind')}</p>
                    </BasicModal>
                    <BasicModal
                        title={t('remind')}
                        centered={true}
                        visible={dismissGroupModalVisible}
                        className="schedule-change-confirm-modal"
                        onCancel={() => closeDismissGroupModal()}
                        footer={
                            <div className="footer df">
                                <BaseButton
                                    size="small"
                                    type="default"
                                    onClick={() => delScheduleSycDismissGroup(false)}
                                >
                                    否
                                </BaseButton>
                                <BaseButton
                                    size="small"
                                    className="ml24"
                                    type="primary"
                                    onClick={() => delScheduleSycDismissGroup(true)}
                                >
                                    是
                                </BaseButton>
                            </div>
                        }
                    >
                        <p>日程删除后，是否将关联的组进行解散处理？</p>
                    </BasicModal>
                </div>
            )}
            <RejectReason
                isVisible={isReasonVisible}
                onOk={onOkHandler}
                onCancel={onCancelHandler}
            />
            <Drawer
                title={null}
                width={443}
                closable={false}
                className="im-drawer"
                onClose={() => {
                    setImDrawerVisible?.(false);
                }}
                visible={imDrawerVisible}
                mask={false}
                style={{ zIndex: 99, padding: 0 }}
            >
                {calendarImService.render()}
            </Drawer>
            {setImDrawerVisible &&
                !isNoChatSelfSchedule &&
                (isCreator || isParticipant || isAgent) &&
                !isBusy &&
                !isDebtee &&
                history.location?.pathname.indexOf('/messages') < 0 && (
                    <Button
                        onClick={isGroupCreating ? () => {} : handleChatNow}
                        className="unfold-btn"
                    >
                        {imDrawerVisible ? (
                            <CaretRightOutlined className="unfold-icon" />
                        ) : (
                            <CaretLeftOutlined className="unfold-icon" />
                        )}
                    </Button>
                )}
        </div>
    );
};

function mapStateToProps({ calendar, user, work, netStatus }: any) {
    return {
        calendarMap: calendar.calendarMap,
        userData: user.userData?.user,
        selectedCalendarList: calendar.selectedCalendarList,
        calendarList: calendar.calendarList,
        scheduleInfoDraw: calendar.scheduleInfoDraw,
        scheduleInfoDrawOther: calendar.scheduleInfoDrawOther,
        workDrawVisible: work.detailState?.drawVisible,
        workTaskId: work.detailState?.taskId,
        newPublicResult: netStatus.newPublicResult,
        conferencePlatforms: calendar.conferencePlatforms,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setCalendarLoading(data: boolean) {
            dispatch({ type: 'calendar/setCalendarLoading', payload: data });
        },
        openCreateModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
        clearScheduleMap() {
            dispatch({ type: 'calendar/clearScheduleMap' });
        },
        getScheduleListByTime(data: {
            user?: User;
            calendarId?: string;
            beginTime: Dayjs;
            endTime: Dayjs;
        }) {
            dispatch({ type: 'calendar/getScheduleListByTime', payload: data });
        },
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
        changeScheduleInfoDraw: (payload: { visible: boolean; scheduleId: string }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDraw',
                payload,
            }),
        changeScheduleInfoDrawOther: (payload: { visible: boolean; scheduleId: string }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDrawOther',
                payload,
            }),
        setScheduleInfoDrawVisible: (payload: {
            visible: boolean;
            scheduleId: string;
            from: number;
        }) =>
            dispatch({
                type: 'calendar/setScheduleInfoDrawVisible',
                payload,
            }),
        changePersonalTaskDraw: (payload: {
            taskId: string;
            drawVisible: boolean;
            chat: boolean;
        }) =>
            dispatch({
                type: 'work/changePersonalTaskDraw',
                payload,
            }),
        clearSelectSession: () => {
            dispatch({ type: 'chat/clearSelectSession', payload: {} });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetail);
