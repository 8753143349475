import { gatewayInstance } from '../server/gateway';
import { CreateTaskForm, TaskModel, TaskResource } from '@/types/work';
import { NewTaskModel, TaskListParams, IEditTaskItemParam, RecordsParams } from '@/types/newWork';
import { changeGovUrl } from '@/utils/tools';

export function getErrMessage(str: string) {
    return str.substr(str.indexOf(']') + 1);
}

/**
 * @description 新的创建任务接口
 */
export async function createTask(task: Partial<NewTaskModel>) {
    try {
        if (task.resources && task.resources.length) {
            console.log('task.resources----before', task.resources, JSON.stringify(task.resources));
            let resources = task.resources as any;
            task.resources = [] as any;
            resources.forEach((newItem: any) => {
                if (newItem.fileUrl) {
                    newItem.fileUrl = changeGovUrl(newItem.fileUrl);
                }
                if (newItem.url) {
                    newItem.url = changeGovUrl(newItem.url);
                }
                task.resources?.push(newItem);
            });
        }
        console.log('task.resources----after', task.resources, JSON.stringify(task.resources));
        const data = await gatewayInstance.request({
            api: 'work.task.createTask',
            method: 'POST',
            body: task,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * @description 新的修改任务接口
 */
export async function modifyTaskInfo(task: Partial<NewTaskModel>) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.modifyTask',
            method: 'POST',
            body: task,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * @description 新的修改任务接口
 */
export async function modifyWholeTask(NewTaskModel: Partial<NewTaskModel>) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.modifyWholeTask',
            method: 'POST',
            body: NewTaskModel,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * @description 新的查看任务详情接口
 */
export async function getTaskDetail(taskId: string, share = '') {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.queryTaskInfo',
            method: 'POST',
            body: { taskId, share },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message?.toString() || error.errorMsg || error || '服务器错误'];
    }
}

/**
 * @description 新的查询任务推进记录
 * @param taskId
 * @param offset // 当前页
 * @param limit //条数
 * @param userId // 查看个人推进记录
 * @param teamId // 查看个人推进记录
 * @param appId // 查看个人推进记录
 */
export async function getTaskUpdateRecords(opt: RecordsParams) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.queryTaskUpdateRecords',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function createPersonalTaskApi(
    opts: Partial<TaskModel> & { endTimeFrom?: string; callback?: any }
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.userTaskSave',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function createPersonalTaskInfoApi(
    opts: Partial<CreateTaskForm> & { endTimeFrom?: string; callback?: any }
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.clientTaskSave',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

export async function updatePersonalTask(task: Partial<TaskModel>) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.taskUpdate',
            method: 'POST',
            body: task,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function updateTaskProcess(opt: {
    taskId: string;
    taskStatus?: number;
    processStatus?: number;
    front?: string;
    after?: string;
    endTimeFrom?: string;
    endTimeTo?: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.updateTaskProcess',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getPersonalList(opts: {
    endTimeFrom?: string;
    endTimeTo?: string;
    offset: number;
    limit: number;
    projectId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.findMyTasks',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function getPersonalTaskDetail(taskId: string, share = '') {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.getTaskDetail',
            method: 'POST',
            body: { taskId, share },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function addTaskMembers(opt: {
    taskId: string;
    endTimeFrom?: string;
    endTimeTo?: string;
    members: {
        userId: string;
        teamId: string;
        app: string;
        taskUserRole: number;
    }[];
}) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.saveTaskMembers',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function updateTaskMembers(opt: {
    taskId: string;
    endTimeFrom?: string;
    endTimeTo?: string;
    members: {
        userId: string;
        teamId: string;
        app: string;
        taskUserRole: number;
    }[];
}) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.updateTaskMembers',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function addTaskResources(opt: {
    taskId?: string;
    resource?: {
        name: string;
        url: string;
        bizType: number;
        bizId: string;
        size: number;
        resourceType: number;
        fileType: string;
        creatorUserId: string;
    };
    resources?: {
        name: string;
        url: string;
        bizType: number;
        bizId: string;
        size: number;
        resourceType: number;
        fileType: string;
        creatorUserId: string;
    }[];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.saveTaskResources',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function taskSortUpdate(opt: {
    after?: string;
    front?: string;
    moveTaskId: string;
    endTime?: string | null;
    endTimeTo?: string;
    endTimeFrom?: string;
    timeclassify?: number;
    projectId: string;
}) {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.tasksSortUpdate',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 点赞或取消
 * @param taskId
 * @param like
 */
export async function taskPraise(taskId: string, like: boolean): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: like ? 'work.task.taskPraise' : 'work.task.cancelTaskPraise',
            method: 'POST',
            body: { taskId },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function findTaskPraise(opt: {
    taskId: string;
    limit: number;
    offset: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.findTaskPraises',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function delTaskResources(opt: {
    taskId?: string;
    resourceIds: string[];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.delTaskResources',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function delTaskMembers(opt: {
    taskId?: string;
    members: {
        userId: string;
        teamId: string;
        app: string;
        taskUserRole: number;
    }[];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.delTaskMembers',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function saveTaskComment(opt: {
    taskId: string;
    parentId?: string;
    content: string;
    userIds?: string[];
    type: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.saveTaskComment',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function findTaskComments(opt: {
    taskId: string;
    offset?: number;
    limit?: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.findTaskComments',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
export async function deleteTaskComment(opt: { commentId: string }): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.deleteTaskComment',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 *
 * type: 删除类型，1:不递归删除子任务 2:递归删除子任务(会删除)
 * quit: true 退出任务 false 删除任务
 */
export async function deleteTask(opt: {
    taskId: string;
    type: 1 | 2; // 默认1
    quit?: boolean; // 默认false
    delGroup?: boolean; // 是否解散关联的组
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.deleteTask',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 * 任务创建群聊
 */
export async function createGroup(opt: {
    taskId: string;
    groupName: string;
    users: { userId: string; teamId: string; app: string }[];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.project.createGroup',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 任务变更归属
 */
export async function updateTaskSource(opt: {
    endTimeFrom?: string;
    endTimeTo?: string;
    taskId: string;
    sourceId: string;
    source: 'USER' | 'PROJECT' | 'PROCESS' | 'LOW_CODE';
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.updateTaskSource',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 任务导出查询列表
export async function findForExportMyTasks(opt: {
    title?: String;
    startTime?: string;
    endTime?: string;
    priorityTypes: number[];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.findForExportMyTasks',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 任务导出接口
export async function exportMyTasks(opt: {
    title?: String;
    startTime?: string;
    endTime?: string;
    priorityTypes: number[];
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.exportMyTasks',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// -----------start ---------------
export async function getTaskList(opts: TaskListParams): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.queryTaskList',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function queryTasks(opts: {
    sortType: number; // 1: 创建时间排序，2: 截止时间排序
    userRole: number; // 0:创建人, 1:负责人 2:协同人
    taskStatuses: number[]; // 任务状态:1-未完成 2-已完成 3-已中止
    processStatuses: number[]; // 1:推进中 2:已完成 3:有困难 4:未查看
    priorityTypes: number[]; // 任务类型 1:B2 2:B1 3:A2 4:A1"
    limit: number;
    offset?: number;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.queryTasks',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message];
    }
}

/**
 * 根据taskId 查询协作人
 */
export async function queryExecutorsByTaskId(taskId: string): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.queryExecutorsByTaskId',
            method: 'POST',
            body: { taskId },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message];
    }
}

export async function editTaskItem(opts: IEditTaskItemParam): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.modifyTask',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export interface CreateTaskGroupType {
    taskId: string;
    from: {
        app: string;
        pin: string;
        clientType?: string;
        teamId: string;
        userId: string;
    };
    body: {
        modifyFiled: number;
        nickName: string;
        name: string;
        notice: string;
        gid?: string;
        invitees: {
            pin: string;
            app: string;
            nickName: string;
            teamId: string;
            userId: string;
        }[];
    };
    kind: number;
    subKind: number;
}

export async function createTaskGroup(params: CreateTaskGroupType): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.createGroup',
            method: 'POST',
            body: params,
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 * 获取子部门接口，传部门id，返回一级子部门
 */
export async function getEbookChildDept(params: {
    deptId: string;
    userId: string;
    teamId: string;
}) {
    return gatewayInstance
        .request({
            api: 'ebook.gov.getEbookChildDept',
            method: 'POST',
            body: params,
        })
        .catch((err) => {
            console.log('getEbookChildDept-err', err);
        });
}

/**
 * 部门任务列表
 */
export async function deptTaskList(opts: {
    sortType: number; // 1: 创建时间排序，2: 截止时间排序
    deptId: string; // 部门ID
    taskStatuses: number[]; // 任务状态:1-未完成 2-已完成 3-已中止
    priorityTypes: number[]; // 任务类型 1:B2 2:B1 3:A2 4:A1"
    limit: number;
    offset?: number;
    jsfOperator: {
        userId: string;
        teamId: string;
        app: string;
        clientType: string;
    };
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.deptTaskList',
            method: 'POST',
            body: { fromMQ: true, ...opts },
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, getErrMessage(error.message.toString())];
    }
}

/**
 * 部门任务统计
 */
export async function statDeptTask(deptId: string): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.statDeptTask',
            method: 'POST',
            body: { deptId },
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, getErrMessage(error.message.toString())];
    }
}
/**
 * 部门任务权限判断
 */
export async function getDeptStatAuth(): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.task.getDeptStatAuth',
            method: 'POST',
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, getErrMessage(error.message.toString())];
    }
}
