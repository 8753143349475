import { gatewayInstance } from '@/server/gateway';
import cache from '@/utils/cache';
import { isFocusEnv } from '@/utils';

export async function getByApprover(recordId: string) {
    return gatewayInstance.request({
        api: 'joyspace.inviteRecord.approverGetById',
        method: 'GET',
        dynamicPath: `v1/invite-records/${recordId}/getByApprover`,
        headers: {
            platform: isFocusEnv() ? cache?.token?.token?.focusClient || 'web' : 'web', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
        },
    });
}
export async function inviteConfirm(recordId: string, permissionType: number) {
    return gatewayInstance.request({
        api: 'joyspace.inviteRecord.confirm',
        method: 'PUT',
        dynamicPath: `v1/invite-records/${recordId}/confirm`,
        body: {
            permissionType,
        },
        headers: {
            platform: isFocusEnv() ? cache?.token?.token?.focusClient || 'web' : 'web', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
        },
    });
}
export async function inviteRefuse(recordId: string, reason: string) {
    return gatewayInstance.request({
        api: 'joyspace.inviteRecord.refuse',
        method: 'PUT',
        dynamicPath: `v1/invite-records/${recordId}/refuse`,
        body: {
            reason,
        },
        headers: {
            platform: isFocusEnv() ? cache?.token?.token?.focusClient || 'web' : 'web', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
        },
    });
}

export async function pageTransfer(opt: {
    appKey: string;
    downLoadURL: string;
    fileName: string;
    fileType?: string;
    xtoken: string;
}) {
    return gatewayInstance.request({
        api: 'joyspace.pageTransfer.create',
        method: 'POST',
        dynamicPath: `/v1/pageTransfer/create`,
        body: opt,
        headers: {
            platform: isFocusEnv() ? cache?.token?.token?.focusClient || 'web' : 'web', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
        },
    });
}
