import React, { useState } from 'react';
import { Tooltip, Popover, Modal, Row, Col } from 'antd';
import {
    FavoriteType,
    RecommendedType,
    RecommendedTypeTitle,
    StatusLabels,
    StatusLabelsStyles,
    StatusLabelsText,
} from '@/services/AppStore';
import Bus from '@/utils/bus';
import ReactDOM from 'react-dom';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
import IconFont from '@/components/icon';
import HeaderLabel from './HeaderLabel';

export enum MenuType {
    AddThirdApp = 'addThirdApp', // 添加常用
    AboutModal = 'aboutModal', // 关于应用
}

interface IProps {
    menuType: MenuType;
    favoriteType?: FavoriteType;
    appInfo?: any;
}

export const appItemMenus = (props: IProps) => {
    const { menuType, favoriteType = FavoriteType.FavoriteNo, appInfo } = props;
    switch (menuType) {
        case MenuType.AboutModal:
            openAboutModal({ visible: true, ...appInfo });
            break;
        case MenuType.AddThirdApp:
            favoriteType === FavoriteType.FavoriteNo
                ? Bus.emit('onAddFavoriteThirdApp', appInfo)
                : Bus.emit('removeFavoriteThirdApp', appInfo);
            break;
        default:
            break;
    }
};

function AboutModal(props: any) {
    const [visible, setVisible] = useState(props.visible);
    console.log(props, 'props');
    const Header = () => {
        return (
            <div>
                <div className="about-header">
                    <img className="about-logo" src={changeEgovUrl(props.icon)} alt="" />
                    <span className="about-title">{props.name}</span>
                </div>
            </div>
        );
    };
    const getCornerMarkOptions = () => {
        const arr = [];
        if (props.recommended === RecommendedType.RecommendedYes) {
            arr.push({
                text: RecommendedTypeTitle[RecommendedType.RecommendedYes],
                styles: StatusLabelsStyles.default,
            });
        }
        if ((props.applicationStatusLabels || []).includes(StatusLabels.BETA)) {
            arr.push({
                text: StatusLabelsText[StatusLabels.BETA],
                styles: StatusLabelsStyles[StatusLabels.BETA],
            });
        }
        if ((props.applicationStatusLabels || []).includes(StatusLabels.UNDER_MAINTENANCE)) {
            arr.push({
                text: StatusLabelsText[StatusLabels.UNDER_MAINTENANCE],
                styles: StatusLabelsStyles[StatusLabels.UNDER_MAINTENANCE],
            });
        }

        return arr;
    };
    const cornerMarkOptions: any = getCornerMarkOptions();
    const lables = []
        .concat(props.networkEnvLabels || [])
        .concat(props.applicationStatusLabels || []);
    return (
        <Modal
            className="about-modal"
            width={700}
            title="工具详情"
            footer={null}
            onCancel={() => {
                setVisible(false);
            }}
            visible={visible}
        >
            <div className="about-modal-body">
                <div className="about-modal-left">
                    <div className="about-modal-img">
                        <img className="about-logo" src={changeEgovUrl(props.icon)} alt="" />
                    </div>
                    <div className="about-modal-item">
                        <h3 className="item-title">开发者：</h3>
                        <div className="item-content">{props.developer}</div>
                    </div>
                    <div className="about-modal-item">
                        <h3 className="item-title">联系工具开发者：</h3>
                        <div className="item-content">
                            <div className="kefu item-section">
                                <IconFont type="iconic_pc_application_kefu" />
                                <div className="tel-section">
                                    {props.csTelephone
                                        ? props.csTelephone.split('\n').map((item: string) => (
                                              <span className="text" key={item}>
                                                  {item}
                                              </span>
                                          ))
                                        : ''}
                                </div>
                            </div>
                            <div className="item-section">
                                <IconFont type="iconyouxiang" />
                                <span className="text">{props.csEmail}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-modal-right">
                    <h3 className="about-modal-title">
                        {props.name}
                        {!!cornerMarkOptions.length &&
                            cornerMarkOptions.map((item: any, index: number) => (
                                <HeaderLabel {...item} key={index} />
                            ))}
                    </h3>
                    {!!lables.length && (
                        <div className="about-modal-lable">
                            {lables.map((key: any, index: any) => {
                                const text = StatusLabelsText[key];
                                if (
                                    cornerMarkOptions.find((item: any) => item.text === text) ||
                                    text === StatusLabelsText[StatusLabels.VPN]
                                ) {
                                    return '';
                                }
                                return <span key={'net' + index}>{text}</span>;
                            })}
                        </div>
                    )}
                    <div className="about-modal-detail">
                        <h3 className="detail-title">工具详情</h3>
                        <div className="detail-content">
                            {props.description.split('\n').map((item: string, index: number) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default function openAboutModal(props: any) {
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    function removeNode() {
        if (ele) {
            ReactDOM.unmountComponentAtNode(ele);
            ele.remove();
        }
    }
    ReactDOM.render(<AboutModal {...{ ...props, removeNode }} />, ele);
}
