import React, { useEffect, useState } from 'react';
import './index.less';
import { Alert } from 'antd';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { replaceGovUrl } from '@/utils/joyspace';
import { getTaskDetail } from '@/api/work';
// import { Loading } from '@jd/focus-desktop-comps';
import { NewTaskModel, PRIORITY_TYPE, PROCESS_STATUS } from '@/types/newWork';
import { decorateTaskDetail } from '@/components/Work/newUtils';
import dayjs from 'dayjs';
import { PRIORITY_TYPE_MAP } from '@/components/Projects/emnu';
import { changeEgovUrl } from '@/utils/tools';
import { useDefaultFormatTime } from '@/components/Work/utils';
// 实时加载任务详情标记
const LOAD_DETAIL = false;

function TaskCard(props: { message: any; shareUrl: string }) {
    const { shareUrl, message } = props;
    const parseUrl = DeeplinkEvent.parseDeeplink(shareUrl);
    const { mparam } = parseUrl;

    const [loading, setLoading] = useState(false);
    const [errorInfo, setErrorInfo] = useState<any>('');
    const [taskInfo, setTaskInfo] = useState<NewTaskModel>({
        title: mparam.taskName,
        taskId: mparam.taskId,
        startTime: mparam.startTime,
        endTime: mparam.endTime,
        workStartTime: mparam.startTime ? dayjs(mparam.startTime) : null,
        workStopTime: mparam.endTime && mparam.endTime !== -1 ? dayjs(mparam.endTime) : null,
        priorityType: mparam.priorityType,
        dueTag: mparam.dueTag || false,
        content: mparam.content || '',
        // 补齐ts类型校验
        remark: {
            id: '',
            remark: '',
        },
        owner: {
            userId: '',
            teamId: '',
            app: '',
            headImg: '',
            realName: '',
            processStatus: PROCESS_STATUS.NOT_VIEWED,
        },
        executors: [],
        resources: [],
    });
    const label = useDefaultFormatTime(taskInfo.workStopTime!);

    useEffect(() => {
        // 不开启远程加载，或者消息是在一分钟之内发送的，不需要重新获取详情
        if (!LOAD_DETAIL || dayjs(message.datetime).add(1, 'minute').isAfter(dayjs())) {
            return;
        }
        setLoading(true);
        try {
            getTaskDetail(parseUrl.mparam.taskId).then((res: any) => {
                setLoading(false);
                const [data, error] = res;
                if (!data || error) {
                    throw error || '获取任务详情失败';
                }
                const task = decorateTaskDetail(data as NewTaskModel);
                setTaskInfo(task);
            });
        } catch (error) {
            console.error(error);
            setErrorInfo(error);
            setLoading(false);
        }
    }, [message.datetime, parseUrl.mparam.taskId]);

    const openJFile = () => {
        DeeplinkEvent.open(replaceGovUrl(shareUrl), {
            source: 'imShareCard',
            taskComeFrom: 'task',
        });
    };

    if (parseUrl?.pathname !== DeeplinkPathEnum.Task_Draw || errorInfo || !taskInfo) {
        return <Alert message={errorInfo || '不支持的消息类型'} type="error" />;
    }

    return (
        <div
            className={`task-share-card ${loading ? 'loading' : ''} lbb-share-card-mark-aaa`}
            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_sharetask_viewdetail"
            onClick={openJFile}
        >
            <h4>任务</h4>
            <div className="detail-wrap">
                <div className="detail">
                    {taskInfo.priorityType && taskInfo.priorityType !== PRIORITY_TYPE.NO_PRIORITY && (
                        <img
                            style={{
                                width: '14px',
                                height: '14px',
                                marginRight: '5px',
                                marginTop: '-3px',
                            }}
                            src={changeEgovUrl(PRIORITY_TYPE_MAP[taskInfo.priorityType]?.url)}
                            alt={PRIORITY_TYPE_MAP[taskInfo.priorityType]?.alt}
                            title={PRIORITY_TYPE_MAP[taskInfo.priorityType]?.alt}
                        />
                    )}
                    {taskInfo.title}
                </div>
                {taskInfo.workStopTime ? (
                    <div className={`time ${taskInfo.dueTag ? 'overdue' : ''}`}>
                        {label} 截止
                        {taskInfo.dueTag && <span>逾期</span>}
                    </div>
                ) : (
                    <div className="time">无截止时间</div>
                )}
            </div>
        </div>
    );
}

export default TaskCard;
