import React, { ChangeEvent, useCallback, useRef, useMemo, useState, useContext } from 'react';
import { Input, message } from 'antd';
import { _Active } from '@/components/Joyspace/components/CategoriesPicker/types';
import IconFont from '@/components/icon';
import CreateMenu from '@/components/Joyspace/components/CreateMenu';
import { openModalTextBox } from '@/components/Joyspace/components/ModalTextBox';
import api from '@/components/Joyspace/api';
import List from '@/components/Joyspace/components/List';
import ContextMenus from '@/components/Joyspace/contextMenus';
import { Project } from '../../types';
import noDocPicture from '@/assets/img/no-content.png';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import Context from '../../context';
import { isFocusEnv } from '@/utils';
import { openLowCode } from '@/components/Joyspace/util';
import './index.less';

interface propTypes {
    project: Project;
}

export default function Documentation(props: propTypes) {
    const { project } = props;
    const { t } = useContext(Context);
    const [selectfolder, setSelectFolderId] = useState<{ id: string; name: string }>({
        id: '',
        name: '',
    });
    const [teamId, spaceId] = project.joyspaceId?.split(',') ?? ['', ''];
    const [isReload, setIsReload] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const globalContext = useContext<GlobalContextConfig>(GlobalContext);

    const renderList = () => {
        const listColumns = project.archive
            ? List.columns.Page_Zhuanban_archive
            : List.columns.Page_Zhuanban;
        if (selectfolder.id) {
            return (
                <List
                    columns={listColumns}
                    orderOptions={true}
                    context={ContextMenus.page('ZHUANBAN', globalContext)}
                    categoryContext={ContextMenus.category('ZHUANBAN', '')}
                    onRequest={(params) => api.pages.space(params, spaceId)}
                    folderId={selectfolder.id}
                    searchText={searchText.trim()}
                    zhuanban={{ teamId, spaceId, projectId: project.id }}
                    noDataText={{
                        text: t('detail.word.nodata'),
                        url: noDocPicture,
                    }}
                />
            );
        }
        return (
            <List
                columns={listColumns}
                orderOptions={true}
                context={ContextMenus.page('ZHUANBAN', globalContext)}
                categoryContext={ContextMenus.category('ZHUANBAN', '')}
                onRequest={(params) => api.pages.space(params, spaceId)}
                onRequestCategories={() => api.pages.spaceCategories(spaceId)}
                zhuanbanCallback={(data) => {
                    setSearchText('');
                    setSelectFolderId(data);
                }}
                searchText={searchText.trim()}
                zhuanban={{ teamId, spaceId, projectId: project.id }}
                noDataText={{
                    text: t('detail.word.nodata'),
                    url: noDocPicture,
                }}
            />
        );
    };
    // }, [selectfolder.id, spaceId, isReload]);

    function category() {
        // 目前只能在空间下新建文件夹，不支持文件夹下建文件夹
        openModalTextBox({
            title: t('new_folder'),
            onOk: (value, close) => {
                api.categories.create(spaceId, value).then(() => {
                    setIsReload((oldvalue) => !oldvalue);
                    message.success(t('create_success'));
                    close();
                });
            },
        });
    }

    function searchChange(e: ChangeEvent) {
        const value = (e.target as any).value;
        setSearchText(value);
        setIsReload((oldvalue) => !oldvalue);
    }

    return (
        <div className="pm-doc-wrap">
            <div className="pm-doc-header">
                <span>
                    {selectfolder.id && (
                        <span onClick={() => setSelectFolderId({ id: '', name: '' })}>
                            <IconFont type="iconlog_back" />
                        </span>
                    )}
                    {selectfolder.id ? selectfolder.name : t('file')}
                </span>
                <Input
                    placeholder={t('search')}
                    value={searchText}
                    onChange={searchChange}
                    prefix={<IconFont type="iconjs_ic_search" className="pm-doc-search-icon" />}
                />
                <CreateMenu
                    category={selectfolder.id ? undefined : category}
                    zhuanban={{
                        teamId,
                        spaceId,
                        selectfolder,
                        setIsReload,
                        projectId: project.id,
                        archive: project.archive,
                    }}
                    folderId={selectfolder.id}
                    lowCode={isFocusEnv() ? openLowCode : undefined}
                />
            </div>
            <div className="pm-doc-main">{renderList()}</div>
        </div>
    );
}
