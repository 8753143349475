/* eslint-disable complexity */
import { EventEmitter } from 'events';
import { map, uniqueId } from 'lodash';
import { FocusSDK } from '@/utils';
import Bus from './bus';

export enum UseCheckType {
    IMAGE = 'image',
}

interface Optional {
    id?: string;
    url?: string;
    time?: number;
    testFrequency?: number;
    checkType?: UseCheckType;
}

/**
 * 网络检测
 */
class NetworkCheck extends EventEmitter {
    private static instances: NetworkCheck[] = [];
    public static getInstance(props?: Optional): NetworkCheck {
        let instance: NetworkCheck | undefined;
        if (props?.id) {
            instance = this.getById(props?.id);
        } else if (props?.url) {
            instance = this.getByUrl(props?.url);
        }
        if (!instance) {
            instance = new NetworkCheck(props);
            FocusSDK.printLog(`networkCheck--create`, `id: ${instance.id}--props.id: ${props?.id}`);
            this.instances.push(instance);
        }
        return instance;
    }
    private static getById(id: string): NetworkCheck | undefined {
        return this.instances?.find((i) => i.id === id);
    }

    private static getByUrl(url: string): NetworkCheck | undefined {
        return this.instances?.find((i) => i.url === url);
    }

    private id: string;
    private url: string;
    private inited: boolean;
    private checkStart: boolean;
    private time: number;
    private timer: any;
    private testFrequency: number;
    private checkType: UseCheckType;
    private handlerFired: boolean;
    private fileSize = 3000;
    private w: any;
    private handleStateChange: Function;
    constructor(props?: Optional) {
        super();
        const { url, time, testFrequency, checkType } = props || {};
        this.url = url || '';
        this.time = time || 1 * 60 * 1000;
        this.testFrequency = testFrequency || 3;
        this.checkType = checkType || UseCheckType.IMAGE;
        this.handlerFired = false;
        this.w = window;
        this.handleStateChange = this.getState.bind(this);
        this.id = uniqueId();
        this.inited = false;
        this.checkStart = false;
        this.init().then(() => {
            this.startCheck();
        });
    }
    public getId(): string {
        return this.id;
    }

    public setStatus(newState: boolean): void {
        this.eventStatus(newState);
        this.w.onLine = newState;
    }

    /**
     * 检测网络
     * @returns
     */
    public async check(): Promise<number> {
        if (this.url) {
            try {
                let results: number[] = Array(this.testFrequency).fill(0);
                const isFirefox = /firefox/i.test(navigator.userAgent);
                if (isFirefox && !window.navigator.onLine) {
                    return 0;
                }
                results = await Promise.all(
                    map(results, async (item) => {
                        const result =
                            this.checkType === UseCheckType.IMAGE
                                ? await this.withImgTag(this.url, this.fileSize)
                                : await this.withRequest(this.url);
                        return result;
                    })
                );
                return (
                    results.reduce((p, c) => {
                        let current = p;
                        current += c;
                        return current;
                    }) / this.testFrequency
                );
            } catch (error) {
                console.error(`check---error--`, error);
                return 0;
            }
        }
        return 0;
    }

    public async checkState(): Promise<boolean> {
        return this.verifyStatus(await this.check());
    }

    /**
     * 图片检测
     * @param imgUrl
     * @param fileSize
     * @returns
     */
    public withImgTag(imgUrl: string, fileSize: number): Promise<number> {
        return new Promise((resolve, reject) => {
            const imgEl = document.createElement('img');
            const start = new Date().getTime();
            let end = null;
            imgEl.onerror = (e) => reject(e);
            imgEl.onload = () => {
                end = new Date().getTime();
                const speed = fileSize / (end - start);
                resolve(speed);
            };
            imgEl.src = `${imgUrl}?v=${start}`;
        });
    }

    /**
     * http请求检测
     * @param url
     * @returns
     */
    public withRequest(url: string): Promise<number> {
        return new Promise((resolve, reject) => {
            try {
                let start = new Date().getTime();
                let end = 0;
                const xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        end = new Date().getTime();
                        const rawSize = xhr.getResponseHeader('Content-Length') || '0';
                        const size = parseInt(rawSize, 10) / 1024;
                        const speed = (size * 1000) / (end - start);
                        resolve(speed);
                    }
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (err) {
                reject(err);
            }
        });
    }

    public bindEvent(obj: any, type: string, cb: Function): void {
        obj.addEventListener(type, cb);
    }

    public unbindEvent(obj: any, type: string, cb: Function): void {
        obj.removeEventListener(type, cb);
    }

    /**
     * 开始检测
     */
    public async startCheck(): Promise<void> {
        this.clearCheck();
        this.checkStart = true;
        this.setStatus(this.verifyStatus(await this.check()));
        this.timer = setTimeout(() => {
            this.clearCheck();
        }, this.time);
    }

    /**
     * 重置检测
     * @param time
     */
    public async resetCheck(time: number): Promise<void> {
        this.time = time;
    }

    public clearCheck = () => {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
        this.checkStart = false;
    };

    public stopCheck(): void {
        this.clearCheck();
        this.w.onLine = window.navigator.onLine;
        this.handlerFired = window.navigator.onLine;
    }

    public isStartCheck(): boolean {
        return this.checkStart;
    }

    /**
     *
     * @returns 初始化
     */
    private async init(): Promise<void> {
        if (this.inited) {
            return;
        }
        this.inited = true;
        this.w.onLine = window.navigator.onLine;
        this.handlerFired = this.w.onLine;
        this.unbindEvent(this.w, 'offline', this.handleStateChange);
        this.unbindEvent(this.w, 'online', this.handleStateChange);
        this.bindEvent(this.w, 'online', this.handleStateChange);
        this.bindEvent(this.w, 'offline', this.handleStateChange);
        return new Promise((resolve, reject) => {
            if (!this.url) {
                resolve();
            }
            if (this.checkType === UseCheckType.IMAGE) {
                const canvas = document.createElement('canvas');
                let ctx = null;
                let img = new Image();
                img.src = `${this.url}?ver=${new Date().getTime()}`;
                img.setAttribute('crossorigin', 'anonymous');
                img.onload = (e) => {
                    ctx = canvas.getContext('2d');
                    ctx?.drawImage(img, 0, 0);
                    canvas.toBlob((res) => {
                        console.log(`image size: ${res?.size}`);
                        this.fileSize = res?.size || 0;
                        resolve();
                    });
                };
                img.onerror = (e) => {
                    resolve();
                };
            } else {
                resolve();
            }
        });
    }

    private eventStatus(newState: boolean): void {
        // 请求旧地址不通，但网络是通的，有可能切换网络了 用户dns解析不稳定 不去弹窗提示
        if (this.w.onLine !== true && window.navigator.onLine) {
            // 互联网或政务外网域名设置正确
            if (!FocusSDK.isPrivateNet() || FocusSDK.privateDnsIsTrue()) {
                Bus.emit('onLineChangeNet');
            }
        }
        if ((newState === true && this.w.onLine !== true) || this.handlerFired === false) {
            this.emit(`online`, newState);
        }
        if (newState === false && this.w.onLine !== false) {
            const speed = (navigator as any).connection?.downlink;
            const type = (navigator as any).connection?.effectiveType;
            FocusSDK.printLog(
                `networkCheck`,
                `onLine:${this.w.onLine}, newState: ${newState}---network speed: ${speed}, type: ${type}`
            );
            this.emit(`offline`, newState);
            this.handlerFired = false;
            return;
        }
        this.handlerFired = true;
    }

    /**
     * 检测状态
     * @param reslut
     * @returns
     */
    private verifyStatus(reslut: number): boolean {
        return reslut > 0;
    }

    /**
     * 获取状态
     */
    private async getState(): Promise<void> {
        if (!this.url) {
            this.setStatus(window.navigator.onLine);
        } else {
            this.setStatus(this.verifyStatus(await this.check()));
        }
    }
}

export default NetworkCheck;
