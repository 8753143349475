import React, { useEffect, useState } from 'react';
import { Modal, Input, message, Spin } from 'antd';
import { LeftOutlined, CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';

import { changeEgovUrl } from '@/utils/tools';
import { useTranslation } from 'react-i18next';
import { generateTeamCode, refreshTeamCode, msgInvite, GenerateTeamCodeReq } from '@/api/joinMe';
import IconFont, { IconAdd } from '@/components/icon';
import { isFocusEnv, FocusSDK, DownloadItem } from '@/utils';
import './index.less';
import copy from 'copy-to-clipboard';
import html2canvas from 'html2canvas';
import melogo from '@/assets/img/me-logo.png';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import InputWithCtr from './InputWithCtr';
const logoMap: any = {
    mebj: 'https://storage.360buyimg.com/joyspace-support/me-bj-logo.png',
    jdcloud: melogo,
};
const shareBgMap: any = {
    mebj: '',
    jdcloud: 'https://storage.360buyimg.com/joyspace-support/share-bg.png',
};
enum Tabs {
    TeamCode,
    Mobile,
}
const ptn = /^1[123456789]\d{9}$/;
function TeamCodeInvite({
    teamId,
    userData,
    appRuntimeEnv,
}: {
    teamId?: string;
    userData: any;
    appRuntimeEnv: AppRuntimeEnv;
}) {
    const { t } = useTranslation('joinMe');
    const [teamCodeRes, setTeamCodeRes] = useState<GenerateTeamCodeReq | null>(null);
    const [process, setProcess] = useState(0);
    useEffect(() => {
        const { selectedTeamId } = userData;
        generateTeamCode({
            teamId: teamId || selectedTeamId,
            type: 'ALL',
        })
            .then((res) => {
                if (res) {
                    setTeamCodeRes(res as GenerateTeamCodeReq);
                }
            })
            .catch(() => {});
    }, [teamId, userData]);
    function refresh() {
        const { selectedTeamId } = userData;
        Modal.confirm({
            title: t('refreshQrcode'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            content: (
                <p
                    style={{
                        fontSize: '14px',
                        lineHeight: '22px',
                    }}
                >
                    {t('resfreshDesc')}
                </p>
            ),
            okText: t('confirm'),
            cancelText: t('calcel'),
            onOk: () => {
                refreshTeamCode({
                    teamId: teamId || selectedTeamId,
                    type: 'ALL',
                })
                    .then((res) => {
                        if (res) {
                            setTeamCodeRes(res as GenerateTeamCodeReq);
                        }
                    })
                    .catch(() => {});
            },
        });
    }
    async function test() {
        // @ts-ignore
        const a = html2canvas(document.getElementById('share-content'), {
            allowTaint: true,
            useCORS: true,
            backgroundColor: '#ffffff',
            width: 400,
            height: 500,
            scrollX: 0,
            scrollY: 0,
        }).then((canvas: any) => {
            return canvas.toDataURL();
            // return canvas.toDataURL('image/png');
        });
        return a;
    }
    async function handleFileDown(item: any) {
        const a = await test();
        if (isFocusEnv()) {
            FocusSDK.downloadFile(changeEgovUrl(a), {
                saveAs: true,
                filename: `${item.fileName}.png`,
                onStarted: (downloadItem: DownloadItem) => {
                    setProcess(0);
                },
                onProgress: ({ percent }) => {
                    // console.log('percent', percent);
                    setProcess(percent);
                },
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                    //
                },
                onFinish: (file: { path: string; filename: string }) => {
                    // console.log('finish', file);
                    setProcess(100);
                },
            });
        } else {
            // web 端下载文件
            const name = item.fileName;
            const url = item.fileUrl;
            const link = document.createElement('a');

            link.download = `${name}.png`;
            link.href = a;
            // document.body.append(a);
            link.click();
        }
    }

    return (
        <>
            <div
                className="team-code-invite"
                style={{
                    display: 'flex',
                }}
            >
                <div className="item">
                    <p className="title">{t('teamCode')}</p>
                    <p className="desc">{t('teamCodeDesc')}</p>
                    <p
                        className="code"
                        style={{
                            marginBottom: 67,
                        }}
                    >
                        {teamCodeRes
                            ? teamCodeRes.teamCode.slice(0, 4) + ' ' + teamCodeRes.teamCode.slice(4)
                            : ''}
                    </p>
                    <p
                        className="btn"
                        onClick={() => {
                            const content = t('inviteContent')
                                .replace('%team', teamCodeRes?.teamName || '')
                                .replace('%code', teamCodeRes?.teamCode || '')
                                .replace('%name', appRuntimeEnv.productName);
                            copy(content || '');
                            message.success(t('copySuccess'));
                        }}
                    >
                        {t('copyTeamCode')}
                    </p>
                </div>
                <div className="item">
                    <p className="title">{t('teamQrCode')}</p>
                    <p className="desc">{t('teamQrCodeDesc')}</p>
                    <img
                        style={{
                            marginBottom: 27,
                        }}
                        src={changeEgovUrl(teamCodeRes?.qrCode)}
                    />
                    <p
                        className="btn"
                        onClick={() =>
                            handleFileDown({
                                fileUrl: teamCodeRes?.qrCode,
                                fileName: teamCodeRes?.teamCode,
                            })
                        }
                    >
                        {t('saveQrCode')}
                    </p>
                </div>
            </div>

            <div
                className="share-content"
                id="share-content"
                style={{
                    width: 375,
                    height: 475,
                    padding: '18px 28px',
                    fontSize: 16,
                    position: 'fixed',
                    top: 10000,
                    left: 10000,
                    // backgroundImage: `url(${sharebg})`,
                    backgroundImage: `url(${changeEgovUrl(
                        shareBgMap[appRuntimeEnv.runtimeConfig.key]
                    )})`,
                    backgroundSize: '100% 100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 23,
                    }}
                >
                    {userData?.team.headImg ? (
                        <img
                            style={{
                                width: 48,
                                height: 48,
                                borderRadius: 6,
                                marginRight: 15,
                            }}
                            src={changeEgovUrl(userData?.team.headImg)}
                        />
                    ) : (
                        <div
                            className="primary-bg"
                            style={{
                                marginRight: 15,
                                width: 40,
                                height: 40,
                                borderRadius: 20,
                                textAlign: 'center',
                                lineHeight: '40px',
                                color: '#fff',
                            }}
                        >
                            {userData?.team.realName.slice(0, 1)}
                        </div>
                    )}
                    <p
                        style={{
                            fontSize: 16,
                        }}
                    >
                        {userData?.team.realName} {t('inviteYouJoin')}
                    </p>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <p
                        style={{
                            fontSize: 20,
                            marginBottom: 23,
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {userData?.team.teamShortName}
                    </p>
                    <img
                        style={{
                            width: 166,
                            height: 166,
                            marginBottom: 17,
                        }}
                        src={changeEgovUrl(teamCodeRes?.qrCode)}
                        id="tulip"
                    />
                    <p
                        style={{
                            fontSize: 12,
                            color: '#62656D',
                            marginBottom: 23,
                        }}
                    >
                        {t('share-desc').replace('%s', appRuntimeEnv.productName)}
                    </p>
                    <p>
                        {t('teamCode')}:{' '}
                        {teamCodeRes
                            ? teamCodeRes.teamCode.slice(0, 4) + ' ' + teamCodeRes.teamCode.slice(4)
                            : ''}
                    </p>
                    <p
                        style={{
                            width: 307,
                            margin: '24px auto',
                            paddingTop: 24,
                            paddingBottom: 16,
                            borderTop: '1px solid #DEE0E3',
                            fontSize: 16,
                        }}
                    >
                        <img
                            style={{
                                width: 30,
                                height: 30,
                                marginRight: 12,
                            }}
                            src={changeEgovUrl(logoMap[appRuntimeEnv.runtimeConfig.key] || melogo)}
                        />
                        {appRuntimeEnv.productName}
                    </p>
                </div>
            </div>
            <p className="exp">
                {t('expireTime')}: {teamCodeRes?.expireTime}{' '}
                <span onClick={refresh}>{t('clickRefresh')}</span>
            </p>
        </>
    );
}

function MobileInvite({
    teamId,
    userData,
    destroy,
}: {
    teamId?: string;
    userData: any;
    destroy: () => void;
}) {
    const { t } = useTranslation('joinMe');
    const [dataList, setDataList] = useState([
        {
            mobile: '',
            name: '',
        },
        {
            mobile: '',
            name: '',
        },
        {
            mobile: '',
            name: '',
        },
    ]);
    const [illegalList, setIllegalList] = useState<number[]>([]);
    const [illegalStatus, setIllegalStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );
    function onChange(v: any, index: number, field: string) {
        let idx = dataList.findIndex((item: any, i: number) => index === i);
        if (field === 'name') {
            setDataList([
                ...dataList.slice(0, idx),
                {
                    mobile: dataList[idx].mobile,
                    name: v,
                },
                ...dataList.slice(idx + 1),
            ]);
        } else {
            if (v.split(' ').join('').length > 11) {
                return;
            }
            setDataList([
                ...dataList.slice(0, idx),
                {
                    mobile: v,
                    name: dataList[idx].name,
                },
                ...dataList.slice(idx + 1),
            ]);
        }
    }
    function getFormatMobile(code: string | undefined) {
        if (code && code.length > 8) {
            let c = code.split(' ').join('');
            return c.slice(0, 3) + ' ' + c.slice(3, 7) + ' ' + c.slice(7);
            // return code;
        } else if (code && code.length > 3) {
            let c = code.split(' ').join('');
            return c.slice(0, 3) + ' ' + c.slice(3);
        } else {
            return code;
        }
    }
    useEffect(() => {
        // const f = check();
        let s = dataList.filter((item: any, index: number) => {
            return ptn.test(item.mobile?.split(' ').join('')) && item.name;
        });
        if (s.length) {
            setCheckStatus(true);
        } else {
            setCheckStatus(false);
        }
    }, [dataList]);
    function deleteItem(index: number) {
        let idx = dataList.findIndex((item: any, i: number) => index === i);
        setDataList([...dataList.slice(0, idx), ...dataList.slice(idx + 1)]);
    }
    function check() {
        let s = dataList.filter((item: any, index: number) => {
            return (
                (!ptn.test(item.mobile?.split(' ').join('')) && item.name) ||
                (ptn.test(item.mobile?.split(' ').join('')) && !item.name)
            );
        });
        if (s.length) {
            setIllegalStatus(true);
        } else {
            setIllegalStatus(false);
        }
        return !s.length;
    }
    function onConfirm() {
        const f = check();
        const { selectedTeamId } = userData;
        const dataListNew = dataList
            .filter((item: any, index: number) => {
                return ptn.test(item.mobile?.split(' ').join('')) && item.name;
            })
            .map((item) => {
                return {
                    mobile: item?.mobile.split(' ').join(''),
                    name: item.name,
                };
            });
        if (!dataListNew.length) {
            message.warn(t('inviteEmpty'));
        }
        if (f && dataListNew.length) {
            setLoading(true);
            msgInvite({
                teamId: teamId || selectedTeamId,
                inviteMembers: dataListNew,
                // expireTime: 1000000,
                // lang: 'zh',
            })
                .then((res: any) => {
                    setIllegalStatus(false);
                    Modal.confirm({
                        title: t('sendSuceess'),
                        className: 'success-confirm-modal',
                        icon: <CheckCircleFilled className="success" />,
                        content: (
                            <p
                                style={{
                                    fontSize: '14px',
                                    lineHeight: '22px',
                                }}
                            >
                                {t('sendSuccessDesc')
                                    .replace('%s', res?.successMobile.length)
                                    .replace('%m', res?.failMobile.length)}
                            </p>
                        ),
                        okText: t('continueInvite'),
                        cancelText: t('finish'),
                        onCancel: () => {
                            destroy();
                        },
                        onOk: () => {
                            setDataList([
                                {
                                    mobile: '',
                                    name: '',
                                },
                                {
                                    mobile: '',
                                    name: '',
                                },
                                {
                                    mobile: '',
                                    name: '',
                                },
                            ]);
                        },
                    });
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    }
    return (
        <div className="mobile-invite">
            {dataList.map((item: { mobile: string; name: string }, index: number) => {
                return (
                    <div
                        style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}
                        key={index}
                    >
                        <div
                            style={{
                                flex: 1,
                                marginRight: 20,
                                position: 'relative',
                            }}
                        >
                            <InputWithCtr
                                placeholder={t('inputMobilePlaceholder')}
                                value={item.mobile}
                                onChange={(v: any) => onChange(v, index, 'mobile')}
                                allowClear
                                className={`${
                                    illegalStatus &&
                                    !ptn.test(item.mobile.split(' ').join('')) &&
                                    item.name
                                        ? 'warn-info'
                                        : ''
                                }`}
                                type="phone"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    marginTop: 4,
                                }}
                                className="warn"
                            >
                                {illegalStatus &&
                                !ptn.test(item.mobile.split(' ').join('')) &&
                                item.name
                                    ? t('errorNumber')
                                    : ''}
                            </p>
                        </div>
                        <div
                            style={{
                                flex: 1,
                                marginRight: 20,
                            }}
                        >
                            <Input
                                placeholder={t('inputNamePlaceholder')}
                                value={item.name}
                                onChange={(e: any) => onChange(e.target.value, index, 'name')}
                                allowClear
                                className={`${
                                    illegalStatus &&
                                    !item.name &&
                                    ptn.test(item.mobile.split(' ').join(''))
                                        ? 'warn-info'
                                        : ''
                                }`}
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    marginTop: 4,
                                }}
                                className="warn"
                            >
                                {illegalStatus && !item.name && ptn.test(item.mobile)
                                    ? t('errorName')
                                    : ''}
                            </p>
                        </div>
                        <span className="warn" onClick={() => deleteItem(index)}>
                            {t('delete')}
                        </span>
                    </div>
                );
            })}
            <p
                className="continueAdd"
                onClick={() => setDataList([...dataList, { mobile: '', name: '' }])}
            >
                {IconAdd}
                <span style={{ marginLeft: 5 }}>{t('continueAdd')}</span>
            </p>
            <div
                style={{
                    margin: '0 auto 0 auto',
                    height: 42,
                    width: 130,
                    opacity: checkStatus ? 1 : 0.3,
                }}
                className="btn small normal"
                onClick={() => onConfirm()}
            >
                {loading && <div style={{ marginRight: 4 }}>{loadingComp}</div>}
                {t('addConfirm')}
            </div>
        </div>
    );
}
function Invite({
    teamId,
    userData,
    destroy,
    appRuntimeEnv,
}: {
    teamId?: string;
    userData: any;
    appRuntimeEnv: AppRuntimeEnv;
    destroy: () => void;
}) {
    const { t } = useTranslation('joinMe');
    const [currentTab, setCurrentTab] = useState(Tabs.TeamCode);

    let ele = null;
    return (
        <div className="join-me">
            <div
                className="header"
                style={{
                    cursor: 'pointer',
                }}
            >
                {t('inviteTeamUser')}
            </div>
            <div className="content">
                <div
                    style={{
                        width: 780,
                    }}
                >
                    <div className="tabs">
                        <div
                            className={`tab ${currentTab === Tabs.TeamCode ? 'active' : ''}`}
                            onClick={() => setCurrentTab(Tabs.TeamCode)}
                        >
                            {t('teamCodeInvite')}
                        </div>
                        <div
                            className={`tab ${currentTab === Tabs.Mobile ? 'active' : ''}`}
                            onClick={() => setCurrentTab(Tabs.Mobile)}
                        >
                            {t('mobileInvite')}
                        </div>
                    </div>
                    <div>
                        {currentTab === Tabs.TeamCode ? (
                            <TeamCodeInvite
                                teamId={teamId}
                                userData={userData}
                                appRuntimeEnv={appRuntimeEnv}
                            />
                        ) : (
                            <MobileInvite teamId={teamId} userData={userData} destroy={destroy} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invite;
