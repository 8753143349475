import logger from '@/utils/logger';
import API from '@/components/Joyspace/api';
import isEmpty from 'lodash/isEmpty';
import { folderType, folderTypeEnum } from '@/components/Joyspace/config/permissions.config';
import { Order, Sort } from '@/components/Joyspace/components/List/Filters/config';
const defaultDataInfo = [
    {
        name: 'home',
        to: '/joyspace/home',
        icon: 'iconpc_yundoc_home',
        id: 'home',
        isLeaf: true,
        level: 0,
    },
    {
        name: 'myspace',
        to: '/joyspace/myspace',
        icon: 'iconpc_yundoc_myfile',
        id: 'myspace',
        full_path: folderType.MY,
        isLeaf: false,
        type: folderTypeEnum.MY,
        level: 0,
    },
    {
        name: 'teamspace',
        to: '/joyspace/teamspace',
        icon: 'iconpc_yundoc_sharefile',
        type: folderTypeEnum.SHARE,
        id: folderType.SHARE,
        full_path: folderType.SHARE,
        isLeaf: false,
        level: 0,
    },
    {
        name: 'collections',
        to: '/joyspace/collections',
        icon: 'iconpc_yundoc_enshrine',
        id: 'collections',
        isLeaf: true,
        level: 0,
        clstag: 'pageclick|keycount|focus_joyspace_01_1615797631505|7',
    },
    {
        name: 'trashes',
        to: '/joyspace/trashes',
        icon: 'iconpc_yundoc_recycle',
        id: 'trashes',
        isLeaf: true,
        level: 0,
        clstag: 'pageclick|keycount|focus_joyspace_01_1615797631505|8',
    },
];

export interface FolderInfoRes {
    id: string;
    type?: number;
    name: string;
    full_name?: string;
    full_path?: string;
    isLeaf?: boolean;
    [key: string]: any;
}
export interface ChildIdNode {
    id: string;
    children?: ChildIdNode[];
    [key: string]: any;
}
export interface FolderInfoResMap {
    [key: string]: FolderInfoRes;
}
export interface ChildIdMap {
    [key: string]: number;
}
export interface JoyspaceState {
    currentRouterInfo: FolderInfoRes | null;
    error: Error | null;
    folderInfo: FolderInfoRes | null;
    folderInfoMap: FolderInfoResMap | null;
    getFolderInfoById: Function;
    treeDataInfo: [];
    expandedKeys?: [];
    selectedKeys?: [];
    filterInfo: any;
}
// eslint-disable-next-line max-params
function updateTreeList(list: any, key: any, children: any, childIdMap?: ChildIdMap) {
    const data = list.map((node: any) => {
        if (node.id === key) {
            if (childIdMap) {
                const keys = Object.keys(childIdMap);
                const newChildren: ChildIdNode[] = [];
                node?.children?.forEach((element: ChildIdNode) => {
                    if (keys.includes(element.id)) {
                        newChildren.push(element);
                        delete childIdMap[element.id];
                    }
                });
                Object.keys(childIdMap).forEach((element) => {
                    newChildren.push({
                        id: element,
                    });
                });
                return {
                    ...node,
                    children: newChildren,
                };
            }
            return {
                ...node,
                children: children,
            };
        }
        if (node.children) {
            return {
                ...node,
                children: updateTreeList(node.children, key, children, childIdMap),
            };
        }
        return node;
    });
    return data;
}
function getfolderInfoMap(list: FolderInfoRes[]) {
    const defaultMap: FolderInfoResMap = {};
    list.forEach((item: FolderInfoRes) => {
        defaultMap[item.id] = item;
    });
    return defaultMap;
}
export default {
    namespace: 'joyspace',
    state: {
        currentRouterInfo: null,
        expandedKeys: [],
        selectedKeys: ['home'],
        treeDataInfo: defaultDataInfo,
        folderInfoMap: getfolderInfoMap(defaultDataInfo),
        filterInfo: {
            sort: Sort.UpDateTime,
            order: Order.DESC,
        },
    },
    reducers: {
        setExpandedKeys(state: JoyspaceState, { payload }: { payload: any }) {
            return { ...state, expandedKeys: payload };
        },
        setSelectedKeys(state: JoyspaceState, { payload }: { payload: any }) {
            return { ...state, selectedKeys: payload };
        },
        // 只支持树结构存储
        updateTreeDataInfo(state: JoyspaceState, { payload }: any) {
            return { ...state, treeDataInfo: payload };
        },
        setFolderInfo(state: JoyspaceState, { payload }: { payload: FolderInfoRes }) {
            return { ...state, folderInfo: payload };
        },
        setFolderInfoMap(state: JoyspaceState, { payload }: { payload: FolderInfoResMap }) {
            return { ...state, folderInfoMap: payload };
        },
        setCurrentRouterInfo(state: JoyspaceState, { payload }: { payload: FolderInfoRes }) {
            return { ...state, currentRouterInfo: payload };
        },
        setFilter(state: JoyspaceState, { payload }: any) {
            return { ...state, filterInfo: { ...payload } };
        },
        // 清空数据
        clearData(state: JoyspaceState) {
            return {
                currentRouterInfo: null,
                ...state,
                expandedKeys: [],
                treeDataInfo: defaultDataInfo,
                folderInfoMap: getfolderInfoMap(defaultDataInfo),
            };
        },
    },
    effects: {
        // 获取当前的文件夹
        *getCurrentRouterInfo({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            // 因为tree组件 不支持id直接赋值为root
            if (joyspaceState.currentRouterInfo.id === 'myspace') {
                return {
                    ...joyspaceState.currentRouterInfo,
                    id: folderType.MY,
                };
            }
            return joyspaceState.currentRouterInfo;
        },
        // 设置当前的文件夹
        *setCurrentRouterInfoById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const id = payload;
            if (id === null) {
                yield put({
                    type: 'setCurrentRouterInfo',
                    payload: null,
                });
                // yield put({
                //     type: 'setSelectedKeys',
                //     payload: ['home'],
                // });
                return;
            }

            yield put({
                type: 'setSelectedKeys',
                payload: [id],
            });
            let itemInfo = joyspaceState.folderInfoMap?.[id];

            if (!itemInfo) {
                let res;
                try {
                    // 因为最外层肯定存在所以不用重写id
                    res = yield call(API.categories.info, id);
                    itemInfo = res;
                    // eslint-disable-next-line no-debugger
                    // debugger;
                    console.log(res, 'resres');
                } catch (e) {
                    console.log(e, 'errrrrrrrrrrr');
                    return Promise.reject(e);
                }
                if (!res) {
                    return;
                }
                let folderInfoMap = {
                    ...joyspaceState.folderInfoMap,
                    id: res,
                };
                yield put({
                    type: 'setFolderInfoMap',
                    payload: {
                        ...folderInfoMap,
                    },
                });
            }
            const storeId = joyspaceState.currentRouterInfo?.id;
            yield put({
                type: 'setCurrentRouterInfo',
                payload: itemInfo,
            });
            if (!('parent' in itemInfo)) {
                return;
            }

            const pid = itemInfo.parent
                ? itemInfo.parent.includes('$')
                    ? 'myspace'
                    : itemInfo.parent
                : folderType.SHARE;
            // 只有通过父文件夹里点击过来的才展开左侧列表
            if (!joyspaceState.expandedKeys.includes(pid) && id && storeId === pid) {
                yield put({
                    type: 'setExpandedKeys',
                    payload: joyspaceState.expandedKeys.concat(pid),
                });
            }
        },
        // 设置当前的文件夹可能没有文件夹信息
        *goToFolderById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );

            const id = payload;
            if (id === null) {
                yield put({
                    type: 'setCurrentRouterInfo',
                    payload: null,
                });
                // yield put({
                //     type: 'setSelectedKeys',
                //     payload: ['home'],
                // });
                return;
            }
            const itemInfo = joyspaceState.folderInfoMap?.[id];
            if (itemInfo) {
                yield put({
                    type: 'setCurrentRouterInfoById',
                    payload: id,
                });
            } else {
                console.log('数据出错', id, joyspaceState.folderInfoMap);
            }
        },

        // 获取子文件夹
        *getFolderInfoById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const id = payload.id;

            if (!id) {
                return false;
            }

            let res;
            try {
                const useSpaceId = id === 'myspace' ? 'root' : id;
                res = yield call(API.pages.getFileList, useSpaceId, payload.params);
                console.log(res, 'resres');
            } catch (e) {
                console.log(e, 'errrrrrrrrrrr');
                return Promise.reject(e);
            }
            if (!res) {
                return [];
            }
            let folderInfoMap = {
                ...joyspaceState.folderInfoMap,
            };
            const childrenIds: ChildIdNode[] = [];
            res.forEach((element: FolderInfoRes) => {
                folderInfoMap[element.id] = element;
                childrenIds.push({
                    id: element.id,
                });
            });
            // if (joyspaceState.expandedKeys.includes(id)) {
            const updateTreeData = updateTreeList(joyspaceState.treeDataInfo, id, childrenIds);
            yield put({
                type: 'updateTreeDataInfo',
                payload: updateTreeData,
            });
            // }

            yield put({
                type: 'setFolderInfoMap',
                payload: {
                    ...folderInfoMap,
                },
            });
            return res;
        },

        // 文件列表刷新tree数据，只增加删除节点，不改变里面的
        *freshFolderTreeById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const id = payload.id;

            if (!id) {
                return false;
            }

            let res;

            try {
                const useSpaceId = id === 'myspace' ? 'root' : id;
                res = yield call(API.pages.getFileList, useSpaceId, payload.params);
            } catch (e) {
                return Promise.reject(e);
            }
            if (!res) {
                return [];
            }
            let folderInfoMap = {
                ...joyspaceState.folderInfoMap,
            };

            const childrenIds: ChildIdNode[] = [];
            const childIdMap: ChildIdMap = {};
            res.forEach((element: FolderInfoRes) => {
                folderInfoMap[element.id] = element;
                childIdMap[element.id] = 1;
                childrenIds.push({
                    id: element.id,
                });
            });

            const leftTree = id === 'root' ? 'myspace' : id;
            // if (joyspaceState.expandedKeys.includes(leftTree)) {
            const updateTreeData = updateTreeList(
                joyspaceState.treeDataInfo,
                leftTree,
                childrenIds,
                childIdMap
            );
            yield put({
                type: 'updateTreeDataInfo',
                payload: updateTreeData,
            });
            // }

            yield put({
                type: 'setFolderInfoMap',
                payload: {
                    ...folderInfoMap,
                },
            });
            return res;
        },
        // 增加map里的数据
        *addFolderInfo({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const list: FolderInfoRes[] = payload.list;
            let folderInfoMap = {
                ...joyspaceState.folderInfoMap,
            };
            list.forEach((element: FolderInfoRes) => {
                // if (typeof element.page_type === 'undefined') {
                folderInfoMap[element.id] = element;
                // }
            });

            yield put({
                type: 'setFolderInfoMap',
                payload: {
                    ...folderInfoMap,
                },
            });
        },
        // 刷新
        *freshLeftTreeByFolderById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const id = payload.id;

            if (!id) {
                return false;
            }
            const leftTree = id === 'root' ? 'myspace' : id;

            // 只有左侧的
            if (joyspaceState.expandedKeys.includes(leftTree)) {
                yield put({
                    type: 'freshFolderTreeById',
                    payload: {
                        id,
                    },
                });
            }
        },
        // 修改tree文件夹,如果是当前选中的文件夹则也更新
        *changeFolderInfoById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const id = payload.id;
            let folderInfoMap = {
                ...joyspaceState.folderInfoMap,
            };

            if (!id) {
                return false;
            }

            folderInfoMap[id] = {
                ...folderInfoMap[id],
                name: payload.name,
                description: payload.desc,
            };
            yield put({
                type: 'setFolderInfoMap',
                payload: {
                    ...folderInfoMap,
                },
            });
            if (joyspaceState.currentRouterInfo?.id === id) {
                yield put({
                    type: 'setCurrentRouterInfo',
                    payload: {
                        ...folderInfoMap[id],
                    },
                });
            }
        },
        // 删除tree文件夹
        *deleteFolderById({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );
            const id = payload.id;
            let folderInfoMap = {
                ...joyspaceState.folderInfoMap,
            };

            if (!id) {
                return false;
            }

            delete folderInfoMap[id];
            yield put({
                type: 'setFolderInfoMap',
                payload: {
                    ...folderInfoMap,
                },
            });
        },
        // 筛选器
        *setFilterInfo({ payload }: any, { call, put, select }: any): any {
            const joyspaceState = yield select(
                ({ joyspace }: { joyspace: JoyspaceState }) => joyspace
            );

            // const { srot = Sort.UpDateTime, order = Order.DESC } = payload;
            // joyspaceState.filterInfo
            yield put({
                type: 'setFilter',
                payload: {
                    ...payload,
                },
            });
        },
    },
};
