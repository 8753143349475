import React, { useCallback, useMemo, useContext } from 'react';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { connect } from 'dva';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { getTypeImage } from '@/components/Calendars/utils/getTypeImage';
import { formatTime, parsingMeetingInfo } from '../utils';
import { FocusSDK, isFocusEnv } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';

import list from '@/components/Projects/components/Member/List/list';
import { askSchedule, getScheduleInfo } from '@/api/calendar';
import { formatScheduleTime } from '@/components/Calendars/utils';
import { analysisLog } from '@/utils/logAnalytics';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';
import { MeetingPlatform } from '@/types/common';
import { joinCloudLink } from '@/components/MeetingCloudLink/utils';
import { joinMeeting } from '@/components/Meeting/utils';
import GlobalContext from '@/context/GlobalContext';
import { message as msg } from 'antd';
import { joinMeetingOperation } from '@/components/Calendars/utils/operation';
import { useThrottle } from '@/utils/chat/index';
export interface Mparam {
    scheduleId: string;
    beginTime: number;
    endTime: number;
    isNeedCheck: boolean;
    calendarId: string;
}

const CalendarNoticeCard = ({
    message,
    newPublicResult,
    changeScheduleInfoDraw,
}: {
    message: any;
    newPublicResult: boolean;
    changeScheduleInfoDraw: Function;
}) => {
    const { title, infox, extend, sender } = message;
    const { authInfo } = useContext(GlobalContext);
    const [t] = useTranslation('chat');
    const [calendarT] = useTranslation('calendar');
    const { deepLink } = infox;
    let content = extend.fields;
    const avatarSrc = sender.avatar;
    const theContent = useMemo(() => {
        let contentList: any[] = content;
        const messageContent: any[] = [];
        const composeNameData: any = {};
        const composeTimeData: any = {};
        const typeIdx = content.findIndex((obj: { key: string }) => obj.key === 'type');
        const titleIdx = content.findIndex((obj: { key: string }) => obj.key === 'title');
        if (typeIdx > -1 && titleIdx > -1) {
            composeNameData.name = content[titleIdx].name;
            composeNameData.value = (
                <>
                    <img
                        style={{ width: '15px', height: '15px', marginRight: '5px' }}
                        src={getTypeImage(content[typeIdx].value)}
                    />
                    {content[titleIdx].value}
                </>
            );
            composeNameData.isComponent = true;
            messageContent.push(composeNameData);
            contentList = contentList.filter((item) => item.key !== 'type' && item.key !== 'title');
        }
        const beginTimeIdx = content.findIndex((obj: { key: string }) => obj.key === 'beginTime');
        const endTimeIdx = content.findIndex((obj: { key: string }) => obj.key === 'endTime');
        if (beginTimeIdx > -1 && endTimeIdx > -1) {
            let btime = Number(content[beginTimeIdx].value);
            let stime = Number(content[endTimeIdx].value);
            composeTimeData.name = '时间';
            composeTimeData.value = formatScheduleTime(
                dayjs(btime),
                dayjs(stime),
                dayjs(btime).format('HH:mm') === '00:00' && dayjs(stime).format('HH:mm') === '00:00'
                    ? 1
                    : 0,
                calendarT
            );
            composeTimeData.isComponent = true;
            messageContent.push(composeTimeData);
            contentList = contentList.filter(
                (item) => item.key !== 'beginTime' && item.key !== 'endTime'
            );
        }
        const data: any = {};
        contentList?.forEach((i: any) => {
            const contentData: any = {};
            switch (i.key) {
                case 'type':
                    contentData.name = i.name;
                    contentData.value = (
                        <img
                            style={{ width: '15px', height: '15px' }}
                            src={changeEgovUrl(getTypeImage(i.value))}
                        />
                    );
                    contentData.isComponent = true;
                    messageContent.push(contentData);
                    break;
                case 'beginTime':
                case 'endTime':
                    contentData.name = i.name;
                    contentData.value = formatTime(dayjs(Number(i.value)), calendarT);
                    messageContent.push(contentData);
                    break;
                default:
                    contentData.name = i.name;
                    contentData.value = i.value;
                    messageContent.push(contentData);
            }
        });
        data.contentData = messageContent;
        return data;
    }, [content, calendarT]);
    // 点击卡片内容区域查看详情
    const handleDetail = useCallback(() => {
        const link = decodeURIComponent(deepLink);
        const strs = link.split('?mparam=');
        if (strs.length !== 2) {
            return;
        }
        const mparam = JSON.parse(strs[1]);
        analysisLog('xtbg_calhome_1640073907694', '21');
        changeScheduleInfoDraw({
            visible: true,
            scheduleId: mparam.scheduleId,
            selectedScheduleTime: dayjs(mparam.beginTime),
            from: ScheduleDetailFrom.AINoticeCard,
            openTime: dayjs(),
        });
    }, [deepLink, changeScheduleInfoDraw]);
    // jdme://jm/page_calendar_edit?mparam=%7B%22calendarId%22%3A%22270603332890918912%22%7D
    const handleJoinMeeting = useThrottle(() => {
        const link = decodeURIComponent(deepLink);
        const strs = link.split('?mparam=');
        if (strs.length !== 2) {
            return;
        }
        const mparam = JSON.parse(strs[1]);
        // 先查询日程详情获取最新的会议信息
        getScheduleInfo({ scheduleId: mparam.scheduleId })
            .then((res: any) => {
                if (!res) {
                    msg.warning(calendarT('schedule is not found'));
                    return;
                }
                let minfo = parsingMeetingInfo(res.description);
                if (minfo) {
                    joinMeetingOperation(authInfo, minfo, newPublicResult);
                } else {
                    msg.warning(calendarT('failed to join the meeting'));
                    return;
                }
            })
            .catch((err) => {
                msg.error(err.message?.toString() || calendarT('schedule is not found'));
            });
    }, 2000);
    const handleClick = useCallback(
        (btn) => {
            // DeeplinkEvent.open(deepLink);
            const link = decodeURIComponent(btn.params.deepLink);
            const strs = link.split('?mparam=');
            if (strs.length !== 2) {
                return;
            }
            const mparam = JSON.parse(strs[1]);
            if (btn.action === 'accept') {
                askSchedule({
                    scheduleId: mparam.scheduleId,
                    user: {
                        userId: FocusSDK.authInfo?.userId,
                        teamId: isFocusEnv()
                            ? FocusSDK.authInfo?.selectedTeamId
                            : FocusSDK.authInfo?.teamUserInfo?.teamId,
                    },
                    beforeBeginTime: mparam.beginTime,
                    beforeEndTime: mparam.endTime,
                    resStatus: 1,
                    message: '',
                    resType: 1,
                });
            }
            if (btn.action === 'access' || btn.action === 'refuse') {
                analysisLog('xtbg_calhome_1640073907694', '21');
                changeScheduleInfoDraw({
                    visible: true,
                    scheduleId: mparam.scheduleId,
                    selectedScheduleTime: dayjs(mparam.beginTime),
                    from: ScheduleDetailFrom.AINoticeCard,
                    openTime: dayjs(),
                });
            }
            if (btn.action === 'metting') {
                handleJoinMeeting();
            }
        },
        [changeScheduleInfoDraw, handleJoinMeeting]
    );
    const buttons: any[] = extend.btns;
    return (
        <ComNoticeCard
            title={title}
            contentList={theContent.contentData}
            avatarSrc={changeEgovUrl(avatarSrc)}
            type={t('schedule')}
            handleDetail={handleDetail}
            loadButtonsFn={
                buttons
                    ? () => {
                          return (
                              <div className="button_items">
                                  {buttons.map(
                                      (btn: any = {}, idx) =>
                                          btn.action && (
                                              <button
                                                  className={btn.status === '0' ? 'disable' : ''}
                                                  disabled={btn.status === '0' ? true : false}
                                                  key={idx}
                                                  onClick={() => handleClick(btn)}
                                              >
                                                  {btn.name}
                                              </button>
                                          )
                                  )}
                              </div>
                          );
                      }
                    : null
            }
        />
    );
};

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeScheduleInfoDraw: (payload: {
            visible: boolean;
            scheduleId: string;
            selectedScheduleTime: Dayjs;
            from: number;
            openTime: Dayjs;
        }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDraw',
                payload,
            }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarNoticeCard);
