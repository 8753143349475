/**
 * 日期工具类
 * @author sunzhiguang
 * @date 2020/6/12
 */
import dayjs from 'dayjs';
import i18n, { LANGUAGE } from '@/i18n';
import { MessageDateFormType, MessageDateType } from '@/types/chat/enum';

/*
 * 是否今天
 * @param timestamp
 */
export function isToday(timestamp: number): boolean {
    return dayjs().isSame(dayjs(timestamp), 'day');
}

/**
 * 两个时间戳是不是同一天
 * @param t1
 * @param t2
 */
export function isSameDay(t1: number, t2: number) {
    return dayjs(t1).isSame(dayjs(t2), 'day');
}

/**
 * 是否是昨天
 * @param timestamp
 */
export function isYesterday(timestamp: number): boolean {
    const today = dayjs(timestamp).add(1, 'day');
    return dayjs().isSame(today, 'day');
}

/**
 * 是否是前天
 * @param timestamp
 */
export function isBeforeYesterday(timestamp: number): boolean {
    const today = dayjs(timestamp).add(2, 'day');
    return dayjs().isSame(today, 'day');
}

/**
 * 年月日
 * @param timestamp
 */
export function nyr(timestamp: number): string {
    if (i18n.language === LANGUAGE.EN) {
        return dayjs(timestamp).format(`MM/DD/YYYY`);
    } else {
        return dayjs(timestamp).format(`YYYY年MM月DD日`);
    }
}

/**
 * 月日
 * @param timestamp
 */
export function yr(timestamp: number): string {
    if (i18n.language === LANGUAGE.EN) {
        return dayjs(timestamp).format(`MM/DD`);
    } else {
        return dayjs(timestamp).format(`MM月DD日`);
    }
}

/**
 * 月日
 * @param timestamp
 */
export function ny(timestamp: number, forceEN = false): string {
    if (forceEN || i18n.language === LANGUAGE.EN) {
        return dayjs(timestamp).format(`YYYY/MM`);
    } else {
        return dayjs(timestamp).format(`YYYY年MM月`);
    }
}

/**
 * 年月日 小时 分钟
 * @param timestamp
 */
export function nyrsf(timestamp: number): string {
    if (i18n.language === LANGUAGE.EN) {
        return dayjs(timestamp).format(`MM/DD/YYYY HH:mm`);
    } else {
        return dayjs(timestamp).format(`YYYY年MM月DD日 HH:mm`);
    }
}

/**
 * 月日 小时 分钟
 * @param timestamp
 */
export function yrsf(timestamp: number): string {
    if (i18n.language === LANGUAGE.EN) {
        return dayjs(timestamp).format(`MM/DD/ HH:mm`);
    } else {
        return dayjs(timestamp).format(`MM月DD日 HH:mm`);
    }
}

/**
 * 新增月日 小时 分钟
 * @param timestamp
 */
export function formate(timestamp: number | undefined): string {
    // if (i18n.language === LANGUAGE.EN) {
    //     return dayjs(timestamp).format(`MM/DD/ HH:mm`);
    // } else {
    //     return dayjs(timestamp).format(`MM月DD日 HH:mm`);
    // }
    return dayjs(timestamp).format(`YYYY.MM.DD HH:mm`);
}

/**
 * 组接龙月日 小时 分钟
 * @param timestamp
 */
export function Jlmate(timestamp: number | undefined): string {
    if (dayjs(timestamp).format(`YYYY`) === '2022') {
        return dayjs(timestamp).format(`M月D日 HH:mm`);
    }
    return dayjs(timestamp).format(`YYYY年M月D日 HH:mm`);
}

export function newDate(timestamp: number | undefined): string {
    return dayjs(timestamp).format(`YYYY年MM月DD日 HH:mm`);
}

/**
 * 小时 分钟
 * @param timestamp
 */
export function sf(timestamp: number): string {
    return dayjs(timestamp).format(`HH:mm`);
}

/**
 * 获取0点时间戳
 * @param timestamp
 */
export function dayStartStamp(timestamp: number) {
    return dayjs(timestamp).startOf('date').valueOf();
}

/**
 * 获取23:59:59时间戳
 * @param timestamp
 */
export function dayEndStamp(timestamp: number) {
    return dayjs(timestamp).endOf('date').valueOf();
}

/**
 * 是否是 start 天前 end天内 end可传-1或不错表前 start 前
 * @param timestamp
 * @param start start天前
 * @param end end天内
 */
export function dayRange(timestamp: number, start: number, end?: number): boolean {
    if (end && end > 0 && end < start) {
        return false;
    }
    const today = dayjs();
    const d1format = today.subtract(start, 'day').format('YYYY-MM-DD 23:59:59');
    const d1 = dayjs(d1format).valueOf();
    if (!end || end < 0) {
        return timestamp < d1;
    }
    const d2format = today.subtract(end, 'day').format('YYYY-MM-DD 00:00:00');
    const d2 = dayjs(d2format).valueOf();
    return d2 < timestamp && timestamp < d1;
}

/**
 * 是否本年前
 * @param timestamp
 */
export function isYearBefore(timestamp: number): boolean {
    return dayjs(timestamp).year() < dayjs().year();
}

/**
 * 返回星期几 如星期一
 * @param timestamp
 */
export function weekDay(timestamp: number) {
    const week = dayjs(timestamp).format('dddd');
    return i18n.t(`chat:week.${week}`);
}

/**
 * 是否本年
 * @param timestamp
 */
export function isCurrentYear(timestamp: number): boolean {
    return dayjs(timestamp).year() === dayjs().year();
}

/**
 * 会话列表时间格式化
 * @param timestamp
 */
export function sessionDateFormat(timestamp: number): string {
    if (!timestamp) {
        return '';
    }
    if (isToday(timestamp)) {
        return sf(timestamp);
    } else if (isYesterday(timestamp)) {
        return i18n.t(`chat:yesterday`); // '昨天';
    } else if (dayRange(timestamp, 2, 7)) {
        return weekDay(timestamp);
    } else if (isCurrentYear(timestamp) && dayRange(timestamp, 7, -1)) {
        return yr(timestamp);
    } else if (isYearBefore(timestamp)) {
        return nyr(timestamp);
    }
    return '';
}

/**
 * 消息时间格式化
 * @param timestamp
 * @param formatType
 * @param separator
 */
export function getMessageDateFormat(
    timestamp: number,
    formatType: MessageDateFormType,
    separator = ','
): string {
    const type = getMessageDateType(timestamp);
    if (formatType === MessageDateFormType.DATE_SPLIT) {
        // let format = messageDateSplitLineFormat(type, timestamp);
        // let text = i18n.t(`chat:${format}`);
        // if (text) format = text;
        return messageDateSplitLineFormat(type, timestamp);
    } else if (formatType === MessageDateFormType.DATE_AGGREGATION) {
        let [first, ...other] = messageAggregationFormat(type, timestamp, separator);
        if (first) {
            // let text = i18n.t(`chat:${first}`);
            // if (text) first = text;
            return `${first} ${other?.join('')}`;
        }
        return ` ${other?.join(' ')}`;
    }
    return '';
}

/**
 * 会话消息时间类型
 * @param timestamp
 */
export function getMessageDateType(timestamp: number): string {
    let type = '';
    if (isToday(timestamp)) {
        type = MessageDateType.TODAY; // '今天';
    } else if (isYesterday(timestamp)) {
        type = MessageDateType.YESTERDAY; // '昨天';
    } else if (dayRange(timestamp, 2, 7)) {
        type = MessageDateType.RANGE_2_7;
    } else if (isCurrentYear(timestamp) && dayRange(timestamp, 7, -1)) {
        // return yr(timestamp);
        type = MessageDateType.RANGE_7_0;
    } else if (isYearBefore(timestamp)) {
        type = MessageDateType.YEAR_BEFORE;
    }
    return type;
}

/**
 * 会话消息日期分格线格式化
 * @param type
 * @param timestamp
 */
export function messageDateSplitLineFormat(type: string, timestamp: number): string {
    if (!type) {
        return nyr(timestamp);
    }
    const temp: any = {
        [MessageDateType.TODAY]: i18n.t(`chat:${MessageDateType.TODAY}`),
        [MessageDateType.YESTERDAY]: i18n.t(`chat:${MessageDateType.YESTERDAY}`),
        [MessageDateType.RANGE_2_7]: weekDay(timestamp) + ', ' + yr(timestamp),
        [MessageDateType.RANGE_7_0]: yr(timestamp),
        [MessageDateType.YEAR_BEFORE]: nyr(timestamp),
    };
    return temp[type];
}

/**
 * 会话消息日聚合时间格式化
 * @param type
 * @param timestamp
 */
export function messageAggregationFormat(
    type: string,
    timestamp: number,
    separator = ','
): string[] {
    if (!type) {
        return [nyr(timestamp), sf(timestamp)];
    }
    const temp: any = {
        [MessageDateType.TODAY]: ['', sf(timestamp)],
        [MessageDateType.YESTERDAY]: [i18n.t(`chat:${MessageDateType.YESTERDAY}`), sf(timestamp)],
        [MessageDateType.RANGE_2_7]: [weekDay(timestamp), separator, sf(timestamp)],
        [MessageDateType.RANGE_7_0]: [yr(timestamp), sf(timestamp)],
        [MessageDateType.YEAR_BEFORE]: [nyr(timestamp), sf(timestamp)],
    };
    return temp[type];
}

const paddings = '00';
export function videoDurationFromat(duration: number): string {
    return [`${Math.floor(duration / 60)}`, `${duration % 60}`]
        .map((s) => `${paddings.slice(s.length)}${s}`)
        .join(':');
}
/**
 * 获取当前时间的星期一或星期日的ms
 * @param isGetMondayMs boolean true:获取星期一毫秒数 false:获取星期日毫秒数
 * @param ms number 毫秒
 */
export function getWeekMs(isGetMondayMs = true): number {
    let _Date = new Date(); // 获取系统时间
    let week = _Date.getDay(); // 获取星期
    let minusDay = 0;
    let time = '';
    if (isGetMondayMs) {
        time = '00:00:00';
        minusDay = week ? week - 1 : 6; // 处理星期一
    } else {
        time = '23:59:59';
        minusDay = week ? -(week - 1) : 0; // 处理星期日 注意下负号 负负为正
    }
    _Date.setDate(_Date.getDate() - minusDay); // 设置某个天数
    let ms = new Date(`${nyrEN(_Date.valueOf())} ${time}`).valueOf(); // 星期时间毫秒数
    return ms;
}
/**
 * 判断某个时间是否在当前时间周一至周日之间
 * @param timestamp number
 * @param boolean
 */
export function isWeekBetween(timestamp: number): boolean {
    let mondayMs = getWeekMs(); // 星期一时间毫秒数
    let sundayMs = getWeekMs(false); // 星期日时间毫秒数
    return timestamp >= mondayMs && timestamp <= sundayMs;
}

/**
 * 年月日 小时 分钟 英文版
 * @param timestamp MM/DD/YYYY HH:mm
 */
export function nyrsfEN(timestamp: number): string {
    return dayjs(timestamp).format(`YYYY/MM/DD HH:mm`);
}

/**
 * 年月日 英文版
 * @param timestamp MM/DD/YYYY
 */
export function nyrEN(timestamp: number): string {
    return dayjs(timestamp).format(`YYYY/MM/DD`);
}
/**
 * 微信时间类型
 * @param timestamp
 * * 仿微信时间格式化
 * ① 微信对于聊天消息时间显示的规则总结如下（首页“消息”界面）：
 * 1）当聊天消息时间为一周之内时：当天的消息显示为“小时:分钟”形式，然后是“昨天”、“前天”，然后就是“星期几”这个样子；
 * 2）当聊天消息的时间大于一周时：直接显示“年/月/日”的时间格式。
 * ② 微信对于聊天消息时间显示的规则总结如下（聊天内容界面）：
 * 1）当聊天消息时间为一周之内时：当天的消息显示为“小时:分钟”形式，然后是“昨天 时:分”、“前天 时:分”，然后就是“星期几 时:分”这个样子；
 * 2）当聊天消息的时间大于一周时：直接显示“年/月/日 时:分”的完整时间格式。
 * @param date 输出格式形如：“10:30”、“昨天 12:04”、“前天 20:51”、“星期二”、“2019/2/21 12:09”等形式
 */
export function getWxDate(timestamp: number): string {
    if (!timestamp) {
        return '';
    }
    let date = '';
    if (isToday(timestamp)) {
        date = sf(timestamp); // 今天
    } else if (isYesterday(timestamp)) {
        date = `昨天 ${sf(timestamp)}`; // 昨天
    } else if (isBeforeYesterday(timestamp)) {
        date = `前天 ${sf(timestamp)}`; // 前天
    } else if (isCurrentYear(timestamp) && isWeekBetween(timestamp)) {
        date = `${weekDay(timestamp)} ${sf(timestamp)}`;
    } else {
        date = nyrsfEN(timestamp);
    }
    return date;
}

/**
 * 年月日 小时 分钟 英文版
 * @param timestamp MMDDYYYYHHmm
 */
export function nyrsfENStr(timestamp: number): string {
    return dayjs(timestamp).format(`YYYYMMDDHHmm`);
}
/**
 * 年月日 小时 分钟 秒 英文版
 * @param timestamp MMDDYYYYHHmmss
 */
export function nyrssfENStr(timestamp: number): string {
    return dayjs(timestamp).format(`YYYYMMDDHHmmss`);
}

// 根据秒转换为返回时分秒
export function getTime(diff: number) {
    let secondTime = parseInt(String(diff), 10); // 秒
    let minuteTime = 0; // 分
    let hourTime = 0; // 小时
    if (secondTime > 60) {
        // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(String(secondTime / 60), 10);
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(String(secondTime % 60), 10);
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(String(minuteTime / 60), 10);
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(String(minuteTime % 60), 10);
            // console.log(hourTime, minuteTime);
        }
    }
    // let time = `${parseInt(String(secondTime), 10)}秒`;
    if (hourTime > 0) {
        // const realHourTime = minuteTime % 60 > 30 ? hourTime + 1 : hourTime;
        // time = `${parseInt(String(minuteTime), 10)}小时${time}`;
        return { time: hourTime, unit: '小时', remainSec: diff };
    }
    if (minuteTime > 0) {
        // const realMinTime = diff % 60 > 30 ? minuteTime + 1 : minuteTime;
        // time = `${parseInt(String(minuteTime), 10)}分${time}`;
        return { time: minuteTime, unit: '分', remainSec: diff };
    }

    return { time: secondTime, unit: '秒', remainSec: diff };
}
