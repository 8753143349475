/* eslint-disable array-callback-return */
/* eslint-disable no-self-compare */
/* eslint-disable complexity */
/**
 * 聊天消息项组件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { ReactNode } from 'react';
import './index.less';
import ChatState, { UserState } from '@/types/chat/State';
import {
    ChatMessage,
    ChatMessageType,
    Employee,
    MessageStatus,
    ChatMessagePlusEmitBody,
    TextChatMessageExt,
} from '@/types/chat';
import { connect } from 'dva';
import { sf } from '@/utils/date';
import { Checkbox, Dropdown, Menu, message, message as toast, Tooltip } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import SessionType from '@jd/jdee.im.sdk/lib/es/enum/SessionType';
import { HeadShowType, MessageSendType } from '@/types/chat/enum';
import classNames from 'classnames';
import Control from '@/components/chat/message/Item/Control';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import Revoke from '@/components/chat/message/Revoke';
import Text from '@/components/chat/message/Text';
import HttpCard from '@/components/chat/message/HttpCard';
import Image from '@/components/chat/message/Image';
import UploadFile from '@/components/chat/message/File/Upload';
import UploadWithDetail from '@/components/chat/message/File/UploadWithDetail';
import DownloadFile from '@/baseComponents/Chat/message/File/Download';
import Video from '@/components/chat/message/Video';
import Voice from '@/components/chat/message/Voice';
import Share from '@/components/chat/message/share';
import JoyspaceFile from '@/components/chat/message/message-components/JoyspaceFile';
import NoticeCard from '@/components/chat/message/NoticeCard';
import AINoticeCard from '@/components/chat/message/AINoticeCard';
import {
    checkMessageSupport,
    getMessageSendType,
    isCardViewGroupSession,
    isEqualEmployee,
    isGroupChatVisibleUnit,
    isSameClient,
    isShareCardMessage,
    isShowFakeNoticeCard,
    isShowOldNoticeCard,
    uidToEmployee,
} from '@/components/chat/utils/message';
import UnSupport from '@/components/chat/message/UnSupport';
import { UserCardPayload } from '@/models';
import { isFocusEnv } from '@/utils';
import SysMessage from '@/components/chat/message/Sys';
import { UserTag } from '@/components/Tags';
import { UserCardType } from '@/types/UserCard';
import { GroupRosterIdentity } from '@jd/jdee.im.sdk/lib/es/model/Roster';
import { isGroupOrdinary } from '@/components/chat/utils/group';
import Bus from '@/utils/bus';
import EmojiReply from '@/components/chat/message/EmojiReply/index';
import MessageControl from '@/components/chat/message/MsgControl';
import ChatAggregation from '../../message/ChatAggregation/index';
import CountDown from '../CountDown';
import { isAINoticeSession } from '../../utils/session';
import GroupNotificationCard from '../GroupNotificationCard';
import SolitaireCard from '../SolitaireCard';
import ImService from '@/server/ImService';
import { entries } from 'lodash';
import { analysisLog } from '@/utils/logAnalytics';
interface MessageItemProps {
    sendTime: React.ReactElement;
    message: ChatMessage & ChatMessagePlusEmitBody;
    groupRosterIdentity: GroupRosterIdentity | '';
    prevMessage: ChatMessage | null;
    getCurrentMessageId: Function;
    setCurrentMessageId: Function;
    unLock: Function;
    getLock: (lock?: boolean) => boolean;
    members: Employee[];
    sText?: string;
    sMid?: number;
    refreshMessageBox: any;
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
    // io: any;
}
interface OwnState {
    avatarRightClick: boolean;
    showOperate: boolean;
    messageExtend: any;
    beginSend: boolean;
    selectChatItem: boolean;
    hasEmoji: boolean;
    visible: boolean;
}

interface DvaDispatchProps {
    deleteSessionMessage: (data: { message: ChatMessage }) => void;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    getEbookDeptInfo: (params: any) => void;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}

type IProps = Readonly<
    MessageItemProps & ChatState & DvaDispatchProps & UserState & WithTranslation
>;
class ChatItem extends React.Component<IProps, OwnState> {
    wrapRef: any;
    animation: any;
    // eslint-disable-next-line @typescript-eslint/member-ordering
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        // 该方法内禁止访问thisx
        if (nextProps.selectMore) {
            // 通过对比nextProps和prevState，返回一个用于更新状态的对象
            return {
                showOperate: false,
            };
        } else {
            return {
                selectChatItem: false,
            };
        }
        // 不需要更新状态，返回null
        // return null;
    }
    constructor(props: IProps) {
        super(props);
        this.state = {
            avatarRightClick: false,
            showOperate: false,
            messageExtend: {},
            beginSend: false,
            selectChatItem: false,
            hasEmoji: false,
            visible: false,
        };
        this.wrapRef = React.createRef();
    }
    componentDidMount() {
        Bus.on('message-item-chatitem', this.visibleChange);
        Bus.on('chat:clearSelect', this.addclearSelectListener);
        // Bus.on('contextmenu:message-operate:hide', this.handleVisible);
        // 只有io不为空
        const { message, selectedSession } = this.props;
        const scrollEle = document.getElementById('message-box-container-scroll');
        let io: any;
        if (scrollEle) {
            io = selectedSession?.isSecret
                ? new IntersectionObserver((entries) => this.getMessages(io, entries), {
                      root: scrollEle,
                      threshold: [0.5],
                  })
                : null;
        }
        if (io) {
            const element = document.getElementById(`item-${message.id}-${message.timestamp}`);
            // console.log('element', element);
            // 有消息且消息是对方发的文本消息才会做可视区域监听
            if (element && this.isVisibleOfText()) {
                // console.log('对方发的监听');
                io.observe(element);
            }
        }
    }
    componentWillUnmount() {
        Bus.off('message-item-chatitem', this.visibleChange);
        Bus.off('chat:clearSelect', this.addclearSelectListener);
        // Bus.off('contextmenu:message-operate:hide', this.handleVisible);
        this.setState = (state, callback) => {
            return;
        };
    }
    visibleChange = (visible: any) => {
        if (visible) {
            this.setState({ visible: false });
            this.maskShow();
        }
    };
    addclearSelectListener = (params: any) => {
        if (params.clearSelect) {
            this.setState({
                selectChatItem: false,
            });
        }
    };

    getMessages = (io: any, entries: any[]) => {
        const { updateSessionMessageMid, updateSessionMessagesTimestamp } = this.props;
        const imService = ImService.getInstance();
        // console.log('entries', entries);
        entries.forEach(async (entry) => {
            if (entry.isIntersecting) {
                // 当前元素可见 设置消息读取时间
                const message = JSON.parse(entry.target.dataset.message);
                // console.log('可见元素', message);
                if (!message.readTimeOfMessage) {
                    const readTimeOfMessage = new Date().getTime();
                    const result = await imService?.updateReadTime(message.sessionId, message.mid, {
                        ...message,
                        readTimeOfMessage,
                    });
                    if (result) {
                        await updateSessionMessageMid({
                            sessionId: message.sessionId,
                            sendResult: {
                                body: { ...message, readTimeOfMessage },
                            },
                        });
                        await updateSessionMessagesTimestamp({
                            timestamp: new Date().getTime(),
                        });
                    }
                    io?.unobserve(entry.target); // 停止观察当前元素 避免不可见时候再次调用callback函数
                }
            }
        });
    };

    isVisibleOfText() {
        const { message } = this.props;
        const sendType = this.getSendType();
        if (sendType === MessageSendType.OTHER) {
            return message.type === ChatMessageType.TEXT && message?.expire;
        }
        return false;
    }
    getSendUserName() {
        const { selectedSession: session, message, currentEmployee } = this.props;
        if (session.sessionType === SessionType.GROUP) {
            return (
                <div className="name-tag" onClick={this.openUserCard.bind(this)}>
                    <div>{message.sender.name}</div>
                    {!isEqualEmployee(message.sender, currentEmployee) && (
                        <>
                            <div className="tag">
                                <UserTag
                                    userInfo={message.sender as Employee}
                                    currentEmployee={currentEmployee}
                                />
                            </div>
                            {isGroupChatVisibleUnit(session) && message.sender.unitName && (
                                <div className="unitName">@{message.sender.unitName}</div>
                            )}
                        </>
                    )}
                </div>
            );
        }
    }

    /**
     * 消息已准备好发送
     * @param param
     */
    messageReady = (param: { beginSend: boolean; messageExtend: any }) => {
        this.setState({
            messageExtend: param.messageExtend,
            beginSend: param.beginSend,
        });
    };

    getSendType() {
        const { message, currentEmployee, selectedSession } = this.props;
        return getMessageSendType(message, currentEmployee, selectedSession);
    }

    getMessageItemProps(message: any, sendType: MessageSendType): any {
        return {
            message: message,
            sendType: sendType,
            sessionType: this.props.selectedSession.sessionType,
            onReady: this.messageReady,
            refreshMessageBox: this.props.refreshMessageBox,
        };
    }

    // eslint-disable-next-line complexity
    getChatMessageNode(sendType: MessageSendType): ReactNode {
        const { currentEmployee, message, selectedSession } = this.props;
        const isSupport = checkMessageSupport(message);
        if (!isSupport) {
            return <UnSupport message={message} />;
        }
        const props = this.getMessageItemProps(message, sendType);
        if (message.revoke === 1) {
            // 该消息已撤回
            return <Revoke {...props} />;
        }
        const { sText = '', sMid = -1 } = this.props;
        switch (message.type) {
            case ChatMessageType.TEXT:
                if ((message as any).nativeId === 'share_link') {
                    return <HttpCard {...props} />;
                }
                return <Text {...props} sText={sText} sMid={sMid} />;
            case ChatMessageType.IMAGE:
                return <Image {...props} />;
            case ChatMessageType.FILE: {
                if (
                    props.sendType === MessageSendType.OWNER ||
                    props.sendType === MessageSendType.SELF
                ) {
                    if (
                        !isSameClient(message) ||
                        (isFocusEnv() && !message.downLocalPath && !message.upLocalPath)
                    ) {
                        if (
                            isEqualEmployee(message.sender, currentEmployee) &&
                            selectedSession.isGroup
                        ) {
                            return <UploadWithDetail {...props} />;
                        } else {
                            return <DownloadFile {...props} />;
                        }
                    } else {
                        if (
                            isEqualEmployee(message.sender, currentEmployee) &&
                            selectedSession.isGroup
                        ) {
                            return <UploadWithDetail {...props} />;
                        } else {
                            return <UploadFile {...props} />;
                        }
                    }
                } else {
                    return <DownloadFile {...props} />;
                }
            }
            case ChatMessageType.VIDEO:
                return <Video {...props} />;
            case ChatMessageType.VOICE:
                return <Voice {...props} />;
            case ChatMessageType.TEMPLATE2:
                return <Share {...props} />;
            case ChatMessageType.JOYSPACEFILE:
                return <JoyspaceFile {...props} />;
            // 组通知
            case ChatMessageType.GROUPNOTICE:
                return (
                    <GroupNotificationCard
                        selectedSession={this.props.selectedSession}
                        {...props}
                    />
                );
            // @ts-ignore
            case 'unified_notice_message':
                if (!isShowOldNoticeCard(message) || isShowFakeNoticeCard(message)) {
                    return <AINoticeCard {...props} />;
                }
                return <NoticeCard {...props} />;
            case ChatMessageType.SYS:
                return <SysMessage {...props} />;
            case ChatMessageType.SOLITAIRE:
                return <SolitaireCard selectedSession={selectedSession} {...props} />;
            default:
                return null;
        }
    }

    openUserCard() {
        const { selectedSession, message, currentEmployee, t, groupRosterIdentity } = this.props;
        if (selectedSession?.isNotice) {
            return;
        } else {
            const { from } = message;
            // 如果群成员并且禁止了查看群成员名片
            if (
                !isEqualEmployee(currentEmployee, uidToEmployee(from) as Employee) &&
                isGroupOrdinary(groupRosterIdentity) &&
                !isCardViewGroupSession(selectedSession)
            ) {
                toast.warn(t('can_not_open_user_card_tip'));
                return false;
            }
            analysisLog('Xtbg_Msg_Dialog', 'ViewMember');
            Bus.emit('app:toggle-user-card-modal:show', {
                visible: true,
                type: UserCardType.User,
                userId: from?.pin,
                teamId: from.teamId,
                appId: from.app,
            });
        }
    }

    getHeadPortrait(): ReactNode {
        const { selectedSession, message, prevMessage } = this.props;
        let msg: any = prevMessage;
        if (!prevMessage || prevMessage.revoke === 1 || msg.plusList) {
            return (
                <HeadPortrait
                    width="32px"
                    height="32px"
                    imgUrl={message.sender.avatar}
                    name={message.sender.name}
                    type={selectedSession.sessionType}
                    isUnFollow={true}
                    showType={HeadShowType.SESSION_LIST}
                    onItemClick={this.openUserCard.bind(this)}
                />
            );
        }
        return null;
    }

    getMsgItemClass(sendType: MessageSendType) {
        return classNames({
            'message-box': true,
            reverse: [MessageSendType.OWNER, MessageSendType.SELF].includes(sendType),
            'content-height': !this.props.prevMessage,
            group: this.props.selectedSession.sessionType === SessionType.GROUP,
            'select-more': this.props.selectMore,
            selectChatItem: this.state.selectChatItem && this.props.selectMore,
        });
    }

    getContentItemClass(sendType: MessageSendType) {
        return classNames({
            'row-item-wrap': true,
            owner: sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF,
            'has-emoji': this.state.hasEmoji,
        });
    }

    showOperate = () => {
        const message = this.props.message;
        if (message.revoke || !message.mid) {
            // 撤回的消息是没有操作权限的
            return;
        }
        this.props.setCurrentMessageId(message.id);
        const isNotLock = this.props.getLock();
        if (isNotLock) {
            if (this.state.showOperate) {
                return;
            }
            this.setState({ showOperate: true });
        }
    };
    handleSelectMore = () => {
        const { selectChatItem } = this.state;
        const { message, messages, selectMore, selectedSession } = this.props;
        // const isSupport = checkMessageSupport(message);
        // const isNotSelect = selectedSession.isSecret
        //     ? !isSupport
        //     : !isSupport || message.type === ChatMessageType.VOICE;
        // if (isNotSelect) {
        //     return;
        // }
        if (messages.length > 99 && !selectChatItem) {
            toast.error('沟通记录最多可选100条');
            return;
        }
        if (selectMore) {
            this.setState(
                {
                    selectChatItem: !selectChatItem,
                },
                () => {
                    const { selectChatItem } = this.state;
                    if (selectChatItem) {
                        messages.push(message);
                        let newMessages = messages.sort(
                            (item1, item2) => item1.timestamp - item2.timestamp
                        );
                        this.props.updateMessages({ messages: newMessages });
                    } else {
                        let newMessages = messages.filter((item) => item.id !== message.id);
                        newMessages = newMessages.sort(
                            (item1, item2) => item1.timestamp - item2.timestamp
                        );
                        this.props.updateMessages({ messages: newMessages });
                    }
                }
            );
        }
    };
    maskShow = () => {
        const mask = document.getElementById('message-box-container-scroll');
        if (!mask) return;
        // const scrollView = mask.getElementsByClassName('scroll-view-content')[0] as HTMLElement;
        // scrollView.style.overflowY = '';
        mask.style.overflowY = '';
    };
    maskHide = () => {
        const mask = document.getElementById('message-box-container-scroll');
        if (!mask) return;
        // const scrollView = mask.getElementsByClassName('scroll-view-content')[0] as HTMLElement;
        // scrollView.style.overflowY = 'hidden';
        mask.style.overflowY = 'hidden';
    };
    unLock = () => {
        this.onMouseLeave();
        this.props.unLock();
        this.maskShow();
        this.setState({ visible: false });
    };

    onMouseLeave = () => {
        this.props.setCurrentMessageId('');
        if (!this.props.getLock()) {
            return;
        }
        this.setState({ showOperate: false });
    };
    // handleVisible = () => {
    //     this.setState({ visible: false });
    // };
    onVisibleChange = (flag: boolean) => {
        // if (this.props.message.type === ChatMessageType.GROUPNOTICE) {
        //     return;
        // }
        // this.onMouseLeave();
        this.props.unLock();
        Bus.off('message-item-chatitem').on('message-item-chatitem', (data) => {
            this.setState({ visible: false });
            this.maskShow();
        });
        this.setState({ visible: flag });
        if (flag) {
            this.maskHide();
        } else {
            this.maskShow();
        }
        // Bus.emit('contextmenu:short-link:hide');
    };
    getTimeRowPart = () => {
        const { prevMessage, sendTime, message, selectedSession } = this.props;
        let msg: any = prevMessage;
        if (!prevMessage || prevMessage?.revoke === 1 || msg.plusList) {
            return (
                <div
                    className={`time-row ${
                        isAINoticeSession(selectedSession) &&
                        (!isShowOldNoticeCard(message) || isShowFakeNoticeCard(message))
                            ? 'marL'
                            : ''
                    }`}
                >
                    {this.getSendUserName()}
                    {/* <Tooltip title={sendTime}>{sendTime}</Tooltip> */}
                    {/* <div>{sendTime}</div> */}
                </div>
            );
        }
        return null;
    };

    getSfPart = () => {
        const { message, prevMessage } = this.props;
        let msg: any = prevMessage;
        if (!prevMessage || prevMessage?.revoke === 1 || msg.plusList) {
            return null;
        }

        return (
            <Tooltip title={sf(message.timestamp)}>
                <div className="sf">{sf(message.timestamp)}</div>
            </Tooltip>
        );
    };

    emojiReplyCallback = (hasEmoji: boolean) => {
        this.setState({ hasEmoji: hasEmoji });
    };

    getSelectMore = () => {
        const { selectMore, message, selectedSession } = this.props;
        const { selectChatItem } = this.state;
        // const sendType = this.getSendType();
        // let diffDom: any = this.getChatMessageNode(sendType);
        const isSupport = checkMessageSupport(message);
        // @ts-ignore
        const isNotSelect = selectedSession.isSecret
            ? !isSupport
            : !isSupport || message.type === ChatMessageType.VOICE;
        if (isNotSelect) {
            return (
                selectMore && (
                    <div className="checkbox-wrapper">
                        <Checkbox disabled />
                    </div>
                )
            );
        }
        return (
            selectMore && (
                <div className="checkbox-wrapper">
                    <Checkbox checked={selectChatItem} />
                </div>
            )
        );
    };

    showEmojiReply = () => {
        const { message } = this.props;
        return !isShareCardMessage(message);
    };
    handleAvatarRightClick = (visible: boolean) => {
        const { selectedSession } = this.props;
        if (selectedSession.isSingle) {
            this.setState({ avatarRightClick: false });
            return;
        }
        // console.log('avatarRightClick', visible);
        this.setState({ avatarRightClick: visible });
    };
    // 右键AT
    userSelectOk = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const { message } = this.props;
        const data = [
            {
                id: `${message.from.pin}:${message.from.app}:${message.from.teamId}`,
                type: 'user',
                name: message.nickName,
            },
        ];
        Bus.emit(`message:mentionUser`, data);
        this.setState({ avatarRightClick: false });
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    menu = (
        <Menu>
            <Menu.Item>
                <a
                    onClick={(e) => {
                        this.userSelectOk(e);
                    }}
                    style={{ fontSize: 14 }}
                >
                    @ {this.props.message.nickName}
                </a>
            </Menu.Item>
        </Menu>
    );
    onSendStatus = (status: MessageStatus) => {
        // 接收控制发送消息组件的状态，只有不是INIT的状态才会接收
        if (status !== MessageStatus.INIT) {
            // setSendStatus(status);
            return;
        }
    };

    public render() {
        const {
            message,
            selectMore,
            selectedSession,
            currentEmployee,
            refreshMessageBox,
        } = this.props;
        if (!message.type) {
            return null;
        }
        const { messageExtend, beginSend, visible, showOperate, avatarRightClick } = this.state;
        const sendType = this.getSendType();
        const itemEventConfig = {
            onMouseEnter: this.showOperate,
            onClick: this.handleSelectMore,
            onMouseLeave: this.onMouseLeave,
            className: this.getMsgItemClass(sendType),
        };
        if (message.id) {
            return (
                <div ref={this.wrapRef}>
                    {/* 消息聚合 */}
                    {selectedSession?.isGroup === true && message.plusList ? (
                        // <div
                        //     onMouseLeave={() => {
                        //         this.setState({ visible: false });
                        //         this.maskShow();
                        //     }}
                        // >
                        <Dropdown
                            overlay={() => {
                                return (
                                    <Control
                                        {...this.props}
                                        unLock={this.unLock}
                                        showOperateBox={visible}
                                    />
                                );
                            }}
                            trigger={['contextMenu']}
                            visible={visible}
                            onVisibleChange={this.onVisibleChange}
                        >
                            <div>
                                <ChatAggregation
                                    message={message}
                                    selectedSession={selectedSession}
                                    currentEmployee={currentEmployee}
                                    sendType={sendType}
                                    sessionType={selectedSession.sessionType}
                                    onReady={this.messageReady}
                                />
                            </div>
                        </Dropdown>
                    ) : (
                        // </div>
                        <div
                            {...itemEventConfig}
                            id={`item-${message.id}-${message.timestamp}`}
                            data-message={this.isVisibleOfText() ? JSON.stringify(message) : null}
                        >
                            {!message.plusList &&
                                message.type !== ChatMessageType.SOLITAIRE &&
                                message.type !== ChatMessageType.GROUPNOTICE && (
                                    <div>{this.getSelectMore()}</div>
                                )}
                            <div className="content-row">
                                {/* 右键@ */}
                                {isAINoticeSession(selectedSession) &&
                                (!isShowOldNoticeCard(message) ||
                                    isShowFakeNoticeCard(message)) ? null : (
                                    <>
                                        <Dropdown
                                            overlay={this.menu}
                                            trigger={['contextMenu']}
                                            visible={avatarRightClick}
                                            onVisibleChange={(visible) =>
                                                this.handleAvatarRightClick(visible)
                                            }
                                            placement="bottomLeft"
                                        >
                                            <div>{this.getHeadPortrait()}</div>
                                        </Dropdown>

                                        {this.getSfPart()}
                                    </>
                                )}
                                {/* 右键显示操作 */}
                                <Dropdown
                                    overlay={() => {
                                        return (
                                            <Control
                                                {...this.props}
                                                unLock={this.unLock}
                                                showOperateBox={visible}
                                                isRightClick={visible}
                                            />
                                        );
                                    }}
                                    trigger={['contextMenu']}
                                    visible={visible}
                                    onVisibleChange={this.onVisibleChange}
                                >
                                    <div className={this.getContentItemClass(sendType)}>
                                        {this.getTimeRowPart()}
                                        <div className="content-container-202">
                                            {this.getChatMessageNode(sendType)}
                                            {!selectMore ? (
                                                <div
                                                    className={`chat-item-control ${
                                                        selectedSession.isSecret ||
                                                        message.type === ChatMessageType.VOICE
                                                            ? 'chat-item-control_flex_end'
                                                            : ''
                                                    }`}
                                                >
                                                    {/* hover 显示操作 & */}
                                                    {!selectedSession.isSecret &&
                                                        message.type !== ChatMessageType.VOICE && (
                                                            <Control
                                                                {...this.props}
                                                                unLock={this.unLock}
                                                                showOperateBox={showOperate}
                                                            />
                                                        )}
                                                    <MessageControl
                                                        sendType={sendType}
                                                        message={message}
                                                        sessionType={selectedSession.sessionType}
                                                        beginSend={beginSend}
                                                        messageExtend={messageExtend}
                                                        onSendStatus={this.onSendStatus}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        minWidth: `${
                                                            selectedSession.isSecret
                                                                ? '36px'
                                                                : '86px'
                                                        }`,
                                                    }}
                                                />
                                            )}
                                            {/* TODO: 单聊密聊显示 */}
                                            {selectedSession?.isSecret && message.expire ? (
                                                <CountDown
                                                    message={message}
                                                    sendType={sendType}
                                                    refreshMessageBox={refreshMessageBox}
                                                />
                                            ) : null}
                                        </div>
                                        {this.showEmojiReply() && (
                                            <EmojiReply
                                                sessionType={selectedSession.sessionType}
                                                message={message}
                                                sendType={sendType}
                                                callback={this.emojiReplyCallback}
                                            />
                                        )}
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        return <div />;
    }
}

function mapStateToProps({ chat }: any) {
    const {
        selectedSession,
        currentEmployee,
        selectMore,
        messages,
        sessionMessageMap,
    } = chat as ChatState;
    return { selectedSession, currentEmployee, selectMore, messages, sessionMessageMap };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async deleteSessionMessage(data: { message: ChatMessage }) {
            await dispatch({ type: 'chat/deleteSessionMessage', payload: data });
        },
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        getEbookDeptInfo(data: any) {
            dispatch({ type: 'ebook/getEbookDeptInfo', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(ChatItem));
