import {
    getJoySpaceReadAllPermission,
    getJoySpaceReadOnlyPermission,
    getJoySpaceReadWritePermission,
    joyspacePermissionType,
} from '../../config/permissions.config';

const joySpaceReadOnlyPermissionItem = {
    title: '仅阅读',
    permissions: getJoySpaceReadOnlyPermission(),
};
const joySpaceReadWritePermissionItem = {
    title: '可编辑',
    permissions: getJoySpaceReadWritePermission(),
};
const joySpaceReadAllPermissionItem = {
    title: '所有者',
    permissions: getJoySpaceReadAllPermission(),
};

export const getTeamWorkMenus = () => {
    return [joySpaceReadWritePermissionItem, joySpaceReadOnlyPermissionItem];
};

export const getPermissionMenus = (permission: joyspacePermissionType) => {
    const menus = [];
    if (permission === getJoySpaceReadAllPermission()) {
        menus.push(joySpaceReadAllPermissionItem, joySpaceReadWritePermissionItem);
    } else if (permission === getJoySpaceReadWritePermission()) {
        menus.push(joySpaceReadWritePermissionItem);
    }
    menus.push(joySpaceReadOnlyPermissionItem);
    return menus;
};

export const getPermissionText = (permission: joyspacePermissionType) => {
    if (permission === getJoySpaceReadAllPermission()) {
        return joySpaceReadAllPermissionItem;
    } else if (permission === getJoySpaceReadWritePermission()) {
        return joySpaceReadWritePermissionItem;
    } else if (permission === getJoySpaceReadOnlyPermission()) {
        return joySpaceReadOnlyPermissionItem;
    }
};

// 获取分页
export const getPageSize = () => {
    return { pageIndex: 1, pageSize: 30 };
};

export interface SelectType {
    id: string;
    avatar: string;
    name: string;
    desc: string;
    type: string;
    checked?: boolean;
    permissionType: joyspacePermissionType;
}
