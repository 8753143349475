import React, { useMemo, useEffect } from 'react';
import './index.less';
import { detectBrowser, detectOS } from '@/utils';
import DefaultPage from '@/components/DefaultPage';
import { connect } from 'dva';
import { CompDataLoadState, DefaultPageType } from '@/models';
// import CreateCompleteTaskDraw from '../Work/CreateCompleteTaskDraw';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import CreateTaskDraw from '../Work/CreateTaskDraw';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ContentFrame({
    leftSide,
    rightSide,
    rightLoadState,
    rightPageType,
    width,
}: {
    leftSide?: React.ReactNode;
    rightSide: React.ReactNode;
    rightLoadState: CompDataLoadState;
    rightPageType: DefaultPageType;
    width?: string;
}) {
    const rightNode = useMemo(() => {
        let rightNode = null;
        switch (rightLoadState) {
            case CompDataLoadState.ERROR:
                rightNode = <DefaultPage pageType={rightPageType} dataType={rightLoadState} />;
                break;
            case CompDataLoadState.NODATA:
                // (偶现)桌面端筛选未读时后台切换其他应用在切换回来会话页面闪 --hujun
                // rightNode = <DefaultPage pageType={rightPageType} dataType={rightLoadState} />;
                rightNode = rightSide;
                break;
            case CompDataLoadState.NORMAL:
                rightNode = rightSide;
                break;
            case CompDataLoadState.INIT:
                rightNode = rightSide;
                break;
            case CompDataLoadState.LOADING:
                rightNode = <Spin indicator={antIcon} />;
                break;
            default:
                rightNode = null;
                break;
        }
        if (!React.isValidElement(rightNode)) {
            rightNode = (
                <DefaultPage
                    pageType={DefaultPageType.NO_DATA}
                    dataType={CompDataLoadState.NODATA}
                />
            );
        }
        return rightNode;
    }, [rightSide, rightPageType, rightLoadState]);

    useEffect(() => {
        let onMousedown: any = document.getElementById('tabs_line'); // 透明线
        let weizhi: any = document.getElementById('contentFrameLeftSide'); // 左侧列表
        let body: any = document.querySelector('body');
        let istext: any = document.querySelectorAll('#istext');
        let botlie: any = document.querySelectorAll('#bottom-line');

        // 获取鼠标点击位置
        onMousedown?.addEventListener('mousedown', function (e: any) {
            // console.log('33333', body);
            // 鼠标按下时的位置
            let mouseDownX = e.clientX;
            let H = weizhi.offsetWidth;
            body.style.cursor = 'n-resize';
            body.style.opacity = '1.1';
            body.style.position = 'absolute';
            body.style.top = '0';
            body.style.left = '0';
            body.style.width = '100%';
            body.style.backgroundColor = '#fff';
            body.style.zIndex = '5000';
            let timer: any = null;
            clearTimeout(timer);
            timer = setTimeout(function () {
                document.addEventListener('mousemove', move);
            }, 200);
            function move(e: any) {
                let mouseMoveX = e.clientX;
                weizhi.style.width = mouseMoveX - mouseDownX + H + 'px';
                if (weizhi.style.width <= 290 + 'px') {
                    weizhi.style.minWidth = 291 + 'px';
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < istext.length; i++) {
                        istext[i].style.maxWidth = 180 + 'px';
                    }
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < botlie.length; i++) {
                        botlie[i].style.width = 234 + 'px';
                    }
                }
                if (weizhi.style.width >= 380 + 'px') {
                    weizhi.style.maxWidth = 381 + 'px';
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < istext.length; i++) {
                        istext[i].style.maxWidth = 270 + 'px';
                    }
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < botlie.length; i++) {
                        botlie[i].style.width = 324 + 'px';
                    }
                }
            }
            // // (3) 鼠标弹起，就让鼠标移动事件移除
            document.addEventListener('mouseup', function (evt: any) {
                evt.stopPropagation();
                evt.preventDefault();
                document.onmousemove = null;
                document.onmouseup = null;
                body.style.cursor = 'auto';
                document.removeEventListener('mousemove', move);
            });
        });
    }, []);

    return (
        <div className="content-frame">
            {leftSide && (
                <div
                    className="contentFrameLeftSide"
                    id="contentFrameLeftSide"
                    style={{
                        width: width
                            ? '210px'
                            : detectOS() === 'Win' && detectBrowser() === 'Firefox'
                            ? 308
                            : 298,
                    }}
                >
                    <div className="tabs_line" id="tabs_line" />
                    {leftSide}
                </div>
            )}
            <div
                className="me-comps-content-frame-right"
                style={{
                    display: 'flex',
                    flex: 1,
                }}
            >
                {rightNode}
            </div>

            {/* 新建任务 */}
            {/* <CreateTaskDraw /> */}

            {/* 新建日程 */}
            {/* <CreateCompleteTaskDraw /> */}
        </div>
    );
}

function mapStateToProps({ app }: any) {
    const { rightLoadState, rightPageType } = app;
    console.log('content', app);
    return { rightLoadState, rightPageType };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentFrame);
