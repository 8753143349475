// import Axios from 'axios';
// import Cookies from 'js-cookie';
//
// export function submitFeedback({ params }: any) {
//     console.log('REACT_APP_JOYSPACE_HOST =>', process.env.REACT_APP_JOYSPACE_HOST);
//     return Axios({
//         method: 'post',
//         url: process.env.REACT_APP_JOYSPACE_HOST + '/v1/feedback/webapp/submit',
//         responseType: 'json',
//         headers: {
//             'focus-token': Cookies.get('focus-token'),
//         },
//         data: params,
//     }).then((data) => {
//         return data;
//     });
// }

import { gatewayInstance } from '@/server/gateway';
import { changeGovUrl } from '@/utils/tools';

export async function submitFeedback({ params }: any): Promise<any> {
    if (params?.logs?.length) {
        params.logs = params.logs.map((item: any) => changeGovUrl(item));
    }
    if (params?.pictures?.length) {
        params.pictures = params.pictures.map((item: any) => changeGovUrl(item));
    }
    return gatewayInstance
        .request({
            api: 'focus.addFeedBacks',
            method: 'POST',
            body: params,
            headers: {
                platform: 'web', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
            },
        })
        .catch((e) => {
            console.log('submitFeedback =>', e);
            return {};
        });
}
