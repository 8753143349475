// http://ee-doc.jd.com/swagger.html?url=http%3A%2F%2Fee-doc.jd.com%2FgetDocumentDefinition%3FserviceId%3DminiEOpen.json#/开放授权服务接口文档/getCode

// copy from src/server/gateway.ts

import { AuthData } from '@/types/LocalConfig';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

export interface MiniEOpenProps {
    protocol: string;
    host: string;
}

export default class MiniEOpen {
    private client: AxiosInstance;
    private userData: AuthData;
    constructor(props: MiniEOpenProps, authInfo: AuthData, appRuntimeEnv: AppRuntimeEnv) {
        this.userData = authInfo;
        this.client = axios.create({
            // https://jzb-open.jdcloud.com/open-api/auth/v1/get_code?teamId=yXu197AmRxN7W8yguViaz&userId=AfMxkZmdwYPOJUP7eMZcB&appKey=IqJXwUdGRFQZG7x0dj17
            baseURL: `${appRuntimeEnv.envConfig.open?.protocol}://${appRuntimeEnv.envConfig.open?.host}`,
            // baseURL: `https://jzb-open.jdcloud.com`,
        });
    }

    request = (opts: AxiosRequestConfig): AxiosPromise => {
        opts.url = '/open-api/' + opts.url;
        opts.params = {
            ...opts.params,
            teamId: this.userData.teamUserInfo.teamId,
            userId: this.userData.userId,
        };
        opts.data = {
            ...opts.data,
        };
        return this.client.request(opts).then(function (response) {
            if (response.status === 200 && response.data?.code === 0) {
                return response.data;
            }
            return Promise.reject(response);
        });
    };
}

export let miniEOpenInstance: MiniEOpen;

export function setMiniEOpenInstance(
    props: MiniEOpenProps,
    authInfo: AuthData,
    appRuntimeEnv: AppRuntimeEnv
) {
    console.log('setGatewayInstance', props);
    miniEOpenInstance = new MiniEOpen(props, authInfo, appRuntimeEnv);
}
