// import HIGH_PRIORITY_IMG from '@/assets/img/taskType/A1_1.png';
// import MIDDLE_PRIORITY_IMG from '@/assets/img/taskType/A2_1.png';
// import NORMAL_PRIORITY_IMG from '@/assets/img/taskType/B1_1.png';
// import LOW_PRIORITY_IMG from '@/assets/img/taskType/B2_1.png';
import { PRIORITY_TYPE, PROCESS_STATUS, TASK_STATUS, USER_ROLE } from '@/types/newWork';

import PNG_HIGH_PRIORITY_IMG from '@/assets/img/priority/high.png';
import PNG_MIDDLE_PRIORITY_IMG from '@/assets/img/priority/middle.png';
import PNG_NORMAL_PRIORITY_IMG from '@/assets/img/priority/normal.png';
import PNG_LOW_PRIORITY_IMG from '@/assets/img/priority/low.png';

export interface IFilterItem {
    text?: string;
    value: TASK_STATUS | PROCESS_STATUS | PRIORITY_TYPE;
    mix?: boolean; // 文字和图片混和出现
    url?: string; // 可能是图片路径
    color?: string; // 显示的颜色
}
// 任务优先级对应图片
export const PRIORITY_TYPE_MAP = {
    [PRIORITY_TYPE.HIGH_PRIORITY]: {
        url: PNG_HIGH_PRIORITY_IMG,
        alt: '非常紧急',
    },
    [PRIORITY_TYPE.MIDDLE_PRIORITY]: {
        url: PNG_MIDDLE_PRIORITY_IMG,
        alt: '紧急',
    },
    [PRIORITY_TYPE.NORMAL_PRIORITY]: {
        url: PNG_NORMAL_PRIORITY_IMG,
        alt: '普通',
    },
    [PRIORITY_TYPE.LOW_PRIORITY]: {
        url: PNG_LOW_PRIORITY_IMG,
        alt: '低',
    },
};

// 推进任务映射
export const PROCESS_STATUS_MAP = {
    [PROCESS_STATUS.PUSHING]: '我推进中',
    [PROCESS_STATUS.FINISHED]: '我已完成',
    [PROCESS_STATUS.DIFFICULT]: '我有困难',
    [PROCESS_STATUS.NOT_VIEWED]: '我未查看',
};
// 协同人任务映射
export const PROCESS_STATUS_EXECUTORS = {
    [PROCESS_STATUS.PUSHING]: '推进中',
    [PROCESS_STATUS.FINISHED]: '已完成',
    [PROCESS_STATUS.DIFFICULT]: '有困难',
    [PROCESS_STATUS.NOT_VIEWED]: '未查看',
};
// 任务状态映射
export const TASK_STATUS_MAP = {
    [TASK_STATUS.UNDONE]: '进行中',
    [TASK_STATUS.FINISHED]: '已完成',
    [TASK_STATUS.ABORT]: '已中止',
};
// 任务状态列表 --- list filter
export const TASK_STATUS_LIST: IFilterItem[] = [
    { text: '进行中', value: TASK_STATUS.UNDONE },
    { text: '已完成', value: TASK_STATUS.FINISHED },
    { text: '已中止', value: TASK_STATUS.ABORT },
];
// 任务（操作）动作列表 --- list filter
export const TASK_DO_STATUS_LIST: IFilterItem[] = [
    { text: '进行中', value: TASK_STATUS.UNDONE },
    { text: '中止任务', value: TASK_STATUS.ABORT, color: 'black' },
    { text: '整体完成', value: TASK_STATUS.FINISHED },
];
// 角色列表
export const USER_ROLE_LIST = [
    { value: USER_ROLE.CREATED, text: '我创建的' },
    { value: USER_ROLE.RESPONSIBLE, text: '我负责的' },
    { value: USER_ROLE.COLLABORATIVE, text: '我协同的' },
];
// 任务推进状态列表 --- list filter
export const PROCESS_STATUS_LIST: IFilterItem[] = [
    { text: '推进中', value: PROCESS_STATUS.PUSHING },
    { text: '有困难', value: PROCESS_STATUS.DIFFICULT },
    { text: '已完成', value: PROCESS_STATUS.FINISHED },
    { text: '未查看', value: PROCESS_STATUS.NOT_VIEWED },
];

export const PRIORITY_TYPE_LIST: IFilterItem[] = [
    {
        url: PNG_HIGH_PRIORITY_IMG,
        value: PRIORITY_TYPE.HIGH_PRIORITY,
        text: '非常紧急',
    },
    {
        url: PNG_MIDDLE_PRIORITY_IMG,
        value: PRIORITY_TYPE.MIDDLE_PRIORITY,
        text: '紧急',
    },
    {
        url: PNG_NORMAL_PRIORITY_IMG,
        value: PRIORITY_TYPE.NORMAL_PRIORITY,
        text: '普通',
    },
    {
        url: PNG_LOW_PRIORITY_IMG,
        value: PRIORITY_TYPE.LOW_PRIORITY,
        text: '低',
    },
    {
        text: '无',
        mix: true,
        value: PRIORITY_TYPE.NO_PRIORITY,
    },
];
