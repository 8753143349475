import IconFont from '@/components/icon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.less';

export interface ISearchInputProps {
    sessionId: string;
    placeholder?: string;
    onChange?: (search: string) => void;
    clstag?: string;
    keyword?: string;
}
export default function SearchInput(props: ISearchInputProps) {
    const { placeholder, onChange, sessionId, keyword } = props;
    const [searchKey, setSearchKey] = useState(keyword || '');
    const inputRef = useRef<HTMLInputElement>(null);
    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const searchKey = (e.currentTarget.value || '').trim();
            setSearchKey(searchKey);
            onChange?.(searchKey);
        },
        [onChange, setSearchKey]
    );

    const clearSearchKey = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setSearchKey('');
            onChange?.('');
        }
    }, [inputRef, onChange]);

    // 这里写的bug，在新增过滤条件改变时，会清空searchKey
    // useEffect(() => {
    //     clearSearchKey();
    // }, [clearSearchKey, sessionId]);

    return (
        <div className="search-reacord-input-container">
            <IconFont type="iconjs_ic_search" className="search" />
            <input
                type="search"
                className="search-reacord-input-container-input"
                defaultValue={searchKey}
                placeholder={placeholder}
                ref={inputRef}
                onChange={handleInputChange}
            />
            {searchKey && (
                <IconFont
                    type="iconlog_del"
                    className="clear"
                    onClick={clearSearchKey}
                    // clstag={props.clstag}
                />
            )}
        </div>
    );
}
