import BenzAMRRecorder from 'benz-amr-recorder';
export const VoiceStatus = {
    PLAY: 'play', // 播放
    STOP: 'stop', // 停止
    RESUME: 'resume', // 继续
    PAUSE: 'pause', // 暂停
    ENDED: 'ended', // 结束
    AUTOENDED: 'autoEnded', // 自动结束
};

let voiceHandler: VoiceHandler | null;
class VoiceHandler {
    id = '';
    url = '';
    voiceAmr: BenzAMRRecorder | null = null;
    options: any;
    constructor(id: string, url: string, options?: any) {
        this.id = id;
        this.url = url;
        this.options = options;
        this.init();
    }

    init() {
        this.voiceAmr = new BenzAMRRecorder();
        this.voiceAmr.initWithUrl(this.url).then(() => {
            this.play();
        });
        this.voiceAmr?.onPlay(() => {
            this.options?.afterPlay?.();
        });
        this.voiceAmr?.onStop(() => {
            this.options?.afterStop?.();
        });
    }

    play() {
        this.options?.beforePlay?.();
        this.voiceAmr?.play();
        // this.options?.afterPlay?.();
    }
    stop() {
        this.options?.beforeStop?.();
        this.voiceAmr?.stop();
        // this.options?.afterStop?.();
    }
    destroy() {
        this.options?.beforeDestroy?.();
        this.stop();
        this.voiceAmr = null;
        voiceHandler = null;
        this.options?.afterDestroy?.();
    }
}

function getCurVoiceHandler(id?: string, url?: string, options?: any) {
    if (!id || !url) {
        return voiceHandler;
    }
    if (voiceHandler) {
        // console.log(1);
        if (voiceHandler.id !== id) {
            // console.log(2);
            voiceHandler.stop();
            voiceHandler = new VoiceHandler(id, url, options);
            return voiceHandler;
        }
        if (voiceHandler.voiceAmr?.isPlaying()) {
            // console.log('停止播放');
            voiceHandler.stop();
        } else {
            // console.log('开始播放');
            voiceHandler.play();
        }
        return voiceHandler;
    } else {
        // console.log(3);
        voiceHandler = new VoiceHandler(id, url, options);
        return voiceHandler;
    }
}

export default getCurVoiceHandler;
