/**
 * 应用于聊天窗口
 */
import { EventEmitter } from 'events';
import Employee from '@jd/jdee.im.sdk/lib/es/model/Employee';
import { ChatMessage, ChatMessageType } from '@/types/chat';

class ChatEvent extends EventEmitter {
    /**
     * 右侧展开时需要调用
     */
    emitRightSideChange() {
        this.emit('rightSide-change');
    }

    /**
     * 聊天窗口里选择回复人的时候
     * @param props
     */
    emitReply(props: { replyUser: Employee; replyText: string; replyMessage: ChatMessage }) {
        this.emit('chat-reply', props);
    }

    closeRightSide() {
        this.emit('close-rightside');
    }

    closeMention() {
        this.emit('close-mention');
    }
}

export default new ChatEvent();
