import React from 'react';
import { SearchGlobalContext } from '@/components/Modals/SearchGlobalModal/utils';
import DataList from '@/components/Modals/SearchGlobalModal/DataList';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { ListItemClickFn } from '@/components/Modals/SearchGlobalModal/Tabs';
import lodashTrim from 'lodash/trim';
import ListEmptyPlaceholder from '@jd/focus-desktop-comps/lib/es/ListEmptyPlaceholder';
import { analysisLog } from '@/utils/logAnalytics';

export default function TabPaneCalendar({ onClick }: { onClick?: ListItemClickFn }) {
    const { keyword, t } = React.useContext(SearchGlobalContext);
    if (!lodashTrim(keyword)) {
        return <ListEmptyPlaceholder placeholder={t('search.emptyPlSchedule')} />;
    }
    return (
        <DataList
            searchType={SearchType.Schedule}
            onClick={(data: any) => {
                analysisLog('xtbg_home_search_1641454991771', '31');
                if (onClick) {
                    onClick(SearchType.Schedule, data);
                }
            }}
        />
    );
}
