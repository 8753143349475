import React from 'react';

import Prompt from '@jd/focus-desktop-comps/lib/es/PromptModal';
export default Prompt;

// import { Modal } from 'antd';
// import IconFont from '@/components/icon';
// export interface PromptOptions {
//     title?: string; //
//     icon?: React.ReactNode;
//     content?: React.ReactNode;
//     okText?: string;
//     cancelText?: string;
//     onCancel?: () => void;
//     onOk?: () => void;
// }
//
// const Prompt = (props: PromptOptions) => {
//     return Modal.confirm({
//         width: '433px',
//         style: {
//             height: '192px',
//             padding: 0,
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             margin: 'auto',
//             zIndex: 999,
//         },
//         title: props.title,
//         icon: props.icon || <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
//         onOk: props.onOk,
//         onCancel: props.onCancel,
//         content: (
//             <p
//                 style={{
//                     fontSize: '14px',
//                     color: '#62656D',
//                     lineHeight: '22px',
//                 }}
//             >
//                 {props.content}
//             </p>
//         ),
//         okText: props.okText,
//         cancelText: props.cancelText,
//         okButtonProps: {
//             style: {
//                 border: 'none',
//                 background: '@primary-color',
//                 borderRadius: ' 4px',
//                 color: '#ffffff',
//                 fontSize: '14px',
//             },
//         },
//         cancelButtonProps: {
//             style: {
//                 border: ' 1px solid #dee0e3',
//                 borderRadius: '4px',
//                 fontSize: '14px',
//                 color: '#242931',
//             },
//         },
//     });
// };
//
// export default Prompt;
