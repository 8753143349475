/**
 * 判断两个user对象是不是同一个user
 */

import { EmployeeAttribute } from '@/types/chat';
type Employee = Pick<EmployeeAttribute, any>;
export const isSameEmployee = (employee1: any, employee2: any): boolean => {
    // console.log(employee1, employee2, 'employee1employee1employee1employee1');
    if (employee2.gid) {
        return (
            employee1.teamId === (employee2 as any).owner.teamId &&
            employee1.userId === (employee2 as any).owner.pin
        );
    }
    return employee1.teamId === employee2.teamId && employee1.userId === employee2.userId;
};
