import ImService from '@/server/ImService';
import Session from '@jd/jdee.im.sdk/lib/es/model/Session';
import { ListItemData } from '@/components/ListItem';
import { gatewayInstance } from '@/server/gateway';
import { convertEmployeeToString, employeeToUid } from '@/components/chat/utils/message';
import { Employee, GroupRoster, UID } from '@/types/chat';
import { getLabels } from '@/api/elabel';
import { getGroupInfo, getGroupsByLabel } from '@/api/egroup';
import {
    BusinessTabParam,
    RecommendParam,
    SearchGlobalParams,
} from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { getDeptPositionDesc, UserModel } from '@/models/user';
import { parseUidFromString } from '@jd/focus-desktop-comps/lib/es/utils';
import lodashTrim from 'lodash/trim';
import { getSelectorSessions, mapSessions } from '@/components/chat/utils/session';
import uniqBy from 'lodash/uniqBy';
import PinyinMatch from 'pinyin-match';
import { FocusSDK, getUserInfo, getUserAppId, unzipData, storageLocal } from '@/utils';
import lodashCompact from 'lodash/compact';
import { ApprExternalContacts, BindBeijingTongAccount } from '@/types/chat/State';
import { labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { changeEgovUrl } from '@/utils/tools';
import { AVSDK } from '@/baseComponents/Meeting/common';
import { getFirstLoginList } from './ebook';
import { mapEbookSimpleDetailList } from '@/components/AddressBook/common';

export interface SessionInfoFromFocusSDK {
    isSingle?: boolean;
    isNotice?: boolean;
    info?: {
        app: string;
        userId: string;
        teamId: string;
    };
    uid?: UID;
}

export function getListOriginDataFromSession(session: Session): SessionInfoFromFocusSDK {
    const { info, isSingle, isNotice } = session;
    // 单聊
    if (isSingle && !isNotice) {
        const { app, userId, teamId } = info as Employee;
        return {
            isSingle,
            isNotice,
            info: { app, userId, teamId },
            uid: { pin: userId, app: app, teamId: teamId },
        };
    }
    return {};
}

// 根据本部门>本单位>默认规则>其他+“京办小管家” 展示20个
export async function firstLoginList(currentEmployee: any) {
    const [data, err]: any = await getFirstLoginList();

    if (err) {
        const localFirstLoginList = storageLocal.get(
            `${convertEmployeeToString(currentEmployee)}-firstLoginList`
        );

        return localFirstLoginList || [];
    }
    if (!data.length) {
        return [];
    }

    const list = data.filter((item: any) => item);

    const mapFirstLoginList = mapEbookSimpleDetailList(
        list.filter(
            (item: any) =>
                item?.userId !== currentEmployee?.userId &&
                item.teamId === currentEmployee.teamId &&
                item.appId === currentEmployee.app
        )
    );
    // console.log('mapFirstLoginList==>', mapFirstLoginList);

    storageLocal.add(
        `${convertEmployeeToString(currentEmployee)}-firstLoginList`,
        mapFirstLoginList
    );

    return mapFirstLoginList;
}

// 常用联系人

export async function getRecentChatSession(teamId: string) {
    const imService = ImService.getInstance();
    const currentEmployee = await imService.getCurrentEmployee();
    const allSessionList = await imService.getAllSessionList({}, false, true);
    const result: ListItemData[] = [];
    for (let item of allSessionList) {
        if (item.isSingle && !item.isNotice && item.info?.name && !item.isSecret) {
            const info = item.info as Employee;
            result.push({
                // id: item.uid.pin,
                id: convertEmployeeToString(info),
                sessionId: item.sessionId,
                name: item.info?.name,
                app: item.info?.app,
                teamId: item.info?.teamId,
                pin: item.info?.userId,
                userId: item.info?.userId,
                avatar: changeEgovUrl(item.info.avatar),
                sessionType: item.sessionType,
                settings: item.settings,
                origin: getListOriginDataFromSession(item),
                desc: getDeptPositionDesc(
                    item?.info?.e_dept_full_name || item?.info?.deptName || '',
                    item?.info?.e_position || item?.info?.titleName || '',
                    item?.info?.userId,
                    currentEmployee
                ),
                labelInfoList: item.info?.labelInfoList || [],
            });
        }
    }
    // 只显示当前团队teamid和当前appid的联系人
    const clientSessionList = uniqBy(result, 'id');
    if (clientSessionList.length < 20) {
        const res = await firstLoginList(currentEmployee);
        // console.log(uniqBy([...clientSessionList, ...res], 'id').slice(0, 20), 'clientSessionList');
        return uniqBy([...clientSessionList, ...res], 'id').slice(0, 20);
    }
    return clientSessionList.slice(0, 20);
}

// 最近会话
export async function getRecentIncludeGroup() {
    const imService = ImService.getInstance();
    const allSessionList = await imService.getAllSessions({}, true);
    return getSelectorSessions(allSessionList);
}

export async function searchSessions(param: SearchGlobalParams): Promise<ListItemData[]> {
    const imService = ImService.getInstance();
    const allSessionList = await imService.getAllSessionFromStore();
    return mapSessions(allSessionList, (session) => {
        const info: any = session.info || {};
        return !!PinyinMatch.match(info.name || '', param.keyword);
    });
}
// 我的联系人
export async function getExternalContacts(): Promise<ListItemData[]> {
    const imService = ImService.getInstance();
    const externalContacts = await imService.getExternalContacts();
    const currentEmployee = await imService.getCurrentEmployee();
    const newExternalContacts =
        externalContacts?.map((item) => {
            return {
                id: convertEmployeeToString(item.employee as Employee),
                name: item.employee.name,
                avatar: changeEgovUrl(item.employee.avatar),
                desc: getDeptPositionDesc(
                    item?.employee?.e_dept_full_name || item?.employee?.deptName || '',
                    item?.employee?.e_position || item?.employee?.titleName || '',
                    item?.employee?.userId,
                    currentEmployee
                ),
                origin: {
                    info: { app: item.app, userId: item.userId, teamId: item.teamId },
                    uid: employeeToUid(item.employee as Employee),
                },
                labelInfoList: item.employee?.labelInfoList || [],
            };
        }) || [];
    return newExternalContacts.filter((item) => item.name);
}

export function getTopChatSession(teamId: string) {
    const imService = ImService.getInstance();
    return imService.getAllSessions({}, false).then(function (list: Session[]) {
        const result: ListItemData[] = [];
        for (let item of list) {
            if (item.isSingle && !item.isNotice && item.settings && (item.settings.top || 0) > 0) {
                const info = item.info as Employee;
                if (info.teamId === teamId) {
                    result.push({
                        // id: item.id,
                        id: convertEmployeeToString(info),
                        sessionId: item.sessionId,
                        name: item.info?.name,
                        avatar: changeEgovUrl(item.info.avatar),
                        sessionType: item.sessionType,
                        settings: item.settings,
                        origin: getListOriginDataFromSession(item),
                    });
                }
            }
        }
        return result;
    });
}

// 组成员
export async function getGroupMembersData(
    groupId: string | '',
    groupType?: string | ''
): Promise<ListItemData[]> {
    let result: any[] = [];
    try {
        result = await getUserGroupDetailList(groupId, groupType as any);
    } catch (e) {
        console.error('getGroupMembersData-err', e);
    }
    return result;
}

// 我的组
export async function getGroupData(): Promise<ListItemData[]> {
    // const labelsRes = await getLabels();
    // const { labels } = labelsRes;
    // if (!labels.length) {
    //     // throw new Error('invalid labelsRes, labels length 0');
    //     return [];
    // }
    // const label = labels[0];
    // const groupsRes = await getGroupsByLabel({ labelId: '1' });
    // const { groups } = groupsRes;
    // const groupIds = (groups || []).map((item: any) => {
    //     return { groupId: item.gid };
    // });
    // if (groupIds?.length === 0) return [];
    // console.log(groupIds, 'groupIdsgroupIdsgroupIds');
    // const groupInfoRes = await getGroupInfo({ groupIds });
    // if (!groupInfoRes.groups || groupInfoRes.groups.length === 0) {
    //     return [];
    // }
    const data = await ImService.getInstance().getGroupList();
    return (data || []).map((item: any) => {
        return {
            id: item.gid,
            name: item.name,
            avatar: changeEgovUrl(item.avatar || ''),
            kind: item.kind,
            subKind: item.subKind,
            isGroup: true,
            business: item.business,
            ext: item.ext,
            rosterSize: item.rosterSize,
            desc: `${item.rosterSize} 人`,
        };
    });
}

/**
 *
 * 获取日程详情简略信息
 * @returns
 * @param opts
 */
export async function getTranslate(opts: {
    fromLang: 'cn' | 'en';
    toLang: 'en' | 'cn';
    strText: string;
}) {
    return gatewayInstance.request({
        api: 'nlp.translateText',
        method: 'POST',
        body: opts,
    });
}

export function getNoticeBot(avsdk: AVSDK) {
    if (avsdk === AVSDK.JBRTC) {
        return 'iZ1ZpJzbzAdprQiPoImD';
    } else if (avsdk === AVSDK.HUAWEI) {
        return '4iJ0jfgCLTXdz6YED06W';
    } else if (avsdk === AVSDK.XYLINK) {
        return 'vbNWhC0nRvvN1q3nM5Zy';
    }
    return 'jif5OZ0gf6qNotBVAraI';
}

export async function sendNoticeCardMessage(
    opts: any,
    applicationKey = 'jif5OZ0gf6qNotBVAraI'
): Promise<[any, string | null]> {
    const appKey = 'b96a8ab3197544e68c9919667afe9f41';

    try {
        const data = await gatewayInstance.request({
            api: 'eopen.push.pushWithParams',
            method: 'POST',
            body: {
                appKey,
                applicationKey,
                ...opts,
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getGroupMemberDetail(gid: string, uid: UID): Promise<any> {
    return gatewayInstance.request({
        api: 'egroup.getGroupMemberDetail',
        method: 'POST',
        body: {
            groupId: gid,
            member: { ...uid, clientType: 'web' },
        },
    });
}

export async function expireQRCode(qrCode: string): Promise<any> {
    return gatewayInstance.request({
        api: 'qrcode.expireQrCode',
        method: 'POST',
        body: { qrCode },
    });
}

function buildDeptTitle(deptName: string, titleName: string): string {
    const names = (deptName || '').split('-');
    const tempDeptName = names[names.length - 1];
    if (tempDeptName && titleName) {
        return `${tempDeptName}-${titleName}`;
    } else if (tempDeptName) {
        return tempDeptName;
    } else if (titleName) {
        return titleName;
    } else {
        return '';
    }
}

export function mapBusinessData(
    dataList: any,
    currentUser: { app: string; userId: string; teamId: string }
) {
    return (dataList || []).map(
        (item: any): ListItemData => {
            const { teamId, shortName, deptName, titleName } = item || {};
            let desc = buildDeptTitle(deptName, titleName);
            if (teamId !== currentUser.teamId) {
                desc = shortName; // 租户名称
            }
            item.desc = desc;
            const id = `${item?.userId}:${item?.app}:${item?.teamId}`;
            return {
                id,
                userId: item?.userId,
                name: item?.realName,
                app: item?.app,
                teamId: item?.teamId,
                avatar: changeEgovUrl(item?.headImg),
                desc: desc,
                origin: {
                    info: item,
                    uid: parseUidFromString(id),
                },
                checked: false,
                labelInfoList: item?.labelInfoList || [],
            };
        }
    );
}

export enum GroupRosterIdentity {
    'ordinary',
    'sub_administrator',
    'administrator',
}
export interface info {
    app: number;
    avatar: string;
    name: string;
    teamId: string | number;
    userId: string | number;
}
export interface user {
    alias: any;
    app: string | number;
    art: any;
    clientType: any;
    nickName: string;
    pin: string | number;
    realName: string;
    teamId: string | number;
    desc: any;
    labelInfoList: labelInfoList[];
    unitName: string;
    titleName: string;
}
export interface GroupMembers {
    identity: any;
    gid: string;
    info: info;
    user: user;
}
export function mapGroupMembersData(dataList: any, groupId: string) {
    return (dataList || []).map(
        (item: any): GroupMembers => {
            let fields: { [propName: string]: any } = {};
            (item.fields || []).forEach((fieldItem: any) => {
                fields[fieldItem.key] = fieldItem.val;
            });
            return {
                identity: GroupRosterIdentity[item.identity],
                gid: groupId || '',
                info: {
                    app: item.appId,
                    avatar: changeEgovUrl(fields['avatar'] || ''),
                    name: fields['name'] || '',
                    teamId: item.teamId,
                    userId: item?.pin || item?.userId,
                },
                user: {
                    alias: item.alias,
                    app: item.appId,
                    art: item.art,
                    clientType: item.clientType,
                    nickName: fields['name'] || '',
                    pin: item?.pin || item?.userId,
                    realName: item?.realName || '',
                    teamId: item.teamId,
                    desc: getDeptPositionDesc(
                        fields['e_dept_full_name'] || '',
                        fields['e_position'] || ''
                    ),
                    unitName: fields['unitName'] || '',
                    titleName: fields['e_position'] || '',
                    labelInfoList: item?.labelInfoList || [],
                },
            };
        }
    );
}

// app: "me"
// avatar: "https://eefs-pre.jdcloud.com/res/download/QiXaIjU2HwYSw8GfDWFW6PdZy.jpg?appKey=9e92d795a36edea0"
// deptId: "0"
// deptName: "群聊互通"
// email: null
// employeeId: "zYCcDONUU2WfqPSUB8fUB"
// mobile: "13717950126"
// realName: "孙志广"
// tags: "abc"
// teamId: "wFNptZXmu7PGvRjsclqRg"
// teamName: "群聊互通"
// titleId: null
// titleName: null
// userCode: null
// userId: "RSnrKFTsQidPgzT27dYBc"
// 智能推荐子标题显示规则：如果是外部人员，显示团队名称；如果内部人员显示最后一级部门名称+岗位；
export function mapRecommendData(
    dataList: any,
    currentUser: { app: string; userId: string; teamId: string }
): ListItemData[] {
    return (dataList || []).map(
        (item: any): ListItemData => {
            const { deptName, teamName, titleName } = item || {};
            const desc =
                currentUser.teamId !== item.teamId
                    ? teamName
                    : lodashTrim(`${deptName || ''} ${titleName || ''}`);
            const id = item ? `${item.userId}:${item.app}:${item.teamId}` : '';
            return {
                id,
                name: item?.realName,
                avatar: changeEgovUrl(item?.avatar),
                desc: desc,
                origin: {
                    info: item,
                    uid: parseUidFromString(id),
                },
                checked: false,
                labelInfoList: item?.labelInfoList || [],
            };
        }
    );
}

// 人员选择器业务数据接口
export async function getBusinessData(
    param: BusinessTabParam,
    user: UserModel | { teamUserInfo: { teamId: any }; ddAppId: string; userId: string }
): Promise<ListItemData[]> {
    const data: any = await gatewayInstance.request({
        api: 'focus.findBusinessUsers',
        method: 'POST',
        body: { businessId: param.businessId, businessType: param.businessType.toUpperCase() },
    });
    const userBaseList = data.userBaseList;
    const currentUser = {
        teamId: user.teamUserInfo.teamId,
        app: user.ddAppId,
        userId: user.userId,
    };
    return mapBusinessData(userBaseList || [], currentUser);
}
export async function getBusinessData1(
    param: BusinessTabParam,
    user: any
): Promise<ListItemData[]> {
    const data: any = await gatewayInstance.request({
        api: 'focus.findBusinessUsers',
        method: 'POST',
        body: { businessId: param.businessId, businessType: param.businessType.toUpperCase() },
    });
    const userBaseList = data.userBaseList;
    const currentUser = {
        teamId: user?.teamId,
        app: user?.app,
        userId: user?.pin,
    };
    return mapBusinessData(lodashCompact(userBaseList) || [], currentUser);
}

export async function getGroupMembers(groupId: string, groupType: string): Promise<GroupRoster[]> {
    try {
        const data: any = await gatewayInstance
            .request({
                api: 'ebook.gov.getUserGroupDetailList',
                method: 'POST',
                body: { groupId, groupType },
            })
            .catch((err) => {
                // console.error('getGroupMembers-err', err);
                return [];
            });
        return mapGroupMembersData(lodashCompact(data) || [], groupId);
    } catch (error) {
        return [];
    }
}

export async function getUserGroupDetailList(
    groupId: string,
    groupType: string
): Promise<GroupRoster[]> {
    try {
        const data: any = await gatewayInstance
            .request({
                api: 'ebook.gov.getUserGroupDetailListV2',
                method: 'POST',
                body: { groupId, groupType },
            })
            .catch((err) => {
                console.error('getUserGroupDetailList-err', err);
                return [];
            });
        if (data.length) {
            try {
                const res = JSON.parse(unzipData(data));
                const list = mapGroupMembersData(lodashCompact(res) || [], groupId);
                await ImService.getInstance().saveBasicGroupToDB(groupId, list);
                return list;
            } catch (e) {
                console.log(`getUserGroupDetailList--unzipData error`, e);
                return [];
            }
        } else {
            const groupList: any = await ImService.getInstance().getDbGroup({});
            return groupList.filter((item: any) => item.gid === groupId)[0]?.children;
        }
    } catch (error) {
        console.log(`getUserGroupDetailList--error-`, error);
        return [];
    }
}

export async function getGroupMemberList(groupId: string, groupType: string): Promise<any> {
    try {
        const data: any = await getUserGroupDetailList(groupId, groupType);
        return { data, groupId };
    } catch (error) {
        return [];
    }
}

export async function onGetRecommendInfo(
    param: RecommendParam,
    user: UserModel | { teamUserInfo: { teamId: any }; ddAppId: string; userId: string }
): Promise<ListItemData[]> {
    param.appId = param.appId || user.ddAppId;
    const data: any = await gatewayInstance.request({
        api: 'ebook.gov.getAiRecommendation',
        method: 'POST',
        body: param,
    });
    const { ebookUserDetailRes } = data;
    const currentUser = {
        teamId: user.teamUserInfo.teamId,
        app: user.ddAppId,
        userId: user.userId,
    };
    return mapRecommendData(ebookUserDetailRes || [], currentUser);
}

// 模糊搜索群成员
export async function onSearchGroupMember(
    keyword: string,
    data: ListItemData[] | undefined
): Promise<ListItemData[] | []> {
    try {
        const result = data?.filter((item: ListItemData) => {
            return PinyinMatch.match(item.name || '', keyword);
        });
        // console.log('模糊搜索', result);
        return result || [];
    } catch (e) {
        return [];
    }
}

/**
 * 获取常用语
 */
export async function getQuickReplys(opts?: { version: number }): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'presence.getQuickReplys',
            method: 'POST',
            body: opts,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 添加常用语
 */
export async function addQuickReplys(opts?: { quickReplyInfo: { detail: string } }): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'presence.addQuickReply',
            method: 'POST',
            body: opts,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 更新常用语
 */
export async function updateQuickReplyContent(opts: {
    quickReplyInfo: { detail: string; quickReplyID: string };
}): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'presence.updateQuickReplyContent',
            method: 'POST',
            body: opts,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 删除常用语
 */
export async function deleteQuickReply(opts: {
    quickReplyInfo: { quickReplyID: string };
}): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'presence.deleteQuickReply',
            method: 'POST',
            body: opts,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 排序常用语
 */
export async function updateQuickReplySequence(opts: {
    sequences: { [key: string]: string };
}): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'presence.updateQuickReplySequence',
            method: 'POST',
            body: opts,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getProjectDetail(param: any): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.project.findProjectDetail',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getRelationship(param: {
    pin: string;
    app: string;
    teamId: string;
}): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'efriend.getRelationship',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function setModifyFriend(param: {
    pin: string;
    app: string;
    teamId: string;
    accessPhone: string;
    nickName: string;
}): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'efriend.modifyFriend',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getJoyspaceList(
    groupId: string,
    page: number,
    pageSize: number
): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'joyspace.shares.pages',
            dynamicPath: `v1/shares/${groupId}/pages?length=${pageSize}&start=${page}`,
            method: 'GET',
        });
        return [data, null];
    } catch (e) {
        return [null, e.message.toString()];
    }
}

// 外部联系人和邀请正是用户审核接口
export async function updateApprExternalContacts(param: ApprExternalContacts): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.apprExternalContacts',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (e) {
        return [null, e.message.toString()];
    }
}

// 北京通号去完善
export async function setBindBeijingTongAccount(param: BindBeijingTongAccount): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.bindBeijingTongAccount',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (e) {
        return [null, e.message.toString()];
    }
}

// 接收人添加下载记录
export async function addFileDownloadRecord(param: {
    fileId: string;
    fileName: string;
    groupId: string;
    mid: number;
    senderUserId: string;
    senderTeamId: string;
}): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'egroup.addFileDownloadRecord',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (e) {
        return [null, e.message.toString()];
    }
}

// 发送人分页获取下载记录
export async function getFileDownloadRecordByPage(param: {
    fileId: string;
    pageNum: number;
    pageSize: number;
}): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'egroup.getFileDownloadRecordByPage',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (e) {
        return [null, e.message.toString()];
    }
}

interface AddCardReadReq {
    applicationKey: string;
    bizId: string;
    cardBusinessId: string;
    mid: string;
    sendTime: number;
}

function mapRequestData(message: any) {
    return {
        applicationKey: message?.from?.pin,
        bizId: message?.extend?.bizId,
        cardBusinessId: message?.cardBusinessId,
        sendTime: message?.timestamp,
        mid: message?.mid.toString(),
    };
}

/**
 * 开放平台消息卡片已读统计（精确统计，也就是点击详情）
 */

export async function addClickCardRead(message: any): Promise<any> {
    const requestData: AddCardReadReq = mapRequestData(message);
    try {
        const data: any = await gatewayInstance.request({
            api: 'eopen.addClickCardRead',
            method: 'POST',
            body: requestData,
        });
        if (data.errorCode === '0') {
            console.log('成功');
        } else {
            console.log('失败');
        }
        // return [data, null];
    } catch (e) {
        console.log('已读卡片统计 click', e);
        // return [null, (e as any).message.toString()];
    }
}

/**
 * 开放平台消息卡片已读统计（模糊统计）
 * 1、当前会话不是AI助手，点击AI助手，则根据已读逻辑去判断
 * 2、当前会话是AI助手，接收到了开放平台推送过来的消息，这里有疑问, 因为按照已读逻辑是已读了，则就调接口，但是不在全部页签下 ???
 */

export async function addBatchViewCardRead(messages: any): Promise<any> {
    const requestDataList = messages?.map((message: any) => mapRequestData(message));
    try {
        const data: any = await gatewayInstance.request({
            api: 'eopen.addBatchViewCardRead',
            method: 'POST',
            body: { reqList: requestDataList },
        });
        if (data.errorCode === '0') {
            console.log('成功');
        } else {
            console.log('失败');
        }
        // return [data, null];
    } catch (e) {
        console.log('已读卡片统计 dongRead', e);
        // return [null, (e as any).message.toString()];
    }
}

// 扫码入群被拒绝后再次申请
export async function applyAgain(params: { groupId: string; withParamsMsgReq: any }): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'egroup.userApplyToGroup',
            method: 'POST',
            body: params,
        });
        return [data, null];
    } catch (e) {
        console.log('扫码入群申请失败', e);
        return [null, (e as any)?.message.toString()];
    }
}

// 根据微信公众号链接获取标题
export async function getTitleByWXLink(url: string) {
    try {
        const data = await gatewayInstance.request({
            api: 'eopen.messageCardUrlConvert',
            method: 'POST',
            body: { messageCardUrl: url },
        });
        return [data, null];
    } catch (e) {
        console.log('获取链接失败', e);
        return [null, (e as any)?.message.toString()];
    }
}
