import React from 'react';
import './index.less';
import { Spin } from 'antd';
import { changeEgovUrl } from '@/utils/tools';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined className="primary-color" style={{ fontSize: 24 }} spin />;
export default function Empty({
    desc,
    desc1,
    loading,
    icon,
}: {
    desc?: string;
    desc1?: string;
    loading?: boolean;
    icon: string;
}) {
    return (
        <div
            style={{
                textAlign: 'center',
                width: '100%',
                marginTop: 170,
            }}
        >
            {!loading ? (
                <>
                    <img
                        style={{
                            width: 140,
                            height: 140,
                        }}
                        alt=""
                        src={changeEgovUrl(icon)}
                    />
                    <p
                        style={{
                            color: '#8F959E',
                            marginTop: 20,
                            marginBottom: 0,
                        }}
                    >
                        {desc}
                    </p>
                    <p className="primary-color">{desc1}</p>
                </>
            ) : (
                <Spin className="primary-color" indicator={antIcon} />
            )}
        </div>
    );
}
//
