import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import {
    AppStoreApplication,
    FavoriteType,
    RecommendedType,
    StatusLabels,
    StatusLabelsStyles,
    StatusLabelsText,
} from '@/services/AppStore';
// import Rete from '@/components/ThirdApp/components/Rate';
import Bus from '@/utils/bus';
import { IconThirdApp } from '@/components/icon';
import { thirdApp } from '@/assets/img/index';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

export default function ToolsItem({
    toolsItem,
    isDragging,
    loading,
    currentApplicationId,
}: {
    toolsItem: AppStoreApplication;
    isDragging?: boolean;
    loading: boolean;
    currentApplicationId?: string;
}) {
    const [_loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (toolsItem.applicationId === currentApplicationId) {
            setLoading(loading);
        }
    }, [loading, toolsItem, currentApplicationId]);

    // 添加常用
    const AddFavorite = ({ favoriteType, Loading }: { favoriteType: number; Loading: boolean }) => {
        return (
            <Button
                loading={_loading}
                disabled={_loading}
                className="tools-favorite-btn"
                style={{
                    color: favoriteType === FavoriteType.FavoriteNo ? '#323233' : '#999999',
                    cursor: _loading ? 'not-allowed' : 'pointer',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (favoriteType === FavoriteType.FavoriteYes) {
                        Bus.emit('removeFavoriteThirdApp', toolsItem);
                    } else {
                        Bus.emit('onAddFavoriteThirdApp', toolsItem);
                    }
                }}
            >
                {favoriteType === FavoriteType.FavoriteNo ? '添加常用' : '取消常用'}
            </Button>
        );
    };
    return (
        <div className={isDragging ? 'right-tools-tiem right-tools-tiem_bg' : 'right-tools-tiem'}>
            <div style={{ display: 'flex', flex: 1 }}>
                {/* 工具图标 */}
                <div className="right-tools-tiem_logo">
                    {toolsItem.icon ? (
                        <img src={changeEgovUrl(toolsItem.icon)} alt="" />
                    ) : (
                        <span style={{ fontSize: 22 }}>{IconThirdApp}</span>
                    )}
                </div>
                {/* 工具名称及简述 */}
                <div className="right-tools-tiem_info">
                    <div className="right-tools-tiem_title">
                        <span className="main-title">
                            <span className="right-tools-tiem_text">{toolsItem.name}</span>
                            <div className="main-title-right">
                                {toolsItem.recommended === RecommendedType.RecommendedYes && (
                                    <span
                                        className="right-tools-tiem_lable"
                                        style={StatusLabelsStyles.default}
                                    >
                                        推荐
                                    </span>
                                )}
                                {(toolsItem?.applicationStatusLabels || []).map(
                                    (key: any, index: number) => {
                                        return (
                                            <span
                                                className="right-tools-tiem_lable"
                                                key={index}
                                                style={StatusLabelsStyles[key]}
                                            >
                                                {StatusLabelsText[key]}
                                            </span>
                                        );
                                    }
                                )}
                            </div>
                        </span>
                        <span className="sub-title">{toolsItem.builder || ''}</span>
                        {/* <span style={{ margin: '2px 0 8px -3px' }}>
                            {(toolsItem?.networkEnvLabels || []).map((key: any, index: number) => {
                                if (StatusLabelsText[key] === StatusLabelsText[StatusLabels.VPN]) {
                                    return '';
                                }
                                return (
                                    <span
                                        className="right-tools-tiem_lable right-tools-tiem_lable__boder"
                                        key={index}
                                        style={StatusLabelsStyles[key]}
                                    >
                                        {StatusLabelsText[key]}
                                    </span>
                                );
                            })}
                        </span> */}
                        <p className="right-tools-tiem_brief">{toolsItem.brief}</p>
                        {/* {toolsItem.builder ? (
                            <p className="right-tools-tiem_developer">
                                建设方：{toolsItem.builder}
                            </p>
                        ) : (
                            <span />
                        )}
                        <p className="right-tools-tiem_developer">开发者：{toolsItem.developer}</p> */}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* {toolsItem.recommended === RecommendedType.RecommendedNo && ( */}
                <AddFavorite favoriteType={toolsItem.favorite} Loading={loading} />
                {/* )} */}
            </div>
        </div>
    );
}
