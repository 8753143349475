import React, { useEffect, useState, useRef, useContext } from 'react';
import './index.less';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FocusSDK } from '@/utils';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import AudioMp3 from '@/assets/toy_mono.mp3';
import GlobalContext from '@/context/GlobalContext';
import { replayCloudLinkMessage as replayMessage } from '../utils';
import { Avatar } from '@/baseComponents/Avatar';
import { cloudlinkRegister } from '@/api/meeting';
import { changeEgovUrl } from '@/utils/tools';
import {
    changeCloudLinkStatus,
    CloudLinkSessionStateEnum,
    CloudLinkStatusEnum,
} from '../Connector';
import bus from '@/utils/bus';

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export default function MeetingReceView() {
    const [message, setMessage] = React.useState<any>(null);
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo = {} as any } = useContext(GlobalContext);
    const { userId } = authInfo;

    const audioRef = useRef<any>(null);
    const timeRef = useRef<any>(null);
    const [t] = useTranslation('common');

    useEffect(() => {
        function setData({ data }: any) {
            changeCloudLinkStatus(CloudLinkStatusEnum.BUSY);
            setMessage(data);
        }
        FocusSDK.on('cloudlink:message', setData);
        FocusSDK.sendIpcMessage('cloudlink.initdata');
        return () => {
            FocusSDK.off('cloudlink:message', setData);
        };
    }, []);

    function closeModal() {
        FocusSDK.sendIpcMessage('cloudlink.rece.close');
    }
    function stopAudio() {
        const au = audioRef.current;
        if (au) {
            au.muted = true;
        }
    }
    useEffect(() => {
        function handleChatMeetingMessage(data: any) {
            const { sessionState } = data.ext;
            if (sessionState === CloudLinkSessionStateEnum.Cancel) {
                stopAudio();
                closeModal();
            }
        }
        bus.on('cloudlink:receive-chat-message', handleChatMeetingMessage);
    }, []);

    useEffect(() => {
        // 处理超过一分钟未响应的情况，主要是群会议
        function handleTimePass() {
            const timer = timeRef.current;
            if (timer) {
                clearTimeout(timer);
            }
            timeRef.current = setTimeout(() => {
                replayMessage(CloudLinkSessionStateEnum.TIMEOUT, message, closeModal);
            }, 1000 * 60);
        }
        if (!message) {
            return;
        }
        handleTimePass();
    }, [message]);

    if (!message) {
        return null;
    }

    function handleReject() {
        stopAudio();
        replayMessage(CloudLinkSessionStateEnum.UserReject, message, closeModal);
    }

    const { fromUser, meetingTopic, isSingle, meetingNumber, meetingPassword } = message.ext;
    const { avatar, name, titleName, superiorDeptName, deptName } = fromUser;

    async function handleReceVideo() {
        if (connect) {
            return;
        }
        setConnect(true);
        // 创建会议
        const [loginInfo, err] = await cloudlinkRegister({
            userIds: [userId],
        });
        if (err) {
            console.error(err);
            message.error(err);
            setConnect(false);
            closeModal();
            return;
        }
        stopAudio();
        bus.emit('cloudlink:init');
        setTimeout(() => {
            FocusSDK.sendIpcMessage('cloudlink.joinMeeting', {
                loginInfo: loginInfo[0],
                meetingInfo: {
                    isvideooff: false,
                    isaudiooff: false,
                    meetingNumber,
                    password: meetingPassword,
                },
            });
            replayMessage(CloudLinkSessionStateEnum.UserReceive, message, closeModal);
        }, 1500);
    }
    async function handleReceAudio() {
        if (connect) {
            return;
        }
        setConnect(true);
        const [loginInfo, err] = await cloudlinkRegister({
            userIds: [userId],
        });
        if (err) {
            console.error(err);
            message.error(err);
            setConnect(false);
            closeModal();
            return;
        }
        stopAudio();
        setTimeout(() => {
            FocusSDK.sendIpcMessage('cloudlink.joinMeeting', {
                loginInfo: loginInfo[0],
                meetingInfo: {
                    isvideooff: true,
                    isaudiooff: false,
                    meetingNumber,
                    password: meetingPassword,
                },
            });
            replayMessage(CloudLinkSessionStateEnum.UserReceive, message, closeModal);
        }, 1500);
    }

    return (
        <div className="receive-meeting-browser">
            <div className="meeting-header">
                <div className="meeting-header-container">
                    {isSingle && (
                        <div className="avatar">
                            <Avatar
                                className="meeting-header-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name}
                                styleObj={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    textRlign: 'center',
                                    lineHeight: 50,
                                    color: 'white',
                                    marginRight: 7,
                                }}
                            />
                        </div>
                    )}
                    <div className="meeting-header-content">
                        <div className="title">
                            {isSingle ? (
                                <>
                                    <span className="title-name">{name}</span>
                                    {titleName && (
                                        <span className="title-position">{titleName}</span>
                                    )}
                                </>
                            ) : (
                                <span className="title-name">{meetingTopic}</span>
                            )}
                        </div>
                        <div className="dept-info">
                            {!isSingle && (
                                <span className="dept-prefix">
                                    <span className="prefix-title">{name}</span>
                                    <span className="prefix-position">{titleName}</span>
                                </span>
                            )}
                            <span>
                                {`${superiorDeptName ? superiorDeptName + '-' : ''}${
                                    deptName || ''
                                }`}
                            </span>
                        </div>
                        <div className="desc">
                            {t('joinMeeting.Invite you to an online meeting')}
                        </div>
                    </div>
                </div>
            </div>
            {connect ? (
                <div className="meeting-connect-loading">
                    <Spin indicator={antIcon} />
                </div>
            ) : (
                <div className="meeting-action">
                    <div className="meeting-action-reject">
                        <div className="action-icon" onClick={handleReject}>
                            <IconFont type="iconapp_btn_joymerting_hangup" />
                        </div>
                        <div className="label">{t('joinMeeting.reject')}</div>
                    </div>
                    <div className="meeting-action-video">
                        <div className="action-icon" onClick={handleReceVideo}>
                            <IconFont type="iconapp_btn_joymerting_video" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-video')}</div>
                    </div>
                    <div className="meeting-action-audio">
                        <div className="action-icon" onClick={handleReceAudio}>
                            <IconFont type="iconapp_btn_joymerting_answer" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-audio')}</div>
                    </div>
                </div>
            )}
            <audio src={changeEgovUrl(AudioMp3)} loop autoPlay ref={audioRef} />
        </div>
    );
}
