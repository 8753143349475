import React, { useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './migrateTip.module.less';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import IconFont from '@/components/icon';

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { dbVersion } = chat;
    return { dbVersion };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setMigrateVisiable(data: boolean) {
            dispatch({ type: 'chat/setMigrateVisiable', payload: { migrateVisiable: data } });
        },
    };
}

interface MigrateTipProps {
    setMigrateVisiable: Function;
}

type IProps = Readonly<MigrateTipProps & ChatState & WithTranslation>;

function MigrateTip(props: IProps) {
    const { dbVersion, setMigrateVisiable } = props;

    const visable = useMemo(() => {
        const version = (dbVersion || '').split('|');
        if (version.length) {
            return version[0] === '-1';
        }
        return false;
    }, [dbVersion]);
    return visable ? (
        <div className={styles['migrate-tip-wrap']}>
            <p className={styles['migrate-tip-content']}>
                <span className={styles['text']}>您尚未迁移历史消息，请点击</span>
                <span
                    className={`${styles['text']} ${styles['btn']}`}
                    onClick={(e) => {
                        setMigrateVisiable(true);
                    }}
                >
                    立即迁移
                </span>
            </p>
        </div>
    ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(MigrateTip));
