import React, { useEffect, useState, useCallback } from 'react';
import { Tabs, Tooltip, Skeleton, message } from 'antd';
import Member from '../../components/Member';
// import Discuss from '../../components/Discuss';
import Chat from '../../components/Chat';
import Icon from '../../Icon';
import openProjectInfo from '../../components/ProjectInfo';
import { useTabs } from '../../components/Tabs';
import { Project, ProjectRole, IMember, IprojectListType } from '../../types';
// import openAnnouncement from '../../components/Announcement';
import SelectAvatar from '../../components/Avatar/SelectAvatar';
import Status from './Status';
import MoreMenu from './MoreMenu';
import * as api from '../../api';
import Title from './Title';
import TeamWorkView from '@/components/Work/TeamWorkView';
import membersSelector from '../../components/Member/selector';
import Context from '../../context';
import bus from '@/utils/bus';
import { TaskPartEnum } from '@/types/work';
import DataPanel from '../../components/DataPanel';
import Documentation from '../../components/Documentation';
import AnnounceTab from '../../components/AnnounceTab';
import classNames from 'classnames';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
import CalendarView from '../../components/CalendarView';
import GroupTeamList from '../../components/Chat/GroupTeam';
import ProjectSetting from '@/components/Projects/components/ProjectList/projectSetting';
import { isBjme } from '@/utils';
const { FocusSDK } = window as any;

export default function (props: {
    projectId: string;
    selectedProject?: IprojectListType;
    projectUpdate?: () => void;
}) {
    // projectId
    // selectedProject 为兼容设置弹窗所需参数传入
    // projectUpdate 设置后 更新操作
    const { projectId, selectedProject, projectUpdate } = props;
    const { events, t } = React.useContext(Context);
    const [project, setProject] = useState<Project>();
    const [isShowGroupChat, setIsShowGroupChat] = useState(false);
    const [announcement, setAnnouncement] = useState(t('detail.announce.nodata'));
    const [projectIcon, setProjectIcon] = useState(project?.icon || '');
    const [title, setTitle] = useState<string>(project?.title || '');
    const [description, setDescription] = useState<string>(project?.description || '');
    const tabs = useTabs();
    const [isUpdateProject, setIsUpdateProject] = useState(false);
    const [tabKey, setTabKey] = useState('0');
    const [isOpenSettingModal, setIsOpenSettingModal] = useState(false);
    const [settingProject, setSettingProject] = useState<IprojectListType>();
    useEffect(() => {
        if (project) {
            let tmp: IprojectListType = {
                projectId: projectId || '',
                name: project.title || '',
                icon: project.icon,
                iconColor: project.iconColor,
                id: projectId,
                type: project.type,
                progressName: project.progressName || '',
                progressPercent: 0,
                progress: project.progress,
                description: project.description,
                progressDesc: project.progressDesc,
                user: project.user,
                calendarId: project.calendarId,
                joyspaceId: project.joyspaceId || '',
                // @ts-ignore
                userInfo: project.userInfo,
                archive: project.archive,
            };
            setSettingProject(tmp);
        }
    }, [project, projectId]);

    useEffect(() => {
        api.getProjectDetails(projectId, true).then((data) => {
            if (data.errCode) {
                if (data.errCode === 1104919) {
                    // 解散
                    let e = isBjme() ? '专班已解散' : '项目已解散';
                    message.error(e);
                    tabs.close(projectId);
                } else if (data.errCode === 1104904) {
                    // 非专班成员
                    tabs.close(projectId);
                } else {
                    message.error('接口出错了！');
                }
                return;
            }
            setProjectIcon(data.icon);
            setAnnouncement(data?.announcementLatest?.content || t('detail.announce.nodata'));

            setTitle(data?.name);
            setDescription(data?.description);
            const _data = toProjectData(data);
            setProject(_data);
            tabs.setProps({
                id: projectId,
                title: data?.name,
                newIcon: data.icon,
            });
            if (data.announcementAlert) {
                bus.emit('announce:openModal', {
                    infox: {
                        announcementId: data.announcementAlert.announcementId,
                        announcementTypeId: projectId,
                        pic: data.announcementAlert.attachmentUrl || [],
                    },
                    content: data.announcementAlert.content,
                });
            }
        });
        // eslint-disable-next-line
    }, [projectId, isUpdateProject, bus]);

    useEffect(() => {
        function messageHandler(data: any) {
            if (data.projectId === projectId) {
                setIsUpdateProject((oldvalue) => !oldvalue);
            }
        }
        function setPropshandler(data: any) {
            if (data?.id !== projectId) return;
            if (data?.title) {
                setTitle(data.title);
            }
            if (data?.description) {
                setDescription(data.description);
            }
            if (data?.newIcon) {
                setProjectIcon(data.newIcon);
            }
            if (data?.progress) {
                const newProject: any = {
                    ...project,
                    progress: data.progress,
                    progressDesc: data.progressDesc,
                };
                setProject(newProject);
            }
        }

        events.on('projectReplaceOwner', messageHandler);
        events.on('tabs.setProps', setPropshandler);
        return () => {
            events.off('projectReplaceOwner', messageHandler);
            events.off('tabs.setProps', setPropshandler);
        };
    }, [events, projectId, project]);

    useEffect(() => {
        function multiSync(data: any) {
            if (!data?.data) return;
            if (
                data.data.projectId === projectId &&
                [TaskPartEnum.PROJECT_LIST, TaskPartEnum.PROJECT_USER].includes(data.data.part)
            ) {
                setIsUpdateProject((oldvalue) => !oldvalue);
            }
            if (data.data.projectId === projectId && data.data.part === TaskPartEnum.ANNOUNCEMENT) {
                api.getAnnounceListService(projectId).then((res) => {
                    if (!res) return;
                    setAnnouncement(
                        res.announcementLists[0]?.content || t('detail.announce.nodata')
                    );
                });
            }
        }
        function messageHandler(data: any) {
            if (data.projectId === projectId) {
                setIsUpdateProject((oldvalue) => !oldvalue);
            }
        }
        bus.on('project:isArchive', messageHandler);
        bus.on('chat_message_multiSync_event:joyWork', multiSync);
        return () => {
            bus.off('project:isArchive', messageHandler);
            bus.off('chat_message_multiSync_event:joyWork', multiSync);
        };
    }, [projectId, t]);

    useEffect(() => {
        setTitle(project?.title || '');
        setDescription(project?.description || '');
    }, [project]);

    const meetingRender = React.useMemo(() => {
        const openMeeting = (data: IMember[], close: () => void) => {
            const list = data.map((u) => ({
                realName: u.name,
                userId: u.userId,
                teamId: u.teamId,
                appId: u.app,
                headImg: u.avatar,
            }));
            FocusSDK.emitCommonChannelEvent({
                event: 'common:sendMessageToMainPage',
                data: {
                    type: 'meeting:create-show',
                    data: {
                        list,
                    },
                },
                callback: close,
            });
        };
        const openSelector = () => {
            const user = project?.user;
            membersSelector(
                {
                    title: t('basic_setting.select_member'),
                    teamId: user?.teamId || '',
                    currentUser: {
                        app: user?.app ?? '',
                        pin: user?.userId ?? '',
                        teamId: user?.teamId ?? '',
                    },
                },
                openMeeting
            );
        };
        return (
            // 一期不上线
            false && (
                <Tooltip placement="top" title={t('detail.video_metting')}>
                    <Icon type="iconic_meeting" onClick={openSelector} />
                </Tooltip>
            )
        );
    }, [project, t]);

    function renderHeader() {
        if (!project) {
            return (
                <div className="pm-detail-skeleton">
                    <Skeleton.Avatar active shape="square" size="large" />
                    <span>
                        <Skeleton.Input active size="large" style={{ width: 150 }} />
                    </span>
                    <Skeleton.Input active size="large" style={{ width: 150 }} />
                </div>
            );
        } else {
            return (
                <>
                    <div className="pm-details-header-left">
                        <div>
                            {project?.user?.role &&
                            !project.archive &&
                            [ProjectRole.ADMIN, ProjectRole.OWNER].includes(project.user.role) ? (
                                <SelectAvatar
                                    className="pm-details-avatar"
                                    imgUrl={projectIcon}
                                    onChange={(url) => {
                                        setProjectIcon(url);
                                        tabs.setProps({
                                            id: projectId,
                                            newIcon: url,
                                        });
                                        api.updateProject(projectId, {
                                            icon: url,
                                        });
                                    }}
                                />
                            ) : (
                                <div className="pm-selectavatart-wrap">
                                    <div style={{ cursor: 'auto' }}>
                                        <img src={changeEgovUrl(projectIcon)} alt="" />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {project && <Title value={title} project={project} />}
                            <span
                                className="pm-project-status-icon"
                                onClick={() => {
                                    openProjectInfo(
                                        { projectId, title, description, t, project },
                                        setProject,
                                        events
                                    );
                                }}
                            >
                                <Icon type="iconic_describe1" />
                            </span>
                            {project && (
                                <Status
                                    project={project}
                                    onChange={(project) => setProject(project)}
                                />
                            )}
                        </div>
                        <div>
                            <Icon type="iconic_notice1" />
                            {announcement || t('detail.announce.nodata')}
                        </div>
                    </div>
                    <div className="pm-details-header-right">
                        {(project?.user?.role === ProjectRole.OWNER ||
                            project?.user?.role === ProjectRole.ADMIN) && (
                            <Tooltip placement="top" title={`${t('list.setting')}`}>
                                <span
                                    onClick={() => setIsOpenSettingModal((oldvalue) => !oldvalue)}
                                >
                                    <Icon
                                        type="iconjs_ic_install"
                                        className={classNames({
                                            'pm-highlight-chatgroup-icon': isShowGroupChat,
                                        })}
                                    />
                                </span>
                            </Tooltip>
                        )}

                        {meetingRender}
                        {/* <Tooltip placement="top" title={t('detail.announce.title')}>
                            <span
                                onClick={() =>
                                    openAnnouncement(
                                        {
                                            projectId,
                                            userRole: project?.user.role ?? 4,
                                            events,
                                            t,
                                        },
                                        setAnnouncement
                                    )
                                }
                            >
                                <Icon type="iconic_notice1" />
                            </span>
                        </Tooltip> */}
                        {/* {project && !project.archive && <MoreMenu project={project} />} */}
                    </div>
                </>
            );
        }
    }

    const handleChangeTab = useCallback((key: string) => {
        setTabKey(key);
    }, []);

    return (
        <div className="pm-details">
            <div className="pm-details-header">{renderHeader()}</div>
            <div className={['pm-details-main', isShowGroupChat ? 'open' : ''].join(' ')}>
                <div className="pm-tabs-wrap">
                    {project && (
                        <Tabs defaultActiveKey="0" activeKey={tabKey} onChange={handleChangeTab}>
                            <Tabs.TabPane
                                tab={isBjme() ? t('detail.group_team') : t('detail.communicate')}
                                key="0"
                            >
                                <GroupTeamList
                                    projectId={projectId}
                                    selectedProject={selectedProject}
                                    project={project}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('detail.task')} key="1">
                                <TeamWorkView
                                    projectId={projectId}
                                    soureceName={title}
                                    archive={project.archive}
                                />
                            </Tabs.TabPane>
                            {/* <Tabs.TabPane tab={t('detail.trend')} key="2">
                                <Discuss project={project} />
                            </Tabs.TabPane> */}
                            <Tabs.TabPane tab={t('detail.calendar')} key="4">
                                <CalendarView
                                    calendarId={project.calendarId}
                                    archive={project.archive}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('detail.world')} key="5">
                                {tabKey === '5' ? <Documentation project={project} /> : ''}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('detail.announcement')} key="7" forceRender={true}>
                                <AnnounceTab
                                    project={{
                                        projectId,
                                        userRole: project.user.role,
                                        archive: project.archive,
                                        setAnnouncement,
                                    }}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('detail.data')} key="6">
                                {tabKey === '6' ? (
                                    <DataPanel projectId={projectId} changeTab={handleChangeTab} />
                                ) : (
                                    ''
                                )}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('detail.member')} key="3">
                                <div className="pm-member-wrap">
                                    {project && (
                                        <Member project={project} isUpdate={isUpdateProject} />
                                    )}
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                </div>
                <div className="pm-main-chat-wrap">
                    <div>
                        {isShowGroupChat && (
                            <Chat
                                projectId={projectId}
                                project={project}
                                closeGroupChat={(value) => setIsShowGroupChat(value)}
                            />
                        )}
                    </div>
                </div>
            </div>
            {settingProject && (
                <ProjectSetting
                    isOpenSettingModal={isOpenSettingModal}
                    onClose={(res) => {
                        setIsOpenSettingModal(res);
                        setIsUpdateProject((oldvalue) => !oldvalue);
                    }}
                    project={{
                        ...settingProject,
                    }}
                    reloadProjectList={() => {
                        projectUpdate?.();
                        setIsUpdateProject((oldvalue) => !oldvalue);
                    }}
                    activeTab={0}
                />
            )}
        </div>
    );
}

function toProjectData(data: any) {
    const u = data?.userInfo ?? {};
    const o = data?.ownerInfo ?? {};
    const project = {
        id: data?.projectId,
        title: data?.name,
        description: data?.description,
        progress: data.progress,
        progressDesc: data.progressDesc,
        icon: data?.icon || 1,
        iconColor: data?.iconColor,
        type: Number(data?.type || 1),
        // announcementText: data?.announcementText,
        announcementLatest: data.announcementLatest,
        userInfo: data?.userInfo,
        user: {
            app: u.app,
            duty: u.duty,
            permissiones: Number(u.manageUser),
            teamId: u.teamId,
            trend: u.trend,
            userId: u.userId,
            name: u.userName,
            role: u.userRole,
        },
        ownerInfo: {
            app: o.app,
            headImg: o.headImg || o.icon,
            teamId: o.teamId,
            userId: o.userId,
            userName: o.userName,
        },
        calendarId: data.calendarId || '',
        joyspaceId: data.joyspaceId || '',
        defaultGroupId: data.defaultGroupId,
        groupIds: data.groupIds || [],
        archive: data.archive,
        progressName: data.progressName,
    };
    return project as Project;
}
