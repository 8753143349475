/* eslint-disable complexity */
/**
 * 聊天消息头（群组，会话）
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useContext, useMemo, useEffect, useState, useRef } from 'react';
import { message as toast, Modal, Radio, Tooltip } from 'antd';
import './index.less';
import IconFont from '@/components/icon';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import {
    Employee,
    Group,
    UID,
    ChatMessage,
    ChatMessageType,
    GroupAttribute,
    Session,
} from '@/types/chat';
import config, { ChatConfig, ConfigEnum } from '@/config/config';
import { HeadShowType, SessionSettingsType } from '@/types/chat/enum';
import {
    employeeToUid,
    isEqualEmployee,
    isCrossPlatformGroupSession,
    getSessionIndex,
    messageBurnOfSession,
} from '@/components/chat/utils/message';
import { SessionTag } from '@/components/Tags';
import useGroupUserSelector from '@/components/chat/GroupUserSelector';
import { FocusSDK, isFocusEnv } from '@/utils';
import dayjs from 'dayjs';
import { openUserSelectionModal } from '@/utils/modals';
import bus from '@/utils/bus';
import Context from '@/components/chat/context';
import { ConferenceType, ConferenceApplyRequest } from '@/components/Voip/types';
import { checkCanAddOperate } from '@/components/chat/utils/group';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getRelationship } from '@/api/chat';
import { getSelectorSessions, isAINoticeSession } from '@/components/chat/utils/session';
import { get, isString } from 'lodash';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import ImService from '@/server/ImService';
import debounce from 'lodash/debounce';
import GroupNoticeDrawerList from '@/components/Projects/pages/groupNotice';
import { DrawTypeEnum } from '@/components/chat/enum';
import { getGroupInfo } from '@/api/egroup';
import { useHandleSessionTypeClick } from '../../Session/common';
import { SessionSettingService } from '@/server/im/SessionService';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { NetState } from '@/models/netStatus';
import { usePrevious, userEscapeString } from '@/utils/chat/index';
import { htmlUnescape } from '@/utils/tools';
import { NetType } from '@/components/Modals/ChangeNetModal';
import { handleMeetingClickOperation } from './operation';

interface MessageHeadProps {
    setCallBack: (type: DrawTypeEnum) => void;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
    changeSelectMore: Function;
    launchSingleSession: Function;
    initSelectedSession: Function;
}

const snapchatTimeList = [
    { name: '关闭', value: 0 },
    { name: '1分钟', value: 1 },
    { name: '2分钟', value: 2 },
    { name: '5分钟', value: 5 },
    { name: '30分钟', value: 30 },
    { name: '1小时', value: 60 },
    { name: '24小时', value: 60 * 24 },
];

type IMessageHeadProps = Readonly<MessageHeadProps & ChatState>;

const SessionHeader = (props: IMessageHeadProps & NetState) => {
    const {
        selectedSession,
        currentEmployee,
        sessionMembers,
        setCallBack,
        allSessionList,
        messages,
        updateMessages,
        changeSelectMore,
        launchSingleSession,
        initSelectedSession,
        newPublicResult,
        newPrivateResult,
    } = props;

    const { t } = useTranslation('chat');
    const [t_meeting] = useTranslation('meeting');
    const { groupRosters, groupRosterIdentity, userData } = React.useContext(Context);
    const [isfriend, setIsFriend] = useState(false);
    const [voipClick, SetVoipClick] = useState(false);
    const { userId, teamId, app } = selectedSession.info as Employee;
    const [isDimiss, setIsDimiss] = useState(false);
    const [snapchatTime, setSnapchatTime] = useState(0);
    const [snapchatTimeVisible, setSnapchatTimeVisible] = useState(false);
    const preSelectSession = usePrevious(selectedSession || {});
    const mountedRef = useRef(false);

    useEffect(() => {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (
            !selectedSession?.sessionId ||
            preSelectSession?.sessionId === selectedSession?.sessionId
        ) {
            return;
        }
        if (mountedRef.current) setIsDimiss(false);
        if (!selectedSession.isGroup) {
            return;
        }
        // 优先判断info.flag
        const info = selectedSession.info as GroupAttribute;
        if (info?.flag && info.flag !== 3) {
            setIsDimiss(true);
            return;
        }
        if (!info || !info?.flag) {
            getGroupInfo({
                groupIds: [{ groupId: selectedSession.sessionId || selectedSession.gid || '' }],
            })
                .then((res) => {
                    if (res?.groups?.[0]?.flag !== 3) {
                        if (mountedRef.current) setIsDimiss(true);
                    }
                })
                .catch((e) => {
                    console.error(`getGroupInfo error`, e);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession, preSelectSession]);

    useEffect(() => {
        if (selectedSession.isGroup || selectedSession.isNotice) {
            return;
        }
        (async () => {
            const [result, err] = await getRelationship({
                app,
                pin: userId,
                teamId: teamId,
            });
            if (err || !result?.relationship) {
                return;
            }
            const {
                relationship: { relation, friendship },
            } = result;
            if (relation === 1 || friendship === 1) {
                if (mountedRef.current) setIsFriend(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app, selectedSession.isGroup, selectedSession.isNotice, teamId, userId]);

    useEffect(() => {
        function handleGroupDelete(sessionId: string) {
            if (sessionId === selectedSession.sessionId) {
                if (mountedRef.current) setIsDimiss(true);
            }
        }
        bus.on('chat:group_delete', handleGroupDelete);
        return () => {
            bus.off('chat:group_delete', handleGroupDelete);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession.sessionId]);

    const forwardMessages = () => {
        // 合并转发
        const fixedUsers = getSelectorSessions(allSessionList);

        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                mode: SelectorMode.forward,
            },
            (data, close) => {
                doExitAndMergeForward(data);
                close();
            },
            userData
        );

        // 清除信息
        updateMessages({ messages: [] });
        changeSelectMore({ selectMore: false, type: null });
    };
    const exitAndMergeForward = async (data: any) => {
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        const imService = ImService.getInstance();
        const sessionId = selectedSession.sessionId;
        const optionResult: { gids: string[]; uids: UID[] } = { gids: [], uids: [] };
        const options = list.reduce((result: { gids: string[]; uids: UID[] }, item: any) => {
            if (item.isGroup) {
                result.gids.push(item.id);
            } else {
                result.uids.push({
                    pin: item?.pin,
                    app: item?.app,
                    teamId: item?.teamId,
                });
            }
            return result;
        }, optionResult);
        let mids: number[] = [];
        let joyspacePageIds: string[] = [];
        messages.forEach((message) => {
            if (message.type === ChatMessageType.JOYSPACEFILE) {
                joyspacePageIds.push();
            }
            mids.push(message.mid);
        });
        const result = await imService.mergeForwardMessage(sessionId, mids, options);
        if (isString(result) && result === SessionSettingsType.BANNED_POST) {
            return toast.warn(t('banned_post_tip'));
        }
        if (result) {
            toast.success(t('forwarding-successful'));
            updateMessages({ messages: [] });
            changeSelectMore({ selectMore: false });
        } else {
            toast.error(t('forwarding-failed'));
        }
    };

    const doExitAndMergeForward = debounce(async (data: any) => {
        return exitAndMergeForward(data);
    }, 500);

    const openUserSelector = useGroupUserSelector({
        selectedSession,
        currentEmployee,
        sessionMembers,
        groupRosters,
        userData,
        forwardMessages,
        callback: () => {
            bus.emit('chat:group:addMember');
        },
    });

    // 是否可邀请入群
    // 点击头像触发右侧设置展开栏
    const setting = useCallback(
        (type: DrawTypeEnum) => {
            setCallBack(type);
        },
        [setCallBack]
    );

    const doMemberClick = useCallback(
        (e) => {
            e?.stopPropagation();
            setting(DrawTypeEnum.setup);
        },
        [setting]
    );

    const getSessionGroupCountPart = useCallback(() => {
        if (!selectedSession.isGroup) {
            return null;
        }
        const group = (selectedSession.info as Group) || {};
        return (
            <div className="notice">
                <Tooltip placement="bottom" title={t('group-size')}>
                    <span
                        className="member"
                        onClick={doMemberClick}
                        clstag="pageclick|keycount|Xtbg_Msg|SessionMemberNum"
                    >
                        <IconFont className="member_icon" type="iconic_number" />
                        <span className="member_count">
                            {group.rosterSize ||
                                (group.rosters && Object.keys(group.rosters).length)}
                        </span>
                    </span>
                </Tooltip>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession.isGroup, selectedSession.info, doMemberClick]);

    const canAddOperate = useMemo(() => {
        return checkCanAddOperate({
            selectedSession,
            currentEmployee,
            groupRosterIdentity,
        });
    }, [currentEmployee, groupRosterIdentity, selectedSession]);

    const getCreateGroupPart = useCallback(() => {
        if (!canAddOperate) {
            return null;
        }
        const clstag = selectedSession.isSingle
            ? 'pageclick|keycount|Xtbg_Msg|AddMemberIcon_Single'
            : 'pageclick|keycount|Xtbg_Msg|AddMemberIcon_Group';

        const title = selectedSession.isGroup ? t('add_member') : t('create_group');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div className="add_user no-draggable" onClick={openUserSelector} clstag={clstag}>
                    <IconFont type="icona-ic_pc_im_Creategroup" />
                </div>
            </Tooltip>
        );
    }, [canAddOperate, selectedSession.isSingle, selectedSession.isGroup, t, openUserSelector]);

    const getSetupPart = useCallback(() => {
        if (selectedSession.isGroup) {
            bus.emit('chat:getGroupMembers', selectedSession);
        }
        const clstag = 'pageclick|keycount|Xtbg_Msg|SettingIcon';
        const title = t('set_up');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    clstag={clstag}
                    className="set_up no-draggable"
                    onClick={() => {
                        setCallBack(DrawTypeEnum.setup);
                    }}
                >
                    <IconFont type="icona-ic_pc_im_setup" />
                </div>
            </Tooltip>
        );
    }, [t, setCallBack, selectedSession]);

    const getSearchPart = useCallback(() => {
        let clstag = 'pageclick|keycount|Xtbg_Msg|SearchIcon';
        const title = t('search_record');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    clstag={clstag}
                    className="search_record no-draggable"
                    onClick={() => {
                        setCallBack(DrawTypeEnum.search);
                    }}
                >
                    <IconFont type="iconic_pc_im_viewlarger" />
                </div>
            </Tooltip>
        );
    }, [t, setCallBack]);

    function handleMeetingClick(flag = false) {
        handleMeetingClickOperation({
            selectedSession,
            currentEmployee,
            newPublicResult,
            globalContext: {
                groupRosters,
                userData,
            },
            flag,
        });
    }

    function getCreateMeetingPart() {
        // TODO: web端是否显示会议入口
        if (!isFocusEnv()) {
            return null;
        }

        if (selectedSession.isSingle) {
            if (!isfriend) {
                return null;
            }
            // // 解析单聊跨平台逻辑
            const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
            if (app !== currentEmployee.app || teamId !== currentEmployee.teamId) {
                return null;
            }
        }
        if (selectedSession.isNotice || selectedSession.isSecret) {
            return null;
        }
        if (isCrossPlatformGroupSession(selectedSession)) {
            return null;
        }

        let clstag = 'pageclick|keycount|Xtbg_Msg|MeetingIcon_Group';
        if (selectedSession.isSingle) {
            const info = selectedSession.info as Employee;
            if (isEqualEmployee(info, currentEmployee)) {
                // 自已跟自已聊天不能创建群聊
                return null;
            }
            clstag = 'pageclick|keycount|Xtbg_Msg|MeetingIcon_Single';
        }

        const title = selectedSession.isGroup
            ? t('joyMeeting.create meeting')
            : t('joyMeeting.create call');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="create_meeting"
                    onClick={() => handleMeetingClick()}
                    clstag={clstag}
                >
                    <IconFont type="iconic_pc_im_joymeeting_video" />
                </div>
            </Tooltip>
        );
    }
    function getCreateVoipPart() {
        if (!isFocusEnv()) {
            return null;
        }
        if (selectedSession.isNotice || selectedSession.isSecret) {
            return null;
        }
        if (!selectedSession.isSingle) {
            return null;
        }
        // 写群聊的时候把是否是好友放到单聊if里
        if (!isfriend) {
            return;
        }

        // 解析单聊跨平台逻辑
        const sessionSplit = selectedSession.sessionId.split(':');
        if (sessionSplit[1] !== sessionSplit[4]) {
            return null;
        }

        let clstag = 'pageclick|keycount|Xtbg_Msg|VoipIcon_Group';
        if (selectedSession.isSingle) {
            const info = selectedSession.info as Employee;
            if (isEqualEmployee(info, currentEmployee)) {
                // 自已跟自已聊天不能创建群聊
                return null;
            }
            clstag = 'pageclick|keycount|Xtbg_Msg|VoipIcon_Single';
        } else {
            return null;
        }
        function handleVMeetingClick() {
            handleMeetingClick(true);
        }
        function handleCreateSingleVoip() {
            // 处理一下连点场景
            if (voipClick) {
                return;
            }
            SetVoipClick(true);
            setTimeout(() => {
                SetVoipClick(false);
            }, 5000);
            const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
            const opts: Partial<ConferenceApplyRequest> = {
                topic: t('voip.meetingTopicDesc')
                    .replace('%from', currentEmployee.name)
                    .replace('%to', name),
                conference_type: ConferenceType.kConferenceSingle,
                sid: selectedSession.sessionId, //
                start: dayjs().valueOf(),
            };

            FocusSDK.sendIpcMessage('voip:createSingleCall', {
                request: opts,
                invite: {
                    member_app_: app,
                    member_id_: userId,
                    team_id_: teamId,
                },
            });
            toast.info(t_meeting('meeting initialization'));
        }
        function handleCreateGroupVoip() {}
        const title = t('voip.audio meeting');

        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div className="create_meeting" onClick={handleVMeetingClick} clstag={clstag}>
                    <IconFont type="iconic_pc_im_call1" />
                </div>
            </Tooltip>
        );
    }

    // 组(群聊)增加公告 --hujun
    const globalcontext = useContext<GlobalContextConfig>(GlobalContext);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const projectType = `project${
        ['mebj', 'jgswy', 'mebjJZB'].includes(appRuntimeEnv.runtimeConfig.key)
            ? '_' + appRuntimeEnv.runtimeConfig.key
            : ''
    }`;
    const t1 = useTranslation(projectType).t;
    function getCreateGroupNotice() {
        // 不是群组
        if (!selectedSession.isGroup) {
            return null;
        }
        const clstag = 'pageclick|keycount|Xtbg_Msg|GroupNoticeIcon';
        const title = t('groupNotice');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="create_meeting"
                    onClick={() => {
                        GroupNoticeDrawerList({
                            prentVisible: true,
                            projectId: selectedSession.id || selectedSession.sessionId,
                            globalcontext,
                            t: t1,
                            level: groupRosterIdentity,
                            session: selectedSession,
                        });
                    }}
                    clstag={clstag}
                >
                    <IconFont type="iconic_pc_im_notice2" />
                </div>
            </Tooltip>
        );
    }

    const openConfirmModal = () => {
        prompt({
            title: selectedSession.isSecret
                ? t('change-session.normal-chat-title')
                : t('change-session.secret-chat-title'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: async () => {
                handleChangeSession();
            },
            content: selectedSession.isSecret
                ? t('change-session.normal-chat-content')
                : t('change-session.secret-chat-content'),
            okText: t('determine'),
            cancelText: t('cancelText'),
        });
    };

    const getChangeSessionPart = () => {
        const title = selectedSession.isSecret ? t('change_normal_session') : '切换至阅后即焚会话';
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="change_session no-draggable"
                    onClick={() => {
                        openConfirmModal();
                    }}
                    clstag="pageclick|keycount|Xtbg_Msg|ChangeSessionIcon"
                >
                    <IconFont type="iconic_pc_im_returnchat" />
                </div>
            </Tooltip>
        );
    };

    function findSnapChatSession() {
        const index = getSessionIndex(allSessionList, {
            sessionId: `${selectedSession.sessionId}:secret`,
        });
        return index > -1;
    }

    const handleChangeSession = useHandleSessionTypeClick({
        selectedSession,
        launchSingleSession,
        initSelectedSession,
        updateMessages,
        changeSelectMore,
    });

    const getSnapchatTimePart = () => {
        const title = t('snapchat_time');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="set_snapchat_time no-draggable"
                    onClick={() => {
                        setSnapchatTimeVisible(true);
                        const messageBurn = messageBurnOfSession(selectedSession);
                        setSnapchatTime(messageBurn ? messageBurn / 60 : 0);
                    }}
                    clstag="pageclick|keycount|Xtbg_Msg|SetSnapchatTimeIcon"
                >
                    <IconFont
                        className={`${
                            messageBurnOfSession(selectedSession) ? 'iconic_ap_fire_nomal' : ''
                        }`}
                        type="iconic_pc_im_fire1"
                    />
                </div>
            </Tooltip>
        );
    };

    async function sendSnapchatSysMessage() {
        // 设置会话阅后即焚时间、发送系统消息、都成功之后关闭弹框
        const imservice = ImService.getInstance();
        const sessionId = selectedSession.sessionId;
        const result = await SessionSettingService.setSessionMessageBurn.call(
            imservice,
            sessionId,
            snapchatTime * 60
        );
        if (result) {
            setSnapchatTimeVisible(false);
            imservice?.sendSystemMessage(sessionId, {
                content: snapchatTime
                    ? snapchatTime >= 60
                        ? `的消息阅后即焚设置为${snapchatTime / 60}小时`
                        : `的消息阅后即焚设置为${snapchatTime}分钟`
                    : '已关闭“阅后即焚”',
                type: ChatMessageType.SYS,
                flag: 3,
            });
        } else {
            console.log('设置阅后即焚时间失败');
        }
    }

    return (
        <div className="message-head-container grey">
            <div className="message-head-inner no-draggable">
                {!selectedSession.isSecret && (
                    <div
                        onClick={() => setting(DrawTypeEnum.setup)}
                        clstag="pageclick|keycount|Xtbg_Msg|SessionAvatar"
                    >
                        <HeadPortrait
                            key={0}
                            width="36px"
                            height="36px"
                            imgUrl={selectedSession?.info?.avatar}
                            name={selectedSession?.info?.name}
                            type={selectedSession.sessionType}
                            isUnFollow={true}
                            showType={HeadShowType.SESSION}
                        />
                    </div>
                )}
                <div
                    className={`name-notice ${
                        selectedSession.isSecret ? 'secret-name-notice' : ''
                    }`}
                >
                    <div className="name">
                        {selectedSession.isSecret && (
                            <div className="secret">
                                <IconFont
                                    style={{ color: '#F96137' }}
                                    type="iconic_app_im_burnafterreading_facet"
                                />
                            </div>
                        )}
                        <span
                            className="name-1"
                            onClick={() => setting(DrawTypeEnum.setup)}
                            clstag="pageclick|keycount|Xtbg_Msg|SessionName"
                            dangerouslySetInnerHTML={{
                                __html: userEscapeString(selectedSession?.info?.name),
                            }}
                        />
                        {/* {selectedSession?.info?.name}
                        </span> */}
                        <SessionTag
                            session={selectedSession}
                            currentEmployee={currentEmployee}
                            needJump
                        />
                    </div>
                    {getSessionGroupCountPart()}
                </div>
            </div>
            <div className="action-button">
                {/* 单聊密聊或者会话列表创建过密聊 */}
                {/* 聊天窗口视频会议 */}
                {selectedSession.isSingle &&
                    (selectedSession.isSecret || findSnapChatSession()) &&
                    getChangeSessionPart()}
                {selectedSession.isSingle && selectedSession.isSecret && getSnapchatTimePart()}
                {!isDimiss && getCreateGroupNotice()}
                {config[ChatConfig.CHAT_SESSION_CREATE_VOIP_ENABLE] && getCreateVoipPart()}
                {/* {(selectedSession?.info as GroupAttribute).gid && getShareJoyspace()} */}
                {!isAINoticeSession(selectedSession) &&
                    selectedSession.sessionId &&
                    getSearchPart()}
                {config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE] &&
                    !isDimiss &&
                    getCreateMeetingPart()}
                {!isDimiss && getCreateGroupPart()}
                {!isDimiss && selectedSession.sessionId && getSetupPart()}
            </div>
            <Modal
                visible={snapchatTimeVisible}
                title="设置消息阅后即焚时间"
                width={420}
                okText={t('button.ok')}
                cancelText={t('button.cancel')}
                wrapClassName="snapchatTimeModal"
                centered={true}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={() => setSnapchatTimeVisible(false)}
                onOk={() => {
                    sendSnapchatSysMessage();
                }}
            >
                <Radio.Group onChange={(e) => setSnapchatTime(e.target.value)} value={snapchatTime}>
                    {snapchatTimeList.map((item) => (
                        <Radio.Button key={item.value} value={item.value}>
                            {item.name}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </Modal>
        </div>
    );
};

function mapStateToProps({ chat, netStatus }: any) {
    const {
        selectedSession,
        currentEmployee,
        sessionMembers,
        allSessionList,
        messages,
    } = chat as ChatState;
    const { newPublicResult, newPrivateResult } = netStatus;
    return {
        selectedSession,
        currentEmployee,
        sessionMembers,
        allSessionList,
        messages,
        newPublicResult,
        newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        launchSingleSession(data: { employee: Employee }) {
            dispatch({ type: 'chat/launchSingleSession', payload: data });
        },
        initSelectedSession(data: { selectedSession: Session }) {
            dispatch({ type: 'chat/initSelectedSession', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionHeader);
