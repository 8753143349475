import { Dispatch, SetStateAction } from 'react';
import { openPicker } from '../CategoriesPicker';
import { lang } from '../../context';
import { createPageInFile, createTemplatePageInSpace, pageTypes } from '../../api/createmenu';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { ICurrentRouteInfo } from '../../types';
import Bus from '@/utils/bus';
import { isFocusEnv } from '@/utils';
import openTemplateModal from './createDocumentByTemplate';
import { createDocumentByPageType, PageType } from './utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import events from '@/components/Joyspace/context/events';
import { message } from 'antd';

if (!isFocusEnv()) {
    // eslint-disable-next-line
    require('@/components/Joyspace/winOpen');
}
import { PageTypeKey } from '../../const';
import { addToken } from '../../util';

/**
 * @deprecated 将要被放弃使用
 */
export default (
    appRuntimeEnv: AppRuntimeEnv,
    currentRouteInfo: ICurrentRouteInfo,
    zhuanban?: {
        teamId: string;
        spaceId: string;
        selectfolder?: {
            id: string;
            name: string;
        };
        setIsReload?: Dispatch<SetStateAction<boolean>>;
    },
    reloadListCallback?: Dispatch<SetStateAction<boolean>>,
    gid?: string
    // eslint-disable-next-line max-params
) => {
    const urlPrefix = appRuntimeEnv.joyspaceHost;
    const to_groups = [{ gid, permission_type: 1 }];
    if (zhuanban) {
        if (zhuanban.selectfolder?.id) {
            // 这是在专班文件夹中新建文档
            createTemplatePageInSpace({
                folderId: zhuanban.selectfolder?.id ?? '',
                placename: zhuanban.selectfolder?.name || '默认文件夹',
                to_groups,
            }).then((res) => {
                if (!res) return;
                zhuanban.setIsReload!((oldvalue) => !oldvalue);
                window.open(`${urlPrefix}/${PageTypeKey[res.page_type]}/${res.id}`);
                Bus.emit('joyspace:documentCreated', res);
            });
        } else {
            // 这是在专班空间中新建文档
            createTemplatePageInSpace({
                spaceId: zhuanban.spaceId ?? '',
                placename: '',
                to_groups,
            }).then((res) => {
                if (!res) return;
                zhuanban.setIsReload!((oldvalue) => !oldvalue);
                window.open(`${urlPrefix}/${PageTypeKey[res.page_type]}/${res.id}`);
                Bus.emit('joyspace:documentCreated', res);
            });
        }
        return;
    }
    openPicker({
        title: lang('c.CreateMenu.createDoc'),
        rootable: true,
        currentRouteInfo,
        appRuntimeEnv,
        callback: (data, close) => {
            // 在空间中和在文件夹中创建文档，调用的接口不一样，团队空间下用categoryId，spaceId，我的空间下用folderId和placename
            if (data?.category?.id && data?.space?.id) {
                createPageInFile({
                    spaceId: data?.space?.id || '',
                    categoryId: data?.category?.id,
                    placename: data?.category?.name || '我的文件',
                    to_groups,
                }).then((res) => {
                    if (!res) return;
                    reloadListCallback?.((oldvalue) => !oldvalue);
                    close();
                    window.open(`${urlPrefix}/${PageTypeKey[res.page_type]}/${res.id}`);
                    Bus.emit('joyspace:documentCreated', res);
                });
            } else if (data?.category?.id) {
                createPageInFile({
                    folderId: data?.category?.id ?? '',
                    folder_id: data?.category?.id ?? '',
                    placename: data?.category?.name || '我的空间',
                    to_groups,
                }).then((res) => {
                    if (!res) return;
                    reloadListCallback?.((oldvalue) => !oldvalue);
                    close();
                    window.open(`${urlPrefix}/${PageTypeKey[res.page_type]}/${res.id}`);
                    Bus.emit('joyspace:documentCreated', res);
                });
            } else {
                createTemplatePageInSpace({
                    spaceId: data?.space?.id ?? '',
                    placename: data?.space?.name ?? '',
                    to_groups,
                }).then((res) => {
                    if (!res) return;
                    reloadListCallback?.((oldvalue) => !oldvalue);
                    close();
                    window.open(`${urlPrefix}/${PageTypeKey[res.page_type]}/${res.id}`);
                    Bus.emit('joyspace:documentCreated', res);
                });
            }
        },
    });
};

interface IProps {
    canCreateFolder?: boolean;
    canCreatePage?: boolean;
    space?: () => void;
    team?: () => void;
    category?: () => void;
    lowCode?: (url: string) => void;
    zhuanban?: {
        teamId: string;
        spaceId: string;
        selectfolder?: {
            id: string;
            name: string;
        };
        setIsReload?: Dispatch<SetStateAction<boolean>>;
        projectId: string;
        archive?: boolean;
    };
    folderId?: string;
    reloadListCallback?: Dispatch<SetStateAction<boolean>>;
    gid?: string;
    globalContext: GlobalContextConfig;
}

export function createDocByTemplate(props: IProps) {
    const { folderId, reloadListCallback, gid, globalContext } = props;
    const { appRuntimeEnv } = globalContext;
    const urlPrefix = appRuntimeEnv.joyspaceHost;
    // 创建各种文档
    const createDocument = (type: string) => {
        createDocumentByPageType(type, folderId || '', gid || '')
            .then((res: any) => {
                if (!res) return;
                reloadListCallback?.((oldvalue) => !oldvalue);
                events.emit('list.reload');
                const openUrl = `${urlPrefix}/${pageTypes[res.page_type]}/${res.id}`;
                window.open(openUrl);
                Bus.emit('joyspace:documentCreated', res);
            })
            .catch((err: any) => {
                message.error(lang('common.fail'));
            });
    };
    // 通过模版创建
    return () => {
        openTemplateModal({
            globalContext,
            pageType: 1,
            callback: (id: string) => {
                // 没有id时，创建空白文档
                if (!id) {
                    return createDocument(PageType.page);
                }
                createTemplatePageInSpace({
                    folderId: folderId === 'root' ? '' : folderId,
                    templateId: id,
                })
                    .then((res) => {
                        if (!res) return;
                        reloadListCallback?.((oldvalue) => !oldvalue);
                        events.emit('list.reload');
                        window.open(`${urlPrefix}/${pageTypes[res.page_type]}/${res.id}`);
                        Bus.emit('joyspace:documentCreated', res);
                    })
                    .catch(() => {
                        message.error(lang('common.fail'));
                    });
            },
        });
    };
}
