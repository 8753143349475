import React, { useMemo, useState } from 'react';
import './index.less';
import { SuperviseUserInfo, Receiver, ReadFlag } from '@/types/supervise';
import { Popover, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import dayjs from 'dayjs';
import { UserListEmptyImg } from '@/assets/img';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import { connect } from 'dva';
import i18n from '@/i18n';
import Bus from '@/utils/bus';
import LoadMore from '../loadMore';
import { getSuperviseReceiverList } from '@/api/supervise';
import { useAppId } from '@/components/Supervise/envirment';
import { changeEgovUrl } from '@/utils/tools';

const { TabPane } = Tabs;

function ReceiveStatusPopover({
    // unfinishList,
    // finishList,
    onRemove,
    children,
    disable,
    toggleUserCardModal,
    userCardVisible,
    popoverVisibleChange,
    superviseRepeat,
    superviseId,
    unFinishCount,
    finishCount,
}: {
    // unfinishList: Receiver[];
    // finishList: Receiver[];
    onRemove: (user: any) => void;
    children: any;
    disable?: boolean;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    userCardVisible: boolean;
    superviseRepeat?: () => void;
    popoverVisibleChange: (v: boolean) => void;
    superviseId: string;
    unFinishCount: number;
    finishCount: number;
}) {
    const { t } = useTranslation('supervise');

    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [unreadHasMore, setUnReadHasMore] = useState(true);
    const [readHasMore, setReadHasMore] = useState(true);
    const [finishList, setFinishList] = useState<Receiver[]>([]);
    const [unfinishList, setUnFinishList] = useState<Receiver[]>([]);
    const [loading, setLoading] = useState(true);
    const limit = 10;
    const appId = useAppId();
    function visibleChange(visible: boolean) {
        if (modalVisible) {
            return;
        }
        if (userCardVisible) {
            return;
        }
        popoverVisibleChange(visible);
        setVisible(visible);
        // if (unfinishList.length >= limit) {
        //     setUnReadHasMore(true);
        // } else {
        //     setUnReadHasMore(false);
        // }
        // if (finishList.length >= limit) {
        //     setReadHasMore(true);
        // } else {
        //     setReadHasMore(false);
        // }
        if (visible) {
            getData(0, [0]);
            getData(0, [1, 2]);
        } else {
            setUnReadHasMore(true);
            setReadHasMore(true);
            setFinishList([]);
            setUnFinishList([]);
        }
    }
    const [t_common] = useTranslation('common');
    function handleRemoveMember(user: SuperviseUserInfo) {
        onRemove({
            userId: user.userId,
            teamId: user.teamId,
            app: user.app,
        });
    }

    function openUserCard(item: SuperviseUserInfo) {
        const { userId, teamId, appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }

    function getData(offset: number, readFlags: number[]) {
        setLoading(true);
        getSuperviseReceiverList({
            offset,
            limit,
            readFlags,
            superviseId,
        })
            .then((res: any[]) => {
                // console.log(res, 'resresresresresres');
                const { receiverList = [] } = res?.[0] || {};
                if (readFlags.indexOf(0) >= 0) {
                    if (receiverList.length < limit) {
                        setUnReadHasMore(false);
                    } else {
                        setUnReadHasMore(true);
                    }
                    setUnFinishList([...unfinishList, ...receiverList]);
                } else {
                    if (receiverList.length < limit) {
                        setReadHasMore(false);
                    } else {
                        setReadHasMore(true);
                    }
                    setFinishList([...finishList, ...receiverList]);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getContent = () => {
        const getList = (list: any[], finishFlag: boolean) => {
            return list.map((item: Receiver) => (
                // eslint-disable-next-line react/jsx-key
                <div
                    className="receive-status-list-item"
                    key={item.userId + item.teamId + item.appId}
                >
                    {item.headImg ? (
                        <img
                            src={changeEgovUrl(item.headImg || '')}
                            className="avatar"
                            onClick={() => openUserCard(item)}
                        />
                    ) : (
                        <div className="avatar" onClick={() => openUserCard(item)}>
                            {(item.realName || '').slice(0, 1)}
                        </div>
                    )}
                    <div className="container">
                        <div className="title-content">
                            <div>
                                <span className="title">{item.realName}</span>
                                {item.readFlag === ReadFlag.finish && (
                                    <span className="title-status">
                                        {i18n.t('supervise:finished')}
                                    </span>
                                )}
                                {item.isShowTag && <span className="title-tag">外部</span>}
                            </div>
                            <span className="desc">{item.desc}</span>
                        </div>
                        {/* <div className="desc">
                            <span className="desc-org">{item.deptInfo?.fullName || ''}</span>
                        </div> */}
                    </div>
                </div>
            ));
        };
        const getEmpty = () => (
            <div className="receive-status-list-empty">
                <img src={changeEgovUrl(UserListEmptyImg)} />
                <div className="label">{t('noData')}</div>
            </div>
        );
        return (
            <div onClick={(e) => e.stopPropagation()} style={{ height: '100%' }}>
                <Tabs defaultActiveKey="1" className="receive-status-tab">
                    <TabPane tab={`${t('unread')} (${unFinishCount})`} key="1">
                        <div style={{ position: 'relative', height: '100%' }}>
                            <div className="receive-status-list receive-status-list-unfinish">
                                {unfinishList.length > 0
                                    ? getList(unfinishList, false)
                                    : loading
                                    ? null
                                    : getEmpty()}
                                {unreadHasMore && !loading ? (
                                    <LoadMore
                                        loading={loading}
                                        clickHandle={() => {
                                            getData(unfinishList.length, [0]);
                                        }}
                                    />
                                ) : null}
                            </div>
                            {unfinishList.length ? (
                                <div className="receive-status-button">
                                    <p
                                        onClick={() => {
                                            superviseRepeat?.();
                                        }}
                                    >
                                        {i18n.t(`supervise:repeatSupervise${appId}`)}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </TabPane>
                    <TabPane tab={`${t('read')} (${finishCount})`} key="2">
                        <div className="receive-status-list">
                            {finishList.length > 0 ? getList(finishList, true) : getEmpty()}
                            {readHasMore && !loading ? (
                                <LoadMore
                                    loading={loading}
                                    clickHandle={() => {
                                        getData(finishList.length, [1, 2]);
                                    }}
                                />
                            ) : null}
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    };
    return (
        <Popover
            overlayClassName="supervise-receive-status-popover"
            trigger="click"
            placement="bottom"
            content={getContent}
            visible={visible}
            onVisibleChange={visibleChange}
        >
            {children}
        </Popover>
    );
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceiveStatusPopover);
