// 设置展开栏底部按钮

import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { GroupRoster, Session, SessionProps } from '@/types/chat';
import { connect } from 'dva';
import log from '@/utils/logger';
import Context from '@/components/chat/context';
import ChatState from '@/types/chat/State';
import { Button, Checkbox, Input, message, Modal } from 'antd';
import { IconSearch } from '@/components/icon';
import { changeEgovUrl } from '@/utils/tools';

import Bus from '@/utils/bus';
import { UserCardType } from '@/types/UserCard';
import debounce from 'lodash/debounce';
import PinyinMatch from 'pinyin-match';
import { convertEmployeeToString, employeeToUid } from '../utils/message';
import ImService from '@/server/ImService';

const logger = log.getLogger('comp:SetGroupAdmin');
interface GroupSubAdminProps {
    selectedSession: Session;
    loadSessionMembers: Function;
    visible: boolean;
    onCancel: () => void;
}
interface AdminGroupRoster extends GroupRoster {
    checked: boolean;
}

type IProps = Readonly<GroupSubAdminProps>;

function GroupSubAdmin(props: IProps) {
    const { selectedSession, visible, onCancel, loadSessionMembers } = props;
    const { currentEmployee, groupRosters, groupRosterIdentity, userData } = React.useContext(
        Context
    );
    const { t } = useTranslation('chat');

    const [operate, setOperate] = useState('remove');
    const [list, setList] = useState<Partial<AdminGroupRoster>[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [result, setResult] = useState([]);
    const [num, setNum] = useState(0);

    const [subAdmins, ordinarys] = useMemo(() => {
        const subAdminArr = groupRosters?.filter(
            (roster: any) => roster.identity === 'sub_administrator'
        );
        const ordinaryArr = groupRosters
            ?.filter((roster: any) => roster.identity === 'ordinary')
            ?.map((roster1: any) => {
                return { ...roster1, checked: false };
            });
        return [subAdminArr || [], ordinaryArr || []];
    }, [groupRosters]);

    async function doSearch(value: string, isRemove: boolean) {
        const arr = isRemove ? subAdmins : ordinarys;
        const result = arr.filter((roster) => {
            const info: any = roster.info || {};
            return PinyinMatch.match(info.name || '', value);
        });
        // console.log('onSearch ==>', result);
        setList(result);
    }
    const doSearchDebounce = useRef(debounce(doSearch, 500)).current;
    const isRemove = useMemo(() => {
        return operate === 'remove';
    }, [operate]);
    useEffect(() => {
        if (!searchValue) {
            const tempList = isRemove ? subAdmins : ordinarys;
            // console.log('tempList', tempList);
            setList(tempList);
            return;
        }
        // console.log('data ==>', operate, subAdmins, ordinarys);
        // setList([]);
        doSearchDebounce(searchValue, isRemove);
    }, [searchValue, doSearchDebounce, isRemove, subAdmins, ordinarys, num]);

    const handleCheckChange = (roster: any) => {
        const id = convertEmployeeToString(roster.info);
        const idx = ordinarys.findIndex((item: any) => convertEmployeeToString(item.info) === id);
        // console.log('数组中存在的下标', idx);
        if (idx < 0) {
            return;
        }
        const newRoster = {
            ...ordinarys[idx],
            checked: !ordinarys[idx].checked,
        };
        // console.log(newRoster);
        ordinarys.splice(idx, 1, newRoster);
        // console.log(ordinarys, '改变后');
        // setList(ordinarys);
        const count = num + 1;
        setNum(count);
    };

    const openAdminModal = (operate: string) => {
        setOperate(operate);
        setSearchValue('');
    };

    const handleAddConfirm = async () => {
        // console.log('添加');
        if (!selectedSession) {
            return;
        }
        const list = ordinarys?.filter((roster) => roster.checked);
        const members = list.map((roster: any) => {
            return employeeToUid(roster.info);
        });
        logger.debug('addGroupAdmin data ==>>', JSON.stringify(members));
        // console.log('管理员选择', members);
        const instance = ImService.getInstance();
        const result = await instance.setGroupAdmin(
            selectedSession.sessionId,
            members,
            'ADD_ADMIN'
        );

        if (result) {
            Bus.emit('chat:group:addAdmin');
            loadSessionMembers({ selectedSession: selectedSession });
            onCancel();
            message.success('操作成功');
        } else {
            message.error('操作失败');
        }
    };

    const handleRemoveAdmin = async (roster: any) => {
        const members = [employeeToUid(roster.info)];
        if (!selectedSession) {
            return;
        }
        const instance = ImService.getInstance();
        const result = await instance.setGroupAdmin(
            selectedSession.sessionId,
            members,
            'DELETE_ADMIN'
        );
        if (result) {
            Bus.emit('chat:group:addAdmin');
            loadSessionMembers({ selectedSession: selectedSession });
            // onCancel();
            message.success('操作成功');
        } else {
            message.error('操作失败');
        }
    };

    const modalFooter = () => {
        return (
            <div className={isRemove ? 'button-left' : ''}>
                {isRemove ? (
                    <Button type="primary" onClick={() => openAdminModal('add')}>
                        {t('add-group-admin')}
                    </Button>
                ) : (
                    <>
                        <Button onClick={() => openAdminModal('remove')}>{t('cancelText')}</Button>
                        <Button disabled={!list?.length} type="primary" onClick={handleAddConfirm}>
                            {t('confirm')}
                        </Button>
                    </>
                )}
            </div>
        );
    };
    return (
        <Modal
            visible={visible}
            title={isRemove ? t('set-group-admin') : t('add-group-admin')}
            width={480}
            centered={true}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => (isRemove ? onCancel() : openAdminModal('remove'))}
            footer={modalFooter()}
            wrapClassName="sub-admin-modal"
        >
            <Input
                allowClear
                prefix={<span className="search-input-prefix">{IconSearch}</span>}
                placeholder={t('search.iconText')}
                className="search-input"
                value={searchValue}
                onChange={(e: any) => {
                    setSearchValue(e.target.value);
                }}
            />
            <div className="sub-admin-modal-container">
                {list.map((roster) => (
                    <div
                        className="sub-admin-list-item"
                        key={`${roster.info?.userId}:${roster.info?.app}:${roster.info?.teamId}`}
                        onClick={() => handleCheckChange(roster)}
                    >
                        <div className="sub-admin-list-item-content">
                            {operate === 'add' && <Checkbox checked={roster.checked} />}
                            {roster.info?.avatar ? (
                                <img
                                    src={changeEgovUrl(roster.info?.avatar)}
                                    className="content-avatar"
                                />
                            ) : (
                                <div className="content-avatar">
                                    {(roster.info?.name || '').slice(0, 1)}
                                </div>
                            )}
                            <div>{roster.info?.name}</div>
                        </div>
                        {isRemove && (
                            <div className="sub-admin-list-item-button">
                                <Button
                                    type="link"
                                    onClick={() => {
                                        handleRemoveAdmin(roster);
                                    }}
                                >
                                    移除
                                </Button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async loadSessionMembers(data: { selectedSession: SessionProps }) {
            await dispatch({ type: 'chat/loadSessionMembers', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSubAdmin);
