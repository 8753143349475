import 'url-search-params-polyfill';
import { OpenChat } from './Types';

export function getChatToFromSearchParams(searchParams: URLSearchParams): OpenChat | undefined {
    let toGid = searchParams.get('toGid');
    if (toGid) {
        return { type: 'group', gid: toGid };
    }
    let toUserId = searchParams.get('toUserId');
    let toTeamId = searchParams.get('toTeamId');
    let toApp = searchParams.get('toApp');
    if (toUserId && toTeamId) {
        return { type: 'single', userId: toUserId, teamId: toTeamId, app: toApp || undefined };
    }
    return undefined;
}
