// 新建流程
/* eslint-disable complexity */
/* eslint-disable max-depth */
import React from 'react';
import { connect } from 'dvajs';
import {
    Button,
    Input,
    Select,
    Checkbox,
    Radio,
    message,
    Switch,
    Drawer,
    Modal,
    Collapse,
} from 'antd';
import {
    CloseOutlined,
    BellOutlined,
    ContactsOutlined,
    EnvironmentOutlined,
    PaperClipOutlined,
    FileTextOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';
import { IndicatorContent, ColorIndicator } from './../Common/index';
import BasicModal from '../Modal/BasicModal';
import BaseButton from '../Button';
import DatePicker from './../DatePicker';
import CalendarDatePicker from '@/components/Calendars/BaseComponents/DatePicker';
import TimePicker from './../TimePicker';
import ColorPicker from './../ColorPicker';
import ScheduleTypeSelect, { scheduleTypeList } from './../ScheduleTypeSelect';
import './index.less';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { getRealColor, getRealColorWithoutPre } from '@/components/Calendars/utils';
import { getRecentChatSession } from '@/api/chat';
import { changeEgovUrl } from '@/utils/tools';
import { analysisLog } from '@/utils/logAnalytics';

import {
    addSchedule,
    modifySchedule,
    RemindMode,
    User,
    Canceled,
    ModifyType,
    UserType,
    CalendarType,
    CalendarInfo,
    sendCalendarCard,
    Schedule,
    ResStatus,
    Agent,
    createMeetingMinutes,
    shareScheduleAttachment,
} from '@/api/calendar';
import UserPicker from '@/components/Calendars/UserPicker';
import WorkUserList from '@/components/Work/BaseComponents/UserList';

import Attachment, { FileStatus } from './../Attachment';
import {
    closePng,
    remindPng,
    locationPng,
    calendarPng,
    attachmentPng,
    descriptionPng,
    sharelogoPng,
} from '@/assets/img/calendar';
import { CalendarIcon } from '@/components/Calendars/Common';
import { ScheduleDateScope, ScheduleDefaultColor } from '@/components/Calendars/utils/constant';
import log from '@/utils/logger';
import bus from '@/utils/bus';
import config, { ConfigEnum } from '@/config/config';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { debounce } from 'lodash';
import { ChatMessageType, Session } from '@/types/chat';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import IconFont from '@/components/icon';
import { openUserSelectionModal } from '@/utils/modals';
import UserStatusListPopover from './UserStatusListPopover';
import { findIndexExecutorInTask } from '@/components/Work/utils';
import ChatState from '@/types/chat/State';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { decorateQuickCreateTaskResource } from '@/components/Work/newUtils';
import { IMeetingModal } from '../Schedule/Detail';
import lodashCompact from 'lodash/compact';
import BaseInput from '../components/BaseInput';
import BaseTextArea from '../components/BaseTextArea';
import cloneDeep from 'lodash/cloneDeep';
import confirm from 'antd/lib/modal/confirm';
import ScrollView from '@/baseComponents/ScrollView';
import MyCalendar from '../MyCalendar';
import CalendarImService, {
    CalendarImServiceType,
} from '@/components/Calendars/services/CalendarImService';
import { MeetingPlatform, MeetingPlatformName } from '@/types/common';
import '@/components/Calendars/Calendars.less';
import CopyFrame from '@/components/CopyFrame';
import { isFocusEnv } from '@/utils';
import { color } from 'html2canvas/dist/types/css/types/color';

dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
const { Option } = Select;
// 下拉的时间间隔改成15分钟
const timeInterval = 15;

function getStartTimeList(d?: dayjs.Dayjs) {
    let n = d || dayjs();
    let today = n.startOf('date');
    let hourList = Array.from(new Array(144).keys());
    let data = hourList.map((item, index) => {
        let time = today.add(index * timeInterval, 'minute');
        return {
            value: time,
            title: time.format('HH:mm'),
        };
    });
    return data;
}
let startTimeList = getStartTimeList();
// 2020-05-01 12:22 得到-》2020-05-01 12:30
function getTimeWithFixedMinute(t: dayjs.Dayjs) {
    let minute = t.minute();
    let fixedMin = parseInt((minute / timeInterval).toString(), 10) * timeInterval + timeInterval;
    return t.minute(fixedMin);
}

function AddButton({ action }: any) {
    return <p onClick={action}>添加</p>;
}
interface IAttachment {
    fileId: string;
    fileName: string;
    fileType: string;
    fileUrl: string;
}
interface IScheduleCopy {
    title: string;
    attachMentList: IAttachment[];
    beginTime: any;
    endTime: any;
    repeatEndTime: any;
    canAppointment: boolean; // 是否可以同步创建会议
    meetingInfo: IMeetingModal | null; // 视频会议信息
    syncAppointMeeting: boolean; // 是否同步创建会议
    appointMeetingPass: string;
    calendarId: string; // 日历
    isAllDay: boolean; // 是否全天
    repeat: number; // 重复设置
    address: string; // 地点
    calendarColor: string; // 日历颜色
    description: string; // 描述信息
    remark: string; // 我的备注
    remindList: RemindMode[]; // 提醒列表
    ownerUser: any[]; // 创建人
    participant: any[]; // 参与人
}

export enum ScheduleCopyKey {
    title = 'title',
    attachMentList = 'attachMentList',
    beginTime = 'beginTime',
    endTime = 'endTime',
    repeatEndTime = 'repeatEndTime',
    canAppointment = 'canAppointment',
    meetingInfo = 'meetingInfo',
    syncAppointMeeting = 'syncAppointMeeting',
    appointMeetingPass = 'appointMeetingPass',
    calendarId = 'calendarId',
    isAllDay = 'isAllDay',
    repeat = 'repeat',
    address = 'address',
    calendarColor = 'calendarColor',
    description = 'description',
    remark = 'remark',
    remindList = 'remindList',
    ownerUser = 'ownerUser',
    participant = 'participant',
}
interface OwnState {
    addVisible: boolean; // 是否显示添加参与人的弹窗
    visible: boolean;
    title: string;
    beginDate: Dayjs;
    endDate: Dayjs;
    oldBeginTime: any;
    beginTime: any;
    endTime: any;
    repeatEndTime: any;
    // 是否全天
    isAllDay: boolean;
    // 重复设置
    repeat: number;
    // 提醒
    remind: string;
    // 地点
    address: string;
    // 日历
    calendarId: string;
    // 日历颜色
    calendarColor: string;
    // 描述信息
    description: string;
    // 我的备注
    remark: string;
    // 是否显示快速创建弹窗
    quickCreateFlag: boolean | undefined;
    participant: any[]; // 参与人
    ownerUser: any[]; // 创建人
    endTimeList: any[]; // 结束时间渲染列表，要根据beginTime获取
    attachMentList: IAttachment[];
    remindList: RemindMode[]; // 提醒列表

    canAppointment: boolean; // 是否可以同步创建会议
    syncAppointMeeting: boolean; // 是否同步创建会议
    meetingPlatform: MeetingPlatform | '';
    meetingPlatformName: string;
    meetingCustom: any; // 会议自定义参数
    appointMeetingPass: string;
    meetingInfo: IMeetingModal | null; // 视频会议信息
    noAPModalVisible: boolean; // 是否显示无代理权限提示
    createClick: boolean; // 防重点击
    meetingStrCopy: string; // 备份描述中的会议信息
    hadMeeting: boolean; // 用于标记此日程原本是否有视频会议
    endTimeChangedByUser: boolean; // 用于标记结束时间是否被用户手动选择过（选择过就不再根据开始时间自动往后推了）
    meetingInfoChanged: boolean; // 用于标记会议相关信息是否被修改了
    scheduleCopy: IScheduleCopy | null; // 用于备份日程编辑前的初始值
    checkUploadAttackment: boolean; // 用于标记是否上传完成附件
    // conferencePlatforms: any[]; // 更具权限查询会议列表
}

interface conferencePlatformsProp {
    platformType: string;
    platformName: string;
}

export enum ScheduleMode {
    Create = 1,
    Modify,
}

export enum CreateScheduleSourceEnum {
    self = 1, // 自建
    emial = 2, // 邮件
    timeline = 3, // 咚咚
    project = 4, // 专班
}

interface IProps {
    visible: boolean;
    userInfo: any;
    userData: any;
    calendarMap: any;
    calendarList: any;
    changeCreateModalVisible: (visible: boolean) => void;
    setSelectedCalendarList: (data: any) => void;
    onSave?: () => void;
    quickCreate?: boolean;
    beginTime?: Dayjs;
    endTime?: Dayjs;
    myCalendar: CalendarInfo;
    getScheduleListByTime: (data: {
        user?: User;
        calendarId?: string;
        beginTime: Dayjs;
        endTime: Dayjs;
    }) => void;
    selectedCalendarList: any[];
    selectedTime: any;
    mode?: ScheduleMode;
    modifyScheduleContent?: any;
    openCreateModal: (data: any) => void;
    getCalendarList: Function;
    sence?: string; // 场景，预约会议：appointment | 创建日程：create
    frozenCalendarId: string;
    selectedSession: Session;
    isMessageModule: boolean;
    getConferencePlatforms: Function;
    conferencePlatforms: any[]; // 会议类型列表
}
type Props = Readonly<WithTranslation & IProps & ChatState>;
export class CreateSchedule extends React.Component<Props, OwnState> {
    static contextType = GlobalContext;
    /* eslint complexity: ["error", 30] */
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        const { userData } = nextProps;
        let info = nextProps.modifyScheduleContent?.description || '';
        let meetingInfo = null;
        const index = info.indexOf('<p style="display:none;">');
        let descstr = index > -1 ? info.slice(0, index) : info;
        const getRemindText = (v: any) => {
            let { t } = nextProps;
            let text: any = {
                '0': t('no-remind'),
                '-0': t('when-begin'),
                '-5': t('schedule-start-minute').replace('%s', '5'),
                '-10': t('schedule-start-minute').replace('%s', '10'),
                '-15': t('schedule-start-minute').replace('%s', '15'),
            };
            text['-30'] = t('schedule-start-minute').replace('%s', '30');
            text['-60'] = t('开始前1小时');
            text[`-${60 * 2}`] = t('开始前2小时');
            text[`-${60 * 24}`] = t('开始前1天');
            text[`-${60 * 24 * 2}`] = t('开始前2天');

            text[`${60 * 9}`] = '当天 9:00';
            text[`-${60 * (24 - 9)}`] = '提前1天 9:00';
            text[`-${60 * (24 * 2 - 9)}`] = '提前2天 9:00';
            text[`-${60 * (24 * 7 - 9)}`] = '提前1周 9:00';

            return text[v] || '';
        };
        if (!nextProps.visible && prevState.visible) {
            // 关闭
            return {
                visible: false,
                title: '',
                beginDate: dayjs(),
                endDate: dayjs(),
                beginTime: dayjs(),
                endTime: dayjs().add(30, 'minute'),
                repeatEndTime: null,
                // beginDate: nextProps.beginTime || dayjs(),
                // endDate: nextProps.endTime || dayjs(),
                // beginTime: nextProps.beginTime || dayjs(),
                // endTime: nextProps.endTime || dayjs().add(1, 'hour'),
                isAllDay: false,
                repeat: 0,
                remind: RemindMode.Start0 + ',' + RemindMode.Start5, // 默认前15分钟提醒
                address: '',
                meetingCustom: {
                    room: null,
                    terminalId: [],
                },
                calendarId: '',
                calendarColor: '',
                description: descstr,
                remark: '',
                quickCreateFlag: nextProps.quickCreate,
                attachMentList: [],
                remindList: [RemindMode.Start0, RemindMode.Start5],
                participant: [],
                // TODO:创建人
                appointMeetingPass: '',
                meetingInfo: meetingInfo,
                canAppointment: true,
                hadMeeting: false,
                endTimeChangedByUser: false,
                meetingInfoChanged: false,
                scheduleCopy: null,
            };
        }
        if (nextProps.visible && !prevState.visible) {
            // 展示
            startTimeList = getStartTimeList();
            // getTimeWithFixedMinute(nextProps.beginTime || dayjs());
            let now = dayjs();
            // 如下操作是将传入的开始结束时间点进行转换，转化为今天的相对应 HH:mm，这里开始结束时间只关注小时分钟，不关注天
            let nowYYYYMMDD = nextProps.beginTime
                ? nextProps.beginTime.format('YYYY-MM-DD')
                : now.format('YYYY-MM-DD');
            let beginTimeHHmm = nextProps.beginTime
                ? nextProps.beginTime.format('HH:mm')
                : now.format('HH:mm');
            let endTimeHHmm = nextProps.endTime
                ? nextProps.endTime.format('HH:mm')
                : now.add(30, 'minute').format('HH:mm');

            let endTimeYYYYMMDD = nextProps.endTime
                ? nextProps.endTime.format('YYYY-MM-DD')
                : now.add(30, 'minute').format('YYYY-MM-DD');
            if (nextProps.frozenCalendarId && !nextProps.calendarMap[nextProps.frozenCalendarId]) {
                setTimeout(() => {
                    nextProps.getCalendarList?.();
                }, 1000);
            }
            if (nextProps.frozenCalendarId && !nextProps.calendarMap[nextProps.frozenCalendarId]) {
                setTimeout(() => {
                    nextProps.getCalendarList?.();
                }, 1000);
            }
            if (nextProps.mode !== ScheduleMode.Modify) {
                const initBeginTime = getTimeWithFixedMinute(
                    dayjs(`${nowYYYYMMDD} ${beginTimeHHmm}`)
                );
                const initEndTime = getTimeWithFixedMinute(
                    dayjs(`${endTimeYYYYMMDD} ${endTimeHHmm}`)
                ); // 只关注时分，不关注是哪一天。
                return {
                    visible: true,
                    title: nextProps.title
                        ? nextProps.title.length > 100
                            ? nextProps.title.substr(0, 100)
                            : nextProps.title
                        : '',
                    attachMentList: nextProps.messageResource
                        ? [decorateQuickCreateTaskResource(nextProps.messageResource)]
                        : [],
                    beginDate: nextProps.beginTime || dayjs(),
                    endDate: nextProps.endTime || dayjs(),
                    oldBeginTime: initBeginTime,
                    beginTime: initBeginTime,
                    endTime: initEndTime,
                    repeatEndTime: nextProps.repeatEndTime,
                    // endTimeList: getEndTimeList(nextProps.beginTime || now),
                    quickCreateFlag: nextProps.quickCreate,
                    canAppointment: true,
                    meetingInfo: null,
                    syncAppointMeeting: nextProps.defaultAppointment,
                    meetingPlatform:
                        nextProps.meetingPlatform || nextProps.conferencePlatforms[0]?.platformType,
                    meetingPlatformName:
                        nextProps.meetingPlatformName ||
                        nextProps.conferencePlatforms[0]?.platformName,
                    meetingCustom: {
                        room: null,
                        terminalId: [],
                    },
                    calendarId: nextProps.frozenCalendarId,
                    calendarColor: 'FF5EA7F8',
                    // TODO:创建人
                    ownerUser: [
                        {
                            userId: userData.userId,
                            teamId: userData.teamUserInfo.teamId,
                            app: userData.ddAppId,
                            headImg: userData.teamUserInfo.headImg || '',
                            realName: userData.teamUserInfo.realName || userData.account,
                        },
                    ],
                    // 拷贝一份作为退出确认比对用
                    scheduleCopy: {
                        title: nextProps.title
                            ? nextProps.title.length > 100
                                ? nextProps.title.substr(0, 100)
                                : nextProps.title
                            : '',
                        attachMentList: nextProps.messageResource
                            ? [decorateQuickCreateTaskResource(nextProps.messageResource)]
                            : [],
                        beginTime: initBeginTime,
                        endTime: initEndTime,
                        repeatEndTime: nextProps.repeatEndTime,
                        // endTimeList: getEndTimeList(nextProps.beginTime || now),
                        canAppointment: true,
                        meetingInfo: null,
                        syncAppointMeeting: nextProps.defaultAppointment,
                        appointMeetingPass: '',
                        calendarId: nextProps.frozenCalendarId,
                        isAllDay: false,
                        repeat: 0,
                        address: '',
                        calendarColor: 'FF5EA7F8',
                        description: descstr,
                        remark: '',
                        remindList: [RemindMode.Start0, RemindMode.Start5],
                        // TODO:创建人
                        ownerUser: [
                            {
                                userId: userData.userId,
                                teamId: userData.teamUserInfo.teamId,
                                app: userData.ddAppId,
                                headImg: userData.teamUserInfo.headImg || '',
                                realName: userData.teamUserInfo.realName || userData.account,
                            },
                        ],
                        participant: [],
                    },
                };
            } else {
                if (nextProps.modifyScheduleContent) {
                    let meetingobj = null;
                    let hadMeeting = false;
                    let meetingStrCopy = '';
                    let remark = nextProps.modifyScheduleContent.remark || '';
                    let ddAppId = nextProps.modifyScheduleContent.ddAppId;
                    let participant =
                        nextProps.modifyScheduleContent.participant?.map((item: any) => {
                            return {
                                ...item.user,
                                headImg: item.user.imageUrl,
                                app: ddAppId,
                                // app: nextProps.modifyScheduleContent.ddAppId,
                            };
                        }) || [];
                    let ownerUser =
                        [
                            {
                                ...nextProps.modifyScheduleContent.user,
                                headImg: nextProps.modifyScheduleContent.user.imageUrl,
                                app: ddAppId,
                            },
                        ] || [];
                    const index = info.indexOf('<p style="display:none;">');
                    if (index !== -1) {
                        descstr = info.slice(0, index);
                        let meetingstr = info.slice(index);
                        meetingStrCopy = meetingstr;
                        meetingstr = meetingstr
                            .replace('<p style="display:none;">', '')
                            .replace('</p>', '')
                            .trim();
                        if (meetingstr) {
                            meetingstr = meetingstr.replace(/[\r\n]/g, '');
                            const obj = JSON.parse(meetingstr);
                            if (obj.meetingId && obj.meetingType === 0 && obj.type === 'meeting') {
                                meetingobj = obj;
                                hadMeeting = true;
                            }
                        }
                    }
                    return {
                        visible: true,
                        title: nextProps.modifyScheduleContent.title,
                        beginDate:
                            nextProps.modifyScheduleContent.scheduleBeginTime ||
                            dayjs(nextProps.modifyScheduleContent.beginTime),
                        endDate:
                            nextProps.modifyScheduleContent.scheduleEndTime ||
                            dayjs(nextProps.modifyScheduleContent.endTime),
                        oldBeginTime:
                            nextProps.modifyScheduleContent.scheduleBeginTime ||
                            dayjs(nextProps.modifyScheduleContent.beginTime),
                        beginTime:
                            nextProps.modifyScheduleContent.scheduleBeginTime ||
                            dayjs(nextProps.modifyScheduleContent.beginTime),
                        endTime:
                            nextProps.modifyScheduleContent.scheduleEndTime ||
                            dayjs(dayjs(nextProps.modifyScheduleContent.endTime)),
                        repeatEndTime:
                            nextProps.modifyScheduleContent.modifyType === ModifyType.RepeatOne
                                ? null
                                : nextProps.modifyScheduleContent.repeatEndTime,
                        isAllDay: nextProps.modifyScheduleContent.isAlldate,
                        repeat:
                            nextProps.modifyScheduleContent.modifyType === ModifyType.RepeatOne
                                ? 0
                                : nextProps.modifyScheduleContent.repeat,
                        remindList: nextProps.modifyScheduleContent.remind
                            ? nextProps.modifyScheduleContent.remind
                                  .split(',')
                                  .sort((a: string, b: string) => {
                                      return Number(b) - Number(a);
                                  })
                                  .filter((item: any) => {
                                      return !!getRemindText(item);
                                  })
                            : [RemindMode.Start0, RemindMode.Start5], // 默认15分钟
                        address: nextProps.modifyScheduleContent.address,
                        calendarId: nextProps.modifyScheduleContent.calendarId,
                        // 编辑时初始值设置为当前的日程颜色，当为空时，会选用当前的日历颜色
                        // 默认显示后端返回的值20210406
                        calendarColor: nextProps.modifyScheduleContent.color,
                        // description: nextProps.modifyScheduleContent.description,
                        description: descstr,
                        attachMentList: nextProps.modifyScheduleContent.attachment,
                        meetingMinutes: nextProps.modifyScheduleContent.meetingMinutes,
                        participant: cloneDeep(participant),
                        // TODO: 这里需要返回创建人
                        ownerUser: ownerUser,
                        meetingPlatform:
                            meetingobj?.meetingPlatform ||
                            nextProps.conferencePlatforms[0]?.platformType,
                        meetingPlatformName:
                            meetingobj?.meetingPlatformName ||
                            nextProps.conferencePlatforms[0]?.platformName,
                        meetingCustom: {
                            room: null,
                            terminalId: [],
                        },
                        canAppointment: true,
                        // TODO: 暂时还不确定有没有修改视频信息的接口，如果是带视频会议的日程，只允许修改日程其它信息
                        syncAppointMeeting: meetingobj ? true : false,
                        appointMeetingPass: meetingobj?.password || '',
                        meetingInfo: meetingobj,
                        remark,
                        meetingStrCopy,
                        hadMeeting,
                        // 拷贝一份作为退出确认比对用
                        scheduleCopy: {
                            title: nextProps.modifyScheduleContent.title,
                            beginTime:
                                nextProps.modifyScheduleContent.scheduleBeginTime ||
                                dayjs(nextProps.modifyScheduleContent.beginTime),
                            endTime:
                                nextProps.modifyScheduleContent.scheduleEndTime ||
                                dayjs(dayjs(nextProps.modifyScheduleContent.endTime)),
                            isAllDay: nextProps.modifyScheduleContent.isAlldate,
                            repeat: nextProps.modifyScheduleContent.repeat,
                            remindList: nextProps.modifyScheduleContent.remind
                                ? nextProps.modifyScheduleContent.remind
                                      .split(',')
                                      .sort((a: string, b: string) => {
                                          return Number(b) - Number(a);
                                      })
                                : [RemindMode.Start0, RemindMode.Start5], // 默认15分钟
                            address: nextProps.modifyScheduleContent.address,
                            calendarId: nextProps.modifyScheduleContent.calendarId,
                            // 编辑时初始值设置为当前的日程颜色，当为空时，会选用当前的日历颜色
                            // 默认显示后端返回的值20210406
                            calendarColor: nextProps.modifyScheduleContent.color,
                            // description: nextProps.modifyScheduleContent.description,
                            description: descstr,
                            attachMentList: nextProps.modifyScheduleContent.attachment,
                            participant: participant,
                            // TODO: 这里需要返回创建人
                            ownerUser: ownerUser,
                            canAppointment: true,
                            // TODO: 暂时还不确定有没有修改视频信息的接口，如果是带视频会议的日程，只允许修改日程其它信息
                            syncAppointMeeting: meetingobj ? true : false,
                            appointMeetingPass: meetingobj?.password || '',
                            meetingInfo: meetingobj,
                            remark,
                        },
                    };
                }
                return null;
            }
        }
        return {};
    }
    state: OwnState;
    scheduleRef: React.RefObject<Input>;
    themeRef: any;
    scrollRef: any;
    meetingPasswordRef: any;
    calendarImService: CalendarImServiceType;
    constructor(props: Props) {
        super(props);
        this.scheduleRef = React.createRef();
        this.themeRef = React.createRef();
        this.meetingPasswordRef = React.createRef();
        this.calendarImService = CalendarImService.getInstance();
        const { quickCreate, beginTime, endTime, visible, userData, conferencePlatforms } = props;
        this.state = {
            addVisible: false,
            visible,
            title: '',
            beginDate: beginTime || dayjs(),
            endDate: endTime || dayjs(),
            oldBeginTime: beginTime || dayjs(),
            beginTime: beginTime || dayjs(),
            endTime: endTime || dayjs().add(30, 'minute'),
            repeatEndTime: null,
            isAllDay: false,
            repeat: 0,
            remind: RemindMode.Start0 + ',' + RemindMode.Start5, // 默认前5分钟提醒
            address: '',
            calendarId: '',
            calendarColor: '',
            description: '',
            remark: '',
            quickCreateFlag: quickCreate,
            participant: [],
            meetingCustom: {
                room: null,
                terminalId: [],
            },
            // TODO: 创建人
            ownerUser: [
                {
                    userId: userData.userId,
                    teamId: userData.teamUserInfo?.teamId,
                    app: userData?.ddAppId,
                    headImg: userData.teamUserInfo?.headImg || '',
                    realName: userData.teamUserInfo?.realName || userData.account,
                },
            ],
            endTimeList: [],
            attachMentList: [],
            remindList: [RemindMode.Start0, RemindMode.Start5],
            syncAppointMeeting: false,
            meetingPlatform: conferencePlatforms[0]?.platformType || '',
            meetingPlatformName: conferencePlatforms[0]?.platformName || '',
            appointMeetingPass: '',
            canAppointment: false,
            meetingInfo: null,
            noAPModalVisible: false,
            createClick: false,
            meetingStrCopy: '',
            hadMeeting: false,
            endTimeChangedByUser: false,
            meetingInfoChanged: false,
            scheduleCopy: null,
            checkUploadAttackment: true,
            // conferencePlatforms: [],
        };
        // this.openAddModal = this.openAddModal.bind(this);
    }

    componentDidUpdate(prevProps: any) {
        const { connectState } = this.props;
        // 更改加载日历的机制
        if (
            connectState &&
            connectState.state === ConnectState.READY &&
            prevProps.connectState.state !== ConnectState.READY
        ) {
            this.loadCalendar();
        }
    }
    componentDidMount() {
        this.props.getConferencePlatforms();
    }

    loadCalendar() {
        this.props.getCalendarList();
    }

    handleChangeAppointSwitch = (checked: boolean) => {
        this.setState({
            syncAppointMeeting: checked,
        });
        // if (checked) {
        //     this.setState({
        //         repeat: 0,
        //     });
        // }
    };

    handlechangeMeetingPass = (e: any) => {
        const value = e.target.value?.replace(/\s+/g, '');
        if (Number.isNaN(Number(value))) {
            return;
        }
        if (value.length > 6) {
            return;
        }
        this.setState({
            appointMeetingPass: value,
            meetingInfoChanged: true,
        });
    };

    openAddModal() {
        this.setState({
            addVisible: true,
        });
    }
    isScheduleEdited = () => {
        const { scheduleCopy } = this.state;
        let editedFlag = false;
        if (scheduleCopy) {
            const keys: ScheduleCopyKey[] = Object.keys(scheduleCopy) as ScheduleCopyKey[];
            // console.log('😂😄😄', scheduleCopy);
            // console.log('😂', keys);
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < keys.length; i++) {
                if (editedFlag) break;
                // console.log(i + ':', keys[i]);
                switch (keys[i]) {
                    case 'beginTime':
                    case 'endTime': {
                        if (
                            !(
                                scheduleCopy[keys[i]] &&
                                this.state[keys[i]] &&
                                scheduleCopy[keys[i]].valueOf() === this.state[keys[i]].valueOf()
                            )
                        ) {
                            editedFlag = true;
                        }
                        break;
                    }
                    case 'attachMentList': {
                        // console.log('scheduleCopy.' + keys[i] + '=', scheduleCopy[keys[i]]);
                        // console.log('this.state.' + keys[i] + '=', this.state[keys[i]]);
                        if (scheduleCopy[keys[i]].length !== this.state[keys[i]].length) {
                            editedFlag = true;
                        } else {
                            let sameFlag = true;
                            // eslint-disable-next-line @typescript-eslint/prefer-for-of
                            for (let j = 0; j < scheduleCopy[keys[i]].length; j++) {
                                let fileId = scheduleCopy[keys[i]][j].fileId;
                                let exsitFlag = false;
                                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                                for (let k = 0; k < this.state[keys[i]].length; k++) {
                                    if (this.state[keys[i]][k].fileId === fileId) {
                                        exsitFlag = true;
                                    }
                                }
                                if (!exsitFlag) {
                                    sameFlag = false;
                                    break;
                                }
                            }
                            if (!sameFlag) editedFlag = true;
                        }
                        break;
                    }
                    case 'remindList': {
                        // console.log('scheduleCopy.' + keys[i] + '=', scheduleCopy[keys[i]]);
                        // console.log('this.state.' + keys[i] + '=', this.state[keys[i]]);
                        if (scheduleCopy[keys[i]].length !== this.state[keys[i]].length) {
                            editedFlag = true;
                        } else {
                            // eslint-disable-next-line @typescript-eslint/prefer-for-of
                            for (let j = 0; j < scheduleCopy[keys[i]].length; j++) {
                                if (this.state[keys[i]].indexOf(scheduleCopy[keys[i]][j]) <= -1) {
                                    editedFlag = true;
                                    break;
                                }
                            }
                        }
                        break;
                    }
                    case 'ownerUser':
                    case 'participant': {
                        // console.log('scheduleCopy.' + keys[i] + '=', scheduleCopy[keys[i]]);
                        // console.log('this.state.' + keys[i] + '=', this.state[keys[i]]);
                        if (scheduleCopy[keys[i]].length !== this.state[keys[i]].length) {
                            editedFlag = true;
                        } else {
                            let sameFlag = true;
                            // eslint-disable-next-line @typescript-eslint/prefer-for-of
                            for (let j = 0; j < scheduleCopy[keys[i]].length; j++) {
                                let userId = scheduleCopy[keys[i]][j].userId;
                                let exsitFlag = false;
                                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                                for (let k = 0; k < this.state[keys[i]].length; k++) {
                                    if (this.state[keys[i]][k].userId === userId) {
                                        exsitFlag = true;
                                    }
                                }
                                if (!exsitFlag) {
                                    sameFlag = false;
                                    break;
                                }
                            }
                            if (!sameFlag) editedFlag = true;
                        }
                        break;
                    }
                    default: {
                        if (scheduleCopy[keys[i]] !== this.state[keys[i]]) {
                            // console.log('scheduleCopy.' + keys[i] + '=', scheduleCopy[keys[i]]);
                            // console.log('this.state.' + keys[i] + '=', this.state[keys[i]]);
                            editedFlag = true;
                        }
                        break;
                    }
                }
            }
        }
        return editedFlag;
    };
    closeHandle = (isDone?: boolean) => {
        const { userInfo, calendarMap, modifyScheduleContent, mode, userData } = this.props;
        if (this.isScheduleEdited() && !isDone) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            const { t } = this.props;
            const a = Modal.confirm({
                transitionName: 'notransition',
                maskTransitionName: 'notransition',
                maskStyle: { opacity: '0' },
                content:
                    mode === ScheduleMode.Modify && modifyScheduleContent
                        ? '退出编辑后将无法保存当前的更改，是否确认退出？'
                        : '退出后将无法保存当前的内容，是否确认退出？',
                okText: t('confirm'),
                cancelText: t('cancel'),
                onOk() {
                    that.props.openCreateModal({
                        content: null,
                        visible: false,
                    });
                    that.props.changeCreateModalVisible(false);
                    // 数据reset
                    that.setState({
                        appointMeetingPass: '',
                        syncAppointMeeting: false,
                        checkUploadAttackment: true,
                    });
                    bus.emit('Calendars:Attachment', true);
                    // document
                    //     .getElementsByClassName('ant-modal-root')[0]
                    //     ?.setAttribute('id', 'confirmmodal');
                    // const ele = document.getElementById('confirmmodal');
                    // if (ele) ele.style.display = 'none';
                },
            });
        } else {
            this.props.openCreateModal({
                content: null,
                visible: false,
            });
            this.props.changeCreateModalVisible(false);
            // 数据reset
            this.setState({
                appointMeetingPass: '',
                syncAppointMeeting: false,
                checkUploadAttackment: true,
            });
            // bus.emit('Calendars:Attachment', true);
        }
        // if (this.props.quickCreate) {
        //     this.setState({
        //         quickCreateFlag: true,
        //     });
        // }
    };

    titleChangeHandle = (e: any) => {
        const { t } = this.props;
        let title = e.target.value;
        this.setState({
            meetingInfoChanged: true,
            title,
        });
    };
    addressChangeHandle = (e: any) => {
        this.setState({
            address: e.target.value,
        });
    };

    descriptionChangeHandle = (e: any) => {
        let value = e.target.value;
        this.setState({
            description: value,
        });
    };

    remarkChangeHandle = (e: any) => {
        let value = e.target.value;
        this.setState({
            remark: value,
        });
    };

    handleChangeStart = (current: Dayjs) => {
        let isSameOrBefore = current.isSameOrBefore(this.state.endDate);
        if (!isSameOrBefore) {
            this.setState({
                beginDate: current,
                endDate: current,
            });
            if (current.isBefore(this.state.endDate)) {
                this.setState({
                    isAllDay: true,
                });
            } else {
                this.setState({
                    isAllDay: false,
                });
            }
        } else {
            this.setState({
                beginDate: current,
            });
        }
        startTimeList = getStartTimeList(current);
    };
    handleChangeEnd = (current: Dayjs) => {
        let isSameOrBefore = this.state.beginDate.isSameOrBefore(current);
        if (!isSameOrBefore) {
            if (this.state.beginDate.isBefore(current)) {
                this.setState({
                    isAllDay: true,
                });
            } else {
                this.setState({
                    isAllDay: false,
                });
            }
            this.setState({
                beginDate: current,
                endDate: current,
            });
        } else {
            this.setState({
                endDate: current,
            });
        }
    };

    handleSelectStart = (current: any, index: number) => {
        const { endTime, endDate, beginDate } = this.state;
        let isSameOrBefore = current.value.isSameOrBefore(endTime);
        // 重置endTimeList

        this.setState({
            endTimeList: this.getEndTimeList(current.value, beginDate, endDate),
        });

        if (!isSameOrBefore) {
            this.setState({
                beginTime: current.value,
                endTime: current.value.add(30, 'minute'),
            });
        } else {
            this.setState({
                beginTime: current.value,
            });
        }
    };

    handleSelectEnd = (current: any, index: number) => {
        let isSameOrBefore = this.state.beginTime.isSameOrBefore(current.value);
        let isSame = this.state.beginDate.isSame(this.state.endDate, 'date');
        if (!isSameOrBefore && isSame) {
            this.setState({
                beginTime: current.value.subtract(30, 'minute'),
                endTime: current.value,
            });
        } else {
            this.setState({
                endTime: current.value,
            });
        }
    };
    handleTimeChange = async (time: Dayjs | null, start = true, isAllDay?: boolean) => {
        const unix = time ? time : null;
        const { t } = this.props;
        if (!isAllDay) {
            if (start) {
                // if (this.state.endTime && this.state.endTime.isSameOrBefore(time)) {
                //     return message.warn(t('The start time must not be later than the deadline'));
                // }
                if (unix === this.state.beginTime) {
                    return;
                }
                this.setState({
                    meetingInfoChanged: true,
                    beginTime: unix,
                });
                if (
                    !this.state.endTimeChangedByUser ||
                    !this.state.endTime ||
                    (!!this.state.endTime &&
                        !!unix &&
                        unix?.valueOf() >= this.state.endTime.valueOf())
                ) {
                    this.setState({
                        endTime: unix?.add(30, 'minute') || this.state.endTime,
                    });
                }
                return;
            }
            if (unix === this.state.endTime) {
                return;
            }
            if (this.state.beginTime && this.state.beginTime.isSameOrAfter(time)) {
                return message.warn(t('The deadline must not be earlier than the start time'));
            }
            this.setState({
                meetingInfoChanged: true,
                endTime: unix,
                endTimeChangedByUser: true,
            });
            return;
        } else {
            if (start) {
                // if (this.state.endTime && this.state.endTime.isSameOrBefore(time)) {
                //     return message.warn(t('The start time must not be later than the deadline'));
                // }
                if (unix === this.state.beginTime) {
                    return;
                }
                this.setState({
                    meetingInfoChanged: true,
                    beginTime: unix,
                });
                if (
                    !this.state.endTimeChangedByUser ||
                    !this.state.endTime ||
                    (!!this.state.endTime &&
                        !!unix &&
                        unix?.valueOf() >= this.state.endTime.valueOf())
                ) {
                    this.setState({ endTime: unix?.add(1, 'day') || this.state.endTime });
                }
                return;
            }
            // if (dayjs(unix?.format('YYYY-MM-DD'))?.add(1, 'day').add(1, 'second')=== this.state.endTime) {
            //     return;
            // }
            if (this.state.beginTime && this.state.beginTime.isSameOrAfter(time)) {
                return message.warn(t('The deadline must not be earlier than the start time'));
            }
            this.setState({
                meetingInfoChanged: true,
                endTime: dayjs(unix?.format('YYYY-MM-DD'))?.add(1, 'day'),
                endTimeChangedByUser: true,
            });
            return;
        }
    };

    handleRepeatTimeChange = async (time: Dayjs | null) => {
        const unix = time ? time : null;
        const { t } = this.props;
        if (unix) {
            if (dayjs(unix).isBefore(dayjs(this.state.beginTime), 'date')) {
                return message.warn('重复截至日期不能早于日程开始日期');
            }
        }
        this.setState({
            repeatEndTime: unix,
        });
    };

    getMycalendar = () => {};

    getScheduleColor = () => {
        // 1.改变日历时，后面日程的颜色跟随变化(取日历颜色)，
        // 如果主动选择了日程颜色(即日程颜色与日历颜色不一样时，接口入参正常传)，
        // 如果选择的日程颜色与日历颜色一样时，接口入参不传日程颜色
        const { calendarMap, myCalendar } = this.props;
        const { calendarColor, calendarId } = this.state;
        // return (
        //     calendarColor ||
        //     getRealColorWithoutPre(calendarMap[calendarId || myCalendar.calendarId || '']?.color)
        // );
        // 现在日历没有颜色概念，当颜色为空时，设置默认颜色
        return calendarColor || scheduleTypeList[2].color;
        // const calendar = calendarMap[calendarId];
        // console.log('calendar', calendar);
        // if (calendar) {
        //     if (getRealColorWithoutPre(calendar.color) === calendarColor) {
        //         return null;
        //     }
        // }
        // return calendarColor;
    };

    handleSendCard = async (schedule: Schedule) => {
        const {
            scheduleId,
            calendarId,
            beginTime,
            title,
            endTime,
            user,
            agent,
            description,
        } = schedule;
        let { participant = [] } = schedule;
        const { modifyScheduleContent, userData } = this.props;
        const { teamUserInfo } = this.props.userData;
        const { realName = '', ddAppId = '' } = teamUserInfo;
        const params = { scheduleId, calendarId, beginTime, endTime, content: description };
        const deepLink =
            'jdme://jm/page_schedule_detail?mparam=' + encodeURIComponent(JSON.stringify(params));

        // 判断是我新建代建的日程，给被代建人发新建消息
        if (
            this.props.mode !== ScheduleMode.Modify &&
            agent &&
            user.userId &&
            user.userId !== this.props.userData.userId
        ) {
            const agentLangContents = [
                {
                    language: 'zh_CN',
                    title: '日程代建',
                    content: `${realName}为您代建的日程【${title}】, 请您查看`,
                },
                {
                    language: 'en_US',
                    title: 'ScheduleAgentCreate',
                    content: `${realName} agent create the schedule [${title}] for you, please check it out.`,
                },
            ];
            const toClientUser: { app: string; pin: string; teamId: string }[] = [
                {
                    app: user.ddAppId || this.props.userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                    pin: user.userId,
                    teamId: user.teamId,
                },
            ];
            sendCalendarCard({
                toUser: toClientUser,
                deepLink,
                languageContents: agentLangContents,
            });
        }

        // 判断是我代建的日程再次编辑，给被代建人发更新消息
        if (
            this.props.mode === ScheduleMode.Modify &&
            modifyScheduleContent.agent === Agent.AgentAllowed &&
            modifyScheduleContent.ownerUser?.userId &&
            modifyScheduleContent.ownerUser?.userId === userData.userId
        ) {
            const agentLangContents = [
                {
                    language: 'zh_CN',
                    title: '日程更新',
                    content: `${realName}编辑了日程【${title}】, 请您查看`,
                },
                {
                    language: 'en_US',
                    title: 'ScheduleUpdate',
                    content: `${realName} update the the schedule [${title}] for you, please check it out.`,
                },
            ];
            const toClientUser: { app: string; pin: string; teamId: string }[] = [
                {
                    app: modifyScheduleContent.user.ddAppId || userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                    pin: modifyScheduleContent.user.userId,
                    teamId: modifyScheduleContent.user.teamId,
                },
            ];
            sendCalendarCard({
                toUser: toClientUser,
                deepLink,
                languageContents: agentLangContents,
            });
        }

        // 判断是被代建人自己编辑，需要通知代建人，给代建人发消息
        if (
            this.props.mode === ScheduleMode.Modify &&
            modifyScheduleContent.agent === Agent.AgentAllowed &&
            modifyScheduleContent.user?.userId &&
            modifyScheduleContent.user?.userId === userData.userId &&
            modifyScheduleContent.ownerUser?.userId
        ) {
            const agentLangContents = [
                {
                    language: 'zh_CN',
                    title: '日程更新',
                    content: `${realName}编辑了日程【${title}】, 请您查看`,
                },
                {
                    language: 'en_US',
                    title: 'ScheduleUpdate',
                    content: `${realName} update the the schedule [${title}] for you, please check it out.`,
                },
            ];
            const toAgentUser: { app: string; pin: string; teamId: string }[] = [
                {
                    app: modifyScheduleContent.ownerUser.ddAppId || userData.team.ddAppId, // 暂不考虑多租户场景,使用当前登陆人的ddAppId
                    pin: modifyScheduleContent.ownerUser.userId,
                    teamId: modifyScheduleContent.ownerUser.teamId,
                },
            ];
            participant =
                participant?.filter((item) => {
                    return item.user.userId !== modifyScheduleContent.ownerUser.userId;
                }) || [];

            sendCalendarCard({
                toUser: toAgentUser,
                deepLink,
                languageContents: agentLangContents,
            });
        }

        if (!participant || participant.length === 0) {
            return;
        }
        // 给参与人发消息
        const toUser = (participant as any)
            .filter((p: any) => p.resStatus !== ResStatus.Reject)
            .map((p: any) => ({
                app: p.user.appId || p.user.ddAppId || ddAppId || '',
                pin: p.user.userId,
                teamId: p.user.teamId,
            }));
        const languageContents =
            this.props.mode === ScheduleMode.Modify // 判断是否为编辑场景
                ? modifyScheduleContent.agent === Agent.AgentAllowed &&
                  modifyScheduleContent.ownerUser?.userId === userData.userId // 判断是否为代建场景[
                    ? [
                          {
                              language: 'zh_CN',
                              title: '日程更新',
                              content: `${modifyScheduleContent.user?.realName}编辑了日程【${title}】, 请您查看`,
                          },
                          {
                              language: 'en_US',
                              title: 'SchedulUpdate',
                              content: `${modifyScheduleContent.user?.realName} update the schedule [${title}], please check it out.`,
                          },
                      ]
                    : [
                          {
                              language: 'zh_CN',
                              title: '日程更新',
                              content: `${realName}编辑了日程【${title}】, 请您查看`,
                          },
                          {
                              language: 'en_US',
                              title: 'SchedulUpdate',
                              content: `${realName} update the schedule [${title}], please check it out.`,
                          },
                      ]
                : agent === Agent.AgentAllowed && user.userId // 判断是否为代建场景
                ? [
                      {
                          language: 'zh_CN',
                          title: '日程创建',
                          content: `${user.realName}创建的日程【${title}】, 请您查看`,
                      },
                      {
                          language: 'en_US',
                          title: 'ScheduleCreate',
                          content: `${user.realName} created the schedule [${title}], please check it out.`,
                      },
                  ]
                : [
                      {
                          language: 'zh_CN',
                          title: '日程创建',
                          content: `${realName}创建的日程【${title}】, 请您查看`,
                      },
                      {
                          language: 'en_US',
                          title: 'ScheduleCreate',
                          content: `${realName} created the schedule [${title}], please check it out.`,
                      },
                  ];
        sendCalendarCard({ toUser, deepLink, languageContents });
    };

    async addSchedule(isCreateSession?: boolean) {
        this.setState({
            createClick: true,
        });
        try {
            const { userInfo, calendarMap, modifyScheduleContent, mode, userData } = this.props;
            const { teamUserInfo, userId } = userData;
            const { syncAppointMeeting, appointMeetingPass, hadMeeting, meetingInfo } = this.state;
            const { myCalendar, t } = this.props;
            if (!this.state.title.trim()) {
                // title 不能为空
                throw new Error(t('schedule title not empty'));
            }
            // 注释掉参与人不能为空限制20210330
            // if (!this.state.participant.length) {
            //     message.warn({
            //         content: t('participant not empty'),
            //     });
            //     return;
            // }

            let beginTime;
            let endTime;
            if (this.state.beginTime && this.state.endTime) {
                beginTime = dayjs(`${this.state.beginTime.format('YYYY-MM-DD HH:mm')}`).valueOf();
                endTime = dayjs(`${this.state.endTime.format('YYYY-MM-DD HH:mm')}`).valueOf();
            } else {
                this.setState({
                    createClick: false,
                });
                message.error('请填写日程起止时间。');
                return;
            }
            let repeatEndTime = null;
            if (this.state.repeatEndTime) {
                repeatEndTime = dayjs(
                    `${dayjs(this.state.repeatEndTime).format('YYYY-MM-DD 23:59')}`
                ).valueOf();
            }

            // 附件
            if (!this.state.checkUploadAttackment) {
                this.setState({
                    createClick: false,
                });
                message.error(t('attachment is being uploaded'));
                return;
            }

            // 不允许重复日程创建信创会议
            if (this.state.repeat !== 0 && this.state.meetingPlatform === 'HW') {
                this.setState({
                    createClick: false,
                });
                message.error(t('not allowed to add meeting repeated schedule'));
                return;
            }

            // 提醒
            let remind = !this.state.remindList.length
                ? RemindMode.No
                : this.state.remindList.filter((item) => item !== RemindMode.No).join(',');
            const scheduleData: any = {
                title: this.state.title.trim(),
                beginTime: beginTime,
                endTime: endTime,
                user: {
                    teamId: userData?.team?.teamId,
                    userId: userData?.userId,
                },
                remind: remind || RemindMode.No,
                color: this.getScheduleColor() || null,
                repeat: this.state.repeat,
                repeatBeginTime: beginTime,
                repeatEndTime: repeatEndTime,
                meetingEnable: !!this.state.syncAppointMeeting,
                description: this.state.description,
                remark: this.state.remark,
                address: this.state.address,
                isAlldate: Number(this.state.isAllDay),
                groupId: '',
                calendarId: this.state.calendarId ? this.state.calendarId : myCalendar.calendarId,
                participant: this.state.participant,
                // TODO: 创建人 ownerUser
                content: '',
                attachment: this.state.attachMentList,
                ddAppId: userData.teamUserInfo.ddAppId,
            };
            let newParticipant = this.state.participant.map((item: any) => {
                return { user: { ...item } };
            });
            scheduleData.participant = newParticipant;

            if (syncAppointMeeting) {
                if (appointMeetingPass.length !== 0 && appointMeetingPass.length !== 6) {
                    throw new Error(t('meetingPassLabel'));
                }
                // 在线会议不再判断是否是当前之前创建
                if (dayjs(scheduleData.beginTime).isSameOrBefore(dayjs())) {
                    throw new Error(t('do not all appoint before'));
                }
            }

            if (mode === ScheduleMode.Modify && modifyScheduleContent) {
                // 修改
                let modifyType = ModifyType.Update;
                scheduleData.meetingMinutes = modifyScheduleContent.meetingMinutes;
                scheduleData.scheduleId = modifyScheduleContent.scheduleId;
                scheduleData.calendarType = modifyScheduleContent.calendarType;
                scheduleData.calendarId = modifyScheduleContent.calendarId;
                // TODO:这里可能需要对历史颜色做处理，看产品需求
                scheduleData.color = this.state.calendarColor || modifyScheduleContent.color;
                scheduleData.beforeBeginTime = modifyScheduleContent.scheduleBeginTime.valueOf();
                scheduleData.beforeEndTime = modifyScheduleContent.scheduleEndTime.valueOf();

                scheduleData.user = modifyScheduleContent.user;
                scheduleData.agent = modifyScheduleContent.agent;
                scheduleData.remarkId = modifyScheduleContent.remarkId;
                if (!modifyScheduleContent.modifyType) {
                    // 变更方式 1. 重复-只更新单条 2. 重复-从当前时间更新 3. 更新（不重复的更新，由不重复到重复的更新） 4. 仅支持时间更新
                    modifyType = ModifyType.Update;
                } else {
                    modifyType = modifyScheduleContent.modifyType;
                }
                // 参与者信息修改
                let participant = newParticipant.map((item: any) => {
                    return { resStatus: 0, ...item };
                });
                scheduleData.participant = participant;
                // TODO: 创建人修改ownerUser
                scheduleData.modifyType = modifyType;
                if (syncAppointMeeting) {
                    const platform: conferencePlatformsProp = this.props.conferencePlatforms.find(
                        (platform) => platform.platformType === this.state.meetingPlatform
                    );
                    const meetingPlatformName =
                        platform?.platformName || this.state.meetingPlatformName;
                    let originDescription = scheduleData.description;
                    if (meetingInfo?.meetingId && meetingInfo?.meetingUuid) {
                        scheduleData.description =
                            originDescription +
                            `<p style="display:none;">{"type":"meeting","meetingType":0,"meetingId":"${meetingInfo.meetingId}","meetingUuid":"${meetingInfo.meetingUuid}","password":"${appointMeetingPass}","meetingPlatform":"${this.state.meetingPlatform}","meetingPlatformName":"${meetingPlatformName}"} </p>`;
                    } else {
                        scheduleData.description =
                            originDescription +
                            `<p style="display:none;">{"type":"meeting","meetingType":0,"meetingId":"","meetingUuid":"","password":"${appointMeetingPass}","meetingPlatform":"${this.state.meetingPlatform}","meetingPlatformName":"${meetingPlatformName}"} </p>`;
                    }
                }
                const [data, err]: any = await modifySchedule(scheduleData);
                if (err) {
                    console.error(err);
                    throw new Error(err);
                }
                // 触发一个事件 告知群组是否需要更新日程详情显示数据
                bus.emit('chat:group:refreshScheduleInfo');
                this.handleSendCard({
                    ...scheduleData,
                    scheduleId: data.scheduleId,
                    description: data.description,
                });
                this.reloadSchedule();
                this.closeHandle(true);
            } else {
                if (syncAppointMeeting) {
                    const platform: conferencePlatformsProp = this.props.conferencePlatforms.find(
                        (platform) => platform.platformType === this.state.meetingPlatform
                    );
                    const meetingPlatformName =
                        platform?.platformName || this.state.meetingPlatformName;
                    scheduleData.description =
                        scheduleData.description +
                        `<p style="display:none;">{"type":"meeting","meetingType":0,"meetingId":"","meetingUuid":"","password":"${appointMeetingPass}","meetingPlatform":"${this.state.meetingPlatform}","meetingPlatformName":"${meetingPlatformName}"} </p>`;
                }
                // 代建场景
                if (this.state.ownerUser[0].userId !== userData.userId) {
                    scheduleData.user = {
                        userId: this.state.ownerUser[0].userId,
                        teamId: this.state.ownerUser[0].teamId,
                        realName: this.state.ownerUser[0].realName,
                    };
                    scheduleData.agent = Agent.AgentAllowed;
                }
                const [data, err]: any = await addSchedule(scheduleData);
                if (err) {
                    console.error(err);
                    throw new Error(err);
                }

                if (data.scheduleId) {
                    this.handleSendCard({
                        ...scheduleData,
                        scheduleId: data.scheduleId,
                        description: data.description,
                    });
                }
                if (isCreateSession) {
                    await this.handleShareClick({
                        ...scheduleData,
                        scheduleId: data.scheduleId,
                        calendarId: data.calendarId,
                        description: data.description,
                    });
                }
                this.reloadSchedule();
                this.closeHandle(true);
                message.success(t('created-successfully'));
            }
            this.setState({
                createClick: false,
            });
        } catch (error) {
            this.setState({
                createClick: false,
            });
            message.error(error.message || error);
        }
    }
    handleShareClick = async (schedule: Schedule) => {
        const { myCalendar, t, calendarMap } = this.props;
        const projectId =
            calendarMap[this.state.calendarId || myCalendar.calendarId]?.extended?.projectId;
        const { teamUserInfo, userId } = this.props.userData;
        const { ddAppId, teamId, realName } = teamUserInfo;
        const sharelink = {
            ext: ``,
            icon:
                'http://storage.jd.com/jd.jme.photo/message_icon_schedule2x.png?Expires=3687851871&AccessKey=93c0d2d5a6cf315c3d4c52c5f549a9a886b59f76&Signature=eADExHDPxJlqpqGBhAWpXUo6nmw%3D',
            // source: 'joyWork',
            title: t('share card title').replace('%s', realName),
            content: `${schedule.title}\n开始时间:${dayjs(schedule.beginTime).format(
                'YYYY-MM-DD HH:mm'
            )}\n结束时间:${dayjs(schedule.endTime).format('YYYY-MM-DD HH:mm')}`,
            url: DeeplinkEvent.strify({
                appId: ddAppId,
                path: DeeplinkPathEnum.Schedule_Draw,
                mparam: {
                    projectId: projectId || '',
                    content: schedule.description,
                    scheduleId: schedule.scheduleId,
                    beginTime: schedule.beginTime,
                    endTime: schedule.endTime,
                    isNeedCheck: true,
                    calendarId: schedule.calendarId,
                    color: schedule.color,
                },
            }),
        };
        const msg = {
            type: ChatMessageType.TEMPLATE2,
            template: {
                nativeId: 'share_link',
            },
            code: 0,
            data: [
                {
                    sharelink: sharelink,
                    type: 0,
                },
            ],
        };
        const messageId = generateMessageId();
        this.context.imService.sendChatMessage(
            this.props.selectedSession?.['sessionId'] || '',
            messageId,
            msg
        );
        const [toUserId] = this.props.selectedSession?.sessionId.split(':');
        if (schedule.scheduleId) {
            await shareScheduleAttachment({
                bizId: schedule.scheduleId,
                bizType: 2,
                shareRecipientDtos: [
                    {
                        recipientType: this.props.selectedSession?.isGroup ? '2' : '1',
                        recipientId: toUserId,
                        recipientName: this.props.selectedSession?.info?.name,
                        teamId: this.context?.authInfo?.teamUserInfo?.teamId,
                        app: this.context?.authInfo?.teamUserInfo?.ddAppId,
                    },
                ],
            });
        }
    };

    reloadSchedule = () => {
        const {
            selectedTime,
            selectedCalendarList,
            getScheduleListByTime,
            setSelectedCalendarList,
            myCalendar,
        } = this.props;
        bus.emit('calendar:reload', { calendarId: this.state.calendarId, beginTime: selectedTime });
        // 当日历列表无选中项(视图为空)时，不去调用接口查询新数据
        if (selectedCalendarList.length > 0) {
            return getScheduleListByTime({
                calendarId: selectedCalendarList.join(','),
                beginTime: selectedTime.subtract(ScheduleDateScope * 24, 'hour'),
                endTime: selectedTime.add(ScheduleDateScope * 24, 'hour'),

                // beginTime: dayjs(
                //     `${this.state.beginDate.format('YYYY-MM-DD')} ${this.state.beginTime.format(
                //         'HH:mm'
                //     )}`
                // ).startOf('date'),
                // endTime: dayjs(
                //     `${this.state.endDate.format('YYYY-MM-DD')} ${this.state.endTime.format('HH:mm')}`
                // ).endOf('date'),
            });
        } else {
            let newList = [...selectedCalendarList];
            if (selectedCalendarList?.indexOf(myCalendar.calendarId) !== -1) {
                // 表示删除
                newList = selectedCalendarList.filter((i: any) => {
                    return i !== myCalendar.calendarId;
                });
            } else {
                // 表示添加
                newList = [myCalendar.calendarId, ...selectedCalendarList];
            }
            return setSelectedCalendarList({
                data: newList,
            });
        }
    };
    //
    repeatHandleChange = (value: number) => {
        this.setState({
            repeat: value,
            repeatEndTime: value === 0 ? null : this.state.repeatEndTime,
        });
        // if (value !== 0) {
        //     this.setState({
        //         appointMeetingPass: '',
        //         syncAppointMeeting: false,
        //     });
        // }
    };

    mettingTypeChange = (value: MeetingPlatform) => {
        this.setState({
            meetingPlatform: value,
        });
    };

    remindHandleChange = (value: RemindMode, index: number) => {
        // this.setState({
        //     remind: value,
        // });
        this.setState((state) => {
            return {
                remindList: [
                    ...state.remindList.slice(0, index),
                    value,
                    ...state.remindList.slice(index + 1),
                ],
            };
        });
    };

    calendarIdHandleChange = (v: string) => {
        log.info(
            'calendarIdHandleChange',
            v,
            getRealColorWithoutPre(this.props.calendarMap[v]?.color)
        );
        this.setState({
            calendarId: v,
            // 日历颜色已去掉，所以这里暂时不做处理
            calendarColor: getRealColorWithoutPre(this.props.calendarMap[v]?.color) || '',
        });
    };

    calendarColorHandleChange = (v: string) => {
        this.setState({
            calendarColor: v,
        });
    };
    userPickerChange = (resultList: any[]) => {
        let newList = resultList.map((item) => {
            return {
                user: {
                    teamId: item.teamId,
                    userId: item.userId,
                    appId: item.app,
                    headImg: item.avatar,
                    realName: item.realName,
                    ddAppId: item.app,
                },
            };
        });
        this.setState({
            participant: newList || [],
        });
    };
    attachmentChange = (data: any) => {
        let checkUpload = true;
        let newAttachment = data.map((item: any) => {
            if (item.status !== FileStatus.Done) {
                checkUpload = false;
            }
            return {
                fileName: item.fileName,
                fileType: item.fileType,
                fileId: item.fileId,
                fileUrl: item.fileUrl,
                fileSize: item.fileSize,
                extended: item.extended || '',
                permission: item.permission || null,
                status: item.status,
            };
        });
        this.setState({
            attachMentList: newAttachment,
            checkUploadAttackment: checkUpload,
        });
        // let list = Object.keys(data).map((key) => data[key]);
    };
    getDefaultParticipantList = () => {
        const { mode, modifyScheduleContent } = this.props;
        if (mode === ScheduleMode.Modify && modifyScheduleContent) {
            return modifyScheduleContent.participant?.map((item: any) => {
                return {
                    ...item.user,
                    avatar: item.user.imageUrl,
                };
            });
        } else {
            return [];
        }
    };
    getDefaultAttachmentList = () => {
        const { mode, modifyScheduleContent } = this.props;
        if (mode === ScheduleMode.Modify && modifyScheduleContent) {
            return modifyScheduleContent.attachment?.map((item: any) => {
                return {
                    ...item,
                    status: FileStatus.Done,
                };
            });
        } else {
            return this.state.attachMentList || [];
        }
    };
    // startTime开始时间 beginDate 开始日期 endDate: 结束日期
    getEndTimeList = (startTime: dayjs.Dayjs, beginDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
        // 获取startTime在startTimeList中的位置
        const { t } = this.props;
        let fixedTime = getTimeWithFixedMinute(startTime); // 得到想要的HH:mm
        // 根基 beginDate得到相对应的日期
        let ymd = beginDate.format('YYYY-MM-DD');
        let hm = fixedTime.format('HH:mm');
        fixedTime = dayjs(`${ymd} ${hm}`);
        startTimeList = getStartTimeList(beginDate);
        let idx = startTimeList.findIndex((a: any) => a.title === fixedTime.format('HH:mm'));
        let diffDay = 0;
        let minutesToNow = 0;
        // 如果是跨天的话，index从0 开始
        if (beginDate && endDate && dayjs.isDayjs(beginDate) && dayjs.isDayjs(endDate)) {
            if (!beginDate.isSame(endDate, 'date')) {
                idx = 0;
                minutesToNow = fixedTime.diff(fixedTime.startOf('day'), 'minute');
                minutesToNow = Math.abs(minutesToNow);
                fixedTime = beginDate.startOf('day');
            }
            diffDay = this.state.endDate
                .startOf('day')
                .diff(this.state.beginDate.startOf('day'), 'day');
        }
        if (idx >= 0 && idx < startTimeList.length) {
            let endList = startTimeList.slice(idx);
            return endList.map((item) => {
                let diff = item.value.diff(fixedTime, 'minute') + 1;
                // 如果是跨天的
                diff = Math.abs(diff) + diffDay * 24 * 60 - minutesToNow;
                // let hour = Math.floor(diff / 60);
                let hour =
                    Math.floor(diff % 60) + timeInterval - 1 === 60
                        ? Math.floor(diff / 60) + 1
                        : Math.floor(diff / 60);
                let mintue =
                    Math.floor(diff % 60) + timeInterval - 1 === 60
                        ? ''
                        : Math.floor(diff % 60) + timeInterval - 1;
                let hourText = hour ? `${hour}${t('hours')}` : '';
                let minuteText = mintue ? `${mintue}${t('mintues')}` : '';
                return {
                    value: item.value,
                    title: `${item.title}(${hourText}${minuteText})`,
                };
            });
        }
        return startTimeList;
    };
    addRemind = () => {
        this.setState((state) => {
            return {
                remindList: [...state.remindList, RemindMode.No],
            };
        });
    };
    removeRemind = (index: number) => {
        const { remindList } = this.state;
        this.setState((state) => {
            return {
                remindList: [
                    ...state.remindList.slice(0, index),
                    ...state.remindList.slice(index + 1),
                ],
            };
        });
    };
    timePickerVisible = () => {
        this.setState((state) => {
            return {
                endTimeList: this.getEndTimeList(state.beginTime, state.beginDate, state.endDate),
            };
        });
    };
    handleUpdateCreator = async () => {
        const { t, userData, calendarList, myCalendar } = this.props;
        const { userId, teamUserInfo } = userData;
        const { ddAppId, teamId } = teamUserInfo;
        const isGroup = this.props.selectedSession?.['isGroup'];
        const sessionId = this.props.selectedSession?.['sessionId'];
        let canAgentForData: any[] = [];
        calendarList.forEach((element: any) => {
            let exist = false;
            for (let lItem of canAgentForData) {
                if (lItem.userId === element.user.userId) {
                    exist = true;
                }
            }
            if (element.agent === Agent.AgentAllowed && !exist) {
                canAgentForData.push({
                    ...element.user,
                    id: element.user.userId,
                    avatar: element.user.imageUrl,
                    name: element.user.realName,
                    calendarId: element.calendarId,
                });
            }
        });
        let agentForData = canAgentForData;
        if (agentForData.length > 0) {
            agentForData.push({
                ...myCalendar.user,
                id: myCalendar.user.userId,
                avatar: myCalendar.user.imageUrl,
                name: myCalendar.user.realName + '(' + t('self') + ')',
                calendarId: myCalendar.calendarId,
            });
            openUserSelectionModal(
                {
                    title: t('select-creator'),
                    tabs: ['agentFor'],
                    single: true,
                    recommendParam: {
                        keyWords: this.state?.title || '',
                        aiType: ['empl'],
                    },
                    contactsFilterFlag: 'external',
                    currentUser: {
                        app: ddAppId,
                        pin: userId,
                        teamId: teamId,
                    },
                    businessTab: {
                        tabName: '群组成员',
                        tabParam: {
                            businessType: isGroup ? 'group' : 'project',
                            businessId: isGroup ? (sessionId ? sessionId : '') : '',
                        },
                    },
                    resultUsers: lodashCompact(this.state.ownerUser || []).map((item) => {
                        let obj = {
                            id: item.userId,
                            name: item.realName,
                            avatar: item.headImg,
                            checked: true,
                            userId: item.userId,
                            teamId: item.teamId,
                            visible: false,
                        };
                        if (item.app) obj.id = obj.id + ':' + item.app;
                        if (item.teamId) obj.id = obj.id + ':' + item.teamId;
                        return obj;
                    }),
                    propAgentForData: agentForData,
                },
                (data: any, close: any) => {
                    analysisLog('xtbg_calhome_1640073907694', '23');
                    // 过滤掉已选中的
                    const { result } = data.data;
                    if (!result.length) {
                        return close();
                    }
                    const addArr: any[] = [];
                    result.forEach((r: any) => {
                        if (r.id) {
                            const strs = r.id.split(':');
                            if (strs.length > 1) {
                                return addArr.push({
                                    userId: strs[0],
                                    teamId: strs[2],
                                    app: strs[1],
                                    ddAppId: strs[1],
                                    headImg: r.avatar,
                                    realName: r.name,
                                });
                            } else {
                                return addArr.push({
                                    ...r,
                                    headImg: r.avatar,
                                    app: ddAppId,
                                });
                            }
                        }
                        const { origin } = r;
                        if (origin.info) {
                            addArr.push({
                                userId: origin.info.userId,
                                teamId: origin.info.teamId,
                                app: origin.info.appId || origin.info.app,
                                ddAppId: origin.info.appId || origin.info.app,
                                headImg: origin.info.headImg,
                                realName: origin.info.realName,
                            });
                        } else {
                            addArr.push({
                                userId: origin.userId,
                                teamId: origin.teamId,
                                app: origin.app,
                                ddAppId: origin.app,
                                headImg: origin.headImg,
                                realName: origin.realName,
                            });
                        }
                    });
                    let { userId, calendarId } = addArr[0];
                    this.setState({ ownerUser: addArr, calendarId });
                    close();
                },
                userData
            );
        } else if (agentForData.length <= 0) {
            this.setState({ noAPModalVisible: true });
        }
    };
    handleAddParticipant = (data: any[], custom: any) => {
        const temp: any[] = [];
        const { participant } = this.state;
        data.forEach((m: any) => {
            const index = findIndexExecutorInTask(participant, m);
            if (index === -1) {
                temp.push(m);
            }
        });
        if (temp.length === 0 && this.state.meetingPlatform !== MeetingPlatform.HW) {
            return;
        }
        // if ([...participant, ...temp]?.length > 200) {
        //     message.warn(t('200 person limit'));
        //     return;
        // }
        this.setState({ participant: [...participant, ...temp], meetingCustom: custom });
    };
    handleDelParticipant = (members: any[]) => {
        const participant = this.state.participant;
        members.forEach((m) => {
            const index = findIndexExecutorInTask(participant, m);
            if (index > -1) {
                participant.splice(index, 1);
            }
        });
        // 这里需要深拷贝
        this.setState({ participant: [...participant] });
    };

    // 开始时间早于当前时间，弹出提示框
    checkBeginTimeAddSchedule(isCreateSession?: boolean) {
        if (
            this.state.repeatEndTime &&
            dayjs(this.state.repeatEndTime).isBefore(dayjs(this.state.beginTime), 'date')
        ) {
            return message.warn('重复截至日期不能早于日程开始日期');
        }
        let beginTime = new Date(this.state.beginTime).getTime();
        let oldBeginTime = new Date(this.state.oldBeginTime).getTime();
        let nowDate = Date.now();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        if (beginTime !== oldBeginTime && beginTime < nowDate && !this.state.syncAppointMeeting) {
            Modal.confirm({
                content: '是否创建一个过去时间的日程？',
                okText: this.props.t('confirm'),
                cancelText: this.props.t('cancel'),
                onOk() {
                    that.addSchedule(isCreateSession);
                },
            });
        } else {
            this.addSchedule(isCreateSession);
        }
    }

    // eslint-disable-next-line complexity
    render() {
        const { calendarList, myCalendar, calendarMap, mode, t } = this.props;
        const calendarListCanCreateSchedule = calendarList.filter(
            (item: any) =>
                item.userType === UserType.Creator ||
                item.userType === UserType.TeamEditor ||
                item.userType === UserType.TeamManager
        );
        const { appRuntimeEnv } = this.context;
        // 日程颜色
        // const scheduleColor =
        //     this.state.calendarColor ||
        //     getRealColorWithoutPre(
        //         calendarMap[this.state.calendarId || this.state.calendarId || '']?.color
        //     );
        // 现在日历没有颜色概念，当颜色为空时，设置默认颜色
        const scheduleColor = (
            this.state.calendarColor || scheduleTypeList[2].color
        ).toLocaleUpperCase();
        let noAgentPermissionModal = (
            <Modal
                className="noap-modal-wrapper"
                title="代建日程"
                centered
                onCancel={() => this.setState({ noAPModalVisible: false })}
                width={403}
                footer={null}
                visible={this.state.noAPModalVisible}
            >
                <p className="title">您暂无权限为他人代建日程</p>
                <p className="info">
                    如您需要替他人创建日程，必须他人在个人日历的设置页添加您为共享日历成员，并设置允许您代建日程。
                </p>
            </Modal>
        );
        if (this.state.quickCreateFlag && this.props.visible) {
            return (
                <BasicModal
                    visible={true}
                    title={t('create-new-schedule')}
                    className="create-schedule-drawer-wrapper"
                    width={443}
                    cancelText={t('more-option')}
                    okText={t('create')}
                    onOk={debounce(() => {
                        this.addSchedule();
                    }, 500)}
                    onCancel={this.closeHandle}
                    cancelButtonProps={{
                        onClick: () => {
                            this.setState({
                                quickCreateFlag: false,
                            });
                        },
                    }}
                    getContainer={document.getElementById('calendar-page-container')}
                >
                    <div className="df aic basic-line">
                        <p className="left-indicator-wrapper">{t('title')}</p>
                        <CopyFrame>
                            <Input
                                onChange={(e) => this.titleChangeHandle(e)}
                                maxLength={100}
                                placeholder={t('add-theme')}
                                value={this.state.title}
                                style={{ flex: 1 }}
                                className="calendar-custom-input schedule-title-input"
                            />
                        </CopyFrame>
                    </div>
                    <div className="df aic basic-line">
                        <p className="left-indicator-wrapper">{t('time')}</p>
                        {/* <Checkbox
                            onChange={(e) => {
                                this.setState({
                                    isAllDay: e.target.checked,
                                });
                            }}
                            checked={this.state.isAllDay}
                        >
                            <p className="inline-block">全天</p>
                        </Checkbox> */}
                        {/* <Radio
                            className="calendar-custom-radio"
                            onClick={() => {
                                this.setState((state) => {
                                    return {
                                        isAllDay: !state.isAllDay,
                                    };
                                });
                            }}
                            checked={this.state.isAllDay}
                        />
                        <p className="inline-block mr8">{t('all-day')}</p> */}
                        <div className="df" style={{ flex: 1 }}>
                            <div className="df">
                                <CalendarDatePicker
                                    time={this.state.beginTime || null}
                                    isAllDay={this.state.isAllDay}
                                    onChange={(time: Dayjs | null) => this.handleTimeChange(time)}
                                    placeholder={t('set start time')}
                                    isDateFormat={true}
                                />
                            </div>

                            <span
                                className="df"
                                style={{
                                    width: 15,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                -
                            </span>
                            <div className="df">
                                <CalendarDatePicker
                                    time={this.state.endTime || null}
                                    isAllDay={this.state.isAllDay}
                                    onChange={(time: Dayjs | null) =>
                                        this.handleTimeChange(time, false)
                                    }
                                    placeholder={t('set end time')}
                                    isDateFormat={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="df aic basic-line">
                        <p className="left-indicator-wrapper" />
                        <Select
                            disabled={mode === ScheduleMode.Modify && this.state.repeat !== 0}
                            defaultValue={this.state.repeat}
                            style={{ width: 240 }}
                            onChange={this.repeatHandleChange}
                            className="calendar-custom-select"
                        >
                            <Option value={0}>{t('no-repeat')}</Option>
                            <Option value={1}>{t('Every working day')}</Option>
                            <Option value={2}>{t('Every day')}</Option>
                            <Option value={3}>{t('Every week')}</Option>
                            <Option value={4}>{t('Every two weeks')}</Option>
                            <Option value={5}>{t('Every month')}</Option>
                            <Option value={6}>{t('Every year')}</Option>
                        </Select>
                    </div>
                    <div className="df aic basic-line">
                        <p className="left-indicator-wrapper">{t('schedule type')}</p>
                        <ScheduleTypeSelect
                            // 当calendarColor为空时应该选择当前选中的日历颜色
                            value={scheduleColor}
                            isMultiply={false}
                            showTooltip={false}
                            handleSelect={(c) => this.calendarColorHandleChange(c)}
                        />
                    </div>
                    <div className="df aic basic-line calendar-schedule-color-line">
                        <p className="left-indicator-wrapper">
                            <CalendarIcon src={changeEgovUrl(calendarPng)} width={15} height={15} />
                        </p>
                        <Select
                            disabled={mode === ScheduleMode.Modify}
                            placeholder={t('my-calendar')}
                            style={{ width: 391, marginRight: 8 }}
                            onChange={this.calendarIdHandleChange}
                            className="calendar-custom-select"
                            // defaultValue={myCalendar.calendarId}
                            value={this.state.calendarId || myCalendar.calendarId}
                        >
                            {/* {Object.keys(this.props.calendarMap).map((key) => {
                                return (
                                    <Option key={key} value={key}>
                                        {this.props.calendarMap[key].title}
                                    </Option>
                                );
                            })} */}
                            {calendarListCanCreateSchedule.map((item: any, index: number) => {
                                return (
                                    <Option key={index} value={item.calendarId}>
                                        <span>{item.title}</span>
                                        {item.extended?.projectId && (
                                            <span className="schedule-label-zhuanban">
                                                {t(
                                                    `project${
                                                        ['mebj', 'jgswy', 'mebjJZB'].includes(
                                                            appRuntimeEnv.runtimeConfig.key
                                                        )
                                                            ? '_zhuanban'
                                                            : ''
                                                    }`
                                                )}
                                            </span>
                                        )}
                                    </Option>
                                );
                            })}
                        </Select>
                        <ColorPicker
                            value={scheduleColor}
                            handleSelect={(c) => this.calendarColorHandleChange(c)}
                        />
                    </div>
                </BasicModal>
            );
        } else if (this.props.visible) {
            return (
                <Drawer
                    destroyOnClose={true}
                    footer={
                        <>
                            <Button
                                className="calendar-schedule-btn"
                                size="middle"
                                onClick={() => {
                                    this.closeHandle();
                                }}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                className="calendar-schedule-btn"
                                size="middle"
                                loading={this.state.createClick}
                                type="primary"
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|28"
                                onClick={debounce(() => {
                                    this.checkBeginTimeAddSchedule();
                                }, 500)}
                            >
                                {t('confirm')}
                                {/* {mode === ScheduleMode.Modify ? t('save') : t('create')} */}
                            </Button>
                            {this.props.selectedSession && (
                                <Button
                                    className="calendar-schedule-btn"
                                    size="middle"
                                    type="primary"
                                    onClick={debounce(() => {
                                        this.checkBeginTimeAddSchedule(true);
                                    }, 500)}
                                    clstag={`pageclick|keycount|${
                                        this.props.isMessageModule
                                            ? 'Xtbg_Msg_SendToIM|MessSche'
                                            : 'xtbg_calhome_1640073907694|42'
                                    }`}
                                >
                                    {t('create_and_send_to_message')}
                                </Button>
                            )}
                        </>
                    }
                    afterVisibleChange={(visible) => {
                        if (visible) {
                            this.themeRef?.current?.focus();
                        }
                    }}
                    getContainer={
                        document.getElementById('calendar-page-container') ||
                        (document.querySelector('.appContent') as any)
                    }
                    placement="right"
                    title={
                        mode === ScheduleMode.Modify ? t('edit-schedule') : t('create-new-schedule')
                    }
                    visible={true}
                    width={450}
                    onClose={() => {
                        this.closeHandle();
                    }}
                    className="create-schedule-drawer-wrapper"
                >
                    <ScrollView ref={this.scrollRef}>
                        <CopyFrame>
                            <div
                                className="df aic basic-line basic-line-small-space"
                                style={{ marginLeft: 10, marginRight: 10 }}
                            >
                                {/* <Input
                                onChange={(e) => this.titleChangeHandle(e)}
                                placeholder={t('add-theme')}
                                value={this.state.title}
                                style={{ flex: 1 }}
                                maxLength={100}
                                className="calendar-custom-input schedule-title-input"
                            /> */}
                                <BaseTextArea
                                    ref={this.themeRef}
                                    placeholder={t('add-theme')}
                                    style={{ flex: 1 }}
                                    autoSize={{ minRows: 1 }}
                                    value={this.state.title}
                                    maxLength={100}
                                    showCount={true}
                                    onKeyPress={(e) => {
                                        if (this.state.title.length >= 100) {
                                            message.destroy();
                                            return message.warn(t('title limit 100'));
                                        }
                                    }}
                                    className="calendar-custom-textarea schedule-textarea"
                                    onChange={(e) => this.titleChangeHandle(e)}
                                />
                            </div>
                        </CopyFrame>
                        <div className="df aic basic-line">
                            <p className="left-indicator-wrapper">
                                <span className="required">*</span>
                                {t('creator')}
                            </p>
                            <div className="creator">
                                <WorkUserList
                                    disable={!!this.state.ownerUser.length}
                                    list={this.state.ownerUser}
                                    maxLength={8}
                                    addMemberCb={this.handleUpdateCreator}
                                    title={this.state.title}
                                    label="participant"
                                    isShowName={true}
                                    notAvatar={true}
                                    notTooltip={true}
                                />
                                {/* TODO: 默认是自己，下个版本实现代建 */}
                                {/* TODO: 新建可选人代建，编辑状态不可修改被代建人 */}
                                {this.state.ownerUser.length && mode !== ScheduleMode.Modify ? (
                                    <div
                                        className="update-creator"
                                        onClick={this.handleUpdateCreator}
                                        clstag="pageclick|keycount|xtbg_calhome_1640073907694|23"
                                    >
                                        <span>更改</span>&nbsp;
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {/* 参与人 */}
                        <div className="df aic basic-line">
                            <p className="left-indicator-wrapper">
                                {/* 注释掉参与人不能为空限制 */}
                                {/* <span className="required">*</span> */}
                                {t('participant')}
                            </p>
                            <div
                                className={`participant-content ${
                                    this.state.participant.length > 0
                                        ? 'participant-content-data'
                                        : ''
                                }`}
                            >
                                <WorkUserList
                                    platform={this.state.meetingPlatform}
                                    list={this.state.participant}
                                    maxLength={7}
                                    handleAddTaskMembers={this.handleAddParticipant}
                                    onRemove={this.handleDelParticipant}
                                    label="participant"
                                    projectId={
                                        calendarMap[this.state.calendarId || myCalendar.calendarId]
                                            ?.extended?.projectId || ''
                                    }
                                    sessionId={
                                        this.props.selectedSession
                                            ? this.props.selectedSession.sessionId
                                            : undefined
                                    }
                                    isGroup={
                                        this.props.selectedSession
                                            ? this.props.selectedSession.isGroup
                                            : undefined
                                    }
                                    title={this.state.title}
                                    isShowName={true}
                                    notAvatar={true}
                                    notTooltip={true}
                                />
                                {this.state.participant.length > 0 && (
                                    <UserStatusListPopover
                                        list={this.state.participant}
                                        D
                                        onRemove={this.handleDelParticipant}
                                        label="participant"
                                    >
                                        <IconFont
                                            type="iconlog_enter"
                                            className="icon-more icon-toggle participant-icon-more"
                                            style={{ color: '#8f959e' }}
                                        />
                                    </UserStatusListPopover>
                                )}
                            </div>
                        </div>
                        {/* 新的日程类型 */}
                        <div className="df aic basic-line">
                            <p className="left-indicator-wrapper">
                                <span className="required">*</span>
                                <label style={{ letterSpacing: '14px' }}>
                                    {t('schedule type')}
                                </label>
                            </p>
                            <ScheduleTypeSelect
                                // 当calendarColor为空时应该选择当前选中的日历颜色
                                value={scheduleColor}
                                isMultiply={false}
                                showTooltip={false}
                                handleSelect={(c) => this.calendarColorHandleChange(c)}
                            />
                        </div>
                        {/* 时间 */}
                        <div className="df aic basic-line">
                            <p className="left-indicator-wrapper" style={{ letterSpacing: '14px' }}>
                                <span className="required">*</span>
                                {t('time')}
                            </p>
                            <div className="df" style={{ flex: 1 }}>
                                <div className="df calendar-date" style={{ flex: 1 }}>
                                    <CalendarDatePicker
                                        isAllDay={this.state.isAllDay}
                                        time={this.state.beginTime || null}
                                        onChange={(time: Dayjs | null) =>
                                            this.handleTimeChange(time, true, this.state.isAllDay)
                                        }
                                        placeholder={t('set start time')}
                                        isDateFormat={true}
                                        isChangeHeaderVisible={true}
                                    />
                                </div>

                                <span
                                    className="df"
                                    style={{
                                        width: 15,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#e8ebec',
                                    }}
                                >
                                    -
                                </span>
                                <div className="df calendar-date" style={{ flex: 1 }}>
                                    <CalendarDatePicker
                                        isAllDay={this.state.isAllDay}
                                        time={
                                            this.state.isAllDay
                                                ? this.state.endTime?.subtract(1, 'minute')
                                                : this.state.endTime || null
                                        }
                                        onChange={(time: Dayjs | null) =>
                                            this.handleTimeChange(time, false, this.state.isAllDay)
                                        }
                                        placeholder={t('set end time')}
                                        isDateFormat={true}
                                        isChangeHeaderVisible={true}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 全天 */}
                        <div className="df aic basic-line">
                            <p className="left-indicator-wrapper" />
                            <div className="df" style={{ flex: 1 }}>
                                <Checkbox
                                    className="calendar-custom-radio"
                                    onClick={(e) => {
                                        let rmdList: any[] = [];
                                        if (!this.state.isAllDay) {
                                            let beginTime = dayjs(
                                                this.state.beginTime.format('YYYY-MM-DD')
                                            );
                                            let endTime = dayjs(
                                                this.state.beginTime.format('YYYY-MM-DD')
                                            ).add(1, 'day');
                                            rmdList.push(`${60 * 9}`);
                                            this.setState({
                                                isAllDay: !this.state.isAllDay,
                                                remindList: rmdList,
                                                beginTime: this.state.beginTime,
                                                endTime: this.state.endTime,
                                                endTimeChangedByUser: false,
                                            });
                                        } else {
                                            // rmdList.push('-15');
                                            rmdList.push(`${60 * 9}`);
                                            this.setState({
                                                isAllDay: !this.state.isAllDay,
                                                remindList: rmdList,
                                                endTimeChangedByUser: false,
                                                beginTime: this.state.beginTime,
                                                endTime: this.state.endTime,
                                            });
                                        }
                                    }}
                                    checked={this.state.isAllDay}
                                >
                                    <label className="time-type" style={{ fontSize: '13px' }}>
                                        全天
                                    </label>
                                </Checkbox>
                            </div>
                        </div>
                        {/* 重复 */}
                        {this.state.meetingPlatform !== MeetingPlatform.HW && (
                            <div className="df aic basic-line">
                                <p
                                    className="left-indicator-wrapper"
                                    style={{ letterSpacing: '14px' }}
                                >
                                    {t('repeat')}
                                </p>
                                <div
                                    className="repeat-setting"
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'space-around',
                                        lineHeight: '32px',
                                    }}
                                >
                                    <Select
                                        defaultValue={this.state.repeat}
                                        showArrow={true}
                                        suffixIcon={
                                            <IconFont
                                                type="iconicon_down"
                                                className="anticon-down icon-more icon-toggle"
                                            />
                                        }
                                        style={{
                                            width: this.state.repeat === 0 ? '100%' : '120px',
                                        }}
                                        onChange={this.repeatHandleChange}
                                        className="calendar-custom-select calendar-custom-select-arrow"
                                    >
                                        <Option value={0}>{t('no-repeat')}</Option>
                                        <Option value={1}>{t('Every working day')}</Option>
                                        <Option value={2}>{t('Every day')}</Option>
                                        <Option value={3}>{t('Every week')}</Option>
                                        <Option value={4}>{t('Every two weeks')}</Option>
                                        <Option value={5}>{t('Every month')}</Option>
                                        <Option value={6}>{t('Every year')}</Option>
                                    </Select>
                                    {this.state.repeat !== 0 && (
                                        <div className="repeat-setting-endtime">
                                            <CalendarDatePicker
                                                isAllDay={true}
                                                isRepeatPicker={true}
                                                time={
                                                    this.state.repeatEndTime
                                                        ? dayjs(this.state.repeatEndTime)
                                                        : null
                                                }
                                                onChange={(time: Dayjs | null) => {
                                                    console.log('time', time);

                                                    this.handleRepeatTimeChange(time);
                                                }}
                                                placeholder={t('yo valid')}
                                                isDateFormat={true}
                                                isChangeHeaderVisible={true}
                                                isEnd={true}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {/* 线上会议 */}
                        {config[ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT] &&
                            this.props.conferencePlatforms.length > 0 &&
                            (isFocusEnv() ||
                                this.state.meetingPlatform === MeetingPlatform.JBRTC) &&
                            this.state.canAppointment && (
                                <div className="df aic basic-line">
                                    <p className="left-indicator-wrapper">{t('online metting')}</p>
                                    <Input
                                        maxLength={6}
                                        placeholder={t('meetingPassLabel')}
                                        onChange={this.handlechangeMeetingPass}
                                        className={`calendar-custom-input calendar-meeting-input
                                            ${
                                                this.state.syncAppointMeeting
                                                    ? 'calendar-meeting-input-interactive'
                                                    : ''
                                            }`}
                                        ref={this.meetingPasswordRef}
                                        // disabled={this.state.syncAppointMeeting ? false : true}
                                        disabled={!this.state.syncAppointMeeting}
                                        value={
                                            this.state.syncAppointMeeting
                                                ? this.state.appointMeetingPass
                                                : ''
                                        }
                                        suffix={
                                            <Switch
                                                checked={this.state.syncAppointMeeting}
                                                className="calendar-meeting-switch"
                                                onChange={(checked) => {
                                                    this.handleChangeAppointSwitch(checked);
                                                }}
                                            />
                                        }
                                        allowClear
                                    />
                                </div>
                            )}
                        {/* 会议类型 */}
                        {this.state.syncAppointMeeting &&
                            this.props.conferencePlatforms.length > 1 && (
                                <div className="df aic basic-line">
                                    <p className="left-indicator-wrapper">{t('metting type')}</p>
                                    <div className="metting-type">
                                        <Select
                                            style={{ width: '100%' }}
                                            defaultValue={
                                                this.state.meetingPlatform ||
                                                this.props.conferencePlatforms[0]?.platformType
                                            }
                                            showArrow={true}
                                            suffixIcon={
                                                <IconFont
                                                    type="iconicon_down"
                                                    className="anticon-down icon-more icon-toggle"
                                                />
                                            }
                                            onChange={this.mettingTypeChange}
                                            className="calendar-custom-select calendar-custom-select-arrow"
                                        >
                                            {this.props.conferencePlatforms.map(
                                                (
                                                    platform: conferencePlatformsProp,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={platform.platformType}
                                                        >
                                                            {platform.platformName}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </div>
                                </div>
                            )}
                        <div className="module-cross-line" />
                        {/* 提醒 */}
                        <div className="df aic basic-line remind-select-wrapper">
                            <p className="left-indicator-wrapper" style={{ letterSpacing: '14px' }}>
                                {t('remind')}
                            </p>
                            <Select
                                mode="multiple"
                                allowClear={false}
                                showArrow={true}
                                suffixIcon={
                                    <IconFont
                                        type="iconicon_down"
                                        className="anticon-down icon-more icon-toggle"
                                    />
                                }
                                showSearch={false}
                                value={this.state.remindList}
                                className="calendar-custom-multiple-select calendar-custom-select-arrow"
                                onChange={(val: any, opt: any) => {
                                    // eslint-disable-next-line no-param-reassign
                                    val = val.sort((a: string, b: string) => {
                                        return Number(b) - Number(a);
                                    });
                                    if (val.length > 0) {
                                        if (!val.includes('0')) {
                                            this.setState({
                                                remindList: val,
                                            });
                                        } else {
                                            let remindListCopy: any[] = this.state.remindList;
                                            if (remindListCopy.includes('0')) {
                                                let newVal = val.filter((item: any) => {
                                                    return item !== '0';
                                                });
                                                this.setState({
                                                    remindList: newVal,
                                                });
                                            } else {
                                                let remindList: any[] = [];
                                                remindList.push('0');
                                                this.setState({
                                                    remindList: remindList,
                                                });
                                            }
                                        }
                                    } else {
                                        let remindList: any[] = [];
                                        remindList.push('0');
                                        this.setState({
                                            remindList: remindList,
                                        });
                                    }
                                }}
                            >
                                <Option value="0">{t('no-remind')}</Option>
                                {!this.state.isAllDay && (
                                    <>
                                        <Option value="-0">{t('when-begin')}</Option>
                                        <Option value="-5">
                                            {t('schedule-start-minute').replace('%s', '5')}
                                        </Option>
                                        <Option value="-10">
                                            {t('schedule-start-minute').replace('%s', '10')}
                                        </Option>
                                        <Option value="-15">
                                            {t('schedule-start-minute').replace('%s', '15')}
                                        </Option>
                                        <Option value="-30">
                                            {t('schedule-start-minute').replace('%s', '30')}
                                        </Option>
                                        <Option value="-60">{t('开始前1小时')}</Option>
                                        <Option value={`-${60 * 2}`}>{t('开始前2小时')}</Option>
                                        <Option value={`-${60 * 24}`}>
                                            {t('开始前1天')}
                                            {/* {this.state.beginTime
                                            ? '(' + dayjs(this.state.beginTime).format('HH:mm') + ')'
                                            : ''} */}
                                        </Option>
                                        <Option value={`-${60 * 24 * 2}`}>
                                            {t('开始前2天')}
                                            {/* {this.state.beginTime
                                            ? '(' + dayjs(this.state.beginTime).format('HH:mm') + ')'
                                            : ''} */}
                                        </Option>
                                    </>
                                )}
                                {this.state.isAllDay && (
                                    <>
                                        <Option value={`${60 * 9}`}>当天 9:00</Option>
                                        <Option value={`-${60 * (24 - 9)}`}>提前1天 9:00</Option>
                                        <Option value={`-${60 * (24 * 2 - 9)}`}>
                                            提前2天 9:00
                                        </Option>
                                        <Option value={`-${60 * (24 * 7 - 9)}`}>
                                            提前1周 9:00
                                        </Option>
                                    </>
                                )}
                            </Select>
                        </div>
                        {/* 日程地点 */}
                        <CopyFrame>
                            <div className="df aic basic-line description-line">
                                <p
                                    className="left-indicator-wrapper"
                                    style={{
                                        alignSelf: 'flex-start',
                                        marginTop: '5px',
                                        letterSpacing: '14px',
                                    }}
                                >
                                    {t('address')}
                                </p>
                                <BaseTextArea
                                    style={{ flex: 1 }}
                                    autoSize={{ minRows: 1 }}
                                    placeholder={t('calendar please input')}
                                    value={this.state.address}
                                    maxLength={50}
                                    showCount={true}
                                    className="calendar-custom-textarea schedule-textarea cursor-outline"
                                    onChange={(e) => this.addressChangeHandle(e)}
                                    onBlur={(e) => {
                                        let value = this.state.address;
                                        if (value.trim() && value.trim().length > 50) {
                                            value = value.slice(0, 50);
                                            this.setState({ address: value });
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (this.state.address.length >= 50) {
                                            message.destroy();
                                            return message.warn('日程地址最多可输入50字');
                                        }
                                    }}
                                />
                            </div>
                        </CopyFrame>
                        {/* 日程描述 */}
                        <CopyFrame>
                            <div className="df aic basic-line description-line">
                                <p
                                    className="left-indicator-wrapper"
                                    style={{
                                        alignSelf: 'flex-start',
                                        marginTop: '5px',
                                        letterSpacing: '14px',
                                    }}
                                >
                                    {t('calendar description')}
                                </p>
                                <BaseTextArea
                                    style={{ flex: 1 }}
                                    autoSize={{ minRows: 1 }}
                                    placeholder={t('calendar please input')}
                                    value={this.state.description}
                                    maxLength={500}
                                    showCount={true}
                                    className="calendar-custom-textarea schedule-textarea cursor-outline"
                                    onChange={(e) => this.descriptionChangeHandle(e)}
                                    onBlur={(e) => {
                                        let value = this.state.description;
                                        if (value.trim() && value.trim().length > 500) {
                                            value = value.slice(0, 500);
                                            this.setState({ description: value });
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (this.state.description.length >= 500) {
                                            message.destroy();
                                            return message.warn('日程描述最多可输入500字');
                                        }
                                    }}
                                />
                                {/* </div> */}
                            </div>
                        </CopyFrame>
                        <div className="module-cross-line" />
                        {/* 附件 */}
                        <div className="df aic basic-line">
                            <div className="left-indicator-wrapper left-indicator-toggle">
                                <Attachment
                                    titleName="日程附件"
                                    className="calendar-attachment"
                                    defaultList={this.getDefaultAttachmentList()}
                                    onChange={this.attachmentChange}
                                    mitileType={true}
                                    LocalFile={true}
                                />
                            </div>
                        </div>
                        {/* 分割线 */}
                        <div className="module-cross-line" />
                        {/* TODO: 我的备注 */}
                        <CopyFrame>
                            <div
                                className="df aic basic-line remark-line"
                                style={{ flexDirection: 'column' }}
                            >
                                <div className="left-indicator-wrapper left-indicator-toggle">
                                    <Collapse
                                        defaultActiveKey={['1']}
                                        expandIconPosition="right"
                                        bordered={false}
                                        expandIcon={() => (
                                            <IconFont
                                                type="iconicon_down"
                                                className="icon-toggle icon-toggle-remark"
                                            />
                                        )}
                                        onChange={(aKey) => {
                                            let svg = document.getElementsByClassName(
                                                'icon-toggle-remark'
                                            )[0]?.firstElementChild;
                                            if (aKey.length > 0) {
                                                svg?.setAttribute(
                                                    'style',
                                                    'transform: rotate(-180deg)'
                                                );
                                            } else {
                                                svg?.setAttribute(
                                                    'style',
                                                    'transform: rotate(0deg)'
                                                );
                                            }
                                        }}
                                    >
                                        <Collapse.Panel
                                            header={
                                                <>
                                                    {t('my remark')}
                                                    <label className="sub-title">
                                                        {t('remark visible')}
                                                    </label>
                                                </>
                                            }
                                            key="1"
                                        >
                                            <BaseTextArea
                                                style={{
                                                    flex: 1,
                                                    color: '#232930',
                                                    marginTop: '9px',
                                                }}
                                                // placeholder={t('add--my-remark')}
                                                autoSize={{ minRows: 1 }}
                                                value={this.state.remark}
                                                maxLength={500}
                                                className="calendar-custom-textarea schedule-textarea cursor-outline"
                                                onChange={(e) => this.remarkChangeHandle(e)}
                                                onBlur={(e) => {
                                                    let value = this.state.remark;
                                                    if (value.trim() && value.trim().length > 500) {
                                                        value = value.slice(0, 500);
                                                        this.setState({ remark: value });
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (this.state.remark.length >= 500) {
                                                        message.destroy();
                                                        return message.warn(
                                                            '日程备注最多可输入500字'
                                                        );
                                                    }
                                                }}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </CopyFrame>
                        <div className="df aic basic-line add-user">
                            <p className="left-indicator-wrapper">{t('participant')}</p>
                            <UserPicker
                                projectId={
                                    calendarMap[this.state.calendarId || myCalendar.calendarId]
                                        ?.extended?.projectId || ''
                                }
                                sessionId={
                                    this.props.selectedSession
                                        ? this.props.selectedSession.sessionId
                                        : undefined
                                }
                                isGroup={
                                    this.props.selectedSession
                                        ? this.props.selectedSession.isGroup
                                        : undefined
                                }
                                defaultList={this.getDefaultParticipantList()}
                                onChange={this.userPickerChange}
                            />
                        </div>
                        {/* 注释原来的日历、日程颜色 */}
                        <div className="df aic basic-line calendar-schedule-color-line">
                            <p className="left-indicator-wrapper">
                                <CalendarIcon
                                    src={changeEgovUrl(calendarPng)}
                                    width={15}
                                    height={15}
                                />
                            </p>
                            <Select
                                disabled={
                                    mode === ScheduleMode.Modify || !!this.props.frozenCalendarId
                                }
                                placeholder={t('my-calendar')}
                                style={{ flex: 1, marginRight: 8 }}
                                onChange={this.calendarIdHandleChange}
                                className="calendar-custom-select"
                                // defaultValue={myCalendar.calendarId}
                                value={
                                    this.props.frozenCalendarId ||
                                    this.state.calendarId ||
                                    myCalendar.calendarId
                                }
                            >
                                {calendarListCanCreateSchedule.map((item: any, index: number) => {
                                    if (
                                        this.props.frozenCalendarId &&
                                        this.props.frozenCalendarId !== item.calendarId
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <Option key={index} value={item.calendarId}>
                                            <span>{item.title}</span>
                                            {item.extended?.projectId && (
                                                <span className="schedule-label-zhuanban">
                                                    {t(
                                                        appRuntimeEnv?.runtimeConfig.key.includes(
                                                            'mebj'
                                                        )
                                                            ? 'project_zhuanban'
                                                            : 'project'
                                                    )}
                                                </span>
                                            )}
                                        </Option>
                                    );
                                })}
                            </Select>
                            <ColorPicker
                                // 当calendarColor为空时应该选择当前选中的日历颜色
                                value={scheduleColor}
                                handleSelect={(c) => this.calendarColorHandleChange(c)}
                            />
                        </div>
                        {noAgentPermissionModal}
                    </ScrollView>
                </Drawer>
            );
        } else {
            return null;
        }
    }
}
function mapStateToProps({ user, calendar, chat }: any) {
    return {
        connectState: chat.connectState,
        userInfo: user.userInfo, // 不可用
        userData: user.userData.user,
        calendarMap: calendar.calendarMap,
        selectedCalendarList: calendar.selectedCalendarList,
        calendarList: calendar.calendarList,
        selectedTime: calendar.selectedTime,
        myCalendar: calendar.myCalendar,
        visible: calendar.createModalVisible,
        modifyScheduleContent: calendar.modifyScheduleContent,
        mode: calendar.createModalMode,
        quickCreate: calendar.createModalQuickCreate,
        beginTime: calendar.createModalBeginTime,
        endTime: calendar.createModalEndTime,
        title: calendar.createModalTitle,
        messageResource: calendar.createModalResource,
        frozenCalendarId: calendar.createModalFrozenCalendarId,
        selectedSession: calendar.createModalSelectedSession,
        defaultAppointment: calendar.createModalDefaultAppointment,
        meetingPlatform: calendar.meetingPlatform,
        isMessageModule: chat.isMessageModule,
        conferencePlatforms: calendar.conferencePlatforms,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getScheduleListByTime(data: {
            user?: User;
            calendarId?: string;
            beginTime: Dayjs;
            endTime: Dayjs;
        }) {
            dispatch({ type: 'calendar/getScheduleListByTime', payload: data });
        },
        setSelectedCalendarList(data: any) {
            dispatch({ type: 'calendar/setSelectedCalendarList', payload: data });
        },
        openCreateModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
        getCalendarList(data: any) {
            dispatch({ type: 'calendar/getCalendarList', payload: data });
        },
        changeCreateModalVisible(visible: boolean) {
            dispatch({
                type: 'calendar/changeCreateModalVisible',
                payload: { visible: visible },
            });
        },
        getConferencePlatforms() {
            dispatch({ type: 'calendar/getConferencePlatforms' });
        },
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('calendar')(CreateSchedule));
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(withTranslation('calendar')(ChangeCalendar));
