import { GatewayStage } from './types';
const { REACT_APP_IM_WS_HOST, REACT_APP_JANUS_HOST } = process.env;

// 默认应用名称
export const APP_ID = 'ee';
// 467307770673238016 focus-desktop端app id
// 默认server服务
export const IM_SERVER_PIN = '@im.jd.com';
// 默认服务端租户
export const IM_SERVER_TEAM = 'jd.com';
// 默认web应用
// export const CLIENT_TYPE = ClientType.COMET;
// 默认应用版本
export const VERSION = '4.3';
// 默认语言
export const DEFAULT_LANG = 'zh_CN';
// 服务地址
export const WS_SERVER = REACT_APP_IM_WS_HOST;
// 使用TLS
export const USE_TLS = true;

// 网关url地址
export const GATEWAY_BASE = REACT_APP_JANUS_HOST;

// gateway 应用ID focus.im.sdk
export const GATEWAY_APP_ID = '467307770673238016';
// export const GATEWAY_APP_ID = '468467382424309760';

// Focus 桌面端 -- new
// AppId：468467382424309760
export const SECRETKEY = '3e8af81e1d354ef589b387cd67ba31eb';

// 网关版本
export const GATEWAY_VERSION = 2;
export const GATEWAY_STAGE: GatewayStage = GatewayStage.PROD;
