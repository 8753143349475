import { Button, Modal } from 'antd';
import styles from './index.module.less';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'dva';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChatState from '@/types/chat/State';
import ImService from '@/server/ImService';

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { migrateVisiable, loadAllSessionListEnd } = chat;
    return { migrateVisiable, loadAllSessionListEnd };
}
function mapDispatchToProps(dispatch: any) {
    return {
        setMigrateVisiable(data: boolean) {
            dispatch({ type: 'chat/setMigrateVisiable', payload: { migrateVisiable: data } });
        },
        setDbVersion(data: string) {
            dispatch({ type: 'chat/setDbVersion', payload: { dbVersion: data } });
        },
    };
}

interface MigrateModalProp {
    setMigrateVisiable: Function;
    setDbVersion: Function;
}
type IProps = Readonly<MigrateModalProp & ChatState & WithTranslation>;

enum STEP_TYPE {
    INIT = 'init',
    PROCESS = 'process',
    SUCESS = 'success',
    FAILED = 'failed',
}

const STEP_TIP = {
    init: '数据迁移提醒',
    process: '数据迁移进行中…',
    failed: '迁移失败，请重试',
    success: `迁移成功，您可正常使用${process.env.REACT_APP_NAME}`,
};

function MigrateModal(props: IProps) {
    const { migrateVisiable, loadAllSessionListEnd, setDbVersion, setMigrateVisiable } = props;
    const [step, setStep] = useState<STEP_TYPE>(STEP_TYPE.INIT);
    const messageCount = useRef<number>(0);
    const [currProgress, setCurrProgress] = useState<string>('0%');

    useEffect(() => {
        if (!loadAllSessionListEnd) {
            return;
        }
        const imService = ImService.getInstance();
        imService?.getAppModuleVersion('DB').then(async (ver) => {
            if (!ver) {
                const count = await imService?.getMessageCount();
                if (count && count > 0) {
                    messageCount.current = count;
                    setMigrateVisiable(true);
                    imService?.setAppVersion(`2|0`, 'DB');
                } else {
                    imService?.setAppVersion(`2|0`, 'DB').then(() => {
                        setDbVersion(`2|0`);
                    });
                }
            } else {
                setDbVersion(ver);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadAllSessionListEnd]);

    const handleDelay = useCallback(() => {
        const ver = `-1|${messageCount.current}`;
        ImService.getInstance()
            ?.setAppVersion(ver, 'DB')
            .then(() => {
                setMigrateVisiable(false);
                setDbVersion(ver);
            });
    }, [setMigrateVisiable, setDbVersion]);

    const handleMigrate = useCallback(() => {
        setStep(STEP_TYPE.PROCESS);
        const imService = ImService.getInstance();
        imService
            ?.migrateDB({
                onStart: () => {
                    setCurrProgress('0%');
                },
                onProgress: (data: any) => {
                    setCurrProgress(`${data?.progress || 0}%`);
                },
                onFinish: () => {
                    setStep(STEP_TYPE.SUCESS);
                },
            })
            .then(() => {
                imService?.setAppVersion(`2|0`, 'DB').then(() => {
                    setDbVersion(`2|0`);
                });
            })
            .catch((err) => {
                setStep(STEP_TYPE.FAILED);
                setCurrProgress('0%');
            });
    }, [setDbVersion]);

    const compHandleDelay = useMemo(() => {
        return (
            <div className={styles['btn']} onClick={handleDelay}>
                稍后处理
            </div>
        );
    }, [handleDelay]);

    const compHandleMigrate = useMemo(() => {
        return (
            <div className={`${styles['btn']} ${styles['confirm']}`} onClick={handleMigrate}>
                {step === STEP_TYPE.INIT ? '立即迁移' : '再次迁移'}
            </div>
        );
    }, [handleMigrate, step]);

    const content = useMemo(() => {
        switch (step) {
            case STEP_TYPE.INIT:
                return (
                    <div className={styles['migrate-wrap']}>
                        <div className={styles['step-bg']} />
                        <p className={styles['title']}>{STEP_TIP[step]}</p>
                        <p className={styles['text']}>新版本将进行系统优化和数据迁移， </p>
                        <p>
                            <span className={styles['text']}>为保证您能</span>
                            <span className={`${styles['text']} ${styles['highlight']}`}>
                                正常查看所有历史消息
                            </span>
                            <span className={styles['text']}>，请立即进行迁移！</span>
                        </p>
                        <div className={styles['btn-wrap']}>
                            {compHandleDelay}
                            {compHandleMigrate}
                        </div>
                    </div>
                );
            case STEP_TYPE.PROCESS:
                return (
                    <div className={styles['migrate-wrap']}>
                        <div className={styles['step-bg']} />
                        <p className={styles['title']}>{STEP_TIP[step]}</p>
                        <p>
                            <span className={styles['text']}>
                                系统正在进行数据库迁移，请勿关闭京办
                            </span>
                        </p>
                        <div className={styles['progress']}>
                            <i className={styles['inner']} style={{ width: currProgress }} />
                        </div>
                        <p>
                            <span className={`${styles['text']} ${styles['light']}`}>当前进度</span>
                            <span className={`${styles['text']} ${styles['highlight']}`}>
                                {currProgress}
                            </span>
                            <span className={`${styles['text']} ${styles['light']}`}>
                                预计5分钟内完成迁移
                            </span>
                        </p>
                    </div>
                );
            case STEP_TYPE.FAILED:
                return (
                    <div className={`${styles['migrate-wrap']} ${styles['failed']}`}>
                        <div className={styles['step-bg']} />
                        <p className={styles['title']}>{STEP_TIP[step]}</p>
                        <div className={styles['btn-wrap']}>
                            {compHandleDelay}
                            <div className={`${styles['btn']} ${styles['confirm']}`}>再次迁移</div>
                        </div>
                    </div>
                );
            case STEP_TYPE.SUCESS:
                return (
                    <div className={`${styles['migrate-wrap']} ${styles['sucess']}`}>
                        <div className={styles['step-bg']} />
                        <p className={styles['title']}>{STEP_TIP[step]}</p>
                        <div className={styles['btn-wrap']}>
                            <div
                                className={`${styles['btn']} ${styles['confirm']}`}
                                onClick={() => setMigrateVisiable(false)}
                            >
                                知道了
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }, [compHandleDelay, compHandleMigrate, setMigrateVisiable, step, currProgress]);

    return (
        <Modal
            maskClosable={false}
            visible={migrateVisiable}
            width={544}
            centered={true}
            footer={null}
            closable={false}
            afterClose={() => {
                setStep(STEP_TYPE.INIT);
                setCurrProgress('0%');
            }}
            style={{
                borderRadius: ' 8px',
            }}
            bodyStyle={{ padding: 0 }}
        >
            {content}
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MigrateModal);
