import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { findProjectGroups, ProjectGroupListResp } from '../../../api/index';
import { connect } from 'dvajs';
import { Input } from 'antd';
import './index.less';
import ChatState from '@/types/chat/State';
import { Session as ISession, Employee } from '@/types/chat';
import Session from '@/components/chat/Session';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import { HeadShowType } from '@/types/chat/enum';
import { sessionDateFormat } from '@/utils/date';
import { getLastMessageDesc } from '@/components/chat/utils/message';

import { openGroupSession } from '@/utils';
import { SessionTag } from '@/components/Tags';
import IconFont from '@/components/icon';
import { openCreateGroupChat } from '../CreateChat';
import { IGroupChatItem as IGroupItem, Project, ProjectRole } from '../../../types';
import Context from '../../../context';
import Empty from './empty';
import SearchEmpty from '@/assets/img/search_empty_img.png';
import MessageEmpty from '@/assets/img/message-empty.png';
import i18n from '@/i18n';

enum FilterCondition {
    ALL,
    AITE,
    UNREDA,
}
function GroupTeamItem({
    session,
    currentEmployee,
}: {
    session: ISession;
    currentEmployee: Employee;
}) {
    const openChatSession = useCallback((gid: string) => {
        openGroupSession({ gid });
    }, []);
    const mention = useMemo(() => {
        if (!session) {
            return null;
        }
        const mentions = Object.entries(session.mentions || {}).map(([, m]) => m);
        mentions.sort((a, b) => b.mid - a.mid);
        return mentions[0];
    }, [session]);
    return (
        <div className="group-team-item" onClick={openChatSession.bind(null, session.id)}>
            <HeadPortrait
                width="32px"
                height="32px"
                imgUrl={session?.info?.avatar}
                name={session?.info?.name}
                showType={HeadShowType.SESSION}
                type={session.sessionType}
                numPieces={session.unreadCount || 0}
                // mentions={session.mentions}
            />
            <div className="gti-content">
                <div className="title-time">
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                        }}
                    >
                        <p className="title">{session.info?.name}</p>
                        <SessionTag session={session} currentEmployee={currentEmployee} />
                    </div>
                    <p className="time">{sessionDateFormat(session.timestamp)}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                    }}
                >
                    <p
                        className="sub-title"
                        dangerouslySetInnerHTML={{
                            __html: getLastMessageDesc(
                                session?.lastMsg || null,
                                session.sessionType,
                                currentEmployee,
                                false
                            ),
                        }}
                    />
                    {mention && <p className="cue-name">{mention.sender.name}@</p>}
                </div>
                <div className="divider" />
            </div>
        </div>
    );
}
function GroupTeamI({
    projectId,
    allSessionList,
    currentEmployee,
    project,
}: {
    projectId: string;
    allSessionList: ISession[];
    currentEmployee: Employee;
    project: Project;
}) {
    const [groupList, setGroupList] = useState<ProjectGroupListResp[]>([]);
    const [filterList, setFilterList] = useState<ISession[]>([]);
    const [filter, setFilter] = useState(FilterCondition.ALL);
    const [aiteHas, setAiteHas] = useState(false);
    const [groupName, setGroupName] = useState('');
    const { user, events, t } = useContext(Context);
    const m = i18n.t('project:groupTeam.no-message');
    const [emptyMessage, setEmptyMessage] = useState(m);
    const [loading, setLoading] = useState(false);
    function getData() {
        setLoading(true);
        findProjectGroups(projectId, groupName)
            .then((res: any) => {
                const { groupList } = res;
                setGroupList(groupList || []);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    function onChangeHandle(str: string) {
        setGroupName(str);
    }
    function createHandle() {
        openCreateGroupChat({
            projectId,
            user,
            t,
            callback: (content: IGroupItem) => {
                // setList((oldstate: IGroupItem[]) => {
                //     return oldstate.concat(content);
                // });
                console.log(content);
                getData();
            },
        });
    }
    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (filter === FilterCondition.ALL) {
            setEmptyMessage(i18n.t('project:groupTeam.no-message'));
        } else if (filter === FilterCondition.AITE) {
            setEmptyMessage(i18n.t('project:groupTeam.no-aite-message'));
        } else {
            setEmptyMessage(i18n.t('project:groupTeam.no-unread-message'));
        }
        if (groupName.length) {
            setEmptyMessage(i18n.t('project:groupTeam.search-empty'));
        }
    }, [groupName, filter]);
    useEffect(() => {
        const filterList: any = [];
        const allSessionListTmp = allSessionList.filter((group: ISession) => {
            return !groupName || group.info?.name.indexOf(groupName) !== -1;
        });
        if (filter === FilterCondition.ALL) {
            allSessionListTmp.forEach((group: ISession) => {
                let idx = groupList.findIndex(
                    (item: ProjectGroupListResp) => group.id === item.groupId
                );
                if (idx >= 0) {
                    filterList.push(group);
                }
                // return false;
            });
            setFilterList(filterList);
        } else if (filter === FilterCondition.UNREDA) {
            allSessionListTmp.forEach((group: ISession) => {
                let idx = groupList.findIndex(
                    (item: ProjectGroupListResp) => group.id === item.groupId && group.unreadCount
                );
                if (idx >= 0) {
                    filterList.push(group);
                }
                // return false;
            });
            setFilterList(filterList);
        } else {
            allSessionListTmp.forEach((group: ISession) => {
                let idx = groupList.findIndex(
                    (item: ProjectGroupListResp) => group.id === item.groupId
                );
                const mentions = Object.entries(group.mentions || {}).map(([, m]) => m);
                if (idx >= 0 && mentions.length) {
                    filterList.push(group);
                }
            });
            setFilterList(filterList);
        }
        let hasFlag = false;
        allSessionListTmp.forEach((group: ISession) => {
            let idx = groupList.findIndex(
                (item: ProjectGroupListResp) => group.id === item.groupId
            );
            const mentions = Object.entries(group.mentions || {}).map(([, m]) => m);
            if (idx >= 0 && mentions.length) {
                hasFlag = true;
            }
        });
        setAiteHas(hasFlag);
    }, [filter, groupList, allSessionList, groupName]);
    return (
        <div className="group-item-content">
            <div className="header-guide">
                <div style={{ display: 'flex' }}>
                    <p
                        onClick={() => setFilter(FilterCondition.ALL)}
                        className={`selector ${filter === FilterCondition.ALL ? 'active' : ''}`}
                    >
                        全部
                    </p>
                    <p
                        onClick={() => setFilter(FilterCondition.UNREDA)}
                        className={`selector ${filter === FilterCondition.UNREDA ? 'active' : ''}`}
                    >
                        未读
                    </p>
                    <p
                        onClick={() => setFilter(FilterCondition.AITE)}
                        className={`selector ${filter === FilterCondition.AITE ? 'active' : ''}`}
                    >
                        @消息 {aiteHas && <span className="gti-red-dot" />}
                    </p>
                </div>
                <div style={{ display: 'flex' }}>
                    <Input
                        placeholder="搜索团组名称"
                        prefix={
                            <IconFont
                                style={{
                                    color: '#BFC1C4',
                                }}
                                type="iconjs_ic_search"
                            />
                        }
                        value={groupName}
                        allowClear
                        onChange={(e) => onChangeHandle(e.target.value)}
                    />
                    {!project?.archive &&
                    [ProjectRole.ADMIN, ProjectRole.OWNER].includes(
                        project?.user?.role || ProjectRole.MEMBER
                    ) ? (
                        <div onClick={createHandle} className="create-btn">
                            新建
                        </div>
                    ) : null}
                </div>
            </div>
            {filterList.map((item: ISession) => {
                return (
                    <GroupTeamItem currentEmployee={currentEmployee} session={item} key={item.id} />
                );
            })}
            {!filterList.length && (
                <Empty
                    desc={emptyMessage}
                    desc1={groupName}
                    icon={groupName ? SearchEmpty : MessageEmpty}
                    loading={loading}
                />
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { allSessionList, currentEmployee } = chat as ChatState;
    return { allSessionList, currentEmployee };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupTeamI);
