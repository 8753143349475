import React, { useState, useMemo, useEffect, useRef } from 'react';
import './index.less';
import { TaskComment } from '@/types/work';
import { UserItem } from '../../BaseComponents/UserList';
import { useTranslation } from 'react-i18next';
import InputComment from '../InputComment';
import { mattchUrl } from '@/utils/chat/index';
import ScrollView from '@/baseComponents/ScrollView';

function CommentItem({
    commentItem,
    onReply,
    onDelete,
    onZan,
}: {
    commentItem: TaskComment;
    onReply: (item: TaskComment) => void;
    onZan?: ((item: string) => void) | any;
    onDelete: (item: TaskComment) => void;
}) {
    const [t] = useTranslation('work');
    return (
        <div
            className={`page-comments-list-item ${
                commentItem.parentId ? 'page-comments-list-item-child' : ''
            }`}
        >
            <UserItem item={commentItem as any} />
            <div className="item-container user-select-text">
                <div className="item-header">
                    <div className="title">{commentItem.realName}</div>
                    {commentItem.replyUser && (
                        <div className="reply-label">{`${t('reply')}${
                            commentItem.replyUser.realName
                        }:`}</div>
                    )}
                    <span style={{ flex: 1 }} />
                    <div className="action-button">
                        <span className="action-button-zan" onClick={() => onZan('')}>
                            {t('zan')}
                        </span>
                        <span className="action-button-reply" onClick={() => onReply(commentItem)}>
                            {t('reply')}
                        </span>
                        {commentItem?.isCommentDelete && (
                            <span
                                className="action-button-delete"
                                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_comment_delete"
                                onClick={() => onDelete(commentItem)}
                            >
                                {t('delete')}
                            </span>
                        )}
                    </div>
                </div>

                <div
                    className="item-content"
                    dangerouslySetInnerHTML={{ __html: mattchUrl(commentItem.content) }}
                />
                <div className="item-footer">{commentItem.createTime}</div>
            </div>
        </div>
    );
}

function ShowComments({
    list,
    onReply,
    onDelete,
    replyComment,
    onSend,
    onZan,
}: {
    list: { topComment: TaskComment; children: TaskComment[] }[];
    onReply: (item: TaskComment) => void;
    onDelete: (item: TaskComment) => void;
    replyComment: TaskComment | null;
    onSend: (content: string) => Promise<boolean>;
    onZan?: (content: string) => Promise<boolean> | any;
}) {
    const refAll = useRef(null);
    const scrollRef = useRef(null);

    return (
        <ScrollView ref={scrollRef} autoHide style={{ marginBottom: 0 }}>
            <div className="page-comments-list">
                {list.map((l) => {
                    const { topComment, children } = l;
                    return (
                        <div key={topComment.commentId} className="list-block">
                            <CommentItem
                                commentItem={topComment}
                                onReply={onReply}
                                onZan={onZan}
                                onDelete={onDelete}
                            />
                            <div className="block-child-container">
                                {children.map((c) => {
                                    return (
                                        <CommentItem
                                            key={c.commentId}
                                            commentItem={c}
                                            onReply={onReply}
                                            onZan={onZan}
                                            onDelete={onDelete}
                                        />
                                    );
                                })}
                            </div>
                            {(replyComment?.parentId || replyComment?.commentId) ===
                                topComment.commentId && (
                                <InputComment
                                    onSend={onSend}
                                    replyComment={replyComment}
                                    onReply={onReply}
                                    key={topComment.commentId}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </ScrollView>
    );
}

export default ShowComments;
