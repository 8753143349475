import * as React from 'react';
import './index.less';

interface IProps {
    children: any;
    onClick?: () => void;
    type?: 'default' | 'primary' | 'danger';
    size?: 'large' | 'normal' | 'small';
    className?: string;
    clstag?: string;
    disabled?: boolean;
}

export default function BaseButton(props: IProps) {
    const {
        children,
        type = 'default',
        size = 'large',
        className = '',
        onClick,
        disabled = false,
    } = props;
    let theclstag = props.clstag ? { clstag: props.clstag } : {};
    return (
        <div
            className={`basic-button basic-button-type-${type} basic-button-size-${size} basic-button-disabled-${disabled} ${className}`}
            onClick={onClick && onClick}
            {...theclstag}
        >
            {children}
        </div>
    );
}
