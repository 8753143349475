/* eslint-disable array-callback-return */
// 组通知卡片
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { Card, message as toast } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import Context from '@/components/chat/context';
import { buildGroupMessageBody, isBannedPostGroupSession } from '@/components/chat/utils/message';
import { ChatMessage, ClientType, Employee, Session, SessionType } from '@/types/chat';
import { CalendarIcon } from '@/components/Calendars/Common';
import { getFileIconByMime } from '@/utils/chat/index';
import { buildJoyspaceIconUrl } from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { RespondToNotificationReq } from '@/api/notification';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import bus from '@/utils/bus';
import ImService from '@/server/ImService';
import IconFont from '@/components/icon';
import { ChatMessageExtType } from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/Type';

interface MessageOperateProps {
    message: ChatMessage;
    selectedSession: Session;
    currentEmployee: Employee;
    sessionType: SessionType;
    sessionMessageMap: any;
    openNoticeDetails: (data: { isvisibles: boolean; notificationId: any }) => void;
}
type IProps = Readonly<MessageOperateProps>;
// let userInfo: any;
function GroupNotificationCard(props: IProps) {
    const {
        appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
    } = useContext<GlobalContextConfig>(GlobalContext);
    const {
        selectedSession,
        message,
        currentEmployee,
        sessionMessageMap,
        openNoticeDetails,
    } = props;
    let msg: any = message;
    const { groupRosterIdentity } = React.useContext(Context);
    const [disabled, setDisabled] = useState(false);
    const [showUser, setshowUser] = useState(false);
    const [userInfo, setUserInfo] = useState<any>();
    const [notification, setNotification] = useState<any[]>([]);
    const imService = ImService.getInstance();
    const ref = useRef(null);
    const textRef = useRef(null);
    const [t] = useTranslation('chat');
    const newDate = new Date().getTime();
    let newMsg = sessionMessageMap[selectedSession.sessionId];
    const GroupNotification = useCallback(async () => {
        // setVisible(true);
        openNoticeDetails({
            isvisibles: true,
            notificationId: msg.notificationId,
        });
    }, [msg.notificationId, openNoticeDetails]);

    useEffect(() => {
        // 查询缓存
        const instance = ImService.getInstance();
        instance.getMessageExt(message.sessionId, message.mid).then((data) => {
            const msgContent = data?.msgContent || [];
            let msgcontent: any = msgContent;
            if (msgContent.length > 0) {
                setNotification(msgContent);
            }
            // eslint-disable-next-line array-callback-return
            msgcontent?.map((item: any) => {
                // eslint-disable-next-line max-nested-callbacks
                item.users.filter((item1: any) => {
                    if (item1.pin === currentEmployee.userId) {
                        setUserInfo(item1);
                        setDisabled(true);
                        setshowUser(true);
                    }
                    // else {
                    //     setshowUser(false);
                    // }
                });
            });
        });
    }, [currentEmployee, message]);

    // useEffect(() => {
    //     const dom: any = ref.current;
    //     const textDom: any = textRef.current;
    //     if (!dom) return;
    //     let wareNameText = dom?.innerHTML;
    //     let wareHeight = textDom?.clientHeight;
    //     let wareWidht = dom?.offsetWidth;
    //     // textDom?.clientHeight > 36 &&
    //     if (dom?.offsetWidth > 50) {
    //         // eslint-disable-next-line prettier/prettier
    //         // for (let i = 0; textDom?.clientHeight > 36; i++) {
    //         //     wareNameText = wareNameText.substring(0, wareNameText.length - 1);
    //         //     dom.innerHTML = wareNameText;
    //         //     wareHeight = textDom.clientHeight;
    //         // }
    //         for (let i = 0; dom?.offsetWidth > 55; i++) {
    //             wareNameText = wareNameText.substring(0, wareNameText.length - 1);
    //             dom.innerHTML = wareNameText;
    //             wareWidht = dom.offsetWidth;
    //             // break;
    //         }
    //         dom.innerHTML = wareNameText.substring(0, wareNameText.length - 1) + ' ';
    //         // wareName.innerHTML = newText;
    //     }
    // }, [notification]);

    const receiveMessageExtListener = useCallback(
        (msg?: any) => {
            const instance = ImService.getInstance();
            instance.getMessageExt(msg.sessionId, msg.mid).then((data) => {
                const msgContent = data?.msgContent || [];
                let msgcontent: any = msgContent;
                if (msgContent.length > 0) {
                    setNotification(msgContent);
                }
                // console.log('响应22222》》》》', msgContent);
                // eslint-disable-next-line array-callback-return
                msgcontent?.map((item: any) => {
                    // eslint-disable-next-line max-nested-callbacks
                    item.users.filter((item1: any) => {
                        if (item1.pin === currentEmployee.userId) {
                            setUserInfo(item1);
                            setDisabled(true);
                            setshowUser(true);
                        } else {
                            setshowUser(false);
                        }
                    });
                });
            });
        },
        [currentEmployee.userId]
    );

    const received = useCallback(
        async (data?: any) => {
            if (userInfo?.userId === currentEmployee.userId) return false;
            if (data?.content === message.content) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                msg = message;
            }
            newMsg?.map((item: any) => {
                if (item?.mid === data?.mid) {
                    msg = item;
                }
            });
            let optionId: any;
            msg.optionList.map((item: any) => {
                // eslint-disable-next-line no-return-assign
                return (optionId = item.optionId);
            });
            let users = {
                app: currentEmployee.app,
                clientType: ClientType.PC,
                name: currentEmployee.name,
                nickName: currentEmployee.name,
                pin: currentEmployee.userId,
                teamId: currentEmployee.teamId,
                optionId: msg.optionList[0].optionId,
                timestamp: newDate,
            };
            let msgContentItem = {
                users: [users],
            } as any;
            const msgType = ChatMessageExtType.GROUP_NOTICE;
            await RespondToNotificationReq({
                notificationId: msg.notificationId,
                optionId: optionId,
            })
                .then(async (data) => {
                    if (data[0] === null) {
                        toast.error(data[1]);
                        return false;
                    } else {
                        // setDisabled(true);
                        const datas = buildGroupMessageBody({
                            msgType,
                            session: selectedSession,
                            mid: msg.mid,
                            msgContentItem,
                        });
                        await imService.sendChatMessageExt(msg.sessionId, datas as any);
                        receiveMessageExtListener(msg);
                        // msgContentItem = {};
                        // return;
                    }
                })
                .catch((err) => {
                    toast.error(err);
                    return;
                });
        },
        [currentEmployee, imService, message, msg, selectedSession, userInfo, newDate]
    );

    useEffect(() => {
        bus.off('chat:message-groupnotificationcard');
        bus.on('chat:message-groupnotificationcard', (data) => {
            setDisabled(true);
            received(data);
        });
    }, [received]);

    const undateMessageExtListener = useCallback(() => {
        const instance = ImService.getInstance();
        instance.getMessageExt(message.sessionId, message.mid).then((data) => {
            const msgContent = data?.msgContent || [];
            let msgcontent: any = msgContent;
            if (msgContent.length > 0) {
                setNotification(msgContent);
            }
            // eslint-disable-next-line array-callback-return
            msgcontent?.map((item: any) => {
                // eslint-disable-next-line max-nested-callbacks
                item.users.map((item1: any) => {
                    if (item1.pin === currentEmployee.userId) {
                        setUserInfo(item1);
                        setDisabled(true);
                    }
                });
            });
        });
    }, [currentEmployee, message]);

    useEffect(() => {
        const instance = ImService.getInstance();
        const manager = instance.getChatManager();
        if (manager) {
            manager.on(
                `chat:message-groupnotificationcard:${message.sessionId}:${message.mid}`,
                undateMessageExtListener
            );
            return () => {
                manager.off(
                    `chat:message-groupnotificationcard:${message.sessionId}:${message.mid}`,
                    undateMessageExtListener
                );
            };
        }
    }, [message, undateMessageExtListener]);

    // 获取文件icon
    const getAttachmentIcon = (item: any) => {
        const { fileType, fileName } = item;
        if (fileType?.startsWith('joyspace')) {
            try {
                const strs = fileType.split('/');
                if (strs[1] === '6') {
                    return getFileIconByMime(
                        strs.slice(2).join('/') || 'application/octet-stream'
                    )[0];
                } else {
                    return buildJoyspaceIconUrl({
                        title: fileName,
                        pageType: strs[1],
                        type: strs.slice(2).join('/') || '',
                        apiHost: `${joyspaceApiHost}-api`,
                    });
                }
            } catch (error) {
                return getFileIconByMime(fileType || 'application/octet-stream')[0];
            }
        }
        return getFileIconByMime(fileType || 'application/octet-stream')[0];
    };
    // let chatMsgExtUser: any[] = [];
    // for (let i = notification[0]?.users?.length - 1; i >= 0; i--) {
    //     if (!notification[0]?.users[i]) {
    //         return;
    //     }
    //     chatMsgExtUser[chatMsgExtUser.length] = notification[0]?.users[i];
    //     // chatMsgExtUser.splice(
    //     //     chatMsgExtUser.findIndex((item) => item.pin === currentEmployee.userId),
    //     //     1
    //     // );
    // }
    return (
        <div className="GroupNotificationCard">
            <Card
                title={
                    <div className="titleIcon">
                        <div className="titleIcon_icon">
                            <IconFont
                                type="iconic_pc_notification_square"
                                className="titleIcon-icon-small-icon"
                            />
                            <span style={{ fontSize: '14px', color: '#333333', fontWeight: 700 }}>
                                {t('group_tz')}
                            </span>
                        </div>
                        <div style={{ display: 'flex', height: '24px', lineHeight: '23px' }}>
                            <span style={{ width: '60px' }}>{message.nickName}</span>
                            <span>{t('publish')}</span>
                        </div>
                    </div>
                }
                bordered={true}
                style={{ width: 301 }}
                // eslint-disable-next-line react/jsx-key
                actions={
                    message.sender.userId === currentEmployee.userId
                        ? []
                        : [
                              <div
                                  className="ellipsi"
                                  onClick={() => {
                                      if (
                                          isBannedPostGroupSession(selectedSession) &&
                                          groupRosterIdentity === 'ordinary'
                                      ) {
                                          return false;
                                      }
                                      received();
                                  }}
                                  key=""
                              >
                                  <button
                                      className="btns"
                                      disabled={disabled === false ? false : true}
                                      style={{
                                          cursor:
                                              disabled === true ||
                                              userInfo?.pin === currentEmployee.userId
                                                  ? 'not-allowed'
                                                  : 'pointer',
                                          color:
                                              disabled === true ||
                                              userInfo?.pin === currentEmployee.userId
                                                  ? '#BFC1C4'
                                                  : (window as any).styleSass.primaryColor,
                                      }}
                                  >
                                      {disabled === true || userInfo?.pin === currentEmployee.userId
                                          ? '已回复'
                                          : '收到'}
                                  </button>
                              </div>,
                          ]
                }
            >
                <div
                    onClick={() => {
                        if (
                            isBannedPostGroupSession(selectedSession) &&
                            groupRosterIdentity === 'ordinary'
                        ) {
                            return false;
                        }
                        GroupNotification();
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <p className="mrl-11">{message.content}</p>

                    {msg.attachmentList?.map((item: any, index: any) => {
                        return (
                            <div className="GroupNotificationCard-list mrl-11" key={index}>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ lineHeight: '26px' }}>
                                        <CalendarIcon
                                            // className="left"
                                            src={getAttachmentIcon(item)}
                                            width="12px"
                                            height="12px"
                                        />
                                    </span>
                                    <span
                                        className="ellipsis"
                                        style={{
                                            marginLeft: '6px',
                                        }}
                                    >
                                        {item.fileName}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                    {/* {notification.length > 0 && (
                        <div className="userList" ref={textRef}>
                            {showUser && (
                                <>
                                    <span>您</span>
                                    {chatMsgExtUser?.length > 1 && <span>与</span>}
                                </>
                            )}
                            <div style={{ display: 'inline' }}>
                                {chatMsgExtUser?.map((item: any, index: any) => {
                                    if (index > 5 || item.pin === currentEmployee.userId) return;
                                    return (
                                        <div key={index} style={{ display: 'inline' }}>
                                            <span ref={ref}>
                                                {item.name}
                                                {chatMsgExtUser?.length === 1 ||
                                                chatMsgExtUser[chatMsgExtUser?.length - 1] ===
                                                    item ||
                                                index >= 5
                                                    ? ''
                                                    : '、'}
                                            </span>
                                        </div>
                                    );
                                })}
                                <span>
                                    {chatMsgExtUser?.length > 5 && `等${chatMsgExtUser?.length}人`}
                                    已回复
                                </span>
                            </div>
                        </div>
                    )} */}

                    {notification.length > 0 && (
                        <div className="userList">
                            <div style={{ display: 'inline' }}>
                                {notification[0]?.users?.map((item: any, index: any) => {
                                    if (index > 5) return;
                                    return (
                                        <div key={index} style={{ display: 'inline' }}>
                                            <span>{item.name}</span>
                                            {notification[0]?.users?.length === 1 ||
                                            notification[0]?.users[
                                                notification[0]?.users?.length - 1
                                            ] === item ||
                                            index >= 5
                                                ? ''
                                                : '、'}
                                        </div>
                                    );
                                })}
                                <span>
                                    {notification[0]?.users?.length > 5 &&
                                        `等${notification[0]?.users?.length}人`}
                                    已回复
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    const { sessionMessageMap, selectedSession, currentEmployee } = chat;
    return {
        sessionMessageMap,
        selectedSession,
        currentEmployee,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
        openNoticeDetails(data: { isvisibles: boolean; notificationId: any }) {
            dispatch({ type: 'chat/openNoticeDetails', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupNotificationCard);
