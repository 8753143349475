import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ImService from '@/server/ImService';
import { FocusSDK } from '@/utils';
import { getAppVersion } from '@/utils/getVersion';
import './index.less';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { changeEgovUrl } from '@/utils/tools';
const { REACT_APP_CHECK_UP } = process.env;

function GuideComp(props: any) {
    const { onSkip } = props;
    const list = [
        'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/statics/images/thirdapplication-nav.png',
    ];

    const { t } = useTranslation('common');

    const getFooter = useMemo(() => {
        return (
            <div className="footer-space">
                <h3>使用小贴士</h3>
                <p>
                    点击“...”可以查看跟应用相关的更多功能，包括“关于应用”、“添加常用”、“取消常用”等功能。
                </p>
                <p>
                    在“关于应用”中可以查看应用详情、开发者信息、开发者联系方式等。在使用应用过程中如有问题，可以直接联系开发者。
                </p>
                <Button size="middle" type="primary" onClick={() => onSkip()}>
                    {t('newVersion.readed')}
                </Button>
            </div>
        );
    }, [onSkip, t]);

    return (
        <>
            <img
                src={changeEgovUrl(list[0])}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    zIndex: 1000,
                }}
            />
            {getFooter}
        </>
    );
}

export default function NewVersionGuide(props: any) {
    const { pathname } = useLocation();
    const ele = document.createElement('div');
    ele.setAttribute('class', 'thirdapplication-guide-space');
    const [isUp, setIsUp] = useState(false);
    // // eslint-disable-next-line
    // debugger;
    const checkNewVersion = async () => {
        // 网页端暂时不弹出引导页
        const isWeb = FocusSDK.getDeviceInfo().platform === 'web';
        if (isWeb) {
            return;
        }
        // 后续系统设置新功能介绍弹框多加一层判断
        const imService = ImService.getInstance();
        const version = getAppVersion(FocusSDK);
        const isUp = await imService?.isAppUpgraded(version, 'thirdapplication');
        setIsUp(isUp);
        // if (REACT_APP_CHECK_UP && REACT_APP_CHECK_UP === 'true' && isUp) {
        //     document.body.appendChild(ele);
        //     ReactDOM.render(<GuideComp {...{ ...props, onSkip }} />, ele);
        // }
    };
    const onSkip = async () => {
        if (ele) {
            const imService = ImService.getInstance();
            const version = getAppVersion(FocusSDK);
            await imService?.setAppVersion(version, 'thirdapplication');
            ReactDOM.unmountComponentAtNode(ele);
            setIsUp(false);
        }
    };

    useEffect(() => {
        checkNewVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return REACT_APP_CHECK_UP && REACT_APP_CHECK_UP === 'true' && isUp ? (
        <GuideComp {...{ ...props, onSkip }} />
    ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
    );
}
