import {
    Button,
    Drawer,
    message,
    Spin,
    Tabs,
    Tooltip,
    Input,
    Modal,
    Dropdown,
    Select,
    Collapse,
} from 'antd';
const { Panel } = Collapse;
const { Option } = Select;
import { connect } from 'dva';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    createContext,
    useReducer,
    useRef,
} from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import IconFont from '@/components/icon';
// import BasicInfo from './components/BasicInfo';
import { ICreateSuperviseData } from '@/types/supervise';
import GlobalContext from '@/context/GlobalContext';
import { getTaskDetail, modifyWholeTask } from '@/api/work';
import {
    findSelfInExecutors,
    findIndexExecutorInTask,
    getDefaultStartTime,
    getDefaultStopTime,
} from '../utils';
import './index.less';
import {
    NewTaskModel,
    PRIORITY_TYPE,
    PROCESS_STATUS,
    TaskListParams,
    TaskResource,
    TASK_STATUS,
    RemarkInfo,
} from '@/types/newWork';
import { decorateTaskDetail, judgeTaskFinishOrStop } from '../newUtils';
import { nanoid } from 'nanoid';
import { getFileIconByMime } from '@/utils/chat/index';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import bus from '@/utils/bus';
import {
    TaskPartEnum,
    TimeClassifyEnum,
    TaskResourceBizType,
    TaskResourcePermissionType,
} from '@/types/work';
import CryptoJS from 'crypto-js';
import syncConfirm from '@/utils/syncConfirm';
import ScrollView from '@/baseComponents/ScrollView';
const FORCE_GET = '1cf07dd502458e65675172405c2dc3db';

import UserList from '../BaseComponents/UserList';
import UserStatusListPopover from '../UserStatusListPopover';
import { openUserSelectionModal } from '@/utils/modals';
import config, { ConfigEnum } from '@/config/config';
import lodashCompact from 'lodash/compact';
import prompt from '@/baseComponents/ModalComponent/prompt';
import TypeSelect from '../BaseComponents/TypeSelect';
import DatePicker from '@/components/Calendars/BaseComponents/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { remindList } from '../CreateTaskDraw/index';
import WorkAttachmentComp from '../BaseComponents/WorkAttachmentComp/workEdit';
import { htmlUnescape } from '@/utils/tools';

enum FileStatus {
    Init = 1,
    Uploading,
    Done,
    failed,
}
interface IState {
    fileList: any[];
}
const initialState: IState = {
    fileList: [],
};
function reducer(state: IState, action: any) {
    const { payload } = action;
    switch (action.type) {
        case 'changeState':
            return { ...state, ...payload };
        case 'reloadAttchment': // 多端同步时，处理一下正在上传的文档
            // eslint-disable-next-line no-case-declarations
            const fileList: any[] = state.fileList.filter(
                (file: any) => file.status === FileStatus.Uploading
            );
            payload.list.forEach((l: any) => {
                fileList.push({
                    fileName: l.name,
                    size: l.size,
                    fileType: l.fileType,
                    fileId: l.bizId || nanoid(20),
                    fileUrl: l.url,
                    percent: 0,
                    status: FileStatus.Done,
                    resourceId: l.resourceId,
                    icon: getFileIconByMime(l.fileType),
                    creatorUserId: l?.creatorUserId,
                });
            });
            return { ...state, fileList };
        default:
            throw new Error('未知错误');
    }
}
export const TaskDetailContext = createContext<{
    taskDetail: NewTaskModel;
    setTaskDetail: any;
    closeDrawAndRefresh: Function;
}>({} as any);

let clicksign = false; // 记录是不是点击 label里的 ‘+ 添加附件’
// eslint-disable-next-line complexity
function TaskDetailDrawEdit({
    userData,
    drawEditVisible,
    taskId,
    taskDetail0,
    forceGet,
    detailStateActiveTab,
    changeEditTaskDraw,
    openCreateSuperviseModal,
    getTasks,
    offset,
    offsets,
    processStatuses,
    taskStatuses,
    priorityTypes,
    userRole,
    sortType,
}: {
    drawEditVisible: boolean;
    userData: any;
    taskId: string;
    taskDetail0: NewTaskModel | null | undefined;
    forceGet: boolean;
    detailStateActiveTab: string;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    changeEditTaskDraw: (taskId: string, visible: boolean) => void;
    getTasks: (params: TaskListParams) => void;
    offset: number;
    offsets: number[];
    processStatuses: PROCESS_STATUS[];
    taskStatuses: TASK_STATUS[];
    priorityTypes: PRIORITY_TYPE[];
    userRole: number;
    sortType: number;
}) {
    if (taskDetail0 && !taskDetail0.resources) {
        taskDetail0.resources = [];
    }
    const location = useLocation();
    const { t } = useTranslation('work');
    const [t_common] = useTranslation('common');
    const [t_chat] = useTranslation('chat');
    const { authInfo } = useContext(GlobalContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [taskDetail, setTaskDetail] = useState<NewTaskModel | null | undefined>(taskDetail0);
    const [taskDetailDouble, setTaskDetailDouble] = useState<NewTaskModel | null>(null);
    const { accessToken } = authInfo;
    const [submitting, setSubmitting] = useState(false);
    const scrollRef = useRef(null);
    const [remindArr, setRemindArr] = useState<string[]>(
        taskDetail?.remind ? taskDetail.remind.split(',') : []
    );
    const [newFileList, setNewFileList] = useState<any>(taskDetail?.resources || []);

    const [attachmentMenuShowFn, setAttachmentMenuShowFn] = useState<Function>(() => () => {});

    useEffect(() => {
        changeEditTaskDraw('', false);
    }, [changeEditTaskDraw, location]);
    useEffect(() => {
        setNewFileList(() => {
            taskDetail?.resources?.forEach((item) => {
                item.bizId = item.bizId || nanoid(20);
            });
            return taskDetail?.resources || [];
        });
        setRemindArr(taskDetail?.remind ? taskDetail.remind.split(',') : []);
    }, [taskDetail]);
    /**
     * 初始化数据
     */
    useEffect(() => {
        if (!taskId) {
            setTaskDetail(null);
            return;
        }
        if (!drawEditVisible) {
            return;
        }

        let share = '';
        if (forceGet) {
            share = CryptoJS.AES.encrypt(
                `share:${accessToken}`,
                CryptoJS.enc.Utf8.parse(FORCE_GET),
                {
                    iv: CryptoJS.enc.Utf8.parse('0102030405060708'),
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                }
            ).toString();
        }
        if (taskDetail0) {
            setTaskDetailDouble(cloneDeep(taskDetail0) as NewTaskModel);
            setTaskDetail(taskDetail0);
            if (taskDetail0.processStatus === PROCESS_STATUS.NOT_VIEWED) {
                setTaskDetail({ ...taskDetail0, processStatus: PROCESS_STATUS.PUSHING });
            }
            return;
        }
        getTaskDetail(taskId, share).then(([result, err]) => {
            if (err || !result) {
                console.error('get task err:', err);
                message.warn(err as string);
                changeEditTaskDraw('', false);
                return;
            }
            const task = decorateTaskDetail(result as NewTaskModel);

            setTaskDetailDouble(cloneDeep(task) as NewTaskModel);
            setTaskDetail(task);
            initFileList(task.resources);
            if (task.processStatus === PROCESS_STATUS.NOT_VIEWED) {
                setTaskDetail({ ...task, processStatus: PROCESS_STATUS.PUSHING });
            }
        });
    }, [
        taskId,
        taskDetail0,
        drawEditVisible,
        changeEditTaskDraw,
        detailStateActiveTab,
        forceGet,
        accessToken,
    ]); // eslint-disable-line react-hooks/exhaustive-deps
    /**
     * 监听多端同步
     * 单独处理一下正在上传的附件
     * TODO: 需要根据实际情况处理
     */
    useEffect(() => {
        function reloadDetail(msg: any) {
            const { data } = msg;
            if (!data) {
                console.error('------->多端同步消息错误', msg);
                return;
            }
            const { timeclassify, part } = data;
            if (!taskId) {
                setTaskDetail(null);
                return;
            }
            if (!drawEditVisible) {
                return;
            }
            if (
                ![
                    TaskPartEnum.PERSONAL_TASK,
                    TaskPartEnum.MEANWHILE_TASK,
                    TaskPartEnum.PROJECT_TASK,
                ].includes(part)
            ) {
                return;
            }
            if (taskId !== data.taskId && Number(timeclassify) !== 999) {
                return;
            }

            let share = '';
            if (forceGet) {
                share = CryptoJS.AES.encrypt(
                    `share:${accessToken}`,
                    CryptoJS.enc.Utf8.parse(FORCE_GET),
                    {
                        iv: CryptoJS.enc.Utf8.parse('0102030405060708'),
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7,
                    }
                ).toString();
            }

            getTaskDetail(taskId, share).then(([result, err]) => {
                if (err || !result) {
                    console.error('get task err:', err);
                    message.warn(err as string);
                    changeEditTaskDraw('', false);
                    return;
                }
                const task = decorateTaskDetail(result as NewTaskModel);
                setTaskDetail(task);
                dispatch({
                    type: 'reloadAttchment',
                    payload: {
                        list: task.resources,
                    },
                });
            });
        }
        function handleNetworkChange(data: any) {
            const { connectState } = data;
            if (connectState.state === ConnectState.READY) {
                reloadDetail({ data: { taskId } });
            }
        }
        bus.on(`chat_message_multiSync_event:joyWork`, reloadDetail);
        bus.on('chat:connect_state_change', handleNetworkChange);
        return () => {
            bus.off(`chat_message_multiSync_event:joyWork`, reloadDetail);
            bus.off('chat:connect_state_change', handleNetworkChange);
        };
    }, [taskId, drawEditVisible, changeEditTaskDraw, forceGet, accessToken]);

    function initFileList(list: TaskResource[]) {
        const fileList = list.map((l) => {
            return {
                fileName: l.name,
                size: l.size,
                fileType: l.fileType,
                fileId: l.bizId || nanoid(20),
                fileUrl: l.url,
                percent: 0,
                status: FileStatus.Done,
                resourceId: l.resourceId,
                icon: getFileIconByMime(l.fileType),
                creatorUserId: l?.creatorUserId,
            };
        });
        dispatch({
            type: 'changeState',
            payload: {
                fileList,
            },
        });
    }
    const isPrimaryHead = !!(
        taskDetail0?.owner && findSelfInExecutors([taskDetail0?.owner], userData)
    );
    const [isHead, self, isOnlySelf] = useMemo(() => {
        if (!taskDetail) {
            return [false, null, false];
        }
        let isHead = false;
        let self = null;
        let isOnlySelf = false;
        const header = findSelfInExecutors([taskDetail.owner], userData);
        if (header) {
            isHead = true;
        }
        self = findSelfInExecutors(taskDetail.executors, userData);
        if (self && taskDetail.executors.length === 1) {
            isOnlySelf = true;
        }
        return [isHead, self, isOnlySelf];
    }, [taskDetail, userData]);

    const isTaskFinishOrStop = useMemo(() => {
        if (!taskDetail) {
            return false;
        }
        let isTaskFinishOrStop = false;
        if (judgeTaskFinishOrStop(taskDetail.taskStatus)) {
            isTaskFinishOrStop = true;
        }
        return isTaskFinishOrStop;
    }, [taskDetail]);

    const closeDraw = useCallback(() => {
        setTaskDetail(null);
        changeEditTaskDraw(taskId, false);
    }, [changeEditTaskDraw, taskId]);

    function closeDrawConfirm() {
        function cancleAtt(status: boolean) {
            bus.off(`cancle-attachment-upload`, cancleAtt);

            // if (status) {
            //     message.warning(t('attachment uploading'));
            //     return;
            // } else {
            try {
                // 判断是否有编辑内容
                if (JSON.stringify(taskDetailDouble) !== JSON.stringify(taskDetail) || status) {
                    Modal.confirm({
                        title: t('tip'),
                        content: <span className="nimble-con">{t('tip content')}</span>,
                        transitionName: 'notransition',
                        maskTransitionName: 'notransition',
                        maskStyle: { opacity: '0' },
                        okText: t('confirm'),
                        cancelText: t('cancel'),
                        className: 'task-common-modal-lb',
                        onOk() {
                            closeDraw();
                            status && bus.emit('close-task-detail-with-cancle-attachment-upload');
                            // document
                            //     .getElementsByClassName('ant-modal-root')[0]
                            //     ?.setAttribute('id', 'confirmmodal');
                            // const ele = document.getElementById('confirmmodal');
                            // if (ele) ele.style.display = 'none';
                        },
                    });
                } else {
                    closeDraw();
                }
            } catch (error) {
                closeDraw();
            }
            // }
        }
        bus.on(`cancle-attachment-upload`, cancleAtt);

        bus.emit('close-task-detail');

        return () => {
            bus.off(`cancle-attachment-upload`, cancleAtt);
        };
    }

    const getTaskList = useCallback(() => {
        getTasks({
            limit: offsets ? offsets.length * 20 : 20,
            offset: 0,
            // offsets: offsets,
            processStatuses: processStatuses,
            taskStatuses: taskStatuses,
            priorityTypes: priorityTypes,
            userRole: userRole,
            sortType,
        });
    }, [getTasks, offsets, processStatuses, taskStatuses, priorityTypes, userRole, sortType]);

    const closeDrawAndRefresh = useCallback(() => {
        setTaskDetail(null);
        getTaskList();
        changeEditTaskDraw(taskId, false);
    }, [changeEditTaskDraw, getTaskList, taskId]);

    const handleModifyDetailInfo = useCallback(async () => {
        const data: any = taskDetail;
        if (!data) {
            return;
        }
        if (!data?.title) {
            message.error(t('create task err title'));
            return;
        }
        if (data.workStartTime && dayjs().isAfter(data.workStartTime)) {
            const confirm = await syncConfirm('是否编辑一个过去时间的任务');
            if (!confirm) {
                return;
            }
        }
        setSubmitting(true);
        const [result, err] = await modifyWholeTask(data || {});
        if (err) {
            message.warn(err as String);
            setSubmitting(false);
            return false;
        }
        const task = decorateTaskDetail(result as NewTaskModel);
        setTaskDetail(task);
        message.success(t('save success'));
        closeDrawAndRefresh();
        setSubmitting(false);
    }, [closeDrawAndRefresh, t, taskDetail]);

    /* yixia-任务内容-或许可以封为组件  */
    const modifyValidate = useCallback(() => {
        function cancleAtt(status: boolean) {
            bus.off(`cancle-attachment-upload`, cancleAtt);
            if (status) {
                message.warning(t('attachment uploading'));
                return;
            } else {
                handleModifyDetailInfo();
            }
        }
        bus.on(`cancle-attachment-upload`, cancleAtt);
        bus.emit('close-task-detail');
        return () => {
            bus.off(`cancle-attachment-upload`, cancleAtt);
        };
    }, [handleModifyDetailInfo, t]);
    const handleUpdateOwner = useCallback(() => {
        const { userId, teamUserInfo } = userData;
        const { ddAppId, teamId } = teamUserInfo;
        openUserSelectionModal(
            {
                title: t_chat('add_member'),
                // tabs: ['recommend', 'org'],
                tabs: !config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recent', 'group', 'externalContacts']
                    : ['recent', 'org', 'group', 'externalContacts'],
                single: true,
                resultUsers: lodashCompact([taskDetail?.owner]).map((item) => ({
                    id: item.userId + ':' + item.app + ':' + item.teamId,
                    name: item.realName,
                    avatar: item.headImg,
                    userId: item.userId,
                    teamId: item.teamId,
                    checked: true,
                    visible: false,
                })),
                recommendParam: {
                    keyWords: taskDetail?.title || '',
                    aiType: ['empl'],
                },
                contactsFilterFlag: 'external',
                currentUser: {
                    app: ddAppId,
                    pin: userId,
                    teamId: teamId,
                },
            },
            (data, close) => {
                // 过滤掉已选中的
                const { result } = data.data;
                if (!result.length) {
                    return close();
                }
                if (!taskDetail) {
                    return;
                }
                const addArr: any[] = [];
                result.forEach((r) => {
                    if (r.id) {
                        const strs = r.id.split(':');
                        return addArr.push({
                            userId: strs[0],
                            teamId: strs[2],
                            app: strs[1],
                            headImg: r.avatar,
                            realName: r.name,
                        });
                    }
                    const { origin } = r;
                    if (origin.info) {
                        addArr.push({
                            userId: origin.info.userId,
                            teamId: origin.info.teamId,
                            app: origin.info.appId || origin.info.app,
                        });
                    } else {
                        addArr.push({
                            userId: origin.userId,
                            teamId: origin.teamId,
                            app: origin.app,
                        });
                    }
                });
                prompt({
                    title: t(
                        `${
                            !taskDetail.owner
                                ? 'Set the person in charge'
                                : 'Person in charge of transfer'
                        }`
                    ),
                    icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                    onOk: async () => {
                        if (!taskDetail) {
                            return;
                        }

                        setTaskDetail({ ...taskDetail, owner: addArr[0] });
                    },
                    content: t(`transfer owner desc`).replace('%s', addArr[0].realName),
                    okText: t_common('button.ok'),
                    cancelText: t_common('button.cancel'),
                });
                close();
            },
            userData
        );
    }, [t, t_chat, t_common, taskDetail, userData]);

    const disabled = useMemo(() => {
        return isTaskFinishOrStop || !(isPrimaryHead || self) ? true : false;
    }, [isTaskFinishOrStop, isPrimaryHead, self]);
    const [textareaContentFocus, setTextareaContentFocus] = useState<boolean>(false);
    const [textareaRemarkFocus, setTextareaRemarkFocus] = useState<boolean>(false);
    // 只有负责人有编辑描述权限 1.5.0
    const descIsEdit = useMemo(() => {
        return !isTaskFinishOrStop && isHead ? true : false;
    }, [isTaskFinishOrStop, isHead]);

    async function handleTaskMembersWeight(data: any[]) {
        if (!taskDetail) {
            return;
        }
        setTaskDetail({ ...taskDetail, executors: [...data] });
    }
    async function handleAddTaskMembers(data: any[]) {
        if (!taskDetail) {
            return;
        }
        const temp: any[] = [];
        const executors = [...taskDetail.executors];
        data.forEach((m: any) => {
            const index = findIndexExecutorInTask(executors, m);
            if (index === -1) {
                temp.push({ ...m, processStatus: 4 }); // 刚添加的协同人默认显示未查看
            } else {
                executors[index].weight = m.weight; // 更新原有人的权重
            }
        });
        if (temp.length === 0) {
            return;
        }
        if ([...executors, ...temp]?.length > 200) {
            message.warn(t('200 person limit'));
            return;
        }
        setTaskDetail({ ...taskDetail, executors: [...executors, ...temp] });
    }
    async function handledelMember(members: any[]) {
        if (!taskDetail) {
            return;
        }
        const index = findIndexExecutorInTask(taskDetail.executors, members[0]);
        if (index > -1) {
            const temp = taskDetail.executors;
            temp.splice(index, 1);
            setTaskDetail({ ...taskDetail, executors: temp });
        }
    }
    async function handlePriorityChange(priority: number) {
        if (!taskDetail) {
            return;
        }
        if (taskDetail.priorityType === priority) {
            return;
        }
        setTaskDetail({ ...taskDetail, priorityType: priority });
    }
    async function handleTimeChange(time: Dayjs | null, start = true) {
        if (!taskDetail) {
            return;
        }
        const unix = time ? time.valueOf() : null;
        if (start) {
            if (time && taskDetail.workStopTime?.isSameOrBefore(time)) {
                return message.warn(t('The start time must not be later than the deadline'));
            }
            if (unix === taskDetail.startTime) {
                return;
            }
            const task = {
                ...taskDetail,
                startTime: unix,
                workStartTime: unix ? dayjs(Number(unix)) : null,
            };
            setTaskDetail(task);
        } else {
            if (unix === taskDetail.endTime) {
                return;
            }
            if (time && taskDetail.workStartTime?.isSameOrAfter(time)) {
                return message.warn(t('The deadline must not be earlier than the start time'));
            }
            if (!time) {
                setRemindArr([]);
            }
            const task = {
                ...taskDetail,
                endTime: unix,
                workStopTime: unix ? dayjs(Number(unix)) : null,
            };
            setTaskDetail(task);
        }
    }
    async function handleInputChange(e: any, key: string) {
        if (!taskDetail) {
            return;
        }
        let value = e.target.value;
        // 多行文本输入的时候，如果是拼音，输入的时候，不按空格或选择，点击空白，拼音会上屏，这里只有备注和任务描述是textarea
        if (value.length > 500) {
            value = value.slice(0, 500);
        }
        if (key === 'remark') {
            value = {
                id: (taskDetail.remark as RemarkInfo).id,
                remark: value,
            };
        }
        const task = {
            ...taskDetail,
            [`${key}`]: value,
        };
        setTaskDetail(task);
    }

    function onAttachmentRemove(fileId: string) {
        if (!taskDetail) {
            return;
        }
        const index = taskDetail.resources.findIndex((item) => item.bizId === fileId);
        if (index < -1) {
            return;
        }
        const temp = taskDetail.resources.map((item) => {
            return {
                ...item,
                fileName: item.name,
                fileId: item.bizId || nanoid(20),
                fileUrl: item.url,
                status: FileStatus.Done,
            };
        });
        temp.splice(index, 1);

        setNewFileList(temp as any);
        setTaskDetail({ ...taskDetail, resources: temp });
    }

    async function onAttachmentUploadFinish(file: any) {
        if (!taskDetail) {
            return;
        }
        const resources = taskDetail.resources;
        if (Array.isArray(file)) {
            const files = file;
            const NewResource = files.map((f) => {
                return {
                    ...f,
                    name: f.fileName,
                    size: f.size,
                    url: f.fileUrl,
                    resourceId: nanoid(),
                    bizId: f.fileId,
                    bizType: f.bizType || TaskResourceBizType.FILE,
                    resourceType: TaskResourcePermissionType.COMMON,
                    fileType: f.fileType,
                    creatorUserId: f?.creatorUserId,
                    isRemove: true,
                    fileName: f.fileName,
                    fileId: f.bizId || nanoid(20),
                    fileUrl: f.url,
                    status: FileStatus.Done,
                };
            });
            resources.push(...NewResource);
            resources.forEach((item) => {
                Object.assign(item, {
                    fileName: item.name,
                    fileId: item.bizId || nanoid(20),
                    fileUrl: item.url,
                    status: FileStatus.Done,
                });
            });
            setNewFileList(resources as any);
            setTaskDetail({ ...taskDetail, resources });
        } else {
            const NewResource = {
                resourceId: nanoid(),
                name: file.fileName,
                size: file.size,
                url: file.fileUrl,
                bizId: file.fileId,
                bizType: file.bizType || TaskResourceBizType.FILE,
                resourceType: TaskResourcePermissionType.COMMON,
                fileType: file.fileType,
                creatorUserId: file?.creatorUserId,
                isRemove: true,
                fileName: file.fileName,
                fileId: file.bizId || nanoid(20),
                fileUrl: file.url,
                status: FileStatus.Done,
            };

            resources.push(NewResource);
            resources.forEach((item) => {
                Object.assign(item, {
                    fileName: item.name,
                    fileId: item.bizId || nanoid(20),
                    fileUrl: item.url,
                    status: FileStatus.Done,
                });
            });

            setNewFileList(resources as any);
            setTaskDetail({ ...taskDetail, resources });
        }
        window.console.log('nnnddd-----:', resources.length);
    }

    const handleList = useCallback(
        (data) => {
            const setData = data.map((item: any) => {
                return {
                    ...item,
                    isRemove:
                        !isTaskFinishOrStop &&
                        (isHead ||
                            (self &&
                                item.creatorUserId &&
                                item.creatorUserId === userData?.userId) ||
                            (self && !item.creatorUserId))
                            ? true
                            : false,
                };
            });
            return setData;
        },
        [isTaskFinishOrStop, isHead, self, userData]
    );
    const onVisibleChange = (visable: boolean) => {
        if (visable) {
            // 显示的时候，高低太低会使时间选择框错位，手动向下滚动一下
            const detailElement: HTMLElement | null = document.querySelector('.detail-content');
            if (detailElement && detailElement.offsetHeight + detailElement.scrollTop < 710) {
                detailElement.scrollTo(0, 710 - detailElement.offsetHeight);
            }
        }
    };
    const handleTaskReminChange = function (val: string[]) {
        if (val.length === 2 && val[0] === '0') {
            val.shift();
        }
        if (val.length >= 1 && val[val.length - 1] === '0') {
            setTaskDetail({
                ...(taskDetail as NewTaskModel),
                remind: '0',
            });
            setRemindArr(['0']);
        } else {
            setTaskDetail({
                ...(taskDetail as NewTaskModel),
                remind: val.join(','),
            });
            setRemindArr([...val]);
        }
    };
    const DrawerFooter = (
        <div>
            <Button
                type="primary"
                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_edit_sure"
                loading={submitting}
                style={{ margin: '0 8px' }}
                onClick={modifyValidate}
            >
                {t('confirm')}
            </Button>
            <Button
                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_edit_cancel"
                onClick={closeDrawConfirm}
            >
                取 消
            </Button>
        </div>
    );

    if (!drawEditVisible) {
        return null;
    }
    return (
        <Drawer
            width={450}
            placement="right"
            keyboard={false}
            closable={false}
            onClose={closeDrawConfirm}
            className="work-edit-draw"
            visible={drawEditVisible}
            destroyOnClose={true}
            footer={DrawerFooter}
        >
            {!taskDetail ? (
                <div className="draw-loading">
                    <Spin />
                </div>
            ) : (
                <>
                    <div className="detail-header">
                        <div>{t('task edit detail')}</div>
                        <div className="header-btn-wrapper">
                            <div className="action-close action-button" onClick={closeDrawConfirm}>
                                <IconFont type="iconapp_btn_file_cancel" />
                            </div>
                        </div>
                    </div>
                    <ScrollView ref={scrollRef} autoHide>
                        <div className="detail-content">
                            <div className="content-part-top">
                                <div className="detail-content-item task-title-item">
                                    <div className="title-container">
                                        {!isTaskFinishOrStop && isPrimaryHead ? (
                                            <>
                                                {/* <Input
                                                    className="cursor-outline"
                                                    defaultValue={htmlUnescape(taskDetail.title)}
                                                    placeholder={t('input task content')}
                                                    onChange={(e) =>
                                                        setTaskDetail({
                                                            ...taskDetail,
                                                            title: e.target.value,
                                                        })
                                                    }
                                                    maxLength={100}
                                                /> 
                                                 <p className="content-length">
                                                    <span>{taskDetail.title?.length || 0}</span>/100
                                                </p> */}
                                                <Input.TextArea
                                                    className={`cursor-outline ${
                                                        taskDetail.content &&
                                                        taskDetail.content.length > 40
                                                            ? 'padding-bottom-20'
                                                            : ''
                                                    }`}
                                                    style={{ resize: 'none' }}
                                                    autoSize={{ minRows: 1, maxRows: 3 }}
                                                    placeholder={t('input task content')}
                                                    defaultValue={htmlUnescape(taskDetail.title)}
                                                    onChange={(e) =>
                                                        setTaskDetail({
                                                            ...taskDetail,
                                                            title: e.target.value,
                                                        })
                                                    }
                                                    maxLength={100}
                                                />
                                                {taskDetail.title && taskDetail.title.length > 30 && (
                                                    <p className="content-length">
                                                        <span>{taskDetail.title?.length || 0}</span>
                                                        /100
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <div className="title-wrapper">
                                                {/* {taskDetail.dueTag && (
                                                    <span
                                                        className="title-wrapper-tag"
                                                        style={{
                                                            backgroundColor:
                                                                'rgba(246, 100, 45, 0.2)',
                                                            color: 'rgb(246, 100, 45)',
                                                        }}
                                                    >
                                                        逾期
                                                    </span>
                                                )} */}
                                                <Tooltip
                                                    placement="bottom"
                                                    arrowPointAtCenter
                                                    title={taskDetail?.title}
                                                >
                                                    <span className="title-wrapper-title">
                                                        {taskDetail?.title}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="detail-content-type">{t('members info')}</div> */}
                                <div className="detail-content-item task-owner-item">
                                    <div className="label">
                                        <span>*</span>
                                        {t('task head')}
                                    </div>
                                    <div className="owner-container">
                                        <UserList
                                            disable={taskDetail0?.owner}
                                            list={[taskDetail.owner]}
                                            maxLength={8}
                                            isShowName={true}
                                            notAvatar={true}
                                            notTooltip={true}
                                            addMemberCb={handleUpdateOwner}
                                            title={taskDetail.title}
                                        />
                                        {taskDetail.owner && (
                                            <div
                                                className="trans-owner"
                                                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_transferowner"
                                                onClick={handleUpdateOwner}
                                            >
                                                {isPrimaryHead && <span>{t('update owner')}</span>}
                                                {/* <IconFont type="iconlog_enter" className="icon-more" /> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="detail-content-item task-executor-item"
                                    style={{ paddingRight: '10px' }}
                                >
                                    <div className="label">{t('executor')}</div>
                                    <div className="executor-container">
                                        <UserList
                                            list={handleList(taskDetail.executors)}
                                            maxLength={8}
                                            isShowName={true}
                                            // hasWeightSet={true}
                                            notAvatar={true}
                                            title={taskDetail.title}
                                            disable={
                                                disabled ||
                                                !isPrimaryHead ||
                                                taskDetail.executors.length >= 200
                                            }
                                            handleAddTaskMembers={handleAddTaskMembers}
                                            onRemove={handledelMember}
                                            userPopover="task"
                                        />
                                        <UserStatusListPopover
                                            list={handleList(taskDetail.executors)}
                                            onRemove={handledelMember}
                                            disable={disabled}
                                            isHead={isPrimaryHead}
                                            userPopover="task"
                                            userData={userData}
                                            taskDetail={taskDetail}
                                            mode="edit"
                                            handleTaskMembersWeight={handleTaskMembersWeight}
                                            hasWeightSet={false}
                                        >
                                            <IconFont type="iconlog_enter" className="icon-more" />
                                        </UserStatusListPopover>
                                    </div>
                                </div>
                                {/* <div className="detail-content-type">{t('detail info')}</div> */}
                                <div className="detail-content-item task-type-item">
                                    <div className="label">
                                        <span>*</span>
                                        {t('type')}
                                    </div>
                                    <TypeSelect
                                        disable={isTaskFinishOrStop || !isPrimaryHead}
                                        priority={
                                            taskDetail.priorityType ? taskDetail.priorityType : -1
                                        }
                                        onRadioChange={handlePriorityChange}
                                        type="radio"
                                        hide={isTaskFinishOrStop || !isPrimaryHead ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="content-part-other">
                                <div className="detail-content-item task-timerange-item common-single-item">
                                    <div className="label">{t('Start-stop time')}</div>
                                    <div className="timerange-container">
                                        {isTaskFinishOrStop || !isPrimaryHead ? (
                                            <Tooltip
                                                title={
                                                    isTaskFinishOrStop
                                                        ? `任务${
                                                              taskDetail.taskStatus ===
                                                              TASK_STATUS.FINISHED
                                                                  ? '已完成'
                                                                  : '已中止'
                                                          }不能编辑时间`
                                                        : '您暂无修改权限'
                                                }
                                            >
                                                <div className="time-button time-button-star">
                                                    <DatePicker
                                                        onVisibleChange={onVisibleChange}
                                                        time={taskDetail.workStartTime || null}
                                                        onChange={(time: Dayjs | null) =>
                                                            handleTimeChange(time)
                                                        }
                                                        disable={
                                                            isTaskFinishOrStop || !isPrimaryHead
                                                        }
                                                        placeholder={t('no start time')}
                                                        defaultTime={getDefaultStartTime()}
                                                    />
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <div className="time-button time-button-star">
                                                <DatePicker
                                                    onVisibleChange={onVisibleChange}
                                                    time={taskDetail.workStartTime || null}
                                                    onChange={(time: Dayjs | null) =>
                                                        handleTimeChange(time)
                                                    }
                                                    disable={isTaskFinishOrStop || !isPrimaryHead}
                                                    placeholder={t('no start time')}
                                                    defaultTime={getDefaultStartTime()}
                                                />
                                            </div>
                                        )}

                                        <div className="button-split" />
                                        {isTaskFinishOrStop || !isPrimaryHead ? (
                                            <Tooltip
                                                title={
                                                    isTaskFinishOrStop
                                                        ? `任务${
                                                              taskDetail.taskStatus ===
                                                              TASK_STATUS.FINISHED
                                                                  ? '已完成'
                                                                  : '已中止'
                                                          }不能编辑时间`
                                                        : '您暂无修改权限'
                                                }
                                            >
                                                <div className="time-button time-button-end">
                                                    <DatePicker
                                                        time={taskDetail.workStopTime || null}
                                                        onChange={(time: Dayjs | null) =>
                                                            handleTimeChange(time, false)
                                                        }
                                                        disable={
                                                            isTaskFinishOrStop || !isPrimaryHead
                                                        }
                                                        placeholder={t('no end time')}
                                                        defaultTime={getDefaultStopTime()}
                                                    />
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <div className="time-button time-button-end">
                                                <DatePicker
                                                    time={taskDetail.workStopTime || null}
                                                    onChange={(time: Dayjs | null) =>
                                                        handleTimeChange(time, false)
                                                    }
                                                    disable={isTaskFinishOrStop || !isPrimaryHead}
                                                    placeholder={t('no end time')}
                                                    defaultTime={getDefaultStopTime()}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="detail-content-item task-remind-item common-single-item">
                                    <div className="label">逾期提醒</div>
                                    <Select
                                        disabled={
                                            isTaskFinishOrStop ||
                                            !isPrimaryHead ||
                                            !taskDetail.endTime ||
                                            taskDetail.endTime === -1
                                        }
                                        mode="multiple"
                                        allowClear={false}
                                        size="small"
                                        showArrow={
                                            !isTaskFinishOrStop &&
                                            isPrimaryHead &&
                                            !!taskDetail.endTime &&
                                            taskDetail.endTime !== -1
                                        }
                                        showSearch={false}
                                        value={remindArr}
                                        placeholder={
                                            isTaskFinishOrStop || !isPrimaryHead
                                                ? '暂无提醒时间'
                                                : '请选择提醒时间'
                                        }
                                        className="timerange-container"
                                        onChange={handleTaskReminChange}
                                    >
                                        {remindList.map((item: any) => {
                                            return (
                                                <Option key={item.value} value={item.value}>
                                                    {item.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="detail-content-item task-textarea-item">
                                    <div className="label" style={{ alignSelf: 'flex-start' }}>
                                        {t('task description')}
                                    </div>
                                    <div
                                        className={`textarea-container ${
                                            textareaContentFocus && (descIsEdit || isPrimaryHead)
                                                ? 'textarea-container-focus'
                                                : ''
                                        }`}
                                    >
                                        <Input.TextArea
                                            className={`cursor-outline ${
                                                taskDetail.content && taskDetail.content.length > 40
                                                    ? 'padding-bottom-20'
                                                    : ''
                                            }`}
                                            placeholder={t('add description')}
                                            autoSize
                                            disabled={!descIsEdit && !isPrimaryHead}
                                            defaultValue={htmlUnescape(taskDetail.content || '')}
                                            onChange={(e) => handleInputChange(e, 'content')}
                                            onFocus={() => setTextareaContentFocus(true)}
                                            onBlur={() => setTextareaContentFocus(false)}
                                            maxLength={500}
                                        />
                                        {taskDetail.content && taskDetail.content.length > 40 && (
                                            <p className="content-length">
                                                <span>{taskDetail.content?.length || 0}</span>/500
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="content-part-other">
                                <div className="detail-content-item task-attachment-item">
                                    <Collapse
                                        defaultActiveKey={taskDetail?.resources?.length ? [0] : []}
                                        bordered={false}
                                        expandIconPosition="right"
                                        expandIcon={({ isActive }) => (
                                            <div
                                                style={{
                                                    display: !newFileList?.length ? 'none' : '',
                                                }}
                                            >
                                                <IconFont
                                                    type="iconlog_enter"
                                                    className="icon-more"
                                                    rotate={isActive ? 270 : 90}
                                                />
                                            </div>
                                        )}
                                        onChange={function name(params: any) {
                                            if (clicksign && params.length) {
                                                attachmentMenuShowFn(100);
                                            }
                                            clicksign = false;
                                        }}
                                    >
                                        <Panel
                                            header={
                                                <div
                                                    className="label"
                                                    style={{ alignSelf: 'flex-start' }}
                                                >
                                                    <b>{t('attachment')}</b>{' '}
                                                    {newFileList?.length || 0}
                                                    <div
                                                        className="rg-handle"
                                                        style={{
                                                            marginRight: !newFileList?.length
                                                                ? '-10px'
                                                                : '',
                                                        }}
                                                        onClick={function (a) {
                                                            clicksign = true;
                                                        }}
                                                    >
                                                        <IconFont
                                                            className="lb-conclude"
                                                            type="iconapp_btn_add2"
                                                            style={{
                                                                color: (window as any).styleSass
                                                                    .primaryColor,
                                                                position: 'static',
                                                            }}
                                                        />
                                                        <em>添加附件</em>
                                                    </div>
                                                </div>
                                            }
                                            key={0}
                                            forceRender={true}
                                        >
                                            <WorkAttachmentComp
                                                onRemove={onAttachmentRemove}
                                                onFinish={onAttachmentUploadFinish}
                                                fileList={handleList(newFileList)}
                                                disabled={disabled}
                                                mode="modify"
                                                isHead={isHead}
                                                uploaderProxy={true}
                                                multiple={true}
                                                autoShowMenuFnSetter={setAttachmentMenuShowFn}
                                            >
                                                <p>+添加附件</p>
                                            </WorkAttachmentComp>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                            <div className="content-part-other">
                                <div className="detail-content-item task-textarea-item task-mynote-item">
                                    <Collapse
                                        defaultActiveKey={taskDetail?.remark?.remark ? [0] : []}
                                        bordered={false}
                                        expandIconPosition="right"
                                        expandIcon={({ isActive }) => (
                                            <IconFont
                                                type="iconlog_enter"
                                                className="icon-more"
                                                rotate={isActive ? 270 : 90}
                                            />
                                        )}
                                    >
                                        <Panel
                                            header={
                                                <div
                                                    className="label"
                                                    style={{ alignSelf: 'flex-start' }}
                                                >
                                                    {t('my note')}
                                                    <em>(仅自己可见)</em>
                                                </div>
                                            }
                                            key={0}
                                        >
                                            <div
                                                className={`textarea-container ${
                                                    textareaRemarkFocus
                                                        ? 'textarea-container-focus'
                                                        : ''
                                                }`}
                                            >
                                                <Input.TextArea
                                                    className={`cursor-outline ${
                                                        taskDetail.remark?.remark &&
                                                        taskDetail.remark.remark.length > 40
                                                            ? 'padding-bottom-20'
                                                            : ''
                                                    }`}
                                                    placeholder="请输入"
                                                    autoSize
                                                    defaultValue={htmlUnescape(
                                                        (taskDetail.remark as RemarkInfo).remark
                                                    )}
                                                    onChange={(e) => handleInputChange(e, 'remark')}
                                                    onFocus={() => setTextareaRemarkFocus(true)}
                                                    maxLength={500}
                                                />{' '}
                                                {taskDetail.remark?.remark &&
                                                    taskDetail.remark.remark.length > 40 && (
                                                        <p className="content-length">
                                                            <span>
                                                                {taskDetail.remark?.remark
                                                                    ?.length || 0}
                                                            </span>
                                                            /500
                                                        </p>
                                                    )}
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </>
            )}
        </Drawer>
    );
}
function mapStateToProps({ work, user }: any) {
    const { detailState } = work;
    return {
        detailStateActiveTab: detailState.activeTab,
        userData: user.userData.user,
        drawEditVisible: detailState.drawEditVisible,
        taskId: detailState.taskId,
        taskDetail0: detailState.taskDetail,
        forceGet: detailState.forceGet,
        offset: work.offset,
        offsets: work.offsets,
        processStatuses: work.processStatuses,
        taskStatuses: work.taskStatuses,
        priorityTypes: work.priorityTypes,
        userRole: work.userRole,
        sortType: work.sortType,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeEditTaskDraw: (taskId: string, visible: boolean) =>
            dispatch({
                type: 'work/changeEditTaskDraw',
                payload: { taskId, drawEditVisible: visible },
            }),
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
        getTasks(payload: TaskListParams) {
            return dispatch({
                type: 'work/fetchTaskList',
                payload,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailDrawEdit);
