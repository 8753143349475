import React from 'react';
import { Popover } from 'antd';
// import { IconMore } from '@/components/icon';
// import { Avatar } from '@jd/focus-desktop-comps';
import { Avatar } from '@/baseComponents/Avatar';
import { changeEgovUrl } from '@/utils/tools';
import IconFont from '@/components/icon';

export interface TeamItemInfo {
    emplEnabled?: string;
    account: string;
    ddAppId: string;
    headImg: string | null;
    mobile: string;
    realName: string;
    teamAvatarUrl?: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
}

function TeamItem({ team, onClick }: { team: TeamItemInfo; onClick?: any }) {
    const avatar = (
        <Avatar
            src={changeEgovUrl(team.teamAvatarUrl || '')}
            name={team.teamShortName}
            styleObj={{ width: 30, height: 30, borderRadius: 7, border: '1px solid white' }}
        />
    );
    const comp = (
        <div
            className="teamListItem"
            style={{
                width: 30,
                height: 30,
                borderRadius: 7,
                marginTop: 20,
            }}
            onClick={onClick}
        >
            {avatar}
        </div>
    );
    // return (
    //     <Tooltip placement="right" title={team.teamShortName} destroyTooltipOnHide>
    //         {comp}
    //     </Tooltip>
    // );
    return comp;
}

const TeamItem1 = ({
    team,
    selected,
    onClick,
}: {
    team: TeamItemInfo;
    selected: boolean;
    onClick?: any;
}) => {
    const avatar = (
        <Avatar
            src={changeEgovUrl(team.teamAvatarUrl || '')}
            name={team.teamShortName}
            styleObj={{ width: 30, height: 30, borderRadius: 7, border: '1px solid white' }}
        />
        // <Avatar
        //     src={changeEgovUrl(team.teamAvatarUrl || '')}
        //     alt={team.teamShortName}
        //     style={{
        //         width: 30,
        //         height: 30,
        //         borderRadius: 7,
        //         border: '1px solid white',
        //     }}
        // />
    );

    return (
        <div
            className="teamListItem"
            style={{
                width: 170,
                height: 30,
                borderRadius: 7,
                marginTop: 20,
            }}
            onClick={onClick}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 150,
                }}
            >
                {avatar}
                <span
                    style={{
                        fontSize: 16,
                        marginLeft: 5,
                        flex: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {team.teamFullName}
                </span>
            </div>
            <span style={{ float: 'right', marginTop: -25 }}>
                {selected && <IconFont type="iconic_check" />}
            </span>
        </div>
    );
};
export default class TeamList extends React.PureComponent<{
    teams: TeamItemInfo[];
    selectedTeamId?: string;
    onItemClick?: (team: TeamItemInfo) => void;
}> {
    getPopover(teams: TeamItemInfo[], selectedTeam: TeamItemInfo) {
        const { onItemClick } = this.props;
        if (!teams.length) {
            return null;
        }
        return (
            <Popover
                placement="right"
                trigger="click"
                overlayClassName="teamListPopover"
                content={this.props.teams.map((item) => (
                    <div key={item.teamId} className="teamPopoverItem">
                        <TeamItem1
                            team={item}
                            selected={item.teamId === selectedTeam.teamId}
                            onClick={() => {
                                if (onItemClick) {
                                    onItemClick(item);
                                }
                            }}
                        />
                    </div>
                ))}
            >
                <div
                    className="teamListItem"
                    style={{
                        width: 30,
                        height: 30,
                        borderRadius: 7,
                        marginTop: 20,
                        color: 'rgba(255, 255, 255, .5)',
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    {/* {IconMore} */}
                    <TeamItem key={selectedTeam.teamId} team={selectedTeam} />
                </div>
            </Popover>
        );
    }
    render() {
        const { teams, selectedTeamId } = this.props;
        if (teams.length === 1) return null;
        let idx = 0;
        if (selectedTeamId) {
            idx = teams.findIndex((item) => item.teamId === selectedTeamId);
        }
        if (idx < 0) {
            idx = 0;
        }
        const team = teams[idx];
        return (
            <div
                className="no-draggable"
                style={{
                    paddingBottom: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                {/* {team && (
                    <TeamItem
                        key={team.teamId}
                        team={{ ...team, headImg: team.teamAvatarUrl || '' }}
                    />
                )} */}
                {this.getPopover([...teams.slice(0, idx), ...teams.slice(idx + 1)], team)}
            </div>
        );
    }
}
