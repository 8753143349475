/**
 * 聊天窗口，时间线
 * @author sunzhiguang
 * @date 2020/6/12
 */
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { getMessageDateFormat } from '@/utils/date';
import './index.less';
import { MessageDateFormType } from '@/types/chat/enum';
import { useTranslation } from 'react-i18next';

interface SimpleTimeProps {
    timeStamp: number; // 时间戳
}

type ISimpleTimeProps = Readonly<SimpleTimeProps>;

export default function (props: ISimpleTimeProps) {
    const { t } = useTranslation('chat');
    const getShowTime = useCallback(() => {
        const { timeStamp } = props;
        return getMessageDateFormat(timeStamp, MessageDateFormType.DATE_AGGREGATION);
    }, [t, props.timeStamp]); // eslint-disable-line

    const getClass = () => {
        return classNames({
            'message-time-line': true,
            simple: true,
        });
    };

    return (
        <div className={getClass()}>
            <div className="simple-time">{getShowTime()}</div>
        </div>
    );
}
