import bus from '@/utils/bus';
import React from 'react';
import './index.less';

export interface NavItemData {
    path: string;
    title: string;
    name?: string;
    navName?: string;
    icon: any;
    tip?: any;
    [key: string]: any;
}

interface Props extends NavItemData {
    active?: boolean;
    onClick?: (name: string) => void;
    onDoubleClick?: (name: string) => void;
}

export default function NavItem(props: Props) {
    const { active, path, title, icon, tip, name, navName } = props;
    const onClick = () => {
        if (!active && props.onClick) {
            props.onClick(path);
        }
    };
    const onDoubleClick = () => {
        if (props.onDoubleClick) {
            props.onDoubleClick(path);
        }
    };
    const bar = (
        <div
            style={{
                position: 'absolute',
            }}
        />
    );
    const tipComp = (
        <div
            style={{
                position: 'absolute',
                width: 18,
                height: 18,
                borderRadius: '50%',
                border: '1px solid #fff',
                backgroundColor: '#F5222D',
                zIndex: 1,
                color: '#fff',
                top: 5,
                right: 15,
                fontSize: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>{tip}</span>
        </div>
    );
    return (
        <div
            role="button"
            tabIndex={0}
            className={[`focus-nav-item ${navName}`, active ? 'active' : ''].join(' ')}
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 0',
                outline: 0,
                cursor: 'pointer',
            }}
            onClick={() => {
                onClick?.();
                // 因为工作台增加了缓存机制，导致切换tab时，工作台大搜不能关闭。有点不太合理，但是改不动了只能这样了
                bus.emit('onHideGlobalSearch', path);
                if (path === '/third_apps') {
                    bus.emit('onRefreshThirdapps');
                }
            }}
            onDoubleClick={onDoubleClick}
            onKeyDown={() => {}}
        >
            <div
                style={{
                    fontSize: 22,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 22,
                    height: 22,
                }}
            >
                {icon}
            </div>
            <div
                style={{
                    fontSize: 12,
                    marginTop: 3,
                }}
            >
                {title || name}
            </div>
            {active && bar}
            {tip && tipComp}
        </div>
    );
}
