import React from 'react';
import style from './index.module.less';
import { changeEgovUrl, htmlUnescape } from '@/utils/tools';

export interface IProps {
    title?: any;
    timeInfo?: any;
    icon?: any;
    clickHandler?: any;
    timeInfoRender?: Function;
}
function UIComp({ title, timeInfo, icon, clickHandler, timeInfoRender }: IProps) {
    return !title && !timeInfo ? (
        <div />
    ) : (
        <div
            className={style['notice-card-container']}
            onClick={clickHandler || null}
            style={clickHandler ? { cursor: 'pointer' } : {}}
        >
            <img src={changeEgovUrl(icon)} />
            <div style={{ width: '100%' }}>
                <div className={style['title']}>{htmlUnescape(title)}</div>
                <div className={style['time-info']}>
                    {timeInfoRender ? timeInfoRender() : timeInfo}
                </div>
            </div>
        </div>
    );
}

export default UIComp;
