import React from 'react';
import style from './index.module.less';
import { changeEgovUrl, htmlUnescape } from '@/utils/tools';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { getRepeatName } from '@/components/Calendars/Enums';
import { generateDefaultTimeFormat } from '@/components/Calendars/BaseComponents/DatePicker//utils';

export interface IProps {
    title?: any;
    businessInfo?: any;
    timeInfo?: any;
    icon?: any;
    clickHandler?: any;
    timeInfoRender?: Function;
}
function ScheduleUIComp({
    title,
    businessInfo,
    timeInfo,
    icon,
    clickHandler,
    timeInfoRender,
}: IProps) {
    const { t } = useTranslation('calendar');
    const isAllDay = businessInfo?.isAllDay;
    const isRepeat = businessInfo?.repeat;
    let endTime = isAllDay
        ? dayjs(businessInfo?.endTime).subtract(1, 'day')
        : businessInfo?.endTime;
    if (isRepeat) {
        endTime = businessInfo?.repeatEndTime;
    }
    const isPast = isAllDay
        ? dayjs(endTime).isBefore(dayjs(), 'date')
        : dayjs(endTime).isBefore(dayjs(), 'minute');

    return !timeInfo ? (
        <div />
    ) : (
        <div
            className={style['notice-card-container']}
            onClick={clickHandler || null}
            style={clickHandler ? { cursor: 'pointer' } : {}}
        >
            <div style={{ width: '100%' }}>
                <span className={style['title-icon']}>{t('schedule')}</span>
                <div className={style['schedule-info']}>
                    <div className={style['time']}>
                        <img src={changeEgovUrl(icon)} />
                        <label>{timeInfoRender ? timeInfoRender() : timeInfo}</label>
                    </div>
                    {!!isRepeat && (
                        <div>
                            <IconFont
                                className={style['repeat-content-icon']}
                                type="iconic_pc_calender_repeat1"
                            />
                            <label>{`${t(getRepeatName(businessInfo?.repeat))}重复${
                                businessInfo?.repeatEndTime
                                    ? '，' +
                                      generateDefaultTimeFormat(
                                          dayjs(businessInfo?.repeatEndTime),
                                          t
                                      ).date +
                                      '结束'
                                    : ''
                            }`}</label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ScheduleUIComp;
