import { DownloadItem, DownloadProgress } from '@/utils';
import { downloadMessageFile } from '@/utils/chat/index';
import DownloadFileQueue, { SaveOption, DownloadOption } from '@/utils/DownloadFileQueue';
import { FileStatus } from '@/types/chat';

class DownloadService {
    private static instance: DownloadService | null;
    private static downloadingMids: string[] = [];
    public static getInstance({
        updateDownloadingMids,
    }: {
        updateDownloadingMids?: Function;
    }): DownloadService {
        if (!this.instance) {
            this.instance = new DownloadService({ updateDownloadingMids });
        }
        return this.instance;
    }

    private updateDownloadingMids: (mids: string[]) => {};
    private downloadItemMap: Map<number, any> = new Map();
    private downloadOptionsMap: Map<number, any> = new Map();
    private downloadSaveOption: Map<number, SaveOption> = new Map();
    private downloadStatus: Map<number, string> = new Map();

    constructor(props: any) {
        this.updateDownloadingMids = props.updateDownloadingMids || null;
    }

    public download = (
        { viewFile, saveAs, message, auto }: SaveOption,
        options: DownloadOption
    ) => {
        const mid = message.mid;
        // if(this.downloadSaveOption.has(mid)) {
        //     this.downloadItemMap
        // }
        // 另存为时判断该文件是否已经在下载中，如果是需要把之前的下载中断
        const alreadySaveAs = saveAs && this.downloadSaveOption.has(mid);
        this.downloadSaveOption.set(mid, { viewFile, saveAs, message, auto });
        if (!auto) {
            DownloadFileQueue.getInstance()?.delQueue(mid);
        }
        const downloadOptions = {
            onStarted: (downloadItem: DownloadItem) => {
                DownloadService.downloadingMids.push(mid);
                this.updateDownloadingMids?.([...DownloadService.downloadingMids]);
                this.downloadItemMap.set(mid, downloadItem);
                this.downloadStatus.set(mid, FileStatus.DOWNLOADING);
                options.onStarted(downloadItem);
            },
            onProgress: (progress: DownloadProgress) => {
                if (!DownloadService.downloadingMids.includes(mid)) {
                    DownloadService.downloadingMids.push(mid);
                    this.updateDownloadingMids?.([...DownloadService.downloadingMids]);
                }
                this.downloadStatus.set(mid, FileStatus.DOWNLOADING);
                options.onProgress(progress);
            },
            onCancel: () => {
                options.onCancel(alreadySaveAs);
            },
            onPause: () => {
                if (options.onPause) {
                    this.downloadStatus.set(mid, FileStatus.CANCELED);
                    options.onPause();
                }
            },
            onResume: () => {
                if (options.onResume) {
                    this.downloadStatus.set(mid, FileStatus.DOWNLOADING);
                    options.onResume();
                }
            },
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                this.clearServiceItem(mid);
                this.downloadStatus.set(mid, FileStatus.COMPLETED);
                options.onFinish(file, data);
            },
        };
        this.downloadOptionsMap.set(mid, downloadOptions);
        return downloadMessageFile(
            { viewFile, saveAs, message, auto, alreadySaveAs },
            downloadOptions
        );
    };

    public setDownloadStatus = (mid: number, status: string) => {
        this.downloadStatus.set(mid, status);
    };

    public getDownloadStatus = (mid: number) => {
        const status = this.downloadStatus.get(mid);
        if (status) return status;
        return FileStatus.INIT;
    };

    public getSaveOption = (mid: number) => {
        const saveOption = this.downloadSaveOption.get(mid);
        if (saveOption) {
            return saveOption;
        }
        return null;
    };

    public getDLServiceItem = (mid: number, options: DownloadOption) => {
        const downloadItem = this.downloadItemMap.get(mid);
        const downloadOptions = this.downloadOptionsMap.get(mid);
        if (downloadOptions) {
            downloadOptions.onStarted = options.onStarted;
            downloadOptions.onProgress = options.onProgress;
            downloadOptions.onPause = () => {
                if (options.onPause) {
                    this.downloadStatus.set(mid, FileStatus.CANCELED);
                    options.onPause();
                }
            };
            downloadOptions.onResume = options.onResume;
            downloadOptions.onCancel = () => {
                if (options.onCancel) {
                    options.onCancel();
                }
            };
            downloadOptions.onFinish = (file: { path: string; filename: string }, data: any) => {
                this.clearServiceItem(mid);
                options.onFinish(file, data);
            };
            // return downloadOptions;
            return downloadItem;
        }
    };

    private clearServiceItem = (mid: number) => {
        this.downloadItemMap.delete(mid);
        this.downloadOptionsMap.delete(mid);
        this.downloadSaveOption.delete(mid);
        const index = DownloadService.downloadingMids.findIndex((item: any) => item === mid);
        if (index > -1) {
            DownloadService.downloadingMids.splice(index, 1);
        }
        this.updateDownloadingMids?.([...DownloadService.downloadingMids]);
    };
}

export default DownloadService;
