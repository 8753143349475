import { IPageAddCallback, api } from './index';
import { PageType } from '../const';
import { lang } from '../context';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DownloadTypeEnum } from '@/types/chat/enum';
const { confirm } = Modal;
import events from '../context/events';
import { downLoadFile } from '@/utils/chat/index';

const download: IPageAddCallback = (props, isAllow) => {
    const { data } = props;

    if (data.page_type !== PageType.File) {
        return;
    }
    return [
        {
            children: lang('ctx.download.title'),
            callbackOrModal: async () => {
                confirm({
                    title: '您的下载操作将被记录，是否确认下载?',
                    okText: '确定',
                    cancelText: '取消',
                    async onOk() {
                        // downloadFileLog(data.id, DownloadTypeEnum.SPACE).then((_res) => {
                        //     // console.log(_res, 'resresres');
                        //     if (_res) {
                        //         downLoadFile(data.title, res.link);
                        //     }
                        // });
                        const res = await api.file.download(data.id);
                        if (res?.link) {
                            downLoadFile(data.title, res.link);
                        } else {
                            message.error('文档不存在');
                        }
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
                // downLoadFile(data.title, res.link);
                // window.open(res.link);
            },
            isAllow,
            type: 'download',
            icon: 'pc_yundoc_download',
            splitCode: 1,
        },
    ];
};

export default download;
