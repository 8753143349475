import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import log from 'loglevel';
import { Modal, Tooltip, message as toast, Input, message } from 'antd';
import i18n from 'i18next';
import { debounce } from 'lodash';
import { v4 } from 'uuid';
import { sendNoticeCardMessage } from '@/api/chat';
import { ZoomMeetingStatus, ZoomSessionStateEnum } from '@/types/common';
import { isFocusEnv, FocusSDK, JoyMeetingStatusEnum, getClientType } from '@/utils';
import bus from '@/utils/bus';
import { conferenceCall, createMeeting, sendConferenceCardMsg } from '@/api/meeting';
import cache from '@/utils/cache';
import ImService from '@/server/ImService';
import { NetType } from '@/components/Modals/ChangeNetModal';
import { getBigScreenMeetingTopic } from '@/baseComponents/Meeting/utils';
import CHAT_NOTICE from '@/contant/chat';

const logger = log.getLogger('PortalLayout');

export const ZOOM_PASS_LENGTH = 6;

let currentCloudUnionMeetingInfo: any = null;

export function getCurrentCloudUnionMeetingInfo() {
    return currentCloudUnionMeetingInfo;
}
export function setCurrentCloudUnionMeetingInfo(meetingInfo: any) {
    currentCloudUnionMeetingInfo = meetingInfo;
}

export function handleCreateGroupMeeting(
    participants: any[],
    meetingTopic = getBigScreenMeetingTopic()
) {
    handleCreateMeeting(
        participants.map((item) => {
            return {
                ...item,
                userName: item.realName,
            };
        }),
        meetingTopic
    );
}

export function transReceiveData(data: any) {
    let { body } = data;
    const { ext = {} } = body || data; // 应用的会邀和聊天的会邀结构不一致。应用的会邀没有body
    const {
        sessionState,
        meetingNumber,
        meetingPassword,
        meetingTopic,
        fromUser,
        toUsers = [],
    } = ext;
    const receive = {
        fromUser,
        sessionState,
        meetingNumber,
        meetingTopic,
        toUsers,
        meetingPassword,
    };
    return receive;
}

export function isSameUser(from: any, to: any) {
    if (from.userId && !from.pin) {
        // eslint-disable-next-line no-param-reassign
        from.pin = from.userId;
    }
    if (to.userId && !to.pin) {
        // eslint-disable-next-line no-param-reassign
        to.pin = to.userId;
    }
    return from && to && from.pin === to.pin && from.teamId === to.teamId && from.app === to.app;
}

export function handleMeetingSendMessage({
    imService,
    currentEmployee,
    data,
    t,
}: {
    imService: any;
    currentEmployee: { app: string; userId: string; teamId: string };
    data: any;
    t: any;
}) {
    const { body, requestCard } = data;
    const toUsers = data.toUsers.filter((item: any) => item.userId !== currentEmployee.userId);

    if (toUsers?.length === 0 || !body) {
        return;
    }
    if (body.ext?.toUsers && Array.isArray(body.ext?.toUsers)) {
        body.ext.toUsers.forEach((user: any) => {
            user.avatar = user.headImg;
        });
    }
    FocusSDK.sendIpcMessage('voip.sendVoipCall', {
        toUsers: toUsers.map((item: any) => {
            return {
                ...item,
                appId: item.app,
            };
        }),
        body,
    });
    if (body.ext.sessionState === ZoomSessionStateEnum.Call) {
        // 语音通话卡片
        const { ext } = body;

        sendConferenceCardMsg({
            noticeId: CHAT_NOTICE.MEETING_PIN,
            title: ext.meetingTopic,
            receivers: toUsers.map(({ app, pin, teamId }: any) => {
                return {
                    app,
                    pin,
                    teamId,
                };
            }),
            meetingInfoReq: {
                meetingNumber: ext.meetingNumber,
                initiator: ext.fromUser.name,
            },
            infox: {
                password: ext.meetingPassword,
                deepLink: generateNoticeCardMessage(toUsers, body).infox.deepLink,
                btnName: '立即入会',
                action: 'joinmeeting',
                type: body.ext.enableVideo === 0 ? 'voice' : 'zoom',
            },
        });
        log.debug('cache.telcall', cache.telCall);
        cache.telCall &&
            conferenceCall({
                userIds: toUsers.map((item: any) => item.userId),
                meetingId: body.ext.meetingNumber.toString(),
                password: body.ext.meetingPassword || '',
                topic: body.ext.meetingTopic,
                sdkType: 'zoom',
            }).then(([res, err]) => {
                if (err) {
                    logger.error(err);
                }
            });
    }
    toUsers.forEach((user: any) => {
        const sessionId = imService.generateSingleSessionId(currentEmployee, user);
        const newMessage = {
            sessionId,
            ...body,
            requestData: {
                sessionId,
            },
            type: ChatMessageType.SYS,
            flag: 100,
        } as any;
        const messageId = generateMessageId();
        imService
            .sendChatMessage(sessionId, messageId, newMessage, { skipCheckSession: true })
            .then((res: any) => {
                logger.debug('send chat message ok: ', res);
            })
            .catch((e: any) => {
                logger.error('send chat message err', e);
            });
    });
}

export function generateNoticeCardMessage(toUsers: any, body: any) {
    const { ext } = body;
    const deepLink =
        'openapp.jdfocus://jm/biz/appcenter/joymeeting?mparam=' +
        encodeURIComponent(
            JSON.stringify({
                device: null,
                displayName: null,
                enableMicphone: 0,
                enableVideo: 0,
                ...ext,
                fromUser: {
                    clientType: getClientType(),
                    info: null,
                    intro: null,
                    nameIndex: null,
                    xToken: null,
                    ...ext.fromUser,
                },
            })
        );
    return {
        content: `${i18n.t('common:joinMeeting.meetingNumber')}：${ext.meetingNumber}`,
        extend: {
            url: '',
        },
        from: ext.fromUser,
        imgUrl: '',
        infox: {
            fromUser: '',
            imgUrl: '',
            deepLink,
            ...ext,
        },
        title: ext.meetingTopic,
        tos: toUsers,
    };
}

const debounceToast = debounce((str: string, action: 'error') => {
    toast[action](str);
}, 1500);

export function joinMeeting(
    meetingInfo: {
        password: string;
        meetingId: string;
        realName: string;
        meetingTopic?: string;
    },
    isvideooff = true,
    isaudiooff = true
) {
    if (!meetingInfo) {
        return;
    }
    let messager: string | null = null;
    if (!cache.zoomLogin) {
        messager = v4();
        message.loading({
            content: i18n.t('common:joinMeeting.waiting'),
            key: messager,
        });
    }

    function removeListener() {
        FocusSDK.off('notice.joinMeetingStatusCb', handleMeetingStatusCb);
    }

    function handleMeetingStatusCb(data: any) {
        if (messager) {
            message.destroy();
        }
        const { status } = data;
        switch (status) {
            case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                break;
            case ZoomMeetingStatus.MEETING_STATUS_INMEETING:
                removeListener();
                break;
            case ZoomMeetingStatus.MEETING_STATUS_FAILED:
                debounceToast(i18n.t('common:joinMeeting.meeting connect failed'), 'error');
                setTimeout(() => {
                    removeListener();
                }, 2000);
                break;
            case ZoomMeetingStatus.MEETING_STATUS_ENDED:
                debounceToast(i18n.t('common:joinMeeting.meeting has already ended'), 'error');
                removeListener();
                break;
            default:
                break;
        }
    }

    if (isFocusEnv()) {
        FocusSDK.once('notice.joinMeetingByInitDataCb', (data: any) => {
            if (data.status !== 'success') {
                if (data.code && Number(data.code) !== -1) {
                    setCurrentCloudUnionMeetingInfo(null);
                    toast.error(i18n.t('common:joinMeeting.There is a meeting going on'));
                    return;
                }
                if (data.status === '14') {
                    toast.error(
                        i18n.t('common:joinMeeting.The other instance of the SDK is in process')
                    );
                    return;
                }
                toast.error(i18n.t('common:joinMeeting.meeting initialization failure'));
                return;
            }
            setCurrentCloudUnionMeetingInfo({
                meetingId: meetingInfo.meetingId,
                topic: meetingInfo.meetingTopic || '',
                uuid: '',
                password: meetingInfo.password,
            });
            FocusSDK.on('notice.joinMeetingStatusCb', handleMeetingStatusCb);
        });
        // 发送消息到桌面端
        FocusSDK.sendIpcMessage('zoom.joinMeetingByInitData', {
            meetingnum: meetingInfo.meetingId,
            psw: meetingInfo.password,
            username: meetingInfo.realName,
            isvideooff,
            isaudiooff,
        });
    } else {
        bus.emit('chat_joymeeting_invite', {
            ext: {
                meetingNumber: meetingInfo.meetingId,
                meetingPassword: meetingInfo.password,
            },
        });
    }
}

export async function handleCreateMeeting(
    toUsers: {
        appId: string;
        userId: string;
        teamId: string;
        headImg: string;
        userName: string;
    }[],
    meetingTopic: string
) {
    let meetingData: any = null;
    const imService = ImService.getInstance();
    const currentEmployee = await imService.getCurrentEmployee();

    /**
     * 成功或失败后，移除监听函数
     */
    function removeListener() {
        FocusSDK.removeAllListeners('zoom.meetingStatusCb');
        FocusSDK.removeAllListeners('meeting:group call message');
    }

    function sendCallMessage(opts: {
        meetingID: string;
        meetingTopic: string;
        toUsers: {
            name: string;
            teamId: string;
            userId: string;
            appId: string;
            headImg: string;
        }[];
        meetingNumber: string;
        meetingPassword?: string;
        content: string;
    }) {
        const fromUser = {
            app: currentEmployee.app,
            pin: currentEmployee.userId,
            teamId: currentEmployee.teamId,
            name: currentEmployee.name,
            avatar: currentEmployee.avatar,
        };
        const body = {
            ext: {
                fromUser,
                displayName: 'displayName',
                projectType: 0,
                enableVideo: 0,
                avsdk: 'zoom',
                meetingID: opts.meetingID,
                meetingTopic: opts.meetingTopic,
                meetingDuration: 2,
                meetingNumber: opts.meetingNumber.toString(),
                enableMicphone: 0,
                meetingPassword: opts.meetingPassword || '',
                sessionState: 0,
                sessionType: 0,
                startTime: new Date().getTime().toString(),
            },
            flag: 100,
            state: 0,
            noPush: 0,
            type: 'sys',
            content: opts.content,
        };
        const { name, app, avatar, teamId, userId } = currentEmployee as any;
        handleMeetingSendMessage({
            imService,
            currentEmployee: { app, userId, teamId },
            data: {
                toUsers: opts.toUsers.reduce((p: any[], v) => {
                    if (
                        v.userId === fromUser?.pin &&
                        v.teamId === fromUser?.teamId &&
                        v.appId === fromUser?.app
                    ) {
                        return p;
                    }
                    p.push({
                        ...v,
                        app: v.appId,
                        pin: v.userId,
                        username: v.name,
                        avatar: v.headImg,
                    });
                    return p;
                }, []),
                body,
            },
            t: i18n.t,
        });
    }

    function handleChatMeetingMessage(data: any) {
        // 不处理所有逻辑
    }

    function handleMeetingStatusCb(status: number) {
        switch (status) {
            case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                break;
            case ZoomMeetingStatus.MEETING_STATUS_WAITINGFORHOST:
                break;
            case ZoomMeetingStatus.MEETING_STATUS_INMEETING:
                removeListener();
                break;
            // case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
            //   setConnect(true);
            //   break;
            case ZoomMeetingStatus.MEETING_STATUS_DISCONNECTING:
            case ZoomMeetingStatus.MEETING_STATUS_FAILED:
                message.error(i18n.t('joinMeeting.meeting connect failed'));
                removeListener();
                break;
            case ZoomMeetingStatus.MEETING_STATUS_ENDED:
                message.error(i18n.t('joinMeeting.meeting has already ended'));
                removeListener();
                break;
            default:
                message.error(i18n.t('joinMeeting.meeting connect failed'));
                removeListener();
                break;
        }
    }

    function handleInitCb(result: string) {
        if (result !== 'success') {
            message.error(i18n.t('joinMeeting.meeting initialization failure'));
            removeListener();
            return;
        }

        sendCallMessage({
            meetingNumber: meetingData.meetingId,
            toUsers: toUsers.map((u) => ({ ...u, name: u.userName })),
            meetingTopic: meetingData.topic,
            meetingID: meetingData.uuid,
            content: meetingData.topic,
            meetingPassword: meetingData.password,
        });

        FocusSDK.on('meeting:group call message', handleChatMeetingMessage);
        const username = currentEmployee.name;
        FocusSDK.sendIpcMessage('zoom.joinUnlogin', {
            meetingnum: meetingData.meetingId,
            psw: meetingData.password,
            username,
            isvideooff: true,
            isaudiooff: true,
        });
    }
    async function changeJoyMeetingStatusCb(args: { code: JoyMeetingStatusEnum; isFree: boolean }) {
        const { isFree, code } = args;
        if (!isFree) {
            const meetingData = getCurrentCloudUnionMeetingInfo();
            if (code === JoyMeetingStatusEnum.InMeeting && toUsers.length > 0 && meetingData) {
                // 已在会议中，直接发送邀请
                sendCallMessage({
                    meetingNumber: meetingData.meetingId,
                    toUsers: toUsers.map((u) => ({ ...u, name: u.userName })),
                    meetingTopic: meetingData.topic,
                    meetingID: meetingData.uuid,
                    content: meetingData.topic,
                    meetingPassword: meetingData.password,
                });
                bus.emit('chat_in_meeting_invite');
            } else {
                message.error(i18n.t('joinMeeting.There is a meeting going on'));
            }
            return;
        }
        const [result, err] = await createMeeting({
            startTime: (new Date().getTime() + 5000).toString(),
            duration: 2,
            topic: meetingTopic,
            password: '',
            participantIds: toUsers.map((item) => {
                return { ...item, realName: item.userName };
            }),
        });

        if (err) {
            FocusSDK.sendIpcMessage(
                'MeetingControl:changeJoyMeetingStatus',
                JoyMeetingStatusEnum.None
            );
            setCurrentCloudUnionMeetingInfo(null);
            message.error(i18n.t('joinMeeting.create meeting failed'));
            removeListener();
            return;
        }
        setCurrentCloudUnionMeetingInfo(result);
        meetingData = result;
        FocusSDK.once('zoom.initCb', handleInitCb);
        FocusSDK.on('zoom.meetingStatusCb', handleMeetingStatusCb);
        FocusSDK.sendIpcMessage('zoom.initSdk');
    }

    FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', changeJoyMeetingStatusCb);
    FocusSDK.sendIpcMessage(
        'MeetingControl:changeJoyMeetingStatus',
        JoyMeetingStatusEnum.GroupCall
    );
}

export function getJoyMeetingStatus(): Promise<JoyMeetingStatusEnum> {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            reject(new Error('time out'));
        }, 3000);
        FocusSDK.once('MeetingControl:getJoyMeetingStatusCb', ({ code, isFree }) => {
            clearTimeout(timeout);
            resolve(code);
        });
        FocusSDK.sendIpcMessage('MeetingControl:getJoyMeetingStatus');
    });
}
