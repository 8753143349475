import { DefaultPermisions, PageType } from '../const';
import { v4 } from 'uuid';
import { SyncStatusEunm } from '../controllers/JoyspaceController';

interface UserInfo {
    id: string;
    name: string;
    username: string;
    avatar_url: string;
}

interface IParams {
    space_category_id: string;
    space_id: string;
    author: UserInfo;
    pageType: PageType;
    icon_url: string;
}

class PageModel {
    isNew: boolean;
    canEdit: any;
    category: string;
    created_at: number;
    document_id: string;
    icon_url: string;
    id: string;
    isCollected: boolean;
    isShortcuted: boolean;
    is_shared: number;
    lastModifier: UserInfo;
    openTime: number;
    page_id: string;
    page_status: number;
    page_type: number;
    permissions: string[];
    preview_text: string;
    shareAt: any;
    size: number;
    spaceType: number;
    space_category_id: string;
    space_id: string;
    space_name: any;
    status: number;
    summary: string;
    team_name: any;
    template_id: string;
    title: string;
    type: string;
    unreadCount: number;
    updated_at: number;
    // sortTime: number;
    author: UserInfo;
    syncStatus: SyncStatusEunm;
    syncErrorInfo?: string;

    constructor(params: IParams) {
        const id = v4().replace(/-/g, ''); // 去掉‘-’,36位->32位
        let ms = new Date().getTime();
        this.isNew = true;
        this.canEdit = null;
        this.category = '';
        this.created_at = ms;
        this.document_id = id;
        this.icon_url = params.icon_url || 'string';
        this.id = id;
        this.isCollected = false;
        this.isShortcuted = false;
        this.is_shared = 1;
        this.lastModifier = params.author;
        this.author = params.author;
        this.openTime = ms;
        this.page_id = id;
        this.page_status = 1;
        this.page_type = params.pageType || PageType.Page;
        this.permissions = DefaultPermisions;
        this.preview_text = '';
        this.shareAt = null;
        this.size = 0;
        this.spaceType = 12;
        this.space_category_id = params.space_category_id;
        this.space_id = params.space_id;
        this.space_name = null;
        this.status = 1;
        this.summary = '';
        this.team_name = null;
        this.template_id = '';
        this.title = '无标题';
        this.type = '';
        this.unreadCount = 0;
        this.updated_at = ms;
        this.syncStatus = SyncStatusEunm.WAITING;
    }
}

export default PageModel;
