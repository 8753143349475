import { FocusSDK } from '@/utils';

export enum ConfigEnum {
    LEFT_SIDER_ENABLE = 'left.sider.enable',
    TOP_BAR_ENABLE = 'top.bar.enable',
    // 会话列表
    LEFT_SIDE_PAGE_INDEX = 'left.side.page.index',
    LEFT_SIDE_PAGE_MESSAGE = 'left.side.page.message',
    LEFT_SIDE_PAGE_CALENDAR = 'left.side.page.calendar',
    LEFT_SIDE_PAGE_DOCUMENT = 'left.side.page.document',
    LEFT_SIDE_PAGE_SETTING = 'left.side.page.setting',
    LEFT_SIDE_PAGE_THIRDAPP = 'left.side.page.thirdapp',
    LEFT_SIDE_PAGE_ADDRESS = 'left.side.page.address',
    LEFT_SIDE_PAGE_JOYSPACE = 'left.side.page.joyspace',
    LEFT_SIDE_PAGE_TEST = 'left.side.page.test',
    LEFT_SIDE_PAGE_WORK = 'left.side.page.work',
    LEFT_SIDE_PAGE_PROJECTS = 'left.side.page.projects',

    // 顶部创建按钮
    TOP_BAR_MENU_MEETING_JOIN = 'top.bar.menu.meeting.join', // 加入会议
    TOP_BAR_MENU_MEETING_CREATE = 'top.bar.menu.meeting.create', // 创建会议
    TOP_BAR_MENU_MEETING_APPOINT = 'top.bar.menu.meeting.appoint', // 预约会议
    TOP_BAR_MENU_MEETING_MEETINGLIST = 'top.bar.menu.meeting.meetinglist', // 我的会议
    // layout 配置项
    LAYOUT_USER_CENTER = 'layout.user.center', // 用户中心
    LAYOUT_CHAT_MESSAGE = 'layout.chat.message', // 消息会话
    LAYOUT_ADDRESS = 'layout.address', // 消息会话
    LAYOUT_CALENDAR = 'layout.calendar', // 日历

    // 用户中心配置
    LAYOUT_USER_CENTER_HEAD_IMG = 'layout.user.center.head.img', // 用户中心-签名
    LAYOUT_USER_CENTER_CARD = 'layout.user.center.card', // 用户中心-卡片
    LAYOUT_USER_CENTER_SIGNATURE = 'layout.user.center.signature', // 用户中心-签名
    LAYOUT_USER_CENTER_FEEDBACK = 'layout.user.center.feedback', // 用户中心-意见反馈
    LAYOUT_USER_CENTER_ABOUT = 'layout.user.center.about', // 用户中心-关于我们
    LAYOUT_USER_CENTER_LOGOUT = 'layout.user.center.logout', // 用户中心-退出

    // 外部联系人权限控制
    EXTERNAL_CONTACTS_ORG = 'external.contacts.org', // 通讯录-组织架构 和人员选择器的组织架构
    EXTERNAL_CONTACTS_MY_DEPARTMENT = 'external.contacts.my.department', // 通讯录-我的部门
    EXTERNAL_CONTACTS_SEARCH_DEPARTMENT = 'external.search.department', // 搜索-部门
    EXTERNAL_CONTACTS_ADD_CONTACTS = 'external.contacts.add.contacts', // 消息-搜索-添加联系人
    EXTERNAL_CONTACTS_CREAT_GROUP = 'external.contacts.creat.group', // 消息-搜索-创建群组
    EXTERNAL_CONTACTS_CREAT_SCHEDULE = 'external.contacts.creat.schredule', // 新建日程(日历模块中新建、消息输入板新建/长按（右键）新建、月视图加号)
    EXTERNAL_CONTACTS_CREAT_TASK = 'external.contacts.creat.task', // 新建任务(任务模块中新建、消息中输入板新建/长按（右键）新建)
    EXTERNAL_CONTACTS_CREAT_URGING = 'external.contacts.creat.urging', // 新建催办(催办模块中新建、任务模块中新建、消息输入板新建/长按（右键）新建、日程模块中新建)
    EXTERNAL_CONTACTS_CREAT_DOCUMENT = 'external.contacts.creat.document', // 新建文档(文档模块中新建、任务模块中新建、日程模块中新建)
    EXTERNAL_CONTACTS_EXPORT_DOCUMENT = 'external.contacts.export.document', // 导出文档
}

/**
 * 会话配置
 */
export enum ChatConfig {
    // 会话创建 - 群聊
    CHAT_SESSION_CREATE_GROUP_ENABLE = 'chat.session.create.group.enable',
    CHAT_SESSION_CREATE_MEETING_ENABLE = 'chat.session.create.meeting.enable',
    CHAT_SESSION_CREATE_VOIP_ENABLE = 'chat.session.create.voip.enable',
    // 会话列表
    CHAT_SESSION_LIST_ENABLE = 'chat.session.list.enable',
    // 会话头部
    CHAT_SESSION_HEAD_CLICK_EVENT_ENABLE = 'chat.session.head.click.event.enable',
    // 会话列表 - 分组
    CHAT_SESSION_LIST_GROUP_ENABLE = 'chat.session.list.group.enable',
    CHAT_SESSION_LIST_GROUP_ALL_ENABLE = 'chat.session.list.group.all.enable',
    CHAT_SESSION_LIST_GROUP_TIME_ENABLE = 'chat.session.list.group.time.enable',
    CHAT_SESSION_LIST_GROUP_TYPE_ENABLE = 'chat.session.list.group.type.enable',
    CHAT_SESSION_LIST_GROUP_UNREAD_ENABLE = 'chat.session.list.group.unread.enable',
    CHAT_SESSION_LIST_GROUP_IMPORTANT_ENABLE = 'chat.session.list.group.unread.enable',
    // 会话右侧边栏
    CHAT_RIGHT_SIDER_ENABLE = 'chat.right.sider.enable',

    // 会话消息操作 - 转发
    CHAT_MESSAGE_OPERATE_FORWARD_ENABLE = 'chat.message.operate.forward.enable',

    CHAT_MESSAGE_STATUS_READ_UNREAD_ENABLE = 'chat.message.status.read.unread.enable',

    CHAT_MESSAGE_IMAGE_THUMB_URL_ENABLE = 'chat.message.image.thumb.url.enable',

    // 聊天窗口输入框
    CHAT_MESSAGE_INPUT_AT = 'chat.message.input.at', // 输入框是否支持@
    CHAT_MESSAGE_INPUT_CHANG = 'chat.message.input.chang', // 输入框是否支持常用语
    CHAT_MESSAGE_INPUT_EMOJI = 'chat.message.input.emoji', // 输入框菜单是否支持emoji
    CHAT_MESSAGE_INPUT_IMAGE = 'chat.message.input.image', // 输入框菜单是否支持图片
    CHAT_MESSAGE_INPUT_FILE = 'chat.message.input.file', // 输入框菜单是否支持文件
    CHAT_MESSAGE_INPUT_JOYSPACE = 'chat.message.input.joyspace', // 输入框菜单是否支持云空间
    CHAT_MESSAGE_INPUT_FILE_IN_MENU = 'chat.message.input.file.in.menu', // 输入框文件菜单是否在菜单栏
    CHAT_MESSAGE_DRAG_FILE = 'chat.message.drag.file', // 聊天窗口是否支持拖拽上传文件
    CHAT_MESSAGE_INPUT_SCHEDULE = 'chat.message.input.schedule', // 输入框菜单是否支持任务
    CHAT_MESSAGE_INPUT_TASK = 'chat.message.input.task', // 输入框菜单是否支持任务
    CHAT_MESSAGE_INPUT_REMIND = 'chat.message.input.remind', // 输入框菜单是否支持提醒
    CHAT_MESSAGE_INPUT_MORE = 'chat.message.input.more', // 输入框菜单是否支持任务
    CHAT_MESSAGE_INPUT_CAPTURE = 'chat.message.input.capture', // 输入框菜单是否截屏
    CHAT_MESSAGE_INPUT_SOLITAIRE = 'chat.message.input.solitaire', // 输入框菜单是否支持组接龙
    CHAT_MESSAGE_INPUT_SNAPCHAT = 'chat.message.input.snapchat', // 输入框菜单是否支持阅后即焚
    CHAT_MESSAGE_INPUT_GROUPTZ = 'chat.message.input.grouptz', // 输入框菜单是否组通知

    // 会话展示消息类型
    CHAT_MESSAGE_SUPPORT_TEXT = 'chat.message.support.text.enable', // 是否支持文本类型
    CHAT_MESSAGE_SUPPORT_REVOKE = 'chat.message.support.revoke.enable', // 是否支持文本类型
    CHAT_MESSAGE_SUPPORT_IMAGE = 'chat.message.support.image.enable', // 是否支持图片类型
    CHAT_MESSAGE_SUPPORT_FILE = 'chat.message.support.file.enable', // 是否支持文件类型
    CHAT_MESSAGE_SUPPORT_VOICE = 'chat.message.support.voice.enable', // 是否支持语音类型
    CHAT_MESSAGE_SUPPORT_VIDEO = 'chat.message.support.video.enable', // 是否支持视频类型
    CHAT_MESSAGE_SUPPORT_TEMPLATE2 = 'chat.message.support.video.template2', // 是否支持joyspace 文件文档 类型
    CHAT_MESSAGE_SUPPORT_SYS = 'chat.message.support.video.sys', // 是否支持 系统类消息类型
    CHAT_MESSAGE_SUPPORT_JOYSPACE_DOC = 'chat.message.support.joyspace.doc.enable', // 是否支持 joyspace 文档类型
    CHAT_MESSAGE_SUPPORT_JOYSPACE_FILE = 'chat.message.support.joyspace.file.enable', // 是否支持 joyspace 去空间文件类型
    CHAT_MESSAGE_SUPPORT_NOTICE = 'chat.message.support.notice.enable', // 是否支持 通知
    CHAT_MESSAGE_SUPPORT_GROUPSOLITAIRE = 'chat.message.support.groupsolitaire.enable', // 是否支持 群接龙
    CHAT_MESSAGE_SUPPORT_GROUP_NOTICE = 'chat.message.support.group.notice.enable', // 是否支持 组通知
}

export interface Config {
    // [ConfigEnum.LEFT_SIDER_ENABLE]: boolean;
    // // 会话相关
    // [ChatConfig.CHAT_SESSION_CREATE_GROUP_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_GROUP_ALL_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_GROUP_TIME_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_GROUP_TYPE_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_GROUP_UNREAD_ENABLE]: boolean;
    // [ChatConfig.CHAT_SESSION_LIST_GROUP_IMPORTANT_ENABLE]: boolean;
    // [ChatConfig.CHAT_RIGHT_SIDER_ENABLE]: boolean;
    [key: string]: boolean;
}

const config = {
    [ConfigEnum.LEFT_SIDER_ENABLE]: true,
    [ConfigEnum.TOP_BAR_ENABLE]: true,
    //
    [ChatConfig.CHAT_SESSION_CREATE_GROUP_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_CREATE_MEETING_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_CREATE_VOIP_ENABLE]: false,

    // 会话头部 单击事件
    [ChatConfig.CHAT_SESSION_HEAD_CLICK_EVENT_ENABLE]: true,

    // 会话列表
    [ChatConfig.CHAT_SESSION_LIST_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_LIST_GROUP_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_LIST_GROUP_ALL_ENABLE]: false,
    [ChatConfig.CHAT_SESSION_LIST_GROUP_TIME_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_LIST_GROUP_TYPE_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_LIST_GROUP_UNREAD_ENABLE]: true,
    [ChatConfig.CHAT_SESSION_LIST_GROUP_IMPORTANT_ENABLE]: true,
    // 会话右侧边栏
    [ChatConfig.CHAT_RIGHT_SIDER_ENABLE]: true,

    // 会话消息操作
    [ChatConfig.CHAT_MESSAGE_OPERATE_FORWARD_ENABLE]: false,
    [ChatConfig.CHAT_MESSAGE_STATUS_READ_UNREAD_ENABLE]: true,
    [ChatConfig.CHAT_MESSAGE_IMAGE_THUMB_URL_ENABLE]: true,

    // 会话输入框
    [ChatConfig.CHAT_MESSAGE_INPUT_AT]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_CHANG]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_EMOJI]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_IMAGE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_FILE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_JOYSPACE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_FILE_IN_MENU]: false,
    [ChatConfig.CHAT_MESSAGE_DRAG_FILE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_SCHEDULE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_TASK]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_REMIND]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_MORE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_CAPTURE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_SOLITAIRE]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_SNAPCHAT]: true,
    [ChatConfig.CHAT_MESSAGE_INPUT_GROUPTZ]: true,

    // 会话消息类型
    [ChatConfig.CHAT_MESSAGE_SUPPORT_TEXT]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_REVOKE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_IMAGE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_FILE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_VOICE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_VIDEO]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_TEMPLATE2]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_SYS]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_JOYSPACE_DOC]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_JOYSPACE_FILE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_GROUPSOLITAIRE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_NOTICE]: true,
    [ChatConfig.CHAT_MESSAGE_SUPPORT_GROUP_NOTICE]: true,

    [ConfigEnum.LEFT_SIDE_PAGE_INDEX]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_MESSAGE]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_CALENDAR]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_DOCUMENT]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_SETTING]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_THIRDAPP]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_ADDRESS]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_JOYSPACE]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_TEST]: true,
    [ConfigEnum.LEFT_SIDE_PAGE_WORK]: false,
    [ConfigEnum.LEFT_SIDE_PAGE_PROJECTS]: true,

    [ConfigEnum.LAYOUT_USER_CENTER]: true,
    [ConfigEnum.LAYOUT_USER_CENTER_HEAD_IMG]: true,
    [ConfigEnum.LAYOUT_USER_CENTER_CARD]: true,
    [ConfigEnum.LAYOUT_USER_CENTER_SIGNATURE]: true,
    [ConfigEnum.LAYOUT_USER_CENTER_FEEDBACK]: true,
    [ConfigEnum.LAYOUT_USER_CENTER_ABOUT]: true,
    [ConfigEnum.LAYOUT_USER_CENTER_LOGOUT]: true,

    [ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT]: false,
    [ConfigEnum.TOP_BAR_MENU_MEETING_CREATE]: false,
    [ConfigEnum.TOP_BAR_MENU_MEETING_JOIN]: false,
    [ConfigEnum.TOP_BAR_MENU_MEETING_MEETINGLIST]: false,

    // 外部联系人权限控制
    [ConfigEnum.EXTERNAL_CONTACTS_ORG]: false, // 通讯录-组织架构 和人员选择器的组织架构
    [ConfigEnum.EXTERNAL_CONTACTS_MY_DEPARTMENT]: false, // 通讯录-我的部门
    [ConfigEnum.EXTERNAL_CONTACTS_SEARCH_DEPARTMENT]: false, // 搜索 部门
    [ConfigEnum.EXTERNAL_CONTACTS_ADD_CONTACTS]: false, // 消息-搜索-添加联系人
    [ConfigEnum.EXTERNAL_CONTACTS_CREAT_GROUP]: false, // 消息-搜索-创建群组
    [ConfigEnum.EXTERNAL_CONTACTS_CREAT_SCHEDULE]: false, // 新建日程(日历模块中新建、消息输入板新建/长按（右键）新建、月视图加号)
    [ConfigEnum.EXTERNAL_CONTACTS_CREAT_TASK]: false, // 新建任务(任务模块中新建、消息中输入板新建/长按（右键）新建)
    [ConfigEnum.EXTERNAL_CONTACTS_CREAT_URGING]: false, // 新建催办(催办模块中新建、任务模块中新建、消息输入板新建/长按（右键）新建、日程模块中新建)
    [ConfigEnum.EXTERNAL_CONTACTS_CREAT_DOCUMENT]: false, // 新建文档(文档模块中新建、任务模块中新建、日程模块中新建)
    [ConfigEnum.EXTERNAL_CONTACTS_EXPORT_DOCUMENT]: false, // 导出文档
};

export const getUserCenterConfig = function (config: Config) {
    return {
        layout_user_center: config[ConfigEnum.LAYOUT_USER_CENTER],
        layout_user_center_head_img: config[ConfigEnum.LAYOUT_USER_CENTER_HEAD_IMG],
        layout_user_center_card: config[ConfigEnum.LAYOUT_USER_CENTER_CARD],
        layout_user_center_signature: config[ConfigEnum.LAYOUT_USER_CENTER_SIGNATURE],
        layout_user_center_feedback: config[ConfigEnum.LAYOUT_USER_CENTER_FEEDBACK],
        layout_user_center_about: config[ConfigEnum.LAYOUT_USER_CENTER_ABOUT],
        layout_user_center_logout: config[ConfigEnum.LAYOUT_USER_CENTER_LOGOUT],
    };
};

export function addConfig(part: Partial<Config>): void {
    Object.keys(part).forEach((prop) => {
        //@ts-ignore
        config[prop] = part[prop];
    });
}

export const testImg = () => {
    let ossHost = FocusSDK?.getApplicationInfo()?.ossBaseUrl;
    console.log('ossHostossHost', ossHost);
    if (!ossHost) {
        ossHost = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
    }
    return `${ossHost}/statics/icons/jdwork_logo.png`;
};

export default config;
