import { request } from '@/components/Joyspace/api/request';
import * as __shortcuts from './shortcuts';
import * as __collections from './collections';
import * as __follow from './follow';

// 删除空间页面
export const remove = (
    batchDeleteDtoList: {
        deleteType: number;
        targetId: string;
        targetType: number;
    }[]
) => {
    return request({
        api: 'joyspace.batch.remove',
        path: `/v1/batch`,
        method: 'DELETE',
        body: batchDeleteDtoList,
    })
        .then((data) => data)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

// 复制到
// eslint-disable-next-line
export const copy = (
    batchCopyFileToDtoList: {
        folderId: string;
        pageIds: string[];
        spaceId: string;
    }[]
) => {
    return request({
        api: 'joyspace.batch.copyTo',
        path: `v1/batch/copyTo`,
        method: 'POST',
        body: batchCopyFileToDtoList,
    })
        .then((data) => data)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

type MoveType = 'folder' | 'space' | 'category';
// eslint-disable-next-line
export const moveTo = (batchMoveToDtoList: {
    batchMoveInfos: {
        fromType: number;
        targetId: string;
        targetType: number;
    }[];
    toId: string;
    toType: number;
}) => {
    return request({
        api: 'joyspace.batch.moveTo',
        path: `v1/batch/moveto`,
        method: 'PUT',
        body: batchMoveToDtoList,
    })
        .then((data) => data)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};
