/* eslint-disable no-console */
import { EventEmitter } from 'events';
import { isFocusEnv, FocusSDK } from '@/utils';
import { replaceGovUrl } from '@/utils/joyspace';

const DEAFULT_PREFIX = 'jd';

export enum DeeplinkPathEnum {
    Schedule_Draw = 'jm/page_schedule_detail',
    Create_Supervise = 'jm/page_project_supervise_detail',
    Task_Draw = 'jm/page_joywork_detail',
    Joyspace_Page = 'jm/sys/rn',
    Calendar_Tab = 'jm/page_calendar_edit',
    AppCenter = 'jm/biz/appcenter',
}
// jdme://jm/page_joywork_detail?mparam=%7b%22taskId%22%3a+%22254627070066425856%22%2c+%22taskName%22%3a+%22joywork%22%2c+%22projectId%22%3a+%22264364578899755008%22%7d
interface ParseDeeplinkResult {
    protocol: string;
    pathname: string;
    mparam: any;
    maction: any;
    msrc: any;
    motype: any;
    matype: any;
    mtoken: any;
    type: string;
    app: string;
}

class DeeplinkEvent extends EventEmitter {
    /**
     * 解析deeplink, 需要
     * @param deeplink
     * @param option
     */
    parseDeeplink(deeplink: string, option: any = {}) {
        const [flag, result] = this.isDeeplink(deeplink);
        if (!flag || !result) {
            return null;
        }
        // 只解析第一个
        // let link = decodeURIComponent(result[0]);
        let link = result[0];
        console.log('link', link);
        // 'jdme://jm/page_schedule_detail?mparam=' + encodeURIComponent(JSON.stringify(params));
        const parseResult: ParseDeeplinkResult | any = {} as any;
        const urlProtocol = link.split('//')[0];
        parseResult.protocol = urlProtocol;
        parseResult.app = urlProtocol.slice(DEAFULT_PREFIX.length);
        let compatiblePathname = link.slice(urlProtocol.length);
        compatiblePathname = compatiblePathname.split('?')[0];

        parseResult.pathname = compatiblePathname.slice(2);
        let searchPath = link.split('?')[1];
        try {
            const param = new URLSearchParams(searchPath).get('mparam');
            if (param) {
                parseResult.mparam = JSON.parse(param);
            }
        } catch (error) {
            console.error(`deeplink parse mparam error =>`, error);
        }
        // ['mparam', 'maction', 'msrc', 'motype', 'matype', 'mtoken'].forEach((key: any) => {
        //     try {
        //         const param = url.searchParams.get(key);
        //         if (param) {
        //             parseResult[key] = JSON.parse(param);
        //         }
        //     } catch (error) {
        //         console.log(`deeplink parse ${key} error =>`, error);
        //     }
        // });
        return parseResult;
    }

    /**
     * 判断是否是deeplink
     * @param deeplink
     */
    isDeeplink(deeplink: string): [boolean, string[] | null] {
        if (!deeplink) {
            return [false, null];
        }
        const pattern = new RegExp(`${DEAFULT_PREFIX}[\\w\\d_]+://\\S+`, 'ig');
        const result = deeplink.match(pattern);
        if (result === null || result.length === 0) {
            return [false, null];
        }
        return [true, result];
    }

    open = (
        deeplink: string,
        external?: { source?: 'imShareCard'; from?: number; taskComeFrom?: string; chat?: boolean }
    ) => {
        // eslint-disable-next-line no-param-reassign
        deeplink = replaceGovUrl(deeplink);
        if (/^https?:\/\//.test(deeplink)) {
            return window.open(deeplink);
        }
        // if (deeplink.indexOf('http') > -1) {
        //     return window.open(deeplink);
        // }
        const result = this.parseDeeplink(deeplink);
        // console.log('====>>>', { result });
        if (!result) {
            return;
        }
        const { pathname, mparam } = result;
        // 这里用focussdk，是因为群聊有可能从新窗口打开，所以消息需要从桌面端中转一下，在主窗口打开modal
        if (isFocusEnv()) {
            FocusSDK.sendToMainWin({
                type: 'handleDeeplinkParams',
                data: {
                    data: mparam,
                    pathname,
                    external,
                },
            });
            // 主窗口 聚焦
            FocusSDK.sendMainMessage('focusMainWin', {});
            return;
        }
        switch (pathname) {
            // case 'jm/page_schedule_detail':
            //     return this.emit('open-schedule-draw', { data: mparam });
            default:
                return this.emit('open-deeplink', { data: mparam, pathname, external });
        }
    };

    strify = ({
        appId,
        path,
        mparam,
    }: {
        mparam: { [key: string]: any };
        appId: string;
        path: DeeplinkPathEnum;
    }) => {
        let link = `${(appId as string).replace(/[_]/g, '')}://${path}`;
        if(!link.includes('jd')){
            link = 'jd'+link;
        }
        let query = '';
        if (mparam) {
            query = `mparam=${encodeURIComponent(JSON.stringify(mparam))}`;
        }
        if (query) {
            link += `?${query}`;
        }
        console.log(link, 'DeeplinkPath===>');
        return link;
    };
}

export default new DeeplinkEvent();
