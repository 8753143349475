import React, { useCallback, useEffect, useState } from 'react';
import MeetingJD from '@/components/MeetingJD';
import MeetingJDHome from '@/components/MeetingJDHome';
import Bus from '@/utils/bus';
import { Tabs } from 'antd';
import IconFont from '@/components/icon';
import { useLocation } from 'react-router-dom';
import { set } from 'lodash';

let routerState: any = null;

export default function () {
    const { TabPane } = Tabs;
    const [isShowMeetingJD, setIsShowMeetingJD] = useState(true);
    const [isCalendarJoinMeeting, setIsCalendarJoinMeeting] = useState(false);
    const [isQuickJoinMeeting, setIsQuickJoinMeeting] = useState(false);
    const [calendarJoinData, setCalendarJoinData] = useState({});
    const [quickJoinData, setQuickJoinData] = useState({});
    const location = useLocation();

    function operateMeetingJD(isShow: boolean) {
        setIsShowMeetingJD(isShow);
    }

    function destroyComponent() {
        routerState = null;
        setIsCalendarJoinMeeting(false);
        setIsQuickJoinMeeting(false);
        setQuickJoinData({});
        setQuickJoinData({});
    }

    const handleLinkJoinMeeting = useCallback((opts: any) => {
        setCalendarJoinData(opts);
        setIsCalendarJoinMeeting(true);
        setIsQuickJoinMeeting(false);
        operateMeetingJD(opts.isShowMeetingModal);
        routerState = null;
    }, []);

    const handleQuickJoinLink = useCallback((opts) => {
        setQuickJoinData(opts);
        setIsQuickJoinMeeting(true);
        setIsCalendarJoinMeeting(false);
        routerState = null;
    }, []);

    useEffect(() => {
        function handleCleanProps(data: any) {
            routerState = null;
            setIsCalendarJoinMeeting(false);
            setIsQuickJoinMeeting(false);
            setQuickJoinData({});
            setQuickJoinData({});
        }
        const { state } = location;

        if (state) {
            routerState = state;
            operateMeetingJD(false);
        }
        Bus.on('jdmeeting:clear-props', handleCleanProps);
        return () => {
            Bus.off('jdmeeting:clear-props', handleCleanProps);
        };
    }, [location]);

    useEffect(() => {
        Bus.on('load:jdmeetingHome', operateMeetingJD);
        Bus.on('load:jdmeetingJD', operateMeetingJD);
        Bus.on('jdmeeting:calendarJoin', handleLinkJoinMeeting);
        Bus.on('jdmeeting:quickJoin', handleQuickJoinLink);

        return () => {
            Bus.off('load:jdmeetingHome', operateMeetingJD);
            Bus.off('load:jdmeetingJD', operateMeetingJD);
            Bus.off('jdmeeting:calendarJoin', handleLinkJoinMeeting);
            Bus.off('jdmeeting:quickJoin', handleQuickJoinLink);
        };
    }, [setIsShowMeetingJD, handleLinkJoinMeeting, handleQuickJoinLink]);

    useEffect(() => {
        return destroyComponent;
    }, []);

    return (
        <div className="multimedia multimedia-jdmeeting">
            <Tabs type="card" defaultActiveKey="1">
                <TabPane
                    tab={
                        <span>
                            <IconFont type="iconapp_btn_joymeeting_video" />
                            智能会议
                        </span>
                    }
                    key="1"
                >
                    {isShowMeetingJD ? (
                        <MeetingJD />
                    ) : (
                        <MeetingJDHome
                            isCalendarJoinMeeting={isCalendarJoinMeeting}
                            isQuickJoinMeeting={isQuickJoinMeeting}
                            calendarJoinData={calendarJoinData}
                            quickJoinData={quickJoinData}
                            routerData={routerState ? routerState : null}
                        />
                    )}
                </TabPane>
            </Tabs>
        </div>
    );
}
