import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Input, message } from 'antd';
import { findProjectsMemberService, updateUser } from '../../api';
import { Project, ProjectRole } from '../../types';
import UserAvatar from '../../components/UserAvatar';
import { debounce } from 'lodash';
import './index.less';

interface propTypes {
    projectId: string;
    t: any;
    ownerCallback: (value?: { headImg: string; userName: string }, changeRole?: boolean) => void;
    project?: Project;
}
interface ImemberItemType {
    app: string;
    teamId: string;
    userId: string;
    headImg?: string;
    userName: string;
}
interface Iuser {
    app: string;
    userId: string;
    teamId: string;
}

export default function ChangeOwner(props: propTypes) {
    const {
        projectId,
        ownerCallback,
        t,
        project: {
            ownerInfo: projectOwnerInfo = {
                headImg: '',
                userName: '',
                app: '',
                userId: '',
                teamId: '',
            },
        } = {},
    } = props;
    const [ownerSearch, setOwnerSearch] = useState<string>('');
    const [searchIcon, setSearchIcon] = useState<string>(projectOwnerInfo.headImg);
    const [memberList, setMemberList] = useState<ImemberItemType[]>([projectOwnerInfo]);
    const ownerWrapRef = useRef(null);
    const [showMemberList, setShowMemberList] = useState<boolean>(false);
    const [isShowTranferText, setIsShowTranferText] = useState<boolean>(false);
    const [isChangeOwner, setIsChangeOwner] = useState<boolean>(false);
    const [selectMember, setSelectMember] = useState<ImemberItemType>();

    const findProjectsMember = useCallback(
        (search) => {
            findProjectsMemberService(projectId, search).then((res) => {
                if (res.errCode) return;
                setMemberList(res?.projects || []);
            });
        },
        [projectId]
    );

    function isCurrentUser(selectUser: Iuser, currentUser: Iuser) {
        return Object.is(
            selectUser.userId + selectUser.app + selectUser.teamId,
            currentUser.userId + currentUser.app + currentUser.teamId
        );
    }

    // eslint-disable-next-line
    const getProjectsMembers = useCallback(debounce(findProjectsMember, 500), []);

    function ownerSearchChange(e: ChangeEvent<HTMLInputElement>) {
        const newvalue = (e.target as any).value;
        e.persist();
        setOwnerSearch(newvalue);
        getProjectsMembers(newvalue);
    }

    function selectOwner(selectItem: ImemberItemType) {
        setOwnerSearch(selectItem.userName);
        setSearchIcon(selectItem.headImg ?? '');
        if (isCurrentUser(selectItem, projectOwnerInfo)) {
            setShowMemberList(false);
            return;
        }
        setIsChangeOwner((value) => !value);
        setSelectMember(selectItem);
    }

    useEffect(() => {
        if (!isChangeOwner || !selectMember) return;
        updateUser(projectId, {
            userId: selectMember.userId,
            teamId: selectMember.teamId,
            app: selectMember.app,
            userRole: ProjectRole.OWNER,
        }).then((res) => {
            if (res.errCode) return;
            message.success(t('operate_success'));
            setShowMemberList(false);
            ownerCallback(
                { headImg: selectMember.headImg ?? '', userName: selectMember.userName },
                true
            );
        });
        // eslint-disable-next-line
    }, [isChangeOwner]);

    useEffect(() => {
        if (
            (memberList.length === 1 && isCurrentUser(memberList[0], projectOwnerInfo)) ||
            memberList.length === 0
        ) {
            setIsShowTranferText(false);
        } else {
            setIsShowTranferText(true);
        }
    }, [memberList, projectOwnerInfo]);

    useEffect(() => {
        function close(e: MouseEvent) {
            const container = ownerWrapRef.current;
            if (!container) return;
            if (!(container as HTMLDivElement).contains(e.target as HTMLElement)) {
                setShowMemberList(false);
                ownerCallback();
            }
        }
        document.addEventListener('mousedown', close, false);
        return () => document.removeEventListener('mousedown', close, false);
    }, [showMemberList, ownerCallback]);

    return (
        <div className="pm-change-owner-wrap" ref={ownerWrapRef}>
            <Input
                allowClear
                prefix={
                    <UserAvatar
                        headImg={searchIcon}
                        userName={ownerSearch || projectOwnerInfo.userName}
                    />
                }
                placeholder={projectOwnerInfo.userName}
                value={ownerSearch}
                onChange={ownerSearchChange}
                onFocus={() => setShowMemberList(true)}
            />
            {showMemberList && (
                <div className="pm-change-member-list-wrap">
                    {isShowTranferText && <span>{t('detail.change_owner')}</span>}
                    <ul className="pm-change-owner-member-list">
                        {memberList.map((item: ImemberItemType) => (
                            <li
                                key={item.userId + item.app + item.teamId}
                                onClick={() => selectOwner(item)}
                            >
                                <UserAvatar headImg={item.headImg} userName={item.userName} />
                                {item.userName}
                            </li>
                        ))}
                    </ul>
                    {memberList.length === 0 && (
                        <p className="pm-change-member-nodata">{t('detail.no_search_data')}</p>
                    )}
                </div>
            )}
        </div>
    );
}
