import React from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import ChatContext from '@/context/ChatContext';
import DefaultPage from '@/components/DefaultPage';
import { CompDataLoadState, DefaultPageType } from '@/models';
import { QuickReply } from '@/types/chat/State';

function QuickReplyItem({
    quickReplys,
    onItemClick,
}: {
    quickReplys: QuickReply[];
    onItemClick: (text: string) => void;
}) {
    const [t] = useTranslation('chat');

    return (
        <div className="chat-input-phrase">
            <div className="input-phrase-title">{t('all_common_words')}</div>
            <div className="input-phrase-container">
                {!quickReplys.length ? (
                    <DefaultPage
                        dataType={CompDataLoadState.NODATA}
                        pageType={DefaultPageType.REQUEST_FAIL}
                    />
                ) : (
                    quickReplys.map((quick: any, index: number) => {
                        return (
                            <div
                                className="input-phrase-item"
                                key={index}
                                onClick={() => onItemClick(quick.detail)}
                            >
                                {/* <Tooltip title={quick.detail} key={index}>
                                    {quick.detail}
                                </Tooltip> */}
                                {quick.detail}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}

export default function QuickReplyComp({
    children,
    visible,
    onItemClick,
    onVisibleChange,
    quickReplys,
}: {
    children: any;
    visible: boolean;
    onItemClick: (text: string) => void;
    onVisibleChange: (visible: boolean) => void;
    quickReplys: QuickReply[];
}) {
    function handleClick(text: string) {
        onItemClick(text);
        onVisibleChange(false);
    }
    const { isSmall } = React.useContext(ChatContext);
    return (
        <Popover
            trigger="click"
            overlayClassName={`quick-replay-popover ${isSmall ? 'small' : ''}`}
            visible={visible}
            placement="bottom"
            onVisibleChange={(visible) => {
                onVisibleChange(visible);
            }}
            content={<QuickReplyItem onItemClick={handleClick} quickReplys={quickReplys} />}
        >
            {children}
        </Popover>
    );
}
