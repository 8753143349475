/**
 * 系统消息 辅助消息 项组件
 * @author sunzhiguang
 * @date 2020/7/14
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Popover, message as toast } from 'antd';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import isFunction from 'lodash/isFunction';
import debounce from 'lodash/debounce';
import { employeeToUid, isEqualUid, isGroupMemberDeleteMessage } from '@/utils/chat/message';

import {
    getMessageExt,
    getMessageType,
    getSingleSysMessageContent,
    getSysMessageReceiver,
    sysMsgContentConfig,
} from '@/baseComponents/Chat/message/Item/common';

import { Employee, Group, MessageRespType, SessionProps, UID } from '@/types/chat';
import ImService from '@/server/ImService';
import { useHistory } from 'react-router-dom';
import { openUserCardModal } from '@/utils/chat/index';
import Context from '@/context/ChatContext';
import { isGroupAdmin, isGroupOwner } from '@/utils/chat/group';
import { expireQRCode, getRelationship } from '@/api/chat';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import IconFont from '@/components/icon';
import GroupRoster from '@jd/jdee.im.sdk/lib/es/model/Roster';
import Bus from '@/utils/bus';

interface SysMessageItemProps {
    message: any;
}

interface DvaProps {
    loadSessionMembers: Function;
    removeSession: Function;
    removeGroupMember: Function;
}

type IProps = Readonly<SysMessageItemProps & ChatState & DvaProps>;

function SysItem(props: IProps) {
    const { selectedSession, message, loadSessionMembers, removeGroupMember } = props;
    const { currentEmployee, groupRosterIdentity, groupRosters } = React.useContext(Context);

    const sessionId = selectedSession.sessionId;
    const { t } = useTranslation('chat');
    const history = useHistory();

    const addContactsApply = async () => {
        const userInfo = selectedSession.info as Employee;
        let [isRelationship, error] = await getRelationship({
            app: userInfo?.app,
            pin: userInfo?.userId,
            teamId: userInfo?.teamId,
        });
        const {
            relationship: { relation, friendship },
        } = isRelationship;
        if (friendship === 1) {
            toast.warn(t('already_friend_tip'));
            return;
        }
        openUserCardModal(userInfo);
    };

    const failureContent = useMemo(() => {
        if (message.type === 'failure' && message.code === 209) {
            return (
                <div className="add_contacts_tip">
                    <div>
                        {t('apply.not_your_friend')} 请
                        <span
                            onClick={debounce(() => {
                                addContactsApply();
                            }, 200)}
                        >
                            添加联系人
                        </span>
                        后沟通
                    </div>
                </div>
            );
        }
        return null;
    }, [message.code, message.type, t]); //eslint-disable-line

    const messageType = useMemo(() => {
        return getMessageType(message);
    }, [message]);

    const receiverType = useMemo(() => {
        return getSysMessageReceiver({
            message,
            currentEmployee,
            groupRosterIdentity,
        });
    }, [message, currentEmployee, groupRosterIdentity]);

    const content = useMemo(() => {
        if (failureContent) {
            return failureContent;
        }
        if (selectedSession.isSingle && !selectedSession.isNotice) {
            // 单聊会话的系统消息
            return getSingleSysMessageContent({
                message,
                session: selectedSession,
                currentEmployee,
            });
        }
        // 群解散的时候给出提示
        if (selectedSession.isGroup && message.type === MessageRespType.GROUP_DELETE) {
            return '该组已解散！';
        }

        const config = sysMsgContentConfig[messageType || ''];

        if (!config || !receiverType) {
            return null;
        }

        const getI18nArgs = config[receiverType];
        if (getI18nArgs && isFunction(getI18nArgs)) {
            if (messageType === 'group_member_in_5') {
                const i18nArgs = getI18nArgs(message, currentEmployee);
                return t(`system-message-group.${messageType}.${receiverType}`, i18nArgs);
            } else {
                const i18nArgs = getI18nArgs(message);
                return t(`system-message-group.${messageType}.${receiverType}`, i18nArgs);
            }
        }
        return null;
    }, [failureContent, messageType, selectedSession]); // eslint-disable-line

    const isRemoveUser = useMemo(() => {
        const members = message.members || [];
        if (members?.length === 0) {
            return false;
        }
        const index = members.findIndex((item: any) => {
            return isEqualUid(item, employeeToUid(currentEmployee));
        });
        return index > -1;
    }, [message, currentEmployee]);

    const notInGroup = useMemo(() => {
        if (selectedSession.isGroup && message.type === MessageRespType.GROUP_MEMBER_DELETE) {
            const info = selectedSession.info as Group;
            const { app, userId, teamId } = currentEmployee;
            const rosterKey = `${userId}:${app}:${teamId}`;
            return !groupRosters?.hasOwnProperty(rosterKey);
        }
    }, [message, currentEmployee, selectedSession, groupRosters]);

    useEffect(() => {
        if (message.history) {
            return;
        }
        let messageRespType = message.type;
        // 如果收到群成员退出事件， 当前会话的sessionId 等于 退出事件消息的sessionId 需重新加载 群成员
        if (messageRespType === MessageRespType.GROUP_OUT && message.sessionId === sessionId) {
            // 群人员退出事件 重新加载群成员信息
            loadSessionMembers({ selectedSession: selectedSession });
        } else if (isGroupMemberDeleteMessage(message)) {
            if (sessionId === message.sessionId) {
                // 选中的会话是消息的当前会
                // 移除的是当前用户，并且当前的群成员没有该用户
                if (notInGroup && isRemoveUser) {
                    window.history.pushState({}, '', '/messages');
                } else {
                    loadSessionMembers({ selectedSession: selectedSession });
                }
            }
        } else if (messageRespType === MessageRespType.GROUP_MEMBER_IN) {
            if (sessionId === message.sessionId) {
                // 当前会话有人被邀请进入 需要更新 群成员
                loadSessionMembers({ selectedSession: selectedSession });
            }
        }
    }, [sessionId, message.type, message.sessionId]); // eslint-disable-line

    // 停用二维码
    const disableQRCode = useCallback(async () => {
        try {
            const ext = getMessageExt(message);
            if (ext.qrCode) {
                await expireQRCode(ext.qrCode);
                toast.success(t('system-message-group.disable_qrCode_success'));
            }
        } catch (e) {
            toast.error(t('failed'));
        }
    }, [message, t]);

    const confirmRemoveUser = useCallback(
        async (member: GroupRoster) => {
            const members = [employeeToUid(member.info)];
            if (selectedSession.sessionId) {
                const instance = ImService.getInstance();
                const result = await instance.removeGroupMembers(
                    selectedSession.sessionId,
                    members
                );
                if (result) {
                    toast.success(t('removed-successfully'));
                    await removeGroupMember({ member: members[0] });
                } else {
                    toast.error(t('removal-failed'));
                }
            }
        },
        [selectedSession.sessionId] //eslint-disable-line
    );

    // 移聊成员
    const removeUser = useCallback(async () => {
        // 查询是否是群中， 如果在群里，弹确认框提示是否移除 如果不在群里提示：成员不在群聊中
        if (!selectedSession.isGroup) {
            return;
        }
        const ext = getMessageExt(message);
        let rosterIndex = groupRosters.findIndex((item) => {
            // const employee = uidToEmployee(uid) as Employee;
            if (item.user) {
                return isEqualUid(ext.fromUid as UID, item.user);
            }
            return false;
        });
        if (rosterIndex < 0) {
            toast.error(t('system-message-group.roster_not_in_group'));
            return;
        }
        // 点击移除成员
        Prompt({
            title: t('remove-members-reminder'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            content: t('remove-members-prompt', {
                memberName: groupRosters[rosterIndex]?.info?.name,
            }),
            cancelText: t('cancelText'),
            onOk: () => {
                confirmRemoveUser(groupRosters[rosterIndex] as GroupRoster);
            },
            okText: t('determine'),
        });
    }, [selectedSession.isGroup, groupRosters, t, confirmRemoveUser]); //eslint-disable-line

    const popoverContent = useMemo(() => {
        if (isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity)) {
            return (
                <div className="popover-box">
                    <div className="popover-item" onClick={removeUser}>
                        {t('system-message-group.remove_user')}
                    </div>
                    <div className="popover-item" onClick={disableQRCode}>
                        {t('system-message-group.disable_QRCode')}
                    </div>
                </div>
            );
        }
    }, [groupRosterIdentity, disableQRCode, removeUser, t]);

    const extPart = useMemo(() => {
        if (
            messageType === 'group_member_in_5' &&
            (isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity))
        ) {
            return (
                <Popover
                    overlayClassName="sys-message-popover"
                    placement="bottomLeft"
                    content={popoverContent}
                    trigger="hover"
                >
                    <a>&nbsp;&nbsp;{t('system-message-group.revocation')}</a>
                </Popover>
            );
        }
        return null;
    }, [messageType, groupRosterIdentity, popoverContent, t]);

    if (content) {
        return (
            <div className="sys-message-box">
                {content}
                {extPart}
            </div>
        );
    }
    return null;
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    return {
        selectedSession: chat.selectedSession,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async removeGroupMember(data: { member: UID }) {
            await dispatch({ type: 'chat/removeGroupMember', payload: data });
        },
        async loadSessionMembers(data: { selectedSession: SessionProps }) {
            await dispatch({ type: 'chat/loadSessionMembers', payload: data });
        },
        async removeSession(data: { sessionId: string }) {
            await dispatch({ type: 'chat/removeSession', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SysItem);
