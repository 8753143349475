import React, { useState, useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { ConsoleSqlOutlined, DownOutlined } from '@ant-design/icons';
import { menu, MenuEnum } from './config';
import IconFont from '@/components/icon';
import './index.less';
const { SubMenu } = Menu;

interface IProps {
    onChange?: Function;
    defaultActiveKey?: MenuEnum;
    menuList?: any;
    arrow?: boolean;
    disabled?: boolean;
}

const SharesFilter = (props: IProps) => {
    const {
        onChange,
        defaultActiveKey = MenuEnum.SHARES,
        menuList = [],
        arrow = true,
        disabled = false,
    } = props;
    console.log(menuList, 'menuListmenuListmenuList', menu);
    const [defaultName, setDefaultName] = useState('');
    // 给初始值
    useMemo(() => {
        const item: any = menu.find((item) => item.type === defaultActiveKey);
        if (item) {
            setDefaultName(item.name);
        }
    }, [defaultActiveKey]);
    const onClick = (_item: any) => {
        const { key = '', name = '', item = {} } = _item;
        onChange?.(key);
        setDefaultName(item.node.innerHTML);
    };
    const menus = () => {
        return (
            <Menu onClick={onClick}>
                {(menuList.length > 0 ? menuList : menu).map((item: any, index: any) => {
                    if (item.children && !item.children.length) {
                        return <Menu.Item key={item.type}>{item.name}</Menu.Item>;
                    }
                    return (
                        <SubMenu key={index} title={item.name}>
                            {item.children.map((_item: any) => (
                                <Menu.Item key={_item.type}>{_item.name}</Menu.Item>
                            ))}
                        </SubMenu>
                    );
                })}
            </Menu>
        );
    };
    return (
        <Dropdown
            trigger={['click']}
            overlay={menus}
            disabled={(menuList.length > 0 ? menuList : menu).length === 1 || disabled}
        >
            <span className="shares-filter-menus" onClick={(e) => e.preventDefault()}>
                {defaultName}
                {(menuList.length > 0 ? menuList : menu).length > 1 && (
                    <IconFont className="shares-filter-menus-icon" type="iconjs_ilist_arrowdown" />
                )}
            </span>
        </Dropdown>
    );
};

export default SharesFilter;
