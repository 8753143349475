/**
 * 人员选择器 工具方法
 * @author sunzhiguang
 * @date 2020/9/23
 */

import { Employee, Session, UID } from '@/types/chat';
import {
    convertEmployeeToString,
    employeeToUid,
    isCrossPlatformGroupSession,
    isExternalGroupSession,
} from '@/components/chat/utils/message';
import GroupRoster from '@jd/jdee.im.sdk/lib/es/model/Roster';
import { ListItemData } from '@/components/ListItem';
import ImService from '@/server/ImService';
import get from 'lodash/get';
import { UserSelectionResult } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import { GroupKind } from '@jd/jdee.im.sdk/lib/es/model/Group';
import pick from 'lodash/pick';
import { convertUidToString } from '@jd/jdee.im.sdk/lib/es/utils/utils';

export function mapGroupRosters(
    groupRosters: Partial<GroupRoster>[],
    opts?: { checked?: boolean; visible?: boolean }
): ListItemData[] {
    const dataList = groupRosters.map((roster) => {
        const employee = roster.info as Employee;
        let id = '';
        if (employee) {
            id = convertEmployeeToString(employee);
        } else {
            id = convertUidToString(roster.user as UID);
        }
        return {
            id: id,
            name: employee?.name,
            avatar: employee?.avatar,
            checked: true,
            userId: employee?.userId,
            teamId: employee?.teamId,
            visible: false,
            ...opts,
        };
    });
    return dataList.filter((item) => item.name);
}

export function mapSessionMembers(sessionMembers: Partial<Employee>[]): ListItemData[] {
    return sessionMembers.reduce((result, item) => {
        if (item.userId !== 'all') {
            result.push({
                id: convertEmployeeToString(item as Employee),
                name: item.name,
                avatar: item.avatar,
                userId: item.userId,
                teamId: item.teamId,
                origin: { info: item, uid: employeeToUid(item as Employee) },
            });
        }
        return result;
    }, [] as ListItemData[]);
}

export function mapSelectorResult(data: UserSelectionResult): UID[] {
    const result = get(data, 'data.result', []) as ListItemData[];
    if (result.length === 0) {
        return [];
    }
    const selectData = result.filter((item) => !item.checked);
    if (!selectData || selectData.length === 0) {
        return [];
    }
    return selectData.map((item) => {
        return {
            ...item.origin.uid,
            name: item.name,
        };
    });
}

export function mergeGroupRoster(invitees: UID[], groupRosters: Partial<GroupRoster>[]) {
    const rosters = groupRosters.map((item) => pick(item.user, ['pin', 'app', 'teamId']));
    return invitees.concat((rosters || []) as UID[]);
}

export enum CheckInviteeResult {
    Normal = 'normal',
    External = 'external',
    CrossPlatform = 'crossPlatform',
}

export function checkInvitee(
    invitees: UID[],
    currentEmployee: Employee,
    session?: Session
): CheckInviteeResult {
    if (session && isCrossPlatformGroupSession(session)) {
        return CheckInviteeResult.Normal;
    }
    let index = invitees.findIndex((item) => item.app !== currentEmployee.app);
    if (index > -1) {
        return CheckInviteeResult.CrossPlatform;
    }
    if (session && isExternalGroupSession(session)) {
        return CheckInviteeResult.Normal;
    }
    index = invitees.findIndex((item) => item.teamId !== currentEmployee.teamId);
    if (index > -1) {
        return CheckInviteeResult.External;
    }
    return CheckInviteeResult.Normal;
}

/**
 * 邀请入群
 * @param sessionId
 * @param invitees
 */
export function inviteAddGroupApply(sessionId: string, invitees: UID[]) {
    return ImService.getInstance().inviteAddGroupApply(
        sessionId,
        invitees,
        'application_noreply' // application_noreply 拒绝后不可以再次审请
    );
}

export function inviteUser(sessionId: string, invitees: UID[]) {
    return ImService.getInstance().inviteUser(sessionId, invitees, '');
}

export function createGroupSession(invitees: UID[], name: string, intro: string) {
    return ImService.getInstance().createGroupSession(invitees, '', {
        name,
        intro,
    });
}

export function createExternalGroup(invitees: UID[], name: string) {
    return ImService.getInstance().createGroupSession(invitees, '', {
        name,
        kind: GroupKind.EXTERNAL,
        subKind: 6,
    });
}

export function createCrossPlatformGroup(invitees: UID[], name: string) {
    return ImService.getInstance().createGroupSession(invitees, '', {
        name,
        kind: GroupKind.EXTERNAL,
        subKind: 3,
    });
}
