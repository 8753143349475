import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'dva';
import { FileChatMessage, RosterApplyStatus, Session, SessionType } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import ChatState from '@/types/chat/State';
import { WithTranslation } from 'react-i18next';
import Upload from './Upload';
import Download from '@/baseComponents/Chat/message/File/Download';
import IconFont from '@/components/icon';
import { Drawer, Button, List, message as toast, Spin, Empty, Divider, Skeleton } from 'antd';
import { getFileDownloadRecordByPage } from '@/api/chat';
import { ListItem } from '@jd/focus-desktop-comps';
import { ListItemData } from '@/components/ListItem';
import DefaultPage from '@/components/DefaultPage';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '@/components/Loading';
import { CompDataLoadState, DefaultPageType } from '@/models';
import { isGroupVisibleUnit } from '@/utils/chat/message';
import { isFocusEnv } from '@/utils';
import { isSameClient } from '@/components/chat/utils/message';
import { changeEgovUrl } from '@/utils/tools';
import bus from '@/utils/bus';

interface UploadFileProps {
    isMergeForward?: boolean;
    message: FileChatMessage;
    sendType: MessageSendType;
    sessionType: SessionType;
    selectedSession?: Session;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

interface DvaDispatchProps {
    updateSessionMessageMid: Function;
}
type IProps = Readonly<WithTranslation & UploadFileProps & DvaDispatchProps & ChatState>;

interface BaseData {
    fileId: string;
    downloadPeopleCount: number;
    downloadCount: number;
}

function UploadWithDetail(props: IProps) {
    const { message, selectedSession } = props;
    console.log(selectedSession, 'selectedSession');
    const [detailVisible, setDetailVisible] = useState(false);
    const [dataList, setDataList] = useState<ListItemData[]>([]);
    const [baseData, setBaseData] = useState<BaseData>({
        fileId: '',
        downloadPeopleCount: 0,
        downloadCount: 0,
    });
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const fetchDataList = useCallback(() => {
        if (fetching) return;
        setFetching(true);
        getFileDownloadRecordByPage({
            fileId: message.id,
            pageNum,
            pageSize: 30,
        }).then((res) => {
            console.log(res, 'getFileDownloadRecordByPage');
            if (res[0]) {
                const data: {
                    pageNum: number;
                    pageSize: number;
                    totalNum: number;
                    totalPage: number;
                    fileId: string;
                    downloadPeopleCount: number;
                    downloadCount: number;
                    list: any[];
                } = res[0];
                setBaseData(data);
                const hasMore = pageNum < data.totalPage;
                setHasMore(hasMore);
                if (hasMore) {
                    setPageNum((prev) => prev + 1);
                }
                const list = data.list.map((item: any) => ({
                    id: item.userId,
                    name: item.userName,
                    avatar: item.avatar,
                    desc: `${
                        !isGroupVisibleUnit(selectedSession) ? item.unitName : item.deptName
                    }-${item.post}`,
                    ...item,
                }));

                setDataList((prev) => [...prev, ...list]);
            }
            setFetching(false);
            setFetched(true);
        });
    }, [fetching, message.id, pageNum, selectedSession]);

    const visibleChange = (e: MouseEvent) => {
        if (detailVisible) {
            bus.emit('message-item-chatitem', true);
        }
    };
    useEffect(() => {
        document.addEventListener('contextmenu', visibleChange);
        return () => {
            document.removeEventListener('contextmenu', visibleChange);
        };
    });

    const showDetailModal = () => {
        setDetailVisible(true);
        fetchDataList();
    };

    const loadMoreData = () => {
        fetchDataList();
    };

    const handleClose = () => {
        setDetailVisible(false);
        setHasMore(true);
        setPageNum(1);
        setDataList([]);
    };

    return (
        <div className="upload-with-detail">
            {!isSameClient(message) ||
            (isFocusEnv() && !message.downLocalPath && !message.upLocalPath) ? (
                <Download {...props} />
            ) : (
                <Upload {...props} />
            )}
            <div className="upload-detail-btn" onClick={showDetailModal}>
                <span>查看文件下载详情</span>
                <IconFont
                    type="iconapp_ic_details"
                    style={{
                        fontSize: '11px',
                        color: 'rgba(48,111,219,0.6)',
                        height: '18px',
                        lineHeight: '22px',
                    }}
                />
            </div>
            <Drawer
                title={
                    <div className="drawer-title">
                        <span className="drawer-title_name">文件下载详情</span>
                        <span>
                            下载人数：<b>{baseData.downloadPeopleCount}</b>人
                        </span>
                        <span>
                            下载次数：<b>{baseData.downloadCount}</b>次
                        </span>
                    </div>
                }
                className="upload-detail-drawer"
                placement="right"
                width={340}
                onClose={handleClose}
                visible={detailVisible}
                destroyOnClose={true}
                maskClosable={true}
            >
                <>
                    {/* <div className="upload-detail-notice">
                        <IconFont
                            type="iconapp_ic_failure"
                            style={{ fontSize: '12px', color: '#FF7513' }}
                        />
                        <span className="notice-text">仅支持查看最近的文件下载详情数据</span>
                    </div> */}
                    <div className="upload-detail-content">
                        {!fetched ? (
                            <div className="detail-loading-wrap">
                                <Loading type="message" />
                            </div>
                        ) : dataList.length ? (
                            <div className="upload-detail-list">
                                <InfiniteScroll
                                    initialLoad={false}
                                    pageStart={0}
                                    loadMore={loadMoreData}
                                    hasMore={!fetching && hasMore}
                                    useWindow={false}
                                >
                                    <List
                                        dataSource={dataList || []}
                                        renderItem={(item) => {
                                            let newItem = {
                                                ...item,
                                                ...{ avatar: changeEgovUrl(item.avatar) },
                                            };
                                            return (
                                                <ListItem
                                                    data={newItem}
                                                    extra={
                                                        <div className="download-time">
                                                            {dayjs(item.downloadTime).format(
                                                                'YYYY.MM.DD HH:mm'
                                                            )}
                                                        </div>
                                                    }
                                                    className="upload-detail-item"
                                                />
                                            );
                                        }}
                                    />
                                    {/* {!hasMore && <Divider plain>暂无更多数据~</Divider>} */}
                                </InfiniteScroll>
                            </div>
                        ) : (
                            <DefaultPage
                                dataType={CompDataLoadState.NODATA}
                                pageType={DefaultPageType.KEYWORD}
                                description="暂无数据"
                            />
                        )}
                    </div>
                </>
            </Drawer>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

export default connect(mapStateToProps)(UploadWithDetail);
