import React, { useEffect, useCallback, useState, useRef } from 'react';
import { AppStoreApplication, RecommendedType } from '@/services/AppStore';
import { useTranslation } from 'react-i18next';
import ToolsItem from './ToolsItem/index';
import { isBjme } from '@/utils';
import { thirdApp } from '@/assets/img';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Bus from '@/utils/bus';
import ScrollView from '@/baseComponents/ScrollView';
import { changeEgovUrl } from '@/utils/tools';

// 设置样式
/* eslint max-params: ["error", 4] */
const getItemStyle = (isDragging: any, draggableStyle: any, isPointer: any, recommended: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    boxShadow: isDragging ? ' 0 1px 9px 0 rgba(198,198,198,0.5)' : '',
    position: 'relative',
    cursor: isPointer && recommended === RecommendedType.RecommendedNo ? 'move' : 'default',
    backgroundImage: `url(${changeEgovUrl(thirdApp)})`,
    // backgroundSize: '100% 100%',
    // backgroundPosition: '0  0',
    // thirdApp
    // 拖拽的时候背景变化
    // background: isDragging ? 'lightgreen' : '#ffffff',

    // styles we need to apply on draggables
    ...draggableStyle,
});

export default function RightToolsContent({
    rightContentList,
    isPointer,
    onReorder,
}: {
    isPointer: boolean;
    rightContentList: AppStoreApplication[];
    onReorder: (startIndex: any, endIndex: any) => void;
}) {
    const [loading, _setLoading] = useState(false);
    const [isDragging, setDragging] = useState(false);
    const [currentApplicationId, setCurrentApplicationId] = useState('');
    const scrollRef: any = useRef(null);
    // 设置loading及appKey
    const setLoading = useCallback((parames) => {
        const { loading = false, applicationId = '' } = parames;
        _setLoading(loading);
        setCurrentApplicationId(applicationId);
    }, []);

    useEffect(() => {
        Bus.on('setLoading', setLoading);
        return () => {
            Bus.off('setLoading', setLoading);
        };
    }, [setLoading]);

    const { t } = useTranslation('common');
    const onDragEnd = (result: any) => {
        setDragging(false);
        if (!result.destination) {
            return;
        }
        onReorder(result.source.index, result.destination.index);
    };
    return (
        <div className="right-tools-content">
            <ScrollView ref={scrollRef} autoHide>
                {rightContentList.length === 0 && (
                    <div style={{ textAlign: 'center' }}>
                        {isBjme() ? t('thirdapp.no-tools') : t('thirdapp.no-application')}
                    </div>
                )}
                <DragDropContext
                    onDragEnd={onDragEnd}
                    onBeforeDragStart={() => {
                        setDragging(true);
                    }}
                >
                    <div>
                        {rightContentList?.map((item: AppStoreApplication, index: number) => {
                            return !isPointer ? (
                                <ToolsItem
                                    key={item.applicationId}
                                    toolsItem={item}
                                    loading={loading}
                                    currentApplicationId={currentApplicationId}
                                />
                            ) : null;
                        })}
                        <Droppable droppableId="list">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {rightContentList?.map(
                                        (item: AppStoreApplication, index: number) => {
                                            return isPointer ? (
                                                <Draggable
                                                    key={item.applicationId}
                                                    draggableId={item.applicationId}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                                isPointer,
                                                                item.recommended
                                                            )}
                                                            className="circular"
                                                        >
                                                            <ToolsItem
                                                                isDragging={true}
                                                                toolsItem={item}
                                                                loading={loading}
                                                                currentApplicationId={
                                                                    currentApplicationId
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ) : null;
                                        }
                                    )}
                                    {/* {JSON.stringify(snapshot)} */}
                                    {/* 解决组件react-beautiful-dnd滚动底部出现抖动问题 */}
                                    {isDragging && (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '79px',
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
            </ScrollView>
        </div>
    );
}
