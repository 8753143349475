export interface CreateTeamAndEmployee {
    account: string;
    ddAppId: string;
    mobile: string;
    realName: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
    headImg: string;
}
export interface TeamUserInfo {
    emplEnabled?: string;
    account: string;
    ddAppId: string;
    headImg: string | null;
    displayDeptName?: string;
    mobile: string;
    realName: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
    email?: string;
    hasBindEmail?: boolean;
    hasBindMobile?: boolean;
    hasPassword?: boolean;
}

export interface ApprovaTeamUserInfo {
    account: string;
    ddAppId: string;
    mobile: string;
    realName: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
    approvalState: ApprovalStateEnum;
}

export enum ApprovalStateEnum {
    NO_PASS = 0,
    WAIT,
    PASS,
}
export type ApprovaTeamUserInfoList = ApprovaTeamUserInfo[];

export interface SendJoinApproveRes {
    isApprove: boolean;
    joinResult: string;
}
export interface InviteUserInfo {
    teamId: string;
    teamName: string;
    userName: string;
    userId: string;
    avatar: string;
}

export interface AuthDataRes {
    loginStatus: 'NORMAL' | 'NEW_USER' | 'NEW_TEAM';
    refreshToken: string;
    refreshTokenExpireIn: number;
    accessToken: string;
    accessTokenExpireIn: number;
    account: string;
    ddAppId: string;
    headImg: string | null;
    station: string;
    nickName: string | null;
    /**
     * @deprecated 这个字段在切换用户或者修改用户名之后，有可能还是上一次登录的用户名，不推荐用
     */
    realName: string | null;
    userId: string;
    selectedTeamId: string;
    teamUserInfo: TeamUserInfo;
    teamUserInfoList: TeamUserInfo[];
    mobile?: string;
    email?: string;
    hasPassword?: boolean;
    hasBindMobile?: boolean;
    hasBindEmail?: boolean;
}
export interface TeamLoginTypeInfo {
    type: string;
    typeName: string;
}
export interface TeamLoginTypeRes {
    teamId: string;
    typeList: TeamLoginTypeInfo[];
}
export interface ValidateAccountRes {
    hasPassword: boolean;
    isTrustedDevice: boolean;
    hasMobile: boolean;
    hasIdNum: boolean;
    hasEmail: boolean;
}

export enum BizTypeEnum {
    APP_LOGIN = 'APP_LOGIN',
    SET_PASSWORD = 'SET_PASSWORD',
    BIND_MOBILE = 'BIND_MOBILE',
    BIND_EMAIL = 'BIND_EMAIL',
    UPDATE_BIND_MOBILE = 'UPDATE_BIND_MOBILE',
    UPDATE_BIND_EMAIL = 'UPDATE_BIND_EMAIL',
}
export interface ValidateAccountReq {
    bizType: BizTypeEnum;
    loginName: string;
    loginNameType: string;
    teamId?: string;
}
export interface CaServerSecurityRes {
    strServerCert: string;
    strServerRan: string;
    strSignedData: string;
}
