import React from 'react';

import { IPage, ICategory } from './types';

export interface IColumnRenderProps<T> {
    data: T;
    setData: (data: T) => void;
    remove: () => void;
    reload: () => void;
    clearSelect?: () => void;
    freshLeftTreeByFolderById?: (data: any) => void;
}

export interface IColumnProps<Record = IPage, Category = ICategory> {
    className?: string;
    index?: number;
    name: string;
    header?: string | React.ReactNode;
    // 普通列渲染
    render?: (props: IColumnRenderProps<Record>) => React.ReactNode;
    // 文件分类列渲染
    categoryRender?: (props: IColumnRenderProps<Category>) => React.ReactNode;
}

export default function (props: IColumnProps) {
    return null;
}
