import { message, Modal } from 'antd';
import { openPicker as openCategoriesPicker } from '../components/CategoriesPickerTree';
import { IPageAddCallback, api } from './index';
import { getPermissions } from '../permissions';
import context, { lang } from '../context';
import { useContext } from 'react';
import { moveTips } from '../util';
import events from '../context/events';
type MoveType = Parameters<typeof api.page.moveTo>[1];
type PickerCallback = NonNullable<Parameters<typeof openCategoriesPicker>[0]['callback']>;
type PickerCallbackData = Parameters<PickerCallback>[0];

const toHandle = (data: PickerCallbackData): { id: string; type: MoveType } => {
    const { type, id, rootId } = data;
    // 移动到团队空间
    if (!id) {
        return {
            type: 'folder',
            id: 'root',
        };
    }
    if (type === 12) {
        // 移动到空间分类
        if (rootId !== id) {
            return {
                type: 'folder',
                id: id,
            };
        }
        // 移动到空间根目录
        if (rootId === id) {
            return {
                type: 'folder',
                id: rootId,
            };
        }
    }
    // 移动到我的空间
    if (id) {
        return {
            type: 'folder',
            id: id,
        };
    }
    // 默认移动到我的空间根目录
    return {
        type: 'folder',
        id: 'root',
    };
};

// eslint-disable-next-line max-params
const Move: IPageAddCallback = (props, isAllow, isTeam, globalContext) => {
    const { data, remove } = props;
    const callback: PickerCallback = async (o, close) => {
        // const spaceId = o?.space?.id;
        // if (spaceId) {
        //     const perm = await getPermissions(spaceId, 'space');
        //     if (!perm.PAGE_CREATE) {
        //         message.error(lang('ctx.move.perm.error'));
        //         return;
        //     }
        // }
        const tip = moveTips(props.data, o, isTeam);
        Modal.confirm({
            title: lang(tip),
            // content:
            //     '移动后将取消原文件夹协作者的共享，并重新共享给目标文件夹的协作者。',
            cancelText: lang('common.cancel'),
            okText: lang('common.confirm'),
            onOk: () => {
                if (!o.id) {
                    console.warn('notSelect');
                    return;
                }
                const to = toHandle(o);
                const toId = o.id === 'root' ? '' : o.id;
                api.page.moveTo(data.id, 'folder', toId, to.type).then((data) => {
                    if (data) {
                        events.emit('list.removeOrSetData', [props.data], 'delete');

                        message.success(lang('common.success'));
                        events.emit('list.reload');
                        // reload();
                        close();
                    } else {
                        message.error(lang('common.fail'));
                    }
                });
            },
        });
    };
    return [
        {
            children: lang('ctx.move.title'),
            callbackOrModal: () => {
                openCategoriesPicker({
                    title: `${lang('ctx.move.dialog.prefix')}${data.title}${lang(
                        'ctx.move.dialog.suffix'
                    )}`,
                    selectArray: [
                        {
                            id: props.data.id,
                            rootId: props.data.space_id,
                            type: 'file',
                        },
                    ],
                    isTeam: isTeam,
                    // type: 'all',
                    appRuntimeEnv: globalContext?.appRuntimeEnv,
                    // 过滤掉当前类目
                    // categoriesInterceptor: (categories) =>
                    //     categories.filter((category) => category.id !== data.space_category_id),
                    callback,
                });
            },
            isAllow,
            type: 'move',
            icon: 'pc_yundoc_move',
            splitCode: 2,
            tip: '将文档或文件夹移动到新的位置。仅文档和文件夹的所有者或创建者可移动。',
        },
    ];
};

export default Move;
