import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import './index.less';
import { Button, message, Radio, Spin } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'dvajs';
import { getRelatedScheduleList, getScheduleInfo } from '@/api/calendar';
import dayjs, { Dayjs } from 'dayjs';
import { groupBy, orderBy } from 'lodash';
import { handleScheduleList } from '@/components/Calendars/utils';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ISchedule } from '@/types/supervise';
import Loading from '@/components/Loading';
import { useTranslation } from 'react-i18next';
import Tab from '../../../Tab';
import { CalendarsColor } from '../..';
import Empty from '../../../empty';

// 显示今天/明天/昨天
function getDateString(_date: any): string {
    const timestamp = dayjs(_date).valueOf();
    const today = dayjs();

    if (today.isSame(timestamp, 'day')) {
        return '今天';
    }
    if (dayjs(timestamp).add(1, 'day').isSame(today, 'day')) {
        return '昨天';
    }
    if (dayjs(timestamp).subtract(1, 'day').isSame(today, 'day')) {
        return '明天';
    }
    return '';
}

function mapStateToProps({ user }: { user: any }) {
    return {
        userData: user.userData.user,
    };
}

function SchduleList({
    userInfoList,
    scheduleList,
    onLoadMore,
    onScheduleRadioChange,
}: {
    userInfoList: any;
    scheduleList: any;
    onLoadMore: (type: string) => void;
    onScheduleRadioChange: (e: RadioChangeEvent | any) => void;
}) {
    const [radioValue, setRadioValue] = useState(null);
    // 按开始时间归类
    // const groupScheduleList = groupBy(orderBy(scheduleList, 'beginTime', 'asc'), function (
    //     schedule: ISchedule
    // ) {
    //     return dayjs(schedule.beginTime).startOf('day').valueOf();
    // });
    // console.log(groupScheduleList, 'groupScheduleListgroupScheduleListgroupScheduleList');
    // TODO:每次更新后都会滚动到今日日程位置，待优化
    useEffect(() => {
        const todayEle = document.getElementsByClassName('today')[0];
        if (todayEle) {
            todayEle.scrollIntoView();
        }
    });
    // 获取日程创建人的姓名
    const getScheduleCreatUserName = (ownerUserId = '') => {
        if (!ownerUserId) {
            return;
        }
        // console.log(userInfoList, 'userInfoListuserInfoList');
        const userInfo: any = userInfoList.find((item: any) => item.userId === ownerUserId);
        return userInfo ? userInfo.realName : '';
    };
    return (
        <div
            style={{
                background: '#F6F6F6',
                padding: '12px 24px 24px 24px',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <div className="load-more before">
                <Button type="link" icon={<ReloadOutlined />} onClick={() => onLoadMore('before')}>
                    查看更多历史日程
                </Button>
            </div>
            {Object.keys(scheduleList).length ? (
                <Radio.Group
                    onChange={(e) => {
                        // console.log(e.target.value, 'e.target.valuee.target.valuee.target.value');
                        // setRadioValue(e.target.value);
                        // onScheduleRadioChange(e);
                    }}
                    value={radioValue}
                    style={{ width: '100%' }}
                >
                    {Object.keys(scheduleList).map((key: any) => {
                        return (
                            <div className="supervise-schedule-item" key={key}>
                                <div
                                    className={[
                                        'week-title',
                                        dayjs().isSame(Number(key), 'day') ? 'today' : '',
                                    ].join(' ')}
                                >
                                    <span>
                                        {getDateString(key)} {key}
                                    </span>
                                </div>
                                {scheduleList[key].map((item: any, idx: number) => {
                                    const name = getScheduleCreatUserName(item.ownerUserId);
                                    if (!name) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            className="content"
                                            key={item.scheduleId + idx}
                                            onClick={(e) => {
                                                const event = { target: { value: item } };
                                                console.log(item, 'itemitemitemitem');
                                                setRadioValue(item);
                                                onScheduleRadioChange(event);
                                            }}
                                        >
                                            <div className="schedule-radio">
                                                <Radio value={item} />
                                            </div>
                                            <div className="schedule-list">
                                                <div style={{ display: 'flex', paddingTop: 2 }}>
                                                    <Tab
                                                        type={CalendarsColor[(item as any).color]}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <p className="item-title">{item.title}</p>
                                                    <div className="item-info">
                                                        <span className="item-time">{`${dayjs(
                                                            item.beginTime
                                                        ).format('HH:mm')} - ${dayjs(
                                                            item.endTime
                                                        ).format('HH:mm')}`}</span>
                                                        <span>来自：{name}</span>
                                                    </div>
                                                </div>
                                                {/* <p className="item-addr">地点：{item.address}</p> */}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </Radio.Group>
            ) : (
                <div className="schedule-list-empty">
                    <Empty text="暂无日程" />
                </div>
            )}

            <div className="load-more after">
                <Button type="link" icon={<ReloadOutlined />} onClick={() => onLoadMore('after')}>
                    查看更多未来日程
                </Button>
            </div>
        </div>
    );
}
// 获取列表
function getScheduleList(list: any[], startTimeMs: number, endTimeMs: number) {
    const _list = handleScheduleList(list, dayjs(startTimeMs), dayjs(endTimeMs));
    // 去掉空数据
    for (let key in _list) {
        if (!_list[key].length) {
            delete _list[key];
        }
    }
    // console.log(_list, '_list_list', endTimeMs);
    return _list;
}

function Schedule({
    userData,
    changeSelectedScheduleItem,
}: {
    userData: any;
    changeSelectedScheduleItem: (checkedScheduleItem: ISchedule) => void;
}) {
    const [scheduleList, setScheduleList] = useState({});
    const [userInfoList, setUserInfoList] = useState([]);
    const [loading, changeLoading] = useState(false);
    const [loadType, changeLoadType] = useState('current');
    const { t } = useTranslation('calendar');
    // 查询时间，默认查询当前日期前后30天日程
    const currentBeginDate = dayjs().startOf('day').subtract(30, 'day').valueOf(); // 开始时间

    // 加载更多之前的日程的开始时间
    const [loadMoreBeforeBeginDate, changeLoadMoreBeforeBeginDate] = useState(
        dayjs(currentBeginDate).subtract(30, 'day').valueOf()
    );
    // 加载更多未来的日程的开始时间
    const [loadMoreAfterBeginDate, changeLoadMoreAfterBeginDate] = useState(
        dayjs().startOf('day').add(30, 'day').valueOf()
    );

    function handelLoadMore(loadType: string) {
        let beginDate = currentBeginDate;
        let endDate = dayjs().startOf('day').add(30, 'day').valueOf();
        if (loadType === 'before') {
            beginDate = loadMoreBeforeBeginDate;
            endDate = dayjs(beginDate).add(30, 'day').valueOf();
        }
        if (loadType === 'after') {
            beginDate = loadMoreAfterBeginDate;
            endDate = dayjs(beginDate).add(30, 'day').valueOf();
        }

        changeLoading(true);
        getRelatedScheduleList({
            user: {
                userId: userData.userId,
                teamId: userData.team.teamId,
            },
            beginDate,
            endDate,
        }).then((res: any) => {
            changeLoading(false);
            const list: any = getScheduleList(res.scheduleList, beginDate, endDate);
            if (loadType === 'before') {
                setScheduleList({ ...list, ...scheduleList });
            } else {
                setScheduleList({ ...scheduleList, ...list });
            }
            const userList = [...userInfoList].concat(res.userInfoList);
            setUserInfoList(userList);
            if (!Object.keys(list).length) {
                const text = loadType === 'before' ? '历史' : '未来';
                message.warning(`暂无更多${text}日程数据`);
            }
        });
    }

    useEffect(() => {
        let beginDate = currentBeginDate;
        let endDate = dayjs().startOf('day').add(30, 'day').valueOf();

        changeLoading(true);
        getRelatedScheduleList({
            user: {
                userId: userData.userId,
                teamId: userData.team.teamId,
            },
            beginDate,
            endDate,
        }).then((res: any) => {
            changeLoading(false);
            const list: any = getScheduleList(res.scheduleList, beginDate, endDate);
            setScheduleList(list || {});
            setUserInfoList(res.userInfoList);
        });
    }, [userData, currentBeginDate]);

    function loadMore(type: string) {
        if (type === 'before') {
            changeLoadMoreBeforeBeginDate(
                dayjs(loadMoreBeforeBeginDate).subtract(30, 'day').valueOf()
            );
        } else {
            changeLoadMoreAfterBeginDate(dayjs(loadMoreAfterBeginDate).add(30, 'day').valueOf());
        }
        changeLoadType(type);
        handelLoadMore(type);
    }

    // 根据选择的日程查询日程详情
    function onScheduleRadioChange(e: RadioChangeEvent) {
        getScheduleInfo({
            scheduleId: e.target.value.scheduleId,
        })
            .then((res) => {
                if (!res) {
                    message.warning(t('schedule is not found'));
                    return;
                }
                changeSelectedScheduleItem({ ...res, scheduleId: e.target.value.scheduleId });
            })
            .catch((err) => {
                message.error(err.message?.toString() || t('schedule is not found'));
            });
    }

    // return loading ? (
    //     // <Spin indicator={<LoadingOutlined />}>
    //     //     <SchduleList
    //     //         userInfoList={userInfoList}
    //     //         scheduleList={scheduleList}
    //     //         onLoadMore={loadMore}
    //     //         onScheduleRadioChange={onScheduleRadioChange}
    //     //     />
    //     // </Spin>
    //     <div style={{ width: '100%', height: '100%', display: 'flex' }}>
    //         <Loading />
    //     </div>
    // ) : (
    //     <SchduleList
    //         userInfoList={userInfoList}
    //         scheduleList={scheduleList}
    //         onLoadMore={loadMore}
    //         onScheduleRadioChange={onScheduleRadioChange}
    //     />
    // );
    return (
        <>
            {loading && (
                <div
                    style={{
                        width: 'calc(100% - 30px)',
                        height: 'calc(100% - 90px)',
                        position: 'absolute',
                        zIndex: 99999,
                        display: 'flex',
                    }}
                >
                    <Loading />
                </div>
            )}

            <SchduleList
                userInfoList={userInfoList}
                scheduleList={scheduleList}
                onLoadMore={loadMore}
                onScheduleRadioChange={onScheduleRadioChange}
            />
        </>
    );
}

export default connect(mapStateToProps, () => {
    return {};
})(Schedule);
