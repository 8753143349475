/**
 * 加载图片
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useMemo } from 'react';

import './index.less';
import { SysChatMessage } from '@/types/chat';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { isEqualEmployee } from '../../utils/message';
import JoyMeetingCard from './JoyMeetingCard';
import JoyMeetingSys from './JoyMeetingSys';
import { NoticeMeetingCard, SysMeetingCard } from './MeetingCard';
import { AVSDK } from '@/baseComponents/Meeting/common';
import { duccConfigI18 } from '@/utils/i18n';

interface SysProps {
    message: SysChatMessage;
}

type IProps = Readonly<SysProps & ChatState>;

function SysMessage(props: IProps) {
    const { message, currentEmployee, selectedSession } = props;
    // console.log('SysMessage', message);
    const { t } = useTranslation('chat');

    const tipContent = useMemo(() => {
        // console.log('sysMessage', message);
        if (message.flag === 1) {
            // 0-直接显示content,1-文件下载（需要拼接content），2-接收红包消息（需要拼接content）
            const receiver = isEqualEmployee(message.sender, currentEmployee)
                ? `您`
                : message.nickName;
            return t('receive-file-tip', { receiver, filename: message.content });
        }
        if (message.flag === 99) {
            return (
                <div style={{ display: 'flex', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    已将
                    <p className="ellipsis" style={{ marginLeft: '2px' }}>
                        {message.name as any}
                    </p>
                    转存到 {duccConfigI18.joyspace}-我的文件
                </div>
            );
        } else if (message.flag === 3) {
            const receiver = isEqualEmployee(message.sender, currentEmployee)
                ? `您`
                : message.nickName;
            return `${receiver}${message.content}`;
        } else {
            return null;
        }
    }, [message, currentEmployee, t]);

    if (message.flag === 100) {
        if (message.ext?.avsdk === AVSDK.ZOOM) {
            if (selectedSession.isNotice) {
                return <JoyMeetingCard message={message} t={t} />;
            } else {
                return <JoyMeetingSys message={message} t={t} />;
            }
        } else if (message.ext?.avsdk) {
            if (selectedSession.isNotice) {
                return <NoticeMeetingCard message={message} t={t} />;
            } else {
                return <SysMeetingCard message={message} t={t} />;
            }
        } else if (message.ext && !message.ext.avsdk) {
            // 旧版本视频会议消息
            return <JoyMeetingSys message={message} t={t} />;
        }
    }

    return <div className="sys-message-box">{tipContent}</div>;
}

function mapStateToProps({ chat }: any) {
    const { currentEmployee, selectedSession } = chat as ChatState;
    return { currentEmployee, selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SysMessage);
