/* eslint-disable complexity */
// 群接龙详情
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './index.less';
import { Drawer, Form, Space, Button, Input, message as toast, Spin, Dropdown, Menu } from 'antd';
import { Employee } from '@/types/chat';
import IconFont from '@/components/icon';
import ChatState from '@/types/chat/State';
import { connect } from 'dvajs';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import i18n from '@/i18n';
import ImService from '@/server/ImService';
import { isEqualTeam } from '@/components/chat/utils/message';
import { getSolitaire, uptSolitaire } from '@/api/Solitaire';
import { Avatar } from '@/baseComponents/Avatar';
import CopyClipboard from 'copy-to-clipboard';
import { changeEgovUrl } from '@/utils/tools';
import { Jlmate } from '@/utils/date';
import { isGroupAdmin, isGroupOwner } from '@/utils/chat/group';
import {
    ChatMessageExtType,
    MessageExtAction,
} from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/Type';
import bus from '@/utils/bus';
import { getGroupInfo } from '@/api/egroup';
const { TextArea } = Input;

interface MessageOperateProps {
    currentEmployee: Employee;
    connectState: ConnectState;
    solitaireSoli?: boolean;
    updateSessionMessageMid: Function;
    openSolitaireDetails: (data: { isvisibles: boolean; solitaire: any }) => void;
    openSolitaireHis: (data: { isvisibles: boolean; solitaireSid: any }) => void;
}
let solitaireUserList: any = [];
let solitaireObj = {
    solitaireUserId: '',
    appId: '',
    teamId: '',
    solitaireId: '',
    name: '',
    value: '',
    userId: '',
    status: 1,
};
let solitaireUserId: any;
let resultSolitaire: any = [];
let userNameVal = '';
let groupRosterIdentity: any;
let message: any;
let sessionMessages: any[];
type IProps = Readonly<MessageOperateProps & ChatState>;

function SolitaireDetails(props: IProps) {
    const [t] = useTranslation('chat');
    const {
        currentEmployee,
        connectState,
        SolitaireDetailsVisible,
        solitaireId,
        openSolitaireDetails,
        openSolitaireHis,
        solitaireSoli,
        updateSessionMessageMid,
        sessionMessageMap,
    } = props;
    const inputRef = React.useRef<any>(null);
    const btn = React.useRef<any>(null);
    const [tiems, settiems] = useState(false);
    const [descCon, setdescCon] = useState('');
    const [solitaire, setsolitaire] = useState<any>({});
    const [det, setdet] = useState(false);
    const [disabled, setdisabled] = useState(false);
    const [loading, setloading] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [edit, setedit] = useState(false);
    const state: any = connectState;
    let indexs: number;

    // 取消
    const onClose = useCallback(() => {
        openSolitaireDetails({
            isvisibles: false,
            solitaire: '',
        });
        setedit(false);
        setisloading(false);
        setdisabled(false);
        setdet(false);
        settiems(false);
        solitaireUserList = [];
        resultSolitaire = [];
        userNameVal = '';
    }, [openSolitaireDetails]);

    // 打开组接龙
    const handleSolitaireClick = useCallback(async () => {
        // 查询缓存
        const instance = ImService.getInstance();
        setloading(true);
        if (state.state === ConnectState.OFFLINE) {
            return toast.error(i18n.t('Network Error'));
        }

        try {
            const [result, err] = await getSolitaire({
                appId: currentEmployee.app,
                teamId: currentEmployee.teamId,
                userId: currentEmployee.userId,
                solitaireId: solitaireId,
            });
            if (err) {
                toast.error(err);
                onClose();
                return;
            }
            if (result) {
                sessionMessages = sessionMessageMap[result.solitaire.businessId] || [];
                const rosters = await instance.getGroupRosters(result.solitaire.businessId, true);
                groupRosterIdentity = rosters?.filter((item) => {
                    return (
                        item.user?.pin === currentEmployee.userId &&
                        item.user?.teamId === currentEmployee.teamId
                    );
                });
                groupRosterIdentity = groupRosterIdentity[0].identity;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                message = sessionMessages.filter((item) => {
                    return item.mid === Number(result.solitaire.mid);
                });
                if (result.solitaire.status === 2) {
                    settiems(true);
                } else {
                    settiems(false);
                }
                setsolitaire(result);
                setloading(false);
                // 默认添加选项
                let isGroups = result.solitaire?.solitaireUserList.map((item: any) => {
                    if (
                        item.userId === currentEmployee.userId &&
                        currentEmployee.teamId === item.teamId
                    ) {
                        return 'false';
                    }
                });
                if (isGroups.includes('false')) {
                    return;
                }
                const AddBtn = btn.current;
                if (!btn.current) return;
                AddBtn.click();
                let TextRef: any = document.getElementById('TextRef');
                TextRef.focus();
            }
        } catch (err) {
            console.log('err', err);
        }
    }, [state, currentEmployee, solitaireId, onClose, sessionMessageMap]);

    useEffect(() => {
        if (SolitaireDetailsVisible) {
            handleSolitaireClick();
        }
    }, [SolitaireDetailsVisible, handleSolitaireClick]);

    // 参与群接龙
    function sendMessages2() {
        if (state.state === ConnectState.OFFLINE) {
            return;
        }
        setisloading(true);
        solitaireUserList.push({
            solitaireUserId: '',
            appId: currentEmployee.app,
            teamId: currentEmployee.teamId,
            solitaireId: solitaireId,
            name: userNameVal ? userNameVal : currentEmployee.name,
            value: descCon,
            userId: currentEmployee.userId,
            status: 1,
        });
        sendMessages();
    }

    // 保存接龙
    const sendMessages = useCallback(async () => {
        if (state.state === ConnectState.OFFLINE) {
            return toast.error(i18n.t('Network Error'));
        }
        let use: any;
        solitaire.solitaire?.solitaireUserList.map((item: any) => {
            if (item.userId === currentEmployee.userId) {
                use = item.userId;
            }
        });
        // const instance = ImService.getInstance();
        const [data, err] = await uptSolitaire({
            appId: currentEmployee.app,
            teamId: currentEmployee.teamId,
            userId: currentEmployee.userId,
            solitaireId: solitaire.solitaire?.solitaireId,
            solitaireUserList:
                det === true || solitaireUserList[0] === undefined
                    ? ([resultSolitaire[0]] as any)
                    : [solitaireUserList[0]],
        });
        if (err) {
            toast.error(err);
            setisloading(false);
            setdisabled(false);
            return;
        }
        // const newDate = new Date().getTime();
        // const msgType = ChatMessageExtType.GROUP_SOLITAIRE_NEW;
        if (edit) {
            onClose();
            return;
        }
        if (use === solitaireObj.userId || det === true) {
            // if (
            //     message[0].userList.length > 0 &&
            //     det === true &&
            //     message[0].userList[0].pin === currentEmployee.userId
            // ) {
            //     let Msg = message[0];
            //     // eslint-disable-next-line array-callback-return
            //     Msg.userList.filter((item: any) => {
            //         if (item) {
            //             item.action = 1;
            //         }
            //     });
            //     const result = await instance.updateMessage(Msg.sessionId, Msg.mid, {
            //         ...Msg,
            //         userList: [] as any,
            //     });
            //     updateSessionMessageMid({
            //         sessionId: Msg.sessionId,
            //         sendResult: { body: { ...Msg, userList: [] } },
            //     });
            // }
            // let users = {
            //     app: currentEmployee.app,
            //     clientType: 'pc',
            //     name: currentEmployee.name,
            //     nickName: currentEmployee.name,
            //     pin: currentEmployee.userId,
            //     teamId: currentEmployee.teamId,
            //     timestamp: newDate,
            //     action: 1,
            // };
            // let msgContentItem = {
            //     users: [users],
            // } as any;
            // let action = MessageExtAction.DELETE;
            // const detMessage = buildSolitaireMessageBody({
            //     msgType,
            //     action,
            //     session: { sessionId: solitaire.solitaire?.businessId, sessionType: 2 },
            //     mid: Number(solitaire.solitaire?.mid),
            //     msgContentItem,
            // });
            // await instance.sendChatMessageExt(solitaire.solitaire?.businessId, detMessage as any);
            bus.emit('work:Solitaire:solitaireHistory', solitaire.solitaire.solitaireId);
        } else {
            // let users = {
            //     app: currentEmployee.app,
            //     clientType: 'pc',
            //     name: currentEmployee.name,
            //     nickName: currentEmployee.name,
            //     pin: currentEmployee.userId,
            //     teamId: currentEmployee.teamId,
            //     timestamp: newDate,
            //     action: 0,
            // };
            // let msgContentItem = {
            //     users: [users],
            // } as any;
            // let action = MessageExtAction.ADD;
            // const datas = buildSolitaireMessageBody({
            //     msgType,
            //     action,
            //     session: { sessionId: solitaire.solitaire?.businessId, sessionType: 2 },
            //     mid: Number(solitaire.solitaire?.mid),
            //     msgContentItem,
            // });
            // await instance.sendChatMessageExt(solitaire.solitaire?.businessId, datas as any);
            // bus.emit('chat:message:GroupCard', solitaire.solitaire.solitaireId);
            bus.emit('work:Solitaire:solitaireHistory', solitaire.solitaire.solitaireId);
        }
        onClose();
    }, [state.state, solitaire, currentEmployee, det, onClose, edit]);

    // 导出数据
    const onExport = (e: any) => {
        e.preventDefault();
        let personnel = '';
        solitaire.solitaire?.solitaireUserList.filter((item: any, index: any) => {
            const inx = index + 1;
            personnel += inx + '、' + item.name + '&nbsp' + item.value + '\n';
        });
        const onExportText = `接龙名称：${solitaire.solitaire?.title} \n来源：${solitaire.solitaire?.sourceName} \n发起人：${solitaire.solitaire?.createName} \n目前有${solitaire.solitaire?.count}人参与 \n${personnel} \n
            `;
        CopyClipboard(onExportText);
        if (CopyClipboard(onExportText)) {
            toast.success('接龙数据文本已复制');
        }
    };

    // 删除数据
    const remove = (item: any, index: any) => {
        setdet(true);
        setdisabled(true);
        const remov: any = document.querySelectorAll('.items');
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < remov.length; i++) {
            if (item.solitaireUserId === remov[i].getAttribute('id')) {
                resultSolitaire.push(item);
                solitaireUserList.push(item);
                remov[i].style.display = 'none';
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                index - 1;
                solitaire.solitaire?.solitaireUserList.map((item1: any, index: any) => {
                    if (item.solitaireUserId === item1.solitaireUserId) {
                        if (item1.userId !== currentEmployee.userId) {
                            return;
                        }
                        // eslint-disable-next-line no-return-assign
                        return (item.status = 0);
                    }
                });
            }
        }
    };

    // 获取数据
    function onValuesChange(e: any) {
        setdisabled(true);
        setedit(true);
        setdet(true);
        solitaire.solitaire?.solitaireUserList.map((item: any) => {
            if (item.solitaireUserId === solitaireUserId) {
                resultSolitaire.push(item);
                solitaireUserList.push(item);
                // eslint-disable-next-line no-return-assign
                return (item.value = e.target.value);
            }
        });
    }

    function onNameChange(e: any) {
        setdisabled(true);
        solitaire.solitaire?.solitaireUserList.map((item: any) => {
            // setdet(true);
            if (item.name !== e.target.value && item.userId === currentEmployee.userId) {
                resultSolitaire.push(item);
                solitaireUserList.push(item);
                // eslint-disable-next-line no-return-assign
                return (item.name = e.target.value);
            }
        });
    }

    const footer = (
        <div>
            <Space align="end">
                <Button onClick={onClose}>取消</Button>
                {tiems === false && (
                    <Button
                        disabled={disabled === false ? true : false}
                        type="primary"
                        onClick={debounce(() => {
                            sendMessages2();
                        }, 500)}
                    >
                        发布
                    </Button>
                )}
            </Space>
        </div>
    );

    const menu = (
        <Menu className="GroupSlitaireMenu">
            {(solitaire.solitaire?.createBy === currentEmployee.userId &&
                solitaire.solitaire?.teamId === currentEmployee.teamId) ||
            isGroupOwner(groupRosterIdentity) ||
            isGroupAdmin(groupRosterIdentity) ? (
                <Menu.Item>
                    <span
                        onClick={(e) => {
                            onExport(e);
                        }}
                    >
                        导出数据到剪贴板
                    </span>
                </Menu.Item>
            ) : null}
            {!solitaireSoli && (
                <Menu.Item>
                    <span
                        onClick={() => {
                            openSolitaireHis({
                                isvisibles: true,
                                solitaireSid: solitaire.solitaire?.businessId,
                            });
                            onClose();
                        }}
                    >
                        查看历史接龙
                    </span>
                </Menu.Item>
            )}
        </Menu>
    );

    const contentForm = (
        <Form labelAlign="left" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
            {solitaire.solitaire?.solitaireUserList.map((item: any, index: any) => {
                indexs = index + 1;
                return (
                    <div key={index} className="items" id={item.solitaireUserId}>
                        <Form.Item
                            label={
                                currentEmployee.userId === item.userId &&
                                !tiems &&
                                item.teamId === currentEmployee.teamId ? (
                                    <IconFont
                                        type="iconbutton_app_yichu"
                                        className="removeIcon"
                                        onClick={() => {
                                            remove(item, index);
                                        }}
                                    />
                                ) : (
                                    index + 1
                                )
                            }
                            required={false}
                            colon={false}
                        >
                            <Form.Item
                                // {...item}
                                // refs={divEl}
                                label={
                                    currentEmployee.userId === item.userId && !tiems ? (
                                        <IconFont
                                            type="iconbutton_app_yichu"
                                            className="removeIcon"
                                            onClick={() => {
                                                remove(item, index);
                                            }}
                                        />
                                    ) : (
                                        index + 1
                                    )
                                }
                                style={{ padding: index >= 10 ? '4px' : '8px' }}
                                validateTrigger={['onChange', 'onBlur']}
                                noStyle
                                name={['names1', 'value']}
                            >
                                <div className="names">
                                    {item.userId !== currentEmployee.userId ||
                                    !isEqualTeam(item, currentEmployee) ||
                                    tiems ? (
                                        <div className="defaultName">
                                            <span
                                                style={{
                                                    margin: '0 8px',
                                                    color: '#62656D',
                                                    whiteSpace: 'pre',
                                                }}
                                            >
                                                {(solitaire.solitaire?.createBy ===
                                                    currentEmployee.userId &&
                                                    solitaire.solitaire?.teamId ===
                                                        currentEmployee.teamId) ||
                                                isGroupOwner(groupRosterIdentity) ||
                                                isGroupAdmin(groupRosterIdentity) ||
                                                (isEqualTeam(item, currentEmployee) &&
                                                    item.userId === currentEmployee.userId)
                                                    ? item.name
                                                    : '* * *'}
                                            </span>
                                            <p style={{ color: '#232930' }}>{item.value}</p>
                                        </div>
                                    ) : (
                                        <>
                                            <Input
                                                className="hover"
                                                placeholder="姓名"
                                                allowClear={true}
                                                defaultValue={
                                                    (solitaire.solitaire?.createBy ===
                                                        currentEmployee.userId &&
                                                        solitaire.solitaire?.teamId ===
                                                            currentEmployee.teamId) ||
                                                    isGroupOwner(groupRosterIdentity) ||
                                                    isGroupAdmin(groupRosterIdentity) ||
                                                    (isEqualTeam(item, currentEmployee) &&
                                                        item.userId === currentEmployee.userId)
                                                        ? item.name
                                                        : '* * *'
                                                }
                                                style={{
                                                    width: '110px',
                                                    marginRight: '12px',
                                                    color:
                                                        item.userId === currentEmployee.userId &&
                                                        !tiems
                                                            ? '#232930'
                                                            : '#8F959E',
                                                    // padding:
                                                    //     item.userId === currentEmployee.userId && !tiems
                                                    //         ? '4px 11px'
                                                    //         : '4px 0px 4px 10px',
                                                }}
                                                onChange={(e: any) => {
                                                    onNameChange(e);
                                                }}
                                                disabled={!tiems ? false : true}
                                            />
                                            <TextArea
                                                placeholder="接龙内容"
                                                disabled={!tiems ? false : true}
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 4,
                                                }}
                                                maxLength={100}
                                                style={{
                                                    resize: 'none',
                                                    color:
                                                        item.userId === currentEmployee.userId &&
                                                        isEqualTeam(item, currentEmployee) &&
                                                        !tiems
                                                            ? '#232930'
                                                            : '#8F959E',
                                                    // padding:
                                                    //     item.name.length > 3
                                                    //         ? '4px 10px 4px 2px'
                                                    //         : '4px 11px',
                                                }}
                                                defaultValue={item.value}
                                                onChange={(e: any) => {
                                                    solitaireUserId = item.solitaireUserId;
                                                    onValuesChange(e);
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </Form.Item>
                        </Form.Item>
                    </div>
                );
            })}
        </Form>
    );
    if (!SolitaireDetailsVisible) return null;
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            <Drawer
                width={445}
                title={t('solitaire')}
                className="work-solitaire"
                onClose={onClose}
                mask={true}
                visible={SolitaireDetailsVisible}
                destroyOnClose={true}
                placement="right"
                footer={tiems === true ? null : footer}
            >
                {isloading === false ? (
                    <>
                        <div className="headers">
                            <div className="headers-left">
                                <Avatar
                                    src={changeEgovUrl(solitaire.solitaire?.headImg)}
                                    name={solitaire.solitaire?.createName || ''}
                                />
                                <p>
                                    由{solitaire.solitaire?.createName}发起接龙，目前有
                                    {solitaire.solitaire?.count}人参与
                                </p>
                            </div>
                            <Dropdown overlay={menu}>
                                <IconFont type="iconlog_more" />
                            </Dropdown>
                        </div>
                        <div className="content">
                            <h3 style={{ fontWeight: 700 }}>{solitaire.solitaire?.title}</h3>
                            <p
                                style={{
                                    color: '#62656D',
                                    marginBottom: '15px',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {solitaire.solitaire?.desc}
                            </p>
                            <div
                                style={{
                                    marginBottom: '16px',
                                    color: '#62656D',
                                }}
                            >
                                {/* <IconFont type="icona-ic_pc_groupsolitaire_visible" />
                                    &nbsp;&nbsp;
                                    <span>
                                        {solitaire.solitaire?.open === 0
                                            ? '隐藏显示结果'
                                            : '公开显示结果'}
                                    </span> */}
                                {solitaire.solitaire?.deadLine && (
                                    <div className="isTimes">
                                        <span style={{ color: '#232930' }}>
                                            {Jlmate(solitaire.solitaire?.deadLine)}
                                        </span>
                                        &nbsp;&nbsp;
                                        <span
                                            style={{
                                                color: tiems === true ? '#EE0A24' : '#62656D',
                                            }}
                                        >
                                            {tiems === true ? '已截止' : '截止'}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="con-bom">
                            {loading === false ? (
                                <div>{contentForm}</div>
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    <Spin tip="加载中..." spinning={loading} />
                                </div>
                            )}
                            <Form
                                // onValuesChange={ValuesChange}
                                labelAlign="left"
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 22 }}
                            >
                                <Form.List name="names">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field, index) => {
                                                return (
                                                    <Form.Item
                                                        label={
                                                            fields.length > 0 ? (
                                                                <IconFont
                                                                    type="iconbutton_app_yichu"
                                                                    className="removeIcon"
                                                                    onClick={() => {
                                                                        remove(field.name);
                                                                        solitaireUserList.splice(
                                                                            index,
                                                                            1
                                                                        );
                                                                        setdet(true);
                                                                    }}
                                                                />
                                                            ) : (
                                                                index + indexs + 1 || index + 1
                                                            )
                                                        }
                                                        required={false}
                                                        key={field.key}
                                                        colon={false}
                                                    >
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                            name={[field.name, 'value']}
                                                        >
                                                            <div className="names">
                                                                <Input
                                                                    className="hover"
                                                                    allowClear={true}
                                                                    placeholder="姓名"
                                                                    ref={inputRef}
                                                                    defaultValue={
                                                                        currentEmployee.name
                                                                    }
                                                                    style={{
                                                                        width: '110px',
                                                                        marginRight: '12px',
                                                                    }}
                                                                    onChange={(e) => {
                                                                        userNameVal =
                                                                            e.target.value;
                                                                    }}
                                                                />
                                                                <TextArea
                                                                    id="TextRef"
                                                                    placeholder="接龙内容"
                                                                    autoSize={{
                                                                        minRows: 1,
                                                                        maxRows: 4,
                                                                    }}
                                                                    maxLength={100}
                                                                    style={{
                                                                        resize: 'none',
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setdescCon(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Form.Item>
                                                    </Form.Item>
                                                );
                                            })}
                                            {tiems === false && loading === false && (
                                                <Form.Item>
                                                    <div
                                                        className="con-bom-add"
                                                        ref={btn}
                                                        onClick={() => {
                                                            let isGroups = solitaire.solitaire?.solitaireUserList.map(
                                                                (item: any) => {
                                                                    if (
                                                                        item.userId ===
                                                                            currentEmployee.userId &&
                                                                        item.teamId ===
                                                                            currentEmployee.teamId
                                                                    ) {
                                                                        return 'false';
                                                                    }
                                                                }
                                                            );
                                                            if (
                                                                isGroups.includes('false') ||
                                                                fields.length > 0
                                                            ) {
                                                                toast.error(
                                                                    `该接龙不允许每人接龙多次`
                                                                );
                                                                return;
                                                            }
                                                            add();
                                                            setdisabled(true);
                                                        }}
                                                    >
                                                        <IconFont
                                                            type="iconapp_btn_more"
                                                            className="chat-aggregation-icon"
                                                            clstag="pageclick|keycount|focus_chat_01_1615797500283|77"
                                                        />
                                                        <span style={{ marginLeft: '12px' }}>
                                                            添加选项
                                                        </span>
                                                    </div>
                                                </Form.Item>
                                            )}
                                        </>
                                    )}
                                </Form.List>
                            </Form>
                        </div>
                    </>
                ) : (
                    <div style={{ position: 'absolute', top: '50%', left: '45%' }}>
                        <Spin tip="发送中..." spinning={isloading} />
                    </div>
                )}
            </Drawer>
        </>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const {
        sessionMessageMap,
        connectState,
        currentEmployee,
        SolitaireDetailsVisible,
        solitaireId,
    } = chat;

    return {
        sessionMessageMap,
        connectState,
        currentEmployee,
        SolitaireDetailsVisible,
        solitaireId,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        openSolitaireDetails(data: { isvisibles: boolean; solitaire: any }) {
            dispatch({ type: 'chat/openSolitaireDetails', payload: data });
        },
        openSolitaireHis(data: { isvisibles: boolean; solitaireSid: any }) {
            dispatch({ type: 'chat/openSolitaireHis', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SolitaireDetails);
