import { request } from '@/components/Joyspace/api/request';
import { IPageBase, User } from '../types';
import { Method, pwaAttribute } from '@/attributes/pwaAttribute';
import joyspaceController from '../controllers/JoyspaceController';
// eslint-disable-next-line @typescript-eslint/no-require-imports
// const joyspaceController = require('../controllers/JoyspaceController');

console.log('this is sfdfsdfsdfdsfdsf', joyspaceController);
// import { stringEncode } from '@/components/chat/utils';
class PagesApi {
    @pwaAttribute(Method.get, joyspaceController, 'getList', 'setList', 'recent', 'concatList')
    recent(params?: { start: number; length: number }) {
        return request({
            api: 'joyspace.pages.recent',
            path: `/v1/pages/recent`,
            params,
        });
    }
    @pwaAttribute(Method.get, joyspaceController, 'getList', 'setList', 'shareBy')
    myshare(params?: { start: number; length: number }) {
        return request({
            api: 'joyspace.pages.shareBy',
            path: `v1/pages/share-by-self`,
            params,
        });
    }
    @pwaAttribute(Method.get, joyspaceController, 'getSharesList', 'setSharesList', 'shares')
    shares(params?: { start: number; length: number }) {
        return request({
            api: 'joyspace.pages.share',
            path: `v1/pages/shares`,
            params,
        });
    }
    // 获取文件夹接口
    @pwaAttribute(Method.get, joyspaceController, 'getFolderList', 'setFolderList', 'getFileList')
    getFileList(id: string, params?: IGetFileParams) {
        return request({
            api: 'joyspace.folder.sub',
            path: `/v1/folders/${id}/children`,
            params,
        });
    }
    @pwaAttribute(Method.get, joyspaceController, 'getFolderList', 'setFolderList', 'getFileList')
    mySpaceCategories(id: string) {
        return request({
            api: 'joyspace.folder.sub',
            path: `/v1/folders/${id}/children`,
        });
    }
    @pwaAttribute(
        Method.get,
        joyspaceController,
        'getFolderList',
        'setFolderList',
        'getShareFileList'
    )
    getShareFileList(id: string) {
        return request({
            api: 'joyspace.folder.sub',
            path: `/v1/folders/${id}/children`,
        });
    }

    @pwaAttribute(Method.get, joyspaceController, 'getList', 'setList', 'space', 'concatList')
    space(params: IGetPagesParams, id: string) {
        return request<IGetPagesResult>({
            api: 'joyspace.spaces.pages.list',
            path: id ? `v1/spaces/${id}/pages` : `v1/spaces/pages`,
            params,
        });
    }

    @pwaAttribute(
        Method.get,
        joyspaceController,
        'getCollectionsList',
        'setCollectionsList',
        'collections'
    )
    collections(params?: { start: number; length: number }, targetType = 1) {
        return request({
            api: 'joyspace.collection.list',
            path: `v1/collections`,
            params: { target_type: targetType, ...params },
        });
    }
    @pwaAttribute(Method.get, joyspaceController, 'getTrashesList', 'setTrashesList', 'trashes')
    trashes(params: { start: number; length: number }) {
        const useParams = {
            ...params,
            plt: 'web',
        };
        return request({
            api: 'joyspace.trash.list',
            path: `v1/trashes`,
            params: useParams,
        });
    }
    @pwaAttribute(
        Method.get,
        joyspaceController,
        'getShortcutsList',
        'setShortcutsList',
        'shortcuts'
    )
    shortcuts(params: { start: number; length: number }) {
        return request({
            api: 'joyspace.shortcut.page',
            path: '/v1/shortcuts/page',
            method: 'GET',
            params,
        });
    }
    @pwaAttribute(Method.get, joyspaceController, 'getUserInfo', 'setUserInfo')
    getUserInfo() {
        return request<User>({
            api: 'joyspace.user.info',
            path: '/v1/user/info',
        });
    }
    @pwaAttribute(Method.delete, joyspaceController, 'removeRecent', 'recent')
    removeFromRecent(id: string) {
        return request({
            api: 'joyspace.pages.removeFromRecent',
            path: `/v1/pages/${id}/recent`,
            method: 'DELETE',
        });
    }
    @pwaAttribute(Method.delete, joyspaceController, 'remove')
    remove(spaceid: string, id: string) {
        return request({
            api: 'joyspace.spacePage.remove',
            path: `v1/spaces/${spaceid}/pages/${id}`,
            method: 'DELETE',
        });
    }
}

const pagesApi = new PagesApi();

export const recent = (params?: { start: number; length: number }) => pagesApi.recent(params);

export const myshare = (params?: { start: number; length: number }) => pagesApi.myshare(params);

export const shares = (params?: { start: number; length: number }) => pagesApi.shares(params);

export const getFileList = (id: string, params?: IGetFileParams) =>
    pagesApi.getFileList(id, params);

export const mySpaceCategories = (id: string) => pagesApi.mySpaceCategories(id);

export const space = (params: IGetPagesParams, id = '') => pagesApi.space(params, id);

export const collections = (params?: { start: number; length: number }, targetType?: number) =>
    pagesApi.collections(params, targetType);

export const trashes = (params: { start: number; length: number }) => pagesApi.trashes(params);

export const shortcuts = (start: number, length: number) => pagesApi.shortcuts({ start, length });

export const getUserInfoApi = () => pagesApi.getUserInfo();

export const _removeFromRecent = (id: string) => pagesApi.removeFromRecent(id);

export const remove = (spaceid: string, id: string) => pagesApi.remove(spaceid, id);

export const spaceCategories = (spaceId: string) =>
    request({
        api: 'joyspace.spaceCategory.list',
        path: `/v1/spaces/${spaceId}/categories`,
    });

export interface IGetFileParams {
    sort: string;
    order: string;
}
// 获取文件接口
// export const getFileList = (spaceId: string, params?: IGetFileParams) =>
// request({
//     api: 'joyspace.folder.sub',
//     path: `/v1/folders/${spaceId}/children`,
//     params,
// })
//     .then((data) => data)
//     .catch((err) => {
//         console.error(err);
//         throw err;
//     });
// 获取文件接口
// export const mySpaceCategories = (spaceId: string) =>
//     request({
//         api: 'joyspace.folder.sub',
//         path: `/v1/folders/${spaceId}/children`,
//     });

// 获取共享文件接口
export const getShareFileList = (spaceId: string) =>
    request({
        api: 'joyspace.folder.sub',
        path: `/v1/folders/${spaceId}/children`,
    });

export interface IGetPagesParams {
    categoryId?: string;
    length: number;
    sort: string;
    start: number;
    order: string;
}

export interface IGetPagesResult {
    pages: IPageBase[];
    total: number;
}

// export const space = (id: string, params: IGetPagesParams) => {
//     if (params.categoryId === '' || params.categoryId === id) {
//         delete params.categoryId;
//     }
//     return request<IGetPagesResult>({
//         api: 'joyspace.spaces.pages.list',
//         path: `v1/spaces/${id}/pages`,
//         params,
//     });
// };

// 获取共享文件列表接口
export const shareFileList = (id: string, params: IGetPagesParams) =>
    request<IGetPagesResult>({
        api: 'joyspace.spaces.pages.list',
        path: `v1/spaces/${id}/pages`,
        params,
    });

export const follow = (params: any) =>
    request({
        api: 'joyspace.follow.list',
        path: `v1/follow`,
        params: { ...params, target_type: 1 },
    });

// export const collections = (start = 0, length = 10, target_type = 1) =>
//     request({
//         api: 'joyspace.collection.list',
//         path: `v1/collections`,
//         params: { target_type, start, length },
//     });

// export const trashes = (params: { start: number; length: number }) => {
//     const useParams = {
//         ...params,
//         plt: 'web',
//     };
//     return request({
//         api: 'joyspace.trash.list',
//         path: `v1/trashes`,
//         params: useParams,
//     });
// };

// export const myshare = (params?: { start: number }) =>
//     request({
//         api: 'joyspace.pages.shareBy',
//         path: `v1/pages/share-by-self`,
//         params,
//     });

// export const shares = (params?: { start: number }) =>
//     request({
//         api: 'joyspace.pages.share',
//         path: `v1/pages/shares`,
//         params,
//     });

// 关注与动态
export const focus = (start: number) =>
    request<IGetPagesResult>({
        api: 'joyspace.pages.publishList',
        path: `v1/pages/publish`,
        params: { start },
    }).catch(() => ({ pages: [], total: 0 }));

export const zhuanbanSearch = (body: {
    t?: string;
    start?: number;
    length?: number;
    search?: string;
    // category?: string;
    space_ids?: string[];
    // mode?: number;
    space_category_ids?: string[];
}) =>
    request({
        // api: 'joyspace.pages.search',
        api: 'joyspace.search.global',
        path: `v1/search/global`,
        method: 'POST',
        body: {
            start: 0,
            length: 10,
            search: '',
            // t: 'page',
            space_ids: [],
            space_category_ids: [],
            ...body,
        },
    });

// 获取文件夹详情
export const getFolderDetail = (id: string) =>
    request({
        api: 'joyspace.folder.get',
        path: `/v1/folders/${id}`,
    });

// 删除最近
export const removeFromRecent = (id: string) => _removeFromRecent(id);
// request({
//     api: 'joyspace.pages.removeFromRecent',
//     path: `/v1/pages/${id}/recent`,
//     method: 'DELETE',
// });
// 删除我收到的和我发出的
export const removeFromHomePage = (id: string, type: number) =>
    request({
        api: 'joyspace.pages.removeFromHomePage',
        path: `/v1/pages/${id}/homePage`,
        params: { type },
        method: 'DELETE',
    });

//
export const onlineSyncPages = (params: any) =>
    request({
        api: 'joyspace.offLine.create',
        path: `/v1/offLine/create`,
        method: 'POST',
        body: params,
    });
