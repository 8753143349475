/**
 * 创建群组功能
 */
import React, { useCallback, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Employee, Group, Session, UID } from '@/types/chat';
import {
    employeeToUid,
    getInvitees,
    isEqualUid,
    isSpecialProjectSession,
} from '@/utils/chat/message';
import { canInviteMember } from '@/utils/chat/group';

import { openUserSelectionModal } from '@/utils/modals';
import { message } from 'antd';
import get from 'lodash/get';
import GroupRoster from '@jd/jdee.im.sdk/lib/es/model/Roster';
import {
    UserSelectionOptions,
    UserSelectionResult,
} from '@jd/focus-desktop-comps/lib/es/UserSelectionModal/index';
import { InputPlacement } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal/types';
import {
    checkInvitee,
    CheckInviteeResult,
    createCrossPlatformGroup,
    createExternalGroup,
    createGroupSession,
    inviteAddGroupApply,
    inviteUser,
    mapGroupRosters,
    mapSelectorResult,
    mapSessionMembers,
    mergeGroupRoster,
} from '@/baseComponents/Chat/GroupUserSelector/common';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import IconFont from '@/components/icon';
import Context from '@/context/ChatContext';
import { UserSelTab } from '@jd/focus-desktop-comps/lib/es/UserSelection/types';
import { findResourceIdInAuthorPermission } from '@/utils/envConfig';
import GlobalContext from '@/context/GlobalContext';
import './index.less';
import config, { ConfigEnum } from '@/config/config';

// const logger = log.getLogger('comp:UserSelector');

interface GroupCreateProps {
    type?: string;
}

type IProps = Readonly<GroupCreateProps>;

/**
 * 人员选择器 创建群组， 群添加人员
 */
export default function useGroupUserSelector({
    selectedSession,
    currentEmployee,
    groupRosters,
    sessionMembers,
    userData,
    callback,
    forwardMessages,
}: {
    selectedSession?: Session;
    currentEmployee: Employee;
    groupRosters?: Partial<GroupRoster>[];
    sessionMembers?: Partial<Employee>[];
    userData?: any;
    callback?: Function;
    forwardMessages?: Function;
}) {
    const history = useHistory();
    const { t } = useTranslation('chat');
    const { groupRosterIdentity } = React.useContext(Context);
    const isSpecialProject = selectedSession && isSpecialProjectSession(selectedSession);
    // 添加成员  入参
    const addMemberModalOptions = useMemo((): UserSelectionOptions => {
        const groupRosterListItems = mapGroupRosters(groupRosters || []);
        const tabs: (keyof typeof UserSelTab)[] = !config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
            ? ['recent', 'group', 'externalContacts']
            : ['recent', 'org', 'group', 'externalContacts'];
        // if (!isBjme()) {
        //     tabs.push('externalContacts');
        // }
        return {
            title: t('add_member'),
            resultUsers: groupRosterListItems, // 选中的用户数据 （已经在群里的成员）
            frozenIds: groupRosterListItems.map((item) => item.id),
            tabs,
            currentUser: employeeToUid(currentEmployee),
            contactsFilterFlag: isSpecialProject ? 'external' : 'all',
            canSelectAll: true,
        };

        // businessTab: {
        //     tabName: '群组成员',
        //         tabParam: {
        //         businessType: 'group',
        //             businessId: '1',
        //     },
        // },
        // canSelectDept: true,
        // simpleDeptInfoList: [
        //     {
        //         deptId: '0',
        //         deptName: '京东破产管理平台',
        //         deptOrder: 0,
        //     },
        //     {
        //         deptId: 'namxTmkr',
        //         deptName: '测试部',
        //         deptOrder: 1,
        //     },
        // ],
        // virtualDeptInfoList: [
        //     {
        //         topOrgId: 'ctTDntRMPGVTlMpMWQ5i3',
        //         topOrgName:
        //             '呜呜呜呜呜呜呜呜无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无无呃呃呃呃呃呃呃呃呃呃呃呃呃呃呃额鹅鹅鹅饿呃呃呃呃呃呃呃呃呃呃呃呃呃呃呃',
        //     },
        //     {
        //         topOrgId: 'zNCdi6wG6R8qek5tM01kE',
        //         topOrgName: '现金100',
        //     },
        //     {
        //         topOrgId: 'DrfCqffafW5qjCqCPA59y',
        //         topOrgName: '现金777',
        //     },
        // ],
    }, [groupRosters, t, currentEmployee, isSpecialProject]);

    // 创建群组 入参
    const createGroupModalOptions = useMemo((): UserSelectionOptions => {
        const sessionMemberListItems = mapSessionMembers(sessionMembers || []);
        const tabs: (keyof typeof UserSelTab)[] = !config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
            ? ['recent', 'group', 'externalContacts']
            : ['recent', 'org', 'group', 'externalContacts'];

        const [groupNameMaxLength, groupDescMaxLength] = [40, 500];

        // if (!isBjme()) {
        //     tabs.push('externalContacts');
        // }
        return {
            title: t('create_group'),
            modalWrapClass: 'create-group-modal',
            resultUsers: sessionMemberListItems,
            extraInput: {
                maxLength: groupNameMaxLength,
                placeholder: t('please-enter-group-name'),
                isTextArea: true,
            },
            despInput: {
                maxLength: groupDescMaxLength,
                placeholder: t('please-enter-group-intro'),
                isTextArea: true,
            },
            frozenIds: sessionMemberListItems.map((item: any) => item.id),
            tabs,
            currentUser: employeeToUid(currentEmployee),
            canSelectAll: true,
            inputPlacement: InputPlacement.bodyRightBottom,
            inputWrapClass: 'group-input-wrap',
            validate(data) {
                return new Promise((resolve, reject) => {
                    const invitees = getInvitees(data);
                    if (invitees.length < 3) {
                        message.info(t('group-limit-number'));
                        return false;
                    }

                    const groupName = get(data, 'data.extraString', '');
                    const groupDesc = get(data, 'data.despInputValue', '');
                    if (!groupName) {
                        message.info(t('required-group-name'));
                        return false;
                    }

                    if (groupName.length > groupNameMaxLength) {
                        message.info(
                            t('content over maxLength', {
                                name: '组名称',
                                maxLength: groupNameMaxLength,
                            })
                        );
                        return false;
                    }

                    if (groupDesc && groupDesc.length > groupDescMaxLength) {
                        message.info(
                            t('content over maxLength', {
                                name: '组介绍',
                                maxLength: groupDescMaxLength,
                            })
                        );
                        return false;
                    }

                    resolve();
                });
            },
        };
    }, [currentEmployee, sessionMembers, t]);

    const sendInviteApply = useCallback(
        async (invitees: UID[], close: () => void) => {
            if (!selectedSession) {
                return null;
            }
            const result = await inviteAddGroupApply(selectedSession.sessionId, invitees);
            if (result) {
                message.warn(t('apply.send_to_admin'));
                close?.();
            } else {
                message.error(t('add-failed'));
            }
        },
        [selectedSession, t]
    );

    const addMembers = useCallback(
        async (invitees: UID[], close: () => void) => {
            if (!selectedSession) {
                return null;
            }
            const result = await inviteUser(selectedSession.sessionId, invitees);
            if (result.success) {
                if (callback) {
                    callback();
                }
                message.success(t('added-successfully'));
                close?.();
            } else {
                // 如果返回failResult中的 code值为1103036，客户端进行notifyFlag字段的判断：
                //     notifyFlag为1，进行发送群主验证通知审核的逻辑，结束。
                //     notifyFlag为0，判断inviteMode字段：
                //        i:  inviteMode为1，提示仅群主和管理员能邀请进群，结束。
                //        ii: inviteMode为0，不做任何处理，结束。
                const { notifyFlag, inviteMode } = result.data || {};
                let code = result.data?.code;
                if (code === 1103036) {
                    if (notifyFlag === 1) {
                        await sendInviteApply(invitees, close);
                    } else {
                        if (inviteMode === 1) {
                            message.warn(t('apply.only_invite_join_group'));
                        }
                        close?.();
                    }
                } else {
                    message.error(t('add-failed'));
                    close?.();
                }
            }
        },
        [selectedSession, sendInviteApply, t, callback]
    );

    const inviteMembers = useCallback(
        async (invitees: UID[], close: () => void) => {
            if (!selectedSession) {
                return null;
            }
            if (canInviteMember(selectedSession.info as Group, groupRosterIdentity)) {
                // 直接邀请入群
                await addMembers(invitees, close);
            } else {
                // 发送邀请申请
                await sendInviteApply(invitees, close);
            }
        },
        [selectedSession, groupRosterIdentity, addMembers, sendInviteApply]
    );

    const createGroupEnd = useCallback(
        (session: Session | null, close) => {
            if (session?.sessionId) {
                close?.();
                message.success(t('created-successfully'));
                history.push(`/messages/g/${session?.sessionId}`);
            } else {
                message.error(t('creation-failed'));
            }
        },
        [history, t]
    );

    const openCreateCrossPlatformGroupModal = useCallback(
        (invitees: UID[], close: () => void) => {
            const props = {
                content: t('create_crossPlatform_group_tip'),
                cancelText: t('cancelText'),
                okText: t('determine'),
            };
            const onOk = async () => {
                //  合并当前群成员 和 邀请的成员
                const inviteesData = mergeGroupRoster(invitees, groupRosters || []);
                let session = await createCrossPlatformGroup(
                    inviteesData,
                    selectedSession?.info.name || ''
                );
                createGroupEnd(session, close);
            };
            Prompt({
                ...props,
                onOk,
                icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            });
        },
        [t, groupRosters, selectedSession?.info.name, createGroupEnd]
    );

    const openCreateExternalGroupModal = useCallback(
        (invitees: UID[], close: () => void) => {
            const props = {
                content: t('create_external_group_tip'),
                cancelText: t('cancelText'),
                okText: t('determine'),
            };

            const onOk = async () => {
                //  合并当前群成员 和 邀请的成员
                const inviteesData = mergeGroupRoster(invitees, groupRosters || []);
                let session = await createExternalGroup(
                    inviteesData,
                    selectedSession?.info.name || ''
                );
                createGroupEnd(session, close);
            };
            Prompt({
                ...props,
                onOk,
                icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            });
        },
        [t, groupRosters, selectedSession?.info.name, createGroupEnd]
    );

    const addMemberModalCallback = useCallback(
        async (data: UserSelectionResult, close: () => void) => {
            if (!selectedSession) {
                return null;
            }
            // if (forwardMessages) {
            //     forwardMessages();
            // }
            const invitees = mapSelectorResult(data);
            if (invitees.length === 0) {
                close();
                return false;
            }
            const result = checkInvitee(invitees, currentEmployee, selectedSession);
            switch (result) {
                case CheckInviteeResult.CrossPlatform:
                    // 提示是否创建跨平台的群组
                    openCreateCrossPlatformGroupModal(invitees, close);
                    break;
                case CheckInviteeResult.External:
                    openCreateExternalGroupModal(invitees, close);
                    break;
                case CheckInviteeResult.Normal:
                    // 走正常邀请流程
                    await inviteMembers(invitees, close);
                    break;
            }
        },
        [
            selectedSession,
            currentEmployee,
            openCreateCrossPlatformGroupModal,
            openCreateExternalGroupModal,
            inviteMembers,
        ]
    );

    let lockCreateGroup = false;
    // 创建群组 回调
    const createGroupModalCallBack = useCallback(
        async (data, close) => {
            const invitees = getInvitees(data);
            if (invitees.length === 0) {
                close?.();
                return false;
            }
            if (invitees.length < 3 && isEqualUid(employeeToUid(currentEmployee), invitees[0])) {
                message.error('必须选择3个人及以上才能创建组');
                close?.();
                return false;
            }
            const groupName = get(data, 'data.extraString', '');
            const groupDesp = get(data, 'data.despInputValue', '');
            const result = checkInvitee(invitees, currentEmployee);

            // 加锁
            if (lockCreateGroup) {
                return false;
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            lockCreateGroup = true;
            const stt = setTimeout(() => {
                clearTimeout(stt);
                lockCreateGroup = false;
            }, 1500);

            let session = null;
            if (result === CheckInviteeResult.Normal) {
                session = await createGroupSession(invitees, groupName, groupDesp);
            } else if (result === CheckInviteeResult.External) {
                session = await createExternalGroup(invitees, groupName);
            } else if (result === CheckInviteeResult.CrossPlatform) {
                session = await createCrossPlatformGroup(invitees, groupName);
            }

            createGroupEnd(session, close);
        },
        [currentEmployee, createGroupEnd]
    );

    const openAddMemberModal = useCallback(() => {
        openUserSelectionModal(addMemberModalOptions, addMemberModalCallback, userData);
    }, [addMemberModalOptions, addMemberModalCallback, userData]); // eslint-disable-line

    const openCreateGroupModal = useCallback(() => {
        openUserSelectionModal(createGroupModalOptions, createGroupModalCallBack, userData);
    }, [createGroupModalOptions, createGroupModalCallBack, userData]); // eslint-disable-line

    return () => {
        if (!selectedSession) {
            openCreateGroupModal();
        } else if (selectedSession.isGroup) {
            openAddMemberModal();
        } else if (selectedSession.isSingle && !selectedSession.isNotice) {
            openCreateGroupModal();
        }
    };
}
