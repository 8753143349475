import content from 'solarlunar';

interface UserBase {
    account: string;
    ddAppId: string;
    headImg: string;
    realName: string;
}

export interface Team extends UserBase {
    mobile: string;
    teamAvatarUrl: null;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
}

export interface User extends UserBase {
    nickname: string;
    selectedTeamId: string;
    userId: string;
    team: Team;
    teamUserInfo: Team;
    teamUserInfoList: Team[];
}

export interface Project {
    iconColor: string;
    icon: string;
    id: string;
    title?: string;
    description: string;
    type: number;
    // announcementText: string;
    announcementLatest?: {
        content: string;
    };
    // 废弃属性， 使用 user
    userInfo?: {
        userRole?: number;
        app?: string;
        teamId?: string;
        userId?: string;
    };
    ownerInfo?: {
        app: string;
        headImg: string;
        teamId: string;
        userId: string;
        userName: string;
    };
    progress: number;
    progressDesc: string;
    user: IProjectUserInfo;
    joyspaceId?: string;
    calendarId: string;
    defaultGroupId?: string;
    groupIds?: string[];
    archive: boolean; // 是否归档
    progressName?: string;
}
export interface IprojectListType {
    // [x: string]: any;
    projectId: string;
    name: string;
    icon: string;
    iconColor: string;
    id: string; // todo
    type: projectType;
    progressName: string;
    progressPercent: number;
    progress: number;
    description: string;
    progressDesc: string;
    user: IProjectUserInfo;
    calendarId: string;
    joyspaceId: string;
    userInfo?: {
        userRole: number;
    };
    archive?: boolean;
}

export interface Comment {
    id: string;
    userId: string;
    name: string;
    content: string;
    children: Comment[];
    create_at: Date;
    avatar: string;
    parentId: string;
    parent: Comment;
    like: boolean;
    likeTotal: string;
    type: number;
    reply?: {
        commentId: string;
        name: string;
    };
}

export enum permissions {
    READ = 1,
    UPDATE = 2,
    DELETE = 4,
    ADD = 8,
}

export enum ProjectRole {
    CREATE = 1, // 创建人
    OWNER = 2, // 负责人
    ADMIN = 3, // 管理者
    MEMBER = 4, // 项目成员
    FOCUS = 5, // 关注人
}

export interface IMember {
    name: string;
    avatar: string;
    userId: string;
    teamId: string;
    app: string;
    role?: number;
    duty?: string;
}
export interface IGroupChatItem {
    name: string;
    gid: string;
    avatar: string;
}

export interface IProjectUserInfo {
    app: string;
    duty: string;
    permissiones: number;
    teamId: string;
    trend: 15;
    userId: string;
    name: string;
    role: ProjectRole;
}
export enum ContactsFilterFlag {
    all = 'all',
    external = 'external',
    crossPlatform = 'crossPlatform',
}

export enum projectType {
    'common' = 1,
    'imporant' = 2,
}

export interface IprojectOwnerInfo {
    app: string;
    headImg: string;
    teamId: string;
    userId: string;
    userName: string;
    duty?: string;
}

enum showTypes {
    'hidden' = 1,
    'show' = 2,
}
export interface announceListType {
    content: string;
    gmtModified: string;
    announcementId: string;
    updateUserName: string;
    creator?: boolean | null;
    pubTime: number;
    senderUserName: string;
    senderUserAvatar: string;
    attachmentUrl: string[];
    showType: showTypes;
    ackSize: number;
    userNum: number;
}

export enum IprojectStatus {
    'NORMAL' = 'NORMAL',
    'PAUSE' = 'PAUSE',
    'POSTPONE' = 'POSTPONE',
    'RISK' = 'RISK',
    'ARCHIVE' = 'ARCHIVE',
}
