import ScrollView from '@/baseComponents/ScrollView';
import React from 'react';
import NavItem, { NavItemData } from './NavItem';

interface NavTabProps {
    activeKey: string;
    onItemClick: (item: NavItemData) => void;
    onDoubleClick?: (item: NavItemData) => void;
    navItems: NavItemData[];
}

export default function NavTab({ activeKey, onItemClick, onDoubleClick, navItems }: NavTabProps) {
    return (
        <div className="focus-nav no-draggable" style={{ margin: '5px 0' }}>
            <ScrollView className="scroll-view view-container">
                {navItems.map((item, index) => (
                    <NavItem
                        key={index}
                        navName={item.navName}
                        active={activeKey.startsWith(item.path)}
                        {...item}
                        onClick={() => {
                            onItemClick(item);
                        }}
                        onDoubleClick={() => {
                            onDoubleClick?.(item);
                        }}
                    />
                ))}
            </ScrollView>
        </div>
    );
}
