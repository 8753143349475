import { miniEOpenInstance } from '@/server/miniEOpen';

/**
 * open userId转换
 * @param opt
 * @returns
 */
export async function changeOpenUserid(opt: any) {
    opt.url = `exchange/v1/multi_change_userid_by_openuserid`;
    try {
        const data = await miniEOpenInstance.request(opt);
        return [data, null];
    } catch (e) {
        console.log(`changeOpenUserid err: `, e);
        return [null, e.message?.toString() || JSON.stringify({ e })];
    }
}
