import React from 'react';

export default function GroupTitle({ title }: { title: string }) {
    return (
        <h4
            className="color-gray"
            style={{
                fontSize: 14,
                fontWeight: 'normal',
                margin: 0,
                padding: '0 24px',
                lineHeight: '40px',
                position: 'relative',
            }}
        >
            {title}
            <span
                className="split-line-bg"
                style={{
                    position: 'absolute',
                    height: 1,
                    bottom: 0,
                    right: 0,
                    left: 24,
                    backgroundColor: '#e8ebec',
                }}
            />
        </h4>
    );
}
