import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import './index.less';
import { message, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FocusSDK } from '@/utils';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import AudioMp3 from '@/assets/toy_mono.mp3';
import GlobalContext from '@/context/GlobalContext';
import { ConferenceMember } from '../types';
import { Avatar } from '@/baseComponents/Avatar';
import { getUserSimple } from '@/api/meeting';
import { changeEgovUrl } from '@/utils/tools';
import { getUserEbookDetail } from '@/api/ebook';

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export default function VoipReceModal() {
    const [initData, setInitData] = React.useState<{
        fromUser: any;
        isSingle: boolean;
    } | null>(null);
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo = {} as any, service } = useContext(GlobalContext);
    const { teamUserInfo = {}, selectedTeamId, userId } = authInfo;
    const { ddAppId, teamId } = teamUserInfo;
    const [t_common] = useTranslation('common');
    const audioRef = useRef<any>(null);
    const timeRef = useRef<any>(null);
    const [t] = useTranslation('common');
    useEffect(() => {
        if (initData) {
            return;
        }
        FocusSDK.once(
            'voip.setInitReceData',
            (data: { from: ConferenceMember; to: ConferenceMember; isSingle: boolean }) => {
                const { from, to, isSingle } = data;
                (async () => {
                    try {
                        const fromUser: any = {
                            appId: from.member_app_,
                            userId: from.member_id_,
                            teamId: from.team_id_,
                            avatar: '',
                        };
                        const profile = await service.authService.getUserProfile({
                            userId: from.member_id_,
                            teamId: from.team_id_,
                            appId: from.member_app_,
                        });
                        fromUser.avatar = profile.fields.filter(
                            // eslint-disable-next-line max-nested-callbacks
                            (field: any) => field.key === 'avatar'
                        )[0]?.val;

                        const [result, err] = await getUserSimple({
                            appId: from.member_app_,
                            userId: from.member_id_,
                            teamId: from.team_id_,
                        });
                        if (!err && result) {
                            fromUser.positionTitle = result?.titleName || '';
                            fromUser.higherDepartment = result?.superiorDeptName || '';
                            fromUser.currentDepartment = result?.deptName || '';
                            fromUser.name = result?.userName || '';
                        }
                        setInitData({ fromUser, isSingle });
                    } catch (error) {
                        // 如果ebook调用报错，则手动包装一下
                        setInitData({
                            fromUser: {
                                app: from.member_app_,
                                userId: from.member_id_,
                                teamId: from.team_id_,
                                name: '???',
                                avatar: '',
                            },
                            isSingle,
                        });
                    }
                })();
            }
        );
        FocusSDK.sendIpcMessage('voip.getInitReceData');
    }, [ddAppId, initData, service.authService, teamId, userId]);

    useEffect(() => {
        /**
         * 处理超过一分钟未响应的情况，主要是群会议
         */
        function handleTimePass() {
            const timer = timeRef.current;
            if (timer) {
                clearTimeout(timer);
            }
            timeRef.current = setTimeout(() => {
                setTimeout(() => {
                    FocusSDK.sendIpcMessage('voip:rece timeout');
                }, 50);
            }, 1000 * 60);
        }
        if (!initData) {
            return;
        }
        handleTimePass();
    }, [initData]);

    if (!initData) {
        return null;
    }
    const { fromUser, isSingle } = initData;

    function stopAudio() {
        const au = audioRef.current;
        if (au) {
            au.muted = true;
        }
    }

    function handleReject() {
        setTimeout(() => {
            FocusSDK.sendIpcMessage('voip:reject');
        }, 50);
    }

    function handleReceAudio() {
        if (connect) {
            return;
        }
        stopAudio();
        onJoinMeeting();
    }

    function onJoinMeeting() {
        setConnect(true);
        FocusSDK.sendIpcMessage('voip:receive');
    }

    const { avatar, name, positionTitle, higherDepartment, currentDepartment } = fromUser;
    return (
        <div className="receive-voip-browser">
            <div className="voip-header">
                <div className="voip-header-container">
                    {isSingle && (
                        <div className="avatar">
                            <Avatar
                                className="voip-header-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name || ''}
                                styleObj={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    lineHeight: 50,
                                    color: 'white',
                                    marginRight: 7,
                                }}
                            />
                        </div>
                    )}
                    <div className="voip-header-content">
                        <div className="title">
                            <span className="title-name">{name}</span>
                            {positionTitle && (
                                <span className="title-position">{positionTitle}</span>
                            )}
                        </div>
                        <div className="dept-info">
                            <span>
                                {`${higherDepartment ? higherDepartment + '-' : ''}${
                                    currentDepartment || ''
                                }`}
                            </span>
                        </div>
                        <div className="desc">
                            {t('joinMeeting.Invite you to an audio meeting')}
                        </div>
                    </div>
                </div>
            </div>
            {connect ? (
                <div className="voip-connect-loading">
                    <Spin indicator={antIcon} />
                </div>
            ) : (
                <div className="voip-action">
                    <div className="voip-action-reject">
                        <div className="action-icon" onClick={handleReject}>
                            <IconFont type="iconapp_btn_joymerting_hangup" />
                        </div>
                        <div className="label">{t('joinMeeting.reject')}</div>
                    </div>

                    <div className="voip-action-audio">
                        <div className="action-icon" onClick={handleReceAudio}>
                            <IconFont type="iconapp_btn_joymerting_answer" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-audio')}</div>
                    </div>
                </div>
            )}
            <audio src={changeEgovUrl(AudioMp3)} loop autoPlay ref={audioRef} />
        </div>
    );
}
