/**
 * 云空间上传文件进度列表组件
 */
import React, {
    ReactChild,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
    useImperativeHandle,
    forwardRef,
} from 'react';
import Icon from '@/components/icon';
import { message } from 'antd';
import './index.less';
import classNames from 'classnames';
import { TaskState } from './types';
import { sizeToStr } from './utils';
import { UploadControl, UploadControlCenterInstance } from './UploadControl';
import UploadItem from './UploadItem';
import { useTranslation } from '@/components/Joyspace/context';
import okPng from './imgs/ok.png';
import cancelPng from './imgs/cancel.png';
import failPng from './imgs/fail.png';
import Prompt from '@jd/focus-desktop-comps/lib/es/PromptModal';
import { useLocation } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';
function UploadBox(
    props: {
        headerTitle?: React.ReactNode; // 定制化header文案
        toJoysSapce?: boolean; // 是否上传到文档
        uploadControlName: string; // 上传任务模块
        onItemClick?: Function; // 点击上传面板行响应事件
        className?: string;
        retryLimitCallBackFunc?: Function; // retry时数量控制回调
        uploadFailCallBackFunc?: Function; // 上传失败回调
        uploadSuccessCallBackFunc?: Function; // 上传成功回调
    },
    ref: any
) {
    const {
        toJoysSapce = false,
        uploadControlName,
        headerTitle = null,
        onItemClick,
        className,
    } = props;
    const lang = useTranslation();
    const [uploadControlInstance, setUploadControlInstance] = useState<UploadControl | null>(null);
    const [fileIds, setFileIds] = useState<string[]>([]);
    const [panelShow, setPanelShow] = useState(false);
    const visibleRef = useRef(false);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState<TaskState>(TaskState.PENDING);
    const [currentCount, setCurrentCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    const location = useLocation();
    const uploadSizeRef = useRef(0);
    const preUploadSizeRef = useRef(0);
    const noFreshTimesRef = useRef(0);
    const contentActiveClass = classNames({
        'ant-collapse-content': true,
        'ant-collapse-content-active': panelShow,
        'ant-collapse-content-inactive': !panelShow,
    });

    const collapseHeaderClass = classNames({
        'ant-collapse-header': true,
        'ant-collapse-header-progress': true,
        // 'border-bottom-less': panelShow,
    });
    const repeatIconClass = classNames({
        'repeat-icon': true,
        rotate: TaskState.UPLOADING === status || TaskState.PENDING === status,
    });

    const upDownOperate = () => {
        setPanelShow(!panelShow);
    };

    // 去掉上传速度功能
    // useEffect(() => {
    //     // 每0.5秒更新一次，如果没有请求返回使用上次返回的结果取一个随机数，如果五秒都没有返回就返回0；
    //     let timer = setInterval(() => {
    //         if (uploadSizeRef.current > 0) {
    //             setUploadSpeed(uploadSizeRef.current);
    //             preUploadSizeRef.current = uploadSizeRef.current;
    //             uploadSizeRef.current = 0;
    //             noFreshTimesRef.current = 0;
    //         } else {
    //             noFreshTimesRef.current++;
    //             if (uploadControlInstance.getStateInfo() !== TaskState.UPLOADING) {
    //                 preUploadSizeRef.current = 0;
    //                 setUploadSpeed(0);
    //                 return;
    //             }
    //             if (noFreshTimesRef.current > 10) {
    //                 setUploadSpeed(uploadSizeRef.current);
    //             } else {
    //                 // console.log('preUploadSizeRef.current', preUploadSizeRef.current);
    //                 setUploadSpeed(
    //                     Math.floor((0.5 + Math.random() * 0.5) * preUploadSizeRef.current)
    //                 );
    //             }
    //         }
    //     }, 500);
    //     return () => {
    //         timer && clearInterval(timer);
    //     };
    // }, []);
    const headerText: any = {
        [TaskState.UPLOADING]: lang('myspace.upload_uploading'),
        [TaskState.PENDING]: lang('myspace.upload_uploading'),
        [TaskState.COMPLETED]: lang('myspace.upload_success'),
        [TaskState.FAILED]: lang('myspace.upload_failed'),
        [TaskState.CANCEL]: lang('myspace.upload_cancelled'),
    };
    const headerIcon: any = {
        [TaskState.UPLOADING]: 'iconic_repeat',
        [TaskState.PENDING]: 'iconic_repeat',
        [TaskState.COMPLETED]: 'iconic_repeat',
        [TaskState.FAILED]: 'iconic_repeat',
        [TaskState.CANCEL]: 'iconic_repeat',
    };

    const onStateChange = useCallback(
        (_fileId: string, _state: TaskState, _progressSize: number) => {
            if (!uploadControlInstance) {
                return;
            }
            const currentCount = uploadControlInstance.getCompletedCount();
            setCurrentCount(currentCount);
            const state = uploadControlInstance.getStateInfo();
            setStatus(state);
            if (_progressSize > 0) {
                uploadSizeRef.current += uploadSizeRef.current + _progressSize;
            }
        },
        [uploadControlInstance]
    );
    const setVisibleFunc = useCallback(
        (isVisible: boolean) => {
            visibleRef.current = isVisible;
            setVisible(isVisible);
        },
        [setVisible]
    );
    const uploadControlEventListener = useCallback(
        ({ fileIds }: { fileIds: string[] }) => {
            if (!visibleRef.current && location.pathname.indexOf('joyspace/home') > -1) {
                message.info(lang('myspace.upload_to_my_space'));
            }
            setFileIds(fileIds);
            setPanelShow(true);
            setVisibleFunc(true);
            uploadControlInstance && setStatus(uploadControlInstance.getStateInfo());
            setTotalCount(fileIds.length);
        },

        [uploadControlInstance, setVisibleFunc, setFileIds, setPanelShow, lang, location.pathname]
    );

    useMemo(() => {
        const isJoyspace = location.pathname.indexOf('/joyspace') > -1;
        if (uploadControlInstance?.checkIsToJoySpace()) {
            if (fileIds.length > 0 && isJoyspace && !visibleRef.current) {
                setVisibleFunc(true);
            }
            if (visibleRef.current && !isJoyspace) {
                setVisibleFunc(false);
            }
        }
    }, [fileIds, location, uploadControlInstance, setVisibleFunc]);

    useEffect(() => {
        if (!uploadControlInstance) {
            return;
        }
        uploadControlInstance?.on('upload-control-event', uploadControlEventListener);
        return () => {
            uploadControlInstance?.off('upload-control-event', uploadControlEventListener);
        };
    }, [uploadControlEventListener, uploadControlInstance]);

    useEffect(() => {
        setUploadControlInstance(
            UploadControlCenterInstance.register(uploadControlName, toJoysSapce)
        );
        return () => {
            UploadControlCenterInstance.clear(uploadControlName);
        };
    }, [uploadControlName, toJoysSapce]);
    const headerIconNode = useMemo(() => {
        let node = <Icon className={repeatIconClass} type={headerIcon[status]} />;
        switch (status) {
            case TaskState.COMPLETED:
                node = <img src={okPng} />;
                break;
            case TaskState.FAILED:
                node = <img src={failPng} />;
                break;
            case TaskState.CANCEL:
                node = <img src={cancelPng} />;
                break;
            default:
                break;
        }
        return node;
    }, [status, headerIcon, repeatIconClass]);
    const collapseHeaderPart = useMemo(() => {
        if (!status) {
            return null;
        }
        if (!visible) {
            return null;
        }
        let countNode = null;
        if (!(status === TaskState.COMPLETED || status === TaskState.CANCEL)) {
            countNode = (
                <span>
                    {currentCount}/{totalCount}
                </span>
            );
        }
        return (
            <div className="panel-header-space">
                {headerIconNode}
                <span className="panel-header panel-header-progress">
                    {headerTitle}
                    {headerText[status]}
                    {countNode}
                </span>
            </div>
        );
    }, [status, visible, headerText, currentCount, totalCount, headerIconNode, headerTitle]);

    const stopUpload = useCallback(() => {
        setVisibleFunc(false);
        setFileIds([]); // 关闭上传窗口时清空FileIds
        uploadControlInstance?.clearAllUpload();
    }, [uploadControlInstance, setVisibleFunc]);

    const closeCollapse = useCallback(
        (force) => {
            // eslint-disable-next-line no-debugger
            // debugger;
            // 判断上传状态
            // 状态为上传完成 上传失败，直接关闭
            // 状态为已取消提示：文件尚未上传完成，是否取消上传文件？
            // 状态为上传中时提示：文件正在上传中，确认取消所有文件上传吗？
            // setVisible(false);
            // uploadControlInstance.clearAllUpload();
            const state = uploadControlInstance?.getStateInfo() || TaskState.INITED;
            if (state === TaskState.COMPLETED || state === TaskState.FAILED || force) {
                stopUpload();
            } else {
                // 状态为已取消提示：文件尚未上传完成，是否取消上传文件？
                Prompt({
                    title: lang('myspace.upload_cancel'),
                    icon: <Icon type="iconic_failure" style={{ color: '#F96137' }} />,
                    onOk: stopUpload,
                    content: lang('myspace.upload_progress_tip'),
                    okText: lang('myspace.Confirm_Text'),
                    cancelText: lang('myspace.Cancel_Text'),
                });
            }
        },
        [lang, stopUpload, uploadControlInstance]
    );
    useImperativeHandle(
        ref,
        () => {
            return {
                getUploadInstance: () => {
                    return uploadControlInstance;
                },
                close: () => {
                    closeCollapse(1);
                },
            };
        },
        [closeCollapse, uploadControlInstance]
    );
    // 清空虚拟的上传速度
    const onCancelAll = useCallback(() => {
        uploadControlInstance?.cancelAllUpload();
    }, [uploadControlInstance]);

    const collapseOperatePart = useMemo(() => {
        return (
            <div className="panel-operate">
                {!panelShow ? (
                    <Icon
                        className="panel-operate-icon size-reset"
                        onClick={upDownOperate}
                        type="iconicon_up"
                    />
                ) : (
                    <Icon
                        className="panel-operate-icon size-reset"
                        onClick={upDownOperate}
                        type="iconicon_down"
                    />
                )}
                <Icon
                    className="panel-operate-icon size-reset"
                    onClick={() => {
                        closeCollapse(0);
                    }}
                    type="iconjs_ic_closed"
                />
            </div>
        );
    }, [panelShow]); //eslint-disable-line

    if (!visible) {
        return null;
    }

    if (!uploadControlInstance) {
        return null;
    }

    return (
        <div className={`${className} global-upload-controller`}>
            <div className="upload-progress-list">
                <div className="ant-collapse ant-collapse-borderless">
                    <div className="ant-collapse-item ant-collapse-item-disabled ant-collapse-no-arrow ant-collapse-item-progress">
                        <div className={collapseHeaderClass}>
                            {collapseHeaderPart}
                            <div className="ant-collapse-extra ant-collapse-extra-progress">
                                {collapseOperatePart}
                            </div>
                        </div>
                        {panelShow && (
                            <div className="file-upload-sum">
                                <div className="file-upload-speed">
                                    {/* 速度：
                                <span>{sizeToStr(uploadSpeed * 2)}/s</span> */}
                                    文件列表
                                </div>
                                {status === TaskState.UPLOADING && (
                                    <a className="file-canel-all" onClick={onCancelAll}>
                                        {lang('myspace.cancelAllText')}
                                    </a>
                                )}
                            </div>
                        )}
                        <div id="file-progress-container" className={contentActiveClass}>
                            {fileIds.map((id) => (
                                <UploadItem
                                    t={lang}
                                    onItemClick={onItemClick}
                                    uploadControlInstance={uploadControlInstance}
                                    fileId={id}
                                    status={status}
                                    key={id}
                                    onStateChange={onStateChange}
                                    uploadSuccessCallBackFunc={props.uploadSuccessCallBackFunc}
                                    uploadFailCallBackFunc={props.uploadFailCallBackFunc}
                                    retryLimitCallBackFunc={props.retryLimitCallBackFunc}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(UploadBox);
