import { request } from '@/components/Joyspace/api/request';

export const add = (id: string, type: number) => {
    return request({
        api: 'joyspace.collection.create',
        path: '/v1/collections',
        method: 'POST',
        body: {
            target_id: id,
            target_type: type,
        },
    }).then(() => true);
};

export const remove = (id: string, type: number) => {
    return request({
        api: 'joyspace.collection.remove',
        path: `/v1/collections/${id}/${type}`,
        method: 'DELETE',
    })
        .then(() => true)
        .catch(() => false);
};

export const collectionRemove = (limit: number, start: number, target_type: number) => {
    return request({
        api: 'joyspace.collection.list',
        path: '/v1/collections',
        method: 'DELETE',
        params: {
            limit,
            start,
            target_type,
        },
    });
};
