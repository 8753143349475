const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
export default {
    colors: [
        '#31C4CC',
        '#73D987',
        '#ABD846',
        '#8957D7',
        '#617CEF',
        '#5EA7F8',
        '#52CEFB',
        '#EB5B56',
        '#E464A8',
        '#F17945',
        '#FBB056',
        '#F6C859',
        '#EA98AF',
        '#607894',
    ],

    icons: [
        {
            id: 1,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_team_1.svg?Expires=3753091699&AccessKey=NkVv4lcef9RjxEhG&Signature=IbYxyZZzI43DO67JYYDmiuiFAZA%3D',
            preview:
                'http://storage.360buyimg.com/me-official-website/preview_ic_1.svg?Expires=3753016001&AccessKey=NkVv4lcef9RjxEhG&Signature=NgLWAX5XYGWE72ZJoTuS9TnXvJw%3D',
        },
        {
            id: 2,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_task_2.svg?Expires=3753091713&AccessKey=NkVv4lcef9RjxEhG&Signature=gU2Fc%2BoWpEmvJVWo6NVqS3zdijI%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_2.svg?Expires=3753016115&AccessKey=NkVv4lcef9RjxEhG&Signature=H7VrprgUNLcCWoFXTewlmoNC9as%3D',
        },
        {
            id: 3,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_project_3.svg?Expires=3753091731&AccessKey=NkVv4lcef9RjxEhG&Signature=w5TLKDcvdhUJOm%2FogoDUZLr4ShE%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_3.svg?Expires=3753016131&AccessKey=NkVv4lcef9RjxEhG&Signature=B%2FZTSF3RySkXD9%2FpwheiFocfvLc%3D',
        },
        {
            id: 4,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_exploit_4.svg?Expires=3753091744&AccessKey=NkVv4lcef9RjxEhG&Signature=nrmic6cgwdvsWKHzOn7hmNFh6a8%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_4.svg?Expires=3753016147&AccessKey=NkVv4lcef9RjxEhG&Signature=8hxuJN0ZBFShxH9H5PGW347Ooy0%3D',
        },
        {
            id: 5,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_solve_5.svg?Expires=3753091756&AccessKey=NkVv4lcef9RjxEhG&Signature=TsMH1GmFnwVHBwigSdBjtYe1qUk%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_5.svg?Expires=3753016280&AccessKey=NkVv4lcef9RjxEhG&Signature=uPskwASK2cGFsvWWUUAl%2BAp4UXU%3D',
        },
        {
            id: 6,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_data_6.svg?Expires=3753091770&AccessKey=NkVv4lcef9RjxEhG&Signature=79lkNknVDVhdsa76f5jCPmOsL98%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_6.svg?Expires=3753016294&AccessKey=NkVv4lcef9RjxEhG&Signature=%2BoX5v%2FfiMELC4iWdLep74xbOJNY%3D',
        },
        {
            id: 7,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_battle_7.svg?Expires=3753091783&AccessKey=NkVv4lcef9RjxEhG&Signature=s81DILrpSNjxEuz4LfpoWv5gaAk%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_7.svg?Expires=3753016311&AccessKey=NkVv4lcef9RjxEhG&Signature=CWiP7UE3B3sXI9YSNL%2BMJq0pP4A%3D',
        },
        {
            id: 8,
            font:
                'http://storage.360buyimg.com/me-official-website/pic/ic_cover_attention-8.svg?Expires=3753091796&AccessKey=NkVv4lcef9RjxEhG&Signature=CIuTKxM5c7%2FNWOVnQ46yYCjKfiA%3D',
            preview:
                'http://storage.jd.local/me-official-website/preview_ic_8.svg?Expires=3753016324&AccessKey=NkVv4lcef9RjxEhG&Signature=Q44GnNAonIyOZWXP3Ac1fz3XFRw%3D',
        },
    ],
    avatarUrls: [
        {
            id: 1,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_team_1_new.png`,
        },
        {
            id: 2,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_task_2.png`,
        },
        {
            id: 3,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_project_3.png`,
        },
        {
            id: 4,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_exploit_4.png`,
        },
        {
            id: 5,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_solve_5.png`,
        },
        {
            id: 6,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_data_6.png`,
        },
        {
            id: 7,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_battle_7.png`,
        },
        {
            id: 8,
            url: `${REACT_APP_OSS_BASE_URL}/statics/images/projects/project_avatar/ic_cover_attention-8.png`,
        },
    ],
};
