import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { FocusSDK as focusSDK } from '@/utils';
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export enum NetType {
    PRIVATE_NET = 'privateNet',
    PUBLIC_NET = 'publicNet',
    MIX_NET = 'mixNet',
    DEFAULT_NET = 'defaultNet',
}

export interface ChangeNetModalProps {
    isPrivateNet: boolean;
    newPublicResult: boolean;
    newPrivateResult: boolean;
}
export default function ChangeNetModal(props: ChangeNetModalProps) {
    const { isPrivateNet, newPublicResult, newPrivateResult } = props;
    const [visible, setVisible] = useState(false);
    const [netCurrentOff, setNetCurrentOff] = useState(false);
    const [netNew, setNetNew] = useState<NetType>();
    const { t } = useTranslation('common');

    useEffect(() => {
        console.log('isPrivateNet', isPrivateNet);
        if (isPrivateNet) {
            // 政务外网变成互联网
            if (newPublicResult && !newPrivateResult) {
                setNetCurrentOff(true);
                setNetNew(NetType.PUBLIC_NET);
                console.log('###新网络为互联网且无法访问政务外网###');
                setVisible(true);
            }
        } else {
            // 互联网变成政务外网
            if (!newPublicResult && newPrivateResult) {
                setNetCurrentOff(true);
                setNetNew(NetType.PRIVATE_NET);
                console.log('###新网络为政务外网###');
                setVisible(true);
            }
        }
    }, [isPrivateNet, newPublicResult, newPrivateResult]);
    const netTypeDesc = netNew === NetType.PRIVATE_NET ? '政务外网' : '互联网';
    const info = `检测到网络已切换到${netTypeDesc}，确认是否将京办切换到此网络，如果切换将会重启京办。`;

    const confirm = () => {
        setVisible(false);
        focusSDK.relaunchApp();
    };

    const cancel = () => {
        setVisible(false);
    };

    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {netCurrentOff ? (
                        <CloseCircleOutlined
                            style={{ fontSize: '18px', color: 'red', marginRight: '10px' }}
                        />
                    ) : (
                        <ExclamationCircleOutlined
                            style={{
                                fontSize: '18px',
                                color: 'rgb(24, 144, 255)',
                                marginRight: '10px',
                            }}
                        />
                    )}
                    <div>{netCurrentOff ? '当前网络已断开' : '网络情况提示'}</div>
                </div>
            }
            width={450}
            closable={false}
            visible={visible}
            maskClosable={false}
            keyboard={false}
            okText={t('button.ok')}
            cancelText={t('button.cancel')}
            footer={
                <>
                    <Button onClick={cancel}>{t('button.cancel')}</Button>
                    <Button type="primary" onClick={confirm}>
                        {t('button.ok')}
                    </Button>
                </>
            }
            zIndex={2000}
        >
            <p>{info}</p>
        </Modal>
    );
}
