/**
 * 图片消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MessageSendType } from '@/types/chat/enum';
import { ChatMessage, FileStatus, ImageChatMessage, SessionType } from '@/types/chat';
import './index.less';
import ImagePreview from '@/components/chat/message/Preview';
import UploadControl from '@/components/chat/message/MsgControl/UploadControl';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import LoadingOutlined from '@/components/Joyspace/components/Loading';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { MessageStatus } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';

interface ImageProps {
    message: ImageChatMessage;
    sendType: MessageSendType;
    sessionType: SessionType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type IImageProps = Readonly<ImageProps & ChatState>;
interface IState {
    uploadProgress: number;
    isUpload: boolean;
}

function Image(props: IImageProps) {
    const { message, onReady, messageUploadStateMap, connectState } = props;

    const messageUploadState = useMemo(() => {
        return messageUploadStateMap[message.id] || null;
    }, [message, messageUploadStateMap]);
    const task = useMemo(() => {
        return message.task || null;
    }, [message]);

    const [uploadProgress, setUploadProgress] = useState(0);
    const [status, setStatus] = useState(FileStatus.INIT);
    useEffect(() => {
        if (message.taskId && message.task && messageUploadState) {
            const task = message.task;
            switch (task.state) {
                case FileStatus.COMPLETED:
                    setUploadProgress(100);
                    setStatus(FileStatus.COMPLETED);
                    onReady({
                        beginSend: true,
                        messageExtend: {
                            url: messageUploadState.uploadResult.downloadUrl,
                            fileType: messageUploadState.uploadResult.type,
                            fileName: messageUploadState.uploadResult.name,
                        },
                    });
                    break;
                case FileStatus.FAILED:
                    onReady({
                        beginSend: false,
                        messageExtend: { status: FileStatus.FAILED },
                    });
                    setStatus(FileStatus.FAILED);
                    setUploadProgress(messageUploadState.progress);
                    break;
                case FileStatus.CANCELED:
                    setStatus(FileStatus.FAILED);
                    setUploadProgress(messageUploadState.progress);
                    break;
                case FileStatus.UPLOADING:
                    setStatus(FileStatus.UPLOADING);
                    setUploadProgress(messageUploadState.progress);
                    break;
            }
        } else if (message.url && !message.task) {
            setStatus(FileStatus.INIT);
            setUploadProgress(0);
        } else if (message.url) {
            setStatus(FileStatus.COMPLETED);
            setUploadProgress(100);
        }
    }, [messageUploadState]); // eslint-disable-line

    // 图片上传进度
    const onUploadProgress = (progress: number, data: any) => {
        setUploadProgress(progress);
    };

    // 上传完成图片的回传结果
    // downloadUrl: "https://eefs.jdcloud.com/res/downloadAuthn/IieT1w45VbZszCTsIgA86iLiL.jpeg?appKey=9e92d795a36edea0"
    // height: 850
    // md5: "c4c6349a6e00457376ff28ee0b1a8a34"
    // name: "4354409a-9752-46ac-8d45-5dcd110fc764.jpeg"
    // size: 209023
    // state: "completed"
    // type: "image/jpeg"
    // uploadId: ""
    // width: 1280
    // 图片上传完成
    const onUploadComplete = (data: any) => {
        setStatus(FileStatus.COMPLETED);
        // if (task) {
        //     const extend = {
        //         url: data.downloadUrl,
        //         fileType: data.type,
        //         fileName: data.name,
        //         // width: data.width,
        //         // height: data.height,
        //     };
        //     props.onReady({ beginSend: true, messageExtend: extend });
        // }
    };
    // 图片上传失败
    const onUploadImageFailed = (data: any) => {
        setStatus(FileStatus.FAILED);
        // console.log('loadImageComplete22 ==>', '图片上传失败', data);
    };

    // 图片上传开始
    const onUploadImageStart = () => {
        if (connectState.state === ConnectState.OFFLINE) {
            setStatus(FileStatus.FAILED);
            setUploadProgress(0);
            return;
        }
        setStatus(FileStatus.UPLOADING);
        setUploadProgress(0);
    };

    // 预览图片成功
    const previewImageComplete = () => {
        // console.log('loadImageComplete44 ==>', '预览图片成功');
    };

    // 预览图片失败
    const previewImageFailed = () => {
        // console.log('loadImageComplete55 ==>', '预览图片失败');
    };
    const file = task?.file;
    return (
        <div className="content">
            <div className="message image-message">
                <ImagePreview
                    {...props}
                    message={message}
                    isUpload={!!task}
                    file={file}
                    status={status}
                    uploadProgress={uploadProgress}
                    onComplete={previewImageComplete}
                    onFailed={previewImageFailed}
                />
            </div>
            {task && (
                <UploadControl
                    message={message}
                    onProgress={onUploadProgress}
                    onComplete={onUploadComplete}
                    onFailed={onUploadImageFailed}
                    onStart={onUploadImageStart}
                />
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, messageUploadStateMap, connectState } = chat as ChatState;
    return { selectedSession, messageUploadStateMap, connectState };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Image);
