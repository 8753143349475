import BaseService from '@/services/BaseService';
import {
    AuthDataRes,
    TeamLoginTypeRes,
    ValidateAccountRes,
    ValidateAccountReq,
    BizTypeEnum,
    CaServerSecurityRes,
} from '@/types/LoginPage';
import { UserCardData } from '@/types/UserCard';
interface VerifyMobileLegalRes {
    result: 'ILLEGAL' | 'LEGAL';
}
interface AppSendVerCodeRes {
    receive: string;
}

export default class AuthService extends BaseService {
    getTeamLoginType(params: { domainName: string }) {
        return this.fetch<TeamLoginTypeRes>('login', 'getTeamLoginType', {
            ...params,
        });
    }

    verifyMobileLegal(params: { countryCode: string; mobile: string }) {
        return this.fetch<VerifyMobileLegalRes>('login', 'verifyMobileLegal', {
            ...params,
        });
    }

    validateAccount(params: ValidateAccountReq) {
        return this.fetch<ValidateAccountRes>('login', 'validateAccount', {
            accountType: 'GENERAL',
            ...params,
        });
    }
    exclusiveValidateAccount(params: { loginName: string; loginNameType: string }) {
        return this.fetch<ValidateAccountRes>('login', 'exclusiveValidateAccount', {
            accountType: 'GENERAL',
            ...params,
        });
    }

    appLoginSendVerCode(params: { loginName: string }) {
        return this.fetch<VerifyMobileLegalRes>('login', 'appLoginSendVerCode', {
            receiveType: 'MOBILE',
            accountType: 'GENERAL',
            ...params,
        });
    }

    appSendVerCode(params: { loginName: string; loginNameType: string; bizType: BizTypeEnum }) {
        return this.fetch<AppSendVerCodeRes>('login', 'appSendVerCode', {
            accountType: 'GENERAL',
            ...params,
        });
    }
    exclusiveAppLoginSendVerCode(params: {
        loginName: string;
        loginNameType: string;
        bizType: BizTypeEnum;
    }) {
        return this.fetch<AppSendVerCodeRes>('login', 'exclusiveAppLoginSendVerCode', {
            accountType: 'GENERAL',
            ...params,
        });
    }

    fastLogin(params: { accessToken: string; userId?: string }, headers: any) {
        return this.fetch<AuthDataRes>('login', 'appFastLogin', params, {
            headers,
        });
    }
    appLoginByVerCode(params: { loginName: string; verCode: string; receiveType?: string }) {
        return this.fetch<AuthDataRes & { authCode: string }>(
            'login',
            'appLoginByVerCode',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }
    appLoginByPwd(params: { loginName: string; password: string; receiveType?: string }) {
        return this.fetch<AuthDataRes & { authCode: string }>(
            'login',
            'appLoginByPwd',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }
    appExclusiveLogin(params: {
        loginName: string;
        loginTicket: string;
        loginTeamId: string;
        loginNameType: string;
        loginType: string;
    }) {
        return this.fetch<AuthDataRes & { authCode: string }>(
            'login',
            'appExclusiveLogin',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }
    verifyResetPwdVerCode(params: { loginName: string; loginNameType: string; verCode: string }) {
        return this.fetch<AuthDataRes & { authCode: string }>(
            'login',
            'verifyResetPwdVerCode',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }
    exclusiveVerifyResetPwdVerCode(params: {
        loginName: string;
        loginNameType: string;
        teamId: string;
        verCode: string;
    }) {
        return this.fetch<AuthDataRes & { authCode: string }>(
            'login',
            'exclusiveVerifyResetPwdVerCode',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }

    resetPassWord(params: {
        loginName: string;
        loginNameType: string;
        teamId: string;
        authCode: string;
        passWord: string;
    }) {
        return this.fetch<AuthDataRes>(
            'login',
            'resetPassWord',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }
    exclusiveResetPassWord(params: {
        loginName: string;
        loginNameType: string;
        teamId: string;
        authCode: string;
        passWord: string;
    }) {
        return this.fetch<AuthDataRes>(
            'login',
            'exclusiveResetPassWord',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }

    exchangeWebToken() {
        return this.fetch<{ accessToken: string }>('login', 'exchangeWebToken', {});
    }

    getAuthInfo(headers: any) {
        return this.fetch<AuthDataRes>(
            'login',
            'getUserProfile',
            {},
            {
                headers,
            }
        );
    }

    getUserProfile(params: { userId: string; teamId: string; appId: string; isMulti?: boolean }) {
        return this.fetch<UserCardData>('ebook', 'gov.getPersonalDetail', params);
        // return this.fetch<UserCardData>('ebook', 'gov.userebookdetail', params);
    }

    bindMobile(params: {
        loginName: string;
        loginNameType: string;
        teamId?: string;
        mobile: string;
        verCode: string;
    }) {
        return this.fetch<AuthDataRes>(
            'login',
            'bindMobile',
            {
                accountType: 'GENERAL',
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        );
    }

    getCaServerSecurity() {
        return this.fetch<CaServerSecurityRes>('login', 'getCertificationAuthority', {});
    }

    loginByCertificationAuthority(params: {
        userCert: string;
        userSignedData: string;
        strRandom: string;
        strClientUsername?: string;
    }) {
        return this.fetch<AuthDataRes>('login', 'loginByCertificationAuthority', params);
    }

    sendVerfifyCodeReq(body: any) {
        return this.fetch<AuthDataRes>('focus', 'sendVerifyCode', body).catch((err) => {
            console.log('sendVerfifyCodeReq-err', err);
        });
    }
}
