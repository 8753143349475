/**
 * 会话主页
 * @author sunzhiguang
 * @date 2020/6/16
 */

import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './main.less';
import ChatLeft from '@/components/chat/view/LeftSide';
import ChatRight from '@/components/chat/view/RightSide';
import ChatMain from '@/components/chat/view/Main';
import { connect } from 'dva';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import SidebarSet, { ISetSwitch } from '@/components/chat/SidebarSet';
import ChatState, { UserState } from '@/types/chat/State';
import ContentFrame from '@/components/ContentFrame';
import log from '@/utils/logger';
import { Employee, Session, GroupRoster, SessionType } from '@/types/chat';
import config, { ChatConfig } from '@/config/config';
import ChatEvent from '@/components/chat/view/ChatEvent';
import { MessageNotifyType } from '@/types/chat/enum';
import Context from '@/components/chat/context';
import { getGroupRoster, getGroupRosterIdentity } from '@/components/chat/utils/group';
import { DrawTypeEnum } from '@/components/chat/enum';
import { usePrevious } from '@/utils/chat/index';
import { AI_NOTICE_EXCLUDE_PINS } from '@/contant/chat';
import { convertEmployeeToString } from '@/components/chat/utils/message';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';

interface ChatProps {
    type?: string;
    history: any;
    openSetup: boolean;
    match: any;
}

interface DvaProps {
    getCurrentEmployee: Function;
    initSelectedSession: Function;
    launchSingleSession: Function;
    launchGroupSession: Function;
    launchNoticeSession: Function;
    changeNotifyType: Function;
    setglobalDrawerVisible: Function;
    setmessageDrawerVisible: Function;
    setStartMessageId: Function;
    setIsMessageModule: Function;
}

type IChatProps = Readonly<ChatProps & DvaProps & UserState & ChatState>;

function Chat(props: IChatProps) {
    const {
        administrator,
        sub_administrator,
        ordinary,
        match,
        selectedSession,
        currentEmployee,
        launchSingleSession,
        launchGroupSession,
        launchNoticeSession,
        initSelectedSession,
        loadAllSessionListEnd,
        setglobalDrawerVisible,
        messageDrawerVisible,
        userData,
        setStartMessageId,
        isMessageModule,
        setIsMessageModule,
    } = props;
    // sunxiaolin3 进入会话框时初始化新建任务  （bug:新建任务时，点击消息推送，进入对话页面后新建任务没有关闭）
    const groupRosters = useMemo(() => {
        if (!selectedSession.isGroup || administrator.length === 0) {
            return [];
        }
        return getGroupRoster(administrator, sub_administrator, ordinary);
    }, [selectedSession, administrator, sub_administrator, ordinary]);

    const groupRosterIdentity = useMemo(() => {
        if (!selectedSession.isGroup || !currentEmployee) {
            return '';
        }
        if ((administrator || []).length === 0) {
            return '';
        }
        return getGroupRosterIdentity(administrator, sub_administrator, currentEmployee);
    }, [selectedSession, administrator, sub_administrator, currentEmployee]);

    const mountedRef = useRef(false);
    const [openSetup, setOpenSetup] = React.useState(false);
    const [historySearchOpen, setSearchRecordOpen] = React.useState(false);
    const [shareJoyspaceOpen, setShareJoyspaceOpen] = React.useState(false);
    const windowWidthRef = useRef(0);
    useEffect(() => {
        if (!selectedSession || !selectedSession.sessionId) {
            setOpenSetup(false);
        }
    }, [selectedSession]);

    useEffect(() => {
        if (messageDrawerVisible) {
            setglobalDrawerVisible(false);
            setOpenSetup(false);
            setSearchRecordOpen(false);
        }
    }, [messageDrawerVisible, setglobalDrawerVisible]);

    const history = useHistory();
    const { imService } = React.useContext<GlobalContextConfig>(GlobalContext);
    const [sid, setSid] = useState('');
    const [sType, setSType] = useState('');
    const [app, setApp] = useState('');
    const [tid, setsTid] = useState('');
    const [sMid, setSMid] = useState(-1);
    const [sText, setSText] = useState('');
    const [top, setTop] = useState('');
    const [secret, setSecret] = useState('');
    const [sessionList, setsessionList] = useState<Session>();
    const pSid = usePrevious(sid);

    const setParams = useCallback(
        ({
            sid = '',
            sType = '',
            aid = '',
            tid = '',
            top = '',
            sMid = '-1',
            sText = '',
            secret = '',
        }) => {
            setSid(sid);
            setSType(sType);
            setApp(aid);
            setsTid(tid);
            setTop(top);
            setSMid(parseInt(sMid, 10));
            setSText(sText);
            setSecret(secret);
        },
        []
    );

    useEffect(() => {
        const { sid, sType } = match.params;
        const query = new URLSearchParams(history.location.search);
        const aid = query.get('app') || '';
        const tid = query.get('tid') || '';
        const top = query.get('top') || '';
        const sMid = query.get('sMid') || -1;
        const sText = query.get('sText') || '';
        const secret = query.get('secret') || '';
        setParams({
            sid: sid || '',
            sType: sType || '',
            aid,
            tid,
            top,
            sMid,
            sText,
            secret,
        });
    }, [pSid, history, match.params, setParams]);

    useEffect(() => {
        // 处理联系人跳转会话页面 并选中指定联系人的会员 在会列表最前端
        if (!loadAllSessionListEnd || !sid) {
            return;
        }
        // 修复【Bug IM】左侧回话列表有多个相同的单聊对话窗口--hujun
        if (!currentEmployee?.app) {
            return;
        }
        if (sMid) {
            setStartMessageId({ sMid, sText });
        }
        if (pSid === sid) {
            return;
        }
        if (sType === 'g' && sid) {
            launchGroupSession({ sid, top });
        } else if (sType === 's' && sid && tid) {
            if (sid === '@im.jd.com') {
                return;
            }
            // 单聊密聊会话跳转
            const type = secret === 'true' ? SessionType.SECRET_SINGLE : SessionType.SINGLE;
            launchSingleSession({
                top,
                user: {
                    app,
                    pin: sid?.split(':')[0],
                    teamId: tid,
                },
                type,
            });
        } else if (sType === 'n' && sid) {
            let _sid = sid;
            let flag = true;
            for (const pin of AI_NOTICE_EXCLUDE_PINS) {
                if (sid.indexOf(pin) > -1) {
                    flag = false;
                }
            }
            if (flag) {
                _sid = `aiRobot:dd.notice:focus.bot:${convertEmployeeToString(currentEmployee)}`;
            }
            launchNoticeSession({ sid: _sid, top });
        } else {
            initSelectedSession({ selectedSession: selectedSession || {} });
        }
        setStartMessageId({ sMid, sText });
        // changeNotifyType({ selectedNotifyType: MessageNotifyType.ALL });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pSid,
        sid,
        app,
        tid,
        sType,
        top,
        loadAllSessionListEnd,
        currentEmployee,
        selectedSession,
        sMid,
        sText,
    ]);

    const showSessionList = config[ChatConfig.CHAT_SESSION_LIST_ENABLE];

    function handleResize() {
        windowWidthRef.current = document.body.clientWidth;
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        mountedRef.current = true;
        return () => {
            window.removeEventListener('resize', handleResize);
            mountedRef.current = false;
        };
    }, []);

    const onOp = useCallback(
        (session: any) => {
            if (session) {
                const sessions = {
                    ...session,
                    sessionId: selectedSession.sessionId,
                };
                setsessionList(sessions);
            }
        },
        [selectedSession]
    );
    // useEffect(() => {
    //     const chatManager = imService.getChatManager();
    //     if (chatManager) {
    //         chatManager.on('session-update', onOp);
    //         return () => {
    //             chatManager.off('session-update', onOp);
    //         };
    //     }
    // }, [imService, onOp]);

    function handleMainClick() {
        if (openSetup) {
            setOpenSetup(false);
            setSearchRecordOpen(false);
            setShareJoyspaceOpen(false);
        }
        setTimeout(() => {
            ChatEvent.closeMention();
        }, 100);
    }

    function onToggleDraw(type: DrawTypeEnum) {
        if (!mountedRef.current) return;
        switch (type) {
            case DrawTypeEnum.setup:
                setOpenSetup(!openSetup);
                setSearchRecordOpen(false);
                setShareJoyspaceOpen(false);
                break;
            case DrawTypeEnum.search:
                setOpenSetup(false);
                setSearchRecordOpen(!historySearchOpen);
                setShareJoyspaceOpen(false);
                break;
            case DrawTypeEnum.shareJoyspace:
                setOpenSetup(false);
                setSearchRecordOpen(false);
                setShareJoyspaceOpen(!shareJoyspaceOpen);
                break;
        }
    }

    function closeSetting() {
        if (!mountedRef.current) return;
        setOpenSetup(false);
    }
    return (
        <Context.Provider
            value={{
                currentEmployee,
                groupRosters,
                groupRosterIdentity,
                userData,
                onToggleSetup: onToggleDraw,
                closeSetting,
            }}
        >
            <ContentFrame
                leftSide={showSessionList && <ChatLeft />}
                rightSide={
                    <div
                        className={[
                            'chat-container',
                            openSetup ? 'open' : '',
                            !config[ChatConfig.CHAT_RIGHT_SIDER_ENABLE] ? 'hidden-right-side' : '',
                        ].join(' ')}
                    >
                        {!selectedSession.sessionId && <div className="main-top" />}
                        <div
                            className="main"
                            style={{
                                top: selectedSession.sessionId ? '0px' : '60px',
                            }}
                            onClick={handleMainClick}
                        >
                            <ChatMain onToggleSetup={onToggleDraw} />
                        </div>
                        {config[ChatConfig.CHAT_RIGHT_SIDER_ENABLE] && (
                            <div className="right">
                                <ChatRight
                                    session={sessionList}
                                    openSetup={openSetup}
                                    historySearchOpen={historySearchOpen}
                                    shareJoyspaceOpen={shareJoyspaceOpen}
                                    onToggleSetup={onToggleDraw}
                                    onClickClose={() => {
                                        onToggleDraw(DrawTypeEnum.search);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        </Context.Provider>
    );
}

function mapStateToProps({ chat, user: { userData } }: any) {
    const {
        administrator,
        sub_administrator,
        ordinary,
        selectedSession,
        currentEmployee,
        loadAllSessionListEnd,
        globalDrawerVisible,
        messageDrawerVisible,
        allSessionList,
    } = chat as ChatState;

    return {
        administrator,
        sub_administrator,
        ordinary,
        selectedSession,
        currentEmployee,
        loadAllSessionListEnd,
        globalDrawerVisible,
        messageDrawerVisible,
        userData: userData ? userData.user : {},
        allSessionList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getCurrentEmployee: () => {
            dispatch({ type: 'chat/getCurrentEmployee', payload: {} });
        },
        initSelectedSession(data: { selectedSession: Session }) {
            dispatch({ type: 'chat/initSelectedSession', payload: data });
        },
        launchSingleSession(data: { employee: Employee }) {
            dispatch({ type: 'chat/launchSingleSession', payload: data });
        },
        launchGroupSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchGroupSession', payload: data });
        },
        launchNoticeSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchNoticeSession', payload: data });
        },
        changeNotifyType(data: { selectedNotifyType: MessageNotifyType }) {
            dispatch({ type: 'chat/changeNotifyType', payload: data });
        },
        setglobalDrawerVisible(data: boolean) {
            dispatch({ type: 'chat/setglobalDrawerVisible', payload: data });
        },
        setmessageDrawerVisible(data: boolean) {
            dispatch({ type: 'chat/setmessageDrawerVisible', payload: data });
        },
        setStartMessageId(data: { sMid: number; sText: string }) {
            dispatch({ type: 'chat/setStartMessageId', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat as any));
