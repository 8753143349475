import log from '@/utils/logger';
import {
    getEbookDeptInfo,
    getEbookOrgInfo,
    getEbookSimpleVirtualOrgInfo,
    getSimpleDeptInfo,
} from '@/api/ebook';
import isEmpty from 'lodash/isEmpty';
import { AddressNavKey, AddressSearchKey } from '@/types/common';
import ImService from '@/server/ImService';
import { executePut } from '@/server/dvaHelper';
import Bus from '@/utils/bus';
import { UserState } from '@/types/chat/State';
import config, { ConfigEnum } from '@/config/config';
import { labelInfoList, ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { TitleDeptInfo } from '@/types/UserCard';
import { DeptCrumbLisInfo } from '@/services/EBookService';
import { LabelInfoRes, mapGetEbookDeptInfoList } from '@/components/AddressBook/common';
import { GroupItem, GroupCheckedItem } from '@/components/BigScreen/Types';
import { setGetEbookGroupList } from '@/components/BigScreen/common';
import { uniqBy } from 'lodash';

const logger = log.getLogger('models/ebook');

export interface jsonDataInfo {
    deptId: string;
    deptName: string;
    toBeReplaced: string;
    replacedWith: string;
}

export interface EBookDeptUserInfo {
    appId: string;
    avatar: string;
    isLeader: '0' | '1';
    positionName: string;
    realName: string;
    teamId: string;
    userId: string;
    administratorType: string;
    labelInfoList: labelInfoList[];
}

export interface Project {
    id: number; // 主键ID
    projectId: string; // 项目ID
    templateId: string; // 模板ID
    type: number;
    name: string; // 项目名称
    icon: string; // 项目头像地址
    iconColor: string; // icon颜色
    progress: number; // 项目进度
    progressName: string; // 进度名称
    progressColor: string; // 进度颜色
    progressDesc: string; // 进度描述
    progressPercent: string; // 项目进度百分比
    description: string; // 项目描述
    gmtCreate: number; // 创建时间
    gmtModified: number; // 修改时间
}

export interface EBookSubDeptInfo {
    deptId: string;
    teamId: string;
    deptName: string;
    fullName: string;
    fullPath: string;
    parentId: string;
    ebookSubDeptEmplSums: number;
    contactNumber: number;
}

export interface SimpleDeptInfo {
    deptId: string;
    deptName: string;
    deptOrder?: number;
}

export interface simpleDeptInfoRes {
    deptId: string;
    simpleDeptInfoList: SimpleDeptInfo[];
    mainDeptInfoList: SimpleDeptInfo[];
    fullPath: string;
}

export interface VirtualOrgRelationShipInfo {
    orgId: 'string';
    orgName: 'string';
    topOrgId: 'string';
    topOrgName: 'string';
}

export interface EBookDeptInfoRes {
    contactNumber: number;
    deptId: string;
    deptName: string;
    ebookInfoResList: EBookDeptUserInfo[];
    ebookSubDeptInfoList: EBookSubDeptInfo[];
    fullName: string;
    fullPath: string;
    parentId: string;
    teamId: string;
    simpleDeptInfoList: SimpleDeptInfo[];
    id?: string;
}
export interface EBookDeptInfoResMap {
    [key: string]: { [key: string]: EBookDeptInfoRes };
}

export interface VirtualOrgInfo {
    virtualOrgRelationShipInfos: VirtualOrgRelationShipInfo[];
}

export enum BigScreenTabInfo {
    dept = 'dept',
    device = 'device',
    selected = 'selected',
}
export interface simpleDeptInfo {
    deptName: string;
    deptNums: string;
    deptOrder: number;
    deptId: string;
    click: string;
}

export interface ebookUnitDeptInfo {
    unitDeptId: string;
    simpleDeptInfoList: simpleDeptInfo[];
    unitName: string;
    unitIsClick: boolean;
    unitFullName: string;
    unitFullPath: string;
    unitNums: any;
}

export interface EbookState {
    error: Error | null;
    addressNavKey: AddressNavKey | string;
    addressSearchKey: AddressSearchKey;
    // deptInfoErr: Error | null;
    virtualOrgInfo: VirtualOrgInfo | null;
    simpleDeptInfo: simpleDeptInfoRes | null;
    ebookUnitDeptList: ebookUnitDeptInfo[] | null;
    ebookLoading: boolean;
    selectedEbookId: string;
    selectedTreeEbookId: string;
    deptInfo: EBookDeptInfoRes | null;
    deptInfoMap: EBookDeptInfoResMap | null;
    newContactsCount: number;
    newContacts: ListItemData[] | null;
    updateAddressNavKey: Function;
    getSimpleDeptInfo: Function;
    getEbookDeptInfo: Function;
    getEbookOrgInfo: Function;
    // getNewContactsCount: Function;
    updateAddressSearchKey: Function;
    acceptFriend: Function;
    getEbookSimpleVirtualOrgInfo: Function;
    treeDataInfo: [];
    openExternalContactsModal: boolean;
    openAdministratorListModal: boolean;
    openRefusalCauseModal: { visible: boolean; message: any; selectedSession: any };
    expandedKeys?: [];
    createModalVisible?: boolean;
    departmentInfo: EBookDeptInfoRes | null;
    createModalInputValue: string;
    deptCrumbList?: DeptCrumbLisInfo[];
    partTimeTitleDeptInfo?: TitleDeptInfo[];
    label: {
        currentLabelData?: LabelInfoRes | null;
        labelsList?: LabelInfoRes[] | [];
        addUserLabelNameModelVisible?: boolean; // 新建标签弹框
        labelNameUserUserId?: string;
        addUserLabelNameInputValue?: string; // 新建标签名称弹框
        tagIcon?: string; // 标签列表展示
        userLabel?: LabelInfoRes[];
    };
    bigScreen: {
        userOrDeviceList: []; // 大屏左右（部门/设备）联动数据
        selectedList: any[]; // 大屏选中的用户
        collectList: []; // 大屏常用联系人
        rightDrawerVisible: boolean;
        isShowSelected: boolean;
        groupList: GroupItem[]; // 分组列表
        bigScreenActiveTabKey: boolean;
        isBigScreenTip: boolean;
        jsonData: jsonDataInfo;
        needUpdateSearchData: {
            type: string;
            data: {};
        };
    };
    orgActiveKey?: string;
}

export default {
    namespace: 'ebook',
    state: {
        addressNavKey: '',
        addressSearchKey: AddressSearchKey.CONTACTS,
        // deptInfoErr: null,
        virtualOrgInfo: null,
        simpleDeptInfo: null,
        ebookUnitDeptList: null,
        deptInfo: null, // 组织架构数据
        newContactsCount: 0,
        newContacts: null, // 新用户申请列表
        treeDataInfo: [],
        createModalVisible: false, // 消息+号入口添加联系人
        createModalInputValue: '', // 消息+号入口添加联系人
        openAdministratorListModal: false, // 系统管理员modal
        openExternalContactsModal: false, // ai卡片邀请联系人申请列表model
        openRefusalCauseModal: { visible: false, message: {}, selectedSession: {} }, // ai卡片邀请用户驳回原因
        expandedKeys: [],
        deptCrumbList: [],
        partTimeTitleDeptInfo: [],
        label: {
            currentLabelData: null, // 当前标签选中的数据
            labelsList: null, // 标签列表
            addUserLabelNameModelVisible: false, // 新建标签弹框
            labelNameUserUserId: '', // 个人卡片里新建标签需要当前卡片用户的userid
            addUserLabelNameInputValue: '', // 新建标签名称弹框
            tagIcon: 'close',
            userLabel: [],
        },
        bigScreen: {
            userOrDeviceList: [],
            selectedList: [],
            collectList: [],
            rightDrawerVisible: false,
            isShowSelected: false,
            groupList: [],
            bigScreenActiveTabKey: BigScreenTabInfo.dept,
            isBigScreenTip: false,
            jsonData: {},
            needUpdateSearchData: {},
        },
        orgActiveKey: 'org',
    },

    reducers: {
        setNeedUpdateSearchData(state: EbookState, { payload }: { payload: jsonDataInfo }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    needUpdateSearchData: payload,
                },
            };
        },
        setJsonData(state: EbookState, { payload }: { payload: jsonDataInfo }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    jsonData: { ...payload },
                },
            };
        },
        setGroupList(state: EbookState, { payload }: { payload: any[] }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    groupList: [...payload],
                },
            };
        },
        setIsBigScreenTip(state: EbookState, { payload }: { payload: any }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    isBigScreenTip: payload,
                },
            };
        },
        setBigScreenActiveTabKey(state: EbookState, { payload }: { payload: any }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,

                    bigScreenActiveTabKey: payload,
                },
            };
        },
        setIsShowSelected(state: EbookState, { payload }: { payload: any }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    isShowSelected: payload,
                },
            };
        },
        setRightDrawerVisible(state: EbookState, { payload }: { payload: any }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    rightDrawerVisible: payload,
                },
            };
        },
        updateBigScreenSelectedList(state: EbookState, { payload }: { payload: any }) {
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    selectedList: payload,
                },
            };
        },
        setCollectList(state: EbookState, { payload }: any) {
            // console.log('payload=====>', payload);
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    collectList: payload,
                },
            };
        },
        setUserOrDeviceList(state: EbookState, { payload }: any) {
            // console.log('payload=====>', payload);
            return {
                ...state,
                bigScreen: {
                    ...state.bigScreen,
                    userOrDeviceList: payload,
                },
            };
        },
        setUserLabel(state: EbookState, { payload }: any) {
            return {
                ...state,
                label: {
                    ...state.label,
                    userLabel: payload,
                },
            };
        },
        setTagIcon(state: EbookState, { payload }: any) {
            return {
                ...state,
                label: {
                    ...state.label,
                    tagIcon: payload,
                },
            };
        },
        openCreateAddUserLabelNameModel(state: EbookState, { payload }: any) {
            return {
                ...state,
                label: {
                    ...state.label,
                    addUserLabelNameModelVisible: payload.visible,
                    labelNameUserDetail: payload.labelNameUserDetail,
                },
            };
        },
        setAddUserLabelNameInputValue(state: EbookState, { payload }: any) {
            return {
                ...state,
                label: {
                    ...state.label,
                    addUserLabelNameInputValue: payload,
                },
            };
        },
        setLabelsList(state: EbookState, { payload }: any) {
            return {
                ...state,
                label: {
                    ...state.label,
                    labelsList: payload,
                },
            };
        },
        setCurrentLabelData(state: EbookState, { payload }: any) {
            return {
                ...state,
                label: {
                    ...state.label,
                    currentLabelData: payload,
                },
            };
        },
        setOpenExternalContactsModal(state: EbookState, { payload }: any) {
            return { ...state, openExternalContactsModal: payload };
        },
        setOpenAdministratorListModal(state: EbookState, { payload }: any) {
            return {
                ...state,
                openAdministratorListModal: payload,
            };
        },
        setOpenRefusalCauseModal(state: EbookState, { payload }: any) {
            return { ...state, openRefusalCauseModal: payload };
        },
        setCreateModalInputValue(state: EbookState, { payload }: any) {
            return { ...state, createModalInputValue: payload };
        },
        updateAddressNavKey(state: EbookState, { payload }: any) {
            return { ...state, addressNavKey: payload.addressNavKey };
        },
        updateAddressSearchKey(state: EbookState, { payload }: any) {
            return { ...state, addressSearchKey: payload.addressSearchKey };
        },
        // 只支持树结构存储
        updateTreeDataInfo(state: EbookState, { payload }: any) {
            return { ...state, treeDataInfo: payload };
        },
        setDeptCrumbList(state: EbookState, { payload }: any) {
            return { ...state, deptCrumbList: payload };
        },
        setVirtualOrgInfo(state: EbookState, { payload }: { payload: simpleDeptInfoRes }) {
            return { ...state, virtualOrgInfo: payload };
        },
        setEbookUnitDeptList(state: EbookState, { payload }: { payload: ebookUnitDeptInfo[] }) {
            return { ...state, ebookUnitDeptList: payload };
        },
        setSimpleDeptInfo(state: EbookState, { payload }: { payload: simpleDeptInfoRes }) {
            const { simpleDeptInfoList, mainDeptInfoList } = payload;
            let tempSimpleDeptInfoList: SimpleDeptInfo[] = [];

            if (simpleDeptInfoList?.length) {
                tempSimpleDeptInfoList = [simpleDeptInfoList[0]];
                tempSimpleDeptInfoList = tempSimpleDeptInfoList.concat(mainDeptInfoList);
            }

            payload.simpleDeptInfoList = tempSimpleDeptInfoList || [];

            return { ...state, simpleDeptInfo: payload };
        },
        setPartTimeTitleDeptInfo(state: EbookState, { payload }: { payload: any }) {
            return { ...state, partTimeTitleDeptInfo: payload };
        },
        setExpandedKeys(state: EbookState, { payload }: { payload: any }) {
            return { ...state, expandedKeys: payload };
        },
        setOrgActiveKey(state: EbookState, { payload }: { payload: any }) {
            return { ...state, orgActiveKey: payload };
        },
        // setDepartmentInfo(state: EbookState, { payload }: { payload: EBookDeptInfoRes }) {
        //     return { ...state, departmentInfo: payload };
        // },
        setEbookDeptInfo(state: EbookState, { payload }: { payload: EBookDeptInfoRes }) {
            return { ...state, deptInfo: payload };
        },
        setEbookDeptInfoMap(state: EbookState, { payload }: { payload: EBookDeptInfoResMap }) {
            return { ...state, deptInfoMap: payload };
        },
        setEbookLoading(state: EbookState, { payload }: { payload: boolean }) {
            return { ...state, ebookLoading: payload };
        },
        setSelectedEbookId(state: EbookState, { payload }: { payload: string }) {
            return { ...state, selectedEbookId: payload };
        },

        openCreateContactModal(state: EbookState, { payload }: any) {
            // console.log(payload, 'payload');
            return { ...state, createModalVisible: payload.visible };
        },
        setNewContactsCount(state: EbookState, { payload }: any) {
            // logger.debug('setNewContactsCount =>', payload);
            return { ...state, newContactsCount: payload.count };
        },
        setNewContacts(state: EbookState, { payload }: any) {
            // const result = state?.newContacts
            //     ? uniqBy([...(payload || []), ...(state?.newContacts || [])], `id`)
            //     : payload || [];
            // const data = result.sort((a: any, b: any) => {
            //     return b.applyTime - a.applyTime;
            // });
            logger.debug('setNewContactsList =>', payload);
            return {
                ...state,
                newContacts: payload || [],
            };
        },

        setError(state: EbookState, { payload }: { payload: Error }) {
            return { ...state, error: payload };
        },
        // 清空数据
        clearEbookData(state: EbookState) {
            return {
                ...state,
                deptInfo: null,
                // deptInfoMap: null,
                selectedEbookId: '',
                selectedTreeEbookId: '',
                ebookLoading: false,
                addressNavKey: '',
                treeDataInfo: [],
                departmentInfo: null,
                simpleDeptInfo: null,
                deptCrumbList: [],
                partTimeTitleDeptInfo: [],
                label: {
                    currentLabelData: null, // 当前标签选中的数据
                    labelsList: [], // 标签列表
                    addUserLabelNameModelVisible: false, // 新建标签弹框
                    labelNameUserUserId: '', // 个人卡片里新建标签需要当前卡片用户的userid
                    addUserLabelNameInputValue: '', // 新建标签名称弹框
                    tagIcon: 'close',
                    userLabel: [],
                },
                bigScreen: {
                    userOrDeviceList: [],
                    selectedList: [],
                    collectList: [],
                    rightDrawerVisible: false,
                    isShowSelected: false,
                    bigScreenActiveTabKey: 'dept',
                    needUpdateSearchData: {},
                },
            };
        },
    },
    effects: {
        *getEbookSimpleVirtualOrgInfo({ payload }: any, { call, put }: any): any {
            let result = null;
            try {
                result = yield call(getEbookSimpleVirtualOrgInfo, payload);
            } catch (e) {
                // Bus.emit('app:default-page-nodata', DefaultPageType.NO_DATA);
                return;
            }
            if (isEmpty(result)) {
                // Bus.emit('app:default-page-nodata', DefaultPageType.NO_DATA);
                return;
            }
            yield put({
                type: 'setVirtualOrgInfo',
                payload: result,
            });
            return true;
        },
        *getSimpleDeptInfo({ payload }: any, { call, put, select }: any): any {
            let result = null;
            try {
                result = yield call(getSimpleDeptInfo, payload);
            } catch (e) {
                result = {};
            }

            if (isEmpty(result)) {
                result = {};
            }
            yield put({
                type: 'setSimpleDeptInfo',
                payload: result,
            });
            return true;
        },
        *getEbookDeptInfo({ payload }: any, { call, put }: any): any {
            let result = null;
            try {
                result = yield call(getEbookDeptInfo, payload);
            } catch (e) {
                if (e.message.indexOf('10230004') > -1) {
                    // Bus.emit('app:default-page-nodata', DefaultPageType.NO_DATA);
                    return;
                }
            }
            if (isEmpty(result)) {
                // Bus.emit('app:default-page-nodata', DefaultPageType.NO_DATA);
                return;
            }
            yield put({
                type: 'setEbookDeptInfo',
                payload: result,
            });
            return true;
        },
        *getEbookOrgInfo({ payload }: any, { call, put }: any): any {
            let result = null;
            try {
                result = yield call(getEbookOrgInfo, payload);
            } catch (e) {}
            if (isEmpty(result)) {
                return;
            }
            yield put({
                type: 'setEbookDeptInfo',
                payload: result,
            });
            return result;
        },
        *refreshDeptVersion() {
            const imService = ImService.getInstance();
            yield imService.refreshDeptVersion();
        },
        *getEbookUserInfoList({ payload }: any, { call, put, select }: any): any {
            const ebookState = yield select(({ ebook }: { ebook: EbookState }) => ebook);
            const userState = yield select(({ user }: { user: UserState }) => user.userData.user);
            const id = payload.deptId ? payload.deptId : payload.orgId ? payload.orgId : '';
            let deptInfoMap = ebookState.deptInfoMap;

            if (!deptInfoMap) {
                yield put({
                    type: 'setEbookDeptInfoMap',
                    payload: {},
                });
                deptInfoMap = {};
            }
            if (!deptInfoMap[userState.userId]) {
                yield put({
                    type: 'setEbookDeptInfoMap',
                    payload: { ...deptInfoMap },
                });
                deptInfoMap[userState.userId] = {};
            }

            if (!id) {
                return false;
            }

            let res;

            if (payload.deptId) {
                try {
                    const imService = ImService.getInstance();
                    res = yield imService.getDeptInfo(payload);
                } catch (e) {
                    console.log(`getEbookDeptInfoById-`, e);
                }
                if (isEmpty(res)) {
                    return;
                }
            } else if (payload.orgId) {
                try {
                    res = yield call(getEbookOrgInfo, payload);
                } catch (e) {}
                if (isEmpty(res)) {
                    return;
                }
            }
            deptInfoMap[userState.userId][id] = res;
            yield put({
                type: 'setEbookDeptInfoMap',
                payload: { ...deptInfoMap },
            });

            console.log(res?.ebookInfoResList);
            if (!res?.ebookInfoResList?.length) {
                yield put({
                    type: 'setEbookDeptInfo',
                    payload: res,
                });
                return;
            }
            const ids: string[] = [];
            res?.ebookInfoResList?.forEach((item: any) => {
                ids.push(item.userId);
            });
            const data = setGetEbookGroupList(ids, res?.ebookInfoResList, res?.fullName);
            yield put({
                type: 'setEbookDeptInfo',
                payload: data,
            });
            return res;
        },

        // *getEbookDeptInfoById({ payload }: any, { call, put, select }: any): any {
        //     const ebookState = yield select(({ ebook }: { ebook: EbookState }) => ebook);
        //     const userState = yield select(({ user }: { user: UserState }) => user.userData.user);
        //     const id = payload.deptId ? payload.deptId : payload.orgId ? payload.orgId : '';
        //     let deptInfoMap = ebookState.deptInfoMap;

        //     if (!deptInfoMap) {
        //         yield put({
        //             type: 'setEbookDeptInfoMap',
        //             payload: {},
        //         });
        //         deptInfoMap = {};
        //     }
        //     if (!deptInfoMap[userState.userId]) {
        //         yield put({
        //             type: 'setEbookDeptInfoMap',
        //             payload: { ...deptInfoMap },
        //         });
        //         deptInfoMap[userState.userId] = {};
        //     }

        //     if (!id) {
        //         return false;
        //     }

        //     let res;

        //     if (payload.deptId) {
        //         try {
        //             const imService = ImService.getInstance();
        //             res = yield imService.getDeptInfo(payload);
        //         } catch (e) {
        //             console.log(`getEbookDeptInfoById-`, e);
        //         }
        //         if (isEmpty(res)) {
        //             return;
        //         }
        //     } else if (payload.orgId) {
        //         try {
        //             res = yield call(getEbookOrgInfo, payload);
        //         } catch (e) {}
        //         if (isEmpty(res)) {
        //             return;
        //         }
        //     }
        //     deptInfoMap[userState.userId][id] = res;
        //     yield put({
        //         type: 'setEbookDeptInfoMap',
        //         payload: { ...deptInfoMap },
        //     });
        //     if (payload.isDepartment) {
        //         yield put({
        //             type: 'setDepartmentInfo',
        //             payload: res,
        //         });
        //     } else {
        //         yield put({
        //             type: 'setEbookDeptInfo',
        //             payload: res,
        //         });
        //     }
        //     console.log(deptInfoMap, 'deptInfoMap', res);
        //     return res;
        // },
        // *getNewContactsCount({ payload }: any, { put }: any): any {
        //     const imService = ImService.getInstance();
        //     const count = yield imService.getNewContactsCount();

        //     logger.debug('getNewContactsCount ===>', count);

        //     yield put({
        //         type: 'setNewContactsCount',
        //         payload: { count },
        //     });
        //     return true;
        // },

        *getCheckedApplyCount({ payload }: any, { put }: any): any {
            const imService = ImService.getInstance();
            const count = yield imService.getCheckedApplyCount();

            logger.debug('getCheckedApplyCount ===>', count);

            // yield put({
            //     type: 'setNewContactsCount',
            //     payload: { count },
            // });
            // return true;
        },
        *updateEbookDeptInfoById({ payload }: any, { call, put, select }: any): any {
            const ebookState = yield select(({ ebook }: { ebook: EbookState }) => ebook);
            const deptInfoMap = ebookState.deptInfoMap;
            const deptId = payload?.deptId;
            if (deptId) {
                deptInfoMap[deptId] = payload;
                yield put({
                    type: 'setEbookDeptInfoMap',
                    payload: {
                        ...deptInfoMap,
                    },
                });
            }
        },
    },
    subscriptions: {
        'ebook-event'({ dispatch }: any, onError: (reason?: any) => void) {
            Bus.on('ebook:contact/apply-count', (count) => {
                // logger.debug('contact/apply-count', count);
                dispatch({ type: 'setNewContactsCount', payload: count });
            });
            Bus.on('ebook:contact/update-apply', (obj) => {
                logger.debug(11111, 'contact/update-apply', obj);
                dispatch({ type: 'setNewContacts', payload: obj });
            });
            Bus.on('address:add_friend_success', () => {
                logger.debug('address:add_friend_success', history);
                dispatch({ type: 'getNewContactsCount' });
            });
            Bus.on('dept:department-update', (deptInfo) => {
                // console.log(`dept:department-update`, deptInfo);
                dispatch({ type: 'updateEbookDeptInfoById', payload: deptInfo });
            });
        },
    },
};
