import React, { useState, useEffect } from 'react';
import NoticeDetails from './NoticeDetails';
import bus from '@/utils/bus';
import { connect } from 'dvajs';

const NoticeDetailsCard = ({ openNoticeDetails }: { openNoticeDetails: Function }) => {
    const [notificationId, setNotificationId] = useState<string>('');
    const [visible, setVisible] = useState<boolean>(false);
    const openNotification = (notificationId = '') => {
        if (notificationId) {
            // setNotificationId(notificationId);
            // setVisible(true);
            openNoticeDetails({
                isvisibles: true,
                notificationId: notificationId,
            });
        }
    };
    useEffect(() => {
        bus.on('supervise/notification', openNotification);
        return () => {
            bus.off('supervise/notification', openNotification);
        };
    });
    const onClose = () => {
        setVisible(false);
    };
    return <NoticeDetails />;
};
function mapDispatchToProps(dispatch: any) {
    return {
        openNoticeDetails(data: { isvisibles: boolean; notificationId: any }) {
            dispatch({ type: 'chat/openNoticeDetails', payload: data });
        },
    };
}
export default connect(undefined, mapDispatchToProps)(NoticeDetailsCard);
