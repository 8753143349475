import React, { ReactNode } from 'react';
import { mattchEmoji } from '@/components/chat/message/Input/Emoji';
import { convertStringToEmoji } from '@/components/chat/utils/ddemoji';
import { stringEncode } from '@/utils/chat/index';
import { ConsoleSqlOutlined } from '@ant-design/icons';

export function formatMatchContext(
    content: string,
    searchKey: string,
    matchIndex = 0
): ReactNode[] {
    if (!content) {
        return [];
    }
    if (!searchKey) {
        return [content];
    }
    const firstIndex = content.indexOf(searchKey);
    if (firstIndex >= 0) {
        const results: ReactNode[] = [];
        if (firstIndex >= 20) {
            results.push('...');
            const start = firstIndex - 12;
            results.push(content.slice(start < 0 ? 0 : start, firstIndex));
        } else {
            results.push(content.slice(0, firstIndex));
        }
        results.push(<em key={'search-keys' + matchIndex}>{searchKey}</em>);
        results.push(
            ...formatMatchContext(
                content.slice(firstIndex + searchKey.length),
                searchKey,
                matchIndex + 1
            )
        );
        return results;
    }
    return [content];
}

export function processEmoji(content: ReactNode[]) {
    let htmlStr = '';
    let htmlArr = content.map((item: any) => {
        if (typeof item === 'object') {
            let { type, props } = item;
            if (type && props) {
                return `<${type}>${props.children || ''}</${type}>`;
            }
        }
        return mattchEmoji(convertStringToEmoji(stringEncode(item)));
    });
    if (htmlArr.length) {
        htmlStr = htmlArr.join('');
    }
    return htmlStr;
}
