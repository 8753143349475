import React, { ChangeEvent, useRef, useState, Dispatch, useEffect, useCallback } from 'react';
import { Drawer, Button, Input, Switch, message, Form } from 'antd';
import ReactDOM from 'react-dom';
import UploadImg from '@/components/Projects/components/AnnounceTab/UploadImg';
import { GlobalContextConfig } from '@/context/GlobalContext';
import IconFont from '@/components/icon';
import { createAnnounceService, updateAnnounceService, findAnnouncementDetail } from '../../api';
import bus from '@/utils/bus';
import CopyClipboard from 'copy-to-clipboard';
import './index.less';
import { isFocusEnv } from '@/utils';
import CopyFrame from '@/components/CopyFrame';

const { TextArea } = Input;
interface Iprops {
    onclose?: () => void;
    projectId: string;
    globalcontext: GlobalContextConfig;
    announceDetail?: { content: string; announcementId: string };
    pageType?: string;
    t: any;
    el?: any;
}

enum showTypes {
    'hidden' = 1,
    'show' = 2,
}

function CreateAnnounce(props: Iprops) {
    const { globalcontext, onclose, projectId, announceDetail, pageType = 'add', t, el } = props;
    const [visible, setVisible] = useState(true);
    const [showType, setShowType] = useState<showTypes>(showTypes.show);
    const [content, setContent] = useState<string>(announceDetail?.content || '');
    const [contentLength, setContentLength] = useState(500);
    const imgsRef = useRef<string[]>([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [originUrl, setOriginUrl] = useState<string[]>([]);
    const [ContentFouse, setContentFouse] = useState(false);

    const getDetail = useCallback(
        async function (projectId: string, announcementId = '') {
            const res = await findAnnouncementDetail(projectId, announcementId);
            if (res.errCode) return message.error(t('has_error'));
            setOriginUrl(res.announcementDetailInfo?.attachmentUrl || []);
            imgsRef.current = res.announcementDetailInfo?.attachmentUrl || [];
            setShowType(res.announcementDetailInfo?.showType);
        },
        [t]
    );

    useEffect(() => {
        if (!announceDetail?.announcementId) return;
        getDetail(projectId, announceDetail.announcementId);
    }, [projectId, announceDetail, getDetail]);

    async function handleOK() {
        setConfirmLoading(true);
        if (pageType === 'add') {
            await createAnnounceService({
                projectId,
                content,
                attachmentUrls: imgsRef.current,
                showType,
            }).then((res) => {
                message.success('发布成功');
            });
        } else if (pageType === 'edit') {
            await updateAnnounceService({
                projectId,
                content,
                announcementId: announceDetail?.announcementId || '',
                attachmentUrls: imgsRef.current,
                showType,
                announcementTypeEnum: 'Group_Announcement',
            }).then((res) => {
                message.success('发布成功');
            });
            bus.emit('groupNoticeDetail:updata', {
                projectId,
                announcementId: announceDetail?.announcementId || '',
            });
        }
        setConfirmLoading(false);
        bus.emit('groupNoticeList:updata', {
            projectId,
            announcementId: announceDetail?.announcementId || '',
        });
        setVisible(false);
        removeNodeEl(el);
    }

    function contentChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        // const compactValue = newValue.trimStart();
        setContent(newValue);
        // setContentLength(newValue.length - compactValue.length + 500);
    }

    // 复制  cxz
    function doCopy(e: any) {
        e.stopPropagation();
        if (content) {
            let str = content;
            if (str && CopyClipboard(str)) {
                message.success('复制成功');
            } else {
                message.error('复制失败');
            }
        }
    }

    function GetButtons(el: any) {
        return (
            <div className="group_notice_footer">
                <Button
                    className="close-btn"
                    onClick={() => {
                        setVisible(false);
                        setTimeout(() => {
                            removeNodeEl(el);
                        }, 100);
                    }}
                >
                    取消
                </Button>
                <Button
                    type="primary"
                    disabled={content?.trim().length === 0}
                    loading={confirmLoading}
                    onClick={handleOK}
                >
                    发布
                </Button>
            </div>
        );
    }
    return (
        <Drawer
            title={announceDetail ? '编辑公告' : t('detail.announce.title')}
            width="506"
            footer={GetButtons(el)}
            destroyOnClose
            bodyStyle={{ height: 334, padding: '16px 24px' }}
            visible={visible}
            // cancelText={t('cancel')}
            onClose={() => {
                setVisible(false);
                removeNodeEl(el);
            }}
        >
            <div className="pm-create-announce-wrap">
                <Form labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                    <Form.Item label="描述：">
                        {isFocusEnv() ? (
                            <CopyFrame>
                                <div className="pm-create-announce-item">
                                    <TextArea
                                        rows={6}
                                        id="TextArea"
                                        value={content}
                                        style={{
                                            resize: 'none',
                                            borderColor:
                                                ContentFouse === true
                                                    ? (window as any).styleSass.primaryColor
                                                    : '#d8dad9',
                                        }}
                                        maxLength={contentLength}
                                        onChange={contentChange}
                                        placeholder={t(
                                            'detail.announce.create_announce_placeholder'
                                        )}
                                        onFocus={() => setContentFouse(true)}
                                        onBlur={() => setContentFouse(false)}
                                    />
                                    <span
                                        className="pm-create-announce-wordcount"
                                        style={{
                                            borderColor:
                                                ContentFouse === true
                                                    ? (window as any).styleSass.primaryColor
                                                    : '#d8dad9',
                                        }}
                                    >
                                        {content !== '' && (
                                            <span
                                                style={{
                                                    margin: '0 10px',
                                                    position: 'absolute',
                                                    left: '0',
                                                    color: (window as any).styleSass.primaryColor,
                                                }}
                                                onClick={(e) => {
                                                    doCopy(e);
                                                }}
                                            >
                                                <IconFont type="iconapp_btn_copy" /> 复制
                                            </span>
                                        )}

                                        <span
                                            style={{
                                                color: (window as any).styleSass.primaryColor,
                                            }}
                                        >
                                            {content.trimStart().length}
                                        </span>
                                        <span style={{ color: '#8F959E' }}>/500</span>
                                    </span>
                                </div>
                            </CopyFrame>
                        ) : (
                            <div className="pm-create-announce-item">
                                <TextArea
                                    rows={6}
                                    value={content}
                                    style={{
                                        resize: 'none',
                                        borderColor:
                                            ContentFouse === true
                                                ? (window as any).styleSass.primaryColor
                                                : '#d8dad9',
                                    }}
                                    maxLength={contentLength}
                                    onChange={contentChange}
                                    placeholder={t('detail.announce.create_announce_placeholder')}
                                    onFocus={() => setContentFouse(true)}
                                    onBlur={() => setContentFouse(false)}
                                />
                                <span
                                    className="pm-create-announce-wordcount"
                                    style={{
                                        borderColor:
                                            ContentFouse === true
                                                ? (window as any).styleSass.primaryColor
                                                : '#d8dad9',
                                    }}
                                >
                                    {content && (
                                        <span
                                            style={{
                                                margin: '0 10px',
                                                position: 'absolute',
                                                left: '0',
                                                color: (window as any).styleSass.primaryColor,
                                            }}
                                            onClick={(e) => {
                                                doCopy(e);
                                            }}
                                        >
                                            <IconFont type="iconapp_btn_copy" /> 复制
                                        </span>
                                    )}

                                    <span
                                        style={{
                                            color:
                                                content.length === 0
                                                    ? ''
                                                    : (window as any).styleSass.primaryColor,
                                        }}
                                    >
                                        {content.trimStart().length}
                                    </span>
                                    <span style={{ color: '#8F959E' }}>/500</span>
                                </span>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label="附件：">
                        <div>
                            <UploadImg
                                globalcontext={globalcontext}
                                imgs={originUrl}
                                uploadCallback={(imgs) => {
                                    imgsRef.current = imgs;
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '6px',
                                    left: '92px',
                                    color: '#BFC1C4',
                                }}
                            >
                                最多支持添加10张图片～
                            </span>
                        </div>
                    </Form.Item>
                </Form>

                {/* <span
                    className="pm-create-announce-wordcount"
                    style={{
                        borderColor: ContentFouse === true ? (window as any).styleSass.primaryColor : '#d8dad9',
                    }}
                >
                    <span style={{ color: content.length === 0 ? '' : ' #31c4cc' }}>
                        {content.trimStart().length}
                    </span>
                    <span>/500</span>
                </span> */}
            </div>
        </Drawer>
    );
}

const removeNodeEl = (el: any) => {
    if (!el) {
        return;
    }
    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(el);
        el.remove();
    }, 100);
};

export default function (props: Iprops) {
    const el = document.createElement('div');
    const onclose = function () {
        removeNodeEl(el);
    };
    ReactDOM.render(<CreateAnnounce {...props} el={el} onclose={onclose} />, el);
}
