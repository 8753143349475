/**
 * @author sunzhiguang
 * @date 2020/7/10
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ChatMessage,
    ChatMessageRespBody,
    FileStatus,
    MessageStatus,
    SessionType,
} from '@/types/chat';
import ImService from '@/server/ImService';
import { MessageSendType } from '@/types/chat/enum';
import { LoadingOutlined } from '@ant-design/icons';

import { Spin, Tooltip } from 'antd';
import IconFont from '@/components/icon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import './index.less';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';

import config, { ChatConfig } from '@/config/config';
import EventBus from '@/utils/bus';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import omit from 'lodash/omit';
import {
    isEqualEmployee,
    isOuterContacts,
    isReadMessage,
    isRevokeMessage,
    sleep,
} from '@/utils/chat/message';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { debounce } from 'lodash';

interface MessageControlProps {
    sendType: MessageSendType;
    sessionType: SessionType;
    message: ChatMessage & ChatMessageRespBody;
    beginSend: boolean;
    messageExtend: any;
    onSendStatus: Function;
}

interface DvaDispatchProps {
    updateSessionMessageMid: Function;
    clearMessageUploadState: Function;
    clearTempMessage: Function;
    updateTempMessageStatus: Function;
}

type IProps = Readonly<MessageControlProps & DvaDispatchProps & ChatState>;

interface IState {
    status: MessageStatus;
}

const MessageControl = (props: IProps) => {
    const [status, setStatus] = useState(MessageStatus.INIT);
    const { t } = useTranslation('chat');
    // const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

    const {
        sessionType,
        sendType,
        message,
        beginSend,
        messageExtend,
        updateSessionMessageMid,
        onSendStatus,
        clearMessageUploadState,
        clearTempMessage,
        messageUploadStateMap,
        currentEmployee,
        selectedSession,
        connectState,
        updateTempMessageStatus,
        sessionMessageMap,
    } = props;
    const messageUploadState = useMemo(() => {
        return messageUploadStateMap[message.id] || null;
    }, [message, messageUploadStateMap]);

    const getReadInfo = (isRead: boolean) => {
        // const isRead = isReadMessage(message, currentEmployee);
        return {
            iconType: !isRead ? 'iconapp_btn_radio_normal' : 'iconic_complete_2',
            text: !isRead ? t('unRead') : t('read'),
        };
    };

    useEffect(() => {
        if (message.status && connectState.state !== ConnectState.READY) {
            if (message.task && message.task.state !== FileStatus.COMPLETED) {
                message.task.cancel();
            }
            setStatus(MessageStatus.FAILED);
        } else if (
            message.status === MessageStatus.FAILED ||
            message.statusType === MessageStatus.FAILED
        ) {
            setStatus(MessageStatus.FAILED);
        }
    }, [message, connectState, status]);

    const readStatusEnable = config[ChatConfig.CHAT_MESSAGE_STATUS_READ_UNREAD_ENABLE];
    const getMessageState = () => {
        // eslint-disable-next-line no-console
        if (!readStatusEnable) {
            return null;
        }
        if (isRevokeMessage(message)) {
            return null;
        }
        // if (selectedSession.isSingle) {
        //     const info = selectedSession.info as Employee;
        //     if (info.app !== currentEmployee.app) {
        //         return null;
        //     }
        // }
        // 外部联系人 去掉已读未读状态
        if (isOuterContacts(selectedSession, currentEmployee)) {
            return null;
        }
        if (sendType !== MessageSendType.SELF && sendType === MessageSendType.OWNER) {
            const lastMsg = selectedSession.lastMsg || {};
            const isRead = isReadMessage(message, currentEmployee, lastMsg);
            // eslint-disable-next-line no-console
            const { iconType, text } = getReadInfo(isRead);
            const getClassNames = () => {
                return classNames({
                    'status-icon': true,
                    'read-icon': isRead,
                    'unread-icon': !isRead,
                });
            };
            return (
                <div className="message-status">
                    <IconFont className={getClassNames()} type={iconType} />
                    <div className="word read">{text}</div>
                </div>
            );
        }
    };
    const clearMessage = useCallback(() => {
        if (message.type === ChatMessageType.FILE) {
            // 如果是文件的消息 需要消掉上传时的辅助数据
            delete message.taskId;
            delete message.task;
        }
        clearTempMessage({ msgId: message.id, sessionId: message.sessionId });
        // clearMessageUploadState({ msgId: message.id });
    }, [message, clearTempMessage]);

    useEffect(() => {
        if (status !== MessageStatus.INIT) {
            onSendStatus(status);
        }
    }, [status, onSendStatus]);

    const deleteAttr = useCallback((message: any) => {
        delete message.status;
        delete message.statusType;
        if (message.task) {
            // delete message.taskId;
            // delete message.task;
        }
    }, []);

    const sendMessage = useCallback(() => {
        // if (connectState.state !== ConnectState.READY) {
        //     setStatus(MessageStatus.FAILED);
        //     updateTempMessageStatus({
        //         status: MessageStatus.FAILED,
        //         id: message.id,
        //         sessionId: selectedSession.sessionId,
        //     });
        //     return;
        // }
        // 删除不需要的属性
        deleteAttr(message);
        const messageId = message.id;
        const sessionId = message.sessionId;
        const messageBody = omit(cloneDeep(message), [
            'status',
            'taskId',
            'task',
            'mid',
            'beforeMid',
            'sessionId',
            'groupId',
            'statusType',
            '_id',
            '_rev',
            'belong',
            'datetime',
            'mid',
            'localSendTimestamp',
            'readNum',
            'readUids',
        ]) as any;
        if (messageBody.type === ChatMessageType.FILE) {
            delete messageBody.id;
        }
        if (messageExtend) {
            Object.assign(messageBody, messageExtend);
        }
        const imService = ImService.getInstance();
        imService.sendChatMessage(sessionId, messageId, messageBody).then((result) => {
            if (result?.mid && result.id === messageId) {
                setStatus(MessageStatus.SUCCESS);
                // // 发送完成 获得上传结果 更新 tempSessionMessage  sessionMessage
                updateSessionMessageMid({
                    sessionId: messageBody.sessionId,
                    sendResult: result,
                });
                clearMessage();
            } else {
                setStatus(MessageStatus.FAILED);
                updateTempMessageStatus({
                    status: MessageStatus.FAILED,
                    id: messageBody.id,
                    sessionId: selectedSession.sessionId,
                });
            }
        });
    }, [
        selectedSession.sessionId,
        deleteAttr,
        message,
        messageExtend,
        updateTempMessageStatus,
        clearMessage,
        updateSessionMessageMid,
    ]);

    useEffect(() => {
        if (beginSend && (status === MessageStatus.SENDING || status === MessageStatus.UPLOADING)) {
            setStatus(MessageStatus.SENDING);
            sendMessage();
            return;
        } else if (!beginSend) {
            if (message.task && message.task.state === FileStatus.FAILED) {
                setStatus(MessageStatus.FAILED);
            } else if (message.status === MessageStatus.INIT) {
                setStatus(MessageStatus.SENDING);
            }
            return;
        } else if (typeof message.mid === 'undefined') {
            setStatus(MessageStatus.FAILED);
            return;
        }
    }, [beginSend, messageExtend]); // eslint-disable-line

    const sendMessageAgain = debounce(() => {
        Prompt({
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            title: t('send-message-again'),
            cancelText: t('cancelText'),
            onOk: async () => {
                setStatus(MessageStatus.SENDING);
                await sleep(500);
                if (message.task && message.task.state !== FileStatus.COMPLETED) {
                    // 如果没有上传完文件 先处理上传文件
                    if (connectState.state === ConnectState.READY) {
                        message.task.cancel().then(() => {
                            message.task.start().then(() => {
                                setStatus(MessageStatus.UPLOADING);
                            });
                        });
                    } else {
                        setStatus(MessageStatus.FAILED);
                    }
                } else {
                    sendMessage();
                }
            },
            okText: t('determine'),
        });
    }, 200);

    const listener = useCallback(
        (param: { uploadStatus: FileStatus }) => {
            if (param.uploadStatus === FileStatus.UPLOADING) {
                setStatus(MessageStatus.SENDING);
                if (connectState.state !== ConnectState.READY) {
                    sleep(500).then(() => {
                        setStatus(MessageStatus.FAILED);
                    });
                }
            } else if (param.uploadStatus === FileStatus.CANCELED) {
                setStatus(MessageStatus.CANCELED);
            }
        },
        [connectState.state]
    );

    useEffect(() => {
        EventBus.on(`chat:file-upload-status:${message.id}`, listener);
        return () => {
            EventBus.off(`chat:file-upload-status:${message.id}`, listener);
        };
    }, [listener, message.id]);

    useEffect(() => {
        if (message.taskId && message.task && messageUploadState) {
            const task = message.task;
            switch (task.state) {
                case FileStatus.FAILED:
                    setStatus(MessageStatus.FAILED);
                    break;
                case FileStatus.CANCELED:
                    setStatus(MessageStatus.FAILED);
                    break;
                case FileStatus.UPLOADING:
                    setStatus(MessageStatus.SENDING);
                    break;
            }
        }
    }, [message, messageUploadState]);

    if (sendType === MessageSendType.OTHER) {
        return null;
    }

    const getContent = () => {
        if (sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF) {
            if (status === MessageStatus.SENDING) {
                // 去掉上传图片转圈问题
                // return (
                //     <Spin
                //         spinning={status === MessageStatus.SENDING}
                //         size="small"
                //         delay={5}
                //         indicator={antIcon}
                //         style={{
                //             width: '14px',
                //             height: '14px',
                //             lineHeight: '14px',
                //             marginTop: '-14px',
                //         }}
                //     />
                // );
            } else if (status === MessageStatus.CANCELED) {
                return null;
            } else if (
                status === MessageStatus.FAILED &&
                message.statusType !== MessageStatus.SUCCESS
            ) {
                return (
                    <Tooltip title={t('send-again')}>
                        <IconFont
                            type="iconic_failure"
                            className="failure_icon"
                            style={{ color: '#F5222D' }}
                            onClick={sendMessageAgain}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|22"
                        />
                    </Tooltip>
                );
            } else if (sendType === MessageSendType.OWNER) {
                return (
                    [SessionType.SINGLE, SessionType.SECRET_SINGLE].includes(sessionType) &&
                    getMessageState()
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };
    return (
        <div
            className={
                // 群组、是自己发送的文件，icon位置调整
                selectedSession.isGroup &&
                message.type === ChatMessageType.FILE &&
                isEqualEmployee(message.sender, currentEmployee)
                    ? 'group-file message-control-container'
                    : 'message-control-container'
            }
        >
            {getContent()}
        </div>
    );
};

function mapStateToProps({ chat }: { chat: ChatState }) {
    const {
        selectedSession,
        messageUploadStateMap,
        currentEmployee,
        connectState,
        sessionMessageMap,
    } = chat;
    return {
        selectedSession,
        messageUploadStateMap,
        currentEmployee,
        connectState,
        sessionMessageMap,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        clearTempMessage(data: { msgId: string }) {
            dispatch({ type: 'chat/clearTempMessage', payload: data });
        },
        clearMessageUploadState(data: { msgId: string }) {
            dispatch({ type: 'chat/clearMessageUploadState', payload: data });
        },
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateTempMessageStatus(data: { status: MessageStatus; sessionId: String; id: string }) {
            dispatch({ type: 'chat/updateTempMessageStatus', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageControl);
