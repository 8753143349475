import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Input } from 'antd';
import { useTranslation } from '../../context';
import { throttle } from 'lodash';
import { kMaxLength } from 'buffer';

interface Props {
    value?: string;
    title?: string;
    okText?: string;
    cancelText?: string;
    maxLength?: number;
    onOk: (s: string, close: () => void) => void;
    width?: number;
}

export default function ModalTextBox(props: Props) {
    const lang = useTranslation();
    const [visible, setVisible] = React.useState(true);
    const [value, setValue] = React.useState(props.value ?? '');
    const { maxLength = 20 } = props;

    return (
        <Modal
            title={props.title}
            visible={visible}
            okText={props.okText ?? lang('common.confirm')}
            cancelText={props.cancelText ?? lang('common.cancel')}
            onCancel={() => setVisible(false)}
            width={props.width ?? 400}
            destroyOnClose
            onOk={throttle(() => {
                const _value = value.replace(/^\s+|\s+$/g, '');
                if (_value !== '') {
                    props.onOk(_value, () => {
                        setVisible(false);
                    });
                }
            }, 1000)}
        >
            <Input
                type="text"
                maxLength={maxLength}
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <p className="form-item-length" style={{ marginBottom: 20, textAlign: 'right' }}>
                {value.length}/{maxLength}
            </p>
        </Modal>
    );
}

export function openModalTextBox(props: Props) {
    ReactDOM.render(<ModalTextBox {...props} />, document.createDocumentFragment());
}
