import React from 'react';
import { Loading } from '@jd/focus-desktop-comps';
import styles from '@/components/styles';
import TabItem from './TabItem';
import { BrowserViewContext, BrowserViewItem } from './utils';
// import logo from '@/components/icon/logo16x16.png';
import { useTranslation } from 'react-i18next';
// import { getDefaultPageFavicon } from '@/utils/oss';
import thirdAppAnalyticsMap from '@/utils/thirdAppAnalyticsMap';
import { changeEgovUrl } from '@/utils/tools';
import favIco from '../../assets/templateImgs/logo.png';
function TabIcon({ src }: { src: string }) {
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        if (loaded) {
            return;
        }
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setLoaded(true);
        };
    }, [src, loaded]);

    if (!loaded) {
        // const favicon = getDefaultPageFavicon();
        return <img width={16} height={16} src={favIco} alt="" style={{ borderRadius: 5 }} />;
    }

    return <img width={16} height={16} src={changeEgovUrl(src)} alt="" />;
}

export default function Tabs(props: { onTabChange: (id: string) => void }) {
    const { onWindowClose, items, selectedId, defaultPage } = React.useContext(BrowserViewContext);
    const { onTabChange } = props;
    const { t } = useTranslation('common');
    let startupPage = null;
    if (defaultPage) {
        const { title, icon, id } = defaultPage;
        startupPage = (
            <TabItem
                icon={icon}
                name={title}
                minWidth={150}
                active={selectedId === defaultPage.id}
                withoutClose
                onClick={() => {
                    onTabChange(id);
                }}
            />
        );
    }
    return (
        <div
            className="me-thirdapp-tabs-header"
            style={{
                height: 33,
                backgroundColor: '#F6F8FA',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflow: 'auto',
            }}
        >
            {startupPage}
            {/* <TabItem
        icon={IconFont('iconapp_tabbar_application')}
        name="应用中心"
        active={!activeId}
        withoutClose
        onClick={() => {
          onTabChange('');
        }}
      /> */}
            {items.map((item: BrowserViewItem) => (
                <TabItem
                    icon={
                        item.icon ? (
                            <TabIcon src={changeEgovUrl(item.icon)} />
                        ) : (
                            <Loading size={16} />
                        )
                    }
                    key={item.id}
                    name={item.name || t('browserView.defaultTabTitle')}
                    active={item.id === selectedId}
                    clstag={thirdAppAnalyticsMap[item.appKey || '']?.close}
                    onItemClose={() => {
                        if (onWindowClose) {
                            onWindowClose(item.id);
                        }
                    }}
                    onClick={() => {
                        onTabChange(item.id);
                    }}
                />
            ))}
        </div>
    );
}
