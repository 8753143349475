// 群公告列表
import React, { useContext, useCallback, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Button, Spin, Empty, message as toast } from 'antd';
import { getAnnounceListService, deleteAnnounceService } from '@/components/Projects/api/index';
import createGroupNotice from './createGroupNotice';
import GroupNoticeItem from './GroupNoticeItem';
import GroupNoticeDetail from './GroupNoticeDetail';
import bus from '@/utils/bus';
import { time } from '../../util';
import './index.less';
import noticeImg from '@/assets/img/empty/im-empty.png';

let $projectId: any;
let $globalcontext: any;
let $t: any;

enum Level {
    administrator = 1, // 创建者
    sub_administrator = 2, // 管理员
    ordinary = 3, // 组成员
}

// 添加
const addGroupNotice = () => {
    createGroupNotice({
        projectId: $projectId,
        globalcontext: $globalcontext,
        t: $t,
    });
};
// 编辑
const editGroupNotice = (parames: any) => {
    const { content, announcementId } = parames;
    if (!announcementId) {
        return;
    }
    createGroupNotice({
        projectId: $projectId,
        globalcontext: $globalcontext,
        t: $t,
        pageType: 'edit',
        announceDetail: { announcementId, content },
    });
};
// 删除
const removeGroupNotice = (announcementId: any) => {
    if (!announcementId) {
        return;
    }
    deleteAnnounceService($projectId, announcementId, 'Group_Announcement').then((res) => {
        bus.emit('groupNoticeList:updata');
        toast.success('公告已删除');
    });
};
// 渲染底部按钮
export function GetButtons(setVisible: any, level: any) {
    if (level === 1 || level === 2) {
        return (
            <div className="group_notice_footer">
                <Button className="close-btn" onClick={() => setVisible(false)}>
                    取消
                </Button>
                <Button type="primary" className="btn-bg" onClick={addGroupNotice}>
                    新建
                </Button>
            </div>
        );
    }
    return null;
}

function GroupNoticeDrawerList(props: any) {
    const { prentVisible, projectId, globalcontext, t, level, el, session } = props;
    // 更新全局变量
    $projectId = projectId;
    $globalcontext = globalcontext;
    $t = t;

    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isBottom, setIsBottom] = useState<boolean>(false);
    const [list, setList] = useState<[]>([]);
    // 滚动条
    const bindHandleScroll = useCallback(
        (event) => {
            if (event.target.id !== 'group-notice_main_scroll' && event.target.nodeName !== 'DIV') {
                return;
            }
            const clientHeight = event.target.clientHeight;
            const scrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            // 当滚动条距离底部还有100, 触发触底
            const _isBottom = scrollHeight <= clientHeight + scrollTop + 100;
            if (isBottom !== _isBottom) {
                setIsBottom(true);
            }
            console.log('滚动条到底', isBottom, _isBottom);
        },
        [isBottom, setIsBottom]
    );

    // 获取公告列表方法
    const getAnnounceList = useCallback(() => {
        getAnnounceListService(projectId, 0, 100, 'Group_Announcement')
            .then((res) => {
                if (res.announcementLists && res.announcementLists.length) {
                    for (let item of res.announcementLists) {
                        item.pubTime = time.format(item.pubTime);
                    }
                    setList(res.announcementLists);
                } else {
                    setList([]);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [projectId]);

    const divEl = useRef(null);
    useEffect(() => {
        setVisible(prentVisible);
        if (!divEl.current) {
            // 第一次请求公告列表
            getAnnounceList();
            // 更新列表
            bus.off('groupNoticeList:updata', getAnnounceList).on(
                'groupNoticeList:updata',
                getAnnounceList
            );
            // 编辑
            bus.off('editGroupNotice:updata', editGroupNotice).on(
                'editGroupNotice:updata',
                editGroupNotice
            );
            // 删除
            bus.off('removeGroupNotice:updata', removeGroupNotice).on(
                'removeGroupNotice:updata',
                removeGroupNotice
            );
            return () => {
                bus.off('groupNoticeList:updata', getAnnounceList);
                bus.off('editGroupNotice:updata', editGroupNotice);
                bus.off('removeGroupNotice:updata', removeGroupNotice);
            };
        }
    }, [prentVisible, getAnnounceList, bindHandleScroll]);
    // 抽屉
    return (
        <Drawer
            title="公告"
            width="500"
            visible={visible}
            footer={GetButtons(setVisible, Level[level])}
            className="group_notice_body"
            onClose={() => {
                setVisible(false);
                removeNodeEl(el);
            }}
        >
            <div className="group_notice_main" id="group-notice_main_scroll">
                {loading && !list.length && (
                    <div className="example">
                        <Spin spinning={loading} />
                    </div>
                )}
                {/* 加载结束并且有数据 */}
                {!loading &&
                    list.length > 0 &&
                    list.map((item: any, index) => (
                        <div
                            key={index}
                            style={{ marginBottom: index === list.length - 1 ? '0px' : '12px' }}
                            onClick={() =>
                                GroupNoticeDetail({
                                    projectId,
                                    announcementId: item.announcementId,
                                    userRole: 4,
                                    archive: false,
                                    t,
                                    globalcontext,
                                    level: Level[level],
                                    session: session,
                                })
                            }
                        >
                            <GroupNoticeItem
                                item={item}
                                t={t}
                                projectId={projectId}
                                level={Level[level]}
                            />
                        </div>
                    ))}
                {/* 加载结束并且无数据展示 */}
                {!loading && !list.length && (
                    <Empty
                        image={noticeImg}
                        imageStyle={{
                            width: 120,
                            height: 120,
                        }}
                        description={<span>暂无公告</span>}
                    />
                )}
                {/* {!isBottom && <p style={{ color: '#BFC1C4', textAlign: 'center' }}>已经到底了～</p>} */}
            </div>
        </Drawer>
    );
}

const removeNodeEl = (el: any) => {
    if (!el) {
        return;
    }
    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(el);
        el.remove();
    }, 100);
};

export default function (props: any) {
    const el = document.createElement('div');
    const onclose = function () {
        removeNodeEl(el);
    };
    ReactDOM.render(<GroupNoticeDrawerList {...props} el={el} onclose={onclose} />, el);
}
