module.exports = [
  {
    "config": {
      "ui": {
        "links": {
          "service": "https://jdxtbg.tjjn-datalake.com/protocol/service/chinese/",
          "privacy": "https://jdxtbg.tjjn-datalake.com/protocol/privacy/chinese/",
          "downloadUrl": "https://jdxtbg.tjjn-datalake.com/download"
        },
        "enableDu": true,
        "onlyMobileLogin": false,
        "canVipLogin": false,
        "canCreateTeam": false,
        "canJoinTeam": false,
        "isSpecialProjects": true,
        "isGovEbook": true,
        "enableFeedback": true,
        "enableJoyspace": true,
        "canRegister": false,
        "ebook": {
          "navs": [
            "new_contacts",
            "external_contacts",
            "special_projects",
            "group",
            "org",
            "my_department"
          ],
          "search": {
            "addContract": false,
            "addGroup": true
          }
        },
        "layout": {
          "nav": [
            {
              "role": "messages",
              "icon": "iconic_chat",
              "name": "layout.nav.messages"
            },
            {
              "role": "ebook",
              "icon": "iconapp_btn_addressbook",
              "name": "layout.nav.ebook"
            },
            {
              "path": "/projects",
              "role": "route",
              "icon": "iconbacklog",
              "name": "layout.nav.joywork"
            },
            {
              "role": "calendar",
              "icon": "iconic_day",
              "name": "layout.nav.calendar"
            },
            {
              "path": "/joyspace",
              "role": "route",
              "icon": "iconapp_ic_bardoc",
              "name": "layout.nav.joyspace"
            },
            {
              "role": "thirdApp",
              "icon": "iconapp_tabbar_application",
              "name": "layout.nav.thirdApp"
            }
          ]
        },
        "theme": "blue",
        "login": {
          "adCarousel": [
            "/statics/images/theme/blue/leftCenter.png"
          ],
          "description": "login.desc",
          "title": "login.title",
          "leftTopImg": "/statics/images/theme/blue/leftTop.png",
          "leftImg": "/statics/images/theme/blue/login_left.png",
          "leftBottomImg": "/statics/images/theme/blue/leftBottom.png"
        }
      },
      "environments": [
        {
          "app": {
            "entry": "https://jdxtbg.tjjn-datalake.com/client",
            "feedUrl": "https://jzb-oss.com/installer_releases/jdcloud_beta_x64/",
            "feedUrl32": "https://jzb-oss.com/installer_releases/jdcloud_beta_ia32/",
            "forceUpgradeVersion": "1.0.0",
            "appStoreAppKey": "b96a8ab3197544e68c9919667afe9f41",
            "withCALogin": true,
            "CALoginUrl": "https://ca.jzb.beijing.gov.cn/am/oauth2/authorize?service=initService&response_type=code&client_id=64weabwnbxxi2dsv4qxl&scope=all&redirect_uri=https://jzb.beijing.gov.cn/login/ca-callback&model=cs&decision=Allow&hotpFowardModel=mobile"
          },
          "webConfig": {
            "routeBase": "/client",
            "ssoLoginUrl": "https://jdxtbg.tjjn-datalake.com/passport/",
            "ssoClientId": "4d571c78c9df4830b0505b3b03dfbce5",
            "qrLoginUrl": "https://jdxtbg.tjjn-datalake.com/passport/"
          },
          "joyspace": {
            "protocol": "https",
            "wpsTarget": "private",
            "cookie": {
              "domain": ".tjjn-datalake.com"
            },
            "host": "jdxtbg.tjjn-datalake.com/joyspace",
            "lowCode": {
              "createUrl": "https://joybuilder.jzb.beijing.gov.cn/#/form/create"
            }
          },
          "fileService": {
            "appKey": "41d47591b3adf037",
            "hostOrigin": "https://jdxtbg.tjjn-datalake.com/eefs",
            "joyspaceAppKey": "966df28278a65b20"
          },
          "joyspaceApi": {
            "protocol": "https",
            "host": "jdxtbg.tjjn-datalake.com/joyspace"
          },
          "open": {
            "protocol": "https",
            "host": "jdxtbg.tjjn-datalake.com/open"
          },
          "zoom": {
            "domain": "https://jdmeeting.jd.com",
            "api": "https://api-external-joymeeting.jd-eit.com/kk/tellme",
            "sdkId": "OwcSrKQSfCpGZyf3y7YncWDAzPxhAz1j",
            "sk": "8hRfOqen*x9X3jz2KWhTI#0aAo%oe4We"
          },
          "hwlink": {
            "serverAddress": "114.255.186.218",
            "httpsServerPort": "8443",
            "serverPort": "5061",
            "model": 1
          },
          "voip": {
            "domain": "jdxtbg.tjjn-datalake.com",
            "port": "10002"
          },
          "im": {
            "tls": true,
            "host": "jdxtbg.tjjn-datalake.com/im-ws/",
            "apHost": "jdxtbg.tjjn-datalake.com:10001",
            "apPort": 10001
          },
          "whiteList": [
            "jzb.beijing.gov.cn",
            "jzb.beijing.egov.cn",
            "jdcloud.com",
            "jd_third.com",
            "jd.com"
          ],
          "jbrtc": {
            "meetApiUrl": "https://jdxtbg.tjjn-datalake.com/jrtc-meeting-api",
            "rtcServerUrl": "https://jdxtbg.tjjn-datalake.com/jrtc-internal-api"
          },
          "helpUrl": "https://jzb.beijing.gov.cn/helper",
          "xylink": {
            "extID": "d8e08778cd33b3ae6860f1b13e9f1a68ca2f7d08",
            "proxy": "114.255.186.212:7443"
          },
          "name": "生产环境",
          "me": {
            "mainEntry": {
              "protocol": "https",
              "host": "jdxtbg.tjjn-datalake.com"
            }
          },
          "key": "prod",
          "gateway": {
            "protocol": "https",
            "appId": "hr3feu3hHPiQXL1fitO2",
            "webAppId": "94XNeuscQUY2Yj4E0xtm",
            "host": "jdxtbg.tjjn-datalake.com/janus",
            "stage": "PROD"
          },
          "aiConfig": {
            "aiUser": {
              "pin": "aiRobot",
              "teamId": "focus.bot",
              "app": "dd.notice",
              "name": "AI助手",
              "nickName": "AI助手",
              "avatar": "https://jdxtbg.tjjn-datalake.com/statics/icons/icon-ai-robot.png"
            },
            "filterTabList": [
              {
                "pin": "~me69",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "日程"
              },
              {
                "pin": "eopen-push_PZzzvgDrpZVAefKImEfo",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "任务"
              },
              {
                "pin": "~joyspace",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "云空间"
              }
            ],
            "tabList": [
              {
                "pin": "~me69",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "日程"
              },
              {
                "pin": "eopen-push_PZzzvgDrpZVAefKImEfo",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "任务"
              },
              {
                "pin": "~joyspace",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "云文档"
              },
              {
                "pin": "eopen-push_0Gl8b1I3si21n0VVNkrF",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "催办"
              },
              {
                "pin": "eopen-push_qhTSqJ4pYYjuK7ZlYXg5",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "组通知"
              },
              {
                "pin": "other-notice",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "其他"
              }
            ],
            "ver": 6
          }
        },
        {
          "app": {
            "entry": "https://jzb-pre.jdcloud.com/client",
            "feedUrl": "https://jzb-oss.com/installer_releases/jdcloud_beta_x64/",
            "feedUrl32": "https://jzb-oss.com/installer_releases/jdcloud_beta_ia32/",
            "forceUpgradeVersion": "1.0.0",
            "appStoreAppKey": "b96a8ab3197544e68c9919667afe9f41",
            "withCALogin": true,
            "CALoginUrl": "http://223.70.139.221:1993/am/oauth2/authorize?service=initService&amp;response_type=code&amp;client_id=d73l43gdvstsi8mx2iqs&amp;scope=all&amp;redirect_uri=https://jzb-pre.jdcloud.com/login/ca-callback&amp;model=cs&amp;decision=Allow&amp;hotpFowardModel=mobile"
          },
          "webConfig": {
            "routeBase": "/client",
            "ssoLoginUrl": "https://jzb-pre.jdcloud.com/passport/",
            "ssoClientId": "4d571c78c9df4830b0505b3b03dfbce5",
            "qrLoginUrl": "https://me-pre.jdcloud.com/passport/"
          },
          "joyspace": {
            "protocol": "https",
            "wpsTarget": "private",
            "cookie": {
              "domain": ".jdcloud.com"
            },
            "host": "jzb-joyspace-pre.jdcloud.com",
            "lowCode": {
              "createUrl": "https://jzb-joybuilder-pre.jdcloud.com/#/form/create"
            }
          },
          "fileService": {
            "appKey": "41d47591b3adf037",
            "hostOrigin": "https://jzb-eefs-pre.jdcloud.com",
            "joyspaceAppKey": "966df28278a65b20"
          },
          "joyspaceApi": {
            "protocol": "https",
            "host": "jzb-apijoyspace-pre.jdcloud.com"
          },
          "open": {
            "protocol": "https",
            "host": "jzb-open-pre.jdcloud.com"
          },
          "zoom": {
            "domain": "https://jdmeeting.jd.com",
            "api": "https://api-external-joymeeting.jd-eit.com/kk/tellme",
            "sdkId": "OwcSrKQSfCpGZyf3y7YncWDAzPxhAz1j",
            "sk": "8hRfOqen*x9X3jz2KWhTI#0aAo%oe4We"
          },
          "hwlink": {
            "serverAddress": "114.255.186.218",
            "httpsServerPort": "8443",
            "serverPort": "5061",
            "model": 1
          },
          "voip": {
            "domain": "jzb-rtc-ap-pre.jdcloud.com",
            "port": "443"
          },
          "im": {
            "tls": true,
            "host": "jzb-im-ws-pre.jdcloud.com",
            "apHost": "jzb-im-ap-pre.jdcloud.com",
            "apPort": 10001
          },
          "name": "应用接入环境",
          "me": {
            "mainEntry": {
              "protocol": "https",
              "host": "jzb-pre.jdcloud.com"
            }
          },
          "key": "beta",
          "gateway": {
            "protocol": "https",
            "appId": "hr3feu3hHPiQXL1fitO2",
            "webAppId": "94XNeuscQUY2Yj4E0xtm",
            "host": "jzb-janus-pre.jdcloud.com",
            "stage": "PRE"
          },
          "aiConfig": {
            "aiUser": {
              "pin": "aiRobot",
              "teamId": "focus.bot",
              "app": "dd.notice",
              "name": "AI 助手",
              "nickName": "AI 助手",
              "avatar": "https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/statics/icons/icon-ai-robot.png"
            },
            "tabList": [
              {
                "pin": "~me69",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "日程"
              },
              {
                "pin": "eopen-push_PZzzvgDrpZVAefKImEfo",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "任务"
              },
              {
                "pin": "~joyspace",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "云空间"
              },
              {
                "pin": "eopen-push_0Gl8b1I3si21n0VVNkrF",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "提醒"
              },
              {
                "pin": "eopen-push_qhTSqJ4pYYjuK7ZlYXg5",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "组通知"
              },
              {
                "pin": "other-notice",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "其他"
              }
            ],
            "ver": 3
          }
        },
        {
          "app": {
            "entry": "https://csxtbg.tjjn-datalake.com:12345/client",
            "feedUrl": "https://jzb-oss.com/installer_releases/jdcloud_beta_x64/",
            "feedUrl32": "https://jzb-oss.com/installer_releases/jdcloud_beta_ia32/",
            "forceUpgradeVersion": "1.0.0",
            "appStoreAppKey": "b96a8ab3197544e68c9919667afe9f41",
            "withCALogin": true,
            "CALoginUrl": "https://ca.jzb.beijing.gov.cn/am/oauth2/authorize?service=initService&response_type=code&client_id=64weabwnbxxi2dsv4qxl&scope=all&redirect_uri=https://jzb.beijing.gov.cn/login/ca-callback&model=cs&decision=Allow&hotpFowardModel=mobile"
          },
          "webConfig": {
            "routeBase": "/client",
            "ssoLoginUrl": "https://csxtbg.tjjn-datalake.com:12345/passport/",
            "ssoClientId": "4d571c78c9df4830b0505b3b03dfbce5",
            "qrLoginUrl": "https://csxtbg.tjjn-datalake.com:12345/passport/"
          },
          "joyspace": {
            "protocol": "https",
            "wpsTarget": "private",
            "cookie": {
              "domain": ".tjjn-datalake.com"
            },
            "host": "csxtbg.tjjn-datalake.com:12345/joyspace",
            "lowCode": {
              "createUrl": "https://joybuilder.jzb.beijing.gov.cn/#/form/create"
            }
          },
          "fileService": {
            "appKey": "41d47591b3adf037",
            "hostOrigin": "https://csxtbg.tjjn-datalake.com:12345/eefs",
            "joyspaceAppKey": "966df28278a65b20"
          },
          "joyspaceApi": {
            "protocol": "https",
            "host": "csxtbg.tjjn-datalake.com:12345/joyspace"
          },
          "open": {
            "protocol": "https",
            "host": "csxtbg.tjjn-datalake.com:12345/open"
          },
          "zoom": {
            "domain": "https://jdmeeting.jd.com",
            "api": "https://api-external-joymeeting.jd-eit.com/kk/tellme",
            "sdkId": "OwcSrKQSfCpGZyf3y7YncWDAzPxhAz1j",
            "sk": "8hRfOqen*x9X3jz2KWhTI#0aAo%oe4We"
          },
          "hwlink": {
            "serverAddress": "114.255.186.218",
            "httpsServerPort": "8443",
            "serverPort": "5061",
            "model": 1
          },
          "voip": {
            "domain": "csxtbg.tjjn-datalake.com",
            "port": "10002"
          },
          "im": {
            "tls": true,
            "host": "csxtbg.tjjn-datalake.com:12345/im-ws/",
            "apHost": "csxtbg.tjjn-datalake.com:10001",
            "apPort": 10001
          },
          "whiteList": [
            "jzb.beijing.gov.cn",
            "jzb.beijing.egov.cn",
            "jdcloud.com",
            "jd_third.com",
            "jd.com"
          ],
          "jbrtc": {
            "meetApiUrl": "https://csxtbg.tjjn-datalake.com:10443/zoom",
            "rtcServerUrl": "https://jdxtbg.tjjn-datalake.com/jrtc-internal-api"
          },
          "helpUrl": "https://jzb.beijing.gov.cn/helper",
          "xylink": {
            "extID": "d8e08778cd33b3ae6860f1b13e9f1a68ca2f7d08",
            "proxy": "114.255.186.212:7443"
          },
          "name": "测试环境",
          "me": {
            "mainEntry": {
              "protocol": "https",
              "host": "csxtbg.tjjn-datalake.com:12345"
            }
          },
          "key": "test",
          "gateway": {
            "protocol": "https",
            "appId": "hr3feu3hHPiQXL1fitO2",
            "webAppId": "94XNeuscQUY2Yj4E0xtm",
            "host": "csxtbg.tjjn-datalake.com:12345/janus",
            "stage": "PROD"
          },
          "aiConfig": {
            "aiUser": {
              "pin": "aiRobot",
              "teamId": "focus.bot",
              "app": "dd.notice",
              "name": "AI助手",
              "nickName": "AI助手",
              "avatar": "https://csxtbg.tjjn-datalake.com:12345/statics/icons/icon-ai-robot.png"
            },
            "filterTabList": [
              {
                "pin": "~me69",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "日程"
              },
              {
                "pin": "eopen-push_PZzzvgDrpZVAefKImEfo",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "任务"
              },
              {
                "pin": "~joyspace",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "云空间"
              }
            ],
            "tabList": [
              {
                "pin": "~me69",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "日程"
              },
              {
                "pin": "eopen-push_PZzzvgDrpZVAefKImEfo",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "任务"
              },
              {
                "pin": "~joyspace",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "云文档"
              },
              {
                "pin": "eopen-push_0Gl8b1I3si21n0VVNkrF",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "催办"
              },
              {
                "pin": "eopen-push_qhTSqJ4pYYjuK7ZlYXg5",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "组通知"
              },
              {
                "pin": "other-notice",
                "app": "dd.notice",
                "teamId": "focus.bot",
                "name": "其他"
              }
            ],
            "ver": 6
          }
        }
      ],
      "appId": "com.jd.ee.me.client.jdtjjn",
      "upgradeVersionInfo": [
        "增加个人可扫码添加联系人功能",
        "升级任务体验并支持推进记录显示",
        "提升催办分类体验",
        "增加仅自己可见的备注功能",
        "优化日程细节体验",
        "修复其他已知问题，提升体验"
      ],
      "productName": "津南慧办",
      "i18n": {
        "en_US": {
          "layout": {
            "nav": {
              "calendar": "Calendar",
              "joyspace": "Joyspace",
              "ebook": "Contacts",
              "tests": "TestPage",
              "thirdApp": "Application",
              "messages": "Message",
              "joywork": "JoyWork"
            }
          },
          "login": {
            "title": "欢迎使用 {{productName}}",
            "desc": "与你的团队和朋友在{{productName}}中进行交流与协作"
          }
        },
        "zh_CN": {
          "layout": {
            "nav": {
              "calendar": "日历",
              "meeting": "音视频",
              "joyspace": "云文档",
              "ebook": "通讯录",
              "tests": "测试页",
              "thirdApp": "工作台",
              "messages": "消息",
              "joywork": "任务"
            }
          },
          "login": {
            "title": "欢迎使用 {{productName}}",
            "desc": "与您的团队和朋友在{{productName}}中进行交流与协作"
          }
        }
      }
    },
    "key": "jdtjjn",
    "name": "津南慧办"
  }
];