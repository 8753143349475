import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { AuthData } from '@/types/LocalConfig';
import Service from '@/services';
import {
    AppStoreApplication,
    AppStoreGroupApplication,
    WebviewKernel,
    FavoriteType,
} from '@/services/AppStore';
import logger from '@/utils/logger';
import { IconThirdApp } from '@/components/icon';
import { EventEmitter } from 'events';
import Nav from '@/components/ThirdApp/components/LeftNav';
import Content from '@/components/ThirdApp/components/RightContent';
import BrowserView from '@/components/BrowserView';
import GError from '@/utils/GError';
import { useTranslation } from 'react-i18next';
import { FocusSDK, isBjme, isFocusEnv } from '@/utils';
import Bus from '@/utils/bus';
import { message, Modal } from 'antd';
import { connect } from 'dvajs';
import { analysisLog } from '@/utils/logAnalytics';
import { getAppVersion } from '@/utils/getVersion';
import { GATEWAY_VERSION } from '@/server/constants';
import {
    getLocalFavorite,
    dealThirdApplications,
    dealRecent,
    addFavorite,
    removeFavorite,
    setLocalFavorite,
    removeLocalFavorite,
} from './utils';
import InitThirdAppControllers from './components/controllers/InitThirdAppControllers';
import './index.less';
// import config, { ConfigEnum } from '@/config/config';

const log = logger.getLogger('ThirdApp');

const notifyer = new EventEmitter();

export const genThirdAppUrl = (
    applicationKey: string,
    appId: string,
    accessToken: string,
    appRuntime: AppRuntimeEnv,
    options?: {
        redirectUrl?: string;
    }
    // eslint-disable-next-line max-params
) => {
    const s = new URL(`${appRuntime.meMainEntry}/passport/api/desktop/app/redirect/`);
    s.searchParams.append('applicationKey', applicationKey);
    s.searchParams.append('appKey', appId);
    s.searchParams.append('client', (!isFocusEnv() && 'web') || appRuntime.registryConfig.client);
    s.searchParams.append('accessToken', accessToken);
    s.searchParams.append('teamId', appRuntime.selectedTeamId);
    if (options?.redirectUrl) {
        s.searchParams.append('redirectUrl', options.redirectUrl);
    }
    return s.toString();
};

const getThirdAppUrl = (item: AppStoreApplication, config: AuthData, appRuntime: AppRuntimeEnv) => {
    return genThirdAppUrl(item.applicationKey, config.ddAppId, config.accessToken, appRuntime);
};

export const favoriteGroupId = 'L5sXtN09X461bVUwc000';
export const RECENT_GRROUP_ID = 'L5sXtN09X461bVUwc001';
let recentGroup: {
    groupId: string;
    name: string;
    version: string;
    applications: any[];
} = {
    groupId: RECENT_GRROUP_ID,
    name: '最近使用',
    version: '1648021249394',
    applications: [],
};
// 获取全部工具
const getThirdAppListAll = async (
    service: Service,
    appRuntimeEnv: AppRuntimeEnv,
    authInfo: AuthData,
    type?: string
    // eslint-disable-next-line max-params
) => {
    const localstorageKey = `${authInfo.userId}_${authInfo.teamUserInfo.teamId}`;
    let localFavoriteArr = getLocalFavorite(localstorageKey);
    let thirdAppAll: any[] = [];
    let thirdAppListAll: any = [];
    try {
        // // eslint-disable-next-line no-debugger
        // debugger;
        // 其他所有
        const leveldbKey = `${localstorageKey}_thirdAppLications`;
        const GetListService = InitThirdAppControllers.GetListService();
        let group = (await GetListService.getDBList(`${leveldbKey}`)) || {
            groupApplications: [],
        };
        // console.log(group, 'groupgroupgroupgroupgroup===>');
        let groupApplicationVersions: any = {};
        for (let item of group?.groupApplications || []) {
            groupApplicationVersions[item.groupId] = item.version;
        }
        const opt =
            group.version && group.groupApplications
                ? { groupApplicationVersions, version: group.version }
                : {};
        let result: any = await service.appStoreService.getGroupApplications({
            userId: authInfo.userId,
            teamId: appRuntimeEnv.selectedTeamId,
            ...opt,
        });
        if (group.version !== result.version) {
            result.groupApplications = result.groupApplications.map((item: any) => {
                return item.applications
                    ? item
                    : group?.groupApplications.find((_item: any) => _item.groupId === item.groupId);
            });
        } else {
            result = group?.groupApplications ? group : result;
        }
        GetListService.setDBList(`${leveldbKey}`, result);
        // console.log(result, 'resultresultresultresultresultresultresult', group);
        thirdAppAll = result.groupApplications;
        // 本地常用应用
        if (!localFavoriteArr) {
            let favoriteArr: any[] = [];
            // 恢复默认
            if (type === 'default') {
                const tmpFavorite = await service.appStoreService.getRecommendedApplications({
                    userId: authInfo.userId,
                    teamId: appRuntimeEnv.selectedTeamId,
                });
                favoriteArr = tmpFavorite.groupApplication.applications;
            } else {
                const tmpFavorite = await service.appStoreService.getFavoriteApplications({
                    userId: authInfo.userId,
                    teamId: appRuntimeEnv.selectedTeamId,
                });
                favoriteArr = tmpFavorite.applications;
            }
            localFavoriteArr = favoriteArr.map((i) => i.applicationId);
        }
        const obj = dealThirdApplications(localstorageKey, localFavoriteArr, thirdAppAll);

        const favorite = {
            groupId: favoriteGroupId,
            name: '常用应用',
            version: '1620727481000',
            applications: obj.favorite || [],
        };
        // const recent = {
        //     groupId: RECENT_GRROUP_ID,
        //     name: '最近使用',
        //     version: '1648021249394',
        //     applications: obj.recent,
        // };
        recentGroup.applications = obj.recent;
        const defaultAppList = [favorite];
        obj.recent && obj.recent.length > 0 && defaultAppList.push(recentGroup);
        thirdAppListAll = defaultAppList.concat(obj.thirdApplications);
    } catch (error) {
        console.error(error);
    }

    return thirdAppListAll;
};
const getAppStoreUrl = (appRuntimeEnv: AppRuntimeEnv, authInfo: AuthData) => {
    try {
        const urlObj = new URL(`${appRuntimeEnv.meMainEntry}/app_store`);
        urlObj.searchParams.append('appKey', process.env.REACT_APP_WEB_APPID || '');
        urlObj.searchParams.append('appVersion', getAppVersion(FocusSDK));
        urlObj.searchParams.append(
            'client',
            (!isFocusEnv() && 'web') || appRuntimeEnv.registryConfig.client
        );
        urlObj.searchParams.append('did', appRuntimeEnv.registryConfig.machineId);
        urlObj.searchParams.append('gwVersion', GATEWAY_VERSION as any);
        urlObj.searchParams.append('stage', appRuntimeEnv.gateway.stage);
        urlObj.searchParams.append('teamId', appRuntimeEnv.selectedTeamId);
        urlObj.searchParams.append('accessToken', authInfo.accessToken);
        const secretKey =
            FocusSDK.getDeviceInfo().platform === 'web'
                ? process.env.REACT_APP_WEB_SECRETKEY || ''
                : process.env.REACT_APP_DESKTOP_SECRETKEY || '';

        urlObj.searchParams.append('secretKey', secretKey);
        return urlObj.toString();
    } catch (err) {
        console.log(err);
    }
};

function StartupBody({
    visible,
    loading,
    selectedId,
    thirdAppListAll,
    appRuntimeEnv,
    authInfo,
}: {
    visible?: boolean;
    loading: boolean;
    selectedId: string;
    thirdAppListAll: any;
    appRuntimeEnv: AppRuntimeEnv;
    authInfo: AuthData;
}) {
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const openAppStore = () => {
        try {
            const appStoreUrl = getAppStoreUrl(appRuntimeEnv, authInfo);
            if (FocusSDK.getDeviceInfo().platform === 'web') {
                appStoreUrl && FocusSDK.openUrl(appStoreUrl);
            }
            notifyer.emit('clientMessage', {
                event: 'openNewWindow',
                payload: {
                    urls: [appStoreUrl],
                },
            });
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <div
            style={{
                display: selectedId === 'startup-page' ? 'flex' : 'none',
                height: '100%',
            }}
        >
            <Nav
                loading={loading}
                groups={thirdAppListAll}
                selectedId={selectedGroupId}
                onGroupChange={(group) => {
                    setSelectedGroupId(group.groupId);
                }}
                openAppStore={() => {
                    openAppStore();
                }}
            />
            <Content loading={loading} groups={thirdAppListAll} />
        </div>
    );
}

function ThirdApp(props: any) {
    const { authInfo: config, appRuntimeEnv, service, onGlobalError, focusSDK } = React.useContext<
        GlobalContextConfig
    >(GlobalContext);
    const [selectedId, setSelectedId] = React.useState('startup-page');
    const [openLinkList, setOpenLinkList] = React.useState([]);
    const [thirdAppListAll, setThirdAppListAll] = useState<AppStoreGroupApplication[]>([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('common');
    const { setThirdAppData } = props;
    const localstorageKey = `${config.userId}_${config.teamUserInfo.teamId}`;
    // 布局
    const defaultPage = {
        id: 'startup-page',
        icon: IconThirdApp,
        title: isBjme() ? t('thirdapp.tools-center') : t('thirdapp.application-center'),
        body: (
            <StartupBody
                visible
                loading={loading}
                thirdAppListAll={thirdAppListAll}
                selectedId={selectedId}
                appRuntimeEnv={appRuntimeEnv}
                authInfo={config}
            />
        ),
    };
    const history = useHistory();

    const checkNeedAuth = async (applicationKey: string) => {
        const result = await service.appStoreService.checkApplicationNeedAuth({
            appKey: applicationKey,
            userId: config.userId,
            teamId: appRuntimeEnv.selectedTeamId,
            mode: 'sdk',
        });
        console.log(' service.appStoreService', result);
        return result;
    };

    const submitAuth = async (applicationKey: string, authorize: boolean) => {
        const result = await service.appStoreService.submitApplicationAuth({
            appKey: applicationKey,
            userId: config.userId,
            teamId: appRuntimeEnv.selectedTeamId,
            mode: 'sdk',
            authorize: authorize,
        });
        return result;
    };

    const doAuthConfirm = (name: string, icon: string) => {
        return new Promise((resolve) => {
            Modal.confirm({
                title: `${name}申请`,
                icon: <img src={icon} />,
                className: 'auth-confirm',
                content: '获取您的姓名、单位、部门等信息用于登录。',
                okText: '允许',
                cancelText: '拒绝',
                onOk: () => {
                    resolve(true);
                },
                onCancel: () => {
                    resolve(false);
                },
            });
        });
    };

    // 打开工具
    const openThirdApp = React.useCallback(
        async (item, info) => {
            // 添加最近使用
            dealRecent(localstorageKey, item, thirdAppListAll, recentGroup, info);
            try {
                // if (FocusSDK.isPrivateNet()) {
                //     let appName = item.name || '';
                //     let homeUrl = item.homeUrl || '';
                //     if (appName !== '信创会议' && homeUrl.indexOf('/cloudlink') === -1) {
                //         message.warn(t('joinMeeting.private net'));
                //         return;
                //     }
                // }
                const src = getThirdAppUrl(item, config, appRuntimeEnv);
                // 防止一个应用重复打开，注意浏览器打开的不走该逻辑
                // const openApp: any = openLinkList.find((item: any) => item.link === src);
                // if (openApp) {
                //     setSelectedId(openApp.id);
                //     notifyer.emit('onOpenApp', openApp.id);
                //     return;
                // }
                const result = await checkNeedAuth(item.applicationKey);
                if (result?.supportMode && result?.needAuth) {
                    const isConfirm = await doAuthConfirm(
                        result?.appName || '',
                        result?.appIcon || ''
                    );
                    if (isConfirm) {
                        const submitResult = await submitAuth(item.applicationKey, !!isConfirm);
                        if (submitResult?.authorize) {
                            message.success('授权成功');
                        } else {
                            message.error('授权失败');
                        }
                    } else {
                        message.error('已拒绝授权');
                    }
                }
                log.debug(
                    'open third app, appKey: %s, webviewKernel: %s',
                    item.applicationKey,
                    item.webviewKernel
                );

                analysisLog('xtbg_workbench_1', '1', item.applicationId, item.name);
                switch (item.webviewKernel) {
                    case WebviewKernel.MainWindow: {
                        setThirdAppData({ appId: item.applicationId, appData: item });
                        log.debug('主窗口打开 ===>>>', src, item);
                        notifyer.emit('clientMessage', {
                            event: 'openNewWindow',
                            payload: {
                                urls: [src],
                                name: item.name,
                                phone: item.csTelephone || '',
                                company: item.omCompany || '',
                                appKey: item.applicationKey,
                                trustedDomains: item.trustedDomains || '',
                                version: item.version,
                                isThirdApp: true,
                            },
                        });
                        break;
                    }
                    case WebviewKernel.NewWindow: {
                        log.debug('内置浏览器打开 ===>>>', src, '催办，任务，云空间，视屏会议');
                        history.push({
                            pathname: FocusSDK.isPrivateNet()
                                ? item.egovHomeUrl || item.homeUrl
                                : item.homeUrl,
                        });
                        break;
                    }
                    case WebviewKernel.WebBrowser: {
                        log.debug('外部浏览器打开 ===>>>', src);
                        focusSDK.openUrl(src);
                        break;
                    }
                    default:
                        throw GError.fromError(new Error(t('error.thirdAppConfigError')));
                }
            } catch (error) {
                console.log('错误', error);
                onGlobalError(error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [appRuntimeEnv, config, focusSDK, onGlobalError, t, history, thirdAppListAll]
    );
    // 修改所有数据
    const onChangeListAll = useCallback(
        (listAll: AppStoreGroupApplication[], applicationId: string, favorite: FavoriteType) => {
            for (const group of listAll) {
                for (const appInfo of group.applications) {
                    if (appInfo.applicationId === applicationId) {
                        appInfo.favorite = favorite;
                    }
                }
            }
            setThirdAppListAll(listAll);
        },
        []
    );
    // 添加常用工具
    const onAddFavoriteThirdApp = useCallback(
        async (appInfo: AppStoreApplication) => {
            // 触发loading
            Bus.emit('setLoading', {
                loading: true,
                applicationId: appInfo.applicationId,
            });
            analysisLog('xtbg_workbench_1', '3', appInfo.applicationId, appInfo.name);
            try {
                const result = await service.appStoreService.addFavoriteApplication({
                    applicationId: appInfo.applicationId,
                    userId: config.userId,
                    teamId: appRuntimeEnv.selectedTeamId,
                });
                const list = [...thirdAppListAll];
                const groupItem: any | null =
                    list.find(
                        (item: AppStoreGroupApplication) => item.groupId === favoriteGroupId
                    ) || null;
                if (groupItem) {
                    appInfo.favorite = FavoriteType.FavoriteYes;
                    groupItem.applications.push(appInfo);
                    onChangeListAll(list, appInfo.applicationId, FavoriteType.FavoriteYes);
                    // 触发筛选
                    Bus.emit('onSearchUpData', appInfo);
                    // 添加应用
                    Bus.emit('onAddAppData', appInfo);
                    // 关闭loading
                    Bus.emit('setLoading', {
                        loading: false,
                        applicationId: appInfo.applicationId,
                    });
                    addFavorite(localstorageKey, appInfo.applicationId);
                }
                message.success('添加成功');
            } catch (error) {
                message.error('添加失败,请重试');
                Bus.emit('setLoading', { loading: false, applicationId: appInfo.applicationId });
            }
        },
        [
            thirdAppListAll,
            onChangeListAll,
            localstorageKey,
            config.userId,
            appRuntimeEnv.selectedTeamId,
            service.appStoreService,
        ]
    );
    // 移除常用工具
    const removeFavoriteThirdApp = useCallback(
        async (appInfo: AppStoreApplication) => {
            Bus.emit('setLoading', { loading: true, applicationId: appInfo.applicationId });
            analysisLog('xtbg_workbench_1', '4', appInfo.applicationId, appInfo.name);
            try {
                const result = await service.appStoreService.removeFavoriteApplication({
                    applicationId: appInfo.applicationId,
                    userId: config.userId,
                    teamId: appRuntimeEnv.selectedTeamId,
                });
                const list = [...thirdAppListAll];
                const groupItem: any | null =
                    list.find(
                        (item: AppStoreGroupApplication) => item.groupId === favoriteGroupId
                    ) || [];
                const idx = groupItem.applications.findIndex(
                    (item: AppStoreApplication) => item.applicationId === appInfo.applicationId
                );
                if (groupItem && idx !== -1) {
                    appInfo.favorite = FavoriteType.FavoriteNo;
                    groupItem.applications.splice(idx, 1);
                    onChangeListAll(list, appInfo.applicationId, FavoriteType.FavoriteNo);
                    Bus.emit('setLoading', {
                        loading: false,
                        applicationId: appInfo.applicationId,
                    });
                    // 触发筛选
                    Bus.emit('onSearchUpData', appInfo);
                    // 移除应用
                    Bus.emit('onRemoveAppData', appInfo);
                }
                removeFavorite(localstorageKey, appInfo.applicationId);
                message.success('取消成功');
            } catch (error) {
                message.error('添加失败,请重试');
                Bus.emit('setLoading', { loading: false, applicationId: appInfo.applicationId });
            }
        },
        [
            thirdAppListAll,
            onChangeListAll,
            localstorageKey,
            appRuntimeEnv.selectedTeamId,
            config.userId,
            service.appStoreService,
        ]
    );
    // 获取全部分类及分类下的工具列表
    const getAppListAll = useCallback(
        async (type?: string) => {
            setLoading(true);
            const list = await getThirdAppListAll(service, appRuntimeEnv, config, type);
            setLoading(false);
            setThirdAppListAll(list);
        },
        [service, appRuntimeEnv, config]
    );
    // 批量编辑常用工具
    const onEditFavoriteApplication = useCallback(
        async (applicationIds, list: any) => {
            try {
                const res = await service.appStoreService.editFavoriteApplication({
                    userId: config.userId,
                    teamId: appRuntimeEnv.selectedTeamId,
                    applicationIds: list.map((i: any) => i.applicationId),
                });
                // 修改排序
                const groupList = [...thirdAppListAll];
                const appInfo = groupList.find((item) => item.groupId === favoriteGroupId);
                if (!appInfo) {
                    return;
                }
                appInfo.applications = list;
                setLocalFavorite(
                    localstorageKey,
                    list.map((i: any) => i.applicationId)
                );
                setThirdAppListAll(groupList);
                message.success('保存成功');
            } catch (error) {
                message.error('保存失败,请重试');
            }
        },
        [
            thirdAppListAll,
            localstorageKey,
            config.userId,
            appRuntimeEnv.selectedTeamId,
            service.appStoreService,
        ]
    );

    const onResetFavorite = useCallback(
        (type: string) => {
            removeLocalFavorite(localstorageKey);
            getAppListAll(type);
        },
        [getAppListAll, localstorageKey]
    );

    // 获取全部数据及添加监听
    const initRef = useRef('');
    useEffect(() => {
        if (!initRef.current) {
            initRef.current = 'init';
            getAppListAll();
        }
        Bus.on('openThirdApp', openThirdApp);
        Bus.on('onAddFavoriteThirdApp', onAddFavoriteThirdApp);
        Bus.on('removeFavoriteThirdApp', removeFavoriteThirdApp);
        Bus.on('onEditFavoriteApplication', onEditFavoriteApplication);
        Bus.on('onResetFavorite', onResetFavorite);
        Bus.on('onRefreshThirdapps', getAppListAll);
        return () => {
            Bus.off('openThirdApp', openThirdApp);
            Bus.off('onAddFavoriteThirdApp', onAddFavoriteThirdApp);
            Bus.off('removeFavoriteThirdApp', removeFavoriteThirdApp);
            Bus.off('onEditFavoriteApplication', onEditFavoriteApplication);
            Bus.off('onResetFavorite', onResetFavorite);
            Bus.off('onRefreshThirdapps', getAppListAll);
        };
    }, [
        getAppListAll,
        openThirdApp,
        onAddFavoriteThirdApp,
        removeFavoriteThirdApp,
        onEditFavoriteApplication,
        onResetFavorite,
    ]);
    const handleNetworkChange = useCallback(
        (data: any) => {
            const { connectState } = data;
            if (!thirdAppListAll.length && connectState.state === ConnectState.READY) {
                getAppListAll();
            }
        },
        [thirdAppListAll, getAppListAll]
    );
    useEffect(() => {
        Bus.on('chat:connect_state_change', handleNetworkChange);
        return () => {
            Bus.off('chat:connect_state_change', handleNetworkChange);
        };
    }, [handleNetworkChange]);
    // 从搜索跳转到第三方工具
    // const location = useLocation();
    // useEffect(() => {
    //     const applicationInfo = ((location.state || {}) as any).applicationInfo;
    //     console.log(applicationInfo, '<===applicationInfoapplicationInfowww2222');
    //     console.log(location, 'locationlocationlocation');
    //     if (location.state && applicationInfo) {
    //         location.state = {};
    //         console.log(applicationInfo, '<===applicationInfo');
    //         openThirdApp(applicationInfo);
    //     }
    // }, [location, openThirdApp]);
    // 选择父元素并设置父元素高度100%， 应为该组件用了 CacheRoute 导致高度塌陷。
    useEffect(() => {
        const fatherDiv: any = (document.getElementById('third-apps') as any).parentNode;
        fatherDiv.style.height = '100%';
    }, []);

    const getOpenLinks = (links: any) => {
        setOpenLinkList(links);
    };
    useEffect(() => {
        notifyer.on('getOpenLinks', getOpenLinks);
    });

    return (
        <div
            id="third-apps"
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <BrowserView
                configData={{
                    urls: [],
                    partition: 'builtin-third-apps',
                    allowedHosts: [],
                }}
                notifier={notifyer}
                defaultPage={defaultPage}
                onTabChange={(selectedId1: any) => {
                    setSelectedId(selectedId1);
                }}
            />
        </div>
    );
}
function mapStateToProps() {
    return {};
}
function mapDispatchToProps(dispatch: any) {
    return {
        setThirdAppData(data: any) {
            dispatch({ type: 'thirdapp/setThirdAppData', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdApp);
