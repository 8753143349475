import { IPageAddCallback, api } from './index';

import SyncJoySpaceController from '../controllers/SyncJoySpaceController';

const sync: IPageAddCallback = (props, isAllow) => {
    // console.log(isAllow, 'isAllowisAllowisAllowisAllow');
    const { data, setData } = props;
    return [
        {
            children: '同步',
            callbackOrModal: async () => {
                console.log('async=====>');
                if (!navigator.onLine) {
                    return;
                }
                const content = await SyncJoySpaceController.findPageContent(
                    data.page_type,
                    data.id
                );
                // 组合参数
                const item: any = SyncJoySpaceController.getParams(data, content);
                const list: any = [item];
                SyncJoySpaceController.handleSyncApi(list, true);
            },
            isAllow,
            type: 'sync',
        },
    ];
};

export default sync;
