import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';
import { connect } from 'dvajs';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './index.less';
import JoinMe from './JoinMe';
import Invite from './Invite';
import { getInviteUserRole } from '@/api/joinMe';
import { UserState, UserModel } from '@/models/user';
import IconFont from '@/components/icon';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { IFocusSDK } from '@/utils';

const destroyFns: any[] = [];

export function TeamManagement({
    onClose,
    destroy,
    userData,
    getWebUserData,
    appRuntimeEnv,
    focusSDK,
}: {
    onClose?: () => void;
    destroy?: () => void;
    userData: any;
    getWebUserData: any;
    appRuntimeEnv: AppRuntimeEnv;
    focusSDK: IFocusSDK;
}) {
    return (
        <div id="appContentModalManagement" className="app-content-full-modal">
            <Modal
                visible={true}
                onCancel={() => {
                    onClose?.();
                    destroy?.();
                }}
                closeIcon={<IconFont type="iconlog_close" />}
                width="100%"
                style={{
                    height: '100%',
                    top: 0,
                    left: 0,
                    transformOrigin: '0 0',
                    boxShadow: 'none',
                    boxSizing: 'border-box',
                }}
                bodyStyle={{ padding: 0 }}
                footer={null}
                getContainer={() => {
                    // let appContent = document.querySelector('.appContent');
                    let container =
                        document.getElementById('appContentModalManagement') ||
                        document.createElement('div');
                    return container;
                }}
            >
                <JoinMe
                    userData={userData}
                    getWebUserData={getWebUserData}
                    appRuntimeEnv={appRuntimeEnv}
                    focusSDK={focusSDK}
                />
            </Modal>
        </div>
    );
}

export function TeamInvite({
    teamId,
    userData,
    appRuntimeEnv,
    onClose,
    destroy,
}: {
    teamId?: string;
    userData: any;
    appRuntimeEnv: AppRuntimeEnv;
    onClose?: () => void;
    destroy: () => void;
}) {
    return (
        <div id="appContentModalInvite" className="app-content-full-modal">
            <Modal
                visible={true}
                onCancel={destroy}
                closeIcon={<IconFont type="iconlog_close" />}
                width="100%"
                style={{
                    height: '100%',
                    top: 0,
                    left: 0,
                    transformOrigin: '0 0',
                    boxShadow: 'none',
                    boxSizing: 'border-box',
                }}
                // closeIcon = {}
                bodyStyle={{ padding: 0 }}
                footer={null}
                getContainer={() => {
                    // let appContent = document.querySelector('.appContent');
                    let container =
                        document.getElementById('appContentModalInvite') ||
                        document.createElement('div');
                    return container;
                }}
            >
                <Invite
                    teamId={teamId}
                    userData={userData}
                    destroy={destroy}
                    appRuntimeEnv={appRuntimeEnv}
                />
            </Modal>
        </div>
    );
}

const Teams = () => {
    return null;
};

const openModal = ({ propsConfig, modalClass }: any) => {
    let appContent = document.querySelector('.appContent') || document.querySelector('.webLayout');
    const div = document.createElement('div');
    if (!appContent) {
        return {
            destroy: onClose,
            update,
        };
    }
    appContent.appendChild(div);
    let currentConfig = { ...propsConfig, destroy, visible: true } as any;

    function destroy(...args: any[]) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);
        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }

        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            // eslint-disable-next-line no-use-before-define
            if (fn === onClose) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
    function render({ ...props }: any) {
        setTimeout(() => {
            const factory = React.createFactory(modalClass);
            ReactDOM.render(
                factory({
                    ...props,
                }),
                div
            );
        });
    }

    function onClose(...args: any[]) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            // @ts-ignore
            afterClose: destroy.bind(this, ...args),
        };
        render(currentConfig);
    }

    function update(newConfig: any) {
        currentConfig = {
            ...currentConfig,
            ...newConfig,
        };
        render(currentConfig);
    }

    render(currentConfig);

    destroyFns.push(close);

    return {
        destroy: onClose,
        update,
    };
};
Teams.TeamManagement = (props: any) => {
    return openModal({
        propsConfig: {
            ...props,
        },
        modalClass: TeamManagement,
    });
};
Teams.TeamInvite = (props: any) => {
    return openModal({
        propsConfig: {
            ...props,
            onClose: () => {},
        },
        modalClass: TeamInvite,
    });
};
export default Teams;

function mapStateToProps({ user }: { user: UserState }) {
    return {
        user: user.userData.user,
    };
}
function InviteButton({ user, text }: { user: UserModel; text: string }) {
    const [superAdmin, setSuperAdmin] = useState(false);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);

    useEffect(() => {
        getInviteUserRole({
            teamId: user.selectedTeamId,
        })
            .then((res: any) => {
                if (res) {
                    setSuperAdmin(res.superAdmin);
                }
            })
            .catch(() => {});
    }, [user]);
    return superAdmin ? (
        <div
            className="invite-entry"
            onClick={() =>
                Teams.TeamInvite({
                    userData: user,
                    appRuntimeEnv,
                })
            }
        >
            {text}
        </div>
    ) : null;
}
export const InviteEntry = connect(mapStateToProps)(InviteButton);
