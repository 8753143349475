import React, { useMemo, useState, useCallback, useEffect } from 'react';
import './index.less';
import { MessageSendType } from '@/types/chat/enum';
import classNames from 'classnames';
import { Modal } from 'antd';
import { WithTranslation, useTranslation } from 'react-i18next';
import ImService from '@/server/ImService';
import SimpleTime from '@/components/chat/message/Time/Simple';
import { connect } from 'dva';
import { mattchEmoji } from '@/components/chat/message/Input/Emoji';
import { convertStringToEmoji } from '@/components/chat/utils/ddemoji';
import { stringEncode } from '@/utils/chat/index';
import {
    getChatMessageNode,
    getHeadPortrait,
    getShowTime,
    TimeTransform,
} from '@/components/chat/utils/commonMessageModal';
import { changeEgovUrl } from '@/utils/tools';
import bus from '@/utils/bus';
interface IProps {
    message: any;
    sendType: MessageSendType;
}
type MergeForwardProps = Readonly<IProps & WithTranslation>;
let count = 0;
let flag = false;
function MergeForward(props: MergeForwardProps) {
    const { message, sendType } = props;
    const { t } = useTranslation('chat');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalMessages, setModalMessages] = useState([] as any[]);

    const handleOk = (e: any) => {
        e.stopPropagation();
        if (flag) {
            return;
        }
        flag = true;
        setIsModalVisible(false);
        count--;
        setTimeout(() => {
            flag = false;
        }, 500);
    };

    const handleCancel = (e: any) => {
        e.stopPropagation();
        if (flag) {
            return;
        }
        flag = true;
        setIsModalVisible(false);
        count--;
        setTimeout(() => {
            flag = false;
        }, 500);
    };

    const getClass = useCallback(() => {
        return classNames({
            message: true,
            'mergeForward-message': true,
            owner: sendType === MessageSendType.OWNER,
        });
    }, [sendType]);
    let needData = useMemo(() => {
        return message.data[0].detail || [];
    }, [message]);
    let employees: string[] = useMemo(() => {
        let allEmployee: any = new Set(needData.map((item: any) => item.realname));
        return [...allEmployee].filter((item) => item !== message.data[0].title.toRealname);
    }, [needData, message]);

    const showRecordModal = async (e: any) => {
        e.stopPropagation();
        if (flag) {
            return;
        }
        flag = true;
        if (count > 0) {
            // if (document) {
            //     let allMask = document.querySelectorAll('.ant-modal-mask');
            //     let lastMask: any = allMask[allMask.length - 1];
            //     lastMask.style.display = 'none';
            // }
        }
        if (count >= 3) {
            return;
        }
        const imService = ImService.getInstance();
        const result: any = await imService.getMergeForwardBody(
            changeEgovUrl(message.data[0].content),
            message.id
        );
        if (result?.messages) {
            setModalMessages(result.messages);
            setIsModalVisible(true);
            count++;
        } else {
            setIsModalVisible(false);
        }
        setTimeout(() => {
            flag = false;
        }, 500);
    };

    // dom
    const getHead = () => {
        if (!message.data || !message.data[0] || !message.data[0].title) {
            return '';
        }
        let realName = message.data[0].title.fromRealname || message.nickName || message.realname;
        if (message.data[0].title.mergeKind === 'group') {
            let fromGroupName = message.data[0].title.fromGroupName;
            if (fromGroupName) {
                return (
                    <div className="head">{t('more_communicate_record', { fromGroupName })}</div>
                );
            } else {
                return <div className="head">{t('group_communicate_record')}</div>;
            }
        }
        if (realName !== message.data[0].title.toRealname) {
            return (
                <div className="head">
                    {t('double_communicate_record', {
                        realName,
                        toRealname: message.data[0].title.toRealname,
                    })}
                </div>
            );
        } else {
            return <div className="head">{t('single_communicate_record', { realName })}</div>;
        }
    };

    const getTime = (message: any) => {
        const { datetime, timeStamp }: any = message;
        // datetime: "2021-01-21 16:10:39"
        return <SimpleTime timeStamp={timeStamp} />;
    };
    const visibleChange = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        bus.emit('message-item-chatitem', false);
    };

    useEffect(() => {
        if (isModalVisible) {
            document.addEventListener('contextmenu', visibleChange);
            return () => {
                document.removeEventListener('contextmenu', visibleChange);
            };
        }
    }, [isModalVisible]);

    const getModal = () => {
        if (!modalMessages.length) {
            return null;
        }
        let timeSection = '';
        if (modalMessages.length === 1) {
            timeSection = TimeTransform(modalMessages[0].timestamp);
        } else {
            let start = TimeTransform(modalMessages[0].timestamp);
            let end = TimeTransform(modalMessages[modalMessages.length - 1].timestamp);
            if (start !== end) {
                timeSection = start + ' ~ ' + end;
            } else {
                timeSection = start;
            }
        }
        return (
            <Modal
                title={getHead()}
                visible={isModalVisible}
                onOk={(e) => handleOk(e)}
                onCancel={(e) => handleCancel(e)}
                footer={null}
                zIndex={100}
                centered
                className="forwardModal"
                width={550}
            >
                <div className="title">{timeSection}</div>
                <div className="forward-lists">
                    {modalMessages.map((message: any, index: number) => {
                        return (
                            <div key={index} className="wrapper">
                                <div className="forward-list">
                                    <div className="left">{getHeadPortrait(message)}</div>
                                    <div className="right">
                                        <div className="name">
                                            <div>{message.senderName || message.realname}</div>
                                            <div>{getShowTime(message)}</div>
                                        </div>

                                        <div className="message-box">
                                            <div className="content-row">
                                                {getChatMessageNode(
                                                    modalMessages,
                                                    message,
                                                    count,
                                                    true
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="line" />
                            </div>
                        );
                    })}
                </div>
            </Modal>
        );
    };
    return (
        <div className="content">
            <div className={getClass()} onClick={(e) => showRecordModal(e)}>
                {getHead()}
                <div className="body">
                    {needData.map((item: any, index: number) => {
                        if (index > 3) {
                            return null;
                        }
                        return (
                            <div key={index} className="list">
                                {item.content === '[聊天记录]' ? (
                                    (item.realname || item.senderName) + '：[沟通记录]'
                                ) : (
                                    <p
                                        className="dangerous-merge-html"
                                        dangerouslySetInnerHTML={{
                                            __html: mattchEmoji(
                                                convertStringToEmoji(
                                                    stringEncode(
                                                        (item.realname || item.senderName) +
                                                            '：' +
                                                            item.content
                                                    )
                                                )
                                            ),
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            {getModal()}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    // const { currentEmployee } = chat as ChatState;
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MergeForward);
