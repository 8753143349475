import React, { useEffect, useState } from 'react';
import ScheduleUIComp from './ScheduleUIComp';
import { getScheduleInfo } from '@/api/calendar';
import { getTypeImage } from '@/components/Calendars/utils/getTypeImage';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { replaceGovUrl } from '@/utils/joyspace';
import { connect } from 'dvajs';
import { dealTime } from './util';
import { formatScheduleTime } from '@/components/Calendars/utils';
import { useTranslation } from 'react-i18next';
import bus from '@/utils/bus';
import { analysisLog } from '@/utils/logAnalytics';
import { message } from 'antd';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';

export interface IProps {
    // 任务或者日程id
    businessId?: any;
    calendarMap?: any;
    userData: any;
}

function TopNoticeCard2Chat({ businessId, calendarMap, userData }: IProps) {
    const [title, setTitle] = useState('');
    const [timeInfo, setTimeInfo] = useState('');
    const [icon, setIcon] = useState('');
    const [visable, setVisable] = useState(false);
    const [businessInfo, setBusinessInfo]: [any, any] = useState(null);
    const { t } = useTranslation('calendar');

    function clickHandler() {
        analysisLog('xtbg_calhome_1640073907694', '22');
        const { teamUserInfo, userId } = userData;
        const { ddAppId, teamId, realName } = teamUserInfo;
        // console.log('schedule:', businessInfo);
        const projectId = calendarMap?.[businessInfo.calendarId]?.extended?.projectId;
        const url = DeeplinkEvent.strify({
            appId: ddAppId,
            path: DeeplinkPathEnum.Schedule_Draw,
            mparam: {
                projectId: projectId || '',
                content: businessInfo.description,
                scheduleId: businessId,
                beginTime: businessInfo.beginTime,
                endTime: businessInfo.endTime,
                isNeedCheck: true,
                calendarId: businessInfo.calendarId,
                color: businessInfo.color,
            },
        });
        DeeplinkEvent.open(replaceGovUrl(url), {
            source: 'imShareCard',
            from: ScheduleDetailFrom.ChatBanner,
        });
    }
    // const refreshScheduleInfo = React.useCallback(() => {
    //     getScheduleInfo({ scheduleId: businessId, dateTime: Date.now() })
    //         .then((res: any) => {
    //             setTitle(`日程：${res.title}`);
    //             setBusinessInfo(res);
    //             setTimeInfo(dealTime(res.beginTime, res.endTime));
    //             setIcon(getTypeImage(res.color));
    //             setVisable(true);
    //         })
    //         .catch((err) => {
    //             setVisable(false);
    //         });
    // }, [businessId]);

    // useEffect(() => {
    //     bus.on('chat:group:refreshMember', refreshScheduleInfo);
    //     return () => {
    //         bus.off('chat:group:refreshember', refreshScheduleInfo);
    //     };
    // }, [refreshScheduleInfo]);
    const refreshScheduleInfo = function () {
        getScheduleInfo({ scheduleId: businessId })
            .then((res: any) => {
                if (!res) {
                    message.warning(t('schedule is not found'));
                    return;
                }
                setTitle(`日程：${res.title}`);
                setBusinessInfo(res);
                // setTimeInfo(dealTime(res.beginTime, res.endTime));
                setTimeInfo(formatScheduleTime(res.beginTime, res.endTime, res.isAlldate, t));
                setIcon(getTypeImage(res.color));
                setVisable(true);
            })
            .catch((err) => {
                if (visable) {
                    message.error(err.message?.toString() || t('schedule is not found'));
                }
                setVisable(false);
            });
    };
    useEffect(() => {
        bus.on('chat:group:refreshScheduleInfo', refreshScheduleInfo);
        return () => {
            bus.off('chat:group:refreshScheduleInfo', refreshScheduleInfo);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        refreshScheduleInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessId]);
    return visable ? (
        <ScheduleUIComp
            title={title}
            businessInfo={businessInfo}
            timeInfo={timeInfo}
            icon={icon}
            clickHandler={clickHandler}
        />
    ) : null;
}

function mapStateToProps({ calendar, user }: any) {
    return {
        calendarMap: calendar.calendarMap,
        userData: user.userData?.user,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNoticeCard2Chat);
