import React from 'react';
import dayjs from 'dayjs';

export function dealTime(beginTime: number, endTime: number) {
    if (!beginTime && !endTime) {
        return '';
    }
    const theBeginTime = dayjs(beginTime);
    const theEndTime = dayjs(endTime);
    if (!beginTime) {
        return `${theEndTime?.format('M月D日 HH:mm')}截止`;
    }
    if (!endTime) {
        return `${theBeginTime.format('YYYY年M月D日 HH:mm')}开始`;
    }

    const isSameYear = theBeginTime.isSame(theEndTime, 'year');
    const isSameMonth = theBeginTime.isSame(theEndTime, 'month');
    const isSameDay = theBeginTime.isSame(theEndTime, 'day');

    let theTimeInfo = '';
    if (isSameDay) {
        theTimeInfo = `${theBeginTime.format('YYYY年M月D日 HH:mm')} - ${theEndTime.format(
            'HH:mm'
        )}`;
    } else if (isSameMonth) {
        theTimeInfo = `${theBeginTime.format('YYYY年M月D日 HH:mm')} - ${theEndTime.format(
            'M月D日 HH:mm'
        )}`;
    } else if (isSameYear) {
        theTimeInfo = `${theBeginTime.format('YYYY年M月D日 HH:mm')} - ${theEndTime.format(
            'M月D日 HH:mm'
        )}`;
    } else {
        theTimeInfo = `${theBeginTime.format('YYYY年M月D日 HH:mm')} - ${theEndTime.format(
            'YYYY年M月D日 HH:mm'
        )}`;
    }

    return theTimeInfo;
}
