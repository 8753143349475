/**
 * 表情回复
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.less';
import classNames from 'classnames';
import { MessageSendType } from '@/types/chat/enum';
import {
    EmojiMessageContentItem,
    MessageExtAction,
    ChatMessageExtType,
} from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/Type';
import { ChatMessage, Employee, SessionType, UID } from '@/types/chat';
import Context from '@/context/ChatContext';
import { convertStringToEmoji } from '@/utils/chat/ddemoji';

interface EmojiReplyProps {
    sessionType: SessionType;
    message: ChatMessage;
    sendType: MessageSendType;
    callback: (hasEmoji: boolean) => void;
}

type IProps = Readonly<EmojiReplyProps>;

function EmojiReply(props: IProps) {
    const { message, sendType, sessionType, callback } = props;
    const { sessionId, mid } = message;

    const [emojiList, setEmojiList] = useState<EmojiMessageContentItem[]>([]);
    const { currentEmployee } = React.useContext(Context);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const itemClass = React.useMemo(() => {
        return classNames({
            'emoji-reply-item': true,
            owner: sendType === MessageSendType.OWNER,
        });
    }, [sendType]);
    // const currentUid = useMemo(() => {
    //     const user = employeeToUid(currentEmployee);
    //     user.nickName = currentEmployee.name;
    //     user.avatar = currentEmployee.avatar;
    //     user.art = JSON.stringify({ nickName: user.nickName, avatar: user.avatar });
    //     return user;
    // }, [currentEmployee]);

    // useEffect(() => {
    //     // 查询缓存
    //     const instance = ImService.getInstance();
    //     instance.getMessageEmojiReply(sessionId, mid).then((data) => {
    //         const msgContent = data?.msgContent || [];
    //         if (msgContent.length > 0) {
    //             setEmojiList(msgContent);
    //         }
    //     });
    // }, [sessionId, mid]);

    useEffect(() => {
        callback(emojiList.length > 0);
    }, [callback, emojiList]);

    // const receiveEmojiReplyListener = useCallback(
    //     (data) => {
    //         const instance = ImService.getInstance();
    //         instance.getMessageEmojiReply(sessionId, mid).then((data) => {
    //             setEmojiList(data?.msgContent || []);
    //         });
    //     },
    //     [mid, sessionId]
    // );

    // const getMessageBody = useCallback(
    //     (
    //         emojiItem: EmojiMessageContentItem,
    //         action: MessageExtAction,
    //         msgType: ChatMessageExtType
    //         // eslint-disable-next-line max-params
    //     ) => {
    //         emojiItem.users = [currentUid];
    //         return buildQuickEmojiMessageBody({
    //             session: { sessionId, sessionType },
    //             mid,
    //             action,
    //             msgContentItem: emojiItem,
    //             msgType,
    //         });
    //     },
    //     [currentUid, sessionId, sessionType, mid]
    // );

    // const getAction = useCallback(
    //     ({ emoji, emojiCode }: { emoji: string; emojiCode: string }): MessageExtAction => {
    //         let index = emojiList.findIndex((item) => {
    //             return item.emojiCode === emojiCode || item.emoji === emoji;
    //         });
    //         if (index < 0) {
    //             return MessageExtAction.ADD;
    //         }
    //         const users = emojiList[index].users;
    //         index = users.findIndex((user) => isEqualUid(user, currentUid));
    //         return index > -1 ? MessageExtAction.DELETE : MessageExtAction.ADD;
    //     },
    //     [currentUid, emojiList]
    // );

    // const sendEmojiReplyListener = useCallback(
    //     async ({ emoji }: { emoji: string }) => {
    //         if (!emoji) {
    //             return;
    //         }
    //         const emojiCode = getEmojiCode(emoji);
    //         // 构建emoji reply body
    //         // 发送消息
    //         const emojiItem: EmojiMessageContentItem = {
    //             emoji: emoji,
    //             emojiCode: emojiCode,
    //             emojiUrl: '',
    //             users: [currentUid],
    //         };
    //         const action = getAction({ emoji: emojiItem.emoji, emojiCode: emojiItem.emojiCode });
    //         const msgType = ChatMessageExtType.PIUS_ONE;
    //         // const action = MessageExtAction.ADD;
    //         // 获取用户信息
    //         const message = getMessageBody(emojiItem, action, msgType);
    //         // 发送消息
    //         const instance = ImService.getInstance();
    //         await instance.sendChatMessageExt(sessionId, message);
    //     },
    //     [currentUid, getAction, getMessageBody, sessionId]
    // );

    // useEffect(() => {
    //     const instance = ImService.getInstance();
    //     const manager = instance.getChatManager();
    //     if (manager) {
    //         manager.on(`message:emojiReply:${sessionId}:${mid}`, receiveEmojiReplyListener);
    //         return () => {
    //             manager.off(`message:emojiReply:${sessionId}:${mid}`, receiveEmojiReplyListener);
    //         };
    //     }
    // }, [mid, sessionId, receiveEmojiReplyListener]);

    // useEffect(() => {
    //     Bus.on(`message:emojiReply:${sessionId}:${mid}`, sendEmojiReplyListener);
    //     return () => {
    //         Bus.off(`message:emojiReply:${sessionId}:${mid}`, sendEmojiReplyListener);
    //     };
    // }, [mid, sessionId, sendEmojiReplyListener]);
    // 打开回复
    const openUserCard = useCallback((user: UID) => {
        setIsModalVisible(true);
        // openUserCardModal(uidToEmployee(user) as Employee);
    }, []);

    // 点击回复的表情
    // const replyEmojiClick = useCallback(
    //     async (emojiItem: EmojiMessageContentItem) => {
    //         // let index = emojiList.findIndex((item) => {
    //         //     return item.emojiCode === emojiItem.emojiCode || item.emoji === emojiItem.emoji;
    //         // });
    //         // if (index < 0) {
    //         //     return;
    //         // }
    //         // const users = emojiList[index].users;
    //         // index = users.findIndex((user) => isEqualUid(user, currentUid));
    //         // const action = index > -1 ? MessageExtAction.DELETE : MessageExtAction.ADD;
    //         const action = getAction({ emoji: emojiItem.emoji, emojiCode: emojiItem.emojiCode });
    //         const msgType = ChatMessageExtType.PIUS_ONE;
    //         const message = getMessageBody(emojiItem, action, msgType);
    //         // 发送消息
    //         const instance = ImService.getInstance();
    //         await instance.sendChatMessageExt(sessionId, message);
    //     },
    //     [getAction, getMessageBody, sessionId]
    // );

    const getEmojiNode = useCallback((item: EmojiMessageContentItem) => {
        if (item.emoji.indexOf('[') > -1) {
            // 图片展示
            // const src = emoji_png_map[item.emoji];
            // return <img src={src} alt="" />;
            return convertStringToEmoji(item.emoji);
        } else {
            return item.emoji;
        }
    }, []);

    return (
        <div className="emoji-reply">
            {emojiList.map((item, index) => {
                return (
                    <div key={index} className={itemClass}>
                        {/* <span
                            className="e-emoji emoji-i"
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                replyEmojiClick(item);
                            }}
                            dangerouslySetInnerHTML={{ __html: getEmojiNode(item) }}
                        /> */}

                        {/* {getEmojiNode(item)}
                        </span> */}
                        {/* <span className="vertical-i">|</span> */}
                        {item.users.map((user, index) => {
                            return (
                                <div className="emoji-text" key={index}>
                                    {/* <span
                                        key={index}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            openUserCard(user);
                                        }}
                                        className="reply-users"
                                    >
                                        {user.nickName}
                                        {index === item.users.length - 1 ? '' : '，'}
                                    </span> */}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}

export default EmojiReply;
