import React, { useState, useEffect, useCallback } from 'react';
import { Loading } from '@jd/focus-desktop-comps';
import { Button, Progress } from 'antd';
import { BrowserViewContext, BrowserViewItem } from './utils';
import { IconArrowLeft, IconArrowRight, IconMore, IconRefresh } from '@/components/icon';
import styles from '@/components/styles';
import ErrorPage, { ErrorType, ErrorInfo } from './ErrorPage';
import './webview.less';
import { changeEgovUrl } from '@/utils/tools';
import { checkJoyspaceUrl } from '@/components/Joyspace/util';
// const preloadJS = `file://${remote.app.getAppPath()}/browser/webview/AppPreload.js`;
// console.log('AppPreload =>', preloadJS);

import logger from '@/utils/logger';
import { RegistryConfigData } from '@/types/LocalConfig';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getAuthorizationCode } from '@/api/user';
import { connect } from 'dvajs';
import Axios from 'axios';
import { FocusSDK } from '@/utils';

const log = logger.getLogger('BrowserView/Webview');
import { openUserSelectionModal } from '@/utils/modals';
import { UserModel } from '@/models/user';
import { joinMeeting } from '../Meeting/utils';

// const preloadJS = `file://${remote.app.getAppPath()}/browser/webview/AppPreload.js`;

const DEFAULT_HOST_LIST = [
    'jzb.beijing.gov.cn',
    'jzb.beijing.egov.cn',
    'jdcloud.com',
    'jd_third.com',
    'jd.com',
];
function filterUserInfo(data: RegistryConfigData): any {
    if (!data.auth) {
        return null;
    }
    const { realName, nickName, teamUserInfo, headImg, account, userId, ddAppId } = data.auth;
    return {
        realName,
        nickName,
        teamUserInfo,
        headImg,
        account,
        selectedTeamId: data,
        userId,
        ddAppId,
    };
}

function WebView({
    item,
    visible,
    src,
    withToolbar,
    thirdAppMap,
    updatehirdAppList,
}: // onGetSrc
{
    src?: string;
    // onGetSrc?: () => Promise<string>;
    item: BrowserViewItem;
    visible?: boolean;
    withToolbar?: boolean;
    thirdAppMap?: any;
    updatehirdAppList: Function;
}) {
    const {
        onNewWindowOpen,
        onPageMetaDataChanged,
        registryConfigData,
        notifier,
        configData,
        openWebViewModal,
        closeWebViewModal,
        openCommonModal,
        openGroupChat,
        openSingleChat,
        viewImages,
        openExternal,
        webviewModalVisible,
    } = React.useContext(BrowserViewContext);

    const { authInfo, focusSDK, appRuntimeEnv, service } = React.useContext<GlobalContextConfig>(
        GlobalContext
    );
    // const [link, setLink] = useState(src);
    const [percent, setPercent] = useState(0);
    const [ready, setReady] = useState(false);
    const [progressShow, setProgressShow] = useState(true);
    const webview = React.useRef<HTMLWebViewElement | null>(null);
    const sidRef: any = React.useRef(0);
    const percentRef = React.useRef(0);
    const [loadFailInfo, setLoadFailInfo] = useState<ErrorInfo | null>(null);
    const currUrl = React.useRef('');
    percentRef.current = percent;
    const [webViewModalCid, setWebViewModalCid] = useState('');
    let comp = <Loading style={{ height: '100%' }} />;
    const [preWebviewModalVisible, setPreWebviewModalVisible] = useState(webviewModalVisible);

    useEffect(() => {
        const wv = webview.current as any;
        if (!webviewModalVisible && preWebviewModalVisible && webViewModalCid) {
            wv.send('clientMessage', {
                cid: webViewModalCid,
                data: null,
            });
            setPreWebviewModalVisible(false);
        }
        if (!preWebviewModalVisible && webviewModalVisible) {
            setPreWebviewModalVisible(true);
        }
    }, [webviewModalVisible, webViewModalCid, preWebviewModalVisible]);

    const fetchThirdApps = useCallback(async () => {
        if (!authInfo || !navigator.onLine) {
            return;
        }
        const group = await service.appStoreService
            .getGroupApplications({
                userId: authInfo?.userId,
                teamId: appRuntimeEnv.selectedTeamId,
            })
            .catch((e) => {
                console.log(`fetchThirdApps--error: `, e);
            });
        if (!group) {
            return;
        }
        const { groupApplications } = group;

        if (groupApplications) {
            const thirdAppList = groupApplications.reduce((a: any, b: any) => {
                return a.concat(b.applications);
            }, []);
            if (thirdAppList?.length) {
                await updatehirdAppList({ thirdAppList });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appRuntimeEnv.selectedTeamId, authInfo?.userId, service.appStoreService]);

    useEffect(() => {
        fetchThirdApps();
    }, [fetchThirdApps]);

    const increasePercent = React.useCallback((p: number) => {
        if (percentRef.current >= 100) {
            return;
        }
        if (p >= 100) {
            setPercent(100);
            if (sidRef.current) {
                clearTimeout(sidRef.current);
            }
            setTimeout(() => {
                setProgressShow(false);
            }, 500);
            return;
        }
        const n = p + 20;
        setPercent(n >= 90 ? 90 : n);
        sidRef.current = setTimeout(() => {
            increasePercent(n);
        }, 500);
    }, []);

    const isTrustHost = async (url: string, hosts: string[], thirdAppMap: any) => {
        const hostName = new URL(url).hostname;
        let HOST_LIST = DEFAULT_HOST_LIST;
        try {
            const configHost = FocusSDK.isPrivateNet()
                ? 'https://jzb.beijing.egov.cn/oss/focus-desktop'
                : 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
            const { data, status } = await Axios.get(
                `${configHost}/config/${
                    appRuntimeEnv.envConfig.key
                }/jssdk-config.json?d=${Date.now()}`
            );
            if (status === 200) {
                const { whiteList } = data;
                if (Array.isArray(whiteList)) {
                    HOST_LIST = Array.from(new Set(DEFAULT_HOST_LIST.concat(whiteList)));
                }
            }
        } catch (error) {
            console.error(error);
        }
        log.debug('HOST_LIST', HOST_LIST);
        if (hosts.length !== 0) {
            const res = [...hosts, ...HOST_LIST].filter((i: string) => hostName.indexOf(i) > -1);
            return res.length > 0;
        } else {
            const apps = Object.keys(thirdAppMap || {});
            for (let k of apps) {
                const item = thirdAppMap[k];
                if (item) {
                    const hosts = item.trustedDomains ? item.trustedDomains.split(';') : [];
                    const res = [...hosts, ...HOST_LIST].filter(
                        (i: string) => hostName.indexOf(i) > -1
                    );
                    if (res.length > 0) {
                        return true;
                    }
                }
            }
            return false;
        }
    };
    const failLoadCb = React.useCallback((err: any) => {
        log.error(`webview Third app(${item.name}:${item.id}) load err: `, err);
        if (err?.errorCode === -3) {
            return;
        }
        setLoadFailInfo({
            type: ErrorType.TIMEOUT,
        });
        // increasePercent(100);
    }, []); // eslint-disable-line

    const onResponseCb = React.useCallback((rep: any) => {
        log.debug(`webview onResponseCb`, rep);
        const url = rep.url;
        currUrl.current = url;
        if (!rep) {
            setLoadFailInfo({
                type: ErrorType.TIMEOUT,
            });
            return;
        }
        if (rep.httpResponseCode === 404) {
            setLoadFailInfo({
                type: ErrorType.NOTFOUND,
                code: rep.httpResponseCode,
            });
            return;
        } else if (rep.httpResponseCode === 504 || rep.httpResponseCode === 408) {
            setLoadFailInfo({
                type: ErrorType.TIMEOUT,
                code: rep.httpResponseCode,
            });
            return;
        } else if (rep.httpResponseCode === 401 || rep.httpResponseCode === 403) {
            setLoadFailInfo({
                type: ErrorType.FORBIDEN,
                code: rep.httpResponseCode,
            });
            return;
        } else {
            const num = parseInt(`${(parseInt(rep.httpResponseCode, 10) % 1000) / 100}`, 10);
            if (num === 4 || num === 5) {
                setLoadFailInfo({
                    type: ErrorType.SEVERERROR,
                    code: rep.httpResponseCode,
                });
                return;
            }
        }
        setLoadFailInfo(null);
    }, []);
    const readyCb = React.useCallback(
        async (rep: any) => {
            if (webview.current && focusSDK.isBeta()) {
                // @ts-ignore
                webview.current.openDevTools();
            }
            const trustedDomains = item.trustedDomains ? item.trustedDomains.split(';') : [];
            // 检测域名
            try {
                const isTrust = await isTrustHost(currUrl.current, trustedDomains, thirdAppMap);

                if (isTrust) {
                    (webview.current as any).executeJavaScript(
                        `window.FocusSDK && window.FocusSDK.install &&  window.FocusSDK.install()`
                    );
                } else {
                    (webview.current as any).executeJavaScript(`window.FocusSDK={}`);
                }
                console.log(`Third app(${item.name}:${item.id}) ready.`, rep, isTrust);
            } catch (e) {
                console.error(`readyCb--err`, e);
            }
            setReady(true);
            increasePercent(100);
            setTimeout(() => {
                if (webview.current) {
                    webview.current.blur();
                    webview.current.focus();
                }
            }, 0);
        },
        [increasePercent, currUrl, thirdAppMap] //eslint-disable-line
    );

    const refreshCb = React.useCallback(() => {
        if (webview.current) {
            // @ts-ignore
            webview.current.reload();
        }
    }, []);
    const goForwardCb = React.useCallback(() => {
        const ref = webview.current as any;
        if (ref.canGoForward()) {
            ref.goForward();
        }
    }, []);
    const goBackCb = React.useCallback(() => {
        const ref = webview.current as any;
        if (ref.canGoBack()) {
            ref.canGoBack();
        }
    }, []);
    const newWindowCb = React.useCallback(
        (e: any) => {
            const url = new URL(e.url);
            const { protocol, host } = url;
            // console.log('--->', { protocol, host });
            if (!host) {
                return;
            }
            // TODO 这里需要校验 host
            if (!configData.allowedHosts.find((a: string) => a === host)) {
                // remote.shell.openExternal(e.url);
                // return;
            }
            if (protocol === 'http:' || protocol === 'https:') {
                e.preventDefault();
                const classNameArr = e.currentTarget.className.split(' ');
                // eslint-disable-next-line array-callback-return
                let classItem = '';
                classNameArr.forEach((item: any) => {
                    if (item.indexOf('thridapp-') >= 0) {
                        classItem = item;
                    }
                });
                if (!e.currentTarget) return;
                let appKey: any = e.currentTarget?.id?.replace('thridapp-', '');
                let version: string = classItem.replace('thridapp-', '');
                console.log('应用版本号', appKey, version);
                if (onNewWindowOpen) {
                    onNewWindowOpen(e.url, appKey, version);
                }
            }
        },
        [configData.allowedHosts, onNewWindowOpen]
    );

    const bindEventsCb = React.useCallback(() => {
        if (!webview.current || !src) return;
        const isJoyspaceUrl = checkJoyspaceUrl(src).is;
        // eslint-disable-next-line no-debugger
        if (isJoyspaceUrl) {
            webview.current.setAttribute(
                'preload',
                focusSDK.getInnerAppPreloadJSPath(item.version)
            );
        } else {
            webview.current.setAttribute('preload', focusSDK.getAppPreloadJSPath(item.version));
        }

        webview.current.removeEventListener('did-fail-load', failLoadCb);
        webview.current.addEventListener('did-fail-load', failLoadCb);

        webview.current.removeEventListener('did-navigate', onResponseCb);
        webview.current.addEventListener('did-navigate', onResponseCb);

        webview.current.removeEventListener('dom-ready', readyCb);
        webview.current.addEventListener('dom-ready', readyCb);
    }, [failLoadCb, focusSDK, item.version, onResponseCb, readyCb, src]);

    const pageFaviconUpdatedCb = React.useCallback(
        ({ favicons, title }) => {
            if (!onPageMetaDataChanged) {
                return;
            }
            if (Array.isArray(favicons) && favicons.length) {
                onPageMetaDataChanged({
                    id: item.id,
                    favicon: favicons[0],
                });
            }

            if (title) {
                onPageMetaDataChanged({
                    id: item.id,
                    title,
                });
            }
        },
        [item.id, onPageMetaDataChanged]
    );

    if (src) {
        if (configData) {
            comp = loadFailInfo ? (
                <ErrorPage thirdAppInfo={item} errorInfo={loadFailInfo} />
            ) : (
                <webview
                    ref={(ref) => {
                        webview.current = ref;
                        bindEventsCb();
                    }}
                    style={{ flex: 1 }}
                    id={`thridapp-${item.id}`}
                    className={`thridapp-${item.version} third_application_class`}
                    src={changeEgovUrl(src)}
                    nodeintegration={true}
                    webpreferences="contextIsolation=no"
                    partition={configData.partition} // TODO 解决 webview cookies 换账号后依然有缓存的问题，这种方式是有效果的，但是还不知道是否有坑，待日后观察
                />
            );
        }
    }

    const webviewRef = webview.current as any;

    const toolbar = (
        <div
            className={['me-thirdapp-webview-toolbar', !withToolbar ? 'no-toolbar' : ''].join(' ')}
        >
            {withToolbar && (
                <>
                    <div className="me-thirdapp-webview-actions-l">
                        <Button
                            type="text"
                            size="small"
                            disabled={!ready || !webviewRef || !webviewRef.canGoBack()}
                            onClick={goBackCb}
                        >
                            {IconArrowLeft}
                        </Button>
                        <Button
                            type="text"
                            size="small"
                            disabled={!ready || !webviewRef || !webviewRef.canGoForward()}
                            onClick={goForwardCb}
                        >
                            {IconArrowRight}
                        </Button>
                        <Button type="text" size="small" onClick={refreshCb}>
                            {IconRefresh}
                        </Button>
                    </div>
                    <div className="me-thirdapp-webview-actions-r">
                        <Button type="text" size="small">
                            {IconMore}
                        </Button>
                    </div>
                </>
            )}
            {progressShow && (
                <Progress
                    type="line"
                    strokeWidth={2}
                    percent={percent}
                    showInfo={false}
                    trailColor="transparent"
                    // strokeColor={styles.color.primary}
                    className="webview-progress"
                />
            )}
        </div>
    );
    //
    // const onGlobalMessage = useCallback((_e: IpcRendererEvent, cid: string, data: any) => {
    //     log.debug('BrowserWindow Webview globalMessage', { cid, data });
    //     const wv = webview.current as any;
    //     if (!wv) {
    //         return;
    //     }
    //     wv.send('clientMessage', { cid, data });
    // }, []);

    // eslint-disable-next-line complexity
    const handleClientMessages = ({ cid, type, data }: any) => {
        // const webview = this.webviewRef.current as any;
        // const { config: contextConfig, service } = context as GlobalContextType;
        // console.log('handleClientMessages', { cid, type, data });
        if (!appRuntimeEnv) {
            log.error(
                'no appRuntimeEnv when handleClientMessages',
                JSON.stringify({ type, data }, null, '\t')
            );
            return;
        }
        // const { userDataForWeb } = authInfo;

        const wv = webview.current as any;
        switch (type) {
            case 'checkNeedAuth':
                service.appStoreService
                    .checkApplicationNeedAuth({
                        userId: authInfo?.userId,
                        teamId: appRuntimeEnv.selectedTeamId,
                        mode: 'sdk',
                        appKey: data.appKey,
                    })
                    .then((res) => {
                        wv.send('clientMessage', {
                            cid,
                            data: res,
                        });
                    })
                    .catch((e) => {
                        wv.send('clientMessage', {
                            cid,
                            data: {},
                        });
                    });
                break;
            case 'submitApplicationAuth':
                service.appStoreService
                    .submitApplicationAuth({
                        userId: authInfo?.userId,
                        teamId: appRuntimeEnv.selectedTeamId,
                        mode: 'sdk',
                        appKey: data?.appKey,
                        authorize: !!data.authorize,
                    })
                    .then((res) => {
                        wv.send('clientMessage', {
                            cid,
                            data: res,
                        });
                    })
                    .catch((e) => {
                        wv.send('clientMessage', {
                            cid,
                            data: {},
                        });
                    });
                break;
            case 'joinMeeting':
                if (!data.meetingId || !data.realName) {
                    wv.send('clientMessage', {
                        cid,
                        data: {
                            result: false,
                            msg: '缺失必要的请求参数',
                        },
                    });
                    return;
                }
                joinMeeting(data);
                wv.send('clientMessage', {
                    cid,
                    data: {
                        result: true,
                    },
                });
                break;
            case 'getUserInfo':
                wv.send('clientMessage', {
                    cid,
                    data: filterUserInfo(registryConfigData),
                });
                break;
            case 'getThirdAppUserInfo':
            case 'getAuthInfo':
                // log.debug('AppSdk ==>', 'getAuthInfo', JSON.stringify(userDataForWeb));
                wv.send('clientMessage', {
                    cid,
                    data: appRuntimeEnv.userDataForWeb,
                });
                break;
            case 'getAppRuntimeEnv':
                wv.send('clientMessage', {
                    cid,
                    data: appRuntimeEnv,
                });
                break;
            case 'openWebViewModal': {
                if (data) {
                    setWebViewModalCid(cid);
                    openWebViewModal(data);
                }
                break;
            }
            case 'closeWebViewModal': {
                closeWebViewModal(webview.current?.id.replace('thridapp-', ''));
                // closeWebViewModal();
                // wv.send('clientMessage', {
                //     cid: webViewModalCid,
                //     data: null,
                // });
                break;
            }
            case 'openCommonModal': {
                if (data) {
                    openCommonModal(data);
                }
                // ipcRenderer.emit('openWebViewModal', null, 'about');
                break;
            }
            case 'viewImages': {
                if (data) {
                    viewImages(data);
                }
                // ipcRenderer.emit('openWebViewModal', null, 'about');
                break;
            }
            case 'openExternal': {
                if (data) {
                    openExternal(data);
                }
                break;
            }
            case 'openDevTools': {
                // console.log('this is opendevtools');
                // @ts-ignore
                webview?.current?.openDevTools();
                break;
            }
            case 'getAuthorizationCode': {
                getAuthorizationCode(data)
                    .then((res) => {
                        let result;
                        const [success, err] = res;
                        if (success) {
                            result = {
                                statusCode: 0,
                                ...success,
                            };
                        } else {
                            result = {
                                statusCode: 1,
                                err,
                            };
                        }
                        wv.send('clientMessage', {
                            cid,
                            data: result,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                break;
            }
            case 'openGroupChat': {
                if (data) {
                    openGroupChat(data);
                }
                break;
            }
            case 'openSingleChat': {
                if (data) {
                    openSingleChat(data);
                }
                break;
            }
            case 'openUserSelectModel': {
                if (data) {
                    const currentUser = (registryConfigData.auth as unknown) as UserModel;
                    openUserSelectionModal(
                        {
                            ...data,
                            currentUser: {
                                pin: currentUser.userId,
                                app: currentUser.ddAppId,
                                teamId: currentUser.teamUserInfo.teamId,
                            },
                        },
                        (result, close) => {
                            // eslint-disable-next-line no-debugger
                            close?.();
                            wv.send('clientMessage', {
                                cid,
                                data: result.data,
                            });
                        },
                        currentUser
                    );
                }
                break;
            }
            default:
        }
    };

    const handleIPCMessage = (event: any) => {
        const { args, channel } = event;
        const [methodContent] = args || []; // 调用方法内容 方法名 方法参数
        switch (channel) {
            case 'clientMessage':
                if (methodContent) {
                    handleClientMessages(methodContent);
                }
                break;
            default:
                break;
        }
    };
    const onGlobalWebviewMessage = (data: any) => {
        const wv = webview.current;
        if (!wv) {
            return () => {};
        }
        FocusSDK.printLog('webviewMessage111', data);
        // @ts-ignore
        wv.send('webviewMessage', data);
    };
    useEffect(() => {
        const dispose = focusSDK.addIpcEventListener(
            'global-webview-message',
            onGlobalWebviewMessage
        );
        return () => {
            dispose();
        };
    }, [focusSDK]);
    useEffect(() => {
        increasePercent(0);
        const wv = webview.current;
        if (!wv) {
            return () => {};
        }
        wv.addEventListener('ipc-message', handleIPCMessage);
        wv.addEventListener('page-favicon-updated', pageFaviconUpdatedCb);
        wv.addEventListener('page-title-updated', pageFaviconUpdatedCb);
        wv.addEventListener('new-window', newWindowCb);
        // ipcRenderer.on('gm.userSelectionAction', onGlobalMessage);

        return () => {
            wv.removeEventListener('ipc-message', handleIPCMessage);

            wv.removeEventListener('page-favicon-updated', pageFaviconUpdatedCb);
            wv.removeEventListener('page-title-updated', pageFaviconUpdatedCb);
            wv.removeEventListener('new-window', newWindowCb);
            // ipcRenderer.off('gm.userSelectionAction', onGlobalMessage);
        };
    }, [increasePercent, newWindowCb, pageFaviconUpdatedCb]); //eslint-disable-line

    return (
        <div
            style={{
                flex: 1,
                display: visible ? 'flex' : 'none',
                flexDirection: 'column',
            }}
        >
            {toolbar}
            {comp}
        </div>
    );
}

function mapStateToProps(props: { thirdapp: any }) {
    const { thirdapp } = props;
    return {
        thirdAppMap: thirdapp.thirdAppMap,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updatehirdAppList: (data: { thirdAppList: any }) =>
            dispatch({
                type: 'thirdapp/updatehirdAppList',
                payload: data,
            }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebView);
