/* eslint-disable no-console */
/**
 * ai助手内容tab头部
 */
import React, { useEffect, useState, useRef } from 'react';
import './index.less';
import { connect } from 'dva';
import { SessionProps } from '@/types/chat';
import ChatState, { AITabState } from '@/types/chat/State';

import CHAT_NOTICE from '@/contant/chat';

import AINoticeTabList from '../AINoticeTabList';
import TabControllerBtn from './TabControllerBtn';
import TabControllerModal from './ControllerModal';
import ImService from '@/server/ImService';

interface IProps {
    updateFilterTabList: Function;
    updateSelectedAITab: Function;
    filterTabList: any[];
    selectedSession: SessionProps;
    selectedAITab: AITabState;
}

interface TabsOffset {
    [key: string]: { width: number; left: number };
}

// eslint-disable-next-line complexity
function AINoticeTabHeader(props: IProps) {
    const {
        filterTabList,
        updateFilterTabList,
        selectedSession,
        selectedAITab,
        updateSelectedAITab,
    } = props;

    const [tabList, setTabList] = useState<AITabState[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [hiddenTabs, setHiddenTabs] = useState<AITabState[]>([]);
    const [hiddenIndexData, setHiddenIndexData] = useState<{
        startIndex: number;
        endIndex: number;
    }>({
        startIndex: 0,
        endIndex: 0,
    });
    const [tabsOffset, setTabsOffset] = useState<TabsOffset>();
    const tabsWrapperRef = useRef<any>();

    useEffect(() => {
        const array: any[] = [...formatTabList(filterTabList)];
        setTabList(array);
    }, [filterTabList]);

    /**
     * 处理tabList，添加全部
     * @param filterTabList 选中筛选的tabList
     * @returns 处理后的tabList
     */
    const formatTabList = (filterTabList: any[]) => {
        let tabList = [...filterTabList];
        if (tabList.findIndex((item) => item.pin === CHAT_NOTICE.ALL_PIN) === -1) {
            tabList.unshift({
                pin: 'all-notice',
                name: '全部',
            });
        }
        // const otherIdx = tabList.findIndex((item) => item.pin === CHAT_NOTICE.OTHER_PIN);
        // if (otherIdx !== -1) {
        //     tabList.splice(otherIdx, 1);
        // }
        return tabList;
    };

    const handleOk = (selectedTabList: any[]) => {
        const tabList = [...formatTabList(selectedTabList)];
        const selectedTabPin = selectedAITab.pin;
        if (!tabList.find((item) => item.pin === selectedTabPin)) {
            selectedAITab.pin = CHAT_NOTICE.ALL_PIN;
        }
        updateSelectedAITab({ selectedAITab });
        setTabList(tabList);
        handleCancel();
        updateSelectedTabList(selectedTabList);
    };

    const updateSelectedTabList = (selectedTabList: any[]) => {
        updateFilterTabList({ filterTabList: selectedTabList });
        console.log(JSON.stringify(selectedTabList));
        ImService.getInstance().updateSession(selectedSession.sessionId, {
            ext: JSON.stringify({ filterTabList: selectedTabList }),
        });
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const computeHiddenTabs = (startIndex: number, endIndex: number) => {
        const startHiddenTabs = tabList.slice(0, startIndex);
        const endHiddenTabs = tabList.slice(endIndex + 1);
        const hiddenTabs = [...startHiddenTabs, ...endHiddenTabs];
        console.log(hiddenTabs, '222', startIndex, endIndex, tabList);
        setHiddenIndexData({
            startIndex,
            endIndex,
        });
        setHiddenTabs(hiddenTabs);
    };

    const onTabDelete = (item: AITabState, index: number) => {
        if (selectedAITab.pin === item.pin) {
            updateSelectedAITab({ selectedAITab: { pin: CHAT_NOTICE.ALL_PIN } });
        }
        tabList.splice(index, 1);
        updateSelectedTabList([...tabList]);
    };

    const updateTabsOffset = (offset: TabsOffset) => {
        setTabsOffset(offset);
    };

    const scrollToTargetTab = (item: AITabState) => {
        const offset = tabsOffset?.[item.pin] || { width: 0, left: 0 };
        const index = tabList.findIndex((tab) => tab.pin === item.pin);
        updateSelectedAITab({ selectedAITab: item });
        const { endIndex } = hiddenIndexData;
        tabsWrapperRef.current.scrollTo({
            top: 0,
            left: index >= endIndex ? offset.left + offset.width : offset.left,
            behavior: 'smooth',
        });
    };

    return (
        <div className="notice-tab-header">
            <div className="tab-list-content" ref={tabsWrapperRef} id="tabListContent">
                <AINoticeTabList
                    tabList={tabList}
                    activeKey={selectedAITab.pin}
                    wrapperWidth={tabsWrapperRef.current?.offsetWidth || 0}
                    wrapperLeft={tabsWrapperRef.current?.getBoundingClientRect().left || 0}
                    computeHiddenTabs={computeHiddenTabs}
                    onTabDelete={onTabDelete}
                    updateTabsOffset={updateTabsOffset}
                />
            </div>
            <TabControllerBtn
                showControllerModal={() => setModalVisible(true)}
                hiddenTabs={hiddenTabs}
                scrollToTargetTab={scrollToTargetTab}
            />
            {modalVisible && (
                <TabControllerModal
                    visible={modalVisible}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    selectedTabList={[...filterTabList]}
                />
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { filterTabList, selectedSession, selectedAITab } = chat as ChatState;

    return {
        filterTabList,
        selectedSession,
        selectedAITab,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        async updateFilterTabList(data: { selectedSession: SessionProps }) {
            await dispatch({ type: 'chat/updateFilterTabList', payload: data });
        },
        async updateSelectedAITab(data: { selectedAITab: AITabState }) {
            await dispatch({ type: 'chat/updateSelectedAITab', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AINoticeTabHeader);
