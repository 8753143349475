/**
 * 人员卡片分享逻辑
 */
import Bus from '@/utils/bus';
import { openUserSelectionModal } from '@/utils/modals';
import { UserModel } from '@/models/user';
import { message } from 'antd';
import { Employee, UID } from '@/types/chat';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getSelectorSessions } from '@/components/chat/utils/session';
import {
    UserSelectionOptions,
    UserSelectionResult,
} from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection/types';
import lodashGet from 'lodash/get';
import { UserCardData } from '@/types/UserCard';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import { buildTemplate2MessageBody, sleep } from '@/components/chat/utils/message';
import { ListItemData } from '@/components/ListItem';
import lodashCompact from 'lodash/compact';
import config, { ConfigEnum } from '@/config/config';

// 点击分享 调出人员选择器
// 选择会话
// 确认以后， 发送消息， 不做任何会话

function useShareUserCard({
    userData,
    currentEmployee,
}: {
    userData: UserModel;
    currentEmployee: Employee;
}) {
    const { t } = useTranslation('chat');
    const { imService } = useContext<GlobalContextConfig>(GlobalContext);

    // 会话列表
    const getSessions = async () => {
        const allSessionList = await imService.getAllSessions({}, false);
        return getSelectorSessions(allSessionList);
    };

    const currentUser: UID = {
        pin: userData?.userId,
        teamId: userData?.teamUserInfo?.teamId,
        app: userData?.ddAppId,
        name: userData?.teamUserInfo?.realName,
        avatar: userData?.teamUserInfo?.headImg || undefined,
    };

    const getShareCardInfo = (
        userCardData: UserCardData & { avatar?: string; name?: string; app?: string }
    ) => {
        return [
            {
                type: 1,
                sharecard: {
                    pin: userCardData.userId,
                    teamId: userCardData.teamId,
                    app: userCardData?.appId || userCardData?.app,
                    avatar: getFieldsValue(userCardData, 'avatar') || userCardData?.avatar,
                    nickName: getFieldsValue(userCardData, 'name') || userCardData?.name,
                    realName: getFieldsValue(userCardData, 'name') || userCardData?.name,
                },
            },
        ];
    };

    // 人员选择器 入参

    const getSelectorOptions = async () => {
        return {
            title: t('select-session-contacts'),
            // fixedUsers: await getSessions(),
            // frozenIds: [],
            currentUser,
            // mode: SelectorMode.forward,
            mode: SelectorMode.user,
            tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                ? ['recentIncludeGroup', 'org', 'groupCanBeSel', 'externalContacts']
                : ['recentIncludeGroup', 'groupCanBeSel', 'externalContacts'],
            // contactsFilterFlag: 'external',

            // resultUsers: [],
        };
    };

    const getFieldsValue = (userCardData: UserCardData, key: string) => {
        const item = userCardData?.fields?.find((item) => item.key === key);
        return (item || ({} as any)).val || '';
    };

    const getShareCardMessageBody = (userCardData: UserCardData): any => {
        const cardInfo = getShareCardInfo(userCardData);
        const data = {
            nativeId: 'share_card',
            data: cardInfo,
            currentEmployee,
            id: generateMessageId(),
        };
        return buildTemplate2MessageBody(data);
    };

    // // 人员选择器回调
    const selectorCallback = (userCardData: UserCardData) => {
        return async (data: UserSelectionResult, close: () => void) => {
            const selectedData = lodashGet(data, 'data.result', []) as ListItemData[];
            if (selectedData.length > 0) {
                // 构建消息体
                await sendShardUserCardMessage(selectedData, userCardData);
                close();
                message.success('分享成功');
                setTimeout(closeUserCard, 10);
            }
        };
    };

    // 打开人员选择器
    // const openSelector = async (userCardData: UserCardData) => {
    //     const selectorOptions = await getSelectorOptions();
    //     const callback = selectorCallback(userCardData);
    //     openUserSelectionModal(selectorOptions, callback, userData);
    // };

    // 打开人员选择器
    const openSelector = async (userCardData: UserCardData) => {
        const selectorOptions = await getSelectorOptions();

        const callback = selectorCallback(userCardData);
        openUserSelectionModal(selectorOptions as UserSelectionOptions, callback, userData);
    };

    // 发送卡片消息
    const sendShardUserCardMessage = async (
        selectedData: ListItemData[],
        userCardData: UserCardData
    ) => {
        const body = getShareCardMessageBody(userCardData);
        const compactData = lodashCompact(selectedData);
        let imSendPromise = [];
        for (let item of compactData) {
            // 分享到组需要有sessionId
            if (item.isGroup) {
                item.sessionId = item?.id || item?.groupId;
            }
            // 查询store session lastMid
            // 发多条时，不要用同一个id;
            const messageId = generateMessageId();
            if (item.sessionId) {
                imSendPromise.push(
                    imService.sendChatMessage(item.sessionId, messageId, {
                        ...body,
                        id: messageId,
                    })
                );
            } else {
                const sessionId = imService.generateSingleSessionId(currentEmployee, {
                    pin: item?.pin || item?.origin?.uid?.pin,
                    app: item?.app || item?.origin?.uid?.app,
                    teamId: item?.teamId || item?.origin?.uid?.teamId,
                });
                imSendPromise.push(
                    imService.sendChatMessage(sessionId, generateMessageId(), body, {
                        skipCheckSession: true,
                    })
                );
            }
        }
        return Promise.all(imSendPromise);
    };

    // 关闭人员卡片
    const closeUserCard = () => {
        Bus.emit('app:toggle-user-card-modal:hide');
    };

    return async (userCardData: UserCardData) => {
        await openSelector(userCardData);
    };
}

export default useShareUserCard;
