import React from 'react';
import './index.less';
import image1 from '@/assets/images/im/no-data-guide_2.png';
import image2 from '@/assets/images/im/no-data_2.png';
// import image3 from '@/assets/images/im/no-data-guide.png';
// import image4 from '@/assets/images/im/no-data.png';
import { useTranslation } from 'react-i18next';
import { MessageNotifyType } from '@/types/chat/enum';

interface NoDataProps {
    type: String;
}

export default function NoDataTips(props: NoDataProps) {
    const { type } = props;
    const { t } = useTranslation('chat');

    // console.log(type);
    return (
        <div className="no-data-wrapper">
            <div className="img1">
                <img src={image1} />
            </div>
            <div className="context" style={{ color: '#62656D' }}>
                <img src={image2} />
                <span>{t(`list.${type}_text`)}</span>
                {type !== MessageNotifyType.ALL && <span>请切换至“全部”查看</span>}
            </div>
        </div>
    );
}
