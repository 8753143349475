import React from 'react';
import styles from '@/components/styles';
import { IconClose } from '@/components/icon';

const { color } = styles;

const TabItem = ({
    name,
    icon,
    active,
    minWidth,
    onClick,
    onItemClose,
    withoutClose,
    clstag,
}: {
    name: string;
    icon: any;
    active?: boolean;
    minWidth?: number;
    onClick: () => void;
    onItemClose?: () => void;
    withoutClose?: boolean;
    clstag?: string;
}) => (
    <div
        style={{
            position: 'relative',
            cursor: 'pointer',
            overflow: 'hidden',
            height: '33px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            maxWidth: '150px',
            minWidth: minWidth,
            padding: '0 10px',
            borderRadius: '12px 12px 0 0',
        }}
        className={active ? 'active-tab' : 'tab'}
        onClick={onClick}
        tabIndex={0}
    >
        {!active && (
            <div
                style={{
                    position: 'absolute',
                    width: 1,
                    height: 12,
                    backgroundColor: '#DEE0E3',
                    left: 0,
                    top: '50%',
                    marginTop: -6,
                }}
            />
        )}
        <span
            style={{
                display: 'inline-flex',
                color: color.primary2,
                width: 16,
            }}
        >
            {icon}
        </span>
        <span
            style={{
                display: 'inline-block',
                fontSize: '13px',
                padding: '0 10px',
                height: '20px',
                overflow: 'hidden',
            }}
        >
            {name}
        </span>
        {!withoutClose && (
            <span
                style={{ color: '#62656D', fontSize: '9px' }}
                clstag={clstag}
                onClick={onItemClose}
            >
                {IconClose}
            </span>
        )}
    </div>
    // <div
    //     style={{
    //         backgroundColor: active ? '#fff' : 'transparent',
    //         padding: '10px 15px',
    //         position: 'relative',
    //         height: '100%',
    //         display: 'flex',
    //         alignItems: 'center',
    //         borderRadius: '12px 12px 0 0',
    //         cursor: 'pointer',
    //         ...(active ? { zIndex: 1 } : {}),
    //     }}
    //     role="button"
    //     onClick={onClick}
    //     onKeyDown={() => {}}
    //     tabIndex={0}
    // >
    //     {!active && (
    //         <div
    //             style={{
    //                 position: 'absolute',
    //                 width: 1,
    //                 height: 12,
    //                 backgroundColor: '#DEE0E3',
    //                 left: -1,
    //                 top: '50%',
    //                 marginTop: -6,
    //             }}
    //         />
    //     )}
    //     <div style={{ flex: 1, display: 'flex' }}>
    //         <span style={{ color: color.primary2, width: 16 }}>{icon}</span>
    //         <span
    //             style={{
    //                 flex: 1,
    //                 margin: '0 10px',
    //                 width: 105,
    //                 textOverflow: 'clip',
    //                 overflow: 'hidden',
    //                 whiteSpace: 'nowrap',
    //                 display: 'inline-block',
    //             }}
    //         >
    //             {name}
    //             <div
    //                 style={{
    //                     width: 30,
    //                     backgroundColor: 'transparent',
    //                     backgroundImage: `linear-gradient(to left, ${
    //                         active ? '#fff' : '#F6F6F6FF'
    //                     }, transparent)`,
    //                     display: 'inline-block',
    //                     position: 'absolute',
    //                     right: 30,
    //                     height: '100%',
    //                     marginRight: 10,
    //                 }}
    //             />
    //         </span>
    //     </div>
    //     {!withoutClose && (
    //         <span
    //             style={{
    //                 fontSize: 8,
    //                 textAlign: 'center',
    //                 color: '#62656D',
    //                 width: 18,
    //                 height: 18,
    //                 backgroundColor: active ? '#DEE0E3FF' : 'transparent',
    //                 borderRadius: '50%',
    //             }}
    //             className="me-thirdapp-tabs-item-close"
    //             onClick={onItemClose}
    //             tabIndex={0}
    //             role="button"
    //             onKeyDown={() => {}}
    //         >
    //             {IconClose}
    //         </span>
    //     )}
    // </div>
);

export default TabItem;
