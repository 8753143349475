import { PROCESS_STATUS_EXECUTORS } from '@/components/Projects/emnu';
import { Progress, Button, Checkbox, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { WorkUserInfo } from '@/types/newWork';
import { useTranslation } from 'react-i18next';
import './ExecutorsProgress.less';
// import { number2percentum, percentum2number } from '../../BaseComponents/WorkUserListWeightSet';

export default function ExecutorsProgress({
    members,
    urgeSomeOne,
}: {
    members: WorkUserInfo[];
    urgeSomeOne?: any;
}) {
    const { t } = useTranslation('work');
    const [batchUrging, setBatchUrging] = useState(true);
    const [choosedArr, setChoosedArr] = useState<any[]>(
        members
            .map((v, index) => ({
                checked: true,
                ...v,
            }))
            .filter((v) => v.processStatus !== 2)
    );
    useEffect(() => {
        const old_checked_arr = choosedArr.map((v) => v.checked);
        setChoosedArr(
            members
                .map((v, index) => ({
                    checked: old_checked_arr[index] ?? false, // 保证：页面显示勾选上的和choosedArr一致
                    ...v,
                }))
                .filter((v, i) => v.processStatus !== 2)
        );
    }, [members]); // eslint-disable-line react-hooks/exhaustive-deps
    const onChange = useCallback(
        (o, e) => {
            let val = e.target.checked;
            let her = choosedArr.find((v) => v.userId === o.userId);
            her.checked = val;
            setChoosedArr([...choosedArr]);
        },
        [choosedArr]
    );
    const canUrge = members.find((v) => v.processStatus !== 2);
    return (
        <>
            <div
                className="instruction-header"
                style={{ display: members.length > 0 && urgeSomeOne && canUrge ? '' : 'none' }}
            >
                {!batchUrging ? (
                    <section>
                        <span style={{ color: '#232930' }}>共 {members.length} 人</span>
                        <Button
                            type="link"
                            onClick={() => {
                                setBatchUrging(true);
                                setChoosedArr(
                                    members
                                        .map((v, index) => ({
                                            checked: true,
                                            ...v,
                                        }))
                                        .filter((v) => v.processStatus !== 2)
                                );
                            }}
                        >
                            批量{t('urge')}
                        </Button>
                    </section>
                ) : (
                    <section>
                        <span>
                            <em>已选 {choosedArr.filter((v) => v.checked).length} 人</em> / 共{' '}
                            {members.length}人
                        </span>
                        <div>
                            <Button
                                type="link"
                                onClick={() => {
                                    let ret = choosedArr
                                        .filter((v) => v.checked)
                                        .map((v) => ({
                                            userId: v?.userId,
                                            teamId: v?.teamId,
                                            appId: v?.app,
                                            headImg: v?.headImg,
                                            realName: v?.realName,
                                        }));
                                    if (ret.length === 0) {
                                        return message.warn('请选择催办接收人');
                                    }
                                    urgeSomeOne(ret);
                                }}
                            >
                                立即{t('urge')}
                            </Button>
                            {/* <Button
                                type="text"
                                onClick={() => {
                                    setBatchUrging(false);
                                }}
                            >
                                取消
                            </Button> */}
                        </div>
                    </section>
                )}
            </div>
            <ul className="executors-progress-wrapper">
                {members.map((v, index) => (
                    <li
                        key={index}
                        className={`my-process-status process-status-${v.processStatus}`}
                    >
                        {canUrge &&
                            (v.processStatus === 2 ? (
                                <Checkbox checked={false} disabled={true} />
                            ) : (
                                <Checkbox onChange={onChange.bind(null, v)} defaultChecked={true} />
                            ))}
                        <span
                            className="ranking-crew"
                            style={{ marginRight: 'auto' }}
                            title={v.realName}
                        >{`${index + 1}、${v.realName}`}</span>
                        {/* <Progress percent={v.progress} size="small" showInfo={false} /> */}
                        <i
                            className={`personal-process-status tag-process-status-${v.processStatus}`}
                        >
                            {PROCESS_STATUS_EXECUTORS[v.processStatus]}
                        </i>
                        {/* <em className="percent-em">{`${v.progress}%`}</em> */}
                        {/* {urgeSomeOne && !batchUrging && (
                            <b>
                                <Button
                                    type="link"
                                    block
                                    disabled={v.processStatus === 2}
                                    onClick={() => {
                                        urgeSomeOne([
                                            {
                                                userId: v?.userId,
                                                teamId: v?.teamId,
                                                appId: v?.app,
                                                headImg: v?.headImg,
                                                realName: v?.realName,
                                            },
                                        ]);
                                    }}
                                >
                                    {t('urge')}
                                </Button>
                            </b>
                        )} */}
                    </li>
                ))}
            </ul>
        </>
    );
}
