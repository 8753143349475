import React, { useState } from 'react';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
interface IProps {
    avatar: string;
    name: string;
}
export default function Avatar(props: IProps) {
    const { avatar = '', name = '' } = props;
    const text = name.substring(0, 1);
    return avatar ? (
        <img className="avatar-img" src={changeEgovUrl(avatar)} />
    ) : (
        <span className="avatar-img">{text}</span>
    );
}
