import React, { useEffect } from 'react';
import { IColumnProps } from './Column';

export interface IProps<T, C> {
    data: C;
    columnProps: IColumnProps<T, C>[];
    remove: () => void;
    reload: () => void;
    onCreatRouter: (item: any) => any;
}

export default function Category<T, C>(props: IProps<T, C>) {
    const { data: propData } = props;
    const [data, setData] = React.useState(props.data);
    useEffect(() => {
        setData(propData);
    }, [propData]);
    return (
        <div data-row>
            {props.columnProps.map((_props) => (
                <div
                    data-colname={_props.name}
                    key={_props.name}
                    onClick={() => {
                        props.onCreatRouter(data);
                    }}
                >
                    {_props?.categoryRender?.({
                        data,
                        setData,
                        remove: props.remove,
                        reload: props.reload,
                    })}
                </div>
            ))}
        </div>
    );
}
