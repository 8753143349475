import PinyinMatch from 'pinyin-match';
import { PagerParams } from './utils';
import BaseService from './BaseService';
import cookies from 'js-cookie';
import { FocusSDK } from '@/utils';
import { labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { PRIORITY_TYPE } from '@/types/newWork';

interface Highlight {
    name: string;
    value: string;
    key: string;
}

export interface JoySpaceInfo {
    fullPathIds: string; // id$@$id
    fullPathNames: string; // $@$
    highlight: Highlight[];
    id: string;
    originId: string;
    pageId: string;
    pageType: string;
    previewText: string;
    spaceCategoryId: string;
    spaceId: string;
    spaceName: string;
    status: string;
    teamId: string;
    teamName: string;
    tenantId: string;
    title: string;
    type: string;
    icon_url?: string;
}

export interface ContactInfo {
    app: string;
    userId: string;
    teamId: string;
    emplAccount: string;
    realName: string;
    mobile: string;
    email: string;
    avatar: string;
    deptName: string;
    highlight: Highlight[];
    titleName?: string;
    labelInfoList?: labelInfoList[];
}

export interface DeptInfo {
    code: string;
    message?: string;
    data: DepartmentList[];
}

export interface DepartmentList {
    childDept: [];
    deptCategory: string;
    deptId: string;
    deptName: string;
    deptProperty: string;
    fullName: string;
    fullPath: string;
    highlight: Highlight[];
    isParent: boolean;
    parentId: string;
    sortNum: number;
    teamId: string;
    realFullName: string;
}

export interface GroupInfo {
    groupId: string;
    groupName: string;
    groupType: string;
    avatar: string;
    highlight: Highlight[];
    groupUserCount: number;
}

export interface TaskInfo {
    app: string;
    completeTime: number | null;
    content: string;
    endTime: number | null;
    highlight: Highlight[];
    highlight2: Highlight[];
    priorityType: PRIORITY_TYPE;
    startTime: number;
    status: number;
    taskId: string;
    taskStatus: number;
    teamId: string;
    title: string;
    userId: string;
    userInfo: any;
}

export interface AppInfo {
    appId: string;
    appName: string;
    brief: string;
    description: string;
    keyword: string;
    avatar: string;
    highlight: Highlight[];
    foreignLanguage: {
        lang: string;
        appName: string;
        avatar: string;
        brief: string;
        description: string;
        keyword: string;
    }[];
    ext: {
        androidWebviewKernel: number;
        applicationKey: string;
        developmentType: number;
        iosWebviewKernel: number;
        maxVersion: string;
        minVersion: string;
        mobileHomeUrl: string;
        padosWebviewKernel: number;
        pcHomeUrl: string;
        adminHomeUrl: string;
        trustedDomains: string;
        trustedIp: string;
        createType: number;
        applicationStatus: number;
        canDisable: number;
        favorite?: number;
    };
}
export interface SearchGlobalParams {
    keyword: string;
    searchType: SearchType;
    pageNo: number;
    pageSize: number;
    category?: number;
    classification?: number[];
    timeRange?: number;
}
export interface ScheduleInfo {
    agent: any;
    beginTime: any;
    calendarId: string;
    calendarType: string;
    canceled: string;
    canceledScheduleId: string;
    color: string;
    content: string;
    ddAppId: string;
    description: string;
    endTime: any;
    highlight: Highlight[];
    ownerTeamId: string;
    ownerUserId: string;
    repeat: number;
    scheduleId: string;
    teamId: string;
    title: string;
    userId: string;
}
export interface SearchGlobalRes {
    contacts?: ContactInfo[];
    groups?: GroupInfo[];
    apps?: AppInfo[];
    pages?: JoySpaceInfo[];
    schedules?: ScheduleInfo[];
    works?: {
        [key: string]: TaskInfo[];
    }[];
    depts?: DeptInfo;
    hasMoreDepts?: Boolean;
    hasMoreGroups?: Boolean;
    hasMoreContacts?: Boolean;
    hasMoreApps?: Boolean;
}

export enum SearchType {
    All = '0',
    Contacts = '1',
    Group = '2',
    App = '3',
    JoySpace = '4',
    Task = '5',
    Schedule = '6',
    Department = '7',
    MessageHistory = '-1',
    Device = '8',
}

class SearchService extends BaseService {
    /**
     * todo 外部联系人很多时会有性能问题
     * 目前调用了查询外部联系人接口，和用户详细信息，实现查询个部联系人功能
     * 搜索外部联系人
     * @param keyword
     */
    // async searchExternalContacts(keyword: string): Promise<any> {
    //     try {
    //         const content = await this.fetch<any>('efriend', 'getFriendsByGroup', {});
    //         const contacts = (content.groups || [])
    //             .reduce((result: any, item: any) => {
    //                 return result.concat(item.friends);
    //             }, [] as any)
    //             .map((item: any) => {
    //                 return {
    //                     appId: item?.app,
    //                     userId: item?.pin,
    //                     teamId: item?.teamId,
    //                 };
    //             });
    //         if (!contacts || contacts.length === 0) {
    //             return [];
    //         }

    //         const ebookUsers = await this.fetch<any>('ebook', 'getUserEbookDetailList', {
    //             ebookUserDetailReqList: contacts,
    //         });

    //         if (!ebookUsers || ebookUsers.length === 0) {
    //             return [];
    //         }

    //         return ebookUsers.reduce((resultSet: any, item: any) => {
    //             const name = item.fields.find((field: any) => field.key === 'name').val;
    //             const matched = PinyinMatch.match(name || '', keyword);
    //             if (matched) {
    //                 resultSet.push({
    //                     app: item.appId,
    //                     deptName: item.fields.find((field: any) => field.key === 'station').val,
    //                     avatar: item.fields.find((field: any) => field.key === 'avatar').val,
    //                     userId: item.userId,
    //                     realName: name,
    //                     teamId: item.teamId,
    //                     highlight: [
    //                         {
    //                             name: 'realName',
    //                             value: name,
    //                             key: Array.isArray(matched)
    //                                 ? name.slice(matched[0], matched[1] + 1)
    //                                 : keyword,
    //                         },
    //                     ],
    //                 });
    //             }
    //             return resultSet;
    //         }, [] as any);
    //     } catch (e) {
    //         return [];
    //     }
    // }

    searchGlobal(
        params: {
            keyword: string;
            searchType: SearchType;
        } & PagerParams
    ) {
        return this.fetch<SearchGlobalRes>('search', 'searchGlobal', {
            appKey: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
            indexName: 'global',
            ...params,
        });
    }
}

export default SearchService;
