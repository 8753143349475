/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
// 消息聚合
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { Card, Drawer, message as toast } from 'antd';
import {
    ChatMessage,
    Session,
    MessageStatus,
    ChatMessagePlusEmitBody,
    Employee,
    TextChatMessageExt,
    SessionType,
} from '@/types/chat';
import './index.less';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';
import { formate } from '@/utils/date';
import Bus from '@/utils/bus';
import { UserCardType } from '@/types/UserCard';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import ChildrenPlugin from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { convertStringToEmoji } from '../../utils/ddemoji';
import { mattchEmoji } from '../Input/Emoji';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { AggregateUser } from '@jd/jdee.im.sdk/lib/es/types/Common';
import { formatAtUser, getHeadImgThumbUrl } from '@/utils/chat/index';
import { getToMergeMessage } from '@/server/im/MessageService';
import { changeEgovUrl } from '@/utils/tools';
import {
    buildTextMessageBody,
    initChatMessageInfo,
    getMessageSendType,
    isGroupVisibleUnit,
    isCardViewGroupSession,
    isEqualEmployee,
    convertContentToArray,
    MessageCardType,
} from '@/components/chat/utils/message';
import { useTranslation } from 'react-i18next';
import Revoke from '@/components/chat/message/Revoke';
import MessageControl from '@/components/chat/message/MsgControl';
import { getDeptPositionDesc } from '@/models/user';
import { isGroupOrdinary } from '@/components/chat/utils/group';
import Context from '@/components/chat/context';
import JoyspaceTopic from '../message-components/JoyspaceTopic';
import ShortLink from '../message-components/ShortLink';
import { MessageSendType } from '@/types/chat/enum';
import { pick } from 'lodash';
import { getEmoji } from '../../utils/commonMessageModal';

TweenOne.plugins.push(ChildrenPlugin);
interface MessageOperateProps {
    message: ChatMessage & ChatMessagePlusEmitBody & TextChatMessageExt;
    selectedSession: Session;
    currentEmployee: Employee;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
    pushChatMessage: (data: { sessionId: string; message: Partial<ChatMessage> }) => void;
}
type IProps = Readonly<MessageOperateProps & ChatState>;
function ChatAggregation(props: IProps) {
    const { message, selectedSession, sessionMessages, currentEmployee, pushChatMessage } = props;
    const [visible, setvisible] = useState(false);
    const { groupRosterIdentity } = React.useContext(Context);
    const { t } = useTranslation('chat');
    let newplusLists: any = [];
    let msgType = '';
    const realDisplayContent = useMemo(() => {
        if (message.displayContent && message.displayContent.length > 0) {
            return message.displayContent;
        }
        return convertContentToArray(message.content);
    }, [message]);

    const lastSessionMsg = useMemo(() => {
        return !sessionMessages.length ? null : sessionMessages[sessionMessages.length - 1];
    }, [sessionMessages]);

    const plusList = useMemo(() => {
        if (!message?.plusList) {
            return [];
        }
        return (message as any).plusList;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message?.plusList]);

    const oneVisable = useMemo(() => {
        if (!lastSessionMsg) {
            return false;
        }
        if (
            (lastSessionMsg as any).plusList === undefined &&
            lastSessionMsg.content !== message.content
        ) {
            return true;
        }
        if (lastSessionMsg.mid !== message.mid) {
            return true;
        } else {
            return false;
        }
    }, [lastSessionMsg, message]);

    // 构建消息
    const sendMessage = useCallback(() => {
        // const msgs = {
        //     type: ChatMessageType.TEXT,
        //     content: message.content,
        // } as any;
        const messageList = buildTextMessageBody(
            message.content as string,
            message.atUsers as any,
            undefined,
            undefined,
            realDisplayContent
        );
        const messages: Partial<ChatMessage> = initChatMessageInfo({
            selectedSession,
            currentEmployee,
            message: messageList,
        });
        if (!lastSessionMsg) {
            return;
        }
        const sessionId = lastSessionMsg.sessionId;
        if (sessionId) {
            pushChatMessage({
                sessionId: selectedSession.sessionId,
                message: messages as any,
            });
            const { needMerge } = getToMergeMessage(
                selectedSession,
                sessionMessages,
                messages as ChatMessage
            );
            Bus.emit(`message:Box`, needMerge);
            Bus.emit(`message:Container`, needMerge);
        }
    }, [
        lastSessionMsg,
        selectedSession,
        currentEmployee,
        sessionMessages,
        pushChatMessage,
        message,
        realDisplayContent,
    ]);

    let plusLists: any = [];

    const showDrawer = () => {
        setvisible(true);
    };
    const onClose = () => {
        setvisible(false);
    };

    const isVisibleChange = (e: MouseEvent) => {
        if (visible) {
            Bus.emit('message-item-chatitem', true);
        }
    };

    useEffect(() => {
        document.addEventListener('contextmenu', isVisibleChange);
        return () => {
            document.removeEventListener('contextmenu', isVisibleChange);
        };
    });

    // 打开个人信息
    const openUserCard = (e: any) => {
        e.stopPropagation();
        let data = null;
        // 如果群成员并且禁止了查看群成员名片
        if (
            !isEqualEmployee(currentEmployee, message.from as any) &&
            isGroupOrdinary(groupRosterIdentity) &&
            !isCardViewGroupSession(selectedSession)
        ) {
            toast.warn(t('can_not_open_user_card_tip'));
            return false;
        }
        try {
            data = JSON.parse(e.target.getAttribute('data-event-params'));
        } catch (e) {}
        // 如果聚合消息内容为@
        if (data) {
            if (
                e.target.getAttribute('data-event-click') !== 'mention-link' ||
                !data ||
                (data && data.id === 'all') ||
                (data && !data.id)
            ) {
                return;
            } else {
                Object.assign(data, { userId: data.id });
                // 如果群成员并且禁止了查看群成员名片
                Bus.emit('app:toggle-user-card-modal:show', {
                    visible: true,
                    type: UserCardType.User,
                    userId: data.id,
                    teamId: data.teamId,
                    appId: data.app,
                });
            }
        } else {
            let dataId = e.target.getAttribute('data-id');
            let pin = '';
            plusList.map((user: any) => {
                if (user.userId === dataId) {
                    pin = user.userId;
                }
            });
            Bus.emit('app:toggle-user-card-modal:show', {
                visible: true,
                type: UserCardType.User,
                userId: pin,
                teamId: message.from.teamId,
                appId: message.from.app,
            });
        }
    };

    // 聚合消息内容
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getMessageContent = (messageParams: any) => {
        let { atUsers, content } = messageParams;
        let result = convertStringToEmoji(content);
        result = formatAtUser(result, atUsers, currentEmployee);
        result = mattchEmoji(result);
        result = getEmoji(result);
        return result;
    };

    let userid: any = [];
    message.plusList?.map((item: any, index: any) => {
        if (item.statusType === MessageStatus.REVOKE) {
            return;
        }
        if (userid.indexOf(item.userId) === -1 && item.statusType === MessageStatus.SUCCESS) {
            userid.push(item.userId);
            newplusLists.push({ ...item, number: 0 });
        }
        if (
            (userid.indexOf(item.userId) === -1 && item.statusType === MessageStatus.SUCCESS) ||
            item.statusType === MessageStatus.FAILED
        ) {
            // userid.push(item.userId);
            newplusLists.push({ ...item });
        } else {
            newplusLists.map((item2: any) => {
                // item2.userId === item.userId && item2.number++;
                // item2.userId === item.userId && item2.timestamp = item.timestamp;
                if (item2.userId === item.userId) {
                    item2.number++;
                    item2.timestamp = item.timestamp;
                }
                return item2;
            });
        }
    });

    let plulist = [];
    // 重新排序
    for (let i = plusList.length - 1; i >= 0; i--) {
        if (!plusList[i]) {
            return;
        }
        if (
            plusList[i].statusType === MessageStatus.SUCCESS ||
            plusList[i].statusType === MessageStatus.FAILED ||
            plusList[i].statusType === MessageStatus.SENDING
        ) {
            // eslint-disable-next-line no-self-compare
            plusLists[plusLists.length] = plusList[i];
            msgType = plusList[i].statusType;
        }
        if (
            currentEmployee.userId === plusList[i].userId &&
            plusList[i].statusType !== MessageStatus.SUCCESS
        ) {
            plulist[plulist.length] = plusList[i];
        }
    }
    function compare(property: any) {
        return function (a: any, b: any) {
            let value1 = a[property];
            let value2 = b[property];
            // 升序,降序为value2 - value1
            return value2 - value1;
        };
    }

    // let failedplusList = [];
    newplusLists.sort(compare('timestamp'));

    const getUserDesc = (user: any) => {
        const unitOrDeptName = !isGroupVisibleUnit(selectedSession) ? user.unitName : user.deptName;
        const result = getDeptPositionDesc(unitOrDeptName, user.titleName || '');
        return result;
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const titles = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
                style={{
                    color: '#232930',
                    fontSize: 16,
                }}
            >
                消息详情
            </div>

            <div
                style={{
                    color: '#8F959E',

                    fontSize: 12,
                    height: 18,

                    fontFamily: 'PingFangSC',

                    marginLeft: '10px',
                }}
            >
                <span style={{ marginRight: 6 }}>
                    聚合人数：
                    <span
                        style={{ color: (window as any).styleSass.primaryColor, fontWeight: 600 }}
                    >
                        {newplusLists.length}
                    </span>
                    人
                </span>

                <span>
                    聚合次数：
                    <span
                        style={{ color: (window as any).styleSass.primaryColor, fontWeight: 600 }}
                    >
                        {plusLists.length}
                    </span>
                    次
                </span>
            </div>
        </div>
    );
    return !plusList.length ? null : (
        <div
            className="site-card-border-less-wrapper"
            id={`item-${message.id}-${message.timestamp}`}
        >
            {msgType === '' || message.content === '【消息已撤回】' ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Revoke
                        key={`r-${message.id}`}
                        message={message}
                        sessionType={selectedSession?.sessionType}
                        plulistindex={plulist}
                    />
                </div>
            ) : (
                <div className="site-card-border-less-wrapper">
                    <Card
                        bordered={false}
                        style={{ width: 564, borderRadius: 8 }}
                        // className={
                        //     plusList[0]?.name === currentEmployee.name ? 'msg-card' : 'ant-card'
                        // }
                        className="ant-card"
                    >
                        <div className="site-card-title">
                            {/* <p
                                style={{ display: 'inline-block' }}
                                onClick={(e) => {
                                    openUserCard(e);
                                }}
                                dangerouslySetInnerHTML={{ __html: getMessageContent() }}
                            /> */}
                            {realDisplayContent.map((item, index) => {
                                return (
                                    <div key={index} style={{ margin: '1px 0' }}>
                                        {item.type === MessageCardType.Text ? (
                                            <div
                                                className={`top-box ${
                                                    selectedSession.isSecret
                                                        ? 'top-box_isSecret'
                                                        : ''
                                                }`}
                                                dangerouslySetInnerHTML={{
                                                    __html: getMessageContent({
                                                        ...message,
                                                        content: item.content,
                                                    }).trim(),
                                                }}
                                            />
                                        ) : item.type === MessageCardType.JoyspaceTopic ? (
                                            <JoyspaceTopic content={item} {...props} />
                                        ) : (
                                            <ShortLink shortLinkItem={item} />
                                        )}
                                    </div>
                                );
                            })}
                            {/* <div
                        className={
                            plusListMsg[0].name === currentEmployee.name
                                ? 'site-card-title-lines'
                                : 'site-card-title-line'
                        }
                    /> */}
                        </div>
                        <div className="site-card-msg">
                            <div className="site-card-msg-con">
                                {/* 个人信息展示 */}
                                <QueueAnim
                                    type="scaleX"
                                    component="div"
                                    className="demo-content"
                                    animConfig={[{ opacity: [1, 0], translateX: [0, 677] }]}
                                >
                                    {/* 数字展示 大于99展示99+ */}
                                    {/* {conindex >= 99 ? (
                                <div
                                    style={{
                                        height: 38,
                                        lineHeight: '33px',
                                        width: conindex > 9 ? '50px' : '36px',
                                    }}
                                >
                                    <TweenOne
                                        animation={{
                                            Children: {
                                                value: 99,
                                                floatLength: 0,
                                            },
                                            duration: 1000,
                                            delay: 300,
                                        }}
                                        component="span"
                                        className="site-card-msg-index ones"
                                    />
                                    <span
                                        className="site-card-msg-conindex"
                                        style={{ marginRight: 6 }}
                                    >
                                        +
                                    </span>
                                </div>
                            ) : ( */}
                                    <div
                                        style={{
                                            height: 38,
                                            lineHeight: '33px',
                                            width: plusList?.length > 9 ? '48px' : '42px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={showDrawer}
                                    >
                                        <span className="site-card-msg-conindex">+</span>
                                        <TweenOne
                                            animation={{
                                                Children: {
                                                    value:
                                                        plusLists.length >= 99
                                                            ? 99
                                                            : plusLists.length,
                                                    floatLength: 0,
                                                },
                                                duration: 500,
                                                // delay: 200,
                                                // y: 80,
                                            }}
                                            component="span"
                                            className="site-card-msg-index"
                                        />
                                    </div>
                                    {/* // )} */}
                                    {newplusLists.map((item: any, index: any) => {
                                        if (index >= 10) {
                                            return;
                                        }
                                        const msg = {
                                            ...message,
                                            sender: pick(item, [
                                                'userId',
                                                'teamId',
                                                'app',
                                                'avatar',
                                                'name',
                                            ]) as Employee,
                                            id: item.uuid,
                                            fuuid: item.fuuid,
                                            statusType: item.statusType,
                                        };
                                        return (
                                            <div
                                                key={index}
                                                style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                {item.statusType === MessageStatus.FAILED && (
                                                    <MessageControl
                                                        sendType={getMessageSendType(
                                                            msg,
                                                            currentEmployee,
                                                            selectedSession
                                                        )}
                                                        message={msg}
                                                        sessionType={selectedSession.sessionType}
                                                        onSendStatus={() => {}}
                                                        statusType={item.statusType}
                                                    />
                                                )}
                                                <div
                                                    className="site-card-msg-user"
                                                    key={item.number}
                                                >
                                                    <Avatar
                                                        src={getHeadImgThumbUrl(
                                                            item.avatar,
                                                            150,
                                                            150
                                                        )}
                                                        name={item.name || ''}
                                                    />
                                                    <span
                                                        onClick={(e) => {
                                                            openUserCard(e);
                                                        }}
                                                        data-id={item.userId}
                                                    >
                                                        {item.name}
                                                    </span>
                                                    {item.number > 1 && (
                                                        <div
                                                            style={{
                                                                fontWeight: 600,
                                                                color: '#333333',
                                                                marginLeft: 4,
                                                            }}
                                                        >
                                                            +{item.number}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </QueueAnim>
                            </div>
                        </div>
                        {/* 聚合人数展示 */}
                        <div
                            // className={
                            //     plusList[0]?.name === currentEmployee.name
                            //         ? 'site-card-msg-qb'
                            //         : 'site-card-msg-q'
                            // }
                            className="site-card-msg-q"
                            onClick={showDrawer}
                        >
                            <div className="site-card-msg-qs">
                                <span style={{ marginRight: '20px' }}>
                                    聚合人数：
                                    <span
                                        style={{
                                            color: (window as any).styleSass.primaryColor,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {newplusLists.length}
                                    </span>
                                    人
                                </span>
                                <span>
                                    聚合次数：
                                    <span
                                        style={{
                                            color: (window as any).styleSass.primaryColor,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {plusLists.length}
                                    </span>
                                    次
                                </span>
                            </div>
                        </div>
                        {/* +1 */}
                        {oneVisable === false && (
                            <div>
                                <div
                                    // className={
                                    //     plusList[0]?.name === currentEmployee.name
                                    //         ? 'site-card-msg-lines'
                                    //         : 'site-card-msg-line'
                                    // }
                                    className="site-card-msg-line"
                                />
                                <div
                                    className="site-card-msg-jiayi"
                                    onClick={() => {
                                        // if (connectState.state === 'offline') {
                                        //     return;
                                        // }
                                        sendMessage();
                                    }}
                                    clstag="pageclick|keycount|Xtbg_Msg|InfoMerge"
                                >
                                    <div className="jia">
                                        <IconFont
                                            type="iconapp_btn_more"
                                            className="chat-aggregation-icon"
                                        />
                                    </div>
                                    <span>加一个</span>
                                </div>
                            </div>
                        )}
                    </Card>
                    <Drawer
                        className="drawer"
                        title={titles}
                        placement="right"
                        closable={true}
                        onClose={onClose}
                        visible={visible}
                        destroyOnClose={true}
                        maskClosable={true}
                        width={340}
                    >
                        {plusLists.map((item: any, index: any) => {
                            return (
                                <div key={index} className="msgUser">
                                    <div className="head-img">
                                        <Avatar
                                            styleObj={{
                                                width: '36px',
                                                height: '36px',
                                            }}
                                            src={changeEgovUrl(item.avatar)}
                                            className="img"
                                            data-id={item.userId}
                                            name={item.name || ''}
                                        />
                                    </div>
                                    <div
                                        onClick={(e) => {
                                            openUserCard(e);
                                        }}
                                        className="user-info"
                                        data-id={item.userId}
                                    >
                                        <div className="name-time">
                                            <span data-id={item.userId} className="name">
                                                {item.name}
                                            </span>
                                            <span data-id={item.userId} className="time">
                                                {formate(item.timestamp)}
                                            </span>
                                        </div>
                                        <div data-id={item.userId} className="desc">
                                            {getUserDesc(item)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Drawer>
                </div>
            )}
        </div>
    );
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    const { sessionMessageMap, messages, connectState, selectedSession, currentEmployee } = chat;
    let sessionMessages: any[] = sessionMessageMap[selectedSession.sessionId] || [];
    return {
        sessionMessageMap,
        sessionMessages,
        messages,
        connectState,
        userInfo: currentEmployee,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatAggregation);
