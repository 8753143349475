import React, { useState } from 'react';
import { Modal } from 'antd';
import ReactDOM from 'react-dom';
import { getThumbUrl } from '@/utils/chat/index';
import { ackAnnouncement } from '../../api';
import OpenImgPreview from './PreviewImg';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
import { getPreviewImageModal } from '@/utils/chat/commonMessageModal';
import { isFocusEnv } from '@/utils';
interface Iprops {
    onclose?: () => void;
    content: string;
    pictures: string[];
    projectId: string;
    announceId: string;
    t: any;
}

function AnnounceInfo(props: Iprops) {
    const { content, pictures, projectId, announceId, t, onclose } = props;
    const [visible, setVisible] = useState(true);

    function cancelHandler() {
        ackAnnouncement(projectId, announceId);
        onclose?.();
    }
    return (
        <Modal
            title="组公告详情"
            width="550px"
            bodyStyle={{ height: 386, padding: '16px 24px', overflowY: 'auto' }}
            visible={visible}
            footer={null}
            onCancel={cancelHandler}
        >
            <div className="pm-announceinfo-wrap" id="pm-announceinfo-wrap">
                <p>{content}</p>
                <ul className="pm-detail-imgs">
                    {(pictures || []).map((imgItem, imgIndex) => (
                        <li
                            key={imgItem}
                            onClick={() => {
                                // OpenImgPreview({ imgSrc: imgItem, imgArr: pictures || [] });
                                const arr = (pictures || []).map((item, index) => {
                                    return {
                                        kind: 'image',
                                        url: isFocusEnv()
                                            ? encodeURIComponent(item)
                                            : encodeURI(item),
                                        muuid: index,
                                        id: index,
                                        fileName: '',
                                    };
                                });
                                getPreviewImageModal({
                                    message: {
                                        muuid: imgIndex,
                                        url: isFocusEnv()
                                            ? encodeURIComponent(imgItem)
                                            : encodeURI(imgItem),
                                        fileName: '',
                                    },
                                    isMergeForward: true,
                                    modalMessages: arr,
                                });
                            }}
                        >
                            <img src={changeEgovUrl(getThumbUrl(imgItem, 74, 74))} alt="" />
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
}

export default function (props: Iprops) {
    const el = document.createElement('div');
    const onclose = function () {
        if (el) {
            ReactDOM.unmountComponentAtNode(el);
            el.remove();
        }
    };

    ReactDOM.render(<AnnounceInfo {...props} onclose={onclose} />, el);
}
