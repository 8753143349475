import { AuthDataRes } from '@/types/LoginPage';

export interface RegistryConfigData {
    version: number;
    machineId: string;
    pkgVersion: string;
    theme: string;
    lang: LangEnum;
    runtimeConfig: {
        runtime: string;
        env: string;
        webEntry?: string;
        entryDev?: boolean;
    };
    auth?: AuthData;
    selectedTeamId: string;
    autoLogin: boolean;
    settings: SettingsData;
    client: string;
    [key: string]: any;
}

export enum LangEnum {
    ZH_CN = 'zh_CN',
    EN_US = 'en_US',
}

export interface SettingsData {
    downloadDir: string;
}

export interface AuthData extends AuthDataRes {
    loginTs: number;
}
