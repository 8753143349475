import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import moment, { Moment } from 'moment';
import { ChatMessageType, Employee, MessageEmitBody, MessageStatus, Session } from '@/types/chat';
import SearchInput from '../Input';
import { useTranslation } from 'react-i18next';
import DefaultPage from '@/components/DefaultPage';
import { CompDataLoadState, DefaultPageType } from '@/models';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import ImService from '@/server/ImService';
import { List, Button, DatePicker, message } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Avatar } from '@/baseComponents/Avatar';
// import Avatar from '@/components/Avatar';
import { MessageDateFormType, MessageSendType } from '@/types/chat/enum';
import { getMessageDateFormat } from '@/utils/date';
import { formatMatchContext, processEmoji } from '../utils';
import { FocusSDK, isFocusEnv } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';
import IconFont from '@/components/icon';
import { orderBy, uniqBy } from 'lodash';
import { getChatMessageNode } from '../../message/Item/utils';
import { getMessageSendType } from '../../utils/message';
import Loading from '@/components/Loading';
import ScrollView from '@/baseComponents/ScrollView';
import { getHeadImgThumbUrl } from '@/utils/chat/index';

export interface ISearchMessageProps {
    currentSession: Session;
    currentEmployee: Employee;
    jumpTo?: (mid: number) => void;
    onClickClose?: () => void;
}

interface IExportMessagesProps {
    sessionId: any;
    searchKey: any;
    startTimestamp: any;
    endTimestamp: any;
    imService: any;
}

const limit = 500;
async function loadSingeExportMessages(
    opts: IExportMessagesProps,
    inputMessages: MessageEmitBody[]
) {
    const { sessionId, searchKey, startTimestamp, endTimestamp, imService } = opts;
    const startMid = (inputMessages.slice(-1)[0] || { mid: -1 }).mid;
    const msgs = await imService.search({
        sessionId,
        searchKey,
        searchType: ChatMessageType.TEXT,
        startMid: startMid === -1 ? -1 : startMid - 1,
        // startMid: startMid,
        startTimestamp,
        endTimestamp,
        limit,
        desc: true,
        includeBurnAfterRead: true,
    });

    return msgs;
}

async function loadExportMessages(opts: IExportMessagesProps, inputMessages: MessageEmitBody[]) {
    const { startTimestamp, endTimestamp } = opts;

    if (startTimestamp && endTimestamp) {
        let condition = true;
        while (condition) {
            const msgs = await loadSingeExportMessages(opts, inputMessages);
            if (msgs?.length) {
                // eslint-disable-next-line no-param-reassign
                inputMessages = inputMessages.concat(msgs as MessageEmitBody[]);

                if (msgs.length < limit) {
                    condition = false;
                }
            } else {
                condition = false;
            }
        }
    }

    return inputMessages;
}
let laetId: any = 0;

export default function SearchMessage(props: ISearchMessageProps) {
    const { currentSession, currentEmployee, jumpTo, onClickClose } = props;
    const [searchKey, setSearchKey] = useState('');
    const [messages, setMessages] = useState<MessageEmitBody[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [startTimestamp, setStartTimestamp] = useState(Number);
    const [endTimestamp, setEndTimestamp] = useState(Number);
    const [viewContext, setViewContext] = useState(false);
    const [viewContextLoading, setViewContextLoading] = useState(false);
    const [viewContextMessages, setViewContextMessages] = useState<any[]>([]);
    const [curClickItem, setCurClickItem] = useState<any>(null);

    const scrollRef = useRef(null);

    const { t } = useTranslation('chat');
    const imService = ImService.getInstance();

    const get = () => {
        laetId++;
        return async (theLastId: any) => {
            // console.log('get', searchKey, startTimestamp, endTimestamp);
            if (searchKey || startTimestamp || endTimestamp) {
                setLoading(true);
                imService
                    .search({
                        sessionId: currentSession.sessionId,
                        searchKey,
                        searchType: ChatMessageType.TEXT,
                        startTimestamp,
                        endTimestamp,
                        desc: true,
                        includeBurnAfterRead: true,
                    })
                    .then((messages) => {
                        if (laetId === theLastId) {
                            setMessages(messages as MessageEmitBody[]);
                            setLoading(false);
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                    });
            } else {
                // console.log(6666);
                setMessages([]);
            }
        };
    };

    const search = useMemo(() => {
        return debounce((searchKey: string, startTimestamp: number, endTimestamp: number) => {
            if (searchKey || startTimestamp || endTimestamp) {
                setLoading(true);
                imService
                    .search({
                        sessionId: currentSession.sessionId,
                        searchKey,
                        searchType: ChatMessageType.TEXT,
                        startTimestamp,
                        endTimestamp,
                        desc: true,
                        includeBurnAfterRead: true,
                    })
                    .then((messages) => {
                        setMessages(messages as MessageEmitBody[]);
                        setLoading(false);
                    })
                    .catch((e) => {
                        setLoading(false);
                    });
            } else {
                setMessages([]);
            }
        }, 400);
    }, [imService, currentSession, setMessages]);

    useEffect(() => {
        setMessages([]);
    }, [currentSession]);

    useEffect(() => {
        // if (!searchKey && !startTimestamp && !endTimestamp) {
        //     console.log(444444444);
        //     setMessages([]);
        // } else {
        get()(laetId);
        // search(searchKey, startTimestamp, endTimestamp);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKey, startTimestamp, endTimestamp, search]);

    const handleSearchKeyChange = useCallback(
        function (searchKey: string) {
            setSearchKey(searchKey);
            // if (!searchKey && !startTimestamp && !endTimestamp) {
            //     setMessages([]);
            // } else {
            //     search(searchKey, startTimestamp, endTimestamp);
            // }
        },
        [setSearchKey]
        // [setSearchKey, search, startTimestamp, endTimestamp]
    );

    const handleInfiniteOnLoad = () => {
        setLoading(true);
        const startMid = (messages.slice(-1)[0] || { mid: -1 }).mid;
        imService
            .search({
                sessionId: currentSession.sessionId,
                searchKey,
                searchType: ChatMessageType.TEXT,
                startMid: startMid === -1 ? -1 : startMid,
                startTimestamp,
                endTimestamp,
                desc: true,
                includeBurnAfterRead: true,
            })
            .then((msgs) => {
                setMessages(messages.concat(msgs as MessageEmitBody[]));
                setLoading(msgs.length !== 20);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const onScroll = async () => {
        const preArr = viewContextMessages;
        if (!preArr || preArr?.length <= 0) {
            return false;
        }
        if (viewContextLoading) {
            return;
        }

        const curr: any = scrollRef.current;
        // console.log('curr', curr, scrollRef);
        if (!curr) {
            return;
        }
        const { scrollHeight, scrollTop, clientHeight } = curr.getValues();
        // console.log('scrollHeight', scrollHeight);
        // console.log('scrollTop', scrollTop);
        // console.log('values', clientHeight);

        if (scrollTop === 0) {
            const prevMessages = (await getPrevMessage(preArr[0].mid - 1)) as any;
            // console.log('preMessages', prevMessages);
            if (!prevMessages.length) {
                return;
            }
            const sortArr = orderBy(prevMessages, ['mid'], ['asc']);
            setViewContextMessages(sortArr.concat(preArr));
            const ele = document.getElementById(`item-${preArr[0].mid}-${preArr[0].timestamp}`);
            // console.log('ele', ele);
            ele?.scrollIntoView();
            return;
        }
        if (scrollTop !== 0 && clientHeight + scrollTop > scrollHeight - 10) {
            const nextMessages = (await getNextMessage(preArr[preArr.length - 1].mid)) as any;
            // console.log('nextMessages', nextMessages);
            if (!nextMessages.length) {
                return;
            }
            setViewContextMessages(viewContextMessages.concat(nextMessages));

            return;
        }
    };

    const getPrevMessage = (startMid: number) => {
        return new Promise((resolve, reject) => {
            imService
                .search({
                    sessionId: currentSession.sessionId,
                    searchKey: '',
                    startMid: startMid,
                    queryType: 2,
                    desc: true,
                    includeBurnAfterRead: true,
                })
                .then((msgs) => {
                    // console.log('prev', msgs);
                    resolve(msgs);
                })
                .catch((e) => {
                    console.log('prev获取失败', e);
                    reject(e);
                });
        });
    };

    const getNextMessage = (startMid: number) => {
        return new Promise((resolve, reject) => {
            imService
                .search({
                    sessionId: currentSession.sessionId,
                    searchKey: '',
                    startMid: startMid,
                    queryType: 1,
                    includeBurnAfterRead: true,
                })
                .then((msgs) => {
                    // console.log('next', msgs);
                    resolve(msgs);
                })
                .catch((e) => {
                    console.log('next获取失败', e);
                    reject(e);
                });
        });
    };

    const handleViewContext = (item: any) => {
        // console.log('item', item);
        setViewContext(true);
        setViewContextLoading(true);
        setCurClickItem(item);
        Promise.all([getPrevMessage(item.mid), getNextMessage(item.mid)])
            .then((result: any) => {
                const mergeArr = result[0].concat([item]).concat(result[1]);
                const sortArr = orderBy(mergeArr, ['mid'], ['asc']);
                // console.log('sortArr', sortArr);
                const uniqArr = uniqBy(sortArr, 'id');
                // console.log('uniqArr', uniqArr);
                setViewContextMessages(uniqArr);
                setViewContextLoading(false);
                const ele = document.getElementById(`item-${item.mid}-${item.timestamp}`);
                // console.log('ele', ele);
                ele?.scrollIntoView();
            })
            .catch((e) => {
                setViewContextLoading(false);
                console.log('all获取失败', e);
            });
    };

    const handleBack = () => {
        setViewContext(false);
        setViewContextMessages([]);
    };

    const getSendType = (message: any) => {
        // 处理群解散之后，桌面端消息展示在左边的问题
        if (message.statusType === MessageStatus.FAILED && message.localSendTimestamp) {
            return MessageSendType.OWNER;
        }
        return getMessageSendType(message, currentEmployee, currentSession);
    };

    const searchByDate = () => {
        if (startTimestamp && endTimestamp) {
            get()(laetId);
            // search(searchKey, startTimestamp, endTimestamp);
        } else if (!startTimestamp && !endTimestamp) {
            message.info('请先选择开始时间和截止时间');
        } else if (!startTimestamp) {
            message.info('请先选择开始时间');
        } else if (!endTimestamp) {
            message.info('请先选择截止时间');
        }
    };

    // TODO: 已处理在dom中
    // const disabledStartDate = (current: Moment) => {
    //     if (!endTimestamp) {
    //         return false;
    //     }

    //     const newVal = current.valueOf();
    //     return newVal > endTimestamp;
    // };
    // const disabledEndDate = (current: Moment) => {
    //     if (!startTimestamp) {
    //         return false;
    //     }

    //     const newVal = moment(current.subtract(1, 'days').format('YYYY-MM-DD')).valueOf();
    //     return newVal < startTimestamp;
    // };
    let lockExportMessages = false;
    const exportMessages = async () => {
        if (!lockExportMessages && FocusSDK?.sendIpcMessage && messages.length) {
            lockExportMessages = true;
            try {
                const tempMessages = [...messages];
                const rstMessages = await loadExportMessages(
                    {
                        sessionId: currentSession.sessionId,
                        searchKey,
                        startTimestamp,
                        endTimestamp,
                        imService,
                    },
                    tempMessages
                );

                const rstData = [['姓名', '时间', '内容']];
                if (rstMessages?.length) {
                    rstMessages.forEach((item) => {
                        const tempItem = [];

                        if (item.nickName) {
                            tempItem.push(item.nickName);
                        }

                        if (item.timestamp) {
                            tempItem.push(moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                        }

                        switch (item.type) {
                            case 'text':
                                tempItem.push(item.content || '');
                                break;
                            default:
                                tempItem.push('-');
                                break;
                        }

                        if (tempItem.length) {
                            rstData.push(tempItem);
                        }
                    });
                }

                FocusSDK.sendIpcMessage(
                    'focus:im:export-messages',
                    JSON.stringify({
                        data: rstData,
                        fileType: 'excel',
                        fileName: `${t('message_record')}-${currentSession?.info?.name || ''}`,
                    })
                );
                lockExportMessages = false;
            } catch (error) {
                lockExportMessages = false;
                FocusSDK.printLog('exportMessages', error);
            }
        }
    };

    return (
        <div className="search-history search-text">
            {!viewContext && (
                <SearchInput
                    placeholder={t('search.iconText')}
                    onChange={handleSearchKeyChange}
                    sessionId={currentSession.sessionId}
                    keyword={searchKey}
                />
            )}
            {!viewContext ? (
                <div className="search-history-wrapper">
                    <div className="search-history-content">
                        <div className="search-history-result">
                            {messages.length === 0 && !searchKey && (
                                <div className="illustration">
                                    <DefaultPage
                                        dataType={CompDataLoadState.NODATA}
                                        pageType={DefaultPageType.CHAT_HISTORY_RECORD}
                                    />
                                </div>
                            )}
                            {messages.length === 0 && searchKey && (
                                <div className="illustration">
                                    <DefaultPage
                                        dataType={CompDataLoadState.NODATA}
                                        pageType={DefaultPageType.SEARCH_HISTORY}
                                    />
                                </div>
                            )}
                            {messages.length > 0 && (
                                <div className="message-list">
                                    {startTimestamp && endTimestamp ? (
                                        <div className="search-history-timeline">
                                            <span className="search-history-line-left" />
                                            {startTimestamp ? (
                                                <span className="search-history-start-txt">
                                                    {moment(startTimestamp).format('YYYY-MM-DD')}
                                                </span>
                                            ) : null}
                                            {endTimestamp ? <span>-</span> : null}
                                            {endTimestamp ? (
                                                <span className="search-history-end-txt">
                                                    {moment(endTimestamp).format('YYYY-MM-DD')}
                                                </span>
                                            ) : null}
                                            <span className="search-history-line-right" />
                                        </div>
                                    ) : null}
                                    <InfiniteScroll
                                        initialLoad={false}
                                        pageStart={0}
                                        loadMore={handleInfiniteOnLoad}
                                        hasMore={!isLoading}
                                        useWindow={false}
                                    >
                                        <List
                                            dataSource={messages}
                                            renderItem={(item, index) => (
                                                <div
                                                    className="message-item"
                                                    key={index}
                                                    onClick={() => {
                                                        jumpTo?.(item.mid);
                                                    }}
                                                >
                                                    <div className="head-img">
                                                        <Avatar
                                                            src={getHeadImgThumbUrl(
                                                                item.sender.avatar,
                                                                150,
                                                                150
                                                            )}
                                                            name={item.sender.name || ''}
                                                        />
                                                    </div>
                                                    <div className="message">
                                                        <div className="name-time">
                                                            <div className="name-tag">
                                                                <div className="name">
                                                                    {formatMatchContext(
                                                                        item.sender.name,
                                                                        searchKey
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="time">
                                                                {getMessageDateFormat(
                                                                    item.timestamp,
                                                                    MessageDateFormType.DATE_AGGREGATION,
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="context-wrapper">
                                                            <div
                                                                className="content dangerous-message-html"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: processEmoji(
                                                                        formatMatchContext(
                                                                            (item as any).content,
                                                                            searchKey
                                                                        )
                                                                    ),
                                                                }}
                                                            />
                                                            <div
                                                                className="view-context-btn"
                                                                onClick={() =>
                                                                    handleViewContext(item)
                                                                }
                                                            >
                                                                {t('view-context')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </InfiniteScroll>
                                </div>
                            )}
                        </div>
                        <div className="search-history-filter">
                            <div className="im-history-filter-item im-history-filter-title">
                                添加筛选条件
                            </div>
                            <div className="im-history-filter-item">日期：</div>
                            <DatePicker
                                locale={locale}
                                className="im-history-filter-date"
                                format="YYYY-MM-DD"
                                placeholder="选择开始时间"
                                disabledDate={(current) => {
                                    if (!current || !endTimestamp) {
                                        return current.valueOf() > new Date().getTime();
                                    }
                                    return endTimestamp <= current.valueOf();
                                }}
                                onChange={(date, str) => {
                                    const newVal = new Date(`${str} 00:00:00`).getTime() || 0;
                                    setStartTimestamp(newVal);
                                    // search(searchKey, newVal, endTimestamp);
                                }}
                            />
                            <DatePicker
                                locale={locale}
                                className="im-history-filter-date"
                                format="YYYY-MM-DD"
                                placeholder="选择截止时间"
                                disabledDate={(current) => {
                                    if (!current || !startTimestamp) {
                                        return current.valueOf() > new Date().getTime();
                                    }
                                    return (
                                        current.valueOf() <= startTimestamp ||
                                        current.valueOf() > new Date().getTime()
                                    );
                                }}
                                onChange={(date, str) => {
                                    const newVal = new Date(`${str} 23:59:59`).getTime() || 0;
                                    setEndTimestamp(newVal);
                                    // search(searchKey, startTimestamp, newVal);
                                }}
                            />
                            <Button
                                className="filter-btn filter-search"
                                type="primary"
                                onClick={() => {
                                    searchByDate();
                                }}
                                // disabled={!(startTimestamp + endTimestamp)}
                            >
                                查 询
                            </Button>
                        </div>
                    </div>
                    <div className="search-history-footer">
                        <Button
                            className="filter-btn filter-btn-cancel"
                            onClick={() => {
                                onClickClose?.();
                            }}
                        >
                            取 消
                        </Button>
                        {isFocusEnv() && (
                            <Button
                                className="filter-btn"
                                type="primary"
                                disabled={messages.length === 0}
                                onClick={() => {
                                    exportMessages();
                                }}
                            >
                                导 出
                            </Button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="view-context-wrapper">
                    <div className="back-btn-box" onClick={handleBack}>
                        <IconFont type="iconwin_left" />
                        <span className="back-text">{t('back')}</span>
                    </div>
                    <div className="content-box">
                        {viewContextLoading ? (
                            <div className="content-loading">
                                <Loading />
                            </div>
                        ) : (
                            <ScrollView ref={scrollRef} onScroll={onScroll} id="aaa">
                                {viewContextMessages.map((message: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="item-container"
                                            id={`item-${message.mid}-${message.timestamp}`}
                                        >
                                            <div className="item-left">
                                                <Avatar
                                                    src={changeEgovUrl(message.sender.avatar)}
                                                    name={message.sender.name || ''}
                                                />
                                            </div>
                                            <div className="item-right">
                                                <div className="right-name-time">
                                                    <div className="name">
                                                        {message.sender.name || ''}
                                                    </div>
                                                    <div className="time">
                                                        {getMessageDateFormat(
                                                            message.timestamp,
                                                            MessageDateFormType.DATE_AGGREGATION,
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="message-box">
                                                    <div className="content-row">
                                                        {getChatMessageNode(
                                                            getSendType(message),
                                                            message,
                                                            currentSession,
                                                            curClickItem,
                                                            searchKey
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </ScrollView>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
