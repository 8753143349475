/**
 * @author sunzhiguang
 * @date 2020/6/18
 */

/**
 * 执行 effects 的 put方法
 * @param put
 * @param type
 * @param payload
 */
export function executePut(put: Function, type: string, payload: any) {
    return put({ type, payload });
}
