/**
 * 聊天消息头（群组，会话）
 * @author tj
 * @date 2021/9/10
 */
import React, { useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { connect } from 'dva';
import { message as toast, Tooltip } from 'antd';
import './index.less';
import IconFont from '@/components/icon';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import { Employee, Group, UID, ChatMessage, ChatMessageType, GroupAttribute } from '@/types/chat';
import config, { ChatConfig, ConfigEnum } from '@/config/config';
import { HeadShowType, SessionSettingsType, DrawTypeEnum } from '@/types/chat/enum';
import {
    convertEmployeeToString,
    employeeToUid,
    isEqualEmployee,
    isCrossPlatformGroupSession,
} from '@/utils/chat/message';
import { SessionTag } from '@/components/Tags';
import useGroupUserSelector from '@/baseComponents/Chat/GroupUserSelector';
import { FocusSDK, JoyMeetingStatusEnum, isFocusEnv } from '@/utils';
import { createMeeting } from '@/api/meeting';
import dayjs from 'dayjs';
import { openUserSelectionModal } from '@/utils/modals';
import { mapGroupRosters } from '@/baseComponents/Chat/GroupUserSelector/common';
import { UserSelectionResult } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import bus from '@/utils/bus';
import Context from '@/context/ChatContext';
import { ConferenceType, ConferenceApplyRequest } from '@/components/Voip/types';
import { checkCanAddOperate } from '@/utils/chat/group';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getRelationship } from '@/api/chat';
import { getSelectorSessions, isAINoticeSession } from '@/utils/chat/session';
import { get, isString } from 'lodash';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import ImService from '@/server/ImService';
import cache from '@/utils/cache';
import debounce from 'lodash/debounce';
import GroupNoticeDrawerList from '@/components/Projects/pages/groupNotice';
import { getGroupInfo } from '@/api/egroup';
import { NetState } from '@/models/netStatus';
import { NetType } from '@/components/Modals/ChangeNetModal';
import { usePrevious } from '@/utils/chat/index';

interface MessageHeadProps {
    setCallBack: (type: DrawTypeEnum) => void;
    updateMessages: Function;
    changeSelectMore: Function;
    hideSetting: boolean;
}

type IMessageHeadProps = Readonly<MessageHeadProps & ChatState>;

const SessionHeader = (props: IMessageHeadProps & NetState) => {
    const {
        selectedSession,
        currentEmployee,
        sessionMembers,
        setCallBack,
        allSessionList,
        messages,
        updateMessages,
        changeSelectMore,
        hideSetting,
        newPublicResult,
        newPrivateResult,
    } = props;

    const { t } = useTranslation('chat');
    const [t_meeting] = useTranslation('meeting');
    const [t_common] = useTranslation('common');
    const { groupRosters, groupRosterIdentity, userData } = React.useContext(Context);
    const [isfriend, setIsFriend] = useState(false);
    const [voipClick, SetVoipClick] = useState(false);
    const [zoomClick, SetZoomClick] = useState(false);
    const { userId, teamId, app } = selectedSession.info as Employee;
    const [isDimiss, setIsDimiss] = useState(false);
    const preSelectSession = usePrevious(selectedSession || {});

    useEffect(() => {
        if (
            !selectedSession?.sessionId ||
            preSelectSession?.sessionId === selectedSession?.sessionId
        ) {
            return;
        }
        setIsDimiss(false);
        if (!selectedSession.isGroup) {
            return;
        }
        // 优先判断info.flag
        const info = selectedSession.info as GroupAttribute;
        if (info?.flag && info.flag !== 3) {
            setIsDimiss(true);
            return;
        }
        if (!info || !info?.flag) {
            getGroupInfo({
                groupIds: [{ groupId: selectedSession.sessionId || selectedSession.gid || '' }],
            })
                .then((res) => {
                    if (res?.groups?.[0]?.flag !== 3) {
                        setIsDimiss(true);
                    }
                })
                .catch((e) => {
                    console.error(`getGroupInfo error`, e);
                });
        }
    }, [selectedSession, preSelectSession]);

    useEffect(() => {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
    }, []);
    useEffect(() => {
        if (selectedSession.isGroup) {
            return;
        }
        (async () => {
            const [result, err] = await getRelationship({
                app,
                pin: userId,
                teamId: teamId,
            });
            if (err) {
                return;
            }
            const {
                relationship: { relation, friendship },
            } = result;
            if (relation === 1 || friendship === 1) {
                setIsFriend(true);
            }
        })();
    }, [app, selectedSession.isGroup, teamId, userId]);

    useEffect(() => {
        function handleGroupDelete(sessionId: string) {
            if (sessionId === selectedSession.sessionId) {
                setIsDimiss(true);
            }
        }
        bus.on('chat:group_delete', handleGroupDelete);
        return () => {
            bus.off('chat:group_delete', handleGroupDelete);
        };
    }, [selectedSession.sessionId]);

    const forwardMessages = () => {
        // 合并转发
        const fixedUsers = getSelectorSessions(allSessionList);

        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                mode: SelectorMode.forward,
            },
            (data, close) => {
                doExitAndMergeForward(data);
                close();
            },
            userData
        );

        // 清除信息
        updateMessages({ messages: [] });
        changeSelectMore({ selectMore: false, type: null });
    };
    const exitAndMergeForward = async (data: any) => {
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        const imService = ImService.getInstance();
        const sessionId = selectedSession.sessionId;
        const optionResult: { gids: string[]; uids: UID[] } = { gids: [], uids: [] };
        const options = list.reduce((result: { gids: string[]; uids: UID[] }, item: any) => {
            if (item.isGroup) {
                result.gids.push(item.id);
            } else {
                result.uids.push({
                    pin: item?.pin,
                    app: item?.app,
                    teamId: item?.teamId,
                });
            }
            return result;
        }, optionResult);
        let mids: number[] = [];
        let joyspacePageIds: string[] = [];
        messages.forEach((message) => {
            if (message.type === ChatMessageType.JOYSPACEFILE) {
                joyspacePageIds.push();
            }
            mids.push(message.mid);
        });
        const result = await imService.mergeForwardMessage(sessionId, mids, options);
        if (isString(result) && result === SessionSettingsType.BANNED_POST) {
            return toast.warn(t('banned_post_tip'));
        }
        if (result) {
            toast.success(t('forwarding-successful'));
            updateMessages({ messages: [] });
            changeSelectMore({ selectMore: false });
        } else {
            toast.error(t('forwarding-failed'));
        }
    };

    const doExitAndMergeForward = debounce(async (data: any) => {
        return exitAndMergeForward(data);
    }, 500);

    const openUserSelector = useGroupUserSelector({
        selectedSession,
        currentEmployee,
        sessionMembers,
        groupRosters,
        userData,
        forwardMessages,
        callback: () => {
            bus.emit('chat:group:addMember');
        },
    });

    // 是否可邀请入群
    // 点击头像触发右侧设置展开栏
    const setting = useCallback(
        (type: DrawTypeEnum) => {
            setCallBack(type);
        },
        [setCallBack]
    );

    const getSessionGroupCountPart = useCallback(() => {
        if (!selectedSession.isGroup) {
            return null;
        }
        return (
            <div className="notice">
                <Tooltip placement="bottom" title={t('group-size')}>
                    <span
                        className="member"
                        onClick={() => setting(DrawTypeEnum.setup)}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|23"
                    >
                        <IconFont className="member_icon" type="iconic_number" />
                        <span className="member_count">{groupRosters.length}</span>
                    </span>
                </Tooltip>
            </div>
        );
    }, [selectedSession, groupRosters]); // eslint-disable-line

    const canAddOperate = useMemo(() => {
        return checkCanAddOperate({
            selectedSession,
            currentEmployee,
            groupRosterIdentity,
        });
    }, [currentEmployee, groupRosterIdentity, selectedSession]);

    const getCreateGroupPart = useCallback(() => {
        if (!canAddOperate) {
            return null;
        }
        const clstag = selectedSession.isSingle
            ? 'pageclick|keycount|focus_chat_01_1615797500283|25'
            : 'pageclick|keycount|focus_chat_01_1615797500283|24';

        const title = selectedSession.isGroup ? t('add_member') : t('create_group');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div className="add_user no-draggable" onClick={openUserSelector} clstag={clstag}>
                    <IconFont type="iconjiaren" />
                </div>
            </Tooltip>
        );
    }, [canAddOperate, selectedSession.isSingle, selectedSession.isGroup, t, openUserSelector]);

    const getSetupPart = useCallback(() => {
        if (selectedSession.isGroup) {
            bus.emit('chat:getGroupMembers', selectedSession);
        }
        const clstag = 'pageclick|keycount|focus_chat_01_1615797500283|61';
        const title = t('set_up');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    clstag={clstag}
                    className="set_up no-draggable"
                    onClick={() => {
                        setCallBack(DrawTypeEnum.setup);
                    }}
                >
                    <IconFont type="iconbianzu7" />
                </div>
            </Tooltip>
        );
    }, [t, setCallBack, selectedSession]);

    const getSearchPart = useCallback(() => {
        let clstag = 'pageclick|keycount|focus_chat_01_1615797500283|63';
        const title = t('search_record');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    clstag={clstag}
                    className="search_record no-draggable"
                    onClick={() => {
                        setCallBack(DrawTypeEnum.search);
                    }}
                >
                    <IconFont type="iconviewlarger" />
                </div>
            </Tooltip>
        );
    }, [t, setCallBack]);

    const handleCreateSingleMeeting = useCallback(async () => {
        // 启动时为纯政务外网
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            // 互联网可访问了
            if (newPublicResult) {
                toast.warn(t_common('joinMeeting.change public net'));
            } else {
                toast.warn(t_common('joinMeeting.private net'));
            }
            return;
        }
        if (zoomClick) {
            return;
        }
        SetZoomClick(true);
        setTimeout(() => {
            SetZoomClick(false);
        }, 2000);
        async function prepareCreateMeeting(data: any) {
            // 如果当前非空闲，则直接退出
            if (!data.isFree) {
                toast.error(t('joyMeeting.There is a meeting going on'));
                return;
            }
            // open single call meeting
            // 发送消息到桌面端
            const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
            const [result, err] = await createMeeting({
                startTime: (dayjs().valueOf() + 5000).toString(),
                duration: 2,
                topic: t('joyMeeting.meetingTopicDesc')
                    .replace('%from', currentEmployee.name)
                    .replace('%to', name),
                password: '',
                participantIds: [
                    {
                        realName: name,
                        teamId,
                        userId,
                        appId: app,
                        headImg: avatar,
                    },
                    {
                        realName: currentEmployee.name,
                        teamId: currentEmployee.teamId,
                        userId: currentEmployee.userId,
                        appId: currentEmployee.app,
                        headImg: currentEmployee.avatar,
                    },
                ],
            });
            if (err) {
                // 如果请求创建报错，则清空会议状态
                FocusSDK.sendIpcMessage(
                    'MeetingControl:changeJoyMeetingStatus',
                    JoyMeetingStatusEnum.None
                );
                return toast.error(t('joyMeeting.create meeting failed'));
            }
            bus.emit('meeting:create single call', {
                toUser: {
                    username: name,
                    teamId,
                    userId,
                    app,
                    pin: userId,
                    avatar,
                },
                meetingData: result,
            });
        }

        FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', prepareCreateMeeting);
        FocusSDK.sendIpcMessage(
            'MeetingControl:changeJoyMeetingStatus',
            JoyMeetingStatusEnum.SingleCall
        );
    }, [
        currentEmployee.app,
        currentEmployee.avatar,
        currentEmployee.name,
        currentEmployee.teamId,
        currentEmployee.userId,
        selectedSession.info,
        t,
        t_common,
        zoomClick,
        newPublicResult,
    ]);

    function handleCreateGroupMeeting(participants: any[]) {
        // 这里用focussdk，是因为群聊有可能从新窗口打开，所以消息需要从桌面端中转一下，在主窗口打开modal
        FocusSDK.sendToMainWin({
            type: 'meeting:create-show',
            data: {
                list: participants,
            },
        });
        // 主窗口 聚焦
        FocusSDK.sendMainMessage('focusMainWin', {});
    }

    function handleCreateGroupMeetingClick() {
        const groupMembers = mapGroupRosters(groupRosters, { visible: true });
        openUserSelectionModal(
            {
                title: t('add_member'),
                tabs: ['custom'],
                resultUsers: [
                    {
                        id: convertEmployeeToString(currentEmployee),
                        avatar: currentEmployee.avatar,
                        checked: true,
                        name: currentEmployee.name,
                        teamId: currentEmployee.teamId,
                        userId: currentEmployee.userId,
                        visible: true,
                    },
                ],
                frozenIds: [convertEmployeeToString(currentEmployee)],
                customTab: {
                    tabName: t('group_members'),
                    dataList: groupMembers,
                },
                currentUser: employeeToUid(currentEmployee),
            },
            (data: UserSelectionResult, close: () => void) => {
                const { result } = data.data;
                const users = result.map((r) => {
                    const tem = r.id.split(':');
                    return {
                        realName: r.name,
                        userId: tem[0],
                        teamId: tem[2],
                        appId: tem[1],
                        headImg: r.avatar as string,
                    };
                });
                handleCreateGroupMeeting(users);
                close();
            },
            userData
        );
    }

    function getCreateMeetingPart() {
        if (!isFocusEnv()) {
            return null;
        }

        if (selectedSession.isSingle) {
            if (!isfriend) {
                return null;
            }
            // // 解析单聊跨平台逻辑
            const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
            if (app !== currentEmployee.app || teamId !== currentEmployee.teamId) {
                return null;
            }
        }
        if (selectedSession.isNotice) {
            return null;
        }
        if (isCrossPlatformGroupSession(selectedSession)) {
            return null;
        }

        let clstag = 'pageclick|keycount|focus_chat_01_1615797500283|24';
        if (selectedSession.isSingle) {
            const info = selectedSession.info as Employee;
            if (isEqualEmployee(info, currentEmployee)) {
                // 自已跟自已聊天不能创建群聊
                return null;
            }
            clstag = 'pageclick|keycount|focus_chat_01_1615797500283|25';
        }

        const title = selectedSession.isGroup
            ? t('joyMeeting.create meeting')
            : t('joyMeeting.create call');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="create_meeting"
                    onClick={
                        selectedSession.isSingle
                            ? handleCreateSingleMeeting
                            : handleCreateGroupMeetingClick
                    }
                    clstag={clstag}
                >
                    <IconFont type="iconapp_btn_joymeeting_video1" />
                </div>
            </Tooltip>
        );
    }
    function getCreateVoipPart() {
        if (!isFocusEnv()) {
            return null;
        }
        if (selectedSession.isNotice) {
            return null;
        }
        if (!selectedSession.isSingle) {
            return null;
        }
        // 写群聊的时候把是否是好友放到单聊if里
        if (!isfriend) {
            return;
        }

        // 解析单聊跨平台逻辑
        const sessionSplit = selectedSession.sessionId.split(':');
        if (sessionSplit[1] !== sessionSplit[4]) {
            return null;
        }

        let clstag = 'pageclick|keycount|focus_chat_01_1615797500283|24';
        if (selectedSession.isSingle) {
            const info = selectedSession.info as Employee;
            if (isEqualEmployee(info, currentEmployee)) {
                // 自已跟自已聊天不能创建群聊
                return null;
            }
            clstag = 'pageclick|keycount|focus_chat_01_1615797500283|25';
        } else {
            return null;
        }
        function handleCreateSingleVoip() {
            // 处理一下连点场景
            if (voipClick) {
                return;
            }
            SetVoipClick(true);
            setTimeout(() => {
                SetVoipClick(false);
            }, 5000);
            const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
            const opts: Partial<ConferenceApplyRequest> = {
                topic: t('voip.audio meeting'),
                conference_type: ConferenceType.kConferenceSingle,
                sid: selectedSession.sessionId, //
                start: dayjs().valueOf(),
            };

            FocusSDK.sendIpcMessage('voip:createSingleCall', {
                request: opts,
                invite: {
                    member_app_: app,
                    member_id_: userId,
                    team_id_: teamId,
                },
            });
            toast.info(t_meeting('meeting initialization'));
        }
        function handleCreateGroupVoip() {}
        const title = t('voip.audio meeting');

        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="create_meeting"
                    onClick={
                        selectedSession.isSingle ? handleCreateSingleVoip : handleCreateGroupVoip
                    }
                    clstag={clstag}
                >
                    <IconFont type="iconic_pc_im_call1" />
                </div>
            </Tooltip>
        );
    }

    // 组(群聊)增加公告 --hujun
    const globalcontext = useContext<GlobalContextConfig>(GlobalContext);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const projectType = `project${
        ['mebj', 'jgswy', 'mebjJZB'].includes(appRuntimeEnv.runtimeConfig.key)
            ? '_' + appRuntimeEnv.runtimeConfig.key
            : ''
    }`;
    const t1 = useTranslation(projectType).t;
    function getCreateGroupNotice() {
        // 不是群组
        if (!selectedSession.isGroup) {
            return null;
        }
        const clstag = 'pageclick|keycount|focus_chat_01_1602584371145|24';
        const title = t('groupNotice');
        return (
            <Tooltip title={title} overlayStyle={{ whiteSpace: 'nowrap' }} placement="bottom">
                <div
                    className="create_meeting"
                    onClick={() => {
                        GroupNoticeDrawerList({
                            prentVisible: true,
                            projectId: selectedSession.id || selectedSession.sessionId,
                            globalcontext,
                            t: t1,
                            level: groupRosterIdentity,
                        });
                    }}
                    clstag={clstag}
                >
                    <IconFont type="iconic_notice11" />
                </div>
            </Tooltip>
        );
    }

    return (
        <div className="message-head-container grey">
            <div className="message-head-inner no-draggable">
                <div onClick={() => setting(DrawTypeEnum.setup)}>
                    <HeadPortrait
                        key={0}
                        width="36px"
                        height="36px"
                        imgUrl={selectedSession?.info?.avatar}
                        name={selectedSession?.info?.name}
                        type={selectedSession.sessionType}
                        isUnFollow={true}
                        showType={HeadShowType.SESSION}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|21"
                    />
                </div>
                <div className="name-notice">
                    <div className="name">
                        <span
                            className="name-1"
                            onClick={() => setting(DrawTypeEnum.setup)}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|22"
                        >
                            {selectedSession?.info?.name}
                        </span>
                        <SessionTag
                            session={selectedSession}
                            currentEmployee={currentEmployee}
                            needJump
                        />
                    </div>
                    {getSessionGroupCountPart()}
                </div>
            </div>
            {!hideSetting ? (
                <div className="action-button">
                    {!isDimiss && getCreateGroupNotice()}
                    {config[ChatConfig.CHAT_SESSION_CREATE_VOIP_ENABLE] && getCreateVoipPart()}
                    {/* {(selectedSession?.info as GroupAttribute).gid && getShareJoyspace()} */}
                    {!isAINoticeSession(selectedSession) &&
                        selectedSession.sessionId &&
                        getSearchPart()}
                    {config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE] &&
                        !isDimiss &&
                        getCreateMeetingPart()}
                    {!isDimiss && getCreateGroupPart()}
                    {!isDimiss && selectedSession.sessionId && getSetupPart()}
                </div>
            ) : null}
        </div>
    );
};

function mapStateToProps({ chat, netStatus }: any) {
    const {
        selectedSession,
        currentEmployee,
        sessionMembers,
        allSessionList,
        messages,
    } = chat as ChatState;

    const { newPublicResult, newPrivateResult } = netStatus;
    return {
        selectedSession,
        currentEmployee,
        sessionMembers,
        allSessionList,
        messages,
        newPublicResult,
        newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionHeader);
