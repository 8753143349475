const config = {
    'system-message-group': {
        group_create: ['administrator', 'ordinary'],
        group_delete: ['administrator', 'ordinary'],
        group_member_in: ['administrator', 'ordinary'],
        group_out: ['administrator', 'ordinary'],
        group_member_delete: ['administrator', 'ordinary'],
        group_set: ['administrator', 'ordinary'],
        group_banned_post: ['administrator', 'ordinary'],
        group_admin_set: ['administrator', 'ordinary'],
    },
    // dva 每个session 最大缓存消息数量
    'chat-message-max-dva-count': 50,
    // dva 每个session 预加载消息数量
    'chat-message-max-dva-pre-count': 20,
    // 每个session 拉取历史记录分页记录数量
    'chat-message-page-count': 20,
    'chat-session-invisible-list': ['eopen-push_gc1EijDXzxUehGW1ydK5'],
    'chat-revoke-time': 1000 * 60 * 10,
    'chat-revoke-time2': 1000 * 60 * 60 * 24,
    'forward-leave-max-length': 2000,
    'forward-session-count': 5,
    'max-image-size': 1024 * 1024 * 10,
};

export default config;
