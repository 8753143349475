import React, { useEffect, useState, useCallback } from 'react';
import './index.less';
import { SubHeader, HeaderWithMore, TextWithDot } from './common';
import { Circle } from './Circle';
import { receivedSupervise, sendSupervise } from '@/assets/img';
import i18n from '@/i18n';
import { useHistory } from 'react-router-dom';
import { TaskPartEnum } from '@/types/work';
import {
    findProjectDetail,
    getReceiveSuperviseAllDataByWeb,
    getCreateSuperviseAllDataByWeb,
} from '@/api/supervise';
import { IfindProjectDetailResp, IsuperviseAllData } from '@/types/supervise';
import Dayjs from 'dayjs';
import bus from '@/utils/bus';
import { useAppId } from '@/components/Supervise/envirment';

interface Iprops {
    projectId: string;
    changeTab: (tab: string) => void;
}
export default function DataPanel(props: Iprops) {
    const { projectId, changeTab } = props;

    const history = useHistory();
    const [taskData, setTaskData] = useState<IfindProjectDetailResp>();
    const [superviseReceive, setSuperviseReceive] = useState<IsuperviseAllData>();
    const [superviseSend, setSuperviseSend] = useState<IsuperviseAllData>();
    const appId = useAppId();
    const getProjectDetail = useCallback(() => {
        findProjectDetail({
            projectId,
            // @ts-ignore
            endTimeFrom: Date.parse(new Date(new Date().setHours(0, 0, 0, 0))),
            // @ts-ignore
            endTimeTo: Date.parse(new Date(new Date().setHours(23, 59, 59, 0))),
            currentTimestamp: Date.now(),
        }).then((res) => {
            const data: IfindProjectDetailResp = res[0] || {};
            setTaskData(data);
        });
    }, [projectId]);
    useEffect(() => {
        if (!projectId) {
            return;
        }
        getProjectDetail();
        getCreateSuperviseAllDataByWeb({
            sourceBizId: projectId,
            sourceBizType: 'PROJECT',
        }).then((res) => {
            const data: IsuperviseAllData = res[0] || {};
            setSuperviseSend(data);
        });
        getReceiveSuperviseAllDataByWeb({
            sourceBizId: projectId,
            sourceBizType: 'PROJECT',
        }).then((res) => {
            const data: IsuperviseAllData = res[0] || {};
            setSuperviseReceive(data);
        });
    }, [getProjectDetail, projectId]);

    useEffect(() => {
        function multiSync(data: any) {
            if (!data?.data) return;
            if (
                (data.data.part === TaskPartEnum.PROJECT_LIST ||
                    data.data.part.indexOf('TASK') !== -1) &&
                data.data.projectId === projectId
            ) {
                getProjectDetail();
            }
        }
        bus.on('chat_message_multiSync_event:joyWork', multiSync);
        return () => {
            bus.off('chat_message_multiSync_event:joyWork', multiSync);
        };
        // eslint-disable-next-line
    }, []);
    function getRate(a: number | undefined, b: number | undefined) {
        if (!b || !a) {
            return 0;
        }
        let v = a / b;
        let fix = parseFloat(v.toFixed(2));
        let c = Math.min(Math.round(fix * 100) / 100, 1);
        return c;
    }
    function toInit(v: number) {
        return parseInt(`${Math.round(v)}`, 10);
    }
    return (
        <div className="data-panel-wrapper">
            <div className="dp-content">
                <SubHeader
                    title={i18n.t('supervise:taskPanel')}
                    more={true}
                    moreClickHandle={() => {
                        changeTab('1');
                    }}
                />
                <div className="dp-task">
                    <div className="dp-task-item">
                        <p className="dp-pointer" onClick={() => changeTab('1')}>
                            {i18n.t('supervise:myTask')}
                        </p>
                        <p className="dp-primary" style={{ fontSize: 20 }}>
                            {taskData?.myTaskNum}
                        </p>
                    </div>
                    <div className="dp-task-item border">
                        <p className="dp-pointer" onClick={() => changeTab('1')}>
                            {i18n.t('supervise:overdueTask')}
                        </p>
                        <p className="dp-primary" style={{ fontSize: 20 }}>
                            {taskData?.overdueTaskNum}
                        </p>
                    </div>
                    <div className="dp-task-item">
                        <p className="dp-pointer" onClick={() => changeTab('1')}>
                            {i18n.t('supervise:todoFinish')}
                        </p>
                        <p className="dp-primary" style={{ fontSize: 20 }}>
                            {taskData?.unFinishTaskNum}
                        </p>
                    </div>
                </div>
                <SubHeader title={i18n.t(`supervise:SuperviseProgress${appId}`)} />
                <div className="dp-progress">
                    <div className="dp-progress-item">
                        <HeaderWithMore
                            title={i18n.t(`supervise:receivedSupervise${appId}`)}
                            icon={receivedSupervise}
                            clickHandle={() => {
                                history.push(
                                    `/supervise/0?sourceBizId=${projectId}&sourceBizType=PROJECT`
                                );
                            }}
                        />
                        <div style={{ margin: '0 auto', width: 200 }}>
                            <Circle
                                text1={`${toInit(
                                    getRate(superviseReceive?.finish, superviseReceive?.total) * 100
                                )}%`}
                                text2={i18n.t('supervise:completionRate')}
                                color="#0074FF"
                                value={getRate(superviseReceive?.finish, superviseReceive?.total)}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                padding: '0 34px',
                                justifyContent: 'space-between',
                                marginTop: -6,
                            }}
                        >
                            <TextWithDot
                                label={i18n.t('supervise:iReceive')}
                                value={superviseReceive?.total || 0}
                                colorValue="#232831"
                                colorDot="#BFC1C3"
                            />
                            <TextWithDot
                                label={i18n.t('supervise:iFinish')}
                                value={superviseReceive?.finish || 0}
                                colorValue="#0074FF"
                                colorDot="#0074FF"
                            />
                        </div>
                    </div>
                    <div className="dp-progress-item">
                        <HeaderWithMore
                            title={i18n.t(`supervise:sendSupervise${appId}`)}
                            icon={sendSupervise}
                            clickHandle={() => {
                                history.push(
                                    `/supervise/1?sourceBizId=${projectId}&sourceBizType=PROJECT`
                                );
                            }}
                        />
                        <div style={{ margin: '0 auto', width: 200 }}>
                            <Circle
                                text1={`${toInit(
                                    getRate(superviseSend?.finish, superviseSend?.total) * 100
                                )}%`}
                                text2={i18n.t('supervise:completionRate')}
                                color="#F96137"
                                value={getRate(superviseSend?.finish, superviseSend?.total)}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                padding: '0 34px',
                                justifyContent: 'space-between',
                                marginTop: -6,
                            }}
                        >
                            <TextWithDot
                                label={i18n.t('supervise:iSend')}
                                value={superviseSend?.total || 0}
                                colorValue="#232831"
                                colorDot="#BFC1C3"
                            />
                            <TextWithDot
                                label={i18n.t('supervise:otherFinish')}
                                value={superviseSend?.finish || 0}
                                colorValue="#F96137"
                                colorDot="#F96137"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
