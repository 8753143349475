import React, { useState, useContext, useEffect } from 'react';
import { getInviteUserByTeamCode, sendJoinApprove, InviteUserInfo } from '@/api/joinMe';
import { useTranslation } from 'react-i18next';
import { Input, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getUserDefaultAvatar } from '@/utils/oss';
import InputWithCtr from './InputWithCtr';
import { changeEgovUrl } from '@/utils/tools';
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
const jionWaitImg = `${REACT_APP_OSS_BASE_URL}/statics/images/join-wait.png`;
enum JoinOtherTeamStatus {
    Init, // 团队码输入页面
    InvitorCheck, // 带加入页面
    WaitingApproval, // 等待审核页面
    Finish, // 加入完成页面 目前该状态没用上
}
export default function JoinOtherTeam({ goBack }: { goBack?: () => void }) {
    const [teamCode, setTeamCode] = useState<string | undefined>('');
    const [teamInfo, setTeamInfo] = useState<InviteUserInfo | null>(null);
    const [joinResult, setJoinResult] = useState<InviteUserInfo | null>(null);
    const [errorCode, setErroeCode] = useState(false);
    const [joinStatus, setJoinStatus] = useState(JoinOtherTeamStatus.Init);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('joinMe');

    function onTeamCodeChange(v: string) {
        if (v && v.split(' ').join('').length > 8) {
            return;
        }
        setTeamCode(v);
        if (errorCode) {
            setErroeCode(false);
        }
    }
    function getInviteUser() {
        if (!teamCode) {
            return;
        }
        setLoading(true);
        getInviteUserByTeamCode({
            teamCode: teamCode?.split(' ').join(''),
        })
            .then((content) => {
                if (content) {
                    setErroeCode(false);
                    setTeamInfo(content as InviteUserInfo);
                    setJoinStatus(JoinOtherTeamStatus.InvitorCheck);
                }
            })
            .catch((err) => {
                setErroeCode(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function sendJoin(teamInfo: InviteUserInfo) {
        setLoading(true);
        sendJoinApprove({
            inviteTeamId: teamInfo?.teamId,
            inviteUserId: teamInfo?.userId,
        })
            .then((content) => {
                if (content) {
                    setJoinResult(content as InviteUserInfo);
                    setJoinStatus(JoinOtherTeamStatus.WaitingApproval);
                }
            })
            .catch((error) => {
                // setErroeCode(true);
                if (error.message?.split(']')[1]) {
                    message.warn(error.message?.split(']')[1]);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }
    function getFormatTeamCode(code: string | undefined) {
        // if (code && code.length > 4) {
        //     let c = code.split(' ').join('');
        //     return c.slice(0, 4) + ' ' + c.slice(4);
        // } else {
        //     return code;
        // }
        return code;
    }
    function handleLinkClick(url: string) {
        if (!url) {
            return false;
        }
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('target', '_blank');
        a.click();
    }
    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );
    const teamCodeInput = (
        <>
            <div>
                <h4>{t('inputInviteCode')}</h4>
                <p
                    style={{
                        fontSize: 12,
                        lineHeight: 1,

                        marginBottom: 52,
                    }}
                >
                    {t('inviteCodeDesc')}
                </p>
            </div>
            <div
                style={{
                    position: 'relative',
                }}
            >
                {/* <Input
                    autoFocus
                    allowClear
                    placeholder={t('inviteCodePlaceholder')}
                    value={getFormatTeamCode(teamCode)}
                    onKeyDown={(e: any) => {
                        const { keyCode } = e;
                        if (keyCode === 13) {
                            // checkMobileCb();
                        }
                    }}
                    onChange={(e: any) => {
                        console.log(e.target?.selectionEnd);
                        onTeamCodeChange(e);
                    }}
                    className={`${errorCode ? 'warn-info' : ''}`}
                /> */}
                <InputWithCtr
                    autoFocus
                    allowClear
                    placeholder={t('inviteCodePlaceholder')}
                    onKeyDown={(e: any) => {
                        const { keyCode } = e;
                        if (keyCode === 13) {
                            // checkMobileCb();
                        }
                    }}
                    value={teamCode || ''}
                    onChange={(v: any) => {
                        onTeamCodeChange(v);
                    }}
                    type="code"
                    className={`${errorCode ? 'warn-info' : ''}`}
                />
                {errorCode ? (
                    <p
                        className="warn-info"
                        style={{
                            marginTop: 10,
                            position: 'absolute',
                        }}
                    >
                        {t('teamCodeError')}
                    </p>
                ) : null}
            </div>
            <div
                onKeyDown={() => {}}
                onClick={() => {
                    getInviteUser();
                }}
                className="btn big normal"
                style={{
                    marginTop: 162,
                    height: 42,
                    opacity: teamCode?.length ? 1 : 0.5,
                    cursor: teamCode?.length ? 'pointer' : 'not-allowed',
                }}
            >
                {loading && <div style={{ margin: 4 }}>{loadingComp}</div>}
                <div
                    style={{
                        fontSize: 14,
                        color: 'rgb(255, 255, 255)',
                    }}
                >
                    {t('nextStep')}
                </div>
            </div>
        </>
    );
    const invitorCheck = (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <img
                style={{
                    width: 60,
                    height: 60,
                    borderRadius: 9,
                    marginBottom: 24,
                }}
                src={changeEgovUrl(teamInfo?.avatar || getUserDefaultAvatar())}
                alt=""
            />
            <p
                style={{
                    marginBottom: 68,
                    fontSize: 18,
                }}
            >
                <span
                    style={{
                        // fontFamily: 'PingFangSC-Bold',
                        marginRight: 8,
                    }}
                >
                    {teamInfo?.userName}
                </span>
                <span>{t('inviteYouJoin')}</span>
            </p>
            <p
                style={{
                    marginBottom: 90,
                    fontSize: 30,
                    lineHeight: 1,
                }}
            >
                {teamInfo?.teamName}
            </p>
            <div
                onKeyDown={() => {}}
                onClick={() => {
                    teamInfo && sendJoin(teamInfo);
                }}
                className="btn big normal"
                style={{
                    margin: '162px auto 35px auto',
                    height: 42,
                    opacity: teamCode?.length ? 1 : 0.5,
                    cursor: teamCode?.length ? 'pointer' : 'not-allowed',
                }}
            >
                {loading && <div style={{ margin: 4 }}>{loadingComp}</div>}
                <div
                    style={{
                        fontSize: 14,
                        color: 'rgb(255, 255, 255)',
                    }}
                >
                    {t('joinTeam')}
                </div>
            </div>
            <div className="login-right-msg">
                {t('svcProtocolLabel')}
                <a
                    onClick={() => {
                        // 废弃功能
                        handleLinkClick('https://me.jdcloud.com/protocol/service/chinese/');
                        // remote.shell.openExternal(
                        //     'https://me.jdcloud.com/protocol/service/chinese/'
                        // );
                    }}
                >
                    {t('svcProtocol')}
                </a>
                {t('and')}
                <a
                    onClick={() => {
                        // 废弃功能
                        handleLinkClick('https://me.jdcloud.com/protocol/privacy/chinese/');
                        // remote.shell.openExternal(
                        //     'https://me.jdcloud.com/protocol/privacy/chinese/'
                        // );
                    }}
                >
                    {t('yinSiZhengCe')}
                </a>
            </div>
        </div>
    );
    const WaitingApproval = (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <img
                style={{
                    width: 287,
                    height: 225,
                    marginBottom: 32,
                }}
                src={changeEgovUrl(jionWaitImg)}
                alt=""
            />
            <p
                style={{
                    marginBottom: 68,
                    padding: '12px 24px',
                    fontSize: 16,
                    lineHeight: 1,
                    background: 'rgba(49,196,204,0.0762)',
                    borderRadius: 6,
                }}
                className="primary-info"
            >
                {t('waittingApproval')}
            </p>

            <div
                onKeyDown={() => {}}
                onClick={() => {
                    goBack?.();
                }}
                className="btn big normal"
                style={{
                    margin: '58px auto',
                    height: 42,
                }}
            >
                {t('back')}
            </div>
        </div>
    );
    return (
        <div>
            {joinStatus === JoinOtherTeamStatus.Init ? teamCodeInput : null}
            {joinStatus === JoinOtherTeamStatus.InvitorCheck ? invitorCheck : null}
            {joinStatus === JoinOtherTeamStatus.WaitingApproval ? WaitingApproval : null}
        </div>
    );
}
