// 设置展开栏头部

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { Employee, Session } from '@/types/chat';
import { Button, Dropdown, Input, message, Modal, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ImService from '@/server/ImService';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import { HeadShowType } from '@/types/chat/enum';
import { openUserCardModal, userEscapeString } from '@/utils/chat/index';
import Context from '@/components/chat/context';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { checkCanModifyGroup } from '@/components/chat/utils/group';
// eslint-disable-next-line no-duplicate-imports
import { isGroupAdmin, isGroupOwner } from '@/components/chat/utils/group';
import bus from '@/utils/bus';
import CopyClipboard from 'copy-to-clipboard';
import { isFocusEnv } from '@/utils';
import { analysisLog } from '@/utils/logAnalytics';

const { Paragraph } = Typography;
const { TextArea } = Input;

interface Props {
    selectedSession: Session;
}

function GroupHead(props: Props) {
    const { selectedSession } = props;
    const { groupRosterIdentity } = React.useContext(Context);
    const [value, setValue] = useState('');
    const [intro, setIntro] = useState('');
    const [visible, setVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [visibleView, setVisibleView] = useState(false);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const { t } = useTranslation('chat');
    useEffect(() => {
        setValue('');
        setIntro('');
    }, [selectedSession.sessionId]);

    const canModifyGroup = useMemo(() => {
        return checkCanModifyGroup({ selectedSession, groupRosterIdentity });
    }, [selectedSession, groupRosterIdentity]);

    // 点击编辑ICON
    const showModal = useCallback(() => {
        if (groupRosterIdentity === 'ordinary') {
            setVisible(false);
        } else if (!value) {
            setValue(selectedSession.info?.name);
            setVisible(!visible);
        }
        // if (isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity)) {
        //     if (selectedSession.info?.intro) {
        //         setIntro(selectedSession.info?.intro);
        //     } else if (!intro) {
        //         setIntro('');
        //     }
        // }
    }, [selectedSession.info, value, groupRosterIdentity, visible]);

    // input 点击确定修改名称
    const confirmEdit = useCallback(async () => {
        if (saving || !visible) {
            return;
        }
        setSaving(true);
        if (value !== '' && value.length <= 50) {
            const instance = ImService.getInstance();
            const result = await instance.setGroupName(selectedSession.id, value);
            // const resultIntro = await instance.setGroupIntro(selectedSession.id, intro);
            if (result) {
                setVisible(false);
                setValue('');
                setSaving(false);
                // setIntro('');
                // 触发一个事件 告知群组是否需要更新日程详情显示数据
                bus.emit('chat:group:refreshScheduleInfo');
            } else {
                message.info(t('failed'));
                setSaving(false);
            }
        } else {
            message.info(t('please-fill-group-name'));
            setSaving(false);
        }
    }, [selectedSession.id, t, value, visible, saving]); // eslint-disable-line

    // input 点击确定修改组介绍
    const confirmEditIntro = useCallback(async () => {
        if (saving || !visibleView) {
            return;
        }
        setSaving(true);
        const instance = ImService.getInstance();
        const resultIntro = await instance.setGroupIntro(selectedSession.id, intro);
        if (resultIntro) {
            setVisibleView(false);
            setIntro('');
            setSaving(false);
            // 触发一个事件 告知群组是否需要更新日程详情显示数据
            bus.emit('chat:group:refreshScheduleInfo');
        } else {
            message.info(t('failed'));
            setSaving(false);
        }
    }, [selectedSession.id, t, intro, visibleView, saving]); // eslint-disable-line

    // 修改input框的value
    const onChange = (e: any) => {
        setValue(e?.target.value);
    };
    const onChangeIntro = (e: any) => {
        setIntro(e?.target.value || '');
    };

    // 点击取消弹框消失
    const handleCancel = useCallback((e: any) => {
        setVisible(false);
        setValue('');
        // setIntro('');
    }, []);

    const handleCancelView = useCallback(() => {
        setVisibleView(false);
        setIntro('');
    }, []);
    const onClickGroupIntro = useCallback(() => {
        if (!selectedSession.info?.intro && groupRosterIdentity === 'ordinary') {
            return;
        }
        setIntro(!selectedSession.info?.intro ? '' : selectedSession.info?.intro);
        setVisibleView(true);
    }, [selectedSession.info, groupRosterIdentity]);

    const onVisibleChange = (flag: boolean) => {
        setContextMenuVisible(flag);
    };
    const doCopy = (e: any) => {
        e.stopPropagation();
        if (intro) {
            let userSelection;
            let str = intro;
            if (typeof window.getSelection === 'function') {
                userSelection = window.getSelection();
            }
            if (userSelection?.toString()) {
                str = userSelection.toString();
            }
            if ((str || '').length > (intro || '').length) {
                str = intro;
            }
            if (str && CopyClipboard(str)) {
                message.success('复制成功');
            } else {
                message.error('复制失败');
            }
            setContextMenuVisible(false);
        }
    };

    const doPaste = (e: any) => {
        e.stopPropagation();
        focus();
        document.execCommand('paste');
        setContextMenuVisible(false);
    };

    const modifyGroupPart = useMemo(() => {
        if (!canModifyGroup) {
            return null;
        }
        if (groupRosterIdentity === 'ordinary') {
            return null;
        }
        return (
            <Tooltip title={t('edit-group-info')}>
                <IconFont
                    type="iconapp_ic_draft"
                    className="edit_img"
                    onClick={() => {
                        showModal();
                    }}
                />
            </Tooltip>
        );
    }, [canModifyGroup, groupRosterIdentity, showModal, t]);

    const headPortrait = useMemo(() => {
        return (
            <HeadPortrait
                width="40px"
                height="40px"
                imgUrl={selectedSession?.info?.avatar}
                name={selectedSession?.info?.name}
                type={selectedSession?.sessionType}
                isUnFollow={true}
                showType={HeadShowType.GROUP_SETTING}
                onItemClick={() => {
                    if (selectedSession.isSingle && !selectedSession.isNotice) {
                        analysisLog('Xtbg_Msg_Setting', 'SessionAvatar');
                        openUserCardModal(selectedSession.info as Employee);
                    }
                }}
            />
        );
    }, [selectedSession]);

    // 底部button
    const modalFooter = (
        <div>
            <Button
                style={{ border: '1px solid #dee0e3', borderRadius: ' 4px', color: '#242931' }}
                onClick={handleCancel}
            >
                {t('cancelText')}
            </Button>
            <Button
                loading={saving}
                type="primary"
                style={{
                    border: 'none',
                    background: '@primary-color',
                    borderRadius: '4px',
                    fontSize: '14px',
                    color: '#ffffff',
                }}
                onClick={confirmEdit}
            >
                {t('determine')}
            </Button>
        </div>
    );

    const modalFooterView = (
        <div>
            <Button
                style={{ border: '1px solid #dee0e3', borderRadius: ' 4px', color: '#242931' }}
                onClick={handleCancelView}
            >
                {t('cancelText')}
            </Button>
            {groupRosterIdentity === 'ordinary' ? null : (
                <Button
                    loading={saving}
                    type="primary"
                    style={{
                        border: 'none',
                        background: '@primary-color',
                        borderRadius: '4px',
                        fontSize: '14px',
                        color: '#ffffff',
                    }}
                    onClick={confirmEditIntro}
                >
                    {t('determine')}
                </Button>
            )}
        </div>
    );
    // console.log(
    //     'group-name=>',
    //     value + 'value',
    //     selectedSession?.info?.name + 'info.name',
    //     userEscapeString(value || selectedSession?.info?.name)
    // );
    return (
        <div className="group-head">
            <div className="group-top">
                {headPortrait}
                <div className="group_name">
                    <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: ' ' }}>
                        <span
                            className="group_name_html"
                            dangerouslySetInnerHTML={{
                                __html: userEscapeString(value || selectedSession?.info?.name),
                            }}
                        />
                        {/* {value || selectedSession?.info?.name} */}
                    </Paragraph>
                    {modifyGroupPart}
                </div>
            </div>
            {selectedSession.isGroup && (
                <div className="group-set" onClick={onClickGroupIntro}>
                    <div className="group-set-left">
                        <p>{t('group-intro')}</p>
                    </div>
                    <div
                        className="group-gid"
                        style={{
                            cursor: groupRosterIdentity === 'ordinary' ? 'default' : 'pointer',
                        }}
                    >
                        <div className="group-intro-text">
                            {!selectedSession?.info?.intro
                                ? '该组还未添加组介绍'
                                : selectedSession?.info?.intro}
                        </div>
                    </div>
                    {!selectedSession?.info?.intro && groupRosterIdentity === 'ordinary' ? null : (
                        <div className="group-arrow">
                            {/* iconapp_ic_arrow_right */}
                            <IconFont
                                type="iconjs_ilist_arrowright"
                                className="group-management-icon-arrow"
                                style={{
                                    fontSize: '14px',
                                    cursor:
                                        groupRosterIdentity === 'ordinary' ? 'default' : 'pointer',
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <Modal
                centered={true}
                title={<span style={{ fontWeight: 'bold' }}>组名称</span>}
                visible={visible}
                onCancel={handleCancel}
                footer={modalFooter}
                wrapClassName="group-intro-modal"
            >
                <div>
                    <Input
                        placeholder={t('please-enter-group-name')}
                        value={value.trim()}
                        maxLength={50}
                        onChange={onChange}
                        style={{ border: ' 1px solid @primary-color', borderRadius: '4px' }}
                    />
                </div>
            </Modal>
            <Modal
                centered={true}
                title={<span style={{ fontWeight: 'bold' }}>组介绍</span>}
                visible={visibleView}
                onCancel={handleCancelView}
                footer={modalFooterView}
                wrapClassName="group-intro-modal"
            >
                {isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity) ? (
                    <div>
                        {isFocusEnv() ? (
                            <Dropdown
                                overlay={() => {
                                    return (
                                        <div className="contextMenu-wrapper">
                                            <div
                                                className="contextMenu-item"
                                                onClick={(e) => {
                                                    doPaste(e);
                                                }}
                                            >
                                                粘贴
                                            </div>
                                            <div className="contextMenu-item" onClick={doCopy}>
                                                复制
                                            </div>
                                        </div>
                                    );
                                }}
                                trigger={['contextMenu']}
                                visible={contextMenuVisible}
                                onVisibleChange={onVisibleChange}
                            >
                                <TextArea
                                    rows={6}
                                    placeholder={t('please-enter-group-intro')}
                                    value={intro}
                                    maxLength={500}
                                    onChange={onChangeIntro}
                                    style={{
                                        border: ' 1px solid @primary-color',
                                        borderRadius: '4px',
                                        resize: 'none',
                                    }}
                                />
                            </Dropdown>
                        ) : (
                            <TextArea
                                rows={6}
                                placeholder={t('please-enter-group-intro')}
                                value={intro}
                                maxLength={500}
                                onChange={onChangeIntro}
                                style={{
                                    border: ' 1px solid @primary-color',
                                    borderRadius: '4px',
                                    resize: 'none',
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <Dropdown
                        overlay={() => {
                            return (
                                <div className="contextMenu-wrapper">
                                    <div className="contextMenu-item" onClick={doCopy}>
                                        复制
                                    </div>
                                </div>
                            );
                        }}
                        trigger={['contextMenu']}
                        visible={contextMenuVisible}
                        onVisibleChange={onVisibleChange}
                    >
                        <div className="group-intro-content">
                            {selectedSession.isGroup &&
                                !!selectedSession?.info?.intro &&
                                selectedSession.info.intro}
                        </div>
                    </Dropdown>
                )}
            </Modal>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupHead);
function data(data: any) {
    throw new Error('Function not implemented.');
}
