// 业务类型
export enum BusinessType {
    Page = 1,
    Team = 2,
    Space = 3,
    Folder = 4,
    Persion = 5,
}

// 每个功能模块的唯一Key （用于列表）
export enum BusinessKey {
    Recent = 1 << 0, // 最近打开
    Myshare = 1 << 1, // 我发出的
    Shares = 1 << 2, // 我收到的
    Team = 1 << 3, // 团队页面列表
    MySpace = 1 << 4, // 我的空间列表
    Follow = 1 << 5, // 我的关注
    Collections = 1 << 6, // 我的收藏
    Trashes = 1 << 7, // 回收站
}

// 模板类型
export enum PageType {
    Page = 1,
    Reporting = 2,
    Note = 3,
    Sheet = 4,
    Link = 5,
    File = 6,
    WebSheet = 7,
    Form = 8,
}

// 模版类型对应的key
export const PageTypeKey = {
    [PageType.Page as any]: 'page',
    [PageType.Reporting as any]: 'report',
    [PageType.Note as any]: 'note',
    [PageType.Link as any]: 'link',
    [PageType.File as any]: 'file',
    [PageType.Sheet as any]: 'sheet',
    [PageType.WebSheet as any]: 'websheet',
    [PageType.Form as any]: 'form',
};

// 模板类型
export const PageTypes = {
    [PageTypeKey[PageType.Page] as string]: PageType.Page,
    [PageTypeKey[PageType.Reporting] as string]: PageType.Reporting,
    [PageTypeKey[PageType.Note] as string]: PageType.Note,
    [PageTypeKey[PageType.Link] as string]: PageType.Link,
    [PageTypeKey[PageType.File] as string]: PageType.File,
    [PageTypeKey[PageType.Sheet] as string]: PageType.Sheet,
    [PageTypeKey[PageType.WebSheet] as string]: PageType.WebSheet,
    [PageTypeKey[PageType.Form] as string]: PageType.Form,
};

export enum PermissionEnum {
    TEAM_CREATE = 'TEAM_CREATE',
    TEAM_VIEW = 'TEAM_VIEW',
    TEAM_EDIT = 'TEAM_EDIT',
    TEAM_REMOVE = 'TEAM_REMOVE',
    TEAM_MEMBER_CREATE = 'TEAM_MEMBER_CREATE',
    TEAM_MEMBER_VIEW = 'TEAM_MEMBER_VIEW',
    TEAM_MEMBER_REMOVE = 'TEAM_MEMBER_REMOVE',
    SPACE_CREATE = 'SPACE_CREATE',
    SPACE_VIEW = 'SPACE_VIEW',
    SPACE_EDIT = 'SPACE_EDIT',
    SPACE_REMOVE = 'SPACE_REMOVE',
    CATEGORY_CREATE = 'CATEGORY_CREATE',
    CATEGORY_VIEW = 'CATEGORY_VIEW',
    CATEGORY_EDIT = 'CATEGORY_EDIT',
    CATEGORY_REMOVE = 'CATEGORY_REMOVE',
    PAGE_CREATE = 'PAGE_CREATE',
    PAGE_READ = 'PAGE_READ',
    PAGE_EDIT = 'PAGE_EDIT',
    PAGE_MOVE = 'PAGE_MOVE',
    PAGE_COPY = 'PAGE_COPY',
    PAGE_VIEW = 'PAGE_VIEW',
    PAGE_PUBLISH = 'PAGE_PUBLISH',
    PAGE_UNPUBLISH = 'PAGE_UNPUBLISH',
    PAGE_PARENT_CHANGE = 'PAGE_PARENT_CHANGE',
    PAGE_REMOVE = 'PAGE_REMOVE',
    NOTE_CREATE = 'NOTE_CREATE',
    NOTE_REMOVE = 'NOTE_REMOVE',
    NOTE_RESOLVE = 'NOTE_RESOLVE',
    COMMENT_CREATE = 'COMMENT_CREATE',
    COMMENT_REMOVE = 'COMMENT_REMOVE',
    SHARE_CREATE = 'SHARE_CREATE',
    SHARE_EDIT = 'SHARE_EDIT',
    SHARE_VIEW = 'SHARE_VIEW',
    SHARE_REMOVE = 'SHARE_REMOVE',
    ACCESS_DENY = 'ACCESS_DENY',
    FOLDER_CREATE = 'FOLDER_CREATE',
    FOLDER_EDIT = 'FOLDER_EDIT',
    FOLDER_REMOVE = 'FOLDER_REMOVE',
    FOLDER_MOVE = 'FOLDER_MOVE',
    FOLDER_COPY = 'FOLDER_COPY',
    FOLDER_VIEW = 'FOLDER_VIEW',
    FOLDER_PARENT_CHANGE = 'FOLDER_PARENT_CHANGE',
    MODAL_NOT_FOUND = 'MODAL_NOT_FOUND',
    REPORT_EDIT = 'REPORT_EDIT',
    REPORT_PUB = 'REPORT_PUB',
    FOLDER_PUBLIC_SET = 'FOLDER_PUBLIC_SET',
}
// 模板类型
export const DefaultPermisions = [
    PermissionEnum.PAGE_CREATE,
    PermissionEnum.PAGE_READ,
    PermissionEnum.PAGE_EDIT,
    PermissionEnum.PAGE_MOVE,
    PermissionEnum.PAGE_COPY,
    PermissionEnum.PAGE_VIEW,
    PermissionEnum.PAGE_PUBLISH,
    PermissionEnum.PAGE_UNPUBLISH,
    PermissionEnum.PAGE_PARENT_CHANGE,
    PermissionEnum.PAGE_REMOVE,
];
