/**
 * 职责链服务
 * 使用场景：一条数据有多种类型需要处理，可以把每种类型的处理函数传入职责链，每个函数处理一种类型的数据，减少业务代码的判断逻辑；
 * 范型P是对传入参数的约束
 */

export const NEXT_FLAG = 'nextSuccessor';
export class Chain<P> {
    fn: (param: P) => any;
    successor: Chain<P> | null;
    constructor(fn: (param: P) => any) {
        this.fn = fn;
        this.successor = null;
    }

    setNextSuccessor(successor: Chain<P>): Chain<P> | null {
        this.successor = successor;
        return this.successor;
    }

    passRequest(param: P): any {
        const res = this.fn(param);

        if (res === NEXT_FLAG) {
            return this.successor && this.successor.passRequest(param);
        }
        return res;
    }
}

class ChainService<P> {
    chainList: Chain<P>[];
    chain: Chain<P> | null;
    constructor(chainList?: Chain<P>[]) {
        this.chainList = chainList || [];
        this.chain = null;
        if (chainList?.length !== 0) {
            this.chain = chainList?.[0] || null;
        }
        this.initChainList();
    }

    initChainList() {
        let len = this.chainList.length;
        let chain = this.chain;
        for (let i = 1; i < len; i++) {
            chain?.setNextSuccessor(this.chainList[i]);
            chain = this.chainList[i];
        }
    }

    addChainList(chains: Chain<P>[]) {
        if (this.chainList.length === 0) {
            this.chainList = chains;
            this.chain = this.chainList[0];
            this.initChainList();
        } else {
            const len = chains.length;
            let chain = this.chainList[len - 1];
            for (let i = 0; i < len; i++) {
                chain.setNextSuccessor(chains[i]);
                chain = chains[i];
            }
            this.chainList = this.chainList.concat(chains);
        }
    }

    addChain(chain: Chain<P>) {
        if (this.chainList.length === 0) {
            this.chain = chain;
        } else {
            this.chainList[this.chainList.length - 1].setNextSuccessor(chain);
        }
        this.chainList.push(chain);
    }

    execute(param: P) {
        return this.chain?.passRequest(param);
    }
}

export default ChainService;

// 示例
// interface IParam {
//     type: string;
//     data: any;
// }
// function order500(param: IParam) {
//     console.log('ttsss');
//     return 'nextSuccessor';
// }
// function order200(param: IParam) {
//     console.log('sdfsdfsd');
//     return '200';
// }

// const chainOrder500 = new Chain<IParam>(order500);
// const chainOrder200 = new Chain<IParam>(order200);

// const chainService = new ChainService<IParam>([chainOrder500, chainOrder200]);

// const param = {
//     type: '200',
//     data: 200,
// };

// const data = chainService.execute(param);
// console.log('this isis s sd', data);
