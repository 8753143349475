import { useTranslation } from 'react-i18next';
import { useTransferGroupOwner, useSetGroupAdmin } from '@/components/chat/GroupOperation';
import { SessionSettingsType, TransferGroupAdminSource } from '@/types/chat/enum';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import IconFont from '@/components/icon';
import { Drawer, message, Switch } from 'antd';
import {
    getSessionSettingValue,
    isCrossPlatformGroupSession,
    isGroupVisibleUnit,
} from '@/components/chat/utils/message';
import {
    checkApprovalRuleDisabled,
    checkCanSearchDisabled,
    checkCanTransferGroupOwner,
    checkCanSetAdmin,
    checkNeedVerifyDisabled,
} from '@/components/chat/utils/group';
import classnames from 'classnames';
import isNull from 'lodash/isNull';
import { Employee, GroupAttribute, Session, SessionProps } from '@/types/chat';
import { sessionSetting } from '@/server/im/SessionService';
import Context from '@/components/chat/context';
// sxl 连续点击switch 节流
import { debounce, throttle } from 'lodash';
import bus from '@/utils/bus';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import GroupSubAdmin from '../GroupSubAdmin';
interface Props {
    selectedSession: Session;
    sessionMembers: Partial<Employee>[];
    loadSessionMembers: Function;
}

type IProps = Readonly<Props>;

export function GroupSettingItem({
    title,
    desc,
    children,
    onClick,
    clstag,
}: {
    title: string;
    desc?: string;
    children: ReactNode;
    onClick?: () => void;
    clstag?: string;
}) {
    const descPart = useMemo(() => {
        if (desc) {
            return (
                <div className="item-desc">
                    <p>{desc}</p>
                </div>
            );
        }
        return null;
    }, [desc]);

    const classes = classnames({
        'group-management-item': true,
        'has-desc': !isNull(descPart),
    });

    return (
        <div className={classes} onClick={onClick} clstag={clstag}>
            <div className="group-management-item-title">
                <p>{title}</p>
                {descPart}
            </div>
            <div className="group-management-item-children">{children}</div>
        </div>
    );
}

/**
 * 群设置各个配置选项操作开发组件
 * type 开关类型
 * reverse 是否反置开关  reverse:true 0开1关  reverse:false 1开0关
 * @param props
 * @constructor
 */
function SettingSwitchComp(
    props: IProps & {
        type: SessionSettingsType;
        disabled?: boolean;
        reverse?: boolean;
        clsTag?: [string, string];
    }
) {
    const { selectedSession, type, reverse, disabled, clsTag = ['', ''] } = props;
    const { t } = useTranslation('chat');
    const value = getSessionSettingValue(selectedSession, type);
    const [checked, setChecked] = useState(value);
    const [isloading, setLoading] = useState(false);
    useEffect(() => {
        setChecked(value);
    }, [value]); // eslint-disable-line

    // switch滑动添加到置顶
    const onChange = useCallback(
        async (checked: boolean) => {
            try {
                setLoading(true);
                const iChecked = reverse ? !checked : checked;
                const result = await sessionSetting(type, selectedSession.sessionId, iChecked);
                if (isNull(result)) {
                    message.error(t('failed'));
                }
                setLoading(false);
            } catch (e) {
                message.error(t('failed'));
            }
        },
        [selectedSession.sessionId, t, type, reverse] // eslint-disable-line
    );
    return (
        <Switch
            disabled={disabled}
            checked={checked}
            loading={isloading}
            onClick={debounce(onChange, 200)}
            clstag={clsTag[checked ? 1 : 0]}
        />
    );
}
export const SettingSwitch = connect(mapStateToProps, mapDispatchToProps)(SettingSwitchComp);

// 灰线
export function GrayLine({ title }: { title: string }) {
    return <div className="group-management-gray-line">{title}</div>;
}

function GroupManager(
    props: IProps & {
        visible: boolean;
        onClose: () => void;
    }
) {
    const { visible, onClose, selectedSession, loadSessionMembers } = props;

    const { currentEmployee, groupRosters, userData, groupRosterIdentity } = React.useContext(
        Context
    );

    const [visibleSetAdmin, setVisibleSetAdmin] = useState(false);

    const { t } = useTranslation('chat');

    // 转让群主功能
    const transferGroupAdmin = useTransferGroupOwner({
        selectedSession,
        currentEmployee,
        groupRosters,
        userData,
        operateType: TransferGroupAdminSource.GROUP_MANAGER_OPERATE,
        callback: () => {
            let groupInfo = selectedSession.info as GroupAttribute;
            bus.emit('chat:group:transferGroupOwner', groupInfo.gid);
        },
    });

    // 添加移除管理员功能 方案1
    const setGroupAdminFn = useSetGroupAdmin({
        selectedSession,
        currentEmployee,
        groupRosters,
        userData,
        callback: () => {
            let groupInfo = selectedSession.info as GroupAttribute;
            bus.emit('chat:group:addAdmin', groupInfo.gid);
        },
        loadSessionMembers,
    });

    // 添加移除管理员功能 方案2
    // const setGroupAdminFn = () => {
    //     setVisibleSetAdmin(true);
    // };

    const canTransferGroupOwner = useMemo(() => {
        return checkCanTransferGroupOwner({ selectedSession, groupRosterIdentity });
    }, [selectedSession, groupRosterIdentity]);

    const canSetAdmin = useMemo(() => {
        return checkCanSetAdmin({ selectedSession, groupRosterIdentity });
    }, [selectedSession, groupRosterIdentity]);

    const approvalRuleDisabled = useMemo(() => {
        return checkApprovalRuleDisabled({ selectedSession });
    }, [selectedSession]);

    const canSearchDisabled = useMemo(() => {
        return checkCanSearchDisabled({ selectedSession });
    }, [selectedSession]);

    const needVerifyDisabled = useMemo(() => {
        return checkNeedVerifyDisabled({ selectedSession });
    }, [selectedSession]);

    const titleNode = useMemo(() => {
        return (
            <div>
                <IconFont
                    type="iconwin_left"
                    className="group-management-icon-arrow"
                    onClick={onClose}
                />
                <span className="management-title">{t('group_management')}</span>
            </div>
        );
    }, [onClose, t]);

    const adminNum = useMemo(() => {
        return (
            groupRosters?.filter((session: any) => session.identity === 'sub_administrator')
                ?.length || 0
        );
    }, [groupRosters]);
    return (
        <Drawer
            className="group-manager-right-drawer"
            getContainer={document.querySelector('#chat-right-container-change-set') as HTMLElement}
            title={titleNode}
            placement="right"
            width="310px"
            height="100%"
            mask={false}
            destroyOnClose={true}
            closable={false}
            onClose={onClose}
            visible={visible}
            style={{ position: 'absolute', right: 0 }}
        >
            {/* 群信息管理 */}
            {/* 设置 全员可修改群信息 暂时关掉 */}
            {/* {!isSpecialProjectMasterSession(selectedSession) && ( */}
            {/*    <div> */}
            {/*        <GrayLine title={t('group_management_config.group_info_management')} /> */}
            {/*        <GroupSettingItem */}
            {/*            title={t('group_management_config.permission_check.title')} */}
            {/*            desc={t('group_management_config.permission_check.desc')} */}
            {/*        > */}
            {/*            <SettingSwitch */}
            {/*                selectedSession={selectedSession} */}
            {/*                type={SessionSettingsType.PERMISSION_CHECK} */}
            {/*                clsTag={[ */}
            {/*                    'pageclick|keycount|focus_chat_02_1615797535989|15', */}
            {/*                    'pageclick|keycount|focus_chat_02_1615797535989|16', */}
            {/*                ]} */}
            {/*            /> */}
            {/*        </GroupSettingItem> */}
            {/*    </div> */}
            {/* )} */}
            {/* 成员管理 */}
            <GrayLine title={t('group_management_config.group_member_management')} />
            {/* 设置全员禁言 */}
            <GroupSettingItem
                title={t('group_management_config.member_no_talking.title')}
                desc={t('group_management_config.member_no_talking.desc')}
            >
                <SettingSwitch
                    selectedSession={selectedSession}
                    type={SessionSettingsType.BANNED_POST}
                    clsTag={[
                        'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetBandToPost_True',
                        'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetBandToPost_False',
                    ]}
                />
            </GroupSettingItem>
            {/* 允许查看群成员信息 跨平台群无此配置 */}
            {!isCrossPlatformGroupSession(selectedSession) && (
                <GroupSettingItem
                    title={t('group_management_config.member_card_view.title')}
                    desc={t('group_management_config.member_card_view.desc')}
                >
                    <SettingSwitch
                        selectedSession={selectedSession}
                        type={SessionSettingsType.CARD_VIEW}
                        clsTag={[
                            'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetCheck_True',
                            'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetCheck_False',
                        ]}
                    />
                </GroupSettingItem>
            )}
            {/* 按单位选择成员 */}
            <GroupSettingItem
                title={t('group_management_config.visible_unit.title')}
                desc={
                    isGroupVisibleUnit(selectedSession)
                        ? t('group_management_config.visible_unit.open-desc')
                        : t('group_management_config.visible_unit.close-desc')
                }
            >
                <SettingSwitch
                    selectedSession={selectedSession}
                    type={SessionSettingsType.VISIBLE_UNIT}
                    clsTag={[
                        'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetSelect_False',
                        'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetSelect_True',
                    ]}
                />
            </GroupSettingItem>
            <GroupSettingItem
                title={t('group_management_config.chat_visible_unit.title')}
                desc={t('group_management_config.chat_visible_unit.desc')}
            >
                <SettingSwitch
                    selectedSession={selectedSession}
                    type={SessionSettingsType.CHAT_VISIBLE_UNIT}
                />
            </GroupSettingItem>
            {/* 添加移除组管理员 只有群主才可以操作 */}
            {canSetAdmin && (
                <GroupSettingItem
                    title={t('group-admin')}
                    onClick={setGroupAdminFn}
                    clstag="pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetSubAdmin"
                >
                    <span style={{ marginRight: 4 }}>{adminNum}</span>
                    <IconFont
                        type="iconjs_ilist_arrowright"
                        className="group-management-icon-arrow"
                        clstag="pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|SetSubAdmin"
                    />
                </GroupSettingItem>
            )}
            {visibleSetAdmin && (
                <GroupSubAdmin
                    visible={visibleSetAdmin}
                    onCancel={() => setVisibleSetAdmin(false)}
                />
            )}
            {/* 转让群组 只有群主才可以操作 */}
            {canTransferGroupOwner && (
                <GroupSettingItem
                    title={t('transfer-group-owner')}
                    onClick={transferGroupAdmin}
                    clstag="pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|TransferCreator"
                >
                    <IconFont
                        type="iconjs_ilist_arrowright"
                        className="group-management-icon-arrow"
                        clstag="pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|TransferCreator"
                    />
                </GroupSettingItem>
            )}
            {/* 加群设置 */}
            <GrayLine title={t('group_management_config.group_join_management')} />
            {/* 全员可邀请 */}
            <GroupSettingItem
                title={t('group_management_config.approval_rule.title')}
                desc={t('group_management_config.approval_rule.desc')}
            >
                <SettingSwitch
                    selectedSession={selectedSession}
                    type={SessionSettingsType.APPROVAL_RULE}
                    disabled={approvalRuleDisabled}
                    clsTag={[
                        'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|InviteAvailability_True',
                        'pageclick|keycount|Xtbg_Msg_Setting_GroupManageMent|InviteAvailability_False',
                    ]}
                />
            </GroupSettingItem>
            {/* 群可被搜索 跨平台群无此配置 */}
            {/* {!isCrossPlatformGroupSession(selectedSession) && (
                <GroupSettingItem
                    title={t('group_management_config.can_search.title')}
                    desc={t('group_management_config.can_search.desc')}
                >
                    <SettingSwitch
                        selectedSession={selectedSession}
                        type={SessionSettingsType.CAN_SEARCH}
                        disabled={canSearchDisabled}
                        clsTag={[
                            'pageclick|keycount|focus_chat_01_1615797500283|84',
                            'pageclick|keycount|focus_chat_01_1615797500283|85',
                        ]}
                    />
                </GroupSettingItem>
            )} */}
            {/* 加群需要群主验证 跨平台群无此配置 */}
            {/* {!isCrossPlatformGroupSession(selectedSession) && ( */}
            {/*    <GroupSettingItem */}
            {/*        title={t('group_management_config.need_verify.title')} */}
            {/*        desc={t('group_management_config.need_verify.desc')} */}
            {/*    > */}
            {/*        <SettingSwitch */}
            {/*            selectedSession={selectedSession} */}
            {/*            type={SessionSettingsType.NEED_VERIFY} */}
            {/*            disabled={needVerifyDisabled} */}
            {/*            clsTag={[ */}
            {/*                'pageclick|keycount|focus_chat_01_1615797500283|86', */}
            {/*                'pageclick|keycount|focus_chat_01_1615797500283|87', */}
            {/*            ]} */}
            {/*        /> */}
            {/*    </GroupSettingItem> */}
            {/* )} */}
        </Drawer>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    return {
        groupRosters: chat.groupRosters,
        selectedSession: chat.selectedSession,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async loadSessionMembers(data: { selectedSession: SessionProps }) {
            await dispatch({ type: 'chat/loadSessionMembers', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupManager);
