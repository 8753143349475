import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Button, List, message, Modal, Input, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'dvajs';
import { ListItem, Loading } from '@jd/focus-desktop-comps';
import {
    getLabelsInfoList,
    labelEmplPojoList,
    LabelForContactLabelsInfo,
    LabelInfoRes,
} from '../../../AddressBook/common';
import { debounce, forEach, result, trim as lodashTrim } from 'lodash';
import IconFont, { IconSearch } from '../../../icon';
import './index.less';
import bus from '@/utils/bus';
import { setLabelForContact } from '@/api/ebook';
import { UserCardData } from '@/types/UserCard';
import { Employee } from '@/types/chat';
import { CompDataLoadState, DefaultPageType } from '@/models';
import DefaultPage from '@/components/DefaultPage';

function EditUserLabelModel({
    labelsList,
    currentEmployee,
    setUserLabel,
    setLabelsList,
}: {
    labelsList: LabelInfoRes[];
    currentEmployee: Employee;
    setUserLabel: Function;
    setLabelsList: Function;
}) {
    const { t } = useTranslation('addressList');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [drawVisible, setDrawVisible] = useState(false);
    const [labels, setLabels] = useState<LabelForContactLabelsInfo[]>([]);
    const [currentUserDetail, setCurrentUserDetail] = useState<UserCardData>();
    const [allList, setAllList] = useState<LabelInfoRes[]>();
    const [loading, setLoading] = useState(true);

    const scrollRef: any = useRef(null);

    useEffect(() => {
        if (!drawVisible || !labelsList) {
            return;
        }

        const data = labelsList.map((item) => {
            let idx = labels?.findIndex((ele) => ele.labelName === item.labelName);
            return {
                ...item,
                checked: idx > -1 ? true : false,
            };
        });
        setAllList(data);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labelsList, drawVisible]);

    const getLabelList = useCallback(() => {
        setLoading(true);
        getLabelsInfoList()
            .then((res: any) => {
                setLabelsList(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLabelsList]);

    function cancelHandler() {
        setDrawVisible(false);
    }

    const editLabel = useCallback(async () => {
        setButtonLoading(true);
        const [data, err]: any = await setLabelForContact({
            userId: currentUserDetail?.userId || '',
            labels: labels,
        });
        if (err) {
            message.error(err as string);
            setButtonLoading(false);
            return;
        }

        setButtonLoading(false);
        message.success(t('label.edit label success'));
        setUserLabel(labels);
        bus.emit(`close:edit-user-label-model`);
    }, [currentUserDetail, labels, setUserLabel, t]);

    // 初始化数据
    useEffect(() => {
        async function handleOpenCreateDrawVisible(data: any) {
            if (data) {
                setCurrentUserDetail(data);
                setLabels(data.currentUserLabelInfoList);
            }
            console.log(labelsList, 'labelsList===');
            if (!labelsList) {
                getLabelList();
            }
            setDrawVisible(true);
        }
        bus.on(`close:edit-user-label-model`, cancelHandler);
        bus.on('open:edit-user-label-model', handleOpenCreateDrawVisible);
        return () => {
            bus.off(`close:edit-user-label-model`, cancelHandler);
            bus.off('open:edit-user-label-model', handleOpenCreateDrawVisible);
        };
    }, [getLabelList, labelsList]);

    const changeListChecked = useCallback(
        (item: any) => {
            const result =
                allList?.map((ele) => {
                    return {
                        ...ele,
                        checked: item.labelName === ele.labelName ? !ele.checked : ele.checked,
                    };
                }) || [];
            setAllList(result);
        },
        [allList]
    );

    const deleteLabelName = useCallback(
        (item: LabelInfoRes | LabelForContactLabelsInfo) => {
            let labelsFilter = labels.filter((ele) => item.labelName !== ele.labelName);

            if (labelsFilter.length === 0) {
                setLabels([]);
            }
            setLabels(labels.filter((ele) => item.labelName !== ele.labelName));

            changeListChecked(item);
        },
        [changeListChecked, labels]
    );

    const isRepeatLabelName = useCallback(
        (item: LabelInfoRes | LabelForContactLabelsInfo) => {
            let idx = labels.findIndex((ele) => item.labelName === ele.labelName);
            if (idx < 0) {
                return false;
            }
            message.success(t('label.repeat_labelName'));
            return true;
        },
        [labels, t]
    );

    const addLabelName = useCallback(
        (item: LabelInfoRes | LabelForContactLabelsInfo, virtualCreation?: boolean) => {
            if (labels.length === 10) {
                message.warn(t('label.label_overrun'));
                return;
            }
            let itemData: any = {};
            if (virtualCreation) {
                itemData = {
                    labelName: item.labelName,
                };
            } else {
                itemData = {
                    labelName: item.labelName,
                    labelId: item.labelId,
                };
            }
            if (isRepeatLabelName(item)) {
                return;
            }
            setLabels([...labels, itemData]);
            changeListChecked(item);
        },
        [changeListChecked, isRepeatLabelName, labels, t]
    );

    const itemClick = useCallback(
        (item: LabelInfoRes) => {
            if (item.checked) {
                deleteLabelName(item);
            } else {
                addLabelName(item);
            }
        },
        [addLabelName, deleteLabelName]
    );

    const DrawerFooter = (
        <div
            style={{
                marginLeft: '14px',
            }}
        >
            <Button onClick={cancelHandler}>取 消</Button>
            <Button
                loading={buttonLoading}
                type="primary"
                style={{ margin: '0 8px' }}
                onClick={editLabel}
            >
                {t('label.confirm')}
            </Button>
        </div>
    );
    const labelNode = useMemo(() => {
        return (
            <div className="edit-user-label-name" id="edit-user-label-name" ref={scrollRef}>
                <h5>
                    {t('label.label_name')}
                    <div
                        style={{ cursor: 'pointer' }}
                        className="user-label-name-container"
                        onClick={debounce(() => {
                            addLabelName({ labelName: '' }, true);
                            document.getElementById('user-label-name-container-input')?.focus();
                        }, 200)}
                    >
                        <span className="icon-add">
                            <IconFont type="iconlog_add" />
                        </span>
                        <div style={{ display: 'inline-block' }}>{t('label.new_label')}</div>
                    </div>
                </h5>
                <div className="label-names">
                    {labels?.map((item, key) => {
                        if (!item.labelName) {
                            return (
                                <div key={key} className="user-label-name-container">
                                    <Input
                                        id="user-label-name-container-input"
                                        className="user-label-name-container-input"
                                        placeholder={t('label.new_label')}
                                        onBlur={(e) => {
                                            if (e.target.value) {
                                                if (
                                                    isRepeatLabelName({ labelName: e.target.value })
                                                ) {
                                                    return;
                                                }
                                                const data = labels.map((item) => {
                                                    if (!item.labelName) {
                                                        item.labelName = e.target.value;
                                                    }
                                                    return item;
                                                });
                                                setLabels(data);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        }
                        return (
                            <div key={key} className="user-label-name-container">
                                <span>{item.labelName}</span>
                                <span className="icon-close">
                                    <IconFont
                                        type="iconapp_btn_file_cancel"
                                        onClick={debounce(() => {
                                            return deleteLabelName(item);
                                        }, 200)}
                                    />
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [addLabelName, deleteLabelName, isRepeatLabelName, labels, t]);

    const allListNode = useMemo(() => {
        if (allList && !allList.length) {
            return (
                <DefaultPage
                    pageType={DefaultPageType.MY_LABELS}
                    dataType={CompDataLoadState.NODATA}
                />
            );
        }
        const offHeight = scrollRef?.current ? scrollRef?.current?.offsetHeight + 10 : 84;
        return (
            <div
                className="user-label-body"
                style={{
                    height: `calc(100% - ${offHeight}px)`,
                    paddingBottom: '10px',
                    overflowY: 'auto',
                }}
            >
                <h5>{t('label.all_labels')}:</h5>
                <div className="user-label-list">
                    {allList?.map((item: any, key: number) => {
                        return (
                            <div
                                key={key}
                                className="user-label-list-item"
                                onClick={() => {
                                    itemClick(item);
                                }}
                            >
                                <Checkbox checked={item?.checked} />
                                <div className="item-container">
                                    <h5>
                                        {item.labelName} ({item.emplCount})
                                    </h5>
                                    {item.labelEmplPojoList.length > 0 && (
                                        <div className="item-container-users">
                                            {item.labelEmplPojoList.map(
                                                (ele: Employee, eleKey: number) => {
                                                    return (
                                                        <span key={eleKey}>
                                                            {ele.name}
                                                            {eleKey !==
                                                                item.labelEmplPojoList.length - 1 &&
                                                                ele.name &&
                                                                '、'}
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [allList, t, itemClick]);

    if (!drawVisible) {
        return null;
    }

    return (
        <Modal
            title={t('label.setting')}
            width="480px"
            bodyStyle={{ height: 600, padding: '24px 40px 10px' }}
            visible={drawVisible}
            footer={DrawerFooter}
            onCancel={cancelHandler}
            centered={true}
        >
            <div className="edit-user-label-wrap">
                {labels && labelNode}
                {loading ? (
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Loading />
                    </div>
                ) : (
                    allListNode
                )}
            </div>
        </Modal>
    );
}

function mapStateToProps({ ebook, chat }: any) {
    return {
        labelsList: ebook.label.labelsList,
        currentEmployee: chat.currentEmployee,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        openCreateEditUserLabelModel(data: any) {
            dispatch({ type: 'ebook/openCreateEditUserLabelModel', payload: data });
        },
        setCreateModalInputValue(data: any) {
            dispatch({ type: 'ebook/setCreateModalInputValue', payload: data });
        },
        setUserLabel(data: any) {
            dispatch({ type: 'ebook/setUserLabel', payload: data });
        },
        setLabelsList(data: any) {
            dispatch({ type: 'ebook/setLabelsList', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserLabelModel);
