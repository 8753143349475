import React, { useEffect, useRef, useState } from 'react';
import IconFont from '@/components/icon';
import { message, Upload } from 'antd';
import { getThumbUrl } from '@/utils/chat/index';
import { GlobalContextConfig } from '@/context/GlobalContext';
import { createUploadTask } from '@/server/FileService';
import { changeEgovUrl } from '@/utils/tools';

interface Iprops {
    globalcontext: GlobalContextConfig;
    imgs: string[];
    uploadCallback: (value: string[]) => void;
}

export default function (props: Iprops) {
    const { uploadCallback, imgs } = props;
    const imgWrapRef = useRef<any>(null);
    const [imgUrls, setImgUrls] = useState<string[]>(imgs || []);
    const [isShowNextIcon, setIsShowNextIcon] = useState(false);
    const [isShowPreIcon, setIsShowPreIcon] = useState(false);

    function beforeUploadImg(file: any, fileList: File[]) {
        // const fs = new FileService({
        //     appKey: appKey || '', // 文件服务appkey
        //     host: hostOrigin,
        //     janus: {
        //         host: `${protocol}://${host}`,
        //         appId: getAppId() || '', // janus网关appid
        //         // @ts-ignore
        //         stage: stage,
        //     },
        //     auth: {
        //         // 认证信息
        //         xToken: authInfo.accessToken, // 用户登录token
        //         xTeamId: authInfo.teamUserInfo.teamId, // 用户所在teamId
        //         xClient: registryConfig.client, // 客户端类型
        //     },
        // });
        if (file.size > 10485760) {
            message.error('请上传10M以内的图片');
            return false;
        }
        const reader = new FileReader();
        const blob: any = file.getAsFile ? file.getAsFile() : file;
        reader.onload = (evt) => {
            if (!evt.target) return;
            const dataUrl: any = evt.target.result;
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                const task = createUploadTask('id', file, {
                    needAuthn: 1, // 是否需要下载健全，可选参数默认需要
                    fileName: file.name, // 文件名称
                    fileType: file.type || 'application/octet-stream', // 文件类型
                    fileSize: file.size, // 文件大小
                });
                task?.on('state', async (state1: any) => {
                    if (state1.state === 'completed') {
                        if (!state1.downloadUrl) return;
                        setImgUrls((oldvalue) => {
                            const newValue = [
                                ...oldvalue,
                                `${state1.downloadUrl}&width=${img.width}&height=${img.height}`,
                            ];
                            uploadCallback(newValue);
                            return newValue;
                        });
                    } else if (state1.state === 'failed') {
                        message.error('图片上传失败，请重试！');
                    }
                });
                task?.start();
            };
        };
        if (blob instanceof Blob) {
            reader.readAsDataURL(blob);
        }

        return false;
    }

    useEffect(() => {
        setImgUrls(imgs);
    }, [imgs]);

    useEffect(() => {
        const leftValue = imgWrapRef.current!.style.left;
        if (imgUrls.length > 4 && leftValue === '-504px') {
            setIsShowNextIcon(false);
            setIsShowPreIcon(true);
        } else if (imgUrls.length > 4) {
            setIsShowNextIcon(true);
            setIsShowPreIcon(false);
        } else {
            setIsShowNextIcon(false);
            setIsShowPreIcon(false);
        }
        if (imgUrls.length === 5) {
            imgWrapRef.current!.style.left = '0px';
        }
    }, [imgUrls]);

    return (
        <div className="pm-upload-img-wrap">
            {isShowPreIcon && (
                <div
                    className="pm-pre-img"
                    onClick={() => {
                        imgWrapRef.current!.style.left = '0px';
                        setIsShowNextIcon(true);
                        setIsShowPreIcon(false);
                    }}
                >
                    <IconFont type="iconwin_left" />
                </div>
            )}
            <div className="pm-announce-box" ref={imgWrapRef}>
                <div
                    className="pm-announce-upload"
                    style={{ borderColor: imgUrls.length >= 10 ? '#BFC1C4' : '@primary-color' }}
                >
                    <Upload
                        name="avatar"
                        accept="image/*"
                        listType="picture-card"
                        className="pm-avatar-uploader"
                        showUploadList={false}
                        multiple={true}
                        disabled={imgUrls.length >= 10}
                        beforeUpload={beforeUploadImg}
                    >
                        {/* <IconFont type="iconapp_btn_add2" className="pm-iconapp-btn-add2" /> */}
                        <span
                            style={{ color: imgUrls.length >= 10 ? '#BFC1C4' : '@primary-color' }}
                        >
                            添加附件
                        </span>
                    </Upload>
                </div>
            </div>
            <div className="pm-createAnnounce-upload">
                {imgUrls.map((imgItem, index) => {
                    if (index >= 10) {
                        imgUrls.splice(index);
                        return null;
                    }
                    return (
                        <div
                            key={imgItem ? imgItem : index}
                            style={{
                                backgroundImage: `url(
                                    ${changeEgovUrl(getThumbUrl(imgItem, 74, 74))}
                            )`,
                            }}
                            className="pm-createAnnounce-upload-isFiveLi"
                        >
                            {!imgItem && <span />}
                            <div
                                className="delete-img"
                                onClick={() => {
                                    const newImgs = imgUrls.filter((item, i) => i !== index);
                                    uploadCallback(newImgs);
                                    setImgUrls(newImgs);
                                }}
                            >
                                <div>
                                    <IconFont
                                        type="iconapp_ic_delete"
                                        className="delete-img-icon"
                                    />
                                    <span>删除</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* <ul ref={imgWrapRef}>
                <li
                    className="pm-announce-upload"
                    style={{ borderColor: imgUrls.length >= 10 ? '#BFC1C4' : '@primary-color' }}
                >
                    <Upload
                        name="avatar"
                        accept="image/*"
                        listType="picture-card"
                        className="pm-avatar-uploader"
                        showUploadList={false}
                        multiple={true}
                        disabled={imgUrls.length >= 10}
                        beforeUpload={beforeUploadImg}
                    > */}
            {/* <IconFont type="iconapp_btn_add2" className="pm-iconapp-btn-add2" /> */}
            {/* <span style={{ color: imgUrls.length >= 10 ? '#BFC1C4' : '@primary-color' }}>
                            添加附件
                        </span>
                    </Upload>
                </li> */}
            {/* {imgUrls.map((imgItem, index) => {
                    return (
                        <li
                            key={imgItem ? imgItem : index}
                            style={{
                                backgroundImage: `url(
                                    ${changeEgovUrl(getThumbUrl(imgItem, 74, 74))}
                            )`,
                            }}
                            className={
                                index === 3 && imgUrls.length > 4
                                    ? 'pm-createAnnounce-upload-isFiveLi'
                                    : ''
                            }
                        >
                            {!imgItem && <span />}
                            <div
                                onClick={() => {
                                    const newImgs = imgUrls.filter((item, i) => i !== index);
                                    uploadCallback(newImgs);
                                    setImgUrls(newImgs);
                                }}
                            >
                                <IconFont type="iconapp_ic_delete" />
                                <p> 删除</p>
                            </div>
                        </li>
                    );
                })}
            </ul> */}

            {/* {isShowNextIcon && (
                <div
                    className="pm-next-img"
                    onClick={() => {
                        imgWrapRef.current!.style.left = '-504px';
                        setIsShowNextIcon(false);
                        setIsShowPreIcon(true);
                    }}
                >
                    <IconFont type="iconwin_right" />
                </div>
            )} */}
        </div>
    );
}
