/* eslint-disable no-console */
/* eslint max-params: off */
/**
 * 聊天会话通用组件
 * @author tj
 * @date 2020/6/16
 */

import React, { useReducer, useState, useMemo, useEffect, useCallback } from 'react';
import { connect } from 'dva';
import ChatState, { UserState } from '@/types/chat/State';
import { ExclamationOutlined } from '@ant-design/icons';
import { Empty, message } from 'antd';
import { lookup } from 'mime-types';
import log from '@/utils/logger';

import Loading from '@/components/Loading';
import MessageInput, { InitImgState } from '@/baseComponents/Chat/message/Input';
import ChatEvent from '@/baseComponents/Chat/view/ChatEvent';
import MessageBoxContainer from '@/baseComponents/Chat/message/Container';
import SessionHeader from '@/baseComponents/Chat/message/Head';
import { useTranslation } from 'react-i18next';
import { ChatMessage, Employee, Session, SessionType } from '@/types/chat';
import { createUploadTask } from '@/server/FileService';
import {
    buildFileMessageBody,
    buildImageMessageBody,
    initChatMessageInfo,
    isBannedPostGroupSession,
    buildJoyspaceLinkMessageBody,
    JouspaceLinkTypeOption,
} from '@/utils/chat/message';
import config, { ChatConfig } from '@/config/config';
import Forwarding from '@/baseComponents/Chat/message/Forwarding';
import ForwardSelect from '@/baseComponents/Chat/message/ForwardSelect';
import { DrawTypeEnum } from '@/types/chat/enum';
import throttle from 'lodash/throttle';
import { nanoid } from 'nanoid';
import getCurVoiceHandler from '@/baseComponents/Chat/handler/voiceHandler';
import { getGroupRoster, getGroupRosterIdentity } from '@/utils/chat/group';
import Context from '@/context/ChatContext';

import default_chat_png from '@/assets/img/message-empty.png';
import './index.less';
import { isGroupOrdinary } from '@/components/chat/utils/group';
import { getMessageTypeByText } from '@/components/chat/utils/message';
import {
    dealImagesData,
    filterDirectory,
    filterImages,
    formatFileType,
} from '@/components/chat/utils/image';
import chatConfig from '@/components/chat/config';
import { UpLoadFile } from '@/components/chat/view/Main/UpLoadFile';

const style = { margin: 0 };

interface ChatMainProps {
    selectedSession: Session;
    currentEmployee: Employee;
    type?: string;
    onToggleSetup?: (type: DrawTypeEnum) => void;
    allSessionListLength: number;
    selectMore: boolean;
    loadAllSessionListEnd: boolean;
    messageInputFocus: boolean;
    hideSetting?: boolean;
    singleLine?: boolean;
    customHeader?: Function; // 自定义导航
}

interface DvaDispatchProps {
    pushChatMessage: Function;
}

type IChatMainProps = Readonly<ChatMainProps & DvaDispatchProps & UserState & ChatState>;

export interface FileItem {
    title: string;
    size: number;
    fileType: string;
}
interface IState {
    fileState: FileItem;
    fileUploadModalVisible: boolean;
    file: any;
    fileList: any[];
    isPaste: boolean;
    filePath: any;
}

function reducer(state: IState, action: any) {
    const { payload } = action;
    switch (action.type) {
        case 'openUploadFileModal':
            // eslint-disable-next-line no-case-declarations
            const { fileState, file, fileList, isPaste = false, filePath = null } = payload;
            return {
                ...state,
                fileUploadModalVisible: true,
                fileState,
                file,
                fileList,
                isPaste,
                filePath,
            };
        case 'closeUploadFileModal':
            return { ...state, fileUploadModalVisible: false };
        case 'clearFileState':
            return {
                ...state,
                fileState: {},
                file: null,
                fileList: [],
                isPaste: false,
                filePath: null,
            };
        default:
            throw new Error();
    }
}

const initialState: IState = {
    fileState: {
        title: '',
        size: 0,
        fileType: '',
    },
    fileUploadModalVisible: false,
    file: null,
    fileList: [],
    isPaste: false,
    filePath: null,
};

function ChatMain(props: IChatMainProps) {
    const {
        administrator,
        sub_administrator,
        ordinary,
        allSessionListLength,
        onToggleSetup,
        selectedSession,
        currentEmployee,
        selectMore,
        type,
        loadAllSessionListEnd,
        messageInputFocus,
        userData,
        customHeader,
        hideSetting,
        singleLine,
    } = props;
    const [t] = useTranslation('chat');
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isMulti, setIsMulti] = useState(false);
    const [replyMessage, setReplyMessage] = useState();
    const sessionId = selectedSession.sessionId;
    // context start
    const groupRosters = useMemo(() => {
        if (!selectedSession.isGroup || administrator.length === 0) {
            return [];
        }
        return getGroupRoster(administrator, sub_administrator, ordinary);
    }, [selectedSession, administrator, sub_administrator, ordinary]);

    const groupRosterIdentity = useMemo(() => {
        if (!selectedSession.isGroup || !currentEmployee) {
            return '';
        }
        if ((administrator || []).length === 0) {
            return '';
        }
        return getGroupRosterIdentity(administrator, sub_administrator, currentEmployee);
    }, [selectedSession, administrator, sub_administrator, currentEmployee]);
    // context end

    // 关闭语音
    useEffect(() => {
        return () => {
            // console.log('sessionId', sessionId);
            const voiceHandler = getCurVoiceHandler();
            voiceHandler?.destroy();
        };
    }, [sessionId]);

    function setCallBack(type: DrawTypeEnum) {
        if (onToggleSetup) {
            onToggleSetup(type);
        }
    }

    function handleDragEnter(e: any) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('drag enter', e.dataTransfer);
    }

    function handleDragOver(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (state.fileUploadModalVisible) {
            return;
        }
        if (!e.dataTransfer.types || !e.dataTransfer.types.includes('Files')) {
            return;
        }
        // 以下过滤条件为文件往外拖
        if (e.dataTransfer.effectAllowed === 'copyLink') {
            return;
        }
        setFileDrag(true);
    }

    function handleDragLeave(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setFileDrag(false);
    }

    async function handleDrop(e: any) {
        e.preventDefault();
        e.stopPropagation();
        setFileDrag(false);
        // 禁言的会话
        if (isBannedPostGroupSession(selectedSession) && isGroupOrdinary(groupRosterIdentity)) {
            return false;
        }
        if (state.fileUploadModalVisible) {
            return;
        }
        const df = e.dataTransfer;
        if (!df.types || !df.types.includes('Files')) {
            return;
        }
        // 以下过滤条件为文件往外拖
        if (df.effectAllowed === 'copyLink') {
            return;
        }
        const hasItems = df.items !== undefined;
        const files = hasItems ? df.items : df.files;
        if (!files || files.length === 0) {
            return;
        }

        // console.log('拖拽上传', files);
        if (files.length > 1) {
            let typeList: any[] = [];
            files.forEach(async (file: any) => {
                const promise = filterDirectory(file, hasItems);
                typeList.push(promise);
                console.log('拖拽上传', typeList);
            });

            Promise.all(typeList).then((result) => {
                const isAllFile = result.every((item) => item); // 是否全部选择的文件
                if (isAllFile) {
                    let effectAllFiles: any[] = [];
                    if (hasItems) {
                        files.forEach((item: any) => {
                            effectAllFiles.push(item.getAsFile());
                        });
                    } else {
                        effectAllFiles = files;
                    }
                    handleUploadFile(effectAllFiles, 'multi');
                } else {
                    const isAllFolder = result.every((item) => !item);
                    if (isAllFolder) {
                        message.warn('暂不支持发送文件夹，您可以将文件夹压缩后发送');
                        return;
                    } else {
                        // find除文件夹之外的文件
                        let effectFiles: any[] = [];
                        result.forEach((item, index) => {
                            if (item) {
                                effectFiles.push(
                                    hasItems ? files[index].getAsFile() : files[index]
                                );
                            }
                        });
                        handleUploadFile(effectFiles, 'multi');
                    }
                }
            });
        } else {
            const file = files[0];
            const isFile = await filterDirectory(file, hasItems);
            // console.log('单选', file.getAsFile());
            if (isFile) {
                handleUploadFile(hasItems ? [file.getAsFile()] : files);
            } else {
                message.warn('暂不支持发送文件夹，您可以将文件夹压缩后发送');
                return;
            }
        }
    }

    function closeUploadFileModal() {
        dispatch({ type: 'closeUploadFileModal' });
        setTimeout(() => {
            dispatch({ type: 'clearFileState' });
        }, 0);
    }
    function handlePasteFile(file: any, filePath: any) {
        dispatch({ type: 'clearFileState' });
        const fileState = {
            title: file.name,
            size: file.size,
            fileType: file.type || lookup(file.name),
        };
        dispatch({
            type: 'openUploadFileModal',
            payload: { fileState, file, fileList: [], isPaste: true, filePath },
        });
    }
    /**
     * 上传文件的起始入口，拖拽上传 input内部点击上传等， 会唤起确认框
     * @param file
     */
    function handleUploadSingleFile(file: any) {
        dispatch({ type: 'clearFileState' });
        const fileState = {
            title: file.name,
            size: file.size,
            fileType: file.type || lookup(file.name) || '',
        };
        dispatch({
            type: 'openUploadFileModal',
            payload: { fileState, file, fileList: [] },
        });
    }
    function handleUploadMultiFile(files: any) {
        dispatch({ type: 'clearFileState' });
        const realFiles = Array.prototype.slice.call(files);
        // const newFiles = realFiles.map((file) => {
        //     return {
        //         file: file,
        //         title: file.name,
        //         size: file.size,
        //         fileType: file.type || lookup(file.name) || '',
        //         fileId: nanoid(20),
        //     };
        // });
        dispatch({
            type: 'openUploadFileModal',
            payload: { fileState: state.fileState, file: state.file, fileList: realFiles },
        });
    }

    async function handleUploadFile(files: any, multi?: string, filePath?: string) {
        if (!files || files.length === 0) {
            return;
        }
        if (filePath) {
            setIsMulti(false);
            const realList = await formatFileType(Array.from(files));
            handlePasteFile(realList[0], filePath);
            return;
        }
        if (files.length > 1 || multi) {
            setIsMulti(true);
            handleUploadMultiFile(files);
        } else {
            setIsMulti(false);
            const realList = await formatFileType(Array.from(files));
            handleUploadSingleFile(realList[0]);
        }
    }

    /**
     * 接收input内部的上传图片事件，不会唤起确认框
     * @param file
     * @param width
     * @param height
     */
    function handleUploadImg(imgState: typeof InitImgState, file: File) {
        const message: Partial<ChatMessage> = buildImageMessageBody(
            imgState.base64,
            file.size,
            imgState.width,
            imgState.height
        );
        // 创建一个文件上传服务
        const task = createUploadTask(sessionId, file);
        if (task) {
            // 发送临时消息到 dva 临时上传 message 中需要绑定 task 的 id\
            const chatMessage = initChatMessageInfo({
                selectedSession: selectedSession,
                currentEmployee: currentEmployee,
                message: message,
                task: task,
            });
            // const chatMessage = getChatMessageInfo(message, task);
            props.pushChatMessage({ sessionId, message: chatMessage });
        } else {
            log.warn('创建上传任务失败=======>');
        }
        // setTimeout(() => {
        //     dispatch({ type: 'clearFileState' });
        // }, 2000);
    }

    function handleMultiUploadImg(uploadList: any) {
        uploadList?.forEach((item: any) => {
            if (item.file.size >= chatConfig['max-image-size']) {
                handleSendFie(item.file);
            } else {
                handleUploadImg(item.imgState, item.file);
            }
        });
    }
    const handleReply = useCallback((props: any) => {
        const { replyText, replyUser, replyMessage } = props;
        setReplyMessage(replyMessage);
        // dispatch({ type: 'showReply', payload: { replyText, replyUser, replyMessage } });
    }, []);
    useEffect(() => {
        if (ChatEvent) {
            // 处理reply
            ChatEvent.on('chat-reply', handleReply);
        }
        return () => {
            if (ChatEvent) {
                ChatEvent.removeListener('chat-reply', handleReply);
            }
        };
    }, [handleReply]);
    // ChatEvent.on('chat-reply', handleReply);
    function handleShareJoySpace(data: any[]) {
        // console.log(data, 'datadatadatadata');
        if (!data || data.length === 0) {
            return 0;
        }
        data.forEach(async (item) => {
            const messageTypeOption = await getMessageTypeByText(item.url);
            const chatMessage = await buildJoyspaceLinkMessageBody(
                item.url,
                messageTypeOption as JouspaceLinkTypeOption,
                [],
                selectedSession,
                undefined,
                item.permissionType
            );
            const message: Partial<ChatMessage> = initChatMessageInfo({
                selectedSession: selectedSession,
                currentEmployee: currentEmployee,
                message: chatMessage,
            });
            props.pushChatMessage({ sessionId: sessionId, message: message });
        });
    }

    // TODO: 这里需要优化一下写法 发送单个文件和多个文件
    function handleSendFie(file: any, filePath?: string) {
        const { name, size, type: fileType, path } = file;
        const message: Partial<ChatMessage> = buildFileMessageBody(
            '',
            name,
            size,
            fileType,
            path || filePath
        );
        const task = createUploadTask(sessionId, file);
        if (task) {
            const chatMessage = initChatMessageInfo({
                selectedSession: selectedSession,
                currentEmployee: currentEmployee,
                message: message,
                task: task,
            });
            // console.log(chatMessage, task, 'handleSendFie', file);
            // 发送临时消息到 dva 临时上传 message 中需要绑定 task 的 id
            props.pushChatMessage({ sessionId, message: chatMessage });
        } else {
            log.warn('创建上传任务失败=======>', JSON.stringify(file));
        }
    }

    function filesClassify(selectedFiles: any): Promise<any> {
        return new Promise((resolve) => {
            let images: any = [];
            let files: any = [];
            selectedFiles.forEach(async (item: any) => {
                const isImage = await filterImages(item.file);
                if (isImage) {
                    // console.log('image');
                    images.push(item);
                } else {
                    // console.log('file');
                    files.push(item);
                }
            });
            resolve({ images, files });
        });
    }
    async function beforeSendMultiFile(selectedFiles: any) {
        // console.log('selectedFiles', selectedFiles);
        const { images, files } = await filesClassify(selectedFiles);
        // console.log(images, files);
        if (images.length) {
            // console.log('要发送的图片数组', files);
            let tempList: any[] = [];
            images.forEach((imageItem: any) => {
                const promise = dealImagesData(imageItem.file);
                tempList.push(promise);
            });
            Promise.all(tempList).then((result) => {
                // console.log('result', result);
                result.length &&
                    result.forEach(async (data) => {
                        handleUploadImg(data.imgState, data.file);
                    });
            });
        }
        if (files.length) {
            // console.log('要发送的文件数组', files);
            files.forEach((item: any) => {
                handleSendFie(item.file);
            });
        }
    }

    async function sendFile(selectedFiles?: any) {
        dispatch({ type: 'closeUploadFileModal' });
        if (isMulti) {
            beforeSendMultiFile(selectedFiles || []);
        } else {
            // console.log('state.file', state.file);
            const tempFile = state.file;
            const isImage = await filterImages(tempFile);
            if (isImage) {
                const data = await dealImagesData(tempFile);
                // console.log('data', data);
                handleUploadImg(data.imgState, data.file);
                return;
            }
            await handleSendFie(tempFile);
        }
        // setTimeout(() => {
        //     dispatch({ type: 'clearFileState' });
        // }, 2000);
    }
    async function doSendFile(selectedFiles?: any) {
        if (state.filePath) {
            sendFileByPaste(selectedFiles, state.filePath);
        } else {
            console.log('selectedFiles', selectedFiles);
            sendFile(selectedFiles);
        }
    }
    async function sendFileByPaste(selectedFile: any, filePath: any) {
        // console.log('selectedFiles', selectedFiles);
        dispatch({ type: 'closeUploadFileModal' });
        await handleSendFie(state.file, filePath);
    }

    const [fileDrag, setFileDrag] = useState(false);
    const dropOption = config[ChatConfig.CHAT_MESSAGE_DRAG_FILE]
        ? {
              onDragEnter: handleDragEnter,
              onDragOver: handleDragOver,
              onDrop: handleDrop,
          }
        : {};

    const fileDragPart = useMemo(() => {
        if (!selectedSession.sessionId) {
            return null;
        }
        if (isBannedPostGroupSession(selectedSession) && isGroupOrdinary(groupRosterIdentity)) {
            return (
                <div className="main-part-mask" onDragLeave={handleDragLeave}>
                    <div className="inner">
                        <ExclamationOutlined
                            className="exclamation"
                            style={{ color: 'rgba(245,34,45,1)' }}
                        />
                        <div>{t('drag_here_warning')}</div>
                    </div>
                </div>
            );
        }
        return (
            <div className="main-part-mask" onDragLeave={handleDragLeave}>
                <div className="inner">
                    {t('drag_here_to_sb').replace('%s', selectedSession.info.name)}
                </div>
            </div>
        );
    }, [selectedSession, t, groupRosterIdentity]);

    if (!loadAllSessionListEnd) {
        return (
            <div className="chat-main-container">
                <div className="main-part">
                    <Loading />
                </div>
            </div>
        );
    }

    return (
        <Context.Provider
            value={{
                currentEmployee,
                groupRosters,
                groupRosterIdentity,
                userData,
                onToggleSetup,
                closeSetting: () => {},
                chatAggregationStyle: singleLine ? { width: 430 } : {},
                isSmall: singleLine,
            }}
        >
            <div className={`chat-main-container grey ${singleLine ? 'small' : ''}`}>
                {sessionId && (
                    <div className="main-part" {...dropOption}>
                        <div className="main-header">
                            <SessionHeader
                                session={selectedSession}
                                setCallBack={setCallBack}
                                hideSetting={hideSetting}
                            />
                        </div>
                        <div className="main-body">
                            {props.customHeader ? props.customHeader : null}
                            <MessageBoxContainer />
                        </div>
                        {/* tj:非会话模式隐藏输入框，解决切换消息类型未保存草稿 */}
                        <div
                            id="main-box"
                            className={
                                [
                                    SessionType.SINGLE,
                                    SessionType.SECRET_SINGLE,
                                    SessionType.GROUP,
                                ].includes(selectedSession.sessionType)
                                    ? messageInputFocus
                                        ? 'main-footer editing'
                                        : 'main-footer'
                                    : 'dn'
                            }
                        >
                            {selectMore ? (
                                type === 'addUser' ? (
                                    <ForwardSelect />
                                ) : (
                                    <Forwarding />
                                )
                            ) : (
                                <MessageInput
                                    uploadFile={handleUploadFile}
                                    uploadImg={throttle(handleUploadImg, 200)}
                                    uploadMultiImg={throttle(handleMultiUploadImg, 200)}
                                    sendJoyspaceMessage={handleShareJoySpace}
                                    singleLine={singleLine}
                                />
                            )}
                        </div>
                        {/* 拖拽上传 */}
                        {fileDrag && fileDragPart}
                        <UpLoadFile
                            visible={state.fileUploadModalVisible}
                            onCancel={closeUploadFileModal}
                            onClick={doSendFile}
                            isMulti={isMulti}
                            file={state.file}
                            fileList={state.fileList}
                            title={state.fileState.title}
                            size={state.fileState.size}
                            fileType={state.fileState.fileType}
                        />
                    </div>
                )}
                {/* 未点击任何聊天时，右侧展示占位图 */}
                {!sessionId && allSessionListLength >= 0 && (
                    <div className="default">
                        <Empty image={default_chat_png} description="暂无消息" imageStyle={style} />
                    </div>
                )}
            </div>
        </Context.Provider>
    );
}

function mapStateToProps({ chat, user: { userData } }: any) {
    const {
        allSessionList,
        selectedSession,
        currentEmployee,
        selectMore,
        type,
        loadAllSessionListEnd,
        messageInputFocus,
        administrator,
        sub_administrator,
        ordinary,
    } = chat as ChatState;
    return {
        allSessionListLength: allSessionList?.length,
        selectedSession,
        currentEmployee,
        selectMore,
        type,
        loadAllSessionListEnd,
        messageInputFocus,
        userData: userData ? userData.user : {},
        administrator,
        sub_administrator,
        ordinary,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatMain);
