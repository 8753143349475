import { message as toast } from 'antd';
import { Employee } from '@/types/chat';
import { convertEmployeeToString, employeeToUid } from '@/components/chat/utils/message';
import { FocusSDK, JoyMeetingStatusEnum, isFocusEnv, getClientType } from '@/utils';
import { createMeeting, getJRTCToken } from '@/api/meeting';
import dayjs from 'dayjs';
import { openUserSelectionModal } from '@/utils/modals';
import { mapGroupRosters } from '../../GroupUserSelector/common';
import { UserSelectionResult } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import bus from '@/utils/bus';
import cache from '@/utils/cache';
import { AVSDK } from '@/baseComponents/Meeting/common';
import { NetType } from '@/components/Modals/ChangeNetModal';
import i18n from 'i18next';

// 当前聊天中使用的视频会议版本
let currentMeetingPlatform = process.env.REACT_APP_MEETING === 'jrtc' ? AVSDK.JBRTC : AVSDK.ZOOM;

const handleCreateSingleJDMeeting = (selectedSession: any, currentEmployee: any, flag: any) => {
    const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
    let meetingTopic = flag
        ? i18n.t('chat:joyMeeting.voiceTopicDesc')
        : i18n.t('chat:joyMeeting.meetingTopicDesc');
    bus.emit('meeting:create-single-call', {
        avsdk: AVSDK.JBRTC,
        toUser: {
            pin: userId,
            teamId,
            name,
            app,
            avatar,
        },
        meetingInfo: {
            meetingTopic: meetingTopic.replace('%from', currentEmployee.name).replace('%to', name),
        },
        type: flag === true ? 'voice' : 'meeting',
    });
};

function handleCreateGroupMeetingClick(groupRosters: any, userData: any, currentEmployee: any) {
    const groupMembers = mapGroupRosters(groupRosters, { visible: true });
    openUserSelectionModal(
        {
            title: i18n.t('chat:add_member'),
            tabs: ['custom'],
            resultUsers: groupMembers,
            frozenIds: [convertEmployeeToString(currentEmployee)],
            customTab: {
                tabName: i18n.t('chat:group_members'),
                dataList: groupMembers,
            },
            currentUser: employeeToUid(currentEmployee),
        },
        (data: UserSelectionResult, close: () => void) => {
            const { result } = data.data;
            const users = result.map((r) => {
                const tem = r.id.split(':');
                return {
                    realName: r.name,
                    userId: tem[0],
                    teamId: tem[2],
                    appId: tem[1],
                    headImg: r.avatar as string,
                };
            });
            if (currentMeetingPlatform === AVSDK.JBRTC) {
                handleCreateGroupJDMeeting(currentEmployee, users);
            } else {
                handleCreateGroupMeeting(users);
            }
            close();
        },
        userData
    );
}

const handleCreateGroupJDMeeting = (currentEmployee: any, participants: any[]) => {
    getJRTCToken({
        nickname: currentEmployee.name || '',
        unit: currentEmployee.deptName || '',
        avatar: currentEmployee.avatar || '',
        deviceType: getClientType(),
    }).then(([jrcToken, err]) => {
        if (err) {
            toast.error(err);
            return;
        }

        const { app, userId, teamId, name, avatar, nickName, deptName } = currentEmployee;

        const userInfo = {
            token: jrcToken.token,
            deviceType: jrcToken.deviceType,
            jrtcUserId: jrcToken.jrtcUserId,
            appId: jrcToken.appId,
            app,
            pin: userId,
            teamId,
            name,
            avatar,
            deptName,
        };
        FocusSDK.sendIpcMessage('jdmeet.showCreate', { userInfo, participants });
    });
};
const handleCreateSingleMeeting = async (
    newPublicResult: boolean,
    selectedSession: any,
    currentEmployee: any
) => {
    if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
        if (newPublicResult) {
            toast.warn(i18n.t('common:joinMeeting.change public net'));
        } else {
            toast.warn(i18n.t('common:joinMeeting.private net'));
        }
        return;
    }
    async function prepareCreateMeeting(data: any) {
        // 如果当前非空闲，则直接退出
        if (!data.isFree) {
            toast.error(i18n.t('chat:joyMeeting.There is a meeting going on'));
            return;
        }
        // open single call meeting
        // 发送消息到桌面端
        const { userId, teamId, app, name, avatar } = selectedSession.info as Employee;
        const [result, err] = await createMeeting({
            startTime: (dayjs().valueOf() + 5000).toString(),
            duration: 2,
            topic: i18n
                .t('chat:joyMeeting.meetingTopicDesc')
                .replace('%from', currentEmployee.name)
                .replace('%to', name),
            password: '',
            participantIds: [
                {
                    realName: name,
                    teamId,
                    userId,
                    appId: app,
                    headImg: avatar,
                },
                {
                    realName: currentEmployee.name,
                    teamId: currentEmployee.teamId,
                    userId: currentEmployee.userId,
                    appId: currentEmployee.app,
                    headImg: currentEmployee.avatar,
                },
            ],
        });
        if (err) {
            // 如果请求创建报错，则清空会议状态
            FocusSDK.sendIpcMessage(
                'MeetingControl:changeJoyMeetingStatus',
                JoyMeetingStatusEnum.None
            );
            return toast.error(i18n.t('chat:joyMeeting.create meeting failed'));
        }
        bus.emit('meeting:create single call', {
            toUser: {
                username: name,
                teamId,
                userId,
                app,
                pin: userId,
                avatar,
            },
            meetingData: result,
        });
    }
    if (!cache.zoomLogin) {
        toast.warn(i18n.t('common:joinMeeting.waiting'));
    }
    FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', prepareCreateMeeting);
    FocusSDK.sendIpcMessage(
        'MeetingControl:changeJoyMeetingStatus',
        JoyMeetingStatusEnum.SingleCall
    );
};

function handleCreateGroupMeeting(participants: any[]) {
    // 这里用focussdk，是因为群聊有可能从新窗口打开，所以消息需要从桌面端中转一下，在主窗口打开modal
    FocusSDK.sendToMainWin({
        type: 'meeting:create-show',
        data: {
            list: participants,
        },
    });
    // 主窗口 聚焦
    FocusSDK.sendMainMessage('focusMainWin', {});
}

export function handleMeetingClickOperation({
    selectedSession,
    currentEmployee,
    newPublicResult,
    globalContext,
    flag,
}: {
    selectedSession: any;
    currentEmployee: any;
    newPublicResult: boolean;
    globalContext: {
        groupRosters: any;
        userData: any;
    };
    flag: any;
}) {
    const { userData, groupRosters } = globalContext;
    if (selectedSession.isSingle) {
        // 单聊
        if (currentMeetingPlatform === AVSDK.JBRTC) {
            handleCreateSingleJDMeeting(selectedSession, currentEmployee, flag);
        } else {
            handleCreateSingleMeeting(newPublicResult, selectedSession, currentEmployee);
        }
    } else {
        // 群聊
        handleCreateGroupMeetingClick(groupRosters, userData, currentEmployee);
    }
}
