/**
 * 文档批量操作
 * liangshan
 */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Icons } from '@/components/Joyspace/Icon';
import { message, Dropdown, Button, Popover, Tooltip } from 'antd';
import './index.less';
import context, { useTranslation } from '@/components/Joyspace/context';

import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { IContextRender } from '@/components/Joyspace/components/List/ContextMenu';
import events from '@/components/Joyspace/context/events';
import { IPage, ICategory } from '../types';
import { getPermission } from '@/api/user';

export type Option = (props: {
    children: React.ReactElement | string;
    callbackOrModal?: (() => any) | JSX.Element;
    type?: string;
    isAllow?: boolean;
}) => void;

export interface IProps {
    children?: JSX.Element | JSX.Element[];
    render: IContextRender<IPage> | undefined;
    categoryRender: IContextRender<ICategory> | undefined;
    batchRender: IContextRender<(ICategory | IPage)[]> | undefined;
    selecteds: Set<any>;
    select: (set: Set<any>) => void;
    categories: ICategory[];
    setCategories: (c: ICategory[]) => void;
}
function BatchControl(props: IProps) {
    const {
        selecteds,
        select,
        render,
        categoryRender,
        batchRender,
        categories,
        setCategories,
    } = props;
    // console.log(props, 'propspropspropspropsprops');
    const globalContext = useContext<GlobalContextConfig>(GlobalContext);
    const { freshLeftTreeByFolderById } = useContext(context);
    const [visible, setVisible] = React.useState(false);
    const [showBtnCount, setBtnCount] = React.useState(4);
    const lang = useTranslation();
    function MenuItem(props: {
        disabled: boolean;
        tip?: any;
        callbackOrModal: (() => any) | React.ReactElement | undefined;
        name: string | React.ReactElement;
        setVisible: ((v: boolean) => any) | React.ReactElement | undefined;
    }) {
        const { callbackOrModal, setVisible, disabled, tip } = props;
        return (
            <li
                className="joyspace-li-batch-control"
                onClick={(e) => {
                    if (disabled) return;
                    e.stopPropagation();
                    e.preventDefault();
                    if (typeof callbackOrModal === 'function' && typeof setVisible === 'function') {
                        setVisible(false);
                        callbackOrModal();
                    } else if (React.isValidElement(callbackOrModal)) {
                        ReactDOM.render(callbackOrModal, document.createDocumentFragment());
                    }
                }}
            >
                {disabled ? (
                    <Tooltip placement="left" title={tip}>
                        <Button block={true} type="text" disabled={disabled}>
                            {props.name}
                        </Button>
                    </Tooltip>
                ) : (
                    <Button block={true} type="text">
                        {props.name}
                    </Button>
                )}
            </li>
        );
    }
    const doClick = (e: React.MouseEvent, callbackOrModal?: any, setVisible?: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (typeof callbackOrModal === 'function') {
            typeof setVisible === 'function' && setVisible(false);
            callbackOrModal();
        }
    };
    const getDisabledTip = (type: any, selectType: any) => {
        console.log(type, 'typetypetypetypetypetypetype');
        let tip = '无权限';
        switch (type) {
            case 'move':
                // tip = lang('');
                tip = '仅所有者和创建者可移动';
                break;
            case 'delete':
                // tip = lang('');
                tip = '仅所有者和创建者可删除';
                break;
            case 'copy':
                // tip = lang('');
                tip = '仅所有者和可编辑协作者可复制';
                break;
            case 'setName':
                // tip = lang('');
                tip = '仅所有者和可编辑协作者可设置';
                break;
            case 'rename':
                // tip = lang('');
                tip = '仅所有者和可编辑协作者可重命名';
                break;
            case 'clone':
                // tip = lang('');
                tip = '仅所有者和可编辑协作者可创建副本';
                break;
        }
        return tip;
    };
    const getPermission = (selecteds: any, type: any) => {
        return true;
    };
    const create = useMemo(
        () => (render: Function | undefined, callbackProps: any, selectType?: any) => {
            if (!render) {
                console.warn('传入render出错');
                return null;
            }
            const itemsMid: JSX.Element[] = [];
            const itemsRight: JSX.Element[] = [];
            const itemsLeft: JSX.Element[] = [];
            const option: Option = ({ children: name, callbackOrModal, isAllow = true, type }) => {
                const tip = getDisabledTip(type, selectType);
                const button = isAllow ? (
                    <Button
                        type="default"
                        key={itemsRight.length + itemsLeft.length}
                        onClick={(e) => {
                            doClick(e, callbackOrModal);
                        }}
                    >
                        {name}
                    </Button>
                ) : (
                    <Tooltip placement="top" title={tip}>
                        <Button
                            type="default"
                            key={itemsRight.length + itemsLeft.length}
                            onClick={(e) => {
                                doClick(e, callbackOrModal);
                            }}
                            disabled={true}
                        >
                            {name}
                        </Button>
                    </Tooltip>
                );
                // const button = (
                //     <Button
                //         type="default"
                //         disabled={!isAllow}
                //         key={itemsRight.length + itemsLeft.length}
                //         onClick={(e) => {
                //             doClick(e, callbackOrModal);
                //         }}
                //     >
                //         {name}
                //     </Button>
                // );
                if (type === 'delete') {
                    itemsRight.push(button);
                    return;
                }
                if (type === 'copy' && selectType && selectType !== 'file') {
                    return;
                }
                if (itemsLeft.length < showBtnCount) {
                    itemsLeft.push(button);
                    return;
                }
                if (itemsLeft.length === showBtnCount) {
                    itemsMid.push(
                        <MenuItem
                            disabled={!isAllow}
                            key={itemsMid.length}
                            setVisible={setVisible}
                            name={name}
                            callbackOrModal={callbackOrModal}
                            tip={tip}
                        />
                    );
                }
            };
            render(option, callbackProps);
            return (
                <>
                    <div className="batch-button-space-left">{itemsLeft}</div>
                    {itemsMid.length > 0 && (
                        <div className="batch-button-space-mid">
                            <Popover
                                trigger="click"
                                onVisibleChange={handleVisibleChange}
                                visible={visible}
                                content={<ul className="joyspace-operate-lists">{itemsMid}</ul>}
                            >
                                <Button type="default">
                                    <span className="joyspace-trigger-icon">{Icons.trigger}</span>
                                </Button>
                            </Popover>
                        </div>
                    )}
                    <div className="batch-button-space-right">{itemsRight}</div>
                </>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showBtnCount, visible]
    );
    useEffect(() => {
        let timer: any;
        function doHandelResize() {
            const container = document.getElementsByClassName('joyspace-list-table')?.[0];
            if (container) {
                // @ts-ignore
                const width = Number(container.offsetWidth) - 120;
                if (width >= 570) {
                    setBtnCount(6);
                } else if (520 <= width && width <= 570) {
                    setBtnCount(5);
                } else if (420 <= width && width <= 520) {
                    setBtnCount(4);
                } else if (340 <= width && width <= 420) {
                    setBtnCount(3);
                } else if (260 <= width && width <= 340) {
                    setBtnCount(2);
                } else if (160 <= width && width <= 260) {
                    setBtnCount(1);
                } else {
                    setBtnCount(0);
                }
            }
        }
        const handelResize = () => {
            timer = setTimeout(() => {
                doHandelResize();
            }, 100);
        };
        doHandelResize();
        window.addEventListener('resize', handelResize);
        events.on('left-nav-resize', handelResize);

        return () => {
            timer && clearTimeout(timer);
            window.removeEventListener('resize', handelResize);
            events.off('left-nav-resize', handelResize);
        };
    }, []);
    function reload() {
        events.emit('list.reload');
    }
    // function remove(o: ICategory) {
    //     select(new Set());
    //     setCategories(categories.filter((_) => _ !== o));
    // }
    function clearSelect() {
        select(new Set());
    }

    function checkType(selectArray: any) {
        let fileCount = 0;
        let folderCount = 0;
        selectArray.forEach((element: any) => {
            if (typeof element.page_type === 'undefined') {
                folderCount++;
            } else {
                fileCount++;
            }
        });
        let selectType = 'file';
        if (fileCount > 0 && folderCount === 0) {
            selectType = 'file';
        } else if (fileCount > 0 && folderCount > 0) {
            selectType = 'both';
        } else {
            selectType = 'folder';
        }
        return selectType;
    }
    function handleVisibleChange(visible: boolean) {
        setVisible(visible);
    }

    return useMemo(() => {
        if (selecteds.size === 0) {
            return null;
        }
        if (selecteds.size === 1) {
            const item: any = [...selecteds][0];
            let useRender;
            const callbackProps = {
                data: item,
                reload,
                clearSelect,
                freshLeftTreeByFolderById,
            };
            // 文件夹
            if (typeof item.page_type === 'undefined') {
                useRender = categoryRender;
            } else {
                useRender = render;
            }
            return (
                <div className="joyspace-list-bottom">
                    <div className="batch-button-space" id="batch-button-space">
                        {create(useRender, callbackProps)}
                    </div>
                </div>
            );
        }
        // 批量操作
        if (selecteds.size > 1) {
            const callbackProps = {
                data: selecteds,
                reload,
                clearSelect,
                freshLeftTreeByFolderById,
            };
            const selectType = checkType(selecteds);
            const render = batchRender;
            return (
                <div className="joyspace-list-bottom">
                    <div className="batch-button-space">
                        {create(render, callbackProps, selectType)}
                    </div>
                </div>
            );
        }
        return null;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selecteds, create]);
}

export default BatchControl;
