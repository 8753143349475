import IconFont from '@/components/icon';
import { Avatar, Empty, List, message, Tree } from 'antd';
import React, { ReactNode, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import { DataListProps, ListItem, StateActionNames, Folder } from './types';
import './index.less';
import { useTranslation } from '@/components/Joyspace/context';
import InfiniteScroll from 'react-infinite-scroller';
import { StoreContext } from './store';
import { changeEgovUrl } from '@/utils/tools';
const { DirectoryTree } = Tree;

export default function DataList(props: DataListProps) {
    const [state, dispatch] = useContext(StoreContext);
    const [treeData, setTreeData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const t = useTranslation();
    const {
        title = '',
        onBack,
        onFolderClick,
        loadMore,
        loading,
        items,
        hasMore,
        currentFolder = {},
        searchValue = '',
    } = props;
    const Icon = (item: any) => {
        return <img style={{ width: '20px' }} src={changeEgovUrl(item.iconUrl)} />;
    };
    const hanldeData = useCallback(
        (list: any) => {
            return list.map((item: any) => {
                item.key = item.id;
                item.checkable = item.type === 'page';
                item.disableCheckbox = item.type === 'folder';
                item.icon = <Icon {...item} />;
                item.isLeaf = item.type === 'page';
                item.children = [];
                item.selectable = state.selectedItems.some(
                    (si: ListItem) => si.type === item.type && si.id === item.id
                );
                return item;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const setHighlight = (item: any) => {
        if (!item.highlight) {
            return item;
        }
        for (let _item of item.highlight) {
            if (_item.value === item.title) {
                const index = _item.value.indexOf(_item.key);
                const beforeStr = _item.value.substr(0, index);
                const afterStr = _item.value.substr(index + _item.key.length);
                item.name = item.title;
                item.title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="highlight">{_item.key}</span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{_item.value}</span>
                    );
            }
        }
        return item;
    };
    const updateTreeData: any = useCallback((list: any[], key: any, children: any[]) => {
        // console.log(list, 'nodenodenode', children);
        if (!key || key === 'myspace' || key === 'teamRoot') {
            return children.map((item) => {
                return setHighlight(item);
            });
        }
        return list.map((node) => {
            if (node.key === key) {
                return {
                    ...node,
                    children,
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateTreeData(node.children, key, children),
                };
            }
            return node;
        });
    }, []);
    // const loop = (data: any) =>
    //     data.map((item: any) => {
    //         const index = item.title.indexOf(searchValue);
    //         const beforeStr = item.title.substr(0, index);
    //         const afterStr = item.title.substr(index + searchValue.length);
    //         console.log(index, 'indexindexindex');
    //         const title =
    //             index > -1 ? (
    //                 <span>
    //                     {beforeStr}
    //                     <span style={{ color: 'red' }}>{searchValue}</span>
    //                     {afterStr}
    //                 </span>
    //             ) : (
    //                 <span>{item.title}</span>
    //             );
    //         if (item.children) {
    //             return { title, key: item.key, children: loop(item.children) };
    //         }

    //         return {
    //             title,
    //             key: item.key,
    //         };
    //     });
    useMemo(() => {
        const folderId = currentFolder ? currentFolder.id : '';
        const _list: any = updateTreeData([...treeData], folderId, hanldeData([...items]));
        // const data = loop(_list);
        // console.log(_list, '_list_list_list_list_list');
        setTreeData(_list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);
    useMemo(() => {
        const ids = state.selectedItems.map((item: any) => {
            return item.id;
        });
        setSelectedKeys(ids);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectedItems, items]);
    const onLoadData = async (item: any) => {
        // console.log(item, 'itemitemitemitemitem');
        if (item.type === 'folder') {
            await onFolderClick?.(item);
        } else if (item.type === 'page') {
            dispatch({
                action: StateActionNames.TOGGLE_SELECTED,
                item: item,
            });
        }
    };
    return (
        <div className="selector-data-list">
            <div className="selector-data-list-body">
                <DirectoryTree
                    multiple
                    showIcon
                    checkable
                    loadData={onLoadData}
                    checkedKeys={selectedKeys}
                    treeData={treeData}
                    // onExpand={(selectedKeys, e) => {
                    //     console.log(selectedKeys, e);
                    // }}
                    onCheck={(key: any, checkedNodes: any) => {
                        const item = checkedNodes.node || {};
                        if (item.type === 'page') {
                            const action = checkedNodes.checked
                                ? StateActionNames.TOGGLE_SELECTED
                                : StateActionNames.DELETE_SELECTED;
                            item.title = item.name ? item.name : item.title;
                            dispatch({
                                action,
                                item: item,
                            });
                        }
                    }}
                />
                {/* <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={() => loadMore?.()}
                    hasMore={hasMore && !loading}
                    useWindow={false}
                >
                    <List
                        loading={loading}
                        dataSource={items}
                        split={false}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={t('no data')}
                                />
                            ),
                        }}
                        renderItem={(item) => (
                            <List.Item
                                key={item.id}
                                className="selector-data-list-item"
                                onClick={() => {
                                    if (item.type === 'folder') {
                                        onFolderClick?.(item);
                                    } else if (item.type === 'page') {
                                        dispatch({
                                            action: StateActionNames.TOGGLE_SELECTED,
                                            item: item,
                                        });
                                    }
                                }}
                                actions={
                                    item.type === 'folder'
                                        ? [
                                              <IconFont
                                                  key="action-enter"
                                                  type="iconapp_ic_arrow_right"
                                              />,
                                          ]
                                        : undefined
                                }
                            >
                                {state.selectedItems.find(
                                    (si: ListItem) => si.type === item.type && si.id === item.id
                                ) ? (
                                    <IconFont
                                        key="action-selected"
                                        className="iconapp_btn_radio_selected"
                                        type="iconjs_ic_radio1"
                                        onClick={() => {}}
                                    />
                                ) : (
                                    <IconFont
                                        className="iconapp_btn_radio_normal"
                                        key="action-normal"
                                        type="iconbtn_checkbox"
                                        onClick={() => {}}
                                    />
                                )}
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            size={40}
                                            shape="square"
                                            src={changeEgovUrl(item.iconUrl)}
                                            className="joyspace-file-selector-avatar"
                                        />
                                    }
                                    title={
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: item.title || t('untitled'),
                                            }}
                                        />
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </InfiniteScroll> */}
            </div>
        </div>
    );
}
