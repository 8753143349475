import { ChatMessageType } from '@/types/chat';
import { NewTaskModel, TASK_STATUS, taskStatusOptions } from '@/types/newWork';
import { TaskResourceBizType, TaskResourcePermissionType } from '@/types/work';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { FileStatus } from './CreateTaskDraw';
// import { htmlUnescape } from '@/utils/tools';

/**
 * @description 判断任务是否完成或中止
 */
export function judgeTaskFinishOrStop(status: number | null | undefined) {
    if (!status) {
        return false;
    }
    return [TASK_STATUS.FINISHED, TASK_STATUS.ABORT].includes(status);
}
export function judgeTaskFinishAndStop(status: number | null | undefined) {
    if (!status) {
        return [];
    }
    return [TASK_STATUS.FINISHED === status, TASK_STATUS.ABORT === status];
}
/**
 * @description 获取当前任务状态对象
 */
export function getTaskStatus(status: number | null | undefined) {
    const target = taskStatusOptions.find((item) => item.status === status);
    return target;
}

/**
 * 装饰任务详情对象，补全和转换一些字段，
 */
export function decorateTaskDetail(task: NewTaskModel) {
    task.workStartTime = task.startTime ? dayjs(task.startTime) : null;
    task.workStopTime = task.endTime ? dayjs(task.endTime) : null;
    task.remark = task.remark
        ? task.remark
        : {
              id: '',
              remark: '',
          };
    // 提前‘去转义’处理，因为标题有可能会成为群聊天标题(createGroup 接口的入参)——废掉；因为后端先还原转义再转义
    // task.title = htmlUnescape(task.title);
    task.resources = task.resources || [];
    return task;
}

export function decorateQuickCreateTaskResource(resource: any) {
    let fileId = nanoid(20);
    if (resource.type === ChatMessageType.TEMPLATE2) {
        const joyspaceFile =
            resource.data && resource.data[0]
                ? resource.data[0].sharelink
                : { category: '', url: '' };
        let pageType = joyspaceFile.pageType || getPageType(joyspaceFile);
        fileId = joyspaceFile.url.split('?')[0].split('/').pop();
        return {
            fileName: joyspaceFile.title || '无标题',
            name: joyspaceFile.title || '无标题',
            size: 0,
            fileSize: 0,
            fileId: fileId,
            bizId: fileId,
            fileType: `joyspace/${pageType}/${joyspaceFile.type}`,
            // fileUrl: `${link}?jdme_router=${encodeURIComponent(r.linkH5)}`,
            url: joyspaceFile.url,
            fileUrl: joyspaceFile.url,
            bizType: TaskResourceBizType.JOYSPACE,
            resourceType: TaskResourcePermissionType.COMMON,
            resourceId: nanoid(),
            percent: 0,
            status: FileStatus.Done,
        };
    } else if (resource.type === ChatMessageType.JOYSPACEFILE) {
        return {
            fileName: resource.fileName || '无标题',
            name: resource.fileName || '无标题',
            size: 0,
            fileSize: 0,
            fileId: resource.fileId,
            bizId: resource.fileId,
            fileType: `joyspace/${resource.pageType}/${resource.type}`,
            // fileUrl: `${link}?jdme_router=${encodeURIComponent(r.linkH5)}`,
            url: resource.webUrl,
            fileUrl: resource.webUrl,
            bizType: TaskResourceBizType.JOYSPACE,
            resourceType: TaskResourcePermissionType.COMMON,
            resourceId: nanoid(),
            percent: 0,
            status: FileStatus.Done,
        };
    }
    const isImage = resource.type ? resource.type === 'image' : false;
    const id = isImage ? `${resource.id}.jpg` : resource.id;
    return {
        fileName: resource.fileName || resource.name || id,
        fileUrl: resource.url,
        fileId: fileId,
        name: resource.fileName || resource.name || id,
        fileSize: resource.size,
        size: resource.size,
        url: resource.url,
        resourceId: nanoid(),
        bizId: fileId,
        bizType: resource.bizType || TaskResourceBizType.FILE,
        resourceType: TaskResourcePermissionType.COMMON,
        fileType: resource.fileType || (isImage ? 'image/jpeg' : 'application/octet-stream'),
        percent: 0,
        status: FileStatus.Done,
        // icon: getFileIconByMime(
        //     resource.fileType || lookup(resource.fileName) || 'application/octet-stream'
        // ),
    };
}

function getPageType(file: any) {
    // 为了兼容移动端消息的代码
    // joyspace文档
    if (file.category === 'jd_doc') {
        return 1;
    }

    // wps
    const fileExtension = file.title.split('.').pop();
    switch (fileExtension) {
        case 'doc':
        case 'xls':
            return 6;
        default:
            return 1;
    }
}
