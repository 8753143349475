import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Modals } from '@jd/focus-desktop-comps';
import { Tooltip, message, Modal } from 'antd';
import { Avatar } from '@/baseComponents/Avatar';
import { changeEgovUrl, changeGovUrl } from '@/utils/tools';
import IconFont, {
    IconArrowRight,
    IconEdit,
    IconExit,
    IconSet,
    IconFailure,
    IconGroup,
    IconRdit,
} from '@/components/icon';
import { UserCenterConfig } from '@/types/UserCenter';
import { UserCardData, UserCardType, UserCardAction } from '@/types/UserCard';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { useTranslation } from 'react-i18next';
import Teams from '@/components/TeamManagement';
import AboutModal from '@/components/AboutModal';
import { FocusSDK, getUserAppId, isFocusEnv } from '@/utils';
import { connect } from 'dvajs';
import { UserModel } from '@/models/user';
import { submitFeedback } from '@/api/feedback';
import Bus from '@/utils/bus';
import { getTranslationName } from '@/utils/i18n';
import './UserCenter.less';
import { getTeamAdmin } from '@/api/user';
import PersonalQRCode from '@/components/PersonalQRCode';
import UploadImageControler from './UploadImage/UploadIamgeControler';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { debounce, isUndefined } from 'lodash';
import ChatState from '@/types/chat/State';
import Loading from '../components/Loading';
import { getEbookDeptDesc } from '@/api/ebook';
import { useHistory } from 'react-router-dom';
import { createUploadTask } from '@/server/FileService';
// eslint-disable-next-line no-duplicate-imports
import { changeAvatarReq } from '@/api/user';
import { Employee } from '@/types/chat';
import ImService from '@/server/ImService';
import getVersionBySdk from '@/utils/getVersion';
import { UAParser } from 'ua-parser-js';
import colors from '@/components/styles/colors';
import { GlobalOutlined } from '@ant-design/icons';

const divider = (
    <div
        style={{
            height: 1,
            backgroundColor: '#F0F3F3',
            margin: '5px 0',
        }}
    />
);

const Item = ({
    onClick,
    icon,
    text,
    withArrow = true,
    clstag = '',
    className = '',
}: {
    onClick: () => void;
    icon: any;
    text: any;
    withArrow?: boolean;
    clstag?: string;
    className?: string;
}) => {
    const [hover, setHover] = useState(false);
    let theclstag = clstag ? { clstag: clstag } : {};
    return (
        <div
            style={{
                padding: '10px 24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: 14,
                cursor: 'pointer',
                backgroundColor: hover ? '#F8F9F9' : 'transparent',
            }}
            className={className}
            role="button"
            tabIndex={0}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onKeyDown={() => {}}
            onClick={onClick}
            {...theclstag}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    // style={{
                    //     color: '#f00',
                    // }}
                    className="iconColor"
                >
                    {icon}
                </div>
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {text}
                </span>
            </div>
            {withArrow && (
                <span
                    style={{
                        color: '#8F959E',
                        fontSize: 10,
                    }}
                >
                    {IconArrowRight}
                </span>
            )}
        </div>
    );
};

export enum UserCenterItemType {
    Setup = 'setup',
    About = 'about',
    Logout = 'logout',
    Feedback = 'feedback',
    JoinMe = 'joinme',
    Manager = 'manager',
}

// config 是用于配置功能开启关闭的配置项
// eslint-disable-next-line complexity
function UserCenter(props: {
    notify?: any;
    config?: UserCenterConfig;
    getWebUserData: any;
    clearJoyspaceData: any;
    clearChatData: any;
    clearCalendarData: any;
    clearEbookData: any;
    currentEmployee: Employee;
    setIdentityInfoModal: Function;
    updateCurrentEmployee: Function;
    setOpenAdministratorListModal: Function;
}) {
    const { authInfo: authData, service, appRuntimeEnv, focusSDK } = useContext<
        GlobalContextConfig
    >(GlobalContext);
    const { t } = useTranslation('common');
    const { t: joinMeT } = useTranslation('joinMe');
    const {
        notify,
        config,
        getWebUserData,
        clearChatData,
        clearJoyspaceData,
        clearCalendarData,
        clearEbookData,
        setIdentityInfoModal,
        updateCurrentEmployee,
        currentEmployee,
        setOpenAdministratorListModal,
    } = props;
    const history = useHistory();
    let { teamUserInfo } = authData || {};

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [visible, setVisible] = useState(false);
    const [userInfo, setUserInfo] = useState<UserCardData | null>(null);
    const [loading, setLoading] = useState(true);
    // const [superAdmin, setSuperAdmin] = useState(false);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [-50, 16],
                },
            },
        ],
    });
    const [workStatus, setWorkStatus] = useState('');
    const [isAboutModalVisible, setAboutModalVisible] = useState(false);
    const [visibleQRCode, setVisibleQRCode] = useState(false);
    const [username, setUsername] = useState(authData.account);
    const [headImg, setHeadImg] = useState<string | undefined>();
    const TOAST_ERROR_KEY = 'TOAST_ERROR_KEY';

    const getOSSBaseUrl = () => changeEgovUrl(process.env.REACT_APP_OSS_BASE_URL);

    useEffect(() => {
        setUsername(authData.teamUserInfo?.realName || authData.account);
        setHeadImg(authData.teamUserInfo?.headImg || undefined);
    }, [authData]);

    const onlineListener = useCallback(() => {
        if (!authData.teamUserInfo.headImg) return;
        let newHeadImg = authData.teamUserInfo.headImg + `&timestamp=${Date.now()}`;
        setHeadImg(newHeadImg);
    }, [authData]);

    useEffect(() => {
        window.addEventListener('online', onlineListener);
        return () => {
            window.removeEventListener('online', onlineListener);
        };
    }, [onlineListener]);

    const changeWorkStatus = (data: any) => {
        setWorkStatus(data.workStatus && data.workStatus !== '无' ? data.workStatus : '暂未填写');
    };
    useEffect(() => {
        Bus.on('workStatus:update', changeWorkStatus);
        return () => {
            Bus.off('workStatus:update', changeWorkStatus);
        };
    }, []);

    const onOpenUserCard = useCallback(
        (data: { appId: string; userId: string; teamId: string }) => {
            const { userId, appId, teamId } = data;
            Bus.emit('app:toggle-user-card-modal:show', {
                visible: true,
                type: UserCardType.User,
                userId,
                teamId,
                appId,
                applyInfos: [],
                actions: [UserCardAction.Chat],
            });
        },
        []
    );

    const doShowQRCode = useCallback(() => [setVisibleQRCode(true)], []);
    const doSwitchQRCode = useCallback(() => [setVisibleQRCode(!visibleQRCode)], [visibleQRCode]);

    const showJoinOrCreateTeam = useMemo(() => {
        const canCreate = appRuntimeEnv.getUIConfig('canCreateTeam');
        const canJoin = appRuntimeEnv.getUIConfig('canJoinTeam');
        if (!isUndefined(canCreate) && !canCreate && !isUndefined(canJoin) && !canJoin) {
            return false;
        }
        return true;
    }, [appRuntimeEnv]);

    const onItemClick = useCallback(
        (type: UserCenterItemType) => {
            const userData = { ...authData, team: authData.teamUserInfo };
            switch (type) {
                case UserCenterItemType.JoinMe: {
                    Teams.TeamManagement({
                        userData,
                        getWebUserData,
                        appRuntimeEnv,
                        focusSDK,
                    });
                    break;
                }
                case UserCenterItemType.Feedback: {
                    const logFiles = ['main.log', 'main.old.log', 'uncaughtException-latest.log']
                        .map((item) => {
                            const file = focusSDK.getLogFile(item);
                            if (!file) {
                                return null;
                            }
                            return {
                                ...file,
                                name: item,
                            };
                        })
                        .filter((item) => !!item);
                    const { destroy } = Modals.Feedback({
                        t,
                        userData,
                        headNetCheckBtn: false,
                        headNetCheckCallback: () => {
                            if (!isFocusEnv()) {
                                return;
                            }
                            return;
                            // focusSDK.emit('showNetTable');
                        },
                        onSubmit: (params: any) => {
                            const { name } = FocusSDK.getApplicationInfo();
                            const appVersion = isFocusEnv()
                                ? getVersionBySdk(FocusSDK)
                                : appRuntimeEnv.registryConfig.pkgVersion.split('-')[0];
                            const appName = `${name} ${isFocusEnv() ? 'desktop' : 'web'}`;
                            let uaParser = new UAParser();
                            let os = uaParser.getOS();
                            let system_version = '1.0.0';
                            if (os.name && os.version) {
                                system_version = `${os.name}-${os.version}`;
                            }
                            console.log(params, 'params');
                            if (params.type === '2') {
                                params.logs = [];
                                params.pictures = [];
                            } else {
                                params.expression = '';
                            }
                            const app = getUserAppId();
                            return submitFeedback({
                                params: { ...params, appName, app, appVersion, system_version },
                            });
                        },
                        onCancel: () => {
                            destroy();
                        },
                        uploadImageControler: new UploadImageControler(appRuntimeEnv),
                        logFiles,
                        isHiddenSelectLog: !isFocusEnv(),
                        ossBaseUrl: getOSSBaseUrl(),
                    });
                    break;
                }
                case UserCenterItemType.About: {
                    setAboutModalVisible(true);
                    break;
                }
                case UserCenterItemType.Manager: {
                    // 管理员入口 废弃
                    let a: any = document.createElement('a');
                    let url = 'https://me-pre.jdcloud.com';
                    a.target = '_blank';
                    if (appRuntimeEnv.runtimeConfig.key === 'mebjJZB') {
                        url = 'https://jzb.jdcloud.com/';
                    } else if (appRuntimeEnv.env === 'beta') {
                        url = 'https://me-pre.jdcloud.com/admin';
                    } else {
                        url = 'https://me.jdcloud.com/admin';
                    }
                    a.href = url;
                    a.click();
                    break;
                }
                case UserCenterItemType.Logout:
                    FocusSDK.printLog('UserCenterItemType.Logout', 'logout');
                    focusSDK.logout();
                    break;
                default:
            }
        },
        [authData, t] //eslint-disable-line
    );
    const ToPerfect = useCallback(() => {
        setIdentityInfoModal(true);
        setVisible(false);
    }, [setIdentityInfoModal]);

    const handleAdministratorModal = useCallback(() => {
        setOpenAdministratorListModal(true);
        setVisible(false);
    }, [setOpenAdministratorListModal]);

    const onGetUserInfo = useCallback(
        async (params: {
            userId: string;
            teamId: string;
            appId: string;
        }): Promise<UserCardData> => {
            return service.authService.getUserProfile({ ...params, isMulti: true });
        },
        [] //eslint-disable-line
    );

    const onLogout = useCallback(() => {
        clearJoyspaceData();
        clearChatData();
        clearCalendarData();
        clearEbookData();
    }, [clearJoyspaceData, clearChatData, clearCalendarData, clearEbookData]);

    const userStatusClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        Bus.emit('workStatus:show');
    }, []);

    React.useEffect(() => {
        if (config && (!config.layout_user_center || !config.layout_user_center_card)) {
            return () => {};
        }
        if (onGetUserInfo) {
            const { userId, ddAppId } = authData;
            const { selectedTeamId } = appRuntimeEnv;
            onGetUserInfo({
                userId,
                teamId: selectedTeamId,
                appId: ddAppId,
            })
                .then((res) => {
                    try {
                        setWorkStatus(
                            res.workStatus ? JSON.parse(res.workStatus).workStatus : '暂未填写'
                        );
                    } catch (error) {}
                    setUserInfo(res);
                    let fields: { [propName: string]: any } = {};
                    (res.fields || []).forEach((fieldItem: any) => {
                        fields[fieldItem.key] = fieldItem.val;
                    });
                    updateCurrentEmployee(fields);
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [visible, authData, onGetUserInfo, config, appRuntimeEnv, updateCurrentEmployee]);

    useEffect(() => {
        focusSDK.on('logout', onLogout);
        return () => {
            focusSDK.off('logout', onLogout);
        };
    }, [onLogout]); //eslint-disable-line

    useEffect(() => {
        if (!userInfo || !userInfo.fields) return;
        let newAvatar = userInfo?.fields.find((item: any) => item.key === 'avatar')?.val;
        if (headImg !== newAvatar) {
            setHeadImg(newAvatar);
        }
        let newUserName = userInfo?.fields.find((item: any) => item.key === 'name')?.val;
        if (username !== newUserName) {
            setUsername(newUserName);
        }
        // getTeamAdmin().then((res: any) => {
        //     console.log(res.result.includes(1) || res.result.includes(3), 999);
        //     if (res.result.includes(1) || res.result.includes(3)) {
        //         setSuperAdmin(true);
        //     }
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const [sanding, setSanding] = useState({ content: '', title: '', visible: false });

    const userInfoCallback = useCallback(
        (info) => {
            const mainInfo = userInfo?.partTimeTitleDeptInfo
                ? userInfo?.partTimeTitleDeptInfo[0]
                : userInfo?.mainTitleDeptInfo;
            if (!['部门', '单位'].includes(info.key) || !userInfo || !mainInfo) return;

            if (mainInfo.unitIsClick && info.key === '单位') {
                getEbookDeptDesc(mainInfo.unitDeptId, userInfo.teamId).then((res: any) => {
                    setSanding({ content: res?.deptDesc || '', title: '单位', visible: true });
                });
            } else if (mainInfo.deptIsClick && info.key === '部门') {
                getEbookDeptDesc(mainInfo.deptId, userInfo.teamId).then((res: any) => {
                    setSanding({ content: res?.deptDesc || '', title: '部门', visible: true });
                });
            } else {
                message.destroy();
                message.warning({
                    content: `无${info.key}三定信息`,
                    icon: <IconFont type="iconlog_warning" style={{ color: '#F4B754' }} />,
                });
            }
        },
        [userInfo]
    );

    // 不展示用户中心所有功能
    if (config && !config.layout_user_center) {
        return null;
    }

    if (!authData) {
        return null;
    }

    let userInfolist = [];
    if (userInfo) {
        const { fields = [] } = userInfo;
        const deptInfo = userInfo?.partTimeTitleDeptInfo?.[0];

        // const dept = fields.find((item) => item.key === 'e_dept_full_name');
        // const unitName = fields.find((item) => item.key === 'unitName');
        // unitName?.val && userInfolist.push({ key: '单位', value: unitName.val });
        if (deptInfo?.unitName) userInfolist.push({ key: '单位', value: deptInfo?.unitName });
        if (deptInfo?.deptName) userInfolist.push({ key: '部门', value: deptInfo?.deptName });
        if (deptInfo?.titleName) userInfolist.push({ key: '职务', value: deptInfo?.titleName });
        if (deptInfo?.titleDutyName)
            userInfolist.push({ key: '职责', value: deptInfo?.titleDutyName });
        // if (dept?.val) {
        //     const depts = dept.val.split('-') || [];
        //     userInfolist.push({ key: '部门', value: depts[depts.length - 1] });
        // }
        // const position = fields.find((item) => item.key === 'e_position');
        // position?.val && userInfolist.push({ key: '职务', value: position.val });

        const mobile = fields.find((item) => item.key === 'mobile');
        mobile?.val && userInfolist.push({ key: '手机', value: mobile.val });

        const email = fields.find((item) => item.key === 'email');
        email?.val && userInfolist.push({ key: '邮箱', value: email.val });

        const cardNumber = fields.find((item) => item.key === 'cardNumber');

        // userInfo?.mainTitleDeptInfo?.titleDutyName &&
        //     userInfolist.push({ key: '北京通号', value: cardNumber?.val });
        (userInfo?.partTimeTitleDeptInfo?.[0].titleDutyName ||
            userInfo?.mainTitleDeptInfo?.titleDutyName) &&
            userInfolist.push({
                key: '职责',
                value:
                    userInfo?.partTimeTitleDeptInfo?.[0].titleDutyName ||
                    userInfo?.mainTitleDeptInfo?.titleDutyName,
            });
    }
    const changeAvatar = (file: any) => {
        const reader = new FileReader();
        const blob: any = file.getAsFile ? file.getAsFile() : file;
        reader.onload = (evt) => {
            if (!evt.target) return;
            const dataUrl: any = evt.target.result;
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {
                const task = createUploadTask('id', file, {
                    needAuthn: 1, // 是否需要下载健全，可选参数默认需要
                    fileName: file.name, // 文件名称
                    fileType: file.type || 'application/octet-stream', // 文件类型
                    fileSize: file.size, // 文件大小
                });
                task?.on('state', async (state1: any) => {
                    if (state1.state === 'completed') {
                        if (!state1.downloadUrl) return;
                        let avatar = state1.downloadUrl.includes('downloadAuthn')
                            ? state1.downloadUrl.replace('downloadAuthn', 'download')
                            : state1.downloadUrl;
                        setHeadImg(avatar);
                        changeAvatarReq({ avatar: changeGovUrl(avatar) })
                            .then((res) => {
                                const imService = ImService.getInstance();
                                imService.getEmployeeDetail({
                                    appId: currentEmployee.app || 'gwork',
                                    userId: currentEmployee.userId,
                                    teamId: currentEmployee.teamId,
                                });
                            })
                            .catch((e) => {
                                console.log(`getEmployeeDetail--err`, e);
                            });
                        // let teamUserInfoList = (authData.teamUserInfoList || []).map((listItem) => {
                        //     if (authData.selectedTeamId === listItem.teamId) {
                        //         listItem.headImg = avatar;
                        //     }
                        //     return listItem;
                        // });
                        // FocusSDK.setConfigData('auth', {
                        //     ...authData,
                        //     teamUserInfo: { ...authData.teamUserInfo, headImg: avatar },
                        //     teamUserInfoList,
                        // });
                    } else if (state1.state === 'failed') {
                        message.error('图片上传失败，请重试！');
                    }
                });
                task?.start();
            };
        };
        if (blob instanceof Blob) {
            reader.readAsDataURL(blob);
        }
    };
    const selectImg = () => {
        const input = document.createElement('INPUT');
        document.body.appendChild(input);
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        document.body.appendChild(input);
        input.onchange = (ev: any) => {
            if (ev.target.files) {
                changeAvatar(ev.target.files[0]);
            }
        };
        input.click();
        document.body.removeChild(input);
    };
    const body = (
        <div>
            <div className="avatar-wraps">
                <div className="avatar-wrap">
                    <Avatar
                        styleObj={{
                            width: 50,
                            height: 50,
                            borderRadius: 6,
                            overflow: 'hidden',
                            fontSize: 20,
                        }}
                        src={headImg ? changeEgovUrl(getHeadImgThumbUrl(headImg, 150, 150)) : ''}
                        name={username}
                        onClick={() => {
                            if (authData) {
                                const { ddAppId, userId } = authData;
                                if (teamUserInfo) {
                                    const { teamId } = teamUserInfo;
                                    onOpenUserCard({ appId: ddAppId, userId, teamId });
                                    setVisible(false);
                                }
                            }
                        }}
                    />
                    <span className="change-user-avatar" onClick={selectImg}>
                        <IconFont
                            style={{
                                fontSize: 17,
                                color: 'white',
                                backgroundColor: '#c2c2c3',
                                padding: '2px 5px',
                                borderTopLeftRadius: 5,
                                borderBottomRightRadius: 5,
                            }}
                            type="iconapp_btn_camera_4"
                        />
                    </span>
                </div>
                <div className="name-wrap">
                    <div>
                        <div className="nickname-box">
                            <h4
                                style={{
                                    margin: 0,
                                    color: '#242931',
                                    fontSize: 16,
                                    lineHeight: '28px',
                                }}
                            >
                                {username}
                            </h4>
                        </div>

                        <IconFont
                            type="iconbtn_tabbar_m"
                            onClick={doShowQRCode}
                            style={{ opacity: 0.5, marginLeft: 8, cursor: 'pointer' }}
                            className="qr-code"
                        />
                    </div>

                    <p
                        className="multiline-overflow-1 color-gray user-status"
                        onClick={userStatusClick}
                        style={{
                            margin: 0,
                            fontSize: 13,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        clstag="pageclick|keycount|focus_home_01_1615797361152|9"
                    >
                        <IconFont type="iconapp_btn_edit" style={{ marginRight: '4px' }} />
                        {workStatus || t('userCenter.No state')}
                    </p>
                </div>
            </div>

            <div>
                {loading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '200px',
                        }}
                    >
                        <Loading />
                    </div>
                )}
                {userInfolist.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="user-info-lists"
                            onClick={() => userInfoCallback(item)}
                        >
                            <span>{item.key}</span>
                            {item.key === '职责' ? (
                                <Tooltip placement="top" title={item.value} arrowPointAtCenter>
                                    <span className="titleDutyClassName">{item.value} </span>
                                </Tooltip>
                            ) : (
                                <span>
                                    {item.value || (
                                        <span
                                            onClick={debounce(() => {
                                                ToPerfect();
                                            }, 500)}
                                            style={{
                                                cursor: 'pointer',
                                                color: (window as any).styleSass.primaryColor,
                                            }}
                                        >
                                            去完善
                                        </span>
                                    )}
                                </span>
                            )}
                            {/* <span className={item.key === '职责' ? 'titleDutyClassName' : ''}>
                                {item.value || (
                                    <span
                                        onClick={debounce(() => {
                                            ToPerfect();
                                        }, 500)}
                                        style={{ cursor: 'pointer', color: '#4C7CFF' }}
                                    >
                                        去完善
                                    </span>
                                )}
                            </span> */}
                            {['部门', '单位'].includes(item.key) && <span>{IconArrowRight}</span>}
                        </div>
                    );
                })}
                <div style={{ color: '#8f959e', padding: '9px 24px' }}>
                    如果信息有误请您联系
                    <span
                        className="tip"
                        onClick={() => {
                            handleAdministratorModal();
                        }}
                    >
                        单位管理员
                    </span>
                </div>
            </div>
            {divider}
            {showJoinOrCreateTeam && (
                <Item
                    text={joinMeT(getTranslationName(appRuntimeEnv, 'joinOrCreate'))}
                    icon={IconGroup}
                    onClick={() => {
                        if (onItemClick) {
                            onItemClick(UserCenterItemType.JoinMe);
                        }
                        setVisible(false);
                    }}
                />
            )}

            <Item
                text={t('userCenter.feedback')}
                icon={IconRdit}
                onClick={() => {
                    if (onItemClick) {
                        onItemClick(UserCenterItemType.Feedback);
                    }
                    setVisible(false);
                }}
                clstag="pageclick|keycount|focus_home_01_1615797361152|7"
            />
            <Item
                text="系统设置"
                icon={IconSet}
                onClick={() => {
                    // FocusSDK.checkUrlOnline({
                    //     type: 'zoomNet',
                    //     isPrivateNet: FocusSDK.isPrivateNet(),
                    // });
                    setVisible(false);
                    history.push('/systemSetting');
                }}
                clstag="pageclick|keycount|focus_home_01_1615797361152|8"
            />
            {/* <Item
                text="网络检测"
                icon={<GlobalOutlined />}
                onClick={() => {
                    FocusSDK.emit('showNetTable');
                }}
            /> */}
            {/* <Item
                text={t('userCenter.about')}
                icon={IconFailure}
                onClick={() => {
                    if (onItemClick) {
                        onItemClick(UserCenterItemType.About);
                    }
                    setVisible(false);
                }}
                clstag="pageclick|keycount|focus_home_01_1615797361152|8"
            />
            {/* {superAdmin && (
                <Item
                    text={t('userCenter.manager')}
                    icon={IconFailure}
                    onClick={() => {
                        if (onItemClick) {
                            onItemClick(UserCenterItemType.Manager);
                        }
                        setVisible(false);
                    }}
                />
            )} */}
            {divider}
            <Item
                key="userCenter.logout"
                withArrow={false}
                text={t('userCenter.logout')}
                className="logout-btn"
                icon={IconExit}
                onClick={() => {
                    if (onItemClick) {
                        onItemClick(UserCenterItemType.Logout);
                    }
                    // setVisible(false);
                }}
            />
            {/* 关于我们 */}
            <AboutModal
                service={service}
                visible={isAboutModalVisible}
                onCancel={() => setAboutModalVisible(!isAboutModalVisible)}
            />
            {/* 二维码 */}
            {visibleQRCode && <PersonalQRCode visible={visibleQRCode} onCancel={doSwitchQRCode} />}
        </div>
    );

    const popperBody = (
        <div
            className="userCenterPopper"
            ref={setPopperElement as any}
            style={{
                ...popperStyles.popper,
                display: visible ? 'block' : 'none',
            }}
            {...attributes.popper}
        >
            {body}
        </div>
    );

    const openUserCard = () => {
        if (!config || config?.layout_user_center_card) {
            if (!visible) {
                Bus.emit('onHideGlobalSearch');
                Bus.emit('handleBigScreenHideGlobalSearch');
            }
            setVisible(!visible);
        }
    };

    return (
        <div style={{ flexBasis: 55 }}>
            {visible && (
                <div
                    onClick={() => {
                        setVisible(false);
                    }}
                    style={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 100,
                    }}
                />
            )}
            {(!config || config?.layout_user_center_head_img) && (
                <div
                    className="userCenter no-draggable"
                    style={{ zIndex: 100, position: 'relative' }}
                >
                    <a
                        ref={setReferenceElement as any}
                        className="userAvatar"
                        onClick={openUserCard}
                    >
                        <Avatar
                            styleObj={{
                                width: 33,
                                height: 33,
                                overflow: 'hidden',
                            }}
                            src={changeEgovUrl(getHeadImgThumbUrl(headImg || '', 150, 150))}
                            name={username}
                        />
                        {notify && <span className="userCenterNotify" />}
                    </a>
                </div>
            )}
            {/* {superAdmin && popperBody} */}
            {popperBody}
            <Modal
                centered
                width="640px"
                style={{ height: 480, overflow: 'hidden' }}
                bodyStyle={{ padding: '0 0 18px' }}
                title={sanding.title + '三定'}
                visible={sanding.visible}
                footer={null}
                onCancel={() => {
                    setSanding({ visible: false, content: '', title: '' });
                }}
            >
                <div className="sanding-wrap">
                    <p>{sanding.content}</p>
                </div>
            </Modal>
        </div>
    );
}

export default connect(
    ({ chat }: { chat: ChatState }) => {
        return {
            currentEmployee: chat?.currentEmployee ? chat?.currentEmployee : {},
        };
    },
    (dispatch: any) => ({
        clearJoyspaceData() {
            dispatch({ type: 'joyspace/clearData', payload: {} });
        },
        getWebUserData(data: { teamUserInfo: UserModel }) {
            dispatch({ type: 'user/getWebUserData', payload: { userData: data } });
        },
        clearChatData: () => {
            dispatch({ type: 'chat/clearData' });
        },
        clearCalendarData: () => {
            dispatch({ type: 'calendar/clearData' });
        },
        clearEbookData() {
            dispatch({ type: 'ebook/clearEbookData' });
        },
        setIdentityInfoModal(payload: boolean) {
            dispatch({ type: 'chat/setIdentityInfoModal', payload });
        },
        updateCurrentEmployee(payload: any) {
            dispatch({ type: 'chat/updateCurrentEmployee', payload });
        },
        setOpenAdministratorListModal(payload: any) {
            dispatch({ type: 'ebook/setOpenAdministratorListModal', payload });
        },
    })
)(UserCenter);
