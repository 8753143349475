import React, { useState, useMemo, useEffect } from 'react';
import './index.less';
import { Popover } from 'antd';
import IconFont from '@/components/icon';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import BaseCalendar from '@/components/Calendars/BaseCalendar';
import TimePicker from '@/components/Calendars/TimePicker';
import { useDefaultFormatTime } from '@/components/Work/utils';

function getStartTimeList() {
    let today = dayjs().startOf('date');
    let hourList = Array.from(new Array(144).keys());
    let data = hourList.map((item, index) => {
        let time = today.add(index * 10, 'minute');
        return {
            value: time,
            title: time.format('HH:mm'),
        };
    });
    return data;
}
function getEndTimeList() {
    let today = dayjs().startOf('date');
    let hourList = Array.from(new Array(144).keys());
    let data = hourList.map((item, index) => {
        let time = today.add((index + 1) * 10, 'minute');
        let title = time.format('HH:mm');
        return {
            value: time,
            title: title === '00:00' ? '24:00' : title,
        };
    });
    return data;
}
const startTimeList = getStartTimeList();
const endTimeList = getEndTimeList();

function CommonDatePicker({
    time,
    onChange,
    disable,
    placeholder,
    defaultTime,
    endLogic,
    isEnd,
    children,
    placement,
}: {
    time: Dayjs | null;
    onChange?: (time: Dayjs | null) => void;
    disable?: boolean;
    placeholder?: string;
    defaultTime?: Dayjs;
    endLogic?: boolean; // 是否启用末时制，即 当晚0点显示 今天24:00. 对于截止时间的显示很有效果
    isEnd?: boolean; // 截止时间 或 开始时间
    children: any;
    placement?:
        | 'bottom'
        | 'top'
        | 'left'
        | 'right'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | 'rightBottom'
        | undefined;
}) {
    const [visible, setVisible] = useState(false);
    const [selectedTime, setSelectedTime] = useState(formatInitTime(time));

    const [date, detailTime] = useMemo(() => {
        if (endLogic && selectedTime.hour() === 0 && selectedTime.minute() === 0) {
            return [selectedTime.subtract(1, 'day').format('YYYY/MM/DD'), '24:00'];
        }
        return [selectedTime.format('YYYY/MM/DD'), selectedTime.format('HH: mm')];
    }, [endLogic, selectedTime]);

    function formatInitTime(time: Dayjs | null) {
        let current = time || defaultTime || dayjs();
        const minute = Math.floor(current.minute() / 10) * 10;
        current = current.startOf('hour');
        current = current.minute(minute);
        return current;
    }

    function handleSelectedChange(selected: Dayjs) {
        if (endLogic && selectedTime.hour() === 0 && selectedTime.minute() === 0) {
            let current = selected.hour(selectedTime.hour());
            current = current.minute(selectedTime.minute());
            current = current.add(1, 'day');
            setSelectedTime(current);
            return;
        }
        let current = selected.hour(selectedTime.hour());
        current = current.minute(selectedTime.minute());
        setSelectedTime(current);
    }
    function handleDetailTimeChange(item: any, index: number) {
        const time = item.value;
        if (endLogic && time.hour() === 0 && time.minute() === 0) {
            const temp = selectedTime.startOf('day').add(1, 'day');
            setSelectedTime(temp);
            return;
        }
        // 如果选中时间默认是00:00， 由于特殊处理，时间已经加了一天了，所以，从00:00 也就是 24:00 变化为其他时间时，需要先减去一天
        if (endLogic && selectedTime.hour() === 0 && selectedTime.minute() === 0) {
            const temp = selectedTime.subtract(1, 'day').hour(time.hour()).minute(time.minute());
            setSelectedTime(temp);
            return;
        }
        const temp = selectedTime.hour(time.hour()).minute(time.minute());
        setSelectedTime(temp);
    }
    const [t2] = useTranslation('common');
    const { t } = useTranslation('work');
    function handleCancel() {
        setVisible(false);
    }

    function handleClick() {
        if (time?.isSame(selectedTime)) {
            return;
        }
        if (onChange) {
            onChange(selectedTime);
        }
        setVisible(false);
    }

    function getContent() {
        return (
            <div className="work-date-picker-content">
                <div className="picker-title">
                    <div className="item date">{date}</div>
                    <TimePicker
                        className="mr8"
                        current={selectedTime}
                        data={endLogic ? endTimeList : startTimeList}
                        handleSelect={handleDetailTimeChange}
                    >
                        <div className="item time">
                            <span>{detailTime}</span>
                            <IconFont type="iconic_time_normal" />
                        </div>
                    </TimePicker>
                </div>
                <BaseCalendar
                    changeHandle={handleSelectedChange}
                    time={endLogic ? selectedTime.subtract(1, 'millisecond') : selectedTime}
                    noFold={true}
                />
                <div className="work-date-picker-footer">
                    <button className="work-date-picker-footer-cancel" onClick={handleCancel}>
                        {t2('button.cancel')}
                    </button>
                    <button
                        className="work-date-picker-footer-ok"
                        disabled={!visible}
                        onClick={handleClick}
                    >
                        {t2('button.ok')}
                    </button>
                </div>
            </div>
        );
    }
    function handleVisibleChange(visible: boolean) {
        if (disable) {
            return;
        }
        setVisible(visible);
        if (visible) {
            setSelectedTime(formatInitTime(time));
        }
        // if (!visible) {
        //     if (time.isSame(selectedTime)) {
        //         return;
        //     }
        //     if (onChange) {
        //         onChange(selectedTime);
        //     }
        // }
    }

    function clearTime(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        e.preventDefault();
        if (onChange) {
            onChange(null);
        }
    }

    const label = useDefaultFormatTime(time, endLogic);
    return (
        <Popover
            trigger="click"
            placement={placement || 'bottom'}
            content={getContent()}
            visible={visible}
            overlayClassName="work-date-picker-popover"
            onVisibleChange={handleVisibleChange}
        >
            {children}
        </Popover>
    );
}

export default CommonDatePicker;
