/* eslint-disable no-console */
/**
 * 文本消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { MessageSendType } from '@/types/chat/enum';
import './index.less';
import { MessageStatus, SessionType, TextChatMessageExt } from '@/types/chat';
import ChatState, { UserState } from '@/types/chat/State';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { UserCardPayload } from '@/models';
import { getTextMessageContent } from '@/utils/chat/commonMessageModal';
import { UserCardType, UserCardData, TitleDeptInfo } from '@/types/UserCard';
import Context from '@/context/ChatContext';
import { EBookSubDeptInfo } from '@/models/ebook';
import ReplyMessage from '@/baseComponents/Chat/message/ReplyMessage';
import { convertContentToArray, MessageCardType } from '@/components/chat/utils/message';
import JoyspaceTopic from '@/components/chat/message/message-components/JoyspaceTopic';
import ShortLink from '@/components/chat/message/message-components/ShortLink';
import { handleOpenMentionUserInfo } from '@/utils/chat/index';
interface TextProps {
    message: TextChatMessageExt; // 发送消息内容
    sessionType: SessionType;
    sendType: MessageSendType;
    beginSend: boolean;
    messageExtend: any;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

interface DvaDispatchProps {
    unLock: Function;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    getEbookDeptInfo: (params: any) => void;
}

type ITextProps = Readonly<
    TextProps & UserState & DvaDispatchProps & ChatState & EBookSubDeptInfo & UserCardData
>;
function mapStateToProps({ chat }: { chat: ChatState }) {
    const { currentEmployee, selectedSession, connectState } = chat;
    return { userInfo: currentEmployee, currentEmployee, selectedSession, connectState };
}
function Text(props: ITextProps) {
    const { sendType, message, onReady, selectedSession } = props;
    const { groupRosterIdentity, currentEmployee, userData } = React.useContext(Context);
    const { t } = useTranslation('chat');

    const realDisplayContent = useMemo(() => {
        if (message.displayContent && message.displayContent.length > 0) {
            return message.displayContent;
        }
        return convertContentToArray(message.content);
    }, [message]);

    // console.log('realDisplayContent', realDisplayContent);
    const getClass = useCallback(() => {
        return classNames({
            message: true,
            'text-message': true,
            // owner: sendType === MessageSendType.OWNER,
        });
    }, []);

    useEffect(() => {
        if (
            (sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF) &&
            message.status === MessageStatus.INIT
        ) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);

    return (
        <div className="content">
            <div
                className={getClass()}
                onClick={(e) =>
                    handleOpenMentionUserInfo(e, t, {
                        currentEmployee,
                        selectedSession,
                        groupRosterIdentity,
                    })
                }
            >
                <ReplyMessage message={message} sendType={sendType} />
                {/* <p
                    style={{
                        wordBreak: 'break-word',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: getTextMessageContent(message, currentEmployee),
                    }}
                /> */}
                {realDisplayContent.map((item, index) => {
                    // console.log(item, 'itemitemitemitemitemitem');
                    return (
                        <div key={index} style={{ margin: '1px 0' }}>
                            {item.type === MessageCardType.Text ? (
                                <div
                                    className={`top-box ${
                                        selectedSession.isSecret ? 'top-box_isSecret' : ''
                                    }`}
                                    dangerouslySetInnerHTML={{
                                        __html: getTextMessageContent(
                                            { ...message, content: item.content },
                                            currentEmployee
                                        ).trim(),
                                    }}
                                />
                            ) : item.type === MessageCardType.JoyspaceTopic ? (
                                <JoyspaceTopic content={item} {...props} />
                            ) : (
                                <ShortLink shortLinkItem={item} isFusion={true} />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        getEbookDeptInfo(data: any) {
            dispatch({ type: 'ebook/getEbookDeptInfo', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Text);
