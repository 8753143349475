// 通讯录菜单标识
export enum AddressNavKey {
    DEFAULT = 'default',
    NEW_CONTACTS = 'new_contacts', // 新的联系人
    SPECIAL_PROJECTS = 'special_projects', // 我的专班
    GROUP = 'group', // 群组
    ORG = 'org', // 组织架构
    VIRTUAL_ORG = 'virtual_org', // 组织架构
    EXTERNAL_CONTACTS = 'external_contacts', // 新的联系人
    ADD_EXTERNAL_CONTACTS_GROUP = 'add_external_contacts_group', // 新的联系人
    MY_DEPARTMENT = 'my_department', // 我的部门
    MY_LABELS = 'my_labels',
}

// 通讯录搜索类型
export enum AddressSearchKey {
    CONTACTS = 'contacts',
    GROUP = 'group',
}

export interface SearchResultModel {
    app: string;
    avatar: string | null;
    deptId: string | null;
    deptName: string | null;
    email: string | null;
    emplAccount: string;
    employeeId: string | null;
    mobile: string | null;
    realName: string;
    teamId: string;
    titleId: string | null;
    titleName: string | null;
    userCode: string | null;
    userId: string | null;
}

export const ZoomMeetingStatus = {
    MEETING_STATUS_IDLE: 0, // Idle status, no meeting running
    MEETING_STATUS_CONNECTING: 1, // Connecting meeting server status
    MEETING_STATUS_WAITINGFORHOST: 2, // Waiting for host to start meeting
    MEETING_STATUS_INMEETING: 3, // Meeting is ready, in meeting status
    MEETING_STATUS_DISCONNECTING: 4, // Disconnecting meeting server status
    MEETING_STATUS_RECONNECTING: 5, // Reconnecting meeting server status
    MEETING_STATUS_FAILED: 6, // Meeting connection error
    MEETING_STATUS_ENDED: 7, // Meeting is ended
    MEETING_STATUS_UNKNOW: 8,
    MEETING_STATUS_LOCKED: 9,
    MEETING_STATUS_UNLOCKED: 10,
    MEETING_STATUS_IN_WAITING_ROOM: 11,
    MEETING_STATUS_WEBINAR_PROMOTE: 12,
    MEETING_STATUS_WEBINAR_DEPROMOTE: 13,
    MEETING_STATUS_JOIN_BREAKOUT_ROOM: 14,
    MEETING_STATUS_LEAVE_BREAKOUT_ROOM: 15,
    MEETING_STATUS_AUDIO_READY: 16,
    MEETING_STATUS_OTHER_MEETING_INPROGRESS: 17,
    MEETING_STATUS_WAITING_EXTERNAL_SESSION_KEY: 18, // Waiting for the additional secret key
};

export enum ZoomSessionStateEnum {
    Call = 0, // 被叫
    Cancel = 1, // 呼叫方取消
    Ringing = 180, // 用户振铃 这个是反向发送消息
    UserReceive = 200, // 某个端用户接受了会邀
    UserReject = 486, // 某个端用户拒绝
}

// export interface IPermissions {
//     authorityType: number;
//     authorityTypeName: string;
//     resources: {
//         appId: string;
//         code: string;
//         desc: string;
//         name: string;
//         parentId: string;
//         resourceId: string;
//         url: string;
//     }[];
// }

export interface IPermissions {
    code: string;
    text: string;
}

/**
 * 资源对应的id，用来判断权限
 */
export enum AuthorResourceEnum {
    VOIP = 'audioVideo.initiateVoip',
    MEETING = 'audioVideo.addMeeting', // 创建音视频权限
    MEETING_JOIN = 'audioVideo.joinMeeting', // 参加音视频会议
    ORG = 'addressBook.readInstitution', // 通讯录组织架构
    MY_DEPARTMENT = 'addressBook.readApartment', // 通讯录我的部门
    ADD_CONTACTS = 'addressBook.addContact', // 搜索添加联系人
    CREAT_GROUP = 'group.addGroup', // 创建群组
    CREAT_SCHREDULE = 'calendar.addCalendarSchedule', // 新建日程
    CREAT_TASK = 'task.addTask', // 新建任务
    CREAT_URGING = 'fckAeBz1lGcQ7zRz1Atn', // 新建催办
    CREAT_DOCUMENT = 'document.addDoc', // 新建文档
    EXPORT_DOCUMENT = 'document.exportDoc', // 导出文档
    SEARCH_DEPARTMENT = 'search.searchDepart', // 搜索.搜索支持部门
    // VOIP = 'ylQGvzLFBqGqnK8gJUsm',
    // MEETING = 'giYdL5q4pDPYxR2bd7kW',
    // MEETING_JOIN = 'EgERRhFrYRbUUTcsiibf', // 参加音视频会议
    // ORG = 'AvrtZko8l9DX7tPu9gr7', // 组织架构通讯录
    // MY_DEPARTMENT = '31LaIOVsxsxQ0HNZeoyA', // 我的部门
    // ADD_CONTACTS = 'NY1fqiQySrJQJFK7tuxs', // 搜索添加联系人
    // CREAT_GROUP = 'dbtc0niuZWiWIJSYcPLz', // 创建群组
    // CREAT_SCHREDULE = 'oOuXmoQ5dvyyCzzjw4pL', // 新建日程
    // CREAT_TASK = '6qB0Yxd9BDjcrxNAI3RH', // 新建任务
    // CREAT_URGING = 'fckAeBz1lGcQ7zRz1Atn', // 新建催办
    // CREAT_DOCUMENT = 'IgOIHUrIKMzQeYnYdYsS', // 新建文档
    // EXPORT_DOCUMENT = 'SyxNs9cPhkE9kRlVRrUk', // 导出文档
}

/**
 * 会议查询类型
 */
export enum MeetingQueryType {
    ONGOING = 'ONGOING',
    UPCOMING = 'UPCOMING',
    ONGOING_AND_UPCOMING = 'ONGOING_AND_UPCOMING',
    CLOSED = 'CLOSED',
}

export enum MeetingPlatform {
    CLOUD_UNION = 'CLOUD_UNION', // // 云联
    HW = 'HW', // 华为音视频
    XYLINK = 'XY_LINK', // 小鱼会议
    JBRTC = 'JDTC', // 京东会议
}

export const MeetingPlatformName = {
    CLOUD_UNION: '视频会议', // // 云联
    HW: '信创视频会议', // 华为音视频
    XY_LINK: '政务视频会议', // 小鱼会议
    JDTC: '智能会议', // 京东会议
};
