import React from 'react';
import './index.less';
import CustomScrollView from 'react-custom-scrollbars';
import { isIE } from '@/utils';

export function isLowFirefox() {
    let ua = navigator.userAgent;
    if (ua.indexOf('Firefox') > -1) {
        if ('scrollbarWidth' in document.documentElement.style) {
            return false;
        }
        return true;
    }
    return false;
}

function ScrollView(opts: any, ref: any = null) {
    // const onScroll = (e: any) => {
    //     if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
    //         // 滚动到底部需要做的事情
    //     }
    //     if (opts.onScroll) {
    //         opts.onScroll(e);
    //     }
    // };
    const defaultStyle = {
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        overflow: isLowFirefox() ? 'auto' : 'scroll',
        marginRight: isIE() ? '-18px' : '-8px',
        marginBottom: isIE() ? '-18px' : '-8px',
        ...opts.style,
    };

    return (
        <CustomScrollView
            ref={ref}
            autoHide={true}
            renderTrackVertical={() => (
                <div
                    id="message-box-container-basScroll"
                    style={{
                        position: 'absolute',
                        width: '8px',
                        transition: 'opacity 200ms ease 0s',
                        opacity: '0',
                        right: '2px',
                        bottom: '2px',
                        top: '2px',
                        borderRadius: '3px',
                        zIndex: 99,
                    }}
                />
            )}
            renderTrackHorizontal={() =>
                opts.nohorizontal ? (
                    <div />
                ) : (
                    <div
                        style={{
                            position: 'absolute',
                            height: '8px',
                            transition: 'opacity 200ms ease 0s',
                            opacity: '0',
                            right: '2px',
                            bottom: '2px',
                            left: '2px',
                            borderRadius: '3px',
                            zIndex: 99,
                        }}
                    />
                )
            }
            renderView={(props) => (
                <div
                    {...props}
                    className="scroll-view-content"
                    style={
                        opts.nohorizontal ? { ...defaultStyle, overflowX: 'hidden' } : defaultStyle
                    }
                />
            )}
            {...opts}
        />
    );
}

export default React.forwardRef(ScrollView);
