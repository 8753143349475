import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import logger from '@/utils/logger';
import GlobalContext from '@/context/GlobalContext';
// import addressBookImg from '@/assets/user-guide/address-book.png';
import './index.less';

import ImService from '@/server/ImService';
import { FocusSDK, isFocusEnv } from '@/utils';
import { getAppVersion } from '@/utils/getVersion';
import { duccConfigI18 } from '@/utils/i18n';

import { getEbookUnitDeptAndInnerDeptInfo } from '@/api/ebook';

const { REACT_APP_CHECK_UP } = process.env;
const focusEnv = isFocusEnv();
const currentPlatform = FocusSDK.getDeviceInfo().platform;

const log = logger.getLogger('UserGuideModal');

export default function UserGuideModal() {
    const [visible, setVisible] = useState(false);
    const [titleList, setTitleList] = useState<Array<string>>([]);
    const [tipTextList, setTipTextList] = useState<Array<string>>([]);
    const [current, setCurrent] = useState(0);
    const { notifyDis, notifyStatus } = useContext(GlobalContext);
    const [unitFullName, setUnitFullName] = useState<String>('北京市-北京市人民政府');
    const getOffsetTop = useCallback(() => {
        return focusEnv
            ? currentPlatform === 'win32'
                ? 78
                : 96
            : notifyStatus === 'granted'
            ? 78
            : notifyDis
            ? 117
            : 78;
    }, [notifyStatus, notifyDis]);
    const defaultPositionTop = getOffsetTop();
    const [positionTop, setPositionTop] = useState(defaultPositionTop);
    const tipsTotal = 6;
    const tipsBoxOffsetStep = focusEnv ? 61 : 63;
    const { t } = useTranslation('common');

    useEffect(() => {
        console.log('notifyDis---', notifyDis);
        console.log('notifyStatus---', notifyStatus);
        console.log('defaultPositionTop---', defaultPositionTop);
        const titleData = [
            t('userGuide.message'),
            t('userGuide.addressBook'),
            t('userGuide.task'),
            t('userGuide.calendar'),
            t('userGuide.cloudDocument', {
                joyspace: duccConfigI18.joyspace,
            }),
            t('userGuide.workTable'),
        ];
        const tipTextData = [
            t('userGuide.tipsList.messageTips'),
            t('userGuide.tipsList.addressBookTips1'),
            t('userGuide.tipsList.taskTips'),
            t('userGuide.tipsList.calendarTips'),
            t('userGuide.tipsList.cloudDocumentTips', {
                joyspace: duccConfigI18.joyspace,
            }),
            t('userGuide.tipsList.workTableTips'),
        ];
        setTitleList(titleData);
        setTipTextList(tipTextData);
    }, [t, notifyDis, notifyStatus, defaultPositionTop]);

    const getEbookUnitDept = async function () {
        const [result, err] = await getEbookUnitDeptAndInnerDeptInfo();
        if (err) {
            return;
        }
        setUnitFullName(
            result && result.length > 0 ? result[0].unitFullName : '北京市-北京市人民政府'
        );
    };
    const checkNewVersion = async () => {
        console.log('checkNewVersion被调用***', FocusSDK.getDeviceInfo().platform);
        const isWeb = FocusSDK.getDeviceInfo().platform === 'web';
        if (isWeb) {
            const isShowUserGuide = localStorage.getItem('isShowUserGuide');
            if (!isShowUserGuide) {
                setVisible(true);
                localStorage.setItem('isShowUserGuide', 'true');
            }
            return;
        }
        console.log('isweb后调用');
        const imService = ImService.getInstance();
        const version = getAppVersion(FocusSDK);
        const isUp = await imService?.isAppUpgraded(version);
        log.debug('version-', version);
        log.debug('isUp-', isUp);
        // if (REACT_APP_CHECK_UP && REACT_APP_CHECK_UP === 'true' && isUp) {
        //     setVisible(true);
        // }
        if (isUp) {
            setVisible(true);
        }
    };

    useEffect(() => {
        getEbookUnitDept();
        checkNewVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSkip = async () => {
        const imService = ImService.getInstance();
        const version = getAppVersion(FocusSDK);
        await imService?.setAppVersion(version);
        setVisible(false);
    };

    const nextStep = useCallback(() => {
        let count = current;
        let currentPositionTop = positionTop;
        setCurrent(count + 1);
        setPositionTop(currentPositionTop + tipsBoxOffsetStep);
    }, [current, positionTop, tipsBoxOffsetStep]);

    const getFooter = useMemo(() => {
        const isEnd = current === tipsTotal - 1;
        return (
            <div className="footer">
                {!isEnd && <Button onClick={onSkip}>{t('userGuide.skip')}</Button>}
                <Button className="blue-btn" onClick={() => (isEnd ? onSkip() : nextStep())}>
                    {isEnd ? t('userGuide.complete') : t('userGuide.nextStep')}
                </Button>
            </div>
        );
    }, [current, nextStep, t]);

    const getHeader = useMemo(() => {
        return (
            <div className="header">
                <h2>{titleList[current]}</h2>
                <div className="msg-number" onClick={onSkip}>
                    {current + 1}/{tipsTotal}
                </div>
            </div>
        );
    }, [titleList, current]);

    return (
        <Modal
            title={t('newVersion.title')}
            width={400}
            centered
            destroyOnClose
            maskClosable={false}
            closable={false}
            visible={visible}
            onCancel={onSkip}
            wrapClassName="user-guide-wrapper"
            footer={null}
            style={{ top: `${positionTop}px` }}
        >
            {getHeader}

            <div className="triangle-arrow-left" />
            <div className="explain-body">
                {current === 1 ? (
                    <>
                        <p>
                            {tipTextList[current]}
                            <br />
                            {t('userGuide.tipsList.addressBookTips2')}
                            <br />
                            {t('userGuide.tipsList.addressBookTips3')}
                        </p>
                        <div className="img-content">
                            {/* <img src={addressBookImg} width="100%" /> */}
                            <p className="title">组织结构</p>
                            <p className="content">
                                <span className="span-box">
                                    <span className="span1">
                                        {unitFullName.split('-')[0] + ' > '}
                                    </span>
                                    <span className="span2">{unitFullName.split('-')[1]}</span>
                                </span>
                            </p>
                        </div>
                    </>
                ) : (
                    <p>{tipTextList[current]}</p>
                )}
            </div>
            {getFooter}
        </Modal>
    );
}
