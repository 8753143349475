import React, { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import IconFont from '@/components/icon';
import { connect } from 'dva';
import './index.less';
import { dealImagesData } from '@/components/chat/utils/image';
import { FocusSDK } from '@/utils';

function ScreenCapture({
    children,
    captureType,
    setCaptureType,
    file,
}: {
    children: any;
    captureType: string;
    setCaptureType: Function;
    file: any;
}) {
    useEffect(() => {
        // const calendarImService: CalendarImServiceType = CalendarImService.getInstance();
        let config = JSON.parse(FocusSDK.getConfigData());
        setCaptureType({ captureType: config.captureType || '1' });
    }, [setCaptureType]);

    useEffect(() => {
        if (file) {
            dealImagesData(file).then((res) => {
                console.log(res);
            });
        }
    }, [file]);

    const handleMenuClick = ({ key }: any) => {
        FocusSDK.setConfigData('captureType', key);
        setCaptureType({ captureType: key });
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" className="menu-item">
                {captureType === '1' && <IconFont type="iconbtn_check" />}
                <span className="text">截图隐藏当前窗口</span>
            </Menu.Item>
            <Menu.Item key="2" className="menu-item">
                {captureType === '2' && <IconFont type="iconbtn_check" />}
                <span className="text">不隐藏当前窗口</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {children}
            <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                <IconFont type="iconic_joyday_title_dropdown" className="dropdown-icon" />
            </Dropdown>
        </>
    );
}
function mapStateToProps({ chat }: any) {
    return {
        captureType: chat.captureType,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        async setCaptureType(data: { captureType: string }) {
            await dispatch({ type: 'chat/setCaptureType', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCapture);
