// 组通知
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Drawer, Input, Space, Button, message as toast, Select } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { debounce } from 'lodash';
import History from './components/history';
import Attachment, { FileStatus } from '@/components/Calendars/Attachment';
import { createOrUpdateNotification } from '@/api/notification';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import bus from '@/utils/bus';
import { buildGroupNoticeMessageBody } from '@/utils/chat/message';
import { Employee, Session, MessageEmitCategory } from '@/types/chat';
import ImService from '@/server/ImService';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import { shareJoyspaceFiles } from '@/components/Joyspace/api/page';
import CopyClipboard from 'copy-to-clipboard';
import IconFont from '@/components/icon';
import { isFocusEnv } from '@/utils';
import CopyFrame from '@/components/CopyFrame';

const { TextArea } = Input;
const { Option } = Select;
interface IPropss {
    defaultList?: any[];
    className?: string;
}
interface typeMSG {
    selectedSession: Session;
    currentEmployee: Employee;
}
let attachMentList: any[] = [];
type IProps = Readonly<IPropss & typeMSG>;
function GroupNotificationBox(props: IProps) {
    const { focusSDK, appRuntimeEnv, authInfo } = useContext<GlobalContextConfig>(GlobalContext);
    const { selectedSession, currentEmployee } = props;
    const [visible, setVisible] = useState(false);
    const [Filevisible, setFilevisible] = useState(false);
    const [isvisible, setIsVisible] = useState(false);
    const [content, setContent] = useState('');
    const [isloading, setIsloading] = useState(false);
    const [ContentFouse, setContentFouse] = useState(false);
    const [NewCreate, setNewCreate] = useState(false);
    const [updFilevisible, setupdFilevisible] = useState(false);
    const [sessionId, setSessionId] = useState<any>('');
    const [optionContent, setOptionContent] = useState<any>('收到');
    const [percent, setPercent] = useState(0);
    const imService = ImService.getInstance();
    const [t] = useTranslation('chat');

    const showDrawer = (data: any) => {
        setSessionId(data.selectedSession.sessionId);
        setVisible(true);
        setNewCreate(true);
    };

    const onTextArea = (e: any) => {
        setContent(e.target.value);
    };

    const deleteAttr = useCallback((message: any) => {
        delete message.status;
        delete message.statusType;
    }, []);
    const onClose = useCallback(() => {
        setVisible(false);
        setIsloading(false);
        setNewCreate(false);
        setContent('');
        setPercent(0);
        attachMentList = [];
        bus.emit('Calendars:Attachment', visible);
    }, [visible]);

    const fileVisible = (progress: any) => {
        if (progress < 100) {
            setupdFilevisible(true);
        } else {
            setupdFilevisible(false);
        }
    };

    useEffect(() => {
        focusSDK.off('openGroupNotClick', showDrawer).on('openGroupNotClick', showDrawer);
        bus.off('chat:message-groupnotification').on('chat:message-groupnotification', fileVisible);
        return () => {
            focusSDK.off('openGroupNotClick', showDrawer);
            bus.off('chat:message-groupnotification', fileVisible);
        };
    }, [focusSDK]);

    const sendMessages = useCallback(async () => {
        if (updFilevisible) {
            toast.warning('附件正在上传中，请您稍后再试');
            return;
        }
        if (content === '') {
            toast.error('内容不能为空');
            return;
        }
        setIsloading(true);
        const instance = ImService.getInstance();
        const newDate = new Date().getTime();
        const id = generateMessageId();

        const datas = buildGroupNoticeMessageBody({
            content,
            currentEmployee,
            data: {
                optionList: [
                    {
                        optionContent: optionContent,
                        optionId: '',
                        sort: 1,
                    },
                ],
                notificationId: '',
                attachmentList: attachMentList,
                creatorName:
                    currentEmployee.name || authInfo?.teamUserInfo?.realName || authInfo?.realName,
                creatorUserId: currentEmployee.userId,
                userList: [
                    {
                        pin: currentEmployee.userId,
                        name:
                            currentEmployee.name ||
                            authInfo?.teamUserInfo?.realName ||
                            authInfo?.realName,
                        teamId: currentEmployee.teamId,
                        app: currentEmployee.app,
                        optionId: '',
                        timestamp: newDate,
                    },
                ],
            },
        });
        console.log('datas~~~', datas);
        const messageOpts = await instance?.getMessageOptions();
        datas.id = messageOpts.id;
        const messages = {
            ...messageOpts,
            body: { ...datas },
            gid: sessionId,
            type: MessageEmitCategory.CHAT_MESSAGE,
        };
        deleteAttr(messages);
        if (attachMentList.length > 0) {
            attachMentList?.map((item) => {
                if (item.fileType.startsWith('joyspace')) {
                    shareJoyspaceFiles({
                        page_ids: [item.fileId],
                        to_eepaas_users: [],
                        to_groups: [
                            {
                                gid: sessionId,
                                permission_type: item.permission || 2,
                            },
                        ],
                    });
                } else {
                    return;
                }
            });
        }
        const [data, err] = await createOrUpdateNotification({
            notificationId: '',
            content: content,
            sourceType: 1,
            sourceBizId: sessionId,
            status: 0,
            messageId: id,
            optionList: [
                {
                    optionId: '',
                    optionContent: optionContent,
                    sort: 1,
                },
            ],
            attachmentList: [...attachMentList] as any,
            groupChatMessage: messages,
        });
        if (err) {
            toast.error(err);
            setContent('');
            setIsloading(false);
        }
        if (data) {
            attachMentList = [];
            onClose();
        }
    }, [
        content,
        currentEmployee,
        optionContent,
        sessionId,
        deleteAttr,
        onClose,
        authInfo,
        updFilevisible,
    ]);

    const openHistory = () => {
        setIsVisible(true);
        onClose();
    };

    const attachmentChange = (data: any) => {
        let newAttachment = data.map((item: any) => {
            return {
                notificationId: '',
                // attachmentUrl: item.fileUrl,
                createBy: currentEmployee?.userId,
                fileName: item.fileName,
                fileType: item.fileType,
                fileId: item.fileId,
                fileUrl: item.fileUrl,
                id: item.fileSize,
                fileSize: item.fileSize,
                // extended: item.extended || '',
                permission: item.permission || null,
            };
        });
        attachMentList = newAttachment;
        // this.setState({
        //     attachMentList: newAttachment,
        // });
        // let list = Object.keys(data).map((key) => data[key]);
    };

    function onGenderChange(data: any) {
        setOptionContent(data);
        // console.log('收到', optionContent);
    }

    const footer = (
        <>
            <div
                className="GroupNotification_lishi"
                onClick={() => {
                    openHistory();
                }}
            >
                <p>{t('group_history')}</p>
            </div>
            <Space align="end">
                <Button onClick={onClose}>{t('cancelText')}</Button>
                <Button
                    id="uploadBtn"
                    type="primary"
                    loading={isloading}
                    htmlType="submit"
                    disabled={(content === '' && percent !== 100) || content === '' ? true : false}
                    onClick={debounce(() => {
                        sendMessages();
                    }, 500)}
                >
                    {t('publish')}
                </Button>
            </Space>
        </>
    );

    if (isvisible) {
        return (
            <History
                visible={isvisible}
                onClose={() => {
                    setIsVisible(false);
                }}
                sessionId={sessionId}
            />
        );
    }
    if (!visible) {
        return null;
    }
    if (visible) {
        return (
            <Drawer
                className="Drawerbox"
                width={450}
                title={t('newgroup_tz')}
                placement="right"
                onClose={onClose}
                visible={visible}
                footer={footer}
            >
                <div className="GroupNotification">
                    {isFocusEnv() ? (
                        <CopyFrame>
                            <div className="GroupNotification_int">
                                <TextArea
                                    // rows={4}
                                    maxLength={500}
                                    className="describe"
                                    autoSize={{ minRows: 6, maxRows: 6 }}
                                    placeholder={t('group_placeholder')}
                                    value={content}
                                    onChange={(e) => {
                                        onTextArea(e);
                                    }}
                                    onFocus={() => setContentFouse(true)}
                                    onBlur={() => setContentFouse(false)}
                                    style={{
                                        resize: 'none',
                                        borderColor:
                                            ContentFouse === true
                                                ? (window as any).styleSass.primaryColor
                                                : '#d8dad9',
                                    }}
                                />
                                <p
                                    className="conter"
                                    style={{
                                        textAlign: 'right',
                                        borderColor:
                                            ContentFouse === true
                                                ? (window as any).styleSass.primaryColor
                                                : '#d8dad9',
                                    }}
                                >
                                    <span className="primary">{content.length}</span>/500
                                </p>
                            </div>
                        </CopyFrame>
                    ) : (
                        <div className="GroupNotification_int">
                            <TextArea
                                // rows={4}
                                maxLength={500}
                                className="describe"
                                autoSize={{ minRows: 6, maxRows: 6 }}
                                placeholder={t('group_placeholder')}
                                value={content}
                                onChange={(e) => {
                                    onTextArea(e);
                                }}
                                onFocus={() => setContentFouse(true)}
                                onBlur={() => setContentFouse(false)}
                                style={{
                                    resize: 'none',
                                    borderColor:
                                        ContentFouse === true
                                            ? (window as any).styleSass.primaryColor
                                            : '#d8dad9',
                                }}
                            />
                            <p
                                className="conter"
                                style={{
                                    textAlign: 'right',
                                    borderColor:
                                        ContentFouse === true
                                            ? (window as any).styleSass.primaryColor
                                            : '#d8dad9',
                                }}
                            >
                                <span className="primary">{content.length}</span>/500
                            </p>
                        </div>
                    )}

                    <div className="GroupNotification_fj">
                        <span style={{ marginRight: '34px' }}>附件</span>
                        {/* <IconFont className="notification-icon" type="iconapp_ic_doc_new" /> */}
                        {visible && (
                            <Attachment
                                titleName="组通知附件"
                                className="notification-attachment"
                                defaultList={attachMentList}
                                onChange={attachmentChange}
                                mitileType={true}
                                LocalFile={true}
                            />
                        )}
                    </div>
                    <div className="GroupNotification_type">
                        <span style={{ marginRight: '12px' }}>响应类型</span>
                        <Select defaultValue="0" onSelect={onGenderChange} disabled={true}>
                            <Option value="0">响应“收到”</Option>
                        </Select>
                        <p
                            style={{
                                marginLeft: '70px',
                                color: '#8F959E',
                                marginTop: '4px',
                                fontSize: '12px',
                            }}
                        >
                            组成员需要点击“收到”确认操作，实时统计确认人数
                        </p>
                    </div>
                </div>
            </Drawer>
        );
    } else {
        return null;
    }
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const { sessionMessageMap, selectedSession, currentEmployee } = chat;
    return {
        sessionMessageMap,
        selectedSession,
        currentEmployee,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupNotificationBox);
