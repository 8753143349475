import React, { useReducer } from 'react';
import TaskListView from '@/components/Work/TaskListView';
import TaskTable from './components';
import { TaskModel } from '@/types/work';

function Task({
    changeSelectedTaskItem,
}: {
    changeSelectedTaskItem: (checkedTaskItem: TaskModel) => void;
}) {
    return (
        <div className="task-table_box" style={{ padding: 12 }}>
            <TaskTable changeSelectedTaskItem={changeSelectedTaskItem} />
        </div>
        // <TaskListView
        //     key="personal"
        //     isReadOnly={true}
        //     changeSelectedTaskItem={changeSelectedTaskItem}
        // />
    );
}

export default Task;
