/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import {
    CaretDownOutlined,
    CheckCircleFilled,
    CheckOutlined,
    FileExcelFilled,
    LoadingOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Dropdown, Input, Menu, message as toast, Modal, Drawer, Spin, Tooltip } from 'antd';
import { getByApprover, inviteConfirm, inviteRefuse } from '@/api/joyspace';
import { agreeJoinTeam, rejectJoinTeam } from '@/api/joinMe';
import {
    approveApplicationAddition,
    getStoreApplication,
    ApprovalResult,
    ApplicationStatus,
    AppStoreApplication,
} from '@/api/appStore';
import MyModal from '@/components/MyConfirm';
import { Link } from 'react-router-dom';
import { connect } from 'dva';
import { UserCardPayload } from '@/models';
import { FocusSDK, isFocusEnv, useDebounce, isBjme } from '@/utils';
import {
    ApplyGroupState,
    ApplyTeamState,
    ApplyAppStoreState,
    ExternalContactsStatus,
} from '@/types/chat/enum';
import ImService from '@/server/ImService';
import ChatState, { ApprExternalContacts } from '@/types/chat/State';
import { Employee, Session, UID } from '@/types/chat';
import i18n from '@/i18n';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import { UserCardType } from '@/types/UserCard';
import Bus from '@/utils/bus';
import { ZoomMeetingStatus } from '@/types/common';
import dayjs, { Dayjs } from 'dayjs';
import ApplyGroupCard from '@/baseComponents/Chat/message/NoticeCard/ApplyGroupCard';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import config, { ConfigEnum } from '@/config/config';
import logger from '@/utils/logger';
import { updateApprExternalContacts } from '@/api/chat';
import { replaceGovUrl } from '@/utils/joyspace';
import { setExternalContactsTipsTool } from '@/utils/chat/index';
import debounce from 'lodash/debounce';
import { UserState } from '@/models/user';
import { changeEgovUrl } from '@/utils/tools';
import { AVSDK } from '@/baseComponents/Meeting/common';
import CHAT_NOTICE from '@/contant/chat';

const log = logger.getLogger('NoticeCard');

function handleLinkClick(url: string) {
    if (!url) {
        return false;
    }
    let realUrl = replaceGovUrl(url);
    const a = document.createElement('a');
    a.href = realUrl;
    a.setAttribute('target', '_blank');
    a.click();
}

const NoticeCard = connect(
    function ({ chat, user }: { chat: ChatState; user: UserState }) {
        const { selectedSession, currentEmployee } = chat;
        return { selectedSession, useData: user.userData.user, currentEmployee };
    },
    function (dispatch: any) {
        return {
            updateCurrentEmployee(payload: any) {
                dispatch({ type: 'chat/updateCurrentEmployee', payload });
            },
        };
    }
)(function ({
    message,
    selectedSession,
    useData,
    updateCurrentEmployee,
}: {
    message: any;
    selectedSession: Session;
    useData: any;
    updateCurrentEmployee: Function;
}) {
    const { title, content, extend, infox, applicationKey } = message;
    const from = message?.from || {};
    // eslint-disable-next-line no-console
    // console.log('NoticeCardmessage===', message);
    const [t] = useTranslation('chat');
    const [isBj, setIsBj] = useState(false);
    const IsBeiJingTongAccount = useMemo(() => {
        return (
            from.pin === 'eopen-push_9IdJIcxdZmWRmXNLw3Qc' &&
            (message?.infox?.noticeType === 'to_perfect' ||
                message?.infox?.noticeType === 'unbind_success')
        );
    }, [from.pin, message?.infox?.noticeType]);
    useEffect(() => {
        const flag = isBjme();
        setIsBj(flag);
    }, []);
    const getEmployeeDetail = useCallback(async () => {
        if (
            infox?.noticeType === 'to_perfect' ||
            infox?.noticeType === 'bind_success' ||
            infox?.noticeType === 'unbind_success'
        ) {
            const imService = ImService.getInstance();
            const result = await imService.getEmployeeDetail({
                appId: useData.ddAppId,
                userId: useData.userId,
                teamId: useData.teamUserInfo.teamId,
            });
            let fields: any = {};
            (result?.fields || []).forEach((item: any) => {
                fields[item.key] = item.val;
            });
            updateCurrentEmployee(fields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infox?.noticeType]);

    useEffect(() => {
        getEmployeeDetail();
    }, [getEmployeeDetail]);

    if (from.pin === '~joyspace' && infox.type === 'invite') {
        return <JoyspaceInviteCard message={message} />;
    }

    if (from.pin === '~me69') {
        return <CalendarNoticeCard message={message} />;
        // return <NewUserNoticeCard message={message} />;
    }

    // 新用户申请
    if (from.pin === 'eopen-push_yQXxq4WhIpF2CAdw01Ti') {
        return <ExternalNewUserNoticeCard message={message} />;
    }

    // 群助手
    if (from.pin === 'eopen-push_uUyYexheoyRkjp6DpVtx') {
        return <ApplyGroupCard message={message} />;
    }

    // 团队助手
    if (from.pin === 'eopen-push_0GABWH9r96OvSSU0951x') {
        return <ApplyTeamCard message={message} />;
    }

    if (from.pin === CHAT_NOTICE.MEETING_PIN) {
        return <JoyMeetingNoticeCard message={message} />;
    }

    // 应用商店
    if (from.pin === '~appstore' || (isBj && from.pin === 'eopen-push_FGONoJyn5nPEcmRQKu7u')) {
        return <ApplyAppstoreCard message={message} />;
    }
    // zoom视频
    if (message.flag === 100 && message.ext.avsdk === AVSDK.ZOOM) {
        if (selectedSession.isNotice) {
            return <JoyMeetingCard message={message} t={t} />;
        } else {
            return <JoyMeetingSys message={message} t={t} />;
        }
    }
    if (from.pin === 'eopen-push_PZzzvgDrpZVAefKImEfo') {
        return <WorkNoticeCard message={message} />;
    }
    // 督办
    if (from.pin === 'eopen-push_0Gl8b1I3si21n0VVNkrF') {
        return <SuperviseNoticeCard message={message} />;
    }
    // 党史学习图文
    const [isShow, setIsShow] = useState(true);
    const src = infox?.imgUrl || infox?.pic;

    // 北京通号去完善
    if (IsBeiJingTongAccount) {
        return <IdentityInfoToPerfect message={message} />;
    }

    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}
            {src && isShow ? (
                <div className="img-wrapper">
                    <img
                        alt=""
                        onLoad={() => {
                            setIsShow(true);
                        }}
                        onError={() => {
                            setIsShow(false);
                        }}
                        onClick={() => FocusSDK.openUrl(src)}
                        src={changeEgovUrl(src)}
                    />
                </div>
            ) : null}
            {extend?.url && (
                <div className="notice-card-action">
                    <div
                        className="notice-card-action-view"
                        onClick={() => handleLinkClick(extend.url || '')}
                    >
                        {t('click-to-view')}
                    </div>
                </div>
            )}
        </div>
    );
});

export default NoticeCard;

const IdentityInfoToPerfect = connect(
    function ({ chat }: { chat: ChatState }) {
        return { identityInfoModal: chat.identityInfoModal, currentEmployee: chat.currentEmployee };
    },
    function (dispatch: any) {
        return {
            setIdentityInfoModal(payload: boolean) {
                dispatch({ type: 'chat/setIdentityInfoModal', payload });
            },
        };
    }
)(function ({
    message,
    setIdentityInfoModal,
    currentEmployee,
}: {
    message: any;
    setIdentityInfoModal: Function;
    currentEmployee: any;
}) {
    const { title, content } = message;
    const [t] = useTranslation('chat');
    const clickToPerfect = useCallback(() => {
        if (currentEmployee?.cardNumber) {
            toast.error(t('beijing-account-already-exists'));
            return;
        }
        setIdentityInfoModal(true);
    }, [currentEmployee, setIdentityInfoModal, t]);

    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}
            <div className="notice-card-action">
                <div
                    className="notice-card-action-view"
                    onClick={debounce(() => {
                        clickToPerfect();
                    }, 200)}
                >
                    {t('click-to-perfect')}
                </div>
            </div>
        </div>
    );
});

const ExternalNewUserNoticeCard = connect(
    function ({ chat }: { chat: ChatState }) {
        const { selectedSession } = chat;
        return { selectedSession };
    },
    function (dispatch: any) {
        return {
            setOpenExternalContactsModal(payload: boolean) {
                dispatch({ type: 'ebook/setOpenExternalContactsModal', payload });
            },
            updateCurrentEmployee(payload: any) {
                dispatch({ type: 'chat/updateCurrentEmployee', payload });
            },
        };
    }
)(function ({
    message,
    toggleUserCardModal,
    selectedSession,
    currentEmployee,
    setOpenExternalContactsModal,
}: {
    message: any;
    toggleUserCardModal: Function;
    selectedSession: Session;
    currentEmployee: Employee;
    setOpenExternalContactsModal: Function;
}) {
    const { t } = useTranslation('chat');
    const [externalContacts, setExternalContacts] = useState(ExternalContactsStatus.WAIT);

    // 审核通过
    const approvedAction = useCallback(async () => {
        Modal.confirm({
            title: t('approved'),
            icon: <CheckCircleFilled />,
            className: 'team-apply-modal',
            content: t('approvedConfirm'),
            okText: t('button.ok'),
            cancelText: t('button.cancel'),
            onOk: async () => {
                const param: ApprExternalContacts = {
                    apprId: message.infox.apprId, // 审核ID不能为空
                    apprStatus: 1, // 审核状态不能为空 1-审核成功 2-审核失败
                    apprOpinion: '', // 审核意见最大支持100个长度
                };
                const [result, error] = await updateApprExternalContacts(param);

                if (error) {
                    toast.error(error);
                    return;
                }
                const imService = ImService.getInstance();
                let updateResult = await imService.updateApplyExternalContactsList(
                    selectedSession?.sessionId,
                    message,
                    result
                );

                if (updateResult) {
                    setExternalContacts(result);
                }

                setExternalContactsTipsTool(result, t);
            },
        });
    }, [message, selectedSession, t]);

    // 查看更多
    const seeMoreAction = useCallback(async () => {
        setOpenExternalContactsModal(true);
    }, [setOpenExternalContactsModal]);

    useEffect(() => {
        const { applyState } = message.infox;
        if (applyState) {
            setExternalContacts(applyState);
        }
    }, [message.infox]);

    const status = useMemo(() => {
        return (
            externalContacts === ExternalContactsStatus.INVALID ||
            externalContacts === ExternalContactsStatus.SUCCESS ||
            externalContacts === ExternalContactsStatus.ALREADY_APPR ||
            externalContacts === ExternalContactsStatus.ALREADY_EXSIT
        );
    }, [externalContacts]);

    return (
        <div className="apply-group-card">
            <div className="apply-details">
                <div className="apply-details-title">
                    <span className="agree">{message.title.split(' ')[0]} </span>
                    {message.title.split(' ')[1]}
                </div>
                <p className="apply-team">{message.content}</p>
            </div>
            <div className="apply-setting">
                <div className="apply-setting-action">
                    <button className="refuse seeMore" onClick={seeMoreAction}>
                        {t('seeMore')}
                    </button>
                    <button
                        className="agreed approved "
                        onClick={approvedAction}
                        disabled={status}
                        style={{
                            color: status ? '#8F959EFF' : '',
                            cursor: status ? 'inherit' : '',
                        }}
                    >
                        {t('approved')}
                    </button>
                </div>
            </div>
        </div>
    );
});

const CalendarNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            changeScheduleInfoDraw: (payload: {
                visible: boolean;
                scheduleId: string;
                selectedScheduleTime: Dayjs;
                from: number;
                openTime: Dayjs;
            }) =>
                dispatch({
                    type: 'calendar/changeScheduleInfoDraw',
                    payload,
                }),
        };
    }
)(
    ({
        message,
        changeScheduleInfoDraw,
    }: {
        message: any;
        changeScheduleInfoDraw: (payload: {
            visible: boolean;
            scheduleId: string;
            selectedScheduleTime: Dayjs;
            from: number;
            openTime: Dayjs;
        }) => void;
    }) => {
        const { title, content, infox } = message;
        const [t] = useTranslation('chat');
        const { deepLink } = infox;
        // jdme://jm/page_calendar_edit?mparam=%7B%22calendarId%22%3A%22270603332890918912%22%7D
        const handleClick = useCallback(() => {
            // const link = decodeURIComponent(deepLink);

            // const strs = link.split('?mparam=');
            // if (strs.length !== 2) {
            //     return;
            // }
            // const mparam = JSON.parse(strs[1]);
            // changeScheduleInfoDraw({
            //     visible: true,
            //     scheduleId: mparam.scheduleId,
            //     selectedScheduleTime: dayjs(mparam.beginTime),
            //     calendarId: mparam.calendarId,
            // });
            DeeplinkEvent.open(deepLink);
        }, [deepLink]);
        return (
            <div className="notice-card">
                <div className="notice-card-title">{title}</div>
                {content && <div className="notice-card-content">{content}</div>}

                {deepLink && (
                    <div className="notice-card-action" onClick={handleClick}>
                        <div className="notice-card-action-view">{t('click-to-view')}</div>
                    </div>
                )}
            </div>
        );
    }
);

const JoyMeetingNoticeCard = connect(
    function ({ chat }: { chat: ChatState }) {
        const { currentEmployee } = chat;
        return { currentEmployee };
    },
    function (dispatch: any) {
        return {};
    }
)(function JoyMeetingNoticeCard({
    message,
    currentEmployee,
}: {
    message: any;
    currentEmployee: any;
}) {
    const { title, content, extend, from, infox } = message;
    const [t] = useTranslation('chat');

    // TODO: 这块代码和 src/components/Meeting/utils.ts 里的 joinMeeting 方法重复，考虑替换
    const debounceToast = useDebounce((str: string, action: 'error') => {
        toast[action](str);
    }, 1500);
    const [click, setClick] = useState(false);
    function handleClick() {
        if (click) {
            return;
        }
        setClick(true);
        setTimeout(() => {
            setClick(false);
        }, 2000);
        function removeListener() {
            FocusSDK.off('notice.joinMeetingStatusCb', handleMeetingStatusCb);
        }
        function handleMeetingStatusCb(data: any) {
            const { status } = data;
            switch (status) {
                case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_INMEETING:
                    removeListener();
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_FAILED:
                    // toast.error(t('joyMeeting.meeting connect failed'));
                    debounceToast(t('joyMeeting.meeting connect failed'), 'error');
                    setTimeout(() => {
                        removeListener();
                    }, 2000);
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_ENDED:
                    // toast.error(t('joyMeeting.meeting has already ended'));
                    debounceToast(t('joyMeeting.meeting has already ended'), 'error');
                    removeListener();
                    break;
                default:
                    break;
            }
        }
        if (isFocusEnv()) {
            FocusSDK.once('notice.joinMeetingByInitDataCb', (data: any) => {
                if (data.status !== 'success') {
                    if (Number(data.code) !== -1) {
                        toast.error(t('joyMeeting.There is a meeting going on'));
                        return;
                    }
                    toast.error(t('joyMeeting.meeting initialization failure'));
                    return;
                }
                FocusSDK.on('notice.joinMeetingStatusCb', handleMeetingStatusCb);
            });
            // 发送消息到桌面端
            FocusSDK.sendIpcMessage('zoom.joinMeetingByInitData', {
                meetingnum: infox.meetingNumber,
                psw: infox.meetingPassword,
                username: currentEmployee.name,
                isvideooff: true,
                isaudiooff: true,
                // vanityid: infox.meetingID,
            });
        } else {
            Bus.emit('chat_joymeeting_invite', { ext: infox });
        }
    }
    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}
            <div className="notice-card-action">
                <div className="notice-card-action-view" onClick={handleClick}>
                    {t(`joyMeeting.joinMeeting`)}
                </div>
            </div>
        </div>
    );
});

function JoyMeetingCard({ message: data, t }: { message: any; t: any }) {
    const [message, setMessage] = React.useState(data);
    const { ext, content } = message;
    const { meetingNumber, sessionState } = ext || {};
    const isCancel = sessionState === 1;
    const messageChangedCb = React.useCallback(
        (newMessage: any) => {
            if (!newMessage.ext) {
                return;
            }
            // console.log('JoyMeetingCard ->', { message, newMessage });
            if (newMessage.ext.meetingNumber !== message.ext.meetingNumber) {
                return;
            }
            setMessage(newMessage);
        },
        [message]
    );
    React.useEffect(() => {
        Bus.on('chat:message-change', messageChangedCb);
        return () => {
            Bus.off('chat:message-change', messageChangedCb);
        };
    }, [messageChangedCb]);
    return (
        <div className="notice-card-joymeeting">
            <div
                className={['notice-card-joymeeting-title', isCancel ? 'meeting-close' : ''].join(
                    ' '
                )}
            >
                <div className="title-title">{content}</div>
                <div>
                    {t('joyMeeting.meetingNum')}: {meetingNumber}
                </div>
            </div>
            {config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] && (
                <div className="notice-card-action">
                    <div
                        className={[
                            'notice-card-action-view',
                            isCancel ? 'meeting-close' : '',
                        ].join(' ')}
                        onClick={() => {
                            if (isCancel) {
                                return;
                            }
                            Bus.emit('chat_joymeeting_invite', message);
                        }}
                    >
                        {t(`joyMeeting.${isCancel ? 'meetingCancel' : 'joinMeeting'}`)}
                    </div>
                </div>
            )}
        </div>
    );
}

function JoyMeetingSys({ message: data, t }: { message: any; t: any }) {
    const [message, setMessage] = React.useState(data);
    const { ext, content } = message;

    return (
        <div className="text-message">
            <span>{`[${t('system-message-group.joymeeting')}] ${message.content}`}</span>
        </div>
    );
}

function JoyspaceInviteCard({ message }: { message: any }) {
    const { title, content, extend, infox } = message;
    const [t] = useTranslation('chat');
    const [permission, setPermission] = useState('2');
    const [status, setStatus] = useState<any>(1);
    const [refuseVisible, setRefuseVisible] = useState(false);
    const [reason, setReason] = useState('');
    const getRecordId = useCallback(() => {
        const deepLink = decodeURIComponent(infox.deepLink);
        const strs = deepLink.split('mparam=');
        const str = decodeURIComponent(strs[1] || '');
        const obj = JSON.parse(str);
        const recordId = obj.recordId;
        return recordId;
    }, [infox.deepLink]);

    useEffect(() => {
        if (!(infox.type === 'invite')) {
            return;
        }
        const recordId = getRecordId();
        getByApprover(recordId)
            .then((data: any) => {
                const record = data.record;
                setStatus(record.status);
                setPermission(String(record.permission_type) || '2');
            })
            .catch((err) => {
                console.log(`getByApprover err`, err);
                // toast.error(err.message.toString());
            });
    }, [getRecordId, infox.deepLink, infox.type]);

    function changePermission(permission: string) {
        setPermission(permission);
    }

    function confirm() {
        const recordId = getRecordId();
        inviteConfirm(recordId, Number(permission))
            .then((data: any) => {
                const record = data.record;
                setStatus(record.status);
                setPermission(String(record.permission_type) || '2');
            })
            .catch((err) => {
                toast.error(err.message.toString());
            });
    }

    function handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const value = e.target.value;
        setReason(value);
    }

    function refuse() {
        const recordId = getRecordId();
        setRefuseVisible(false);
        inviteRefuse(recordId, reason)
            .then((data: any) => {
                const record = data.record;
                setStatus(record.status);
            })
            .catch((err) => {
                toast.error(err.message.toString());
            });
    }

    const menu = (
        <Menu className="joyspace-invite-card-menu" selectedKeys={[permission]}>
            <Menu.Item key="2" onClick={() => changePermission('2')}>
                <span className="title">{t('permission_read_label')}</span>
                <CheckOutlined />
            </Menu.Item>
            <Menu.Item key="1" onClick={() => changePermission('1')}>
                <span>{t('permission_read_write_label')}</span>
                <CheckOutlined />
            </Menu.Item>
        </Menu>
    );
    if (infox.type === 'invite') {
        return (
            <div
                className={`notice-card joyspace-invite-card
                ${status === 2 ? 'joyspace-invite-card-approval' : ''}
                ${status === 3 ? 'joyspace-invite-card-disapproval' : ''}`}
            >
                <div className="joyspace-invite-card-header">
                    <div className="joyspace-invite-card-header-name">{infox.realName}</div>
                    <div className="joyspace-invite-card-header-subtitle">
                        {title.replace(infox.realName, '').trim()}
                    </div>
                </div>
                {content && <div className="joyspace-invite-card-content">{content}</div>}
                {status === 1 && (
                    <div className="joyspace-invite-card-action">
                        <div className="action-left">
                            <div className="action-left-permission">
                                {permission === '1'
                                    ? t('permission_read_write_label')
                                    : t('permission_read_label')}
                                <Dropdown placement="topCenter" overlay={menu} trigger={['hover']}>
                                    <CaretDownOutlined />
                                </Dropdown>
                            </div>
                            <div className="action-left-approval" onClick={confirm}>
                                {t('approval')}
                            </div>
                        </div>
                        <div className="action-right">
                            <div
                                className="action-right-disapproval"
                                onClick={() => setRefuseVisible(true)}
                            >
                                {t('disapproval')}
                            </div>
                        </div>
                    </div>
                )}
                {(status === 2 || status === 3) && (
                    <div className="joyspace-invite-card-action">
                        {status === 2 && (
                            <div className="joyspace-invite-card-view">
                                {permission === '1'
                                    ? t('approval_and_canedit')
                                    : t('approval_and_canread')}
                            </div>
                        )}
                        {status === 3 && (
                            <div className="joyspace-invite-card-view">{t('has_disapproval')}</div>
                        )}
                    </div>
                )}
                <MyModal
                    title={t('refuse_subtitle').replace('%name', infox.realName)}
                    onCancel={() => {
                        setRefuseVisible(false);
                    }}
                    onClick={refuse}
                    visible={refuseVisible}
                    okText={t('determine')}
                >
                    <div className="joyspace-invite-card-modal">
                        <Input.TextArea onChange={handleInputChange} maxLength={100} />
                    </div>
                </MyModal>
            </div>
        );
    }
    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}
            {extend && (
                <div className="notice-card-action">
                    <div
                        className="notice-card-action-view"
                        onClick={() => handleLinkClick(extend.url)}
                    >
                        {t('click-to-view')}
                    </div>
                </div>
            )}
        </div>
    );
}

const ApplyTeamCard = connect(
    function ({ chat }: { chat: ChatState }) {
        const { selectedSession, currentEmployee } = chat;
        return { selectedSession, currentEmployee };
    },
    function (dispatch: any) {
        return {
            toggleUserCardModal(payload: UserCardPayload) {
                dispatch({ type: 'app/toggleUserCardModal', payload });
            },
        };
    }
)(function ({
    message,
    toggleUserCardModal,
    selectedSession,
    currentEmployee,
}: {
    message: any;
    toggleUserCardModal: Function;
    selectedSession: Session;
    currentEmployee: Employee;
}) {
    const { t } = useTranslation('chat');

    const [invitee, setInvitee] = useState<UID>({ app: '', name: '', pin: '', teamId: '' });
    const [applyState, setApplyState] = useState(ApplyTeamState.INIT);

    // 同意操作
    const agreeAction = useCallback(async () => {
        if (message.infox?.phoneNum) {
            Modal.confirm({
                title: t('agreeApply'),
                icon: <CheckCircleFilled />,
                className: 'team-apply-modal',
                content: (
                    <p
                        style={{
                            fontSize: '14px',
                            lineHeight: '22px',
                        }}
                    >
                        {t('agreeConfirm')}
                        <span className="info">{message.infox.fromUserName}</span>
                        {t('joinAsk').replace('%team', message.infox.teamName)}
                    </p>
                ),
                okText: t('apply.agree'),
                cancelText: t('cancelText'),
                onOk: async () => {
                    const res: any = await agreeJoinTeam({
                        inviteTeamId: message.infox.teamId,
                        invitedUserMobile: message.infox.phoneNum,
                        invitedUserName: message.infox.fromUserName,
                    });
                    if (res.joinTeamResult) {
                        const imService = ImService.getInstance();
                        await imService.updateTeamApplyState(
                            selectedSession.sessionId,
                            message,
                            ApplyTeamState.AGREED
                        );
                        setApplyState(ApplyTeamState.AGREED);
                    }
                },
            });
        }
    }, [t, selectedSession.sessionId, message]);

    const refuseAction = useCallback(async () => {
        if (message.infox?.phoneNum) {
            Modal.confirm({
                title: t('rejectApply'),
                icon: <CheckCircleFilled />,
                className: 'team-apply-modal-reject',
                content: (
                    <p
                        style={{
                            fontSize: '14px',
                            lineHeight: '22px',
                        }}
                    >
                        {t('rejectApply')}
                        <span className="info">{message.infox.fromUserName}</span>
                        {t('joinAsk').replace('%team', message.infox.teamName)}
                    </p>
                ),
                okText: t('apply.refuse'),
                cancelText: t('cancelText'),
                onOk: async () => {
                    const res: any = await rejectJoinTeam({
                        inviteTeamId: message.infox.teamId,
                        invitedUserMobile: message.infox.phoneNum,
                        invitedUserName: message.infox.fromUserName,
                    });
                    if (res.joinTeamResult) {
                        const imService = ImService.getInstance();
                        await imService.updateTeamApplyState(
                            selectedSession.sessionId,
                            message,
                            ApplyTeamState.REJECTED
                        );
                        setApplyState(ApplyTeamState.REJECTED);
                    }
                },
            });
        }
    }, [selectedSession.sessionId, message, t]);

    useEffect(() => {
        const { applyState } = message.infox;
        if (applyState) {
            setApplyState(applyState);
        }

        const { from, noticeType } = message.infox;

        if (from) {
            const fromJson = attempt(JSON.parse, from);
            if (isError(fromJson)) {
                return;
            }
            if (noticeType !== 'reply') {
                setInvitee(fromJson);
            }
        }
    }, [message]);

    const stateNode = useMemo(() => {
        // console.log('stateNode ===》', message);
        if (message.infox.noticeType === 'reply') {
            // 可以再次申请
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else if (applyState === ApplyTeamState.AGREED) {
            return <div className="agreed">{t('apply.agreed')}</div>;
        } else if (applyState === ApplyTeamState.REJECTED) {
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else {
            return (
                <div className="apply-setting-action">
                    <button className="refuse" onClick={refuseAction}>
                        {t('apply.refuse')}
                    </button>
                    <button className="agree" onClick={agreeAction}>
                        {t('apply.agree')}
                    </button>
                </div>
            );
        }
    }, [applyState, message, t, refuseAction, agreeAction]);

    const openUserCard = useCallback(() => {
        const from = attempt(JSON.parse, message.infox.from);
        if (isError(from)) {
            return;
        }
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId: from?.pin,
            teamId: from?.teamId,
            appId: from?.app,
        });
    }, [message.infox.from]);

    const applyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.apply-join')}</span>
            <span className="apply-details-content-link group">{message.infox.groupName}</span>
            {message.infox.reason && (
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;{t('apply.reason')}: {message.infox.reason}
                </span>
            )}
        </div>
    );

    const replyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.refuse_you_add')}</span>
            <span>{message.infox.groupName}</span>
        </div>
    );

    const contentNode = useMemo(() => {
        if (message.infox.noticeType === 'reply') {
            return replyContent;
        } else {
            return applyContent;
        }
    }, [applyContent, message.infox.noticeType, replyContent]);

    return (
        <div className="apply-group-card">
            <div className="apply-details">
                <div className="apply-details-title">
                    {message.infox.title}
                    {/* {message.infox.teamName} */}
                </div>
                {/* <div className="apply-details-title">{message.infox.fromUserName}</div> */}
                <p className="apply-team">
                    {/* onClick={openUserCard} */}
                    <span>{message.infox.fromUserName}</span>
                    {t('applyJoin')}
                    {message.infox.teamName}
                </p>
                <p className="apply-team">
                    {t('mobile')}: {message.infox.phoneNum}
                </p>
                {/* {contentNode} */}
            </div>
            <div className="apply-setting">{stateNode}</div>
        </div>
    );
});
const ApplyAppstoreCard = connect(
    function ({ chat }: { chat: ChatState }) {
        const {
            selectedSession,
            currentEmployee,
            globalDrawerVisible,
            messageDrawerVisible,
            appcationApplyMessageId,
        } = chat;
        return {
            selectedSession,
            currentEmployee,
            globalDrawerVisible,
            messageDrawerVisible,
            appcationApplyMessageId,
        };
    },
    function (dispatch: any) {
        return {
            toggleUserCardModal(payload: UserCardPayload) {
                dispatch({ type: 'app/toggleUserCardModal', payload });
            },
            setglobalDrawerVisible(data: boolean) {
                dispatch({ type: 'chat/setglobalDrawerVisible', payload: data });
            },
            setmessageDrawerVisible(data: boolean) {
                dispatch({ type: 'chat/setmessageDrawerVisible', payload: data });
            },
            setappcationApplyMessageId(data: boolean) {
                dispatch({ type: 'chat/setappcationApplyMessageId', payload: data });
            },
        };
    }
)(function ({
    message,
    selectedSession,
    setglobalDrawerVisible,
    setmessageDrawerVisible,
    globalDrawerVisible,
    messageDrawerVisible,
    appcationApplyMessageId,
    setappcationApplyMessageId,
}: {
    message: any;
    selectedSession: Session;
    globalDrawerVisible: boolean;
    messageDrawerVisible: boolean;
    appcationApplyMessageId: string;
    setglobalDrawerVisible: Function;
    setmessageDrawerVisible: Function;
    setappcationApplyMessageId: Function;
}) {
    const { t } = useTranslation('chat');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [invitee, setInvitee] = useState<UID>({ app: '', name: '', pin: '', teamId: '' });
    const [applyState, setApplyState] = useState(ApplyAppStoreState.INIT);
    const [agreeLoading, setAgreeLodaing] = useState(false);
    const [rejectLoading, setRejectLodaing] = useState(false);
    const [storeApplicationInfo, setStoreApplicationInfo] = useState<AppStoreApplication | null>(
        null
    );
    const { appRuntimeEnv, focusSDK } = useContext<GlobalContextConfig>(GlobalContext);
    const mainEntry = appRuntimeEnv.meMainEntry
        ? `${appRuntimeEnv.meMainEntry}/admin/`
        : process.env.REACT_APP_ME_ADMIN_URL || 'https://me.jdcloud.com/admin/';
    const [appId, setAppId] = useState('');
    useEffect(() => {
        if (isBjme()) {
            setAppId('mebj');
        }
    }, []);
    useEffect(() => {
        if (globalDrawerVisible) {
            setmessageDrawerVisible(false);
            setDrawerVisible(false);
        }
    }, [globalDrawerVisible, setmessageDrawerVisible]);
    useEffect(() => {
        setmessageDrawerVisible(drawerVisible);
    }, [drawerVisible, setmessageDrawerVisible]);
    useEffect(() => {
        if (appcationApplyMessageId !== message.id) {
            setDrawerVisible(false);
        }
    }, [appcationApplyMessageId, message]);
    const loadingComp = (
        <Spin
            indicator={
                <LoadingOutlined
                    style={{ fontSize: 16, marginRight: 6 }}
                    className="primary"
                    spin
                />
            }
        />
    );
    async function getStoreApplicationInfo() {
        const res = await getStoreApplication({
            appKey: '',
            applicationId: message.infox.applicationId,
        }).then((res: any) => {
            return Promise.resolve(res);
        });
        if (res) {
            const { application } = res;
            if (application) {
                setStoreApplicationInfo(application);
                return Promise.resolve(application);
            }
        }
        return Promise.resolve({});
    }
    // eslint-disable-next-line
    const approveApplication = (approvalResult: ApprovalResult) => {
        if (agreeLoading || rejectLoading) {
            return;
        }
        if (approvalResult === ApprovalResult.Reject) {
            setRejectLodaing(true);
        } else {
            setAgreeLodaing(true);
        }
        approveApplicationAddition({
            appKey: '',
            applicantUserId: message.infox.applicantUserId,
            approvalResult: approvalResult,
            applicationId: message.infox.applicationId,
        })
            .then((res) => {
                const imService = ImService.getInstance();
                if (approvalResult === ApprovalResult.Reject) {
                    imService.updateAppStoreApplyState(
                        selectedSession.sessionId,
                        message,
                        ApplyAppStoreState.REJECTED
                    );
                    setApplyState(ApplyAppStoreState.REJECTED);
                    toast.success({
                        content: t('applyRejected'),
                        className: 'apply-app-message-modal',
                    });
                } else {
                    imService.updateAppStoreApplyState(
                        selectedSession.sessionId,
                        message,
                        ApplyAppStoreState.AGREED
                    );
                    setApplyState(ApplyAppStoreState.AGREED);
                    toast.success({
                        className: 'apply-app-message-modal',
                        content: t('submitSuccess'),
                    });
                }
            })
            .catch((err) => {})
            .finally(() => {
                getStoreApplicationInfo();
                if (approvalResult === ApprovalResult.Reject) {
                    setRejectLodaing(false);
                } else {
                    setAgreeLodaing(false);
                }
            });
    };
    async function openDrawer() {
        const application = await getStoreApplicationInfo();
        if (application) {
            setappcationApplyMessageId(message.id);
            setDrawerVisible(true);
        }
    }
    // 同意操作
    const agreeAction = useCallback(async () => {
        approveApplication(ApprovalResult.Agree);
    }, [approveApplication]);

    const refuseAction = useCallback(async () => {
        if (message.infox?.applicantUserId) {
            Modal.confirm({
                title: t('apply.reject-apply'),
                icon: <ExclamationCircleOutlined />,
                // className: 'team-apply-modal-reject',
                content: (
                    <p
                        style={{
                            fontSize: '14px',
                            lineHeight: '22px',
                        }}
                    >
                        <span>{t(`apply.reject-apply-desc${appId}`)}</span>
                    </p>
                ),
                okText: t('button.ok'),
                cancelText: t('button.cancel'),
                onOk: async () => {
                    approveApplication(ApprovalResult.Reject);
                },
            });
        }
    }, [approveApplication, message, t, appId]);

    useEffect(() => {
        const { applyState, from, noticeType } = message?.infox || {};
        if (applyState) {
            setApplyState(applyState);
        }
        if (from) {
            const fromJson = attempt(JSON.parse, from);
            if (isError(fromJson)) {
                return;
            }
            if (noticeType !== 'reply') {
                setInvitee(fromJson);
            }
        }
    }, [message]);

    const stateNode = useMemo(() => {
        return <div className="agreed">{t('viewDetail')}</div>;
    }, [t]);
    function getHeader() {
        // 被别人添加
        if (
            storeApplicationInfo?.added === ApplicationStatus.Added &&
            applyState === ApplyAppStoreState.INIT
        ) {
            return i18n.t(`chat:applicationAdded${appId}`);
        } else if (
            storeApplicationInfo?.added === ApplicationStatus.Unadd &&
            applyState === ApplyAppStoreState.INIT
        ) {
            // 被别人驳回
            return i18n.t(`chat:applicationRejected${appId}`);
        }
        return i18n.t(`chat:apply.add-application${appId}`);
    }
    const MessageInfo = ({
        className,
        styles,
        type,
        text,
    }: {
        className?: string;
        styles?: any;
        type: number;
        text?: string;
    }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    lineHeight: 1.4,
                    ...styles,
                }}
                className={`message-info ${className}`}
            >
                {type === 0 ? (
                    <CheckCircleOutlined
                        style={{
                            marginTop: 2,
                        }}
                    />
                ) : null}
                {type === 1 ? (
                    <ExclamationCircleOutlined
                        style={{
                            marginTop: 2,
                        }}
                    />
                ) : null}
                <p
                    style={{
                        marginLeft: 6,
                    }}
                    className="text"
                >
                    {text}
                </p>
            </div>
        );
    };

    const addSuccess = (
        <div
            style={{
                padding: '0 32px 60px 32px',
                textAlign: 'center',
            }}
        >
            {/* <MessageInfo type={1} className="success" text={i18n.t('chat:applicationAddDesc')} /> */}
            <img
                style={{
                    width: 186,
                    height: 138,
                    marginTop: 116,
                }}
                src="http://storage.360buyimg.com/joyspace-support/me-appstore/add-success.png"
            />
            <p
                style={{
                    marginTop: 32,
                    textAlign: 'center',
                }}
                className="desc"
            >
                {t(`chat:apply.add-app-success${appId}`)}
            </p>
            <div>
                <div
                    className="btn active"
                    onClick={() => {
                        focusSDK.openUrl(mainEntry);
                    }}
                >
                    {t('chat:apply.go-app-admin')}
                </div>
            </div>
        </div>
    );
    // 自己没有执行操作，但是其他管理员可能执行了操作
    const unAdd = (
        <>
            <div
                style={{
                    padding: '0 16px 120px 16px',
                }}
            >
                {/* 被别人添加 */}
                {storeApplicationInfo?.added === ApplicationStatus.Added &&
                applyState === ApplyAppStoreState.INIT ? (
                    <MessageInfo
                        type={0}
                        className="success"
                        text={i18n.t(`chat:applicationAddDesc${appId}`)}
                    />
                ) : null}
                {/* 被别人驳回 */}
                {storeApplicationInfo?.added === ApplicationStatus.Unadd &&
                applyState === ApplyAppStoreState.INIT ? (
                    <MessageInfo
                        type={1}
                        className="warn"
                        text={i18n.t(`chat:applicationRejectedDesc${appId}`)}
                    />
                ) : null}
                <img className="icon" src={changeEgovUrl(message.infox?.applicationIcon)} />
                <p
                    style={{
                        textAlign: 'center',
                        marginTop: 14,
                    }}
                >
                    <span
                        style={{
                            fontWeight: 500,
                            marginRight: 6,
                        }}
                    >
                        {message.infox?.applicantName}
                    </span>{' '}
                    {i18n.t(`chat:apply.add-application${appId}`)}
                </p>
                <p
                    style={{
                        textAlign: 'center',
                        fontSize: 20,
                        fontWeight: 'bold',
                        marginTop: 34,
                    }}
                >
                    {message.infox?.applicationName}
                </p>
                <p
                    style={{
                        marginTop: 26,
                        background: 'rgba(248,249,250,1)',
                        borderRadius: 6,
                        padding: '16px 24px',
                    }}
                >
                    {i18n.t('chat:apply.reason')}:{message.infox?.reasonForApplying}
                </p>
            </div>
            {/* 自己没处理，别人也没处理 */}
            {storeApplicationInfo?.added === ApplicationStatus.Processing &&
            applyState === ApplyAppStoreState.INIT ? (
                <div className="bottom-btn">
                    <div className="item reject" onClick={() => refuseAction()}>
                        {rejectLoading ? loadingComp : ''}
                        {i18n.t('chat:apply.bo-hui')}
                    </div>
                    <p className="tinny" />
                    <div className=" item agree" onClick={() => agreeAction()}>
                        {agreeLoading ? loadingComp : ''}
                        {i18n.t('chat:apply.confirm-and-add')}
                    </div>
                    )
                </div>
            ) : null}
            {/* 已被别人添加 */}
            {storeApplicationInfo?.added === ApplicationStatus.Added &&
            applyState === ApplyAppStoreState.INIT ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        paddingBottom: 32,
                        background: '#fff',
                    }}
                >
                    <div
                        className="btn active"
                        style={{
                            marginTop: 26,
                        }}
                        onClick={() => {
                            // window.open(process.env.REACT_APP_ME_ADMIN_URL);
                            focusSDK.openUrl(mainEntry);
                        }}
                    >
                        {t('chat:apply.go-app-admin')}
                    </div>
                </div>
            ) : null}
            {applyState === ApplyAppStoreState.REJECTED ? (
                <div className="bottom-btn">
                    <div
                        className="item"
                        style={{
                            color: '#8F959E',
                        }}
                    >
                        {rejectLoading ? loadingComp : ''}
                        {i18n.t('chat:apply.yi-bo-hui')}
                    </div>
                </div>
            ) : null}
        </>
    );
    return (
        <div className="apply-group-card">
            <div className="apply-details">
                <div className="apply-details-title">
                    {i18n.t(`chat:apply.apply-application${appId}`)}
                </div>
                {message.infox ? (
                    <p
                        className="apply-team"
                        style={{
                            marginTop: 8,
                        }}
                    >
                        <span className="primary">{message.infox?.applicantName}</span>
                        {i18n.t('chat:apply.apply-open')}
                        <span className="primary">{message.infox?.applicationName}</span>,{' '}
                        {i18n.t('chat:apply.reason')}:{message.infox?.reasonForApplying}
                    </p>
                ) : (
                    <p
                        className="apply-team"
                        style={{
                            marginTop: 8,
                            marginBottom: 8,
                        }}
                    >
                        {message.content}
                    </p>
                )}
            </div>
            {message.infox ? (
                <div
                    onClick={() => openDrawer()}
                    className="apply-setting"
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    {stateNode}
                </div>
            ) : null}

            {drawerVisible ? (
                <Drawer
                    visible={drawerVisible}
                    className="apply-app-store-drawer"
                    getContainer={
                        document.querySelector('#chat-right-container-change-set') as HTMLElement
                    }
                    placement="right"
                    width="310px"
                    height="100%"
                    mask={false}
                    style={{ position: 'absolute', right: 50, zIndex: -1 }}
                    onClose={() => {
                        setDrawerVisible(false);
                    }}
                    closable={true}
                >
                    <div
                        style={{
                            position: 'relative',
                            zIndex: 0,
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <div className="header">{getHeader()}</div>
                        {applyState === ApplyAppStoreState.AGREED &&
                        storeApplicationInfo?.added === ApplicationStatus.Added
                            ? addSuccess
                            : unAdd}
                        {/* 这里的unadd是指 不是自己添加的，但可能被别人添加 */}
                    </div>
                </Drawer>
            ) : null}
        </div>
    );
});
const WorkNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            changePersonalTaskDraw: (taskId: string) =>
                dispatch({
                    type: 'work/changePersonalTaskDraw',
                    payload: { taskId, drawVisible: true },
                }),
            changeFullIframeModal: (visible: boolean, url: string) =>
                dispatch({
                    type: 'work/changeFullIframeModal',
                    payload: { fullIframeModalVisible: visible, fullIframeModalUrl: url },
                }),
        };
    }
)(function ({
    changePersonalTaskDraw,
    message,
    changeFullIframeModal,
}: {
    changePersonalTaskDraw: (taskId: string) => void;
    changeFullIframeModal: (visible: boolean, url: string) => void;
    message: any;
}) {
    const { title, content, extend, from, infox } = message;
    const [t] = useTranslation('chat');
    const handleClick = useDebounce(() => {
        log.debug('WorkNoticeCard click', { infox, message });
        if (infox.extraConf) {
            const extraConf = JSON.parse(infox.extraConf);
            const { openView } = extraConf;
            switch (openView) {
                case 1:
                    break;
                case 3:
                    DeeplinkEvent.open(infox.pcHomeUrl);
                    // if (isFocusEnv()) {
                    //     DeeplinkEvent.open(infox.pcHomeUrl);
                    //     // window.open(infox.pcHomeUrl);
                    //     return;
                    // }
                    // changeFullIframeModal(true, infox.pcHomeUrl || '');
                    return;
                default:
                    break;
            }
            return;
        }
        if (infox.taskId) {
            changePersonalTaskDraw(infox.taskId);
        } else {
            DeeplinkEvent.open(infox.deepLink);
        }
    }, 250);
    if (infox?.msgType === '1') {
        return (
            <div className="notice-card">
                <div className="notice-card-title">{infox.msgTitle}</div>
                {content && (
                    <div className="notice-card-content">
                        <div>任务名称：{infox.taskName}</div>
                        <div>
                            截止时间：
                            {infox.endTime
                                ? dayjs(Number(infox.endTime)).format('YYYY年M月D日 HH:mm')
                                : '无截止时间'}
                        </div>
                    </div>
                )}

                {infox.deepLink && (
                    <div className="task-notice-card-action">
                        <div
                            className="task-notice-card-action-view"
                            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_notice_card"
                            onClick={() => handleClick()}
                        >
                            查看详情
                        </div>
                    </div>
                )}
            </div>
        );
    }
    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}

            {infox.deepLink && (
                <div className="notice-card-action">
                    <div className="notice-card-action-view" onClick={() => handleClick()}>
                        {t('click-to-view')}
                    </div>
                </div>
            )}
        </div>
    );
});

const SuperviseNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            setDetailDrawerVisible: (superviseId: string) =>
                dispatch({
                    type: 'supervise/setDetailDrawerVisible',
                    payload: {
                        visible: true,
                        superviseId,
                        // globalSuperviseType: '',
                    },
                }),
        };
    }
)(function ({
    message,
    setDetailDrawerVisible,
}: {
    message: any;
    setDetailDrawerVisible: (s: any) => void;
}) {
    const { title, content, extend, from, infox } = message;
    const [t] = useTranslation('chat');
    const handleClick = useDebounce(() => {
        setDetailDrawerVisible(infox?.superviseId);
    }, 250);
    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}

            <div className="notice-card-action">
                <div className="notice-card-action-view" onClick={() => handleClick()}>
                    {t('click-to-view')}
                </div>
            </div>
        </div>
    );
});
