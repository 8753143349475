import { gatewayInstance } from '@/server/gateway';

export async function getThirdEmail(code: string) {
    return gatewayInstance.request({
        api: 'focus.thirdEmail',
        method: 'POST',
        body: {
            code,
            authId: '',
        },
    });
}
