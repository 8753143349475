// http://ee-doc.jd.com/swagger.html?url=http%3A%2F%2Fee-doc.jd.com%2FgetDocumentDefinition%3FserviceId%3DminiEOpen.json#/开放授权服务接口文档/getCode

// copy from src/server/gateway.ts

import { AuthData, RegistryConfigData } from '@/types/LocalConfig';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { isFocusEnv } from '@/utils';

export interface scanCodeProps {
    routeBase: string;
    ssoLoginUrl: string;
    ssoClientId: string;
}

const TIME_OUT = 3 * 1000;

export default class ScanCode {
    private client: AxiosInstance;
    private ssoLoginUrl: string;
    private clientId: string;
    private machineId: string;
    constructor(props: scanCodeProps, params: RegistryConfigData) {
        this.ssoLoginUrl = props?.ssoLoginUrl;
        this.clientId = params.client;
        this.machineId = params.machineId;
        this.client = axios.create({
            // https://me-pre.jdcloud.com/passport/api/general/get/qrcode?ReturnUrl=https://me-pre.jdcloud.com/gwork/admin/index.html/&clientId=824cd7bf40af4a2892e11d781533208f
            baseURL: `${props?.ssoLoginUrl}`,
            timeout: TIME_OUT,
            // baseURL: `https://me-pre.jdcloud.com`,
        });
    }
    request = (opts: AxiosRequestConfig & { polling: boolean; clientId: string }): AxiosPromise => {
        opts.url = opts.polling ? opts.url : this.ssoLoginUrl + opts.url;
        opts.params = !opts.polling
            ? {
                  ReturnUrl: this.ssoLoginUrl || '',
                  'x-client': (!isFocusEnv() && 'WEB') || this.clientId,
                  'x-did': this.machineId,
                  clientId: opts.clientId,
              }
            : { clientId: opts.clientId };
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let timer = setTimeout(() => {
            source.cancel(`请求${opts.url}超过${TIME_OUT / 1000}秒未响应`);
        }, TIME_OUT);

        opts.cancelToken = source.token;

        return this.client
            .request(opts)
            .then(function (response) {
                if (response.status === 200 && response.data?.errorCode === '0') {
                    return response;
                }

                return Promise.reject(response);
            })
            .finally(() => {
                clearTimeout(timer);
            });
    };
}

export let scanCodeInstance: ScanCode;

export function setScanCodeInstance(props: scanCodeProps, params: RegistryConfigData) {
    scanCodeInstance = new ScanCode(props, params);
}
