import React, { useCallback, useMemo, CSSProperties, useEffect } from 'react';
import { Spin, Checkbox, message, Input, Button, Tabs, Select, Modal, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isValidMobile, isValidEmail, LoginFormContext, LoginFormContextType } from '../utils';
import ResendCode from '../ResendCode';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { useTranslation } from 'react-i18next';
import logger from '@/utils/logger';
import NetworkSettingsModal from '@/components/Modals/NetworkSettingsModal';
import { BackNav } from '../comps';
import { IcVipLogin, IcNormalLogin } from '@/assets/svg';
import { TeamLoginTypeInfo, TeamLoginTypeRes, AuthDataRes } from '@/types/LoginPage';
import isUndefined from 'lodash/isUndefined';
import { debounce } from 'lodash';
import BJCA from '@/utils/XTXSAB';
import { getQRCode, pollingQrcode } from '@/api/scanCode';
import checkedImg from '@/assets/img/scanCode/checked.png';
import loadingBlack from '@/assets/img/scanCode/loading_black.png';
import loadingImg from '@/assets/img/scanCode/loading.png';
import loadingWebGov from '@/assets/img/szy.png';
import IconFont from '@/components/icon';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { changeEgovUrl } from '@/utils/tools';
import sentryManual from '@/utils/sentryManual';
import { APP_NAME, MODAL_URL } from '@/config/name';
import classNames from 'classnames';
const { TabPane } = Tabs;
const { Option } = Select;
const ModalTitle = `${APP_NAME}扫码登录`;

const log = logger.getLogger('MobileInputForm');
const { REACT_APP_DEFAULT_COUNTRY_CODE: DEFAULT_COUNTRY_CODE = '86' } = process.env;
const STATUS = {
    WAIT_SCAN: 'WAIT_SCAN', // 等待扫描
    WAIT_CONFIRM: 'WAIT_CONFIRM', // 扫描完成后  等待手机端确认
    EXPIRED: 'EXPIRED', // 二维码已过期
    SUCCESS: 'SUCCESS', // 验证成功
    WAIT_REDIRECT: 'WAIT_REDIRECT', // 确认登录，等待跳转
    REDIRECT: 'REDIRECT', // 跳转
    HAS_SCANNED: 'HAS_SCANNED', //
    FETCHING: 'FETCHING', // 加载中
    CANCEL: 'CANCEL',
};
const maskStyle: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,0.7)',
};
const centerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
};
const avatarStyle: CSSProperties = {
    width: 24,
    display: 'block',
    position: 'absolute',
    top: 110,
    left: 107,
};
function getFormatMobile(code: string | undefined) {
    let s = '';
    if (code && code.length > 7) {
        const c = code.split(' ').join('');
        s = `${c.slice(0, 3)} ${c.slice(3, 7)} ${c.slice(7)}`;
    } else if (code && code.length > 3) {
        const c = code.split(' ').join('');
        s = `${c.slice(0, 3)} ${c.slice(3)}`;
    } else {
        s = code || '';
    }
    return s;
}

let scanTimer: any = null;
let pollingTimer: any = null;
let waitConfirmTimer: any = null;
let currLoginType: any = null;
// eslint-disable-next-line complexity
export default function MobileInputForm() {
    const { t } = useTranslation('common');
    const {
        mobile,
        email,
        account,
        onLoginTypeChange,
        onTeamIdChange,
        onMobileChange,
        onEmailChange,
        onAccountChange,
        onLoginModeChange,
        autoLogin,
        resendLastTs,
        onAutoLoginChange,
        onSendCode,
        sendVerifyCode,
        navigate,
        loginType,
        loginMode,
        teamId,
        navigatePath,
        selectedTeam,
    } = React.useContext<LoginFormContextType>(LoginFormContext);
    const { onGlobalError, appRuntimeEnv, service, focusSDK } = React.useContext<
        GlobalContextConfig
    >(GlobalContext);

    const onlyMobileLogin = useMemo(() => {
        const onlyMobileLoginConfig = appRuntimeEnv.getUIConfig('onlyMobileLogin');
        return !!onlyMobileLoginConfig;
    }, [appRuntimeEnv]);

    const canVipLogin = useMemo(() => {
        const canLogin = appRuntimeEnv.getUIConfig('canVipLogin');
        if (!isUndefined(canLogin) && !canLogin) {
            return false;
        }
        if (onlyMobileLogin) {
            return false;
        }
        return true;
    }, [appRuntimeEnv, onlyMobileLogin]);

    const canCaLogin = useMemo(() => {
        const canCaLoginConfig = appRuntimeEnv.getUIConfig('canCaLogin');
        return true;
    }, [appRuntimeEnv]);

    const [loading, setLoading] = React.useState(false);
    const [enterpriseDomain, setEnterpriseDomain] = React.useState('');
    const [teamLoginTypeList, setTeamLoginTypeList] = React.useState([] as TeamLoginTypeInfo[]);
    const [nextStepInstance, setNextStepInstance] = React.useState({
        valid: false,
        onClick: () => {},
    });
    const [certList, setCertList] = React.useState([]);
    const [certId, setCertId] = React.useState('');
    const [caPwd, setCaPwd] = React.useState('');
    const [infoModal, setInfoModal] = React.useState(false);
    const [qrcode, setQrcode] = React.useState<{ qrCodeUrl: string }>({ qrCodeUrl: '' });
    const [qrcodeStatus, setQrcodeStatus] = React.useState(STATUS.WAIT_SCAN);
    const [realName, setRealName] = React.useState('');
    const [avatar, setAvatar] = React.useState('');
    const [userData, setUserData] = React.useState<AuthDataRes | null>(null);
    // const [scanCode, setScanCode] = React.useState(true);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // 每3秒调一次轮询 二维码 start
    function startPollingTimer(qrcode: any) {
        clearInterval(pollingTimer);
        pollingTimer = setInterval(() => {
            pollingQrcode({ polling: true, pollingUrl: qrcode.pollingUrl })
                .then(({ data }) => {
                    if (!data) {
                        setQrcodeStatus(STATUS.EXPIRED);
                        clearTimer();
                        return;
                    }
                    if (loginType !== 'QRCODE') {
                        clearInterval(pollingTimer);
                        clearTimeout(scanTimer);
                        clearTimeout(waitConfirmTimer);
                        clearTimer();
                        setQrcodeStatus(STATUS.EXPIRED);
                    }
                    let content = data.content || {};
                    if (content) {
                        // 轮询接口
                        setQrcodeStatus(content.qrStatus);
                        setRealName(content.realName);
                        setAvatar(content.avatar);
                        if (
                            content.qrStatus === STATUS.REDIRECT ||
                            content.qrStatus === STATUS.EXPIRED
                        ) {
                            clearInterval(pollingTimer);
                            clearTimeout(waitConfirmTimer);
                            if (content.qrStatus === STATUS.REDIRECT) {
                                setTimeout(() => {
                                    // '登陆成功
                                    fastLoginSelectTeam(content);
                                }, 1000);
                            }
                        } else if (content.qrStatus === STATUS.WAIT_CONFIRM) {
                            // '扫码成功'
                            setWaitConfirmTimer(1000 * qrcode.waitScanExpire);
                        } else if (content.qrStatus === STATUS.CANCEL) {
                            // '回到初始状态从新扫码'
                            clearInterval(pollingTimer);
                            getCode();
                        }
                    } else {
                        setQrcodeStatus(STATUS.EXPIRED);
                        clearTimer();
                    }
                })
                .catch((err) => {
                    log.debug('pollingStatus-error', err);
                    sentryManual.captureException(err, 'pollingStatus-error');
                    focusSDK.printLog('pollingStatus-error', err?.stack || err?.message || err);
                    setQrcodeStatus(STATUS.EXPIRED);
                });
        }, 3000);
    }
    function clearTimer() {
        clearInterval(pollingTimer);
        clearTimeout(scanTimer);
        clearTimeout(waitConfirmTimer);
    }

    const onlineCallback = async () => {
        focusSDK.printLog('二维码-online');
        clearTimer();
        getCode();
    };
    const offlineCallback = () => {
        focusSDK.printLog('二维码-offlineCallback');
        clearTimer();
        setQrcodeStatus(STATUS.EXPIRED);
    };

    useEffect(() => {
        window.addEventListener('online', onlineCallback);
        window.addEventListener('offline', offlineCallback);
        return () => {
            window.removeEventListener('online', onlineCallback);
            window.removeEventListener('offline', offlineCallback);
            clearTimer();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        currLoginType = loginType;
        if (loginType === 'QRCODE') {
            clearTimer();
            getCode();
        }
        // return () => {
        //     clearTimer();
        // };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginType]);

    function getCode() {
        setQrcodeStatus(STATUS.FETCHING); // 正在请求状态
        getQRCode({ polling: false })
            .then(({ data }) => {
                focusSDK.printLog('getCode-data', data);
                if (!data) {
                    setQrcodeStatus(STATUS.EXPIRED);
                    return;
                }
                if (data.errorCode === '0') {
                    let content = data.content || {};
                    setQrcode(content);
                    setQrcodeStatus(STATUS.WAIT_SCAN); // 等待扫码
                    // 获取到二维码后等待扫码
                    // 扫描成功后计时
                    waitScanTimeout(1000 * content.waitScanExpire);
                    // 轮训登录
                    startPollingTimer(content);
                } else {
                    setQrcodeStatus(STATUS.EXPIRED);
                }
            })
            .catch((err) => {
                log.debug('getCode-error', err);
                sentryManual.captureException(err, 'getCode-error');
                focusSDK.printLog('getCode-error', err?.stack || err?.message || err);
                setQrcodeStatus(STATUS.EXPIRED);
            });
    }
    function waitScanTimeout(time: any) {
        clearTimeout(scanTimer);
        scanTimer = setTimeout(() => {
            // '二维码已过期');
            setQrcodeStatus(STATUS.EXPIRED); // 等待扫码
        }, time);
    }
    function setWaitConfirmTimer(time: any) {
        clearTimeout(waitConfirmTimer);
        clearTimeout(scanTimer);
        waitConfirmTimer = setTimeout(() => {
            //  等待确认登录超时');
            setQrcodeStatus(STATUS.EXPIRED); // 等待确认跳转页面
        }, time);
    }
    const handleUserData = useCallback(
        async (data: AuthDataRes) => {
            setUserData(data);
            service.focusService.setToken(data.accessToken);
        },
        [service.focusService, setUserData]
    );
    // const onSelectTeam = useCallback(
    //     (teamId: string, data?: any) => {
    //         if (!data && !userData) {
    //             onGlobalError(new Error('Can not get user data when login.'));
    //             return;
    //         }
    //         focusSDK.login(
    //             data || userData,
    //             teamId,
    //             () => {
    //                 log.debug('focusSDK.login callback');
    //                 focusSDK.emit('loginSuccess');
    //                 // setTimeout(() => {
    //                 //     history.push('/');
    //                 // }, 0);
    //             },
    //             autoLogin
    //         );
    //     },
    //     [focusSDK, onGlobalError, userData, autoLogin]
    // );
    const fastLoginSelectTeam = useCallback(
        async (data: any) => {
            let res;
            try {
                res = await service.authService.fastLogin(
                    {
                        accessToken: data.twoToken.accessToken,
                        userId: data.userId,
                    },
                    {
                        'x-token': data.twoToken.accessToken,
                    }
                );
            } catch (error) {
                console.error(error);
            }
            console.log('fastLoginSelectTeam-res', res);
            if (!res) {
                // '回到初始状态从新扫码'
                clearInterval(pollingTimer);
                getCode();
                return;
            }
            await handleUserData(res);
            setUserData(res);
            selectedTeam(res);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setUserData, service.authService, selectedTeam]
    );
    function UserHead(name: string, avatar: string) {
        const avatarPic = (
            <div>
                <img
                    style={{ width: 66, borderRadius: 33, height: 66, objectFit: 'cover' }}
                    src={changeEgovUrl(getHeadImgThumbUrl(avatar, 150, 150))}
                />
                <img
                    src={changeEgovUrl(checkedImg)}
                    style={{
                        ...avatarStyle,
                    }}
                />
            </div>
        );
        const avatarText = (
            <div>
                <p style={{ margin: 0 }} className="avatar-first-chr">
                    {name ? name.slice(0, 1) : ''}
                </p>
                <img
                    src={changeEgovUrl(checkedImg)}
                    style={{
                        ...avatarStyle,
                    }}
                />
            </div>
        );
        return avatar ? avatarPic : avatarText;
    }
    // 二维码 end
    React.useEffect(() => {
        setTeamLoginTypeList(teamLoginTypeList);
    }, [teamLoginTypeList]);

    const [networkSettingsModalVisible, setNetworkSettingsModalVisible] = React.useState(false);

    const getTeamLoginType = React.useCallback(async () => {
        let typeList: TeamLoginTypeInfo[] = [] as TeamLoginTypeInfo[];
        try {
            const res: TeamLoginTypeRes = await service.authService.getTeamLoginType({
                domainName: enterpriseDomain,
            });
            if (res) {
                typeList = res.typeList;
                onTeamIdChange(res.teamId);
                onLoginTypeChange(typeList[0].type);
            }
            setTeamLoginTypeList(typeList);
            setEnterpriseDomain('');
            return typeList;
        } catch (err) {}
    }, [service.authService, enterpriseDomain, onTeamIdChange, onLoginTypeChange]);

    const checkMobileCb = React.useCallback(async () => {
        if (loading || !isValidMobile(mobile.split(' ').join(''))) {
            return;
        }
        setLoading(true);
        try {
            const res = await service.authService.verifyMobileLegal({
                countryCode: DEFAULT_COUNTRY_CODE,
                mobile,
            });
            const { result } = res;
            if (result === 'LEGAL') {
                await onSendCode();
            } else {
                message.error(t('login.invalidMobile'));
            }
        } catch (e) {
            sentryManual.captureException(e, 'login-mobile');
            focusSDK.printLog('login-mobile', (e as any)?.stack || (e as any)?.message || e);
            // ignore
        }
        setLoading(false);
    }, [loading, mobile, service.authService, t, onSendCode, focusSDK]);

    const sendCodeCb = React.useCallback(async () => {
        setLoading(true);
        await onSendCode();
        setLoading(false);
    }, [onSendCode]);

    const checkAndGetLoginType = useCallback(async () => {
        if (!enterpriseDomain) {
            return;
        }
        setLoading(true);
        const typeList = (await getTeamLoginType()) || [];
        setLoading(false);
    }, [enterpriseDomain, setLoading, getTeamLoginType]);

    const checkCaPwd = useCallback(async () => {
        setLoading(true);
        const submitLoginCallback = async (retObj: any) => {
            setLoading(true);
            try {
                const strClientRan = retObj.retVal;
                const { strUserCert, strSignedData } = retObj.ctx;
                const params = {
                    userCert: strUserCert,
                    userSignedData: strSignedData,
                    strRandom: strClientRan,
                };
                const submitLoginRes = await service.authService.loginByCertificationAuthority(
                    params
                );
                if (submitLoginRes.loginStatus.toLowerCase() === 'normal') {
                    selectedTeam(submitLoginRes);
                } else {
                    message.error('证书认证错误，请联系单位管理员！');
                }
            } catch (e) {
                message.error('证书认证错误，请联系单位管理员！');
            }
            setLoading(false);
        };

        try {
            CALogin(certId, caPwd, submitLoginCallback);
        } catch (e) {
            message.error('证书认证错误，请联系单位管理员！');
        }
        setLoading(false);
        // @ts-ignore
        // window.VerifyUserPIN(certId, caPwd, async (data: any) => {
        //     const { retVal } = data || {};
        //     if (retVal) {
        //         try {
        //             // @ts-ignore
        //             window.GetSignCert(certId, (data) => {
        //             });
        //             // const securityRes = await service.authService.getCaServerSecurity();
        //             // const { strServerCert, strServerRan, strSignedData } = securityRes;
        //             message.success('登录成功');
        //         } catch (err) {
        //             console.log(err);
        //         }
        //     } else {
        //         // @ts-ignore
        //         window.SOF_GetPinRetryCount(certId, (res: any) => {
        //             const { retVal: retryCount } = res;
        //             if (retryCount > 0) {
        //                 message.error('校验证书密码失败!您还有' + retryCount + '次机会重试!');
        //             } else if (retryCount === 0) {
        //                 message.error('您的证书密码已被锁死,请联系BJCA进行解锁!');
        //             } else {
        //                 message.error('登录失败');
        //             }
        //         });
        //     }
        // });
    }, [service.authService, CALogin, selectedTeam, certId, caPwd]);

    const CALogin = useCallback((certId, caPwd, callback) => {
        /*eslint-disable*/
        BJCA.SOF_Login(certId, caPwd, async (data: any) => {
            if (!data.retVal) {
                message.error(t('login.pwdFailed'));
                return;
            }
            try {
                const securityRes = await service.authService.getCaServerSecurity();
                const { strServerCert, strServerRan, strSignedData } = securityRes;
                if (!strServerCert || !strServerRan || !strSignedData) {
                    throw new Error();
                }
                BJCA.SOF_Base64Encode(strServerRan, () => {
                    BJCA.SOF_SignData(certId, strServerRan, (signVal: any) => {
                        BJCA.SOF_ExportUserCert(certId, (certVal: any) => {
                            callback({
                                retVal: strServerRan,
                                ctx: {
                                    strUserCert: certVal.retVal,
                                    strSignedData: signVal.retVal,
                                },
                            });
                        });
                    });
                });
            } catch (e) {
                message.error('证书认证错误，请联系单位管理员！');
            }
        });
    }, []);

    const initNextStepValid = useCallback(() => {
        let type = loginType;
        if (loginMode === 'vip' && enterpriseDomain) {
            type = 'DOMAIN';
        }
        switch (type) {
            case 'ACCOUNT':
                setNextStepInstance({
                    valid: !!account,
                    onClick: sendCodeCb,
                });
                break;
            case 'EMAIL':
                setNextStepInstance({
                    valid: !!email && isValidEmail(email),
                    onClick: sendCodeCb,
                });
                break;
            case 'MOBILE':
                setNextStepInstance({
                    valid: isValidMobile(mobile),
                    onClick: checkMobileCb,
                });
                break;
            case 'DOMAIN':
                setNextStepInstance({
                    valid: !!enterpriseDomain,
                    onClick: checkAndGetLoginType,
                });
                break;
            case 'CA':
                setNextStepInstance({
                    valid: !!certId && !!caPwd,
                    onClick: checkCaPwd,
                });
        }
    }, [
        loginType,
        loginMode,
        enterpriseDomain,
        account,
        email,
        mobile,
        certId,
        caPwd,
        setNextStepInstance,
        sendCodeCb,
        checkAndGetLoginType,
        checkMobileCb,
        checkCaPwd,
    ]);
    // 防止用户频繁操作
    const debounceNextStep = debounce(() => {
        if (!navigator.onLine) {
            message.error('当前网络未连接，请检查网络。', 6);
            return;
        }
        nextStepInstance.onClick();
    }, 500);
    React.useEffect(() => {
        initNextStepValid();
    }, [initNextStepValid]);

    const getUserListCb = React.useCallback(
        (data: any) => {
            if (data) {
                const ret = data.retVal;
                if (ret) {
                    const cList = ret
                        .split('&&&')
                        .filter((el: any) => el)
                        .map((el: any) => {
                            const [name, id] = el.split('||');
                            return { id, name };
                        });
                    setCertList(cList);
                    setCertId(cList[0].id);
                    onLoginTypeChange('MOBILE');
                } else {
                    setCertList([]);
                    setCertId('');
                    setCaPwd('');
                    // focusSDK.logout();
                    // message.warn('未找到CA证书');
                }
            }
        },
        [setCertList, onLoginTypeChange]
    );

    const isWin = appRuntimeEnv.platform === 'WINDOWS' || appRuntimeEnv.platform === 'IPAD';

    React.useEffect(() => {
        if (isWin && canCaLogin) {
            BJCA.init(
                function () {
                    // @ts-ignore
                    window.SOF_GetUserList(getUserListCb);
                },
                function () {
                    // if 是 CA 登录的
                    // focusSDK.getAuthInfo((userData: AuthData) => {
                    //     console.log('userData', userData);
                    // });
                    //sunxiaolin3 暂时禁止登出 （影响扫码登陆，登录页频繁切换登录方式  确定二维码登录后  二维码登录获取频繁失败）
                    // focusSDK.logout();
                }
            );
            BJCA.SetAlertFunction((strMsg: string) => {
                message.warn(strMsg);
            });
        }
    }, [isWin, canCaLogin, appRuntimeEnv.platform, setCertList, getUserListCb, focusSDK]);

    const nextStepComp = (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => debounceNextStep()}
            className="btn"
            style={
                !loading && nextStepInstance.valid
                    ? {
                          opacity: 1,
                          cursor: 'pointer',
                      }
                    : {
                          pointerEvents: 'none',
                      }
            }
        >
            {loading && (
                <div>
                    <Spin
                        indicator={
                            <LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />
                        }
                    />
                </div>
            )}
            <div
                style={{
                    margin: 12,
                    fontSize: 14,
                    color: 'rgb(255, 255, 255)',
                }}
            >
                {loginMode === 'register' ? t('login.agreeAndRegister') : t('nextStep')}
            </div>
        </div>
    );

    // const onlyMobileLogin = useMemo(() => {
    //     const onlyMobileLoginConfig = appRuntimeEnv.getUIConfig('onlyMobileLogin');
    //     return !!onlyMobileLoginConfig;
    // }, [appRuntimeEnv]);
    //
    // const canVipLogin = useMemo(() => {
    //     const canLogin = appRuntimeEnv.getUIConfig('canVipLogin');
    //     if (!isUndefined(canLogin) && !canLogin) {
    //         return false;
    //     }
    //     if (onlyMobileLogin) {
    //         return false;
    //     }
    //     return true;
    // }, [appRuntimeEnv, onlyMobileLogin]);

    const canRegister = useMemo(() => {
        const canReg = appRuntimeEnv.getUIConfig('canRegister');
        if (!isUndefined(canReg) && !canReg) {
            return false;
        }
        return true;
    }, [appRuntimeEnv]);

    const mobileInputComp = (
        <div className="mobileInput">
            <Input
                autoFocus
                placeholder={t('login.inputMobilePl')}
                value={mobile}
                onKeyDown={(e: any) => {
                    const { keyCode } = e;
                    if (keyCode === 13) {
                        checkMobileCb();
                    }
                }}
                onChange={(e: any) => {
                    onMobileChange(e.target.value);
                }}
                allowClear
            />
        </div>
    );

    const emailInputComp = (
        <div className="mobileInput">
            <Input
                autoFocus
                placeholder={t('login.inputEmailPl')}
                value={email}
                onKeyDown={(e: any) => {
                    const { keyCode } = e;
                    if (keyCode === 13) {
                        sendCodeCb();
                    }
                }}
                onChange={(e: any) => {
                    onEmailChange(e.target.value);
                }}
                allowClear
            />
        </div>
    );

    const accountInputComp = (
        <div className="mobileInput">
            <Input
                autoFocus
                placeholder={t('login.inputAccountPl')}
                value={account}
                onKeyDown={(e: any) => {
                    const { keyCode } = e;
                    if (keyCode === 13) {
                        sendCodeCb();
                    }
                }}
                onChange={(e: any) => {
                    onAccountChange(e.target.value);
                }}
                allowClear
            />
        </div>
    );

    const caInputComp = (
        <div className="mobileInput" style={{ height: 110 }}>
            <Select
                defaultValue="证书编号"
                placeholder="证书编号"
                style={{
                    width: '100%',
                    height: 50,
                    marginBottom: '10px',
                    color: '#a1a6ae',
                }}
            >
                {certList.length > 0 ? (
                    certList.map((el: any) => (
                        <Option className="mobileInputOption" value={el.id} key={el.id}>
                            {el.name}
                        </Option>
                    ))
                ) : (
                    <Option className="mobileInputOption" value="暂无数据" disabled>
                        暂无数据
                    </Option>
                )}
            </Select>
            <Input
                placeholder={t('login.inputCaPwdPl')}
                value={caPwd}
                type="password"
                onKeyDown={(e: any) => {
                    const { keyCode } = e;
                    if (keyCode === 13) {
                        checkCaPwd();
                    }
                }}
                onChange={(e: any) => {
                    setCaPwd(e.target.value);
                }}
                allowClear
            />
        </div>
    );
    // 证书登录使用说明
    const caInputCompSm = (
        <>
            <div
                style={{
                    fontSize: '12px',
                    color: '@primary-colorff',
                    cursor: 'pointer',
                }}
                onClick={() => setInfoModal(true)}
            >
                证书登录使用说明
            </div>
            <Modal
                className="Notice"
                title="证书登录使用说明"
                visible={infoModal}
                onCancel={() => {
                    setInfoModal(false);
                }}
                footer={null}
                centered={true}
            >
                <div>
                    <p>1、京办平台的证书登录需要使用信创版证书助手及信创版USBKEY访问登录；</p>
                    <p>
                        2、关于账号注册、证书申领、证书助手安装以及相关使用操作请联系上级单位或本单位证书管理员；
                    </p>
                    <p>3、关于证书到期更新、丢失、损坏补办等问题可联系单位证书管理员。</p>
                </div>
            </Modal>
        </>
    );
    // 二维码
    const qrcodeComp = (
        <div className="scan-code" id="scan-code">
            <div className="qrCode-img">
                <p>
                    使用
                    <span
                        onClick={() => {
                            MODAL_URL && showModal;
                        }}
                        className={classNames({
                            'qrCode-txt-span': !!MODAL_URL,
                        })}
                    >
                        {APP_NAME}APP
                    </span>
                    扫码登录
                </p>
                <div className="img">
                    <img src={changeEgovUrl(qrcode?.qrCodeUrl)} alt="" />
                    {!qrcode && <div>获取不到二维码</div>}
                    {/* 过期 */}
                    {qrcodeStatus === STATUS.EXPIRED ? (
                        <div style={{ ...maskStyle, ...centerStyle }} onClick={getCode}>
                            <IconFont
                                type="iconjs_ic_break"
                                style={{
                                    fontSize: '28px',
                                    color: '#ffffff',
                                }}
                            />
                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#fff',
                                    margin: '10px 0 0 0',
                                }}
                            >
                                刷新二维码
                            </p>
                        </div>
                    ) : null}
                    {/* 正在加载 */}
                    {qrcodeStatus === STATUS.FETCHING || qrcodeStatus === STATUS.WAIT_REDIRECT ? (
                        <div style={{ ...maskStyle, ...centerStyle }}>
                            <img
                                style={{ width: 20 }}
                                className="xuanzhuan"
                                src={changeEgovUrl(loadingImg)}
                            />

                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#fff',
                                    margin: '10px 0 0 0',
                                }}
                            >
                                加载中...
                            </p>
                        </div>
                    ) : null}
                    {/* 扫码成功 */}
                    {qrcodeStatus === STATUS.WAIT_CONFIRM ||
                    qrcodeStatus === STATUS.REDIRECT ||
                    qrcodeStatus === STATUS.WAIT_REDIRECT ? (
                        <>
                            <div
                                style={{
                                    ...maskStyle,
                                    ...centerStyle,
                                    background: 'rgba(0,0,0,0.6)',
                                }}
                            >
                                {UserHead(realName, avatar)}
                            </div>
                            <h1
                                style={{
                                    lineHeight: 1,
                                    color: '#232930',
                                    fontSize: 24,
                                    marginTop: 32,
                                    marginBottom: 12,
                                }}
                            >
                                扫码成功
                            </h1>
                            <p
                                style={{
                                    lineHeight: 1,
                                    color: '#6E6F71',
                                    fontSize: 12,
                                }}
                            >
                                {qrcodeStatus === STATUS.REDIRECT ||
                                qrcodeStatus === STATUS.WAIT_REDIRECT ? (
                                    <>
                                        正在登录
                                        <img
                                            width="12"
                                            className="xuanzhuan"
                                            src={changeEgovUrl(loadingBlack)}
                                        />
                                    </>
                                ) : (
                                    '请在移动端确认登录'
                                )}
                            </p>
                        </>
                    ) : null}
                </div>
            </div>
            <Modal
                className="Qcode"
                title={ModalTitle}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
                footer={null}
                width={646}
            >
                <img src={changeEgovUrl(loadingWebGov)} />
            </Modal>
        </div>
    );
    const mobileInputSectionComp = (
        <div className="mobileFormContainer">
            <div className="tab-line" />
            {loginMode === 'vip' ? (
                teamLoginTypeList.length ? (
                    <Tabs
                        defaultActiveKey={teamLoginTypeList[0].type}
                        onChange={(activeKey: string) => {
                            onLoginTypeChange(activeKey);
                        }}
                    >
                        {teamLoginTypeList.map((el) => {
                            const { typeName, type } = el;
                            switch (type) {
                                case 'MOBILE':
                                    return (
                                        <TabPane tab={typeName} key={type}>
                                            {mobileInputComp}
                                        </TabPane>
                                    );
                                case 'EMAIL':
                                    return (
                                        <TabPane tab={typeName} key={type}>
                                            {emailInputComp}
                                        </TabPane>
                                    );
                                case 'ACCOUNT':
                                    return (
                                        <TabPane tab={typeName} key={type}>
                                            {accountInputComp}
                                        </TabPane>
                                    );
                                default:
                                    return (
                                        <TabPane tab="手机号" key="MOBILE">
                                            {mobileInputComp}
                                        </TabPane>
                                    );
                            }
                        })}
                    </Tabs>
                ) : (
                    <div className="mobileInput">
                        <Input
                            autoFocus
                            placeholder={t('login.inputEnterpriseDomain')}
                            value={enterpriseDomain}
                            onKeyDown={(e: any) => {
                                const { keyCode } = e;
                                if (keyCode === 13) {
                                    checkAndGetLoginType();
                                }
                            }}
                            onChange={(e: any) => {
                                setEnterpriseDomain(e.target.value);
                            }}
                            allowClear
                        />
                    </div>
                )
            ) : onlyMobileLogin ? (
                mobileInputComp
            ) : (
                <Tabs
                    defaultActiveKey={loginType}
                    activeKey={loginType}
                    onChange={(activeKey: string) => {
                        onLoginTypeChange(activeKey);
                    }}
                    className="login-styles"
                >
                    <TabPane tab="扫码登录" key="QRCODE">
                        {qrcodeComp}
                    </TabPane>
                    <TabPane tab="手机号" key="MOBILE">
                        {mobileInputComp}
                    </TabPane>
                    <TabPane tab="邮箱" key="EMAIL">
                        {emailInputComp}
                    </TabPane>
                    {/* {isWin && canCaLogin && (
                        <TabPane tab="CA证书" key="CA">
                            {caInputComp}
                        </TabPane>
                    )} */}
                </Tabs>
            )}
        </div>
    );

    const loginTitleComp = (
        <h4 style={{ margin: 0, textAlign: 'center' }}>
            {appRuntimeEnv.getI18N(appRuntimeEnv.getUIConfig('login.title'), {
                productName: appRuntimeEnv.productName,
            })}
            <small>{appRuntimeEnv.key === 'beta' ? 'beta' : ''}</small>
        </h4>
    );

    return (
        <div className="mobileForm">
            {loginMode === 'vip' ? (
                <>
                    {!teamLoginTypeList.length ? (
                        <BackNav
                            clickHandle={() => {
                                onLoginModeChange('');
                                onLoginTypeChange('MOBILE');
                            }}
                            title={t('login.back')}
                            subtitle={t('login.vipLogin')}
                            desc={t('login.inputEnterpriseDomainLong')}
                        />
                    ) : (
                        <h4 style={{ margin: 0, textAlign: 'center' }}>
                            {appRuntimeEnv.getI18N(appRuntimeEnv.getUIConfig('login.title'), {
                                productName: appRuntimeEnv.productName,
                            })}
                            <small>{appRuntimeEnv.key === 'beta' ? 'beta' : ''}</small>
                        </h4>
                    )}
                    {mobileInputSectionComp}
                    <div>{nextStepComp}</div>
                    <div className="otherLogin">
                        <p>
                            <span>{t('login.otherLoginMode')}</span>
                        </p>
                        <Button
                            onClick={() => {
                                onLoginModeChange('');
                                setTeamLoginTypeList([]);
                                onLoginTypeChange('MOBILE');
                            }}
                        >
                            <IcNormalLogin />
                            {t('login.normalLogin')}
                        </Button>
                    </div>
                </>
            ) : loginMode === 'register' ? (
                <>
                    <BackNav
                        clickHandle={() => onLoginModeChange('')}
                        title={t('login.back')}
                        subtitle={t('login.newUserRegister')}
                    />
                    {mobileInputSectionComp}
                    {nextStepComp}
                </>
            ) : (
                <>
                    {loginTitleComp}
                    {mobileInputSectionComp}
                    <div>
                        {loginType === 'QRCODE' ? '' : nextStepComp}
                        {appRuntimeEnv.platform !== 'IPAD' && (
                            <div
                                className="noAccount-box"
                                style={{
                                    justifyContent: loginType === 'CA' ? 'space-between' : 'center',
                                }}
                            >
                                <span>
                                    <Checkbox
                                        style={{
                                            fontSize: 12,
                                        }}
                                        checked={autoLogin}
                                        disabled={
                                            qrcodeStatus === STATUS.WAIT_CONFIRM ||
                                            qrcodeStatus === STATUS.REDIRECT ||
                                            qrcodeStatus === STATUS.WAIT_REDIRECT
                                        }
                                        onChange={(e: any) => {
                                            focusSDK.printLog(
                                                '是否选中了30天登录',
                                                e.target.checked,
                                                JSON.stringify(e.target)
                                            );
                                            onAutoLoginChange(e.target.checked);
                                        }}
                                    >
                                        {t('login.autoLoginPl')}
                                    </Checkbox>
                                    <Tooltip
                                        placement={loginType === 'CA' ? 'bottom' : 'topLeft'}
                                        overlayClassName="loginDescript"
                                        title="勾选之后，30天之内每次启动应用时不需要重新登录，主动退出登录之后失效。"
                                    >
                                        <IconFont type="iconzw_ic_doubt" />
                                    </Tooltip>
                                </span>

                                {canRegister && (
                                    <span className="noAccount">
                                        {t('login.haveNoAccount')}？
                                        <a onClick={() => onLoginModeChange('register')}>
                                            {t('login.signupNow')}
                                        </a>
                                    </span>
                                )}
                                {loginType === 'CA' ? caInputCompSm : ''}
                            </div>
                        )}
                    </div>
                    {canVipLogin && (
                        <div className="otherLogin">
                            <p>
                                <span>{t('login.otherLoginMode')}</span>
                            </p>
                            <Button
                                onClick={() => {
                                    onLoginModeChange('vip');
                                    onLoginTypeChange('DOMAIN');
                                    onMobileChange('');
                                    onEmailChange('');
                                }}
                            >
                                <IcVipLogin />
                                {t('login.vipLogin')}
                            </Button>
                        </div>
                    )}
                </>
            )}
            <div className="debugSetting">
                {appRuntimeEnv.debugMode && (
                    <Button
                        style={{
                            height: 40,
                        }}
                        block
                        onClick={() => {
                            setNetworkSettingsModalVisible(true);
                        }}
                    >
                        环境设置
                    </Button>
                )}
            </div>
            {navigatePath === 'step2' && <ResendCode />}
            <NetworkSettingsModal
                visible={networkSettingsModalVisible}
                onCancel={() => {
                    setNetworkSettingsModalVisible(false);
                }}
            />
        </div>
    );
}
