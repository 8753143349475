import React from 'react';

import { Button } from 'antd';
import debounce from 'lodash/debounce';
import JEditor from '../..';
import Quill from 'quill';
import CursorPopover from '../CursorPopover';
import './index.less';
import { Employee, Session } from '@/types/chat';
import log from '@/utils/logger';
import { UserTag } from '@/components/Tags';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { changeEgovUrl } from '@/utils/tools';
import {
    convertEmployeeToString,
    filterDeptFullName,
    isGroupVisibleUnit,
} from '@/utils/chat/message';
import { Avatar } from '@/baseComponents/Avatar';
import CheckBox from '@/baseComponents/Checkbox';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import Bus from '@/utils/bus';
import { isFocusEnv } from '@/utils';
import { ebookNodataPng } from '@/components/DefaultPage/imgs';
import IconFont from '@/components/icon';
import { getDeptPositionDesc } from '@/models/user';
export interface MentionUserPluginProps {
    editor: JEditor | any;
    members: Partial<Employee>[];
    handleSearch: (input: string) => Partial<Employee>[];
    t: any;
    onVisibleChange?: (visible: boolean) => void;
    selectedSession: Session;
    groupMemberSessionId: string;
}

export interface MentionUserPluginState {
    users: Partial<Employee>[];
    selected: string[];
    text: string;
    visible: boolean;
    onvisible: boolean;
    multipleUserSelect: boolean;
    focusUser: any;
}
class MentionUserPlugin extends React.Component<
    MentionUserPluginProps & ChatState,
    MentionUserPluginState
> {
    ref_div: React.RefObject<HTMLDivElement> = React.createRef();
    curRef: CursorPopover | null = null;
    constructor(props: any) {
        super(props);
        this.onTextInput = debounce(this.onTextInput, 300);
        this.state = {
            users: props.members.slice(0, 30),
            selected: [],
            text: '',
            visible: false,
            multipleUserSelect: true,
            onvisible: false,
            focusUser: {},
        };
    }
    getHighlightDom() {
        const { text, users } = this.state;
        const { selectedSession } = this.props;

        const input = text.replace('@', '').replace(/\\/g, '\\').trim();
        let newUsers;
        const isShowUnit = !isGroupVisibleUnit(selectedSession);

        if (input && input.length > 0) {
            newUsers = users.map((item: Partial<Employee>) => {
                let replaceReg = new RegExp(input, 'g');
                let replaceString = '<span class="highlight-text">' + input + '</span>';
                const tempName = isShowUnit
                    ? item.unitName || filterDeptFullName(item.e_dept_full_name) || ''
                    : item.deptName || '';
                const result = getDeptPositionDesc(tempName, item.titleName || '');
                // console.log('职位信息', result);
                const hightDesc = result.replace(replaceReg, replaceString);
                // console.log('111', hightDesc);
                return {
                    ...item,
                    highName: item.name?.replace(replaceReg, replaceString),
                    // highUnitName: isShowUnit
                    //     ? item.unitName
                    //         ? item.unitName.replace(replaceReg, replaceString)
                    //         : filterDeptFullName(item.e_dept_full_name).replace(
                    //               replaceReg,
                    //               replaceString
                    //           )
                    //     : item.unitName,
                    // highDeptName: isShowUnit
                    //     ? item.deptName
                    //     : item.deptName
                    //     ? item.deptName.replace(replaceReg, replaceString)
                    //     : '',
                    hightDesc,
                };
            });
        } else {
            newUsers = users.map((item: any) => {
                const tempName = isShowUnit
                    ? item.unitName || filterDeptFullName(item.e_dept_full_name) || ''
                    : item.deptName || '';
                const result = getDeptPositionDesc(tempName, item.titleName || '');
                // console.log('职位信息111', result);
                return {
                    ...item,
                    highName: item.name,
                    // highUnitName: item.unitName
                    //     ? item.unitName
                    //     : filterDeptFullName(item.e_dept_full_name),
                    // highDeptName: item.deptName || '',
                    hightDesc: result,
                };
            });
        }
        return newUsers;
    }

    handleMentionKeyDown(e: React.KeyboardEvent<HTMLDivElement>, ok: Function) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('mention keydown', e.key);
        const key = e.keyCode === 32 ? 'space' : e.key;
        ok(key);
    }
    getAvatar(user: any) {
        if (user.userId === 'all') {
            return <div className="all-avatar">@</div>;
        } else if (user.userId === user.deptId || user.userId === user.unitDeptId) {
            return (
                <div className="org-avatar">
                    <IconFont type="iconic_app_im_Architecture" />
                </div>
            );
        } else {
            return (
                <Avatar
                    styleObj={{
                        width: '22px',
                        height: '22px',
                    }}
                    src={
                        user.avatar ? changeEgovUrl(getHeadImgThumbUrl(user.avatar, 150, 150)) : ''
                    }
                    className="avatarNoDraggable"
                    name={user.name}
                />
            );
        }
    }

    getItem(list: any) {
        const { selected, focusUser } = this.state;
        const { selectedSession } = this.props;
        return list.map((user: any, index: number) => {
            return (
                <div
                    key={index}
                    className={`mention-list-item ${
                        focusUser.userId && focusUser.userId === user.userId
                            ? 'mention-list-item-focus'
                            : ''
                    }`}
                    onClick={() => this.onUserSelect(user)}
                >
                    <CheckBox
                        className="mention-list-item-checkbox"
                        checked={selected.includes(convertEmployeeToString(user as Employee))}
                        fillColor
                    />

                    <div className="avatar">{this.getAvatar(user)}</div>
                    <div className="name-tag">
                        <div
                            className={`name ${user.role === 'user' ? 'name_user' : ''}`}
                            dangerouslySetInnerHTML={{ __html: user.highName }}
                        />
                        {user.role !== 'all' && user.role !== 'unit' && user.role !== 'dept' && (
                            <div className="descInfo">
                                <div
                                    className="descInfo_content"
                                    dangerouslySetInnerHTML={{
                                        __html: user.hightDesc,
                                    }}
                                />
                                {/* {!isGroupVisibleUnit(selectedSession) ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: user.highUnitName,
                                        }}
                                    />
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: user.highDeptName,
                                        }}
                                    />
                                )}
                                {user.titleName && <div>-{user.titleName}</div>} */}
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }
    getContent() {
        const { users, visible, onvisible, text } = this.state;
        const newUsers = this.getHighlightDom();
        const allList = newUsers.filter((item) => item.role === 'all');
        const orgList = newUsers.filter((item) => item.role === 'unit' || item.role === 'dept');
        const memberList = newUsers.filter((item) => item.role === 'user');
        const { t } = this.props;
        // console.log('users', users, 'newUsers', newUsers);
        // console.log('allList', allList, 'orgList', orgList, 'memberList', memberList);
        return (
            <div
                className={onvisible === true || !visible ? '' : 'editor-mention-container'}
                ref={this.ref_div}
                tabIndex={0}
                onKeyDown={(e) => this.handleMentionKeyDown(e, this.onKeyDown)}
            >
                <div className="mention-list">
                    {text === '' && this.getItem(allList)}
                    {orgList && orgList.length > 0 ? (
                        <>
                            {text === '' && <div className="category-title">组织信息</div>}
                            {this.getItem(orgList)}
                        </>
                    ) : (
                        ''
                    )}
                    {text === '' && <div className="category-title">人员信息</div>}
                    {this.getItem(memberList)}
                    {/* {users.length === 0 || (users.length === 1 && users[0].userId === 'all') ? (
                        <div key="no user" className="no-user">
                            <img src={changeEgovUrl(ebookNodataPng)} alt="" draggable="false" />
                            <span>{t('no_relative_user')}</span>
                        </div>
                    ) : (
                        ''
                    )} */}
                </div>
                {/* {users.length > 1 && ( */}
                <div className="at-footer-wrapper">
                    <p className="tip-content">空格键 - 选择，Enter键 - 确认</p>
                    <Button className="cancel" size="small" onClick={() => this.userSelectCancel()}>
                        取消
                    </Button>
                    <Button
                        className="confirm"
                        size="small"
                        type="primary"
                        onClick={() => this.userSelectOk()}
                    >
                        确定
                    </Button>
                </div>
                {/* )} */}
            </div>
        );
    }

    componentDidMount() {
        Bus.on(`message:mentionUser`, this.onMessageMention);
    }
    componentWillUnmount() {
        Bus.off('message:mentionUser', this.onMessageMention);
    }
    onMessageMention = (data: any) => {
        this.props.editor.events.emit('close-mention');
        this.props.editor.insertText('@');
        this.setState({
            onvisible: true,
        });
        this.setState({ users: data, text: '' });
        setTimeout(() => {
            if (!isFocusEnv()) {
                this.setState({ visible: false });
            }
            this.insertMentionLink(data);
        }, 100);
        this.props.editor.focus();
    };

    // 选择用户 鼠标点击或者空格选中
    onUserSelect(user?: Partial<Employee>) {
        if (user?.userId === 'all') {
            this.insertMentionLink([
                {
                    id: convertEmployeeToString(user as Employee),
                    type: 'user',
                    name: user.name,
                },
            ]);
            return;
        }
        const { selected } = this.state;
        const userString = convertEmployeeToString(user as Employee);
        const idx = selected.indexOf(userString);
        if (idx > -1) {
            selected.splice(idx, 1);
        } else {
            selected.push(userString);
        }
        this.setState({
            selected,
        });
    }
    userSelectCancel = () => {
        this.curRef?.hide();
        this.props.editor.focus();
    };

    // Enter操作并关闭弹框
    userSelectOk = () => {
        const { selected, users } = this.state;
        if (!selected.length) {
            this.userSelectCancel();
            return;
        }
        let selectedUsers = users.filter((user) => {
            return selected.includes(convertEmployeeToString(user as Employee));
        });
        const insertUsers = selectedUsers.map((user) => {
            return {
                id: convertEmployeeToString(user as Employee),
                type: 'user',
                name: user.name,
            };
        });
        if (!isFocusEnv()) {
            this.setState({
                visible: false,
            });
        }
        setTimeout(() => {
            this.insertMentionLink(insertUsers);
        }, 10);
        this.props.editor.focus();
    };

    insertMentionLink(values: any) {
        const { editor } = this.props;
        const range = editor.getSelection();
        if (!range) return;
        const { index } = range;
        const quill = editor.getQuill();
        if (!quill) return;
        const Delta = Quill.import('delta');
        const inputLen = this.state.text.length + 1;
        const deltaObj = new Delta().retain(index - inputLen).delete(inputLen);
        values.forEach((value: any) => {
            deltaObj.insert({
                'mention-link': value,
            });
        });
        quill.updateContents(deltaObj, 'user');
        try {
            if (!isFocusEnv()) {
                const currentRange = quill.getSelection();
                const { index: currentIndex } = currentRange;
                quill.insertText(currentIndex, ' ');
            } else {
                quill.insertText(index + values.length - 1, ' ');
            }
        } catch (e) {
            console.log('网页版@成员后插入空格报错', e);
        }

        this.setState({ text: '' });
        if (this.curRef) {
            this.curRef.hide();
        }
        if (this.state.onvisible) {
            this.setState({ onvisible: false });
        }
    }

    scrollList() {
        const ele = document.querySelector<HTMLDivElement>(
            '.editor-mention-container .mention-list-item-focus'
        );
        if (ele) {
            ele.scrollIntoView({
                block: 'end',
                inline: 'end',
            });
        }
    }

    onKeyDown = (key: string) => {
        const { users, focusUser } = this.state;
        // TODO: 这里看看如何处理键盘操作
        if (!this.state.users.length) return;
        // const idx = this.state.users.findIndex((user) => {
        //     return this.state.selected.includes(convertEmployeeToString(user as Employee));
        // });
        const idx = this.state.users.findIndex((user) => {
            return focusUser.userId && focusUser.userId === user.userId;
        });
        // console.log('keyDown', key);
        let nIdx = idx;
        switch (key) {
            case 'ArrowUp':
                nIdx = idx - 1;
                if (nIdx < 0) {
                    nIdx = users.length - 1;
                }

                break;
            case 'ArrowDown':
                nIdx = idx + 1;
                if (nIdx > users.length - 1) {
                    nIdx = 0;
                }
                break;
            case 'Enter':
                this.userSelectOk();
                break;
            case 'space':
                // console.log('空格', focusUser);
                if (focusUser.userId) {
                    this.onUserSelect(focusUser);
                }
                break;
            default:
                break;
        }
        // console.log('下标', nIdx, users[nIdx]);
        this.setState(
            {
                // selected: [convertEmployeeToString(users[nIdx] as Employee)] || [],
                focusUser: users[nIdx] || {},
            },
            () => {
                this.scrollList();
            }
        );
    };

    onTextInput = async (text: string) => {
        const input = text.replace('@', '').trim();
        const result = await this.props.handleSearch(input);
        this.setState({
            text: input,
        });
        if (this.ref_div.current) {
            this.ref_div.current.scrollTop = 0;
        }
        const { selected } = this.state;
        if (result.length === 0) {
            this.setState({
                users: [],
                selected: [],
                focusUser: {},
            });
            this.curRef?.setKeyPrevent(false);
            return;
        }
        this.curRef?.setKeyPrevent(true);
        let tempUser = {};
        if (result.length === 1) {
            tempUser = result[0];
        } else {
            tempUser = {};
        }
        this.setState({
            users: result,
            selected: selected,
            onvisible: false,
            focusUser: tempUser,
        });
    };

    onVisibleChange = (visible: boolean) => {
        this.props.onVisibleChange?.(visible);
        if (!visible) {
            this.setState({
                users: [],
                visible: false,
                selected: [],
                focusUser: {},
            });
            setTimeout(() => {
                this.setState({ text: '' });
            }, 100);
        } else {
            const users = this.props.handleSearch('');
            const { selectedSession, groupMemberSessionId } = this.props;
            const { focusUser } = this.state;
            const needShow =
                selectedSession.isSingle ||
                (selectedSession.isGroup && selectedSession.sessionId === groupMemberSessionId);
            if (users.length > 0 && needShow) {
                this.curRef?.setKeyPrevent(true);
                this.setState({
                    visible: true,
                    selected: [],
                    users,
                    // focusUser: focusUser.userId ? focusUser : users[0],
                    focusUser: {},
                });
            }
        }
    };

    render() {
        const { visible, users, onvisible } = this.state;
        // const onlyAllUser = users.length === 1 && users[0].userId === 'all';
        return (
            <div className={onvisible === true ? 'ismention' : ''}>
                <div
                    className={`chat-mention ${
                        visible && users.length ? '' : 'chat-mention-hidden'
                    }`}
                >
                    {/* {!onlyAllUser && this.getContent()} */}
                    {this.getContent()}
                </div>
                <CursorPopover
                    ref={(ref) => {
                        this.curRef = ref;
                    }}
                    bindings={['@']}
                    editor={this.props.editor}
                    content={null}
                    onKeyDown={this.onKeyDown}
                    onTextInput={this.onTextInput}
                    onVisibleChange={this.onVisibleChange}
                    withoutPopover={true}
                    visible={visible}
                />
            </div>
        );
    }
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, currentEmployee, groupMemberSessionId } = chat as ChatState;
    return { selectedSession, currentEmployee, groupMemberSessionId };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MentionUserPlugin);
