import { request } from '@/components/Joyspace/api/request';
import newListController from '../controllers/NewListController';
import { Method, pwaAttribute } from '@/attributes/pwaAttribute';
import { notUseJoySpaceOffline } from '@/components/Joyspace/util';
export const pageTypes: string[] = ['', 'page', 'report', '', 'sheet', 'link', 'file', 'websheet'];

class CreateMenuApi {
    @pwaAttribute(Method.get, newListController, 'createPageInFile')
    createPageInFile(
        body: {
            placename?: string; // 空间名称
            folderId?: string; // 文件夹id
            folder_id?: string; // 文件夹id
            spaceId?: string; // 空间ID
            categoryId?: string; // 分类ID
            to_groups?: Array<any>; //
        },
        folderName: string
    ) {
        return request({
            api: 'joyspace.pages.add',
            path: '/v1/documents',
            method: 'POST',
            body,
        });
    }
    @pwaAttribute(Method.get, newListController, 'createSheetInFile')
    createSheetInFile(
        params: {
            folderId?: string;
            spaceId?: string;
            categoryId?: string;
            placename?: string;
            gid?: string;
        },
        folderName?: string
    ) {
        return request({
            api: 'joyspace.table.add',
            path: '/v1/tables',
            method: 'POST',
            body: {
                ...params,
                pageType: 4,
                to_groups: [{ gid: params.gid, permission_type: 1 }],
            },
        });
    }
}

const createMenuApi = new CreateMenuApi();

// 创建专班文档
export function createTemplatePageInSpace(body: {
    spaceId?: string;
    categoryId?: string;
    templateId?: string;
    title?: string;
    placename?: string;
    pageType?: number;
    to_groups?: Array<any>;
    folderId?: string;
}) {
    return request({
        api: 'joyspace.pages.add',
        path: '/v1/pages',
        method: 'POST',
        body,
    });
}
// 创建专班表格
export function createTemplateTableInSpace(body: {
    spaceId?: string;
    categoryId?: string;
    templateId?: string;
    title?: string;
    placename?: string;
    pageType?: number;
}) {
    return request({
        api: 'joyspace.table.add',
        path: '/v1/tables',
        method: 'POST',
        body,
    });
}

export function createPageInFile(
    body: {
        placename?: string; // 空间名称
        folderId?: string; // 文件夹id
        folder_id?: string; // 文件夹id
        spaceId?: string; // 空间ID
        categoryId?: string; // 分类ID
        to_groups?: Array<any>; //
    },
    folderName?: string
) {
    if (notUseJoySpaceOffline()) {
        return request({
            api: 'joyspace.pages.add',
            path: '/v1/documents',
            method: 'POST',
            body,
        });
    }
    return createMenuApi.createPageInFile(body, folderName || '');
}
// 创建表格
export function getTables(spaceId: string, placename: string, gid?: string) {
    return request({
        api: 'joyspace.table.add',
        path: '/v1/tables',
        method: 'POST',
        body: {
            spaceId,
            placename,
            pageType: 4,
            to_groups: [{ gid: gid, permission_type: 1 }],
        },
    });
}

// 创建表格
export function createTableInFile(
    params: {
        folderId?: string;
        spaceId?: string;
        categoryId?: string;
        placename?: string;
        gid?: string;
    },
    folderName?: string
) {
    if (notUseJoySpaceOffline()) {
        return request({
            api: 'joyspace.table.add',
            path: '/v1/tables',
            method: 'POST',
            body: {
                ...params,
                pageType: 4,
                to_groups: [{ gid: params.gid, permission_type: 1 }],
            },
        });
    }
    return createMenuApi.createSheetInFile(params, folderName || '');
}

export function getTemplateCategories(pageType: number) {
    return request({
        api: 'joyspace.templates.queryTemplateCategory',
        method: 'GET',
        params: { language: 'zh_CN', pageType },
    });
}
export function getTemplateListByCategories(opts: {
    teamId: string;
    categoryId: string;
    lang?: string;
}) {
    return request({
        api: 'joyspace.template.listByCategoryIdAndTeamId',
        method: 'GET',
        params: opts,
    });
}
export function getTemplateById(templateId: string) {
    return request({
        api: 'joyspace.template.content',
        path: `/v1/templates/${templateId}/content`,
        method: 'GET',
    });
}

export function createTableInSpaceFile(categoryId: string, placename: string, gid?: string) {
    return request({
        api: 'joyspace.table.add',
        path: '/v1/tables',
        method: 'POST',
        body: {
            categoryId,
            placename,
            pageType: 4,
            to_groups: [{ gid: gid, permission_type: 1 }],
        },
    });
}

export enum WPSType {
    'word' = 'w',
    'excel' = 's',
}
export function createWPSWordInSpace(
    type?: WPSType,
    opts?: {
        category_id?: string;
        folderId?: string;
        folder_id?: string;
        space_id?: string;
        gid?: string;
    }
) {
    return request({
        api: 'joyspace.wps.templates',
        path: '/wps/templates',
        params: {
            ...opts,
            t: type || 'w',
            to_groups: [{ gid: opts?.gid, permission_type: 1 }],
        },
    });
}

/**
 * wps私有化 创建空文档
 * @param body
 * fileName 无标题.doc
 * fileType doc或者xls的mimetype
 */
export async function createPrivateWps(body: {
    fileName: string;
    fileType: string;
    folderId?: string;
    spaceId?: string;
    categoryId?: string;
    to_groups?: Array<any>;
}) {
    return request({
        api: 'joyspace.file.createWpsEmptyFile',
        // path: '/v1/files/wps',
        method: 'POST',
        body,
    });
}
