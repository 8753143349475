import React, { useContext } from 'react';
import { Modal, Input } from 'antd';
import DropMenu from './DropMenu';
import Context from '../../../context';

const { TextArea } = Input;
interface MainProps {
    progress: string;
    desc: string;
    onClose: () => void;
    onOk: (progress: string, desc: string, close: () => void) => void;
    readonly: boolean;
}

export default function (props: MainProps) {
    const { readonly } = props;
    const { t } = useContext(Context);
    const [visible, setVisible] = React.useState(true);
    const [progress, setProgress] = React.useState(props.progress);
    const [showMenu, setShowMenu] = React.useState(false);
    const [desc, setDesc] = React.useState(props.desc);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const close = (e: MouseEvent) => {
            setShowMenu(false);
        };
        document.addEventListener('mousedown', close, false);
        return () => document.removeEventListener('mousedown', close, false);
    }, []);

    return (
        <Modal
            title={t('basic_setting.status')}
            visible={visible}
            cancelText={t('cancel')}
            okText={t('confirm')}
            width={510}
            footer={readonly ? null : undefined}
            destroyOnClose={true}
            onCancel={() => {
                setVisible(false);
                props.onClose();
            }}
            onOk={() => {
                setLoading(true);
                props.onOk(progress, desc, () => setVisible(false));
            }}
            okButtonProps={{
                loading,
            }}
        >
            <div className="pm-status-main">
                <div>
                    <div className="pm-status-main-label">{t('detail.status')}</div>
                    <div className="pm-status-main-field">
                        <DropMenu
                            readonly={readonly}
                            show={showMenu}
                            progress={progress}
                            onChange={(value) => setProgress(value)}
                            onShow={(show) => setShowMenu(show)}
                        />
                    </div>
                </div>
                <div>
                    <div className="pm-status-main-label">{t('detail.description')}</div>
                    <TextArea
                        readOnly={readonly}
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        placeholder={t('detail.description_placeholder')}
                        className="pm-status-main-field"
                        maxLength={500}
                    />
                </div>
            </div>
        </Modal>
    );
}
