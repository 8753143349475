/**
 * 云空间上传文件进度列表组件
 */
import React, { useRef } from 'react';
import UploadBox from '@/components/UploadBox';
import { joyspaceKey } from '@/components/UploadBox/UploadControl';

import context, { useTranslation } from '../../context';
import { message } from 'antd';
function JoySpaceUploadBox() {
    const ref = useRef<HTMLDivElement | null>(null);
    const lang = useTranslation();
    // const retryLimitCallBackFunc = () => {
    //     // eslint-disable-next-line no-debugger
    //     // debugger;
    //     // @ts-ignore
    //     const count = ref.current?.getUploadInstance().getUploadingCount();
    //     if (count >= 1) return false;
    //     return true;
    // };
    const uploadSuccessCallBackFunc = () => {
        // message.success(lang('myspace.upload_end'));
    };
    const uploadFailCallBackFunc = () => {
        // message.error(lang('myspace.upload_failed'));
    };

    return (
        <UploadBox
            ref={ref}
            uploadControlName={joyspaceKey}
            toJoysSapce={true}
            headerTitle=""
            // retryLimitCallBackFunc={retryLimitCallBackFunc}
            uploadSuccessCallBackFunc={uploadSuccessCallBackFunc}
            uploadFailCallBackFunc={uploadFailCallBackFunc}
        />
    );
}

export default JoySpaceUploadBox;
