import { AuthData } from '@/types/LocalConfig';
import { FocusSDK, IFocusSDK, isFocusEnv } from '@/utils';
import getVersionBySdk from './getVersion';
import { LOG_DOMAN } from '@/config/name';
import { NetType } from '@/components/Modals/ChangeNetModal';

let hasLoad = false;
interface IExtObj {
    [key: string]: string;
}

enum Environment {
    TEST = 'test',
    PREV = 'prev',
    PROD = 'prod',
}

// 是否私有网络
export function isPrivateNet() {
    if ((window as any).__isPrivateNet !== undefined) {
        return (window as any).__isPrivateNet;
    }
    let privateDomain = ['beijing.egov.cn'];
    let currentHost = FocusSDK.getMeMainEntry();

    let findItem = privateDomain.find((item) => {
        return currentHost.indexOf(item) > -1;
    });

    (window as any).__isPrivateNet = !!findItem;

    return !!findItem;
}

export function initLogAnalytics(account: string, authData: AuthData, focusSDK: IFocusSDK): void {
    try {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET || hasLoad) {
            return;
        }
        hasLoad = true;
        let isBeta = focusSDK.isBeta();
        let version = getVersionBySdk(focusSDK);
        let win: any = window;
        win.jaq = win.jaq || [];
        win.jaq.push(['account', 'JA2022_1833789']);
        win.jaq.push(['domain', LOG_DOMAN]);
        win.jaq.push(['erp_account', account]);
        // 自定义参数上报
        let extParams: Array<string> = [];
        extParams.push(`deptName=${authData.teamUserInfo.displayDeptName}`);
        extParams.push(`environment=${isBeta ? Environment.TEST : Environment.PROD}`);
        extParams.push(`userId=${authData.userId}`);
        extParams.push(`version=${version}`);
        extParams.push(`clientType=${isFocusEnv() ? 'pc' : 'web'}`);
        extParams.push(`origin=${new URL(window.location.href).origin}`);
        extParams.push(`tenant=${authData.teamUserInfo.teamId}`);
        extParams.push(`appId=${focusSDK?.authInfo?.ddAppId}`);
        extParams.push('bp_ver=1');
        win.jaq.push(['extParams', extParams.join('&')]);
        // 单页面应用方案
        win.jaq.push(['anchorpvflag', 'true']);
        // hash路由方式开启
        // win.jaq.push(['anchorToUri',1]);
        (function () {
            let ja: any = document.createElement('script');
            ja.type = 'text/javascript';
            ja.async = true;
            ja.src = 'https://wl.jd.com/joya.js';
            let s: any = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ja, s);
        })();
    } catch (e) {
        console.error(e);
    }
}

export function analysisLog(log_type1: string, log_type2: string, ...ret: Array<string>): void {
    // try {
    //     if (isPrivateNet()) {
    //         return;
    //     }
    //     let win: any = window;
    //     win.log(log_type1, log_type2, ...ret);
    // } catch (e) {
    //     console.error(e);
    // }
}
