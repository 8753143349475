import { BusinessToken, OpenChat, Token as OToken } from './Types';
import bus from '@/utils/bus';
export type Token = OToken;
export default abstract class TokenProvider<BTOP = unknown> {
    /**
     * 获取businessToken
     * @param opitons
     */
    protected async getBusinessToken(opitons: BTOP): Promise<BusinessToken> {
        return {
            autoAppendBusinessToken: false,
            businessToken: '',
        };
    }

    protected emitChatTo(chatTo: OpenChat): void {
        if (chatTo.type === 'group') {
            bus.emit('chat:top-search-group-session', {
                id: chatTo.gid,
            });
        } else if (chatTo.type === 'single') {
            bus.emit('chat:top-search-single-session', {
                pin: chatTo.userId,
                teamId: chatTo.teamId,
                app: chatTo.app,
            });
        }
    }
    /**
     * 获取token 信息
     */
    abstract token(): Promise<Token>;

    /**
     * 失败操作
     * @param url
     */
    abstract failed(url: string): Promise<void>;

    /**
     * 用户登出
     */
    abstract logout(): Promise<void>;
}
