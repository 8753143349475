import React, { useEffect, useRef, useMemo } from 'react';
import { IColumnProps } from './Column';
import Line from './Line';
import { Loading } from '@jd/focus-desktop-comps';
import Category from './Category';
import { useTranslation } from '../../context';
import { ReadyState } from './types';
import Headers from './Headers';
import { Checkbox } from 'antd';
import ScrollView from '@/baseComponents/ScrollView';
import { MenuEnum } from '../../components/List/SharesFilters/config';
import { throttle } from 'lodash';
// import events from '@/components/Joyspace/context/events';
export interface IProps<T, C> {
    setSource: (pages: T[] & { id?: string }) => void;
    setCategories: (c: (C & { id?: string })[]) => void;
    columns: number;
    disableHeader?: boolean;
    children: React.ReactElement<IColumnProps<T, C>> | React.ReactElement<IColumnProps<T, C>>[];
    reload: () => void;
    onCreatRouter: (item: any) => any;
    isShowLoadMore?: boolean;
    loadMoreCallback?: () => void;
    status?: ReadyState;
    batch: boolean;
    selecteds: Set<any>;
    select: (set: Set<any>) => void;
    source: (T & { id?: string })[];
    categories: (C & { id?: string })[];
    headers: any;
    name: string;
    onChangeTableHeaderKey?: Function;
    defaultActiveKey?: MenuEnum;
    total: number;
    isLoadMore: boolean;
}

export default function <T, C>(props: IProps<T, C>) {
    const {
        disableHeader,
        source,
        categories,
        status,
        isShowLoadMore,
        setCategories,
        loadMoreCallback,
        setSource,
        onCreatRouter,
        select,
        batch = false,
        selecteds,
        headers,
        name,
        onChangeTableHeaderKey,
        defaultActiveKey = MenuEnum.SHARES,
        total,
        isLoadMore,
    } = props;
    const lang = useTranslation();
    const previousListLengthRef = useRef(0);
    const scrollViewRef: any = useRef();
    const columnProps = useMemo(
        () =>
            React.Children.map(props.children, (o) => o?.props).filter(
                (o) => !o.index || props.columns & o.index
            ),
        [props.children, props.columns]
    );
    const onScroll = () => {
        console.log(isLoadMore, 'isLoadMoreisLoadMoreisLoadMore', isShowLoadMore);
        if (isLoadMore) {
            return;
        }
        if (!isShowLoadMore) {
            return;
        }
        const cu: any = scrollViewRef.current;
        if (!cu) {
            return;
        }
        const scrollTop = cu.getScrollTop();
        const cHight = cu.getClientHeight();
        const sHight = cu.getScrollHeight();
        if (scrollTop + cHight >= sHight - 10) {
            loadMoreCallback?.();
        }
    };
    return (
        <div className="joyspace-list-table" id="joyspace-list-table">
            <Headers
                headers={headers}
                name={name}
                columnProps={columnProps}
                batch={batch}
                categories={categories}
                source={source}
                selecteds={selecteds}
                select={select}
                onChangeTableHeaderKey={onChangeTableHeaderKey}
                defaultActiveKey={defaultActiveKey}
            />
            <ScrollView ref={scrollViewRef} onScroll={throttle(onScroll, 400)}>
                <div data-body>
                    {categories?.map((o) => (
                        <Category
                            key={o.id}
                            data={o}
                            columnProps={columnProps}
                            remove={() => setCategories(categories.filter((_) => _ !== o))}
                            reload={props.reload}
                            onCreatRouter={props.onCreatRouter}
                        />
                    ))}
                    {source?.map((o) => (
                        <Line
                            key={o.id}
                            data={o}
                            remove={() => setSource(source.filter((_) => _ !== o))}
                            isSelected={props.selecteds.has(o)}
                            columnProps={columnProps}
                            reload={props.reload}
                        />
                    ))}
                    {isShowLoadMore && (
                        <div className="joyspace-list-more">
                            <span onClick={loadMoreCallback}>
                                {status === ReadyState.loading && (
                                    <Loading style={{ marginRight: '5px' }} />
                                )}
                                {lang('common.loadmore')}
                            </span>
                        </div>
                    )}
                </div>
            </ScrollView>
        </div>
    );
}
