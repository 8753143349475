import { Button, Input, List, message, message as toast, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'dvajs';
import { useTranslation } from 'react-i18next';
import { EbookState } from '@/models/ebook';
import { getMyAdministratorsList } from '@/api/ebook';
import './index.less';
import { ListItem } from '@jd/focus-desktop-comps';
import { openUserCardModal } from '@/components/AddressBook/common';
import { getDeptPositionDesc } from '@/models/user';
import ChatState from '@/types/chat/State';
import { Employee } from '@/types/chat';
import { ListItemData } from '@/components/ListItem';
import { debounce } from 'lodash';
import IconFont from '@/components/icon';

function AdministratorModal({
    openAdministratorListModal,
    setOpenAdministratorListModal,
    currentEmployee,
}: {
    openAdministratorListModal: boolean;
    setOpenAdministratorListModal: Function;
    currentEmployee: Employee;
}) {
    const [t] = useTranslation('chat');
    const [administratorList, setAdministratorList] = useState<ListItemData[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleCancel = useCallback(() => {
        setVisible(false);
        setOpenAdministratorListModal(false);
        setAdministratorList([]);
    }, [setOpenAdministratorListModal]);

    const handleSearch = useCallback(async () => {
        if (!currentEmployee?.deptId) {
            return;
        }
        setLoading(true);
        const [result, err] = await getMyAdministratorsList({
            deptId: currentEmployee?.deptId,
        });
        setLoading(false);

        if (err || !result?.myAdministratorList.length) {
            message.warning('单位暂无管理员');
            handleCancel();
            return;
        }

        setVisible(true);
        setAdministratorList(
            result?.myAdministratorList.map((item: any) => {
                return {
                    ...item,
                    id: `${item.userId}:${item.appId}:${item.teamId}`,
                    name: item?.realName,
                    avatar: item?.headImg,
                    desc: getDeptPositionDesc(item?.deptName || '', item?.titleName || ''),
                    labelInfoList: item?.labelInfoList || [],
                    app: item.appId,
                    teamId: item.teamId,
                    userId: item.userId,
                };
            })
        );
    }, [currentEmployee?.deptId, handleCancel]);

    useEffect(() => {
        if (!openAdministratorListModal) {
            setVisible(false);
            return;
        }
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAdministratorListModal]);

    const getExtraPart = (item: ListItemData) => {
        return <IconFont type="iconapp_ic_arrow_right" />;
    };

    if (!administratorList?.length) {
        return null;
    }

    return (
        <Modal
            maskClosable
            title="请选择单位管理员"
            visible={visible}
            footer={null}
            onCancel={() => {
                handleCancel();
            }}
            width="480px"
            bodyStyle={{
                width: 480,
                padding: '10px 0',
                minHeight: 46,
                maxHeight: 314,
                overflow: 'auto',
            }}
            centered={true}
        >
            <List
                loading={loading}
                dataSource={administratorList || []}
                renderItem={(item: any) => {
                    return (
                        <ListItem
                            data={item}
                            onClick={debounce((item: ListItemData) => {
                                openUserCardModal(item);
                            }, 500)}
                            extra={getExtraPart(item)}
                        />
                    );
                }}
            />
        </Modal>
    );
}
function mapStateToProps({ ebook, chat }: { ebook: EbookState; chat: ChatState }) {
    return {
        currentEmployee: chat.currentEmployee,
        openAdministratorListModal: ebook.openAdministratorListModal,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        setOpenAdministratorListModal(payload: boolean) {
            dispatch({ type: 'ebook/setOpenAdministratorListModal', payload });
        },
        getCurrentEmployee: () => {
            dispatch({ type: 'chat/getCurrentEmployee', payload: {} });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorModal);
