import { FocusSDK } from '@/utils';
import ICON from '@/assets/templateImgs/logo.png';
export const permissionStatus = async (): Promise<NotificationPermission | string> => {
    if (!window.Notification) {
        return '';
    }
    try {
        let status = await window.Notification?.requestPermission();
        return status;
    } catch (e) {
        return '';
    }
};

interface NotificationOptions {
    dir?: 'auto' | 'ltr' | 'rtl';
    body: string;
    icon?: string;
}

const showNotification = (title: string, options?: NotificationOptions) => {
    if (!window.Notification) {
        return;
    }
    const REACT_APP_OSS_BASE_URL = FocusSDK.getApplicationInfo()?.ossBaseUrl;
    let { dir, body, icon } = options || {};
    dir = dir || 'auto';
    body = body || '';
    // icon =
    //     icon ||
    //     process.env.REACT_APP_LOGO_URL ||
    //     `${REACT_APP_OSS_BASE_URL}/statics/icons/${process.env.REACT_APP_RUNTIME}/icon.png`;
    icon = icon || ICON;
    console.log('notification~~~~开始执行');
    let notification = new Notification(title, {
        dir,
        body,
        icon,
    });
    console.log('notification~~~~结束执行');
    notification.onclick = function () {
        window.focus();
    };
    return notification;
};

export default function (title: string, options?: NotificationOptions) {
    if (window.Notification) {
        let status: NotificationPermission | string = Notification.permission;
        if (status !== 'granted') {
            return false;
        }

        showNotification(title, options);
        return true;
    }

    return false;
}
