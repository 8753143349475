import React, { useEffect, useState, useContext } from 'react';
import { LoginFormContext } from '../../utils';
import {
    IconSwitch,
    IconRight,
    IconTopUp,
    IconGroup,
    IconJoyMeetingGroup,
} from '@/components/icon';
import styles from '@/components/styles';
import { BackNav } from '../../comps';
import {
    ApprovalStateEnum,
    ApprovaTeamUserInfo,
    ApprovaTeamUserInfoList,
    TeamUserInfo,
} from '@/types/LoginPage';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { useTranslation } from 'react-i18next';
import colors from '@/components/styles/colors';
import '../index.less';
import { message } from 'antd';
const { color } = styles;

function TeamItem({
    title,
    name,
    desc,
    styles,
    clickHandle,
}: {
    title: string;
    name: string;
    desc?: string | number;
    styles?: any;
    clickHandle: () => void;
}) {
    return (
        <div
            onClick={clickHandle}
            className="teamItem"
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '16px',
                marginBottom: '16px',
                borderRadius: '6px',
                boxShadow: '0px 8px 20px 0px rgba(0,0,0,0.1)',
            }}
        >
            <p
                className="title join-me-primary-bg"
                style={{
                    width: '48px',
                    lineHeight: '48px',
                    // background: color.primary,
                    borderRadius: '6px',
                    margin: 0,
                }}
            >
                {title}
            </p>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                    marginLeft: '15px',
                }}
            >
                <p
                    style={{
                        fontSize: '16px',
                        color: '#202020',

                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {name}
                </p>
                <p style={styles}>{desc}</p>
            </div>
            {IconRight}
        </div>
    );
}
export default function SelectTeam({
    approveList = [],
    teamUserInfoList = [],
    onSelectTeam,
    onJoinOtherTeam,
    onCreateTeam,
}: {
    approveList: ApprovaTeamUserInfoList;
    teamUserInfoList: TeamUserInfo[];
    onSelectTeam: (id: string) => void;
    onJoinOtherTeam: () => void;
    onCreateTeam: () => void;
}) {
    const { t } = useTranslation('common');
    const { navigate, onMobileChange, setResendLastTs } = useContext(LoginFormContext);
    const [toggle, setToggle] = useState(true);
    const [approveStateList, setApproveStateList] = useState(approveList);
    const [teamUserInfoStateList, setTeamUserInfoStateList] = useState(teamUserInfoList);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const canCreateTeamConfig = appRuntimeEnv.getUIConfig('canCreateTeam');
    const canCreateTeam =
        typeof canCreateTeamConfig === 'undefined' || canCreateTeamConfig === true;

    const canJoinTeamConfig = appRuntimeEnv.getUIConfig('canJoinTeam');
    const canJoinTeam = typeof canJoinTeamConfig === 'undefined' || canCreateTeamConfig === true;

    useEffect(() => {
        // approveList 与 teamUserInfoList可能会存在重复数据，即加入的团队已审核过了
        // 需要将approveList中与teamUserInfoList重复的数据去除
        const approveListTemp: ApprovaTeamUserInfoList = approveList.filter(
            (a: ApprovaTeamUserInfo) => {
                const idx: number = teamUserInfoList.findIndex(
                    (item: TeamUserInfo) => a.teamId === item.teamId
                );
                return idx === -1; // 只返回不重复的数据
            }
        );
        if (!toggle) {
            setApproveStateList(approveListTemp);
            setTeamUserInfoStateList(teamUserInfoList);
        } else {
            if (teamUserInfoList.length >= 3) {
                setTeamUserInfoStateList(teamUserInfoList.slice(0, 3));
                setApproveStateList([] as ApprovaTeamUserInfoList);
            }
            if (teamUserInfoList.length < 3) {
                setTeamUserInfoStateList(teamUserInfoList);
                setApproveStateList(approveListTemp.slice(0, 3 - teamUserInfoList.length));
            }
        }
    }, [approveList, teamUserInfoList, toggle]);
    return (
        <div
            className="mobileForm joinMe"
            style={{
                border: '1px solid rgba(240,243,243,1)',
                borderRadius: '6px',
                padding: 0,
            }}
        >
            <div
                style={{
                    padding: '20px 30px 0 30px',
                }}
            >
                <BackNav
                    clickHandle={() => {
                        onMobileChange('');
                        setResendLastTs(-1);
                        setTimeout(() => {
                            navigate('step1');
                        }, 0);
                    }}
                    icon={IconSwitch}
                    title={t('login.changeLogin')}
                    subtitle={t('login.selectTeam')}
                    desc={t('login.enterJoinedTeam')}
                />
            </div>

            <div>
                <div className="selectTeam">
                    <div className="teamScroller">
                        {teamUserInfoStateList?.map((item: TeamUserInfo, index: number) => {
                            return (
                                <TeamItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${item.teamId}-${index}`}
                                    title={item.teamFullName.slice(0, 1)}
                                    name={item.teamFullName}
                                    clickHandle={() => {
                                        if (item.emplEnabled !== 'DIS_ENABLED') {
                                            onSelectTeam(item.teamId);
                                        } else {
                                            message.destroy();
                                            message.warn(
                                                '该账号已被冻结，如有疑问请联系所在单位管理员进行核查。'
                                            );
                                        }
                                    }}
                                />
                            );
                        })}
                        {approveStateList?.map((item: ApprovaTeamUserInfo, index: number) => {
                            let desc = '';

                            let style;
                            if (item.approvalState === ApprovalStateEnum.WAIT) {
                                desc = t('login.waitPass');
                                style = {
                                    color: colors.warn,
                                };
                            } else if (item.approvalState === ApprovalStateEnum.NO_PASS) {
                                desc = t('login.rejected');
                                style = {
                                    color: colors.danger,
                                };
                            }
                            return (
                                <TeamItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${item.teamId}-${index}`}
                                    title={item.teamFullName.slice(0, 1)}
                                    name={item.teamFullName}
                                    desc={desc}
                                    styles={style}
                                    clickHandle={() => {
                                        // Modal.warn({
                                        //   title: t('login.userNotFound'),
                                        //   content: t('login.userNotFoundDesc')
                                        // });
                                    }}
                                />
                            );
                        })}
                        {toggle && teamUserInfoList.length + approveList.length > 3 ? (
                            <p
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    marginTop: '32px',
                                    cursor: 'pointer',
                                }}
                                className="join-me-primary-color"
                                onClick={() => {
                                    setToggle(false);
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: 6,
                                    }}
                                >
                                    {t('login.toggleTeam', {
                                        num: teamUserInfoList.length + approveList.length - 3,
                                    })}
                                </span>
                                {IconTopUp}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="fixedBottom">
                {canJoinTeam && (
                    <p onClick={onJoinOtherTeam}>
                        {IconGroup}
                        <span
                            style={{
                                marginLeft: 6,
                            }}
                        >
                            {t('login.joinOtherTeam')}
                        </span>
                    </p>
                )}
                {canCreateTeam && (
                    <p onClick={onCreateTeam}>
                        {IconJoyMeetingGroup}
                        <span
                            style={{
                                marginLeft: 6,
                            }}
                        >
                            {t('login.createNewTeam')}
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
}
