import Quill from 'quill';
import { stringEncode } from '@/utils/chat/index';
import { stickers_sets } from '@/utils/chat/stickers-config';
import { cache } from '@/utils/chat/ddemoji';
const EmbedBlot = Quill.import('blots/embed');
export default class Emoji extends EmbedBlot {
    static blotName = 'custom-emoji';
    static className = 'e-emoji';
    static tagName = 'IMG';
    static create(value: any) {
        const node = super.create(value) as any;
        let map = new Map();
        map.set('✌', '[胜利]');
        map.set('☕', '[咖啡]');
        node.innerText = '';
        node.setAttribute('data-value', value);
        node.setAttribute('src', cache[`./${stickers_sets[value]}`]);
        if (map.get(value) && !cache[`./${stickers_sets[value]}`]) {
            node.setAttribute('src', cache[`./${stickers_sets[map.get(value)]}`]);
        }
        node.classList.add('input-emoji');
        return node;
    }
    static value(domNode: HTMLLinkElement) {
        const { value } = domNode.dataset;
        return value;
    }
}
