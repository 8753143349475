/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { LoginFormContext, LoginFormContextType } from '../utils';
import { Btn } from '../comps';
import { IconArrowLeft } from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { AuthDataRes } from '@/types/LoginPage';
import { useTranslation } from 'react-i18next';
import { Input, message } from 'antd';
import './index.less';
// @ts-ignore
import { JSEncrypt } from 'jsencrypt';

const { REACT_APP_DEFAULT_COUNTRY_CODE: DEFAULT_COUNTRY_CODE = 86 } = process.env;
const countryCode = DEFAULT_COUNTRY_CODE;

export default function VerifyPwdForm({
    authCode,
    onSuccess,
    onNewUser,
    onNewTeam,
}: {
    authCode: string;
    onSuccess: (authData: AuthDataRes) => void;
    onNewUser: (authCode: string) => void;
    onNewTeam: (authData: AuthDataRes) => void;
}) {
    const { t } = useTranslation('common');
    const { onGlobalError: onLoginError, service } = useContext<GlobalContextConfig>(GlobalContext);
    const {
        mobile: receive,
        loginName,
        loginMode,
        loginType,
        teamId,
        onMobileChange,
        navigate,
    } = React.useContext<LoginFormContextType>(LoginFormContext);
    const [verifyFailed, setVerifyFailed] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordAgain, setPasswordAgain] = React.useState('');
    const [failedDesc, setFailedDesc] = React.useState('');
    const [failedAgainDesc, setFailedAgainDesc] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const checkPwdValid = (pwd: string) => {
        const regx = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
        return pwd.match(regx) !== null;
    };

    const checkPwdCb = React.useCallback(async () => {
        try {
            // if (!password) return;
            if (password !== passwordAgain) {
                setFailedAgainDesc('两次密码不一致，请重新输入');
                // setFailedDesc('密码不符合规则，请重新输入');
                return;
            }
            setLoading(true);
            const res: { focus_public_key: string } = await service.focusService.commonConfig();
            const { focus_public_key } = res;
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(focus_public_key);
            const rsaPwd = encrypt.encrypt(password);
            const params = {
                loginName: loginName || receive,
                passWord: rsaPwd,
                teamId,
                loginNameType: loginType,
                authCode,
            };
            if (loginMode === 'vip') {
                const content = await service.authService.exclusiveResetPassWord(params);
                onSuccess(content);
            } else {
                const content = await service.authService.resetPassWord(params);
                console.log('reset res', content);
                if (content.loginStatus === 'NEW_USER') {
                    setLoading(false);
                    onNewUser(authCode);
                    return;
                }
                if (content.loginStatus === 'NEW_TEAM') {
                    setLoading(false);
                    onNewTeam(content as any);
                    return;
                }
                onSuccess(content);
            }
            message.success(t('login.setSuccess'));
            setLoading(false);
        } catch (error) {
            if (error.code === '10160036') {
                setVerifyFailed(true);
                setPassword('');
                setLoading(false);
                return;
            }
            // onLoginError(error);
        }
    }, [
        password,
        passwordAgain,
        loginName,
        loginType,
        loginMode,
        teamId,
        authCode,
        onNewTeam,
        onNewUser,
        onSuccess,
        // onLoginError,
        receive,
        service.focusService,
        service.authService,
        t,
    ]);

    return (
        <div className="mobileForm">
            <div
                style={{
                    margin: '10px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    className="hover-color"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setPassword('');
                        onMobileChange('');
                        setTimeout(() => {
                            navigate('step1');
                        }, 0);
                    }}
                >
                    {IconArrowLeft}
                    <span
                        style={{
                            verticalAlign: 'middle',
                            marginLeft: 5,
                        }}
                    >
                        {t('login.back')}
                    </span>
                </div>
            </div>
            <h4 style={{ margin: 0 }}>{t('login.modifyPwd')}</h4>
            <div style={{ paddingBottom: 52 }}>{t('login.modifyPwdDesc')}</div>
            <div
                style={{
                    paddingBottom: 10,
                }}
            >
                <Input.Password
                    placeholder={t('login.inputPwdPl')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`mobileInput ${failedDesc && 'warn-info'}`}
                    style={{ width: '100%' }}
                    autoFocus
                />
            </div>
            {failedDesc && <div className="verifyFailedDesc">{failedDesc}</div>}
            <div
                style={{
                    padding: '10px 0',
                }}
            >
                <Input.Password
                    placeholder={t('login.inputPwdAgainPl')}
                    value={passwordAgain}
                    onFocus={() => {
                        setFailedDesc(checkPwdValid(password) ? '' : t('login.pwdFormatFailed'));
                    }}
                    onChange={(e) => setPasswordAgain(e.target.value)}
                    className={`mobileInput ${failedAgainDesc && 'warn-info'}`}
                    style={{ width: '100%' }}
                />
            </div>
            {failedAgainDesc && <div className="verifyFailedDesc">{failedAgainDesc}</div>}
            <div
                style={{
                    paddingTop: failedAgainDesc ? 20 : 34,
                }}
            >
                <Btn
                    text={t('nextStep')}
                    loading={loading}
                    disabled={!password || password.length !== passwordAgain.length || verifyFailed}
                    onClick={() => checkPwdCb()}
                />
            </div>
        </div>
    );
}
