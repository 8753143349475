import React, { useState, useEffect } from 'react';
import BaseCalendar from './../BaseCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { Tooltip, Popover } from 'antd';
import './index.less';
import { detectOS, detectBrowser } from '@/utils';

export interface HoverListItem {
    title?: string;
    value: any;
}
export interface HoverListProps {
    list: HoverListItem[];
}
export interface Iprops {
    current?: Dayjs;
    className?: string;
    data: HoverListItem[];
    handleSelect?: (p: HoverListItem, index: number) => void;
    open?: () => void;
    children?: any;
}
export default function TimePicker({
    current = dayjs(),
    data,
    handleSelect,
    className = '',
    open,
    children,
}: Iprops) {
    const [visible, setVisible] = useState(false);
    const [timePickerUuid, setTimePickerUUid] = useState(uuid());
    function handleItemSelect(current: HoverListItem, index: number) {
        if (handleSelect) {
            handleSelect(current, index);
        }
        setVisible(false);
    }
    function onVisibleChange(v: boolean) {
        setVisible(v);
        if (v) {
            open?.();
            let idx = data.findIndex((i) => i.title === current.format('HH:mm'));
            if (idx >= 0) {
                setTimeout(() => {
                    document
                        .getElementById('timer-picker-scroller-' + timePickerUuid)
                        ?.scrollTo(0, idx * 36);
                }, 0);
            }
        }
    }
    function uuid() {
        let s: any[] = [];
        let hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';
        let uuid = s.join('');
        return uuid;
    }
    return (
        <Popover
            visible={visible}
            // mouseLeaveDelay={10000}
            trigger="click"
            overlayClassName="time-picker-popover"
            placement="bottom"
            title={() => {
                return (
                    <div>
                        <ul
                            id={'timer-picker-scroller-' + timePickerUuid}
                            style={{
                                paddingRight:
                                    detectOS() === 'Win' && detectBrowser() !== 'Chrome' ? 10 : 0,
                            }}
                        >
                            {data.map((item, index) => {
                                return (
                                    <li onClick={(e) => handleItemSelect(item, index)} key={index}>
                                        {item.title}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            }}
            onVisibleChange={onVisibleChange}
        >
            {children ? (
                children
            ) : (
                <div className={`time-picker-inline ${className}`}>
                    <span className={`${visible ? 'active' : ''}`}>{current.format('HH:mm')}</span>
                </div>
            )}
        </Popover>
    );
}
