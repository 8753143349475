import { gatewayInstance } from '../server/gateway';
export interface CreateTeamAndEmployee {
    account: string;
    ddAppId: string;
    mobile: string;
    realName: string;
    teamFullName: string;
    teamId: string;
    teamShortName: string;
    headImg: string;
}
export interface InviteUserInfo {
    teamId: string;
    teamName: string;
    userName: string;
    userId: string;
    avatar: string;
}
export interface GenerateTeamCodeReq {
    qrCode: 'string';
    teamCode: 'string';
    expireTime: 'string';
    inviteUserName: 'string';
    teamName: 'string';
    deptName: 'string';
    avatar: 'string';
}
/**
 *
 * 创建团队
 * @returns
 * @param opts
 */
export async function createTeamAndEmployee(opts: {
    mobile: string;
    userTeamName: string;
    teamName: string;
    countryCode: string;
}) {
    return gatewayInstance.request({
        api: 'focus.createTeamAndEmployee',
        method: 'POST',
        body: opts,
    });
}

export function getInviteUserByTeamCode(opts: { teamCode: string }) {
    return gatewayInstance.request({
        api: 'focus.getInviteUserByTeamCode',
        method: 'POST',
        body: opts,
    });
}

export function sendJoinApprove(opts: { inviteTeamId: string; inviteUserId: string }) {
    return gatewayInstance.request({
        api: 'focus.sendJoinApprove',
        method: 'POST',
        body: opts,
    });
}
export function generateTeamCode(opts: { teamId: string; type: string }) {
    return gatewayInstance.request({
        api: 'focus.generateTeamCode',
        method: 'POST',
        body: opts,
    });
}
export function refreshTeamCode(opts: { teamId: string; type: string }) {
    return gatewayInstance.request({
        api: 'focus.refreshTeamCode',
        method: 'POST',
        body: opts,
    });
}

export function msgInvite(opts: {
    teamId: string;
    inviteMembers: any[];
    expireTime?: number;
    lang?: string;
}) {
    return gatewayInstance.request({
        api: 'focus.msgInvite',
        method: 'POST',
        body: opts,
    });
}

export function getInviteUserRole(opts: { teamId: string }) {
    return gatewayInstance.request({
        api: 'focus.getInviteUserRole',
        method: 'POST',
        body: opts,
    });
}

export function agreeJoinTeam(opts: {
    inviteTeamId: string;
    invitedUserMobile: string;
    invitedUserName: string;
}) {
    return gatewayInstance.request({
        api: 'focus.agreeJoinTeam',
        method: 'POST',
        body: opts,
    });
}

export function rejectJoinTeam(opts: {
    inviteTeamId: string;
    invitedUserMobile: string;
    invitedUserName: string;
}) {
    return gatewayInstance.request({
        api: 'focus.rejectJoinTeam',
        method: 'POST',
        body: opts,
    });
}
