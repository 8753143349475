import React from 'react';
import { Tag } from 'antd';
import IconFont from '@/components/icon';

export default function FollowTag(props: any) {
    const { left = 0, right = 0 } = props;
    return (
        <span style={{ marginLeft: left, marginRight: right }}>
            <IconFont type="iconic_yipingfen" style={{ color: '#FFA000' }} />
        </span>
    );
}
