/* 创建页、编辑页在使用 */
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import './index.less';
import { Drawer, Spin, Input, Upload, message, Tooltip, Dropdown, Menu, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAuthInfo, getAppId, isFocusEnv, FocusSDK } from '@/utils';
import FileService from '@jd/jdee-fs-desktop';
import { lookup } from 'mime-types';
import { FileStatus } from '@/components/Calendars/Attachment';
import { getFileIconByMime, getFileUrl, downLoadFile } from '@/utils/chat/index';
import { TaskResourceBizType, TaskResourcePermissionType } from '@/types/work';
import { CalendarIcon } from '@/components/Calendars/Common';
import { uploadingPng, searchDeletePng } from '@/assets/img/calendar';
import GlobalContext from '@/context/GlobalContext';
import { nanoid } from 'nanoid';
import { changeEgovUrl } from '@/utils/tools';
import {
    buildPagePathKey,
    buildJoyspaceIconUrl,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import { PageType } from '@/components/Joyspace/const';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import IconFont from '@/components/icon';
import { createWPSWord } from '@/components/Joyspace/components/CreateMenu/createWPSWord';
import createDoc, {
    createDocByTemplate,
} from '@/components/Joyspace/components/CreateMenu/createDoc';
import { createWPSExcel } from '@/components/Joyspace/components/CreateMenu/createWPSExcel';
import { ICurrentRouteInfo } from '@/components/Joyspace/types';
import bus from '@/utils/bus';
import { createUploadTask } from '@/server/FileService';
import { Excel_3xImg, wordImg, Word_3xImg } from '@/utils/joyspace';
import { duccConfigI18 } from '@/utils/i18n';
import UploadBox from '@/components/UploadBox';

const { Dragger } = Upload;
const LIMIT_SIZE = 300 * 1024 * 1024;
const {
    REACT_APP_FILE_SERVICE_APPKEY,
    REACT_APP_FILE_SERVICE_HOST,
    REACT_APP_JANUS_HOST,
} = process.env;

const progressingMap = new Map();

function WorkAttachmentComp({
    disabled,
    fileList,
    onFinish,
    onRemove,
    mode,
    isHead,
    children,
    multiple,
    uploaderProxy,
    autoShowMenuFnSetter = function () {},
}: {
    disabled: boolean;
    fileList: any[];
    onFinish: (file: any) => Promise<null | { resourceId: string } | any>;
    onRemove: (resourceIds: string) => void;
    mode?: string;
    isHead?: boolean;
    multiple?: boolean;
    children?: any;
    autoShowMenuFnSetter?: Function;
    uploaderProxy?: boolean;
}) {
    const { t } = useTranslation('work');
    const [currentFileList, setCurrentFileList] = useState<any[]>(fileList);

    const [uploadControlInstance, setUploadControlInstance] = useState<any>();
    const queueRef = useRef<{ list: any[]; runing: boolean }>({ list: [], runing: false });
    const reflectionRef = useRef(children);
    const uploadRef = useRef<null | any>(null);
    const { appRuntimeEnv, focusSDK } = useContext(GlobalContext);
    const { fileService, gateway, joyspaceApiHost } = appRuntimeEnv;
    const { appKey, hostOrigin } = fileService;
    const { host, stage, protocol } = gateway;
    const currentRouteInfo: ICurrentRouteInfo = {
        categoryId: '',
        spaceId: '',
        teamId: '',
    };
    useEffect(() => {
        const stateListener = (stateParams: any) => {
            const { fileId, state, progress, file } = stateParams;
            console.log(333, stateParams);
        };
        const uploadControlInstance = uploadRef.current?.getUploadInstance();
        if (!uploadControlInstance?.on || !uploaderProxy) {
            return;
        }
        setUploadControlInstance(uploadControlInstance);
        uploadControlInstance.on('upload-control-state-event', stateListener);
        return () => {
            uploadControlInstance.off('upload-control-state-event', stateListener);
        };
    }, [uploadRef?.current]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        setCurrentFileList((list) => {
            const newList: any[] = list.filter((file: any) =>
                [FileStatus.Uploading, FileStatus.Init].includes(file.status)
            );
            return fileList
                .map((file: any) => {
                    return {
                        ...file,
                        fileName: file.name,
                        fileId: file.bizId || nanoid(20),
                        fileUrl: file.url,
                        status: FileStatus.Done,
                    };
                })
                .concat(newList);
        });
    }, [fileList]);
    useEffect(() => {
        function handle() {
            // if (mode === 'create') {
            //     currentFileList.forEach((file: any) => {
            //         if (file.status === FileStatus.Init || file.status === FileStatus.Uploading) {
            //             console.info(file.taskInstance);
            //             file.taskInstance?.cancel();
            //         }
            //     });
            //     console.info('cancle-attachment-upload create');
            //     bus.emit('cancle-attachment-upload', false);
            // } else {
            const target = currentFileList.find((item: any) =>
                [FileStatus.Uploading, FileStatus.Init].includes(item.status)
            );
            // console.log(target, '查找是否有附件正在上传');
            if (target) {
                // console.log('附件正在上传，不可关闭');
                bus.emit('cancle-attachment-upload', true);
            } else {
                // console.log('没有附件正在上传，可关闭');
                bus.emit('cancle-attachment-upload', false);
            }
            // }
        }
        function cancleForce() {
            const targetArr = currentFileList.filter((item: any) =>
                [FileStatus.Uploading, FileStatus.Init].includes(item.status)
            );
            targetArr.forEach((v) => {
                deleteAttachment(null, v);
            });
        }
        bus.on(`close-task-detail`, handle);
        bus.on(`close-task-detail-with-cancle-attachment-upload`, cancleForce);
        return () => {
            bus.off(`close-task-detail`, handle);
            bus.off(`close-task-detail-with-cancle-attachment-upload`, cancleForce);
        };
    }, [currentFileList, mode]); // eslint-disable-line
    useEffect(() => {
        async function handleCreateDocument(res: any) {
            const { joyspaceHost } = appRuntimeEnv;
            const path = buildPagePathKey(res.page_type).subPath;
            const link = `${joyspaceHost}/${path}/${res.id}`;
            const { title, page_type, type } = res;
            const file = {
                fileName: res.title || t('unname'),
                size: 0,
                fileSize: 0,
                fileType: `joyspace/${res.page_type}/${res.type}`,
                fileId: res.id,
                fileUrl: `${link}?jdme_router=${encodeURIComponent(res.linkH5)}`,
                percent: 100,
                status: FileStatus.Done,
                taskInstance: null,
                // extended: JSON.stringify(res),
                bizType: TaskResourceBizType.JOYSPACE,
                icon: buildJoyspaceIconUrl({
                    title,
                    pageType: page_type.toString(),
                    type,
                    apiHost: joyspaceApiHost,
                }),
                resourceId: nanoid(),
                permission: res.permissionType || null,
            };
            onFinish([file]);
        }
        bus.on('joyspace:documentCreated', handleCreateDocument);

        return () => {
            bus.off('joyspace:documentCreated', handleCreateDocument);
        };
    }, [t, onFinish, appRuntimeEnv, joyspaceApiHost]);
    // 自动展开‘操作菜单’
    const autoShowFn = useCallback(
        (time) => {
            if (reflectionRef.current?.click) {
                setTimeout(() => {
                    reflectionRef.current.click();
                }, time || 0);
            }
        },
        [reflectionRef]
    );
    useEffect(() => {
        autoShowMenuFnSetter(() => autoShowFn);
    }, [autoShowFn, autoShowMenuFnSetter]);
    const joyspaceUploader = useCallback(
        async (files) => {
            let result;
            if (files.length > 10 - currentFileList.length) {
                return message.warn('附件总数不能超过10个');
            }
            try {
                result = uploadControlInstance.addFiles(files);
            } catch (e) {
                console.log(444, e);
                if ((e as any).message === 'zeroSize') {
                    message.warn('不能上传大小为0的文件');
                }
            }
            console.log(1008, currentFileList.length, files, result);
            return result;
        },
        [uploadControlInstance, currentFileList]
    );
    const uploadSuccessCallBackFunc = useCallback(
        (a) => {
            const { file, fileId, progress, state } = a;
            onFinish({
                ...file,
                fileId,
                percent: progress,
                status: state,
                fileType: file.type,
                fileName: file.name,
                fileSize: file.size,
                size: file.size,
            });
        },
        [onFinish]
        // eslint-disable-line react-hooks/exhaustive-deps
    );
    const uploadFailCallBackFunc = useCallback((a) => {
        console.log('uploadFailCallBackFunc', a);
    }, []);
    function deleteConfirm(e: any, item: any) {
        item.taskInstance?.cancel();
        e.stopPropagation();
        Modal.confirm({
            title: t('tip delete'),
            content: <span className="nimble-con">{t('tip task delete Attachment')}</span>,
            okText: t('confirm'),
            cancelText: t('cancel'),
            className: 'task-common-modal-lb',
            onOk() {
                deleteAttachment(e, item);
            },
        });
    }
    async function deleteAttachment(e: any, item: any) {
        if (item.status === FileStatus.Uploading || item.status === FileStatus.Init) {
            if (progressingMap.get(item.fileId)) {
                try {
                    progressingMap.get(item.fileId).cancel();
                } catch (error) {
                    console.error(error);
                }
            }
            return setCurrentFileList((list: any[]) => {
                let idx = list.findIndex((a: any) => a.fileId === item.fileId);
                if (idx >= 0) {
                    list.splice(idx, 1);
                    return [...list];
                }
                return list;
            });
        }
        uploadControlInstance.deleteFile(item.fileId || item.bizId);
        onRemove(item.fileId || item.bizId);
    }
    async function uploadFile(file: any) {
        const authInfo = await getAuthInfo();
        // const fs = new FileService({
        //     appKey: appKey || '', // 文件服务appkey
        //     host: hostOrigin,
        //     janus: {
        //         host: `${protocol}://${host}`,
        //         appId: getAppId() || '', // janus网关appid
        //         // @ts-ignore
        //         stage: stage,
        //     },
        //     auth: {
        //         // 认证信息
        //         xToken: authInfo['x-token'], // 用户登录token
        //         xTeamId: authInfo['x-team-id'], // 用户所在teamId
        //         xClient: authInfo['x-client'], // 客户端类型
        //     },
        // });
        const blob: any = file.getAsFile ? file.getAsFile() : file;

        const task = createUploadTask('id', blob, {
            needAuthn: 1, // 是否需要下载健全，可选参数默认需要
            fileName: blob.name, // 文件名称
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream', // 文件类型
            fileSize: blob.size, // 文件大小
        });
        let fileObj: any = {
            fileName: blob.name,
            size: blob.size,
            fileType: blob.type || lookup(blob.name) || 'application/octet-stream',
            fileId: blob.uid || nanoid(20),
            fileUrl: '',
            percent: 0,
            status: FileStatus.Init,
            taskInstance: task,
            icon: getFileIconByMime(blob.type || lookup(blob.name) || 'application/octet-stream'),
            isRemove: true,
        };
        setCurrentFileList((list: any[]) => {
            return [...list, fileObj];
        });

        task?.on('progress', (progress: any) => {
            progressingMap.set(fileObj.fileId, task);
            // window.console.log(progress, progress.progress);
            let percent = progress.progress * 100;
            setCurrentFileList((list: any[]) => {
                let idx1 = list.findIndex((a: any) => a.fileId === fileObj.fileId);
                if (idx1 >= 0) {
                    let newFileList = [
                        ...list.slice(0, idx1),
                        {
                            ...list[idx1],
                            percent: percent,
                            status: FileStatus.Uploading,
                            isRemove: true,
                        },
                        ...list.slice(idx1 + 1),
                    ];
                    return newFileList;
                }
                return list;
            });
        });
        task?.on('state', async (state1: any) => {
            if (state1.state === 'completed') {
                // 处理上传完成状态变化
                const result = state1;
                fileObj.fileUrl = result.downloadUrl;

                await onFinish(fileObj);
                setCurrentFileList((list: any[]) => {
                    let idx = list.findIndex((a: any) => {
                        return a.fileId === fileObj.fileId && a.status !== FileStatus.Done;
                    });
                    if (idx >= 0) {
                        list.splice(idx, 1);
                    }
                    return [...list];
                });
            }
            if (state1.state === 'failed') {
                return setCurrentFileList((list: any[]) => {
                    let idx = list.findIndex((a: any) => a.fileId === fileObj.fileId);
                    if (idx >= 0) {
                        let newAttachment = [...list.slice(0, idx), ...list.slice(idx + 1)];
                        return newAttachment;
                    }
                    console.error(t('attachment upload failed'));
                    message.error(t('attachment upload failed'));
                    return list;
                });
            }
        });
        task?.start();
    }

    function handleBeforeUpload(file: any, FileList: any[]) {
        if (!file.type) {
            const reader = new FileReader();
            reader.onload = function () {
                uploadFile(file);
            };
            reader.onerror = function () {
                // it's a directory!
                message.warn(t('Upload folders are not supported'));
            };
            reader.readAsArrayBuffer(file.slice(0, 1));
            return false;
        }
        uploadFile(file);
        return false;
    }

    function downloadFile(item: any) {
        if (item.status === FileStatus.Uploading) {
            return;
        }
        if (item.fileType.startsWith('joyspace')) {
            let fileUrl = item.fileUrl;
            try {
                const { joyspaceHost } = appRuntimeEnv;
                const path = buildPagePathKey(Number(item.fileType.split('/')[1])).subPath;
                fileUrl = `${joyspaceHost}/${path}/${item.fileId}`;
            } catch (error) {
                console.error(error);
            }
            return DeeplinkEvent.open(changeEgovUrl(fileUrl));
        }
        if (isFocusEnv()) {
            FocusSDK.downloadFile(changeEgovUrl(item.fileUrl), {
                saveAs: true,
                filename: item.fileName,
                onStarted: () => {},
                onProgress: () => {},
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                },
                onFinish: (file: { path: string; filename: string }) => {},
            });
        } else {
            // web 端下载文件
            const name = item.fileName;
            const url = getFileUrl(item.fileUrl);
            downLoadFile(name, url);
        }
    }

    function handleFileClick() {
        const input = document.createElement('INPUT');
        input.setAttribute('type', 'file');
        if (multiple && uploaderProxy) {
            input.setAttribute('multiple', 'multiple');
        }
        // input.setAttribute('multiple', 'false');
        input.onchange = (ev: any) => {
            if (ev.target.files && ev.target.files.length > 0) {
                if (uploaderProxy) {
                    let fileArr = Array.from(ev.target.files);
                    fileArr = fileArr.filter((file: any) => {
                        if (file.size > LIMIT_SIZE) {
                            message.warn(t('A maximum of 300MB files are supported'));
                            return false;
                        }
                        if (file?.size === 0) {
                            message.warn(t('A single attachment must be greater than 0M'));
                            return false;
                        }
                        return true;
                    });
                    fileArr.length && joyspaceUploader(fileArr);
                    return;
                }
                // console.log('本地文件', ev.target.files);
                let file = ev.target.files[0];
                if (file.size > LIMIT_SIZE) {
                    return message.warn(t('A maximum of 300MB files are supported'));
                }
                if (file.size === 0) {
                    return message.warn(t('A single attachment must be greater than 0M'));
                }
                handleBeforeUpload(file, []);
            }
        };
        input.click();
        autoShowFn(500);
    }

    function handleUploadJoyspace() {
        const { joyspaceHost } = appRuntimeEnv;
        const id = nanoid();
        const callback = async ({
            status,
            callbackId,
            results,
        }: {
            status: 'ok' | 'cancel';
            callbackId: string;
            results: {
                id: string;
                title: string;
                pageType: number;
                type: string;
                link: string;
                linkH5: string;
                permissionType: any;
            }[];
        }) => {
            if (id !== callbackId) {
                return;
            }
            focusSDK.off('openJoyspaceDocSelector:result', callback);
            if (status === 'cancel') {
                return;
            }
            if (results.length === 0) {
                return;
            }

            const list = results.map((r) => {
                const path = buildPagePathKey(r.pageType).subPath;
                const link =
                    r.pageType === PageType.Form ? r.link : `${joyspaceHost}/${path}/${r.id}`;
                const { title, pageType, type } = r;
                return {
                    fileName: r.title || t('unname'),
                    size: 0,
                    fileSize: 0,
                    fileType: `joyspace/${r.pageType}/${r.type}`,
                    fileId: r.id,
                    fileUrl: `${link}?jdme_router=${encodeURIComponent(r.linkH5)}`,
                    percent: 100,
                    status: FileStatus.Done,
                    taskInstance: null,
                    // extended: JSON.stringify(r),
                    bizType: TaskResourceBizType.JOYSPACE,
                    icon: buildJoyspaceIconUrl({
                        title,
                        pageType: pageType.toString(),
                        type,
                        apiHost: joyspaceApiHost,
                    }),
                    resourceId: nanoid(),
                    isRemove: true,
                    permission: r.permissionType || null,
                };
            });
            onFinish(list);
            // const rs = await onFinish(list);
            // setCurrentFileList((fileList: any[]) => {
            //     const newlist = fileList.map((r: any) => ({
            //         ...r,
            //         percent: 0,
            //         fileName: r.name,
            //         size: r.size,
            //         fileId: r.bizId || nanoid(20),
            //         fileUrl: r.url,
            //         status: FileStatus.Done,
            //         creatorUserId: r?.creatorUserId,
            //         isRemove: true,
            //     }));
            //     return [...newlist];
            // });
        };

        focusSDK.on('openJoyspaceDocSelector:result', callback);
        focusSDK.emit('openJoyspaceDocSelector', {
            callbackId: id,
            max: 10 - currentFileList.length,
        });
    }

    function getAttachmentIcon(item: any) {
        const { fileType, fileName, name } = item;
        if (fileType.startsWith('joyspace')) {
            try {
                const strs = fileType.split('/');
                if (strs[1] === '6') {
                    return getFileIconByMime(
                        strs.slice(2).join('/') || 'application/octet-stream'
                    )[0];
                } else {
                    return buildJoyspaceIconUrl({
                        title: fileName || name,
                        pageType: strs[1],
                        type: strs.slice(2).join('/') || '',
                        apiHost: `${joyspaceApiHost}-api`,
                    });
                }
            } catch (error) {
                return getFileIconByMime(fileType || 'application/octet-stream')[0];
            }
        }
        return getFileIconByMime(fileType || 'application/octet-stream')[0];
    }
    const createDoc = createDocByTemplate({
        globalContext: useContext(GlobalContext),
    });

    return (
        <div className="attachment-container base-attachment-container">
            <div className="flex-box">
                <Dropdown
                    trigger={['hover']}
                    placement="topRight"
                    overlay={
                        <Menu>
                            <Menu.ItemGroup key="g1" title="选择">
                                <Menu.Item key="sel1" onClick={handleFileClick}>
                                    <div className="doc-menu">
                                        <IconFont
                                            type="iconapp_btn_document_4"
                                            style={{ color: '#FFB416' }}
                                        />
                                        <span>{t('Add Local File')}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item onClick={handleUploadJoyspace}>
                                    <div className="doc-menu">
                                        <IconFont
                                            type="iconapp_ic_bardoc"
                                            style={{ color: '#4661FF' }}
                                        />
                                        <span>
                                            {t('Add joyspace document', {
                                                joyspace: duccConfigI18.joyspace,
                                            })}
                                        </span>
                                    </div>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Menu.ItemGroup key="g2" title="新建">
                                <Menu.Item key="new1" data-name="doc" onClick={() => createDoc()}>
                                    <div className="doc-menu">
                                        <img className="doc-img" src={changeEgovUrl(wordImg())} />
                                        <span>{t('create document')}</span>
                                    </div>
                                </Menu.Item>
                                {
                                    // 通过ducc配置wpsTarget来控制 joyspaceWpsTarget
                                }
                                {/* {appRuntimeEnv?.joyspaceWpsTarget === 'private' && (
                                    <Menu.Item
                                        data-name="wpsdoc"
                                        onClick={() => createWPSWord(appRuntimeEnv)}
                                    >
                                        <div className="doc-menu">
                                            <img
                                                className="doc-img"
                                                src={changeEgovUrl(Word_3xImg())}
                                            />
                                            <span>{t('WPS document')}</span>
                                        </div>
                                    </Menu.Item>
                                )} */}
                                {/* {appRuntimeEnv?.joyspaceWpsTarget === 'private' && (
                                    <Menu.Item
                                        data-name="wpsspreadsheet"
                                        onClick={() => createWPSExcel(appRuntimeEnv)}
                                    >
                                        <div className="doc-menu">
                                            <img
                                                className="doc-img"
                                                src={changeEgovUrl(Excel_3xImg())}
                                            />
                                            <span>{t('WPS excel')}</span>
                                        </div>
                                    </Menu.Item>
                                )} */}
                            </Menu.ItemGroup>
                        </Menu>
                    }
                    overlayStyle={{ minWidth: '120px', width: '120px' }}
                    disabled={disabled || currentFileList.length >= 10}
                    className={`${
                        disabled || currentFileList.length >= 10 ? 'attachment-item-disable' : ''
                    }`}
                >
                    {(children && (
                        <i
                            className="reflection"
                            style={{ opacity: 0, right: currentFileList.length > 0 ? '' : '0' }}
                            ref={reflectionRef}
                        >
                            {children}
                        </i>
                    )) || (
                        <div
                            style={{ marginRight: 24 }}
                            className={`attachment-item ${
                                disabled ? 'attachment-item-disable' : ''
                            }`}
                        >
                            <div className="attachment-button">
                                <IconFont type="iconapp_btn_newfile" style={{ marginRight: 8 }} />
                                {t('create document')}
                            </div>
                        </div>
                    )}
                </Dropdown>
            </div>
            {/* 附件样式调整sxl  ---end */}

            <div className="task-custom-upload-list">
                {currentFileList.length > 0 &&
                    currentFileList.map((item: any, index: number) => {
                        return (
                            <div
                                className="file-item"
                                key={item.resourceId || item.fileId}
                                onClick={() => downloadFile(item)}
                            >
                                <CalendarIcon
                                    width="auto"
                                    className={`left ${
                                        item.status === FileStatus.Done ||
                                        item.status === FileStatus.completed
                                            ? ''
                                            : 'file-icon-loading'
                                    }`}
                                    src={
                                        item.status === FileStatus.Done ||
                                        item.status === FileStatus.completed
                                            ? changeEgovUrl(getAttachmentIcon(item))
                                            : uploadingPng
                                    }
                                />
                                <span className="name ellipsis">{item.fileName || item.name}</span>
                                {(mode === 'modify' && (
                                    <IconFont
                                        type="iconic_pc_calender_download"
                                        className="download-icon"
                                        style={{
                                            width: '14px',
                                            height: '14px',
                                            marginLeft: '8px',
                                        }}
                                    />
                                )) ||
                                    null}
                                {!disabled && (
                                    <IconFont
                                        className="lb-conclude delete-icon"
                                        style={{
                                            marginLeft: '8px',
                                        }}
                                        type="iconic_pc_calender_delete"
                                        onClick={(e) => deleteConfirm(e, item)}
                                    />
                                )}
                                <div
                                    className="process"
                                    style={{
                                        visibility:
                                            item.status === FileStatus.Done ||
                                            item.status === FileStatus.completed
                                                ? 'hidden'
                                                : 'visible',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: `${item.percent}%`,
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <UploadBox
                ref={uploadRef}
                uploadControlName="joyspace-hybrid-task"
                toJoysSapce={false}
                headerTitle="任务附件"
                retryLimitCallBackFunc={() => {
                    let ret =
                        10 >
                        currentFileList.length +
                            (uploadRef.current?.getUploadInstance?.()?.getUploadingCount?.() || 0);
                    if (!ret) {
                        message.warning(`附件总数不能超过10个`);
                        // message.warning(`附件最多添加${10 - currentFileList.length}个`);
                    }
                    return ret;
                }}
                uploadSuccessCallBackFunc={uploadSuccessCallBackFunc}
                uploadFailCallBackFunc={uploadFailCallBackFunc}
                className="uploader-hybrid-task"
            />
        </div>
    );
}

export default WorkAttachmentComp;
