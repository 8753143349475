import { connect } from 'dva';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

function mapStateToProps({ work }: any) {
    const { detailState } = work;
    return {
        fullIframeModalVisible: detailState.fullIframeModalVisible,
        fullIframeModalUrl: detailState.fullIframeModalUrl,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeFullIframeModal: (visible: boolean, url: string) =>
            dispatch({
                type: 'work/changeFullIframeModal',
                payload: { fullIframeModalVisible: visible, fullIframeModalUrl: url },
            }),
    };
}
function FullIframeModal({
    fullIframeModalUrl,
    fullIframeModalVisible,
    changeFullIframeModal,
}: {
    fullIframeModalVisible: boolean;
    fullIframeModalUrl: string;
    changeFullIframeModal: (visible: boolean, url: string) => void;
}) {
    const handleClose = useCallback(() => {
        changeFullIframeModal(false, '');
    }, [changeFullIframeModal]);

    return (
        <Modal
            visible={fullIframeModalVisible}
            width="100%"
            destroyOnClose={true}
            style={{
                height: '100%',
                top: 0,
                left: 0,
                transformOrigin: '0 0',
                boxShadow: 'none',
                boxSizing: 'border-box',
            }}
            onCancel={handleClose}
            bodyStyle={{ padding: 0 }}
            // closable={false}
            footer={null}
            getContainer={() => document.querySelector('.appContent') || document.body}
            wrapClassName="work-full-modal-wrapper"
        >
            <iframe src={changeEgovUrl(fullIframeModalUrl)} frameBorder="0" />
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FullIframeModal);
