/**
 * 群会话开关设置接口
 */

import { SessionSettingsType } from '@/types/chat/enum';
import log from '@/utils/logger';
import {
    GroupApprovalRule,
    GroupBannedPostOperation,
    GroupModifyField,
    Session,
} from '@/types/chat';
import ChatManager from '@jd/jdee.im.sdk';
import ImService from '@/server/ImService';
import lodashPick from 'lodash/pick';
import { SyncReqBody } from '@jd/jdee.im.sdk/lib/es/protocol/Sync';
import CHAT_NOTICE from '@/contant/chat';
import { isTopSession } from '@/utils/chat/message';

const logger = log.getLogger('ImService:groupSession');

export class SessionSettingService {
    public static chatManager: ChatManager | undefined;

    /**
     *  设置session状态 消息置顶
     *  @param sessionId 会话ID
     *  @param status 置顶状态数据 true: 置顶  false: 非置顶
     */
    static async setSessionTop(sessionId: string, status: boolean): Promise<boolean> {
        await this.chatManager?.method.session.setStatus(sessionId, {
            top: status ? 1 : 0,
        });
        return true;
    }

    /**
     *  设置session状态 消息通知
     *  @param sessionId 会话ID
     *  @param status 消息通知状态数据 true: 消息通知已关闭  false: 消息通知已开启
     */
    static async setSessionShield(sessionId: string, status: boolean): Promise<boolean> {
        await this.chatManager?.method.session.setStatus(sessionId, {
            shield: status ? 1 : 0,
        });
        return true;
    }

    /**
     *  设置session状态 阅后即焚
     *  @param sessionId 会话ID
     *  @param time 阅后即焚时间 0 关闭 1/2/5/30/60/60*24 分钟
     */
    static async setSessionMessageBurn(sessionId: string, time: number): Promise<boolean> {
        // console.log('messageBurn', sessionId, time);
        await this.chatManager?.method.session.setStatus(sessionId, {
            messageBurn: time || 0,
        });
        return true;
    }

    /**
     *  设置群会话 是否可搜索
     *  @param sessionId 会话ID
     *  @param status 可搜索状态数据 true: 可以搜索  false: 不可以搜索
     */
    static async setGroupSessionCanSearch(sessionId: string, status: boolean): Promise<boolean> {
        const canSearch = status ? 1 : 0;
        await this.chatManager?.method.group.set(sessionId, GroupModifyField.FILED_SEARCH_B, {
            canSearch: canSearch,
        });
        return true;
    }

    /**
     *  设置群 全员可邀请开关
     *  @param sessionId 会话ID
     *  @param status 加群认证方式状态值
     *  false <=> preapproval: 允许任何人邀请成员 初始化为preapproval
     *  true <=> identity: 只能群主或管理员能主动邀请进群，主动进群和普通成员邀请会被拒绝
     */
    static async setGroupSessionApprovalRule(sessionId: string, status: boolean): Promise<boolean> {
        const approvalRule = status ? GroupApprovalRule.PRE_APPROVAL : GroupApprovalRule.IDENTITY;
        await this.chatManager?.method.group.set(sessionId, GroupModifyField.FILED_APPROVAL_B, {
            approvalRule,
        });
        return true;
    }

    /**
     *  设置群会话 全员禁言
     *  @param sessionId 会话ID
     *  @param status 全员禁言状态值
     *  false <=> REMOVE_BAN_ALL: 全部禁言已关闭
     *  true <=> BAN_ALL: 全部禁言已开启
     */
    static async setGroupSessionBannedPost(sessionId: string, status: boolean): Promise<boolean> {
        const operation = status
            ? GroupBannedPostOperation.BAN_ALL
            : GroupBannedPostOperation.REMOVE_BAN_ALL;
        const param = {
            gid: sessionId,
            operation: operation,
        };
        try {
            await this.chatManager?.method.group.bannedPost(param);
        } catch (e) {
            console.log(`setGroupSessionBannedPost-----`, e);
        }
        return true;
    }

    /**
     *  设置群会话 是否允许查看群成员信息（不包括管理员）
     *  @param sessionId 会话ID
     *  @param status 允许查看群成员信息状态值
     *  false <=> 1: 全员禁止查看群成员名片
     *  true <=> 2: 全员可以查看群成员名片 默认值为null 全员可以查看群成员名称
     */
    static async setGroupSessionCardView(sessionId: string, status: boolean): Promise<boolean> {
        const forbidViewCard = status ? 2 : 1;
        await this.chatManager?.method.group.set(sessionId, GroupModifyField.FILED_CARDVIEW_B, {
            forbidViewCard,
        });
        return true;
    }

    /**
     *  设置群会话 是否可按部门选择成员
     *  "searchByDepartment": (Integer） 0 不开启、1 开启
     *  @param sessionId 会话ID
     *  @param status 是否可按部门选择成员状态值
     *  false <=> 0: 不显示部门信息，且不可根据部门搜索
     *  true <=> 1: 显示部门信息，且可根据部门搜索
     */
    static async setSessionGroupVisibleUnit(sessionId: string, status: boolean): Promise<boolean> {
        const visibleUnit = status ? 0 : 1; // 反着来 0可见  1不可见
        // console.log('visibleUnit', visibleUnit);
        await this.chatManager?.method.group.set(sessionId, GroupModifyField.FILED_VISIBLE_UNIT, {
            visibleUnit,
        });
        return true;
    }

    /**
     *  设置群会话 是否开启显示成员单位名称
     *  @param sessionId 会话ID
     *  @param status 是否显示成员单位名称
     */
    static async setSessionGroupChatVisibleUnit(
        sessionId: string,
        status: boolean
    ): Promise<boolean> {
        const chatVisibleUnit = status ? 1 : 0;
        // console.log('chatVisibleUnit', chatVisibleUnit);
        await this.chatManager?.method.group.set(
            sessionId,
            GroupModifyField.FILED_CHAT_VISIBLE_UNIT,
            {
                chatVisibleUnit,
            }
        );
        return true;
    }

    /**
     *  设置群会话 是否允许全员修改群信息
     *  "permissionCheck": (Integer）是否开启权限检查 0 不开启 1 开启
     *  @param sessionId 会话ID
     *  @param status 是否允许全员修改群信息状态值
     *  false <=> 0: 禁止非管理员修改群信息
     *  true <=> 1: 合员可以修改群信息
     */
    static async setGroupSessionPermission(sessionId: string, status: boolean): Promise<boolean> {
        const permissionCheck = status ? 0 : 1;
        await this.chatManager?.method.group.set(
            sessionId,
            GroupModifyField.FILED_PERMISSION_CHECK,
            {
                permissionCheck,
            }
        );
        return true;
    }

    /**
     *  设置群会话 加群是否需要群主验证
     *  3、"needVerify": (Integer）加群是否需要群主验证 0 不需要、1 需要
     *  @param sessionId 会话ID
     *  @param status 加群是否需要群主验证状态值
     *  false <=> 0: 不需要群主验证
     *  true <=> 1: 需要群主验证
     */
    static async setGroupSessionNeedVerify(sessionId: string, status: boolean): Promise<boolean> {
        const needVerify = status ? 1 : 0;
        await this.chatManager?.method.group.set(sessionId, GroupModifyField.FILED_NEED_VERIFY, {
            needVerify,
        });
        return true;
    }
}

export const sessionSettings: any = {
    // 置顶
    [SessionSettingsType.TOP]: SessionSettingService.setSessionTop,
    // 消息提醒
    [SessionSettingsType.SHIELD]: SessionSettingService.setSessionShield,
    // 成员管理
    // 权限查验，是否允许全员修改群信息
    [SessionSettingsType.PERMISSION_CHECK]: SessionSettingService.setGroupSessionPermission,
    // 禁言
    [SessionSettingsType.BANNED_POST]: SessionSettingService.setGroupSessionBannedPost,
    // 查看人员名片
    [SessionSettingsType.CARD_VIEW]: SessionSettingService.setGroupSessionCardView,
    // 按部门选择成员（针对群组）
    [SessionSettingsType.VISIBLE_UNIT]: SessionSettingService.setSessionGroupVisibleUnit,
    // 开启全员可阅后即焚
    [SessionSettingsType.CHAT_VISIBLE_UNIT]: SessionSettingService.setSessionGroupChatVisibleUnit,
    // 加群设置
    // 全员可邀请
    [SessionSettingsType.APPROVAL_RULE]: SessionSettingService.setGroupSessionApprovalRule,
    // 可被搜索
    [SessionSettingsType.CAN_SEARCH]: SessionSettingService.setGroupSessionCanSearch,
    // 加群需验证
    [SessionSettingsType.NEED_VERIFY]: SessionSettingService.setGroupSessionNeedVerify,
};

// 1、"permissionCheck": (Integer）是否开启权限检查 0 不开启 1 开启
// 2、"approvalRule" "邀请规则："preapproval" - 允许任何人邀请成员 "identity" - 只能群主或管理员能主动邀请进群
// 3、"needVerify": (Integer）加群是否需要群主验证 0 不需要、1 需要

export async function sessionSetting(
    type: SessionSettingsType,
    sessionId: string,
    status: boolean
): Promise<boolean | null> {
    const imService = ImService.getInstance();
    if (!imService.isValid() || !imService.getChatManager()) {
        return null;
    }
    const fn = sessionSettings[type];
    if (!fn) {
        return null;
    }
    try {
        return await fn.apply(imService, [sessionId, status]);
    } catch (e) {
        logger.error('im =>', e);
        return null;
    }
}

export function getImChatManager() {
    const imService = ImService.getInstance();
    if (!imService.isValid()) {
        return null;
    }
    return imService.getChatManager();
}

export async function getSession(sessionId: string): Promise<Session | null> {
    const chatManager = getImChatManager();
    if (!chatManager) {
        return null;
    }
    return chatManager.method.session.get({ sessionId });
}

export function sortSessions(sessions: Session[]) {
    // console.log('sessionService', sessions);
    const topSessionList = sessions.filter((session) => isTopSession(session));
    const restSessionList = sessions.filter((session) => !isTopSession(session));
    // console.log('topSessionList1', topSessionList, 'restSessionList1', restSessionList);
    const sortTopSessions = topSessionList.sort(function (prev, next): number {
        return (next.timestamp || 0) - (prev.timestamp || 0);
    });
    const sortRestSessions = restSessionList.sort(function (prev, next): number {
        return (next.timestamp || 0) - (prev.timestamp || 0);
    });
    // console.log('sortTopSessions1', sortTopSessions, 'sortRestSessions1', sortRestSessions);
    // console.log('finally1', sortTopSessions.concat(sortRestSessions));
    return sortTopSessions.concat(sortRestSessions);
    // return sessions.sort(function (prev, next): number {
    //     return next.timestamp - prev.timestamp;
    // });
}

export const sessionAttrs = [
    'id',
    'sessionId',
    'sessionType',
    'timestamp',
    'unreadCount',
    'isGroup',
    'isSingle',
    'isNotice',
    'isSecret',
    'settings',
    'mentions',
    'info',
    'lastMsg',
    'uid',
    'lastMid',
    'lastReadMid',
    'name',
    'ext',
];

export const sessionUpdateAttrs = [
    'id',
    'sessionId',
    'sessionType',
    'unreadCount',
    'isGroup',
    'isSingle',
    'isNotice',
    'settings',
    'mentions',
    'info',
    'lastMsg',
    'uid',
];

export async function getAllSessionList(
    props?: Partial<SyncReqBody>,
    sync?: boolean
): Promise<any> {
    const chatManager = getImChatManager();
    if (!chatManager) {
        return [];
    }
    try {
        const sessions: any = await chatManager.method.session.sessions(props, sync);
        const allSessionList: Session[] =
            sessions.map((s: any) => {
                return lodashPick(s, sessionAttrs);
            }) || [];
        const list = sortSessions(allSessionList);
        return list;
    } catch (e) {
        logger.error('getAllSessionList =>', e);
        return [];
    }
}

export async function emptySessionMessage(sessionId: string): Promise<boolean> {
    const chatManager = getImChatManager();
    if (!chatManager) {
        return false;
    }
    try {
        if (sessionId.includes(CHAT_NOTICE.AI_PIN)) {
            await chatManager.method.session.removeNotice(sessionId, {
                delSession: 0,
                clearHistory: 1,
            });
        } else {
            await chatManager.method.session.remove(sessionId, {
                delSession: 0,
                clearHistory: 1,
            });
        }

        return true;
    } catch (e) {
        logger.error('emptySession =>', e);
        return false;
    }
}

export async function removeChatSession(sessionId: string): Promise<boolean> {
    const chatManager = getImChatManager();
    if (!chatManager) {
        return false;
    }
    try {
        await chatManager.method.session.remove(sessionId, {
            delSession: 1,
            clearHistory: 0,
        });
        return true;
    } catch (e) {
        logger.error('im =>', e);
        return false;
    }
}

export default {};
