import React, { useState, useContext, CSSProperties } from 'react';
import { getFileUrl, downLoadFile, getFileIconByMime } from '@/utils/chat/index';
import {
    buildJoyspaceIconUrl,
    buildPagePathKey,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import GlobalContext from '@/context/GlobalContext';
import { replaceGovUrl } from '@/utils/joyspace';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { isFocusEnv, FocusSDK, DownloadItem } from '@/utils';
import { lookup } from 'mime-types';
import { Tooltip, message as toast } from 'antd';
import { CalendarIcon } from '@/components/Calendars/Common';
import { changeEgovUrl } from '@/utils/tools';
import IconFont from '@/components/icon';
import { summaryPng } from '@/assets/img/calendar';
import { useTranslation } from 'react-i18next';

const Downloader = ({
    content,
    style,
    source,
}: {
    content: any;
    style?: CSSProperties;
    source?: string;
}) => {
    const [process, setProcess] = useState(0);
    const {
        appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
    } = useContext(GlobalContext);
    const { t } = useTranslation('calendar');
    function getAttachmentIcon(item: any) {
        const { fileType, extended, fileName } = item;
        if (fileType.startsWith('joyspace')) {
            try {
                const type = fileType.split('/')[1];
                // const page = JSON.parse(extended);
                // page.pageType = page.pageType || page.page_type;
                if (Number(type) === 6) {
                    // 兼容移动端日程创建的wps文档附件图标
                    if (extended) {
                        let extObj = JSON.parse(extended);
                        let { title } = extObj;
                        if (title.split('.').length > 1) {
                            let fType = title.split('.')[title.split('.').length - 1];
                            // eslint-disable-next-line max-depth
                            switch (fType) {
                                case 'doc':
                                    return getFileIconByMime('application/msword')[0];
                                case 'xls':
                                    return getFileIconByMime('application/vnd.ms-excel')[0];
                            }
                        }
                    }
                    return getFileIconByMime(
                        fileType.split('/6/')[1] || 'application/octet-stream'
                    )[0];
                } else {
                    return buildJoyspaceIconUrl({
                        pageType: type,
                        title: fileName,
                        type,
                        apiHost: `${joyspaceApiHost}-api`,
                    });
                }
            } catch (error) {
                return getFileIconByMime(fileType || 'application/octet-stream')[0];
            }
        }
        if (fileType.indexOf('/') === -1) {
            return getFileIconByMime(
                lookup(`tempname.${fileType}`) || 'application/octet-stream'
            )[0];
        }
        return getFileIconByMime(fileType || 'application/octet-stream')[0];
    }
    function handleFileDown(item: any) {
        if (item.fileType.startsWith('joyspace')) {
            const path = buildPagePathKey(Number(item.fileType.split('/')[1])).subPath;
            let newUrl = `${joyspaceHost}/${path}/${item.fileId}`;
            let realUrl = replaceGovUrl(newUrl);
            return DeeplinkEvent.open(realUrl);
        }
        if (isFocusEnv()) {
            FocusSDK.downloadFile(changeEgovUrl(item.fileUrl), {
                saveAs: true,
                filename: item.fileName,
                onStarted: (downloadItem: DownloadItem) => {
                    //
                    setProcess(0);
                },
                onProgress: ({ percent }) => {
                    //
                    // console.log('percent', percent);
                    setProcess(percent);
                },
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                    //
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                    //
                },
                onFinish: (file: { path: string; filename: string }) => {
                    // console.log('finish', file);
                    setProcess(100);
                },
            });
        } else {
            // web 端下载文件
            const name = item.fileName;
            const url = getFileUrl(item.fileUrl);
            downLoadFile(name, url);
        }
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {source === 'summary' ? (
                <div
                    className="file-item"
                    onClick={() => handleFileDown(content)}
                    key={content.fileId}
                >
                    <CalendarIcon src={summaryPng} width={32} height={32} />
                    <Tooltip title={t('record the minutes')}>
                        <span className="ellipsis">{t('record the minutes')}</span>
                    </Tooltip>
                </div>
            ) : (
                <div
                    className="file-item"
                    // href={item.fileUrl}
                    onClick={() => handleFileDown(content)}
                    key={content.fileId}
                >
                    <CalendarIcon
                        src={changeEgovUrl(getAttachmentIcon(content))}
                        width={20}
                        height={20}
                        style={style}
                    />
                    <Tooltip title={content.fileName}>
                        <span className="ellipsis" style={{ flex: 1, zIndex: 9 }}>
                            {content.fileName}
                        </span>
                    </Tooltip>
                    {!content.fileType.startsWith('joyspace') && (
                        <IconFont
                            type="iconic_pc_calender_download"
                            className="download-icon"
                            style={{
                                width: '14px',
                                height: '14px',
                                marginRight: '7px',
                            }}
                        />
                    )}
                    <div
                        className="mask"
                        style={{
                            width: `${process}%`,
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default Downloader;
