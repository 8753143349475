import React, { ReactNode, SFC, CSSProperties } from 'react';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

// 左侧标识图标
export interface IndicatorContentProps {
    indicator?: () => ReactNode;
    children?: ReactNode;
    className?: string;
    onClick?: () => void;
}
export const IndicatorContent: SFC<IndicatorContentProps> = ({
    indicator,
    children,
    className,
    onClick,
}) => {
    return (
        <div className={`df indicator-content-wrapper ${className}`} onClick={() => onClick?.()}>
            <div className="indicator">{indicator?.()}</div>
            <div className="content">{children}</div>
        </div>
    );
};
interface ColorIndicatorProps {
    color?: string;
    onClick?: () => void;
    type?: 'default' | 'primary' | 'danger';
    size?: 'large' | 'normal' | 'small';
    className?: string;
    selected?: boolean;
}
export function ColorIndicator({
    color = 'fff',
    size = 'small',
    onClick,
    className,
    selected,
}: ColorIndicatorProps) {
    return (
        <span
            className={`color-indicator color-indicator-size-${size} ${className} ${
                selected ? 'selected' : ''
            }`}
            onClick={onClick && onClick}
            style={{ backgroundColor: color.indexOf('#') === -1 ? `#${color}` : `${color}` }}
        />
    );
}

export function CalendarIcon({
    src,
    width = 16,
    height = 16,
    className,
    onClick,
    style,
}: {
    src: string;
    width?: number | string;
    height?: number | string;
    className?: string;
    style?: CSSProperties;
    onClick?: (p: any) => void;
}) {
    return (
        <img
            onClick={(e: any) => onClick?.(e)}
            src={changeEgovUrl(src)}
            width={width}
            height={height}
            className={className}
            style={style}
            draggable="false"
        />
    );
}
