import React, { useState, useEffect, useRef, useContext } from 'react';
import { message } from 'antd';
import { Project, ProjectRole } from '../../types';
import { useTabs } from '../../components/Tabs';
import * as api from '../../api';
import Context from '../../context';

interface Props {
    project: Project;
    value?: string;
}

export default function (props: Props) {
    const { project } = props;
    const { t } = useContext(Context);
    const [value, setValue] = useState(project.title);
    const [status, setStatus] = useState(false);
    const Input = useRef(null);
    const tabs = useTabs();
    useEffect(() => {
        ((Input.current as unknown) as HTMLInputElement)?.focus();
    }, [status]);

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    if (!status) {
        const editable =
            !project.archive &&
            (project?.user?.role === ProjectRole.ADMIN ||
                project?.user?.role === ProjectRole.OWNER);
        return <h1 onMouseDown={() => editable && setStatus(true)}>{value ?? t('no_title')}</h1>;
    }

    return (
        <input
            ref={Input}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            maxLength={20}
            onBlur={() => {
                if (value === '') {
                    return;
                }
                // 默认编辑成功，接口失败再撤回重新编辑
                setStatus(false);
                if (value !== project.title) {
                    api.updateProject(project.id, { name: value }).then((data) => {
                        if (data.errCode) return;
                        message.success(t('operate_success'));
                        tabs.setProps({ title: value, id: project.id });
                    });
                }
            }}
        />
    );
}
