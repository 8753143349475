/* eslint-disable complexity */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Input, Popover, Switch, message } from 'antd';
import Bus from '@/utils/bus';
import classNames from 'classnames';
import IconFont, { IconArrowRight, IconChat, IconShare } from '@/components/icon';
import pick from 'lodash/pick';
import isError from 'lodash/isError';
import isNull from 'lodash/isNull';
import isFunction from 'lodash/isFunction';
import { ModifySettingFn, UserCardAction, UserCardData, UserCardType } from '@/types/UserCard';
import { Line } from './common';
import NameSig from './NameSig';
import { Avatar } from '@/baseComponents/Avatar';
import UserInfoItem from './UserInfoItem';
import UserDeptTitle from './UserDeptTitle';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import './index.less';
import { isBjme, isFocusEnv } from '@/utils';
import config, { ChatConfig, ConfigEnum } from '@/config/config';
import styles from '@/components/styles';
import { connect } from 'dvajs';
import Loading from '../Loading';
import { Employee } from '@/types/chat';
import { changeEgovUrl } from '@/utils/tools';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { labelInfoList, ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { appliesNewContacts, LabelInfoRes, tagsNode } from '../AddressBook/common';
import uniqBy from 'lodash/uniqBy';
import { setModifyFriend } from '@/api/chat';
import { contactRelation } from '../Modals/UserCardModal';
import { analysisLog } from '@/utils/logAnalytics';

const { TextArea } = Input;

function UserCard(props: {
    visible: boolean;
    data: {
        type: UserCardType;
        userId: string;
        teamId: string;
        appId: string;
        rootOrgId?: string;
        group?: any;
        actions?: UserCardAction[];
        applyInfos?: string[];
        deptId?: string;
    } | null;
    isSelf?: boolean;
    isfriend?: number | -1;
    visibleFriends?: boolean;
    onGetUserInfo: (params: {
        userId: string;
        teamId: string;
        appId: string;
        rootOrgId?: string;
    }) => Promise<UserCardData>;
    onUploadAvatar: () => void;
    onActionClick?: (action: UserCardAction, data: UserCardData, accessPhone?: string) => any;
    onModifySetting?: ModifySettingFn;
    t: any;
    // onRemoveFriend?: () => void;
    isRemove?: boolean;
    currentEmployee: Employee;
    onClickAvatar?: (data: any) => void;
    onClickLabelModel?: (data: LabelInfoRes[], user: UserCardData) => void;
    onClickCreateLabelNameModel?: (user: UserCardData) => void;
    userLabel: LabelInfoRes[];
    setUserLabel: Function;
    contactRelationship: contactRelation;
    isMessageModule: boolean;
    friendErrMsg?: string;
}) {
    const { authInfo: currentUser } = useContext<GlobalContextConfig>(GlobalContext);
    const { teamUserInfo, userId, ddAppId } = currentUser;
    const {
        visible,
        data,
        onGetUserInfo,
        t,
        onUploadAvatar,
        onActionClick,
        isSelf,
        onModifySetting,
        // onRemoveFriend,
        isfriend,
        isRemove,
        visibleFriends,
        currentEmployee,
        onClickAvatar,
        onClickLabelModel,
        onClickCreateLabelNameModel,
        userLabel,
        setUserLabel,
        isMessageModule,
        contactRelationship,
        friendErrMsg,
    } = props;
    const { isOnlyScanAdd, friendship } = contactRelationship;
    const { actions, applyInfos, type: cardType, group } = data as any;
    const [user, setUser] = useState<UserCardData | null>(null);
    const [error, setError] = useState<string>('');
    // 验证消息记数
    const [count, setCount] = useState<number>(0);
    // 加好友时的验证消息
    const [validationMsg, setValidationMsg] = useState<string>('');
    // 操作状态
    const [actionState, setActionState] = useState<UserCardAction | null>(null);

    const [workStatus, setWorkStatus] = useState<any | null>(null);
    // 验证信息
    const [loadApplyInfos, setLoadApplyInfos] = useState<string[]>(applyInfos);

    const [checkedIphone, setCheckedIphone] = useState<boolean>(true);
    const [switchLoading, setSwitchLoading] = useState<boolean>(false);
    // const param = useMemo(() => {
    //     if (!data) {
    //         return null;
    //     }
    //     const { appId, teamId, userId, rootOrgId, type } = data;
    //     return { appId, teamId, userId, rootOrgId, type };
    // }, []); //eslint-disable-line

    // const [isfriend, setIsFriend] = useState(isFriend);
    useEffect(() => {
        if (!visible) {
            setUserLabel([]);
            return;
        }
        console.log(isfriend, actions, friendErrMsg, 'isfriendactions');

        if (!group) {
            return;
        }

        if (friendErrMsg) {
            setError(friendErrMsg);
            return;
        }
        // setActionState(null);
        if (actions?.includes(UserCardAction.Chat)) {
            setActionState(UserCardAction.Chat);
            return;
        }
        if (actions?.[0]) {
            setActionState(actions[0]);
            return;
        }
        if (isfriend === 1) {
            setActionState(UserCardAction.Chat);
        } else {
            if (isBjme()) {
                setActionState(UserCardAction.Add);
            } else {
                setActionState(UserCardAction.Add);
            }
            return;
        }
    }, [actions, group, friendErrMsg, isfriend, setUserLabel, visible]);

    // 获取并设置用户信息
    useEffect(() => {
        const { userId, teamId, type } = data || {};
        if (type === UserCardType.Group) {
            return;
        }

        if (!visible || isfriend === -1) {
            return;
        }

        if (!userId || !teamId) {
            // setError(`invalid userId(${userId}) or teamId(${teamId})`);
            return;
        }
        if (onGetUserInfo) {
            const param: any = pick(data, ['appId', 'teamId', 'userId', 'rootOrgId', 'deptId']);
            onGetUserInfo(param)
                .then((data) => {
                    const { fields, workStatus, accessPhone } = data || {};
                    if (!Array.isArray(fields)) {
                        throw new Error('用户信息获取失败');
                    }
                    try {
                        if (data.workStatus) {
                            const workStatus = JSON.parse(data.workStatus || '');
                            if (workStatus) {
                                setWorkStatus(workStatus);
                            }
                        }
                        // setUserLabel(currentUserLabelInfoList);
                    } catch (error) {}
                    delete data['workStatus'];
                    setUser({
                        ...data,
                        fields: fields.map((item) =>
                            pick(item, ['val', 'hide', 'index', 'key', 'name'])
                        ),
                    });
                    setError('');
                })
                .catch((e) => {
                    setError(e?.message || '');
                });
        }
    }, [visible, data, isfriend]); //eslint-disable-line

    useEffect(() => {
        if (actionState !== UserCardAction.Accept || !user) {
            return;
        }
        // 接受好友申请时 获取本地数据口的多条验证信息 （接口只返回一条数据，本地数据缓存了多条一个好友请求3次的数据）
        appliesNewContacts()
            .then((res) => {
                const infos = res
                    ?.filter(
                        (n) =>
                            n.app === user.appId &&
                            n.userId === user.userId &&
                            n.teamId === user.teamId
                    )?.[0]
                    ?.items?.map((ele: any) => ele.info);
                setLoadApplyInfos(infos);
            })
            .catch((err) => {
                // console.log( err);
            });
    }, [actionState, user]);

    const getItemVal = useCallback(
        (key: string): string => {
            if (!user) {
                return '';
            }
            const item = user.fields.find((item) => item.key === key);
            return (item || ({} as any)).val || '';
        },
        [user]
    );

    const avatar = useMemo(() => {
        if (cardType === UserCardType.Group) {
            return (group || ({} as any)).avatar || '';
        }

        if (cardType === UserCardType.User) {
            if (!user) {
                return null;
            }
            // return user.fields.find((item) => item.key === 'avatar').val || '';
            return getItemVal('avatar');
        }
        return '';
    }, [group, cardType, getItemVal, user]);

    const station = useMemo(() => {
        if (cardType === UserCardType.User) {
            if (!user) {
                return null;
            }
            // return user.fields.find((item) => item.key === 'station').val || '';
            return getItemVal('station');
        }
        return '';
    }, [cardType, getItemVal, user]);

    const name = useMemo(() => {
        if (cardType === UserCardType.Group) {
            return (group || {}).name || '';
        }
        if (!user) {
            return null;
        }
        return getItemVal('name');
    }, [group, cardType, getItemVal, user]);

    const sex = useMemo(() => {
        if (cardType === UserCardType.Group) {
            return '';
        }
        if (!user) {
            return '';
        }
        return getItemVal('sex');
    }, [cardType, getItemVal, user]);

    // const avatarNode = useMemo(() => {
    //     return (
    //         <Avatar
    //             uploadEnable={isSelf}
    //             avatar={avatar}
    //             name={name}
    //             sex={sex}
    //             t={t}
    //             onClick={onUploadAvatar}
    //         />
    //     );
    // }, [isSelf, avatar, onUploadAvatar, name, sex, t]);

    const avatarNode = useMemo(() => {
        if (!user) {
            return '';
        }

        return (
            <div className="user-card-avatar">
                <div className="user-card-avatar-item">
                    <div className="item-avatar">
                        <Avatar
                            styleObj={{
                                width: 56,
                                height: 56,
                                borderRadius: 6,
                                fontSize: 28,
                                cursor: avatar ? 'pointer' : 'auto',
                            }}
                            src={getHeadImgThumbUrl(avatar, 150, 150)}
                            name={name}
                            onClick={() => {
                                if (avatar && onClickAvatar) {
                                    onClickAvatar(user);
                                }
                            }}
                            // clstag="pageclick|keycount|xtbg_add_home_1637657110163|43"
                        />
                    </div>
                    <div className="item-content">
                        <h4>{name}</h4>
                        <p className="item-content-tag">
                            {user?.labelInfoList && tagsNode(user?.labelInfoList)}
                        </p>
                    </div>
                </div>
            </div>
        );
    }, [avatar, name, onClickAvatar, user]);

    const signature = useMemo(() => {
        if (cardType === UserCardType.Group) {
            return '';
        }
        if (!user) {
            return '';
        }
        return getItemVal('signature');
    }, [cardType, user, getItemVal]);

    const nameSigNode = useMemo(() => {
        return null;
    }, []);

    const labelListNode = useMemo(() => {
        if (!user) {
            return null;
        }
        return (
            <div className="me-user-card-item">
                <div className="usercard-item-content">
                    <h5 className="usercard-item-title">标签</h5>
                    <div className="usercard-item-desc label-usercard-item-desc">
                        {!userLabel || userLabel?.length === 0 ? (
                            <span
                                className="item-label"
                                onClick={() => {
                                    onClickCreateLabelNameModel?.(user);
                                }}
                            >
                                新建标签
                            </span>
                        ) : (
                            <div
                                onClick={() => {
                                    onClickLabelModel?.(userLabel, user);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="label-name">
                                    {userLabel.map((item, key) => {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <span key={key}>
                                                {item?.labelName}
                                                {userLabel.length - 1 !== key && '、'}
                                            </span>
                                        );
                                    })}
                                </div>
                                <IconFont
                                    className="icon-arrow_right"
                                    type="iconapp_ic_arrow_right"
                                    // onClick={() => {
                                    //     onClickLabelModel?.(userLabel, user);
                                    // }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }, [onClickCreateLabelNameModel, onClickLabelModel, user, userLabel]);

    // const nameSigNode = useMemo(() => {
    //     // if (!workStatus || !workStatus.workStatus) {
    //     //     return null;
    //     // }
    //     return (
    //         // <div className="usercard-workstatus">
    //         //     {workStatus.icon && <img src={workStatus.icon} />}
    //         //     <span className="work-status-title">{workStatus.workStatus}</span>
    //         // </div>
    //         <NameSig
    //             name={name}
    //             sig={signature}
    //             t={t}
    //             isSelf={!!isSelf}
    //             onModifySig={onModifySetting}
    //             sex={sex}
    //             workStatus={workStatus}
    //         />
    //     );
    // }, [isSelf, name, onModifySetting, sex, signature, t, workStatus]);

    useEffect(() => {
        if (isNull(user)) {
            return;
        }
        if (visible) {
            const defaultValidationMsg = t('userCard.validation-message', {
                first: currentEmployee?.unitName ? `“${currentEmployee?.unitName}”的` : '',
                second: currentUser.realName,
            });
            setValidationMsg(defaultValidationMsg);
            setCount(defaultValidationMsg.length);
        } else {
            setValidationMsg('');
            setCount(0);
        }
    }, [currentUser, t, visible, user, currentEmployee]);

    const actionOperate = useCallback(
        (action: UserCardAction) => {
            switch (action) {
                case UserCardAction.Add:
                    setActionState(UserCardAction.Send);
                    break;
                case UserCardAction.JoinGroup:
                    if (onActionClick) {
                        onActionClick(UserCardAction.JoinGroup, data as any).then(
                            (result: boolean) => {
                                if (result) {
                                    setActionState(UserCardAction.SendApplyGroup);
                                }
                            }
                        );
                    }
                    break;
                case UserCardAction.AddGroup:
                    setActionState(UserCardAction.SendApplyGroup);
                    break;
                case UserCardAction.SendApplyGroup:
                    // 发送加群申请
                    if (data) {
                        data.group.validationMsg = validationMsg;
                    }
                    if (onActionClick) {
                        onActionClick(UserCardAction.AddGroup, data as any).then(
                            ({ data: result }: any) => {
                                if (isError(result)) {
                                    setActionState(UserCardAction.More_Times_Group);
                                    return;
                                }
                                if (result) {
                                    setActionState(UserCardAction.AppliedGroup);
                                }
                            }
                        );
                    }
                    break;
                case UserCardAction.Send:
                    // 发送加人申请
                    if (user && onActionClick) {
                        user.validationMsg = validationMsg;
                        onActionClick(UserCardAction.Send, user, checkedIphone ? '1' : '0').then(
                            ({ data: result }: any) => {
                                if (result === 'more-times') {
                                    setActionState(UserCardAction.More_Times);
                                    return;
                                }
                                if (result) {
                                    setActionState(UserCardAction.Applied);
                                    setTimeout(() => {
                                        Bus.emit('app:toggle-user-card-modal:hide');
                                    }, 1000);
                                }
                            }
                        );
                    }
                    break;
                case UserCardAction.Accept:
                    if (user && onActionClick) {
                        onActionClick(UserCardAction.Accept, user, checkedIphone ? '1' : '0').then(
                            ({ data: result }: any) => {
                                if (result) {
                                    setActionState(UserCardAction.Chat);
                                    setTimeout(() => {
                                        Bus.emit('app:toggle-user-card-modal:hide');
                                    }, 1000);
                                }
                            }
                        );
                    }
                    break;
                case UserCardAction.Deleted:
                    if (user && onActionClick) {
                        onActionClick(UserCardAction.Deleted, user).then();
                    }
                    break;
                default:
                    break;
            }
        },
        [checkedIphone, data, onActionClick, user, validationMsg]
    );

    const actionButtonNode = useMemo(() => {
        if (!actionState) {
            return null;
        }
        const classes = classNames({
            'action-btn-node': true,
            'add-group': [
                UserCardAction.AppliedGroup,
                UserCardAction.AddGroup,
                UserCardAction.JoinGroup,
                UserCardAction.More_Times_Group,
            ].includes(actionState),
            send: [UserCardAction.Send, UserCardAction.SendApplyGroup].includes(actionState),
            accept: actionState === UserCardAction.Accept,
            add: [UserCardAction.Applied, UserCardAction.Add, UserCardAction.More_Times].includes(
                actionState
            ),
            deleted: actionState === UserCardAction.Deleted,
        });

        const btnTextConfig: any = {
            [UserCardAction.Add]: t('userCard.action.add'),
            [UserCardAction.AddGroup]: t('userCard.action.apply-add-group'),
            [UserCardAction.JoinGroup]: t('userCard.action.join-group'),
            [UserCardAction.SendApplyGroup]: t('userCard.action.send'),
            [UserCardAction.AppliedGroup]: t('userCard.action.applied'),
            [UserCardAction.More_Times_Group]: t('userCard.action.send'),
            [UserCardAction.Applied]: t('userCard.action.applied'),
            [UserCardAction.Send]: t('userCard.action.send'),
            [UserCardAction.More_Times]: t('userCard.action.send'),
            [UserCardAction.Accept]: t('userCard.action.accept'),
            [UserCardAction.Expired]: t('userCard.action.expired'),
            [UserCardAction.Deleted]: t('userCard.action.deleted'),
        };

        const disabled = [
            UserCardAction.Applied,
            UserCardAction.More_Times,
            UserCardAction.AppliedGroup,
            UserCardAction.More_Times_Group,
            UserCardAction.Expired,
        ].includes(actionState);

        const showTip = [UserCardAction.More_Times].includes(actionState);
        const clstag = (type: string): { clstag: string } => {
            switch (type) {
                case UserCardAction.Send:
                    return {
                        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|41',
                    };
                case UserCardAction.Add:
                    return {
                        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|25',
                    };
                case UserCardAction.Accept:
                    return {
                        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|42',
                    };
                case UserCardAction.Deleted:
                    return {
                        clstag: 'pageclick|keycount|xtbg_add_home_1637657110163|18',
                    };
                default:
                    return { clstag: '' };
            }
        };

        return (
            <div className={classes}>
                {/* {showTip && <div className="more-time-tip">{t('userCard.more-times')}</div>} */}
                <Button
                    type="primary"
                    onClick={actionOperate.bind(null, actionState)}
                    disabled={disabled}
                    {...clstag(actionState)}
                >
                    {btnTextConfig[actionState]}
                </Button>
            </div>
        );
    }, [actionOperate, actionState, t]);

    const errorNode = useMemo(() => {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40vh',
                    padding: 20,
                }}
            >
                <h4>
                    {/* <span>{t('userCard.loadErr')}</span> */}
                    <span>{error}</span>
                </h4>
            </div>
        );
    }, [error]);

    // const loadingNode = useMemo(() => {
    //     const style = { height: '40vh' };
    //     return <Loading style={style} />;
    // }, []);

    const loadingNode = useMemo(() => {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40vh',
                    padding: 20,
                }}
            >
                <Loading />
            </div>
        );
    }, []);

    const UserDeptTitleNode = useMemo(() => {
        if (!user) {
            return null;
        }
        if (!user.mainTitleDeptInfo && !user.partTimeTitleDeptInfo) {
            return null;
        }
        return (
            <UserDeptTitle
                t={t}
                mainTitleDeptInfo={user.mainTitleDeptInfo as any}
                partTimeTitleDeptInfo={user.partTimeTitleDeptInfo as any}
            />
        );
    }, [user, t]);

    const handleCheckedIphoneChange = useCallback(
        async (clstag: string) => {
            if (!user) {
                return;
            }
            analysisLog('xtbg_add_home_1637657110163', clstag);
            setSwitchLoading(true);
            if (actionState === UserCardAction.Deleted) {
                let fields: { [propName: string]: any } = {};
                (user?.fields || []).forEach((fieldItem: any) => {
                    fields[fieldItem.key] = fieldItem.val;
                });
                const [result, err] = await setModifyFriend({
                    pin: user?.userId || '',
                    app: user?.appId || '',
                    teamId: user?.teamId || '',
                    accessPhone: !checkedIphone ? '1' : '0',
                    nickName: fields['name'] || '',
                });

                if (err) {
                    message.error(err?.message || '设置失败');
                    setSwitchLoading(false);
                    return;
                }

                message.success(`设置成功`);
                setUser({ ...user, accessPhone: !checkedIphone ? 1 : 0 });
            }
            setCheckedIphone(!checkedIphone);
            setSwitchLoading(false);
        },
        [actionState, checkedIphone, user]
    );

    // 设置手机号可见性
    const verificationSetting = useCallback(
        ({ clstag }) => {
            if (!user) {
                return null;
            }
            return (
                <div className="Verification-setting">
                    <span>允许对方查看我的手机号</span>
                    <Switch
                        loading={switchLoading}
                        checked={checkedIphone}
                        onChange={() => {
                            handleCheckedIphoneChange(clstag);
                        }}
                    />
                </div>
            );
        },
        [user, switchLoading, checkedIphone, handleCheckedIphoneChange]
    );

    const actionNode = useMemo(() => {
        if (cardType !== UserCardType.User || !user) {
            return null;
        }
        return (
            <div className="me-user-card-actions">
                {/* 发起会话的button */}
                <Button
                    // type="primary"
                    shape="circle"
                    size="large"
                    icon={<IconFont type="iconic_chat" style={{ color: styles.color.primary2 }} />}
                    onClick={() => {
                        if (onActionClick) {
                            onActionClick(UserCardAction.Chat, user);
                        }
                    }}
                    clstag={`pageclick|keycount|${
                        isMessageModule
                            ? 'Xtbg_Msg_Dialog|InitiateSession'
                            : 'xtbg_add_home_1637657110163|19'
                    }`}
                />
                {isFocusEnv() &&
                    config[ChatConfig.CHAT_SESSION_CREATE_VOIP_ENABLE] &&
                    isfriend === 1 &&
                    teamUserInfo.ddAppId === user.appId &&
                    user.userId + user.teamId !== userId + teamUserInfo.teamId && (
                        <Button
                            // type="primary"
                            shape="circle"
                            size="large"
                            style={{ marginLeft: '8px' }}
                            icon={
                                <IconFont
                                    type="iconapp_btn_joymerting_answer"
                                    style={{ color: styles.color.primary2 }}
                                />
                            }
                            onClick={() => {
                                if (onActionClick) {
                                    onActionClick(UserCardAction.Voip, user);
                                }
                            }}
                            clstag={`pageclick|keycount|${
                                isMessageModule
                                    ? 'Xtbg_Msg_Dialog|InitiateVoip'
                                    : 'xtbg_add_home_1637657110163|20'
                            }`}
                        />
                    )}

                {/* 用户名片视频会议 */}
                {isFocusEnv() &&
                    config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE] &&
                    isfriend === 1 &&
                    teamUserInfo.ddAppId === user.appId &&
                    user.userId + user.teamId !== userId + teamUserInfo.teamId && (
                        <Button
                            // type="primary"
                            shape="circle"
                            size="large"
                            style={{ marginLeft: '8px' }}
                            icon={
                                <IconFont
                                    type="iconapp_btn_joymerting_video"
                                    style={{ color: styles.color.primary2 }}
                                />
                            }
                            onClick={() => {
                                if (onActionClick) {
                                    onActionClick(
                                        process.env.REACT_APP_MEETING === 'jrtc'
                                            ? UserCardAction.Jrtc
                                            : UserCardAction.Meeting,
                                        user
                                    );
                                }
                            }}
                            clstag={`pageclick|keycount|${
                                isMessageModule
                                    ? 'Xtbg_Msg_Dialog|InitiateMeeting'
                                    : 'xtbg_add_home_1637657110163|21'
                            }`}
                        />
                    )}
                {/* {!isFocusEnv() &&
                    config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE] &&
                    isfriend === 1 &&
                    teamUserInfo.ddAppId === user.appId &&
                    user.userId + user.teamId !== userId + teamUserInfo.teamId && (
                        <Button
                            // type="primary"
                            shape="circle"
                            size="large"
                            style={{ marginLeft: '8px' }}
                            icon={
                                <IconFont
                                    type="iconapp_btn_joymerting_video"
                                    style={{ color: styles.color.primary2 }}
                                />
                            }
                            onClick={() => {
                                if (onActionClick) {
                                    onActionClick(UserCardAction.Jrtc, user);
                                }
                            }}
                            clstag={`pageclick|keycount|${
                                isMessageModule
                                    ? 'Xtbg_Msg_Dialog|InitiateMeeting'
                                    : 'xtbg_add_home_1637657110163|21'
                            }`}
                        />
                    )} */}
                {(isSelf || isfriend === 1) && (
                    <Button
                        // type="primary"
                        shape="circle"
                        size="large"
                        style={{ marginLeft: '8px' }}
                        icon={
                            <IconFont
                                type="iconfenxiang"
                                style={{ color: styles.color.primary2 }}
                            />
                        }
                        onClick={() => {
                            if (onActionClick) {
                                onActionClick(UserCardAction.Share, user);
                            }
                        }}
                        clstag={`pageclick|keycount|${
                            isMessageModule
                                ? 'Xtbg_Msg_Dialog|ShareUserCard'
                                : 'xtbg_add_home_1637657110163|22'
                        }`}
                    />
                )}
                {!isSelf && visibleFriends && (
                    <Button
                        // type="primary"
                        shape="circle"
                        size="large"
                        style={{ marginLeft: '8px' }}
                        icon={
                            <IconFont
                                type="icontianjialianxiren"
                                style={{ color: styles.color.primary2 }}
                            />
                        }
                        onClick={() => {
                            setActionState(UserCardAction.Send);
                        }}
                        clstag={`pageclick|keycount|${
                            isMessageModule
                                ? 'Xtbg_Msg_Dialog|AddFriend'
                                : 'xtbg_add_home_1637657110163|23'
                        }`}
                    />
                )}
            </div>
        );
    }, [
        cardType,
        user,
        isfriend,
        teamUserInfo.ddAppId,
        teamUserInfo.teamId,
        userId,
        isSelf,
        visibleFriends,
        onActionClick,
        isMessageModule,
    ]);

    // 可以发起会话的卡片显示内容
    const getNoActionNode = useCallback(() => {
        if (user && cardType === UserCardType.User) {
            return (
                <div>
                    {avatarNode}
                    <div className="user-item-container">{nameSigNode}</div>
                </div>
            );
        }
    }, [user, cardType, avatarNode, nameSigNode]);

    // 右上角更多 （删除）
    const getDeleteSettingNode = useCallback(() => {
        if (user && cardType === UserCardType.User) {
            return (
                <div className="container">
                    <div className="validation-msg-node" style={{ height: 'auto' }}>
                        <p className="validation-title delete-setting">
                            <span
                                className="anticon"
                                onClick={() => {
                                    setActionState(UserCardAction.Chat);
                                }}
                            >
                                <IconFont type="iconwin_left" />
                            </span>
                            {t('userCard.DeleteSetting')}
                        </p>
                        {/* 好友关系是单相思单相思（A的好友列表有B，但是B的好友列表没有A）的情况下去掉手机号可见设置 */}
                        {friendship !== 8 && (
                            <div className="Verification-info">
                                <p className="Verification-info-title">
                                    {t('userCard.action.accept-info-settings')}
                                </p>
                                {verificationSetting({
                                    clstag: '48',
                                })}
                            </div>
                        )}
                    </div>
                    <div style={{ margin: friendship !== 8 ? '0' : '-11px 0 10px' }}>
                        {actionButtonNode}
                    </div>
                </div>
            );
        }
    }, [actionButtonNode, cardType, friendship, t, user, verificationSetting]);

    // 可以发起会话的卡片显示内容
    const getChatActionNode = useCallback(() => {
        if (user && cardType === UserCardType.User) {
            return (
                <div className="usercard-wrap">
                    {isRemove && (
                        <div className="more-operations-node">
                            <IconFont
                                type="iconapp_btn_joymerting_more"
                                onClick={() => {
                                    setActionState(UserCardAction.Deleted);
                                    setCheckedIphone(user.accessPhone ? true : false);
                                }}
                            />
                        </div>
                    )}
                    {avatarNode}
                    {actionNode}
                    <div className="user-item-container">
                        {nameSigNode}
                        <Line />
                        {UserDeptTitleNode}
                        <UserInfoItem user={user} cardType={cardType} />
                        <Line />
                        {/* {labelListNode} */}
                    </div>
                </div>
            );
        }
    }, [user, cardType, isRemove, avatarNode, actionNode, nameSigNode, UserDeptTitleNode]);

    const getGroupOwnerItemNode = useMemo(() => {
        if (cardType !== UserCardType.Group) {
            return null;
        }
        const { avatar, name } = group.owner || ({} as any);
        return (
            <div className="card-group-owner">
                {/* <UserAvatar src={changeEgovUrl(avatar)} alt={name} size={36} /> */}
                <Avatar src={changeEgovUrl(getHeadImgThumbUrl(avatar, 150, 150))} name={name} />
                <div className="card-group-owner-name">
                    {t('userCard.group.owner', { first: name })}
                </div>
            </div>
        );
    }, [cardType, group, t]);

    // 添加联系人
    const getAddContactNode = useMemo(() => {
        if (!(user?.partTimeTitleDeptInfo?.length as any)) {
            return;
        }
        return (
            <div>
                {user?.partTimeTitleDeptInfo &&
                    user?.partTimeTitleDeptInfo.map((item, index) => {
                        return (
                            <div className="me-user-card-item" key={index}>
                                <div className="usercard-item-content">
                                    <h5 className="usercard-item-title">
                                        {t('userCard.unitName')}
                                    </h5>
                                    <p className="usercard-item-desc">{item.unitName}</p>
                                </div>

                                {item.deptName && (
                                    <div className="usercard-item-content">
                                        <h5 className="usercard-item-title inner">
                                            {t('userCard.dept')}
                                            {/* {!item.major && <div className="dept-item-j">兼</div>} */}
                                        </h5>
                                        <p className="usercard-item-desc">{item.deptName}</p>
                                    </div>
                                )}
                                <div className="usercard-item-content">
                                    <h5 className="usercard-item-title inner">
                                        {t('userCard.title')}
                                    </h5>
                                    <p className="usercard-item-desc">{item.titleName}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }, [user, t]);

    // 仅可通过扫码方式添加
    const onlyScanAddNode = useMemo(() => {
        return (
            <>
                <Line />
                <div className="only-scan-add">
                    <IconFont type="iconic_describe1" className="iconic_describe1" />
                    仅可通过扫码方式添加
                </div>
            </>
        );
    }, []);

    // 添加好友
    const getAddActionNode = useCallback(() => {
        return (
            <div className="container">
                {avatarNode}
                <div className="user-item-container">
                    {nameSigNode}
                    <Line />
                    {getAddContactNode}
                </div>
                {isOnlyScanAdd ? onlyScanAddNode : actionButtonNode}
            </div>
        );
    }, [
        avatarNode,
        nameSigNode,
        getAddContactNode,
        isOnlyScanAdd,
        onlyScanAddNode,
        actionButtonNode,
    ]);

    // 新的申请过期
    const getExpiredActionNode = useCallback(() => {
        if (isfriend) {
            setActionState(UserCardAction.Chat);
            return;
        }
        return (
            <div className="container">
                {avatarNode}
                <div className="user-item-container">
                    {nameSigNode}
                    <Line />
                    {getAddContactNode}
                </div>
                {actionButtonNode}
            </div>
        );
    }, [isfriend, avatarNode, nameSigNode, getAddContactNode, actionButtonNode]);

    const getJoinActionNode = useCallback(() => {
        return (
            <div className="container">
                {avatarNode}
                <div className="user-item-container">
                    {nameSigNode}
                    <Line />
                    {getGroupOwnerItemNode}
                </div>
                {actionButtonNode}
            </div>
        );
    }, [avatarNode, getGroupOwnerItemNode, nameSigNode, actionButtonNode]);

    // 验证消息
    const validationInfoNode = useMemo(() => {
        if (!loadApplyInfos) {
            return;
        }
        return (
            <div className="validation-info-node">
                <div className="title">{t('userCard.action.accept-info')}</div>
                <div className="info">
                    {loadApplyInfos.map((info: any, index: any) => {
                        return (
                            <div key={index} className="info-item">
                                {info}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [loadApplyInfos, t]);

    // 通过验证
    const getAcceptActionNode = useCallback(() => {
        if (!user) {
            return null;
        }
        return (
            <div className="container">
                {avatarNode}
                <div className="user-item-container">
                    {nameSigNode}
                    <Line />
                    {getAddContactNode}
                    <Line />
                    {validationInfoNode}
                    <Line />
                    <div style={{ margin: '14px 20px 0' }}>
                        {verificationSetting({
                            clstag: '49',
                        })}
                    </div>
                </div>
                {actionButtonNode}
            </div>
        );
    }, [
        avatarNode,
        nameSigNode,
        getAddContactNode,
        validationInfoNode,
        verificationSetting,
        user,
        actionButtonNode,
    ]);

    const textAreaChange = (event: any) => {
        let inputValue = event.target.value;
        if (inputValue.length > 200) {
            return false;
        }
        setValidationMsg(inputValue);
        setCount(inputValue.length);
    };

    // 验证消息节点
    const validationMsgNode = useMemo(() => {
        if (!name || !user) {
            return null;
        }
        return (
            <div className="validation-msg-node">
                <p className="validation-title">
                    {t('userCard.sendAdd')}
                    <span
                        className="anticon"
                        onClick={() => {
                            Bus.emit('app:toggle-user-card-modal:hide');
                        }}
                    >
                        <IconFont type="iconlog_close" />
                    </span>
                </p>
                <div className="Verification-info">
                    <p className="Verification-info-title">
                        {t('userCard.action.accept-info')}
                        {t('userCard.action.accept-info-content')}
                    </p>
                    <TextArea
                        className="message"
                        defaultValue={validationMsg}
                        // value={validationMsg}
                        // maxLength={50}
                        // onChange={textAreaChange}
                        disabled={true}
                    />
                    <p className="Verification-info-title">
                        {t('userCard.action.accept-info-settings')}
                    </p>
                    {verificationSetting({
                        clstag: '50',
                    })}
                </div>
            </div>
        );
    }, [name, t, user, validationMsg, verificationSetting]);

    const getSendActionNode = useCallback(() => {
        return (
            <div className="container">
                {validationMsgNode}
                {actionButtonNode}
            </div>
        );
    }, [actionButtonNode, validationMsgNode]);

    const getActionNode = useCallback(() => {
        if (cardType === UserCardType.User && !user) {
            return null;
        } else if (cardType === UserCardType.Group && !group) {
            return null;
        }
        // // 没有actions 支持打开会话 为了兼容老版本调用
        // if ((actions || []).length === 0 || actions.includes(UserCardAction.Chat)) {
        //     return getChatActionNode();
        // }
        const actionNodeMap = {
            [UserCardAction.No]: getNoActionNode,
            [UserCardAction.Chat]: getChatActionNode,
            [UserCardAction.Send]: getSendActionNode,
            [UserCardAction.SendApplyGroup]: getSendActionNode,
            [UserCardAction.Accept]: getAcceptActionNode,
            [UserCardAction.Applied]: getSendActionNode,
            [UserCardAction.Add]: getAddActionNode,
            [UserCardAction.More_Times]: getAddActionNode,
            [UserCardAction.AddGroup]: getAddActionNode,
            [UserCardAction.JoinGroup]: getJoinActionNode,
            [UserCardAction.AppliedGroup]: getAddActionNode,
            [UserCardAction.More_Times_Group]: getAddActionNode,
            [UserCardAction.Expired]: getExpiredActionNode,
            [UserCardAction.Deleted]: getDeleteSettingNode,
        };

        if (isfriend === -1) {
            return loadingNode;
        }
        // @ts-ignore
        if (isFunction(actionNodeMap[actionState])) {
            // @ts-ignore
            return actionNodeMap[actionState]();
        }
    }, [
        cardType,
        user,
        group,
        getNoActionNode,
        getChatActionNode,
        getSendActionNode,
        getAcceptActionNode,
        getAddActionNode,
        getJoinActionNode,
        getExpiredActionNode,
        getDeleteSettingNode,
        isfriend,
        actionState,
        loadingNode,
    ]);

    const modalBody = useMemo(() => {
        if (!visible) {
            return null;
        }

        if (error) {
            return errorNode;
        }

        return getActionNode();
    }, [visible, error, getActionNode, errorNode]);

    if (!modalBody) {
        return null;
    }

    return <div className="wrap">{modalBody}</div>;
}
function mapStateToProps({ work, user, chat, ebook }: any) {
    return {
        currentEmployee: chat.currentEmployee,
        userLabel: ebook.label.userLabel,
        addUserLabelNameModelVisible: ebook.label.addUserLabelNameModelVisible,
        isMessageModule: chat.isMessageModule,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        setUserLabel(data: any) {
            dispatch({ type: 'ebook/setUserLabel', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserCard);
