import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { connect } from 'dvajs';
import { List, Button, message } from 'antd';
import GlobalContext from '@/context/GlobalContext';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import './index.less';
import { MeetingQueryType, MeetingPlatform } from '@/types/common';
import { queryMeeting } from '@/api/meeting';
import { IconRight } from '@/components/icon';
import { joinMeeting, getAvsdk } from '../utils';
import ScrollView from '@/baseComponents/ScrollView';
import { isFocusEnv } from '@/utils';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';

interface ParticipantModal {
    headImg: string;
    realName: string;
    teamId: string;
    userId: string;
}

enum ConferenceStatus {
    UPCOMING = 0, // 预约成功
    CLOSED = 1, // 已完成
    ONGOUING = 2, // 进行中
}
interface MeetingModel {
    calendarId: string | null;
    conferenceCode: string;
    conferenceImgUrl: string;
    conferenceName: string;
    conferenceOrganizer: ParticipantModal;
    conferenceOrganizerId: string;
    conferenceOrganizerName: string;
    conferenceStatus: ConferenceStatus;
    conferenceTimeEnd: number;
    conferenceTimeStart: number;
    conferenceType: number;
    scheduleId: string;
    participantInfos: ParticipantModal[];
    password: string;
}
const pageSize = 10;

function JoinMeetingModal(props: {
    meetingType: MeetingQueryType;
    changeScheduleInfoDraw: Function;
    platform: MeetingPlatform;
    onCancel: Function;
}) {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [meetingList, setMeetingList] = useState([]);
    const { authInfo } = useContext(GlobalContext);
    const scrollRef: any = useRef(null);

    const handleJoinMeeting = useCallback(
        (meeting: MeetingModel) => {
            if (!meeting.conferenceCode || !meeting.conferenceOrganizer) {
                return;
            }
            joinMeeting(getAvsdk(props.platform), {
                meetingNumber: meeting.conferenceCode,
                meetingPassword: meeting.password,
            }).catch((error) => {
                // message.error(error.message || error);
            });
        },
        [props]
    );

    const handleDetail = function (meeting: MeetingModel) {
        props.onCancel();
        props.changeScheduleInfoDraw({
            visible: true,
            scheduleId: meeting.scheduleId,
            selectedScheduleTime: dayjs(meeting.conferenceTimeStart),
            from: ScheduleDetailFrom.Meeting,
            openTime: dayjs(),
        });
    };

    const queryMeetingList = useCallback(() => {
        setLoading(true);
        queryMeeting(page, pageSize, props.meetingType, props.platform).then(([res, err]) => {
            setLoading(false);
            if (!res || err) {
                console.error(err);
                return;
            }
            const { conferenceInfos, totalCount } = res.conferenceInfoMap[props.meetingType];
            if (conferenceInfos.length > 0) {
                setMeetingList((m) => {
                    return m.concat(conferenceInfos);
                });
                setTotal(totalCount);
            } else {
                setHasMore(false);
            }
        });
    }, [page, props.meetingType, props.platform]);

    useEffect(() => {
        queryMeetingList();
    }, [queryMeetingList]);

    const onLoadMore = useCallback(() => {
        setPage(page + 1);
    }, [page]);

    const loadMore = (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                marginBottom: 30,
                height: 32,
                lineHeight: '32px',
            }}
        >
            {meetingList.length < total && hasMore ? (
                <Button onClick={onLoadMore}>加载更多</Button>
            ) : !loading && meetingList.length ? (
                '没有更多了'
            ) : null}
        </div>
    );

    return (
        <div className="meeting-list">
            <ScrollView ref={scrollRef} autoHide>
                <List
                    itemLayout="horizontal"
                    loading={loading}
                    locale={{ emptyText: '暂无数据' }}
                    dataSource={meetingList}
                    loadMore={loadMore}
                    renderItem={(meeting: MeetingModel) => (
                        <List.Item className="meeting-item">
                            <div>
                                <div className={`status status-${meeting.conferenceStatus}`}>
                                    {(() => {
                                        let statusText = '';
                                        switch (meeting.conferenceStatus) {
                                            case ConferenceStatus.UPCOMING:
                                                statusText = '即将开始';
                                                break;
                                            case ConferenceStatus.CLOSED:
                                                statusText = '已结束';
                                                break;
                                            case ConferenceStatus.ONGOUING:
                                                statusText = '进行中';
                                                break;
                                            default:
                                                break;
                                        }
                                        return <span>{statusText}</span>;
                                    })()}
                                </div>
                                <div className="topic" title={meeting.conferenceName}>
                                    {meeting.conferenceName}
                                </div>
                                <div className="detail">
                                    {meeting.conferenceOrganizerName}{' '}
                                    {moment(meeting.conferenceTimeStart).format('MM月DD日 HH:mm')}-
                                    {moment(meeting.conferenceTimeEnd).format('MM月DD日 HH:mm')}
                                </div>
                            </div>

                            <div>
                                {meeting.conferenceStatus !== ConferenceStatus.CLOSED &&
                                    meeting.conferenceTimeStart < new Date().getTime() &&
                                    new Date().getTime() < meeting.conferenceTimeEnd && (
                                        <Button
                                            onClick={handleJoinMeeting.bind(null, meeting)}
                                            shape="round"
                                        >
                                            加入会议
                                        </Button>
                                    )}
                                {meeting.scheduleId ? (
                                    <span
                                        style={{ marginLeft: 15, cursor: 'pointer' }}
                                        onClick={() => {
                                            handleDetail(meeting);
                                        }}
                                    >
                                        {IconRight}
                                    </span>
                                ) : (
                                    <span style={{ width: 29, display: 'inline-block' }} />
                                )}
                            </div>
                        </List.Item>
                    )}
                />
            </ScrollView>
        </div>
    );
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeScheduleInfoDraw: (payload: {
            visible: boolean;
            scheduleId: string;
            from: number;
            openTime: Dayjs;
        }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDraw',
                payload,
            }),
    };
}

export default connect(undefined, mapDispatchToProps)(JoinMeetingModal);
