import { joinMeeting } from '@/components/Meeting/utils';
import { joinCloudLink } from '@/components/MeetingCloudLink/utils';
import { FocusSDK, isFocusEnv } from '@/utils';
import { message as toast, message } from 'antd';
import { MeetingPlatform } from '@/types/common';
import { NetType } from '@/components/Modals/ChangeNetModal';
import i18n from '@/i18n';
import { IMeetingModal } from '@/components/Calendars/Schedule/Detail';
import { AuthData } from '@/types/LocalConfig';
import { joinMeeting as joinMeetingBase } from '@/baseComponents/Meeting/utils';
import { AVSDK } from '@/baseComponents/Meeting/common';
import logger from '@/utils/logger';

// eslint-disable-next-line max-params
export function joinMeetingOperation(
    authInfo: AuthData,
    meetingInfo: IMeetingModal,
    newPublicResult: boolean,
    cb?: Function
) {
    if (!meetingInfo) {
        return;
    }
    if (!isFocusEnv()) {
        if (meetingInfo.meetingPlatform === MeetingPlatform.JBRTC) {
            joinMeetingBase(AVSDK.JBRTC, {
                meetingPassword: meetingInfo.password,
                meetingNumber: meetingInfo.meetingId,
            })
                .then((data) => {
                    cb?.(data);
                })
                .catch((err) => {
                    logger.error(err.message);
                });
        }
        return;
    }
    if (meetingInfo.meetingPlatform === MeetingPlatform.HW) {
        joinCloudLink(authInfo.userId, {
            meetingNumber: meetingInfo.meetingId,
            username: authInfo.teamUserInfo.realName,
            password: meetingInfo.password,
            isaudiooff: true,
            isvideooff: true,
        })
            .then((data) => {
                cb?.(data);
            })
            .catch((err) => {
                message.error((err.message || err || '加入会议失败').toString());
            });
    } else if (meetingInfo.meetingPlatform === MeetingPlatform.JBRTC) {
        joinMeetingBase(AVSDK.JBRTC, {
            meetingPassword: meetingInfo.password,
            meetingNumber: meetingInfo.meetingId,
        })
            .then((data) => {
                cb?.(data);
            })
            .catch((err) => {
                logger.error(err.message);
            });
    } else if (meetingInfo.meetingPlatform === MeetingPlatform.XYLINK) {
        joinMeetingBase(AVSDK.XYLINK, {
            meetingPassword: meetingInfo.password,
            meetingNumber: meetingInfo.meetingId,
        })
            .then((data) => {
                cb?.(data);
            })
            .catch((err) => {
                message.error(err.message);
            });
    } else {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            if (newPublicResult) {
                toast.warn(i18n.t('common:joinMeeting.change public net'));
            } else {
                toast.warn(i18n.t('common:joinMeeting.private net'));
            }
            return;
        }
        joinMeeting({
            password: meetingInfo.password,
            meetingId: meetingInfo.meetingId,
            realName: authInfo.teamUserInfo.realName,
            meetingTopic: meetingInfo.title,
        });
        cb?.();
    }
}
