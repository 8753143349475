/* eslint-disable max-nested-callbacks */
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import JRTCMeetWebSDK from '@jdcloud/jrtc-meet-web-sdk';
import Bus from '@/utils/bus';
import { Loading } from '@jd/focus-desktop-comps';
import { openUserSelectionModal } from '@/utils/modals'; // 人员选择器弹窗
import { handleMeetingSendMessage } from '@/baseComponents/Meeting/utils';
import { AVSDK, SessionStateEnum, EXTERNAL_CALL_STATUS } from '@/baseComponents/Meeting/common';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { isFocusEnv } from '@/utils';

const CALL_EVENT = {
    CALL: 'CALL',
    CANCEL: 'CANCEL',
};

let sdk: any | null = null;
let preInviteList: any[] = [];

function getMeetStatus(sessionState: SessionStateEnum) {
    let status = null;
    switch (sessionState) {
        case SessionStateEnum.UserReceive:
            status = EXTERNAL_CALL_STATUS.ACCEPT;
            break;
        case SessionStateEnum.UserReject:
            status = EXTERNAL_CALL_STATUS.REJECT;
            break;
        case SessionStateEnum.Ringing:
            status = EXTERNAL_CALL_STATUS.RING;
            break;
        case SessionStateEnum.Busy:
            status = EXTERNAL_CALL_STATUS.BUSY;
            break;
        case SessionStateEnum.TIMEOUT:
            status = EXTERNAL_CALL_STATUS.TIMEOUT;
            break;
        default:
            break;
    }
    return status;
}

export default function App(props: any) {
    console.log('app props:', props);
    let { routerData } = props;
    console.log('***路径传参***', routerData);
    let timer = null;
    const meetRef = useRef(null);
    const [t_chat] = useTranslation('chat');
    const {
        authInfo = {} as any,
        appRuntimeEnv,
        notifyDis,
        notifyStatus,
        isInJBRTCMeeting,
    } = useContext<GlobalContextConfig>(GlobalContext);
    const history = useHistory();
    const topTitleBar = 46;
    const topMsgTipBar = 40;
    const [browserOffsetHeight, setBrowserOffsetHeight] = useState(document.body.offsetHeight);
    const [jdmeetingWrapperHeight, setJdmeetingWrapperHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    // 获取京东视频会议容器高度
    const getJdMeetingWrapperHeight = useCallback(() => {
        console.log('notifyDis:&&&', notifyDis);
        console.log('notifyStatus---', notifyStatus);
        const currentViewHeight =
            notifyStatus === 'granted'
                ? browserOffsetHeight - topTitleBar
                : notifyDis
                ? browserOffsetHeight - topTitleBar - topMsgTipBar
                : browserOffsetHeight - topTitleBar;
        return currentViewHeight;
    }, [notifyDis, notifyStatus, browserOffsetHeight]);

    console.info(appRuntimeEnv);
    // 浏览器可视区域变化时，设置浏览器可视区域高度
    const onResize = useCallback(() => {
        console.log('监听浏览器可视区域变化：', document.body.offsetHeight);
        setBrowserOffsetHeight(document.body.offsetHeight);
    }, []);

    // 预邀请，记录被邀请成员
    const handlePreInvite = useCallback(
        (meetInfo) => {
            const tempLocalUserInfo: any = localStorage.getItem('localUserInfo');
            const currentUserInfo: any = JSON.parse(tempLocalUserInfo);
            console.log('笑嘻嘻:', meetInfo, currentUserInfo);
            // preInviteList = [];
            const { selectedTeamId, teamUserInfo, userId } = authInfo;

            const resultUsers = preInviteList.map((l: any) => {
                return {
                    id: `${l.pin}:${currentUserInfo.app}:${currentUserInfo.teamId}`,
                    name: l.name,
                    avatar: l.avatar || '',
                    checked: true,
                    userId: l.pin,
                    teamId: currentUserInfo.teamId,
                    visible: true,
                };
            });

            if (resultUsers.length === 0) {
                console.log('currentUserInfo - preInvite:', currentUserInfo);
                resultUsers.push({
                    id: `${currentUserInfo.pin}:${currentUserInfo.app}:${currentUserInfo.teamId}`,
                    name: currentUserInfo.name,
                    avatar: currentUserInfo.avatar,
                    checked: true,
                    userId: currentUserInfo.pin,
                    teamId: currentUserInfo.teamId,
                    visible: true,
                });
            }

            const userInfo: any = {
                id: `${userId}:${teamUserInfo.ddAppId}:${teamUserInfo.teamId}`,
                avatar: teamUserInfo.headImg,
                name: teamUserInfo.realName,
                app: teamUserInfo.ddAppId,
                userId,
                teamId: teamUserInfo.teamId,
                pin: userId,
            };

            const options: any = {
                frozenIds: [],
            };

            options.title = t_chat('add_member');
            options.resultUsers = resultUsers;
            options.frozenIds = [userInfo.id];
            options.currentUser = {
                id: `${currentUserInfo.pin}:${currentUserInfo.app}:${currentUserInfo.teamId}`,
                name: currentUserInfo.name,
                avatar: currentUserInfo.avatar,
                checked: true,
                userId: currentUserInfo.pin,
                teamId: currentUserInfo.teamId,
                visible: true,
            };
            options.tabs = ['org', 'group'];
            openUserSelectionModal(
                options,
                (data, close) => {
                    try {
                        const { result } = data.data;
                        console.log('人员选择器result:', result);
                        if (result.length) {
                            preInviteList = result.map((r: any) => {
                                const tem = r.id.split(':');
                                return {
                                    name: r.name,
                                    pin: tem[0],
                                    teamId: tem[2],
                                    app: tem[1],
                                    avatar: r.avatar as string,
                                };
                            });
                            const toSdkInvitePersons = preInviteList.map((item: any) => {
                                return {
                                    nickname: item.name,
                                    userId: item.pin,
                                };
                            });
                            console.log('邀请回填数据：', preInviteList);
                            console.log('处理后的preInviteList：', toSdkInvitePersons);
                            console.log('ioo:', sdk);
                            sdk.invite(toSdkInvitePersons);
                        }

                        close();
                    } catch (error) {
                        // message.error(t('chat.failedToCreateGroup'));
                        if (close) {
                            close();
                        }
                    }
                },
                authInfo as any
            );
        },
        [t_chat, authInfo]
    );

    // 进入房间后邀请
    const handleInvite = useCallback(
        (meetInfo) => {
            const tempLocalUserInfo: any = localStorage.getItem('localUserInfo');
            const currentUserInfo: any = JSON.parse(tempLocalUserInfo);
            console.log('哈哈哈:', meetInfo, currentUserInfo);
            console.log('handleInvite:', currentUserInfo);
            const { selectedTeamId, teamUserInfo, userId } = authInfo;

            const userInfo: any = {
                id: `${userId}:${teamUserInfo.ddAppId}:${teamUserInfo.teamId}`,
                avatar: teamUserInfo.headImg,
                name: teamUserInfo.realName,
                app: teamUserInfo.ddAppId,
                userId,
                teamId: teamUserInfo.teamId,
                pin: userId,
            };
            const options: any = {
                frozenIds: [],
            };

            options.title = t_chat('add_member');
            options.resultUsers = [];
            options.frozenIds = [userInfo.id];
            options.currentUser = {
                id: `${currentUserInfo.pin}:${currentUserInfo.app}:${currentUserInfo.teamId}`,
                name: currentUserInfo.name,
                avatar: currentUserInfo.avatar,
                checked: true,
                userId: currentUserInfo.pin,
                teamId: currentUserInfo.teamId,
                visible: true,
            };
            options.tabs = ['org', 'group'];
            openUserSelectionModal(
                options,
                (data, close) => {
                    try {
                        const { result } = data.data;
                        console.log('人员选择器result,加入房间后,人员选择器-人员列表:', result);
                        if (result.length) {
                            const members = result.map((r: any) => {
                                const tem = r.id.split(':');
                                return {
                                    name: r.name,
                                    pin: tem[0],
                                    teamId: tem[2],
                                    app: tem[1],
                                    avatar: r.avatar as string,
                                };
                            });

                            console.log('已选中邀请人员：', members);
                            const toSdkInvitePersons = members.map((item: any) => {
                                return {
                                    nickname: item.name,
                                    userId: item.pin,
                                };
                            });
                            sdk.invite(toSdkInvitePersons);

                            const { room, subject, password } = meetInfo;
                            if (!room) {
                                return;
                            }
                            console.log('发送会议消息～', meetInfo.sessionState);
                            handleMeetingSendMessage({
                                avsdk: AVSDK.JBRTC,
                                meetingInfo: {
                                    meetingNumber: room,
                                    meetingTopic: subject,
                                    password,
                                },
                                sessionState: meetInfo.sessionState || SessionStateEnum.Call,
                                toUsers: members,
                            });
                        }

                        close();
                    } catch (error) {
                        // message.error(t('chat.failedToCreateGroup'));
                        if (close) {
                            close();
                        }
                    }
                },
                authInfo as any
            );
        },
        [t_chat, authInfo]
    );

    // 二次呼叫
    function handleCalling({ sessionState, subject, room, userId, password }: any) {
        console.info('sdk 重新呼叫', {
            sessionState,
            subject,
            room,
            userId,
            password,
        });
        const tempLocalUserInfo: any = localStorage.getItem('localUserInfo');
        const currentUserInfo: any = JSON.parse(tempLocalUserInfo);
        console.log('二次呼叫消息发送~~~');
        handleMeetingSendMessage({
            avsdk: AVSDK.JBRTC,
            meetingInfo: {
                meetingNumber: room,
                meetingTopic: subject,
                password,
            },
            sessionState,
            toUsers: [
                {
                    pin: userId,
                    app: currentUserInfo.app,
                    teamId: currentUserInfo.teamId,
                    name: '',
                    avatar: '',
                },
            ],
        });
    }

    // 加入房间成功时触发
    const handleHomeJoined = useCallback((joinedData) => {
        console.log('入会成功 home', joinedData);
        console.log('加入房间成功时触发***：', preInviteList);
        setIsLoading(false);
        if (preInviteList.length > 0) {
            const {
                room,
                subject,
                e: { isNewRoom, password },
            } = joinedData;
            if (!room || !isNewRoom) {
                return;
            }
            console.log('邀请人数超过1人时，执行该代码');
            // 邀请成员>1时，发送im消息
            handleMeetingSendMessage({
                avsdk: AVSDK.JBRTC,
                meetingInfo: {
                    meetingNumber: room,
                    meetingTopic: subject,
                    password,
                },
                sessionState: joinedData.sessionState || SessionStateEnum.Call,
                toUsers: preInviteList,
            });
        }
    }, []);

    // 离开或结束会议触发
    const handleHangup = useCallback(() => {
        if (sdk) {
            sdk.dispose();
            sdk = null;
            preInviteList = [];
            console.log('测试挂断：', preInviteList);
            Bus.emit('load:jdmeetingJD', true);
            Bus.emit('jdmeeting:clear-props', true);
            Bus.emit('jdmeeting:exist-local-meetInfo', false);
            Bus.emit('jdmeeting:meeting-status', {
                inMeeting: false,
                meetingNumber: null,
            });
        }
        localStorage.removeItem('jdmeetUserInfo');
        localStorage.removeItem('currentMeetInfo');
    }, []);

    useEffect(() => {
        console.log('isInJBRTCMeeting:', isInJBRTCMeeting);
        const { userId } = authInfo;
        const localUserPin: any = localStorage.getItem('localUserInfo');
        const formatLocalUser: any = JSON.parse(localUserPin);
        console.log('formatLocalUser-', formatLocalUser, typeof formatLocalUser);
        console.log('authInfo全局：', authInfo);
        console.log('localUserPin：', localUserPin);
        if (!localUserPin || userId === formatLocalUser.pin) {
            return;
        }
        console.log('66677777~~~');
        localStorage.removeItem('localUserInfo');
    }, [authInfo, isInJBRTCMeeting]);

    useEffect(() => {
        const currentMeetViewHeight = getJdMeetingWrapperHeight();
        console.log('当前京东视频会议容器高度：', currentMeetViewHeight);
        setJdmeetingWrapperHeight(currentMeetViewHeight);
    }, [getJdMeetingWrapperHeight]);

    useEffect(() => {
        console.log('web可见区域width:', document.body.offsetWidth);
        console.log('web可见区域Height:', document.body.offsetHeight);
        function init() {
            const jrtcConfig = appRuntimeEnv.jrtcConfig || {
                rtcServerUrl: '',
                meetApiUrl: '',
            };
            sdk = new JRTCMeetWebSDK({
                parent: meetRef.current,
                rtcServerUrl: jrtcConfig.rtcServerUrl || '',
                meetApiUrl: new URL(jrtcConfig.meetApiUrl || 'https://jzbmeeting.jdcloud.com'),
                productName: '智能会议',
            });

            console.info('sdk.version', sdk.version, process.env.DEBUG_PROD);
            console.log('sdk***', sdk);

            sdk.on(JRTCMeetWebSDK.Event_Invite, (meetInfo: any) => {
                console.info('入会后邀请meetInfo', meetInfo);
                handleInvite(meetInfo);
            });
            sdk.on(JRTCMeetWebSDK.Event_Hangup, () => {
                handleHangup();
                console.log('挂断事件被触发');
            });
            sdk.on(JRTCMeetWebSDK.Event_Pre_Invite, (meetInfo: any) => {
                console.log(`会议预邀请信息`, meetInfo);
                handlePreInvite(meetInfo);
            });

            sdk.on(JRTCMeetWebSDK.Event_Call, (info: any) => {
                console.info('Event_Call', info);
                const meetInfo = sdk.getMeetInfo();
                handleCalling({
                    ...meetInfo,
                    ...info,
                    sessionState:
                        info.callStatus === CALL_EVENT.CALL
                            ? SessionStateEnum.Call
                            : SessionStateEnum.Cancel,
                });
            });
        }
        init();
        return () => {};
    }, [handlePreInvite, handleHangup, handleInvite, appRuntimeEnv]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    useEffect(() => {
        // 加入会议 sdk.join()
        function handleJoin(data: any, _e?: any) {
            console.log('哟哟哟:', data);
            const { userInfo, meetingInfo } = data;
            const options = {
                token: userInfo.token,
                deviceType: userInfo.deviceType,
                jrtcUserId: userInfo.jrtcUserId,
                appId: userInfo.app,
                avatar: userInfo.avatar,
                nickname: userInfo.name,
                room: String(meetingInfo.meetingNum),
                password: meetingInfo.password,
                userId: userInfo.pin,
                desc: userInfo.deptName,
            };
            console.log('handleJoin', options, data);
            const handleJoined = (e: any) => {
                console.log('加入房间sdk.join()事件触发：', e);
                if (e.success) {
                    return;
                }
                const meetInfo = sdk.getMeetInfo();
                console.log('入会成功sdk.join():meetInfo7777--', meetInfo);
                localStorage.setItem(
                    'currentMeetInfo',
                    JSON.stringify({ ...meetInfo, password: meetingInfo.password })
                );
                Bus.emit('jdmeeting:joinMeetingCb', {
                    status: 1,
                    data: meetInfo,
                });
                Bus.emit('jdmeeting:meeting-status', {
                    inMeeting: true,
                    meetingNumber: meetInfo.room,
                });
                sdk.videoMute(meetingInfo.isvideooff);
                sdk.audioMute(meetingInfo.isaudiooff);
                sdk.off(JRTCMeetWebSDK.Event_Joined, handleJoined);
            };

            sdk.on(JRTCMeetWebSDK.Event_Joined, handleJoined);
            sdk.join(options)
                .then((res: any) => {
                    console.log('handleJoin成功-', res);
                })
                .catch((err: any) => {
                    console.log('handleJoin失败：', err);
                    const { errorCode, errorMessage } = err;
                    if (errorCode) {
                        message.error(errorMessage);
                        // handleHangup();
                        // localStorage.removeItem('jdmeetUserInfo');
                        // localStorage.removeItem('currentMeetInfo');
                    } else if (err.error) {
                        console.log('error:', err.error);
                        // 对于web sdk 抛出的异常，被邀请人无法入会时的兼容性处理
                        message.info(`${err.error.message},请尝试通过AI助手入会`);
                    } else {
                        console.log('非sdk标准错误:', err);
                    }
                    handleHangup();
                    localStorage.removeItem('jdmeetUserInfo');
                    localStorage.removeItem('currentMeetInfo');
                });
        }

        // 加入会议成功
        function handleJdmeetJoined(e: any) {
            console.info('joined', e);
            if (!e.success) {
                return;
            }
            const meetInfo = sdk.getMeetInfo();
            console.info('meetInfo', meetInfo);
            console.log('加入会议成功*****');
            localStorage.setItem(
                'currentMeetInfo',
                JSON.stringify({ ...meetInfo, password: e.password })
            );
            Bus.emit('jdmeeting:joinMeetingCb', {
                status: 1,
                data: meetInfo,
            });
            Bus.emit('jdmeeting:meeting-status', {
                inMeeting: true,
                meetingNumber: meetInfo.room,
            });
            handleHomeJoined({
                ...meetInfo,
                e,
            });
        }
        // 调用sdk加入会议主界面
        function handleHomeJoin(userInfo: any, _e?: any) {
            const options = {
                isJoin: true,
                appId: userInfo.app,
                userId: userInfo.pin,
                deviceType: userInfo.deviceType,
                jrtcUserId: userInfo.jrtcUserId,
                nickname: userInfo.name,
                desc: userInfo.deptName,
                avatar: userInfo.avatar,
                temporary: false,
                token: userInfo.token,
            };
            console.info('handleHomeJoin', options);
            sdk.on(JRTCMeetWebSDK.Event_Joined, handleJdmeetJoined);
            sdk.home(options);
        }

        // 调用sdk创建会议主界面
        function handleHomeCreate(userInfo: any, _e?: any) {
            console.log('_e', _e);
            console.log('userInfo', userInfo);
            console.log('**appId:', userInfo.app);
            const options = {
                isJoin: false,
                appId: userInfo.app,
                userId: userInfo.pin,
                deviceType: userInfo.deviceType,
                jrtcUserId: userInfo.jrtcUserId,
                nickname: userInfo.name,
                desc: userInfo.deptName,
                avatar: userInfo.avatar,
                temporary: false,
                token: userInfo.token,
            };
            console.info('handleHomeCreate', options);
            sdk.on(JRTCMeetWebSDK.Event_Joined, handleJdmeetJoined);
            sdk.home(options);
        }

        // 调用sdk消息显示方法
        function handleShowNotification(message: any, _e?: any) {
            console.log('测试-handleShowNotification触发：', message, _e);
            sdk.showNotification(message);
        }

        // 调用sdk设置被邀请用户状态
        function handleSetCallStatus(data: any, _e?: any) {
            console.info('handleSetCallStatus***', data, data.status);
            const status = getMeetStatus(data.status as SessionStateEnum);
            if (!status) {
                return;
            }
            sdk.setCallStatus({
                userId: data.from.pin,
                status,
            });
        }

        // 设置分享链接
        function handleSetShareLink(url: string, _e?: any) {
            console.log('jdmeeting:setShareLink', url);
            sdk.setShareLink('');
        }

        if (routerData) {
            console.log('useEffect routerData:', routerData);
            const { meetStatus, meetingInfo, userInfo } = routerData;
            console.log('*****8*****', meetStatus, meetingInfo, userInfo);
            if (meetStatus === 'joinMeeting') {
                console.log('执行时间：', new Date().getTime());
                handleJoin({ meetingInfo, userInfo });
            }
        }

        Bus.on('jdmeeting:showCreate', handleHomeCreate);
        Bus.on('jdmeeting:showJoin', handleHomeJoin);
        Bus.on('jdmeeting:join-from-calendar', handleJoin);
        Bus.on('jdmeeting:join-from-quickLink', handleJoin);
        Bus.on('jdmeeting:showNotification', handleShowNotification);
        Bus.on('jdmeeting:setAttendeeStatus', handleSetCallStatus);
        Bus.on('jdmeeting:setShareLink', handleSetShareLink);

        return () => {
            Bus.off('jdmeeting:showCreate', handleHomeCreate);
            Bus.off('jdmeeting:showJoin', handleHomeJoin);
            Bus.off('jdmeeting:join-from-calendar', handleJoin);
            Bus.off('jdmeeting:join-from-quickLink', handleJoin);
            Bus.off('jdmeeting:showNotification', handleShowNotification);
            Bus.off('jdmeeting:setAttendeeStatus', handleSetCallStatus);
            Bus.off('jdmeeting:setShareLink', handleSetShareLink);
        };
    }, [handleHomeJoined, handleHangup, routerData]);

    function destroyComponent() {
        preInviteList = [];
        console.log('组件卸载时sdk**', sdk);
        if (sdk) {
            sdk.dispose();
            sdk = null;
            Bus.emit('jdmeeting:meeting-status', {
                inMeeting: false,
                meetingNumber: null,
            });
        }
        setIsLoading(false);
        localStorage.removeItem('localUserInfo');
    }
    useEffect(() => {
        return destroyComponent;
    }, []);

    return (
        <div
            id="meet"
            className="jd-meeting"
            style={{ height: `${jdmeetingWrapperHeight}px` }}
            ref={meetRef}
        >
            <Loading style={{ marginTop: '150px' }} />
            {!meetRef.current && <Loading style={{ marginTop: '150px' }} />}
        </div>
    );
}
