import styles from '@/components/styles';
import React from 'react';
import './index.less';

interface CornerMarkType {
    text?: String;
    styles?: any;
}

export default function CornerMark(props: CornerMarkType) {
    const { text = '推荐', styles } = props;
    return (
        <div className="corner-mark" style={styles}>
            {text}
        </div>
    );
}
