import * as log from 'loglevel';
// import * as prefix from 'loglevel-plugin-prefix';
// import remote from 'loglevel-plugin-remote';
// import { API } from '@const/api';
// import cookies from 'js-cookie';

log.enableAll();

// prefix.reg(log);
// prefix.apply(log, {
//   format(level, name, timestamp) {
//     return `[${timestamp}] ${level.toUpperCase()} ${name}:`;
//   },
// });

if (process.env.REACT_APP_NO_CONSOLE !== 'true') {
    log.setLevel('debug');
} else {
    log.setLevel('info');
}

// export const enableRemoveLog = () => {
//     // const remote = require('loglevel-plugin-remote');
//     const remote = require('./remote').default;
//     if (!remote) return;
//     log.debug('enable remote logging', remote);
//     remote.apply(log, {
//         url: API.Logging.Post(),
//         method: 'POST',
//         headers: {
//             token: cookies.get('token'),
//             'Content-Type': 'application/json',
//         },
//         token: 'token',
//         onUnauthorized: () => {
//             console.error('log post unauthorized');
//         },
//         timeout: 3000,
//         interval: 1000,
//         level: 'error',
//         backoff: {
//             multiplier: 2,
//             jitter: 0.1,
//             limit: 30000,
//         },
//         capacity: 500,
//         stacktrace: {
//             levels: ['trace', 'warn', 'error'],
//             depth: 3,
//             excess: 0,
//         },
//         timestamp: () => new Date().toISOString(),
//         format: remote.json,
//     });
// };
export default log;
