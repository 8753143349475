import { Typography } from 'antd';
import React, { useState } from 'react';
import IconFont from '@/components/icon';
const { Paragraph } = Typography;

interface IProps {
    children: any;
}
export default function MyParagraph(props: IProps) {
    const [isEllipsis, setIsEllipsis] = useState(true);
    const [key, setKey] = useState(0);
    return (
        <div key={key}>
            <Paragraph
                ellipsis={
                    isEllipsis
                        ? {
                              rows: 1,
                              expandable: true,
                              symbol: (
                                  <IconFont style={{ color: '#D8D8D8' }} type="iconicon_down" />
                              ),
                              onExpand: () => {
                                  setIsEllipsis(false);
                              },
                          }
                        : false
                }
            >
                {props.children}
            </Paragraph>
            {!isEllipsis && (
                <a
                    onClick={() => {
                        setIsEllipsis(true);
                        setKey(key + 1);
                    }}
                >
                    收起
                </a>
            )}
        </div>
    );
}
