import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import './index.less';

import { ChatMessage, ChatMessageType, Employee, MessageEmitBody, Session } from '@/types/chat';
import SearchInput from '../Input';
import { useTranslation } from 'react-i18next';
import DefaultPage from '@/components/DefaultPage';
import { CompDataLoadState, DefaultPageType } from '@/models';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import ImService from '@/server/ImService';
import { Checkbox, List } from 'antd';
import DownloadFile from '@/components/chat/message/File/History';
import { getMessageSendType } from '../../utils/message';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import Loading from '@/components/Loading';
import Forwarding from '../../message/Forwarding';
export interface ISearchFileProps {
    currentSession: Session;
    currentEmployee: Employee;
    jumpTo?: (mid: number) => void;
}

function SearchFile(props: ISearchFileProps) {
    const { currentSession, jumpTo, currentEmployee } = props;
    const [searchKey, setSearchKey] = useState('');
    const [messages, setMessages] = useState<MessageEmitBody[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [hasMore, setMore] = useState(false);
    const [selectedMsgs, setSelectedMsgs] = useState<MessageEmitBody[]>([]);
    const { t } = useTranslation('chat');
    const imService = useMemo(() => {
        return ImService.getInstance();
    }, []);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const isGovEnv = ['mebj', 'jgswy', 'mebjJZB'].includes(appRuntimeEnv.runtimeConfig.key);
    const search_noFilesDescription = isGovEnv && currentSession?.isGroup ? '没有组文件' : ''; // '' 会被替换为默认值
    const search = useMemo(() => {
        return debounce((searchKey: string) => {
            setLoading(true);
            imService
                .search({
                    sessionId: currentSession.sessionId,
                    searchKey,
                    searchType: ChatMessageType.FILE,
                    desc: true,
                })
                .then((messages) => {
                    // console.log('messages', messages);
                    setMessages(messages as MessageEmitBody[]);
                    setLoading(false);
                    setMore(messages.length === 20);
                })
                .catch((e) => {
                    setLoading(false);
                });
        }, 400);
    }, [imService, currentSession]);

    useEffect(() => {
        search('');
    }, [search]);

    const handleSearchKeyChange = useCallback(
        (searchKey: string) => {
            setLoading(true);
            setSearchKey(searchKey);
            search(searchKey);
        },
        [search]
    );
    const handleInfiniteOnLoad = useCallback(() => {
        setLoading(true);
        const startMid = (messages.slice(-1)[0] || { mid: -1 }).mid;
        imService
            .search({
                sessionId: currentSession.sessionId,
                searchKey,
                searchType: ChatMessageType.FILE,
                startMid: startMid === -1 ? -1 : startMid,
                desc: true,
            })
            .then((msgs) => {
                setMessages(messages.concat(msgs as MessageEmitBody[]));
                setLoading(false);
                setMore(msgs.length === 20);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [searchKey, messages, imService, currentSession]);

    const handleChange = (e: any, message: any) => {
        // console.log('e', e, 'message', message);
        let temp = [...selectedMsgs];
        const idx = selectedMsgs.findIndex((item) => item.mid === message.mid);
        if (idx < 0) {
            temp.splice(0, 0, message);
            // console.log('addArr', temp);
            temp = temp.sort((item1, item2) => item1.timestamp - item2.timestamp);
            setSelectedMsgs(temp);
            return;
        } else {
            temp.splice(idx, 1);
            // console.log('deleteArr', temp);
            temp = temp.sort((item1, item2) => item1.timestamp - item2.timestamp);
            setSelectedMsgs(temp);
            return;
        }
    };

    const getCheckedStatus = (message: any) => {
        return selectedMsgs.findIndex((item) => item.mid === message.mid) < 0;
    };

    const clearMsgs = () => {
        setSelectedMsgs([]);
    };

    return (
        <div className="search-history search-file">
            <SearchInput
                placeholder={t('search.iconText')}
                onChange={handleSearchKeyChange}
                sessionId={currentSession.sessionId}
            />
            <div className="search-history-result">
                <div className="message-list">
                    {!isLoading && searchKey && messages.length === 0 && (
                        <div className="illustration">
                            <DefaultPage
                                dataType={CompDataLoadState.NODATA}
                                pageType={DefaultPageType.SEARCH_HISTORY}
                            />
                        </div>
                    )}
                    {!isLoading && !searchKey && messages.length === 0 && (
                        <div className="illustration">
                            <DefaultPage
                                dataType={CompDataLoadState.NODATA}
                                pageType={DefaultPageType.CHAT_HISTORY_FILE}
                            />
                        </div>
                    )}
                    {messages.length > 0 && (
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!isLoading && hasMore}
                            useWindow={false}
                        >
                            <List
                                dataSource={messages}
                                loading={
                                    isLoading
                                        ? {
                                              indicator: (
                                                  <div
                                                      style={{
                                                          width: '100%',
                                                          height: '100%',
                                                          display: 'flex',
                                                          minHeight: '200px',
                                                          left: 0,
                                                      }}
                                                  >
                                                      <Loading />
                                                  </div>
                                              ),
                                          }
                                        : false
                                }
                                renderItem={(item) => (
                                    <div
                                        className={`message-item ${
                                            currentSession?.isSecret ? 'message-item_normal' : ''
                                        }`}
                                    >
                                        {!currentSession?.isSecret && (
                                            <div
                                                className={`checkbox-wrapper ${
                                                    selectedMsgs.length > 0 ? 'show' : ''
                                                }`}
                                            >
                                                <Checkbox
                                                    checked={!getCheckedStatus(item)}
                                                    onChange={(e) => handleChange(e, item)}
                                                />
                                            </div>
                                        )}
                                        <DownloadFile
                                            message={item}
                                            sendType={getMessageSendType(
                                                (item as unknown) as ChatMessage,
                                                currentEmployee,
                                                currentSession
                                            )}
                                            searchKey={searchKey}
                                            jumpTo={jumpTo}
                                        />
                                    </div>
                                )}
                            />
                        </InfiniteScroll>
                    )}
                </div>
            </div>
            {!currentSession?.isSecret && selectedMsgs?.length ? (
                <Forwarding
                    groupMsgs={selectedMsgs}
                    clearSelectedMsgs={clearMsgs}
                    isGroupFileForward={true}
                />
            ) : null}
        </div>
    );
}

export default SearchFile;
