import { createContext } from 'react';
import { User } from './types';
import i18n, { LANGUAGE } from '@/i18n';
import { useTranslation as _useTranslation } from 'react-i18next';
import Eventemitter3 from 'eventemitter3';
export function useTranslation() {
    return _useTranslation('projects')[0];
}

export function lang(name: string) {
    return i18n.t(`projects[0]:${name}`);
}

export { LANGUAGE };

export const state = {};

export const reducers = {};

export interface Action {
    type: keyof typeof reducers;
    data?: { [key: string]: any };
}

export type Dispatch = (type: Action['type'], data?: Action['data']) => void;

export default createContext<{
    state: typeof state;
    user: User;
    dispatch: Dispatch;
    events: Eventemitter3;
    t: any;
}>({} as any);
