import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { FocusSDK, getClientType } from '@/utils';
import { openUserSelectionModal } from '@/utils/modals';
import _ from 'lodash';
import { getUserSimple, updateRecentLoginTime, createMeeting } from '@/api/meeting';
import IconFont from '@/components/icon';
import { deleteEmojiStr } from '@/components/chat/message/Input/Emoji';
import { handleMeetingSendMessage, setCurrentMeetingInfo } from '@/baseComponents/Meeting/utils';
import { AVSDK, SessionStateEnum } from '@/baseComponents/Meeting/common';
import log from '@/utils/logger';
import dayjs from 'dayjs';
import { MeetingPlatform } from '@/types/common';
interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
    userList: {
        realName: string;
        teamId: string;
        userId: string;
        appId: string;
        headImg: string;
    }[];
}

export default function CreateMeetingModal() {
    const [showPass, setShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [meetingTopic, setMeetingTopic] = useState('');
    const [pass, setPass] = useState('');
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo, currentEmployee } = useContext(GlobalContext);
    const { selectedTeamId, teamUserInfo, userId } = authInfo;
    let teamId = teamUserInfo?.teamId || '';
    const [t_common] = useTranslation('common');
    const [t_chat] = useTranslation('chat');

    const [ownerInfo, setOwnerInfo] = useState<{
        deptName: string; // 部门
        superiorDeptName: string | null; // 次级部门
        titleName: string | null; // 岗位名称
        userName: string;
    } | null>(null);

    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
        userList: [],
    });
    const inputRef = React.useRef<Input | null>(null);
    useEffect(() => {
        function handleModalVisible(data: any = {}) {
            const { list = [] } = data;
            const { realName, ddAppId, headImg } = teamUserInfo;

            const user = {
                realName,
                userId,
                teamId: teamUserInfo?.teamId,
                appId: ddAppId,
                headImg,
            };
            if (list.length === 0) {
                setState((s: any) => ({
                    ...s,
                    userList: [user],
                }));
            } else {
                setState((s: any) => ({
                    ...s,
                    userList: list,
                }));
            }
            setVisible(true);
            (async () => {
                const [result, err] = await getUserSimple({ userId, teamId, appId: ddAppId });
                if (err || !result) {
                    return;
                }
                setOwnerInfo(result);
            })();
        }
        FocusSDK.on('xylink:create-show', handleModalVisible);
        bus.on('xylink:create-show', handleModalVisible);
        return () => {
            FocusSDK.off('xylink:create-show', handleModalVisible);
            bus.off('xylink:create-show', handleModalVisible);
        };
    }, [teamId, teamUserInfo, userId]);

    function closeModal() {
        setMeetingTopic('');
        setPass('');
        setVisible(false);
        setShowPass(false);
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
            userList: [],
        });
    }

    let messageShow = false;
    const showValidate = _.debounce(function () {
        if (messageShow) return;
        message.error(t_common('joinMeeting.Exceed the word limit'), undefined, () => {
            messageShow = false;
        });
        messageShow = true;
    }, 200);

    function onMeetingTopicChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (value.length > 30) {
            showValidate();
            return;
        }
        setMeetingTopic(deleteEmojiStr(value));
    }

    useEffect(() => {
        function handle({ status, error, data }: { status: number; error: any; data: any }) {
            if (!visible) {
                return;
            }
            setConnect(false);
            if (status) {
                handleMeetingSendMessage({
                    avsdk: AVSDK.XYLINK,
                    meetingInfo: {
                        meetingNumber: data.meetingNumber,
                        meetingTopic,
                        password: pass,
                    },
                    sessionState: SessionStateEnum.Call,
                    toUsers: state.userList.map((item) => {
                        return {
                            pin: item.userId,
                            teamId: item.teamId,
                            name: item.realName,
                            app: item.appId,
                            avatar: item.headImg,
                        };
                    }),
                }).then((res) => {
                    log.info(res);
                });
                closeModal();
                message.success('会议创建成功');
            } else {
                console.error('会议创建失败', error);
                message.error(error || '会议创建失败');
            }
        }
        FocusSDK.on('xylink:joinMeetingCb', handle);
        return () => {
            FocusSDK.off('xylink:joinMeetingCb', handle);
        };
    }, [meetingTopic, pass, state.userList, visible]);

    async function handleCreateMeeting() {
        try {
            if (connect) {
                return;
            }
            setConnect(true);
            const [result, err] = await createMeeting({
                startTime: (dayjs().valueOf() + 5000).toString(),
                duration: 2,
                topic: meetingTopic,
                password: pass,
                platform: MeetingPlatform.XYLINK,
                participantIds: [
                    {
                        realName: currentEmployee.name,
                        teamId: authInfo.selectedTeamId,
                        userId: authInfo.userId,
                        appId: authInfo.ddAppId,
                        headImg: authInfo.headImg || '',
                    },
                ],
            });
            if (!result || err) {
                // 如果请求创建报错，则清空会议状态
                setConnect(false);
                message.error(t_chat('joyMeeting.create meeting failed'));
                return;
            }
            setCurrentMeetingInfo(AVSDK.XYLINK, result);
            FocusSDK.sendIpcMessage('xylink.joinMeeting', {
                meetingInfo: {
                    topic: meetingTopic,
                    meetingNumber: String(result.meetingId),
                    controlPassword: result.controlPassword,
                    password: pass,
                    isvideooff: state.videoDisable,
                    isaudiooff: state.audioDisable,
                },
                userInfo: {
                    app: teamUserInfo.ddAppId,
                    pin: authInfo.userId,
                    teamId: authInfo.selectedTeamId,
                    name: teamUserInfo.realName,
                    avatar: teamUserInfo.headImg,
                    titleName: ownerInfo?.titleName || '',
                    superiorDeptName: ownerInfo?.superiorDeptName || '',
                    deptName: ownerInfo?.deptName || '',
                },
            });
        } catch (error) {
            console.error(error);
        }
    }

    const isValidMeeting = () => {
        return (
            meetingTopic && state.userList.length > 0 && (pass.length === 0 || pass.length === 6)
        );
    };
    function onMeetingPassChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (!/^\d{0,6}$/.test(value)) {
            return;
        }
        setPass(value);
    }
    function handlePassBlur() {
        if (!pass) {
            setShowPass(false);
        }
    }

    const passComp = showPass ? (
        <Input
            value={pass}
            placeholder={t_common('joinMeeting.Set a 6-digit password')}
            autoFocus
            onChange={onMeetingPassChange}
            onBlur={handlePassBlur}
        />
    ) : (
        <div
            className="input-label"
            onClick={() => {
                setShowPass(true);
            }}
        >
            <IconFont style={{ marginRight: 5 }} type="iconic_editor" />
            {t_common('joinMeeting.Set a 6-digit password')}
        </div>
    );

    function handleAddMember() {
        const options: any = {
            frozenIds: [],
        };

        const userInfo: any = {
            id: `${userId}:${teamUserInfo.ddAppId}:${teamUserInfo.teamId}`,
            avatar: teamUserInfo.headImg,
            name: teamUserInfo.realName,
            app: teamUserInfo.ddAppId,
            userId,
            teamId: teamUserInfo.teamId,
            pin: userId,
        };
        const members = _.compact(state.userList || []).map((l: any) => ({
            id: `${l.userId}:${l.appId}:${l.teamId}`,
            name: l.realName,
            avatar: l.headImg,
            checked: true,
            userId: l.userId,
            teamId: l.teamId,
            visible: true,
        }));
        options.title = t_chat('add_member');
        options.resultUsers = members;
        options.frozenIds = [userInfo.id];
        options.currentUser = {
            app: userInfo?.app,
            pin: userInfo?.pin,
            teamId: userInfo?.teamId,
        };
        options.tabs = ['org', 'group'];
        openUserSelectionModal(
            options,
            (data, close) => {
                try {
                    const { result } = data.data;
                    if (result.length) {
                        setState((s) => {
                            return {
                                ...s,
                                userList: result.map((r) => {
                                    const tem = r.id.split(':');
                                    return {
                                        realName: r.name,
                                        userId: tem[0],
                                        teamId: tem[2],
                                        appId: tem[1],
                                        headImg: r.avatar as string,
                                    };
                                }),
                            };
                        });
                    }

                    close();
                } catch (error) {
                    log.error(error);
                    if (close) {
                        close();
                    }
                }
            },
            authInfo as any
        );
    }

    const handleMenuClick = (key: string) => {
        if (connect) {
            return;
        }
        if (key === 'audio') {
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
        if (key === 'addUser') {
            handleAddMember();
        }
    };

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    function onCancel() {
        if (connect) {
            return;
        }
        closeModal();
    }
    return (
        <Modal
            className="desk-me-join-meeting-modal desk-me-create-meeting-modal"
            width={780}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
            destroyOnClose
        >
            <div className="me-join-meeting-body">
                <div className="me-join-meeting-form">
                    <div className="me-join-meeting-input">
                        <Input
                            type="text"
                            value={meetingTopic}
                            ref={inputRef}
                            autoFocus
                            placeholder={t_common(
                                'joinMeeting.Please enter the topic of the meeting'
                            )}
                            onPressEnter={() => {
                                if (isValidMeeting() && !connect) {
                                    handleCreateMeeting();
                                }
                            }}
                            onChange={onMeetingTopicChange}
                        />
                    </div>
                    <div className="me-join-meeting-input">{passComp}</div>
                </div>
                <div className="me-join-meeting-actions">
                    <div className="me-join-meeting-menu">
                        <div className="me-join-meeting-menu-container">
                            <span
                                className={`me-join-meeting-menu-item 
                                    ${
                                        state.audioDisable
                                            ? 'me-join-meeting-menu-item-disable'
                                            : ''
                                    }`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                className={`me-join-meeting-menu-item
                                    ${state.videoDisable ? 'me-join-meeting-menu-item-disable' : ''}
                                `}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                className="me-join-meeting-menu-item me-join-meeting-menu-addUser"
                                onClick={() => handleMenuClick('addUser')}
                            >
                                <IconFont type="iconapp_btn_addcontacts" />
                            </span>
                            <span className="label">
                                {t_common('joinMeeting.%s person to attend').replace(
                                    '%s',
                                    state.userList.length.toString()
                                )}
                            </span>
                        </div>
                    </div>
                    <Button
                        disabled={!isValidMeeting()}
                        style={{ opacity: isValidMeeting() ? 1 : 0.5, width: 232 }}
                        type="primary"
                        onClick={handleCreateMeeting}
                        loading={connect}
                        className="me-join-meeting-start-button"
                    >
                        {t_common('joinMeeting.Start the meeting')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
