import React, { useCallback, useContext, useEffect, Suspense, useState } from 'react';
import { Switch, useHistory, Redirect, Route } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import routerConfig, { thirdAppPath } from '@/router/v2';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import LoginPage from '@/pagesv2/LoginPage';
import { connect } from 'dvajs';
import UserCardModal from '@/components/Modals/UserCardModal';
import SearchGlobalModal from '@/components/Modals/SearchGlobalModal';
import CreateSchedule from '@/components/Calendars/Create';
import FullIframeModal from '@/components/Work/BaseComponents/FullIframeModal';
import SuperviseDetail from '@/components/Supervise/components/SuperviseDetail';
import SuperviseCreate from '@/components/Supervise/components/SuperviseCreate';
import ScheduleDraw from '@/components/Projects/components/CalendarView/ScheduleDraw';
import ScheduleDrawOther from '@/components/Projects/components/CalendarView/ScheduleDrawOther';
import TaskDetailDraw from '@/components/Work/TaskDetailDraw';
import CreateCloudLinkMeetingModal from '@/components/MeetingCloudLink/CreateMeetingModal';
import CreateCloudLinkModal from '@/components/MeetingCloudLink/JoinMeetingModal';
import CreateMeetingXYLinkModal from '@/components/MeetingXYLink/CreateMeetingModal';
import JoinMeetingXYLinkModal from '@/components/MeetingXYLink/JoinMeetingModal';
import JoinMeetingModal from '@/components/Meeting/JoinMeetingModal';
import CreateMeetingModal from '@/components/Meeting/CreateMeetingModal';
import MeetingCallModal from '@/components/Meeting/MeetingCallModal';
import CloudLinkCallModal from '@/components/MeetingCloudLink/MeetingCallModal';
import BaseMeetingCallModal from '@/baseComponents/Meeting/MeetingCallModal';
import EventBus from '@/utils/bus';
import { useTranslation } from 'react-i18next';
import ZoomLogin from '@/components/Meeting/zoomLogin';
import MeetingCloudLink from '@/components/MeetingCloudLink/Connector';
import MeetingControl from '@/baseComponents/Meeting/index';
import MeetingUserListModal from '@/components/Meeting/MeetingUserListModal';
import UploadBox from '@/components/Joyspace/components/UploadBox';
import GlobalLayout from '@/layout/GlobalLayout';
import ExitTipModal from '@/components/Modals/ExitTipModal';
import DeeplinkHandler from '@/components/DeeplinkHandler';
import VoipLogin from '@/components/Voip/VoipLogin';
import JoyspaceFileSelectorModal from '@/components/Modals/JoyspaceFileSelectorModal';
import WorkStatusModal from '@/components/Modals/WorkStatusModal';
import { FocusSDK, isFocusEnv } from '@/utils';
import TaskDetailEditDraw from '@/components/Work/TaskDetailEditDraw';
import ApplyExternalContactsList from '@/components/Modals/ApplyExternalContactsList';
import ContactModal from '@/components/Modals/AddContactModal';
import AdministratorModal from '@/components/Modals/AdministratorModal';
import OfflineTip from '@/components/ErrorHolder/OfflineTip';
import IdentityInfoModal from '@/components/Modals/IdentityInfoModal';
// import NewVersionGuideModal from '@/components/Modals/NewVersionGuideModal';
// 用户指引
import UserGuideModal from '@/components/Modals/UserGuideModal';
import JDMeetWebReceiveModal from '@/components/MeetingJD/JDMeetWebReceiveModal';
import MeetingReceView from '@/components/Meeting/MeetingReceView';
import GroupSlitaire from '@/components/Work/Solitaire';
import CreateTaskDraw from '@/components/Work/CreateTaskDraw';
// import LabelModal from '@/components/AddressBook/right/labels';
import UserLabelNameModel from '@/components/Modals/LabelModel/AddUserLabelNameModel';
import EditUserLabelModel from '@/components/Modals/LabelModel/EditUserLabelModel';
import GroupNotificationBox from '@/components/chat/message/GroupNotification';
import NoticeDetailsCard from '@/components/chat/message/GroupNotificationCard/NoticeDetailsCard';
import MigrateModal from '@/components/Modals/MigrateModal';
import { message } from 'antd';
import PrivateNetExcepModal from '@/components/Modals/PrivateNetExcepModal';
import Context from '@/components/chat/context';
import RefusalCause from '@/components/Modals/ApplyExternalContactsList/RefusalCause';
import SolitaireDetails from '@/components/chat/message/SolitaireCard/SolitaireDetails';
import SlitaireHis from '@/components/Work/Solitaire/solitaireHistory';

function HomePage({ userData }: { userData: any }) {
    const { focusSDK, service, appRuntimeEnv, onReLogin, authInfo } = useContext<
        GlobalContextConfig
    >(GlobalContext);
    const history = useHistory();
    const { t } = useTranslation('common');
    const [dnsVisible, setDnsVisible] = useState(false);

    const onAutoLogin = useCallback(() => {
        service.authService
            .relogin()
            .then((res: any) => {
                const d = {
                    ...authInfo,
                    ...res,
                };
                focusSDK.login(
                    d,
                    appRuntimeEnv.selectedTeamId,
                    () => {
                        onReLogin(d);
                    },
                    appRuntimeEnv.registryConfig.autoLoginChecked
                );
            })
            .catch((e) => {
                FocusSDK.printLog('HomePage-autoLogin-e', `${JSON.stringify(e)}-${e}-${e.message}`);
                if (e.message.includes(`Failed to fetch`)) {
                    FocusSDK.printLog(`HomePage-autoLogin-e`, `Failed to fetch`);
                    EventBus.emit('chat:state-auth-faild');
                }
                if (e.message === 'failed to refresh token') {
                    focusSDK.logout('expired');
                }
            });
    }, [
        appRuntimeEnv.selectedTeamId,
        authInfo,
        focusSDK,
        onReLogin,
        service.authService,
        appRuntimeEnv.registryConfig.autoLoginChecked,
    ]);

    // 这里处理的是mac离线消息跳转
    const onLocationChange = useCallback(
        (path: string) => {
            history.push(path);
        },
        [history]
    );

    const newMsgCb = useCallback(
        ({ message }: any) => {
            if (isFocusEnv()) {
                focusSDK.sendIpcMessage('messageNotify', message);
            }
        },
        [focusSDK]
    );

    useEffect(() => {
        console.log('***homePage组件被调用***');
        if (!history) {
            return;
        }
        focusSDK.on('autoLogin', onAutoLogin);
        focusSDK.on('locationChange', onLocationChange);

        let dispose: { (): void; (): void } | null = null;
        if (isFocusEnv()) {
            dispose = focusSDK.addIpcEventListener('locationChange', onLocationChange);
        }
        EventBus.on('chat_message_new_message', newMsgCb);

        // if (focusSDK.isPrivateNet()) {
        //     // 初始dns设置存在问题且重设后依旧存在问题
        //     if (!focusSDK.privateDnsIsTrue() && !focusSDK.newPrivateDnsIsTrue) {
        //         setDnsVisible(true);
        //     }
        // }

        return () => {
            focusSDK.off('autoLogin', onAutoLogin);
            focusSDK.off('locationChange', onLocationChange);
            dispose?.();
            EventBus.off('chat_message_new_message', newMsgCb);
        };
    }, []); // eslint-disable-line

    return (
        <>
            <MeetingReceView />
            <ApplyExternalContactsList />
            <AdministratorModal />
            <RefusalCause />
            <IdentityInfoModal />
            <ContactModal />
            <UserLabelNameModel />
            <EditUserLabelModel />
            {/* <LabelModal /> */}
            <SearchGlobalModal />
            <JoinMeetingModal />
            <CreateMeetingModal />
            <CreateCloudLinkMeetingModal />
            <MeetingCloudLink />
            <MeetingControl />
            <CreateCloudLinkModal />
            <JoinMeetingXYLinkModal />
            <CreateMeetingXYLinkModal />
            <MeetingCallModal />
            <BaseMeetingCallModal />
            <CloudLinkCallModal />
            <CreateSchedule />
            <FullIframeModal />
            <ScheduleDraw />
            <ScheduleDrawOther />
            <SuperviseDetail />
            <NoticeDetailsCard />
            <SuperviseCreate />
            <CreateTaskDraw />
            <TaskDetailDraw />
            <TaskDetailEditDraw />
            <ZoomLogin />
            <UserCardModal />
            <MeetingUserListModal />
            <UploadBox />
            <ExitTipModal />
            <JoyspaceFileSelectorModal />
            <DeeplinkHandler history={history} />
            <VoipLogin />
            <WorkStatusModal />
            <OfflineTip />
            {/* <NewVersionGuideModal /> */}
            <UserGuideModal />
            <JDMeetWebReceiveModal />
            <GroupNotificationBox />
            <GroupSlitaire />
            <SolitaireDetails />
            <SlitaireHis />
            {isFocusEnv() ? <MigrateModal /> : null}
            <GlobalLayout>
                <Switch>
                    <Route key="/login" path="/login" exact render={() => <LoginPage />} />
                </Switch>
                <Switch>
                    {routerConfig.map((item) => {
                        const Component = item.component;
                        return (
                            item.path !== thirdAppPath && (
                                <Route
                                    key={item.path}
                                    exact={typeof item.exact === 'undefined' || item.exact}
                                    path={item.path}
                                    render={(props) => {
                                        return (
                                            <Suspense fallback={() => <div>loading...</div>}>
                                                <Component />
                                            </Suspense>
                                        );
                                    }}
                                />
                            )
                        );
                    })}
                </Switch>

                <CacheSwitch>
                    {routerConfig.map((item) => {
                        const Component = item.component;
                        return (
                            item.path === thirdAppPath && (
                                <CacheRoute
                                    // cache
                                    key={item.path}
                                    exact={typeof item.exact === 'undefined' || item.exact}
                                    path={item.path}
                                    render={(props) => {
                                        return <Component />;
                                    }}
                                />
                            )
                        );
                    })}

                    {/* <Route exact path="/chat/:type(s|g|n)/:id" component={ChatSupportPage} /> */}
                </CacheSwitch>
                <Switch>
                    <Redirect to="/" />
                </Switch>
            </GlobalLayout>
            {/* <PrivateNetExcepModal dnsVisible={dnsVisible} /> */}
        </>
    );
}

function mapStateToProps({ user, chat }: any) {
    return {
        userData: user.userData,
        chat,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        clearChatData: () => {
            dispatch({ type: 'chat/clearData' });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
