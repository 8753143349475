/**
 * 国际化主入口
 * @Description:
 * @author sunzhiguang
 * @date 2020/6/10
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './resource';

i18n.use(initReactI18next)
    .init({
        ns: ['common', 'chat', 'calendar', 'joinMe', 'work', 'joyspace'],
        resources: resources,
        defaultNS: 'common',
        lng: 'zh',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    })
    .then();

export default i18n;

export enum LANGUAGE {
    ZH = 'zh',
    EN = 'en',
}
