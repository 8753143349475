import React, { useCallback, useContext, useEffect, useReducer, useState, useRef } from 'react';
import { Input, List, Modal, Avatar, Tabs, message, Empty } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import logger from '@/utils/logger';
import { useTranslation } from '@/components/Joyspace/context';
import './index.less';
import IconFont from '@/components/icon';
import TabPaneCloud from './panels/TabPanelCloud';
import TabPaneSearch from './panels/TabPanelSearch';
import TabPanePersonal from './panels/TabPanelPersonal';
import TabPanelTeamSpace from './panels/TabPanelTeamSpace';
import TabPanelCollections from './panels/TabPanelCollections';
import PageList from './PageList';
import { initState, reducer, StoreContext } from './store';
import { StateActionNames } from './types';
import { buildDeepLink } from './utils';
import { changeEgovUrl } from '@/utils/tools';
import { duccConfigI18 } from '@/utils/i18n';

const log = logger.getLogger('JoyspaceFileSelectorModal');

export default function JoyspaceFileSelectorModal(props: any) {
    const t = useTranslation();
    const [state, dispatch] = useReducer(reducer, initState);
    const { focusSDK } = useContext<GlobalContextConfig>(GlobalContext);
    const [searchValue, setSearchValue] = useState<string>('');
    const [modalVisible, setModalVisible] = useState(false);
    const [callbackId, setCallbackId] = useState('');
    const [cloudTitle, setCloudTitle] = useState('selector.title');
    const chatCbk = useRef<any>();
    const modalVisibleCurrent = useRef(false);
    const onClose = useCallback(() => {
        // setModalVisible(false);
        modalVisibleCurrent.current = false;
        setSearchValue('');
        focusSDK.emit('openJoyspaceDocSelector:result', {
            status: 'cancel',
            callbackId: callbackId,
        });
        dispatch({ action: StateActionNames.CLEAR_SELECTED });
    }, [focusSDK, callbackId]);

    const handleModalOk = useCallback(() => {
        // setModalVisible(false);
        modalVisibleCurrent.current = false;
        setSearchValue('');
        console.log('cloudTitle~~~', cloudTitle);
        if (cloudTitle !== 'selector.cloudTitle') {
            console.log(
                'focusSDK.listenerCount~~~~',
                focusSDK.listenerCount('openJoyspaceDocSelector:result')
            );
            console.log(
                'focusSDK.listenerCount~~~~',
                focusSDK.listeners('openJoyspaceDocSelector:result')
            );
            focusSDK.emit('openJoyspaceDocSelector:result', {
                status: 'ok',
                callbackId: callbackId,
                results: state.selectedItems.map((item) => ({
                    id: item.id,
                    title: item.title,
                    pageType: item.pageType,
                    type: item.mimeType,
                    link: item.link,
                    permissionType: item.permissionType,
                    icon: changeEgovUrl(item.iconUrl),
                    linkH5: item.linkH5 || buildDeepLink(item.id, item.pageType),
                })),
            });
        } else {
            // console.log('chatCbk', typeof chatCbk.current);
            if (typeof chatCbk.current === 'function') {
                chatCbk.current(
                    state.selectedItems.map((item) => ({
                        id: item.id,
                        title: item.title,
                        pageType: item.pageType,
                        type: item.mimeType,
                        link: item.link,
                        permissionType: item.permissionType,
                        icon: changeEgovUrl(item.iconUrl),
                        linkH5: item.linkH5 || buildDeepLink(item.id, item.pageType),
                    }))
                );
            }
        }
        dispatch({ action: StateActionNames.CLEAR_SELECTED });
    }, [focusSDK, callbackId, state, cloudTitle, chatCbk]);

    const handleOpenJoyspaceDocSelector = useCallback(
        (data: { callbackId: string; max?: number }) => {
            setCloudTitle('selector.title');
            setCallbackId(data?.callbackId || '');
            dispatch({ action: StateActionNames.SET_MAX, max: data?.max || -1 });
            // setModalVisible(true);
            modalVisibleCurrent.current = true;
        },
        [setCallbackId, dispatch, setCloudTitle]
    );

    const handleOpenJoyspaceCloudSelector = useCallback(
        (data: { callbackId: string; max?: number; callback?: () => {} }) => {
            setCloudTitle('selector.cloudTitle');
            setCallbackId(data?.callbackId || '');
            let noop = () => {};
            console.log('data.callback', data?.callback);
            chatCbk.current = data?.callback || noop;
            dispatch({ action: StateActionNames.SET_MAX, max: data?.max || -1 });
            // setModalVisible(true);
            modalVisibleCurrent.current = true;
        },
        [setCallbackId, dispatch, setCloudTitle]
    );

    useEffect(() => {
        focusSDK
            .off('openJoyspaceDocSelector', handleOpenJoyspaceDocSelector)
            .on('openJoyspaceDocSelector', handleOpenJoyspaceDocSelector);
        focusSDK
            .off('openJoyspaceCloudSelector', handleOpenJoyspaceCloudSelector)
            .on('openJoyspaceCloudSelector', handleOpenJoyspaceCloudSelector);
        return () => {
            focusSDK.off('openJoyspaceDocSelector', handleOpenJoyspaceDocSelector);
            focusSDK.off('openJoyspaceCloudSelector', handleOpenJoyspaceCloudSelector);
        };
    }, [focusSDK, handleOpenJoyspaceDocSelector, handleOpenJoyspaceCloudSelector]);

    useEffect(() => {
        if (state.errorType) {
            message.error(t(state.errorType).replace('__count__', `${state.max}`));
            dispatch({ action: StateActionNames.CLEAR_ERROR_TYPE });
        }
    }, [state, t, dispatch]);

    return (
        <Modal
            title={t(cloudTitle, {
                joyspace: duccConfigI18.joyspace,
            })}
            destroyOnClose
            width="75vw"
            visible={modalVisibleCurrent.current}
            onCancel={onClose}
            onOk={handleModalOk}
            okText={t('confirm')}
            cancelText={t('cancel')}
            wrapClassName="modalAutoFillHeight joyspace-file-selector-modal"
            zIndex={1050}
        >
            <StoreContext.Provider value={[state, dispatch]}>
                <div className="selector-sources">
                    <div className="selector-sources-search">
                        <Input.Search
                            prefix={<IconFont className="prefix-icon" type="iconapp_ic_search" />}
                            placeholder={t('selector.search.placeholder')}
                            allowClear
                            onSearch={(value) => {
                                setSearchValue(value);
                            }}
                        />
                    </div>
                    {searchValue && <TabPaneSearch key="search" search={searchValue} />}
                    {!searchValue && (
                        <Tabs className="selector-sources-tabs">
                            <Tabs.TabPane key="joyspace" tab={t('home.tabs.recent')}>
                                <TabPaneCloud key="joyspace" />
                            </Tabs.TabPane>
                            <Tabs.TabPane key="myspace" tab={t('myspace.title')}>
                                <TabPanePersonal key="myspace" />
                            </Tabs.TabPane>
                            <Tabs.TabPane key="teamspace" tab={t('teamspace.title')}>
                                <TabPanelTeamSpace key="teamspace" />
                            </Tabs.TabPane>
                            <Tabs.TabPane key="collections" tab={t('collections.title')}>
                                <TabPanelCollections key="collections" />
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                </div>
                <div className="selected-files">
                    <div>
                        <PageList value={[state, dispatch]} />
                    </div>
                    {/* <List
                        className="selected-files-body"
                        split={false}
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={t('no data')}
                                />
                            ),
                        }}
                        renderItem={(item) => (
                            <List.Item
                                className="selected-files-item"
                                key={item.id}
                                actions={[
                                    <IconFont
                                        key="remove selected"
                                        className="iconapp_btn_alert_cancel"
                                        type="iconapp_btn_alert_cancel"
                                        onClick={() => {
                                            dispatch({
                                                action: StateActionNames.TOGGLE_SELECTED,
                                                item,
                                            });
                                        }}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            size={40}
                                            shape="square"
                                            src={changeEgovUrl(item.iconUrl)}
                                        />
                                    }
                                    title={item.title || t('untitled')}
                                />
                            </List.Item>
                        )}
                        dataSource={state.selectedItems}
                    /> */}
                </div>
            </StoreContext.Provider>
        </Modal>
    );
}
