import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, message, Popover, Tabs, Modal } from 'antd';
import dayjs from 'dayjs';
import { getThumbUrl } from '@/utils/chat/index';
import IconFont from '@/components/icon';
import { findAnnouncementDetail, ackAnnouncement } from '../../api';
import { Loading } from '@jd/focus-desktop-comps';
import noDataPicture from '@/assets/img/userlist-empty.png';
import { ProjectRole } from '../../types';
import OpenImgPreview from './PreviewImg';
import { Avatar } from '@/baseComponents/Avatar';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

const { TabPane } = Tabs;

interface Iprops {
    projectId: string;
    announcementId: string;
    onclose?: () => void;
    userRole: ProjectRole;
    archive: boolean;
    t: any;
    removeAnnounce: (announceId: string) => void;
    editAnnounce: (value: { content: string; announcementId: string }) => void;
    events: any;
}

interface IAnnounceDetail {
    announcementId: string;
    senderUserId: string;
    senderTeamId: string;
    senderUserAppId: string;
    senderUserName: string;
    senderUserAvatar: string;
    content: string;
    receiverSize: number;
    ackSize: number;
    pubTime: number;
    acked: boolean;
    showType: number;
    attachmentUrl: string[];
    userNum: number;
    users: {
        userId: string;
        teamId: string;
        app: string;
        userRole: number;
        duty: string;
        userName: string;
        headImg: string;
        acked: boolean;
        fullName: string;
    }[];
}

export function AnnounceDetail(props: Iprops) {
    const {
        projectId,
        announcementId = '',
        userRole,
        archive,
        removeAnnounce,
        t,
        editAnnounce,
        events,
    } = props;
    const [visible, setVisible] = useState(true);
    const [detailData, setDetailData] = useState<IAnnounceDetail>();
    const [loading, setLoading] = useState(false);

    const getDetail = useCallback(
        async function (projectId: string, announcementId: string) {
            setLoading(true);
            const res = await findAnnouncementDetail(projectId, announcementId);
            setLoading(false);
            if (res.errCode) return message.error(t('has_error'));
            setDetailData(res.announcementDetailInfo);
            if (!res.announcementDetailInfo.acked) {
                ackAnnouncement(projectId, announcementId);
            }
        },
        [t]
    );

    useEffect(() => {
        getDetail(projectId, announcementId);
    }, [projectId, announcementId, getDetail]);

    useEffect(() => {
        function handleAnnounceUpdate(data: { projectId: string; announcementId: string }) {
            if (visible && data.projectId === projectId && data.announcementId === announcementId) {
                getDetail(projectId, announcementId);
            }
        }
        events.on('announceList:update', handleAnnounceUpdate);
        return () => {
            events.off('announceList:update', handleAnnounceUpdate);
        };
    }, [events, projectId, announcementId, visible, getDetail]);

    const tabTitles = [
        `${t('detail.announce.no_read')} ${
            (detailData?.userNum || 0) - (detailData?.ackSize || 0)
        }`,
        `${t('detail.announce.has_read')} ${detailData?.ackSize || 0}`,
    ];
    const ackUserContent = (
        <Tabs defaultActiveKey="0" style={{ width: '300px' }}>
            {tabTitles.map((tabItem, index) => {
                const userData =
                    (detailData?.users || []).filter((item) => {
                        return index === 0 ? !item.acked : item.acked;
                    }) || [];
                if (userData.length === 0) {
                    return (
                        <TabPane tab={tabItem} key={index}>
                            <div className="pm-announce-nodata-img">
                                <img src={changeEgovUrl(noDataPicture)} alt="" />
                                <p>{t('detail.no_data')}</p>
                            </div>
                        </TabPane>
                    );
                }
                return (
                    <TabPane tab={tabItem} key={index}>
                        <ul className="pm-detail-user-list">
                            {userData.map((item) => (
                                <li key={item.app + item.userId + item.teamId}>
                                    {/* {item.headImg ? (
                                        <img src={changeEgovUrl(getThumbUrl(item.headImg, 30, 30))} alt="" />
                                    ) : (
                                        <span>{item.userName[0]}</span>
                                        )} */}
                                    <span>
                                        <Avatar
                                            src={changeEgovUrl(getThumbUrl(item.headImg, 30, 30))}
                                            styleObj={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '6px',
                                                marginTop: item.headImg ? -5 : 0,
                                            }}
                                            name={item.userName || ''}
                                        />
                                    </span>

                                    <h6>{item.userName}</h6>
                                    <p>{item.fullName}</p>
                                </li>
                            ))}
                        </ul>
                    </TabPane>
                );
            })}
        </Tabs>
    );

    return (
        <Drawer
            width="550"
            bodyStyle={{ padding: 0 }}
            onClose={() => {
                events.emit('announceList:update', {
                    projectId,
                    announcementId: announcementId,
                });
                setVisible(false);
            }}
            visible={visible}
            destroyOnClose={true}
        >
            {loading ? (
                <Loading style={{ marginTop: 50 }} />
            ) : (
                <>
                    <p className="pm-announce-detail-title">
                        <em>{t('detail.announce.title')}</em>
                        {!archive && [ProjectRole.ADMIN, ProjectRole.OWNER].includes(userRole) && (
                            <>
                                <span
                                    onClick={() => {
                                        editAnnounce({
                                            content: detailData?.content || '',
                                            announcementId: detailData?.announcementId || '',
                                        });
                                    }}
                                >
                                    <IconFont
                                        type="iconjs_ic_edit"
                                        className="pm-announce-small-icon"
                                    />
                                </span>
                                <span
                                    onClick={(e) => {
                                        Modal.confirm({
                                            title: t('detail.announce.remove_tip'),
                                            content: t('detail.announce.remove_confim'),
                                            okText: t('remove'),
                                            cancelText: t('cancel'),
                                            onOk() {
                                                removeAnnounce(announcementId);
                                                setVisible(false);
                                            },
                                        });
                                    }}
                                >
                                    <IconFont
                                        type="icondelect"
                                        className="pm-announce-small-icon"
                                    />
                                </span>
                            </>
                        )}
                    </p>
                    <div className="pm-announce-detail-wrap">
                        <div className="pm-announce-detail-header">
                            {detailData && (
                                <span>
                                    <Avatar
                                        src={
                                            detailData?.senderUserAvatar
                                                ? changeEgovUrl(
                                                      getThumbUrl(
                                                          detailData?.senderUserAvatar,
                                                          40,
                                                          40
                                                      )
                                                  )
                                                : ''
                                        }
                                        styleObj={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '7px',
                                        }}
                                        name={detailData?.senderUserName || ''}
                                    />
                                </span>
                            )}
                            {/* {detailData?.senderUserAvatar ? (
                                <img
                                    src={changeEgovUrl(getThumbUrl(detailData?.senderUserAvatar, 40, 40))}
                                    alt=""
                                />
                            ) : (
                                <span>{detailData?.senderUserName[0]}</span>
                            )} */}

                            <h6>{detailData?.senderUserName}</h6>
                            <p>
                                {t('detail.announce.update_at')}
                                {dayjs(detailData?.pubTime).format('YYYY-MM-DD HH:mm:ss')}
                            </p>
                        </div>
                        <div>
                            <p>
                                {(detailData?.users || []).map((userItem) => (
                                    <span key={userItem.app + userItem.teamId + userItem.userId}>
                                        {userItem.userName}
                                    </span>
                                ))}
                            </p>
                            <Popover content={ackUserContent} placement="bottom" trigger="click">
                                <p>
                                    {t('detail.announce.no_read')}
                                    <i>
                                        {(detailData?.userNum || 0) - (detailData?.ackSize || 0)}/
                                        {detailData?.userNum || 0}
                                    </i>
                                    <IconFont
                                        type="iconwin_right"
                                        className="pm-detail-right-icon"
                                    />
                                </p>
                            </Popover>
                        </div>
                        <div>
                            <p>{detailData?.content}</p>
                            <ul className="pm-detail-imgs">
                                {(detailData?.attachmentUrl || []).map((imgItem) => (
                                    <li
                                        key={imgItem}
                                        onClick={() =>
                                            OpenImgPreview({
                                                imgSrc: imgItem,
                                                imgArr: detailData?.attachmentUrl || [],
                                            })
                                        }
                                    >
                                        <img
                                            src={changeEgovUrl(getThumbUrl(imgItem, 72, 72))}
                                            alt=""
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </Drawer>
    );
}

export default function OpenAnnounceDetail(props: Iprops) {
    const el = document.createElement('div');
    const onclose = () => {
        if (el) {
            ReactDOM.unmountComponentAtNode(el);
            el.remove();
        }
    };
    ReactDOM.render(<AnnounceDetail {...props} onclose={onclose} />, el);
}
