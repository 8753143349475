import React, { useEffect } from 'react';
import ChatState from '@/types/chat/State';
import { connect } from 'dvajs';
import './index.less';
import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { ClickParam } from 'antd/lib/menu';
import { Session, Employee, Group, UID, ChatMessage, ChatMessageType } from '@/types/chat';
import {
    checkMessageSupport,
    convertEmployeeToString,
    employeeToUid,
    getMessageSendType,
    forwardJoySpaceCard,
    ContentItem,
    MessageCardType,
} from '@/components/chat/utils/message';
import { openUserSelectionModal } from '@/utils/modals';
import get from 'lodash/get';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import ImService from '@/server/ImService';
import { UserModel } from '@/models/user';
import { SessionSettingsType } from '@/types/chat/enum';
import { Button, message as toast, Modal } from 'antd';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { getSelectorSessions } from '@/components/chat/utils/session';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import debounce from 'lodash/debounce';
import { shareJoyspaceFiles } from '@/components/Joyspace/api/page';
import config, { ConfigEnum } from '@/config/config';
import { InputPlacement } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal/types';
import chatConfig from '@/config/chat';
import { forwardLeaveWord, selectedSessionsToForward } from '@/utils/chat/message';
import { analysisLog } from '@/utils/logAnalytics';

interface ForwardingProps {
    messages: any[];
    math: any;
    userData: UserModel;
    isGroupFileForward?: boolean;
    groupMsgs?: any[];
    clearSelectedMsgs: Function;
}
interface DvaDispatchProps {
    deleteSessionMessage: (data: { message: ChatMessage }) => void;
    changeSelectMore: Function;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}

type IProps = Readonly<ChatState & DvaDispatchProps & ForwardingProps>;

function mapStateToProps({ chat, user: { userData } }: { chat: ChatState; user: any }) {
    const {
        selectMore,
        messages,
        allSessionList,
        currentEmployee,
        selectedSession,
    } = chat as ChatState;
    return {
        selectMore,
        messages,
        allSessionList,
        currentEmployee,
        selectedSession,
        userData: userData ? userData.user : {},
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        async deleteSessionMessage(data: { message: ChatMessage }) {
            await dispatch({ type: 'chat/deleteSessionMessage', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

function Forwarding(props: IProps) {
    const {
        changeSelectMore,
        messages,
        allSessionList,
        currentEmployee,
        userData,
        selectedSession,
        deleteSessionMessage,
        updateMessages,
        isGroupFileForward,
        groupMsgs,
        clearSelectedMsgs,
    } = props;
    const [t] = useTranslation('chat');
    const cancelSelectMore = () => {
        changeSelectMore({ selectMore: false });
        updateMessages({ messages: [] });
    };

    const isMeetConditions = () => {
        if (isGroupFileForward) {
            if (!groupMsgs || groupMsgs?.length < 1) {
                return false;
            }
            if (groupMsgs?.length > 5) {
                toast.error('每次最多选择5个文件', 2);
                return false;
            }
        } else {
            if (messages.length < 1) {
                toast.error(t('please_select_messages'));
                return false;
            }
        }
        return true;
    };
    /* eslint-disable max-params */
    // 合并转发
    const mergeForwarding = () => {
        if (!isMeetConditions()) return;
        const fixedUsers = getSelectorSessions(allSessionList);
        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                // mode: SelectorMode.forward,
                mode: SelectorMode.user,
                tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recentIncludeGroup', 'org', 'groupCanBeSel', 'externalContacts']
                    : ['recentIncludeGroup', 'groupCanBeSel', 'externalContacts'],
                notLoad: isGroupFileForward ? true : false,
                leaveWordInput: {
                    maxLength: chatConfig['forward-leave-max-length'],
                    placeholder: t('please-enter-forward-leave'),
                    isTextArea: true,
                },
                inputPlacement: InputPlacement.bodyRightBottom,
                inputWrapClass: 'forward-input-wrap',
                validate(data) {
                    return new Promise((resolve, reject) => {
                        const list = get(data, 'data.result', []);
                        // if (isGroupFileForward) {
                        if (list.length > chatConfig['forward-session-count']) {
                            toast.error(`最多支持选择${chatConfig['forward-session-count']}个会话`);
                            return false;
                        }
                        // }
                        const forwardLeave = get(data, 'data.leaveWordInputValue', '');
                        if (
                            forwardLeave &&
                            forwardLeave.length > chatConfig['forward-leave-max-length']
                        ) {
                            toast.info(
                                t('content over maxLength', {
                                    name: '留言',
                                    maxLength: chatConfig['forward-leave-max-length'],
                                })
                            );
                            return false;
                        }
                        resolve();
                    });
                },
            },
            (data, close, update, cancelLoadingFn) => {
                // console.log(data);
                const list = get(data, 'data.result', []);
                // console.log('list', list);
                const names = list?.map((item: any) => item.name);
                if (isGroupFileForward) {
                    Modal.confirm({
                        className: 'confirm-modal',
                        title: '是否发送给',
                        content: names.join('，'),
                        icon: undefined,
                        centered: true,
                        okText: `确认（${names?.length}）`,
                        cancelText: '取消',
                        onOk(closeModal) {
                            return new Promise((resolve, reject) => {
                                doExitAndMergeForward(data, close, closeModal);
                            });
                        },
                        onCancel(cancelModal) {
                            cancelModal();
                            cancelLoadingFn();
                        },
                    });
                } else {
                    doExitAndMergeForward(data, close);
                }
            },
            userData
        );
    };

    const exitAndMergeForward = (options: any, list: any): Promise<Boolean> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const imService = ImService.getInstance();
            const sessionId = selectedSession.sessionId;
            let mids: number[] = [];
            let joyspacePages: any = [];
            const msgs: any[] = isGroupFileForward ? groupMsgs || [] : messages;
            msgs.forEach((message) => {
                if (message.type === ChatMessageType.JOYSPACEFILE) {
                    joyspacePages.push(message.fileId);
                } else if (message.type === ChatMessageType.TEXT && message.displayContent) {
                    for (let item of message.displayContent) {
                        if (item.type === MessageCardType.JoyspaceTopic) {
                            const ext = JSON.parse(item?.ext || '{}');
                            joyspacePages.push(ext.pageId);
                        }
                    }
                }
                mids.push(message.mid);
            });
            const result = await imService.mergeForwardMessage(sessionId, mids, options);
            if (isString(result) && result === SessionSettingsType.BANNED_POST) {
                // return toast.warn(t('banned_post_tip'));
                toast.warn(t('banned_post_tip'));
                resolve(false);
            }
            if (result) {
                if (joyspacePages.length > 0) {
                    // 合并转发文档授权
                    let users: Array<any> = [];
                    let groups: Array<any> = [];
                    (options.uids || []).forEach((user: any) => {
                        users.push({
                            userId: user.pin,
                            teamId: user.teamId,
                            permission_type: 2,
                        });
                    });
                    (options.gids || []).forEach((id: any) => {
                        groups.push({ gid: id, permission_type: 2 });
                    });
                    shareJoyspaceFiles({
                        page_ids: joyspacePages,
                        to_eepaas_users: users,
                        to_groups: groups,
                    });
                }
                if (isGroupFileForward) {
                    clearSelectedMsgs();
                } else {
                    updateMessages({ messages: [] });
                    changeSelectMore({ selectMore: false });
                }
                resolve(true);
            } else {
                resolve(false);
            }
        });
    };

    const doExitAndMergeForward = debounce(async (data: any, cb, closeModal?) => {
        // 等待合并转发消息处理完成以后，再关闭人员选择器窗口
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        const options = await selectedSessionsToForward(list);
        console.log('多选合并转发', data, options);
        const status = await exitAndMergeForward(options, list);
        if (status) {
            const forwardLeave = get(data, 'data.leaveWordInputValue', '');
            // console.log(forwardLeave);
            forwardLeave && (await forwardLeaveWord(currentEmployee, options, forwardLeave));
            toast.success(t('forwarding-successful'));
        } else {
            toast.error(t('forwarding-failed'));
        }
        closeModal?.();
        cb();
    }, 500);

    /* eslint-disable max-params */
    // 逐条转发
    const listsForwarding = () => {
        if (!isMeetConditions()) return;
        const fixedUsers = getSelectorSessions(allSessionList);
        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                // mode: SelectorMode.forward,
                mode: SelectorMode.user,
                tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recentIncludeGroup', 'org', 'groupCanBeSel', 'externalContacts']
                    : ['recentIncludeGroup', 'groupCanBeSel', 'externalContacts'],
                notLoad: isGroupFileForward ? true : false,
                leaveWordInput: {
                    maxLength: chatConfig['forward-leave-max-length'],
                    placeholder: t('please-enter-forward-leave'),
                    isTextArea: true,
                },
                inputPlacement: InputPlacement.bodyRightBottom,
                inputWrapClass: 'forward-input-wrap',
                validate(data) {
                    return new Promise((resolve, reject) => {
                        const list = get(data, 'data.result', []);
                        // if (isGroupFileForward) {
                        if (list.length > chatConfig['forward-session-count']) {
                            toast.error(`最多支持选择${chatConfig['forward-session-count']}个会话`);
                            return false;
                        }
                        // }
                        const forwardLeave = get(data, 'data.leaveWordInputValue', '');
                        if (
                            forwardLeave &&
                            forwardLeave.length > chatConfig['forward-leave-max-length']
                        ) {
                            toast.info(
                                t('content over maxLength', {
                                    name: '留言',
                                    maxLength: chatConfig['forward-leave-max-length'],
                                })
                            );
                            return false;
                        }
                        resolve();
                    });
                },
            },
            (data, close, update, cancelLoadingFn) => {
                // console.log(data);
                const list = get(data, 'data.result', []);
                // console.log('list', list);
                const names = list?.map((item: any) => item.name);
                if (isGroupFileForward) {
                    Modal.confirm({
                        className: 'confirm-modal',
                        title: '是否发送给',
                        content: names.join('，'),
                        icon: undefined,
                        centered: true,
                        okText: `确认（${names?.length}）`,
                        cancelText: '取消',
                        onOk(closeModal) {
                            return new Promise((resolve, reject) => {
                                doExitAndForward(data, close, closeModal);
                            });
                        },
                        onCancel(cancelModal) {
                            cancelModal();
                            cancelLoadingFn();
                        },
                    });
                } else {
                    doExitAndForward(data, close);
                }
            },
            userData
        );
    };
    const exitAndForward = (options: any, list: any): Promise<Boolean> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const imService = ImService.getInstance();
            const sessionId = selectedSession.sessionId;
            let forwardAll = true;
            const msgs: any[] = isGroupFileForward ? groupMsgs || [] : messages;
            let body: any = {};
            for (let message of msgs) {
                // TODO 逐条转发
                body = await forwardJoySpaceCard(message, options, message.type);
                const result = await imService.forwardMessage(sessionId, message, options, body);
                if (isString(result) && result === SessionSettingsType.BANNED_POST) {
                    forwardAll = false;
                    // return toast.warn(t('banned_post_tip'));
                    toast.warn(t('banned_post_tip'));
                    resolve(false);
                }
                if (!result) {
                    resolve(false);
                }
            }
            if (forwardAll) {
                // toast.success(t('forwarding-successful'));
                if (isGroupFileForward) {
                    clearSelectedMsgs();
                } else {
                    updateMessages({ messages: [] });
                    changeSelectMore({ selectMore: false });
                }
                resolve(true);
            }
        });
    };

    const doExitAndForward = debounce(async (data: any, cb, closeModal?) => {
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        const options = await selectedSessionsToForward(list);
        console.log('多选逐条转发', data, options);
        const status = await exitAndForward(options, list);
        if (status) {
            const forwardLeave = get(data, 'data.leaveWordInputValue', '');
            // console.log(forwardLeave);
            forwardLeave && (await forwardLeaveWord(currentEmployee, options, forwardLeave));
            toast.success(t('forwarding-successful'));
        } else {
            toast.error(t('forwarding-failed'));
        }
        closeModal?.();
        cb();
    }, 500);

    // 删除
    const deleteMessages = () => {
        if (messages.length < 1) {
            toast.error(t('please_select_messages'));
            return;
        }
        // 删除提醒
        prompt({
            title: t('delete-reminder'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onCancel: () => {
                analysisLog('Xtbg_Msg', 'InfoMultiDelete_Cancel');
            },
            onOk: async () => {
                // 调用删除接口
                analysisLog('Xtbg_Msg', 'InfoMultiDelete_Confirm');
                let deleteAll = true;
                for (let message of messages) {
                    const imService = ImService.getInstance();
                    const result = await imService.deleteMessage(message);
                    if (result) {
                        // 删除 dva 中的 数据
                        deleteSessionMessage({ message: message });
                    } else {
                        deleteAll = false;
                        toast.error(t('deletion-failed'));
                        return;
                    }
                }
                if (deleteAll) {
                    toast.success(t('deletion-succeeded'));
                    updateMessages({ messages: [] });
                    changeSelectMore({ selectMore: false });
                }
            },
            content: t('delete-selected-message'),
            okText: t('determine'),
            cancelText: t('cancelText'),
        });
    };
    if (isGroupFileForward) {
        return (
            <div className="group-file-btn-wrapper">
                <Button
                    type="primary"
                    onClick={debounce(listsForwarding, 500)}
                    clstag="pageclick|keycount|Xtbg_Msg|InfoMultiListForward"
                >
                    {t('lists_forwarding')}
                </Button>
                <Button
                    type="primary"
                    onClick={debounce(mergeForwarding, 500)}
                    clstag="pageclick|keycount|Xtbg_Msg|InfoMultiMergeForward"
                >
                    {t('merge_forwarding')}
                </Button>
            </div>
        );
    } else {
        return (
            <div className="forwarding">
                <div className="operate">
                    <div className="lists-forwarding">
                        <span
                            onClick={listsForwarding}
                            clstag="pageclick|keycount|Xtbg_Msg|InfoMultiListForward"
                        >
                            <IconFont className="icon" type="iconapp_btn_forwarding1" />
                        </span>
                        <span>{t('lists_forwarding')}</span>
                    </div>

                    <div
                        className="merge-forwarding"
                        clstag="pageclick|keycount|Xtbg_Msg|InfoMultiMergeForward"
                    >
                        <span onClick={mergeForwarding}>
                            <IconFont
                                className="icon"
                                style={{ fontSize: '16px' }}
                                type="iconapp_btn_merge"
                            />
                        </span>
                        <span>{t('merge_forwarding')}</span>
                    </div>

                    <div className="delete">
                        <span
                            onClick={deleteMessages}
                            clstag="pageclick|keycount|Xtbg_Msg|InfoMultiDelete"
                        >
                            <IconFont className="icon" type="iconapp_ic_delete" />
                        </span>
                        <span>{t('delete')}</span>
                    </div>
                </div>
                <div className="cancel">
                    <div>
                        <span>
                            {t('userSelection.hasSelected')}（{messages.length || 0}）
                        </span>
                    </div>
                    <div
                        onClick={cancelSelectMore}
                        clstag="pageclick|keycount|Xtbg_Msg|InfoCancelMulti"
                    >
                        <span>{t('cancelText')}</span>
                        <span>
                            <IconFont className="icon" type="iconapp_btn_file_cancel" />
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Forwarding);
