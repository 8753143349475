// 新建组接龙
import React, { useState, useEffect, useContext, useCallback } from 'react';
import './index.less';
import { Drawer, Input, Button, Space, Select, Form, message as toast, Dropdown, Menu } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { Avatar } from '@/baseComponents/Avatar';
import { ChatMessage, Session, Employee, MessageEmitCategory } from '@/types/chat';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import DatePicker from '../BaseComponents/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { NewTaskModel, PRIORITY_TYPE } from '@/types/newWork';
import { nanoid } from 'nanoid';
import SolitaireMessageBody, {
    SOLITAIRE_TYPE,
} from '@jd/jdee.im.sdk/lib/es/protocol/message/solitaire';
import { newSolitaire } from '@/api/Solitaire';
import IconFont from '@/components/icon';
import { debounce } from 'lodash';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import i18n from '@/i18n';
import { changeEgovUrl } from '@/utils/tools';
import ImService from '@/server/ImService';
import { buildsolitaireMessage } from '@/utils/chat/message';
import bus from '@/utils/bus';
const { TextArea } = Input;
const { Option } = Select;

let solitaireUserList: any = [];
let textarealist: any = '';
const initTaskDetail: NewTaskModel = {
    title: '',
    taskId: nanoid(),
    owner: {
        userId: '',
        teamId: '',
        app: '',
        headImg: '',
        realName: '',
        processStatus: 1,
    },
    executors: [],
    remind: '0',
    priorityType: PRIORITY_TYPE.NORMAL_PRIORITY,
    workStartTime: null,
    workStopTime: null,
    startTime: -1,
    endTime: -1,
    content: '',
    remark: {
        id: '',
        remark: '',
    },
    resources: [],
};
interface MessageOperateProps {
    message?: ChatMessage;
    selectedSession: Session;
    currentEmployee: Employee;
    titleValues: string;
    connectState: ConnectState;
    openSolitaireHis: (data: { isvisibles: boolean; solitaireSid: any }) => void;
}
type IProps = Readonly<MessageOperateProps>;

function GroupSlitaire(props: IProps) {
    const { currentEmployee, selectedSession, connectState, openSolitaireHis } = props;
    const inputRef = React.useRef<any>(null);
    const [t] = useTranslation('chat');
    const [visible, setVisible] = useState(false);
    const [userinfo, setUserinfo] = useState<Employee>();
    const { focusSDK, authInfo } = useContext<GlobalContextConfig>(GlobalContext);
    const [taskDetail, setTaskDetail] = useState<NewTaskModel>(initTaskDetail);
    const [textValue, settextValue] = useState('');
    const [example, setexample] = useState('');
    const [open, setopen] = useState('');
    const [titleValue, settitleValue] = useState(false);
    // 获取字段
    const [titleValues, settitleValues] = useState('');
    const [unixs, setunixs] = useState<any>();
    const [show, setshow] = useState(false);
    const [focus, setfocus] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [soLdisable, setsoLdisable] = useState(false);
    const state: any = connectState;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showDrawer = (data: any) => {
        setUserinfo(data.currentEmployee);
        setVisible(true);
    };

    useEffect(() => {
        // @ts-ignore
        window.debugSdk = focusSDK;
        focusSDK.on('openCreateSolitaire', showDrawer);
        return () => {
            focusSDK.off('openCreateSolitaire', showDrawer);
        };
    }, [focusSDK, showDrawer, titleValues]);

    const onClose = useCallback(() => {
        setVisible(false);
        settextValue('');
        settitleValues('');
        settitleValue(false);
        setsoLdisable(false);
        setexample('');
        setunixs(undefined);
        setopen('');
        setshow(false);
        setisloading(false);
        setTaskDetail(initTaskDetail);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        solitaireUserList = [];
        textarealist = '';
    }, []);
    // 发布群接龙
    function sendMessages2() {
        if (show === false) {
            sendMessages();
            setshow(false);
            return;
        }
        solitaireUserList = [
            {
                solitaireUserId: '',
                appId: currentEmployee.app,
                teamId: currentEmployee.teamId,
                solitaireId: '',
                name: inputRef.current?.state.value
                    ? inputRef.current?.state.value
                    : currentEmployee.name,
                value: textarealist,
                userId: currentEmployee.userId,
                status: 1,
            },
        ];
        sendMessages();
    }
    const sendMessages = useCallback(async () => {
        const instance = ImService.getInstance();
        setisloading(true);
        const sessionId = selectedSession.sessionId;
        const newTime = new Date();
        if (state.state === ConnectState.OFFLINE) {
            setisloading(false);
            return toast.error(i18n.t('Network Error'));
        }
        if (unixs < newTime.valueOf() && unixs !== null) {
            setisloading(false);
            return toast.warning('不能选择过去时间！');
        }
        if (!unixs) {
            setisloading(false);
            return toast.warning('截止时间不能为空');
        }
        if (titleValues === '' && !unixs) {
            setisloading(false);
            return toast.warning('标题不能为空！');
        }
        const newDate = new Date().getTime();
        const datas = buildsolitaireMessage({
            content: titleValues,
            currentEmployee,
            data: {
                solitaireId: '',
                nickName:
                    currentEmployee.name || authInfo?.teamUserInfo?.realName || authInfo?.realName,
                creatorUserId: currentEmployee.userId,
                title: titleValues,
                solitaire: SOLITAIRE_TYPE.ROOT,
                deadline: newDate,
                // userList: show
                //     ? [
                //           {
                //               app: currentEmployee.app,
                //               clientType: 'pc',
                //               name: currentEmployee.name,
                //               nickName: currentEmployee.name,
                //               pin: currentEmployee.userId,
                //               teamId: currentEmployee.teamId,
                //               timestamp: newDate,
                //               action: 0,
                //           },
                //       ]
                //     : [],
            },
        });
        const messageOpts = await instance?.getMessageOptions();
        datas.id = messageOpts.id;
        const messages = {
            ...messageOpts,
            body: { ...datas },
            gid: sessionId,
            type: MessageEmitCategory.CHAT_MESSAGE,
        };
        const [data, err] = await newSolitaire({
            appId: currentEmployee.app,
            teamId: currentEmployee.teamId,
            userId: currentEmployee.userId,
            solitaireId: '',
            businessId: selectedSession.id,
            businessType: 1,
            title: titleValues,
            deadLine: unixs,
            open: 1,
            example: example,
            desc: textValue,
            status: 1,
            solitaireUserList: [...solitaireUserList] as any,
            groupChatMessage: messages,
        });
        if (err) {
            toast.error(err);
            setisloading(false);
            return;
        }
        if (data) {
            // if (show) {
            //     bus.emit('message:share-SolitaireCard', data);
            // }
            setVisible(false);
            setisloading(false);
            onClose();
        }
    }, [
        selectedSession,
        unixs,
        titleValues,
        currentEmployee,
        example,
        textValue,
        onClose,
        state,
        authInfo,
    ]);

    // 时间选择器
    function onTimeChange(time: any) {
        const newTime = new Date();
        const unix = time ? time.valueOf() : null;
        setunixs(unix);
        setsoLdisable(true);
        if (unix < newTime.valueOf() && unix !== null) {
            setunixs(undefined);
            return toast.warning('不能选择过去时间！');
        }
        // if (time && taskDetail?.workStopTime?.isSameOrBefore(time)) {
        //     return toast.warning(t('The start time must not be later than the deadline'));
        // }
        const task = {
            ...taskDetail,
            startTime: unix,
            workStartTime: unix ? dayjs(Number(unix)) : null,
        };
        setTaskDetail(task);
        if (time === null) {
            setsoLdisable(false);
        }
        return;
    }
    const onTextArea = (e: any) => {
        settextValue(e.target.value);
    };
    const onTitlechang = (e: any) => {
        settitleValues(e.target.value);
        e.target.value.trim();
        if (e.target.value) {
            settitleValue(true);
        } else {
            settitleValue(false);
        }
    };

    // 字段更新时触发回调
    function ValuesChange(e: any) {
        textarealist = e.target.value;
    }

    // 新建
    const getGroupSlitaire = (
        <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            labelAlign="left"
        >
            <Form.Item label={t('solitaires.title')} colon={false} required className="titles">
                <Input
                    value={titleValues}
                    placeholder={t('solitaires.pleases')}
                    onChange={(e) => {
                        onTitlechang(e);
                    }}
                    maxLength={50}
                />
            </Form.Item>
            <Form.Item label={t('solitaires.describe')} colon={false}>
                <TextArea
                    rows={4}
                    maxLength={100}
                    className="describe"
                    value={textValue}
                    autoSize={{ minRows: 6, maxRows: 6 }}
                    placeholder={t('solitaires.pleases')}
                    style={{ resize: 'none' }}
                    onChange={(e) => {
                        onTextArea(e);
                    }}
                />
                <p className="conter" style={{ textAlign: 'right' }}>
                    <span style={{ color: '#BFC1C4' }}>{textValue.length}</span>/100
                </p>
            </Form.Item>
            <Form.Item label={t('solitaires.time')} required colon={false} className="titles">
                <Space direction="vertical" size={12}>
                    <DatePicker
                        placeholder="请设置截止时间"
                        time={taskDetail?.workStartTime || null}
                        onChange={(time: Dayjs | null) => onTimeChange(time)}
                        soLdisable={soLdisable}
                        type="group_solitaire_new"
                    />
                </Space>
                {unixs && (
                    <div className="tooltip">
                        <span>截止时间5分钟前未参与人员，将收到AI助手提醒</span>
                    </div>
                )}
            </Form.Item>
        </Form>
    );
    const footer = (
        <Space align="end">
            <Button
                onClick={() => {
                    setVisible(false);
                }}
            >
                取消
            </Button>
            <Button
                type="primary"
                loading={isloading}
                htmlType="submit"
                disabled={titleValue === false ? true : false}
                onClick={debounce(() => {
                    sendMessages2();
                }, 500)}
            >
                发布
            </Button>
        </Space>
    );
    const menu = (
        <Menu className="GroupSlitaireMenu">
            <Menu.Item>
                <span
                    onClick={() => {
                        openSolitaireHis({
                            isvisibles: true,
                            solitaireSid: selectedSession.sessionId,
                        });
                        setVisible(false);
                        setTaskDetail(initTaskDetail);
                        setunixs(undefined);
                    }}
                >
                    查看历史接龙
                </span>
            </Menu.Item>
        </Menu>
    );
    return (
        <div>
            <Drawer
                width={450}
                title={t('solitaire')}
                className="work-solitaire"
                onClose={() => {
                    setVisible(false);
                    setTaskDetail(initTaskDetail);
                    setunixs(undefined);
                    setshow(false);
                    solitaireUserList = [];
                }}
                visible={visible}
                destroyOnClose={true}
                placement="right"
                footer={footer}
            >
                <div className="headers">
                    <div className="headers-left">
                        <Avatar src={changeEgovUrl(userinfo?.avatar)} name={userinfo?.name || ''} />
                        <p>由{userinfo?.name}发起接龙，目前有0人参与</p>
                    </div>
                    <div className="headers-right">
                        <Dropdown overlay={menu} trigger={['click']}>
                            <IconFont type="iconlog_more" />
                        </Dropdown>
                    </div>
                </div>
                <div className="content">{getGroupSlitaire}</div>
                <div className="con-bom">
                    <Form labelAlign="left" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                        <Form.List name="names">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => {
                                        return (
                                            <Form.Item
                                                label={
                                                    <IconFont
                                                        type="iconbutton_app_yichu"
                                                        className="removeIcon"
                                                        onClick={() => {
                                                            remove(field.name);
                                                            solitaireUserList.splice(index, 1);
                                                            setshow(false);
                                                        }}
                                                    />
                                                }
                                                required={false}
                                                key={field.key}
                                                colon={false}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                    name={[field.name, 'value']}
                                                >
                                                    <div className="names">
                                                        <Input
                                                            placeholder="姓名"
                                                            className="hover"
                                                            allowClear={true}
                                                            ref={inputRef}
                                                            defaultValue={userinfo?.name}
                                                            style={{
                                                                width: '110px',
                                                                marginRight: '12px',
                                                            }}
                                                        />
                                                        <TextArea
                                                            placeholder="接龙内容"
                                                            autoSize={{ minRows: 1, maxRows: 7 }}
                                                            maxLength={100}
                                                            style={{
                                                                resize: 'none',
                                                            }}
                                                            onChange={(e) => {
                                                                ValuesChange(e);
                                                            }}
                                                        />
                                                    </div>
                                                </Form.Item>
                                            </Form.Item>
                                        );
                                    })}
                                    <Form.Item>
                                        <div
                                            className="con-bom-add"
                                            onClick={() => {
                                                if (fields.length > 0) {
                                                    toast.error('该接龙不允许每人接龙多次');
                                                    return;
                                                }
                                                add();
                                                setshow(true);
                                            }}
                                        >
                                            {/* <div className="jia"> */}
                                            <IconFont
                                                type="iconapp_btn_more"
                                                className="chat-aggregation-icon"
                                                clstag="pageclick|keycount|focus_chat_01_1615797500283|77"
                                            />
                                            {/* </div> */}
                                            <span>添加选项</span>
                                        </div>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form>
                </div>
            </Drawer>
        </div>
    );
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    const {
        sessionMessageMap,
        sessionMessages,
        messages,
        connectState,
        selectedSession,
        currentEmployee,
    } = chat;
    return {
        sessionMessageMap,
        sessionMessages,
        messages,
        connectState,
        selectedSession,
        currentEmployee,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
        openSolitaireHis(data: { isvisibles: boolean; solitaireSid: any }) {
            dispatch({ type: 'chat/openSolitaireHis', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSlitaire);
