import React, { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { AppStoreApplication } from '@/services/AppStore';
import ToolsItem from '../ToolsItem';
import { SearchGlobalParams, SearchType } from '@/services/SearchService';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { isBjme } from '@/utils';
import { useTranslation } from 'react-i18next';
import Bus from '@/utils/bus';
import { markFavorite, transformInfo } from '@/components/ThirdApp/utils';
let keyWord = ''; // 保存参数
export default function SearchList() {
    const [searchList, setSearchList] = useState<AppStoreApplication[]>([]);
    const { service, authInfo } = useContext<GlobalContextConfig>(GlobalContext);

    const localstorageKey = `${authInfo.userId}_${authInfo.teamUserInfo.teamId}`;

    const [loading, _setLoading] = useState(false);
    const [currentApplicationId, setCurrentApplicationId] = useState('');

    const { t } = useTranslation('common');
    const onSearch = useCallback(
        async (_keyWord: string) => {
            keyWord = _keyWord ? _keyWord : keyWord;
            if (!keyWord) {
                return;
            }
            let params: SearchGlobalParams = {
                keyword: keyWord,
                searchType: SearchType.App,
                pageNo: 1,
                pageSize: 999,
            };
            try {
                const res: any = await service.searchService.searchGlobal(params);
                res.apps = res.apps?.map((item: any) => transformInfo(item));
                setSearchList(markFavorite(localstorageKey, res.apps || []));
            } catch (error) {
                setSearchList([]);
            }
        },
        [service.searchService, localstorageKey]
    );
    const onSearchUpData = useCallback(
        (appInfo: AppStoreApplication) => {
            const list = [...searchList];
            for (let item of list) {
                if (item.applicationId === appInfo.applicationId) {
                    item.favorite = appInfo.favorite;
                }
            }
            setSearchList(list);
        },
        [searchList]
    );
    useEffect(() => {
        Bus.on('onSearch', onSearch);
        Bus.on('onSearchUpData', onSearchUpData);
        return () => {
            Bus.off('onSearch', onSearch);
            Bus.off('onSearchUpData', onSearchUpData);
        };
    }, [onSearchUpData, onSearch]);

    // 设置loading及appKey
    const setLoading = useCallback((parames) => {
        const { loading = false, applicationId = '' } = parames;
        _setLoading(loading);
        setCurrentApplicationId(applicationId);
    }, []);
    useEffect(() => {
        Bus.on('setLoading', setLoading);
        return () => {
            Bus.off('setLoading', setLoading);
        };
    }, [setLoading]);
    return (
        <div style={{ width: '100%' }}>
            {searchList.length > 0 &&
                searchList?.map((item: any) => {
                    return (
                        <ToolsItem
                            loading={loading}
                            currentApplicationId={currentApplicationId}
                            key={item.applicationId}
                            toolsItem={item}
                        />
                    );
                })}
            {searchList.length === 0 && (
                <div style={{ textAlign: 'center' }}>
                    {isBjme() ? t('thirdapp.no-tools') : t('thirdapp.no-application')}
                </div>
            )}
        </div>
    );
}
