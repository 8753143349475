/**
 * JoyWork 类型定义
 */

import { Dayjs } from 'dayjs';

export enum TaskSourceTypeEnum {
    JoyWork = 1,
}

// export type CreateTaskForm = Pick<
//     TaskModel,
//     Exclude<
//         keyof TaskModel,
//         | 'taskId'
//         | 'taskStatus'
//         | 'operatorCount'
//         | 'completeCount'
//         | 'enablePermission'
//         | 'permission'
//         | 'duCount'
//     >
// >;

export interface CreateTaskForm {
    parentTaskId?: string; // 父任务id
    parentTask?: CreateTaskForm;
    title: string;
    owners: WorkUserInfo[];
    executors: WorkUserInfo[];
    priorityType: number;
    startTime?: string | null;
    endTime?: string | null;
    workStartTime: Dayjs | null;
    workStopTime: Dayjs | null;
    taskId?: string;
    clientChildTasks: CreateTaskForm[];
    isChild?: boolean;
    resources: TaskResource[];
    remark?: string; // 备注
    projectId?: string;
    sourceType?: TaskSourceTypeEnum; // 任务来源类型：比如：项目、个人、流程中心
    sourceId?: string;
    sourceName?: string;
    timeClassify?: number; // 时间分类类型：0置顶 1已逾期 2今天 3未来7天 4未安排 5已完成
    notifyTime?: string;
    completeTime?: string;
    sort?: number;
}
export interface TaskModel {
    id?: number;
    taskId: string;
    projectId?: string;
    taskStatus: number;
    priorityType: number;
    sourceType?: TaskSourceTypeEnum; // 任务来源类型：比如：项目、个人、流程中心
    sourceId?: string;
    title: string;
    content?: string; // 内容
    remark?: string; // 备注
    timeClassify?: number; // 时间分类类型：0-今天，1-本周，2-稍后，3-逾期，4-自定义（不会落库）
    isCustom?: number; // 是否是自定义，0不是，1是（为前端使用）
    operatorCount: number; // 执行人人数(创建时算负责人，可以干掉)
    completeCount: number; // 执行人完成人数
    notifyTime?: string;
    completeTime?: string;
    isChild?: boolean;
    haveChild?: number; // 子任务的个数
    parentTaskId?: string; // 父任务id
    frontTask?: string; // 前置任务，多个前置任务逗号分隔
    creator?: string;
    gmtCreate?: string;
    modifier?: string; // 数据修改人
    sort?: number;
    startTime?: string;
    endTime?: string;
    gmtModified?: string; // 数据修改时间
    status?: number; // 数据状态值:0、无效;1、有效
    workStartTime: Dayjs | null;
    workStopTime: Dayjs | null;
    source?: any; // 前端临时字段
    owners: WorkUserInfo[];
    executors: WorkUserInfo[];
    childWorks: TaskModel[];
    parentTask?: TaskModel;
    resources: TaskResource[];
    enablePermission: boolean; // 是否启用权限逻辑
    groupId?: string;
    permission: string[];
    duCount: number;
    sourceName: string;
    extraConf?: {
        permission?: { [key: string]: number }; //  1(强制可编辑) 2(强制不可编辑) null(默认) ；
        openView?: number; // 1(打开内部) 、2(打开外部) 、null(默认)
    };
}
export interface WorkUserInfo {
    id?: number;
    taskId?: string;
    userId: string;
    teamId: string;
    app: string;
    userRole?: number;
    taskUserRole?: number;
    processStatus?: number;
    status?: number;
    weight?: number;
    progress?: number; // 个人进度（百分比：个人自己的工作的完成率）
    creator?: string;
    gmtCreate?: string;
    modifier?: string;
    gmtModified?: string;
    headImg: string;
    realName: string;
}

export interface TaskComment extends WorkUserInfo {
    id?: number;
    commentId: string;
    taskId: string;
    userId: string;
    teamId: string;
    app: string;
    content: string;
    parentId: string; // 父评论ID
    type: number; // TEXT(1, "普通文本"), DELTA(2, "delta数组"), HTML(3, "HTML")
    status: number;
    creator: string;
    gmtCreate: string;
    modifier: string;
    gmtModified: string;
    createTime: string;
    replyUserId?: string;
    replyTeamId?: string;
    replyApp?: string;
    replyId?: string;
    replyUser?: WorkUserInfo;
    isCommentDelete?: boolean; // 当前评论信息是否可以删除
}

export interface TaskResource {
    creatorUserId: any; // 创建者
    id?: number;
    resourceId: string; // '资源ID',
    taskId?: string; // '任务ID',
    url: string; // '资源地址',
    name: string; // '资源名称',
    fileType: string; // '文件类型',
    bizType: number; // '业务类型',
    bizId: string; // '业务ID',
    size: number; // '资源大小',
    resourceType: number; // '资源类型(资源权限控制)',
    status?: number; // '数据状态值:0、无效;1、有效',
    creator?: string; // '数据创建人',
    gmtCreate?: number; // '数据创建时间',
    modifier?: string; // '数据修改人',
    gmtModified?: number; // '数据修改时间'
}

export enum TimeClassifyEnum {
    Temporary = 0,
    Past = 1,
    Today = 2,
    Week = 3,
    LaterOrUntime = 4,
    Finished = 5,
}

export enum TaskStatus {
    New = 1,
    Finish = 2,
    UN_FINISH_LOCK = 3, // (3, "未完成(锁定)"),
    FINISH_LOCK = 4, // (4, "已完成(锁定)"),
}

export enum TaskResourceBizType {
    FILE = 1, // (1, "文件"),
    JOYSPACE = 2, // (2, "joyspace"),
}
export enum TaskResourcePermissionType {
    COMMON = 1, // (1, "公共"),
}

export enum TaskCommentType {
    Text = 1, // 纯文本格式
    Delta = 2, // quill的delta数据
    Html = 3, // html格式
}

export enum TaskEditPermissionEnum {
    OnlyHead = 1, // 仅负责人可编辑
    HeadAndExecute = 2, // 负责人和执行人都可编辑
}

export enum TaskPermissionEnum {
    TASK_UPDATE = 'update',
    TASK_DELETE = 'delete',
    TASK_COMPLETE = 'complete',
}

// 标注多端同步消息要更新的位置
export enum TaskPartEnum {
    PERSONAL_TASK = 'PERSONAL_TASK', // 个人任务
    PROJECT_TASK = 'PROJECT_TASK', // 项目任务
    MEANWHILE_TASK = 'MEANWHILE_TASK', // 同时更新个人任务和项目任务
    IM_GROUP = 'IM_GROUP', // IM群组
    PROJECT_USER = 'PROJECT_USER', // 更新项目用户
    PROJECT_LIST = 'PROJECT_LIST', // 项目首页列表
    TREND = 'TREND', // 动态页
    ANNOUNCEMENT = 'ANNOUNCEMENT', // 公告页
    DELETE_USER = 'DELETE_USER', // 从项目中删除成员
}
