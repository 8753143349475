import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import GlobalSearchModel from './GlobalSearchModel';
import { IconSearch, IconCircleClose } from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import './index.less';
import Bus from '@/utils/bus';
import { UAParser } from 'ua-parser-js';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { Input } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import AddressBook from '@/pages/AddressBook';
import { analysisLog } from '@/utils/logAnalytics';
interface GlobalSearchType {
    activeKey?: SearchType;
}
let timer: any = null;
export default function GlobalSearch(props: GlobalSearchType) {
    const { focusSDK } = useContext<GlobalContextConfig>(GlobalContext);
    const { activeKey = SearchType.All } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [keyWord, setKeyWord] = useState<string>('');
    const [isChange, setIsChange] = useState(false);
    let inputRef: any = useRef({});
    const location = useLocation();

    // useEffect(() => {
    //     document.addEventListener('click', () => {
    //         setKeyWord('');
    //         setVisible(false);
    //     });
    //     return () => {
    //         document.removeEventListener('click', () => {
    //             setKeyWord('');
    //             setVisible(false);
    //         });
    //     };
    // }, []);

    const openGlobalSearch = useCallback(() => {
        console.log('222222222', 'e===========>');
        inputRef.current && inputRef.current.focus();
        setVisible(true);
    }, []);

    const isMac = useMemo(() => {
        let platform = focusSDK.getDeviceInfo().platform;
        if (platform === 'web') {
            let uaParser = new UAParser();
            let os = uaParser.getOS();
            return (
                (os?.name as string).indexOf('Mac') > -1 || (os?.name as string).indexOf('iOS') > -1
            );
        }
        return platform === 'darwin';
    }, [focusSDK]);

    useEffect(() => {
        const keySearch = (e: KeyboardEvent) => {
            // if (focusSDK.getDeviceInfo().platform !== 'web') {
            //     return;
            // }
            let keyFlag = !!e.ctrlKey;
            if (isMac) {
                keyFlag = !!e.metaKey;
            }
            console.log(e, 'e===========>');
            if (keyFlag && e.keyCode === 70) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                openGlobalSearch();
            }
        };
        console.log('keydown=================>');
        window.addEventListener('keydown', keySearch);
        focusSDK.on('openGlobalSearch', openGlobalSearch);
        return () => {
            focusSDK.off('openGlobalSearch', openGlobalSearch);
            window.removeEventListener('keydown', keySearch);
        };
    }, [focusSDK, openGlobalSearch, isMac]);
    const onHideGlobalSearch = useCallback(() => {
        setVisible(false);
    }, []);
    useEffect(() => {
        Bus.on('onHideGlobalSearch', onHideGlobalSearch);
        return () => {
            Bus.off('onHideGlobalSearch', onHideGlobalSearch);
        };
    }, [onHideGlobalSearch]);

    const setOnChange = useCallback((val: string) => {
        setSearchValue(val);
    }, []);

    useEffect(() => {
        if (!isChange) {
            setKeyWord(searchValue);
        }
    }, [isChange, searchValue]);

    const clstag = () => {
        console.log('current_pathname', location.pathname);
        const pathname = location.pathname;
        // if (pathname === '/address_list') {
        //     analysisLog('xtbg_add_home_1637657110163', '40');
        // }
        if (pathname.startsWith('/messages')) {
            analysisLog('Xtbg_Msg_Home', 'Search');
            return;
        }
        switch (pathname) {
            case '/address_list':
                analysisLog('xtbg_add_home_1637657110163', '40');
                break;
            case '/projects':
                analysisLog('xtbg_home_search_1641454991771', '34');
                break;
            case '/calendar':
                analysisLog('xtbg_home_search_1641454991771', '35');
                break;
            case '/joyspace/home/recent':
                analysisLog('xtbg_home_search_1641454991771', '36');
                break;
            case '/third_apps':
                analysisLog('xtbg_home_search_1641454991771', '37');
                break;
        }
    };
    const DOM = useMemo(() => {
        return (
            <div className="global-search-container">
                {/* 搜索 */}
                <div
                    className="global-search-input cursor-outline"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    onClick={(event: any) => {
                        setVisible(true);
                        clstag();
                        inputRef.current && inputRef.current.focus();
                        event.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    <div>
                        <span>{IconSearch}</span>
                        <span>搜索</span>
                    </div>
                    <span>({isMac ? '⌘+F' : 'Ctrl+F'})</span>
                </div>
                <div
                    className={
                        visible
                            ? 'search-global-modal-mask  search-global-translateY-mask'
                            : 'search-global-modal-mask'
                    }
                    onClick={(e) => {
                        setVisible(false);
                    }}
                />
                {/* 弹出框 */}
                <div
                    className={
                        visible
                            ? 'search-global-modal  search-global-translateY'
                            : 'search-global-modal'
                    }
                    onClick={(event: any) => {
                        event.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    {/* input容器 */}
                    <div className="global-search-input_container">
                        {/* input */}
                        <div className="global-search-input input-left">
                            <span>{IconSearch}</span>
                            <Input
                                ref={inputRef}
                                value={searchValue}
                                // defaultValue={keyWord}
                                placeholder="搜索"
                                onChange={(event: any) => {
                                    event.persist();
                                    const val = event.target.value;
                                    setOnChange(val);
                                }}
                                onCompositionStart={(e) => {
                                    setIsChange(true);
                                }}
                                onCompositionEnd={(event: any) => {
                                    setOnChange(event.target.value);
                                    setIsChange(false);
                                }}
                            />
                            {searchValue && !isChange && (
                                <span
                                    onClick={(event: any) => {
                                        setSearchValue('');
                                        inputRef.current && inputRef.current.focus();
                                        event.nativeEvent.stopImmediatePropagation();
                                    }}
                                >
                                    {IconCircleClose}
                                </span>
                            )}
                        </div>
                    </div>
                    <GlobalSearchModel
                        keyWord={keyWord}
                        isChange={isChange}
                        activeKey={activeKey}
                    />
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey, isChange, isMac, keyWord, searchValue, setOnChange, visible]);
    return DOM;
}
