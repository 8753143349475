import React, { useEffect, useState, useCallback } from 'react';
import './TabMultimedia.less';
import { message, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { connect } from 'dvajs';
import { FocusSDK, isFocusEnv } from '@/utils';
import EventBus from '@/utils/bus';
import { openJoinMeetingModal } from '@/utils/modals';

import icon_join from '@/assets/img/meeting/multimedia-join.png';
import icon_presentation from '@/assets/img/meeting/multimedia-presentation.png';
import icon_camera from '@/assets/img/meeting/multimedia-camera.png';
import icon_user from '@/assets/img/meeting/multimedia-user.png';

import MyMeetingListModal from '../Meeting/MyMeetingListModal';

import config, { ConfigEnum } from '@/config/config';
import { ApplyAuthorResourceToConfig } from '@/utils/envConfig';
import { getPermission } from '@/api/user';
import { changeEgovUrl } from '@/utils/tools';
import { AuthorResourceEnum, IPermissions } from '@/types/common';
import { AnyPtrRecord } from 'dns';
import { NetState } from '@/models/netStatus';
import { useHistory } from 'react-router-dom';
import { NetType } from '@/components/Modals/ChangeNetModal';

interface ButtonModel {
    icon: string;
    clstag: string;
    caption: string;
    click: any;
}

function TabMultimedia({
    openCreateScheduleModal,
    newPublicResult,
    newPrivateResult,
}: {
    openCreateScheduleModal: (data: any) => void;
} & NetState) {
    const [now, setNow] = useState<Dayjs>(dayjs());
    const { t } = useTranslation('common');
    const [loadingConfig, setLoadingConfig] = useState(false);
    const [buttons, setButtons] = useState<ButtonModel[]>([]);
    const history = useHistory();

    useEffect(() => {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
    }, []);

    useEffect(() => {
        // 每分钟更新计时
        const time = (60 - now.second()) * 1000; // 计时补正
        const timer = setTimeout(() => {
            setNow(dayjs());
        }, time);

        return () => {
            clearTimeout(timer);
        };
    });

    // 获取权限
    const getUserPermissions = useCallback(async () => {
        const res: any = await getPermission();
        // 依据权限进行config配置
        const config = ApplyAuthorResourceToConfig(res || []);
        return config;
    }, []);

    const getCreateButtons = useCallback(() => {
        const buttons = [];
        const isFocus = isFocusEnv();

        if (isFocus && config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE]) {
            buttons.push({
                icon: icon_presentation,
                clstag: 'pageclick|keycount|xtbg_PCMeeting|Create',
                caption: t('createButton.createMeeting'),
                click() {
                    EventBus.emit('meeting:create-show');
                },
            });
        }
        if (config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN]) {
            buttons.push({
                icon: icon_join,
                clstag: 'pageclick|keycount|xtbg_PCMeeting|Joinin',
                caption: t('createButton.joinMeeting'),
                click() {
                    if (isFocusEnv()) {
                        EventBus.emit('meeting:join-show');
                        return;
                    }
                    openJoinMeetingModal({
                        visible: true,
                        t,
                        async onOk({ meetingNum, meetingPass }) {
                            EventBus.emit('chat_joymeeting_invite', {
                                ext: {
                                    meetingNumber: meetingNum,
                                    meetingPassword: meetingPass,
                                },
                            });
                            return true;
                        },
                    });
                },
            });
        }
        if (isFocus && config[ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT]) {
            buttons.push({
                icon: icon_camera,
                clstag: 'pageclick|keycount|xtbg_PCMeeting|Book',
                caption: t('createButton.appointMeeting'),
                click() {
                    openCreateScheduleModal({ visible: true, defaultAppointment: true });
                },
            });
        }
        buttons.push({
            icon: icon_user,
            clstag: 'pageclick|keycount|xtbg_PCMeeting|Mymeeting',
            caption: t('createButton.myMeetingList'),
            click() {
                EventBus.emit('meeting:my-meeting-list-show');
            },
        });

        setButtons(buttons);
    }, [openCreateScheduleModal, t]);

    useEffect(() => {
        setLoadingConfig(true);
        getUserPermissions()
            .then(() => {
                getCreateButtons();
            })
            .finally(() => {
                setLoadingConfig(false);
            });
    }, [getCreateButtons, getUserPermissions]);

    return (
        <div className="tab-multimedia">
            <div className="moment">
                <p className="moment-time">{now.format('HH:mm')}</p>
                <p className="moment-date">{now.format('YYYY.MM.DD')}</p>
            </div>
            <div className="function-list">
                {loadingConfig ? (
                    <Spin
                        style={{
                            marginTop: 30,
                        }}
                        size="large"
                    />
                ) : (
                    buttons.map((item, index) => {
                        return (
                            <div
                                className="function-item"
                                key={index}
                                clstag={item.clstag}
                                onClick={item.click}
                            >
                                <div
                                    className="function-item-icon"
                                    style={{
                                        backgroundImage: `url(${changeEgovUrl(item.icon)})`,
                                    }}
                                />
                                <p className="function-item-caption">{item.caption}</p>
                            </div>
                        );
                    })
                )}
            </div>

            <MyMeetingListModal />
        </div>
    );
}

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateScheduleModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMultimedia);
