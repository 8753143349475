import { IPageBase, ICategoryBase } from '../../types';
import { IPermissions } from '../../permissions';

export type IPage = IPageBase & { perms?: IPermissions };

export type ICategory = ICategoryBase;
export enum ReadyState {
    'loading',
    'complete',
}

export enum listDataType {
    USER = 1, // 用户
    ORGANIZATION = 2, // 组织
    GROUP = 3, // TimLine Group
    MAILGROUP = 4, // Email Group
    TEAMMEMBER = 5, // 团队成员
    SHARE = 6, // 分享
    PAGE = 7, // page
    FOLDER = 8, // page
    DOCUMENT = 9, // page
    TEAM = 10,
    SPACE = 11,
    CATEGORY = 12,
}
