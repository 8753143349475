/* eslint-disable array-callback-return */
// 通知详情
import React, { useCallback, useEffect, useState, useContext } from 'react';
import './index.less';
import { Drawer, Popover, Tabs, Button, Empty, message as toast, Dropdown } from 'antd';
import { Avatar } from '@/baseComponents/Avatar';
import IconFont from '@/components/icon';
import { ChatMessage } from '@/types/chat';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { EsuperviseSourceType, ICreateSuperviseData } from '@/types/supervise';
import { CalendarIcon } from '@/components/Calendars/Common';
import { connect } from 'dva';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getFileIconByMime, getFileUrl, downLoadFile } from '@/utils/chat/index';
import {
    buildJoyspaceIconUrl,
    buildPagePathKey,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import { replaceGovUrl } from '@/utils/joyspace';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { isFocusEnv, FocusSDK } from '@/utils';
import { formate } from '@/utils/date';
import ChatState from '@/types/chat/State';
import bus from '@/utils/bus';
import { queryNotificationDetail, RespondToNotificationReq } from '@/api/notification';
import noticeImg from '@/assets/img/no-content.png';
import ImService from '@/server/ImService';
import { ChatMessageExtType } from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend/Type';
import { buildGroupMessageBody } from '@/components/chat/utils/message';
import CopyClipboard from 'copy-to-clipboard';
import { changeEgovUrl } from '@/utils/tools';
import History from '@/components/chat/message/GroupNotification/components/history';
import Context from '@/components/chat/context';

const { TabPane } = Tabs;

interface NoticeDetailsMsg {
    message?: ChatMessage;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    openNoticeDetails: (data: { isvisibles: boolean; notificationId: any }) => void;
}

type IProps = Readonly<NoticeDetailsMsg & ChatState>;
// eslint-disable-next-line complexity
function NoticeDetails(props: IProps) {
    const {
        message,
        openCreateSuperviseModal,
        sessionMessageMap,
        currentEmployee,
        allSessionList,
        NoticeDetailsVisible,
        notificationId,
        openNoticeDetails,
        selectedSession,
        type,
    } = props;
    const { groupRosterIdentity } = useContext(Context);
    const [isvisible, setIsvisible] = useState(false);
    const [openHistorys, setopenHistory] = useState(false);
    const [getScheduleInfos, setGetScheduleInfos] = useState<any>();
    const [newUsers, setNewUsers] = useState<any>([]);
    const [readFlag, setReadFlag] = useState(false);
    const [visible, setVisible] = useState(false);
    const [btnstyle, setbtnstyle] = useState({ display: 'block' });
    const { t } = useTranslation('chat');
    const {
        appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
    } = useContext<GlobalContextConfig>(GlobalContext);
    const instance = ImService.getInstance();
    let noticeType: any = type;

    const onClose = () => {
        openNoticeDetails({
            isvisibles: false,
            notificationId: '',
        });
    };

    // 响应回复
    const sendMessages = async () => {
        let msg = sessionMessageMap[getScheduleInfos?.sourceBizId];
        let optionId: any;
        let msgs: any = message;
        getScheduleInfos?.optionList.map((item: any) => {
            optionId = item.optionId;
        });
        msg?.map((item: any) => {
            item?.optionList?.map((item1: any) => {
                if (item1.optionId === optionId) {
                    msgs = item;
                }
            });
        });
        if (message === undefined) {
            const [data, err] = await RespondToNotificationReq({
                notificationId: getScheduleInfos?.notificationId,
                optionId: optionId,
            });
            if (err) {
                toast.error(err);
                return;
            }
            const newDate = new Date().getTime();
            let sessions: any;
            let users = {
                app: currentEmployee.app,
                clientType: 'pc',
                name: currentEmployee.name,
                nickName: currentEmployee.name,
                pin: currentEmployee.userId,
                teamId: currentEmployee.teamId,
                optionId: getScheduleInfos?.optionList[0].optionId,
                timestamp: newDate,
            };
            // eslint-disable-next-line array-callback-return
            allSessionList.filter((item: any) => {
                if (item.sessionId === getScheduleInfos?.sourceBizId) {
                    sessions = item;
                }
            });
            let msgContentItem = {
                users: [users],
            } as any;
            const msgType = ChatMessageExtType.GROUP_NOTICE;
            const datas = buildGroupMessageBody({
                msgType,
                session: sessions,
                mid: Number(getScheduleInfos?.mid),
                msgContentItem,
            });
            await instance.sendChatMessageExt(getScheduleInfos?.sourceBizId, datas as any);
            onClose();
        } else {
            bus.emit('chat:message-groupnotificationcard', msgs);
        }
        setReadFlag(true);
    };

    // 查询详情
    const GroupNotification = useCallback(async () => {
        // notificationId = _notificationId;
        const [data, err] = await queryNotificationDetail({ notificationId: notificationId });
        if (err) {
            openNoticeDetails({
                isvisibles: false,
                notificationId: '',
            });
            toast.error(err);
            return;
        }
        if (data) {
            setGetScheduleInfos(data);
            if (data.unReadMemberList === null) return;
            if (data.readMemberList.length === 0) {
                setNewUsers(data.unReadMemberList);
                return;
            }
            setNewUsers(data.readMemberList[0].memberList?.concat(...data.unReadMemberList));
            return;
        }
    }, [notificationId, openNoticeDetails]);

    useEffect(() => {
        if (NoticeDetailsVisible === false) {
            // notificationId = '';
            setGetScheduleInfos(undefined);
            setNewUsers([]);
            setReadFlag(false);
            return;
        }
        GroupNotification();
    }, [GroupNotification, NoticeDetailsVisible, notificationId]);

    function visibleChange(visible: boolean) {
        setIsvisible(visible);
    }

    // 打开催办
    function handleOpenRecord(advanceUser: any, value: any) {
        const userList: any[] = [];
        if (advanceUser.length === undefined) {
            // eslint-disable-next-line array-callback-return
            // advanceUser.map((item: any) => {
            // console.log('advanceUser', item);
            userList.push({
                userId: advanceUser?.userId,
                teamId: advanceUser?.teamId,
                appId: advanceUser?.appId,
                headImg: advanceUser?.headImg,
                realName: advanceUser?.realName,
            });
            // });
        } else {
            advanceUser.map((item: any) => {
                userList.push({
                    userId: item?.userId,
                    teamId: item?.teamId,
                    appId: item?.appId,
                    headImg: item?.headImg,
                    realName: item?.realName,
                });
            });
        }
        console.log('打开催办', userList);
        openCreateSuperviseModal({
            visible: true,
            createData: {
                id: notificationId,
                title: value,
                userList,
                from: EsuperviseSourceType.NOTIFICATION,
            },
        });
        setIsvisible(false);
        onClose();
    }

    // 文件icon
    const getAttachmentIcon = (item: any) => {
        const { fileType, fileName } = item;
        if (fileType.startsWith('joyspace')) {
            try {
                const strs = fileType.split('/');
                if (strs[1] === '6') {
                    return getFileIconByMime(
                        strs.slice(2).join('/') || 'application/octet-stream'
                    )[0];
                } else {
                    return buildJoyspaceIconUrl({
                        title: fileName,
                        pageType: strs[1],
                        type: strs.slice(2).join('/') || '',
                        apiHost: `${joyspaceApiHost}-api`,
                    });
                }
            } catch (error) {
                return getFileIconByMime(fileType || 'application/octet-stream')[0];
            }
        }
        return getFileIconByMime(fileType || 'application/octet-stream')[0];
    };

    // 文件下载
    function downloadFile(item: any) {
        // if (item.status === FileStatus.Uploading) {
        //     return;
        // }
        if (item.fileType.startsWith('joyspace')) {
            const path = buildPagePathKey(Number(item.fileType.split('/')[1])).subPath;
            let newUrl = `${joyspaceHost}/${path}/${item.fileId}`;
            let realUrl = replaceGovUrl(newUrl);
            return DeeplinkEvent.open(realUrl);
        }
        if (isFocusEnv()) {
            FocusSDK.downloadFile(item.fileUrl, {
                saveAs: true,
                filename: item.fileName,
                onStarted: () => {},
                onProgress: () => {},
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                },
                onFinish: (file: { path: string; filename: string }) => {},
            });
        } else {
            // web 端下载文件
            const name = item.fileName;
            const url = getFileUrl(item.fileUrl);
            downLoadFile(name, url);
        }
    }

    // function openUserCard(item: any) {
    //     // 如果群成员并且禁止了查看群成员名片
    //     console.log('打开用户信息', selectedSession);
    //     if (
    //         !isEqualEmployee(currentEmployee, message?.from as any) &&
    //         isGroupOrdinary(groupRosterIdentity) &&
    //         !isCardViewGroupSession(selectedSession)
    //     ) {
    //         toast.warn(t('can_not_open_user_card_tip'));
    //         return false;
    //     }
    //     const { userId, teamId, appId } = item;
    //     bus.emit('app:toggle-user-card-modal:show', {
    //         visible: true,
    //         type: UserCardType.User,
    //         userId,
    //         appId: appId,
    //         teamId,
    //     });
    // }

    const onVisibleChange = (flag: boolean) => {
        setVisible(flag);
    };

    const doCopy = (e: any) => {
        e.stopPropagation();
        if (getScheduleInfos?.content) {
            let userSelection;
            let str = getScheduleInfos.content;
            if (typeof window.getSelection === 'function') {
                userSelection = window.getSelection();
            }
            if (userSelection?.toString()) {
                str = userSelection.toString();
            }
            if ((str || '').length > (getScheduleInfos.content || '').length) {
                str = getScheduleInfos.content;
            }
            if (str && CopyClipboard(str)) {
                toast.success('复制成功');
            } else {
                toast.error('复制失败');
            }
        }
        setVisible(false);
    };

    const onTabchange = (activeKey: any) => {
        if (activeKey === '1') {
            setbtnstyle({ display: 'block' });
        } else {
            setbtnstyle({ display: 'none' });
        }
    };

    const openHistory = () => {
        setopenHistory(true);
        onClose();
    };

    const Demo = (
        <div onClick={(e) => e.stopPropagation()} style={{ height: '100%' }}>
            {getScheduleInfos && (
                <Tabs onChange={(activeKey) => onTabchange(activeKey)} tabBarGutter={70}>
                    <TabPane tab={`未回复 ${getScheduleInfos.unansweredNum}`} key="1">
                        {getScheduleInfos?.unReadMemberList === null ||
                        getScheduleInfos?.unReadMemberList.length === 0 ? (
                            <div className="Empty_item">
                                <Empty
                                    image={noticeImg}
                                    imageStyle={{
                                        height: 100,
                                    }}
                                    description={<span style={{ color: '#62656D' }}>暂无数据</span>}
                                />
                            </div>
                        ) : (
                            <div>
                                {getScheduleInfos?.ownerUserId === currentEmployee.userId ? (
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <>
                                        {getScheduleInfos?.unReadMemberList.map(
                                            (item: any, index: any) => {
                                                if (item === undefined) return null;
                                                let dept = '';
                                                let proTitle = '';
                                                if (item.labelInfoList?.[1]) {
                                                    dept = item.labelInfoList[1].name;
                                                }
                                                if (item.labelInfoList?.[2]) {
                                                    proTitle = item.labelInfoList[2].name;
                                                }
                                                return (
                                                    <div key={index}>
                                                        <div
                                                            className="NoticeDetails_user"
                                                            key={index}
                                                            // onClick={(e) => {
                                                            //     e.stopPropagation();
                                                            //     openUserCard(item);
                                                            // }}
                                                        >
                                                            <div className="NoticeDetails_left">
                                                                <Avatar
                                                                    className="imgAvatar"
                                                                    name={item.realName || ''}
                                                                    src={changeEgovUrl(
                                                                        item.headImg
                                                                    )}
                                                                    // onClick={() => openUserCard(item)}
                                                                />
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        marginLeft: '6px',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: '#232930',
                                                                            width: '160px',
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >
                                                                        {item.realName}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: '#6E6F71',
                                                                            fontSize: '12px',
                                                                            width: '160px',
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >{`${dept}-${proTitle}`}</span>
                                                                </div>
                                                            </div>
                                                            {getScheduleInfos?.ownerUserId ===
                                                                currentEmployee.userId && (
                                                                <div
                                                                    className="NoticeDetails_right"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        handleOpenRecord(
                                                                            item,
                                                                            getScheduleInfos?.content
                                                                        );
                                                                    }}
                                                                >
                                                                    催办
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <>
                                        {getScheduleInfos?.unReadMemberList.map(
                                            (item: any, index: any) => {
                                                if (item === undefined) return null;
                                                let dept = '';
                                                let proTitle = '';
                                                if (item.labelInfoList?.[1]) {
                                                    dept = item.labelInfoList[1].name;
                                                }
                                                if (item.labelInfoList?.[2]) {
                                                    proTitle = item.labelInfoList[2].name;
                                                }
                                                return (
                                                    <div key={index}>
                                                        <div
                                                            className="NoticeDetails_user NoticeDetails_user1"
                                                            key={index}
                                                            // onClick={(e) => {
                                                            //     e.stopPropagation();
                                                            //     openUserCard(item);
                                                            // }}
                                                        >
                                                            <div className="NoticeDetails_left">
                                                                <Avatar
                                                                    className="imgAvatar"
                                                                    name={item.realName || ''}
                                                                    src={changeEgovUrl(
                                                                        item.headImg
                                                                    )}
                                                                    // onClick={() => openUserCard(item)}
                                                                />
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        marginLeft: '6px',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: '#232930',
                                                                            width: '160px',
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >
                                                                        {item.realName}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            color: '#6E6F71',
                                                                            fontSize: '12px',
                                                                            width: '160px',
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >{`${dept}-${proTitle}`}</span>
                                                                </div>
                                                            </div>
                                                            {/* {getScheduleInfos?.ownerUserId ===
                                                                currentEmployee.userId && (
                                                                <div
                                                                    className="NoticeDetails_right"
                                                                    onClick={() => {
                                                                        handleOpenRecord(
                                                                            item,
                                                                            getScheduleInfos?.content
                                                                        );
                                                                    }}
                                                                >
                                                                    催办
                                                                </div>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </>
                                )}

                                {getScheduleInfos?.ownerUserId === currentEmployee.userId && (
                                    <div className="NoticeDetails_button" style={btnstyle}>
                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            onClick={debounce(() => {
                                                handleOpenRecord(
                                                    getScheduleInfos.unReadMemberList,
                                                    getScheduleInfos?.content
                                                );
                                            }, 500)}
                                        >
                                            全部催办
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </TabPane>
                    <TabPane tab={`已回复 ${getScheduleInfos.repliedNum}`} key="2">
                        {getScheduleInfos.readMemberList.length === 0 ? (
                            <div className="Empty_item">
                                <Empty
                                    image={noticeImg}
                                    imageStyle={{
                                        height: 100,
                                    }}
                                    description={<span style={{ color: '#62656D' }}>暂无数据</span>}
                                />
                            </div>
                        ) : (
                            getScheduleInfos.readMemberList[0]?.memberList?.map(
                                (item: any, index: any) => {
                                    if (item === undefined) return null;
                                    let dept = '';
                                    let proTitle = '';
                                    if (item.labelInfoList?.[1]) {
                                        dept = item.labelInfoList[1].name;
                                    }
                                    if (item.labelInfoList?.[2]) {
                                        proTitle = item.labelInfoList[2].name;
                                    }
                                    return (
                                        <div className="NoticeDetails_user" key={index}>
                                            <div className="NoticeDetails_left">
                                                <Avatar
                                                    className="imgAvatar"
                                                    name={item.realName || ''}
                                                    src={changeEgovUrl(item.headImg)}
                                                    // onClick={() => openUserCard(item)}
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginLeft: '6px',
                                                    }}
                                                >
                                                    <span
                                                        className="ellipsis"
                                                        style={{
                                                            color: '#232930',
                                                            width: '160px',
                                                        }}
                                                    >
                                                        {item.realName}
                                                    </span>
                                                    <span
                                                        className="ellipsis"
                                                        style={{
                                                            color: '#6E6F71',
                                                            fontSize: '12px',
                                                            width: '160px',
                                                        }}
                                                    >{`${dept}-${proTitle}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )
                        )}
                    </TabPane>
                </Tabs>
            )}
        </div>
    );

    const footer = (
        <Button
            block
            type="primary"
            htmlType="submit"
            disabled={getScheduleInfos?.readFlag === 0 && readFlag === false ? false : true}
            onClick={debounce(() => {
                sendMessages();
            }, 500)}
        >
            {getScheduleInfos?.readFlag === 0 && readFlag === false ? '收到' : '已回复'}
        </Button>
    );
    // if (getScheduleInfos === undefined) return null;
    return (
        <>
            <Drawer
                className="NoticeDetails"
                width={445}
                title="通知详情"
                placement="right"
                onClose={() => {
                    onClose();
                }}
                visible={NoticeDetailsVisible}
                style={{ position: 'absolute', right: 0, zIndex: 1029 }}
                footer={
                    getScheduleInfos !== undefined &&
                    currentEmployee.userId !== getScheduleInfos?.ownerUserId &&
                    footer
                }
            >
                {getScheduleInfos === undefined ? (
                    <div />
                ) : (
                    <div className="NoticeDetails_box">
                        <div className="NoticeDetails_title">
                            <Avatar
                                className="imgAvatar"
                                name={getScheduleInfos?.realName || ''}
                                src={changeEgovUrl(getScheduleInfos?.headImg)}
                            />
                            <div className="NoticeDetails_name">
                                <span className="NoticeDetails_name_item">
                                    {getScheduleInfos?.realName}
                                </span>
                                <span>发布于 {formate(getScheduleInfos?.gmtCreate)}</span>
                            </div>
                        </div>
                        <div className="NoticeDetails_user">
                            <span style={{ whiteSpace: 'nowrap', color: '#232930' }}>接收人：</span>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setIsvisible(true);
                                }}
                            >
                                <div className="NoticeDetails_user1" id="NoticeDetails_user1">
                                    {/* {newUsers?.map((item: any, index: any) => {
                                    return (
                                        <span key={index}>
                                            {item.realName}
                                            {newUsers?.length === 1 ||
                                            newUsers[newUsers?.length - 1] === item
                                                ? ''
                                                : '、'}
                                        </span>
                                    );
                                })} */}
                                    <span>共{newUsers?.length}人</span>
                                    <span>
                                        （已回复{getScheduleInfos.repliedNum}
                                        人， 未回复{getScheduleInfos.unansweredNum}人）
                                    </span>
                                </div>
                                <Popover
                                    overlayClassName={
                                        btnstyle.display === 'block' &&
                                        getScheduleInfos?.ownerUserId === currentEmployee.userId
                                            ? 'NoticeDetails_Popover'
                                            : 'NoticeDetails_Popover1'
                                    }
                                    trigger="click"
                                    placement="bottom"
                                    content={Demo}
                                    onVisibleChange={visibleChange}
                                    visible={isvisible}
                                >
                                    <div className="NoticeDetails_user2">
                                        {/* <span>{getScheduleInfos.repliedNum} 人回复</span> */}
                                        <IconFont
                                            className="NoticeDetails_icon"
                                            type="iconic_profile_next"
                                            clstag="pageclick|keycount|focus_chat_01_1602584371145|47"
                                        />
                                    </div>
                                </Popover>
                            </div>
                        </div>
                        {isFocusEnv() ? (
                            <Dropdown
                                overlay={() => {
                                    return (
                                        <div
                                            className="paste-space"
                                            onClick={(e) => {
                                                doCopy(e);
                                            }}
                                        >
                                            复制
                                        </div>
                                    );
                                }}
                                trigger={['contextMenu']}
                                visible={visible}
                                onVisibleChange={onVisibleChange}
                            >
                                <div className="NoticeDetails_con" id="NoticeDetails_con">
                                    <p style={{ whiteSpace: 'pre-wrap' }}>
                                        {getScheduleInfos.content}
                                    </p>
                                </div>
                            </Dropdown>
                        ) : (
                            <div className="NoticeDetails_con" id="NoticeDetails_con">
                                <p style={{ whiteSpace: 'pre-wrap' }}>{getScheduleInfos.content}</p>
                            </div>
                        )}

                        {getScheduleInfos.attachmentList?.map((item: any, index: any) => {
                            return (
                                <div
                                    key={index}
                                    className="NoticeDetails_list"
                                    onClick={() => {
                                        downloadFile(item);
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ display: 'flex' }}>
                                            <CalendarIcon
                                                src={getAttachmentIcon(item)}
                                                className="left"
                                                width={12}
                                                height={12}
                                            />
                                        </span>
                                        <span className="ellipsis" style={{ marginLeft: '8px' }}>
                                            {item.fileName}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                        {noticeType !== 'history' && (
                            <div
                                className="GroupNotification_lishi"
                                style={{
                                    bottom:
                                        currentEmployee.userId === getScheduleInfos?.ownerUserId
                                            ? '18px'
                                            : '70px',
                                }}
                                onClick={openHistory}
                            >
                                <p>{t('group_history')}</p>
                            </div>
                        )}
                    </div>
                )}
            </Drawer>
            {openHistorys === true && (
                <History
                    visible={openHistorys}
                    onClose={() => {
                        setopenHistory(false);
                    }}
                    sessionId={getScheduleInfos?.sourceBizId}
                />
            )}
        </>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    const {
        sessionMessageMap,
        currentEmployee,
        selectedSession,
        allSessionList,
        NoticeDetailsVisible,
        notificationId,
        type,
    } = chat;
    return {
        sessionMessageMap,
        currentEmployee,
        selectedSession,
        allSessionList,
        NoticeDetailsVisible,
        notificationId,
        type,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        openNoticeDetails(data: { isvisibles: boolean; notificationId: any }) {
            dispatch({ type: 'chat/openNoticeDetails', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticeDetails);
