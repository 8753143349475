import React from 'react';
import { message, Checkbox } from 'antd';
import Context from '../../../context';
import Title from '../Title';
import Role from '../Role';
import './index.less';
import { ProjectMember } from '../index';
import * as api from '../../../api';
import { ProjectRole, Project } from '../../../types';
import { isSameUser } from '../../../util';
import { Avatar } from '@/baseComponents/Avatar';
import { changeEgovUrl } from '@/utils/tools';
interface Props {
    project: Project;
    source: ProjectMember[];
    setSelecteds: (items: ProjectMember[]) => void;
    onDelete: (item: ProjectMember) => void;
}

export default function (props: Props) {
    const { source, project, setSelecteds, onDelete } = props;
    const [members, setMembers] = React.useState<ProjectMember[]>([]);
    const { events, t } = React.useContext(Context);
    const [user, setUser] = React.useState(project.user);
    const isMember = user?.role !== ProjectRole.ADMIN && user?.role !== ProjectRole.OWNER;
    React.useEffect(() => {
        setMembers(source);
    }, [source, project]);
    React.useEffect(() => {
        setUser(project.user);
    }, [project]);

    React.useEffect(() => {
        setSelecteds(members.filter((m) => m.selected));
    }, [members, setSelecteds]);

    const selectAll = members.every((m) => m.selected);
    const enableSelect = members.some((m) => m.selected);

    function Console(m: ProjectMember) {
        // 本人
        if (isSameUser(user, m) || project.archive) {
            return null;
        }
        const els = [];
        const role = m.role ?? 0;
        if (user?.role === ProjectRole.OWNER) {
            els.push(
                <span
                    key="owner"
                    className="pm-mm-list-set-owner"
                    onClick={() => {
                        api.updateUser(project.id, {
                            ...m,
                            userRole: ProjectRole.OWNER,
                        }).then((data) => {
                            if (data.errCode) return;
                            message.success(t('operate_success'));
                            setMembers(
                                members.map((o) => ({
                                    ...o,
                                    role: isSameUser(o, user)
                                        ? ProjectRole.ADMIN
                                        : isSameUser(o, m)
                                        ? ProjectRole.OWNER
                                        : o.role,
                                }))
                            );
                            setUser({
                                ...user,
                                role: ProjectRole.ADMIN,
                            });
                            events.emit('projectReplaceOwner', {
                                projectId: project.id,
                            });
                        });
                    }}
                >
                    {t('member.owner_replace')}
                </span>
            );
            els.push(
                <span
                    key="manage"
                    className="pm-mm-list-set-manage"
                    onClick={() => {
                        const userRole =
                            role !== ProjectRole.ADMIN ? ProjectRole.ADMIN : ProjectRole.MEMBER;
                        api.updateUser(project.id, {
                            ...m,
                            userRole,
                        }).then((data) => {
                            if (data.errCode) return;
                            message.success(t('operate_success'));
                            m.role = userRole;
                            setMembers([...members]);
                        });
                    }}
                >
                    {role !== ProjectRole.ADMIN
                        ? t('member.set-administrator')
                        : t('member.cancel-administrator')}
                </span>
            );
        }
        if (
            user?.role === ProjectRole.OWNER ||
            (user?.role === ProjectRole.ADMIN &&
                role !== ProjectRole.OWNER &&
                role !== ProjectRole.ADMIN)
        ) {
            els.push(
                <span
                    key="delete"
                    className="pm-mm-list-set-delete"
                    onClick={() => {
                        api.deleteProjectsMember(project.id, [m]).then((data) => {
                            if (data.errCode) return;
                            const _members = members.filter((o) => o !== m);
                            setMembers(_members);
                            onDelete(m);
                            message.success(t('operate_success'));
                        });
                    }}
                >
                    {t('remove')}
                </span>
            );
        }
        return els;
    }

    return (
        <div className={'pm-mm-list ' + (enableSelect ? 'pm-mm-list-enableSelect' : '')}>
            <div className="pm-mm-list-header">
                <div className="pm-mm-list-line">
                    <div style={{ display: isMember || project.archive ? 'none' : '' }}>
                        <Checkbox
                            checked={selectAll}
                            onChange={() => {
                                const flag = !selectAll;
                                members?.forEach((m) => {
                                    m.selected = flag;
                                });
                                setMembers([...members]);
                            }}
                        />
                    </div>
                    <div>{t('member.name')}</div>
                    <div>{t('member.duty')}</div>
                    {!project.archive && <div>{t('member.operate')}</div>}
                </div>
            </div>
            <div className="pm-mm-list-body">
                {members?.map((m) => (
                    <div key={m.userId + m.app + m.teamId} className="pm-mm-list-line">
                        <div style={{ display: isMember || project.archive ? 'none' : '' }}>
                            <Checkbox
                                checked={m.selected}
                                onChange={() => {
                                    m.selected = !m.selected;
                                    setMembers([...members]);
                                }}
                            />
                        </div>
                        <div>
                            {/* <span
                                className="pm-mm-list-avatar"
                                style={m.avatar ? { backgroundImage: `url(${m.avatar})` } : {}}
                            >
                                {m.avatar ? '' : m.name?.[0]}
                            </span> */}
                            <span className="pm-mm-list-avatar">
                                {/* {m.avatar} */}
                                <Avatar
                                    src={changeEgovUrl(m.avatar)}
                                    name={m.name || ''}
                                    styleObj={{
                                        width: '100%',
                                        height: '100%',
                                        marginTop: m.avatar ? -5 : 0,
                                        // borderRadius: '6px',
                                        // marginRight: '10px',
                                        // marginTop: '-2px',
                                        // fontSize: '16px',
                                    }}
                                />
                            </span>

                            <span className="pm-mm-list-name">{m.name}</span>
                            <Role role={m.role || 0} />
                        </div>
                        <Title member={m} project={project} value={m.duty as string} />
                        <div>{Console(m)}</div>
                    </div>
                ))}
                {members.length === 0 && (
                    <div className="pm-mm-list-noResult">{t('member.no_result')}</div>
                )}
            </div>
        </div>
    );
}
