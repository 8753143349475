import React from 'react';

import IconFont from '@/components/icon';
import { IFilterItem } from '@/components/Projects/emnu';
import { changeEgovUrl } from '@/utils/tools';

import './index.less';

interface CustomCellProps extends React.HTMLAttributes<HTMLElement> {
    dataIndex: string;
    title: any;
    index: number;
    children: React.ReactNode;
    customTdClass: string;
}

// 自定义表格列
export const TableCell: React.FC<CustomCellProps> = ({
    dataIndex,
    title,
    index,
    customTdClass,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            <div className={customTdClass}>{children}</div>
        </td>
    );
};

// 列表原型图标处理
export const CircleShapeIcon = (props: any) => {
    const { content, WithIcon, style } = props;
    return (
        <div
            style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: (window as any).styleSass.primaryColor,
            }}
        >
            <span style={{ marginRight: 6, marginTop: '-5px' }}>{WithIcon || ''}</span>
            <span>{content}</span>
        </div>
    );
};

// 是否为反选
let isReverse = false;
export const getColumnSearchProps = (
    dataIndex: any,
    filterList: IFilterItem[],
    handleSearch: Function,
    filterMultiple = false,
    stateStatus: any
    // eslint-disable-next-line max-params
) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
        // isReverse是否为反选
        if (!isReverse) {
            if (stateStatus.length && selectedKeys.length <= 0) {
                setTimeout(() => {
                    let localStatus = filterList.filter((item: any) =>
                        stateStatus?.includes(item.value)
                    );
                    setSelectedKeys(localStatus);
                }, 100);
            }
        }
        return (
            <div className="filter-dropdown__box">
                <ul className="ant-dropdown-menu">
                    {filterList.map((item) => (
                        <li
                            className={`ant-dropdown-menu-item ${
                                selectedKeys.find((key: IFilterItem) => key.value === item.value)
                                    ? 'ant-dropdown-menu-item-selected'
                                    : ''
                            }`}
                            key={item.value}
                            onClick={() => {
                                const index = selectedKeys.findIndex(
                                    (k: IFilterItem) => k.value === item.value
                                );
                                // 反选逻辑, 如果已经选了在点击就取消选择
                                if (index >= 0) {
                                    isReverse = true;
                                    selectedKeys.splice(index, 1);
                                    handleSearch(selectedKeys.slice());
                                    setTimeout(() => {
                                        if (stateStatus.length <= 0) {
                                            clearFilters();
                                        } else if (selectedKeys.length <= 0) {
                                            // eslint-disable-next-line no-param-reassign
                                            stateStatus = [];
                                        }
                                        setSelectedKeys(selectedKeys);
                                        isReverse = false;
                                    }, 10);
                                } else {
                                    const selectedList = filterMultiple
                                        ? [item, ...selectedKeys]
                                        : [item];
                                    setSelectedKeys(selectedList);
                                    handleSearch(selectedList);
                                }
                                !filterMultiple && confirm();
                            }}
                        >
                            {(() => {
                                if (item.url) {
                                    const isSelected = selectedKeys.find(
                                        (key: IFilterItem) => key.value === item.value
                                    );
                                    return (
                                        <img
                                            src={changeEgovUrl(item.url)}
                                            className={`${isSelected ? '' : 'dark-bg'}`}
                                        />
                                    );
                                }
                                // 既有图片 又有文字过滤的先默认这个样式.之后有需求在改
                                return (
                                    <span className={`${item.mix ? 'filter-mix__text' : ''}`}>
                                        {item.text}
                                    </span>
                                );
                            })()}
                            {selectedKeys.find((key: IFilterItem) => key.value === item.value) ? (
                                <IconFont type="iconapp_ic_finish" />
                            ) : (
                                ''
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        );
    },
});
