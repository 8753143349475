import React, { useState, useRef, useCallback, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, message, Tooltip, Select } from 'antd';
import Search from '../../components/Search';
import Tab from '../../components/Tab';
import { TabKeyType, SelectType, tab, tabAllList } from '../../components/Tab/config';
import {
    folderTypeEnum,
    getJoySpaceReadOnlyPermission,
    joyspacePermissionType,
} from '../../config/permissions.config';
import { addMembers, AddMembersParamesType, getShareCount } from '../../api/permissions';
import './index.less';
import openEditUserPermissionModal from '../EditUserPermission';
import events from '../../context/events';
import { FileType, OpenFolderStatus } from '../../types';
import { openUserSelectionModal } from '@/utils/modals';
import config, { ConfigEnum } from '@/config/config';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getUserInfoApi } from '../../api/pages';
import PermissionSelect from '../../components/PermissionSelect';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { getSharedRecommendList } from '../../api/page';
import IconFont from '@/components/icon';
import addMembersModal from '../AddMembers/index';

const { Option } = Select;
interface IProps {
    id: string;
    activeKey?: TabKeyType;
    isModalVisible: boolean;
    type: FileType;
    title: ReactNode;
    permissionType: joyspacePermissionType;
    modalType?: string;
    folderType?: folderTypeEnum;
    isOpenFolder?: OpenFolderStatus;
    isShowManageBtn?: Boolean;
    // selectList?: (SelectType | any)[];
    onOk?: (list: any) => void;
}

const parames: AddMembersParamesType = {
    id: '',
    type: FileType.Page,
    toGroups: [],
    toOrgs: [],
    toUsers: [],
    toEpssUsers: [],
};

async function openAddUserModal(props: IProps) {
    const { permissionType, type, id, folderType, onOk, isShowManageBtn = true } = props;
    // console.log(isShowManageBtn, 'isShowManageBtnisShowManageBtnisShowManageBtn');
    const user = await getUserInfoApi();
    let ddAppId = 'gwork';
    const userData: any = {
        ddAppId,
        userId: user.user_id,
        team: {
            ddAppId,
            teamId: user.tenant_id,
        },
        teamUserInfo: {
            ddAppId,
            teamId: user.tenant_id,
        },
    };
    let setChangeItem: any;
    let setChangeDataAll: any;
    let reomveDataAll: any;
    let permissionAllVal: any;
    const editPermissionAll = (val: any) => {
        // if (val === joyspacePermissionType.ReadDel) {
        //     return;
        // }
        permissionAllVal = val;
        setChangeDataAll?.({ permission: val });
    };
    const openAddMembersModal = () => {
        addMembersModal({
            id,
            type,
            folderType,
            permissionType: permissionType,
            title: permissionType < getJoySpaceReadOnlyPermission() ? '管理协作者' : '查看协作者',
            // isVisibleModal: true,
            // isOpenFolder: isOpenFolder,
        });
    };
    // 获取协同人数量
    let count = 0;
    const getPageCount = async () => {
        count = (await getShareCount(id)) || 0;
        // console.log(count, 'countcountcountcountcountcountcountcountcountcountcountcountcount');
    };
    getPageCount();
    events.on('joyspace:updata_number', getPageCount);
    const onFinish = async (data: any = [], close = () => {}) => {
        parames.id = id;
        parames.type = type;
        parames.toEpssUsers = [];
        parames.toUsers = [];
        parames.toOrgs = [];
        parames.toGroups = [];
        for (let item of data) {
            if (item.isDept) {
                parames.toOrgs.push({
                    code: item.id,
                    permission_type: item.permission || getJoySpaceReadOnlyPermission(),
                });
            } else if (item.isGroup) {
                parames.toGroups.push({
                    gid: item.id,
                    permission_type: item.permission || getJoySpaceReadOnlyPermission(),
                });
            } else {
                parames.toEpssUsers.push({
                    // id: item.id,
                    permission_type:
                        item.permission === undefined
                            ? getJoySpaceReadOnlyPermission()
                            : item.permission,
                    userId: item.userid || item.origin.info.userId,
                    teamId: item.teamid || item.origin.info.teamId,
                });
            }
        }
        addMembers(parames)
            .then((res) => {
                if (res === undefined) {
                    message.error('您暂无权限进行此操作');
                    return;
                }
                // setLoading(false);
                onOk?.(data);
                if (type === FileType.Folder) {
                    events.emit('list.reload');
                    // events.emit('freshLeftTreeByFolderById', '');
                    events.emit('freshLeftTreeByFolderById', 'root');
                    setTimeout(() => {
                        events.emit('freshLeftTreeByFolderById', 'teamRoot');
                    }, 700);
                }
                message.success(
                    folderType === folderTypeEnum.MY && type === FileType.Folder
                        ? '操作成功，该文件夹将移动到【共享文件】'
                        : '操作成功'
                );
                // events.emit('changeCount', getPageCount);
                // getPageCount();
                events.emit('joyspace:updata');
                close();
            })
            .catch((err) => {
                message.error('操作失败');
                onOk?.(data);
            });
    };
    const result = await getSharedRecommendList(id, 0, 99);
    // console.log(result, 'resultresultresultresultresultresultresult');
    let recentlyContactList: ListItemData[] = result.map((item: any, index: number) => {
        // console.log(
        //     item.type,
        //     'item.typeitem.typeitem.typeitem.typeitem.type',
        //     item,
        //     '===========>',
        //     index
        // );
        switch (item.type) {
            case 'user':
                return {
                    id: `${item.user_id}:${ddAppId}:${item.tenantId}`,
                    name: item.name,
                    desc: `${item.org_name}-${
                        item.position_name ? item.position_name : '暂无填写'
                    }`,
                    avatar: item.avatar_url,
                    userid: item.user_id,
                    teamid: item.tenantId,
                    app: ddAppId,
                    labelInfoList: item.labelInfoList,
                };
            case 'org': {
                let ids = item.id.split(':');
                // ids.splice(1, 0, ddAppId);
                let fullPath = item.full_path.split('/');
                let parentId = fullPath[fullPath.length - 2] || '0';
                return {
                    id: item.code,
                    name: item.name,
                    avatar: (
                        <div className="focus-user-selection-dept-avatar">
                            <IconFont type="iconapp_btn_cha" />
                        </div>
                    ),
                    desc: item.full_name,
                    origin: { contactNumber: 0 },
                    contactNumber: 0,
                    deptId: item.code,
                    deptName: item.name,
                    fullName: item.full_name,
                    fullPath: item.full_path,
                    parentId,
                    teamId: ids[0],
                    permission: 2,
                    isDept: true,
                };
            }
            case 'group':
                return {
                    id: item.gid,
                    name: item.name,
                    desc: `${item.memberCount || 0}人`,
                    avatar: item.avatar,
                    isGroup: true,
                };
            default:
                return item;
        }
    });
    const userModal = openUserSelectionModal(
        {
            title: (
                <div>
                    分享给
                    <Tooltip
                        placement="top"
                        title={
                            <div>
                                <h4 style={{ color: '#ffffff', fontWeight: 'bold' }}>分享说明</h4>
                                如果被分享人的已有权限高于本次分享设置的权限，则该用户的权限不会被改变。如需变更用户的权限，请在“协作者管理”中设置。
                            </div>
                        }
                        className="joyspace-operate-tip"
                    >
                        <QuestionCircleOutlined className="share-tip" />
                    </Tooltip>
                </div>
            ),
            tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                ? ['custom', 'org', 'groupCanBeSel', 'externalContacts']
                : ['custom', 'groupCanBeSel', 'externalContacts'],
            customTab: {
                tabName: '最近分享',
                dataList: [...recentlyContactList],
            },
            contactsFilterFlag: 'external',
            currentUser: {
                app: 'gwork',
                pin: user.user_id,
                teamId: user.tenant_id,
            },
            canSelectDept: true,
            rightHeaderExtra: (data: any) => {
                return (
                    <PermissionSelect
                        onChange={editPermissionAll}
                        type={type}
                        permissionType={permissionType}
                        isCheckAll
                        propsValue={permissionAllVal}
                        isDisableAll={data.some((item: any) => item.isGroup || item.isDept)}
                    />
                );
            },
            rightExtra: {
                // eslint-disable-next-line max-params
                render: (item: any, onChangeItem: any, onChangeDataAll: any) => {
                    setChangeItem = onChangeItem;
                    setChangeDataAll = onChangeDataAll;
                    // reomveDataAll = removeAll;
                    return (
                        <PermissionSelect
                            key={item.id}
                            onChange={(values: any) => {
                                setChangeItem?.(item, { permission: values });
                            }}
                            type={type}
                            permissionType={permissionType}
                            propsValue={
                                item.permission === undefined
                                    ? getJoySpaceReadOnlyPermission()
                                    : item.permission
                            }
                            isDisableAll={item.isGroup || item.isDept}
                        />
                    );
                },
            },
            footerExtra: (
                <div className="joyspace-user-footer-left-modal">
                    <span>
                        已有
                        <span className="joyspace-user-footer-count">{count}</span>
                        协作者
                    </span>
                    {isShowManageBtn && (
                        <span onClick={openAddMembersModal}>
                            {permissionType < getJoySpaceReadOnlyPermission()
                                ? '管理协作者'
                                : '查看协作者'}
                        </span>
                    )}
                </div>
            ),
        },
        (res, close) => {
            console.log(res, 7777);
            onFinish((res as any).data.result, close);
            close();
            events.off('joyspace:updata_number', getPageCount);
        },
        userData
    );
    return userModal;
}

// function mapStateToProps({ user }: { user: UserState }) {
//     return {
//         userData: user.userData.user,
//     };
// }

// function mapDispatchToProps(dispatch: any) {
//     return {};
// }

export default openAddUserModal;
