// 筛选

export enum Order {
    ASC = 'ASC', // 升序
    DESC = 'DESC', // 降序
}

export enum Sort {
    UpDateTime = 'update_time',
    CreateTime = 'create_time',
    Title = 'title',
}

export const timeFilter = () => [
    { text: '更新时间降序', sort: Sort.UpDateTime, order: Order.DESC },
    { text: '更新时间升序', sort: Sort.UpDateTime, order: Order.ASC },
    { text: '创建时间降序', sort: Sort.CreateTime, order: Order.DESC },
    { text: '创建时间升序', sort: Sort.CreateTime, order: Order.ASC },
];

export const titleFilter = () => [
    { text: '标题降序', sort: Sort.Title, order: Order.DESC },
    { text: '标题升序', sort: Sort.Title, order: Order.ASC },
];

// export { timeFilter, titleFilter };
