import React, { useState } from 'react';
import { Input } from 'antd';
import { IconSearch } from '@/components/icon';
import './search.less';
import Bus from '@/utils/bus';

export default function ToolsSearch({
    onShowToolsSearch,
}: {
    onShowToolsSearch: (val: boolean) => void;
}) {
    let timer: any = null;
    const IconSearchNode = () => {
        return <span color="#d9d9d9">{IconSearch}</span>;
    };
    return (
        <Input
            className="tools-search-input"
            placeholder="搜索"
            prefix={IconSearchNode()}
            onChange={(event) => {
                const val = event.target.value.trim() || '';
                clearTimeout(timer);
                timer = setTimeout(() => {
                    onShowToolsSearch(Boolean(val));
                    Bus.emit('onSearch', val);
                }, 700);
            }}
        />
    );
}
