/* eslint-disable max-params */
// /**
//  * 处理文件上传的业务
//  * 支持单文件， 多文件上传，上传进度，大文件分块上传。
//  */
import { EventEmitter } from 'events';
import { nanoid } from 'nanoid';
import { RcFile } from 'antd/lib/upload/interface';
// import { UploadTarget, OpenFileMode } from '@model/types';
// import { fileOpenMode } from '@utils/page-helper';
import {
    FileDocument,
    OpenFileMode,
    TaskState,
    UploadFile,
    UploadType,
    WPSUploadTransactionModel,
} from './types';
// const { publicRuntimeConfig } = getConfig();
import { createUploadTask } from '@/server/FileService';
import Task from '@jd/jdee-fs-desktop/lib/es/Task';
import {
    createDocument,
    fileOpenMode,
    getFileMd5,
    getFileType,
    initFile,
    uploadWPSFile,
} from '@/components/Joyspace/components/UploadBox/utils';
import isError from 'lodash/isError';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import {
    finishUploadWpsTransaction,
    startUploadWpsTransaction,
} from '@/components/Joyspace/api/file';
import { changeEgovUrl } from '@/utils/tools';
// import { createAttachmentWrapper, mergeFile, postFilesInit, sleep, sliceFile, uploadFile, uploadWPSFile } from '@utils/upload';
interface UploadControlItemsMap {
    [key: string]: UploadControl;
}
export const joyspaceKey = 'joyspace';
class UploadControlCenter {
    private UploadControlItems: UploadControlItemsMap;
    constructor() {
        this.UploadControlItems = {};
    }
    register(uploadControlName: string, toJoySpace: boolean) {
        if (!(uploadControlName in this.UploadControlItems)) {
            this.UploadControlItems[uploadControlName] = new UploadControl({
                toJoySpace,
                uploadControlName,
            });
        }
        return this.UploadControlItems[uploadControlName];
    }
    get(uploadControlName: string) {
        if (uploadControlName in this.UploadControlItems) {
            return this.UploadControlItems[uploadControlName];
        }
        console.error('获取上传对象出错');
        return null;
    }
    clear(uploadControlName: string) {
        if (uploadControlName in this.UploadControlItems) {
            delete this.UploadControlItems[uploadControlName];
        }
        return true;
    }
}
interface QueueItemp {
    data: {
        originFile: RcFile;
        fileServiceConfig?: { appKey: string; hostOrigin: string; joyspaceAppKey: string };
        options?: { joyspaceWpsTarget?: 'private' | 'public' };
        gid?: string;
    };
}
export class UploadControl extends EventEmitter {
    private currentUploadCount: number;
    private maxUploadCount: number;
    private toJoySpace: boolean;
    private uploadControlName: string;
    private readonly uploadTaskMap: Map<string, Task>;
    private readonly beginUploadTaskMap: Map<string, Task>;
    private readonly uploadFileMap: Map<string, UploadFile>;
    private joyspaceAppKey = ''; // joyspace上传服务专有key
    private currTask: QueueItemp | null = null;
    private queueItem: QueueItemp[] = [];
    private working = false;
    private gid?: string;
    constructor(props: { toJoySpace: boolean; uploadControlName: string }) {
        super();
        this.uploadControlName = props.uploadControlName;
        this.currentUploadCount = 0;
        this.maxUploadCount = 2; // 同时上传文件个数
        this.uploadFileMap = new Map<string, UploadFile>();
        this.uploadTaskMap = new Map<string, Task>();
        this.beginUploadTaskMap = new Map<string, Task>();
        this.gid = '';
        this.toJoySpace = props.toJoySpace;
    }
    checkIsToJoySpace() {
        return this.toJoySpace ?? false;
    }

    // **** 上传文件第一步 将文件数据发送给展示上传进度控制中心
    addFile = async (
        originFile: RcFile,
        fileServiceConfig?: { appKey: string; hostOrigin: string; joyspaceAppKey: string },
        options?: { joyspaceWpsTarget?: 'private' | 'public' },
        gid?: string
    ) => {
        const uploadFile = originFile as UploadFile;
        if (!this.validateSize(uploadFile)) {
            throw new Error('zeroSize');
        }
        uploadFile.uid = `${uploadFile.uid}_${nanoid()}`;
        uploadFile.state = TaskState.PENDING;
        this.uploadFileMap.set(uploadFile.uid, uploadFile);
        this.emit('upload-control-event', {
            fileIds: Array.from(this.uploadFileMap.keys()).reverse(),
        });
        this.pushQueue({
            data: {
                originFile,
                fileServiceConfig,
                options,
                gid,
            },
        });
        return uploadFile.uid;
    };
    addFiles = (
        originFiles: RcFile[],
        fileServiceConfig?: { appKey: string; hostOrigin: string; joyspaceAppKey: string },
        options?: { joyspaceWpsTarget?: 'private' | 'public' },
        gid?: string
    ) => {
        const fileIds = originFiles.map(async (originFile) => {
            return this.addFile(originFile, fileServiceConfig, options, gid);
        });
        return fileIds;
    };

    // 取消上传
    cancelUpload = (fileId: string) => {
        const task = this.getUploaddingTask(fileId);
        const file = this.getUploadFile(fileId);
        // eslint-disable-next-line no-debugger
        // debugger;
        if (!file) {
            return;
        }
        if (file && !task && file.state !== TaskState.COMPLETED) {
            Object.assign(file, { state: TaskState.CANCEL });
            this.sendNotify({ fileId, state: TaskState.CANCEL });
        }
        if (!task) {
            return;
        }
        task.cancel().then(() => {
            Object.assign(file, { state: TaskState.CANCEL });
            this.sendNotify({ fileId, state: TaskState.CANCEL });
            this.executeUpload();
        });
    };

    // 全部取消上传
    cancelAllUpload = () => {
        // const keys = Array.from(this.uploadFileMap.keys()) || [];
        // for (let key of keys) {
        //     let file = this.uploadFileMap.get(key);
        //     if (file) {
        //         if (TaskState.UPLOADING === file.state) {
        //             file.state = TaskState.CANCEL;
        //             this.sendNotify({ fileId: key, state: TaskState.CANCEL });
        //         }
        //     }
        // }
        const uploadTasks = Array.from(this.uploadTaskMap.values());
        const beiginUploadTasks = Array.from(this.beginUploadTaskMap.values());

        uploadTasks.forEach((task) => {
            const fileId = (task.file as UploadFile).uid;
            const file = this.getUploadFile(fileId);
            const uploadTask = this.beginUploadTaskMap.get(fileId);
            if (file && !uploadTask && file.state !== TaskState.COMPLETED) {
                Object.assign(file, { state: TaskState.CANCEL });
                this.sendNotify({ fileId, state: TaskState.CANCEL });
            }
        });
        beiginUploadTasks.forEach((task) => {
            const fileId = (task.file as UploadFile).uid;
            const file = this.getUploadFile(fileId);
            if (task.state !== TaskState.COMPLETED) {
                task.cancel().then(() => {
                    Object.assign(file, { state: TaskState.CANCEL });
                    this.sendNotify({ fileId, state: TaskState.CANCEL });
                });
            }
        });
    };
    // 获取正在上传以及等待中数量
    getUploadingCount() {
        const files = Array.from(this.uploadFileMap.values() || []);
        const uploadingCountAry = files.filter(this.isUploadingState);
        return uploadingCountAry.length;
    }
    // 重新上传,全部取消时，没有开始的重新建立上传任务
    reStartUpload = async (fileId: string) => {
        const task = this.getUploadTask(fileId);
        const task1 = this.getUploaddingTask(fileId);
        const file = this.getUploadFile(fileId);

        if (!file) {
            return;
        }
        // 重新上传wps文件
        if (file.fileType === 'wps' && this.toJoySpace) {
            this.dealWpsFile(file);
            return;
        }
        // 秒传文件直接结束上传即可
        if (file.uploadType === UploadType.ALL) {
            this.endUploadFile(fileId);
            return;
        }

        if (task1) {
            const initResult = await initFile(file);
            // eslint-disable-next-line no-console
            // // console.log('step3 ===> ', initResult);
            if (initResult) {
                // 文件已经初始化完成了
                file.initResult = initResult;
                console.log(file.initResult.uploadId, 'uploadId', file.uid);
                Object.assign(file, { state: TaskState.INITED });
                this.sendNotify({ fileId: file.uid, state: TaskState.INITED });
                if (initResult.secondPass) {
                    // 秒传文件不创建上传文件任务，直接执行创建文档类型
                    // this.currentUploadCount--;
                    // **** 最后一步 处理创建文档类型，发送事件通知
                    await this.endUploadFile(file.uid);
                } else {
                    task1.restart(initResult);
                }
            } else {
                console.log('restart初始化失败');
            }
        } else {
            // 如果初始化成功
            if (task) {
                Object.assign(file, { state: TaskState.INITED });
                this.sendNotify({ fileId: file.uid, state: TaskState.INITED });
                this.executeUploadTask(task);
            } else {
                // 如果初始化不成功 重新初始化一次
                await this.initUploadFile(file);
                const task = this.getUploadTask(fileId);
                task && this.executeUploadTask(task);
            }
        }
    };
    // 获取上传完成的数量
    getCompletedCount = (): number => {
        const tasks = Array.from(this.uploadFileMap.values() || []);
        return tasks.filter((task) => {
            return TaskState.COMPLETED === task.state;
        }).length;
    };

    // 获取上传状态
    getStateInfo = (): TaskState => {
        // const files = Array.from(this.uploadFileMap.values()) || [];
        // // let file = null;

        // for (let file of files) {
        //     if (file.state !== TaskState.INITED) {
        //         return TaskState.UPLOADING;
        //     }
        // }

        const files = Array.from(this.uploadFileMap.values() || []);
        let index = files.findIndex(this.isUploadingState);
        if (index > -1) {
            return TaskState.UPLOADING;
        }

        index = files.findIndex(this.isFailedState);
        if (index > -1) {
            return TaskState.FAILED;
        }

        index = files.findIndex(this.isCancelState);
        if (index > -1) {
            return TaskState.CANCEL;
        }

        return TaskState.COMPLETED;
    };
    // 清除上传过程中创建的资源
    clearAllUpload = (): void => {
        // this.maxUploadCount = 2;
        this.clearQueue();
        this.currentUploadCount = 0;
        const uploadTasks = Array.from(this.beginUploadTaskMap.values());
        uploadTasks.forEach((task) => {
            if (task && task.state !== TaskState.COMPLETED) {
                task.cancel().then(() => {
                    task.off('state', this.taskStateChange.bind(this, task));
                    task.off('error', this.uploadErrorListener.bind(this, task));
                });
            }
        });
        this.uploadFileMap.clear();
        this.uploadTaskMap.clear();
        this.beginUploadTaskMap.clear();
    };
    // 删除某一任务
    deleteFile = (fileId: string) => {
        if (fileId in this.beginUploadTaskMap) {
            const task = this.beginUploadTaskMap.get(fileId);
            task?.cancel().then(() => {
                task.off('state', this.taskStateChange.bind(this, task));
                task.off('error', this.uploadErrorListener.bind(this, task));
            });
        }
        let needResh = 0;
        if (this.uploadFileMap.get(fileId)) {
            needResh = 1;
        }
        this.uploadFileMap.delete(fileId);
        this.uploadTaskMap.delete(fileId);
        this.beginUploadTaskMap.delete(fileId);
        needResh &&
            this.emit('upload-control-event', {
                fileIds: Array.from(this.uploadFileMap.keys()).reverse(),
            });
    };
    public getUploadFile = (fileId: string): UploadFile | undefined => {
        return this.uploadFileMap.get(fileId);
    };
    private pushQueue(item: QueueItemp): void {
        this.queueItem.unshift(item);
        this.consumer();
    }

    /**
     * 清空下载队列
     */
    private clearQueue(): void {
        this.queueItem.splice(0, this.queueItem.length);
        this.currTask = null;
        this.working = false;
    }

    private async consumer(): Promise<void> {
        if (!this.working) {
            const item = this.queueItem.pop();
            if (item) {
                this.working = true;
                this.currTask = item;
                const { originFile, fileServiceConfig, options, gid } = item.data;

                this.beginUpload(originFile, fileServiceConfig, options, gid)
                    .then((res) => {
                        this.working = false;
                        this.currTask = null;
                        this.consumer();
                    })
                    .catch((err) => {
                        this.working = false;
                        this.currTask = null;

                        console.log(`auto download error`, err);
                        this.consumer();
                    });
            } else {
                this.working = false;
            }
        }
    }
    private beginUpload = async (
        originFile: RcFile,
        fileServiceConfig?: { appKey: string; hostOrigin: string; joyspaceAppKey: string },
        options?: { joyspaceWpsTarget?: 'private' | 'public' },
        gid?: string
    ) => {
        const uploadFile = originFile as UploadFile;

        this.gid = gid;
        // 创建上传任务
        if (uploadFile) {
            let file: any = uploadFile;
            // 适配中标麒麟application/vnd.lotus-1-2-3格式文件及测试提出的无type文本问题
            if (file.type === 'application/vnd.lotus-1-2-3') {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                let url: any = window.URL || window.webkitURL;
                let blob = url.createObjectURL(file);
                // 构造新File对象
                file = new File([file], file.name + '.txt', { type: 'text/plain' });
            }

            // console.log(file.uploadId);
            // 发送事件到控制界面

            this.joyspaceAppKey = fileServiceConfig?.joyspaceAppKey || '';

            this.uploadFileMap.set(file.uid, file);

            // 如果wps是私有化模式 并且文件是wps类型，走私有化上传渠道
            if (
                options &&
                options.joyspaceWpsTarget === 'private' &&
                fileOpenMode(getFileType(file)) === OpenFileMode.WPS &&
                this.toJoySpace
            ) {
                this.dealWpsFile(file);
                return;
            } else {
                await this.initUploadFile(file);
            }

            this.executeUpload();
        }
        return uploadFile.uid;
    };

    // **** 上传第二步 初始化上传文件
    private initUploadFile = async (file: UploadFile, appRuntimeEnv?: AppRuntimeEnv) => {
        // const file = this.findOneUnInitFile();
        // eslint-disable-next-line no-console
        // // console.log('step1 ===> findOneUnInitFile', file?.uid, file?.state);
        if (!file) {
            return;
        }
        // if (file.state !== TaskState.INITING) {
        //     return;
        // }
        if (!this.toJoySpace) {
            this.createUploadFileTask(file);
            Object.assign(file, { state: TaskState.INITED });
            this.sendNotify({ fileId: file.uid, state: TaskState.INITED });
            return;
        }
        const initResult = await initFile(file);
        // eslint-disable-next-line no-console
        // // console.log('step3 ===> ', initResult);
        if (initResult) {
            // 文件已经初始化完成了
            file.initResult = initResult;
            console.log(file.initResult.uploadId, 'uploadId', file.uid);
            Object.assign(file, { state: TaskState.INITED });
            this.sendNotify({ fileId: file.uid, state: TaskState.INITED });
            if (initResult.secondPass) {
                // 秒传文件不创建上传文件任务，直接执行创建文档类型
                // this.currentUploadCount--;
                // **** 最后一步 处理创建文档类型，发送事件通知
                Object.assign(file, { uploadType: UploadType.ALL });
                await this.endUploadFile(file.uid);
                // await this.initUploadFile();
            } else {
                // 非秒传的文件 创建上传任务进行上传
                this.createUploadFileTask(file);
            }
        } else {
            // 当前文件初始化失败， 处理下一个文件
            // this.currentUploadCount--;
            Object.assign(file, { state: TaskState.FAILED });
            // 发送通知
            this.sendNotify({ fileId: file.uid, state: TaskState.FAILED });
            // await this.initUploadFile();
        }
    };

    // **** 上传文件第三步  创建上传任务
    private createUploadFileTask = (file: UploadFile) => {
        if (!file) {
            return null;
        }
        // eslint-disable-next-line no-debugger
        // debugger;
        // 创建上传任务， 该创建任务的初始状态为 inited
        // @ts-ignore
        const task = createUploadTask(
            file.uid,
            file,
            this.toJoySpace
                ? {
                      fileHash: file.initResult.hash,
                      uploadId: file.initResult.uploadId,
                      sliceSize: file.initResult.info.sliceSize,
                      // sliceSize: (1024 * 1024) / 4,
                      fileUploadUrl: changeEgovUrl(file.initResult.info.fileUploadUrl),
                      appKey: this.joyspaceAppKey || 'd756fe70a332a6f9', // 'd756fe70a332a6f9',
                  }
                : {}
        );
        console.log('addFile ==>', task?.state, task);
        if (task) {
            this.uploadTaskMap.set(file.uid, task);
            // console.log('uploadTaskMap', this.uploadTaskMap.keys());
            // this.executeUpload();
        }
    };

    // 上传过程 错误事件回调
    private uploadErrorListener = (task: Task, error: any) => {
        // // console.log('executeUpload error ==>', error);
        const fileId = (task.file as UploadFile).uid;
        // this.sendNotify({ fileId, state: TaskState.FAILED });
        this.emit('upload-control-error-event', {
            task,
            fileId,
            error,
        });
    };

    // **** 上传文件第四步 开始上传操作
    private executeUpload = () => {
        console.log('step2 ===> ', this.currentUploadCount, this.maxUploadCount);
        if (this.currentUploadCount >= this.maxUploadCount) {
            return;
        }
        // eslint-disable-next-line no-debugger
        // debugger;
        // eslint-disable-next-line no-console
        // console.log(this.uploadTaskMap.values(), 'task1111111', this.uploadFileMap.values());
        const task = this.findOneInitedTask();
        this.currentUploadCount++;
        // console.log('TaskState.INITED', task);
        if (
            task &&
            ((this.toJoySpace && task.state === TaskState.INITED) ||
                (!this.toJoySpace && task.state === TaskState.PENDING))
        ) {
            console.log('TaskState.INITEDexecuteUpload', task);
            const fileId = (task.file as UploadFile).uid;
            this.beginUploadTaskMap.set(fileId, task);
            console.log(this.beginUploadTaskMap.values(), 'beginUploadTaskMap');
            task.on('state', this.taskStateChange.bind(this, task));
            task.on('error', this.uploadErrorListener.bind(this, task));
            task.start().then();
        } else {
            this.currentUploadCount--;
        }
    };
    private executeUploadTask = (task: Task) => {
        console.log('executeUploadTask111', task);
        if (
            task &&
            ((this.toJoySpace && task.state === TaskState.INITED) ||
                (!this.toJoySpace && task.state === TaskState.PENDING))
        ) {
            console.log('executeUploadTask222', task);
            const fileId = (task.file as UploadFile).uid;
            this.beginUploadTaskMap.set(fileId, task);
            task.on('state', this.taskStateChange.bind(this, task));
            task.on('error', this.uploadErrorListener.bind(this, task));
            task.start().then();
        }
    };
    // **** 上传文件第五步 监听上传数据回调
    private taskStateChange = async (task: Task, data: any) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const fileId = (task.file as UploadFile).uid;

        const file = this.getUploadFile(fileId);
        if (!file) {
            return;
        }
        Object.assign(file, { state: data.state });
        // console.log('taskStateChange state ==>', data);
        if (data.state === TaskState.COMPLETED) {
            // 清除上传资源

            console.log('COMPLETED ==>', data);
            file.fileUrl = data.downloadUrl;
            // Object.assign(file, data);
            // this.clearUpload((task.file as UploadFile).uid);
            // 当前有任务完成后 执行下一个上传任务
            this.currentUploadCount--;
            this.sendNotify({ fileId, state: TaskState.UPLOADING, progress: 99.99 });
            // 创建文档类型
            await this.endUploadFile(fileId);

            // // console.log('taskStateChange state ==>', this.currentUploadCount);
            this.executeUpload();
        } else if (data.state === TaskState.FAILED) {
            this.currentUploadCount--;
            this.sendNotify({ fileId, state: TaskState.FAILED });
            this.executeUpload();
        } else if (data.state === TaskState.CANCEL) {
            this.currentUploadCount--;
        } else if (data.state === TaskState.UPLOADING) {
            const progress = data.progress * 100;
            const p = progress > 99 ? 99 : progress;
            this.sendNotify({ fileId, state: TaskState.UPLOADING, progress: p });
        } else {
            this.sendNotify({ fileId, state: TaskState.UPLOADING });
        }
    };

    private dealWpsFile = async (file: UploadFile) => {
        Object.assign(file, { state: TaskState.INITING, fileType: 'wps' });
        // this.currentUploadCount++;
        // 初始化文件
        const trans: {
            transaction: WPSUploadTransactionModel;
            folder: any;
        } = await this.startUploadTransaction(file);
        if (!trans) {
            return;
        }
        const { folder, transaction } = trans;
        Object.assign(file, { transactionId: transaction.id });

        const fileId = file.uid;
        // 虚拟进度条
        let _timeIndex: any;
        let percent = 5;

        const tmpTime = Math.max(Math.floor(file.size / (200 * 1024)), 3);
        _timeIndex = setInterval(() => {
            if (file.state === TaskState.CANCEL) {
                _timeIndex && clearInterval(_timeIndex);
                _timeIndex = null;
                return;
            }
            percent += (90 - percent) / tmpTime;
            Object.assign(file, { state: TaskState.UPLOADING });
            this.sendNotify({ fileId, state: TaskState.UPLOADING, progress: percent });
        }, 100);

        const result = await uploadWPSFile(file, transaction);
        // 已经重新生成上传任务取消后面的操作
        if (file.transactionId !== transaction.id) {
            _timeIndex && clearInterval(_timeIndex);
            _timeIndex = null;
            return;
        }
        if (file.state === TaskState.CANCEL) {
            // 先不调用aborted现在调用报错
            // await this.finishUploadTransaction(
            //     file,
            //     transaction.id,
            //     folder.id,
            //     transaction.feedback,
            //     'aborted',
            //     false
            // );

            _timeIndex && clearInterval(_timeIndex);
            _timeIndex = null;
            return;
        }

        // 未上传成功则提交终止事件
        if (!result.storekey) {
            await this.finishUploadTransaction(
                file,
                transaction.id,
                folder.id,
                transaction.feedback,
                'aborted',
                false
            );
            _timeIndex && clearInterval(_timeIndex);
            _timeIndex = null;
            // this.currentUploadCount--;
            // this.executeUpload();
            Object.assign(file, { state: TaskState.FAILED });
            // 发送通知
            this.sendNotify({ fileId: file.uid, state: TaskState.FAILED });
            return;
        }
        _timeIndex && clearInterval(_timeIndex);
        _timeIndex = null;
        const finish = await this.finishUploadTransaction(
            file,
            transaction.id,
            folder.id,
            transaction.feedback,
            'commited'
        );
        if (!finish) {
            return;
        }
        file.document = finish;
        // this.currentUploadCount--;
        this.uploadFileMap.set(fileId, file);
        Object.assign(file, { state: TaskState.COMPLETED });
        this.sendNotify({ fileId, state: TaskState.COMPLETED, progress: 100 });
    };

    private startUploadTransaction = async (file: UploadFile) => {
        const fileMd5 = getFileMd5(file);
        const postFile = {
            size: file.size,
            name: file.name,
            md5: fileMd5,
            type: getFileType(file),
        };
        const [data, err] = await startUploadWpsTransaction({
            file: postFile,
            folderId: file.folderId || '',
            // spaceId: file.spaceId || '',
            // categoryId: file.categoryId || '',
            attachment: false,
        });
        if (err || !data) {
            // this.currentUploadCount--;
            Object.assign(file, { state: TaskState.FAILED });
            // this.executeUpload();
            // 发送通知
            this.sendNotify({ fileId: file.uid, state: TaskState.FAILED });
            return null;
        }
        return data;
    };

    private finishUploadTransaction = async (
        file: UploadFile,
        transactionId: string,
        parentId: string,
        feedback: string,
        status: 'commited' | 'aborted',
        showError = true
    ) => {
        const {
            type,
            spaceId = '',
            categoryId = '',
            folderId = '',
            attachment = false,
            pageId = '',
        } = file;
        const [data, err] = await finishUploadWpsTransaction({
            fileType: getFileType(file),
            transactionId,
            parentId,
            status,
            // spaceId,
            // categoryId,
            folderId,
            feedback,
            attachment,
            pageId,
            to_groups: [{ gid: this.gid, permission_type: 1 }],
        });

        if (showError && (err || !data)) {
            // this.currentUploadCount--;
            Object.assign(file, { state: TaskState.FAILED });
            // this.executeUpload();
            // 发送通知
            this.sendNotify({ fileId: file.uid, state: TaskState.FAILED });
            return null;
        }
        return data;
    };

    // **** 最后一步 处理创建文档类型，发送事件通知
    private endUploadFile = async (fileId: string) => {
        if (this.toJoySpace) {
            const document = await this.createFileDocument(fileId);
            console.log('endUploadFiledocument', document);
            if (document && !isError(document)) {
                console.log('endUploadFile', document);
                const file = this.getUploadFile(fileId);
                if (file) {
                    file.document = document;
                    this.uploadFileMap.set(fileId, file);
                    Object.assign(file, { state: TaskState.COMPLETED });
                }

                this.sendNotify({ fileId, state: TaskState.COMPLETED, progress: 100 });
            } else {
                const file = this.getUploadFile(fileId);
                if (file) {
                    Object.assign(file, { state: TaskState.FAILED });
                }
                this.sendNotify({ fileId, state: TaskState.FAILED });
            }
        } else {
            const file = this.getUploadFile(fileId);
            if (file) {
                Object.assign(file, { state: TaskState.COMPLETED });
                this.sendNotify({ fileId, state: TaskState.COMPLETED, progress: 100 });
            }
        }
    };

    // 创建文档类型
    private createFileDocument = async (fileId: string): Promise<FileDocument | Error | null> => {
        const file = this.getUploadFile(fileId);
        if (!file) {
            return null;
        }
        console.log(file.initResult.uploadId, 'createFileDocument', file.uid, fileId);

        const body = {
            uploadId: file.initResult.uploadId,
            folderId: file.folderId || '',
            // spaceId: file.spaceId || '',
            // categoryId: file.categoryId || '',
            gid: this.gid,
        };
        return await createDocument(body);
    };

    // 获取一个未初始化的文件
    private findOneUnInitFile = (): UploadFile | undefined => {
        const files = Array.from(this.uploadFileMap.values() || []);
        return files.find((file) => {
            if (file.state === TaskState.PENDING) {
                Object.assign(file, { state: TaskState.INITING });
                return file;
            }
            return null;
        });
    };

    // 获取一个初始化结束的上传任务
    private findOneInitedTask = (): Task | undefined => {
        const tasks = Array.from(this.uploadTaskMap.values() || []);
        return tasks.find((task) => {
            const fileId = (task.file as UploadFile).uid;
            const file = this.getUploadFile(fileId);
            if (file) {
                return file.state === TaskState.INITED;
            }
            return false;
        });
    };

    // 发送上传环节事件通知
    private sendNotify = ({
        fileId,
        state,
        progress,
    }: {
        fileId: string;
        state: TaskState;
        progress?: number;
    }) => {
        const file = this.getUploadFile(fileId);
        if (file && progress) {
            Object.assign(file, { progress: progress });
        }
        this.emit(`upload-control-state-event`, { fileId, state, progress, file });
        this.emit(`upload-item-control-event:${fileId}`, { fileId, state, progress, file });
    };

    // 获取上传任务
    private getUploadTask = (fileId: string): Task | undefined => {
        return this.uploadTaskMap.get(fileId);
    };
    // 获取开始上传任务
    private getUploaddingTask = (fileId: string): Task | undefined => {
        return this.beginUploadTaskMap.get(fileId);
    };
    private isUploadingState = (file: UploadFile): boolean => {
        return ![TaskState.COMPLETED, TaskState.CANCEL, TaskState.FAILED].includes(file.state);
    };

    private isFailedState = (file: UploadFile): boolean => {
        return TaskState.FAILED === file.state;
    };

    private isCancelState = (file: UploadFile): boolean => {
        return TaskState.CANCEL === file.state;
    };

    private validateSize = (file: UploadFile): boolean => {
        return !(file && file.size === 0);
    };
}

export const UploadControlCenterInstance = new UploadControlCenter();
