import { gatewayInstance } from '@/server/gateway';
import { GatewayRequestOptions } from '@/server/types';
import { message } from 'antd';
import cache from '@/utils/cache';
import { IprojectStatus } from '../types';
import { changeGovUrl } from '@/utils/tools';

export interface RequestOptions extends Partial<Pick<GatewayRequestOptions, 'method' | 'body'>> {
    api: string;
    path?: string;
    params?: { [key: string]: any };
}

export interface ProjectGroupListResp {
    fromMQ: boolean;
    groupId: string;
    groupName: string;
    icon: string;
}

interface Request200StatusRes {
    errCode?: number;
    [key: string]: any;
}

export const request = (options: RequestOptions, ignoreError?: boolean) =>
    gatewayInstance
        .request<Request200StatusRes>({
            api: options.api,
            method: options.method ?? 'POST',
            dynamicPath: options.path,
            params: options.params,
            body: options.body,
            headers: {
                platform: cache?.token?.token?.focusClient || 'ipad', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
            },
        })
        .catch((e) => {
            const errCode = e.toString().match(/\[(\d+)\]/)?.[1];
            if (!ignoreError) {
                message.error(e?.message || '接口出错了！', 5);
            }
            return {
                errCode: Number(errCode ?? -1),
            };
        })
        .then((data) => {
            // 服务端接口规则不统一，需要做一层标准化
            if (data === null) {
                return {};
            }
            if (Array.isArray(data)) {
                return { items: data };
            }
            if (typeof data !== 'object') {
                return { data };
            }
            return data;
        });

// 获取用户的所有项目
export function getAllProjectsService(
    body: { searchText: string; type?: IprojectStatus; summary?: boolean } = {
        searchText: '',
        type: IprojectStatus.NORMAL,
        summary: false,
    }
) {
    return request({
        api: 'work.project.findMyProjects',
        body,
    });
}

// 创建项目
export function createProjectService(body: {
    type: number;
    name: string;
    icon: string;
    iconColor?: string;
    description: string;
    projectUsers: Array<{ userId: string; teamId: string; app: string; duty?: string }>;
}) {
    return request({
        api: 'work.project.createProject',
        body,
    });
}

interface UpdateProjectOptions {
    name?: string;
    icon?: string;
    iconColor?: string;
    progress?: string | number;
    progressDesc?: string;
    description?: string;
}
// 更新项目信息
export function updateProject(projectId: string, options: UpdateProjectOptions) {
    return request({
        api: 'work.project.updateProject',
        body: {
            projectId,
            name: options.name,
            icon: options.icon,
            progress: options.progress,
            progressDesc: options.progressDesc,
            description: options.description,
        },
    });
}

// 退出或删除项目
export function exitOrDeleteProject(projectId: string) {
    return request({
        api: 'work.project.exitOrDeleteProject',
        body: {
            projectId,
        },
    });
}
// 归档项目
export function archiveProjectApi(projectId: string, archive: boolean) {
    return request({
        api: 'work.project.archiveProject',
        body: {
            projectId,
            archive,
        },
    });
}

// 公告列表
/* eslint max-params: ["error", 4] */
export function getAnnounceListService(
    projectId: string,
    offset = 0,
    limit = 10,
    announcementTypeEnum?: string
) {
    return request({
        api: 'work.project.findProjectAnnouncement',
        body: {
            announcementTypeId: projectId,
            offset,
            limit,
            announcementTypeEnum: announcementTypeEnum
                ? announcementTypeEnum
                : 'JoyProject_Announcement',
        },
    });
}
// 创建公告
export function createAnnounceService(datas: {
    projectId: string;
    content: string;
    attachmentUrls?: string[];
    showType?: any;
}) {
    let urlArray = datas.attachmentUrls?.map((item) => changeGovUrl(item)) || [];
    return request({
        api: 'work.project.createAnnouncement',
        body: {
            announcementTypeId: datas.projectId,
            content: datas.content,
            showType: datas.showType,
            type: 1,
            // announcementTypeEnum: 'JoyProject_Announcement',
            announcementTypeEnum: 'Group_Announcement',
            announcementAttachments: [
                {
                    attachmentUrls: urlArray,
                    attachmentType: 1,
                },
            ],
        },
    });
}
// 更新公告
export function updateAnnounceService(datas: {
    projectId: string;
    announcementId: string;
    content: string;
    showType?: any;
    attachmentUrls?: string[];
    announcementTypeEnum?: string;
}) {
    return request({
        api: 'work.project.updateAnnouncement',
        body: {
            announcementTypeId: datas.projectId,
            content: datas.content,
            showType: datas.showType,
            type: 1,
            announcementId: datas.announcementId,
            announcementTypeEnum: datas.announcementTypeEnum
                ? datas.announcementTypeEnum
                : 'JoyProject_Announcement',
            announcementAttachments: [
                {
                    attachmentUrls: datas.attachmentUrls,
                    attachmentType: 1,
                },
            ],
            clientTerminal: 'PC_1_5_0',
        },
    });
}
// 删除公告
export function deleteAnnounceService(
    projectId: string,
    announcementId: string,
    announcementTypeEnum?: string
) {
    return request({
        api: 'work.project.deleteProjectAnnouncement',
        body: {
            announcementTypeId: projectId,
            announcementId,
            announcementTypeEnum: announcementTypeEnum
                ? announcementTypeEnum
                : 'JoyProject_Announcement',
        },
    });
}

// 公告详情
export function findAnnouncementDetail(
    projectId: string,
    announcementId: string,
    announcementTypeEnum?: string
) {
    return request({
        api: 'work.project.findAnnouncementDetail',
        body: {
            announcementTypeId: projectId,
            announcementId,
            announcementTypeEnum: announcementTypeEnum
                ? announcementTypeEnum
                : 'JoyProject_Announcement',
        },
    });
}
// 获取专班群的最新公告
export function findAlertAnnouncement(projectId: string) {
    return request(
        {
            api: 'work.project.findAlertAnnouncement',
            body: {
                announcementTypeId: projectId,
                announcementTypeEnum: 'Group_Announcement',
            },
        },
        true
    );
}
// 确认公告
export function ackAnnouncement(
    projectId: string,
    announcementId: string,
    announcementTypeEnum?: string
) {
    return request({
        api: 'work.project.ackAnnouncement',
        body: {
            announcementTypeId: projectId,
            announcementId,
            announcementTypeEnum: announcementTypeEnum
                ? announcementTypeEnum
                : 'JoyProject_Announcement',
        },
    });
}

// 获取项目详情页
export function getProjectDetails(projectId: string, ignoreError?: boolean) {
    return request(
        {
            api: 'work.project.findProjectDetail',
            body: { projectId },
        },
        ignoreError
    );
}

interface PublishMessageProps {
    projectId: string;
    content: string;
    contentType: 1 | 2;
    parentId?: string;
    replyId?: string;
}

// 发布评论
export function saveComment(body: PublishMessageProps) {
    return request({
        api: 'work.project.saveComment',
        body,
    });
}

// 评论列表
export function comments(projectId: string) {
    return request({
        api: 'work.project.findComments',
        body: {
            projectId,
        },
    });
}

// 删除列表
export function deleteComment(commentId: string) {
    return request({
        api: 'work.project.deleteComment',
        body: {
            commentId,
        },
    });
}

// 评论列表
export function commentlike(commentId: string, flag: boolean) {
    return request({
        api: 'work.project.commentPraise',
        body: {
            commentId,
            flag,
        },
    });
}

// 群聊列表
export function findProjectGroups(projectId: string, keyWord?: string) {
    return request({
        api: 'work.project.findProjectGroup',
        body: {
            projectId,
            keyWord,
        },
    });
}
// 根据群组 id 批量获取群组信息
export async function getGroupInfo(groupIds: { groupId: string }[]) {
    return (await gatewayInstance.request({
        api: 'egroup.groupGetInfo',
        method: 'POST',
        body: { type: 5, groupIds },
    })) as any;
}

// 群聊列表
export function addProjectsMember(projectId: string, members: any) {
    return request({
        api: 'work.project.addProjectsMember',
        body: {
            projectId,
            projectUsers: members,
        },
    });
}

interface UpdateUserParams {
    userId: string;
    teamId: string;
    app: string;
    duty?: string;
    userRole?: number;
}

// 更新用户信息
export function updateUser(projectId: string, params: UpdateUserParams) {
    return request({
        api: 'work.project.updateUser',
        body: {
            projectId,
            userList: [
                {
                    userId: params.userId,
                    teamId: params.teamId,
                    app: params.app,
                    duty: params.duty,
                    userRole: params.userRole,
                },
            ],
        },
    });
}
// interface UpdateUserRoleParams {
//     userId: string;
//     teamId: string;
//     app: string;
//     userRole: number;
// }

// // 更新用户角色
// export function updateUserRole(projectId: string, user: UpdateUserRoleParams) {
//     return request({
//         api: 'work.project.updateUserRole',
//         body: {
//             projectId,
//             user,
//         },
//     });
// }

// 新建群聊
export function addGroupChat(body: {
    projectId: string;
    users: Array<{
        userId: string;
        teamId: string;
        app: string;
    }>;
    icon: string;
    groupName: string;
}) {
    return request({
        api: 'work.project.createGroup',
        body,
    });
}

interface DeleteProjectsMemberParams {
    userId: string;
    teamId: string;
    app: string;
}

// 删除项目成员
export function deleteProjectsMember(
    projectId: string,
    projectUsers: DeleteProjectsMemberParams[]
) {
    return request({
        api: 'work.project.deleteProjectsMember',
        body: {
            projectId,
            projectUsers,
        },
    });
}

// 获取项目成员
export function findProjectsMemberService(projectId: string, keyWord = '') {
    return request({
        api: 'work.project.findProjectMemberDetailList',
        body: {
            projectId,
            keyWord,
            keyType: 1,
        },
    });
}
