import { request } from '@/components/Joyspace/api/request';
import * as __shortcuts from './shortcuts';
import * as __collections from './collections';
import * as __follow from './follow';

import { BusinessType } from '../const';
import { shortcuts as _shortcuts, _removeFromRecent, remove as _remove } from './pages';
const business = BusinessType.Page;

// 收藏、关注、快捷 增删改查接口
interface ICrud {
    add: (id: string, type?: any) => Promise<boolean>;
    remove: (id: string, type?: any) => Promise<boolean>;
    toggle: (id: string, status: boolean) => Promise<boolean>;
    [key: string]: any;
}

// 删除空间页面
export const remove = (spaceid: string, id: string) => _remove(spaceid, id);

// 页面收藏
export const collections: ICrud = {
    add: (id) => __collections.add(id, business),
    remove: (id) => __collections.remove(id, business),
    toggle: (id, status) => (status ? collections.remove(id) : collections.add(id)),
};

// 页面关注
export const follow: ICrud = {
    add: (id) => __follow.add(id, business),
    remove: (id) => __follow.remove(id, business),
    toggle: (id, status) => (status ? follow.remove(id) : follow.add(id)),
};

// 页面快捷方式
export const shortcuts: ICrud = {
    add: (id: string, type: number) => __shortcuts.add(id, type),
    remove: (id: string, type: number) => __shortcuts.remove(id, type),
    toggle: (id, status) => (status ? shortcuts.remove(id) : shortcuts.add(id)),
    get: (start: number, length?: number) => {
        return _shortcuts(start, length || 10);
    },
    // request({
    //     api: 'joyspace.shortcut.page',
    //     path: '/v1/shortcuts/page',
    //     params: {
    //         start,
    //         length,
    //     },
    // }),
};

export const recent = {
    remove: (id: string) => _removeFromRecent(id),
};

export const addTo = (categoryId: string, pageId: string, team?: 0 | 1) => {
    const body: any = { pageId };
    if (typeof team !== 'undefined') {
        body.categoryId = categoryId;
        if (team === 1) {
            body.permissionType = 1;
        }
    } else {
        body.folderId = categoryId;
    }
    return request({
        api: 'joyspace.pages.addTo',
        path: `/v1/pages/addTo`,
        method: 'POST',
        body,
    });
};

export const rename = (id: string, name: string) => {
    return request({
        api: 'joyspace.file.rename',
        path: `/v1/files/${id}/rename`,
        method: 'PUT',
        body: {
            name,
        },
    });
};

export const clone = (id: string, title: string) => {
    return request({
        api: 'joyspace.pages.clone',
        path: `v1/pages/clone`,
        method: 'POST',
        body: {
            pageId: id,
            pageTitle: title,
        },
    });
};

// 复制到
// eslint-disable-next-line
export const copy = (pageId: string, placename: string, categoryId?: string, spaceId?: string) => {
    return request({
        api: 'joyspace.pages.copyTo',
        path: `v1/pages/copyTo`,
        method: 'POST',
        body: {
            // categoryId,
            // spaceId,
            folderId: categoryId,
            pageId,
            placename,
        },
    })
        .then((data) => data)
        .catch((err) => {
            console.error(err);
            throw err;
        });
};

type MoveType = 'folder' | 'space' | 'category';
// eslint-disable-next-line
export const moveTo = (fromId: string, fromType: MoveType, toId: string, toType: MoveType) => {
    return request({
        api: 'joyspace.pages.moveTo',
        path: `v1/pages/moveto`,
        method: 'PUT',
        body: {
            fromId,
            fromType,
            toId,
            toType,
        },
    });
};

export const moveToCategory = (spaceId: string, pageId: string, categoryId: string) => {
    return request({
        api: 'joyspace.spacePage.moveToCategory',
        path: `/v1/spaces/${spaceId}/pages/${pageId}/move-to-category`,
        method: 'PUT',
        body: {
            categoryId,
        },
    });
};

// 获取最近分享人列表
export function getSharedContact(page_id = '', start = 0, limit = 99) {
    return request({
        api: 'joyspace.shareRecommend.getSharedRecommendList',
        path: '/v1/share-recommend/getSharedRecommendList',
        method: 'GET',
        params: {
            start,
            limit,
            page_id,
        },
    });
}

// 把文档分享给某人
export function sharedToSomeBodyService(
    params: Partial<{
        page_id: string;
        to_groups: Array<any>;
        to_orgs: Array<any>;
        to_users: Array<{ id: string; permission_type: number }>;
        to_mail_groups: Array<any>;
    }>
) {
    return request({
        api: 'joyspace.share.create',
        path: '/v1/shares',
        method: 'POST',
        params: params,
    });
}

// 获取最近联系人
export const getSharedRecommendList = (pageId: any, start: number, pageSize = 100) => {
    return request({
        api: 'joyspace.shareRecommend.getSharedRecommendList',
        path: 'v1/share-recommend/getSharedRecommendList',
        method: 'GET',
        params: { page_id: pageId, start, limit: pageSize },
    });
};

// 把文档分享给人或者群
export function shareJoyspaceFiles(
    params: Partial<{
        page_ids: Array<string>;
        to_eepaas_users: Array<any>;
        to_groups: Array<any>;
    }>
) {
    return request({
        api: 'joyspace.share.card',
        path: '/v1/shares/card',
        method: 'POST',
        body: params,
    });
}

// 把文档分享给人或者群后取消分享
export function cancelShareJoyspaceFiles(
    params: Partial<{
        page_ids: Array<string>;
        to_eepaas_users: Array<any>;
        to_groups: Array<any>;
    }>
) {
    return request({
        api: 'joyspace.share.cardCancel',
        path: '/v1/shares/cardCancel',
        method: 'POST',
        body: params,
    });
}

// 获取文档二维码
export function getQrCode(params: {
    appKey: string;
    content: string;
    bizCode: string;
    type: string;
    height?: number; // 仅type为QR_CODE 是有效
    width?: number; // 仅type为QR_CODE 是有效
}) {
    return request({
        api: 'qrcode.getQrCode',
        path: 'getQrCode',
        method: 'POST',
        body: params,
    });
}
// 获取文档是否存在
// 把文档分享给人或者群
export function getPageStatus(pageIdList: any[] = []) {
    return request({
        api: 'joyspace.pages.getPageStatus',
        path: '/v1/pages/getPageStatus',
        method: 'POST',
        body: pageIdList,
    });
}
