/**
 * 声音消息组件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useState } from 'react';
import { MessageSendType } from '@/types/chat/enum';
import IconFont from '@/components/icon';
import './index.less';
import { VoiceChatMessage } from '@/types/chat';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getFileUrl } from '@/utils/chat/index';
import getCurVoiceHandler, { VoiceStatus } from '../../handler/voiceHandler';
import classNames from 'classnames';
import { IcBtnVoice } from '@/assets/svg';
import { connect } from 'dvajs';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import ChatState, { ChatConnectState } from '@/types/chat/State';
import ImService from '@/server/ImService';
interface VoiceProps {
    message: VoiceChatMessage;
    sendType: MessageSendType;
}
interface DvaDispatchProps {
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
}

type IVoiceProps = Readonly<VoiceProps & DvaDispatchProps & WithTranslation & ChatState>;

function Voice({
    message,
    sendType,
    connectState,
    selectedSession,
    updateSessionMessageMid,
    updateSessionMessagesTimestamp,
}: IVoiceProps) {
    const [voiceStatus, setVoiceStatus] = useState(VoiceStatus.PLAY);

    const handleSetReadTime = async () => {
        // TODO: 单聊密聊消息开启倒计时
        const readTimeOfMessage = new Date().getTime();
        const imService = ImService.getInstance();
        const result = await imService.updateReadTime(message.sessionId, message.mid, {
            ...message,
            readTimeOfMessage,
        });
        if (result) {
            await updateSessionMessageMid({
                sessionId: message.sessionId,
                sendResult: {
                    body: { ...message, readTimeOfMessage },
                },
            });
            await updateSessionMessagesTimestamp({
                timestamp: new Date().getTime(),
            });
        }
    };

    function handleClickVoice(curMsg: VoiceChatMessage) {
        if (connectState && connectState.state === ConnectState.READY) {
            // 设置消息读取时间
            const needManualSet =
                selectedSession?.isSecret &&
                sendType === MessageSendType.OTHER &&
                message?.expire &&
                !message.readTimeOfMessage;
            needManualSet && handleSetReadTime();
            const { id, url } = curMsg;
            const src = getFileUrl(url);
            const voiceHandler = getCurVoiceHandler(id, src, {
                afterPlay: () => {
                    // console.log('afterPlay');
                    setVoiceStatus(VoiceStatus.STOP);
                },
                afterStop: () => {
                    // console.log('afterStop');
                    setVoiceStatus(VoiceStatus.PLAY);
                },
            });
            console.log('voiceHandler', voiceHandler);
            // 绑定事件
            // voiceHandler?.voiceAmr?.onPlay(function () {
            //     console.log('event play');
            //     setVoiceStatus(VoiceStatus.STOP);
            // });
            // voiceHandler?.voiceAmr?.onStop(function () {
            //     console.log('event stop');
            //     setVoiceStatus(VoiceStatus.PLAY);
            // });
        }
    }
    function getClass() {
        return classNames({
            'voice-box': true,
            owner: sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF,
        });
    }

    function getVoiceStatus() {
        if (voiceStatus === VoiceStatus.STOP) {
            return <div className="bg voicePlay" />;
        }

        if (voiceStatus === VoiceStatus.PLAY) {
            return <IcBtnVoice />;
        }
    }
    return (
        <div className={getClass()} data-id={message.id} onClick={() => handleClickVoice(message)}>
            <div className="icon">{getVoiceStatus()}</div>
            <div className="time">{message.duration}&quot;</div>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    return {
        connectState: chat.connectState,
        selectedSession: chat.selectedSession,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(Voice));
