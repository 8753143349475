import React, { useState } from 'react';
import './index.less';
import { Popover } from 'antd';
import IconFont from '@/components/icon';
import B2Img from '@/assets/img/taskType/B2_1.png';
import B1Img from '@/assets/img/taskType/B1_1.png';
import A2Img from '@/assets/img/taskType/A2_1.png';
import A1Img from '@/assets/img/taskType/A1_1.png';
import { changeEgovUrl } from '@/utils/tools';

// 任务优先级
export const PriorityList = [
    // { label: 'A1上级紧急', code: 4, color: '#EB5B56', color1: '#C93E39' },
    // { label: 'A2上级', code: 3, color: '#F3AC56', color1: '#EA861E' },
    // { label: 'B1同级紧急', code: 2, color: '#5EA7F8', color1: '#2473CA' },
    // { label: 'B2同级', code: 1, color: '#9AAEBB', color1: '#6D7F8C' },
    { label: A1Img, code: 4, color: '#F23030', color1: '#f230304d' },
    { label: A2Img, code: 3, color: '#FFB416', color1: '#ffb4164d' },
    { label: B1Img, code: 2, color: '#5EA7F8', color1: '#5ea7f84d' },
    { label: B2Img, code: 1, color: '#8ADE30', color1: '#8ade304d' },
];

function PrioritySelect({
    priority,
    onChange,
    disable,
}: {
    priority: number;
    onChange: (priority: number) => void;
    disable?: boolean;
}) {
    const [visible, setVisible] = useState(false);
    function handleClick(code: number) {
        if (!onChange || code === priority) {
            setVisible(false);
            return;
        }
        setVisible(false);
        onChange(code);
    }
    function getContent() {
        return (
            <div className="work-priority-list">
                {PriorityList.map((item) => (
                    <div
                        key={item.code}
                        className="work-priority-list-item"
                        onClick={() => handleClick(item.code)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                style={{ width: 100 + '%' }}
                                src={changeEgovUrl(item.label)}
                                alt=""
                            />
                        </div>
                        <span style={{ flex: 1 }} />
                        <span
                            className={`anction-selected ${
                                priority === item.code ? '' : 'anction-selected-hidden'
                            }`}
                        >
                            <IconFont type="iconbtn_check" />
                        </span>
                    </div>
                ))}
            </div>
        );
    }
    function handleVisibleChange(visible: boolean) {
        if (disable) {
            return;
        }
        setVisible(visible);
    }
    const priorityItem = PriorityList.find((p) => p.code === priority) || ({} as any);
    return (
        <Popover
            trigger="click"
            placement="bottom"
            content={getContent()}
            visible={visible}
            overlayClassName="work-task-priority-popover"
            onVisibleChange={handleVisibleChange}
            getPopupContainer={(trigger) => {
                return trigger.parentNode as HTMLElement;
            }}
        >
            <div className={`work-task-priority priority-${priority}`}>
                <div
                    style={{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        width: 25,
                        lineHeight: 26 + 'px',
                    }}
                >
                    <img
                        style={{ width: 100 + '%' }}
                        src={changeEgovUrl(priorityItem.label)}
                        alt=""
                    />
                </div>
                {/* {!disable && ( */}
                {/*    <div */}
                {/*        className="priority-angle" */}
                {/*        data-color={priorityItem.color} */}
                {/*        style={{ */}
                {/*            borderLeftColor: priorityItem.color1, */}
                {/*            borderBottomColor: priorityItem.color1, */}
                {/*        }} */}
                {/*    /> */}
                {/* )} */}
            </div>
        </Popover>
    );
}

export default PrioritySelect;
