/* eslint-disable react/no-direct-mutation-state */
// 设置展开栏群成员

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.less';
import { Input } from 'antd';
import classNames from 'classnames';
import { GroupRoster } from '@/types/chat/';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import PinyinMatch from 'pinyin-match';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import GroupMemberOperate from '@/components/chat/GroupMembers/MemberOperate';
import useGroupUserSelector from '@/components/chat/GroupUserSelector';
import { checkCanAddMember } from '@/components/chat/utils/group';
import Context from '@/components/chat/context';
import { getGroupMemberList } from '@/api/chat';
import _ from 'lodash';
import { GroupAttribute } from '@jd/jdee.im.sdk/lib/es/model/Group';
import bus from '@/utils/bus';
import ScrollView from '@/baseComponents/ScrollView';
import Loading from '@/components/Loading';
import { usePrevious } from '@/utils/chat/index';
import { analysisLog } from '@/utils/logAnalytics';

interface GroupMembersProps {
    updateGroupMembers: Function;
}

function GroupMembers(props: GroupMembersProps & ChatState) {
    const { selectedSession } = props;
    const prevSessionId = usePrevious(selectedSession?.sessionId || '');
    const { currentEmployee, groupRosterIdentity, userData } = React.useContext(Context);
    const currId = React.useRef('');
    const [groupRosters, setGroupRosters] = useState<GroupRoster[]>([]);
    const [loading, setLoading] = useState(false);
    const getAllGroupMembers = React.useCallback(
        (gid: string) => {
            if (!gid) {
                return;
            }
            const groupInfo = selectedSession.info as GroupAttribute;
            // 解散群或者不在群不调用接口
            if (!groupInfo.gid || (groupInfo.flag && groupInfo.flag !== 3)) return;
            setLoading(true);
            let _gid = gid;
            if (gid === '添加组成员' || gid === '删除组成员') {
                _gid = groupInfo.gid;
            }
            getGroupMemberList(_gid, 'group')
                .then(({ data, groupId }) => {
                    if (groupId === currId.current) {
                        setGroupRosters(data);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    console.log(`getGroupMemberList--err`, e);
                });
        },
        [selectedSession]
    );

    useEffect(() => {
        bus.on(`chat:session-update:${selectedSession?.sessionId}`, () => {
            getAllGroupMembers(selectedSession.sessionId);
        });
        return () => {
            bus.off(`chat:session-update:${selectedSession?.sessionId}`);
        };
        // bus.on('chat:group:addMember', getAllGroupMembers);
        // bus.on('chat:group:refreshMember', getAllGroupMembers);
        // bus.on('chat:group:addAdmin', getAllGroupMembers);
        // bus.on('chat:group:transferGroupOwner', getAllGroupMembers);
        // return () => {
        //     bus.off('chat:group:addMember', getAllGroupMembers);
        //     bus.off('chat:group:refreshember', getAllGroupMembers);
        //     bus.off('chat:group:addAdmin', getAllGroupMembers);
        //     bus.on('chat:group:transferGroupOwner', getAllGroupMembers);
        // };
    }, [selectedSession.sessionId, getAllGroupMembers]);
    // todo 先hack，需要查找selectedSession rosters 的赋值时机
    useEffect(() => {
        if (prevSessionId && prevSessionId === selectedSession.sessionId) {
            return;
        }
        let groupInfo = selectedSession.info as GroupAttribute;
        // 解散群或者不在群不调用接口
        if (!groupInfo.gid || (groupInfo.flag && groupInfo.flag !== 3)) return;
        getAllGroupMembers(groupInfo.gid);
        currId.current = selectedSession.sessionId;
    }, [selectedSession, getAllGroupMembers, prevSessionId]);

    const { t } = useTranslation('chat');
    const addMembers = useGroupUserSelector({
        selectedSession,
        currentEmployee,
        groupRosters,
        sessionMembers: [],
        userData,
        // callback: getAllGroupMembers,
    });

    let [list, setList] = useState([] as Partial<GroupRoster>[]);
    let [hasMore, setHasMore] = useState(false);

    const canAddMember = useMemo(() => {
        return checkCanAddMember({ selectedSession, groupRosterIdentity });
    }, [selectedSession, groupRosterIdentity]);

    const padList = useCallback(
        (list) => {
            const count = canAddMember ? list.length + 1 : list.length;
            const padNum = Math.abs(count % 6);
            if (padNum > 0 && padNum < 6) {
                return list.concat(
                    [...Array(6 - padNum)].map(() => {
                        return {};
                    })
                );
            }
            return list;
        },
        [canAddMember]
    );

    const initList = useCallback(
        (groupRosters) => {
            let list = groupRosters;
            if (list.length > 34) {
                list = list.slice(0, 34);
                setHasMore(true);
                setList(list);
            } else {
                list = padList(list);
                setHasMore(false);
                setList(list);
            }
        },
        [padList]
    );

    useEffect(() => {
        // initList(groupRosters);
        setList(groupRosters);
    }, [groupRosters]);

    const showMore = useCallback(() => {
        const list = padList(groupRosters);
        setHasMore(false);
        setList(list);
    }, [groupRosters, padList]);

    // input框enter搜索事件
    const searchName = useCallback(
        (e: any) => {
            const value = e.target.value;
            if (!value) {
                // initList(groupRosters);
                setList(groupRosters);
            }
            const result = groupRosters.filter((s) => {
                const info: any = s.info || {};
                return PinyinMatch.match(info.name || '', value);
            });
            // initList(result);
            setList(result);
        },
        [groupRosters]
    );

    const headPart = useMemo(() => {
        if (groupRosters?.length === 0) {
            return null;
        }

        return (
            <div className="members_head">
                <div className="members">
                    {t('group_members')}({groupRosters.length})
                </div>
                <Input
                    onChange={searchName}
                    onFocus={() => analysisLog('Xtbg_Msg_Setting', 'SearchMember')}
                    placeholder={t('please-enter-account/nickname/remarks')}
                    prefix={<IconFont type="iconic_search" className="search-icon" />}
                />
            </div>
        );
    }, [groupRosters.length, searchName, t]);

    const addMembersPart = useMemo(() => {
        if (!canAddMember) {
            return null;
        }
        if (!list.length) {
            return null;
        }
        return (
            <div className="icon-box">
                <div
                    className="icon-part"
                    onClick={addMembers}
                    clstag="pageclick|keycount|Xtbg_Msg_Setting|AddMember"
                >
                    <IconFont type="iconapp_btn_add2" />
                    <span>{t('add_member')}</span>
                </div>
            </div>
        );
    }, [addMembers, canAddMember, t, list]);

    const getItemClasses = useCallback((item: any) => {
        return classNames({
            independent: true,
            pad: !item.info,
        });
    }, []);

    return (
        <div className="group-members">
            {selectedSession.isGroup && (
                <div className="group-in">
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Loading />
                        </div>
                    ) : (
                        <>
                            {headPart}
                            {addMembersPart}
                            <ScrollView className="members_con scroll-view" nohorizontal="true">
                                {list.map((item, index) => {
                                    return (
                                        <div className={getItemClasses(item)} key={index}>
                                            {item?.info && (
                                                <GroupMemberOperate
                                                    member={item}
                                                    getAllGroupMembers={getAllGroupMembers}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </ScrollView>
                        </>
                    )}
                    {/* {hasMore && (
                        <div className="icon-box">
                            <div className="icon-part more" onClick={showMore}>
                                <IconFont type="iconlog_more" />
                                <span>{t('more')}</span>
                            </div>
                        </div>
                    )} */}
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async updateGroupMembers(data: { groupRosters: any }) {
            await dispatch({ type: 'chat/updateGroupMembers', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers);
