import React from 'react';
import { Button, message } from 'antd';
import {
    createCloudLinkCall,
    createCloudLinkSingleCall,
    createCloudLinkTerminalCall,
} from '../MeetingCloudLink/utils';
import { Avatar } from '@/utils/Avatar';
import { getJoyMeetingStatus, handleCreateGroupMeeting } from '../Meeting/utils';
import { mapGetEbookDeptInfoList } from '../AddressBook/common';
import { SearchType } from '@/services/SearchService';
import ImService from '@/server/ImService';
import IconFont from '../icon';
import { createMeeting, getBigScreenMeetingTopic } from '@/baseComponents/Meeting/utils';
import { AVSDK } from '@/baseComponents/Meeting/common';

export function handleCreateZoomMeeting(dataList: any[]) {
    // const status: CloudLinkStatusEnum = getCloudLinkStatus();
    // console.log(status, 'zoom视频状态');
    // if (status !== CloudLinkStatusEnum.FREE) {
    //     // message.error('加入zoom失败');

    //     return;
    // }

    const users =
        dataList
            .filter((item: any) => item.memberType === 1)
            .map((r: any) => {
                return {
                    realName: r.name,
                    userId: r.userId,
                    teamId: r.teamId,
                    appId: r.appId,
                    headImg: r.avatar as string,
                };
            }) || [];
    handleCreateGroupMeeting(users);
}

export async function handleCreateGroupXCMeeting(dataList: any[], memberType?: number) {
    // getJoyMeetingStatus().then((res: JoyMeetingStatusEnum) => {
    //     console.log(res, '华为视频状态');
    //     if (res === JoyMeetingStatusEnum.None) {
    XCMeeting(dataList, memberType);
    // }
    // else {
    // message.error('');
    // }
    // });
}
export async function handleCreateGroupXYMeeting(dataList: any[], memberType?: number) {
    const users =
        dataList
            .filter((item: any) => item.memberType === 1)
            .map((r: any) => {
                return {
                    name: r.name,
                    pin: r.userId,
                    teamId: r.teamId,
                    app: r.appId,
                    avatar: r.avatar as string,
                };
            }) || [];
    createMeeting(
        AVSDK.XYLINK,
        {
            topic: getBigScreenMeetingTopic(),
        },
        users
    );
}

function XCMeeting(dataList: any[], memberType?: number) {
    if (memberType === 1) {
        createCloudLinkSingleCall({
            ...dataList[0],
            titleName: '',
            userName: dataList[0].fields.filter((item: any) => item.key === 'name')[0].val,
        }).catch((err) => {
            message.error(err.message || err || '会议创建失败');
        });
        return;
    } else if (memberType === 2) {
        createCloudLinkTerminalCall([dataList[0].terminalId]).catch((err) => {
            message.error(err.message || err || '会议创建失败');
        });
        return;
    }

    const users = dataList?.filter((item: any) => item.memberType === 1) || [];
    const terminalIds = dataList?.filter((item: any) => item.memberType === 2) || [];
    const toUsers = users.map((item) => {
        return {
            teamId: item?.teamId,
            userId: item?.userId,
            appId: item?.appId || item?.app,
            userName: item?.fields?.filter((item: any) => item.key === 'name')[0].val,
            avatar: item?.fields?.filter((item: any) => item.key === 'avatar')[0].val,
        };
    });
    const terminals: any[] = terminalIds.map((item: any) => {
        return {
            terminalId: item?.terminalId,
        };
    });
    console.log(toUsers, { terminalId: terminals }, 'terminalsterminalsterminals');
    createCloudLinkCall({
        toUsers: toUsers,
        terminals: { terminalId: terminals },
        opts: {
            meetingTopic: getBigScreenMeetingTopic(),
        },
    }).catch((err) => {
        message.error(err.message || err || '创建会议失败');
    });
}

// eslint-disable-next-line max-params
export function mapUserDetailTarget(
    list: any[],
    currentUserData: any,
    groupList?: any[],
    fullName?: string
) {
    return (
        // eslint-disable-next-line complexity
        list?.map((item: any, index: number) => {
            const userId = item?.userId || item?.terminalId || item?.memberId;
            const name = item?.name || item?.realName || item?.memberName || '';
            const titleName = item.titleName || item.positionName || '';
            const fullDeptName = item.fullDeptName || fullName || item.deptName || '';
            const memberType = item.memberType ? item.memberType : item?.terminalId ? 2 : 1;
            const avatar =
                memberType === 1 ? (
                    item.avatar || ''
                ) : (
                    <Avatar
                        styleObj={{ position: 'relative' }}
                        IconFont={
                            <IconFont type="iconic_pc_dongao_equipment" className="device-icon" />
                        }
                    />
                );
            return {
                ...item,
                userId: item?.userId || item?.terminalId || item?.memberId,
                appId: currentUserData?.app || currentUserData?.appId || '',
                teamId: currentUserData?.teamId || '',
                fields: item?.fields
                    ? item?.fields
                    : [
                          {
                              key: 'name',
                              val: titleName ? titleName + '-' + name : name,
                              hide: 0,
                              index: 2,
                              name: '姓名',
                          },
                          {
                              key: 'avatar',
                              val: item?.avatar || '',
                              hide: 0,
                              index: 0,
                              name: '头像',
                          },
                      ],
                name: name,
                partTimeTitleDeptInfo:
                    item?.partTimeTitleDeptInfo?.length > 0
                        ? item?.partTimeTitleDeptInfo
                        : [
                              {
                                  fullDeptName: fullDeptName,
                                  //   titleName:
                                  //       (item.titleName || item.positionName) === '暂未填写'
                                  //           ? '-'
                                  //           : titleName,
                              },
                          ],
                memberType: memberType,
                groupList: item.groupList || groupList?.[index]?.groupList || [],
                labelInfoList: [],
                searchKey: memberType === 1 ? SearchType.Contacts : SearchType.Device,
                avatar: avatar,
            };
        }) || []
    );
}

export async function setGetEbookGroupList(ids: string[], InfoResList: any, fullName?: string) {
    const imService = ImService.getInstance();
    const currentEmployee = await imService.getCurrentEmployee();
    if (ids.length) {
        const data: any = await mapGetEbookDeptInfoList(ids);

        if (!data || !data.length) {
            return null;
        }
        const ebookUsers = mapUserDetailTarget(InfoResList, currentEmployee, data, fullName);
        // console.log('sxl====>mapUserDetailTarget', ebookUsers);

        return ebookUsers || [];
    }
    return [];
}
