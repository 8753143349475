import { FocusSDK as focusSDK } from '@/utils';
/**
 *
 * 获取web端使用的颜色值
 * @export
 * @param {string} color
 * @returns
 */
export function pickHexColor(color: string) {
    if (color && color.length === 8) {
        return `#${color.slice(2)}`;
    } else if (color && color.length === 6) {
        return `#${color}`;
    }
    return color;
}
export function pickHexColorWithOutPre(color: string) {
    if (color && color.length === 8) {
        return `${color.slice(2)}`;
    } else if (color && color.length === 6) {
        return `${color}`;
    }
    return color;
}
// todo 需要收集确定不替换的网站规则
function isNotReplaceUrlList(url: string) {
    const list = ['jzb.beijing.egov.cn/admin', 'jzb.beijing.egov.cn/client'];
    let findItem = list.find((item) => {
        return url.indexOf(item) > -1;
    });
    return !!findItem;
}

// 根据host切换不同的url host
export function changeEgovUrl(urlParam: any) {
    if (typeof urlParam !== 'string') {
        return urlParam;
    }
    let url = urlParam;
    let currentHost = focusSDK.getMeMainEntry();
    // console.log('currentHostcurrentHost', currentHost);
    if (process.env.REACT_APP_GOV) {
        url = url.replace(
            'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
            process.env.REACT_APP_DUCC_DOMAIN_URL || ''
        );
        return url;
    }

    // 非政务外网下
    if (currentHost.indexOf('beijing.egov.cn') === -1) {
        // gov环境下将egov的切换为gov
        if (currentHost.indexOf('beijing.gov.cn') > -1 && !isNotReplaceUrlList(url)) {
            url = url.replace('beijing.egov.cn', 'beijing.gov.cn');
        }
        return url;
    }

    let egovAddressMap = {
        'eefs.jzb.beijing.gov.cn': 'eefs.jzb.beijing.egov.cn',
        'jzb-eefs.jdcloud.com': 'eefs.jzb.beijing.egov.cn',
        'oss.jzb.beijing.gov.cn': 'oss.jzb.beijing.egov.cn',
        'jzb-oss.jdcloud.com': 'oss.jzb.beijing.egov.cn',
        'joyspace.jzb.beijing.gov.cn': 'joyspace.jzb.beijing.egov.cn',
        'jzb-joyspace.jdcloud.com': 'joyspace.jzb.beijing.egov.cn',
        'apijoyspace.jzb.beijing.gov.cn': 'apijoyspace.jzb.beijing.egov.cn',
        'jzb-apijoyspace.jdcloud.com': 'apijoyspace.jzb.beijing.egov.cn',
        'jzb.beijing.gov.cn': 'jzb.beijing.egov.cn',
        'jzb.jdcloud.com': 'jzb.beijing.egov.cn',
        'wps.jzb.beijing.gov.cn': 'wps.jzb.beijing.egov.cn',
        'app.jzb.beijing.gov.cn': 'app.jzb.beijing.egov.cn',
        'eepaas-public.s3.cn-north-1.jdcloud-oss.com': 'oss.jzb.beijing.egov.cn',
        'focus-desktop.s3.cn-north-1.jdcloud-oss.com': 'oss.jzb.beijing.egov.cn/focus-desktop',
        's3.cn-north-1.jdcloud-oss.com': 'oss.jzb.beijing.egov.cn',
    } as any;
    if (url) {
        try {
            let baseUrl = url.indexOf('http') !== 0 ? window.location?.origin : undefined;
            let newUrl = new URL(url, baseUrl);
            if (newUrl) {
                let hostname = newUrl.hostname;
                let newHost = egovAddressMap[hostname];
                if (newHost) {
                    url = url.replace(hostname, newHost);
                }
            }
        } catch (e) {
            console.log('切换政务外网地址出异常', url, e);
            return url;
        }
    }
    return url;
}

export function changeGovUrl(urlParam: any) {
    if (typeof urlParam !== 'string') {
        return urlParam;
    }
    let url = urlParam;
    let currentHost = focusSDK.getMeMainEntry();
    // 非政务外网下
    if (currentHost.indexOf('beijing.egov.cn') === -1) {
        return url;
    }
    let govAddressMap = {
        'eefs.jzb.beijing.egov.cn': 'eefs.jzb.beijing.gov.cn',
        'oss.jzb.beijing.egov.cn': 'oss.jzb.beijing.gov.cn',
        'joyspace.jzb.beijing.egov.cn': 'joyspace.jzb.beijing.gov.cn',
        'jzb.beijing.egov.cn': 'jzb.beijing.gov.cn',
        'app.jzb.beijing.egov.cn': 'app.jzb.beijing.gov.cn',
    } as any;
    if (url) {
        try {
            let baseUrl = url.indexOf('http') !== 0 ? window.location?.origin : undefined;
            let newUrl = new URL(url, baseUrl);
            if (newUrl) {
                let hostname = newUrl.hostname;
                let newHost = govAddressMap[hostname];
                if (newHost) {
                    url = url.replace(hostname, newHost);
                }
            }
        } catch (e) {
            console.log('切换互联网地址出异常', url, e);
            return url;
        }
    }
    return url;
}

// 将HTML转义为实体 (& —— 和号，其实体名称为：【&amp;】  其实体编号为：【&#38;】)
export function htmlEscape(html: string): string {
    const elem = document.createElement('div');
    const txt = document.createTextNode(html);
    elem.appendChild(txt);
    return elem.innerHTML;
}

// 将实体转回为HTML
export function htmlUnescape(str: string): string {
    const elem = document.createElement('div');
    elem.innerHTML = str;
    return elem.innerText || elem.textContent || '';
}

// http卡片链接url判断
export function judgeHttpCardLinkUrl(url: string) {
    const reg1 = /jzb\.beijing\.e?gov\.cn\/client/;
    const reg2 = /jzb([\s\S]*)\.jdcloud.com\/client/;
    const list = [
        'https://jzb.beijing.gov.cn',
        'https://jzb.beijing.egov.cn',
        'http://jzb.beijing.gov.cn',
        'http://jzb.beijing.egov.cn',
        'https://xtbg-saas-test.jdcloud.com',
        'http://xtbg-saas-test.jdcloud.com',
        'https://xtbg-saas.jdcloud.com',
        'http://xtbg-saas.jdcloud.com',
    ];
    // eslint-disable-next-line no-console
    console.log('location', process.env, url, list, location.host);
    if (reg1.test(url) || reg2.test(url)) {
        return false;
    }
    const target = list.find((item: string) => url.includes(item));
    if (target) {
        return false;
    }
    return true;
}
