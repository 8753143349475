/**
 * 单人呼叫页面
 * @author zhangpengcheng15
 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import AudioMp3 from '@/assets/ringoutdou.mp3';
import { transReceiveData, isSameUser, handleMeetingSendMessage } from '../utils';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { FocusSDK, JoyMeetingStatusEnum } from '@/utils';
import { useTranslation } from 'react-i18next';
import { ZoomMeetingStatus } from '@/types/common';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';
import { getUserSimple } from '@/api/meeting';
import { changeEgovUrl } from '@/utils/tools';

enum ZoomSessionStateEnum {
    Call = 0, // 被叫
    Cancel = 1, // 呼叫方取消
    Ringing = 180, // 用户振铃 这个是反向发送消息
    UserReceive = 200, // 某个端用户接受了会邀
    UserReject = 486, // 某个端用户拒绝
}

interface CreateMeetingResult {
    meetingId: string;
    uuid: string;
    topic: string;
    startTime: string;
    duration: number;
    password: string;
    joinUrl: string;
}

export interface JoinMeetingResult {
    meetingNum: string;
    meetingPass: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
    toUser: any;
    meetingData: CreateMeetingResult | null;
}

export default function MeetingCallModal() {
    const [visible, setVisible] = useState(false);
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo = {} as any, imService, currentEmployee } = useContext(GlobalContext);
    const { teamUserInfo, selectedTeamId, userId } = authInfo;
    const [t_common] = useTranslation('common');
    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
        toUser: null,
        meetingData: null,
    });
    const ref = useRef<any>(null);

    useEffect(() => {
        async function sendCallMessage(opts: {
            meetingID: string;
            meetingTopic: string;
            toUser: any;
            meetingNumber: string;
            meetingPassword?: string;
            content: string;
        }) {
            const fromUser = {
                app: teamUserInfo.ddAppId,
                pin: userId,
                teamId: selectedTeamId,
                name: currentEmployee.name,
                avatar: teamUserInfo.headImg,
            };
            try {
                const [result, err] = await getUserSimple({
                    userId,
                    teamId: fromUser.teamId,
                    appId: fromUser.app,
                });
                if (result) {
                    Object.assign(fromUser, {
                        titleName: result?.titleName || '',
                        superiorDeptName: result?.superiorDeptName || '',
                        deptName: result?.deptName || '',
                    });
                }
            } catch (error) {}

            const body = {
                ext: {
                    fromUser,
                    displayName: 'displayName',
                    projectType: 0,
                    avsdk: 'zoom',
                    enableVideo: 0,
                    meetingID: opts.meetingID,
                    meetingTopic: opts.meetingTopic,
                    meetingDuration: 120,
                    meetingNumber: opts.meetingNumber.toString(),
                    enableMicphone: 0,
                    meetingPassword: opts.meetingPassword || '',
                    sessionState: 0,
                    sessionType: 0,
                    startTime: new Date().getTime().toString(),
                },
                flag: 100,
                state: 0,
                noPush: 0,
                type: 'sys',
                content: opts.content,
            };
            ref.current.sendMessage = body;
            const { ddAppId, teamId } = teamUserInfo;
            handleMeetingSendMessage({
                imService,
                currentEmployee: { app: ddAppId, userId, teamId },
                data: {
                    toUsers: [opts.toUser],
                    body,
                },
                t: t_common,
            });
        }

        /**
         * 处理所有的meeting相关消息
         * @param _event
         * @param args
         */
        function handleChatMeetingMessage(data: any) {
            // 没有会议信息时，不处理所有逻辑
            if (!ref.current) {
                return;
            }
            let { body, to } = data;
            const { ext = {} } = body || data; // 应用的会邀和聊天的会邀结构不一致。应用的会邀没有body
            const { sessionState } = ext;
            if (sessionState === ZoomSessionStateEnum.Call) {
                return;
            }
            const rece = transReceiveData(data);
            const { toUser, meetingData, videoDisable, audioDisable } = ref.current;
            // 如果消息的发送方
            if (
                isSameUser(rece.fromUser, toUser) &&
                rece.sessionState === ZoomSessionStateEnum.UserReceive
            ) {
                FocusSDK.on('zoom.meetingStatusCb', handleMeetingStatusCb);
                FocusSDK.sendIpcMessage('zoom.joinUnlogin', {
                    meetingnum: meetingData.meetingId,
                    psw: meetingData.password,
                    username: currentEmployee.name,
                    isvideooff: videoDisable,
                    isaudiooff: audioDisable,
                });
            }
            if (
                isSameUser(rece.fromUser, toUser) &&
                rece.sessionState === ZoomSessionStateEnum.UserReject
            ) {
                message.info(t_common('joinMeeting.user reject'));
                removeListener();
                FocusSDK.sendIpcMessage(
                    'MeetingControl:changeJoyMeetingStatus',
                    JoyMeetingStatusEnum.None
                );
                closeModal();
            }
        }

        function handleInitCb(result: string) {
            if (result !== 'success') {
                if (result === '14') {
                    message.error(
                        t_common('joinMeeting.The other instance of the SDK is in process')
                    );
                } else {
                    message.error(t_common('joinMeeting.meeting initialization failure'));
                }
                removeListener();
                closeModal();
                return;
            }
            const { toUser, meetingData } = ref.current;
            if (!ref.current.visible) {
                // 如果此时visible不为true，代表modal已经由用户主动挂断关闭
                removeListener();
                return;
            }
            sendCallMessage({
                meetingNumber: meetingData.meetingId,
                toUser,
                meetingTopic: meetingData.topic,
                meetingID: meetingData.uuid,
                content: meetingData.topic,
            });

            FocusSDK.on('meeting:single call message', handleChatMeetingMessage);
        }

        /**
         * 处理一下加入会议的回调
         * 连接中 显示一下loading组件
         * 失败则弹出错误提示，并重置一些状态
         * 连接成功 关闭当前modal
         * @param event
         * @param status
         */
        function handleMeetingStatusCb(status: number) {
            switch (status) {
                case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                    closeModal();
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_WAITINGFORHOST:
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_INMEETING:
                    removeListener();
                    break;
                // case ZoomMeetingStatus.MEETING_STATUS_CONNECTING:
                //   setConnect(true);
                //   break;
                case ZoomMeetingStatus.MEETING_STATUS_DISCONNECTING:
                case ZoomMeetingStatus.MEETING_STATUS_FAILED:
                    message.error(t_common('joinMeeting.meeting connect failed'));
                    removeListener();
                    break;
                case ZoomMeetingStatus.MEETING_STATUS_ENDED:
                    message.error(t_common('joinMeeting.meeting has already ended'));
                    removeListener();
                    break;
                default:
                    message.error(t_common('joinMeeting.meeting connect failed'));
                    removeListener();
                    break;
            }
        }

        /**
         * 成功或失败后，移除监听函数
         */
        function removeListener() {
            // bus.off('meeting:create single call', handleMeetingCreate);
            FocusSDK.off('zoom.initCb', handleInitCb);
            FocusSDK.removeAllListeners('zoom.meetingStatusCb');
            // FocusSDK.removeAllListeners('meeting:single call message');
        }

        async function handleMeetingCreate(data: {
            toUser: any;
            meetingData: CreateMeetingResult;
        }) {
            if (ref.current?.visible) {
                return;
            }
            // 只有状态设置成功才会请求创建会议，然后才唤起本窗口，所以当前不需要设置joyMeetingStatus
            // 初始化失败或者加入会议失败都由zoomControl来变更joyMeetingStatus
            // 只有对方拒绝时，本页面才重置joyMeetingStatus状态
            const { toUser, meetingData } = data;
            ref.current = {
                ...ref.current,
                toUser,
                meetingData,
                audioDisable: true,
                videoDisable: true,
            };
            setState((currentState: any) => {
                return {
                    ...currentState,
                    toUser,
                    meetingData,
                };
            });
            setVisible(true);
            ref.current.visible = true; // 在这里存储一下，因为闭包原因，当前函数内state为点击函数时的快照
            FocusSDK.once('zoom.initCb', handleInitCb);
            FocusSDK.sendIpcMessage('zoom.initSdk');
        }
        removeListener();
        bus.off('meeting:create single call', handleMeetingCreate);
        bus.on('meeting:create single call', handleMeetingCreate);
        return () => {
            removeListener();
            bus.off('meeting:create single call', handleMeetingCreate);
            FocusSDK.removeAllListeners('meeting:single call message');
        };
    }, [currentEmployee, imService, selectedTeamId, t_common, teamUserInfo, userId]);

    function closeModal() {
        setVisible(false);
        ref.current = null;
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
            toUser: null,
            meetingData: null,
        });
    }
    function handleMenuClick(key: string) {
        if (key === 'audio') {
            ref.current = {
                ...ref.current,
                audioDisable: !state.audioDisable,
            };
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            ref.current = {
                ...ref.current,
                videoDisable: !state.videoDisable,
            };
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
    }

    function onCancel() {
        FocusSDK.sendIpcMessage('MeetingControl:changeJoyMeetingStatus', JoyMeetingStatusEnum.None);
        // 如果已经发出呼叫消息，关闭的时候发起取消消息
        if (ref.current.sendMessage) {
            ref.current.sendMessage.ext.sessionState = 1;
            const { ddAppId, teamId } = teamUserInfo;
            handleMeetingSendMessage({
                imService,
                currentEmployee: { app: ddAppId, userId, teamId },
                data: {
                    toUsers: [ref.current.toUser],
                    body: {
                        ...ref.current.sendMessage,
                    },
                },
                t: t_common,
            });
        }
        FocusSDK.removeAllListeners('meeting:single call message');
        closeModal();
    }
    if (!state.toUser) {
        return null;
    }
    const { avatar, username: name } = state.toUser;

    return (
        <Modal
            className="desk-me-meeting-call-modal"
            width={780}
            visible={visible}
            maskClosable={false}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            destroyOnClose
            footer={null}
        >
            <div className="me-meeting-call-body">
                <div className="me-meeting-call-container">
                    <div className="avatar-outter">
                        <div className="avatar-inner">
                            <Avatar
                                className="meeting-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name || ''}
                                styleObj={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    lineHeight: 80,
                                    color: '#fff',
                                    fontSize: 22,
                                }}
                            />
                        </div>
                    </div>
                    <div className="meeting-call-name">{name}</div>
                    <div className="meeting-call-desc">{t_common('joinMeeting.connecting')}</div>
                </div>
                <div className="me-meeting-call-actions">
                    <div className="me-meeting-call-menu">
                        <div className="me-meeting-call-menu-container">
                            <span
                                className={`me-meeting-call-menu-item
              ${state.audioDisable ? 'me-meeting-call-menu-item-disable' : ''}`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>

                        <div className="me-meeting-call-menu-container">
                            <span
                                className="me-meeting-call-menu-item me-meeting-call-menu-close"
                                onClick={onCancel}
                            >
                                <IconFont type="iconapp_btn_joymerting_hangup" />
                            </span>
                            <span className="label">{t_common('joinMeeting.Hang up')}</span>
                        </div>

                        <div className="me-meeting-call-menu-container">
                            <span
                                className={`me-meeting-call-menu-item
              ${state.videoDisable ? 'me-meeting-call-menu-item-disable' : ''}
              `}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <audio src={changeEgovUrl(AudioMp3)} loop autoPlay />
            </div>
        </Modal>
    );
}
