import React from 'react';
import { message } from 'antd';
import Icon from '../../../Icon';
import conf from './conf';
import { Project, ProjectRole } from '../../../types';
import Main from './Main';
import * as api from '../../../api';
import './index.less';
import { useTabs } from '../../../components/Tabs';

interface Props {
    project: Project;
    onChange: (project: Project) => void;
}

type Status = typeof conf.status[0];

export default function (props: Props) {
    const { project, onChange } = props;
    const [progress, setProgress] = React.useState<string>('1');
    const [show, setShow] = React.useState(false);
    const status = getStatus(progress);
    const readonly =
        project.archive ||
        (project?.user?.role !== ProjectRole.ADMIN && project?.user?.role !== ProjectRole.OWNER);
    const tabs = useTabs();

    const onOk = React.useCallback(
        (progress, progressDesc, close) => {
            api.updateProject(project.id, {
                progressDesc,
                progress,
            }).then((data) => {
                if (data.errCode) return;
                message.success('修改成功');
                const _project = {
                    ...project,
                    progress,
                    progressDesc,
                };
                onChange(_project);
                tabs.setProps({
                    id: project.id,
                    progressDesc: progressDesc,
                    progress: progress,
                });
                close();
                setShow(false);
            });
        },
        [onChange, project, tabs]
    );

    React.useEffect(() => {
        setProgress(String(project.progress) ?? '1');
    }, [project.progress]);

    return (
        <>
            <span
                className="pm-status"
                style={{ color: status.color, backgroundColor: status.bgColor }}
                onClick={() => {
                    setShow(true);
                }}
            >
                {status.text}
                <Icon type="iconicon_down" />
            </span>
            {show && (
                <Main
                    progress={progress}
                    desc={project.progressDesc}
                    onClose={() => setShow(false)}
                    readonly={readonly}
                    onOk={onOk}
                />
            )}
        </>
    );
}

export function getStatus(id: string): Status {
    let status: Status | undefined;
    const items = conf.status.slice();
    while ((status = items.shift())) {
        if (status.id === id) {
            return status;
        }
    }
    return conf.status[0];
}
