import React, { useCallback, useEffect, useState } from 'react';
import { Button, List, message, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'dvajs';
import { debounce, trim as lodashTrim } from 'lodash';
import IconFont, { IconSearch } from '../../../icon';
import './index.less';
import { createLabel } from '@/api/ebook';
import { LabelInfoRes } from '@/components/AddressBook/common';

function AddUserLabelNameModel({
    addUserLabelNameModelVisible,
    openCreateAddUserLabelNameModel,
    addUserLabelNameInputValue,
    setAddUserLabelNameInputValue,
    labelsList,
    setUserLabel,
    labelNameUserDetail,
    userLabel,
}: {
    addUserLabelNameModelVisible: boolean;
    openCreateAddUserLabelNameModel: Function;
    addUserLabelNameInputValue: string;
    setAddUserLabelNameInputValue: Function;
    labelsList: LabelInfoRes[];
    setUserLabel: Function;
    labelNameUserDetail: any;
    userLabel: any;
}) {
    const { t } = useTranslation('addressList');
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(addUserLabelNameInputValue);
    const [contentTips, setContentTips] = useState(false);

    function cancelHandler() {
        openCreateAddUserLabelNameModel(false);
    }
    useEffect(() => {
        if (!addUserLabelNameModelVisible) {
            setSearchValue('');
        }
        if (searchValue) {
            setAddUserLabelNameInputValue('');
        }
    }, [addUserLabelNameModelVisible, searchValue, setAddUserLabelNameInputValue]);

    const handleSearch = useCallback(async () => {
        if (!searchValue) {
            return;
        }
        setLoading(true);
        const [data, err]: any = await createLabel({
            labelName: searchValue,
            labelEmplPojoList: [
                {
                    userId: labelNameUserDetail?.userId,
                    userName:
                        labelNameUserDetail?.fields?.filter((item: any) => item.key === 'name')[0]
                            .val || '',
                },
            ],
        });
        // console.log(data, userLabel, 87777);
        if (err) {
            message.error(err as string);
            setLoading(false);
            return;
        }
        setLoading(false);
        message.success(t('label.create label success'));
        setUserLabel([...userLabel, data]);
        cancelHandler();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (addUserLabelNameInputValue) {
            setSearchValue(addUserLabelNameInputValue);
        }
    }, [addUserLabelNameInputValue]);

    return (
        <Modal
            title={t('label.new_label')}
            width="480px"
            bodyStyle={{ width: 480, maxHeight: 340, padding: 24, minHeight: 46 }}
            visible={addUserLabelNameModelVisible}
            footer={null}
            onCancel={cancelHandler}
            centered={true}
            zIndex={1000}
        >
            <div className="addLabelName-wrap" style={{ height: '72px' }}>
                <div className="addLabelNameSearchInput ">
                    <div className="search-labelName-container-input">
                        <Input
                            prefix={
                                <IconFont type="iconapp_ic_search" style={{ color: '#848484' }} />
                            }
                            placeholder={t('label.create label err name')}
                            className="input-box"
                            value={searchValue}
                            allowClear
                            onChange={(e) => {
                                setSearchValue(lodashTrim(e.target.value));
                            }}
                            maxLength={100}
                            onPressEnter={handleSearch}
                            onBlur={(e) => {
                                const indx = labelsList.findIndex(
                                    (item) => item.labelName === e.target.value
                                );
                                if (indx > -1) {
                                    setContentTips(true);
                                } else {
                                    setContentTips(false);
                                }
                            }}
                        />
                    </div>
                    <Button
                        className="addLabelName-btn"
                        type="primary"
                        loading={loading}
                        onClick={handleSearch}
                        disabled={!searchValue}
                    >
                        {t('label.button_confirm')}
                    </Button>
                </div>
                <p className="content-tips">{contentTips && t('label.name_tips')}</p>
            </div>
        </Modal>
    );
}
function mapStateToProps({ ebook }: any) {
    return {
        addUserLabelNameModelVisible: ebook.label.addUserLabelNameModelVisible,
        addUserLabelNameInputValue: ebook.label.addUserLabelNameInputValue,
        labelsList: ebook.label.labelsList,
        labelNameUserDetail: ebook.label.labelNameUserDetail,
        userLabel: ebook.label.userLabel,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateAddUserLabelNameModel(data: any) {
            dispatch({ type: 'ebook/openCreateAddUserLabelNameModel', payload: data });
        },
        setAddUserLabelNameInputValue(data: any) {
            dispatch({ type: 'ebook/setAddUserLabelNameInputValue', payload: data });
        },
        setUserLabel(data: any) {
            dispatch({ type: 'ebook/setUserLabel', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserLabelNameModel);
