import { Button, Input, List, message as toast, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'dvajs';
import { useTranslation } from 'react-i18next';
import { EbookState } from '@/models/ebook';
import { getPageExternalContactsApprInfoReq } from '@/api/ebook';
import { ApprExternalContacts, PageExternalContactsApprInfoReqParam } from '@/types/chat/State';
import './index.less';
import { ExternalContactsStatus } from '@/types/chat/enum';
import { setExternalContactsTipsTool } from '@/utils/chat/index';
import { changeEgovUrl } from '@/utils/tools';
import { Employee, Session } from '@/types/chat';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateApprExternalContacts } from '@/api/chat';
import ImService from '@/server/ImService';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { debounce } from 'lodash';

const { TextArea } = Input;

function RefusalCause({
    openRefusalCauseModal,
    setOpenRefusalCauseModal,
}: {
    openRefusalCauseModal: { visible: boolean; message: any; selectedSession: any };
    setOpenRefusalCauseModal: Function;
    message: any;
}) {
    const [t] = useTranslation('chat');
    const [regularRefusalCause, setRegularRefusalCause] = useState<string>('');
    // const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const onOk = async () => {
        const param: ApprExternalContacts = {
            apprId: openRefusalCauseModal.message.infox.apprId, // 审核ID不能为空
            apprStatus: 2, // 审核状态不能为空 1-审核成功 2-审核失败
            apprOpinion: regularRefusalCause, // 审核意见最大支持100个长度
        };
        const [result, error] = await updateApprExternalContacts(param);

        if (error) {
            toast.error(error);
            return;
        }
        const imService = ImService.getInstance();
        let updateResult = await imService.updateApplyExternalContactsList(
            openRefusalCauseModal?.selectedSession?.sessionId,
            openRefusalCauseModal?.message,
            result
        );
        console.log(result, updateResult, 666);

        setExternalContactsTipsTool(result, t);
        handleClose();
    };

    const handleClose = () => {
        setRegularRefusalCause('');
        setOpenRefusalCauseModal({ visible: false, message: {} });
    };

    const onChangeRefusalCause = (e: any) => {
        setRegularRefusalCause(e.target.value || '');
    };

    return (
        <Modal
            title="驳回原因"
            centered={true}
            visible={openRefusalCauseModal.visible}
            onCancel={() => {
                handleClose();
            }}
            footer={
                <>
                    <Button
                        size="middle"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t('cancelText')}
                    </Button>
                    <Button
                        type="primary"
                        size="middle"
                        loading={loading}
                        onClick={debounce(() => {
                            onOk();
                        }, 500)}
                    >
                        {t('confirm')}
                    </Button>
                </>
            }
        >
            <div className="regular-refusal-cause">
                <TextArea
                    placeholder={t('请输入驳回原因')}
                    rows={4}
                    value={regularRefusalCause}
                    onChange={onChangeRefusalCause}
                    maxLength={100}
                />
                <p className="refusal-cause-length">
                    <span>{regularRefusalCause.length || 0}</span>
                    /100
                </p>
            </div>
        </Modal>
    );
}

function mapStateToProps({ ebook }: { ebook: EbookState }) {
    return { openRefusalCauseModal: ebook.openRefusalCauseModal };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setOpenRefusalCauseModal(payload: boolean) {
            dispatch({ type: 'ebook/setOpenRefusalCauseModal', payload });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RefusalCause);
