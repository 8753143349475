import BaseService from './BaseService';

class EUserService extends BaseService {
    SaveUserSetting(params: { settingCode: string; settingContent: string }) {
        const { appId } = this.appRuntime.gateway;
        return this.fetch<any>('euser', 'saveUserSetting', { appId, ...params });
    }
}

export default EUserService;
