import React, {
    useMemo,
    useState,
    useCallback,
    useImperativeHandle,
    forwardRef,
    useEffect,
} from 'react';
import { Select, message, Modal } from 'antd';
import Avatar from '../Avatar';
import Icon from '../../Icon';
import {
    getJoySpaceDelPermission,
    getJoySpaceReadAllPermission,
    getJoySpaceReadNothingPermission,
    getJoySpaceReadOnlyPermission,
    getJoySpaceReadWritePermission,
    joyspacePermissionType,
} from '../../config/permissions.config';
import {
    getFolderMenus,
    getFolderAllMenus,
    SelectType,
    getPageMenusAll,
    getPageMenus,
} from './config';
import './index.less';
import { FileType } from '../../types';
const { Option } = Select;
interface IProps {
    type?: FileType; // 判断是文件还是文件夹
    permissionType: joyspacePermissionType;
    onClick?: Function;
    onChange?: Function;
    onlyText?: Boolean; // 是否为全部权限设置
    isDisableAll?: Boolean; // 是否为全部权限设置
    isCheckAll?: Boolean; // 是否为全部权限设置
    propsValue?: string;
    isOnlyText?: Boolean;
    isShowDelMenu?: Boolean;
}

const PermissionSelect = (props: IProps) => {
    const {
        type = FileType.Page, // 判断是文件还是文件夹
        permissionType = getJoySpaceReadOnlyPermission(),
        isDisableAll = false,
        onClick,
        onChange,
        propsValue,
        isCheckAll = false,
        isOnlyText,
        isShowDelMenu = false,
    } = props;
    // console.log('propsValluepropsValluepropsValluepropsVallue', isOnlyText);
    const [value, setValue] = useState(propsValue);
    const [onlyText, setOnlyText] = useState(isOnlyText ?? false);
    const [menus, setMenus] = useState([]);
    const getDelMenu = useCallback(() => {
        if (isShowDelMenu) {
            return {
                title: '删除',
                tip:
                    type === FileType.Page
                        ? '取消该协作者的文档权限'
                        : '取消该协作者的文档权限和文件夹权限',
                permissions: getJoySpaceDelPermission(),
            };
        }
    }, [isShowDelMenu, type]);
    useEffect(() => {
        let useMenus: any[] = [];

        if (isCheckAll && type === FileType.Folder) {
            useMenus = getFolderAllMenus();
        }
        if (!isCheckAll && type === FileType.Folder) {
            useMenus = getFolderMenus();
        }
        if (isCheckAll && type === FileType.Page) {
            useMenus = getPageMenusAll();
        }
        if (!isCheckAll && type === FileType.Page) {
            useMenus = getPageMenus();
        }
        useMenus = useMenus.filter((item) => {
            if (item.permissions < permissionType) {
                return false;
            }
            return true;
        });
        // 增加移除按钮
        if (!isCheckAll && isShowDelMenu && permissionType <= Number(value)) {
            useMenus.push(getDelMenu());
        }
        // console.log(useMenus, 'useMenususeMenususeMenususeMenus');
        // @ts-ignore
        setMenus(useMenus);
    }, [isCheckAll, type, permissionType, getDelMenu, value, isShowDelMenu]);
    const getPermissionText = useCallback(
        (permission) => {
            for (const item of menus) {
                if (permission === (item as any).permissions) {
                    return (item as any)?.title;
                }
            }
            if (permission === getJoySpaceReadWritePermission()) {
                return '可编辑';
            }
            if (permission === getJoySpaceReadAllPermission()) {
                return '所有者';
            }
            return;
        },
        [menus]
    );
    console.log(isOnlyText, '============================');
    useEffect(() => {
        isOnlyText !== undefined && setOnlyText(isOnlyText);
    }, [isOnlyText]);
    useEffect(() => {
        if (permissionType === getJoySpaceReadOnlyPermission()) {
            setOnlyText(true);
        }
    }, [permissionType]);
    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    const onChangeHandle = useCallback(
        (values: any) => {
            onChange?.(values);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange, setValue]
    );
    // console.log(onlyText, isCheckAll, 'isCheckAll=====>');
    if (onlyText && isCheckAll) {
        return (
            <Select
                size="small"
                value={value}
                defaultValue="权限统一设置"
                dropdownMatchSelectWidth={false}
                // onChange={(values) => {
                //     onChangeHandle?.(values);
                // }}
                onSelect={(value) => {
                    // onChangeHandle?.(value);
                }}
                className="joyspace-permission-select"
            >
                {(menus as any[]).map((_item, _index) => {
                    return (
                        <Option
                            key={_index}
                            value={_item.permissions}
                            className="joyspace-permission-select-option"
                        >
                            {_item.title}
                            <p className="permission-select-tip">{_item.tip}</p>
                        </Option>
                    );
                })}
            </Select>
        );
    }
    // console.log(onlyText, 'onlyTextonlyTextonlyTextonlyTextonlyText');
    if (
        onlyText ||
        permissionType === getJoySpaceReadOnlyPermission() ||
        permissionType === getJoySpaceReadNothingPermission()
    ) {
        return (
            <span className="joyspace-select-only-text">
                {
                    // @ts-ignore
                    getPermissionText(propsValue ?? permissionType)
                }
            </span>
        );
    }
    // console.log(menus, 'menusmenusmenusmenusmenus');
    if (typeof propsValue !== 'undefined') {
        return (
            <Select
                size="small"
                value={value}
                defaultValue="权限统一设置"
                dropdownMatchSelectWidth={false}
                // onChange={(values) => {
                //     onChangeHandle?.(values);
                // }}
                onSelect={(value) => {
                    onChangeHandle?.(value);
                }}
                className="joyspace-permission-select"
            >
                {(menus as any[]).map((_item, _index) => {
                    if (
                        isDisableAll &&
                        !isCheckAll &&
                        _item.permissions === getJoySpaceReadAllPermission()
                    ) {
                        return null;
                    }
                    return (
                        <Option
                            key={_index}
                            value={_item.permissions}
                            disabled={
                                isDisableAll && _item.permissions === getJoySpaceReadAllPermission()
                                    ? true
                                    : false
                            }
                            className="joyspace-permission-select-option"
                        >
                            {_item.title}
                            <p className="permission-select-tip">{_item.tip}</p>
                        </Option>
                    );
                })}
            </Select>
        );
    }
    return (
        <Select
            size="small"
            defaultValue="权限统一设置"
            dropdownMatchSelectWidth={false}
            onSelect={(value) => {
                onChangeHandle?.(value);
            }}
            className="joyspace-permission-select"
        >
            {(menus as any[]).map((_item, _index) => {
                if (
                    isDisableAll &&
                    !isCheckAll &&
                    _item.permissions === getJoySpaceReadAllPermission()
                ) {
                    return null;
                }
                return (
                    <Option
                        key={_index}
                        value={_item.permissions}
                        disabled={
                            isDisableAll && _item.permissions === getJoySpaceReadAllPermission()
                                ? true
                                : false
                        }
                        className="joyspace-permission-select-option"
                    >
                        {_item.title}
                        <p className="permission-select-tip">{_item.tip}</p>
                    </Option>
                );
            })}
        </Select>
    );
};
export default PermissionSelect;
