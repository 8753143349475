import { request } from '@/components/Joyspace/api/request';
import * as shortcuts from './shortcuts';
import { BusinessType } from '../const';

export const shortcut = {
    add: (id: string) => {
        return shortcuts.add(id, BusinessType.Folder);
    },
    remove: (id: string) => {
        return shortcuts.remove(id, BusinessType.Folder);
    },
};

export function rename(id: string, value: string, isTeam?: boolean) {
    // if (isTeam) {
    //     return request({
    //         api: 'joyspace.spaceCategory.update',
    //         path: `/v1/categories/${id}`,
    //         method: 'PUT',
    //         body: {
    //             name: value,
    //         },
    //     });
    // }
    return request({
        api: 'joyspace.folder.rename',
        path: `/v1/folders/${id}/rename`,
        method: 'PUT',
        body: {
            name: value,
        },
    });
}

export function remove(id: string, isTeam?: boolean) {
    // 去掉共享走不同接口
    // if (isTeam) {
    //     return request({
    //         api: 'joyspace.spaceCategory.remove',
    //         path: `/v1/categories/${id}`,
    //         method: 'DELETE',
    //     });
    // }
    return request({
        api: 'joyspace.folder.remove',
        path: `/v1/folders/${id}`,
        method: 'DELETE',
    })
        .then((res) => res)
        .catch((err) => {
            throw err;
        });
}

// 创建文件夹
export function create(id: string, name: string, description?: string) {
    // 去掉共享走不同接口增加描述
    // if (isTeam) {
    //     return request({
    //         api: 'joyspace.spaceCategory.create',
    //         path: `/v1/spaces/${id}/categories`,
    //         method: 'POST',
    //         body: {
    //             name,
    //         },
    //     });
    // }
    return request({
        api: 'joyspace.folder.create',
        path: `/v1/folders`,
        method: 'POST',
        body: {
            name,
            parent: id,
            description,
        },
    });
}

export function move(targetId: string, toId: string) {
    return request({
        api: 'joyspace.folder.moveTo',
        path: `/v1/folders/${targetId}/moveTo`,
        method: 'POST',
        body: {
            folderId: toId || '',
        },
    });
}

export function info(id: string) {
    return request({
        api: 'joyspace.folder.get',
        path: `/v1/folders/${id}`,
    });
}

export function paths(categoryId: string) {
    return request({
        api: 'joyspace.spaceCategory.get',
        path: `/v1/categories/${categoryId}`,
    });
}
// 设置文件夹
export function setFolderApi(folderId: string, name: string, description?: string) {
    return request({
        api: 'joyspace.folder.update',
        path: `/v1/folders/${folderId}/update`,
        method: 'PUT',
        body: {
            name,
            description: description || '',
        },
    });
}
