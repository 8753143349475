/**
 * 文件消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { Dropdown, Menu, message as toast, Popover, Progress, Tooltip } from 'antd';
import { useTranslation, WithTranslation } from 'react-i18next';
import { Employee, FileChatMessage, FileStatus } from '@/types/chat';
import { changeEgovUrl } from '@/utils/tools';
import {
    downLoadFile,
    formatFileName,
    getFileIconByMime,
    getFileName,
    getMessageFileName,
    checkDownloadFilePathValid,
    getFileFilter,
    getFileUrl,
    sizeToStr,
    downloadMessageFile,
} from '@/utils/chat/index';
import { MessageDateFormType, MessageSendType } from '@/types/chat/enum';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { lookup } from 'mime-types';
import { DownloadItem, FocusSDK, isFocusEnv } from '@/utils';
import ImService from '@/server/ImService';
import { getMessageDateFormat } from '@/utils/date';
import { formatMatchContext } from '../../MessageHistorySearch/utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { addFileDownloadRecord } from '@/api/chat';
import { isEqualEmployee } from '@/utils/chat/message';
import { handleAddFileDownloadRecord } from '@/utils/chat/commonMessageModal';
import { SaveOption } from '@/utils/DownloadFileQueue';

interface DownLoadFileProps {
    message: FileChatMessage;
    sendType: MessageSendType;
    searchKey: string;
    currentEmployee: Employee;
    jumpTo?: (mid: number) => void;
}

type IDownLoadFileProps = Readonly<WithTranslation & DownLoadFileProps & ChatState>;

let dXhr: any = {};
function DownLoadFile(props: IDownLoadFileProps) {
    const { message, searchKey, sendType, currentEmployee, selectedSession } = props;
    const { t } = useTranslation('chat');
    const [status, setStatus] = useState(FileStatus.INIT);
    const [path, setPath] = useState('');
    const [progress, setProgress] = useState(0);
    const [downloadItem, setDownloadItem]: any = useState({});
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const defaultDownloadPath =
        appRuntimeEnv.registryConfig.defaultDownloadPath || FocusSDK.getDownloadPath();

    useEffect(() => {
        const downLocalPath = message.downLocalPath || message.upLocalPath;
        if (isFocusEnv() && downLocalPath) {
            FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                if (exists) {
                    setStatus(FileStatus.COMPLETED);
                    setPath(downLocalPath || '');
                } else {
                    setStatus(FileStatus.INIT);
                    setPath('');
                }
            });
        }
    }, [message]);

    const getFileConfig = (status: FileStatus): string[] => {
        const data: any = {
            [FileStatus.INIT]: [
                '',
                'click-to-save',
                'click-to-save',
                'file-info-status-view',
                'file-info-status-size',
            ],
            [FileStatus.DOWNLOADING]: [
                'iconapp_btn_file_cancel',
                'cancel-download',
                'cancel-download',
                'file-info-status-view',
                'file-info-status-ing',
            ],
            [FileStatus.CANCELED]: [
                'iconic_refresh1',
                'download-again',
                'download-again',
                'file-info-status-re-upload',
                '',
            ],
            [FileStatus.FAILED]: [
                'iconic_refresh1',
                'download-again',
                'download-again',
                'file-info-status-re-upload',
                '',
            ],
            [FileStatus.COMPLETED]: isFocusEnv()
                ? ['iconbtn_localfile', 'click-to-view', 'open-folder', 'file-info-status-view', '']
                : [
                      'iconbtn_localfile',
                      'click-to-save',
                      'open-folder',
                      'file-info-status-view',
                      '',
                  ],
            [FileStatus.DUE]: ['', '', 'file-due', 'file-info-status-due', ''],
        };
        return data[status];
    };

    const getDownloadOptions = ({ viewFile, saveAs, message, auto }: SaveOption) => {
        return {
            onStarted: (downloadItem: DownloadItem) => {
                setDownloadItem(downloadItem);
            },
            onProgress: ({ percent = 0 }) => {
                setStatus(FileStatus.DOWNLOADING);
                setProgress(percent);
            },
            onCancel: () => {
                // console.log('onCancel ==>取消下载');
            },
            onPause: () => {
                // console.log('onPause ==> 暂停下载');
                setStatus(FileStatus.CANCELED);
            },
            onResume: () => {
                // console.log('onResume ==> 恢复下载');
                setStatus(FileStatus.DOWNLOADING);
            },
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                const saveName = getMessageFileName(message);
                if (!checkDownloadFilePathValid(saveName, file)) {
                    setStatus(FileStatus.INIT);
                    toast.error(t('failed'));
                    return;
                }
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(message.sessionId, message.mid, {
                    downLocalPath: file.path,
                });
                if (result) {
                    setStatus(FileStatus.COMPLETED);
                    setProgress(100);
                    setPath(file.path || '');
                    if (viewFile) {
                        FocusSDK.openItem(file.path);
                    }
                    if (
                        !isEqualEmployee(message.sender, currentEmployee) &&
                        selectedSession.isGroup
                    ) {
                        addFileDownloadRecord({
                            fileId: message.id,
                            fileName: (message as any).name,
                            groupId: message.sessionId,
                            mid: message.mid,
                            senderUserId: message.sender.userId,
                            senderTeamId: message.sender.teamId,
                        });
                    }
                    localStorage.setItem('downloadFilePath', data.saveAs);
                } else {
                    toast.error(t('failed'));
                }
            },
        };
    };

    const doDownloadMessageFile = ({
        viewFile,
        saveAs,
        message,
        auto = false,
    }: SaveOption): Promise<void> => {
        const options = getDownloadOptions({ viewFile, saveAs, message, auto });
        return downloadMessageFile({ viewFile, saveAs, message, auto }, options);
    };

    const fileOperate = (operateTipKey: string) => {
        console.log(operateTipKey, 'operateTipKey');
        switch (operateTipKey) {
            case 'click-to-save':
                if (isFocusEnv()) {
                    doDownloadMessageFile({ viewFile: false, saveAs: false, message })
                        .then()
                        .catch((e) => console.log(e));
                } else {
                    // web 端下载文件
                    const name = getFileName(message);
                    const url = getFileUrl(message.url);
                    dXhr = downLoadFile(name, url, '', {
                        onProgress: (currPercent: any) => {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(currPercent);
                        },
                        onFinish: () => {
                            setStatus(FileStatus.COMPLETED);
                            setProgress(100);
                            dXhr = {};
                            toast.success('下载成功');
                            if (selectedSession.isGroup) {
                                handleAddFileDownloadRecord(message, currentEmployee);
                            }
                        },
                    });
                }
                break;
            case 'download-again':
                if (isFocusEnv()) {
                    downloadItem?.resume();
                } else {
                    // web 端下载文件
                    const name = getFileName(message);
                    const url = getFileUrl(message.url);
                    dXhr = downLoadFile(name, url, '', {
                        onProgress: (currPercent: any) => {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(currPercent);
                        },
                        onFinish: () => {
                            setStatus(FileStatus.COMPLETED);
                            setProgress(100);
                            dXhr = {};
                            toast.success('下载成功');
                            addFileDownloadRecord({
                                fileId: message.id,
                                fileName: (message as any).name,
                                groupId: message.sessionId,
                                mid: message.mid,
                                senderUserId: message.sender.userId,
                                senderTeamId: message.sender.teamId,
                            });
                        },
                    });
                }
                break;
            case 'click-to-view':
                if (isFocusEnv() && path) {
                    FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                        if (exists) {
                            FocusSDK.openItem(path);
                        } else {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(0);
                            setTimeout(() => {
                                doDownloadMessageFile({ viewFile: false, saveAs: false, message })
                                    .then()
                                    .catch((e) => console.log(e));
                            }, 50);
                        }
                    });
                }
                break;
            case 'open-folder':
                if (isFocusEnv() && path) {
                    FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                        if (exists) {
                            FocusSDK.showItemInFolder(path);
                        } else {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(0);
                            setTimeout(() => {
                                doDownloadMessageFile({ viewFile: false, saveAs: false, message })
                                    .then()
                                    .catch((e) => console.log(e));
                            }, 50);
                        }
                    });
                }
                break;
            case 'cancel-download':
                if (isFocusEnv()) {
                    downloadItem?.pause();
                } else {
                    dXhr.abort?.();
                    setStatus(FileStatus.CANCELED);
                    setProgress(0);
                }
                break;
            default:
                break;
        }
    };

    const fileOperateClsTag = (operateTipKey: string): string => {
        switch (operateTipKey) {
            case 'click-to-save':
                return 'pageclick|keycount|focus_chat_02_1615797535989|2';
            case 'download-again':
                return 'pageclick|keycount|focus_chat_02_1615797535989|3';
            case 'click-to-view':
                return 'pageclick|keycount|focus_chat_02_1615797535989|4';
            case 'open-folder':
                return 'pageclick|keycount|focus_chat_02_1615797535989|5';
            case 'cancel-download':
                return 'pageclick|keycount|focus_chat_02_1615797535989|6';
            default:
                return '';
        }
    };

    const [operateIcon, operateTipKey, operateKey, operateClass, sizeClass] = getFileConfig(status);
    const [, progressColor, fileIcon] = useMemo(
        () => getFileIconByMime(message.fileType || lookup(message.name) || ''),
        [message]
    );

    return (
        <div className="history-file">
            <div className="file-content">
                <div className="file-content-name">
                    {formatMatchContext(message.name, searchKey)}
                </div>
                <div className="file-content-status">
                    <div className="file-info-status">
                        <span className="file-info-size">{sizeToStr(message.size)}</span>
                        <span className="file-info-uploader">
                            {formatMatchContext(message.sender.name, searchKey)}
                        </span>
                        <span className="file-info-time">
                            {getMessageDateFormat(
                                message.timestamp,
                                MessageDateFormType.DATE_AGGREGATION,
                                ''
                            )}
                        </span>
                    </div>
                </div>
            </div>
            <img src={changeEgovUrl(fileIcon)} alt="" className="file-icon" draggable="false" />

            {status === FileStatus.DOWNLOADING && (
                <div
                    className="file-img-mask"
                    style={{
                        backgroundColor: 'red',
                        backgroundImage: `conic-gradient(transparent ${
                            progress * 3.6
                        }deg,  rgba(114, 114, 114, 0.5) ${progress * 3.6}deg)`,
                    }}
                >
                    <IconFont
                        type="iconlog_del"
                        className="file-content-download-cancel"
                        onClick={() => {
                            fileOperate(operateKey);
                        }}
                    />
                </div>
            )}
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item
                            key="download"
                            onClick={() => fileOperate(operateTipKey)}
                            // clstag={fileOperateClsTag(operateKey)}
                        >
                            {t(`history-${operateTipKey}`)}
                        </Menu.Item>
                    </Menu>
                }
                placement="bottomRight"
                trigger={['hover']}
            >
                <IconFont type="iconlog_more" className="file-actions" />
            </Dropdown>
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, currentEmployee } = chat as ChatState;
    return { selectedSession, currentEmployee };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DownLoadFile);
