// 任务编辑、创建页面使用
import React, { useCallback, useMemo, useRef, useState } from 'react';
import './index.less';
import { WorkUserInfo } from '@/types/work';
import { Button, Popover, Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { UserListEmptyImg } from '@/assets/img';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import { connect } from 'dva';
import Bus from '@/utils/bus';
import { processStatusOptions, TASK_STATUS } from '@/types/newWork';
import { PROCESS_STATUS_EXECUTORS } from '@/components/Projects/emnu';
import AdvanceCom from '../TaskDetailDraw/components/AdvanceCom';
import IconFont, { IconArrowLeft } from '@/components/icon';
import user from '@/models/user';
import { changeEgovUrl } from '@/utils/tools';
import { EsuperviseSourceType, ICreateSuperviseData } from '@/types/supervise';
import { labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';
import ScrollView from '@/baseComponents/ScrollView';
import WorkUserListWeight, {
    number2percentum,
    percentum2number,
} from '../BaseComponents/WorkUserListWeightSet';

function UserStatusListPopover({
    list,
    onRemove,
    children,
    disable,
    isHead = true,
    hasWeightSet = true,
    toggleUserCardModal,
    openCreateSuperviseModal,
    userCardVisible,
    label,
    userPopover,
    userData,
    taskDetail,
    mode, // 目前是 仅详情场景为空
    handleTaskMembersWeight,
}: {
    onRemove: (user: any[]) => void;
    children: any;
    disable?: boolean;
    hasWeightSet?: boolean;
    isHead?: boolean;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    userCardVisible: boolean;
    list: WorkUserInfo[];
    label?: string; // 区分日程参与人
    userPopover?: string;
    userData: any;
    taskDetail: any;
    mode?: string;
    handleTaskMembersWeight?: Function;
}) {
    const { t } = useTranslation('work');

    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [advanceVisible, setAdvanceVisible] = useState(false);
    const [advanceUser, setAdvanceUser] = useState<WorkUserInfo>();
    const scrollRef = useRef(null);
    const [visibleOfWeight, setVisibleOfWeight] = useState(false);

    const currentIsEdit = useCallback(
        (item: any) => {
            if (
                userData?.team?.teamId === item?.teamId &&
                userData?.team?.ddAppId === item?.app &&
                userData?.userId === item?.userId
            ) {
                return true;
            }
        },
        [userData]
    );
    function visibleChange(visible: boolean) {
        if (modalVisible) {
            return;
        }
        if (userCardVisible) {
            return;
        }
        setVisible(visible);
        setTimeout(() => {
            setAdvanceVisible(false);
        }, 500);
    }
    const [t_common] = useTranslation('common');
    function handleRemoveMember(user: WorkUserInfo) {
        setModalVisible(true);
        // 删除提醒
        prompt({
            title: t('remove executor'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: () => {
                setModalVisible(false);
                onRemove([
                    {
                        userId: user.userId,
                        teamId: user.teamId,
                        app: user.app,
                        taskUserRole: label ? undefined : user.userRole,
                    },
                ]);
            },
            onCancel: () => {
                setModalVisible(false);
            },
            content: label
                ? t('remove participant desc').replace('%s', user.realName)
                : t('remove executor desc').replace('%s', user.realName),
            okText: t_common('button.ok'),
            cancelText: t_common('button.cancel'),
        });
    }

    function handleAdvanceRecord(user: WorkUserInfo) {
        setAdvanceVisible(true);
        setAdvanceUser(user as any);
    }

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    const getProcessStatusTarget = (status: number) => {
        return processStatusOptions.find((item) => item.status === status) || null;
    };
    const getContent = () => {
        const getList = (list: any[]) => {
            return list.map((item) => (
                <div className="user-status-list-item" key={item.userId + item.teamId + item.app}>
                    {item.headImg ? (
                        <img
                            src={changeEgovUrl(item.headImg || '')}
                            className="avatar"
                            onClick={() => openUserCard(item)}
                        />
                    ) : (
                        <div className="avatar" onClick={() => openUserCard(item)}>
                            {(item.realName || '').slice(0, 1)}
                        </div>
                    )}
                    {!userPopover ? (
                        <div className="container">
                            <div className="title-content">
                                <span className="title">
                                    {item.realName}
                                    {item.labelInfoList &&
                                        item.labelInfoList.map((label: labelInfoList) => {
                                            return label.isShow === '1' ? (
                                                <span
                                                    style={{
                                                        color: `#${label.color}`,
                                                        marginLeft: '5px',
                                                        fontSize: 12,
                                                        border: `1px solid #${label.color}`,
                                                    }}
                                                >
                                                    {label.name}
                                                </span>
                                            ) : null;
                                        })}
                                </span>
                                {!disable && isHead && (
                                    <span
                                        className="action-remove bbbbb"
                                        onClick={() => handleRemoveMember(item)}
                                    >
                                        {t('remove')}
                                    </span>
                                )}
                            </div>
                            <div className="desc">
                                <span className="desc-org">{item.deptInfo?.fullName || ''}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="task-container mark-lb-aaa">
                            <div className="title-content">
                                <span className="title">
                                    {item.realName}
                                    {item.labelInfoList &&
                                        item.labelInfoList.map((label: labelInfoList) => {
                                            return label.isShow === '1' ? (
                                                <span
                                                    style={{
                                                        color: `#${label.color}`,
                                                        marginLeft: '5px',
                                                        fontSize: 12,
                                                        border: `1px solid #${label.color}`,
                                                    }}
                                                >
                                                    {label.name}
                                                </span>
                                            ) : null;
                                        })}
                                </span>
                                <span className="duty">
                                    {[item.deptName, item.titleName]
                                        .filter((item) => item)
                                        .join('-')}
                                </span>
                            </div>
                            <div className="status-content">
                                {item.processStatus && (
                                    <span
                                        className={`process-status process-status-${item.processStatus}`}
                                    >
                                        {currentIsEdit(item)
                                            ? getProcessStatusTarget(item.processStatus)
                                                  ?.currentLabel || ''
                                            : getProcessStatusTarget(item.processStatus)?.label ||
                                              ''}
                                    </span>
                                )}

                                {!disable && mode !== 'create' && (
                                    <span
                                        className="action-remove"
                                        clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_advancerecord"
                                        onClick={() => handleAdvanceRecord(item)}
                                    >
                                        {t('advance-record')}
                                    </span>
                                )}
                                {!disable && item.isRemove && isHead && (
                                    <span
                                        className="action-remove aaaaa"
                                        clstag={`pageclick|keycount|${
                                            mode === 'create'
                                                ? 'xtbg_add_task|xtbg_add_task_createtask_deletecolaborator'
                                                : 'xtbg_add_task|xtbg_add_task_taskdetail_deletecolaborator'
                                        }`}
                                        onClick={() => handleRemoveMember(item)}
                                    >
                                        {t('remove')}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ));
        };
        const getEmpty = () => (
            <div className="user-status-list-empty">
                <img src={changeEgovUrl(UserListEmptyImg)} />
                <div className="label">{label ? t('no participant') : t('no executors')}</div>
            </div>
        );
        return (
            <>
                <div className="user-status-list bbb" style={{ paddingBottom: '48px' }}>
                    <ScrollView ref={scrollRef} autoHide nohorizontal={true}>
                        {list.length > 0 ? getList(list) : getEmpty()}
                    </ScrollView>
                </div>
                {hasWeightSet && mode === 'edit' && list.length > 0 && isHead && (
                    <div className="go-somewhere">
                        <Button
                            type="link"
                            onClick={() => {
                                setVisibleOfWeight(true);
                            }}
                        >
                            编辑任务权重
                        </Button>
                    </div>
                )}
            </>
        );
    };
    const getAdvanceContent = () => {
        return (
            <div className="user-status-advance-record new-edition-version">
                <div className="user-status-advance-record-title">
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setAdvanceVisible(false);
                        }}
                    >
                        {IconArrowLeft}
                    </span>
                    <p>
                        <i> {advanceUser?.realName}</i>的{t('advance-record')}
                    </p>
                </div>
                <div className="personal-situation">
                    <span
                        style={{ opacity: advanceUser?.processStatus !== 4 ? '1' : '0' }}
                        className={`personal-process-status tag-process-status-${advanceUser?.processStatus}`}
                    >
                        {PROCESS_STATUS_EXECUTORS[advanceUser?.processStatus as TASK_STATUS]}
                    </span>
                    {/* <p>
                        权重占比：
                        {advanceUser?.weight &&
                            number2percentum(advanceUser.weight! / taskDetail.totalWeight)}
                    </p>
                    <div
                        className={`process-status-${advanceUser?.processStatus}`}
                        style={{
                            width: '100%',
                            display: advanceUser?.processStatus !== 4 ? 'flex' : 'none',
                            justifyContent: 'space-between',
                            marginTop: '15px',
                            alignItems: 'center',
                        }}
                    >
                        <article>
                            {advanceUser?.progress && number2percentum(advanceUser.progress / 100)}
                        </article>
                        <Progress percent={advanceUser?.progress} size="small" showInfo={false} />
                    </div> */}
                </div>
                <div className="advance-record-wrapper bbb-2">
                    {advanceUser?.processStatus !== 4 ? (
                        <AdvanceCom className="as-to-someone" advanceUser={advanceUser} />
                    ) : (
                        <div className="non-seen">
                            {advanceUser?.realName +
                                (getProcessStatusTarget(advanceUser?.processStatus)?.label || '')}
                        </div>
                    )}
                </div>
                {advanceUser?.processStatus === 2 ? null : (
                    <Button
                        type="link"
                        clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_advancerecord_urge"
                        block
                        onClick={() => {
                            const userList: any[] = [];
                            userList.push({
                                userId: advanceUser?.userId,
                                teamId: advanceUser?.teamId,
                                appId: advanceUser?.app,
                                headImg: advanceUser?.headImg,
                                realName: advanceUser?.realName,
                            });

                            openCreateSuperviseModal({
                                visible: true,
                                createData: {
                                    id: taskDetail?.taskId,
                                    title: taskDetail?.title,
                                    userList,
                                    from: EsuperviseSourceType.WORK,
                                },
                            });
                        }}
                    >
                        {t('urge immediately')}
                    </Button>
                )}
            </div>
        );
    };
    return (
        <>
            <Popover
                overlayClassName={
                    'work-user-status-popover' + (advanceVisible ? ' about-advance' : '')
                }
                trigger="click"
                placement="bottomRight"
                content={advanceVisible ? getAdvanceContent : getContent}
                visible={visible}
                onVisibleChange={visibleChange}
                getPopupContainer={(trigger) => {
                    return trigger as HTMLElement;
                }}
            >
                {children}
            </Popover>
            {hasWeightSet && visibleOfWeight && (
                <WorkUserListWeight
                    weightModalConfig={{
                        userArr: list,
                        close: () => {
                            setVisibleOfWeight(false);
                        },
                        complete: handleTaskMembersWeight,
                    }}
                />
            )}
        </>
    );
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStatusListPopover);
