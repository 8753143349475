import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import { message as toast } from 'antd';
import { debounce } from 'lodash';
import ImService from '@/server/ImService';
import { UserState } from '@/models/user';

const IdentityInfoToPerfect = connect(
    function ({ chat, user }: { chat: ChatState; user: UserState }) {
        return {
            identityInfoModal: chat.identityInfoModal,
            currentEmployee: chat.currentEmployee,
            useData: user.userData.user,
        };
    },
    function (dispatch: any) {
        return {
            setIdentityInfoModal(payload: boolean) {
                dispatch({ type: 'chat/setIdentityInfoModal', payload });
            },
            updateCurrentEmployee(payload: any) {
                dispatch({ type: 'chat/updateCurrentEmployee', payload });
            },
        };
    }
)(function ({
    message,
    useData,
    setIdentityInfoModal,
    currentEmployee,
    updateCurrentEmployee,
}: {
    message: any;
    useData: any;
    setIdentityInfoModal: Function;
    currentEmployee: any;
    updateCurrentEmployee: Function;
}) {
    const { title, content, infox } = message;
    const [t] = useTranslation('chat');

    const getEmployeeDetail = useCallback(async () => {
        if (
            infox?.noticeType === 'to_perfect' ||
            infox?.noticeType === 'bind_success' ||
            infox?.noticeType === 'unbind_success'
        ) {
            const imService = ImService.getInstance();
            const result = await imService.getEmployeeDetail({
                appId: useData.ddAppId,
                userId: useData.userId,
                teamId: useData.teamUserInfo.teamId,
            });
            let fields: any = {};
            (result?.fields || []).forEach((item: any) => {
                fields[item.key] = item.val;
            });
            updateCurrentEmployee(fields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infox?.noticeType]);

    useEffect(() => {
        getEmployeeDetail();
    }, [getEmployeeDetail]);

    const clickToPerfect = useCallback(() => {
        if (currentEmployee?.cardNumber) {
            toast.error(t('beijing-account-already-exists'));
            return;
        }
        setIdentityInfoModal(true);
    }, [currentEmployee, setIdentityInfoModal, t]);

    const IsBeiJingTongAccount = useMemo(() => {
        return (
            message?.infox?.noticeType === 'to_perfect' ||
            message?.infox?.noticeType === 'unbind_success'
        );
    }, [message?.infox?.noticeType]);

    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}
            {IsBeiJingTongAccount && (
                <div className="notice-card-action">
                    <div
                        className="notice-card-action-view"
                        onClick={debounce(() => {
                            clickToPerfect();
                        }, 200)}
                    >
                        {t('click-to-perfect')}
                    </div>
                </div>
            )}
        </div>
    );
});

export default IdentityInfoToPerfect;
