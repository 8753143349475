import { gatewayInstance } from '../server/gateway';
export enum ApprovalResult {
    Reject = 0,
    Agree,
}
export enum ApplicationStatus {
    Unadd = 0,
    Added,
    Processing, // 审核中
}
export interface AppStoreApplication {
    appId: string;
    applicationId: string;
    purchaseQuantity: number;
    paymentStrategy: number;
    poster: string;
    name: string;
    icon: string;
    brief: string;
    description: string;
    useAgreement: string;
    privacyAgreement: string;
    docUrl: string;
    keyword: string;
    preview: string;
    supportedLang: string;
    version: string;
    maxVersion: string;
    minVersion: string;
    recommended: number;
    added: number;
    homeUrl: string;
    tags: any[];
}
/**
 *
 * 拒绝申请
 * @returns
 * @param opts
 */
export async function approveApplicationAddition(opts: {
    appKey: string;
    applicationId: string;
    applicantUserId: string;
    approvalResult: ApprovalResult;
    approvalComments?: string;
}) {
    return gatewayInstance.request({
        api: 'appstore.approveApplicationAddition',
        method: 'POST',
        body: opts,
    });
}

export async function getStoreApplication(opts: { appKey: string; applicationId: string }) {
    return gatewayInstance.request({
        api: 'appstore.getStoreApplication',
        method: 'POST',
        body: opts,
    });
}
