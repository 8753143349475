import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { connect as dvaConnect } from 'dvajs';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import log from '@/utils/logger';
import { FocusSDK, getClientType, isFocusEnv } from '@/utils';
import IconFont from '@/components/icon';
import AudioMp3 from '@/assets/toy_mono.mp3';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { changeEgovUrl } from '@/utils/tools';
import { handleMeetingSendMessage } from '../utils';
import { AVSDK, BasicUserInfo, SessionStateEnum } from '../common';
import './index.less';
import { getJRTCToken, getConferenceByCode } from '@/api/meeting';
import { MeetingPlatform } from '@/types/common';
import bus from '@/utils/bus';

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const MeetingReceView = () => {
    const { authInfo, currentEmployee } = useContext<GlobalContextConfig>(GlobalContext);
    const [connect, setConnect] = useState(true);
    const audioRef = useRef<any>(null);
    const timeRef = useRef<any>(null);
    const [t] = useTranslation('common');
    const [message, setMessage] = useState<any>({});

    useEffect(() => {
        function handle(data: any) {
            log.debug(data);
            setMessage(data);
            setConnect(false);
        }
        FocusSDK.sendIpcMessage('meeting.initdata');
        FocusSDK.on('meeting:receiveMessage', handle);
        return () => {
            FocusSDK.off('meeting:receiveMessage', handle);
        };
    }, []);

    const handleClose = useCallback(() => {
        stopAudio();
        setTimeout(() => {
            FocusSDK.sendIpcMessage('meeting.invitedClose');
        }, 200);
    }, []);

    const replayMessage = useCallback(
        (sessionState) => {
            const { avsdk, meetingNumber, meetingPassword, meetingTopic } = message.ext;
            const fromUser: BasicUserInfo = message.ext.fromUser;
            FocusSDK.sendIpcMessage('meeting.sendMessage', {
                avsdk: avsdk,
                meetingNumber,
                meetingTopic,
                meetingPassword,
                content: meetingTopic,
                sessionState,
                toUsers: [
                    {
                        pin: fromUser.pin,
                        teamId: fromUser.teamId,
                        name: fromUser.name,
                        app: fromUser.app,
                        avatar: fromUser.avatar,
                    },
                ],
            });
            setTimeout(() => {
                handleClose();
            }, 300);
        },
        [handleClose, message.ext]
    );

    useEffect(() => {
        function handleTimePass() {
            const timer = timeRef.current;
            if (timer) {
                clearTimeout(timer);
            }
            timeRef.current = setTimeout(() => {
                replayMessage(SessionStateEnum.UserReject);
            }, 1000 * 60);
        }
        handleTimePass();
    }, [message, replayMessage]);

    function stopAudio() {
        const au = audioRef.current;
        if (au) {
            au.muted = true;
        }
    }

    function handleReject() {
        replayMessage(SessionStateEnum.UserReject);
    }

    function onJoinMeeting(opts: {
        avsdk: AVSDK;
        meetingNumber: string;
        meetingPassword: string;
        isvideooff: boolean;
        isaudiooff: boolean;
    }) {
        const { avsdk, meetingNumber, meetingPassword, isvideooff, isaudiooff } = opts;
        FocusSDK.sendIpcMessage('meeting.joinMeeting', {
            avsdk: avsdk,
            meetingInfo: {
                meetingNumber,
                meetingPassword,
                isvideooff,
                isaudiooff,
            },
        });
        setTimeout(() => {
            replayMessage(SessionStateEnum.UserReceive);
        }, 300);
    }

    function handleReceVideo() {
        stopAudio();
        const { meetingNumber, meetingPassword, avsdk } = message.ext;

        onJoinMeeting({
            avsdk: avsdk,
            meetingNumber,
            meetingPassword,
            isvideooff: false,
            isaudiooff: false,
        });
    }
    function handleReceAudio() {
        stopAudio();
        const { meetingNumber, meetingPassword, avsdk } = message.ext;

        onJoinMeeting({
            avsdk: avsdk,
            meetingNumber,
            meetingPassword,
            isvideooff: true,
            isaudiooff: false,
        });
    }

    if (!message || connect) {
        return (
            <div className="receive-meeting-browser">
                <div className="meeting-connect-loading">
                    <Spin indicator={antIcon} />
                </div>
            </div>
        );
    }
    const { ext } = message;
    if (!ext?.avsdk) {
        return;
    }
    const { name } = ext.fromUser;
    return (
        <div className="receive-meeting-browser">
            <div className="meeting-header">
                <div className="meeting-header-container">
                    <div className="meeting-header-content">
                        <div className="title">
                            <span className="title-name">{name}</span>
                        </div>
                        <div className="desc">
                            {t('joinMeeting.Invite you to an online meeting')}[{ext.meetingTopic}]
                        </div>
                    </div>
                </div>
            </div>
            <div className="meeting-action">
                <div className="meeting-action-reject">
                    <div className="action-icon" onClick={handleReject}>
                        <IconFont type="iconapp_btn_joymerting_hangup" />
                    </div>
                    <div className="label">{t('joinMeeting.reject')}</div>
                </div>
                <div className="meeting-action-video">
                    <div className="action-icon" onClick={handleReceVideo}>
                        <IconFont type="iconapp_btn_joymerting_video" />
                    </div>
                    <div className="label">{t('joinMeeting.rece-video')}</div>
                </div>
                <div className="meeting-action-audio">
                    <div className="action-icon" onClick={handleReceAudio}>
                        <IconFont type="iconapp_btn_joymerting_answer" />
                    </div>
                    <div className="label">{t('joinMeeting.rece-audio')}</div>
                </div>
            </div>
            <audio src={changeEgovUrl(AudioMp3)} loop autoPlay ref={audioRef} />
        </div>
    );
};

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {}

export default dvaConnect(mapStateToProps, mapDispatchToProps)(MeetingReceView);
