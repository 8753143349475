import React, { useEffect } from 'react';
import queryString from 'query-string';
import ThemeConfig from './themeConfig';
import { UAParser } from 'ua-parser-js';
import cssVars from 'css-vars-ponyfill';
function ThemeComp({ theme }: { theme?: string }) {
    let current: any = theme;
    if (!current) {
        const parsedUrl = queryString.parseUrl(location.href);
        const query = parsedUrl.query;
        current = query.theme;
    }

    useEffect(() => {
        if (!current || !ThemeConfig[current]) {
            const isIE = new UAParser().getBrowser().name === 'IE';
            if (isIE) {
                cssVars({});
            }
            return;
        }
        const isIE = new UAParser().getBrowser().name === 'IE';
        if (isIE) {
            cssVars({
                variables: ThemeConfig[current],
            });
        } else {
            Object.keys(ThemeConfig[current]).forEach((key) => {
                document.documentElement.style.setProperty(key, ThemeConfig[current][key]);
            });
        }
    }, [current]);
    return null;
}

export default ThemeComp;
