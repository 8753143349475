import React, { useMemo, useState, useCallback } from 'react';
import './index.less';
import dayjs, { Dayjs } from 'dayjs';

import { useTranslation } from 'react-i18next';
import { getCalendarList } from '@/components/Calendars/utils';
import { TotalRow } from '@/components/Calendars/utils/constant';
import IconFont from '@/components/icon';

function BaseCalendar({
    time,
    selectedTime,
    onClick,
}: {
    time: Dayjs;
    selectedTime: Dayjs;
    onClick: (time: Dayjs) => void;
}) {
    const { t } = useTranslation('calendar');
    const [toggle, setToggle] = useState(true);
    const today = useMemo(() => {
        return dayjs();
    }, []);
    let dateList = useMemo(() => {
        return getCalendarList(time, false);
    }, [time]);

    function selectHandle(item: Dayjs, e: any) {
        onClick(item);
    }
    let weeks: number[] = [];
    let weekStart = dateList[0].week();
    for (let i = 1; i <= TotalRow; i++) {
        weeks[i - 1] = weekStart;
        weekStart++;
    }
    return (
        <div className="project-base-calendar base-calendar">
            {toggle ? (
                <div className="calendar-body-wrapper">
                    <div className="df">
                        <div className="pannel">
                            <ul className="flex calendar-week-header">
                                <li className="disable">{t('seven')}</li>
                                <li className="disable">{t('one')}</li>
                                <li className="disable">{t('two')}</li>
                                <li className="disable">{t('three')}</li>
                                <li className="disable">{t('four')}</li>
                                <li className="disable">{t('five')}</li>
                                <li className="disable">{t('six')}</li>
                            </ul>
                            {weeks.map((item, index) => {
                                return (
                                    <ul
                                        key={index}
                                        className={`calendar-row common-row flex
                                    ${today.week() === item ? 'selected-row' : ''}`}
                                    >
                                        {dateList
                                            .slice(index * 7, index * 7 + 7)
                                            .map((item1, index1) => {
                                                if (!item1.isSame(time, 'month')) {
                                                    return (
                                                        <li
                                                            className="calendar-column-item common-item"
                                                            key={`${index}-${index1}`}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <li
                                                        className={`calendar-column-item common-item
                                                        ${item1.isToday() ? 'today' : ''}
                                                        ${
                                                            item1.isSame(
                                                                selectedTime.startOf('date')
                                                            )
                                                                ? 'selected-day'
                                                                : ''
                                                        }
                                                        ${
                                                            item1.isBefore(today, 'day')
                                                                ? 'disable'
                                                                : 'enable'
                                                        }`}
                                                        key={`${index}-${index1}`}
                                                        onClick={(e) => selectHandle(item1, e)}
                                                    >
                                                        {item1.date()}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default function CalendarComp({
    selectedTime,
    onTimeChange,
}: {
    selectedTime: Dayjs;
    onTimeChange: (time: Dayjs) => void;
}) {
    const [current, setCurrent] = useState(selectedTime);
    const [t] = useTranslation('calendar');
    const { currentMonth, lastMonth } = useMemo(() => {
        const currentMonth = current.startOf('month');
        const lastMonth = currentMonth.subtract(1, 'month');
        return { currentMonth, lastMonth };
    }, [current]);

    const handleSelectedChange = useCallback(
        (time) => {
            onTimeChange(time);
        },
        [onTimeChange]
    );

    const changeMonthBefore = useCallback(() => {
        const time = current.subtract(1, 'month');
        setCurrent(time);
    }, [current]);
    const changeMonthAfter = useCallback(() => {
        const time = current.add(1, 'month');
        setCurrent(time);
    }, [current]);

    const { lastMonthTitle, currentMonthTitle } = useMemo(() => {
        const lastMonthTitle = lastMonth.month() + 1 + t('month');
        const currentMonthTitle = currentMonth.month() + 1 + t('month');
        return { lastMonthTitle, currentMonthTitle };
    }, [currentMonth, lastMonth, t]);

    return (
        <div className="project-calendar-comp">
            <div className="comp-header">
                <span onClick={changeMonthBefore} className="comp-header-action">
                    <IconFont type="iconwin_left" />
                </span>
                <span className="comp-header-month-title">
                    <span className="comp-header-month-title-before">{lastMonthTitle}</span>
                    <span className="comp-header-month-title-after">{currentMonthTitle}</span>
                </span>

                <span onClick={changeMonthAfter} className="comp-header-action">
                    <IconFont type="iconwin_right" />
                </span>
            </div>
            <div className="comp-content">
                <BaseCalendar
                    onClick={handleSelectedChange}
                    time={lastMonth}
                    selectedTime={selectedTime}
                />
                <BaseCalendar
                    onClick={handleSelectedChange}
                    time={currentMonth}
                    selectedTime={selectedTime}
                />
            </div>
        </div>
    );
}
