import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Spin, message, Button } from 'antd';

import { connect as dvaConnect } from 'dvajs';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

import log from '@/utils/logger';
import { FocusSDK, getClientType, isFocusEnv } from '@/utils';
import IconFont from '@/components/icon';
import AudioMp3 from '@/assets/toy_mono.mp3';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { changeEgovUrl } from '@/utils/tools';
import { handleMeetingSendMessage } from '@/baseComponents/Meeting/utils';
import { AVSDK, BasicUserInfo, SessionStateEnum } from '@/baseComponents/Meeting/common';
import './index.less';
import { getJRTCToken, getConferenceByCode } from '@/api/meeting';
import { MeetingPlatform } from '@/types/common';
import bus from '@/utils/bus';

// const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const MeetingReceiveModal = () => {
    const [visible, setVisible] = useState(false);

    const { authInfo, isInJBRTCMeeting, jbrtcMeetingNumber, currentEmployee } = useContext<
        GlobalContextConfig
    >(GlobalContext);
    const [connect, setConnect] = useState(true);
    const audioRef = useRef<any>(null);
    const timeRef = useRef<any>(null);
    const [t] = useTranslation('common');
    const [message, setMessage] = useState<any>({});
    const [isCalling, setIsCalling] = useState(false);

    const history = useHistory();

    const closeModal = useCallback(
        (isShow: boolean) => {
            console.log('visible, connect:', visible, connect, isCalling);
            if (!visible) {
                return;
            }
            setVisible(isShow);
            setConnect(false);
            setIsCalling(false);
        },
        [visible, connect, isCalling]
    );

    useEffect(() => {
        console.log('isInJBRTCMeeting, jbrtcMeetingNumber:', isInJBRTCMeeting, jbrtcMeetingNumber);
        function handle(data: any) {
            log.debug('jd meeting receive data:', data);
            setMessage(data);
            setConnect(false);
            setVisible(true);
            setIsCalling(true);
        }
        // FocusSDK.sendIpcMessage('meeting.initdata');
        // FocusSDK.on('meeting:receiveMessage', handle);
        if (!isFocusEnv()) {
            bus.on('jdmeeting:show-receive-modal', handle);
            bus.on('jdmeeting:hide-receive-modal', closeModal);
        }
        return () => {
            // FocusSDK.off('meeting:receiveMessage', handle);
            bus.off('jdmeeting:show-receive-modal', handle);
            bus.off('jdmeeting:hide-receive-modal', closeModal);
        };
    }, [isInJBRTCMeeting, jbrtcMeetingNumber, closeModal]);

    function onCancel() {
        console.log('挂断web端视频会议');
        closeModal(false);
    }

    const handleClose = useCallback(() => {
        stopAudio();
        // setTimeout(() => {
        //     FocusSDK.sendIpcMessage('meeting.invitedClose');
        // }, 200);
    }, []);

    const replayMessage = useCallback(
        (sessionState) => {
            const { meetingNumber, meetingPassword, meetingTopic } = message.ext;
            const fromUser: BasicUserInfo = message.ext.fromUser;
            console.log('replayMessage-message.ext:', message.ext);
            // FocusSDK.sendIpcMessage('meeting.sendMessage', {
            //     avsdk: avsdk,
            //     meetingNumber,
            //     meetingTopic,
            //     meetingPassword,
            //     content: meetingTopic,
            //     sessionState,
            //     toUsers: [
            //         {
            //             pin: fromUser.pin,
            //             teamId: fromUser.teamId,
            //             name: fromUser.name,
            //             app: fromUser.app,
            //             avatar: fromUser.avatar,
            //         },
            //     ],
            // });
            console.log('打印replayMessage-sessionState:', sessionState);
            if (!isFocusEnv()) {
                handleMeetingSendMessage({
                    avsdk: message?.ext?.avsdk || AVSDK.JBRTC,
                    meetingInfo: {
                        meetingTopic: meetingTopic,
                        meetingNumber,
                        password: meetingPassword || '',
                    },
                    sessionState,
                    toUsers: [
                        {
                            pin: fromUser.pin,
                            teamId: fromUser.teamId,
                            name: fromUser.name,
                            app: fromUser.app,
                            avatar: fromUser.avatar,
                        },
                    ],
                }).then((res) => {
                    console.log('replayMessage callback', res);
                    closeModal(false);
                });
            }
            setTimeout(() => {
                handleClose();
            }, 300);
        },
        [handleClose, closeModal, message.ext]
    );

    // 进入页面后，60s后会报message不存在的error，需要加参数处理
    useEffect(() => {
        function handleTimePass() {
            const timer = timeRef.current;
            console.log('timer---', timer, timeRef.current);
            let intervals = 0;
            if (message && isInJBRTCMeeting) {
                intervals = 100;
            } else {
                intervals = 1000 * 60;
            }
            if (timer) {
                console.log('清除定时器～执行了～～～');
                clearTimeout(timer);
            }
            timeRef.current = setTimeout(() => {
                // 需要增加判断条件，如用户接听了，则不执行
                if (isInJBRTCMeeting) {
                    replayMessage(SessionStateEnum.Busy);
                } else {
                    console.log('isCalling:', isCalling);
                    // replayMessage(SessionStateEnum.UserReject);
                }
                console.log('定时器被执行-handleTimePass:', timeRef.current);
            }, intervals);
        }
        if (isCalling) {
            console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
            handleTimePass();
        }
    }, [message, connect, isCalling, isInJBRTCMeeting, jbrtcMeetingNumber, replayMessage]);

    function stopAudio() {
        const au = audioRef.current;
        if (au) {
            au.muted = true;
        }
    }

    function handleReject() {
        setIsCalling(false);
        replayMessage(SessionStateEnum.UserReject);
    }

    function onJoinMeeting(opts: {
        avsdk: AVSDK;
        meetingNumber: string;
        meetingPassword: string;
        isvideooff: boolean;
        isaudiooff: boolean;
    }) {
        switch (opts.avsdk) {
            case AVSDK.JBRTC:
                getJRTCToken({
                    nickname: currentEmployee.name,
                    unit: currentEmployee.deptName || '',
                    avatar: authInfo.headImg || '',
                    deviceType: getClientType(),
                }).then(([jrcToken, err]) => {
                    if (err) {
                        return;
                    }
                    const {
                        ddAppId,
                        userId,
                        selectedTeamId,
                        realName,
                        headImg,
                        teamUserInfo,
                        nickName,
                    } = authInfo;

                    const userInfo = {
                        token: jrcToken.token,
                        deviceType: jrcToken.deviceType,
                        jrtcUserId: jrcToken.jrtcUserId,
                        appId: jrcToken.appId,
                        app: ddAppId,
                        pin: userId,
                        teamId: selectedTeamId || teamUserInfo.teamId,
                        name: realName || nickName,
                        avatar: headImg,
                        deptName: teamUserInfo.displayDeptName,
                    };
                    // FocusSDK.sendIpcMessage('jdmeet.joinMeeting', {
                    //     meetingInfo: {
                    //         meetingNum: opts.meetingNumber,
                    //         password: opts.meetingPassword || '',
                    //         isvideooff: opts.isvideooff,
                    //         isaudiooff: opts.isaudiooff,
                    //     },
                    //     userInfo,
                    // });
                    if (!isFocusEnv()) {
                        console.log('接收入会：onJoinMeeting');
                        const meetingData = {
                            meetingInfo: {
                                meetingNum: opts.meetingNumber,
                                password: opts.meetingPassword || '',
                                isvideooff: opts.isvideooff,
                                isaudiooff: opts.isaudiooff,
                            },
                            userInfo,
                        };
                        history.push({
                            pathname: '/jdmeet',
                            state: {
                                meetStatus: 'joinMeeting',
                                ...meetingData,
                            },
                        });
                        localStorage.setItem('localUserInfo', JSON.stringify(userInfo));
                    }
                    replayMessage(SessionStateEnum.UserReceive);
                });

                break;
            case AVSDK.XYLINK:
                getConferenceByCode({
                    meetingId: opts.meetingNumber,
                    platform: MeetingPlatform.XYLINK,
                }).then(([info, err]: any) => {
                    if (info && !err) {
                        const {
                            ddAppId,
                            userId,
                            selectedTeamId,
                            realName,
                            headImg,
                            teamUserInfo,
                            nickName,
                        } = authInfo;
                        const userInfo = {
                            app: ddAppId,
                            pin: userId,
                            teamId: selectedTeamId,
                            name: realName || nickName,
                            avatar: headImg,
                            deptName: teamUserInfo.displayDeptName,
                        };
                        FocusSDK.sendIpcMessage('xylink.joinMeeting', {
                            meetingInfo: {
                                meetingNumber: opts.meetingNumber,
                                controlPassword: info.controlPassword,
                                topic: info.topic || '信创会议',
                                password: opts.meetingPassword || '',
                                isvideooff: opts.isvideooff,
                                isaudiooff: opts.isaudiooff,
                            },
                            userInfo,
                        });
                        replayMessage(SessionStateEnum.UserReceive);
                    }
                });
                break;

            default:
                break;
        }
    }

    function handleReceVideo() {
        stopAudio();
        const { meetingNumber, meetingPassword, avsdk } = message.ext;

        onJoinMeeting({
            avsdk: avsdk,
            meetingNumber,
            meetingPassword,
            isvideooff: false,
            isaudiooff: false,
        });
    }
    function handleReceAudio() {
        stopAudio();
        const { meetingNumber, meetingPassword, avsdk } = message.ext;

        onJoinMeeting({
            avsdk: avsdk,
            meetingNumber,
            meetingPassword,
            isvideooff: true,
            isaudiooff: false,
        });
    }

    if (!message || connect) {
        console.log('不存在message || 已连接');
        return null;
    }

    if (message && isInJBRTCMeeting && jbrtcMeetingNumber) {
        onCancel();
        return null;
    }

    const { ext } = message;
    if (!ext?.avsdk) {
        return;
    }
    const { name } = ext.fromUser;

    return (
        <Modal
            className="web-jd-meeting-call-modal"
            width={580}
            visible={visible}
            maskClosable={false}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            destroyOnClose
            footer={null}
        >
            <div className="receive-meeting-browser">
                <div className="meeting-header">
                    <div className="meeting-header-container">
                        <div className="meeting-header-content">
                            <div className="title">
                                <span className="title-name">{name}</span>
                            </div>
                            <div className="desc">
                                {t('joinMeeting.Invite you to an online meeting')}[
                                {ext?.meetingTopic}]
                            </div>
                        </div>
                    </div>
                </div>
                <div className="meeting-action">
                    <div className="meeting-action-reject">
                        <div className="action-icon" onClick={handleReject}>
                            <IconFont type="iconapp_btn_joymerting_hangup" />
                        </div>
                        <div className="label">{t('joinMeeting.reject')}</div>
                    </div>
                    <div className="meeting-action-video">
                        <div className="action-icon" onClick={handleReceVideo}>
                            <IconFont type="iconapp_btn_joymerting_video" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-video')}</div>
                    </div>
                    <div className="meeting-action-audio">
                        <div className="action-icon" onClick={handleReceAudio}>
                            <IconFont type="iconapp_btn_joymerting_answer" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-audio')}</div>
                    </div>
                </div>
                <audio src={changeEgovUrl(AudioMp3)} loop autoPlay ref={audioRef} />
            </div>
        </Modal>
    );
};

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {}

export default dvaConnect(mapStateToProps, mapDispatchToProps)(MeetingReceiveModal);
