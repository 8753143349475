/* eslint-disable array-callback-return */
/* eslint-disable no-self-compare */
/**
 * 聊天消息项组件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { ReactNode } from 'react';
import './index.less';
import ChatState, { UserState } from '@/types/chat/State';
import {
    ChatMessage,
    ChatMessageType,
    Employee,
    MessageStatus,
    ChatMessagePlusEmitBody,
} from '@/types/chat';
import { connect } from 'dva';
import { sf } from '@/utils/date';
import { Checkbox, Dropdown, Menu, message as toast, Tooltip } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import SessionType from '@jd/jdee.im.sdk/lib/es/enum/SessionType';
import { HeadShowType, MessageSendType } from '@/types/chat/enum';
import classNames from 'classnames';
import Control from '@/baseComponents/Chat/message/Item/Control';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import Revoke from '@/baseComponents/Chat/message/Revoke';
import Text from '@/baseComponents/Chat/message/Text';
import Image from '@/baseComponents/Chat/message/Image';
import UploadFile from '@/baseComponents/Chat/message/File/Upload';
import UploadWithDetail from '@/baseComponents/Chat/message/File/UploadWithDetail';
import DownloadFile from '@/baseComponents/Chat/message/File/Download';
import Video from '@/baseComponents/Chat/message/Video';
import Voice from '@/baseComponents/Chat/message/Voice';
import Share from '@/baseComponents/Chat/message/share';
import NoticeCard from '@/baseComponents/Chat/message/NoticeCard';
import HttpCard from '@/components/chat/message/HttpCard';
import {
    checkMessageSupport,
    getMessageSendType,
    isCardViewGroupSession,
    isEqualEmployee,
    isGroupChatVisibleUnit,
    isSameClient,
    isShareCardMessage,
    uidToEmployee,
} from '@/utils/chat/message';
import UnSupport from '@/baseComponents/Chat/message/UnSupport';
import { UserCardPayload } from '@/models';
import { isFocusEnv } from '@/utils';
import SysMessage from '@/baseComponents/Chat/message/Sys';
import { UserTag } from '@/components/Tags';
import { UserCardType } from '@/types/UserCard';
import { GroupRosterIdentity } from '@jd/jdee.im.sdk/lib/es/model/Roster';
import { isGroupOrdinary } from '@/utils/chat/group';
import Bus from '@/utils/bus';
import EmojiReply from '@/baseComponents/Chat/message/EmojiReply/index';
import MessageControl from '@/baseComponents/Chat/message/MsgControl';
import ChatAggregation from '@/baseComponents/Chat/message/ChatAggregation/index';
import JoyspaceFile from '@/components/chat/message/message-components/JoyspaceFile';
interface MessageItemProps {
    sendTime: React.ReactElement;
    message: ChatMessage & ChatMessagePlusEmitBody;
    groupRosterIdentity: GroupRosterIdentity | '';
    prevMessage: ChatMessage | null;
    getCurrentMessageId: Function;
    setCurrentMessageId: Function;
    unLock: Function;
    getLock: (lock?: boolean) => boolean;
    members: Employee[];
}
interface OwnState {
    showOperate: boolean;
    messageExtend: any;
    beginSend: boolean;
    selectChatItem: boolean;
    hasEmoji: boolean;
    visible: boolean;
}

interface DvaDispatchProps {
    deleteSessionMessage: (data: { message: ChatMessage }) => void;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    getEbookDeptInfo: (params: any) => void;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}

type IProps = Readonly<
    MessageItemProps & ChatState & DvaDispatchProps & UserState & WithTranslation
>;
class ChatItem extends React.Component<IProps, OwnState> {
    animation: any;
    // eslint-disable-next-line @typescript-eslint/member-ordering
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        // 该方法内禁止访问thisx
        if (nextProps.selectMore) {
            // 通过对比nextProps和prevState，返回一个用于更新状态的对象
            return {
                showOperate: false,
            };
        } else {
            return {
                selectChatItem: false,
            };
        }
        // 不需要更新状态，返回null
        // return null;
    }
    constructor(props: IProps) {
        super(props);
        this.state = {
            showOperate: false,
            messageExtend: {},
            beginSend: false,
            selectChatItem: false,
            hasEmoji: false,
            visible: false,
        };
    }
    componentDidMount() {
        Bus.on('chat:clearSelect', this.addclearSelectListener);
    }
    componentWillUnmount() {
        Bus.off('chat:clearSelect', this.addclearSelectListener);
    }
    addclearSelectListener = (params: any) => {
        if (params.clearSelect) {
            this.setState({
                selectChatItem: false,
            });
        }
    };

    getSendUserName() {
        const { selectedSession: session, message, currentEmployee } = this.props;
        if (session.sessionType === SessionType.GROUP) {
            return (
                <div className="name-tag" onClick={this.openUserCard.bind(this)}>
                    <div>{message.sender.name}</div>
                    {!isEqualEmployee(message.sender, currentEmployee) && (
                        <>
                            <div className="tag">
                                <UserTag
                                    userInfo={message.sender as Employee}
                                    currentEmployee={currentEmployee}
                                />
                            </div>
                            {isGroupChatVisibleUnit(session) && message.sender.unitName && (
                                <div className="unitName">@{message.sender.unitName}</div>
                            )}
                        </>
                    )}
                </div>
            );
        }
    }

    /**
     * 消息已准备好发送
     * @param param
     */
    messageReady = (param: { beginSend: boolean; messageExtend: any }) => {
        this.setState({
            messageExtend: param.messageExtend,
            beginSend: param.beginSend,
        });
    };

    getSendType() {
        const { message, currentEmployee, selectedSession } = this.props;
        // 处理群解散之后，桌面端消息展示在左边的问题
        if (message.statusType === MessageStatus.FAILED && message.localSendTimestamp) {
            return MessageSendType.OWNER;
        }
        return getMessageSendType(message, currentEmployee, selectedSession);
    }

    getMessageItemProps(message: any, sendType: MessageSendType): any {
        return {
            message: message,
            sendType: sendType,
            sessionType: this.props.selectedSession.sessionType,
            onReady: this.messageReady,
        };
    }

    // eslint-disable-next-line complexity
    getChatMessageNode(sendType: MessageSendType): ReactNode {
        const { currentEmployee, selectedSession } = this.props;
        const message: any = this.props.message;
        const isSupport = checkMessageSupport(message);
        if (!isSupport) {
            return <UnSupport message={message} />;
        }
        const props = this.getMessageItemProps(message, sendType);
        if (message.revoke === 1) {
            // 该消息已撤回
            return <Revoke {...props} />;
        }
        switch (message.type) {
            case ChatMessageType.TEXT:
                if ((message as any).nativeId === 'share_link') {
                    return <HttpCard {...props} />;
                }
                return <Text {...props} />;
            case ChatMessageType.IMAGE:
                return <Image {...props} />;
            case ChatMessageType.FILE: {
                if (
                    props.sendType === MessageSendType.OWNER ||
                    props.sendType === MessageSendType.SELF
                ) {
                    if (
                        !isSameClient(message) ||
                        (isFocusEnv() && !message.downLocalPath && !message.upLocalPath)
                    ) {
                        if (
                            isEqualEmployee(message.sender, currentEmployee) &&
                            selectedSession.isGroup
                        ) {
                            return <UploadWithDetail {...props} />;
                        } else {
                            return <DownloadFile {...props} />;
                        }
                    } else {
                        if (
                            isEqualEmployee(message.sender, currentEmployee) &&
                            selectedSession.isGroup
                        ) {
                            return <UploadWithDetail {...props} />;
                        } else {
                            return <UploadFile {...props} />;
                        }
                    }
                } else {
                    return <DownloadFile {...props} />;
                }
            }
            case ChatMessageType.VIDEO:
                return <Video {...props} />;
            case ChatMessageType.VOICE:
                return <Voice {...props} />;
            case ChatMessageType.TEMPLATE2:
                return <Share {...props} />;
            case ChatMessageType.JOYSPACEFILE:
                return <JoyspaceFile {...props} />;
            // @ts-ignore
            case 'unified_notice_message':
                return <NoticeCard {...props} />;
            case ChatMessageType.SYS: {
                if (message.flag === 100) {
                    return <NoticeCard {...props} />;
                }
                return <SysMessage {...props} />;
            }
            default:
                return null;
        }
    }

    openUserCard() {
        const { selectedSession, message, currentEmployee, t, groupRosterIdentity } = this.props;
        if (selectedSession?.isNotice) {
            return;
        } else {
            const { from } = message;
            // 如果群成员并且禁止了查看群成员名片
            if (
                !isEqualEmployee(currentEmployee, uidToEmployee(from) as Employee) &&
                isGroupOrdinary(groupRosterIdentity) &&
                !isCardViewGroupSession(selectedSession)
            ) {
                toast.warn(t('can_not_open_user_card_tip'));
                return false;
            }

            Bus.emit('app:toggle-user-card-modal:show', {
                visible: true,
                type: UserCardType.User,
                userId: from?.pin,
                teamId: from.teamId,
                appId: from.app,
            });
        }
    }

    getHeadPortrait(): ReactNode {
        const { selectedSession, message, prevMessage } = this.props;
        if (!prevMessage || prevMessage.revoke === 1) {
            return (
                <HeadPortrait
                    width="32px"
                    height="32px"
                    imgUrl={message.sender.avatar}
                    name={message.sender.name}
                    type={selectedSession.sessionType}
                    isUnFollow={true}
                    showType={HeadShowType.SESSION_LIST}
                    onItemClick={this.openUserCard.bind(this)}
                />
            );
        }
        return null;
    }

    getMsgItemClass(sendType: MessageSendType) {
        return classNames({
            'message-box': true,
            reverse: [MessageSendType.OWNER, MessageSendType.SELF].includes(sendType),
            'content-height': !this.props.prevMessage,
            group: this.props.selectedSession.sessionType === SessionType.GROUP,
            'select-more': this.props.selectMore,
            selectChatItem: this.state.selectChatItem && this.props.selectMore,
        });
    }

    getContentItemClass(sendType: MessageSendType) {
        return classNames({
            'row-item-wrap': true,
            isMix: true,
            owner: sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF,
            'has-emoji': this.state.hasEmoji,
        });
    }

    showOperate = () => {
        const message = this.props.message;
        if (message.revoke || !message.mid) {
            // 撤回的消息是没有操作权限的
            return;
        }
        this.props.setCurrentMessageId(message.id);
        const isNotLock = this.props.getLock();
        if (isNotLock) {
            if (this.state.showOperate) {
                return;
            }
            this.setState({ showOperate: true });
        }
    };
    handleSelectMore = () => {
        const { selectChatItem } = this.state;
        const { message, messages, selectMore, selectedSession } = this.props;
        // const isSupport = checkMessageSupport(message);
        // const isNotSelect = selectedSession.isSecret
        //     ? !isSupport
        //     : !isSupport || message.type === ChatMessageType.VOICE;
        // if (isNotSelect) {
        //     return;
        // }
        if (messages.length > 99 && !selectChatItem) {
            toast.error('沟通记录最多可选100条');
            return;
        }
        if (selectMore) {
            this.setState(
                {
                    selectChatItem: !selectChatItem,
                },
                () => {
                    const { selectChatItem } = this.state;
                    if (selectChatItem) {
                        messages.push(message);
                        let newMessages = messages.sort(
                            (item1, item2) => item1.timestamp - item2.timestamp
                        );
                        this.props.updateMessages({ messages: newMessages });
                    } else {
                        let newMessages = messages.filter((item) => item.id !== message.id);
                        newMessages = newMessages.sort(
                            (item1, item2) => item1.timestamp - item2.timestamp
                        );
                        this.props.updateMessages({ messages: newMessages });
                    }
                }
            );
        }
    };
    maskShow = () => {
        const mask = document.getElementById('message-box-container-scroll');
        if (!mask) return;
        mask.style.overflowY = '';
    };
    maskHide = () => {
        const mask = document.getElementById('message-box-container-scroll');
        if (!mask) return;
        const ua = navigator.userAgent;
        // 火狐overlay不兼容
        if (ua.indexOf('Firefox') > -1) {
            mask.style.overflowY = 'auto';
        } else {
            mask.style.overflowY = 'overlay';
        }
    };
    unLock = () => {
        this.onMouseLeave();
        this.props.unLock();
        this.maskShow();
        this.setState({ visible: false });
    };

    onMouseLeave = () => {
        this.props.setCurrentMessageId('');
        if (!this.props.getLock()) {
            return;
        }
        this.setState({ showOperate: false });
    };
    onVisibleChange = (flag: boolean) => {
        if (this.props.message.type === ChatMessageType.GROUPNOTICE) {
            return;
        }
        // this.onMouseLeave();
        this.props.unLock();
        this.setState({ visible: flag });
        if (flag) {
            this.maskHide();
        } else {
            this.maskShow();
        }
    };
    getTimeRowPart = () => {
        const { prevMessage, sendTime } = this.props;
        if (!prevMessage || prevMessage?.revoke === 1) {
            return (
                <div className="time-row">
                    {this.getSendUserName()}
                    {/* <Tooltip title={sendTime}>{sendTime}</Tooltip> */}
                    {/* <div>{sendTime}</div> */}
                </div>
            );
        }
        return null;
    };

    getSfPart = () => {
        const { message, prevMessage } = this.props;
        if (!prevMessage || prevMessage?.revoke === 1) {
            return null;
        }

        return (
            <Tooltip title={sf(message.timestamp)}>
                <div className="sf">{sf(message.timestamp)}</div>
            </Tooltip>
        );
    };

    emojiReplyCallback = (hasEmoji: boolean) => {
        this.setState({ hasEmoji: hasEmoji });
    };

    getSelectMore = () => {
        const { selectMore, message, selectedSession } = this.props;
        const { selectChatItem } = this.state;
        // const sendType = this.getSendType();
        // let diffDom: any = this.getChatMessageNode(sendType);
        const isSupport = checkMessageSupport(message);
        // @ts-ignore
        const isNotSelect = selectedSession.isSecret
            ? !isSupport
            : !isSupport || message.type === ChatMessageType.VOICE;
        if (isNotSelect) {
            return (
                selectMore && (
                    <div className="checkbox-wrapper">
                        <Checkbox disabled />
                    </div>
                )
            );
        }
        return (
            selectMore && (
                <div className="checkbox-wrapper">
                    <Checkbox checked={selectChatItem} />
                </div>
            )
        );
    };

    showEmojiReply = () => {
        const { message } = this.props;
        return !isShareCardMessage(message);
    };
    // 右键AT
    userSelectOk = () => {
        const { message } = this.props;
        const data = [
            {
                id: `${message.from.pin}:${message.from.app}:${message.from.teamId}`,
                type: 'user',
                name: message.nickName,
            },
        ];
        Bus.emit(`message:mentionUser`, data);
    };
    // eslint-disable-next-line @typescript-eslint/member-ordering
    menu = (
        <Menu>
            <Menu.Item onClick={this.userSelectOk}>
                <a style={{ fontSize: 14 }}>@ {this.props.message.nickName}</a>
            </Menu.Item>
        </Menu>
    );
    public render() {
        const { message, selectMore, selectedSession, currentEmployee } = this.props;
        const { messageExtend, beginSend, visible, showOperate } = this.state;
        const sendType = this.getSendType();
        if (!message.type) {
            return null;
        }
        const itemEventConfig = {
            onMouseEnter: this.showOperate,
            onClick: this.handleSelectMore,
            onMouseLeave: this.onMouseLeave,
            className: this.getMsgItemClass(sendType),
        };
        const onSendStatus = (status: MessageStatus) => {
            // 接收控制发送消息组件的状态，只有不是INIT的状态才会接收
            if (status !== MessageStatus.INIT) {
                // setSendStatus(status);
            }
        };
        if (message.id) {
            return (
                <div>
                    {/* 消息聚合 */}
                    {selectedSession?.isGroup === true && message.plusList ? (
                        // <div
                        //     onMouseLeave={() => {
                        //         this.setState({ visible: false });
                        //         this.maskShow();
                        //     }}
                        // >
                        <Dropdown
                            overlay={() => {
                                return (
                                    <Control
                                        {...this.props}
                                        unLock={this.unLock}
                                        showOperateBox={visible}
                                    />
                                );
                            }}
                            trigger={['contextMenu']}
                            visible={visible}
                            onVisibleChange={this.onVisibleChange}
                        >
                            <div>
                                <ChatAggregation
                                    message={message}
                                    plusListLen={message?.plusList?.length}
                                    selectedSession={selectedSession}
                                    currentEmployee={currentEmployee}
                                    sendType={sendType}
                                    sessionType={selectedSession.sessionType}
                                    onReady={this.messageReady}
                                />
                            </div>
                        </Dropdown>
                    ) : (
                        // </div>
                        <div {...itemEventConfig} id={`item-${message.id}-${message.timestamp}`}>
                            {message.plusList ? '' : <div>{this.getSelectMore()}</div>}
                            <div className="content-row">
                                {/* 右键@ */}
                                <Dropdown overlay={this.menu} trigger={['contextMenu']}>
                                    <div>{this.getHeadPortrait()}</div>
                                </Dropdown>
                                {this.getSfPart()}

                                {/* 右键显示操作 */}
                                <Dropdown
                                    overlay={() => {
                                        return (
                                            <Control
                                                {...this.props}
                                                unLock={this.unLock}
                                                showOperateBox={visible}
                                                isRightClick={visible}
                                            />
                                        );
                                    }}
                                    trigger={['contextMenu']}
                                    visible={visible}
                                    onVisibleChange={this.onVisibleChange}
                                >
                                    <div className={this.getContentItemClass(sendType)}>
                                        {this.getTimeRowPart()}
                                        <div className="content-container-202">
                                            {this.getChatMessageNode(sendType)}
                                            {!selectMore && (
                                                <div
                                                    className={`chat-item-control ${
                                                        selectedSession.isSecret ||
                                                        message.type === ChatMessageType.VOICE
                                                            ? 'chat-item-control_flex_end'
                                                            : ''
                                                    }`}
                                                >
                                                    {/* hover 显示操作 & */}
                                                    {!selectedSession.isSecret &&
                                                        message.type !== ChatMessageType.VOICE && (
                                                            <Control
                                                                {...this.props}
                                                                unLock={this.unLock}
                                                                showOperateBox={showOperate}
                                                            />
                                                        )}
                                                    <MessageControl
                                                        sendType={sendType}
                                                        message={message}
                                                        sessionType={selectedSession.sessionType}
                                                        beginSend={beginSend}
                                                        messageExtend={messageExtend}
                                                        onSendStatus={onSendStatus}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {this.showEmojiReply() && (
                                            <EmojiReply
                                                sessionType={selectedSession.sessionType}
                                                message={message}
                                                sendType={sendType}
                                                callback={this.emojiReplyCallback}
                                            />
                                        )}
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        return null;
    }
}

function mapStateToProps({ chat }: any) {
    const {
        selectedSession,
        currentEmployee,
        selectMore,
        messages,
        sessionMessageMap,
    } = chat as ChatState;
    return { selectedSession, currentEmployee, selectMore, messages, sessionMessageMap };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async deleteSessionMessage(data: { message: ChatMessage }) {
            await dispatch({ type: 'chat/deleteSessionMessage', payload: data });
        },
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        getEbookDeptInfo(data: any) {
            dispatch({ type: 'ebook/getEbookDeptInfo', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(ChatItem));
