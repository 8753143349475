import {
    addParticipant,
    AddParticipantType,
    // modifySchedule,
} from '@/api/calendar';
import React from 'react';
import { Button, message } from 'antd';

interface IProps {
    scheduleId: string;
    userData: any;
    isCreator: boolean;
    isParticipant: boolean;
    isAgent: boolean;
    calendarType: any;
    closeHandle: Function;
}

export function AddToMyCalendar(props: IProps) {
    const {
        scheduleId,
        userData,
        closeHandle,
        isCreator,
        isParticipant,
        isAgent,
        calendarType,
    } = props;
    async function addCalendarToMine() {
        try {
            const params: AddParticipantType = {
                scheduleId,
                user: {
                    teamId: userData.team.teamId,
                    userId: userData.userId,
                    account: userData.account,
                    imageUrl: userData.imageUrl,
                    ddAppId: userData.ddAppId,
                    userType: userData.userType,
                    realName: userData.realName,
                },
                acceptFlag: true,
            };
            const [res, err] = await addParticipant(params);
            if (!err) {
                message.success('添加成功');
                closeHandle();
            } else {
                message.error('添加失败');
            }
        } catch (error) {
            message.error('添加失败');
        }
    }
    return !isCreator && !isParticipant && !isAgent && calendarType !== 2 ? (
        <div>
            <Button style={{ marginLeft: '24px' }} type="primary" onClick={addCalendarToMine}>
                添加到我的日程
            </Button>
        </div>
    ) : null;
}
