import { getUser, FocusSDK, isFocusEnv } from '@/utils';
import DbService from '@/services/DbService';

if (typeof FocusSDK.DbService === 'undefined') {
    FocusSDK.DbService = DbService;
}
export interface IBaseService {
    dbService: any;
    getFromDB: (id: string) => any;
    saveToDB: (data: any) => void;
}

interface IService {
    serviceName: string;
    dbName: string;
    schemaName: string;
}
class BaseService implements IBaseService {
    static userInfo: any;
    dbService: any;
    dbName: string | undefined;
    schemaName: string | undefined;
    [type: string]: any;
    /**
     * @params dbName 数据库名称
     * @params schemaName 数据表名称
     * @params services 可以创建多个数据库服务，如果改参数传值则dbName和schemaName无效
     */
    // eslint-disable-next-line max-params
    constructor(dbName?: string, schemaName?: string) {
        this.dbName = dbName;
        this.schemaName = schemaName;
        this.initDb();
    }

    async initDb() {
        const userInfo = await this.getUserInfo();
        // console.log(userInfo, 'initDb===>userInfo');
        console.log('BaseService======>', this.dbName, this.schemaName, userInfo);
        if (this.dbName && userInfo?.userId && userInfo?.teamUserInfo?.teamId) {
            this.dbService = new FocusSDK.DbService(
                this.dbName,
                this.schemaName,
                userInfo.userId,
                userInfo.teamUserInfo.teamId
            );
            // console.log(this.dbService, 'this.dbServicethis.dbServicethis.dbService');
        }
    }
    saveToDB(data: any) {
        console.log(data, 'dbServicedbServicedbServicedbService', this, '===========>');
        this.dbService?.saveToDB(data);
    }
    getFromDB(id: string) {
        return this.dbService?.getFromDB(id);
    }
    deleteFromDB(id: string) {
        return this.dbService?.deleteFromDB(id);
    }
    getAllDocsFromDB(params: any) {
        // console.log(params, 'dbServicedbServicedbServicedbService', this, '===========>');
        return this.dbService?.getAllDocsFromDB(params);
    }
    async getUserInfo() {
        const oldUserInfo = BaseService.userInfo;
        // console.log(oldUserInfo, 'oldUserInfo===>');
        // eslint-disable-next-line no-debugger
        // debugger;
        if (oldUserInfo instanceof Object && Object.keys(oldUserInfo).length > 0) {
            return oldUserInfo;
        } else {
            const userInfo = await getUser();
            // console.log(userInfo, 'userInfouserInfouserInfouserInfo=>');
            BaseService.userInfo = userInfo;
            return userInfo;
        }
    }
    destory() {
        BaseService.userInfo = null;
    }
}

export default BaseService;
