/**
 * 消息操作控件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { CSSProperties } from 'react';
import { Dropdown, Menu } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import './index.less';
import { ChatMessage, Employee, Session, ChatMessagePlusEmitBody } from '@/types/chat';
import { ClickParam } from 'antd/lib/menu';
import {
    getReplyMessageDesc,
    getMessageSendType,
    isShareCardMessage,
    isCloudFileFunc,
} from '@/utils/chat/message';
import { MessageSendType } from '@/types/chat/enum';
import ChatEvent from '@/baseComponents/Chat/view/ChatEvent';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { checkScheduleVisible, checkTaskVisible, checkRemindVisible } from '@/utils/chat/group';
import { isFocusEnv } from '@/utils';
import chatConfig from '@/config/chat';

import config, { ConfigEnum } from '@/config/config';

interface MessageOperateProps {
    style?: CSSProperties;
    message: ChatMessage & ChatMessagePlusEmitBody;
    selectedSession: Session;
    currentEmployee: Employee;
    onDropMenuChange: Function;
    unLock: Function;
    isRightClick?: boolean;
    handleFile: (param: ClickParam) => void;
    copyOperate: (index: ClickParam) => void;
    undoOperate: (param: ClickParam) => void;
    forwardOperate: (param?: ClickParam) => void;
    deleteOperate: (param: ClickParam) => void;
    scheduleOperate: (param: ClickParam) => void;
    taskOperate: (param: ClickParam) => void;
    remindOperate: (param: ClickParam) => void;
    selectMoreFn: (param: ClickParam) => void;
    emojiReplyOperate: (emoji: string) => void;
}

interface MoreIconAction {
    text: string;
    event: Function;
}

interface MenuOption {
    [key: string]: MoreIconAction;
}

type IMessageOperateProps = Readonly<MessageOperateProps & WithTranslation>;

interface MessageOperateState {
    type: string;
    moreActive: boolean;
    expressionVisible: boolean;
}

class MessageOperate extends React.Component<IMessageOperateProps, MessageOperateState> {
    messageTime: number;
    constructor(props: IMessageOperateProps) {
        super(props);
        this.state = {
            type: '',
            moreActive: false,
            expressionVisible: false,
        };
        // this.messageTime = props.message.timestamp;
        this.messageTime = props.message.localSendTimestamp || 0;
    }

    onVisibleChange = (visible: boolean) => {
        this.setState({ expressionVisible: false });
    };

    // eslint-disable-next-line complexity
    getDropdownMenu = () => {
        const canCreateTask = config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_TASK];
        const { selectedSession, message, currentEmployee } = this.props;
        const sendType = getMessageSendType(message, currentEmployee);
        const type = this.props.message.type;
        const expireTime =
            type === ChatMessageType.FILE ||
            type === ChatMessageType.IMAGE ||
            type === ChatMessageType.VIDEO
                ? chatConfig['chat-revoke-time2']
                : chatConfig['chat-revoke-time'];
        const more2 =
            new Date().getTime() - this.messageTime <= 0
                ? true
                : new Date().getTime() - this.messageTime > expireTime;
        if (message.plusList === undefined) {
            return (
                <Menu selectedKeys={['']}>
                    {/* 文件另存为 */}
                    {(type === ChatMessageType.FILE ||
                        type === ChatMessageType.IMAGE ||
                        type === ChatMessageType.VIDEO) &&
                        isFocusEnv() && (
                            <Menu.Item
                                onClick={this.props.handleFile}
                                clstag="pageclick|keycount|focus_chat_01_1615797500283|33"
                            >
                                <div className="operate-menu-item">
                                    <IconFont className="icon" type="iconlingcunwei2" />
                                    <span>{this.props.t('click-to-saveAs')}</span>
                                </div>
                            </Menu.Item>
                        )}
                    {/* 复制 --hujun */}
                    {(type === ChatMessageType.TEXT ||
                        (type === ChatMessageType.IMAGE && isFocusEnv())) && (
                        <Menu.Item
                            onClick={this.props.copyOperate}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|33"
                        >
                            <div className="operate-menu-item">
                                <IconFont className="icon" type="iconapp_btn_copy" />
                                <span>{this.props.t('copy')}</span>
                            </div>
                        </Menu.Item>
                    )}
                    {/* 引用 */}
                    {type !== ChatMessageType.VOICE && (
                        <Menu.Item
                            onClick={this.handleReplyClick}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|27"
                        >
                            <div className="operate-menu-item">
                                <IconFont className="icon" type="iconapp_btn_reply" />
                                <span>{this.props.t('quote')}</span>
                            </div>
                        </Menu.Item>
                    )}
                    {/* 转发 */}
                    {type !== ChatMessageType.VOICE && (
                        <Menu.Item
                            onClick={this.props.forwardOperate}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|29"
                        >
                            <div className="operate-menu-item">
                                <IconFont className="icon" type="iconapp_btn_forwarding" />
                                <span>{this.props.t('forwarding')}</span>
                            </div>
                        </Menu.Item>
                    )}

                    {/* 撤回 */}
                    {sendType === MessageSendType.OWNER && this.messageTime && !more2 && (
                        <Menu.Item
                            onClick={this.props.undoOperate}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|31"
                        >
                            <div className="operate-menu-item">
                                <IconFont className="icon" type="iconapp_btn_undo" />
                                <span>{this.props.t('undo')}</span>
                            </div>
                        </Menu.Item>
                    )}
                    {/* <Menu.Divider /> */}
                    {/* 多选 */}
                    <Menu.Item
                        onClick={this.props.selectMoreFn}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|29"
                    >
                        <div className="operate-menu-item">
                            <IconFont className="icon" type="iconapp_btn_select" />
                            <span>{this.props.t('select_more')}</span>
                        </div>
                    </Menu.Item>

                    {/* 删除 */}
                    <Menu.Item
                        onClick={this.props.deleteOperate}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|30"
                    >
                        <div className="operate-menu-item">
                            <IconFont className="icon" type="iconapp_ic_delete" />
                            <span>{this.props.t('delete')}</span>
                        </div>
                    </Menu.Item>
                    {/* <Menu.Divider /> */}
                    {/* 任务 */}
                    {(type === ChatMessageType.TEXT ||
                        type === ChatMessageType.FILE ||
                        type === ChatMessageType.IMAGE ||
                        isCloudFileFunc(this.props.message)) &&
                        checkTaskVisible({ selectedSession }) &&
                        canCreateTask && (
                            <Menu.Item
                                onClick={this.props.taskOperate}
                                clstag="pageclick|keycount|focus_chat_02_1602584371145|20"
                            >
                                <div className="operate-menu-item">
                                    <IconFont className="icon" type="iconapp_ic_task" />
                                    <span>{this.props.t('task')}</span>
                                </div>
                            </Menu.Item>
                        )}

                    {/* 日程 */}
                    {(type === ChatMessageType.TEXT ||
                        type === ChatMessageType.FILE ||
                        type === ChatMessageType.IMAGE ||
                        isCloudFileFunc(this.props.message)) &&
                        checkScheduleVisible({ selectedSession }) && (
                            <Menu.Item
                                onClick={this.props.scheduleOperate}
                                clstag="pageclick|keycount|focus_chat_02_1615797535989|20"
                            >
                                <div className="operate-menu-item">
                                    <IconFont className="icon" type="iconapp_ic_schedule1" />
                                    <span>{this.props.t('schedule')}</span>
                                </div>
                            </Menu.Item>
                        )}

                    {/* 催办 */}
                    {type === ChatMessageType.TEXT && checkRemindVisible({ selectedSession }) && (
                        <Menu.Item
                            onClick={this.props.remindOperate}
                            clstag="pageclick|keycount|focus_chat_02_1602584371145|20"
                        >
                            <div className="operate-menu-item">
                                <IconFont className="icon" type="iconic_remind1" />
                                <span>{this.props.t('warn')}</span>
                            </div>
                        </Menu.Item>
                    )}
                </Menu>
            );
        }
        if (message.plusList) {
            return (
                <Menu selectedKeys={['']}>
                    {/* 复制 --hujun */}
                    {(type === ChatMessageType.TEXT || type === ChatMessageType.IMAGE) && (
                        <Menu.Item
                            onClick={this.props.copyOperate}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|33"
                        >
                            <div className="operate-menu-item">
                                <IconFont className="icon" type="iconapp_btn_copy" />
                                <span>{this.props.t('copy')}</span>
                            </div>
                        </Menu.Item>
                    )}

                    {/* 转发 */}
                    <Menu.Item
                        onClick={this.props.forwardOperate}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|29"
                    >
                        <div className="operate-menu-item">
                            <IconFont className="icon" type="iconapp_btn_forwarding" />
                            <span>{this.props.t('forwarding')}</span>
                        </div>
                    </Menu.Item>
                    {/* 撤回 */}
                    {/* {sendType === MessageSendType.OWNER && this.messageTime && !more2 && (
                        <Menu.Item
                            onClick={this.props.undoOperate}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|31"
                        >
                            <span className="icon">
                                <IconFont className="icon" type="iconapp_btn_undo" />
                            </span>
                            {this.props.t('undo')}
                        </Menu.Item>
                    )} */}
                </Menu>
            );
        }
    };

    handleReplyClick = () => {
        this.props.unLock();
        const { message, t } = this.props;
        let content = getReplyMessageDesc(message);
        ChatEvent.emitReply({
            replyUser: message.sender as Employee,
            replyText: content,
            replyMessage: message,
        });
    };

    handleExpressionClick = () => {
        // 点击表情也可以关闭表情回复
        if (this.state.expressionVisible) {
            this.setState({ expressionVisible: false });
            return;
        }
        this.setState({ expressionVisible: true });
    };

    getShowEmojiReply = () => {
        const { message } = this.props;
        return !isShareCardMessage(message);
    };

    render() {
        const { t, style, selectedSession, message, isRightClick } = this.props;
        const showEmojiReply = this.getShowEmojiReply();
        return (
            <div className="operate-control-container">
                {isRightClick && this.getDropdownMenu()}
                {selectedSession?.isGroup === true && message.plusList ? (
                    <div className="operate-control-menu">{this.getDropdownMenu()}</div>
                ) : (
                    !isRightClick && (
                        <div className="operate-box" id="operate-box">
                            {/* 引用 */}
                            <div
                                className="operate-box-item"
                                onClick={() => this.handleReplyClick()}
                                clstag="pageclick|keycount|focus_chat_01_1615797500283|27"
                            >
                                {/* <Tooltip placement="top" title={t('quote')}> */}

                                <IconFont className="icon" type="iconapp_btn_reply" />
                                {/* </Tooltip> */}
                            </div>
                            {/* 转发 */}
                            <div
                                className="operate-box-item"
                                onClick={() => this.props.forwardOperate()}
                                clstag="pageclick|keycount|focus_chat_01_1615797500283|29"
                            >
                                {/* <Tooltip placement="top" title={t('forwarding')}> */}
                                <IconFont className="icon" type="iconapp_btn_forwarding" />
                                {/* </Tooltip> */}
                            </div>
                            <div className="operate-box-item">
                                <Dropdown
                                    overlay={() => {
                                        return <div>{this.getDropdownMenu()}</div>;
                                    }}
                                    onVisibleChange={this.onVisibleChange}
                                    trigger={['click']}
                                    getPopupContainer={() =>
                                        document.getElementById('operate-box') as HTMLElement
                                    }
                                >
                                    {/* <Tooltip placement="top" title={t('more_operate')}> */}
                                    <IconFont
                                        className="icon"
                                        type="iconlog_more"
                                        clstag="pageclick|keycount|focus_chat_01_1615797500283|28"
                                    />
                                    {/* </Tooltip> */}
                                </Dropdown>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}

export default withTranslation('chat')(MessageOperate);
