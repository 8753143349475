/**
 * 上传文件
 */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { message, Progress } from 'antd';
import './index.less';
import classNames from 'classnames';
import { formatFileName, sizeToStr, toPercent } from './utils';
import { TaskState, UploadFile } from './types';
import okPng from './imgs/ok.png';
import retryPng from './imgs/retry.png';
import { extname } from 'path';
import { lookup } from 'mime-types';
import { getFileType } from '@/components/Joyspace/components/UploadBox/utils';
import { UploadControl } from './UploadControl';
import { getFileIconUrl } from '@/components/Joyspace/api/file';
import GlobalContext from '@/context/GlobalContext';
import { isFocusEnv } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';
import { EventEmitter } from 'events';
import { DefaultPermisions, PermissionEnum } from '@/components/Joyspace/const';
// import Task from '@jd/jdee-fs-desktop/lib/es/Task';

interface FileItemProps {
    fileId: string;
    status: string;
    uploadControlInstance: UploadControl;
    t: Function;
    onItemClick?: Function;
    retryLimitCallBackFunc?: Function;
    uploadSuccessCallBackFunc?: Function;
    uploadFailCallBackFunc?: Function;
    onStateChange: (fileId: string, state: TaskState, progressSize: number) => void;
}

type IFProps = Readonly<FileItemProps>;

function UploadItem(props: IFProps) {
    const {
        fileId,
        onStateChange,
        t: lang,
        uploadControlInstance,
        onItemClick,
        retryLimitCallBackFunc,
        uploadSuccessCallBackFunc,
        uploadFailCallBackFunc,
    } = props;
    const [status, setStatus] = useState(props.status || TaskState.INITED);
    const [progress, setProgress] = useState(0);
    const [uploadSize, setUploadSize] = useState(0);
    const [fileSize, setFileSize] = useState(0);
    const [hover, setHover] = useState(false);
    const [file, setFile] = useState<UploadFile>();
    const progressRef = useRef<number>(0);

    useEffect(() => {
        progressRef.current = progress;
    }, [progress]);

    let progressTime = 0;
    useEffect(() => {
        const file = uploadControlInstance?.getUploadFile(fileId);
        if (file) {
            setFile(file);
            // eslint-disable-next-line no-console
            // console.log('FileSize', file.size);
            setFileSize(file.size);
            setStatus(file.state);
            setProgress(file.progress || 0);
            setUploadSize(file.progress ? (file.progress * file.size) / 100 : 0);
        }
    }, [fileId, uploadControlInstance]);

    const {
        appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
    } = useContext(GlobalContext);

    const openFile = () => {
        if (file?.document?.id) {
            window.open(`${joyspaceHost}/file/${file?.document.id}`, '_blank');
        }
        return false;
    };

    // const mimeType = useMemo(() => {
    //     if (!file) {
    //         return null;
    //     }
    //     const fileExtName = extname(file.name);
    //     let type = file.type || lookup(file.name);
    //     // TIPS: 为了统一 mac 和 windows（windows系统会识别rtf文件是word类型文档） 系统 rtf 文档类型， 统一设置为文本文件类型
    //     // if (fileExtName === '.rtf' && type === 'application/msword') {
    //     //     type = 'text/rtf';
    //     //     return type;
    //     // }
    //     return (
    //         type ||
    //         ((fileExtName) => {
    //             if (fileExtName === '.ape') {
    //                 return 'audio/x-ape';
    //             }
    //         })(fileExtName) ||
    //         'application/octet-stream'
    //     );
    // }, [file]);

    const getFileIcon = useCallback(
        (fileName: string, mimeType: string) => {
            const name = fileName;
            let tempMimeType = mimeType;
            const extname = fileName.substr(name.lastIndexOf('.') + 1);
            // TIPS: 为了统一 mac 和 windows（windows系统会识别rtf文件是word类型文档） 系统 rtf 文档类型， 统一设置为文本文件类型
            // @ts-ignore
            if (extname === 'rtf' && mimeType === 'application/msword') {
                // @ts-ignore
                tempMimeType = 'text/rtf';
            }
            if (!tempMimeType) {
                return '';
            }
            return `${joyspaceApiHost}-api/v1/common/icons?title=${name}&pageType=6&type=${tempMimeType}&client=web`;
        },
        [joyspaceApiHost]
    );

    const fileIcon = useMemo(() => {
        if (!file) {
            return '';
        }
        return getFileIcon(file.name, getFileType(file));
    }, [file, getFileIcon]);

    // 监听状态变化
    const initListener = useCallback(
        ({ fileId: id, state, progress }) => {
            // console.log('uploadControlInstance initListener', id, state, progress);
            if (fileId !== id) {
                return;
            }
            setStatus(state);
            const file = uploadControlInstance.getUploadFile(fileId);
            if (state === TaskState.COMPLETED) {
                // 在监听时已经处理了
                if (file && uploadControlInstance.checkIsToJoySpace()) {
                    const document = file.document;
                    const data = {
                        ...document,
                        title: document.name,
                        icon_url: getFileIcon(document.name, getFileType(file)),
                        permissions: DefaultPermisions,
                    };
                    // 去掉专班代码
                    // if (file.projectId) {
                    //     // 专班id 专班上传
                    //     events.emit(`list.add:${file.projectId}`, data);
                    // } else {
                    uploadControlInstance.emit(`list.add`, data);
                    // }
                }
                uploadSuccessCallBackFunc?.({
                    file,
                    fileId,
                    state,
                    progress,
                });
            } else if (state === TaskState.FAILED) {
                uploadFailCallBackFunc?.({
                    file,
                    fileId,
                    state,
                    progress,
                });
            }
            const nowProgress = progress ? progress : 0;
            // eslint-disable-next-line no-console
            // console.log(id, state, progress, 'uploadControlInstance');
            if (progress) {
                onStateChange(
                    fileId,
                    state,
                    (fileSize * (nowProgress - progressRef.current)) / 100
                );
            } else {
                onStateChange(fileId, state, 0);
            }

            if (progress) {
                setUploadSize((progress * fileSize) / 100);
                setProgress(progress);
            }
        },

        [
            fileId,
            onStateChange,
            getFileIcon,
            fileSize,
            uploadControlInstance,
            uploadSuccessCallBackFunc,
            uploadFailCallBackFunc,
        ]
    );

    useEffect(() => {
        uploadControlInstance?.on(`upload-item-control-event:${fileId}`, initListener);
        return () => {
            uploadControlInstance?.off(`upload-item-control-event:${fileId}`, initListener);
        };
    }, [fileId, initListener]); // eslint-disable-line

    const onCancel = useCallback(() => {
        uploadControlInstance?.cancelUpload(fileId);
    }, [fileId, uploadControlInstance]);

    const onRetry = useCallback(() => {
        // if (!task) {
        //     return null;
        // }
        // task.restart();
        if (retryLimitCallBackFunc && retryLimitCallBackFunc() === false) {
            return;
        }
        uploadControlInstance?.reStartUpload(fileId);
    }, [fileId, uploadControlInstance, retryLimitCallBackFunc]);

    const onMouseEnter = () => {
        setHover(true);
    };

    const onMouseLeave = () => {
        setHover(false);
    };

    const itemClass = classNames({
        'file-progress-item-box': true,
        'file-progress-item-box-hover': hover && status === TaskState.COMPLETED,
    });

    const itemNameClass = classNames({
        'name-part': true,
        gray: status !== TaskState.COMPLETED,
    });

    if (!file) {
        return null;
    }
    const fileAction = () => {
        return (
            <div className="file-action">
                {status === TaskState.UPLOADING && hover && (
                    <a className="link cancel" onClick={onCancel}>
                        {lang('myspace.cancelText')}
                    </a>
                )}
                {status === TaskState.UPLOADING && !hover && <span>{toPercent(progress)}</span>}
                {status === TaskState.INITED && (
                    <a className="link" onClick={onCancel}>
                        {lang('myspace.cancelText')}
                    </a>
                )}
                {(status === TaskState.CANCEL || status === TaskState.FAILED) && (
                    <a className="link" onClick={onRetry}>
                        {lang('myspace.retry')}
                    </a>
                )}
                {(onItemClick || uploadControlInstance.checkIsToJoySpace()) &&
                    status === TaskState.COMPLETED &&
                    hover && (
                        <a
                            className="link grey"
                            onClick={() => {
                                onItemClick ? onItemClick(file) : openFile();
                            }}
                        >
                            {lang('myspace.open')}
                        </a>
                    )}
            </div>
        );
    };

    return (
        <div className={itemClass} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={itemNameClass}>
                <div className="file-icon">
                    <img src={changeEgovUrl(fileIcon)} />
                </div>
                <div className="file-name">
                    <div className="name">{formatFileName(file.name)}</div>
                    <div className="size">
                        {status === TaskState.COMPLETED && (
                            <span className="upload-size">{sizeToStr(fileSize)}/</span>
                        )}
                        {status === TaskState.UPLOADING && (
                            <span className="upload-size">{sizeToStr(uploadSize)}/</span>
                        )}
                        {sizeToStr(fileSize)}
                    </div>
                </div>
            </div>
            {/* {status !== TaskState.FAILED && fileAction()} */}
            {
                fileAction() // 失败加上重新上传功能
            }
            {status !== TaskState.FAILED && (
                <div className="file-status">
                    {status === TaskState.CANCEL && (
                        // <Icon type="iconic_refresh" className="panel-operate-icon" onClick={onRetry} />
                        <img src={changeEgovUrl(retryPng)} onClick={onRetry} className="retry" />
                    )}
                    {status === TaskState.COMPLETED && <img src={okPng} />}
                    {status === TaskState.UPLOADING && (
                        <Progress
                            type="circle"
                            percent={progress}
                            showInfo={false}
                            width={14}
                            strokeWidth={12}
                        />
                    )}
                    {status === TaskState.INITED && (
                        <Progress
                            type="circle"
                            percent={0}
                            showInfo={false}
                            width={14}
                            strokeWidth={12}
                        />
                    )}
                </div>
            )}
            {status === TaskState.FAILED && (
                <div className="failed">{lang('myspace.upload_failed')}</div>
            )}
        </div>
    );
}

export default UploadItem;
