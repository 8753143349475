import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import logger from '@/utils/logger';
import { IFocusSDK } from '@/utils';
import { checkUpgradeApp } from '@/api/upgradeApp';
import getVersionBySdk from '@/utils/getVersion';
import './index.less';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { changeEgovUrl } from '@/utils/tools';
// import { UPGRADE_SUCCESS, UPGRADE_FAILED } from '@/config/name';
import UPGRADE_SUCCESS from '@/assets/templateImgs/app_upgrade_success.png';
import UPGRADE_FAILED from '@/assets/templateImgs/app_upgrade_failed.png';

const log = logger.getLogger('UpgradeModal');
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;

/*
* files: (2) [{…}, {…}]
path: "jdcloud-1.3.4-darwin.zip"
releaseDate: "2020-11-24T10:54:51.432Z"
sha512: "YkgzT/pVfwn8MP9+ZcLRtHP5Se+4BOVYhfy6hs8ZI+pXuyOKYxnUG5WQLv1+PBlILoAs6bFAUXHah5YkKfgsew=="
version: "1.3.4"
* */

interface UpgradeModalProps {
    focusSDK: IFocusSDK;
    upgradeVersionInfo: string[];
    appRuntimeEnv: AppRuntimeEnv | undefined;
}

let lockCheckUpgrade = false;
// eslint-disable-next-line complexity
export default function UpgradeModal(props: UpgradeModalProps) {
    const localUrl = new URL(location.href);
    const pageName = localUrl.searchParams.get('page');
    const isHomePage = !pageName;
    const { focusSDK, appRuntimeEnv } = props;
    const { t } = useTranslation('common');
    const [visible, setVisible] = useState(false);
    const [upgradeData, setUpgradeData] = useState<any>({});
    const [releaseNotes, setReleaseNotes] = useState<string[]>([]);

    const { type: upgradeType, data } = upgradeData || {};
    const { version = '', percent, forceUpdate } = data || {};
    let title = t('upgrade.title', { newVersion: '' });
    const canLoad = useRef<boolean>(true);
    const canUpdateAvailable = useRef<boolean>(true);
    // tj: 增加更新失败背景图
    const bgImage = useMemo(() => {
        if (upgradeType !== 'error') {
            return `url(${UPGRADE_SUCCESS}) no-repeat center / cover`;
        } else {
            return `url(${UPGRADE_FAILED}) no-repeat center / cover`;
        }
    }, [upgradeType]);

    switch (upgradeType) {
        case 'checking':
            title = t('upgrade.checking');
            break;
        case 'updateDownloading':
            title = t('upgrade.downloading', {
                percent: `${isNaN(percent) ? 0 : Number(percent).toFixed(2)}%`, // eslint-disable-line
            });
            break;
        case 'updateAvailable':
            title = t('upgrade.title', { newVersion: version ? `V${version}` : '' });
            break;
        case 'updateNotAvailable':
            title = t('upgrade.checkNotAvailable');
            break;
        case 'updateDownloaded':
            title = t('upgrade.downloaded');
            break;
        case 'error':
            title = t('upgrade.error');
            log.error('app autoUpdate error', data);
            break;
        default:
    }
    const onUpdaterMessage = useCallback(
        (info: any) => {
            if (!isHomePage) {
                return;
            }
            setUpgradeData(info);
            if (
                ['updateNotAvailable', 'updateAvailable', 'checking', 'error'].indexOf(info.type) >=
                0
            ) {
                setVisible(true);
            }
        },
        [isHomePage]
    );

    const onCancel = useCallback(() => {
        if (forceUpdate) {
            return;
        }
        setVisible(false);
    }, [forceUpdate]);

    const checkUpgrade = useCallback(async () => {
        if (!isHomePage) {
            return;
        }
        const inputVersion = getVersionBySdk(focusSDK);
        if (inputVersion && appRuntimeEnv) {
            const versionSplits = inputVersion.split('-');
            const [data, error] = await checkUpgradeApp(versionSplits[0], appRuntimeEnv.platform);
            let { updateType, releaseNotes, version } = data || {};
            // updateType = 4;
            if (error) {
                lockCheckUpgrade = false;
            }

            const tempUpdateType = String(updateType);
            log.debug('[chat] tempUpdateType ', tempUpdateType);
            try {
                const tempReleaseNotes = releaseNotes.split(/\n/);
                setReleaseNotes(tempReleaseNotes);
            } catch (error) {}

            if (tempUpdateType === '1') {
                focusSDK.checkUpdate(true, version);
            } else if (tempUpdateType === '3' || tempUpdateType === '4') {
                focusSDK.checkUpdate(true, version, updateType);
            }
        }
    }, [focusSDK, isHomePage, appRuntimeEnv]);

    useEffect(() => {
        focusSDK.printLog('checkUpdate-navigator.onLine', navigator.onLine);
        if (!isHomePage || !navigator.onLine) {
            return;
        }

        if (!lockCheckUpgrade && appRuntimeEnv) {
            lockCheckUpgrade = true;
            const stt = setTimeout(() => {
                clearTimeout(stt);
                checkUpgrade();
            }, 1000);
        }
    }, [focusSDK, isHomePage, appRuntimeEnv, checkUpgrade]);

    useEffect(() => {
        focusSDK.on('updaterMessage', onUpdaterMessage);
        return () => {
            focusSDK.off('updaterMessage', onUpdaterMessage);
        };
    }, [focusSDK, onUpdaterMessage]);

    if (!isHomePage) {
        return null;
    }

    function handleUpdateDownloaded() {
        if (!canLoad.current) return;
        canLoad.current = false;
        setTimeout(() => {
            canLoad.current = true;
        }, 2000);
        focusSDK.sendMainMessage('autoUpdate', {
            action: 'quitAndInstall',
        });
    }

    function handleUpdateAvailable() {
        if (!canUpdateAvailable.current) return;
        canUpdateAvailable.current = false;
        setTimeout(() => {
            canUpdateAvailable.current = true;
        }, 2000);
        focusSDK.sendMainMessage('autoUpdate', { action: 'download' });
    }

    return (
        <Modal
            width={340}
            maskClosable={false}
            visible={visible}
            closable={!forceUpdate}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            closeIcon={
                <img
                    width="30"
                    src={changeEgovUrl(
                        `${REACT_APP_OSS_BASE_URL}/statics/images/modal_close_icon.png`
                    )}
                />
            }
            footer={null}
        >
            <div
                className="upgrade-modal-wrap"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className="bg-image" style={{ background: bgImage }} />
                <div
                    style={{
                        padding: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: 10,
                    }}
                >
                    <h1
                        style={{
                            fontSize: 16,
                            marginBottom: 15,
                        }}
                    >
                        {forceUpdate
                            ? t('upgrade.forceUpdateTitle', {
                                  newVersion: version ? `V${version}` : '',
                              })
                            : title}
                        {forceUpdate ? (
                            <p style={{ fontSize: 14, fontWeight: 'normal' }}>
                                {t('upgrade.forceUpdate')}
                            </p>
                        ) : null}
                    </h1>
                    {/* 在这儿加升级的信息 (tj: 更新失败显示错误提示) */}
                    {/* <ul>
                        <li>1.增加支持一人多主岗管理与展示功能</li>
                    </ul> */}
                    {upgradeType !== 'error' && upgradeType !== 'updateNotAvailable' ? (
                        <ul>
                            {releaseNotes.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    ) : (
                        upgradeType === 'error' && (
                            <ul>
                                <li>您的版本更新失败，建议你再次更新或者联系管理员</li>
                            </ul>
                        )
                    )}
                    <div className="upgrade-footer">
                        {upgradeType === 'updateDownloading' && !forceUpdate && (
                            <Button
                                type="text"
                                style={{
                                    height: 40,
                                }}
                                onClick={() => {
                                    focusSDK.sendMainMessage('autoUpdate', {
                                        action: 'abort',
                                    });
                                    onCancel();
                                }}
                                block
                            >
                                {t('upgrade.cancel')}
                            </Button>
                        )}
                        {upgradeType === 'updateDownloaded' && (
                            <Button
                                type="primary"
                                style={{
                                    height: 40,
                                    marginBottom: 10,
                                }}
                                block
                                onClick={handleUpdateDownloaded}
                            >
                                {t('upgrade.installAndQuit')}
                            </Button>
                        )}
                        {upgradeType === 'updateAvailable' && (
                            <>
                                <Button
                                    block
                                    type="primary"
                                    style={{
                                        height: 40,
                                    }}
                                    onClick={handleUpdateAvailable}
                                >
                                    {t('upgrade.update')}
                                </Button>
                                {!forceUpdate && (
                                    <Button
                                        type="text"
                                        block
                                        style={{
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            onCancel();
                                        }}
                                    >
                                        {t('upgrade.skip')}
                                    </Button>
                                )}
                            </>
                        )}
                        {['updateNotAvailable', 'error'].includes(upgradeType) && (
                            <Button
                                block
                                style={{
                                    height: 40,
                                }}
                                onClick={() => {
                                    onCancel();
                                }}
                            >
                                {t('upgrade.close')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
