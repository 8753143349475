import React, { useState, useEffect } from 'react';
import { Modal, Tabs } from 'antd';
import './index.less';
import IconFont, { IconDocHome, IconMyDoc } from '@/components/icon';
import { MeetingQueryType } from '@/types/common';
import bus from '@/utils/bus';
import MeetingList from './MeetingList';

const { TabPane } = Tabs;

export default function JoinMeetingModal() {
    const [visible, setVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');

    function closeModal() {
        setVisible(false);
    }

    function onCancel() {
        closeModal();
    }

    useEffect(() => {
        function handleModalVisible() {
            setVisible(true);
        }
        bus.on('cloudlink:meeting-list-show', handleModalVisible);
        return () => {
            bus.off('cloudlink:meeting-list-show', handleModalVisible);
        };
    }, []);

    return (
        <Modal
            width={720}
            title="我的会议"
            visible={visible}
            onCancel={onCancel}
            destroyOnClose={true}
            closeIcon={<IconFont type="iconapp_btn_file_cancel" />}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div className="my-meeting-list">
                <Tabs
                    tabPosition="left"
                    activeKey={activeKey}
                    renderTabBar={(props, DefaultTabBar) => {
                        return (
                            <div className="meeting-tab">
                                <div
                                    className={props.activeKey === '1' ? 'active' : ''}
                                    onClick={() => {
                                        setActiveKey('1');
                                    }}
                                >
                                    {IconDocHome} 我的会议
                                </div>
                                <div
                                    clstag="pageclick|keycount|xtbg_PCHMeeting|Mymeeting_Hirstroy"
                                    className={props.activeKey === '2' ? 'active' : ''}
                                    onClick={() => {
                                        setActiveKey('2');
                                    }}
                                >
                                    {IconMyDoc} 已结束
                                </div>
                            </div>
                        );
                    }}
                >
                    <TabPane key="1">
                        <MeetingList
                            onCancel={onCancel}
                            meetingType={MeetingQueryType.ONGOING_AND_UPCOMING}
                        />
                    </TabPane>
                    <TabPane key="2">
                        <MeetingList onCancel={onCancel} meetingType={MeetingQueryType.CLOSED} />
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
}
