import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import BaseTextArea from '../../components/BaseTextArea';
export interface IProps {
    isVisible?: boolean;
    onOk?: (reason: string) => void;
    onCancel?: () => void;
}

export default function RejectReason(props: IProps) {
    const { isVisible, onOk, onCancel } = props;
    const [reason, setReason] = useState('');
    const onOkHandler = useCallback(() => {
        // console.log(reason);
        onOk?.(reason);
    }, [onOk, reason]);
    function onCancelHandler() {
        onCancel?.();
    }
    return (
        <Modal
            title="拒绝原因"
            visible={isVisible}
            okText="保存"
            cancelText="取消"
            destroyOnClose={true}
            onOk={onOkHandler}
            onCancel={onCancelHandler}
            width={510}
        >
            <BaseTextArea
                style={{ flex: 1, fontSize: '18px' }}
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder="请填写拒绝日程原因"
                value={reason}
                maxLength={200}
                showCount={true}
                className="calendar-custom-textarea schedule-textarea"
                onChange={(e) => setReason(e.target.value)}
            />
        </Modal>
    );
}
