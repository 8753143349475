/**
 * 回到最后组件
 * @author sunzhiguang
 * @date 2020/6/16
 */

import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import React from 'react';
import './index.less';

interface IProps {
    onGoEnd?: () => void;
}

const GoEnd = (props: IProps) => {
    const { onGoEnd } = props;
    const { t } = useTranslation('chat');
    return (
        <div
            className="message-back-bottom"
            onClick={() => {
                onGoEnd?.();
            }}
        >
            <IconFont style={{ marginRight: 4 }} type="iconapp_ic_arrow" /> {t('goEnd')}
        </div>
    );
};

export default GoEnd;
