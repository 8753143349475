import React from 'react';
import bgImg from '@/assets/templateImgs/bg.png';
import { leftDesc, leftTitle } from '@/config/name';

function JoyCarousel() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div
                className="largeTitle"
                style={{ marginBottom: '22px', fontSize: '30px', fontWeight: 'bold' }}
            >
                {leftDesc}
            </div>
            <div className="subTitle" style={{ width: '285px' }}>
                {leftTitle}
            </div>
        </div>
    );
}

export default function LeftSide() {
    return (
        <div className="loginLeftSide">
            <div
                style={{
                    width: 430,
                    height: '100%',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: `url(${bgImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                }}
                className="leftBg"
            >
                <JoyCarousel />

                {/* <img
                    style={{ width: 430, height: 'auto', position: 'absolute', bottom: 0 }}
                    src="https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/me-login/prod/img/ad_bottom.png"
                    alt="ad_bottom"
                />
                <img
                    style={{ width: 430, height: 'auto', position: 'absolute', top: 0 }}
                    src="https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/me-login/prod/img/ad_top.png"
                    alt="ad_bottom"
                /> */}
            </div>
        </div>
    );
}
