import { request } from '@/components/Joyspace/api/request';

export const applyApprove = (
    applyCode: string,
    // applyUserId: string,
    // permissionType: any,
    // applyReason: string,
    cardMsgId: string
    // eslint-disable-next-line max-params
) =>
    request({
        api: 'joyspace.pageApply.approve',
        path: `/v1/pageApply/approve`,
        method: 'POST',
        body: {
            applyCode,
            cardMsgId,
            // pageId,
            // applyUserId,
            // applyReason,
            // permissionType,
        },
    });

export const applyReject = (
    applyCode: string,
    // applyUserId: string,
    // permissionType: any,
    // applyReason: string,
    cardMsgId: string
    // eslint-disable-next-line max-params
) =>
    request({
        api: 'joyspace.pageApply.reject',
        path: `/v1/pageApply/reject`,
        method: 'POST',
        body: {
            applyCode,
            cardMsgId,
            // pageId,
            // applyUserId,
            // applyReason,
            // permissionType,
        },
    });
