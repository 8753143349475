/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { IndicatorContent } from './../../Common/index';
import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import './index.less';
import { ResStatus } from '@/api/calendar';
import { userPng } from '@/assets/img/calendar';
import { CalendarIcon } from '@/components/Calendars/Common';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { Avatar } from '@/baseComponents/Avatar';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import UserStatusListPopover from './UserStatusListPopover';
import IconFont from '@/components/icon';
import style from './index.module.less';
import { WorkUserInfo } from '@/types/work';
import { UserCardType } from '@/types/UserCard';
import Bus from '@/utils/bus';
import { changeEgovUrl } from '@/utils/tools';

export interface IProps {
    acceptCount: Number;
    unreadCount: Number;
    rejectCount: Number;
    content: any;
    userData: any;
}
const maxLenght = 7;
function ParticipantIndicator(props: IProps) {
    const { t } = useTranslation('calendar');
    const { acceptCount, unreadCount, rejectCount, content, userData } = props;
    const [listObj, setListObj] = useState<any>([]);

    useEffect(() => {
        let participantObjs: { title: string; list: any[]; clstag: string }[] = [
            {
                title: t('some-accept'),
                list: [],
                clstag: 'pageclick|keycount|xtbg_calhome_1640073907694|6',
            },
            {
                title: t('some-unread'),
                list: [],
                clstag: 'pageclick|keycount|xtbg_calhome_1640073907694|7',
            },
            {
                title: t('some-reject'),
                list: [],
                clstag: 'pageclick|keycount|xtbg_calhome_1640073907694|8',
            },
        ];
        content.participant.forEach((item: any) => {
            let participantObj = null;
            switch (item.resStatus) {
                case ResStatus.Agree:
                    participantObj = participantObjs[0];
                    break;
                case ResStatus.Unread:
                    participantObj = participantObjs[1];
                    break;
                case ResStatus.Reject:
                    participantObj = participantObjs[2];
                    break;
                default:
                    participantObj = participantObjs[0];
                    break;
            }
            item.user.rejectReason = item.message;
            item.user.resStatus = item.resStatus;
            participantObj.list.push(item.user);
        });
        setListObj(participantObjs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, t]);

    let participantRenderList = [...content.participant];
    let isShowMoreBtn = false;

    if (participantRenderList.length > maxLenght) {
        participantRenderList.splice(maxLenght);
        isShowMoreBtn = true;
    }

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId: userData?.ddAppId || appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }

    return (
        <IndicatorContent
            indicator={() => (
                <div className="calendar-tooltip-content-label" style={{ marginTop: '0px' }}>{`${t(
                    'participant'
                )}`}</div>
            )}
        >
            <p className="info info-important info-important-participant">
                <UserStatusListPopover
                    listObj={listObj}
                    label="participant"
                    disable={true}
                    userData={userData}
                >
                    <span className="exact-info">
                        {t('total')}
                        {Number(acceptCount) + Number(unreadCount) + Number(rejectCount)}
                        {t('people')}（{t('some-accept')}
                        {acceptCount}
                        {t('people')}，{t('some-unread')}
                        {unreadCount}
                        {t('people')}，{t('some-reject')}
                        {rejectCount}
                        {t('people')}）
                        {/* {acceptCount}
                    {t('some-accept')}，{unreadCount}
                    {t('some-unread')}，{rejectCount}
                    {t('some-reject')} */}
                    </span>
                    <IconFont
                        type="iconlog_enter"
                        className={`${style['icon-more']} icon-toggle`}
                        style={{ color: '#DEE0E3' }}
                        clstag="pageclick|keycount|xtbg_calhome_1640073907694|5"
                    />
                </UserStatusListPopover>
            </p>
            {/* 
            <div className="user-list df">
                <div className="user-item">
                    <Avatar
                        src={changeEgovUrl(getHeadImgThumbUrl(content.user?.imageUrl, 150, 150))}
                        className="avator avatarNoDraggable"
                        name={content.user?.realName || ''}
                    />

                    <p className="tag">{t('organizer')}</p>
                    <p className="name">{content.user?.realName}</p>
                </div>
                {participantRenderList?.map((item: any) => {
                    const staus =
                        item.resStatus === ResStatus.Agree
                            ? 'accept'
                            : item.resStatus === ResStatus.Reject
                            ? 'reject'
                            : 'unread';
                    return (
                        <div
                            className={`user-item ${staus}`}
                            key={item.user?.userId}
                            onClick={() => {
                                if (item.user) {
                                    openUserCard(item.user);
                                }
                            }}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <Avatar
                                src={changeEgovUrl(
                                    getHeadImgThumbUrl(item.user?.imageUrl, 150, 150)
                                )}
                                className="avator avatarNoDraggable"
                                name={item.user?.realName || ''}
                                styleObj={{ width: '32px', height: '32px', borderRadius: '4px' }}
                            />
                            {item.resStatus === ResStatus.Agree ? (
                                <CheckCircleFilled className="tag-icon accept" />
                            ) : item.resStatus === ResStatus.Reject ? (
                                <MinusCircleFilled className="tag-icon reject" />
                            ) : null}
                            <div className="mask">
                                <span>
                                    //解决网页版未回复/已拒绝等三字文案，由于chrome、火狐等浏览器默认最小字体12px，设置10px不生效导致的展示不全问题
                                    <em>
                                        {item.resStatus === ResStatus.Agree
                                            ? t('accepted')
                                            : item.resStatus === ResStatus.Reject
                                            ? t('rejected')
                                            : t('unanswered')}
                                    </em>
                                </span>
                            </div>
                        </div>
                    );
                })}
                {isShowMoreBtn ? <div className={style['more-btn']}>...</div> : ''}
            </div> */}
        </IndicatorContent>
    );
}

export default ParticipantIndicator;
