enum ErrorCode {
    CommonError = '100000',
    ErrUnauthorized = '100401',
}

export type GlobalErrorConfig =
    | {
          type: 'message' | 'modal';
          error: GError;
      }
    | string
    | GError
    | Error;

export default class GError extends Error {
    static fromError(err: Error) {
        return new GError(ErrorCode.CommonError, err.message, err.stack);
    }
    code: string | number;
    msg: string;
    debugMsg?: string;
    constructor(code: string | number, msg: string, debugMsg?: string) {
        super();
        this.code = code;
        this.msg = msg;
        this.debugMsg = debugMsg;
    }
    toString() {
        // return `[${this.code}]${this.msg}`;
        return this.msg;
    }
}

export const ErrUnauthorized = new GError(ErrorCode.ErrUnauthorized, 'ErrUnauthorized');
