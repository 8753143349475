import openAddMembersModal from '@/components/AddMembers';
// import { openUserSelectionModal } from '@/utils/modals';
import { UserSelectionResult } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import { IMember, ContactsFilterFlag } from '../../types';
// import { FocusSDK } from '@/utils';
import lodashCompact from 'lodash/compact';
type ListItemData = UserSelectionResult['data']['result'];

function toMembers(data: ListItemData) {
    const __data = lodashCompact(data || [])
        .filter((item) => !item.checked)
        .map((resultItem) => ({
            name: resultItem.name,
            avatar: resultItem.avatar,
            userId: resultItem.origin?.info.userId,
            teamId: resultItem.origin?.info.teamId,
            app: resultItem.origin?.info.appId || resultItem.origin?.info.app,
        }));
    return __data as IMember[];
}

function toSelectedData(data: Partial<IMember>[]) {
    return data.map((item) => item.userId + ':' + item.app + ':' + item.teamId) as string[];
}
function toResultUsers(data: Partial<IMember>[]) {
    return data.map((item) => ({
        id: item?.userId + ':' + item?.app + ':' + item?.teamId,
        name: item?.name,
        avatar: item?.avatar,
        checked: true,
    }));
}

interface Options {
    title: string;
    teamId: string;
    contactsFilterFlag?: ContactsFilterFlag;
    selected?: Partial<IMember>[];
    currentUser?: {
        app: string;
        pin: string;
        teamId: string;
    };
}

export default (options: Options, callback: (data: IMember[], close: () => void) => void) => {
    /*     FocusSDK.userSelection(
        {
            title: options.title,
            tabs: ['org'],
            frozenIds: options.selected ? toSelectedData(options.selected) : [],
        },
        (result, close) => {
            callback(toMembers(result?.data?.result), close);
        }
    ); */
    openAddMembersModal(
        {
            title: options.title,
            contactsFilterFlag: 'external',
            tabs: ['recent', 'org'],
            frozenIds: options.selected ? toSelectedData(options.selected) : [],
            resultUsers: options.selected ? toResultUsers(options.selected) : [],
            currentUser: options.currentUser,
        },
        (data: UserSelectionResult, close: () => void) => {
            const members = toMembers(data?.data?.result);
            callback(members, close);
        },
        options.teamId
    );
    // openUserSelectionModal(
    //     {
    //         title: options.title,
    //         tabs: ['recent', 'org'],
    //         frozenIds: options.selected ? toSelectedData(options.selected) : [],
    //         currentUser: {} as any,
    //     },
    //     (data, close) => {
    //         // 过滤掉已选中的
    //         const { result } = data.data;

    //         close();
    //     },
    //     {selectedTeamId:options.teamId}
    // );
};
