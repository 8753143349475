import React, { useMemo, useState, useEffect, useReducer, useRef, useContext } from 'react';
import './index.less';
import { Draggable } from 'react-beautiful-dnd';
import {
    TaskModel,
    WorkUserInfo,
    TaskStatus,
    TimeClassifyEnum,
    TaskSourceTypeEnum,
    TaskPermissionEnum,
} from '@/types/work';
import { connect } from 'dva';
import MyCheckBox from '../MyCheckbox';
import ConfirmPopover from '../../ConfirmPopover';
import { Tooltip, Input, message, Progress, Radio } from 'antd';
import IconFont from '@/components/icon';
import PrioritySelect from '../PrioritySelect';
import DatePicker from '../DatePicker';
import { Dayjs } from 'dayjs';
import WorkUserList from '../UserList';
import {
    updatePersonalTask,
    updateTaskProcess,
    addTaskMembers,
    delTaskMembers,
    deleteTask,
    taskSortUpdate,
} from '@/api/work';
import {
    findSelfInExecutors,
    getTimeClassifyByTime,
    generateTimeRange,
    findIndexExecutorInTask,
    judgeTaskFinish,
    updateExecutorInTask,
    getDefaultStopTime,
    transformUserDataToWorkUserInfo,
} from '../../utils';
import TaskProgress from '../TaskProgress';
import WorkEvent from '../../WorkEvent';
import { useTranslation } from 'react-i18next';
import TaskItemMoreBtn from '../TaskItemMoreBtn';
import { useDebounce, isFocusEnv } from '@/utils';
import context from '@/components/Projects/context';

function mapStateToProps({ work, user }: any) {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeFullIframeModal: (visible: boolean, url: string) =>
            dispatch({
                type: 'work/changeFullIframeModal',
                payload: { fullIframeModalVisible: visible, fullIframeModalUrl: url },
            }),
    };
}

interface IState {
    checked: boolean;
    confirmpopoverVisible: boolean;
    checkboxTooltipVisible: boolean;
    titleInputVisible: boolean;
    inputValue: string;
}

const initialState: IState = {
    checked: false,
    confirmpopoverVisible: false,
    checkboxTooltipVisible: false,
    titleInputVisible: false,
    inputValue: '',
};

function reducer(state: IState, action: any) {
    const { payload } = action;
    switch (action.type) {
        case 'changeState':
            return { ...state, ...payload };
        case 'changeChecked':
            // eslint-disable-next-line no-case-declarations
            const { checked } = payload;
            return { ...state, checked };
        case 'setConfirmPopoverVisible':
            // eslint-disable-next-line no-case-declarations
            if (payload.visible) {
                return { ...state, confirmpopoverVisible: true, checkboxTooltipVisible: false };
            }
            return { ...state, confirmpopoverVisible: payload.visible };
        case 'setTitleInputVisible':
            // eslint-disable-next-line no-case-declarations
            if (payload.visible) {
                return {
                    ...state,
                    confirmpopoverVisible: false,
                    checkboxTooltipVisible: false,
                    titleInputVisible: true,
                    inputValue: payload.value,
                };
            }
            return { ...state, titleInputVisible: payload.visible };
        case 'setCheckboxTooltipVisible':
            // eslint-disable-next-line no-case-declarations
            const { visible } = payload;
            if (state.confirmpopoverVisible) {
                return { ...state, checkboxTooltipVisible: false };
            }
            return { ...state, checkboxTooltipVisible: visible };
        case 'onInputValueChange':
            return { ...state, inputValue: payload.value };
        default:
            throw new Error();
    }
}

function TaskItem({
    task,
    updatePersonalTaskItem,
    timeBlockId,
    sort,
    userData,
    delPersonalTaskInTimeBlock,
    changePersonalTaskDraw,
    labelColor,
    isDropDisabled,
    changeFullIframeModal,
    projectId,
    isReadOnly = false,
    changeSelectedTaskItem,
}: {
    task: TaskModel;
    timeBlockId: string;
    sort: number;
    labelColor: string;
    updatePersonalTaskItem: (timeBlockId: string, task: TaskModel) => void;
    userData: any;
    isDropDisabled?: boolean;
    changePersonalTaskDraw: (taskId: string) => void;
    delPersonalTaskInTimeBlock: (timeBlockId: string, taskId: string) => void;
    changeFullIframeModal: (visible: boolean, url: string) => void;
    projectId: string;
    isReadOnly?: boolean;
    changeSelectedTaskItem?: (checkedTaskItem: TaskModel) => void;
}) {
    const { t } = useTranslation('work');
    const textRef = useRef<HTMLInputElement>(null);
    const { t: t_project } = useTranslation('project_mebjJZB');
    const [isHead, self, isOnlySelf] = useMemo(() => {
        if (!task) {
            return [false, null, false];
        }
        let isHead = false;
        let self = null;
        let isOnlySelf = false;
        const header = findSelfInExecutors(task.owners, userData);
        if (header) {
            isHead = true;
        }
        // 强制可编辑权限，等同于负责人待遇
        if (task.enablePermission && task.permission.includes(TaskPermissionEnum.TASK_UPDATE)) {
            isHead = true;
        }
        self = findSelfInExecutors(task.executors, userData);
        if (self && task.executors.length === 1) {
            isOnlySelf = true;
        }
        return [isHead, self, isOnlySelf];
    }, [task, userData]);

    const participants = useMemo(() => {
        const arr: any = [];
        [...task.owners, ...task.executors, transformUserDataToWorkUserInfo(userData)].forEach(
            (user) => {
                const index = arr.findIndex(
                    (a: any) =>
                        `${a.userId}:${a.app}:${a.teamId}` ===
                        `${user.userId}:${user.app}:${user.teamId}`
                );
                if (index === -1) {
                    arr.push({
                        app: user.app,
                        teamId: user.teamId,
                        userId: user.userId,
                    });
                }
            }
        );
        return arr;
    }, [task.executors, task.owners, userData]);

    /**
     * 当不是负责人也不是执行人的时候，删掉本任务
     */
    // useEffect(() => {
    //     if (!isHead && !self) {
    //         delPersonalTaskInTimeBlock('', task.taskId);
    //     }
    // }, [delPersonalTaskInTimeBlock, isHead, self, task.taskId]);

    const [isTaskFinish] = useMemo(() => {
        if (!task) {
            return [false, false];
        }
        let isTaskFinish = false;

        if (judgeTaskFinish(task.taskStatus)) {
            isTaskFinish = true;
        }

        return [isTaskFinish];
    }, [task]);

    const checked = useMemo(() => {
        return isTaskFinish;
    }, [isTaskFinish]);

    const [state, dispatch] = useReducer(reducer, initialState);

    const disabled = useMemo(() => {
        if (isTaskFinish) {
            return true;
        }

        if (task.enablePermission) {
            if (task.permission.includes(TaskPermissionEnum.TASK_UPDATE)) {
                return false;
            } else {
                return true;
            }
        }
        return isHead || self ? isTaskFinish : true;
    }, [isHead, isTaskFinish, self, task.enablePermission, task.permission]);

    const checkboxDisable = useMemo(() => {
        if (task.enablePermission) {
            if (task.permission.includes(TaskPermissionEnum.TASK_COMPLETE)) {
                return false;
            } else {
                return true;
            }
        }
        if (isHead || self) {
            return false;
        }
        return true;
    }, [isHead, self, task.enablePermission, task.permission]);

    const addMemberDisable = useMemo(() => {
        if (!task) {
            return true;
        }
        if (task.executors.length >= 200) {
            return true;
        }
        return false;
    }, [task]);

    const canDel = useMemo(() => {
        if (task.enablePermission) {
            if (task.permission.includes(TaskPermissionEnum.TASK_DELETE)) {
                return true;
            } else {
                return false;
            }
        }
        if (isHead || self) {
            return true;
        }
        return false;
    }, [isHead, self, task.enablePermission, task.permission]);

    const [unfinishList, finishList] = useMemo(() => {
        const unfinishList: any[] = [];
        const finishList: any[] = [];
        task.executors.forEach((l) => {
            if (!judgeTaskFinish(l.processStatus)) {
                unfinishList.push(l);
            } else {
                finishList.push(l);
            }
        });
        return [unfinishList, finishList];
    }, [task.executors]);
    /**
     * 1. 点击时，如果是执行人，直接发起请求；如果是负责人，则唤起二次确认
     * 2. 取消时，如果是执行人，发起取消完成的请求；如果是负责人？
     * 3. 执行人的请求完成后，变更任务信息，负责人的走二次确认框
     */
    // eslint-disable-next-line complexity
    async function handleCheckboxChange(e: any) {
        const flag = e.target.checked;
        const status = flag === true ? TaskStatus.Finish : TaskStatus.New;
        // 在完成时间块，不允许有从未完成到完成
        if (flag && Number(timeBlockId) === TimeClassifyEnum.Finished) {
            return;
        }
        // 在其他时间块，不允许有从完成到未完成
        if (!flag && Number(timeBlockId) !== TimeClassifyEnum.Finished) {
            return;
        }
        if (flag) {
            return handleOrderByTaskFinish({ taskStatus: status });
        } else {
            return handleOrderByTaskUnFinish({ taskStatus: status });
        }
    }

    function handleOrderByTaskFinish({
        taskStatus,
        processStatus,
    }: {
        taskStatus?: number;
        processStatus?: number;
    }) {
        const destination = {
            droppableId: TimeClassifyEnum.Finished.toString(),
            index: 0,
        };
        const source = {
            index: sort,
            taskId: task.taskId,
            droppableId: timeBlockId,
        };
        WorkEvent.emit('reorderByTaskStatusChange', {
            source,
            destination,
            task,
            taskStatus,
            processStatus,
            self,
            projectId,
        });
    }
    function handleOrderByTaskUnFinish({
        taskStatus,
        processStatus,
    }: {
        taskStatus?: number;
        processStatus?: number;
    }) {
        const timeClassify = getTimeClassifyByTime(task.workStopTime);
        const destination = {
            droppableId: timeClassify.toString(),
            index: 0,
        };
        const source = {
            index: sort,
            taskId: task.taskId,
            droppableId: timeBlockId,
        };
        WorkEvent.emit('reorderByTaskStatusChange', {
            source,
            destination,
            task,
            taskStatus,
            processStatus,
            self,
            projectId,
        });
    }
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        // change background colour if dragging
        // background: isDragging ? 'lightgreen' : 'grey',
        // opacity: isDragging ? 0.5 : 1,
        // styles we need to apply on draggables
        // tramsform: isDragging ? 'none' : 'rolate(40deg)',
        ...draggableStyle,
    });
    function setInputVisible(e: any) {
        if (disabled) {
            return;
        }
        e.stopPropagation();
        dispatch({ type: 'setTitleInputVisible', payload: { visible: true, value: task.title } });
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        dispatch({ type: 'onInputValueChange', payload: { value } });
    }

    const handleTitleChange = useDebounce(async () => {
        if (!state.inputValue || task.title === state.inputValue) {
            dispatch({ type: 'setTitleInputVisible', payload: { visible: false } });
            return;
        }
        const [result, err] = await updatePersonalTask({
            taskId: task.taskId,
            title: state.inputValue,
        });
        if (err || !result) {
            message.error(err as String);
            dispatch({ type: 'setTitleInputVisible', payload: { visible: false } });
            return;
        }
        updatePersonalTaskItem(timeBlockId, { ...task, title: state.inputValue });
        dispatch({ type: 'setTitleInputVisible', payload: { visible: false } });
    }, 300);

    async function handlePriorityChange(priority: number) {
        if (isReadOnly) {
            return false;
        }
        if (task.priorityType === priority) {
            return;
        }
        const [result, err] = await updatePersonalTask({
            taskId: task.taskId,
            priorityType: priority,
        });
        if (err || !result) {
            message.error(err as string);
            return;
        }
        updatePersonalTaskItem(timeBlockId, { ...task, priorityType: priority });
    }

    async function handleTimeChange(time: Dayjs | null) {
        if (task.workStopTime && time?.isSame(task.workStopTime)) {
            return;
        }
        if (time && task.workStartTime?.isSameOrAfter(time)) {
            return message.warn(t('The deadline must not be earlier than the start time'));
        }
        const destination = {
            droppableId: getTimeClassifyByTime(time).toString(),
            index: 0,
        };
        // 如果时间块没有变化，则直接更新即可
        if (destination.droppableId === timeBlockId) {
            // 更新截止时间
            const [result, err] = await updatePersonalTask({
                taskId: task.taskId,
                endTime: time ? time.valueOf().toString() : '-1',
            });
            if (err || !result) {
                message.error(err as string);
                return;
            }
            updatePersonalTaskItem(timeBlockId, {
                ...task,
                workStopTime: time,
                endTime: time ? time.valueOf().toString() : '',
            });
            return;
        }
        const source = {
            index: sort,
            droppableId: timeBlockId,
        };
        WorkEvent.emit('reorderByTimeChange', {
            source,
            destination,
            task,
            newTime: time,
            projectId,
        });
    }

    async function handleAddTaskMembers(data: any[]) {
        const timeRange = generateTimeRange(Number(timeBlockId));
        const temp: any[] = [];
        const { executors } = task;
        data.forEach((m: any) => {
            const index = findIndexExecutorInTask(executors, m);
            if (index === -1) {
                temp.push(m);
            }
        });
        if (temp.length === 0) {
            return;
        }
        if ([...executors, ...temp]?.length > 200) {
            message.warn(t('200 person limit'));
            return;
        }
        const [result, err] = await addTaskMembers({
            taskId: task.taskId,
            members: temp,
            ...timeRange,
        });
        if (err) {
            message.error(err as string);
            return;
        }
        const { members = [] } = result as any;
        members.forEach((m: any) => {
            const index = findIndexExecutorInTask(executors, m);
            if (index === -1) {
                executors.push(m);
            }
        });
        task.executors = [...executors];
        updatePersonalTaskItem(timeBlockId, { ...task });
    }

    async function handledelMember(members: any[]) {
        if (isReadOnly) {
            return false;
        }
        const [result, err] = await delTaskMembers({ taskId: task.taskId, members });
        if (err) {
            return message.error(err);
        }
        const executors = [...task.executors];
        members.forEach((m) => {
            const index = findIndexExecutorInTask(executors, m);
            if (index > -1) {
                executors.splice(index, 1);
            }
        });
        updatePersonalTaskItem(timeBlockId, { ...task, executors });
    }

    function handleTextMouseEnter() {
        const current = textRef.current;
        if (!current) {
            return;
        }
        current.style.border = '1px dashed #8F959E';
    }
    function handleTextMouseLeave() {
        const current = textRef.current;
        if (!current) {
            return;
        }
        current.style.border = '1px dashed #f6f7f8';
    }

    const handleTaskClick = useDebounce(() => {
        if (isReadOnly) {
            return false;
        }
        if (task.extraConf) {
            const { openView } = task.extraConf;
            switch (openView) {
                case 1:
                    break;
                case 3:
                    if (!task.content) {
                        return;
                    }
                    if (isFocusEnv()) {
                        window.open(task.content);
                        return;
                    }
                    changeFullIframeModal(true, task.content || '');
                    return;
                default:
                    break;
            }
        }
        changePersonalTaskDraw(task.taskId);
    }, 250);

    async function handleDelTask() {
        const [result, err] = await deleteTask({ taskId: task.taskId, type: 1 });
        if (err) {
            return message.error(err);
        }
        delPersonalTaskInTimeBlock(timeBlockId, task.taskId);
    }

    function checkTaskItem(checkedTaskItem: TaskModel) {
        if (changeSelectedTaskItem) {
            changeSelectedTaskItem(checkedTaskItem);
        }
    }

    return (
        <Draggable
            key={task.taskId}
            draggableId={task.taskId}
            index={sort}
            isDragDisabled={isDropDisabled || isReadOnly}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    className={`work-personal-taskitem ${
                        isTaskFinish ? 'work-personal-taskitem-finish' : ''
                    }
                    ${snapshot.isDragging && !isReadOnly ? 'work-personal-taskitem-dragging' : ''}
                    `}
                >
                    {isReadOnly && (
                        <Radio
                            disabled={task.taskStatus !== 1}
                            value={task}
                            onChange={(e) => checkTaskItem(e.target.value)}
                        />
                    )}
                    {!isReadOnly && (
                        <TaskItemMoreBtn
                            onRemove={handleDelTask}
                            isHead={isHead}
                            disable={!canDel}
                        />
                    )}
                    <div className="prefix" style={{ backgroundColor: labelColor }} />

                    <div
                        className={`task-title
                         ${
                             state.titleInputVisible && !isReadOnly
                                 ? 'task-title-input-visible'
                                 : ''
                         }`}
                    >
                        {!isReadOnly && (
                            <Tooltip
                                title={t('Marking completed')}
                                visible={state.checkboxTooltipVisible}
                                onVisibleChange={(visible: boolean) => {
                                    if (checkboxDisable) {
                                        return;
                                    }
                                    dispatch({
                                        type: 'setCheckboxTooltipVisible',
                                        payload: { visible },
                                    });
                                }}
                            >
                                <div className="checkbox">
                                    <MyCheckBox
                                        disabled={checkboxDisable}
                                        checked={checked}
                                        onChange={handleCheckboxChange}
                                        fillColor={false}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        {state.titleInputVisible && !isReadOnly ? (
                            <Input
                                defaultValue={task.title}
                                autoFocus={true}
                                allowClear
                                onChange={handleInputChange}
                                onBlur={handleTitleChange}
                                onPressEnter={handleTitleChange}
                                maxLength={100}
                            />
                        ) : (
                            <div className="task-title-content" onClick={handleTaskClick}>
                                <span className="text" ref={textRef}>
                                    {task.title}
                                </span>
                                {!projectId && task.projectId && (
                                    <div className="task-title-label">{t_project('zhuanban')}</div>
                                )}
                                {!isReadOnly && (
                                    <span
                                        className="title-edit"
                                        onClick={setInputVisible}
                                        onMouseEnter={handleTextMouseEnter}
                                        onMouseLeave={handleTextMouseLeave}
                                    >
                                        <IconFont type="iconic_editor" />
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {!!task.duCount && (
                        <div className="task-du">
                            <IconFont type="iconzw_app_remind2" />+{task.duCount}
                        </div>
                    )}
                    {/* {task.childWorks.length !== 0 && (
                        <div className="task-progress">
                            <TaskProgress
                                list={task.childWorks}
                                disabled={disabled || isReadOnly}
                                handledelMember={handledelMember}
                            />
                        </div>
                    )} */}
                    {/* {task.childWorks?.length > 0 && (
                        <div className="task-subtask">
                            <TaskItemSubtask subtasks={task.childWorks || []} />
                        </div>
                    )} */}
                    <div className="task-priority">
                        <PrioritySelect
                            priority={task.priorityType}
                            onChange={handlePriorityChange}
                            disable={disabled || isReadOnly}
                        />
                    </div>
                    <div className="task-date-picker">
                        <DatePicker
                            time={task.workStopTime}
                            onChange={handleTimeChange}
                            disable={disabled || isReadOnly}
                            placeholder={t('set end time')}
                            defaultTime={getDefaultStopTime()}
                            isEnd={true}
                            // endLogic={true}
                        />
                    </div>

                    {/* <div className="task-create-chat">
                        <CreateChat userData={userData} task={task} />
                    </div> */}

                    <div className="task-userlist">
                        <WorkUserList
                            disable={disabled || isReadOnly}
                            owner={task.owners[0]}
                            list={task.executors}
                            handleAddTaskMembers={handleAddTaskMembers}
                            maxLength={1}
                            onRemove={handledelMember}
                            addMemberDisable={addMemberDisable}
                            projectId={task.projectId}
                            title={task.title}
                        />
                    </div>
                    {/* <div style={{ width: 100 }}>{task.sort}</div> */}
                </div>
            )}
        </Draggable>
    );
}

// export default TaskItem;
export default connect(mapStateToProps, mapDispatchToProps)(TaskItem);
