/**
 * 用户电话号码组件
 */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { examineMobileRecord } from '@/api/addressBook';
import { getEBookUserDetail } from '@/api/ebook';
import { UserCardData, UserCardItem } from '@/types/UserCard';
import { UserModel } from '@/models/user';
import { connect } from 'dvajs';
import { IconCopyBtn } from '@/components/icon';
import { debounce } from 'lodash';

function UserMobile(props: {
    user: UserCardData;
    isSelf: boolean;
    appId: string;
    userId: string;
    teamId: string;
}) {
    const { user, isSelf, appId, teamId, userId } = props;
    const { t } = useTranslation('addressList');
    let [isShow, setDisplayState] = useState(false);
    const mobileRef = useRef<HTMLParagraphElement | null>(null);

    const mobileData = user.fields.find((item: UserCardItem) => item.key === 'mobile');

    if (!mobileData) {
        return null;
    }

    const useMobile = (isShow: boolean, mobileData: UserCardItem) => {
        const mobile =
            isShow || isSelf
                ? mobileData.val
                : mobileData.val.substring(0, 3) + '****' + mobileData.val.substring(7);
        return [mobile];
    };

    const Mobile = (props: { isShow: boolean; mobileData: UserCardItem }) => {
        const [mobile] = useMobile(props.isShow, props.mobileData);
        return (
            <span className="usercard-item-desc mobile-desc" ref={mobileRef}>
                {mobile}
            </span>
        );
    };

    const CheckFullNumber = (props: {}) => {
        const [isSending, setIsSending] = useState(false);
        const isMounted = useRef(true);

        useEffect(() => {
            return () => {
                isMounted.current = false;
            };
        }, []);

        const checkPermission = useCallback(async () => {
            if (isSending) return;
            setIsSending(true);
            const currUser: any = await getEBookUserDetail({
                userId: userId,
                teamId: teamId,
                appId: appId,
            });

            const clickerName = currUser.fields.find((item: UserCardItem) => item.key === 'name')
                .val;
            const clickerSex = currUser.fields.find((item: UserCardItem) => item.key === 'sex').val;
            const examinedName = (user as any).fields.find(
                (item: UserCardItem) => item.key === 'name'
            ).val;
            const examinedSex = (user as any).fields.find(
                (item: UserCardItem) => item.key === 'sex'
            ).val;
            const [result, err] = await examineMobileRecord({
                clickerUserId: userId,
                clickerName,
                clickerTeamId: teamId,
                clickerSex,
                examinedUserId: user.userId,
                examinedName,
                examinedTeamId: user.teamId,
                examinedSex,
                appId: user.appId,
            });
            setIsSending(false);
            if (err) {
                message.error(err);
                return;
            }
            if (result === true) {
                setDisplayState(true);
            } else if (result === false) {
                message.info(t('user_card.full_times'));
            }
        }, [isSending]);

        return (
            <Button
                type="link"
                onClick={checkPermission}
                style={{ lineHeight: '22px' }}
                clstag="pageclick|keycount|xtbg_add_home_1637657110163|24"
            >
                点击查看
            </Button>
        );
    };

    function copyMobileNumber() {
        const copyValue = mobileRef.current?.innerHTML;
        document.body.insertAdjacentHTML(
            'beforeend',
            `<input style="height:0;widht:0" id="temp-copy" value="${copyValue}">`
        );
        const copyText = document.getElementById('temp-copy') as HTMLInputElement;
        copyText?.select();
        document.execCommand('Copy');
        copyText?.remove();
        setTimeout(() => {
            message.destroy();
            message.success(t('user_card.copy_mobile'));
        }, 0);
    }

    return (
        <div className="usercard-item-content">
            <h5 className="usercard-item-title" style={{ flex: 'none', lineHeight: '22px' }}>
                {mobileData.name}
            </h5>
            <Mobile isShow={isShow} mobileData={mobileData} />
            {isShow || isSelf ? (
                <Button
                    type="text"
                    className="user-mobile-copy-button"
                    style={{ lineHeight: '22px' }}
                    onClick={debounce(() => {
                        copyMobileNumber();
                    }, 200)}
                    clstag="pageclick|keycount| xtbg_add_home_1637657110163|44"
                >
                    {IconCopyBtn}
                </Button>
            ) : (
                <CheckFullNumber />
            )}
        </div>
    );
}

function mapStateToProps({ user }: { user: any }) {
    return {
        userData: user.userData.user,
        appId: user.userData.app.appId,
        userId: user.userData.user.userId,
        teamId: user.userData.user.team.teamId,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getWebUserData(data: { teamUserInfo: UserModel }) {
            dispatch({ type: 'user/getWebUserData', payload: { userData: data } });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMobile);
