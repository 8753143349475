import Quill from 'quill';
import { stickers_sets } from '@/utils/chat/stickers-config';
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

export default class CustomClipboard extends Clipboard {
    constructor(quill: any, options: any) {
        super(quill, options);
        this.options = options;
        this.upload = options.upload;
    }

    onPaste(e: any) {
        if (e.defaultPrevented || !this.quill.isEnabled()) return;
        let range = this.quill.getSelection();
        e.preventDefault();
        const html = e.clipboardData.getData('text/html');
        const text = e.clipboardData.getData('text/plain');
        const files = Array.from(e.clipboardData.files || []);
        // console.log('paste html', html);
        // console.log('paste text', text);
        // console.log('paste files', files);

        if (!html && !text && files.length === 0) {
            return;
        }
        if (!html && !text && files.length > 0) {
            if (this.upload) {
                this.upload(range, files);
            }
            return;
        }
        // 判断是否仅为图片
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const container = doc.body;
        if (
            container &&
            container.childNodes.length === 1 &&
            container.firstChild &&
            container.firstChild.nodeName === 'IMG' &&
            files.length > 0
        ) {
            if (this.upload) {
                this.upload(range, files);
            }
            return;
        }
        let delta = new Delta().retain(range.index);
        let scrollTop = this.quill.scrollingContainer.scrollTop;
        this.container.focus();
        this.quill.selection.update('silent');
        setTimeout(() => {
            if (!html && text) {
                delta = delta.insert(text).delete(range.length);
            } else if (text) {
                const patt = /\[.*?\]/g;
                const re = text.match(patt);
                let result = text;
                if (re && re.length > 0) {
                    const reSet = new Set([...re]);
                    reSet.forEach((r: string) => {
                        if (!r) {
                            return;
                        }
                        if (!stickers_sets[r]) {
                            return;
                        }
                        let tmpIndex = r.indexOf(']');
                        let tmpStr = r.substring(1, tmpIndex);
                        let regexp = new RegExp(`\\[${tmpStr}\\]`, 'g');
                        result = result.replace(
                            regexp,
                            `<span class="e-emoji" contenteditable="false" data-value="${r}">&#65279;<span contenteditable="false">${r}</span>&#65279;</span>`
                        );
                    });
                }
                const pattern1 = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
                const specialEmojiRegex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;
                let rtnResult = '';
                for (const temp of result) {
                    if (pattern1.test(temp)) {
                        rtnResult += `<span class="e-emoji" contenteditable="false" data-value="${temp}">&#65279;<span contenteditable="false">${temp}</span>&#65279;</span>`;
                    } else {
                        if (specialEmojiRegex.test(temp)) {
                            rtnResult += `<span class="e-emoji" contenteditable="false" data-value="${temp}">&#65279;<span contenteditable="false">${temp}</span>&#65279;</span>`;
                        } else {
                            rtnResult += temp;
                        }
                    }
                }
                const content = rtnResult.replace(/\n/g, '<br/>');
                const p = `<p>${content}</p>`;
                delta = delta.concat(this.convert(p)).delete(range.length);
            } else {
                delta = delta.concat(this.convert(html)).delete(range.length);
            }

            let result = new Delta().retain(range.index);
            delta.forEach((op: any) => {
                // if (op.insert && typeof op.insert === 'object' && op.insert.image) {
                //     return;
                // } op.insert['custom-emoji'] ||
                if (
                    op.insert &&
                    (typeof op.insert === 'string' ||
                        op.insert['mention-link'] ||
                        op.insert['custom-emoji'])
                ) {
                    result.ops.push({ insert: op.insert });
                }
            });
            result = result.delete(range.length);
            this.quill.updateContents(result, 'user');
            // range.length contributes to delta.length()
            this.quill.setSelection(result.length() - range.length, 'silent');
            this.quill.scrollingContainer.scrollTop = scrollTop;
            this.quill.focus();
        }, 1);
    }
}
