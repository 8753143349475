/**
 * 加载图片
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './index.less';
import { ChatMessage, ChatMessageType, SessionType } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import { useTranslation } from 'react-i18next';
import { mySendMessage } from '@/utils/chat/message';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import ChatEvent from '@/baseComponents/Chat/view/ChatEvent';

interface RevokeProps {
    message: ChatMessage;
    sessionType?: SessionType;
    sendType?: MessageSendType;
}

type IRevokeProps = Readonly<RevokeProps & ChatState>;

function Revoke(props: IRevokeProps) {
    const { message, currentEmployee, sessionType } = props;
    const { t } = useTranslation('chat');
    const [canReedit, setCanReedit] = useState(false);

    // const canReedit = useMemo(() => {
    //     const times = 5 * 60 * 1000;
    //     if (Date.now() > message.timestamp + times) {
    //         return false;
    //     }
    //     return mySendMessage({ message, currentEmployee }) && message.type === ChatMessageType.TEXT;
    // }, [message, currentEmployee]);

    const reeditMessage = useCallback(() => {
        if (canReedit) {
            ChatEvent.emit('reedit-message', { message });
        }
    }, [canReedit, message]);

    const revokeTextTip = useMemo(() => {
        if (mySendMessage({ message, currentEmployee })) {
            return t('you_revoke_message');
        } else {
            if (sessionType === SessionType.SINGLE || sessionType === SessionType.SECRET_SINGLE) {
                return t('other_revoke_message');
            } else if (sessionType === SessionType.GROUP) {
                return t('someone-revoke-message', { name: message?.sender.name });
            }
        }
    }, [t, message, currentEmployee, sessionType]);

    useEffect(() => {
        const times = 5 * 60 * 1000;
        if (!mySendMessage({ message, currentEmployee })) {
            return;
        }
        if (message.type !== ChatMessageType.TEXT) {
            setCanReedit(false);
            return;
        }
        if (Date.now() > message.timestamp + times) {
            setCanReedit(false);
            return;
        } else {
            setCanReedit(true);
            const ms = times - (Date.now() - message.timestamp);
            // 超过5秒定时
            if (ms > 5000) {
                const timeout = setTimeout(() => {
                    setCanReedit(false);
                    clearTimeout(timeout);
                }, ms);
            } else {
                // 不足5秒， 直接设置 不让重新编缉
                setCanReedit(false);
            }
        }
    }, [message, currentEmployee]);

    return (
        <div className="content">
            <div className="revoke-message">
                <div className="revoke-message-text">
                    {revokeTextTip}
                    {canReedit && (
                        <span>
                            &nbsp;<a onClick={reeditMessage}>{t('revoke_reedit')}</a>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    // const { currentEmployee } = chat as ChatState;
    return { currentEmployee: chat.currentEmployee };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Revoke);

// export default withTranslation('chat')();
