import React, { ChangeEvent, useRef, useState, Dispatch, useEffect, useCallback } from 'react';
import { Modal, Input, Switch, message } from 'antd';
import ReactDOM from 'react-dom';
import UploadImg from './UploadImg';
import { GlobalContextConfig } from '@/context/GlobalContext';
import { createAnnounceService, updateAnnounceService, findAnnouncementDetail } from '../../api';
import './index.less';

const { TextArea } = Input;
interface Iprops {
    onclose?: () => void;
    projectId: string;
    globalcontext: GlobalContextConfig;
    announceDetail?: { content: string; announcementId: string };
    pageType?: string;
    t: any;
}

enum showTypes {
    'hidden' = 1,
    'show' = 2,
}

function CreateAnnounce(props: Iprops) {
    const { globalcontext, onclose, projectId, announceDetail, pageType = 'add', t } = props;
    const { events3: events } = globalcontext;
    const [visible, setVisible] = useState(true);
    const [showType, setShowType] = useState<showTypes>(showTypes.show);
    const [content, setContent] = useState<string>(announceDetail?.content || '');
    const [contentLength, setContentLength] = useState(500);
    const imgsRef = useRef<string[]>([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [originUrl, setOriginUrl] = useState<string[]>([]);

    const getDetail = useCallback(
        async function (projectId: string, announcementId = '') {
            const res = await findAnnouncementDetail(projectId, announcementId);
            if (res.errCode) return message.error(t('has_error'));
            setOriginUrl(res.announcementDetailInfo?.attachmentUrl || []);
            imgsRef.current = res.announcementDetailInfo?.attachmentUrl || [];
            setShowType(res.announcementDetailInfo?.showType);
        },
        [t]
    );

    useEffect(() => {
        if (!announceDetail?.announcementId) return;
        getDetail(projectId, announceDetail.announcementId);
    }, [projectId, announceDetail, getDetail]);

    async function handleOK() {
        setConfirmLoading(true);
        if (pageType === 'add') {
            await createAnnounceService({
                projectId,
                content,
                attachmentUrls: imgsRef.current,
                showType,
            });
        } else if (pageType === 'edit') {
            await updateAnnounceService({
                projectId,
                content,
                announcementId: announceDetail?.announcementId || '',
                attachmentUrls: imgsRef.current,
                showType,
            });
        }
        setConfirmLoading(false);
        events.emit('announceList:update', {
            projectId,
            announcementId: announceDetail?.announcementId || '',
        });
        setVisible(false);
    }

    function contentChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setContent(newValue);
        setContentLength(newValue.length - compactValue.length + 500);
    }
    return (
        <Modal
            title={t('detail.announce.title')}
            width="550px"
            destroyOnClose
            bodyStyle={{ height: 334, padding: '19px 24px' }}
            visible={visible}
            cancelText={t('cancel')}
            onCancel={() => {
                setVisible(false);
                setTimeout(() => {
                    onclose?.();
                }, 100);
            }}
            okText={t('publish')}
            confirmLoading={confirmLoading}
            onOk={handleOK}
            okButtonProps={{
                disabled: content?.trim().length === 0,
            }}
        >
            <div className="pm-create-announce-wrap">
                <TextArea
                    rows={6}
                    value={content}
                    maxLength={contentLength}
                    onChange={contentChange}
                    placeholder={t('detail.announce.create_announce_placeholder')}
                />
                <span className="pm-create-announce-wordcount">
                    <span style={{ color: content.length === 0 ? '' : ' #31c4cc' }}>
                        {content.trimStart().length}
                    </span>
                    <span>/500</span>
                </span>
                <div>
                    <UploadImg
                        globalcontext={globalcontext}
                        imgs={originUrl}
                        uploadCallback={(imgs) => {
                            imgsRef.current = imgs;
                        }}
                    />
                </div>
                {/* <p>
                    <span>{t('detail.announce.show_announce_modal')}</span>
                    <Switch
                        checked={showType === showTypes.show}
                        onChange={(value) => setShowType(value ? showTypes.show : showTypes.hidden)}
                    />
                </p> */}
            </div>
        </Modal>
    );
}

export default function (props: Iprops) {
    const el = document.createElement('div');
    const onclose = function () {
        if (el) {
            ReactDOM.unmountComponentAtNode(el);
            el.remove();
        }
    };

    ReactDOM.render(<CreateAnnounce {...props} onclose={onclose} />, el);
}
