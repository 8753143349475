/**
 * 模块说明
 * 作用： 该模块主要作用提供  ‘新建文档’、‘新建表格’、‘新建WPS Word’、‘新建WPS Excel’、‘新建文件’、‘从模版新建’入口
 * createDoc createWPSWord createWPSExcel createSpreadsheet TemplateCreate 这些文件是对应的实现。
 * 创建文件的方法是在父组件里，但是有的父组件Home页面没有这个方法，是因为Home就不夹创建文件。
 */
import React, { Dispatch, SetStateAction, useContext, useState, useMemo } from 'react';
import { Menu, Button, Dropdown, message } from 'antd';
import UploadButton from '../UploadBox/UploadButton';
import './index.less';
import context, { useTranslation } from '../../context';
import folderPng from '@/assets/img/folder.png';
import openTemplateModal from './createDocumentByTemplate';
import { createDocumentByPageType, PageType } from './utils';
import { isFocusEnv } from '@/utils';
// import openTemplateModal from './CreateByTemplate';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import IconFont from '@/components/icon';
import Bus from '@/utils/bus';
import { createTemplatePageInSpace, pageTypes } from '../../api/createmenu';
import { openModalTextBox } from '../ModalTextBox';
import api from '../../api';
import events from '@/components/Joyspace/context/events';
import { addToken, notUseJoySpaceOffline, openLink } from '../../util';
interface IProps {
    canCreateFolder?: boolean;
    canCreatePage?: boolean;
    space?: () => void;
    team?: () => void;
    category?: () => void;
    lowCode?: (url: string) => void;
    disabledCreateFolder?: boolean;
    zhuanban?: {
        teamId: string;
        spaceId: string;
        selectfolder?: {
            id: string;
            name: string;
        };
        setIsReload?: Dispatch<SetStateAction<boolean>>;
        projectId: string;
        archive?: boolean;
    };
    folderId?: string;
    reloadListCallback?: Dispatch<SetStateAction<boolean>>;
    gid?: string;
}

export default function (props: IProps) {
    const {
        folderId,
        reloadListCallback,
        gid,
        canCreateFolder = true,
        canCreatePage = true,
        disabledCreateFolder = false,
    } = props;
    const lang = useTranslation();
    const canCreateFile = canCreatePage;
    const globalContext = useContext<GlobalContextConfig>(GlobalContext);
    const { appRuntimeEnv } = globalContext;
    const { currentRouterInfo } = useContext(context);
    const [disabled, setDisabled] = useState(false);
    const urlPrefix = appRuntimeEnv.joyspaceHost;
    const canCreateSheet = appRuntimeEnv.canCreateSheet;
    useMemo(() => {
        if (notUseJoySpaceOffline()) {
            return;
        }
        setDisabled(!navigator.onLine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.onLine]);
    // 创建各种文档
    const createDocument = (type: string) => {
        createDocumentByPageType(type, folderId || '', gid || '', currentRouterInfo?.name)
            .then((res: any) => {
                console.log(res, 'res==========>');
                if (!res) return;
                reloadListCallback?.((oldvalue) => !oldvalue);
                events.emit('list.reload');
                // const openUrl = `${urlPrefix}/${pageTypes[res.page_type]}/${res.id}`;
                // window.open(openUrl);
                if (!navigator.onLine) {
                    setTimeout(() => {
                        openLink(res, urlPrefix);
                    }, 600);
                } else {
                    openLink(res, urlPrefix);
                }
            })
            .catch((err: any) => {
                message.error(lang('common.fail'));
            });
    };
    // 创建模板文档
    const createTemplatePageInSpaceFn = (id = '') => {
        createTemplatePageInSpace({
            folderId: folderId === 'root' ? '' : folderId,
            templateId: id,
        })
            .then((res) => {
                if (!res) return;
                reloadListCallback?.((oldvalue) => !oldvalue);
                events.emit('list.reload');
                // window.open(`${urlPrefix}/${pageTypes[res.page_type]}/${res.id}`);
                openLink(res, urlPrefix);
                Bus.emit('joyspace:documentCreated', res);
            })
            .catch(() => {
                message.error(lang('common.fail'));
            });
    };
    // 通过模版创建
    const createDocumentByTemplate = () => {
        // console.log('createDocumentByTemplate===>', navigator.onLine);
        if (notUseJoySpaceOffline() || navigator.onLine) {
            openTemplateModal({
                globalContext,
                pageType: 1,
                callback: (id: string) => {
                    // 没有id时，创建空白文档
                    if (!id) {
                        return createDocument(PageType.page);
                    }
                    createTemplatePageInSpaceFn(id);
                    if (!navigator.onLine) {
                        message.error(lang('common.fail'));
                    }
                },
            });
        } else {
            createDocument(PageType.page);
        }
    };
    // 创建文件夹
    const createFolder = () => {
        openModalTextBox({
            title: lang('c.categoriesPicker.create'),
            onOk: (value, close) => {
                close();
                api.categories.create(folderId || '', value).then((data) => {
                    if (data) {
                        // tree数据更新
                        reloadListCallback?.((oldvalue) => !oldvalue);
                        // 文档左侧导航更新
                        events.emit('freshLeftTreeByFolderById', folderId || '');
                        message.success(lang('common.success'));
                    } else {
                        message.error(lang('common.fail'));
                    }
                });
            },
        });
    };

    const menu = (
        <Menu className="joyspace-create-menu">
            {/* 创建 文档 */}
            <Menu.Item
                // data-name="doc"
                data-name={!isFocusEnv() && disabled ? 'notdoc' : 'doc'}
                disabled={isFocusEnv() ? false : disabled}
                onClick={() => createDocumentByTemplate()}
                clstag="pageclick|keycount|focus_joyspace_01_1615797631505|9"
            >
                {lang('c.CreateMenu.createDoc')}
            </Menu.Item>
            {/* 创建 表格 */}
            {canCreateSheet && (
                <Menu.Item
                    data-name="spreadsheet"
                    onClick={() => createDocument(PageType.sheet)}
                    clstag="pageclick|keycount|focus_joyspace_01_1615797631505|10"
                >
                    {lang('c.CreateMenu.createSpreadsheet')}
                </Menu.Item>
            )}
            {/* 创建 WPS Word */}
            {/* <Menu.Item
                data-name="wpsdoc"
                onClick={() => createDocument(PageType.wpsWord)}
                clstag="pageclick|keycount|focus_joyspace_01_1615797631505|11"
            >
                {lang('c.CreateMenu.createWPSWord')}
            </Menu.Item> */}
            {/* 创建 WPSExcel */}
            {/* <Menu.Item
                data-name="wpsspreadsheet"
                onClick={() => createDocument(PageType.wpsexcel)}
                clstag="pageclick|keycount|focus_joyspace_01_1615797631505|12"
            >
                {lang('c.CreateMenu.createWPSExcel')}
            </Menu.Item> */}
            {/* 京办暂时关闭低代码 */}
            {/* {isFocusEnv() && props.lowCode && (
                <Menu.Item
                    data-name="lowCode"
                    onClick={() => props?.lowCode?.(appRuntimeEnv.joyspaceLowCode.createUrl)}
                >
                    {lang('c.CreateMenu.createLowCode')}
                </Menu.Item>
            )} */}
            {/* {props.space && (
                <Menu.Item
                    data-name="space"
                    onClick={() => props?.space?.()}
                    clstag="pageclick|keycount|focus_joyspace_01_1615797631505|13"
                >
                    {lang('c.CreateMenu.createSpace')}
                </Menu.Item>
            )}
            {props.team && (
                <Menu.Item
                    data-name="team"
                    onClick={() => props?.team?.()}
                    clstag="pageclick|keycount|focus_joyspace_01_1615797631505|14"
                >
                    {lang('c.CreateMenu.createTeam')}
                </Menu.Item>
            )} */}
            {/* 创建文件夹 */}
            {canCreateFolder && (
                <Menu.Item disabled={disabled} onClick={createFolder}>
                    <img src={folderPng} className="iconic-folder" />
                    {lang('c.CreateMenu.createCategory')}
                </Menu.Item>
            )}
            {/* <Menu.Item
                data-name="templateCreate"
                onClick={() => templateCreate(globalContext, currentRouteInfo, zhuanban)}
                clstag="pageclick|keycount|focus_joyspace_01_1615797631505|15"
            >
                从模版新建
            </Menu.Item> */}
        </Menu>
    );

    // console.log(canCreateFile, 'canCreateFilecanCreateFilecanCreateFile');
    // console.log(canCreateFile, canCreateFolder, 'canCreateFilecanCreateFilecanCreateFile');
    return (
        <div className="joyspace-create">
            <UploadButton archive={!canCreateFile} folderId={folderId} gid={gid} />
            {!canCreateFile && canCreateFolder && (
                <Button
                    type="primary"
                    disabled={disabledCreateFolder || disabled}
                    className="create-btn"
                    onClick={createFolder}
                >
                    {lang('c.CreateMenu.createCategory')}
                </Button>
            )}
            {!canCreateFile && !canCreateFolder && (
                <Button disabled type="primary" className="create-btn">
                    {lang('c.CreateMenu.create')}
                    <IconFont className="down" type="iconwin_right" />
                </Button>
            )}
            {canCreateFile && (
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button
                        type="primary"
                        clstag="pageclick|keycount|focus_joyspace_01_1615797631505|5"
                        className="create-btn"
                    >
                        {lang('c.CreateMenu.create')}
                        <IconFont className="down" type="iconwin_right" />
                    </Button>
                </Dropdown>
            )}
        </div>
    );
}
