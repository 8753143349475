import React, { useState, useContext } from 'react';
import { Spin, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LoginFormContext } from '../../utils';
import { BackNav } from '../../comps';
import { CreateTeamAndEmployee } from '@/types/LoginPage';
import { useTranslation } from 'react-i18next';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { changeEgovUrl } from '@/utils/tools';
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
enum CreateTeamStatus {
    Init, // 团队名称输入页面
    Finish, // 加入完成页面
}

export default function CreateTeam({ goBack }: { goBack: () => void }) {
    const { t } = useTranslation('common');
    const { service, authInfo: authData, onGlobalError, appRuntimeEnv } = useContext<
        GlobalContextConfig
    >(GlobalContext);
    const { mobile, userName, enterApplication, navigate } = useContext(LoginFormContext);
    const [teamName, setTeamName] = useState<string | undefined>('');
    const [loading, setLoading] = useState(false);
    const [createStatus, setCreateStatus] = useState(CreateTeamStatus.Init);
    const [newTeamInfo, setNewTeamInfo] = useState<CreateTeamAndEmployee | null>(null);
    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );
    let teamCanCreated = true;
    function onTeamNameChange(v: string | undefined) {
        if (v && v.length > 30) {
            return;
        }
        setTeamName(v);
    }

    async function createTeam() {
        if (!teamName || loading || !teamCanCreated) {
            return;
        }
        setLoading(true);
        teamCanCreated = false;
        // const { authData } = config; // authData 中的teamUserInfo是已登录的team
        // 登录流程中的创建新团队 肯定还没有选择团队
        try {
            const content: CreateTeamAndEmployee = await service.focusService.createTeamAndEmployee(
                {
                    mobile,
                    userTeamName: authData?.realName ? authData.realName : userName,
                    teamName,
                    countryCode: '86',
                }
            );
            setLoading(true);
            teamCanCreated = true;
            if (content) {
                setCreateStatus(CreateTeamStatus.Finish);
                setNewTeamInfo(content);
            }
        } catch (e) {
            onGlobalError(e);
        }
    }

    const teamNameInput = (
        <>
            <div
                style={{
                    paddingBottom: 32,
                }}
            >
                <BackNav
                    clickHandle={goBack}
                    title={t('login.back')}
                    subtitle={t('login.inputTeamName')}
                    desc={t(
                        `login.teamNameDesc${
                            appRuntimeEnv.runtimeConfig.key === 'mebj' ? 'mebj' : ''
                        }`
                    )}
                />
            </div>
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <div className="mobileInput">
                    <Input
                        autoFocus
                        allowClear
                        placeholder={t('login.inputTeamNamePlaceholder')}
                        value={teamName}
                        onKeyDown={(e: any) => {
                            const { keyCode } = e;
                            if (keyCode === 13) {
                                // checkMobileCb();
                            }
                        }}
                        onChange={(e: any) => {
                            onTeamNameChange(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                        createTeam();
                    }}
                    className="btn"
                    style={
                        !loading && teamName && teamName.length
                            ? {
                                  opacity: 1,
                                  cursor: 'pointer',
                              }
                            : {}
                    }
                >
                    {loading && <div>{loadingComp}</div>}
                    <div
                        style={{
                            margin: 12,
                            fontSize: 14,
                            color: 'rgb(255, 255, 255)',
                        }}
                    >
                        {t('nextStep')}
                    </div>
                </div>
            </div>
        </>
    );

    const createFinish = (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <img
                style={{
                    width: 121,
                    height: 111,
                    marginBottom: 37,
                }}
                src={changeEgovUrl(`${REACT_APP_OSS_BASE_URL}/statics/images/join-success.png`)}
                alt=""
            />
            <p
                style={{
                    fontSize: 16,
                    lineHeight: 1,
                    margin: 0,
                }}
            >
                {t('login.createSuccessAdmin')}
            </p>
            <a
                onClick={() => {
                    // 废弃功能
                    window.open('https://me.jdcloud.com');
                }}
            >
                https://me.jdcloud.com
            </a>
            <div
                className="btn secondary small"
                style={{
                    margin: '57px auto 0 auto',
                    cursor: 'pointer',
                }}
                onClick={() => enterApplication(newTeamInfo)}
            >
                {t('login.enterApplication')}
            </div>
        </div>
    );
    return (
        <div className="mobileForm">
            {createStatus === CreateTeamStatus.Init ? teamNameInput : null}
            {createStatus === CreateTeamStatus.Finish ? createFinish : null}
        </div>
    );
}
