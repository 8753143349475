import { request } from '@/components/Joyspace/api/request';

export interface Banner {
    src: string;
    title: string;
    url: string;
    link: string;
}

export interface resData {
    hide: boolean;
    list: Banner[];
}

export function get() {
    return request<resData>({
        api: 'joyspace.common.baner',
        path: '/v1/common/banner',
    });
}
