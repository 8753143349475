import { gatewayInstance } from '../server/gateway';
import {
    IfindProjectDetail,
    IcreateSuperviseReq,
    IcommentResp,
    IcreateSupervise,
    IReceiveSupervise,
    ReadFlag,
    IsuperviseAllData,
    IsuperviseListReq,
    IgetSuperviseRemark,
    IcreateSuperviseRemark,
    EsuperviseSourceType,
} from '@/types/supervise';

/**
 * 任务数据看板
 * @param opts
 */
export async function findProjectDetail(
    opts: Partial<IfindProjectDetail>
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'work.project.findProjectDetail',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 督办数据看板
 * @param opts
 */
export async function getCreateSuperviseAllDataByWeb(opts: {
    sourceBizId: string;
    sourceBizType: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.getCreateSuperviseAllDataByWeb',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 我收到的督办数据看板
 * @param opts
 */

export async function getReceiveSuperviseAllDataByWeb(opts: {
    sourceBizId: string;
    sourceBizType: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.getReceiveSuperviseAllDataByWeb',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 我发出的督办数据看板
 * @param opts
 */
export async function getCreateSuperviseAllData(): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.getCreateSuperviseAllData',
            method: 'POST',
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 创建督办
 * @param opts
 */

export async function createSupervise(
    opts: Partial<IcreateSuperviseReq>
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.createSupervise',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 删除督办
 * @param opts
 */

export async function delSupervise(opts: { superviseId: string }): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.delSupervise',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 隐藏督办
 * @param opts
 */

export async function hideSuperviseUser(opts: {
    superviseId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.hideSuperviseUser',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 创建督办评论
 * @param opts
 */

export async function createSuperviseComment(opts: {
    superviseId: string;
    commentContent: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.createSuperviseComment',
            method: 'POST',
            body: opts,
        });
        return [data as IcommentResp, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 删除督办评论
 * @param opts
 */

export async function deleteSuperviseComment(opts: {
    superviseId: string;
    commentId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.deleteSuperviseComment',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 获取创建的督办列表
 * @param opts
 */

export async function getCreateSupervise(opts: IsuperviseListReq): Promise<[any, string | null]> {
    try {
        if (!opts.sourceType) {
            delete opts.sourceType;
        }
        const data: any = await gatewayInstance.request({
            api: 'focus.getCreateSupervise',
            method: 'POST',
            body: opts,
        });
        data.createList =
            (Array.isArray(data.createList) &&
                data.createList.map((item: any) => {
                    if (item.sourceType === EsuperviseSourceType.NOTIFICATION) {
                        const _item = JSON.parse(item.sourceParam);
                        _item.title = _item.title ? _item.title : '组通知';
                        _item.id = _item.notificationId || _item.id;
                        item.sourceParam = JSON.stringify(_item);
                    }
                    if (item.sourceType === EsuperviseSourceType.SOLITAIRE) {
                        const _item = JSON.parse(item.sourceParam);
                        _item.title = _item.title ? _item.title : '组接龙';
                        _item.id = _item.solitaireId || _item.id;
                        item.sourceParam = JSON.stringify(_item);
                    }
                    return item;
                })) ||
            [];
        return [data as IcreateSupervise[], null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取收到的督办列表
 * @param opts
 */

export async function getReceiveSupervise(opts: IsuperviseListReq): Promise<[any, string | null]> {
    try {
        if (!opts.sourceType) {
            delete opts.sourceType;
        }
        const data: any = await gatewayInstance.request({
            api: 'focus.getReceiveSupervise',
            method: 'POST',
            body: opts,
        });
        data.receiveList =
            (Array.isArray(data.receiveList) &&
                data.receiveList.map((item: any) => {
                    if (item.sourceType === EsuperviseSourceType.NOTIFICATION) {
                        const _item = JSON.parse(item.sourceParam);
                        _item.title = _item.title ? _item.title : '组通知';
                        _item.id = _item.notificationId || _item.id;
                        item.sourceParam = JSON.stringify(_item);
                    }
                    if (item.sourceType === EsuperviseSourceType.SOLITAIRE) {
                        const _item = JSON.parse(item.sourceParam);
                        _item.title = _item.title ? _item.title : '组接龙';
                        _item.id = _item.solitaireId || _item.id;
                        item.sourceParam = JSON.stringify(_item);
                    }
                    return item;
                })) ||
            [];
        return [data as IReceiveSupervise[], null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取相关的督办评论
 * @param opts
 */

export async function getRelatedSuperviseComment(
    opts: IsuperviseListReq
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.getRelatedSuperviseComment',
            method: 'POST',
            body: opts,
        });
        return [data as IcommentResp[], null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取督办评论
 * @param opts
 */
export async function getSuperviseComment(opts: {
    offset: number;
    limit: number;
    superviseId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.getSuperviseComment',
            method: 'POST',
            body: opts,
        });
        return [data as IcommentResp[], null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取督办详情
 * @param opts
 */
export async function getSuperviseInfo(opts: {
    superviseId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.getSuperviseInfo',
            method: 'POST',
            body: opts,
        });
        return [data as IcreateSupervise, null];
    } catch (error) {
        return [null, error?.message?.toString()];
    }
}

/**
 * 标记已读或完成
 * @param opts
 */
export async function readSupervise(opts: {
    superviseId: string;
    readFlag: ReadFlag;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.readSupervise',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 再次督办
 * @param opts
 */
export async function repeatSupervise(opts: {
    superviseId: string;
}): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.repeatSupervise',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 再次督办
 * @param opts
 */
export async function queryNoRead(): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.queryNoRead',
            method: 'POST',
        });
        return [data, null];
    } catch (error) {
        return [null, error.message?.toString() || error.errorMsg || error || '服务器错误'];
    }
}

/**
 * 再次督办
 * @param opts
 */
export async function getSuperviseReceiverList(opts: {
    offset: number;
    limit: number;
    readFlags: number[];
    superviseId: string;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'focus.getSuperviseReceiverList',
            method: 'POST',
            body: opts,
        });
        // console.log(data, 'datadatadatadata');
        for (let item of data.receiverList) {
            item.desc = '';
            if (!item.labelInfoList) {
                item.isShowTag = false;
                item.tagName = '';
            } else {
                for (let _item of item.labelInfoList) {
                    if (_item.labelEnum === 'EXTERNAL') {
                        item.isShowTag = Boolean(Number(_item.isShow));
                        item.tagName = _item.name;
                    }
                    if (_item.labelEnum === 'DEPT' && Boolean(Number(_item.isShow))) {
                        item.desc += _item.name;
                    }
                    if (_item.labelEnum === 'TITLE' && Boolean(Number(_item.isShow))) {
                        item.desc = item.desc ? `${item.desc}-${_item.name}` : item.name;
                    }
                }
            }
        }
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 获取备注
export async function getSuperviseServiceRemark(
    opts: IgetSuperviseRemark
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.querySuperviseRemark',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function changeSuperviseServiceRemark(
    opts: IcreateSuperviseRemark
): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'focus.createOrUpdateSuperviseRemark',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 修改备注
// /jsfSwagger/com.jd.ee.focus.api.FocusSuperviseService/createOrUpdateSuperviseRemark
