/* eslint-disable complexity */
import React, { useState, useEffect, useContext, useRef } from 'react';
import './index.less';
import { connect } from 'dva';
import { Drawer, Input, Button, message, Checkbox, Modal, Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import TypeSelect from '../BaseComponents/TypeSelect';
import DatePicker from '@/components/Calendars/BaseComponents/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import bus from '@/utils/bus';
import { findForExportMyTasks, exportMyTasks } from '@/api/work';
import { PriorityList } from '@/types/newWork';
import { downLoadFile } from '@/utils/chat/index';
import { isFocusEnv, FocusSDK, DownloadItem } from '@/utils';
import debounce from 'lodash/debounce';
import Loading from '@/components/Loading';
import taskEmpty from '@/assets/img/empty/task-empty.png';
import ScrollView from '@/baseComponents/ScrollView';
import { addXtoken } from '@/utils/addToken';
import { analysisLog } from '@/utils/logAnalytics';
import { APP_NAME } from '@/config/name';

function mapStateToProps({ user }: any) {
    return {
        userData: user.userData.user,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}
interface TaskExportListType {
    // title?: string;
    priorityTypes: number[];
    startTime?: string;
    endTime?: string;
    workStartTime?: Dayjs | null;
    workStopTime?: Dayjs | null;
}
const TaskExportList: TaskExportListType = {
    // title: '',
    priorityTypes: [1, 2, 3, 4],
    startTime: '',
    endTime: '',
    workStartTime: null,
    workStopTime: null,
};
// eslint-disable-next-line complexity
function ExportTaskDraw({ userData }: { userData: any }) {
    const [drawVisible, setDrawVisible] = useState<boolean>(false);
    const [emptyTaskList, setEmptyTaskList] = useState<boolean>(false);
    const [emptyLength, setEmptyLength] = useState<boolean>(false);
    const [taskList, setTaskList] = useState([]);
    const [queryParams, setQueryParams] = useState<Array<string>>([]);
    const [taskDetail, setTaskDetail] = useState<TaskExportListType>(TaskExportList);
    const [loading, setLoading] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [process, setProcess] = useState(0);
    const scrollRef = useRef(null);

    const { t } = useTranslation('work');

    useEffect(() => {
        function handleOpenCreateDrawVisible(data: any) {
            setDrawVisible(true);
        }
        bus.on('task:open-exporet-task', handleOpenCreateDrawVisible);
        return () => {
            bus.off('task:open-exporet-task', handleOpenCreateDrawVisible);
        };
    }, []);
    function closeDrawConfirm() {
        // 什么都没选中的时候，不出弹窗
        if (!taskDetail.priorityTypes.length && !taskDetail.startTime && !taskDetail.endTime) {
            resetData();
        } else {
            Modal.confirm({
                title: t('tip'),
                content: <span className="nimble-con">{t('tip export content')}</span>,
                okText: t('confirm'),
                cancelText: t('cancel'),
                className: 'task-common-modal-lb',
                onOk() {
                    resetData();
                },
            });
        }
    }
    function exportConfirm() {
        Modal.confirm({
            title: t('tip'),
            content: `数据将从${APP_NAME}中导出，请您确保数据安全。您是否确认导出？`,
            okText: t('confirm'),
            cancelText: t('cancel'),
            className: 'task-common-modal-lb',
            onOk() {
                analysisLog(
                    'xtbg_add_task',
                    'xtbg_add_task_exporttask_Doubleconfirmationexport_save',
                    ...queryParams
                );
                exportTask();
            },
        });
    }
    function resetData() {
        setDrawVisible(false);
        setTaskDetail(TaskExportList);
        setTaskList([]);
        setEmptyLength(false);
    }

    async function handlePriorityChange(priority: number[]) {
        if (!priority) {
            return;
        }
        const task = { ...taskDetail, priorityTypes: priority };
        setTaskDetail(task);
    }
    async function handleTimeChange(time: Dayjs | null, start = true) {
        const unix = time ? time.valueOf().toString() : null;
        if (start) {
            if (time && taskDetail.workStopTime?.isSameOrBefore(time)) {
                return message.warn(t('The start time must not be later than the deadline'));
            }
            if (unix === taskDetail.startTime) {
                return;
            }
            const task = {
                ...taskDetail,
                startTime: unix || '',
                workStartTime: unix ? dayjs(Number(unix)) : null,
            };
            setTaskDetail(task);
            return;
        }
        if (unix === taskDetail.endTime) {
            return;
        }
        if (time && taskDetail.workStartTime?.isSameOrAfter(time)) {
            return message.warn(t('The deadline must not be earlier than the start time'));
        }
        const workStopTime = unix ? dayjs(Number(unix)) : null;
        const task = {
            ...taskDetail,
            endTime: unix || '',
            workStopTime: workStopTime,
        };

        setTaskDetail(task);
        return;
    }

    const exportTask = async () => {
        // if (!taskDetail.startTime) {
        //     message.error(t('please set the start time'));
        //     return;
        // }
        // if (!taskDetail.endTime) {
        //     message.error(t('please set the deadline'));
        //     return;
        // }
        // if (!taskDetail.priorityTypes.length) {
        //     message.error(t('Please select type'));
        //     return;
        // }
        setExportLoading(true);

        let params: TaskExportListType = {
            // title: taskDetail.title, // 任务标题
            priorityTypes: taskDetail.priorityTypes, // 任务优先级
        };
        if (taskDetail.startTime) {
            params.startTime = taskDetail.startTime;
        }
        if (taskDetail.endTime) {
            params.endTime = taskDetail.endTime;
        }
        const [data, err] = await exportMyTasks(params);
        setExportLoading(false);
        if (err) {
            message.error(err);
            return;
        }
        await resetData();

        const url = addXtoken(data.url, userData.accessToken);
        // downLoadFile(`${data.fileName}`, `${data.url}&x-token=${userData.accessToken}`);
        exportFileload(url, `${data.fileName}`);
    };

    // 导出
    function exportFileload(fileUrl: any, fileName: any) {
        if (isFocusEnv()) {
            FocusSDK.downloadExportFile(fileUrl, {
                saveAs: true,
                filename: fileName,
                extensionName: '.xls',
                onStarted: (downloadItem: DownloadItem) => {},
                onProgress: ({ percent }) => {},
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                    //
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                    //
                },
                onFinish: (file: { path: string; filename: string }) => {
                    // console.log('finish', file);
                },
            });
        } else {
            // web 端下载文件
            downLoadFile(fileName, fileUrl);
        }
    }

    async function taskListSearch() {
        if (taskList.length > 0) {
            setTaskList([]);
        }
        setEmptyLength(true);
        // if (!taskDetail.startTime) {
        //     message.error(t('please set the start time'));
        //     return;
        // }
        // if (!taskDetail.endTime) {
        //     message.error(t('please set the deadline'));
        //     return;
        // }
        // if (!taskDetail.priorityTypes.length) {
        //     message.error(t('Please select type'));
        //     return;
        // }
        let params: TaskExportListType = {
            // title: taskDetail.title, // 任务标题
            priorityTypes: taskDetail.priorityTypes, // 任务优先级
        };
        if (taskDetail.startTime) {
            params.startTime = taskDetail.startTime; // 开始时间
        }
        if (taskDetail.endTime) {
            params.endTime = taskDetail.endTime; // 截止时间
        }
        setQueryParams([
            taskDetail.priorityTypes.join(),
            taskDetail.startTime || '',
            taskDetail.endTime || '',
        ]);
        setLoading(true);
        const [data, err] = await findForExportMyTasks(params);
        setLoading(false);
        if (err) {
            message.error(err);
            return;
        }
        const { tasks } = data;
        if (!tasks.length) {
            setEmptyTaskList(true);
        } else {
            setEmptyTaskList(false);
        }
        setTaskList(tasks || []);
    }
    const DrawerFooter = (
        <div
            style={{
                marginLeft: '14px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginRight: '6px',
            }}
        >
            <div>
                <Button onClick={closeDrawConfirm} style={{ marginRight: 8 }}>
                    取消
                </Button>
                <Button
                    type="primary"
                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_exporttask_export"
                    style={{ marginRight: 8 }}
                    disabled={!taskList.length || exportLoading}
                    onClick={exportConfirm}
                >
                    {t('export')}
                </Button>
            </div>
            {/* <Checkbox onChange={onChange}>{t('export results to personal mailbox')}</Checkbox> */}
        </div>
    );
    function Borderstyle(item: any) {
        const target = PriorityList.find((obj) => obj.code === item.priorityType);
        return `3px solid ${target ? target.color : PriorityList[4].color}`;
    }

    return (
        <Drawer
            width={550}
            title={t('export task')}
            className="work-export-detail-draw"
            onClose={closeDrawConfirm}
            visible={drawVisible}
            destroyOnClose={true}
            footer={DrawerFooter}
        >
            {exportLoading && (
                <div className="exportLoading">
                    <Spin spinning={exportLoading} />
                </div>
            )}
            {!exportLoading && (
                <div className="detail-content">
                    {/* <div className="detail-content-item task-title-item">
                    <div className="label">{t('name')}</div>
                    <div className="title-container">
                        <Input
                            value={taskDetail.title}
                            placeholder={t('input task content')}
                            onChange={handleTitleChange}
                            maxLength={100}
                        />
                    </div>
                </div> */}

                    {/* 起止时间 */}
                    <div className="detail-content-item task-timerange-item common-single-item">
                        <div className="label">
                            {/* <span>*</span> */}
                            {t('Start-stop time')}
                        </div>
                        <div className="timerange-content">
                            <div className="time-button time-button-star">
                                <DatePicker
                                    time={taskDetail.workStartTime || null}
                                    onChange={(time: Dayjs | null) => handleTimeChange(time)}
                                    placeholder={t('set start time')}
                                />
                            </div>
                            <div className="button-split" />
                            <div className="time-button time-button-end">
                                <DatePicker
                                    time={taskDetail.workStopTime || null}
                                    onChange={(time: Dayjs | null) => handleTimeChange(time, false)}
                                    placeholder={t('set end time')}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="detail-content-item task-type-item"
                        style={{ alignItems: 'initial' }}
                    >
                        <div className="label">
                            {/* <span>*</span> */}
                            {t('type')}
                        </div>
                        <TypeSelect
                            onCheckboxChange={(val) => handlePriorityChange(val)}
                            type="checkbox"
                            defaultAllChecked={true}
                        />
                    </div>
                    <div className="detail-content-item task-list-item">
                        <div className="label">{t('task list')}</div>
                        <div className="work-task-list">
                            <div>
                                {loading ? (
                                    '查询中'
                                ) : (
                                    <span>{emptyLength && `共${taskList.length}条任务`}</span>
                                )}
                                <Button
                                    size="middle"
                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_exporttask_search"
                                    onClick={taskListSearch}
                                >
                                    {t('query')}
                                </Button>
                            </div>
                            {loading && !taskList.length && (
                                <div className="example">
                                    {/* <Spin spinning={loading} /> */}
                                    <Loading />
                                </div>
                            )}
                            <ul>
                                <ScrollView ref={scrollRef} autoHide>
                                    {!loading &&
                                        taskList.length > 0 &&
                                        taskList.map((item: any, key) => {
                                            return (
                                                <li
                                                    key={key}
                                                    style={{ borderLeft: Borderstyle(item) }}
                                                >
                                                    {item.title}
                                                </li>
                                            );
                                        })}
                                    {emptyTaskList && (
                                        <Empty
                                            style={{ marginLeft: ' -100px' }}
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description={t('no data')}
                                        />
                                    )}
                                </ScrollView>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportTaskDraw);
