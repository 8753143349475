import BaseService from '@/attributes/BaseService';

class NewService extends BaseService {
    createPageInFile(propertyKey: string, pageId: string, folderId: string) {
        const spaceId = folderId || 'root';

        const id = `${spaceId}_${pageId}`;
        console.log(
            {
                _id: id,
                value: pageId,
            },
            'createPageInFilecreatePageInFilecreatePageInFilecreatePageInFilecreatePageInFile'
        );
        this.saveToDB({
            _id: id,
            value: pageId,
        });
    }
    async getNewDBFilterList(id = '') {
        try {
            let newDoc = { rows: [], total_rows: 0, offset: 0 };
            newDoc = await this.getAllDocsFromDB({
                include_docs: true,
            });
            let newRows = newDoc.rows
                .filter((i: any) => i?.id.indexOf(id) !== -1)
                .map((i: any) => i?.doc?.value);
            return newRows;
        } catch (error) {
            return [];
        }
    }
    async getNewDBListAll() {
        try {
            let newDoc = { rows: [], total_rows: 0, offset: 0 };
            newDoc = await this.getAllDocsFromDB({
                include_docs: true,
            });
            // console.log(newDoc, 'newDocnewDocnewDocnewDocnewDoc');
            let newRows = newDoc.rows.map((i: any) => i?.doc?.value);
            console.log(newRows, 'newRowsnewRowsnewRowsnewRows');
            return newRows;
        } catch (error) {
            console.log(error, 'errorerror');
            return [];
        }
    }
    async remove(id: string) {
        try {
            let newDoc = { rows: [], total_rows: 0, offset: 0 };
            newDoc = await this.getAllDocsFromDB({
                include_docs: true,
            });
            const index = newDoc.rows.findIndex((item: any) => item?.doc?.value === id);
            if (index !== -1) {
                this.deleteFromDB((newDoc.rows[index] as any).id);
                return true;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}
// new NewService('joyspace.db', 'newlists');
export default NewService;
