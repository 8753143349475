import * as React from 'react';
import { Popover } from 'antd';
import './index.less';
import debounce from 'lodash/debounce';
import JEditor from '../..';
import { IRect } from '@/types/chat/Base';
import { isFocusEnv } from '@/utils';

export interface ICursorPopoverProps {
    content: React.ReactNode;
    editor: JEditor;
    withoutPopover?: boolean;
    visible?: boolean;
    onKeyDown?: (key: string) => void;
    onTextInput?: (text: string) => void;
    onVisibleChange?: (visible: boolean) => void;
    onPrefixKeyPressed?: () => void;
    prefix?: string;
    bindings: any;
}

export interface ICursorPopoverState {
    rect: any;
    range: any;
    textInput: string;
    keyPrevent?: boolean;
}

// TODO 关于 popover 显示/关闭 的逻辑，这里处理的稍有简陋，应该是将 @ 做成 embed 元素，通过判断光标是否处在元素内来判断是否显示
export default class CursorPopover extends React.Component<
    ICursorPopoverProps,
    ICursorPopoverState
> {
    anchorRef: any;
    constructor(props: any) {
        super(props);
        this.onPrefixKeyPressed = debounce(this.onPrefixKeyPressed, 50);
        this.state = {
            rect: null,
            range: null,
            textInput: '',
            keyPrevent: true,
        };
    }
    componentDidMount() {
        const { editor } = this.props;
        if (!editor) {
            return;
        }
        const quill = editor.getQuill();
        if (!quill) {
            return;
        }
        if (editor) {
            quill.on('text-change', this.onEditorTextChange);
            if (!isFocusEnv()) {
                quill.on('text-change', this.onSpaceKeyCompatible);
            }
            quill.on('text-change', this.onEditorChange);
            editor.events.on('editor-selection-change', this.onEditorSelectionChange);
            editor.events.on('editor-keyDown', this.onEditorKeyDown);
            editor.events.on('close-mention', this.hide);
        }
    }
    componentWillUnmount() {
        const { editor } = this.props;
        if (!editor) {
            return;
        }
        const quill = editor.getQuill();
        if (!quill) return;
        if (editor) {
            quill.off('text-change', this.onEditorChange);
            if (!isFocusEnv()) {
                quill.off('text-change', this.onSpaceKeyCompatible);
            }
            quill.off('text-change', this.onEditorTextChange);
            editor.events.off('editor-selection-change', this.onEditorSelectionChange);
            editor.events.off('editor-keyDown', this.onEditorKeyDown);
            editor.events.off('close-mention', this.hide);
        }
    }

    // 空格键兼容处理
    onSpaceKeyCompatible = (delta: any, oldDelta: any, source: any) => {
        const { editor, visible, onKeyDown } = this.props;
        if (!editor) return;
        let { ops } = delta;
        let len = ops.length;

        if (!len) {
            return;
        }
        let op = ops[len - 1];

        if (visible && op?.insert === ' ') {
            let currentLength = editor.quill?.getLength() || 0;
            let contentsDelta = editor.quill?.getContents();

            console.log('contentsDelta', contentsDelta);
            let { ops: contentsOps } = contentsDelta || {};
            if (contentsOps?.length) {
                let prevIndex = contentsOps?.length - 2 || 0;
                // 最新的插入符号
                let prevOp = contentsOps[prevIndex];
                let insertData: any = prevOp?.insert;
                let data = insertData['mention-link'] || {};
                console.log('data', data);

                if (data?.name === '全体成员') {
                    return;
                }
            }

            if (currentLength > 2) {
                editor.quill?.deleteText(currentLength - 2, 1);
            }
            if (typeof onKeyDown === 'function') {
                onKeyDown('space');
            }
        }
    };

    onEditorTextChange = (delta: any, oldDelta: any, source: any) => {
        if (source !== 'user') {
            return;
        }
        if (delta && delta.ops.length !== 0) {
            // console.log('delta.ops', delta.ops);
            delta.ops.some((op: any) => {
                if (op.insert && this.props.bindings.find((b: any) => b === op.insert)) {
                    this.onPrefixKeyPressed();
                    return true;
                }
                return false;
            });
        }
    };

    onAtKeyDown = () => {
        this.onPrefixKeyPressed();
        return true;
    };

    setKeyPrevent = (keyPrevent: boolean) => {
        this.setState({
            keyPrevent,
        });
    };

    show(rect: IRect, range: any) {
        this.setState({
            rect,
            range,
        });
        this.autoDisableEditorEnterKey();
        if (this.props.onVisibleChange) {
            this.props.onVisibleChange(true);
        }
    }
    hide = () => {
        this.setState(
            {
                rect: null,
                range: null,
                textInput: '',
            },
            () => {
                this.autoDisableEditorEnterKey();
            }
        );

        if (this.props.onVisibleChange) {
            this.props.onVisibleChange(false);
        }
        const { editor } = this.props;
        if (!editor) return;
        editor.changeAtActive(false);
    };
    onEditorSelectionChange = () => {
        setTimeout(() => {
            const { rect } = this.state;
            if (!rect) return;
            const { editor } = this.props;
            if (!editor) return;
            const range = editor.getSelection();
            if (!range) {
                // 应该不会到这里
                return;
            }
            if (range.index < this.state.range.index) {
                // console.log('onEditorSelectionChange111', range, this.state.range.index);
                this.hide();
                return;
            }
            const quill = this.props.editor.getQuill();
            if (!quill) return;
            const textInput = editor.getText(
                this.state.range.index,
                range.index - this.state.range.index
            );
            if (textInput.length > this.state.textInput.length) {
                // console.log('onEditorSelectionChange222', textInput, this.state.textInput);
                this.hide();
                return;
            }
        }, 30);
    };

    onEditorChange = (delta: any, oldDelta: any, source: any) => {
        const { editor, visible } = this.props;
        const { rect, textInput } = this.state;
        if (!editor) return;
        // console.log('onEditorChange111', rect);

        // 按键没有触发时不执行检测
        if (!rect) {
            return;
        }

        const range = editor.getSelection();
        // console.log('onEditorChange222', range, this.state.range);
        if (!range) {
            // 应该不会到这里
            return;
        }
        if (range.index < this.state.range.index) {
            // console.log('onEditorChange333', range, this.state.range);
            this.hide();
            return;
        }

        const tempTextInput = editor.getText(
            this.state.range.index,
            range.index - this.state.range.index
        );
        // console.log('哈哈哈', visible, textInput, tempTextInput.trim());
        if (visible && !tempTextInput.trim() && !textInput) {
            console.log('打开@符号且输入的空格');
            return;
        }
        const realText = tempTextInput.endsWith('@') ? '' : tempTextInput;
        this.setState({
            textInput: realText,
        });

        if (this.props.onTextInput) {
            this.props.onTextInput(realText);
        }
        if (/\s{2}$/.test(realText)) {
            this.hide();
            return;
        }
    };
    autoDisableEditorEnterKey() {
        const { rect } = this.state;
        const keyboardModule = this.props.editor.getModule('keyboard');
        if (!keyboardModule) return;
        const { bindings } = keyboardModule;
        if (!bindings[13]) return;
        const binding = bindings[13][0];
        if (!binding) return;
        if (rect && binding.handler !== this.handleEnterKey) {
            bindings[13].unshift({ key: 'Enter', handler: this.stopJeditorDefault });
        }
        if (!rect && binding.handler === this.stopJeditorDefault) {
            bindings[13].shift();
        }
    }

    /**
     * 阻止编辑器默认插入回车行为
     */
    stopJeditorDefault = () => {
        return false;
    };

    handleEnterKey = () => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown('Enter');
        }
        this.hide();
    };

    onPrefixKeyPressed() {
        const { editor } = this.props;
        if (!editor) return;
        const rects = editor.getSelectionRects();
        if (!rects.length) {
            // console.log('onPrefixKeyPressed', rects);
            this.hide();
            return;
        }
        const rect = rects[0];
        const range = editor.getSelection();
        if (!range) {
            // 应该不会到这里
            return;
        }
        // console.log('onPrefixKeyPressed');
        this.show(rect, range);
        editor.changeAtActive(true);
        if (this.props.onPrefixKeyPressed) {
            this.props.onPrefixKeyPressed();
        }
    }

    onEditorArrowKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        // console.log('e', e, e.key, e.keyCode, e.which, e.charCode);
        const { rect, keyPrevent } = this.state;
        if (!rect || !keyPrevent) {
            return;
        }
        if (!this.props.onKeyDown) return;
        if (
            ['Enter', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) < 0 &&
            e.keyCode !== 32
        )
            return;
        e.preventDefault();
        e.stopPropagation();
        const key = e.keyCode === 32 ? 'space' : e.key;
        switch (key) {
            case 'ArrowUp':
                this.props.onKeyDown(key);
                break;
            case 'ArrowDown':
                this.props.onKeyDown(key);
                break;
            case 'Enter':
                // this.props.onKeyDown(e.key);
                this.handleEnterKey();
                break;
            case 'space':
                this.props.onKeyDown(key);
                break;
            default:
        }
    }
    onEditorKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') {
            this.hide();
            return;
        }

        this.onEditorArrowKeyDown(e);

        // this.onEditorKeyDownCallback();
    };
    render() {
        const { rect } = this.state;
        const { withoutPopover } = this.props;
        if (withoutPopover) {
            if (!rect) {
                return null;
            }
            return this.props.content;
        }
        return (
            <div className="mention">
                <Popover placement="bottomLeft" visible={!!rect} content={this.props.content}>
                    <div
                        ref={(ref) => {
                            this.anchorRef = ref;
                        }}
                        className="mention-anchor"
                        style={
                            rect
                                ? {
                                      top: rect.top,
                                      height: rect.height,
                                      left: rect.left,
                                  }
                                : { visibility: 'hidden' }
                        }
                    />
                </Popover>
            </div>
        );
    }
}
