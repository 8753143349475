import * as Sentry from '@sentry/react';

const sentryManual = {
    captureException(error: any, tag: string) {
        try {
            Sentry.withScope(function (scope) {
                if (tag) {
                    scope.setTag('custom-tag', tag);
                }
                Sentry.captureException(error);
            });
        } catch (e) {
            console.log(e);
        }
    },
    captureMessage(message: any, tag: string) {
        try {
            Sentry.withScope(function (scope) {
                if (tag) {
                    scope.setTag('custom-tag', tag);
                }
                Sentry.captureMessage(message);
            });
        } catch (e) {
            console.log(e);
        }
    },
};

export default sentryManual;
