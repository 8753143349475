import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styles from './offline.module.less';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { CloseCircleOutlined } from '@ant-design/icons';
import IconFont from '@/components/icon';
function mapStateToProps({ chat }: { chat: ChatState }) {
    const { connectState } = chat;
    return { connectState };
}

interface OfflineProps {
    connectState: boolean;
}

type IProps = Readonly<OfflineProps & ChatState & WithTranslation>;

function OfflineTip(props: IProps) {
    const { connectState, t } = props;
    return connectState.state === ConnectState.OFFLINE ? (
        <div
            className={styles['offline-wrap']}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <p className={styles['offline-content']}>
                <IconFont className={styles['icon-close']} type="iconjs_ic_error" />
                <span>{t('connection-status.network-disabled')}</span>
            </p>
        </div>
    ) : null;
}

export default connect(mapStateToProps)(withTranslation('chat')(OfflineTip));
