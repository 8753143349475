import React, { useContext } from 'react';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import WinToolbar from '@/layout/WinToolbar';
import Search from '@/layout/Search';
import config, { ConfigEnum } from '@/config/config';
import { getSelected } from '@/components/chat/utils/message';

export default function RightContent({ children }: { children?: any }) {
    const { isDesktop, appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const winTool = isDesktop && appRuntimeEnv.platform === 'WINDOWS' ? <WinToolbar /> : null;
    let conut = 0;
    return (
        <div className="rightSide">
            {config[ConfigEnum.TOP_BAR_ENABLE] && winTool && (
                <div className="topBar">
                    {winTool && <div style={{ width: 120 }} />}
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 1 }} />
                    {winTool}
                </div>
            )}
            <div className="rightSide">
                <div className="appContent">{children}</div>
            </div>
        </div>
    );
}
