import React, { useContext, useEffect, useState } from 'react';
import { Spin, message, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LoginFormContext, LoginFormContextType } from '../../utils';
import { IconArrowRight, IconGroup, IconJoyMeetingGroup } from '@/components/icon';
import SelectTeam from './SelectTeam';
import JoinOtherTeam from './JoinOtherTeam';
import CreateTeam from './CreateTeam';
import { BackNav } from '../../comps';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { ApprovaTeamUserInfoList } from '@/types/LoginPage';
import { useTranslation } from 'react-i18next';
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
const teamBgImg = `${REACT_APP_OSS_BASE_URL}/statics/images/team_bg.png`;
enum JoinPath {
    Init,
    JoinOtherTeam,
    CreateTeam,
}

function EntryItem({
    title,
    desc,
    styles,
    icon,
    clickHandle,
}: {
    styles: any;
    title: string;
    desc: string;
    icon: any;
    clickHandle: () => void;
}) {
    return (
        <div
            style={{
                display: 'flex',
                borderRadius: '6px',
                boxShadow: '0px 8px 20px 0px rgba(0,0,0,0.1)',
                height: '96px',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
                marginBottom: '18px',
                cursor: 'pointer',
                backgroundPosition: 'top right',
                backgroundSize: '99px 47px',
                backgroundImage: `url(${teamBgImg})`,
                backgroundRepeat: 'no-repeat',
            }}
            className="entry hover"
            onClick={clickHandle}
        >
            <div style={{ marginRight: '32px' }}>
                <p
                    style={{
                        textAlign: 'left',
                    }}
                >
                    {icon}
                    <span className="title" style={{ ...styles, marginLeft: 6 }}>
                        {title}
                    </span>
                </p>
                <p className="desc">{desc}</p>
            </div>
            {IconArrowRight}
        </div>
    );
}

export default function JoinMeForm({ onSelectTeam }: { onSelectTeam: (teamId: string) => void }) {
    const { service, appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const { navigate, onMobileChange, setResendLastTs, authInfo } = React.useContext<
        LoginFormContextType
    >(LoginFormContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = React.useState(true);
    const [approveList, setApproveList] = useState([] as ApprovaTeamUserInfoList);
    const [joinPath, setJoinPath] = useState(JoinPath.Init);
    const [hasFetch, setHasFetch] = useState(false);
    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );
    const canCreateTeamConfig = appRuntimeEnv.getUIConfig('canCreateTeam');
    const canJoinTeamConfig = appRuntimeEnv.getUIConfig('canJoinTeam');
    const canCreateTeam =
        typeof canCreateTeamConfig === 'undefined' || canCreateTeamConfig === true;

    const canJoinTeam = typeof canJoinTeamConfig === 'undefined' || canCreateTeamConfig === true;

    useEffect(() => {
        const { accessToken } = authInfo;
        if (accessToken && joinPath === JoinPath.Init && !hasFetch) {
            setLoading(true);
            setHasFetch(true);
            service.focusService
                .getApproveStatus()
                .then((res: any) => {
                    setLoading(false);
                    setApproveList(res.teamInfoList as ApprovaTeamUserInfoList);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [authInfo, hasFetch, joinPath]); //eslint-disable-line

    let initEle = null;
    if (!loading && !approveList.length && !authInfo.teamUserInfoList.length) {
        initEle = (
            <div className="mobileForm">
                <BackNav
                    clickHandle={() => {
                        onMobileChange('');
                        setResendLastTs(-1);
                        setTimeout(() => {
                            navigate('step1');
                        }, 0);
                    }}
                    title={t('login.changeLogin')}
                    subtitle={t('login.welcome', {
                        appName: appRuntimeEnv.productName,
                    })}
                    desc={t('login.teamDesc')}
                />
                <div
                    style={{
                        marginTop: 20,
                    }}
                >
                    {canCreateTeam && (
                        <EntryItem
                            styles={
                                {
                                    // color: color.primary,
                                }
                            }
                            title={t('login.createTeam', {
                                appName: appRuntimeEnv.productName,
                            })}
                            desc={t('login.createDesc')}
                            icon={IconJoyMeetingGroup}
                            clickHandle={() => setJoinPath(JoinPath.CreateTeam)}
                        />
                    )}
                    {canJoinTeam && (
                        <EntryItem
                            styles={{
                                color: '#F7B500',
                            }}
                            title={t('login.joinTeam', {
                                appName: appRuntimeEnv.productName,
                            })}
                            desc={t('login.joinDesc')}
                            icon={IconGroup}
                            clickHandle={() => setJoinPath(JoinPath.JoinOtherTeam)}
                        />
                    )}
                </div>
            </div>
        );
    }
    if (!loading && (approveList.length || authInfo.teamUserInfoList.length)) {
        initEle = (
            <SelectTeam
                approveList={approveList}
                teamUserInfoList={authInfo.teamUserInfoList}
                onSelectTeam={onSelectTeam}
                onJoinOtherTeam={() => setJoinPath(JoinPath.JoinOtherTeam)}
                onCreateTeam={() => setJoinPath(JoinPath.CreateTeam)}
            />
        );
    }
    return (
        <>
            {joinPath === JoinPath.Init ? initEle : null}
            {joinPath === JoinPath.JoinOtherTeam ? (
                <JoinOtherTeam goBack={() => setJoinPath(JoinPath.Init)} />
            ) : null}
            {joinPath === JoinPath.CreateTeam ? (
                <CreateTeam goBack={() => setJoinPath(JoinPath.Init)} />
            ) : null}
        </>
    );
}
