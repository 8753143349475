/**
 * 文档左侧导航tree
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Icon from '../../Icon';
import { message, Tree } from 'antd';
import './tree.less';
import { useTranslation } from '../../context';
import { _Active, selectArrayType } from './types';
import { ICurrentRouteInfo } from '../../types';
import events from '../../context/events';
import { folderTypeEnum } from '@/components/Joyspace/config/permissions.config';
const { DirectoryTree, TreeNode } = Tree;
import api from '../../api';

interface IProps {
    setConfirmable: (flag: boolean) => void;
    setAddabled: (flag: boolean) => void;
    setActive: (a: _Active) => void;
    type: 'myspace' | 'teamspace' | 'other';
    selectType: 'file' | 'folder' | 'both';
    excludesFolerMap?: { [key: string]: any };
    canSelectMy?: boolean;
}
import { folderType } from '../../config/permissions.config';
import folderPng from '@/assets/img/folder.png';
import ShareIcon from '../List/ShareIcon';
interface navItem {
    id: string;
    name: string;
    isLeaf?: boolean;
    children?: navItem[];
    [key: string]: any;
}
function SelectTree(props: IProps) {
    const navlinks = [
        {
            name: 'myspace',
            id: 'myspace',
            icon: 'iconpc_yundoc_myfile',
            level: 0,
            isLeaf: false,
            type: folderTypeEnum.MY,
            disabled: false,
            selectable: true,
        },
        {
            name: 'teamspace',
            icon: 'iconpc_yundoc_sharefile',
            id: folderType.SHARE,
            level: 0,
            type: folderTypeEnum.SHARE,
            isLeaf: false,
            disabled: false,
            selectable: true,
        },
    ];
    const {
        canSelectMy = false,
        excludesFolerMap,
        selectType,
        setAddabled,
        setActive,
        type,
        setConfirmable,
    } = props;

    if (!canSelectMy && type === 'teamspace') {
        navlinks[0].isLeaf = true;
        navlinks[0].selectable = false;
        navlinks[0].disabled = true;
    }
    // if (selectType !== 'folder') {
    //     navlinks[0].isLeaf = true;
    //     navlinks[0].disabled = true;
    // }

    const lang = useTranslation();
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [treeData, setTreeData] = useState<navItem[]>(navlinks);
    // 如果是共享文件我的文件要注释变灰,默认选中共享文件夹
    const defaultSelectedKeys = getDefaultSelectKeys();
    const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);
    const [key, setKey] = useState(1);
    const onExpand = (expandedKey: any, obj: any) => {
        if (obj.expanded) {
            setExpandedKeys(expandedKey);
        } else {
            const arr: any = [];
            expandedKey.forEach((itemKey: any) => {
                if (itemKey === obj.node.key) {
                    arr.push(obj.node.key);
                }
                getChildExpandKey(itemKey, obj.node.children, arr);
            });
            let currentKey = expandedKey.filter(function (val: any) {
                return arr.indexOf(val) === -1;
            });
            setExpandedKeys(currentKey);
        }
    };
    function getDefaultSelectKeys() {
        if (type === 'teamspace') {
            return [folderType.SHARE];
        }
        if (type === 'myspace') {
            return [type];
        }
        // return [];
    }
    function getChildExpandKey(expandedKey: any, child: any, arr: any) {
        if (child) {
            child.forEach((item: any) => {
                if (expandedKey === item.key) {
                    arr.push(item.key);
                }
                getChildExpandKey(expandedKey, item.children, arr);
            });
        }
    }
    const onSelect = useCallback(
        (selectedKeys: any, e: any) => {
            const { node } = e;
            // if (node.className === 'first-level-node') {
            //     return;
            // }
            const useKey = node.key === 'myspace' ? 'root' : node.key;
            setActive({
                id: useKey,
                type: node['data-type'] || null,
                rootId: node['data-root'] || null,
            });
        },
        [setActive]
    );
    useEffect(() => {
        events.on('selectTreeAddNode', (item, key) => {
            const useKey = key === 'root' ? 'myspace' : key;
            const useTreeData = addTreeNode(treeData, useKey, item);
            setTreeData(useTreeData);
            setSelectedKeys([item.id]);
            setActive({
                id: item.id,
                type: item.type,
                rootId: item.space_id,
            });
            // if (!expandedKeys.includes(item.parent as never)) {
            //     const expandedKeysUse = [...expandedKeys];
            //     expandedKeysUse.push(item.parent as never);
            //     setExpandedKeys(expandedKeysUse);
            // }
            setKey(key + 1);
        });
        return () => {
            events.off('selectTreeAddNode');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });
    const IconTitleRight = <Icon type="iconic_pc_sanjiao_yundoc" className="tree-icon-expand" />;
    function updateTreeList(list: any, key: any, children: any) {
        const data = list.map((node: any) => {
            if (node.id === key) {
                return {
                    ...node,
                    children: children,
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateTreeList(node.children, key, children),
                };
            }
            return node;
        });
        return data;
    }
    function addTreeNode(list: any, key: any, addNode: any) {
        const data = list.map((node: any) => {
            if (node.id === key) {
                const children = node.children ? [...node.children] : [];
                children.unshift(addNode);
                return {
                    ...node,
                    children: children,
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: addTreeNode(node.children, key, addNode),
                };
            }
            return node;
        });
        return data;
    }

    function onLoadData({ key, children }: any) {
        return new Promise<void>((resolve) => {
            const searchFolderId = key === 'myspace' ? 'root' : key;
            api.pages
                .getFileList(searchFolderId)
                .then((data) => {
                    const filterData = data.filter((item: any) => {
                        return (
                            !excludesFolerMap?.hasOwnProperty(item.id) &&
                            (item.type === folderTypeEnum.MY ||
                                item.permissions?.includes('FOLDER_EDIT'))
                        );
                    });
                    const resetTreeData = updateTreeList(treeData, key, filterData);
                    setTreeData(resetTreeData);
                    resolve();
                })
                .catch((e) => {
                    console.warn('error', e);
                });
        });
    }
    function renderTree(data: navItem[]) {
        return data.map((item: any) => {
            return (
                <TreeNode
                    className={item.level === 0 ? 'first-level-node' : ''}
                    data-type={item.type}
                    data-root={item.space_id || ''}
                    data-permission_type={item.permission_type}
                    title={() => {
                        return (
                            <div className="nav-tree-item-title" id={item.id}>
                                <div className="nav-item-content">
                                    {item.level === 0 ? (
                                        <Icon type={item.icon} />
                                    ) : (
                                        <ShareIcon isShared={item.isShared} />
                                        // <img src={folderPng} className="iconic-folder" />
                                    )}
                                    <div className="nav-item-name">
                                        {item.level === 0 ? lang(item.name + '.title') : item.name}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                    key={item.id}
                    isLeaf={item.isLeaf === true}
                    disabled={item.disabled === true}
                    selectable={item.selectable !== false}
                >
                    {item?.children && renderTree(item?.children || [])}
                </TreeNode>
            );
        });
    }

    return (
        <div className="joyspace-select-tree-space ">
            <DirectoryTree
                multiple
                key={key}
                blockNode
                loadData={onLoadData}
                icon={() => {
                    return false;
                }}
                onExpand={onExpand}
                loadedKeys={expandedKeys}
                switcherIcon={IconTitleRight}
                onSelect={onSelect}
                defaultSelectedKeys={selectedKeys}
                defaultExpandedKeys={expandedKeys}
            >
                {treeData && renderTree(treeData)}
            </DirectoryTree>
        </div>
    );
}

export default SelectTree;
