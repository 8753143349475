/* eslint-disable new-cap */
/* eslint-disable max-params */
import BaseController from '@/attributes/BaseController';
import PageModel from '../datamodel/PageModel';
// import ListService from '../service/ListService';
// import NewService from '../service/NewService';
// import PageService from '../service/PageService';
import Moment from 'moment';
import InitDBService from './InitJoySpaceController';
// import UserService from '../service/UserService';
import events from '@/components/Joyspace/context/events';
enum OrderEnum {
    asc = 'ASC',
    desc = 'DESC',
    normal = 'NORMAL',
}
export enum SyncStatusEunm {
    WAITING = 0, // 等待同步 断网 变成 等待联网后同步
    PROCESS = 1, // 同步中 断网 变成 同步失败
    COMPLETE = 2, // 同步完成
    ERROR = 3, // 同步失败
    DATAERROR = 4, // 同步异常 服务器返回异常
}
enum SortEnum {
    date = 'DATE',
    string = 'STRING',
    number = 'NUMBER',
}

const SortMap: any = {
    update_time: 'update_at',
    create_time: 'create_at',
    title: 'title',
};

const SortTypeMap: any = {
    update_time: SortEnum.date,
    create_time: SortEnum.date,
    title: SortEnum.string,
};

function compareData(a: any, b: any, type: SortEnum) {
    let result;
    switch (type) {
        case SortEnum.date:
            result = new Date(a).getTime() - new Date(b).getTime();
            break;
        case SortEnum.string:
            break;
        case SortEnum.number:
            break;
        default:
    }
    return result;
}

export function getFullTime(timeStamp: number) {
    const stamp = new Date(timeStamp);
    const time = Moment(stamp).format('YYYY-MM-DD HH:mm:ss');
    return time;
}
export const JoyspaceData: any = {
    syncStateMap: {},
};
class JoyspaceController extends BaseController {
    listDBService: any;
    newDBService: any;
    pageService: any;
    userService: any;
    syncStateMap: any;
    constructor() {
        super();
        this.syncStateMap = {};
    }
    initDb() {
        this.listDBService = InitDBService.GetListService();
        this.newDBService = InitDBService.GetNewService();
        this.pageService = InitDBService.GetPageService();
        this.userService = InitDBService.GetUserService();
    }
    // 改变同步状态
    setSyncStateMap(key?: string, type?: SyncStatusEunm) {
        if (key) {
            this.syncStateMap[key] = type || SyncStatusEunm.WAITING;
        } else {
            this.syncStateMap = {};
        }

        // events.emit('list.reload');
    }
    // 获取同步状态
    getSyncStateMap() {
        return this.syncStateMap;
    }
    // 我的文件&共享文件&最近&我发出的
    async getList(propertyKey: string, ...args: any[]) {
        const [params, id = ''] = [...args];
        const _propertyKey = `${propertyKey}_${id}`;
        const start = params.start;
        const length = params.length || 30;
        try {
            const list: any = await this.listDBService.getDBList(_propertyKey);
            let newRows: any = [];
            if (id) {
                let _id = id.indexOf('$') !== -1 ? 'root' : id;
                newRows = await this.newDBService.getNewDBFilterList(_id);
            } else if (propertyKey === 'recent') {
                newRows = await this.newDBService.getNewDBListAll();
            }
            console.log(newRows, 'newRowsnewRowsnewRowsnewRowsnewRows', list, '===>');
            let theNewRows = await this.pageService.concatList2Page(newRows);
            theNewRows = theNewRows.map((item: any) => {
                item.updated_at = getFullTime(item.updated_at);
                item.created_at = getFullTime(item.created_at);
                item.openTime = getFullTime(item.openTime);
                if (item.syncErrorInfo) {
                    this.syncStateMap[item.id] = SyncStatusEunm.DATAERROR;
                }
                console.log('syncStateMap', this.syncStateMap, this.syncStateMap[item.id], item);
                item.syncStatus = this.syncStateMap[item.id] || SyncStatusEunm.WAITING;
                return item;
            });
            console.log('theNewRows===>', theNewRows, list, 'listlistlistlistlistlist==>');
            // let pageList = await this.pageService.concatList2Page(list);
            let value = theNewRows ? theNewRows.concat(list || []) : list;
            const data: any = {};
            data.total = value.length;
            // console.log(start, 'startstartstart=>');
            data.pages = value.splice(start, length);
            console.log(data, 'datadatadatadatadatadata', value);
            return data;
        } catch (error) {
            // console.log(error, 'errorerrorerrorerror');
            return { total: 0, data: [] };
        }
    }
    setList(propertyKey: string, data: any, ...args: any[]) {
        const [params, id = ''] = [...args];
        const _propertyKey = `${propertyKey}_${id}`;
        const start = params.start;
        const length = params.length || 30;
        const pages = data.pages;
        // console.log('setList===>', pages, _propertyKey);
        return this.listDBService.setPagingDBList(_propertyKey, pages, start, length);
    }
    // 我的文件夹&共享文件夹
    async getFolderList(propertyKey: string, ...args: any[]) {
        const [id] = [...args];
        const _propertyKey = `${propertyKey}_${id}`;
        // console.log(_propertyKey, '_propertyKey_propertyKey_propertyKey');
        const list = this.listDBService.getDBList(_propertyKey);
        // console.log(list, 'getFolderList==');
        return await this.listDBService.getDBList(_propertyKey);
    }
    setFolderList(propertyKey: string, data: any, ...args: any[]) {
        const [id] = [...args];
        const _propertyKey = `${propertyKey}_${id}`;
        return this.listDBService.setDBList(_propertyKey, data);
    }
    // 我收到
    getSharesList(propertyKey: string, ...args: any[]) {
        const [params] = [...args];
        const _propertyKey = `${propertyKey}`;
        const start = params.start;
        const length = params.length || 30;
        return this.listDBService.getPagingDBList(_propertyKey, start, length, 'shares');
    }
    setSharesList(propertyKey: string, data: any, ...args: any[]) {
        const [params] = [...args];
        const _propertyKey = `${propertyKey}`;
        const start = params.start;
        const length = params.length || 30;
        const pages = data.shares || [];
        return this.listDBService.setPagingDBList(_propertyKey, pages, start, length);
    }
    async getShortcutsDBList(propertyKey: string, start: number, length: number, val?: string) {
        try {
            const doc: any = await this.listDBService.getPagingDBList(propertyKey, start, length);
            let value = doc?.value || [];
            console.log(doc, 'doc==>');
            const data: any = {};
            data.total = doc.total;
            data.shortcuts = value;
            // data.nomore = data.total < doc.total;
            return data;
        } catch (error) {
            // console.log(error, 'getShortcutsDBList');
            return await { total: 0, shortcuts: [], nomore: true };
        }
    }
    // 置顶
    getShortcutsList(propertyKey: string, ...args: any[]) {
        const [params] = [...args];
        const _propertyKey = `${propertyKey}`;
        const start = params.start;
        const length = params.length || 30;
        // console.log(_propertyKey, '_propertyKey_propertyKey_propertyKey');
        return this.getShortcutsDBList(_propertyKey, start, length);
    }
    setShortcutsList(propertyKey: string, data: any, ...args: any[]) {
        const [params] = [...args];
        const _propertyKey = `${propertyKey}`;
        const start = params.start;
        const length = params.length || 30;
        const pages = data.shortcuts || [];
        // const list = this.listDBService.setPagingDBList(_propertyKey, pages, start, length);
        // console.log(list, 'setShortcutsListsetShortcutsListsetShortcutsList');
        return this.listDBService.setPagingDBList(_propertyKey, pages, start, length);
    }
    // 收藏
    getCollectionsList(propertyKey: string, ...args: any[]) {
        const [params, targetType = ''] = [...args];
        const _propertyKey = `${propertyKey}_${targetType}`;
        const start = params.start;
        const length = params.length || 30;
        return this.listDBService.getPagingDBList(_propertyKey, start, length, 'data');
    }
    setCollectionsList(propertyKey: string, data: any, ...args: any[]) {
        const [params, targetType = ''] = [...args];
        const _propertyKey = `${propertyKey}_${targetType}`;
        const start = params.start;
        const length = params.length || 30;
        const pages = data.data || [];
        return this.listDBService.setPagingDBList(_propertyKey, pages, start, length);
    }
    // 回收站
    getTrashesList(propertyKey: string, ...args: any[]) {
        const [params] = [...args];
        const _propertyKey = `${propertyKey}`;
        const start = params.start;
        const length = params.length || 30;
        return this.listDBService.getPagingDBList(_propertyKey, start, length, 'trashes');
    }
    setTrashesList(propertyKey: string, data: any, ...args: any[]) {
        const [params] = [...args];
        const _propertyKey = `${propertyKey}`;
        const start = params.start;
        const length = params.length || 30;
        const pages = data.trashes || [];
        return this.listDBService.setPagingDBList(_propertyKey, pages, start, length);
    }
    // 获取用户信息
    async getUserInfo() {
        return await this.userService._getUserInfo();
    }
    // 存储用户信息
    setUserInfo(propertyKey: string, id: string, data: any) {
        this.userService.setUserInfo(data);
    }
    // 删除最近
    async removeRecent(propertyKey: string, id: string) {
        // const res = await this.newDBService.remove(id);
        // if (res) {
        const result = await this.pageService.remove(id);
        if (result) {
            return true;
        }
        return false;
        // }
        // return false;
    }
    // 删除
    async remove(propertyKey: string, ...args: any[]) {
        try {
            // eslint-disable-next-line no-debugger
            debugger;
            const [spaceId, id] = [...args];
            const res = await this.newDBService.remove(id);
            if (res) {
                const result = await this.pageService.remove(id);
                if (result) {
                    return true;
                }
                return false;
            }
            return false;
        } catch (error) {
            return false;
        }
    }
    // 有网时，合并离线时新建的本地文档数据
    async concatList(propertyKey: string, result: any, ...args: any[]) {
        console.log(propertyKey, result, args, '有网时，合并离线时新建的本地文档数据');
        const [params, id = ''] = [...args];
        const _propertyKey = `${propertyKey}_${id}`;
        const start = params.start;
        let total = result?.total || 0;
        let pages = result?.pages || [];
        try {
            if (!start) {
                let newRows: any = [];
                if (id) {
                    let _id = id.indexOf('$') !== -1 ? 'root' : id;
                    newRows = await this.newDBService.getNewDBFilterList(_id);
                } else if (propertyKey === 'recent') {
                    newRows = await this.newDBService.getNewDBListAll();
                }
                let theNewRows = await this.pageService.concatList2Page(newRows);
                console.log(theNewRows, 'theNewRowstheNewRowstheNewRows', newRows);
                theNewRows = theNewRows.map((item: any) => {
                    item.updated_at = getFullTime(item.updated_at);
                    item.created_at = getFullTime(item.created_at);
                    item.openTime = getFullTime(item.openTime);

                    if (item.syncErrorInfo || !item.page_id) {
                        this.syncStateMap[item.id] = SyncStatusEunm.DATAERROR;
                    }
                    // console.log(
                    //     'syncStateMap',
                    //     this.syncStateMap,
                    //     this.syncStateMap[item.id],
                    //     item
                    // );
                    item.syncStatus = this.syncStateMap[item.id] || SyncStatusEunm.WAITING;
                    return item;
                });
                pages = theNewRows.concat(pages);
                total += theNewRows.length;
            }
            // console.log({ total: total, pages: pages }, '====================>');
            return { total, pages };
        } catch (error) {
            // console.log(error, 'errorerrorerrorerror');
            return { total: 0, data: [] };
        }
    }
}
// console.log('this is new joyspaceController', new JoyspaceController());
export default new JoyspaceController();
