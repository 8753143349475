import React, { useState, ChangeEvent, Dispatch, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Input, message } from 'antd';
import { updateProject } from '../../api';
import { Project, ProjectRole } from '../../types';
import UserAvatar from '../../components/UserAvatar';
import ChangeOwner from '../ChangeOwner';
import IconFont from '@/components/icon';
import Eventemitter3 from 'eventemitter3';
import './index.less';

interface propTypes {
    onCancel: () => void;
    projectId: string;
    title: string;
    description: string;
    project?: Project;
    callback: Dispatch<SetStateAction<Project | undefined>>;
    events: Eventemitter3;
    t: any;
}

export function ProjectInfo(props: propTypes) {
    const {
        projectId,
        project: {
            ownerInfo: projectOwnerInfo = {
                headImg: '',
                userName: '',
                app: '',
                userId: '',
                teamId: '',
            },
            userInfo = {
                userRole: ProjectRole,
            },
        } = {},
        callback,
        events,
        t,
    } = props;
    const [visible, setVisible] = useState(true);
    const [isShowChangeOwner, setIsShowChangeOwner] = useState(false);
    const [userAvatar, setUserAvatar] = useState<{ headImg: string; userName: string }>({
        headImg: projectOwnerInfo.headImg,
        userName: projectOwnerInfo.userName,
    });
    const [description, setDescription] = useState<string>(props.description || '');
    const [descriptionLength, setDescriptionLength] = useState(100);
    const [name, setName] = useState<string>(props.title || '');
    const [nameLength, setNameLength] = useState(20);
    const [currentUserRole, setCurrentUserRole] = useState(
        userInfo?.userRole || ProjectRole.MEMBER
    );

    function handleOK() {
        updateProject(projectId, {
            name,
            description,
        }).then((res) => {
            if (res.errCode) return;
            message.success(t('operate_success'));
            setVisible(false);
            const newvalue: any = {
                ...props.project,
                title: name,
                description,
                ownerInfo: { ...props.project?.userInfo, ...userAvatar },
                userInfo: {
                    userRole: currentUserRole,
                },
            };
            events.emit('tabs.setProps', { title: name, id: projectId, description });
            callback(newvalue);
        });
    }

    function renderChangeOwner() {
        if (
            !props.project?.archive &&
            currentUserRole === ProjectRole.OWNER &&
            !isShowChangeOwner
        ) {
            return (
                <div className="pm-onlyshow-avatar">
                    <UserAvatar headImg={userAvatar.headImg} userName={userAvatar.userName} />
                    <span onClick={() => setIsShowChangeOwner(true)}>
                        {userAvatar.userName}
                        <IconFont type="iconjs_ic_edit" className="pm-avatar-edit-icon" />
                    </span>
                </div>
            );
        }
        if (!props.project?.archive && isShowChangeOwner) {
            return (
                <ChangeOwner
                    projectId={projectId}
                    project={props.project}
                    t={t}
                    ownerCallback={(value, isChangeRole) => {
                        setIsShowChangeOwner(false);
                        value && setUserAvatar(value);
                        if (isChangeRole) {
                            setCurrentUserRole(ProjectRole.ADMIN);
                            const newvalue: any = {
                                ...props.project,
                                userInfo: { userRole: ProjectRole.ADMIN },
                                ownerInfo: { ...props.project?.userInfo, ...value },
                            };
                            callback(newvalue);
                        }
                    }}
                />
            );
        }
        return (
            <div className="pm-onlyshow-avatar">
                <UserAvatar headImg={userAvatar.headImg} userName={userAvatar.userName} />
                {userAvatar.userName}
            </div>
        );
    }

    function descriptionChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setDescription(newValue);
        setDescriptionLength(newValue.length - compactValue.length + 100);
    }

    function nameChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setName(newValue);
        setNameLength(newValue.length - compactValue.length + 20);
    }

    function isEdit() {
        return (
            !props.project?.archive &&
            (currentUserRole === ProjectRole.OWNER || currentUserRole === ProjectRole.ADMIN)
        );
    }

    return (
        <Modal
            visible={visible}
            width="550px"
            bodyStyle={{ height: 333 }}
            title={t('detail.info')}
            cancelText={t('cancel')}
            okText={t('save')}
            onCancel={() => setVisible(false)}
            onOk={handleOK}
            okButtonProps={{
                disabled: name.trim().length === 0,
            }}
            footer={!isEdit() ? null : undefined}
        >
            <div className="pm-projectinfo-wrap">
                <span>{t('basic_setting.name')}</span>
                <div>
                    <Input
                        maxLength={nameLength}
                        disabled={!isEdit()}
                        value={name}
                        onChange={nameChange}
                    />
                </div>
                <span>{t('basic_setting.owner')}</span>
                <div>{renderChangeOwner()}</div>
                <span>{t('basic_setting.description')}</span>
                <div>
                    <Input.TextArea
                        allowClear
                        maxLength={descriptionLength}
                        disabled={!isEdit()}
                        rows={3}
                        placeholder={t('basic_setting.description_placeholder')}
                        value={description}
                        onChange={descriptionChange}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default function openProjectInfo(
    props: { projectId: string; title: string; description: string; t: any; project?: Project },
    callback: Dispatch<SetStateAction<Project | undefined>>,
    events: Eventemitter3
) {
    return new Promise((resolve) => {
        ReactDOM.render(
            <ProjectInfo {...props} onCancel={resolve} callback={callback} events={events} />,
            document.createDocumentFragment()
        );
    });
}
