import { useState, useEffect, useContext } from 'react';
import GlobalContext from '@/context/GlobalContext';
import { meetingPreLogin, meetingRename } from '@/api/meeting';
import { FocusSDK, isFocusEnv } from '@/utils';
import { usePrevious } from '@/utils/chat/index';
import { handleMeetingSendMessage, joinMeeting } from './utils';
import { useTranslation } from 'react-i18next';
import { findResourceIdInAuthorPermission } from '@/utils/envConfig';
import { AuthorResourceEnum } from '@/types/common';
import cache from '@/utils/cache';
import { NetType } from '../Modals/ChangeNetModal';

let retryCount = 0;

export default function ZoomLogin() {
    const [hasInit, setHasInit] = useState(false);
    const [hasLogin, setHasLogin] = useState(false);
    const { authInfo, imService, appRuntimeEnv, permissions, currentEmployee } = useContext(
        GlobalContext
    );
    const { selectedTeamId, teamUserInfo, userId } = authInfo;
    // const { ddAppId = '', teamId = '', realName = '' } = teamUserInfo;
    let ddAppId = teamUserInfo?.ddAppId || '';
    let teamId = teamUserInfo?.teamId || '';
    let realName = currentEmployee.name;
    const { zoom } = appRuntimeEnv;
    const [t_common] = useTranslation('common');
    const preRef = usePrevious(`${selectedTeamId}:${ddAppId}:${userId}`);
    // const [password, setPassWord] = useState('');

    useEffect(() => {
        async function handleLoginCb({ status }: { status: number }) {
            if (status === 3) {
                setHasLogin(false);
                console.error('zoom sdk login failure');
                cache.zoomLogin = false;
                return;
            }
            setHasLogin(true);
            cache.zoomLogin = true;
            await meetingRename({
                appId: ddAppId,
                userId,
                teamId,
                fullName: realName || '',
            });
        }
        FocusSDK.on('zoom:loginCb', handleLoginCb);
        return () => {
            FocusSDK.off('zoom:loginCb', handleLoginCb);
        };
    }, [ddAppId, permissions, realName, teamId, userId, zoom.domain]);

    useEffect(() => {
        if (!isFocusEnv()) {
            return;
        }
        if (
            !(
                findResourceIdInAuthorPermission(permissions, AuthorResourceEnum.MEETING) ||
                findResourceIdInAuthorPermission(permissions, AuthorResourceEnum.MEETING_JOIN)
            )
        ) {
            return;
        }
        function getToken() {
            if (retryCount >= 3) {
                return;
            }
            if (!userId || !selectedTeamId || !ddAppId) {
                return;
            }
            if (hasInit && preRef === `${selectedTeamId}:${ddAppId}:${userId}`) {
                return;
            }
            setHasInit(true);
            // TODO: 打开京办是否初始化zoom
            FocusSDK.sendIpcMessage('zoom.initSdk');
        }
        // 如果是纯政务外网
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            return;
        }
        getToken();
    }, [hasInit, permissions, preRef, selectedTeamId, ddAppId, teamId, userId, zoom.domain]);

    useEffect(() => {
        async function handleGetUserToken() {
            const [result, err] = await meetingPreLogin(ddAppId.replace('_', ''));
            if (err) {
                console.error(`zoom getToken failure:`, err);
                FocusSDK.sendIpcMessage('zoom:getUserTokenCb', {
                    success: false,
                });
                return;
            }
            FocusSDK.sendIpcMessage('zoom:getUserTokenCb', {
                success: true,
                password: result,
                username: userId,
            });
        }
        FocusSDK.on('zoom.getUserToken', handleGetUserToken);
        return () => {
            FocusSDK.off('zoom.getUserToken', handleGetUserToken);
        };
    }, [ddAppId, userId]);

    useEffect(() => {
        function onSendMeetingMessage(data: any) {
            handleMeetingSendMessage({
                imService,
                currentEmployee: { app: ddAppId, userId, teamId },
                data,
                t: t_common,
            });
        }
        FocusSDK.on('meeting:handleMeetingSendMessage', onSendMeetingMessage);
        return () => {
            FocusSDK.off('meeting:handleMeetingSendMessage', onSendMeetingMessage);
        };
    }, [ddAppId, imService, t_common, teamId, userId]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         FocusSDK.sendIpcMessage('voip:init');
    //     }, 5 * 1000);
    //     setTimeout(() => {
    //         FocusSDK.sendIpcMessage('voip:createSingleCall', { invitedPeople: 'we2' });
    //     }, 10 * 1000);
    // }, []);
    useEffect(() => {
        function handleJoinMeeting(data: any) {
            console.info('handleJoinMeeting', data);
            joinMeeting(
                {
                    password: data.psw,
                    meetingId: data.meetingnum,
                    realName,
                },
                data.isvideooff,
                data.isaudiooff
            );
        }
        FocusSDK.on('meeting.joinMeeting', handleJoinMeeting);
        return () => {
            FocusSDK.off('meeting.joinMeeting', handleJoinMeeting);
        };
    }, [realName]);

    return null;
}
