import React, { useEffect, useState, useCallback } from 'react';
import './index.less';
import { Spin, message, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { connect } from 'dvajs';
import EventBus from '@/utils/bus';
import icon_join from '@/assets/img/meeting/multimedia-join.png';
import icon_presentation from '@/assets/img/meeting/multimedia-presentation.png';
import icon_camera from '@/assets/img/meeting/multimedia-camera.png';
import icon_user from '@/assets/img/meeting/multimedia-user.png';

import { changeEgovUrl } from '@/utils/tools';
import { FocusSDK, isFocusEnv } from '@/utils';
import { getPermission } from '@/api/user';
import { ApplyAuthorResourceToConfig } from '@/utils/envConfig';
import config, { ConfigEnum } from '@/config/config';
import { MeetingPlatform } from '@/types/common';

interface ButtonModel {
    icon: string;
    caption: string;
    click: any;
}

function TabMultimedia({
    openCreateScheduleModal,
}: {
    openCreateScheduleModal: (data: any) => void;
}) {
    const [now, setNow] = useState<Dayjs>(dayjs());
    let platform = FocusSDK.getDeviceInfo().platform;
    const { t } = useTranslation('common');
    const [loadingConfig, setLoadingConfig] = useState(false);
    const [buttons, setButtons] = useState<ButtonModel[]>([]);

    // 获取权限
    const getUserPermissions = useCallback(async () => {
        const res: any = await getPermission();
        // 依据权限进行config配置
        const config = ApplyAuthorResourceToConfig(res || []);
        return config;
    }, []);

    const getCreateButtons = useCallback(() => {
        const buttons = [];
        const isFocus = isFocusEnv();

        if (isFocus && config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE]) {
            buttons.push({
                icon: icon_presentation,
                caption: '创建会议',
                click() {
                    EventBus.emit('xylink:create-show');
                },
            });
        }
        if (config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN]) {
            buttons.push({
                icon: icon_join,
                caption: '加入会议',
                click() {
                    EventBus.emit('xylink:join-show');
                },
            });
        }
        if (isFocus && config[ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT]) {
            buttons.push({
                icon: icon_camera,
                caption: '预约会议',
                click() {
                    openCreateScheduleModal({
                        visible: true,
                        defaultAppointment: true,
                        meetingPlatform: MeetingPlatform.XYLINK,
                    });
                },
            });
        }
        buttons.push({
            icon: icon_user,
            caption: '我的会议',
            click() {
                EventBus.emit('meeting:meeting-list-show', {
                    platform: MeetingPlatform.XYLINK,
                });
            },
        });

        setButtons(buttons);
    }, [openCreateScheduleModal]);

    useEffect(() => {
        if (platform !== 'win32') {
            return;
        }
        setLoadingConfig(true);
        getUserPermissions()
            .then(() => {
                getCreateButtons();
            })
            .finally(() => {
                setLoadingConfig(false);
            });
    }, [getCreateButtons, getUserPermissions, platform]);

    useEffect(() => {
        // 每分钟更新计时
        const time = (60 - now.second()) * 1000; // 计时补正
        const timer = setTimeout(() => {
            setNow(dayjs());
        }, time);

        return () => {
            clearTimeout(timer);
        };
    });

    return (
        <div className="xylink-tab-multimedia tab-multimedia">
            <div className="moment">
                <p className="moment-time">{now.format('HH:mm')}</p>
                <p className="moment-date">{now.format('YYYY.MM.DD')}</p>
            </div>
            {platform !== 'win32' ? (
                <Result title="暂不支持" icon={null} />
            ) : loadingConfig ? (
                <Spin
                    style={{
                        marginTop: 30,
                    }}
                    size="large"
                />
            ) : (
                <div className="function-list">
                    {buttons.map((item, index) => {
                        return (
                            <div className="function-item" key={index} onClick={item.click}>
                                <div
                                    className="function-item-icon"
                                    style={{
                                        backgroundImage: `url(${changeEgovUrl(item.icon)})`,
                                    }}
                                />
                                <p className="function-item-caption">{item.caption}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateScheduleModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMultimedia);
