import { useMemo } from 'react';
import dayjs from 'dayjs';
import { MessageNotifyType, MessageTimeCategory, MessageTypeCategory } from '@/types/chat/enum';
import { Employee, Session } from '@/types/chat';
export function getTodayZeroStamp() {
    return dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).valueOf();
}

// 时间类型分类条件表达式
export const sessionConditionMap: { [key: string]: Function } = {
    [MessageTimeCategory.ALL]: (_s: Session) => true,
    [MessageTimeCategory.TODAY]: (s: Session) => s.timestamp >= getTodayZeroStamp(),
    [MessageTimeCategory.EARLIER]: (s: Session) => s.timestamp < getTodayZeroStamp(),
    [MessageTypeCategory.GROUP]: (s: Session) => {
        return s.isGroup && (s.settings || {}).shield !== 1;
    },
    [MessageTypeCategory.SINGLE]: (s: Session) => {
        return s.isSingle && !s.isNotice && (s.settings || {}).shield !== 1;
    },
    [MessageTypeCategory.NOTICE]: (s: Session) => {
        return s.isNotice && (s.settings || {}).shield !== 1;
    },
    [MessageTypeCategory.NODISTURBING]: (s: Session) => (s.settings || {}).shield === 1,
};

// 会话消息分类条件表达式
export const messageNotifyTypeConditionMap = {
    [MessageNotifyType.ALL]: (_s: Session) => true,
    [MessageNotifyType.GROUPS]: (s: Session) => s.isGroup,
    [MessageNotifyType.UNREAD]: (s: Session) => s.unreadCount > 0,
    [MessageNotifyType.IMPORTANT]: (s: Session, currentEmployee: Employee) => {
        const messages = Object.values(s.mentions || {});
        let flag = false;
        for (let message of messages) {
            const index = message.users.findIndex((user) => {
                if (!user.userId) {
                    user['userId'] = (user as any).pin;
                }
                return user.userId === currentEmployee.userId || user.userId === 'all';
            });
            if (index > -1) {
                flag = true;
                break;
            }
        }
        return flag;
    }, // 重要消息会话 有@我 和 @所有人 的消息
};
