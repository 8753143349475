/**
 * 文本消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import './index.less';
import { ChatMessage, MessageStatus, SessionType } from '@/types/chat';
import ChatState, { UserState } from '@/types/chat/State';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { MessageSendType } from '@/types/chat/enum';

interface UnSupportProps {
    message: ChatMessage; // 发送消息内容
    // sessionType: SessionType;
    // sendType: MessageSendType;
    // onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type IProps = Readonly<UnSupportProps>;

function UnSupport(props: IProps) {
    const { message } = props;
    const { t } = useTranslation('chat');

    const classes = useMemo(() => {
        return classNames({
            message: true,
            'un-support-message': true,
        });
    }, []);

    // useEffect(() => {
    //     if (sendType === MessageSendType.OWNER && message.status === MessageStatus.INIT) {
    //         onReady({ beginSend: false, messageExtend: { unSupport: false } });
    //     }
    // }, [sendType, message, onReady]);

    // todo 不支持消息类型的说明文案
    // const messageTypeDesc = useMemo(() => {
    //     return '';
    // }, [message.type]);

    return (
        <div className="content">
            <div className={classes}>
                <p>[{t('no_support_message_type')}]</p>
            </div>
        </div>
    );
}

export default UnSupport;
