import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, message, Popover, Tabs, Modal, Button, Dropdown, message as toast } from 'antd';
import dayjs from 'dayjs';
import { getPreviewImageUrl, getThumbUrl } from '@/utils/chat/index';
import IconFont from '@/components/icon';
import { findAnnouncementDetail, ackAnnouncement } from '../../api';
import createGroupNotice from './createGroupNotice';
import { Loading } from '@jd/focus-desktop-comps';
import noDataPicture from '@/assets/img/userlist-empty.png';
import { ProjectRole } from '@/components/Projects/types';
import OpenImgPreview from '@/components/Projects/components/AnnounceTab/PreviewImg';
import { GetButtons } from './index';
import { Avatar } from '@/baseComponents/Avatar';
import bus from '@/utils/bus';
import { time } from '../../util';
import { FocusSDK, isFocusEnv } from '@/utils';
import qs from 'query-string';
import './GroupNoticeDetail.less';
import { changeEgovUrl } from '@/utils/tools';
import CopyClipboard from 'copy-to-clipboard';
import { Session } from '@/types/chat';
import ImService from '@/server/ImService';
import { isGroupVisibleUnit } from '@/utils/chat/message';
import { getPreviewImageModal } from '@/components/chat/utils/commonMessageModal';

const { TabPane } = Tabs;

interface Iprops {
    projectId: string;
    announcementId: string;
    onClose?: () => void;
    userRole: ProjectRole;
    archive: boolean;
    t: any;
    globalcontext: any;
    level: any;
    session?: Session;
}

interface IAnnounceDetail {
    announcementId: string;
    senderUserId: string;
    senderTeamId: string;
    senderUserAppId: string;
    senderUserName: string;
    senderUserAvatar: string;
    content: string;
    receiverSize: number;
    ackSize: number;
    pubTime: number;
    acked: boolean;
    showType: number;
    attachmentUrl: string[];
    userNum: number;
    users: {
        userId: string;
        teamId: string;
        app: string;
        userRole: number;
        duty: string;
        userName: string;
        headImg: string;
        acked: boolean;
        fullName: string;
    }[];
}
export function GroupNoticeDetail(props: Iprops) {
    const { projectId, announcementId = '', t, level, session } = props;
    const [visible, setVisible] = useState(true);
    const [detailData, setDetailData] = useState<IAnnounceDetail>();
    const [isvisible, setIsvisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const isShowUnit = !isGroupVisibleUnit(session as any);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const getDetail = useCallback(async function (projectId: string, announcementId: string) {
        setLoading(true);
        const imService = ImService.getInstance();
        const res = await findAnnouncementDetail(projectId, announcementId, 'Group_Announcement');
        const rosters = await imService.getGroupRosterInfo(projectId, true);
        setLoading(false);
        if (res.errCode) {
            // 如果公告删除直接关闭公告详情页面
            bus.emit('groupNoticeList:updata', {
                projectId,
                announcementId: announcementId,
            });
            setVisible(false);
            return;
        }

        // eslint-disable-next-line array-callback-return
        res.announcementDetailInfo.users = res.announcementDetailInfo.users.map((item: any) => {
            let newDetailData: any;
            rosters.map((item1: any) => {
                if (item.userId === item1.info?.userId) {
                    newDetailData = {
                        ...item,
                        unitName: item1.info?.unitName,
                        titleName: item1.info?.titleName,
                    };
                }
                return newDetailData;
            });
            return newDetailData;
        });
        setDetailData(res.announcementDetailInfo);
        if (!res.announcementDetailInfo.acked) {
            ackAnnouncement(projectId, announcementId, 'Group_Announcement');
        }
    }, []);

    useEffect(() => {
        getDetail(projectId, announcementId);
    }, [projectId, announcementId, getDetail]);

    useEffect(() => {
        function handleAnnounceUpdate(data: { projectId: string; announcementId: string }) {
            if (visible && data.projectId === projectId && data.announcementId === announcementId) {
                getDetail(projectId, announcementId);
            }
        }
        bus.on('groupNoticeDetail:updata', handleAnnounceUpdate);
        return () => {
            bus.off('groupNoticeDetail:updata', handleAnnounceUpdate);
        };
    }, [projectId, announcementId, visible, getDetail]);

    // 复制  cxz
    function doCopy(e: any) {
        e.stopPropagation();
        if (detailData?.content) {
            let userSelection;
            let str = detailData.content;
            if (typeof window.getSelection === 'function') {
                userSelection = window.getSelection();
            }
            if (userSelection?.toString()) {
                str = userSelection.toString();
            }
            if ((str || '').length > (detailData.content || '').length) {
                str = detailData.content;
            }
            if (str && CopyClipboard(str)) {
                toast.success('复制成功');
            } else {
                toast.error('复制失败');
            }
        }
    }

    function visibleChange(visible: boolean) {
        setIsvisible(visible);
    }

    const tabTitles = [
        `${t('detail.announce.no_read')} ${
            (detailData?.userNum || 0) - (detailData?.ackSize || 0)
        }`,
        `${t('detail.announce.has_read')} ${detailData?.ackSize || 0}`,
    ];
    const ackUserContent = (
        <Tabs defaultActiveKey="0" tabBarGutter={90}>
            {tabTitles.map((tabItem, index) => {
                const userData =
                    (detailData?.users || []).filter((item) => {
                        return index === 0 ? !item.acked : item.acked;
                    }) || [];
                if (userData.length === 0) {
                    return (
                        <TabPane tab={tabItem} key={index}>
                            <div className="pm-announce-nodata-img">
                                <img src={changeEgovUrl(noDataPicture)} alt="" />
                                <p>{t('detail.no_data')}</p>
                            </div>
                        </TabPane>
                    );
                }
                return (
                    <TabPane tab={tabItem} key={index}>
                        <ul className="pm-detail-user-list">
                            {userData.map((item: any) => (
                                <li
                                    key={item.app + item.userId + item.teamId}
                                    style={{ padding: '8px 16px' }}
                                >
                                    {/* {item.headImg ? (
                                        <img src={changeEgovUrl(getThumbUrl(item.headImg, 30, 30))} alt="" />
                                    ) : (
                                        <span>{item.userName[0]}</span>
                                        )} */}
                                    <span>
                                        <Avatar
                                            src={changeEgovUrl(getThumbUrl(item.headImg, 30, 30))}
                                            styleObj={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '6px',
                                            }}
                                            name={item.userName || ''}
                                        />
                                    </span>

                                    <h6>{item.userName}</h6>
                                    {isShowUnit ? (
                                        <p>
                                            {item?.unitName} - {item?.titleName}
                                        </p>
                                    ) : (
                                        <p>
                                            {item?.deptName} - {item?.titleName}
                                        </p>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </TabPane>
                );
            })}
        </Tabs>
    );
    const titles = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>公告详情</span>
            <div className="isoperation">
                {(level === 1 || level === 2) && (
                    <span
                        style={{ cursor: 'pointer', marginRight: '21px' }}
                        onClick={() => {
                            bus.emit('editGroupNotice:updata', {
                                content: detailData?.content || '',
                                announcementId: detailData?.announcementId || '',
                            });
                        }}
                    >
                        <IconFont type="iconjs_ic_edit" className="pm-announce-small-icon" />
                        {' 编辑'}
                    </span>
                )}
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        doCopy(e);
                    }}
                >
                    <IconFont type="iconapp_btn_copy" className="pm-announce-small-icon" />
                    {' 复制'}
                </span>

                {(level === 1 || level === 2) && (
                    <span
                        style={{ cursor: 'pointer', color: '#ff5e27', marginLeft: '21px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalVisible(true);
                            // Modal.confirm({
                            //     title: t('detail.announce.remove_tip'),
                            //     content: t('detail.announce.remove_confim'),
                            //     okText: t('remove'),
                            //     cancelText: t('cancel'),
                            //     onOk() {
                            //         bus.emit(
                            //             'removeGroupNotice:updata',
                            //             detailData?.announcementId || ''
                            //         );
                            //         setVisible(false);
                            //     },
                            // });
                        }}
                    >
                        <IconFont
                            style={{ color: '#ff5e27' }}
                            type="icondelect"
                            className="pm-announce-small-icon"
                        />
                        {' 删除'}
                    </span>
                )}
            </div>
        </div>
    );
    return (
        <Drawer
            width="500"
            title={titles}
            bodyStyle={{ padding: 0 }}
            footer={GetButtons(setVisible, level)}
            onClose={() => {
                bus.emit('groupNoticeList:updata', {
                    projectId,
                    announcementId: announcementId,
                });
                setVisible(false);
            }}
            visible={visible}
            destroyOnClose={true}
        >
            <div>
                {loading ? (
                    <Loading style={{ marginTop: 50 }} />
                ) : (
                    <div className="pm-announce-detail-wrap">
                        <div className="pm-announce-detail-header">
                            {detailData && (
                                <span>
                                    <Avatar
                                        src={
                                            detailData?.senderUserAvatar
                                                ? changeEgovUrl(
                                                      getThumbUrl(
                                                          detailData?.senderUserAvatar,
                                                          40,
                                                          40
                                                      )
                                                  )
                                                : ''
                                        }
                                        styleObj={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '7px',
                                        }}
                                        name={detailData?.senderUserName || ''}
                                    />
                                </span>
                            )}
                            <div className="user-info">
                                <h4 className="ellipsis">{detailData?.senderUserName}</h4>
                                {/* <div>
                                    {(level === 1 || level === 2) && (
                                        <>
                                            <span style={{ cursor: 'pointer' }}>
                                                <IconFont
                                                    type="iconapp_btn_copy"
                                                    className="pm-announce-small-icon"
                                                    onClick={(e) => {
                                                        doCopy(e);
                                                    }}
                                                />
                                            </span>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    bus.emit('editGroupNotice:updata', {
                                                        content: detailData?.content || '',
                                                        announcementId:
                                                            detailData?.announcementId || '',
                                                    });
                                                }}
                                            >
                                                <IconFont
                                                    type="iconjs_ic_edit"
                                                    className="pm-announce-small-icon"
                                                />
                                            </span>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    Modal.confirm({
                                                        title: t('detail.announce.remove_tip'),
                                                        content: t('detail.announce.remove_confim'),
                                                        okText: t('remove'),
                                                        cancelText: t('cancel'),
                                                        onOk() {
                                                            bus.emit(
                                                                'removeGroupNotice:updata',
                                                                detailData?.announcementId || ''
                                                            );
                                                            setVisible(false);
                                                        },
                                                    });
                                                }}
                                            >
                                                <IconFont
                                                    type="icondelect"
                                                    className="pm-announce-small-icon"
                                                />
                                            </span>
                                        </>
                                    )}
                                </div> */}
                            </div>

                            <p>
                                {t('detail.announce.update_at')}
                                {time.format(detailData?.pubTime)}
                                {/* {dayjs(detailData?.pubTime).format('YYYY-MM-DD HH:mm:ss')} */}
                            </p>
                        </div>
                        <div
                            className="pm-announce-detail-user"
                            onClick={() => {
                                setIsvisible(true);
                            }}
                        >
                            {/* <p>
                                {(detailData?.users || []).map((userItem) => {
                                    if (!userItem.acked) return null;
                                    return (
                                        <span
                                            key={userItem.app + userItem.teamId + userItem.userId}
                                        >
                                            {userItem.userName}
                                        </span>
                                    );
                                })}
                            </p> */}
                            <span>接收人：</span>
                            <span>
                                共<i>{detailData?.users.length}</i>人
                            </span>
                            <span>
                                （<i>{detailData?.ackSize || 0}</i>
                                人已读，
                                <i>{(detailData?.userNum || 0) - (detailData?.ackSize || 0)}</i>
                                人未读）
                            </span>
                            <Popover
                                overlayClassName="detailData_Popover"
                                content={ackUserContent}
                                placement="bottomRight"
                                trigger="click"
                                onVisibleChange={visibleChange}
                                visible={isvisible}
                            >
                                <p>
                                    {/* {t('detail.announce.no_read')} */}
                                    {/* <i>
                                        {(detailData?.userNum || 0) - (detailData?.ackSize || 0)}/
                                        {detailData?.userNum || 0}
                                    </i>
                                    {t('detail.announce.no_read')} */}
                                    <IconFont
                                        type="iconwin_right"
                                        className="pm-detail-right-icon"
                                    />
                                </p>
                            </Popover>
                        </div>
                        <div>
                            <Dropdown
                                overlay={() => {
                                    return (
                                        <div
                                            className="paste-space"
                                            onClick={(e) => {
                                                doCopy(e);
                                            }}
                                        >
                                            复制
                                        </div>
                                    );
                                }}
                                trigger={['contextMenu']}
                            >
                                <p className="pm-detail-content">{detailData?.content}</p>
                            </Dropdown>

                            <ul className="pm-detail-imgs">
                                {(detailData?.attachmentUrl || []).map((imgItem, imgIndex) => {
                                    const imgSize = qs.parseUrl(imgItem).query;
                                    const { width, height } = imgSize;
                                    return (
                                        <li
                                            key={imgItem}
                                            onClick={() => {
                                                // OpenImgPreview({
                                                //     imgSrc: imgItem,
                                                //     imgArr: detailData?.attachmentUrl || [],
                                                // });
                                                const arr = (detailData?.attachmentUrl || []).map(
                                                    (item, index) => {
                                                        return {
                                                            kind: 'image',
                                                            url: isFocusEnv()
                                                                ? encodeURIComponent(item)
                                                                : encodeURI(item),
                                                            muuid: index,
                                                            id: index,
                                                            fileName: '',
                                                        };
                                                    }
                                                );
                                                getPreviewImageModal({
                                                    message: {
                                                        muuid: imgIndex,
                                                        url: isFocusEnv()
                                                            ? encodeURIComponent(imgItem)
                                                            : encodeURI(imgItem),
                                                        fileName: '',
                                                    },
                                                    isMergeForward: true,
                                                    modalMessages: arr,
                                                });
                                            }}
                                        >
                                            <img
                                                src={changeEgovUrl(
                                                    getThumbUrl(
                                                        imgItem,
                                                        Number(width),
                                                        Number(height)
                                                    )
                                                )}
                                                alt=""
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}

                <Modal
                    title="提醒"
                    className="removeModal"
                    width={400}
                    visible={isModalVisible}
                    onOk={(e) => {
                        e.stopPropagation();
                        bus.emit('removeGroupNotice:updata', detailData?.announcementId || '');
                        setIsModalVisible(false);
                        setVisible(false);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                        setIsModalVisible(false);
                    }}
                    okText="确定"
                    cancelText={t('cancel')}
                    maskClosable={true}
                    centered
                >
                    <p>确认删除当前公告吗？</p>
                </Modal>
            </div>
        </Drawer>
    );
}

export default function OpenAnnounceDetail(props: Iprops) {
    const el = document.createElement('div');
    const onclose = () => {
        if (el) {
            ReactDOM.unmountComponentAtNode(el);
            el.remove();
        }
    };
    ReactDOM.render(<GroupNoticeDetail {...props} onClose={onclose} />, el);
}
