import React from 'react';
import { default as Icon } from '../icon/index';
export default Icon;

export const Icons = {
    dropdownMenu: <Icon type="iconjs_ilist_arrowdown" />,
    dropupMenu: <Icon type="iconjs_list_arrowup" />,
    dropfinishMenu: <Icon type="iconapp_ic_finish" />,
    trigger: <Icon type="iconjs_list_more" />,
    timeSortMenu: <Icon type="iconic_app_paixu" />,
};
