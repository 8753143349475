/**
 * 群组相关操作
 */
import GroupRoster, { GroupRosterIdentity } from '@jd/jdee.im.sdk/lib/es/model/Roster';
import { Employee, Group, GroupApprovalRule, Session } from '@/types/chat';
import {
    employeeToUid,
    isEqualEmployee,
    isEqualUid,
    isPermissionCheckSession,
    isSpecialProjectMasterSession,
    isSpecialProjectSession,
} from '@/components/chat/utils/message';
import config, { ChatConfig } from '@/config/config';
import lodashAttempt from 'lodash/attempt';
import isError from 'lodash/isError';

export function getGroupRoster(
    administrator: Partial<GroupRoster>[],
    sub_administrator: Partial<GroupRoster>[],
    ordinary: Partial<GroupRoster>[]
): Partial<GroupRoster>[] {
    return [...(administrator || []), ...(sub_administrator || []), ...(ordinary || [])];
}

export function canInviteMember(
    group: Group,
    groupRosterIdentity: GroupRosterIdentity | ''
): boolean {
    if (group.approvalRule === GroupApprovalRule.PRE_APPROVAL) {
        return true;
    } else if (group.approvalRule === GroupApprovalRule.IDENTITY) {
        return !!groupRosterIdentity && !isGroupOrdinary(groupRosterIdentity);
    } else {
        return false;
    }
}

// 群会话，加人入群权限控制，是否可以操作加人入群
export function checkCanAddMember({
    selectedSession,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    if (selectedSession.isGroup && groupRosterIdentity) {
        const group = selectedSession.info as Group;
        return canInviteMember(group, groupRosterIdentity);
    }
    return false;
}

// 聊天窗口 添加按扭权限控制， 是否可创建群，或者 加人入群
export function checkCanAddOperate({
    selectedSession,
    currentEmployee,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    currentEmployee: Employee;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    const showGroupAddBtn = config[ChatConfig.CHAT_SESSION_CREATE_GROUP_ENABLE];
    // 配置控制
    if (!showGroupAddBtn) {
        return false;
    }

    // 通知会话没有
    if (selectedSession.isNotice) {
        return false;
    }
    // 自已跟自已聊天不能创建群聊
    if (selectedSession.isSingle) {
        const info = selectedSession.info as Employee;
        if (isEqualEmployee(info, currentEmployee)) {
            return false;
        }
    }

    if (selectedSession.isGroup) {
        // 归档的群
        if (isArchiveGroup(selectedSession)) {
            return false;
        }

        // 检查是否可以邀请
        const can = canInviteMember(selectedSession.info as Group, groupRosterIdentity);
        if (!can) {
            return false;
        }
    }

    return true;
}

// 检查是否可修改群名称的权限
export function checkCanModifyGroup({
    selectedSession,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    if (!selectedSession.isGroup) {
        return false;
    }

    // 归档的群，不可以修改
    if (isArchiveGroup(selectedSession)) {
        return false;
    }

    // 如果是专班大群 只有管理员可以修改
    if (isSpecialProjectMasterSession(selectedSession)) {
        return isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity);
    }

    // 是管理员或者是群主
    if (isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity)) {
        return true;
    }

    // 如果开启权限检查
    return isPermissionCheckSession(selectedSession);
}

// 检查是否有操作群成员的权限
export function checkShowDropdownOperate({
    selectedSession,
    groupRosterIdentity,
    member,
    currentEmployee,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
    member: any;
    currentEmployee: Employee;
}) {
    // 是归档的群不可以显示操作项
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    // 当前用户是群成员不显示操作项
    if (isGroupOrdinary(groupRosterIdentity)) {
        return false;
    }
    // 操作对象是自已不显示操作项
    if (isEqualUid(employeeToUid(member.info), employeeToUid(currentEmployee))) {
        return false;
    }
    return true;
}
// 检查可否转让群主
export function checkCanTransferGroupOwner({
    selectedSession,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    // 归档群不可以转让群主
    if (isArchiveGroup(selectedSession)) {
        return false;
    }

    return isGroupOwner(groupRosterIdentity);
}

// 检查可否解散群
export function checkCanDissolutionGroup({
    selectedSession,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    // 归档群不可以解散
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    return isGroupOwner(groupRosterIdentity);
}

// 检查可否添加移除管理员
export function checkCanSetAdmin({
    selectedSession,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    // 归档群不可以解散
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    return isGroupOwner(groupRosterIdentity);
}

export function checkCanExitGroup({
    selectedSession,
    groupRosterIdentity,
}: {
    selectedSession: Session;
    groupRosterIdentity: GroupRosterIdentity | '';
}) {
    // 归档群 并且是群主 不可以退出
    if (isArchiveGroup(selectedSession) && isGroupOwner(groupRosterIdentity)) {
        return false;
    }
    return true;
}

export function getGroupRosterIdentity(
    administrator: Partial<GroupRoster>[],
    sub_administrator: Partial<GroupRoster>[],
    currentEmployee: Employee
): GroupRosterIdentity | '' {
    const owner = administrator[0] as GroupRoster;
    if (!owner.user) {
        return '';
    }
    // console.log('getGroupRosterIdentity 11==>', owner);
    // console.log('getGroupRosterIdentity 22==>', currentEmployee);
    if (owner && isEqualUid(owner.user, employeeToUid(currentEmployee))) {
        return 'administrator';
    } else {
        const index = sub_administrator.findIndex((item) => {
            return isEqualEmployee((item as GroupRoster).info, currentEmployee);
        });
        if (index > -1) {
            return 'sub_administrator';
        } else {
            return 'ordinary';
        }
    }
}

// 是群主
export function isGroupOwner(identity: GroupRosterIdentity | '') {
    return identity === 'administrator';
}

// 是群管理员
export function isGroupAdmin(identity: GroupRosterIdentity | '') {
    return identity === 'sub_administrator';
}

// 是群成员
export function isGroupOrdinary(identity: GroupRosterIdentity | '') {
    return identity === 'ordinary';
}

export function isOrdinaryRoster(roster: GroupRoster) {
    return isGroupOrdinary(roster.identity);
}

export function isArchiveGroup(session: Session): boolean {
    if (session.isGroup) {
        const { ext = '' } = session.info as Group;
        const extInfo = lodashAttempt(JSON.parse, ext);
        // console.log('isArchiveGroup ===>', extInfo);
        if (!extInfo || isError(extInfo)) {
            return false;
        }
        return extInfo.archive;
    }
    return false;
}

export function checkApprovalRuleDisabled({ selectedSession }: { selectedSession: Session }) {
    if (isArchiveGroup(selectedSession)) {
        return true;
    }
    return isSpecialProjectSession(selectedSession);
}

export function checkCanSearchDisabled({ selectedSession }: { selectedSession: Session }) {
    if (isArchiveGroup(selectedSession)) {
        return true;
    }
    return false;
}

export function checkNeedVerifyDisabled({ selectedSession }: { selectedSession: Session }) {
    if (isArchiveGroup(selectedSession)) {
        return true;
    }
    return false;
}

export function checkScheduleVisible({ selectedSession }: { selectedSession: Session }) {
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    return true;
}

export function checkTaskVisible({ selectedSession }: { selectedSession: Session }) {
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    return true;
}

export function checkRemindVisible({ selectedSession }: { selectedSession: Session }) {
    if (isArchiveGroup(selectedSession)) {
        return false;
    }
    return true;
}
