import { request } from '@/components/Joyspace/api/request';
export const restore = (id: string) =>
    request({
        api: 'joyspace.trash.restore',
        path: `v1/trashes/${id}/restore`,
        method: 'PUT',
    });

export const remove = (id: string) =>
    request({
        api: 'joyspace.trash.remove',
        path: `v1/trashes/${id}`,
        method: 'DELETE',
    });
export const empty = () =>
    request({
        api: 'joyspace.trash.empty',
        path: `v1/v1/trashes/empty`,
        method: 'DELETE',
    });
