import { useEffect, useContext, useState, useRef } from 'react';
import { FocusSDK, isFocusEnv } from '@/utils';
import GlobalContext from '@/context/GlobalContext';
import { usePrevious } from '@/utils/chat/index';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import bus from '@/utils/bus';
import { findResourceIdInAuthorPermission } from '@/utils/envConfig';
import { AuthorResourceEnum } from '@/types/common';
import cache from '@/utils/cache';
import { sendConferenceCardMsg } from '@/api/meeting';
import CHAT_NOTICE from '@/contant/chat';

export default function VoipLogin() {
    const {
        authInfo = {} as any,
        currentEmployee,
        appRuntimeEnv,
        focusSDK,
        permissions,
    } = useContext(GlobalContext);
    const { accessToken, userId, teamUserInfo } = authInfo;
    // const { ddAppId = '', teamId = '' } = teamUserInfo;
    let ddAppId = teamUserInfo?.ddAppId || '';
    let teamId = teamUserInfo?.teamId || '';
    const [regist, setRegist] = useState(false);
    const tokenRef = useRef('');
    const { voip } = appRuntimeEnv;
    const { t } = useTranslation('meeting');
    const aidRef = usePrevious(cache.aid);

    useEffect(() => {
        if (!isFocusEnv()) {
            return;
        }
        // if (cache.aid === aidRef) {
        //     return;
        // }
        if (!cache.aid) {
            return;
        }
        if (!findResourceIdInAuthorPermission(permissions, AuthorResourceEnum.VOIP)) {
            return;
        }
        function handleRegistCb() {
            setRegist(true);
        }
        function handleRegistFailureCb({
            error_code,
            error_desc,
        }: {
            error_code: number;
            error_desc: string;
        }) {
            // 如果鉴权失败，则不重试
            if (error_code === 401) {
                return;
            }
            // 如果失败了，30秒后再次尝试
            setTimeout(() => {
                initVoip();
            }, 30 * 1000);
        }

        function initVoip() {
            console.log('voip:init~~~~~', {
                domain: voip.domain,
                userId,
                token: tokenRef.current,
                appId: ddAppId,
                teamId,
                serverPort: voip.port,
            });
            FocusSDK.sendIpcMessage('voip:init', {
                domain: voip.domain,
                userId,
                token: tokenRef.current,
                appId: ddAppId,
                teamId,
                serverPort: voip.port,
            });
        }
        FocusSDK.off('voip:OnRegisterSuccess', handleRegistCb).on(
            'voip:OnRegisterSuccess',
            handleRegistCb
        );
        FocusSDK.off('voip:OnRegisterSuccess', handleRegistCb).on(
            'voip:OnRegisterFailureWithInfo',
            handleRegistFailureCb
        );
        function handleImAuthed() {
            tokenRef.current = cache.aid || '';
            initVoip();
        }
        handleImAuthed();
        return () => {
            FocusSDK.off('voip:OnRegisterSuccess', handleRegistCb);
            FocusSDK.off('voip:OnRegisterFailureWithInfo', handleRegistFailureCb);
        };
    }, [aidRef, ddAppId, permissions, teamId, userId, voip.domain, voip.port]);

    // 此处监听一些通知的回调，例如申请会议失败、加入会议失败等
    useEffect(() => {
        function OnApplyConferenceIdFailure() {
            message.error(t('meeting initialization failure'));
        }
        function handleAlreadyInMeeting() {
            message.warn(t('There is a meeting going on'));
        }

        // 对端挂机
        function handleMeetingEnd() {
            message.warn(t('hangup cause peer hangup'));
        }
        function handleMeetingToast(data: { str: string }) {
            const { str } = data;
            switch (str) {
                case 'waitTimeout':
                    message.warn(t('Waiting for a call timeout'));
                    break;
                case 'userReject':
                    message.warn(t('other user reject'));
                    break;
                default:
                    break;
            }
        }
        function handleForceQuit() {
            FocusSDK.sendIpcMessage('voip:force_quit');
        }

        function OnApplyConferenceIdSuccess({ info, conference_id }: any) {
            console.info('OnApplyConferenceIdSuccess', info);
            const sid = info.sid.split(':');
            sendConferenceCardMsg({
                noticeId: CHAT_NOTICE.VOICE_PIN,
                title: info.topic,
                receivers: [
                    {
                        pin: sid[3],
                        app: sid[4],
                        teamId: sid[5],
                    },
                ],
                meetingInfoReq: {
                    meetingNumber: conference_id.conference_number_,
                    initiator: currentEmployee.name,
                },
                infox: {
                    password: '',
                    action: 'joinmeeting',
                    type: 'voice',
                },
            });
        }
        FocusSDK.on('voip:OnApplyConferenceIdFailure', OnApplyConferenceIdFailure);
        FocusSDK.on('voip:OnApplyConferenceIdSuccess', OnApplyConferenceIdSuccess);
        FocusSDK.on('voip:toast:alreadyInMeeting', handleAlreadyInMeeting);
        FocusSDK.on('voip:toast:meetingEnd', handleMeetingEnd);
        FocusSDK.on('voip:toast', handleMeetingToast);
        bus.on('chat:force_quit', handleForceQuit);
        focusSDK.on('logout', handleForceQuit);
        return () => {
            FocusSDK.off('voip:OnApplyConferenceIdFailure', OnApplyConferenceIdFailure);
            FocusSDK.off('voip:OnApplyConferenceIdSuccess', OnApplyConferenceIdSuccess);
            FocusSDK.off('voip:toast:alreadyInMeeting', handleAlreadyInMeeting);
            FocusSDK.off('voip:toast:meetingEnd', handleMeetingEnd);
            FocusSDK.off('voip:toast', handleMeetingToast);
            bus.off('chat:force_quit', handleForceQuit);
            focusSDK.off('logout', handleForceQuit);
        };
    }, [currentEmployee.name, focusSDK, t]);

    useEffect(() => {
        function handleSendLocalVoipMessage({ type, data }: { type: string; data: any }) {}
        FocusSDK.on('voip:sendLocalVoipMessage', handleSendLocalVoipMessage);
        return () => {
            FocusSDK.off('voip:sendLocalVoipMessage', handleSendLocalVoipMessage);
        };
    }, []);
    return null;
}
