/* eslint-disable complexity */
/**
 * 聊天消息输入组件
 * @author tj
 * @date 2020/6/16
 */
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { connect } from 'dva';
import {
    Tooltip,
    message as AntMessage,
    message as toast,
    Menu,
    Button,
    Space,
    Dropdown,
} from 'antd';
import IconFont from '@/components/icon';
import { useTranslation, WithTranslation } from 'react-i18next';

import JEditor from '@/baseComponents/JEditor';
import MentionUserPlugin from '@/baseComponents/JEditor/Plugins/MentionUser';
import ChatState, { QuickReply, SessionStatusInfo } from '@/types/chat/State';
import {
    ChatMessage,
    ChatMessageType,
    Employee,
    MessageStatus,
    Session,
    SessionType,
} from '@/types/chat';
import { isFocusEnv, detectOS, useDebounce, FocusSDK } from '@/utils';
import PinyinMatch from 'pinyin-match';
import EmojiPopover from './Emoji';
import {
    buildJoyspaceLinkMessageBody,
    buildTextMessageBody,
    convertEmployeeToString,
    filterDeptFullName,
    initChatMessageInfo,
    isBannedPostGroupSession,
    isGroupVisibleUnit,
    JouspaceLinkTypeOption,
} from '@/utils/chat/message';
import { isGroupOrdinary } from '@/utils/chat/group';
import MoreDropdown from './MoreDropdown';
import ChatEvent from '@/baseComponents/Chat/view/ChatEvent';
import config, { ChatConfig } from '@/config/config';
import { usePrevious } from '@/utils/chat/index';
import Quill, { Sources } from 'quill';
import BannedInput from './BannedInput';
import Context from '@/context/ChatContext';
import bus from '@/utils/bus';
import { DownOutlined } from '@ant-design/icons';
import { lookup } from 'mime-types';
import CopyClipboard from 'copy-to-clipboard';
import { getToMergeMessage } from '@/server/im/MessageService';
import './index.less';
import { getOrgList } from '@/components/chat/utils/group';
import {
    convertContentToArray,
    extraConvertEmployeeToString,
    HttpCardLinkTypeOption,
    nonExistentPageToText,
    sendMessageBody,
    getMessageTypeByText,
} from '@/components/chat/utils/message';
import chatConfig from '@/components/chat/config';
import { UpLoadImg } from '@/components/chat/message/Input/UpLoadImg';
import QuickReplyComp from '@/components/chat/message/Input/QuickReply';
import {
    dataURLtoFile,
    dealImagesData,
    filterDirectory,
    filterImages,
    heicToPng,
    isHeicImage,
} from '@/components/chat/utils/image';

const Delta = Quill.import('delta');

const SendMessageIsBanned = 'banned_post_tip';

interface MessageInputProps {
    Upload?: string;
    singleLine?: boolean;
}

type IMessageInputProps = Readonly<MessageInputProps & WithTranslation>;

interface MessageInputState {
    type?: string;
}

function mapStateToProps({ user, chat }: { user: any; chat: ChatState }) {
    return {
        ...user,
        selectedSession: chat.selectedSession,
        singleSessionMembers: chat.sessionMembers,
        sessionStatusMap: chat.sessionStatusMap,
        currentEmployee: chat.currentEmployee,
        sessionMessageMap: chat.sessionMessageMap,
        quickReplys: chat.quickReplys,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeViewTab(viewTab: string) {
            dispatch({ type: 'calendar/changeViewTab', payload: { viewTab } });
        },
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
        setSessionStatus(data: { sessionId: string; sessionStatusInfo: SessionStatusInfo }) {
            dispatch({ type: 'chat/setSessionStatus', payload: data });
        },
        setMessageInputFocus(data: boolean) {
            dispatch({ type: 'chat/setMessageInputFocus', payload: data });
        },
    };
}

export const InitImgState: {
    base64: any;
    title: string;
    width: number;
    height: number;
    size: number;
} = {
    title: '',
    base64: '',
    width: 0,
    height: 0,
    size: 0,
};

interface IState {
    imgState: typeof InitImgState;
    imgUploadModalVisible: boolean;
    file: any;
    fileList: any[];

    replyVisible: boolean;
    replyText: string;
    replyUser: Partial<Employee> | null;
    replyMessage: Partial<ChatMessage>;

    quickReplyVisible: boolean; // 常用语
    emojiVisible: boolean;
}

const initialState: IState = {
    imgState: InitImgState,
    imgUploadModalVisible: false,
    file: null,
    fileList: [],

    replyVisible: false,
    replyText: '',
    replyUser: null,
    replyMessage: {},

    quickReplyVisible: false,
    emojiVisible: false,
};

function reducer(state: IState, action: any) {
    const { payload } = action;
    switch (action.type) {
        case 'openUploadImgModal':
            // eslint-disable-next-line no-case-declarations
            const { imgState, file, fileList } = payload;
            return { ...state, imgUploadModalVisible: true, imgState, file, fileList };
        case 'closeUploadImgModal':
            return { ...state, imgUploadModalVisible: false };
        case 'clearImageState':
            return { ...state, imgState: {}, file: null, fileList: [] };
        case 'closeReply':
            return {
                ...state,
                replyText: '',
                replyVisible: false,
                replyUser: null,
                replyMessage: {},
            };
        case 'showReply':
            return {
                ...state,
                replyVisible: true,
                replyText: payload.replyText,
                replyUser: payload.replyUser,
                replyMessage: payload.replyMessage,
            };
        case 'changeQuickReplyVisible':
            if (payload.quickReplyVisible) {
                return {
                    ...state,
                    quickReplyVisible: true,
                    emojiVisible: false,
                };
            }
            return {
                ...state,
                quickReplyVisible: false,
            };
        case 'changeEmojiVisible':
            if (payload.visible) {
                return {
                    ...state,
                    quickReplyVisible: false,
                    emojiVisible: true,
                };
            }
            return {
                ...state,
                emojiVisible: false,
            };
        case 'handleAtClick':
            return {
                ...state,
                quickReplyVisible: false,
                emojiVisible: false,
            };
        case 'handleImgClick':
            return {
                ...state,
                quickReplyVisible: false,
                emojiVisible: false,
            };

        default:
            throw new Error();
    }
}

/**
 * 判断最后一个
 * @param {*} delta
 */
function isEndwithEnter(delta: any) {
    if (delta.ops.length < 1) {
        return false;
    }
    let length = delta.ops.length;
    const op = delta.ops[length - 1];
    if (typeof op.insert === 'string' && op.insert.endsWith('\n')) {
        return true;
    }
    return false;
}

function MessageInput({
    currentEmployee,
    selectedSession,
    singleSessionMembers,
    uploadFile,
    uploadImg,
    uploadMultiImg,
    pushChatMessage,
    sendJoyspaceMessage,
    setSessionStatus,
    setMessageInputFocus,
    sessionStatusMap,
    sessionMessageMap,
    singleLine,
    quickReplys,
}: {
    currentEmployee: Employee;
    selectedSession: Session;
    singleSessionMembers: Partial<Employee>[];
    sessionStatusMap: any;
    sessionMessageMap: any;
    uploadFile: (file: any, multiple?: any, path?: any) => void;
    uploadImg: (imgState: typeof InitImgState, file: any) => void;
    uploadMultiImg: (uploadList: any) => void;
    pushChatMessage: (data: { sessionId: string; message: Partial<ChatMessage> }) => void;
    sendJoyspaceMessage: (data: any[]) => void;
    setSessionStatus: (data: { sessionId: string; sessionStatusInfo: SessionStatusInfo }) => void;
    setMessageInputFocus: (data: boolean) => void;
    quickReplys: QuickReply[];
} & IMessageInputProps) {
    let weizhi: any = document.getElementById('input-control-container'); // 输入框
    let qlContainer: any = document.querySelector('.ql-container');

    let domMessageScroll: any = document.getElementById('message-box-container-basScroll');
    let isIpt: any = document.getElementById('input-box-multiline');
    let onMousedown: any = document.getElementById('input-line'); // 透明线
    let body: any = document.querySelector('body');
    let qlEditor = document.querySelector('.ql-editor') as HTMLElement;

    const { groupRosters, groupRosterIdentity, closeSetting } = React.useContext(Context);
    const sessionMembers: Partial<Employee>[] = useMemo(
        function () {
            if (
                [SessionType.SINGLE, SessionType.SECRET_SINGLE].includes(
                    selectedSession.sessionType
                )
            ) {
                const tempSingleSessionMembers = singleSessionMembers.map((item: any) => {
                    if (item.userId === 'all') {
                        item.role = 'all';
                    } else {
                        item.role = 'user';
                    }
                    return item;
                });
                // console.log(tempSingleSessionMembers);
                return (tempSingleSessionMembers || []) as Partial<Employee>[];
            } else if (selectedSession.sessionType === SessionType.GROUP) {
                const rosters: any[] = groupRosters
                    .map((item) => {
                        const user = { ...item?.info, role: 'user' } as any;
                        return user;
                    })
                    .filter((item) => item?.userId && item?.app && item?.teamId && item?.name);
                const all = {
                    app: currentEmployee.app || '',
                    name: '全体成员',
                    userId: 'all',
                    teamId: currentEmployee.teamId,
                    role: 'all',
                };
                const result = getOrgList(groupRosters, selectedSession);
                // rosters.unshift(all);
                return result.length > 1 ? [all, ...result, ...rosters] : [all, ...rosters];
                // return rosters;
            }
            return [];
        },
        [selectedSession, groupRosters, singleSessionMembers, currentEmployee]
    );

    const divEl = useRef(null);
    const replyRef = useRef(null);
    const inputRef = useRef<JEditor | null>(null);
    const [inputWidth, setInputWidth] = useState(100);
    const [expand, setExpand] = useState(false);
    const [visible, setVisible] = useState(false);
    const preSelectedId = usePrevious(selectedSession.id);
    const [state, dispatch] = useReducer(reducer, initialState);
    const draftRef = useRef<any>(null);
    const [t] = useTranslation('chat');

    // cxz修改
    const [isenter, setIsenter] = useState('');
    const [isctrl, setIsctrl] = useState('');
    const [keyNum, setKeyNum] = useState('');
    const [copyText, setcopyText] = useState(false);

    // lyj 是否发送多张图片
    const [isMulti, setMulti] = useState(false);

    const handleResize = useCallback(() => {
        if (
            ![SessionType.SINGLE, SessionType.SECRET_SINGLE, SessionType.GROUP].includes(
                selectedSession.sessionType
            )
        ) {
            return;
        }
        const cu: any = divEl.current;
        if (cu) {
            const width = cu.clientWidth;

            let rightWidth = 14;

            const right = cu.querySelector('.input-control-container-right');
            if (right) {
                rightWidth += right.clientWidth;
            }
            const inputWidth = width - rightWidth;
            setInputWidth(inputWidth);
            const div = cu.querySelector('.ql-editor');
            if (!div) {
                return;
            }
            const editWidth = singleLine ? div.scrollWidth - rightWidth : div.scrollWidth;
            if (editWidth > inputWidth) {
                setExpand(true);
            } else {
                setExpand(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession]);

    // 在输入时保存一下草稿信息
    function saveDraft(session: any) {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const { text, userIds, delta } = jeditor.getTextMessage();
        if (text === '') {
            setcopyText(false);
        } else {
            setcopyText(true);
        }
        const uids: any[] = [];
        userIds.forEach((u: string) => {
            const temp: any = sessionMembers.find((i: any) => {
                if (i.role === 'unit' || i.role === 'dept') {
                    return extraConvertEmployeeToString(i as Employee, selectedSession) === u;
                }
                return convertEmployeeToString(i as Employee) === u;
            });
            // console.log('遍历结果', temp);
            if (temp) {
                let uid: any;
                if (temp.role === 'unit') {
                    uid = {
                        nickname: temp.name,
                        unitId: temp.unitDeptId,
                    };
                } else if (temp.role === 'dept') {
                    uid = {
                        nickname: temp.name,
                        deptId: temp.deptId,
                    };
                } else {
                    uid = {
                        app: temp.app || '',
                        teamId: temp.teamId || '',
                        nickname: temp.name,
                        pin: temp.userId || '',
                    };
                }
                uids.push(uid);
            }
        });
        // userIds.forEach((u: string) => {
        //     const temp = sessionMembers.find((i) => i.userId === u);
        //     if (temp) {
        //         const uid: UID = {
        //             app: temp.app || '',
        //             teamId: temp.teamId || '',
        //             nickname: temp.name,
        //             pin: temp.userId || '',
        //         };
        //         uids.push(uid);
        //     }
        // });
        draftRef.current = {
            sessionId: session.id || '',
            sessionStatusInfo: {
                status: MessageStatus.EDITING,
                // timestamp: Date.now(),
                timestamp: new Date().getTime(),
                info: {
                    atUsers: uids,
                    content: text,
                    delta,
                },
            },
        };
    }

    const onMainWinHidden = useCallback(() => {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const { text } = jeditor.getTextMessage();
        if (text?.trim()) {
            saveDraft(selectedSession);
        }
        if (selectedSession?.sessionId && draftRef.current) {
            setSessionStatus(draftRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession]);

    /**
     * 切换聊天窗口时清空输入框
     */
    useEffect(() => {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        if (preSelectedId !== selectedSession.id) {
            if (draftRef.current) {
                setSessionStatus(draftRef.current);
                draftRef.current = null;
            }
            jeditor.clearMessage();
            // 从草稿中提取信息
            const { info } = sessionStatusMap[selectedSession.id] || {};
            if (info) {
                // jeditor.insertText(info.content);
                const quill = jeditor.getQuill();
                if (quill) {
                    quill.setContents(info.delta);
                    setTimeout(() => {
                        const length = quill.getLength();
                        quill.setSelection(length);
                    });
                }
            }
            dispatch({ type: 'closeReply' });
            if (singleLine) {
                setExpand(false);
            }
            jeditor.focus();
            if (detectOS() === 'Win') {
                setMessageInputFocus(true);
            }
        }
        // jeditor.changePlaceholder(t('send_to_sb').replace('%s', selectedSession.info.name || ''));
        jeditor.changePlaceholder('请输入消息');
    }, [
        selectedSession.id,
        selectedSession.info.name,
        setSessionStatus,
        setMessageInputFocus,
        t,
        preSelectedId,
        sessionStatusMap,
        singleLine,
    ]);

    /**
     * 消息输入框是否焦点
     */
    const onEditorFocus = useCallback(
        (status: boolean) => {
            if (detectOS() === 'Win') {
                setMessageInputFocus(status);
            }
        },
        [setMessageInputFocus]
    );

    useEffect(() => {
        const jeditor: any = inputRef.current;
        if (!jeditor || !jeditor.events) {
            return;
        }
        jeditor.events.on('editor-on-focus', onEditorFocus);
        return () => {
            onMainWinHidden();
            jeditor.events.off('editor-on-focus', onEditorFocus);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [inputDisable, setInputDisable] = useState(false);

    const isBanned = useMemo(() => {
        return isGroupOrdinary(groupRosterIdentity) && isBannedPostGroupSession(selectedSession);
    }, [groupRosterIdentity, selectedSession]);

    // 群禁言时处理一下交互
    useEffect(() => {
        if (isBanned) {
            const jeditor: any = inputRef.current;
            if (!jeditor) {
                // eslint-disable-next-line no-console
                console.log('can not find jeditor');
                return;
            }
            const { text } = jeditor.getTextMessage();
            if (text) {
                return;
            }
            setInputDisable(true);
            setTimeout(() => {
                jeditor.changePlaceholder(t('Group manager has been banned'));
            });
        } else {
            setInputDisable(false);
        }
    }, [isBanned, t]);

    /**
     * 设置回复的数据
     * @param props
     */

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleReply(props: any) {
        let qlContainer: any = document.querySelector('.ql-container');
        let weizhi: any = document.getElementById('input-control-container'); // 输入框
        const { replyText, replyUser, replyMessage } = props;
        dispatch({ type: 'showReply', payload: { replyText, replyUser, replyMessage } });
        if (singleLine) {
            setExpand(true);
        }
        const jeditor: any = inputRef.current;
        const minHeight = singleLine ? 90 : 160;
        const qlContainerHeight = singleLine ? 45 : 36;
        if (weizhi.style.height <= minHeight + 'px') {
            qlContainer.style.maxHeight = qlContainerHeight + 'px';
            weizhi.style.height = minHeight + qlContainerHeight + 'px';
        }
        if (weizhi.style.height >= 266 + 'px') {
            qlContainer.style.maxHeight = 138 + 'px';
        }
        if (!jeditor) {
            return;
        }
        jeditor.focus();
    }

    function SetMessageFromReEdit(props: any) {
        const { message } = props;
        let { atUsers, content } = message;
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        let { delta } = jeditor.getTextMessage();
        if (isEndwithEnter(delta)) {
            const length = delta.length();
            delta = delta.compose(new Delta().retain(length - 1).delete(1));
        }
        let result = content;
        if (atUsers && atUsers?.length > 0) {
            let tempIndex = 0;
            for (const u of atUsers) {
                const atStr = `@${u.nickname}`;
                tempIndex = result.indexOf(atStr);
                if (tempIndex >= 0) {
                    delta = delta.insert(result.substring(0, tempIndex)).insert({
                        'mention-link': {
                            id: `${u?.pin || u?.unitId || u?.deptId || ''}:${
                                u?.app || currentEmployee.app || ''
                            }:${u?.teamId || currentEmployee.teamId || ''}`,
                            // id: `${u?.pin}:${u?.app}:${u?.teamId}`,
                            type: 'user',
                            name: u.nickname,
                        },
                    });
                    result = result.substr(tempIndex + atStr.length);
                }
            }
            delta = delta.insert(result);
        } else {
            delta = delta.insert(content);
        }
        const quill = jeditor.getQuill();
        if (quill) {
            quill.setContents(delta);
            setTimeout(() => {
                const length = quill.getLength();
                quill.setSelection(length);
            });
        }
    }
    const onVisibleChange = (flag: boolean) => {
        setVisible(flag);
    };
    function doPaste(e: any) {
        e.stopPropagation();
        let Path: any = null;
        let dataFile: any = null;
        document.addEventListener('paste', onPaste);
        async function onPaste(e: any) {
            e.preventDefault();
            e.stopPropagation();
            // 禁言的会话
            if (isBannedPostGroupSession(selectedSession)) {
                return false;
            }
            const usefilePath = await FocusSDK.getClipboardPaths();
            // 使用 new File 生成可用于Web端的 File 对象
            const fileList = usefilePath.map(({ fileName, filePath, data }) => {
                Path = filePath;
                dataFile = data;
                // 适配咚咚复制图片
                if (!data && usefilePath !== []) {
                    return dataURLtoFile(usefilePath.toString(), 'image.png');
                }
                return new window.File([data], fileName, {
                    type: lookup(fileName) as any,
                    lastModified: Date.now(),
                });
            });
            if (
                fileList &&
                fileList.length > 0 &&
                dataFile &&
                !fileList[0].type.match(
                    /^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i
                )
            ) {
                // console.log('获取文件类型', fileList[0].type);
                uploadFile(fileList, 'single', Path);
            } else {
                // console.log('获取文件类型', fileList[0].type);
                handleUploadImgs(fileList);
            }
            // document.addEventListener('paste', function (evt: any) {
            //     let body: any = document.querySelector('body');
            //     evt.stopPropagation();
            //     evt.preventDefault();
            //     document.onpaste = null;
            //     body.style.cursor = 'auto';
            //     document.removeEventListener('paste', onPaste);
            // });
            document.removeEventListener('paste', onPaste);
        }
        focus();
        document.execCommand('paste');
        setVisible(false);
    }
    function doCopy(e: any) {
        e.stopPropagation();
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        let { text } = jeditor.getTextMessage();
        let userSelection;
        let str = text;
        if (typeof window.getSelection === 'function') {
            userSelection = window.getSelection();
        }
        if (userSelection?.toString()) {
            str = userSelection.toString();
        }
        if ((str || '').length > (text || '').length) {
            str = text;
        }
        if (str && CopyClipboard(str)) {
            toast.success(t('copy-succeeded'));
        } else {
            toast.error(t('copy-failed'));
        }
        setVisible(false);
        jeditor.focus();
    }
    function focus() {
        let qlContainer: any = document.querySelector('.ql-container');
        const jeditor: any = inputRef.current;
        state.replyVisible = false;
        qlContainer.style.maxHeight = null;
        if (!jeditor) {
            return;
        }
        jeditor.focus();
    }

    /**
     * 初始化数据及监听
     */
    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        if (ChatEvent) {
            // 处理右侧展开
            ChatEvent.on('rightSide-change', handleResize);
            // 处理reply
            ChatEvent.on('chat-reply', handleReply);
            ChatEvent.on('close-mention', closeMention);
            ChatEvent.on('reedit-message', SetMessageFromReEdit);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (ChatEvent) {
                ChatEvent.removeListener('rightSide-change', handleResize);
                ChatEvent.removeListener('chat-reply', handleReply);
                ChatEvent.removeListener('close-mention', closeMention);
                ChatEvent.removeListener('reedit-message', SetMessageFromReEdit);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback(
        (input: string) => {
            if (!input) {
                return sessionMembers;
            }
            const temp = sessionMembers.filter((item: any) => item.role !== 'all');
            return temp.filter((s) => {
                if (!isGroupVisibleUnit(selectedSession)) {
                    const unitName = s?.unitName || filterDeptFullName(s.e_dept_full_name);
                    return (
                        PinyinMatch.match(s.name || '', input) ||
                        unitName.includes(input) ||
                        PinyinMatch.match(unitName, input)
                    );
                } else {
                    const deptName = s?.deptName || '';
                    return (
                        PinyinMatch.match(s.name || '', input) ||
                        deptName.includes(input) ||
                        PinyinMatch.match(deptName, input)
                    );
                    // return PinyinMatch.match(s.name || '', input);
                }
            }); // [1, 3])
        },
        [sessionMembers, selectedSession]
    );

    function closeMention() {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.events.emit('close-mention');
    }

    /**
     * 内容变更事件
     * @param delta
     */
    function contentChange(delta: any, oldDelta: any, source: Sources) {
        handleInputStyleChange();
        saveDraft(selectedSession);
        if (visible) {
            setVisible(false);
        }
    }

    /**
     * 回车发送消息 和 alt + 回车插入新行
     * 拦截包围框的按键事件，在内部处理事件之后，不会与@ 的回车混叠
     * @param e
     */
    function handleKeyDown(e: any) {
        if (keyNum === '1') {
            if (e.key === 'Enter' && (e.ctrlKey || e.shiftkey || e.optionkey)) {
                e.preventDefault();
                e.stopPropagation();
                insertText('\n');
                return;
            }
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                sendMessage();
                return;
            }
        }
        if (keyNum === '2') {
            if (e.key === 'Enter' && (e.ctrlKey || e.shiftkey || e.optionkey)) {
                e.preventDefault();
                e.stopPropagation();
                sendMessage();
                return;
            }
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                insertText('\n');
            }
        }
        if (!keyNum) {
            if (e.key === 'Enter' && (e.ctrlKey || e.shiftkey || e.optionkey)) {
                e.preventDefault();
                e.stopPropagation();
                insertText('\n');
                return;
            }
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                sendMessage();
            }
        }
    }
    // 手动发送消息
    const handleSendmessage = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        sendMessage();
        focus();
    };
    const handleEnterClick = (e: any) => {
        setKeyNum(e.key);
        setIsenter('Enter');
        setIsctrl('Ctrl + Enter');
    };
    const handleCtrlClick = (e: any) => {
        setKeyNum(e.key);
        setIsenter('Ctrl + Enter');
        setIsctrl('Enter');
    };
    // 右下角切换方式
    const menu = (
        <Menu selectable={true} defaultSelectedKeys={['1']}>
            <Menu.Item key="1" onClick={handleEnterClick}>
                按Enter发送消息
            </Menu.Item>
            <Menu.Item key="2" onClick={handleCtrlClick}>
                按Ctrl+Enter发送消息
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        let weizhi: any = document.getElementById('input-control-container'); // 输入框
        let qlContainer: any = document.querySelector('.ql-container');

        let domMessageScroll: any = document.getElementById('message-box-container-basScroll');
        let isIpt: any = document.getElementById('input-box-multiline');
        let onMousedown: any = document.getElementById('input-line'); // 透明线
        let body: any = document.querySelector('body');
        let qlEditor = document.querySelector('.ql-editor') as HTMLElement;
        // 透明线双击，文本区域变最高
        onMousedown?.addEventListener('dblclick', function (e: any) {
            const weizhiHeight = weizhi?.getBoundingClientRect()?.height;
            console.log('dblclick');
            if (weizhiHeight === 266) {
                weizhi.style.height = 160 + 'px';
                weizhi.style.minHeight = 160 + 'px';
                weizhi.style.maxHeight = 'auto';
                isIpt.style.maxHeight = 50 + '%';
                isIpt.style.width = 100 + '%';
                isIpt.style.maxHeight = 70 + '%';
                isIpt.style.height = 110 + 'px';
                qlEditor.style.width = 100 + '%';
                qlEditor.style.whiteSpace = 'pre-wrap';
                qlEditor.style.overflowY = 'auto';
                state.replyVisible === true && (qlContainer.style.maxHeight = 36 + 'px');
            } else {
                weizhi.style.maxHeight = 266 + 'px';
                weizhi.style.height = 266 + 'px';
                weizhi.style.minHeight = 'auto';
                isIpt.style.maxHeight = 80 + '%';
                isIpt.style.width = 100 + '%';
                isIpt.style.height = 300 + 'px';
                weizhi.style.minHeight = 160 + 'px';
                qlEditor.style.width = 100 + '%';
                qlEditor.style.whiteSpace = 'pre-wrap';
                qlEditor.style.overflowY = 'auto';
                state.replyVisible === true && (qlContainer.style.maxHeight = 138 + 'px');
            }
        });
        // 获取鼠标点击位置
        onMousedown?.addEventListener('mousedown', function (e: any) {
            if (domMessageScroll === null) {
                return;
            }
            let mouseDownY = e.clientY;
            let H = weizhi.offsetHeight;
            body.style.cursor = 'n-resize';
            body.style.opacity = '1.1';
            body.style.position = 'absolute';
            body.style.top = '0';
            body.style.left = '0';
            body.style.width = '100%';
            body.style.backgroundColor = '#fff';
            body.style.zIndex = '5000';
            document.addEventListener('mousemove', move);
            function move(e: any) {
                let mouseMoveY = e.clientY;
                const weizhiHeight = mouseDownY - mouseMoveY + H;
                weizhi.style.height = `${weizhiHeight}px`;
                if (weizhi) {
                    domMessageScroll.scrollTop = domMessageScroll.scrollHeight;
                }
                body.style.cursor = 'n-resize';
                if (parseInt(weizhi.style.height, 10) >= 266) {
                    weizhi.style.maxHeight = 266 + 'px';
                    isIpt.style.maxHeight = 70 + '%';
                    isIpt.style.width = 100 + '%';
                    isIpt.style.maxHeight = 80 + '%';
                    isIpt.style.height = 300 + 'px';
                    qlEditor.style.width = 100 + '%';
                    qlEditor.style.whiteSpace = 'pre-wrap';
                    qlEditor.style.overflowY = 'auto';
                    state.replyVisible === true && (qlContainer.style.maxHeight = 138 + 'px');
                } else if (parseInt(weizhi.style.height, 10) <= 160) {
                    weizhi.style.height = 160 + 'px';
                    weizhi.style.minHeight = 160 + 'px';
                    isIpt.style.maxHeight = 70 + '%';
                    isIpt.style.width = 100 + '%';
                    isIpt.style.height = 100 + 'px';
                    qlEditor.style.width = 100 + '%';
                    qlEditor.style.whiteSpace = 'pre-wrap';
                    qlEditor.style.overflowY = 'auto';
                    state.replyVisible === true && (qlContainer.style.maxHeight = 36 + 'px');
                } else {
                    isIpt.style.maxHeight = '';
                    state.replyVisible === true &&
                        (qlContainer.style.maxHeight = ` ${weizhiHeight - 132}px`);
                }
            }
            function mouseUpEvent(evt: any) {
                console.log('mouseup');
                evt.stopPropagation();
                evt.preventDefault();
                document.onmousemove = null;
                document.onmouseup = null;
                body.style.cursor = 'auto';
                document.removeEventListener('mousemove', move);
                document.removeEventListener('mouseup', mouseUpEvent);
            }
            // // (3) 鼠标弹起，就让鼠标移动事件移除
            document.addEventListener('mouseup', mouseUpEvent);
        });
        return () => {
            onMousedown?.removeEventListener('dblclick', () => {});
            onMousedown?.removeEventListener('mousedown', () => {});
            // document.removeEventListener('mouseup', () => {
            //     console.log('removeMouseUp');
            // });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.replyVisible]);
    /**
     * 处理输入框样式，判断单行还是多行
     */
    function handleInputStyleChange() {
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (!quill) {
            return;
        }

        // 如果有回车或者超出一行，则变更为多行样式
        const length = quill.getLength();
        const text = quill.getText(0, length - 1);
        const index = text.indexOf('\n');
        if (index > -1) {
            setExpand(true);
            return;
        }
    }
    // const node = document.querySelectorAll('#need-merge');
    async function sendMessage() {
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        const jeditor: any = inputRef.current;
        if (!jeditor) {
            return;
        }
        let { text, userIds } = jeditor.getTextMessage();
        text = text.trim();
        while (text[text.length - 1] === '\n') {
            text = text.slice(0, -1);
        }
        if (!text) {
            return;
        }
        const uids: any[] = [];
        userIds.forEach((u: string) => {
            const temp: any = sessionMembers.find((i: any) => {
                if (i.role === 'unit' || i.role === 'dept') {
                    return extraConvertEmployeeToString(i as Employee, selectedSession) === u;
                }
                return convertEmployeeToString(i as Employee) === u;
            });
            // console.log('遍历结果', temp);
            if (temp) {
                let uid: any;
                if (temp.role === 'unit') {
                    uid = {
                        nickname: temp.name,
                        unitId: temp.unitDeptId,
                    };
                } else if (temp.role === 'dept') {
                    uid = {
                        nickname: temp.name,
                        deptId: temp.deptId,
                    };
                } else {
                    uid = {
                        app: temp.app || '',
                        teamId: temp.teamId || '',
                        nickname: temp.name,
                        pin: temp.userId || '',
                    };
                }
                uids.push(uid);
            }
        });
        let messageTypeOption = (await getMessageTypeByText(text)) as any;
        messageTypeOption = await nonExistentPageToText(messageTypeOption);
        let messageBody;
        let displayContent;
        switch (messageTypeOption.type) {
            case ChatMessageType.JOYSPACEFILE:
                messageBody = await buildJoyspaceLinkMessageBody(
                    text,
                    messageTypeOption as JouspaceLinkTypeOption,
                    uids,
                    selectedSession,
                    state.replyMessage
                );
                break;
            case ChatMessageType.TEXT:
            default:
                displayContent = !messageTypeOption?.is
                    ? await sendMessageBody(text, [selectedSession])
                    : messageTypeOption?.nativeId === 'share_link'
                    ? undefined
                    : convertContentToArray(text);
                messageBody = buildTextMessageBody(
                    text,
                    uids,
                    state.replyMessage,
                    messageTypeOption as HttpCardLinkTypeOption,
                    displayContent
                );
        }
        const message: Partial<ChatMessage> = initChatMessageInfo({
            selectedSession,
            currentEmployee,
            message: messageBody,
        });
        const { needMerge } = getToMergeMessage(
            selectedSession,
            sessionMessageMap[selectedSession.id],
            message as ChatMessage
        );
        bus.emit(`message:Box`, needMerge);
        pushChatMessage({ sessionId: selectedSession.sessionId, message });
        dispatch({ type: 'closeReply' });
        jeditor.clearMessage();
        jeditor.events.emit('close-mention');
    }

    async function handleUploadImgs(files: any, type?: string) {
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        let noFolderFiles: any[] = [];
        if (!files || (files && !files.length)) {
            return;
        } else {
            let allFilesPromise = [].slice.call(files, 0).map((item: any) => {
                return filterDirectory(item, false);
            });

            let result = await Promise.all(allFilesPromise);
            result.forEach((itemFlag, index) => {
                if (itemFlag) {
                    noFolderFiles.push(files[index]);
                }
            });
            if (!noFolderFiles.length) {
                AntMessage.warn('暂不支持发送文件夹，您可以将文件夹压缩后发送');
                return;
            }
        }
        dispatch({ type: 'clearImageState' });
        if (noFolderFiles.length > 1) {
            setMulti(true);
            let typeList: any[] = [];
            noFolderFiles.forEach(async (file: any) => {
                const promise = filterImages(file);
                typeList.push(promise);
            });
            // console.log('typeList', typeList);
            Promise.all(typeList).then((result) => {
                const isAllImages = result.every((item) => item); // 是否全部选择的图片类型
                if (isAllImages) {
                    // 先弹框，在处理旋转问题
                    dispatch({
                        type: 'openUploadImgModal',
                        payload: {
                            imgState: state.imgState,
                            file: state.file,
                            fileList: noFolderFiles,
                        },
                    });
                } else {
                    uploadFile(noFolderFiles);
                }
            });
        } else {
            setMulti(false);
            const file = noFolderFiles[0];
            // console.log('单张图片', file);
            const isImage = await filterImages(file);
            if (!isImage) {
                uploadFile([file]);
                return;
            }
            let tempFile = file;
            // const isHeic = await isHeicImage(tempFile);
            // if (isHeic) {
            //     tempFile = await heicToPng(tempFile);
            // }
            const data = await dealImagesData(tempFile);
            // console.log('data', data);
            if (type !== 'captureImg') {
                dispatch({
                    type: 'openUploadImgModal',
                    payload: { ...data, fileList: [] },
                });
            } else {
                return data;
            }
        }
    }

    function closeUploadImgModal() {
        dispatch({ type: 'closeUploadImgModal' });
        setTimeout(() => {
            dispatch({ type: 'clearImageState' });
        }, 0);
    }

    async function sendImg(selectedImgs?: any, withParam?: boolean, params?: any) {
        dispatch({ type: 'closeUploadImgModal' });
        if (isMulti) {
            uploadMultiImg(selectedImgs);
        } else {
            if (withParam) {
                uploadImg(params.imgState, params.file);
            } else {
                uploadImg(state.imgState, state.file);
            }
        }
    }

    function insertText(str: string) {
        const jeditor = inputRef.current;
        if (!jeditor) {
            return;
        }
        jeditor.insertText(str);
    }

    function handleAtClick(e: any) {
        insertText('@');
        dispatch({
            type: 'handleAtClick',
        });
    }

    function handleQuickReplyClick(visible: boolean) {
        // 打开常用语时 关闭@
        if (visible) {
            closeMention();
        }
        dispatch({
            type: 'changeQuickReplyVisible',
            payload: { quickReplyVisible: visible },
        });
    }

    function handleAtVisibleChange(visible: boolean) {
        if (visible) {
            dispatch({
                type: 'handleAtClick',
            });
        }
    }

    function handleQuickReplyItemClick(detail: any) {
        // 需求变更 常用语会变三行，同意类，中性类，反对类。点击后到输入框中，不是直接发送
        const jeditor = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (!quill) {
            return;
        }
        quill.focus();
        setTimeout(() => {
            const range = quill.getSelection();
            if (!range) return;
            quill.setText(detail);
            quill.setSelection(detail.length + 1, 0);
        });
        dispatch({ type: 'closeReply' });
        focus();
    }

    function handleImgClick() {
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        // 点击图片关闭常用语、表情、@modal框
        dispatch({
            type: 'handleImgClick',
        });
        closeMention();
        const input = document.createElement('INPUT');
        document.body.appendChild(input);
        input.setAttribute('type', 'file');
        input.setAttribute('multiple', 'true');
        input.setAttribute('accept', 'image/*');
        document.body.appendChild(input);
        input.onchange = (ev: any) => {
            if (ev.target.files) {
                handleUploadImgs(ev.target.files);
            }
        };
        input.click();
        document.body.removeChild(input);
    }

    function handleEmojiClick(str: string) {
        const jeditor = inputRef.current;
        if (!jeditor) {
            return;
        }
        const quill = jeditor.getQuill();
        if (!quill) {
            return;
        }
        quill.focus();
        setTimeout(() => {
            const range = quill.getSelection();
            if (!range) return;
            const { index } = range;
            const Delta = Quill.import('delta');
            quill.updateContents(
                new Delta().retain(index).insert({
                    'custom-emoji': str,
                }),
                'user'
            );
            quill.setSelection(index + 1, 0);
        });
    }
    function handleFileClick() {
        closeOtherModal();
        if (isBanned) {
            AntMessage.warn(t(SendMessageIsBanned));
            return;
        }
        const input = document.createElement('INPUT');
        input.setAttribute('type', 'file');
        input.onchange = (ev: any) => {
            if (ev.target.files && ev.target.files.length > 0) {
                uploadFile(ev.target.files[0]);
            }
        };
        input.click();
    }

    function handleEmojiVisible(visible: boolean) {
        // 点击表情关闭@modal框
        if (visible) {
            closeMention();
        }
        dispatch({
            type: 'changeEmojiVisible',
            payload: { visible },
        });
    }

    // 点击+号关闭常用语和@modal框
    function closeOtherModal() {
        closeMention();
    }

    function getRightMenu() {
        const menus: any[] = [];
        // 表情
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_EMOJI]) {
            menus.push(
                <EmojiPopover
                    key="1"
                    onclick={handleEmojiClick}
                    visible={state.emojiVisible}
                    onVisibleChange={handleEmojiVisible}
                    clstag="pageclick|keycount|focus_chat_01_1615797500283|41"
                >
                    <Tooltip title={t('emoji')} placement="bottom">
                        <IconFont
                            className="expression input-button"
                            type="iconic_app_im_expression"
                        />
                    </Tooltip>
                </EmojiPopover>
            );
        }
        // 图片
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_IMAGE]) {
            menus.push(
                <Tooltip key="3" title={t('image')} placement="bottom">
                    <IconFont
                        className="pic input-button"
                        type="iconic_app_im_picture"
                        onClick={handleImgClick}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|43"
                    />
                </Tooltip>
            );
        }
        // @
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_AT] && !selectedSession.isSingle) {
            menus.push(
                <Tooltip key="10" title={t('@remind')} placement="bottom">
                    <IconFont
                        className="at input-button"
                        type="iconic_app_im_mention"
                        onClick={handleAtClick}
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|42"
                    />
                </Tooltip>
            );
        }
        // 常用语
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_CHANG]) {
            menus.push(
                <QuickReplyComp
                    visible={state.quickReplyVisible}
                    onItemClick={handleQuickReplyItemClick}
                    key={selectedSession.id}
                    onVisibleChange={handleQuickReplyClick}
                    quickReplys={quickReplys}
                >
                    <Tooltip key="7" title={t('quickReply')} placement="bottom">
                        <IconFont
                            className="at input-button"
                            type="icona-ic_app_im_Commonlyused"
                            clstag="pageclick|keycount|focus_chat_01_1602584371145|42"
                        />
                    </Tooltip>
                </QuickReplyComp>
            );
        }
        // +号
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_FILE_IN_MENU]) {
            if (config[ChatConfig.CHAT_MESSAGE_INPUT_FILE]) {
                if (menus.length > 0) {
                    menus.push(<div key="4" className="sort-line" />);
                }
                menus.push(
                    <Tooltip key="5" title={t('file')} placement="bottom">
                        <IconFont
                            className="chat-input-more-menu-icon"
                            type="iconapp_btn_folder"
                            onClick={handleFileClick}
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|45"
                        />
                    </Tooltip>
                );
            }
        } else {
            if (config[ChatConfig.CHAT_MESSAGE_INPUT_MORE]) {
                if (menus.length > 0) {
                    menus.push(<div key="4" className="sort-line" />);
                }
                menus.push(
                    <MoreDropdown
                        key="6"
                        // handleUploadImgs={handleUploadImgs}
                        uploadFile={
                            isBanned
                                ? () => {
                                      AntMessage.warn(t(SendMessageIsBanned));
                                  }
                                : uploadFile
                        }
                        sendJoyspaceMessage={sendJoyspaceMessage}
                        closeOtherModal={closeOtherModal}
                    >
                        <IconFont
                            className="add  input-button"
                            type="icona-ic_app_im_Addto"
                            clstag="pageclick|keycount|focus_chat_01_1615797500283|44"
                        />
                    </MoreDropdown>
                );
            }
        }

        return (
            <div className={`${!expand ? '' : 'input-control-container-multRight'} `}>
                <div
                    className={`${
                        singleLine
                            ? 'input-control-container-right single-line'
                            : 'input-control-container-right'
                    }`}
                >
                    {menus}
                </div>
            </div>
        );
    }
    if (inputDisable) {
        return (
            <BannedInput
                currentEmployee={currentEmployee}
                selectedSession={selectedSession}
                config={config}
                divEl={divEl}
            />
        );
    }
    return (
        <div
            id="input-control-container"
            className={`input-control-container ${
                inputDisable ? 'input-control-container-disable' : singleLine ? 'single-line' : ''
            }`}
            ref={divEl}
            onKeyDown={handleKeyDown}
            onClick={(e) => {
                e.stopPropagation();
                closeSetting?.();
            }}
        >
            <div className="input-line" id="input-line" />
            {!singleLine ? getRightMenu() : null}
            {state.replyVisible && (
                <div className="input-replay" ref={replyRef}>
                    <div
                        className="action"
                        clstag="pageclick|keycount|focus_chat_01_1615797500283|51"
                        onClick={() => {
                            dispatch({ type: 'closeReply' });
                            focus();
                        }}
                    >
                        <IconFont className="icon-close" type="iconapp_btn_alert_cancel" />
                    </div>
                    <div className="sort-line" />
                    <div className="reply-text">
                        <span>
                            {t('input_quote_text')
                                .replace(
                                    '%name',
                                    (state.replyUser || {}).nickName || (state.replyUser || {}).name
                                )
                                .replace('%s', '')}
                        </span>
                        <p
                            className="dangerous-inner-html"
                            dangerouslySetInnerHTML={{
                                __html: state.replyText,
                            }}
                        />
                    </div>
                </div>
            )}
            {isFocusEnv() ? (
                <Dropdown
                    overlay={() => {
                        return (
                            <>
                                <div
                                    className="paste-space"
                                    onClick={(e) => {
                                        doPaste(e);
                                    }}
                                >
                                    {t('paste')}
                                </div>
                                {copyText && visible && (
                                    <div
                                        className="paste-space"
                                        onClick={(e) => {
                                            doCopy(e);
                                        }}
                                    >
                                        {t('copy')}
                                    </div>
                                )}
                            </>
                        );
                    }}
                    trigger={['contextMenu']}
                    visible={visible}
                    onVisibleChange={onVisibleChange}
                >
                    <div
                        id="input-box-multiline"
                        className={`input-box ${
                            !expand ? 'input-box-singleline' : 'input-box-multiline'
                        }`}
                        style={
                            expand
                                ? {}
                                : {
                                      width: `${inputWidth}px`,
                                  }
                        }
                    >
                        <JEditor
                            contentChange={contentChange}
                            ref={inputRef}
                            upload={handleUploadImgs}
                            placeholder="请输入消息"
                            disableEnter={true}
                            maxLength={2000}
                            // onEditorKeyDown={handleKeyDown}
                        />
                    </div>
                </Dropdown>
            ) : (
                <div
                    id="input-box-multiline"
                    className={`input-box ${
                        !expand ? 'input-box-singleline' : 'input-box-multiline'
                    }`}
                    style={
                        expand
                            ? {}
                            : {
                                  width: `${inputWidth}px`,
                              }
                    }
                >
                    <JEditor
                        contentChange={contentChange}
                        ref={inputRef}
                        upload={handleUploadImgs}
                        placeholder="请输入消息"
                        disableEnter={true}
                        maxLength={2000}
                        // onEditorKeyDown={handleKeyDown}
                    />
                </div>
            )}
            {singleLine ? getRightMenu() : null}
            {config[ChatConfig.CHAT_MESSAGE_INPUT_AT] &&
                !(
                    selectedSession.isSingle && selectedSession.uid?.pin === currentEmployee.userId
                ) &&
                inputRef.current && (
                    <MentionUserPlugin
                        editor={inputRef.current}
                        members={sessionMembers}
                        handleSearch={handleSearch}
                        t={t}
                        onVisibleChange={handleAtVisibleChange}
                    />
                )}
            <UpLoadImg
                visible={state.imgUploadModalVisible}
                onCancel={closeUploadImgModal}
                onClick={sendImg}
                isMulti={isMulti}
                file={state.file}
                fileList={state.fileList}
                base64={state.imgState.base64}
                title={state.imgState.title}
                size={state.imgState.size}
                width={state.imgState.width}
                height={state.imgState.height}
            />
            {/* 切换发送按钮 */}
            {!singleLine ? (
                <div className="enter-container">
                    <span className="enter-txt">
                        按{isenter === '' ? 'Enter' : isenter}发送消息，
                        {isctrl === '' ? 'Ctrl + Enter' : isctrl}换行
                    </span>
                    <div className="enter-btn1">
                        <Button type="primary" size="small" block onClick={handleSendmessage}>
                            发 送
                        </Button>
                    </div>

                    <div className="enter-btn2">
                        <Space>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button type="primary" size="small">
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Space>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);
