import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import { difference, throttle } from 'lodash';
import { usePrevious } from '@/utils/chat/index';

interface vProps {
    id: string;
    display: boolean;
    container: any;
    virtualList: any[];
    height: number;
    children: (props: any) => React.ReactNode;
}

export interface ChildrenProps {
    item: any;
    index: number;
}

export interface sectionProps {
    visibleList: any[];
    offset: number;
    sIndex: number;
}

function DownVirtualList(props: vProps) {
    const { id, display, container, virtualList, height = 60, children } = props;
    const [visibleObj, setVisibleObj] = useState<sectionProps>({
        visibleList: [],
        offset: 0,
        sIndex: 0,
    });
    const preVisibleList = usePrevious(visibleObj?.visibleList || []);
    // const [sIndex, setSIndex] = useState(0);
    const sIndex = useRef(-1);
    const eIndex = useRef(-1);
    // const lastScrollTop = useRef(0);
    const vLength = useRef(virtualList.length);
    const handleVirtualScroll = useCallback(
        (force = false) => {
            const scrollTop = container?.scrollTop || 0;
            const clientHeight = container?.clientHeight || 0;
            const visibleCount = Math.ceil(clientHeight / height);
            let startIndex = Math.floor(scrollTop / height);
            let endIndex = startIndex + visibleCount;
            if (endIndex > virtualList.length) {
                endIndex = virtualList.length;
            }
            if (
                startIndex === sIndex.current &&
                endIndex === eIndex.current &&
                vLength.current === virtualList.length
            ) {
                const newList = virtualList.slice(startIndex, endIndex);
                const diff = difference(newList, preVisibleList || []);
                if (!diff.length && !force) {
                    return;
                }
            }
            sIndex.current = startIndex;
            eIndex.current = endIndex;
            vLength.current = virtualList.length;
            setVisibleObj({
                visibleList: virtualList.slice(startIndex, endIndex),
                offset: scrollTop - (scrollTop % height),
                sIndex: startIndex,
            });
            // setSIndex(startIndex);
        },
        [container?.clientHeight, container?.scrollTop, height, preVisibleList, virtualList]
    );

    const doHandleVirtualScroll = throttle(() => handleVirtualScroll(true), 60);

    useEffect(() => {
        if (virtualList.length === 0 || !container) {
            return;
        }
        handleVirtualScroll();
        container.addEventListener('scroll', handleVirtualScroll);
        return () => {
            container.removeEventListener('scroll', handleVirtualScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container, virtualList]);

    useEffect(() => {
        window.addEventListener('resize', doHandleVirtualScroll);
        return () => {
            window.removeEventListener('resize', doHandleVirtualScroll);
        };
    }, [doHandleVirtualScroll]);

    return (
        <div
            id={id}
            style={{
                display: display ? 'block' : 'none',
                position: 'relative',
                // transform: 'translate3d(0, ' + visibleObj.offset + 'px, 0)',
            }}
        >
            {visibleObj.visibleList?.map((item: any, index) => {
                return children!({
                    item,
                    curIndex: visibleObj.sIndex + index,
                });
            })}
        </div>
    );
}

export default memo(DownVirtualList);
