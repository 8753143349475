import Quill from 'quill';
import { formatAtName } from '@/utils/chat/index';
const EmbedBlot = Quill.import('blots/embed');

export default class MentionBlot extends EmbedBlot {
    static blotName = 'mention-link';
    static className = 'e-mention-link';
    static tagName = 'A';
    static SANITIZED_URL = 'javascript:void(0)';
    static PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];
    static create(value: any = {}) {
        const node = super.create(value) as HTMLLinkElement;
        return MentionBlot._formatNode(node, value);
    }

    static _formatNode(domNode: HTMLLinkElement, value: any = {}) {
        const { id = '', type = '', name = '' } = value;
        domNode.setAttribute('id', `mention-link-${id}-${Math.random().toString(36).substr(2, 9)}`);
        domNode.setAttribute('mention-id', id);
        domNode.setAttribute('data-event-click', 'mention-link');
        domNode.setAttribute(
            'data-event-params',
            JSON.stringify({
                id,
                type,
                name,
            })
        );
        domNode.innerText = formatAtName(name);
        // domNode.innerText = `@${name}`;
        domNode.setAttribute('contenteditable', 'false');
        domNode.classList.add(`e-mention-${type}`);
        return domNode;
    }

    static value(domNode: HTMLLinkElement) {
        const { eventParams = '' } = domNode.dataset;
        let params = {};
        try {
            params = JSON.parse(eventParams);
        } catch (error) {
            return {};
        }
        const { id, name, type } = params as any;
        return { id, name, type };
    }
}
