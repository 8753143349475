/**
 * http链接转卡片消息组件
 * @author lvyajing3
 */
import React, { useEffect } from 'react';
import { connect } from 'dva';
import './index.less';
import { Employee, MessageStatus, SessionType, TextChatMessageExt } from '@/types/chat';
import { MessageSendType, SessionSettingsType } from '@/types/chat/enum';
import ChatState from '@/types/chat/State';
import Link from '@/assets/images/link.png';
import CopyClipboard from 'copy-to-clipboard';
import { message as toast } from 'antd';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { getTextMessageContent } from '@/components/chat/utils/commonMessageModal';
import Context from '@/context/ChatContext';
import ReplyMessage from '@/components/chat/message/ReplyMessage';
import { judgeHttpCardLinkUrl } from '@/utils/tools';
import MessageCard, { OriginType } from '../message-components/MessageCard';
import { convertContentToArray, MessageCardType } from '../../utils/message';
import ShortLink from '../message-components/ShortLink';
import { handleOpenMentionUserInfo } from '@/utils/chat/index';
import { getSelectorSessions } from '@/utils/chat/session';
import { openUserSelectionModal } from '@/utils/modals';
import { employeeToUid, forwardLeaveWord, selectedSessionsToForward } from '@/utils/chat/message';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import config, { ConfigEnum } from '@/config/config';
import chatConfig from '@/components/chat/config';
import { InputPlacement } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal/types';
import { UserModel } from '@/models/user';
import get from 'lodash/get';
import { debounce, isString, pick } from 'lodash';
import ImService from '@/server/ImService';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { shareScheduleAttachment } from '@/api/calendar';

interface TextProps {
    message: TextChatMessageExt;
    sessionType: SessionType;
    sendType: MessageSendType;
    beginSend: boolean;
    messageExtend: any;
    userData: UserModel;
    isforward?: boolean;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type ITextProps = Readonly<TextProps & ChatState>;

function HttpCard(props: ITextProps) {
    const {
        sendType,
        message,
        onReady,
        currentEmployee,
        selectedSession,
        allSessionList,
        sessionMessageMap,
        isforward,
        userData,
    } = props;
    const { groupRosterIdentity } = React.useContext(Context);
    const { t } = useTranslation('chat');

    const { data } = message as any;
    const isArr = Array.isArray(data);
    const { fileName = '内容分享', fileContent = '快查看分享的内容吧！', webUrl } = isArr
        ? data[0].joySpaceLinkDetail
        : data;
    if (isArr) {
        props.message.content = data[0].joySpaceLinkDetail.content;
    }

    useEffect(() => {
        if (
            (sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF) &&
            message.status === MessageStatus.INIT
        ) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);

    function handleOpenUrl(e: any) {
        e.stopPropagation();
        e.preventDefault();
        if (!webUrl) {
            return;
        }
        const isOpen = judgeHttpCardLinkUrl(webUrl);
        // console.log('isOpen', isOpen);
        if (!isOpen) {
            const isShowToast = handleCopyUrl(e, true);
            if (isShowToast) {
                toast.success('地址已复制到剪切板，请在浏览器中打开。');
            }
            return;
        }
        window.open(webUrl);
    }
    function handleCopyUrl(event: any, isNotDefaultToast?: boolean) {
        event.stopPropagation();
        event.preventDefault();
        // let userSelection;
        // let str = webUrl;
        // if (typeof window.getSelection === 'function') {
        //     userSelection = window.getSelection();
        // }
        // if (userSelection?.toString()) {
        //     str = userSelection.toString();
        // }
        // if ((str || '').length > (webUrl || '').length) {
        //     str = webUrl;
        // }
        if (webUrl && CopyClipboard(webUrl)) {
            if (isNotDefaultToast) {
                return true;
            }
            toast.success(t('copy-succeeded'));
        } else {
            toast.error(t('copy-failed'));
        }
    }

    // 转发
    function handleForward() {
        const fixedUsers = getSelectorSessions(allSessionList);
        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                // mode: SelectorMode.forward,
                mode: SelectorMode.user,
                tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recentIncludeGroup', 'org', 'groupCanBeSel', 'externalContacts']
                    : ['recentIncludeGroup', 'groupCanBeSel', 'externalContacts'],
                leaveWordInput: {
                    maxLength: chatConfig['forward-leave-max-length'],
                    placeholder: t('please-enter-forward-leave'),
                    isTextArea: true,
                },
                inputPlacement: InputPlacement.bodyRightBottom,
                inputWrapClass: 'forward-input-wrap',
                validate(data) {
                    return new Promise((resolve, reject) => {
                        let list = get(data, 'data.result', []);
                        if (list.length > chatConfig['forward-session-count']) {
                            toast.error(`最多支持选择${chatConfig['forward-session-count']}个会话`);
                            return false;
                        }
                        const forwardLeave = get(data, 'data.leaveWordInputValue', '');
                        if (
                            forwardLeave &&
                            forwardLeave.length > chatConfig['forward-leave-max-length']
                        ) {
                            toast.info(
                                t('content over maxLength', {
                                    name: '留言',
                                    maxLength: chatConfig['forward-leave-max-length'],
                                })
                            );
                            return false;
                        }
                        resolve();
                    });
                },
            },
            (data, close) => {
                doExitAndForward(data, close);
            },
            userData
        );
    }

    const exitAndForward = (options: any, list: any): Promise<Boolean> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const imService = ImService.getInstance();
            const sessionId = selectedSession.sessionId;
            let Message: any = message;
            const result = await imService.forwardMessage(sessionId, Message, options);
            if (isString(result) && result === SessionSettingsType.BANNED_POST) {
                toast.warn(t('banned_post_tip'));
                resolve(false);
            }
            if (result) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    };

    const doExitAndForward = debounce(async (data: any, close) => {
        // 等待消息转发完成以后关闭窗口
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        const options = await selectedSessionsToForward(list);
        const status = await exitAndForward(options, list);
        if (status) {
            const forwardLeave = get(data, 'data.leaveWordInputValue', '');
            forwardLeave && (await forwardLeaveWord(currentEmployee, options, forwardLeave));
            toast.success(t('forwarding-successful'));
        } else {
            toast.error(t('forwarding-failed'));
        }
        close();
    }, 500);

    // 纯http链接转卡片
    if (webUrl === props.message.content) {
        const desc =
            fileContent === '快查看分享的内容吧！' ? '请点击卡片，查看内容详情。' : fileContent;
        return (
            <div className="content">
                <ReplyMessage message={message} sendType={sendType} />
                <MessageCard
                    origin={OriginType.IM}
                    title={fileName}
                    desc={desc}
                    imageSrc={Link}
                    handleDetail={(e: any) => handleOpenUrl(e)}
                    footerChildren={() => {
                        if (isforward === true) return null;
                        return (
                            <div className="http-link-footer">
                                <div className="http-link-footer-container" onClick={handleForward}>
                                    {/* <span>复制链接</span> */}
                                    <span>转发</span>
                                    {/* <IconFont className="icon" type="iconapp_btn_copy" /> */}
                                    <IconFont className="icon" type="iconapp_btn_forwarding" />
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
        );
    } else {
        const contentArr = convertContentToArray(message.content);
        // console.log('http卡片链接', contentArr);
        return (
            <div className="content">
                <div
                    className={`http-link-card-container ${
                        sendType === MessageSendType.OTHER ? 'http-link-card-container-other' : ''
                    }`}
                >
                    <ReplyMessage message={message} sendType={sendType} />
                    {contentArr.map((item, index) => {
                        return (
                            <div key={index} style={{ margin: '1px 0' }}>
                                {item.type === MessageCardType.Text ? (
                                    <div
                                        onClick={(e) =>
                                            handleOpenMentionUserInfo(e, t, {
                                                currentEmployee,
                                                selectedSession,
                                                groupRosterIdentity,
                                            })
                                        }
                                        className={`top-box ${
                                            selectedSession.isSecret ? 'top-box_isSecret' : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: getTextMessageContent(
                                                { ...message, content: item.content },
                                                currentEmployee
                                            ).trim(),
                                        }}
                                    />
                                ) : (
                                    <ShortLink shortLinkItem={item} />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps({ chat, user: { userData } }: any) {
    const {
        currentEmployee,
        selectedSession,
        connectState,
        allSessionList,
        sessionMessageMap,
    } = chat as ChatState;
    return {
        currentEmployee,
        selectedSession,
        connectState,
        allSessionList,
        sessionMessageMap,
        userData: userData ? userData.user : {},
    };
}
function mapDispatchToProps(dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(HttpCard);
