const defaultState = {
    newPublicResult: true,
    newPrivateResult: false,
};

export interface NetState {
    newPublicResult: boolean;
    newPrivateResult: boolean;
}

export default {
    namespace: 'netStatus',
    state: { ...defaultState },
    reducers: {
        setCurrentNetInfo(state: NetState, { payload }: any) {
            return { ...state, ...payload };
        },
    },
};
