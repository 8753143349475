/**
 * 加载图片
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useMemo } from 'react';

import './index.less';
import { SysChatMessage } from '@/types/chat';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import { isEqualEmployee } from '@/utils/chat/message';

interface SysProps {
    message: SysChatMessage;
}

type IProps = Readonly<SysProps & ChatState>;

function SysMessage(props: IProps) {
    const { message, currentEmployee } = props;
    const { t } = useTranslation('chat');

    const tipContent = useMemo(() => {
        if (message.flag === 1) {
            // 0-直接显示content,1-文件下载（需要拼接content），2-接收红包消息（需要拼接content）
            const receiver = isEqualEmployee(message.sender, currentEmployee)
                ? `您`
                : message.nickName;
            return t('receive-file-tip', { receiver, filename: message.content });
        } else {
            return null;
        }
    }, [message, currentEmployee, t]);
    return <div className="sys-message-box">{tipContent}</div>;
}

function mapStateToProps({ chat }: any) {
    const { currentEmployee } = chat as ChatState;
    return { currentEmployee };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SysMessage);
