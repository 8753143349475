/**
 * 分类消息组件
 * @author sunzhiguang
 * @date 2020/6/17
 */
import React, { useCallback, useContext, useEffect, useState, memo } from 'react';

import './index.less';
import Bus from '@/utils/bus';
import SessionCategoryHeader from '@/components/chat/SessionCategoryHeader';
import Session from '@/components/chat/Session';
import { SessionProps } from '@/types/chat';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
// import FixedVirtualList from '../message/VirtualList/FixedVirtualList.tsx';
import VirtualList from '../message/VirtualList/VirtualList';

interface SessionCategoryProps {
    id: string;
    title: string;
    dataList: Array<SessionProps>;
    historyState?: any;
    sessionHeight?: number;
}

type ISessionCategoryProps = Readonly<SessionCategoryProps>;

function SessionCategory(props: ISessionCategoryProps) {
    const { focusSDK } = useContext<GlobalContextConfig>(GlobalContext);
    const { dataList, title, id, sessionHeight = 60 } = props;
    const [display, setDisplay] = useState<boolean>(true);
    const [paddingTop, setPaddingTop] = useState<number>(0);
    const handleClick = () => {
        setDisplay(!display);
    };

    const afterClickSession = useCallback(
        (session: any) => {
            focusSDK.sendIpcMessage('afterSessionClick', session.sessionId);
        },
        [focusSDK]
    );
    const container = document.getElementById('chat-left-scroll')?.firstChild;
    return (
        <div style={{ height: dataList?.length * sessionHeight + 'px' }}>
            {dataList?.length > 0 && (
                <SessionCategoryHeader title={title} clickEvent={handleClick} />
            )}
            {dataList?.length > 0 && (
                <VirtualList
                    id={id}
                    display={display}
                    container={container}
                    virtualList={dataList}
                    height={sessionHeight}
                >
                    {(props: any) => {
                        const { item, curIndex } = props;
                        return (
                            <Session
                                session={item}
                                key={item.sessionId}
                                last={false}
                                setCallBack={afterClickSession}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: `${sessionHeight}px`,
                                    transform: `translateY(${curIndex * 60}px)`,
                                }}
                            />
                        );
                    }}
                </VirtualList>
            )}
        </div>
    );
}

export default SessionCategory;
