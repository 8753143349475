import React from 'react';
import { connect } from 'dva';
import logger from '@/utils/logger';
const log = logger.getLogger('SearchGlobalModal');
import { PRIORITY_TYPE_MAP } from '@/components/Projects/emnu';
import { PRIORITY_TYPE } from '@/types/newWork';
import { decorateTaskDetail } from '@/components/Work/newUtils';
import { changeEgovUrl } from '@/utils/tools';

import './TaskPaneItem.less';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';

const titleMap: any = {
    wxtdList: '我协同的',
    wfzdList: '我负责的',
    wcjdList: '我创建的',
};
function TabPaneTask({ info, changePersonalTaskDraw, onClick }: any) {
    const { origin } = info;
    if (!origin) return null;

    const taskDetail = decorateTaskDetail(origin);
    // @ts-ignore
    // window.aaaddd = taskDetail;
    const priorityType: PRIORITY_TYPE = origin.priorityType;
    return (
        <div>
            <div
                className="search-task-item"
                onClick={() => {
                    if (onClick) {
                        onClick(SearchType.Task, info);
                    }
                    changePersonalTaskDraw(info.id);
                }}
            >
                <div className="avatar">
                    {priorityType && priorityType !== PRIORITY_TYPE.NO_PRIORITY && (
                        <img
                            style={{
                                width: '14px',
                                height: '14px',
                                marginRight: '5px',
                                marginTop: '-3px',
                            }}
                            src={changeEgovUrl(PRIORITY_TYPE_MAP[priorityType]?.url)}
                            alt={PRIORITY_TYPE_MAP[priorityType]?.alt}
                            title={PRIORITY_TYPE_MAP[priorityType]?.alt}
                        />
                    )}
                </div>
                <div className="detail">
                    {info.name}
                    <div className={`time ${taskDetail.dueTag ? 'overdue' : ''}`}>
                        {taskDetail.workStopTime
                            ? `${taskDetail.workStopTime.format('YYYY-M-D HH:mm')} 截止`
                            : '无截止时间'}
                        {taskDetail.dueTag && <span>逾期</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch: any) {
    return {
        changePersonalTaskDraw(taskId: string, activeTab?: string) {
            return dispatch({
                type: 'work/changePersonalTaskDraw',
                payload: { taskId, drawVisible: true, activeTab },
            });
        },
    };
}
export default connect(function () {
    return {};
}, mapDispatchToProps)(TabPaneTask);
