import React, { Component } from 'react';
import './index.less';
import { connect } from 'dvajs';
import { ITimeBlock } from '@/models/work';

import { TaskModel, TimeClassifyEnum, WorkUserInfo, TaskPartEnum } from '@/types/work';

import WorkEvent from '../WorkEvent';
import {
    generateTimeRange,
    findFrontAndAfter,
    updateExecutorInTask,
    updateTaskInTimeBlock,
    getTimeClassifyByTime,
    findSelfInExecutors,
    findPersonalTaskInTimeBlock,
} from '../utils';
import dayjs, { Dayjs } from 'dayjs';
import { taskSortUpdate, updateTaskProcess } from '@/api/work';
import { message } from 'antd';
import bus from '@/utils/bus';
import _throttle from 'lodash/throttle';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { DraggableLocation } from 'react-beautiful-dnd';

export function reorder(
    timeBlocks: ITimeBlock[],
    source: DraggableLocation,
    destination: DraggableLocation
) {
    const timeBlock = timeBlocks[Number(source.droppableId)];
    const list = timeBlock.list;
    const result = Array.from(list);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    timeBlock.list = result;
    return [...timeBlocks];
}
export function move(
    timeBlocks: ITimeBlock[],
    source: DraggableLocation,
    destination: DraggableLocation
) {
    const sourceTimeBlock = { ...timeBlocks[Number(source.droppableId)] };
    const sourceList = [...sourceTimeBlock.list];

    const destinationTimeBlock = { ...timeBlocks[Number(destination.droppableId)] };
    const destinationList = [...destinationTimeBlock.list];

    const [removed] = sourceList.splice(source.index, 1);

    destinationList.splice(destination.index, 0, removed);

    sourceTimeBlock.list = sourceList;
    sourceTimeBlock.total = sourceTimeBlock.total - 1;
    sourceTimeBlock.lastTaskId =
        sourceTimeBlock.list.length === 0
            ? ''
            : sourceTimeBlock.list[sourceTimeBlock.list.length - 1].taskId;

    destinationTimeBlock.list = destinationList;
    destinationTimeBlock.total = destinationTimeBlock.total + 1;
    destinationTimeBlock.lastTaskId =
        destinationTimeBlock.list.length === 0
            ? ''
            : destinationTimeBlock.list[destinationTimeBlock.list.length - 1].taskId;

    const newTimeBlocks = [...timeBlocks];
    newTimeBlocks[Number(source.droppableId)] = sourceTimeBlock;
    newTimeBlocks[Number(destination.droppableId)] = destinationTimeBlock;
    return newTimeBlocks;
}

interface IProps {
    timeBlocks: ITimeBlock[];
    changPersonalTaskOrder: (timeBlocks: ITimeBlock[]) => void;
    updatePersonalTaskItem: (timeBlockId: string, task: Partial<TaskModel>) => void;
    getMyTasksByTime: (opts: {
        endTimeFrom?: string;
        endTimeTo?: string;
        timeBlockId?: string;
        offset: number;
        limit: number;
        timeClassify?: number;
        projectId: string;
        priorityTypes?: number[];
        taskClassify?: number;
    }) => void;
    userData: any;
    addPersonalTemporyTask: (task: TaskModel) => void;
    delPersonalTaskInTimeBlock: (timeBlockId: string, taskId: string) => void;
    projectId: string;
    priorityTypes?: number[];
    taskClassify?: number;
}

class WorkHandle extends Component<IProps> {
    queue: {
        source: any;
        destination: any;
        task: TaskModel;
        newTask: TaskModel;
        taskStatus: number;
        processStatus: number;
        self?: WorkUserInfo;
    }[] = [];
    queueRuning = false;
    reloadSet: Set<number> = new Set();
    constructor(props: IProps) {
        super(props);
        this.handleThrottleSync = _throttle(this.handleThrottleSync, 1000);
    }

    componentDidMount() {
        this.bindEvent();
    }
    componentWillUnmount() {
        this.unbindEvent();
    }

    /**
     * 更新函数不区分个人任务还是项目任务，在更新的时候会查询任务是否存在
     */
    handleUpdatePersonalTaskItem = (opt: { timeBlockId: string; task: Partial<TaskModel> }) => {
        let { timeBlockId, task } = opt;
        this.props.updatePersonalTaskItem(timeBlockId, task);
    };
    /**
     * 创建的时候，只有本人可以创建，因此当为个人任务时，不判断哪个团队的，直接增加到个人任务的临时区域
     */
    handleAddPersonalTemporyTask = ({ task }: { task: TaskModel }) => {
        if (this.props.projectId && task.projectId !== this.props.projectId) {
            return;
        }
        this.props.addPersonalTemporyTask(task);
    };
    handleDelPersonalTaskInTimeBlock = ({ taskId }: { taskId: string }) => {
        this.props.delPersonalTaskInTimeBlock('', taskId);
    };

    bindEvent = () => {
        WorkEvent.on('reorderByTimeChange', this.handleMoveByTimeChange);
        WorkEvent.on('reorderByTaskStatusChange', this.handleMoveByTaskStatusChange);
        WorkEvent.on('reloadTimeBlock', this.handleReloadList);
        WorkEvent.on('updatePersonalTaskItem', this.handleUpdatePersonalTaskItem);
        WorkEvent.on('addPersonalTemporyTask', this.handleAddPersonalTemporyTask);
        WorkEvent.on('delPersonalTaskInTimeBlock', this.handleDelPersonalTaskInTimeBlock);
        WorkEvent.on('resetParentTaskProgress', this.resetParentTaskProgress);
        bus.on(`chat_message_multiSync_event:joyWork`, this.handleSync);
        bus.on('chat:connect_state_change', this.handleNetworkChange);
        bus.on('project:isArchive', this.handleProjectArchive);
    };

    unbindEvent = () => {
        WorkEvent.off('reorderByTimeChange', this.handleMoveByTimeChange);
        WorkEvent.off('reorderByTaskStatusChange', this.handleMoveByTaskStatusChange);
        WorkEvent.off('reloadTimeBlock', this.handleReloadList);
        WorkEvent.off('updatePersonalTaskItem', this.handleUpdatePersonalTaskItem);
        WorkEvent.off('addPersonalTemporyTask', this.handleAddPersonalTemporyTask);
        WorkEvent.off('delPersonalTaskInTimeBlock', this.handleDelPersonalTaskInTimeBlock);
        WorkEvent.off('resetParentTaskProgress', this.resetParentTaskProgress);
        bus.off(`chat_message_multiSync_event:joyWork`, this.handleSync);
        bus.off('chat:connect_state_change', this.handleNetworkChange);
        bus.off('project:isArchive', this.handleProjectArchive);
    };

    handleProjectArchive = (data: { projectId: string; archive: boolean }) => {
        this.handleReloadList({ timeClassifys: [1, 2, 3, 4, 5], projectId: '' });
    };
    handleSync = (receive: any) => {
        if (!receive) {
            console.log('多端同步逻辑数据', receive);
            return message.error('多端同步数据格式错误');
        }
        const { data } = receive;
        const { taskId, endTimeBefore, endTimeAfter, timeClassify, projectId = '', part } = data;
        if (
            ![
                TaskPartEnum.PERSONAL_TASK,
                TaskPartEnum.MEANWHILE_TASK,
                TaskPartEnum.PROJECT_TASK,
            ].includes(part)
        ) {
            return;
        }
        if (part === TaskPartEnum.PERSONAL_TASK) {
            if (this.props.projectId) {
                return;
            }
        }
        if (part === TaskPartEnum.PROJECT_TASK) {
            if (projectId !== this.props.projectId) {
                return;
            }
        }
        if (part === TaskPartEnum.MEANWHILE_TASK) {
            if (projectId !== this.props.projectId && this.props.projectId) {
                return;
            }
        }

        if (endTimeBefore) {
            this.reloadSet.add(getTimeClassifyByTime(dayjs(endTimeBefore)));
        } else {
            this.reloadSet.add(TimeClassifyEnum.LaterOrUntime);
        }
        if (endTimeAfter) {
            this.reloadSet.add(getTimeClassifyByTime(dayjs(endTimeAfter)));
        } else {
            this.reloadSet.add(TimeClassifyEnum.LaterOrUntime);
        }
        if (timeClassify) {
            if (Number(timeClassify) === 999) {
                for (let i = 1; i < 6; i++) {
                    this.reloadSet.add(i);
                }
            } else {
                this.reloadSet.add(Number(timeClassify));
            }
        }
        // console.log('多端同步逻辑数据', receive, Array.from(this.reloadSet));
        this.handleThrottleSync();
    };
    handleNetworkChange = (data: any) => {
        // console.log('网络状态变化', data);
        const { connectState } = data;
        if (connectState.state === ConnectState.READY) {
            for (let i = 1; i < 6; i++) {
                this.reloadSet.add(i);
            }
            this.handleThrottleSync();
        }
    };
    /**
     * 时间块一致的情况仅作时间更新，不考虑排序变动
     * @param source
     * @param destination
     * @param task
     */
    handleMoveByTimeChange = ({
        source,
        destination,
        task,
        newTime,
        projectId,
    }: {
        source: any;
        destination: any;
        task: TaskModel;
        newTime: Dayjs | null;
        projectId: string;
    }) => {
        if (this.props.projectId !== projectId) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            return;
        }
        const { updatePersonalTaskItem, changPersonalTaskOrder, timeBlocks, userData } = this.props;
        // 先更新时间，保证流畅性
        updatePersonalTaskItem(source.droppableId, {
            ...task,
            endTime: newTime ? newTime.valueOf().toString() : '',
            workStopTime: newTime,
        });
        // 修正source的index,详情页改变时间会找不到index
        if (source.taskId) {
            const index = this.findIndexInTimeBlock(source.droppableId, source.taskId);
            if (index > -1) {
                source.index = index;
            } else {
                // 如果上面源时间块找不到，则有可能是临时区域的位置，所以修正一下
                const index2 = this.findIndexInTimeBlock(
                    TimeClassifyEnum.Temporary.toString(),
                    source.taskId
                );
                if (index2 > -1) {
                    source.droppableId = TimeClassifyEnum.Temporary.toString();
                    source.index = index2;
                } else {
                    // 如果还是没找到任务，直接返回
                    console.log('任务排序异常，未在源位置找到该任务');
                    return;
                }
            }
        }
        // 跨区域拖动,但不更新数据
        const newResult = move(timeBlocks, source, destination);

        const { front, after } = findFrontAndAfter(newResult, source, destination);
        const timeClassify = Number(destination.droppableId);
        const timeRange = generateTimeRange(timeClassify);

        // 发送请求更新时间和排序
        taskSortUpdate({
            front,
            after,
            ...timeRange,
            moveTaskId: task.taskId,
            endTime: newTime ? newTime.valueOf().toString() : '-1',
            projectId: this.props.projectId,
        }).then(([result, err]) => {
            if (err) {
                // 报错则回退任务状态，此处不传时间块，全列表查找更新
                updatePersonalTaskItem('', {
                    ...task,
                });
                message.error(err as string);
                return;
            }
            changPersonalTaskOrder(
                updateTaskInTimeBlock(newResult, {
                    ...task,
                    endTime: newTime ? newTime.valueOf().toString() : '',
                    workStopTime: newTime,
                })
            );
            if (!this.props.projectId && task.projectId) {
                WorkEvent.emit('reloadTimeBlock', {
                    timeClassifys: [Number(source.droppableId), Number(destination.droppableId)],
                    projectId: task.projectId,
                });
            }
            if (this.props.projectId) {
                const header = findSelfInExecutors(task.owners, userData);
                const self = findSelfInExecutors(task.executors, userData);
                if (header || self) {
                    WorkEvent.emit('reloadTimeBlock', {
                        timeClassifys: [
                            Number(source.droppableId),
                            Number(destination.droppableId),
                        ],
                        projectId: '',
                    });
                }
            }
        });
    };

    /**
     * 处理由于状态变化导致的变更
     */
    handleMoveByTaskStatusChange = ({
        source,
        destination,
        task,
        taskStatus,
        processStatus,
        self,
        projectId,
    }: {
        source: any;
        destination: any;
        task: TaskModel;
        taskStatus: number;
        processStatus: number;
        self?: WorkUserInfo;
        projectId: string;
    }) => {
        if (projectId !== this.props.projectId) {
            return;
        }
        const { updatePersonalTaskItem, changPersonalTaskOrder, timeBlocks } = this.props;
        const newTask = { ...task };
        if (self && processStatus) {
            const executors = updateExecutorInTask(task.executors || [], {
                ...self,
                processStatus,
            });
            newTask.executors = executors;
        }
        if (taskStatus) {
            newTask.taskStatus = taskStatus;
        }
        // 先更新状态，保证流畅性
        updatePersonalTaskItem(source.droppableId, newTask);
        this.queue.push({ source, destination, task, taskStatus, processStatus, self, newTask });
        this.handleQueue();
    };

    handleQueue = async () => {
        if (this.queue.length === 0) {
            this.queueRuning = false;
            return;
        }
        if (this.queueRuning === true) {
            return;
        }
        this.queueRuning = true;

        setTimeout(() => {
            const {
                updatePersonalTaskItem,
                changPersonalTaskOrder,
                timeBlocks,
                userData,
            } = this.props;
            const opt = this.queue.shift();
            if (!opt) {
                message.error('状态变更逻辑执行错误，请分析复现步骤并联系 张鹏程');
                this.queueRuning = false;
                return;
            }
            const { source, destination, task, taskStatus, processStatus, self, newTask } = opt;
            // 修正source的index,快速点击多个任务的完成会导致index信息错误
            if (source.taskId) {
                const index = this.findIndexInTimeBlock(source.droppableId, source.taskId);
                if (index > -1) {
                    source.index = index;
                } else {
                    // 如果上面源时间块找不到，则有可能是临时区域的位置，所以修正一下
                    const index2 = this.findIndexInTimeBlock(
                        TimeClassifyEnum.Temporary.toString(),
                        source.taskId
                    );
                    if (index2 > -1) {
                        source.droppableId = TimeClassifyEnum.Temporary.toString();
                        source.index = index2;
                    } else {
                        // 如果还是没找到任务，直接返回
                        console.log('任务排序异常，未在源位置找到该任务');
                        return;
                    }
                }
            }
            // 跨区域拖动,但不更新数据
            const newResult = move(timeBlocks, source, destination);
            const { front, after } = findFrontAndAfter(newResult, source, destination);
            const timeClassify = Number(destination.droppableId);
            const timeRange = generateTimeRange(timeClassify);
            // 发送请求更新状态和排序
            updateTaskProcess({
                front,
                after,
                ...timeRange,
                taskId: task.taskId,
                taskStatus,
                processStatus,
            }).then(([result, err]) => {
                if (err) {
                    this.queueRuning = false;
                    updatePersonalTaskItem('', {
                        ...task,
                    });
                    message.error(err as string);
                    this.handleQueue(); // 报错之后 执行下一条
                    return;
                }
                changPersonalTaskOrder(updateTaskInTimeBlock(newResult, newTask));
                this.queueRuning = false;
                if (!this.props.projectId && task.projectId) {
                    WorkEvent.emit('reloadTimeBlock', {
                        timeClassifys: [
                            Number(source.droppableId),
                            Number(destination.droppableId),
                        ],
                        projectId: task.projectId,
                    });
                }
                if (this.props.projectId) {
                    const header = findSelfInExecutors(task.owners, userData);
                    const self = findSelfInExecutors(task.executors, userData);
                    if (header || self) {
                        WorkEvent.emit('reloadTimeBlock', {
                            timeClassifys: [
                                Number(source.droppableId),
                                Number(destination.droppableId),
                            ],
                            projectId: '',
                        });
                    }
                }
                // 子任务完成或取消，重置父任务的完成进度
                if (task.parentTaskId) {
                    this.resetParentTaskProgress({
                        parentTaskId: task.parentTaskId,
                        taskId: task.taskId,
                        taskStatus,
                    });
                }
                this.handleQueue();
            });
        }, 500);
    };

    /**
     * 重置一下父任务的进度完成状态
     * @param param0
     */
    resetParentTaskProgress = ({
        parentTaskId,
        taskId,
        taskStatus,
    }: {
        parentTaskId: string;
        taskId: string;
        taskStatus: number;
    }) => {
        const tempTask = findPersonalTaskInTimeBlock(this.props.timeBlocks, parentTaskId);
        if (!tempTask) {
            return;
        }
        const childTasks = tempTask.childWorks || [];
        const index = childTasks.findIndex((t) => t.taskId === taskId);
        if (index === -1) {
            return;
        }
        const childTask = childTasks[index];
        childTasks.splice(index, 1, { ...childTask, taskStatus });
        WorkEvent.emit('updatePersonalTaskItem', {
            task: { ...tempTask, childWorks: [...childTasks] },
        });
    };

    findIndexInTimeBlock(timeBlockId: string, taskId: string) {
        const { timeBlocks } = this.props;
        const timeBlock = timeBlocks[Number(timeBlockId)];
        const index = timeBlock.list.findIndex((l) => l.taskId === taskId);
        return index;
    }

    handleThrottleSync = () => {
        const arr = Array.from(this.reloadSet);
        this.reloadSet = new Set();
        this.handleReloadList({ timeClassifys: arr, projectId: this.props.projectId });
    };

    handleReloadList = ({
        timeClassifys,
        projectId = '',
    }: {
        timeClassifys: TimeClassifyEnum[];
        projectId: string;
    }) => {
        const { timeBlocks, getMyTasksByTime, taskClassify, priorityTypes } = this.props;
        if (projectId !== this.props.projectId) {
            return;
        }
        timeClassifys.forEach((timeClassify) => {
            if (timeClassify === 0) {
                // eslint-disable-next-line no-param-reassign
                timeClassify = TimeClassifyEnum.LaterOrUntime;
            }
            const timeBlock = timeBlocks[timeClassify];
            const { list } = timeBlock;
            const result = generateTimeRange(timeClassify);
            getMyTasksByTime({
                ...result,
                offset: 0,
                limit: 10,
                timeBlockId: timeClassify.toString(),
                projectId,
                taskClassify,
                priorityTypes,
            });
        });
    };

    render() {
        return null;
    }
}

export default WorkHandle;
