import 'url-search-params-polyfill';
import Axios, { AxiosError } from 'axios';
import TokenProvider from './TokenProvider';
import Janus, { JanusStage } from '@jd/jzb.jdee-janus';
import { getAppId } from '@/utils';
import { Modal } from 'antd';
import i18n from '@/i18n';
import { postMessageToParent } from '@/utils/messages';
import bus from '@/utils/bus';
import { BusinessToken, GetBusinessTokenResp, OpenChat, Token } from './Types';
import { getChatToFromSearchParams } from './utils';

import Logger from '@/utils/logger';
import cache from '@/utils/cache';
import EventChannel from '@jd/jdee.im.sdk/lib/es/core/channel/EventChannel';
import PostMessageEmitter from '@jd/jdee.im.sdk/lib/es/client/channel/emitter/PostMessageEmitter';

const log = Logger.getLogger('OpenTokenProvider');

export const AUTH_HOST = 'https://me.jdcloud.com';

export interface ExchangeTokenReq {
    clientId: string;
    mapAccountToken: string;
}

export interface ExchangeToken {
    accessToken: string; //	ME 行业版的 accessToken
    accessTokenExpireIn: number; // accessToken 有效时长
    refreshToken: string; //	当 accessToken 过期时，可以用 refreshToken 去换取新的 accessToken + refreshToken（当业务方为客户端时，这个值不为空）
    refreshTokenExpireIn: number; //	appRefreshToken 有效时长（当业务方为客户端时，这个值不为空）
    expand: string; //	业务方自定义的拓展字段，
}

export interface ExchangeTokenResp {
    data: ExchangeToken | null;
    code: string;
    msg: string;
}

export interface ExchangeTeamIdResp {
    msg: string;
    code: number | string;
    data: { teamId: string | null };
}

export interface ExchangeTeamIdAndUserIdResp {
    msg: string;
    code: number | string;
    data: { teamId: string | null; userId: string | null; app: string | null };
}

export interface GetBusinessTokenOptions {
    clientId: string;
}

export default class extends TokenProvider<GetBusinessTokenOptions> {
    // accessToken
    private authHost: string;
    private clientId: string;
    private appKey: string;
    private mapAccountToken: string;
    private openTeamId: string;
    private janus: Janus;
    private chatTo?: OpenChat;
    private accessToken = '';
    private teamId = '';
    constructor() {
        super();
        const { REACT_APP_JANUS_HOST = '', REACT_APP_JANUS_STAGE = 'PROD' } = process.env;
        this.janus = new Janus({
            appId: getAppId() || '',
            host: REACT_APP_JANUS_HOST,
            stage: (REACT_APP_JANUS_STAGE as unknown) as JanusStage,
        });
        const searchParam = new URLSearchParams(window.location.search);
        this.clientId = (searchParam.get('clientId') || '').trim();
        // 设置clientId
        cache.clientId = this.clientId;
        this.appKey = (searchParam.get('appKey') || '').trim();
        this.mapAccountToken = (searchParam.get('mapAccountToken') || '').trim();
        this.openTeamId = (searchParam.get('openTeamId') || '').trim();
        this.authHost =
            (searchParam.get('authHost') || '').trim() || window.location.origin || AUTH_HOST;
        this.bindGlobalEvents();
        this.chatTo = getChatToFromSearchParams(searchParam);
        // 增加postMessageEmitter
        cache.channel = new EventChannel(new PostMessageEmitter());
    }

    // eslint-disable-next-line complexity
    async token(): Promise<Token> {
        if (this.clientId && this.mapAccountToken && this.openTeamId) {
            try {
                const token = await this.exchangeEpaasToken();
                const teamId = await this.exchangeEpaasTeamId({
                    'x-token': token.accessToken,
                });
                if (!teamId) {
                    throw this.buildError('OpenedTeamIdError', 'open team id errror');
                }
                // 设置accessToken
                this.accessToken = token.accessToken;
                this.teamId = teamId;
                const businessToken = await this.getBusinessToken({ clientId: this.clientId });
                let chatTo: OpenChat | undefined;
                if (this.chatTo) {
                    chatTo = await this.getChatTo(this.chatTo, {
                        'x-token': token.accessToken,
                        'x-team-id': teamId,
                    });
                }
                // 返回认证结果
                const rs: Token = {
                    token: {
                        focusToken: token.accessToken,
                        focusTeamId: teamId,
                        focusClient: 'web',
                    },
                    deviceCode: '',
                    ...businessToken,
                };
                if (chatTo) {
                    if (chatTo.type === 'group') {
                        rs.gid = chatTo.gid;
                    } else if (chatTo.type === 'single') {
                        rs.uid = {
                            pin: chatTo.userId,
                            teamId: chatTo.teamId,
                            app: chatTo.app || 'me',
                        };
                    }
                }
                return rs;
            } catch (e) {
                if (e.name === 'TokenError') {
                    // window.location.href = process.env.REACT_APP_CIFTIS_LOGIN_URL || '';
                } else if (e.name === 'TargetError') {
                    this.modal({ content: i18n.t('target_account_error', { ns: 'ciftis' }) });
                } else if (e.name === 'PermissionError') {
                    this.modal({ content: i18n.t('user_no_access_right', { ns: 'ciftis' }) });
                } else if (e.name === 'OpenedTeamIdError') {
                    this.modal({ content: 'OpenTeamIdError' });
                } else if (e.name === 'MapAccountTokenError') {
                    this.modal({ content: e.message || 'MapAccountTokenError' });
                } else {
                    // display errors
                    console.error('auth error', e);
                }
                return new Promise((resolve) => {});
            }
        } else {
            // window.location.href = process.env.REACT_APP_CIFTIS_LOGIN_URL || '';
        }
        return new Promise((resolve) => {});
    }
    failed(url: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    logout(): Promise<void> {
        postMessageToParent({ event: 'logout' });
        this.modal({ title: '您已登出' });
        return new Promise((resolve) => {});
    }

    /**
     * 获取businessToken
     * @param opitons
     */
    protected async getBusinessToken(options: GetBusinessTokenOptions): Promise<BusinessToken> {
        const { clientId } = options;
        // https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/config/test/business-token.json
        const {
            REACT_APP_CONFIG_BASE = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
            REACT_APP_CONFIG_ENV = 'prod',
        } = process.env;
        const d = {
            autoAppendBusinessToken: false,
            businessToken: '',
        };
        if (clientId) {
            try {
                const res = await Axios.get<GetBusinessTokenResp>(
                    `${REACT_APP_CONFIG_BASE}/config/${REACT_APP_CONFIG_ENV}/business-token.json?d=${Date.now()}`
                );
                if (res.status === 200) {
                    const data = res.data;
                    if (data.errorCode === '0' && data.content) {
                        return data.content[clientId] || d;
                    }
                }
            } catch (error) {
                console.error('OpenTokenProvider#getBusinessToken -> err', error);
            }
        }
        return d;
    }

    private exchangeEpaasToken = async (): Promise<ExchangeToken> => {
        const rs = await Axios.post<ExchangeTokenResp>(
            `${this.authHost}/passport/api/exchange/map/account/token`,
            { mapAccountToken: this.mapAccountToken, clientId: this.clientId }
        ).catch((e: AxiosError) => {
            if (e.response) {
                if (e.response.status === 401) {
                    throw this.buildError('TokenError', e.message);
                }
            }
            if (e.code) {
                throw this.buildError('PermissionError', `${e.code}-${e.message}`);
            }
            throw e;
        });
        const body = rs.data;
        if (!body.data) {
            throw this.buildError('MapAccountTokenError', body.msg);
        }
        return body.data;
        // return rs.data.data;
    };

    private exchangeEpaasTeamId = async (
        headers: Record<string, string> = {}
    ): Promise<string | null> => {
        const rs = await this.janus
            .fetch<ExchangeTeamIdResp>({
                api: 'eopen.getTeamId',
                method: 'POST',
                body: { openTeamId: this.openTeamId },
                headers: {
                    ...headers,
                    'x-client': 'WEB',
                },
            })
            .catch((e: AxiosError) => {
                if (e.response && e.response.status === 401) {
                    throw this.buildError('TokenError', e.message);
                }
                throw e;
            });
        if (rs.code !== 0 && rs.code !== '0') {
            throw this.buildError('OpenedTeamIdError', 'open team id errror');
        }
        return rs.data.teamId;
    };

    private async getChatTo(
        chatTo: OpenChat,
        headers: Record<string, string>
    ): Promise<OpenChat | undefined> {
        if (chatTo.type === 'group') {
            return chatTo;
        }
        if (chatTo.type === 'single') {
            try {
                const rs = await this.janus
                    .fetch<ExchangeTeamIdAndUserIdResp>({
                        api: 'eopen.exchangeTeamIdAndUserId',
                        method: 'POST',
                        body: {
                            openTeamId: chatTo.teamId,
                            openUserId: chatTo.userId,
                            clientId: this.appKey || this.clientId || '',
                        },
                        headers: {
                            ...headers,
                            'x-client': 'WEB',
                        },
                    })
                    .catch((e: AxiosError) => {
                        if (e.response && e.response.status === 401) {
                            throw this.buildError('TokenError', e.message);
                        }
                        throw e;
                    });
                if (rs.code !== 0 && rs.code !== '0') {
                    log.error('eopen.exchangeTeamIdAndUserId erros =>', rs);
                    return;
                }
                //
                const data = rs.data;
                if (data.userId && data.teamId) {
                    return {
                        type: 'single',
                        userId: data.userId,
                        teamId: data.teamId,
                        app: data.app || chatTo.app,
                    };
                }
            } catch (error) {
                log.error('eopen.exchangeTeamIdAndUserId erros =>', error);
            }
        }
    }

    private modal(options: { title?: string; content?: string }) {
        Modal.warning({
            ...options,
            onOk: (x) => false,
        });
    }

    private buildError(name: string, message = ''): Error {
        const err = new Error(message);
        err.name = name;
        return err;
    }

    private bindGlobalEvents() {
        bus.on('login:open', (msg) => {
            this.clientId = msg.clientId;
            this.mapAccountToken = msg.mapAccountToken;
            this.openTeamId = msg.openTeamId;
        });
        bus.on('chat:open:open', (data: OpenChat) => {
            this.getChatTo(data, { 'x-token': this.accessToken, 'x-team-id': this.teamId })
                .then((chatTo: OpenChat | undefined) => {
                    if (chatTo) {
                        this.emitChatTo(chatTo);
                    }
                })
                .catch((e) => log.error('open chat error', e));
        });
    }
}
