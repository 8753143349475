import React, { ReactNode, useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, message, Modal } from 'antd';
import {
    folderTypeEnum,
    getJoySpaceDelPermission,
    getJoySpaceReadOnlyPermission,
    joyspacePermissionType,
} from '../../config/permissions.config';
import Tabs from './baseComponents/Tab';
import { configTabs } from './configTabs';
import './index.less';
import { FileType, SharesMembers, SharesMembersType } from '../../types';
import { getPageSize } from '../../components/SelectedMembers/config';
import Api, { getUserInfo } from '../../api';
import openAddUserModal from '../AddUserMembers';
import PermissionModal from '../../components/PermissionModal';
import { getPermissionText } from './config';
import { setPrmission, removePrmission } from '../../api/permissions';
import events from '../../context/events';
interface IProps {
    id: string;
    type: FileType;
    // title: ReactNode;
    permissionType: joyspacePermissionType;
    title?: string;
    folderType?: folderTypeEnum;
    onOk?: (list: any) => void;
    removeNode?: () => void;
}

const parames: SharesMembers = {
    pageId: '',
    fileType: FileType.Page,
    start: getPageSize().pageIndex || 1,
    length: getPageSize().pageSize || 30,
    memberType: SharesMembersType.ALL,
};

const AddMembers = (props: IProps) => {
    const {
        id,
        type,
        folderType,
        permissionType = getJoySpaceReadOnlyPermission(),
        removeNode,
        title = '管理协作者',
    } = props;
    // console.log(id, 'id============?');
    const [visibleModal, setVisibleModal] = useState(true);
    const [membersList, setMembersList] = useState([]);
    const [tabs, setTabs] = useState(configTabs());
    const [myPermissionType, setMyPermissionType] = useState(permissionType);
    const [loading, setLoading] = useState(true);
    const [isLoadMore, setLoadMore] = useState(false);

    const [user, setUser] = useState<any>();
    // 获取协作人数量
    const getSharesCount = useCallback(
        (pageId = '') => {
            Api.permissions.sharesCount(pageId).then((res: any) => {
                const _tabs = configTabs().map((item: any, index: number) => {
                    item.text = `${item.text} (${res[index] || 0})`;
                    return item;
                });
                setTabs(_tabs);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tabs]
    );
    useEffect(() => {
        getSharesCount(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // 获取协作人列表
    const getUserPermissionList = useCallback(
        (_parames: SharesMembers, userInfo: any = {}) => {
            Api.permissions
                .sharesMembers(_parames)
                .then((res: any) => {
                    // console.log(res, 'resresresresresresresresres');
                    if (res === undefined) {
                        message.error('您暂无权限进行此操作');
                        return;
                    }
                    let result: any = res
                        ? res.members.map((item: any) => {
                              item.isLoginUser =
                                  item.memberType === SharesMembersType.CONTACT &&
                                  item.memberId === userInfo.id;
                              let desc = '';
                              if (item.memberType === SharesMembersType.CONTACT) {
                                  const positionName = item.user.position_name
                                      ? item.user.position_name
                                      : '暂无填写';
                                  desc = `${item.user.org_name}-${positionName}`;
                              } else if (item.memberType === SharesMembersType.DEPARTMENT) {
                                  desc = item.fullName;
                              } else {
                                  desc = `${item.memberCount}人`;
                              }
                              //   console.log(desc, 'descdescdescdescdescdesc');
                              item.desc = desc;
                              return item;
                          })
                        : [];
                    if (_parames.start !== 1) {
                        result = [].concat([...membersList], result || []);
                    }
                    // const total = res ? res.total : 0;
                    setLoadMore(res?.hasMore);
                    setMembersList(result);
                })
                .finally(() => {
                    setLoading(false);
                })
                .catch((err) => {
                    message.error(err);
                });
        },
        [membersList]
    );
    const getUser = useCallback(async () => {
        const info = await getUserInfo();
        setUser(info);
        getUserPermissionList(parames, info);
    }, [getUserPermissionList]);
    useEffect(() => {
        parames.start = 1;
        parames.pageId = id;
        parames.fileType = type;
        setLoading(true);
        getUser();
        return () => {
            parames.memberType = SharesMembersType.ALL;
            parames.start = 1;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const updata = useCallback(() => {
        getSharesCount(parames.pageId);
        parames.start = 1;
        getUserPermissionList(parames, user);
    }, [getSharesCount, getUserPermissionList, user]);
    useEffect(() => {
        events.on('joyspace:updata', updata);
        return () => {
            events.off('joyspace:updata', updata);
        };
    }, [updata]);
    const onButtom = useCallback(() => {
        if (!isLoadMore) {
            return;
        }
        parames.start++;
        getUserPermissionList(parames, user);
    }, [isLoadMore, getUserPermissionList, user]);
    const onChangeTab = useCallback(
        (val: any) => {
            parames.start = 1;
            parames.memberType = val;
            setLoadMore(false);
            setLoading(true);
            setMembersList([]);
            getUserPermissionList(parames, user);
        },
        [getUserPermissionList, user]
    );
    const openAddMembersModal = () => {
        openAddUserModal({
            type: type,
            id: id,
            isModalVisible: true,
            title: '添加协作者',
            permissionType,
            folderType,
            isShowManageBtn: false,
            // isOpenFolder,
            onOk: () => {
                parames.start = 1;
                parames.pageId = id;
                parames.fileType = type;
                getUserPermissionList(parames);
            },
        });
    };
    const handleMenuClick = useCallback(
        (val: any, id) => {
            let list: any = JSON.parse(JSON.stringify(membersList));
            const idx = list.findIndex((item: any) => item.id === id);
            // 移除
            if (idx === -1) {
                return;
            }
            const item: any = list[idx];
            if (val === getJoySpaceDelPermission()) {
                const text = item.isLoginUser
                    ? '确定删除自己吗？操作后，您将失去对文件的任何权限。'
                    : '确定删除该协作者吗？';
                PermissionModal({
                    text,
                    visibleModal: true,
                    onOk: () => {
                        removePrmission(id).then((res) => {
                            list.splice(idx, 1);
                            setMembersList(list);
                            getSharesCount(parames.pageId);
                            events.emit('joyspace:updata_number');
                            message.success('删除成功');
                            if (item.isLoginUser) {
                                setMyPermissionType(joyspacePermissionType.ReadNothing);
                            }
                        });
                    },
                });
            } else if (item.isLoginUser && val > myPermissionType) {
                const text = `操作后，您将失去对文件的“${getPermissionText(
                    myPermissionType
                )}”权限。确定变更权限吗？`;
                PermissionModal({
                    text,
                    visibleModal: true,
                    onOk: () => {
                        setPrmission(item.id, val).then((res) => {
                            item.permissionType = val;
                            list.splice(idx, 1, { ...item });
                            setMyPermissionType(val);
                            setMembersList(list);
                            message.success('设置成功');
                        });
                    },
                });
            } else {
                setPrmission(item.id, val).then((res) => {
                    item.permissionType = val;
                    list.splice(idx, 1, { ...item });
                    setMembersList(list);
                    message.success('设置成功');
                });
            }
        },
        [membersList, myPermissionType, getSharesCount]
    );
    return (
        <Modal
            className="add-mebers-modal"
            width={480}
            visible={visibleModal}
            destroyOnClose={true}
            title={title}
            onCancel={() => {
                setVisibleModal(false);
                removeNode?.();
                events.emit('joyspace:updata_number');
            }}
            footer={
                <Button
                    type="primary"
                    disabled={myPermissionType === joyspacePermissionType.ReadNothing}
                    onClick={openAddMembersModal}
                >
                    添加协作者
                </Button>
            }
        >
            <Tabs
                list={membersList}
                defaultActiveKey={tabs[0].type}
                config={tabs}
                permissionType={myPermissionType}
                type={type}
                onChangeTab={onChangeTab}
                handleMenuClick={handleMenuClick}
                pagination={true}
                loading={loading}
                isLoadMore={isLoadMore}
                onButtom={onButtom}
            />
        </Modal>
    );
};

export default function addMembersModal(props: IProps) {
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    function removeNode() {
        if (ele) {
            ReactDOM.unmountComponentAtNode(ele);
            ele.remove();
        }
    }
    ReactDOM.render(<AddMembers {...{ ...props }} removeNode={removeNode} />, ele);
}
