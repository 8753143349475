import { FocusSDK, getClientType } from '@/utils';

export function getStatus() {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            reject(new Error('超时'));
        }, 10000);
        function handle(status: any) {
            clearTimeout(timeout);
            resolve(status);
        }
        FocusSDK.once('jdmeet:getStatusCb', handle);
        FocusSDK.sendIpcMessage('jdmeet.getStatus');
    });
}
