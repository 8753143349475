import * as React from 'react';
import { Popover } from 'antd';
import './index.less';
import debounce from 'lodash/debounce';
import JEditor from '../..';
import { IRect } from '@/types/chat/Base';

export interface ICursorPopoverProps {
    content: React.ReactNode;
    editor: JEditor;
    withoutPopover?: boolean;
    onKeyDown?: (key: string) => void;
    onTextInput?: (text: string) => void;
    onVisibleChange?: (visible: boolean) => void;
    onPrefixKeyPressed?: () => void;
    prefix?: string;
    bindings: any;
}

export interface ICursorPopoverState {
    rect: any;
    range: any;
    textInput: string;
}

// TODO 关于 popover 显示/关闭 的逻辑，这里处理的稍有简陋，应该是将 @ 做成 embed 元素，通过判断光标是否处在元素内来判断是否显示
export default class CursorPopover extends React.Component<
    ICursorPopoverProps,
    ICursorPopoverState
> {
    anchorRef: any;
    constructor(props: any) {
        super(props);
        this.onPrefixKeyPressed = debounce(this.onPrefixKeyPressed, 50);
        this.state = {
            rect: null,
            range: null,
            textInput: '',
        };
    }
    componentDidMount() {
        const { editor } = this.props;
        if (!editor) {
            return;
        }
        const quill = editor.getQuill();
        if (!quill) {
            return;
        }
        if (editor) {
            quill.on('text-change', this.onEditorTextChange);
            quill.on('text-change', this.onEditorChange);
            editor.events.on('editor-selection-change', this.onEditorSelectionChange);
            editor.events.on('editor-keyDown', this.onEditorKeyDown);
            editor.events.on('close-mention', this.hide);
        }
    }
    componentWillUnmount() {
        const { editor } = this.props;
        if (!editor) {
            return;
        }
        const quill = editor.getQuill();
        if (!quill) return;
        if (editor) {
            quill.off('text-change', this.onEditorChange);
            quill.off('text-change', this.onEditorTextChange);
            editor.events.off('editor-selection-change', this.onEditorSelectionChange);
            editor.events.off('editor-keyDown', this.onEditorKeyDown);
            editor.events.off('close-mention', this.hide);
        }
    }

    onEditorTextChange = (delta: any, oldDelta: any, source: any) => {
        if (source !== 'user') {
            return;
        }
        if (delta && delta.ops.length !== 0) {
            delta.ops.some((op: any) => {
                if (op.insert && this.props.bindings.find((b: any) => b === op.insert)) {
                    this.onPrefixKeyPressed();
                    return true;
                }
                return false;
            });
        }
    };

    onAtKeyDown = () => {
        this.onPrefixKeyPressed();
        return true;
    };

    show(rect: IRect, range: any) {
        this.setState({
            rect,
            range,
        });
        this.autoDisableEditorEnterKey();
        if (this.props.onVisibleChange) {
            this.props.onVisibleChange(true);
        }
    }
    hide = () => {
        this.setState(
            {
                rect: null,
                range: null,
                textInput: '',
            },
            () => {
                this.autoDisableEditorEnterKey();
            }
        );

        if (this.props.onVisibleChange) {
            this.props.onVisibleChange(false);
        }
        const { editor } = this.props;
        if (!editor) return;
        editor.changeAtActive(false);
    };
    onEditorSelectionChange = () => {
        setTimeout(() => {
            const { rect } = this.state;
            if (!rect) return;
            const { editor } = this.props;
            if (!editor) return;
            const range = editor.getSelection();
            if (!range) {
                // 应该不会到这里
                return;
            }
            if (range.index < this.state.range.index) {
                this.hide();
                return;
            }
            const quill = this.props.editor.getQuill();
            if (!quill) return;
            const textInput = editor.getText(
                this.state.range.index,
                range.index - this.state.range.index
            );
            if (textInput.length > this.state.textInput.length) {
                this.hide();
                return;
            }
        }, 30);
    };

    onEditorChange = (delta: any, oldDelta: any, source: any) => {
        const { editor } = this.props;
        const { rect } = this.state;
        if (!editor) return;

        // 按键没有触发时不执行检测
        if (!rect) {
            return;
        }

        const range = editor.getSelection();
        if (!range) {
            // 应该不会到这里
            return;
        }
        if (range.index < this.state.range.index) {
            this.hide();
            return;
        }

        const textInput = editor.getText(
            this.state.range.index,
            range.index - this.state.range.index
        );
        this.setState({
            textInput,
        });

        if (this.props.onTextInput) {
            this.props.onTextInput(textInput);
        }
        if (/\s{2}$/.test(textInput)) {
            this.hide();
            return;
        }
    };
    autoDisableEditorEnterKey() {
        const { rect } = this.state;
        const keyboardModule = this.props.editor.getModule('keyboard');
        if (!keyboardModule) return;
        const { bindings } = keyboardModule;
        if (!bindings[13]) return;
        const binding = bindings[13][0];
        if (!binding) return;
        if (rect && binding.handler !== this.handleEnterKey) {
            bindings[13].unshift({ key: 'Enter', handler: this.stopJeditorDefault });
        }
        if (!rect && binding.handler === this.stopJeditorDefault) {
            bindings[13].shift();
        }
    }

    /**
     * 阻止编辑器默认插入回车行为
     */
    stopJeditorDefault = () => {
        return false;
    };

    handleEnterKey = () => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown('Enter');
        }
        this.hide();
    };

    onPrefixKeyPressed() {
        const { editor } = this.props;
        if (!editor) return;
        const rects = editor.getSelectionRects();
        if (!rects.length) {
            this.hide();
            return;
        }
        const rect = rects[0];
        const range = editor.getSelection();
        if (!range) {
            // 应该不会到这里
            return;
        }
        this.show(rect, range);
        editor.changeAtActive(true);
        if (this.props.onPrefixKeyPressed) {
            this.props.onPrefixKeyPressed();
        }
    }

    onEditorArrowKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        const { rect } = this.state;
        if (!rect) {
            return;
        }
        if (!this.props.onKeyDown) return;
        if (['Enter', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) < 0) return;
        e.preventDefault();
        e.stopPropagation();
        switch (e.key) {
            case 'ArrowUp':
                this.props.onKeyDown(e.key);
                break;
            case 'ArrowDown':
                this.props.onKeyDown(e.key);
                break;
            case 'Enter':
                // this.props.onKeyDown(e.key);
                this.handleEnterKey();
                break;
            default:
        }
    }
    onEditorKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
            this.hide();
            return;
        }
        e.stopPropagation();
        this.onEditorArrowKeyDown(e);

        // this.onEditorKeyDownCallback();
    };
    render() {
        const { rect } = this.state;
        const { withoutPopover } = this.props;
        const { editor } = this.props;
        if (withoutPopover) {
            if (!rect) {
                return null;
            }
            return this.props.content;
        }
        return (
            <div className="mention">
                <Popover
                    placement="topLeft"
                    visible={!!rect}
                    content={this.props.content}
                    getPopupContainer={() => editor.getContainer() || document.body}
                >
                    <div
                        ref={(ref) => {
                            this.anchorRef = ref;
                        }}
                        className="mention-anchor"
                        style={
                            rect
                                ? {
                                      top: rect.top,
                                      height: rect.height,
                                      left: rect.left,
                                  }
                                : { visibility: 'hidden' }
                        }
                    />
                </Popover>
            </div>
        );
    }
}
