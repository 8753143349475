const config = {
    szy: '数正云',
};

const {
    REACT_APP_NAME: APP_NAME,
    REACT_APP_DOWNLOAD_URL: DOWNLOAD_URL,
    REACT_APP_NAME_ENG,
    REACT_APP_LOGO_URL: ICON,
    REACT_APP_LOGO_URL: FAV_ICO,
    REACT_APP_UPGRADE_SUCCESS: UPGRADE_SUCCESS,
    REACT_APP_UPGRADE_FAILED: UPGRADE_FAILED,
    REACT_APP_LOG_DOMAN: LOG_DOMAN,
    REACT_APP_KEFU_TEL: TELSERVICE = '',
    REACT_APP_LOGIN_BGIMG: LOGIN_BGIMG = '',
    REACT_APP_SERVICE,
    REACT_APP_privacy,
    REACT_APP_MODAL_URL: MODAL_URL = '',
    REACT_APP_NAME: productionName,
    REACT_APP_LEFT_TITLE: leftTitle,
    REACT_APP_LEFT_DESC: leftDesc,
    REACT_APP_DOWNLOAD_COMPANY_NAME: companyName = ''
} = process.env;
// const isSzy = REACT_APP_NAME_ENG === 'szy';

export {
    APP_NAME,
    DOWNLOAD_URL,
    FAV_ICO,
    ICON,
    UPGRADE_SUCCESS,
    UPGRADE_FAILED,
    LOG_DOMAN,
    TELSERVICE,
    LOGIN_BGIMG,
    REACT_APP_SERVICE,
    REACT_APP_privacy,
    MODAL_URL,
    leftDesc,
    leftTitle,
    productionName,
    REACT_APP_NAME_ENG,
    companyName
};
