import React, { useState, useCallback, useMemo, useContext } from 'react';
import { changeEgovUrl } from '@/utils/tools';
import GlobalContext from '@/context/GlobalContext';
import IconFont from '@/components/icon';
import icon_search from '@/assets/img/icon_search.png';
import iconDefault from '@/assets/img/default_avatar_1.png';

enum nameType {
    others = 0,
    enOneWord = 1,
    enTwoWords = 2,
    zh = 3,
}

const zhPattern = /[\u4E00-\u9FA5]+/;
const enOneWordPattern = /[A-Za-z0-9]+/;
const enTwoWordsPattern = /[A-Za-z0-9]+[$][A-Za-z0-9]+/;
const defaultSize = 36;

function applyRule(name: string) {
    return defaultRule(name);
}

function defaultRule(name: string) {
    const filteredName = filterName(name);
    let language;
    let str;
    if (zhPattern.test(filteredName)) {
        language = nameType.zh;
    } else if (enOneWordPattern.test(filteredName)) {
        language = nameType.enOneWord;
    } else if (enTwoWordsPattern.test(filteredName)) {
        language = nameType.enTwoWords;
    } else {
        language = nameType.others;
    }
    let strArr;
    switch (language) {
        case nameType.zh:
            str = filteredName[0];
            break;
        case nameType.enTwoWords:
            strArr = filteredName.split(' ');
            str = strArr[0][0] + strArr[1][0];
            break;
        case nameType.enOneWord:
        case nameType.others:
            str = filteredName.substring(0, 2);
            break;
        default:
            break;
    }
    return str;
}

function filterName(name: string) {
    return name
        .trim()
        .split('')
        .filter((char: string) => {
            return zhPattern.test(char) || enOneWordPattern.test(char) || /\s/.test(char);
        })
        .join('');
}

export function Avatar(props: {
    size?: number;
    styleObj?: any;
    className?: string;
    src?: string;
    name?: string;
    defaultImg?: string;
    onClick?: (e?: any) => void;
    fullName?: boolean;
    searchIcon?: boolean;
}): any {
    const { styleObj, src, name, className, onClick, size, fullName, searchIcon, ...other } = props;
    const { isWebpSupported } = useContext(GlobalContext);
    const divAvatarNode = useMemo(() => {
        if (name) {
            const displayName = fullName ? name : name[0];
            // const displayName = fullName ? name : applyRule(name);
            return (
                <div
                    className={`me-avatar ${className || ''}`}
                    style={{
                        width: size || defaultSize,
                        height: size || defaultSize,
                        borderRadius: 6,
                        fontSize: 14,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        outline: 0,
                        ...styleObj,
                    }}
                    onClick={onClick}
                    {...other}
                >
                    {displayName}
                </div>
            );
        }
        if (searchIcon) {
            return (
                <div
                    className={`me-avatar ${className || ''}`}
                    style={{
                        width: size || defaultSize,
                        height: size || defaultSize,
                        borderRadius: 6,
                        fontSize: 16,
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        outline: 0,
                        ...styleObj,
                    }}
                    onClick={onClick}
                    {...other}
                >
                    <img src={icon_search} alt="" width="15px" />
                    {/* <IconFont type="iconviewlarger" style={{ color: '#ffffff' }} /> */}
                </div>
            );
        }
        return null;
    }, [name, searchIcon, fullName, className, size, styleObj, onClick, other]);

    const imgAvatarNode = useMemo(() => {
        let src1 =
            src && (src.includes('downloadAuthn') ? src.replace('downloadAuthn', 'download') : src);
        return (
            <img
                className={`${className} user-card-avatar-remote`}
                style={{
                    width: size || defaultSize,
                    height: size || defaultSize,
                    borderRadius: 6,
                    backgroundSize: 'cover',
                    backgroundColor: '#ddd',
                    overflow: 'hidden',
                    display: 'block',
                    backgroundPositionY: 'center',
                    outline: 0,
                    // backgroundImage: `url(${changeEgovUrl(src1)}${
                    //     !isWebpSupported && (src1 || '').indexOf('?') > -1 ? '&imageType=png' : ''
                    // })`,
                    ...styleObj,
                }}
                src={`${changeEgovUrl(src1)}${
                    !isWebpSupported && (src1 || '').indexOf('?') > -1 ? '&imageType=png' : ''
                }`}
                onClick={onClick}
                onError={(e: any) => {
                    if (e.target) {
                        e.target.src = iconDefault;
                    }
                }}
            />
        );
    }, [className, size, styleObj, src, onClick, isWebpSupported]);
    let filteredSrc = '';
    if (src) {
        let newSrc = changeEgovUrl(src);
        filteredSrc = filterUselessImg(newSrc);
    }
    if (!filteredSrc) {
        return divAvatarNode;
    } else {
        return imgAvatarNode;
    }
}

export function filterUselessImg(imgUrl: string) {
    if (imgUrl.includes('ebook.avatar.getDefaultAvatar')) {
        return '';
    }

    return imgUrl;
}
