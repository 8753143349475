import { firstUpdateTreeList } from '@/components/AddressBook/common';
import BaseService from './BaseService';

/*
appId: "ee"
avatar: "https://eefs.jdcloud.com/res/download/UQHkvYzcT12XANcwvwDWLrjs8.png?appKey=9e92d795a36edea0"
isLeader: "0"
positionName: null
realName: "徐通"
teamId: "mS2CKU8HN3UFxdliJ1AxR"
userId: "1"
*/
export interface EBookDeptUserInfo {
    appId: string;
    avatar: string;
    isLeader: '0' | '1';
    positionName: string;
    realName: string;
    teamId: string;
    userId: string;
    fullPath?: string;
    fullName?: string;
}

interface EBookSubDeptInfo {
    deptId: string;
    teamId: string;
    deptName: string;
    fullName: string;
    fullPath: string;
    parentId: string;
    ebookSubDeptEmplSums: number;
}

export interface EBookDeptInfoRes {
    contactNumber: number;
    deptId: string;
    deptName: string;
    ebookInfoResList: EBookDeptUserInfo[];
    ebookSubDeptInfoList: EBookSubDeptInfo[];
    fullName: string;
    fullPath: string;
    parentId: string;
    teamId: string;
    simpleDeptInfoList: {
        deptId: string;
        deptName: string;
        deptOrder: number;
    }[];
}

export interface DeptCrumbLisInfo {
    deptName: string;
    deptId: string;
}

export function getDeptCrumbList(data: EBookDeptInfoRes | any) {
    const { fullName, fullPath, fullDeptName, fullDeptPath } = data;
    const names = fullName ? fullName?.split('-') : fullDeptName?.split('-');
    const paths = fullPath ? fullPath?.split('/')?.slice(1) : fullDeptPath?.split('/')?.slice(1);
    if (!paths || !paths.length) {
        return [];
    }
    const list = paths
        .map((path: any, i: any) => {
            const name = names[i];
            if (!name) {
                return null;
            }
            return {
                deptName: name,
                deptId: path,
            };
        })
        .filter((item: any) => !!item) as DeptCrumbLisInfo[];
    return list;
    // return dataList(firstUpdateTreeList(list));
}

export function dataList(list: any) {
    let data: any = [];
    // eslint-disable-next-line array-callback-return
    list.some((item: any, index: number) => {
        item.children = list[index + 1] ? [list[index + 1]] : [];
        data.push(item);
    });
    return data.slice(0, 1);
}

class EBookService extends BaseService {
    getEBookDeptInfo(params: { deptId?: string }) {
        return this.fetch<EBookDeptInfoRes>(
            'ebook',
            'getEbookDeptInfo',
            {
                displaySubDept: true,
                ...params,
            },
            {
                ignoreErrMessageAlert: true,
            }
        ).then((data) => {
            const { ebookInfoResList, fullName, fullPath } = data;
            return {
                ...data,
                ebookInfoResList: ebookInfoResList.map((item) => ({
                    ...item,
                    fullName,
                    fullPath,
                })),
            };
        });
        // .catch((e: any) => {
        //   console.error('e', e);
        //   return null;
        // });
    }

    // getUserEbookDetailList(
    //     users: {
    //         appId: string;
    //         userId: string;
    //         teamId: string;
    //     }[]
    // ) {
    //     return this.fetch('ebook', 'getUserEbookDetailList', users);
    // }
}

export default EBookService;
