import { notUseJoySpaceOffline } from '@/components/Joyspace/util';
import { IBaseController } from './BaseController';

export enum Method {
    get = 'GET',
    post = 'POST',
    delete = 'DELETE',
}

/**
 * @params type 请求接口类型 Method
 * @params controller offlineMethod,onlineMethod所在的controler
 * @params offlineMethod 单独获取数据逻辑的接口方法名（controller中实现接口） 无网情况下处理数据的函数
 * @params onlineMethod 单独存储数据逻辑的接口方法名（controller中实现接口） 有网情况下处理数据的函数
 * @params specialOnlineMethod 有网情况下需要对数据特殊处理的函数， 比如需要拼接数据等
 * @params dbKey key的名称
 */
/* eslint max-params: ["error", 10] */
export function pwaAttribute(
    type: Method,
    controller: IBaseController,
    offlineMethod?: string,
    onlineMethod?: string,
    dbKey?: string,
    specialOnlineMethod?: string
) {
    if (notUseJoySpaceOffline()) {
        return () => {};
    }
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        // console.log('this is argssss=======>', offlineMethod, onlineMethod);
        const dbName = dbKey || propertyKey;
        const oldMthod = target[propertyKey];
        descriptor.value = function (...args: any[]) {
            try {
                if (!controller.dbService) {
                    controller.initDb();
                }
                const p = new Promise((resolve, reject) => {
                    if (navigator.onLine) {
                        oldMthod(...args)
                            .then((data: any) => {
                                const result = specialOnlineMethod
                                    ? (controller as any)[specialOnlineMethod]?.(
                                          dbName,
                                          data,
                                          ...args
                                      )
                                    : data;

                                resolve(result);
                                // console.log(controller, 'controllercontrollercontroller');
                                // if (controller.dbService) {
                                if (!onlineMethod) {
                                    switch (type) {
                                        case Method.get:
                                            controller.saveToDB({
                                                _id: dbName,
                                                value: data,
                                            });
                                            break;
                                    }
                                } else {
                                    // console.log('this is argssss', args, propertyKey);
                                    onlineMethod &&
                                        (controller as any)
                                            [onlineMethod](dbName, data, ...args)
                                            .then((data: any) => {
                                                resolve(data);
                                            })
                                            .catch((e: any) => {
                                                reject(e);
                                            });
                                }
                                // }
                            })
                            .catch((e: any) => {
                                reject(e);
                            });
                    } else {
                        if (offlineMethod) {
                            (controller as any)
                                [offlineMethod](dbName, ...args)
                                .then((data: any) => {
                                    resolve(data);
                                })
                                .catch((e: any) => {
                                    reject(e);
                                });
                        } else {
                            controller
                                .getFromDB(dbName)
                                .then((doc: any) => {
                                    if (!doc) {
                                        resolve(doc);
                                    } else {
                                        resolve(doc.value);
                                    }
                                })
                                .catch((e: any) => {
                                    reject(e);
                                });
                        }
                    }
                });
                return p;
            } catch (e) {
                // eslint-disable-next-line no-debugger
                // debugger;
                controller?.initDb();
            }
        };
    };
}
