export enum AudioDevType {
    kAudioPlayoutDevice = 0,
    kAudioRecordDevice = 1,
}

export enum ConferenceStatus {
    eConfStatus_Unknown = -100,
    eConfStatus_OnGoing = 0,
    eConfStatus_End = 1,
    eConfStatus_Will_Begin = 2,
    eConfStatus_Not_Start = 3, // reserve meeting not start status
    eConfStatus_Canceled = 4,
    eConfStatus_enum_last_value,
}

export enum Deskshare_Mode {
    Deskshare_Mode_Share = 0,
    Deskshare_Mode_View = 1,
}

export enum AudioFileFormat {
    kFileFormatWavFile = 1,
    kFileFormatCompressedFile = 2,
    kFileFormatPreencodedFile = 4,
    kFileFormatPcm16kHzFile = 7,
    kFileFormatPcm8kHzFile = 8,
    kFileFormatPcm32kHzFile = 9,
}

export enum ConferenceMode {
    kImmediateConference = 0,
    kReservationConference = 1,
}

export enum ConferenceMediaType {
    kAudioConference = 0,
    kVideoConference = 1,
    kRemoteCtrl = 2,
}

export enum ConferenceType {
    kConferenceSingle = 0, //
    kConferenceMulti = 1,
    kConferenceUnknown = 2,
}

export enum ConferenceControl {
    kConfControlInvite = 0,
    kConfControlKick = 1,
    kConfControlMute = 2,
    kConfControlUnmute = 3,
    kConfControlGiveAdmin = 4,
}

export enum ChangeRoleResultCode {
    kGarResultSuccess = 0,
    kGarResultTimeout = 1,
    kGarResultFailure = 2,
}
export enum InviteResultCode {
    kInvResultSuccess = 0,
    kInvResultTimeout = 1,
    kInvResultFailure = 2,
}

export enum MemberRole {
    kMemberRoleUser = 0,
    kMemberRoleAdmin = 1,
    kMemberOwner = 2,
}

export enum VideoCallStatus {
    kVideoCallStatusClosed = 0,
    kVideoCallStatusOpened = 1,
    kVideoCallStatusInvalid = 9,
}

export enum DesktopViewStatus {
    kDesktopViewStatusClosed = 0,
    kDesktopViewStatusOpened = 1,
    kDesktopViewStatusSignalDisconnected = 2,
    kDesktopViewStatusDataDisconnected = 3,
    kDesktopViewStatusInvalid = 9,
}

export enum DesktopShareStatus {
    kDesktopShareStatusClosed = 0,
    kDesktopShareStatusOpened = 1,
    kDesktopShareStatusSignalDisconnected = 2,
    kDesktopShareStatusDataDisconnected = 3,
    kDesktopShareStatusInvalid = 9,
}

export enum MemberMicStatus {
    kMemberMicClosed = 0,
    kMemberMicOpened = 1,
    kMemberMicInvalid = 9,
}

/**
 * 参会人状态
 */
export enum MemberParticipateStatus {
    kMemberParticipateOut = 0, // 不在会议中
    kMemberParticipateIn = 1, // 在会议中
    kMemberParticipateEntering = 2, // 正在加入
    kMemberParticipatePSTNCalling = 3, // 接听落地电话
    kMemberParticipateBusy = 4, // 繁忙
    kMemberParticipateReject = 5, // 拒绝
    kMemberParticipateAcceptTimeout = 6, // 超时接听
    kMemberParticipateInviting = 7, // 邀请中
    kMemberParticipateRemoveOut = 8, // 移除会议
    kMemberParticipateInvalid = 9, // 无效
}

export enum MemberVoiceStatus {
    kMemberVoiceOn = 0,
    kMemberVoiceOff = 1,
    kMemberVoiceInvalid = 9,
}

/**
 * 语音 OnConferenceLeave错误码定义
 */
export enum CallHangupCause {
    kHangupCauseUnKnown = 0, // 未知错误
    kHangupCausePeerHangup = 1, // 对端挂掉
    kHangupCauseMySelfHangup = 2, // 自己已经挂机
    kHangupCauseCallTimeOut = 3, // 呼叫中因为网络不佳而中断
    kHangupCauseNetworkError = 4, // 因为网络不佳 呼叫发起 失败
    kHangupCauseConfEnd = 5, // 会议已结束
    kHangupCauseOtherDeviceIn = 6, // 本账号的其他终端已经接入(此终端被踢出了)
    kHangupCauseAdminKick = 7, // 管理员将你踢出
    kHangupCauseRecoveryFailure = 8, // 呼叫
    kHangupCauseStartMediaError = 9, // rtc客户端启动媒体失败
    kHangupCauseAsOwnerTerimateConf = 10,
    kHangupCausePeerReject = 11, // 呼叫未接通,对方已拒绝
    kHangupCausePeerAnswerTimeout = 12, // 等待接听超时
    kHangupCauseOtherDeviceReject = 13,

    kHangupCauseDeskShareEnd = 20, // 共享桌面已经结束
    kHangupCauseDeskShareTimeOut = 21, // 左面共享超时结束

    /* http://wiki.im/protocol/v3/voip/  #10.14.137.200 wiki.im */
    kHangupCauseSrv_AuthError = 401, // 注册认证失败
    kHangupCauseSrv_PermissionError = 403, // (已废弃)没有呼叫权限
    kHangupCauseSrv_AlreadyJoined = 409, // 已经加入会议
    kHangupCauseSrv_ConfAlreadyEnd = 410, // 会议已经结束
    kHangupCauseSrv_ConfExpire = 412, // 会议已经过期
    kHangupCauseSrv_ConfUserNotIn = 413, // 用户不在会议中
    kHangupCauseSrv_ConfUserBusy = 414, // 用户繁忙中
    kHangupCauseSrv_ReachMaxLimit = 421, // 超出最大限制
    kHangupCauseSrv_SystemError = 500, // 服务系统出错
    kHangupCauseSrv_ConnectOtherSystemError = 504, // 请求外部系统处理超时
    kHangupCauseSrv_MessageNotSupportError = 505, // 不支持的消息类型
    kHangupCauseSrv_ClientSdkVerNotSupported = 510, // 本端客户端版本太老, 需要升级新版本 server not support this to old client sdk
    kHangupCauseSrv_PeerClientSdkVerNotSupprted = 511, // 对端客户端版本太老,需要升级新版本
    kHangupCauseSrv_PeerClientNotOnline = 462,
    kHangupCauseSrv_PeerClientOSVerNotSupprted = 463,

    // sdk state machine error 700-(state_machine_error_code)
    kHangupCauseStateMachine_wrongevent = 701,
    kHangupCauseStateMachine_is_calling = 702,
    kHangupCauseStateMachine_busycall = 703,
    kHangupCauseStateMachine_timeout = 704,
    kHangupCauseStateMachine_is_not_ready = 705,
    kErrorCode_VoipRegisterWithNoUser_Or_Token = 706,

    kHangupCauseSrv_OtherClientOffline = 800, // 远程控制 接收端已经离线 peer side not online
    kHangupCauseSrv_PeerClientTypeNotSupport = 801, // 远程控制 接收端客户端版本不支持 peer side current app type not support this feature
    kHangupCauseSrv_RemoteControlExsit = 802, // 远程控制, 会话已存在 exist remotectrl session on this two person
    kHangupCauseSrv_RemoteControlNotExist = 803, // that remotectrl session has expired

    kHangupCauseSrv_GatewayCallExsit = 842, // exist other remotectrl session on this two person
    kHangupCauseSrv_GatewayCallNotExist = 843, // that remotectrl session has expired
}

export enum ConferenceCallEvent {
    kConferenceCallEvent_RecoverySuccess = 0,
    kConferenceCallEvent_RecoveryFailure = 1,
}

/**
 * 通话中媒体状态信息
 */
export enum MediaEvent {
    kMediaEvent_Disconnect = 0, // 媒体断链
    kMediaEvent_NetworkNotstable = 1, // 媒体链路不稳定
    kMediaEvent_NetworkRecover = 2, // 媒体会链路恢复
    kMediaEvent_InitRecordError = 3, // 初始化录音失败
    kMediaEvent_InitSpeakerError = 4, // 初始化播放失败
    kMediaEvent_MediaLostPlayAudio = 5,
}

export interface KeyValuePair {
    key_: string;
    value_: string;
}

export interface ConferenceMember {
    member_app_: string;
    member_id_: string;
    team_id_: string;
}

export interface ConferenceId {
    app_id_: string;
    conference_number_: string;
}

export interface MemberStatus {
    conference_id: ConferenceId;
    member_id: ConferenceMember;
    mvs: MemberVoiceStatus; // {kMemberVoiceInvalid}
    mps: MemberParticipateStatus; // {kMemberParticipateOut}
    mms: MemberMicStatus; // {kMemberMicClosed}
    desktop_share_status: DesktopShareStatus; // {kDesktopShareStatusInvalid}
    desktop_view_status: DesktopViewStatus; // {kDesktopViewStatusInvalid}
    video_shared_status: VideoCallStatus; // {kVideoCallStatusInvalid}
    video_enable_share: boolean; // {false}
    call_rid: number; // {0}
    deskshare_rid: number; // {0}
    video_id: number; // {0}
    netlevel: number; // {0}
    ver: number; // {0}
    i_viewing_it: boolean; // {false}
    media_channel_id: number; // {-1}
    displayName: string;
}

export interface StMemberParticipateStatus {
    mps: MemberParticipateStatus;
}

export interface DeskshareInviteInfo {
    from: ConferenceMember;
    conference_id: ConferenceId;
}

export interface InviteInfo {
    theme: string;
    from: ConferenceMember; //
    to: ConferenceMember; //
    conference_id: ConferenceId; //
    conference_type: ConferenceType; //
    media_type: ConferenceMediaType; //
    sid: string; //
    holders: Array<ConferenceMember>; //
    owner: ConferenceMember; //
    mode: ConferenceMode; // { kImmediateConference }
    call_infos: Array<KeyValuePair>; //
}

export interface ConferenceInfo {
    code: number; // {0}
    conference_id: ConferenceId; //
    conference_type: ConferenceType; // {kConferenceUnknown}
    media_type: ConferenceMediaType; // {kAudioConference}
    is_secret_conference: boolean; // {false}
    topic: string; //
    duration: string; //
    create_timestamp: number; // {0}
    owner: ConferenceMember; //
    sid: string; //
    start: number; // {0}
    holders: Array<ConferenceMember>; //
    cycle: string; //
    remind: number; // {0}
    password: string; //
    member_count: number; // {0}
    pstn_number: string; //
    pstn_id: string; //
    pstn_password: string; //
    // conference state , 0: meeting  1: over 2:ready to begin  3:has not started 4:canceled
    status: number; // {0}
    ver: number; // {0}
    mode: ConferenceMode; // {kImmediateConference}
    pstn: number; // {0}
    // conferece voice record 1:enable 0:disable
    tape: number; // {0}
    sharer: ConferenceMember; //
    share_viewer_num: number; //
    error_code_desc: string; //
    conf_end_stamp: number; // {0}
    locked: number; // {1}
    conf_status: ConferenceStatus; // {eConfStatus_Unknown}
    icon: string; //
    sn: string; //  // conference instance number(server is name it: serial number)
    shareUrl: string; //
}

export interface ConferenceApplyRequest {
    topic: string; //
    media_type: ConferenceMediaType; // {kAudioConference}
    conference_type: ConferenceType; // {kConferenceUnknown}
    is_secret_conference: boolean; // {false}
    mode: ConferenceMode; // {kImmediateConference}
    sid: string; //
    start: number; // {0}
    duration: string; //
    cycle: string; //
    remind: number; // {0}
    pstn: number; // {1}
    tape: number; // {0}  // conferece voice record 1:enable 0:disable
    locked: number; // {1}
    icon?: string; //
}

export interface ConferenceMemberRelationShip {
    member: ConferenceMember; //
    is_friend: boolean; //
}

export interface DevInfo {
    dev_index: number; // {-1}
    dev_name: string; //
    dev_id: string; //
    dev_type: AudioDevType; //
}

export interface CameraDevice {
    // Index of Camera device.
    index: number; //
    // Friendly name of the capture device(utf-8)
    fname: string; //
    // Unique name of the capture device if it exist, Otherwise same as fname.(utf-8)
    uname: string; //
    // Unique product id if it exist.
    uid: string; //
}

export interface ExtensionVender {
    venderId: string;
}

export interface Rect {
    left: number;
    top: number;
    width: number;
    height: number;
}

export interface RunConfig {
    server_ip: string;
    server_port: string;
    username: string;
    password: string;
    clientapp: string;
    teamid: string;
    token: string;
    clienttype: string; // 'tester';
    voipProxyLogPath: string;
    conference_id_: ConferenceId;
    conf_sid_: string;
    voip_log: string; // '';
    video_log: string; // '';
    mek: string;
    mes: string;
    peername: string;
    is_single: boolean;
}
