import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import './index.less';
import { message, Spin, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FocusSDK, useDebounce } from '@/utils';
import { useTranslation } from 'react-i18next';
import { ZoomMeetingStatus, ZoomSessionStateEnum } from '@/types/common';
import IconFont from '@/components/icon';
import AudioMp3 from '@/assets/toy_mono.mp3';
import GlobalContext from '@/context/GlobalContext';
import { handleMeetingSendMessage } from '../utils';
import { changeEgovUrl } from '@/utils/tools';
import { Avatar } from '@/baseComponents/Avatar';
import { connect as dvaConnect } from 'dvajs';
import { NetType } from '@/components/Modals/ChangeNetModal';

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const MeetingReceView = (props: any) => {
    const [initData, setInitData] = React.useState<any>(null);
    const [originData, setOriginData] = React.useState<any>(null);
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo = {} as any, imService, currentEmployee } = useContext(GlobalContext);
    const { teamUserInfo = {}, selectedTeamId, userId } = authInfo;
    const { ddAppId, teamId } = teamUserInfo;
    const [t_common] = useTranslation('common');
    const audioRef = useRef<any>(null);
    const timeRef = useRef<any>(null);
    const [t] = useTranslation('common');
    const { newPublicResult, newPrivateResult } = props;

    useEffect(() => {
        if (FocusSDK.getCurrentNetType() === NetType.PRIVATE_NET) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
    }, []);

    useEffect(() => {
        FocusSDK.once('receiveMeeting.setInitData', (data: any) => {
            const { receiveData, originData } = data;
            setInitData(receiveData);
            setOriginData(originData);
        });
        FocusSDK.sendIpcMessage('receiveMeeting.getInitData');
    }, []);

    const debounceToast = useDebounce((str: string, action: 'error') => {
        message[action](str);
    }, 1500);

    const sendUserReject = useCallback(
        (data: any) => {
            if (!data) {
                return;
            }
            let { body, to } = data;
            const { ext = {} } = body || data;
            const { fromUser } = ext;

            const from = {
                app: teamUserInfo.ddAppId,
                pin: userId,
                teamId: selectedTeamId,
                name: currentEmployee.name,
                avatar: teamUserInfo.headImg,
            };
            const newMessage = {
                ...body,
                ext: {
                    ...ext,
                    fromUser: from,
                    sessionState: ZoomSessionStateEnum.UserReject,
                    sessionType: 1,
                },
                noPush: 1,
                content: '用户拒绝',
                state: 0,
            };
            handleMeetingSendMessage({
                imService,
                currentEmployee: { app: ddAppId, userId, teamId },
                data: {
                    toUsers: [fromUser],
                    body: newMessage,
                    requestCard: true,
                },
                t: t_common,
            });
        },
        [
            currentEmployee.name,
            ddAppId,
            imService,
            selectedTeamId,
            t_common,
            teamId,
            teamUserInfo.ddAppId,
            teamUserInfo.headImg,
            userId,
        ]
    );

    useEffect(() => {
        /**
         * 处理超过一分钟未响应的情况，主要是群会议
         */
        function handleTimePass() {
            const timer = timeRef.current;
            if (timer) {
                clearTimeout(timer);
            }
            timeRef.current = setTimeout(() => {
                sendUserReject(originData);
                setTimeout(() => {
                    FocusSDK.sendIpcMessage('receiveMeeting.reject');
                }, 50);
                // changeJoyMeetingStatus(-1);
            }, 1000 * 60);
        }
        if (!originData) {
            return;
        }
        handleTimePass();
    }, [originData, sendUserReject]);

    if (!initData) {
        return null;
    }
    const { fromUser, meetingNumber, meetingTopic, meetingPassword, isSingle, own } = initData;

    function stopAudio() {
        const au = audioRef.current;
        if (au) {
            au.muted = true;
        }
    }
    /**
     * 发送用户应答数据
     * @param data
     */
    function sendUserReceive(data: any) {
        if (!data) {
            return;
        }
        const { body, to } = data;
        const { ext = {} } = body || data;
        const { fromUser } = ext;

        const from = {
            app: teamUserInfo.ddAppId,
            pin: userId,
            teamId: selectedTeamId,
            name: currentEmployee.name,
            avatar: teamUserInfo.headImg,
        };
        const newMessage = {
            ...body,
            ext: {
                ...ext,
                fromUser: from,
                sessionState: ZoomSessionStateEnum.UserReceive,
                sessionType: 0,
            },
            noPush: 1,
            content: '用户应答',
            state: 0,
        };
        handleMeetingSendMessage({
            imService,
            currentEmployee: { app: ddAppId, userId, teamId },
            data: {
                toUsers: [fromUser],
                body: newMessage,
            },
            t: t_common,
        });
    }

    function handleReject() {
        sendUserReject(originData);
        setTimeout(() => {
            FocusSDK.sendIpcMessage('receiveMeeting.reject');
        }, 50);
    }

    function handleReceVideo() {
        if (connect) {
            return;
        }
        stopAudio();
        onJoinMeeting({ isvideooff: false, isaudiooff: false });
    }
    function handleReceAudio() {
        if (connect) {
            return;
        }
        stopAudio();
        onJoinMeeting({ isvideooff: true, isaudiooff: false });
    }

    function handleClose() {
        FocusSDK.sendIpcMessage('receiveMeeting.close');
        setConnect(false);
    }

    function handleConnectFailed() {
        FocusSDK.sendIpcMessage('receiveMeeting.failed');
    }

    function onJoinMeeting({
        isvideooff,
        isaudiooff,
    }: {
        isvideooff: boolean;
        isaudiooff: boolean;
    }) {
        setConnect(true);
        sendUserReceive(originData);
        FocusSDK.sendIpcMessage('receiveMeeting.receive', {
            meetingnum: meetingNumber,
            psw: meetingPassword,
            isvideooff,
            isaudiooff,
        });
        setTimeout(() => {
            handleClose();
        }, 300);
    }

    const { avatar, name, titleName, superiorDeptName, deptName } = fromUser;

    return (
        <div className="receive-meeting-browser">
            <div className="meeting-header">
                <div className="meeting-header-container">
                    {isSingle && (
                        <div className="avatar">
                            <Avatar
                                className="meeting-header-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name}
                                styleObj={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    textRlign: 'center',
                                    lineHeight: 50,
                                    color: 'white',
                                    marginRight: 7,
                                }}
                            />
                        </div>
                    )}
                    <div className="meeting-header-content">
                        <div className="title">
                            {isSingle ? (
                                <>
                                    <span className="title-name">{name}</span>
                                    {titleName && (
                                        <span className="title-position">{titleName}</span>
                                    )}
                                </>
                            ) : (
                                <span className="title-name">{meetingTopic}</span>
                            )}
                        </div>
                        <div className="dept-info">
                            {!isSingle && (
                                <span className="dept-prefix">
                                    <span className="prefix-title">{name}</span>
                                    <span className="prefix-position">{titleName}</span>
                                </span>
                            )}
                            <span>
                                {`${superiorDeptName ? superiorDeptName + '-' : ''}${
                                    deptName || ''
                                }`}
                            </span>
                        </div>
                        <div className="desc">
                            {t('joinMeeting.Invite you to an online meeting')}
                        </div>
                    </div>
                </div>
            </div>
            {connect ? (
                <div className="meeting-connect-loading">
                    <Spin indicator={antIcon} />
                </div>
            ) : (
                <div className="meeting-action">
                    <div className="meeting-action-reject">
                        <div className="action-icon" onClick={handleReject}>
                            <IconFont type="iconapp_btn_joymerting_hangup" />
                        </div>
                        <div className="label">{t('joinMeeting.reject')}</div>
                    </div>
                    <div className="meeting-action-video">
                        <div className="action-icon" onClick={handleReceVideo}>
                            <IconFont type="iconapp_btn_joymerting_video" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-video')}</div>
                    </div>
                    <div className="meeting-action-audio">
                        <div className="action-icon" onClick={handleReceAudio}>
                            <IconFont type="iconapp_btn_joymerting_answer" />
                        </div>
                        <div className="label">{t('joinMeeting.rece-audio')}</div>
                    </div>
                </div>
            )}
            <audio src={changeEgovUrl(AudioMp3)} loop autoPlay ref={audioRef} />
        </div>
    );
};

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {}

export default dvaConnect(mapStateToProps, mapDispatchToProps)(MeetingReceView);
