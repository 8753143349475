import React, {
    CSSProperties,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { LoginFormContext } from './utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import {
    AuthDataRes,
    CreateTeamAndEmployee,
    TeamUserInfo,
    ValidateAccountRes,
    ValidateAccountReq,
    BizTypeEnum,
} from '@/types/LoginPage';
import LeftSide from './LeftSide';
import MobileInputForm from './forms/MobileInputForm';
import VerifyCodeForm from './forms/VerifyCodeForm';
import VerifyPwdForm from './forms/VerifyPwdForm';
import ResetPwdForm from './forms/ResetPwdForm';
import NameInputForm from './forms/NameInputForm';
import JoinMeForm from './forms/JoinMe';
import { useHistory } from 'react-router-dom';
import logger from '@/utils/logger';
import { useTranslation } from 'react-i18next';
import './index.less';
import { message } from 'antd';
import WinToolbar from '@/layout/WinToolbar';
import { getServiceAndPrivacyLinks } from '@/utils/ui';
import cookies from 'js-cookie';
import BindMobileModal from '@/components/Modals/BindMobileModal';
import { changeEgovUrl } from '@/utils/tools';
// import NDAModal from '@/components/Modals/NDAModal';
import EventBus from '@/utils/bus';
import { FocusSDK, isFocusEnv } from '@/utils';
import sentryManual from '@/utils/sentryManual';
import { TELSERVICE } from '@/config/name';
import PrivateNetExcepModal from '@/components/Modals/PrivateNetExcepModal';
import { GlobalOutlined } from '@ant-design/icons';

const { REACT_APP_DEFAULT_RESEND_PERIOD: DEFAULT_RESEND_PERIOD = '60' } = process.env;

const log = logger.getLogger('LoginPage');

interface LoginCompProps {
    enterApplication: (teamInfo: CreateTeamAndEmployee | TeamUserInfo | null) => void;
}
// const STATUS = {
//     //   WAIT_SCAN("等待扫描"),
//     //   WAIT_CONFIRM("等待确认"),
//     //   EXPIRED("已过期"),
//     //   SUCCESS("验证成功"),
//     //   WAIT_REDIRECT("等待跳转"),
//     //   /** 非缓存记录字段，这个字段仅仅用来返回给前端 */
//     //   HAS_SCANNED("已经被扫描"),
//     //   REDIRECT("跳转");
//     WAIT_SCAN: 'WAIT_SCAN', // 等待扫描
//     WAIT_CONFIRM: 'WAIT_CONFIRM', // 扫描完成后  等待手机端确认
//     EXPIRED: 'EXPIRED', // 二维码已过期
//     SUCCESS: 'SUCCESS', // 验证成功
//     WAIT_REDIRECT: 'WAIT_REDIRECT', // 确认登录，等待跳转
//     REDIRECT: 'REDIRECT', // 跳转
//     HAS_SCANNED: 'HAS_SCANNED', //
//     FETCHING: 'FETCHING', // 加载中
//     CANCEL: 'CANCEL',
// };
// const maskStyle: CSSProperties = {
//     position: 'absolute',
//     width: '100%',
//     height: '100%',
//     top: 0,
//     left: 0,
//     background: 'rgba(0,0,0,0.7)',
// };
// const centerStyle: CSSProperties = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     cursor: 'pointer',
// };
// const avatarStyle: CSSProperties = {
//     width: 24,
//     display: 'block',
//     position: 'absolute',
//     top: 110,
//     left: 107,
// };
// function UserHead(name: string, avatar: string) {
//     const avatarPic = (
//         <div>
//             <img style={{ width: 66, borderRadius: 33 }} src={changeEgovUrl(avatar)} />
//             <img
//                 src={changeEgovUrl(checkedImg)}
//                 style={{
//                     ...avatarStyle,
//                 }}
//             />
//         </div>
//     );
//     const avatarText = (
//         <div>
//             <p style={{ margin: 0 }} className="avatar-first-chr">
//                 {name ? name.slice(0, 1) : ''}
//             </p>
//             <img
//                 src={changeEgovUrl(checkedImg)}
//                 style={{
//                     ...avatarStyle,
//                 }}
//             />
//         </div>
//     );
//     return avatar ? avatarPic : avatarText;
// }
export default function LoginComp(props: LoginCompProps) {
    let scanTimer: any = null;
    let pollingTimer: any = null;
    let waitConfirmTimer: any = null;
    const { t } = useTranslation('common');
    const history = useHistory();
    const { onGlobalError, service, focusSDK, appRuntimeEnv } = useContext<GlobalContextConfig>(
        GlobalContext
    );
    const [step2, setStep2] = useState(false);
    const [resendLastTs, setResendLastTs] = useState(0);
    const [loginType, setLoginType] = useState('QRCODE');
    const [loginMode, setLoginMode] = useState('');
    const [teamId, setTeamId] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [account, setAccount] = useState('');
    const [userName, setUserName] = useState('');
    const [autoLogin, setAutoLogin] = useState(false);
    const [routePath, setRoutePath] = useState('step1');
    const [authCode, setAuthCode] = useState('');
    const [userData, setUserData] = useState<AuthDataRes | null>(null);
    const [accountInfo, setAccountInfo] = useState<ValidateAccountRes | null>(null);
    const [loginName, setLoginName] = useState('');
    const [bizType, setBizType] = useState(BizTypeEnum.APP_LOGIN);
    const { authInfo = {} as any } = useContext(GlobalContext);
    const links = getServiceAndPrivacyLinks(appRuntimeEnv);
    const [bindMobileVisible, setBindMobileVisible] = useState(false);
    const [ndaModalVisible, setNDAModalVisible] = useState(false);
    const [scanCode, setScanCode] = useState(true);
    // const [qrcodeStatus, setQrcodeStatus] = useState(STATUS.WAIT_SCAN);
    const [avatar, setAvatar] = useState('');
    const [realName, setRealName] = useState('');
    const [qrcode, setQrcode] = useState<{ qrCodeUrl: string }>({ qrCodeUrl: '' });
    const isCheckedRef = useRef(false);
    const [dnsVisible, setDnsVisible] = useState(false);

    const navigateCb = useCallback((path: string) => {
        setRoutePath(path);
    }, []);

    // const onMobileChange = useCallback((f: string) => {
    //     if (f && f.split(' ').join('').length > 11) {
    //         return;
    //     }
    //     setMobile(f.split(' ').join(''));
    // }, []);

    const onMobileChange = useCallback((f: string) => {
        if (f && f.length > 11) {
            return;
        }
        setMobile(f);
    }, []);

    const onEmailChange = useCallback((f: string) => {
        setEmail(f);
    }, []);

    const onAccountChange = useCallback((f: string) => {
        setAccount(f);
    }, []);

    const onLoginTypeChange = useCallback((f: string) => {
        setLoginType(f);
    }, []);

    const onTeamIdChange = useCallback((f: string) => {
        setTeamId(f);
    }, []);

    const onAutoLoginChange = useCallback((f: boolean) => {
        setAutoLogin(f);
    }, []);

    const onUserNameChange = useCallback((f: string) => {
        setUserName(f);
    }, []);
    const onLoginModeChange = useCallback((f: string) => {
        setLoginMode(f);
    }, []);

    useEffect(() => {
        focusSDK.printLog('useEffect-autoLogin', autoLogin);
        isCheckedRef.current = autoLogin;
    }, [autoLogin, focusSDK]);
    useEffect(() => {
        if (localStorage.getItem('nda_readed') !== 'true') {
            setNDAModalVisible(true);
        }
    }, [setNDAModalVisible]);
    // useEffect(() => {
    //     if (!isFocusEnv()) {
    //         return;
    //     }
    //     if (focusSDK.isPrivateNet()) {
    //         // 初始dns设置存在问题且重设后依旧存在问题
    //         if (!focusSDK.privateDnsIsTrue() && !focusSDK.newPrivateDnsIsTrue) {
    //             setDnsVisible(true);
    //         }
    //     }
    //     // window.open('https://jzb-test.jdcloud.com/download/dns');
    // }, [focusSDK]);

    // focusSDK.printLog('loginComp-autoLogin', autoLogin, isCheckedRef.current);
    const onSelectTeam = useCallback(
        (teamId: string, data?: any) => {
            focusSDK.printLog('onSelectTeam', isCheckedRef.current);
            if (!data && !userData) {
                onGlobalError(new Error('Can not get user data when login.'));
                return;
            }
            focusSDK.login(
                data || userData,
                teamId,
                () => {
                    log.debug('focusSDK.login callback');
                    focusSDK.emit('loginSuccess');
                },
                isCheckedRef.current
            );
        },
        [focusSDK, onGlobalError, userData]
    );

    const enterApplication = useCallback(
        (teamInfo: CreateTeamAndEmployee | TeamUserInfo | null) => {
            if (!teamInfo) {
                onGlobalError(new Error('Can not get team data when login.'));
                return;
            }
            if (!userData) {
                onGlobalError(new Error('Can not get user data when login.'));
                return;
            }
            focusSDK.login(
                {
                    ...userData,
                    selectedTeamId: teamInfo.teamId,
                    teamUserInfo: teamInfo,
                    teamUserInfoList: [teamInfo],
                },
                teamInfo.teamId,
                () => {
                    focusSDK.emit('loginSuccess');
                },
                autoLogin
            );
        },
        [focusSDK, onGlobalError, userData, autoLogin]
    );

    const handleUserData = useCallback(
        async (data: AuthDataRes) => {
            setUserData(data);
            service.focusService.setToken(data.accessToken);
        },
        [service.focusService, setUserData]
    );

    const selectedTeam = useCallback(
        async (data: AuthDataRes) => {
            try {
                await handleUserData(data);
                const teamUserInfoList = data.teamUserInfoList || [];
                const selectedTeamId = '';
                console.log('登录后选择租户', teamUserInfoList, selectedTeamId);
                FocusSDK.printLog('登录后选择租户', teamUserInfoList, selectedTeamId);
                if (teamUserInfoList.length === 1) {
                    if (teamUserInfoList[0].emplEnabled !== 'DIS_ENABLED') {
                        onSelectTeam(teamUserInfoList[0].teamId, data);
                    } else {
                        message.destroy();
                        message.warn('该账号已被冻结，如有疑问请联系所在单位管理员进行核查。');
                        navigateCb('step1');
                    }
                } else if (
                    selectedTeamId &&
                    teamUserInfoList.find((el) => el.teamId === selectedTeamId)
                ) {
                    onSelectTeam(selectedTeamId, data);
                } else {
                    const timer = setTimeout(() => {
                        setRoutePath('stepNewTeam');
                        clearTimeout(timer);
                    }, 0);
                }
            } catch (err) {
                console.warn(err);
            }
        },
        [handleUserData, onSelectTeam, navigateCb]
    );

    const bindEnterpriseMobile = useCallback(
        async (data: AuthDataRes) => {
            const { hasMobile } = accountInfo || {};
            // if (!hasMobile) {
            //     await handleUserData(data);
            //     onSelectTeam(teamId, data);
            // } else {
            // 绑定手机号Modal
            setUserData(data);
            setBindMobileVisible(true);

            // onSelectTeam(teamId, data);
            // }
        },
        [accountInfo]
    );

    const getcurrentLoginType = useCallback(
        (bizType?: BizTypeEnum) => {
            let params: {
                bizType: BizTypeEnum;
                loginName: string;
                loginNameType: string;
                teamId?: string;
            } = {
                bizType: bizType || BizTypeEnum.APP_LOGIN,
                loginName: mobile,
                loginNameType: 'MOBILE',
            };
            switch (loginType) {
                case 'MOBILE':
                    params = {
                        ...params,
                        loginName: mobile,
                        loginNameType: 'MOBILE',
                    };
                    break;
                case 'EMAIL':
                    params = {
                        ...params,
                        loginName: email,
                        loginNameType: 'EMAIL',
                    };
                    break;
                case 'ACCOUNT':
                    params = {
                        ...params,
                        loginName: account,
                        loginNameType: 'ACCOUNT',
                    };
                    break;
            }
            if (loginMode === 'vip' && teamId) {
                params.teamId = teamId;
            }
            setLoginName(params.loginName);
            return params;
        },
        [loginType, loginMode, mobile, teamId, account, email]
    );

    const sendVerifyCode = useCallback(
        async (params?: ValidateAccountReq, bizType?: BizTypeEnum) => {
            try {
                setBizType(bizType || BizTypeEnum.APP_LOGIN);
                const req = params || getcurrentLoginType(bizType);
                let receive = '';
                if (loginMode === 'vip') {
                    const res = await service.authService.exclusiveAppLoginSendVerCode(req);
                    receive = res.receive;
                } else {
                    const res = await service.authService.appSendVerCode(req);
                    receive = res.receive;
                }
                if (receive) {
                    setMobile(receive);
                }
                setRoutePath('step2');
                setResendLastTs(parseInt(DEFAULT_RESEND_PERIOD, 10));
                message.success(t('login.resendVerifyCodeSuccess'));
                return receive;
            } catch (err) {}
        },
        [setBizType, loginMode, service.authService, getcurrentLoginType, t]
    );

    const onSendCode = useCallback(async () => {
        try {
            const params: ValidateAccountReq = getcurrentLoginType();
            if (loginMode === 'vip') {
                const res = await service.authService.exclusiveValidateAccount(params);
                setAccountInfo(res);
                const { hasPassword, isTrustedDevice, hasMobile } = res;
                if (hasMobile) {
                    if (hasPassword && isTrustedDevice) {
                        setRoutePath('step3');
                    } else {
                        sendVerifyCode(params);
                    }
                } else {
                    setRoutePath('step3');
                }
            } else {
                const res = await service.authService.validateAccount(params);
                setAccountInfo(res);
                const { hasPassword, isTrustedDevice, hasMobile } = res;
                if (hasPassword && isTrustedDevice) {
                    setRoutePath('step3');
                } else {
                    sendVerifyCode(params);
                }
            }
        } catch (error) {
            sentryManual.captureException(error, 'login-email');
            focusSDK.printLog(
                'login-email',
                (error as any)?.stack || (error as any)?.message || error
            );
            if ((error as any).code !== '10160038') {
                message.error(t('login.resendVerifyCodeError'));
            }
        }
        return false;
    }, [getcurrentLoginType, loginMode, service.authService, sendVerifyCode, t, focusSDK]);

    const onVerifySuccess = useCallback(
        (data: any) => {
            if (bizType === BizTypeEnum.SET_PASSWORD) {
                setAuthCode(data.authCode);
                setRoutePath('reset');
            } else if (bizType === BizTypeEnum.BIND_MOBILE) {
                setRoutePath('bind');
            } else {
                selectedTeam(data);
            }
        },
        [bizType, selectedTeam]
    );

    const onPwdSuccess = useCallback(
        (data: any) => {
            if (loginMode === 'vip' && !data.hasBindMobile) {
                bindEnterpriseMobile(data);
            } else {
                selectedTeam(data);
            }
        },
        [loginMode, bindEnterpriseMobile, selectedTeam]
    );
    // 每秒掉一次轮询
    // function startPollingTimer(qrcode: any) {
    //     clearInterval(pollingTimer);
    //     pollingTimer = setInterval(() => {
    //         pollingQrcode({ polling: true, pollingUrl: qrcode.pollingUrl }).then(({ data }) => {
    //             if (!data) {
    //                 clearTimer();
    //                 return;
    //             }
    //             let content = data.content || {};
    //             if (content) {
    //                 // 轮询接口
    //                 setQrcodeStatus(content.qrStatus);
    //                 if (
    //                     content.qrStatus === STATUS.REDIRECT ||
    //                     content.qrStatus === STATUS.EXPIRED
    //                 ) {
    //                     clearInterval(pollingTimer);
    //                     clearTimeout(waitConfirmTimer);
    //                     if (content.qrStatus === STATUS.REDIRECT) {
    //                         setTimeout(() => {
    //                             // '登陆成功
    //                             fastLoginSelectTeam(content);
    //                         }, 1000);
    //                     }
    //                 } else if (content.qrStatus === STATUS.WAIT_CONFIRM) {
    //                     // '扫码成功'
    //                     setRealName(content.realName);
    //                     setAvatar(content.avatar);
    //                     setWaitConfirmTimer(1000 * qrcode.waitScanExpire);
    //                 } else if (content.qrStatus === STATUS.CANCEL) {
    //                     // '回到初始状态从新扫码'
    //                     clearInterval(pollingTimer);
    //                     getCode();
    //                 }
    //             } else {
    //                 clearTimer();
    //             }
    //         });
    //     }, 1000);
    // }
    // function setWaitConfirmTimer(time: any) {
    //     clearTimeout(waitConfirmTimer);
    //     clearTimeout(scanTimer);
    //     waitConfirmTimer = setTimeout(() => {
    //         //  等待确认登录超时');
    //         setQrcodeStatus(STATUS.EXPIRED); // 等待确认跳转页面
    //     }, time);
    // }
    // function waitScanTimeout(time: any) {
    //     clearTimeout(scanTimer);
    //     scanTimer = setTimeout(() => {
    //         // '二维码已过期');
    //         setQrcodeStatus(STATUS.EXPIRED); // 等待扫码
    //     }, time);
    // }
    // function clearTimer() {
    //     clearInterval(pollingTimer);
    //     clearTimeout(scanTimer);
    //     clearTimeout(waitConfirmTimer);
    // }
    // function getCode() {
    //     setQrcodeStatus(STATUS.FETCHING); // 正在请求状态
    //     getQRCode({ polling: false }).then(({ data }) => {
    //         if (!data) {
    //             return;
    //         }
    //         if (data.errorCode === '0') {
    //             let content = data.content || {};
    //             setQrcode(content);
    //             setQrcodeStatus(STATUS.WAIT_SCAN); // 等待扫码
    //             // 获取到二维码后等待扫码
    //             // 扫描成功后计时
    //             waitScanTimeout(1000 * content.waitScanExpire);
    //             // 轮训登录
    //             startPollingTimer(content);
    //         } else {
    //             setQrcodeStatus(STATUS.EXPIRED);
    //         }
    //     });
    // }
    // useEffect(() => {
    //     if (scanCode) {
    //         getCode();
    //     } else {
    //         clearTimer();
    //     }
    //     return () => {
    //         clearTimer();
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [scanCode]);

    const fastLoginSelectTeam = useCallback(
        async (data: any) => {
            const res = await service.authService.fastLogin(
                {
                    accessToken: data.twoToken.accessToken,
                    userId: data.userId,
                },
                {
                    'x-token': data.twoToken.accessToken,
                }
            );
            if (!res) {
                return;
            }
            await handleUserData(res);
            setUserData(res);
            const teamUserInfoList = res.teamUserInfoList || [];
            const selectedTeamId = cookies.get('focus-team-id');
            if (teamUserInfoList.length === 1) {
                onSelectTeam(teamUserInfoList[0].teamId, res);
            } else if (
                selectedTeamId &&
                teamUserInfoList.find((el) => el.teamId === selectedTeamId)
            ) {
                onSelectTeam(selectedTeamId, res);
            } else {
                const timer = setTimeout(() => {
                    setScanCode(false);
                    setRoutePath('stepNewTeam');
                    clearTimeout(timer);
                }, 0);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setUserData, service.authService]
    );
    const currentForm = useMemo(() => {
        switch (routePath) {
            case 'step1':
                return <MobileInputForm />;
            case 'step2':
                return (
                    <VerifyCodeForm
                        onSuccess={onVerifySuccess}
                        onNewUser={(data) => {
                            setAuthCode(data);
                            setRoutePath('stepNewUser');
                        }}
                        onNewTeam={selectedTeam}
                    />
                );
            case 'step3':
                return (
                    <VerifyPwdForm
                        onSuccess={onPwdSuccess}
                        onNewUser={(data) => {
                            setAuthCode(data);
                            setRoutePath('stepNewUser');
                        }}
                        onNewTeam={selectedTeam}
                        onSendVerify={sendVerifyCode}
                        onSendResetVerify={() =>
                            sendVerifyCode(undefined, BizTypeEnum.SET_PASSWORD)
                        }
                    />
                );
            case 'reset':
                return (
                    <ResetPwdForm
                        authCode={authCode}
                        onSuccess={onPwdSuccess}
                        onNewUser={(data) => {
                            setAuthCode(data);
                            setRoutePath('stepNewUser');
                        }}
                        onNewTeam={selectedTeam}
                    />
                );
            case 'stepNewUser':
                return <NameInputForm authCode={authCode} onCreateUser={selectedTeam} />;
            case 'stepNewTeam':
                return <JoinMeForm onSelectTeam={onSelectTeam} />;
            default:
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        authCode,
        sendVerifyCode,
        onSelectTeam,
        routePath,
        selectedTeam,
        onVerifySuccess,
        onPwdSuccess,
        fastLoginSelectTeam,
        scanCode,
    ]);

    const showNetModal = () => {
        focusSDK.emit('showNetTable', {
            fromType: 'entry',
        });
    };

    // const handleClick = debounce(() => {
    //     setScanCode(!scanCode);
    // }, 300);
    return (
        <LoginFormContext.Provider
            value={{
                step2,
                resendLastTs,
                setResendLastTs: (ts: number) => {
                    setResendLastTs(ts);
                },
                loginType,
                loginMode,
                teamId,
                mobile,
                email,
                account,
                loginName,
                navigatePath: routePath,
                navigate: navigateCb,
                onLoginTypeChange,
                onTeamIdChange,
                onMobileChange,
                onEmailChange,
                onAccountChange,
                onLoginModeChange,
                userName,
                onUserNameChange,
                autoLogin,
                onAutoLoginChange,
                sendVerifyCode,
                onSendCode,
                enterApplication,
                selectedTeam,
                authInfo: userData,
                accountInfo,
                bizType,
                onLoginError: onGlobalError,
            }}
        >
            <div className="loginPage">
                <div
                    className="draggable"
                    style={{
                        height: 40,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        position: 'fixed',
                        top: '1px',
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    <WinToolbar />
                </div>
                <div className="loginRoot">
                    <LeftSide />
                    <div className="loginRightSide">
                        <div className="loginRightTop">
                            <div className="currentForm">{currentForm}</div>
                        </div>
                        {/* 行业me暂时隐藏 */}
                        {/* <div className="net-check" onClick={showNetModal}>
                            <GlobalOutlined className="check-icon" />
                            <span className="check-text">网络检测</span>
                        </div> */}
                        {TELSERVICE && <p className="kufu">客服电话：{TELSERVICE}</p>}
                    </div>
                </div>
                <BindMobileModal
                    visible={bindMobileVisible}
                    loginName={loginName}
                    loginType={loginType}
                    teamId={teamId}
                    onSuccess={() => {
                        onSelectTeam(teamId, userData);
                    }}
                    closable={(accountInfo || {}).hasIdNum}
                    onClose={() => {
                        setBindMobileVisible(false);
                        onSelectTeam(teamId, userData);
                    }}
                />
                {/* <NDAModal
                    visible={ndaModalVisible}
                    onSuccess={() => {
                        // cookies.set('nda_readed', 'true');
                        localStorage.setItem('nda_readed', 'true');
                        setNDAModalVisible(false);
                    }}
                    onClose={() => {
                        setNDAModalVisible(false);
                        focusSDK.appExit?.();
                    }}
                /> */}
            </div>
        </LoginFormContext.Provider>
    );
}
