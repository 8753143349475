import React, { useMemo } from 'react';
import styles from './errorpage.module.less';
import { connect } from 'dva';
import { BrowserViewItem } from './utils';

export enum ErrorType {
    TIMEOUT = 'timeout',
    FORBIDEN = 'forbiden',
    NOTFOUND = 'notfound',
    SEVERERROR = 'server-error',
}

export interface ErrorInfo {
    code?: number;
    type: ErrorType;
}

interface ErrorPageProps {
    thirdAppInfo: BrowserViewItem;
    errorInfo: ErrorInfo;
}

type IProps = Readonly<ErrorPageProps>;

function ErrorPage(props: IProps) {
    const { thirdAppInfo, errorInfo } = props;
    const msgContent = useMemo(() => {
        switch (errorInfo.type) {
            case ErrorType.TIMEOUT:
                return thirdAppInfo?.name || ''
                    ? `启动"${
                          thirdAppInfo?.name || ''
                      }"应用时出现网络连接错误，请检查您的网络，或稍后重试`
                    : `访问页面时出现网络连接错误，请检查您的网络，或稍后重试`;
            case ErrorType.FORBIDEN:
                return thirdAppInfo?.name || ''
                    ? `您没有权限访问"${thirdAppInfo?.name || ''}"应用，请联系应用运维方`
                    : `您没有权限访问此页面`;
            case ErrorType.NOTFOUND:
                return thirdAppInfo?.name || ''
                    ? `启动"${thirdAppInfo?.name || ''}"应用时加载出错，请联系应用运维方`
                    : '您访问的页面暂时无法找到或者不可用';
            default:
                return thirdAppInfo?.name || ''
                    ? `启动"${thirdAppInfo?.name || ''}"应用时加载出错，请联系应用运维方`
                    : `您所访问的页面加载出错`;
        }
    }, [errorInfo, thirdAppInfo?.name]);
    const phoneContent = useMemo(() => {
        if (thirdAppInfo?.phone) {
            return thirdAppInfo.phone.split(';');
        } else {
            return ['', ''];
        }
    }, [thirdAppInfo]);
    return (
        <div className={styles['error-page-warp']}>
            <div className={styles['inner']}>
                <div className={`${styles['icon']} ${styles[errorInfo.type]}`} />
                <div className={styles['error-wrap']}>
                    <p className={styles['msg']}>{`${msgContent}`}</p>
                    {errorInfo.code ? (
                        <div className={styles['items']}>
                            <div className={styles['item']}>
                                <span className={styles['name']}>错误代码：</span>
                                <span className={styles['value']}>{errorInfo.code}</span>
                            </div>
                            {thirdAppInfo?.company ? (
                                <div className={styles['item']}>
                                    <span className={styles['name']}>应用运维方：</span>
                                    <span className={styles['value']}>{thirdAppInfo?.company}</span>
                                </div>
                            ) : null}
                            {thirdAppInfo?.phone ? (
                                <div className={styles['item']}>
                                    <span className={styles['name']}>应用客服电话：</span>
                                    <div className={styles['value']}>
                                        {phoneContent.map((phone, idx) => {
                                            if (idx !== phoneContent.length - 1) {
                                                return (
                                                    <div key={idx}>
                                                        <span>{phone}</span>
                                                        <br />
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div key={idx}>
                                                        <span>{phone}</span>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>{' '}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default connect()(ErrorPage);
