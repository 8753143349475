import React from 'react';
import IconFont from '@/components/icon';

export const Line = () => {
    const style: React.CSSProperties = {
        background: '#eeeeee',
        height: '1px',
        width: '290px',
        // float: 'right',
        margin: '0 auto',
    };
    return <div style={style} />;
};

export const SplitLine = () => {
    return <div className="usercard-item-splitline" />;
};

export const getSexNode = (sex: any) => {
    if (!sex || sex === '2') {
        return '';
    } else if (sex === '0') {
        return <IconFont style={{ color: '#FF6792' }} type="iconapp_sex_female" />;
    } else {
        return <IconFont style={{ color: '#2E8EFF' }} type="iconapp_sex_male" />;
    }
};

// export const NameSig = (props: {
//     isSelf: boolean;
//     name?: string;
//     sig?: string;
//     onModifySig?: ModifySettingFn;
//     t: any;
//     sex: any;
// }) => {
//     const { name, sig, t, isSelf, onModifySig, sex } = props;
//     const [value, setValue] = useState('');
//
//     useEffect(() => {
//         setValue(sig);
//     }, [sig]);
//
//     const saveSig = async (value: string) => {
//         await onModifySig({
//             settingCode: 'signature',
//             settingContent: value,
//         });
//     };
//
//     const doSaveDebounce = useRef(debounce(saveSig, 500)).current;
//
//     return (
//         <div
//             className="me-user-card-namesig"
//             style={{
//                 padding: '18px 20px',
//             }}
//         >
//             <h4
//                 className="usercard-namesig-title"
//                 style={{
//                     fontSize: 18,
//                     // color: styles.color.black,
//                     lineHeight: '24px',
//                     margin: 0,
//                 }}
//             >
//                 {name || t('userCard.titleDefaultStr')}
//                 {!sex || sex === '2' ? (
//                     ''
//                 ) : (
//                     <img
//                         src={sex === '0' ? Woman : Man}
//                         style={{ width: '14px', height: '14px', margin: ' 0 0 6px 8px' }}
//                     />
//                 )}
//             </h4>
//
//             {isSelf ? (
//                 <Input
//                     maxLength={50}
//                     placeholder={t('userCard.workStatusDefaultStr')}
//                     value={value}
//                     onChange={(e) => {
//                         setValue(e.target.value);
//                         doSaveDebounce(e.target.value);
//                     }}
//                     style={{
//                         border: 'none',
//                         resize: 'none',
//                         color: '#242931',
//                         maxHeight: '73px',
//                         marginTop: '6px',
//                     }}
//                     allowClear
//                 />
//             ) : (
//                 <p
//                     className="usercard-namesig-desc"
//                     style={{
//                         margin: 0,
//                         // color: styles.color.gray,
//                         fontSize: 13,
//                         lineHeight: '18px',
//                     }}
//                 >
//                     {sig || ''}
//                 </p>
//             )}
//         </div>
//     );
// };
