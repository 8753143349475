import { StringLiteralLike } from 'typescript';
import { PageType, PermissionEnum } from './const';

interface IBase {
    created_at: string;
    created_by?: string;
    updated_at?: string;
}

export interface User {
    avatar_url: string;
    full_org_name: string;
    full_org_path: string;
    id: string;
    userId: string;
    is_link: string;
    mobile: string;
    name: string;
    org_code: string;
    org_name: string;
    tenant_id: string;
    user_id: string;
    username: string;
}

export interface ISpace extends IBase {
    avatar_url: string;
    description: string;
    followCount: number;
    id: string;
    isCollected: boolean;
    isFollowed: boolean;
    isShortcuted: boolean;
    name: string;
    tags: string;
    type: number;
}

export interface IAuthor {
    avatar_url: string;
    id: string;
    name: string;
}

export interface ICategoryBase {
    amount: number;
    created_at: string;
    created_by: string;
    from: string;
    full_name: string;
    full_path: string;
    id: string;
    name: string;
    parent: string;
    sort_index: number;
    space_id: string;
    status: number;
    tenant_id: string;
    third_app: string;
    type: number;
    updated_at: string;
    isShortcuted: boolean;
    permissions: PermissionEnum[];
    [key: string]: any;
}

export interface IPageBase {
    author: IAuthor;
    lastModifier: IAuthor;
    created_at: string;
    created_by: string;
    followCount: number;
    icon_url: string;
    id: string;
    key: string;
    md5: string;
    origin_id: string;
    page_id: string;
    page_status: number;
    page_type: PageType;
    preview_text: string;
    published_at: string;
    published_by: string;
    published_content_id: string;
    space_category_id: string;
    space_id: string;
    status: number;
    summary: string;
    team_id: string;
    team_type?: number | string;
    tenant_id: string;
    title: string;
    type: string;
    updated_at: string;
    updated_by: string;
    version: number;
    permissions: PermissionEnum[];
    link: string;
    link_h5: string;
    form_id: string;
    form_status: number;
    [key: string]: any;
    isShared?: boolean;
}

export interface ITeam extends IBase {
    avatar_url: string;
    description: string;
    id: string;
    member_amount: string;
    member_avatar_urls: string[];
    name: string;
    status: number;
    team_type: string;
    tenant_id: string;
    type: number;
    extend?: object;
}

export interface TemplateCategory {
    id: string;
    tenant_id: string;
    name: string;
    created_by: string;
    order: number;
    status: number;
    templates: Template[];
    created_at: Date;
    updated_at: Date;
    team_id: string;
    visible: boolean;
    permission_type: number;
    hasQuery: boolean;
    type: string;
}

export interface Template {
    id: string;
    tenant_id: string;
    template_category_id: string;
    title: string;
    preview_text: string;
    cover_img_url: string;
    content: JSON;
    tag: string;
    status: number;
    source: string;
    created_by: string;
    category: TemplateCategory;
    created_at: Date;
    updated_at: Date;
    page_type: number;
    name: string;
    selectStatus: boolean;
    order: number;
    templates: Template[];
    visible: boolean;
    team_id: string;
    permission_type: number;
    isEdit: boolean;
    hasQuery: boolean;
    cycle: number;
}
export interface ICurrentRouteInfo {
    categoryId: string;
    spaceId: string;
    teamId: string;
    // currentPage: string;
}

export enum JoyspacePages {
    'MY_SPACE' = 'MY_SPACE',
    'TEAM_SPACE' = 'TEAM_SPACE',
}

// 设置公开文件夹
export enum OpenFolderStatus {
    'OPEN' = 2,
    'CLOSE' = 1,
}

export enum FileType {
    'Page' = 1,
    'Folder' = 4,
}

// 0-所有 1-联系人 2-部门 3-群组
export enum SharesMembersType {
    ALL = 0,
    CONTACT = 1,
    DEPARTMENT = 2,
    GROUP = 3,
}
export interface SharesMembers {
    length: number;
    memberType: SharesMembersType;
    pageId: string;
    search?: string;
    start: number;
    fileType: FileType;
}
