import React, { useCallback, useContext, useEffect, useState } from 'react';
import './WinToolbar.less';
import IconFont from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { useTranslation } from 'react-i18next';
import { FocusSDK } from '@/utils';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import { APP_NAME } from '@/config/name';

const MinIcon = <IconFont type="iconwin_Minimize" />;
const MaxIcon = <IconFont type="iconwin_Full" />;
const WinIcon = <IconFont type="iconwin_window" />;
const CloseIcon = <IconFont type="iconwin_Close" />;

interface WinToolbarProps {
    appFrame: string;
    isLogin: boolean;
    onItemClick: (button: 'min' | 'max' | 'normal' | 'quit') => void;
}

type AppFrameType = 'min' | 'max' | 'unMax' | 'hide' | 'close';

export default function WinToolbar({ isViewImage }: { isViewImage?: boolean }) {
    const [t_common] = useTranslation('common');
    const { appRuntimeEnv, focusSDK } = useContext<GlobalContextConfig>(GlobalContext);

    const isLogin = !!appRuntimeEnv.registryConfig.auth;
    const [isMax, setIsMax] = useState(focusSDK.getWindowFrame('max'));
    const onItemClick = useCallback(
        (appFrame: AppFrameType) => {
            focusSDK.setWindowFrame(appFrame);
            setTimeout(() => {
                setIsMax(focusSDK.getWindowFrame('max'));
            }, 0);
            const domMessageScroll: any = document.getElementById('message-box-container-scroll');
            if (!domMessageScroll) return;
            if (appFrame) {
                domMessageScroll.scrollTop = domMessageScroll.scrollHeight;
            }
        },
        [focusSDK]
    );

    const handleClose = useCallback(() => {
        if (isViewImage) {
            onItemClick('close');
        }
        let config = JSON.parse(FocusSDK.getConfigData());
        const closeApp = config?.settings?.closeApp;
        if (closeApp) {
            Prompt({
                title: t_common('setting.tip'),
                content: `确定要退出${APP_NAME}吗?`,
                okText: t_common('setting.confirm'),
                cancelText: t_common('setting.cancel'),
                onOk: async () => {
                    onItemClick('close');
                },
            });
        } else {
            onItemClick('hide');
        }
    }, [isViewImage, onItemClick, t_common]);

    if (appRuntimeEnv.registryConfig.client !== 'WINDOWS') {
        return null;
    }
    return (
        <div className={['winToolBar', isLogin ? 'winToolLogin' : ''].join(' ')}>
            <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                className="winToolItem"
                onClick={() => {
                    onItemClick('min');
                }}
            >
                {MinIcon}
            </div>
            <div
                className="winToolItem"
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                    onItemClick(isMax ? 'unMax' : 'max');
                }}
            >
                {focusSDK.getWindowFrame('max') ? WinIcon : MaxIcon}
            </div>
            <div
                className={['winToolItem', 'close'].join(' ')}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={handleClose}
            >
                {CloseIcon}
            </div>
        </div>
    );
}
