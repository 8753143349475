import { Page } from './types';
import { getJoySpaceReadOnlyPermission } from '@/components/Joyspace/config/permissions.config';
// 加载页面数量
export const PAGE_SIZE = 100;
export function buildJoyspaceIconUrl({
    title,
    pageType,
    type,
    apiHost,
}: {
    title: string;
    pageType: string;
    type: string;
    apiHost: string;
}) {
    return `${apiHost}/v1/common/icons?title=${title}&pageType=${pageType}&type=${type}&client=web`;
}

export function buildPagePathKey(pageType: number | string) {
    let keys = {
        subPath: 'page',
        routeTag: 'document_edit',
    };
    switch (pageType) {
        case 1:
            keys = {
                subPath: 'page',
                routeTag: 'document_edit',
            };
            break;
        case 2:
            keys = {
                subPath: 'report',
                routeTag: 'report_view',
            };
            break;
        case 3:
            keys = {
                subPath: 'note',
                routeTag: 'note_edit',
            };
            break;
        case 4:
            keys = {
                subPath: 'sheet',
                routeTag: 'spreadsheet_edit',
            };
            break;
        case 6:
            keys = {
                subPath: 'file',
                routeTag: 'file_view',
            };
            break;
        default:
            keys = {
                subPath: 'page',
                routeTag: 'document_edit',
            };
    }
    return keys;
}

// eslint-disable-next-line max-params
export function buildDeepLink(
    pageId: string,
    pageType: number | string,
    protocol = 'jdme',
    appId = '201909020601'
): string {
    const path = buildPagePathKey(pageType);
    const mparam = {
        appName: appId,
        standalone: 2,
        page_id: pageId,
        routeTag: path.routeTag,
    };
    return `${protocol}://jm/sys/rn?mparam=${encodeURIComponent(JSON.stringify(mparam))}`;
}

export function transformOriginToPage(page: any, apiHost: string): Page {
    return {
        type: 'page',
        id:
            page.page_type === 5
                ? page.origin?.id || page.page_id || page.id || ''
                : page.page_id || page.id || '',
        title: page.title || '无标题',
        iconUrl: buildJoyspaceIconUrl({
            title: page.title,
            pageType: page.page_type,
            type: page.type || '',
            apiHost,
        }),
        highlight: page.highlight,
        pageType: page.page_type === 5 ? page.origin?.page_type || 5 : page.page_type,
        mimeType: page.page_type === 5 ? page.origin?.type || '' : page.type || '',
        link: page.link || '',
        linkH5: page.linkH5 || '',
        permissions: page.permissions || [],
        permissionType: getJoySpaceReadOnlyPermission(),
        // status: page.status,
        disabled: !page.status,
    };
}
