import { request } from '@/components/Joyspace/api/request';

export interface IGetHistoryResult {
    pages: {
        title: string;
    }[];
    total: number;
}
// 获取关注空间
export function getFollow() {
    return request({
        api: 'joyspace.follow.list',
        path: '/v1/follow',
        method: 'GET',
    });
}
/* 此处要修改，不规范 */
// 删除关注空间
export function followRemove(target_id: number, target_type: number) {
    return request({
        api: 'joyspace.follow.remove',
        path: `v1/follow/${target_id}/${target_type}`,
        method: 'DELETE',
    });
}

/* 此处要修改，不规范 */
// 点击关注空间
export function followCreate(body: { target_id: string; target_type: string }) {
    return request({
        api: 'joyspace.follow.create',
        path: '/v1/follow',
        method: 'POST',
        body,
    });
}

export function add(id: string, type: number) {
    return request({
        api: 'joyspace.follow.create',
        path: '/v1/follow',
        method: 'POST',
        body: {
            target_id: id,
            target_type: type,
        },
    }).then(() => true);
}

export function remove(id: string, type: number) {
    return request({
        api: 'joyspace.follow.remove',
        path: `v1/follow/${id}/${type}`,
        method: 'DELETE',
    }).then(() => false);
}
