import React from 'react';
import { ListItemData, ListItemProps } from '@jd/focus-desktop-comps/lib/es/ListItem';
import {
    RecommendedType,
    StatusLabels,
    StatusLabelsText,
    StatusLabelsStyles,
    RecommendedTypeTitle,
} from '@/services/AppStore';
import Rate from '@/components/ThirdApp/components/Rate';
import './SearchAppItem.less';
import { changeEgovUrl } from '@/utils/tools';

const SearchAppItem = (
    props: { data: ListItemData; onClick?: (data: ListItemData) => void; t: any } & ListItemProps
) => {
    const { data, onClick, t, ...rest } = props;
    const appInfo = data.origin.ext;
    const lables = []
        .concat(appInfo?.networkEnvLabels || [])
        .concat(appInfo?.applicationStatusLabels || []);
    const getCornerMarkOptions = () => {
        const arr = [];
        if ((appInfo?.applicationStatusLabels || []).includes(StatusLabels.UNDER_MAINTENANCE)) {
            arr.push({
                text: StatusLabelsText[StatusLabels.UNDER_MAINTENANCE],
                styles: StatusLabelsStyles[StatusLabels.UNDER_MAINTENANCE],
            });
        }
        if (appInfo?.recommended === RecommendedType.RecommendedYes) {
            arr.push({
                text: RecommendedTypeTitle[RecommendedType.RecommendedYes],
                styles: StatusLabelsStyles.default,
            });
        }
        if ((appInfo?.applicationStatusLabels || []).includes(StatusLabels.BETA)) {
            arr.push({
                text: StatusLabelsText[StatusLabels.BETA],
                styles: StatusLabelsStyles[StatusLabels.BETA],
            });
        }
        return arr;
    };
    const cornerMarkOptions: any = getCornerMarkOptions();
    return (
        <div
            className="search-app-item"
            style={{ alignItems: 'flex-start' }}
            onClick={() => {
                if (onClick) {
                    onClick(data);
                }
            }}
        >
            <img className="search-app-item_img" src={changeEgovUrl(data.origin.avatar)} />
            <div className="search-app-item_right">
                <div className="search-app-item_right_info">
                    <span className="search-app-item_title color-primary">
                        {data.origin.appName}
                    </span>
                    {data.origin &&
                        data.origin.ext &&
                        data.origin.ext.recommended === RecommendedType.RecommendedYes && (
                            <Rate value={0} />
                        )}
                </div>
                <div>
                    <span className="third-content-item_lable">
                        {lables
                            .concat(data.origin.ext?.applicationStatusLabels || [])
                            .map((key: any, index: any) => {
                                const text = StatusLabelsText[key];
                                if (
                                    cornerMarkOptions.find((item: any) => item.text === text) ||
                                    text === StatusLabelsText[StatusLabels.VPN]
                                ) {
                                    return '';
                                }
                                return <span key={'net' + index}>{text}</span>;
                            })}
                    </span>
                </div>
                <p className="search-app-item_text">{data.origin.brief}</p>
                <p className="search-app-item_text">开发者：{data.origin.ext?.developer || ''}</p>
            </div>
        </div>
    );
};

export default SearchAppItem;
