export const time = {
    format(isostr: Date | string | number | undefined) {
        if (isostr === undefined) {
            return '';
        }
        const date = new Date(
            (isostr instanceof Date ? isostr : new Date(isostr)).getTime() + 60 * 60 * 1000 * 8
        );
        const today = new Date(new Date().toISOString().slice(0, 10));
        const tomorrow = new Date(today.getTime() + 86400000);
        if (today < date && date < tomorrow) {
            return '今天 ' + date.toISOString().substr(11, 5);
        }
        return date.toISOString().slice(0, 16).replace(/\-/g, '-').replace('T', ' ');
    },
};

export function isSameUser(
    selectUser: { app: string; userId: string; teamId: string },
    CurrentUser: { app: string; userId: string; teamId: string }
) {
    if (!selectUser || !CurrentUser) return;
    const selectUserId = selectUser.app + selectUser.userId + selectUser.teamId;
    const currentUserId = CurrentUser.app + CurrentUser.userId + CurrentUser.teamId;
    return Object.is(selectUserId, currentUserId);
}

export function isOverdue(time: number, taskStatus: number | undefined): boolean {
    if (!time) {
        return false;
    }
    const today = Date.now();
    // 逾期
    if (time < today && taskStatus !== 2) {
        return true;
    }
    return false;
}
