/* eslint-disable */

import Cookies from 'js-cookie';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { changeEgovUrl } from '@/utils/tools';
import { FocusSDK as focusSDK } from '@/utils';

let setCookieFlag = false;
export const getURLParameters = (url: any) =>
    (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
        (a: any, v: any) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a),
        {}
    );

export const objectToQueryString = (queryParameters: any) => {
    return queryParameters
        ? Object.entries(queryParameters).reduce((queryString, [key, val], index) => {
              const symbol = queryString.length === 0 ? '?' : '&';
              queryString += typeof val === 'string' ? `${symbol}${key}=${val}` : '';
              return queryString;
          }, '')
        : '';
};

// 通知卡片文档地址兼容
const cardHostProcess = (urlParam: string) => {
    if (!urlParam) {
        return urlParam;
    }
    let url = urlParam;
    let currentHost = focusSDK.getMeMainEntry();
    if (currentHost.indexOf('beijing.gov.cn')) {
        url = url.replace('jzb-joyspace.jdcloud.com', 'joyspace.jzb.beijing.gov.cn');
    }
    return url;
};

export const processJoyspaceUrl = function (urlParam: string) {
    if (!urlParam) {
        return urlParam;
    }
    let url = urlParam;
    url = changeEgovUrl(url);
    if (url.indexOf('joyspace') > 0) {
        let decodeUrl = decodeURIComponent(url.replace(/%/g, '%25')); // 将链接中的%进行编码
        let isDeeplink = DeeplinkEvent.isDeeplink(decodeUrl);
        let [flag, result] = isDeeplink;
        let paramIndex = url.indexOf('?');
        let originPath = url;
        if (paramIndex > 0) {
            originPath = url.substring(0, paramIndex);
        }
        if (flag) {
            url = originPath;
        }

        url = cardHostProcess(url);

        // let obj;
        // let paramStr: any;
        // let {
        //     'focus-token': focusToken,
        //     'focus-team-id': focusTeamId,
        //     'focus-client': focusClient,
        // } = Cookies.get() as any;
        // try {
        //     let newUrl = new URL(url);
        //     obj = new URLSearchParams(newUrl.search);
        //     obj.set('focus-token', focusToken || '');
        //     obj.set('focus-team-id', focusTeamId || '');
        //     obj.set('focus-client', focusClient || '');
        //     // obj.set('isSignleToken', 'true');
        //     paramStr = obj.toString();
        //     paramStr = `?${paramStr}`;
        // } catch (e) {
        //     obj = getURLParameters(url);
        //     obj['focus-token'] = focusToken || '';
        //     obj['focus-team-id'] = focusTeamId || '';
        //     obj['focus-client'] = focusClient || '';
        //     // obj['isSignleToken'] = 'true';
        //     paramStr = objectToQueryString(obj);
        // }
        // if (!setCookieFlag) {
        //     let currentUrl = location.href;
        //     let domain: any = '.beijing.gov.cn';
        //     if (currentUrl.indexOf('.jdcloud.com') > -1) {
        //         domain = '.jdcloud.com';
        //     }
        //     if (currentUrl.indexOf('.beijing.egov.cn') > -1) {
        //         domain = '.beijing.egov.cn';
        //     }
        //     Cookies.set('focus-token', focusToken || '', { domain: domain });
        //     Cookies.set('focus-team-id', focusTeamId || '', { domain: domain });
        //     Cookies.set('focus-client', focusClient || '', { domain: domain });
        //     // Cookies.set('isSignleToken', 'true', { domain: domain });
        //     Cookies.set('focus-app-id', 'gwork', { domain: domain });
        //     Cookies.set('focus-lang', 'zh-CN', { domain: domain });
        //     setCookieFlag = true;
        // }

        // url = `${originPath}${paramStr}`;
    }
    return url;
};

const originOpen = (window as any).open;

(window as any).open = function () {
    let array: any = [].slice.call(arguments, 0);
    let url = array[0];
    url = processJoyspaceUrl(url);
    console.log('url----', url);
    arguments[0] = url;
    array[0] = url;
    originOpen.apply(window, array);
};
