import React, { useState, useRef, Dispatch, SetStateAction, ChangeEvent, useContext } from 'react';
import { IprojectListType } from '../../types';
import { Input, Button, message } from 'antd';
import SelectAvatar from '../../components/Avatar/SelectAvatar';
import ProjectStatus from './ProjectStatus';
import { updateProject } from '../../api';
import context from '../../context';
import './index.less';

const { TextArea } = Input;
interface IProps {
    project: IprojectListType;
    onClose: Dispatch<SetStateAction<boolean>>;
    reloadProjectList: () => void;
}

export default function (props: IProps) {
    const { project, reloadProjectList, onClose } = props;
    const [icon, setIcon] = useState<string>(project.icon);
    const selectStatusRef = useRef<number>(project.progress);
    const [title, setTitle] = useState<string>(project.name ?? '');
    const [titleLength, setTitleLength] = useState(20);
    const [description, setDescription] = useState<string>(project.description ?? '');
    const [descriptionLength, setDescriptionLength] = useState(100);
    const [progressDesc, setProgressDesc] = useState<string>(project.progressDesc ?? '');
    const [progressDescLength, setProgressDescLength] = useState(500);
    const [loading, setLoading] = useState(false);
    const { t } = useContext(context);

    function selectAvatarChange(url: string) {
        setIcon(url);
    }

    function titleChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setTitle(newValue);
        setTitleLength(newValue.length - compactValue.length + 20);
    }
    function descriptionChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setDescription(newValue);
        setDescriptionLength(newValue.length - compactValue.length + 100);
    }
    function progressDescChange(e: ChangeEvent) {
        const newValue = (e.target as any).value;
        const compactValue = newValue.trimStart();
        setProgressDesc(newValue);
        setProgressDescLength(newValue.length - compactValue.length + 500);
    }

    async function save() {
        setLoading(true);
        const res = await updateProject(project.projectId, {
            name: title,
            icon,
            progress: selectStatusRef.current,
            progressDesc,
            description,
        });
        setLoading(false);
        if (res.errCode) return;
        reloadProjectList();
        onClose(false);
        message.success(t('operate_success'));
    }

    return (
        <div className="pm-basic-setting">
            <p>{t('basic_setting.status')}</p>
            <div style={{ marginBottom: 20 }}>
                <ProjectStatus
                    archive={project.archive}
                    status={project.progress}
                    onChange={(status) => {
                        selectStatusRef.current = status;
                    }}
                />
            </div>
            <div>
                <TextArea
                    allowClear
                    maxLength={progressDescLength}
                    rows={4}
                    disabled={project.archive}
                    placeholder={t('basic_setting.status_placeholder')}
                    value={progressDesc}
                    onChange={progressDescChange}
                />
                <span className="pm-basic-setting-wordcount">
                    <span style={{ color: progressDesc.length === 0 ? '' : ' #262626' }}>
                        {progressDesc.trim().length}
                    </span>
                    /500
                </span>
            </div>
            <p>{t('basic_setting.name')}</p>
            <Input
                maxLength={titleLength}
                disabled={project.archive}
                placeholder={t('basic_setting.name_placeholder')}
                allowClear
                value={title}
                onChange={titleChange}
            />
            <p>{t('basic_setting.description')}</p>
            <div>
                <TextArea
                    allowClear
                    disabled={project.archive}
                    maxLength={descriptionLength}
                    rows={4}
                    placeholder={t('basic_setting.description_placeholder')}
                    value={description}
                    onChange={descriptionChange}
                />
                <span className="pm-basic-setting-wordcount">
                    <span style={{ color: description.length === 0 ? '' : ' #262626' }}>
                        {description.trim().length}
                    </span>
                    /{descriptionLength}
                </span>
            </div>

            <p>{t('basic_setting.avatar')}</p>
            <div className="pm-basic-setting-avatar">
                <SelectAvatar
                    imgUrl={icon}
                    archive={project.archive}
                    onChange={selectAvatarChange}
                />
            </div>
            <p>
                <Button type="primary" disabled={project.archive} onClick={save} loading={loading}>
                    {t('save')}
                </Button>
            </p>
        </div>
    );
}
