import { guid } from './index';
import EventBus from './bus';
import Logger from './logger';
const logger = Logger.getLogger('utils/messages');
export interface MessageEventDataType<T = unknown> {
    id: string;
    event: string;
    data: T;
}
window.addEventListener('message', (event) => {
    if (event.data) {
        const data = event.data as MessageEventDataType;
        if (data.event) {
            // 登出操作
            if (data.event === 'logout') {
                // 发出用户登出操作
                EventBus.emit('user:logout');
            } else if (data.event === 'login') {
                const provider = (data.data as Record<string, unknown>).provider;
                EventBus.emit(`login:${provider}`, data.data);
            } else if (data.event.startsWith('chat:open')) {
                EventBus.emit(data.event, data.data);
            }
        }
    }
});

export function postMessageToParent(
    data: Pick<MessageEventDataType, 'event'> & Partial<MessageEventDataType>
) {
    logger.debug('postMessageToParent -> %O', data);
    if (window.top) {
        window.top.postMessage({ id: guid(), ...data }, '*');
    }
}
EventBus.on('chat_message_unread_count', function (data) {
    postMessageToParent({ data: data, event: 'message:unread-count' });
});
