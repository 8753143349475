/**
 * 加载图片
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './index.less';
import CircleProgress from '@/components/CircleProgress';
import {
    dealImageWidthHeight2,
    downLoadFile,
    getMessageFileName,
    getImgSrc,
    getThumbUrl,
    getXToken,
    getFileFilter,
    checkDownloadFilePathValid,
} from '@/utils/chat/index';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FileStatus, ImageChatMessage } from '@/types/chat';
import { detectOS, FocusSDK, isFocusEnv } from '@/utils';
import { nyrsfENStr } from '@/utils/date';
import { getPreviewImageModal } from '@/utils/chat/commonMessageModal';
import { message as toast } from 'antd';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import ImService from '@/server/ImService';
import imgLoading from '@/assets/img/img-loading.png';
import { changeEgovUrl } from '@/utils/tools';
import { addXtoken } from '@/utils/addToken';
import errImg from '@/assets/img/empty/err-img.png';
import bus from '@/utils/bus';
import { isGifImage } from '@/components/chat/utils/image';
interface ImagePreviewProps {
    message: ImageChatMessage;
    isUpload: boolean;
    uploadProgress: number;
    file?: Blob;
    onComplete: Function;
    onFailed: Function;
    isMergeForward?: boolean;
    modalMessages?: any[];
    status?: FileStatus;
}

type IImagePreviewProps = Readonly<ImagePreviewProps & WithTranslation & ChatState>;

interface MessageOperateState {
    width: number;
    height: number;
    thumbWidth: number;
    progress: number;
    display: string;
    imgSrc: string;
    thumbUrl: string;
    viewModal: boolean;
    modalLoading: boolean;
    isDragging: Boolean;
    dataUrl: any;
    path: string;
    newImgSrc: boolean;
}

class ImagePreview extends React.Component<IImagePreviewProps, MessageOperateState> {
    imageRef: React.RefObject<HTMLImageElement> = React.createRef();
    preImageRef: React.RefObject<HTMLImageElement> = React.createRef();
    thumbWidth = 600;
    thumbHeight = 400;

    constructor(props: IImagePreviewProps) {
        super(props);
        const { width: imageWidth, height: imageHeight, url } = props.message;
        // const [width, height] = dealImageWidthHeight(
        //     [imageWidth || 480, imageHeight || 1],
        //     false,
        //     480
        // );
        const [width, height] = dealImageWidthHeight2([imageWidth || 378, imageHeight || 180]);
        let thumbWidth = imageWidth || 100;
        if (!(imageWidth && imageWidth < 200 && imageHeight && imageHeight < 200)) {
            thumbWidth = this.thumbWidth;
        }
        this.state = {
            width: width,
            height: height,
            thumbWidth: thumbWidth,
            progress: 0,
            display: 'none',
            imgSrc: '',
            thumbUrl: '',
            viewModal: false,
            modalLoading: true,
            isDragging: false,
            dataUrl: '',
            path: '',
            newImgSrc: true,
        };
    }

    public componentDidMount() {
        const { message, sessionMessageMap, selectedSession, messageUploadStateMap } = this.props;
        let tempImageUrl: any;
        let MessageMap = sessionMessageMap[selectedSession.sessionId];
        MessageMap?.forEach((item) => {
            if (message.id === item.id) {
                tempImageUrl = messageUploadStateMap[item.id];
            }
            if (!tempImageUrl) return;
        });
        if (message.url) {
            const imgSrc = getImgSrc(message.url);
            let thumbUrl = '';
            // 小于100 * 100的图 直接显示原图
            if (message.width && message.width < 200 && message.height && message.height < 200) {
                thumbUrl = getThumbUrl(this.props.message.url, 100, 100);
            } else {
                thumbUrl = getThumbUrl(this.props.message.url, this.thumbWidth, this.thumbHeight);
            }
            if (
                (message as any).fileType?.includes('image/gif') ||
                isGifImage(message.url) ||
                detectOS() === 'Mac'
            ) {
                thumbUrl = imgSrc;
            }
            thumbUrl = addXtoken(thumbUrl);
            const downLocalPath = message.downLocalPath;
            // console.log('downLocalPath', downLocalPath);
            if (isFocusEnv() && downLocalPath) {
                FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                    if (exists) {
                        this.setState({ path: downLocalPath });
                    } else {
                        this.setState({ path: '' });
                    }
                });
            }
            this.setState({
                display: 'block',
                imgSrc: imgSrc,
                thumbUrl: thumbUrl,
                dataUrl: tempImageUrl?.message.url ? tempImageUrl?.message.url : thumbUrl,
            });
            this.checkImgExists(thumbUrl)
                .then((res) => {
                    this.setState({
                        newImgSrc: true,
                    });
                })
                .catch(() => {
                    this.setState({
                        newImgSrc: false,
                    });
                });
        } else if (this.props.file) {
            this.preViewLocalImage(this.props.file).catch(() => {
                this.setDefaultSrc();
            });
            return;
        }
    }

    shouldComponentUpdate(
        nextProps: IImagePreviewProps,
        nextState: MessageOperateState,
        nextContext: any
    ): boolean {
        const { message, status } = this.props;
        const { message: newMessage, status: newStatus } = nextProps;
        if (status === FileStatus.COMPLETED && newStatus === status) {
            return false;
        }
        if (message?.url?.includes(`base64`) && !newMessage?.url?.includes(`base64`)) {
            return false;
        }
        return true;
    }

    setDefaultSrc = () => {
        const imgRef = this.imageRef.current as any;
        if (imgRef) {
            imgRef.src = '';
        }
        this.props.onFailed();
    };

    preViewLocalImage = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const imgRef = this.imageRef.current as any;
                if (evt.target?.result && imgRef) {
                    imgRef.src = evt.target.result;
                    if (!this.state.imgSrc) {
                        this.setState({ imgSrc: imgRef.src });
                    }
                    this.props.onComplete();
                    resolve();
                } else {
                    reject(new Error(this.props.t('failed-to-load-picture')));
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            if (file instanceof Blob) {
                reader.readAsDataURL(file);
            }
        }).then(() => {
            this.setState({ display: 'block' });
        });
    };

    loadImageProgressCallback = (progress: number) => {
        this.setState({ progress });
    };

    downloadFile = () => {
        const { message, t } = this.props;
        const name = getMessageFileName(message);
        const url = getImgSrc(message.url);
        FocusSDK.downloadFile(url, {
            saveAs: false,
            filename: name,
            filter: getFileFilter(message),
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                // console.log('finish path', path, 'message id', message.mid);
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(message.sessionId, message.mid, {
                    downLocalPath: path,
                });
                if (result) {
                    this.setState({
                        path: path,
                        isDragging: false,
                    });
                    // toast.success('下载完成');
                } else {
                    toast.error(t('failed'));
                    this.setState({ isDragging: false });
                }
            },
        });
    };

    previewImage = async () => {
        const { message, isMergeForward, modalMessages } = this.props;
        getPreviewImageModal({ message, isMergeForward, modalMessages });
    };

    getFileName = () => {
        const message = this.props.message as any;
        const name = getMessageFileName(message);
        if (name) {
            return name;
        }
        return `${process.env.REACT_APP_NAME || '京办'}${nyrsfENStr(new Date().getTime())}.jpg`;
    };

    downImageFile = () => {
        downLoadFile(this.getFileName(), this.state.imgSrc);
    };

    onImageLoad = () => {
        this.setState({ modalLoading: false });
    };

    onDragStart = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const { connectState, message, t } = this.props;
        if (connectState && connectState.state === ConnectState.READY) {
            const { path, isDragging } = this.state;
            // console.log('new message', message, '哈哈哈', path);
            if (isDragging) {
                toast.warning(t('file-downloading-drag-save'));
                return;
            }
            if (!path) {
                this.setState({ isDragging: true });
                toast.warning(t('file-downloading-drag-save'));
                this.downloadFile();
            } else {
                FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.startDrag(path);
                    } else {
                        if (isDragging) {
                            toast.warning(t('file-downloading-drag-save'));
                            return;
                        }
                        this.setState({ isDragging: true });
                        toast.warning(t('file-downloading-drag-save'));
                        this.downloadFile();
                    }
                });
            }
        } else {
            toast.warning(t('drag-after-the-network-is-normal'));
            return;
        }
    };
    // 判断图片地址是否有效
    checkImgExists = (imgurl: any) => {
        return new Promise(function (resolve, reject) {
            let ImgObj = new Image();
            ImgObj.src = imgurl;
            ImgObj.onload = function (res) {
                resolve(res);
            };
            ImgObj.onerror = function (err) {
                reject(err);
            };
        });
    };
    public render() {
        const {
            width,
            height,
            display,
            viewModal,
            imgSrc,
            thumbUrl,
            modalLoading,
            thumbWidth,
            dataUrl,
            newImgSrc,
        } = this.state;
        const {
            isUpload,
            uploadProgress,
            message,
            isMergeForward,
            modalMessages,
            status,
        } = this.props;
        const progress = isUpload ? (uploadProgress as number) : this.state.progress;
        const isDrag = FocusSDK.getDeviceInfo().platform !== 'web' && !isMergeForward;

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        // console.log('thumUrl', newImgSrc);
        const reg = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i;
        let isBase64 = false;
        if (thumbUrl) {
            // console.log('是正则', reg.test(thumbUrl));
            isBase64 = reg.test(thumbUrl);
        }
        return (
            <div
                className="pre-view-box"
                style={{
                    width: newImgSrc === true ? width : 'auto',
                    // width: thumbWidth,
                    height: 'auto',
                }}
            >
                {newImgSrc === true ? (
                    <img
                        id="img"
                        className={`${(message as any).fileType === 'image/gif' && 'imgGif'}`}
                        ref={this.imageRef}
                        src={changeEgovUrl(dataUrl)}
                        alt=""
                        onClick={() => this.previewImage()}
                        width={width}
                        height={height}
                        // width={thumbWidth}
                        // height="auto"
                        style={{ display: display }}
                        data-id={message.id}
                        draggable={isDrag}
                        onDragStart={this.onDragStart}
                    />
                ) : (
                    <div className="errimg">
                        <img
                            id="img"
                            ref={this.imageRef}
                            src={changeEgovUrl(errImg)}
                            onClick={() => this.previewImage()}
                            alt=""
                            width={60}
                            height={54}
                            data-id={message.id}
                            draggable={isDrag}
                            onDragStart={this.onDragStart}
                        />
                        <span>图片加载失败</span>
                    </div>
                )}
                {/* {!isBase64 ? (
                    <img
                        id="img"
                        ref={this.imageRef}
                        src={changeEgovUrl(thumbUrl)}
                        alt=""
                        onClick={() => this.previewImage()}
                        width={width}
                        height={height}
                        // width={thumbWidth}
                        // height="auto"
                        style={{ display: display }}
                        data-id={message.id}
                        draggable={isDrag}
                        onDragStart={this.onDragStart}
                    />
                ) : (
                    <div className="pre-view-box" style={{ width, height }} />
                )} */}
                <div
                    className="circle-progress"
                    style={{
                        // display: modalLoading ? 'block' : 'none',
                        display: display === 'block' ? 'none' : 'block',
                    }}
                >
                    {/* <Progress type="circle" percent={progress} width={20} /> */}
                    <CircleProgress percent={progress} />
                </div>
                {/* {(status === FileStatus.UPLOADING ||
                    status === FileStatus.CANCELED ||
                    status === FileStatus.INIT) && (
                    <div
                        className="file-image-mask"
                        // style={{
                        //     right: width < 200 ? '0' : '16px',
                        // }}
                    >
                        <img className="imgLoading" src={imgLoading} alt="" />
                    </div>
                )} */}
            </div>
        );
    }
}
function mapStateToProps({ chat }: any) {
    const {
        connectState,
        sessionMessageMap,
        selectedSession,
        messageUploadStateMap,
    } = chat as ChatState;
    return { connectState, sessionMessageMap, selectedSession, messageUploadStateMap };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(ImagePreview));
