/**
 * 加入视频会议页面
 * @author zhangpengcheng15
 */
import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import './index.less';
import { FocusSDK } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';
import { Avatar } from '@/baseComponents/Avatar';

export default function MeetingUserListModal() {
    const [visible, setVisible] = useState(false);
    const [state, setState] = useState<any>({
        list: [],
        title: '',
    });

    useEffect(() => {
        function showUserListModal(args: any) {
            setVisible(true);
            const { title, list } = args;
            setState({
                title,
                list,
            });
        }
        FocusSDK.on('receiveMeeting.showUserListModal', showUserListModal);
        return () => {
            FocusSDK.off('receiveMeeting.showUserListModal', showUserListModal);
        };
    }, []);

    function closeModal() {
        setVisible(false);

        setState({
            list: [],
            title: '',
        });
    }

    function onCancel() {
        closeModal();
    }

    if (!visible) {
        return null;
    }
    return (
        <Modal
            className="common-modal-userlist"
            visible={visible}
            onCancel={onCancel}
            width={360}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div className="common-modal-userlist-header">
                <div className="title">{state.title || ''}</div>
            </div>
            <div className="common-modal-userlist-list">
                {state.list.map((user: any) => {
                    const { pin = '', name, avatar } = user;
                    return (
                        <div className="user-list-item" key={`${pin}${name}`}>
                            <Avatar
                                className="user-list-item-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name || ''}
                                styleObj={{
                                    width: 32,
                                    height: 32,
                                    borderRadius: 6,
                                    textAlign: 'center',
                                    lineHeight: 32,
                                    marginRight: 8,
                                }}
                            />
                            <div className="name">{name}</div>
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
}
