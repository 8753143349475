import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Button, Modal, message } from 'antd';
import { IprojectListType, ProjectRole } from '../../types';
import { exitOrDeleteProject, archiveProjectApi } from '../../api';
import context from '../../context';
import bus from '@/utils/bus';
import './index.less';
import { useTabs } from '../../components/Tabs';
const { confirm } = Modal;
interface IProps {
    onClose: Dispatch<SetStateAction<boolean>>;
    setCurrentTab: Dispatch<SetStateAction<number>>;
    project: IprojectListType;
    reloadProjectList: () => void;
}

export default function (props: IProps) {
    const { onClose, project, reloadProjectList, setCurrentTab } = props;
    const { t } = useContext(context);
    const tabs = useTabs();
    function deleteProject() {
        confirm({
            title: t('list.delete'),
            content: t('list.delete_confirm'),
            cancelText: t('cancel'),
            okText: t('confirm'),
            onOk: () => {
                return exitOrDeleteProject(project.projectId).then((data) => {
                    if (data.errCode) return;
                    tabs.close(project.projectId);
                    reloadProjectList();
                    message.success(t('list.delete_success'));
                    onClose(false);
                });
            },
        });
    }

    function quitProject() {
        if (project?.userInfo?.userRole === ProjectRole.OWNER) {
            confirm({
                title: t('list.quit'),
                content: t('list.quit_confirm'),
                cancelText: t('cancel'),
                okText: t('list.owner_replace'),
                onOk: () => {
                    setCurrentTab(1);
                    // tabs.close(project.projectId);
                },
            });
            return;
        }

        confirm({
            title: t('list.quit'),
            content: t('list.quit_confirm2'),
            cancelText: t('cancel'),
            okText: t('confirm'),
            onOk: () => {
                return exitOrDeleteProject(project.projectId).then((data) => {
                    if (data.errCode) return;
                    tabs.close(project.projectId);
                    reloadProjectList();
                    message.success(t('list.quit_success'));
                    onClose(false);
                });
            },
        });
    }

    function archiveProject() {
        confirm({
            title: t('list.archive'),
            content: project.archive ? t('list.cancel_archive_confirm') : t('list.archive_confirm'),
            cancelText: t('cancel'),
            okText: t('confirm'),
            onOk: () => {
                return archiveProjectApi(project.projectId, !project.archive).then((data) => {
                    if (data.errCode) return;
                    bus.emit('project:isArchive', {
                        projectId: project.projectId,
                        archive: !project.archive,
                    });
                    reloadProjectList();
                    message.success(t('operate_success'));
                    onClose(false);
                });
            },
        });
    }
    return (
        <div className="pm-zhuanban-operate-wrap">
            {project.userInfo?.userRole === ProjectRole.OWNER && (
                <div>
                    <h3>{t('list.archive')}</h3>
                    <p>
                        {project.archive
                            ? t('list.cancel_archive_confirm')
                            : t('list.archive_warning')}
                    </p>
                    <Button style={{ marginBottom: 24 }} onClick={archiveProject}>
                        {project.archive ? t('list.cancel_archive') : t('list.archive')}
                    </Button>
                </div>
            )}
            {project.userInfo?.userRole === ProjectRole.OWNER && !project.archive && (
                <div>
                    <h3>{t('list.delete')}</h3>
                    <p>{t('list.delete_warning')}</p>
                    <Button danger style={{ marginBottom: 24 }} onClick={deleteProject}>
                        {t('list.delete')}
                    </Button>
                </div>
            )}
            {(!project.archive || project.userInfo?.userRole !== ProjectRole.OWNER) && (
                <>
                    <h3>{t('list.quit')}</h3>
                    <p>
                        {project.userInfo?.userRole === ProjectRole.OWNER
                            ? t('list.quit_warning')
                            : t('list.quit_warning2')}
                    </p>
                    <Button danger onClick={quitProject}>
                        {t('list.quit')}
                    </Button>
                </>
            )}
        </div>
    );
}
