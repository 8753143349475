import { PageType } from '@/components/Joyspace/const';
import { documentPng, tablePng, weeklyPng } from '@/assets/img/file-icon';

export const getPageIconByPagetype = (pageType: PageType): any => {
    switch (pageType) {
        case PageType.Page:
            return documentPng;
        case PageType.Sheet:
            return tablePng;
        case PageType.Reporting:
            return weeklyPng;
        default:
            return documentPng;
    }
};

export const urlReg = /((ht|f)tps?):\/\/([\w\-]+(\.[\w\-]+)*\/)*[\w\-]+(\.[\w\-]+)*\/?(\?([\w\-\.,@?^=%&:\/~\+#]*)+)?/gi;
export const urlPatt = /(((https?):\/\/)|(3.cn))[-A-Za-z0-9+&#\/%?=~_|!:,.;]+[-A-Za-z0-9+&#\/%=~_|]/gi;
// 移动端使用的正则
// cosnt urlPatt = '(http[s]?://[a-zA-Z]|[0-9]|[$-_.&+/]|[!*(),]|(?:%[0-9a-fA-F][0-9a-fA-F])|(?)|(#)|(=))+';
