/* eslint-disable max-nested-callbacks */
import React, { StyleHTMLAttributes, ReactNode } from 'react';
import { Schedule } from '@/api/calendar';
import dayjs, { Dayjs } from 'dayjs';
import { assignTime } from '.';
import { nanoid } from 'nanoid';
export interface ComputeSchedule extends Schedule {
    computeStart: number;
    computeEnd: number;
    computeExpend?: number;
    gId?: string;
}
const colorList = [
    // '#cecdca',
    // '#9b9a97',
    '#8c2e00',
    '#f55d00',
    '#e9a800',
    '#00876b',
    '#0078df',
    '#6724de',
    '#dd0081',
    '#ff001a',
];

export function getColor() {
    const length = colorList.length;
    const index = Math.floor(Math.random() * (length - 1));
    return colorList[index];
}

const list: any[] = [
    { title: '1', beginTime: 10, endTime: 50, color: getColor(), calendarId: 1 },
    { title: '2', beginTime: 75, endTime: 99, color: getColor(), calendarId: 2 },
    { title: '3', beginTime: 45, endTime: 69, color: getColor(), calendarId: 3 },
    { title: '4', beginTime: 55, endTime: 59, color: getColor(), calendarId: 4 },
    { title: '5', beginTime: 45, endTime: 59, color: getColor(), calendarId: 5 },
    { title: '6', beginTime: 25, endTime: 39, color: getColor(), calendarId: 6 },
    { title: '7', beginTime: 25, endTime: 39, color: getColor(), calendarId: 7 },
    { title: '8', beginTime: 60, endTime: 80, color: getColor(), calendarId: 8 },
];

/**
 * 判断
 * @param line
 * @param ele
 */
function judgeConflict(line: ComputeSchedule[], ele: ComputeSchedule) {
    let conflict = false;
    line.some((l) => {
        if (ele.computeEnd <= l.computeStart || ele.computeStart >= l.computeEnd) {
            return false;
        }
        conflict = true;
        return true;
    });
    return conflict;
}

/**
 * 处理每个日历的扩展信息，计算出最大扩展倍数
 * @param map
 */
function handleExtern(map: Array<ComputeSchedule[]>) {
    const lineLength = map.length;
    map.forEach((line, index) => {
        if (index === lineLength - 1) {
            return;
        }
        // 遍历每列所有元素
        line.forEach((ele) => {
            let canExpend = true;

            const arr = map.slice(index + 1);
            arr.every((l) => {
                const conflict = judgeConflict(l, ele);
                // let conflict = false;
                // l.forEach((lItem: any) => {
                //     if (
                //         ele.computeEnd <= lItem.computeStart ||
                //         ele.computeStart >= lItem.computeEnd
                //     ) {
                //         // conflict = false;
                //     } else {
                //         if (ele.gId) {
                //             lItem.gId = ele.gId;
                //         } else {
                //             if (lItem.gId) {
                //                 ele.gId = lItem.gId;
                //             } else {
                //                 ele.gId = nanoid();
                //                 lItem.gId = ele.gId;
                //             }
                //         }
                //         conflict = true;
                //     }
                // });
                if (conflict) {
                    canExpend = false;
                    return false;
                }
                if (ele.computeExpend) {
                    ele.computeExpend += 1;
                } else {
                    ele.computeExpend = 2;
                }
                return true;
            });
        });
    });
}

/**
 * 将传入的日程放入不同的列中
 * @param list
 * @return map 分割完成的二维数组
 */
function splitCols(list: ComputeSchedule[]): Array<ComputeSchedule[]> {
    // 按时间长短排序，优先放置时间最长的元素
    const copy = [...list];
    copy.sort((a, b) => {
        // eslint-disable-next-line prettier/prettier
        return b.endTime - b.beginTime - (a.endTime - a.beginTime);
    });

    const map: Array<ComputeSchedule[]> = [];
    const firstLine: ComputeSchedule[] = [];
    map.push(firstLine);

    copy.forEach((c) => {
        let canPlace = false;
        map.some((line) => {
            let conflict = judgeConflict(line, c);
            if (!conflict) {
                line.push(c);
                canPlace = true;
                return true;
            }
            return false;
        });
        // 如果当前所有列都没有位置，放置到新列
        if (!canPlace) {
            const temp: ComputeSchedule[] = [];
            temp.push(c);
            map.push(temp);
        }
    });
    return map;
}

/**
 * 生成style对象
 * @param ele
 * @param basicWidth
 * @param col
 */
// eslint-disable-next-line max-params
export function getComputedStyle(
    ele: ComputeSchedule,
    basicWidth: number,
    col: number,
    gIdMap: any
) {
    if (ele.gId) {
        // let realCol = 0;
        // for (let i = 0; i < gIdMap[ele.gId].length; i++) {
        //     if (gIdMap[ele.gId][i].scheduleId === ele.scheduleId) {
        //         realCol = i;
        //     }
        // }
        // const trans = selfTimeAdapter(gIdMap[ele.gId], dayjs());
        const thisPartMap = splitCols(gIdMap[ele.gId]);
        let realWidth = Number((100 / thisPartMap.length).toFixed(2));
        let finalWidth = realWidth * (ele.computeExpend || 1);
        // gId关联日程集合中的排在最后一列的日程由于computeExpend参数逻辑影响，
        // finalWidth可能会超出单日单元宽度，在周视图中出现显示越界，所以此时需要截断处理
        if (finalWidth + col * realWidth > 100) {
            finalWidth = Number((100 - col * realWidth).toFixed(2));
        }
        const style: any = {
            width: finalWidth + '%',
            top: ele.computeStart + '%',
            left: col * realWidth + '%',
            position: 'absolute',
            height: (ele.computeEnd - ele.computeStart || 0) + '%',
            overflowY: 'hidden',
        };
        return style;
    } else {
        const style: any = {
            width: basicWidth * (ele.computeExpend || 1) + '%',
            top: ele.computeStart + '%',
            left: col * basicWidth + '%',
            position: 'absolute',
            height: (ele.computeEnd - ele.computeStart || 0) + '%',
            overflowY: 'hidden',
        };
        return style;
    }
}

function selfTimeAdapter(list: Schedule[], current: Dayjs) {
    if (!list || list.length === 0) {
        return [];
    }
    const trans = (ele: Schedule) => {
        let scheduleEnd = ele.scheduleEndTime || dayjs();
        let scheduleStart = ele.scheduleBeginTime || dayjs();
        const computeStart =
            ((scheduleStart.hour() * 60 + scheduleStart.minute()) * 100) / (24 * 60);
        const computeEnd = ((scheduleEnd.hour() * 60 + scheduleEnd.minute()) * 100) / (24 * 60);
        const copy: ComputeSchedule = {
            ...ele,
            computeStart,
            computeEnd,
        };
        return copy;
    };
    return list.map(trans);
}

/**
 * 获得计算完成的元素列表
 * @param list
 * @param generateItem
 */
export function getComputedElements({
    list,
    current,
    timeAdapter = selfTimeAdapter,
}: {
    list: Schedule[];
    current: Dayjs;
    timeAdapter?: (list: Schedule[], current: Dayjs) => ComputeSchedule[];
}) {
    if (list.length === 0) {
        return [];
    }
    let trans = timeAdapter(list, current);

    // 基于scheduleId去重
    // let uniqueByScheduleId = (arr1: any) => {
    //     const res = new Map();
    //     return arr1.filter((a: any) => !res.has(a.scheduleId) && res.set(a.scheduleId, 1));
    // };
    // trans = uniqueByScheduleId(trans);
    // console.log(trans);

    // 提前通过给相互存在关联关系的日程赋予gId进行关联性分组。
    let unsetList = trans;
    let doneList: any[] = [];
    // 先根据开始时间排下序
    unsetList = unsetList.sort((a: ComputeSchedule, b: ComputeSchedule) => {
        return a.scheduleBeginTime.valueOf() - b.scheduleBeginTime.valueOf();
    });
    while (unsetList.length > 0) {
        let cur = unsetList[0];
        if (cur.gId) {
            doneList.push(cur);
            unsetList.shift();
            continue;
        }
        let hasInDone = false;
        let hasInUnset = false;
        let inDoneId = '';
        let newId = nanoid();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < doneList.length; i++) {
            if (
                cur.computeEnd > doneList[i].computeStart &&
                cur.computeStart < doneList[i].computeEnd
            ) {
                cur.gId = doneList[i].gId;
                hasInDone = true;
                inDoneId = doneList[i].gId;
                break;
            }
        }
        if (unsetList.length > 1) {
            for (let j = 1; j < unsetList.length; j++) {
                if (
                    cur.computeEnd > unsetList[j].computeStart &&
                    cur.computeStart < unsetList[j].computeEnd
                ) {
                    hasInUnset = true;
                    if (hasInDone) {
                        cur.gId = inDoneId;
                        unsetList[j].gId = inDoneId;
                    } else {
                        cur.gId = newId;
                        unsetList[j].gId = newId;
                    }
                }
            }
        } else {
            if (!hasInDone) {
                cur.gId = nanoid();
            }
        }
        if (!hasInUnset && !hasInDone) {
            cur.gId = nanoid();
        }
        doneList.push(cur);
        unsetList.shift();
    }
    // console.log('🔥', doneList);
    const map = splitCols(doneList);
    // console.log('🔥map', map);
    handleExtern(map);

    const lineLength = map.length;
    const basicWidth = Number((100 / lineLength).toFixed(2));
    let gIdMap: any = {};
    map.forEach((line: any) => {
        line.forEach((item: any) => {
            if (item.gId) {
                if (gIdMap[item.gId]) {
                    gIdMap[item.gId].push(item);
                } else {
                    gIdMap[item.gId] = [item];
                }
            }
        });
    });
    const content = map.reduce((p, v, index) => {
        const items = v.map((ele) => {
            const style = getComputedStyle(ele, basicWidth, index, gIdMap);
            return { schedule: ele, styles: style, current };
        });

        return p.concat(items);
    }, [] as any[]);
    return content;
}
