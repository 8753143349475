class RefreshMessageBox {
    messageMap = new Map();
    timer: any;
    time: number; // 以多长时间间隔循环 默认1s
    constructor(time?: number) {
        this.time = time || 1000;
    }

    on(id: number, cb: Function) {
        if (!this.messageMap.has(id)) {
            this.messageMap.set(id, cb);
        }
    }

    off(id: number) {
        if (this.messageMap.has(id)) {
            this.messageMap.delete(id);
        }
    }

    destroy() {
        this.messageMap = new Map();
        this.timer = null;
    }

    startTimer() {
        this.timer = setInterval(() => {
            const values = Array.from(this.messageMap.values());
            values.map((i: Function) => i.call(this));
        }, this.time);
    }
    clearTimer() {
        clearInterval(this.timer);
    }
}
export default RefreshMessageBox;
