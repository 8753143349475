import React from 'react';
import { Chain, NEXT_FLAG } from '@/services/ChainService';
import DefaultNoticeCard from '@/components/chat/message/AINoticeCard/DefaultNoticeCard';
import CalendarNoticeCard from '@/components/Calendars/AINoticeCard';
import WorkNoticeCard from '@/components/Work/AINoticeCard';
import SuperviseNoticeCard from '@/components/Supervise/AINoticeCard';
import ExternalNewUserNoticeCard from '@/components/chat/message/AINoticeCard/ExternalNewUserNoticeCard';
import IdentityInfoToPerfect from '@/components/chat/message/AINoticeCard/IdentityInfoToPerfect';
import ApplyGroupCard from '@/components/chat/message/AINoticeCard/ApplyGroupCard';
import ApplyTeamCard from '@/components/chat/message/AINoticeCard/ApplyTeamCard';
import JoyMeetingNoticeCard from '@/components/chat/message/AINoticeCard/JoyMeetingNoticeCard';
import CloudLinkNoticeCard from '@/components/chat/message/AINoticeCard/CloudLinkNoticeCard';
import JoyspaceNoticeCard from '@/components/Joyspace/AINoticeCard';
import AiNotificationCard from '@/components/chat/message/GroupNotification/components/AiNotificationCard';
import AiSolitaireCard from '@/components/Work/Solitaire/AiSolitaireCard';

// 日历
const calendarCardChain = new Chain((message: any) => {
    if (message?.from.pin === '~me69') {
        return <CalendarNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});

// 任务
const workCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_PZzzvgDrpZVAefKImEfo') {
        return <WorkNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});

// 催办
const superviseCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_0Gl8b1I3si21n0VVNkrF') {
        return <SuperviseNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});

// 云文档
const joyspaceCardChain = new Chain((message: any) => {
    if (message?.from.pin === '~joyspace') {
        return <JoyspaceNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});
// 组通知
const AiNotificationCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_qhTSqJ4pYYjuK7ZlYXg5') {
        return <AiNotificationCard message={message} />;
    }
    return NEXT_FLAG;
});

// 组接龙
const AiSolitaireCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_1ZIFjH1IBE20KYEU8NSP') {
        return <AiSolitaireCard message={message} />;
    }
    return NEXT_FLAG;
});

// 云文档邀请卡片 代码好像没用到了
// const joyspaceInviteCardChain = new Chain((message: any) => {
//     if (message?.from.pin === '~joyspace' && message?.infox.type === 'invite') {
//         return <JoyspaceInviteCard message={message} />;
//     }
//     return NEXT_FLAG;
// });

// 新用户申请
const externalNewUserCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_yQXxq4WhIpF2CAdw01Ti') {
        return <ExternalNewUserNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});

// 北京通号去完善
const identityInfoToPerfectCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_9IdJIcxdZmWRmXNLw3Qc') {
        return <IdentityInfoToPerfect message={message} />;
    }
    return NEXT_FLAG;
});

// 群助手
const applyGroupCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_uUyYexheoyRkjp6DpVtx') {
        return <ApplyGroupCard message={message} />;
    }
    return NEXT_FLAG;
});

// 团队助手
const applyTeamCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_0GABWH9r96OvSSU0951x') {
        return <ApplyTeamCard message={message} />;
    }
    return NEXT_FLAG;
});

const MeetingCardChain = new Chain((message: any) => {
    if (
        message?.from.pin === 'eopen-push_4iJ0jfgCLTXdz6YED06W' ||
        message?.from.pin === 'eopen-push_iZ1ZpJzbzAdprQiPoImD' ||
        message?.from.pin === 'eopen-push_vbNWhC0nRvvN1q3nM5Zy'
    ) {
        return <CloudLinkNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});

const joyMeetingCardChain = new Chain((message: any) => {
    if (message?.from.pin === 'eopen-push_jif5OZ0gf6qNotBVAraI') {
        return <JoyMeetingNoticeCard message={message} />;
    }
    return NEXT_FLAG;
});

// 默认
const defaultCardChain = new Chain((message) => {
    return <DefaultNoticeCard message={message} />;
});

class AINoticeCardService {
    imService: any;
    chainList: Chain<any>[];
    constructor() {
        this.imService = null;
        this.chainList = [
            calendarCardChain,
            workCardChain,
            superviseCardChain,
            joyspaceCardChain,
            // joyspaceInviteCardChain,
            externalNewUserCardChain,
            identityInfoToPerfectCardChain,
            applyGroupCardChain,
            applyTeamCardChain,
            MeetingCardChain,
            joyMeetingCardChain,
            AiNotificationCardChain,
            AiSolitaireCardChain,
            // 兜底卡片，要保证放在最后
            defaultCardChain,
        ];
    }
    setImService(imService: any) {
        this.imService = imService;
        this.initChainList(this.chainList);
    }
    initChainList(chains: Chain<any>[]) {
        this.imService?.addAICardChins(chains);
    }
}

export default new AINoticeCardService();
