import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'dva';
import { debounce } from 'lodash';
import { message, Popover, Table, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { changeEgovUrl } from '@/utils/tools';

import {
    TableCell,
    CircleShapeIcon,
    getColumnSearchProps,
} from '@/components/Projects/components/CustomTableProps';
import {
    TASK_STATUS,
    NewTaskModel,
    WorkUserInfo,
    PRIORITY_TYPE,
    PROCESS_STATUS,
    TaskListParams,
    IEditTaskItemParam,
    USER_ROLE,
} from '@/types/newWork';
import {
    IFilterItem,
    TASK_STATUS_MAP,
    TASK_STATUS_LIST,
    PRIORITY_TYPE_MAP,
    PRIORITY_TYPE_LIST,
    PROCESS_STATUS_MAP,
    PROCESS_STATUS_LIST,
} from '@/components/Projects/emnu';

import bus from '@/utils/bus';
import IconFont from '@/components/icon';
import { TaskPartEnum, TaskModel } from '@/types/work';
import { UserCardType } from '@/types/UserCard';
import { isOverdue } from '@/components/Projects/util';
import { generateDefaultTimeFormat } from '@/components/Work/utils';
import TypeSelect from '@/components/Work/BaseComponents/TypeSelect';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { FocusSDK } from '@/utils';
import { ItemProps } from '@/components/Projects/components/SideTabs';
import './index.less';
import Loading from '@/components/Loading';
import { color } from 'html2canvas/dist/types/css/types/color';
import { getTaskList } from '@/api/work';
// 自定义过滤图标
// const filterIcon = (filtered: boolean) => {
//     return (
//         <IconFont
//             type="iconic_pc_task_screeningsvg"
//             style={{ color: filtered ? '#1890ff' : undefined }}
//         />
//     );
// };
// 协同人 图标
const executorIcon = <IconFont type="iconic_pc_task_Collaborator" className="table-cell__icon" />;

export const onOpenUserCard = (data: { appId: string; userId: string; teamId: string }) => {
    const { userId, appId, teamId } = data;
    bus.emit('app:toggle-user-card-modal:show', {
        visible: true,
        type: UserCardType.User,
        userId,
        appId,
        teamId,
    });
};

const allPriorityTypes = [
    PRIORITY_TYPE.LOW_PRIORITY,
    PRIORITY_TYPE.NORMAL_PRIORITY,
    PRIORITY_TYPE.MIDDLE_PRIORITY,
    PRIORITY_TYPE.HIGH_PRIORITY,
];
interface IProps {
    // total: number;
    offset: number;
    offsets: number[];
    userData: any;
    // taskList: NewTaskModel[];
    setOffset: (params: number) => void;
    setOffsets: (params: number[]) => void;
    // getTasks: (params: TaskListParams) => void;
    changePersonalTaskDraw: (taskId: string, activeTab?: string) => void;
    editTaskItem: (params: IEditTaskItemParam) => void;
    priorityTypes: PRIORITY_TYPE[];
    taskStatuses: TASK_STATUS[];
    processStatuses: PROCESS_STATUS[];
    setPriorities: (priorities: PRIORITY_TYPE[]) => void;
    setTaskStatus: (taskStatuses: TASK_STATUS[]) => void;
    // setTaskList: (params: TaskListParams) => void;
    setProcessStatus: (processStatus: PROCESS_STATUS[]) => void;
    setUserRole: (userRole: number) => void;
    active?: ItemProps;
    userRole: number;
    sortType: number;
    // taskLoading: boolean;
    changeSelectedTaskItem: (item: TaskModel) => {};
}
function TaskTable(props: IProps) {
    const {
        // total,
        // offset,
        // offsets,
        // taskList,
        // getTasks,
        // userData,
        // sortType,
        // setOffset,
        // setOffsets,
        // taskStatuses,
        // priorityTypes,
        // processStatuses,
        // editTaskItem,
        // setPriorities,
        // setTaskStatus,
        // setTaskList,
        // setProcessStatus,
        // changePersonalTaskDraw,
        // active,
        // setUserRole,
        // userRole,
        changeSelectedTaskItem,
    } = props;

    const { t: t1 } = useTranslation('work');
    const { t } = useTranslation('calendar');
    const initHeight = document.body.scrollHeight - 160;
    const [offset, setOffset] = useState(0);
    const [taskList, setTaskList] = useState<NewTaskModel[]>([]);
    const [total, setTotal] = useState(0);
    const [taskLoading, setTaskLoading] = useState(false);
    // 影响全局state里面的值了
    // useEffect(() => {
    //     if (active?.processStatuses) {
    //         setProcessStatus(active?.processStatuses);
    //     }
    //     if (active?.userRole) {
    //         setUserRole(active?.userRole);
    //     } else {
    //         setUserRole(0);
    //     }
    //     setOffset(0);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [active]);

    // useEffect(() => {
    //     if (offset === 0) {
    //         setTaskList({
    //             tasks: [],
    //             total: 0,
    //             offset: 0,
    //             limit: 20,
    //             userRole: USER_ROLE.RESPONSIBLE,
    //         });
    //     }
    //     getTasks({
    //         limit: 20,
    //         taskStatuses,
    //         processStatuses: [],
    //         priorityTypes: allPriorityTypes,
    //         offset,
    //         userRole: USER_ROLE.RESPONSIBLE,
    //     });

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [getTasks, taskStatuses, processStatuses, priorityTypes, offset, sortType]);

    // useEffect(() => {
    //     function handleReloadList(receive: any) {
    //         if (!receive) {
    //             return message.error('多端同步数据格式错误');
    //         }
    //         const {
    //             data: { part },
    //         } = receive;
    //         if (
    //             ![
    //                 TaskPartEnum.PERSONAL_TASK,
    //                 TaskPartEnum.MEANWHILE_TASK,
    //                 TaskPartEnum.PROJECT_TASK,
    //             ].includes(part)
    //         ) {
    //             return;
    //         }
    //         getTasks({
    //             limit: 20,
    //             taskStatuses,
    //             processStatuses: [],
    //             priorityTypes: allPriorityTypes,
    //             offset: 0,
    //             userRole: USER_ROLE.RESPONSIBLE,
    //             sortType,
    //         });
    //     }
    //     function handleNetworkChange(data: any) {
    //         const { connectState } = data;
    //         if (connectState.state === ConnectState.READY) {
    //             // getTasks({ limit: 20, taskStatuses, processStatuses, priorityTypes, offsets });
    //             getTasks({
    //                 limit: 20,
    //                 taskStatuses,
    //                 processStatuses: [],
    //                 priorityTypes: allPriorityTypes,
    //                 offset: 0,
    //                 userRole: USER_ROLE.RESPONSIBLE,
    //                 sortType,
    //             });
    //         }
    //     }
    //     function handleProjectArchive() {}
    //     bus.on(`chat_message_multiSync_event:joyWork`, handleReloadList);
    //     bus.on('chat:connect_state_change', handleNetworkChange);
    //     bus.on('project:isArchive', handleProjectArchive);
    //     return () => {
    //         bus.off(`chat_message_multiSync_event:joyWork`, handleReloadList);
    //         bus.off('chat:connect_state_change', handleNetworkChange);
    //         bus.off('project:isArchive', handleProjectArchive);
    //     };
    // }, [getTasks, taskStatuses, processStatuses, priorityTypes, offsets, sortType]);

    // 根据 优先级 类型 过滤
    // const filterByPriority = debounce(function (priorities: IFilterItem[]) {
    //     const localPriorities = priorities.map((item) => item.value) as PRIORITY_TYPE[];
    //     setPriorities(localPriorities);
    //     setOffset(0);
    // }, 500);

    // // 根据 任务状态 过滤
    // const filterByTaskStatus = debounce(function (taskStatus: IFilterItem[]) {
    //     const localStatus = taskStatus.map((item) => item.value) as TASK_STATUS[];
    //     setTaskStatus(localStatus);
    //     setOffset(0);
    // }, 500);

    // // 根据 我的推进状态 过滤
    // const filterByProcessStatus = debounce(function (processStatus: IFilterItem[]) {
    //     const localStatus = processStatus.map((item) => item.value) as PROCESS_STATUS[];
    //     setProcessStatus(localStatus);
    //     setOffset(0);
    // }, 500);

    // // 修改单条任务的类型
    // const handlePriorityChange = function (priorityType: PRIORITY_TYPE, { taskId }: NewTaskModel) {
    //     editTaskItem({ priorityType, taskId });
    // };
    function loadMore(offset: number) {
        setOffset(offset);
        // const localOffsets = Array.from(new Set([...offsets, offset]));
        // setOffsets(localOffsets);
    }

    useEffect(() => {
        setTaskLoading(true);
        getTaskList({
            limit: 20,
            offset,
            taskStatuses: [],
            priorityTypes: allPriorityTypes,
            processStatuses: [],
        })
            .then(([res, err]) => {
                if (err || !res) {
                    console.error(err);
                    message.error(err);
                    return;
                }
                setTaskList((taskList) => {
                    return taskList.concat(res.tasks);
                });
                setTotal(res.total);
            })
            .finally(() => {
                setTaskLoading(false);
            });
    }, [offset]);

    const columns: any = [
        {
            title: '',
            width: '40px',
            dataIndex: 'radio',
            // ellipsis: true,
            align: 'center',
            render: (title: any, { taskId }: NewTaskModel) => (
                <div>
                    <Radio
                        onChange={() => {
                            // const taskInof: any = taskList.find(
                            //     (item: any) => item.taskId === taskId
                            // );
                            // if (!taskInof) {
                            //     return;
                            // }
                            // changeSelectedTaskItem(taskInof);
                        }}
                    />
                </div>
            ),
        },
        {
            title: '类型',
            width: '40px',
            dataIndex: 'priorityType',
            align: 'left',
            render: (priorityType: PRIORITY_TYPE, record: NewTaskModel) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '-2px',
                        }}
                    >
                        <div style={{ width: 18, position: 'relative' }}>
                            {priorityType === null || priorityType === PRIORITY_TYPE.NO_PRIORITY ? (
                                <div style={{ height: 38 }}>无</div>
                            ) : (
                                <img
                                    style={{ width: 100 + '%' }}
                                    src={changeEgovUrl(PRIORITY_TYPE_MAP[priorityType]?.url)}
                                    alt={PRIORITY_TYPE_MAP[priorityType]?.alt}
                                    title={PRIORITY_TYPE_MAP[priorityType]?.alt}
                                />
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: '任务名称',
            width: '140px',
            dataIndex: 'title',
            ellipsis: true,
            align: 'left',
            render: (title: any, { taskId }: NewTaskModel) => (
                <div
                    title={title}
                    style={{
                        paddingRight: '15px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {title}
                </div>
            ),
        },
        {
            title: '截止时间',
            width: '160px',
            align: 'left',
            dataIndex: 'endTime',
            render: (endTime: number, { taskId, taskStatus }: NewTaskModel) => {
                const localIsOverdue = isOverdue(endTime, taskStatus);
                const timText = endTime
                    ? generateDefaultTimeFormat(dayjs(Number(endTime)), t) + ' 截止'
                    : t1('no end time');
                return (
                    <div style={{ textIndent: 10 }}>
                        <span style={{ color: localIsOverdue ? '#FF000D' : '' }}>
                            {timText}
                            {/* 已完成的任务不显示逾期标签1.4.2 */}
                            {localIsOverdue && <span className="overdue__block">逾期</span>}
                        </span>
                    </div>
                );
            },
        },
        {
            title: '任务总状态',
            dataIndex: 'taskStatus',
            width: '60px',
            align: 'left',
            render: (taskStatus: TASK_STATUS, { taskId }: NewTaskModel) => (
                <div
                    style={{
                        opacity: taskStatus === 2 ? 0.3 : 1,
                    }}
                >
                    {TASK_STATUS_MAP[taskStatus]}
                </div>
            ),
        },
        {
            title: '我的状态',
            dataIndex: 'processStatus',
            align: 'left',
            width: '80px',

            render: (processStatus: PROCESS_STATUS, { taskId }: NewTaskModel) => (
                <div>
                    <span
                        style={{
                            marginRight: 6,
                            opacity: processStatus === 2 ? 0.3 : 1,
                            color: processStatus === 3 ? 'red' : '',
                        }}
                    >
                        {PROCESS_STATUS_MAP[processStatus]}
                    </span>
                </div>
            ),
        },
        {
            title: '负责人',
            dataIndex: 'owner',
            width: '60px',
            ellipsis: true,
            align: 'left',
            render: (record: any) => <div className="cell-owner">{record?.realName}</div>,
        },
        {
            title: '协同人',
            dataIndex: 'executors',
            align: 'left',
            width: '60px',
            render: (executors: WorkUserInfo[]) => (
                <div style={{ position: 'relative' }}>
                    <div style={{ justifyContent: 'center' }}>
                        {executorIcon}
                        <span
                            style={{
                                color: (window as any).styleSass.primaryColor,
                                paddingLeft: '5px',
                                lineHeight: '18px',
                            }}
                        >
                            {executors?.length || 0}
                        </span>
                        {/* <CircleShapeIcon content={`+${executors.length}`} WithIcon={executorIcon} /> */}
                    </div>
                </div>
            ),
        },
    ];
    function LoadMore() {
        const _taskList = taskList.filter((item) => item);
        // console.log(_taskList, '_taskList_taskList_taskList_taskList_taskList_taskList', total);
        if (!_taskList.length || _taskList.length >= total || _taskList.length < 20) {
            return null;
        }
        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={7}>
                    <div className="table-footer-load-more">
                        <span className="load-more__text" onClick={() => loadMore(taskList.length)}>
                            加载更多
                        </span>
                    </div>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }
    // console.log(taskList, 'taskListtaskListtaskListtaskListtaskList');
    return (
        <div className="project-table-space" style={{ height: '100%' }}>
            <Table
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            // console.log(event, 'eventeventevent==>', record.taskId);
                            const taskInof: any = taskList.find(
                                (item: any) => item.taskId === record.taskId
                            );
                            if (!taskInof) {
                                return;
                            }
                            changeSelectedTaskItem(taskInof);
                        },
                    };
                }}
                loading={
                    taskLoading
                        ? {
                              indicator: (
                                  <div
                                      style={{
                                          width: '100%',
                                          height: '100%',
                                          display: 'flex',
                                          left: 0,
                                      }}
                                  >
                                      <Loading />
                                  </div>
                              ),
                          }
                        : false
                }
                dataSource={taskList.filter((item) => item)}
                scroll={{ y: initHeight, x: 960 }}
                columns={columns}
                rowKey="taskId"
                pagination={false}
                locale={{ emptyText: taskLoading ? ' ' : '暂无数据' }}
                components={{
                    body: {
                        cell: (restProps: any) =>
                            TableCell({
                                ...restProps,
                                customTdClass: taskList.length > 0 ? 'custom-table-td' : '',
                            }),
                    },
                }}
                summary={LoadMore}
            />
        </div>
    );
}

function mapStateToProps({ user, work }: any) {
    const {
        // taskList,
        // total,
        offset,
        offsets,
        processStatuses,
        taskStatuses,
        priorityTypes,
        userRole,
        taskLoading,
        sortType,
    } = work;
    return {
        // total,
        offset,
        offsets,
        // taskList,
        taskStatuses,
        priorityTypes,
        processStatuses,
        userData: user.userData.user,
        userRole,
        sortType,
        taskLoading,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changePersonalTaskDraw(taskId: string, activeTab?: string) {
            return dispatch({
                type: 'work/changePersonalTaskDraw',
                payload: { taskId, drawVisible: true, activeTab },
            });
        },
        // getTasks(payload: TaskListParams) {
        //     return dispatch({
        //         type: 'work/fetchTaskList',
        //         payload,
        //     });
        // },
        editTaskItem(payload: { taskId: string; priorityType: PRIORITY_TYPE }) {
            return dispatch({
                type: 'work/editTaskItem',
                payload,
            });
        },
        setUserRole(payload: { userRole: number }) {
            return dispatch({
                type: 'work/setUserRole',
                payload,
            });
        },
        setProcessStatus(payload: { processStatus: PROCESS_STATUS[] }) {
            return dispatch({
                type: 'work/setProcessStatus',
                payload,
            });
        },
        setTaskStatus(payload: { taskStatus: TASK_STATUS[] }) {
            return dispatch({
                type: 'work/setTaskStatus',
                payload,
            });
        },
        // setTaskList(payload: TaskListParams) {
        //     return dispatch({
        //         type: 'work/setTaskList',
        //         payload,
        //     });
        // },
        setPriorities(payload: { priorities: PRIORITY_TYPE[] }) {
            return dispatch({
                type: 'work/setPriorities',
                payload,
            });
        },
        // setOffset(offset: number) {
        //     return dispatch({
        //         type: 'work/setOffset',
        //         payload: offset,
        //     });
        // },
        setOffsets(offsets: number[]) {
            return dispatch({
                type: 'work/setOffsets',
                payload: offsets,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskTable);
