import React from 'react';
import Follow from './Follow';
import Open from './Open';

export enum TagType {
    Open = 'Open',
    Follow = 'Follow',
}

interface IProps {
    TagType: TagType;
    left?: number;
    right?: number;
}

export default function Tags(props: IProps) {
    const { left = 0, right = 0 } = props;
    switch (props.TagType) {
        case TagType.Open:
            return <Open left={left} right={right} />;
        case TagType.Follow:
            return <Follow left={left} right={right} />;
        default:
            return null;
    }
}
