import React from 'react';
import { connect } from 'dvajs';
import './index.less';
import { userEscapeString } from '@/utils/chat/index';
import { changeEgovUrl } from '@/utils/tools';
import { getSelected } from '@/components/chat/utils/message';

interface Content {
    order: number;
    name: string;
    value: string;
    isComponent?: boolean;
}
interface ComNoticeCardProps {
    avatarSrc: string; // 卡片图标 message.sender.avatar
    titleChildren?: any; // 自定义标题 组件传参
    title?: string; // 默认标题 message.title
    type: string; // 卡片类型
    children?: any; // 自定义内容 组件传参
    contentList?: Content[]; // 默认显示内容 message.extend.fields
    loadButtonsFn?: any; // 自定义按钮 组件传参
    handleDetail?: Function; // 内容区可查看详情
    wrapClassName?: string; // 自定义样式
}
type IProps = Readonly<ComNoticeCardProps>;
function ComNoticeCard(props: IProps) {
    const {
        avatarSrc,
        titleChildren,
        title,
        type,
        children,
        contentList,
        loadButtonsFn,
        handleDetail,
        wrapClassName,
    } = props;
    return (
        <div className={`ai-notice-card-container ${wrapClassName}`}>
            <div className="ai-notice-card-container-header">
                <div className="header_title">
                    <div className="header_title_icon">
                        <img src={changeEgovUrl(avatarSrc)} />
                    </div>
                    {titleChildren}
                    {title && <div className="header_title_content">{title}</div>}
                </div>
                <div className="header_type_name">{type}</div>
            </div>
            <div
                className={`ai-notice-card-container-main ${handleDetail ? 'cursor' : ''}`}
                onClick={() => {
                    // eslint-disable-next-line no-extra-boolean-cast
                    if (!!getSelected()) {
                        console.log('有选择的文字不触发点击事件，用户可能需要复制');
                        return;
                    }
                    handleDetail?.();
                }}
            >
                {children}
                {contentList
                    ? contentList.map((content, index) => (
                          <div key={index} className="content_item">
                              {content.name && (
                                  <span className="content_item_label">{content.name}：</span>
                              )}
                              {content.isComponent ? (
                                  <span
                                      className="content_item_value"
                                      style={{ whiteSpace: 'pre-wrap' }}
                                  >
                                      {content.value}
                                  </span>
                              ) : (
                                  <span
                                      className="content_item_value"
                                      style={{ whiteSpace: 'pre-wrap' }}
                                      dangerouslySetInnerHTML={{
                                          __html: userEscapeString(content.value),
                                      }}
                                  />
                              )}
                          </div>
                      ))
                    : null}
            </div>
            {/* 各个业务渲染自己的操作按钮 */}
            {loadButtonsFn?.() ? (
                <div className="ai-notice-card-container-footer">
                    {/* {btnList.map((btn) => (
                        <div key={btn.order} className="button_item">
                            {btn.textCode}
                        </div>
                    ))} */}
                    {loadButtonsFn()}
                </div>
            ) : null}
        </div>
    );
}
function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ComNoticeCard);
