import { lazy } from 'react';

const Tests = lazy(() => import(/* webpackChunkName: "Tests" */ '../pagesv2/Tests'));

import {
    IconChat,
    IconJoyspace,
    IconCalendar,
    IconThirdApp,
    IconEBook,
    IconWork,
    IconAppointMeeting,
    IconMail,
} from '@/components/icon';
import IndexPage from '@/pagesv2/IndexPage';
import Messages from '@/pagesv2/Messages';
import JoySpacePage from '@/pagesv2/JoySpace';
import CalendarPage from '@/pagesv2/CalendarPage';
import ProjectsPage from '@/pagesv2/ProjectsPage';
import ThirdApp from '@/pagesv2/ThirdApp';
import AddressListPage from '@/pagesv2/AddressList';
import Supervise from '@/pagesv2/Supervise';
import MeetingLeftNavPage from '@/pagesv2/MeetingLeftNavPage';
import MeetingCloudLink from '@/pagesv2/MeetingCloudLink';
import MeetingJD from '@/pagesv2/MeetingJD';
import MeetingXYLink from '@/pagesv2/MeetingXYLink';

import ThirdEmailApp from '@/pagesv2/ThirdEmailApp';
import SystemSetting from '@/pagesv2/SystemSetting';
// const ThirdApp = lazy(() => import('@/pagesv2/ThirdApp'));
// const Messages = lazy(() => import('@/pagesv2/Messages'));
// const AddressListPage = lazy(() => import('@/pagesv2/AddressList'));
// const JoySpacePage = lazy(() => import('@/pagesv2/JoySpace'));
// const CalendarPage = lazy(() => import('@/pagesv2/CalendarPage'));
// const ProjectsPage = lazy(() => import('@/pagesv2/ProjectsPage'));

export const thirdAppPath = '/third_apps';

export default [
    {
        path: '/',
        name: 'Index',
        component: IndexPage,
        icon: IconChat,
    },
    {
        path: '/systemSetting',
        name: 'systemSetting',
        component: lazy(() =>
            import(/* webpackChunkName: "systemSetting" */ '@/pagesv2/SystemSetting')
        ),
    },
    {
        path: '/messages',
        name: 'Messages',
        component: Messages,
        icon: IconChat,
    },
    {
        path: '/messages/:sType/:sid',
        name: '聊天',
        component: Messages,
        icon: IconChat,
    },
    {
        path: '/meeting',
        name: '会议',
        component: MeetingLeftNavPage,
        icon: IconAppointMeeting,
    },
    {
        path: '/cloudlink',
        name: '会议',
        component: MeetingCloudLink,
        icon: IconAppointMeeting,
    },
    {
        path: '/jdmeet',
        name: '智能会议',
        component: MeetingJD,
        icon: IconAppointMeeting,
    },
    {
        path: '/xylink',
        name: '政务视频会议',
        component: MeetingXYLink,
        icon: IconAppointMeeting,
    },
    {
        path: '/joyspace',
        name: 'JoySpace',
        component: lazy(() => import(/* webpackChunkName: "JoySpace" */ '@/pagesv2/JoySpace')),
        icon: IconJoyspace,
        exact: false,
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: lazy(() =>
            import(/* webpackChunkName: "CalendarPage" */ '@/pagesv2/CalendarPage')
        ),
        icon: IconCalendar,
    },
    {
        path: '/projects',
        name: 'Projects',
        component: lazy(() =>
            import(/* webpackChunkName: "ProjectsPage" */ '@/pagesv2/ProjectsPage')
        ),
        icon: IconWork,
    },
    {
        path: thirdAppPath,
        name: 'ThirdApp',
        component: ThirdApp,
        icon: IconThirdApp,
    },
    {
        path: '/address_list',
        name: 'AddressList',
        component: lazy(() =>
            import(/* webpackChunkName: "AddressList" */ '@/pagesv2/AddressList')
        ),
        icon: IconEBook,
    },
    {
        path: '/third_email',
        name: 'ThirdEmail',
        component: ThirdEmailApp,
        icon: IconMail,
    },
    {
        path: '/tests',
        name: 'Tests',
        icon: IconChat,
        component: Tests,
    },
    {
        path: '/supervise/(.*)?',
        name: '督办',
        component: lazy(() => import(/* webpackChunkName: "Supervise" */ '@/pagesv2/Supervise')),
        icon: IconWork,
    },
];
