/**
 * 用户每个项数据组件
 */
import React, { useMemo, useCallback } from 'react';
import { SplitLine } from './common';
import Mobile from './Mobile';
import { UserCardData, UserCardType } from '@/types/UserCard';
import { connect } from 'dvajs';
import { changeEgovUrl } from '@/utils/tools';

function UserInfoItem(props: { user: UserCardData; cardType: UserCardType; userId: string }) {
    const { user, cardType, userId } = props;
    // console.log('UserInfoItem ===>', user);
    const isSelf = user.userId === userId;
    const fields = useMemo(() => {
        if (!user) {
            return null;
        }
        return user.fields
            .filter(
                (item) =>
                    item.hide === 0 &&
                    [
                        'avatar',
                        'name',
                        'signature',
                        'sex',
                        'station',
                        'e_dept_full_name',
                        'e_position',
                        // 'workStatus',
                    ].indexOf(item.key) < 0 &&
                    !!item.val
            )
            .sort((a, b) => a.index - b.index);
    }, [user]);

    const showLine = useMemo(() => {
        const { mainTitleDeptInfo: main, partTimeTitleDeptInfo: part } = user;
        return main?.deptId || (part && part.length > 0 && part[0].deptId);
    }, [user]);

    const itemNode = useCallback(
        (item) => {
            if (item.key === 'mobile') {
                return <Mobile user={user} isSelf={isSelf} />;
            }
            return (
                <div className="usercard-item-content">
                    <h5 className="usercard-item-title">{item.name}</h5>
                    {item.key === 'workStatus' ? (
                        <p className="usercard-item-desc">
                            <img
                                src={changeEgovUrl(JSON.parse(item.val || '')?.icon)}
                                style={{
                                    width: '20px',
                                    verticalAlign: 'sub',
                                    marginRight: '5px',
                                }}
                            />
                            {JSON.parse(item.val || '')?.workStatus}
                        </p>
                    ) : (
                        <p className="usercard-item-desc">{item.val}</p>
                    )}
                </div>
            );
        },
        [isSelf, user]
    );

    if (cardType !== UserCardType.User || !user) {
        return null;
    }

    return (
        <div>
            {(fields || []).map((item, index) => {
                if (item.key === 'workStatus' && !JSON.parse(item.val || '')?.workStatus) {
                    return null;
                }
                return (
                    <div key={item.key} className="me-user-card-item">
                        {(showLine || index > 0) && <SplitLine />}
                        {itemNode(item)}
                        {/* {item.key === 'mobile' ? (
                            <Mobile user={user} isSelf={isSelf} />
                        ) : (
                            <div className="usercard-item-content">
                                <h5 className="usercard-item-title">{item.name}</h5>
                                {item.key === 'workStatus' ? (
                                    <p className="usercard-item-desc">
                                        <img
                                            src={changeEgovUrl(JSON.parse(item.val || '')?.icon)}
                                            style={{
                                                width: '20px',
                                                verticalAlign: 'sub',
                                                marginRight: '5px',
                                            }}
                                        />
                                        {JSON.parse(item.val || '')?.workStatus}
                                    </p>
                                ) : (
                                    <p className="usercard-item-desc">{item.val}</p>
                                )}
                            </div>
                        )} */}
                    </div>
                );
            })}
        </div>
    );
}

function mapStateToProps({ user }: { user: any }) {
    return {
        userId: user.userData.user.userId,
    };
}

export default connect(mapStateToProps)(UserInfoItem);
