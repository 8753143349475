import TokenProvider, { Token } from '@/provider/TokenProvider';
import Channel from '@jd/jdee.im.sdk/lib/es/core/channel/EventChannel';

export interface Cache {
    clientId?: string;
    tokenProvider?: TokenProvider;
    token?: Token;
    clientMode?: 'server' | 'client';
    gateWayAppId?: string;
    gateWaySecretKey?: string;
    ddAppId?: string;
    channel?: Channel;
    aid?: string;
    zoomLogin?: boolean; // zoom是否登陆成功
    cloudLinkLogin?: boolean; // 信创是否登录成功
    telCall?: boolean; // 是否开启语音短信外呼
}

const cache: Cache = { clientMode: 'server' };

export default cache;
