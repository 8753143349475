import { EventEmitter } from 'events';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { v4 } from 'uuid';
import logger from '@/utils/logger';
import { FocusSDK, getAppId, getAppSecretKey, IFocusSDK, isFocusEnv } from '@/utils';
import { message, Modal } from 'antd';
import i18next from 'i18next';
import GError, { ErrUnauthorized } from '@/utils/GError';
import { AuthData } from '@/types/LocalConfig';
import cookies from 'js-cookie';
const log = logger.getLogger('BaseService');
import md5 from 'js-md5';
import { GATEWAY_VERSION } from '@/server/constants';
const LOGIN_ERROR_KEY = 'LOGIN_ERROR_KEY';
export default class BaseService extends EventEmitter {
    appRuntime: AppRuntimeEnv;
    focusSDK: IFocusSDK;
    constructor(appRuntimeEnv: AppRuntimeEnv, focusSDK: IFocusSDK) {
        super();
        this.appRuntime = appRuntimeEnv;
        this.focusSDK = focusSDK;
    }
    getEnv() {
        const { registryConfig } = this.appRuntime;
        return registryConfig.runtimeConfig.env;
    }
    getFetchHeaders(api: string, body = {}) {
        const { registryConfig } = this.appRuntime;
        const { auth, selectedTeamId, machineId, pkgVersion, runtimeConfig } = registryConfig;
        console.log('auth@@@', auth);
        const xContentObj = Object.keys(body).length ? body : {};
        const xContent = md5(JSON.stringify(xContentObj));
        const appId = isFocusEnv() ? this.appRuntime.gatewayAppId : getAppId();
        const ts = `${new Date().getTime()}`;
        let secretKey = '';
        if (this.focusSDK.getDeviceInfo().platform === 'web') {
            secretKey = process.env.REACT_APP_WEB_SECRETKEY || '';
        } else {
            secretKey =
                this.appRuntime.gateway.secretKey ||
                getAppSecretKey(this.appRuntime.runtimeConfig.key);
        }
        const platform = (!isFocusEnv() && 'WEB') || registryConfig.client;
        const nonce = v4();
        const headers = {
            'Content-Type': 'application/json',
            'x-client': platform,
            'x-ts': ts,
            'x-gw-version': GATEWAY_VERSION,
            'x-app': appId, // appId 改为可配置的
            'x-api': api,
            'x-nonce': nonce,
            'x-stage': this.getEnv() === 'prod' || this.getEnv() === 'test' ? 'PROD' : 'PRE',
            'x-did': machineId,
            'x-app-version': pkgVersion.split('-')[0],
            ...(auth ? { 'x-team-id': selectedTeamId || '', 'x-token': auth.accessToken } : {}),
        } as any;
        xContent && (headers['x-content-md5'] = xContent);
        const tempString = `${GATEWAY_VERSION}&${appId}&${secretKey}&${api}&${ts}&${nonce}&${platform}${
            xContent ? '&' + xContent : ''
        }`;
        const sign = md5(tempString);
        console.log('$$$$$$$$', tempString);
        headers['x-sign'] = sign;
        console.log('header-=====>', headers);
        return headers;
    }
    getUrl(module: string, func: string) {
        let gatewayHost = this.appRuntime.gatewayHost;
        let currentHost = FocusSDK.getMeMainEntry();
        if (currentHost.indexOf('beijing.egov') > -1) {
            gatewayHost = gatewayHost.replace('beijing.gov', 'beijing.egov');
        }
        return `${gatewayHost}/${module}.${func}`;
    }
    getApi(module: string, func: string) {
        return `${module}.${func}`;
    }
    getHeaders(headers: any) {
        const gatewayAppId = isFocusEnv() ? this.appRuntime.gatewayAppId : getAppId();
        if (gatewayAppId) {
            return Object.assign(headers, { 'x-app': gatewayAppId });
        }
        return headers;
    }

    refreshToken() {
        const { refreshToken } = this.appRuntime;
        return fetch(`${this.appRuntime.gatewayHost}/appRefreshToken`, {
            method: 'POST',
            headers: this.getHeaders(
                this.getFetchHeaders('login.appRefreshToken', { refreshToken })
            ),
            body: JSON.stringify({
                refreshToken,
            }),
        })
            .then((res) => res.json())
            .then((d) => {
                const { errorCode, errorMsg, content } = d;
                if (errorCode !== '0') {
                    return null;
                }
                return content;
            })
            .catch((e) => {
                console.error(e);
                return null;
            });
    }

    // eslint-disable-next-line max-params
    async fetch<T>(
        module: string,
        func: string,
        params: any,
        options: {
            headers?: any;
            ignoreErrCodes?: string[];
            ignoreErrMessageAlert?: boolean;
        } = {}
    ): Promise<T> {
        const logout = async () => {
            // refresh token,如果未勾选30天，accessToken过期了直接退出
            if (!this.appRuntime.registryConfig.autoLoginChecked) {
                this.focusSDK.printLog('登录时间过期token expired');
                this.focusSDK.logout('expired');
                // 多个接口调用，只提示一次登录过期
                if (cookies.get('hide-expired-msg') !== 'true') {
                    message.error({
                        content: i18next.t('Login Expired'),
                        duration: 10,
                        key: LOGIN_ERROR_KEY,
                    });
                    this.focusSDK.setCookies(
                        'hide-expired-msg',
                        'true',
                        isFocusEnv()
                            ? Math.round(new Date().getTime() / 1000) + 60 * 2
                            : new Date(Number(new Date()) + 1000 * 60 * 2)
                    );
                }
                throw new Error('token expired, logout');
            }
            const refreshData = await this.refreshToken();
            if (!refreshData) {
                // web 端直接退出
                if (this.appRuntime.platform === 'IPAD') {
                    this.focusSDK.logout('expired');
                    throw new Error('web token expired, logout');
                } else {
                    this.focusSDK.printLog('fetch-refreshData', JSON.stringify(refreshData));
                    await this.logout();
                    throw ErrUnauthorized;
                }
            }
            this.focusSDK.setConfigData(
                'tokenExpireIn',
                this.appRuntime.registryConfig.autoLoginChecked
                    ? new Date().getTime() + 1000 * 60 * 60 * 24 * 30
                    : new Date().getTime() + 1000 * 60 * 60 * 24
            );
            this.appRuntime.mergeAuthData(refreshData);
            this.focusSDK.emit('reLogin');
            return this.fetch<T>(module, func, params);
        };
        const { ignoreErrMessageAlert, headers = {} } = options;
        const url = this.getUrl(module, func);
        const opt: any = {
            method: 'POST',
            headers: {
                ...this.getHeaders(this.getFetchHeaders(this.getApi(module, func), params)),
                ...headers,
            },
        };
        let bodyStr = '';
        if (params && Object.keys(params).length) {
            bodyStr = JSON.stringify(params);
        } else {
            bodyStr = JSON.stringify({});
        }
        opt.body = bodyStr;
        let res: any = {};

        try {
            res = await fetch(url, opt);
        } catch (e) {
            let urlParse = url.split('/');
            if (urlParse.pop() === 'login.getUserProfile') {
                logout();
            } else {
                if (!ignoreErrMessageAlert) {
                    message.error(i18next.t('chat:server_busy'));
                }
                throw e;
            }
        }
        if (res.status !== 200) {
            if (res.status === 500 || res.status === 302) {
                try {
                    const data = await res.json();
                    const { gw_code: gwCode, gw_echo: gwEcho } = data as any; // eslint-disable-line
                    switch (gwCode) {
                        case 1000107: {
                            this.focusSDK.printLog('baseservice-errorCode', '1000107');
                            return logout();
                        }
                        default: {
                            Modal.error({
                                title: 'Error',
                                // content: `[${gwCode}]${i18next.t('error.gateway', {
                                //     errMsg: gwEcho,
                                // })}`,
                                content: `[${gwCode}]${gwEcho}`,
                            });
                            throw new GError(gwCode, gwEcho);
                        }
                    }
                } catch (e) {
                    // console.log(e);
                    return logout();
                }
            }
            throw new GError(res.status, res.statusText);
        }

        const data = await res.json();
        const { errorCode, content, errorMsg } = data;

        // 兼容 joyspace 接口
        if (data.status === 'success') {
            return data as T;
        }

        if (errorCode === '0002') {
            this.focusSDK.printLog('baseservice-errorCode', '0002');
            return logout();
        }
        if (errorCode !== '0') {
            const err = new GError(errorCode, errorMsg, `content: ${JSON.stringify(content)}`);
            if (!ignoreErrMessageAlert) {
                err.toString() && message.error(err.toString());
            }
            throw err;
        }

        return (content || {}) as T;
    }

    async relogin(): Promise<AuthData> {
        this.focusSDK.printLog('1-relogin', this.appRuntime.registryConfig.auth?.refreshToken);

        if (this.appRuntime.registryConfig.tokenExpireIn < new Date().getTime()) {
            this.focusSDK.printLog('登录时间过期-1');
            this.focusSDK.logout('expired');
            // 多个接口调用，只提示一次登录过期
            message.error({
                content: i18next.t('Login Expired'),
                duration: 10,
                key: LOGIN_ERROR_KEY,
            });
            this.focusSDK.setCookies(
                'hide-expired-msg',
                'true',
                isFocusEnv()
                    ? Math.round(new Date().getTime() / 1000) + 60 * 2
                    : new Date(Number(new Date()) + 1000 * 60 * 2)
            );
            throw new Error('token expired, logout');
        }

        return fetch(`${this.appRuntime.gatewayHost}/appRefreshToken`, {
            method: 'POST',
            headers: this.getHeaders(
                this.getFetchHeaders('login.appRefreshToken', {
                    refreshToken: this.appRuntime.registryConfig.auth?.refreshToken,
                })
            ),
            body: JSON.stringify({
                refreshToken: this.appRuntime.registryConfig.auth?.refreshToken,
            }),
        })
            .then((res) => res.json())
            .then((d) => {
                const { errorCode, errorMsg, content } = d;
                this.focusSDK.printLog('relogin-fetch', `${JSON.stringify(d)}-${d.errorCode}`);

                // 过期或者在另外一个账号登录都会返回10160001
                if (
                    ['10160001', 10160001].includes(errorCode) &&
                    this.appRuntime.registryConfig.tokenExpireIn &&
                    this.appRuntime.registryConfig.tokenExpireIn > Date.now()
                ) {
                    message.error({
                        content: i18next.t('Login Expired'),
                        duration: 10,
                        key: LOGIN_ERROR_KEY,
                    });
                    throw new Error('failed to refresh token');
                } else if (['10160001', 10160001].includes(errorCode)) {
                    if (cookies.get('hide-expired-msg') !== 'true') {
                        message.error({
                            content: i18next.t('Login Expired'),
                            duration: 10,
                            key: LOGIN_ERROR_KEY,
                        });
                        this.focusSDK.setCookies(
                            'hide-expired-msg',
                            'true',
                            isFocusEnv()
                                ? Math.round(new Date().getTime() / 1000) + 60 * 2
                                : new Date(Number(new Date()) + 1000 * 60 * 2)
                        );
                    }
                    throw new Error('failed to refresh token');
                } else if (errorCode && errorCode !== '0') {
                    throw new Error('failed to refresh token');
                }
                this.focusSDK.setConfigData(
                    'tokenExpireIn',
                    this.appRuntime.registryConfig.autoLoginChecked
                        ? new Date().getTime() + 1000 * 60 * 60 * 24 * 30
                        : new Date().getTime() + 1000 * 60 * 60 * 24
                );
                return content;
            });
    }

    logout() {
        this.focusSDK.printLog('baseservice-logout');
        let headers = this.getHeaders(this.getFetchHeaders(`login.appLogout`));
        if (!isFocusEnv()) {
            headers['x-did'] = 'WEB';
        }
        return fetch(`${this.appRuntime.gatewayHost}/appLogout`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then((d) => {
                const { errorCode, errorMsg } = d;
                if (errorCode !== '0') {
                    log.error(`[${errorCode}]${errorMsg}, failed to logout`);
                }
            })
            .catch((e) => {
                console.error('logout-e', e);
            });
    }
}
