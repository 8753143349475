import React from 'react';
import './index.less';

export default function OpenTag(props: any) {
    const { left = 0, right = 0 } = props;
    return (
        <span style={{ marginLeft: left, marginRight: right }} className="open-tag">
            公开
        </span>
    );
}
