import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppStoreGroupApplication } from '@/services/AppStore';
import Search from '@/components/ThirdApp/components/Search';
import Loading from '@/components/Loading';
import { isBjme } from '@/utils';
import './index.less';
export default function Nav({
    groups,
    onGroupChange,
    loading,
    selectedId,
    openAppStore,
}: {
    loading: boolean;
    groups: AppStoreGroupApplication[];
    selectedId: string;
    onGroupChange: (group: AppStoreGroupApplication) => void;
    openAppStore: () => void;
}) {
    const { t } = useTranslation('common');
    const Item = ({ data, active }: { data: AppStoreGroupApplication } & { active?: boolean }) => (
        <div
            className={
                active ? 'third-left-nav_item third-left-nav_item_active' : 'third-left-nav_item'
            }
            onClick={() => {
                const jumpName = document.getElementById(data.name);
                if (jumpName) {
                    jumpName.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                    });
                }
                onGroupChange(data);
            }}
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
        >
            {data.name}
        </div>
    );
    return (
        <div className="third-left-nav">
            <div className="third-left-search">
                <Search />
            </div>
            <div className="third-left-nav_list">
                {!loading &&
                    groups.length > 0 &&
                    groups.map((group, i) => (
                        <Item key={i} data={group} active={group.groupId === selectedId} />
                    ))}
                {/* no-data */}
                {!loading && groups.length === 0 && (
                    <div style={{ textAlign: 'center' }}>
                        {isBjme() ? t('thirdapp.no-tools') : t('thirdapp.no-application')}
                    </div>
                )}
                {/* loading中 */}
                {loading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Loading />
                    </div>
                )}
            </div>
            {/* 行业me暂时隐藏应用市场 */}
            {/* <div className="third-left-nav_app_store">
                <span
                    onClick={() => {
                        openAppStore?.();
                    }}
                >
                    应用市场
                </span>
            </div> */}
        </div>
    );
}
