import React, { useState, useMemo } from 'react';
import { remove } from '@/components/Joyspace/api/collections';
import { PageType } from '@/components/Joyspace/const';
import events from '@/components/Joyspace/context/events';
import { Icons } from '@/components/Joyspace/Icon';
import { message, Popover } from 'antd';
import IconFont from '@/components/icon';
import { FileType } from '@/components/Joyspace/types';
// import './index.less';

interface IProps {
    pageId: any;
    fileType: FileType;
    onChange?: () => void;
}

export default function CollectionPopover(props: IProps) {
    const { pageId = '', fileType = FileType.Page, onChange } = props;
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [disabled, setDisabled] = useState(false);
    const handleVisibleChange = () => {
        setPopoverVisible(!popoverVisible);
    };
    useMemo(() => {
        setDisabled(!navigator.onLine);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.onLine]);
    return (
        <div className="collection-popover">
            <Popover
                trigger="click"
                placement="bottomRight"
                onVisibleChange={handleVisibleChange}
                visible={popoverVisible}
                content={
                    <ul
                        className={
                            disabled
                                ? 'joyspace-operate-lists joyspace-operate-lists-disabled'
                                : 'joyspace-operate-lists'
                        }
                    >
                        <li
                            onClick={() => {
                                if (disabled) {
                                    return;
                                }
                                setPopoverVisible(false);
                                remove(pageId, fileType)
                                    .then((res) => {
                                        message.success('操作成功');
                                        events.emit('list.reload');
                                    })
                                    .catch((err) => {
                                        message.error('操作失败');
                                    });
                                onChange?.();
                            }}
                        >
                            <IconFont
                                type="iconpc_yundocument_inshare"
                                className="joyspace-collection-operate-icon"
                            />
                            取消收藏
                        </li>
                    </ul>
                }
            >
                <a href="#" type="default">
                    <span className="joyspace-trigger-icon">{Icons.trigger}</span>
                </a>
            </Popover>
        </div>
    );
}
