import React, { useContext, useMemo, useState } from 'react';
import './index.less';
import { connect } from 'dva';
import { UserCardPayload } from '@/models';
import { FocusSDK, isFocusEnv } from '@/utils';
import { Tooltip } from 'antd';
import IconFont from '@/components/icon';
import { openUserSelectionModal } from '@/utils/modals';
import { useTranslation } from 'react-i18next';
import { User } from '@/types/supervise';
import UserStatusListPopover from '../UserStatusListPopover';
// import { judgeTaskFinish } from '../../utils';
import { UserCardType } from '@/types/UserCard';
import ChatState from '@/types/chat/State';
import Employee from '@jd/jdee.im.sdk/lib/es/model/Employee';
import { employeeToUid } from '@/components/chat/utils/message';
import Bus from '@/utils/bus';
import lodashCompact from 'lodash/compact';
import { changeEgovUrl } from '@/utils/tools';
import config, { ConfigEnum } from '@/config/config';

function mapStateToProps({ user, chat }: any) {
    const { currentEmployee } = chat as ChatState;

    return {
        userData: user.userData ? user.userData.user : {},
        currentEmployee,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
    };
}

export function UserItem({ item, onClick }: { item: any; onClick?: (item: any) => void }) {
    return (
        <Tooltip title={item.realName}>
            <div className="supervise-userlist-item" onClick={() => onClick?.(item)}>
                {item.headImg ? (
                    <img src={changeEgovUrl(item.headImg || '')} className="avatar" />
                ) : (
                    <div className="avatar">{(item.realName || '').slice(0, 1)}</div>
                )}
            </div>
        </Tooltip>
    );
}

function SuperviseUserList({
    owner,
    list = [],
    toggleUserCardModal,
    userData,
    maxLength,
    disable,
    handleAddTaskMembers,
    onRemove,
    addMemberDisable,
    currentEmployee,
    isGroup,
    sessionId,
}: {
    owner?: any;
    list?: any[];
    toggleUserCardModal: (payload: UserCardPayload) => void;
    userData: any;
    maxLength: number;
    disable?: boolean;
    handleAddTaskMembers: (members: any) => void;
    onRemove: (user: any[]) => void;
    addMemberDisable?: boolean;
    currentEmployee: Employee;
    isGroup?: boolean;
    sessionId?: string;
}) {
    function openUserCard(item: any) {
        const { userId, teamId, appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    const [t_chat] = useTranslation('chat');

    function openUserSelect() {
        const { userId, teamUserInfo } = userData;
        const { ddAppId, teamId } = teamUserInfo;
        let tabs = ['recent', 'org', 'group', 'externalContacts'];
        if (!config[ConfigEnum.EXTERNAL_CONTACTS_ORG]) {
            tabs = ['recent', 'group', 'externalContacts'];
        }
        if (isGroup) {
            tabs.push('business');
        }
        // console.log(list, 'listlistlistlist', userData);
        // const arr = lodashCompact(list || []).map((item: any) => ({
        //     id: item.userId + ':' + (item.appId || item.app) + ':' + item.teamId,
        //     name: item.realName,
        //     avatar: item.headImg,
        //     checked: true,
        //     userId: item.userId,
        //     teamId: item.teamId,
        //     visible: false,
        // }));
        // console.log(arr, 'arrarrarrarrarr');
        openUserSelectionModal(
            {
                title: t_chat('add_member'),
                tabs: JSON.parse(JSON.stringify(tabs)),
                // tabs: isGroup
                //     ? ['recent', 'org', 'group', 'externalContacts', 'business']
                //     : ['recent', 'org', 'group', 'externalContacts'],
                frozenIds: list.map(
                    (item) => item.userId + ':' + (item.app || item.appId) + ':' + item.teamId
                ),
                resultUsers: list.map((item: any) => ({
                    id: item.userId + ':' + (item.appId || item.app) + ':' + item.teamId,
                    name: item.realName,
                    avatar: item.headImg,
                    checked: true,
                    userId: item.userId,
                    teamId: item.teamId,
                    visible: true,
                })),
                contactsFilterFlag: 'external',
                currentUser: {
                    app: ddAppId,
                    pin: userId,
                    teamId: teamId,
                },
                businessTab: {
                    tabName: '当前组成员',
                    tabParam: {
                        businessType: isGroup ? 'group' : 'project',
                        businessId: isGroup ? (sessionId ? sessionId : '') : '',
                    },
                },
            },
            (data, close) => {
                // 过滤掉已选中的
                const { result } = data.data;
                if (result.length) {
                    const addArr: any[] = [];
                    result.forEach((r: any) => {
                        if (r.id) {
                            const strs = r.id.split(':');
                            return addArr.push({
                                userId: strs[0],
                                teamId: strs[2],
                                app: strs[1],
                                appId: strs[1],
                                headImg: r.avatar,
                                realName: r.name,
                            });
                        }
                        const { origin } = r;
                        if (origin.info) {
                            addArr.push({
                                userId: origin.info.userId,
                                teamId: origin.info.teamId,
                                app: origin.info.appId || origin.info.app,
                                appId: origin.info.appId || origin.info.app,
                                headImg: origin.info.avatar,
                                realName: origin.info.realName,
                            });
                        }
                    });
                    handleAddTaskMembers(addArr);
                }
                close();
            },
            userData
        );
    }

    const operators = useMemo(() => {
        if (maxLength <= 1) {
            return [];
        }
        if (list.length > maxLength) {
            return list.slice(0, maxLength);
        }
        return [...list];
    }, [list, maxLength]);

    return (
        <div className="supervise-userlist">
            {/* {owner && <UserItem item={owner} onClick={openUserCard} />}
            {owner && list.length > 0 && <div className="supervise-userlist-split" />} */}
            {operators.length > 0 &&
                operators.map((l, index) => (
                    <UserItem
                        key={l.userId + l.teamId + (l.appId || l.app) + index}
                        item={l}
                        onClick={openUserCard}
                    />
                ))}
            {list.length > maxLength ? (
                <UserStatusListPopover
                    unfinishList={[]}
                    finishList={list}
                    onRemove={onRemove}
                    disable={false}
                >
                    <div className="supervise-userlist-item">
                        <div className="avatar">
                            <IconFont type="iconapp_btn_more_1" />
                        </div>
                    </div>
                </UserStatusListPopover>
            ) : null}

            <div className="supervise-userlist-add" onClick={openUserSelect}>
                <IconFont type="iconapp_btn_add2" />
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperviseUserList);
