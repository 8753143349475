import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Revoke from '@/components/chat/message/Revoke';
import Text from '@/components/chat/message/Text';
import HttpCard from '@/components/chat/message/HttpCard';
import Image from '@/components/chat/message/Image';
import UploadFile from '@/baseComponents/Chat/message/File/Upload';
import DownloadFile from '@/baseComponents/Chat/message/File/Download';
import Video from '@/components/chat/message/Video';
import Voice from '@/components/chat/message/Voice';
import Share from '@/components/chat/message/share';
import NoticeCard from '@/components/chat/message/NoticeCard';
import AINoticeCard from '@/components/chat/message/AINoticeCard';
import SysMessage from '@/components/chat/message/Sys';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import IconFont from '@/components/icon';
import PreviewImage from '../message/Preview/web/view_3';
import { changeEgovUrl } from '@/utils/tools';
import {
    ChatMessageType,
    Employee,
    FileChatMessage,
    ImageChatMessage,
    JoySpaceDocumentMessage,
    TextChatMessageExt,
    VideoMessage,
} from '@/types/chat';
import { MessageDateFormType, MessageSendType } from '@/types/chat/enum';
import { getMessageDateFormat } from '@/utils/date';
import {
    checkMessageSupport,
    convertContentToArray,
    isEqualEmployee,
    isSameClient,
    MessageCardType,
} from '@/utils/chat/message';
import JoyspaceFile from '../message/message-components/JoyspaceFile';
import {
    formatAtUser,
    getFileUrl,
    mattchUrl,
    stringEncode,
    getMessageFileName,
    checkDownloadFilePathValid,
    getFileFilter,
    downLoadFile,
} from '@/utils/chat/index';
import { convertStringToEmoji } from '@/components/chat/utils/ddemoji';
import { mattchEmoji } from '@/components/chat/message/Input/Emoji';
import { detectOS, DownloadItem, FocusSDK, isFocusEnv } from '@/utils';
import { message as toast, Modal } from 'antd';
import { openUserCardModal } from '@/components/AddressBook/common';
import lodashGet from 'lodash/get';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { replaceGovUrl } from '@/utils/joyspace';
import ImService from '@/server/ImService';
import { stickers_sets } from '@/components/chat/utils/stickers-config';
import './commonMessageModal.less';
import { addFileDownloadRecord } from '@/api/chat';
interface TextHighlightIProps {
    keyWord: string;
    content: string;
    sMid: number;
    mid: number;
}
export const getTextHighlightMessageContent = (props: TextHighlightIProps) => {
    const { keyWord = '', content = '', sMid = -1, mid = -1 } = props;
    const index = content.indexOf(keyWord);
    const beforeStr = content.substr(0, index);
    const afterStr = content.substr(index + keyWord.length);
    // sMid === mid 只有本条消息才高亮
    const highlight =
        index > -1 && sMid !== -1 && sMid === mid
            ? `<p class="message-highlight-container">${beforeStr}<span class="message-highlight">${keyWord}</span>${afterStr}</p>`
            : `${content}`;
    return highlight;
};
/* eslint max-params: ["error", 4] */

export const getEmoji = (content: string) => {
    const patt = /\[.*?\]/g;
    const re = content.match(patt);
    let result = content;
    let newText: any = Array.from(content).filter((item) => !!item);
    if (re && re.length > 0) {
        const NewreSet = new Set([...re]);
        NewreSet.forEach((r: string) => {
            if (!r) {
                return;
            }
            if (!stickers_sets[r]) {
                return;
            }
            const ispattern = result.indexOf(r);
            if (ispattern !== -1) {
                result = result.replace(r, convertStringToEmoji(r));
            }
        });
    }
    const pattern1 = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
    const specialEmojiRegex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;
    newText.forEach((e: string) => {
        if (stickers_sets[e] === undefined && pattern1.test(e)) {
            const pattern = new RegExp(e, 'g');
            result = result.replace(pattern, `<span class='message-emoji'>${e}</span>`);
        } else {
            if (specialEmojiRegex.test(e)) {
                const pattern = new RegExp(e, 'g');
                // console.log('3', e);
                result = result.replace(pattern, `<span class='message-emoji'>${e}</span>`);
            } else {
                return result;
            }
        }
    });
    return result;
};

export const getTextMessageContent = (
    message: TextChatMessageExt,
    currentEmployee: Employee,
    sText?: string,
    sMid?: number
) => {
    let { atUsers, content, mid = -1 } = message;
    content = stringEncode(content);
    let result = mattchUrl(content);
    result = formatAtUser(result, atUsers, currentEmployee);
    result = convertStringToEmoji(result);
    result = mattchEmoji(result);
    result = getTextHighlightMessageContent({
        keyWord: sText || '',
        content: result,
        sMid: sMid || -1,
        mid,
    });
    result = getEmoji(result);
    return result;
};

export const getPreviewImageModal = ({
    message,
    isMergeForward,
    modalMessages,
}: {
    message: any;
    isMergeForward?: boolean;
    modalMessages?: any[];
}) => {
    let previewImgVisible = false;
    if (isFocusEnv()) {
        let opts;
        if (isMergeForward) {
            const filterModalMessages = modalMessages
                ?.filter((item) => item.kind === ChatMessageType.IMAGE)
                .map((item) => {
                    return {
                        fileName: item.fileName,
                        url: item.url,
                        id: item.muuid,
                    };
                });
            const currentImageIndex = filterModalMessages?.findIndex(
                (item) => item.id === message?.muuid
            );
            opts = {
                isMergeForward: true,
                curIndex: currentImageIndex,
                filterModalMessages: JSON.stringify(filterModalMessages),
            };
            // console.log('filterModalMessages', modalMessages, { ...opts });
        } else {
            const imageMessage = message as ImageChatMessage;
            opts = {
                sessionId: imageMessage.sessionId,
                id: imageMessage.mid,
                fileName: getMessageFileName(imageMessage),
                fileUrl: imageMessage.url,
            };
        }
        FocusSDK.sendIpcMessage('image:createImagePreviewWindow', { ...opts });
    } else {
        // web端预览图片
        previewImgVisible = true;
    }
    const args = message?.sessionId?.split(':') || [];
    let isDownload = true;
    if (args.length > 6 && args[6] === 'secret') {
        isDownload = false;
    }
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    const MyModal = (
        <Modal
            title="图片预览"
            destroyOnClose={true}
            footer={null}
            keyboard={false}
            mask={false}
            visible={previewImgVisible}
            width="100%"
            wrapClassName="web-preview-image-modal"
            zIndex={1900}
        >
            {previewImgVisible && (
                <PreviewImage
                    fileName={getMessageFileName(message)}
                    fileUrl={(message as ImageChatMessage).url}
                    isDownload={isDownload}
                    closeModal={() => {
                        // e.stopPropagation();
                        // setPreviewImgVisible(false);
                        if (ele) {
                            ReactDOM.unmountComponentAtNode(ele);
                            ele.remove();
                        }
                    }}
                />
            )}
        </Modal>
    );
    ReactDOM.render(MyModal, ele);
};

export const getVideoModal = (message: any) => {
    // 点击聊天中视频播放按钮时触发
    let previewVideoVisible = false;
    if (isFocusEnv()) {
        const videoMessage = message as VideoMessage;
        if (videoMessage.url) {
            const imgSrc = getFileUrl(videoMessage.thumbnail);
            const videoSrc = message.downLocalPath || getFileUrl(videoMessage.url);
            FocusSDK.viewVideo({
                source: videoSrc,
                poster: imgSrc,
            });
        }
    } else {
        previewVideoVisible = true;
    }

    const ele = document.createElement('div');
    document.body.appendChild(ele);
    const MyModal = (
        <Modal
            destroyOnClose={true}
            keyboard={false}
            mask={false}
            footer={null}
            centered
            visible={previewVideoVisible}
            wrapClassName="web-view-video-modal-wrap"
        >
            <div className="preview-web-image-close">
                <IconFont
                    type="iconapp_btn_file_cancel"
                    onClick={(e: any) => {
                        // e.stopPropagation();
                        // previewVideoVisible = false;
                        if (ele) {
                            ReactDOM.unmountComponentAtNode(ele);
                            ele.remove();
                        }
                    }}
                />
            </div>
            <video
                controls
                loop={false}
                width="520"
                height="466"
                className="web-video"
                onClick={() => {
                    return false;
                }}
                onDoubleClick={() => {
                    return false;
                }}
                muted
                autoPlay={false}
                disablePictureInPicture
            >
                <source
                    src={changeEgovUrl(getFileUrl((message as VideoMessage).url))}
                    type="video/mp4"
                />
            </video>
        </Modal>
    );
    ReactDOM.render(MyModal, ele);
};

const getMessageItemProps = (message: any): any => {
    return {
        message: message,
    };
};

/**
 * 解析template-video格式
 * @param message
 */
const getMergeVideoProps = (message: any): any => {
    if (message?.data?.length) {
        const video = message?.data[0]?.video;
        if (video) {
            return {
                size: video.size || 0,
                thumbHeight: video.thumbHeight || 0,
                thumbWidth: video.thumbWidth || 0,
                thumbnail: video.imageUrl || '',
                url: video.videoUrl || '',
            };
        }
    }
    return {};
};
/* eslint complexity: ["error", 22] */
// eslint-disable-next-line complexity
export const getChatMessageNode = (
    modalMessages: any[],
    message: any,
    count: number,
    isMergeForward?: boolean
    // eslint-disable-next-line max-params
): ReactNode => {
    const isSupport = checkMessageSupport(message);
    // if (!isSupport) {
    //     return <UnSupport message={message} />;
    // }
    const isOldStyle =
        !message.extend?.messageFormatVer || message.extend?.messageFormatVer === 'v1';
    const props = getMessageItemProps(message);
    if (message.revoke === 1) {
        // 该消息已撤回
        return <Revoke {...props} />;
    }
    // 兼容老版本
    if (message.kind === ChatMessageType.JOYSPACEFILE) {
        const contentArr = convertContentToArray(message.content);
        // console.log(contentArr, 'contentArrcontentArrcontentArrcontentArr');
        const shortLinkArr = contentArr.filter(
            (item: any) => item.type === MessageCardType.ShortLink
        );
        // console.log(shortLinkArr, 'shortLinkArrshortLinkArrshortLinkArr');
        if (shortLinkArr.length > 1) {
            message.kind = ChatMessageType.TEXT;
        }
        // console.log(message, 'messagemessagemessagemessage==>');
        // message.content
    }
    switch (message.kind) {
        case ChatMessageType.JOYSPACEFILE:
            return <JoyspaceFile {...props} />;
        case ChatMessageType.TEXT:
            if (props.message.replyMsgs) {
                props.message.replyMsgs = null;
            }
            if ((message as any).nativeId === 'share_link') {
                return <HttpCard {...props} isforward={true} />;
            }
            return <Text {...props} isMergeForward={isMergeForward} />;
        case ChatMessageType.IMAGE:
            return (
                <Image isMergeForward={isMergeForward} modalMessages={modalMessages} {...props} />
            );
        case ChatMessageType.FILE:
            if (
                props.sendType === MessageSendType.OWNER ||
                props.sendType === MessageSendType.SELF
            ) {
                if (!isSameClient(message)) {
                    return <DownloadFile isMergeForward={isMergeForward} {...props} />;
                } else {
                    return <UploadFile isMergeForward={isMergeForward} {...props} />;
                }
            } else {
                return <DownloadFile isMergeForward={isMergeForward} {...props} />;
            }
        case ChatMessageType.VIDEO:
            return <Video isMergeForward={isMergeForward} {...props} />;
        case ChatMessageType.VOICE:
            return <Voice {...props} content="音频" />;
        case ChatMessageType.TEMPLATE:
            if (message?.template?.nativeId === 'video') {
                const video = getMergeVideoProps(message);
                const videoProps = {
                    message: { ...props.message, ...video },
                } as any;
                return <Video isMergeForward={isMergeForward} {...videoProps} />;
            }
            return <Share {...props} count={count} />;
        case ChatMessageType.TEMPLATE2:
            return <Share {...props} />;
        case 'unified_notice_message':
            if (!isOldStyle) {
                return <AINoticeCard {...props} />;
            }
            return <NoticeCard {...props} />;
        case ChatMessageType.SYS: {
            return <SysMessage {...props} />;
        }
        default:
            return null;
    }
};

export const getHeadPortrait = (message: any): ReactNode => {
    return (
        <HeadPortrait
            width="32px"
            height="32px"
            imgUrl={message.senderUrl}
            name={message.senderName}
            isUnFollow={true}
        />
    );
};

export const getShowTime = (message: any) => {
    const { timestamp } = message;
    return getMessageDateFormat(timestamp, MessageDateFormType.DATE_AGGREGATION);
};

export const TimeTransform = (datetime: any) => {
    let getDate: any = new Date(datetime).getDate();
    let getMonth: any = new Date(datetime).getMonth() + 1;
    let getFullYear = new Date(datetime).getFullYear();
    if (String(getMonth).length < 2) {
        getMonth = '0' + getMonth;
    }
    if (String(getDate).length < 2) {
        getDate = '0' + getDate;
    }
    return `${getFullYear}-${getMonth}-${getDate}`;
};

export const openShareUserCard = (message: JoySpaceDocumentMessage) => {
    const cardInfo = lodashGet(message, 'data.0.sharecard', {});
    const data = {
        userId: cardInfo?.pin,
        teamId: cardInfo?.teamId,
        app: cardInfo?.app,
    } as any;
    openUserCardModal(data);
};

export const openShareCommonCard = (message: JoySpaceDocumentMessage, from?: number) => {
    const { sharelink } = message.data[0];
    if (!sharelink.url) {
        return;
    }
    // 日程需要区分分享的详细来源，是聊天的IM窗口还是日程详情的IM窗口故增加from参数标记
    DeeplinkEvent.open(replaceGovUrl(sharelink.url), { source: 'imShareCard', from: from });
};

export const checkFilesLocal = (path: string): Promise<boolean> => {
    return new Promise((resolve) => {
        if (!path) {
            resolve(false);
        }
        FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
            resolve(exists);
        });
    });
};

export const getFileFolderPath = (filePath: string) => {
    return detectOS() === 'Win'
        ? filePath.split('\\').slice(0, -1).join('\\')
        : filePath.split('/').slice(0, -1).join('/');
};

export const viewUploadFile = async (
    message: any,
    t: any,
    folderPath?: string,
    currentEmployee?: Employee
    // eslint-disable-next-line max-params
) => {
    const fileMessage = message as FileChatMessage;
    if (isFocusEnv()) {
        let exists = await checkFilesLocal(fileMessage.upLocalPath);
        if (folderPath) {
            if (fileMessage.upLocalPath) {
                if (exists) {
                    FocusSDK.openItem(getFileFolderPath(fileMessage.upLocalPath));
                }
            } else {
                toast.error('文件已被清理');
            }
        }
        if (!exists) {
            exists = await checkFilesLocal(fileMessage.downLocalPath);
            if (exists) {
                FocusSDK.openItem(
                    folderPath
                        ? getFileFolderPath(fileMessage.downLocalPath)
                        : fileMessage.downLocalPath
                );
            } else {
                // toast.error(t('find-file-failed-and-upload'));
                downloadReplyFile({ fileMessage, t, currentEmployee, openFolder: !!folderPath });
            }
        } else {
            FocusSDK.openItem(
                folderPath ? getFileFolderPath(fileMessage.upLocalPath) : fileMessage.upLocalPath
            );
        }
    } else {
        const name = getMessageFileName(message);
        const url = getFileUrl(message.url);
        downLoadFile(name, url);
        downLoadFile(name, url, '', {
            onFinish: () => {
                handleAddFileDownloadRecord(message, currentEmployee);
            },
        });
    }
};

export const downloadReplyFile = ({
    fileMessage,
    t,
    currentEmployee,
    openFolder,
}: {
    fileMessage: FileChatMessage;
    t: any;
    currentEmployee?: Employee;
    openFolder?: boolean;
}) => {
    const saveName = getMessageFileName(fileMessage);
    const url = getFileUrl(fileMessage.url);
    FocusSDK.downloadFile(url, {
        saveAs: false,
        filename: saveName,
        // defaultPath: isFocusEnv()
        //     ? defaultPath
        //     : localStorage.getItem('downloadFilePath') || undefined,
        filter: getFileFilter(fileMessage),
        onStarted: (downloadItem: DownloadItem) => {},
        onProgress: ({ percent }) => {},
        onCancel: () => {},
        onPause: () => {},
        onResume: () => {},
        onFinish: (file: { path: string; filename: string }, data: any) => {
            // tj: 解决保存为头像地址
            if (!checkDownloadFilePathValid(saveName, file)) {
                toast.error(t('failed'));
                return;
            }
            let path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
            if (openFolder) {
                path = getFileFolderPath(path);
            }
            const imService = ImService.getInstance();
            imService
                .updateLocalPath(fileMessage.sessionId, fileMessage.mid, {
                    downLocalPath: path,
                })
                .then((result) => {
                    if (result) {
                        FocusSDK.openItem(path);
                        handleAddFileDownloadRecord(fileMessage, currentEmployee);
                    } else {
                        toast.error(t('failed'));
                    }
                });
        },
    });
};

export const handleAddFileDownloadRecord = (
    fileMessage: FileChatMessage,
    currentEmployee: Employee | undefined
) => {
    if (currentEmployee && !isEqualEmployee(fileMessage.sender, currentEmployee)) {
        addFileDownloadRecord({
            fileId: fileMessage.id,
            fileName: (fileMessage as any).name,
            groupId: fileMessage.sessionId,
            mid: fileMessage.mid,
            senderUserId: fileMessage.sender.userId,
            senderTeamId: fileMessage.sender.teamId,
        });
    }
};
