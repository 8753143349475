/**
 * 上传文件公共组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileStatus } from '@/types/chat';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { message as AntMessage } from 'antd';
interface UploadControl {
    message: any;
    onProgress: Function;
    onComplete: Function;
    onFailed: Function;
    onStart: Function;
    status?: string;
    onCancel?: Function;
}

interface DvaProps {
    updateMessageUploadState: Function;
}

type IUploadControl = Readonly<UploadControl & ChatState & DvaProps>;

function UploadControl(props: IUploadControl) {
    const UPLOAD_ERROR_KEY = `UPLOAD_ERROR_KEY`;
    const {
        onFailed,
        onProgress,
        onStart,
        onComplete,
        onCancel,
        status,
        selectedSession,
        message,
        updateMessageUploadState,
        connectState,
    } = props;
    const sessionId = message.sessionId;

    const task = useMemo(() => {
        if (message.taskId && message.task && message.task.on) {
            return message.task;
        }
        return null;
    }, [message]);

    const [uploadState, setUploadState] = useState(FileStatus.INIT);

    const uploadCallBack = useCallback(
        (data: any) => {
            if (!task) {
                return;
            }
            setUploadState(data.state);
            const msgId = message.id;
            if (data.state === FileStatus.UPLOADING) {
                const progress = data.progress * 100;
                const p = progress > 99 ? 99 : progress;
                onProgress(p);
                // dispatch 更新messageUploadStateMap 数据
                updateMessageUploadState({ msgId: msgId, progress: p });
            } else if (data.state === FileStatus.COMPLETED) {
                onComplete(data);
                // dispatch 更新messageUploadStateMap 数据
                updateMessageUploadState({ msgId: msgId, progress: 100, uploadResult: data });
            } else if (data.state === FileStatus.FAILED) {
                // onFailed(data);
                updateMessageUploadState({ msgId: msgId });
                // console.log('发送失败', data);
                // tj: 失败显示失败原因
                if (data.reason && data.reason.message) {
                    AntMessage.error({
                        content: '发送失败',
                        duration: 2,
                        key: UPLOAD_ERROR_KEY,
                    });
                    if (data.reason.message === '文件大小必须大于0') {
                        AntMessage.error('不支持发送空文件');
                    }
                }
            } else if (data.state === FileStatus.CANCELED) {
                // updateMessageUploadState({ msgId: msgId });
            } else if (data.state === FileStatus.CALC) {
                onStart();
            }
        },
        [sessionId, setUploadState] // eslint-disable-line
    );

    const selectedSessionId = selectedSession.sessionId;
    useEffect(() => {
        if (!task) {
            return;
        }
        if (task?.id) {
            // 无图片远程地址，有上传图片任务
            task.on && task.on('state', uploadCallBack);
            if (task.state === 'pending') {
                task.start().then(() => {
                    onStart?.();
                });
            }
        }
        // return () => {
        //     if (task?.id) {
        //         task.off('state', uploadCallBack);
        //     }
        // };
    }, [task]); // eslint-disable-line

    useEffect(() => {
        if (!task) {
            return;
        }
        if (task?.id) {
            if (connectState.state !== ConnectState.READY) {
                task.cancel()
                    .then((data: any) => {
                        onFailed?.(data);
                    })
                    .catch((e: any) => {
                        // console.log('onCancel ==>', e);
                    });
            } else if (status === 'canceled') {
                task.cancel()
                    .then((data: any) => {
                        onCancel?.(data);
                    })
                    .catch((e: any) => {
                        // console.log('onCancel ==>', e);
                    });
            } else if (status === 'restart') {
                if (uploadState === FileStatus.FAILED || uploadState === FileStatus.CANCELING) {
                    onProgress(0);
                    task.start().then(() => {
                        onStart?.();
                    });
                } else {
                    task.restart();
                }
            }
        }
    }, [status, connectState, task]); // eslint-disable-line

    return <div />;
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, connectState } = chat as ChatState;
    return { selectedSession, connectState };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async updateMessageUploadState(data: { msgId: string; progress: number }) {
            await dispatch({ type: 'chat/updateMessageUploadState', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadControl);
