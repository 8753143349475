import { request } from '@/components/Joyspace/api/request';

// 查找团队创建者
export function findUserInfo(id: string, tenant_id: string) {
    return request({
        api: 'joyspace.user.find',
        path: '/v1/users/find',
        method: 'GET',
        params: {
            id,
            tenant_id,
        },
    }).catch(() => []);
}
// 获取团队下空间
export function getSpaces(teamId: any) {
    return request({
        api: 'joyspace.team.spaces',
        path: `/v1/teams/${teamId}/spaces`,
        method: 'GET',
    }).catch(() => []);
}
// 新建团队
export function teamCreate(body: {
    avatar_url: String;
    description: String;
    name: String;
    type: String;
}) {
    return request({
        api: 'joyspace.team.create',
        path: 'v1/teams',
        method: 'POST',
        body,
    }).catch((error) => {
        console.log('error---', error);
        return [];
    });
}
// 获取全部团队
export function getTeams() {
    return request({
        api: 'joyspace.team.list',
        path: '/v1/teams',
        method: 'GET',
    }).catch(() => []);
}

// 获取关注空间
export function getFollow(limit: number, start: number, target_type: number) {
    return request({
        api: 'joyspace.follow.list',
        path: '/v1/follow',
        method: 'GET',
        params: {
            limit,
            start,
            target_type,
        },
    }).catch(() => []);
}

// 加入审批-获取申请记录
export function getAllApplications(teamId: string | number, start = 0, status: number) {
    return request({
        api: 'joyspace.teamMember.applies',
        path: `/v1/teams/${teamId}/members/applies`,
        method: 'GET',
        params: {
            length: 10,
            start,
            status: status === 0 ? null : status,
        },
    }).catch(() => []);
}
export function agreeApplication(recordId: number, permissionType = 2) {
    return request({
        api: 'joyspace.inviteRecord.confirm',
        path: `/v1/invite-records/${recordId}/confirm`,
        method: 'PUT',
        body: {
            permissionType,
        },
    });
}
export function refuseApplication(recordId: number, reason: string) {
    return request({
        api: 'joyspace.inviteRecord.refuse',
        path: `/v1/invite-records/${recordId}/refuse`,
        method: 'PUT',
        body: {
            reason,
        },
    }).catch(() => []);
}
// 加入审批-批量同意用户申请
export function batchAgree(recordIds: Array<number | string>, permissionType: number | string) {
    return request({
        api: 'joyspace.inviteRecord.batchApproved',
        path: `/v1/invite-records/confirm/batch`,
        method: 'PUT',
        body: {
            recordIds,
            permissionType,
        },
    }).catch(() => []);
}
// 加入审批-批量拒绝用户申请
export function batchRefuse(recordIds: Array<number | string>, reason: string) {
    return request({
        api: 'joyspace.inviteRecord.batchRefuse',
        path: `/v1/invite-records/refuse/batch`,
        method: 'PUT',
        body: {
            recordIds,
            reason,
        },
    }).catch(() => []);
}

// 新建空间
export function spaceCreate(body: {
    avatar_url: String;
    description: String;
    name: String;
    type: String;
    team_id: String;
}) {
    return request({
        api: 'joyspace.space.create',
        path: 'v1/spaces',
        method: 'POST',
        body,
    }).catch(() => []);
}

// 空间重命名
export function updateSpaceName(
    spaceId: string,
    body: {
        name: string;
    }
) {
    return request({
        api: 'joyspace.space.update',
        path: `v1/spaces/${spaceId}`,
        method: 'PUT',
        body,
    }).catch(() => []);
}

// 更新团队名称，描述
export function updateTeamInfo(
    teamId: string,
    body: {
        name?: string;
        description?: string;
        avatar_url?: string;
        type?: string;
    }
) {
    return request({
        api: 'joyspace.team.update',
        path: `/v1/teams/${teamId}`,
        method: 'PUT',
        body,
    }).catch(() => []);
}

// 获取成员邀请的链接
export function getMemberInviteLink(targetId: string) {
    return request({
        api: 'joyspace.invite.getByTargetIdAndType',
        path: `/v1/invites/getByTargetIdAndType`,
        method: 'GET',
        params: {
            targetType: 10,
            targetId,
        },
    }).catch(() => []);
}

// 重置成员邀请的链接
export function resetMemberInviteLink(targetId: string) {
    return request({
        api: 'joyspace.invite.resetByTargetIdAndType',
        path: `/v1/invites/resetByTargetIdAndType`,
        method: 'POST',
        body: {
            targetType: 10,
            targetId,
        },
    }).catch(() => []);
}
// 批量添加团队成员
export function batchAddTeamMembers(
    teamId: string,
    selectedParams: { groups: Array<any>; eepaasUsers: Array<any> }
) {
    return request({
        api: 'joyspace.team.invite',
        path: `/v1/teams/${teamId}/invite`,
        method: 'POST',
        body: selectedParams,
    }).catch(() => []);
}

// 成员管理获取成员
export function getMembersOfMemberManage(
    teamId: string,
    { start = 0, length = 10, search = '', permissionType = null }
) {
    return request({
        api: 'joyspace.teamMember.members',
        path: `/v1/teams/${teamId}/members`,
        method: 'GET',
        params: {
            start,
            length,
            search,
            permissionType,
        },
    }).catch(() => []);
}

// 成员管理->批量删除成员
export function batchRemoveMembersOfMemberManage(teamId: string, userIds: string[]) {
    return request({
        api: 'joyspace.teamMember.batchRemove',
        path: `/v1/teams/members/batchRemove`,
        method: 'DELETE',
        body: {
            teamId,
            userIds,
        },
    }).catch(() => []);
}
// 成员管理->批量修改成员权限
export function batchChangePermissionOfMemberManage(
    teamId: string,
    userIds: string[],
    permission: number
) {
    return request({
        api: 'joyspace.teamMember.batchPermission',
        path: `/v1/teams/members/batchPermission`,
        method: 'PUT',
        body: {
            teamId,
            userIds,
            permission,
        },
    }).catch(() => []);
}

// 成员管理->删除成员
export function removeMembersOfMemberManage(teamId: string, userId: string) {
    return request({
        api: 'joyspace.teamMember.remove',
        path: `/v1/teams/${teamId}/members/${userId}/remove`,
        method: 'DELETE',
    }).catch(() => []);
}
// 成员管理->修改成员权限
export function changePermissionOfMemberManage(teamId: string, userId: string, permission: number) {
    return request({
        api: 'joyspace.teamMember.permission',
        path: `/v1/teams/${teamId}/members/${userId}/permission`,
        method: 'PUT',
        body: {
            permission,
        },
    }).catch(() => []);
}
// 删除团队
export function teamRemove(teamId: string) {
    return request({
        api: 'joyspace.team.remove',
        path: `/v1/teams/${teamId}`,
        method: 'DELETE',
    }).catch(() => []);
}

// 删除空间
export function spaceRemove(spaceId: string) {
    return request({
        api: 'joyspace.space.remove',
        path: `/v1/spaces/${spaceId}`,
        method: 'DELETE',
    }).catch(() => []);
}

// 更新空间
export function updateSpaceInfo(
    spaceId: string,
    body: {
        name?: string;
        description?: string;
        avatar_url?: string;
        type?: string;
    }
) {
    return request({
        api: 'joyspace.space.update',
        path: `/v1/spaces/${spaceId}`,
        method: 'PUT',
        body,
    }).catch(() => []);
}

// 新建文件夹
export function folderCreate(body: { name: string; parent: string }) {
    return request({
        api: 'joyspace.folder.create',
        path: '/v1/folders',
        method: 'POST',
        body,
    }).catch(() => []);
}

export function teams() {
    return request({
        api: 'joyspace.team.findAllByUserIdAndPermissionType',
        path: '/v1/teams/findAllByUserIdAndPermissionType',
    }).catch(() => []);
}

// 获取团队成员
export function getTeamMembers(teamId: string) {
    return request({
        api: 'joyspace.teamMember.members',
        path: `/v1/teams/${teamId}/members`,
        method: 'GET',
    }).catch(() => []);
}
