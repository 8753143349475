import React from 'react';

function JoyMeetingSys({ message: data, t }: { message: any; t: any }) {
    const [message, setMessage] = React.useState(data);

    return (
        <div className="text-message">
            <span>{`[${t('system-message-group.joymeeting')}] ${message.content}`}</span>
        </div>
    );
}

export default JoyMeetingSys;
