let whiteHostsEgov = [
    'https://janus.jzb.beijing.egov.cn',
    'https://oss.jzb.beijing.egov.cn',
    'https://eefs.jzb.beijing.egov.cn',
    'https://im-ws.jzb.beijing.egov.cn',
    'https://wps.jzb.beijing.egov.cn',
    'https://open.jzb.beijing.egov.cn',
    'https://apijoyspace.jzb.beijing.egov.cn',
    'https://joyspace.jzb.beijing.egov.cn',
    'https://otjoyspace.jzb.beijing.egov.cn',
];
let whiteHostsGov = [
    'https://janus.jzb.beijing.gov.cn',
    'https://oss.jzb.beijing.gov.cn',
    'https://eefs.jzb.beijing.gov.cn',
    'https://im-ws.jzb.beijing.gov.cn',
    'https://wps.jzb.beijing.gov.cn',
    'https://open.jzb.beijing.gov.cn',
    'https://apijoyspace.jzb.beijing.gov.cn',
    'https://joyspace.jzb.beijing.gov.cn',
    'https://otjoyspace.jzb.beijing.gov.cn',
];

const whiteHostList = [...whiteHostsEgov, ...whiteHostsGov];

export const whiteHostReg = /^https?:\/\/[a-zA-Z\-\.]+\.(jdcloud.com|beijing\.e?gov\.cn)/;

const relativePathReg = /^\.?\.?\/.*/;

export const isInWhiteHosts = (url: string) => {
    return whiteHostReg.test(url) || relativePathReg.test(url);
};
