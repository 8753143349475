/* eslint-disable array-callback-return */
/**
 * 消息盒子组件
 */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { MessageDateFormType } from '@/types/chat/enum';
import { useTranslation } from 'react-i18next';

import { ChatMessage, Session, UID } from '@/types/chat';
import ChatMessageItem from '@/baseComponents/Chat/message/Item/ChatItem';
import { getMessageDateFormat } from '@/utils/date';
import { MessageEmitCategory } from '@jd/jdee.im.sdk/lib/es/enum/MessageEmitType';
import SysMessageItem from '@/baseComponents/Chat/message/Item/SysItem';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import SysMessage from '@/baseComponents/Chat/message/Sys';
import Revoke from '@/baseComponents/Chat/message/Revoke';
import Context from '@/context/ChatContext';
import bus from '@/utils/bus';
import './index.less';
import ImService from '@/server/ImService';
interface MessageBoxProps {
    messages: ChatMessage[];
    session?: Session;
    getLock: Function;
    unLock: Function;
    getCurrentMessageId: Function;
    setCurrentMessageId: Function;
}
let visbol: boolean;
type IProps = Readonly<MessageBoxProps>;
function MessageBox(props: IProps) {
    const { messages, session, getCurrentMessageId } = props;
    const { groupRosterIdentity } = React.useContext(Context);
    const { t } = useTranslation('chat');
    // console.log(messages, 'MessageBox===>');
    // 临时办法， 这样写并不友好。因为 ImService.getInstance() 和 imService.getBySession 会执行多次
    const imService = ImService.getInstance();
    const msgId = getCurrentMessageId();
    if (msgId) {
        const message: any = messages.find((item) => item.id === msgId) || {};
        if (message.type === ChatMessageType.JOYSPACEFILE) {
            imService.getBySession(message.sessionId, message.mid).then((result: any) => {
                message.currentPermission = result.currentPermission;
            });
        }
    }
    const getSendTime = useCallback(
        (message) => {
            const sendTime = getMessageDateFormat(
                message.timestamp,
                MessageDateFormType.DATE_AGGREGATION
            );
            return <div className="time">{sendTime}</div>;
        },
        [t] // eslint-disable-line
    );
    useEffect(() => {
        bus.on(`message:Box`, (data) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            visbol = data;
        });
        return () => {
            bus.off(`message:Box`, (data) => {
                // setvisbol(data);
                visbol = data;
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (messages.length > 0) {
        return (
            <div className="message-node">
                {messages.map((message: any, index) => {
                    if (message.category === MessageEmitCategory.SYSTEM) {
                        if (
                            message.type === ChatMessageType.SYS &&
                            message.flag !== 100 &&
                            !message.data?._sys_msg_type_
                        ) {
                            return <SysMessage key={`s-2-${message.id}`} message={message} />;
                        } else {
                            return <SysMessageItem key={`s-${message.id}`} message={message} />;
                        }
                    } else if (message.category === MessageEmitCategory.CHAT_MESSAGE) {
                        if (message.revoke === 1) {
                            return (
                                <Revoke
                                    key={`r-${message.id}`}
                                    message={message}
                                    sessionType={session?.sessionType}
                                />
                            );
                        } else {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                        display: message.plusList && 'flex',
                                        justifyContent: message.plusList && 'center',
                                    }}
                                    className={
                                        visbol === true &&
                                        !message.mid &&
                                        message.type === ChatMessageType.TEXT
                                            ? 'mergeUp'
                                            : ''
                                    }
                                >
                                    <ChatMessageItem
                                        key={`c-${message.id}-${message.timestamp}`}
                                        groupRosterIdentity={groupRosterIdentity}
                                        sendTime={getSendTime(messages[0])}
                                        prevMessage={index > 0 ? messages[index - 1] : null}
                                        message={message}
                                        getLock={props.getLock}
                                        unLock={props.unLock}
                                        getCurrentMessageId={props.getCurrentMessageId}
                                        setCurrentMessageId={props.setCurrentMessageId}
                                    />
                                </div>
                            );
                        }
                    } else {
                        return null;
                    }
                })}
            </div>
        );
    }
    return null;
}
export default MessageBox;
