import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import { UserCardPayload } from '@/models';
import { Employee, Session, UID } from '@/types/chat';
import { ApplyTeamState } from '@/types/chat/enum';
import { Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { agreeJoinTeam, rejectJoinTeam } from '@/api/joinMe';
import ImService from '@/server/ImService';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import Bus from '@/utils/bus';
import { UserCardType } from '@/types/UserCard';
import i18n from '@/i18n';

const ApplyTeamCard = connect(
    function ({ chat }: { chat: ChatState }) {
        const { selectedSession, currentEmployee } = chat;
        return { selectedSession, currentEmployee };
    },
    function (dispatch: any) {
        return {
            toggleUserCardModal(payload: UserCardPayload) {
                dispatch({ type: 'app/toggleUserCardModal', payload });
            },
        };
    }
)(function ({
    message,
    toggleUserCardModal,
    selectedSession,
    currentEmployee,
}: {
    message: any;
    toggleUserCardModal: Function;
    selectedSession: Session;
    currentEmployee: Employee;
}) {
    const { t } = useTranslation('chat');

    const [invitee, setInvitee] = useState<UID>({ app: '', name: '', pin: '', teamId: '' });
    const [applyState, setApplyState] = useState(ApplyTeamState.INIT);

    // 同意操作
    const agreeAction = useCallback(async () => {
        if (message.infox?.phoneNum) {
            Modal.confirm({
                title: t('agreeApply'),
                icon: <CheckCircleFilled />,
                className: 'team-apply-modal',
                content: (
                    <p
                        style={{
                            fontSize: '14px',
                            lineHeight: '22px',
                        }}
                    >
                        {t('agreeConfirm')}
                        <span className="info">{message.infox.fromUserName}</span>
                        {t('joinAsk').replace('%team', message.infox.teamName)}
                    </p>
                ),
                okText: t('apply.agree'),
                cancelText: t('cancelText'),
                onOk: async () => {
                    const res: any = await agreeJoinTeam({
                        inviteTeamId: message.infox.teamId,
                        invitedUserMobile: message.infox.phoneNum,
                        invitedUserName: message.infox.fromUserName,
                    });
                    if (res.joinTeamResult) {
                        const imService = ImService.getInstance();
                        await imService.updateTeamApplyState(
                            selectedSession.sessionId,
                            message,
                            ApplyTeamState.AGREED
                        );
                        setApplyState(ApplyTeamState.AGREED);
                    }
                },
            });
        }
    }, [t, selectedSession.sessionId, message]);

    const refuseAction = useCallback(async () => {
        if (message.infox?.phoneNum) {
            Modal.confirm({
                title: t('rejectApply'),
                icon: <CheckCircleFilled />,
                className: 'team-apply-modal-reject',
                content: (
                    <p
                        style={{
                            fontSize: '14px',
                            lineHeight: '22px',
                        }}
                    >
                        {t('rejectApply')}
                        <span className="info">{message.infox.fromUserName}</span>
                        {t('joinAsk').replace('%team', message.infox.teamName)}
                    </p>
                ),
                okText: t('apply.refuse'),
                cancelText: t('cancelText'),
                onOk: async () => {
                    const res: any = await rejectJoinTeam({
                        inviteTeamId: message.infox.teamId,
                        invitedUserMobile: message.infox.phoneNum,
                        invitedUserName: message.infox.fromUserName,
                    });
                    if (res.joinTeamResult) {
                        const imService = ImService.getInstance();
                        await imService.updateTeamApplyState(
                            selectedSession.sessionId,
                            message,
                            ApplyTeamState.REJECTED
                        );
                        setApplyState(ApplyTeamState.REJECTED);
                    }
                },
            });
        }
    }, [selectedSession.sessionId, message, t]);

    useEffect(() => {
        const { applyState } = message.infox;
        if (applyState) {
            setApplyState(applyState);
        }

        const { from, noticeType } = message.infox;

        if (from) {
            const fromJson = attempt(JSON.parse, from);
            if (isError(fromJson)) {
                return;
            }
            if (noticeType !== 'reply') {
                setInvitee(fromJson);
            }
        }
    }, [message]);

    const stateNode = useMemo(() => {
        // console.log('stateNode ===》', message);
        if (message.infox.noticeType === 'reply') {
            // 可以再次申请
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else if (applyState === ApplyTeamState.AGREED) {
            return <div className="agreed">{t('apply.agreed')}</div>;
        } else if (applyState === ApplyTeamState.REJECTED) {
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else {
            return (
                <div className="apply-setting-action">
                    <button className="refuse" onClick={refuseAction}>
                        {t('apply.refuse')}
                    </button>
                    <button className="agree" onClick={agreeAction}>
                        {t('apply.agree')}
                    </button>
                </div>
            );
        }
    }, [applyState, message, t, refuseAction, agreeAction]);

    const openUserCard = useCallback(() => {
        const from = attempt(JSON.parse, message.infox.from);
        if (isError(from)) {
            return;
        }
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId: from?.pin,
            teamId: from?.teamId,
            appId: from?.app,
        });
    }, [message.infox.from]);

    const applyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.apply-join')}</span>
            <span className="apply-details-content-link group">{message.infox.groupName}</span>
            {message.infox.reason && (
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;{t('apply.reason')}: {message.infox.reason}
                </span>
            )}
        </div>
    );

    const replyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.refuse_you_add')}</span>
            <span>{message.infox.groupName}</span>
        </div>
    );

    const contentNode = useMemo(() => {
        if (message.infox.noticeType === 'reply') {
            return replyContent;
        } else {
            return applyContent;
        }
    }, [applyContent, message.infox.noticeType, replyContent]);

    return (
        <div className="apply-group-card">
            <div className="apply-details">
                <div className="apply-details-title">
                    {message.infox.title}
                    {/* {message.infox.teamName} */}
                </div>
                {/* <div className="apply-details-title">{message.infox.fromUserName}</div> */}
                <p className="apply-team">
                    {/* onClick={openUserCard} */}
                    <span>{message.infox.fromUserName}</span>
                    {t('applyJoin')}
                    {message.infox.teamName}
                </p>
                <p className="apply-team">
                    {t('mobile')}: {message.infox.phoneNum}
                </p>
                {/* {contentNode} */}
            </div>
            <div className="apply-setting">{stateNode}</div>
        </div>
    );
});

export default ApplyTeamCard;
