/**
 * 下载队列
 */

import { FileChatMessage, VideoChatMessage, ImageChatMessage } from '@/types/chat';
import { DownloadItem, DownloadProgress } from '.';
import DownloadService from '@/server/DownloadService';

export interface SaveOption {
    viewFile: boolean;
    saveAs: boolean;
    message?: any;
    isGroup?: boolean;
    auto?: boolean;
    alreadySaveAs?: boolean;
}

export interface DownloadOption {
    onStarted: (downloadItem: DownloadItem) => void;
    onProgress: (progress: DownloadProgress) => void;
    onCancel: (alreadySaveAs?: boolean) => void;
    onPause?: () => void;
    onResume?: () => void;
    onFinish: (file: { path: string; filename: string }, data: any) => void;
}

interface QueueItemp {
    data: FileChatMessage | VideoChatMessage | ImageChatMessage;
    dowanload: (options: SaveOption) => Promise<void>;
    resolve?: (value?: any) => void;
    reject?: (reason?: unknown) => void;
}

class DownloadFileQueue {
    static donwloadServiceInstance: DownloadService;
    private static instance: DownloadFileQueue | null;
    // 下载队列

    public static getInstance(): DownloadFileQueue {
        if (!this.instance) {
            this.instance = new DownloadFileQueue();
            DownloadFileQueue.donwloadServiceInstance = DownloadService.getInstance({});
        }
        return this.instance;
    }

    private currTask: QueueItemp | null = null;
    private queueItem: QueueItemp[] = [];
    private working = false;

    /**
     * 增加下载任务
     * @param item
     */
    public pushQueue(item: QueueItemp): void {
        const saveOption = DownloadFileQueue.donwloadServiceInstance.getSaveOption(item.data.mid);
        const idx = this.queueItem.findIndex((i: QueueItemp) => i.data.mid === item.data.mid);
        if (
            (!this.currTask || this.currTask.data.mid !== item.data.mid) &&
            !saveOption &&
            idx === -1
        ) {
            this.queueItem.unshift(item);
        }
        this.consumer();
    }

    /**
     * 删除下载任务
     * @param item
     */
    public delQueue(mid: number): void {
        const idx = this.queueItem.findIndex((i: QueueItemp) => i.data.mid === mid);
        if (idx > -1) {
            this.queueItem.splice(idx, 1);
        }
    }

    /**
     * 清空下载队列
     */
    public clearQueue(): void {
        console.log('清空自动下载队列', JSON.parse(JSON.stringify(this.queueItem)));
        // this.queueItem.splice(0, this.queueItem.length);
        this.queueItem = [];
        console.log('清空自动下载队列end', JSON.parse(JSON.stringify(this.queueItem)));
        this.currTask = null;
        this.working = false;
    }

    private async consumer(): Promise<void> {
        if (!this.working) {
            const item = this.queueItem.pop();
            if (item) {
                this.working = true;
                this.currTask = item;
                const { data, dowanload, resolve, reject } = item;
                const saveOption = DownloadFileQueue.donwloadServiceInstance.getSaveOption(
                    item.data.mid
                );
                // 如果正在手动下载，跳过当前文件的自动下载，执行下一个文件自动下载
                if (saveOption) {
                    this.working = false;
                    this.currTask = null;
                    resolve?.(true);
                    this.consumer();
                } else {
                    dowanload({ message: data, viewFile: false, saveAs: false, auto: true })
                        .then((res) => {
                            this.working = false;
                            this.currTask = null;
                            resolve?.(res);
                            this.consumer();
                        })
                        .catch((err) => {
                            this.working = false;
                            this.currTask = null;
                            reject?.(err);
                            console.log(`auto download error`, err);
                            this.consumer();
                        });
                }
            } else {
                this.working = false;
            }
        }
    }
}
export default DownloadFileQueue;
