import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Modal, Button, message } from 'antd';
import { Loading } from '@jd/focus-desktop-comps';
import './index.less';
import { getQrCode } from '@/components/Joyspace/api/page';
import { Avatar } from '@/baseComponents/Avatar';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import querystring from 'querystring';
import { FocusSDK } from '@/utils';
import { downLoadFile, getHeadImgThumbUrl, html2Canvas } from '@/utils/chat/index';
import { debounce } from 'lodash';
import { changeEgovUrl } from '@/utils/tools';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import { Employee } from '@/types/chat';
import { APP_NAME } from '@/config/name';
import ICON from '@/assets/templateImgs/logo-thumb.png';
import iconJzb from '@/assets/img/icon-jzb_thumbnail.png';
interface QRCodeProp {
    visible: boolean;
    onCancel: () => void;
    currentEmployee: Employee;
}

function PersonalQRCode(props: QRCodeProp) {
    const { visible, onCancel, currentEmployee } = props;
    const {
        authInfo: { teamUserInfo, userId, account },
    } = useContext<GlobalContextConfig>(GlobalContext);
    const [qrcodeUrl, setQrcodeUrl] = useState<string>('');
    const { app: appId, teamId, name, nickName, avatar: headImg } = currentEmployee || {};
    const [onSaving, setOnSaving] = useState<boolean>(false);
    // "jdgwork://jm/personalCardPage?mparam={"appId":"gwork","teamId":"QFJytWrURIWKNmkeSSzvf","userId":"5JiacdJyA1vuhLCNDY0qW"}"
    let deepLink = `${appId.replace(/_/g, '')}://jm/personalCardPage`;
    if(!deepLink.includes('jd')){
        deepLink = 'jd'+deepLink;
    }
    let params = {
        mparam: JSON.stringify({
            appId,
            teamId,
            userId,
            from: 'scan',
        }),
    };
    // let content = `${deepLink}?${querystring.stringify(params)}`;
    let content = `inviteUserCardPage=${deepLink}?${querystring.stringify(params)}`;
    const qrModal = useRef(null);

    const qrClass = useCallback((type?: boolean) => {
        return type ? 'qr-save-wrap qr-save-wrap-none' : 'qr-save-wrap';
    }, []);

    const userName = useMemo(() => {
        return name ? name : nickName;
    }, [name, nickName]);

    const modalFooter = () => {
        return (
            <div>
                <Button
                    style={{
                        height: 30,
                        border: '1px solid #dee0e3',
                        borderRadius: ' 4px',
                        color: '#242931',
                    }}
                    onClick={saveQrcode}
                >
                    保存
                </Button>
                {FocusSDK.getDeviceInfo().platform !== 'web' && (
                    <Button
                        className="primary-btn"
                        type="primary"
                        style={{
                            height: 30,
                            border: 'none',
                            backgroundColor: ' @primary-color !important',
                            borderRadius: '4px',
                            fontSize: '14px',
                            color: '#ffffff',
                        }}
                        onClick={() => {
                            copyQrcode();
                        }}
                    >
                        复制
                    </Button>
                )}
            </div>
        );
    };
    useEffect(() => {
        // appKey后端说写死
        getQrCode({
            appKey: 'YO6OmiYA8vDt0cWq2Ox7',
            content,
            bizCode: 'singleCard',
            type: 'QR_CODE',
            height: 200,
            width: 200,
        }).then((res) => {
            if (!res) {
                return;
            }
            setQrcodeUrl(`data:image/png;base64,${res.qrCode}`);
        });
    }, [content]);

    useEffect(() => {
        if (!qrcodeUrl) {
            return;
        }
        // clone node
        let box1 = document.getElementById('qrModal');
        // 第一份儿深克隆
        let cloneDom: any = box1?.cloneNode(true);
        // 排版，不显示在页面上（不能用display:none，会导致无法生成url）
        cloneDom.setAttribute('style', 'position: absolute;top: 0px; z-index: -1;');
        // 设置id（用于销毁，否则会导致页面重复生成table）
        cloneDom?.setAttribute('id', 'qrModal_clone');
        cloneDom?.lastChild.setAttribute('style', 'display: flex');
        document.getElementById('qr-wrap')?.append(cloneDom);
    }, [qrcodeUrl]);

    function saveQrcode() {
        if (!qrcodeUrl || onSaving) return;
        setOnSaving(true);
        setTimeout(() => {
            const dom = document.getElementById('qrModal_clone');
            if (dom) {
                html2Canvas(dom)
                    .then((url) => {
                        console.log('urlurlurl', url);
                        setOnSaving(false);
                        downLoadFile('我的二维码.png', url);
                    })
                    .catch(() => {
                        setOnSaving(false);
                        message.error('下载失败');
                    });
            } else {
                setOnSaving(false);
                message.error('下载失败');
            }
        }, 100);
    }
    const copyQrcode = debounce(function () {
        if (!qrcodeUrl || onSaving) return;
        setOnSaving(true);
        setTimeout(() => {
            const dom = document.getElementById('qrModal_clone');
            if (dom) {
                html2Canvas(dom)
                    .then((url) => {
                        setOnSaving(false);
                        let isOk = FocusSDK.clipboardImage(url);
                        isOk ? message.success('复制成功') : message.error('复制失败');
                    })
                    .catch(() => {
                        setOnSaving(false);
                        message.error('复制失败');
                    });
            } else {
                setOnSaving(false);
                message.error('复制失败');
            }
        }, 100);
    }, 500);

    const getImagePart = useCallback(() => {
        const headImgThumbUrl = getHeadImgThumbUrl(headImg || '', 150, 150);
        if (!headImgThumbUrl) {
            return (
                <div className="avatar">
                    <Avatar name={userName} styleObj={{ width: 33, height: 33 }} />
                </div>
            );
        } else {
            return (
                <div className="avatar">
                    <img
                        src={`${changeEgovUrl(headImgThumbUrl)}?time=${new Date().valueOf()}`}
                        crossOrigin="anonymous"
                        className="newWorkAvatar"
                    />

                    {/* <Avatar
                        name={userName}
                        src={changeEgovUrl(headImgThumbUrl)}
                        styleObj={{ width: 33, height: 33 }}
                    /> */}
                </div>
            );
        }
    }, [headImg, userName]);

    const contentNode = useCallback(() => {
        return (
            <div className={qrClass()} id="qrModal">
                <div className="header">
                    {getImagePart()}
                    <div className="name">{userName}</div>
                </div>
                <div className="content">
                    <img src={changeEgovUrl(qrcodeUrl)} alt={userName} crossOrigin="anonymous" />
                    <p style={{ marginTop: 10 }} className="tip">
                        请使用{APP_NAME}扫码
                    </p>
                </div>
                <div className="footer" id="footer">
                    <img src={ICON} className="icon" crossOrigin="anonymous" />
                    {/* <i className="icon" /> */}
                    <span className="name">{APP_NAME}</span>
                </div>
            </div>
        );
    }, [getImagePart, qrClass, qrcodeUrl, userName]);

    return (
        <Modal
            visible={visible}
            title="我的二维码"
            width={350}
            centered={true}
            okText="保存"
            cancelText="取消"
            onCancel={onCancel}
            footer={modalFooter()}
            wrapClassName="qr-modal"
        >
            <div className="qr-wrap" id="qr-wrap">
                {contentNode()}
            </div>
            {onSaving ? (
                <div className="saving-wrap">
                    <Loading />
                </div>
            ) : null}
        </Modal>
    );
}
export default connect(
    ({ chat }: { chat: ChatState }) => {
        return {
            currentEmployee: chat?.currentEmployee ? chat?.currentEmployee : {},
        };
    },
    (dispatch: any) => ({})
)(PersonalQRCode);
