import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { commentImg } from '@/assets/img';
import './index.less';
import InputComment from './InputComment';
import { saveTaskComment, findTaskComments, deleteTaskComment } from '@/api/work';
import { message } from 'antd';
import { TaskComment, TaskCommentType, WorkUserInfo, TaskModel } from '@/types/work';
import {
    isJSON,
    parseDeltaToHtml,
    findSelfInExecutors,
    findIndexSelfInExecutors,
    productBriefTimeStr,
} from '../utils';
import dayjs from 'dayjs';
import ShowComments from './ShowComments';
import { useTranslation } from 'react-i18next';
import prompt from '@/baseComponents/ModalComponent/prompt';
import IconFont from '@/components/icon';
import { connect } from 'dva';
import { Loading } from '@jd/focus-desktop-comps';

function decorateComment(comment: TaskComment) {
    if (!comment) {
        return;
    }
    if (Number(comment.type) !== 2) {
        // todo
    }
    if (Number(comment.type) === 2 || isJSON(comment.content)) {
        const ops = JSON.parse(comment.content);
        const temp = parseDeltaToHtml(ops);
        if (temp) {
            comment.content = temp;
        }
    }

    comment.createTime = productBriefTimeStr(comment.gmtCreate);
}

function Comment({
    userData,
    taskIdInModal,
    taskId,
    isHead,
    isExecutorsSelf,
    setCountOfComment = () => {},
}: {
    userData?: any;
    taskIdInModal: string;
    taskId?: string;
    isHead?: boolean;
    isExecutorsSelf?: boolean;
    setCountOfComment?: Function;
}) {
    const refAll = useRef(null);
    const [comments, setComments] = useState<
        { topComment: TaskComment; children: TaskComment[] }[]
    >([]);
    const [replyComment, setReplyComment] = useState<TaskComment | null>(null);
    const [count, setCount] = useState(0);
    const { t } = useTranslation('work');
    const [loading, setLoading] = useState(true);
    const [t_common] = useTranslation('common');
    function initComments(data: TaskComment[]) {
        setCount(data.length);
        setCountOfComment(data.length);
        const tmpComments: {
            [key: string]: { topComment: TaskComment; children: TaskComment[] };
        } = data.reduce(
            (pre: { [key: string]: { topComment: TaskComment; children: TaskComment[] } }, v) => {
                let tmp: any;
                // 对于不是delta格式的数据，处理一下
                if (Number(v.type) === 2 || isJSON(v.content)) {
                    const ops = JSON.parse(v.content);
                    const temp = parseDeltaToHtml(ops);
                    if (temp) {
                        v.content = temp;
                    }
                }
                // v.createTime = dayjs(v.gmtCreate).format('YYYY-MM-DD HH:mm');
                v.createTime = productBriefTimeStr(v.gmtCreate);
                if (!v.parentId) {
                    tmp = pre[v.commentId];
                    if (!tmp) {
                        tmp = { topComment: v, children: [] };
                        pre[v.commentId] = tmp;
                    } else {
                        tmp.topComment = v;
                    }
                } else {
                    tmp = pre[v.parentId];
                    if (tmp) {
                        tmp.children.push(v);
                    } else {
                        tmp = { children: [v] };
                        pre[v.parentId] = tmp;
                    }
                }
                return pre;
            },
            {}
        );
        let tmpShowArr = Object.values(tmpComments).map((x) => {
            let tmparr = x.children;
            tmparr = tmparr.sort((a, b) => {
                if (a.gmtCreate > b.gmtCreate) {
                    return 1;
                } else {
                    return -1;
                }
            });
            x.children = tmparr;
            return x;
        });
        tmpShowArr = tmpShowArr.filter((t) => t.topComment);
        const showComments = tmpShowArr.sort((a, b) => {
            if (a.topComment.gmtCreate > b.topComment.gmtCreate) {
                return 1;
            } else {
                return -1;
            }
        });
        setComments(showComments);
    }

    function onReply(item: TaskComment) {
        setReplyComment(item);
    }

    async function onDelete(item: TaskComment) {
        prompt({
            title: t('delete'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: async () => {
                const [result, err] = await deleteTaskComment({ commentId: item.commentId });
                if (err) {
                    message.error(err);
                    return false;
                }
                const index = comments.findIndex(
                    (comment) => comment.topComment.commentId === (item.parentId || item.commentId)
                );

                if (index === -1) {
                    return;
                }
                const block = comments[index];
                if (item.commentId === block.topComment.commentId) {
                    const remove = comments.splice(index, 1);
                    const length = 1 + remove[0].children.length;
                    setCount(count - length);
                    setCountOfComment(count - length);
                    return setComments([...comments]);
                }
                const { children } = block;
                const index1 = children.findIndex((c) => c.commentId === item.commentId);
                if (index1 === -1) {
                    return;
                }
                children.splice(index1, 1);
                const newComments = [...comments];
                newComments[index] = { ...block, children: [...children] };
                setComments(newComments);
                setCount(count - 1);
                setCountOfComment(count - 1);
            },
            content: t('delete comment info'),
            okText: t_common('button.ok'),
            cancelText: t_common('button.cancel'),
        });
    }
    const resetItem = (item: TaskComment) => {
        if (isHead) {
            item.isCommentDelete = true;
        } else {
            if (
                isExecutorsSelf &&
                item.userId + item.teamId + item.app ===
                    userData?.userId + userData?.team?.teamId + userData?.team?.ddAppId
            ) {
                item.isCommentDelete = true;
            } else {
                item.isCommentDelete = false;
            }
        }
        return item;
    };

    const resetRes = useCallback(
        () => {
            const list = refAll.current ? refAll.current : [];
            const taskCommentsList: any = list?.map((item: TaskComment) => {
                return resetItem(item);
            });
            // console.log(taskCommentsList, 'taskCommentsListtaskCommentsList');
            initComments(taskCommentsList || []);
        },
        [isHead, isExecutorsSelf, userData, comments, replyComment] //eslint-disable-line
    );
    useEffect(() => {
        setLoading(true);
        taskId &&
            findTaskComments({
                taskId,
            }).then(([result, err]) => {
                setLoading(false);
                if (err) {
                    return message.error(err);
                }
                if (!result?.taskComments?.length) {
                    return;
                }
                refAll.current = result.taskComments;
                resetRes();
            });
    }, [taskId]); //eslint-disable-line

    async function handleSend(content: string) {
        if (!taskId) {
            return false;
        }
        if (!content) {
            return false;
        }
        const opt: any = {
            taskId,
            content,
            type: TaskCommentType.Delta,
        };
        if (replyComment) {
            const { userId, teamId, app, parentId, commentId } = replyComment;
            opt.replyUserId = userId;
            opt.replyTeamId = teamId;
            opt.replyApp = app;
            opt.replyId = commentId;
            opt.parentId = parentId || commentId;
        }
        const [result, err] = await saveTaskComment(opt);
        if (err) {
            message.error(err);
            return false;
        }
        const { taskComments = [] } = result;
        const comment: TaskComment = taskComments[0];
        if (comment) {
            decorateComment(comment);
            resetItem(comment);
            if (!comment.parentId) {
                setComments([
                    ...comments,
                    {
                        topComment: comment,
                        children: [],
                    },
                ]);
            } else {
                const index = comments.findIndex(
                    (c) => c.topComment.commentId === comment.parentId
                );
                if (index > -1) {
                    const top = { ...comments[index] };
                    top.children.push(comment);
                    comments.splice(index, 1, top);
                }
                setComments([...comments]);
            }
        }
        setReplyComment(null);
        setCount(count + 1);
        setCountOfComment(count + 1);
        return true;
    }

    if (loading) {
        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="page-comments belong-to-work">
            {/* <div className="page-comments-header">
                {t('comment')}: {count}
            </div> */}
            {count ? (
                <ShowComments
                    list={comments}
                    onDelete={onDelete}
                    onReply={onReply}
                    // eslint-disable-next-line
                    onZan={function () {}}
                    replyComment={replyComment}
                    onSend={handleSend}
                />
            ) : (
                <div
                    className="noComment"
                    style={{
                        backgroundImage: `url(${commentImg})`,
                    }}
                >
                    暂无评论
                </div>
            )}
            {!replyComment && (
                <InputComment onSend={handleSend} onReply={onReply} key="common" directAt="task" />
            )}
        </div>
    );
}

function mapStateToProps({ work, user }: any) {
    const { detailState } = work;
    return {
        userData: user.userData.user,
        taskIdInModal: detailState.taskId,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Comment);
