// 左下角卡片提醒
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import './index.less';
import grouptz from '@/assets/img/grouptz.png';
import solittare from '@/assets/img/solitaire.png';
import { ChatMessage, Session } from '@/types/chat';
import { findGroupRelatedInfo } from '@/api/notification';
import { connect } from 'dvajs';
import IconFont from '@/components/icon';
import History from '@/components/chat/message/GroupNotification/components/history';
import { changeEgovUrl } from '@/utils/tools';
import bus from '@/utils/bus';
function GroupCard({
    message,
    openNoticeDetails,
    openSolitaireDetails,
    openSolitaireHis,
}: {
    message: Session;
    openNoticeDetails: (data: { isvisibles: boolean; notificationId: any }) => void;
    openSolitaireDetails: (data: { isvisibles: boolean; solitaire: any }) => void;
    openSolitaireHis: (data: { isvisibles: boolean; solitaireSid: any }) => void;
}) {
    const [noticeLists, setNoticeLists] = useState<any>([]);
    const [solitaire, setSolitaire] = useState<any>([]);
    const [visible, setvisible] = useState(false);
    const [GroupCardVisible, setGroupCardVisible] = useState(false);
    const handleOpen = (item?: any) => {
        if (noticeLists.length > 3) {
            setvisible(true);
            return;
        } else {
            openNoticeDetails({
                isvisibles: true,
                notificationId: item.bizId,
            });
        }
    };

    const handleSolitaireOpen = (item?: any) => {
        if (solitaire.length > 3) {
            openSolitaireHis({
                isvisibles: true,
                solitaireSid: message.sessionId,
            });
            return;
        } else {
            openSolitaireDetails({
                isvisibles: true,
                solitaire: item.bizId,
            });
        }
    };

    const handleClose = (e: any, Id?: any) => {
        e.stopPropagation();
        const bizId = Id;
        if (Id && bizId === Id) {
            setNoticeLists(
                noticeLists.filter((item: any) => {
                    return item.bizId !== Id;
                })
            );
            setSolitaire(
                solitaire.filter((item: any) => {
                    return item.bizId !== Id;
                })
            );
            return;
        }
        setGroupCardVisible(true);
    };
    const noticeList = useRef([]);

    const getNotices = () => {
        if (noticeLists.length > 3) {
            return (
                <div
                    className="GroupCard_box"
                    style={{ display: GroupCardVisible === true ? 'none' : 'flex' }}
                    onClick={handleOpen}
                >
                    <div style={{ display: 'flex' }}>
                        <img src={changeEgovUrl(grouptz)} alt="" />
                    </div>
                    <span className="text name ellipsis">组通知</span>
                    <span className="text">未回复</span>
                    <span className="text_num">{noticeLists.length}</span>
                    {/* <IconFont
                        type="iconwin_Close"
                        className="icon_Close"
                        onClick={(e) => {
                            handleClose(e);
                        }}
                    /> */}
                </div>
            );
        }
        return noticeLists.map((item: any, idx: number) => {
            return !item ? (
                <div />
            ) : (
                <div
                    className="GroupCard_box"
                    key={idx}
                    onClick={() => {
                        handleOpen(item);
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <img src={changeEgovUrl(grouptz)} alt="" />
                    </div>
                    <span className="text name ellipsis">{item.creatorName}</span>
                    <span className="text">{item.title}</span>
                    {/* <IconFont
                        type="iconwin_Close"
                        className="icon_Close"
                        onClick={(e) => {
                            handleClose(e, item.bizId);
                        }}
                    /> */}
                </div>
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const getSolitaire = () => {
        if (solitaire.length > 3) {
            return (
                <div
                    className="GroupCard_box"
                    style={{ display: GroupCardVisible === true ? 'none' : 'flex' }}
                    onClick={handleSolitaireOpen}
                >
                    <div style={{ display: 'flex' }}>
                        <img src={changeEgovUrl(solittare)} alt="" />
                    </div>
                    <span className="text name ellipsis">组接龙</span>
                    <span className="text">未回复</span>
                    <span className="text_num">{solitaire.length}</span>
                    {/* <IconFont
                        type="iconwin_Close"
                        className="icon_Close"
                        onClick={(e) => {
                            handleClose(e);
                        }}
                    /> */}
                </div>
            );
        }
        return solitaire.map((item: any, idx: number) => {
            return !item ? (
                <div />
            ) : (
                <div
                    className="GroupCard_box"
                    key={idx}
                    onClick={() => {
                        handleSolitaireOpen(item);
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <img src={changeEgovUrl(solittare)} alt="" />
                    </div>
                    <span className="text name ellipsis">{item.creatorName}</span>
                    <span className="text">{item.title}</span>
                    {/* <IconFont
                        type="iconwin_Close"
                        className="icon_Close"
                        onClick={(e) => {
                            handleClose(e, item.bizId);
                        }}
                    /> */}
                </div>
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const getUnReadNoticeList = useCallback(async (groupId: string) => {
        try {
            const [data] = await findGroupRelatedInfo({ groupId });
            if (data?.aggregationList) {
                noticeList.current = data?.aggregationList;
                setNoticeLists(
                    noticeList.current.filter((item: any) => {
                        return item.bizType === 'notification';
                    })
                );
                setSolitaire(
                    noticeList.current.filter((item: any) => {
                        return item.bizType !== 'notification';
                    })
                );
            }
        } catch (e) {
            console.log(`getUnReadNoticeList-err--`, e);
        }
    }, []);
    const closeBox = useCallback(
        (dateId: any) => {
            console.log('上方通知', noticeList.current, dateId);
            getUnReadNoticeList(message.sessionId);
            // setSolitaire(
            //     noticeList.current.filter((item: any) => {
            //         return item.bizType !== 'notification' && item.bizId !== dateId;
            //     })
            // );
        },
        [getUnReadNoticeList, message]
    );
    useEffect(() => {
        getUnReadNoticeList(message.sessionId);
        // bus.off('chat:message:GroupCard').on('chat:message:GroupCard', closeBox);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.sessionId, getUnReadNoticeList]);
    return noticeList.current.length > 0 ? (
        <div className="GroupCard_box_wrap">
            {getNotices()}
            {getSolitaire()}
            {visible && (
                <History
                    visible={visible}
                    onClose={() => {
                        setvisible(false);
                    }}
                    sessionId={message.sessionId}
                />
            )}
        </div>
    ) : null;
}
function mapDispatchToProps(dispatch: any) {
    return {
        openNoticeDetails(data: { isvisibles: boolean; notificationId: any }) {
            dispatch({ type: 'chat/openNoticeDetails', payload: data });
        },
        openSolitaireDetails(data: { isvisibles: boolean; solitaire: any }) {
            dispatch({ type: 'chat/openSolitaireDetails', payload: data });
        },
        openSolitaireHis(data: { isvisibles: boolean; solitaireSid: any }) {
            dispatch({ type: 'chat/openSolitaireHis', payload: data });
        },
    };
}
export default connect(undefined, mapDispatchToProps)(GroupCard);
