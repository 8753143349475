/**
 * 文本消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import './index.less';
import { ChatMessage } from '@/types/chat';
import { useTranslation } from 'react-i18next';

interface UnSupportProps {
    message: ChatMessage; // 发送消息内容
}

type IProps = Readonly<UnSupportProps>;

function UnSupport(props: IProps) {
    const { t } = useTranslation('chat');

    const classes = useMemo(() => {
        return classNames({
            message: true,
            'un-support-message': true,
        });
    }, []);

    return (
        <div className="content">
            <div className={classes}>
                <p>[{t('no_support_message_type')}]</p>
            </div>
        </div>
    );
}

export default UnSupport;
