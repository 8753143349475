/**
 * 聊天窗口，时间线
 */
import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { getMessageDateFormat } from '@/utils/date';
import { MessageDateFormType } from '@/types/chat/enum';
import './index.less';
import { useTranslation } from 'react-i18next';

interface TimeLineProps {
    timeStamp: number; // 时间戳
    text?: string;
}

type ITimeLineProps = Readonly<TimeLineProps>;

export default function (props: ITimeLineProps) {
    const { text, timeStamp } = props;
    const { t } = useTranslation('chat');
    const getContent = useCallback(() => {
        if (text) {
            return text;
        }
        return getMessageDateFormat(timeStamp, MessageDateFormType.DATE_SPLIT);
    }, [text, timeStamp]);

    const getClass = useCallback(() => {
        return classNames({
            'message-time-line': true,
            newest: !!text,
        });
    }, [text]);

    return (
        <div className={getClass()}>
            <div className="line left-line" />
            <div className="time">{getContent()}</div>
            <div className="line right-line" />
        </div>
    );
}
