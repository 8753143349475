import { GatewayProps } from '@/server/types';
import logger from '@/utils/logger';
import _ from 'lodash';
import { AuthData, RegistryConfigData } from '@/types/LocalConfig';
import { AuthInfo } from '@/utils';
import { addConfig as addUIConfig, Config } from '@/config/config';
import { AIConfig } from '@jd/jdee.im.sdk/lib/es/utils/types';

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const log = logger.getLogger('AppRuntimeEnv');

export interface GateWayConfig extends GatewayProps {
    protocol: string;
    host: string;
    secretKey: string;
}

export interface JBRTCConfig {
    meetApiUrl: string;
    rtcServerUrl: string;
}
export interface AppRuntimeConfig {
    debugProd: boolean;
    name: string;
    key: string;
    config: {
        appId: string;
        productName: string;
        ui: any;
        i18n: any;
        environments: AppRuntimeEnvConfig[];
        upgradeVersionInfo: string[];
    };
}

export interface AppRuntimeEnvConfig {
    key: string;
    name: string;
    app: {
        entry: string;
        feedUrl: string;
        feedUrl32: string;
        appStoreAppKey: string;
    };
    webConfig: {
        routeBase: string;
        ssoLoginUrl: string;
        ssoClientId: string;
    };
    gateway: GateWayConfig;
    me: {
        mainEntry: GateWayConfig;
    };
    fileService: {
        appKey: string;
        hostOrigin: string;
        joyspaceAppKey: string; // joyspace上传服务专用的key
    };
    im: {
        apHost: string;
        apPort: string;
        host: string;
        tls: boolean;
    };
    joyspace: {
        cookie: {
            domain: string;
        };
        lowCode: {
            createUrl: string;
        };
        wpsTarget: 'private' | 'public';
        canCreateSheet: false;
    } & GateWayConfig;
    open: {
        protocol: string;
        host: string;
    };
    ui: Partial<Config>;
    joyspaceApi: GateWayConfig;
    layoutClassNames: string[];
    zoom: {
        domain: string;
    };
    voip: {
        domain: string;
        port: string;
    };
    aiConfig: AIConfig;
    helpUrl?: string;
    jbrtc?: JBRTCConfig;
}

export interface UIConfig {
    [key: string]: any;
}

export interface I18NConfig {
    [key: string]: any;
}

export interface Options {
    theme: string;
    lang: string;
    env: string;
}

export default class AppRuntimeEnv {
    // 当前环境配置，指定的 env
    envConfig: AppRuntimeEnvConfig;

    // 运行环境配置 ducc 上的，全部的环境配置
    runtimeConfig: AppRuntimeConfig;

    // 桌面端本地配置
    registryConfig: RegistryConfigData;

    constructor(runtimeConfig: AppRuntimeConfig, registryConfig: RegistryConfigData) {
        console.log('-----', runtimeConfig);
        this.runtimeConfig = runtimeConfig;
        this.envConfig = runtimeConfig.config.environments.find(
            (item) => item.key === registryConfig.runtimeConfig.env
        ) as any;
        if (!this.envConfig) {
            this.envConfig = runtimeConfig.config.environments.find(
                (item) => item.key === 'prod'
            ) as any;
        }
        if (this.envConfig) {
            addUIConfig(this.envConfig.ui || {});
        }
        this.registryConfig = registryConfig;
    }

    get userDataForWeb() {
        const { registryConfig, selectedTeamId } = this;
        const { auth } = registryConfig;
        if (!auth) {
            return {};
        }
        const {
            accessToken,
            account,
            ddAppId,
            headImg,
            nickName,
            realName,
            userId,
            teamUserInfoList,
        } = auth;
        const team = teamUserInfoList.find((item) => item.teamId === selectedTeamId);
        return {
            accessToken,
            app: {
                appId: this.gatewayAppId,
                deviceId: registryConfig.machineId,
                client: this.platform,
            },
            user: {
                accessToken,
                account,
                ddAppId,
                headImg,
                nickName,
                realName,
                userId,
                team,
                teamUserInfo: team,
                teamUserInfoList,
                selectedTeamId,
            },
        };
    }

    get selectedTeamId() {
        if (this.platform === 'IPAD') {
            return this.registryConfig.auth?.teamUserInfo?.teamId || '';
        }
        return this.registryConfig.selectedTeamId;
    }

    setAuthInfo(data: AuthData) {
        this.registryConfig.auth = data;
        if (!this.registryConfig.selectedTeamId) {
            this.registryConfig.selectedTeamId = data.teamUserInfo?.teamId;
        }
    }

    mergeAuthData(data: AuthData) {
        this.registryConfig.auth = {
            ...this.registryConfig.auth,
            ...data,
        };
    }

    // 获取当前环境标识: jdcloud | jgswy | capcloud | mebj
    get tenantKey() {
        return this.runtimeConfig.key;
    }

    get platform(): 'WINDOWS' | 'MAC' | 'IPAD' {
        return this.registryConfig.client as any;
    }

    get refreshToken() {
        return this.registryConfig.auth?.refreshToken;
    }

    getI18N(key: string, vals?: { [key: string]: any }): any {
        if (!key) {
            return '';
        }
        const text = _.result(this.i18n, [this.registryConfig.lang, ...key.split('.')]) as any;

        if (!vals) {
            return text;
        }
        try {
            return _.template(text)({ ...vals });
        } catch (e) {
            log.error(`failed to getI18N for ${key}, values: ${JSON.stringify(vals)}`);
            return '';
        }
    }

    getUIConfig(key: string): any {
        if (!this.ui) {
            return '';
        }
        const keys = key.split('.');
        if (!keys.length) {
            return '';
        }
        return _.result(this.ui, keys);
    }

    get debugMode() {
        return this.runtimeConfig.debugProd;
    }

    get config() {
        return this.envConfig;
    }

    get theme() {
        return this.registryConfig.theme;
    }

    set theme(t) {
        this.registryConfig.theme = t;
    }

    get productName() {
        return this.runtimeConfig.config.productName;
    }

    get ui() {
        return this.runtimeConfig.config.ui;
    }

    get i18n() {
        return this.runtimeConfig.config.i18n;
    }

    get key() {
        return this.envConfig.key;
    }

    get name() {
        return this.envConfig.name;
    }

    get env() {
        return this.registryConfig.runtimeConfig.env;
    }

    get gateway() {
        return this.envConfig.gateway;
    }

    get gatewayAppId() {
        if (this.platform === 'IPAD') {
            return this.envConfig.gateway.webAppId;
        }
        return this.envConfig.gateway.appId;
    }

    get domain() {
        return this.envConfig.joyspace.cookie.domain;
    }

    get imHost() {
        const { host, apHost } = this.envConfig.im;
        if (this.platform === 'IPAD') {
            return `wss://${host}`;
        }
        return `wss://${apHost}`;
    }

    get imTls() {
        return this.envConfig.im.tls;
    }

    get gatewayHost() {
        const { host, protocol } = this.envConfig.gateway;
        return `${protocol}://${host}`;
    }

    get joyspaceHost() {
        const { host, protocol } = this.envConfig.joyspace;
        return `${protocol}://${host}`;
    }

    get joyspaceLowCode(): { createUrl: string } {
        const { lowCode } = this.envConfig.joyspace;
        if (!lowCode) {
            return { createUrl: '' };
        }
        return lowCode;
    }

    get joyspaceWpsTarget() {
        const { wpsTarget = 'public' } = this.envConfig.joyspace;
        return wpsTarget;
    }

    get fileService() {
        return this.envConfig.fileService || { appKey: '', hostOrigin: '', joyspaceAppKey: '' };
    }

    get joyspaceApiHost() {
        const { host, protocol } = this.envConfig.joyspaceApi;
        return `${protocol}://${host}`;
    }

    get canCreateSheet() {
        return this.envConfig.joyspace.canCreateSheet;
    }

    get meMainEntry() {
        const { host, protocol } = this.envConfig.me.mainEntry;
        return `${protocol}://${host}`;
    }

    get webConfig() {
        return this.envConfig.webConfig;
    }

    get jrtcConfig() {
        return this.envConfig.jbrtc || null;
    }

    loginEntry() {
        const { routeBase, ssoLoginUrl, ssoClientId } = this.envConfig.webConfig;
        return `${ssoLoginUrl}?ReturnUrl=${encodeURIComponent(
            location.origin + routeBase
        )}&clientId=${ssoClientId}`;
    }

    get webEntry() {
        return this.envConfig.app.entry;
    }

    get zoom() {
        return this.envConfig.zoom;
    }
    get voip() {
        return this.envConfig.voip;
    }

    get aiConfig() {
        return this.envConfig.aiConfig;
    }
}
