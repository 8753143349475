import React from 'react';
import { message, Modal } from 'antd';
import events from '../../context/events';
import { removeFromHomePage, removeFromRecent } from '../../api/pages';
import { remove } from '../../api/shortcuts';
import { remove as removeCollections } from '../../api/collections';
import { FileType } from '../../types';

const openDeleteModal = (type: any, id = '', delType = '') => {
    const str = type === FileType.Page ? '文件' : '文件夹';
    Modal.confirm({
        title: `该${str}已被删除，是否从列表移除?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
            let result;
            let path = window.location.href;
            console.log(path, 'pathpathpathpath');
            if (delType === 'shortcuts') {
                result = await remove(id, type);
            } else if (path.indexOf('home/recent') !== -1) {
                result = await removeFromRecent(id);
            } else if (path.indexOf('home/myshare') !== -1 || path.indexOf('home/shares') !== -1) {
                const _type = path.indexOf('home/myshare') !== -1 ? 1 : 2;
                result = await removeFromHomePage(id, _type);
            } else if (
                path.indexOf('collections/document') !== -1 ||
                path.indexOf('collections/space') !== -1
            ) {
                result = await removeCollections(id, type);
            }
            if (result) {
                events.emit('list.reload');
                events.emit('shortcut.change');
                return;
            }
            message.error('操作失败');
        },
        onCancel() {},
    });
};

export default openDeleteModal;
