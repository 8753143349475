import { message } from 'antd';
import { gatewayInstance } from '../server/gateway';
import { IPermissions } from '../types/common';
/**
 *
 * 用户订阅
 * @param {Schedule} schedule
 * @returns
 */
export async function getUserProfile(body: any = {}) {
    return gatewayInstance.request({
        api: 'login.getUserProfile',
        method: 'POST',
        // @ts-ignore
        body,
    });
}

export async function getMapAccountList(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'login.getMapAccountList',
            method: 'POST',
            // @ts-ignore
            body,
        })
        .then((res) => res)
        .catch((err) => {
            message.error(err.message);
        });
}

// 获取能否创建专班的权限
export function getPermission() {
    return gatewayInstance
        .request<IPermissions[]>({
            api: 'abacAuthService.getAuthoritiesAndResources',
            // api: 'authz.getAuthoritiesAndResources',
            method: 'POST',
            body: {},
        })
        .then((res) => res)
        .catch(() => []);
}

// 是否是团队管理员
export function getTeamAdmin() {
    return gatewayInstance
        .request({
            api: 'authz.determineAdministrator',
            method: 'POST',
            body: {},
        })
        .then((res: any) => {
            return { result: res.result };
        })
        .catch(() => ({
            result: [],
        }));
}

export async function updatePwdReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.setPassword',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}

export async function updateBindMobileReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.updateBindMobile',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}

export async function updateBindEmailReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.updateBindEmail',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}
export async function bindEmailReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.bindEmail',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}

export async function verifyCodeReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.verifyCode',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}

export async function unbindEmailReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.unBindEmail',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
            throw err;
        });
}

export async function verifyPwdReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.verifyPassword',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}

export async function changeAvatarReq(body: any = {}) {
    return gatewayInstance
        .request({
            api: 'focus.changeAvatar',
            method: 'POST',
            body,
        })
        .catch((err) => {
            message.error(err.message);
        });
}

export async function getAuthorizationCode(opts: any): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'eopen.getCode',
            method: 'POST',
            body: {
                ...opts,
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
