import {
    UserSelectionOptions,
    UserSelectionResult,
} from '@jd/focus-desktop-comps/lib/es/UserSelectionModal';
import i18next from 'i18next';
// import { FocusSDK, isFocusEnv } from '@/utils';
import { Modals } from '@jd/focus-desktop-comps';
import {
    BusinessTabParam,
    RecommendParam,
    SearchType,
} from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { getEbookDeptInfo } from '@/api/ebook';
import { searchExternalContacts, searchGlobal } from '@/api/search';
import {
    getBusinessData1,
    getExternalContacts,
    getGroupData,
    getRecentChatSession,
    getTopChatSession,
    onGetRecommendInfo,
    getGroupMembersData,
    getRecentIncludeGroup,
} from '@/api/chat';
import { getHeadImgThumbUrl } from '@/utils/chat/index';

export default function openAddMembersModal(
    options: UserSelectionOptions,
    cb: (data: UserSelectionResult, close: () => void) => void,
    selectedTeamId: string
) {
    const { destroy } = Modals.UserSelecton(
        options,
        (result: any) => {
            return new Promise((resolve) => {
                cb(result, () => {
                    resolve();
                    destroy();
                });
            });
        },
        {
            t: (...args: any[]) => {
                // @ts-ignore
                return i18next.t(...args);
            },
            onGetAvatarURL: (url) => {
                return getHeadImgThumbUrl(url, 150, 150);
            },
            onGetEBookDeptInfo: async (params) => {
                let result = null;
                try {
                    result = await getEbookDeptInfo(params);
                } catch (e) {
                    // if (e.message.indexOf('10230004')) {}
                    result = {};
                }
                return result as any;
            },
            onSearchGlobal: async (params) => {
                const result = await searchGlobal(params);
                if (params.searchType === SearchType.Contacts) {
                    const externalContacts = await searchExternalContacts(params);
                    result.contacts.unshift(...externalContacts);
                }
                return result as any;
            },
            onGetRecentData: () => getRecentChatSession(selectedTeamId),
            onGetRecentIncludeGroup: () => getRecentIncludeGroup(),
            onGetTopData: () => getTopChatSession(selectedTeamId),
            onGetExternalContacts: () => getExternalContacts(),
            onGetGroupData: () => getGroupData(),
            onGetGroupMembersData: (groupId: string) => getGroupMembersData(groupId),
            onGetBusinessData: (param: BusinessTabParam) =>
                getBusinessData1(
                    param,
                    options.currentUser || ({ pin: '', app: '', teamId: '' } as any)
                ),
            onGetRecommendInfo: (param: RecommendParam) =>
                onGetRecommendInfo(
                    param,
                    options.currentUser || ({ pin: '', app: '', teamId: '' } as any)
                ),
        }
    );
}

// export default function openAddMembersModal(
//     options: UserSelectionOptions,
//     cb: (data: UserSelectionResult, close: () => void) => void,
//     selectedTeamId: string
// ) {
//     if (isFocusEnv()) {
//         FocusSDK.userSelection(options, (data: UserSelectionResult, close: () => void) => {
//             cb(data, close);
//         });
//     } else {
//         const { destroy } = Modals.UserSelecton(
//             options,
//             (result: any) => {
//                 return new Promise((resolve) => {
//                     cb(result, () => {
//                         resolve();
//                         destroy();
//                     });
//                 });
//             },
//             {
//                 t: (...args: any[]) => {
//                     // @ts-ignore
//                     return i18next.t(...args);
//                 },
//                 onGetAvatarURL: (url) => {
//                     return getHeadImgThumbUrl(url, 150, 150);
//                 },
//                 onGetEBookDeptInfo: async (params) => {
//                     let result = null;
//                     try {
//                         result = await getEbookDeptInfo(params);
//                     } catch (e) {
//                         // if (e.message.indexOf('10230004')) {}
//                         result = {};
//                     }
//                     return result as any;
//                 },
//                 onSearchGlobal: async (params) => {
//                     const result = await searchGlobal(params);
//                     if (params.searchType === SearchType.Contacts) {
//                         const externalContacts = await searchExternalContacts(params);
//                         result.contacts.unshift(...externalContacts);
//                     }
//                     return result as any;
//                 },
//                 onGetRecentData: () => getRecentChatSession(selectedTeamId),
//                 onGetTopData: () => getTopChatSession(selectedTeamId),
//                 onGetExternalContacts: () => getExternalContacts(),
//                 onGetGroupData: () => getGroupData(),
//             }
//         );
//     }
// }
