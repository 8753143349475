import { IPageAddCallback, api } from './index';
import { message } from 'antd';
import { lang } from '../context';
import { PageType } from '../const';
import events from '../context/events';
const clone: IPageAddCallback = (props, isAllow) => {
    const { data, reload } = props;

    // if (!data.perms?.PAGE_CREATE) {
    //     return;
    // }
    return [
        {
            children: lang('ctx.clone.title'),
            icon: 'pc_yundoc_fuben',
            splitCode: 2,
            callbackOrModal: () => {
                let newTitle = data.title
                    ? data.title + lang('ctx.clone.dialog.title')
                    : '无标题' + lang('ctx.clone.dialog.title');
                try {
                    if (data.page_type === PageType.File) {
                        const strs = data.title.split('.');
                        if (strs.length >= 2) {
                            const last = strs[strs.length - 1];
                            strs[strs.length - 1] = lang('ctx.clone.dialog.title') + '.' + last;
                            newTitle = strs.join('');
                        }
                    }
                } catch (error) {}

                api.page.clone(data.id, newTitle).then((data) => {
                    if (data) {
                        events.emit('list.reload');
                        message.success(lang('common.success'));
                    } else {
                        message.error(lang('common.fail'));
                    }
                });
            },
            isAllow,
            type: 'clone',
            tip: '在当前位置创建一个副本文件，内容与原件一致，但两份文件互相独立。',
        },
    ];
};

export default clone;
