import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import ReactDOM from 'react-dom';
import { FocusSDK } from '@/utils';
export function getServiceAndPrivacyLinks(appRuntimeEnv: AppRuntimeEnv) {
    const links = appRuntimeEnv.getUIConfig('links') || {};
    const { service = '', privacy = '', downloadUrl = '' } = links;
    let currentHost = FocusSDK.getMeMainEntry();
    let currentDownUrl = `${currentHost}/download`;

    if (!service || !privacy || downloadUrl) {
        return {
            service: 'https://me.jdcloud.com/protocol/service/chinese/',
            privacy: 'https://me.jdcloud.com/protocol/privacy/chinese/',
            downloadUrl: currentDownUrl,
            // downloadUrl: 'https://me-pre.jdcloud.com/download',
        };
    }
    return { service, privacy, downloadUrl };
}

interface WatermarkConfig {
    left?: number; // 左侧留白
    top?: number; // 顶部留白
    color?: string;
    opacity?: number;
    font?: string;
    fontSize?: number;
}
const defaultWatermarkConfigConfig = {
    id: 'app_wm_el',
    left: 50,
    top: 50,
    color: '#8A8A8A',
    opacity: 0.08,
    font: '微软雅黑',
    fontSize: 16,
};
let watermarkIndex = 0;

export function getWatermark(watermarkConfig: WatermarkConfig = defaultWatermarkConfigConfig) {
    const cfg = {
        ...defaultWatermarkConfigConfig,
        ...watermarkConfig,
        ...{ id: `${defaultWatermarkConfigConfig.id}_${watermarkIndex++}` },
    };

    return {
        setWaterMark(text: Array<string>) {
            let markEl = document.getElementById(cfg.id!);
            if (markEl !== null) {
                document.body.removeChild(markEl);
            }
            let canvasEl = document.createElement('canvas');
            // canvasEl.style.position = 'fixed';
            // canvasEl.style.zIndex = '1000001';
            // canvasEl.style.background = 'red';
            let context = canvasEl.getContext('2d');
            if (context) {
                context.font = `${cfg.fontSize}px ${cfg.font}`;
                const contentWidth = Math.max(
                    context.measureText(text[0]).width,
                    context.measureText(text[text.length - 1]).width
                );
                // canvas要以文字中心旋转
                const [x, y] = [contentWidth / 2 + cfg.left, cfg.fontSize + cfg.top];
                canvasEl.width = cfg.left * 2 + contentWidth;
                canvasEl.height = cfg.top * 2 + cfg.fontSize * text.length;
                context.translate(x, y);
                context.rotate(-(30 * Math.PI) / 180);
                context.translate(-x, -y);
                context.font = `${cfg.fontSize}px ${cfg.font}`;
                context.fillStyle = cfg.color;
                // 文字左顶点为基准点
                context.textAlign = 'left';
                context.textBaseline = 'top';
                for (let i = 0; i < text.length; i++) {
                    const str = text[i];
                    context.fillText(
                        str,
                        cfg.left,
                        cfg.top - (cfg.fontSize * text.length) / 2 + (cfg.fontSize + 6) * i
                    );
                }
                let div = document.createElement('div');
                div.id = cfg.id;
                div.style.pointerEvents = 'none';
                div.style.top = '40px';
                div.style.left = '0px';
                div.style.opacity = String(cfg.opacity);
                div.style.position = 'fixed';
                div.style.zIndex = '100000';
                // 使用估算最大值，免得处理窗口在Desktop间移动、Resize等变化
                div.style.width = '5000px';
                div.style.height = '5000px';
                div.style.background =
                    'url(' + canvasEl.toDataURL('image/png') + ') left top repeat';
                document.body.appendChild(div);
            }
        },
        removeWatermark() {
            let markEl = document.getElementById(cfg.id);
            if (!markEl || !ReactDOM.unmountComponentAtNode(markEl)) {
                return;
            }
            if (markEl.parentNode !== null) {
                markEl.parentNode.removeChild(markEl);
            }
        },
    };
}
