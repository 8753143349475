import React, { useState, useRef, useContext } from 'react';
import { Dropdown, Modal, message as toast } from 'antd';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';
import Context from '../../context';
import { getThumbUrl } from '@/utils/chat/index';
import './GroupNoticeItem.less';
import bus from '@/utils/bus';
import arrowIconBottom from '@/assets/img/calendar/bottom-line.png';
import { changeEgovUrl } from '@/utils/tools';
import CopyClipboard from 'copy-to-clipboard';

export default function GroupNoticeItem(props: any) {
    const { item, t, level } = props;
    const unReadNum = (item.userNum || 0) - (item.ackSize || 0); // 未读条数
    const text = unReadNum ? '未读' : '全部已读';
    const [isCollapse, setCollapse] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    // 复制  cxz
    function doCopy(e: any) {
        e.stopPropagation();
        if (item.content) {
            let userSelection;
            let str = item.content;
            if (typeof window.getSelection === 'function') {
                userSelection = window.getSelection();
            }
            if (userSelection?.toString()) {
                str = userSelection.toString();
            }
            if ((str || '').length > (item.content || '').length) {
                str = item.content;
            }
            if (str && CopyClipboard(str)) {
                toast.success('复制成功');
            } else {
                toast.error('复制失败');
            }
        }
    }
    return (
        <div className="item newItem">
            <div className="header">
                <Avatar
                    src={changeEgovUrl(getThumbUrl(item.senderUserAvatar, 26, 26))}
                    styleObj={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                    }}
                    name={item.senderUserName}
                />
                <div className="title_name">
                    <span className="ellipsis">{item.senderUserName}</span>
                    <span>更新于{item.pubTime}</span>
                </div>
                {/* {(level === 1 || level === 2) && (
                    <IconFont
                        type="icondelect"
                        onClick={(e) => {
                            e.stopPropagation();
                            Modal.confirm({
                                title: t('detail.announce.remove_tip'),
                                content: t('detail.announce.remove_confim'),
                                okText: t('remove'),
                                cancelText: t('cancel'),
                                onOk() {
                                    bus.emit('removeGroupNotice:updata', item.announcementId);
                                },
                            });
                        }}
                    />
                )}
                <IconFont
                    type="iconapp_btn_copy"
                    onClick={(e) => {
                        doCopy(e);
                    }}
                /> */}
                <span className="acksize-right">
                    {unReadNum}/{item.userNum || 0} {text}
                </span>
            </div>
            <Dropdown
                overlay={() => {
                    return (
                        <div
                            className="paste-space"
                            onClick={(e) => {
                                doCopy(e);
                            }}
                        >
                            复制
                        </div>
                    );
                }}
                trigger={['contextMenu']}
            >
                {/* <div className={isCollapse ? 'content content-h-auto' : 'content content-h-80'}>
                    <p>{item.content}</p>
                </div> */}
                <div className="content content-h-80">
                    <p>{item.content}</p>
                </div>
            </Dropdown>
            <div
                className="operation"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {(level === 1 || level === 2) && (
                    <div
                        style={{ marginRight: '24px' }}
                        onClick={(e) => {
                            e.stopPropagation();

                            bus.emit('editGroupNotice:updata', {
                                content: item?.content || '',
                                announcementId: item?.announcementId || '',
                            });
                        }}
                    >
                        <IconFont type="iconjs_ic_edit" className="pm-announce-small-icon" />
                        编辑
                    </div>
                )}

                <div
                    onClick={(e) => {
                        doCopy(e);
                    }}
                >
                    <IconFont type="iconapp_btn_copy" />
                    复制
                </div>
                {(level === 1 || level === 2) && (
                    <div
                        style={{ color: '#ff5e27', marginLeft: '24px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            showModal();
                            // Modal.confirm({
                            //     title: t('detail.announce.remove_tip'),
                            //     content: t('detail.announce.remove_confim'),
                            //     okText: t('remove'),
                            //     cancelText: t('cancel'),
                            //     onOk() {
                            //         bus.emit('removeGroupNotice:updata', item.announcementId);
                            //     },
                            // });
                        }}
                    >
                        <IconFont type="icondelect" />
                        删除
                    </div>
                )}
            </div>
            {/* <div
                className="collapse"
                onClick={(e: any) => {
                    e.stopPropagation();
                    setCollapse(!isCollapse);
                }}
            >
                <img
                    style={{
                        width: '12px',
                        transform: isCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    src={changeEgovUrl(arrowIconBottom)}
                />
            </div> */}
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    return;
                }}
            >
                <Modal
                    title="提醒"
                    className="removeModal"
                    width={400}
                    visible={isModalVisible}
                    onOk={(e) => {
                        e.stopPropagation();
                        bus.emit('removeGroupNotice:updata', item.announcementId);
                        setIsModalVisible(false);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                        setIsModalVisible(false);
                    }}
                    okText="确定"
                    cancelText={t('cancel')}
                    maskClosable={true}
                    centered
                >
                    <p style={{ textAlign: 'center' }}>确认删除当前公告吗？</p>
                </Modal>
            </div>
        </div>
    );
}
