import { LabelInfoRes } from '@/components/AddressBook/common';
import { labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';

export interface UserCardItem {
    val: any;
    hide: 0 | 1;
    name: string;
    index: number;
    key: string;
    cardNumber?: string;
}

export enum UserCardType {
    User = 'user',
    Group = 'group',
}

export interface UserCardData {
    type: UserCardType;
    appId: string;
    userId: string;
    teamId: string;
    rootOrgId?: string;
    fields: UserCardItem[];
    intro: any;
    mainTitleDeptInfo?: TitleDeptInfo;
    partTimeTitleDeptInfo?: TitleDeptInfo[];
    validationMsg: any;
    workStatus: any;
    isMulti?: boolean;
    labelInfoList?: labelInfoList[];
    currentUserLabelInfoList?: LabelInfoRes[];
    terminalId?: string;
    memberType?: number;
    accessPhone?: number;
}

export interface userDetailListPrams {
    appId: string;
    userId: string;
    teamId: string;
    isMulti?: boolean;
}

export enum UserCardAction {
    Chat = 'chat', // 聊天
    AddGroup = 'addGroup', // 申请加群
    AppliedGroup = 'appliedGroup', // 已申请加群
    JoinGroup = 'JoinGroup', // 加入群聊
    SendApplyGroup = 'sendApplyGroup', // 发送加群
    More_Times_Group = 'more_times_group', // 超次数限制
    Add = 'add', // 添加好友
    Send = 'send', // 发送验证消息
    Applied = 'applied', // 已申请
    Done = 'done', // 已添加
    Accept = 'accept', // 接受 通过验证
    More_Times = 'more_times', // 超次数限制
    Voip = 'voip', // voip通话
    // Voip = 'Meeting', // voip通话
    Jrtc = 'jrtc', // 京东视频通话
    Meeting = 'Meeting', // Meeting视频
    XCMeeting = 'XCMeeting', // 信创视频会议
    XYMeeting = 'XYMeeting', // 小鱼视频会议
    Share = 'share', // voip通话
    No = 'no', // 无任何操作
    Expired = 'Expired', // 3天未申请通过
    Deleted = 'Deleted', // 删除好友 设置手机号可见性
}
export type ModifySettingFn = (params: {
    settingCode: string;
    settingContent: string;
}) => Promise<void>;

export interface TitleDeptInfo {
    deptId: string;
    unitName: string;
    deptName: string;
    fullDeptName: string;
    titleId: string;
    titleName: string;
    titleDutyName: string;
    major: true;
    unitIsClick?: boolean;
    deptIsClick?: boolean;
    unitDeptId?: string;
    telephone?: string;
}

export default {};
