import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Avatar from '../Avatar';
import './index.less';
import { getJoySpaceReadAllPermission } from '@/components/Joyspace/config/permissions.config';
import { TabKeyType } from '../../config';
import PermissionSelect from '@/components/Joyspace/components/PermissionSelect';
import { SharesMembersType } from '@/components/Joyspace/types';

// 人员标签属性展示 （未认证、外部、单位领导、管理员等）
export function tagsNode(labelInfoList: any[]) {
    return labelInfoList?.map((label: any, key: number) => {
        return label.isShow === '1' && label.labelEnum === 'EXTERNAL' ? (
            <span
                key={key}
                style={{
                    color: `#${label.color}`,
                    marginRight: '5px',
                    fontSize: 10,
                    padding: '0 4px',
                    border: `.5px solid #${label.color}`,
                    borderRadius: '2px',
                }}
            >
                {label.name}
            </span>
        ) : null;
    });
}

export default function Item(props: any) {
    const { permission, fileType, handleMenuClick, owner = false } = props;
    // console.log(permission, 'propspropspropsprops');

    return (
        <div className="joyspace-tab-item">
            <div className="tab-item-left">
                <Avatar
                    avatar={props.avatar || props.avatarUrl}
                    name={props.name}
                    type={props.memberType}
                />
                <div className="tab-item-info">
                    <div className="tab-item-box">
                        <p className="tab-item-title">{props.name}</p>
                        {tagsNode(props.labelInfoList)}
                    </div>
                    <p className="tab-item-desc">{props.desc}</p>
                </div>
            </div>
            <div className="tab-item-right">
                <PermissionSelect
                    onChange={(values: any) => {
                        handleMenuClick?.(values, props.id);
                    }}
                    isOnlyText={
                        permission > props.permissionType ||
                        (props.permissionType === getJoySpaceReadAllPermission() &&
                            props.isLoginUser &&
                            props.memberType === SharesMembersType.CONTACT)
                    }
                    type={fileType}
                    permissionType={permission}
                    propsValue={props.permissionType}
                    isCheckAll={false}
                    isDisableAll={
                        props.memberType === SharesMembersType.DEPARTMENT ||
                        props.memberType === SharesMembersType.GROUP
                    }
                    isShowDelMenu={true}
                />
            </div>
        </div>
    );
}
