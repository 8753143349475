import React, { useEffect, useMemo } from 'react';

import { Button, Checkbox, Modal } from 'antd';
import './index.less';
import { CloseIcon } from '@/assets/img';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';
interface OwnProps {
    title: string;
    toolTip?: string;
    visible: boolean;
    isKeyboard?: boolean;
    size?: number;
    isImage?: boolean; // 发送图片modal
    isOrigin?: boolean; // 选择原图按钮
    rows?: any;
    onCancel: () => void;
    onClick: () => void;
    onKeyDown?: (e: any) => void;
    onChange?: (e: any) => void;
    children: any;
    footerLine?: boolean;
    okText?: string;
    selfClass?: string;
    disabled?: boolean;
}

export default function MyModal({
    title,
    toolTip,
    visible,
    isKeyboard,
    size,
    isImage,
    isOrigin,
    rows,
    onCancel,
    onClick,
    onKeyDown,
    onChange,
    children,
    footerLine = true,
    okText,
    selfClass,
    disabled,
}: OwnProps) {
    const [t] = useTranslation('common');

    useEffect(() => {
        if (!onKeyDown) {
            return;
        }
        if (visible && isKeyboard) {
            document.addEventListener('keydown', onKeyDown);
            return () => {
                document.removeEventListener('keydown', onKeyDown);
            };
        }
    }, [visible, isKeyboard, onKeyDown]);

    const isDefaultOrigin = useMemo(() => {
        if (isImage) {
            if (size) {
                return size < 1024 * 1024;
            }
            if (rows) {
                const isOutofSize = rows.some((item: any) => item.file.size >= 1024 * 1024);
                return !isOutofSize;
            }
            return false;
        }
    }, [rows, size, isImage]);

    const handleOk = useDebounce(onClick, 450);

    function getFooter() {
        return (
            <div className={`my-modal-footer ${isImage ? 'my-modal-footer_image' : ''}`}>
                {isImage && (
                    <div>
                        <Checkbox checked={isOrigin} disabled={isDefaultOrigin} onChange={onChange}>
                            原图
                        </Checkbox>
                        <span className="tips">1M以下图片默认以原图发送</span>
                    </div>
                )}
                <div>
                    <Button
                        // className="my-modal-footer-cancel"
                        onClick={onCancel}
                    >
                        {t('button.cancel')}
                    </Button>
                    <Button
                        // className="my-modal-footer-ok"
                        type="primary"
                        disabled={!visible || disabled}
                        onClick={handleOk}
                    >
                        {okText || t('button.send')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Modal
            className={`my-modal ${selfClass} ${footerLine ? '' : 'my-modal-hidden-footerline'}`}
            title={
                <>
                    <div className="my-modal-header-title">
                        <span>{title}</span>
                        {toolTip && <span className="tooltip">{toolTip}</span>}
                    </div>
                    <span style={{ flex: 1 }} />
                    <img
                        className="my-modal-header-close"
                        src={changeEgovUrl(CloseIcon)}
                        onClick={onCancel}
                        draggable="false"
                    />
                </>
            }
            visible={visible}
            footer={getFooter()}
            closable={false}
        >
            {children}
        </Modal>
    );
}
