import { replaceGovUrl } from '@/utils/joyspace';
import { processJoyspaceUrl } from '@/components/Joyspace/winOpen';
import bus from '@/utils/bus';
import { analysisLog } from '@/utils/logAnalytics';
import { AuthData } from '@/types/LocalConfig';
import { isFocusEnv } from '@/utils';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';

const genThirdAppUrl = (
    applicationKey: string,
    appId: string,
    accessToken: string,
    appRuntime: AppRuntimeEnv,
    options?: {
        redirectUrl?: string;
    }
    // eslint-disable-next-line max-params
) => {
    const s = new URL(`${appRuntime.meMainEntry}/passport/api/desktop/app/redirect/`);
    s.searchParams.append('applicationKey', applicationKey);
    s.searchParams.append('appKey', appId);
    s.searchParams.append('client', 'web');
    s.searchParams.append('accessToken', accessToken);
    s.searchParams.append('teamId', appRuntime.selectedTeamId);
    if (options?.redirectUrl) {
        s.searchParams.append('redirectUrl', options.redirectUrl);
    }
    return s.toString();
};

const getThirdAppUrl = (applicationKey: string, config: AuthData, appRuntime: AppRuntimeEnv) => {
    return genThirdAppUrl(applicationKey, config.ddAppId, config.accessToken, appRuntime);
};

// eslint-disable-next-line max-params
export function handleLinkClick(
    url: string,
    message?: any,
    config?: AuthData,
    appRuntime?: AppRuntimeEnv
) {
    // let appPin: string = message?.from.pin.replace('eopen-push_', '');
    if (!url) {
        return;
    }
    analysisLog('xtbg_workbench_1', '1', message?.from.pin, message?.extend.applicationName);
    // if (message.nickName === '公文管理') {
    //     setTimeout(() => {
    //         bus.emit('openThirdApp', {
    //             homeUrl: replaceGovUrl(url),
    //             name: message?.extend.applicationName,
    //             csTelephone: '',
    //             omCompany: '北京办公自动化-公文管理',
    //             applicationKey: appPin,
    //             trustedDomains: '',
    //             version: '2.0.0',
    //             webviewKernel: 6,
    //         });
    //     }, 500);
    //     return false;
    // }
    let realUrl = replaceGovUrl(url);
    realUrl = processJoyspaceUrl(realUrl);
    const a = document.createElement('a');
    let applicationKey = message?.infox?.applicationKey;
    if (applicationKey && !isFocusEnv()) {
        realUrl = getThirdAppUrl(
            applicationKey,
            config || ({} as AuthData), // eslint-disable-line
            appRuntime || ({} as AppRuntimeEnv) // eslint-disable-line
        );
    }
    console.log('realUrl', realUrl, applicationKey, message);
    a.href = realUrl;
    a.setAttribute('target', '_blank');
    a.click();
}
