import axios from 'axios';
import { gatewayInstance } from '@/server/gateway';
import { GatewayRequestOptions } from '@/server/types';
import { isFocusEnv, FocusSDK } from '@/utils';
import cache from '@/utils/cache';
export interface RequestOptions extends Partial<Pick<GatewayRequestOptions, 'method' | 'body'>> {
    api: string;
    path?: string;
    params?: { [key: string]: any };
}

export const request = <T = any>(options: RequestOptions) => {
    let janusBaseUrl = gatewayInstance.getBaseUrl();
    return gatewayInstance
        .request<T>({
            api: options.api,
            method: options.method ?? 'GET',
            dynamicPath: options.path,
            params: options.params,
            body: options.body,
            headers: {
                platform: isFocusEnv() ? cache?.token?.token?.focusClient || 'web' : 'web', // todo joyspace api请求 暂时定为web 网关请求headers[x-client]对应的值
                'x-host': janusBaseUrl,
            },
        })
        .catch((e) => {})
        .then((data) => {
            return data as T;
        });
};
