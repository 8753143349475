/* eslint-disable max-nested-callbacks */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import MyModal from '@/components/MyConfirm';
import './index.less';
import { useTranslation } from 'react-i18next';
import { dealImageWidthHeight2, sizeToStr } from '@/utils/chat/index';
import { message as toast, Table, Tooltip } from 'antd';
import { dealImagesData, formatFileType, imageMin } from '@/components/chat/utils/image';
import { nanoid } from 'nanoid';
import { nyrssfENStr } from '@/utils/date';
import { changeEgovUrl } from '@/utils/tools';
import { sendMessageByKeyboard } from '@/components/chat/utils/message';

const LIMIT_SIZE = 5;
export function UpLoadImg({
    isMulti,
    base64,
    title,
    size,
    width,
    height,
    file,
    fileList,
    visible,
    onCancel,
    onClick,
}: {
    isMulti: boolean;
    base64: any;
    title?: any;
    size?: number;
    width: number;
    height: number;
    file: any;
    fileList: any[];
    visible: boolean;
    onCancel: () => void;
    onClick: (list?: any, withParam?: boolean, params?: any) => void;
}) {
    const [isOrigin, setIsOrigin] = useState(false);

    const [t] = useTranslation('chat');
    const box = useMemo(() => {
        if (!width || !height) {
            return [0, 0];
        }
        return dealImageWidthHeight2([width, height]);
    }, [width, height]);
    const size_str = useMemo(() => {
        return sizeToStr(size || 0);
    }, [size]);
    const getMessageFileName = (title: string): string => {
        const name = `${process.env.REACT_APP_NAME || '京办'}${nyrssfENStr(new Date().getTime())}`;
        if (title === 'image.png') {
            return `${name}.png`;
        } else {
            return '';
        }
    };

    // 多文件发送
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([]);
    const [selectedRow, setSelectedRow] = useState<Array<any>>([]);
    const [tableData, setTableData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    const mountedRef = useRef(false);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (!isMulti) {
            if (size && size < 1024 * 1024) {
                setIsOrigin(true);
            } else {
                setIsOrigin(false);
            }
        } else {
            if (selectedRow && selectedRow.length > 0) {
                const isOutofSize = selectedRow.some((item) => item.file.size >= 1024 * 1024);
                setIsOrigin(!isOutofSize);
            } else {
                setIsOrigin(false);
            }
        }
    }, [isMulti, size, selectedRow]);

    const handleKeyDown = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log('mention keydown', e.key);
        if (e.key !== 'Enter') return;
        const isCanSend = sendMessageByKeyboard(e, isMulti, selectedRow);
        if (!isCanSend) {
            toast.warning('至少选择一张图片发送');
            return;
        }
        beforeUpload();
    };

    useEffect(() => {
        let tempList: any[] = [];
        formatFileType(fileList).then((tempFileList) => {
            tempFileList.forEach((file: any) => {
                const promise = dealImagesData(file);
                tempList.push(promise);
            });
            Promise.all(tempList).then((result) => {
                // console.log('result', result);
                const newFiles = result.map((item) => {
                    return {
                        ...item,
                        fileId: nanoid(20),
                    };
                });
                setTableData(newFiles);
                setLoading(!newFiles.length);
                if (newFiles.length <= LIMIT_SIZE) {
                    const ids = newFiles.map((item) => item.fileId);
                    setSelectedRowKeys(ids);
                    setSelectedRow(newFiles);
                } else {
                    setSelectedRowKeys([]);
                    setSelectedRowKeys([]);
                }
            });
        });
    }, [fileList]);

    useEffect(() => {
        if (tableData.length > LIMIT_SIZE) {
            const disbleRows = document.querySelectorAll('.ant-checkbox-wrapper-disabled');
            if (disbleRows.length) {
                disbleRows.forEach((ele) => {
                    const tr = ele.parentNode?.parentNode as Element;
                    tr.classList.add('over-max-num-disabled');
                    setIsDisabled(true);
                });
            } else {
                if (isDisabled) {
                    const disbleTr = document.querySelectorAll('.over-max-num-disabled');
                    if (disbleTr.length) {
                        disbleTr.forEach((ele) => {
                            ele.classList.remove('over-max-num-disabled');
                        });
                    }
                    setIsDisabled(false);
                }
            }
        }
    }, [tableData, selectedRowKeys, isDisabled]);

    function getCheckboxProps(record: any) {
        return {
            disabled:
                selectedRowKeys.length >= LIMIT_SIZE && !selectedRowKeys.includes(record.fileId),
        };
    }

    function rowSelectChange(selectedkeys: any, selectedRows: any) {
        // console.log('选中的值', selectedkeys, selectedRows);
        if (tableData.length > LIMIT_SIZE && selectedkeys.length > LIMIT_SIZE) {
            // message.warn(`最多选择${LIMIT_SIZE}个文件`);
            return;
        } else {
            setSelectedRowKeys(selectedkeys);
            setSelectedRow(selectedRows);
        }
    }

    const columns: any = [
        {
            title: '图片名称',
            width: '260px',
            key: 'title',
            align: 'left',
            ellipsis: true,
            render: (record: any) => {
                return (
                    <div className="file_title">
                        <img src={changeEgovUrl(record.imgState.base64)} draggable="false" />
                        <Tooltip
                            title={record.imgState.title}
                            overlayClassName="multi-file-tooltip"
                            placement="bottom"
                        >
                            <div className="file_title_text">{record.imgState.title}</div>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: '图片大小',
            width: '120px',
            key: 'size',
            align: 'center',
            render: (record: any) => {
                const sizeText = sizeToStr(record.imgState.size || 0);
                return <div>{sizeText}</div>;
            },
        },
    ];
    async function compressImage(fileObj: any) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            if (fileObj.file.size > 1024 * 1024) {
                const data = await imageMin(fileObj.file);
                resolve({ imgState: data.imgState, file: data.file, fileId: fileObj.fileId });
            } else {
                resolve(fileObj);
            }
        });
    }
    async function beforeUpload() {
        if (isMulti) {
            // console.log('selectedRow', selectedRow);
            let tempList: any[] = [];
            selectedRow.forEach((item) => {
                const promise = compressImage(item);
                tempList.push(promise);
            });
            Promise.all(tempList).then((result) => {
                console.log('多张图片发送', result);
                onClick(result);
            });
        } else {
            const result = await imageMin(file);
            console.log('单张图片发送', result);
            // onClick(compressData);
            onClick([], true, result);
        }
    }

    if (!isMulti) {
        return (
            <MyModal
                title={t('send_image')}
                onCancel={onCancel}
                onClick={() => {
                    if (isOrigin) {
                        onClick();
                    } else {
                        beforeUpload();
                    }
                    // bus.emit(`chat:message-preview`, { base64 });
                }}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                    setIsOrigin(e.target.checked);
                }}
                isKeyboard={true}
                size={size}
                isImage={true}
                isOrigin={isOrigin}
                visible={visible}
            >
                <div className="chat-uploadimg">
                    <div className="img-container">
                        <img
                            src={changeEgovUrl(base64)}
                            width={box[0]}
                            height={box[1]}
                            draggable="false"
                        />
                    </div>
                    {title && (
                        <div className="img-title">
                            {title === 'image.png' ? getMessageFileName(title) : title}
                            {/* {title} */}
                            {/* {formatFileName(title)} */}
                        </div>
                    )}
                    {size && <div className="img-size">{size_str}</div>}
                </div>
            </MyModal>
        );
    } else {
        return (
            <MyModal
                title={t('send_image')}
                toolTip={t('max_img_num').replace('%s', `${LIMIT_SIZE}`)}
                onCancel={onCancel}
                onClick={() => {
                    if (isOrigin) {
                        onClick(selectedRow);
                    } else {
                        beforeUpload();
                    }
                    // bus.emit(`chat:message-preview`, { tableData });
                }}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                    setIsOrigin(e.target.checked);
                }}
                isKeyboard={true}
                isImage={true}
                isOrigin={isOrigin}
                visible={visible}
                rows={selectedRow}
                disabled={!selectedRowKeys.length || !tableData.length}
                selfClass="multi-file"
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    locale={{ emptyText: '暂无数据' }}
                    pagination={false}
                    scroll={{ y: 308 }}
                    rowKey="fileId"
                    rowClassName="multi-file-list-table-row"
                    rowSelection={{
                        columnWidth: 40,
                        getCheckboxProps,
                        hideSelectAll: tableData.length > LIMIT_SIZE ? true : false,
                        selectedRowKeys,
                        onChange: rowSelectChange,
                    }}
                />
            </MyModal>
        );
    }
}
