/**
 * joy space 分享文档组件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useEffect, useState } from 'react';
import './index.less';
import { JoySpaceDocumentMessage, MessageStatus, SessionType } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import { getFileIconByMime } from '@/utils/chat/index';
import { lookup } from 'mime-types';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import UnSupport from '@/components/chat/message/UnSupport';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import IconFont from '@/components/icon';
import { replaceGovUrl } from '@/utils/joyspace';
import CalendarCard from '../CalendarCard';
import TaskCard from '../TaskCard';
import { changeEgovUrl } from '@/utils/tools';
import { openShareCommonCard } from '@/components/chat/utils/commonMessageModal';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';

interface ShareJdocProps {
    message: JoySpaceDocumentMessage;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type IShareJdocProps = Readonly<ShareJdocProps & WithTranslation>;

function CommonCard(props: IShareJdocProps) {
    const { sendType, message, onReady } = props;

    const { sharelink } = message.data[0];

    const [parseUrl, setParseUrl] = useState(DeeplinkEvent.parseDeeplink(sharelink.url));

    const extJson = attempt(JSON.parse, sharelink?.ext || '{}');
    if (isError(extJson)) throw extJson;

    const getIcon = (): any => {
        if (sharelink.icon) {
            return sharelink.icon;
        }
        const mimeType = lookup(sharelink?.title || '') || '';
        const iconConfig = getFileIconByMime(mimeType);
        return iconConfig[iconConfig.length - 1];
    };

    // category: "jd_doc"
    const getContent = (): any => {
        if (sharelink?.content) {
            let content = sharelink.content.split('\r')[0];
            if (!content) {
                content = props.t('share-links');
            }
            return content;
        } else {
            return '';
        }

        // console.log(sharelink.content, '分享内容');
        // return sharelink.content;
    };

    const openJFile = () => {
        if (!sharelink.url) {
            return;
        }
        DeeplinkEvent.open(replaceGovUrl(sharelink.url), { source: 'imShareCard' });
        // if (sharelink?.url && sharelink.url.indexOf('http') > -1) {
        //     window.open(sharelink.url);
        // }
    };

    useEffect(() => {
        if (sendType === MessageSendType.OWNER && message.status === MessageStatus.INIT) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);

    if (!sharelink) {
        return <UnSupport message={message} />;
    }

    return (
        <div className="content">
            {parseUrl?.pathname === DeeplinkPathEnum.Schedule_Draw ? (
                <CalendarCard
                    {...props}
                    openJFile={() => openShareCommonCard(message, ScheduleDetailFrom.ChatShare)}
                />
            ) : parseUrl?.pathname === DeeplinkPathEnum.Task_Draw ? (
                <TaskCard message={message} shareUrl={sharelink.url} />
            ) : (
                <div className="j-file-share-card" onClick={() => openShareCommonCard(message)}>
                    <div className="share-title-row">{sharelink.title}</div>
                    <div className="share-content-row">
                        <img
                            src={changeEgovUrl(getIcon())}
                            alt=""
                            className="share-content-row-icon"
                            draggable="false"
                        />
                        <div className="share-content-row-text">{getContent()}</div>
                    </div>
                    {parseUrl?.pathname !== DeeplinkPathEnum.Task_Draw && (
                        <>
                            <div className="share-line-row" />
                            <div className="share-source-row">
                                {props.t('source')}:{sharelink.source}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default withTranslation('chat')(CommonCard);
