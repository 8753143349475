import A1_1Img from '@/assets/img/taskType/A1_1.png';
import A2_1Img from '@/assets/img/taskType/A2_1.png';
import B1_1Img from '@/assets/img/taskType/B1_1.png';
import B2_1Img from '@/assets/img/taskType/B2_1.png';

const defaultColors = ['FFF23030', 'FFFFB416', 'FF5EA7F8', 'FF8ADE30'];
const defaultImgs = [A1_1Img, A2_1Img, B1_1Img, B2_1Img];
/**
 * 根绝颜色获取对应的图片
 * @param {String} color 颜色
 * @returns 对应的图片
 */
export const getTypeImage = (color: string) => {
    // return defaultImgs[defaultColors.indexOf((color || defaultColors[2]).toUpperCase())] || B1_1Img;
    // 不再给默认三角，因为节日紫色，没有对应图形
    return defaultImgs[defaultColors.indexOf((color || defaultColors[2]).toUpperCase())];
};
/**
 * 获取默认颜色
 */
export const getDefaultColor = () => {
    return defaultColors[2];
};
