import React from 'react';
import { Spin, message, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LoginFormContext, LoginFormContextType } from '../utils';
import GlobalContext from '@/context/GlobalContext';
import { IconArrowLeft } from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { AuthDataRes } from '@/types/LoginPage';

export default function NameInputForm({
    authCode,
    onCreateUser,
}: {
    authCode: string;
    onCreateUser: (data: AuthDataRes) => void;
}) {
    const { t } = useTranslation('common');
    const {
        mobile,
        userName,
        onUserNameChange,
        navigate,
        setResendLastTs,
        onMobileChange,
    } = React.useContext<LoginFormContextType>(LoginFormContext);
    const { appRuntimeEnv, service, onGlobalError } = React.useContext(GlobalContext);
    // const [nickname, setNickname] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );

    async function createUser() {
        // TODO
        try {
            setLoading(true);
            const content = await service.focusService.createUser({
                userName,
                mobile,
                authCode,
                countryCode: '86',
            });
            setLoading(false);
            if (content) {
                // TODO
                // 换取token
                service.authService
                    .fastLogin(
                        {
                            accessToken: content.accessToken,
                        },
                        {
                            'x-token': content.accessToken,
                        }
                    )
                    .then((data: AuthDataRes) => {
                        onCreateUser(data);
                    })
                    .catch((e: any) => {
                        onGlobalError(e);
                    });
            }
        } catch (error) {
            setLoading(false);
        }
        // navigate('stepNewTeam');
    }

    return (
        <div className="mobileForm">
            <div
                style={{
                    margin: '10px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        onMobileChange('');
                        setResendLastTs(-1);
                        setTimeout(() => {
                            navigate('step1');
                        }, 0);
                    }}
                >
                    <span
                        style={{
                            marginRight: 5,
                        }}
                    >
                        {IconArrowLeft}
                    </span>
                    {t('login.back')}
                </div>
            </div>
            <h4 style={{ margin: 0 }}>{t('login.enterNickname')}</h4>
            <p>{t('login.nicknameDesc')}</p>
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <div className="mobileInput">
                    <Input
                        autoFocus
                        placeholder={t('login.enterNicknamePlaceholder')}
                        value={userName}
                        onKeyDown={(e: any) => {
                            const { keyCode } = e;
                            if (keyCode === 13) {
                                // checkMobileCb();
                            }
                        }}
                        onChange={(e: any) => {
                            onUserNameChange(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                        // checkMobileCb();
                        // TODO 创建新用户
                        createUser();
                    }}
                    className="btn"
                    style={
                        !loading && userName && userName.length
                            ? {
                                  opacity: 1,
                                  cursor: 'pointer',
                              }
                            : {}
                    }
                >
                    {loading && <div>{loadingComp}</div>}
                    <div
                        style={{
                            margin: 12,
                            fontSize: 14,
                            color: 'rgb(255, 255, 255)',
                        }}
                    >
                        {t('nextStep')}
                    </div>
                </div>
            </div>
        </div>
    );
}
