/**
 * joy space 分享文档, 文件入品
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useEffect, useMemo, useState } from 'react';
import {
    ChatMessageType,
    EmployeeAttribute,
    GroupAttribute,
    JoySpaceDocumentMessage,
    MessageStatus,
    SessionType,
} from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import { WithTranslation, useTranslation } from 'react-i18next';
// import { getPageIconByPagetype } from '../utiles/joyspace-file';
import Text from '@/components/chat/message/Text';
import i18n from '@/i18n';
import { isFocusEnv } from '@/utils';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

if (!isFocusEnv()) {
    // eslint-disable-next-line
    require('@/components/Joyspace/winOpen');
}
import { Dropdown, Menu, Select, message as Message } from 'antd';
import IconFont from '@/components/icon';
import JoyspaceFileMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/message/JoyspaceFile';
import Context from '@/components/chat/context';
import { isSameEmployee } from '@/utils/user';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { shareJoyspaceFiles } from '@/components/Joyspace/api/page';
import {
    getJoySpaceReadOnlyPermission,
    getJoySpaceReadWritePermission,
} from '@/components/Joyspace/config/permissions.config';
// import { couldStartTrivia } from 'typescript';
import ImService from '@/server/ImService';
import ReplyMessage from '@/components/chat/message/ReplyMessage';
import { getTextMessageContent } from '@/components/chat/utils/commonMessageModal';
import ShortLink from '@/components/chat/message/message-components/ShortLink';
// import MessageCard, { OriginType } from '../MessageCard';
import {
    ContentItem,
    convertContentToArray,
    MessageCardType,
    sendMessageBody,
} from '@/components/chat/utils/message';
import MessageCard, { OriginType } from '@/components/chat/message/message-components/MessageCard';
import { getPageIconByPagetype } from '@/components/chat/message/message-components/utiles/joyspace-file';
import { handleOpenMentionUserInfo } from '@/utils/chat/index';
import PermissionSelect from '@/components/Joyspace/components/PermissionSelect';

const { Option } = Select;
interface JoyspaceFileProps {
    message: JoyspaceFileMessageBody;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
    count?: number;
    updateSessionMessageMid?: Function;
}

type ISJoyspaceFilePropsProps = Readonly<JoyspaceFileProps & WithTranslation & ChatState>;

function JoyspaceFile(props: ISJoyspaceFilePropsProps | any) {
    const [currentPermission, setCurrentPermission] = useState(
        // @ts-ignore
        props.message.currentPermission || 2
    );
    console.log(props.message, 'props.messageprops.messageprops.message');
    const { t } = useTranslation('chat');
    const { groupRosterIdentity } = React.useContext(Context);
    useEffect(() => {
        setCurrentPermission(props.message.currentPermission);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.message.currentPermission]);
    const { data } = props.message;
    const {
        sendType,
        onReady,
        selectedSession,
        updateSessionMessageMid,
        currentEmployee,
        updateSessionMessagesTimestamp,
    } = props;
    const setPermission = async (permission: number) => {
        // 减少请求及IM方法调用
        // eslint-disable-next-line no-debugger
        // debugger;
        const selectedSession = props.selectedSession;
        let groups;
        let users;
        let info = selectedSession.info as GroupAttribute | EmployeeAttribute;
        if (selectedSession.isGroup) {
            groups = [{ gid: (info as GroupAttribute).gid, permission_type: permission }];
        } else {
            users = [
                {
                    userId: (info as EmployeeAttribute).userId,
                    teamId: (info as EmployeeAttribute).teamId,
                    permission_type: permission,
                },
            ];
        }
        // 修改消息体
        const imService = ImService.getInstance();
        console.log(selectedSession, 'selectedSessionselectedSessionselectedSession');
        if (selectedSession) {
            const sessionId = (selectedSession as any).sessionId; // 参数1
            const _message = { ...props.message };
            // console.log(_message, '_message========>');
            const res = await shareJoyspaceFiles({
                page_ids: [_message.fileId],
                to_eepaas_users: users,
                to_groups: groups,
            });
            const fileId = _message.fileId;
            if (res[fileId].status === 'faild' || res[fileId].status === 'failed') {
                Message.error(res[fileId].errMsg);
            }
            if (props.message.type === ChatMessageType.TEXT) {
                let displayContent = props.message.displayContent.map((item: ContentItem) => {
                    if (item.type === MessageCardType.JoyspaceTopic) {
                        let ext = JSON.parse((item as any).ext);
                        if (
                            ext.pageId === fileId &&
                            res[(ext as any).pageId] &&
                            Array.isArray(res[ext.pageId].page.permissions)
                        ) {
                            ext.currentPermission = permission;
                        } else if (
                            res[(ext as any).pageId] &&
                            !Array.isArray(res[ext.pageId].page.permissions)
                        ) {
                            ext.permission = -1;
                            ext.currentPermission = -1;
                        }

                        (item as any).ext = JSON.stringify(ext);
                    }
                    return item;
                });
                _message.displayContent = displayContent;
            }
            const isDelPage = Array.isArray(res[fileId].page.permissions);
            const body = {
                ..._message,
                permission: isDelPage ? _message.permission : -1,
                currentPermission: isDelPage ? permission : -1,
            };
            setCurrentPermission(() => {
                return isDelPage ? permission : -1;
            });
            const result = await imService.updateJoySpaceLink(
                sessionId,
                selectedSession.mid || selectedSession.lastMid,
                body
            );

            if (result) {
                // 修改渲染数据
                updateSessionMessageMid?.({
                    sessionId: sessionId,
                    sendResult: {
                        body: { ...body },
                    },
                });
                await updateSessionMessagesTimestamp({
                    timestamp: new Date().getTime(),
                });
            }
        }
    };
    const handleMenuClick = (key = '2') => {
        const permission = parseInt(key, 10);
        // console.log(
        //     permission,
        //     currentPermission,
        //     'currentPermissioncurrentPermissioncurrentPermission'
        // );
        if (permission === currentPermission) {
            return;
        }
        setPermission(permission);
    };
    // @ts-ignore
    // 兼容移动端的神奇代码
    if (data?.[0]?.joySpaceLinkDetail) {
        // @ts-ignore
        const joySpaceLinkDetail = data[0].joySpaceLinkDetail || {};
        props.message.fileName = joySpaceLinkDetail.fileName;
        props.message.pageType = joySpaceLinkDetail.pageType;
        props.message.webUrl = joySpaceLinkDetail.webUrl;
        props.message.content = joySpaceLinkDetail.content;
        props.message.iconUrl = joySpaceLinkDetail.iconUrl;
    }
    const {
        fileName,
        webUrl,
        pageType,
        content,
        iconUrl,
        // @ts-ignore
        sender,
        // @ts-ignore
        senderTeamId,
        // @ts-ignore
        permission = 2,
        sText,
        sMid,
        replyMsgs = [],
    } = props.message;
    const message = props.message;
    useEffect(() => {
        if (
            (sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF) &&
            // @ts-ignore
            message.status === MessageStatus.INIT
        ) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);
    // 这块可能不满足所有的业务场景
    const isOwnSendMessage: boolean = isSameEmployee(
        currentEmployee,
        typeof sender === 'string' ? { userId: sender, teamId: senderTeamId } : sender
    );
    // const isToSelf: boolean = isSameEmployee(sender, selectedSession.info as EmployeeAttribute);
    const addTextClass =
        (webUrl !== content && content) ||
        (replyMsgs && replyMsgs.filter((item: any) => item).length > 0)
            ? 'joyspace-file-message-content-text'
            : 'joyspace-file-message-content-no-text';
    let contentArr: any = convertContentToArray(content);
    // 兼容老版本
    let isCompatible = false;
    if (message.type === ChatMessageType.JOYSPACEFILE) {
        const shortLinkArr = contentArr.filter(
            (item: any) => item.type === MessageCardType.ShortLink
        );
        if (shortLinkArr.length > 1) {
            isCompatible = true;
            // contentArr = await sendMessageBody(content, [selectedSession]);
            // message.type = ChatMessageType.TEXT;
        }
    }
    // console.log(contentArr, 'contentArr===========>');
    const hasUrl =
        contentArr[0].type === MessageCardType.JoyspaceTopic ||
        contentArr[0].type === MessageCardType.ShortLink ||
        contentArr[0].type === MessageCardType.HttpTopic ||
        (contentArr[0] as any).type === ChatMessageType.JOYSPACEFILE;
    const addStyle =
        replyMsgs && replyMsgs.filter((item: any) => item).length > 0 && hasUrl
            ? { paddingBottom: '12px' }
            : { padding: '0' };
    return (
        <div
            className={[
                'joyspace-file-message-content',
                `${isCompatible ? 'joyspace-file-message-content_old' : ''}`,
                `${addTextClass}`,
            ].join(' ')}
            style={{ backgroundColor: isOwnSendMessage ? '#D4E9FB' : '#ffffff' }}
        >
            {props.message.type === ChatMessageType.JOYSPACEFILE && (
                <div style={{ ...addStyle }}>
                    <ReplyMessage message={message} sendType={sendType} />
                </div>
            )}
            {contentArr?.map((item: any, index: number) => {
                const _message = { ...message, content: item.content };
                if (item.type === MessageCardType.Text) {
                    return (
                        <p
                            style={{
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                                userSelect: 'text',
                            }}
                            className={`joyspace-file-message-content_message${index}`}
                            onClick={(e) =>
                                handleOpenMentionUserInfo(e, t, {
                                    currentEmployee,
                                    selectedSession,
                                    groupRosterIdentity,
                                })
                            }
                            dangerouslySetInnerHTML={{
                                __html: getTextMessageContent(
                                    _message,
                                    currentEmployee,
                                    sText,
                                    sMid
                                ),
                            }}
                        />
                    );
                } else if (isCompatible) {
                    return <ShortLink shortLinkItem={item} />;
                } else if (
                    item.type === MessageCardType.JoyspaceTopic ||
                    item.type === MessageCardType.ShortLink ||
                    item.type === MessageCardType.HttpTopic ||
                    item.type === ChatMessageType.JOYSPACEFILE
                ) {
                    return (
                        <MessageCard
                            origin={OriginType.JoySpace}
                            title={`${fileName}` || '无标题'}
                            imageSrc={changeEgovUrl(iconUrl || getPageIconByPagetype(pageType))}
                            handleDetail={() => {
                                window.open(webUrl);
                            }}
                            footerChildren={() => {
                                return (
                                    permission !== -1 &&
                                    isOwnSendMessage &&
                                    currentPermission !== -1 && (
                                        <div className="share-menu">
                                            <span>
                                                {i18n.t('joyspace:teamspace.granting-permission')}
                                            </span>
                                            <div className="drop-down">
                                                {permission === 1 &&
                                                (sendType === MessageSendType.OWNER ||
                                                    sendType === MessageSendType.SELF) ? (
                                                    // <Select
                                                    //     value={currentPermission}
                                                    //     // optionLabelProp="label"
                                                    //     onChange={(val) => {
                                                    //         // console.log(
                                                    //         //     val,
                                                    //         //     '===================>'
                                                    //         // );
                                                    //         handleMenuClick(val);
                                                    //     }}
                                                    // >
                                                    //     <Option
                                                    //         value={getJoySpaceReadOnlyPermission()}
                                                    //     >
                                                    //         {i18n.t('joyspace:teamspace.readonly')}
                                                    //         <p className="tip">可预览文档</p>
                                                    //     </Option>
                                                    //     <Option
                                                    //         value={getJoySpaceReadWritePermission()}
                                                    //     >
                                                    //         {i18n.t('joyspace:teamspace.edit')}
                                                    //         <p className="tip">可预览/编辑文档</p>
                                                    //     </Option>
                                                    // </Select>
                                                    <PermissionSelect
                                                        onChange={handleMenuClick}
                                                        permissionType={getJoySpaceReadWritePermission()}
                                                        propsValue={currentPermission}
                                                    />
                                                ) : (
                                                    <span>
                                                        {(sendType === MessageSendType.OWNER ||
                                                        sendType === MessageSendType.SELF
                                                            ? permission
                                                            : currentPermission) === 2
                                                            ? i18n.t('joyspace:teamspace.readonly')
                                                            : i18n.t('joyspace:teamspace.edit')}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )
                                );
                            }}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
}
function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
    };
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    // console.log(chat.sessionMessageMap, 'chat.sessionMessageMap');
    return {
        selectedSession: chat.selectedSession,
        currentEmployee: chat.currentEmployee,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(JoyspaceFile);
