export const stickers_sets: any = {
    '[+1]': 'agree+1.png', // +1
    '🤗': 'hugging-face_1f917.png', // 拥抱
    '😏': 'smirking-face_1f60f.png',
    '😉': 'winking-face_1f609.png',
    '😣': 'persevering-face_1f623.png',
    '😌': 'relieved-face_1f60c.png',
    '🙂': 'em-01.png', // 微笑&呵呵
    '😕': 'em-02.png', // 撇嘴
    '😍': 'em-03.png', // 色
    '😯': 'hushed-face_1f62f.png', // 发呆
    '[吓]': 'em-04.png', // 吓
    '😎': 'em-05.png', // 得意&酷酷
    '😢': 'em-06.png', // 流泪
    '[害羞]': 'em-07.png', // 害羞
    '🤐': 'em-08.png', // 闭嘴
    '😴': 'em-09.png', // 睡着
    '😭': 'em-10.png', // 大哭
    '😅': 'em-11.png', // 尴尬
    '😡': 'em-12.png', // 发怒
    '😋': 'em-13.png', // 调皮
    '😁': 'em-14.png', // 嘻嘻&憨笑&呲牙
    '😲': 'em-15.png', // 惊讶&吃惊
    '🙁': 'em-16.png', // 难过
    '[囧]': 'em-17.png', // 囧
    '😫': 'em-18.png', // 抓狂
    '🤮': 'em-19.png', // 吐
    '🤭': 'em-20.png', // 偷笑
    '😊': 'em-21.png', // 可爱
    '🙄': 'em-22.png', // 白眼
    '😤': 'em-23.png', // 傲慢
    '😪': 'em-24.png', // 困
    '😱': 'em-25.png', // 惊恐
    '😓': 'em-26.png', // 流汗
    '[憨笑]': 'em-27.png', // 憨笑
    '[悠闲]': 'em-28.png', // 悠闲
    '[奋斗]': 'em-29.png', // 奋斗
    '🤬': 'em-30.png', // 咒骂
    '🤔️': 'thinking-face_1f914.png', // 疑问
    '[疑问]': 'em-31.png',
    '🤫': 'em-32.png', // 嘘
    '😵': 'em-33.png', // 晕
    '🤕': 'em-34.png', // 衰
    '💀': 'em-35.png', // 骷髅
    '[敲打]': 'em-36.png', // 敲打
    '👋': 'em-37.png', // 再见
    '[擦汗]': 'em-38.png', // 擦汗
    '[抠鼻]': 'em-39.png', // 抠鼻
    '👏': 'em-40.png', // 鼓掌
    '[坏笑]': 'em-41.png', // 坏笑
    '[左哼哼]': 'em-42.png', // 左哼哼
    '[右哼哼]': 'em-43.png', // 右哼哼
    '🥱': 'em-44.png', // 哈欠
    '[鄙视]': 'em-45.png', // 鄙视
    '[委屈]': 'em-46.png', // 委屈
    '[快哭了]': 'em-47.png', // 快哭了
    '[阴险]': 'em-48.png', // 阴险
    '😘': 'em-49.png', // 亲亲
    '🥺': 'em-50.png', // 可怜
    '[吐血]': 'em-51.png', // 吐血
    '🍉': 'em-52.png', // 西瓜
    '🍺': 'em-53.png', // 啤酒
    '☕': 'em-54.png', // 咖啡
    '[咖啡]': 'em-54.png',
    '[joy]': 'em-55.png', // joy
    '🌹': 'em-56.png', // 玫瑰
    '🥀': 'em-57.png', // 凋谢
    '👄': 'em-58.png', // 嘴唇
    '❤️': 'em-59.png', // 爱心
    '💔': 'em-60.png', // 心碎
    '🎂': 'em-61.png', // 蛋糕
    '💣': 'em-62.png', // 炸弹
    '[whocare]': 'em-63.png', // whocare
    '🌛': 'em-64.png', // 月亮
    '🌞': 'em-65.png', // 太阳
    '[拥抱]': 'em-66.png', // 拥抱
    '👍': 'em-67.png', // 强
    '👎': 'em-68.png', // 弱
    '🤝': 'em-69.png', // 握手
    '✌': 'em-70.png', // 胜利
    '[胜利]': 'em-70.png', // 胜利
    '[抱拳]': 'em-71.png', // 抱拳
    '[勾引]': 'em-72.png', // 勾引
    '[爱你]': 'em-73.png', // 爱你
    '[NO]': 'em-74.png', // no
    '👌': 'em-75.png', // ok
    '😜': 'em-76.png', // 鬼脸
    '😄': 'em-77.png', // 笑脸
    '😷': 'em-78.png', // 生病
    '😂': 'em-79.png', // 破涕为笑
    '😛': 'em-80.png', // 吐舌
    '😳': 'em-81.png', // 脸红
    '😨': 'em-82.png', // 恐惧
    '😞': 'em-83.png', // 失望
    '😒': 'em-84.png', // 无语
    '[嘿哈]': 'em-85.png', // 嘿哈
    '[捂脸]': 'em-86.png', // 捂脸
    '[奸笑]': 'em-87.png', // 奸笑
    '[机智]': 'em-88.png', // 机智
    '😠': 'em-89.png', // 皱眉
    '[耶]': 'em-90.png', // 耶
    '🙏': 'em-91.png', // 合十
    '💪': 'em-92.png', // 强壮
    '🎉': 'em-93.png', // 庆祝
    '🎁': 'em-94.png', // 礼物
    '🧧': 'em-95.png', // 红包
};
