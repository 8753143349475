import { IPageAddCallback } from './index';
import { lang } from '../context';
import { createPageUrl, openLink } from '../util';

// eslint-disable-next-line max-params
const edit: IPageAddCallback = (props, isAllow, isTeam, globalContext) => {
    const { data } = props;

    return [
        {
            children: lang('ctx.edit.title'),
            callbackOrModal: () => {
                // PWAtest
                // window.open(createPageUrl(data, 'http://localhost:3002' || ''), '_blank');
                openLink(data, globalContext?.appRuntimeEnv.joyspaceHost || '');
            },
            isAllow,
            type: 'edit',
            icon: 'pc_yundocument_open',
            splitCode: 1,
        },
    ];
};

export default edit;
