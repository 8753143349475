import { EventEmitter } from 'events';
import AppRuntimeEnv, { AppRuntimeConfig } from '@/types/AppRuntimeEnv';
import { AuthData, LangEnum, RegistryConfigData } from '@/types/LocalConfig';
import { getEnvByRuntimeAndEnv } from './envConfig';
import logger from '@/utils/logger';
import pkg from '../../package.json';
import Cookies from 'js-cookie';
import AuthService from '@/services/AuthService';
import { AuthDataRes } from '@/types/LoginPage';
import cache from '@/utils/cache';
import { isFocusEnv, toMainWinMessage } from '@/utils/index';
import { checkUpgradeApp } from '@/api/upgradeApp';
import { processJoyspaceUrl } from '@/components/Joyspace/winOpen';
import { NetType } from '@/components/Modals/ChangeNetModal';

const log = logger.getLogger('FocusSDKWeb');

const registryConfigData = (env: string): RegistryConfigData => ({
    version: 1,
    pkgVersion: pkg.version,
    machineId: '',
    theme: 'default',
    runtimeConfig: {
        runtime: '',
        env,
        webEntry: '',
    },
    selectedTeamId: '',
    settings: {
        downloadDir: '',
    },
    lang: LangEnum.ZH_CN,
    autoLogin: false,
    client: 'IPAD',
});

const { NODE_ENV } = process.env;

export interface ApplicationInfo {
    name: string;
    key: string;
    isSpecialProjects: boolean;
    domain: string;
    isGovEbook: boolean;
    ossBaseUrl: string;
}

export default class FocusSDK extends EventEmitter {
    runtimeEnv: AppRuntimeEnv | null = null;
    registryConfig: RegistryConfigData | null = null;
    theme = '';
    authInfo: AuthDataRes | null = null;

    private applicationInfo: ApplicationInfo | undefined;

    getDeviceInfo() {
        return {
            platform: 'web',
        };
    }

    getApplicationInfo() {
        return this.applicationInfo;
    }

    getThirdAppUserInfo(cb: (thirdAppUserData: any) => void) {
        return;
    }
    sendIpcMessage(event: string, ...args: any[]): void {
        return;
    }
    getTheme() {
        if (this.theme) {
            return this.theme;
        }
        this.theme = this.runtimeEnv?.getUIConfig('theme') || 'default';
        return this.theme;
    }

    printLog(...arg: any[]) {
        if (process.env.REACT_APP_NO_CONSOLE !== 'true') {
            console.log(...arg);
        }
    }
    logout(reason?: string) {
        this.clearCookies();
        this.emit('logout', reason);
    }

    openUserCard(data: any) {
        this.emit('openUserCard', data);
    }

    onIpcMessage(event: string, cb: Function) {}
    sendToMainWin(data: toMainWinMessage): void {}
    addIpcEventListener(channel: string, cb: (...args: any[]) => void): () => void {
        return () => {};
    }
    closeCurrentWindow(): void {}
    checkUpdate() {
        //
    }
    switchTeam(teamId: string) {}
    getAuthInfo(cb: (authInfo: AuthData) => void) {
        if (!this.runtimeEnv) {
            throw new Error('no runtimeEnv found');
        }
        const service = new AuthService(this.runtimeEnv, this as any);
        new Promise<{ token: string; teamId: string }>((resolve, reject) => {
            if (cache.token) {
                // eslint-disable-next-line no-promise-executor-return
                return resolve({
                    token: cache.token.token.focusToken,
                    teamId: cache.token.token.focusTeamId,
                });
            }
            if (cache.tokenProvider) {
                // eslint-disable-next-line no-promise-executor-return
                return cache.tokenProvider
                    .token()
                    .then((token) => {
                        cache.token = token;
                        return resolve({
                            token: token.token.focusToken,
                            teamId: token.token.focusTeamId,
                        });
                    })
                    .catch(reject);
            }
            let teamId = Cookies.get('focus-team-id') || '';
            let token = Cookies.get('focus-token') || '';
            resolve({ teamId, token });
        })
            .then((token: { token: string; teamId: string }) => {
                service
                    .getAuthInfo({
                        'x-token': token.token,
                        'x-team-id': token.teamId,
                    })
                    .then((authData: AuthDataRes) => {
                        // this.authInfo = authData;
                        this.authInfo = isFocusEnv()
                            ? authData
                            : {
                                  ...authData,
                                  ddAppId:
                                      Cookies.get('focus-app-id') || authData.ddAppId || 'gwork',
                                  teamUserInfo: {
                                      ...authData.teamUserInfo,
                                      ddAppId:
                                          Cookies.get('focus-app-id') ||
                                          authData.ddAppId ||
                                          'gwork',
                                  },
                              };
                        log.debug('---> authInfo', this.authInfo);
                        cb({
                            // ...authData,
                            ...this.authInfo,
                            accessToken: token.token,
                            teamUserInfoList: [this.authInfo.teamUserInfo as any],
                            loginTs: Date.now(),
                        });
                    });
            })
            .catch((e) => {
                log.error('failed to getAuthInfo', e);
            });
    }
    openGlobalSearch() {
        this.emit('openGlobalSearch');
    }
    getAppRuntimeEnv(
        cb: (config: { runtimeEnv: AppRuntimeConfig; localConfig: RegistryConfigData }) => void
    ): void {
        const env = process.env.REACT_APP_NODE_ENV === 'production' ? 'prod' : 'beta';
        log.debug('--getEnvByRuntimeAndEnv-cache:', cache);
        // getEnvByRuntimeAndEnv(cache.clientId || process.env.REACT_APP_RUNTIME || 'jdcloud', env)
        getEnvByRuntimeAndEnv(cache.clientId || process.env.REACT_APP_RUNTIME || 'jdcloud', env)
            .then((result) => {
                log.debug('--getEnvByRuntimeAndEnv', result);
                const runtimeEnv = result;
                const localConfig = registryConfigData(env);
                this.runtimeEnv = new AppRuntimeEnv(result, localConfig);
                this.registryConfig = localConfig;
                this.theme = this.runtimeEnv.getUIConfig('theme') || 'default';
                const isGovEbook = this.runtimeEnv.getUIConfig('isGovEbook') || false;
                const isSpecialProjects = this.runtimeEnv.getUIConfig('isSpecialProjects') || false;
                this.applicationInfo = {
                    name: this.runtimeEnv.productName,
                    key: this.runtimeEnv.runtimeConfig.key,
                    isSpecialProjects,
                    domain: this.runtimeEnv.domain,
                    isGovEbook: isGovEbook,
                    ossBaseUrl: 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
                };
                console.log(
                    'process.env.REACT_APP_OSS_BASE_URL',
                    process.env.REACT_APP_OSS_BASE_URL
                );
                log.debug('AppRuntimeEnv theme => ', this.theme);
                log.debug('AppRuntimeEnv applicationInfo => ', this.applicationInfo);
                cb({
                    runtimeEnv,
                    localConfig,
                });
            })
            .catch((e) => {
                log.error('-getEnvByRuntimeAndEnv-e');
                log.error(e);
            });
    }
    // joyspace url携带cookie信息
    openUrl(urlParam: string) {
        let url = processJoyspaceUrl(urlParam);
        window.open(url);
    }
    getVersion() {
        // const data = await checkUpgradeApp('0.0.0', 'WEB');
        // console.log('getVersion-', data);
        // return data[0]?.version || '';
        // 不能用异步 web端没有版本号
        return '';
    }
    setCookies(name: string, value: string, expired?: any) {
        try {
            Cookies.set(name, value, { expired });
        } catch (e) {}
    }
    clearCookies() {
        Cookies.remove('focus-token', {
            domain: process.env.REACT_APP_TOP_LEVEL_DOMAIN,
            path: process.env.REACT_APP_ROUTE_BASE || '/client',
        });
        // 增加由服务端setCookies设置的cookie信息
        Cookies.remove('focus-token', {
            domain: process.env.REACT_APP_TOP_LEVEL_DOMAIN,
            path: '/',
        });
        Cookies.remove('focus-app-id', {
            domain: process.env.REACT_APP_TOP_LEVEL_DOMAIN,
            path: process.env.REACT_APP_ROUTE_BASE || '/client',
        });
        Cookies.remove('focus-app-id', {
            domain: process.env.REACT_APP_TOP_LEVEL_DOMAIN,
            path: '/',
        });
        Cookies.remove('focus-team-id', {
            domain: process.env.REACT_APP_TOP_LEVEL_DOMAIN,
            path: process.env.REACT_APP_ROUTE_BASE || '/client',
        });
        Cookies.remove('focus-team-id', {
            domain: process.env.REACT_APP_TOP_LEVEL_DOMAIN,
            path: '/',
        });
    }
    getLogFile(file: string) {}
    getDownloadPath() {}
    getConfigData() {
        let obj = {
            defaultDownloadPath: '',
        };
        return JSON.stringify(obj);
    }
    isBeta() {
        return process.env.REACT_APP_NODE_ENV !== 'production';
    }
    isPrivateNet() {
        const currentHost = window.location?.hostname || '';
        return currentHost.indexOf('beijing.egov.cn') > -1;
    }
    getCurrentNetType() {
        const currentHost = window.location?.hostname || '';
        let isPrivate = currentHost.indexOf('beijing.egov.cn') > -1;
        let type = isPrivate ? NetType.PRIVATE_NET : NetType.PUBLIC_NET;
        return type;
    }
    privateDnsIsTrue() {
        return true;
    }
    canSetPrivateDns() {
        return true;
    }
    getMeMainEntry() {
        const hostname = window.location?.origin || '';
        return hostname;
    }
    getWebConfig() {
        const webConfig = {
            routeBase: process.env.REACT_APP_ROUTE_BASE,
            ssoLoginUrl: process.env.REACT_APP_SSO_LOGIN_URL,
            ssoClientId: process.env.REACT_APP_SSO_CLIENTID,
        };

        return webConfig;
    }
    checkUrlOnline() {
        let platform = this.getDeviceInfo().platform;
        if (platform === 'web') {
            return;
        }
    }
    checkCurrentNet() {}
}
