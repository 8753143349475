import React from 'react';

interface CornerMarkType {
    text?: String;
    styles?: any;
}

export default function HeaderLabel(props: CornerMarkType) {
    const { text = '推荐', styles } = props;
    return (
        <div className="header-label" style={styles}>
            {text}
        </div>
    );
}
