import BaseService from '@/attributes/BaseService';

class ServiceList extends BaseService {
    setDBList(propertyKey: string, data: any) {
        return new Promise((resolve, reject) => {
            const doc = {
                _id: propertyKey,
                value: data,
            };
            console.log(doc, 'docdocdocdocdoc==>');
            this.dbService.saveToDB(doc);
            resolve('操作成功');
        });
    }

    getDBList(propertyKey: string) {
        return new Promise((resolve, reject) => {
            this.dbService
                .getFromDB(propertyKey)
                .then((doc: any) => {
                    resolve(doc?.value);
                })
                .catch((e: any) => {
                    console.error(e);
                    reject(e);
                });
        });
    }
}

export default ServiceList;
