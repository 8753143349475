import React, { ReactNode, CSSProperties } from 'react';
import { Modal, Button } from 'antd';
interface Iprop {
    visible: boolean;
    title?: any;
    onOk?: () => void;
    onCancel?: () => void;
    children?: any;
    className?: string;
    style?: CSSProperties;
    width?: string | number;
    okText?: string;
    cancelText?: string;
    cancelButtonProps?: any;
    bodyStyle?: CSSProperties;
    closable?: boolean;
    footer?: any;
    getContainer?: any;
    centered?: boolean;
}
export default function BasicModal({
    visible,
    title,
    onOk,
    onCancel,
    children,
    className,
    style,
    width,
    cancelText,
    okText,
    cancelButtonProps,
    bodyStyle,
    closable,
    footer,
    getContainer,
    centered,
}: Iprop) {
    return (
        <Modal
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            wrapClassName={`base-modal-wrapper ${className}`}
            style={style}
            width={width}
            okText={okText}
            cancelText={cancelText}
            cancelButtonProps={cancelButtonProps}
            bodyStyle={bodyStyle}
            getContainer={getContainer}
            closable={closable}
            footer={footer}
            centered={centered}
        >
            {children}
        </Modal>
    );
}
