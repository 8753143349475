/**
 * 文件消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */

import React, { useContext, useEffect, useState } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { message as toast, Tooltip } from 'antd';
import { useTranslation, WithTranslation } from 'react-i18next';
import { ChatMessageType, FileChatMessage, FileStatus } from '@/types/chat';
import {
    downLoadFile,
    getFileIconByMime,
    getFileName,
    getMessageFileName,
    checkDownloadFilePathValid,
    getFileUrl,
    sizeToStr,
    downloadMessageFile,
    pushAutoDownload,
} from '@/utils/chat/index';
import { MessageSendType } from '@/types/chat/enum';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { lookup } from 'mime-types';
import { DownloadItem, FocusSDK, isFocusEnv, detectOS } from '@/utils';
import ImService from '@/server/ImService';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import pick from 'lodash/pick';
import { changeEgovUrl } from '@/utils/tools';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import DownloadFileQueue, { SaveOption } from '@/utils/DownloadFileQueue';
import { handleAddFileDownloadRecord } from '@/components/chat/utils/commonMessageModal';
import DownloadService from '@/server/DownloadService';
import { isEqualEmployee } from '@/utils/chat/message';
import ChatContext from '@/components/chat/context';

interface DownLoadFileProps {
    message: FileChatMessage;
    sendType: MessageSendType;
    isMergeForward?: boolean;
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
    downloadingMids: string[];
    updateDownloadingMids: Function;
}

type IDownLoadFileProps = Readonly<WithTranslation & DownLoadFileProps & ChatState>;

let dXhr: any = {};

function DownLoadFile(props: IDownLoadFileProps) {
    const {
        message,
        connectState,
        currentEmployee,
        isMergeForward,
        updateSessionMessageMid,
        updateSessionMessagesTimestamp,
        selectedSession,
        sendType,
        downloadingMids,
        updateDownloadingMids,
    } = props;
    const { t } = useTranslation('chat');
    const { isSmall } = React.useContext(ChatContext);
    const downloadServiceInstance = DownloadService.getInstance({
        updateDownloadingMids,
    } as any);
    const downloadStatus = downloadServiceInstance.getDownloadStatus((message as any).mid);
    const [status, setStatus] = useState(downloadStatus);
    const [path, setPath] = useState('');
    const [progress, setProgress] = useState(0);
    const [downloadItem, setDownloadItem]: any = useState({});
    const [downloadXhr, setDownloadXhr]: any = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const defaultDownloadPath =
        appRuntimeEnv.registryConfig.defaultDownloadPath || FocusSDK.getDownloadPath();
    const getDownloadOptions = ({ viewFile, saveAs, message, auto, isGroup }: SaveOption) => {
        const saveName = getMessageFileName(message);
        const needSend = !auto && isGroup;
        return {
            onStarted: (downloadItem: DownloadItem) => {
                setStatus(FileStatus.DOWNLOADING);
                setDownloadItem(downloadItem);
            },
            onProgress: ({ percent = 0 }) => {
                setStatus(FileStatus.DOWNLOADING);
                setProgress(percent);
            },
            onCancel: (alreadySaveAs?: boolean) => {
                console.log('cancel');
                if (!alreadySaveAs) {
                    setStatus(FileStatus.INIT);
                }
            },
            onPause: () => {
                setStatus(FileStatus.CANCELED);
            },
            onResume: () => {
                setStatus(FileStatus.DOWNLOADING);
            },
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                console.log('file=>', file);
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = file.path;
                // console.log(detectOS());
                // const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${file.filename}`;
                // console.log('path=>', data.saveAs, path);
                // // tj: 解决保存为头像地址
                // if (!checkDownloadFilePathValid(saveName, file)) {
                //     setStatus(FileStatus.INIT);
                //     if (!auto) {
                //         toast.error(t('failed'));
                //     }
                //     setIsDragging(false);
                //     return;
                // }
                if (isMergeForward) {
                    setStatus(FileStatus.COMPLETED);
                    setProgress(100);
                    setPath(path);
                    downloadServiceInstance.setDownloadStatus(
                        (message as any).mid,
                        FileStatus.COMPLETED
                    );
                    // if (viewFile) {
                    //     FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    //         if (exists) {
                    //             setTimeout(() => {
                    //                 FocusSDK.openItem(path);
                    //             }, 500);
                    //         } else {
                    //             toast.error('文件下载失败');
                    //         }
                    //     });
                    // }
                    setIsDragging(false);
                    if (saveAs) {
                        localStorage.setItem('downloadFilePath', data.saveAs);
                    }
                    return;
                }
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(
                    (message as FileChatMessage).sessionId,
                    (message as FileChatMessage).mid,
                    {
                        downLocalPath: path,
                    }
                );
                console.log('result=>', result);
                if (result) {
                    await updateSessionMessageMid({
                        sessionId: (message as FileChatMessage).sessionId,
                        sendResult: { body: { ...message, downLocalPath: path } },
                    });
                    console.log('status=>', status, message);
                    setStatus(FileStatus.COMPLETED);
                    setProgress(100);
                    setPath(path);
                    downloadServiceInstance.setDownloadStatus(
                        (message as any).mid,
                        FileStatus.COMPLETED
                    );
                    // if (viewFile) {
                    //     FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    //         if (exists) {
                    //             setTimeout(() => {
                    //             }, 500);
                    //         } else {
                    //             toast.error('文件下载失败');
                    //         }
                    //     });
                    // }
                    setIsDragging(false);
                    if (saveAs) {
                        localStorage.setItem('downloadFilePath', data.saveAs);
                    }
                    // toast.success('下载完成');
                } else {
                    if (!auto) {
                        toast.error(t('failed'));
                    }
                    setIsDragging(false);
                }
                // 下载完成提交下载数据
                if (
                    needSend &&
                    !isEqualEmployee((message as FileChatMessage).sender, currentEmployee)
                ) {
                    handleAddFileDownloadRecord(message as FileChatMessage, currentEmployee);
                }
            },
        };
    };

    const doDownloadMessageFile = ({
        viewFile,
        saveAs,
        message,
        auto = false,
        isGroup,
    }: SaveOption): Promise<void> => {
        const options = getDownloadOptions({ viewFile, saveAs, message, auto, isGroup });
        return downloadServiceInstance.download({ viewFile, saveAs, message, auto }, options);
        // return downloadMessageFile({ viewFile, saveAs, message, auto }, options);
    };

    useEffect(() => {
        if (isFocusEnv()) {
            // 优先判断该文件是否在下载中
            console.log('downloadingMids=>', downloadingMids);
            if (downloadingMids.length && downloadingMids.includes((message as any).mid)) {
                const saveOption = downloadServiceInstance.getSaveOption((message as any).mid);
                if (saveOption) {
                    const options = getDownloadOptions(saveOption);
                    const downloadItem = downloadServiceInstance.getDLServiceItem(
                        (message as any).mid,
                        options
                    );
                    setDownloadItem(downloadItem);

                    setStatus(downloadServiceInstance.getDownloadStatus((message as any).mid));
                }
            } else {
                const downLocalPath = message.downLocalPath;
                console.log('downLocalPath=>', downLocalPath);
                if (downLocalPath) {
                    FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                        console.log('useEffect-exists=>', exists);
                        if (exists) {
                            setStatus(FileStatus.COMPLETED);
                            setPath(downLocalPath);
                            downloadServiceInstance.setDownloadStatus(
                                (message as any).mid,
                                FileStatus.COMPLETED
                            );
                        } else {
                            downloadServiceInstance.setDownloadStatus(
                                (message as any).mid,
                                FileStatus.INIT
                            );
                            setStatus(
                                downloadServiceInstance.getDownloadStatus((message as any).mid)
                            );
                            setPath('');
                        }
                    });
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadingMids, message]);

    useEffect(() => {
        // 自动下载
        if (!selectedSession?.isSecret) {
            console.log(
                'pushAutoDownload=>',
                downloadingMids,
                downloadServiceInstance.getDownloadStatus((message as any).mid)
            );
            const isGroup = selectedSession.isGroup;
            if (!downloadingMids.includes((message as any).mid)) {
                pushAutoDownload(message, currentEmployee, (options: any) =>
                    doDownloadMessageFile({
                        ...options,
                        isGroup,
                    })
                );
            }
        }
        // 切出聊天页，组件销毁时，如果下载未完成，自动终止下载
        return () => {
            dXhr.abort?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getFileConfig = (status: FileStatus): string[] => {
        const data: any = {
            [FileStatus.INIT]: isFocusEnv()
                ? [
                      '',
                      'click-to-view',
                      'click-to-save',
                      'file-info-status-view',
                      'file-info-status-size',
                      'click-to-saveAs',
                  ]
                : [
                      '',
                      '',
                      'click-to-save',
                      'file-info-status-view',
                      'file-info-status-size',
                      'click-to-saveAs',
                  ],
            [FileStatus.DOWNLOADING]: [
                'iconapp_btn_file_cancel',
                'cancel-download',
                'cancel-download',
                'file-info-status-view',
                'file-info-status-ing',
            ],
            [FileStatus.CANCELED]: [
                'iconic_refresh1',
                'download-again',
                'download-again',
                'file-info-status-re-upload',
                '',
            ],
            [FileStatus.FAILED]: [
                'iconic_refresh1',
                'download-again',
                'download-again',
                'file-info-status-re-upload',
                '',
            ],
            [FileStatus.COMPLETED]: isFocusEnv()
                ? ['iconbtn_localfile', 'click-to-view', 'open-folder', 'file-info-status-view', '']
                : [
                      '',
                      '',
                      'click-to-save',
                      'file-info-status-view',
                      'file-info-status-size',
                      'click-to-saveAs',
                  ],
            [FileStatus.DUE]: ['', '', 'file-due', 'file-info-status-due', ''],
        };
        return data[status];
    };

    // 设置消息读取时间
    const handleSetReadTime = async () => {
        const needManualSet =
            selectedSession?.isSecret &&
            sendType === MessageSendType.OTHER &&
            message?.expire &&
            !message.readTimeOfMessage;
        if (!needManualSet) {
            return;
        }
        const readTimeOfMessage = new Date().getTime();
        const imService = ImService.getInstance();
        const result = await imService.updateReadTime(message.sessionId, message.mid, {
            ...message,
            readTimeOfMessage,
        });
        if (result) {
            await updateSessionMessageMid({
                sessionId: message.sessionId,
                sendResult: {
                    body: { ...message, readTimeOfMessage, downLocalPath: path },
                },
            });
            await updateSessionMessagesTimestamp({
                timestamp: new Date().getTime(),
            });
        }
    };

    const fileOperateClsTag = (operateTipKey: string): string | undefined => {
        if (!operateTipKey) {
            return undefined;
        }
        const preStr = 'pageclick|keycount|Xtbg_Msg_Info_File';
        return `${preStr}|${operateTipKey}`;
    };

    const fileOperate = (operateTipKey: string) => {
        // console.log('operateTipKey', operateTipKey);
        const isGroup = selectedSession.isGroup;
        const _path = path ? path : `${defaultDownloadPath}${message.name}`;
        console.log(operateTipKey, 'operateTipKey');
        switch (operateTipKey) {
            case 'click-to-save':
                if (isFocusEnv()) {
                    doDownloadMessageFile({
                        viewFile: false,
                        saveAs: true,
                        message,
                        isGroup,
                    })
                        .then(() => {
                            handleSetReadTime();
                        })
                        .catch((e) => console.log(e));
                } else {
                    // web 端下载文件
                    const name = getFileName(message);
                    const url = getFileUrl(message.url);
                    dXhr = downLoadFile(name, url, '', {
                        onProgress: (currPercent: any) => {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(currPercent);
                        },
                        onFinish: () => {
                            setStatus(FileStatus.COMPLETED);
                            setProgress(100);
                            dXhr = {};
                            toast.success('下载成功');
                            handleSetReadTime();
                            if (isGroup) {
                                handleAddFileDownloadRecord(message, currentEmployee);
                            }
                        },
                    });
                }
                break;
            case 'download-again':
                if (isFocusEnv()) {
                    downloadItem?.resume();
                } else {
                    // web 端下载文件
                    const name = getFileName(message);
                    const url = getFileUrl(message.url);
                    dXhr = downLoadFile(name, url, '', {
                        onProgress: (currPercent: any) => {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(currPercent);
                        },
                        onFinish: () => {
                            setStatus(FileStatus.COMPLETED);
                            setProgress(100);
                            dXhr = {};
                            toast.success('下载成功');
                            handleSetReadTime();
                            if (isGroup) {
                                handleAddFileDownloadRecord(message, currentEmployee);
                            }
                        },
                    });
                }
                break;
            case 'click-to-view':
                if (!isFocusEnv()) {
                    // 网页版下载之后查看
                    const name = getFileName(message);
                    const url = getFileUrl(message.url);
                    dXhr = downLoadFile(name, url, '', {
                        onProgress: (currPercent: any) => {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(currPercent);
                        },
                        onFinish: () => {
                            setStatus(FileStatus.COMPLETED);
                            setProgress(100);
                            dXhr = {};
                            toast.success('下载成功');
                            handleSetReadTime();
                            if (isGroup) {
                                handleAddFileDownloadRecord(message, currentEmployee);
                            }
                        },
                    });
                    return;
                }
                FocusSDK.checkLocalPathExist(_path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.openItem(_path);
                    } else {
                        setStatus(FileStatus.DOWNLOADING);
                        setProgress(0);
                        setTimeout(() => {
                            doDownloadMessageFile({
                                viewFile: true,
                                saveAs: false,
                                message,
                                isGroup,
                            })
                                .then(() => {
                                    handleSetReadTime();
                                })
                                .catch((e) => console.log(e));
                        }, 50);
                    }
                });
                break;
            case 'open-folder':
                if (isFocusEnv() && path) {
                    FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                        if (exists) {
                            FocusSDK.showItemInFolder(path);
                        } else {
                            // setStatus(FileStatus.DOWNLOADING);
                            // setProgress(0);
                            setTimeout(() => {
                                doDownloadMessageFile({
                                    viewFile: true,
                                    saveAs: true,
                                    message,
                                    isGroup,
                                })
                                    .then()
                                    .catch((e) => console.log(e));
                            }, 50);
                        }
                    });
                }
                break;
            case 'cancel-download':
                if (isFocusEnv()) {
                    downloadItem?.pause();
                } else {
                    dXhr.abort?.();
                    setStatus(FileStatus.CANCELED);
                    setProgress(0);
                }
                break;
            default:
                break;
        }
    };

    const onDragStart = (e: any) => {
        if (!isFocusEnv()) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        // console.log('download', 'message', message, 'path', path, 'isDragging', isDragging);
        if (connectState && connectState.state === ConnectState.READY) {
            if (isDragging) {
                toast.warning(t('file-downloading-drag-save'));
                return;
            }
            if (!path) {
                setIsDragging(true);
                toast.warning(t('file-downloading-drag-save'));

                doDownloadMessageFile({ viewFile: false, saveAs: false, message }).then();
            } else {
                FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.startDrag(path);
                    } else {
                        setStatus(FileStatus.DOWNLOADING);
                        setProgress(0);
                        setTimeout(() => {
                            if (isDragging) {
                                toast.warning(t('file-downloading-drag-save'));
                                return;
                            }
                            setIsDragging(true);
                            toast.warning(t('file-downloading-drag-save'));

                            doDownloadMessageFile({
                                viewFile: false,
                                saveAs: false,
                                message,
                            })
                                .then()
                                .catch((e) => console.log(e));
                        }, 50);
                    }
                });
            }
        } else {
            toast.warning(t('drag-after-the-network-is-normal'));
            return;
        }
    };

    const [operateIcon, operateTipKey, operateKey, operateClass, sizeClass, saveAs] = getFileConfig(
        status as FileStatus
    );
    const [fileIcon, progressColor] = getFileIconByMime(lookup(message.name) || '');
    const isDrag =
        FocusSDK.getDeviceInfo().platform !== 'web' &&
        !isMergeForward &&
        !selectedSession?.isSecret;

    return (
        <div
            className="content file"
            draggable={isDrag}
            onDragStart={onDragStart}
            style={{ width: '336px' }}
        >
            <div className="file-content">
                <div
                    className="file-content-name"
                    onClick={() => {
                        fileOperate(operateTipKey);
                    }}
                    clstag={fileOperateClsTag(operateTipKey)}
                    style={isSmall ? { width: '100%', padding: '0' } : {}}
                >
                    {message.name}
                    {/* {formatFileName(message.name)} */}
                </div>
                <div
                    className="file-content-item"
                    style={{
                        width: '100%',
                        position: 'absolute',
                        bottom: '-2px',
                        alignItems: 'center',
                        padding: '0',
                    }}
                >
                    <span className={sizeClass}>{sizeToStr(message.size)}</span>
                    {status === FileStatus.FAILED ? (
                        <div className="file-info-dow">
                            {/* 下载失败 */}
                            <span className="file-sucess">
                                {t('download-error')}
                                <IconFont
                                    type="icona-ic_pc_im_reupload"
                                    className="file-icon-warning"
                                    clstag={fileOperateClsTag(operateKey)}
                                />
                            </span>
                        </div>
                    ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                            {status === FileStatus.COMPLETED ? (
                                <div className="file-info-dow">
                                    {/* 下载成功 */}
                                    <span className="file-sucess">
                                        {t('download-sucess')}
                                        <IconFont
                                            type="iconjs_ic_success"
                                            className="file-icon-sucess"
                                            clstag={fileOperateClsTag(operateKey)}
                                        />
                                    </span>
                                </div>
                            ) : (
                                <span className="file-info-dow">
                                    {t(operateKey) !== '下载' &&
                                        t(operateKey) === '取消下载' &&
                                        t('file-downloading')}
                                </span>
                            )}
                        </>
                    )}
                    {/* <IconFont
                            type="iconjs_ic_warning"
                            className="file-operate"
                            clstag={fileOperateClsTag(operateKey)}
                        /> */}
                    {/* <div className="file-line" style={{ width: '256px' }} /> */}
                </div>
                {/* <div className="file-content-status">
                     <div className="file-info">
                         <div className="file-info-status">
                             {sizeClass && (
                                 <span className={sizeClass}>{sizeToStr(message.size)}</span>
                             )}
 
                             <span
                                 onClick={() => {
                                     fileOperate(operateTipKey);
                                 }}
                                 clstag={fileOperateClsTag(operateTipKey)}
                                 className={operateClass}
                             >
                                 {t(operateTipKey)}
                             </span>
 
                             {saveAs && (
                                 <span
                                     style={{ margin: '10px' }}
                                     onClick={() => {
                                         fileOperate('click-to-save');
                                     }}
                                     clstag={fileOperateClsTag('click-to-save')}
                                     className={operateClass}
                                 >
                                     {isFocusEnv() ? t(saveAs) : '点击保存'}
                                 </span>
                             )}
                         </div>
                     </div>
                     <Tooltip placement="top" title={t(operateKey)}>
                         <IconFont
                             type={operateIcon}
                             className="file-operate"
                             onClick={() => {
                                 fileOperate(operateKey);
                             }}
                             clstag={fileOperateClsTag(operateKey)}
                         />
                     </Tooltip>
                 </div> */}
            </div>
            <img
                src={changeEgovUrl(fileIcon)}
                alt=""
                className="file-icon"
                draggable="false"
                onClick={() => {
                    fileOperate(operateTipKey);
                }}
            />
            {status === FileStatus.DOWNLOADING && (
                <div
                    className="file-img-mask"
                    style={{
                        backgroundImage: `conic-gradient(transparent ${
                            progress * 3.6
                        }deg,  rgba(0, 0, 0, 0.3) ${progress * 3.6}deg)`,
                    }}
                />
            )}
            {/* {status === FileStatus.DOWNLOADING && (
                 <Progress
                     type="line"
                     percent={progress}
                     strokeWidth={3}
                     strokeColor={progressColor}
                     showInfo={false}
                     trailColor="#DEE0E3"
                 />
             )} */}
            {/* 下载 另存为 */}
            {isFocusEnv() ? (
                <div className="file-status-bom">
                    {t(operateKey) === '取消下载' ? null : (
                        <span
                            className="file-status-bom-text"
                            style={{ marginRight: '40px' }}
                            onClick={() => {
                                t(operateKey) === '打开文件夹'
                                    ? fileOperate(operateTipKey)
                                    : t(operateTipKey) === '查看详情'
                                    ? fileOperate(operateKey)
                                    : fileOperate('click-to-save');
                            }}
                        >
                            {t(operateKey) === '打开文件夹' ? t(operateTipKey) : '另存为'}
                        </span>
                    )}
                    <span
                        className="file-status-bom-text"
                        onClick={() => {
                            t(operateKey) === '打开文件夹'
                                ? fileOperate(operateKey)
                                : fileOperate(operateTipKey);
                        }}
                    >
                        {/* {t(operateTipKey)} */}
                        {t(operateKey)}
                    </span>
                </div>
            ) : (
                <div className="file-status-bom">
                    <span
                        className="file-status-bom-text"
                        onClick={() => {
                            t(operateKey) === '下载'
                                ? fileOperate(operateKey)
                                : fileOperate(operateTipKey);
                        }}
                    >
                        {t(operateKey)}
                    </span>
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { connectState, selectedSession, currentEmployee, downloadingMids } = chat as ChatState;
    return { connectState, selectedSession, currentEmployee, downloadingMids };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            return dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
        updateDownloadingMids(data: string[]) {
            dispatch({ type: 'chat/updateDownloadingMids', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DownLoadFile);
