function getVersionBySdk(focusSDK: any) {
    const appInfo = focusSDK.appInfo;
    if (appInfo?.version) {
        return appInfo.version || '';
    }
    if (appInfo && typeof appInfo === 'function') {
        // @ts-ignore
        return appInfo().version || '';
    }
    return '';
}

export function getAppVersion(focusSDK: any) {
    if (focusSDK.getDeviceInfo().platform === 'web') {
        return focusSDK.getVersion();
    }
    return getVersionBySdk(focusSDK);
}

export default getVersionBySdk;
