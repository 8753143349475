import React, { Component } from 'react';
import { getCodeInfo, saveUserSetting, getUserSetting } from '@/api/ebook';
import Modal from 'antd/lib/modal/Modal';
import './index.less';
import IconFont from '@/components/icon';
import { Input, message, Checkbox } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import prompt from '@/baseComponents/ModalComponent/prompt';
import dayjs, { Dayjs } from 'dayjs';
import CommonDatePicker from './CommonDatePicker';
import bus from '@/utils/bus';
import { changeEgovUrl, changeGovUrl } from '@/utils/tools';
export interface WorkStatusItem {
    appId: string;
    codeType: 'workStatus';
    duration: string | null;
    editable: string;
    icon: string;
    selected: boolean;
    statusDesc: string;
    statusId: string;
    workStatus: string;
}

export interface IReplyContent {
    content: string;
    selected: boolean;
}

const NoneStatusId = '1';

interface IState {
    visible: boolean;

    selectTab: WorkStatusItem | null;
    title: string;
    duration: Dayjs | null;
    tempReplyContent: IReplyContent[];

    codeList: WorkStatusItem[];
    atUserInfoList: { userId: string; userName: string }[];
    replyContent: IReplyContent[];
    isChanged: boolean;
    isSetDeadLine: boolean;
}

type IProps = Readonly<WithTranslation>;

class WorkStatusModal extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            codeList: [],
            selectTab: null,
            title: '',
            atUserInfoList: [],
            replyContent: [],
            duration: null,
            tempReplyContent: [],
            isChanged: false,
            isSetDeadLine: false,
        };
        this.toast = debounce(this.toast, 300);
    }

    componentDidMount() {
        this.bindEvents();
    }
    componentWillUnmount() {
        this.unbindEvents();
    }

    bindEvents = () => {
        bus.on('workStatus:show', this.show);
    };
    unbindEvents = () => {
        bus.off('workStatus:show', this.show);
    };

    show = () => {
        this.init();
        this.setState({ visible: true });
    };

    init = () => {
        this.initWorkStatus();
    };

    /**
     * 初始化数据，默认选中当前设置的状态
     */
    initWorkStatus = async () => {
        const [result, err] = await getCodeInfo({ codeType: 'workStatus' });
        if (err) {
            return;
        }
        const { codeList } = result as { codeList: WorkStatusItem[] };
        if (codeList.length === 0) {
            return;
        }
        const index = codeList.findIndex((c: any) => c.selected);
        const selectTab = codeList[index] || codeList[0];
        this.setState({
            codeList,
            selectTab,
            title: selectTab.workStatus,
            duration: selectTab.duration ? dayjs(Number(selectTab.duration)) : null,
            isSetDeadLine: selectTab.duration ? true : false,
        });
    };

    initAutoReply = async () => {
        const [result, err] = await getUserSetting({ settingCode: 'autoReply' });
        if (err) {
            return;
        }
        const { settingContent } = result;
        if (!settingContent) {
            return;
        }
        try {
            const { replyContent = [], atUserInfoList = [] } = JSON.parse(settingContent);
            this.setState({
                replyContent,
                atUserInfoList,
                tempReplyContent: replyContent,
            });
        } catch (error) {}
    };

    changeAutoReply = async (content: IReplyContent[], atUserInfoList: any[] = []) => {
        const [result, err] = await saveUserSetting({
            settingCode: 'autoReply',
            bizChildType: {
                autoReply: {
                    replyContent: content,
                    atUserInfoList: atUserInfoList,
                },
            },
        });
        if (err) {
            message.error(err);
            this.init();
        }
        const { selectTab } = this.state;
        if (selectTab) {
            this.setIsChange(selectTab);
        }
    };

    toast = (type: 'error' | 'success', str: string) => {
        message[type](str);
    };
    /**
     * 发起变更请求
     * 如果报错，则直接重新初始化
     */
    changeSelectStatus = async (item: WorkStatusItem) => {
        const { t } = this.props;
        const [result, err] = await saveUserSetting({
            settingCode: 'workStatus',
            bizChildType: {
                workStatus: { ...item, icon: changeGovUrl(item?.icon) },
            },
        });
        if (err) {
            message.error(err);
            this.init();
            return;
        }
        this.setIsChange(item);
        bus.emit('workStatus:update', item);
        message.success(t('userCard.tips.success'));
    };
    isReplyContentSame = (reply1: IReplyContent[], reply2: IReplyContent[]) => {
        if (reply1.length !== reply2.length) {
            return false;
        }
        const length = reply1.length;
        for (let i = 0; i < length; i++) {
            if (
                reply1[i].content !== reply2[i].content ||
                reply1[i].selected !== reply2[i].selected
            ) {
                return false;
            }
        }
        return true;
    };

    /**
     * 切换侧边栏，非变更个人工作状态
     * 只有 无 这一栏是点击即变更
     */
    handleItemClick = async (item: WorkStatusItem) => {
        const { codeList, selectTab, title, duration, replyContent, tempReplyContent } = this.state;
        const { t } = this.props;
        if (!selectTab) {
            return;
        }
        if (item.statusId === selectTab?.statusId) {
            return;
        }
        const oldItem = codeList.find((i) => i.selected === true) || selectTab;
        const isChanged = await this.setIsChange(item.statusId === '1' ? oldItem : item);
        const applyChangeItem = () => {
            if (item.statusId === NoneStatusId) {
                const index = codeList.findIndex((c) => c.statusId === item.statusId);
                codeList.forEach((c) => {
                    c.selected = false;
                });
                codeList[index].selected = true;
                this.setState({
                    selectTab: { ...item, selected: true },
                    codeList: [...codeList],
                    title: item.workStatus,
                    duration: item.duration ? dayjs(Number(item.duration)) : null,
                    tempReplyContent: replyContent,
                });
                this.changeSelectStatus(item);
                // if (isChanged) {
                //     this.changeSelectStatus(item);
                // }
            } else {
                this.setState({
                    selectTab: item,
                    title: item.workStatus,
                    duration: item.duration ? dayjs(Number(item.duration)) : null,
                    isSetDeadLine: item.duration ? true : false,
                    tempReplyContent: replyContent,
                });
            }
        };
        if (
            selectTab?.workStatus !== title ||
            selectTab.duration !== (duration ? duration.valueOf().toString() : null) ||
            !this.isReplyContentSame(replyContent, tempReplyContent)
        ) {
            prompt({
                title: t('workStatus.un save desc'),
                icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                onOk: async () => {
                    this.handleUseButtonClick();
                },
                onCancel: applyChangeItem,
                content: '',
                okText: t('button.ok'),
                cancelText: t('button.cancel'),
            });
        } else {
            applyChangeItem();
        }
    };

    setIsChange = (item: WorkStatusItem): Promise<boolean> => {
        return new Promise((resolve) => {
            const { codeList, tempReplyContent, replyContent, title, duration } = this.state;
            const index = codeList.findIndex((c) => c.statusId === item.statusId);
            const oldItem = codeList.find((i) => i.selected === true);
            const newItem = codeList[index];
            const isChanged =
                oldItem?.statusId !== newItem?.statusId ||
                oldItem?.workStatus !== newItem?.workStatus ||
                (Number(newItem?.statusId || 0) === 5 && item?.workStatus !== title) ||
                oldItem.duration !== (duration ? duration.valueOf().toString() : null) ||
                !this.isReplyContentSame(replyContent, tempReplyContent);
            this.setState(
                {
                    isChanged,
                },
                () => {
                    resolve(isChanged);
                }
            );
        });
    };

    handleTitleChange = (e: any) => {
        const { t } = this.props;
        const value = e.target.value;
        if (value.length > 50) {
            this.toast('error', t('workStatus.title beyond desc'));
            return;
        }
        this.setState(
            {
                title: value,
            },
            () => {
                if (this.state.selectTab) {
                    this.setIsChange(this.state.selectTab);
                }
            }
        );
    };
    handleTitleBlur = (e: any) => {
        const { t } = this.props;
        const { selectTab } = this.state;
        if (!selectTab) {
            return;
        }
        const value = e.target.value.trim();
        this.setIsChange(selectTab);
        if (!value) {
            this.setState({
                title: selectTab?.workStatus,
            });
        } else {
            this.setState({
                title: value,
            });
        }
    };

    /**
     * 点击使用按钮，生成新的所有被选中的内容，保存并选中当前内容和状态
     */
    handleUseButtonClick = () => {
        const {
            codeList,
            selectTab,
            title,
            tempReplyContent,
            duration,
            replyContent,
            isChanged,
            isSetDeadLine,
        } = this.state;
        if (!selectTab) {
            return;
        }
        if (
            isSetDeadLine &&
            Number(duration?.valueOf().toString()) - Number(dayjs().valueOf().toString()) < 60 * 5
        ) {
            message.warn('持续时间太短，请重新选择！');
            return;
        }
        const index = codeList.findIndex((c) => c.statusId === selectTab.statusId);
        codeList.forEach((c) => {
            c.selected = false;
        });
        const newTab = {
            ...selectTab,
            workStatus: title.trim() || selectTab.workStatus,
            duration: duration?.valueOf().toString() || null,
            selected: true,
        };
        codeList[index] = newTab;

        this.setState({
            selectTab: newTab,
            title: newTab.workStatus,
            codeList: [...codeList],
        });
        if (isChanged) {
            this.changeSelectStatus(newTab);
        }
        if (!this.isReplyContentSame(tempReplyContent, replyContent)) {
            this.setState({
                replyContent: tempReplyContent,
            });
            this.changeAutoReply(tempReplyContent);
        }
    };

    handleDeleteReplyContent = (select: number) => {
        const { tempReplyContent } = this.state;

        const newTemp = [...tempReplyContent];
        newTemp.splice(select, 1);
        const index = newTemp.findIndex((t) => t.selected);
        if (index === -1 && newTemp.length !== 0) {
            newTemp[0].selected = true;
        }
        this.setState({
            tempReplyContent: newTemp,
        });
    };

    /**
     * 负责回复内容的切换
     */
    handleReplyContentClick = (select: number) => {
        const { tempReplyContent } = this.state;
        const index = tempReplyContent.findIndex((t) => t.selected);

        if (select === index) {
            return;
        }
        const newTemp = tempReplyContent.map((t) => {
            return {
                ...t,
                selected: false,
            };
        });
        newTemp[select].selected = true;
        this.setState({
            tempReplyContent: newTemp,
        });
    };

    handleDurationCheck = (e: any) => {
        if (e.target.checked) {
            this.setState(
                {
                    duration: null,
                    isSetDeadLine: e.target.checked,
                },
                () => {
                    const { selectTab } = this.state;
                    if (selectTab) {
                        this.setIsChange(selectTab);
                    }
                }
            );
        } else {
            this.setState(
                {
                    isSetDeadLine: e.target.checked,
                    duration: null,
                },
                () => {
                    const { selectTab } = this.state;
                    if (selectTab) {
                        this.setIsChange(selectTab);
                    }
                }
            );
        }
    };

    handleSetDuration = (time: Dayjs | null) => {
        this.setState(
            {
                duration: time,
            },
            () => {
                const { selectTab } = this.state;
                if (selectTab) {
                    this.setIsChange(selectTab);
                }
            }
        );
    };

    onClose = () => {
        this.setState({
            visible: false,
            selectTab: null,
            codeList: [],
            replyContent: [],
            atUserInfoList: [],
            title: '',
            duration: null,
            tempReplyContent: [],
        });
    };

    render() {
        const {
            visible,
            codeList,
            selectTab,
            title,
            duration,
            isChanged,
            isSetDeadLine,
        } = this.state;
        const { t } = this.props;
        if (!visible) {
            return null;
        }
        return (
            <Modal
                visible={visible}
                width="720px"
                destroyOnClose={true}
                onCancel={this.onClose}
                style={{
                    height: 480,
                }}
                centered
                bodyStyle={{ padding: 0 }}
                footer={null}
                getContainer={() => document.querySelector('.appContent') || document.body}
                wrapClassName="work-status-modal-wrapper"
            >
                <div className="work-status">
                    <div className="work-status-title">
                        <span className="title-label">{t('workStatus.work status')}</span>
                        <span style={{ flex: 1 }} />
                        <span className="action-close" />
                    </div>
                    <div className="work-status-container">
                        <div className="work-status-left">
                            {codeList.map((code) => {
                                return (
                                    <div
                                        className={`work-status-left-item ${
                                            selectTab?.statusId === code.statusId
                                                ? 'work-status-left-item-selected'
                                                : ''
                                        }${
                                            code.editable === '0'
                                                ? ' work-status-left-item-default'
                                                : ''
                                        }
                                        `}
                                        key={code.statusId}
                                        onClick={() => this.handleItemClick(code)}
                                    >
                                        <div className="item-firstline">
                                            {code.icon && (
                                                <img
                                                    className="item-icon"
                                                    src={changeEgovUrl(code.icon)}
                                                />
                                            )}
                                            <span className="item-title">{code.workStatus}</span>
                                            {code.selected && <IconFont type="iconapp_ic_check" />}
                                        </div>
                                        {code.duration && (
                                            <div className="item-lastline">
                                                {t('workStatus.State continues until')}{' '}
                                                {dayjs(Number(code.duration)).format('MM-DD HH:mm')}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="work-status-right">
                            {selectTab ? (
                                selectTab?.statusId === NoneStatusId ? (
                                    <div className="no-status-container">
                                        <span>{t('workStatus.no status desc')}</span>
                                    </div>
                                ) : (
                                    <>
                                        <Input
                                            className="input-title"
                                            value={title}
                                            placeholder={t(
                                                'workStatus.Please enter working status'
                                            )}
                                            disabled={selectTab.editable === '0'}
                                            onChange={this.handleTitleChange}
                                            allowClear
                                            onBlur={this.handleTitleBlur}
                                        />
                                        <div className="duration-label">
                                            {t('workStatus.duration time')}
                                        </div>
                                        <CommonDatePicker
                                            time={duration}
                                            onChange={this.handleSetDuration}
                                            placement="bottomLeft"
                                        >
                                            <div
                                                className={`duration-content ${
                                                    // duration ? '' : 'duration-content-disable'
                                                    isSetDeadLine ? '' : 'duration-content-disable'
                                                }`}
                                            >
                                                <span className="duration-time">
                                                    {duration
                                                        ? duration.format('YYYY-MM-DD HH:mm')
                                                        : t('workStatus.Please select the date')}
                                                </span>
                                                <span style={{ flex: 1 }} />
                                                <IconFont type="iconic_schedule" />
                                            </div>
                                        </CommonDatePicker>
                                        <div className="duration-checkBox">
                                            <Checkbox
                                                checked={isSetDeadLine}
                                                onChange={this.handleDurationCheck}
                                            />
                                            <span className="duration-checkBox-label">
                                                {t('workStatus.No deadline')}
                                            </span>
                                        </div>
                                        <div
                                            className={
                                                !isChanged || (isSetDeadLine && !duration)
                                                    ? 'action-use disable'
                                                    : 'action-use'
                                            }
                                            onClick={debounce(this.handleUseButtonClick, 300)}
                                        >
                                            {t('workStatus.button-use')}
                                        </div>
                                    </>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
export default withTranslation('common')(WorkStatusModal);
