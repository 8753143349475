import { isSelf, UserModel, UserState } from '@/models/user';
import {
    getVirtualUserDetail,
    getIsAddFriends,
    saveEUserSetting,
    removeEFriend,
    getAllRelationShip,
} from '@/api/ebook';
import UserCard from '@/components/UserCard';
import { message as toast, message, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppState } from '@/models';
import { Employee, Session, UID } from '@/types/chat';
import { connect } from 'dvajs';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import { UserCardData, UserCardAction } from '@/types/UserCard';
import { mapUserDataToUid } from '../chat/utils/message';
import { useHistory, useLocation } from 'react-router-dom';
import Bus from '@/utils/bus';
import ImService from '@/server/ImService';
import RosterApplyStatus from '@jd/jdee.im.sdk/lib/es/enum/RosterApplyStatus';
import { EbookState } from '@/models/ebook';
import { FocusSDK, isFocusEnv, JoyMeetingStatusEnum } from '@/utils';
import GlobalContext from '@/context/GlobalContext';
import { ConferenceApplyRequest, ConferenceType } from '../Voip/types';
import dayjs from 'dayjs';
import useShareUserCard from '@/components/Modals/useShareUserCard';
import ChatState from '@/types/chat/State';
import { getRelationship } from '@/api/chat';
import lodashPick from 'lodash/pick';
import { acceptFriend, LabelInfoRes } from '@/components/AddressBook/common';
import { openUserSelectionModal } from '@/utils/modals';
import cache from '@/utils/cache';
import { createMeeting } from '@/api/meeting';
import { getPreviewImageModal } from '../chat/utils/commonMessageModal';
import { handleCreateGroupXCMeeting } from '../BigScreen/common';
import { NetState } from '@/models/netStatus';
import { analysisLog } from '@/utils/logAnalytics';
import { AVSDK } from '@/baseComponents/Meeting/common';
// import { NetType } from '@/components/Modals/ChangeNetModal';

const logger = log.getLogger('UserCard');

interface Props {
    userData: UserModel;
    selectedSession: Session;
}
export interface contactRelation {
    friendship: number;
    relation: number;
    contactType: any;
    isEnableAddFriend: boolean;
    isOnlyScanAdd: boolean;
}

type IProps = Readonly<Props & AppState & EbookState & ChatState>;

function UserCardModal(
    props: IProps & {
        pushChatMessage: Function;
        openCreateContactModal: Function;
        addUserLabelNameModelVisible: boolean;
        openCreateAddUserLabelNameModel: Function;
        setOpenAdministratorListModal: Function;
    } & NetState
) {
    const {
        userData,
        modals,
        currentEmployee,
        openCreateContactModal,
        setOpenAdministratorListModal,
        selectedSession,
        addUserLabelNameModelVisible,
        openCreateAddUserLabelNameModel,
        newPublicResult,
        newPrivateResult,
    } = props;
    const { appId, userId, teamId } = modals.userCard;
    const { t } = useTranslation('common');
    const [t_chat] = useTranslation('chat');
    const [t_meeting] = useTranslation('meeting');
    const { imService } = useContext(GlobalContext);
    const [voipClick, SetVoipClick] = useState(false);

    const [friendErrMsg, setFriendErrMsg] = useState<string>('');
    const [isfriend, setIsFriend] = useState<Number>(-1);
    const [visibleFriends, setVisibleFriends] = useState(false);
    const [contactRelationship, setContactRelationship] = useState<contactRelation>();

    const history = useHistory();
    const location = useLocation();

    const shareUserCard = useShareUserCard({ userData, currentEmployee });

    useEffect(() => {
        if (FocusSDK.isPrivateNet()) {
            FocusSDK.checkUrlOnline({
                type: 'zoomNet',
                isPrivateNet: true,
            });
        }
    }, []);

    // 拉起会话
    const launchSession = (userCardData: UserCardData) => {
        logger.debug('startSessionFromUserCardCb ==>', userCardData);
        const { app, pin, teamId } = mapUserDataToUid(userCardData);
        Bus.emit('app:toggle-user-card-modal:hide');
        // tj: 判断是否是当前会话, 解决当前会话跳转不获取消息
        // TODO: 单聊密聊会话跳转 参数添加isSecret 按道理说个人卡片跳转的是普通单聊
        const path = `/messages/s/${pin}?app=${app}&tid=${teamId}`;
        const currPath = location.pathname;
        const currQuery = location.search.replace('&top=true', '');
        if (path !== `${currPath}${currQuery}`) {
            setTimeout(() => {
                if (!pin) return;
                history.push(`${path}&top=true`);
            }, 1);
        }
        return { close: true, data: true };
    };

    // 申请加群
    const applyAddGroup = async (data: any) => {
        // return true;
        const user: UID = {
            app: userData.teamUserInfo.ddAppId || userData.ddAppId,
            teamId: userData.teamUserInfo.teamId,
            pin: userData.userId,
            name: userData.teamUserInfo.realName || userData.realName || '',
        };
        const groupId = data.group?.id;
        if (!groupId) {
            return false;
        }
        const imService = ImService.getInstance();
        const reason = data.group?.validationMsg || '';
        const result = await imService.applyAddGroup(groupId, [user], 'application', reason);

        // if (isFocusEnv()) {
        //     return { close: false, data: result };
        // }
        //
        // // return result;
        return { close: false, data: result };
    };

    const joinGroup = async (data: any) => {
        const { id } = data.group;
        const user: UID = {
            app: userData.teamUserInfo.ddAppId || userData.ddAppId,
            teamId: userData.teamUserInfo.teamId,
            pin: userData.userId,
            name: userData.teamUserInfo.realName || userData.realName || '',
        };
        const imService = ImService.getInstance();
        const result = await imService.joinGroup(id, user);
        if (result === 0) {
            Bus.emit('app:toggle-user-card-modal:hide');
            setTimeout(() => {
                history.push(`/messages/g/${id}?top=true`);
            }, 1);
        } else if (result < 0) {
            message.error(t('search.operate_failed'));
        } else if (result.toString() === '1103006') {
            // 人数太多
            message.warn('search.max_group_rosters');
        } else if (result.toString() === '1103036') {
            // 是入审批流程
            // openGroupCardModal(item, [UserCardAction.SendApplyGroup]);
            // 发送审批
            return true;
        } else {
            message.error(t('search.operate_failed'));
        }
    };

    // 申请好友
    const applyFriend = async (data: UserCardData, accessPhone = '') => {
        const imService = ImService.getInstance();
        const user: UID = { app: data.appId, teamId: data.teamId, pin: data.userId };
        // 获取好友关系
        const [ship] = await imService.getContactShip([user]);
        const relationship = ship.relationship;
        if (relationship.friendship === 1) {
            toast.warn(t('userCard.friend-ship'));
            // return false;
            return { close: false, data: false };
        }

        const [result, err]: any = await imService.applyFriend(
            user,
            data.validationMsg,
            accessPhone
        );

        // const result = await imService.applyFriend(user, data.validationMsg);
        if (result) {
            toast.success(t('userCard.tips.sendSuccess'));
        } else {
            toast.error(err);
            // toast.error(t('userCard.tips.sendFailed'));
        }
        // if (isFocusEnv()) {
        //     return { close: false, data: result };
        // }
        // return result;
        return { close: false, data: result };
    };

    // 接受 - 加为好友
    const doAcceptFriend = async (data: UserCardData, accessPhone?: '') => {
        const user: UID = { app: data.appId, teamId: data.teamId, pin: data.userId };
        const [result, err] = await acceptFriend(user, RosterApplyStatus.Approved, accessPhone);
        // const result = await acceptFriend({ user, status: RosterApplyStatus.Approved });q`
        if (result) {
            toast.success(t('userCard.tips.success'));
            // if (!isFocusEnv()) {
            Bus.emit('app:toggle-user-card-modal:hide');
            Bus.emit('app:toggle-user-card-modal:update-new-contacts', user);
            // }
        } else {
            toast.error(err);
            // toast.error(t('userCard.tips.failed'));
        }
        // return result;
        return { close: true, data: result };
    };

    const handleCreateMeeting = useCallback((data: any) => {
        // console.log('信创视频会议2222', data);
        // if (data?.memberType === 1) {
        //     console.log('人员2222', {
        //         ...data,
        //         userName: data.fields.filter((item: any) => item.key === 'name')[0].val,
        //     });
        //     createCloudLinkSingleCall({
        //         ...data,
        //         userName: data.fields.filter((item: any) => item.key === 'name')[0].val,
        //     });
        // } else {
        //     console.log('设备2222', data?.terminalId);
        //     createCloudLinkTerminalCall([data.terminalId]);
        // }
        handleCreateGroupXCMeeting([data], data?.memberType || 1);
    }, []);

    // 创建视频会议
    const handleCreateGroupMeeting = useCallback(
        (data: UserCardData) => {
            if (FocusSDK.isPrivateNet()) {
                if (newPublicResult) {
                    message.warn(t('joinMeeting.change public net'));
                } else {
                    message.warn(t('joinMeeting.private net'));
                }

                return;
            }
            const fields: { [propName: string]: any } = {};
            (data.fields || []).forEach((fieldItem: any) => {
                fields[fieldItem.key] = fieldItem.val;
            });
            const ToUser: any = {
                realName: fields.name,
                teamId: data.teamId,
                userId: data.userId,
                appId: data.appId,
                headImg: fields.avatar,
            };

            async function prepareCreateMeeting(data: any) {
                if (!data.isFree) {
                    message.error(t_chat('joyMeeting.There is a meeting going on'));
                    return;
                }
                // 发送消息到桌面端
                const [result, err] = await createMeeting({
                    startTime: (dayjs().valueOf() + 5000).toString(),
                    duration: 2,
                    topic: t_chat('joyMeeting.meetingTopicDesc')
                        .replace('%from', currentEmployee.name)
                        .replace('%to', ToUser.realName),
                    password: '',
                    participantIds: [
                        ToUser,
                        {
                            realName: currentEmployee.name,
                            teamId: currentEmployee.teamId,
                            userId: currentEmployee.userId,
                            appId: currentEmployee.app,
                            headImg: currentEmployee.avatar,
                        },
                    ],
                });
                if (err) {
                    // 如果请求创建报错，则清空会议状态
                    FocusSDK.sendIpcMessage(
                        'MeetingControl:changeJoyMeetingStatus',
                        JoyMeetingStatusEnum.None
                    );
                    logger.error('user card create meeting error：', err);
                    return toast.error(t_chat('joyMeeting.create meeting failed'));
                }
                Bus.emit('meeting:create single call', {
                    toUser: {
                        username: ToUser.realName,
                        teamId: ToUser.teamId,
                        userId: ToUser.userId,
                        app: ToUser.appId,
                        pin: ToUser.userId,
                        avatar: ToUser.headImg,
                    },
                    meetingData: result,
                });
            }
            if (!cache.zoomLogin) {
                setTimeout(() => {
                    if (!cache.zoomLogin) {
                        toast.error(t_chat('joyMeeting.meeting initialization failure'));
                        return;
                    }
                    FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', prepareCreateMeeting);
                    FocusSDK.sendIpcMessage(
                        'MeetingControl:changeJoyMeetingStatus',
                        JoyMeetingStatusEnum.SingleCall
                    );
                }, 2000);
                return;
            }
            FocusSDK.once('MeetingControl:changeJoyMeetingStatusCb', prepareCreateMeeting);
            FocusSDK.sendIpcMessage(
                'MeetingControl:changeJoyMeetingStatus',
                JoyMeetingStatusEnum.SingleCall
            );
        },
        [currentEmployee, t, t_chat, newPublicResult]
    );

    function startVoip(data: UserCardData) {
        message.info(t_meeting('meeting initialization'));
        // 处理一下连点场景
        if (voipClick) {
            return;
        }
        SetVoipClick(true);
        setTimeout(() => {
            SetVoipClick(false);
        }, 5000);
        const { app, pin, teamId, name } = mapUserDataToUid(data);
        const { userId, teamUserInfo } = userData;
        const sessionId = imService.generateSingleSessionId(
            // @ts-ignore
            { app: teamUserInfo.ddAppId, userId, teamId: teamUserInfo.teamId },
            { app, pin, teamId }
        );
        const opts: Partial<ConferenceApplyRequest> = {
            topic: t_chat('voip.meetingTopicDesc')
                .replace('%from', currentEmployee.name)
                .replace('%to', name || ''),
            conference_type: ConferenceType.kConferenceSingle,
            sid: sessionId, //
            start: dayjs().valueOf(),
            // duration: '1.0',
        };
        FocusSDK.sendIpcMessage('voip:createSingleCall', {
            request: opts,
            invite: {
                member_app_: app,
                member_id_: pin,
                team_id_: teamId,
            },
        });
    }

    const startJrtc = useCallback(
        (data: any, flag = false) => {
            const { app, pin, avatar, teamId, name = '' } = mapUserDataToUid(data);
            let meetingTopic = flag
                ? t_chat('joyMeeting.voiceTopicDesc')
                : t_chat('joyMeeting.meetingTopicDesc');
            Bus.emit('meeting:create-single-call', {
                avsdk: AVSDK.JBRTC,
                toUser: {
                    pin,
                    teamId,
                    name,
                    app,
                    avatar,
                },
                meetingInfo: {
                    meetingTopic: meetingTopic
                        .replace('%from', currentEmployee.name)
                        .replace('%to', name),
                },
                type: flag === true ? 'voice' : 'meeting',
            });
            setTimeout(() => {
                closeCard();
            }, 500);
        },
        [currentEmployee.name, t_chat]
    );

    const actionClick = async (type: UserCardAction, data: UserCardData, accessPhone?: '') => {
        openCreateContactModal({ visible: false }); // 添加联系人modal关闭
        setOpenAdministratorListModal(false); // 系统管理员列表modal关闭

        switch (type) {
            case UserCardAction.Chat:
                launchSession(data);
                Bus.emit('onHideGlobalSearch');
                return;
            case UserCardAction.Send:
                return await applyFriend(data, accessPhone);
            case UserCardAction.Accept:
                return await doAcceptFriend(data, accessPhone);
            case UserCardAction.AddGroup:
                return await applyAddGroup(data as any);
            case UserCardAction.JoinGroup:
                return await joinGroup(data as any);
            case UserCardAction.Voip:
                // startVoip(data);
                startJrtc(data, true);
                return;
            case UserCardAction.Jrtc:
                startJrtc(data);
                return;
            case UserCardAction.Meeting:
                handleCreateGroupMeeting(data);
                return;
            case UserCardAction.Share:
                await shareUserCard(data as any);
                return;
            case UserCardAction.XCMeeting:
                await handleCreateMeeting(data as any);
                return;
            case UserCardAction.Deleted:
                await removeFriend();
                return;

            default:
                break;
        }
        // if (isFocusEnv()) {
        //     return { close: true, data: true };
        // }
        // return true;
        return { close: true, data: true };
    };

    const closeCard = () => {
        Bus.emit('app:toggle-user-card-modal:hide', {});
    };

    const getIsSelf = (userData: UserModel, userCard: any) => {
        const data = {
            ddAppId: userCard.appId,
            userId: userCard.userId,
            selectedTeamId: userCard.teamId,
        };
        return isSelf(userData, data);
    };

    const onClickAvatar = (data: any) => {
        let url = data?.fields.filter((item: any) => item.key === 'avatar')[0]?.val;
        let fileName = data?.fields.filter((item: any) => item.key === 'name')[0]?.val;
        analysisLog('xtbg_add_home_1637657110163', '43');
        getPreviewImageModal({
            message: { muuid: 1, url: url, fileName: fileName },
            isMergeForward: true,
            modalMessages: [{ url: url, fileName: fileName, muuid: 1, kind: 'image' }],
        });
    };

    const saveUserSetting = async (params: { settingCode: string; settingContent: string }) => {
        try {
            await saveEUserSetting(params);
            toast.success(t('userCard.saveUserSettingOk'));
        } catch (e) {
            toast.error(t('userCard.saveUserSettingFailed'));
        }
    };
    const removeFriend = async () => {
        Modal.confirm({
            zIndex: 2000,
            title: t('userCard.tip'),
            content: t('userCard.removeTips'),
            okText: t('button.ok'),
            cancelText: t('button.cancel'),
            async onOk() {
                try {
                    const imService = ImService.getInstance();
                    await imService.setRemoveFriend({ pin: userId, app: appId, teamId: teamId });
                    Bus.emit('friend:removeFriend', { userId: userId, app: appId, teamId: teamId });
                    toast.success(t('userCard.removeFriendOk'));
                } catch (e) {
                    toast.error(t('userCard.removeFriendFailed'));
                }
                closeCard();
            },
        });
    };

    const openEditLabelModel = (data: LabelInfoRes[], user: UserCardData) => {
        Bus.emit('open:edit-user-label-model', { ...user, currentUserLabelInfoList: data });
    };

    const openCreateLabelNameModel = (user: UserCardData) => {
        openCreateAddUserLabelNameModel({ visible: true, labelNameUserDetail: user });
    };

    useEffect(() => {
        if (!appId || !userId || !teamId) {
            return;
        }
        setIsFriend(-1);
        setFriendErrMsg('');
        setVisibleFriends(false);
        // app相同 teamId相同，可以直接会话
        // if (appId === currentEmployee.app && teamId === currentEmployee.teamId) {
        //     setIsFriend(true);
        //     return;
        // }
        getIsFriends(appId, userId, teamId);
    }, [appId, userId, teamId]);

    async function getIsFriends(appId: string, userId: string, teamId: string) {
        let [AllRelationShip, err] = await getAllRelationShip({
            oppoUserId: userId,
            oppoTeamId: teamId,
        });

        log.info('getAllRelationShip', AllRelationShip, err);
        if (err) {
            setFriendErrMsg(err);
            // setIsFriend(0);
            return;
        }
        const {
            contactType,
            friendship,
            isEnableAddFriend,
            isOnlyScanAdd,
            relation,
        } = AllRelationShip;

        // 是否可添加好友 isEnableAddFriend
        // 好友关系 friendship
        // 同事关系 relation
        // 是否只能扫码添加 isOnlyScanAdd
        // 联系人类型 contactType

        setContactRelationship(AllRelationShip);

        // friendShip:0无友谊（路人），1好友，2黑名单，4被关注（B的好友列表中有A，但是A的好友列表没有B），8单相思（A的好友列表有B，但是B的好友列表没有A）
        // relation:1同事，2陌生人

        // isAddFriends  false 不需要加好友 有可见关系(可见)  true 需要加好友 没有可见关系(不可见)
        // 好友关系如果可见 并且 是同事或者好友 不用添加好友

        if (isEnableAddFriend) {
            if (friendship === 0 || friendship === 4) {
                setIsFriend(0);
                return;
            } else if (friendship === 1 || friendship === 8) {
                setIsFriend(1);
                return;
            }
        } else {
            // 组织可见联系人进行添加联系人的操作
            if (friendship === 1 || friendship === 8) {
                setIsFriend(1);
                return;
            } else if (friendship === 0 || friendship === 4) {
                setVisibleFriends(true);
                setIsFriend(1);
                return;
            } else {
                setIsFriend(0);
                return;
            }
        }
    }

    useEffect(() => {
        const listener = ({ close, data }: any) => {
            // logger.debug('open-user-card listener 1111 ==>', data, close);
            actionClick(data.action, data).then((result) => {
                // logger.debug('open-user-card listener 2222 ==>', result);
                close(result);
            });
        };
        Bus.on('open-user-card', listener);

        return () => {
            Bus.off('open-user-card', listener);
        };
    }, [history]); // eslint-disable-line

    // 关闭时 人员卡片全部销毁
    if (!modals.userCard.visible) {
        return null;
    }

    return (
        <Modal
            visible={modals.userCard.visible}
            closable={false}
            onCancel={closeCard}
            footer={null}
            bodyStyle={{
                padding: 0,
            }}
            wrapClassName="me-user-card-modal"
            style={{
                width: 330,
            }}
            // maskStyle={{
            //     backgroundColor: 'auto',
            // }}
            mask={true}
            maskStyle={{
                backgroundColor: 'transparent',
            }}
            transitionName=""
            maskTransitionName=""
            destroyOnClose
        >
            <UserCard
                friendErrMsg={friendErrMsg}
                contactRelationship={contactRelationship ? contactRelationship : {}}
                isRemove={
                    (contactRelationship?.friendship === 1 ||
                        contactRelationship?.friendship === 8) &&
                    !getIsSelf(userData, modals.userCard)
                }
                visible={modals.userCard.visible}
                isSelf={getIsSelf(userData, modals.userCard)}
                isfriend={isfriend}
                visibleFriends={visibleFriends}
                data={modals.userCard}
                t={t}
                onActionClick={actionClick}
                onModifySetting={saveUserSetting}
                // isShowStar={false}
                onGetUserInfo={async (params: {
                    userId: string;
                    teamId: string;
                    appId: string;
                    rootOrgId?: string;
                }) => {
                    let result: any;
                    if (params.rootOrgId) {
                        result = await getVirtualUserDetail(params);
                    } else {
                        const imService = ImService.getInstance();
                        result = await imService.getEmployeeDetail(
                            lodashPick(params, ['appId', 'userId', 'teamId'])
                        );
                    }
                    return result || null;
                }}
                onUploadAvatar={() => {}}
                // onRemoveFriend={removeFriend}
                onClickAvatar={onClickAvatar}
                onClickLabelModel={openEditLabelModel}
                onClickCreateLabelNameModel={openCreateLabelNameModel}
                // currentDataIndex={0}
            />
        </Modal>
    );
}

function mapStateToProps({
    user,
    app,
    chat,
    ebook,
    netStatus,
}: {
    chat: ChatState;
    user: UserState;
    app: AppState;
    ebook: EbookState;
    netStatus: NetState;
}) {
    return {
        userData: user.userData.user,
        modals: app.modals,
        currentEmployee: chat.currentEmployee,
        selectedSession: chat.selectedSession,
        addUserLabelNameModelVisible: ebook.label.addUserLabelNameModelVisible,
        newPublicResult: netStatus.newPublicResult,
        newPrivateResult: netStatus.newPrivateResult,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateContactModal(data: any) {
            dispatch({ type: 'ebook/openCreateContactModal', payload: data });
        },
        setOpenAdministratorListModal(payload: boolean) {
            dispatch({ type: 'ebook/setOpenAdministratorListModal', payload });
        },
        openCreateAddUserLabelNameModel(data: any) {
            dispatch({ type: 'ebook/openCreateAddUserLabelNameModel', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCardModal);
