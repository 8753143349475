
export default {
  gray: '#8F959E',
  lightGray: '#F8F9FA',
  primary: '#31C4CC',
  primary2: window.styleSass.primaryColor,
  second: '#EAF9F9',
  black: '#242931',
  splitLine: '#F0F3F3', // #DEE0E3 F0F3F3
  // blue: '#4C7CFF',
  blue: window.styleSass.primaryColor,
  line: {
    light: '#E0E1E3'
  },
  white: '#fff',
  danger: '#f96137',
  warn: '#FFB416',
  black2: '#232930'
};
