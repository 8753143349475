import CopyClipboard from 'copy-to-clipboard';
import { message as toast } from 'antd';
import { judgeHttpCardLinkUrl } from '@/utils/tools';
import i18n from '@/i18n';

export function handleOpenUrl(e: any) {
    if (
        e.target &&
        e.target.nodeName?.toUpperCase() === 'A' &&
        e.target.getAttribute('href') === 'javascript:void(0)'
    ) {
        e.stopPropagation();
        e.preventDefault();
        let data = null;
        try {
            data = JSON.parse(
                e.target.getAttribute('data-event-params') || e.target.dataset.eventParams
            );
        } catch (e) {}
        if (!data || (data && !data.url)) {
            return;
        }
        const isOpen = judgeHttpCardLinkUrl(data.url);
        if (!isOpen) {
            if (data.url && CopyClipboard(data.url)) {
                toast.success('地址已复制到剪切板，请在浏览器中打开。');
            } else {
                toast.error(i18n.t('chat:copy-failed'));
            }
            return;
        }
        window.open(data.url);
    }
}
