import { message } from 'antd';
import { IPageAddCallback, api } from './index';
import { openModalTextBox } from '../components/ModalTextBox';
import { PageType } from '../const';
import { lang } from '../context';
import events from '../context/events';

const rename: IPageAddCallback = (props, isAllow) => {
    const { data, setData } = props;
    if (data.page_type !== PageType.File) {
        return;
    }
    return [
        {
            children: lang('ctx.rename.title'),
            callbackOrModal: () => {
                openModalTextBox({
                    title: lang('ctx.rename.title'),
                    value: data.title,
                    maxLength: 50,
                    onOk: (value, close) => {
                        api.page.rename(data.id, value).then((data) => {
                            // console.log(data, 'datadatadatadata===>');
                            if (data.code === '10002') {
                                message.error('文件后缀名有误，请确认后重试');
                                return;
                            }
                            if (data) {
                                const newData = {
                                    ...props.data,
                                    title: value,
                                };
                                events.emit('list.removeOrSetData', [newData], 'setData');
                                events.emit('list.reload');
                                message.success(lang('common.success'));
                            } else {
                                message.error(lang('common.suffix-error'));
                            }
                            // 新名称不能立即生效，需要延迟刷新
                            // setTimeout(reload, 1500);
                            close();
                        });
                    },
                });
            },
            isAllow,
            type: 'rename',
            icon: 'pc_yundoc_rename',
            splitCode: 1,
        },
    ];
};

export default rename;
