import React from 'react';
import i18n from '@/i18n';
import { Spin } from 'antd';
import './index.less';
import { LoadingOutlined } from '@ant-design/icons';
import Loading from '@/components/Loading';
export default function LoadMore({
    loading,
    clickHandle,
}: {
    loading: boolean;
    clickHandle: () => void;
}) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 12,
            }}
        >
            <p onClick={clickHandle} style={{ marginRight: 6, cursor: 'pointer' }}>
                {i18n.t('supervise:loadMore')}
            </p>
        </div>
    );
}
export function MiddleLoading() {
    return (
        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: 'calc(50% + 145px)',
                transform: 'translate(-50%,-50%)',
            }}
        >
            <Loading />
        </div>
    );
}
