import { ContentItem } from '@/components/chat/utils/message';
import React from 'react';
import './index.less';
import JoyspaceTopicPng from '@/assets/images/joyspace-topic.png';
import { Popover } from 'antd';
import JoyspaceFile from '../JoyspaceFile';
import JoyspaceFileMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/message/JoyspaceFile';
import { ChatMessageType, SessionType } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
interface IProps {
    content: ContentItem;
    message: any;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
    updateSessionMessageMid?: Function;
    isMergeForward?: boolean;
}
function JoyspaceTopic(props: IProps) {
    // const {
    //     fileName,
    //     webUrl,
    //     pageType,
    //     content,
    //     iconUrl,
    //     // @ts-ignore
    //     sender,
    //     // @ts-ignore
    //     senderTeamId,
    //     // @ts-ignore
    //     permission = 2,
    //     sText,
    //     sMid,
    //     replyMsgs = [],
    // } = props.message;
    const {
        content,
        message,
        sessionType,
        sendType,
        onReady,
        updateSessionMessageMid,
        isMergeForward = false,
    } = props;
    const ext = JSON.parse(content?.ext || '{}');
    // console.log(message, 'message======================>');
    let _message = {
        ...message,
        content: content.url,
        ...ext,
        // senderTeamId: message?.sender?.teamId || '',
        type: ChatMessageType.TEXT,
        fileName: ext.title,
        replyMsgs: message.replyMsgs,
        webUrl: content.url,
    };
    // console.log(_message, 'messagemessagemessage===>', content?.ext);
    const hoverContent = (
        <JoyspaceFile
            sessionType={sessionType}
            sendType={sendType}
            onReady={onReady}
            updateSessionMessageMid={updateSessionMessageMid}
            message={_message}
        />
    );
    return (
        <div className="joyspace-topic-box">
            <img src={JoyspaceTopicPng} />
            <Popover
                content={hoverContent}
                // trigger="click"
                arrowPointAtCenter
                overlayClassName={
                    isMergeForward
                        ? 'joyspace-topic-popover joyspace-topic-footer'
                        : 'joyspace-topic-popover'
                }
            >
                <span className="joyspace-topic-box-content">{content.content}</span>
            </Popover>
        </div>
    );
}

export default JoyspaceTopic;
