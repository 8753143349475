import { IPageAddCallback, api } from './index';
import { message, Modal } from 'antd';
import { lang } from '../context';
import events from '../context/events';
import SyncJoySpaceController from '../controllers/SyncJoySpaceController';
import { SyncStatusEunm } from '../controllers/JoyspaceController';

const { confirm } = Modal;

const remove: IPageAddCallback = (props, isAllow, isZhuanban?: boolean) => {
    const { data, remove } = props;
    // if (!data.perms?.PAGE_REMOVE) {
    //     return;
    // }
    return [
        {
            children: lang('common.delete'),
            callbackOrModal: () => {
                confirm({
                    title: `是否确认删除"${data.title ? data.title : '无标题'}" 文档？`,
                    content: '删除的文件可在回收站找回',
                    // content: isZhuanban
                    //     ? '确认删除后，文档将不可恢复。'
                    //     : lang('ctx.deleteConfirm'),
                    okText: lang('common.confirm'),
                    cancelText: lang('common.cancel'),
                    async onOk() {
                        // 同步失败,同步异常可以删除
                        if (
                            data.isNew &&
                            (data.syncStatus === SyncStatusEunm.ERROR ||
                                data.syncStatus === SyncStatusEunm.DATAERROR)
                        ) {
                            const result = await SyncJoySpaceController.removeDb(data.id);
                            if (result) {
                                events.emit('list.reload');
                            }
                            return;
                        }
                        api.page.remove(data.space_id, data.id).then((data) => {
                            if (data) {
                                events.emit('list.reload');
                                events.emit('list.removeOrSetData', [props.data], 'delete');
                                events.emit('shortcut.remove', [props.data.id]);
                                message.success(lang('common.success'));
                                // reload();
                            } else {
                                message.error(lang('common.fail'));
                            }
                        });
                    },
                });
            },
            isAllow,
            type: 'delete',
            icon: 'pc_yundoc_recycle',
            splitCode: 3,
        },
    ];
};

export default remove;
