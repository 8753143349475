import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { Button, Modal } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { useTranslation } from 'react-i18next';
import logger from '@/utils/logger';
import { CloseCircleOutlined } from '@ant-design/icons';
import EventBus from '@/utils/bus';
import { isFocusEnv, getLoginURL } from '@/utils';

export default function ExitTipModal() {
    const globalContext = useContext<GlobalContextConfig>(GlobalContext);
    const { focusSDK } = globalContext;
    const { t } = useTranslation('common');
    const [visible, setVisible] = useState(false);
    const [msgText, setMsgText] = useState('');
    const accountTip = useMemo(() => {
        if (msgText) {
            return msgText;
        }
        return t('account-off-line-tips');
    }, [t, msgText]);
    const confirm = () => {
        setTimeout(() => {
            // tj: 延迟退出，解决页面卡死
            focusSDK.printLog('ExitTipModal', '--------');
            if (!isFocusEnv()) {
                focusSDK.emit('logout');
            } else {
                focusSDK.logout();
            }
        }, 100);
    };

    useEffect(() => {
        const forceQuitListener = (msg: any) => {
            const { code, msgtext } = msg;
            if ([210003, '210003'].includes(code)) {
                setMsgText(msgtext);
            } else {
                if (msgtext.indexOf('冻结') > -1) {
                    setMsgText(msgtext);
                } else {
                    setMsgText('');
                }
            }
            const nodes = document.querySelectorAll('.ant-modal-root');
            // tj: 打开名片时，互踢假死
            if (nodes && nodes.length > 0) {
                nodes.forEach((node) => {
                    node.setAttribute('style', 'display:none');
                });
            }
            EventBus.emit('app:toggle-user-card-modal:hide', {});
            focusSDK.printLog('logout setVisible true');
            setVisible(true);
            // if (!isFocusEnv()) {
            //     focusSDK.clearCookies();
            // }
        };

        EventBus.off('chat:force_quit', forceQuitListener).on('chat:force_quit', forceQuitListener);
        return () => {
            EventBus.off('chat:force_quit', forceQuitListener);
        };
    }, [focusSDK]);

    return (
        <Modal
            title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CloseCircleOutlined
                        style={{ fontSize: '18px', color: 'red', marginRight: '10px' }}
                    />
                    <div>{t('account-off-line')}</div>
                </div>
            }
            width={450}
            closable={false}
            visible={visible}
            maskClosable={false}
            keyboard={false}
            okText={t('button.ok')}
            footer={
                <Button type="primary" onClick={confirm}>
                    {t('button.ok')}
                </Button>
            }
            zIndex={2000}
        >
            <p>{accountTip}</p>
        </Modal>
    );
}
