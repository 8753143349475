import { IconSearch } from '@/components/icon';
import React from 'react';

export default function DetailSearchBtn({ text, onClick }: { text: string; onClick?: () => void }) {
    return (
        <div
            className="flex-layout-center-left"
            style={{
                lineHeight: '40px',
                padding: '0 24px',
            }}
        >
            <div
                className="color-primary"
                style={{
                    fontSize: 14,
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                {IconSearch}
                &nbsp;
                {text}
            </div>
        </div>
    );
}
