import {
    Button,
    Drawer,
    message,
    Tabs,
    Tooltip,
    Input,
    Modal,
    Dropdown,
    Menu,
    Checkbox,
    Collapse,
    Progress,
    Select,
    Radio,
    Slider,
} from 'antd';
const { Panel } = Collapse;
const { Option } = Select;
import { connect } from 'dva';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    createContext,
    useReducer,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import IconFont, {
    IconTaskCommunicate,
    IconTaskPreserve,
    IconTaskProceed,
    IconTaskRestart,
} from '@/components/icon';
// import BasicInfo from './components/BasicInfo';
import AdvanceRecord from './components/AdvanceRecord';
import ExecutorsProgress from './components/ExecutorsProgress';
import Comment from '../Comment';
import { EsuperviseSourceType, ICreateSuperviseData } from '@/types/supervise';
import GlobalContext from '@/context/GlobalContext';
import { createTaskGroup, deleteTask, getTaskDetail, modifyTaskInfo } from '@/api/work';
import {
    findSelfInExecutors,
    getTimeClassifyByTime,
    getDefaultStartTime,
    getDefaultStopTime,
} from '../utils';
import { FocusSDK } from '@/utils';
import { changeEgovUrl, htmlUnescape } from '@/utils/tools';
import ChatMain from '@/baseComponents/Chat/view/Main';
import UserList from '../BaseComponents/UserList';
import { number2percentum, percentum2number } from '../BaseComponents/WorkUserListWeightSet';
import WorkAttachmentComp from '../BaseComponents/WorkAttachmentComp';
import UserStatusListPopover from '../UserStatusListPopover/filter';
import DatePicker from '../BaseComponents/DatePicker';

import { openUserSelectionModal } from '@/utils/modals';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import Loading from '@/components/Loading';
import './index.less';
import {
    NewTaskModel,
    PRIORITY_TYPE,
    PROCESS_STATUS,
    TaskListParams,
    TaskResource,
    TASK_STATUS,
} from '@/types/newWork';
import {
    decorateTaskDetail,
    getTaskStatus,
    judgeTaskFinishOrStop,
    judgeTaskFinishAndStop,
} from '../newUtils';
import { nanoid } from 'nanoid';
import { getFileIconByMime, mattchUrl } from '@/utils/chat/index';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import bus from '@/utils/bus';
import { TaskPartEnum, TaskResourceBizType, TaskResourcePermissionType } from '@/types/work';
import { remindList } from '../CreateTaskDraw/index';
import {
    CaretLeftOutlined,
    CaretRightOutlined,
    CommentOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import CryptoJS from 'crypto-js';
import { ChatMessageBody, Session } from '@/types/chat';
import {
    PRIORITY_TYPE_MAP,
    PROCESS_STATUS_LIST,
    TASK_DO_STATUS_LIST,
    PROCESS_STATUS_EXECUTORS,
} from '@/components/Projects/emnu';
import config, { ConfigEnum } from '@/config/config';
import { ShareRecipientDto, shareScheduleAttachment } from '@/api/calendar';
import syncConfirm from '@/utils/syncConfirm';
// import ImService from '@/server/ImService';
import { getGroupInfo } from '@/api/egroup';
import Prompt from '@/baseComponents/ModalComponent/prompt';
// import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import debounce from 'lodash/debounce';
let clickFn: undefined | Function;
// 立即沟通按钮的节流
const debounceFn = debounce(
    function () {
        clickFn?.();
    },
    999,
    { leading: true, trailing: false }
);
// 替换其他任务详情之后是否打开聊天抽屉
let openChatSoon = false;

const FORCE_GET = '1cf07dd502458e65675172405c2dc3db';

enum FileStatus {
    Init = 1,
    Uploading,
    Done,
    failed,
}
interface IState {
    fileList: any[];
}
const initialState: IState = {
    fileList: [],
};

function reducer(state: IState, action: any) {
    const { payload } = action;
    switch (action.type) {
        case 'changeState':
            return { ...state, ...payload };
        case 'reloadAttchment': // 多端同步时，处理一下正在上传的文档
            // eslint-disable-next-line no-case-declarations
            const fileList: any[] = state.fileList.filter(
                (file: any) => file.status === FileStatus.Uploading
            );
            payload.list.forEach((l: any) => {
                fileList.push({
                    fileName: l.name,
                    size: l.size,
                    fileType: l.fileType,
                    fileId: l.bizId || nanoid(20),
                    fileUrl: l.url,
                    percent: 0,
                    status: FileStatus.Done,
                    resourceId: l.resourceId,
                    icon: getFileIconByMime(l.fileType),
                    creatorUserId: l?.creatorUserId,
                });
            });
            return { ...state, fileList };
        default:
            throw new Error();
    }
}

export const TaskDetailContext = createContext<{
    taskDetail: NewTaskModel;
    setTaskDetail: any;
    closeDrawAndRefresh: Function;
}>({} as any);

// 进度条+滑动输入条
export function ProgressSliderCOMPLEX({
    max, // 个人权重数：整数
    current, // 百分比，如 10， 代表用户进度为 10%
    totalWeight,
    setter,
    className = '',
    isDisabled = false,
    maxpercent = 100,
}: {
    max: number;
    maxpercent?: number;
    current: number;
    totalWeight: number;
    setter: Function;
    className: string;
    isDisabled?: boolean;
}) {
    let initialVal = current;
    const [val, setVal] = useState(current);
    useEffect(() => {
        setVal(current > maxpercent ? maxpercent : current || 0);
    }, [current]); // eslint-disable-line
    const tipFormatter = useCallback(
        (v) => {
            let zhi = v - current;
            if (zhi && Number.isFinite(zhi)) {
                return `推进至 ${v}%，占总任务的 ${number2percentum(
                    (v * max * 0.01) / totalWeight
                )}`;
                // return `推进 ${zhi}%，占总任务的 ${number2percentum(
                //     (zhi * max * 0.01) / totalWeight
                // )}`;
            }
            let cur = current;
            if (!Number.isFinite(current)) {
                cur = 100;
            }
            return `当前进度为 ${cur}%，占总任务的 ${number2percentum(
                (cur * max * 0.01) / totalWeight
            )}`;
        },
        [max, current, totalWeight]
    );
    const onChange = (v: any) => {
        console.log(v, 'vvvvvvvvvvvvvvvvvvvvvvvvv');
        setVal(v);
        setter(v);
    };
    return (
        <div className={['lb-however', className].join(' ')}>
            <div className={'progress-slider-complex' + (val !== current ? ' changed' : '')}>
                <Slider
                    value={val}
                    max={100}
                    tipFormatter={tipFormatter}
                    onChange={onChange}
                    disabled={isDisabled}
                />
                <Progress percent={initialVal} size="small" showInfo={false} />
            </div>
            <span>{`${val}%`}</span>
        </div>
    );
}

// eslint-disable-next-line complexity
function TaskDetailDraw({
    userData,
    drawVisible,
    taskId,
    forceGet,
    chat,
    detailStateActiveTab,
    changePersonalTaskDraw,
    changeEditTaskDraw,
    openCreateSuperviseModal,
    getTasks,
    offset,
    sortType,
    offsets,
    processStatuses,
    taskStatuses,
    priorityTypes,
    userRole,
    launchGroupSession,
    updateSelectedSession,
    selectedSession,
    scheduleInfoDraw,
    scheduleInfoDrawOther,
    changeScheduleInfoDraw,
    changeScheduleInfoDrawOther,
}: {
    drawVisible: boolean;
    userData: any;
    taskId: string;
    chat: boolean;
    forceGet: boolean;
    detailStateActiveTab: string;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    changePersonalTaskDraw: (
        taskId: string,
        visible: boolean,
        activeTab?: string,
        chat?: boolean
    ) => void;
    changeEditTaskDraw: (
        taskId: string,
        visible: boolean,
        taskDetail?: NewTaskModel | null
    ) => void;
    getTasks: (params: TaskListParams) => void;
    offset: number;
    offsets: number[];
    processStatuses: PROCESS_STATUS[];
    taskStatuses: TASK_STATUS[];
    priorityTypes: PRIORITY_TYPE[];
    userRole: number;
    sortType: number;
    launchGroupSession: Function;
    updateSelectedSession: Function;
    selectedSession: Session;
    scheduleInfoDraw: any;
    scheduleInfoDrawOther: any;
    changeScheduleInfoDraw: (payload: { visible: boolean; scheduleId: string }) => void;
    changeScheduleInfoDrawOther: (payload: { visible: boolean; scheduleId: string }) => void;
}) {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation('work');
    const [t_chat] = useTranslation('chat');
    const [t_common] = useTranslation('common');
    const { imService, authInfo } = useContext(GlobalContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    // 更改标题栏的背景
    const titleBlock = useRef(null);
    const [titleBlockClassname, setTitleBlockClassname] = useState('');
    const [intersectionObserver, __] = useState(function () {
        return new IntersectionObserver(
            function (entries) {
                console.log('Loaded new items', entries[0]);
                // If intersectionRatio is 0, the target is out of view
                // if (entries[0].intersectionRatio <= 0) {
                //     setTitleBlockClassname('whitening');
                // } else {
                //     setTitleBlockClassname('');
                // } isIntersecting
                setTitleBlockClassname(!entries[0].isIntersecting ? ' whitening' : '');
            },
            { threshold: [0.329] }
        );
    });
    const [taskDetail, setTaskDetail] = useState<NewTaskModel | null>(null);
    const [taskDetailCopy, setTaskDetailCopy] = useState<NewTaskModel | null>(null);
    const [activeTab, setActiveTab] = useState('advanceRecord');
    const [title, setTitle] = useState('');
    const [comment, setComment] = useState('');
    // const [titleInputVisible, setTitleInputVisible] = useState(false);
    const [chatVisable, setChatVisable] = useState(false);
    const [stopTaskVisible, setStopTaskVisible] = useState(false);
    const [textareaCommentFocus, setTextareaCommentFocus] = useState(false);
    const { accessToken, teamUserInfo, userId, ddAppId } = authInfo;
    const [loadingChat, setLoadingChat] = useState(false);

    const [promptDisband, setPromptDisband] = useState(false);
    const [remindArr, setRemindArr] = useState<string[]>(
        taskDetail?.remind ? taskDetail.remind.split(',') : []
    );
    // 管理者维护任务总状态
    const [isPushingWhole, setIsPushingWhole] = useState(false);
    const [taskStatus, setTaskStatus] = useState(taskDetail?.taskStatus || -1);

    // 个人推进
    const [isPushing, setIsPushing] = useState(false);
    const [progressMy, setProgressMy] = useState(taskDetail?.progress || 0);
    const [processStatus, setProcessStatus] = useState<PROCESS_STATUS | -1>(
        taskDetail?.processStatus || -1
    );
    const [submitting, setSubmitting] = useState(false);
    const [pullRecords, setPullRecords] = useState(1);

    const [remarkInputVisible, setRemarkInputVisible] = useState<boolean>(false);
    const [remark, setRemark] = useState(taskDetail?.remark?.remark || '');
    // 评论总条数
    const [countOfComment, setCountOfComment] = useState<number>(0);

    const showChat = useCallback(() => {
        if (!chat) {
            return false;
        }
        // 没有协同人不显示
        if (!taskDetail?.executors || !taskDetail.executors.length) {
            return false;
        }
        // 负责人和协同人只有一个，不显示
        if (
            taskDetail.executors.length === 1 &&
            taskDetail.executors[0].userId === taskDetail?.owner.userId
        ) {
            return false;
        }
        // 在协同人列表或者是负责人才显示
        return (
            taskDetail?.executors.map((item) => item.userId).includes(authInfo.userId) ||
            taskDetail?.owner.userId === authInfo.userId
        );
    }, [authInfo.userId, chat, taskDetail?.executors, taskDetail?.owner?.userId]);
    const handleLoadChat = useCallback(
        async (sid: string) => {
            // IM模块
            if (history.location.pathname.match(/messages/)) {
                if (history.location.pathname.includes(sid)) {
                    history.push(`/messages/`);
                    setTimeout(() => {
                        history.push(`/messages/g/${sid}`);
                    }, 200);
                } else {
                    history.push(`/messages/g/${sid}`);
                }
                return;
            }
            // 任务模块
            if (sid) {
                await launchGroupSession({
                    sid,
                });
                setLoadingChat(false);
            }
            setChatVisable(true);
        },
        [launchGroupSession, history]
    );
    // 弹窗确认是否替换当前 日程 的详情页
    function replaceCalendarDetailDraw() {
        // 检测一下是否日程打开的
        let scheduleId;
        let type = '';
        if (scheduleInfoDraw?.visible && scheduleInfoDrawOther?.visible) {
            let scheduleInfoCur;
            if (scheduleInfoDraw.get() > scheduleInfoDrawOther.get()) {
                scheduleInfoCur = scheduleInfoDraw;
                type = 'scheduleInfoDraw';
            } else {
                scheduleInfoCur = scheduleInfoDrawOther;
                type = 'scheduleInfoDrawOther';
            }
            scheduleId = scheduleInfoCur.scheduleId;
        } else if ((scheduleId = scheduleInfoDraw?.scheduleId)) {
            type = 'scheduleInfoDraw';
        } else if ((scheduleId = scheduleInfoDrawOther?.scheduleId)) {
            type = 'scheduleInfoDrawOther';
        }
        if (!scheduleId) {
            return Promise.resolve(1);
        }

        return new Promise<any>((resolve, reject) => {
            Modal.confirm({
                title: t('tip'),
                content: (
                    <span className="nimble-con">
                        是否进入该任务的沟通组？
                        <br />
                        确认后原日程详情和沟通组将关闭。
                    </span>
                ),
                className: 'task-common-modal-lb',
                okText: '确认',
                cancelText: '取消',
                onCancel: () => {
                    resolve(0);
                },
                onOk: () => {
                    if (type === 'scheduleInfoDrawOther') {
                        changeScheduleInfoDrawOther({
                            visible: false,
                            scheduleId: '',
                        });
                    } else {
                        changeScheduleInfoDraw({
                            visible: false,
                            scheduleId: '',
                        });
                    }
                    resolve(1);
                },
            });
        });
    }
    const handleChat = useCallback(
        async (ignore = false) => {
            window.console.log(77777);
            let deng = await replaceCalendarDetailDraw();
            if (!taskDetail || !showChat() || deng === 0) {
                return;
            }
            updateSelectedSession({ selectedSession: {} });
            setLoadingChat(true);

            let needCreate = !taskDetail.groupId;
            if (taskDetail.groupId) {
                // 判断群是否已解散
                const groupInfo = await getGroupInfo({
                    groupIds: [{ groupId: taskDetail.groupId }],
                });

                const isDisband = groupInfo.groups[0].status !== 1;
                const isOutOfGroup = groupInfo.groups[0].flag !== 3;
                if (isDisband) {
                    // 先判断已解散
                    if (ignore === true) {
                        setLoadingChat(false);
                        return;
                    }
                    const reCreate = await syncConfirm('该组已解散，是否重新创建组');
                    if (!reCreate) {
                        setLoadingChat(false);
                        return;
                    }
                    needCreate = true;
                } else if (isOutOfGroup) {
                    // 未解散，不在群内
                    if (ignore === true) {
                        setLoadingChat(false);
                        return;
                    }
                    const reJoin = await syncConfirm('您不在组内，是否选择加入？');
                    if (reJoin) {
                        const joinRes = await imService.joinGroup(taskDetail.groupId, {
                            app: ddAppId,
                            pin: userId,
                            teamId: teamUserInfo.teamId,
                        });
                        if (joinRes !== 0) {
                            setLoadingChat(false);
                            return message.error('重新加入失败');
                        }
                    }
                }
            }
            if (needCreate) {
                const [res, err] = await createTaskGroup({
                    taskId,
                    from: {
                        app: process.env.REACT_APP_DD_APPID || 'shb',
                        pin: taskDetail.owner.userId,
                        teamId: taskDetail.owner.teamId,
                        userId: taskDetail.owner.userId,
                    },
                    body: {
                        modifyFiled: 1,
                        nickName: taskDetail.owner.realName,
                        name: taskDetail.title.substring(0, 50),
                        notice: '',
                        gid: '',
                        invitees: taskDetail.executors.map((i: any) => {
                            return {
                                pin: i.userId,
                                app: process.env.REACT_APP_DD_APPID || 'shb',
                                nickName: i.realName,
                                teamId: i.teamId,
                                userId: i.userId,
                            };
                        }),
                    },
                    kind: 1024,
                    subKind: 6,
                });
                if (err) {
                    setLoadingChat(false);
                    return message.error(err);
                }
                taskDetail.groupId = res.groupId;
                handleLoadChat(taskDetail.groupId || '');
            } else {
                handleLoadChat(taskDetail.groupId || '');
            }
            window.console.log(9999);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            taskDetail,
            showChat,
            updateSelectedSession,
            imService,
            ddAppId,
            userId,
            teamUserInfo.teamId,
            taskId,
            handleLoadChat,
        ]
    );
    // 当聊天的sessionId发生变化，聊天窗口的‘开启/关闭’需要处理
    useEffect(() => {
        if (selectedSession?.sessionId && chatVisable) {
            if (showChat()) {
                setChatVisable(true);
            } else {
                setChatVisable(false);
            }
        }
    }, [selectedSession?.sessionId]); // eslint-disable-line

    // fix:评论任务后im抽屉会关闭重新打开。 同时也做到：任务详情默认不展开聊天
    // useEffect(() => {
    //     if (taskDetail?.groupId && chat) {
    //         handleChat(true);
    //     }
    // }, [chat, handleChat, taskDetail]);
    useEffect(() => {
        setCountOfComment(0);
        setTaskDetailCopy(taskDetail);
        if (taskDetail) {
            setRemindArr(
                taskDetail.remind
                    ? taskDetail.remind.split(',').sort((a, b) => Number(b) - Number(a))
                    : []
            );
            setTaskStatus(taskDetail.taskStatus || -1);
            setProcessStatus(taskDetail.processStatus || -1);
            setProgressMy(taskDetail.progress || 0);
            setRemark(taskDetail?.remark?.remark || '');
        }
    }, [taskDetail]);

    useEffect(() => {
        if (!drawVisible && location.pathname.includes(`projects`)) {
            updateSelectedSession({ selectedSession: {} });
        }
    }, [updateSelectedSession, drawVisible, location.pathname]);

    useEffect(() => {
        changePersonalTaskDraw('', false);
    }, [changePersonalTaskDraw, location]);

    useEffect(() => {
        let tem = titleBlock.current;
        if (drawVisible && tem) {
            // window.console.log(444, titleBlock);
            intersectionObserver.observe(tem);
            return () => {
                // window.console.log(555, tem);
                intersectionObserver.unobserve(tem!);
            };
        }
    }, [drawVisible, titleBlock.current]); // eslint-disable-line

    // 弹窗确认是否替换当前 任务 的详情页
    function replaceWorkDetailDraw() {
        return new Promise<any>((resolve, reject) => {
            Modal.confirm({
                title: t('tip'),
                content: (
                    <span className="nimble-con">
                        是否进入该任务详情页？
                        <br />
                        确认后原任务详情页将关闭。
                    </span>
                ),
                className: 'task-common-modal-lb',
                okText: '确认',
                cancelText: '取消',
                onCancel: () => {
                    changePersonalTaskDraw(taskDetailCopy!.taskId, true, activeTab, true);
                    reject(new Error());
                },
                onOk: () => {
                    // 如果是从任务沟通群组分享过来的 则先关闭任务详情Draw
                    // TODO
                    setChatVisable(false);
                    resolve(1);
                },
            });
        });
    }

    /**
     * 初始化数据
     */
    useEffect(() => {
        if (!taskId) {
            if (drawVisible) {
                message.error('无效的任务id');
                changeEditTaskDraw('', false);
                return;
            }
            setTaskDetail(null);
            return;
        }
        if (!drawVisible) {
            return;
        }
        if (detailStateActiveTab) {
            setActiveTab(detailStateActiveTab);
        }

        let share = '';
        if (forceGet) {
            share = CryptoJS.AES.encrypt(
                `share:${accessToken}`,
                CryptoJS.enc.Utf8.parse(FORCE_GET),
                {
                    iv: CryptoJS.enc.Utf8.parse('0102030405060708'),
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                }
            ).toString();
        }
        window.console.log('5555555', taskId, taskDetailCopy);
        let p;
        if (taskId && chatVisable && taskDetailCopy?.taskId) {
            if (taskId !== taskDetailCopy?.taskId) {
                p = replaceWorkDetailDraw();
            } else {
                return;
            }
        } else {
            p = Promise.resolve(0);
        }
        p.then((v) => {
            if (v === 1) {
                openChatSoon = true;
            } else {
                openChatSoon = false;
            }
            getTaskDetail(taskId, share).then(([result, err]) => {
                if (err || !result || (result as any).content === null) {
                    message.warn((err || (result as any).errorMsg) as string);
                    changePersonalTaskDraw('', false);
                    return;
                }
                const task = decorateTaskDetail(result as NewTaskModel);
                setTaskDetail(task);
                setTaskDetailCopy(taskDetail);
                setTitle(task.title);
                initFileList(task.resources || []);
                setRemark(task?.remark?.remark || '');
                setPullRecords(pullRecords + 1);
                if (task.processStatus === PROCESS_STATUS.NOT_VIEWED) {
                    handleModifyDetailInfo({
                        taskId: taskId,
                        processStatus: PROCESS_STATUS.PUSHING,
                    });
                }
            });
        }).catch((v) => {
            window.console.log('cancel');
        });
    }, [taskId, drawVisible, changePersonalTaskDraw, detailStateActiveTab, forceGet, accessToken]); // eslint-disable-line react-hooks/exhaustive-deps
    /**
     * 监听多端同步
     * 单独处理一下正在上传的附件
     * TODO: 需要根据实际情况处理
     */
    useEffect(() => {
        function reloadDetail(msg: any) {
            const { data, content } = msg;
            if (content?.includes('deleteTask')) {
                console.warn('删除任务', msg);
                return;
            }
            if (!data) {
                console.error('------->多端同步消息错误', msg);
                return;
            }
            const { timeclassify, part } = data;
            if (!taskId) {
                setTaskDetail(null);
                return;
            }
            if (!drawVisible) {
                return;
            }
            if (
                ![
                    TaskPartEnum.PERSONAL_TASK,
                    TaskPartEnum.MEANWHILE_TASK,
                    TaskPartEnum.PROJECT_TASK,
                ].includes(part)
            ) {
                return;
            }
            if (taskId !== data.taskId && Number(timeclassify) !== 999) {
                return;
            }
            let share = '';
            if (forceGet) {
                share = CryptoJS.AES.encrypt(
                    `share:${accessToken}`,
                    CryptoJS.enc.Utf8.parse(FORCE_GET),
                    {
                        iv: CryptoJS.enc.Utf8.parse('0102030405060708'),
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7,
                    }
                ).toString();
            }

            getTaskDetail(taskId, share).then(([result, err]) => {
                if (err || !result || (result as any).content === null) {
                    message.warn((err || (result as any).errorMsg) as string);
                    changePersonalTaskDraw('', false);
                    return;
                }
                const task = decorateTaskDetail(result as NewTaskModel);
                setTaskDetail(task);
                setTitle(task.title);
                dispatch({
                    type: 'reloadAttchment',
                    payload: {
                        list: task.resources || [],
                    },
                });
            });
        }
        function handleNetworkChange(data: any) {
            const { connectState } = data;
            if (connectState.state === ConnectState.READY) {
                reloadDetail({ data: { taskId } });
            }
        }

        function onPromptDisbandChange(e: any) {
            setPromptDisband(e.target.checked);
        }
        async function imSpecialDisbandGroupEvent({ businessId, businessType, callback }: any) {
            if (businessType === 'work') {
                Prompt({
                    icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                    title: '解散组提醒',
                    content: (
                        <div>
                            {t_chat('dismiss-group-prompt')}
                            <div>
                                <Checkbox
                                    style={{ margin: '5px 5px 0 0' }}
                                    onChange={onPromptDisbandChange}
                                />
                                同时删除任务
                            </div>
                        </div>
                    ),
                    cancelText: '取消',
                    onOk: async () => {
                        if (promptDisband) {
                            await deleteTask({ taskId: businessId, type: 1, delGroup: false });
                        }
                        callback();
                    },
                    okText: '确定',
                });
            }
        }

        bus.on(`chat_message_multiSync_event:joyWork`, reloadDetail);
        bus.on('chat:connect_state_change', handleNetworkChange);
        bus.on('im_special_disband_group_event', imSpecialDisbandGroupEvent);
        return () => {
            bus.off(`chat_message_multiSync_event:joyWork`, reloadDetail);
            bus.off('chat:connect_state_change', handleNetworkChange);
            bus.off('im_special_disband_group_event', imSpecialDisbandGroupEvent);
        };
    }, [
        taskId,
        drawVisible,
        changePersonalTaskDraw,
        forceGet,
        accessToken,
        handleChat,
        t,
        promptDisband,
        t_chat,
    ]);

    function initFileList(list: TaskResource[]) {
        const fileList = list.map((l) => {
            return {
                fileName: l.name,
                size: l.size,
                fileType: l.fileType,
                fileId: l.bizId || nanoid(20),
                fileUrl: l.url,
                percent: 0,
                status: FileStatus.Done,
                resourceId: l.resourceId,
                icon: getFileIconByMime(l.fileType),
                creatorUserId: l?.creatorUserId,
            };
        });
        dispatch({
            type: 'changeState',
            payload: {
                fileList,
            },
        });
    }

    const [isHead, self, isOnlySelf] = useMemo(() => {
        if (!taskDetail) {
            return [false, null, false];
        }
        let isHead = false;
        let self = null;
        let isOnlySelf = false;
        const header = findSelfInExecutors([taskDetail.owner], userData);
        if (header) {
            isHead = true;
        }
        self = findSelfInExecutors(taskDetail.executors, userData);
        if (self && taskDetail.executors.length === 1) {
            isOnlySelf = true;
        }
        return [isHead, self, isOnlySelf];
    }, [taskDetail, userData]);

    const isTaskFinishOrStop = useMemo(() => {
        if (!taskDetailCopy) {
            return false;
        }
        let isTaskFinishOrStop = false;
        if (judgeTaskFinishOrStop(taskDetailCopy.taskStatus)) {
            isTaskFinishOrStop = true;
        }
        return isTaskFinishOrStop;
    }, [taskDetailCopy]);
    const [isTaskFinish, isTaskStop] = useMemo(() => {
        if (!taskDetail) {
            return [];
        }
        let isTaskFinishAndStop = judgeTaskFinishAndStop(taskDetail.taskStatus);
        return isTaskFinishAndStop;
    }, [taskDetail]);

    const canCheckMyProgress = useMemo(() => {
        // 当前任务是负责人或者协同人时没有完成任务或者终止任务 可以提交个人的推进说明
        return !isTaskFinishOrStop && (isHead || self);
    }, [isTaskFinishOrStop, isHead, self]);

    const getTaskList = useCallback(() => {
        getTasks({
            limit: offsets ? offsets.length * 20 : 20,
            offset: 0,
            // offsets: offsets,
            processStatuses: processStatuses,
            taskStatuses: taskStatuses,
            priorityTypes: priorityTypes,
            userRole: userRole,
            sortType,
        });
    }, [getTasks, offsets, processStatuses, taskStatuses, priorityTypes, userRole, sortType]);

    const closeDraw = useCallback(() => {
        bus.emit('close-task-detail');
        setChatVisable(false);
        setIsPushingWhole(false);
        setIsPushing(false);
        setTaskDetail(null);
        // setTitleInputVisible(false);
        changePersonalTaskDraw(taskId, false, 'advanceRecord');
        setActiveTab('advanceRecord');
    }, [changePersonalTaskDraw, taskId]);

    const handleEditTask = useCallback(() => {
        setTaskDetail(null);
        setChatVisable(false);
        setActiveTab('advanceRecord');
        changeEditTaskDraw(taskId, true, taskDetail);
    }, [changeEditTaskDraw, taskId, taskDetail]);

    // 催办
    const handleDuClick = useCallback(
        (userList0?: any[]) => {
            if (!taskDetail) {
                return;
            }
            let userList: any[] = [];
            if (Array.isArray(userList0) && userList0.length > 0) {
                userList = userList0;
            } else {
                [taskDetail.owner, ...taskDetail.executors].forEach((user) => {
                    const { userId, teamId, app, headImg, realName } = user;
                    const index = userList.findIndex(
                        (u: any) =>
                            `${u.teamId}:${u.appId}:${u.userId}` ===
                            `${user.teamId}:${user.app}:${user.userId}`
                    );
                    if (index === -1) {
                        userList.push({
                            userId,
                            teamId,
                            appId: app,
                            headImg,
                            realName,
                        });
                    }
                });
            }

            openCreateSuperviseModal({
                visible: true,
                createData: {
                    id: taskDetail.taskId,
                    title: taskDetail.title,
                    userList,
                    from: EsuperviseSourceType.WORK,
                },
            });
        },
        [openCreateSuperviseModal, taskDetail]
    );

    const handleShareClick = useCallback(() => {
        const { teamUserInfo, userId } = userData;
        const { ddAppId, teamId, realName } = teamUserInfo;
        if (!taskDetail) {
            return;
        }
        openUserSelectionModal(
            {
                title: t('share to friend'),
                tabs: !config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recent', 'groupCanBeSel', 'externalContacts']
                    : ['recent', 'org', 'groupCanBeSel', 'externalContacts'],
                // tabs: ['recent', 'org', 'group', 'externalContacts'],
                // frozenIds: list.map((item) => item.userId + ':' + item.app + ':' + item.teamId),
                // resultUsers: list.map((item) => ({
                //     id: item.userId + ':' + item.app + ':' + item.teamId,
                //     name: item.realName,
                //     avatar: item.headImg,
                //     checked: true,
                //     userId: item.userId,
                //     teamId: item.teamId,
                //     visible: false,
                // })),
                contactsFilterFlag: 'external',
                currentUser: {
                    app: ddAppId,
                    pin: userId,
                    teamId: teamId,
                },
            },
            async (data, close) => {
                // 过滤掉已选中的
                const { result } = data.data;
                if (!result.length) {
                    return close();
                }
                if (!taskDetail) {
                    return;
                }
                const addArr: any[] = [];
                const groupArr: any[] = [];
                const originGroupArr: any[] = [];

                result.forEach((r) => {
                    if (r.isGroup) {
                        originGroupArr.push(r);
                        return groupArr.push(r.id);
                    }
                    if (r.id) {
                        const strs = r.id.split(':');
                        return addArr.push({
                            userId: strs[0],
                            teamId: strs[2],
                            app: strs[1],
                            taskUserRole: 1,
                            headImg: r.avatar,
                            realName: r.name,
                        });
                    }
                });
                close();
                const { title } = taskDetail;
                const sharelink = {
                    ext: ``,
                    icon:
                        'http://storage.360buyimg.com/jd.jme.photo/message_icon_task2x.png?Expires=3757406611&AccessKey=93c0d2d5a6cf315c3d4c52c5f549a9a886b59f76&Signature=pTm0aXa1Azq0eea7K91GNDWDypo%3D',
                    // source: 'joyWork',
                    category: 'joywork',
                    title: t('share card title').replace('%s', realName),
                    content: title,
                    url: DeeplinkEvent.strify({
                        appId: ddAppId,
                        path: DeeplinkPathEnum.Task_Draw,
                        mparam: {
                            taskId: taskDetail.taskId,
                            taskName: taskDetail.title,
                            from: 'mobile',
                            priorityType: taskDetail.priorityType,
                            dueTag: taskDetail.dueTag,
                            endTime: taskDetail.endTime,
                            startTime: taskDetail.startTime,
                            content: taskDetail.content,
                        },
                    }),
                };
                const msg: ChatMessageBody = {
                    type: ChatMessageType.TEMPLATE2,
                    template: {
                        nativeId: 'share_link',
                    },
                    code: 0,
                    data: [
                        {
                            sharelink: sharelink,
                            type: 0,
                        },
                    ],
                };
                let shareRecipientDtos: ShareRecipientDto[] = [];

                for (let item of addArr) {
                    shareRecipientDtos.push({
                        recipientType: '1',
                        recipientId: item.userId,
                        recipientName: item.realName,
                        teamId: item.teamId,
                        app: item.app,
                    });
                    const session = await imService.createSingleSession({
                        app: item.app,
                        pin: item.userId,
                        teamId: item.teamId,
                    });
                    if (session) {
                        const messageId = generateMessageId();
                        await imService.sendChatMessage(session.sessionId, messageId, msg);
                    }
                }
                originGroupArr.forEach((og) => {
                    shareRecipientDtos.push({
                        recipientType: '2',
                        recipientId: og.id,
                        recipientName: og.name,
                        app: ddAppId,
                    });
                });
                groupArr.forEach((a) => {
                    const sessionId = a;
                    const messageId = generateMessageId();
                    imService.sendChatMessage(sessionId, messageId, msg);
                });

                const [_shareRes, shareErr] = await shareScheduleAttachment({
                    bizId: taskId,
                    bizType: 1,
                    shareRecipientDtos: shareRecipientDtos,
                });
                if (shareErr) {
                    console.error('分享日程文档权限报错:', shareErr);
                    return message.error(shareErr);
                }
                message.success(t('share success'));
            },
            userData
        );
    }, [userData, taskDetail, t, taskId, imService]);

    const executorsMap = useMemo(() => {
        let temp = taskDetail?.executors || [];
        return {
            [PROCESS_STATUS.NOT_VIEWED]: temp.filter(
                (item) => item.processStatus === PROCESS_STATUS.NOT_VIEWED
            ),
            [PROCESS_STATUS.PUSHING]: temp.filter(
                (item) => item.processStatus === PROCESS_STATUS.PUSHING
            ),
            [PROCESS_STATUS.DIFFICULT]: temp.filter(
                (item) => item.processStatus === PROCESS_STATUS.DIFFICULT
            ),
            [PROCESS_STATUS.FINISHED]: temp.filter(
                (item) => item.processStatus === PROCESS_STATUS.FINISHED
            ),
        };
    }, [taskDetail?.executors]);

    // async function handleTitleSave() {
    //     if (!taskDetail) {
    //         return;
    //     }
    //     if (!title.trim()) {
    //         message.warn(t('input task content'));
    //         return;
    //     }
    //     const isSuccess = await handleModifyDetailInfo({
    //         taskId: taskId,
    //         title: title,
    //     });
    //     if (isSuccess) {
    //         message.success(t('save success'));
    //         setTitleInputVisible(false);
    //     } else {
    //         return;
    //     }
    // }

    // 切换Tab
    function handleTabChange(key: string) {
        setActiveTab(key);
    }

    const handleTaskStatusChange = useCallback(
        (status: number) => {
            async function changingTaskStatus(status: number) {
                const isSuccess = await handleModifyDetailInfo({
                    taskId: taskId,
                    taskStatus: status,
                    comment: comment,
                });
                if (isSuccess) {
                    message.success(t('save success'));
                    setIsPushingWhole(false);
                    setIsPushing(false);
                    setComment('');
                    taskDetailCopy!.taskStatus = status;
                }
            }

            if (!taskDetail) {
                return;
            }
            let content = '';
            if (status !== 3) {
                if (status === 1) {
                    if (taskDetailCopy?.taskStatus === 1) {
                        changingTaskStatus(status);
                        return;
                    }
                    if (isTaskFinish) {
                        Modal.info({
                            title: t('tip'),
                            content: (
                                <span className="nimble-con">该任务已整体完成，不支持此操作</span>
                            ),
                            className: 'task-common-modal-lb',
                            onOk() {},
                        });
                        return;
                    }
                    content = 'tip stop to restart task';
                } else {
                    const processStatusList = taskDetail.executors.map(
                        (item) => item.processStatus
                    );
                    const isFinish = processStatusList.every((item) => item === 2);
                    content = isFinish ? 'tip finish task' : 'tip force finish task';
                }
                const modal = Modal.confirm({
                    title: t('tip'),
                    content: <span className="nimble-con">{t(`${content}`)}</span>,
                    okText: t('confirm'),
                    cancelText: t('cancel'),
                    className: 'task-common-modal-lb',
                    centered: true,
                    onOk: () => {
                        changingTaskStatus(status);
                    },
                });
            } else {
                setStopTaskVisible(true);
            }
            return;
        },
        [t, taskDetail, taskDetailCopy, taskId, comment] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleStopTask = useCallback(() => {
        if (!taskDetail) {
            return;
        }
        if (!comment.trim()) {
            message.warn(t('add stop reason'));
            return;
        }
        handleModifyDetailInfo({
            taskId: taskId,
            taskStatus: TASK_STATUS.ABORT,
            comment: comment,
        })
            .then(() => {
                setStopTaskVisible(false);
                setComment('');
                setTextareaCommentFocus(false);
                setIsPushingWhole(false);
                setIsPushing(false);
                taskDetailCopy!.taskStatus = TASK_STATUS.ABORT;
            })
            .catch((error) => console.error(error));
    }, [t, taskDetail, comment, taskId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancel = useCallback(() => {
        setComment('');
        setTextareaCommentFocus(false);
        setStopTaskVisible(false);
    }, []);

    const closeDrawAndRefresh = useCallback(
        (_taskId: string) => {
            setTaskDetail(null);
            setChatVisable(false);
            // setTitleInputVisible(false);
            changePersonalTaskDraw(taskId, false);
            getTaskList();
            setActiveTab('advanceRecord');
            // 部门任务的
            bus.emit('departmentalTast-delete-someone', _taskId);
        },
        [changePersonalTaskDraw, getTaskList, taskId]
    );

    const handleDelTask = useCallback(async () => {
        if (!taskId) {
            return;
        }
        const confirmDel = await syncConfirm(t('delete task info'), t('delete task'));
        if (confirmDel) {
            let groupConfirm = false;
            if (taskDetail?.groupId) {
                groupConfirm = await syncConfirm('任务删除后，是否将关联的组进行解散处理？');
            }
            const [, err] = await deleteTask({ taskId: taskId, type: 1, delGroup: groupConfirm });
            if (err) {
                return message.warn(err);
            }
            message.success(t('delete success'));
            closeDrawAndRefresh(taskId);
        }
    }, [closeDrawAndRefresh, t, taskDetail?.groupId, taskId]);

    const tipContent = useMemo(() => {
        return (
            <div className="task-tip-wrapper" style={{ boxShadow: 'rgba(0,0,0,.2) 0 0 10px' }}>
                <div className="tip-item">
                    <div className="tip-title">整体完成</div>
                    <div className="tip-content">
                        只有任务的负责人有权限整体完成任务，点击“整体完成”后，任务将被整体标记为完成。如需要编辑或重新开始任务，可操作重启任务。
                    </div>
                </div>
                <div className="tip-item">
                    <div className="tip-title">中止</div>
                    <div className="tip-content">
                        中止代表中途暂停任务。如需要编辑或重新开始任务，可操作重启任务。
                    </div>
                </div>
            </div>
        );
    }, []);

    // 下拉更改任务状态
    /* const getStatuChanger = useMemo(() => {
        const menu = isTaskFinishOrStop ? (
            <Menu>
                <Menu.Item onClick={() => handleTaskStatusChange(1)}>{t('restart')}</Menu.Item>
            </Menu>
        ) : (
            <Menu>
                <Menu.Item onClick={() => handleTaskStatusChange(3)}>{t('suspend task')}</Menu.Item>
                <Menu.Item onClick={() => handleTaskStatusChange(2)}>{t('finish task')}</Menu.Item>
            </Menu>
        );
        return (
            <Dropdown
                className="status-changer"
                placement="bottomLeft"
                trigger={['click']}
                overlay={menu}
                overlayStyle={{ fontSize: '14px' }}
            >
                <IconFont type="iconlog_enter" style={{ fontSize: '12px' }} />
            </Dropdown>
        );
    }, [t, handleTaskStatusChange, isTaskFinishOrStop]); */

    // 终止任务的弹窗
    const getFooter = useMemo(() => {
        return (
            <div className="footer-button">
                <Modal
                    title={t('stop reason')}
                    destroyOnClose={true}
                    visible={stopTaskVisible}
                    centered={true}
                    closable={false}
                    okText={t('confirm')}
                    cancelText={t('cancel')}
                    onOk={handleStopTask}
                    onCancel={handleCancel}
                    className="stop-task-draw"
                >
                    <div
                        className={`textarea-container ${
                            textareaCommentFocus ? 'textarea-container-focus' : ''
                        }`}
                    >
                        <Input.TextArea
                            placeholder={t('add stop reason')}
                            rows={4}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            onFocus={() => setTextareaCommentFocus(true)}
                            maxLength={500}
                        />
                        <p className="text-length">
                            <span>{comment.length}</span>/500
                        </p>
                    </div>
                </Modal>
            </div>
        );
    }, [t, stopTaskVisible, textareaCommentFocus, comment, handleCancel, handleStopTask]);

    // 立即沟通按钮的点击事件回调
    clickFn = useMemo(() => {
        window.console.log('1111-bbbbbb-1111', taskDetailCopy?.taskId);
        const retFn = () => {
            window.console.log(1111);
            chatVisable ? setChatVisable(false) : handleChat();
        };
        if (openChatSoon) {
            setTimeout(retFn);
            openChatSoon = false;
        }
        return retFn;
    }, [handleChat, chatVisable]); // eslint-disable-line react-hooks/exhaustive-deps

    // TODO: 根据实际情况处理result err
    async function handleModifyDetailInfo(data?: any) {
        const [result, err] = await modifyTaskInfo(data);
        if (err) {
            message.warn(err as String);
            return false;
        }
        const task = decorateTaskDetail(result as NewTaskModel);
        setTaskDetail(task);
        getTaskList();
        return result;
    }

    // 保存‘我的备注’
    async function saveRemark(event: any) {
        if (!event.ctrlKey) {
            setRemarkInputVisible(false);

            const res = await handleModifyDetailInfo({
                taskId: taskId,
                remark: {
                    id: taskDetail?.remark?.id,
                    remark: event.target.value,
                },
            });
            if (res) {
                message.success('保存成功');
            }
        }
    }
    function handleRemarkChange(e: any) {
        setRemark(e.target.value || '');
    }

    async function onAttachmentRemove(fileIds: string[]): Promise<boolean> {
        if (!taskDetail) {
            return false;
        }
        const delResource = taskDetail.resources.find((item) => item.bizId === fileIds[0]);
        if (!delResource) {
            return false;
        }
        const isSuccess = await handleModifyDetailInfo({
            taskId: taskId,
            delResources: [delResource],
        });
        if (isSuccess) {
            message.success(t('attachment remove success'));
        }
        return true;
    }
    async function onAttachmentUploadFinish(file: any): Promise<null | any> {
        if (!taskDetail) {
            return null;
        }
        if (Array.isArray(file)) {
            const files = file;
            const resources = files.map((f) => {
                return {
                    ...f,
                    name: f.fileName,
                    size: f.size,
                    url: f.fileUrl,
                    resourceId: nanoid(),
                    bizId: f.fileId,
                    bizType: f.bizType || TaskResourceBizType.FILE,
                    resourceType: TaskResourcePermissionType.COMMON,
                    fileType: f.fileType,
                    creatorUserId: f?.creatorUserId,
                };
            });
            const isSuccess = await handleModifyDetailInfo({
                taskId: taskId,
                addResources: resources,
            });
            if (isSuccess) {
                message.success(t('attachment upload success'));
            }
            return resources;
        }
        const resource = {
            resourceId: nanoid(),
            name: file.fileName,
            size: file.size,
            url: file.fileUrl,
            bizId: file.fileId,
            bizType: file.bizType || TaskResourceBizType.FILE,
            resourceType: TaskResourcePermissionType.COMMON,
            fileType: file.fileType,
            creatorUserId: file?.creatorUserId,
        };
        const resourceId = nanoid();
        const isSuccess = await handleModifyDetailInfo({
            taskId: taskId,
            addResources: [resource],
        });
        if (isSuccess) {
            message.success(t('attachment upload success'));
        }
        return { resourceId: resourceId };
    }

    // 个人推进的提交
    async function handleSubmit() {
        // console.log(taskDetail, 'taskDetail');
        if (!taskDetail) {
            return;
        }

        // if (!comment.trim()) {
        //     message.warn(t('comment task content'));
        //     return;
        // }
        setSubmitting(true);
        const isSuccess = await handleModifyDetailInfo({
            taskId: taskId,
            comment: comment,
            processStatus: processStatus,
            progress: processStatus === 2 ? 100 : progressMy,
        });
        setSubmitting(false);
        if (isSuccess) {
            message.success(t('save success'));
            setPullRecords(pullRecords + 1);
            setComment('');
            setIsPushing(false);
        }
    }

    if (!drawVisible) {
        return null;
    }

    // userRole可能不准确（比如在im菜单下打开任务的时候）
    if (taskDetail && !taskDetail.userRoles!.includes(userRole)) {
        userRole = taskDetail.userRoles![0] as number; // eslint-disable-line
    }

    return (
        <Drawer
            width={437}
            placement="right"
            style={{
                transform: chatVisable ? `translate(-430px)` : 'inherit',
                zIndex: 100,
            }}
            closable={false}
            onClose={closeDraw}
            className="work-detail-draw"
            visible={true}
            destroyOnClose={true}
            footer={getFooter}
        >
            {!taskDetail ? (
                <div className="draw-loading">
                    <Loading />
                </div>
            ) : (
                <>
                    <div className={`detail-header${titleBlockClassname}`}>
                        <div>
                            {t('task detail')}
                            <b className="myRole">
                                (我{`${userRole === 1 ? '负责' : userRole === 0 ? '创建' : '协同'}`}
                                的)
                            </b>
                        </div>
                        <div className="header-btn-wrapper">
                            <div
                                title={t_common('button.edit')}
                                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_edit"
                                className="action-du action-button"
                                onClick={handleEditTask}
                            >
                                <IconFont type="iconrdit" />
                            </div>
                            {!isTaskFinishOrStop && (
                                <div
                                    title="催办"
                                    style={{ display: isTaskFinish ? 'none' : '' }}
                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_urge"
                                    className="action-du action-button"
                                    onClick={() => handleDuClick()}
                                >
                                    <IconFont type="iconzw_app_remind1" />
                                </div>
                            )}
                            <div
                                title={t_common('button.share')}
                                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_share"
                                className="action-share action-button"
                                onClick={handleShareClick}
                            >
                                <IconFont type="iconshare" />
                            </div>
                            {isHead && (
                                <div
                                    title={t_common('button.delete')}
                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_delete"
                                    className="action-du action-button"
                                    onClick={handleDelTask}
                                >
                                    <IconFont type="icondelect" />
                                </div>
                            )}
                            <div
                                title={t_common('button.close')}
                                className="action-close action-button"
                                onClick={closeDraw}
                            >
                                <IconFont type="iconapp_btn_file_cancel" />
                            </div>
                        </div>
                    </div>
                    <div className="detail-mainbody">
                        <div className="detail-title-box" ref={titleBlock}>
                            <div className="title-wrapper">
                                {taskDetail?.priorityType &&
                                    taskDetail.priorityType !== PRIORITY_TYPE.NO_PRIORITY && (
                                        <img
                                            style={{
                                                width: '18px',
                                                height: '18px',
                                                marginRight: '9px',
                                            }}
                                            src={changeEgovUrl(
                                                PRIORITY_TYPE_MAP[taskDetail.priorityType].url
                                            )}
                                            alt={PRIORITY_TYPE_MAP[taskDetail.priorityType].alt}
                                        />
                                    )}
                                <Tooltip
                                    placement="bottom"
                                    arrowPointAtCenter
                                    overlayClassName="task-detail-title-tooltip"
                                    title={
                                        <div className="user-select-text">
                                            {htmlUnescape(taskDetail?.title)}
                                        </div>
                                    }
                                >
                                    <span className="user-select-text title-wrapper-title">
                                        {htmlUnescape(taskDetail?.title)}
                                    </span>
                                </Tooltip>
                                {taskDetail.dueTag && !(processStatus === 2 && !isHead) && (
                                    <span className="title-wrapper-tag">逾期</span>
                                )}
                            </div>
                            <div
                                className={`title-wrapper-status task-status-is-${
                                    taskDetail?.taskStatus || ''
                                }`}
                                style={{
                                    // backgroundColor: `${ getTaskStatus(taskDetail?.taskStatus)?.color }`,
                                    color: taskDetail.dueTag
                                        ? 'rgba(238,10,36,1)' // 红色
                                        : `${getTaskStatus(taskDetail?.taskStatus)?.color}`,
                                }}
                            >
                                <span className="status-txt">
                                    {getTaskStatus(taskDetail?.taskStatus)?.title}
                                </span>
                                {/* {isHead ? getStatuChanger : null} */}
                                {/* <Progress
                                    percent={taskDetail?.totalProgress || 0}
                                    size="small"
                                    className={
                                        `task-status-${taskDetail?.taskStatus}` +
                                        `${taskDetail.dueTag ? ' overdue' : ''}`
                                    }
                                /> */}
                            </div>

                            {/* ----  立即沟通/ 更新状态(任务维护）/个人进展  ----- */}
                            <div className="showy-buttons">
                                <ul>
                                    {showChat() && (
                                        <li
                                            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_IMgroupcommunicate"
                                            onClick={debounceFn}
                                        >
                                            {chatVisable ? (
                                                <IconFont
                                                    type="icona-ic_pc_task_putaway2x"
                                                    style={{ fontSize: '24px', marginRight: '4px' }}
                                                />
                                            ) : (
                                                IconTaskCommunicate
                                            )}
                                            <span>{chatVisable ? '收起聊天' : '立即沟通'}</span>
                                        </li>
                                    )}
                                    {isHead ? (
                                        isTaskFinishOrStop ? (
                                            isTaskStop ? (
                                                <li
                                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_advancerecord_commit3"
                                                    onClick={() => {
                                                        handleTaskStatusChange(1);
                                                    }}
                                                >
                                                    {IconTaskRestart}
                                                    <span>{t('restart')}</span>
                                                </li>
                                            ) : null
                                        ) : (
                                            <li
                                                clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_advancerecord_commit1"
                                                onClick={() => {
                                                    setIsPushingWhole(true);
                                                }}
                                            >
                                                {IconTaskPreserve}
                                                <span>更新状态</span>
                                            </li>
                                        )
                                    ) : null}
                                    {!isTaskFinishOrStop && taskDetail?.userRoles?.includes(2) && (
                                        <li
                                            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_advancerecord_commit2"
                                            onClick={() => {
                                                if (isTaskFinish) {
                                                    handleTaskStatusChange(1);
                                                    return;
                                                }
                                                setIsPushing(true);
                                                setIsPushingWhole(false);
                                            }}
                                        >
                                            {IconTaskProceed}
                                            <span>个人进展</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="detail-content">
                            <div className="content-basic">
                                <div className="detail-content-item task-owner-item">
                                    <div className="label">{t('task head')}</div>
                                    <div className="owner-container">
                                        <UserList
                                            disable={true}
                                            addMemberDisable={isTaskFinishOrStop || !isHead}
                                            list={[taskDetail.owner]}
                                            maxLength={8}
                                            isShowName={true}
                                            notAvatar={true}
                                            notTooltip={true}
                                            title={taskDetail.title}
                                        />
                                        <span style={{ flex: 1 }} />
                                    </div>
                                </div>
                                <div className="detail-content-item task-executor-item">
                                    <div className="label">{t('executor')}</div>
                                    <div style={{ position: 'relative' }}>
                                        <div className="executor-stat-container">
                                            <UserStatusListPopover
                                                list={executorsMap}
                                                userData={userData}
                                                taskDetail={taskDetail}
                                                showAdvance={true}
                                            >
                                                <div
                                                    className="title"
                                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_collaborator_view_Advancerecord"
                                                >
                                                    <div className="title-txt">
                                                        {/* {
                                                            executorsMap[PROCESS_STATUS.NOT_VIEWED]
                                                                .length
                                                        }
                                                        人未查看，
                                                        {
                                                            executorsMap[PROCESS_STATUS.PUSHING]
                                                                .length
                                                        }
                                                        人推进中，
                                                        {
                                                            executorsMap[PROCESS_STATUS.DIFFICULT]
                                                                .length
                                                        }
                                                        人有困难，
                                                        {
                                                            executorsMap[PROCESS_STATUS.FINISHED]
                                                                .length
                                                        }
                                                        人已完成 */}
                                                    </div>
                                                    <IconFont
                                                        type="iconlog_enter"
                                                        className="icon-more"
                                                    />
                                                </div>
                                            </UserStatusListPopover>
                                        </div>

                                        <div className="executor-container">
                                            <UserList
                                                list={taskDetail.executors}
                                                isShowName={true}
                                                notAvatar={true}
                                                maxLength={8}
                                                disable={true}
                                                title={taskDetail.title}
                                                userPopover="task"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="detail-content-item task-timerange-item common-single-item"
                                    style={{
                                        display:
                                            !taskDetail.workStartTime && !taskDetail.workStopTime
                                                ? 'none'
                                                : '',
                                    }}
                                >
                                    <div className="label">{t('Start-stop time')}</div>
                                    <div className="timerange-container">
                                        <div
                                            className="time-button time-button-star"
                                            style={{ height: 32 }}
                                        >
                                            <DatePicker
                                                time={taskDetail.workStartTime || null}
                                                disable={true}
                                                placeholder={t('no start time')}
                                                defaultTime={getDefaultStartTime()}
                                            />
                                        </div>
                                        <div className="button-split" />
                                        <div
                                            className="time-button time-button-end"
                                            style={{ height: 32 }}
                                        >
                                            <DatePicker
                                                time={taskDetail.workStopTime || null}
                                                disable={true}
                                                placeholder={t('no end time')}
                                                defaultTime={getDefaultStopTime()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {remindArr.length ? (
                                    <div className="detail-content-item task-remind-item common-single-item">
                                        <div className="label">逾期提醒</div>
                                        <div className="textarea-button">
                                            <div className="textarea-text">
                                                {remindArr
                                                    .map((v) => {
                                                        return remindList.find(
                                                            (o) => o.value === String(v)
                                                        )?.label;
                                                    })
                                                    .join('、')}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div
                                    className="detail-content-item task-textarea-item"
                                    style={{
                                        alignSelf: 'flex-start',
                                        display: !taskDetail.content ? 'none' : '',
                                    }}
                                >
                                    <div className="label">描述</div>

                                    <div className="textarea-button">
                                        {taskDetail.content ? (
                                            <div
                                                className="textarea-text user-select-text"
                                                dangerouslySetInnerHTML={{
                                                    __html: mattchUrl(taskDetail.content),
                                                }}
                                            />
                                        ) : (
                                            <div className="textarea-text textarea-text-empty">
                                                {t('no description')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {state.fileList?.length ? (
                                <div className="content-part-other">
                                    <div className="detail-content-item task-attachment-item">
                                        <Collapse
                                            // defaultActiveKey={[0]}
                                            bordered={false}
                                            expandIconPosition="right"
                                            expandIcon={({ isActive }) => (
                                                <div>
                                                    <IconFont
                                                        type="iconlog_enter"
                                                        className="icon-more"
                                                        rotate={isActive ? 270 : 90}
                                                    />
                                                </div>
                                            )}
                                        >
                                            <Panel
                                                header={
                                                    <div className="label">
                                                        {t('attachment')} {state.fileList?.length}
                                                    </div>
                                                }
                                                key={0}
                                            >
                                                <WorkAttachmentComp
                                                    disabled={true}
                                                    mode="modify"
                                                    onRemove={onAttachmentRemove}
                                                    onFinish={onAttachmentUploadFinish}
                                                    fileList={state.fileList}
                                                    isHead={isHead}
                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                            ) : null}
                            <div className="content-part-other">
                                <div className="detail-content-item task-textarea-item task-mynote-item">
                                    <Collapse
                                        // defaultActiveKey={remark ? [0] : []}
                                        bordered={false}
                                        expandIconPosition="right"
                                        expandIcon={({ isActive }) => (
                                            <IconFont
                                                type="iconlog_enter"
                                                className="icon-more"
                                                rotate={isActive ? 270 : 90}
                                            />
                                        )}
                                    >
                                        <Panel
                                            header={
                                                <div
                                                    className="label"
                                                    style={{ alignSelf: 'flex-start' }}
                                                >
                                                    {t('my note')}
                                                    <em>(仅您自己可见)</em>
                                                </div>
                                            }
                                            key={0}
                                        >
                                            {remarkInputVisible ? (
                                                <div className="textarea-container">
                                                    <Input.TextArea
                                                        autoSize
                                                        placeholder={t('no remark content')}
                                                        defaultValue={remark}
                                                        maxLength={500}
                                                        onChange={handleRemarkChange}
                                                        onPressEnter={saveRemark}
                                                        onBlur={saveRemark}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="textarea-button">
                                                    <div className="textarea-text-flex">
                                                        {remark ? (
                                                            <div
                                                                className="textarea-text user-select-text"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: mattchUrl(remark),
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="textarea-text textarea-text-empty">
                                                                {t('no remark content')}
                                                            </div>
                                                        )}
                                                        <IconFont
                                                            type="iconrdit"
                                                            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_note"
                                                            onClick={() => {
                                                                setRemarkInputVisible(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                        <div className="divider-block" />
                        <TaskDetailContext.Provider
                            value={{ taskDetail, setTaskDetail, closeDrawAndRefresh }}
                        >
                            <Tabs
                                activeKey={activeTab}
                                onChange={handleTabChange}
                                className="tab-wrapper"
                            >
                                <Tabs.TabPane
                                    tab={
                                        <span clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_detailpage_advancerecords">
                                            {t('advance-record')}
                                        </span>
                                    }
                                    key="advanceRecord"
                                >
                                    <AdvanceRecord
                                        isTaskFinishOrStop={isTaskFinishOrStop}
                                        isHead={isHead}
                                        isExecutorsSelf={self ? true : false}
                                        pullRecords={pullRecords}
                                    />
                                </Tabs.TabPane>
                                {taskDetail.executors.length && (
                                    <Tabs.TabPane
                                        tab={
                                            <span clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_detailpage_executors">
                                                {t('executors-progress')}
                                            </span>
                                        }
                                        key="executorsProgress"
                                    >
                                        <ExecutorsProgress
                                            members={taskDetail.executors}
                                            urgeSomeOne={!isTaskFinishOrStop && handleDuClick}
                                        />
                                    </Tabs.TabPane>
                                )}
                                <Tabs.TabPane
                                    tab={
                                        <span clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_detailpage_comments">{`${t(
                                            'comment'
                                        )}${countOfComment ? ' ' + countOfComment : ''}`}</span>
                                    }
                                    key="comment"
                                >
                                    <Comment
                                        isHead={isHead}
                                        isExecutorsSelf={self ? true : false}
                                        setCountOfComment={setCountOfComment}
                                        taskId={taskDetail?.taskId}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </TaskDetailContext.Provider>
                        {/* 点赞去掉20210415 */}
                        {/* <div className="task-like">
                            <Like id={taskDetail.taskId} />
                        </div> */}
                    </div>
                    <div
                        className="detail-my-boost"
                        style={{
                            boxShadow:
                                isPushingWhole || isPushing ? '0 0 8px 2px rgb(0 0 0 / 10%)' : '',
                        }}
                    >
                        {isPushingWhole ? (
                            <>
                                <div
                                    style={{
                                        height: '64px',
                                    }}
                                >
                                    <div className="my-progress-header">
                                        <p>
                                            更新状态
                                            <Dropdown
                                                overlay={tipContent}
                                                // trigger={['click']}
                                                placement="topLeft"
                                                overlayClassName="dropdown-overlay-belong-work"
                                            >
                                                <QuestionCircleOutlined
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#8f959e',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Dropdown>
                                        </p>
                                        <IconFont
                                            className="lb-conclude"
                                            style={{ fontSize: 14 }}
                                            type="iconapp_btn_file_cancel"
                                            onClick={() => {
                                                setIsPushing(false);
                                                setIsPushingWhole(false);
                                                setTaskStatus(taskDetailCopy!.taskStatus!);
                                                setComment('');
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="advance-status">
                                    <Radio.Group
                                        onChange={(e) => {
                                            setTaskStatus(e.target.value);
                                        }}
                                        value={taskStatus}
                                    >
                                        {TASK_DO_STATUS_LIST.map((item, key) => {
                                            return (
                                                <Radio
                                                    key={key}
                                                    value={item.value}
                                                    className={`process-status-${
                                                        item.color || item.value
                                                    }`}
                                                >
                                                    {item.text}
                                                </Radio>
                                            );
                                        })}
                                    </Radio.Group>
                                    {/* <Progress
                                        className={`related-status-${taskStatus} refer-to-task`}
                                        percent={
                                            taskStatus === 2 ? 100 : taskDetail?.totalProgress || 0
                                        }
                                        size="small"
                                    /> */}
                                    <div className="textarea">
                                        <Input.TextArea
                                            placeholder={
                                                taskStatus === 3
                                                    ? '情况说明(中止任务必填)'
                                                    : '情况说明'
                                            }
                                            rows={4}
                                            value={comment}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setComment(e.target.value);
                                                // setTaskDetail({ ...taskDetail, comment: e.target.value });
                                            }}
                                            maxLength={500}
                                        />
                                    </div>
                                    <Button
                                        size="middle"
                                        clstag={`pageclick|keycount|${
                                            taskStatus === 3
                                                ? 'xtbg_add_task|xtbg_add_task_taskdetail_suspendtask'
                                                : taskStatus === 2
                                                ? 'xtbg_add_task|xtbg_add_task_taskdetail_complete'
                                                : ''
                                        }`}
                                        loading={submitting}
                                        onClick={() => {
                                            handleTaskStatusChange(taskStatus);
                                        }}
                                        type="primary"
                                        disabled={taskStatus === 3 && !comment}
                                    >
                                        {t('update confirm')}
                                    </Button>
                                </div>
                            </>
                        ) : isPushing ? (
                            <>
                                <div
                                    style={{
                                        height: '64px',
                                    }}
                                >
                                    <div className="my-progress-header">
                                        <p>个人进展</p>
                                        <IconFont
                                            className="lb-conclude"
                                            style={{ fontSize: 14 }}
                                            type="iconapp_btn_file_cancel"
                                            onClick={() => {
                                                setIsPushing(false);
                                                setComment('');
                                                setProcessStatus(taskDetailCopy!.processStatus!);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="advance-status">
                                    <Radio.Group
                                        onChange={(e) => {
                                            setProcessStatus(e.target.value);
                                        }}
                                        value={processStatus}
                                    >
                                        {PROCESS_STATUS_LIST.map((item, key) => {
                                            return (
                                                item.text !== '未查看' && (
                                                    <Radio
                                                        key={key}
                                                        value={item.value}
                                                        className={`process-status-${item.value}`}
                                                    >
                                                        {item.text}
                                                    </Radio>
                                                )
                                            );
                                        })}
                                    </Radio.Group>
                                    {/* <ProgressSliderCOMPLEX
                                        className={`related-status-${processStatus}`}
                                        max={taskDetail.weight || 0}
                                        current={
                                            processStatus === 2 ? 100 : taskDetail.progress || 0
                                        }
                                        totalWeight={taskDetail.totalWeight!}
                                        setter={(v: number) => {
                                            setProgressMy(v);
                                            if (v === 100) {
                                                setProcessStatus(2);
                                            } else if (processStatus === 2) {
                                                setProcessStatus(1);
                                            }
                                        }}
                                    /> */}
                                    <div className="textarea">
                                        <Input.TextArea
                                            placeholder="请输入推进具体事项"
                                            rows={4}
                                            value={comment}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setComment(e.target.value);
                                                // setTaskDetail({ ...taskDetail, comment: e.target.value });
                                            }}
                                            maxLength={500}
                                        />
                                        {/* <p className="text-length">
                                            <span>{Comment ? Comment.length : 0}</span>/500
                                        </p> */}
                                    </div>
                                    <Button
                                        size="middle"
                                        clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_advancerecord_commit"
                                        loading={submitting}
                                        onClick={handleSubmit}
                                        type="primary"
                                    >
                                        {t('update confirm')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            taskDetail?.userRoles?.includes(2) && (
                                <div style={{ height: '64px' }}>
                                    <div
                                        className="my-progress-header"
                                        onClick={() => {
                                            canCheckMyProgress && setIsPushing(!isPushing);
                                        }}
                                    >
                                        <p>
                                            我的进展
                                            <b> </b>
                                            {/* <b>{(taskDetail.progress || 0) + '%'}</b> */}
                                            <span
                                                className={`personal-process-status tag-process-status-${processStatus}`}
                                            >
                                                {processStatus !== -1 &&
                                                    PROCESS_STATUS_EXECUTORS[processStatus]}
                                            </span>
                                        </p>
                                        {canCheckMyProgress && (
                                            <IconFont
                                                className="lb-conclude"
                                                type="iconlog_enter"
                                                rotate={-90}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </>
            )}
            {showChat() && (
                <div className="chat-switch-btn-wrap" style={{ right: 437 }}>
                    <Button
                        clstag={`pageclick|keycount|${
                            !chatVisable
                                ? 'xtbg_add_task|xtbg_add_task_taskdetail_IMgroupcommunicate'
                                : ''
                        }`}
                        onClick={debounceFn}
                    >
                        {chatVisable ? <CaretRightOutlined /> : <CaretLeftOutlined />}
                    </Button>
                </div>
            )}

            <Drawer // 聊天抽屉
                title={null}
                width={430}
                className="task-chat-drawer"
                mask={false}
                closable={false}
                onClose={() => {
                    setChatVisable(false);
                }}
                visible={chatVisable}
            >
                <ChatMain hideSetting={true} singleLine={true} />
            </Drawer>
        </Drawer>
    );
}
function mapStateToProps({ work, user, calendar, chat }: any) {
    const { detailState } = work;
    return {
        detailStateActiveTab: detailState.activeTab,
        userData: user.userData.user,
        drawVisible: detailState.drawVisible,
        taskId: detailState.taskId,
        forceGet: detailState.forceGet,
        chat: detailState.chat,
        offset: work.offset,
        sortType: work.sortType,
        offsets: work.offsets,
        processStatuses: work.processStatuses,
        taskStatuses: work.taskStatuses,
        priorityTypes: work.priorityTypes,
        userRole: work.userRole,
        selectedSession: chat.selectedSession,
        scheduleInfoDraw: calendar.scheduleInfoDraw,
        scheduleInfoDrawOther: calendar.scheduleInfoDrawOther,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changePersonalTaskDraw: (
            taskId: string,
            visible: boolean,
            activeTab?: string,
            chat?: false
            // eslint-disable-next-line max-params
        ) =>
            dispatch({
                type: 'work/changePersonalTaskDraw',
                payload: { taskId, drawVisible: visible, activeTab, chat },
            }),
        changeEditTaskDraw: (taskId: string, visible: boolean, taskDetail: NewTaskModel) =>
            dispatch({
                type: 'work/changeEditTaskDraw',
                payload: { taskId, drawEditVisible: visible, taskDetail },
            }),
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
        getTasks(payload: TaskListParams) {
            return dispatch({
                type: 'work/fetchTaskList',
                payload,
            });
        },
        launchGroupSession(data: { sid: string }) {
            dispatch({ type: 'chat/launchGroupSession', payload: data });
        },
        updateSelectedSession(data: {}) {
            dispatch({ type: 'chat/updateSelectedSession', payload: data });
        },
        changeScheduleInfoDraw: (payload: { visible: boolean; scheduleId: string }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDraw',
                payload,
            }),
        changeScheduleInfoDrawOther: (payload: { visible: boolean; scheduleId: string }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDrawOther',
                payload,
            }),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailDraw);
