import { gatewayInstance } from '@/server/gateway';
import { MobileRecordReq } from '@/types/Mobile';

export async function examineMobileRecord(opt: MobileRecordReq): Promise<[any, string | null]> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.reportExamineMobileRecords',
            method: 'POST',
            body: opt,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
