import React, { useEffect } from 'react';
import { IColumnProps } from './Column';

export interface IProps<T, C> {
    data: T;
    isSelected: boolean;
    columnProps: IColumnProps<T, C>[];
    reload: () => void;
    remove: () => void;
}

export default function Line<T, C>(props: IProps<T, C>) {
    // console.log(props, 'propspropspropspropspropsprops');
    const [data, setData] = React.useState(props.data);
    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <div data-row className={props.isSelected ? 'selected' : undefined}>
            {props.columnProps.map((__props) => (
                <div data-colname={__props.name} key={__props.name}>
                    {__props?.render?.({
                        data,
                        setData,
                        remove: props.remove,
                        reload: props.reload,
                    })}
                </div>
            ))}
        </div>
    );
}
