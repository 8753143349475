import React, { CSSProperties, useMemo } from 'react';
import { SuperviseUserInfo, ReadFlag } from '@/types/supervise';
import './index.less';
import { connect } from 'dvajs';
import { isFocusEnv, FocusSDK } from '@/utils';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import IconFont from '@/components/icon';
import Bus from '@/utils/bus';
import { AvatarIcon } from '@/components/Avatar';
import { Avatar } from '@/baseComponents/Avatar';
import { changeEgovUrl } from '@/utils/tools';
interface IUser {
    userId: string;
    teamId: string;
    appId: string;
    app?: string;
    realName: string;
    headImg: string;
    readFlag: ReadFlag;
}
function UserNameI({
    user,
    toggleUserCardModal,
    showRead,
}: {
    user: IUser;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    showRead?: boolean;
}) {
    function openUserCard(item: IUser) {
        const { userId, teamId, appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    return (
        <div
            style={{
                position: 'relative',
                margin: '2px 0',
            }}
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    openUserCard(user);
                }}
                className="siw-user-name"
            >
                {user.realName}
            </div>
            {showRead ? (
                <IconFont
                    style={{
                        fontSize: 10,
                        lineHeight: 1,
                        position: 'absolute',
                        top: -2,
                        right: 4,
                        color: '#2ECE39',
                    }}
                    type="iconapp_btn_radio_selected"
                />
            ) : null}
        </div>
    );
}
function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
    };
}
export const UserName = connect(() => {
    return {};
}, mapDispatchToProps)(UserNameI);

export function UserNameList({ list, maxlength }: { list: IUser[]; maxlength: number }) {
    const operators = useMemo(() => {
        if (maxlength <= 1) {
            return [];
        }
        if (list.length > maxlength) {
            return list.slice(0, maxlength);
        }
        return [...list];
    }, [list, maxlength]);
    return (
        <div style={{ display: 'flex' }}>
            {operators.map((item: IUser, index: number) => {
                return (
                    <UserName
                        showRead={item.readFlag !== ReadFlag.unread}
                        key={`${item.userId}_${index}`}
                        user={item}
                    />
                );
            })}
        </div>
    );
}

function UserAvatarI({
    user,
    toggleUserCardModal,
    styles,
}: {
    user: IUser;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    styles?: CSSProperties;
}) {
    function openUserCard(item: IUser) {
        const { userId, teamId, appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }

    return (
        <Avatar
            styleObj={{
                width: 40,
                height: 40,
                marginRight: 10,
                ...styles,
            }}
            src={changeEgovUrl(user.headImg)}
            onClick={(e) => {
                e.stopPropagation();
                openUserCard(user);
            }}
            name={user.realName}
        />
    );
    // return user.headImg ? (
    //     <Avatar
    //         styleObj={{
    //             width: 40,
    //             height: 40,
    //             borderRadius: 20,
    //             marginRight: 8,
    //             ...styles,
    //         }}
    //         src={changeEgovUrl(user.headImg)}
    //         onClick={(e) => {
    //             e.stopPropagation();
    //             openUserCard(user);
    //         }}
    //     />
    // ) : (
    // <img
    //     onClick={(e) => {
    //         e.stopPropagation();
    //         openUserCard(user);
    //     }}
    //     style={{
    //         width: 40,
    //         height: 40,
    //         borderRadius: 20,
    //         marginRight: 8,
    //         ...styles,
    //     }}
    //     src={changeEgovUrl(user.headImg)}
    // />
    //     <AvatarIcon
    //         size={30}
    //         styles={{
    //             marginRight: 8,
    //         }}
    //         clickHandle={(e: any) => {
    //             e.stopPropagation();
    //             openUserCard(user);
    //         }}
    //     />
    // );
}
export const UserAvatar = connect(() => {
    return {};
}, mapDispatchToProps)(UserAvatarI);
