import { gatewayInstance } from '../server/gateway';

export interface EGroupGroupsRes {
    labelId: string;
    groups: {
        gid: string;
    }[];
}

/**
 *
 * 根据分组 id 获取用户群组 gid
 * @param {Schedule} schedule
 * @param {*} opts labelId写死为1 ,ebookType：1：我创建的；2：我加入的
 * @returns
 */
export async function getGroupsByLabel({
    labelId,
    ebookType,
}: {
    labelId: string;
    ebookType?: string;
}): Promise<EGroupGroupsRes> {
    return gatewayInstance.request({
        api: 'egroup.getGroupsByLabel',
        method: 'POST',
        // @ts-ignore
        body: {
            labelId,
            ebookType,
        },
    }) as any;
}

export interface EGroupGroupInfo {
    name: string;
    avatar: string;
    rosterSize: number;
    gid: string;
    kind: number;
    subKind: number;
    business: string;
    businessId: string;
    ext: any;
    flag?: number;
    status?: number;
}

export interface EGroupGroupInfoRes {
    type: number;
    groups: EGroupGroupInfo[];
}

// 根据群组 id 批量获取群组信息
export async function getGroupInfo({
    groupIds,
}: {
    groupIds: string[] | { groupId: any }[];
}): Promise<EGroupGroupInfoRes> {
    return (await gatewayInstance.request({
        api: 'egroup.groupGetInfo',
        method: 'POST',
        body: { type: 5, groupIds },
    })) as any;
}
