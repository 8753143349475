import crypto from 'crypto';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import logger from '@/utils/logger';
import { AppRuntimeConfig } from '@/types/AppRuntimeEnv';
// @ts-ignore
import NodeRSA from 'node-rsa';
import { IPermissions, AuthorResourceEnum } from '@/types/common';
import config, { ChatConfig, ConfigEnum } from '@/config/config';
import { isFocusEnv } from '.';
import duccConfig from '../ducc.js';

const log = logger.getLogger('EnvConfig');

const sKey = {
    pubkey:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCn8CHZxb1rcxKeCv5X5DzU9Jgg\n' +
        'wNa/1rn5NiKECj6zM4JA0v9SjH6eNjw2s0kVVd7wFsMp2MMODMMD5R55W4y9EsTi\n' +
        'wWtRR9t8ihpYHbzZJRCLX4NpLMpdEypcr3tWuSev+xJ2R/JQUbykg1u2IeFyf4hE\n' +
        'DqfNcuORRduys4NMbwIDAQAB\n' +
        '-----END PUBLIC KEY-----',
    prikey:
        '-----BEGIN PRIVATE KEY-----\n' +
        'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAKfwIdnFvWtzEp4K\n' +
        '/lfkPNT0mCDA1r/Wufk2IoQKPrMzgkDS/1KMfp42PDazSRVV3vAWwynYww4MwwPl\n' +
        'HnlbjL0SxOLBa1FH23yKGlgdvNklEItfg2ksyl0TKlyve1a5J6/7EnZH8lBRvKSD\n' +
        'W7Yh4XJ/iEQOp81y45FF27Kzg0xvAgMBAAECgYBy+ffjBfvChd04MOSjvhJb5j4F\n' +
        'JqGgY92dno+RAQaeX+SMhyjhFmlV3mHpPWQK5GimzZ6+lrYsd1ZI42OyyJgYepW4\n' +
        'YU8AgCuy4eHYxtULoSUiJpZnCP39DfzXgFTPNFRrKwaX+iED5Vr41WiN0Zrt3ofM\n' +
        'dLj6+ffodoe0XHIXQQJBANcphUihW9dap4HV242v6htgtsU7xTVoZZo8pRGoGYfO\n' +
        'HSW3FSiv55g4+nzRQ2THbnoG6m7xb4lAnlinXpCFs+ECQQDH0AtP/4Rw6ypMMuAq\n' +
        'WGdHtnEDyGMgdk54TqmLttEfx3PycHGuWP87zNSUqCfKVBJ4jYaUidwnHSn4gYwo\n' +
        'egpPAkEAjiZuWjfQ14ybElpZ/Vf1kTTqS5BbkBdy2N2sKOgoReLUuvAkWIcuuREb\n' +
        '3I7cuZ5QzOn4xGdaSxGYC/Y3sb/DwQJBAIXzi9Hqi0Aj9yw0IyFgOITT4vnVOdcG\n' +
        'WN7AZVs7iQCT9r/978gg86KEU7MA9APozqUww5sKvKMgIwXs2yg7gHMCQCeVbXu/\n' +
        '9rSgXclmxa1qJnM2Nz+iqn3GDb6y3g8MeFGYUElAB0emByQ8BHVLF3Ia2nAbvRZW\n' +
        'bcX5Tlq06jYlttA=\n' +
        '-----END PRIVATE KEY-----',
};
const encrypt = (rKey: any) => {
    // const publicKey = pki.publicKeyFromPem(sKey.pubkey);
    // return publicKey.encrypt(rKey, 'RAW', {
    //     md: md.sha256.create(),
    //     mgf1: {
    //         md: md.sha1.create(),
    //     },
    // });
    const key = new NodeRSA(sKey.pubkey, 'pkcs8-public', {
        encryptionScheme: 'pkcs1',
    });
    return key.encrypt(rKey, 'base64');
};

function getRuntimeAppId() {
    let key = '';
    let appId = 'com.jd.ee.me.client';
    key = process.env.REACT_APP_RUNTIME || 'mebjJZB';
    appId = `${appId}.${key}`;
    return appId;
}

function getSsoAppkey() {
    const duccHost = location.host || '';
    return duccHost.includes('egov') ? 'jzbDesktop' : 'meDesktop';
}

function genRandomKey() {
    return crypto.randomBytes(16).toString('hex').slice(0, 16);
}

function genRequestUrl(isBeta: boolean, duccHost?: string) {
    if (duccHost) {
        return new URL(`${duccHost}/common/api/sso/domains`);
    }

    return new URL(`https://jzb${isBeta ? '-pre' : ''}.jdcloud.com/common/api/sso/domains`);
}

export async function getRuntimeConfig(isBeta: boolean): Promise<AppRuntimeConfig[]> {
    try {
        let runtimeConfig: any;
        if (process.env.REACT_APP_DUCC === 'local') {
            runtimeConfig = duccConfig;
            return runtimeConfig;
        } else {
            const appId = getRuntimeAppId();
            const randomKey = genRandomKey();
            console.log('location.host', location.host, process.env.REACT_APP_DUCC_DOMAIN_URL);
            const reqUrl = genRequestUrl(isBeta, process.env.REACT_APP_DUCC_DOMAIN_URL);
            reqUrl.searchParams.append('content', appId);
            const headers = {
                'sso-app-key': getSsoAppkey(),
                cipherText: encrypt(randomKey),
            };
            logger.debug('get app config from ducc: ', {
                url: reqUrl.toString(),
                headers,
                isBeta,
            });
            const res = await axios.get(reqUrl.toString(), {
                headers,
                timeout: 3000,
            });
            const { errorCode, errorMsg, content } = res.data;
            if (errorCode !== '0') {
                throw new Error(`response error: [${errorCode}]${errorMsg}`);
            }
            const key = CryptoJS.enc.Utf8.parse(randomKey);
            const iv = CryptoJS.enc.Utf8.parse('0102030405060708');
            const decrypted = CryptoJS.AES.decrypt(content, key, {
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            const str = CryptoJS.enc.Utf8.stringify(decrypted);
            const data = JSON.parse(str);
            return data;
        }
        // return data.find((item: any) => item.config.appId === appId);
    } catch (error) {
        logger.error(`failed to get app config from ducc: ${error}`);
        // eslint-disable-next-line global-require
        throw new Error(`failed to get app config from ducc: ${error}`);
    }
}

export async function getEnvByRuntimeAndEnv(
    runtime: string,
    env: string,
    isDev?: boolean
): Promise<AppRuntimeConfig> {
    log.debug(`get config by: runtime: ${runtime}, env: ${env}`);
    try {
        if (isDev) {
            // eslint-disable-next-line no-param-reassign
            env = 'beta';
        }
        const runtimeAppId = getRuntimeAppId() || `com.jd.ee.me.client.${runtime}`;
        let runtimeConfig: any;
        if (process.env.REACT_APP_DUCC === 'local') {
            runtimeConfig =
                Array.isArray(duccConfig) &&
                duccConfig.find((item: any) => item.config.appId === runtimeAppId);
        } else {
            const randomKey = crypto.randomBytes(16).toString('hex').slice(0, 16);
            console.log('location.host===>', location.host, process.env.REACT_APP_DUCC_DOMAIN_URL);
            const reqUrl = genRequestUrl(env === 'beta', process.env.REACT_APP_DUCC_DOMAIN_URL);
            reqUrl.searchParams.append('content', runtimeAppId);
            const headers = {
                'sso-app-key': 'meDesktop',
                cipherText: encrypt(randomKey),
            };
            logger.debug('get app config from ducc: ', { url: reqUrl.toString(), headers });
            const res = await axios.get(reqUrl.toString(), {
                headers,
                timeout: 15000,
            });
            if (res.status !== 200) {
                throw new Error(
                    `failed to request config(${runtimeAppId}) server [${res.status}]${res.statusText}`
                );
            }
            const { errorCode, errorMsg, content } = res.data;
            if (errorCode !== '0') {
                throw new Error(`response error: [${errorCode}]${errorMsg}`);
            }
            const key = CryptoJS.enc.Utf8.parse(randomKey);
            const iv = CryptoJS.enc.Utf8.parse('0102030405060708');
            const decrypted = CryptoJS.AES.decrypt(content, key, {
                iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
            const str = CryptoJS.enc.Utf8.stringify(decrypted);
            const data = JSON.parse(str);
            console.log('getEnvByRuntimeAndEnv-data', data);
            log.debug('ducc json -> \n', JSON.stringify(data, null, '\t'));
            runtimeConfig = data.find((item: any) => item.config.appId === runtimeAppId);
        }
        if (
            !runtimeConfig ||
            !runtimeConfig.config ||
            !Array.isArray(runtimeConfig.config.environments)
        ) {
            throw new Error(`can not get runtimeConfig for ${runtimeAppId}`);
        }
        return runtimeConfig;
    } catch (error) {
        logger.error(`failed to get app config from ducc: ${error}`);
        // eslint-disable-next-line global-require
        throw new Error(`failed to get app config from ducc: ${error}`);
    }
}

export function ApplyAuthorResourceToConfig(permissions: IPermissions[]) {
    // voip
    config[ChatConfig.CHAT_SESSION_CREATE_VOIP_ENABLE] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.VOIP
    );
    // 加入会议
    config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.MEETING_JOIN
    );

    // 我的会议
    config[ConfigEnum.TOP_BAR_MENU_MEETING_MEETINGLIST] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.MEETING_JOIN
    );
    // 创建会议
    config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.MEETING
    );
    // 预约会议
    config[ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.MEETING
    );

    config[ConfigEnum.EXTERNAL_CONTACTS_ORG] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.ORG
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_MY_DEPARTMENT] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.MY_DEPARTMENT
    );
    // 搜索外部联系人控制部门
    config[ConfigEnum.EXTERNAL_CONTACTS_SEARCH_DEPARTMENT] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.SEARCH_DEPARTMENT
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_ADD_CONTACTS] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.ADD_CONTACTS
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_GROUP] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.CREAT_GROUP
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_SCHEDULE] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.CREAT_SCHREDULE
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_TASK] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.CREAT_TASK
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_URGING] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.CREAT_URGING
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_DOCUMENT] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.CREAT_DOCUMENT
    );
    config[ConfigEnum.EXTERNAL_CONTACTS_EXPORT_DOCUMENT] = findResourceIdInAuthorPermission(
        permissions,
        AuthorResourceEnum.EXPORT_DOCUMENT
    );
}

export function findResourceIdInAuthorPermission(
    permissions: IPermissions[],
    code: AuthorResourceEnum
) {
    // const index = permissions.findIndex((permission) => {
    //     return permission.resources.findIndex((r) => r.resourceId === resourceId) !== -1;
    // });
    const index = permissions.findIndex((permission) => permission.code === code);
    // console.log(index, permissions, code, 'index');
    return index !== -1;
}
