import { PROCESS_STATUS_LIST } from '@/components/Projects/emnu';
import { Button, Empty, Input, message, Radio } from 'antd';
import { connect } from 'dva';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskDetailContext } from '../index';

import AdvanceCom from './AdvanceCom';

function AdvanceRecord({
    userData,
    taskId,
    handleModifyDetailInfo,
    isTaskFinishOrStop,
    isHead,
    isExecutorsSelf,
    pullRecords,
}: {
    userData: any;
    taskId: string;
    handleModifyDetailInfo: (data: any) => boolean;
    isTaskFinishOrStop: boolean;
    isHead: boolean;
    pullRecords: boolean | number;
    isExecutorsSelf: boolean;
}) {
    let { taskDetail } = useContext(TaskDetailContext);
    const { t } = useTranslation('work');
    // const [RecordsData, setRecordsData] = useState<AdvanceRecordModel[]>([]);
    // const [total, setTotal] = useState(0);
    // const [count, setCount] = useState(1);
    const [Comment, setComment] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const [processStatus, setProcessStatus] = useState(taskDetail.processStatus);

    const isCheck = useMemo(() => {
        // 当前任务是负责人或者协同人时没有完成任务或者终止任务 可以提交推进说明
        return !isTaskFinishOrStop && (isHead || isExecutorsSelf);
    }, [isTaskFinishOrStop, isHead, isExecutorsSelf]);
    /**
     * 初始化数据
     */
    // useEffect(() => {
    //     if (!taskId) {
    //         return;
    //     }
    //     getRecords();
    //     return () => {
    //         setRecordsData([]);
    //     };
    // }, [count]);

    // function getRecords() {
    //     const params = {
    //         taskId: taskId,
    //         offset: (count - 1) * 20,
    //         limit: 20,
    //     };
    //     getTaskUpdateRecords(params).then(([result, err]: any) => {
    //         if (err) {
    //             message.error(err as string);
    //             return;
    //         }
    //         if (!result && !result.records.length) {
    //             return;
    //         }
    //         setTotal(result?.total);
    //         const data = [...RecordsData, ...result?.records].sort(function (a: any, b: any) {
    //             return b.time - a.time;
    //         });
    //         setRecordsData(data);
    //     });
    // }
    // async function handleSubmit() {
    //     // console.log(taskDetail, 'taskDetail');
    //     if (!taskDetail) {
    //         return;
    //     }

    //     if (!Comment.trim()) {
    //         message.warn(t('comment task content'));
    //         return;
    //     }
    //     setSubmitting(true);
    //     setPullRecords(false);
    //     const isSuccess = await handleModifyDetailInfo({
    //         taskId: taskId,
    //         comment: Comment,
    //         processStatus: processStatus,
    //     });
    //     setSubmitting(false);
    //     if (isSuccess) {
    //         message.success(t('save success'));
    //         // getRecords();
    //         setPullRecords(true);
    //         setComment('');
    //         return;
    //     } else {
    //         return;
    //     }
    // }
    const advanceRecord = useCallback(() => {
        return taskId && <AdvanceCom className="as-to-task" pullRecords={pullRecords} />;
    }, [taskId, pullRecords]);

    return (
        <div className="advance-record-wrapper">
            {advanceRecord()}
            {/* {isCheck && (
                <div className="advance-status">
                    <div className="detail-content-type">{t('advance-status')}</div>
                    <Radio.Group
                        onChange={(e) => {
                            if (e.target.value !== taskDetail?.processStatus) {
                                setProcessStatus(e.target.value);
                            }
                        }}
                        value={processStatus}
                    >
                        {PROCESS_STATUS_LIST.map((item, key) => {
                            return (
                                item.text !== '未查看' && (
                                    <Radio key={key} value={item.value}>
                                        {item.text}
                                    </Radio>
                                )
                            );
                        })}
                    </Radio.Group>
                    <div className="textarea">
                        <Input.TextArea
                            placeholder={t('comment description required')}
                            rows={4}
                            value={Comment}
                            onChange={(e) => {
                                e.stopPropagation();
                                setComment(e.target.value);
                                // setTaskDetail({ ...taskDetail, comment: e.target.value });
                            }}
                            maxLength={500}
                        />
                        <p className="text-length">
                            <span>{Comment ? Comment.length : 0}</span>/500
                        </p>
                    </div>
                    <Button
                        size="middle"
                        loading={submitting}
                        onClick={handleSubmit}
                        type="primary"
                        disabled={!Comment}
                        style={{ float: 'right', marginTop: '10px' }}
                    >
                        {t('submit')}
                    </Button>
                </div>
            )} */}
        </div>
    );
}
function mapStateToProps({ work, user }: any) {
    const { detailState } = work;
    return {
        taskId: detailState.taskId,
        userData: user.userData.user,
    };
}

function mapDispatchToProps(_dispatch: any) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceRecord);
