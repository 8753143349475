export interface ItemCommon {
    type: string;
    id: string;
    title: string;
    iconUrl: string;
    isSelected?: boolean;
}
export interface Folder extends ItemCommon {
    type: 'folder';
    spaceId: string;
}

export interface Page extends ItemCommon {
    type: 'page';
    pageType: string | number;
    mimeType: string;
    link: string;
    linkH5: string;
    permissions: string[];
    permissionType: any;
    highlight?: any[];
    // status: number;
    disabled: boolean;
}

export type ListItem = Folder | Page;

export interface TabPanelCommonProps {
    key: string;
}

export interface TabPanelSearchProps extends TabPanelCommonProps {
    key: string;
    search: string;
}

export interface DataListProps {
    searchValue?: string;
    items: any[];
    /**
     * 文档标题
     */
    title?: string;

    /**
     * loading
     */
    loading?: boolean;
    /**
     *
     */
    hasMore?: boolean;

    currentFolder?: Folder | any;

    /**
     * 返回上一层
     */
    onBack?: () => void;

    /**
     * 文件夹被点击
     */
    onFolderClick?: (folder: any) => void;

    /**
     * 文件被点击
     */
    onPageClick?: (page: Page) => void;

    /**
     * 加载更多
     */
    loadMore?: () => void;
}

export interface StoreState {
    selectedItems: Page[];
    /**
     * 最大可选择数量 -1不限制
     * @default -1
     */
    max: number;
    /**
     *  异常类型
     */
    errorType: string;
}

export enum StateActionNames {
    TOGGLE_SELECTED = 'TOGGLE_SELECTED',
    CLEAR_SELECTED = 'CLEAR_SELECTED',
    SET_MAX = 'SET_MAX',
    CLEAR_ERROR_TYPE = 'CLEAR_ERROR_TYPE',
    EDIT_PERMISSIONTYPE = 'EDIT_PERMISSIONTYPE',
    EDIT_PERMISSIONTYPE_ALL = 'EDIT_PERMISSIONTYPE_ALL',
    DELETE_SELECTED = 'DELETE_SELECTED',
}

export interface ToggleSelectedAction {
    action: StateActionNames.TOGGLE_SELECTED;
    item: Page;
}

export interface ClearSelectedAction {
    action: StateActionNames.CLEAR_SELECTED;
}

export interface SetMaxAction {
    action: StateActionNames.SET_MAX;
    max: number;
}

export interface ClearErrorTypeAction {
    action: StateActionNames.CLEAR_ERROR_TYPE;
}

export interface ClearErrorTypeAction {
    action: StateActionNames.CLEAR_ERROR_TYPE;
}

export interface EditPermissionTypeAction {
    action: StateActionNames.EDIT_PERMISSIONTYPE;
    item: Page;
    permissionType: any;
}

export interface EditPermissionTypeAllAction {
    action: StateActionNames.EDIT_PERMISSIONTYPE_ALL;
    permissionType: any;
}

export interface DeleteStateAction {
    action: StateActionNames.DELETE_SELECTED;
    item: any;
}

export type StoreAction =
    | ToggleSelectedAction
    | ClearSelectedAction
    | SetMaxAction
    | ClearErrorTypeAction
    | EditPermissionTypeAction
    | EditPermissionTypeAllAction
    | DeleteStateAction;
