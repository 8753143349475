import React from 'react';
import Icon from '../../../Icon';
import conf from './conf';
import { getStatus } from './index';

interface Props {
    progress: string;
    show: boolean;
    readonly: boolean;
    onChange: (value: string) => void;
    onShow: (show: boolean) => void;
}

export default function (props: Props) {
    const { progress, show, readonly, onChange, onShow } = props;
    const active = getStatus(progress);

    React.useEffect(() => {
        onShow(show);
    }, [show, onShow]);

    const renderer = React.useCallback(
        (o) => (
            <li
                className={progress === o.id ? 'active' : ''}
                key={o.id}
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(o.id);
                    onShow(false);
                }}
            >
                <div style={{ backgroundColor: o.bgColor, color: o.color }}>{o.text}</div>
                {progress === o.id && <Icon type="iconapp_ic_finish" />}
            </li>
        ),
        [onChange, onShow, progress]
    );

    return (
        <div className="pm-status-dropbox">
            <div
                className="pm-status-dropbox-header"
                onClick={() => {
                    !readonly && onShow(!show);
                }}
            >
                <span style={{ color: active.color }}>{active.text}</span>
                {!readonly && (show ? <Icon type="iconicon_up" /> : <Icon type="iconicon_down" />)}
            </div>
            {show && (
                <div className="pm-status-dropbox-menu">
                    <ul>{conf.canEditStatus.map(renderer)}</ul>
                </div>
            )}
        </div>
    );
}
