import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, message } from 'antd';
import AudioMp3 from '@/assets/ringoutdou.mp3';
import { getPlatform, handleMeetingSendMessage, joinMeeting } from '../utils';
import { isFocusEnv } from '@/utils';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';

import { createMeeting, CreateMeetingResult } from '@/api/meeting';
import dayjs from 'dayjs';
import logger from '@/utils/logger';
import { changeEgovUrl } from '@/utils/tools';
import { AVSDK, BasicUserInfo, sessionStateContentMap, SessionStateEnum } from '../common';

export default function MeetingCallModal() {
    const { authInfo, currentEmployee } = useContext(GlobalContext);
    const [visible, setVisible] = useState(false);
    const [videoOff, setVideoOff] = useState(true);
    const [audioOff, setAudioOff] = useState(true);

    const [currentCallUser, setCurrentCallUser] = useState<BasicUserInfo | null>(null);
    const [currentAvsdk, setCurrentAvsdk] = useState<AVSDK>(AVSDK.ZOOM);
    const [currentMeetingData, setCurrentMeetingData] = useState<CreateMeetingResult | null>(null);

    const [t_common] = useTranslation('common');

    const history = useHistory();

    const handleChatMeetingMessage = useCallback(
        async (data: any) => {
            if (!visible || !currentCallUser || !currentMeetingData) {
                return;
            }
            const { ext = {} } = data;
            const { sessionState } = ext;
            if (sessionState === SessionStateEnum.Call) {
                return;
            }
            // 如果消息的发送方
            if (ext.fromUser.pin === currentCallUser.pin) {
                if (sessionState === SessionStateEnum.UserReceive) {
                    // bus.off('meeting:receive-chat-message', handleChatMeetingMessage);
                    joinMeeting(currentAvsdk, {
                        meetingNumber: currentMeetingData.meetingId.toString(),
                        meetingPassword: currentMeetingData.password || '',
                        isaudiooff: audioOff,
                        isvideooff: videoOff,
                    });
                    closeModal();
                } else if (
                    sessionState === SessionStateEnum.UserReject ||
                    sessionState === SessionStateEnum.TIMEOUT ||
                    sessionState === SessionStateEnum.Busy
                ) {
                    closeModal();
                }
            }
        },
        [audioOff, currentAvsdk, currentCallUser, currentMeetingData, videoOff, visible]
    );

    useEffect(() => {
        async function handleMeetingCreate({
            avsdk,
            toUser,
            meetingInfo,
            type = 'meeting',
        }: {
            avsdk: AVSDK;
            toUser: BasicUserInfo;
            meetingInfo: {
                meetingTopic: string;
                meetingPassword?: string;
            };
            type: any;
        }) {
            const { headImg, ddAppId, selectedTeamId, userId } = authInfo;
            const realName = currentEmployee.name;
            if (toUser.pin === userId) {
                message.error('无法呼叫自己');
                return;
            }
            if (visible || !avsdk) {
                return;
            }

            setVisible(true);
            setAudioOff(type === 'meeting' ? true : false);

            const [meetingData, err] = await createMeeting({
                startTime: (dayjs().valueOf() + 5000).toString(),
                duration: 2,
                topic: meetingInfo.meetingTopic,
                password: meetingInfo.meetingPassword || '',
                participantIds: [
                    {
                        userId,
                        teamId: selectedTeamId,
                        appId: ddAppId,
                        realName: realName || '',
                        headImg: headImg || '',
                    },
                    {
                        userId: toUser.pin,
                        teamId: toUser.teamId || '',
                        appId: toUser.app || '',
                        realName: toUser.name || '',
                        headImg: '',
                    },
                ],
                platform: getPlatform(avsdk),
            });
            if (err || !meetingData) {
                message.error(err);
                closeModal();
                return;
            }
            setCurrentCallUser(toUser);
            setCurrentMeetingData(meetingData);
            setCurrentAvsdk(avsdk);
            handleMeetingSendMessage({
                avsdk,
                meetingInfo: {
                    meetingTopic: meetingInfo.meetingTopic,
                    meetingNumber: meetingData.meetingId.toString(),
                    password: meetingInfo.meetingPassword || '',
                },
                sessionState: SessionStateEnum.Call,
                toUsers: [toUser],
            });
        }
        bus.on('meeting:create-single-call', handleMeetingCreate);
        bus.on('meeting:receive-chat-message', handleChatMeetingMessage);
        return () => {
            bus.off('meeting:create-single-call', handleMeetingCreate);
            bus.off('meeting:receive-chat-message', handleChatMeetingMessage);
        };
    }, [authInfo, currentEmployee.name, handleChatMeetingMessage, visible]);

    function closeModal() {
        setVisible(false);
    }
    function handleMenuClick(key: string) {
        if (key === 'audio') {
            setAudioOff(!audioOff);
        }
        if (key === 'video') {
            setVideoOff(!videoOff);
        }
    }

    function onCancel() {
        // 如果已经发出呼叫消息，关闭的时候发起取消消息
        if (!currentCallUser || !currentMeetingData) {
            return;
        }
        const { topic, meetingId, password } = currentMeetingData;
        handleMeetingSendMessage({
            avsdk: currentAvsdk,
            meetingInfo: {
                meetingTopic: topic,
                meetingNumber: meetingId.toString(),
                password: password || '',
            },
            sessionState: SessionStateEnum.Cancel,
            toUsers: [currentCallUser],
        })
            .then(closeModal)
            .catch(closeModal);
    }

    if (!currentCallUser) {
        return null;
    }
    const { avatar, name } = currentCallUser;
    return (
        <Modal
            className="desk-me-meeting-call-modal"
            width={780}
            visible={visible}
            maskClosable={false}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            destroyOnClose
            footer={null}
        >
            <div className="me-meeting-call-body">
                <div className="me-meeting-call-container">
                    <div className="avatar-outter">
                        <div className="avatar-inner">
                            <Avatar
                                className="meeting-avatar"
                                src={changeEgovUrl(avatar)}
                                name={name || ''}
                                styleObj={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    lineHeight: 80,
                                    color: '#fff',
                                    fontSize: 22,
                                }}
                            />
                        </div>
                    </div>
                    <div className="meeting-call-name">{name}</div>
                    <div className="meeting-call-desc">{t_common('joinMeeting.connecting')}</div>
                </div>
                <div className="me-meeting-call-actions">
                    <div className="me-meeting-call-menu">
                        <div className="me-meeting-call-menu-container">
                            <span
                                className={`me-meeting-call-menu-item
                                    ${audioOff ? 'me-meeting-call-menu-item-disable' : ''}`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {audioOff ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(audioOff ? 'joinMeeting.closed' : 'joinMeeting.open')}
                            </span>
                        </div>

                        <div className="me-meeting-call-menu-container">
                            <span
                                className="me-meeting-call-menu-item me-meeting-call-menu-close"
                                onClick={onCancel}
                            >
                                <IconFont type="iconapp_btn_joymerting_hangup" />
                            </span>
                            <span className="label">{t_common('joinMeeting.Hang up')}</span>
                        </div>

                        <div className="me-meeting-call-menu-container">
                            <span
                                className={`me-meeting-call-menu-item ${
                                    videoOff ? 'me-meeting-call-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('video')}
                            >
                                {videoOff ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(videoOff ? 'joinMeeting.closed' : 'joinMeeting.open')}
                            </span>
                        </div>
                    </div>
                </div>
                <audio src={AudioMp3} loop autoPlay />
            </div>
        </Modal>
    );
}
