import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import AuthService from '@/services/AuthService';
import { IFocusSDK } from '@/utils';
import AppStoreService from '@/services/AppStore';
import FocusService from '@/services/FocusService';
import EBookService from '@/services/EBookService';
import SearchService from '@/services/SearchService';
import EUserService from '@/services/EUserService';

export default class Service {
    appRuntime: AppRuntimeEnv;
    authService: AuthService;
    appStoreService: AppStoreService;
    focusService: FocusService;
    ebookService: EBookService;
    searchService: SearchService;
    euserService: EUserService;
    constructor(appRuntime: AppRuntimeEnv, focusSDK: IFocusSDK) {
        this.appRuntime = appRuntime;
        this.authService = new AuthService(appRuntime, focusSDK);
        this.appStoreService = new AppStoreService(appRuntime, focusSDK);
        this.focusService = new FocusService(appRuntime, focusSDK);
        this.ebookService = new EBookService(appRuntime, focusSDK);
        this.searchService = new SearchService(appRuntime, focusSDK);
        this.euserService = new EUserService(appRuntime, focusSDK);
    }
}
