import { createContext } from 'react';
import { User } from '../types';
import i18n, { LANGUAGE } from '@/i18n';
import { useTranslation as _useTranslation } from 'react-i18next';
import { FolderInfoRes } from '@/models/joyspace';
export function useTranslation() {
    return _useTranslation('joyspace')[0];
}
export function useTranslation2(params?: any) {
    return _useTranslation(params);
}

export function lang(name: string) {
    return i18n.t(`joyspace:${name}`);
}

export { LANGUAGE };

export interface State {
    count: number;
}

export const state: State = {
    count: 0,
};

export interface Reducers {
    [key: string]: (state: State, action: Action) => State;
}

export const reducers = {
    test: (state: State) => {
        return { ...state, count: ++state.count };
    },
};

export interface Action {
    type: keyof typeof reducers;
    payload?: { [key: string]: any };
}

export default createContext<{
    lang: (name: string) => string;
    user: User;
    state: State;
    urlPrefix: string;
    dispatch: (action: Action | string) => void;
    deleteFolderById: Function;
    changeFolderInfoById: Function;
    freshLeftTreeByFolderById: Function;
    getCurrentRouterInfo: Function;
    currentRouterInfo: FolderInfoRes | null;
    selectTableArray: Set<FolderInfoRes>;
    setSelectTableArray: Function;
    clearSelectTableArray: Function;
    addFolderInfo: Function;
}>({} as any);
