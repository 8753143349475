import { WorkUserInfo, TaskModel, TimeClassifyEnum, TaskStatus } from '@/types/work';
import dayjs, { Dayjs } from 'dayjs';
import { ITimeBlock } from '@/models/work';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { isArray } from 'util';

/**
 * 更新任务中的执行人信息
 * @param executors
 * @param executor
 */
export function updateExecutorInTask(executors: WorkUserInfo[], executor: WorkUserInfo) {
    const index = findIndexExecutorInTask(executors, executor);
    if (index === -1) {
        return executors;
    }
    const newArr = [...executors];
    newArr.splice(index, 1, executor);
    return newArr;
}
/**
 * 找到执行人的位置
 * @param executors
 * @param executor
 */
export function findIndexExecutorInTask(executors: WorkUserInfo[], executor: WorkUserInfo) {
    const index = executors.findIndex(
        (e) => e.userId + e.teamId + e.app === executor.userId + executor.teamId + executor.app
    );
    return index;
}
export function updateTaskInTimeBlock(timeBlocks: ITimeBlock[], task: TaskModel) {
    timeBlocks.some((timeBlock) => {
        const index = timeBlock.list.findIndex((t) => t.taskId === task.taskId);
        if (index > -1) {
            const list = timeBlock.list;
            list.splice(index, 1, task);
            timeBlock.list = list;
            return true;
        }
        return false;
    });
    return [...timeBlocks];
}
/**
 * 从执行人列表里找到自己,使用userData
 * @param executors
 * @param userData
 */
export function findSelfInExecutors(executors: WorkUserInfo[], userData: any) {
    const index = findIndexSelfInExecutors(executors, userData);
    if (index > -1) {
        return executors[index];
    }
    return undefined;
}
/**
 * 从执行人列表里找到自己的位置
 * @param executors
 * @param userData
 */
export function findIndexSelfInExecutors(executors: WorkUserInfo[], userData: any) {
    const teamId = userData.team ? userData.team.teamId : '';
    const app = userData.team ? userData.team.ddAppId : '';
    return executors.findIndex(
        (e) => e.userId + e.teamId + e.app === userData.userId + teamId + app
    );
}

/**
 * 装饰个人任务列表，补全和转换一些字段，
 * 所有任务拉取回来之后都需要进行转换
 * @param taskList
 */
export function decoratePersonalTask(taskList: TaskModel[]) {
    if (!taskList || taskList.length === 0) {
        return [];
    }
    taskList.forEach((task) => {
        task.workStartTime = task.startTime ? dayjs(task.startTime) : null;
        task.workStopTime = task.endTime ? dayjs(task.endTime) : null;
        if (!task.executors) {
            task.executors = [];
        }
        if (!task.childWorks) {
            task.childWorks = [];
        }
        if (!task.owners) {
            task.owners = [] as any;
        }
        if (!task.resources) {
            task.resources = [];
        }
        if (!task.projectId) {
            task.projectId = '';
        }
        if (task.duCount) {
            task.duCount = Number(task.duCount);
        }
        if (task.extraConf) {
            const { extraConf } = task;
            if (typeof extraConf.openView !== 'undefined') {
                extraConf.openView = Number(extraConf.openView);
            }
        }
        task.sourceType = Number(task.sourceType);
        task.taskStatus = Number(task.taskStatus);
        task.executors.forEach((e) => {
            e.processStatus = Number(e.processStatus);
        });
    });
    return taskList;
}

/**
 * 依据时间块生成对应时间
 * 在初始化拉取列表时会用到timeclassify
 * @param timeClassify
 */
export function generateTimeRange(timeClassify: number) {
    switch (timeClassify) {
        case TimeClassifyEnum.Past:
            return { endTimeTo: dayjs().valueOf().toString() };
        case TimeClassifyEnum.Today:
            return {
                endTimeFrom: dayjs().valueOf().toString(),
                endTimeTo: dayjs().startOf('day').add(1, 'day').valueOf().toString(),
            };
        case TimeClassifyEnum.Week:
            return {
                endTimeFrom: dayjs().startOf('day').add(1, 'day').valueOf().toString(),
                endTimeTo: dayjs().startOf('day').add(1, 'day').add(7, 'day').valueOf().toString(),
            };
        case TimeClassifyEnum.LaterOrUntime:
        case TimeClassifyEnum.Temporary:
            return {
                endTimeFrom: dayjs()
                    .startOf('day')
                    .add(1, 'day')
                    .add(7, 'day')
                    .valueOf()
                    .toString(),
                timeClassify: TimeClassifyEnum.LaterOrUntime,
            };
        case TimeClassifyEnum.Finished:
            return {
                timeClassify: TimeClassifyEnum.Finished,
            };
        default:
            return {};
    }
}

/**
 * 依据classify生成对应时间范围
 * @param time
 */
export function getTimeClassifyByTime(time: Dayjs | null) {
    if (!time) {
        return TimeClassifyEnum.LaterOrUntime;
    }
    const now = dayjs();
    if (time.isBefore(now)) {
        return TimeClassifyEnum.Past;
    }
    if (time.isBefore(now.startOf('day').add(1, 'day'))) {
        return TimeClassifyEnum.Today;
    }
    if (time.isBefore(now.startOf('day').add(1, 'day').add(7, 'day'))) {
        return TimeClassifyEnum.Week;
    }
    return TimeClassifyEnum.LaterOrUntime;
}

/**
 * 找到当前时间块之前的最后一条任务id
 * @param timeBlocks
 * @param timeClassify
 */
export function findLatestTaskId(timeBlocks: ITimeBlock[], timeClassify: number) {
    let index = timeClassify;
    let taskId = '';
    if (index <= TimeClassifyEnum.Past) {
        return '';
    }
    index--;
    while (index >= TimeClassifyEnum.Past) {
        if (timeBlocks[index].lastTaskId) {
            taskId = timeBlocks[index].lastTaskId;
            break;
        }
        index--;
    }
    return taskId;
}
/**
 * 找到当前时间块之前的最后一条任务id
 * @param timeBlocks
 * @param timeClassify
 */
export function findFirstAfterTaskId(timeBlocks: ITimeBlock[], timeClassify: number) {
    let index = timeClassify;
    let taskId = '';
    if (index >= TimeClassifyEnum.LaterOrUntime) {
        return '';
    }
    index++;
    while (index <= TimeClassifyEnum.LaterOrUntime) {
        const list = timeBlocks[index].list;
        if (list.length > 0) {
            taskId = list[0].taskId;
            break;
        }

        index++;
    }
    return taskId;
}

export function findPersonalTaskInTimeBlock(
    timeBlocks: ITimeBlock[],
    taskId: string
): TaskModel | null {
    let task = null;
    timeBlocks.some((t) => {
        const index = t.list.findIndex((l) => l.taskId === taskId);
        if (index > -1) {
            task = t.list[index];
        }
        return index > -1;
    });
    return task;
}

export function generateEndTimeByTimeClassify(timeClassify: number, endTime: Dayjs | null) {
    let newEndtime = null;
    const current = dayjs();
    switch (timeClassify) {
        case TimeClassifyEnum.Past:
            if (!endTime) {
                newEndtime = dayjs().startOf('day').subtract(1, 'day').hour(18).minute(0);
            } else {
                newEndtime = dayjs()
                    .startOf('day')
                    .subtract(1, 'day')
                    .hour(endTime.hour())
                    .minute(endTime.minute());
            }

            break;
        case TimeClassifyEnum.Today:
            if (endTime && current.hour(endTime.hour()).minute(endTime.minute()).isAfter(current)) {
                newEndtime = current.startOf('day').hour(endTime.hour()).minute(endTime.minute());
            } else {
                newEndtime = dayjs().startOf('day').hour(23).minute(59);
            }
            break;
        case TimeClassifyEnum.Week:
            if (!endTime) {
                newEndtime = dayjs().add(1, 'day').hour(18).minute(0);
            } else {
                if (endTime.hour() === 0 && endTime.minute() === 0) {
                    newEndtime = dayjs()
                        .startOf('day')
                        .add(2, 'day')
                        .hour(endTime.hour())
                        .minute(endTime.minute());
                } else {
                    newEndtime = dayjs()
                        .startOf('day')
                        .add(1, 'day')
                        .hour(endTime.hour())
                        .minute(endTime.minute());
                }
            }
            break;
        case TimeClassifyEnum.LaterOrUntime:
            newEndtime = endTime
                ? current.startOf('day').add(8, 'day').hour(endTime.hour()).minute(endTime.minute())
                : null;
            break;
        default:
            break;
    }
    return newEndtime;
}

export function findFrontAndAfter(timeBlocks: ITimeBlock[], source: any, destination: any) {
    // const sourceTimeBlock = timeBlocks[Number(source.droppableId)];
    // const sourceList = sourceTimeBlock.list;

    const destinationTimeBlock = timeBlocks[Number(destination.droppableId)];
    const destinationList = destinationTimeBlock.list;
    const opts: { front?: string; after?: string } = {};
    if (destination.index === 0) {
        const front = findLatestTaskId(timeBlocks, Number(destination.droppableId));
        if (front) {
            opts.front = front;
        }
        if (destinationList.length > 1) {
            opts.after = destinationList[1].taskId;
        } else {
            const after = findFirstAfterTaskId(timeBlocks, Number(destination.droppableId));
            if (after) {
                opts.after = after;
            }
        }
        return opts;
    }
    if (destination.index === destinationList.length - 1) {
        opts.front = destinationList[destinationList.length - 2].taskId;
        const after = findFirstAfterTaskId(timeBlocks, Number(destination.droppableId));
        if (after) {
            opts.after = after;
        }
        return opts;
    }
    opts.front = destinationList[destination.index - 1].taskId;
    opts.after = destinationList[destination.index + 1].taskId;
    return opts;
}

export function getDefaultStartTime() {
    const time = dayjs().startOf('day').hour(9);
    return time;
}
export function getDefaultStopTime() {
    const time = dayjs();
    if (time.hour() < 18) {
        return time.startOf('day').hour(18);
    } else {
        return time.startOf('day').add(1, 'day').hour(18);
    }
}

export function judgeTaskFinish(status: number | null | undefined) {
    if (!status) {
        return false;
    }
    return [TaskStatus.Finish, TaskStatus.FINISH_LOCK].includes(status);
}

export function generateDefaultTimeFormat(time: Dayjs | null, t: any) {
    const today = dayjs();
    const label = !time
        ? ''
        : time.isBefore(today.startOf('year')) || time.isAfter(today.endOf('year'))
        ? time.format(`YYYY${t('year')}M${t('month')}D${t('day')}`)
        : today.isSame(time, 'day')
        ? t('today') + ' ' + time.format('HH:mm')
        : today.add(24, 'hour').isSame(time, 'day')
        ? t('tomorrow') + ' ' + time.format('HH:mm')
        : today.subtract(24, 'hour').isSame(time, 'day')
        ? t('yesterday') + ' ' + time.format('HH:mm')
        : time.format(`M${t('month')}D${t('day')}HH:mm`);
    return label;
}
export function generateDefaultTimeFormatByEndLogic(time: Dayjs | null, t: any) {
    const today = dayjs();
    if (!time) {
        return '';
    }
    if (time.isBefore(today.startOf('year')) || time.isAfter(today.endOf('year'))) {
        return time.format(`YYYY${t('year')}M${t('month')}D${t('day')}`);
    }
    if (today.startOf('day').isSameOrAfter(time)) {
        if (today.startOf('day').isSame(time)) {
            return t('yesterday') + ' 24:00';
        }
        return t('yesterday') + ' ' + time.format('HH:mm');
    }
    if (today.startOf('day').add(1, 'day').isSameOrAfter(time)) {
        if (today.startOf('day').add(1, 'day').isSame(time)) {
            return t('today') + ' 24:00';
        }
        return t('today') + ' ' + time.format('HH:mm');
    }
    if (today.startOf('day').add(1, 'day').add(24, 'hour').isSameOrAfter(time)) {
        if (today.startOf('day').add(1, 'day').add(24, 'hour').isSame(time)) {
            return t('tomorrow') + ' 24:00';
        }
        return t('tomorrow') + ' ' + time.format('HH:mm');
    }
    if (time.hour() === 0 && time.minute() === 0) {
        return time.subtract(1, 'millisecond').format(`M${t('month')}D${t('day')}24:00`);
    }
    return time.format(`M${t('month')}D${t('day')}HH:mm`);
}

export function useDefaultFormatTime(time: Dayjs | null, endLogic = false) {
    const { t } = useTranslation('calendar');
    const hour = dayjs().startOf('hour').valueOf();
    return useMemo(() => {
        const today = dayjs(hour);
        const label = endLogic
            ? generateDefaultTimeFormatByEndLogic(time, t)
            : generateDefaultTimeFormat(time, t);
        return label;
    }, [hour, endLogic, time, t]);
}

export function isJSON(str: any) {
    if (typeof str === 'string') {
        try {
            const obj = JSON.parse(str);
            if (typeof obj === 'object' && obj) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    return false;
}

/**
 * 获取‘精简时间’，今年的时间不显示年
 * @param time
 */
export function productBriefTimeStr(time: number | string, format?: string) {
    const ti = dayjs(Number(time));
    const today = dayjs(new Date());
    let fmt = format || 'YYYY年M月D日 HH:mm';
    if (ti.year() === today.year()) {
        fmt = fmt.replace(/YYYY./, '');
    }
    return dayjs(ti).format(fmt);
}

/**
 * 利用浏览器底层转义字符串，防止xss攻击
 * 禁止服务端渲染部分调用
 * @param str
 */
export function stringEncode(str: string) {
    const div = document.createElement('div');
    if (div.innerText) {
        div.innerText = str;
    } else {
        div.textContent = str; // Support firefox
    }
    return div.innerHTML;
}

/**
 * @param ops 要求参数为delta中到ops数组
 * 返回值为html
 */
export function parseDeltaToHtml(ops: any) {
    if (isArray(ops)) {
        const temp = ops.reduce((p1, v1) => {
            let str = p1;
            if (typeof v1.insert === 'string') {
                if (v1.insert !== '\n') {
                    str += stringEncode(v1.insert);
                } else {
                    str += '<br/>';
                }
            } else if (typeof v1.insert === 'object') {
                const mention = v1.insert['mention-link'];
                if (mention) {
                    str += `<a data-type="${mention.type}" data-id="${mention.id}" page-comment-mention-a="${mention.id}" contenteditable="false">@${mention.name}</a>`;
                }
            }
            return str;
        }, '');
        return temp;
    }
    return '';
}

/**
 *
 * 转换用户数据为work的user数据
 * 目前用于新建时生成对应的负责人和执行人
 */
export function transformUserDataToWorkUserInfo(userData: any): WorkUserInfo {
    const { teamUserInfo, userId } = userData;
    return {
        taskId: '',
        userId: teamUserInfo.userId || userId,
        teamId: teamUserInfo.teamId,
        app: teamUserInfo.ddAppId,
        userRole: 0,
        processStatus: 1,
        status: 1,
        creator: '',
        headImg: teamUserInfo.headImg,
        realName: teamUserInfo.realName,
        gmtCreate: '',
        modifier: '',
        gmtModified: '',
    };
}
