// 左侧时间状态条

import React, { useState } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { WithTranslation, withTranslation } from 'react-i18next';

interface SessionCategoryHeaderProps {
    title: string;
    clickEvent: (isShow: boolean) => void;
}

type IProps = Readonly<SessionCategoryHeaderProps & WithTranslation>;

function SessionCategoryHeader(props: IProps) {
    const { clickEvent, title } = props;
    const [isShow, setIsShow] = useState(false);

    const headerClick = () => {
        setIsShow(!isShow);
        clickEvent(!isShow);
    };

    return (
        <div />
        // <div
        //     className="session-header-container"
        //     onClick={headerClick}
        //     clstag={
        //         isShow
        //             ? 'pageclick|keycount|focus_chat_01_1615797500283|13'
        //             : 'pageclick|keycount|focus_chat_01_1615797500283|14'
        //     }
        // >
        //     <div>{title}</div>
        //     <div className="time_icon">
        //         <IconFont type={isShow ? 'iconapp_btn_up' : 'iconapp_btn_down'} />
        //     </div>
        // </div>
    );
}

export default withTranslation('chat')(SessionCategoryHeader);
