/**
 * 各模块的缺省页配置
 * @author sunzhiguang
 * @date 2020/8/10
 */
import React, { useMemo, useEffect } from 'react';
import { Button } from 'antd';

import { Empty } from '@jd/focus-desktop-comps';
import { changeEgovUrl } from '@/utils/tools';
import {
    checkNetworkConnPng,
    commonNodataPng,
    searchEmpty,
    contactAdminPng,
    loadFailedPng,
    lowVersionPng,
    messageNodataPng,
    sessionNodataPng,
    ebookNodataPng,
    darkNodataPng,
    nodata_department,
    contactsNodataPng,
    groupNoData,
    newApplication,
    requestFail,
} from './imgs';
import { CompDataLoadState, DefaultPageType } from '@/models';
import { useTranslation } from 'react-i18next';

const config = {
    [DefaultPageType.NO_USERS_DATA]: {
        image: commonNodataPng,
        description: '暂无人员、设备信息',
    },
    [DefaultPageType.NO_DATA]: {
        image: commonNodataPng,
        description: '暂无数据',
    },
    [DefaultPageType.DARK_NO_DATA]: {
        image: darkNodataPng,
        description: '暂无数据',
    },
    [DefaultPageType.KEYWORD]: {
        image: commonNodataPng,
        description: 'no-keyword',
    },
    [DefaultPageType.EXTERNAL_CONTACT]: {
        image: contactsNodataPng,
        description: '暂无联系人',
    },
    [DefaultPageType.ORG]: {
        image: ebookNodataPng,
        description: '暂无数据',
    },
    [DefaultPageType.NEW_CONTACT]: {
        image: newApplication,
        description: '暂无新的申请',
    },
    [DefaultPageType.MY_DEPARTMENT]: {
        image: ebookNodataPng,
        description: '暂无我的部门',
    },
    [DefaultPageType.MY_LABELS]: {
        image: ebookNodataPng,
        description: '暂无我的标签',
    },
    [DefaultPageType.GROUP]: {
        image: groupNoData,
        description: '暂无组',
    },
    [DefaultPageType.CREATE_GROUP]: {
        image: groupNoData,
        description: '暂无我创建的组',
    },
    [DefaultPageType.JOIN_GROUP]: {
        image: groupNoData,
        description: '暂无我加入的组',
    },
    [DefaultPageType.SESSION]: {
        image: sessionNodataPng,
        description: '',
    },
    [DefaultPageType.MESSAGE]: {
        image: messageNodataPng,
        description: '暂无新消息',
    },
    [DefaultPageType.AT]: {
        image: commonNodataPng,
        description: '没有人@您',
    },
    [DefaultPageType.CHAT_HISTORY_RECORD]: {
        image: commonNodataPng,
        description: '请输入查询条件',
    },
    [DefaultPageType.CHAT_HISTORY_FILE]: {
        image: commonNodataPng,
        description: '没有文件',
    },
    [DefaultPageType.CHAT_HISTORY_IMG_VIDEO]: {
        image: commonNodataPng,
        description: '没有图片',
    },
    [DefaultPageType.SEARCH_HISTORY]: {
        image: searchEmpty,
        description: '未匹配到搜索结果',
    },
    [DefaultPageType.CONTACT_ADMIN]: {
        image: contactAdminPng,
        description: '请联系管理员配置应用',
    },
    [DefaultPageType.APP_SETTING]: {
        image: contactAdminPng,
        description: '请前往管理后台配置应用',
    },
    [DefaultPageType.LOAD_FAILED]: {
        image: loadFailedPng,
        description: '加载失败，请刷新重试',
    },
    [DefaultPageType.LOW_VERSION]: {
        image: lowVersionPng,
        description: '请更新最新版本',
    },
    [DefaultPageType.CHECK_NETWORK_CONN]: {
        image: checkNetworkConnPng,
        description: '请检查网络连接',
    },
    // 此配置为不展示图片调用
    [DefaultPageType.HIDE_IMAGE]: {
        image: undefined,
        description: '',
    },
    // 此配置为默认京办图片调用
    [DefaultPageType.IMG_DEFAULT]: {
        image: ebookNodataPng,
        description: '',
    },
    [DefaultPageType.NODETA_DEPT]: {
        image: nodata_department,
        description: '暂无下一级部门',
    },
    [DefaultPageType.REQUEST_FAIL]: {
        image: requestFail,
        description: '加载失败',
    },
};

interface DefaultPageProps {
    pageType: DefaultPageType;
    dataType: CompDataLoadState;
    keyword?: string;
    description?: string;
}

type IProps = Readonly<DefaultPageProps>;

function DefaultPage(props: IProps) {
    const { pageType, dataType, keyword = '', description = '' } = props;

    const { t } = useTranslation();

    const refreshNode = useMemo(() => {
        if (pageType === DefaultPageType.LOAD_FAILED) {
            return <Button type="primary">刷新</Button>;
        }
        return null;
    }, [pageType]);

    const { image, description: newDescription } = useMemo(() => {
        const typeConfig = config[pageType] || {};
        if (!typeConfig) {
            Object.assign(typeConfig, config[DefaultPageType.NO_DATA]);
        }
        const image = <img src={changeEgovUrl(typeConfig.image)} alt="" draggable="false" />;
        const newDescription = t(description || typeConfig.description);
        return { image, description: newDescription };
    }, [pageType, t, description]);

    return (
        <Empty
            image={image}
            keyword={keyword}
            description={newDescription}
            refreshNode={refreshNode}
        />
    );
}

export default DefaultPage;
