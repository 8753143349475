import { Dayjs } from 'dayjs';
import B2Img from '@/assets/img/taskType/B2_1.png';
import B1Img from '@/assets/img/taskType/B1_1.png';
import A2Img from '@/assets/img/taskType/A2_1.png';
import A1Img from '@/assets/img/taskType/A1_1.png';
import C2Img from '@/assets/img/taskType/C2.png';

// 任务推进记录
export const PriorityList = [
    { label: A1Img, code: 4, color: '#F23030', color1: '#f230304d' },
    { label: A2Img, code: 3, color: '#FFB416', color1: '#ffb4164d' },
    { label: B1Img, code: 2, color: '#5EA7F8', color1: '#5ea7f84d' },
    { label: B2Img, code: 1, color: '#8ADE30', color1: '#8ade304d' },
    { label: C2Img, code: -1, color: '#c5c4c4', color1: '#E1E1E1' },
];
// 任务优先级
export enum PRIORITY_TYPE {
    NO_PRIORITY = -1,
    LOW_PRIORITY = 1, // B2
    NORMAL_PRIORITY, // B1
    MIDDLE_PRIORITY, // A2
    HIGH_PRIORITY, // A1
}

// 任务推进状态
export enum PROCESS_STATUS {
    PUSHING = 1, // 我推进中
    FINISHED, // 我已完成
    DIFFICULT, // 我有困难
    NOT_VIEWED, // 我未查看
}

// 任务状态
export enum TASK_STATUS {
    UNDONE = 1, // 未完成
    FINISHED, // 已完成
    ABORT, // 已终止
}
// 任务角色
export enum USER_ROLE {
    CREATED = 0, // 创建人
    RESPONSIBLE, // 负责人
    COLLABORATIVE, // 协同人
}

export interface TaskListParams {
    limit: number;
    offset?: number;
    offsets?: number[];
    owner?: string;
    endTime?: number;
    executor?: string;
    taskStatuses?: TASK_STATUS[];
    priorityTypes?: PRIORITY_TYPE[];
    processStatuses?: PROCESS_STATUS[];
    tasks?: [];
    sortType?: number;
    total?: number;
    userRole?: number;
}

export interface IEditTaskItemParam {
    taskId: string;
    title?: string;
    startTime?: number;
    endTime?: number;
    priorityType?: PRIORITY_TYPE;
    content?: string;
    taskStatus?: TASK_STATUS;
    processStatus?: PROCESS_STATUS;
    remark?: string;
    owner?: string;
}

export interface TaskResource {
    creatorUserId?: any;
    id?: number;
    taskId?: string; // '任务ID',
    resourceId: string; // '资源ID',
    url: string; // '资源地址',
    name: string; // '资源名称',
    fileType: string; // '文件类型',
    bizType: number; // '业务类型',
    bizId: string; // '业务ID',
    size: number; // '资源大小',
    resourceType: number; // '资源类型(资源权限控制)',
}

export interface WorkUserInfo {
    id?: number;
    userId: string;
    teamId: string;
    app: string;
    headImg: string;
    realName: string;
    deptName?: string; // 部门名称
    titleName?: string; // 职务名称
    processStatus: PROCESS_STATUS; // 协同人推进状态
    userRole?: number;
    weight?: number;
    progress?: number; // 个人进度（百分比：个人自己的工作的完成率）
}
export interface BaseWorkUserInfo {
    userId: string;
    teamId: string;
    app: string;
}
export interface RemarkInfo {
    id: string;
    remark: string;
}
export interface RecordsParams {
    taskId: string;
    offset: number;
    limit: number;
    userId?: string;
    teamId?: string;
    appId?: string;
}
export interface NewTaskModel {
    taskId: string;
    priorityType: PRIORITY_TYPE;
    title: string;
    content: string | null; // 任务描述
    remark: RemarkInfo; // 我的备注
    startTime: number | null;
    endTime: number | null;
    remind?: string; // 提醒列表
    workStartTime?: Dayjs | null;
    workStopTime?: Dayjs | null;
    owner: WorkUserInfo;
    executors: WorkUserInfo[];
    resources: TaskResource[];
    taskStatus?: number;
    comment?: string; // 说明
    userRoles?: number[];
    timeClassify?: number; // 时间分类类型：0-临时，1-逾期，2-今天，3-本周，4-稍后，5-完成
    processStatus?: number;
    progress?: number; // 个人进度（百分比：个人自己的工作的完成率）
    weight?: number; // 整数
    totalWeight?: number;
    totalProgress?: number;
    addExecutors?: BaseWorkUserInfo[];
    delExecutors?: BaseWorkUserInfo[];
    addResources?: TaskResource[];
    delResources?: TaskResource[];
    dueTag?: boolean; // 是否逾期
    groupId?: string; // 融合会议组id
    // processComment?: string;
}
export const processStatusOptions = [
    {
        status: PROCESS_STATUS.PUSHING,
        label: '推进中',
        currentLabel: '我推进中',
        color: '#5DC4E8',
    },
    {
        status: PROCESS_STATUS.FINISHED,
        label: '已完成',
        currentLabel: '我已完成',
        color: '#4BD069',
    },
    {
        status: PROCESS_STATUS.DIFFICULT,
        label: '有困难',
        currentLabel: '我有困难',
        color: '#FF000D',
    },
    {
        status: PROCESS_STATUS.NOT_VIEWED,
        label: '未查看',
        currentLabel: '我未查看',
        color: '#A5A5A5',
    },
];
export const timeBlocks = [
    {
        id: '0',
        title: 'temporaryBlock',
        color: '#EEEEEE',
        timeClassify: 0,
    },
    {
        id: '1',
        title: 'overdue',
        color: '#F6642D',
        timeClassify: 1,
    },
    {
        id: '2',
        title: 'today',
        color: '#8CCC48',
        timeClassify: 2,
    },
    {
        id: '3',
        title: 'in week',
        color: '#379AFF',
        timeClassify: 3,
    },
    {
        id: '4',
        title: 'later or untime',
        color: '#4751C8',
        timeClassify: 4,
    },
    {
        id: '5',
        title: 'finished',
        color: '#8899A4',
        timeClassify: 5,
    },
];

export const taskStatusOptions = [
    {
        status: TASK_STATUS.UNDONE,
        title: '进行中',
        color: (window as any).styleSass.primaryColor,
        bgcolor: '#F0F5FF',
    },
    { status: TASK_STATUS.FINISHED, title: '已完成', color: '#00A645', bgcolor: '#E1F9E2' },
    { status: TASK_STATUS.ABORT, title: '已中止', color: '#62656D', bgcolor: '#F0F0F0' },
];

// 推进记录操作状态
export enum ACTION_TYPE {
    UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS', // "状态"
    UPDATE_OWNER = 'UPDATE_OWNER', // 负责人
    UPDATE_EXECUTOR = 'UPDATE_EXECUTOR', // 协同人
    UPDATE_PRIORITY_TYPE = 'UPDATE_PRIORITY_TYPE', // 任务类型
    UPDATE_CONTENT = 'UPDATE_CONTENT', // 任务描述
    UPDATE_RESOURCE = 'UPDATE_RESOURCE', // 附件
    UPDATE_TITLE = 'UPDATE_TITLE', // 任务名称
    UPDATE_TIME = 'UPDATE_TIME', // 任务时间
    UPDATE_PROCESS_STATUS = 'UPDATE_PROCESS_STATUS', // 推进状态
    UPDATE_REMARK = 'UPDATE_REMARK', // "备注
    CREATE_TASK = 'CREATE_TASK', // 创建任务
    RESUME_TASK = 'RESUME_TASK', // 重新启动任务
    FINISH_TASK = 'FINISH_TASK', // 整体完成任务
    CREATE_SUPERVISE = 'CREATE_SUPERVISE', // 催办
    UPDATE_EXECUTOR_WEIGHT = 'UPDATE_EXECUTOR_WEIGHT', // 任务权重
}

// 推进记录
export interface AdvanceRecordModel {
    item: any;
    recordId: string; // 记录id
    operator: string; // 操作人
    newContent: string; // 新内容
    oldContent: string; // 原内容
    comment: string; // 情况说明
    time: string; // 操作时间
    action: string; // 操作类型
    userProgress?: number | string | null; // 个人推进进度
    totalProgress?: number | string | null; // 任务总进度
}
