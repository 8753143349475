import React from 'react';
import MeetingJD from '@/components/MeetingXYLink';
import { Tabs } from 'antd';
import IconFont from '@/components/icon';

export default function () {
    const { TabPane } = Tabs;
    return (
        <div className="multimedia">
            <Tabs type="card" defaultActiveKey="1">
                <TabPane
                    tab={
                        <span>
                            <IconFont type="iconapp_btn_joymeeting_video" />
                            政务视频会议
                        </span>
                    }
                    key="1"
                >
                    <MeetingJD />
                </TabPane>
            </Tabs>
        </div>
    );
}
