import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './index.less';
import { Spin, message, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { connect } from 'dvajs';
import EventBus from '@/utils/bus';
import icon_join from '@/assets/img/meeting/multimedia-join.png';
import icon_presentation from '@/assets/img/meeting/multimedia-presentation.png';
import icon_camera from '@/assets/img/meeting/multimedia-camera.png';
import icon_user from '@/assets/img/meeting/multimedia-user.png';
import { IconArrowRight, IconMobilePhone } from '@/components/icon';

import { changeEgovUrl } from '@/utils/tools';
import { FocusSDK, getClientType, isFocusEnv } from '@/utils';
import logger from '@/utils/logger';
import { getPermission } from '@/api/user';
import { ApplyAuthorResourceToConfig } from '@/utils/envConfig';
import config, { ConfigEnum } from '@/config/config';
import { getJRTCToken } from '@/api/meeting';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { MeetingPlatform } from '@/types/common';

interface ButtonModel {
    icon: string;
    caption: string;
    click: any;
}

const focusEnv = isFocusEnv();

function TabMultimedia({
    openCreateScheduleModal,
}: {
    openCreateScheduleModal: (data: any) => void;
}) {
    const { authInfo, currentEmployee } = useContext<GlobalContextConfig>(GlobalContext);
    const [now, setNow] = useState<Dayjs>(dayjs());
    let platform = FocusSDK.getDeviceInfo().platform;
    const { t } = useTranslation('common');
    const [fetching, setFetching] = useState(false);
    const [loadingConfig, setLoadingConfig] = useState(false);
    const [buttons, setButtons] = useState<ButtonModel[]>([]);
    const [isExistCurrentMeet, setIsExistCurrentMeet] = useState(
        !!localStorage.getItem('jdmeetUserInfo') && !!localStorage.getItem('currentMeetInfo')
    );
    const history = useHistory();

    const handleQuickJoinLink = useCallback(() => {
        const userInfo: any = localStorage.getItem('jdmeetUserInfo');
        const currentMeetInfo: any = localStorage.getItem('currentMeetInfo');
        const meetingData = {
            meetingInfo: {
                isaudiooff: JSON.parse(currentMeetInfo)?.isaudiooff || true,
                isvideooff: JSON.parse(currentMeetInfo)?.isvideooff || true,
                meetingNum:
                    JSON.parse(currentMeetInfo)?.room || JSON.parse(currentMeetInfo)?.meetingNum,
                password: JSON.parse(currentMeetInfo).password,
            },
            userInfo: JSON.parse(userInfo),
        };
        localStorage.setItem('localUserInfo', userInfo);
        console.log('触发quick-join-meeting', meetingData);
        EventBus.emit('jdmeeting:quickJoin', meetingData);
        EventBus.emit('load:jdmeetingHome', false);
    }, []);
    // 获取权限
    const getUserPermissions = useCallback(async () => {
        const res: any = await getPermission();
        // 依据权限进行config配置
        const config = ApplyAuthorResourceToConfig(res || []);
        return config;
    }, []);
    const handleMeeting = useCallback(
        function (event: string) {
            setFetching(true);
            getJRTCToken({
                nickname: currentEmployee.name || '',
                unit: currentEmployee.deptName || '',
                avatar: authInfo.headImg || '',
                deviceType: getClientType(),
            })
                .then(([jrcToken, err]) => {
                    if (err) {
                        message.error(err);
                        return;
                    }

                    const { ddAppId, userId, selectedTeamId, teamUserInfo } = authInfo;

                    const userInfo = {
                        token: jrcToken.token,
                        deviceType: jrcToken.deviceType,
                        jrtcUserId: jrcToken.jrtcUserId,
                        appId: jrcToken.appId,
                        app: ddAppId,
                        pin: userId,
                        teamId: selectedTeamId || teamUserInfo.teamId,
                        name: currentEmployee.name,
                        avatar: teamUserInfo.headImg || '',
                        deptName: currentEmployee.deptName,
                    };
                    if (focusEnv) {
                        FocusSDK.sendIpcMessage(event, { userInfo });
                    } else {
                        EventBus.emit(event, userInfo);
                        localStorage.setItem('jdmeetUserInfo', JSON.stringify(userInfo));
                        localStorage.setItem('localUserInfo', JSON.stringify(userInfo));
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setFetching(false);
                    }, 1000);
                });
        },
        [authInfo, currentEmployee]
    );
    const handleCreateMeeting = useCallback(
        function () {
            if (focusEnv) {
                handleMeeting('jdmeet.showCreate');
            } else {
                EventBus.emit('load:jdmeetingHome', false);
                handleMeeting('jdmeeting:showCreate');
            }
        },
        [handleMeeting]
    );

    const handleJoinMeeting = useCallback(
        function () {
            if (focusEnv) {
                handleMeeting('jdmeet.showJoin');
            } else {
                EventBus.emit('load:jdmeetingHome', false);
                handleMeeting('jdmeeting:showJoin');
            }
        },
        [handleMeeting]
    );

    const getCreateButtons = useCallback(() => {
        const buttons = [];
        if (config[ConfigEnum.TOP_BAR_MENU_MEETING_CREATE]) {
            buttons.push({
                icon: icon_presentation,
                caption: '创建会议',
                click() {
                    handleCreateMeeting();
                },
            });
        }
        if (config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN]) {
            buttons.push({
                icon: icon_join,
                caption: '加入会议',
                click() {
                    handleJoinMeeting();
                },
            });
        }
        if (config[ConfigEnum.TOP_BAR_MENU_MEETING_APPOINT]) {
            buttons.push({
                icon: icon_camera,
                caption: '预约会议',
                click() {
                    openCreateScheduleModal({
                        visible: true,
                        defaultAppointment: true,
                        meetingPlatform: MeetingPlatform.JBRTC,
                    });
                },
            });
        }
        buttons.push({
            icon: icon_user,
            caption: '我的会议',
            click() {
                EventBus.emit('meeting:meeting-list-show', {
                    platform: MeetingPlatform.JBRTC,
                });
            },
        });
        setButtons(buttons);
    }, [handleCreateMeeting, handleJoinMeeting, openCreateScheduleModal]);

    function handleClearLocalMeetInfo(isClear: boolean) {
        console.log('触发事件测试:', isClear);
        setIsExistCurrentMeet(isClear);
    }

    useEffect(() => {
        const { userId } = authInfo;
        const localJdmeetUser: any = localStorage.getItem('jdmeetUserInfo');
        console.log('authInfo全局：', authInfo);
        console.log('localJdmeetUser：', localJdmeetUser);
        console.log('localJdmeetUser type：', typeof JSON.parse(localJdmeetUser));
        if (!localJdmeetUser) {
            return;
        }
        if (userId === JSON.parse(localJdmeetUser).pin) {
            return;
        }
        localStorage.removeItem('jdmeetUserInfo');
        localStorage.removeItem('currentMeetInfo');
        setIsExistCurrentMeet(false);
    }, [authInfo]);

    useEffect(() => {
        EventBus.on('jdmeeting:exist-local-meetInfo', handleClearLocalMeetInfo);
        return () => {
            EventBus.off('jdmeeting:exist-local-meetInfo', handleClearLocalMeetInfo);
        };
    }, []);

    useEffect(() => {
        setLoadingConfig(true);
        getUserPermissions()
            .then(() => {
                getCreateButtons();
            })
            .finally(() => {
                setLoadingConfig(false);
            });
        return () => {
            setLoadingConfig(false);
        };
    }, [getCreateButtons, getUserPermissions]);

    useEffect(() => {
        // 每分钟更新计时
        const time = (60 - now.second()) * 1000; // 计时补正
        const timer = setTimeout(() => {
            setNow(dayjs());
        }, time);

        return () => {
            clearTimeout(timer);
        };
    });

    // useEffect(() => {

    // }, []);

    return (
        <Spin
            spinning={fetching}
            // tip="加载中"
            wrapperClassName="jdmeet-tab-multimedia tab-multimedia"
        >
            <div className="moment">
                <p className="moment-time">{now.format('HH:mm')}</p>
                <p className="moment-date">{now.format('YYYY.MM.DD')}</p>
            </div>
            {!focusEnv && isExistCurrentMeet && (
                <div className="link-join-meet" onClick={handleQuickJoinLink}>
                    <span className="icon-mobile-phone">{IconMobilePhone}</span>
                    您有1个进行中的语音会议
                    <span className="right-arrow">{IconArrowRight}</span>
                </div>
            )}
            {loadingConfig ? (
                <Spin
                    style={{
                        marginTop: 30,
                    }}
                    size="large"
                />
            ) : (
                <div className="function-list">
                    {buttons.map((item, index) => {
                        return (
                            <div className="function-item" key={index} onClick={item.click}>
                                <div
                                    className="function-item-icon"
                                    style={{
                                        backgroundImage: `url(${changeEgovUrl(item.icon)})`,
                                    }}
                                />
                                <p className="function-item-caption">{item.caption}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </Spin>
    );
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateScheduleModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMultimedia);
