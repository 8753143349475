/* eslint-disable no-console */
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import GlobalContext, { GlobalContextConfig, PreviewImageInfo } from '@/context/GlobalContext';
import { Modals } from '@jd/focus-desktop-comps';
import { initLogAnalytics } from '@/utils/logAnalytics';
import { FocusSDK as focusSDK, getAppLogoConfig, isFocusEnv, getLoginURL } from '@/utils';
import { setGatewayInstance } from '@/server/gateway';
import i18n from '@/i18n';
import { duccConfigI18 } from '@/utils/i18n';
import { TFunction } from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import resources from '@/i18n/resource';
import logger from '@/utils/logger';
import { message, Modal } from 'antd';
import qs from 'query-string';
import GError from '@/utils/GError';
import { BrowserRouter, HashRouter, useHistory, useLocation } from 'react-router-dom';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import Service from '@/services';
import { AuthData } from '@/types/LocalConfig';
import { connect } from 'dvajs';
import cache from '@/utils/cache';
import ImService from '@/server/ImService';
import CalendarImService, {
    CalendarImServiceType,
} from '@/components/Calendars/services/CalendarImService';
import { ChatMessage, Session } from '@/types/chat';
import HomePage from '@/pagesv2/HomePage';
import LoginPage from '@/pagesv2/LoginPage';
import ThemeComp from './components/ThemeComp';
import { initFileUploadService } from '@/server/FileService';
import { EventEmitter } from 'events';
import MeetingReceView from './components/Meeting/MeetingReceView';
import ReceiveModal from './baseComponents/Meeting/ReceiveModal';
import CloudLinkReceView from './components/MeetingCloudLink/MeetingReceView';
import CloudLinkMeetingControl from './components/MeetingCloudLink/MeetingControl';
import ChatState, { QuickReply } from '@/types/chat/State';
import Bus from '@/utils/bus';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { getServiceAndPrivacyLinks, getWatermark } from '@/utils/ui';
import UpgradeModal from '@/components/Modals/UpgradeModal';
import NotifyModal from '@/components/Modals/NotifyModal';
import VoipCallModal from './components/Voip/VoipCallModal';
import VoipReceModal from './components/Voip/VoipReceModal';
import { SuperviseType } from '@/types/supervise';
import { getPermission } from '@/api/user';
import { IPermissions } from '@/types/common';
import { ApplyAuthorResourceToConfig } from './utils/envConfig';
import user, { UserState } from '@/models/user';
import dayjs from 'dayjs';
import { setMiniEOpenInstance } from '@/server/miniEOpen';
import { setScanCodeInstance } from '@/server/scanCode';
import getVersionBySdk from '@/utils/getVersion';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import ErrorCatch from './components/ErrorHolder/ErrorCatch';
import Loading from './components/Loading';
import { permissionStatus } from './notifications';
import notifyInfo from '@/assets/img/notify-info.png';
import closeImg from '@/assets/img/close.png';
import { UAParser } from 'ua-parser-js';
import classNames from 'classnames';
import { notifyMask } from '@/api/mask';
import { changeOpenUserid } from '@/api/miniEOpen';
import { takeTerminals } from '@/api/meeting';
import lifecycle from 'page-lifecycle';
import { changeEgovUrl } from '@/utils/tools';
import aiNoticeCardService from '@/services/AINoticeCardService';
import DownloadFileQueue from './utils/DownloadFileQueue';

import NetworkCheck, { UseCheckType } from './utils/NetworkCheck';
import { testImg } from './config/config';
import getCurVoiceHandler from './components/chat/handler/voiceHandler';
import { debounce } from 'lodash';
import ChangeNetModal, { ChangeNetModalProps } from './components/Modals/ChangeNetModal';
import { APP_NAME } from '@/config/name';
import { getUserEbookDetailList } from './api/ebook';
import { CloudLinkStatusEnum, getCloudLinkStatus } from './components/MeetingCloudLink/Connector';
import { createCloudLinkCall } from './components/MeetingCloudLink/utils';
import { handleCreateMeeting } from './components/Meeting/utils';
import { mapUserDetailTarget } from './components/BigScreen/common';
import { mapGetEbookDeptInfoList } from './components/AddressBook/common';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import md5 from 'js-md5';
import * as Sentry from '@sentry/react';
import { EbookState } from './models/ebook';
import { getBigScreenMeetingTopic, joinMeeting } from './baseComponents/Meeting/utils';
import { AVSDK } from './baseComponents/Meeting/common';
import InitJoySpaceController from './components/Joyspace/controllers/InitJoySpaceController';
import SyncJoySpaceController from './components/Joyspace/controllers/SyncJoySpaceController';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';
import NetTable from './components/Modals/NetTable';
import { getQuickReplys } from './api/chat';
import InitThirdAppControllers from './components/ThirdApp/components/controllers/InitThirdAppControllers';
import { handleLinkClick } from './components/chat/message/AINoticeCard/utils';
import { notUseJoySpaceOffline, setJoySpaceDucc } from './components/Joyspace/util';

// const UserCardPage = lazy(() => import('@/pagesv2/UserCardPage'));
const UserSelectionPage = lazy(() =>
    import(/* webpackChunkName: "UserSelectionPage" */ '@/pagesv2/UserSelectionPage')
);
const WinNotification = lazy(() =>
    import(/* webpackChunkName: "WinNotification" */ '@/pagesv2/WinNotification')
);
const WinUnreadPanel = lazy(() =>
    import(/* webpackChunkName: "WinUnreadPanel" */ '@/pagesv2/WinUnreadPanel')
);
const ChatSupportPage = lazy(() =>
    import(/* webpackChunkName: "ChatSupportPage" */ '@/pagesv2/ChatSupport')
);
const WebBrowserPage = lazy(() =>
    import(/* webpackChunkName: "WebBrowserPage" */ '@/pagesv2/WebBrowserPage')
);
const SuperviseNoticeCard = lazy(() =>
    import(/* webpackChunkName: "SuperviseNoticeCard" */ '@/pagesv2/SuperviseNoticeCard')
);
const ImagePreviewModal = lazy(() =>
    import(/* webpackChunkName: "ImagePreviewModal" */ '@/pagesv2/ImagePreviewModal/index')
);
const CalendarNoticeCard = lazy(() =>
    import(/* webpackChunkName: "CalendarNoticeCard" */ '@/pagesv2/CalendarNoticeCard')
);
const TaskNoticeCard = lazy(() =>
    import(/* webpackChunkName: "TaskNoticeCard" */ '@/pagesv2/TaskNoticeCard/index')
);
const SysNoticeCard = lazy(() =>
    import(/* webpackChunkName: "SysNoticeCard" */ '@/pagesv2/SysNoticeCard')
);
const HWSelectionPage = lazy(() =>
    import(/* webpackChunkName: "TaskNoticeCard" */ '@/pagesv2/UserSelectionPage/cloudlink')
);
const { REACT_APP_DEBUG_PROD, REACT_APP_BUILD_ELECTRON } = process.env;

const log = logger.getLogger('AppV2');
const suspenseLoading = () => (
    <div className="flex1 flex-center">
        <Loading />
    </div>
);

const localUrl = new URL(location.href);
const loadSess = localUrl.searchParams.get('loadSess'); // 1
const pageName = localUrl.searchParams.get('page');
const isHomePage = !pageName && !localUrl.pathname.includes(`VideoViewer`);
window.onerror = (e: any) => {
    focusSDK.printLog(`global error`, JSON.stringify({ e }));
    console.error('global error', { e });
};

// 兼容IE
if (!document.body.scrollTo) {
    Element.prototype.scrollTo = function (option: any) {
        this.scrollLeft = option.left;
        this.scrollTop = option.top;
    };
}

const watermark = getWatermark({
    top: 80,
});
const hiddenWatermark = getWatermark({
    color: '#000000',
    opacity: 0.005,
    fontSize: 12,
});

const clearImServiceListeners = () => {
    const imService = ImService.getInstance();
    imService?.removeAllListeners('chat_new_message');
    imService?.removeAllListeners('chat_batch_message');
};

console.log('======>', process.env);

function AppV2(props: any) {
    const {
        receiveSessionNewMessage,
        receiveSessionBatchMessage,
        setUserData,
        getAllChatSessions,
        getCurrentEmployee,
        changePersonalTaskDraw,
        userData,
        service,
        setDetailDrawerVisible,
        currentEmployee,
        setMainWinShow,
        clearSessionStatusMap,
        updateBigScreenSelectedList,
        setRightDrawerVisible,
        selectUserList,
        setCurrentNetInfo,
        newContactsCount,
        setIsMessageModule,
        onUpdateAuthInfo,
        quickReplyVersion,
        setQuickReplyInfo,
    } = props;
    console.log('service====', service);
    const { t } = useTranslation('common');
    const { t: chatT } = useTranslation('chat');
    const onGlobalError = useCallback((cfg: any) => {
        let config = cfg;
        if (typeof cfg === 'string') {
            config = {
                type: 'message',
                error: GError.fromError(new Error(cfg)),
            };
        } else if (cfg instanceof GError) {
            config = {
                type: 'message',
                error: cfg,
            };
        } else if (cfg instanceof Error) {
            config = {
                type: 'message',
                error: GError.fromError(cfg),
            };
        }
        switch (config.type) {
            case 'message':
                message.error(config.error.toString());
                return;
            case 'modal':
                Modal.error({
                    content: config.error.toString(),
                });
                return;
            default:
        }
    }, []);
    const history = useHistory();
    const [authData, setAuthData] = useState<AuthData | null>(null);
    const [imService, setImService] = useState<ImService | null>(null);
    const [imserviceInited, setImserviceInited] = useState<boolean>(false);
    const appRuntimeEnv: AppRuntimeEnv = service.appRuntime;
    const [configLoaded, setConfigLoaded] = useState(false);
    const [permissions, setPermissions] = useState<IPermissions[]>([]);
    const [previewImageInfo, setPreviewImageInfo] = useState<PreviewImageInfo>({
        sessionId: '',
        id: '',
    });
    const currentUserId = React.useRef<any>();
    const netCheckIdRef = useRef('');
    const selectListRef = useRef<any>([]);
    const deeplinkSearchRef = React.useRef<string>('');
    const [notifyVisible, setNotifyVisible] = useState<any>(false);
    const [notifyStatus, setNotifyStatus] = useState<any>('granted');
    const [notifyDis, setNotifyDis] = useState(true);
    // const [isInJBRTCMeeting, setIsInJBRTCMeeting] = useState(false);
    const notifyRef = React.useRef<any>(null);
    // @ts-ignore
    const [globalContext, setGlobalContext] = useState<GlobalContextConfig>({
        focusSDK,
        service,
        appRuntimeEnv: service.appRuntime,
        onGlobalError,
        isDesktop: isFocusEnv(),
        currentEmployee,
        notifyStatus: window.Notification?.permission || 'granted',
        notifyDis: true,
        isInJBRTCMeeting: false,
        jbrtcMeetingNumber: null,
        // isWebpSupported: false,
    });
    // 仅网页端这里会有帮助地址、通知版本暂时写死
    const appVersion = '1.7.0';
    let currentOrigin = focusSDK.getMeMainEntry();
    // const notifyRef = React.useRef<any>(null);
    let helpUrl = '';
    if (appRuntimeEnv.envConfig?.helpUrl) {
        helpUrl = appRuntimeEnv?.envConfig?.helpUrl;
    } else {
        helpUrl = `${currentOrigin}/guide/#/pc?web=true`;
        if (process.env.REACT_APP_NODE_ENV !== 'production') {
            helpUrl = `${currentOrigin}/jingzhiban#/pc?web=true`;
        }
    }
    const getBrowserName = useMemo(() => {
        let parser = new UAParser();
        let browserName = parser.getBrowser().name;
        return browserName?.toLowerCase();
    }, []);

    useEffect(() => {
        aiNoticeCardService.setImService(imService);
        // imService?.addAICardChain(
        //     new Chain((params) => {
        //         console.log('not type2');
        //         return NEXT_FLAG;
        //     })
        // );
        // imService?.addAICardChain(
        //     new Chain((params) => {
        //         if (params.pin === '~me69') {
        //             return <div> this.is calendar</div>;
        //         }
        //         return NEXT_FLAG;
        //     })
        // );
        // imService?.addAICardChain(
        //     new Chain((params) => {
        //         console.log(params);
        //         return <div>this is card</div>;
        //     })
        // );
    }, [imService]);
    // 注册日程与im融合服务，监听解散组；
    useEffect(() => {
        // const calendarImService: CalendarImServiceType = CalendarImService.getInstance();
        let config = JSON.parse(focusSDK.getConfigData());
        const { settings } = config;
        const { netSettingChanged, egovFirst } = settings || {};
        if (netSettingChanged) {
            // dns设置正确
            if (focusSDK.privateDnsIsTrue()) {
                message.info(egovFirst ? '已优先连接政务外网' : '已优先连接互联网');
            }
            focusSDK.setConfigData(`settings|netSettingChanged`, false);
        }
        // calendarImService.setDisbandGroupPrompt((businessId: string, disbandGroup: Function) => {
        //     calendarDisbandGroupPrompt(businessId, disbandGroup, chatT);
        // });
        // eslint-disable-next-line
    }, []);
    // 初次通知遮罩弹出
    useEffect(() => {
        if (isFocusEnv()) {
            return;
        }
        const notifyMaskShow = async () => {
            if (window.Notification) {
                let status: NotificationPermission | string = Notification.permission;
                if (status === 'default') {
                    if (getBrowserName !== 'firefox') {
                        let res = await notifyMask({ appVersion });
                        // 返回1 需要打开通知遮罩
                        let canShowMask = !!res;
                        if (canShowMask) {
                            setNotifyVisible(true);
                            // 获取通知授权
                            let newStatus = await permissionStatus();
                            // 授权为禁止或允许
                            if (newStatus) {
                                status = newStatus;
                            }
                            setNotifyVisible(false);
                        }
                    }
                }
                setGlobalContext((globalContext: any) => ({
                    ...globalContext,
                    notifyStatus: status,
                }));
                setNotifyStatus(status);
            }
        };
        if (userData?.user?.realName) {
            notifyMaskShow();
        }
    }, [userData, getBrowserName]);

    // 打开通知
    const openNotify = useCallback(async (event) => {
        event.preventDefault();
        let res = await notifyMask({ appVersion });
        let canShowMask = !!res;
        console.log('canShowMask', canShowMask);
        if (canShowMask) {
            setNotifyVisible(true);
            let newStatus = await permissionStatus();
            console.log('newStatus', newStatus);
            if (newStatus) {
                setNotifyStatus(newStatus);
                setGlobalContext((globalContext: any) => ({
                    ...globalContext,
                    notifyStatus: newStatus,
                }));
            }
            setNotifyVisible(false);
        } else {
            let newStatus = await permissionStatus();
            console.log('newStatus', newStatus);
            if (newStatus) {
                setNotifyStatus(newStatus);
                setGlobalContext((globalContext: any) => ({
                    ...globalContext,
                    notifyStatus: newStatus,
                }));
            }
        }
    }, []);

    const imLogin = useCallback(
        async (authInfo: any, appRuntimeEnv: any, force: boolean) => {
            const imService = ImService.getInstance();
            if (!isHomePage || !imService) {
                return;
            }
            try {
                await imService.loginIm(
                    {
                        xToken: authInfo.accessToken,
                        xTeamId: appRuntimeEnv.selectedTeamId,
                        xClient: appRuntimeEnv.registryConfig.client,
                    },
                    force,
                    isHomePage
                );
                if (imService) {
                    if (!pageName || loadSess === '1') {
                        // performance.mark('get-all-sessions-start');
                        getAllChatSessions();
                    }
                    getCurrentEmployee();
                }
                setImserviceInited(true);
                Bus.emit('chat:state', {
                    state: ConnectState.READY,
                });
            } catch (e) {
                console.log(`im--login error--`, e);
            }
        },
        [getCurrentEmployee, getAllChatSessions, setImserviceInited]
    );

    // 来新消息时增加消息通知
    const messageListener = useCallback(
        (message: ChatMessage) => {
            receiveSessionNewMessage(message);
        },
        [receiveSessionNewMessage]
    );

    const messageBatchListener = useCallback(
        (messages: ChatMessage[]) => {
            receiveSessionBatchMessage(messages);
        },
        [receiveSessionBatchMessage]
    );

    // 初始化操作日志分析埋点
    authData && initLogAnalytics(authData.account, authData, focusSDK);

    const initImService = useCallback(
        (imService: ImService, authInfo: any, appRuntimeEnv: any) => {
            const isProd = REACT_APP_DEBUG_PROD === 'false' ? true : false;
            focusSDK.printLog('initImService', `im start login ...${isProd}`);
            // 下一行，会清楚所有监听，不然会有多效监听效果
            imService.removeAllListeners('chat_new_message');
            imService.on('chat_new_message', messageListener);
            imService.removeAllListeners('chat_batch_message');
            imService.on('chat_batch_message', messageBatchListener);
            imLogin(authInfo, appRuntimeEnv, isProd);
        },
        [messageListener, messageBatchListener, imLogin]
    );
    const startImRelogin = useCallback(() => {
        const timer = setTimeout(() => {
            clearTimeout(timer);
            imLogin(authData, appRuntimeEnv, true);
            focusSDK.printLog(`AppV2-retry-login-retry`, `startImRelogin`);
        }, 30 * 1000);
    }, [imLogin, authData, appRuntimeEnv]);
    const onAuthInfoLoad = useCallback(
        async (authInfo: AuthData) => {
            if (!authInfo) {
                log.debug('no authInfo, logout', { authInfo });
                focusSDK.printLog('no authInfo, logout', JSON.stringify(authInfo));
                setConfigLoaded(true);
                focusSDK.logout();
                return;
            }
            if (!appRuntimeEnv) {
                log.debug('no appRuntimeEnv, logout', { authInfo, appRuntimeEnv });
                focusSDK.printLog(
                    'no appRuntimeEnv, logout',
                    JSON.stringify({ authInfo, appRuntimeEnv })
                );
                focusSDK.logout();
                return;
            }
            log.debug('authInfo loaded', { authInfo });
            appRuntimeEnv.setAuthInfo(authInfo);
            setAuthData(authInfo);
            console.log('appRuntimeEnv======', appRuntimeEnv, authInfo);
            onUpdateAuthInfo(authInfo);
            setGatewayInstance(appRuntimeEnv.gateway, authInfo, appRuntimeEnv);
            setMiniEOpenInstance(appRuntimeEnv.envConfig.open, authInfo, appRuntimeEnv);
            // merge token
            cache.token = {
                ...(cache.token || {}),
                token: {
                    focusToken: authInfo.accessToken,
                    focusClient: appRuntimeEnv.registryConfig.client,
                    focusTeamId: appRuntimeEnv.selectedTeamId,
                },
                deviceCode: appRuntimeEnv.registryConfig.machineId,
            };
            cache.gateWayAppId = appRuntimeEnv.gatewayAppId;
            cache.ddAppId = authInfo.ddAppId;
            await setUserData({
                user: {
                    ...authInfo,
                    team: authInfo.teamUserInfo,
                },
                app: {
                    appId: appRuntimeEnv.gateway.appId,
                    deviceId: appRuntimeEnv.registryConfig.machineId,
                    client: appRuntimeEnv.registryConfig.client,
                },
            });
            // performance.mark('im-get-instance-start');
            const imService1 = ImService.getInstance(appRuntimeEnv);
            // performance.mark('im-get-instance-end');
            if (ImService.isValid() && navigator.onLine) {
                initImService(imService1, authInfo, appRuntimeEnv);
            } else {
                Bus.emit('chat:state', {
                    state: ConnectState.OFFLINE,
                });
                const voiceHandler = getCurVoiceHandler();
                voiceHandler?.destroy();
            }
            setImService(imService1);
            await initFileUploadService(appRuntimeEnv);
            if (isHomePage) {
                const res: any = await getPermission();
                // token 失效网关直接返回{errCode,errMsg}
                if (res?.errorMsg) {
                    return;
                }
                setPermissions(res || []);
                const [result, err] = await getQuickReplys({ version: quickReplyVersion });
                // console.log('getQuickReplys', result);
                if (result) {
                    setQuickReplyInfo({
                        quickReplyVersion: result.version,
                        quickReplys: result.quickReplys,
                    });
                }
                // 依据权限进行config配置
                focusSDK.printLog('打印getPermission：', res);
                ApplyAuthorResourceToConfig(res || []);

                try {
                    // console.log('=======>');
                    if (notUseJoySpaceOffline()) {
                        return;
                    }
                    // 初始化数据库
                    InitJoySpaceController.init();
                    // 初始化数据库
                    InitThirdAppControllers.init();
                    // 同步数据
                    SyncJoySpaceController.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        [appRuntimeEnv, setUserData] //eslint-disable-line
    );

    const onReLogin = useCallback(
        (d: AuthData) => {
            onAuthInfoLoad(d).then(() => {
                log.info('onReLogin finished.');
            });
        },
        [onAuthInfoLoad]
    );

    // 设置会议状态
    const onSetJBRTCMeetStatus = useCallback(
        (data: any) => {
            const { inMeeting, meetingNumber } = data;
            setGlobalContext({
                ...globalContext,
                isInJBRTCMeeting: inMeeting,
                jbrtcMeetingNumber: meetingNumber,
            });
        },
        [globalContext]
    );

    const doGetOffMessage = debounce(() => {
        imService?.getOfflineMessage();
    }, 100);

    const onAuthReady = useCallback(
        (data) => {
            if (data?.state === ConnectState.AUTHED) {
                doGetOffMessage();
                Bus.off('chat:state', onAuthReady);
            }
            if (data?.state === ConnectState.READY) {
                if (newContactsCount > 0) {
                    // 断网重连 新的申请存在未读数时 有可能移动端已读，桌面端需要重新获取新的申请数量 保持数量多端同步
                    imService?.updateNewContactCount();
                }
            }
        },
        [doGetOffMessage, imService, newContactsCount]
    );

    const onlineListener = useCallback(() => {
        if (isHomePage && isFocusEnv()) {
            focusSDK.sendGlobalWebviewMessage('onlineOfflineCheck', {
                isOnline: navigator.onLine,
            });
        }
        focusSDK.printLog('onlineCallback', `online: ${navigator.onLine}`);
        const netServer = NetworkCheck.getInstance({
            id: netCheckIdRef?.current,
        });
        netServer?.resetCheck(5 * 60 * 1000);
        if (navigator.onLine) {
            Bus.emit('chat:state', {
                state: ConnectState.CONNECTING,
            });
            if (imserviceInited) {
                const imService = ImService.getInstance();
                Bus.on('chat:state', onAuthReady);
                imService?.reloginIm().then();
            } else {
                const imService1 = ImService.getInstance(appRuntimeEnv);
                initImService(imService1, authData, appRuntimeEnv);
            }
        }
    }, [authData, imserviceInited, appRuntimeEnv, initImService, onAuthReady]);

    const offlineListener = async () => {
        if (!navigator.onLine) {
            ImService.getInstance()?.closeWS();
            if (isHomePage && isFocusEnv()) {
                focusSDK.sendGlobalWebviewMessage('onlineOfflineCheck', {
                    isOnline: navigator.onLine,
                });
            }
        }
        // 重新定义连接状态
        const netServer = NetworkCheck.getInstance({
            id: netCheckIdRef?.current,
        });
        focusSDK.printLog('offlineCallback', `online: ${navigator.onLine}`);
        if (!navigator.onLine) {
            Bus.emit('chat:state', {
                state: ConnectState.OFFLINE,
            });
            const voiceHandler = getCurVoiceHandler();
            voiceHandler?.destroy();
            // 清空下载队列
            DownloadFileQueue.getInstance()?.clearQueue();
        } else {
            netServer?.startCheck();
        }
    };

    const onMainWinResume = useCallback(() => {
        if (netCheckIdRef?.current) {
            const netServer = NetworkCheck.getInstance({
                id: netCheckIdRef?.current,
            });
            focusSDK.printLog(`AppV2--on win resume`);
            netServer?.startCheck();
        }
    }, []);

    const onForceLogout = useCallback(() => {
        // tj: logout 托盘停止闪动
        focusSDK.sendIpcMessage('flashSession', { type: 'stop' });
        if (ImService.getInstance()) {
            ImService.getInstance().logoutIm().then();
        }
        // 清空下载队列
        DownloadFileQueue.getInstance()?.clearQueue();
        const netServer = NetworkCheck.getInstance({
            id: netCheckIdRef.current,
        });
        netServer?.off('online', onlineListener);
        netServer?.off('offline', offlineListener);
        netServer?.stopCheck();
        deeplinkSearchRef.current = '';
        clearImServiceListeners();
    }, [onlineListener]);

    const handleDeeplinkParams = useCallback(
        // eslint-disable-next-line complexity
        async (currentEmployee: any) => {
            const url = deeplinkSearchRef.current;
            const params = new URLSearchParams(url);
            try {
                if (params) {
                    let users: any = [];
                    const userId = params.get('userid')?.split(',');
                    const terminalId = params.get('terminals')?.split(',') || [];
                    const teamToken = params.get('token');
                    if (userId && teamToken) {
                        const [data, error] = await changeOpenUserid({
                            data: { openUserIds: userId },
                            headers: {
                                Authorization: `Bearer ${teamToken}`,
                                'content-type': 'application/json',
                            },
                            method: 'post',
                        });
                        if (data?.data) {
                            users = await getUserEbookDetailList(
                                data.data
                                    .filter((item: any) => !!item.userId)
                                    .map((item: any) => {
                                        return {
                                            userId: item.userId,
                                            appId: currentEmployee.appId || currentEmployee.app,
                                            teamId: currentEmployee.teamId,
                                        };
                                    })
                            );
                        }
                    }
                    const openPath = params.get('open');
                    let meetingType = parseInt(params.get('meetingType') || '1', 10);
                    meetingType = isNaN(meetingType) ? 1 : meetingType;
                    let command = parseInt(params.get('command') || '0', 10);
                    command = isNaN(command) ? 0 : command;
                    console.log(
                        `deeplink:---changeOpenUserid---res-openPath:${openPath}-command:${command}`,
                        users,
                        terminalId
                    );
                    if (openPath?.includes('cloudlink')) {
                        let toPath =
                            command === 1
                                ? 'address_list'
                                : meetingType === 1
                                ? 'cloudlink'
                                : 'meeting';
                        if (!openPath.includes('meeting')) {
                            toPath += `?create=1`;
                            if (history.location.pathname.includes(`cloudlink`)) {
                                Bus.emit(`${toPath}:create-show`);
                            }
                            history.push(`/${toPath}`);
                            return;
                        }
                        history.push(`/${toPath}`);
                        // 入会
                        if (users?.length || terminalId) {
                            const toUsers = users?.map((item: any) => {
                                const avatar = item?.fields?.find((i: any) => i.key === 'avatar');
                                const name = item?.fields?.find((i: any) => i.key === 'name');
                                return {
                                    userId: item.userId,
                                    teamId: item.teamId,
                                    appId: item.appId,
                                    userName: name?.val || '',
                                    headImg: avatar?.val || '',
                                };
                            });

                            // 是否在会
                            const multi = params.get('multi');
                            const handleInMeeting = () => {
                                console.log(
                                    `deeplink:---handleInMeeting---multi:${multi}-command:${command}`
                                );
                                if (multi || command === 1) {
                                    history.push('/address_list');
                                } else {
                                    focusSDK.setWindowFrame('min');
                                }
                            };
                            if (meetingType === 0) {
                                Bus.once('chat_in_meeting_invite', handleInMeeting);
                            } else if (getCloudLinkStatus() === CloudLinkStatusEnum.IN_MEETING) {
                                handleInMeeting();
                            }
                            if (meetingType === 1) {
                                createCloudLinkCall({
                                    toUsers,
                                    terminals: {
                                        terminalId,
                                    },
                                    opts: {
                                        meetingTopic: getBigScreenMeetingTopic(),
                                    },
                                }).catch((err: any) => {
                                    message.error(err.message || err || '会议创建失败');
                                });
                            } else {
                                handleCreateMeeting(toUsers, getBigScreenMeetingTopic()).catch(
                                    (err: any) => {
                                        message.error(err.message || err || '会议创建失败');
                                    }
                                );
                            }
                        }
                    } else if (openPath?.includes('meeting')) {
                        const meetingId = params.get('meetingId');
                        const sdkType = params.get('sdkType') as AVSDK;
                        if (!meetingId || !sdkType) {
                            return;
                        }
                        if (!Object.values(AVSDK).includes(sdkType)) {
                            logger.error('未知的sdkType');
                            return;
                        }
                        const avsdk = sdkType as AVSDK;

                        joinMeeting(avsdk, {
                            meetingNumber: meetingId,
                            meetingPassword: params.get('password') || '',
                        });
                        switch (avsdk) {
                            case AVSDK.JBRTC:
                                history.push('/jdmeet');
                                break;
                            default:
                                break;
                        }
                    } else if (openPath === 'commandscreen') {
                        let select = parseInt(params.get('select') || '1', 10);
                        select = isNaN(select) ? 1 : select;
                        let commonContact = parseInt(params.get('commonContact') || '0', 10);
                        commonContact = isNaN(select) ? 0 : commonContact;
                        console.log(
                            `deeplink:----select:${select}--commonContact:${commonContact}-`
                        );
                        history.push('/address_list');
                        setRightDrawerVisible(commonContact === 1 ? true : false);
                        if (!users?.length && !terminalId?.length) {
                            if (select === 0) {
                                // 清空
                                updateBigScreenSelectedList([]);
                            }
                            deeplinkSearchRef.current = '';
                            return;
                        }
                        console.log(`deeplink:----users---`, users, terminalId);
                        let devices = [];
                        if (terminalId.length) {
                            const [res, error] = await takeTerminals(1, 100);
                            if (res?.recordList) {
                                console.log(`deeplink:--terminalId----`, res);
                                const list = res?.recordList.filter((item: any) => {
                                    return terminalId.includes(item.terminalId);
                                });
                                devices = terminalId?.length
                                    ? mapUserDetailTarget(list, currentEmployee)
                                    : [];
                            }
                        }
                        users = (users || [])
                            .map((item: any) => {
                                return {
                                    ...item,
                                    memberType: 1,
                                };
                            })
                            .concat(
                                devices.map((item: any) => {
                                    return {
                                        ...item,
                                        memberType: 2,
                                    };
                                })
                            );
                        const ids = users.map((i: any) => i.userId);
                        const groupData: any = await mapGetEbookDeptInfoList(ids);
                        if (groupData?.length) {
                            users = users.map((item: any, idx: number) => {
                                if (groupData[idx]) {
                                    item.groupList = groupData[idx]?.groupList;
                                }

                                return item;
                            });
                        }
                        const rest = selectListRef.current.filter(
                            (item: any) => !ids.includes(item.userId)
                        );
                        if (select === 1) {
                            console.log(`deeplink:--other--users---`, users, rest);
                            updateBigScreenSelectedList([...users, ...rest]);
                        } else {
                            console.log(`deeplink:--rest--users---`, rest);
                            updateBigScreenSelectedList(rest);
                        }
                    } else if (openPath === 'schedule') {
                        history.push('/calendar');
                    }
                }
            } catch (e) {
                console.log(`handle deeplink error: `, e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history]
    );

    const onMainWinOpen = useCallback(
        (data: any) => {
            if (data?.urlSearch) {
                deeplinkSearchRef.current = data.urlSearch;
                if (currentEmployee?.userId) {
                    handleDeeplinkParams(currentEmployee);
                }
            }
            deeplinkSearchRef.current = '';
        },
        [currentEmployee, handleDeeplinkParams]
    );

    useEffect(() => {
        if (!deeplinkSearchRef.current || !currentEmployee.userId) {
            return;
        }
        handleDeeplinkParams(currentEmployee);
        deeplinkSearchRef.current = '';
    }, [currentEmployee, handleDeeplinkParams]);

    useEffect(() => {
        selectListRef.current = selectUserList;
    }, [selectUserList]);
    const supportsWebp = useCallback(({ createImageBitmap, Image }: any) => {
        if (!createImageBitmap || !Image) return Promise.resolve(false);

        return new Promise((resolve) => {
            const image = new Image();
            image.onload = () => {
                createImageBitmap(image)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(() => {
                        resolve(false);
                    });
            };
            image.onerror = () => {
                resolve(false);
            };
            image.src =
                'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
        });
    }, []);

    const webpIsSupported = useCallback(() => {
        let memo: any = null;
        return () => {
            if (!memo) {
                memo = supportsWebp(window);
            }
            return memo;
        };
    }, [supportsWebp]);

    useEffect(() => {
        if (!isHomePage) {
            return;
        }
        const netServer = NetworkCheck.getInstance({
            url: testImg(),
            checkType: UseCheckType.IMAGE,
        });
        if (netServer) {
            netCheckIdRef.current = netServer.getId();
        }
        if (!netServer?.isStartCheck()) {
            netServer?.startCheck();
        }
        return () => {
            netServer?.stopCheck();
        };
    }, []);

    useEffect(() => {
        if (!isHomePage) {
            return;
        }
        if (!netCheckIdRef.current) {
            return;
        }
        const netServer = NetworkCheck.getInstance({
            id: netCheckIdRef.current,
        });
        netServer?.off('online', onlineListener);
        netServer?.off('offline', offlineListener);
        Bus.off('chat:state-auth-faild', startImRelogin);
        netServer?.on('online', onlineListener);
        netServer?.on('offline', offlineListener);
        Bus.off('chat:force_quit', onForceLogout).on('chat:force_quit', onForceLogout);
        Bus.on('chat:state-auth-faild', startImRelogin);
        focusSDK.on('main-win-resume', onMainWinResume);
        focusSDK.on('onlineOfflineCheck', (data) => {
            focusSDK.printLog('onlineOfflineCheck', data);
        });
        return () => {
            netServer?.off('online', onlineListener);
            netServer?.off('offline', offlineListener);
            Bus.off('chat:state-auth-faild', startImRelogin);
            focusSDK.off('main-win-resume', onMainWinResume);
            focusSDK.off('onlineOfflineCheck', (data) => {
                console.log('onlineOfflineCheck', data);
            });
            Bus.off('chat:force_quit', onForceLogout);
        };
    }, [onlineListener, startImRelogin, onMainWinResume, onForceLogout]);

    useEffect(() => {
        if (!isHomePage) {
            return;
        }
        focusSDK.off('main-page-open', onMainWinOpen).on('main-page-open', onMainWinOpen);
        return () => {
            focusSDK.off('main-page-open', onMainWinOpen);
        };
    }, [onMainWinOpen]);

    const [dateTime, setDateTime] = useState(dayjs().format('YYYYMMDD'));
    useEffect(() => {
        let timmer = setInterval(function () {
            setDateTime(dayjs().format('YYYYMMDD'));
        }, 24 * 60 * 60 * 1000);
        return () => {
            clearInterval(timmer);
        };
    }, []);
    useEffect(() => {
        if (userData?.user?.realName) {
            currentUserId.current = userData.user.userId;
            // console.log('currentUserId.current', currentUserId.current, userData.user);
            const mobile = userData.user.mobile;
            // 姓名+手机后四位 +20210314+非涉密网络
            let text = [
                userData.user.realName,
                mobile?.substring(mobile.length - 4, mobile.length),
                dateTime,
                '非涉密网络',
            ];
            if (process.env.REACT_APP_HIDE_WATERMARK === 'true') {
                text = [];
            }
            watermark.setWaterMark([text.join(' ')]);
            hiddenWatermark.setWaterMark(text);
            try {
                Sentry.configureScope(function (scope) {
                    let user = {
                        username: md5(userData.user.realName),
                        mobile: md5(mobile),
                    };
                    let machineId = appRuntimeEnv.registryConfig.machineId;
                    console.log('machineId', machineId);
                    if (machineId) {
                        (user as any).id = machineId;
                    }
                    scope.setUser(user);
                });
            } catch (e) {
                console.log(e);
            }
        }

        return () => {
            watermark.removeWatermark();
            hiddenWatermark.removeWatermark();
        };
    }, [userData, dateTime, appRuntimeEnv]);

    useEffect(() => {
        if (!service) {
            return;
        }
        // performance.mark('get-auth-info-start');
        const ts = Date.now();
        focusSDK.getAuthInfo((authInfo) => {
            if (!authInfo) {
                setConfigLoaded(true);
                return;
            }
            // 设置为团队的真实姓名
            authInfo.realName = authInfo.teamUserInfo?.realName || authInfo.realName || '';
            authInfo.headImg = authInfo.teamUserInfo?.headImg || authInfo.headImg || '';
            // performance.mark('get-auth-info-end');
            onAuthInfoLoad(authInfo).then(() => {
                log.info('getAuthInfo', Date.now() - ts);
                // 添加浏览器是否支持webp格式判断
                webpIsSupported()()
                    // eslint-disable-next-line max-nested-callbacks
                    .then((res: any) => {
                        // console.log('是否支持 webp', res);
                        setGlobalContext({
                            ...globalContext,
                            isWebpSupported: res,
                            service,
                            appRuntimeEnv: service.appRuntime,
                        });
                        setConfigLoaded(true);
                    })
                    // eslint-disable-next-line max-nested-callbacks
                    .catch((err: any) => {
                        message.error(err);
                        console.log(err);
                    });
            });
            if (isHomePage) {
                clearSessionStatusMap();
            }
        });
    }, []); // eslint-disable-line
    const onShowSuperviseDetail = useCallback(
        (data: any) => {
            const { superviseId } = data;
            setDetailDrawerVisible(false, superviseId);
            setTimeout(() => {
                setDetailDrawerVisible(true, superviseId);
            }, 300);
        },
        [setDetailDrawerVisible]
    );
    const onShowCalendarDetail = useCallback((data: any) => {
        const { calendarId, deepLink } = data;
        DeeplinkEvent.open(deepLink, { from: ScheduleDetailFrom.WinNoticeCard });
    }, []);
    const onShowSysNoticeDetail = useCallback((data: any) => {
        const { noticeId, deepLink } = data;
        handleLinkClick(deepLink || '');
    }, []);
    const onShowTaskDetail = useCallback(
        (data: any) => {
            const { taskId } = data;
            changePersonalTaskDraw(taskId, true, true);
        },
        [changePersonalTaskDraw]
    );

    const onSuperviseRead = useCallback(() => {
        Bus.emit('supervise/update');
    }, []);

    const onImageShow = useCallback((data: any) => {
        setPreviewImageInfo(data);
    }, []); // eslint-disable-line

    const onMainWinHidden = useCallback(() => {
        setMainWinShow({ show: false });
    }, [setMainWinShow]);
    const onMainWinShow = useCallback(() => {
        setMainWinShow({ show: true });
    }, [setMainWinShow]);

    const stateChange = useCallback(
        (event) => {
            if (isFocusEnv()) {
                return;
            }
            let newState = event.newState;
            if (newState === 'active' || newState === 'passive') {
                setMainWinShow({ show: true });
            } else {
                setMainWinShow({ show: false });
            }
        },
        [setMainWinShow]
    );

    const zoomNetResult = useCallback(
        ({ type, isPrivateNet, publicResult, privateResult }) => {
            // console.log(
            //     `zoomNetResult: type, ${type}, publicResult, ${publicResult}, privateResult, ${privateResult}`
            // );
            if (type === 'zoomNet') {
                setCurrentNetInfo({
                    newPublicResult: publicResult,
                    newPrivateResult: privateResult,
                });
            }
        },
        [setCurrentNetInfo]
    );

    useEffect(() => {
        focusSDK.on('supervise:handleShowDetail', onShowSuperviseDetail);
        focusSDK.on('calendar:handleShowDetail', onShowCalendarDetail);
        focusSDK.on('sysNotice:handleShowDetail', onShowSysNoticeDetail);
        focusSDK.on('task:handleShowDetail', onShowTaskDetail);
        focusSDK.on('supervise:handleRead', onSuperviseRead);
        focusSDK.on('image:handleShow', onImageShow);
        focusSDK.on('main-win-hidden', onMainWinHidden);
        focusSDK.on('main-win-show', onMainWinShow);
        focusSDK.on('zoomNetResult:reply', zoomNetResult);
        Bus.on('jdmeeting:meeting-status', onSetJBRTCMeetStatus);
        lifecycle.addEventListener('statechange', stateChange);

        return () => {
            focusSDK.off('supervise:handleShowDetail', onShowSuperviseDetail);
            focusSDK.off('calendar:handleShowDetail', onShowCalendarDetail);
            focusSDK.off('sysNotice:handleShowDetail', onShowSysNoticeDetail);
            focusSDK.off('task:handleShowDetail', onShowTaskDetail);
            focusSDK.off('supervise:handleRead', onSuperviseRead);
            focusSDK.off('image:handleShow', onImageShow);
            focusSDK.off('main-win-hidden', onMainWinHidden);
            focusSDK.off('main-win-show', onMainWinShow);
            focusSDK.off('zoomNetResult:reply', zoomNetResult);
            Bus.off('jdmeeting:meeting-status', onSetJBRTCMeetStatus);
            lifecycle.removeEventListener('statechange', stateChange);
        };
    }, [
        onShowSuperviseDetail,
        onShowCalendarDetail,
        onShowSysNoticeDetail,
        onSuperviseRead,
        onImageShow,
        onMainWinHidden,
        onMainWinShow,
        onShowTaskDetail,
        stateChange,
        zoomNetResult,
        onSetJBRTCMeetStatus,
    ]);

    const location = useLocation();
    useEffect(() => {
        // console.log('location.pathname', location.pathname);
        if (location.pathname.startsWith('/messages')) {
            setIsMessageModule(true);
        } else {
            setIsMessageModule(false);
        }
    }, [location.pathname, setIsMessageModule]);

    const pageComp = useMemo(() => {
        cache.clientMode = 'client';
        switch (pageName) {
            // case 'user-card':
            //     return <UserCardPage />;
            case 'win-unread-panel':
                return <WinUnreadPanel />;
            case 'win-notification-panel':
                return <WinNotification />;
            case 'user-selection':
                return <UserSelectionPage />;
            case 'hw-selection':
                return <HWSelectionPage />;
            case 'builtin-browser':
                return <WebBrowserPage />;
            case 'chat':
                return <ChatSupportPage />;
            case 'meeting-rece-view':
                return <MeetingReceView />;
            case 'meeting-receive':
                return <ReceiveModal />;
            case 'cloudlink-rece-view':
                return <CloudLinkReceView />;
            case 'cloudlink-meeting-control':
                return <CloudLinkMeetingControl />;
            case 'voip-call-modal':
                return <VoipCallModal />;
            case 'voip-rece-view':
                return <VoipReceModal />;
            case 'supervise-notice':
                return <SuperviseNoticeCard />;
            case 'calendar-notice':
                return <CalendarNoticeCard />;
            case 'task-notice':
                return <TaskNoticeCard />;
            case 'sys-notice':
                return <SysNoticeCard />;
            case 'image-preview':
                return <ImagePreviewModal />;
            default:
                cache.clientMode = 'server';
                return <HomePage />;
        }
    }, []);

    if (!configLoaded) {
        return (
            <div className="flex1 flex-center">
                <Loading />
            </div>
        );
    }
    return (
        <Suspense fallback={suspenseLoading()}>
            <GlobalContext.Provider
                value={{
                    ...globalContext,
                    authInfo: authData,
                    imService,
                    onReLogin,
                    setAuthInfo: setAuthData,
                    events3: new EventEmitter(),
                    permissions,
                    previewImageInfo,
                    currentEmployee,
                }}
            >
                <ErrorCatch>
                    {!isFocusEnv() && notifyStatus !== 'granted' && (
                        <div className={classNames('notify-area', { 'hidden-notify': !notifyDis })}>
                            <img src={changeEgovUrl(notifyInfo)} className="notify-info-logo" />
                            {notifyStatus === 'denied' ? (
                                <span className="help-info">当前无法接收{APP_NAME}消息提醒</span>
                            ) : (
                                <span className="open-notify">
                                    开启通知功能可及时接收{APP_NAME}消息提醒，
                                    <a href="#" onClick={openNotify} className="link-notify">
                                        开启消息通知
                                    </a>
                                </span>
                            )}
                            <img
                                src={changeEgovUrl(closeImg)}
                                className="close-img"
                                onClick={() => {
                                    setGlobalContext({
                                        ...globalContext,
                                        notifyDis: false,
                                    });
                                    setNotifyDis(false);
                                }}
                            />
                        </div>
                    )}
                    {pageComp}
                </ErrorCatch>
            </GlobalContext.Provider>
            <ThemeComp />
            <NotifyModal visible={notifyVisible} closeMask={() => setNotifyVisible(false)} />
        </Suspense>
    );
}

function mapStateToProps({
    chat,
    user,
    ebook,
}: {
    chat: ChatState;
    user: UserState;
    ebook: EbookState;
}) {
    return {
        // connectState: chat.connectState.state,
        userData: user.userData,
        currentEmployee: chat.currentEmployee || {},
        selectUserList: ebook.bigScreen?.selectedList || [],
        newContactsCount: ebook.newContactsCount,
        quickReplyVersion: chat.quickReplyVersion,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changePersonalTaskDraw: (taskId: string, visible: boolean, chat?: boolean) =>
            dispatch({
                type: 'work/changePersonalTaskDraw',
                payload: { taskId, drawVisible: visible, chat },
            }),
        getCurrentEmployee: () => {
            dispatch({ type: 'chat/getCurrentEmployee', payload: {} });
        },
        setCurrentNetInfo: (payload: any) => {
            dispatch({ type: 'netStatus/setCurrentNetInfo', payload });
        },
        getAllChatSessions: () => {
            dispatch({ type: 'chat/getAllChatSessions', payload: {} });
        },
        setUserData: (payload: any) => {
            dispatch({
                type: 'user/setUserData',
                payload,
            });
        },
        receiveSessionNewMessage: (message: any) => {
            dispatch({ type: 'chat/receiveSessionNewMessage', payload: { chatMessage: message } });
        },
        receiveSessionBatchMessage: (messages: any) => {
            dispatch({
                type: 'chat/receiveSessionBatchMessage',
                payload: { chatMessages: messages },
            });
        },
        setDetailDrawerVisible: (visible: boolean, superviseId: string) => {
            dispatch({
                type: 'supervise/setDetailDrawerVisible',
                payload: {
                    visible,
                    superviseId,
                    globalSuperviseType: SuperviseType.Receive,
                },
            });
        },
        updateSelectedSession(data: { selectedSession: Session }) {
            dispatch({ type: 'chat/updateSelectedSession', payload: data });
        },
        setMainWinShow: (data: { show: boolean }) => {
            dispatch({ type: 'chat/setMainWinShow', payload: data });
        },
        clearSessionStatusMap: (data: { show: boolean }) => {
            dispatch({ type: 'chat/clearSessionStatusMap', payload: data });
        },
        // 联系人
        updateBigScreenSelectedList(data: any) {
            dispatch({ type: 'ebook/updateBigScreenSelectedList', payload: data });
        },
        setRightDrawerVisible(data: any) {
            dispatch({ type: 'ebook/setRightDrawerVisible', payload: data });
        },
        setIsMessageModule(payload: boolean) {
            dispatch({ type: 'chat/setIsMessageModule', payload });
        },
        setQuickReplyInfo(payload: { quickReplyVersion: number; quickReplys: QuickReply[] }) {
            dispatch({ type: 'chat/setQuickReplyInfo', payload });
        },
    };
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppV2);

function App() {
    const [service, setService] = useState<Service | null>(null);
    const tRef = React.useRef<TFunction | null>(null);
    const [changeNetProps, setChangeNetProps] = useState<ChangeNetModalProps>({
        isPrivateNet: focusSDK.isPrivateNet(),
        newPublicResult: false,
        newPrivateResult: false,
    });
    const [netVisible, setNetVisible] = useState(false);
    const [currentAuthInfo, setCurrentAuthInfo] = useState(null);
    const [fromType, setFromType] = useState('');
    const pageName = localUrl.searchParams.get('page');
    const history = useHistory();

    const loadAppRuntimeEnv = useCallback((force?: boolean) => {
        console.log('loadAppRuntimeEnv========');
        const ts = Date.now();
        focusSDK.getAppRuntimeEnv((data) => {
            log.info('loadAppRuntimeEnv', Date.now() - ts, data);
            const { runtimeEnv, localConfig } = data;
            const s = new AppRuntimeEnv(runtimeEnv, localConfig);
            // ducc配置joyspace名称
            duccConfigI18.joyspace = s.getI18N('layout.nav.joyspace');
            document.title = s.productName;
            console.log('sssssss', s);
            console.log('focusSDK', focusSDK);

            const service = new Service(s, focusSDK);
            console.log('serviceserviceserviceservice', service);
            // 屏蔽重要信息
            const joyspace: any = { ...service.appRuntime.envConfig.joyspace };
            delete joyspace.host;
            delete joyspace.lowCode;
            delete joyspace.cookie;
            setJoySpaceDucc(joyspace);
            setService(service);
            setScanCodeInstance(
                service.appRuntime.envConfig.webConfig,
                service.appRuntime.registryConfig
            );
        }, force);
    }, []);

    const onConfigDataChange = useCallback(() => {
        loadAppRuntimeEnv(true);
    }, []); //eslint-disable-line

    const onLoginSuccess = useCallback(() => {
        loadAppRuntimeEnv(true);
    }, []); //eslint-disable-line

    const onLogout = async (reason?: string) => {
        log.debug('start logout ...');
        focusSDK.printLog('start logout ...', reason);
        if (!service) {
            return;
        }
        if (reason !== 'expired') {
            try {
                await service.authService.logout();
            } catch (e) {
                //
            }
        }
        // tj: logout 托盘停止闪动
        focusSDK.sendIpcMessage('flashSession', { type: 'stop' });
        // eslint
        // debugger;
        if (service.appRuntime.platform === 'IPAD') {
            // web端退出登录
            log.debug('web logout ...');
            if (ImService?.getInstance()) {
                await ImService.getInstance().logoutIm();
            }
            location.href = getLoginURL();
        } else {
            loadAppRuntimeEnv(true);
            // 桌面商退出登录
            log.debug('desktop logout ...');
            // 有网情况下重新定义连接状态
            if (navigator.onLine) {
                Bus.emit('chat:state', ConnectState.CONNECTING);
            }
            // 退出登录后修改url地址，不刷新页面。 如果不设置，可能会导致上次的url地址拉起其它团队会话的bug
            window.history.pushState({}, '', '/messages');
            // 退出登录后 登出im
            if (ImService.getInstance()) {
                await ImService.getInstance().logoutIm();
            }
        }
        // 退出登录，需要清除 Service，不清除会导致切换用户，离线文档列表显示A用户数据，而实际登录用户为B用户。
        InitJoySpaceController.clearServiceAll();
        SyncJoySpaceController.removeLineSyncData();
        InitThirdAppControllers.clearServiceAll();
        if (!notUseJoySpaceOffline()) {
            // 退出登录，需要清除 Service，不清除会导致切换用户，离线文档列表显示A用户数据，而实际登录用户为B用户。
            InitJoySpaceController.clearServiceAll();
            SyncJoySpaceController.removeLineSyncData();
        }

        clearImServiceListeners();
        // 清空下载队列
        DownloadFileQueue.getInstance()?.clearQueue();
        const netServer = NetworkCheck.getInstance({
            url: testImg(),
        });
        netServer?.stopCheck();
    };

    const onOpenAboutModal = useCallback(() => {
        if (!service) {
            return;
        }
        const config = getAppLogoConfig(service.appRuntime.runtimeConfig);
        const { destroy } = Modals.About({
            t: tRef.current,
            version: getVersionBySdk(focusSDK),
            linkConfig: getServiceAndPrivacyLinks(service.appRuntime),
            ...config,
            onCancel: () => {
                destroy();
            },
        });
    }, [service]);
    /* eslint-disable */
    const navigatorOnline = useCallback(
        debounce(async () => {
            // console.log('网络断开又重连了');
            // 互联网时
            focusSDK.checkUrlOnline({
                type: 'changeNetType',
                isPrivateNet: focusSDK.isPrivateNet(),
            });
        }, 300),
        [debounce, focusSDK]
    );
    /* eslint-enable */

    useEffect(() => {
        // 网页端直接返回
        if (!isFocusEnv()) {
            return;
        }
    }, []);

    useEffect(() => {
        // web端不处理
        if (!isFocusEnv()) {
            return;
        }
        // window.addEventListener('online', navigatorOnline);
        Bus.on('onLineChangeNet', navigatorOnline);
        return () => {
            // window.removeEventListener('online', navigatorOnline);
            Bus.off('onLineChangeNet', navigatorOnline);
        };
    }, [navigatorOnline]);

    const urlOnlineResult = useCallback(({ type, isPrivateNet, publicResult, privateResult }) => {
        console.log(
            `type: ${type}, publicResult: ${publicResult}, privateResult: ${privateResult}`
        );
        if (type === 'changeNetType') {
            setChangeNetProps({
                newPublicResult: publicResult,
                newPrivateResult: privateResult,
                isPrivateNet,
            });
        }
    }, []);

    useEffect(() => {
        // 根据不同系统，可以做样式区分
        document.body.classList.add(focusSDK.getDeviceInfo().platform);
        log.debug('did mount');
        i18n.use(initReactI18next)
            .init({
                ns: ['common', 'chat', 'calendar', 'joinMe', 'work', 'joyspace'],
                resources: resources,
                defaultNS: 'common',
                lng: 'zh',
                debug: false,
                interpolation: {
                    escapeValue: false,
                },
            })
            .then((t) => {
                tRef.current = t;
                log.debug('i18n loaded');
                loadAppRuntimeEnv();
            })
            .catch((e) => {
                log.error('failed to load i18n: ', e);
            });
    }, [loadAppRuntimeEnv]);

    const showNetTable = useCallback(({ fromType = '' }: any = {}) => {
        setFromType(fromType);
        setNetVisible(true);
    }, []);

    const hideNetTable = useCallback(() => {
        setNetVisible(false);
    }, []);

    const onUpdateAuthInfo = (authInfo: any) => {
        setCurrentAuthInfo(authInfo);
    };

    useEffect(() => {
        focusSDK
            .off('configDataChange', onConfigDataChange)
            .on('configDataChange', onConfigDataChange);
        focusSDK.off('loginSuccess', onLoginSuccess).on('loginSuccess', onLoginSuccess);
        focusSDK.off('logout', onLogout).on('logout', onLogout);
        focusSDK
            .off('app:openAboutModal', onOpenAboutModal)
            .on('app:openAboutModal', onOpenAboutModal);

        focusSDK.on('urlOnline:reply', urlOnlineResult);
        focusSDK.on('showNetTable', showNetTable);
        focusSDK.on('hideNetTable', hideNetTable);

        return () => {
            focusSDK.off('configDataChange', onConfigDataChange);
            focusSDK.off('loginSuccess', onLoginSuccess);
            focusSDK.off('logout', onLogout);
            focusSDK.off('app:openAboutModal', onOpenAboutModal);
            focusSDK.off('urlOnline:reply', urlOnlineResult);
            focusSDK.off('showNetTable', showNetTable);
            focusSDK.off('hideNetTable', hideNetTable);
        };
    }, [onConfigDataChange, onLoginSuccess, onOpenAboutModal, urlOnlineResult]); // eslint-disable-line
    useEffect(() => {
        // 桌面端登录页
        if (
            service &&
            service.appRuntime.platform !== 'IPAD' &&
            !service.appRuntime.registryConfig.auth
        ) {
            const netServer = NetworkCheck.getInstance({
                url: testImg(),
                checkType: UseCheckType.IMAGE,
            });
        }
    }, [service]);
    let comp;
    if (!service) {
        comp = (
            <div className="flex1 flex-center">
                <Loading />
            </div>
        );
    } else if (
        service.appRuntime.platform !== 'IPAD' &&
        !service.appRuntime.registryConfig.auth &&
        pageName !== 'builtin-browser'
    ) {
        // 桌面端跳转到登录页
        // if (service.appRuntime.platform === 'IPAD') {
        //     location.href = service.appRuntime.loginEntry();
        // }
        comp = (
            <GlobalContext.Provider
                value={{
                    focusSDK,
                    service,
                    appRuntimeEnv: service.appRuntime,
                }}
            >
                <LoginPage />
            </GlobalContext.Provider>
        );
    } else if (REACT_APP_BUILD_ELECTRON === 'true') {
        comp = (
            <HashRouter basename={service.appRuntime.webConfig.routeBase}>
                <AppContainer service={service} onUpdateAuthInfo={onUpdateAuthInfo} />
            </HashRouter>
        );
    } else
        comp = (
            <BrowserRouter
                basename={
                    process.env.REACT_APP_ROUTE_BASE || service.appRuntime.webConfig.routeBase
                }
            >
                <AppContainer service={service} onUpdateAuthInfo={onUpdateAuthInfo} />
            </BrowserRouter>
        );
    return (
        <>
            <UpgradeModal
                focusSDK={focusSDK}
                appRuntimeEnv={service?.appRuntime}
                upgradeVersionInfo={
                    service?.appRuntime.runtimeConfig.config.upgradeVersionInfo || []
                }
            />
            {/* <ChangeNetModal {...changeNetProps} /> */}
            <NetTable
                visible={netVisible}
                appRuntimeEnv={service?.appRuntime}
                authInfo={currentAuthInfo}
                fromType={fromType}
            />
            {comp}
        </>
    );
}

export default App;
