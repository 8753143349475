import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, message, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ToolsSearch from './Search/Search';
import SearchList from './Search/SearchList';
import {
    AppStoreApplication,
    AppStoreGroupApplication,
    // onEditFavoriteApplicationReq,
    RecommendedType,
} from '@/services/AppStore';
import LeftToolsNav from './LeftToolsNav';
import RightToolsContent from './RightToolsContent';
import { favoriteGroupId } from '@/components/ThirdApp';
import Bus from '@/utils/bus';
import './index.less';

const { confirm } = Modal;

export default function ModalAppTool({
    groups,
    isModalVisible,
    onCancel,
}: {
    groups: AppStoreGroupApplication[];
    isModalVisible: boolean;
    onCancel: () => void;
}) {
    const [rightContentList, setRightContentList] = useState<AppStoreApplication[]>([]);
    const [rightContentFavoriteList, setRightContentFavoriteList] = useState<AppStoreApplication[]>(
        []
    );
    const [groupId, setGroupId] = useState<string>(favoriteGroupId);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const handleCancel = () => {
        if (!showConfirm) {
            onCancel();
            setShowSearch(false);
            setGroupId(favoriteGroupId);
            setShowConfirm(false);
            setRightContentFavoriteList([]);
            return;
        }
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否保存设置？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                const applicationIds = [];
                const list = [];
                for (let item of rightContentFavoriteList) {
                    if (item.recommended === RecommendedType.RecommendedNo) {
                        applicationIds.push(item.applicationId);
                        list.push(item);
                    }
                }
                onCancel();
                Bus.emit('onEditFavoriteApplication', applicationIds, [
                    ...rightContentFavoriteList,
                ]);
                setShowConfirm(false);
            },
            onCancel() {
                onCancel();
                setShowSearch(false);
                setGroupId(favoriteGroupId);
                setShowConfirm(false);
                setRightContentFavoriteList([]);
            },
        });
    };
    const onTabChange = useCallback(
        (groupId: string) => {
            setGroupId(groupId);
            if (groupId !== favoriteGroupId) {
                const item = groups.find(
                    (item: AppStoreGroupApplication) => item.groupId === groupId
                );
                setRightContentList(item?.applications || []);
            }
        },
        [groups]
    );
    const onReorder = useCallback(
        (startIndex, endIndex) => {
            // 当没有改变排序时，不触发此方法。
            if (startIndex === endIndex) {
                return;
            }
            const result = [...rightContentFavoriteList];
            // 删除并记录 删除元素
            const [removed] = result.splice(startIndex, 1);
            // 将原来的元素添加进数组
            result.splice(endIndex, 0, removed);
            setRightContentFavoriteList(result);
            // setRightContentList(result);
            setShowConfirm(true);
        },
        [rightContentFavoriteList]
    );
    const onAddAppData = useCallback(
        (appInfo) => {
            const item: any =
                groups.find((item: AppStoreGroupApplication) => item.groupId === favoriteGroupId) ||
                {};
            setRightContentFavoriteList([...item.applications]);
        },
        [groups]
    );
    const onRemoveAppData = useCallback(
        (appInfo) => {
            const list = [...rightContentFavoriteList];
            const idx = list.findIndex((item) => item.applicationId === appInfo.applicationId);
            if (idx !== -1) {
                list.splice(idx, 1);
                setRightContentFavoriteList(list);
            }
        },
        [rightContentFavoriteList]
    );

    const resetFavorite = useCallback(() => {
        confirm({
            title: '提示',
            icon: <ExclamationCircleOutlined />,
            content: '是否恢复管理员默认设置？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                Bus.emit('onResetFavorite', 'default');
                onCancel();
            },
            onCancel() {
                // onCancel();
            },
        });
    }, [onCancel]);

    useEffect(() => {
        Bus.on('onAddAppData', onAddAppData);
        Bus.on('onRemoveAppData', onRemoveAppData);
        return () => {
            Bus.off('onAddAppData', onAddAppData);
            Bus.off('onRemoveAppData', onRemoveAppData);
        };
    }, [onAddAppData, onRemoveAppData]);
    const initRef = useRef('');
    useEffect(() => {
        // 其他工具存储
        if (isModalVisible && groupId !== favoriteGroupId) {
            const item: any =
                groups.find((item: AppStoreGroupApplication) => item.groupId === groupId) || {};
            setRightContentList(item.applications || []);
            // 常用工具存储
        } else if (isModalVisible && groupId === favoriteGroupId && !initRef.current) {
            initRef.current = 'initData';
            const item: any =
                groups.find((item: AppStoreGroupApplication) => item.groupId === favoriteGroupId) ||
                {};
            setRightContentFavoriteList([...item.applications] || []);
        } else if (!isModalVisible) {
            initRef.current = '';
        }
    }, [groups, groupId, isModalVisible, rightContentFavoriteList, rightContentList]);
    return (
        <Modal
            className="app-tool-modal"
            bodyStyle={{ minHeight: 300, padding: 0 }}
            title="管理常用应用"
            destroyOnClose={true}
            maskClosable={false}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <div>
                <div className="tools-modal-search">
                    <div className="top-left-box">
                        <div className="tools-search-box">
                            <ToolsSearch
                                onShowToolsSearch={(isShow: boolean) => {
                                    setShowSearch(isShow);
                                }}
                            />
                        </div>
                        {groupId === favoriteGroupId
                            ? !showSearch && <p className="tips-text">可拖拽调整常用应用排序</p>
                            : !showSearch && (
                                  <p className="tips-text">可以选择应用添加或取消常用</p>
                              )}
                    </div>
                    {groupId === favoriteGroupId && !showSearch ? (
                        <Button style={{ marginTop: 12 }} onClick={resetFavorite}>
                            恢复默认
                        </Button>
                    ) : (
                        <span />
                    )}
                </div>
                <div className="app-tools-main" style={{ display: showSearch ? 'none' : 'flex' }}>
                    <LeftToolsNav leftNavList={groups} onTabChange={onTabChange} />
                    <RightToolsContent
                        isPointer={groupId === favoriteGroupId}
                        rightContentList={
                            groupId === favoriteGroupId
                                ? rightContentFavoriteList
                                : rightContentList
                        }
                        onReorder={onReorder}
                    />
                </div>
                <div
                    className="app-tools-main"
                    style={{
                        display: showSearch ? 'flex' : 'none',
                        overflowY: 'auto',
                        padding: '0 20px',
                    }}
                >
                    <SearchList />
                </div>
            </div>
        </Modal>
    );
}
