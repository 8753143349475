import React, { useCallback, useEffect, useState } from 'react';
import { message, Modal, Input, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'dvajs';
import { debounce, trim as lodashTrim } from 'lodash';
import './index.less';
import { UserModel, UserState } from '@/models/user';
import ChatState, { BindBeijingTongAccount } from '@/types/chat/State';
import { setBindBeijingTongAccount } from '@/api/chat';
import { QuestionCircleOutlined } from '@ant-design/icons';

function ContactModal({
    identityInfoModal,
    setIdentityInfoModal,
    userData,
    currentEmployee,
}: {
    identityInfoModal: boolean;
    setIdentityInfoModal: Function;
    userData: UserModel;
    currentEmployee: any;
}) {
    const { t } = useTranslation('common');
    const [idCard, setIdCard] = useState('');
    const [infoConfirm, setInfoConfirm] = useState(true);
    const [errorTip, setErrorTip] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    function handleCancel() {
        setIdentityInfoModal(false);
        setTimeout(() => {
            setInfoConfirm(true);
            setIdCard('');
            setErrorTip(false);
            setButtonLoading(false);
        }, 100);
    }

    function confirmSubmit(type: boolean) {
        if (!type && (errorTip || !idCard)) {
            return;
        }
        setInfoConfirm(type);
    }
    async function infoOk() {
        setButtonLoading(true);
        const param: BindBeijingTongAccount = {
            name: currentEmployee.name || userData.teamUserInfo.realName, // 姓名
            idCard: idCard, // 身份证号
            mobile: currentEmployee.mobile, // 手机号
        };
        const [result, error] = await setBindBeijingTongAccount(param);
        setButtonLoading(false);
        if (error) {
            message.error(error);
            return;
        }
        message.success(t('userCard.tips.success'));
        handleCancel();
    }
    // 底部button
    const modalFooter = (
        <div>
            <Button
                style={{ border: '1px solid #dee0e3', borderRadius: ' 4px', color: '#242931' }}
                onClick={debounce(() => {
                    infoConfirm ? handleCancel() : confirmSubmit(true);
                }, 300)}
            >
                {t('button.cancel')}
            </Button>
            <Button
                type="primary"
                style={{
                    border: 'none',
                    background: '@primary-color',
                    borderRadius: '4px',
                    fontSize: '14px',
                    color: '#ffffff',
                }}
                onClick={debounce(() => {
                    infoConfirm ? confirmSubmit(false) : infoOk();
                }, 300)}
                loading={buttonLoading ? true : false}
                disabled={infoConfirm && !idCard}
            >
                {infoConfirm ? t('button.submit') : t('button.ok')}
            </Button>
        </div>
    );
    const TooltipTitle = useCallback(() => {
        return (
            <div className="Tooltip-title">
                如需帮助，请拨打<span>400-615-1212</span>
            </div>
        );
    }, []);
    const ModalTitle = useCallback(() => {
        return (
            <span>
                {t('userCenter.identityInfo')}
                <Tooltip placement="bottom" title={TooltipTitle} overlayClassName="modal-title">
                    <span style={{ marginLeft: '6px', fontSize: '12px' }}>
                        <QuestionCircleOutlined />
                    </span>
                </Tooltip>
            </span>
        );
    }, [TooltipTitle, t]);
    return (
        <Modal
            title={ModalTitle()}
            width="480px"
            bodyStyle={{
                width: 480,
                // minHeight: 240,
                minHeight: 146,
                padding: '24px 40px',
                display: 'flex',
                alignItems: 'center',
            }}
            visible={identityInfoModal}
            onCancel={debounce(() => {
                handleCancel();
            }, 300)}
            centered={true}
            footer={modalFooter}
        >
            {infoConfirm && (
                <div className="identityInfo">
                    <div className="itemInfo">
                        <span className="item-info-label">{t('identityInfo.name')}</span>
                        <span className="item-info-container">{currentEmployee.name}</span>
                    </div>
                    <div className="itemInfo">
                        <span className="item-info-label">{t('identityInfo.mobile')}</span>
                        <span className="item-info-container">{currentEmployee.mobile}</span>
                    </div>
                    <div className="itemInfo">
                        <span className="item-info-label">{t('identityInfo.identity')}</span>
                        <span className="item-info-container">
                            <Input
                                placeholder={t('search.identityInfoTextContact')}
                                className="input-box"
                                value={idCard}
                                allowClear
                                maxLength={18}
                                onChange={(e) => {
                                    if (!e.target.value) setErrorTip(false);
                                    setIdCard(lodashTrim(e.target.value));
                                }}
                                onBlur={(e) => {
                                    let reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                                    if (!reg.test(e.target.value) && e.target.value) {
                                        setErrorTip(true);
                                        return;
                                    }
                                    setErrorTip(false);
                                }}
                            />
                            {errorTip && (
                                <p style={{ color: 'red' }}>
                                    {t('identityInfo.identityInfoError')}
                                </p>
                            )}
                            <p>{t('identityInfo.infoTips')}</p>
                        </span>
                    </div>
                </div>
            )}
            {!infoConfirm && <div style={{ margin: ' auto' }}>{t('identityInfo.infoSubmit')}</div>}
        </Modal>
    );
}

function mapStateToProps({ chat, user }: { chat: ChatState; user: UserState }) {
    return {
        identityInfoModal: chat.identityInfoModal,
        userData: user.userData.user,
        currentEmployee: chat.currentEmployee,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        setIdentityInfoModal(payload: boolean) {
            dispatch({ type: 'chat/setIdentityInfoModal', payload });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
