/**
 * voip页面
 * @author zhangpengcheng15
 */
import React, { Component } from 'react';
import { message } from 'antd';
import './index.less';
import AudioMp3 from '@/assets/ringoutdou.mp3';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { FocusSDK } from '@/utils';
import { withTranslation, WithTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import { changeEgovUrl } from '@/utils/tools';
import {
    MemberStatus,
    MemberMicStatus,
    MemberParticipateStatus,
    ConferenceMember,
    ConferenceType,
} from '../types';
import { Avatar } from '@/baseComponents/Avatar';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix

const PAGE_SIZE = 12; // 每页显示的最大头像数

interface IProps {
    t: any;
}
interface IState {
    userList: VoipUser[];
    isSingle: boolean;
    title: string;
    durationTime: number; // 会议持续时间
    currentPage: number;
    isAudioDisable: boolean;
    owner?: VoipUser;
    isOwner: boolean;
}

interface VoipUser {
    appId: string;
    teamId: string;
    userId: string;
    avatar: string;
    realName: string;
    audioDisable: boolean;
    mps: MemberParticipateStatus;
}
type Props = Readonly<WithTranslation & IProps>;
class VoipCallModal extends Component<Props, IState> {
    static contextType = GlobalContext;
    audioRef = React.createRef<HTMLAudioElement>();
    timer: any = null;
    userMap: { [key: string]: any } = {};
    constructor(props: any) {
        super(props);
        this.state = {
            // userList: [
            //     {
            //         appId: 'string',
            //         teamId: 'string',
            //         userId: 'string',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: '安然',
            //         audioDisable: true,
            //         join: true,
            //     },
            //     {
            //         appId: 'string1',
            //         teamId: 'string1',
            //         userId: 'string1',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string2',
            //         teamId: 'string2',
            //         userId: 'string2',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string3',
            //         teamId: 'string3',
            //         userId: 'string3',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string4',
            //         teamId: 'string4',
            //         userId: 'string4',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string5',
            //         teamId: 'string5',
            //         userId: 'string5',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string6',
            //         teamId: 'string6',
            //         userId: 'string6',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string7',
            //         teamId: 'string7',
            //         userId: 'string7',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string8',
            //         teamId: 'string8',
            //         userId: 'string8',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string9',
            //         teamId: 'string9',
            //         userId: 'string9',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string10',
            //         teamId: 'string10',
            //         userId: 'string10',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string11',
            //         teamId: 'string11',
            //         userId: 'string11',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            //     {
            //         appId: 'string12',
            //         teamId: 'string12',
            //         userId: 'string12',
            //         avatar:
            //             'https://m.360buyimg.com/jmeside/jfs/t1/30932/11/6034/82864/5c8b6b79Ee6395e10/2d5de40ad81b79d1.png',
            //         realName: 'string1dsxz gfchjklzfdghj',
            //         audioDisable: false,
            //         join: false,
            //     },
            // ],
            isSingle: true,
            title: '',
            durationTime: 0,
            currentPage: 0,
            isAudioDisable: false,
            userList: [],
            isOwner: false,
        };
        this.bindEvents();
        FocusSDK.sendIpcMessage('voip:getInitCallData');
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.unbingEvents();
    }

    bindEvents = () => {
        FocusSDK.on('voip:setInitCallData', this.initCallModal);
        FocusSDK.on('voip:OnConferenceSyncState', this.OnConferenceSyncState);
    };

    unbingEvents = () => {
        FocusSDK.off('voip:OnConferenceSyncState', this.OnConferenceSyncState);
        FocusSDK.off('voip:setInitCallData', this.initCallModal);
    };

    initCallModal = (data: {
        memberstatus_list: Array<MemberStatus>;
        title: string;
        conference_type: ConferenceType;
        owner: ConferenceMember;
        create_timestamp: number;
    }) => {
        const { title, conference_type, owner = {} as any, create_timestamp } = data;
        const { authInfo } = this.context as GlobalContextConfig;
        const { teamUserInfo, userId } = authInfo;
        const { ddAppId, teamId } = teamUserInfo;
        const isSingle = conference_type === ConferenceType.kConferenceSingle;
        const isOwner =
            `${teamId}:${ddAppId}:${userId}` ===
            `${owner.team_id_}:${owner.member_app_}:${owner.member_id_}`;
        this.setState({
            title,
            isSingle,
            isOwner,
        });
        // 如果是单聊并且是负责人，响铃
        if (isSingle && isOwner) {
            const au = this.audioRef.current;
            if (au) {
                au.muted = false;
            }
        }
        // 如果是群聊会议，或者不是发起人直接开始计时
        if (!isSingle || !isOwner) {
            this.timer = setInterval(this.handleComputeTime, 1000);
        }
        this.OnConferenceSyncState(data);
    };

    startTimer = () => {};

    OnConferenceSyncState = (data: { memberstatus_list: Array<MemberStatus>; source?: number }) => {
        const { imService } = this.context as GlobalContextConfig;
        const { memberstatus_list = [], source } = data;
        const list: VoipUser[] = memberstatus_list.map((member) => {
            const { member_id, displayName, mms, mps } = member;
            return {
                appId: member_id.member_app_,
                userId: member_id.member_id_,
                teamId: member_id.team_id_,
                realName: displayName,
                avatar: '',
                audioDisable: !(mms === MemberMicStatus.kMemberMicOpened),
                mps,
            };
        });
        let newList = list;
        // 全量同步
        if ((source === 0 || !source) && list.length > 1) {
            // todo
        } else {
            const { userList } = this.state;
            newList = [...userList];
            list.forEach((user) => {
                const index = newList.findIndex(
                    (u) =>
                        `${u.teamId}:${u.appId}:${u.userId}` ===
                        `${user.teamId}:${user.appId}:${user.userId}`
                );
                if (index === -1) {
                    newList.push(user);
                } else {
                    newList[index] = { ...newList[index], ...user };
                }
            });
        }
        newList.forEach((user) => {
            const key = `${user.userId}:${user.appId}:${user.teamId}`;
            if (this.userMap[key]) {
                user.avatar = this.userMap[key].avatar;
                return;
            }
        });

        this.setState(
            {
                userList: newList,
            },
            () => {
                this.handleOwnerStatus();
                this.handleRingAndTime();
            }
        );
        // 如果是同步模式，由于状态更新太快，会导致后面的状态被前面覆盖，所以在此使用异步
        newList
            .filter((user) => !user.avatar)
            .map(async (user) => {
                const key = `${user.userId}:${user.appId}:${user.teamId}`;
                const result = await imService
                    .getChatManager()
                    ?.service?.employee.getUserEbookDetail({
                        appId: user.appId,
                        userId: user.userId,
                        teamId: user.teamId,
                        isMulti: true,
                    });

                user.avatar = result?.avatar || '';
                this.userMap[key] = result;
                this.forceUpdate();
            });
    };

    /**
     * 判断是否关闭等待铃声:群聊从不响铃
     * 判断是否开始计时
     */
    handleRingAndTime = () => {
        const { isSingle, isOwner, userList, durationTime } = this.state;
        // 不是单聊或者不是创建人或者会议已经开始，不再做后续处理
        if (!isSingle || !isOwner || durationTime !== 0) {
            return;
        }
        // 如果单聊双方都已经加入，静音，并且开始计时
        const index = userList.findIndex(
            (u) => !(u.mps === MemberParticipateStatus.kMemberParticipateIn)
        );
        if (index === -1) {
            const au = this.audioRef.current;
            if (au) {
                au.muted = true;
            }
            // 如果是单聊，当两个人都加入的时候，开始计算时间
            if (this.timer) {
                return;
            }
            this.timer = setInterval(this.handleComputeTime, 1000);
        }
    };

    handleComputeTime = () => {
        const { durationTime } = this.state;
        this.setState({
            durationTime: durationTime + 1,
        });
    };

    handleOwnerStatus = () => {
        const { userList } = this.state;
        const { authInfo } = this.context as GlobalContextConfig;
        const { teamUserInfo, userId } = authInfo;
        const { ddAppId, teamId } = teamUserInfo;
        const index = userList.findIndex(
            (u) => `${u.teamId}:${u.appId}:${u.userId}` === `${teamId}:${ddAppId}:${userId}`
        );
        if (index === -1) {
            return;
        }
        const owner = userList[index];
        this.setState({
            owner,
            isAudioDisable: owner.audioDisable,
        });
    };

    pageTurnLeft = () => {
        const { currentPage } = this.state;
        if (currentPage > 0) {
            this.setState({
                currentPage: currentPage - 1,
            });
        }
    };

    pageTurnRight = () => {
        const { currentPage, userList } = this.state;
        const max = Math.floor(userList.length / PAGE_SIZE);
        if (currentPage < max) {
            this.setState({
                currentPage: currentPage + 1,
            });
        }
    };

    changeAudioDisable = () => {
        const { isAudioDisable, owner } = this.state;
        if (!owner) {
            return;
        }
        const own: ConferenceMember = {
            member_app_: owner.appId,
            member_id_: owner.userId,
            team_id_: owner.teamId,
        };
        FocusSDK.sendIpcMessage('voip:ConferenceMute', {
            conferenceMember: own,
            mute: !isAudioDisable,
        });
    };

    handleHangup = () => {
        // todo
        FocusSDK.sendIpcMessage('voip:ConferenceLeave');
    };

    render() {
        const { title, durationTime, userList, currentPage, isAudioDisable } = this.state;
        const { t } = this.props;
        const renderList = userList.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
        const joinLength = userList.filter(
            (user) => user.mps === MemberParticipateStatus.kMemberParticipateIn
        ).length;
        const minitus = Math.floor(durationTime / 60);
        const seconds = durationTime % 60;
        return (
            <div className="voip-call-modal">
                <div className="voip-call-modal-header">
                    <div className="voip-call-modal-header-title">{title}</div>
                    {durationTime !== 0 && (
                        <div className="voip-call-modal-header-duration">
                            {`${minitus < 10 ? '0' + minitus : minitus}:${
                                seconds < 10 ? '0' + seconds : seconds
                            }`}
                        </div>
                    )}
                </div>
                <div className="voip-call-modal-content">
                    {userList.length > PAGE_SIZE && (
                        <div className="button-turn button-turn-left" onClick={this.pageTurnLeft}>
                            <IconFont type="iconwin_left" />
                        </div>
                    )}

                    <div className="voip-call-modal-content-userList">
                        {renderList.map((user) => {
                            return (
                                <VoipUserItem
                                    user={user}
                                    t={t}
                                    key={`${user.appId}:${user.teamId}:${user.userId}`}
                                />
                            );
                        })}
                    </div>

                    {userList.length > PAGE_SIZE && (
                        <div className="button-turn button-turn-right" onClick={this.pageTurnRight}>
                            <IconFont type="iconwin_right" />
                        </div>
                    )}
                </div>
                <div className="voip-call-modal-bottom">
                    {/* <div className="bottom-button button-addMember">
                        <div className="button-content">
                            <IconFont type="iconic_shared" />
                        </div>
                        <div className="button-label">
                            {`${t('member')}${joinLength}/${userList.length}`}
                        </div>
                    </div> */}
                    {/* <div className="bottom-button button-share-window">
                        <div className="button-content">
                            <IconFont type="" />
                        </div>
                        <div className="button-label">{t('share window')}</div>
                    </div> */}
                    <div className="bottom-button button-audio">
                        <div
                            className={`button-content ${
                                isAudioDisable ? 'button-content-disable' : ''
                            }`}
                            onClick={this.changeAudioDisable}
                        >
                            {isAudioDisable ? (
                                <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                            ) : (
                                <IconFont type="iconapp_btn_joymeeting_voice" />
                            )}
                        </div>
                        <div className="button-label">{t(isAudioDisable ? 'closed' : 'open')}</div>
                    </div>
                    <div className="bottom-button button-hangup">
                        <div className="button-content" onClick={this.handleHangup}>
                            <IconFont type="iconapp_btn_joymerting_hangup" />
                        </div>
                        <div className="button-label">{t('Hang up')}</div>
                    </div>
                </div>
                <audio src={changeEgovUrl(AudioMp3)} loop autoPlay muted ref={this.audioRef} />
            </div>
        );
    }
}

export default withTranslation('meeting')(VoipCallModal);

function VoipUserItem({ user, t }: { user: VoipUser; t: any }) {
    const { avatar, realName, mps, audioDisable } = user;
    return (
        <div className="voip-user-container">
            <div className="voip-user-content">
                <Avatar
                    className="voip-user-avatar"
                    src={changeEgovUrl(avatar)}
                    name={realName || ''}
                    styleObj={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                        color: '#fff',
                        textAlign: 'center',
                        lineHeight: 50,
                        fontSize: 20,
                    }}
                />
                {mps === MemberParticipateStatus.kMemberParticipateIn ? (
                    <div
                        className={`voip-user-audio ${
                            audioDisable ? 'voip-user-audio-disable' : ''
                        }`}
                    >
                        {audioDisable ? (
                            <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                        ) : (
                            <IconFont type="iconapp_btn_joymeeting_voice" />
                        )}
                    </div>
                ) : [
                      MemberParticipateStatus.kMemberParticipateEntering,
                      MemberParticipateStatus.kMemberParticipateInviting,
                  ].includes(mps) ? (
                    <div className="voip-user-waiting">
                        <span className="waiting-circle" />
                        <span className="waiting-circle" />
                        <span className="waiting-circle" />
                    </div>
                ) : (
                    <div className="voip-user-out">{t('user unjoin')}</div>
                )}
            </div>
            <div className="voip-user-title">{realName}</div>
        </div>
    );
}
