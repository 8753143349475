import { AuthData } from './../../types/LocalConfig';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { generateMessageId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import log from '@/utils/logger';
import ImService from '@/server/ImService';

import {
    CloudLinkStatusEnum,
    CloudLinkSessionStateEnum,
    changeCloudLinkStatus,
    getCloudLinkStatus,
} from './Connector';
import { FocusSDK, getClientType, getUser } from '@/utils';
import {
    cloudlinkRegister,
    addParticipants,
    createMeeting,
    conferenceCall,
    sendConferenceCardMsg,
} from '@/api/meeting';
import bus from '@/utils/bus';
import { NPCRoom } from './Invites/NPC';
import { message } from 'antd';
import { getNoticeBot, sendNoticeCardMessage } from '@/api/chat';
import { isUndefined } from 'lodash';
import i18n from 'i18next';
import { Employee } from '@/types/chat';
import cache from '@/utils/cache';
import { MeetingPlatform } from '@/types/common';
import dayjs from 'dayjs';
import { AVSDK, sessionStateContentMap } from '@/baseComponents/Meeting/common';
import { getBigScreenMeetingTopic } from '@/baseComponents/Meeting/utils';
import CHAT_NOTICE from '@/contant/chat';

const logger = log.getLogger('cloudlink');

const isBigScreen = process.env.REACT_APP_X_FLAG === 'olympic';
log.debug('isBigScreen', isBigScreen);
interface BasicUserInfo {
    pin: string;
    teamId: string;
    name: string;
    app: string;
    avatar: string;
}

export interface ToUserType {
    appId: string;
    userId: string;
    teamId: string;
    userName: string;
    avatar: string;
}

let currentMeetingInfo: {
    password: string;
    meetingNumber: string;
    conferenceUuid: string;
} | null = null;

export function getCurrentCloudLinkMeetingInfo() {
    return currentMeetingInfo;
}

export function setCurrentCloudLinkMeetingInfo(meetingInfo: any) {
    logger.debug('currentMeetingInfo', meetingInfo);
    currentMeetingInfo = meetingInfo;
}

export function createCloudLinkTerminalCall(
    terminals: string[],
    meetingTopic = getBigScreenMeetingTopic()
) {
    return createCloudLinkCall({
        terminals: {
            terminalId: terminals,
        },
        opts: { meetingTopic },
    });
}

export function createCloudLinkSingleCall(
    toUser: ToUserType,
    meetingTopic = getBigScreenMeetingTopic()
) {
    return new Promise((resolve, reject) => {
        if (FocusSDK.getDeviceInfo().platform !== 'win32') {
            reject(new Error('信创会议暂只支持windows'));
            return;
        }
        if (getCloudLinkStatus() === CloudLinkStatusEnum.IN_MEETING) {
            // 会议中，直接邀请
            logger.debug('会议中，直接邀请');
            invite({ toUsers: [toUser], meetingTopic });
            resolve(true);
            return;
        }
        if (getCloudLinkStatus() !== CloudLinkStatusEnum.FREE) {
            reject(new Error('会议资源重置中，请5秒后重新发起会议'));
            return;
        }
        bus.emit('cloudlink:create single call', {
            toUser,
            meetingInfo: {
                meetingTopic,
            },
        });
        resolve(true);
    });
}

function invite({
    toUsers,
    terminals,
    meetingTopic,
}: {
    toUsers?: ToUserType[];
    terminals?: {
        terminalId?: string[];
        room?: NPCRoom;
    };
    meetingTopic: string;
}) {
    if (!currentMeetingInfo) return;
    logger.debug(`deeplink:--invite--`, currentMeetingInfo, toUsers);
    const { password, meetingNumber, conferenceUuid } = currentMeetingInfo;
    // FIXME: 信创会议，主题不能超过20个字
    // let topic = `${userInfo.realName}发起的信创会议`;
    // if (toUsers?.length === 1) {
    //     topic = '%from和%to的信创会议'
    //         .replace('%from', userInfo.realName || '')
    //         .replace('%to', toUsers[0].userName);
    // }
    if (toUsers && toUsers.length > 0) {
        sendCloudLinkCallMessage(
            {
                password,
                meetingNumber,
                meetingTopic,
            },
            toUsers.map(({ userId, userName, appId, teamId, avatar }: any) => {
                return {
                    appId,
                    userId,
                    teamId,
                    name: userName,
                    avatar,
                };
            })
        );
    }

    if (terminals && conferenceUuid) {
        if ((terminals.terminalId && terminals.terminalId.length > 0) || terminals.room) {
            addParticipants({
                conferenceUuid,
                terminalId: terminals.terminalId || [],
                room: terminals.room || null,
            }).then(([_res, err]: any) => {
                if (err) {
                    message.error(err);
                }
            });
        }
    }
}

export function createCloudLinkCall({
    toUsers,
    terminals,
    opts,
}: {
    toUsers?: ToUserType[];
    terminals?: {
        terminalId?: string[];
        room?: NPCRoom;
    };
    opts: {
        meetingTopic: string;
        meetingPassword?: string;
        isvideooff?: boolean;
        isaudiooff?: boolean;
    };
}) {
    const { meetingTopic, meetingPassword = '', isvideooff, isaudiooff } = opts;
    return new Promise((resolve, reject) => {
        try {
            if (FocusSDK.getDeviceInfo().platform !== 'win32') {
                reject(new Error('信创视频会议暂只支持windows'));
                return;
            }
            if (!terminals && (!toUsers || toUsers.length === 0)) {
                throw new Error('缺少必要参数');
            }
            logger.debug(`deeplink:--getCloudLinkStatus--`, getCloudLinkStatus());
            if (getCloudLinkStatus() === CloudLinkStatusEnum.IN_MEETING) {
                // 会议中，直接邀请
                logger.debug('会议中，直接邀请');
                invite({ toUsers, terminals, meetingTopic });
                resolve(true);
                return;
            }
            if (getCloudLinkStatus() !== CloudLinkStatusEnum.FREE) {
                throw new Error('会议资源重置中，请5秒后重新发起会议');
            }
            changeCloudLinkStatus(CloudLinkStatusEnum.BUSY);
            ImService.getInstance()
                .getCurrentEmployee()
                .then((userInfo: Partial<Employee>) => {
                    if (!userInfo.userId) {
                        changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                        reject(new Error('获取当前用户失败'));
                        return;
                    }
                    function handleCreateCb({
                        status,
                        error,
                        data,
                    }: {
                        status: number;
                        error: string;
                        data: any;
                    }) {
                        FocusSDK.off('cloudlink:createMeetingCb', handleCreateCb);
                        // FocusSDK.off('cloudlink:joinMeetingCb', handleCreateCb);
                        if (status) {
                            if (isBigScreen) {
                                // 声智
                                fetch(
                                    'https://olympics-support-platform-api-fat.soundai.net/ow/support/jb/callback?action=open'
                                );
                            }
                            FocusSDK.on('cloudlink:callEnded', handleCallEnded);
                            setCurrentCloudLinkMeetingInfo(data);
                            invite({ toUsers, terminals, meetingTopic: meetingTopic });
                            resolve(data);
                        } else {
                            changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                            reject(new Error(error));
                        }
                    }
                    // 会议结束
                    function handleCallEnded() {
                        if (isBigScreen) {
                            fetch(
                                'https://olympics-support-platform-api-fat.soundai.net/ow/support/jb/callback?action=close'
                            );
                        }
                        FocusSDK.off('cloudlink:callEnded', handleCallEnded);
                        currentMeetingInfo = null;
                    }

                    if (cache.cloudLinkLogin) {
                        // 直接创建会议并加入
                        createMeeting({
                            startTime: (dayjs().valueOf() + 5000).toString(),
                            duration: 2,
                            topic: meetingTopic,
                            password: meetingPassword,
                            participantIds: [
                                {
                                    userId: userInfo.userId,
                                    teamId: userInfo.teamId || '',
                                    appId: userInfo.app || '',
                                    realName: userInfo.name || '',
                                    headImg: userInfo.avatar || '',
                                },
                            ],
                            platform: MeetingPlatform.HW,
                        }).then(([meetingInfo, err]) => {
                            if (err) {
                                changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                                reject(new Error(err));
                                return;
                            } else {
                                bus.emit('cloudlink:init');
                                logger.debug('直接加入', meetingInfo);
                                FocusSDK.once('cloudlink:joinMeetingCb', handleCreateCb);

                                setTimeout(() => {
                                    FocusSDK.sendIpcMessage('cloudlink.joinMeeting', {
                                        meetingInfo: {
                                            password: meetingPassword,
                                            meetingNumber: meetingInfo?.meetingId,
                                            isvideooff: true,
                                            isaudiooff: true,
                                            uuid: meetingInfo?.uuid,
                                        },
                                    });
                                }, 1000);
                            }
                        });

                        // bus.emit('cloudlink:init');

                        // FocusSDK.once('cloudlink:createMeetingCb', handleCreateCb);
                        // FocusSDK.sendIpcMessage('cloudlink.create', {
                        //     meetingInfo: {
                        //         topic: meetingTopic,
                        //         password: meetingPassword,
                        //         chairmanPwd: '',
                        //         isvideooff: isUndefined(isvideooff) ? true : isvideooff,
                        //         isaudiooff: isUndefined(isaudiooff) ? true : isaudiooff,
                        //     },
                        // });
                    } else {
                        cloudlinkRegister({
                            userIds: [userInfo.userId],
                        }).then(([loginInfo, err]) => {
                            if (err) {
                                changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                                reject(new Error(err));
                                return;
                            }
                            bus.emit('cloudlink:init');

                            let topic = meetingTopic;

                            FocusSDK.once('cloudlink:createMeetingCb', handleCreateCb);
                            FocusSDK.sendIpcMessage('cloudlink.create', {
                                meetingInfo: {
                                    topic,
                                    password: meetingPassword,
                                    chairmanPwd: '',
                                    isvideooff: isUndefined(isvideooff) ? true : isvideooff,
                                    isaudiooff: isUndefined(isaudiooff) ? true : isaudiooff,
                                },
                                loginInfo: loginInfo[0],
                            });
                        });
                    }
                });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}

export async function sendCloudLinkCallMessage(
    meetingInfo: {
        meetingTopic: string;
        meetingNumber: string;
        password: string;
    },
    toUsers: {
        name: string;
        teamId: string;
        userId: string;
        appId: string;
        avatar: string;
    }[],
    sessionState = CloudLinkSessionStateEnum.Call
) {
    const body = {
        ext: {
            displayName: '',
            avsdk: 'huawei',
            projectType: 0,
            enableVideo: 0,
            meetingID: '',
            meetingTopic: meetingInfo.meetingTopic,
            meetingDuration: 120,
            meetingNumber: String(meetingInfo.meetingNumber),
            enableMicphone: 0,
            clientType: getClientType(),
            meetingPassword: meetingInfo.password || '',
            sessionState,
            sessionType: 0,
            startTime: new Date().getTime().toString(),
        },
        flag: 100,
        state: 0,
        noPush: 0,
        pushSms: 0,
        type: 'sys',
        content: meetingInfo.meetingTopic,
    };
    logger.debug(`deeplink:--sendCloudLinkCallMessage--`, body);
    return handleMeetingSendMessage({
        toUsers: toUsers.map((v: any) => {
            return {
                ...v,
                app: v.appId,
                pin: v.userId,
                username: v.name,
            };
        }, []),
        body,
    });
}

export async function sendCloudLinkNoticeCardMessage(toUsers: any, message: any) {
    const imService = ImService.getInstance();
    const userInfo: Partial<Employee> = await imService.getCurrentEmployee();

    return sendNoticeCardMessage(
        generateRequestCardMsg(
            {
                pin: userInfo.userId || '',
                teamId: userInfo.teamId || '',
                name: userInfo.name || '',
                app: userInfo.app || '',
                avatar: userInfo.avatar || '',
            },
            toUsers,
            message
        ),
        '4iJ0jfgCLTXdz6YED06W'
    );
}

export async function handleMeetingSendMessage({
    toUsers,
    body,
    callback,
}: {
    toUsers: any;
    body: any;
    callback?: Function;
}) {
    if (!body || !toUsers || toUsers.length === 0) {
        logger.debug('invilad options', body, toUsers);
        return;
    }

    const imService = ImService.getInstance();
    const userInfo = await getUser();
    // eslint-disable-next-line no-param-reassign
    toUsers = toUsers.filter((item: any) => item.userId !== userInfo.userId);

    if (toUsers.length === 0) {
        return;
    }
    const fromUser = {
        pin: userInfo.userId || '',
        teamId: userInfo.selectedTeamId || '',
        name: userInfo.realName || '',
        app: userInfo.ddAppId || '',
        avatar: userInfo.teamUserInfo.headImg || '',
    };
    body.ext.fromUser = fromUser;
    const ext = body.ext;
    if (body.ext.sessionState === CloudLinkSessionStateEnum.Call) {
        cache.telCall &&
            conferenceCall({
                userIds: toUsers.map((item: any) => item.userId),
                meetingId: body.ext.meetingNumber.toString(),
                password: body.ext.meetingPassword,
                topic: body.ext.meetingTopic,
                sdkType: 'huawei',
            }).then(([res, err]) => {
                if (err) {
                    logger.error(err);
                }
            });
    }
    if (body.ext.sessionState === CloudLinkSessionStateEnum.Call) {
        sendConferenceCardMsg({
            noticeId: `eopen-push_${getNoticeBot(AVSDK.HUAWEI)}`,
            title: ext.meetingTopic,
            receivers: toUsers.map(({ app, pin, teamId }: any) => {
                return {
                    app,
                    pin,
                    teamId,
                };
            }),
            meetingInfoReq: {
                meetingNumber: ext.meetingNumber,
                initiator: ext.fromUser.name,
            },
            infox: {
                password: ext.meetingPassword,
                deepLink: generateRequestCardMsg(fromUser, toUsers, body).infox.deepLink,
                btnName: '立即入会',
                action: 'joinmeeting',
                type: AVSDK.HUAWEI,
            },
        });
        // sendNoticeCardMessage(
        //     generateRequestCardMsg(fromUser, toUsers, body),
        //     '4iJ0jfgCLTXdz6YED06W'
        // );
    }
    FocusSDK.sendIpcMessage('voip.sendVoipCall', {
        toUsers,
        body,
    });
    const sendResult = await Promise.all(
        toUsers
            .filter((user: any) => user.pin !== userInfo.userId)
            .map((user: any) => {
                return new Promise((resolve) => {
                    const sessionId = imService.generateSingleSessionId(
                        {
                            app: userInfo.ddAppId,
                            userId: userInfo.userId,
                            teamId: userInfo.selectedTeamId,
                        } as any,
                        {
                            pin: user.pin,
                            app: user.app,
                            teamId: user.teamId,
                        }
                    );
                    const newMessage = {
                        sessionId,
                        ...body,
                        requestData: {
                            sessionId,
                        },
                        timestamp: new Date().getTime(),
                        type: ChatMessageType.SYS,
                        flag: 100,
                    } as any;
                    const messageId = generateMessageId();
                    imService
                        .sendChatMessage(sessionId, messageId, newMessage, {
                            skipCheckSession: true,
                        })
                        .then((res: any) => {
                            logger.debug('send chat message ok: ', res);
                            resolve({
                                message: '消息发送成功',
                                status: 1,
                            });
                        })
                        .catch((e: any) => {
                            logger.error('send chat message err', e);
                            resolve({
                                message: '消息发送失败',
                                status: 0,
                                error: e,
                            });
                        });
                });
            })
    );

    if (callback) {
        callback(sendResult);
    }
}

export function generateRequestCardMsg(
    fromUser: BasicUserInfo,
    toUsers: BasicUserInfo[],
    message: any
) {
    const { ext } = message;
    const deepLink =
        'openapp.jdfocus://jm/biz/appcenter/cloudlink?mparam=' +
        encodeURIComponent(
            JSON.stringify({
                device: null,
                displayName: null,
                enableMicphone: 0,
                enableVideo: 0,
                ...ext,
                fromUser: {
                    clientType: getClientType(),
                    info: null,
                    intro: null,
                    nameIndex: null,
                    xToken: null,
                    ...fromUser,
                },
            })
        );
    return {
        title: `${ext.meetingTopic}`,
        content: `${i18n.t('common:joinMeeting.meetingNumber')}: ${ext.meetingNumber}`,
        extend: {
            url: '',
        },
        from: fromUser,
        imgUrl: '',
        infox: {
            ...ext,
            imgUrl: '',
            deepLink,
        },
        tos: toUsers.map((item: any) => {
            return {
                ...item,
                pin: item.pin || item.userId,
                name: item.name || item.realName,
            };
        }),
    };
}

export async function replayCloudLinkMessage(
    sessionState: CloudLinkSessionStateEnum,
    originMessage: any,
    callback?: Function
) {
    const { ext = {} } = originMessage;
    const { fromUser } = ext;

    const newMessage = {
        ext: {
            ...ext,
            clientType: getClientType(),
            toUsers: [fromUser],
            sessionState: sessionState,
            sessionType: 1,
        },
        flag: 100,
        pushSms: 0,
        state: 0,
        noPush: 1,
        type: 'sys',
        content: `${sessionStateContentMap[sessionState]}`,
    };
    return handleMeetingSendMessage({
        toUsers: [fromUser],
        body: newMessage,
        callback,
    });
}

// 加入信创会议
export function joinCloudLink(
    userId: string,
    meetingInfo: {
        password: string;
        meetingNumber: string;
        username: string;
        isvideooff: boolean;
        isaudiooff: boolean;
    }
) {
    return new Promise((resolve, reject) => {
        if (FocusSDK.getDeviceInfo().platform !== 'win32') {
            reject(new Error('信创视频会议暂只支持windows'));
            return;
        }
        if (getCloudLinkStatus() === CloudLinkStatusEnum.IN_MEETING) {
            reject(new Error('忙碌，请先结束其他会议'));
            return;
        }
        if (getCloudLinkStatus() !== CloudLinkStatusEnum.FREE) {
            reject(new Error('会议资源重置中，请稍后重试'));
            return;
        }
        changeCloudLinkStatus(CloudLinkStatusEnum.BUSY);
        setCurrentCloudLinkMeetingInfo(null);
        cloudlinkRegister({
            userIds: [userId],
        }).then(([loginInfo, err]) => {
            if (err) {
                changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                console.error(err);
                reject(new Error(err));
                changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                return;
            }
            bus.emit('cloudlink:init');
            function handleJoinCb({
                status,
                data,
                error,
            }: {
                status: number;
                data: any;
                error: any;
            }) {
                FocusSDK.off('cloudlink:joinMeetingCb', handleJoinCb);
                if (status) {
                    setCurrentCloudLinkMeetingInfo(data);
                    resolve(data);
                } else {
                    changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                    reject(error);
                }
            }
            FocusSDK.on('cloudlink:joinMeetingCb', handleJoinCb);

            FocusSDK.sendIpcMessage('cloudlink.joinMeeting', {
                loginInfo: loginInfo[0],
                meetingInfo,
            });
        });
    });
}

export function getDevicesInfo(type: any) {
    return new Promise((resolve, reject) => {
        function handle(param: any) {
            FocusSDK.off('cloudlink:getDevicesInfoCb', handle);
            const { data, status } = param;
            if (status === 1) {
                resolve(data);
            } else {
                reject(new Error('获取失败'));
            }
        }
        FocusSDK.on('cloudlink:getDevicesInfoCb', handle);
        FocusSDK.sendIpcMessage('cloudlink.getDevicesInfo', type);
    });
}
