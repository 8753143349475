import React, { useState, useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { TotalConlum, TotalRow } from './../utils/constant';
import { getCalendarList, getWeekList } from './../utils/index';
import './index.less';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import { leftPng, rightPng, topPng, dropDownPng } from '@/assets/img/calendar';
import { CalendarIcon } from '@/components/Calendars/Common';
import { useTranslation } from 'react-i18next';
import { userSelectDataType } from '@/components/Joyspace/util';
import { changeEgovUrl } from '@/utils/tools';
import IconFont from '@/components/icon';

let a = getWeekList(dayjs().subtract(2 * 24, 'hour'), false);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);

interface Iprops {
    time?: dayjs.Dayjs;
    mode?: boolean; // true 表示周一为一周的第一天, false表示周日为一周的第一天
    change?: (add?: boolean) => void;
    toggle?: () => void;
    noFold?: boolean; // 是否隐藏折叠按钮
}

export function CalendarHeader({ time, mode = true, change, toggle, noFold }: Iprops) {
    const year = time?.year();
    let month = time?.month() || 0;
    let date = time?.date() || 0;
    const { t } = useTranslation('calendar');
    const [toggleFlag, setToggleFlag] = useState(false);
    function toggleHandle() {
        setToggleFlag(!toggleFlag);
        toggle?.();
    }
    return (
        <div className="calendar-header-wrapper">
            <span className="text">
                {`${year}${t('year')}${month + 1}${t('month')}${date}${t('day')}`}
            </span>
            <div className="calendar-switch-icon">
                <IconFont type="iconwin_left" onClick={() => change?.(false)} />
                <IconFont type="iconwin_right" onClick={() => change?.(true)} />
                {/* {!noFold && (
                    <CalendarIcon
                        style={{
                            transform: toggleFlag ? 'rotate(180deg)' : 'rotate(0deg)',
                            MozTransformOrigin: 'center center',
                        }}
                        src={changeEgovUrl(topPng)}
                        width={20}
                        height={20}
                        onClick={() => toggleHandle()}
                    />
                )} */}
                {/* <CaretLeftOutlined onClick={() => change?.(false)} />
                <CaretRightOutlined onClick={() => change?.(true)} /> */}
                {/* <p
                    style={{
                        background: '#fff',
                        border: '1px solid rgba(239,239,239,1)',
                        borderRadius: '4px',
                        height: '18px',
                        display: 'inline-block',
                    }}
                >
                    <CaretUpOutlined />
                </p> */}
            </div>
        </div>
    );
}

interface IpropsBaseCalendar {
    time?: dayjs.Dayjs;
    mode?: boolean; // true 表示周一为一周的第一天, false表示周日为一周的第一天
    changeHandle?: (p: dayjs.Dayjs, b?: boolean) => void;
    handleSelect?: (p: dayjs.Dayjs) => void;
    extraNotice?: string[] | dayjs.Dayjs[];
    noFold?: boolean; // 是否隐藏折叠按钮
    isShowFooter?: boolean;
    isChangeHeaderVisible?: boolean; // 切换日历选择器上的前进后退键是否还显示日历
    isRepeatPicker?: boolean;
    selectForever?: Function;
}

export default function BaseCalendar(prop: IpropsBaseCalendar) {
    let current = prop.time || dayjs();
    let isChangeHeaderVisible = prop.isChangeHeaderVisible || false;
    const { t } = useTranslation('calendar');
    const [toggle, setToggle] = useState(true);
    const today = dayjs();
    let dateList: any[] = useMemo(() => {
        return getCalendarList(current);
    }, [current]);
    let weeks: number[] = [];
    let weekStart = dateList[0].week();
    for (let i = 1; i <= TotalRow; i++) {
        weeks[i - 1] = weekStart;
        weekStart++;
    }

    function selectHandle(item: dayjs.Dayjs, e: any) {
        if (prop.changeHandle) {
            prop.changeHandle(item);
        }
        if (prop.handleSelect) {
            prop.handleSelect(item);
        }
    }

    function changeHeader(add?: boolean) {
        let item: dayjs.Dayjs;
        if (add) {
            item = current.add(1, 'month');
        } else {
            item = current.subtract(1, 'month');
        }
        if (prop.changeHandle) {
            prop.changeHandle(item, isChangeHeaderVisible);
        }
    }
    function toggleHandle() {
        setToggle(!toggle);
    }

    function setDay(addDay: number) {
        const date = today.add(addDay, 'day');
        prop?.changeHandle?.(date);
    }

    const getCalendarFooter = useCallback(() => {
        let isToday = today.startOf('date').isSame(current.startOf('date'));
        const isTomorrow = today.add(1, 'day').startOf('date').isSame(current.startOf('date'));
        const isAfterTomorrow = today.add(2, 'day').startOf('date').isSame(current.startOf('date'));
        return (
            <>
                {!prop.isRepeatPicker && (
                    <div className="calendar-footer">
                        <span
                            className={isToday ? 'active' : ''}
                            onClick={() => {
                                setDay(0);
                            }}
                        >
                            今天
                        </span>
                        <span
                            className={isTomorrow ? 'active' : ''}
                            onClick={() => {
                                setDay(1);
                            }}
                        >
                            明天
                        </span>
                        <span
                            className={isAfterTomorrow ? 'active' : ''}
                            onClick={() => {
                                setDay(2);
                            }}
                        >
                            后天
                        </span>
                    </div>
                )}
                {prop.isRepeatPicker && (
                    <div className="calendar-footer">
                        <span
                            className={isToday ? 'active' : ''}
                            style={{ width: 'calc(100% - 28px)', textAlign: 'center' }}
                            onClick={() => {
                                prop?.selectForever?.();
                            }}
                        >
                            长期有效
                        </span>
                    </div>
                )}
                <div style={{ clear: 'both' }} />
            </>
        );
        // eslint-disable-next-line
    }, [current]);

    return (
        <div className="base-calendar">
            {/* <div className="calendar-header-wrapper">
                <CaretLeftOutlined />
                <span className="text">{`${year}年${month}月`}</span>
                <CaretRightOutlined />
            </div> */}
            <CalendarHeader
                time={current}
                change={(add) => changeHeader(add)}
                toggle={toggleHandle}
                noFold={prop.noFold}
            />
            {toggle ? (
                <div className="calendar-body-wrapper">
                    <div className="df">
                        {/* <ul className="week-ul">
                            <li className="disable week-item">W</li>
                            <div className="disable-bg">
                                {weeks.map((item, index) => {
                                    return (
                                        <li className="disable week-item" key={index}>
                                            {item}
                                        </li>
                                    );
                                })}
                            </div>
                        </ul> */}
                        <div className="pannel">
                            <ul className="flex">
                                <li>{t('one')}</li>
                                <li>{t('two')}</li>
                                <li>{t('three')}</li>
                                <li>{t('four')}</li>
                                <li>{t('five')}</li>
                                <li>{t('six')}</li>
                                <li>{t('seven')}</li>
                            </ul>
                            {weeks.map((item, index) => {
                                return (
                                    <ul
                                        key={index}
                                        className={`calendar-row common-row flex
                                    ${today.week() === item ? 'selected-row' : ''}`}
                                    >
                                        {/* <li className="calendar-column-item disable">{item}</li> */}
                                        {dateList
                                            .slice(index * 7, index * 7 + 7)
                                            .map((item1, index1) => {
                                                const isPast = dayjs().isAfter(item1);
                                                const isPastDay = dayjs().isAfter(item1, 'day');
                                                const isToday = item1.isToday();
                                                return (
                                                    <li
                                                        className={`calendar-column-item common-item
                                                        ${isToday ? 'today' : ''}
                                                        ${
                                                            item1.isSame(current.startOf('date'))
                                                                ? 'selected-day'
                                                                : ''
                                                        }`}
                                                        key={`${index}-${index1}`}
                                                        onClick={(e) => selectHandle(item1, e)}
                                                        style={{ opacity: isPastDay ? '0.5' : 1 }}
                                                    >
                                                        {isToday ? '今' : item1.date()}
                                                        {prop.extraNotice &&
                                                        prop.extraNotice.indexOf(
                                                            item1.format('YYYY-MM-DD')
                                                        ) !== -1 ? (
                                                            <p
                                                                className={`extra-notice ${
                                                                    !isToday && isPast
                                                                        ? 'schedule-timepast'
                                                                        : ''
                                                                }`}
                                                            />
                                                        ) : null}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                );
                            })}
                        </div>
                    </div>
                    {prop?.isShowFooter && getCalendarFooter()}
                </div>
            ) : null}
        </div>
    );
}
