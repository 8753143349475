import React, { useMemo } from 'react';
import './index.less';
import { getImgSrc, getThumbUrl } from '@/utils/chat/index';
import { ChatMessageType, ImageChatMessage, VideoChatMessage } from '@/types/chat';
import { videoDurationFromat } from '@/utils/date';
import historyVideoIcon from '@/assets/images/history-video.png';

interface ImageItemProps {
    message: ImageChatMessage | VideoChatMessage;
}

export default function ImageItem(props: ImageItemProps) {
    const { message } = props;
    const { propItems, isVideo, duration } = useMemo(() => {
        let imageSource = message.url;
        let imageWidth = 80;
        let imageHeight = 80;
        let isVideo = false;
        let duration = 0;
        const propItems: Record<string, string> = {};
        if (message.type === ChatMessageType.IMAGE) {
            imageWidth = (message as ImageChatMessage).width || 80;
            imageHeight = (message as ImageChatMessage).height || 80;
        } else if (message.type === ChatMessageType.VIDEO) {
            isVideo = true;
            imageSource = (message as VideoChatMessage).thumbnail;
            imageWidth = (message as VideoChatMessage).thumbWidth || 80;
            imageHeight = (message as VideoChatMessage).thumbHeight || 80;
            duration = (message as VideoChatMessage).duration || 0;
        }
        imageSource = getImgSrc(imageSource);
        // 小于100 * 100的图 直接显示原图
        if (message.type === ChatMessageType.IMAGE) {
            if (imageWidth && imageWidth < 200 && imageHeight && imageHeight < 200) {
                imageSource = getThumbUrl(imageSource, 100, 100);
            } else {
                imageSource = getThumbUrl(imageSource, imageWidth, imageHeight);
            }
        }
        //
        propItems.backgroundImage = `url(${imageSource})`;
        if (imageWidth > imageHeight) {
            propItems.backgroundSize = 'auto 100%';
        } else {
            propItems.backgroundSize = '100% auto';
        }
        // propItems.padding = '12px';
        return { imageSource, imageWidth, imageHeight, propItems, isVideo, duration };
    }, [message]);
    return (
        <div className="history-media-item" style={{ ...propItems }}>
            {isVideo && (
                <div className="video-info">
                    <img src={historyVideoIcon} />
                    <span className="duration">{videoDurationFromat(duration)}</span>
                </div>
            )}
        </div>
    );
}
