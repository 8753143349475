import BaseController from '@/attributes/BaseController';
import PageModel from '../datamodel/PageModel';
// import NewService from '../service/NewService';
import { DefaultPermisions, PageType } from '../const';
// import PageService from '../service/PageService';
// import ListService from '../service/ListService';
import { exPng, woPng } from '@/assets/images';
import InitDBService from './InitJoySpaceController';

class NewListController extends BaseController {
    listDBService: any;
    newDBService: any;
    pageService: any;
    initDb() {
        this.listDBService = InitDBService.GetListService();
        this.newDBService = InitDBService.GetNewService();
        this.pageService = InitDBService.GetPageService();
    }
    // eslint-disable-next-line max-params
    async createInfile(
        propertyKey: string,
        folderId: string,
        folderName: string,
        pageType: PageType
    ) {
        const userInfo = await this.newDBService.getUserInfo();
        let iconUrl = '';
        if (pageType === PageType.Page) {
            iconUrl = woPng;
        } else if (pageType === PageType.Sheet) {
            iconUrl = exPng;
        }
        const pageModel = new PageModel({
            space_category_id: folderId,
            space_id: folderId,
            icon_url: iconUrl,
            author: {
                id: userInfo.userId,
                name: userInfo.teamUserInfo.realName,
                username: userInfo.teamUserInfo.realName,
                avatar_url: userInfo.avatar_url || '',
            },
            pageType,
        });
        this.newDBService.createPageInFile(propertyKey, pageModel.id, folderId);
        this.pageService.createPageInFile(propertyKey, pageModel);
        this.pageService.setParentFolder(
            {
                id: folderId,
                name: folderName || '我的文件',
                type: 'folder',
            },
            pageModel.id
        );
        // console.log(
        //     {
        //         id: folderId,
        //         name: !folderName || folderName === 'myspace' || '我的文件',
        //         type: 'folder',
        //     },
        //     pageModel.id,
        //     'asdadasdadadadadsadadada'
        // );
        return pageModel;
    }
    async createPageInFile(propertyKey: string, ...args: any[]) {
        const [params, folderName] = [...args];
        const { folderId } = params;
        const res = await this.createInfile(propertyKey, folderId, folderName, PageType.Page);
        return res;
    }
    async createSheetInFile(propertyKey: string, ...args: any[]) {
        const [params, folderName] = [...args];
        const { folderId } = params;
        const res = await this.createInfile(propertyKey, folderId, folderName, PageType.Sheet);
        return res;
    }
}

export default new NewListController();
