import File from '@tanker/file-ponyfill';
import matchAll from 'string.prototype.matchall';

try {
    const nonRegexStr = /[ac]/g;
    ''.matchAll(nonRegexStr);
} catch (e) {
    // eslint-disable-next-line
    String.prototype.matchAll = function (arg: RegExp) {
        return matchAll(this as string, arg);
    };
}

window.File = File;
