/*
    具体规则：
  今年：不显示年，如“6月18日14:00–16:00”，跨天：6月18日14:00–6月19日16:00
  跨年：显示年，如“2022年6月18日16:00-2023年8月8日16:00”
  今天：日期的位置加今天显示，，如“6月18日（今天）14:00–16:00”，跨天：6月18日14:00–6月19日16:00
  明天：日期的位置加明天显示，，如“6月18日（明天）14:00–16:00”，跨天：同上
  本周：日期的位置加星期几显示，，如“6月18日（星期三）14:00–16:00”（本周是指每周的周一到周日），跨天：同上
*/

import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

const weekMap = ['日', '一', '二', '三', '四', '五', '六'];

export function showTime(begin: Dayjs, end: Dayjs, t: Function): String {
    function isCrossDay(remark?: string) {
        if (dayjs(begin).isSame(end, 'date')) {
            // 开始时间和结束时间为同一天
            return (
                begin.format('MM月DD日 HH:mm') +
                (remark ? remark : '') +
                ' – ' +
                end.format('HH:mm')
            );
        } else {
            // 跨天日程
            return (
                begin.format('MM月DD日 HH:mm') +
                (remark ? remark : '') +
                ' – ' +
                end.format('MM月DD日 HH:mm')
            );
        }
    }
    const currentDate = dayjs(new Date());
    const tomorrowDate = currentDate.add(1, 'day');
    const isSameDay = dayjs(begin).isSame(currentDate, 'date');
    // const isSameWeek = dayjs(begin).isSame(currentDate, 'week');
    const isSameWeek = moment(begin.toString()).isSame(moment(currentDate.toString()), 'week');
    const isSameYear = dayjs(begin).isSame(end, 'year');
    const isTomorrow = dayjs(begin).isSame(tomorrowDate, 'date');
    if (isSameYear) {
        if (isSameDay) {
            return isCrossDay('（今天）');
        } else if (isTomorrow) {
            return isCrossDay('（明天）');
        } else if (isSameWeek) {
            const week = begin.day();
            return isCrossDay(`（星期${weekMap[week]}）`);
        } else {
            return isCrossDay();
        }
    } else {
        return begin.format('YYYY年MM月DD日 HH:mm') + '-' + end.format('YYYY年MM月DD日 HH:mm');
    }
}
