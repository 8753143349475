import React from 'react';
import './index.less';
import ImService from '@/server/ImService';

function AINoticeCard({ message }: { message: any }) {
    const imService = ImService.getInstance();
    const card = imService.aiCardChainexecute(message);
    return card;
}

export default AINoticeCard;
