import { gatewayInstance } from '../server/gateway';
import { request } from '@/components/Projects/api';
import { WorkStatusItem } from '@/components/Modals/WorkStatusModal';
import { FocusSDK } from '@/utils';
import { PageExternalContactsApprInfoReqParam } from '@/types/chat/State';
import {
    DelLabelInfo,
    getEbookDeptInfoList,
    LabelForContactInfo,
    LabelInfoRes,
    mapEbookSimpleDetailList,
    NewLabelModel,
    updateLabelInfo,
} from '@/components/AddressBook/common';
import { userDetailListPrams } from '@/types/UserCard';
import { ebookUnitDeptInfo } from '@/models/ebook';
import ImService from '@/server/ImService';

export function getIsGovAddressBook() {
    return FocusSDK.getApplicationInfo().isGovEbook;
}

export function addressBookApiConfig() {
    const isGovAddressBook = getIsGovAddressBook();
    if (isGovAddressBook) {
        return {
            ebookSimpleVirtualOrgInfo: 'ebook.gov.getEbookSimpleVirtualOrgInfo',
            simpleDeptInfo: 'ebook.gov.getEbookGovFatherDeptInfo',
            ebookOrgInfo: 'ebook.gov.getEbookOrgInfo',
            ebookDeptInfo: 'ebook.gov.getEbookDeptInfo',
            ebookUserDetail: 'ebook.gov.userebookdetail',
            virtualUserDetail: 'ebook.gov.getVirtualUserEbookDetail',
        };
    } else {
        return {
            ebookSimpleVirtualOrgInfo: 'ebook.getEbookSimpleVirtualOrgInfo',
            simpleDeptInfo: 'ebook.getEbookDeptInfo',
            ebookOrgInfo: '',
            ebookDeptInfo: 'ebook.getEbookDeptInfo',
            ebookUserDetail: 'ebook.userebookdetail',
            virtualUserDetail: 'ebook.userebookdetail',
        };
    }
}

/**
 * 虚拟部门数据
 * @returns
 * @param body
 */
export async function getEbookSimpleVirtualOrgInfo(body: {
    appId?: string;
    virtualOrgId?: string;
}) {
    return gatewayInstance.request({
        api: addressBookApiConfig().ebookSimpleVirtualOrgInfo,
        method: 'POST',
        // @ts-ignore
        body,
    });
}

export function getUserEbookDetailList(
    users: {
        appId: string;
        userId: string;
        teamId: string;
    }[]
) {
    try {
        const result = gatewayInstance.request({
            api: 'ebook.gov.getUserEbookDetailList',
            method: 'POST',
            // @ts-ignore
            body: { ebookUserDetailReqList: users },
        });
        return result;
    } catch (error) {
        return null;
    }
}

export function getUserEbookDetail(user: {
    appId: string;
    userId: string;
    teamId: string;
    isMulti: boolean;
}) {
    try {
        const result = gatewayInstance.request({
            api: addressBookApiConfig().ebookUserDetail,
            method: 'POST',
            // @ts-ignore
            body: { ...user },
        });
        return result;
    } catch (error) {
        return null;
    }
}

// 获取常用联系人成员列表
export function getGroupMemberList() {
    try {
        const result = gatewayInstance.request({
            api: 'screen.queryGroupMemberList',
            method: 'POST',
            // @ts-ignore
            body: {},
        });
        return result;
    } catch (error) {
        return null;
    }
}

/**
 * 批量添加人员或设备到组内
 * @param body
 * @returns
 */
export async function batchAddGroupMember(body: {
    groupId: string;
    memberList: {
        memberId: string;
        memberType: 1 | 2;
    }[];
}) {
    try {
        const result = await gatewayInstance.request({
            api: 'screen.batchAddGroupMember',
            method: 'POST',
            // @ts-ignore
            body,
        });
        return result;
    } catch (error) {
        return null;
    }
}

/**
 * 创建组
 * @param body
 * @returns
 */
export async function addGroup(body: {
    groupName: string;
    memberList: {
        memberId: string;
        memberType: 1 | 2;
    }[];
}) {
    try {
        const result = await gatewayInstance.request({
            api: 'screen.addGroup',
            method: 'POST',
            // @ts-ignore
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 修改组名
 * @param body
 * @returns
 */
export async function editGroupName(body: { groupName: string; groupId: string }) {
    try {
        const result = await gatewayInstance.request({
            api: 'screen.changeGroupName',
            method: 'POST',
            // @ts-ignore
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 删除组
 * @param body
 * @returns
 */
export function removeGroup(body: { groupId: string }) {
    try {
        const result = gatewayInstance.request({
            api: 'screen.removeGroup',
            method: 'POST',
            // @ts-ignore
            body,
        });
        return result;
    } catch (error) {
        return null;
    }
}

/**
 * 取消组内成员
 * @param body
 * @returns
 */
export function removeGroupMember(body: { groupId: string; memberId: string; memberType: 1 | 2 }) {
    try {
        const result = gatewayInstance.request({
            api: 'screen.removeGroupMember',
            method: 'POST',
            // @ts-ignore
            body,
        });
        return result;
    } catch (error) {
        return null;
    }
}

/**
 * 获取冬奥部门截取配置信息
 * @returns
 */
export function getDepartmentConfig() {
    try {
        const result = gatewayInstance.request({
            api: 'screen.getDepartmentConfig',
            method: 'POST',
            // @ts-ignore
            body: {},
        });
        return result;
    } catch (error) {
        return null;
    }
}

/**
 * 获取冬奥通知配置信息
 * @returns
 */
export function getNotifyConfig() {
    try {
        const result = gatewayInstance.request({
            api: 'screen.getNotifyConfig',
            method: 'POST',
            // @ts-ignore
            body: {},
        });
        return result;
    } catch (error) {
        return null;
    }
}

/**
 *
 * 部门数据(左侧导航)
 * @returns
 * @param body
 */
export async function getSimpleDeptInfo(body: { deptId?: string; displaySubDept?: boolean }) {
    try {
        const result = await gatewayInstance.request({
            api: addressBookApiConfig().simpleDeptInfo,
            method: 'POST',
            // @ts-ignore
            body,
        });
        return result;
    } catch (error) {
        return null;
    }
}
// 获取单位和部门的平布列表数据
export async function getEbookUnitDeptAndInnerDeptInfo() {
    try {
        const result: any = await gatewayInstance.request({
            api: 'ebook.gov.getEbookUnitDeptAndInnerDeptInfo',
            method: 'POST',
            // @ts-ignore
            // body,
        });
        return [result, null];
    } catch (error) {
        return [null, error?.message.toString()];
    }
}
/**
 *
 * 用户数据（总体）
 * @returns
 * @param body
 */
export async function getEbookOrgInfo(body: { orgId?: string; displaySubDept?: boolean }) {
    return gatewayInstance.request({
        api: addressBookApiConfig().ebookOrgInfo,
        method: 'POST',
        // @ts-ignore
        body,
    });
}

/**
 *
 * 用户数据（总体）
 * @returns
 * @param body
 */
export async function getEbookDeptInfo(body: { deptId?: string; displaySubDept?: boolean }) {
    try {
        const imService = ImService.getInstance();
        await imService.refreshDeptVersion();
        if (!body.deptId) {
            const currentEmployee = await imService.getCurrentEmployee();
            body.deptId = currentEmployee?.deptId;
        }
        const result = await getEbookDeptInfoList(body);
        return result || {};
    } catch (error) {
        return {};
    }

    // return gatewayInstance.request({
    //     api: addressBookApiConfig().ebookDeptInfo,
    //     method: 'POST',
    //     // @ts-ignore
    //     body,
    // });
}

export async function getVirtualUserDetail(params: {
    userId: string;
    teamId: string;
    appId: string;
    rootOrgId?: string;
}) {
    return gatewayInstance.request({
        api: addressBookApiConfig().virtualUserDetail,
        method: 'POST',
        body: params,
    });
}

export async function getEBookUserDetail(params: {
    userId: string;
    teamId: string;
    appId: string;
    isMulti?: boolean;
}) {
    return gatewayInstance.request({
        api: addressBookApiConfig().ebookUserDetail,
        method: 'POST',
        body: { isMulti: true, ...params },
    });
}

export async function saveEUserSetting(params: { settingCode: string; settingContent: string }) {
    return gatewayInstance.request({
        api: 'euser.saveUserSetting',
        method: 'POST',
        body: params,
    });
}

export async function getEbookDeptDesc(deptId = '', teamId: string) {
    return gatewayInstance
        .request({
            api: 'ebook.gov.getDeptDesc',
            method: 'POST',
            body: { deptId, teamId },
        })
        .catch((err) => {
            console.log('getEbookDeptDesc-err', err);
        });
}

// 获取用户的所有项目
export function getMyProjects(body: { searchText: string } = { searchText: '' }) {
    return request({
        api: 'work.project.findMyProjects',
        body,
    });
}

// 获取用户状态
export async function getPageExternalContactsApprInfoReq(
    body: PageExternalContactsApprInfoReqParam
): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'focus.pageExternalContactsApprInfo',
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 获取用户状态
export async function getCodeInfo(body: { codeType: 'workStatus' | 'autoReply' }): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'euser.getCodeInfo',
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 保存用户状态
export async function saveUserSetting(body: {
    settingCode: 'workStatus' | 'autoReply';
    bizChildType: {
        workStatus?: WorkStatusItem;
        autoReply?: any;
    };
}): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'euser.saveUserSetting',
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 获取用户设置
export async function getUserSetting(body: {
    settingCode: 'workStatus' | 'autoReply';
}): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'euser.getUserSetting',
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getIsAddFriends(param: { friendUserId: string }): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.isAddFriends',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function getAllRelationShip(param: {
    oppoUserId: string;
    oppoTeamId: string;
}): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.getAllRelationShip',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

// 删除好友
export async function removeEFriend(param: {
    pin: string;
    app: string;
    teamId: string;
}): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'efriend.removeFriend',
            method: 'POST',
            body: param,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * @description 获取标签列表
 */
export async function getLabelsList(): Promise<LabelInfoRes[]> {
    return gatewayInstance.request({
        api: 'ebook.gov.getLabelInfos',
        method: 'POST',
    }) as any;
}

/**
 * @description 创建标签接口
 */
export async function createLabel(label: NewLabelModel): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.gov.createLabelAndEmpls',
            method: 'POST',
            body: label,
        });
        return [data, null];
    } catch (error) {
        return [null, error?.message.toString()];
    }
}

/**
 * @description 编辑标签
 */
export async function updateLabel(label: updateLabelInfo): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.gov.updateLabel',
            method: 'POST',
            body: label,
        });
        return [data, null];
    } catch (error) {
        return [null, error?.message.toString()];
    }
}

/**
 * @description 编辑标签
 */
export async function setLabelForContact(label: LabelForContactInfo): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.gov.setLabelForContact',
            method: 'POST',
            body: label,
        });
        return [data, null];
    } catch (error) {
        return [null, error?.message.toString()];
    }
}

/**
 * @description 标签人员详情
 */
export async function getEbookSimpleDetailList(users: userDetailListPrams[]): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.gov.getEbookSimpleDetailList',
            method: 'POST',
            body: { ebookUserDetailReqList: users },
        });
        if (!data) {
            return null;
        }
        return mapEbookSimpleDetailList(data);
    } catch (error) {
        return error?.message.toString();
    }
}

/**
 * @description 删除标签
 */
export async function delLabel(label: DelLabelInfo): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.gov.delLabel',
            method: 'POST',
            body: label,
        });
        return [data, null];
    } catch (error) {
        return [null, error?.message.toString()];
    }
}

/**
 * @description 常用联系人可见规则
 */
export async function getFirstLoginList(): Promise<any> {
    try {
        const data = await gatewayInstance.request({
            api: 'ebook.gov.getFirstLoginList',
            method: 'POST',
        });
        return [data, null];
    } catch (error) {
        return [null, error?.message];
    }
}

// 系统管理员列表
export async function getMyAdministratorsList(body: { deptId: string }): Promise<any> {
    try {
        const result = await gatewayInstance.request({
            api: 'authz.getMyAdministrators',
            method: 'POST',
            body,
        });
        return [result, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
