/* eslint-disable no-return-assign */
/**
 * 聊天会话左侧功能合并组件
 * @author sunzhiguang
 * @date 2020/6/16
 */

import React, { useCallback, useEffect, useState, useRef } from 'react';
import MsgNotification from '@/components/chat/MsgNotification';
import SessionTop from '@/components/chat/SessionTop';
import ChatState, { UserState } from '@/types/chat/State';
import GlobalSearch from '@/components/GlobalSearch';
import AddPopover from '@/components/GlobalSearch/AddPopover';
import { SessionProps } from '@/types/chat';
import { connect } from 'dva';
import './index.less';
import SessionList, { sessionItem } from '@/components/chat/SessionList';
import bus from '@/utils/bus';
import Loading from '@/components/Loading';
import MigrateTip from '@/components/ErrorHolder/MigrateTip';
import ScrollView from '@/baseComponents/ScrollView';
import { throttle } from 'lodash';
interface dvaProps {
    reSortAllSessionList: Function;
    getAllChatSessions: Function;
    updatePageLoadState: Function;
}

type IChatLeftProps = Readonly<ChatState & UserState & dvaProps>;

function ChatLeft(this: any, props: IChatLeftProps) {
    const [showSessionTopAll, setShowSessionTopAll] = useState(false);
    const PAGE_SIZE = 20;
    const pageSize = useRef(PAGE_SIZE);
    const {
        sessionStatusMap,
        allSessionList,
        reSortAllSessionList,
        getAllChatSessions,
        loadAllSessionListEnd,
        sessionLoadEnd,
        sessionPage,
    } = props;
    const [withBtnPopover, setWithBtnPopover] = useState<boolean>(true);
    // console.log('leftSide', allSessionList);
    useEffect(() => {
        // console.log('allSessionList', allSessionList);
        // 这个变更需要重新排序 allSessionList
        const list = allSessionList.map((item) => {
            const data = sessionStatusMap[item.sessionId];
            if (data && data.timestamp > item.timestamp && data.info.content) {
                return Object.assign(item, { timestamp: data.timestamp });
            }
            return item;
        });
        reSortAllSessionList({ allSessionList: list });
    }, [sessionStatusMap]); // eslint-disable-line

    useEffect(() => {
        bus.on('hideGlobalSearchModel', () => {
            setWithBtnPopover(true);
        });
        bus.on('showGlobalSearchModel', () => {
            setWithBtnPopover(false);
        });
        return () => {
            bus.off('showGlobalSearchModel');
            bus.off('hideGlobalSearchModel');
        };
    }, []);
    const scrollRef = useRef(null);

    const closeAllFollow = useCallback(() => {
        setShowSessionTopAll(false);
    }, []);

    // 屏幕大小调整加载条数
    const handleResize = useCallback(() => {
        const curr: any = scrollRef.current;
        if (!curr) {
            return;
        }
        const count = Math.ceil(curr.getClientHeight() / sessionItem.height);
        pageSize.current = Math.max(count, PAGE_SIZE);
    }, []);
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onScroll = async () => {
        const curr: any = scrollRef.current;
        if (!curr) {
            return;
        }
        const { scrollHeight, scrollTop, clientHeight } = curr.getValues();
        // if (!sessionLoadEnd && scrollTop !== 0 && clientHeight + scrollTop > scrollHeight - 180) {
        if (!sessionLoadEnd && scrollTop !== 0 && scrollTop > (scrollHeight - clientHeight) / 2) {
            getAllChatSessions({
                options: {
                    offset: sessionPage * pageSize.current,
                    count: pageSize.current,
                },
            });
        }
    };

    return (
        <div className="chat-left-container">
            {/* 消息类型切换部分 */}
            {/* <Search withBtnPopover={true} /> */}
            <div className="chat-left-header">
                <GlobalSearch />
                {withBtnPopover && (
                    <div style={{ marginLeft: '10px' }}>
                        <AddPopover />
                    </div>
                )}
            </div>
            <MigrateTip />
            {/* 头部消息类型 */}
            <MsgNotification
                showSessionTopAll={showSessionTopAll}
                isAllFollow={showSessionTopAll}
                closeAllFollow={closeAllFollow}
            />
            {loadAllSessionListEnd ? (
                <ScrollView
                    className="chat-left-scroll reply-popover scroll-view"
                    id="chat-left-scroll"
                    ref={scrollRef}
                    onScroll={throttle(onScroll, 300)}
                >
                    <div className="chat-left-mask" id="chat-left-mask" />
                    {/* 置顶 */}
                    {/* <SessionTop
                            allSessionList={allSessionList}
                            onShowAll={onSessionTopShowAll}
                            isAllFollow={showSessionTopAll}
                        /> */}
                    <SessionList
                        allSessionList={allSessionList}
                        showSessionTopAll={showSessionTopAll}
                    />
                </ScrollView>
            ) : (
                <div className="chat-left-scroll reply-popover scroll-view" id="chat-left-scroll">
                    <Loading />
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const {
        connectState,
        sessionStatusMap,
        allSessionList,
        loadAllSessionListEnd,
        sessionLoadEnd,
        sessionPage,
    } = chat as ChatState;
    return {
        connectState,
        sessionStatusMap,
        allSessionList,
        loadAllSessionListEnd,
        sessionLoadEnd,
        sessionPage,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async reSortAllSessionList(data: { allSessionList: SessionProps[] }) {
            await dispatch({ type: 'chat/reSortAllSessionList', payload: data });
        },
        getAllChatSessions(data: any) {
            dispatch({ type: 'chat/getAllChatSessions', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatLeft);
