import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { Modal, Input, message, Button, Tabs, Row, Col, Drawer } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { connect } from 'dvajs';
import {
    EsendType,
    User,
    EsuperviseSourceType,
    SuperviseUserInfo,
    ICreateSuperviseData,
    IScheduleUser,
    ISchedule,
} from '@/types/supervise';
import bus from '@/utils/bus';
import IconFont from '@/components/icon';
import SuperviseUserList from '../UserList';
import UserStatusListPopover from '../UserStatusListPopover';
import { createSupervise } from '@/api/supervise';
import { getPersonalTaskDetail, queryExecutorsByTaskId } from '@/api/work';
import i18n from '@/i18n';
import './index.less';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { useAppId } from '@/components/Supervise/envirment';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
const { TextArea } = Input;

import AttachTaskOrSchedule from './components/AttachTaskOrSchedule';
import { TaskModel } from '@/types/work';
import { difference, rest, uniqBy } from 'lodash';
import { resolveModuleNameFromCache } from 'typescript';
import { Session } from '@/types/chat';
import cloneDeep from 'lodash/cloneDeep';
import CopyFrame from '@/components/CopyFrame';
import Tab from '../Tab';

export enum CalendarsColor {
    'FFF23030' = 4,
    'FFFFB416' = 3,
    'FF5EA7F8' = 2,
    'FF8ADE30' = 1,
}
interface IAttachedTaskOrSchedule {
    name: string;
    userList: Array<SuperviseUserInfo>;
}

const SendTypeItem = ({
    active,
    label,
    clickHandle,
    iconName,
}: {
    active: boolean;
    label: string;
    clickHandle: Function;
    iconName?: string;
}) => {
    const appId = useAppId();
    return (
        <div
            onClick={() => clickHandle()}
            className={`send-type-item ${active ? 'send-type-item-active' : ''}`}
        >
            <IconFont
                type={iconName ? iconName : 'iconzw_app_note_remind'}
                style={{ display: 'inline-block', textAlign: 'left', fontSize: 20 }}
            />
            <p style={{ lineHeight: 1, marginTop: 16 }}>{label}</p>
        </div>
    );
};
// eslint-disable-next-line complexity
function SuperviseCreate({
    createModalVisible,
    dispatch,
    createData,
    userData,
    selectedSession,
}: {
    createModalVisible: boolean;
    dispatch: Function;
    createData: ICreateSuperviseData;
    userData: any;
    selectedSession?: Session;
}) {
    const [sendType, setSendType] = useState<EsendType>(EsendType.APP);
    const [content, setContent] = useState('');
    const [remarks, setRemarks] = useState('');
    const [remarkFouse, setRemarkFouse] = useState(false);
    const [contentFouse, setContentFouse] = useState(false);
    const [receiveList, setReceiveList] = useState<User[]>([]);
    const [receiveListCopy, setReceiveListCopy] = useState<User[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isChangeData, setIsChangeData] = useState(false);
    const { authInfo } = useContext<GlobalContextConfig>(GlobalContext);
    const appId = useAppId();

    // 是否选择了任务或者日程
    const [isSelectTaskOrSchedule, changeSelectTaskOrSchedule] = useState(false);

    // 新建提醒支持选择任务和日程
    const [attachedTaskOrSchedule, changeAttach] = useState<IAttachedTaskOrSchedule | null>(null);

    const [activeTabKey, changeTabKey] = useState('task');
    const initRef = useRef('');
    useEffect(() => {
        if (createModalVisible) {
            initRef.current = 'createModalVisible';
            setSendType(createData.sendType || EsendType.APP);
            setContent(
                createData.content
                    ? createData.content.length > 500
                        ? createData.content.substr(0, 500)
                        : createData.content
                    : ''
            );
            // 备注 wzp
            const _remarks: any =
                createData.remarks && createData.remarks.length > 500
                    ? createData.remarks.substr(0, 500)
                    : createData.remarks;
            setRemarks(_remarks || '');
            if (selectedSession && selectedSession?.isGroup === false && !isChangeData) {
                const sessionInfo: any = selectedSession?.info;
                const member = {
                    userId: sessionInfo.userId,
                    teamId: sessionInfo.teamId,
                    appId: sessionInfo.app,
                    realName: sessionInfo.name,
                    headImg: sessionInfo.avatar,
                };
                setReceiveList(
                    uniqBy(
                        [
                            ...(createData.userList || []),
                            member,
                            ...(attachedTaskOrSchedule?.userList || []),
                        ],
                        'userId'
                    )
                );
            } else {
                setReceiveList(
                    uniqBy(
                        [
                            ...(createData.userList || []),
                            ...(attachedTaskOrSchedule?.userList || []),
                        ],
                        'userId'
                    )
                );
            }
        } else {
            setSendType(EsendType.APP);
            setContent('');
            setRemarks('');
            setReceiveList([]);
            changeSelectTaskOrSchedule(false);
            changeAttach(null);
            changeTabKey('task');
            setIsChangeData(false);
        }
    }, [createModalVisible, selectedSession, isChangeData, createData, attachedTaskOrSchedule]);

    const addMemberHandle = (v: SuperviseUserInfo[]) => {
        let newList: User[] = [];
        if (!receiveList.length) {
            setReceiveList([...v]);
            updateCreateData({ userList: [...v] });
            return;
        }
        if (v?.length) {
            v.forEach((i) => {
                let idx = receiveList.findIndex(
                    (user) =>
                        user.appId === i.appId &&
                        user.teamId === i.teamId &&
                        user.userId === i.userId
                );
                if (idx < 0) {
                    newList.push(i);
                }
            });
        }
        setReceiveList([...receiveList, ...newList]);
        updateCreateData({ userList: [...receiveList, ...newList] });
    };
    function onRemoveReceive(i: User) {
        let idx = receiveList.findIndex(
            (user) => user.appId === i.appId && user.teamId === i.teamId && user.userId === i.userId
        );
        if (idx !== -1) {
            const list = [...receiveList];
            list.splice(idx, 1);
            const tmp: any = { ...attachedTaskOrSchedule };
            setTimeout(() => {
                setReceiveList(list);
                tmp.userList = list;
                // changeAttach(tmp);
            });
            dispatch({
                type: 'supervise/removeReceiveUser',
                payload: list,
            });
            setIsChangeData(true);
        }
    }
    // eslint-disable-next-line complexity
    function create() {
        if (!receiveList.length || !content || !content.trim().length) {
            message.info(i18n.t('supervise:requireMessage'));
            return;
        }
        if (isFetching) {
            return;
        }
        let req: any;

        // jdme://jm/page_project_supervise_detail?mparam=%7B%22superviseId%22%3A%22%22%7D
        // 如果来自日程的话，在sourceParam中添加deeplink字段const deeplink = `jdme://jm/page_project_supervise_detail?`
        // 来自任务的督办不做修改，还是通过id和title
        // 有projectId
        // console.log(createData, 'createDatacreateDatacreateDatacreateData');
        if (createData?.from === EsuperviseSourceType.WORK && createData?.projectId) {
            req = {
                sourceType: EsuperviseSourceType.WORK,
                sourceId: createData.id || `${Date.now()}`,
                sourceParam: createData.id
                    ? JSON.stringify({
                          id: createData.id,
                          title: createData.title,
                          type: createData.type,
                      })
                    : '',
                sendType: sendType,
                sourceBizId: createData.projectId,
                sourceBizType: 'PROJECT',
                receiverList: receiveList.map((item) => {
                    return {
                        userId: item.userId,
                        teamId: item.teamId,
                        appId: item.appId,
                    };
                }),
                content: content,
                remarkContent: remarks,
            };
        } else if (createData?.from === EsuperviseSourceType.SCHEDULE && createData?.shceduleInfo) {
            const shceduleInfo = createData.shceduleInfo;
            const deeplink = DeeplinkEvent.strify({
                appId: authInfo.ddAppId,
                path: DeeplinkPathEnum.Schedule_Draw,
                mparam: {
                    scheduleId: shceduleInfo.scheduleId,
                    beginTime: shceduleInfo.beginTime,
                    endTime: shceduleInfo.endTime,
                    isNeedCheck: true,
                    calendarId: shceduleInfo.calendarId,
                },
            });
            req = {
                sourceType: EsuperviseSourceType.SCHEDULE,
                sourceId: createData.id || `${Date.now()}`,
                sourceParam: JSON.stringify({
                    id: shceduleInfo.scheduleId,
                    title: createData.title,
                    deeplink,
                    type: createData.type,
                }),
                sendType: sendType,

                receiverList: receiveList.map((item) => {
                    return {
                        userId: item.userId,
                        teamId: item.teamId,
                        appId: item.appId,
                    };
                }),
                content: content,
                remarkContent: remarks,
            };
            if (createData.projectId && createData.sourceBizType) {
                req.sourceBizId = createData.projectId;
                req.sourceBizType = createData.sourceBizType;
            }
        } else if (createData?.from === EsuperviseSourceType.IM && selectedSession) {
            // TODO: 这里请求参数sourceParam需要优化
            req = {
                sourceType: EsuperviseSourceType.IM,
                sourceId: selectedSession?.sessionId,
                sourceParam: JSON.stringify({
                    title: selectedSession?.info.name,
                    arguments: {
                        sendId: userData.userId,
                        sessionType: selectedSession?.sessionType,
                        sessionId: selectedSession?.sessionId,
                        sessionTitle: userData.realName,
                        app: userData.teamUserInfo.ddAppId,
                        enableVideoCall: true,
                    },
                }),
                sendType: sendType,
                receiverList: receiveList.map((item) => {
                    return {
                        userId: item.userId,
                        teamId: item.teamId,
                        appId: item.appId,
                    };
                }),
                content: content,
                remarkContent: remarks,
            };
        } else if (createData?.from === EsuperviseSourceType.NOTIFICATION) {
            req = {
                sourceType: EsuperviseSourceType.NOTIFICATION,
                sourceId: createData.id || `${Date.now()}`,
                sourceParam: createData.id
                    ? JSON.stringify({ id: createData.id, title: createData.title })
                    : '',
                sendType: sendType,
                receiverList: receiveList.map((item) => {
                    return {
                        userId: item.userId,
                        teamId: item.teamId,
                        appId: item.appId,
                    };
                }),
                content: content,
                remarkContent: remarks,
            };
        } else if (createData?.from === EsuperviseSourceType.SOLITAIRE) {
            req = {
                sourceType: EsuperviseSourceType.SOLITAIRE,
                sourceId: createData.id || `${Date.now()}`,
                sourceParam: createData.id
                    ? JSON.stringify({ id: createData.id, title: createData.title })
                    : '',
                sendType: sendType,
                receiverList: receiveList.map((item) => {
                    return {
                        userId: item.userId,
                        teamId: item.teamId,
                        appId: item.appId,
                    };
                }),
                content: content,
                remarkContent: remarks,
            };
        } else {
            const _sourceParam = createData.id
                ? { id: createData.id, title: createData.title, type: createData.type }
                : { id: createData.id, title: createData.title };
            req = {
                sourceType: createData.id ? EsuperviseSourceType.WORK : EsuperviseSourceType.NONE,
                sourceId: createData.id || `${Date.now()}`,
                sourceParam: createData.id ? JSON.stringify(_sourceParam) : '',
                sendType: sendType,
                receiverList: receiveList.map((item) => {
                    return {
                        userId: item.userId,
                        teamId: item.teamId,
                        appId: item.appId,
                    };
                }),
                content: content,
                remarkContent: remarks,
            };
        }

        setIsFetching(true);
        createSupervise(req)
            .then((res) => {
                const [superviseId, errMsg] = res;
                // console.log(res, '============');
                if (superviseId) {
                    message.success(i18n.t('supervise:createSuccess'));
                    bus.emit('supervise/createSupervise', superviseId);
                } else {
                    message.error(errMsg ? errMsg : i18n.t('supervise:createFailed'));
                }
                onClose();
            })
            .finally(() => {
                setIsFetching(false);
            });
    }
    function onClose() {
        dispatch({
            type: 'supervise/setCreateModalVisible',
            payload: { visible: false },
        });
        dispatch({
            type: 'supervise/setCreateData',
            payload: {},
        });
    }
    if (!createModalVisible) {
        return null;
    }

    function updateCreateData(payload: Partial<ICreateSuperviseData>) {
        dispatch({
            type: 'supervise/setCreateData',
            payload: { ...createData, ...payload },
        });
    }

    const selectTaskorScheduleTitle = (
        <Tabs
            className="create-supervise-modal-tabs"
            activeKey={activeTabKey}
            onChange={(key) => {
                changeTabKey(key);
            }}
        >
            <Tabs.TabPane tab="选择任务" key="task" />
            <Tabs.TabPane tab="选择日程" key="schedule" />
        </Tabs>
    );

    // 选择任务
    async function changeSelectedTaskItem(taskItem: TaskModel) {
        changeSelectTaskOrSchedule(false);
        changeTabKey('task');
        let executors = [];
        if (Array.isArray(taskItem.executors)) {
            executors = taskItem.executors;
        } else {
            const [res, err] = await queryExecutorsByTaskId(taskItem.taskId);
            if (!res || err) {
                console.error(err);
                message.error(err);
                return;
            }
            executors = res;
        }
        let userList = executors.map((user: any) => {
            return {
                userId: user.userId,
                teamId: user.teamId,
                appId: user.app,
                realName: user.realName,
                headImg: user.headImg,
            };
        });
        if (taskItem.owners && taskItem.owners.length) {
            const owners = taskItem.owners.map((user) => {
                return {
                    userId: user.userId,
                    teamId: user.teamId,
                    appId: user.app,
                    realName: user.realName,
                    headImg: user.headImg,
                };
            });
            userList = userList.concat(owners);
        }
        userList = userList.concat(receiveList);
        console.log(userList, 'userListuserListuserListuserList');
        // 更新createData
        updateCreateData({
            id: taskItem.taskId,
            from: EsuperviseSourceType.WORK,
            title: taskItem.title,
            projectId: taskItem.projectId,
            userList,
            type: taskItem.priorityType,
        });
        changeAttach({
            name: taskItem.title,
            userList,
        });
        setReceiveListCopy(cloneDeep(receiveList));
        setReceiveList(userList);
    }

    // 选择日程
    function changeSelectedScheduleItem(checkedScheduleItem: ISchedule) {
        changeSelectTaskOrSchedule(false);
        changeTabKey('schedule');

        // 组织者和参与者放到一起
        const userList: any[] = [
            checkedScheduleItem.user,
            ...(checkedScheduleItem.participantDTOs || []).map((item) => {
                return item.user;
            }),
            ...receiveList,
        ];

        // 去重
        const uniqUserList = uniqBy(userList, function (item) {
            return item.userId;
        }).map((user) => {
            return {
                userId: user.userId,
                teamId: user.teamId,
                appId: user.app || user.ddAppId || appId || userData.ddAppId,
                realName: user.realName,
                headImg: user.headImg || user.imageUrl,
            };
        });
        // console.log(
        //     checkedScheduleItem,
        //     'checkedScheduleItemcheckedScheduleItemcheckedScheduleItemcheckedScheduleItem'
        // );
        // 更新createData
        updateCreateData({
            from: EsuperviseSourceType.SCHEDULE,
            title: checkedScheduleItem.title,
            userList: uniqUserList,
            shceduleInfo: {
                scheduleId: checkedScheduleItem.scheduleId,
                beginTime: checkedScheduleItem.beginTime,
                endTime: checkedScheduleItem.endTime,
                calendarId: checkedScheduleItem.calendarId,
            },
            type: CalendarsColor[(checkedScheduleItem as any).color],
        });
        changeAttach({
            name: checkedScheduleItem.title,
            userList: uniqUserList,
        });
        setReceiveListCopy(cloneDeep(receiveList));
        setReceiveList(uniqUserList);
    }

    function handleCancel(e: any) {
        e.stopPropagation();
        e.preventDefault();
        changeAttach(null);

        // const differenceUserList = difference(
        //     createData.userList,
        //     attachedTaskOrSchedule?.userList || [],
        //     'userId' as any
        // );

        updateCreateData({
            // userList: differenceUserList,
            userList: cloneDeep(receiveListCopy),
            title: '',
            id: '',
            projectId: '',
            from: undefined,
            shceduleInfo: undefined,
        });
        setReceiveListCopy([]);
        // setReceiveList([]);
    }

    function FooterBtn() {
        const fromTask =
            (attachedTaskOrSchedule && activeTabKey === 'task') ||
            createData?.from === EsuperviseSourceType.WORK;
        return (
            !isSelectTaskOrSchedule && (
                <>
                    <Button
                        className="supervise-create-btn"
                        size="middle"
                        clstag={
                            fromTask
                                ? 'pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_urge_cancel'
                                : undefined
                        }
                        onClick={onClose}
                    >
                        {i18n.t('supervise:cancel')}
                    </Button>
                    <Button
                        className="ml8 supervise-create-btn"
                        size="middle"
                        clstag={
                            fromTask
                                ? 'pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_urge_sure'
                                : undefined
                        }
                        type="primary"
                        onClick={create}
                    >
                        {i18n.t('supervise:confirm')}
                    </Button>
                </>
            )
        );
    }

    return (
        <Drawer
            className={`create-supervise-modal ${
                !receiveList.length || !content?.trim().length ? 'disable' : ''
            } ${isSelectTaskOrSchedule ? 'hide-border-bottom' : ''}`}
            visible={createModalVisible}
            width={450}
            title={
                isSelectTaskOrSchedule
                    ? selectTaskorScheduleTitle
                    : i18n.t(`supervise:createSupervise${appId}`)
            }
            destroyOnClose={true}
            onClose={() => onClose()}
            placement="right"
            footer={FooterBtn()}
            // zIndex={1100}
        >
            {isSelectTaskOrSchedule ? (
                <AttachTaskOrSchedule
                    activeTab={activeTabKey}
                    changeSelectedTaskItem={changeSelectedTaskItem}
                    changeSelectedScheduleItem={changeSelectedScheduleItem}
                />
            ) : (
                <div style={{ padding: 12 }}>
                    {selectedSession && (
                        <div className="session-source">来自会话：{selectedSession?.info.name}</div>
                    )}
                    {attachedTaskOrSchedule || createData?.id || createData?.shceduleInfo ? (
                        <div className="supervise-task-schedule">
                            <div
                                className="supervise-task-schedule-header"
                                onClick={() => {
                                    changeSelectTaskOrSchedule(true);
                                }}
                            >
                                {`来自${
                                    (attachedTaskOrSchedule && activeTabKey === 'task') ||
                                    createData?.from === EsuperviseSourceType.WORK
                                        ? '任务'
                                        : createData?.from === EsuperviseSourceType.SCHEDULE
                                        ? '日程'
                                        : createData?.from === EsuperviseSourceType.NOTIFICATION
                                        ? '组通知'
                                        : '组接龙'
                                }：`}
                                {createData.type && (
                                    <Tab
                                        from={(createData as any).sourceType}
                                        type={createData.type}
                                    />
                                )}
                                <div className="supervise-task-schedule-title">{`${
                                    attachedTaskOrSchedule?.name || createData?.title
                                }`}</div>
                                {/* <IconFont
                                    style={{
                                        fontSize: 11,
                                        marginLeft: 10,
                                        color: '#0F6EE4',
                                    }}
                                    type="iconwin_right"
                                /> */}
                                <div
                                    className="supervise-task-schedule-cancel"
                                    clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_taskdetail_urge_fromthetask_cancel"
                                    onClick={(e) => handleCancel(e)}
                                >
                                    <IconFont className="icon" type="iconic_pc_im_close" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        !selectedSession && (
                            <div>
                                <Button
                                    className="primary primary-border create-supervise-btn"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        changeSelectTaskOrSchedule(true);
                                    }}
                                >
                                    添加任务或日程
                                </Button>
                            </div>
                        )
                    )}
                    <div style={{ display: 'flex', marginTop: 12 }}>
                        <SendTypeItem
                            clickHandle={() => {
                                setSendType(EsendType.APP);
                                updateCreateData({ sendType: EsendType.APP });
                            }}
                            iconName="iconzw_app_application_remind"
                            active={sendType === EsendType.APP}
                            label={i18n.t('supervise:inApp')}
                        />
                        <SendTypeItem
                            clickHandle={() => {
                                setSendType(EsendType.SMS);
                                updateCreateData({ sendType: EsendType.SMS });
                            }}
                            active={sendType === EsendType.SMS}
                            label={i18n.t('supervise:sms')}
                        />
                    </div>
                    <div className="task-userlist">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 22,
                                marginBottom: 10,
                            }}
                        >
                            <span style={{ fontSize: 14, color: '#232930' }}>
                                <span className="required">*</span>
                                {i18n.t('supervise:selectReceive')}
                            </span>
                            <UserStatusListPopover
                                unfinishList={[]}
                                finishList={receiveList}
                                onRemove={onRemoveReceive}
                                disable={false}
                            >
                                <span
                                    style={{
                                        fontSize: 14,
                                        color: '#8F959E',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {receiveList.length}
                                    {i18n.t('supervise:people')}{' '}
                                    <IconFont
                                        style={{
                                            fontSize: 10,
                                            verticalAlign: 'middle',
                                            marginLeft: 8,
                                            color: '#8F959E',
                                        }}
                                        type="iconwin_right"
                                    />
                                </span>
                            </UserStatusListPopover>
                        </div>
                        <SuperviseUserList
                            disable={false}
                            isGroup={selectedSession?.isGroup}
                            sessionId={selectedSession?.sessionId}
                            list={receiveList}
                            handleAddTaskMembers={addMemberHandle}
                            maxLength={9}
                            onRemove={onRemoveReceive}
                            addMemberDisable={false}
                        />
                    </div>
                    <div className="content-form-label">
                        <span className="required">*</span>
                        {i18n.t('supervise:description')}
                    </div>
                    <div className={contentFouse ? 'supervise-content focus' : 'supervise-content'}>
                        <CopyFrame>
                            <TextArea
                                rows={4}
                                value={content}
                                placeholder={i18n.t('supervise:pleaseInput')}
                                maxLength={500}
                                onChange={(e) => {
                                    setContent(e.target.value);
                                    updateCreateData({ content: e.target.value });
                                }}
                                onFocus={() => setContentFouse(true)}
                                onBlur={() => setContentFouse(false)}
                            />
                        </CopyFrame>
                        <p className="conter">
                            <span className="primary">{content.length}</span>/500
                        </p>
                    </div>
                    {/* TODO:加备注 wzp */}
                    <div className="content-form-label">
                        {i18n.t('supervise:my-note')}{' '}
                        <span style={{ fontSize: 14, color: '#BFC1C4' }}>（仅您自己可见）</span>
                    </div>
                    <div className={remarkFouse ? 'supervise-content focus' : 'supervise-content'}>
                        <CopyFrame>
                            <TextArea
                                rows={4}
                                value={remarks}
                                placeholder={i18n.t('supervise:add-my-note')}
                                maxLength={500}
                                onChange={(e) => {
                                    setRemarks(e.target.value);
                                    updateCreateData({ remarks: e.target.value });
                                }}
                                onFocus={() => setRemarkFouse(true)}
                                onBlur={() => setRemarkFouse(false)}
                            />
                        </CopyFrame>
                        <div className="conter">
                            <p>
                                <span className="primary">{remarks?.length}</span>/500
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    );
}

export default connect(({ supervise, user }: any) => ({
    selectedSession: supervise.selectedSession,
    createData: supervise.createData,
    createModalVisible: supervise.createModalVisible,
    userData: user.userData.user,
}))(SuperviseCreate);
