import {
    getJoySpaceDelPermission,
    getJoySpaceReadAllPermission,
    getJoySpaceReadOnlyPermission,
    getJoySpaceReadWritePermission,
    joyspacePermissionType,
} from '../../config/permissions.config';

const joySpaceReadOnlyPermissionItem = {
    title: '仅阅读',
    tip: '可预览文档',
    permissions: getJoySpaceReadOnlyPermission(),
};
const joySpaceReadWritePermissionItem = {
    title: '可编辑',
    tip: '可预览/编辑文档',
    permissions: getJoySpaceReadWritePermission(),
};
const joySpaceReadOnlyPermissionAllItem = {
    title: '全部仅阅读',
    tip: '全部可预览文档',
    permissions: getJoySpaceReadOnlyPermission(),
};
const joySpaceReadWritePermissionAllItem = {
    title: '全部可编辑',
    tip: '全部可预览/编辑文档',
    permissions: getJoySpaceReadWritePermission(),
};
// const joySpaceReadDelPermissionAllItem = {
//     title: '全部移除',
//     tip: '全部取消选择',
//     permissions: getJoySpaceDelPermission(),
// };

const joySpaceReadOnlyPermissionFolderItem = {
    title: '仅阅读',
    tip: '可查看文档和文件夹',
    permissions: getJoySpaceReadOnlyPermission(),
};
const joySpaceReadWritePermissionFolderItem = {
    title: '可编辑',
    tip: '可查看/编辑文档和文件夹',
    permissions: getJoySpaceReadWritePermission(),
};
const joySpaceReadAllPermissionItem = {
    title: '所有者',
    tip: '可查看/编辑/管理文档和文件夹',
    permissions: getJoySpaceReadAllPermission(),
};
const joySpaceReadOnlyPermissionFolderAllItem = {
    title: '全部仅阅读',
    tip: '全部可查看文档和文件夹',
    permissions: getJoySpaceReadOnlyPermission(),
};
const joySpaceReadWritePermissionFolderAllItem = {
    title: '全部可编辑',
    tip: '全部可查看/编辑文档和文件夹',
    permissions: getJoySpaceReadWritePermission(),
};

// const joySpaceReadDelPermissionFolderAllItem = {
//     title: '全部移除',
//     tip: '全部取消选择',
//     permissions: getJoySpaceDelPermission(),
// };

const joySpaceReadAllPermissionAllItem = {
    title: '全部所有者',
    tip: '全部可查看/编辑/管理文档和文件夹',
    permissions: getJoySpaceReadAllPermission(),
};

export const getFolderMenus = () => {
    return [
        joySpaceReadOnlyPermissionFolderItem,
        joySpaceReadWritePermissionFolderItem,
        joySpaceReadAllPermissionItem,
    ];
};
export const getFolderAllMenus = () => {
    return [
        joySpaceReadOnlyPermissionFolderAllItem,
        joySpaceReadWritePermissionFolderAllItem,
        joySpaceReadAllPermissionAllItem,
        // joySpaceReadDelPermissionFolderAllItem,
    ];
};
export const getPageMenus = () => {
    return [joySpaceReadOnlyPermissionItem, joySpaceReadWritePermissionItem];
};
export const getPageMenusAll = () => {
    return [
        joySpaceReadOnlyPermissionAllItem,
        joySpaceReadWritePermissionAllItem,
        // joySpaceReadDelPermissionAllItem,
    ];
};

// 获取分页
export const getPageSize = () => {
    return { pageIndex: 1, pageSize: 999 };
};

export interface SelectType {
    id: string;
    avatar: string;
    name: string;
    desc: string;
    type: string;
    checked?: boolean;
    permissionType: joyspacePermissionType;
}
