import React from 'react';
import './index.less';
import { Checkbox } from 'antd';

function MyCheckBox({
    checked,
    disabled,
    onChange,
    className,
    fillColor,
}: {
    checked?: boolean;
    disabled?: boolean;
    onChange?: (e: any) => void;
    fillColor?: boolean;
    className?: string;
}) {
    return (
        <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            className={`mycheckbox ${className || ''}
             ${!fillColor ? 'no-fill-color' : ''}`}
        />
    );
}

export default MyCheckBox;
