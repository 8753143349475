import React, { useState } from 'react';
import { Tooltip, Popover } from 'antd';
import { IconThirdApp, IconMore } from '@/components/icon';
import Rete from '@/components/ThirdApp/components/Rate';
import {
    FavoriteType,
    RecommendedType,
    RecommendedTypeTitle,
    StatusLabels,
    StatusLabelsStyles,
    StatusLabelsText,
} from '@/services/AppStore';
import Bus from '@/utils/bus';
import thirdAppAnalyticsMap from '@/utils/thirdAppAnalyticsMap';
import { appItemMenus, MenuType } from '../ThirdMenu';
import CornerMark from '../CornerMark';
import { changeEgovUrl } from '@/utils/tools';
import { analysisLog } from '@/utils/logAnalytics';

export default function AppItem({ appInfo }: { appInfo: any }) {
    // 介绍&来源
    const ToolText = (desc: string, source: string, csTelephone: string) => {
        return (
            <div>
                {desc && <p>简介：{desc}</p>}
                {source && <p>开发者：{source}</p>}
                {csTelephone && (
                    <p>联系电话：{csTelephone ? csTelephone.split('\n').join('、') : ''}</p>
                )}
            </div>
        );
    };
    // 添加常用
    const AddFavorite = ({ favoriteType }: { favoriteType: number }) => {
        return (
            <span
                className="third-popover"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {IconMore}
                <div className="third-popover-menu">
                    <span
                        className="third-favorite-text"
                        style={{
                            color: '#323233',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            analysisLog(
                                'xtbg_workbench_1',
                                '5',
                                appInfo.applicationId,
                                appInfo.name
                            );
                            appItemMenus({ appInfo, menuType: MenuType.AboutModal });
                            // if (favoriteType === FavoriteType.FavoriteNo) {
                            //     Bus.emit('onAddFavoriteThirdApp', appInfo);
                            // } else {
                            //     Bus.emit('removeFavoriteThirdApp', appInfo);
                            // }
                        }}
                    >
                        关于应用
                    </span>
                    {/* {appInfo.recommended === RecommendedType.RecommendedNo && ( */}
                    <span
                        className="third-favorite-text"
                        style={{
                            color: favoriteType === FavoriteType.FavoriteNo ? '#323233' : '#323233',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            appItemMenus({
                                appInfo,
                                favoriteType,
                                menuType: MenuType.AddThirdApp,
                            });
                        }}
                    >
                        {favoriteType === FavoriteType.FavoriteNo ? '添加常用' : '取消常用'}
                    </span>
                    {/* )} */}
                </div>
            </span>
        );
    };
    const getCornerMarkOptions = () => {
        const arr = [];
        if ((appInfo.applicationStatusLabels || []).includes(StatusLabels.UNDER_MAINTENANCE)) {
            arr.push({
                text: StatusLabelsText[StatusLabels.UNDER_MAINTENANCE],
                styles: StatusLabelsStyles[StatusLabels.UNDER_MAINTENANCE],
            });
        }
        if (appInfo.recommended === RecommendedType.RecommendedYes) {
            arr.push({
                text: RecommendedTypeTitle[RecommendedType.RecommendedYes],
                styles: StatusLabelsStyles.default,
            });
        }
        if ((appInfo.applicationStatusLabels || []).includes(StatusLabels.BETA)) {
            arr.push({
                text: StatusLabelsText[StatusLabels.BETA],
                styles: StatusLabelsStyles[StatusLabels.BETA],
            });
        }
        return arr;
    };
    const cornerMarkOptions: any = getCornerMarkOptions();
    const lables = []
        .concat(appInfo.networkEnvLabels || [])
        .concat(appInfo.applicationStatusLabels || []);
    return (
        <Tooltip
            title={ToolText(appInfo.brief || appInfo.name, appInfo.developer, appInfo.csTelephone)}
            key={appInfo.applicationId}
            // @ts-ignore
            color="#494949"
            placement="bottom"
        >
            <span
                className={
                    appInfo.deploymentEnvironment === 2
                        ? 'third-content-item third-content-item_lightblue_bg'
                        : 'third-content-item third-content-item_lightred_bg'
                }
                clstag={thirdAppAnalyticsMap[appInfo.applicationKey]?.click}
                onClick={() => {
                    Bus.emit('openThirdApp', appInfo);
                }}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
            >
                {/* 工具图标 */}
                <div className="third-content-item_logo">
                    {appInfo.icon ? (
                        <img src={changeEgovUrl(appInfo.icon)} alt="" />
                    ) : (
                        <span style={{ fontSize: 22 }}>{IconThirdApp}</span>
                    )}
                </div>
                {/* 工具名称及简述 */}
                <div className="third-content-item_info">
                    <span className="third-content-item_text">{appInfo.name}</span>
                    {appInfo.builder ? (
                        <span className="third-content-item_developer">{appInfo.builder}</span>
                    ) : (
                        <span style={{ height: '15px' }} />
                    )}
                    <span className="third-content-item_brief">{appInfo.brief}</span>
                    <span
                        className="third-content-item_developer"
                        style={{
                            borderBottom: '1px solid #E8EBEC',
                            paddingBottom: '5px',
                            marginBottom: '4px',
                            marginTop: '5px',
                        }}
                    >
                        开发者：{appInfo.developer}
                    </span>
                    <span className="third-content-item_lable">
                        {lables.map((key: any, index: any) => {
                            const text = StatusLabelsText[key];
                            if (
                                cornerMarkOptions.find((item: any) => item.text === text) ||
                                text === StatusLabelsText[StatusLabels.VPN]
                            ) {
                                return '';
                            }
                            return <span key={'net' + index}>{text}</span>;
                        })}
                    </span>
                </div>
                {/* 推荐 */}
                {!!cornerMarkOptions.length && (
                    <div className="thirdapp-item-recommended">
                        <CornerMark
                            text={cornerMarkOptions[0].text}
                            styles={cornerMarkOptions[0].styles}
                        />
                    </div>
                )}
                {/* 添加常用 */}
                {/* {appInfo.recommended === RecommendedType.RecommendedNo && ( */}
                <div className="thirdapp-item-favorite">
                    <AddFavorite favoriteType={appInfo.favorite} />
                </div>
                {/* )} */}
            </span>
        </Tooltip>
    );
}
