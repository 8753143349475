import React, { useEffect, useState, useMemo } from 'react';
import { Button, Dropdown, Menu, Popover, Divider, message } from 'antd';
import './index.less';
import { FocusSDK } from '@/utils';
import {
    TsdkAttendee,
    TsdkConfParticipantStatus,
    TsdkConfRole,
    TsdkConfStatusInfo,
    DeviceType,
} from '../types';
import WinToolbar from '@/layout/WinToolbar';
import { Empty } from '@jd/focus-desktop-comps';
import Loading from '@/components/Loading';
import { getDevicesInfo } from '../utils';
import { LoadingOutlined } from '@ant-design/icons';

const devicesList = [
    {
        desc: '摄像头',
        value: DeviceType.camera,
    },
    {
        desc: '扬声器',
        value: DeviceType.speaker,
    },
    {
        desc: '麦克风',
        value: DeviceType.mic,
    },
];

export default function MeetingControl() {
    const [attendeeList, setAttendeeList] = useState<TsdkAttendee[]>([]);
    const [presenter, setPresenter] = useState(false);
    const [loadingDevice, setLoadingDevice] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [deviceType, setDeviceType] = useState(DeviceType.mic);
    const [activedDevice, setActivedDevice] = useState(null);
    let platform = FocusSDK.getDeviceInfo().platform;
    useEffect(() => {
        function setData({ confStatus }: { confStatus: TsdkConfStatusInfo; handle: number }) {
            if (confStatus.hasChairman) {
                confStatus.attendeeList.forEach(({ statusInfo, baseInfo }: any) => {
                    if (statusInfo.isSelf && baseInfo.role === TsdkConfRole.Presenter) {
                        setPresenter(true);
                    }
                });
            } else {
                setPresenter(false);
            }
            setAttendeeList(confStatus.attendeeList);
        }

        function handleperationMessage(data: any) {
            console.info(data);
        }
        FocusSDK.on('cloudlink:meetingControl', setData);
        FocusSDK.on('operationMessage', handleperationMessage);

        return () => {
            FocusSDK.off('cloudlink:meetingControl', setData);
            FocusSDK.off('operationMessage', handleperationMessage);
        };
    }, []);

    useEffect(() => {
        FocusSDK.sendIpcMessage('cloudlink.initMeetingControl');
    }, []);

    function changeAttendeeMute(account: number, status: number) {
        FocusSDK.sendIpcMessage('cloudlink.meetingControl', {
            type: 'muteAttendee',
            data: {
                account,
                status,
            },
        });
    }

    function muteConference() {
        FocusSDK.sendIpcMessage('cloudlink.meetingControl', {
            type: 'muteConference',
        });
    }

    async function handleGetDevicesInfo(type: any) {
        try {
            setLoadingDevice(true);
            const data: any = await getDevicesInfo(type);
            setLoadingDevice(false);
            const [list, selected] = data;
            setDeviceList(list.deviceInfo);
            setDeviceType(list.deviceType);
            setActivedDevice(selected.index);
            return data;
        } catch (error) {
            console.error(error);
            setLoadingDevice(false);
        }
    }

    useEffect(() => {
        function handle(param: any) {
            if (param.status) {
                handleGetDevicesInfo(deviceType).then((res: any) => {
                    const [list, selected] = res;
                    const selectedDevice = list.deviceInfo.find(
                        (item: any) => item.index === selected.index
                    );
                    if (selectedDevice) {
                        let localInfo: any = {
                            [DeviceType.mic]: '',
                            [DeviceType.camera]: '',
                            [DeviceType.speaker]: '',
                        };
                        try {
                            const localStr = localStorage.getItem('cloudlink.deviceSelected');
                            if (localStr) {
                                localInfo = JSON.parse(localStr);
                            }
                        } catch (error) {
                            console.error(error);
                        }

                        localInfo[deviceType] = selectedDevice.deviceName;
                        localStorage.setItem(`cloudlink.deviceSelected`, JSON.stringify(localInfo));
                    }
                });
            } else {
                message.error('切换失败');
            }
        }
        FocusSDK.on('cloudlink:setDevicesInfoCb', handle);

        return () => {
            FocusSDK.off('cloudlink:setDevicesInfoCb', handle);
        };
    }, [deviceType]);

    function handleMenuClick(account: number, e: any) {
        if (e.key === 'remove') {
            FocusSDK.sendIpcMessage('cloudlink.meetingControl', {
                type: 'removeAttendee',
                data: {
                    account,
                },
            });
        }
    }
    const userControlMenu = function (account: number) {
        return (
            <Menu onClick={handleMenuClick.bind(null, account)}>
                <Menu.Item key="remove">移除与会者</Menu.Item>
            </Menu>
        );
    };

    function getAttendeeDom(attendee: TsdkAttendee) {
        try {
            const { baseInfo, statusInfo } = attendee;
            const { displayName, role, number, userId } = baseInfo;
            const { isSelf, isMute, state } = statusInfo;
            return (
                <div key={number} className="attendee-item">
                    <span>
                        {displayName}
                        {isSelf ? '，我' : ''}
                        {role === TsdkConfRole.Presenter ? '，主持人' : ''}
                    </span>

                    {state === TsdkConfParticipantStatus.IN_MEETING ? (
                        presenter ? (
                            !isSelf ? (
                                <span>
                                    <Dropdown.Button
                                        size="small"
                                        onClick={changeAttendeeMute.bind(
                                            null,
                                            userId,
                                            isMute ? 0 : 1
                                        )}
                                        overlay={userControlMenu(userId)}
                                    >
                                        {isMute ? '解除静音' : '静音'}
                                    </Dropdown.Button>
                                </span>
                            ) : null
                        ) : (
                            ''
                        )
                    ) : (
                        '未加入'
                    )}
                </div>
            );
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const deviceContent = useMemo(() => {
        if (loadingDevice || deviceList.length === 0) {
            return <Loading />;
        }
        function changeDevice(device: any) {
            FocusSDK.sendIpcMessage('cloudlink.setDevicesInfo', { deviceType, device });
        }
        return deviceList.map((device: any, idx) => {
            return (
                <div
                    onClick={() => {
                        changeDevice(device);
                    }}
                    title={device.deviceName}
                    key={idx}
                    className={`device-item ${device.index === activedDevice ? 'active' : ''}`}
                >
                    {device.deviceName}
                </div>
            );
        });
    }, [activedDevice, deviceList, deviceType, loadingDevice]);

    return (
        <div className="meeting-control-browser">
            {platform === 'win32' && (
                <div className="win-tool-bar draggable">
                    <div className="title">参会人列表</div>
                    <WinToolbar />
                </div>
            )}
            <div className="meeting-control-content">
                <div className="attendee-list">
                    {attendeeList.length === 0 && (
                        <Empty image={<LoadingOutlined />} description="" />
                    )}
                    {attendeeList.map((item: TsdkAttendee) => {
                        return getAttendeeDom(item);
                    })}
                </div>
                <div className="operations">
                    {/* 主持人权限 */}
                    {presenter ? (
                        <>
                            <Button size="small" onClick={muteConference}>
                                全体静音
                            </Button>
                            <Divider type="vertical" />
                        </>
                    ) : (
                        <span style={{ display: 'inline-block', padding: '0 30px' }} />
                    )}
                    {devicesList.map((device, idx) => {
                        return (
                            <Popover
                                key={idx}
                                placement="top"
                                transitionName=""
                                overlayClassName="cloudlink-devices-popover"
                                title={device.desc}
                                content={deviceContent}
                                trigger="click"
                            >
                                <Button
                                    size="small"
                                    onClick={() => {
                                        handleGetDevicesInfo(device.value);
                                    }}
                                >
                                    {device.desc}
                                </Button>
                            </Popover>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
