import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useReducer,
    Dispatch,
    SetStateAction,
    useRef,
} from 'react';
import { Button, Modal, message } from 'antd';
import IconFont from '@/components/icon';
import { ProjectRole, announceListType } from '../../types';
import Context from '../../context';
import { time } from '../../util';
import { getThumbUrl } from '@/utils/chat/index';
import noticeImg from '@/assets/img/no-content.png';
import { getAnnounceListService, deleteAnnounceService } from '../../api';
import createAnnounce from './createAnnounce';
import OpenAnnounceDetail from './AnnounceDetail';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import bus from '@/utils/bus';
import OpenAnnounceInfo from './announceInfo';
import { Loading } from '@jd/focus-desktop-comps';
import { Avatar } from '@/baseComponents/Avatar';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
interface IProps {
    project: {
        projectId: string;
        userRole: ProjectRole;
        archive: boolean;
        setAnnouncement: Dispatch<SetStateAction<string>>;
    };
}
interface stateType {
    list: announceListType[];
    hasShowAllListData: boolean;
    offset: number;
}

function listReducer(state: stateType, action: { payload: any; type: string }): stateType {
    switch (action.type) {
        case 'add':
            return {
                offset: action.payload.offset,
                hasShowAllListData: action.payload.hasShowAllListData,
                list: state.list.concat(action.payload.list),
            };
        case 'firstFetchList':
            return {
                offset: action.payload.offset,
                hasShowAllListData: action.payload.hasShowAllListData,
                list: [].concat(action.payload.list),
            };
        default:
            return state;
    }
}

export default function (props: IProps) {
    const { userRole, projectId, archive, setAnnouncement } = props.project;
    const { t } = useContext(Context);

    const globalcontext = useContext<GlobalContextConfig>(GlobalContext);
    const { events3: events } = globalcontext;

    const [loading, setLoading] = useState(true);
    const [datas, listDispatch] = useReducer(listReducer, {
        list: [],
        hasShowAllListData: false,
        offset: 0,
    });
    const [isUpdateList, setIsUpdateList] = useState(false);
    const loadMoreRef = useRef<any>(null);
    const { list, hasShowAllListData, offset } = datas;
    const previousListLengthRef = useRef(0);

    async function removeAnnounce(id: string) {
        const res = await deleteAnnounceService(projectId, id);
        if (res.errCode) return;
        events.emit('announceList:update', { projectId, announcementId: id });
        message.success(t('operate_success'));
    }

    useEffect(() => {
        setAnnouncement(list[0]?.content);
    }, [list, setAnnouncement]);

    useEffect(() => {
        function handleAnnounceUpdate(data: { projectId: string; announcementId: string }) {
            if (data.projectId !== projectId) return;
            setIsUpdateList((oldvalue) => !oldvalue);
        }
        events.on('announceList:update', handleAnnounceUpdate);
        return () => {
            events.off('announceList:update', handleAnnounceUpdate);
        };
    }, [setIsUpdateList, events, projectId]);

    const getAnnounceList = useCallback(
        async (start?: number) => {
            setLoading(true);
            const res = await getAnnounceListService(projectId, start ?? offset);
            setLoading(false);
            if (res.errCode) return;
            listDispatch({
                type: start === 0 ? 'firstFetchList' : 'add',
                payload: {
                    offset: (start ?? offset) + 10,
                    hasShowAllListData: res.announcementLists?.length < 10,
                    list: res.announcementLists || [],
                },
            });
            return res.announcementLists || [];
        },
        [projectId, offset]
    );

    useEffect(() => {
        getAnnounceList(0);
        // eslint-disable-next-line
    }, [isUpdateList]);

    useEffect(() => {
        function openAnnounceModal(data: any) {
            if (data?.infox?.announcementTypeId !== projectId) return;
            OpenAnnounceInfo({
                content: data?.content || '',
                pictures:
                    typeof data.infox.pic === 'string'
                        ? JSON.parse(data.infox.pic) || []
                        : data.infox.pic,
                projectId: data?.infox?.announcementTypeId,
                announceId: data?.infox?.announcementId,
                t,
            });
            setIsUpdateList((oldvalue) => !oldvalue);
        }
        bus.on('announce:openModal', openAnnounceModal);
        return () => {
            bus.off('announce:openModal', openAnnounceModal);
        };
    }, [projectId, t]);

    function editAnnounce(item: { content: string; announcementId: string }) {
        createAnnounce({
            projectId,
            globalcontext,
            pageType: 'edit',
            announceDetail: item,
            t,
        });
    }

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        // 加载更多可见的时候获取数据
        if (
            !hasShowAllListData &&
            entries[0].isIntersecting &&
            previousListLengthRef.current !== list.length
        ) {
            previousListLengthRef.current = list.length;
            getAnnounceList();
        }
    });

    useEffect(() => {
        const loadMore = loadMoreRef.current as HTMLDivElement;
        loadMore && observer.observe(loadMore);
        return () => {
            observer.disconnect();
        };
    }, [observer]);

    function showAnnounceList() {
        if (list.length === 0) {
            return (
                <div className="pm-noAnnounce">
                    <img src={changeEgovUrl(noticeImg)} alt="" />
                    <p>{t('detail.announce.nodata')}</p>
                    {!archive && [ProjectRole.ADMIN, ProjectRole.OWNER].includes(userRole) && (
                        <Button
                            className="pm-add-announce"
                            type="primary"
                            disabled={archive}
                            onClick={() => createAnnounce({ projectId, globalcontext, t })}
                        >
                            <IconFont type="iconapp_btn_add2" className="pm-add-icon" />
                            {t('detail.announce.create')}
                        </Button>
                    )}
                </div>
            );
        }
        return (
            <>
                {!archive && [ProjectRole.ADMIN, ProjectRole.OWNER].includes(userRole) && (
                    <Button
                        className="pm-add-announce"
                        type="primary"
                        disabled={archive}
                        onClick={() => createAnnounce({ projectId, globalcontext, t })}
                    >
                        <IconFont type="iconapp_btn_add2" className="pm-add-icon" />
                        {t('detail.announce.create')}
                    </Button>
                )}
                <ul>
                    {list.map((item: announceListType) => (
                        <li
                            key={item.announcementId}
                            onClick={() =>
                                OpenAnnounceDetail({
                                    projectId,
                                    announcementId: item.announcementId,
                                    userRole,
                                    archive,
                                    t,
                                    removeAnnounce,
                                    editAnnounce,
                                    events,
                                })
                            }
                        >
                            <div>
                                <Avatar
                                    src={changeEgovUrl(getThumbUrl(item.senderUserAvatar, 26, 26))}
                                    styleObj={{
                                        width: '26px',
                                        height: '26px',
                                    }}
                                    name={item.senderUserName}
                                />
                                {/* {item.senderUserAvatar ? (
                                    <img src={changeEgovUrl(getThumbUrl(item.senderUserAvatar, 26, 26))} alt="" />
                                ) : (
                                    <i>{item.senderUserName?.[0]}</i>
                                )} */}

                                <span>
                                    {item.senderUserName}更新于 {time.format(item.pubTime)}
                                </span>
                                <span>
                                    {!archive &&
                                        [ProjectRole.ADMIN, ProjectRole.OWNER].includes(
                                            userRole
                                        ) && (
                                            <>
                                                <span
                                                    className="pm-announce-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        editAnnounce(item);
                                                    }}
                                                >
                                                    <IconFont
                                                        type="iconjs_ic_edit"
                                                        className="pm-announce-small-icon"
                                                    />
                                                </span>
                                                <span
                                                    className="pm-announce-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        Modal.confirm({
                                                            title: t('detail.announce.remove_tip'),
                                                            content: t(
                                                                'detail.announce.remove_confim'
                                                            ),
                                                            okText: t('remove'),
                                                            cancelText: t('cancel'),
                                                            onOk() {
                                                                removeAnnounce(item.announcementId);
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <IconFont
                                                        type="icondelect"
                                                        className="pm-announce-small-icon"
                                                    />
                                                </span>
                                            </>
                                        )}
                                </span>

                                <span>
                                    {(item.userNum || 0) - (item.ackSize || 0)}/{item.userNum || 0}{' '}
                                    {t('detail.announce.no_read')}
                                </span>
                            </div>
                            <div>
                                <p>{item.content}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                {!hasShowAllListData ? (
                    <div className="pm-loadmore" ref={loadMoreRef}>
                        <Loading />
                    </div>
                ) : null}
            </>
        );
    }

    return <div className="pm-announce-tab-wrap">{showAnnounceList()}</div>;
}
