/**
 * 聊天用枚举类型
 * @author sunzhiguang
 * @date 2020/6/11
 */

export enum HeadShowType {
    TOP = 'top',
    SESSION_LIST = 'session_list',
    GROUP_MEMBER = 'group_member',
    SESSION = 'session',
    GROUP_SETTING = 'group_setting',
    SHARE_USER_CARD = 'share_user_card',
}

/**
 * 消息展示
 */
export enum MessageSendType {
    OWNER = 'owner', // 自己对别人发消息
    OTHER = 'other', // 对方发消息
    SELF = 'self', // 自己对自己发消息
}

export enum SysMessageReceiveType {
    OWNER = 'owner',
    OTHER = 'other',
    INVITEE = 'invitee',
    ADMIN = 'admin',
    TO = 'to',
    Applicant = 'applicant',
}

export enum JoyspaceDocumentType {
    DOCUMENT = 'document',
    WEEK_REPORT = 'week_report',
    TABLE = 'table',
}

export enum MessageDateFormType {
    DATE_SPLIT = 'date_split', // 消息日期分隔
    DATE_AGGREGATION = 'date_aggregation', // 消息聚合时间
}

// 会话消息时间分隔点
export enum MessageDateType {
    NEWEST = 'newest',
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    RANGE_2_7 = 'RANGE_2_7', // 2天前 7天内
    RANGE_7_0 = 'RANGE_7_0', // 7天前
    YEAR_BEFORE = 'year_before', // 本年前
}

export enum ClientType {
    PC = 'pc', // pc端
    IOS = 'ios', // ios 端
    ANDROID = 'android', // android 端
    COMET = 'comet', // web端
    H5 = 'm', // H5
    JIMI = 'jimi', // AI机器人
    IPAD = 'ipad', // ipad端
    MAC = 'mac', // mac 端
    MINI_PROGRAM = 'miniprogram', // 小程序
}

export enum MessageNotifyType {
    ALL = 'all',
    GROUPS = 'groups',
    UNREAD = 'unRead',
    IMPORTANT = 'important',
}

export enum MessageCategoryType {
    ALL = 'all',
    TIME = 'time',
    TYPE = 'type',
}

// 消息时间分类 : 今天更早
export enum MessageTimeCategory {
    TODAY = 'today',
    EARLIER = 'earlier',
    ALL = 'all',
}

// 消息类型分类 : 群聊 单聊 通知 免打扰
export enum MessageTypeCategory {
    GROUP = 'group',
    SINGLE = 'single',
    NOTICE = 'notice',
    NODISTURBING = 'noDisturbing',
}

export enum SessionSettingsType {
    TOP = 'top',
    SHIELD = 'shield',
    VISIBLE_UNIT = 'visible_unit', // 群组设置
    CHAT_VISIBLE_UNIT = 'chat_visible_unit', // 显示成员单位名称
    CAN_SEARCH = 'can_search',
    APPROVAL_RULE = 'approval_rule',
    CARD_VIEW = 'card_view',
    BANNED_POST = 'banned_post',
    PERMISSION_CHECK = 'permission_check',
    NEED_VERIFY = 'need_verify',
}

export enum TransferGroupAdminSource {
    GROUP_MEMBER_OPERATE = 'group_member_operate',
    EXIT_GROUP_OPERATE = 'exit_group_operate',
    GROUP_MANAGER_OPERATE = 'group_manager_operate',
}

export enum ApplyGroupState {
    INIT = 'init',
    AGREED = 'agreed',
    REJECTED = 'rejected',
    REJECT = 'reject',
    REAPPLY = 'reApply', // 再次申请pc端先隐藏操作入口
}
// 本地消息状态
export enum ApplyTeamState {
    INIT = 'init',
    AGREED = 'agreed',
    REJECTED = 'rejected',
}
export enum ApplyTeamStatus {
    INIT = '0',
    AGREED = '1',
    REJECTED = '2',
}
export enum ApplyAppStoreState {
    INIT = 'init',
    AGREED = 'agreed',
    REJECTED = 'rejected',
}

// 外部联系人申请通知
export enum ExternalContactsStatus {
    WAIT = 0, // 待审核
    SUCCESS = 1, // 审核成功
    FAIL = 2, // 审核失败
    INVALID = 3, // 失效
    ALREADY_APPR = 4, // (4,"当前用户申请已审核，请勿重复操作"),
    ALREADY_EXSIT = 5, // (5,"当前用户已存在，无需进行审核操作。"),
    DEPT_NOT_EXSIT = 6, // (6,"部门不存在"),
}
export enum ExternalContactsState {
    WAIT = 'WAIT', // 待审核
    SUCCESS = 'SUCCESS', // 审核成功
    FAIL = 'FAIL', // 审核失败
    INVALID = 'INVALID', // 失效
    ALREADY_APPR = 'ALREADY_APPR', // (4,"当前用户申请已审核，请勿重复操作"),
    ALREADY_EXSIT = 'ALREADY_APPR', // (5,"当前用户已存在，无需进行审核操作。"),
    DEPT_NOT_EXSIT = 'DEPT_NOT_EXSIT', // (6,"部门不存在"),
}

export enum DrawTypeEnum {
    setup = 'setup',
    search = 'search',
    shareJoyspace = 'shareJoyspace',
    other = 'other',
}

// 下载Map
// 1.云空间 2.消息 3.任务附件 4.日程附件
export enum DownloadTypeEnum {
    SPACE = 1,
    MESSAGE = 2,
    TASK = 3,
    CALENDARS = 4,
    // '1': '云空间',
    // '2': '消息',
    // '3': '任务附件',
    // '4': '日程附件',
    // 云空间: '1',
    // 消息: '2',
    // 任务附件: '3',
    // 日程附件: '4',
}
