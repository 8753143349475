/**
 * 日程分享卡片
 * @author mawenjie
 * @date 2021/7/13
 */
import React, { useEffect, useState, useContext, useRef } from 'react';
import style from './index.module.less';
import { JoySpaceDocumentMessage, MessageStatus, SessionType } from '@/types/chat';
import { MessageSendType } from '@/types/chat/enum';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import { getFileIconByMime, useThrottle } from '@/utils/chat/index';
import { lookup } from 'mime-types';
import { WithTranslation, withTranslation, useTranslation } from 'react-i18next';
import UnSupport from '@/baseComponents/Chat/message/UnSupport';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { getTypeImage } from '@/components/Calendars/utils/getTypeImage';
import { showTime } from '@/baseComponents/Chat/message/share/CalendarCard/showTime';
import dayjs from 'dayjs';
import { connect } from 'dvajs';
import GlobalContext from '@/context/GlobalContext';
import { IMeetingModal } from '@/components/Calendars/Schedule/Detail';
import BaseButton from '@/components/Calendars/Button';
import { joinMeetingOperation } from '@/components/Calendars/utils/operation';
import { sharelogoPng } from '@/assets/img/calendar';
import { CalendarIcon } from '@/components/Calendars/Common';
import { isFocusEnv } from '@/utils';
import { getScheduleInfo } from '@/api/calendar';
import { message as msg } from 'antd';
import { parsingMeetingInfo } from '@/components/Calendars/utils';
interface ShareJdocProps {
    message: JoySpaceDocumentMessage;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
    openJFile: () => void;
}

interface netStatusProps {
    newPublicResult: boolean;
}

type IShareJdocProps = Readonly<ShareJdocProps & WithTranslation & netStatusProps>;

function CalendarCard(props: IShareJdocProps) {
    const { sendType, message, onReady } = props;

    const { sharelink } = message.data[0];

    const [parseUrl, setParseUrl] = useState(DeeplinkEvent.parseDeeplink(sharelink.url));

    const { authInfo } = useContext(GlobalContext);
    const [meetingInfo, setMeetingInfo] = useState<IMeetingModal | null>(null);
    const scheduleId = useRef('');
    const { t } = useTranslation('calendar');

    useEffect(() => {
        // 解析会议信息参数
        let info = parseUrl.mparam?.content || '';
        scheduleId.current = parseUrl.mparam?.scheduleId;
        let minfo = parsingMeetingInfo(info);
        setMeetingInfo(minfo);
    }, [parseUrl.mparam]);

    const extJson = attempt(JSON.parse, sharelink?.ext || '{}');
    if (isError(extJson)) throw extJson;

    const getIcon = (): any => {
        if (sharelink.icon) {
            return sharelink.icon;
        }
        const mimeType = lookup(sharelink?.title || '') || '';
        const iconConfig = getFileIconByMime(mimeType);
        return iconConfig[iconConfig.length - 1];
    };
    const getScheduleContent = () => {
        let content = sharelink.content;
        if (!content) {
            return <div>{props.t('share-links')}</div>;
        } else {
            const des = parseUrl.mparam.content || '';
            const typeColor = parseUrl.mparam.color || '';
            let iconBg = <span />;
            if (typeColor) {
                const iconUrl = getTypeImage(typeColor);
                iconBg = (
                    <span
                        className={style['icon']}
                        style={{ backgroundImage: `url(${iconUrl})` }}
                    />
                );
            }
            const index = content.indexOf('开始时间');
            let title = '';
            let beginTime = '';
            let endTime = '';
            if (index === -1) {
                title = content;
            } else {
                title = content.slice(0, index);
                const timeArr = content.slice(index).split('\n');
                beginTime = timeArr[0].substring(5);
                endTime = timeArr[1].substring(5);
            }
            return (
                <>
                    <div className={style['share-schedule-content']}>
                        <p className={style['share-content-row-text-title']}>
                            {iconBg}
                            <span className={style['share-content-row-text-sub-title']}>
                                {title}
                            </span>
                        </p>
                    </div>
                    <p className="share-content-row-text-sub-time">
                        <span>{showTime(dayjs(beginTime), dayjs(endTime), props.t)}</span>
                    </p>
                </>
            );
        }
    };

    const handleJoinMeeting = useThrottle(() => {
        if (!scheduleId.current) {
            msg.warning(t('schedule is not found'));
            return;
        }
        // 先查询日程详情获取最新的会议信息
        getScheduleInfo({ scheduleId: scheduleId.current })
            .then((res: any) => {
                if (!res) {
                    msg.warning(t('schedule is not found'));
                    return;
                }
                let minfo = parsingMeetingInfo(res.description);
                if (minfo) {
                    joinMeetingOperation(authInfo, minfo, props.newPublicResult);
                } else {
                    msg.warning(t('failed to join the meeting'));
                    return;
                }
            })
            .catch((err) => {
                msg.error(err.message?.toString() || t('schedule is not found'));
            });
    }, 2000);
    useEffect(() => {
        if (sendType === MessageSendType.OWNER && message.status === MessageStatus.INIT) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);

    if (!sharelink) {
        return <UnSupport message={message} />;
    }

    return (
        <div className="content">
            <div className={style['j-file-share-card']}>
                <div className={style['share-title-row']} onClick={props.openJFile}>
                    {props.t('schedule')}
                </div>
                <div className={style['share-content-row']} onClick={props.openJFile}>
                    {/* <img
                        src={getIcon()}
                        alt=""
                        className="share-content-row-icon"
                        draggable="false"
                    /> */}
                    <div className={style['share-content-row-text']}>{getScheduleContent()}</div>
                    <CalendarIcon src={sharelogoPng} width={40} height={48} />
                </div>
                {meetingInfo && isFocusEnv() && (
                    <div className={style['share-button-content']}>
                        <BaseButton
                            clstag="pageclick|keycount|xtbg_Pcschdule|JoininNow"
                            className={style['share-button-join-meeting']}
                            onClick={handleJoinMeeting}
                            size="small"
                        >
                            {props.t('joyMeeting.join now')}
                        </BaseButton>
                    </div>
                )}
            </div>
        </div>
    );
}

function mapStateToProps({ netStatus }: any) {
    return {
        newPublicResult: netStatus.newPublicResult,
    };
}

export default connect(mapStateToProps)(withTranslation('chat')(CalendarCard));
