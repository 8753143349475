/**
 * 本地存储对象，目前仅使用localStorage存储
 */
class LocalCache {
    setItem = (key: string, value: string) => {
        if (!window || !window.localStorage) {
            return false;
        }
        window.localStorage.setItem(key, value);
        return true;
    };
    getItem = (key: string) => {
        if (!window || !window.localStorage) {
            return false;
        }
        return window.localStorage.getItem(key);
    };
    removeItem = (key: string) => {
        if (!window || !window.localStorage) {
            return false;
        }
        window.localStorage.removeItem(key);
        return true;
    };
}
export default new LocalCache();
