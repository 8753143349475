import { message, Modal } from 'antd';
import { IPageAddCallback, api } from './index';
import { openPicker as openCategoriesPicker } from '../components/CategoriesPickerTree';
import { getPermissions } from '../permissions';
import { lang } from '../context';
import events from '../context/events';

// eslint-disable-next-line max-params
const copy: IPageAddCallback = (props, isAllow, isTeam, globalContext, currentRouterInfo) => {
    const { data, reload } = props;
    const callback = () => {
        openCategoriesPicker({
            title: `${lang('ctx.copy.title.prefix')}${data.title}${lang('ctx.copy.title.suffix')}`,
            selectArray: [
                {
                    id: props.data.id,
                    rootId: props.data.space_id,
                    type: 'file',
                },
            ],
            canSelectMy: true,
            isTeam: isTeam,
            appRuntimeEnv: globalContext?.appRuntimeEnv,
            callback: async ({ id, type, rootId }, close) => {
                let spaceId: any;
                let categoryId: any;

                // 如果是到共享文件夹需要判断是不是到根文件夹
                if (type === 12) {
                    // spaceId = rootId;
                    categoryId = id;
                } else {
                    // 如果是到我的文件夹需要都置空
                    categoryId = id === 'root' ? '' : id;
                }

                Modal.confirm({
                    title: lang('ctx.copy.tip'),
                    // content:
                    //     '移动后将取消原文件夹协作者的共享，并重新共享给目标文件夹的协作者。',
                    cancelText: lang('common.cancel'),
                    okText: lang('common.confirm'),
                    onOk: () => {
                        api.page
                            .copy(data.id, data.title, categoryId, spaceId)
                            .then((data) => {
                                if (data) {
                                    message.success(lang('common.success'));

                                    // 如果选中的是当前目录刷新
                                    const selectId = id === 'root' ? 'myspace' : id;
                                    // 如果选中的是当前目录刷新
                                    if (currentRouterInfo?.id === selectId) {
                                        reload();
                                    } else {
                                        props.clearSelect && props.clearSelect();
                                    }
                                    events.emit('list.reload');
                                } else {
                                    message.error(lang('common.fail'));
                                }
                                close();
                            })
                            .catch((e) => {
                                e && message.error(e);
                                close();
                            });
                    },
                });
            },
        });
    };

    return [
        {
            children: lang('ctx.copy.title.suffix'),
            callbackOrModal: callback,
            isAllow,
            type: 'copy',
            icon: 'pc_yundoc_copy',
            splitCode: 2,
            tip: '在选中目标位置创建一个副本文件，内容与原件一致，但两份文件互相独立。',
        },
    ];
};

export default copy;
