import React from 'react';
export function getContactHighlight(keyword: string): React.ReactNode | string {
    if (!keyword) {
        return '';
    }
    return (
        <>
            <span>查找更多关于“</span>
            <span style={{ color: '@primary-color', wordBreak: 'break-all' }}>{keyword}</span>
            <span>”的联系人</span>
        </>
    );
}
export function getHighlightComp(
    highlight: {
        key: string;
        name: string;
        value: string;
    }[],
    name: string,
    defualtStrValue?: string
): React.ReactNode | string {
    if (!highlight.length) {
        return defualtStrValue || '';
    }
    const hl = highlight.find((item) => item.name === name);
    if (!hl) {
        return defualtStrValue || '';
    }

    const comps: any[] = [];
    let idx = 0;
    const { key, value } = hl;
    // @ts-ignore
    value.replace(new RegExp(key, 'g'), (a: any, i: any) => {
        comps.push(<span key={i}>{value.slice(idx, i)}</span>);
        comps.push(
            <span key={`${i}-1`} className="color-primary">
                {value.slice(i, i + key.length)}
            </span>
        );
        idx = i + key.length;
    });
    comps.push(<span key={`${idx}-last`}>{value.slice(idx)}</span>);
    return (
        <div
            style={{
                display: 'inline-block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%',
            }}
        >
            {comps}
        </div>
    );
}
