import React, { ReactElement } from 'react';
import { v4 } from 'uuid';
import { EventEmitter } from 'events';
import { RegistryConfigData } from '@/types/LocalConfig';

export interface BrowserViewItem {
    id: string;
    icon?: any;
    name?: string;
    link?: string;
    phone?: string;
    company?: string;
    appKey?: string;
    trustedDomains?: string;
    version?: string;
    isThirdApp?: boolean;
}

export interface PageMetaData {
    id: string;
    title?: string;
    favicon?: string;
}

export interface BrowserViewConfigData {
    urls: string[];
    allowedHosts: string[];
    partition: string;
}

export interface DefaultPageConfig {
    id: string;
    title: string;
    icon: any;
    body: ReactElement;
}

interface BrowserViewContextProps {
    items: BrowserViewItem[];
    selectedId?: string;
    configData: BrowserViewConfigData;
    registryConfigData: RegistryConfigData;
    onNewWindowOpen?: (url: string, appKey?: string, version?: string) => void;
    onWindowClose?: (id: string) => void;
    onPageMetaDataChanged?: (data: PageMetaData) => void;
    defaultPage?: DefaultPageConfig;
    notifier: EventEmitter;
    openWebViewModal: (data?: any) => void;
    closeWebViewModal: (data?: any) => void;
    openGroupChat: (data?: any) => void;
    openSingleChat: (data?: any) => void;
    openCommonModal: (data?: any) => void;
    openExternal: (data?: any) => void;
    viewImages: (data: string[]) => void;
    webviewModalVisible?: boolean;
}

// @ts-ignore
export const BrowserViewContext = React.createContext<BrowserViewContextProps>({
    items: [],
});

export function createBrowserViewItems(urls: string[], appKey?: string, version?: string) {
    return urls.map((item) => ({ id: v4(), link: item, appKey, version }));
}
