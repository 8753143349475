import { ChatMessageBody, ChatMessageResp } from '@jd/jdee.im.sdk/lib/es/protocol/message';

// export * from '@jd/jdee.im.sdk/lib/es/protocol/';
export * from '@jd/jdee.im.sdk/lib/es/enum/MessageEmitType';

export * from '@jd/jdee.im.sdk/lib/es/model/Friend';
export { default as Friend } from '@jd/jdee.im.sdk/lib/es/model/Friend';
export { default as Apply } from '@jd/jdee.im.sdk/lib/es/model/Apply';

export * from '@jd/jdee.im.sdk/lib/es/model/Session';
export * from '@jd/jdee.im.sdk/lib/es/method/Session';
export { default as Session } from '@jd/jdee.im.sdk/lib/es/model/Session';
export { default as SessionProps } from '@jd/jdee.im.sdk/lib/es/model/Session';

export * from '@jd/jdee.im.sdk/lib/es/model/Employee';
export { default as Employee } from '@jd/jdee.im.sdk/lib/es/model/Employee';

export * from '@jd/jdee.im.sdk/lib/es/model/Group';
export { default as Group } from '@jd/jdee.im.sdk/lib/es/model/Group';
export * from '@jd/jdee.im.sdk/lib/es/enum/GroupModifyField';
export * from '@jd/jdee.im.sdk/lib/es/protocol/group/GroupBannedPost';

export { default as RosterApplyStatus } from '@jd/jdee.im.sdk/lib/es/enum/RosterApplyStatus';

export * from '@jd/jdee.im.sdk/lib/es/model/Roster';
export * from '@jd/jdee.im.sdk/lib/es/service/Types';
import { default as User, UIDExt } from '@jd/jdee.im.sdk/lib/es/types/UID';

export type UID = User;
export type UIDExtAt = UIDExt;
export * from '@jd/jdee.im.sdk/lib/es/enum/GroupModifyField';
export * from '@jd/jdee.im.sdk/lib/es/enum/MessageType';
export { default as SessionType } from '@jd/jdee.im.sdk/lib/es/enum/SessionType';
export { default as ClientType } from '@jd/jdee.im.sdk/lib/es/enum/ClientType';
export * from '@jd/jdee.im.sdk/lib/es/protocol/message';
export * from '@jd/jdee.im.sdk/lib/es/protocol/type';
import {
    MessageEmitBody,
    ChatMessageEmitBody,
    MessageEmitBodyCommon,
} from '@jd/jdee.im.sdk/lib/es/protocol/type';

export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
export * from '@jd/jdee.im.sdk/lib/es/protocol/group/GroupAdminSet';
export * from '@jd/jdee.im.sdk/lib/es/method/Group';
export * from '@jd/jdee.im.sdk/lib/es/method/Message';
export { default as MessageReadScene } from '@jd/jdee.im.sdk/lib/es/enum/MessageReadScene';

export { default as GroupRoster } from '@jd/jdee.im.sdk/lib/es/model/Roster';

export enum MessageStatus {
    INIT = 'init', // 初始状态 刚放入dva中
    EDITING = 'editing', // 编缉中
    SENDING = 'sending', // 发送中
    SUCCESS = 'success', // 发送成功
    FAILED = 'failed', // 发送失败
    CANCELED = 'canceled',
    UNREAD = 'unread', // 未读
    READ = 'read', // 已读
    REVOKING = 'revoking', // 撤回中
    RECEIVED = 'received', // 接收到的消息
    DOWNLOADING = 'downloading', // 下载中 文件使用
    UPLOADING = 'uploading', // 上传中 文件使用
    GROUPDELETE = 'group_delete', // 上传中 文件使用
    REVOKE = 'revoke', // 撤回
    TEMP = 'temp', // 临时
}

export enum FileStatus {
    INIT = 'init',
    SUCCESS = 'success', // 上传成功
    COMPLETED = 'completed',
    WEB_COMPLETED = 'web_completed',
    FAILED = 'failed', // 发送失败
    CANCELED = 'canceled', // 取消上传canceled
    UPLOADING = 'uploading', // 上传中
    DOWNLOADING = 'downloading',
    DUE = 'due',
    PENDING = 'pending',
    CALC = 'calc',
    INITING = 'initing',
    INITED = 'inited',
    COMPLETING = 'completing',
    CANCELING = 'canceling',
}
export interface ReadUids {
    app: string;
    pin: string;
    teamId: string;
}
export type ChatMessage = ChatMessageEmitBody & {
    type: string;
    taskId?: string;
    task?: any;
    groupId?: string;
    beforeMid: number;
    status?: MessageStatus;
    history?: boolean;
    revoke: number;
    upLocalPath: string;
    downLocalPath: string;
    kind?: string;
    content?: string;
    readUids?: ReadUids[];
    intaction: number;
    localSendTimestamp: any;
    belong?: string;
};

import { default as TextMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Text';

export type TextMessage = TextMessageBody;
export type TextChatMessage = TextMessage & ChatMessage;
export type TextChatMessageExt = TextChatMessage & { displayContent?: ContentItem[] };

import { default as ImageMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Image';

export type ImageMessage = ImageMessageBody;
export type ImageChatMessage = ImageMessage & ChatMessage;

import { default as FileMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/File';

export type FileMessage = FileMessageBody;
export type FileChatMessage = FileMessage & ChatMessage;

import { default as VideoMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Video';

export type VideoMessage = VideoMessageBody;
export type VideoChatMessage = VideoMessage &
    ChatMessage & {
        thumbHeight: number;
    };

import { default as VoiceMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Voice';

export type VoiceMessage = VoiceMessageBody;
export type VoiceChatMessage = VoiceMessage & ChatMessage;

import { default as SysMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Sys';

export type SysMessage = SysMessageBody;
export type SysChatMessage = SysMessage & ChatMessage;

import { default as ShakeMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Shake';

export type ShakeMessage = ShakeMessageBody;
export type ShakeChatMessage = ShakeMessage & ChatMessage;

import { default as LocationMessageBody } from '@jd/jdee.im.sdk/lib/es/protocol/message/Location';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { ContentItem } from '@/components/chat/utils/message';

export type LocationMessage = LocationMessageBody;
export type LocationChatMessage = LocationMessage & ChatMessage;

export type JoySpaceDocumentMessage = {
    type: string;
    data: [
        {
            sharelink: {
                category: string;
                content: string;
                ext: string;
                icon: string;
                source: string;
                title: string;
                url: string;
            };
            type: number;
        }
    ];
    template: {
        nativeId: string;
    };
    datetime: string;
    fileName: string;
    webUrl: string;
    pageType: number;
} & ChatMessage;

export { default as Task } from '@jd/jdee-fs-desktop/lib/es/Task';

export * from '@jd/jdee.im.sdk/lib/es/error/WebsocketError';

//     type: ChatMessageType.JOYSPACE_DOCUMENT;
//     data: JoySpaceDocument[];
//     template: Template;
//     datetime: string; // datetime: "2020-06-26 14:01:54"
// export * from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
// export * from '@jd/jdee.im.sdk/lib/es/enum/MessageEmitType';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';

// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Image';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Voice';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Video';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Location';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Shake';

// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/File';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Read';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/DongRead';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/RevokeMessage';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/Sys';
// export * from '@jd/jdee.im.sdk/lib/es/protocol/message/UnReceivedMessagePush';
