export interface Ioperator {
    userId: string;
    teamId: string;
    app: string;
    clientType: string;
}
export enum EsourceType {
    personal = 1,
    project,
}
export enum EpriorityType {
    // 1低 2普通 3紧急 4非常紧急
    low = 1,
    normal,
    urgent,
    veryUrgent,
}
export enum EtaskStatus {
    // 1未完成 2已完成 3未完成(锁定) 4已完成(锁定)
    unFinish = 1,
    finished,
    unFinishLock,
    finishedLock,
}
export enum EtimeClassify {
    // 0置顶 1已逾期 2今天 3未来7天 4未安排 5已完成
    top = 0,
    overdue,
    today,
    sevendays,
    notArranged,
    finished,
}
export enum SourceBizType {
    PROJECT = 'PROJECT',
}
export interface ICreateSuperviseData {
    id?: string; // 任务id
    title: string; // 任务名称 日程标题
    projectId?: string; // 专班id
    from: EsuperviseSourceType; // WORK时id title projectId有效，Schedule时 shceduleInfo有效
    userList: SuperviseUserInfo[];
    sendType?: EsendType;
    content?: string;
    remarks?: string;
    shceduleInfo?: {
        scheduleId: string;
        beginTime: number;
        endTime: number;
        calendarId: string;
    };
    sourceBizType?: string; //
    type?: any;
}
// 任务数据看板 入参
export interface IfindProjectDetail {
    jsfOperator?: Ioperator;
    offset?: number;
    limit?: number;
    taskId?: string;
    projectId: string;
    projectTaskRelatedMe?: boolean;
    title?: string;
    sourceType: EsourceType;
    taskStatus: EtaskStatus;
    processStatus: EtaskStatus;
    priorityType: EpriorityType;
    timeClassify: EtimeClassify;
    sort: number;
    startTime: number;
    endTime: number;
    endTimeFrom: number;
    endTimeTo: number;
    parentTaskId: string;
    currentTimestamp: number;
}

export interface IfindProjectDetailResp {
    projectId: string;
    type: string;
    name: string;
    icon: string;
    iconColor: string;
    progress: string;
    progressName: string;
    progressColor: string;
    progressDesc: string;
    description: string;
    announcementText: string;
    calendarId: string;
    joyspaceId: string;
    groupIds: string[];
    announcements: string[];
    todayTaskNum: number;
    overdueTaskNum: number;
    todayFinishNum: number;
    myTaskNum: number;
    unFinishTaskNum: number;
}

export interface IsuperviseAllData {
    total: number;
    finish: number;
}

export enum EsuperviseSourceType {
    All = '', // 后端并没有这个枚举值
    WORK = 'WORK',
    NONE = 'NONE',
    SCHEDULE = 'SCHEDULE',
    IM = 'IM',
    NOTIFICATION = 'NOTIFICATION',
    SOLITAIRE = 'SOLITAIRE',
}
export enum EsendType {
    // APP, SMS
    APP = 'APP',
    SMS = 'SMS',
}
export interface User {
    userId: string;
    teamId: string;
    appId: string;
}
export enum SuperviseType {
    Receive = 1,
    Send,
}

export interface SuperviseUserInfo {
    id?: number;
    userId: string;
    teamId: string;
    app?: string;
    headImg?: string;
    realName?: string;
    appId: string;
}
export enum ReadFlag {
    unread = 0,
    read,
    finish,
}
export type Owner = User & { realName: string; headImg: string };
export type Receiver = Owner & {
    readFlag: ReadFlag;
    count: number;
    labelInfoList: any;
    isShowTag: boolean;
    desc: string;
};
export interface IcreateSuperviseReq {
    operator?: Ioperator;
    sourceId: string;
    sourceType: EsuperviseSourceType;
    sourceParam: string;
    sendType: EsendType;
    receiverList: User[];
    content: string;
    sourceBizId?: string;
    sourceBizType?: string;
}
// 督办列表获取接口入参
export interface IsuperviseListReq {
    offset: number;
    limit: number;
    sourceType?: EsuperviseSourceType;
    sourceId?: string; // 日程id
    sourceBizId?: string; // 专班id
    sourceBizType?: string;
}
export interface IcommentResp {
    userId: string;
    teamId: string;
    appId: string;
    realName: string;
    headImg: string;
    superviseId: string;
    commentId: string;
    commentContent: string;
    time: number;
    owner?: Owner;
}
export interface IcreateSupervise {
    superviseId: string;
    sourceType: EsuperviseSourceType;
    sourceParam: string;
    sendType: EsendType;
    content: string;
    remarks: string;
    owner: Owner;
    time: number;
    commentCount?: number;
    receiverList: Receiver[];
    totalReceiverCount: number;
    unReadReceiverCount: number;
    sourceBizType?: string;
}

export interface IReceiveSupervise {
    superviseId: string;
    sourceType: EsuperviseSourceType;
    sourceParam: string;
    sendType: EsendType;
    content: string;
    owner: Owner;
    time: number;
    readFlag: ReadFlag;
    count: number;
    commentCount: number;
    sourceBizType?: string;
}

export enum EreadFlag {
    read = 1,
    finsh,
}

// 日程组织者
export interface IScheduleUser {
    account: string;
    ddAppId: string | null;
    imageUrl: string | null;
    realName: string;
    teamId: string;
    tenantCode: string | null;
    userId: string;
    userType: string;
}
// 日程参与者
export interface IScheduleParticipant {
    resStatus: number;
    user: IScheduleUser[];
}

// 提成详情
export interface ISchedule {
    address: string;
    archive?: string | null;
    beginTime: number;
    calendarId: string;
    color?: string | null;
    ddAppId?: string | null;
    description?: string;
    endTime: number;
    groupId?: string;
    isAlldate: number;
    isAcross: number;
    isBusy: number;
    relateType: number;
    remind: string;
    repeat: number;
    repeatBeginTime: number;
    repeatData?: string;
    repeatEndTime?: string | null;
    scheduleId: string;
    teamId: string;
    title: string;
    user?: IScheduleUser[];
    participantDTOs: IScheduleParticipant[];
    calendarType?: any;
}

export interface IcreateSuperviseRemark {
    operator: {
        userId: string;
        teamId: string;
        appId: string;
    };
    remarkId?: string;
    superviseId: string;
    remarkContent: string;
}

export interface IgetSuperviseRemark {
    operator: {
        userId: string;
        teamId: string;
        appId: string;
    };
    superviseId: string;
}
