import React, { useState, useEffect, useContext } from 'react';
import { Radio, Input, message, List, Form, Checkbox } from 'antd';
import { takeTerminals } from '@/api/meeting';
import './index.less';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const PAGE_SIZE = 20;

interface TerminalType {
    active: boolean;
    alarmStatus: number;
    name: string;
    terminalId: string;
    terminalType: string;
}

export default function Terminal({
    onSelectChange,
    initVal,
}: {
    onSelectChange: (checkedValue: CheckboxValueType[]) => void;
    initVal: CheckboxValueType[];
}) {
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [selectedTerminals, setSelectedTerminals] = useState<CheckboxValueType[]>(initVal);
    useEffect(() => {
        async function getData() {
            const [res, error] = await takeTerminals(page, PAGE_SIZE);

            if (!res || error) {
                return message.error(error);
            }
            const { recordList, total } = res;
            if (page * PAGE_SIZE < total) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
            setList((preList) => {
                return preList.concat(recordList);
            });
        }
        getData();
    }, [page]);

    const onChange = function (val: CheckboxValueType[]) {
        setSelectedTerminals(val);
        onSelectChange(val);
    };
    const loadMore = function () {
        setPage(page + 1);
    };

    return (
        <div className="cloudlink-terminal-select">
            <div className="terminal-list">
                <Checkbox.Group
                    value={selectedTerminals}
                    style={{ width: '100%' }}
                    onChange={onChange}
                >
                    <List
                        dataSource={list}
                        renderItem={(item: TerminalType) => (
                            <List.Item className="cursor-pointer">
                                <Checkbox value={item.terminalId}>{item.name}</Checkbox>
                            </List.Item>
                        )}
                    />
                </Checkbox.Group>
            </div>
            {hasMore && (
                <div className="load-more" onClick={loadMore}>
                    加载更多
                </div>
            )}
        </div>
    );
}
