import React, { useState, useEffect, useRef } from 'react';
import { AppStoreApplication, AppStoreGroupApplication } from '@/services/AppStore';

export default function LeftToolsNav({
    leftNavList,
    onTabChange,
}: {
    leftNavList: AppStoreGroupApplication[];
    onTabChange: (groupId: string) => void;
}) {
    const [activeKey, setActiveKey] = useState<Number>(0);
    return (
        <div className="left-tools-nav">
            {leftNavList?.map((item: AppStoreGroupApplication, index: number) => {
                return (
                    <div
                        className={
                            activeKey === index
                                ? 'left-tools-nav_item left-tools-nav_item_active'
                                : 'left-tools-nav_item'
                        }
                        key={item.groupId}
                        onClick={() => {
                            onTabChange(item.groupId);
                            setActiveKey(index);
                        }}
                    >
                        {item.name}
                    </div>
                );
            })}
        </div>
    );
}
