import { Popover } from 'antd';
import React, { useState } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { ChatConfig } from '@/config/config';
import IconFont from '@/components/icon';

function BannedInput({
    divEl,
    config,
    selectedSession,
    currentEmployee,
}: {
    divEl: any;
    config: any;
    selectedSession: any;
    currentEmployee: any;
}) {
    const { t } = useTranslation('chat');
    function getRightMenu() {
        const menus: any[] = [];
        // 表情
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_EMOJI]) {
            menus.push(
                <IconFont
                    key="0"
                    className="expression input-button"
                    type="iconic_app_im_expression"
                />
            );
        }
        // 图片
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_IMAGE]) {
            menus.push(
                <IconFont key="2" className="pic input-button" type="iconic_app_im_picture" />
            );
        }
        // @
        if (
            config[ChatConfig.CHAT_MESSAGE_INPUT_AT] &&
            !(selectedSession.isSingle && selectedSession.uid?.pin === currentEmployee.userId)
        ) {
            menus.push(
                <IconFont key="1" className="at input-button" type="iconic_app_im_mention" />
            );
        }
        // 常用语
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_CHANG]) {
            menus.push(
                <IconFont key="3" className="at input-button" type="icona-ic_app_im_Commonlyused" />
            );
        }
        if (menus.length > 0) {
            menus.push(<div key="4" className="sort-line" />);
        }
        // +号
        if (config[ChatConfig.CHAT_MESSAGE_INPUT_FILE_IN_MENU]) {
            menus.push(
                <IconFont key="5" className="chat-input-more-menu-icon" type="iconapp_btn_folder" />
            );
        } else {
            menus.push(
                <IconFont key="6" className="add  input-button" type="icona-ic_app_im_Addto" />
            );
        }
        return <div className="input-control-container-right">{menus}</div>;
    }
    return (
        <div className="input-control-container banned-input-container">
            <div className="input-box">
                {getRightMenu()}
                <div className="jeditor">{t('Group manager has been banned')}</div>
            </div>
        </div>
    );
}

export default BannedInput;
