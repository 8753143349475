import React from 'react';
import { Tabs as AntTabs } from 'antd';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { SearchGlobalParams, SearchGlobalRes } from '@/services/SearchService';

import { useTranslation } from 'react-i18next';
import { SearchGlobalContext } from '@/components/Modals/SearchGlobalModal/utils';
import TabPaneAll from '@/components/Modals/SearchGlobalModal/panels/TabPaneAll';
import TabPaneQunzu from '@/components/Modals/SearchGlobalModal/panels/TabPaneQunzu';
import TabPaneJoySpace from '@/components/Modals/SearchGlobalModal/panels/TabPaneJoySpace';
import TabPaneYingyong from '@/components/Modals/SearchGlobalModal/panels/TabPaneApps';
import TabPaneLiaotianjilu from '@/components/Modals/SearchGlobalModal/panels/TabPaneChatHistory';
import TabPaneLianxiren from '@/components/Modals/SearchGlobalModal/panels/TabPaneLianxiren';
import TabPaneTask from '@/components/Modals/SearchGlobalModal/panels/TaskPane/TabPaneTask';
import { analysisLog } from '@/utils/logAnalytics';
import TabPaneSchedule from './panels/TabPaneSchedule';
import TabPaneDepartment from './panels/TabPaneDepartment';
import SeacrchMassageHistoryList from './SearchMessageHistoryList';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { duccConfigI18 } from '@/utils/i18n';

export type getAvatarURLFn = (url: string, width: number, height: number) => string;

export type SearchGlobalFn = (params: SearchGlobalParams) => Promise<SearchGlobalRes>;

const { TabPane } = AntTabs;

export interface TabData {
    key: SearchType;
}

const tabsData: TabData[] = [
    { key: SearchType.All },
    { key: SearchType.Contacts },
    { key: SearchType.Department },
    { key: SearchType.Group },
    { key: SearchType.Schedule },
    { key: SearchType.JoySpace },
    { key: SearchType.App },
    { key: SearchType.Task },
    { key: SearchType.MessageHistory },
];

export type ListItemClickFn = (type: SearchType, data: ListItemData) => void;

// eslint-disable-next-line
function getTabPane(
    item: TabData,
    t: any,
    onClick?: ListItemClickFn,
    keyword = '',
    activeKey = ''
) {
    switch (item.key) {
        case SearchType.All:
            return <TabPaneAll t={t} onClick={onClick} />;
        case SearchType.Contacts:
            return <TabPaneLianxiren t={t} onClick={onClick} />;
        case SearchType.Department:
            return <TabPaneDepartment t={t} onClick={onClick} />;
        case SearchType.Group:
            return <TabPaneQunzu t={t} onClick={onClick} />;
        case SearchType.JoySpace:
            return <TabPaneJoySpace onClick={onClick} />;
        case SearchType.Schedule:
            return <TabPaneSchedule onClick={onClick} />;
        case SearchType.App:
            return <TabPaneYingyong onClick={onClick} />;
        case SearchType.MessageHistory:
            return <SeacrchMassageHistoryList t={t} activeKey={activeKey} keyWord={keyword} />;
        // return <TabPaneLiaotianjilu onClick={onClick} />;
        case SearchType.Task:
            return <TabPaneTask onClick={onClick} />;
        default:
            return null;
    }
}

export default function Tabs({
    config,
    activeKey,
    onActiveKeyChange,
    onItemClick,
    onGetAvatarURL,
    onSearchGlobal,
    keyword,
    authData,
    isChange,
}: {
    config?: TabData[];
    onGetAvatarURL?: getAvatarURLFn;
    activeKey: SearchType;
    onActiveKeyChange: (activeKey: SearchType) => void;
    onItemClick?: ListItemClickFn;
    onSearchGlobal: SearchGlobalFn;
    keyword: string;
    authData?: any;
    isChange?: boolean;
}) {
    const { t } = useTranslation('common');
    let data = config;
    if (!data) {
        data = tabsData;
    }
    return (
        <SearchGlobalContext.Provider
            value={{
                t,
                onGetAvatarURL,
                onSearchGlobal,
                keyword,
                activeKey,
                setTabIndex: onActiveKeyChange,
                authData,
                isChange,
            }}
        >
            <AntTabs
                size="small"
                className="searchModalTabs"
                style={{
                    flex: 1,
                }}
                activeKey={activeKey}
                onChange={(key) => {
                    let logType = '';
                    switch (key) {
                        case '1':
                            logType = '1';
                            break;
                        case '2':
                            logType = '2';
                            break;
                        case '3':
                            logType = '3';
                            break;
                        case '4':
                            logType = '4';
                            break;
                        case '5':
                            logType = '5';
                            break;
                        case '6':
                            logType = '6';
                            break;
                        case '7':
                            logType = '7';
                            break;
                        case '-1':
                            logType = '8';
                            break;
                    }
                    logType && analysisLog('xtbg_home_search_1641454991771', logType);
                    onActiveKeyChange(key as SearchType);
                }}
            >
                {data.map((item) => (
                    <TabPane
                        key={item.key}
                        tab={t(`search.tabs.${item.key}`, {
                            joyspace: duccConfigI18.joyspace,
                        })}
                    >
                        {getTabPane(item, t, onItemClick, keyword, activeKey)}
                    </TabPane>
                ))}
            </AntTabs>
        </SearchGlobalContext.Provider>
    );
}
