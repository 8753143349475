// import fetchApi from './fetch';
// import 'isomorphic-fetch';
// import Cookies from 'js-cookie';
// import logger from '@utils/logger';
import {
    FileDocument,
    InitResult,
    OpenFileMode,
    PageType,
    UploadFile,
    WPSUploadTransactionModel,
} from './types';
import { algo, enc } from 'crypto-js';
import { lookup } from 'mime-types';
import { extname } from 'path';
import { postAddFile, postInitFile } from '@/components/Joyspace/api/file';
import { changeEgovUrl } from '@/utils/tools';
// import { API } from '@const/api';
// import { JResult, JResultContent } from '@model/types';
// import nanoid from 'nanoid';

//
// import getConfig from 'next/config';
// import { isError } from 'lodash';
//
// const { publicRuntimeConfig } = getConfig();
// const log = logger.getLogger('utils/upload');

export function sizeToStr(size: number): string {
    if (size < 1024) {
        return size + 'B';
    }
    if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + 'KB';
    }
    if (size < 1024 * 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + 'MB';
    }

    if (size < 1024 * 1024 * 1024 * 1024) {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
    }
    return '';
}
export function toPercent(progress: number): string {
    return Math.floor(progress) + '%';
}
export function getFileType(file: UploadFile) {
    const fileExtName = extname(file.name);
    let type = file.type || lookup(file.name);
    console.log(file.type, 'getFileType111111111');
    // TIPS: 为了统一 mac 和 windows（windows系统会识别rtf文件是word类型文档） 系统 rtf 文档类型， 统一设置为文本文件类型
    if (fileExtName === '.rtf' && type === 'application/msword') {
        type = 'text/rtf';
        return type;
    }
    console.log('fileExtName111111', fileExtName);
    const wpsAry = ['.wps', '.dps', '.wpt', '.dpt', '.et', '.ett'];
    if (wpsAry.includes(fileExtName)) {
        type = 'application/wps-office' + fileExtName;
        console.log('type111111111', type);
        return type;
    }

    return (
        type ||
        ((fileExtName) => {
            if (fileExtName === '.ape') {
                return 'audio/x-ape';
            }
        })(fileExtName) ||
        'application/octet-stream'
    );
}

const getStrlen = (str: string) => {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
            len += 2;
        } else {
            len++;
        }
    }
    return len;
};

export function formatFileName(name: string): string {
    const len = getStrlen(name);
    if (len < 32) {
        return name;
    } else {
        const before = name.substring(0, 10);
        const after = name.substring(name.length - 10, name.length);
        return `${before}...${after}`;
    }
}

/**
 * 分页读取文件
 */
export const readChunked = (
    file: UploadFile,
    chunkCallback: (result: string, offset: number, fileSize: number) => void,
    endCallback: (error: Error | null) => void
) => {
    const fileSize = file.size;
    const chunkSize = 1024 * 1024; // 1MB
    // let offset = file.sizeOffset || 0;
    let offset = 0;

    let reader = new FileReader();
    // if (!reader) {
    //     reader = new FileReader();
    //     file.reader = reader;
    // }

    reader.onload = (e: any) => {
        if (reader.error) {
            endCallback(reader.error || {});
            return;
        }
        if (e?.target?.result) {
            offset += e.target.result.length;
            // file.sizeOffset = offset;
            chunkCallback(e.target.result, offset, fileSize);
        } else {
            endCallback(new Error('Read file error!'));
        }
        if (offset >= fileSize) {
            endCallback(null);
            return;
        }
        readNext();
    };

    // @ts-ignore
    reader.onerror = (err: Error) => {
        endCallback(err || {});
    };

    function readNext() {
        const fileSlice = file.slice(offset, offset + chunkSize);
        reader.readAsBinaryString(fileSlice);
    }

    readNext();
};

export const getFileMd5 = (file: UploadFile): string => {
    const md5 = algo.MD5.create();
    let { name, size } = file;
    let hash = md5.finalize(`${name}--${size}`);
    return hash.toString(enc.Hex);
};

export async function initFile(file: UploadFile): Promise<InitResult | null> {
    try {
        const fileMd5 = getFileMd5(file);
        const postBody = {
            fileSize: file.size,
            fileName: file.name,
            fileMd5: fileMd5,
            fileType: getFileType(file),
        };

        const result = await postInitFile(postBody);
        // console.log('step3.1 postInitFile =>', result);
        if (result && result.status === 'failed') {
            if (result.errCode === 40429) {
                console.error('error =>', result.errMsg);
                return await initFile(file);
            }
            return null;
        } else {
            return Object.assign(result, { hash: fileMd5 });
        }
    } catch (e) {
        console.log('initFile =>', e);
        return null;
    }
}

/**
 * 创建文件类型文档
 * @param uploadId 文件上传ID
 * @param spaceId 文档所属空间ID
 * @param categoryId 文档所属空间分类ID，categoryID与spaceID同时存在时，会校验spaceID与categoryID
 * @param folderId 文件夹ID，默认为空，为空时，创建在用户根目录，folderId与，spaceId，categoryId同时存在时只创建到指定的categoryId下面
 */
export async function createDocument({
    uploadId,
    folderId,
    spaceId,
    categoryId,
    gid,
}: {
    uploadId: string;
    folderId?: string;
    spaceId?: string;
    categoryId?: string;
    gid?: string;
}): Promise<FileDocument | Error | null> {
    const postBody = {
        uploadId,
        spaceId: spaceId || '',
        categoryId: categoryId || '',
        folderId: folderId || '',
        to_groups: [{ gid: gid, permission_type: 1 }],
    };
    const data = await postAddFile(postBody);
    // console.log('postAddFile ==>', data);
    if (data?.id) {
        return data;
    }
    return null;
}

/**
 * 文件打开方式
 * @param 文件 mime type
 */
export function fileOpenMode(type: string): OpenFileMode {
    const isImageFile = (type: string) => {
        const supportList = ['image/png', 'image/gif', 'image/jpeg'];
        return supportList.includes(type);
    };
    const isWPSSupportFile = (type: string) => {
        const wordTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/eio-x-doc',
            'application/eio-x-docx',
            'application/wps-office.doc',
            'application/wps-office.docx',
            'application/vnd.ms-word.document.macroEnabled.12',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
            'application/vnd.ms-word.template.macroEnabled.12',
            // 'application/wps-office.wps',
            // 'application/wps-office.wpt',
        ];
        const excelTypes = [
            'application/excel',
            'application/vnd.ms-excel',
            'application/x-excel',
            'application/x-msexcel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/eio-x-xls',
            'application/eio-x-xlsx',
            'application/wps-office.xls',
            'application/wps-office.xlsx',
            'text/csv',
            'application/vnd.ms-excel.sheet.macroEnabled.12',
            'application/vnd.ms-excel.template.macroEnabled.12',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        ];
        const pptTypes = [
            'application/vnd.ms-powerpoint',
            'application/mspowerpoint',
            'application/powerpoint',
            'application/x-mspowerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/eio-x-ppt',
            'application/eio-x-pptx',
            'application/wps-office.ppt',
            'application/wps-office.pptx',
            'application/vnd.ms-powerpoint.template.macroEnabled.12',
            'application/vnd.openxmlformats-officedocument.presentationml.template',
            'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
            // 'application/wps-office.dps',
            // 'application/wps-office.dpt',
        ];
        const wpsTypes = [
            'application/wps-office.wps',
            'application/wps-office.dps',
            'application/wps-office.wpt',
            'application/wps-office.dpt',
            'application/wps-office.et',
            'application/wps-office.ett',
        ];
        const pdfTypes = [
            'application/pdf',
            'application/eio-x-pdf',
            'application/eio-x-pdfx',
            'application/wps-office.ppts',
            'application/wps-office.ppt',
        ];
        const supportList = wordTypes
            .concat(excelTypes)
            .concat(pptTypes)
            .concat(pdfTypes)
            .concat(wpsTypes);
        return supportList.includes(type) && false;
    };
    const isVideoFile = (type: string) => {
        // 不支持的格式：'video/x-matroska','video/x-flv','video/3gpp','video/avi','video/mpeg','video/x-ms-vob'
        const supportList = ['video/mp4', 'video/quicktime', 'video/webm', 'video/ogg'];
        return supportList.includes(type);
    };
    console.log('fileOpenMode', type);
    if (type && isImageFile(type)) {
        return OpenFileMode.IMAGE;
    } else if (type && isWPSSupportFile(type)) {
        return OpenFileMode.WPS;
    } else if (type && isVideoFile(type)) {
        return OpenFileMode.VIDEO;
    } else {
        return OpenFileMode.OTHER;
    }
}

export async function uploadWPSFile(
    file: UploadFile | Blob,
    transaction: WPSUploadTransactionModel,
    signal?: AbortSignal
) {
    const { upload_requests } = transaction;
    const value = upload_requests.value[0].request;
    const { url, method, form, inner_url } = value;
    let realUrl = url.indexOf('http') === -1 ? inner_url : url;
    const formData = new FormData();
    realUrl = changeEgovUrl(realUrl);

    form.forEach((f) => {
        const { name, value } = f;
        formData.append(name, value);
    });

    formData.append('file', file);
    const resp = await fetch(realUrl, {
        method,
        body: formData,
        // headers,
        signal,
    });
    try {
        const result = await resp.json();
        return result;
    } catch (e) {
        return new Error('uploadFile failed');
    }
}

//
// // 休眠
// export function sleep(ms: number): Promise<null> {
//     return new Promise((resolve) => {
//         setTimeout(() => {
//             resolve();
//         }, ms);
//     });
// }
//

//
// /**
//  * 创建文档附件
//  * @param file 上传的文件
//  * @param signal
//  */
// export async function createAttachmentWrapper(
//     file: UploadFile,
//     signal: AbortSignal
// ): Promise<UploadFile> {
//     const attachment = await createAttachment(file.uploadId, file.pageId, signal);
//     if (isError(attachment) || !attachment) {
//         Object.assign(file, { status: UploadStatus.FAILED });
//     } else {
//         const attachmentId = attachment.id;
//         Object.assign(file, { percent: 100, fileId: attachmentId, status: UploadStatus.END });
//     }
//     return file;
// }
//
// /**
//  * 创建文档附件
//  * @param uploadId 文件上传ID
//  * @param signal
//  * @param pageId 文档ID
//  */
// export async function createAttachment(
//     uploadId: string,
//     pageId: string,
//     signal: AbortSignal
// ): Promise<Attachment | Error | null> {
//     const postBody = {
//         uploadId,
//         pageId,
//     };
//     const [data, err] = await fetchApi<FileDocument | Error>(API.Attachments.Create(), {
//         method: 'POST',
//         body: JSON.stringify(postBody),
//         signal,
//     });
//
//     if (err) {
//         return new Error('createDocument Error');
//     }
//     return data;
// }
//
// export async function uploadWPSFile(
//     file: UploadFile | Blob,
//     transaction: WPSUploadTransactionModel,
//     signal?: AbortSignal
// ) {
//     const { upload_requests } = transaction;
//     const value = upload_requests.value[0].request;
//     const { url, method, form } = value;
//
//     const formData = new FormData();
//
//     form.forEach((f) => {
//         const { name, value } = f;
//         formData.append(name, value);
//     });
//
//     formData.append('file', file);
//     const resp = await fetch(url, {
//         method,
//         body: formData,
//         // headers,
//         signal,
//     });
//     try {
//         const result = await resp.json();
//         return result;
//     } catch (e) {
//         log.error('uploadFile =>', e);
//         return new Error('uploadFile failed');
//     }
// }
