const ThemeConfig: { [key: string]: any } = {
    default: {
        '--app-bg': '#212b37',
        '--primary-color': '#31c4cc',
        '--left-side-color': '#000',
        '--top-side-color': '#000',
    },
    black: {
        '--app-bg': '#212b37',
        '--primary-color': '#31c4cc',
        '--left-side-color': '#000',
        '--top-side-color': '#000',
    },
    blue: {
        '--app-bg': '#00f',
        '--primary-color': '#f00',
        '--left-side-color': '#000',
        '--top-side-color': '#000',
    },
};
export default ThemeConfig;
