/**
 * 系统消息用到的工具方法
 */
import {
    employeeToUid,
    isEqualUid,
    isInvitee,
    myReceiveMessage,
    mySendMessage,
    senderIsInvitee,
} from '@/utils/chat/message';
import { isGroupAdmin, isGroupOwner } from '@/utils/chat/group';
import {
    Employee,
    GroupApprovalRule,
    GroupModifyField,
    MessageRespType,
    Session,
} from '@/types/chat';
import { SysMessageReceiveType } from '@/types/chat/enum';
import sysMsgConfig from '@/config/chat';
import { withModifyField } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import i18n from '@/i18n';
import { GroupRosterIdentity } from '@jd/jdee.im.sdk/lib/es/model/Roster';
import log from '@/utils/logger';
const logger = log.getLogger('ImService');
/**
 * 修改群信息 动作： 开启 或 关闭
 * @param message
 * @constructor
 */
export function groupSetAction(message: any): string {
    const { modifyField, type } = message;
    const group_set_open = i18n.t('chat:group_set_open');
    const group_set_close = i18n.t('chat:group_set_close');
    if (type === MessageRespType.GROUP_BANNED_POST) {
        // 禁言
        return message.operation === 1 ? group_set_open : group_set_close;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_SEARCH_B)) {
        // 可被搜索 1024
        return message.canSearch === 1 ? group_set_open : group_set_close;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_APPROVAL_B)) {
        // 加群验证  2048
        return message.approvalRule === GroupApprovalRule.IDENTITY
            ? group_set_close
            : group_set_open;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_CARDVIEW_B)) {
        // 查看群名片  4096
        return message.forbidViewCard === 2 ? group_set_open : group_set_close;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_PERMISSION_CHECK)) {
        // 全员修改群信息  16384
        return message.permissionCheck === 0 ? group_set_open : group_set_close;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_NEED_VERIFY)) {
        // 加群验证  32768
        return message.needVerify === 1 ? group_set_open : group_set_close;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_VISIBLE_UNIT)) {
        // 按单位选择成员  131073 反着来 0可见  1不可见
        return message.visibleUnit === 1 ? group_set_close : group_set_open;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_CHAT_VISIBLE_UNIT)) {
        // 显示成员单位名称  262144
        return message.chatVisibleUnit === 1 ? group_set_open : group_set_close;
    }
    return '';
}

/**
 * 组合群组修改字段类型
 */
export function combGroupSetField(message: any): string {
    const { type, modifyField } = message;
    // 是否可以被搜索
    if (withModifyField(modifyField, GroupModifyField.FILED_SEARCH_B)) {
        // 可被搜索 1024
        return type + '_' + GroupModifyField.FILED_SEARCH_B;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_APPROVAL_B)) {
        // 加群验证  2048
        return type + '_' + GroupModifyField.FILED_APPROVAL_B;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_CARDVIEW_B)) {
        // 查看群名片  4096
        return type + '_' + GroupModifyField.FILED_CARDVIEW_B;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_NAME_B)) {
        // 名称  16
        return type + '_' + GroupModifyField.FILED_NAME_B;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_PERMISSION_CHECK)) {
        // 名称  16384
        return type + '_' + GroupModifyField.FILED_PERMISSION_CHECK;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_NEED_VERIFY)) {
        // 加群验证  32768
        return type + '_' + GroupModifyField.FILED_NEED_VERIFY;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_VISIBLE_UNIT)) {
        // 按单位选择成员  131073
        return type + '_' + GroupModifyField.FILED_VISIBLE_UNIT;
    } else if (withModifyField(modifyField, GroupModifyField.FILED_CHAT_VISIBLE_UNIT)) {
        // 显示成员单位名称  262144
        return type + '_' + GroupModifyField.FILED_CHAT_VISIBLE_UNIT;
    }
    return '';
}
/**
 * 单聊会话的系统通知消息
 * @param message
 * @param session
 * @param currentEmployee
 */
export function getSingleSysMessageContent({
    message,
    session,
    currentEmployee,
}: {
    message: any;
    session: Session;
    currentEmployee: Employee;
}): string {
    const { sys_msg_type } = message;
    if (sys_msg_type === 'SetRosterSuccessNotice') {
        return message.content;
    }
    return '';
}

export function getMessageType(message: any): string {
    let type = message.type;
    if (type === MessageRespType.GROUP_SET) {
        type = combGroupSetField(message);
    } else if (type === MessageRespType.GROUP_MEMBER_IN) {
        let ext = message.ext;
        if (ext) {
            try {
                ext = JSON.parse(ext);
                if (ext.joinMode) {
                    type = MessageRespType.GROUP_MEMBER_IN + '_' + ext.joinMode;
                } else {
                    type = MessageRespType.GROUP_MEMBER_IN;
                }
            } catch (e) {
                type = '';
            }
        }
    }
    return type;
}
/**
 * 获取系统消息的接收者
 * @param message
 * @param currentEmployee
 * @param groupRosterIdentity
 */
export function getSysMessageReceiver({
    message,
    currentEmployee,
    groupRosterIdentity,
}: {
    message: any;
    currentEmployee: Employee;
    groupRosterIdentity: GroupRosterIdentity | '';
}): SysMessageReceiveType | null {
    if (!message) {
        return null;
    }
    const sysMsgGroupConfig: any = sysMsgConfig['system-message-group'];
    const receiveRoles = sysMsgGroupConfig[message.type];
    if (!receiveRoles) {
        // 没有配置项，群里任何角色都不可以接收这个系统消息
        return null;
    }
    // 判断是不是 sender  invitee 是不是一个人
    // if (senderIsInvitee({ message })) {
    //     return SysMessageReceiveType.Applicant; // 申请人;
    // } else

    // 只有是group_member_in 的情况下 判断是不是 sender  invitee 是不是一个人
    if (senderIsInvitee({ message })) {
        return SysMessageReceiveType.Applicant; // 申请人;
    }
    // 如果是管理员
    if (
        (isGroupOwner(groupRosterIdentity) || isGroupAdmin(groupRosterIdentity)) &&
        receiveRoles.includes('administrator')
    ) {
        // 当前用户是管理员 并且管理员可以接收系统消息
        if (mySendMessage({ message, currentEmployee })) {
            return SysMessageReceiveType.OWNER; // 发送者;
        } else if (myReceiveMessage({ message, currentEmployee })) {
            return SysMessageReceiveType.TO; // 接收者
        } else {
            return SysMessageReceiveType.ADMIN; // 管理员;
        }
    } else if (receiveRoles.includes('ordinary')) {
        // 当前用户不是管理员 并且可以接收系统消息
        if (mySendMessage({ message, currentEmployee })) {
            return SysMessageReceiveType.OWNER; // 发送者;
        } else if (myReceiveMessage({ message, currentEmployee })) {
            return SysMessageReceiveType.TO; // 接收者
        } else if (isInvitee({ message, currentEmployee })) {
            return SysMessageReceiveType.INVITEE; // 被邀请者;
        } else {
            return SysMessageReceiveType.OTHER; // 其它人;
        }
    } else {
        return null;
    }
}

export function getMessageExt(message: any) {
    try {
        if (!message.ext) {
            return {};
        }
        const ext = JSON.parse(message.ext || '');
        ext.qrUid = JSON.parse(ext.qrUid);
        ext.fromUid = JSON.parse(ext.fromUid);
        return ext;
    } catch (e) {
        return {};
    }
}

export const sysMsgContentConfig: any = {
    group_create: {
        owner: (message: any) => {
            // 你发起了群聊，并邀请 {{first}} 加入
            const first = message.invitees
                .reduce((result: any, item: any) => {
                    if (!isEqualUid(item, employeeToUid(message.sender))) {
                        result.push(
                            item.realName || item.nickName || item.nickname || item.name || ''
                        );
                    }
                    return result;
                }, [] as any)
                .join(', ');
            return { first };
        },
        other: (message: any) => {
            // {{first}} 邀请你加入群聊
            return { first: message.sender.name };
        },
    },
    group_member_in: {
        owner: (message: any) => {
            // 你邀请 {{first}} 加入群聊 ok
            const sender = employeeToUid(message.sender);
            const arr = [];
            for (let item of message.members) {
                if (isEqualUid(sender, item)) {
                    continue;
                }
                arr.push(item.realName || item.nickName || item.nickname || item.name || '');
            }
            const first = arr.length ? arr.join(',') : '';
            return { first };
        },
        invitee: (message: any) => {
            // {{first}} 邀请你加入群聊
            return { first: message.sender.name };
        },
        other: (message: any) => {
            // {{first}} 邀请 {{second}} 加入群聊
            const second = message.members.reduce((result: any, item: any) => {
                if (!isEqualUid(item, employeeToUid(message.sender))) {
                    result.push(item.realName || item.nickName || item.nickname || item.name || '');
                }
                return result;
            }, [] as any);

            const finalSecond = second?.length ? second.join(',') : '';
            return { first: message.sender.name, second: finalSecond };
        },
        admin: (message: any) => {
            // {{first}} 邀请 {{second}} 加入群聊
            const second = message.members.reduce((result: any, item: any) => {
                if (!isEqualUid(item, employeeToUid(message.sender))) {
                    result.push(item.realName || item.nickName || item.nickname || item.name || '');
                }
                return result;
            }, [] as any);
            const finalSecond = second?.length ? second.join(',') : '';
            return { first: message.sender.name, second: finalSecond };
        },
        applicant: (message: any) => {
            // {{first}} 加入群聊
            return { first: message.sender.name };
        },
    },
    group_member_in_5: {
        owner: (message: any, currentEmployee: Employee) => {
            // 你通过扫描xxx分享的二维码加入群聊
            // 解析ext
            const ext = getMessageExt(message);
            logger.debug('getMessageExt ==>1', ext);
            const first = ext.fromUid?.nickName || '';
            const secondUser = ext.qrUid;
            let second = '';
            if (!secondUser) {
                second = '';
            } else {
                if (isEqualUid(secondUser, employeeToUid(currentEmployee))) {
                    second = '您';
                } else {
                    second = secondUser.nickName || '';
                }
            }
            return { first, second };
        },
        other: (message: any, currentEmployee: Employee) => {
            // // xxx 通过扫描 xxx 分享的二维码加入群聊
            // const ext = getMessageExt(message);
            // logger.debug('getMessageExt ==>2', ext);
            // const second = ext.qrUid?.nickName || '';
            // return { first: message.sender.name, second };
            const ext = getMessageExt(message);
            logger.debug('getMessageExt ==>2', ext);
            const first = ext.fromUid?.nickName || '';
            const secondUser = ext.qrUid;
            let second = '';
            if (!secondUser) {
                second = '';
            } else {
                if (isEqualUid(secondUser, employeeToUid(currentEmployee))) {
                    second = '您';
                } else {
                    second = secondUser.nickName || '';
                }
            }
            return { first, second };
        },
        admin: (message: any, currentEmployee: Employee) => {
            // // xxx 通过扫描 xxx 分享的二维码加入群聊
            const ext = getMessageExt(message);
            logger.debug('getMessageExt ==>3', ext);
            const first = ext.fromUid?.nickName || '';
            const secondUser = ext.qrUid;
            let second = '';
            if (!secondUser) {
                second = '';
            } else {
                if (isEqualUid(secondUser, employeeToUid(currentEmployee))) {
                    second = '您';
                } else {
                    second = secondUser.nickName || '';
                }
            }
            return { first, second };
        },
        invitee: (message: any, currentEmployee: Employee) => {
            // {{first}} 邀请你加入群聊
            const ext = getMessageExt(message);
            logger.debug('getMessageExt ==>4', ext);
            const first = ext.qrUid?.nickName || '';
            return { first };
        },
    },
    group_out: {
        admin: (message: any) => {
            // "admin": "{{first}} 退出了群聊"  ok
            return { first: message.sender.name };
        },
        other: (message: any) => {
            return { first: message.sender.name };
        },
    },
    group_member_delete: {
        owner: (message: any) => {
            // 你将 {{first}} 从群聊移除 ok
            const first = message.members.map((item: any) => {
                return item.nickName || item.name || '';
            });
            const finalFirst = first?.length ? first.join(',') : '';
            return { first: finalFirst };
        },
        admin: (message: any) => {
            const second = message.members.map((item: any) => {
                return item.nickName || item.name || '';
            });
            const finalSecond = second?.length ? second.join(',') : '';
            return { first: message.sender.name, second: finalSecond };
        },
        other: (message: any) => {
            const second = message.members.map((item: any) => {
                return item.nickName || item.name || '';
            });
            const finalSecond = second?.length ? second.join(',') : '';
            return { first: message.sender.name, second: finalSecond };
        },
    },
    group_set_16: {
        // 修改群名称
        owner: (message: any) => {
            // '你将群名称 “{{first}}” 修改为 “{{second}}”'
            return { first: message.oldName, second: message.name };
        },
        other: (message: any) => {
            // '{{first}} 将群名称 “{{second}}” 修改为 “{{third}}” '
            return { first: message.sender.name, second: message.oldName, third: message.name };
        },
        admin: (message: any) => {
            // '{{first}} 将群名称 “{{second}}” 修改为 “{{third}}” '
            return { first: message.sender.name, second: message.oldName, third: message.name };
        },
    },

    group_set_1024: {
        // 修改 群可被搜索
        owner: (message: any) => {
            // 你开启了群可被搜索 || 你关闭了群可被搜索
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // 群主开启了群可被搜索 || 群主关闭了群可被搜索
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // 群主开启了群可被搜索 || 群主关闭了群可被搜索
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_set_2048: {
        // 修改 加群验证
        owner: (message: any) => {
            // 你开启了加群验证 || 你关闭了加群验证
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // xxx开启了加群验证 || 群主关闭了加群验证
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // xxx开启了加群验证 || 群主关闭了加群验证
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_set_4096: {
        // 修改 查看群名片
        owner: (message: any) => {
            // 你开启了群成员可查看其它人名片页 || 你关闭了群成员可查看其它人名片页
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // xxx开启了群成员可查看其它人名片页 || xxx关闭了群成员可查看其它人名片页
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // xxx开启了群成员可查看其它人名片页 || xxx关闭了群成员可查看其它人名片页
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_set_16384: {
        // 全员可修改群信息
        owner: (message: any) => {
            // 你开启/关闭了全员可修改群信息
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // xxx开启/关闭了全员可修改群信息
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // xxx开启/关闭了全员可修改群信息
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_set_32768: {
        // 开启关闭加群验证
        owner: (message: any) => {
            // 你开启/关闭了加群验证
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // 操作人开启/关闭了加群验证
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // 操作人开启/关闭了加群验证
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_set_131073: {
        // 开启关闭按单位选择成员
        owner: (message: any) => {
            // 你开启/关闭了按单位选择成员
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // 操作人开启/关闭了按单位选择成员
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // 操作人开启/关闭了按单位选择成员
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_set_262144: {
        owner: (message: any) => {
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_banned_post: {
        // 修改 群禁言
        owner: (message: any) => {
            // 你开启了群可被搜索 || 你关闭了群可被搜索
            return { first: '', second: groupSetAction(message) };
        },
        admin: (message: any) => {
            // 操作人开启了群禁言 || 你关闭了群禁言
            return { first: message.sender.name, second: groupSetAction(message) };
        },
        other: (message: any) => {
            // 操作人开启了群可被搜索 || 群主关闭了群可被搜索
            return { first: message.sender.name, second: groupSetAction(message) };
        },
    },
    group_admin_set: {
        // 转让群主
        owner: (message: any) => {
            // 你将群主转让给xxx
            return { first: '', second: message.to.name };
        },
        to: (message: any) => {
            // xxx将群主转让给你
            return { first: message.sender.name, second: '' };
        },
        other: (message: any) => {
            // xxx将群主转让给xxx
            return { first: message.sender.name, second: message.to.name };
        },
    },
};
