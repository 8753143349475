import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import bus from '@/utils/bus';
import GlobalContext from '@/context/GlobalContext';
import { FocusSDK } from '@/utils';
import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { getConferenceByCode, getUserSimple } from '@/api/meeting';
import { MeetingPlatform } from '@/types/common';

export interface JoinMeetingResult {
    meetingNum: string;
    meetingPass: string;
}

interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
}

export default function JoinMeetingModal() {
    const [showPass, setShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [meetingNum, setMeetingNum] = useState('');
    const [pass, setPass] = useState('');
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const [t_common] = useTranslation('common');
    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
    });
    const [ownerInfo, setOwnerInfo] = useState<{
        deptName: string; // 部门
        superiorDeptName: string | null; // 次级部门
        titleName: string | null; // 岗位名称
        userName: string;
    } | null>(null);

    const { authInfo } = useContext(GlobalContext);
    const { teamUserInfo, userId } = authInfo;
    const inputRef = React.useRef<Input | null>(null);
    useEffect(() => {
        function handleModalVisible() {
            setVisible(true);
            (async () => {
                const [result, err] = await getUserSimple({
                    userId,
                    teamId: authInfo.selectedTeamId,
                    appId: authInfo.ddAppId,
                });
                if (err || !result) {
                    return;
                }
                setOwnerInfo(result);
            })();
        }
        bus.on('xylink:join-show', handleModalVisible);
        return () => {
            bus.off('xylink:join-show', handleModalVisible);
        };
    }, [authInfo.ddAppId, authInfo.selectedTeamId, userId]);

    useEffect(() => {
        function handle({ status, error }: { status: number; error: any }) {
            if (!visible) {
                return;
            }
            setConnect(false);
            if (status) {
                closeModal();
            } else {
                message.error(error || '加入会议失败');
            }
        }
        FocusSDK.on('xylink:joinMeetingCb', handle);
        return () => {
            FocusSDK.off('xylink:joinMeetingCb', handle);
        };
    }, [userId, visible]);

    function closeModal() {
        setMeetingNum('');
        setPass('');
        setVisible(false);
        setShowPass(false);
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
        });
    }
    function onMeetingNumChange(event: React.ChangeEvent<HTMLInputElement>) {
        let { value } = event.target;
        value = value.trim().replace(/\s+/g, '');
        if (connect) {
            return;
        }
        if (!/^[\d\s]{0,20}$/.test(value)) {
            return;
        }
        setMeetingNum(value);
    }

    async function onJoinMeeting() {
        if (connect) {
            return;
        }
        setConnect(true);
        const [meetingInfo, err] = await getConferenceByCode({
            meetingId: meetingNum,
            platform: MeetingPlatform.XYLINK,
        });
        if (!meetingInfo || err) {
            message.error(err);
            setConnect(false);
            return;
        }
        if (meetingInfo.password !== pass) {
            message.error('会议密码错误');
            setConnect(false);
            return;
        }
        FocusSDK.sendIpcMessage('xylink.joinMeeting', {
            meetingInfo: {
                topic: meetingInfo.topic,
                controlPassword: meetingInfo.controlPassword,
                meetingNumber: meetingNum,
                password: pass,
                isvideooff: state.videoDisable,
                isaudiooff: state.audioDisable,
            },
            userInfo: {
                app: teamUserInfo.ddAppId,
                pin: authInfo.userId,
                teamId: authInfo.selectedTeamId,
                name: teamUserInfo.realName,
                avatar: teamUserInfo.headImg,
                titleName: ownerInfo?.titleName || '',
                superiorDeptName: ownerInfo?.superiorDeptName || '',
                deptName: ownerInfo?.deptName || '',
            },
        });
    }

    function onMeetingPassChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (!/^\d{0,6}$/.test(value)) {
            return;
        }
        setPass(value);
    }

    const isValidMeetingNum = () => {
        return meetingNum && (pass.length === 0 || pass.length === 6);
    };

    function handlePassBlur() {
        if (!pass) {
            setShowPass(false);
        }
    }

    function handleMenuClick(key: string) {
        if (connect) {
            return;
        }
        if (key === 'audio') {
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
    }

    const passComp = showPass ? (
        <Input
            value={pass}
            placeholder={t_common('joinMeeting.meetingPass')}
            autoFocus
            onPressEnter={onJoinMeeting}
            onChange={onMeetingPassChange}
            onBlur={handlePassBlur}
        />
    ) : (
        <div
            className="input-label"
            onClick={() => {
                setShowPass(true);
            }}
        >
            <IconFont style={{ marginRight: 5 }} type="iconic_editor" />
            {t_common('joinMeeting.meetingPassLabel')}
        </div>
    );

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    function onCancel() {
        closeModal();
    }
    return (
        <Modal
            className="desk-me-join-meeting-modal"
            width={780}
            visible={visible}
            onCancel={onCancel}
            closeIcon={<IconFont type="iconapp_btn_file_cancel" />}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div className="me-join-meeting-body">
                <div className="me-join-meeting-form">
                    <div className="me-join-meeting-input">
                        <Input
                            type="text"
                            value={meetingNum}
                            ref={inputRef}
                            autoFocus
                            placeholder={t_common('joinMeeting.meetingNum')}
                            onPressEnter={onJoinMeeting}
                            onChange={onMeetingNumChange}
                        />
                    </div>
                    <div className="me-join-meeting-input">{passComp}</div>
                </div>
                <div className="me-join-meeting-actions">
                    <div className="me-join-meeting-menu">
                        <div className="me-join-meeting-menu-container">
                            <span
                                className={`me-join-meeting-menu-item 
                                ${state.audioDisable ? 'me-join-meeting-menu-item-disable' : ''}`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                className={`me-join-meeting-menu-item
                                    ${state.videoDisable ? 'me-join-meeting-menu-item-disable' : ''}
                                `}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                    </div>
                    <Button
                        type="primary"
                        onClick={onJoinMeeting}
                        loading={connect}
                        disabled={!isValidMeetingNum()}
                    >
                        {t_common('joinMeeting.join')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
