import BaseService from '@/attributes/BaseService';
import ListService from '../service/ListService';
import NewService from '../service/NewService';
import PageService from '../service/PageService';
import SheetService from '../service/SheetService';
import UserService from '../service/UserService';
import _PageService from '../service/_PageService';
import JoyspaceController from './JoyspaceController';
import DbService from '@/services/DbService';
import { getUser, FocusSDK, isFocusEnv } from '@/utils';
import { getUserInfo } from '../api';
import { request as joyspaceRequest } from '@/components/Joyspace/api/request';
import { User } from '../types';

class InitDBService {
    static List: any;
    static New: any;
    static Page: any;
    static Sheet: any;
    static _Page: any;
    static User: any;
    static init() {
        // FocusSDK.DbService = DbService;
        // console.log(
        //     DbService,
        //     'FocusSDK.DbServiceFocusSDK.DbServiceFocusSDK.DbServiceFocusSDK.DbService'
        // );
        this.List = this.List ? this.List : new ListService('joyspace.db', 'lists');
        this.New = this.New ? this.New : new NewService('joyspace.db', 'newlists');
        this.Page = this.Page ? this.Page : new PageService('joyspace.db', 'apis');
        this.User = this.User ? this.User : new UserService('joyspace.db', 'user');
        this.Sheet = this.Sheet ? this.Sheet : new SheetService('joyspace.db', 'sheets');
        this._Page = this._Page ? this._Page : new _PageService('joyspace.db', 'pages');
        // 初始化设置 存储员工id
        // this.initUserInfo();
    }
    // static initUserInfo() {
    //     if (!navigator.onLine) {
    //         return;
    //     }
    //     // getUserInfo();
    //     try {
    //         joyspaceRequest<User>({
    //             api: 'joyspace.user.info',
    //             path: '/v1/user/info',
    //         })
    //             .then((res) => {
    //                 JoyspaceController.initDb();
    //                 JoyspaceController.setUserInfo(res);
    //                 // this.User.setUserInfo(res);

    //                 setTimeout(() => {
    //                     console.log(
    //                         'initUserInfoinitUserInfoinitUserInfo',
    //                         JoyspaceController.getUserInfo()
    //                     );
    //                 }, 5000);
    //             })
    //             .catch((e) => {
    //                 console.log('this is sfdfsfs', e);
    //             });
    //     } catch (e) {
    //         console.log('this is sss', e);
    //     }
    // }
    static clearServiceAll() {
        this.List?.destory();
        this.New?.destory();
        this.Page?.destory();
        this.User?.destory();
        this.Sheet?.destory();
        this._Page?.destory();
        this.List = null;
        this.New = null;
        this.Page = null;
        this.User = null;
        this.Sheet = null;
        this._Page = null;
        // FocusSDK.DbService = 'undefined'; // 重要
    }
    static GetListService() {
        return this.List || new ListService('joyspace.db', 'lists');
    }
    static GetNewService() {
        return this.New || new NewService('joyspace.db', 'newlists');
    }
    static GetPageService() {
        return this.Page || new PageService('joyspace.db', 'apis');
    }
    static GetSheetService() {
        return this.Sheet || new SheetService('joyspace.db', 'sheets');
    }
    static GetPagesService() {
        return this._Page || new _PageService('joyspace.db', 'pages');
    }
    static GetUserService() {
        return this.User || new UserService('joyspace.db', 'user');
    }
}

export default InitDBService;
