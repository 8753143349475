import React, { CSSProperties } from 'react';
import './index.less';
import cloneDeep from 'lodash/cloneDeep';
import { getThumbUrl, html2Canvas } from '@/utils/chat/index';
import IconFont from '@/components/icon';
import qs from 'query-string';
import { changeEgovUrl } from '@/utils/tools';
import { Dropdown, Menu, message } from 'antd';
import { FocusSDK, isFocusEnv } from '@/utils';
export interface OwnProps {
    style: CSSProperties;
    src: string;
    width: number;
    height: number;
    hideClose?: boolean;
    siderWidth?: number;
    imgArr: string[];
    downloadFile: () => void;
    closeModal: () => void;
    onImageLoad: () => void;
}

export interface OwnState {
    innerWidth: number;
    innerHeight: number;
    imgEl: any;
    width: number;
    height: number;
    initWidth: number;
    percentage: number;
    imgStyle: any;
    visible: boolean;
}

export default class WebPreView extends React.Component<OwnProps, OwnState> {
    bigImgRef: React.RefObject<HTMLImageElement> = React.createRef();
    imgBoxRef: React.RefObject<HTMLImageElement> = React.createRef();

    constructor(props: OwnProps) {
        super(props);
        let { width: initWidth, height: initHeight, siderWidth } = props;
        const innerWidth = window.innerWidth * 0.7 - (siderWidth || 0);
        const innerHeight = window.innerHeight * 0.8;
        // console.log('图片宽高', initWidth, initHeight, innerWidth, innerHeight);
        // 初始化设置图片的宽度为 弹框宽度的 80%
        // if (initWidth > innerWidth) {
        //     initWidth = Math.ceil(innerWidth * 0.8);
        //     initHeight = Math.ceil(initWidth / (props.width / props.height));
        // }

        if (initWidth > 200 || initHeight > 200) {
            initWidth = Math.ceil(innerWidth * 0.8);
            initHeight = Math.ceil(initWidth / (props.width / props.height));
        } else {
            initHeight = Math.ceil(innerHeight * 0.2);
            initWidth = Math.ceil(initHeight / (props.height / props.width));
        }

        this.state = {
            innerWidth: innerWidth,
            innerHeight: innerHeight,
            imgEl: null,
            initWidth: initWidth,
            width: initWidth,
            height: initHeight,
            percentage: 100,
            imgStyle: { display: 'block' },
            visible: false,
        };
    }

    handleVisible = () => {
        this.setState({
            visible: false,
        });
    };
    visibleChange = (flag: boolean) => {
        this.setState({
            visible: flag,
        });
    };
    public componentDidMount() {
        const { imgArr, siderWidth, src } = this.props;
        const { width, height, imgStyle } = this.state;
        this.renewPic();
        let mr = 0;
        const imgNext: any = document.getElementById('img_next');
        const imgPrev: any = document.getElementById('img_prev');

        // imgNext.onclick = function () {
        //     mr++;
        //     if (mr === imgArr.length) {
        //         mr = 0;
        //     }
        //     const img: any = document.getElementById('pic');
        //     img.src = changeEgovUrl(getThumbUrl(imgArr[mr], width, height));
        //     img.width = width || 'auto';
        //     img.height = height || 'auto';
        //     if (mr === imgArr.length - 1) {
        //         message.warning('已是最后一张');
        //         imgNext.style.visibility = 'hidden';
        //         return;
        //     } else {
        //         imgPrev.style.visibility = 'inherit';
        //     }
        // };
        // imgPrev.onclick = function () {
        //     mr--;
        //     if (mr < 0) {
        //         mr = imgArr.length - 1;
        //     }
        //     const img: any = document.getElementById('pic');
        //     img.src = changeEgovUrl(getThumbUrl(imgArr[mr], width, height));
        //     img.width = width || 'auto';
        //     img.height = height || 'auto';
        //     if (mr === 0) {
        //         message.warning('已是第一张');
        //         imgPrev.style.visibility = 'hidden';
        //         return;
        //     } else {
        //         imgNext.style.visibility = 'inherit';
        //     }
        // };
    }

    setImgStyle = () => {
        const { innerWidth, innerHeight } = this.state;
        const img: any = this.bigImgRef.current;
        console.log('1111', img);
        const marginTop = `-${img.height / 2}px`;
        const marginLeft = `-${img.width / 2}px`;

        // console.log('setImgStyle marginTop ==>', marginTop);
        // console.log('setImgStyle marginLeft ==>', marginLeft);
        // console.log('setImgStyle width ==>', img.width);
        // console.log('setImgStyle height ==>', img.height);

        const imgStyle = cloneDeep(this.state.imgStyle);
        if (img.width < innerWidth) {
            Object.assign(imgStyle, { left: '50%', marginLeft });
            if (img.height < innerHeight) {
                Object.assign(imgStyle, { top: '50%', marginTop });
            } else {
                Object.assign(imgStyle, { top: 0, marginTop: 0 });
            }
        } else {
            Object.assign(imgStyle, { left: '0', marginLeft: 0 });
            if (img.height < innerHeight) {
                Object.assign(imgStyle, { top: '50%', marginTop });
            } else {
                Object.assign(imgStyle, { top: 0, marginTop: 0 });
            }
        }
        this.setState({ imgStyle });
        this.props.onImageLoad();
    };

    enlargePic = () => {
        if (this.state.percentage === 350) {
            return;
        }
        const { width, percentage, initWidth } = this.state;
        this.setState(
            {
                width: width + initWidth / 4,
                height: 0,
                percentage: percentage + 25,
            },
            () => {
                // Toast.info(percentage + 25 + '%', 0.6, () => {}, false);
                this.setImgStyle();
            }
        );
    };

    shrinkPic = () => {
        const { width, percentage, initWidth } = this.state;
        if (percentage - 25 < 25) {
            // Toast.info(percentage + '%', 1, () => {}, false);
            return;
        }
        this.setState(
            {
                width: width - initWidth / 4,
                height: 0,
                percentage: percentage - 25,
            },
            () => {
                // Toast.info(percentage - 25 + '%', 1, () => {}, false);
                this.setImgStyle();
            }
        );
    };

    renewPic = () => {
        this.setState(
            {
                width: this.state.initWidth,
                percentage: 100,
            },
            () => {
                // Toast.info(100 + '%', 0.6, () => {}, false);
                this.setImgStyle();
            }
        );
    };

    copyImage = () => {
        const image: any = this.bigImgRef.current;
        if (image) {
            const image_bck = image.cloneNode(true) as HTMLElement;
            document.body.appendChild(image_bck);
            image_bck.setAttribute('src', changeEgovUrl(image.src));
            image_bck.style.marginLeft = '0px';
            image_bck.style.marginTop = '0px';
            image_bck.style.zIndex = '-10';
            html2Canvas(image_bck, true)
                .then((url) => {
                    document.body.removeChild(image_bck);
                    let isOk = FocusSDK.clipboardImage(url);
                    isOk
                        ? message.success({
                              content: '复制成功',
                              duration: 1.5,
                              className: 'previewImageMessage',
                          })
                        : message.error({
                              content: '复制失败',
                              duration: 1.5,
                              className: 'previewImageMessage',
                          });
                })

                .catch(() => {
                    message.error({
                        content: '复制失败',
                        duration: 1.5,
                        className: 'previewImageMessage',
                    });
                });
        } else {
            message.error({
                content: '复制失败',
                duration: 1.5,
                className: 'previewImageMessage',
            });
        }
        this.handleVisible();
    };

    getDropdownMenu = () => {
        return (
            <Menu selectedKeys={['']}>
                <Menu.Item onClick={() => this.copyImage()}>
                    <div className="operate-menu-item">
                        <IconFont className="icon" type="iconapp_btn_copy" />
                        <span>复制</span>
                    </div>
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        this.props.downloadFile();
                        this.handleVisible();
                    }}
                >
                    <div className="operate-menu-item">
                        <IconFont className="icon" type="iconlingcunwei2" />
                        <span>另存为</span>
                    </div>
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        const { width, height, imgStyle, visible } = this.state;

        return (
            <div className="preview-web-image" id="preview-web-image" style={this.props.style}>
                {!this.props.hideClose && (
                    <div className="preview-web-image-close" onClick={this.props.closeModal}>
                        <IconFont type="iconapp_btn_file_cancel" />
                    </div>
                )}
                {isFocusEnv() ? (
                    <Dropdown
                        overlay={() => {
                            return (
                                <div className="image-box-operate">{this.getDropdownMenu()}</div>
                            );
                        }}
                        trigger={['contextMenu']}
                        visible={visible}
                        onVisibleChange={this.visibleChange}
                    >
                        <div className="preview-web-image-item" ref={this.imgBoxRef}>
                            {/* <div className="img_next" id="img_next">
                                <IconFont className="icon" type="iconwin_right" />
                            </div>
                            <div className="img_prev" id="img_prev">
                                <IconFont className="icon" type="iconwin_left" />
                            </div> */}
                            <img
                                src={changeEgovUrl(getThumbUrl(this.props.src, width, height))}
                                className="pic"
                                id="pic"
                                width={width || 'auto'}
                                height={height || 'auto'}
                                style={imgStyle}
                                ref={this.bigImgRef}
                                onLoad={this.setImgStyle}
                                alt=""
                                draggable="false"
                            />
                        </div>
                    </Dropdown>
                ) : (
                    <div className="preview-web-image-item" ref={this.imgBoxRef}>
                        {/* <div className="img_next" id="img_next">
                            next
                        </div>
                        <div className="img_prev" id="img_prev">
                            prev
                        </div> */}
                        <img
                            src={changeEgovUrl(getThumbUrl(this.props.src, width, height))}
                            className="pic"
                            id="pic"
                            width={width || 'auto'}
                            height={height || 'auto'}
                            style={imgStyle}
                            ref={this.bigImgRef}
                            onLoad={this.setImgStyle}
                            alt=""
                            draggable="false"
                        />
                    </div>
                )}

                <div className="group">
                    <button className="enlarge" onClick={this.enlargePic}>
                        <IconFont type="iconbtn_editor_amplification" />
                    </button>
                    <button className="shrink" onClick={this.shrinkPic}>
                        <IconFont type="iconbtn_editor_zoom" />
                    </button>
                    <button className="renew" onClick={this.renewPic}>
                        <IconFont type="iconbtn_editor_reduction" />
                    </button>
                    <button className="onload" onClick={this.props.downloadFile}>
                        <IconFont type="iconbtn_editor_download" />
                    </button>
                </div>
            </div>
        );
    }
}
