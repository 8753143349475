import React from 'react';
import Messages from '@/pages/chat/main';
import GlobalLayout from '@/layout/GlobalLayout';

export default () => {
    return (
        // <GlobalLayout>
        <Messages />
        // </GlobalLayout>
    );
};
