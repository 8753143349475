import React, { forwardRef } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea.d';
import styles from './index.module.less';

const { TextArea } = Input;
/**
 * 当前版本的antd的TextArea没有showCount属性，故重写了antd的TextArea组件
 */
const BaseTextArea = forwardRef((props: TextAreaProps & { showCount?: boolean }, ref: any) => {
    return (
        <div className={styles['schedule-textarea-wrapper']}>
            <TextArea {...props} ref={ref} />
            {/* <p className={styles['limit']}>
                <span>{props?.value?.toString().length}</span>
                <span>/{props.maxLength}</span>
            </p> */}
        </div>
    );
});

export default BaseTextArea;
