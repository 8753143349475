import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFocusEnv } from '@/utils';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import logger from '@/utils/logger';
import Bus from '@/utils/bus';
import { changeEgovUrl } from '@/utils/tools';
import { joinMeeting } from '@/components/Meeting/utils';
import Avatar from '@/components/Avatar';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';

const log = logger.getLogger('NoticeCard');

export default function ({ message }: { message: any }) {
    const { authInfo, currentEmployee } = useContext<GlobalContextConfig>(GlobalContext);
    const { title, extend, infox = {}, from } = message;
    const [click, setClick] = useState(false);

    const [t] = useTranslation('chat');
    const content = extend.fields;

    const mparams =
        JSON.parse(decodeURIComponent(infox.deepLink || '').split('mparam=')[1] || '{}') || {};
    const fromUser = mparams.fromUser;
    const handleClick = useCallback(() => {
        if (click) {
            return;
        }
        setClick(true);
        setTimeout(() => {
            setClick(false);
        }, 2000);

        const meetingNum =
            infox.meetingNumber || content.find((i: any) => i.key === 'meetNumber')?.value;

        if (isFocusEnv()) {
            joinMeeting(
                {
                    meetingId: meetingNum,
                    password: infox.meetingPassword || infox.password || '',
                    realName: currentEmployee.name,
                },
                true,
                infox.type === 'voice' ? false : true
            );
        } else {
            Bus.emit('chat_joymeeting_invite', { ext: infox });
        }
    }, [click, infox, content, currentEmployee.name]);

    if (extend.messageFormatVer === 'v2') {
        return (
            <ComNoticeCard
                avatarSrc={changeEgovUrl(message.sender.avatar)}
                type={extend.applicationName}
                contentList={content}
                title={title}
                loadButtonsFn={() => (
                    <div
                        className="button_item"
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        立即入会
                    </div>
                )}
            />
        );
    }

    return (
        <div className="notice-card notice-card-joymeeting">
            <div className="notice-card-header">
                <div className="notice-card-title">
                    {t('joyMeeting.create meeting')}-{title}
                </div>
                {content && <div className="notice-card-content">{content}</div>}
            </div>
            {fromUser && (
                <div className="notice-card-from">
                    <Avatar
                        style={{
                            borderRadius: '50%',
                            width: 26,
                            height: 26,
                            marginRight: 5,
                        }}
                        src={changeEgovUrl(fromUser.avatar)}
                    />
                    <span>{fromUser.name}</span>
                </div>
            )}
            <div className="notice-card-action">
                <div className="notice-card-action-view" onClick={handleClick}>
                    {t(`joyMeeting.joinMeeting`)}
                </div>
            </div>
        </div>
    );
}
