/**
 *
 * oos 工具类
 * @export
 * @param {string} color
 * @returns
 */
import { FocusSDK } from '@/utils';

export function getOssImageUrl(path: string): string {
    const ossBaseUrl =
        FocusSDK.getApplicationInfo()?.ossBaseUrl ||
        'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
    let fullPath = ossBaseUrl + path;
    return fullPath;
}

export function getTheme(theme?: string) {
    return theme || FocusSDK.getTheme() || 'default';
}

/**
 * 获取用户卡版默认头像
 * @param theme
 */
export function getUserCardDefaultAvatar(theme?: string) {
    const ossBaseUrl =
        FocusSDK.getApplicationInfo()?.ossBaseUrl ||
        'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
    // oss地址例子：https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/statics/images/theme/default/user_card_default_avatar.png
    return new URL(
        `/statics/images/theme/${getTheme(theme)}/user_card_default_avatar.png`,
        ossBaseUrl
    ).toString();
}

/**
 * 获取用户默认头像
 * @param theme
 */
export function getUserDefaultAvatar(theme?: string) {
    const ossBaseUrl =
        FocusSDK.getApplicationInfo()?.ossBaseUrl ||
        'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
    // oss地址例子： https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/statics/images/theme/default/default_avatar.png
    return new URL(
        `/statics/images/theme/${getTheme(theme)}/default_avatar.png`,
        ossBaseUrl
    ).toString();
}

export function getDefaultPageFavicon(theme?: 'string') {
    const ossBaseUrl =
        FocusSDK.getApplicationInfo()?.ossBaseUrl ||
        'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
    // return new URL(
    //     `/statics/images/theme/${getTheme(theme)}/faviconred.ico`,
    //     ossBaseUrl
    // ).toString();
    return new URL(`/statics/icons/jdwork_logo.png`, ossBaseUrl).toString();
}
