/* eslint-disable no-console */
/* eslint-disable complexity */
import { Dropdown, Menu, Modal, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { FocusSDK, isBjme, isFocusEnv } from '@/utils';
import log from '@/utils/logger';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import Session from '@jd/jdee.im.sdk/lib/es/model/Session';
import SessionType from '@jd/jdee.im.sdk/lib/es/enum/SessionType';
import IconFont from '@/components/icon';
import Employee, { EmployeeAttribute } from '@jd/jdee.im.sdk/lib/es/model/Employee';
import {
    getSessionIndex,
    isCrossPlatformGroupSession,
    isEqualEmployee,
    isExternalGroupSession,
} from '@/components/chat/utils/message';
import config, { ChatConfig, ConfigEnum } from '@/config/config';
import { getProjectDetail } from '@/api/chat';
import { ChatMessage, Group } from '@/types/chat';
import {
    checkScheduleVisible,
    checkTaskVisible,
    checkRemindVisible,
} from '@/components/chat/utils/group';
import {
    openCreateSchedule,
    openCreateTask,
    openCreateRemind,
    getFileIconByMime,
    openGroupNotClick,
    openCreateSolitaire,
} from '@/utils/chat/index';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import bus from '@/utils/bus';
import {
    buildPagePathKey,
    buildJoyspaceIconUrl,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import { PageType } from '@/components/Joyspace/const';
import { useHandleSessionTypeClick } from '@/components/chat/Session/common';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { analysisLog } from '@/utils/logAnalytics';
import { duccConfigI18 } from '@/utils/i18n';

const logger = log.getLogger('chat.Input.MoreDropdown');

// eslint-disable-next-line complexity
function MoreDropdown({
    children,
    uploadFile,
    // handleUploadImgs,
    selectedSession,
    sendJoyspaceMessage,
    closeOtherModal,
    currentEmployee,
    openCreateModal,
    openCreateRemindModal,
    launchSingleSession,
    initSelectedSession,
    allSessionList,
    changeSelectMore,
    updateMessages,
}: {
    children: any;
    uploadFile: (file: any) => void;
    selectedSession: Session;
    sendJoyspaceMessage: (data: any[]) => void;
    closeOtherModal: () => void;
    currentEmployee: Employee;
    openCreateModal: Function;
    openCreateRemindModal: Function;
    // handleUploadImgs: Function;
    launchSingleSession: Function;
    initSelectedSession: Function;
    allSessionList: Session[];
    changeSelectMore: Function;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}) {
    const [t] = useTranslation('chat');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [tooltipVisible, settooltipVisible] = useState(false);
    const [snapChatSessionVisible, setSnapChatSessionVisible] = useState(false);

    const {
        appRuntimeEnv: { joyspaceHost },
        focusSDK,
    } = useContext(GlobalContext);
    const { appRuntimeEnv, authInfo } = useContext<GlobalContextConfig>(GlobalContext);
    function handleFileClick() {
        const input = document.createElement('INPUT');
        document.body.appendChild(input);
        input.setAttribute('type', 'file');
        input.setAttribute('multiple', 'true');
        input.onchange = (ev: any) => {
            if (ev.target.files && ev.target.files.length > 0) {
                // try {
                //     let images = new DataTransfer();
                //     let others = new DataTransfer();
                //     ev.target.files.forEach((item: File) => {
                //         if (item?.type?.includes('image')) {
                //             images.items.add(item);
                //         } else if (!item?.type?.includes('image')) {
                //             others.items.add(item);
                //         }
                //     });
                //     if (images.items.length) {
                //         handleUploadImgs(images.files);
                //     }
                //     if (others.items.length) {
                //         uploadFile(others.files);
                //     }
                // } catch (e) {
                //     let images: any = [];
                //     let others: any = [];
                //     ev.target.files.forEach((item: File) => {
                //         if (item?.type?.includes('image')) {
                //             images.push(item);
                //         } else if (!item?.type?.includes('image')) {
                //             others.push(item);
                //         }
                //     });
                //     if (images.length) {
                //         handleUploadImgs(images);
                //     }
                //     if (others.length) {
                //         uploadFile(others);
                //     }
                // }
                uploadFile(ev.target.files);
            }
        };
        input.click();
        document.body.removeChild(input);
        // input.remove();
    }

    // 打开创建日程
    async function handleScheduleClick() {
        if (selectedSession.isGroup) {
            const { businessId, business } = selectedSession.info as Group;
            if (business && business?.indexOf('project') > -1) {
                // 专班群
                const [data, error] = await getProjectDetail({
                    projectId: businessId,
                    summary: true,
                });
                if (data?.calendarId) {
                    if (isFocusEnv()) {
                        openCreateSchedule({
                            title: '',
                            calendarId: data.calendarId,
                            selectedSession: selectedSession,
                        });
                    } else {
                        openCreateModal({
                            visible: true,
                            title: '',
                            frozenCalendarId: data.calendarId,
                            selectedSession: selectedSession,
                        });
                    }
                    return;
                }
            }
        }
        if (isFocusEnv()) {
            openCreateSchedule({
                title: '',
                calendarId: '',
                selectedSession: selectedSession,
            });
        } else {
            openCreateModal({
                visible: true,
                title: '',
                frozenCalendarId: '',
                selectedSession: selectedSession,
            });
        }
    }
    // 打开创建任务
    async function handleTaskClick() {
        if (selectedSession.isGroup) {
            const { businessId, business, name } = selectedSession.info as Group;
            if (business && business?.indexOf('project') > -1) {
                bus.emit('task:open-create', {
                    title: '',
                    projectId: businessId,
                    sourceName: name,
                    selectedSession,
                });
                return;
            }
        }
        bus.emit('task:open-create', {
            title: '',
            projectId: '',
            sourceName: '',
            selectedSession,
        });
        // }
    }
    // 打开创建提醒
    async function handleRemindClick() {
        // console.log(selectedSession, 'selectedSessionselectedSessionselectedSession');
        if (isFocusEnv()) {
            openCreateRemind({
                selectedSession: selectedSession,
                createData: {
                    from: 'IM',
                },
            });
        } else {
            openCreateRemindModal({
                visible: true,
                selectedSession: selectedSession,
                createData: {
                    from: 'IM',
                },
            });
        }
    }
    // 打开组接龙
    function handleSolitaireClick() {
        FocusSDK.emit(`openCreateSolitaire`, { visible: true, currentEmployee });
    }
    // 生成URL
    const caertJoySpaceLink = useCallback(
        (data: any) => {
            let content;
            if (data.pageType === PageType.Form) {
                // 低代码
                content = data.link;
                return content;
            }
            const { joyspaceHost } = appRuntimeEnv;
            const path = buildPagePathKey(data.pageType).subPath;
            const link = `${joyspaceHost}/${path}/${data.id}`;
            // const appId = authInfo.teamUserInfo.ddAppId;
            // const routerTag = getRnRouter(data.pageType);
            // content = DeeplinkEvent.strify({
            //     appId,
            //     path: DeeplinkPathEnum.Joyspace_Page,
            //     mparam: {
            //         page_id: data.id,
            //         standalone: 2,
            //         appName: appRuntimeEnv.runtimeConfig.key.includes('mebj')
            //             ? 'JDReactJoySpaceJzb'
            //             : 'JDReactJoySpaceHub',
            //         routeTag: routerTag,
            //     },
            // });
            // console.log(link, 'linklinklinklink');
            return `${link}`;
        },
        [appRuntimeEnv]
    );
    const callback = useCallback(
        ({
            status,
            callbackId,
            results,
        }: {
            status: 'ok' | 'cancel';
            callbackId: string;
            results: any;
        }) => {
            const { sessionId } = selectedSession;
            if (selectedSession.sessionId !== callbackId) {
                return;
            }
            FocusSDK.off('openJoyspaceDocSelector:result', callback);
            if (status === 'cancel') {
                return;
            }
            if (results.length === 0) {
                return;
            }
            const list = results.map((item: any) => {
                return {
                    content: '',
                    icon: item.icon,
                    title: item.title,
                    type: item.type,
                    pageType: item.pageType,
                    id: item.id,
                    permissionType: item.permissionType,
                    url: caertJoySpaceLink(item),
                };
            });
            sendJoyspaceMessage(list);
            // id: "Vbo4v938n9ODRQzsmnmI"
            // link: ""
            // linkH5: "jdme://jm/sys/rn?mparam=%7B%22appName%22%3A%22201909020601%22%2C%22standalone%22%3A2%2C%22page_id%22%3A%22Vbo4v938n9ODRQzsmnmI%22%2C%22routeTag%22%3A%22file_view%22%7D"
            // pageType: 6
            // permissionType: 1
            // title: "V1.8.0工作任务.xls"
            // type: "application/vnd.ms-excel"
            // {
            //     "content": "",
            //     "icon": "https://storage.360buyimg.com/hub-static/images/tableIcon.png",
            //     "pageType": 6,
            //     "title": "每日进度汇报表.xls",
            //     "type": "application/vnd.ms-excel",
            //     "url": "https://joyspace.jzb.beijing.gov.cn/file/fTga9iaORNho7HgYUuvq?jdme_router=jdme%3A%2F%2Fjm%2Fsys%2Frn%3Fmparam%3D%257B%2522appName%2522%253A%2522JDReactJoySpaceHub%2522%252C%2522standalone%2522%253A2%252C%2522page_id%2522%253A%2522fTga9iaORNho7HgYUuvq%2522%252C%2522routeTag%2522%253A%2522file_view%2522%257D"
            // }
            // sendJoyspaceMessage(data);
        },
        [selectedSession, sendJoyspaceMessage, caertJoySpaceLink]
    );
    useEffect(() => {
        FocusSDK.on('openJoyspaceDocSelector:result', callback);
        return () => {
            FocusSDK.off('openJoyspaceDocSelector:result', callback);
        };
    }, [callback]);
    const canOpenSnaptChat = useMemo(() => {
        if (selectedSession.isGroup || selectedSession.isNotice || selectedSession.isSecret) {
            return false;
        }
        const info = selectedSession.info as Employee;
        if (isEqualEmployee(info, currentEmployee)) {
            return false;
        }
        return true;
    }, [selectedSession, currentEmployee]);
    // 打开文档
    function handleJoySpaceClick() {
        if (isFocusEnv()) {
            logger.debug('handleJoySpaceClick', selectedSession, currentEmployee);
            const { sessionType, sessionId, info } = selectedSession;
            let pin = sessionId;
            let teamId = '';
            let app = '';
            if (sessionType === SessionType.SINGLE || sessionType === SessionType.SECRET_SINGLE) {
                const employee = info as EmployeeAttribute;
                if (!employee) {
                    logger.error('handleJoySpaceClick can not get uid', { sessionType });
                    return;
                }
                pin = employee.userId;
                teamId = employee.teamId;
                app = employee.app;
            }
            if (sessionType === SessionType.GROUP) {
                teamId = currentEmployee.teamId;
                app = currentEmployee.app;
            }
            FocusSDK.emit('openJoyspaceDocSelector', { callbackId: selectedSession.sessionId });
            // FocusSDK.shareDocuments(
            //     {
            //         pin,
            //         sessionType,
            //         teamId,
            //         app,
            //     },
            //     (data: any) => {
            //         logger.debug('shareDocuments result', data);
            //         sendJoyspaceMessage(data);
            //     }
            // );
        } else {
            focusSDK.emit('openJoyspaceCloudSelector', {
                callbackId: 'chat-cloud-file',
                callback: (data: any) => {
                    console.log('data---', data);
                    const { joyspaceApiHost } = appRuntimeEnv;
                    const list = data.map((item: any) => {
                        return {
                            ...item,
                            content: '',
                            url: caertJoySpaceLink(item),
                        };
                    });
                    sendJoyspaceMessage(list);
                },
            });
        }
    }
    // 打开组通知
    function handleGroupNotClick() {
        if (selectedSession.isGroup) {
            let visible = true;
            FocusSDK.emit(`openGroupNotClick`, {
                selectedSession: selectedSession,
                visible: visible,
            });
        }
    }
    function dropdownVisibleChange(visible: boolean) {
        // 点击+号 关闭常用语和@modal
        closeOtherModal();
        if (visible) {
            analysisLog('Xtbg_Msg_Info_MsgDialog', 'MoreService');
            settooltipVisible(false);
        }
        setDropdownVisible(visible);
    }
    function tooltipVisibleChange(visible: boolean) {
        if (dropdownVisible && visible) {
            return;
        }
        settooltipVisible(visible);
    }

    function findSnapChatSession() {
        const index = getSessionIndex(allSessionList, {
            sessionId: `${selectedSession.sessionId}:secret`,
        });
        return index > -1;
    }

    const openConfirmModal = () => {
        const isExistInSessionList = findSnapChatSession();
        if (isExistInSessionList) {
            prompt({
                title: t('change-session.secret-chat-title'),
                icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                onOk: async () => {
                    handleChangeSession();
                },
                content: t('change-session.secret-chat-content'),
                okText: t('determine'),
                cancelText: t('cancelText'),
            });
        } else {
            setSnapChatSessionVisible(true);
        }
    };

    const handleChangeSession = useHandleSessionTypeClick({
        selectedSession,
        launchSingleSession,
        initSelectedSession,
        updateMessages,
        changeSelectMore,
        closeConfirmModal: () => setSnapChatSessionVisible(false),
    });

    const menu = (
        <Menu className="chat-input-more-menu">
            {config[ChatConfig.CHAT_MESSAGE_INPUT_FILE] && (
                <Menu.Item
                    onClick={handleFileClick}
                    clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|UploadDoc"
                >
                    <div className="more-menu-operate-item">
                        <IconFont
                            className="chat-input-more-menu-icon iconic_app_more_file"
                            type="iconic_app_more_file"
                        />
                        <span className="menu-text">{isBjme() ? t('local-file') : t('file')}</span>
                    </div>
                </Menu.Item>
            )}
            {!selectedSession.isSecret &&
                config[ChatConfig.CHAT_MESSAGE_INPUT_JOYSPACE] &&
                !isCrossPlatformGroupSession(selectedSession) &&
                // 解决移动端基于日程创建的群组，在pc端没有云文件入口的bug
                // !isExternalGroupSession(selectedSession) &&
                !(
                    selectedSession.isSingle &&
                    (selectedSession.info as EmployeeAttribute)?.app !== currentEmployee.app
                ) && (
                    <Menu.Item
                        onClick={handleJoySpaceClick}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|CloudDoc"
                    >
                        <div className="more-menu-operate-item">
                            <IconFont
                                className="chat-input-more-menu-icon iconic_app_more_cloudspace"
                                type="iconic_app_more_cloudspace"
                            />
                            <span className="menu-text">
                                {t('cloud-space', {
                                    joyspace: duccConfigI18.joyspace,
                                })}
                            </span>
                        </div>
                    </Menu.Item>
                )}
            {!selectedSession.isSecret &&
                config[ChatConfig.CHAT_MESSAGE_INPUT_TASK] &&
                checkTaskVisible({ selectedSession }) &&
                config[ConfigEnum.EXTERNAL_CONTACTS_CREAT_TASK] && (
                    <Menu.Item
                        onClick={handleTaskClick}
                        // clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|Task"
                        clstag={`pageclick|keycount|xtbg_add_task|xtbg_add_task_createtask_source${
                            selectedSession.isGroup ? '1' : '2'
                        }`}
                    >
                        <div className="more-menu-operate-item">
                            <IconFont
                                className="chat-input-more-menu-icon iconic_app_more_task"
                                type="iconic_app_more_task"
                            />
                            <span className="menu-text">{t('task_distribution')}</span>
                        </div>
                    </Menu.Item>
                )}
            {!selectedSession.isSecret &&
                config[ChatConfig.CHAT_MESSAGE_INPUT_SCHEDULE] &&
                checkScheduleVisible({ selectedSession }) && (
                    <Menu.Item
                        onClick={handleScheduleClick}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|Schedule"
                    >
                        <div className="more-menu-operate-item">
                            <IconFont
                                className="chat-input-more-menu-icon iconic_app_more_schedule"
                                type="iconic_app_more_schedule"
                            />
                            <span className="menu-text">{t('schedule_share')}</span>
                        </div>
                    </Menu.Item>
                )}
            {!selectedSession.isSecret &&
                config[ChatConfig.CHAT_MESSAGE_INPUT_REMIND] &&
                checkRemindVisible({ selectedSession }) && (
                    <Menu.Item
                        onClick={handleRemindClick}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|Urge"
                    >
                        <div className="more-menu-operate-item">
                            <IconFont
                                className="chat-input-more-menu-icon iconic_app_more_remind"
                                type="iconic_app_more_remind"
                            />
                            <span className="menu-text">{t('warn')}</span>
                        </div>
                    </Menu.Item>
                )}

            {config[ChatConfig.CHAT_MESSAGE_INPUT_GROUPTZ] &&
                checkRemindVisible({ selectedSession }) &&
                selectedSession.isGroup && (
                    <Menu.Item
                        onClick={handleGroupNotClick}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|GroupNotice"
                    >
                        <div className="more-menu-operate-item">
                            <IconFont
                                // style={{ verticalAlign: 'middle' }}
                                className="chat-input-more-menu-icon iconic_app_more_notification"
                                type="iconic_app_more_notification"
                            />
                            <span className="menu-text">{t('group_tz')}</span>
                        </div>
                    </Menu.Item>
                )}

            {/* 组接龙 */}
            {config[ChatConfig.CHAT_MESSAGE_INPUT_SOLITAIRE] &&
                checkRemindVisible({ selectedSession }) &&
                selectedSession.isGroup === true && (
                    <Menu.Item onClick={handleSolitaireClick}>
                        <div className="more-menu-operate-item">
                            <IconFont
                                style={{ color: (window as any).styleSass.primaryColor }}
                                className="chat-input-more-menu-icon iconapp_btn_schedule"
                                type="iconic_app_btn_groupsolitaire1"
                                clstag="pageclick|keycount|focus_chat_01_1602584371145|47"
                            />
                            <span className="menu-text">{t('solitaire')}</span>
                        </div>
                    </Menu.Item>
                )}

            {/* TODO: 单聊且不是密聊and是好友关系and不是和自己聊天 */}
            {process.env.REACT_APP_SNAPCHAT &&
                canOpenSnaptChat &&
                config[ChatConfig.CHAT_MESSAGE_INPUT_SNAPCHAT] && (
                    <Menu.Item
                        onClick={() => openConfirmModal()}
                        clstag="pageclick|keycount|Xtbg_Msg_Info_MsgDialog|SnapChat"
                    >
                        <div className="more-menu-operate-item">
                            <IconFont
                                className="chat-input-more-menu-icon iconic_app_im_rurn_after_reading burnafterreading_facet"
                                type="iconic_app_im_burnafterreading_facet"
                            />
                            <span className="menu-text">{t('snapchat')}</span>
                        </div>
                    </Menu.Item>
                )}
        </Menu>
    );

    const snapChatSessionTitle = (
        <div>
            <IconFont type="iconic_failure" style={{ color: '#F96137' }} />
            <span style={{ marginLeft: 6 }}>提示</span>
        </div>
    );

    return (
        <>
            <Dropdown
                placement="topCenter"
                overlay={menu}
                className="chat-input-more-dropdown"
                onVisibleChange={dropdownVisibleChange}
                trigger={['click']}
                overlayClassName="more-dropdown"
            >
                <Tooltip
                    title={t('more')}
                    visible={tooltipVisible}
                    onVisibleChange={tooltipVisibleChange}
                    placement="bottom"
                >
                    {children}
                </Tooltip>
            </Dropdown>
            <Modal
                visible={snapChatSessionVisible}
                width={360}
                title={snapChatSessionTitle}
                okText={t('button.ok')}
                cancelText={t('button.cancel')}
                wrapClassName="snapChatSessionModal"
                centered={true}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={() => setSnapChatSessionVisible(false)}
                onOk={() => {
                    handleChangeSession();
                }}
            >
                <div className="snapChatSessionContent">
                    <h4>{t('change-session.snap-chat-title')}</h4>
                    <p>
                        <span>*</span>
                        {t('change-session.snap-chat-content')}
                    </p>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, currentEmployee, allSessionList } = chat as ChatState;
    return { selectedSession, currentEmployee, allSessionList };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
        openCreateRemindModal(data: any) {
            dispatch({ type: 'supervise/setCreateModalVisible', payload: data });
        },
        launchSingleSession(data: { employee: Employee }) {
            dispatch({ type: 'chat/launchSingleSession', payload: data });
        },
        initSelectedSession(data: { selectedSession: Session }) {
            dispatch({ type: 'chat/initSelectedSession', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDropdown);
