/**
 * 历史记录搜索
 * @param props
 * @constructor
 */
import React, { useEffect } from 'react';
import './index.less';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { Employee, Session, SessionType } from '@/types/chat';
import { UserModel } from '@/models/user';
import SearchMessage from './Message';
import SearchFile from './File';
import SearchMedia from './Media';
import GroupRoster from '@jd/jdee.im.sdk/lib/es/model/Roster';
import Context from '@/components/chat/context';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { DrawTypeEnum } from '@/components/chat/enum';
import { analysisLog } from '@/utils/logAnalytics';
import { getSafariVer } from '@/utils';
const { TabPane } = Tabs;
interface Props {
    selectedSession: Session;
    historySearchOpen: boolean;
    onClickClose?: () => void;
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
}

type IProps = Readonly<Props>;
function MessageHistorySearch(props: IProps) {
    let {
        selectedSession,
        historySearchOpen,
        onClickClose,
        updateSessionMessageMid,
        updateSessionMessagesTimestamp,
    } = props;
    const { currentEmployee, onToggleSetup } = React.useContext(Context);
    const { t } = useTranslation('chat');
    const { appRuntimeEnv } = React.useContext<GlobalContextConfig>(GlobalContext);
    const isGovEnv = ['mebj', 'jgswy', 'mebjJZB'].includes(appRuntimeEnv.runtimeConfig.key);

    useEffect(() => {
        if (
            getSafariVer() === 15 &&
            [SessionType.SINGLE, SessionType.SECRET_SINGLE, SessionType.GROUP].includes(
                selectedSession.sessionType
            )
        ) {
            const qlClipBoard: any = document.getElementsByClassName('ql-clipboard')[0];
            if (qlClipBoard) {
                qlClipBoard.style.display = historySearchOpen ? 'none' : '';
            }
            const leftSide: any = document.getElementById('contentFrameLeftSide');
            if (leftSide) {
                leftSide.style.position = historySearchOpen ? 'static' : 'relative';
            }
            const msgBox: any = document.getElementById('message-box-container-scroll');
            if (msgBox) {
                msgBox.style.overflowY = historySearchOpen ? 'hidden' : 'overlay';
            }
        }
    }, [selectedSession.sessionType, historySearchOpen]);

    return (
        <Drawer
            className="search-record-drawer"
            getContainer={document.querySelector('#chat-right-container-change-set') as HTMLElement}
            placement="right"
            width="560px"
            height="100%"
            mask={true}
            maskStyle={{
                backgroundColor: 'inherit',
            }}
            destroyOnClose={true}
            closable={false}
            onClose={() => {
                if (onToggleSetup) onToggleSetup(DrawTypeEnum.search);
            }}
            visible={historySearchOpen}
            // style={{ position: 'absolute', right: 50 }}
        >
            <Tabs
                defaultActiveKey="text"
                className="search-record-tabs"
                onTabClick={(key) => {
                    analysisLog('Xtbg_Msg_Search', key);
                }}
            >
                <TabPane tab={t('message_record')} key="text">
                    <SearchMessage
                        currentSession={selectedSession}
                        onClickClose={onClickClose}
                        currentEmployee={currentEmployee}
                    />
                </TabPane>
                <TabPane
                    tab={isGovEnv && selectedSession?.isGroup ? '文件' : t('message_attachment')}
                    key="attachment"
                >
                    <SearchFile
                        currentSession={selectedSession}
                        currentEmployee={currentEmployee}
                    />
                </TabPane>
                <TabPane tab={t('message_image_video')} key="media">
                    <SearchMedia
                        currentSession={selectedSession}
                        updateSessionMessageMid={updateSessionMessageMid}
                        updateSessionMessagesTimestamp={updateSessionMessagesTimestamp}
                    />
                </TabPane>
            </Tabs>
        </Drawer>
    );
}
function mapStateToProps({ chat }: any) {
    const { selectedSession } = chat as ChatState;
    return { selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            return dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        updateSessionMessagesTimestamp(data: { timestamp: number }) {
            dispatch({ type: 'chat/updateSessionMessagesTimestamp', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageHistorySearch);
