import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { IPage, ICategory, ReadyState, listDataType } from './types';
import { Checkbox } from 'antd';
import List from './List';
import Column from './Column';
import * as util from '../../util';
import Filter from './Filter';
import ContextMenu, { IContextRender } from './ContextMenu';
import BatchConsole, { BatchRender } from './BatchConsole';
import {
    IGetPagesParams,
    IGetPagesResult as IPagesResult,
    zhuanbanSearch,
    IGetFileParams,
} from '../../api/pages';
import { toPermissionsMap } from '../../permissions';
import PageLink from '../PageLink';
import context, { useTranslation } from '../../context';
import events from '../../context/events';
import noDataPicture from '@/assets/img/no-content.png';
import Loading from '@/components/Loading';
import { setIconUrl } from '@/components/Joyspace/util';
import { timeFilter, Sort, Order } from '../../components/List/Filters/config';
import { IHeader } from './Headers';
import './index.less';
import BatchControl from './BatchControl';
import { promises } from 'dns';
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
import { changeEgovUrl } from '@/utils/tools';
import CollectionPopover from '../../pages/Collections/Popover';
import Tags, { TagType } from '../Tags';
import { FileType, OpenFolderStatus } from '../../types';
import { menu, MenuEnum } from './SharesFilters/config';
import ShareIcon from './ShareIcon';
// 内置列启动索引
export enum ColumnIndex {
    Title = 1 << 0,
    Owner = 1 << 1,
    Status = 1 << 2,
    Time = 1 << 3,
    PublishStatus = 1 << 4,
    Operate = 1 << 30,
    Page_Home_Recent = Title | Time | Operate,
    Page_Home_Shares = Title | Time | Owner | Operate,
    Page_Home_MyShare = Title | Status | Time | Operate,
    Page_MySpace = Title | Time | Operate,
    Page_TeamSpace = Title | Owner | Time | Operate,
    Page_TeamSpace_Archive = Title | Owner | Time,
    Page_Trashes = Title | Time | Operate,
    Page_Collections = Title | Time | Operate,
    Page_Follow = Title | Time,
    Page_Zhuanban = Title | Owner | Time | PublishStatus | Operate,
    Page_Zhuanban_archive = Title | Owner | Time | PublishStatus,
}

export enum Mode {
    Nolink = 1,
}

type Request = (params: IGetPagesParams) => Promise<IPagesResult>;
type RequestCategories = (spaceId: string, params: IGetFileParams) => Promise<ICategory[]>;

export enum OrderMenu {
    UPDATE = 'updated_at',
    CREATE = 'created_at',
    SHARED = 'shared_at',
}

export interface IProps<T = IPage> {
    // 内置列
    columns: number;
    // 启动列表头部
    disableHeader?: boolean;
    // 列表顶部批量按钮
    batch?: boolean;
    // 获取列表文件请求
    onRequest: Request;
    // 获取文件夹网络请求
    onRequestCategories?: RequestCategories;
    onReady?: (props: { getPages: () => T[]; setPages: (pages: T[]) => void }) => void;
    // 分页大小
    pageSize?: number;
    // 开启时间排序
    orderOptions?: boolean;
    // 文件上下文菜单
    context?: IContextRender<T>;
    batchContext?: IContextRender<(ICategory | T)[]>;
    // 文件夹上下文菜单
    categoryContext?: IContextRender<ICategory>;
    // 列表自定义列扩展
    children?: React.ReactElement | React.ReactElement[];
    mode?: Mode;
    zhuanbanCallback?: (value: { id: string; name: string }) => void;
    folderId?: string;
    searchText?: string;
    zhuanban?: { teamId: string; spaceId: string; projectId: string };
    noDataText?: {
        text: string;
        url: string;
    };
    name?: string;
    defaultActiveKey?: MenuEnum;
}

// 给所有列表添加默认自增索引，用于恢复默认顺序
let __nextIndex = 0;

let lastTime = '';
let type = 0;
export default function __default(props: IProps) {
    const lang = useTranslation();
    const {
        onRequest,
        onRequestCategories,
        onReady,
        mode = 0,
        folderId,
        zhuanbanCallback,
        searchText,
        zhuanban,
        noDataText,
        name = '',
        batch = false,
        defaultActiveKey = MenuEnum.SHARES,
    } = props;
    const __request = React.useRef(0);
    const [selecteds, select] = React.useState(new Set<IPage | ICategory>());
    const [pages, setPages] = React.useState<IPage[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [categories, setCategories] = React.useState<ICategory[]>([]);
    const [status, setStatus] = React.useState(ReadyState.complete);
    const [categorieLoading, setCategorieLoading] = React.useState(ReadyState.complete);
    const [isButtom, setIsButtom] = React.useState(false);
    const [isLoadMore, setLoadMore] = React.useState(true);
    const { search } = useLocation();
    const [hideMore, setMore] = React.useState(true);
    const searchs = qs.parse(search) as { [key: string]: string };
    const { currentRouterInfo } = React.useContext(context);
    const [categoryId, setCategoryId] = React.useState<string | null>(
        currentRouterInfo?.id || null
    );
    const pageCount = React.useRef(0);
    const [headers, setHeaders] = React.useState<IHeader | null>(null);

    const [tableHeaderKey, setTableHeaderKey] = React.useState('');
    const history = useHistory();
    React.useMemo(() => {
        const item: any = menu.find((item) => item.type === defaultActiveKey);
        if (item) {
            setTableHeaderKey(item.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultActiveKey]);
    useEffect(() => {
        // 添加路由监听函数
        history.listen((historyLocation: any) => {
            pageCount.current = 0;
            params.current.start = 0;
            params.current.length = props.pageSize ?? 10;
            // params.current.sort = Sort.UpDateTime;
            // params.current.order = Order.DESC;
            // lastTime = key; // 记录行为
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);
    const onChangeHeader = useCallback(
        (headers: IHeader) => {
            // console.log(headers, 'headersheadersheaders');
            setHeaders(headers);
        },
        [setHeaders]
    );
    useEffect(() => {
        events.on('onChangeHeader', onChangeHeader);
        return () => {
            events.off('onChangeHeader', onChangeHeader);
        };
    }, [onChangeHeader]);
    // const categoryId = folderId ?? searchs.categoryId;

    const params = React.useRef<IGetPagesParams>({
        categoryId: currentRouterInfo?.id || '',
        length: props.pageSize ?? 10,
        sort: Sort.UpDateTime,
        start: 0,
        order: timeFilter()[0].order,
    });
    useEffect(() => {
        if (currentRouterInfo !== null) {
            setCategoryId(currentRouterInfo.id);
        } else {
            setCategoryId(null);
        }
    }, [currentRouterInfo]);
    // 无参数调用刷新页面
    // 有回调参数调用可以筛选数据
    const loadPages = React.useCallback(
        (callback?: (data: IPagesResult) => void) => {
            setStatus(ReadyState.loading);
            const curParams = params.current;
            if (!callback) {
                curParams.start = 0;
            }
            const sTime = Date.now();
            const ready = (data: IPagesResult) => {
                let timeout = Date.now() - sTime;
                setTimeout(
                    () => {
                        // 兼容接口里 data.pages 可能为null
                        const pages = data.pages || [];
                        // 兼容data.total 接口里可能不准确
                        const total = data.total || pages.length;
                        // 返回结果数为空，或者返回结果数低于分页大小隐藏“加载更多”
                        pageCount.current += pages.length;
                        // console.log(pageCount.current, total, 'pageCount.currentpageCount.current');
                        setMore(pageCount.current < total);
                        pages.forEach((page) => {
                            page.__index = page.__index ?? __nextIndex++;
                            if (page.permissions) {
                                page.perms = toPermissionsMap(page.permissions);
                            }
                        });
                        if (callback) {
                            callback(data);
                        } else {
                            setPages(pages);
                            setTotal(total);
                        }
                        setStatus(ReadyState.complete);
                        setTimeout(() => {
                            events.emit('onChangeMenu', {
                                sort: params.current.sort,
                                order: params.current.order,
                            });
                        }, 400);
                        if (!__request.current++) {
                            setTimeout(() => {
                                onReady?.({
                                    getPages: () => {
                                        return pages;
                                    },
                                    setPages,
                                });
                            });
                        }
                        setLoadMore(false);
                    },
                    // loading 至少停留500毫秒，视觉上不会显得闪跳
                    timeout < 300 ? 300 : 0
                );
            };
            if (searchText) {
                zhuanbanSearch({
                    search: searchText,
                    space_ids: zhuanban?.spaceId ? [zhuanban?.spaceId] : [],
                    space_category_ids: folderId ? [folderId] : [],
                })
                    .then((data) =>
                        data?.data
                            ? { total: data.total, pages: data.data }
                            : { total: 0, pages: [] }
                    )
                    .then(ready);
            } else {
                const useCurParams = {
                    ...curParams,
                    categoryId: curParams.categoryId === 'myspace' ? '' : curParams.categoryId,
                };
                setLoadMore(true);
                onRequest(useCurParams)
                    .then((data) => (data?.pages ? data : { total: 0, pages: [] }))
                    .then(ready);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRequest, onReady, searchText, zhuanban, folderId]
    );
    // 恢复默认顺序
    const restoreOrder = React.useCallback((data) => {
        const newPages = [...data.pages];
        newPages.sort((a, b) => (a.__index < b.__index ? -1 : 1));
        setPages(newPages);
        setTotal(data.total);
    }, []);

    const reload = React.useCallback(() => {
        pageCount.current = 0;
        select(new Set());
        setPages([]);
        setCategories([]);
        loadPages();
        setIsButtom(false);
        if (categoryId === null) {
            setCategorieLoading(ReadyState.complete);
            return;
        }
        if (!searchText) {
            setCategorieLoading(ReadyState.loading);
            onRequestCategories?.(categoryId, {
                order: params.current.order,
                sort: params.current.sort,
            })
                .then((data) => {
                    data && setCategories(data);
                })
                .finally(() => {
                    setTimeout(() => {
                        events.emit('onChangeMenu', {
                            sort: params.current.sort,
                            order: params.current.order,
                        });
                    }, 400);
                    setCategorieLoading(ReadyState.complete);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);
    // 筛选参数
    useEffect(() => {
        events.on('onChangeFilter', (item, key) => {
            params.current.start = 0;
            params.current.length = props.pageSize ?? 10;
            params.current.sort = item.sort;
            params.current.order = item.order;
            lastTime = key; // 记录行为
            reload();
        });
        return () => {
            events.off('onChangeFilter');
        };
    }, [props, reload]);
    //
    React.useEffect(() => {
        if (categoryId !== null) {
            params.current.categoryId = categoryId;
            select(new Set());
            setPages([]);
            setCategories([]);
        }
    }, [categoryId]);

    React.useEffect(() => {
        if (type !== Number(currentRouterInfo?.type)) {
            type = Number(currentRouterInfo?.type);
            params.current.sort = Sort.UpDateTime;
            params.current.order = Order.DESC;
        }
        reload();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const listener = React.useCallback(
        (page: IPage) => {
            setPages([page, ...pages]);
        },
        [pages]
    );
    const listener2 = React.useCallback(
        (page: IPage) => {
            if (
                (!folderId && page.space_category_id === '') ||
                (folderId && page.space_category_id === folderId)
            ) {
                setPages([page, ...pages]);
            }
        },
        [pages, folderId]
    );

    React.useEffect(() => {
        if (zhuanban?.projectId) {
            events.on(`list.add:${zhuanban?.projectId}`, listener2);
        } else if (!['recent', 'homeMyshare', 'homeshare'].includes(name)) {
            events.on('list.add', listener);
        }

        return () => {
            if (zhuanban?.projectId) {
                events.off(`list.add:${zhuanban?.projectId}`, listener2);
            } else {
                events.off('list.add', listener);
            }
        };
    }, [zhuanban, listener, listener2, name]);
    React.useEffect(() => {
        // function shortcutChange() {
        //     select(new Set());
        // }
        function doRelod() {
            reload();
        }
        // events.on('shortcut.change', shortcutChange);
        events.on('list.reload', doRelod);
        return () => {
            // events.off('shortcut.change', shortcutChange);
            events.off('list.reload', doRelod);
        };
    }, [reload]);
    const removeOrSetData = useCallback(
        (changeData: any[], type: string, insureHasPage?: boolean) => {
            // console.log('removeOrSetData111', changeData, categories, pages);
            select(new Set());
            if (changeData.length === 0) return;
            const dataMap: {
                [key: string]: any;
            } = {};
            let hasCategory = 0;
            let hasPage = insureHasPage ? 1 : 0;
            changeData.forEach((item) => {
                dataMap[item.id] = item;
                item.page_type > 0 ? hasPage++ : hasCategory++;
            });

            if (type === 'delete') {
                if (hasCategory) {
                    const useCategories = categories.filter((category) => {
                        return category.id in dataMap ? false : true;
                    });
                    setCategories(useCategories);
                }

                if (hasPage) {
                    const usePages = pages.filter((page) => {
                        return page.id in dataMap ? false : true;
                    });
                    setPages(usePages);
                }
                return;
            }
            if (type === 'setData') {
                if (hasCategory) {
                    const useCategories = categories.map((category) => {
                        if (category.id in dataMap) {
                            return { ...category, ...dataMap[category.id] };
                        }
                        return category;
                    });
                    setCategories(useCategories);
                }

                if (hasPage) {
                    const usePages = pages.map((page) => {
                        if (page.id in dataMap) return { ...page, ...dataMap[page.id] };
                        return page;
                    });
                    setPages(usePages);
                }
                return;
            }
        },
        [categories, pages]
    );
    React.useEffect(() => {
        events.on('list.removeOrSetData', removeOrSetData);

        return () => {
            events.off('list.removeOrSetData', removeOrSetData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeOrSetData]);

    if (
        (status === ReadyState.loading && pages?.length === 0) ||
        (categorieLoading === ReadyState.loading && categories?.length === 0)
    ) {
        return (
            <div className="joyspace-list-table-loading">
                <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                    <Loading />
                </div>
            </div>
        );
    }

    function loadMoreCallback() {
        // console.log(isButtom, 'isButtomisButtomisButtom');
        if (isButtom) {
            return;
        }
        params.current.start = pages.length;
        loadPages((result) => {
            // 兼容接口data.pages可能为null
            const _pages = result.pages || [];
            setPages((pages) => {
                const list = [...pages, ..._pages];
                if (list.length >= result.total) {
                    setIsButtom(true);
                } else {
                    setIsButtom(false);
                }
                return list;
            });
            // eslint-disable-next-line no-debugger
            // debugger;
            // console.log(pages, 'pages====>');
            // 兼容接口data.total可能永远为0
            setTotal(result.total || _pages.length);
        });
    }
    const onCreatRouter = function (item: any) {
        events.emit('onCreatRouter', {
            fullName: item.full_name,
            fullPath: item.full_path,
            description: item.description,
            permissionType: item.permission_type,
        });
    };
    const onChangeTableHeaderKey = (key: string) => {
        // console.log(key);
        setTableHeaderKey(key);
    };
    function renderList() {
        if (pages.length === 0 && categories.length === 0) {
            return (
                <div className="joyspace-list-table" id="joyspace-list-table">
                    <div className="pm-doc-nodata">
                        <img src={changeEgovUrl(noDataText?.url ?? noDataPicture)} alt="" />
                        <p>{noDataText?.text ?? '暂无数据'}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <List
                    headers={headers}
                    source={pages}
                    setSource={setPages}
                    name={name}
                    columns={props.columns}
                    disableHeader={props.disableHeader}
                    selecteds={selecteds}
                    categories={categories}
                    setCategories={setCategories}
                    reload={reload}
                    batch={batch}
                    select={select}
                    onCreatRouter={onCreatRouter}
                    total={total}
                    isShowLoadMore={hideMore}
                    loadMoreCallback={loadMoreCallback}
                    status={status}
                    onChangeTableHeaderKey={onChangeTableHeaderKey}
                    defaultActiveKey={defaultActiveKey}
                    isLoadMore={isLoadMore}
                >
                    {props.batch && (
                        <Column
                            name="select"
                            render={({ data }) => (
                                <Checkbox
                                    checked={selecteds.has(data as any)}
                                    onChange={() => {
                                        const _set = new Set(selecteds);
                                        if (_set.has(data as any)) {
                                            _set.delete(data as any);
                                        } else {
                                            _set.add(data as any);
                                        }
                                        select(_set);
                                    }}
                                />
                            )}
                            categoryRender={({ data }) => (
                                <Checkbox
                                    checked={selecteds.has(data as any)}
                                    onChange={() => {
                                        const _set = new Set(selecteds);
                                        if (_set.has(data as any)) {
                                            _set.delete(data as any);
                                        } else {
                                            _set.add(data as any);
                                        }
                                        select(_set);
                                    }}
                                />
                            )}
                        />
                    )}

                    <Column
                        index={ColumnIndex.Title}
                        header="标题"
                        /*                     header={
                    <Filter
                        restoreOrder={() => restoreOrder(pages)}
                        menu={[
                            { text: lang('c.list.filter.type.all'), value: 0 },
                            { text: lang('c.list.filter.type.doc'), value: 1 },
                            { text: lang('c.list.filter.type.spreadsheet'), value: 4 },
                        ]}
                        onChange={(o) => {
                            const newPages = [...pages];
                            newPages.sort((a, b) => {
                                if (a.page_type === o.value) {
                                    return -1;
                                } else if (b.page_type === o.value) {
                                    return 1;
                                }
                                return 0;
                            });
                            setPages(newPages);
                            setTotal(total);
                        }}
                    />
                } */
                        name="title"
                        render={({ data }) => (
                            <>
                                {data.icon_url ? (
                                    <img data-icon src={changeEgovUrl(data.icon_url)} alt="" />
                                ) : data.origin_type === listDataType.FOLDER ? (
                                    <ShareIcon isShared={data.isShared || false} />
                                ) : null}
                                <PageLink page={data} nolink={!(mode & Mode.Nolink)}>
                                    {/* {data.published_by && !zhuanbanCallback && !folderId && (
                                        <span data-published>{lang('c.list.published')}</span>
                                    )} */}
                                    {data.open === OpenFolderStatus.OPEN && (
                                        <Tags TagType={TagType.Open} />
                                    )}
                                    {name !== 'collections' && (
                                        <span style={{ marginLeft: 10 }}>
                                            {data.isCollected && <Tags TagType={TagType.Follow} />}
                                        </span>
                                    )}
                                </PageLink>
                            </>
                        )}
                        categoryRender={({ data }) => (
                            <Link
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                }}
                                to={{ search: `?categoryId=${data.id}` }}
                                onDragStart={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                            >
                                <ShareIcon isShared={data.isShared || false} />
                                <span
                                    style={{
                                        flex: 1,
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        marginRight: data.open === OpenFolderStatus.OPEN ? 10 : 0,
                                    }}
                                >
                                    {data.name}
                                </span>
                                {data.open === OpenFolderStatus.OPEN && (
                                    <Tags TagType={TagType.Open} />
                                )}
                                {name !== 'collections' && (
                                    <span style={{ marginLeft: 10 }}>
                                        {data.isCollected && <Tags TagType={TagType.Follow} />}
                                    </span>
                                )}
                            </Link>
                        )}
                    />

                    {/* <Column
                        index={ColumnIndex.PublishStatus}
                        header="发布状态"
                        name="PublishStatus"
                        render={({ data }) => (
                            <span className="joyspace-publishStatus">
                                {data.status === 2 ? (
                                    <span data-publish>已发布</span>
                                ) : (
                                    <span>未发布</span>
                                )}
                            </span>
                        )}
                    /> */}

                    <Column
                        index={ColumnIndex.Owner}
                        name="owner"
                        // header={folderId ? '创建者' : '所有者'}
                        render={({ data }) => {
                            return (
                                <span style={{ maxWidth: 70, display: 'flex' }}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        title={
                                            tableHeaderKey === MenuEnum.PATH
                                                ? data?.location?.fullName
                                                : tableHeaderKey === MenuEnum.SHARES_TYPE
                                                ? data.shareTitle
                                                : data?.share?.name
                                        }
                                    >
                                        {tableHeaderKey === MenuEnum.PATH &&
                                            data?.location?.lastPath}
                                        {tableHeaderKey === MenuEnum.SHARES && data?.share?.name}
                                        {tableHeaderKey === MenuEnum.SHARES_TYPE &&
                                            data?.shareTitle}
                                    </span>
                                </span>
                            );
                        }}
                    />

                    <Column
                        index={ColumnIndex.Status}
                        // header="状态"
                        name="status"
                        render={({ data }) => {
                            return (
                                <span style={{ maxWidth: 70, display: 'flex' }}>
                                    <span
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        title={
                                            tableHeaderKey === MenuEnum.PATH
                                                ? data?.location?.fullName
                                                : tableHeaderKey === MenuEnum.SHARES_TYPE
                                                ? data.shareTitle
                                                : data?.share?.name
                                        }
                                    >
                                        {tableHeaderKey === MenuEnum.PATH &&
                                            data?.location?.lastPath}
                                        {tableHeaderKey === MenuEnum.SHARES && data?.share?.name}
                                        {tableHeaderKey === MenuEnum.SHARES_TYPE &&
                                            data?.shareTitle}
                                    </span>
                                </span>
                            );
                        }}
                    />

                    {props.children as any}
                    {/* 时间展示 */}
                    {/* <Column
                        className="list-time"
                        index={ColumnIndex.Time}
                        name="time"
                        header={props.orderOptions && '发送时间'}
                        // 渲染时间
                        categoryRender={({ data }) => {
                            if (params.current.sort === Sort.UpDateTime) {
                                return util.time.format(data.updated_at);
                            } else if (params.current.sort === Sort.CreateTime) {
                                return util.time.format(data.created_at);
                            } else if (params.current.sort === Sort.Title) {
                                const key =
                                    lastTime === Sort.UpDateTime ? 'updated_at' : 'created_at';
                                return util.time.format(data[key]);
                            }
                        }}
                    /> */}
                    <Column
                        index={ColumnIndex.Time}
                        name="time"
                        header={
                            props.orderOptions &&
                            ['homeMyshare', 'homeshare'].includes(props.name || '') ? (
                                props.name === 'homeMyshare' ? (
                                    '发送时间'
                                ) : (
                                    '分享时间'
                                )
                            ) : props.orderOptions ? (
                                <Filter
                                    onChange={(o) => {
                                        params.current.sort = o.value;
                                        params.current.start = 0;
                                        loadPages();
                                    }}
                                    menu={[
                                        {
                                            text:
                                                props.name === 'homeMyshare'
                                                    ? '发送时间'
                                                    : props.name === 'homeshare'
                                                    ? '分享时间'
                                                    : lang('c.list.updated_at'),
                                            value:
                                                props.name === 'homeshare'
                                                    ? OrderMenu.SHARED
                                                    : OrderMenu.UPDATE,
                                        },
                                        {
                                            text: lang('c.list.created_at'),
                                            value: OrderMenu.CREATE,
                                        },
                                    ]}
                                />
                            ) : (
                                lang('c.list.recent')
                            )
                        }
                        render={({ data }) => {
                            if (props.name === 'recent') {
                                return util.time.format(data.openTime);
                            }
                            if (props.name === 'homeMyshare') {
                                return util.time.format(data.shareAt);
                            }
                            // console.log(props.name, 'props.nameprops.name', data);
                            if (props.name === 'homeshare') {
                                return util.time.format(data.shareAt);
                            } else if (props.name === 'collections') {
                                return util.time.format(data.collectionTime);
                            } else if (props.name === 'trashes') {
                                return util.time.format(data.updated_at);
                            } else if (params.current.sort === Sort.UpDateTime) {
                                return util.time.format(data.updated_at);
                            } else if (params.current.sort === Sort.CreateTime) {
                                return util.time.format(data.created_at);
                            } else if (params.current.sort === Sort.Title) {
                                const key =
                                    lastTime === Sort.UpDateTime ? 'updated_at' : 'created_at';
                                return util.time.format(data[key]);
                            } else {
                                return util.time.format(
                                    params.current.sort === OrderMenu.CREATE
                                        ? data.created_at
                                        : data.updated_at || data.created_at
                                );
                            }
                        }}
                        categoryRender={({ data }) => {
                            if (params.current.sort === Sort.UpDateTime) {
                                return util.time.format(data.updated_at);
                            } else if (params.current.sort === Sort.CreateTime) {
                                return util.time.format(data.created_at);
                            } else if (params.current.sort === Sort.Title) {
                                const key =
                                    lastTime === Sort.UpDateTime ? 'updated_at' : 'created_at';
                                return util.time.format(data[key]);
                            }
                        }}
                    />

                    <Column
                        index={ColumnIndex.Operate}
                        name="context"
                        categoryRender={(renderProps) => {
                            // console.log(props, 'propspropspropsprops');
                            return (
                                props.categoryContext && (
                                    <ContextMenu
                                        render={props.categoryContext}
                                        callbackProps={renderProps}
                                    />
                                )
                            );
                        }}
                        render={(renderProps) => {
                            // console.log(props, 'propspropspropsprops');
                            // 取消收藏
                            if (props.name === 'collections') {
                                // console.log(renderProps, 'renderPropsrenderPropsrenderProps');
                                return (
                                    <CollectionPopover
                                        pageId={renderProps.data.id}
                                        fileType={FileType.Page}
                                        onChange={() => {
                                            setTimeout(() => {
                                                reload();
                                            }, 500);
                                        }}
                                    />
                                );
                            }
                            return (
                                props.context && (
                                    <ContextMenu
                                        render={props.context}
                                        callbackProps={renderProps}
                                    />
                                )
                            );
                        }}
                    />
                </List>
            );
        }
    }
    console.log(selecteds, select, props, categories);
    return (
        <div className="joyspace-list">
            {renderList()}
            {selecteds.size > 0 && props.batch && (
                <BatchControl
                    selecteds={selecteds as any}
                    select={select}
                    render={props.context}
                    categoryRender={props.categoryContext}
                    batchRender={props?.batchContext}
                    categories={categories}
                    setCategories={setCategories}
                />
            )}
        </div>
    );
}

__default.columns = ColumnIndex;
__default.Column = Column;
