import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Spin, message } from 'antd';
import WebPreView from '@/components/chat/message/Preview/web/View';
import { nanoid } from 'nanoid';
import { downLoadFile, getFileUrl, getThumbUrl } from '@/utils/chat/index';
import qs from 'query-string';
import { changeEgovUrl } from '@/utils/tools';
import { DownloadItem, FocusSDK, isFocusEnv } from '@/utils';
import { nyrssfENStr } from '@/utils/date';
interface IProps {
    imgSrc: string;
    imgArr: string[];
    imgName?: string;
    width?: number;
    height?: number;
    onclose?: () => void;
}

export function ImgPreview(props: IProps) {
    const { imgSrc, width = 100, height = 100, imgName, onclose, imgArr } = props;
    const [viewModal, setViewModal] = useState(true);
    const [modalLoading, setModalLoading] = useState(true);
    const imgSize = qs.parseUrl(imgSrc).query;

    function getCaption(obj: any) {
        // eslint-disable-next-line no-param-reassign
        obj = obj.substring(imgSrc.indexOf('downloadAuthn'), obj.length);
        let strEnd = obj.lastIndexOf('?');
        let strStart = obj.lastIndexOf('.');
        // console.log('getCaption', obj.substring(strStart, strEnd));
        return obj.substring(strStart, strEnd);
    }

    const downImageFile = () => {
        const fileName = `京办_${nyrssfENStr(new Date().getTime())}`;
        if (isFocusEnv()) {
            FocusSDK.downloadFile(changeEgovUrl(imgSrc), {
                saveAs: true,
                filename: `${fileName + getCaption(imgSrc)}`,
                onStarted: (downloadItem: DownloadItem) => {
                    // setProcess(0);
                },
                onProgress: () => {
                    // console.log('percent', percent);
                    // setProcess(percent);
                },
                onCancel: () => {
                    // console.log('onCancel ==>取消下载');
                },
                onPause: () => {
                    // console.log('onPause ==> 暂停下载');
                },
                onResume: () => {
                    // console.log('onResume ==> 恢复下载');
                    //
                },
                onFinish: (file: { path: string; filename: string }) => {
                    message.success('下载成功');
                    // console.log('finish', file);
                    // setProcess(100);
                },
            });
        } else {
            const a = document.createElement('a');
            document.body.append(a);
            a.download = imgName || `${nanoid()}.png`;
            // web 端下载文件
            // const name = item.fileName;
            // const url = getFileUrl(item.fileUrl);
            a.href = getFileUrl(imgSrc);
            downLoadFile(`${fileName + getCaption(imgSrc)}`, imgSrc);
            a.click();
        }
    };
    function closeModal() {
        setViewModal(false);
        onclose?.();
    }
    return (
        <Modal
            title="图片预览"
            wrapClassName="web-view-image-modal-wrap"
            mask={false}
            visible={viewModal}
            onCancel={closeModal}
            footer={null}
        >
            <Spin spinning={modalLoading} delay={10} size="large" className="web-view-image-spin" />
            <WebPreView
                style={{ display: modalLoading ? 'none' : 'block' }}
                src={changeEgovUrl(imgSrc)}
                imgArr={imgArr}
                width={Number(imgSize.width) || 320}
                height={Number(imgSize.height)}
                downloadFile={downImageFile}
                closeModal={closeModal}
                onImageLoad={() => setModalLoading(false)}
            />
        </Modal>
    );
}

export default function OpenImgPreview(props: IProps) {
    const el = document.createElement('div');
    document.body.append(el);
    const onclose = () => {
        if (el) {
            ReactDOM.unmountComponentAtNode(el);
            el.remove();
        }
    };
    ReactDOM.render(<ImgPreview {...props} onclose={onclose} />, el);
}
