import React from 'react';
import { connect } from 'dva';
import { message as toast } from 'antd';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import { joinCloudLink } from '@/components/MeetingCloudLink/utils';
import Avatar from '@/components/Avatar';
import { changeEgovUrl } from '@/utils/tools';
import { joinMeeting } from '@/baseComponents/Meeting/utils';
import { avsdkNameMap } from '../Sys/MeetingCard';
import { AVSDK } from '@/baseComponents/Meeting/common';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { FocusSDK } from '@/utils';

const JoyMeetingNoticeCard = connect(function ({ chat }: { chat: ChatState }) {
    const { currentEmployee } = chat;
    return { currentEmployee };
})(function CloudLinkNoticeCard({
    message,
    currentEmployee,
}: {
    message: any;
    currentEmployee: any;
}) {
    const { title, content, infox, extend } = message;
    const [t] = useTranslation('chat');

    const mparams = JSON.parse(decodeURIComponent(message.infox.deepLink).split('mparam=')[1]);
    const fromUser = mparams.fromUser;
    const avsdk = mparams.avsdk as AVSDK;

    function handleJoin() {
        joinMeeting(avsdk, {
            meetingNumber: infox.meetingNumber,
            meetingPassword: infox.meetingPassword,
        }).catch((err) => {
            // toast.error(err.message || err);
        });
    }

    function loadButtons() {
        let platform = FocusSDK.getDeviceInfo().platform;
        if (platform !== 'win32') {
            if (avsdk === AVSDK.XYLINK || avsdk === AVSDK.HUAWEI) {
                return null;
            }
        }
        return (
            <div
                className="button_item"
                onClick={() => {
                    handleJoin();
                }}
            >
                立即入会
            </div>
        );
    }

    if (extend.messageFormatVer === 'v2') {
        return (
            <ComNoticeCard
                avatarSrc={changeEgovUrl(message.sender.avatar)}
                type={avsdkNameMap[avsdk]}
                contentList={extend.fields}
                title={title}
                loadButtonsFn={loadButtons}
            />
        );
    }

    return (
        <div className="notice-card notice-card-cloudlink">
            <div className="notice-card-header">
                <div className="notice-card-title">
                    {avsdkNameMap[avsdk]}-{title}
                </div>
                {content && <div className="notice-card-content">{content}</div>}
            </div>
            {fromUser && (
                <div className="notice-card-from">
                    <Avatar
                        style={{
                            borderRadius: '50%',
                            width: 26,
                            height: 26,
                            marginRight: 5,
                        }}
                        src={changeEgovUrl(fromUser.avatar)}
                    />
                    <span>{fromUser.name}</span>
                </div>
            )}
            {mparams.avsdk && (
                <div className="notice-card-action">
                    <div className="notice-card-action-view" onClick={handleJoin}>
                        {t(`joyMeeting.joinMeeting`)}
                    </div>
                </div>
            )}
        </div>
    );
});

export default JoyMeetingNoticeCard;
