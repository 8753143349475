import React, { useCallback, useEffect, useContext, useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import logger from '@/utils/logger';
import { useTranslation } from 'react-i18next';
import { isValidMobile } from '@/components/LoginComp/utils';
import { BizTypeEnum } from '@/types/LoginPage';

const log = logger.getLogger('NetworkSettingsModal');

const { REACT_APP_DEFAULT_RESEND_PERIOD: DEFAULT_RESEND_PERIOD = '60' } = process.env;

function getFormatMobile(code: string | undefined) {
    let s = '';
    if (code && code.length > 7) {
        const c = code.split(' ').join('');
        s = `${c.slice(0, 3)} ${c.slice(3, 7)} ${c.slice(7)}`;
    } else if (code && code.length > 3) {
        const c = code.split(' ').join('');
        s = `${c.slice(0, 3)} ${c.slice(3)}`;
    } else {
        s = code || '';
    }
    return s;
}

export interface BindMobileModalProps {
    visible: boolean;
    loginName: string;
    loginType: string;
    teamId?: string;
    onSuccess: () => void;
    closable?: boolean;
    onClose: () => void;
}

export default function BindMobileModal({
    visible,
    loginName,
    loginType,
    teamId,
    onSuccess,
    closable,
    onClose,
}: BindMobileModalProps) {
    const { service } = useContext<GlobalContextConfig>(GlobalContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [mobile, setMobile] = useState('');
    const [mobileFormat, setMobileFormat] = useState(getFormatMobile(mobile));
    const [verifyCode, setVerifyCode] = useState('');
    const [failedMobileDesc, setFailedMobileDesc] = useState('');
    const [failedVerifyDesc, setFailedVerifyDesc] = useState('');
    const [resendLastTs, setResendLastTs] = useState(-1);

    useEffect(() => {
        setMobileFormat(getFormatMobile(mobile));
    }, [mobile]);

    useEffect(() => {
        if (resendLastTs < 1) {
            return;
        }

        setTimeout(() => {
            setResendLastTs(resendLastTs - 1);
        }, 1000);
    }, [resendLastTs, setResendLastTs]);

    const bindMobile = useCallback(async () => {
        setLoading(true);
        try {
            await service.authService.bindMobile({
                loginName,
                loginNameType: loginType,
                teamId,
                mobile,
                verCode: verifyCode,
            });
            setLoading(false);
            message.success(t('setting.bindMobileSuccess'));
            onClose();
            onSuccess();
        } catch (err) {
            setLoading(false);
            setFailedVerifyDesc(t('login.verifyFailed'));
        }
    }, [
        setLoading,
        service.authService,
        loginName,
        loginType,
        mobile,
        verifyCode,
        teamId,
        onClose,
        onSuccess,
        t,
    ]);

    const sendVerify = useCallback(async () => {
        if (resendLastTs > 0 || sending || failedMobileDesc) {
            return;
        }
        if (!mobile) {
            message.error(t('login.inputMobilePl'));
            return;
        }
        try {
            setSending(true);
            const params = {
                bizType: BizTypeEnum.BIND_MOBILE,
                loginName: mobile,
                loginNameType: 'MOBILE',
                teamId,
            };
            await service.authService.appSendVerCode(params);
            setResendLastTs(parseInt(DEFAULT_RESEND_PERIOD, 10));
            setSending(false);
        } catch (err) {
            // setFailedVerifyDesc(t('login.verifyFailed'));
            setSending(false);
        }
    }, [sending, resendLastTs, failedMobileDesc, mobile, teamId, service.authService, t]);

    return (
        <Modal
            title="绑定手机号"
            visible={visible}
            closable={closable}
            maskClosable={false}
            onCancel={onClose}
            footer={null}
            wrapClassName="mobileForm"
            style={{ width: 420 }}
            bodyStyle={{ paddingBottom: 32 }}
        >
            <div>
                <p style={{ marginBottom: 32 }}>{t('setting.bindMobileDesc')}</p>
                <div
                    className="mobileInput"
                    style={{
                        marginBottom: failedMobileDesc ? 10 : 34,
                    }}
                >
                    <Input
                        className={`${failedMobileDesc && 'warn-info'}`}
                        autoFocus
                        placeholder={t('login.inputMobilePl')}
                        value={mobileFormat}
                        onChange={(e: any) => {
                            const f = e.target.value;
                            if (!f) setFailedMobileDesc('');
                            if (f && f.split(' ').join('').length > 11) {
                                return;
                            }
                            setMobile(f.split(' ').join(''));
                        }}
                        onBlur={() => {
                            setFailedMobileDesc(
                                isValidMobile(mobile) || !mobile ? '' : t('login.invalidMobile')
                            );
                        }}
                        allowClear
                    />
                </div>
                {failedMobileDesc && <div className="verifyFailedDesc">{failedMobileDesc}</div>}
                <div
                    className="mobileInput"
                    style={{
                        marginTop: 10,
                        marginBottom: failedVerifyDesc ? 10 : 24,
                    }}
                >
                    <Input
                        className={`${failedVerifyDesc && 'warn-info'}`}
                        placeholder={t('login.inputVerifyCodePl')}
                        value={verifyCode}
                        onChange={(e: any) => {
                            const f = e.target.value;
                            setVerifyCode(f);
                        }}
                        allowClear
                        suffix={
                            <a className="resendVerifyBtn" onClick={sendVerify}>
                                {resendLastTs >= 0
                                    ? (resendLastTs ? resendLastTs + 'S后' : '') + t('login.resend')
                                    : t('login.send') + t('login.verifyCode')}
                            </a>
                        }
                    />
                </div>
                {failedVerifyDesc && <div className="verifyFailedDesc">{failedVerifyDesc}</div>}
                <div
                    style={{
                        marginTop: 44,
                    }}
                >
                    <Button
                        type="primary"
                        loading={loading}
                        disabled={!mobile || !verifyCode || !!failedMobileDesc}
                        onClick={() => bindMobile()}
                        style={{ width: '100%', height: 42 }}
                    >
                        {t('nextStep')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
