/* eslint-disable */
import Quill from 'quill';
import { stickers_sets } from '@/components/chat/utils/stickers-config';
import { readDataFromClipboard } from '@/utils/clipOperate';
import { lookup } from 'mime-types';
import { message as AntMessage } from 'antd';
import events from '@/components/Joyspace/context/events';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

function getNewStr(str: string, replaceStr: string) {
    let newStr = '';
    let len = replaceStr.length;
    let firstIndex = str.indexOf(replaceStr);
    console.log('firstIndex', firstIndex);
    if (firstIndex < 0) {
        return str;
    }
    newStr =
        str.substring(0, firstIndex) +
        `<span class="e-emoji" contenteditable="false" data-value="${replaceStr}">&#65279;<span contenteditable="false">${replaceStr}</span>&#65279;</span>`;
    let nextStr = str.substring(firstIndex + len);
    let tmpStr = '';
    if (nextStr) {
        tmpStr = getNewStr(nextStr, replaceStr);
    }
    newStr += tmpStr;
    return newStr;
}

// 获取剪贴板文件
async function getClipImgFile(type: string) {
    try {
        let blobData = (await readDataFromClipboard()) || [];
        let fileBlob: any;
        let fileName: any;

        for (let item of blobData) {
            let itemType = item?.type || '';
            if (itemType.indexOf('image/') > -1) {
                fileBlob = item;
            } else if (itemType.indexOf('text/plain') > -1) {
                fileName = await item?.text();
            }
        }

        if (fileBlob) {
            console.log('blob---', fileBlob, fileName, type);
            let file = new window.File([fileBlob], fileName, { type: type });
            return file;
        }
        return null;
    } catch (e) {
        console.error(e);
        return null;
    }
}

// 国产操作系统且是文件
function isLinuxAndFile(text: string) {
    let browserInfo = window.navigator?.userAgent;

    if (/linux/i.test(browserInfo)) {
        if (text.startsWith('/') && lookup(text)) {
            return true;
        }
    }

    return false;
}
export default class CustomClipboard extends Clipboard {
    constructor(quill: any, options: any) {
        super(quill, options);
        this.options = options;
        this.upload = options.upload;
    }

    async onPaste(e: any) {
        // await readDataFromClipboard();
        // console.log('@@@@', e.clipboardData?.files?.length);
        if (e.defaultPrevented || !this.quill.isEnabled()) return;
        let range = this.quill.getSelection();
        e.preventDefault();
        const html = e.clipboardData.getData('text/html');
        let text = e.clipboardData.getData('text/plain');
        const files = Array.from(e.clipboardData.files || []);

        console.log(`粘贴内容, html: ${html}, text: ${text}`, files);
        // console.log('paste html', html);
        // console.log('paste text', text);
        // console.log('paste files', files);

        if (!html && !text && files.length === 0) {
            return;
        }
        if (!html && !text && files.length > 0) {
            if (this.upload) {
                this.upload(range, files);
            }
            return;
        }
        // 判断是否仅为图片
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const container = doc.body;
        if (
            container &&
            container.childNodes.length === 1 &&
            container.firstChild &&
            container.firstChild.nodeName === 'IMG' &&
            files.length > 0
        ) {
            if (this.upload) {
                this.upload(range, files);
            }
            return;
        }
        if (text) {
            let type = lookup(text) || '';
            console.log('type---', type);
            if (type.match(/^image\/(gif|jpe?g|a?png)/i)) {
                let file = await getClipImgFile(type);
                console.log('file---', file);
                if (file) {
                    return this.upload(range, [file]);
                }
            }
            // 国产操作系统且是文件但获取不到剪贴板文件
            if (isLinuxAndFile(text)) {
                text = '';
                AntMessage.warn('当前浏览器不支持粘贴文件，请拖拽文件至当前窗口');
                return;
            }
        }

        let delta = new Delta().retain(range.index);
        let scrollTop = this.quill.scrollingContainer.scrollTop;
        this.container.focus();
        this.quill.selection.update('silent');
        //setTimeout(() => {
        if (!html && text) {
            delta = delta.insert(text).delete(range.length);
        } else if (text) {
            const patt = /\[.*?\]/g;
            const re = text.match(patt);
            let result = text;
            if (re && re.length > 0) {
                const reSet = new Set([...re]);
                reSet.forEach((r: string) => {
                    if (!r) {
                        return;
                    }
                    if (!stickers_sets[r]) {
                        return;
                    }
                    let tmpIndex = r.indexOf(']');
                    let tmpStr = r.substring(1, tmpIndex);
                    let regexp = new RegExp(`\\[${tmpStr}\\]`, 'g');
                    result = result.replace(
                        regexp,
                        `<span class="e-emoji" contenteditable="false" data-value="${r}">&#65279;<span contenteditable="false">${r}</span>&#65279;</span>`
                    );
                });
            }
            const pattern1 = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
            const specialEmojiRegex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu;
            let rtnResult = '';
            for (const temp of result) {
                if (pattern1.test(temp)) {
                    rtnResult += `<span class="e-emoji" contenteditable="false" data-value="${temp}">&#65279;<span contenteditable="false">${temp}</span>&#65279;</span>`;
                } else {
                    if (specialEmojiRegex.test(temp)) {
                        rtnResult += `<span class="e-emoji" contenteditable="false" data-value="${temp}">&#65279;<span contenteditable="false">${temp}</span>&#65279;</span>`;
                    } else {
                        rtnResult += temp;
                    }
                }
            }
            const content = rtnResult.replace(/\n/g, '<br/>');
            const p = `<p>${content}</p>`;
            delta = delta.concat(this.convert(p)).delete(range.length);
        } else {
            delta = delta.concat(this.convert(html)).delete(range.length);
        }

        let result = new Delta().retain(range.index);
        delta.forEach((op: any) => {
            // if (op.insert && typeof op.insert === 'object' && op.insert.image) {
            //     return;
            // }
            if (
                op.insert &&
                (typeof op.insert === 'string' ||
                    op.insert['mention-link'] ||
                    op.insert['custom-emoji'])
            ) {
                result.ops.push({ insert: op.insert });
            }
        });
        result = result.delete(range.length);
        this.quill.updateContents(result, 'user');
        // range.length contributes to delta.length()
        this.quill.setSelection(result.length() - range.length, 'silent');
        this.quill.scrollingContainer.scrollTop = scrollTop;
        //this.quill.focus();
        //}, 1);
    }
}
