import React, { useReducer, useEffect, useMemo, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
    CalendarInfo,
    getCalendarInfo,
    getRelatedScheduleList,
    ScheduleRelated,
} from '@/api/calendar';
import { message, Spin, Tabs } from 'antd';
import ScheduleList from './ScheduleList';
import { handleScheduleList } from '@/components/Calendars/utils';
import { useTranslation } from 'react-i18next';
import { connect } from 'dvajs';
import './index.less';
import CalendarComp from './CalendarComp';
import IconFont from '@/components/icon';
import bus from '@/utils/bus';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';

interface State {
    calendarInfo: CalendarInfo;
    selectedTime: Dayjs; // 全局选中时间
    scheduleList: any[];
    scheduleMap: { [key: string]: ScheduleRelated[] };
    relatedScheduleMap: { [key: string]: ScheduleRelated[] };
    reloadTime: any; // 主要是用来改变此数据，导致触发重新加载
    // calendarList: any[];
    // selectedCalendarList: any[];
    // scheduleMap: any; // key: 2020-05-01
    // viewTab: 'month' | 'week' | 'day';

    // // 视图缩放比例
    // weekViewScalePercent: number;
    // dayViewScalePercent: number;

    // // 查看其它联系人
    // otherUser: any;
    // // 我的日历
    // myCalendar: CalendarInfo;
    // // 已选择日历列表的 本地存储key值
    // savedCalendarListkey: string;
    // createModalVisible: boolean; // 全局创建modal控制，不负责日历页面本身的
}

const functionObj: any = {
    /**
     * 通用设置state函数
     */
    setState: function (state: State, { payload }: { payload: any }) {
        return {
            ...state,
            ...payload,
        };
    },
};

function reducer(state: State, action: { type: string; payload?: any }) {
    try {
        const { type, payload } = action;
        return functionObj[type](state, { payload });
    } catch (error) {
        return state;
    }
}

function CalendarView({
    archive,
    calendarId,
    userData,
    changeScheduleInfoDraw,
    openCreateModal,
}: {
    archive: boolean;
    calendarId: string;
    userData: any;
    openCreateModal: (data: any) => void;
    changeScheduleInfoDraw: (payload: {
        visible: boolean;
        scheduleId: string;
        selectedScheduleTime: Dayjs;
        from: number;
        openTime: Dayjs;
    }) => void;
}) {
    const [state, dispatch]: [State, any] = useReducer(reducer, {
        selectedTime: dayjs().startOf('day'),
        scheduleList: [],
        calendarList: [],
        calendarSelectedList: [],
        scheduleMap: {},
        selectedCalendarList: [],
        viewTab: 'month',
        weekViewScalePercent: 100,
        dayViewScalePercent: 100,
        otherUser: null,
        modifyScheduleContent: null,
        myCalendar: {},
        savedCalendarListkey: 'savedCalendarListkey',
    });

    const [t] = useTranslation('calendar');
    const [t_common] = useTranslation('common');

    const { setState } = useMemo(
        () => ({
            setState: (payload: any) => dispatch({ type: 'setState', payload }),
        }),
        []
    );

    const changeSelectedTime = useCallback(
        (time: Dayjs) => {
            setState({ selectedTime: time });
        },
        [setState]
    );

    useEffect(() => {
        function reloadSchedule(opt: any) {
            if (!opt.calendarId || opt.calendarId !== calendarId) {
                return;
            }
            setState({
                reloadTime: dayjs(),
            });
        }
        bus.on('calendar:reload', reloadSchedule);
        return () => {
            bus.off('calendar:reload', reloadSchedule);
        };
    }, [calendarId, setState]);

    /**
     * 加载日程信息
     * 会同步获取用户及日历信息
     * 将用户装饰进日程里
     */
    useEffect(() => {
        const selectedTime = state.selectedTime;
        const beginDate = selectedTime;
        const endDate = selectedTime.add(90, 'day');

        function fetchScheduleList(
            opt: {
                calendarId: string;
                beginDate: number;
                endDate: number;
                user?: any;
            },
            callback: (opts: any) => void
        ) {
            getRelatedScheduleList(opt)
                .then((result: any) => {
                    let { scheduleList = [], userInfoList = [], calendarList = [] } = result;
                    if (!scheduleList || !userInfoList || !calendarList) {
                        return message.error(t_common('error.description'));
                    }
                    const scheduleMap = handleScheduleList(
                        scheduleList,
                        selectedTime,
                        endDate,
                        true
                    );
                    const userInfoMap = userInfoList.reduce((p: { [key: string]: any }, v: any) => {
                        const id = `${v.teamId}:${v.ddAppId || ''}:${v.userId}`;
                        p[id] = v;
                        return p;
                    }, {});
                    Object.keys(scheduleMap).forEach((key) => {
                        const list: any[] = scheduleMap[key];
                        list.forEach(
                            // eslint-disable-next-line max-nested-callbacks
                            (schedule: { teamId: any; ddAppId: any; userId: any; user: any }) => {
                                const id = `${schedule.teamId}:${schedule.ddAppId || ''}:${
                                    schedule.userId
                                }`;
                                if (userInfoMap[id]) {
                                    schedule.user = userInfoMap[id];
                                }
                            }
                        );
                        // eslint-disable-next-line max-nested-callbacks
                        list.sort((a: ScheduleRelated, b: ScheduleRelated) => {
                            if (a.scheduleBeginTime.isBefore(b.scheduleBeginTime)) {
                                return -1;
                            }
                            if (a.scheduleBeginTime.isSame(b.scheduleBeginTime)) {
                                return 0;
                            }
                            return 1;
                        });
                    });
                    // setState({ scheduleMap, calendarInfo: calendarList[0] });
                    callback({ scheduleMap, calendarInfo: calendarList[0] });
                })
                .catch((err) => {
                    message.error(err.message.toString());
                });
        }
        fetchScheduleList(
            {
                calendarId,
                beginDate: beginDate.valueOf(),
                endDate: endDate.valueOf(),
            },
            (opt: any) => {
                setState(opt);
            }
        );
        // const { teamUserInfo } = userData;
        // fetchScheduleList(
        //     {
        //         calendarId,
        //         beginDate: beginDate.valueOf(),
        //         endDate: endDate.valueOf(),
        //         user: {
        //             userId: teamUserInfo.userId,
        //             teamId: teamUserInfo.teamId,
        //             ddAppId: teamUserInfo.ddAppId,
        //         },
        //     },
        //     (opt: any) => {
        //         const { scheduleMap, calendarInfo } = opt;
        //         setState({
        //             relatedScheduleMap: scheduleMap,
        //         });
        //     }
        // );
    }, [calendarId, setState, state.selectedTime, t_common, userData, state.reloadTime]);

    const handleScheduleClick = useCallback(
        (schedule: ScheduleRelated) => {
            changeScheduleInfoDraw({
                visible: true,
                scheduleId: schedule.scheduleId || '',
                selectedScheduleTime: schedule.scheduleBeginTime,
                from: ScheduleDetailFrom.Calendar,
                openTime: dayjs(),
            });
        },
        [changeScheduleInfoDraw]
    );

    const handleCreate = useCallback(() => {
        openCreateModal({
            visible: true,
            frozenCalendarId: calendarId,
        });
    }, [calendarId, openCreateModal]);

    return (
        <div className="project-calendar-view">
            <div className="project-calendar-main">
                <div className="project-calendar-view-container">
                    <CalendarComp
                        selectedTime={state.selectedTime}
                        onTimeChange={changeSelectedTime}
                    />
                    {state.calendarInfo ? (
                        <ScheduleList
                            selectedTime={state.selectedTime}
                            scheduleMap={state.scheduleMap}
                            calendarInfo={state.calendarInfo}
                            onItemClick={handleScheduleClick}
                        />
                    ) : (
                        // <Tabs defaultActiveKey="1">
                        //     <Tabs.TabPane tab={t('All')} key="1">
                        //         <ScheduleList
                        //             selectedTime={state.selectedTime}
                        //             scheduleMap={state.scheduleMap}
                        //             calendarInfo={state.calendarInfo}
                        //             onItemClick={handleScheduleClick}
                        //         />
                        //     </Tabs.TabPane>
                        //     <Tabs.TabPane tab={t('Related to me')} key="2">
                        //         <ScheduleList
                        //             selectedTime={state.selectedTime}
                        //             scheduleMap={state.relatedScheduleMap}
                        //             calendarInfo={state.calendarInfo}
                        //             onItemClick={handleScheduleClick}
                        //         />
                        //     </Tabs.TabPane>
                        // </Tabs>
                        <Spin />
                    )}
                </div>
            </div>
            {!archive && (
                <div className="project-calendar-view-create" onClick={handleCreate}>
                    <IconFont type="iconapp_btn_add2" />
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ calendar, user }: any) {
    return {
        userData: user.userData.user,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeScheduleInfoDraw: (payload: {
            visible: boolean;
            scheduleId: string;
            selectedScheduleTime: Dayjs;
            from: number;
            openTime: Dayjs;
        }) =>
            dispatch({
                type: 'calendar/changeScheduleInfoDraw',
                payload,
            }),
        openCreateModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);
