import React, { useEffect, useContext, useState, useCallback } from 'react';
import Icon from '../../Icon';
import { getUserDefaultAvatar } from '@/utils/oss';
import { Tooltip, Badge } from 'antd';
import { openCreateGroupChat } from './CreateChat';
import Context from '../../context';
import './index.less';
import { IGroupChatItem as IGroupItem, Project, ProjectRole } from '../../types';
import { getGroupInfo } from '../../api';
import bus from '@/utils/bus';
import { openGroupSession } from '@/utils';
import { TaskPartEnum } from '@/types/work';
import ImService from '@/server/ImService';
import { changeEgovUrl } from '@/utils/tools';
interface propTypes {
    projectId: string;
    closeGroupChat: (value: boolean) => void;
    project?: Project;
}

export default function (props: propTypes) {
    const { projectId, closeGroupChat, project } = props;
    const { user, events, t } = useContext(Context);
    const [list, setList] = useState<IGroupItem[]>([]);
    const [count, setCount] = useState<number>(0);

    const getProjectGroups = useCallback(async () => {
        const newGroupIds = (project?.groupIds || []).map((item) => ({
            groupId: item,
        }));
        const groupIds = [{ groupId: project?.defaultGroupId || '' }, ...newGroupIds];
        const result = await getGroupInfo(groupIds);

        setList(result.groups || []);
    }, [project?.defaultGroupId, project?.groupIds]);

    useEffect(() => {
        getProjectGroups();
    }, [getProjectGroups]);

    useEffect(() => {
        events.on('members.change', getProjectGroups);
        return () => {
            events.off('members.change', getProjectGroups);
        };
    }, [events, getProjectGroups]);

    useEffect(() => {
        function multiSync(data: any) {
            if (!data?.data) return;
            if (data.data.projectId === projectId && data.data.part === TaskPartEnum.IM_GROUP) {
                getProjectGroups();
            }
        }
        bus.on('chat_message_multiSync_event:joyWork', multiSync);
        if (list.length === 0) {
            return;
        }
        const imService = ImService.getInstance();
        imService.getAllSessions({}, false).then((sessions) => {
            const firstGroupChatInfo = (sessions || []).find(
                (item: { gid?: string; sessionId: string; unreadCount: number; lastMsg: any }) =>
                    item.sessionId === list[0].gid
            );
            setCount(firstGroupChatInfo?.unreadCount || 0);
        });
        return () => {
            bus.off('chat_message_multiSync_event:joyWork', multiSync);
        };
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        function messageEvent(event: any) {
            if (
                (event.origin || event.originalEvent.origin) !== location.origin ||
                event.data?.source !== 'group-session-info'
            )
                return;

            const firstGroupChatInfo = (event.data?.payload || []).find(
                (item: { gid?: string; sessionId: string; unreadCount: number; lastMsg: any }) =>
                    item.sessionId === list[0]?.gid
            );
            setCount(firstGroupChatInfo?.unreadCount || 0);
        }
        window.addEventListener('message', messageEvent);

        if (list.length === 0) {
            return;
        }
        const imService = ImService.getInstance();
        imService.getAllSessions({}, false).then((sessions) => {
            const firstGroupChatInfo = (sessions || []).find(
                (item: { gid?: string; sessionId: string; unreadCount: number; lastMsg: any }) =>
                    item.sessionId === list[0].gid
            );
            setCount(firstGroupChatInfo?.unreadCount || 0);
        });
        return () => window.removeEventListener('message', messageEvent);
    }, [list]);

    const openChatSession = useCallback((gid: string) => {
        openGroupSession({ gid });
    }, []);

    return (
        <div className="pm-chat">
            <div className="pm-chat-header">
                <Tooltip placement="top" title={t('detail.chat.hide')}>
                    <span onClick={() => closeGroupChat(false)}>
                        <Icon type="iconic_packup" className="pm-chat-packup-icon" />
                    </span>
                </Tooltip>
                <span className="pm-chat-header-createchat">
                    {project?.archive ? t('detail.chat_title') : t('detail.chat.create')}
                </span>
                <span
                    onClick={() =>
                        openCreateGroupChat({
                            projectId,
                            user,
                            t,
                            callback: (content: IGroupItem) => {
                                setList((oldstate: IGroupItem[]) => {
                                    return oldstate.concat(content);
                                });
                            },
                        })
                    }
                >
                    {!project?.archive &&
                        [ProjectRole.ADMIN, ProjectRole.OWNER].includes(
                            project?.user?.role || ProjectRole.MEMBER
                        ) && <Icon type="iconlog_add_to" className="pm-chat-add-icon" />}
                </span>
            </div>
            <div className="pm-chat-main">
                <ul>
                    {(list || []).map((item, index) => (
                        <li key={item.gid} onClick={openChatSession.bind(null, item.gid)}>
                            {index === 0 ? (
                                <Badge count={count} className="pm-chat-main-badge">
                                    <img
                                        className="pm-chat-main-avatar"
                                        src={changeEgovUrl(item.avatar || getUserDefaultAvatar())}
                                        alt=""
                                    />
                                </Badge>
                            ) : (
                                <img
                                    className="pm-chat-main-avatar"
                                    src={changeEgovUrl(item.avatar || getUserDefaultAvatar())}
                                    alt=""
                                />
                            )}
                            <span>{item.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
