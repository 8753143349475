// @ts-nocheck
import React, { useRef, useEffect } from 'react';
export function Circle({
    text1,
    text2,
    value,
    color,
}: {
    text1: string;
    text2: string;
    value: number;
    color: string;
}) {
    const canvasEl = useRef(null);
    useEffect(() => {
        circle();
    }, [circle, text1, value]);
    const circle = React.useCallback(() => {
        function drawCircle(circleObj: any) {
            let ctx = circleObj.ctx;

            ctx.beginPath();
            ctx.arc(
                circleObj.x,
                circleObj.y,
                circleObj.radius,
                circleObj.startAngle,
                circleObj.endAngle,
                false
            );
            // 设定曲线粗细度
            ctx.lineWidth = circleObj.lineWidth;
            // 给曲线着色
            ctx.strokeStyle = circleObj.color;
            // 连接处样式
            ctx.lineCap = value >= 0.9 ? 'round' : 'square';
            // 给环着色
            ctx.stroke();
            ctx.closePath();
        }
        let ctx = canvasEl.current.getContext('2d');
        ctx.clearRect(0, 0, 340, 340);
        // 填充文字
        // 第一行文字
        ctx.font = '36px Microsoft YaHei';
        ctx.fillStyle = '#2E2D2D';
        let textC1 = ctx.measureText(text1);
        /* 插入文字，后面两个参数为文字的位置 */
        /* 此处注意：text.width获得文字的宽度，然后就能计算出文字居中需要的x值 */
        ctx.fillText(text1, 170 - textC1.width / 2, 160);

        // 第二行文字
        ctx.font = '22px Microsoft YaHei';
        ctx.fillStyle = '#8F959E';
        let textC2 = ctx.measureText(text2);
        /* 插入文字，后面两个参数为文字的位置 */
        /* 此处注意：text.width获得文字的宽度，然后就能计算出文字居中需要的x值 */
        ctx.fillText(text2, 170 - textC2.width / 2, 200);

        /* 开始圆环 */
        let circleObj: any = {
            ctx: ctx,
            /* 圆心 */
            x: 170,
            y: 170,
            /* 半径 */
            radius: 120,
            /* 环的宽度 */
            lineWidth: 40,
        };

        /* 灰色的圆环 */
        /* 开始的度数-从上一个结束的位置开始 */
        circleObj.startAngle = 0;
        /* 结束的度数 */
        circleObj.endAngle = Math.PI * 2;
        circleObj.color = '#F0F3F3';
        drawCircle(circleObj);
        if (value) {
            /* 有色的圆环 */
            circleObj.lineWidth = 48;
            circleObj.radius = 144;
            /* 从-90度的地方开始画 */
            circleObj.startAngle = -Math.PI * 2 * 0.25;
            /* 从当前度数减去-90度 */
            circleObj.endAngle = Math.PI * 2 * (value - 0.25);
            circleObj.color = color;
            drawCircle(circleObj);
        }
    }, [text1, text2, value, color]);
    /* 画曲线 */

    return <canvas ref={canvasEl} width="340" height="340" style={{ width: 170, height: 170 }} />;
}
