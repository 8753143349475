import React, { useState, useEffect } from 'react';
import { Tooltip, Popover } from 'antd';
import { ColorIndicator } from './../Common/index';
import './index.less';
import { CaretDownOutlined } from '@ant-design/icons';
//   Color(0xFFEF5B56),
//   Color(0xFFF04BA8),
//   Color(0xFFFF8803),
//   Color(0xFFFFC60C),
//   Color(0xFF35C724),
//   Color(0xFF10D5BB),
//   Color(0xFF4C82FC),
//   Color(0xFF16C0FF),
//   Color(0xFF6069E5),
//   Color(0xFF9459F6),
//   Color(0xFFD135D0),
//   Color(0xFF8F949E),
// export const colorList = [
//     'DE2418',
//     'F1008F',
//     'F07F00',
//     'F4B800',
//     '3ABF00',
//     '00C5E0',
//     'ef5b56',
//     '0090F2',
//     '2634D4',
//     '6F24D4',
//     'DF00D2',
//     '848484',
// ];
export const colorList = [
    '5EA7F8',
    'EF5B56',
    'F04BA8',
    'FF8803',
    'FFC60C',
    '35C724',
    '10D5BB',
    '16C0FF',
    '6069E5',
    '9459F6',
    'D135D0',
    '8F949E',
];
interface Iprops {
    value?: string;
    className?: string;
    handleChange?: (p: string) => void;
    handleSelect?: (p: string) => void;
    disabled?: boolean;
}
export default function ColorPicker({
    // value = 'f00',
    value = '5EA7F8',
    handleChange,
    handleSelect,
    className = '',
}: Iprops) {
    const [current, setCurrent] = useState(value);
    const [visible, setVisible] = useState(false);
    function currentColorChange(current: string) {
        setCurrent(current);
        if (handleChange) {
            handleChange(current);
        }
    }
    function handleColorSelect(current: string) {
        setCurrent(current);
        if (handleSelect) {
            handleSelect(current);
        }
        setVisible(false);
    }
    function onVisibleChange(v: boolean) {
        setVisible(v);
    }
    useEffect(() => {
        setCurrent(value);
    }, [value]);
    return (
        <Popover
            visible={visible}
            // mouseLeaveDelay={10000}
            trigger="click"
            overlayClassName="color-picker-popover"
            placement="bottom"
            title={() => {
                return (
                    <div className="color-picker-wrapper">
                        {colorList.map((item) => (
                            <ColorIndicator
                                className="color-indicator-item"
                                key={item}
                                size="large"
                                color={item}
                                selected={item === current}
                                onClick={() => handleColorSelect(item)}
                            />
                        ))}
                    </div>
                );
            }}
            onVisibleChange={onVisibleChange}
        >
            <div className={`color-picker-inline ${className}`}>
                <ColorIndicator
                    className="color-indicator-item-inline"
                    size="large"
                    color={current}
                />
                <CaretDownOutlined className="calendar-down" />
            </div>
        </Popover>
    );
}
export function ColorSelector({
    // value = 'f00',
    value = '5EA7F8',
    handleSelect,
    className = '',
    disabled = false,
}: Iprops) {
    return (
        <div className="color-selector-wrapper">
            {colorList.map((item) => (
                <ColorIndicator
                    className={`${
                        value.toUpperCase() === item.toUpperCase() ? 'selected' : ''
                    } ${className}`}
                    key={item}
                    size="large"
                    color={item}
                    onClick={() => {
                        if (!disabled) {
                            handleSelect?.(item);
                        }
                    }}
                />
            ))}
        </div>
    );
}
