// wzp Typography排版

import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
const { TextArea } = Input;
import { userEscapeString, mattchUrl } from '@/utils/chat/index';
import CopyFrame from '@/components/CopyFrame';
import { handleOpenUrl } from '@/utils/openUrl';
interface ParagraphType {
    placeholder?: string;
    editing: boolean;
    content: string;
    maxLength: number;
    onEnd?: any;
    handleSetEditing: Function;
}

export function Paragraph(props: ParagraphType) {
    const { maxLength, content, placeholder, onEnd, editing, handleSetEditing } = props;
    const [editContent, setEditContent] = useState('');
    const initContent = useRef(null);

    useEffect(() => {
        setEditContent(content);
    }, [content]);

    useEffect(() => {
        if (editing) {
            setTimeout(() => {
                const textAreaNode: any = initContent.current;
                textAreaNode?.focus({ cursor: 'end' });
            }, 0);
        }
    }, [editing]);

    return (
        <div>
            {/* 编辑 */}
            <div style={{ display: editing ? 'block' : 'none' }}>
                <CopyFrame>
                    <TextArea
                        id="TextArea"
                        ref={initContent}
                        value={editContent}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        onPressEnter={() => {
                            handleSetEditing(false);
                            onEnd(editContent);
                        }}
                        onBlur={() => {
                            handleSetEditing(false);
                            onEnd(editContent);
                        }}
                        onChange={({ target: { value } }) => {
                            setEditContent(value);
                        }}
                        autoSize={true}
                    />
                </CopyFrame>
            </div>
            {/* 展示 */}
            <div style={{ display: !editing ? 'block' : 'none' }}>
                <CopyFrame>
                    <span
                        className="mattch-url"
                        onClick={handleOpenUrl}
                        dangerouslySetInnerHTML={{
                            __html: mattchUrl(userEscapeString(editContent), true),
                        }}
                    />
                </CopyFrame>
            </div>
        </div>
    );
}
