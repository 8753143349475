import { message } from 'antd';
import { ICategoryAddCallback, api } from './index';
import { openModalTextBox } from '../components/ModalTextBox';
import { lang } from '../context';
import events from '../context/events';
const Rename: ICategoryAddCallback = (props, isAllow, isTeam) => {
    const { data, setData } = props;

    return [
        {
            children: lang('ctx.rename.title'),
            callbackOrModal: () => {
                openModalTextBox({
                    title: lang('ctx.rename.title'),
                    value: data.name,
                    onOk: (value, close) => {
                        api.categories.rename(data.id, value, isTeam).then((res) => {
                            if (res) {
                                events.emit('list.reload');
                                events.emit('changeFolderInfoById', data.id, value);
                                const newData = {
                                    ...data,
                                    name: value,
                                };

                                events.emit('list.removeOrSetData', [newData], 'setData');

                                message.success(lang('common.success'));
                            } else {
                                message.error(lang('common.fail'));
                            }
                            close();
                        });
                        // changeFolderInfoById({
                        //     id: data.id,
                        //     name: value,
                        // }).then((res: any) => {
                        //     if (res) {
                        //         message.success(lang('common.success'));
                        //         setData({
                        //             ...data,
                        //             name: value,
                        //         });
                        //     } else {
                        //         message.error(lang('common.fail'));
                        //     }
                        //     close();
                        // });
                    },
                });
            },
            isAllow,
            type: 'rename',
            icon: 'pc_yundoc_rename',
            splitCode: 1,
        },
    ];
};

export default Rename;
