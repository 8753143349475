import React, { useCallback, useEffect, useState, useMemo, CSSProperties, useContext } from 'react';
import { Typography, Drawer, message, Popover, Modal, Tooltip, Button } from 'antd';
import { Paragraph } from '@/components/Supervise/components/Paragraph';
import { connect } from 'dvajs';
import Dayjs from 'dayjs';
import IconFont from '@/components/icon';
import ReceiveStatusPopover from '../ReceiveStatusPopover';
import CommentInput from './../commentInput';
import './index.less';
import { finishReminder } from '@/assets/img/';
import bus from '@/utils/bus';
import Loading from '@/components/Loading';
import LoadMore, { MiddleLoading } from './../loadMore';
import i18n from '@/i18n';
import { handleOpenUrl } from '@/utils/openUrl';
import {
    getSuperviseInfo,
    getSuperviseComment,
    createSuperviseComment,
    readSupervise,
    delSupervise,
    hideSuperviseUser,
    deleteSuperviseComment,
    repeatSupervise,
    getSuperviseServiceRemark,
    changeSuperviseServiceRemark,
} from '@/api/supervise';
import {
    IcreateSupervise,
    SuperviseType,
    Receiver,
    ReadFlag,
    IcommentResp,
    EsendType,
    Owner,
    EsuperviseSourceType,
    IgetSuperviseRemark,
    IcreateSuperviseRemark,
} from '@/types/supervise';
import { UserAvatar } from '../userInfo/';
import { SuperviseDel } from '@/assets/img';
import { sessionDateFormat } from '@/utils/date';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { useAppId } from '@/components/Supervise/envirment';
import { SessionType } from '@/types/chat';
import { useHistory } from 'react-router-dom';
import { values } from 'lodash';
import { changeEgovUrl } from '@/utils/tools';
import { userEscapeString, stringEncode, mattchUrl } from '@/utils/chat/index';
import CopyFrame from '@/components/CopyFrame';
import { ScheduleDetailFrom } from '@/components/Calendars/Schedule/Detail';
import Tab from '../Tab';
import UserNameList from '../UserNameList';

function CommentItem({
    data,
    removeable,
    removeHandle,
}: {
    data: IcommentResp;
    removeable: boolean;
    removeHandle: Function;
}) {
    return (
        <div className="sd-comment-item">
            <UserAvatar
                user={data}
                styles={{
                    width: 32,
                    height: 32,
                    marginRight: 8,
                    cursor: 'pointer',
                }}
            />
            <div style={{ flex: 1 }}>
                <CopyFrame>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#232930',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span style={{ flex: 1 }}>{data.realName}</span>
                        {removeable ? (
                            <span className="sd-remove" onClick={() => removeHandle()}>
                                {i18n.t('supervise:delete')}
                            </span>
                        ) : null}
                    </p>
                    <p
                        className="mattch-url"
                        style={{
                            fontSize: 14,
                            color: '#232930',
                            wordBreak: 'break-all',
                            whiteSpace: 'pre-wrap',
                            userSelect: 'auto',
                        }}
                        onClick={handleOpenUrl}
                        dangerouslySetInnerHTML={{
                            __html: mattchUrl(userEscapeString(data.commentContent), true),
                        }}
                    >
                        {/* {data.commentContent} */}
                    </p>
                    <p style={{ fontSize: 12, color: '#BFC1C4' }}>{sessionDateFormat(data.time)}</p>
                </CopyFrame>
            </div>
        </div>
    );
}
function testOwner(user: any, owner: Owner) {
    if (
        user?.userId === owner?.userId &&
        user?.teamUserInfo.teamId === owner?.teamId &&
        user?.ddAppId === owner?.appId
    ) {
        return true;
    }
    return false;
}

function getFrom(detailData: IcreateSupervise | null) {
    const from = i18n.t('supervise:from');
    const task = detailData ? i18n.t(`supervise:${detailData.sourceType}`) : '';
    const zhuanban = detailData?.sourceBizType === 'PROJECT' ? i18n.t('supervise:zhuanban') : '';
    // console.log(detailData, from, task, zhuanban, 'zhuanbanzhuanbanzhuanbanzhuanban');
    return `${from}${zhuanban}${task}`;
}
function getFinishIcon(appId: string) {
    return finishReminder;
}
function SuperviseDetail({
    dispatch,
    globalSuperviseId,
    userData,
    globalSuperviseType,
    detailDrawerVisible,
    changePersonalTaskDraw,
    setDetailDrawerVisible,
    openSolitaireDetails,
}: {
    dispatch: Function;
    globalSuperviseId: string;
    userData: any;
    globalSuperviseType?: SuperviseType;
    detailDrawerVisible: boolean;
    changePersonalTaskDraw: (taskId: string) => void;
    setDetailDrawerVisible: (visible: boolean) => void;
    openSolitaireDetails: (data: { isvisibles: boolean; solitaire: any }) => void;
}) {
    const { authInfo: authData } = useContext(GlobalContext);
    const [comments, setComments] = useState('');
    const [detailData, setDetailData] = useState<IcreateSupervise | null>(null);
    const [remarks, setRemarks] = useState('');
    const [superviseType, setSuperviseType] = useState<SuperviseType>();
    const [myData, setMyData] = useState<Receiver>(); // 在接收人列表中查找我的数据
    const [commentList, setCommentList] = useState<IcommentResp[]>([]);
    const [newCommentList, setNewCommentList] = useState<IcommentResp[]>([]); // 新增评论信息
    const [readFlag, setReadFlag] = useState(ReadFlag.unread);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [ownerIsReceiver, setOwnerIsReceiver] = useState(false);
    const [deleteCount, setDeleteCount] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [remarkId, setRemarkId] = useState(''); // 备注Id
    const [editing, setEditing] = useState(false);
    const limit = 20;
    const appId = useAppId();
    const changeSuperviseStatus = useCallback(
        (flag: ReadFlag) => {
            if (readFlag === ReadFlag.finish) {
                return;
            }
            readSupervise({
                superviseId: globalSuperviseId,
                readFlag: flag,
            }).then((res) => {
                setReadFlag(flag);
                bus.emit('supervise/update');
                // console.log(res, 'readSuperviseres========>');
                if (res[1] === '非法的催办ID') {
                    setDetailData(null);
                    setIsDeleted(true);
                    return;
                }
                getSuperviseInfo({
                    superviseId: globalSuperviseId,
                }).then((res) => {
                    const data: IcreateSupervise = res[0];
                    setDetailData(data);
                });
            });
        },
        [globalSuperviseId, readFlag]
    );
    async function deleteSupervise(superviseId: string) {
        if (isOwner) {
            await delSupervise({
                superviseId,
            });
        } else {
            await hideSuperviseUser({
                superviseId,
            });
        }
        onClose();
        bus.emit('supervise/update');
    }
    // flag 表示删除的是否为自己新增的 true：是
    function deleteComment(commentId: string, flag: boolean) {
        Modal.confirm({
            title: i18n.t('supervise:deleteComment'),
            okText: i18n.t('supervise:confirm'),
            content: i18n.t('supervise:deleteCommentDesc'),
            cancelText: i18n.t('supervise:cancel'),
            icon: <IconFont type="iconlog_warning" />,
            onOk: async () => {
                await deleteSuperviseComment({
                    superviseId: detailData!.superviseId,
                    commentId,
                });
                if (flag) {
                    let idx = newCommentList.findIndex((item: IcommentResp) => {
                        return item.commentId === commentId;
                    });
                    if (idx >= 0) {
                        setNewCommentList([
                            ...newCommentList.slice(0, idx),
                            ...newCommentList.slice(idx + 1),
                        ]);
                    }
                } else {
                    let idx = commentList.findIndex((item: IcommentResp) => {
                        return item.commentId === commentId;
                    });
                    if (idx >= 0) {
                        setCommentList([
                            ...commentList.slice(0, idx),
                            ...commentList.slice(idx + 1),
                        ]);
                    }
                    setDeleteCount(deleteCount + 1);
                }

                bus.emit('supervise/update');
            },
        });
    }
    // 获取备注
    const getRemark = (opt: IgetSuperviseRemark) => {
        getSuperviseServiceRemark(opt).then((res) => {
            const data = res[0] || {};
            setRemarkId(data?.remarkId || '');
            setRemarks(data?.remarkContent || '');
        });
    };
    // 修改备注
    const changeRemark = (opt: IcreateSuperviseRemark) => {
        if (!opt.remarkId) {
            delete opt.remarkId;
        }
        changeSuperviseServiceRemark(opt)
            .then((res) => {})
            .catch(() => {
                message.error('请重试');
            });
    };
    useEffect(() => {
        if (detailDrawerVisible && globalSuperviseId) {
            // 请求网络
            getSuperviseInfo({
                superviseId: globalSuperviseId,
            }).then((res) => {
                const data: IcreateSupervise = res[0];
                let err: string | null = res[1];
                console.log(res, '催办详情返回值');
                if (!data) {
                    setLoading(false);
                    setIsDeleted(true);
                    if (err) {
                        err = err === '网络请求异常' ? err : '';
                        setErrMsg(err);
                    }
                    return;
                } else if (data?.owner) {
                    setRemarks('');
                    getRemark({
                        superviseId: globalSuperviseId,
                        operator: {
                            userId: data.owner.userId,
                            appId: data.owner.appId,
                            teamId: data.owner.teamId,
                        },
                    });
                }
                const { user } = userData;
                const { owner, receiverList } = data;
                // 判断当前用户是否是owner 即 当前用户是否为督办的创建者
                if (testOwner(user, owner)) {
                    setIsOwner(true);
                    // 如果是创者的话，判断创建者是否在接收人列表中
                    let idxOwner = receiverList.findIndex((item) => {
                        return (
                            owner?.userId === item?.userId &&
                            owner?.teamId === item?.teamId &&
                            owner?.appId === item?.appId
                        );
                    });
                    if (idxOwner >= 0) {
                        setOwnerIsReceiver(true);
                    }
                }

                // 确定 展示类型
                if (!globalSuperviseType) {
                    if (testOwner(user, owner)) {
                        // 表示自己是 发送者
                        setSuperviseType(SuperviseType.Send);
                    } else {
                        setSuperviseType(SuperviseType.Receive);
                    }
                } else {
                    setSuperviseType(globalSuperviseType);
                }
                // 在接收人列表中查找当前登陆用户的信息
                let idx = receiverList.findIndex((item) => {
                    return (
                        user?.userId === item?.userId &&
                        user?.teamUserInfo.teamId === item?.teamId &&
                        user?.ddAppId === item?.appId
                    );
                });

                if (idx >= 0) {
                    setMyData(receiverList[idx]);
                    setReadFlag(receiverList[idx]?.readFlag);
                    if (receiverList[idx]?.readFlag === ReadFlag.unread) {
                        changeSuperviseStatus(ReadFlag.read);
                    }
                }

                setDetailData(data);
            });
            getComments(0);
        } else {
            setDetailData(null);
            setIsOwner(false);
            setNewCommentList([]);
            setCommentList([]);
            setDeleteCount(0);
            setReadFlag(ReadFlag.unread);
            setIsDeleted(false);
            setOwnerIsReceiver(false);
        }
    }, [detailDrawerVisible]); // eslint-disable-line react-hooks/exhaustive-deps
    const statusCount = useMemo(() => {
        let countRead = 0;
        let countFinish = 0;
        let unreadCount = 0;
        if (!detailData) {
            return [0, 0, 0];
        }
        const receiverList = detailData?.receiverList;
        receiverList?.forEach((item: Receiver) => {
            if (item.readFlag === ReadFlag.read) {
                countRead++;
            }
            if (item.readFlag === ReadFlag.unread) {
                unreadCount++;
            }
            if (item.readFlag === ReadFlag.finish) {
                countFinish++;
            }
        });
        return [unreadCount, countRead, countFinish];
    }, [detailData]);
    const inputOkHandle = (v: string) => {
        // 调用新增
        // setComments(v);
        if (!v?.trim().length) {
            message.warn(i18n.t('supervise:commentPlaceholder'));
            return;
        }
        createSuperviseComment({
            superviseId: globalSuperviseId,
            commentContent: v,
        }).then((res) => {
            const newData: IcommentResp = res[0];
            if (newData) {
                setNewCommentList([{ ...newData }, ...newCommentList]);
            }
            bus.emit('supervise/createComment', newData);
        });
    };
    function onClose() {
        setDetailDrawerVisible(false);
    }
    function openTask(type: EsuperviseSourceType) {
        onClose();
        // let id = JSON.parse(detailData?.sourceParam || '').id || '';
        // if (id) {
        //     changePersonalTaskDraw(id);
        // }
        if (type === EsuperviseSourceType.WORK) {
            let id = JSON.parse(detailData?.sourceParam || '').id || '';
            if (id) {
                changePersonalTaskDraw(id);
            }
        } else if (type === EsuperviseSourceType.SCHEDULE) {
            let deeplink = JSON.parse(detailData?.sourceParam || '').deeplink || '';
            if (deeplink) {
                DeeplinkEvent.open(deeplink, { from: ScheduleDetailFrom.Supervise });
            }
        } else if (type === EsuperviseSourceType.IM) {
            // 打开IM会话
            const args = JSON.parse(detailData?.sourceParam || '').arguments || {};
            let sessionType = args.sessionType || '';
            let sessionId = args.sessionId || '';
            if (SessionType.SINGLE === sessionType) {
                // 我发出的跳到发送人，我收到的使用sessionId
                const owner = detailData?.owner;
                const ids = sessionId.split(':');
                if (ids.length < 3) {
                    return;
                }
                // 前段为要发送的人，后段为发送者
                if (ids.length === 3) {
                    launchSingleSession({
                        pin: ids[0],
                        app: ids[1],
                        teamId: ids[2],
                    });
                } else {
                    if (owner?.userId !== authData.userId) {
                        launchSingleSession({
                            pin: owner?.userId,
                            app: owner?.appId,
                            teamId: owner?.teamId,
                        });
                    } else {
                        const sendIdx = authData.userId === ids[0] ? 3 : 0;
                        // 跳转至发送者
                        launchSingleSession({
                            pin: ids[0 + sendIdx],
                            app: ids[1 + sendIdx],
                            teamId: ids[2 + sendIdx],
                        });
                    }
                }
            } else if (SessionType.GROUP === sessionType) {
                launchGroupSession(sessionId);
            }
        } else if (type === EsuperviseSourceType.NOTIFICATION) {
            let id = JSON.parse(detailData?.sourceParam || '').id || '';
            bus.emit('supervise/notification', id);
        } else if (type === EsuperviseSourceType.SOLITAIRE) {
            let id = JSON.parse(detailData?.sourceParam || '').id || '';
            openSolitaireDetails({
                isvisibles: true,
                solitaire: id,
            });
        }
    }
    const history = useHistory();
    // 拉起会话
    const launchSingleSession = (from: any) => {
        const { app, pin, teamId } = from;
        setTimeout(() => {
            if (!pin) return;
            history.push(`/messages/s/${pin}?app=${app}&tid=${teamId}&top=true`);
        }, 1);
    };
    const launchGroupSession = (from: any) => {
        setTimeout(() => {
            history.push(`/messages/g/${from}?top=true`);
        }, 1);
    };

    function getComments(offset: number) {
        setLoading(true);
        getSuperviseComment({
            limit,
            offset,
            superviseId: globalSuperviseId,
        })
            .then((res) => {
                const data = res[0]?.commentList || [];
                setCommentList([...commentList, ...data]);
                if (data.length < limit) {
                    setHasMore(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const repeat = useCallback(() => {
        repeatSupervise({
            superviseId: detailData!.superviseId,
        })
            .then((res) => {
                const [content, error] = res;
                if (error) {
                    // const errorMsg = error.split(']')[1] ? error.split(']')[1] : error;
                    message.error(error || '操作失败');
                    return;
                }
                message.success(i18n.t(`supervise:superviseSuccess${appId}`));
                bus.emit('supervise/update');
            })
            .catch(() => {
                message.error(i18n.t(`supervise:superviseError${appId}`));
            });
    }, [detailData, appId]);

    function removeSupervise() {
        Modal.confirm({
            title: i18n.t(`supervise:deleteSupervise${appId}`),
            content: i18n.t(`supervise:deleteSuperviseDesc${appId}`),
            okText: i18n.t('supervise:confirm'),
            cancelText: i18n.t('supervise:cancel'),
            icon: <IconFont type="iconlog_warning" />,
            onOk: () => {
                deleteSupervise(detailData!.superviseId);
            },
        });
    }

    const createHeaderDOM = () => {
        return (
            <div className="sd-header">
                <div>
                    {superviseType === SuperviseType.Receive || ownerIsReceiver
                        ? '催办详情'
                        : detailData && (detailData as any).sendType === EsendType.APP
                        ? i18n.t(`supervise:inAPPSupervise${appId}`)
                        : i18n.t(`supervise:smsSupervise${appId}`)}
                </div>
                <div className="sd-remove-btn" onClick={removeSupervise}>
                    <IconFont type="icondelect" />
                </div>
            </div>
        );
    };
    const createFooterDOM = () => {
        return (
            <div className="supervise-detail-footer">
                {(!isDeleted && superviseType === SuperviseType.Receive) || ownerIsReceiver ? (
                    <Button
                        className={`sd-btn ${readFlag === ReadFlag.finish ? 'sd-btn-finish' : ''}`}
                        onClick={() => changeSuperviseStatus(ReadFlag.finish)}
                        disabled={readFlag === ReadFlag.finish}
                        type="primary"
                    >
                        {readFlag === ReadFlag.finish
                            ? i18n.t('supervise:finished')
                            : i18n.t(`supervise:finishSupervise${appId}`)}
                    </Button>
                ) : null}
            </div>
        );
    };
    const sMiddle: CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    };
    return (
        <Drawer
            className="supervise-detail-drawer"
            width={450}
            placement="right"
            visible={detailDrawerVisible}
            onClose={() => onClose()}
            title={createHeaderDOM()}
            footer={createFooterDOM()}
        >
            {isDeleted && (
                <div>
                    {/* <div className="sd-header">
                        <p>{i18n.t(`supervise:superviseDetail${appId}`)}</p>
                        <IconFont
                            onClick={() => onClose()}
                            style={{ fontSize: 10 }}
                            type="iconlog_close"
                        />
                    </div> */}
                    <div style={sMiddle}>
                        <img
                            style={{
                                width: 140,
                                height: 140,
                            }}
                            src={changeEgovUrl(SuperviseDel)}
                        />
                        <p
                            style={{
                                fontSize: 14,
                                color: '#8F959E',
                                textAlign: 'center',
                            }}
                        >
                            {/* eslint complexity: ["error", 40] */}
                            {errMsg || i18n.t(`supervise:superviseDel${appId}`)}
                        </p>
                    </div>
                </div>
            )}
            {detailData ? (
                <div className="supervise-detail-content">
                    <div className="sd-top">
                        {readFlag === ReadFlag.finish && (
                            <div className="finish-icon">
                                <img src={changeEgovUrl(getFinishIcon(appId))} />
                            </div>
                        )}
                        <div className="sd-user-info">
                            <UserAvatar user={detailData.owner} />
                            <div className="sd-user-info_name">
                                <p className="realName">{detailData.owner.realName}</p>
                                <p className="time">{sessionDateFormat(detailData.time)}</p>
                            </div>
                        </div>

                        {/* 我发出的 或者 发给自己的 */}
                        {superviseType === SuperviseType.Send ? (
                            <div className="sd-users">
                                <div className="sd-users-label">发给：</div>
                                <div className="sd-users-box">
                                    <UserNameList
                                        list={detailData?.receiverList || []}
                                        isDetail={true}
                                    />
                                </div>
                                <ReceiveStatusPopover
                                    onRemove={() => {}}
                                    disable={false}
                                    popoverVisibleChange={(v: boolean) => {}}
                                    superviseRepeat={repeat}
                                    superviseId={globalSuperviseId}
                                    unFinishCount={detailData.unReadReceiverCount}
                                    finishCount={
                                        detailData.totalReceiverCount -
                                        detailData.unReadReceiverCount
                                    }
                                >
                                    <div
                                        className="sd-users-unread"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <span>{i18n.t('supervise:unread')}</span>
                                        <span>{detailData.unReadReceiverCount}人</span>
                                        <IconFont
                                            className="sd-users-unread_icon"
                                            type="iconwin_right"
                                        />
                                    </div>
                                </ReceiveStatusPopover>
                            </div>
                        ) : null}
                    </div>
                    <div className="sd-bottom">
                        {/* 评论 */}
                        <div className="sd-leave-message">
                            <CopyFrame>
                                <p
                                    className="mattch-url"
                                    onClick={handleOpenUrl}
                                    dangerouslySetInnerHTML={{
                                        __html: mattchUrl(
                                            userEscapeString(detailData.content),
                                            true
                                        ),
                                    }}
                                />
                            </CopyFrame>
                            {detailData.sourceParam && (
                                <span
                                    onClick={() => openTask(detailData.sourceType)}
                                    className="sd-from"
                                >
                                    {getFrom(detailData)}：
                                    {JSON.parse(detailData.sourceParam).type ? (
                                        <Tab
                                            from={detailData.sourceType}
                                            type={JSON.parse(detailData.sourceParam).type}
                                        />
                                    ) : null}
                                    <span className="sd-from_title">
                                        {JSON.parse(detailData.sourceParam).title}
                                    </span>
                                    <IconFont type="iconwin_right" />
                                </span>
                            )}
                        </div>
                        <div className="sd-remark">
                            <div className="sd-remark-top">
                                <div className="sd-remark-label">
                                    我的备注<span>(仅您自己可见)</span>
                                </div>
                                <div className="sd-remark-edit">
                                    <Tooltip placement="top" title="点击编辑">
                                        <IconFont
                                            type="iconjs_ic_edit"
                                            onClick={() => {
                                                setEditing(true);
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <Paragraph
                                placeholder="请输入"
                                content={remarks}
                                editing={editing}
                                handleSetEditing={(value: boolean) => {
                                    setEditing(value);
                                }}
                                onEnd={async (value: string) => {
                                    await changeRemark({
                                        remarkId: remarkId,
                                        superviseId: globalSuperviseId,
                                        remarkContent: value,
                                        operator: {
                                            appId: detailData.owner.appId,
                                            teamId: detailData.owner.teamId,
                                            userId: detailData.owner.userId,
                                        },
                                    });
                                    setRemarks(value);
                                }}
                                maxLength={500}
                            />
                        </div>

                        <div className="sd-comment-box">
                            <div className="sd-comment-list">
                                <div className="sd-comment-list_label">
                                    {i18n.t('supervise:comments')}{' '}
                                    {(detailData?.commentCount || 0) +
                                        newCommentList.length -
                                        deleteCount}
                                </div>
                                {!loading && !newCommentList?.length && !commentList?.length ? (
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            color: '#232930',
                                            margin: '20% 0',
                                        }}
                                    >
                                        {i18n.t('supervise:noComment')}
                                    </p>
                                ) : null}
                                {newCommentList?.map((item: IcommentResp) => (
                                    <CommentItem
                                        removeable={testOwner(userData?.user, item)}
                                        key={item.commentId}
                                        data={item}
                                        removeHandle={() => deleteComment(item.commentId, true)}
                                    />
                                ))}
                                {commentList?.map((item: IcommentResp) => (
                                    <CommentItem
                                        removeable={testOwner(userData?.user, item)}
                                        key={item.commentId}
                                        data={item}
                                        removeHandle={() => deleteComment(item.commentId, false)}
                                    />
                                ))}
                                {commentList?.length && hasMore ? (
                                    <LoadMore
                                        clickHandle={() => {
                                            getComments(commentList.length);
                                        }}
                                        loading={loading}
                                    />
                                ) : null}
                            </div>
                            <CommentInput onOk={inputOkHandle} user={userData?.user} />
                        </div>
                    </div>
                </div>
            ) : loading ? (
                <div style={sMiddle}>
                    <Loading />
                </div>
            ) : null}
        </Drawer>
    );
}

export default connect(
    ({ supervise, user }: any) => ({
        globalSuperviseId: supervise.globalSuperviseId,
        globalSuperviseType: supervise.globalSuperviseType,
        userData: user.userData,
        detailDrawerVisible: supervise.detailDrawerVisible,
    }),
    (dispatch: any) => {
        return {
            changePersonalTaskDraw: (taskId: string) =>
                dispatch({
                    type: 'work/changePersonalTaskDraw',
                    payload: { taskId, drawVisible: true, chat: true },
                }),
            setDetailDrawerVisible: (visible: boolean) =>
                dispatch({
                    type: 'supervise/setDetailDrawerVisible',
                    payload: { visible },
                }),
            openSolitaireDetails(data: { isvisibles: boolean; solitaire: any }) {
                dispatch({ type: 'chat/openSolitaireDetails', payload: data });
            },
        };
    }
)(SuperviseDetail);
