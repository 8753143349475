/**
 * 督办 du
 */

import React, { useEffect, useState, useCallback } from 'react';
import IconFont, { getIconFromString } from '@/components/icon';
import './index.less';
import { queryNoRead } from '@/api/supervise';
import bus from '@/utils/bus';
import { useAppId } from '@/components/Supervise/envirment';
import { FocusSDK, isFocusEnv } from '@/utils';
import { Tooltip } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
export default function ExtendNavs({
    active,
    clickHandle,
}: {
    active: boolean;
    clickHandle: (path: string) => void;
}) {
    const [readState, setReadState] = useState(false);
    const appId = useAppId();
    const getState = useCallback(() => {
        queryNoRead()
            .then((res: any) => {
                const { readStatus } = res?.[0] || {};
                if (readStatus === 'YES') {
                    setReadState(true);
                } else {
                    setReadState(false);
                }
            })
            .catch();
    }, [setReadState]);
    useEffect(() => {
        getState();
    }, [getState]);
    useEffect(() => {
        bus.addListener('supervise/update', getState);
        return () => {
            bus.removeListener('supervise/update', getState);
        };
    }, [getState]);

    const { appRuntimeEnv } = React.useContext<GlobalContextConfig>(GlobalContext);

    const openUrl = useCallback(() => {
        let currentOrigin = FocusSDK.getMeMainEntry();
        let helpUrl = '';
        if (appRuntimeEnv.envConfig?.helpUrl) {
            helpUrl = appRuntimeEnv?.envConfig?.helpUrl;
        } else {
            helpUrl = `${currentOrigin}/guide/#/pc`;
            if (process.env.REACT_APP_NODE_ENV !== 'production' && appRuntimeEnv.env !== 'prod') {
                helpUrl = `${currentOrigin}/jingzhiban#/pc`;
            }
        }
        console.log('currentOrigin', currentOrigin);
        if (!isFocusEnv()) {
            helpUrl = `${helpUrl}?web=true`;
            // window.open(helpUrl);
        } else {
            // window.open(helpUrl);
        }
    }, [appRuntimeEnv.env, appRuntimeEnv.envConfig?.helpUrl]);
    // if (FocusSDK.getDeviceInfo().platform === 'web') {
    //     return null;
    // }

    return (
        <>
            <div
                role="button"
                tabIndex={0}
                onClick={() => {
                    clickHandle('/supervise');
                }}
                className={`extend-nav-item ${active ? 'active' : ''}`}
                clstag="pageclick|keycount|focus_home_01_1615797361152|6"
            >
                {readState ? <p className="eni-dot" /> : null}
                <Tooltip placement="right" title="催办">
                    <IconFont style={{ fontSize: 22 }} type="iconzw_app_remind1" />
                </Tooltip>
            </div>

            {/* <div role="button" onClick={openUrl} className="extend-nav-item">
                <Tooltip placement="right" title="操作指引">
                    <IconFont style={{ fontSize: 22 }} type="iconic_question" />
                </Tooltip>
            </div> */}
        </>
    );
}
