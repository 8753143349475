import cookies from 'js-cookie';
import {
    getLoginURL,
    isFocusEnv,
    FocusSDK,
    getAppId,
    relogin,
    getJanusStage,
    getAppSecretKey,
} from '@/utils';
import md5 from 'js-md5';
import {
    GatewayProps,
    GatewayRequestOptions,
    FocusAuthProps,
    GatewayResp,
    GatewaySuccessResp,
    GatewayJoySpaceSuccessResp,
    GatewayJoySpaceBannerResp,
} from './types';
import UAParser from 'ua-parser-js';
const parser = new UAParser();

import axios, { AxiosInstance, Method as AxiosRequestMethod, AxiosRequestConfig } from 'axios';
import { GATEWAY_BASE, GATEWAY_VERSION, GATEWAY_STAGE } from './constants';
import cache from '@/utils/cache';
import i18n from '@/i18n';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import { AuthData, RegistryConfigData } from '@/types/LocalConfig';
import { message } from 'antd';
import { v4 } from 'uuid';
import getVersionBySdk from '@/utils/getVersion';
/**
 *
 *
 * @export
 * @class Gateway
 */

export default class Gateway {
    private registryConfig: RegistryConfigData;
    private client: AxiosInstance;
    private auth?: FocusAuthProps;
    private appKey: string;
    private encrypt: boolean;
    private focusTeamID: string;
    private focusToken: string;
    private appId: string;
    private deviceId: string;
    private platform: string;
    private userData: any;
    private isFocus: boolean;
    private SecretKey: string;
    private domain: string;
    private janusBaseUrl: string;
    /**
     *Creates an instance of Gateway.
     * @param {GatewayProps} [props={}]
     * @param authInfo
     * @param appRuntimeEnv
     * @memberof Gateway
     */
    constructor(props: GatewayProps, authInfo: AuthData, appRuntimeEnv: AppRuntimeEnv) {
        const { host, protocol, appId, appKey, stage, focusAuth, version, encrypt } = props as any;
        const baseUrl = `${protocol}://${host}`;
        const { REACT_APP_JANUS_HOST } = process.env;
        this.janusBaseUrl = baseUrl || REACT_APP_JANUS_HOST || GATEWAY_BASE || '';
        // 根据环境初始化数据
        this.isFocus = isFocusEnv();
        this.appId = isFocusEnv() ? appId || getAppId() || '' : getAppId();
        this.registryConfig = appRuntimeEnv.registryConfig;
        this.deviceId = appRuntimeEnv.registryConfig.machineId;
        this.platform = appRuntimeEnv.registryConfig.client;
        this.focusTeamID = appRuntimeEnv.selectedTeamId;
        this.focusToken = authInfo.accessToken;
        this.userData = authInfo;
        this.domain = appRuntimeEnv.domain;
        const { pkgVersion } = this.registryConfig;
        // this.SecretKey = '';
        if (FocusSDK.getDeviceInfo().platform === 'web') {
            this.SecretKey = process.env.REACT_APP_WEB_SECRETKEY || '';
        } else {
            this.SecretKey =
                appRuntimeEnv?.gateway?.secretKey ||
                getAppSecretKey(appRuntimeEnv.runtimeConfig.key);
        }
        // 创建客户端
        const url = baseUrl || REACT_APP_JANUS_HOST || GATEWAY_BASE;
        console.log('=======', baseUrl, REACT_APP_JANUS_HOST, GATEWAY_BASE, url);
        this.client = axios.create({
            baseURL: baseUrl || REACT_APP_JANUS_HOST || GATEWAY_BASE,
            headers: {
                'x-gw-version': version || GATEWAY_VERSION,
                'x-stage': stage || getJanusStage() || GATEWAY_STAGE,
                'x-os': parser.getOS().name,
                'x-os-version': parser.getOS().version,
                'x-model': parser.getDevice().model,
                'Access-Control-Allow-Origin': true,
                // web端没有版本号，固定版本为 1.9.0
                'x-app-version': getVersionBySdk(FocusSDK) || pkgVersion.split('-')[0],
            },
            withCredentials: true,
        });
        this.appKey = appKey || '';
        this.encrypt = typeof encrypt === 'undefined' ? false : encrypt; // 是否使用参数加密
        this.auth = focusAuth;
    }
    // eslint-disable-next-line
    sendRequest = async <T>(opts: GatewayRequestOptions): Promise<T> => {
        // cfg
        const cfg: AxiosRequestConfig = {
            url: opts.dynamicPath || `/${opts.api}`,
            method: opts.method as AxiosRequestMethod,
            params: opts.params,
        };
        console.log('cfg', cfg);

        // headers
        const nonce = Date.now().toString(36);
        const ts = Date.now();
        const headers: { [key: string]: string | number } = {
            'x-api': opts.api,
            'x-nonce': nonce,
            'x-ts': ts,
            'x-trace-id': v4(),
        };
        // 接口环境
        if (opts.stage) {
            headers['x-stage'] = opts.stage;
        }
        // 认证信息
        const auth = opts.auth || this.auth;
        if (auth) {
            headers['x-token'] = auth.focusToken; // 0bf51668f8204922a7d78d54b8f51bcb
            headers['x-team-id'] = auth.focusTeamId; // mS2CKU8HN3UFxdliJ1AxR
            headers['x-client'] = auth.focusClient; // 468467382424309760
        } else {
            headers['x-app'] = this.appId;
            headers['x-token'] = this.focusToken;
            headers['x-team-id'] = this.focusTeamID; // mS2CKU8HN3UFxdliJ1AxR
            headers['x-client'] = this.platform; // 468467382424309760
            headers['x-did'] = this.deviceId; // 468467382424309760
        }
        if (!isFocusEnv()) {
            let { 'force-x-client': forceXClient } = opts.headers || {};
            headers['x-client'] = forceXClient || 'WEB';
        }
        const xClientHeaders = headers['x-client'];
        // x-gw-version，x-app，secretKey，api，x-ts，x-nonce，x-client
        const xContent = opts.body ? md5(JSON.stringify(opts.body)) : '';
        xContent && (headers['x-content-md5'] = xContent);
        const tempString = `${GATEWAY_VERSION}&${this.appId}&${this.SecretKey}&${
            opts.api
        }&${ts}&${nonce}&${xClientHeaders}${xContent ? '&' + xContent : ''}`;
        const sign = md5(tempString);
        headers['x-sign'] = sign;
        cfg.headers = headers;
        let { 'x-host': xHost, 'x-flag': xFlag } = opts.headers || {};
        if (xHost) {
            cfg.headers['x-host'] = xHost;
        }
        if (xFlag) {
            cfg.headers['x-flag'] = xFlag;
        }
        // body
        if (opts.body) {
            cfg.data = opts.body;
        }
        let resp: any;
        try {
            console.log('try=====', cfg);
            resp = await this.client.request<GatewayResp<T>>(cfg);
        } catch (error) {
            console.log('gateway-err', error);
            // @ts-ignore
            if (error?.response?.data) {
                const { gw_code: gwCode, gw_echo: gwEcho } = error.response.data; // eslint-disable-line
                // throw new Error(`[${gwCode}]${gwEcho}`);
                throw new Error(gwEcho);
            } else {
                throw new Error(i18n.t('Network Error'));
            }
        }
        if (resp.status === 200) {
            console.log(resp.data, 'resprespresp====>');
            const data = resp?.data || {};
            if (data.errorCode === '0') {
                return (data as GatewaySuccessResp<T>).content; // 返回结果
            } else if (data.status === 'success') {
                return (data as GatewayJoySpaceSuccessResp<T>).data; // 返回joyspace接口结果
            } else if (data.code === '10002' || data.code === '10001') {
                return data; // 返回joyspace接口结果
            } else if (data.homepage) {
                return (data as GatewayJoySpaceBannerResp<T>).homepage; // 返回joyspace Banner接口结果
            } else if (data.errorCode === '0002') {
                // 表示登陆态失效
                FocusSDK.printLog('gateway error，need relogin', data);
                relogin();
                // todo web端直接跳转登陆页面，客户端调用更新状态接口
            }
            // 当错误码为7XXXX时，要单独错误提示
            if (data.errCode && /^7[0-9]{4}$/.test(String(data.errCode)) && data.errMsg) {
                message.error(data.errMsg);
            }
            // return data as any;
            FocusSDK.printLog(
                'gatewayInterfaceErrorReported',
                `api: ${opts.api}--error: ${JSON.stringify({ data })}`
            ); // 日志：网关接口请求报错]
            throw new Error(`${data?.errorMsg || data?.errMsg || ''}`);
        }
        return resp.data as any;
    };
    // eslint-disable-next-line complexity
    request = async <T>(opts: GatewayRequestOptions) => {
        // 用户信息获取
        if (!this.userData && this.isFocus) {
            await new Promise((resolve) => {
                FocusSDK.getAuthInfo((userData: any) => {
                    this.userData = userData;
                    resolve();
                });
            });
            this.appId = this.userData.app.appId;
            this.deviceId = this.userData.app.deviceId;
            this.platform = ((opts.headers?.platform || '') as string) || this.userData.app.client;
            this.focusToken = this.userData.accessToken;
            this.focusTeamID = this.userData.user.team.teamId;
            // this.SecretKey = getSecretKey() || '';
            return this.sendRequest<T>(opts);
        } else if (this.isFocus) {
            // this.SecretKey = getSecretKey() || '';
            this.platform = ((opts.headers?.platform || '') as string) || this.platform;
            return this.sendRequest<T>(opts);
        } else if (cache.tokenProvider) {
            if (!cache.token) {
                try {
                    cache.token = await cache.tokenProvider.token();
                } catch (e) {}
            }
            if (cache.token) {
                this.appId = getAppId() || '';
                this.focusTeamID = this.focusTeamID || cache.token.token.focusTeamId;
                this.focusToken = this.focusToken || cache.token.token.focusToken;
                this.platform =
                    ((opts.headers?.platform || '') as string) ||
                    this.platform ||
                    cache.token.token.focusClient;
                this.deviceId = this.deviceId || cache.token.deviceCode;
                return this.sendRequest<T>(opts);
            }
        } else {
            this.appId = getAppId() || '';
            this.focusTeamID = this.focusTeamID || cookies.get('focus-team-id') || '';
            this.focusToken = this.focusToken || cookies.get('focus-token') || '';
            this.platform = ((opts.headers?.platform || '') as string) || 'IPAD';
            if (!this.focusToken) {
                // web环境下不存在focusToken时，跳转登陆页面
                location.href = getLoginURL();
            }
            return this.sendRequest<T>(opts);
        }
    };
    getBaseUrl() {
        return this.janusBaseUrl;
    }
}

export let gatewayInstance: Gateway;

export function setGatewayInstance(
    props: GatewayProps,
    authInfo: AuthData,
    appRuntimeEnv: AppRuntimeEnv
) {
    gatewayInstance = new Gateway(props, authInfo, appRuntimeEnv);
}
