import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Input, Button, Select, Checkbox } from 'antd';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import logger from '@/utils/logger';
import { getRuntimeConfig } from '@/utils/envConfig';
import { AppRuntimeConfig } from '@/types/AppRuntimeEnv';
import { Loading } from '@jd/focus-desktop-comps';
import { APP_RUNTIME } from '@/contant/runtime';

const log = logger.getLogger('NetworkSettingsModal');

const { Option } = Select;

function FormBody({ onClose }: { onClose: () => void }) {
    const { appRuntimeEnv, focusSDK } = React.useContext<GlobalContextConfig>(GlobalContext);
    const { runtimeConfig } = appRuntimeEnv.registryConfig;
    const [allRuntimeConfig, setAllRuntimeConfig] = useState<AppRuntimeConfig[]>([]);
    // const [selectedRuntime, setSelectedRuntime] = useState<AppRuntimeConfig | undefined>(undefined);
    // const [envName, setEnvName] = React.useState(runtimeConfig.env);
    const [entryDev, setEntryDev] = useState(runtimeConfig.entryDev);
    // const [customEntry, setCustomEntry] = useState(
    //     runtimeConfig.webEntry || 'http://desktop.jdcloud.com:3000'
    // );
    const [customEntry, setCustomEntry] = useState(
        runtimeConfig.webEntry || 'http://desktop.jdcloud.com:3000'
    );
    const entryValueRef = useRef(runtimeConfig.webEntry);
    console.log('allRuntimeConfig==', allRuntimeConfig, '=====', runtimeConfig);
    const selectedRuntime = allRuntimeConfig.find((item) => item.key === runtimeConfig.runtime);
    console.log('selectedRuntime==', selectedRuntime);
    const saveRuntimeConfig = useCallback(
        (config: any, reload = false) => {
            if (config.webEntry) {
                entryValueRef.current = config.webEntry;
                try {
                    const url = new URL(config.webEntry);
                } catch (e) {
                    Modal.error({
                        content: `Invalid url: ${config.webEntry}`,
                    });
                    return;
                }
            }
            console.log('saveRuntimeConfig--', {
                ...runtimeConfig,
                ...config,
            });
            if (reload) {
                focusSDK.on('configDataChange', focusSDK.relaunchApp);
            }

            focusSDK.setConfigData('runtimeConfig', {
                ...runtimeConfig,
                ...config,
            });
        },
        [focusSDK, runtimeConfig]
    );

    const onSelectRuntime = useCallback(
        (runtime: string) => {
            const item = allRuntimeConfig.find((item) => item.key === runtime);
            if (item) {
                const [data] = item.config.environments;
                if (data) {
                    const newRuntime = {
                        runtime,
                        webEntry: entryDev ? customEntry : '',
                        env: data.key,
                    };
                    saveRuntimeConfig(newRuntime);
                }
            }
        },
        [allRuntimeConfig, customEntry, entryDev, saveRuntimeConfig]
    );

    const onSelectEnv = useCallback(
        (env: string) => {
            const item = allRuntimeConfig.find((item) => item.key === runtimeConfig.runtime);
            if (!item) {
                return;
            }
            const data = item.config.environments.find((item) => item.key === env);
            if (!data) {
                return;
            }
            const newRuntime = {
                runtime: runtimeConfig.runtime,
                webEntry: entryDev ? customEntry : '',
                env: data.key,
            };
            saveRuntimeConfig(newRuntime);
        },
        [allRuntimeConfig, customEntry, entryDev, runtimeConfig.runtime, saveRuntimeConfig]
    );

    const loadRuntimes = useCallback(async () => {
        // const data = await getRuntimeConfig(focusSDK.isBeta());
        const data = await getRuntimeConfig(true);
        log.debug('loadRuntimes ==>', data);
        const runtimeConfigs = data.filter((item) => {
            // return APP_RUNTIME.includes(item.key);
            return item.key === process.env.REACT_APP_RUNTIME || '';
        });
        log.debug('loadRuntimes ==>runtimeConfigs', runtimeConfigs);
        setAllRuntimeConfig(runtimeConfigs);
        // const item = data.find((item) => item.key === runtimeConfig.runtime);
        // if (item) {
        //     setResult(item);
        // }
    }, []); //eslint-disable-line

    useEffect(() => {
        loadRuntimes();
    }, [loadRuntimes]);

    useEffect(() => {
        let entry;
        if (entryDev) {
            entry = customEntry;
        } else {
            const item = allRuntimeConfig.find((item) => item.key === runtimeConfig.runtime);
            if (!item) {
                return;
            }
            const data = item.config.environments.find((item) => item.key === runtimeConfig.env);
            if (!data) {
                return;
            }
            entry = data.app.entry;
        }
        saveRuntimeConfig({
            webEntry: entry,
            entryDev,
        });
    }, [entryDev]); // eslint-disable-line

    if (!selectedRuntime) {
        return (
            <div className="flex1 flex-center">
                <Loading />
            </div>
        );
    }

    return (
        <div>
            <h4>App Runtime</h4>
            <Select
                style={{ width: '100%' }}
                value={runtimeConfig.runtime}
                onChange={onSelectRuntime}
            >
                {allRuntimeConfig.map((runtime) => {
                    return (
                        <Option key={runtime.key} value={runtime.key}>
                            {runtime.name}
                        </Option>
                    );
                })}
            </Select>
            <h4 style={{ marginTop: 20 }}>App Env</h4>
            <Select style={{ width: '100%' }} value={runtimeConfig.env} onChange={onSelectEnv}>
                {selectedRuntime.config.environments.map((env) => {
                    return (
                        <Option key={env.key} value={env.key}>
                            {env.name}
                        </Option>
                    );
                })}
                {/* {appRuntimeEnv.runtimeConfig.config.environments.map((env) => { */}
                {/*    return ( */}
                {/*        <Option key={env.key} value={env.key}> */}
                {/*            {env.name} {env.app.entry} */}
                {/*        </Option> */}
                {/*    ); */}
                {/* })} */}
            </Select>
            <h4 style={{ marginTop: 20 }}>App Entry</h4>
            <Input
                addonBefore={
                    <Checkbox
                        checked={entryDev}
                        onChange={(e) => {
                            setEntryDev(e.target.checked);
                        }}
                    >
                        自定义 Web Entry
                    </Checkbox>
                }
                disabled={!entryDev}
                value={customEntry}
                onChange={(e) => {
                    setCustomEntry(e.target.value);
                    entryValueRef.current = e.target.value;
                }}
            />
            <div
                style={{
                    marginTop: 20,
                    textAlign: 'right',
                }}
            >
                <Button
                    style={{
                        marginRight: 10,
                    }}
                    onClick={onClose}
                >
                    关闭
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        // const webEntry = entryDev ? customEntry : runtimeConfig.webEntry;
                        let webEntry = '';
                        if (entryDev) {
                            try {
                                const url = new URL(customEntry);
                                webEntry = url.toString();
                            } catch (e) {
                                //
                            }
                        }
                        // focusSDK.reloadApp(webEntry);
                        saveRuntimeConfig(
                            {
                                webEntry,
                            },
                            true
                        );
                        onClose();
                    }}
                >
                    确定
                </Button>
            </div>
        </div>
    );
}

export interface NetworkSettingsModalProps {
    visible: boolean;
    onCancel: () => void;
}

export default function NetworkSettingsModal({ visible, onCancel }: NetworkSettingsModalProps) {
    return (
        <Modal title="环境设置" visible={visible} onCancel={onCancel} footer={null}>
            <FormBody onClose={onCancel} />
        </Modal>
    );
}
