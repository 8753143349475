import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import bus from '@/utils/bus';
import GlobalContext from '@/context/GlobalContext';
import { FocusSDK } from '@/utils';
import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { cloudlinkRegister, updateRecentLoginTime } from '@/api/meeting';
import { changeCloudLinkStatus, CloudLinkStatusEnum, getCloudLinkStatus } from '../Connector';
import { joinCloudLink } from '../utils';
import { analysisLog } from '@/utils/logAnalytics';

export interface JoinMeetingResult {
    meetingNum: string;
    meetingPass: string;
}

interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
}

export default function JoinMeetingModal() {
    const [showPass, setShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [meetingNum, setMeetingNum] = useState('');
    const [pass, setPass] = useState('');
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const [t_common] = useTranslation('common');
    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
    });
    const { authInfo = {} as any } = useContext(GlobalContext);
    const { teamUserInfo, userId } = authInfo;
    const inputRef = React.useRef<Input | null>(null);
    useEffect(() => {
        function handleModalVisible() {
            setVisible(true);
        }
        bus.on('cloudlink:join-show', handleModalVisible);
        return () => {
            bus.off('cloudlink:join-show', handleModalVisible);
        };
    }, []);

    function closeModal() {
        setMeetingNum('');
        setPass('');
        setVisible(false);
        setShowPass(false);
        setConnect(false);
        setState({
            videoDisable: true,
            audioDisable: true,
        });
    }
    function onMeetingNumChange(event: React.ChangeEvent<HTMLInputElement>) {
        let { value } = event.target;
        value = value.trim().replace(/\s+/g, '');
        if (connect) {
            return;
        }
        if (!/^[\d\s]{0,7}$/.test(value)) {
            return;
        }
        setMeetingNum(value);
    }

    async function onJoinMeeting() {
        if (connect) {
            return;
        }
        if (!isValidMeetingNum()) {
            return;
        }
        setConnect(true);
        joinCloudLink(userId, {
            meetingNumber: meetingNum.replace(/\s+/g, ''),
            password: pass,
            username: teamUserInfo.realName,
            isvideooff: state.videoDisable,
            isaudiooff: state.audioDisable,
        })
            .then(() => {
                closeModal();
            })
            .catch((err) => {
                setConnect(false);
                message.error((err.message || err || '加入会议失败').toString());
            });
    }

    function onMeetingPassChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (!/^\d{0,6}$/.test(value)) {
            return;
        }
        setPass(value);
    }

    const isValidMeetingNum = () => {
        return meetingNum && (pass.length === 0 || pass.length === 6);
    };

    function handlePassBlur() {
        if (!pass) {
            setShowPass(false);
        }
    }

    function handleMenuClick(key: string) {
        if (connect) {
            return;
        }
        if (key === 'audio') {
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
    }

    const passComp = showPass ? (
        <Input
            value={pass}
            placeholder={t_common('joinMeeting.meetingPass')}
            autoFocus
            onPressEnter={onJoinMeeting}
            onChange={onMeetingPassChange}
            onBlur={handlePassBlur}
        />
    ) : (
        <div
            className="input-label"
            onClick={() => {
                setShowPass(true);
            }}
        >
            <IconFont style={{ marginRight: 5 }} type="iconic_editor" />
            {t_common('joinMeeting.meetingPassLabel')}
        </div>
    );

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    function onCancel() {
        analysisLog('xtbg_PCHMeeting', 'Joinin_Cancel');
        closeModal();
    }
    return (
        <Modal
            className="desk-me-join-meeting-modal"
            width={780}
            visible={visible}
            onCancel={onCancel}
            closeIcon={<IconFont type="iconapp_btn_file_cancel" />}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div className="me-join-meeting-body">
                <div className="me-join-meeting-form">
                    <div className="me-join-meeting-input">
                        <Input
                            type="text"
                            value={meetingNum}
                            ref={inputRef}
                            autoFocus
                            placeholder={t_common('joinMeeting.meetingNum')}
                            onPressEnter={onJoinMeeting}
                            onChange={onMeetingNumChange}
                        />
                    </div>
                    <div className="me-join-meeting-input">{passComp}</div>
                </div>
                <div className="me-join-meeting-actions">
                    <div className="me-join-meeting-menu">
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCHMeeting|Joinin_Voice"
                                className={`me-join-meeting-menu-item 
                                ${state.audioDisable ? 'me-join-meeting-menu-item-disable' : ''}`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCHMeeting|Joinin_Video"
                                className={`me-join-meeting-menu-item ${
                                    state.videoDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                    </div>
                    <Button
                        type="primary"
                        onClick={onJoinMeeting}
                        clstag="pageclick|keycount|xtbg_PCHMeeting|Joinin_Confirm"
                        loading={connect}
                        disabled={!isValidMeetingNum()}
                    >
                        {t_common('joinMeeting.join')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
