import React, { Dispatch, SetStateAction } from 'react';
import { message, Checkbox, Popover, Modal } from 'antd';
import Context from '../../../context';
import Title from '../Title';
import Role from '../Role';
import { ProjectMember } from '../index';
import * as api from '../../../api';
import Icon from '../../../Icon';
import { ProjectRole, Project } from '../../../types';
import searchNoData from '@/assets/img/search_empty_img.png';
import { isSameUser } from '../../../util';
import { Avatar } from '@/baseComponents/Avatar';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

const { confirm } = Modal;
interface Props {
    project: Project;
    source: ProjectMember[];
    setSelecteds: (items: ProjectMember[]) => void;
    onDelete: (item: ProjectMember) => void;
    onClose?: Dispatch<SetStateAction<boolean>>;
    loading?: boolean;
}

export default function (props: Props) {
    const { source, project, setSelecteds, onDelete, onClose, loading } = props;
    const [members, setMembers] = React.useState<ProjectMember[]>([]);
    const { events, t } = React.useContext(Context);
    const [user, setUser] = React.useState(project.userInfo);
    const isMember = user?.userRole !== ProjectRole.ADMIN && user?.userRole !== ProjectRole.OWNER;

    React.useEffect(() => {
        setMembers(source);
    }, [source, project]);
    React.useEffect(() => {
        setUser(project.userInfo);
    }, [project]);

    React.useEffect(() => {
        setSelecteds(members.filter((m) => m.selected));
    }, [members, setSelecteds]);

    const selectAll = members.every((m) => m.selected);
    const enableSelect = members.some((m) => m.selected);

    function Console(m: ProjectMember) {
        // 本人
        if (
            isSameUser(
                {
                    app: user?.app || '',
                    teamId: user?.teamId || '',
                    userId: user?.userId || '',
                },
                m
            )
        ) {
            return null;
        }
        const els = [];
        const role = m.role ?? 0;
        if (user?.userRole === ProjectRole.OWNER) {
            els.push(
                <span
                    key="owner"
                    className="pm-member-setting-operate"
                    onClick={() => {
                        confirm({
                            title: t('list.owner_replace'),
                            content: t('member.replace_confirm', { name: m.name }),
                            cancelText: t('cancel'),
                            okText: t('confirm'),
                            onOk: () => {
                                return api
                                    .updateUser(project.id, {
                                        ...m,
                                        userRole: ProjectRole.OWNER,
                                    })
                                    .then((data) => {
                                        if (data.errCode) return;
                                        message.success(t('list.replace_success'));
                                        setMembers(
                                            members.map((o) => ({
                                                ...o,
                                                role: isSameUser(o, {
                                                    app: user?.app || '',
                                                    teamId: user?.teamId || '',
                                                    userId: user?.userId || '',
                                                })
                                                    ? ProjectRole.MEMBER
                                                    : isSameUser(o, m)
                                                    ? ProjectRole.OWNER
                                                    : o.role,
                                            }))
                                        );
                                        setUser({
                                            ...user,
                                            userRole: ProjectRole.MEMBER,
                                        });
                                        events.emit('projectReplaceOwner', {
                                            projectId: project.id,
                                        });
                                        onClose?.(false);
                                    });
                            },
                        });
                    }}
                >
                    {t('list.owner_replace')}
                </span>
            );
            els.push(
                <span
                    key="manage"
                    className="pm-member-setting-operate"
                    onClick={() => {
                        const userRole =
                            role !== ProjectRole.ADMIN ? ProjectRole.ADMIN : ProjectRole.MEMBER;
                        api.updateUser(project.id, {
                            ...m,
                            userRole,
                        }).then((data) => {
                            if (data.errCode) return;
                            message.success(t('operate_success'));
                            m.role = userRole;
                            setMembers([...members]);
                        });
                    }}
                >
                    {role !== ProjectRole.ADMIN
                        ? t('member.set-administrator')
                        : t('member.cancel-administrator')}
                </span>
            );
        }
        if (
            user?.userRole === ProjectRole.OWNER ||
            (user?.userRole === ProjectRole.ADMIN &&
                role !== ProjectRole.OWNER &&
                role !== ProjectRole.ADMIN)
        ) {
            els.push(
                <span
                    key="delete"
                    className="pm-member-setting-operate"
                    data-delete-member
                    onClick={() => {
                        confirm({
                            title: t('member.remove'),
                            content: t('member.remove_confirm', { name: m.name }),
                            cancelText: t('cancel'),
                            okText: t('confirm'),
                            onOk: () => {
                                return api.deleteProjectsMember(project.id, [m]).then((data) => {
                                    if (data.errCode) return;
                                    const _members = members.filter((o) => o !== m);
                                    setMembers(_members);
                                    onDelete(m);
                                    message.success(t('operate_success'));
                                });
                            },
                        });
                    }}
                >
                    {t('remove')}
                </span>
            );
        }
        return els;
    }

    return (
        <div
            className={
                'pm-mm-list pm-setting-member-wrap ' +
                (enableSelect ? 'pm-mm-list-enableSelect' : '')
            }
        >
            <div className="pm-mm-list-header">
                <div className="pm-setting-member-header pm-mm-list-line">
                    <div style={{ display: isMember || project.archive ? 'none' : '' }}>
                        <Checkbox
                            checked={selectAll}
                            onChange={() => {
                                const flag = !selectAll;
                                members?.forEach((m) => {
                                    m.selected = flag;
                                });
                                setMembers([...members]);
                            }}
                        />
                    </div>
                    <div>{t('member.name')}</div>
                    <div>{t('member.duty')}</div>
                    {!project.archive && <div>{t('member.operate')}</div>}
                </div>
            </div>
            <div className="pm-mm-list-body">
                {members?.map((m) => (
                    <div
                        key={m.userId + m.app + m.teamId}
                        className="pm-setting-member-header pm-mm-list-line"
                    >
                        <div style={{ display: isMember || project.archive ? 'none' : '' }}>
                            <Checkbox
                                checked={m.selected}
                                onChange={() => {
                                    m.selected = !m.selected;
                                    setMembers([...members]);
                                }}
                            />
                        </div>
                        <div>
                            {/* <span
                                className="pm-mm-list-avatar"
                                style={m.avatar ? { backgroundImage: `url(${m.avatar})` } : {}}
                            >
                                {m.avatar ? '' : m.name?.[0]}
                            </span> */}
                            <span className="pm-mm-list-avatar">
                                {/* {m.avatar} */}
                                <Avatar
                                    src={changeEgovUrl(m.avatar)}
                                    name={m.name || ''}
                                    styleObj={{
                                        width: '100%',
                                        height: '100%',
                                        marginTop: m.avatar ? -5 : 0,
                                        // borderRadius: '6px',
                                        // marginRight: '10px',
                                        // marginTop: '-2px',
                                        // fontSize: '16px',
                                    }}
                                />
                            </span>

                            <span className="pm-mm-list-name">{m.name}</span>
                            <Role role={m.role || 0} />
                        </div>
                        <Title member={m} project={project} value={m.duty as string} />
                        {!project.archive && (
                            <div>
                                {(user?.userRole === ProjectRole.ADMIN ||
                                    user?.userRole === ProjectRole.OWNER) &&
                                    !isSameUser(
                                        {
                                            app: user?.app || '',
                                            teamId: user?.teamId || '',
                                            userId: user?.userId || '',
                                        },
                                        m
                                    ) && (
                                        <Popover placement="bottom" content={Console(m)}>
                                            <span className="pm-more-icon">
                                                <Icon type="iconbtn_ic_more_vertical" />
                                            </span>
                                        </Popover>
                                    )}
                            </div>
                        )}
                    </div>
                ))}
                {!loading && members.length === 0 && (
                    <div className="pm-mm-list-noResult">
                        <img src={changeEgovUrl(searchNoData)} alt="" />
                        <p>{t('member.no_search_data')}</p>
                    </div>
                )}
            </div>
        </div>
    );
}
