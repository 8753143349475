import React from 'react';
import { superviseEmpty } from '@/assets/img';
import { changeEgovUrl } from '@/utils/tools';

export default function Empty({ src, text }: { src?: string; text?: string }) {
    return (
        <div
            style={{
                textAlign: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#62656D',
            }}
        >
            <img
                style={{
                    width: 150,
                    height: 150,
                }}
                src={changeEgovUrl(src || superviseEmpty)}
            />
            <p>{text}</p>
        </div>
    );
}
