export enum TsdkConfRole {
    Nomral = 0, // 普通与会者
    Presenter, // 主持人
}

export enum TsdkConfParticipantStatus {
    IN_MEETING = 0, // 会议中
    CALLING, // 正在呼叫
    JOINING, // 正在加入会议
    GONE, // 已经离开
    NOT_EXIST, // 用户不存在
    BUSY, // 被叫用户忙
    NO_ANSWER, // 用户无应答
    REJECT, // 用户拒绝接听
    CALL_FAIL, // 呼叫失败
}

export enum TsdkConfTerminalType {
    SIP = 0, // sip终端
    H323, // h323终端
}

// 与会者基础信息类
export interface TsdkAttendeeBaseInfo {
    displayName: string; // 不涉及  可选，与会者显示名称
    sms: string; // 不涉及   可选，短信通知手机号码
    number: string; // 不涉及   号码
    userId: number;
    role: TsdkConfRole; // TsdkConfRole   会议成员角色
    email: string; // 不涉及   可选，电子邮箱地址
    accountId: string; // 不涉及   可选，用户帐号，预约会议时若不填写，则该用户无法查询到
    terminalType: TsdkConfTerminalType; // TsdkConfTerminalType   【预留参数】
}

// 与会者状态信息类
export interface TsdkAttendeeStatusInfo {
    hasCamera: number; // 不涉及 是否有摄像头，VC组网下该参数无效，0：没有，1：
    isAnonymous: number; // 不涉及  是否匿(// 匿名方式加入会议)，0：不是，1：是
    isBroadcast: number; // 不涉及  是否被广播，0：不是，1：是
    isHandup: number; // 不涉及  是否举手，0：不是，1：是
    isMute: number; // 不涉及  是否闭音，0：不是，1：是
    isPresent: number; // 不涉及  是否主讲人，0：不是，1：是
    isSelf: number; // 不涉及  是否自己，0：不是，1：是
    isReqTalk: number; // 不涉及  【预留参数】
    isShareOwner: number; // 不涉及  是否共享拥有者，0：不是，1：是
    isAudio: number; // 不涉及  是否语音会议，1：是，0：否
    participantId: string; // 不涉及  与会者唯一标识
    state: TsdkConfParticipantStatus; // TsdkConfParticipantStatus  用户状态，VC下仅支持离会和入会两个状态。
    dataUserId: number; // 不涉及  【预留参数】
    isJoinDataconf: number; // 不涉及  【预留参数】
    isOnlyInDataConf: number; // 不涉及  【预留参数】
}

// 与会者信息类
export interface TsdkAttendee {
    baseInfo: TsdkAttendeeBaseInfo; // 与会者基础信息
    statusInfo: TsdkAttendeeStatusInfo; // 与会者状态信息
}

// (会议状态信息类)
export interface TsdkConfStatusInfo {
    attendeeList: TsdkAttendee[]; // 不涉及 与会者列表
    attendeeNum: number; // 不涉及  与会者数量
    confId: string; // 不涉及  会议id
    confMediaType: number; // TsdkConfMediaType  媒体类型
    confState: number; // TsdkConfState  会议状态
    groupUri: string; // 不涉及  群组uri
    hasChairman: number; // 是否有主持人
    isAlMute: number; // 不涉及  主持人是否开启了全场闭音，只给主持人推送，其他会场默认为0
    isHdConf: number; // 不涉及  是否高清视频会议
    isLiveBroadcast: number; // 不涉及  会场是否为直播状态
    isLock: number; // 不涉及  会场是否为锁定状态
    isRecord: number; // 不涉及  会场是否为录播状态
    isSupportLiveBroadcast: number; // 不涉及  是否支持直播，0：不支持，1：支持
    isSupportRecordBroadcast: number; // 不涉及  是否支持录播，0：不支持，1：支持
    isChecked: number; // 不涉及  是否已签到，0：没签到，1：已签到
    remainTime: number; // 不涉及  会议剩余时间
    scheduserAccount: string; // 不涉及  预订者帐号
    scheduserName: string; // 不涉及  预订者姓名
    size: number; // 不涉及  会议方数
    subject: string; // 不涉及  会议主题
    updateType: number; // TsdkConfAttendeeUpdateType  成员更新方式
}

export enum DeviceType {
    mic = 0, // 麦克风
    speaker = 1, // 扬声器
    camera = 2, // 摄像头
}
