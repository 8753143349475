import { gatewayInstance } from '../server/gateway';
import { MeetingPlatform, MeetingQueryType } from '@/types/common';
import { NPCRoom } from '@/components/MeetingCloudLink/Invites/NPC';

export interface CreateMeetingResult {
    controlPassword: any;
    meetingId: string;
    uuid: string;
    topic: string;
    startTime: string;
    duration: number;
    password: string;
    joinUrl: string;
}
export async function createMeeting(opts: {
    startTime: string;
    duration: number;
    topic: string;
    password: string;
    participantIds: {
        userId: string;
        teamId: string;
        appId: string;
        realName: string;
        headImg: string;
    }[];
    platform?: MeetingPlatform;
    isCycle?: boolean;
    pin?: string;
    proxyPin?: string;
    room?: NPCRoom;
    terminalId?: any;
}): Promise<[CreateMeetingResult | null, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.subConference',
            method: 'POST',
            body: opts,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

export async function cancelMeeting(opts: {
    meetingId: string;
    meetingUuid: string;
    meetingType: number;
    cancelCycle: boolean; // 是否取消循环会议，取消=true，其他场景false
    beginTime: number;
    endTime: number;
    platform?: MeetingPlatform;
}): Promise<any> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.cancelConference',
            method: 'POST',
            body: {
                conferenceCode: opts.meetingId,
                conferenceUuid: opts.meetingUuid,
                conferenceKind: opts.meetingType === 1 ? 'pcpm' : 'cuvc',
                cancelCycle: opts.cancelCycle,
                beginTime: opts.beginTime,
                endTime: opts.endTime,
                platform: opts.platform,
            },
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error];
    }
}

export async function meetingPreLogin(appId: string): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.preLogin',
            method: 'POST',
            body: { appId },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message?.toString() || error.errorMsg || error || '服务器错误'];
    }
}

/**
 * 查询会议
 * @param startIndex
 * @param pageSize
 * @param queryType
 * @returns
 */
// eslint-disable-next-line max-params
export async function queryMeeting(
    page = 1,
    pageSize = 10,
    queryType: MeetingQueryType = MeetingQueryType.ONGOING_AND_UPCOMING,
    platform: MeetingPlatform = MeetingPlatform.CLOUD_UNION
): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.queryConference',
            method: 'POST',
            body: {
                queryType,
                conferenceTypeList: [0], // 会议类型 0：视频会议 1：VoIP
                platform,
                pager: {
                    startIndex: page - 1,
                    pageSize,
                },
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 主持人重命名
 * @param body
 */
export async function meetingRename(body: {
    appId: string;
    teamId: string;
    userId: string;
    fullName: string;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.rename',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取主持人职位
 * @param body
 */
export async function getUserSimple(body: {
    appId: string;
    teamId: string;
    userId: string;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.getUserSimple',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 关联会议日程
 * @param body
 */
export async function associateSchedule(body: {
    conferenceCode: string; // 会议号
    scheduleId: string; // 日程ID
    calendarId: string; // 日历ID
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.associateSchedule',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 修改会议
 * @param body
 */
export async function modifyMeeting(opts: {
    meetingId: string;
    meetingUuid: string;
    appId: string;
    teamId: string;
    userId: string;
    duration: number; // 持续时间
    topic: string; // 会议密码
    password: string;
    startTime: number; // 开始时间
    meetingType: number; // 会议类型
    option_jbh: boolean; // 主持人是否入会
    platform?: MeetingPlatform;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.modifyConference',
            method: 'POST',
            body: {
                conferenceCode: opts.meetingId,
                conferenceUuid: opts.meetingUuid,
                appId: opts.appId,
                teamId: opts.teamId,
                userId: opts.userId,
                duration: opts.duration,
                topic: opts.topic,
                password: opts.password,
                startTime: opts.startTime,
                meetingType: opts.meetingType,
                option_jbh: opts.option_jbh,
                platform: opts.platform,
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 华为批量取号接口
 * @param body
 */
export async function cloudlinkRegister(body: {
    userIds: string[]; // 用户id列表
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.register',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error.message.toString()];
    }
}

/**
 * 发送卡片消息接口
 * @param body
 */
export async function notice(body: { userId: string }): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.notice',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 华为消息卡片获取账号信息接口
 * @param body
 */
export async function takeAccount(body: { userId: string }): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.takeAccount',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
/**
 * 华为获取终端列表
 * @param body
 */
export async function takeTerminals(
    pageNo = 1,
    pageSize = 20,
    name?: string
): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.takeTerminals',
            method: 'POST',
            body: {
                pageNo,
                pageSize,
                name,
            },
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error.message.toString()];
    }
}
/**
 * 华为添加设备或人大会议
 * @param body
 */
export async function addParticipants(body: {
    conferenceUuid: string;
    terminalId?: string[];
    room?: NPCRoom | null;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.addParticipants',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        console.error(error);
        return [null, error.message.toString()];
    }
}

/**
 * 发起会议/加入会议 更新信息接口
 * @param body
 */
export async function updateRecentLoginTime(body: {
    userIds: string[]; // 用户id列表
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.updateRecentLoginTime',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 京东会议获取token
 * @param body
 */
export async function getJRTCToken({
    deviceType,
    nickname,
    unit,
    avatar,
    temporary = false,
}: {
    nickname: string;
    unit: string;
    avatar: string;
    temporary?: boolean;
    deviceType: string;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.getJRTCToken',
            method: 'POST',
            body: {
                deviceType,
                nickname,
                unit,
                avatar,
                temporary,
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 发起会议/加入会议 更新信息接口
 * @param body
 */
export async function conferenceCall(body: {
    userIds: string[]; // 用户id列表
    meetingId: string; // 会议号
    password: string; // 会议密码
    sdkType: string;
    topic: string; // 会议主题
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.call',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 通过会议号获取会议详情
 * @param body
 */
export async function getConferenceByCode(body: {
    meetingId: string;
    platform: MeetingPlatform;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.getConferenceByCode',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取会议邀请短链接
 * @param body
 */
export async function getConferenceDeeplink(body: {
    meetingId: string;
    meetingCode: string;
    platform: MeetingPlatform;
}): Promise<[any, any]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.deeplink',
            method: 'POST',
            body,
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}

/**
 * 获取会议列表
 */

export async function getConferencePlatforms(): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.platforms',
            method: 'POST',
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
// 发送消息卡片
export async function sendConferenceCardMsg(body: {
    noticeId: string;
    title: string;
    receivers: {
        pin: string;
        teamId: string;
        app: string;
    }[];
    meetingInfoReq: {
        meetingNumber: string;
        initiator: string;
    };
    infox: Record<string, any>;
}): Promise<[any, string | null]> {
    try {
        const data: any = await gatewayInstance.request({
            api: 'conference.sendConferenceCardMsg',
            method: 'POST',
            body: {
                ...body,
                applicationKey: body.noticeId.replace('eopen-push_', ''),
            },
        });
        return [data, null];
    } catch (error) {
        return [null, error.message.toString()];
    }
}
