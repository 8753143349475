import React from 'react';
import Task from '../Task';
import Schedule from '../Schedule/';
import { TaskModel } from '@/types/work';
import { ISchedule } from '@/types/supervise';

export default function AttachTaskOrSchedule(props: {
    activeTab: string;
    changeSelectedTaskItem: (checkedTaskItem: TaskModel) => void;
    changeSelectedScheduleItem: (checkedScheduleItem: ISchedule) => void;
}) {
    return props.activeTab === 'task' ? (
        <Task changeSelectedTaskItem={props.changeSelectedTaskItem} />
    ) : (
        <Schedule changeSelectedScheduleItem={props.changeSelectedScheduleItem} />
    );
}
