import React, { useMemo, useState } from 'react';
import './index.less';
import { SuperviseUserInfo } from '@/types/supervise';
import { Popover, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import IconFont from '@/components/icon';
import dayjs from 'dayjs';
import { UserListEmptyImg } from '@/assets/img';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import { connect } from 'dva';
import Bus from '@/utils/bus';
import { changeEgovUrl } from '@/utils/tools';
const { TabPane } = Tabs;

function UserStatusListPopover({
    unfinishList,
    finishList,
    onRemove,
    children,
    disable,
    toggleUserCardModal,
    userCardVisible,
}: {
    unfinishList: SuperviseUserInfo[];
    finishList: SuperviseUserInfo[];
    onRemove: (user: any) => void;
    children: any;
    disable?: boolean;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    userCardVisible: boolean;
}) {
    const { t } = useTranslation('supervise');

    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    function visibleChange(visible: boolean) {
        if (modalVisible) {
            return;
        }
        if (userCardVisible) {
            return;
        }
        setVisible(visible);
    }
    const [t_common] = useTranslation('common');
    function handleRemoveMember(user: SuperviseUserInfo) {
        onRemove({
            userId: user.userId,
            teamId: user.teamId,
            appId: user.app || user.appId,
        });
    }

    function openUserCard(item: SuperviseUserInfo) {
        const { userId, teamId, appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }

    const getContent = () => {
        const getList = (list: any[]) => {
            return list.map((item) => (
                // eslint-disable-next-line react/jsx-key
                <div
                    className="user-status-list-item"
                    key={item.userId + item.teamId + (item.app || item.appId)}
                >
                    {item.headImg ? (
                        <img
                            src={changeEgovUrl(item.headImg || '')}
                            className="avatar"
                            onClick={() => openUserCard(item)}
                        />
                    ) : (
                        <div className="avatar" onClick={() => openUserCard(item)}>
                            {(item.realName || '').slice(0, 1)}
                        </div>
                    )}
                    <div className="container">
                        <div className="title-content">
                            <span className="title">{item.realName}</span>
                            {/* {item.completeTime && (
                                <span className="label">
                                    {t('finish at')}
                                    {` ${dayjs(item.completeTime).format('YYYY/MM/DD')}`}
                                </span>
                            )}
                            //  */}
                            <span style={{ flex: 1 }} />
                            {!disable && (
                                <span
                                    className="action-remove"
                                    onClick={() => handleRemoveMember(item)}
                                >
                                    {t('remove')}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            ));
        };
        const getEmpty = () => (
            <div className="user-status-list-empty">
                <img src={changeEgovUrl(UserListEmptyImg)} />
                <div className="label">{t('noData')}</div>
            </div>
        );
        return (
            <div className="user-status-tab">
                <div
                    style={{
                        textAlign: 'center',
                        lineHeight: '48px',
                        borderBottom: '1px solid rgba(222, 224, 227, 1)',
                    }}
                >
                    {t('receiverList')}
                </div>
                <div className="user-status-list">
                    {finishList.length > 0 ? getList(finishList) : getEmpty()}
                </div>
            </div>
        );
    };
    return (
        <Popover
            overlayClassName="supervise-user-status-popover"
            trigger="click"
            placement="bottom"
            content={getContent}
            visible={visible}
            onVisibleChange={visibleChange}
        >
            {children}
        </Popover>
    );
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStatusListPopover);
