import React, { useState, useEffect } from 'react';
import conf from './conf';
import Icon from '../../Icon';
import ClassName from 'classnames';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';
interface propTypes {
    onInit?: (url: string) => void;
    onChange?: (url: string) => void;
    imgUrl?: string;
    className?: string;
    isShowText?: boolean;
    archive?: boolean;
}

export default function SelectAvatar(props: propTypes) {
    const { onInit, onChange, imgUrl, className, isShowText = false, archive } = props;
    const { avatarUrls } = conf;
    const [isShowIcons, setIsShowIcons] = useState(false);
    const [currentIcon, setCurrentIcon] = useState(0);
    const container = React.useRef(null);

    useEffect(() => {
        onInit?.(avatarUrls[0].url);
        // eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        const close = (e: MouseEvent) => {
            const ctnr = container.current;
            if (!ctnr) return;
            if (!(ctnr as HTMLDivElement).contains(e.target as HTMLBaseElement)) {
                setIsShowIcons(false);
            }
        };
        document.addEventListener('mousedown', close, false);
        return () => document.removeEventListener('mousedown', close, false);
    }, [isShowIcons]);

    return (
        <div className="pm-selectavatart-wrap" ref={container}>
            <div
                className={ClassName([className])}
                onClick={() => {
                    !archive && setIsShowIcons(true);
                }}
            >
                <img src={changeEgovUrl(imgUrl || avatarUrls[currentIcon].url)} alt="" />
                {isShowText && (
                    <div>
                        <span>修改封面</span>
                    </div>
                )}
            </div>
            {isShowIcons && (
                <ul>
                    {avatarUrls.map((item, index) => (
                        <li
                            key={item.id}
                            onClick={() => {
                                setCurrentIcon(index);
                                onChange?.(item.url);
                            }}
                        >
                            <img src={changeEgovUrl(item.url)} alt="" />
                            {(imgUrl || avatarUrls[currentIcon].url) === item.url && (
                                <Icon
                                    type="iconapp_btn_radio_selected"
                                    className="pm-selectavatar-check"
                                />
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
