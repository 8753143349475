import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function Loading(props: any) {
    const { color = (window as any).styleSass.primaryColor, type } = props;
    const { t } = useTranslation('common');
    const [tips, setTips] = useState('');
    const saveTime = new Date().getTime();
    useEffect(() => {
        // if (type === 'message') return;
        const timer = setInterval(() => {
            const nowTime = new Date().getTime();
            const useTime = nowTime - saveTime;
            if (2000 <= useTime && useTime < 5000) {
                setTips(t('loading.littleSlowLoadingTips'));
            } else if (useTime >= 5000) {
                setTips(t('loading.slowLoadingTips'));
                if (timer) clearInterval(timer);
            }
        }, 1000);
        return () => {
            if (timer) clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                // marginTop: '-40px',
            }}
        >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color }} />} />
            {tips && (
                <div
                    style={{
                        fontSize: '12px',
                        marginTop: '12px',
                        color: '#666666',
                    }}
                >
                    {tips}
                </div>
            )}
        </div>
    );
}
