/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
// 消息聚合
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Card, Drawer, message as toast } from 'antd';
import {
    ChatMessage,
    Session,
    MessageStatus,
    ChatMessagePlusEmitBody,
    Employee,
    SessionType,
    TextChatMessageExt,
} from '@/types/chat';
import './index.less';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';
import { formate } from '@/utils/date';
import Bus from '@/utils/bus';
import { UserCardType } from '@/types/UserCard';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import ChildrenPlugin from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { convertStringToEmoji } from '@/utils/chat/ddemoji';
import { mattchEmoji } from '../Input/Emoji';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import Context from '@/context/ChatContext';
import { AggregateUser } from '@jd/jdee.im.sdk/lib/es/types/Common';
import { formatAtUser, getHeadImgThumbUrl } from '@/utils/chat/index';
import { getToMergeMessage } from '@/server/im/MessageService';
import {
    buildTextMessageBody,
    initChatMessageInfo,
    isGroupVisibleUnit,
} from '@/utils/chat/message';
import { changeEgovUrl } from '@/utils/tools';
import {
    convertContentToArray,
    isCardViewGroupSession,
    isEqualEmployee,
    MessageCardType,
} from '@/components/chat/utils/message';
import { isGroupOrdinary } from '@/components/chat/utils/group';
import { useTranslation } from 'react-i18next';
import { getDeptPositionDesc } from '@/models/user';
import { MessageSendType } from '@/types/chat/enum';
import ShortLink from '@/components/chat/message/message-components/ShortLink';
import JoyspaceTopic from '@/components/chat/message/message-components/JoyspaceTopic';

TweenOne.plugins.push(ChildrenPlugin);
interface MessageOperateProps {
    message: ChatMessage & ChatMessagePlusEmitBody & TextChatMessageExt;
    selectedSession: Session;
    updateTempMessageStatus: Function;
    updateSessionMessageMid: Function;
    currentEmployee: Employee;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
    customStyle?: { width: number; borderRadius: number };
    pushChatMessage: (data: { sessionId: string; message: Partial<ChatMessage> }) => void;
}
type IProps = Readonly<MessageOperateProps & ChatState>;
function ChatAggregation(props: IProps) {
    const {
        message,
        selectedSession,
        sessionMessageMap,
        currentEmployee,
        connectState,
        pushChatMessage,
    } = props;
    const { chatAggregationStyle, groupRosterIdentity } = React.useContext(Context);
    const [visible, setvisible] = useState(false);
    const [onVisible, setonVisible] = useState(false);
    const [conindex, setconindex] = useState<any>(0);
    const [plusList, setplusList] = useState<AggregateUser[]>([]);
    const [msg, setmsg] = useState<any>();
    const { t } = useTranslation('chat');

    const realDisplayContent = useMemo(() => {
        if (message.displayContent && message.displayContent.length > 0) {
            return message.displayContent;
        }
        return convertContentToArray(message.content);
    }, [message]);

    // 构建消息
    const sendMessage = useCallback(() => {
        // const instance = ImService.getInstance();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        // const msgs = {
        //     type: ChatMessageType.TEXT,
        //     content: msg.content,
        // } as any;
        const messageList = buildTextMessageBody(
            message.content,
            message.atUsers || [],
            undefined,
            undefined,
            realDisplayContent
        );
        const messages: Partial<ChatMessage> = initChatMessageInfo({
            selectedSession,
            currentEmployee,
            message: messageList,
        });
        const sessionId = msg.sessionId;
        if (sessionId) {
            // setTimeout(() => {
            // await instance.sendChatMessage(sessionId, messagesId, msgs);
            pushChatMessage({
                sessionId: selectedSession.sessionId,
                message: messages as any,
            });
            const { needMerge } = getToMergeMessage(
                selectedSession,
                sessionMessageMap[selectedSession.id],
                messages as ChatMessage
            );
            Bus.emit(`message:Box`, needMerge);
            // }, 200);
        }
    }, [
        msg,
        selectedSession,
        currentEmployee,
        sessionMessageMap,
        pushChatMessage,
        message,
        realDisplayContent,
    ]);
    let plusLists: any = [];
    let SessionMessageMap = sessionMessageMap[selectedSession.sessionId];

    const handleMessageBox = useCallback((data: boolean) => {
        if (data === true) {
            setonVisible(true);
        }
    }, []);

    useEffect(() => {
        Bus.on(`message:Box`, handleMessageBox);
        return () => {
            Bus.off(`message:Box`, handleMessageBox);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message.plusList === undefined || message.plusList === []) {
            return;
        } else {
            setplusList(message.plusList);
            setconindex(plusList?.length);
        }
        // 加一是否显示
        SessionMessageMap.map((item: any) => {
            setmsg(item);
            // setmessages(item);
            if (item.plusList === undefined && item.content !== message.content) {
                setonVisible(true);
            }
            if (item.mid !== message.mid) {
                setonVisible(true);
            } else {
                setonVisible(false);
            }
        });
    }, [SessionMessageMap, message.content, message.mid, message.plusList, plusList?.length]);

    const showDrawer = () => {
        setvisible(true);
    };
    const onClose = () => {
        setvisible(false);
    };

    // 打开个人信息
    const openUserCard = (e: any) => {
        let data = null;
        // 如果群成员并且禁止了查看群成员名片
        if (
            !isEqualEmployee(currentEmployee, message.from as any) &&
            isGroupOrdinary(groupRosterIdentity) &&
            !isCardViewGroupSession(selectedSession)
        ) {
            toast.warn(t('can_not_open_user_card_tip'));
            return false;
        }
        try {
            data = JSON.parse(e.target.getAttribute('data-event-params'));
        } catch (e) {}
        // 如果聚合消息内容为@
        if (data) {
            if (
                e.target.getAttribute('data-event-click') !== 'mention-link' ||
                !data ||
                (data && data.id === 'all') ||
                (data && !data.id)
            ) {
                return;
            } else {
                Object.assign(data, { userId: data.id });
                // 如果群成员并且禁止了查看群成员名片
                // if (
                //     !isEqualEmployee(currentEmployee, data) &&
                //     isGroupOrdinary(groupRosterIdentity) &&
                //     !isCardViewGroupSession(selectedSession)
                // ) {
                //     toast.warn(t('can_not_open_user_card_tip'));
                //     return false;
                // }
                Bus.emit('app:toggle-user-card-modal:show', {
                    visible: true,
                    type: UserCardType.User,
                    userId: data.id,
                    teamId: data.teamId,
                    appId: data.app,
                });
            }
        } else {
            let dataId = e.target.getAttribute('data-id');
            let pin = '';
            plusList.map((user: any) => {
                if (user.userId === dataId) {
                    pin = user.userId;
                }
            });
            Bus.emit('app:toggle-user-card-modal:show', {
                visible: true,
                type: UserCardType.User,
                userId: pin,
                teamId: message.from.teamId,
                appId: message.from.app,
            });
        }
    };
    // 聚合消息内容
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getMessageContent = (messageParams: any) => {
        let { atUsers, content } = messageParams;
        let result = convertStringToEmoji(content);
        result = formatAtUser(result, atUsers, currentEmployee);
        result = mattchEmoji(result);
        return result;
    };
    if (plusList === []) {
        return;
    }
    let plusListMsg: any = [];
    let newplusLists: any = [];
    let userid: any = [];
    message.plusList?.map((item: any, index: any) => {
        if (userid.indexOf(item.userId) === -1) {
            userid.push(item.userId);
            newplusLists.push({ ...item, number: 1 });
        } else {
            newplusLists.map((item2: any) => {
                item2.userId === item.userId && item2.number++;
                return item2;
            });
        }
    });
    // 重新排序
    for (let i = plusList.length - 1; i >= 0; i--) {
        if (!plusList[i]) {
            return;
        }
        // eslint-disable-next-line no-self-compare
        plusLists[plusLists.length] = plusList[i];
    }
    for (let i = newplusLists.length - 1; i >= 0; i--) {
        if (!newplusLists[i]) {
            return;
        }
        // eslint-disable-next-line no-self-compare
        plusListMsg[plusListMsg.length] = newplusLists[i];
    }

    const getUserDesc = (user: any) => {
        const unitOrDeptName = !isGroupVisibleUnit(selectedSession) ? user.unitName : user.deptName;
        const result = getDeptPositionDesc(unitOrDeptName, user.titleName || '');
        return result;
    };
    return (
        <div className="site-card-border-less-wrapper">
            <Card
                bordered={false}
                style={{ width: 564, borderRadius: 8, ...chatAggregationStyle }}
                // className={plusList[0]?.name === currentEmployee.name ? 'msg-card' : 'ant-card'}
                className="ant-card"
            >
                <div className="site-card-title">
                    {/* <p
                        onClick={(e) => {
                            if (message.atUsers?.length === 0) {
                                return;
                            } else {
                                openUserCard(e);
                            }
                        }}
                        dangerouslySetInnerHTML={{ __html: getMessageContent() }}
                    >
                        {messages?.content}
                    </p> */}
                    {realDisplayContent.map((item, index) => {
                        return (
                            <div key={index} style={{ margin: '1px 0' }}>
                                {item.type === MessageCardType.Text ? (
                                    <div
                                        className={`top-box ${
                                            selectedSession.isSecret ? 'top-box_isSecret' : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: getMessageContent({
                                                ...message,
                                                content: item.content,
                                            }).trim(),
                                        }}
                                    />
                                ) : item.type === MessageCardType.JoyspaceTopic ? (
                                    <JoyspaceTopic content={item} {...props} />
                                ) : (
                                    <ShortLink shortLinkItem={item} isFusion={true} />
                                )}
                            </div>
                        );
                    })}
                    {/* <div
                        className={
                            plusListMsg[0].name === currentEmployee.name
                                ? 'site-card-title-lines'
                                : 'site-card-title-line'
                        }
                    /> */}
                </div>
                <div className="site-card-msg">
                    <div className="site-card-msg-con">
                        {/* 个人信息展示 */}
                        <QueueAnim
                            type="scaleX"
                            component="div"
                            className="demo-content"
                            animConfig={[{ opacity: [1, 0], translateX: [0, 677] }]}
                        >
                            {/* 数字展示 大于99展示99+ */}
                            {/* {conindex >= 99 ? (
                                <div
                                    style={{
                                        height: 38,
                                        lineHeight: '33px',
                                        width: conindex > 9 ? '50px' : '36px',
                                    }}
                                >
                                    <TweenOne
                                        animation={{
                                            Children: {
                                                value: 99,
                                                floatLength: 0,
                                            },
                                            duration: 1000,
                                            delay: 300,
                                        }}
                                        component="span"
                                        className="site-card-msg-index ones"
                                    />
                                    <span
                                        className="site-card-msg-conindex"
                                        style={{ marginRight: 6 }}
                                    >
                                        +
                                    </span>
                                </div>
                            ) : ( */}
                            <div
                                style={{
                                    height: 38,
                                    lineHeight: '33px',
                                    width: conindex > 9 ? '50px' : '36px',
                                }}
                                onClick={showDrawer}
                            >
                                <span className="site-card-msg-conindex">×</span>
                                <TweenOne
                                    animation={{
                                        Children: {
                                            value: conindex >= 99 ? 99 : conindex,
                                            floatLength: 0,
                                        },
                                        duration: 500,
                                        delay: 200,
                                        // y: 80,
                                    }}
                                    component="span"
                                    className="site-card-msg-index"
                                />
                            </div>
                            {/* // )} */}
                            {plusListMsg.map((item: any, index: any) => {
                                if (index >= 10) {
                                    return;
                                }
                                return (
                                    <div key={index}>
                                        <div className="site-card-msg-user" key={item.number}>
                                            <Avatar
                                                src={getHeadImgThumbUrl(item.avatar, 150, 150)}
                                                name={item.name || ''}
                                            />
                                            <span
                                                onClick={(data) => {
                                                    openUserCard(data);
                                                }}
                                                data-id={item.userId}
                                            >
                                                {item.name}
                                            </span>
                                            {item.number > 1 && (
                                                <div
                                                    style={{
                                                        fontWeight: 600,
                                                        color: '#333333',
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    ×{item.number}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                            {plusListMsg.length >= 10 ? (
                                <div
                                    key={plusListMsg.length}
                                    // className={
                                    //     plusList[0]?.name === currentEmployee.name
                                    //         ? 'site-card-msg-qb'
                                    //         : 'site-card-msg-q'
                                    // }
                                    className="site-card-msg-q"
                                    onClick={showDrawer}
                                >
                                    <span>查看全部</span>
                                    <IconFont
                                        type="iconjs_ilist_arrowright"
                                        className="group-management-icon-arrow"
                                        clstag="pageclick|keycount|focus_chat_01_1615797500283|77"
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </QueueAnim>
                    </div>
                </div>
                {/* +1 */}
                {onVisible === false && (
                    <div style={{ animationDuration: '3s' }}>
                        <div
                            // className={
                            //     plusList[0]?.name === currentEmployee.name
                            //         ? 'site-card-msg-lines'
                            //         : 'site-card-msg-line'
                            // }
                            className="site-card-msg-line"
                        />
                        <div
                            className="site-card-msg-jiayi"
                            onClick={() => {
                                if (connectState.state === 'offline') {
                                    return;
                                }
                                sendMessage();
                            }}
                        >
                            {/* <IconFont
                                style={{ fontSize: 18 }}
                                type="iconapp_ic_addone"
                                className="chat-aggregation-icon"
                                clstag="pageclick|keycount|focus_chat_01_1615797500283|77"
                            /> */}
                            <div className="jia">
                                <IconFont
                                    type="iconapp_btn_more"
                                    className="chat-aggregation-icon"
                                    clstag="pageclick|keycount|focus_chat_01_1615797500283|77"
                                />
                            </div>
                            <span>加一个</span>
                        </div>
                    </div>
                )}
            </Card>
            <Drawer
                className="drawer"
                title="消息详情"
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
                // mask={false}
                // maskStyle={}
                destroyOnClose={true}
                maskClosable={true}
                width={340}
            >
                {plusLists.map((item: any, index: any) => {
                    return (
                        <div key={index} className="msgUser">
                            <div className="head-img">
                                <Avatar
                                    src={changeEgovUrl(item.avatar)}
                                    className="img"
                                    data-id={item.userId}
                                    name={item.name || ''}
                                />
                            </div>

                            <div
                                onClick={(e) => {
                                    openUserCard(e);
                                }}
                                className="user-info"
                                data-id={item.userId}
                            >
                                <div className="name-time">
                                    <span data-id={item.userId} className="name">
                                        {item.name}
                                    </span>
                                    <span data-id={item.userId} className="time">
                                        {formate(item.timestamp)}
                                    </span>
                                </div>
                                <div data-id={item.userId} className="desc">
                                    {getUserDesc(item)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Drawer>
        </div>
    );
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    const {
        sessionMessageMap,
        sessionMessages,
        messages,
        connectState,
        selectedSession,
        currentEmployee,
    } = chat;
    return {
        sessionMessageMap,
        sessionMessages,
        messages,
        connectState,
        userInfo: currentEmployee,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        updateTempMessageStatus(data: { status: MessageStatus; sessionId: String; id: string }) {
            dispatch({ type: 'chat/updateTempMessageStatus', payload: data });
        },
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
        pushChatMessage(data: { sessionId: string; message: Partial<ChatMessage> }) {
            dispatch({ type: 'chat/pushChatMessage', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatAggregation);
