import React, { useCallback, useMemo, useState } from 'react';
import './index.less';
import { Button, Popover, Tabs } from 'antd';
import { connect } from 'dva';

import PopoverContent from './PopoverContent';

function UserStatusListPopover({
    list,
    taskDetail,
    children,
    userCardVisible,
    showAdvance,
}: {
    onRemove: (user: any[]) => void;
    children: any;
    userCardVisible: boolean;
    list: any;
    label?: string; // 区分日程参与人
    userData: any;
    taskDetail: any;
    showAdvance?: boolean;
}) {
    const [visible, setVisible] = useState(false);

    function visibleChange(visible: boolean) {
        if (userCardVisible) {
            return;
        }
        setVisible(visible);
    }

    return (
        <Popover
            overlayClassName="work-user-status-popover"
            trigger="click"
            placement="leftTop"
            content={
                <PopoverContent taskDetail={taskDetail} list={list} showAdvance={showAdvance} />
            }
            visible={visible}
            onVisibleChange={visibleChange}
            getPopupContainer={(trigger) => {
                return trigger as HTMLElement;
            }}
        >
            {children}
        </Popover>
    );
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

export default connect(mapStateToProps)(UserStatusListPopover);
