export interface FocusAuthProps {
    focusToken: string;
    focusTeamId: string;
    focusClient: string;
}

export enum GatewayStage {
    PRE = 'PRE', // 预发环境
    PROD = 'PROD', // 生产环境
    TEST = 'TEST', // 测试环境
}

export interface GatewayProps {
    baseUrl?: string; // 网关地址，默认https://me-janus.jdcloud.com
    appId: string; // 应用ID,默认448114541067374592 joyspace主应用
    webAppId: string;
    appKey?: string; // 前端认证key
    version?: number; // 网关接口版本，现在2
    stage: GatewayStage; // 接口环境，PRE，PROD ，默认是PROD
    encrypt?: boolean; // 消息加密
    focusAuth?: FocusAuthProps;
}

export interface GatewayRequestOptions {
    api: string; // api名称
    dynamicPath?: string; // 动态路径地址
    method: 'OPTION' | 'HEAD' | 'GET' | 'PUT' | 'POST' | 'DELETE'; // 请求方式
    stage?: GatewayStage; // 接口类型
    body?: { [key: string]: any }; // 发送消息类型
    headers?: { [key: string]: string | number }; // 自定义头信息
    auth?: FocusAuthProps; // focus 认证信息
    params?: { [key: string]: string | bigint | number | boolean };
}
export interface GatewayRespCommon {
    errorCode: string;
    errorMsg: string;
    status: string;
}
// 成功消息结构
export interface GatewaySuccessResp<T> extends GatewayRespCommon {
    content: T;
}

// 成功消息结构
export interface GatewayJoySpaceSuccessResp<T> extends GatewayRespCommon {
    data: T;
}

// 成功消息结构
export interface GatewayJoySpaceBannerResp<T> extends GatewayRespCommon {
    homepage: T;
}

// 异常消息结构
export type GatewayErrorResp = GatewayRespCommon;
//
export type GatewayResp<T> = GatewayErrorResp | GatewaySuccessResp<T>;
