import guideNotify from '@/assets/img/guideNotify.png';
import closeNotify from '@/assets/img/closeNotify.png';
import React from 'react';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

function NotifyModal(props: any) {
    const { visible, closeMask } = props;
    return (
        visible && (
            <div className="notify-modal-container">
                <img src={changeEgovUrl(guideNotify)} className="guide-notify" />
                <img
                    src={changeEgovUrl(closeNotify)}
                    className="close-notify"
                    onClick={closeMask}
                />
            </div>
        )
    );
}

export default NotifyModal;
