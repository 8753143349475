import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Tabs } from 'antd';
import './index.less';
import IconFont, { IconDocHome, IconMyDoc } from '@/components/icon';
import { MeetingPlatform, MeetingQueryType } from '@/types/common';
import bus from '@/utils/bus';
import MeetingList from './MeetingList';
import logger from '@/utils/logger';

const { TabPane } = Tabs;

export default function JoinMeetingModal() {
    const [visible, setVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const [platform, setPlatform] = useState<MeetingPlatform>(MeetingPlatform.CLOUD_UNION);
    const history = useHistory();

    function closeModal() {
        setVisible(false);
    }

    function onCancel() {
        closeModal();
    }

    useEffect(() => {
        function handleModalVisible(opts: any) {
            setPlatform(opts.platform);
            setVisible(true);
        }
        function handleShowJDHome(opts: any) {
            closeModal();
            logger.debug('触发handleShowJDHome:', opts);
            const meetingData = {
                meetingInfo: opts.meetingInfo,
                userInfo: opts.userInfo,
            };
            history.push({
                pathname: '/jdmeet',
                state: {
                    meetStatus: 'joinMeeting',
                    ...meetingData,
                },
            });
        }
        bus.on('meeting:meeting-list-show', handleModalVisible);
        bus.on('meeting:meeting-list-hidden', handleShowJDHome);
        return () => {
            bus.off('meeting:meeting-list-show', handleModalVisible);
            bus.off('meeting:meeting-list-hidden', handleShowJDHome);
        };
    }, [history]);

    return (
        <Modal
            width={720}
            title="我的会议"
            visible={visible}
            onCancel={onCancel}
            destroyOnClose={true}
            closeIcon={<IconFont type="iconapp_btn_file_cancel" />}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div className="my-meeting-list">
                <Tabs
                    tabPosition="left"
                    activeKey={activeKey}
                    renderTabBar={(props, DefaultTabBar) => {
                        return (
                            <div className="meeting-tab">
                                <div
                                    className={props.activeKey === '1' ? 'active' : ''}
                                    onClick={() => {
                                        setActiveKey('1');
                                    }}
                                >
                                    {IconDocHome} 我的会议
                                </div>
                                <div
                                    className={props.activeKey === '2' ? 'active' : ''}
                                    onClick={() => {
                                        setActiveKey('2');
                                    }}
                                >
                                    {IconMyDoc} 已结束
                                </div>
                            </div>
                        );
                    }}
                >
                    <TabPane key="1">
                        <MeetingList
                            onCancel={onCancel}
                            meetingType={MeetingQueryType.ONGOING_AND_UPCOMING}
                            platform={platform}
                        />
                    </TabPane>
                    <TabPane key="2">
                        <MeetingList
                            onCancel={onCancel}
                            meetingType={MeetingQueryType.CLOSED}
                            platform={platform}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
}
