import { message, Modal } from 'antd';
import { openPicker } from '../components/CategoriesPickerTree';
import { ICategoryAddCallback, api } from './index';
import { moveTips } from '../util';
import { lang } from '../context';
import events from '../context/events';
const Move: ICategoryAddCallback = (props, isAllow, isTeam) => {
    function freshLeftTreeByFolderById(id: string) {
        events.emit('freshLeftTreeByFolderById', id);
    }
    const { data, reload } = props;
    return [
        {
            children: lang('ctx.move.title'),
            icon: 'pc_yundoc_move',
            splitCode: 2,
            tip: '将文档或文件夹移动到新的位置。仅文档和文件夹的所有者或创建者可移动。',
            callbackOrModal: () => {
                openPicker({
                    title: lang('ctx.move.title'),
                    isTeam: isTeam,
                    callback: (data, close) => {
                        // data.id &&
                        //     api.categories.move(props.data.id, data.id).then((data) => {
                        //         if (data) {
                        //             freshLeftTreeByFolderById(data.id);
                        //             reload();
                        //             message.success(lang('common.success'));
                        //         } else {
                        //             message.error(lang('common.fail'));
                        //         }
                        //         close();
                        //         // props.remove();
                        //     });
                        const tip = moveTips(props.data, data, isTeam);
                        Modal.confirm({
                            title: lang(tip),
                            // content:
                            //     '移动后将取消原文件夹协作者的共享，并重新共享给目标文件夹的协作者。',
                            cancelText: lang('common.cancel'),
                            okText: lang('common.confirm'),
                            onOk: () => {
                                data.id &&
                                    api.categories.move(props.data.id, data.id).then((data1) => {
                                        if (data1) {
                                            events.emit('list.reload');
                                            // 需要reload以后再更新不然会有重复的key导致错误
                                            setTimeout(() => {
                                                freshLeftTreeByFolderById(data.id as any);
                                            }, 200);
                                            message.success(lang('common.success'));
                                        } else {
                                            message.error(lang('common.fail'));
                                        }
                                        close();
                                    });
                            },
                        });
                    },
                    selectArray: [
                        {
                            id: props.data.id,
                            rootId: props.data.space_id,
                            type: 'folder',
                        },
                    ],
                    treeFreshFunction: (data: any) => {
                        freshLeftTreeByFolderById(data);
                    },
                });
            },
            isAllow,
            type: 'move',
        },
    ];
};

export default Move;
