import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Input, List, message, Empty } from 'antd';
import { searchDeletePng } from '@/assets/img/calendar';
import { searchGlobal } from '@/api/search';
import openAddMembersModal from '@/components/AddMembers';
import './index.less';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import debounce from 'lodash/debounce';
import { Avatar } from '@/baseComponents/Avatar';
import IconFont from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { AuthorResourceEnum } from '@/types/common';
import { changeEgovUrl } from '@/utils/tools';
import config, { ConfigEnum } from '@/config/config';
import { analysisLog } from '@/utils/logAnalytics';

function ListItem({
    data,
    clickHandle,
    extra,
    className,
}: {
    data: any;
    clickHandle?: (user?: any) => void;
    extra?: React.ReactNode;
    className?: string;
}) {
    // console.log(data);
    return (
        <div className={`df list-item ${className}`} onClick={() => clickHandle?.()}>
            <Avatar
                className="avatar avatarNoDraggable"
                styleObj={{
                    width: '32px',
                    height: '32px',
                    marginRight: '8px',
                    borderRadius: '6px',
                }}
                src={changeEgovUrl(getHeadImgThumbUrl(data.avatar, 150, 150))}
                name={data.realName}
            />
            <div className="info">
                <p className="name">{data.realName}</p>
                {data.desc && <p className="desc">{data.desc}</p>}
                {!data.desc && data.deptName && data.titleName && (
                    <p className="desc">{`${data.deptName}-${data.titleName}`}</p>
                )}
                {data.origin && !data.desc && !data.deptName && (
                    <p className="desc">{`${data.origin.info?.deptName}-${data.origin.info?.titleName}`}</p>
                )}
            </div>
            {extra}
        </div>
    );
}
interface State {
    searchList: any[];
    selectedList: any[];
    keyWord: string;
    searchListVisible: boolean;
    listLoading: boolean;
}
interface Iprops {
    onChange?: (resultList: any[]) => void;
    keywordsChange?: (w: any) => void;
    searchClassName?: string;
    itemClassName?: string;
    defaultKeyWord?: string;
    onlySelect?: boolean; // sh否显示选择列表，当为false后，搜索结果列表点击后就表示选择完成
    defaultList?: any[];
    projectId?: string; // 专班id
    sessionId?: string;
    isGroup?: boolean;
    isSingle?: boolean;
    filterSelf?: boolean;
    userInfo?: any;
    addClassName?: string;
}
type Props = Readonly<WithTranslation & Iprops>;
class UserPicker extends React.Component<Props, State> {
    static contextType = GlobalContext;
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.defaultKeyWord && nextProps.defaultKeyWord !== prevState.keyWord) {
            return {
                keyWord: nextProps.defaultKeyWord,
            };
        }
        return null;
    }
    state: State;
    constructor(props: Props) {
        super(props);
        this.state = {
            searchList: [],
            selectedList: props.defaultList ? props.defaultList : [],
            keyWord: '',
            searchListVisible: false,
            listLoading: false,
        };
        this.searchGlobal = debounce(this.searchGlobal, 300);
    }

    onKeywordChange = (e: any) => {
        this.setState({
            keyWord: e.target.value,
            searchListVisible: e.target.value.trim() ? true : false,
        });
        this.props.keywordsChange?.(e.target.value);
        if (e.target.value.trim()) {
            this.searchGlobal(e.target.value);
        } else {
            this.setState({
                searchList: [],
            });
        }
    };
    searchGlobal = (t: string) => {
        this.setState({
            listLoading: true,
        });
        searchGlobal({
            keyword: t,
            searchType: SearchType.Contacts,
            pageNo: 1,
            pageSize: 10,
        })
            .then((res: any) => {
                if (this.props.userInfo && this.props.filterSelf) {
                    const contacts = res.contacts.filter((item: any) => {
                        return this.props.userInfo.userId !== item.userId;
                    });
                    this.setState({
                        searchList: contacts || [],
                        listLoading: false,
                    });
                } else {
                    this.setState({
                        searchList: res.contacts || [],
                        listLoading: false,
                    });
                }
            })
            .catch((e) => {
                message.error({
                    content: `${e}`,
                });
            });
    };
    selectHandle = (user: any) => {
        const { selectedList } = this.state;
        const { onChange, onlySelect } = this.props;
        this.setState({
            keyWord: '',
        });
        if (onlySelect) {
            this.setState({
                selectedList: user,
                searchListVisible: false,
            });
            onChange?.(user);
            return;
        }
        const idx = selectedList.findIndex((a: any) => a.userId === user.userId);
        if (idx >= 0) {
            this.setState({
                searchListVisible: false,
            });
            return;
        }
        let newList = [...selectedList, user];
        this.setState({
            selectedList: newList,
            searchListVisible: false,
        });
        onChange?.(newList);
    };
    deleteItem = (user: any) => {
        const { selectedList } = this.state;
        const { onChange } = this.props;
        const idx = selectedList.findIndex((a: any) => a.userId === user.userId);
        if (idx < 0) {
            return;
        }
        let newList = [...selectedList.slice(0, idx), ...selectedList.slice(idx + 1)];
        this.setState({
            selectedList: newList,
        });
        onChange?.(newList);
    };
    itemExtra = (item: any) => {
        return (
            <img
                onClick={() => this.deleteItem(item)}
                style={{ width: 14, height: 14 }}
                src={changeEgovUrl(searchDeletePng)}
                draggable="false"
                clstag="pageclick|keycount|xtbg_calhome_1640073907694|41"
            />
        );
    };

    pickParticipater = () => {
        const { authInfo, permissions } = this.context;
        const { onChange, onlySelect, projectId, isGroup, sessionId } = this.props;
        const resultUsers = this.state.selectedList.map((item) => ({
            id: item?.userId + ':' + item?.app + ':' + item?.teamId,
            name: item?.realName,
            avatar: item?.avatar,
            checked: true,
        }));
        const frozenIds = this.state.selectedList.map(
            (item) => item.userId + ':' + item.app + ':' + item.teamId
        );
        let tabs = ['recent', 'org', 'group', 'externalContacts'];
        if (!config[ConfigEnum.EXTERNAL_CONTACTS_ORG]) {
            tabs = ['recent', 'group', 'externalContacts'];
        }
        if (projectId || isGroup) {
            tabs.push('business');
        }
        openAddMembersModal(
            {
                title: '添加参与人',
                tabs: JSON.parse(JSON.stringify(tabs)),
                // projectId || isGroup
                //     ? ['recent', 'org', 'group', 'externalContacts', 'business']
                //     : ['recent', 'org', 'group', 'externalContacts'],
                // tabs: projectId ? ['org', 'business'] : ['org'],
                single: this.props.isSingle ? this.props.isSingle : false,
                recommendParam: {
                    keyWords: '',
                    aiType: ['empl'],
                },
                currentUser: {
                    pin: authInfo?.userId || '',
                    app: authInfo?.ddAppId || '',
                    teamId: authInfo?.teamUserInfo?.teamId || '',
                },
                resultUsers,
                frozenIds,
                businessTab: {
                    tabName: '当前组成员',
                    tabParam: {
                        businessType: isGroup ? 'group' : 'project',
                        businessId: isGroup ? sessionId || '' : projectId || '',
                    },
                },
            },
            (data, close) => {
                analysisLog('xtbg_calhome_1640073907694', '40');
                const newList = (data?.data?.result || []).map((item: any) => {
                    const [userId1 = '', app1 = '', teamId1 = ''] = (item.id || '').split(':');
                    let resultObj = {
                        userId: item.origin?.info.userId || userId1,
                        teamId: item.origin?.info.teamId || teamId1,
                        app: item.origin?.info.appId || app1,
                        avatar: item.avatar,
                        realName: item.name,
                        desc: item.desc,
                    };
                    if (item.origin?.info && !item.desc) {
                        resultObj.desc = `${item.origin?.info.deptName}-${item.origin?.info.titleName}`;
                    }
                    return resultObj;
                });
                this.setState({
                    selectedList: newList,
                });
                onChange?.(newList);
                close();
            },
            authInfo.selectedTeamId || ''
        );
    };

    render() {
        const { keyWord, searchList, selectedList, searchListVisible } = this.state;
        const { searchClassName, itemClassName, onlySelect = false, t, addClassName } = this.props;
        return (
            <div className="user-picker-wrapper">
                <div className={`search ${searchClassName}`}>
                    <Input
                        allowClear
                        autoFocus
                        value={keyWord}
                        onChange={this.onKeywordChange}
                        className="calendar-custom-input"
                        suffix={
                            <span
                                className="calendar-add-participater"
                                onClick={this.pickParticipater}
                                clstag="pageclick|keycount|xtbg_calhome_1640073907694|39"
                            >
                                <IconFont
                                    className="calendar-add-participater-icon"
                                    type="iconapp_ic_doc_new"
                                />
                                添加
                            </span>
                        }
                    />
                </div>
                {!onlySelect ? (
                    <div className="selected-list">
                        <List
                            locale={{
                                emptyText: (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={t('暂无数据')}
                                    />
                                ),
                            }}
                            loading={this.state.listLoading}
                            dataSource={selectedList}
                            renderItem={(item) => {
                                return (
                                    <ListItem
                                        className={itemClassName}
                                        data={item}
                                        extra={this.itemExtra(item)}
                                    />
                                );
                            }}
                        />
                    </div>
                ) : null}

                {searchListVisible ? (
                    <div className={`result-list ${addClassName}`}>
                        <List
                            locale={{
                                emptyText: (
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={t('暂无数据')}
                                    />
                                ),
                            }}
                            loading={this.state.listLoading}
                            dataSource={searchList}
                            renderItem={(item) => {
                                return (
                                    <ListItem
                                        className={itemClassName}
                                        data={item}
                                        clickHandle={() => this.selectHandle(item)}
                                    />
                                );
                            }}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withTranslation('calendar')(UserPicker);
