import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

/**
 * 顺次重定向
 */
export default () => {
    const history = useHistory();
    useEffect(() => {
        history.push('/messages');
    }, [history]);
    return null;
};
