/**
 * 上传文件组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import './index.less';
import IconFont from '@/components/icon';
import { Tooltip, message as toast } from 'antd';
import { useTranslation, WithTranslation } from 'react-i18next';
import {
    ChatMessage,
    Employee,
    FileChatMessage,
    FileStatus,
    MessageStatus,
    SessionType,
} from '@/types/chat';
import {
    downLoadFile,
    getFileIconByMime,
    getFileName,
    getFileUrl,
    sizeToStr,
    getFileFilter,
} from '@/utils/chat/index';
import { MessageSendType } from '@/types/chat/enum';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import UploadControl from '@/baseComponents/Chat/message/MsgControl/UploadControl';
import { detectOS, FocusSDK, isFocusEnv } from '@/utils';
import EventBus from '@/utils/bus';
import { viewUploadFile } from '@/utils/chat/commonMessageModal';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import ImService from '@/server/ImService';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { changeEgovUrl } from '@/utils/tools';
interface UploadFileProps {
    isMergeForward?: boolean;
    message: FileChatMessage;
    sendType: MessageSendType;
    sessionType: SessionType;
    currentEmployee: Employee;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

interface DvaDispatchProps {
    updateSessionMessageMid: Function;
}

type IProps = Readonly<WithTranslation & UploadFileProps & DvaDispatchProps & ChatState>;
let dXhr: any = {};
function UploadFile(props: IProps) {
    const {
        isMergeForward,
        message,
        messageUploadStateMap,
        connectState,
        onReady,
        currentEmployee,
    } = props;
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const defaultDownloadPath =
        appRuntimeEnv.registryConfig.defaultDownloadPath || FocusSDK.getDownloadPath();
    const messageUploadState = useMemo(() => {
        return messageUploadStateMap[message.id] || null;
    }, [message, messageUploadStateMap]);

    const { t } = useTranslation('chat');
    const [status, setStatus] = useState(FileStatus.INITING);
    const [progress, setProgress] = useState(0.1);
    const [beginSend, setBeginSend] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    const [uploadResult, setUploadResult] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [path, setPath] = useState('');
    // console.log('upload', message);

    const task = useMemo(() => {
        if (message.taskId && message.task) {
            return message.task;
        }
        return null;
    }, [message]);

    useEffect(() => {
        if (isFocusEnv() && message.status !== MessageStatus.INIT) {
            const upLocalPath = message.upLocalPath;
            FocusSDK.checkLocalPathExist(upLocalPath || '', (exists: boolean) => {
                if (exists) {
                    if (isFocusEnv()) {
                        setStatus(FileStatus.COMPLETED);
                        setPath(upLocalPath);
                    } else {
                        // WEB 页面 兼容
                        setStatus(FileStatus.WEB_COMPLETED);
                        setPath(upLocalPath);
                    }
                } else {
                    // setStatus(FileStatus.INIT);
                    setPath('');
                }
            });
        }
    }, [message]);

    useEffect(() => {
        if (message.taskId && message.task && messageUploadState) {
            const task = message.task;
            switch (task.state) {
                case FileStatus.COMPLETED:
                    if (isFocusEnv()) {
                        setStatus(FileStatus.COMPLETED);
                    } else {
                        // WEB 页面 兼容
                        setStatus(FileStatus.WEB_COMPLETED);
                    }
                    setProgress(100);
                    onReady({
                        beginSend: true,
                        messageExtend: { url: messageUploadState.uploadResult.downloadUrl },
                    });
                    break;
                case FileStatus.FAILED:
                    setStatus(FileStatus.FAILED);
                    setProgress(messageUploadState.progress);
                    onReady({
                        beginSend: false,
                        messageExtend: { status: FileStatus.FAILED },
                    });
                    break;
                case FileStatus.CANCELED:
                    setStatus(FileStatus.CANCELED);
                    setProgress(messageUploadState.progress);
                    break;
                case FileStatus.UPLOADING:
                    setStatus(FileStatus.UPLOADING);
                    setProgress(messageUploadState.progress);
                    break;
            }
        } else if (message.url && !message.task) {
            if (isFocusEnv()) {
                setStatus(FileStatus.COMPLETED);
            } else {
                // WEB 页面 兼容
                setStatus(FileStatus.WEB_COMPLETED);
            }
            setProgress(0);
        }
    }, [messageUploadState, onReady]); //eslint-disable-line

    const statusConfig = useMemo(() => {
        const data: any = {
            [FileStatus.INITING]: [
                'iconapp_btn_file_cancel',
                'cancel-upload',
                'file-uploading',
                '',
                'file-info-status-ing',
            ],
            [FileStatus.INIT]: [
                'iconapp_btn_file_cancel',
                'cancel-upload',
                'file-uploading',
                '',
                'file-info-status-ing',
            ],
            [FileStatus.UPLOADING]: [
                'iconapp_btn_file_cancel',
                'cancel-upload',
                'file-uploading',
                '',
                'file-info-status-ing',
            ],
            [FileStatus.CANCELED]: [
                'iconic_refresh1',
                'upload-again',
                'upload-again',
                'file-info-status-re-upload',
                'file-info-status-ing',
            ],
            [FileStatus.FAILED]: [
                'iconic_refresh1',
                'upload-again',
                'upload-again',
                'file-info-status-re-upload',
                'file-info-status-ing',
            ],
            [FileStatus.COMPLETED]: [
                'iconbtn_localfile',
                'open-folder',
                'click-to-view',
                'file-info-status-view',
            ],
            [FileStatus.WEB_COMPLETED]: [
                '',
                'click-to-save',
                'click-to-save',
                'file-info-status-view',
                'file-info-status-size',
            ],
            [FileStatus.DOWNLOADING]: [
                'iconapp_btn_file_cancel',
                'cancel-download',
                'cancel-download',
                'file-info-status-view',
                'file-info-status-ing',
            ],
            [FileStatus.DUE]: ['', '', 'file-due', 'file-info-status-due', ''],
        };
        return data[status];
    }, [status]);

    const fileOperate = async (operateTipKey: string, e: any) => {
        if (
            status !== FileStatus.COMPLETED &&
            status !== FileStatus.WEB_COMPLETED &&
            !task &&
            operateKey !== 'cancel-download'
        ) {
            return false;
        }
        let config = JSON.parse(FocusSDK.getConfigData());

        switch (operateTipKey) {
            case 'click-to-save':
                if (!isFocusEnv() && message.url) {
                    // web 端下载文件
                    const name = getFileName(message);
                    const url = getFileUrl(message.url);
                    dXhr = downLoadFile(name, url, '', {
                        onProgress: (currPercent: any) => {
                            setStatus(FileStatus.DOWNLOADING);
                            setProgress(currPercent);
                        },
                        onFinish: () => {
                            setStatus(FileStatus.WEB_COMPLETED);
                            setProgress(100);
                            dXhr = {};
                            toast.success('下载成功');
                        },
                    });
                }
                break;
            case 'click-to-view':
                viewUploadFile(message, t, '', currentEmployee);
                break;
            case 'cancel-upload':
                setUploadStatus('canceled');
                setStatus(FileStatus.CANCELED);
                EventBus.emit(`chat:file-upload-status:${message.id}`, {
                    uploadStatus: FileStatus.CANCELED,
                });
                break;
            case 'cancel-download':
                dXhr.abort?.();
                setStatus(FileStatus.WEB_COMPLETED);
                setProgress(0);
                break;
            case 'upload-again':
                setUploadStatus('restart');
                setStatus(FileStatus.UPLOADING);
                EventBus.emit(`chat:file-upload-status:${message.id}`, {
                    uploadStatus: FileStatus.UPLOADING,
                });
                break;
            case 'open-folder':
                viewUploadFile(
                    message,
                    t,
                    config.defaultDownloadPath || FocusSDK.getDownloadPath(),
                    currentEmployee
                );
                break;
        }
    };

    const fileOperateClsTag = (operateTipKey: string): string => {
        if (status !== FileStatus.COMPLETED && status !== FileStatus.WEB_COMPLETED && !task) {
            return '';
        }
        switch (operateTipKey) {
            case 'click-to-save':
                return 'pageclick|keycount|focus_chat_01_1615797500283|35';
            case 'click-to-view':
                return 'pageclick|keycount|focus_chat_01_1615797500283|36';
            case 'cancel-upload':
                return 'pageclick|keycount|focus_chat_01_1615797500283|37';
            case 'upload-again':
                return 'pageclick|keycount|focus_chat_01_1615797500283|38';
            case 'open-folder':
                return 'pageclick|keycount|focus_chat_01_1615797500283|39';
            default:
                return '';
        }
    };

    // 文件上传进度
    const onUploadProgress = (progress: number) => {
        // setStatus(FileStatus.UPLOADING);
        // setProgress(progress);
    };

    // 文件上传完成
    const onUploadComplete = (data: any) => {
        // console.log('onUploadComplete ==>', '文件上传完成', data);
    };
    // 图片上传失败
    const onUploadFailed = (data: any) => {
        if (message.status === MessageStatus.INIT) {
            setStatus(FileStatus.FAILED);
            setUploadStatus('canceled');
        }
    };

    // 文件上传开始
    const onUploadStart = (data: any) => {
        setStatus(FileStatus.UPLOADING);
    };

    const onUploadCancel = (data: any) => {
        // console.log('onUploadCancel ==>', '文件上传取消', data);
    };

    const downloadDragFile = () => {
        const name = getFileName(message);
        const url = getFileUrl(message.url);
        FocusSDK.downloadFile(url, {
            saveAs: false,
            filename: name,
            filter: getFileFilter(message),
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                // console.log('finish path', path, 'message id', message.mid);
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(message.sessionId, message.mid, {
                    upLocalPath: path,
                });
                if (result) {
                    setPath(path);
                    setIsDragging(false);
                    // toast.success('下载完成');
                } else {
                    toast.error(t('failed'));
                    setIsDragging(false);
                }
            },
        });
    };

    const onDragStart = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log('upload', 'message', message, 'path', path, 'isDragging', isDragging);
        if (connectState && connectState.state === ConnectState.READY) {
            if (isDragging) {
                toast.warning(t('file-downloading-drag-save'));
                return;
            }
            if (!path) {
                setIsDragging(true);
                toast.warning(t('file-downloading-drag-save'));
                downloadDragFile();
            } else {
                FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.startDrag(path);
                    } else {
                        if (isDragging) {
                            toast.warning(t('file-downloading-drag-save'));
                            return;
                        }
                        setIsDragging(true);
                        toast.warning(t('file-downloading-drag-save'));
                        downloadDragFile();
                    }
                });
            }
        } else {
            toast.warning(t('drag-after-the-network-is-normal'));
            return;
        }
    };

    const showFileOperate = useMemo(() => {
        if (isFocusEnv()) {
            return true;
        } else {
            return status !== FileStatus.COMPLETED;
        }
    }, [status]);

    const [operateIcon, operateTipKey, operateKey, operateClass, sizeClass] = statusConfig;
    const [fileIcon, progressColor] = getFileIconByMime(message.fileType || '');
    const isDrag = FocusSDK.getDeviceInfo().platform !== 'web' && !isMergeForward;
    // 切出聊天页，组件销毁时，如果存在未完成的下载任务，自动终止下载
    useEffect(() => {
        return () => {
            dXhr.abort?.();
        };
    }, []);

    return (
        <div className="content ">
            <div className="file" draggable={isDrag} onDragStart={onDragStart}>
                <div className="file-content">
                    <div
                        className="file-content-name"
                        style={{ width: '100%' }}
                        onClick={(e) => {
                            fileOperate(operateKey, e);
                        }}
                    >
                        {message.name}
                        {/* {formatFileName(message.name)} */}
                    </div>
                    <div className="file-content-status" style={{ width: '100%' }}>
                        <div
                            className="file-content-item"
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <span className={sizeClass}>{sizeToStr(message.size)}</span>
                        </div>
                        {status === FileStatus.CANCELED ? (
                            <div className="file-info-dow">
                                {/* 已取消 */}
                                <span className="file-sucess">已取消</span>
                            </div>
                        ) : (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {status === FileStatus.FAILED &&
                                status === FileStatus.FAILED &&
                                message.status === MessageStatus.INIT ? (
                                    <div className="file-info-dow">
                                        {/* 上传失败 */}
                                        <span className="file-sucess">
                                            {t('file-error')}
                                            <IconFont
                                                type="icona-ic_pc_im_reupload"
                                                className="file-icon-warning"
                                                clstag={fileOperateClsTag(operateKey)}
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <>
                                        {status === FileStatus.COMPLETED ||
                                        status === FileStatus.WEB_COMPLETED ? (
                                            <div className="file-info-dow">
                                                {/* 上传成功 */}
                                                <span className="file-sucess">
                                                    {t('file-success')}
                                                    <IconFont
                                                        type="iconjs_ic_success"
                                                        className="file-icon-sucess"
                                                        clstag={fileOperateClsTag(operateKey)}
                                                    />
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="file-info-dow">
                                                {/* 正在上传 */}
                                                {t(operateKey) !== '查看详情' &&
                                                    t(operateKey) !== '取消下载' && (
                                                        <span>{t(operateKey)}</span>
                                                    )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {/* <div className="file-content-status">
                        <div className="file-info">
                            <div className="file-info-status">
                                {sizeClass && (
                                    <span className={sizeClass}>{sizeToStr(message.size)}</span>
                                )}
                                {showFileOperate && (
                                    <span
                                        clstag={fileOperateClsTag(operateKey)}
                                        onClick={(e) => {
                                            fileOperate(operateKey, e);
                                        }}
                                        className={operateClass}
                                    >
                                        {t(operateKey)}
                                    </span>
                                )}
                            </div>
                        </div>
                        {showFileOperate && operateIcon && (
                            <Tooltip placement="top" title={t(operateTipKey)}>
                                <IconFont
                                    type={operateIcon}
                                    className="file-operate"
                                    clstag={fileOperateClsTag(operateKey)}
                                    onClick={(e) => {
                                        fileOperate(operateTipKey, e);
                                    }}
                                />
                            </Tooltip>
                        )}
                    </div> */}
                    {/* <div className="file-line" style={{ width: '256px' }} /> */}
                </div>
                <img
                    src={changeEgovUrl(fileIcon)}
                    alt=""
                    className="file-icon"
                    draggable="false"
                    onClick={(e) => {
                        fileOperate(operateKey, e);
                    }}
                />
                {(status === FileStatus.UPLOADING ||
                    status === FileStatus.CANCELED ||
                    status === FileStatus.FAILED) && (
                    <div
                        className="file-img-mask"
                        style={{
                            backgroundImage: `conic-gradient(transparent ${
                                progress * 3.6
                            }deg,  rgba(0, 0, 0, 0.3) ${progress * 3.6}deg)`,
                        }}
                    />
                )}
                {status === FileStatus.DOWNLOADING && (
                    <div
                        className="file-img-mask"
                        style={{
                            backgroundImage: `conic-gradient(transparent ${
                                progress * 3.6
                            }deg,  rgba(0, 0, 0, 0.3) ${progress * 3.6}deg)`,
                        }}
                    />
                )}
                {/* {(status === FileStatus.UPLOADING ||
                    status === FileStatus.CANCELED ||
                    status === FileStatus.FAILED) && (
                    // <Progress
                    //     type="line"
                    //     percent={progress}
                    //     strokeWidth={3}
                    //     strokeColor={progressColor}
                    //     showInfo={false}
                    //     trailColor="#DEE0E3"
                    // />
                )} */}
                {isFocusEnv() ? (
                    <div className="file-status-bom">
                        <span
                            className="file-status-bom-text"
                            style={{ marginRight: '40px' }}
                            onClick={(e) => {
                                fileOperate(operateKey, e);
                            }}
                        >
                            {t(operateKey) !== '正在上传' &&
                                t(operateKey) !== '重新上传' &&
                                t(operateKey)}
                        </span>
                        <span
                            className="file-status-bom-text"
                            onClick={(e) => {
                                fileOperate(operateTipKey, e);
                            }}
                        >
                            {t(operateTipKey)}
                        </span>
                    </div>
                ) : (
                    <div className="file-status-bom">
                        <span
                            className="file-status-bom-text"
                            onClick={(e) => {
                                t(operateTipKey) === '下载'
                                    ? fileOperate(operateKey, e)
                                    : fileOperate(operateTipKey, e);
                            }}
                        >
                            {t(operateTipKey)}
                        </span>
                    </div>
                )}
            </div>
            {task?.id && (
                <UploadControl
                    message={message}
                    onProgress={onUploadProgress}
                    onComplete={onUploadComplete}
                    onFailed={onUploadFailed}
                    onStart={onUploadStart}
                    onCancel={onUploadCancel}
                    status={uploadStatus}
                />
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const { messageUploadStateMap, connectState, currentEmployee } = chat as ChatState;
    return { messageUploadStateMap, connectState, currentEmployee };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateSessionMessageMid(data: { message: ChatMessage; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
