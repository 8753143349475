import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';

export enum AVSDK {
    ZOOM = 'zoom', // 云联zoomSDK
    HUAWEI = 'huawei', // 华为SDK
    JBRTC = 'jbrtc', // 京办rtc
    XYLINK = 'xiaoyu', // 小鱼SDK
}

export interface MeetingInfoType {
    meetingTopic: string;
    password: string;
    meetingNumber: string;
}

export interface BasicUserInfo {
    pin: string;
    teamId: string;
    name: string;
    app: string;
    avatar: string;
}

export interface ToUserType {
    appId: string;
    userId: string;
    teamId: string;
    userName: string;
    avatar: string;
}

export enum SessionStateEnum {
    Call = 0, // 被叫
    Cancel = 1, // 呼叫方取消
    Ringing = 180, // 用户振铃 这个是反向发送消息
    UserReceive = 200, // 某个端用户接受了会邀
    Busy = 400, // 用户正忙
    UserReject = 486, // 某个端用户拒绝
    TIMEOUT = 502, // 未接听
}

export const sessionStateContentMap: {
    [index: string]: string;
} = {
    [SessionStateEnum.Call]: '被叫',
    [SessionStateEnum.Cancel]: '已取消',
    [SessionStateEnum.Ringing]: '已振铃',
    [SessionStateEnum.UserReceive]: '已接听',
    [SessionStateEnum.UserReject]: '已拒绝',
    [SessionStateEnum.Busy]: '忙碌中',
    [SessionStateEnum.TIMEOUT]: '超时未接听',
};

// jrtc web sdk 使用
export const EXTERNAL_CALL_STATUS = {
    ACCEPT: 'ACCEPT',
    REJECT: 'REJECT',
    RING: 'RING',
    BUSY: 'BUSY',
    TIMEOUT: 'TIMEOUT',
};
