// import App from './AppV2';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import 'element-scroll-polyfill';
import 'url-search-params-polyfill';
import '@/utils/custom-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './public-path';
import 'antd/dist/antd.less';
import './index.less';
import '@/utils/messages';
import styleSass from './style.js';

import cache from './utils/cache';
import EEPaasProvider from './provider/EEPaasProvider';
import OpenTokenProvider from './provider/OpenTokenProvider';
import dva from 'dvajs';
import { createBrowserHistory as createHistory } from 'history';
import { handleException } from './utils/handleErrors';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { addConfig } from './config/config';
import { FocusSDK as focusSDK, isFocusEnv } from '@/utils';

let dsn = 'https://6461eef6617f4acba84d59c3cd3089bf@jzb.jdcloud.com/jzbsentry/5';
let netType = 'public';
if (focusSDK.isPrivateNet()) {
    dsn = 'https://6461eef6617f4acba84d59c3cd3089bf@jzb.jdcloud.com/jzbsentry/5';
    netType = 'private';
}

(window as any).styleSass = styleSass;
console.log('-=========', (window as any).styleSass);

// try {
//     Sentry.init({
//         dsn,
//         autoSessionTracking: true,
//         // integrations: [new Integrations.BrowserTracing()],
//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         tracesSampleRate: 1.0,
//     });

//     Sentry.configureScope(function (scope) {
//         scope.setTag('custom-container', isFocusEnv() ? 'electron' : 'web');
//         scope.setTag('custom-net', netType);
//     });
// } catch (e) {
//     console.log(e);
// }

if (!String.prototype.repeat) {
    // eslint-disable-next-line no-extend-native
    String.prototype.repeat = function (count) {
        if (this === null) {
            throw new TypeError("can't convert " + this + ' to object');
        }
        let str = String(this);
        // eslint-disable-next-line no-param-reassign
        count = Number(count);
        // eslint-disable-next-line no-self-compare
        if (count !== count) {
            // eslint-disable-next-line no-param-reassign
            count = 0;
        }
        if (count < 0) {
            throw new RangeError('repeat count must be non-negative');
        }
        if (count === Infinity) {
            throw new RangeError('repeat count must be less than infinity');
        }
        // eslint-disable-next-line no-param-reassign
        count = Math.floor(count);
        if (str.length === 0 || count === 0) {
            return '';
        }
        // 确保 count 是一个 31 位的整数。这样我们就可以使用如下优化的算法。
        // 当前（2014年8月），绝大多数浏览器都不能支持 1 << 28 长的字符串，所以：
        if (str.length * count >= 1 << 28) {
            throw new RangeError('repeat count must not overflow maximum string size');
        }
        let rpt = '';
        for (;;) {
            if ((count & 1) === 1) {
                rpt += str;
            }
            // eslint-disable-next-line no-param-reassign
            count >>>= 1;
            if (count === 0) {
                break;
            }
            str += str;
        }
        return rpt;
    };
}
if (!window.crypto) {
    // @ts-ignore
    window.crypto = window.msCrypto;
}

const searchParams = new URLSearchParams(window.location.search);
let config = {};
try {
    config = JSON.parse((searchParams.get('option') || '{}').trim());
} catch (error) {
    config = {};
}
// 载入配置
addConfig(config);

const provider = (searchParams.get('provider') || '').trim().toLowerCase();

if (provider === 'open') {
    cache.tokenProvider = new OpenTokenProvider();
} else if (provider === 'eepaas') {
    cache.tokenProvider = new EEPaasProvider();
}

// 1. Initialize
const app = dva({
    history: createHistory,
});
// 2. Plugins
// @ts-ignore 关闭hooks
// app.use(dvaHooks());

// 3. Model
app.model(require('./models').default); // eslint-disable-line
app.model(require('./models/user').default); // eslint-disable-line
app.model(require('./models/chat').default); // eslint-disable-line
app.model(require('./models/calendar').default); // eslint-disable-line
app.model(require('./models/ebook').default); // eslint-disable-line
app.model(require('./models/work').default); // eslint-disable-line
app.model(require('./models/supervise').default); // eslint-disable-line
app.model(require('./models/joyspace').default); // eslint-disable-line
app.model(require('./models/netStatus').default); // eslint-disable-line
app.model(require('./models/thirdapp').default); // eslint-disable-line

// 4. Router
app.router(require('./AppV2.tsx').default); // eslint-disable-line
// 5. Start
const App = app.start();

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
