import { gatewayInstance } from '../server/gateway';

export async function notifyMask(body = {}) {
    try {
        return gatewayInstance
            .request({
                api: 'focus.isFirstUse',
                method: 'POST',
                body,
            })
            .then((res) => res)
            .catch((err) => {
                console.log('查询是否打开过通知遮罩出错', err);
            });
    } catch (error) {
        console.log(error, '查询是否打开过通知遮罩出错');
        // throw error;
    }
}
