/**
 * 最近使用和常用应用本地存储的工具文件
 */

import { FavoriteType } from '@/services/AppStore';

export const LOCAL_FAVORITE = 'favoriteApplicationArr';
export const LOCAL_RECENT = 'recentApplicationArr';
export const RECENT_MAX_LEN = 4;

/**
 * 处理数据，初始化常用应用列表和最近使用列表
 */
export function dealThirdApplications(key: string, favoriteIds: any[], thirdApplications: any[]) {
    const favorite: any[] = [];
    const recent: any[] = [];
    const recentIds: any[] = getLocalRecent(key) || [];
    let appObj: any | null = {};

    thirdApplications.forEach((item: any) => {
        item.applications.forEach((appInfo: any) => {
            appInfo.favorite = FavoriteType.FavoriteNo;
            appObj[appInfo.applicationId] = appInfo;
        });
    });

    favoriteIds.forEach((id: string) => {
        const appInfo = appObj[id];
        thirdApplications.forEach((item: any) => {
            const index = item.applications.findIndex((i: any) => i.applicationId === id);
            const obj = item.applications[index];
            obj && (obj.favorite = FavoriteType.FavoriteYes);
        });
        if (appInfo) {
            appInfo.favorite = FavoriteType.FavoriteYes;
            favorite.push(appInfo);
        }
    });

    recentIds.forEach((id: string) => {
        const appInfo = appObj[id];
        if (appInfo) {
            recent.push(appInfo);
        }
    });

    appObj = null;

    setLocalFavorite(
        key,
        favorite.map((i) => i.applicationId)
    );
    setLocalRecent(
        key,
        recent.map((i) => i.applicationId)
    );

    return {
        favorite,
        recent,
        thirdApplications,
    };
}

/**
 * 添加常用应用
 */
export function addFavorite(key: string, id: string) {
    const favoriteArr = getLocalFavorite(key);
    favoriteArr?.push(id);
    setLocalFavorite(key, favoriteArr || []);
}

/**
 * 移除常用应用
 */
export function removeFavorite(key: string, id: string) {
    const favoriteArr = getLocalFavorite(key);
    setLocalFavorite(key, favoriteArr?.filter((i: string) => i !== id) || []);
}

/**
 * 处理最近使用
 */
// eslint-disable-next-line max-params
export function dealRecent(
    key: string,
    curitem: any,
    thirdAppListAll: any[],
    recentGroup: any,
    info: any
) {
    // let item = curitem;
    const item: any = info ? transformInfo(info) : curitem;
    if (!item) {
        return false;
    }
    const thirdAppIdsList = thirdAppListAll.map((i: any) => i.groupId);
    const recentGroupId = recentGroup.groupId;

    let recentArr;
    if (thirdAppIdsList.includes(recentGroupId)) {
        recentArr = thirdAppListAll[thirdAppIdsList.indexOf(recentGroupId)].applications;
    } else {
        thirdAppListAll.splice(1, 0, recentGroup);
        recentArr = recentGroup.applications;
    }

    const recentIds = recentArr.map((i: any) => i.applicationId);
    if (recentIds.includes(item.applicationId)) {
        const index = recentIds.indexOf(item.applicationId);
        const theItem = recentArr.splice(index, 1)[0];
        recentArr.unshift(theItem);
    } else {
        recentArr.unshift(item);
        if (recentArr.length > RECENT_MAX_LEN) {
            recentArr.pop();
        }
    }
    setLocalRecent(
        key,
        recentArr.map((i: any) => i.applicationId)
    );
    return recentArr;
}

/**
 * 存储常用应用
 */
export function setLocalFavorite(key: string, arr: any[]) {
    return localStorage.setItem(`${key}_${LOCAL_FAVORITE}`, JSON.stringify(arr));
}

/**
 * 获取本地常用应用列表
 */
export function getLocalFavorite(key: string): any[] | null {
    localStorage.removeItem(LOCAL_FAVORITE);
    const str = localStorage.getItem(`${key}_${LOCAL_FAVORITE}`);
    if (!str) {
        return null;
    }
    return JSON.parse(str);
}

/**
 * 移除localstorage中的常用应用列表
 */
export function removeLocalFavorite(key: string) {
    return localStorage.removeItem(`${key}_${LOCAL_FAVORITE}`);
}

/**
 * 存储最近使用
 */
export function setLocalRecent(key: string, arr: any[]) {
    return localStorage.setItem(`${key}_${LOCAL_RECENT}`, JSON.stringify(arr));
}

/**
 * 获取最近使用列表
 */
export function getLocalRecent(key: string): any[] | null {
    localStorage.removeItem(LOCAL_RECENT);
    const str = localStorage.getItem(`${key}_${LOCAL_RECENT}`);
    if (!str) {
        return null;
    }
    return JSON.parse(str);
}

/**
 * 根据传入的应用列表，标记已经添加为常用的应用
 */
export function markFavorite(key: string, apps: any[]) {
    const favorites = getLocalFavorite(key);
    return apps.map((app) => {
        if (favorites?.includes(app.applicationId)) {
            app.favorite = FavoriteType.FavoriteYes;
        } else {
            app.favorite = FavoriteType.FavoriteNo;
        }
        return app;
    });
}

/**
 * 搜索出来的应用转化为工作台中应用的数据结构
 */
export function transformInfo(item: any) {
    const newItem: any = Object.assign(item, item.ext);
    newItem.icon = newItem.avatar;
    newItem.name = newItem.appName;
    delete newItem.ext;
    delete newItem.foreignLanguage;
    delete newItem.highlight;
    return newItem;
}
