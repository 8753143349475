/* eslint-disable no-console */
/**
 * 文本消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { MessageSendType } from '@/types/chat/enum';
import './index.less';
import { MessageStatus, SessionType, TextChatMessageExt } from '@/types/chat';
import ChatState, { UserState } from '@/types/chat/State';
import { connect } from 'dva';
import { convertContentToArray, MessageCardType } from '@/components/chat/utils/message';
import { useTranslation } from 'react-i18next';
import { UserCardPayload } from '@/models';
import { getTextMessageContent } from '../../utils/commonMessageModal';
import { UserCardData } from '@/types/UserCard';
import Context from '@/components/chat/context';
import { EBookSubDeptInfo } from '@/models/ebook';
import ReplyMessage from '../ReplyMessage';
import ShortLink from '../message-components/ShortLink';
import JoyspaceTopic from '../message-components/JoyspaceTopic';
import { handleOpenMentionUserInfo } from '@/utils/chat/index';
interface TextProps {
    sText?: string;
    sMid?: number;
    message: TextChatMessageExt; // 发送消息内容
    sessionType: SessionType;
    sendType: MessageSendType;
    beginSend: boolean;
    messageExtend: any;
    isMergeForward?: boolean;
    curSearchMessgae?: any;
    searchKey?: string;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

interface DvaDispatchProps {
    unLock: Function;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    getEbookDeptInfo: (params: any) => void;
}

type ITextProps = Readonly<
    TextProps & UserState & DvaDispatchProps & ChatState & EBookSubDeptInfo & UserCardData
>;
function mapStateToProps({ chat }: { chat: ChatState }) {
    const { currentEmployee, selectedSession, connectState } = chat;
    return {
        userInfo: currentEmployee,
        currentEmployee,
        selectedSession,
        connectState,
    };
}
function Text(props: ITextProps) {
    const {
        sendType,
        message,
        onReady,
        selectedSession,
        sText = '',
        sMid = -1,
        isMergeForward,
        curSearchMessgae,
        searchKey,
    } = props;
    const { groupRosterIdentity, currentEmployee, userData } = React.useContext(Context);
    const { t } = useTranslation('chat');
    const [isHighLight, setIsHighLight] = useState(false);

    const realDisplayContent = useMemo(() => {
        if (message.displayContent && message.displayContent.length > 0) {
            return message.displayContent;
        }
        // console.log('888', message.content, convertContentToArray(message.content));
        return convertContentToArray(message.content);
    }, [message]);

    // console.log('realDisplayContent', realDisplayContent);
    useEffect(() => {
        const tempMid = curSearchMessgae ? curSearchMessgae.mid : sMid;
        const keyword = searchKey || sText;
        if (tempMid === message.mid && keyword) {
            setIsHighLight(true);
            setTimeout(() => {
                setIsHighLight(false);
            }, 3000);
        } else {
            setIsHighLight(false);
        }
    }, [curSearchMessgae, sMid, searchKey, sText, message]);

    const getClass = useCallback(() => {
        return classNames({
            message: true,
            'text-message': true,
            // owner: sendType === MessageSendType.OWNER,
        });
    }, []);

    useEffect(() => {
        if (
            (sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF) &&
            message.status === MessageStatus.INIT
        ) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);

    // if (!message.reply || (message.reply && message.replyMsgs) || isMergeForward) {
    return (
        <div className="content">
            <div
                className={getClass()}
                onClick={(e) =>
                    handleOpenMentionUserInfo(e, t, {
                        currentEmployee,
                        selectedSession,
                        groupRosterIdentity,
                    })
                }
            >
                <ReplyMessage message={message} sendType={sendType} />
                {/* <p
                    style={{
                        wordBreak: 'break-word',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: getTextMessageContent(message, currentEmployee, sText, sMid),
                    }}
                /> */}
                {realDisplayContent.map((item, index) => {
                    return (
                        <div key={index} style={{ margin: '1px 0' }}>
                            {item.type === MessageCardType.Text ? (
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    className={`top-box ${
                                        selectedSession.isSecret ? 'top-box_isSecret' : ''
                                    }`}
                                    dangerouslySetInnerHTML={{
                                        __html: getTextMessageContent(
                                            { ...message, content: item.content },
                                            currentEmployee,
                                            isHighLight ? (searchKey ? searchKey : sText) : '',
                                            isHighLight
                                                ? curSearchMessgae
                                                    ? curSearchMessgae.mid
                                                    : sMid
                                                : -1
                                        ).trim(),
                                    }}
                                />
                            ) : item.type === MessageCardType.JoyspaceTopic ? (
                                <JoyspaceTopic
                                    content={item}
                                    {...props}
                                    isMergeForward={isMergeForward}
                                />
                            ) : (
                                <ShortLink
                                    shortLinkItem={item}
                                    searchKey={isHighLight ? (searchKey ? searchKey : sText) : ''}
                                    curSearchMid={
                                        isHighLight
                                            ? curSearchMessgae
                                                ? curSearchMessgae.mid
                                                : sMid
                                            : -1
                                    }
                                    curMessageMid={message.mid}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
    // }
    // return null;
}
function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        getEbookDeptInfo(data: any) {
            dispatch({ type: 'ebook/getEbookDeptInfo', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Text);
