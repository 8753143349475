import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';

import taskPng1 from './standardization/task_communicate.png';
import taskPng2 from './standardization/task_preserve.png';
import taskPng3 from './standardization/task_proceed.png';
import taskPng4 from './standardization/task_restart.png';
import './style.less';
export const IconTaskCommunicate = <img src={taskPng1} />;
export const IconTaskPreserve = <img src={taskPng2} />;
export const IconTaskProceed = <img src={taskPng3} />;
export const IconTaskRestart = <img src={taskPng4} />;

export { default as DefaultUserAvatar } from '../../assets/img/default_avatar.png';
export { default as DefaultTeamAvatar } from '../../assets/img/group_default_avatar.png';

const { PUBLIC_URL } = process.env;

const IconFont = createFromIconfontCN({
    scriptUrl: PUBLIC_URL ? `${PUBLIC_URL}/iconfont.js` : '/iconfont.js', // '//at.alicdn.com/t/font_1717591_5fs7ttlpnm6.js',
});

export default IconFont;

export const IconFresh = <IconFont type="iconjs_ic_break" />;
export const IconSearch = <IconFont type="iconapp_ic_search" />;
export const IconAppointMeeting = <IconFont type="iconapp_btn_joymeeting_video" />;

export const IconCloseEye = <IconFont type="iconzw_web_eye_closed" />;
export const IconTitleRight = <IconFont type="iconic_joyday_title_dropdown" />;

export const IconChat = <IconFont type="iconic_chat" />;
export const IconMultimedia = <IconFont type="iconapp_btn_joymeeting_video" />; // TODO: 临时使用该图标
export const IconShare = <IconFont type="iconapp_btn_peopleshare" />;
export const IconJoyspace = <IconFont type="iconapp_ic_bardoc" />;
export const IconCalendar = <IconFont type="iconic_day" />;
export const IconWork = <IconFont type="iconbacklog" />;
export const IconThirdApp = <IconFont type="iconapp_tabbar_application" />;
export const IconClose = <IconFont type="iconwin_Close" />;
export const IconRefresh = <IconFont type="iconbtn_reduction" />;
export const IconEBook = <IconFont type="iconapp_btn_addressbook" />;
export const IconMail = <IconFont type="iconapp_btn_pc_mail" />;
export const IconSetup = <IconFont type="iconapp_btn_Setup" />;
export const IconArrowRight = <IconFont type="iconapp_ic_arrow_right" />;
export const IconMore = <IconFont type="iconwin_more" />;
export const IconEdit = <IconFont type="iconapp_btn_edit" />;
export const IconRdit = <IconFont type="iconrdit" />;
export const IconFailure = <IconFont type="iconic_failure" />;
export const IconSet = <IconFont type="iconicon_Set" />;
export const IconExit = <IconFont type="iconzw_ic_exit" />;
export const IconManager = <IconFont type="iconiconbtn_manager" />;
export const IconAdd = <IconFont type="iconbtn_add" />;
export const IconGroup = <IconFont type="iconapp_btn_peoplegroup" />;
export const IconMeeting = <IconFont type="iconapp_ic_Joymeeting" />;
export const IconJoinMeeting = <IconFont type="iconapp_ic_join_meeting" />;
export const IconCopyBtn = <IconFont type="iconweb_btn_copy" />;
export const IconZhuanban = <IconFont className="create-zhuanban-icon" type="iconzw_app_project" />;
export const IconMute = <IconFont type="iconmute" />;
export const IconDocHome = <IconFont type="iconapp_ic_dochome" />;
export const IconMyDoc = <IconFont type="iconapp_ic_mydoc" />;
export const IconMobilePhone = <IconFont type="iconic_mobilephone" />;
// 收藏
export const IconKeep = <IconFont type="iconic_yipingfen" />;
export const IconCancelKeep = <IconFont type="iconic_pingfen" />;
// 圆加号
export const IconAddApp = <IconFont type="iconapp_btn_add" />;
// 圆取消
export const IconCircleClose = <IconFont type="iconjs_ic_error" />;
// 搜索
// export const IconSearch = <IconFont type="iconic_search" />;

export const IconRemind3 = <IconFont type="iconzw_app_remind3" />; // 催办小铃铛图标
// export const IconlogDown = <IconFont type="iconlog_down" />;
export const IconDefualtOrg = (
    <div
        style={{
            color: '#fff',
            backgroundColor: (window as any).styleSass.primaryColor,
            borderRadius: 8,
            height: 36,
            width: 36,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        className="subTitle"
    >
        <IconFont type="iconapp_btn_cha" />
    </div>
);
export const IconRight = <IconFont type="iconapp_ic_arrow_right" />;

export const IconArrowLeft = <IconFont type="iconwin_left" />;
export const IconJoyMeetingGroup = <IconFont type="iconapp_btn_joymeeting_group" />;
export const IconTopUp = <IconFont type="iconapp_btn_top_up" />;
export const IconSwitch = <IconFont type="iconlog_switch" />;
export const IconApplicationShop = <IconFont type="iconapp_application_shop" />;
export const getIconFromString = (type: string) => <IconFont type={type} />;
export const IconicPcDongaoAdd = <IconFont type="iconic_pc_dongao_add" />;
export const IconbtnTopUp = <IconFont type="iconbtn_top_up" />;
export const IconbtnTopDown = <IconFont type="iconbtn_top_down" />;
