import React, { useEffect } from 'react';
import './index.less';
import TabMultimedia from './TabMultimedia';
import { Tabs } from 'antd';
import IconFont from '@/components/icon';
import { analysisLog } from '@/utils/logAnalytics';

export default function () {
    const { TabPane } = Tabs;

    useEffect(() => {
        analysisLog('xtbg_PCMeeting', 'index');
    }, []);

    return (
        <div className="multimedia">
            <Tabs type="card" defaultActiveKey="1">
                <TabPane
                    tab={
                        <span>
                            <IconFont type="iconapp_btn_joymeeting_video" />
                            视频会议
                        </span>
                    }
                    key="1"
                >
                    <TabMultimedia />
                </TabPane>
            </Tabs>
        </div>
    );
}
