import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button, message } from 'antd';
import { openModalTextBox } from '../ModalTextBox';
import Icon from '../../Icon';
import { _Active, selectArrayType } from './types';
import { ICurrentRouteInfo } from '../../types';
import api from '../../api';
import { useTranslation } from '../../context';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import './index.less';
import { folderTypeEnum } from '@/components/Joyspace/config/permissions.config';
import SelectTree from './SelectTree';
import events from '../../context/events';
import { useLocation } from 'react-router-dom';
import { isMySpace } from '../../util';
import { folderType } from '../../config/permissions.config';
interface Props {
    title: string;
    currentRouteInfo?: ICurrentRouteInfo;
    isTeam?: boolean;
    // type?: 'myspace' | 'teamspace' | 'other';
    callback?: (
        data: {
            id?: string;
            type?: folderTypeEnum | null;
            rootId?: string;
        },
        close: () => void
    ) => void;
    treeFreshFunction?: Function;
    visible?: boolean;
    selectArray?: selectArrayType[];
    canSelectMy?: boolean;
    onClose?: () => void; // 供本文件使用，外部调用不需要传
    appRuntimeEnv?: AppRuntimeEnv;
}

export default function Picker(props: Props) {
    const lang = useTranslation();
    const {
        selectArray,
        isTeam,
        callback,
        onClose,
        canSelectMy = false,
        currentRouteInfo,
        appRuntimeEnv,
        treeFreshFunction,
    } = props;
    const [selectType, setSelectType] = React.useState<'file' | 'folder' | 'both'>('both');
    const [excludesFolerMap, setExcludesFolerMap] = React.useState<any>({});

    const [visible, setVisible] = React.useState(props.visible ?? true);
    const [addabled, setAddabled] = React.useState(true);
    const [doConfirmable, setConfirmable] = React.useState(true);
    const [active, setActive] = React.useState<_Active>({
        id: folderType.MY,
        type: folderTypeEnum.MY,
    });
    useEffect(() => {
        // 有选中时传入，移动和复制功能
        let selectType = 'folder';
        // 选中的如果有文件夹则需要过滤掉。
        const selectFolerMap: {
            [key: string]: any;
        } = {};
        if (selectArray) {
            let fileCount = 0;
            let folderCount = 0;
            selectArray?.forEach((item) => {
                if (item.type === 'folder') {
                    folderCount++;
                    selectFolerMap[item.id] = 1;
                } else if (item.type === 'file') {
                    fileCount++;
                }
            });
            if (fileCount > 0 && folderCount === 0) {
                selectType = 'file';
            } else if (fileCount > 0 && folderCount > 0) {
                selectType = 'both';
            } else {
                selectType = 'folder';
            }
            setSelectType(selectType as any);
            setExcludesFolerMap(selectFolerMap);
            // 如果不都是文件夹 共享文件的根不可选择
            // if (selectType !== 'folder') {
            //     navlinks[1].selectable = false;
            //     navlinks[1].disabled = true;
            // }
        }
    }, [selectArray]);
    useEffect(() => {
        // 如果不都是文件夹 共享文件的根不可选择置灰确定按钮
        if (selectType !== 'folder' && active.id === folderType.SHARE) {
            setConfirmable(false);
        } else {
            setConfirmable(true);
        }
    }, [active, selectType]);
    useEffect(() => {
        if (isTeam) {
            setActive({
                id: folderType.SHARE,
                type: folderTypeEnum.SHARE,
            });
        } else {
            setActive({
                id: folderType.MY,
                type: folderTypeEnum.MY,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTeam, setActive]);
    const ok = React.useCallback(
        (active: _Active) => {
            // const type = active.type;
            const data = {
                ...active,
            };

            callback?.(data, () => {
                setVisible(false);
            });
        },
        [callback]
    );

    function createCategory(active: _Active, setActive: (a: _Active) => void) {
        return () => {
            openModalTextBox({
                title: lang('c.categoriesPicker.create'),
                onOk: (value, close) => {
                    api.categories.create(active?.id ?? '', value).then((data) => {
                        if (data) {
                            // tree数据更新
                            events.emit('selectTreeAddNode', data, active?.id);
                            // 文档左侧导航更新
                            treeFreshFunction?.({ id: active?.id });
                            message.success(lang('common.success'));
                            close();
                        } else {
                            message.error(lang('common.fail'));
                        }
                    });
                },
            });
        };
    }

    const handleCancle = useCallback(() => {
        setVisible(false);
        setTimeout(() => onClose?.(), 1000);
    }, [onClose]);

    const UseSelectTree = React.useMemo(() => {
        return (
            <SelectTree
                canSelectMy={canSelectMy}
                type={!isTeam ? 'myspace' : 'teamspace'}
                setActive={setActive}
                setAddabled={setAddabled}
                setConfirmable={setConfirmable}
                // currentRouteInfo={currentRouteInfo}
                // selectArray={selectArray}
                selectType={selectType}
                excludesFolerMap={excludesFolerMap}
                // categoriesInterceptor={props.categoriesInterceptor}
            />
        );
    }, [isTeam, selectType, excludesFolerMap, canSelectMy]);
    return (
        <Modal
            visible={visible}
            footer={null}
            title={props.title}
            width={480}
            className="joyspace-spacepicker-tree"
            onCancel={handleCancle}
            destroyOnClose
        >
            <div data-main>
                {UseSelectTree}
                <div data-footer>
                    {addabled && (
                        <span data-create onClick={createCategory(active, setActive)}>
                            <Icon type="iconlog_add" className="joyspace-spacepicker-add-icon" />
                            <span>{lang('c.categoriesPicker.create')}</span>
                        </span>
                    )}
                    <Button type="default" onClick={handleCancle}>
                        {lang('common.cancel')}
                    </Button>
                    <Button type="primary" disabled={!doConfirmable} onClick={() => ok(active)}>
                        {lang('common.confirm')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export function openPicker(props: Props) {
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    function onClose() {
        if (ele) {
            ReactDOM.unmountComponentAtNode(ele);
            ele.remove();
        }
    }
    ReactDOM.render(<Picker {...{ ...props, onClose }} />, ele);
}
