import { FocusSDK, isFocusEnv } from '@/utils';
import moment from 'moment';
import { onlineSyncPages } from '../api/pages';
import { PageType } from '../const';
import PageModel from '../datamodel/PageModel';
import JoyspaceController, { SyncStatusEunm } from './JoyspaceController';
import InitDBService from './InitJoySpaceController';
import events from '@/components/Joyspace/context/events';
class SyncDbData {
    // static isData = false;
    static listDBService: any;
    static newDBService: any;
    static pageService: any;
    static userService: any;
    static _pagesService: any;
    static sheetService: any;
    static clearSyncData: any;
    // static
    static init() {
        this.listDBService = InitDBService.GetListService();
        this.newDBService = InitDBService.GetNewService();
        this.pageService = InitDBService.GetPageService();
        this.userService = InitDBService.GetUserService();
        this._pagesService = InitDBService.GetPagesService();
        this.sheetService = InitDBService.GetSheetService();
        // 不加setTimeout，这里会有问题。获取数据库对象，虽然能成功，但是cbs为{}。导致 this.newDBService.getNewDBListAll() 获取不到数据。

        this.clearSyncData = setTimeout(() => {
            this.onlineSyncData();
        }, 1000);
    }
    // // 处理正在同步中的数据
    // static handleInSync() {
    //     const result = JoyspaceController.getSyncStateMap() || {};
    //     // 将同步中的数据修改为同步失败
    //     for (let key in result) {
    //         if (result[key] === SyncStatusEunm.PROCESS) {
    //             JoyspaceController.setSyncStateMap(key, SyncStatusEunm.ERROR);
    //             // events.emit('list.refresh', { syncStatus: SyncStatusEunm.ERROR, id: key });
    //         }
    //     }
    //     // console.log(result, 'result====>');
    // }
    // 将本地数据同步到后端
    static handleSyncApi(data = [], isSync = false) {
        if (!data.length || !data[0]) {
            if (navigator.onLine && !isSync) {
                events.emit('list.syncReload');
                // console.log('=================>=======');
                // events.emit('list.reload');
            }
            // this.isData = false;
            return;
        }
        // this.isData = true;
        const pageId = (data[0] as any).id;
        const result = JoyspaceController.getSyncStateMap() || {};
        // (result[pageId] === SyncStatusEunm.ERROR ||
        // result[pageId] === SyncStatusEunm.DATAERROR) &&
        // !isSync
        if (result[pageId] === SyncStatusEunm.DATAERROR && !isSync) {
            data.splice(0, 1);
            this.handleSyncApi(data);
            return;
        } else if (!navigator.onLine && result[pageId] === SyncStatusEunm.PROCESS) {
            JoyspaceController.setSyncStateMap(pageId, SyncStatusEunm.ERROR);
            events.emit('list.refresh', { syncStatus: SyncStatusEunm.ERROR, page_id: pageId });
            data.splice(0, 1);
            this.handleSyncApi(data);
            return;
        }
        if (!navigator.onLine) {
            return;
        }
        JoyspaceController.setSyncStateMap(pageId, SyncStatusEunm.PROCESS);
        if (isFocusEnv()) {
            FocusSDK.sendGlobalWebviewMessage('onlineOfflineCheck', {
                isOnline: true,
                doSync: true,
                oldPageId: pageId,
            });
        }
        events.emit('list.refresh', { syncStatus: SyncStatusEunm.PROCESS, page_id: pageId });
        onlineSyncPages(data[0])
            .then((result) => {
                let res = result;
                if (result && typeof result === 'string') {
                    res = { code: 200, data: result };
                }
                // console.log(res, 'resresresresresresresresres');
                console.log('离线接口返回值onlineSyncPages===>', res);
                const newPageId = res.data;
                if (res.code === '10002') {
                    // 需要数据库保存错误信息
                    JoyspaceController.setSyncStateMap(pageId, SyncStatusEunm.DATAERROR);
                    if (!JoyspaceController.pageService) {
                        JoyspaceController.initDb();
                    }
                    JoyspaceController.pageService.updatePageInFile(pageId, {
                        syncErrorInfo: '保存出错',
                    });
                    events.emit('list.refresh', {
                        syncStatus: SyncStatusEunm.DATAERROR,
                        page_id: pageId,
                    });
                } else if ((res.code !== '10002' || res.code !== '10001') && newPageId) {
                    try {
                        // 删除数据库
                        if (isFocusEnv()) {
                            FocusSDK.sendGlobalWebviewMessage('onlineOfflineCheck', {
                                isOnline: true,
                                newPageId: newPageId,
                                oldPageId: pageId,
                            });
                        }
                        events.emit('list.refresh', {
                            isNew: false,
                            page_id: pageId,
                            oldPageId: newPageId,
                            syncStatus: SyncStatusEunm.COMPLETE,
                        });
                        this.removeDb(pageId);
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    throw new Error('同步失败了');
                }
                // 处理同步数据失败后，同步下一条数据，不能阻塞其他数据同步
                data.splice(0, 1);
                this.handleSyncApi(data);
            })
            .catch((e) => {
                console.log('getDbData111error', e);
                // 处理同步数据失败后，重试一次，重试一次以后继续
                if ((data[0] as any).isRetry === 1) {
                    events.emit('list.refresh', {
                        syncStatus: SyncStatusEunm.ERROR,
                        page_id: pageId,
                    });
                    JoyspaceController.setSyncStateMap(pageId, SyncStatusEunm.ERROR);
                    data.splice(0, 1);
                } else {
                    // 第一次失败先把isRetry置换成为1
                    (data[0] as any).isRetry = 1;
                }
                this.handleSyncApi(data);
            });
    }
    // 根据文档id, 删除数据库中的对应数据
    static async removeDb(pageId: string) {
        try {
            await this.newDBService.remove(pageId);
            await this.pageService.remove(pageId);
            await this._pagesService.deleteFromDB(`base-data-${pageId}`);
            await this.sheetService.deleteFromDB(`base-data-${pageId}`);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    // 根据pageid获取文档内容
    static async findPageContent(pageType: PageType, pageId: string) {
        // 后期优化掉
        let content: any = [];
        try {
            if (pageType === PageType.Page) {
                const {
                    value: { ops },
                } = await this._pagesService.getFromDB(`page-data-${pageId}`);
                content = ops;
                console.log(content, '_pages=========>');
            } else if (pageType === PageType.Sheet) {
                const { value } = await this.sheetService.getFromDB(`sheet-commands-${pageId}`);
                content = value;
                console.log(content, 'sheet=========>');
            }
        } catch (error) {
            // console.error(error);
        }
        return content;
    }
    // 组合参数
    static getParams(page: any, content: any) {
        // 可以枚举 等会优化
        let pageType = 1;
        if (page.page_type === PageType.Page) {
            pageType = 1;
        } else if (page.page_type === PageType.Sheet) {
            pageType = 2;
        }
        // 组合接口所需的参数
        let item: any = {};
        item.id = page.page_id;
        item.folderId = page.space_id;
        item.offLineType = pageType;
        item.title = page.title;
        item.updateTime = moment(page.updateTime).format('YYYY-MM-DD HH:mm:ss');
        item.createTime = moment(page.createTime).format('YYYY-MM-DD HH:mm:ss');
        item.content = JSON.stringify(content);
        return item;
    }
    // 获取数据
    static async getDbData() {
        // 获取数据
        try {
            const result = (await this.newDBService.getNewDBListAll()) || [];
            console.log(result, 'resultresultresultresult');
            // 没有离线数据就不同步数据
            if (result.rows && !result.rows.length) {
                return;
            }
            let data: any = [];
            for (let pageId of result) {
                const { value } = (await this.pageService.getFromDB(`base-data-${pageId}`)) || {
                    value: null,
                };
                if (value) {
                    const { page } = value;
                    if (!page.page_id) {
                        // JoyspaceController.setSyncStateMap(page.page_id, SyncStatusEunm.DATAERROR);
                        continue;
                    }
                    // 内容出错不再同步
                    if (!page.syncErrorInfo) {
                        // // 根据pageid获取文档内容
                        const content = await this.findPageContent(page.page_type, pageId);
                        // 组合参数
                        const item = this.getParams(page, content);
                        data.push(item);
                    }
                }
            }
            console.log(data, 'datadatadatadatadata');
            this.handleSyncApi(data);
        } catch (error) {
            console.error(error, '离线错误error');
        }
    }
    static onlineSyncData() {
        // 开启联网&断网监听
        window.removeEventListener('online', () => {});
        window.addEventListener('online', () => {
            this.getDbData();
        });
        window.removeEventListener('offline', () => {});
        window.addEventListener('offline', () => {
            this.getDbData();
        });
        this.getDbData();
    }
    static removeLineSyncData() {
        let _SyncDbData = SyncDbData;
        _SyncDbData.onlineSyncData = () => {};
        clearTimeout(this.clearSyncData || null);
    }
}

export default SyncDbData;
