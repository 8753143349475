/* eslint-disable no-duplicate-imports */
/* eslint-disable complexity */
import React, { useCallback, useMemo } from 'react';
import {
    filterAppInfo,
    filterContactInfo,
    filterGroupInfo,
    filterJoySpaceInfo,
    filterTaskInfo,
    SearchGlobalContext,
    filterScheduleInfo,
    onOpenUserCard,
    filterDeptsInfo,
} from '@/components/Modals/SearchGlobalModal/utils';
import throttle from 'lodash/throttle';
import ListEmptyPlaceholder from '@jd/focus-desktop-comps/lib/es/ListEmptyPlaceholder';
import { List, Empty, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ListItemClickFn } from '@/components/Modals/SearchGlobalModal/Tabs';
import DetailSearchBtn from '@/components/Modals/SearchGlobalModal/DetailSearchBtn';
import GroupTitle from '@/components/Modals/SearchGlobalModal/GroupTitle';
import SearchListItem from '@/components/Modals/SearchGlobalModal/ListItem';
import SearchAppItem from '../SearchAppItem';
import logger from '@/utils/logger';
const log = logger.getLogger('SearchGlobalModal');
import lodashTrim from 'lodash/trim';
import { SearchGlobalRes, TaskInfo } from '@/services/SearchService';

import TaskPaneItem from './TaskPane/TaskPaneItem';
// eslint-disable-next-line complexity
import SearchScheduleItem from '../SearchScheduleItem';
import noContent from '@/assets/img/search-nodata.png';
import styles from '@/components/styles';
import userCardIcon from '@/assets/img/user-card-icon.png';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import Loading from '@/components/Loading';
import { changeEgovUrl } from '@/utils/tools';
import ImService from '@/server/ImService';
import { ChatMessageType } from '@/types/chat';
import { SearchMessageHistoryListUI, SearchMessageItemUI } from '../SearchMessageHistoryUI';
import '../SearchMessageHistoryUI.less';
import { useHistory } from 'react-router-dom';
import bus from '@/utils/bus';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { getGroupInfo } from '@/api/egroup';
import { Avatar } from '@/baseComponents/Avatar';
import { getContactHighlight } from '@/utils/search';
import { connect } from 'dvajs';
import config, { ConfigEnum } from '@/config/config';
import ChatState from '@/types/chat/State';
import { Session } from '@/types/chat';
import { setTimeout } from 'timers';
import { analysisLog } from '@/utils/logAnalytics';
import { duccConfigI18 } from '@/utils/i18n';
interface Item {
    avatar: string;
    name: string;
    mid: number;
    sessionId: string;
    url: string;
    isGroup: boolean;
    children?: Item[];
    isSingle?: boolean;
    info: any;
}

let laetId: any = 0;
let currentMid: any = '';
function TabPaneAll({
    t,
    onClick,
    openCreateContactModal,
    setCreateModalInputValue,
    allSessionList,
}: // selectedSession,
{
    t: any;
    onClick?: ListItemClickFn;
    openCreateContactModal: Function;
    setCreateModalInputValue: Function;
    allSessionList: Session[];
    // selectedSession: Session;
}) {
    // eslint-disable-next-line prettier/prettier
    const imService = ImService.getInstance();
    // eslint-disable-next-line prettier/prettier
    const { onSearchGlobal, keyword, setTabIndex, activeKey, isChange } = React.useContext(
        SearchGlobalContext
    );
    const [result, setResult] = React.useState<SearchGlobalRes | null>(null);
    const [tmpMessageList, setTmpMessageList] = React.useState<any[]>([]); // 临时存储数据
    const [messageList, setMessageList] = React.useState<any[]>([]);
    // const [loading, setLoaing] = React.useState(true);
    const [loadMore, setLoadMore] = React.useState(false);
    const [isShowBackBtn, setShowBackBtn] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [magLoading, setMagLoading] = React.useState(false);
    const history = useHistory();
    const keywordRef = React.useRef<string>();
    keywordRef.current = keyword;
    const activeKeyRef = React.useRef<SearchType>();
    activeKeyRef.current = activeKey;
    React.useMemo(() => {
        setShowBackBtn(false);
        setTmpMessageList([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey, keywordRef.current]);

    const get = () => {
        console.log('3------');
        laetId++;
        return async (theLastId: any) => {
            const res = await onSearchGlobal({
                keyword: keywordRef.current as any,
                searchType: SearchType.All,
                pageNo: 1,
                pageSize: 3,
            });
            if (laetId === theLastId) {
                if (keywordRef.current) {
                    setResult(res);
                }
            }
        };
    };
    // 处理数据
    const handleData = (item: any) => {
        const isSingle =
            item.sessionId.indexOf(`:${process.env.REACT_APP_DD_APPID}:`) !== -1 ? true : false;
        let url = '';
        if (isSingle) {
            const { app, teamId, userId } = item.requestData;
            url = `/messages/s/${userId}?app=${app}&tid=${teamId}&top=${true}&sMid=${
                item.mid
            }&sText=${keywordRef.current}`;
        } else {
            url = `/messages/g/${item.sessionId}?sMid=${item.mid}&top=${true}&sText=${
                keywordRef.current
            }`;
        }
        // let avatar: any = ''; // 存储最新头像
        // // 只有单聊才更新自己的头像，这里逻辑主要是拿自己的最新头像，然后更新。为什么不用selectedSession，因为更新头像selectedSession字段没有值。
        // if (isSingle) {
        //     const session: any = allSessionList.find(
        //         (_item: any) => _item.id === item.sessionId
        //     ) || { info: {} };
        //     console.log(session, 'sessionsessionsession');
        //     avatar = session.info?.avatar || '';
        // }
        // console.log(avatar, 'avataravataravataravatar');
        return {
            avatar: (item.requestData && item.requestData.avatar) || '',
            name: (item.requestData && item.requestData.name) || '',
            desc: item.content,
            isGroup: false,
            mid: item.mid,
            sessionId: item.sessionId,
            url,
            isSingle,
            info: item.requestData,
        };
    };
    // 加载数据
    async function loadSingeExportMessages(searchKey = '', offset = 0) {
        // console.log(searchKey, 'searchKeysearchKeysearchKey');
        if (!searchKey) {
            setMessageList([]);
            return;
        }
        const msgs = await imService.search({
            sessionId: '',
            searchKey,
            searchType: ChatMessageType.TEXT,
            limit: 10,
            queryType: 2,
            incluedUser: false,
            offset,
            excludeSecret: true,
        });
        msgs.sort((a: any, b: any) => b.timestamp - a.timestamp);
        console.log(msgs, '消息处理之前');
        // 消息聚合
        const list: Item[] = [];
        try {
            for (let item of msgs) {
                if ((item as any).requestData && !(item as any).requestData.name) {
                    continue;
                }
                // 打开聚合代码 请注释掉 这两行代码
                const result: Item = handleData(item);
                list.push(result);

                // 聚合代码 start

                // const _item =
                //     list.find((_item: Item) => _item.sessionId === (item as any).sessionId) || null;
                // if (!_item) {
                //     const result: Item = handleData(item);
                //     result.children = [];
                //     list.push(result);
                //     continue;
                // }
                // _item.isGroup = true;
                // if (!_item.children?.length) {
                //     const res = { ..._item };
                //     res.isGroup = false;
                //     delete res.children;
                //     _item.children = [res];
                // }
                // _item.children?.push(handleData(item));

                // 聚合代码 end
            }
        } catch (error) {
            console.log(error, 'error');
        }
        list.splice(3);
        console.log(list, '消息处理之后');
        setMessageList(list);
    }
    const doSearch = async () => {
        if (activeKeyRef.current !== SearchType.All) {
            return;
        }
        if (!keywordRef.current) {
            return [];
        }
        try {
            setLoading(true);
            await get()(laetId);
            setLoading(false);
            // setMagLoading(true);
            // await loadSingeExportMessages(keywordRef.current);
            // setMagLoading(false);
        } catch (e) {
            setResult({});
            setLoading(false);
            setMagLoading(false);
        }
    };

    const doSearchThrottle = React.useRef(throttle(doSearch, 500)).current;

    React.useEffect(() => {
        if (!lodashTrim(keywordRef.current)) {
            setResult(null);
            setMessageList([]);
            return;
        }
        if (isChange) {
            return;
        }
        doSearchThrottle();
    }, [keyword, doSearchThrottle, activeKey, isChange]);

    const noContactData = () => {
        const ContactsItem = {
            name: getContactHighlight(keyword),
            desc: `${t('createButton.searchAddContact')}`,
            id: '1',
            avatar: <Avatar searchIcon={true} styleObj={{ color: '#ffffff' }} />,
        };
        // 小于十个字符且搜索不到，展示精确搜索入口
        return config[ConfigEnum.EXTERNAL_CONTACTS_ADD_CONTACTS] && keyword?.length <= 10 ? (
            <>
                <GroupTitle title={t(`search.tabs.${SearchType.Contacts}`)} />
                <SearchListItem
                    data={ContactsItem}
                    clstag="pageclick|keycount|xtbg_add_home_1637657110163|51"
                    onClick={() => {
                        openCreateContactModal({ visible: true });
                        setCreateModalInputValue(keywordRef.current);
                    }}
                    t={t}
                />
            </>
        ) : null;
    };

    const getExtraPart = useCallback((item: ListItemData) => {
        return (
            <div
                style={{
                    color: styles.color.primary2,
                    marginLeft: 15,
                    width: 34,
                    height: 30,
                    textAlign: 'center',
                }}
                onClick={(event: any) => {
                    const option = {
                        appId: item?.origin?.app,
                        userId: item?.origin?.userId,
                        teamId: item?.origin?.teamId,
                    };
                    onOpenUserCard(option);
                    event.stopPropagation();
                }}
            >
                <img src={changeEgovUrl(userCardIcon)} alt="" style={{ width: 20 }} />
            </div>
        );
    }, []);

    if (!result) {
        if (!messageList.length) {
            return <ListEmptyPlaceholder placeholder={t('search.emptyPlAll')} />;
        }
        return;
    }

    const {
        contacts = [],
        groups = [],
        apps = [],
        pages = [],
        schedules = [],
        works = [],
        depts = [],
        hasMoreDepts,
        hasMoreGroups,
        hasMoreContacts,
        hasMoreApps,
    } = result as any;

    const DeptsData = depts?.data || [];

    const worksEmpty =
        !works ||
        works
            ?.map((work: any) => work.data)
            .every((item: any) => {
                return !item || item?.length === 0;
            });
    const isEmpty =
        [contacts, groups, apps, pages, schedules, DeptsData, messageList].every((item) => {
            return !item || item?.length === 0;
        }) && worksEmpty;

    if (isEmpty) {
        // 满足精确入口展示 否则 展示暂无数据
        return noContactData() || <Empty image={noContent} description={t('search.nodata')} />;
        // return <Empty image={noContent} description={t('search.nodata')} />;
    }

    const clstag = (searchType: String, isMoreBtn?: Boolean) => {
        const key = 'xtbg_home_search_1641454991771';
        switch (searchType) {
            case '1': // 联系人
                return isMoreBtn ? analysisLog(key, '18') : analysisLog(key, '10');
            case '2': // 组
                return isMoreBtn ? analysisLog(key, '20') : analysisLog(key, '12');
            case '3': // 工具
                return isMoreBtn ? analysisLog(key, '24') : analysisLog(key, '16');
            case '4': // 云文档
                return isMoreBtn ? analysisLog(key, '21') : analysisLog(key, '13');
            case '5': // 任务
                return isMoreBtn ? analysisLog(key, '22') : analysisLog(key, '14');
            case '6': // 日程
                return isMoreBtn ? analysisLog(key, '23') : analysisLog(key, '15');
            case '7': // 部门
                return isMoreBtn ? analysisLog(key, '19') : analysisLog(key, '11');
            case '-1': // 聊天记录
                return isMoreBtn ? analysisLog(key, '25') : analysisLog(key, '17');
        }
    };
    // console.log('contactsData2', contacts);
    const contactsData = filterContactInfo(contacts || [], allSessionList, keywordRef.current);
    console.log('contactsData', contactsData);

    let contactComp = contactsData.length ? (
        <div>
            <GroupTitle title={t(`search.tabs.${SearchType.Contacts}`)} />
            <List
                loading={
                    loading && {
                        indicator: <LoadingOutlined size={16} />,
                    }
                }
                dataSource={contactsData.slice(0, 3)}
                renderItem={(item) => (
                    <SearchListItem
                        data={item}
                        onClick={() => {
                            if (onClick) {
                                clstag(SearchType.Contacts);
                                onClick(SearchType.Contacts, item);
                            }
                        }}
                        t={t}
                        extra={getExtraPart(item)}
                    />
                )}
            />
            {hasMoreContacts ? (
                <DetailSearchBtn
                    text={t('search.moreContacts')}
                    onClick={() => {
                        clstag(SearchType.Contacts, true);
                        setTabIndex(SearchType.Contacts);
                    }}
                />
            ) : null}
        </div>
    ) : (
        noContactData()
    );

    const DeptsInfoData = filterDeptsInfo(DeptsData || []);
    let DeptsComp = DeptsInfoData.length ? (
        <div>
            <GroupTitle title={t(`search.tabs.${SearchType.Department}`)} />
            <List
                loading={
                    loading && {
                        indicator: <LoadingOutlined size={16} />,
                    }
                }
                dataSource={DeptsInfoData}
                renderItem={(item) => (
                    <SearchListItem
                        data={item}
                        onClick={() => {
                            clstag(SearchType.Department);
                            if (onClick) {
                                onClick(SearchType.Department, item);
                            }
                        }}
                        t={t}
                    />
                )}
            />
            {hasMoreDepts ? (
                <DetailSearchBtn
                    text={t('search.moreDepartment')}
                    onClick={() => {
                        clstag(SearchType.Department, true);
                        setTabIndex(SearchType.Department);
                    }}
                />
            ) : null}
        </div>
    ) : null;
    //     DeptsInfoData.length === 0 && DeptsCode === '-1' ? (
    //     <div>
    //         <GroupTitle title={t(`search.tabs.${SearchType.Department}`)} />
    //         <p style={{ padding: '12px 24px' }}>{DeptsMsg}</p>
    //     </div>
    // ) : null;

    const groupsData = groups ? filterGroupInfo(groups) : [];
    let groupsComp = groupsData.length ? (
        <div>
            <GroupTitle title={t(`search.tabs.${SearchType.Group}`)} />
            <List
                loading={
                    loading && {
                        indicator: <LoadingOutlined size={16} />,
                    }
                }
                dataSource={groupsData.slice(0, 3)}
                renderItem={(item) => (
                    <SearchListItem
                        style={{
                            paddingLeft: 24,
                        }}
                        data={item}
                        onClick={() => {
                            clstag(SearchType.Group);
                            if (onClick) {
                                onClick(SearchType.Group, item);
                            }
                        }}
                        t={t}
                    />
                )}
            />
            {hasMoreGroups ? (
                <DetailSearchBtn
                    text={t('search.moreGroups')}
                    onClick={() => {
                        clstag(SearchType.Group, true);
                        setTabIndex(SearchType.Group);
                    }}
                />
            ) : null}
        </div>
    ) : null;
    const appsData = apps ? filterAppInfo(apps) : [];
    let appsComp = appsData.length ? (
        <div>
            <GroupTitle title={t(`search.tabs.${SearchType.App}`)} />
            <List
                loading={
                    loading && {
                        indicator: <LoadingOutlined size={16} />,
                    }
                }
                dataSource={appsData.slice(0, 3)}
                renderItem={(item) => (
                    <SearchAppItem
                        style={{
                            paddingLeft: 24,
                        }}
                        data={item}
                        onClick={() => {
                            clstag(SearchType.App);
                            if (onClick) {
                                onClick(SearchType.App, item);
                            }
                        }}
                        t={t}
                    />
                )}
            />
            {hasMoreApps ? (
                <DetailSearchBtn
                    text={t('search.moreApps')}
                    onClick={() => {
                        clstag(SearchType.App, true);
                        setTabIndex(SearchType.App);
                    }}
                />
            ) : null}
        </div>
    ) : null;
    const pagesData = pages ? filterJoySpaceInfo(pages) : [];
    let pagesComp = pagesData.length ? (
        <div>
            <GroupTitle
                title={t(`search.tabs.${SearchType.JoySpace}`, {
                    joyspace: duccConfigI18.joyspace,
                })}
            />
            <List
                loading={
                    loading && {
                        indicator: <LoadingOutlined size={16} />,
                    }
                }
                dataSource={pagesData.slice(0, 3)}
                renderItem={(item) => (
                    <SearchListItem
                        style={{
                            paddingLeft: 24,
                        }}
                        data={item}
                        onClick={() => {
                            clstag(SearchType.JoySpace);
                            if (onClick) {
                                onClick(SearchType.JoySpace, item);
                            }
                        }}
                        t={t}
                    />
                )}
            />
            {pagesData.length >= 3 ? (
                <DetailSearchBtn
                    text={t('search.moreJoySpaces')}
                    onClick={() => {
                        clstag(SearchType.JoySpace, true);
                        setTabIndex(SearchType.JoySpace);
                    }}
                />
            ) : null}
        </div>
    ) : null;

    const workData = works ? filterTaskInfo(works) : [];

    let workComp = function () {
        return (
            <div>
                {workData.map(
                    (
                        { displayName, data }: { data: TaskInfo[]; displayName: string },
                        idx: number
                    ) => {
                        if (!data || data.length === 0) return null;
                        return (
                            <div key={idx}>
                                <GroupTitle title={`任务-${displayName}`} />
                                <List
                                    loading={
                                        loading && {
                                            indicator: <LoadingOutlined size={16} />,
                                        }
                                    }
                                    dataSource={data}
                                    renderItem={(item: any) => (
                                        <TaskPaneItem
                                            onClick={() => {
                                                clstag(SearchType.Task);
                                                if (onClick) {
                                                    onClick(SearchType.Task, item);
                                                }
                                            }}
                                            info={item}
                                        />
                                    )}
                                />
                            </div>
                        );
                    }
                )}
                {!worksEmpty ? (
                    <DetailSearchBtn
                        text={t('search.moreTask')}
                        onClick={() => {
                            clstag(SearchType.Task, true);
                            setTabIndex(SearchType.Task);
                        }}
                    />
                ) : null}
            </div>
        );
    };

    const schedulesData = schedules ? filterScheduleInfo(schedules) : [];
    let schedulesComp = schedulesData.length ? (
        <div>
            <GroupTitle title={t(`search.tabs.${SearchType.Schedule}`)} />
            <List
                loading={
                    loading && {
                        indicator: <LoadingOutlined size={16} />,
                    }
                }
                dataSource={schedulesData.slice(0, 3)}
                renderItem={(item) => (
                    <SearchScheduleItem
                        style={{
                            paddingLeft: 24,
                        }}
                        data={item}
                        onClick={() => {
                            clstag(SearchType.Schedule);
                            if (onClick) {
                                onClick(SearchType.Schedule, item);
                            }
                        }}
                        t={t}
                    />
                )}
            />
            {schedulesData.length >= 3 ? (
                <DetailSearchBtn
                    text={t('search.moreSchedules')}
                    onClick={() => {
                        clstag(SearchType.Schedule, true);
                        setTabIndex(SearchType.Schedule);
                    }}
                />
            ) : null}
        </div>
    ) : null;
    // 聊天记录
    // const schedulesData = schedules ? filterScheduleInfo(schedules) : [];
    // console.log(messageList, 'messageListmessageListmessageListmessageList');
    const handleUrl = (item: any) => {
        // const sMid = item.url.split('sMid=')[1].split('&')[0];
        // if (currentMid === sMid) {
        //     const index = item.url.indexOf('sText=');
        //     let url = item.url.substring(0, index - 1);
        //     history.push(`${url}`);
        // }
        // setTimeout(() => {
        //     history.push(item.url);
        // }, 700);
        // currentMid = sMid;
        history.push(item.url);
        bus.emit('onHideGlobalSearch');
    };
    let massageHistoryComp =
        !magLoading && messageList.length > 0 ? (
            <div className="search-message-history-list">
                <GroupTitle title={t(`search.tabs.${SearchType.MessageHistory}`)} />
                <List
                    loading={
                        magLoading && {
                            indicator: <LoadingOutlined size={16} />,
                        }
                    }
                    dataSource={messageList}
                    renderItem={(item) => (
                        <SearchMessageItemUI
                            keyWord={keywordRef.current}
                            {...item}
                            onChangeDate={(list) => {
                                clstag(SearchType.MessageHistory);
                                setShowBackBtn(true);
                                // setLoadMore(false);
                                setTmpMessageList([...messageList]);
                                setMessageList(list);
                            }}
                            onOpenMessage={async (item) => {
                                clstag(SearchType.MessageHistory);
                                if (!(item as any).isSingle) {
                                    let group: any = imService.getGroup(item.sessionId);
                                    if (!group?.name || !group?.flag) {
                                        try {
                                            const res = await getGroupInfo({
                                                groupIds: [{ groupId: item.sessionId }],
                                            });
                                            if (res.groups[0].flag !== 3) {
                                                message.error('该组已解散！');
                                                return;
                                            } else {
                                                setTimeout(() => {
                                                    handleUrl(item);
                                                    // history.push(item.url);
                                                    // bus.emit('onHideGlobalSearch');
                                                }, 400);
                                            }
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }
                                } else {
                                    try {
                                        const res = await imService.getEmployeeDetail({
                                            appId: (item as any).info.app,
                                            userId: (item as any).info.userId,
                                            teamId: (item as any).info.teamId,
                                        });
                                        setTimeout(() => {
                                            handleUrl(item);
                                            bus.emit('onHideGlobalSearch');
                                        }, 400);
                                    } catch (error) {
                                        message.error('该用户已被移除');
                                    }
                                }
                            }}
                        />
                        // <SearchScheduleItem
                        //     style={{
                        //         paddingLeft: 24,
                        //     }}
                        //     data={item}
                        //     onClick={() => {
                        //         if (onClick) {
                        //             onClick(SearchType.Schedule, item);
                        //         }
                        //     }}
                        //     t={t}
                        // />
                    )}
                />
                {messageList.length >= 3 ? (
                    <DetailSearchBtn
                        text={t('search.moreMassage')}
                        onClick={() => {
                            clstag(SearchType.MessageHistory, true);
                            setTabIndex(SearchType.MessageHistory);
                        }}
                    />
                ) : null}
            </div>
        ) : magLoading ? (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '200px',
                }}
            >
                <Loading />
            </div>
        ) : null;
    if (loading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '200px',
                }}
            >
                <Loading />
            </div>
        );
    }
    return (
        <div>
            {!isShowBackBtn && (
                <div>
                    {contactComp}
                    {DeptsComp}
                    {groupsComp}
                    {appsComp}
                    {pagesComp}
                    {workComp()}
                    {schedulesComp}
                    {massageHistoryComp}
                </div>
            )}
            {isShowBackBtn && (
                <SearchMessageHistoryListUI
                    keyWord={keywordRef.current}
                    loading={false}
                    list={messageList}
                    loadMore={false}
                    t={t}
                    isShowBackBtn={isShowBackBtn}
                    backFn={() => {
                        setShowBackBtn(false);
                        // setLoadMore(tmpMessageList.length === 3 ? true : false);
                        setMessageList(tmpMessageList);
                        setTmpMessageList([]);
                    }}
                    onOpenMessage={async (item) => {
                        // updateMessages?.({ messages: [] });
                        if (!(item as any).isSingle) {
                            let group: any = imService.getGroup(item.sessionId);
                            if (!group?.name || !group?.flag) {
                                try {
                                    const res = await getGroupInfo({
                                        groupIds: [{ groupId: item.sessionId }],
                                    });
                                    if (res.groups[0].flag !== 3) {
                                        message.error('该组已解散！');
                                        return;
                                    } else {
                                        setTimeout(() => {
                                            handleUrl(item);
                                            bus.emit('onHideGlobalSearch');
                                        }, 400);
                                    }
                                } catch (error) {
                                    console.error(error);
                                }
                            }
                        } else {
                            try {
                                const res = await imService.getEmployeeDetail({
                                    appId: (item as any).info.app,
                                    userId: (item as any).info.userId,
                                    teamId: (item as any).info.teamId,
                                });
                                setTimeout(() => {
                                    handleUrl(item);
                                    bus.emit('onHideGlobalSearch');
                                }, 400);
                            } catch (error) {
                                message.error('该用户已被移除');
                            }
                        }
                    }}
                    // loadmoreFnts={() => {}}
                />
            )}
        </div>
    );
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    // console.log(chat.selectedSession, 'chat.selectedSessionchat.selectedSession');
    return { allSessionList: chat.allSessionList };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateContactModal(data: any) {
            dispatch({ type: 'ebook/openCreateContactModal', payload: data });
        },
        setCreateModalInputValue(data: any) {
            dispatch({ type: 'ebook/setCreateModalInputValue', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabPaneAll);
