// 设置展开栏底部按钮

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'dva';
import './index.less';
import {
    ChatMessage,
    ChatMessageType,
    Employee,
    FileChatMessage,
    JoySpaceDocumentMessage,
    Session,
    TextChatMessageExt,
} from '@/types/chat';
import { getReplyMessageDesc, isSameClient } from '@/components/chat/utils/message';
import log from '@/utils/logger';
import { MessageSendType } from '@/types/chat/enum';
import { downLoadFile, getFileUrl, getMessageFileName } from '@/utils/chat/index';
import { Modal, Popover, message as toast } from 'antd';
import { FocusSDK, isFocusEnv } from '@/utils';
import ImService from '@/server/ImService';
import {
    getChatMessageNode,
    getHeadPortrait,
    getShowTime,
    TimeTransform,
    getTextMessageContent,
    getVideoModal,
    getPreviewImageModal,
    openShareCommonCard,
    openShareUserCard,
    viewUploadFile,
    downloadReplyFile,
} from '../../utils/commonMessageModal';
import { handleAddFileDownloadRecord } from '@/utils/chat/commonMessageModal';
import { changeEgovUrl } from '@/utils/tools';
import bus from '@/utils/bus';

const logger = log.getLogger('comp:ReplyMessage');

interface ReplyMessageProps {
    message: ChatMessage;
    sendType: MessageSendType;
    currentEmployee: Employee;
    selectedSession: Session;
    openNoticeDetails: (data: { isvisibles: boolean; notificationId: any }) => void;
}

type IProps = Readonly<ReplyMessageProps>;
let count = 0;
let flag = false;
let isClickModal = true;
function ReplyMessage({
    message,
    sendType,
    currentEmployee,
    selectedSession,
    openNoticeDetails,
}: IProps) {
    const replyMsg =
        message.replyMsgs && message.replyMsgs.length > 0
            ? (message.replyMsgs[0] as ChatMessage)
            : undefined;
    const { t } = useTranslation('chat');

    const [visible, setVisible] = useState(false); // 文本气泡
    const [popoverContent, setPopoverContent] = useState<any>(''); // 文本内容
    const [isModalVisible, setIsModalVisible] = useState(false); // 转发
    const [timeSection, setTimeSection] = useState(''); // 转发日期
    const [modalMessages, setModalMessages] = useState([] as any[]); // 转发消息体

    const handleHide = () => {
        setVisible(false);
    };

    useEffect(() => {
        const scrollBox = document.getElementById('message-box-container-scroll');
        scrollBox?.addEventListener('scroll', handleHide);
        return () => {
            scrollBox?.removeEventListener('scroll', handleHide);
        };
    }, []);

    function getReplyTextContent() {
        const result = getTextMessageContent(
            replyMsg as TextChatMessageExt,
            currentEmployee
        ).trim();
        setPopoverContent(result);
        setVisible(true);
    }

    function onVisibleChange(visible: boolean) {
        if (replyMsg?.type !== ChatMessageType.TEXT || replyMsg?.revoke) {
            return;
        }
        setVisible(visible);
    }

    const getReplyImageContent = () => {
        getPreviewImageModal({ message: replyMsg });
    };

    const getReplyVideoContent = () => {
        if (isClickModal) {
            isClickModal = false;
            getVideoModal(replyMsg);
        }
        setTimeout(() => {
            isClickModal = true;
        }, 2000);
    };

    const downLocalFile = (fileMessage: FileChatMessage) => {
        if (!isFocusEnv()) {
            // 网页版下载之后查看
            const name = getMessageFileName(fileMessage);
            const url = getFileUrl(fileMessage.url);
            downLoadFile(name, url, '', {
                onFinish: () => {
                    if (selectedSession.isGroup) {
                        handleAddFileDownloadRecord(fileMessage, currentEmployee);
                    }
                },
            });
            return;
        }
        const downLocalPath = fileMessage.downLocalPath;
        if (!downLocalPath) {
            downloadReplyFile({ fileMessage, t, currentEmployee });
        }
        if (isFocusEnv() && downLocalPath) {
            FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                if (exists) {
                    FocusSDK.openItem(downLocalPath);
                } else {
                    downloadReplyFile({ fileMessage, t, currentEmployee });
                }
            });
        }
    };

    const getReplyFileContent = async () => {
        const fileMessage = replyMsg as FileChatMessage;
        // console.log(sendType, 'sendType');
        if (sendType === MessageSendType.OWNER || sendType === MessageSendType.SELF) {
            if (
                !isSameClient(fileMessage) ||
                (isFocusEnv() && !fileMessage.downLocalPath && !fileMessage.upLocalPath)
            ) {
                console.log('down');
                downLocalFile(fileMessage);
            } else {
                console.log('view');
                viewUploadFile(fileMessage, t, '', currentEmployee);
            }
        } else {
            downLocalFile(fileMessage);
        }
    };

    const getHead = () => {
        if (isModalVisible) {
            const forwardMessage = replyMsg as any;
            let realName = forwardMessage.nickName || forwardMessage.realname;
            if (forwardMessage.data[0].title.mergeKind === 'group') {
                let fromGroupName = forwardMessage.data[0].title.fromGroupName;
                if (fromGroupName) {
                    return (
                        <div className="head">
                            {t('more_communicate_record', { fromGroupName })}
                        </div>
                    );
                } else {
                    return <div className="head">{t('group_communicate_record')}</div>;
                }
            }
            if (realName !== forwardMessage.data[0].title.toRealname) {
                return (
                    <div className="head">
                        {t('double_communicate_record', {
                            realName,
                            toRealname: forwardMessage.data[0].title.toRealname,
                        })}
                    </div>
                );
            } else {
                return <div className="head">{t('single_communicate_record', { realName })}</div>;
            }
        }
    };

    const forwardMessage = async (message: any) => {
        if (flag) {
            return;
        }
        flag = true;
        const imService = ImService.getInstance();
        const result: any = await imService.getMergeForwardBody(
            changeEgovUrl(message.data[0].content),
            message.id
        );
        if (result?.messages) {
            setModalMessages(result.messages);
            let timeSection = '';
            if (result.messages.length === 1) {
                timeSection = TimeTransform(result.messages[0].timestamp);
            } else {
                let start = TimeTransform(result.messages[0].timestamp);
                let end = TimeTransform(result.messages[result.messages.length - 1].timestamp);
                if (start !== end) {
                    timeSection = start + ' ~ ' + end;
                } else {
                    timeSection = start;
                }
            }
            setTimeSection(timeSection);
            setIsModalVisible(true);
            count++;
        } else {
            setIsModalVisible(false);
        }
        setTimeout(() => {
            flag = false;
        }, 500);
    };

    const getReplyCardContent = () => {
        const shareMessage = replyMsg as JoySpaceDocumentMessage;
        const sharelink =
            shareMessage.data && shareMessage.data[0]
                ? shareMessage.data[0].sharelink
                : { category: '', url: '' };
        const { template } = shareMessage;
        if (template.nativeId === 'merge_forward') {
            forwardMessage(shareMessage);
            return;
        }
        if (template.nativeId === 'share_link') {
            if (sharelink?.category === 'jd_doc') {
                if (sharelink?.url && sharelink.url.indexOf('http') > -1) {
                    window.open(sharelink.url);
                }
                return;
            } else {
                openShareCommonCard(shareMessage);
                return;
            }
        } else if (template.nativeId === 'share_card') {
            openShareUserCard(shareMessage);
            return;
        } else {
            console.log('不支持');
            return;
        }
    };

    const getReplyJoyspaceFileContent = () => {
        const joyspaceFileMessage = replyMsg as JoySpaceDocumentMessage;
        const { data } = joyspaceFileMessage;
        let webUrl = '';
        // @ts-ignore
        // 兼容移动端的神奇代码
        if (data?.[0]?.joySpaceLinkDetail) {
            // @ts-ignore
            const joySpaceLinkDetail = data[0].joySpaceLinkDetail || {};
            webUrl = joySpaceLinkDetail.webUrl;
        }
        webUrl = joyspaceFileMessage.webUrl;
        window.open(webUrl);
    };
    // 组通知引用
    const getReplyGroupNotice = () => {
        const GroupNoticeMessage: any = replyMsg as ChatMessage;
        if (!GroupNoticeMessage) return;
        openNoticeDetails({
            isvisibles: true,
            notificationId: GroupNoticeMessage.notificationId,
        });
    };

    function viewReplyMessage(e: any) {
        // 引用内容撤销不可点击
        if (replyMsg?.revoke || isModalVisible) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        logger.debug('replyMsgContent =====>>', replyMsg);
        switch (replyMsg?.type) {
            case ChatMessageType.TEXT:
                getReplyTextContent();
                break;
            case ChatMessageType.IMAGE:
                getReplyImageContent();
                break;
            case ChatMessageType.VIDEO:
                getReplyVideoContent();
                break;
            case ChatMessageType.VOICE:
                // 音频内容不可被引用
                console.log('voice');
                break;
            case ChatMessageType.FILE:
                getReplyFileContent();
                break;
            case ChatMessageType.JOYSPACEFILE:
                getReplyJoyspaceFileContent();
                break;
            case ChatMessageType.TEMPLATE2:
                getReplyCardContent();
                break;
            case ChatMessageType.GROUPNOTICE:
                getReplyGroupNotice();
                break;
            default:
                console.log('default chatmessage');
                break;
        }
    }
    const visibleChange = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        bus.emit('message-item-chatitem', false);
    };

    useEffect(() => {
        if (isModalVisible) {
            document.addEventListener('contextmenu', visibleChange);
            return () => {
                document.removeEventListener('contextmenu', visibleChange);
            };
        }
    }, [isModalVisible]);
    if (replyMsg) {
        return (
            <Popover
                visible={visible}
                destroyTooltipOnHide
                trigger="click"
                overlayClassName="reply-message-text-popover"
                placement={
                    sendType === MessageSendType.SELF || sendType === MessageSendType.OWNER
                        ? 'left'
                        : 'right'
                }
                // placement="left"
                onVisibleChange={onVisibleChange}
                content={
                    <p
                        className="reply-popover-content"
                        dangerouslySetInnerHTML={{ __html: popoverContent }}
                    />
                }
            >
                <div className="reply-message" onClick={viewReplyMessage}>
                    <div className="reply-message-line" />
                    <div className="reply-message-content">
                        {replyMsg.revoke ? (
                            <span className="revoke-tips">{t('revoke content')}</span>
                        ) : (
                            <>
                                <span className="revoke-tips">{`${t('quote')} ${
                                    replyMsg.sender.nickName || replyMsg.sender.name
                                }：`}</span>
                                {/* {getReplyMessageDesc(replyMsg)} */}
                                <p
                                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    className="reply-message-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: getReplyMessageDesc(replyMsg),
                                    }}
                                />
                            </>
                        )}
                    </div>
                    <Modal
                        title={getHead()}
                        visible={isModalVisible}
                        destroyOnClose={true}
                        footer={null}
                        width={550}
                        zIndex={100}
                        centered
                        className="forwardModal"
                        onCancel={(e: any) => {
                            e.stopPropagation();
                            count = 0;
                            flag = false;
                            setTimeSection('');
                            setModalMessages([]);
                            setIsModalVisible(false);
                        }}
                    >
                        <div className="title">{timeSection}</div>
                        <div className="forward-lists">
                            {modalMessages.map((message: any, index: number) => {
                                return (
                                    <div key={index} className="wrapper">
                                        <div className="forward-list">
                                            <div className="left">{getHeadPortrait(message)}</div>
                                            <div className="right">
                                                <div className="name">
                                                    <div>
                                                        {message.senderName || message.realname}
                                                    </div>
                                                    <div>{getShowTime(message)}</div>
                                                </div>

                                                <div className="message-box">
                                                    <div className="content-row">
                                                        {getChatMessageNode(
                                                            modalMessages,
                                                            message,
                                                            count,
                                                            true
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line" />
                                    </div>
                                );
                            })}
                        </div>
                    </Modal>
                </div>
            </Popover>
        );
    }
    return null;
}

function mapStateToProps({ chat }: any) {
    const { currentEmployee, selectedSession } = chat;

    return { currentEmployee, selectedSession };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openNoticeDetails(data: { isvisibles: boolean; notificationId: any }) {
            dispatch({ type: 'chat/openNoticeDetails', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyMessage);
