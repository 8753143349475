/* eslint-disable complexity */
import React, { useCallback, useMemo, useState } from 'react';
import './index.less';
import { WorkUserInfo } from '@/types/work';
import { Button, Popover, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import prompt from '@/baseComponents/ModalComponent/prompt';
import dayjs from 'dayjs';
import { UserListEmptyImg } from '@/assets/img';
import { isFocusEnv, FocusSDK } from '@/utils';
import { UserCardType } from '@/types/UserCard';
import { UserCardPayload } from '@/models';
import { connect } from 'dva';
import Bus from '@/utils/bus';
import { processStatusOptions } from '@/types/newWork';
import AdvanceCom from '@/components/Work/TaskDetailDraw/components/AdvanceCom';
import IconFont, { IconArrowLeft } from '@/components/icon';
import { changeEgovUrl } from '@/utils/tools';
import user from '@/models/user';
import { EsuperviseSourceType, ICreateSuperviseData } from '@/types/supervise';
const { TabPane } = Tabs;

function UserStatusListPopover({
    list,
    onRemove,
    children,
    disable,
    toggleUserCardModal,
    openCreateSuperviseModal,
    userCardVisible,
    label,
    userPopover,
    userData,
    taskDetail,
    mode,
}: {
    onRemove: (user: any[]) => void;
    children: any;
    disable?: boolean;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => void;
    userCardVisible: boolean;
    list: WorkUserInfo[];
    label?: string; // 区分日程参与人
    userPopover?: string;
    userData: any;
    taskDetail: any;
    mode?: string;
}) {
    const { t } = useTranslation('work');

    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [advanceVisible, setAdvanceVisible] = useState(false);
    const [advanceUser, setAdvanceUser] = useState<WorkUserInfo>();
    const currentIsEdit = useCallback(
        (item: any) => {
            if (
                userData?.team?.teamId === item?.teamId &&
                userData?.team?.ddAppId === item?.app &&
                userData?.userId === item?.userId
            ) {
                return true;
            }
        },
        [userData]
    );
    function visibleChange(visible: boolean) {
        if (modalVisible) {
            return;
        }
        if (userCardVisible) {
            return;
        }
        setVisible(visible);
        setTimeout(() => {
            setAdvanceVisible(false);
        }, 500);
    }
    const [t_common] = useTranslation('common');
    function handleRemoveMember(user: WorkUserInfo) {
        setModalVisible(true);
        // 删除提醒
        prompt({
            title: t('remove participant'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: () => {
                setModalVisible(false);
                onRemove([
                    {
                        userId: user.userId,
                        teamId: user.teamId,
                        app: user.app,
                        taskUserRole: label ? undefined : user.userRole,
                    },
                ]);
            },
            onCancel: () => {
                setModalVisible(false);
            },
            content: label
                ? t('remove schedule participant desc').replace('%s', user.realName)
                : t('remove executor desc').replace('%s', user.realName),
            okText: t_common('button.ok'),
            cancelText: t_common('button.cancel'),
        });
    }

    function handleAdvanceRecord(user: WorkUserInfo) {
        setAdvanceVisible(true);
        setAdvanceUser(user as any);
    }

    function openUserCard(item: WorkUserInfo) {
        const { userId, teamId, app: appId } = item;
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId,
            appId,
            teamId,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId });
        // }
    }
    const getProcessStatusTarget = (status: number) => {
        return processStatusOptions.find((item) => item.status === status) || null;
    };
    const getContent = () => {
        const getList = (list: any[]) => {
            return list.map((item) => {
                const { labelInfoList } = item;
                let isExternal = false;
                let exTagColor = '#000000';
                let dept = '';
                let proTitle = '';

                if (labelInfoList?.[0]) {
                    exTagColor = '#' + labelInfoList[0].color;
                    if (labelInfoList[0].isShow === '1') {
                        isExternal = true;
                    }
                }
                if (labelInfoList?.[1]) {
                    dept = labelInfoList[1].name;
                }
                if (labelInfoList?.[2]) {
                    proTitle = labelInfoList[2].name;
                }
                return (
                    <div
                        className="user-status-list-item"
                        key={item.userId + item.teamId + item.app}
                    >
                        {item.headImg ? (
                            <img
                                src={changeEgovUrl(item.headImg || '')}
                                className="avatar"
                                onClick={() => openUserCard(item)}
                            />
                        ) : (
                            <div className="avatar" onClick={() => openUserCard(item)}>
                                {(item.realName || '').slice(0, 1)}
                            </div>
                        )}
                        {!userPopover ? (
                            <div className="container">
                                <div className="title-content">
                                    <span className="title">
                                        <div className="name">{item.realName}</div>
                                        {isExternal && (
                                            <div
                                                className="external-tag"
                                                style={{
                                                    borderColor: exTagColor,
                                                    color: exTagColor,
                                                }}
                                            >
                                                {t('external')}
                                            </div>
                                        )}
                                    </span>
                                    <span style={{ flex: 1 }} />
                                    {!disable && (
                                        <span
                                            className="action-remove"
                                            onClick={() => handleRemoveMember(item)}
                                        >
                                            {t('remove')}
                                        </span>
                                    )}
                                </div>
                                <div className="desc">
                                    {/* <span className="desc-org">{item.deptInfo?.fullName || ''}</span> */}
                                    {dept && proTitle && (
                                        <span className="desc-org">{`${dept}-${proTitle}`}</span>
                                    )}
                                    {!(dept && proTitle) && (
                                        <span className="desc-org">{item.titleName || ''}</span>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="task-container">
                                <div className="title-content">
                                    <span className="title">{item.realName}</span>
                                    <span className="duty">{item.titleName}</span>
                                </div>
                                <div className="status-content">
                                    {item.processStatus && (
                                        <span
                                            className={`process-status process-status-${item.processStatus}`}
                                        >
                                            {currentIsEdit(item)
                                                ? getProcessStatusTarget(item.processStatus)
                                                      ?.currentLabel || ''
                                                : getProcessStatusTarget(item.processStatus)
                                                      ?.label || ''}
                                        </span>
                                    )}

                                    {!disable && !mode && (
                                        <span
                                            className="action-remove"
                                            onClick={() => handleAdvanceRecord(item)}
                                        >
                                            {t('advance-record')}
                                        </span>
                                    )}
                                    {!disable && item.isRemove && (
                                        <span
                                            className="action-remove"
                                            onClick={() => handleRemoveMember(item)}
                                        >
                                            {t('remove')}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            });
        };
        const getEmpty = () => (
            <div className="user-status-list-empty">
                <img src={changeEgovUrl(UserListEmptyImg)} />
                <div className="label">{label ? t('no participant') : t('no executors')}</div>
            </div>
        );
        return (
            <div className="user-status-list">{list.length > 0 ? getList(list) : getEmpty()}</div>
        );
    };
    const getAdvanceContent = () => {
        return (
            <div className="user-status-advance-record">
                <div className="user-status-advance-record-title">
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setAdvanceVisible(false);
                        }}
                    >
                        {IconArrowLeft}
                    </span>
                    {advanceUser?.realName}的{t('advance-record')}
                </div>
                <div className="advance-record-wrapper">
                    {advanceUser?.processStatus !== 4 ? (
                        <AdvanceCom advanceUser={advanceUser} />
                    ) : (
                        <div style={{ lineHeight: '200px', textAlign: 'center' }}>
                            {advanceUser?.realName}
                            {getProcessStatusTarget(advanceUser?.processStatus)?.label || ''}
                        </div>
                    )}
                </div>
                <Button
                    type="primary"
                    block
                    onClick={() => {
                        const userList: any[] = [];
                        userList.push({
                            userId: advanceUser?.userId,
                            teamId: advanceUser?.teamId,
                            appId: advanceUser?.app,
                            headImg: advanceUser?.headImg,
                            realName: advanceUser?.realName,
                        });

                        openCreateSuperviseModal({
                            visible: true,
                            createData: {
                                id: taskDetail?.taskId,
                                title: taskDetail?.title,
                                userList,
                                from: EsuperviseSourceType.WORK,
                            },
                        });
                    }}
                >
                    {t('urge immediately')}
                </Button>
            </div>
        );
    };
    return (
        <Popover
            overlayClassName="calendar-user-status-popover"
            trigger="click"
            placement="bottomRight"
            content={advanceVisible ? getAdvanceContent : getContent}
            visible={visible}
            onVisibleChange={visibleChange}
            getPopupContainer={(trigger) => {
                return trigger as HTMLElement;
            }}
        >
            {children}
        </Popover>
    );
}
function mapStateToProps({ app }: any) {
    return {
        userCardVisible: app.modals.userCard.visible,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        openCreateSuperviseModal: (opt: { visible: boolean; createData: ICreateSuperviseData }) => {
            dispatch({
                type: 'supervise/setCreateModalVisible',
                payload: opt,
            });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserStatusListPopover);
