import React, { useContext } from 'react';
import './index.less';
import { ProjectRole } from '../../../types';
import Context from '../../../context';

export default function Role(props: { role: ProjectRole }) {
    const { role } = props;
    const { t } = useContext(Context);
    return role === ProjectRole.OWNER ? (
        <span className="pm-mm-list-role1">{t('basic_setting.owner')}</span>
    ) : role === ProjectRole.ADMIN ? (
        <span className="pm-mm-list-role2">{t('member.administrator')}</span>
    ) : null;
}
