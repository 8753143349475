/**
 * 视频消息组件
 * 使用方：用户对话，群组对话
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { ReactNode } from 'react';
import { MessageSendType } from '@/types/chat/enum';
import { VideoChatMessage } from '@/types/chat';
import './index.less';
import {
    dealImageWidthHeight,
    getFileUrl,
    getImgSrc,
    getMessageFileName,
    getFileFilter,
} from '@/utils/chat/index';
import IconFont from '@/components/icon';
import { getVideoModal } from '@/utils/chat/commonMessageModal';
import { detectOS, FocusSDK, isFocusEnv } from '@/utils';
import { message as toast } from 'antd';
import { connect } from 'dvajs';
import ChatState from '@/types/chat/State';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import ImService from '@/server/ImService';
import { withTranslation, WithTranslation } from 'react-i18next';
import { changeEgovUrl } from '@/utils/tools';
interface VideoProps {
    isMergeForward?: boolean;
    message: VideoChatMessage;
    sendType: MessageSendType;
    MessageStatus?: ReactNode;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type IVideoProps = Readonly<VideoProps & WithTranslation & ChatState>;

interface IState {
    width: number;
    height: number;
    imgSrc: string;
    progress: number;
    viewModal: boolean;
    videoSrc: string;
    isClick: Boolean;
    isDragging: Boolean;
    path: string;
}

class Video extends React.Component<IVideoProps, IState> {
    imageRef: React.RefObject<HTMLImageElement> = React.createRef();

    constructor(props: IVideoProps) {
        super(props);
        const [width, height] = dealImageWidthHeight(
            [props.message.thumbWidth, props.message.thumbHeight],
            false
        );
        const imgSrc = getImgSrc(props.message.thumbnail);
        const videoSrc = getFileUrl(this.props.message.url);
        this.state = {
            width: width as number,
            height: height as number,
            imgSrc: imgSrc,
            progress: 0,
            viewModal: false,
            videoSrc: videoSrc,
            isClick: true,
            isDragging: false,
            path: '',
        };
    }

    public componentDidMount() {
        const { message } = this.props;
        const downLocalPath = message.downLocalPath;
        // console.log('downLocalPath', downLocalPath);
        if (isFocusEnv() && downLocalPath) {
            FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                if (exists) {
                    this.setState({ path: downLocalPath });
                } else {
                    this.setState({ path: '' });
                }
            });
        }
    }

    playVideo = () => {
        const { isClick } = this.state;
        if (isClick) {
            this.setState({ isClick: false });
            getVideoModal(this.props.message);

            setTimeout(() => {
                this.setState({ isClick: true });
            }, 2000);
        }
    };

    downloadFile = () => {
        const { message, t } = this.props;
        const name = getMessageFileName(message);
        const url = getImgSrc(message.url);
        FocusSDK.downloadFile(url, {
            saveAs: false,
            filename: name,
            filter: getFileFilter(message),
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                // console.log('finish path', path, 'message id', message.mid);
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(message.sessionId, message.mid, {
                    downLocalPath: path,
                });
                if (result) {
                    this.setState({
                        path: path,
                        isDragging: false,
                    });
                    // toast.success('下载完成');
                } else {
                    toast.error(t('failed'));
                    this.setState({ isDragging: false });
                }
            },
        });
    };

    onDragStart = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const { connectState, message, t } = this.props;
        if (connectState && connectState.state === ConnectState.READY) {
            const { path, isDragging } = this.state;
            // console.log('new message', message, '哈哈哈', path);
            if (isDragging) {
                toast.warning(t('file-downloading-drag-save'));
                return;
            }
            if (!path) {
                this.setState({ isDragging: true });
                toast.warning(t('file-downloading-drag-save'));
                this.downloadFile();
            } else {
                FocusSDK.checkLocalPathExist(path, (exists: boolean) => {
                    if (exists) {
                        FocusSDK.startDrag(path);
                    } else {
                        if (isDragging) {
                            toast.warning(t('file-downloading-drag-save'));
                            return;
                        }
                        this.setState({ isDragging: true });
                        toast.warning(t('file-downloading-drag-save'));
                        this.downloadFile();
                        // toast.warning(t('find-file-failed-and-upload'));
                    }
                });
            }
        } else {
            toast.warning(t('drag-after-the-network-is-normal'));
            return;
        }
    };
    public render() {
        const { width, height, imgSrc } = this.state;
        const { isMergeForward } = this.props;
        const isDrag = FocusSDK.getDeviceInfo().platform !== 'web' && !isMergeForward;
        return (
            <div className="content video">
                <div className="pre-view-video" style={{ width, height }}>
                    <img
                        ref={this.imageRef}
                        alt=""
                        src={changeEgovUrl(imgSrc)}
                        width={width}
                        height={height}
                        draggable={isDrag}
                        onDragStart={this.onDragStart}
                    />
                    <IconFont
                        className="download-icon"
                        type="iconapp_btn_play_page"
                        onClick={this.playVideo}
                    />
                </div>
            </div>
        );
    }
}
function mapStateToProps({ chat }: any) {
    const { connectState } = chat as ChatState;
    return { connectState };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}
// export default Video;
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(Video));
