import ListItem, {
    DefaultAvatarSize,
    ListItemData,
    ListItemProps,
} from '@jd/focus-desktop-comps/lib/es/ListItem';
import React from 'react';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { SearchGlobalContext } from '@/components/Modals/SearchGlobalModal/utils';
import { ContactsTag, GroupTag } from '@/components/Tags';
import Rete from '@/components/ThirdApp/components/Rate';
import { Group } from '@/types/chat';
import { Avatar } from '@/baseComponents/Avatar';
import { RecommendedType } from '@/services/AppStore';
import GlobalContext from '@/context/GlobalContext';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { changeEgovUrl } from '@/utils/tools';

const SearchListItem = (
    props: {
        data: ListItemData;
        onClick?: (data: ListItemData) => void;
        extra?: React.ReactNode;
        t: any;
    } & ListItemProps
) => {
    const { onGetAvatarURL, authData, activeKey } = React.useContext(SearchGlobalContext);
    const { authInfo } = React.useContext(GlobalContext);
    const { data, onClick, extra, t, ...rest } = props;
    const { avatar, searchKey } = data;
    const origin = data.origin;
    let itemTag = null;
    if (searchKey === SearchType.Contacts || searchKey === SearchType.Group) {
        if (origin.userId === data.id) {
            // 联系人
            const userI = { teamId: origin.teamId, app: origin.app };
            const userII = {
                teamId: authData?.teamUserInfo?.teamId || authInfo?.teamUserInfo?.teamId,
                app: authData?.ddAppId || authInfo?.ddAppId,
            };
            itemTag = <ContactsTag userI={userI} userII={userII} />;
        } else if ((origin.gid || origin.groupId) === data.id) {
            // 群组
            const group: Group = { ...origin, ...origin.ext };
            itemTag = <GroupTag group={group} />;
        }
    } else if (searchKey === SearchType.App) {
        // 推荐
        itemTag =
            data.origin.ext.recommended === RecommendedType.RecommendedYes ? (
                <Rete value={0} />
            ) : null;
    }

    let setAvatar = null;
    if (onGetAvatarURL && typeof avatar === 'string') {
        setAvatar = onGetAvatarURL(avatar, DefaultAvatarSize, DefaultAvatarSize);
    } else if (typeof avatar === 'string') {
        setAvatar = (
            <Avatar
                src={changeEgovUrl(getHeadImgThumbUrl(avatar, 150, 150) || '')}
                name={data?.displayName || ''}
            />
        );
    } else if (React.isValidElement(avatar)) {
        setAvatar = avatar;
    } else {
        setAvatar = <Avatar name={data?.displayName || ''} />;
    }

    return (
        <ListItem
            style={{
                paddingLeft: 24,
            }}
            data={{
                ...data,
                avatar: setAvatar,
            }}
            onClick={() => {
                if (onClick) {
                    onClick(data);
                }
            }}
            extra={extra}
            tag={itemTag}
            {...rest}
        />
    );
};

export default SearchListItem;
