/* eslint-disable max-params */
/* eslint-disable complexity */
/**
 * 聊天ImSdk初始化
 * @author sunzhiguang
 * @date 2020/6/18
 */
import { EventEmitter } from 'events';
import ChatManager from '@jd/jdee.im.sdk/lib/es/index';
import i18n from '@/i18n';
import {
    Apply,
    ChatMessage,
    ChatMessageBody,
    ChatMessageEmitBody,
    ChatMessageRespBody,
    ClientType,
    Employee,
    EmployeeAttribute,
    Friend,
    Group,
    GroupAdminSetAction,
    GroupAdminSetReqBody,
    GroupModifyField,
    GroupRoster,
    GroupSessionCreateOptions,
    ISessionAttribute,
    LoadHistoryOptions,
    LoadHistoryResp,
    LoadSessionOptions,
    MessageEmitCategory,
    MessageReadScene,
    Session,
    SessionCreateOptions,
    UID,
    WebsocketErrorCode,
} from '@/types/chat';
import cookies from 'js-cookie';
import {
    FocusSDK,
    getAppId,
    getAppSecretKey,
    getJanusHost,
    getJanusStage,
    getWsHost,
    getWsTls,
    isFocusEnv,
    relogin,
} from '@/utils';
import Bus from '@/utils/bus';
import lodashPick from 'lodash/pick';
import SessionType from '@jd/jdee.im.sdk/lib/es/enum/SessionType';
import log from '@/utils/logger';
import {
    checkGroupSysMessage,
    employeeToUid,
    isInValidSessionInfo,
    mapRosters,
    setMessageGroupId,
    convertUserToString,
    checkSingleSysMessage,
    isGroupNoticeMsg,
} from '@/components/chat/utils/message';
import chatConfig from '@/components/chat/config';
import cache from '@/utils/cache';
import { generateMessageId, generateSessionId } from '@jd/jdee.im.sdk/lib/es/utils/utils';
import {
    ApplyAppStoreState,
    ApplyGroupState,
    ApplyTeamState,
    SessionSettingsType,
} from '@/types/chat/enum';
import RosterApplyStatus from '@jd/jdee.im.sdk/lib/es/enum/RosterApplyStatus';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import { GroupGetType } from '@jd/jdee.im.sdk/lib/es/service/Group';
import Channel from '@jd/jdee.im.sdk/lib/es/core/channel/EventChannel';
import LocalStorageEmitter from '@jd/jdee.im.sdk/lib/es/core/channel/emitter/LocalStorageEmitter';
import { SyncReqBody } from '@jd/jdee.im.sdk/lib/es/protocol/Sync';
import { GroupAttribute } from '@jd/jdee.im.sdk/lib/es/model/Group';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';
import lodashOmit from 'lodash/omit';
import lodashGet from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { FocusAuthProps } from '@jd/jzb.jdee-janus';
import { sessionAttrs } from '@/server/im/SessionService';
import { isAINoticeSession, isUnnecessarySession } from '@/components/chat/utils/session';
import {
    ChatMessageExtBody,
    ChatMessageExtResp,
} from '@jd/jdee.im.sdk/lib/es/protocol/messageExtend';
import { User } from '@/types/supervise';
import { GetEbookDeptReq } from '@jd/jdee.im.sdk/lib/es/service/Department';
import Department from '@jd/jdee.im.sdk/lib/es/model/Department';
import CHAT_NOTICE from '@/contant/chat';
import ConnectState from '@jd/jdee.im.sdk/lib/es/enum/ConnectState';
import { EGroupGroupInfo, getGroupInfo } from '@/api/egroup';
import { AIConfig } from '@jd/jdee.im.sdk/lib/es/utils/types';
import ChainService, { Chain } from '@/services/ChainService';
import { AggregateUser } from '@jd/jdee.im.sdk/lib/es/types/Common';
// import { mapDetailListData } from '@/components/AddressBook/common';
import { userDetailListPrams } from '@/types/UserCard';
import { message as AntMessage, Modal } from 'antd';
import { changeEgovUrl, changeGovUrl } from '@/utils/tools';
import { isTopSession } from '@/utils/chat/message';
import { getAppVersion } from '@/utils/getVersion';
import { UserReply } from '@jd/jdee.im.sdk/lib/es/protocol/message/GroupNotice';
import { gatewayInstance } from './gateway';

const logger = log.getLogger('ImService');

interface MessageOptions {
    max_cache_message_count: number;
}

const SESSION_EVENTS: string[] = [
    'session-add',
    'session-update',
    'session-update-batch',
    'session-del',
];
const EBOOK_EVENTS: string[] = ['contact/apply-count'];

const APPLY_EVENTS: string[] = ['contact/update-apply'];

// 拉取离线消息间隔天数
const LIMIT_DAY = 3;

export function getPullTimeLimit(day: number): number {
    const now = Date.now();
    const todayStart = new Date();
    todayStart.setHours(0);
    todayStart.setMinutes(0);
    todayStart.setSeconds(0);

    return now - todayStart.getTime() + day * 24 * 60 * 60 * 1000;
}

class ImService extends EventEmitter {
    private static instance: ImService | null;

    // todo promise 处理
    // eslint-disable-next-line complexity
    public static getInstance(runtimeEnv?: AppRuntimeEnv): ImService {
        if (!runtimeEnv) {
            return this.instance as any;
        }
        if (!this.instance) {
            const userInfo = runtimeEnv.registryConfig.auth;
            if (!userInfo) {
                return this.instance as any;
            }
            const teamId = userInfo.teamUserInfo?.teamId || '';
            let xToken = cookies.get('focus-token') || '';
            let xTeamId = cookies.get('focus-team-id') || '';
            const gatewayHost = runtimeEnv.gatewayHost || getJanusHost();
            const gatewayStage = runtimeEnv.gateway.stage || getJanusStage();
            const appRuntime = process.env.REACT_APP_RUNTIME || 'mebjJZB';
            const appKey = getAppSecretKey(appRuntime);
            let xApp;
            let xAppKey;
            if (isFocusEnv()) {
                xApp = runtimeEnv.gateway.appId;
                xAppKey =
                    runtimeEnv.gateway.secretKey || getAppSecretKey(runtimeEnv.runtimeConfig.key);
            } else {
                // xApp = runtimeEnv.gateway.webAppId;
                xApp = getAppId();
                xAppKey = process.env.REACT_APP_WEB_SECRETKEY;
            }
            const props = {
                host: runtimeEnv.imHost,
                tls: runtimeEnv.imTls, // 是否使用加密通道默认不加密
                userId: userInfo?.userId, // 当前用户ID
                teamId: teamId, // 当前用户所在teamIDef
                app: userInfo.ddAppId,
                focusToken: {
                    // focus认证token
                    xToken: xToken || '', // 'ae93e4000d02c179d3a301df8f642b5b', // focus token
                    xTeamId: xTeamId || '', // 'mS2CKU8HN3UFxdliJ1AxR', // focus team id
                    xClient: 'web', // 当前客户端类型
                },
                version: '4.3',
                options: {},
                gateWayOption: {
                    host: gatewayHost,
                    appId: xApp, // userInfo.ddAppId || getAppId(),
                    appKey: xAppKey,
                    stage: gatewayStage,
                    appVersion: getAppVersion(FocusSDK) || '1.9.1',
                },
                deviceCode: '',
                clientType: ClientType.IPAD,
                autoAppendBusinessToken: false,
                businessToken: '',
                channel: cache.channel || new Channel(new LocalStorageEmitter()),
                aiConfig: runtimeEnv.config.aiConfig,
            };
            if (isFocusEnv()) {
                props.focusToken.xToken = userInfo?.accessToken || '';
                props.focusToken.xTeamId = teamId || '';
                props.gateWayOption = {
                    host: gatewayHost,
                    appId: xApp || '',
                    appKey: xAppKey,
                    stage: gatewayStage,
                    appVersion: getAppVersion(FocusSDK) || '1.9.1',
                };
                props.deviceCode = runtimeEnv.registryConfig.machineId || '';
                props.clientType = runtimeEnv.platform === 'MAC' ? ClientType.MAC : ClientType.PC;
                props.channel = new Channel(FocusSDK.ipcEventEmitter);
                // : userInfo?.client === 'windows'
                // ? ClientType.PC
                // : ClientType.IPAD;
            }
            if (cache.token) {
                props.focusToken.xToken = cache.token.token.focusToken;
                props.focusToken.xTeamId = cache.token.token.focusTeamId;
                props.focusToken.xClient = cache.token.token.focusClient;
                props.autoAppendBusinessToken = cache.token.autoAppendBusinessToken || false;
                props.businessToken = cache.token.businessToken || '';
            }
            logger.debug('create instance', { runtimeEnv, userInfo, props });
            this.instance = new ImService(runtimeEnv, props);
        }
        return this.instance;
    }

    public static isValid() {
        return !!(ImService.instance && ImService.instance.userId && ImService.instance.teamId);
    }

    public static destroy() {
        const instance = ImService.instance;
        if (instance) {
            instance.offChatMessage();
            if (instance.chatManager) {
                instance.chatManager
                    .logout()
                    .then((data) => {
                        log.debug('instance.chatManager =>', 'logout 11', data);
                    })
                    .catch((e) => {
                        log.error('im service logout error =>', e);
                    })
                    .finally(() => {
                        log.debug('instance.chatManager =>', 'logout 22');
                        // instance.chatManager = null;
                    });
            }
        }
        // ImService.instance = null;
    }
    // Im 登录状态
    public imLoginState: boolean;
    public focusToken: { xClient: string; xToken: string; xTeamId: string };

    // 消息配置项
    private defaultMessageOptions: MessageOptions = {
        max_cache_message_count: 100, // 每个会话最新消息的最大缓存消息数量 超过该值 最早的消息会被清空
    };
    private readonly userId: string;
    private readonly teamId: string;
    private readonly app: string;
    private readonly gateWayOption: any;
    private readonly imVersion: string;
    private readonly clientType: ClientType;
    private readonly chatManager: ChatManager | null;
    private readonly channel?: Channel;
    private readonly aiConfig?: AIConfig;
    private appRuntimeEnv: AppRuntimeEnv;
    // 消息配置项
    private messageOptions: any;
    // 事件监听
    private eventListener: any = {};
    private aiCardchainService: ChainService<any>;
    private imForceClosed = false;
    private constructor(
        runtimeEnv: AppRuntimeEnv,
        props?: {
            clientType?: ClientType;
            deviceCode?: string;
            userId?: string;
            teamId?: string;
            app?: string;
            focusToken?: { xClient?: string; xToken?: string; xTeamId?: string };
            gateWayOption?: { appId?: string };
            version?: string;
            options?: any;
            autoAppendBusinessToken?: boolean;
            businessToken?: string;
            channel?: Channel;
            aiConfig?: AIConfig;
        }
    ) {
        super();
        this.imLoginState = false;
        // prod
        this.appRuntimeEnv = runtimeEnv;
        const {
            clientType = ClientType.IPAD,
            deviceCode = '',
            businessToken,
            autoAppendBusinessToken,
        } = props || {};
        // dev
        // const [clientType, deviceCode] = [ClientType.COMET, ''];
        // 初始化消息配置
        this.messageOptions = {
            ...this.defaultMessageOptions,
            ...(props?.options || {}),
        };
        this.app = props?.app || '';
        this.userId = props?.userId || '';
        this.teamId = props?.teamId || '';
        this.focusToken = {
            ...(props?.focusToken || {}),
            ...{
                xClient: 'web',
                xToken: props?.focusToken?.xToken || '',
                xTeamId: props?.focusToken?.xTeamId || '',
            },
        };
        this.gateWayOption = props?.gateWayOption;
        this.imVersion = props?.version || '4.3';
        this.clientType = clientType;
        this.channel = props?.channel;
        this.aiConfig = props?.aiConfig;
        this.aiCardchainService = new ChainService();
        try {
            const props = {
                host: getWsHost(), // 'im-ws-test.jdcloud.com',
                tls: getWsTls(),
                // tls: false,
                // useTLS: NODE_ENV !== 'development', // 是否使用加密通道默认不加密
                userId: this.userId, // 当前用户ID
                teamId: this.teamId, // 当前用户所在teamID
                app: this.app,
                // focusToken: this.focusToken,
                version: this.imVersion || '4.3', // 使用会话协议版本
                gateway: this.gateWayOption || {},
                debug: true,
                clientType,
                server: { app: this.app },
                deviceCode,
                businessToken,
                autoAppendBusinessToken,
                callbacks: {
                    onStateChange: this.onStateChangeCallBack,
                    onError: this.errorCallBack,
                    onForceQuit: this.onForceQuitCallBack,
                    onAppRefreshToken: this.onAppRefreshToken,
                },
                mode: cache.clientMode,
                messageChannel: this.channel,
                tripledesKey: '',
                uploadFile: (buf: ArrayBuffer, options: Record<string, any> = {}) =>
                    Promise.resolve(''),
                aiConfig: this.aiConfig,
            };
            if (isFocusEnv()) {
                Object.assign(props, {
                    cache: FocusSDK.cache,
                    // @ts-ignore
                    mode: FocusSDK.IM_MODE || 'server',
                });
            }
            if (props.mode === 'server') {
                props.tripledesKey = 'F50DBAB515286F4C88D44CADE0819334829C15F60D859F43';
                props.uploadFile = (buf: ArrayBuffer, options: Record<string, any> = {}) => {
                    const formData = new FormData();
                    const mimeType = options.fileType || 'application/octet-stream';
                    formData.append('appKey', this.appRuntimeEnv.fileService.appKey);
                    formData.append('needAuthn', '1');
                    formData.append('fileType', mimeType);
                    formData.append(
                        'file',
                        new File([new Blob([buf], { type: mimeType })], options.fileName || '', {
                            type: 'text/html',
                        })
                    );
                    return fetch(
                        `${this.appRuntimeEnv.config.fileService.hostOrigin}/single/upload`,
                        {
                            method: 'post',
                            headers: {
                                'x-token': this.focusToken.xToken,
                                'x-team-id': this.focusToken.xTeamId,
                            },
                            body: formData,
                        }
                    )
                        .then((x) => x.json())
                        .then((res) => {
                            if (res.errorCode === '0') {
                                const { appKey, fileDownloadUrl } = res.content;
                                return fileDownloadUrl + '?appKey=' + appKey;
                            }
                            throw new Error(`${res.errorCode}-${res.errorMsg}`);
                        });
                };
            }
            // this.chatManager = null;
            this.initEventListener();
            (window as any).user = this.chatManager = new ChatManager(props);
        } catch (e) {
            logger.error('init chatManager error =>', e);
            this.chatManager = null;
        }
    }

    addAICardChain(chain: Chain<any>) {
        this.aiCardchainService.addChain(chain);
    }

    addAICardChins(chains: Chain<any>[]) {
        this.aiCardchainService.addChainList(chains);
    }

    aiCardChainexecute(params: any) {
        return this.aiCardchainService.execute(params);
    }

    // 强制退出
    onForceQuitCallBack = (msg?: unknown) => {
        FocusSDK.printLog('logout', 'onForceQuitCallBack');
        Bus.emit('chat:force_quit', msg);
    };
    // 强制退出
    onAppRefreshToken = () => {
        FocusSDK.printLog('onAppRefreshToken', 'accessToken过期了，延期一天');
        FocusSDK.autoLogin();
    };
    // 错误
    errorCallBack = (error: any) => {
        if (error.name === 'TokenError' || error.name === 'WebsocketError') {
            if (error.code === '100001' || error.code === '0002') {
                FocusSDK.printLog('errorCallBack---', error.name, error.code);
                const registryConfig = this?.appRuntimeEnv?.registryConfig || {};
                const { autoLoginChecked, auth } = registryConfig as any;
                if (autoLoginChecked && auth) {
                    return;
                }
                FocusSDK.logout();
            } else if (error.code === WebsocketErrorCode.AuthError) {
                FocusSDK.printLog(`ImService-token-e`, `need auth info`);
                Bus.emit('chat:state-auth-faild');
            } else {
                // return relogin();
            }
        } else if (error.name === 'BaseError') {
            if (error.code === '009') {
                Modal.error({
                    content: '数据库加载失败，重新加载',
                    okText: '确定',
                    cancelButtonProps: { style: { display: 'none' } },
                    onOk: () => FocusSDK.relaunchApp(),
                });
            }
        }
    };
    onStateChangeCallBack = (data: any) => {
        log.debug('onStateChange', data);
        Bus.emit(`chat:state`, data);
        if (data.state === 'ready') {
            // 判断是否存在指定会话拉起会话
            if (cache.token) {
                if (cache.token.uid) {
                    Bus.emit('chat:top-search-single-session', cache.token.uid);
                } else if (cache.token.gid) {
                    Bus.emit('chat:top-search-group-session', {
                        id: cache.token.gid,
                    });
                }
            }
        }
        if (data.state === 'authed') {
            log.debug('onStateChange', data);
            Bus.emit('chat:authed-change', data.reason?.aid);
            cache.aid = data.reason?.aid;
            if (this.imForceClosed) {
                this.imForceClosed = false;
                this.getOfflineMessage(true);
            }
        }
        if (data.state === 'closed') {
            this.imForceClosed = true;
        }
    };

    migrateDB = async (option: { onProgress: Function; onStart: Function; onFinish: Function }) => {
        function handleProgress(data: any) {
            if (typeof option.onProgress === 'function') {
                option.onProgress(data);
            }
            if (data && data.progress === 100) {
                if (typeof option.onFinish === 'function') {
                    option.onFinish();
                }
                Bus.off('chat:migrate-progress', handleProgress);
            }
        }
        try {
            if (this.chatManager) {
                if (typeof option.onStart === 'function') {
                    option.onStart();
                }
                Bus.off('chat:migrate-progress', handleProgress).on(
                    'chat:migrate-progress',
                    handleProgress
                );
                this.chatManager.method.message
                    .migrateDB()
                    .catch((e) => {
                        throw e;
                    })
                    .finally(() => {
                        if (typeof option.onFinish === 'function') {
                            option.onFinish();
                        }
                        Bus.off('chat:migrate-progress', handleProgress);
                    });
            }
        } catch (e) {
            console.log(`migrate--`, e);
            throw e;
        }
    };

    retryCreateTable = async () => {
        try {
            if (this.chatManager) {
                this.chatManager.method.message.retryCreateTable();
            }
        } catch (e) {
            console.log(`retryCreateTable--`, e);
            throw e;
        }
    };

    getMessageCount = async () => {
        try {
            if (this.chatManager) {
                return await this.chatManager.method.message.getMessageCount();
            }
        } catch (e) {
            console.log(`migrate--`, e);
            return 0;
        }
        return 0;
    };

    searchMessageById = async (option: any) => {
        try {
            if (this.chatManager) {
                // const res = await this.chatManager.method.message.searchMessageById(option);
            }
        } catch (e) {
            console.log(`searchMessageById--`, e);
            return 0;
        }
        return 0;
    };

    unBindAllEvents = () => {
        // 聊天消息事件
        this.offChatMessage();
        this.offDeptInfo();
        // 解绑会话事件
        this.unbindEvents();
    };

    bindAllEvents = () => {
        // 聊天消息事件
        this.onChatMessage();
        this.onDeptInfo();
        // 绑定会话事件
        this.bindEvents();
    };

    getChatManager(): ChatManager | null {
        return this.chatManager;
    }

    receiveMessageHandle = (message: ChatMessage) => {
        this.emit('chat_new_message', message);
    };

    receiveBatchMessageHandle = (messages: ChatMessage[]) => {
        this.emit('chat_batch_message', messages);
    };
    receiveDeptUpdate = (dept: any) => {
        Bus.emit('dept:department-update', dept);
    };

    destroyMessageHandle = (messages: ChatMessage[]) => {
        // console.log('destroyMessageHandle', messages);
        Bus.emit('chat:message-del', messages);
    };

    migrateProgressHandle = (data: any) => {
        Bus.emit('chat:migrate-progress', data);
    };

    viewCardReadBatchHandle = (messages: ChatMessage[]) => {
        Bus.emit('chat:card-read-batch', messages);
    };

    onChatMessage() {
        if (this.chatManager) {
            this.chatManager.off('message', this.receiveMessageHandle);
            this.chatManager.on('message', this.receiveMessageHandle);
            this.chatManager.off('message-batch', this.receiveBatchMessageHandle);
            this.chatManager.on('message-batch', this.receiveBatchMessageHandle);
            this.chatManager.off('message-del', this.destroyMessageHandle);
            this.chatManager.on('message-del', this.destroyMessageHandle);
            this.chatManager.off('migrate-progress', this.migrateProgressHandle);
            this.chatManager.on('migrate-progress', this.migrateProgressHandle);
            this.chatManager.off('card-read-batch', this.viewCardReadBatchHandle);
            this.chatManager.on('card-read-batch', this.viewCardReadBatchHandle);
        }
    }

    /**
     * 监听组织架构更新
     */
    onDeptInfo() {
        if (this.chatManager) {
            this.chatManager.off('dept-update', this.receiveDeptUpdate);
            this.chatManager.on('dept-update', this.receiveDeptUpdate);
        }
    }

    initEventListener() {
        for (const e of SESSION_EVENTS) {
            this.eventListener[e] = (args: any) => {
                Bus.emit(`chat:${e}`, args);
            };
        }
        for (const e of EBOOK_EVENTS) {
            this.eventListener[e] = (args: any) => {
                Bus.emit(`ebook:${e}`, args);
            };
        }
        for (const e of APPLY_EVENTS) {
            this.eventListener[e] = (args: any) => {
                Bus.emit(`ebook:${e}`, args);
            };
        }
    }

    unbindEvents = () => {
        const chatManager = this.chatManager;
        if (!chatManager) {
            return;
        }
        for (const e of SESSION_EVENTS) {
            chatManager.off(e, this.eventListener[e]);
        }
        for (const e of EBOOK_EVENTS) {
            logger.debug('unbind ebookEvents ===>', e);
            chatManager.off(e, this.eventListener[e]);
        }
    };

    offChatMessage() {
        if (this.chatManager) {
            // 断开im消息连接
            logger.debug('unbind message event ===>', 'message');
            this.chatManager.off('message', this.receiveMessageHandle);
            this.chatManager.off('message-batch', this.receiveBatchMessageHandle);
            this.chatManager.off('migrate-progress', this.migrateProgressHandle);
            this.chatManager.off('card-read-batch', this.viewCardReadBatchHandle);
        }
    }

    /**
     * 移除监听组织架构更新
     */
    offDeptInfo() {
        if (this.chatManager) {
            this.chatManager.off('dept-update', this.receiveDeptUpdate);
        }
    }

    bindEvents = () => {
        const chatManager = this.chatManager;
        if (!chatManager) {
            return;
        }
        for (const e of SESSION_EVENTS) {
            chatManager.on(e, this.eventListener[e]);
        }
        for (const e of EBOOK_EVENTS) {
            chatManager.on(e, this.eventListener[e]);
        }
        chatManager.on('error', (args) => {
            Bus.emit(`chat:error`, args);
        });
    };

    isValid() {
        return !!(ImService.instance && ImService.instance.userId && ImService.instance.teamId);
    }

    async findMeetingMessage(meetingNumber: any, sessionId: any, mid: any): Promise<any> {
        logger.debug('findMeetingMessage', { meetingNumber, sessionId, mid });
        if (!this.chatManager) {
            return null;
        }
        const res = (await this.chatManager.method.message.loadHistory(1, sessionId, mid, {
            queryType: 2,
        })) as any;
        logger.debug('findMeetingMessage res', res);
        if (!res.messages.length) {
            return null;
        }
        for (let i = 0; i <= res.messages.length; i += 1) {
            const msg = res.messages[i] as any;
            if (msg.flag === 100 && msg.ext && msg.ext.meetingNumber === meetingNumber) {
                return msg;
            }
        }
        return await this.findMeetingMessage(
            meetingNumber,
            sessionId,
            res.messages[res.length - 1].mid
        );
    }

    /**
     * 读消息
     * @param mid
     * @param sessionId
     * @returns
     */
    async readMessageById(mid: number, sessionId: string): Promise<void> {
        if (!this.chatManager || isNaN(mid)) {
            return;
        }
        try {
            const message = await this.chatManager.method.message.getBySessionIdAndMid(
                sessionId,
                Number(mid)
            );
            if (message) {
                this.readOneMessage(message);
            }
        } catch (e) {
            console.log(`readMessageById error: ${JSON.stringify({ e })}`);
        }
    }

    readMessage(
        message: ChatMessage & ChatMessageRespBody,
        session: Session,
        currentEmployee: Employee
    ): void {
        if (
            message.sessionId === session.sessionId &&
            [SessionType.SINGLE, SessionType.SECRET_SINGLE].includes(session.sessionType) &&
            message.sender.userId !== currentEmployee.userId
        ) {
            // 如果接收的消息 是当前会话(单聊的)  发送 dong read 消息
            this.readOneMessage(message)
                .then((result) => {
                    logger.debug('readOneMessage ===>', message.id, message.content, result);
                })
                .catch((e) => {
                    logger.error('readOneMessage ===>', e);
                    this.readOneMessage(message).then();
                });
        }
    }

    /**
     * 读全部消息
     * @param sessionId
     * @param scene
     */
    async readAllMessage(
        sessionId: string,
        scene: MessageReadScene,
        force?: boolean
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            if (sessionId.includes(CHAT_NOTICE.AI_PIN)) {
                await this.chatManager.method.session.readAllNoticeMessage(1, scene, force);
            } else {
                await this.chatManager.method.session.readAllMessages(1, sessionId, scene, force);
            }
            return true;
        } catch (e) {
            logger.error('readAllMessage-im =>', e);
            return false;
        }
    }

    /**
     * 读单条消息
     * @param message
     */
    async readOneMessage(message: any) {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.dongRead(message);
            return true;
        } catch (e) {
            logger.error('readOneMessage-im =>', e);
            return false;
        }
    }

    async forwardMessage(
        sessionId: string,
        message: ChatMessage,
        options: {
            sessionIds?: string[];
            gids?: string[]; // 群聊传 gid
            uids?: UID[]; // 单聊传  uid
        },
        body?: any
    ): Promise<boolean | string> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.forward(
                message.sessionId,
                message.mid,
                {
                    sessionIds: options.sessionIds,
                    gids: options.gids,
                    uids: options.uids,
                },
                body
            );

            return true;
        } catch (e) {
            logger.error('im =>', e);
            const { type, body } = e;
            if (type === 'failure') {
                const { code } = body;
                if (code === 236032) {
                    return SessionSettingsType.BANNED_POST;
                }
            }
            return false;
        }
    }

    async mergeForwardMessage(
        sessionId: string,
        mids: number[],
        options: {
            sessionIds?: string[];
            gids?: string[]; // 群聊传 gid
            uids?: UID[]; // 单聊传  uid
        }
    ): Promise<boolean | string> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.mergeForward(sessionId, mids, {
                sessionIds: options.sessionIds,
                gids: options.gids,
                uids: options.uids,
            });
            return true;
        } catch (e) {
            logger.error('im =>', e);
            const { type, body } = e;
            if (type === 'failure') {
                const { code } = body;
                if (code === 236032) {
                    return SessionSettingsType.BANNED_POST;
                }
            }
            return false;
        }
    }

    async getMergeForwardBody(contentUrl: string, uuid: string): Promise<boolean | string> {
        if (!this.chatManager) {
            return false;
        }
        try {
            let token = cache.token?.token.focusToken;
            let result: any = await this.chatManager.method.message.getMergeForwardBody(
                `${contentUrl}&x-token=${token}`,
                uuid
            );
            return result;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 撤回一条消息
     * @param session
     * @param message
     */
    async revokeMessage(session: Session, message: ChatMessage): Promise<any> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const result = await this.chatManager.method.message.revoke(
                session.sessionId,
                message.mid
            );
            logger.debug('revokeMessage result =>', result, message);
            return result;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 销毁一条消息
     * @param message
     */
    async destroyMessage(message: ChatMessage): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            delete message.taskId;
            delete message.task;
            logger.debug('destroyMessage result =>', message);
            await this.chatManager.method.message.destroy(message);
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 删除一条消息
     * @param message
     */
    async deleteMessage(message: ChatMessage): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        const { sessionId, mid } = message;
        const result = await this.chatManager.method.message.removeBySessionIdAndMid(
            sessionId,
            mid
        );
        logger.debug('deleteMessage result ===>', result);
        return result;
    }

    /**
     *
     * @param sessionId
     * @param mid
     * @param options
     * @returns
     */
    async updateReadTime(
        sessionId: string,
        mid: number,
        options: { readTimeOfMessage: number }
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            // console.log('updateReadTime', options);
            await this.chatManager.method.message.updateLocal(sessionId, mid, options);
            return true;
        } catch (e) {
            logger.error('im updateReadTime error:', e);
            return false;
        }
    }

    /**
     * 更新本地文件路径
     * @param sessionId
     * @param mid
     * @param options
     */
    async updateLocalPath(
        sessionId: string,
        mid: number,
        options: { downLocalPath?: string; upLocalPath?: string }
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const items: any = {};
            if (options.downLocalPath) {
                items.downLocalPath = options.downLocalPath;
            } else if (options.upLocalPath) {
                items.upLocalPath = options.upLocalPath;
            }
            if (Object.keys(items).length === 0) {
                return false;
            }
            await this.chatManager.method.message.updateLocal(sessionId, mid, items);
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }
    // JoySpaceLink 消息体
    async updateJoySpaceLink(
        sessionId: string,
        mid: number,
        // message: any,
        options: {}
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            if (Object.keys(options).length === 0) {
                return false;
            }
            // console.log(options, 'optionsoptionsoptions');
            await this.chatManager.method.message.updateLocal(sessionId, mid, options);
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }
    // 获取某一条消息
    async getBySession(sessionId: string, mid: number): Promise<any> {
        if (!this.chatManager) {
            return {};
        }
        try {
            const message = await this.chatManager.method.message.getBySessionIdAndMid(
                sessionId,
                Number(mid)
            );
            return message;
        } catch (e) {
            return {};
        }
    }

    /**
     * 更新转存消息
     * @param sessionId
     * @param mid
     * @param options
     * @returns
     */
    async updateFileToMessage(
        sessionId: string,
        mid: number,
        options: { flag: number }
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(sessionId, mid, options);
            // console.log(options, 'optionsoptionsoptions');
            return true;
        } catch (e) {
            logger.error('im updateMergedMessage error:', e);
            return false;
        }
    }

    /**
     * 更新消息聚合数组
     * @param sessionId
     * @param mid
     * @param options
     * @returns
     */
    async updateMergedMessage(
        sessionId: string,
        mid: number,
        options: { plusList: Array<AggregateUser> }
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(sessionId, mid, options, true);
            return true;
        } catch (e) {
            logger.error('im updateMergedMessage error:', e);
            return false;
        }
    }

    async updateMessage(
        sessionId: string,
        mid: number,
        options: { userList: [] }
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(sessionId, mid, options, true);
            return true;
        } catch (e) {
            logger.error('im updateMergedMessage error:', e);
            return false;
        }
    }

    /**
     * 撤回已聚合消息
     * @param sessionId
     * @param mid   // 当前要撤回的消息id
     * @param fmid  // 聚合消息的mid
     * @param options
     * @returns
     */
    // eslint-disable-next-line max-params
    async revokeMergedMessage(
        sessionId: string,
        mid: number,
        fmid: number,
        options: { plusList: Array<AggregateUser> }
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const result = await this.chatManager.method.message.revoke(sessionId, mid);
            // if (result) {
            //     const res = await this.updateMergedMessage(sessionId, fmid, options);
            //     console.log('res', res);
            // }
            return result;
        } catch (e) {
            logger.error('im revokeMergedMessage error=>', e);
            return false;
        }
    }

    // 根据当前host将政务外网下链接地址egov切换为gov
    decorateUrlDomainFromHost(msg: ChatMessageBody): ChatMessageBody {
        const { url, webUrl, thumbnail, content, data } = msg as any;

        if (url) {
            (msg as any).url = changeGovUrl(url);
        }
        // 文档
        if (webUrl) {
            (msg as any).webUrl = changeGovUrl(webUrl);
            if (content) {
                (msg as any).content = changeGovUrl(content);
            }
        }
        if (thumbnail) {
            (msg as any).thumbnail = changeGovUrl(thumbnail);
        }
        if (data && Array.isArray(data) && data.length > 0) {
            const firstData = data[0];
            if (firstData.sharecard) {
                const sharecard = firstData.sharecard || {};
                let avatar = sharecard.avatar || '';
                if (!avatar) {
                    return msg;
                }
                avatar = changeGovUrl(avatar);
                (msg as any).data[0].sharecard.avatar = avatar;
            }
        }
        return msg;
    }
    /**
     * 发送会话消息
     * @param sessionId 会话ID
     * @param messageId
     * @param message
     * @param options
     */
    // eslint-disable-next-line max-params
    async sendChatMessage(
        sessionId: string,
        messageId: string,
        message: ChatMessageBody,
        options?: any
    ): Promise<any> {
        if (!this.chatManager) {
            return null;
        }
        try {
            let processMessage = this.decorateUrlDomainFromHost(message);
            const result = await this.chatManager.method.message.sendMessage(
                sessionId,
                processMessage,
                {
                    id: messageId,
                    skipCheckSession: options?.skipCheckSession,
                } as any
            );
            return result;
        } catch (e) {
            logger.error('im sendMessage error =>', e);
            return null;
        }
    }

    async getMessageOptions(): Promise<any> {
        if (!this.chatManager) {
            return null;
        }
        try {
            return this.chatManager.getMessageOptions();
        } catch (e) {}
    }

    /**
     * 发送系统消息
     * @param sessionId
     * @param message
     * @param options
     * @returns
     */
    async sendSystemMessage(
        sessionId: string,
        message: ChatMessageBody,
        options?: any
    ): Promise<any> {
        if (!this.chatManager) {
            return null;
        }
        try {
            const result = await this.chatManager.method.message.sendSysMessage(
                sessionId,
                message,
                {
                    skipCheckSession: options?.skipCheckSession,
                    noSave: options?.noSave,
                } as any
            );
            return result;
        } catch (e) {
            logger.error('im sendSystemMessage error =>', e);
            return null;
        }
    }

    /**
     * 消息聚合
     * @param {Array<any>} messages
     * @return {*}  {Promise<Array<any>>}
     * @memberof ImService
     */
    async mergeChatMessage(messages: Array<any>): Promise<Array<any>> {
        if (!this.chatManager) {
            return Promise.resolve(messages);
        }
        try {
            const result = await this.chatManager.method.message.mergeMessages(messages);
            // console.log('333333333333', result);
            return result;
        } catch (e) {
            // logger.error('im mergeChatMessage error =>', e);
            return Promise.resolve(messages);
        }
    }

    async sendChatMessageExt(
        sessionId: string,
        messageBody: Partial<ChatMessageExtBody>,
        option?: any
    ): Promise<ChatMessageExtResp | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            const messageId = generateMessageId();
            const mid = messageBody.mid as number;
            await this.chatManager.method.messageExt.sendExtMessage(sessionId, mid, messageBody, {
                id: messageId,
                skipCheckSession: false,
            });
        } catch (e) {
            logger.error('im =>', e);
            return null;
        }
        return null;
    }

    /**
     * 获取全体成员信息
     */
    getAllEmployeeInfo(currentEmployee: Employee): any {
        return {
            app: FocusSDK.authInfo?.ddAppId || cookies.get('focus-app-id') || 'gwork',
            name: '全体成员',
            userId: 'all',
            teamId: currentEmployee.teamId,
        };
    }

    async getSingleMembers(
        session: Session,
        currentEmployee: Employee
    ): Promise<Partial<Employee>[]> {
        const sessionUser = lodashPick(session.info, [
            'app',
            'avatar',
            'name',
            'userId',
            'teamId',
            'e_dept_full_name',
            'cardNumber',
            'unitName',
            'unitDeptId',
            'deptId',
            'deptName',
            'titleName',
        ]);
        return [this.getAllEmployeeInfo(currentEmployee), currentEmployee, sessionUser];
    }

    /**
     * 获取当前登录的 Employee
     */
    async getPersonalDetail(): Promise<Partial<Employee>> {
        if (this.chatManager) {
            try {
                const employee = await this.chatManager.user();
                const user: any = await gatewayInstance.request({
                    api: 'ebook.gov.getPersonalDetail',
                    method: 'POST',
                    body: {
                        appId: isFocusEnv() ? employee.app : cookies.get('focus-app-id') || 'gwork',
                        userId: employee.userId,
                        teamId: employee.teamId,
                        isMulti: true,
                    },
                });
                let fields: any = {};
                (user.fields || []).forEach((item: any) => {
                    fields[item.key] = item.val;
                });
                return lodashPick(
                    { ...user, app: user?.appId, ...fields, ...user?.partTimeTitleDeptInfo?.[0] } ||
                        {},
                    [
                        'app',
                        'avatar',
                        'name',
                        'userId',
                        'teamId',
                        'e_dept_full_name',
                        'e_position',
                        'cardNumber',
                        'unitName',
                        'unitDeptId',
                        'deptId',
                        'deptName',
                        'titleName',
                    ]
                );
            } catch (e) {
                logger.error('im =>', e);
                const employee = await this.chatManager.user();
                return employee || {};
            }
        }
        return {};
    }

    /**
    //  * 获取当前登录的 Employee
      * 获取当前用户详情
     */
    async getCurrentEmployee(): Promise<Partial<Employee>> {
        if (this.chatManager) {
            try {
                const employee = await this.chatManager.user();
                const user: any = await gatewayInstance.request({
                    api: 'ebook.gov.getPersonalDetail',
                    method: 'POST',
                    body: {
                        appId: isFocusEnv() ? employee.app : cookies.get('focus-app-id') || 'gwork',
                        userId: employee.userId,
                        teamId: employee.teamId,
                        isMulti: true,
                    },
                });
                let fields: any = {};
                (user.fields || []).forEach((item: any) => {
                    fields[item.key] = item.val;
                });
                return lodashPick(
                    { ...user, app: user?.appId, ...fields, ...user?.partTimeTitleDeptInfo?.[0] } ||
                        {},
                    [
                        'app',
                        'avatar',
                        'name',
                        'userId',
                        'teamId',
                        'e_dept_full_name',
                        'e_position',
                        'cardNumber',
                        'unitName',
                        'unitDeptId',
                        'deptId',
                        'deptName',
                        'titleName',
                    ]
                );
            } catch (e) {
                logger.error('im =>', e);
                const employee = await this.chatManager.user();
                // console.log(employee, 8888);
                return employee || {};
            }
        }

        // throw new Error('chatManager is null');
        return {};
    }

    /**
     * 获取用户信息
     * @param {User} employee
     * @return {*}  {Promise<Partial<Employee>>}
     */
    getEmployeeDetail(employee: User): Promise<any> {
        return new Promise((resovle, reject) => {
            if (this.chatManager) {
                this.chatManager.service.employee
                    .fetchUserEbookDetail({
                        appId: employee.appId,
                        userId: employee.userId,
                        teamId: employee.teamId,
                        isMulti: true,
                    })
                    .then((user) => {
                        if (user) {
                            this.chatManager?.store.employee
                                .setRawUser(convertUserToString(employee), user)
                                .then((res) => {
                                    Bus.emit('employee:user-update', res);
                                });
                            resovle(user);
                        } else {
                            reject(new Error('get failed'));
                        }
                    })
                    .catch((e: any) => {
                        logger.error(`getEmployeeDetail--error`, e.message, e.code);
                        reject(new Error('网络不可用，请检查您的网络状态'));
                    });
            } else {
                reject(new Error('get failed'));
            }
        });
    }

    /**
     * 获取部门信息
     * @param {GetEbookDeptReq} opt
     * @return {*}  {(Promise<Department | null>)}
     * @memberof ImService
     */
    async getDeptInfo(opt: GetEbookDeptReq): Promise<Department | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            return await this.chatManager.method.department.getDeptInfo(opt);
        } catch (e) {
            logger.error('im =>getDeptInfo', e.code, e.message);
            // return e.code || -1;
            // throw e;
            return null;
        }
    }

    /**
     * 刷新部门信息版本号
     * @return {*}  {Promise<void>}
     * @memberof ImService
     */
    async refreshDeptVersion(): Promise<void> {
        if (this.chatManager) {
            await this.chatManager.method.department.refreshVersion();
        }
    }

    /**
     * 加入群聊 在没有开启群验证的情况下
     */
    async joinGroup(gid: string, operator: UID): Promise<number> {
        if (!this.chatManager) {
            return -1;
        }
        try {
            const options = {
                gid: gid,
                operator: {
                    ...operator,
                } as any,
            };
            logger.debug('joinGroup ====>', options);
            const result = await this.chatManager.method.group.applyToGroup(options);
            return 0;
        } catch (e) {
            logger.error('im =>', e.code, e);
            return e.code || -1;
        }
    }

    /**
     * 获取群成员
     * @param sessionId
     * @param force
     */
    async getGroupRosters(sessionId: string, force: boolean): Promise<Partial<GroupRoster>[]> {
        if (!this.chatManager) {
            return [];
        }
        try {
            return (await this.chatManager.method.group.members(sessionId, force)) || [];
        } catch (e) {
            // 不在群中
            if (e.code.toString() === '1103009') {
                return [];
            }
            logger.error('im: getGroupRosters =>', e);
            return [];
        }
    }

    /**
     * 检查是否在群里
     * @param gids 群id
     */
    async checkInGroup(gids: string[]): Promise<{ [key: string]: boolean }> {
        if (!this.chatManager) {
            return {};
        }
        try {
            const groupIds = gids.map((gid) => {
                return { groupId: gid };
            });
            let groupsInfo = await getGroupInfo({
                groupIds,
            }).catch((err) => {
                console.error('checkInGroup-err', err);
                throw err;
            });
            const resultList = groupsInfo.groups || [];
            const data: { [key: string]: boolean } = {};
            // flag： 群与当前用户关系  1-群不存在 2-不在群中 3-在群中
            resultList.forEach((group: EGroupGroupInfo) => {
                data[group.gid] = group.flag === 3;
            });
            return data;
        } catch (e) {
            logger.error('im: checkInGroup =>', e);
            throw e;
        }
    }

    /**
     * 获取群成员列表
     * 如果会话上带有群成员数据，则不必再请求接口拉取数据
     * @param sessionId
     * @param force {boolean} 强制获取新数据
     */
    async getGroupRosterInfo(sessionId: string, force: boolean): Promise<Partial<GroupRoster>[]> {
        if (!this.chatManager) {
            return [];
        }
        try {
            let members = (await this.chatManager.method.group.members(sessionId, force)) || [];
            // console.log('强制获取新数据', members, !force);
            if (!members || members.length === 0) {
                return [];
            }
            return mapRosters(members);
        } catch (e) {
            logger.error('im: getGroupRosterInfo =>', e);
            return [];
        }
    }

    /**
     * 修改群名称
     * @param sessionId 会话号
     * @param name 群名称
     */
    async setGroupName(sessionId: string, name: string): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const field = GroupModifyField.FILED_NAME_B;
            await this.chatManager.method.group.set(sessionId, field, { name });
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 修改组介绍
     * @param sessionId 会话号
     * @param intro 组介绍
     */
    async setGroupIntro(sessionId: string, intro: string): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const field = GroupModifyField.FILED_INTRO_B;
            await this.chatManager.method.group.set(sessionId, field, { intro });
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 从群中移除成员
     * @param sessionId 会话ID
     * @param members 移聊的人员
     */
    async removeGroupMembers(sessionId: string, members: UID[]): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.group.removeMembers(sessionId, members);
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 群成员退出群聊
     * @param sessionId 会话ID
     */
    async exitGroup(sessionId: string): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.group.leave(sessionId);
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 设置管理员
     * @param sessionId 会话ID
     * @param uid 用户
     * @param members 管理信息
     */
    async setGroupAdmin(
        sessionId: string,
        // uid: UID,
        members: UID[],
        action: GroupAdminSetAction
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const options: Omit<GroupAdminSetReqBody, 'nickName'> = {
                gid: sessionId,
                action: action,
                // uid: uid,
                members: members,
            };
            return await this.chatManager.method.group.setAdmin(options);
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 转让群主
     * @param sessionId 会话ID
     * @param newOwner
     */
    async changeGroupOwner(sessionId: string, newOwner: UID): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const options: Omit<GroupAdminSetReqBody, 'nickName'> = {
                gid: sessionId,
                action: 'SET_OWNER',
                uid: newOwner,
            };
            return await this.chatManager.method.group.setAdmin(options);
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 群主退出群聊，并转让群主
     * @param sessionId 会话ID
     * @param newOwner 新群主
     */
    async changeGroupOwnerAndExit(sessionId: string, newOwner: UID): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            return await this.chatManager.method.group.leave(sessionId, newOwner);
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 解散群
     * @param sessionId
     */
    async disbandGroup(sessionId: string): Promise<boolean> {
        try {
            if (this.chatManager) {
                return this.chatManager.method.group.disband(sessionId);
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    /**
     * 邀请入群申请
     * @param sessionId
     * @param uids
     * @param noticeType // application | application_noreply 不发再次申请 | 拒绝后的 reply 可以再次申请
     * @param reason
     */
    // eslint-disable-next-line max-params
    async inviteAddGroupApply(
        sessionId: string,
        uids: UID[],
        noticeType: string,
        reason?: string
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            const clientType = this.clientType;
            const chatManager = this.chatManager;
            // const group = await chatManager.store.group.get(sessionId);
            const [group] = await chatManager.service.group.groupGetInfo({
                type: GroupGetType.BASIC,
                groupIds: [{ groupId: sessionId }],
            });
            // todo 发送加群申请不确定是发一次多人申请审批，还是按人次发送多次申请审批
            await uids.map(async (invitee: UID) => {
                await chatManager.service.open.pushWithParams({
                    id: generateMessageId(),
                    appKey: 'b96a8ab3197544e68c9919667afe9f41',
                    applicationKey: 'uUyYexheoyRkjp6DpVtx',
                    infox: {
                        noticeType: noticeType || 'application_noreply',
                        applyState: ApplyGroupState.INIT, // 申请状态
                        from: JSON.stringify(invitee), // 被邀请人
                        fromUsername: invitee.name,
                        // inviter: JSON.stringify(inviter), // 邀请人
                        // group: JSON.stringify({ gid: group.gid, name: group.name }),
                        groupId: group.gid,
                        groupName: group.name,
                        reason: reason || '',
                        deeplink: '',
                    },
                    tos: [group.owner],
                    appParams: { minVersion: '000.000.000.000' },
                    // @ts-ignore
                    client: clientType,
                    title: i18n.t('chat:apply.apply-title', {
                        first: invitee.name,
                        second: group.name,
                    }),
                    content: i18n.t('chat:apply.apply-content'),
                });
            });
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 申请加群
     * @param sessionId
     * @param uids
     * @param noticeType
     * @param reason
     */
    // eslint-disable-next-line max-params
    async applyAddGroup(sessionId: string, uids: UID[], noticeType: string, reason?: string) {
        return this.inviteAddGroupApply(sessionId, uids, noticeType, reason);
    }

    /**
     * 更新加群审批状态
     * @param sessionId
     * @param message
     * @param state
     */
    async updateAddGroupApplyState(
        sessionId: string,
        message: any,
        state: ApplyGroupState
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(
                sessionId,
                message.mid,
                {
                    infox: { ...message.infox, applyState: state },
                },
                true
            );
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 更新外部联系人审批通过状态
     * @param sessionId
     * @param message
     * @param state
     */
    async updateApplyExternalContactsList(
        sessionId: string,
        message: any,
        state: number
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(
                sessionId,
                message.mid,
                {
                    infox: { ...message.infox, applyState: state },
                },
                true
            );
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    // 申请加入团队
    async updateTeamApplyState(
        sessionId: string,
        message: any,
        state: ApplyTeamState
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(sessionId, message.mid, {
                infox: { ...message.infox, applyState: state },
            });
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    /**
     * 审批添加应用
     * @param sessionId
     * @param message
     * @param state
     */
    async updateAppStoreApplyState(
        sessionId: string,
        message: any,
        state: ApplyAppStoreState
    ): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.message.updateLocal(sessionId, message.mid, {
                infox: { ...message.infox, applyState: state },
            });
            return true;
        } catch (e) {
            logger.error('im =>', e);
            return false;
        }
    }

    // 拒绝申请者入群
    async rejectAddGroupApply(gid: string, invitee: UID) {
        if (!this.chatManager) {
            return false;
        }
        try {
            const clientType = this.clientType;
            const chatManager = this.chatManager;
            const group = await chatManager.store.group.get(gid);
            const groupOwner = await this.chatManager.service.employee.getUserEbookDetail({
                appId: group.owner?.app,
                userId: group.owner?.pin,
                teamId: group.owner?.teamId,
                isMulti: true,
            });
            await chatManager.service.open.pushWithParams({
                id: generateMessageId(),
                appKey: 'b96a8ab3197544e68c9919667afe9f41',
                applicationKey: 'uUyYexheoyRkjp6DpVtx',
                from: group.owner,
                infox: {
                    noticeType: 'reply', // reply | application_noreply 不发再次申请 | reply 申请 | 再次申请
                    applyState: ApplyGroupState.REJECTED, // 申请状态
                    from: JSON.stringify(employeeToUid(groupOwner as Employee)), // 被邀请人
                    fromUsername: groupOwner?.name,
                    groupId: group.gid,
                    groupName: group.name,
                    reason: '',
                    deeplink: '',
                },
                reason: 'test',
                tos: [invitee],
                appParams: { minVersion: '000.000.000.000' },
                // @ts-ignore
                client: clientType,
                title: `${i18n.t('chat:apply.refuse_you_add')}`,
                content: i18n.t('chat:apply.refuse_content'),
            });
            return true;
        } catch (e) {
            logger.error('im inviteAddGroupApply =>', e);
            return false;
        }
    }

    /**
     * 邀请成员进入群
     * @param sessionId 会话ID 当前群ID
     * @param invitees 添加的群人员
     * @param invitation 邀请语
     */
    async inviteUser(
        sessionId: string,
        invitees: UID[],
        invitation?: string | undefined
    ): Promise<{ success: boolean; data?: any }> {
        if (!this.chatManager) {
            return { success: false };
        }
        if (!invitees || invitees.length === 0) {
            return { success: false };
        }
        try {
            const result = await this.chatManager.method.group.invite(
                '',
                sessionId,
                invitees,
                invitation
            );
            logger.debug('invite user =>', result);
            return { success: result };
        } catch (e) {
            logger.error('im inviteUser =>', e);
            const data = lodashGet(e, '0', {}) as any;
            if (data && data.code === 1103036) {
                return { success: false, data };
            }
            return { success: false };
        }
    }

    /**
     * 允许入群
     * @param sessionId
     * @param invitees
     * @param invitation
     * @param applyRecordId
     * @returns
     */
    async applyUser(
        message: any,
        invitees: UID[],
        invitation?: string | undefined
    ): Promise<{ success: boolean; data?: any }> {
        if (!this.chatManager) {
            return { success: false };
        }
        if (!invitees || invitees.length === 0) {
            return { success: false };
        }
        const gid = message?.infox?.groupId;
        const applyRecordId = message?.infox?.applyRecordId || '';
        if (!gid) {
            return { success: false };
        }
        try {
            const result = await this.chatManager.method.group.invite(
                applyRecordId,
                gid,
                invitees,
                invitation
            );
            logger.debug('apply user =>', result);
            return { success: result };
        } catch (e) {
            const data = lodashGet(e, '0', {}) as any;
            if (data && data.code === 1103036) {
                return { success: false, data };
            } else if (typeof e === 'string') {
                const err = JSON.parse(e);
                return { success: false, data: err };
            }
            return { success: false };
        }
    }

    /**
     * 拒绝入群
     * @param gid
     * @param message
     */
    async refuseUser(
        currentEmployee: Employee,
        gid: string,
        message: any
    ): Promise<{ success: boolean; data?: any }> {
        if (!this.chatManager) {
            return { success: false };
        }
        message.infox = message.infox || {};
        try {
            const from = JSON.parse(message.infox.from);
            const applyRecordId = message.infox.applyRecordId || '';
            const clientType = this.clientType;
            // @ts-ignore
            await this.chatManager.method.group.refuse(applyRecordId, gid, {
                id: generateMessageId(),
                appKey: 'b96a8ab3197544e68c9919667afe9f41',
                applicationKey: 'uUyYexheoyRkjp6DpVtx',
                from: employeeToUid(currentEmployee as Employee),
                infox: {
                    ...message.infox,
                    form: JSON.stringify(employeeToUid(currentEmployee as Employee)),
                    fromUsername: currentEmployee.name,
                    noticeType: 'reply',
                    applyState: ApplyGroupState.REJECTED, // 申请状态
                },
                reason: '',
                tos: [from],
                appParams: { minVersion: '000.000.000.000' },
                // @ts-ignore
                client: clientType,
                title: `${i18n.t('chat:apply.refuse_you_add')}`,
                content: i18n.t('chat:apply.refuse_content'),
            });
            return { success: true };
        } catch (e) {
            const data = lodashGet(e, '0', {}) as any;
            if (data && data.code === 1103036) {
                return { success: false, data };
            } else if (typeof e === 'string') {
                const err = JSON.parse(e);
                return { success: false, data: err };
            }
            return { success: false };
        }
    }

    generateSingleSessionId(from: Employee, to: UID, type?: any): string {
        if (!from || !to) {
            return '';
        }
        // 缓存拉取session
        return generateSessionId({
            type: type || SessionType.SINGLE,
            to: to,
            from: employeeToUid(from),
        });
    }

    /**
     * 强制重新拉取会话
     */
    async reloadSession(session: Session): Promise<Session | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            // 重新拉取session 防止 info 信息为 {}
            const newSession = await this.chatManager.store.session.get(session.sessionId);
            if (newSession && !newSession.timestamp) {
                newSession.timestamp = Date.now();
            }

            if (newSession && isInValidSessionInfo(newSession)) {
                if (newSession?.isGroup) {
                    const groupInfo = await this.chatManager.store.group.get(session.sessionId);
                    if (groupInfo.name) {
                        newSession.info = Object.assign(session.info, groupInfo);
                    } else {
                        let groupsInfo = await getGroupInfo({
                            groupIds: [{ groupId: session.sessionId || session.gid || '' }],
                        }).catch((err) => {
                            console.error('reloadSession-err', err);
                            return { groups: [] };
                        });
                        newSession.info = Object.assign(
                            groupInfo,
                            groupsInfo.groups?.length && groupsInfo.groups[0]
                        );
                    }
                } else if (newSession?.isSingle) {
                    let uid = newSession.uid;
                    if (uid) {
                        // "c8XZvL2kawqD74UHdfgjs:me:wFNptZXmu7PGvRjsclqRg:eopen-push_uUyYexheoyRkjp6DpVtx:dd.notice:focus.bot"
                        const employee = await this.chatManager.store.employee.get(uid);
                        if (employee.name) {
                            newSession.info = { ...(session.info || {}), ...employee };
                        }
                    }
                }
            }
            return (lodashPick(newSession, sessionAttrs) as Session) || null;
        } catch (e) {
            logger.error('im reloadSession=>', e);
            return null;
        }
    }

    /**
     * 加载通知会话数据
     * @param sid
     */
    async loadNoticeSession(sid: string): Promise<Session | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            const session = await this.chatManager.store.session.get(sid);
            if (session && !session.timestamp) {
                session.timestamp = Date.now();
            }
            if (isInValidSessionInfo(session as any)) {
                return null;
            }
            logger.debug('loadNoticeSession =>', session);
            return session || null;
        } catch (e) {
            logger.error('im =>', e);
            return null;
        }
    }

    async getSingleSession(currentEmployee: Employee, user: UID): Promise<Session | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            const sessionId = this.generateSingleSessionId(currentEmployee, user);
            // 缓存拉取session
            let session: any = await this.chatManager.method.session.get({ sessionId } as any);
            logger.debug('getSingleSession 1111=>', session);
            if (!session.timestamp) {
                session.timestamp = Date.now();
            }
            if (isInValidSessionInfo(session || {})) {
                const employee = await this.chatManager.store.employee.get(user);
                if (employee?.name) {
                    session.info = Object.assign(session.info || {}, employee);
                }
            }
            logger.debug('getSingleSession 2222=>', session);
            if (isInValidSessionInfo(session || {})) {
                return null;
            }
            logger.debug('getSingleSession =>', JSON.stringify(session.info), session.sessionType);
            if (session && !session.isSingle) {
                return null;
            }
            logger.debug('getSingleSession =>', session);
            return session || null;
        } catch (e) {
            logger.error('im =>', e);
            return null;
        }
    }

    /**
     * 创建一个会话
     */
    async createSingleSession(user: UID, type?: any): Promise<Session | null> {
        if (!this.chatManager) {
            console.log('!this.chatManager');
            return null;
        }
        try {
            const sessionGroup = 1;
            const createOption: any = {
                sessionType: type || SessionType.SINGLE,
                uid: user,
                timestamp: Date.now(),
            };
            // logger.debug('createSingleSession 00=>', createOption);
            const sessionAttr = await this.chatManager.method.session.add(
                sessionGroup,
                createOption,
                true
            );
            console.log('createSingleSession =>', user, sessionAttr);
            let session: any = sessionAttr;
            if (session && !session.timestamp) {
                session.timestamp = Date.now();
            }
            console.log('createSingleSession =>', user, sessionAttr, session);
            // logger.debug('createSingleSession 11=>', session);
            if (isInValidSessionInfo(sessionAttr || {})) {
                console.log('createSingleSession 22=>', session);
                // 会话 info 数据不正常 重新拉取
                const employee = await this.chatManager.store.employee.get(user);
                if (employee?.name) {
                    session.info = Object.assign(session.info || {}, employee);
                }
                console.log('createSingleSession 33=>', session);
            }
            if (isInValidSessionInfo(session || {})) {
                console.log('createSingleSession 44=>', session);
                return null;
            }

            // [修复bug]创建新会话,没有显示历史消息 --hujun
            if (!session.lastMsg) {
                const loadHistoryResult = await this.loadHistory(session.sessionId);
                let loadHistoryMsg = loadHistoryResult?.messages;

                if (loadHistoryMsg && loadHistoryMsg.length > 0) {
                    loadHistoryMsg = loadHistoryMsg.sort((a: any, b: any) => {
                        return a.mid - b.mid;
                    });
                    const lastMsg = loadHistoryMsg[loadHistoryMsg.length - 1] as any;
                    if (
                        lastMsg.type === ChatMessageType.TEXT ||
                        lastMsg.type === ChatMessageType.IMAGE ||
                        lastMsg.type === ChatMessageType.VOICE ||
                        lastMsg.type === ChatMessageType.VIDEO ||
                        lastMsg.type === ChatMessageType.FILE ||
                        lastMsg.type === ChatMessageType.TEMPLATE2 ||
                        lastMsg.type === ChatMessageType.SYS
                    ) {
                        session.lastMsg = lastMsg;
                    }
                }
                console.log('createSingleSession session.lastMsg=>', session.lastMsg);
            }
            console.log('---lyj---createSingleSession', session);
            return session;
        } catch (e) {
            // 1v1阅后即焚未注册
            if ((e as any).code === '2') {
                AntMessage.error('对方版本不支持阅后即焚', 2);
            }
            logger.error('im =>', e);
            return null;
        }
    }

    /**
     * 更新session数据
     * @param sessionId
     * @param options
     * @returns
     */
    async updateSession(sessionId: string, options: Partial<Session>): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            await this.chatManager.method.session.updateSessionExt(sessionId, options);
            return true;
        } catch (e) {
            logger.error('im => updateSession error: ', e);
            return false;
        }
    }

    async getGroupInfo(gid: string): Promise<Group | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            // const groupInfo = await this.chatManager.store.group.get(gid);
            const [groupInfo] = await this.chatManager.service.group.groupGetInfo({
                type: GroupGetType.DETAIL,
                groupIds: [{ groupId: gid }],
            });
            if (groupInfo) {
                const owner = groupInfo.owner;
                const employee = await this.chatManager.store.employee.get(owner);
                groupInfo.owner = Object.assign(owner, {
                    avatar: employee.avatar || '',
                    name: employee.name,
                });
            }
            return groupInfo as Group;
        } catch (e) {
            logger.error('im: getGroupInfo =>', e);
            return null;
        }
    }

    // async getGroupSession(sessionId: string): Promise<Session | null> {
    //     if (!this.chatManager) {
    //         return null;
    //     }
    //     try {
    //         const result = await this.chatManager.store.session.get(sessionId);
    //         return result || null;
    //     } catch (e) {
    //         logger.error('im =>', e);
    //         return null;
    //     }
    // }

    async getGroup(gid: string): Promise<Group | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            return await this.chatManager.store.group.get(gid);
        } catch (e) {
            logger.error('im: getGroup =>', e);
            return null;
        }
    }

    async launchGroupSession(sid: string): Promise<Session | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            const sessionGroup = 1;
            const createOption: any = {
                sessionType: SessionType.GROUP,
                gid: sid,
                canSearch: 1,
            };
            const sessionAttr = await this.chatManager.method.session.add(
                sessionGroup,
                createOption,
                true
            );
            let session: any = sessionAttr;
            if (session && !session.timestamp) {
                session.timestamp = Date.now();
            }
            if (isInValidSessionInfo(sessionAttr)) {
                // 重新拉取session 防止 info 信息为 {}
                session = await this.reloadSession(sessionAttr);
            }
            if (isInValidSessionInfo(session as any)) {
                return null;
            }

            // [修复bug]创建新会话,没有显示历史消息 --hujun
            if (!session.lastMsg) {
                const loadHistoryResult = await this.loadHistory(session.sessionId);
                const loadHistoryMsg = loadHistoryResult?.messages;

                if (loadHistoryMsg && loadHistoryMsg.length > 0) {
                    const lastMsg = loadHistoryMsg[loadHistoryMsg.length - 1] as any;
                    if (
                        lastMsg.type === ChatMessageType.TEXT ||
                        lastMsg.type === ChatMessageType.IMAGE ||
                        lastMsg.type === ChatMessageType.VOICE ||
                        lastMsg.type === ChatMessageType.VIDEO ||
                        lastMsg.type === ChatMessageType.FILE ||
                        lastMsg.type === ChatMessageType.TEMPLATE2 ||
                        lastMsg.type === ChatMessageType.SYS
                    ) {
                        session.lastMsg = lastMsg;
                        // logger.debug('createSingleSession session.lastMsg=>', session.lastMsg);
                    }
                    logger.debug('createSingleSession lastMsg=>', lastMsg);
                }
            }

            return session;
        } catch (e) {
            logger.error('im: launchGroupSession =>', e);
            return null;
        }
    }

    /**
     *  创建群
     * @param invitees 邀请人
     * @param invitation 邀请语
     * @param options
     */
    async createGroupSession(
        invitees: UID[],
        invitation?: string,
        options?: Partial<GroupSessionCreateOptions>
    ): Promise<Session | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            const sessionGroup = 1;
            const createOption: SessionCreateOptions = {
                sessionType: SessionType.GROUP,
                canSearch: 1,
                invitation: invitation,
                invitees: invitees,
            };

            if (options?.name) {
                createOption.name = options.name;
            }
            if (options?.intro) {
                createOption.intro = options.intro;
            }
            if (options?.kind && options.subKind) {
                createOption.kind = options.kind;
                createOption.subKind = options.subKind;
            }
            const session = await this.chatManager.method.session.add(
                sessionGroup,
                createOption,
                true
            );
            if (session && !session.timestamp) {
                session.timestamp = Date.now();
            }
            // logger.debug('createSingleSession =>', JSON.stringify(session.info));
            if (isInValidSessionInfo(session || {})) {
                const groupInfo = await this.chatManager.store.group.get(session.sessionId);
                if (groupInfo?.name) {
                    session.info = Object.assign(session.info, groupInfo);
                }
            }
            if (isInValidSessionInfo(session || {})) {
                return null;
            }
            return session || null;
        } catch (e) {
            logger.error('im =>', e);
            return null;
        }
    }

    /**
     * 查询历史消息记录
     * 需要排序历史记录 消息包括 会话消息（），系统通知消息（未排序）
     */
    async loadHistory(
        sessionId: string,
        startMid = -1,
        options?: Partial<LoadHistoryOptions>
    ): Promise<LoadHistoryResp | undefined> {
        if (!this.chatManager) {
            return {} as any;
        }
        const opts = {
            offset: 0,
            sessionFilter: {},
            ...options,
        };
        let result: any;
        try {
            if (sessionId.includes(CHAT_NOTICE.AI_PIN)) {
                result = await this.chatManager?.method.message.loadNoticeHistory(1, startMid, {
                    loadSys: 0,
                    count: 10,
                    queryType: 2, // queryType: 1 | 2; 向上 或 向下
                    ...opts,
                });
                result.messages = result.messages.filter((item: any) => !isGroupNoticeMsg(item));
            } else {
                result = await this.chatManager?.method.message.loadHistory(
                    1,
                    sessionId,
                    startMid,
                    {
                        loadSys: 1,
                        count: chatConfig['chat-message-page-count'],
                        queryType: 2, // queryType: 1 | 2; 向上 或 向下
                        mergeMessage: true,
                        ...options,
                    }
                );
            }
            let messages: ChatMessage[] = result?.messages || [];
            messages = messages
                .filter((item) => {
                    // @ts-ignore
                    return item.type !== 'revoke_message';
                })
                .map((message: ChatMessage, index: number) => {
                    const prevMessage: any = index > 0 ? messages[index - 1] : null;
                    setMessageGroupId(message, prevMessage);
                    return message;
                });
            // const sysMessage = result.sys.filter((item: ChatMessage) => {
            //     return item.category === MessageEmitCategory.SYSTEM;
            // });
            if (result.sys && result.sys.length) {
                const sysMessage = result.sys.reduce((result: ChatMessage[], item: ChatMessage) => {
                    if (item.category === MessageEmitCategory.SYSTEM) {
                        if (checkGroupSysMessage({ message: item })) {
                            item.history = true;
                            result.push(item);
                        } else if (checkSingleSysMessage({ message: item })) {
                            item.history = true;
                            result.push(item);
                        }
                    }
                    return result;
                }, []);
                messages = messages.concat(sysMessage);
            }
            // console.log('历史消息', messages);
            return { messages: messages, end: result.end };
        } catch (e) {
            logger.error('im =>', e);
            return {} as any;
        }
    }

    /**
     * 确认好友关系
     * @param user
     * @param status
     */
    async confirmFriendApply(user: UID, status: RosterApplyStatus, accessPhone = ''): Promise<any> {
        if (!this.chatManager) {
            return [false, null];
        }
        try {
            const options = {
                labelId: '',
                status,
                accessPhone,
                ...user,
            };
            await this.chatManager.method.apply.confirmFriendApply(options);
            return [true, null];
        } catch (e) {
            logger.error('imConfirmFriendApply =>', e, 1111, e.message);

            return [null, e.message];
        }
    }

    // 发送添加好友请求
    async applyFriend(user: UID, info: string, accessPhone = ''): Promise<any> {
        if (!this.chatManager) {
            return [false, null];
        }
        try {
            const options = {
                accessPhone,
                labelId: '',
                info: info,
                ...user,
            };
            await this.chatManager.method.apply.applyFriend(options);
            return [true, null];
        } catch (e) {
            logger.error('applyFriend =>', e);
            return [null, e.message];
            // if (e.code.toString() === '1103014') {
            //     // return new Error('more-times');
            //     return 'more-times';
            // }
            // return e.message;
        }
    }

    // 新申请数量
    async getMessageEmojiReply(sessionId: string, mid: number): Promise<ChatMessageExtBody | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            return await this.chatManager.method.messageExt.getEmojiReply(sessionId, mid);
        } catch (e) {
            logger.error('im =>', e);
            return null;
        }
    }

    async getMessageExt(sessionId: string, mid: number): Promise<ChatMessageExtBody | null> {
        if (!this.chatManager) {
            return null;
        }
        try {
            return await this.chatManager.method.messageExt.getMessageExt(sessionId, mid);
        } catch (e) {
            logger.error('im =>', e);
            return null;
        }
    }

    // async getSolitaireMessageExt(
    //     sessionId: string,
    //     mid: number
    // ): Promise<ChatMessageExtBody | null> {
    //     if (!this.chatManager) {
    //         return null;
    //     }
    //     try {
    //         return await this.chatManager.method.messageExt.getSolitaireMessageExt(sessionId, mid);
    //     } catch (e) {
    //         logger.error('im =>', e);
    //         return null;
    //     }
    // }

    // 新申请数量
    async getNewContactsCount(): Promise<number> {
        if (!this.chatManager) {
            return 0;
        }
        try {
            return await this.chatManager.method.apply.applyCount();
        } catch (e) {
            logger.error('getNewContactsCount =>', e);
            return 0;
        }
    }

    // 设置已查看新申请数量
    async getCheckedApplyCount(): Promise<number> {
        if (!this.chatManager) {
            return 0;
        }
        try {
            return await this.chatManager.method.apply.checkedApplyCount();
        } catch (e) {
            logger.error('getCheckedApplyCount =>', e);
            return 0;
        }
    }

    // 新的联系人
    async getNewContacts(): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            const contacts: any = await this.chatManager.method.apply.loadApplies(); // cache时 当请求applies()
            return contacts;
        } catch (e) {
            logger.error('getNewContacts =>', e);
            return [];
        }
    }

    // 历史新的申请列表数据
    async getAppliesNewContacts(): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            const contacts = await this.chatManager.method.apply.applies();
            return contacts;
        } catch (e) {
            logger.error('getAppliesNewContacts =>', e);
            return [];
        }
    }

    // 监听离线时获取新的好友申请数量 并更新本地
    async updateNewContactCount(): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            await this.chatManager.method.apply.applyCount();
            this.chatManager.method.apply.checkedApplyCount();
        } catch (e) {
            logger.error('imupdateNewContactCount =>', e);
            return [];
        }
    }

    // 组列表
    async getGroupList(ebookType?: string): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            const groupList: any = await this.chatManager.method.group.getGroupList(ebookType);
            console.log('groupList====>', ebookType, groupList);
            return groupList || [];
        } catch (e) {
            logger.error('getGroupList =>', e);
            return [];
        }
    }

    // 本地缓存的组成员列表
    async getDbGroup(options: { ebookType?: string; gid?: string }): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            return await this.chatManager.method.group.getDbGroup(options);
        } catch (e) {
            logger.error('getDbGroup =>', e);
            return [];
        }
    }

    // 组成员列表
    async saveBasicGroupToDB(gid: string, basicGroup: any[]): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            const groupList: any = await this.getDbGroup({});
            if (!groupList.length) {
                return [];
            }
            return await this.chatManager.method.group.setGroupListToDB(groupList, gid, basicGroup);
        } catch (e) {
            logger.error('saveBasicGroupToDB =>', e);
            return [];
        }
    }

    // 批量获取人员详情
    async getChangeUserDetailList(users: userDetailListPrams[]) {
        if (!this.chatManager) {
            return [];
        }
        try {
            const UserDetailList = await this.chatManager.service.employee.getChangeUserDetailList({
                ebookVersionReqs: users,
            });
            return UserDetailList || [];
        } catch (e) {
            logger.error('getChangeUserDetailList =>', e);
            return [];
        }
    }

    // 我的联系人
    async getExternalContacts(): Promise<Friend[] | null> {
        if (!this.chatManager) {
            return [];
        }
        try {
            // let friendVersion: any = await this.chatManager.method.friend.getFriendVersion();
            let ExternalContactsList: any = await this.chatManager.method.friend.getFriendsByGroup(
                {}
            );
            console.log(ExternalContactsList, 'ExternalContactsList');
            // 根据id（teYfjVyztU5YJMagPeQAe:gwork:mS2CKU8HN3UFxdliJ1AxR）去重
            const uniqList: any = uniqBy(ExternalContactsList, 'id');
            console.log('我的联系人', uniqList);
            return uniqList;
        } catch (e) {
            logger.error('getExternalContacts =>', e);
            return null;
        }
    }

    // 删除联系人
    async setRemoveFriend(param: { pin: string; app: string; teamId: string }): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            await this.chatManager.method.friend.removeFriend(param);
            return true;
        } catch (e) {
            logger.error('setRemoveFriend =>', e);
            return null;
        }
    }

    // 搜索联系人关系
    async getContactShip(targets: UID[]) {
        if (!this.chatManager) {
            return [];
        }
        try {
            return await this.chatManager.method.friend.getRelationships(targets);
        } catch (e) {
            logger.error('im =>', e);
            return [];
        }
    }

    async getAllSessionFromStore(): Promise<Session[]> {
        if (!this.chatManager) {
            return [];
        }
        try {
            const sessions = await this.chatManager.store.session.findAll();
            for (let i = 0; i < sessions.length; i++) {
                const s = sessions[i];
                if (isInValidSessionInfo(s)) {
                    const newSession = await this.reloadSession(s);
                    if (newSession) {
                        sessions[i] = newSession;
                    }
                }
            }
            const list = this.sortSessions((sessions || []) as Session[]);
            return list;
        } catch (e) {
            logger.error('im =>', e);
            return [];
        }
    }

    async getSessionFromStore(sessionId: string): Promise<Session | undefined> {
        if (!this.chatManager) {
            return;
        }
        try {
            return await this.chatManager.store.session.get(sessionId);
        } catch (e) {
            logger.error('im =>', e);
            return;
        }
    }

    async getAllSessions(
        props?: Partial<SyncReqBody>,
        sync?: boolean,
        sortFn?: Function
    ): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        try {
            const sessions: any = await this.chatManager.method.session.sessions(props, sync);
            for (let s of sessions) {
                if (isUnnecessarySession(s)) {
                    continue;
                }
                // 如果 是无效的会话，重新拉取会话数据
                if (isInValidSessionInfo(s)) {
                    const newSession = await this.chatManager.method.session.get(s);
                    Object.assign(s, newSession);
                }
            }
            let list;
            if (sortFn && typeof sortFn === 'function') {
                list = sortFn((sessions || []) as Session[]);
            } else {
                list = this.sortSessions((sessions || []) as Session[]);
            }
            return list.filter((s: any) => !isInValidSessionInfo(s));
        } catch (e) {
            logger.error('im =>', e);
            return [];
        }
    }

    async getAllSessionList(
        props?: Partial<SyncReqBody>,
        sync?: boolean,
        loadMessage = false
    ): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        let sessions: any = null;
        try {
            if (loadMessage) {
                sessions = await this.chatManager.method.session.sessionAndLoad(props, sync, {
                    offset: 0,
                    count: 20,
                });
            } else {
                sessions = await this.chatManager.method.session.sessions(props, sync);
            }
            const allSessionList = this.decorateSessionList(sessions, false);
            return allSessionList;
        } catch (e) {
            logger.error('im =>', e);
            return [];
        }
    }

    async loadSessionPageList(
        props?: Partial<SyncReqBody>,
        sync?: boolean,
        options?: Partial<LoadSessionOptions>
    ): Promise<any> {
        if (!this.chatManager) {
            return [];
        }
        const opts = { count: 20, offset: 0, sync: false, ...options };
        const rest = await this.chatManager.method.session.sessionAndLoad(props, sync, options);
        const restRes = await this.decorateSessionList(rest as any, true);
        return {
            allSessionList: restRes,
            page: opts.offset / opts.count + 1,
            end: rest.length < opts?.count,
        };
    }

    async getNoticeSessionList(
        props?: Partial<SyncReqBody>,
        sync?: boolean,
        options?: Partial<LoadSessionOptions>
    ): Promise<void> {
        if (!this.chatManager) {
            return;
        }
        this.chatManager.method.session
            .sessionAndLoad(props, sync, {
                count: 0,
                offset: 0,
                sessionType: SessionType.NOTICE,
            })
            .then(async (res) => {
                const sessions = await this.decorateSessionList(res as any, false);
                Bus.emit('chat:session-update-batch', sessions);
            });
    }

    async decorateSessionList(sessions: Session[], sync = false): Promise<Session[]> {
        if (!this.chatManager) {
            return [];
        }
        let dealSession: Array<Session> = [];
        let allSessionList: Array<Session> = [];
        for (let s of sessions) {
            if (isUnnecessarySession(s)) {
                continue;
            }
            if (isInValidSessionInfo(s)) {
                // 如果 是无效的会话，重新拉取会话数据
                const newSession = await this.chatManager.method.session.get(s);
                if (newSession?.isGroup && !newSession?.info?.name) {
                    dealSession.push(newSession);
                }
                Object.assign(s, newSession);
            }
            const temp = lodashOmit(s, ['groupStore', 'employeeStore', 'profile']) as Session;
            if (!temp?.info?.name) {
                continue;
            }
            if (isAINoticeSession(temp as Session)) {
                temp.info.name = 'AI助手';
                temp.info.nickName = 'AI助手';
            }
            allSessionList.push(temp);
        }
        // 异步处理
        if (dealSession.length) {
            const ids = dealSession.map((s: any) => {
                return {
                    groupId: s.sessionId || s.gid || '',
                };
            });
            let groupsInfo = await getGroupInfo({
                groupIds: ids,
            }).catch((err) => {
                console.error('reloadSession-err', err);
                return { groups: [] };
            });
            const { groups } = groupsInfo;
            const res = dealSession
                .map((s: any) => {
                    const g = (groups || []).find(
                        (g: any) => g.gid === s.sessionId || g.gid === s.gid
                    );
                    if (g) {
                        s.info = g;
                    }
                    return s;
                })
                .filter((s: Session) => s.info && s.info.name);
            if (res.length) {
                if (sync) {
                    allSessionList = allSessionList.concat(res);
                } else {
                    Bus.emit('chat:session-update-batch', res);
                }
            }
        }
        return this.sortSessions((allSessionList || []) as Session[]);
    }

    /**
     * 拉取离线消息
     * @returns
     */
    getOfflineMessage(nopull = false): void {
        if (!this.chatManager) {
            return;
        }
        this.chatManager.method.message
            .fetchOffLineMsgBatchPull(
                { clientMaxPullTimeLimit: getPullTimeLimit(LIMIT_DAY) },
                nopull
            )
            .then();
    }

    async search(
        options: {
            sessionId: string;
            searchKey: string;
            searchType?: ChatMessageType;
            startMid?: number;
            limit?: number;
            composeVideo?: boolean;
            startTimestamp?: number;
            endTimestamp?: number;
            queryType?: 1 | 2;
            incluedRevoke?: boolean;
            incluedUser?: boolean;
            offset?: number;
            desc?: boolean;
            includeBurnAfterRead?: boolean;
            excludeSecret?: boolean;
        },
        delay = false
    ): Promise<unknown[]> {
        const {
            sessionId,
            searchKey,
            searchType,
            startMid,
            limit,
            composeVideo,
            startTimestamp,
            endTimestamp,
            queryType,
            incluedRevoke,
            incluedUser,
            offset,
            desc,
            includeBurnAfterRead,
            excludeSecret,
        } = options;
        if (!this.chatManager) {
            return [];
        }
        const opts = {
            sessionId,
            queryType: queryType,
            startMid,
            messageType: searchType,
            incluedRevoke: !!incluedRevoke,
            incluedUser,
            includeBurnAfterRead,
            composeVideo,
            count: limit,
            startTimestamp,
            endTimestamp,
            offset,
            desc,
            excludeSecret,
        };
        if (delay) {
            return this.chatManager.method.message.searchDelay(searchKey, opts);
        }
        return this.chatManager.method.message.search(searchKey, opts);
    }

    setImLoginState = (state: boolean) => {
        this.imLoginState = state;
    };

    setFocusToken = (focusToken: FocusAuthProps) => {
        this.focusToken = focusToken;
    };

    /**
     * 强制登出im
     * @param force
     */
    logoutIm = (force?: boolean): Promise<void> => {
        // 登出im
        log.debug('imService ==> im start logout ...');
        // eslint-disable-next-line
        let that = this;
        // eslint-disable-next-line
        if (that && that.chatManager) {
            return new Promise((resolve) => {
                that.chatManager
                    ?.logout(force)
                    .then((data: any) => {
                        console.log('imService ==> im logout success');
                        log.debug('imService ==> im logout success', data);
                    })
                    .catch((e: any) => {
                        console.log('imService ==> im logout error');
                        log.debug('imService ==> im logout error', e);
                    })
                    .finally(() => {
                        // 解绑事件
                        console.log('imServicelogout');
                        that.unBindAllEvents();
                        that.setImLoginState(false);
                        log.debug('imService ==> im logout end');
                        resolve();
                    });
            });
        }
        return Promise.resolve();
    };

    loginIm = async (focusToken: FocusAuthProps, force = false, imInit = false): Promise<void> => {
        this.setFocusToken(focusToken);
        this.unBindAllEvents();
        if (!this.chatManager) {
            throw new Error(`no imService`);
        }
        // 登入im
        try {
            await this.chatManager
                // @ts-ignore
                .login({ focusToken }, force, imInit);
            this.bindAllEvents();
            this.setImLoginState(true);
            log.debug('imService im login success =>');
        } catch (e) {
            this.setImLoginState(false);
            log.debug('imService im login error =>', e);
            throw e;
        }
    };

    reloginIm = (): Promise<void> => {
        FocusSDK.printLog('reloginIm', '有网之后重新连');
        // 登入im
        return new Promise((resovle, reject) => {
            if (this.chatManager) {
                this.chatManager
                    .relogin()
                    .then(() => {
                        // 绑定消息事件
                        this.setImLoginState(true);
                        if (!isFocusEnv()) {
                            // im.sdk应该抛出异常，这里现在都是正常
                            Bus.emit('chat:state', {
                                state: ConnectState.READY,
                            });
                        }
                        resovle();
                    })
                    .catch((e) => {
                        console.log('imService im relogin error =>', e);
                        this.setImLoginState(false);
                        FocusSDK.printLog('reloginIm', `${e}-${e.message}-${e.name}`);
                        reject(e);
                    })
                    .finally(() => {
                        console.log('imService im relogin end ...');
                    });
            }
        });
    };

    closeWS = (): Promise<void> => {
        FocusSDK.printLog('closeWS', '没网的时候断开连接');
        // 登入im
        if (this.chatManager) {
            console.log('imService ==> im start closeWS ...');
            this.chatManager
                .closeWS()
                .then(() => {
                    console.log('imService im closeWS success =>');
                })
                .catch((e: any) => {
                    FocusSDK.printLog('closeWS', `${e}-${e.message}-${e.name}`);
                })
                .finally(() => {
                    console.log('imService im closeWS end ...');
                });
        }
        return Promise.resolve();
    };

    /**
     * 应用是否升级
     * @param version
     * @param moduleName
     * @returns
     */
    async isAppUpgraded(version: string, moduleName = ''): Promise<boolean> {
        if (!this.chatManager) {
            return false;
        }
        try {
            return this.chatManager.method.setting.isAppUpgraded(version, moduleName);
        } catch (err) {
            logger.error('im => isAppUpgrade: error', err);
            return false;
        }
    }

    async getAppModuleVersion(moduleName = ''): Promise<string> {
        if (!this.chatManager) {
            return '';
        }
        try {
            return this.chatManager.method.setting.getAppVersion(moduleName);
        } catch (err) {
            logger.error('im => isAppUpgrade: error', err);
            throw err;
        }
    }

    /**
     * 设置应用版本号
     * @param version
     * @param moduleName
     * @returns
     */
    async setAppVersion(version: string, moduleName = ''): Promise<void> {
        if (!this.chatManager) {
            return;
        }
        try {
            return this.chatManager.method.setting.setAppVersion(version, moduleName);
        } catch (err) {
            logger.error('im => setAppVersion: error', err);
            return;
        }
    }

    protected sortSessions(sessions: Session[]) {
        // console.log('imService sortSessions', sessions);
        const topSessionList = sessions.filter((session) => isTopSession(session));
        const restSessionList = sessions.filter((session) => !isTopSession(session));
        // console.log('topSessionList', topSessionList, 'restSessionList', restSessionList);
        const sortTopSessions = topSessionList.sort(function (prev, next): number {
            return (next.timestamp || 0) - (prev.timestamp || 0);
        });
        const sortRestSessions = restSessionList.sort(function (prev, next): number {
            return (next.timestamp || 0) - (prev.timestamp || 0);
        });
        // console.log('sortTopSessions', sortTopSessions, 'sortRestSessions', sortRestSessions);
        // console.log('finally', sortTopSessions.concat(sortRestSessions));
        return sortTopSessions.concat(sortRestSessions);
        // return sessions.sort(function (prev, next): number {
        //     return next.timestamp - prev.timestamp;
        // });
    }
}

Bus.on('user:logout', () => {
    const instance = ImService.getInstance();
    if (instance) {
        instance.logoutIm().then();
    }
});
export default ImService;
