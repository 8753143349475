export enum Repeat {
    NOREPEAT = 0,
    EVERYWORKDAY = 1,
    EVERYDAY = 2,
    EVERYWEEK = 3,
    EVERYTWOWEEK = 4,
    EVERYMONTH = 5,
    EVERYYEAR = 6,
}

export function getRepeatName(type: Repeat) {
    switch (type) {
        case Repeat.NOREPEAT:
            return 'no-repeat';
        case Repeat.EVERYWORKDAY:
            return 'Every working day';
        case Repeat.EVERYDAY:
            return 'Every day';
        case Repeat.EVERYWEEK:
            return 'Every week';
        case Repeat.EVERYTWOWEEK:
            return 'Every two weeks';
        case Repeat.EVERYMONTH:
            return 'Every month';
        case Repeat.EVERYYEAR:
            return 'Every year';
        default:
            return 'no-repeat';
    }
}
