import React from 'react';
import './index.less';
export default function CircleProgress({ percent }: { percent: number }) {
    let percentLeft = 0;
    let percentRight = 0;
    let colorRight: any = '';
    if (percent <= 50) {
        percentRight = percent * 3.6;
    }
    if (percent > 50) {
        percentLeft = (percent - 50) * 3.6;
        colorRight = 'white';
    }
    return (
        <div className="circle-progress">
            <div className="circle-progress-inner">
                <div
                    className="circle-progress-left"
                    style={{
                        transform: `rotate(${percentLeft}deg)`,
                    }}
                />
                <div
                    className="circle-progress-right"
                    style={{
                        transform: `rotate(${percentRight}deg)`,
                        backgroundColor: colorRight,
                    }}
                />
            </div>
        </div>
    );
}
