import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import React, { useCallback, useState } from 'react';
import {
    filterAppInfo,
    filterContactInfo,
    filterGroupInfo,
    filterJoySpaceInfo,
    SearchGlobalContext,
    filterScheduleInfo,
    onOpenUserCard,
    filterDeptsInfo,
} from '@/components/Modals/SearchGlobalModal/utils';
import throttle from 'lodash/throttle';
import isNull from 'lodash/isNull';
import { LoadMore } from '@jd/focus-desktop-comps/lib/es/List';
import { List, Empty, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SearchListItem from './ListItem';
import SearchAppItem from './SearchAppItem';
import lodashTrim from 'lodash/trim';
import { connect } from 'dvajs';
import noContent from '@/assets/img/search-nodata.png';
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
import { DeptInfo } from '@/services/SearchService';
import SearchScheduleItem from './SearchScheduleItem';
import config, { ConfigEnum } from '@/config/config';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import styles from '@/components/styles';
import userCardIcon from '@/assets/img/user-card-icon.png';
import Loading from '@/components/Loading';
import { getContactHighlight } from '@/utils/search';
import Chat from '@/components/Projects/components/Chat';
import ChatState from '@/types/chat/State';
import { Session } from '@/types/chat';
import { changeEgovUrl } from '@/utils/tools';
import SeacrchMassageHistoryList from './SearchMessageHistoryList';
import { Avatar } from '@/baseComponents/Avatar';

let laetId: any = 0;
function DataList({
    onClick,
    searchType,
    openCreateContactModal,
    setCreateModalInputValue,
    allSessionList,
}: {
    onClick?: (data: ListItemData) => void;
    searchType: SearchType;
    openCreateContactModal: Function;
    setCreateModalInputValue: Function;
    allSessionList: Session[];
}) {
    const { onSearchGlobal, keyword, activeKey, t, isChange } = React.useContext(
        SearchGlobalContext
    );
    const [result, setResult] = React.useState<ListItemData[] | null>(null);
    const [hasMore, setHasMore] = React.useState(false);
    const [DeptsData, setDeptsData] = React.useState<DeptInfo>();
    const keywordRef = React.useRef<string>('');
    keywordRef.current = keyword;
    const activeKeyRef = React.useRef<SearchType>();
    activeKeyRef.current = activeKey;
    // 日程搜索暂时处理日程过多导致搜不到问题，设置日程搜索时pageSize为9999
    const pageSizeRef = React.useRef<number>(activeKey === SearchType.Schedule ? 50 : 20);
    const pageNoRef = React.useRef<number>(1);
    const [loading, setLoading] = useState(true);

    const get = (pageNo: any) => {
        laetId++;
        return async (theLastId: any) => {
            const res = await onSearchGlobal({
                keyword: keywordRef.current,
                searchType,
                pageNo,
                pageSize: searchType === SearchType.Contacts ? 50 : pageSizeRef.current,
            });
            if (laetId === theLastId) {
                if (keywordRef.current) {
                    const { apps, contacts, groups, pages, schedules, depts } = res as any;
                    setDeptsData(depts);

                    let data = null;
                    switch (searchType) {
                        case SearchType.Contacts: {
                            data = filterContactInfo(contacts, allSessionList, keywordRef.current);
                            break;
                        }
                        case SearchType.Group: {
                            data = filterGroupInfo(groups);
                            break;
                        }
                        case SearchType.JoySpace: {
                            data = filterJoySpaceInfo(pages);
                            break;
                        }
                        case SearchType.Schedule: {
                            data = filterScheduleInfo(schedules);
                            break;
                        }
                        case SearchType.App: {
                            data = filterAppInfo(apps);
                            break;
                        }
                        case SearchType.Department: {
                            data = filterDeptsInfo(depts?.data || []);
                            break;
                        }
                        default:
                    }
                    if (pageNo === 1) {
                        setResult(data || []);
                    } else {
                        setResult([...(result || []), ...(data || [])]);
                    }

                    setHasMore(
                        searchType === SearchType.Contacts
                            ? false
                            : (data || []).length >= pageSizeRef.current
                    );
                    setLoading(false);
                }
            }
        };
    };
    const doSearch = async (pageNo: number) => {
        if (activeKeyRef.current !== searchType) {
            return;
        }
        try {
            await get(pageNo)(laetId);
        } catch (e) {
            setResult([]);
            setHasMore(false);
            setLoading(false);
        }
    };

    const doSearchThrottle = React.useRef(throttle(doSearch, 500)).current;

    React.useEffect(() => {
        if (!lodashTrim(keywordRef.current)) {
            setResult(null);
            return;
        }
        if (isChange) {
            return;
        }
        doSearchThrottle(1);
    }, [keyword, activeKey, doSearchThrottle, isChange]);

    const getLoadingProp = useCallback((list: ListItemData[] | null) => {
        return {
            delay: 10,
            indicator: loadingIcon,
            spinning: isNull(list),
            wrapperClassName: 'loading-wrapper',
        };
    }, []);

    // 添加联系人入口
    const openContact = useCallback(() => {
        openCreateContactModal({ visible: true });
        setCreateModalInputValue(keywordRef.current);
    }, [openCreateContactModal, setCreateModalInputValue]);

    let loadMore = null;
    if (hasMore) {
        loadMore = (
            <LoadMore
                onClick={() => {
                    pageNoRef.current = pageNoRef.current + 1;
                    doSearch(pageNoRef.current);
                }}
            >
                {t('list.loadMore')}
            </LoadMore>
        );
    } else if (result?.length) {
        const ContactsItem = {
            name: getContactHighlight(keyword),
            desc: `${t('createButton.searchAddContact')}`,
            id: '1',
            avatar: <Avatar searchIcon={true} styleObj={{ color: '#ffffff' }} />,
        };
        loadMore = (
            <>
                {searchType === '1' &&
                    config[ConfigEnum.EXTERNAL_CONTACTS_ADD_CONTACTS] &&
                    result?.length < 20 &&
                    keyword?.length < 10 &&
                    keyword?.length > 1 && (
                        <SearchListItem
                            data={ContactsItem}
                            clstag="pageclick|keycount|xtbg_add_home_1637657110163|53"
                            onClick={() => {
                                openCreateContactModal({ visible: true });
                                setCreateModalInputValue(keywordRef.current);
                            }}
                            t={t}
                        />
                    )}
                <LoadMore>{t('list.noMore')}</LoadMore>
            </>
        );
    }

    const GetSearchItem = (item: any) => {
        const getExtraPart = (item: ListItemData) => {
            if (item.searchKey !== SearchType.Contacts) {
                return false;
            }
            return (
                <div
                    style={{
                        color: styles.color.primary2,
                        marginLeft: 15,
                        width: 34,
                        height: 30,
                        textAlign: 'center',
                    }}
                    onClick={(event: any) => {
                        const option = {
                            appId: item?.origin?.app,
                            userId: item?.origin?.userId,
                            teamId: item?.origin?.teamId,
                        };
                        onOpenUserCard(option);
                        event.stopPropagation();
                    }}
                >
                    <img src={changeEgovUrl(userCardIcon)} alt="" style={{ width: 20 }} />
                </div>
            );
        };
        console.log(item.searchKey, 'item.searchKeyitem.searchKeyitem.searchKey');
        return (
            <div>
                {item.searchKey !== SearchType.MessageHistory &&
                    item.searchKey !== SearchType.App &&
                    item.searchKey !== SearchType.Schedule && (
                        <SearchListItem
                            style={{
                                paddingLeft: 24,
                            }}
                            data={item}
                            onClick={() => {
                                if (onClick) {
                                    onClick(item);
                                }
                            }}
                            t={t}
                            extra={getExtraPart(item)}
                        />
                    )}
                {/* 显示聊天记录 */}
                {item.searchKey === SearchType.MessageHistory && (
                    <SeacrchMassageHistoryList
                        t={t}
                        activeKey={activeKey}
                        keyWord={keywordRef.current}
                    />
                )}
                {/* 应用展示 */}
                {item.searchKey === SearchType.App && (
                    <SearchAppItem
                        style={{
                            paddingLeft: 24,
                        }}
                        data={item}
                        onClick={() => {
                            if (onClick) {
                                onClick(item);
                            }
                        }}
                        t={t}
                    />
                )}
                {/* 日程展示 */}
                {item.searchKey === SearchType.Schedule && (
                    <SearchScheduleItem
                        style={{
                            paddingLeft: 24,
                        }}
                        data={item}
                        onClick={() => {
                            if (onClick) {
                                onClick(item);
                            }
                        }}
                        t={t}
                    />
                )}
            </div>
        );
    };

    if (loading) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '200px',
                }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div>
            {(result || []).length === 0 ? (
                DeptsData && DeptsData?.code === '-1' ? (
                    <p style={{ padding: '12px 24px' }}>{DeptsData?.message}</p>
                ) : (
                    <div>
                        {searchType === '1' ? (
                            <div
                                style={{
                                    margin: '40px 20px 0',
                                    color: '#A3A3A3',
                                    fontSize: '14px',
                                }}
                            >
                                <p
                                    style={{
                                        color: '#000000',
                                        fontSize: '16px',
                                        marginBottom: '23px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {getContactHighlight(keyword)}
                                </p>
                                <p style={{ marginLeft: '70px' }}>1、请检查关键词是否输入正确</p>
                                <p style={{ marginLeft: '70px' }}>
                                    2、该联系人可能不在您的通讯录中
                                </p>
                            </div>
                        ) : (
                            <Empty image={noContent} description={t('search.nodata')} />
                        )}
                        {/* <Empty image={noContent} description={t('search.nodata')} /> */}
                        {searchType === '1' && config[ConfigEnum.EXTERNAL_CONTACTS_ADD_CONTACTS] && (
                            <div style={{ textAlign: 'center', marginTop: 30 }}>
                                <Button
                                    style={{ border: ' 1px solid #1890ff', color: '#1890ff' }}
                                    onClick={openContact}
                                    clstag="pageclick|keycount|xtbg_add_home_1637657110163|52"
                                >
                                    {t('createButton.searchAddContact')}
                                </Button>
                            </div>
                        )}
                    </div>
                )
            ) : (
                <List
                    loading={getLoadingProp(result)}
                    dataSource={result || []}
                    loadMore={loadMore}
                    renderItem={(item: any) => GetSearchItem(item)}
                />
            )}
        </div>
    );
}
function mapStateToProps({ chat }: { chat: ChatState }) {
    return { allSessionList: chat.allSessionList };
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateContactModal(data: any) {
            dispatch({ type: 'ebook/openCreateContactModal', payload: data });
        },
        setCreateModalInputValue(data: any) {
            dispatch({ type: 'ebook/setCreateModalInputValue', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataList);
