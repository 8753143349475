/** eslint-disable */

// 获取剪切板的读取权限
export async function askReadPermission() {
    try {
        const { state } = await window.navigator?.permissions?.query({
            name: 'clipboard-read' as PermissionName,
        });
        console.log('获得剪贴板读权限状态：', state);
        return state === 'granted' || state === 'prompt';
    } catch (error) {
        console.log('获得剪贴板读权限状态失败：', error);
        return false;
    }
}

// 读取剪贴板内容
export async function readDataFromClipboard() {
    let permissionFlag = await askReadPermission();

    if (permissionFlag) {
        try {
            if (
                window.navigator.clipboard &&
                typeof (window.navigator.clipboard as any).read === 'function'
            ) {
                const clipboardItems = (await (navigator.clipboard as any).read()) || [];
                console.log('读取剪贴板内容：', clipboardItems);
                let dataArray = [];
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        dataArray.push(clipboardItem.getType(type));
                        // console.log('已读取剪贴板中的内容：', await blob.text());
                    }
                }
                return await Promise.all(dataArray);
            }
        } catch (err) {
            console.error('读取剪贴板内容失败: ', err);
            return [];
        }
    }
    return [];
}
