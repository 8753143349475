import React, { useState } from 'react';
import { connect } from 'dva';
import { useTranslation } from 'react-i18next';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

const AiNotificationCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            openNoticeDetails(data: { isvisibles: boolean; notificationId: any }) {
                dispatch({ type: 'chat/openNoticeDetails', payload: data });
            },
        };
    }
)(
    ({
        message,
        openNoticeDetails,
    }: {
        message: any;
        openNoticeDetails: (data: { isvisibles: boolean; notificationId: any }) => void;
    }) => {
        const { title, extend } = message;
        const [t] = useTranslation('chat');
        const handleOpen = () => {
            openNoticeDetails({
                isvisibles: true,
                notificationId: message.infox.notificationId,
            });
        };
        return (
            <ComNoticeCard
                avatarSrc={changeEgovUrl(message.sender.avatar)}
                type="组通知"
                contentList={extend.fields}
                title={title}
                handleDetail={handleOpen}
                loadButtonsFn={() => (
                    <div
                        className="button_items"
                        onClick={() => {
                            handleOpen();
                        }}
                    >
                        <span>{t('click-to-view')}</span>
                    </div>
                )}
            />
        );
    }
);

export default AiNotificationCard;
