/* eslint-disable complexity */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/**
 * im服务的消息业务
 */
import ImService from '@/server/ImService';
import { removeChatSession } from '@/server/im/SessionService';
import ChatState from '@/types/chat/State';
import {
    ChatMessage,
    ChatMessagePlusEmitBody,
    ChatMessageRespBody,
    ChatMessageType,
    Employee,
    MessageEmitCategory,
    MessageStatus,
    Session,
    SysChatMessage,
    UID,
} from '@/types/chat';
import { message as toast } from 'antd';
import {
    addContactsSuccess,
    checkSysMessage,
    employeeToUid,
    getGroupNotifyMessage,
    getNoticeNotifyMessage,
    getSingleNotifyMessage,
    isAnnounceMessage,
    isEqualEmployee,
    isEqualUid,
    isGroupMemberDeleteMessage,
    isJoyMeetingMessage,
    isMultiSyncMessage,
    isShieldSession,
    isSuperviseMessage,
    isSysMessage,
    mySendMessage,
    setMessageGroupId,
    messageCompare,
    isCloudLinkMeetingMessage,
    isTaskMessage,
    isJAVSDKMeetingMessage,
} from '@/components/chat/utils/message';
import { FocusSDK, getUser, isFocusEnv } from '@/utils';
import Bus from '@/utils/bus';
import findLastIndex from 'lodash/findLastIndex';
import lodashPick from 'lodash/pick';
import log from '@/utils/logger';
import config, { ConfigEnum } from '@/config/config';
import {
    CloudLinkSessionStateEnum,
    CloudLinkStatusEnum,
    getCloudLinkStatus,
} from '@/components/MeetingCloudLink/Connector';
import {
    getCurrentCloudLinkMeetingInfo,
    replayCloudLinkMessage,
    sendCloudLinkNoticeCardMessage,
} from '@/components/MeetingCloudLink/utils';
import dayjs from 'dayjs';
import { isAINoticeSession } from '@/components/chat/utils/session';
import CHAT_NOTICE from '@/contant/chat';
import { uniqMergedMessage } from '@/utils/chat/message';
import { SessionStateEnum } from '@/baseComponents/Meeting/common';
import { handleMeetingMessage } from '@/baseComponents/Meeting/utils';
const logger = log.getLogger('ImService');

const isBigScreen = process.env.REACT_APP_X_FLAG === 'olympic';
interface GetMergedResp {
    toMerge: ChatMessage[];
    needMerge: boolean;
}

function sendMultiSyncMessage(message: any) {
    const { appName } = message;
    Bus.emit(`chat_message_multiSync_event:${appName}`, message);
}

function sendJoyMeetingMessage(message: any) {
    if (isFocusEnv()) {
        // 发送消息到桌面端
        FocusSDK.sendIpcMessage('chat_message_joyMeeting_event', { data: message });
    }
}
async function handleCloudLinkMeetingMessage(message: SysChatMessage) {
    if (isFocusEnv()) {
        // 一分钟前的消息不处理
        if (dayjs(message.timestamp).add(1, 'minute').isBefore(dayjs())) {
            console.info('华为消息：一分钟前的消息不处理', message);
            return;
        }
        Bus.emit('cloudlink:receive-chat-message', message);

        // 只有主窗口负责处理后面的逻辑
        if (window.location.href.includes('?page=')) {
            return;
        }
        if (message.ext?.sessionState === CloudLinkSessionStateEnum.Call) {
            if (getCurrentCloudLinkMeetingInfo()?.meetingNumber === message.ext?.meetingNumber) {
                console.info('华为消息：已经在此会议中，', message);
                return;
            }

            if (getCloudLinkStatus() !== CloudLinkStatusEnum.FREE) {
                // 非空闲直接回复忙碌
                console.info('华为消息：非空闲直接回复忙碌，', message);
                replayCloudLinkMessage(CloudLinkSessionStateEnum.Busy, message);
            } else {
                // 发送给桌面端，展示被叫弹窗
                console.info('华为消息：', message);
                FocusSDK.sendIpcMessage('cloudlink.invited', { data: message });
            }
        } else {
            console.log('华为消息：收到其他类型的消息', message);
            if (getCloudLinkStatus() !== CloudLinkStatusEnum.BUSY) {
                if (
                    isBigScreen &&
                    (message.ext?.sessionState === CloudLinkSessionStateEnum.TIMEOUT ||
                        message.ext?.sessionState === CloudLinkSessionStateEnum.UserReject ||
                        message.ext?.sessionState === CloudLinkSessionStateEnum.Busy)
                ) {
                    // 再次发送卡片通知
                    sendCloudLinkNoticeCardMessage([message.ext.fromUser], message);
                }
                if (message.content) {
                    // FIXME: 去掉回复消息
                    console.info(message.content);
                    // toast.info(message.content);
                }
            }
        }
    }
}

function sendSuperviseMessage(message: any) {
    if (isFocusEnv()) {
        // 发送消息到桌面端
        if (
            message?.infox?.superviseId &&
            message?.infox?.sendType !== 'SMS' &&
            message?.infox?.sendType !== 'COMMENT'
        ) {
            FocusSDK.sendIpcMessage('chat_message_supervise_event', {
                superviseId: message.infox.superviseId,
                mid: message.mid,
                sessionId: message.requestData?.sessionId || message.sessionId,
            });
        }
    }
}

export function sendTaskMessage(message: any) {
    if (isFocusEnv() && message.infox?.msgType === '1') {
        const deepLink = new URL(message.infox.deepLink);
        const { extend, infox } = message;
        // 标题可能有特殊字符
        infox.taskName = encodeURIComponent(infox.taskName || '');
        const params = JSON.parse(deepLink.searchParams.get('mparam') || '');
        FocusSDK.sendIpcMessage('chat_message_task_event', { params, extend, infox });
        // window.console.log('chat_message_task_event', infox);
    }
}

// function sendCalendarMessage(message: any) {
//     if (isFocusEnv()) {
//         const deepLink = new URL(message.infox.deepLink);
//         const params = JSON.parse(deepLink.searchParams.get('mparam') || '');
//         FocusSDK.sendIpcMessage('chat_message_calendar_event', { calendarId: params.scheduleId });
//     }
// }

function saveJoyMeetingMessage(message: any) {
    const imService = ImService.getInstance();
    const chatManager = imService.getChatManager();
    if (!chatManager) {
        return;
    }
    if (message.ext) {
        const { sessionState, meetingNumber } = message.ext;
        if (sessionState === 0) {
            chatManager.method.message
                .updateLocal(message.sessionId, message.mid, message, true)
                .then(() => {
                    logger.debug('save joymeeting messag ok');
                    chatManager.method.session
                        .isNew({ sessionId: message.sessionId })
                        .then(({ session }) => {
                            if (session) {
                                chatManager.method.message
                                    .business(session.sessionId, session.lastReadMid)
                                    .then((params) => {
                                        session.unreadCount = params.unreadCount;
                                        Bus.emit('chat:session-update', session);
                                    });
                            }
                        });
                })
                .catch((e) => {
                    logger.error('failed to save joymeeting message', e);
                });
        } else if (sessionState === 1) {
            imService
                .findMeetingMessage(meetingNumber, message.sessionId, message.mid)
                .then((msg: any) => {
                    logger.debug('findMeetingMessage result', { message, msg });
                    chatManager.method.message
                        .updateLocal(msg.sessionId, msg.mid, message)
                        .then(() => {
                            logger.debug('update meeting message ok');
                            Bus.emit('chat:message-change', message);
                        })
                        .catch((e) => {
                            logger.error('failed to update meeting message when close meeting', e);
                        });
                })
                .catch((e: any) => {
                    logger.error('failed to findMeetingMessage', e);
                });
            return { changed: false };
        } else {
            return { changed: false };
        }
    }
}

// eslint-disable-next-line complexity
function forwardMessage(
    message: ChatMessage & ChatMessageRespBody & any,
    currentEmployee: Employee,
    selectedSession: Session
): boolean {
    let flag = false;
    if (isJoyMeetingMessage(message)) {
        // 会议消息
        if (isFocusEnv()) {
            // 通过事件发送给桌面端 如果没有加入会议的权限，放弃本次消息
            if (!config[ConfigEnum.TOP_BAR_MENU_MEETING_JOIN]) {
                flag = true;
            }
            sendJoyMeetingMessage(message);
            flag = true;
        } else {
            // @ts-ignore
            if (mySendMessage({ message, currentEmployee })) {
                flag = true;
            }
            saveJoyMeetingMessage(message);
            flag = true;
        }
    } else if (isJAVSDKMeetingMessage(message)) {
        const avsdk = message.ext.avsdk;
        if (currentEmployee.userId === message.from.pin) {
            flag = true;
        } else if (isFocusEnv() || (!isFocusEnv() && avsdk === 'jbrtc')) {
            // 聊天窗口视频icon 拨打后，当用户接听，触发该逻辑
            Bus.emit('meeting:receive-chat-message', message);
            handleMeetingMessage(message);
        }
        flag = true;
    } else if (isCloudLinkMeetingMessage(message)) {
        // 会议消息
        if (isFocusEnv()) {
            if (currentEmployee.userId === message.from.pin) {
                console.info('自己收到自己的消息，不处理', message);
            } else {
                handleCloudLinkMeetingMessage(message);
            }
            flag = true;
        }
    } else if (isSuperviseMessage(message) && !message.isRead) {
        // 督办消息
        sendSuperviseMessage(message);
        Bus.emit('supervise/update');
        flag = true;
    } else if (isTaskMessage(message) && !message.isRead) {
        sendTaskMessage(message);
        flag = true;
    }
    // else if (isCalendarMessage(message) && !message.isRead) {
    //     sendCalendarMessage(message);
    //     flag = true;
    // }
    else if (isAnnounceMessage(message)) {
        if (!message.isRead) {
            // 公告消息
            Bus.emit('announce:openModal', message);
        }
        flag = true;
    } else if (isMultiSyncMessage(message)) {
        // 10000 为多端同步消息 直接传递
        sendMultiSyncMessage(message);
        flag = true;
    } else if (addContactsSuccess(message)) {
        // 添加好友成功
        Bus.emit('address:add_friend_success');
        flag = false;
    }
    /* else if (isMergeForwardMessage(message, selectedSession)) {
        // 是合并转发的消息
        flag = true;
    } */

    // AI助手需触发更新
    if (isAINoticeSession(selectedSession) && message?.from?.pin !== CHAT_NOTICE.SYS_PIN) {
        flag = false;
    }

    return flag;
}

/**
 * 桌面端消息提醒
 * @param allSessionList
 * @param message
 * @param currentEmployee
 */
function sendMessageNotify(
    allSessionList: Session[],
    message: ChatMessage,
    currentEmployee: Employee
): any {
    if (message.category !== MessageEmitCategory.CHAT_MESSAGE) {
        return;
    }
    if (isEqualEmployee(message.sender, currentEmployee)) {
        return;
    }
    if (isSysMessage(message)) {
        return;
    }
    // 取消 sys 类型的消息提醒
    const session = allSessionList.find((item) => item.sessionId === message.sessionId);
    if (!session || isShieldSession(session)) {
        // 取消消息提醒不发送消息通知
        return;
    }

    if (session.isNotice) {
        // 新消息提醒 通知 发送事件
        Bus.emit('chat_message_new_message', {
            message: getNoticeNotifyMessage(session, message, currentEmployee),
        });
    } else if (session.isSingle) {
        // 新消息提醒 单聊 发送事件
        Bus.emit('chat_message_new_message', {
            message: getSingleNotifyMessage(session, message, currentEmployee),
        });
    } else if (session.isGroup) {
        // 新消息提醒 群聊 发送事件
        Bus.emit('chat_message_new_message', {
            message: getGroupNotifyMessage(session, message, currentEmployee),
        });
    }
}

/**
 * 获取要聚合的消息
 * @param session
 * @param messages
 * @param message
 * @returns
 */
export function getToMergeMessage(
    session: Session | undefined,
    messages: ChatMessage[],
    message: ChatMessage & ChatMessagePlusEmitBody
): GetMergedResp {
    if (message.category !== MessageEmitCategory.CHAT_MESSAGE) {
        return {
            toMerge: [],
            needMerge: false,
        };
    }
    if (message.type !== ChatMessageType.TEXT) {
        return {
            toMerge: [],
            needMerge: false,
        };
    }
    if (message.type === ChatMessageType.TEXT && (message as any).nativeId === 'share_link') {
        return {
            toMerge: [],
            needMerge: false,
        };
    }
    if (message.fuuid || message.reply) {
        return {
            toMerge: [],
            needMerge: false,
        };
    }
    if (message.statusType === MessageStatus.FAILED) {
        const lastMessages = messages.slice(-4);
        const needMerge = getNeedToMerge(messages, message);
        return {
            toMerge: lastMessages,
            needMerge,
        };
    }

    if (!session) {
        return {
            toMerge: [],
            needMerge: false,
        };
    }
    if (!session.isGroup) {
        return {
            toMerge: [],
            needMerge: false,
        };
    }
    const lastMessages = messages.slice(-4);
    const len = lastMessages.length;
    for (let i = len; i > 0; i--) {
        if (lastMessages[i - 1].type !== ChatMessageType.TEXT || lastMessages[i - 1].reply) {
            const toMerge = lastMessages.splice(i);
            const needMerge = getNeedToMerge(toMerge, message);
            return {
                toMerge,
                needMerge,
            };
        }
    }
    const needMerge = getNeedToMerge(lastMessages, message);
    return {
        toMerge: lastMessages,
        needMerge,
    };
}

function getNeedToMerge(messages: ChatMessage[], message: ChatMessage): boolean {
    const last = messages.slice(-1)[0];

    if (
        (last as ChatMessagePlusEmitBody)?.plusList &&
        last.content === message.content &&
        last.revoke !== 1
    ) {
        return true;
    }

    const lastest = messages.slice(-2);
    return (
        lastest.length >= 2 &&
        lastest.filter((m) => m.content === message.content).length === lastest.length
    );
}
export async function dealMessageMerged(
    session: Session | undefined,
    messages: ChatMessage[],
    message?: ChatMessage
): Promise<ChatMessage[]> {
    const imService = ImService.getInstance();
    if (!imService || !session || !messages.length) {
        return messages;
    }
    const _messages = message || messages[messages.length - 1];
    const { toMerge, needMerge } = getToMergeMessage(session, messages, _messages);
    if (needMerge) {
        const last = messages.splice(messages.length - toMerge.length);
        const merged = await imService.mergeChatMessage(last);
        return [...messages, ...merged];
    }
    return messages;
}

/**
 * 获取session
 * @param allSessionList
 * @param message
 * @returns
 */
function getSession(allSessionList: Session[], message: ChatMessage): Session | undefined {
    return allSessionList.find((item) => item.sessionId === message.sessionId);
}

function getSessionById(allSessionList: Session[], sessionId: string): Session | undefined {
    return allSessionList.find((item) => item.sessionId === sessionId);
}

/**
 * 快速发送消息时，因为发消息触发的此事件可能晚于当前消息的dongRead执行
 * @param sessionMessage
 * @param mergeMessage
 * @returns
 */
function mergeData(sessionMessage: any, mergeMessage: any) {
    let { readUids } = sessionMessage;
    let { readUids: newReadUids } = mergeMessage;
    let rtnMergeData = {};
    if (readUids?.length && !newReadUids?.length) {
        rtnMergeData = {
            readUids,
        };
    }

    return rtnMergeData;
}

/**
 * 处理一条新消息
 * 接收的消息 只有当前选择会话的消息 其它会话的消息不用关心
 * @param chatState
 * @param message
 */
// eslint-disable-next-line complexity
export async function dealSessionNewMessage(
    chatState: ChatState,
    message: ChatMessage & ChatMessageRespBody & any
): Promise<any> {
    const { sessionMessageMap, allSessionList, selectedSession, currentEmployee } = chatState;
    const currentUID = employeeToUid(currentEmployee);
    if (!message) {
        return { changed: false };
    }
    const forward = forwardMessage(message, currentEmployee, selectedSession);
    if (forward) {
        return { changed: false };
    }

    // tj: 处理空图片消息
    if (message?.type === ChatMessageType.IMAGE && !message?.url) {
        return { changed: false };
    }

    // 移除成员且成员是自已
    if (message && message.category === MessageEmitCategory.SYSTEM) {
        if (
            isGroupMemberDeleteMessage(message) &&
            isEqualUid(currentUID, message.members[0] as UID)
        ) {
            // 移除会话
            await removeChatSession(message.sessionId);
            return { changed: false };
        }
    }

    const imService = ImService.getInstance();
    const sessionId = message.sessionId;
    const session = getSession(allSessionList, message);
    // if (message.sessionId !== selectedSession.sessionId) {
    //     // 如果不是当前打开的聊天会话
    //     return { changed: false };
    // }
    if (message && message.category === MessageEmitCategory.SYSTEM) {
        // 系统通知类消息，需要检查是否有展示的权限
        if (!session) {
            return { changed: false };
        }
        const checkResult = checkSysMessage({ message, session }); // 检查消息是否要展示
        if (!checkResult) {
            return { changed: false };
        }
    }

    // 有当前会话存在 并且新消息是属于当前会话的 将新消息插入到会话消息映射结构中
    let chatMessages = (sessionMessageMap || {})[sessionId] || [];
    // chatMessages = chatMessages.sort(messageCompare);
    // console.log('chatMessages111', chatMessages);
    const index = findLastIndex(chatMessages, (item) => item.id === message.id);
    if (index > -1) {
        let mergeMessage = message;
        if (message.type === 'revoke_message') {
            mergeMessage = lodashPick(message, ['id', 'revoke']);
            chatMessages.forEach((item, idx) => {
                if (item.replyMsgs && item.replyMsgs.length > 0) {
                    const replyMsg = item.replyMsgs[0] as ChatMessage;
                    if (replyMsg?.id === mergeMessage.id) {
                        chatMessages[idx] = {
                            ...chatMessages[idx],
                            replyMsgs: [{ ...replyMsg, ...mergeMessage }],
                        };
                    }
                }
            });
        }

        let mergeReadUids = mergeData(chatMessages[index], mergeMessage);
        chatMessages[index] = {
            ...chatMessages[index],
            ...mergeMessage,
            ...mergeReadUids,
            // timestamp: chatMessages[index].timestamp,
        };
        // tj: 增加消息排序
        chatMessages = chatMessages.sort(messageCompare);
        if (sessionId === selectedSession.sessionId && selectedSession.isGroup) {
            // 只聚合最后3条
            chatMessages = uniqMergedMessage(chatMessages);
            chatMessages = await dealMessageMerged(session, chatMessages, message);
            chatMessages = chatMessages.sort(messageCompare);
        }
        sessionMessageMap[sessionId] = [...chatMessages];
        return { sessionMessageMap, changed: true };
    } else {
        if (message.fuuid) {
            console.log('message.fuuid', message);
            return { sessionMessageMap, changed: true };
        }
        if (message.type === 'revoke_message') {
            chatMessages.map(async (item: any) => {
                if (item.plusList === undefined) {
                    return;
                }
                // eslint-disable-next-line array-callback-return
                item.plusList.filter((user: any, index: any) => {
                    if (user.messageId === message.mid) {
                        // eslint-disable-next-line no-return-assign
                        return (user.statusType = MessageStatus.REVOKE);
                    }
                });
                const imService = ImService.getInstance();
                const result = await imService.updateMergedMessage(message.sessionId, item.mid, {
                    ...item,
                    plusList: item.plusList,
                });
                if (result) {
                    sessionMessageMap[sessionId] = chatMessages;
                }
            });
            return { sessionMessageMap, changed: true };
        }
        let messages = chatMessages.concat([message]).sort(messageCompare);
        // console.log('chatMessages222', messages);
        if (sessionId === selectedSession.sessionId && selectedSession.isGroup) {
            // 只聚合最后3条
            messages = uniqMergedMessage(messages);
            messages = await dealMessageMerged(session, messages, message);
            messages = messages.sort(messageCompare);
        }
        const lastMessage = messages[messages.length - 1] || null;
        const prevMessage = messages[messages.length - 2] || null;
        setMessageGroupId(lastMessage, prevMessage);
        // 距现在最近的消息在消息数组后面位置
        sessionMessageMap[sessionId] = messages;
        // 消息提醒
        sendMessageNotify(allSessionList, message, currentEmployee);
        return { sessionMessageMap, changed: true };
    }
}

/**
 * 批量处理消息
 * @param chatState
 * @param messages
 * @returns
 */
export async function dealSessionBatchMessage(
    chatState: ChatState,
    messages: ChatMessage & ChatMessageRespBody & any[],
    sessionId: string
): Promise<any> {
    const _messages = messages.sort(messageCompare);
    const { sessionMessageMap, allSessionList, currentEmployee } = chatState;
    const currentUID = employeeToUid(currentEmployee);
    const imService = ImService.getInstance();
    let chatMessages = (sessionMessageMap || {})[sessionId] || [];
    let changed = false;
    const session = getSessionById(allSessionList, sessionId);
    if (!session) {
        return {
            sessionMessageMap,
            changed,
        };
    }
    for (const message of _messages) {
        // console.log('message', message, message.from);
        if (!message) {
            continue;
        }
        const forward = forwardMessage(message, currentEmployee, session);
        if (forward) {
            continue;
        }
        // 过滤AI助手不展示的卡片消息
        if (isAINoticeSession(session) && message?.from?.pin === CHAT_NOTICE.SYS_PIN) {
            continue;
        } // tj: 处理空图片消息
        if (message?.type === ChatMessageType.IMAGE && !message?.url) {
            continue;
        } // 移除成员且成员是自已
        if (message && message.category === MessageEmitCategory.SYSTEM) {
            if (
                isGroupMemberDeleteMessage(message) &&
                isEqualUid(currentUID, message.members[0] as UID)
            ) {
                // 移除会话
                removeChatSession(message.sessionId).then();
                continue;
            }
        }
        if (message && message.category === MessageEmitCategory.SYSTEM) {
            // 系统通知类消息，需要检查是否有展示的权限
            const checkResult = checkSysMessage({
                message,
                session,
            }); // 检查消息是否要展示
            if (!checkResult) {
                continue;
            }
        }
        changed = true;
        // chatMessages = chatMessages.sort(messageCompare);
        const index = findLastIndex(chatMessages, (item) => item.id === message.id);
        if (index > -1) {
            let mergeMessage = message;
            if (message.type === 'revoke_message') {
                mergeMessage = lodashPick(message, ['id', 'revoke']);
                chatMessages.forEach((item, idx) => {
                    if (item.replyMsgs && item.replyMsgs.length > 0) {
                        const replyMsg = item.replyMsgs[0] as ChatMessage;
                        if (replyMsg?.id === mergeMessage.id) {
                            chatMessages[idx] = {
                                ...chatMessages[idx],
                                replyMsgs: [{ ...replyMsg, ...mergeMessage }],
                            };
                        }
                    }
                });
            }
            chatMessages[index] = {
                ...chatMessages[index],
                ...mergeMessage,
                // timestamp: chatMessages[index].timestamp,
            };
            // tj: 增加消息排序
            chatMessages = chatMessages.sort(messageCompare);
            if (session.isGroup) {
                chatMessages = uniqMergedMessage(chatMessages);
                // 只聚合最后3条
                chatMessages = await dealMessageMerged(session, chatMessages, message);
                chatMessages = chatMessages.sort(messageCompare);
            }
        } else {
            if (message.fuuid) {
                continue;
            }
            if (message.type === 'revoke_message') {
                chatMessages = await Promise.all(
                    chatMessages.map(async (item: any) => {
                        if (item.plusList === undefined) {
                            return item;
                        }
                        // eslint-disable-next-line array-callback-return
                        item.plusList = item.plusList.filter((user: any, index: any) => {
                            if (user.messageId === message.mid) {
                                // eslint-disable-next-line no-return-assign
                                return (user.statusType = MessageStatus.REVOKE);
                            }
                        });
                        imService
                            .updateMergedMessage(message.sessionId, item.mid, {
                                ...item,
                                plusList: item.plusList,
                            })
                            .then();
                        return item;
                    })
                );
                continue;
            }
            let messages = chatMessages.concat([message]).sort(messageCompare);
            if (session.isGroup) {
                messages = uniqMergedMessage(messages);
                // 只聚合最后3条
                messages = await dealMessageMerged(session, messages, message);
                messages = messages.sort(messageCompare);
            }
            const lastMessage = messages[messages.length - 1] || null;
            const prevMessage = messages[messages.length - 2] || null;
            setMessageGroupId(lastMessage, prevMessage);
            // 距现在最近的消息在消息数组后面位置
            chatMessages = messages;
            // 消息提醒
            sendMessageNotify(allSessionList, message, currentEmployee);
        }
    }

    sessionMessageMap[session.sessionId] = chatMessages;
    return { sessionMessageMap, changed };
}
