import React, { useState, useContext } from 'react';
import { Spin, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LoginFormContext } from '../../utils';
import { BackNav } from '../../comps';
import { InviteUserInfo, SendJoinApproveRes } from '@/types/LoginPage';
import { useTranslation } from 'react-i18next';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import InputWithCtr from '@/components/TeamManagement/InputWithCtr';
import { changeEgovUrl } from '@/utils/tools';
const {
    REACT_APP_OSS_BASE_URL = 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com',
} = process.env;
enum JoinOtherTeamStatus {
    Init, // 团队码输入页面
    InvitorCheck, // 带加入页面
    WaitingApproval, // 等待审核页面
    Finish, // 加入完成页面 目前该状态没用上
}

export default function JoinOtherTeam({ goBack }: { goBack: () => void }) {
    const { t } = useTranslation('common');
    const { service, authInfo: authData, onGlobalError } = useContext<GlobalContextConfig>(
        GlobalContext
    );
    const [teamCode, setTeamCode] = useState<string | undefined>('');
    const [teamInfo, setTeamInfo] = useState<InviteUserInfo>({} as any);
    const [joinResult, setJoinResult] = useState<SendJoinApproveRes | null>(null);
    const [loading, setLoading] = useState(false);
    const [joinStatus, setJoinStatus] = useState(JoinOtherTeamStatus.Init);
    const [errorCode, setErrorCode] = useState(false);
    const loadingComp = (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />} />
    );

    function onTeamCodeChange(v: string | undefined) {
        if (v && v.split(' ').join('').length > 8) {
            return;
        }
        setTeamCode(v);
        if (errorCode) {
            setErrorCode(false);
        }
    }

    function getFormatTeamCode(code: string | undefined) {
        if (code && code.length > 4) {
            const c = code.split(' ').join('');
            return `${c.slice(0, 4)} ${c.slice(4)}`;
        }
        return code || '';
    }

    async function getInviteUserByTeamCode() {
        if (!teamCode) {
            return;
        }
        try {
            const content: InviteUserInfo = await service.focusService.getInviteUserByTeamCode({
                teamCode: teamCode?.split(' ').join(''),
            });
            // setErrorCode(true);
            if (content) {
                setTeamInfo(content);
                setJoinStatus(JoinOtherTeamStatus.InvitorCheck);
            }
        } catch (e) {
            setErrorCode(true);
            onGlobalError(e);
        }
    }

    async function sendJoinApprove() {
        try {
            const content: SendJoinApproveRes = await service.focusService.sendJoinApprove({
                inviteTeamId: teamInfo.teamId,
                inviteUserId: teamInfo.userId,
            });
            if (content) {
                setJoinResult(content);
                setJoinStatus(JoinOtherTeamStatus.WaitingApproval);
            }
        } catch (e) {
            onGlobalError(e);
        }
    }

    const teamCodeInput = (
        <>
            <div
                style={{
                    paddingBottom: 32,
                }}
            >
                <BackNav
                    clickHandle={goBack}
                    title={t('login.back')}
                    subtitle={t('login.inputTeamCode')}
                    desc={t('login.teamCodeDesc')}
                />
            </div>
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <div className="mobileInput">
                    <InputWithCtr
                        autoFocus
                        allowClear
                        placeholder={t('login.inputTeamCodePlaceholder')}
                        value={teamCode || ''}
                        onKeyDown={(e: any) => {
                            const { keyCode } = e;
                            if (keyCode === 13) {
                                // checkMobileCb();
                            }
                        }}
                        className={`${errorCode ? 'warn-info' : ''}`}
                        onChange={(v: any) => {
                            onTeamCodeChange(v);
                        }}
                        type="code"
                    />
                    {errorCode ? (
                        <p
                            className="warn-info"
                            style={{
                                marginTop: 10,
                            }}
                        >
                            {t('login.teamCodeError')}
                        </p>
                    ) : null}
                </div>
            </div>
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                        getInviteUserByTeamCode();
                    }}
                    className="btn"
                    style={
                        !loading && teamCode && teamCode.length
                            ? {
                                  opacity: 1,
                                  cursor: 'pointer',
                              }
                            : {}
                    }
                >
                    {loading && <div>{loadingComp}</div>}
                    <div
                        style={{
                            margin: 12,
                            fontSize: 14,
                            color: 'rgb(255, 255, 255)',
                        }}
                    >
                        {t('nextStep')}
                    </div>
                </div>
            </div>
        </>
    );
    const invitorCheck = (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <img
                style={{
                    width: 60,
                    height: 60,
                    borderRadius: 9,
                    marginBottom: 24,
                }}
                src={changeEgovUrl(
                    teamInfo.avatar || `${REACT_APP_OSS_BASE_URL}/statics/images/default_avatar.png`
                )}
                alt=""
            />
            <p
                style={{
                    marginBottom: 68,
                    fontSize: 18,
                    // color: colors.black2,
                }}
            >
                <span
                    style={{
                        // fontFamily: 'PingFangSC-Bold',
                        marginRight: 8,
                    }}
                >
                    {teamInfo.userName}
                </span>
                <span
                    style={{
                        fontFamily: 'PingFangSC-Regular',
                    }}
                >
                    {t('login.inviteYouJoin')}
                </span>
            </p>
            <p
                style={{
                    marginBottom: 90,
                    fontSize: 30,
                    lineHeight: 1,
                    // color: colors.black2,
                    fontWeight: 'bold',
                    // fontFamily: 'PingFangSC-Bold'
                }}
            >
                {teamInfo.teamName}
            </p>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div
                    className="btn secondary small"
                    style={{
                        marginRight: 14,
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        goBack();
                    }}
                >
                    {t('login.back')}
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                        sendJoinApprove();
                    }}
                    className="btn small"
                    style={{
                        opacity: 1,
                        cursor: 'pointer',
                    }}
                >
                    {loading && <div>{loadingComp}</div>}
                    <div
                        style={{
                            margin: 12,
                            fontSize: 14,
                            color: 'rgb(255, 255, 255)',
                        }}
                    >
                        {t('login.joinTeam')}
                    </div>
                </div>
            </div>
            {/* wzp 2021-03-30 【赵婷婷提出需求】【协议】刘处反馈政府内部使用不需要协议展示，两个协议均去掉 行云地址 http://jagile.jd.com/teamspace/cardlist/JINGZHIBAN/carddetail?cardId=810946&sprintId=41239 */}
            {/* <div className="loginRightLoginMsg">
                {t('login.svcProtocolLabel')}
                <a
                    onClick={() => {
                        window.open('https://me.jdcloud.com/protocol/service/chinese/');
                    }}
                >
                    {t('login.svcProtocol')}
                </a>
                {t('login.and')}
                <a
                    onClick={() => {
                        window.open('https://me.jdcloud.com/protocol/privacy/chinese/');
                    }}
                >
                    {t('login.yinSiZhengCe')}
                </a>
            </div> */}
        </div>
    );
    const WaitingApproval = (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <img
                style={{
                    width: 287,
                    height: 225,
                    marginBottom: 32,
                }}
                src={changeEgovUrl(`${REACT_APP_OSS_BASE_URL}/statics/images/join-wait.png`)}
                alt=""
            />
            <p
                style={{
                    marginBottom: 68,
                    padding: '12px 24px',
                    fontSize: 16,
                    lineHeight: 1,
                    // color: colors.primary,
                    background: 'rgba(49,196,204,0.0762)',
                    borderRadius: 6,
                }}
            >
                {t('login.waittingApproval')}
            </p>

            <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                    goBack();
                }}
                className="btn"
                style={{
                    opacity: 1,
                    cursor: 'pointer',
                    margin: '0 auto',
                }}
            >
                <div
                    style={{
                        margin: 12,
                        fontSize: 14,
                        color: 'rgb(255, 255, 255)',
                    }}
                >
                    {t('login.back')}
                </div>
            </div>
        </div>
    );
    return (
        <div className="mobileForm">
            {joinStatus === JoinOtherTeamStatus.Init ? teamCodeInput : null}
            {joinStatus === JoinOtherTeamStatus.InvitorCheck ? invitorCheck : null}
            {joinStatus === JoinOtherTeamStatus.WaitingApproval ? WaitingApproval : null}
        </div>
    );
}
