/* eslint-disable no-template-curly-in-string */
import { Popover } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isBjme } from '@/utils';
import findKey from 'lodash/findKey';
import classNames from 'classnames';
import { stickers_sets } from '@/utils/chat/stickers-config';
import { cache } from '@/utils/chat/ddemoji';
import GraphemeSplitter from 'grapheme-splitter';
const emoji_str =
    '🙂😄😲😎😭😂😳🤐😕😡🤗😏👌✌👍💪👏🤝👋😊😉😁😍😘😋😌😵😴😯🙁🙄🤔😨😱😓😒😣😤💀🙈🐶👆👇👈👉✊🙏💯🉑💡📅📄📧📁📱💻🔗📌❌❓🚩📢❤💔🎁💰🌹🥀☕🍺🍗🎉🎂🔥💣';

const emoji_map: { [key: number]: string } = {
    82345435: '🙂',
    913039591: '😄',
    684148117: '😲',
    648823177: '😎',
    57014997: '😭',
    826758823: '😂',
    748735821: '😳',
    745490754: '🤐',
    106397922: '😕',
    230199207: '😡',
    862967646: '🤗',
    225775353: '😏',
    994011696: '👌',
    897295649: '✌️',
    721799613: '👍',
    109740240: '💪',
    431073010: '👏',
    941777090: '🤝',
    295114464: '👋',
    1025371740: '🙏',
    269292583: '😊',
    597572425: '😉',
    521351744: '😁',
    989555239: '😍',
    138085543: '😘',
    396043112: '😋',
    88964812: '😌',
    270668880: '😵',
    443721966: '😴',
    356621963: '😯',
    374349995: '🙁',
    113770930: '🙄',
    471705786: '🤔',
    916644171: '😨',
    982673707: '😱',
    620215843: '😓',
    504770619: '😒',
    294688550: '😣',
    1045983413: '😤',
    806507440: '💀',
    27850640: '🙈',
    36829185: '🐶',
    119144935: '👆',
    51411409: '👇',
    883481858: '👈',
    511783094: '👉',
    344071651: '✊',
    510111883: '💯',
    380018773: '🉑',
    571946965: '💡',
    167020478: '📅',
    270439445: '📄',
    638959579: '📧',
    648724754: '📁',
    329194217: '📱',
    171280424: '💻',
    175802562: '🔗',
    512340201: '📌',
    107479455: '❌',
    172755305: '❓',
    256545470: '🚩',
    529478391: '📢',
    62487608: '❤',
    152176078: '💔',
    658162102: '🎁',
    875322385: '💰',
    138118052: '🌹',
    258871107: '🥀',
    818370234: '☕',
    775442383: '🍺',
    771346234: '🍗',
    908681165: '🎉',
    484846864: '🎂',
    437430276: '🔥',
    395649765: '💣',
    233409599: '🤭',
};

const emoji_mebj_array = [
    '🙂',
    '😕',
    '[吓]',
    '🤐',
    '😴',
    '😢',
    '😓',
    '😁',
    '🙁',
    '🤭',
    '😊',
    '😪',
    '[憨笑]',
    '[奋斗]',
    '😭',
    '[疑问]',
    '[拥抱]',
    '👍',
    '🤝',
    '✌',
    '[抱拳]',
    '👏',
    '👌',
    '💪',
    '🙏',
    '☕',
    '🌛',
    '🌞',
    '🎂',
    '🌹',
    '🎁',
    '🎉',
];
// const emoji_str_mebj_map = {
//     'em-01': {
//         emoji: '🙂',
//         content: '微笑&呵呵',
//     },
//     'em-77': {
//         emoji: '😄',
//         content: '笑脸',
//     },
//     'em-20': {
//         emoji: '🤭',
//         content: '偷笑',
//     },
//     'em-37': {
//         emoji: '👋',
//         content: '再见',
//     },
//     'em-40': {
//         emoji: '👏',
//         content: '鼓掌',
//     },
//     'em-67': {
//         emoji: '👍',
//         content: '强',
//     },
//     'em-69': {
//         emoji: '🤝',
//         content: '强',
//     },
//     'em-75': {
//         emoji: '👌',
//         content: 'OK',
//     },
//     'em-70': {
//         emoji: '✌️️',
//         content: '胜利',
//     },
//     'em-93': {
//         emoji: '🎉️',
//         content: '庆祝',
//     },
// };

export function getEmojis(): string[] {
    if (isBjme()) {
        return emoji_mebj_array;
    } else {
        return Array.from(emoji_str).filter((item) => !!item);
    }
}

const clsTags = [
    'pageclick|keycount|focus_emoji_01_1615797581373|1',
    'pageclick|keycount|focus_emoji_01_1615797581373|2',
    'pageclick|keycount|focus_emoji_01_1615797581373|3',
    'pageclick|keycount|focus_emoji_01_1615797581373|4',
    'pageclick|keycount|focus_emoji_01_1615797581373|5',
    'pageclick|keycount|focus_emoji_01_1615797581373|6',
    'pageclick|keycount|focus_emoji_01_1615797581373|7',
    'pageclick|keycount|focus_emoji_01_1615797581373|8',
    'pageclick|keycount|focus_emoji_01_1615797581373|9',
    'pageclick|keycount|focus_emoji_01_1615797581373|10',
    'pageclick|keycount|focus_emoji_01_1615797581373|11',
    'pageclick|keycount|focus_emoji_01_1615797581373|12',
    'pageclick|keycount|focus_emoji_01_1615797581373|13',
    'pageclick|keycount|focus_emoji_01_1615797581373|14',
    'pageclick|keycount|focus_emoji_01_1615797581373|15',
    'pageclick|keycount|focus_emoji_01_1615797581373|16',
    'pageclick|keycount|focus_emoji_01_1615797581373|17',
    'pageclick|keycount|focus_emoji_01_1615797581373|18',
    'pageclick|keycount|focus_emoji_01_1615797581373|19',
    'pageclick|keycount|focus_emoji_01_1615797581373|20',
    'pageclick|keycount|focus_emoji_01_1615797581373|21',
    'pageclick|keycount|focus_emoji_01_1615797581373|22',
    'pageclick|keycount|focus_emoji_01_1615797581373|23',
    'pageclick|keycount|focus_emoji_01_1615797581373|24',
    'pageclick|keycount|focus_emoji_01_1615797581373|25',
    'pageclick|keycount|focus_emoji_01_1615797581373|26',
    'pageclick|keycount|focus_emoji_01_1615797581373|27',
    'pageclick|keycount|focus_emoji_01_1615797581373|28',
    'pageclick|keycount|focus_emoji_01_1615797581373|29',
    'pageclick|keycount|focus_emoji_01_1615797581373|30',
    'pageclick|keycount|focus_emoji_01_1615797581373|31',
    'pageclick|keycount|focus_emoji_01_1615797581373|32',
    'pageclick|keycount|focus_emoji_01_1615797581373|33',
    'pageclick|keycount|focus_emoji_01_1615797581373|34',
    'pageclick|keycount|focus_emoji_01_1615797581373|35',
    'pageclick|keycount|focus_emoji_01_1615797581373|36',
    'pageclick|keycount|focus_emoji_01_1615797581373|37',
    'pageclick|keycount|focus_emoji_01_1615797581373|38',
    'pageclick|keycount|focus_emoji_01_1615797581373|39',
    'pageclick|keycount|focus_emoji_01_1615797581373|40',
    'pageclick|keycount|focus_emoji_01_1615797581373|41',
    'pageclick|keycount|focus_emoji_01_1615797581373|42',
    'pageclick|keycount|focus_emoji_01_1615797581373|43',
    'pageclick|keycount|focus_emoji_01_1615797581373|44',
    'pageclick|keycount|focus_emoji_01_1615797581373|45',
    'pageclick|keycount|focus_emoji_01_1615797581373|46',
    'pageclick|keycount|focus_emoji_01_1615797581373|47',
    'pageclick|keycount|focus_emoji_01_1615797581373|48',
    'pageclick|keycount|focus_emoji_01_1615797581373|49',
    'pageclick|keycount|focus_emoji_01_1615797581373|50',
    'pageclick|keycount|focus_emoji_01_1615797581373|51',
    'pageclick|keycount|focus_emoji_01_1615797581373|52',
    'pageclick|keycount|focus_emoji_01_1615797581373|53',
    'pageclick|keycount|focus_emoji_01_1615797581373|54',
    'pageclick|keycount|focus_emoji_01_1615797581373|55',
    'pageclick|keycount|focus_emoji_01_1615797581373|56',
    'pageclick|keycount|focus_emoji_01_1615797581373|57',
    'pageclick|keycount|focus_emoji_01_1615797581373|58',
    'pageclick|keycount|focus_emoji_01_1615797581373|59',
    'pageclick|keycount|focus_emoji_01_1615797581373|60',
    'pageclick|keycount|focus_emoji_01_1615797581373|61',
    'pageclick|keycount|focus_emoji_01_1615797581373|62',
    'pageclick|keycount|focus_emoji_01_1615797581373|63',
    'pageclick|keycount|focus_emoji_01_1615797581373|64',
    'pageclick|keycount|focus_emoji_01_1615797581373|65',
    'pageclick|keycount|focus_emoji_01_1615797581373|66',
    'pageclick|keycount|focus_emoji_01_1615797581373|67',
    'pageclick|keycount|focus_emoji_01_1615797581373|68',
    'pageclick|keycount|focus_emoji_01_1615797581373|69',
    'pageclick|keycount|focus_emoji_01_1615797581373|70',
    'pageclick|keycount|focus_emoji_01_1615797581373|71',
    'pageclick|keycount|focus_emoji_01_1615797581373|72',
    'pageclick|keycount|focus_emoji_01_1615797581373|73',
    'pageclick|keycount|focus_emoji_01_1615797581373|74',
    'pageclick|keycount|focus_emoji_01_1615797581373|75',
];

function EmojiContent({ onclick }: { onclick: (str: string) => void }) {
    const [t] = useTranslation('chat');
    const emojiArray = getEmojis();
    return (
        <div className="emoji-container">
            {/* 所有表情 */}
            <div className="emoji-all">
                <div className="emoji-all-title">{t('all_emoji')}</div>
                <div className="emoji-all-content">
                    {emojiArray.map((e: string, i: number) => {
                        return (
                            <div className="emoji-item" key={i} onClick={() => onclick(e)}>
                                <img
                                    className="dd-emoji"
                                    data-id={`dd-emoji-${e.slice(3)}`}
                                    src={cache[`./${stickers_sets[e]}`]}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default function EmojiPopover({
    children,
    onclick,
    visible,
    onVisibleChange,
    placement,
}: {
    children: any;
    visible: boolean;
    onclick: (str: string) => void;
    onVisibleChange: (visible: boolean) => void;
    placement?: any;
}) {
    function handleClick(str: string) {
        onclick(str);
        onVisibleChange(false);
    }

    const classes = useMemo(() => {
        return classNames({
            'emoji-popover': true,
            mebj: isBjme(),
        });
    }, []);

    return (
        <Popover
            trigger="click"
            overlayClassName={classes}
            placement={placement || 'top'}
            visible={visible}
            onVisibleChange={(visible) => {
                onVisibleChange(visible);
            }}
            content={<EmojiContent onclick={handleClick} />}
        >
            {children}
        </Popover>
    );
}

export function mattchEmoji(content: string) {
    let result = content;
    if (!content) {
        return '';
    }
    const emojiArray = getEmojis();
    emojiArray.forEach((e: string, i: number) => {
        const pattern = new RegExp(e, 'g');
        const ispattern = result.indexOf(e);
        if (ispattern !== -1) {
            result = result.replace(
                pattern,
                // e,
                `<img class="dd-emoji" data-id="dd-emoji-${e.slice(3)}" src="${
                    cache[`./${stickers_sets[e]}`]
                }"
                data-value="${cache[`./${stickers_sets[e]}`]}" draggable="false">`
                // formatEmoji(e)
            );
        }
    });
    return result;
}

export function getEmojiCode(emoji: string): string {
    const data = findKey(emoji_map, (v, k) => {
        return v === emoji;
    });
    return data || '';
}

// 删除emoji表情
export function deleteEmojiStr(content: string): string {
    if (!content) {
        return content;
    }
    let newStr = content;
    let bracketsRegex = /\[.*?\]/g;
    let simpleRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    let specialRegex = /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/;
    let allRegex = [bracketsRegex, simpleRegex];

    allRegex.forEach((item) => {
        newStr = newStr.replace(item, '');
    });
    if (specialRegex.test(newStr)) {
        let newArray = [];
        let splitter = new GraphemeSplitter();
        // emoji分割成数组
        let graphemes = splitter.splitGraphemes(newStr);
        // eslint-disable-next-line
        for (let i = 0; i < graphemes.length; i++) {
            if (!specialRegex.test(graphemes[i])) {
                newArray.push(graphemes[i]);
            }
        }
        newStr = newArray.join('');
    }

    return newStr;
}
