import { gatewayInstance } from '@/server/gateway';
const { REACT_APP_X_FLAG } = process.env;
enum osTypes {
    'WINDOWS' = 4,
    'MAC' = 5,
    'WEB' = 6,
}

export async function checkUpgradeApp(version: string, platform: string): Promise<any> {
    // osType=4   windows；osType=5   mac；osType=6   web；

    try {
        const data = await gatewayInstance.request({
            api: 'appVersionRpcService.getLatestVersion',
            method: 'POST',
            body: { version, osType: osTypes[platform.toUpperCase() as any] || 4 },
            headers: {
                'x-flag': REACT_APP_X_FLAG || 'gwork',
            },
        });
        return [data, null];
    } catch (e) {
        return [null, e.message.toString()];
    }
}
