import React from 'react';
import GlobalContext from '@/context/GlobalContext';
import Tab, { _TabProps, _Icon } from './Tab';
import './index.less';
import Header from './header';

export type _Tab = _TabProps & { order: number; key: number };
type ActiveTab = _Tab | null;

interface Props {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
}

export interface SetProps {
    icon?: _Icon;
    title?: string;
    id: string | number;
    newIcon?: string;
    progress?: number;
    progressDesc?: string;
    announce?: string;
}

let nextId = 0;

// 对外暴露hooks接口
export function useTabs() {
    const { events3: events } = React.useContext(GlobalContext);
    return {
        open(props: _TabProps) {
            events.emit('tabs.open', props);
        },
        setProps(props: SetProps) {
            events.emit('tabs.setProps', props);
        },
        close(id: string) {
            events.emit('tabs.close', { id });
        },
    };
}

function init(o: _TabProps) {
    const props: _Tab = {
        ...o,
        order: nextId++,
        key: nextId++,
    };
    return props;
}

export default function Tabs(props: Props) {
    const { events3: events } = React.useContext(GlobalContext);
    const [items, setItems] = React.useState<_Tab[]>([]);
    const [active, _setActive] = React.useState<ActiveTab>(null);

    const setActive = React.useCallback((o: ActiveTab) => {
        if (o) {
            o.order = nextId++;
        }
        _setActive(o);
    }, []);

    const remove = React.useCallback(
        (o: _Tab) => {
            const _items = items.filter((_o) => _o.id !== o.id);
            setItems(() => _items);
            // if (o.key === active?.key) {
            if (o.id === active?.id) {
                let _active: ActiveTab = null;
                _items.forEach((o) => {
                    if (!_active || o.order > _active.order) {
                        _active = o;
                    }
                });
                setActive(_active);
            }
        },
        [active, items, setActive]
    );

    const containerRenderer = React.useMemo(
        () => (
            <div className="pm-tabs-body">
                {items.map((o) => (
                    <div key={o.key} style={{ display: o.key === active?.key ? '' : 'none' }}>
                        {o.children}
                    </div>
                ))}
            </div>
        ),
        [items, active]
    );

    const close = (o: _Tab) => (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (typeof o.close === 'function' && o.close() === false) {
            return;
        }
        remove(o);
    };
    React.useEffect(() => {
        function openchange(o: _TabProps) {
            const newTab = init(o);
            setItems([...items, newTab]);
            setActive(newTab);
        }
        events.on('tabs.open', openchange);
        return () => {
            events.off('tabs.open', openchange);
        };
    }, [events, items, setActive]);

    React.useEffect(() => {
        function closechange(o: { id: string }) {
            const { id } = o;
            items.some((o) => {
                if (o.id === id) {
                    remove(o);
                    // return true;
                }
                return false;
            });
        }
        events.on('tabs.close', closechange);
        return () => {
            events.off('tabs.close', closechange);
        };
    }, [events, items, remove]);

    React.useEffect(() => {
        const items: _Tab[] = [];
        React.Children.forEach(props.children, (child) => {
            if (React.isValidElement(child)) {
                if (child.type.valueOf() === Tab) {
                    items.push(init(child.props as _TabProps));
                }
            }
        });
        setItems(items);
        setActive(items[0]);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={'pm-tabs ' + (props.className ?? '')}>
            <div className="pm-tabs-header">
                {items.map((o) => (
                    <Header
                        key={o.key}
                        active={active}
                        tab={o}
                        setActive={setActive}
                        onClose={close(o)}
                    />
                ))}
            </div>
            {containerRenderer}
        </div>
    );
}

Tabs.Item = Tab;
