import { FocusSDK as focusSDK, getAppLogoConfig, isFocusEnv } from '@/utils';
import { getServiceAndPrivacyLinks } from '@/utils/ui';
import { Modal } from 'antd';
import React, { useCallback, useEffect, useContext, useState } from 'react';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import logoOssImg from '@/assets/templateImgs/logo.png';
import './index.less';
import { changeEgovUrl } from '@/utils/tools';

interface Links {
    service: string;
    privacy: string;
    downloadUrl: string;
}

export interface AboutModalProps {
    service: any;
    visible: boolean;
    onCancel: () => void;
}
function getVersion() {
    if (focusSDK.getDeviceInfo().platform === 'web') {
        return focusSDK.getVersion();
    }
    const appInfo = focusSDK.appInfo;
    if (appInfo?.version) {
        return appInfo.version || '';
    }
    if (appInfo && typeof appInfo === 'function') {
        // @ts-ignore
        return appInfo().version || '';
    }
    return '';
}

async function getAboutInfo(service: any) {
    if (!service) {
        return { linkConfig: null, logoImg: '', appName: '', version: '' };
    }
    const config = getAppLogoConfig(service.appRuntime.runtimeConfig);
    const version = await getVersion();
    const linkConfig = getServiceAndPrivacyLinks(service.appRuntime);
    const download = linkConfig.service?.match(/^https?:\/\/(.*?)(:\d+)?\/.*$/);
    const downloadUrl = download && `https://${download[1]}/download`;
    return { linkConfig, downloadUrl, version, ...config };
}

export default function AboutModal(props: AboutModalProps) {
    const { service, visible, onCancel } = props;
    const [aboutInfo, setAboutInfo] = React.useState({
        linkConfig: null,
        logoImg: '',
        downloadUrl: '',
        appName: '',
        version: '',
    });

    const [downloadUrlValue, setDownloadUrlValue] = useState('https://jzb.beijing.gov.cn/download');

    useEffect(() => {
        if (!service) return;
        getAboutInfo(service).then((res: any) => {
            setAboutInfo(res);
        });
    }, [service]);

    const { linkConfig, logoImg, appName, version, downloadUrl } = aboutInfo;
    /**
     * tj: 下载app使用外部浏览器打开
     */
    const openUrl = useCallback(
        async (downloadUrl: any) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const asToken = await service.authService.exchangeWebToken();
            focusSDK.openUrl(downloadUrl + `?token=${asToken.accessToken}`);
        },
        [service.authService]
    );

    const openRosterUrl = useCallback(async () => {
        let currentOrigin = focusSDK.getMeMainEntry();
        focusSDK.openUrl(`${currentOrigin}/guide/#/roster`);
    }, []);

    let linkMap: Links = linkConfig || {
        service: 'https://me.jdcloud.com/protocol/service/chinese/',
        privacy: 'https://me.jdcloud.com/protocol/privacy/chinese/',
        downloadUrl: 'https://jzb.beijing.gov.cn/download',
    };

    useEffect(() => {
        let currentOrgin = focusSDK.getMeMainEntry();
        let downloadUrlValueTmp = `${currentOrgin}/download`;
        setDownloadUrlValue(downloadUrlValueTmp);
    }, []);
    return (
        <Modal
            className="me-about-modal"
            width={340}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
        >
            <div
                style={{
                    minHeight: 300,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    className="me-about-modal-content"
                    style={{
                        flex: 1,
                        paddingTop: 20,
                    }}
                >
                    <img
                        alt=""
                        src={logoOssImg}
                        width={72}
                        style={{
                            borderRadius: 12,
                        }}
                    />
                    <h4
                        style={{
                            fontSize: 18,
                            margin: 5,
                        }}
                    >
                        {appName}
                    </h4>
                    {version && (
                        <p
                            style={{
                                margin: 0,
                                fontSize: 12,
                            }}
                        >
                            {`V${version}`}
                        </p>
                    )}
                    {/* {newVersion && (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            <span
                                style={{
                                    padding: '3px 10px',
                                    borderRadius: 5,
                                    fontSize: 10,
                                    backgroundColor: 'rgba(245,34,45,0.2)',
                                    color: '#F5222D',
                                }}
                            >
                                获取新版本
                            </span>
                        </p>
                    )} */}
                    <div className="me-about-modal-development">
                        <div
                            className="me-about-modal-development-team"
                            onClick={() => {
                                openRosterUrl();
                            }}
                        >
                            开发团队
                        </div>
                        <div className="me-about-modal-development-text">
                            京东城市（北京）数字科技有限公司
                        </div>
                    </div>
                    {downloadUrlValue && (
                        <div className="me-about-modal-download-app">
                            <a
                                onClick={() => {
                                    openUrl(downloadUrlValue);
                                }}
                                className="me-about-modal-download-app-btn"
                            >
                                {appName}客户端下载链接
                            </a>
                        </div>
                    )}
                </div>
                {/* <div
                    className="me-about-modal-footer"
                    style={{
                        height: 50,
                        padding: 10,
                    }}
                >
                    <a href={linkMap.service} target="_blank" className="me-about-modal-footer-btn">
                        软件用户协议
                    </a>
                    <div
                        className="me-about-modal-footer-splitline"
                        style={{
                            width: 1,
                            height: 13,
                            margin: '0 20px',
                        }}
                    />
                    <a href={linkMap.privacy} target="_blank" className="me-about-modal-footer-btn">
                        软件隐私协议
                    </a>
                </div> */}
            </div>
        </Modal>
    );
}
