import React, { useEffect, useState } from 'react';
import './index.less';
import styles from './index.module.less';
import { Popover } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import BaseCalendar from '@/components/Calendars/BaseCalendar';
import TimePicker from '@/components/Calendars/TimePicker';
import { useDefaultFormatTime } from './utils';
import IconFont from '@/components/icon';

function getStartTimeList() {
    let today = dayjs().startOf('date');
    let hourList = Array.from(new Array(288).keys());
    let data = hourList.map((item, index) => {
        let time = today.add(index * 5, 'minute');
        return {
            value: time,
            title: time.format('HH:mm'),
        };
    });
    return data;
}
function getEndTimeList() {
    let today = dayjs().startOf('date');
    let hourList = Array.from(new Array(288).keys());
    let data = hourList.map((item, index) => {
        let time = today.add((index + 1) * 5, 'minute');
        let title = time.format('HH:mm');
        return {
            value: time,
            title: title === '00:00' ? '24:00' : title,
        };
    });
    return data;
}
const startTimeList = getStartTimeList();
const endTimeList = getEndTimeList();

function CalendarDatePicker({
    time,
    onChange,
    onVisibleChange,
    disable,
    placeholder,
    defaultTime,
    endLogic,
    isEnd,
    isAllDay,
    isDateFormat,
    isChangeHeaderVisible,
    configOfPopover,
    isRepeatPicker,
    style,
}: {
    time: Dayjs | null;
    onChange?: (time: Dayjs | null) => void;
    onVisibleChange?: (visable: boolean) => void;
    disable?: boolean;
    placeholder?: string;
    defaultTime?: Dayjs;
    endLogic?: boolean; // 是否启用末时制，即 当晚0点显示 今天24:00. 对于截止时间的显示很有效果
    isEnd?: boolean; // 截止时间 或 开始时间
    isAllDay?: boolean; // 全天选择器隐藏时间。
    isDateFormat?: boolean; // 是否显示成日期格式
    isChangeHeaderVisible?: boolean; // 时间选择器显隐展示
    configOfPopover?: {
        getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
    };
    isRepeatPicker?: boolean;
    style?: React.CSSProperties;
}) {
    const calendarDatePickerId = 'calendar-date-picker' + Math.floor(Math.random() * 10000);
    const [visible, setVisible] = useState(false);
    const [selectedTime, setSelectedTime] = useState(formatInitTime(time));
    useEffect(() => {
        setSelectedTime(formatInitTime(time));
        // eslint-disable-next-line
    }, [time]);
    function formatInitTime(time: Dayjs | null) {
        let current = time || defaultTime || dayjs();
        const minute = Math.floor(current.minute() / 5) * 5;
        current = current.startOf('hour');
        current = current.minute(minute);
        return current;
    }

    function handleSelectedChange(selected: Dayjs, v?: boolean) {
        if (endLogic && selectedTime.hour() === 0 && selectedTime.minute() === 0) {
            let current = selected.hour(selectedTime.hour());
            current = current.minute(selectedTime.minute());
            current = current.add(1, 'day');
            onSelectedTime(current, v);
            return;
        }
        let current = selected.hour(selectedTime.hour());
        current = current.minute(selectedTime.minute());
        onSelectedTime(current, v);
    }

    function onSelectedTime(time: Dayjs, v?: boolean) {
        setSelectedTime(time);
        onChange?.(time);
        setVisible(v ? v : false);
    }

    function handleDetailTimeChange(item: any, index: number) {
        const time = item.value;
        if (endLogic && time.hour() === 0 && time.minute() === 0) {
            const temp = selectedTime.startOf('day').add(1, 'day');
            onSelectedTime(temp);
            return;
        }
        // 如果选中时间默认是00:00， 由于特殊处理，时间已经加了一天了，所以，从00:00 也就是 24:00 变化为其他时间时，需要先减去一天
        if (endLogic && selectedTime.hour() === 0 && selectedTime.minute() === 0) {
            const temp = selectedTime.subtract(1, 'day').hour(time.hour()).minute(time.minute());
            onSelectedTime(temp);
            return;
        }
        const temp = selectedTime.hour(time.hour()).minute(time.minute());
        onSelectedTime(temp);
    }
    const { t } = useTranslation('work');

    function getContent() {
        return (
            <div className="calendar-date-picker-content">
                <BaseCalendar
                    isRepeatPicker={!!isRepeatPicker}
                    changeHandle={handleSelectedChange}
                    selectForever={() => {
                        onChange?.(null);
                        setVisible(false);
                    }}
                    time={endLogic ? selectedTime.subtract(1, 'millisecond') : selectedTime}
                    noFold={true}
                    isShowFooter={true}
                    isChangeHeaderVisible={isChangeHeaderVisible}
                />
            </div>
        );
    }
    function handleVisibleChange(visible: boolean) {
        onVisibleChange?.(visible);
        if (disable) {
            return;
        }
        setVisible(visible);
        if (visible) {
            setSelectedTime(formatInitTime(time));
        }
    }

    const label = useDefaultFormatTime(time, endLogic, isDateFormat);
    return (
        <>
            <Popover
                trigger="click"
                placement="bottom"
                content={getContent()}
                visible={visible}
                getPopupContainer={
                    configOfPopover
                        ? configOfPopover.getPopupContainer
                        : () => document.getElementById(calendarDatePickerId) as HTMLElement
                }
                overlayClassName="calendar-date-picker-popover"
                // overlayStyle={{ width: '226px', height: '264px' }}
                onVisibleChange={handleVisibleChange}
            >
                <div
                    id={calendarDatePickerId}
                    style={style}
                    className={`calendar-date-picker ${
                        visible ? 'calendar-date-picker-visible' : ''
                    } 
                    ${!time ? 'calendar-date-picker-notime' : ''}
                    ${disable ? 'calendar-date-picker-disable' : ''}
                    ${isRepeatPicker ? 'calendar-date-picker-repeat' : ''}
                    `}
                >
                    <span
                        className={`date ${isRepeatPicker ? 'repeat-date' : ''}`}
                        title={time ? label.date + (isEnd ? ' ' + t('deadline') : '') : placeholder}
                    >
                        {time ? label.date + (isEnd ? ' ' + t('deadline') : '') : placeholder}
                    </span>
                    {!!isRepeatPicker && (
                        <IconFont className="schedule-calendar" type="iconapp_ic_schedule11" />
                    )}
                </div>
            </Popover>
            {label.time && !isAllDay && (
                <TimePicker
                    className="mr8"
                    current={selectedTime}
                    data={endLogic ? endTimeList : startTimeList}
                    handleSelect={handleDetailTimeChange}
                >
                    <div className={`item ${styles['item']} time`}>
                        <span>{label.time}</span>
                    </div>
                </TimePicker>
            )}
        </>
    );
}

export default CalendarDatePicker;
