import React, { useEffect, useMemo, useState } from 'react';
import './index.less';
import { ChatMessageType, ImageChatMessage, Session, VideoChatMessage } from '@/types/chat';
import { useTranslation } from 'react-i18next';
import DefaultPage from '@/components/DefaultPage';
import { CompDataLoadState, DefaultPageType } from '@/models';
import ImService from '@/server/ImService';
import { List, message as toast, Space, Spin } from 'antd';
import { ny, nyrsfENStr } from '@/utils/date';
import Image from '../components/Image';
import { downloadMessageFile, getMessageFileName } from '@/utils/chat/index';
import { getPreviewImageModal, getVideoModal } from '../../utils/commonMessageModal';
import Loading from '@/components/Loading';
import { detectOS, FocusSDK, isFocusEnv } from '@/utils';
import { SaveOption } from '@/utils/DownloadFileQueue';
import { LoadingOutlined } from '@ant-design/icons';
export interface ISearchMediaProps {
    currentSession: Session;
    jumpTo?: (mid: number) => void;
    updateSessionMessageMid: Function;
    updateSessionMessagesTimestamp: Function;
}

const LIMIT_SIZE = 10;

function Panel(props: {
    title: string;
    messages: Array<any>;
    isDownload: boolean;
    curMsg: any;
    previewFunc: (message: any) => void;
}) {
    const { title, messages, isDownload, curMsg } = props;
    return (
        <div className="panel">
            <div className="panel-header">
                <div className="panel-header__title">{title}</div>
            </div>
            <div className="panel-body">
                <List
                    className="media-list"
                    // grid={{ gutter: 4, column: 4 }}
                    dataSource={messages}
                    renderItem={(item) => {
                        return (
                            <div
                                className="media-item"
                                key={item.mid}
                                onClick={() => props.previewFunc(item)}
                            >
                                <Image message={item as any} />
                                {isDownload &&
                                curMsg?.mid === item.mid &&
                                item.type === ChatMessageType.VIDEO ? (
                                    <div className="loading-layout">
                                        <Space>
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{ fontSize: 20, color: '#ffffff' }}
                                                    />
                                                }
                                            />
                                        </Space>
                                    </div>
                                ) : null}
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}

export default function SearchMedia(props: ISearchMediaProps) {
    const {
        currentSession,
        jumpTo,
        updateSessionMessageMid,
        updateSessionMessagesTimestamp,
    } = props;
    const [messages, setMessages] = useState<Array<any>>([]);
    const [isLoading, setLoading] = useState(true);
    const [isMore, setIsMore] = useState(false);
    const [curVideoPath, setCurVideoPath] = useState('');
    const [isDownload, setIsDownload] = useState(false);
    const [curMsg, setCurMsg] = useState<any>(null);
    const [isPreview, setIsPreview] = useState(true);
    const { t } = useTranslation('chat');
    const imService = useMemo(() => {
        return ImService.getInstance();
    }, []);
    const currentYearMonthStr = useMemo(() => {
        return ny(Date.now(), true);
    }, []);

    useEffect(() => {
        setLoading(true);
        // console.log(currentSession, 'currentSession');
        imService
            .search({
                sessionId: currentSession.sessionId,
                searchKey: '',
                startMid: -1,
                searchType: ChatMessageType.IMAGE,
                queryType: 2,
                limit: LIMIT_SIZE,
                composeVideo: true,
                desc: true,
            })
            .then((messages) => {
                const filterMessages = messages.length
                    ? messages.filter((item: any) => !!item.url)
                    : [];
                setIsMore(messages.length === LIMIT_SIZE ? true : false);
                setMessages(filterMessages as Array<ImageChatMessage | VideoChatMessage>);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [imService, currentSession]);
    const groupMessages: Record<
        string,
        Array<ImageChatMessage | VideoChatMessage>
    > = useMemo(() => {
        return messages.length
            ? messages.reduce<Record<string, Array<ImageChatMessage | VideoChatMessage>>>(
                  (prev, elem) => {
                      const msgYearMonthStr = ny(elem.timestamp, true);
                      let group = (prev[msgYearMonthStr] = prev[msgYearMonthStr] || []);
                      group.push(elem);
                      return prev;
                  },
                  {}
              )
            : {};
    }, [messages]);

    const downloadVideo = (message: any) => {
        setIsDownload(true);
        // console.log('1-playVideo');
        doDownloadMessageFile({
            viewFile: false,
            saveAs: false,
            auto: true,
            message,
        }).then((res: any) => {
            const { filename, saveAs } = res;
            if (filename && saveAs) {
                console.log('download-cb-true');
                const downLocalPath = `${saveAs}/${filename}`;
                getVideoModal(Object.assign(message, { downLocalPath }));
            } else {
                console.log('download-cb-false');
                getVideoModal(message);
            }
            setIsDownload(false);
        });
    };

    const isExistDownLocalVideoPath = (downLocalPath: string) => {
        return new Promise((resolve, reject) => {
            FocusSDK.checkLocalPathExist(downLocalPath, (exists: boolean) => {
                if (exists) {
                    setCurVideoPath(downLocalPath);
                    resolve(true);
                } else {
                    setCurVideoPath('');
                    resolve(false);
                }
            });
        });
    };

    const getDownloadOptions = ({ viewFile, saveAs, message, auto }: SaveOption) => {
        return {
            onStarted: () => {},
            onProgress: () => {},
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {},
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                // 当多个文件同时下载时，file.path 获取路径错误
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                // console.log('finish path', path, 'message id', message.mid);
                const imService = ImService.getInstance();
                const result = await imService.updateLocalPath(
                    (message as VideoChatMessage).sessionId,
                    (message as VideoChatMessage).mid,
                    {
                        downLocalPath: path,
                    }
                );
                if (result) {
                    updateSessionMessageMid({
                        sessionId: (message as VideoChatMessage).sessionId,
                        sendResult: { body: { ...message, downLocalPath: path } },
                    });
                    updateSessionMessagesTimestamp({
                        timestamp: new Date().getTime(),
                    });
                    setCurVideoPath(path);
                    // toast.success('下载完成');
                } else {
                    if (!auto) {
                        toast.error(t('failed'));
                    }
                }
            },
        };
    };

    const doDownloadMessageFile = ({
        viewFile,
        saveAs,
        message,
        auto = false,
    }: SaveOption): Promise<void> => {
        const options = getDownloadOptions({ viewFile, saveAs, message, auto });
        return downloadMessageFile({ viewFile, saveAs, message, auto }, options);
    };

    const previewImageOrVideo = async (message: any) => {
        // console.log('预览', message);
        if (isPreview) {
            setCurMsg(message);
            if (message.type === ChatMessageType.IMAGE) {
                getPreviewImageModal({ message });
                setIsPreview(false);
            } else {
                const downLocalPath = message.downLocalPath || '';
                if (isFocusEnv()) {
                    if (!downLocalPath) {
                        downloadVideo(message);
                    } else {
                        const isExist = await isExistDownLocalVideoPath(downLocalPath);
                        if (isExist) {
                            getVideoModal(message);
                            setIsPreview(false);
                        } else {
                            downloadVideo(message);
                            setIsPreview(false);
                        }
                    }
                } else {
                    getVideoModal(message);
                }
            }
            setTimeout(() => {
                setIsPreview(true);
            }, 2000);
        }
        return `${process.env.REACT_APP_NAME || '京办'}${nyrsfENStr(new Date().getTime())}.jpg`;
    };

    const loadMore = () => {
        const startMid = !isNaN(Number(messages[messages.length - 1].mid))
            ? Number(messages[messages.length - 1].mid)
            : 0;
        imService
            .search({
                sessionId: currentSession.sessionId,
                searchKey: '',
                startMid,
                searchType: ChatMessageType.IMAGE,
                queryType: 2,
                limit: LIMIT_SIZE,
                composeVideo: true,
                desc: true,
            })
            .then((data) => {
                if (!data?.length) {
                    setIsMore(false);
                    return;
                }
                const filterData = data.filter((item: any) => !!item.url);
                setIsMore(data.length === LIMIT_SIZE ? true : false);
                const newArr = messages.concat(
                    filterData as Array<ImageChatMessage | VideoChatMessage>
                );
                setMessages(newArr);
            })
            .catch((e) => {});
    };

    return (
        <div className="search-history search-media">
            <div className="search-history-result">
                <div className="message-list">
                    {!isLoading && messages.length === 0 && (
                        <div className="illustration" style={{ top: 262 }}>
                            <DefaultPage
                                dataType={CompDataLoadState.NODATA}
                                pageType={DefaultPageType.CHAT_HISTORY_IMG_VIDEO}
                            />
                        </div>
                    )}
                    {messages.length > 0 && (
                        <List
                            dataSource={Object.keys(groupMessages).map<
                                [string, Array<ImageChatMessage | VideoChatMessage>]
                            >((yearMonthStr: string) => [
                                yearMonthStr,
                                groupMessages[yearMonthStr] || [],
                            ])}
                            loading={
                                isLoading
                                    ? {
                                          indicator: (
                                              <div
                                                  style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      minHeight: '200px',
                                                      display: 'flex',
                                                      left: 0,
                                                      background: '#fff',
                                                  }}
                                              >
                                                  <Loading />
                                              </div>
                                          ),
                                      }
                                    : false
                            }
                            renderItem={([yearMonthStr, item]) => (
                                <Panel
                                    key={yearMonthStr}
                                    messages={item}
                                    title={
                                        yearMonthStr === currentYearMonthStr
                                            ? t('search.record.currentMonth')
                                            : yearMonthStr
                                    }
                                    isDownload={isDownload}
                                    curMsg={curMsg}
                                    previewFunc={(message: any) => {
                                        // setPreviewMessage(message);
                                        // setPreviewModalOpen(true);
                                        previewImageOrVideo(message);
                                    }}
                                />
                            )}
                        />
                    )}
                    {isMore && (
                        <div onClick={loadMore} className="media-load-more">
                            加载更多
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
