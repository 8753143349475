import React, { useContext } from 'react';
// import config from '../../config';
import { FileType, IPageBase } from '../../types';
import { PageType } from '../../const';
import { useTranslation } from '../../context';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import jsxss from 'xss';
import Cookies from 'js-cookie';
import { isFocusEnv } from '@/utils';
import openDeleteModal from '../../contextModal/DeleteModal';

if (!isFocusEnv()) {
    // eslint-disable-next-line
    require('@/components/Joyspace/winOpen');
}

export default function Pagelink(props: {
    page: IPageBase;
    children?: React.ReactNode;
    nolink?: boolean;
}) {
    const { page, nolink } = props;
    const lang = useTranslation();
    const { appRuntimeEnv, focusSDK } = useContext<GlobalContextConfig>(GlobalContext);

    // const content = (
    //     <>
    //         {page.title || lang('c.pagelink.notitle')} {props.children}
    //     </>
    // );

    function openLink(url: string, target: string) {
        focusSDK.printLog('newWindowDebug: joyspace: 打开文档列表 before：', url);
        window.open && focusSDK.printLog('joyspace: 打开文档文档列表 before has window.open：');
        try {
            window.open(url, target);
        } catch (e) {
            focusSDK.printLog('newWindowDebug: joyspace: 打开文档列表 error：', e);
        }
        focusSDK.printLog('newWindowDebug: joyspace: 打开文档列表 end：', url);
    }
    // console.log(
    //     page,
    //     'page===>',
    //     jsxss(page.title, {
    //         whiteList: {
    //             em: [],
    //         },
    //     })
    // );
    return nolink ? (
        <>
            <a
                href="javascript:void(0);"
                className="joyspace-page-link"
                onClick={(e) => {
                    if (!page.status) {
                        // console.log(page, 'pagepagepagepage');
                        openDeleteModal(FileType.Page, page.id);
                        return;
                    }
                    e.preventDefault();
                    openLink(createPageUrl(page, appRuntimeEnv.joyspaceHost), '_blank');
                }}
                onDragStart={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                title={page?.title}
                dangerouslySetInnerHTML={{
                    __html:
                        jsxss(page.title, {
                            whiteList: {
                                em: [],
                            },
                        }) || lang('c.pagelink.notitle'),
                }}
            />
            {props.children}
        </>
    ) : (
        <>
            <span
                className="joyspace-list-table-title-nolink"
                dangerouslySetInnerHTML={{
                    __html:
                        jsxss(page.title, {
                            whiteList: {
                                em: [],
                            },
                        }) || lang('c.pagelink.notitle'),
                }}
            />
            {props.children}
        </>
    );
    // return nolink ? (
    //     <>
    //         <a
    //             href={createPageUrl(page, appRuntimeEnv.joyspaceHost)}
    //             target="_blank"
    //             dangerouslySetInnerHTML={{ __html: page.title || lang('c.pagelink.notitle') }}
    //         />
    //         {props.children}
    //     </>
    // ) : (
    //     <>
    //         <span
    //             className="joyspace-list-table-title-nolink"
    //             dangerouslySetInnerHTML={{ __html: page.title || lang('c.pagelink.notitle') }}
    //         />
    //         {props.children}
    //     </>
    // );
}

export function createPageUrl(page: IPageBase | any, urlPrefix: string) {
    if (Number(page.space_type) === 1 || Number(page.team_type) === 3) {
        return `${urlPrefix}/spaces/${page.id}`;
    }
    if (page.page_type === PageType.Form) {
        return page.link;
    }
    let DocType = 'page';
    if (page.page_type === PageType.Sheet) {
        DocType = 'sheet';
    }
    if (page.page_type === PageType.File) {
        DocType = 'file';
    }
    if (page.page_type === PageType.Reporting) {
        DocType = 'report';
    }
    if (page.page_type === PageType.Link) {
        if (page.origin?.page_type === PageType.Sheet) {
            DocType = 'sheet';
        }
        if (page.origin?.page_type === PageType.File) {
            DocType = 'file';
        }
        if (page.origin?.page_type === PageType.Reporting) {
            DocType = 'report';
        }
    }
    return `${urlPrefix}/${DocType}/${page.id}`;
}
