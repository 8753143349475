/**
 * joy space 分享文档组件
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { useEffect } from 'react';
import './index.less';
import { JoySpaceDocumentMessage, MessageStatus, SessionType } from '@/types/chat';
import { JoyspaceDocumentType, MessageSendType } from '@/types/chat/enum';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import { documentPng, tablePng, weeklyPng } from '@/assets/img/file-icon';
import { useTranslation } from 'react-i18next';
import { message as AntdMessage } from 'antd';
import cache from '@/utils/cache';
import { FocusSDK, isFocusEnv } from '@/utils';
import { changeEgovUrl } from '@/utils/tools';
if (!isFocusEnv()) {
    // eslint-disable-next-line
    require('@/components/Joyspace/winOpen');
}

const { REACT_APP_JOYSPACE_HOST } = process.env;

interface ShareJdocProps {
    message: JoySpaceDocumentMessage;
    sessionType: SessionType;
    sendType: MessageSendType;
    onReady: (params: { beginSend: boolean; messageExtend: any }) => {};
}

type IShareJdocProps = Readonly<ShareJdocProps>;

export default function ShareJdoc(props: IShareJdocProps) {
    const { sendType, message, onReady } = props;

    const { t } = useTranslation('chat');

    useEffect(() => {
        if (sendType === MessageSendType.OWNER && message.status === MessageStatus.INIT) {
            onReady({ beginSend: true, messageExtend: {} });
        }
    }, [sendType, message, onReady]);

    const { sharelink } = message.data[0] || {};
    let extJson = {} as any;
    if (sharelink.ext) {
        extJson = attempt(JSON.parse, sharelink.ext || '{}');
        if (isError(extJson)) throw extJson;
    }

    const temp = {
        [JoyspaceDocumentType.DOCUMENT]: documentPng,
        [JoyspaceDocumentType.WEEK_REPORT]: weeklyPng,
        [JoyspaceDocumentType.TABLE]: tablePng,
    };

    const openJdoc = () => {
        if (sharelink?.url && sharelink.url.indexOf('http') > -1) {
            // window.open(
            //     `me://gm.open-joyspace?cid=${Date.now()}&app_params=` +
            //     encodeURIComponent(JSON.stringify({ url: sharelink.url }))
            // );
            let realUrl = sharelink.url;
            // if (realUrl.indexOf('joyspace') > -1) {
            //     let paramIndex = realUrl.indexOf('?');
            //     if (paramIndex > 0) {
            //         realUrl = realUrl.substring(0, paramIndex);
            //     }
            // }
            window.open(realUrl);
            // if (isFocusEnv()) {
            //     window.open(sharelink.url);
            //     return;
            // }
            // if (!REACT_APP_JOYSPACE_HOST || !cache.token) {
            //     AntdMessage.error(t('chat.invalidJoyspaceHost'));
            //     return;
            // }
            // const { focusTeamId, focusClient, focusToken } = cache.token.token;
            // const link = `${REACT_APP_JOYSPACE_HOST}/sso/redirect?returnUrl=${encodeURIComponent(
            //     sharelink.url
            // )}&focus-token=${focusToken}&focus-team-id=${focusTeamId}&focus-client=${focusClient}`;
            // window.open(link);
        }
    };
    const getIcon = (): any => {
        if (extJson?.type) {
            const type = extJson.type as JoyspaceDocumentType;
            return temp[type];
        } else if (sharelink.url.indexOf('/page/') > -1) {
            return documentPng;
        } else if (sharelink.url.indexOf('/sheet/') > -1) {
            return tablePng;
        } else if (sharelink.url.indexOf('/report/') > -1) {
            return weeklyPng;
        }
        return '';
    };

    // category: "jd_doc"
    const getContent = (): any => {
        if (sharelink?.content) {
            return sharelink.content.split('\r')[0];
        }
        return '';
    };

    return (
        <div className="content">
            {/* <MessageControl {...props} beginSend={beginSend} /> */}
            <div className="j-doc-share-card" onClick={openJdoc}>
                <img
                    src={changeEgovUrl(getIcon())}
                    alt=""
                    className="doc-share-icon"
                    draggable="false"
                />
                <div className="doc-share-title-content">
                    <div className="doc-s-title">{sharelink.title || t('unknow_name_doc')}</div>
                    <div className="doc-s-content">{getContent()}</div>
                </div>
            </div>
        </div>
    );
}
