/**
 * 群成员操作
 * @author sunzhiguang
 * @date 2020/7/14
 */

import React, { useCallback, useMemo } from 'react';
import './index.less';
import { GroupAttribute, GroupRoster, SessionProps, SessionType, UID } from '@/types/chat';
import { HeadShowType, TransferGroupAdminSource } from '@/types/chat/enum';
import { Dropdown, Menu, message } from 'antd';
import { useTranslation } from 'react-i18next';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import ImService from '@/server/ImService';
import HeadPortrait from '@/baseComponents/Chat/HeadPortrait';
import Prompt from '@/baseComponents/ModalComponent/prompt';
import { UserCardPayload } from '@/models';
import {
    employeeToUid,
    isCardViewGroupSession,
    isEqualEmployee,
    isEqualUid,
    isGroupVisibleUnit,
} from '@/components/chat/utils/message';
import {
    checkShowDropdownOperate,
    isGroupAdmin,
    isGroupOrdinary,
    isGroupOwner,
    isOrdinaryRoster,
} from '@/components/chat/utils/group';
import { useTransferGroupOwner } from '@/components/chat/GroupOperation';
import IconFont from '@/components/icon';
import Context from '@/components/chat/context';
import { getDeptPositionDesc } from '@/models/user';

interface MemberOperateProps {
    member: GroupRoster;
}

interface DvaDispatchProps {
    removeGroupMember: Function;
    toggleUserCardModal: (payload: UserCardPayload) => void;
    loadSessionMembers: Function;
}

type IProps = Readonly<
    MemberOperateProps & DvaDispatchProps & ChatState & Partial<{ getAllGroupMembers?: Function }>
>;

function GroupMemberOperate(props: IProps) {
    const {
        selectedSession,
        member,
        removeGroupMember,
        toggleUserCardModal,
        getAllGroupMembers,
        loadSessionMembers,
    } = props;
    const { currentEmployee, groupRosters, groupRosterIdentity } = React.useContext(Context);
    const { t } = useTranslation('chat');

    const transferGroupOwner = useTransferGroupOwner({
        selectedSession,
        groupRosters,
        groupRoster: member,
        operateType: TransferGroupAdminSource.GROUP_MEMBER_OPERATE,
        callback: getAllGroupMembers,
    });
    // 设置管理员
    const setGroupAdmin = useCallback(
        async (action) => {
            const members = [employeeToUid(member.info)];
            if (selectedSession.id) {
                const instance = ImService.getInstance();
                const result = await instance.setGroupAdmin(
                    selectedSession.sessionId,
                    members,
                    action
                );
                if (result) {
                    getAllGroupMembers?.();
                    loadSessionMembers({ selectedSession: selectedSession });
                    message.success(action === 'DELETE_ADMIN' ? '移除成功' : '添加成功');
                } else {
                    message.error(action === 'DELETE_ADMIN' ? '移除失败' : '添加失败');
                }
            }
        },
        [member] //eslint-disable-line
    );

    const setConfirmEdit = useCallback(async () => {
        const members = [employeeToUid(member.info)];
        if (selectedSession.id) {
            const instance = ImService.getInstance();
            const result = await instance.removeGroupMembers(selectedSession.sessionId, members);
            if (result) {
                // console.log('删除成功');
                await removeGroupMember({ member: members[0] });
                let groupInfo = selectedSession.info as GroupAttribute;
                getAllGroupMembers?.(groupInfo.gid);
                message.success(t('removed-successfully'));
            } else {
                message.error(t('removal-failed'));
            }
        }
    }, [member]); //eslint-disable-line

    // 点击设置管理员
    const confirmSetGroupAdmin = useCallback(
        (action: string) => {
            Prompt({
                title:
                    action === 'ADD_ADMIN' ? t('add-admin-reminder') : t('delete-admin-reminder'),
                icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
                content: t(action === 'ADD_ADMIN' ? 'add-admin-prompt' : 'delete-admin-prompt', {
                    memberName: member.info.name,
                }),
                cancelText: t('cancelText'),
                onOk: () => setGroupAdmin(action),
                okText: t('determine'),
            });
        },
        [member, setGroupAdmin, t]
    );

    // 点击移除成员
    const removeMembers = useCallback(() => {
        Prompt({
            title: t('remove-members-reminder'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            content: t('remove-members-prompt', {
                memberName: member.info.name,
            }),
            cancelText: t('cancelText'),
            onOk: setConfirmEdit,
            okText: t('determine'),
        });
    }, [member]); // eslint-disable-line

    const currentRosterIsOwner = useMemo(() => {
        return isGroupOwner(groupRosterIdentity);
    }, [groupRosterIdentity]);

    const currentRosterIsAdmin = useMemo(() => {
        return isGroupAdmin(groupRosterIdentity);
    }, [groupRosterIdentity]);

    const transferOwnerAction = useMemo(() => {
        // 只有群主才可以进行转让
        if (currentRosterIsOwner) {
            return (
                <Menu.Item
                    key="transferOwner"
                    onClick={transferGroupOwner}
                    clstag="pageclick|keycount|focus_chat_01_1615797500283|90"
                >
                    {t('transfer-group-owner')}
                </Menu.Item>
            );
        }
    }, [transferGroupOwner, t, currentRosterIsOwner]);

    const setAdmin = useMemo(() => {
        // 只有群主才可以设置管理员
        if (currentRosterIsOwner) {
            if (isGroupAdmin(member.identity)) {
                return (
                    <Menu.Item
                        key="removeAdmin"
                        onClick={() => confirmSetGroupAdmin('DELETE_ADMIN')}
                    >
                        {t('remove-admin')}
                    </Menu.Item>
                );
            } else {
                return (
                    <Menu.Item key="addAdmin" onClick={() => confirmSetGroupAdmin('ADD_ADMIN')}>
                        {t('add-admin')}
                    </Menu.Item>
                );
            }
        }
    }, [confirmSetGroupAdmin, t, member.identity, currentRosterIsOwner]);

    const removeMembersAction = useMemo(() => {
        // 当前用户是群主 或者 是管理员 且况操作对象是群成员
        if (currentRosterIsOwner || (currentRosterIsAdmin && isOrdinaryRoster(member))) {
            return (
                <Menu.Item
                    key="removeMembers"
                    onClick={removeMembers}
                    clstag="pageclick|keycount|focus_chat_01_1615797500283|88"
                >
                    {t('removeMembers')}
                </Menu.Item>
            );
        }
    }, [currentRosterIsOwner, currentRosterIsAdmin, member, removeMembers, t]);

    const showDropdownOperate = useMemo(() => {
        return checkShowDropdownOperate({
            selectedSession,
            groupRosterIdentity,
            member,
            currentEmployee,
        });
    }, [selectedSession, groupRosterIdentity, member, currentEmployee]);

    const removeMemberPart = useMemo(() => {
        if (!showDropdownOperate) {
            return null;
        }
        if (!(currentRosterIsOwner || (currentRosterIsAdmin && isOrdinaryRoster(member)))) {
            return null;
        }
        return (
            <div
                className="remove-operate-wrapper"
                onClick={removeMembers}
                clstag="pageclick|keycount|Xtbg_Msg_Setting|RemoveMember"
            >
                <IconFont className="remove-icon" type="iconic_pc_im_close" />
            </div>
        );
    }, [showDropdownOperate, currentRosterIsOwner, currentRosterIsAdmin, member, removeMembers]);

    // 操作功能
    const dropdownPart = useMemo(() => {
        if (!showDropdownOperate) {
            return null;
        }

        if (!transferOwnerAction && !setAdmin && !removeMembersAction) {
            return null;
        }

        return (
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu>
                        {transferOwnerAction}
                        {setAdmin}
                        {removeMembersAction}
                    </Menu>
                }
                placement="topLeft"
                overlayClassName="leader-set"
            >
                <span className="group-member-item-operate">···</span>
            </Dropdown>
        );
    }, [showDropdownOperate, transferOwnerAction, setAdmin, removeMembersAction]);

    const openUserCard = (data: any) => {
        const { info } = data;
        const { userId, teamId, app } = info || {};

        // 如果群成员并且禁止了查看群成员名片
        if (
            !isEqualEmployee(currentEmployee, info) &&
            isGroupOrdinary(groupRosterIdentity) &&
            !isCardViewGroupSession(selectedSession)
        ) {
            message.warn(t('can_not_open_user_card_tip'));
            return false;
        }

        toggleUserCardModal({ visible: true, userId, teamId, appId: app });

        // if (isFocusEnv() && !isSupportEnv()) {
        //     FocusSDK.openUserCard({ type: UserCardType.User, userId, teamId, appId: app });
        // } else {
        //     toggleUserCardModal({ visible: true, userId, teamId, appId: app });
        // }
    };
    const desc = () => {
        const { user } = member as any;
        const descinfo = !isGroupVisibleUnit(selectedSession)
            ? getDeptPositionDesc(user.unitName, user.titleName)
            : user.desc;
        return descinfo;
    };
    return (
        <div className="group-member-item">
            {/* {dropdownPart} */}
            <HeadPortrait
                width="32px"
                height="32px"
                showType={HeadShowType.GROUP_MEMBER}
                imgUrl={member.info.avatar}
                name={member.info.name}
                type={SessionType.SINGLE}
                isLeader={isGroupOwner(member.identity)}
                isAdmin={isGroupAdmin(member.identity)}
                isUnFollow={true}
                onItemClick={() => {
                    openUserCard(member);
                }}
                desc={desc()}
                clstag="pageclick|keycount|Xtbg_Msg_Setting|MemberAvatar"
            />
            {removeMemberPart}
        </div>
    );
}

function mapStateToProps({ chat }: { chat: ChatState }) {
    return {
        selectedSession: chat.selectedSession,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async removeGroupMember(data: { member: UID }) {
            await dispatch({ type: 'chat/removeGroupMember', payload: data });
        },
        toggleUserCardModal(payload: UserCardPayload) {
            dispatch({ type: 'app/toggleUserCardModal', payload });
        },
        async loadSessionMembers(data: { selectedSession: SessionProps }) {
            await dispatch({ type: 'chat/loadSessionMembers', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMemberOperate);
