import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import colors from '@/components/styles/colors';
import { BrowserViewContext } from '../BrowserView/utils';
import { IconClose } from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import logger from '@/utils/logger';
import { changeEgovUrl } from '@/utils/tools';

const log = logger.getLogger('WebViewModal');

export interface WebViewModalProps {
    visible: boolean;
    src: string;
    styles: any;
    title?: React.CSSProperties;
    closeable?: boolean;
}
const loadingComp = (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: colors.primary }} spin />} />
);
export default function WebViewModal({
    styles,
    src,
    visible,
    title,
    closeable,
}: {
    visible: boolean;
    src: string;
    styles: React.CSSProperties;
    title?: string;
    closeable?: boolean;
}) {
    // const { visible, src, styles } = props;
    const webview = React.useRef<HTMLWebViewElement | null>(null);
    const [loading, setLoading] = useState(true);
    const { openWebViewModal, closeWebViewModal, openCommonModal } = React.useContext(
        BrowserViewContext
    );

    const { appRuntimeEnv, authInfo, focusSDK } = React.useContext<GlobalContextConfig>(
        GlobalContext
    );
    function handleDomReady() {
        setLoading(false);
        if (focusSDK.isBeta()) {
            // @ts-ignore
            webview.current.openDevTools();
        }
    }
    function handleFailedLoad() {
        setLoading(false);
    }
    useEffect(() => {
        if (!visible) {
            setLoading(true);
        }
    }, [visible]);
    const handleClientMessages = ({ cid, type, data }: any) => {
        // const webview = this.webviewRef.current as any;
        // const { config: contextConfig, service } = context as GlobalContextType;
        log.debug('webview  <-', { cid, type, data });
        const { userDataForWeb } = appRuntimeEnv;
        log.debug('AppSdk ==>', type);
        const wv = webview.current as any;
        switch (type) {
            case 'getAuthInfo':
                log.debug('AppSdk ==>', 'getAuthInfo', JSON.stringify(userDataForWeb));
                wv.send('clientMessage', {
                    cid,
                    data: userDataForWeb,
                });
                break;
            case 'openWebViewModal': {
                if (data) {
                    openWebViewModal(data);
                }
                // ipcRenderer.emit('openWebViewModal', null, 'about');
                break;
            }
            case 'closeWebViewModal': {
                closeWebViewModal();
                // ipcRenderer.emit('openWebViewModal', null, 'about');
                break;
            }
            case 'openCommonModal': {
                if (data) {
                    openCommonModal(data);
                }

                // ipcRenderer.emit('openWebViewModal', null, 'about');
                break;
            }

            default:
        }
    };
    const handleIPCMessage = (event: any) => {
        const { args, channel } = event;
        log.debug('-->', { args, channel });
        const [methodContent] = args || []; // 调用方法内容 方法名 方法参数
        switch (channel) {
            case 'clientMessage':
                if (methodContent) {
                    handleClientMessages(methodContent);
                }
                break;
            default:
                break;
        }
    };

    const bindEventsCb = React.useCallback(() => {
        if (!webview.current) return;

        webview.current.removeEventListener('ipc-message', handleIPCMessage);
        webview.current.addEventListener('ipc-message', handleIPCMessage);

        webview.current.removeEventListener('did-fail-load', handleFailedLoad);
        webview.current.addEventListener('did-fail-load', handleFailedLoad);

        webview.current.removeEventListener('dom-ready', handleDomReady);
        webview.current.addEventListener('dom-ready', handleDomReady);
    }, []); //eslint-disable-line

    const style: React.CSSProperties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        // width: 500,
        // height: 500,
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        borderRadius: 6,
        paddingTop: 6,
    };
    if (visible) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    background: 'rgba(0,0,0,.5)',
                }}
                onClick={() => closeWebViewModal()}
            >
                <div
                    style={{
                        ...style,
                        width: styles.width,
                    }}
                >
                    <div>
                        <div
                            className="header"
                            style={{
                                height: 55,
                                width: '100%',
                                borderBottom: '1px solid #F0F3F3',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                color: '#62656D',
                                padding: '0 24px',
                            }}
                        >
                            <p
                                className="title"
                                style={{
                                    color: colors.black2,
                                    fontSize: 16,
                                    margin: 0,
                                }}
                            >
                                {title}
                            </p>
                            <div
                                style={{
                                    cursor: 'pointer',
                                    fontSize: 12,
                                }}
                                onClick={() => closeWebViewModal()}
                            >
                                {closeable ? IconClose : null}
                            </div>
                        </div>
                        <div
                            className="content"
                            style={{
                                position: 'relative',
                                height: styles.height,
                            }}
                        >
                            <webview
                                webpreferences="allowRunningInsecureContent"
                                ref={(ref) => {
                                    webview.current = ref;
                                    bindEventsCb();
                                }}
                                style={{ ...styles }}
                                id="webview-modal-screen"
                                src={changeEgovUrl(src)}
                                preload={focusSDK.getAppPreloadJSPath()}
                            />
                            {loading ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        zIndex: 200,
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                    }}
                                >
                                    {loadingComp}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div
            style={{
                display: 'none',
            }}
        />
    );
}
