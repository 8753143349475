/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';
import { message as toast, Modal } from 'antd';
import { connect } from 'dva';
import { FocusSDK, useDebounce, isBjme } from '@/utils';
import { ExternalContactsStatus } from '@/types/chat/enum';
import ImService from '@/server/ImService';
import ChatState, { ApprExternalContacts } from '@/types/chat/State';
import { Employee, Session } from '@/types/chat';
import dayjs, { Dayjs } from 'dayjs';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import logger from '@/utils/logger';
import { updateApprExternalContacts } from '@/api/chat';
import { setExternalContactsTipsTool } from '@/utils/chat/index';
import { debounce } from 'lodash';
import { UserState } from '@/models/user';
import { processJoyspaceUrl } from '@/components/Joyspace/winOpen';
import { changeEgovUrl } from '@/utils/tools';

import { handleLinkClick } from '../AINoticeCard/utils';
import ApplyTeamCard from '../AINoticeCard/ApplyTeamCard';
import JoyMeetingNoticeCard from '../AINoticeCard/JoyMeetingNoticeCard';
import CloudLinkNoticeCard from '../AINoticeCard/CloudLinkNoticeCard';
import IdentityInfoToPerfect from '../AINoticeCard/IdentityInfoToPerfect';
import ExternalNewUserNoticeCard from '../AINoticeCard/ExternalNewUserNoticeCard';
import ApplyGroupCard from '../AINoticeCard/ApplyGroupCard';

const log = logger.getLogger('NoticeCard');

const NoticeCard = connect(
    function ({ chat, user }: { chat: ChatState; user: UserState }) {
        const { selectedSession, currentEmployee } = chat;
        return { selectedSession, useData: user.userData.user, currentEmployee };
    },
    function (dispatch: any) {
        return {
            updateCurrentEmployee(payload: any) {
                dispatch({ type: 'chat/updateCurrentEmployee', payload });
            },
        };
    }
)(function ({
    message,
    useData,
    updateCurrentEmployee,
}: {
    message: any;
    selectedSession: Session;
    useData: any;
    updateCurrentEmployee: Function;
}) {
    const { title, content, extend, infox } = message;
    const from = message?.from || {};
    // eslint-disable-next-line no-console
    // console.log('NoticeCardmessage===', message);
    const [t] = useTranslation('chat');

    if (from.pin === '~me69') {
        return <CalendarNoticeCard message={message} />;
        // return <NewUserNoticeCard message={message} />;
    }

    // 新用户申请
    if (from.pin === 'eopen-push_yQXxq4WhIpF2CAdw01Ti') {
        return <ExternalNewUserNoticeCard message={message} />;
    }

    // 群助手
    if (from.pin === 'eopen-push_uUyYexheoyRkjp6DpVtx') {
        return <ApplyGroupCard message={message} />;
    }

    // 团队助手
    if (from.pin === 'eopen-push_0GABWH9r96OvSSU0951x') {
        return <ApplyTeamCard message={message} />;
    }

    if (from.pin === 'eopen-push_jif5OZ0gf6qNotBVAraI') {
        return <JoyMeetingNoticeCard message={message} />;
    }

    if (
        from.pin === 'eopen-push_4iJ0jfgCLTXdz6YED06W' ||
        from.pin === 'eopen-push_iZ1ZpJzbzAdprQiPoImD'
    ) {
        return <CloudLinkNoticeCard message={message} />;
    }

    if (from.pin === 'eopen-push_PZzzvgDrpZVAefKImEfo') {
        return <WorkNoticeCard message={message} />;
    }
    // 督办
    if (from.pin === 'eopen-push_0Gl8b1I3si21n0VVNkrF') {
        return <SuperviseNoticeCard message={message} />;
    }
    // 北京通号去完善
    if (from.pin === 'eopen-push_9IdJIcxdZmWRmXNLw3Qc') {
        return <IdentityInfoToPerfect message={message} />;
    }
    // 党史学习图文
    const [isShow, setIsShow] = useState(true);
    const src = infox?.imgUrl || infox?.pic;

    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}
            {src && isShow ? (
                <div className="img-wrapper">
                    <img
                        alt=""
                        onLoad={() => {
                            setIsShow(true);
                        }}
                        onError={() => {
                            setIsShow(false);
                        }}
                        onClick={() => {
                            // console.log('src----', src);
                            FocusSDK.openUrl(src);
                        }}
                        src={changeEgovUrl(src)}
                    />
                </div>
            ) : null}
            {extend?.url && (
                <div className="notice-card-action">
                    <div
                        className="notice-card-action-view"
                        onClick={() => handleLinkClick(extend.url || '')}
                    >
                        {t('click-to-view')}
                    </div>
                </div>
            )}
        </div>
    );
});

export default NoticeCard;

const CalendarNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            changeScheduleInfoDraw: (payload: {
                visible: boolean;
                scheduleId: string;
                selectedScheduleTime: Dayjs;
                from: number;
                openTime: Dayjs;
            }) =>
                dispatch({
                    type: 'calendar/changeScheduleInfoDraw',
                    payload,
                }),
        };
    }
)(
    ({
        message,
        changeScheduleInfoDraw,
    }: {
        message: any;
        changeScheduleInfoDraw: (payload: {
            visible: boolean;
            scheduleId: string;
            selectedScheduleTime: Dayjs;
            from: number;
            openTime: Dayjs;
        }) => void;
    }) => {
        const { title, content, infox } = message;
        const [t] = useTranslation('chat');
        const { deepLink } = infox;
        // jdme://jm/page_calendar_edit?mparam=%7B%22calendarId%22%3A%22270603332890918912%22%7D
        const handleClick = useCallback(() => {
            // const link = decodeURIComponent(deepLink);

            // const strs = link.split('?mparam=');
            // if (strs.length !== 2) {
            //     return;
            // }
            // const mparam = JSON.parse(strs[1]);
            // changeScheduleInfoDraw({
            //     visible: true,
            //     scheduleId: mparam.scheduleId,
            //     selectedScheduleTime: dayjs(mparam.beginTime),
            //     calendarId: mparam.calendarId,
            // });
            DeeplinkEvent.open(deepLink);
        }, [deepLink]);
        return (
            <div className="notice-card">
                <div className="notice-card-title">{title}</div>
                {content && <div className="notice-card-content">{content}</div>}

                {deepLink && (
                    <div className="notice-card-action" onClick={handleClick}>
                        <div className="notice-card-action-view">{t('click-to-view')}</div>
                    </div>
                )}
            </div>
        );
    }
);

const WorkNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            changePersonalTaskDraw: (taskId: string) =>
                dispatch({
                    type: 'work/changePersonalTaskDraw',
                    payload: { taskId, drawVisible: true },
                }),
            changeFullIframeModal: (visible: boolean, url: string) =>
                dispatch({
                    type: 'work/changeFullIframeModal',
                    payload: { fullIframeModalVisible: visible, fullIframeModalUrl: url },
                }),
        };
    }
)(function ({
    changePersonalTaskDraw,
    message,
    changeFullIframeModal,
}: {
    changePersonalTaskDraw: (taskId: string) => void;
    changeFullIframeModal: (visible: boolean, url: string) => void;
    message: any;
}) {
    const { title, content, extend, from, infox } = message;
    const [t] = useTranslation('chat');
    const handleClick = useDebounce(() => {
        log.debug('WorkNoticeCard click', { infox, message });
        if (infox.extraConf) {
            const extraConf = JSON.parse(infox.extraConf);
            const { openView } = extraConf;
            switch (openView) {
                case 1:
                    break;
                case 3:
                    DeeplinkEvent.open(infox.pcHomeUrl);
                    // if (isFocusEnv()) {
                    //     DeeplinkEvent.open(infox.pcHomeUrl);
                    //     // window.open(infox.pcHomeUrl);
                    //     return;
                    // }
                    // changeFullIframeModal(true, infox.pcHomeUrl || '');
                    return;
                default:
                    break;
            }
            return;
        }
        if (infox.taskId) {
            changePersonalTaskDraw(infox.taskId);
        } else {
            DeeplinkEvent.open(infox.deepLink);
        }
    }, 250);
    if (infox?.msgType === '1') {
        return (
            <div className="notice-card">
                <div className="notice-card-title">{infox.msgTitle}</div>
                {content && (
                    <div className="notice-card-content">
                        <div>任务名称：{infox.taskName}</div>
                        <div>
                            截止时间：
                            {infox.endTime
                                ? dayjs(Number(infox.endTime)).format('YYYY年M月D日 HH:mm')
                                : '无截止时间'}
                        </div>
                    </div>
                )}

                {infox.deepLink && (
                    <div className="task-notice-card-action">
                        <div
                            className="task-notice-card-action-view"
                            clstag="pageclick|keycount|xtbg_add_task|xtbg_add_task_Aiassistant_viewdetail"
                            onClick={() => handleClick()}
                        >
                            查看详情
                        </div>
                    </div>
                )}
            </div>
        );
    }
    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}

            {infox.deepLink && (
                <div className="notice-card-action">
                    <div className="notice-card-action-view" onClick={() => handleClick()}>
                        {t('click-to-view')}
                    </div>
                </div>
            )}
        </div>
    );
});

const SuperviseNoticeCard = connect(
    function () {
        return {};
    },
    function (dispatch: any) {
        return {
            setDetailDrawerVisible: (superviseId: string) =>
                dispatch({
                    type: 'supervise/setDetailDrawerVisible',
                    payload: {
                        visible: true,
                        superviseId,
                    },
                }),
        };
    }
)(function ({
    message,
    setDetailDrawerVisible,
}: {
    message: any;
    setDetailDrawerVisible: (s: any) => void;
}) {
    const { title, content, infox } = message;
    const [t] = useTranslation('chat');
    const handleClick = useDebounce(() => {
        setDetailDrawerVisible(infox?.superviseId);
    }, 250);
    return (
        <div className="notice-card">
            <div className="notice-card-title">{title}</div>
            {content && <div className="notice-card-content">{content}</div>}

            <div className="notice-card-action">
                <div className="notice-card-action-view" onClick={() => handleClick()}>
                    {t('click-to-view')}
                </div>
            </div>
        </div>
    );
});
