import React, { useState } from 'react';
import conf from './statusConf';
import Icon from '../../Icon';
import './index.less';

interface IProps {
    status: number;
    onChange: (value: number) => void;
    archive?: boolean;
}

export default function (props: IProps) {
    const { archive } = props;
    const [status, setStatus] = useState<number>(props.status ?? 1);
    const allProjectStatus = archive ? conf.allStatus : conf.canEditStatus;
    return (
        <div className="pm-project-status-wrap">
            {allProjectStatus.map((item) => (
                <span
                    key={item.id}
                    style={{
                        borderColor: item.color,
                        color: status === item.id ? 'white' : item.color,
                        backgroundColor: status === item.id ? item.color : '',
                    }}
                    onClick={() => {
                        if (archive) return;
                        setStatus(item.id);
                        props.onChange(item.id);
                    }}
                >
                    {status === item.id && (
                        <Icon type="iconapp_btn_radio_selected" className="pm-status-check" />
                    )}
                    {item.text}
                </span>
            ))}
        </div>
    );
}
