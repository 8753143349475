import { TaskModel, WorkUserInfo, TimeClassifyEnum } from '@/types/work';
import {
    getPersonalList,
    queryTasks,
    editTaskItem,
    getEbookChildDept,
    deptTaskList,
    statDeptTask,
    getDeptStatAuth,
} from '@/api/work';
import { message } from 'antd';
import { decoratePersonalTask, getTimeClassifyByTime } from '@/components/Work/utils';

import {
    TaskListParams,
    IEditTaskItemParam,
    PROCESS_STATUS,
    TASK_STATUS,
    PRIORITY_TYPE,
    NewTaskModel,
} from '@/types/newWork';
export enum WorkTabEnum {
    personal = 'personal',
}

export interface ITimeBlock {
    id: string;
    title: string;
    timeClassify: number;
    color: string;
    list: TaskModel[];
    total: number;
    lastTaskId: string;
}

interface State {
    tab: WorkTabEnum;
    personalTimeBlocks: ITimeBlock[];
    detailState: {
        drawVisible: boolean;
        taskId: string;
        drawEditVisible: string;
    };
    taskList: NewTaskModel[];
    departmentalTaskList: NewTaskModel[];
    taskStatuses: TASK_STATUS[];
}
/**
 * 从personalTimeBlocks里更新任务
 * @param param0
 */
function updatePersonalTask({
    state,
    task,
    timeBlockId,
}: {
    state: State;
    task: TaskModel;
    timeBlockId: string;
}) {
    const { personalTimeBlocks } = state;
    const index0 = personalTimeBlocks.findIndex((t) => {
        if (timeBlockId) {
            return t.id === timeBlockId;
        }
        const index = t.list.findIndex((l) => l.taskId === task.taskId);
        return index > -1;
    });
    if (index0 === -1) {
        return personalTimeBlocks;
    }
    const timeBlock = { ...personalTimeBlocks[index0] };

    const { list } = timeBlock;
    const index = list.findIndex((l) => l.taskId === task.taskId);
    if (index === -1) {
        return personalTimeBlocks;
    }
    timeBlock.list = [...list];
    timeBlock.list.splice(index, 1, { ...task });
    const newPersonalTimeBlocks = [...personalTimeBlocks];
    newPersonalTimeBlocks.splice(index0, 1, timeBlock);
    return newPersonalTimeBlocks;
}

function deleteTaskFromTimeBlocks({
    timeBlocks,
    timeBlockId,
    taskId,
}: {
    timeBlocks: ITimeBlock[];
    timeBlockId?: string;
    taskId: string;
}) {
    const index0 = timeBlocks.findIndex((t) => {
        if (timeBlockId) {
            return t.id === timeBlockId;
        }
        const index = t.list.findIndex((l) => l.taskId === taskId);
        return index > -1;
    });
    if (index0 === -1) {
        return timeBlocks;
    }
    const timeBlock = { ...timeBlocks[index0] };
    const list = [...timeBlock.list];
    const index = list.findIndex((l) => l.taskId === taskId);
    if (index === -1) {
        return timeBlocks;
    }
    timeBlock.list = list;
    timeBlock.list.splice(index, 1);
    timeBlock.total = timeBlock.total - 1;
    const newtimeBlocks = [...timeBlocks];
    newtimeBlocks.splice(index0, 1, timeBlock);
    return newtimeBlocks;
}

function addTemporyTaskFromTimeBlocks({
    timeBlocks,
    task,
}: {
    timeBlocks: ITimeBlock[];
    task: TaskModel;
}) {
    const timeBlock = { ...timeBlocks[0] };
    timeBlock.list = [task, ...timeBlock.list];
    const newTimeBlocks = [...timeBlocks];
    newTimeBlocks.splice(0, 1, timeBlock);
    return newTimeBlocks;
}

function addTaskListFromTimeBlocks({
    timeBlockId,
    timeBlocks,
    tasks,
    offset,
    total,
    limit,
    lastTaskId,
    timeClassify,
}: {
    timeBlocks: ITimeBlock[];
    timeBlockId: string;
    tasks: TaskModel[];
    offset: number;
    total: number;
    lastTaskId: string;
    limit: number;
    timeClassify: number;
}) {
    const timeBlock = timeBlocks[Number(timeBlockId)];
    const newList = offset === 0 ? [] : [...timeBlock.list];

    // 2. 更新对应的列表, 去重一下
    decoratePersonalTask(tasks).forEach((t) => {
        const index = newList.findIndex((n) => n.taskId === t.taskId);
        if (index === -1) {
            newList.push(t);
        } else {
            newList.splice(index, 1, t);
        }
    });
    timeBlock.list = newList;
    timeBlock.total = total;
    timeBlock.lastTaskId = lastTaskId;
    timeBlocks[Number(timeBlockId)] = { ...timeBlock };

    const tempBlock = timeBlocks[0];

    // 如果加载回来的数据少于请求数据，默认已经全部加载完成，更新total为本地总数，
    // 防止由于自动补齐10条的逻辑导致死循环加载数据，一般不会触发此操作，但是后台分页偶尔会出问题，导致拉取数量小于limit
    if (tasks.length < limit) {
        timeBlock.total = timeBlock.list.length;
    }

    // 如果有7天后时间块的更新，清空临时区域
    if (timeClassify === TimeClassifyEnum.LaterOrUntime) {
        tempBlock.list = [];
        tempBlock.total = 0;
    }
    return [...timeBlocks];
}

export default {
    namespace: 'work',
    state: {
        tab: 'personal',
        personalTimeBlocks: [
            {
                id: '0',
                title: 'temporaryBlock',
                timeClassify: 0,
                list: [],
                total: 0, // 临时位置不需要总数
                color: '#EEEEEE',
            },
            {
                id: '1',
                title: 'overdue',
                timeClassify: 1,
                list: [],
                total: 0,
                color: '#F6642D',
            },
            {
                id: '2',
                title: 'today',
                color: '#8CCC48',
                timeClassify: 2,
                list: [],
                total: 0,
            },
            {
                id: '3',
                title: 'in week',
                color: '#379AFF',
                timeClassify: 3,
                list: [],
                total: 0,
            },
            {
                id: '4',
                title: 'later or untime',
                color: '#4751C8',
                timeClassify: 4,
                list: [],
                total: 0,
            },
            {
                id: '5',
                title: 'finished',
                color: '#8899A4',
                timeClassify: 5,
                list: [],
                total: 0,
            },
        ],
        teamTimeBlocks: [
            {
                id: '0',
                title: 'temporaryBlock',
                timeClassify: 0,
                list: [],
                total: 0, // 临时位置不需要总数
                color: '#EEEEEE',
            },
            {
                id: '1',
                title: 'overdue',
                timeClassify: 1,
                list: [],
                total: 0,
                color: '#F6642D',
            },
            {
                id: '2',
                title: 'today',
                color: '#8CCC48',
                timeClassify: 2,
                list: [],
                total: 0,
            },
            {
                id: '3',
                title: 'in week',
                color: '#379AFF',
                timeClassify: 3,
                list: [],
                total: 0,
            },
            {
                id: '4',
                title: 'later or untime',
                color: '#4751C8',
                timeClassify: 4,
                list: [],
                total: 0,
            },
            {
                id: '5',
                title: 'finished',
                color: '#8899A4',
                timeClassify: 5,
                list: [],
                total: 0,
            },
        ],
        detailState: {
            drawVisible: false,
            taskId: '',
            fullIframeModalVisible: false,
            fullIframeModalUrl: '',
            forceGet: false,
            activeTab: 'basicInfo',
            drawEditVisible: false,
            taskComeFrom: '',
        },
        taskList: [],
        total: 0,
        departmentalTaskList: [],
        departmentalTotal: 0,
        offset: 0,
        offsets: [0], // 存储offset
        processStatuses: [],
        taskStatuses: [1],
        priorityTypes: [1, 2, 3, 4],
        userRole: 1,
        sortType: 1,
        taskLoading: false,
    },
    reducers: {
        setDepartmentalTask(
            state: any,
            { payload }: { payload: { departmentalTotal?: number; departmentalTaskList?: [] } }
        ) {
            return { ...state, ...payload };
        },
        setTaskLoading(state: any, { payload }: { payload: boolean }) {
            return { ...state, taskLoading: payload };
        },
        changeTab(state: State, { payload }: { payload: { tab: WorkTabEnum } }) {
            const { tab } = payload;
            return { ...state, tab };
        },
        setOffset(state: any, { payload }: { payload: number }) {
            return {
                ...state,
                offset: payload,
            };
        },
        setOffsets(state: any, { payload }: { payload: number[] }) {
            return {
                ...state,
                offsets: payload,
            };
        },
        setProcessStatus(state: any, { payload }: { payload: PROCESS_STATUS[] }) {
            return {
                ...state,
                offsets: [0],
                offset: 0,
                processStatuses: payload,
            };
        },
        setUserRole(state: any, { payload }: { payload: number }) {
            return {
                ...state,
                offsets: [0],
                offset: 0,
                userRole: payload,
            };
        },
        setSortType(state: any, { payload }: { payload: number }) {
            return {
                ...state,
                offsets: [0],
                offset: 0,
                sortType: payload,
            };
        },
        setTaskStatus(state: any, { payload }: { payload: TASK_STATUS[] }) {
            return {
                ...state,
                offsets: [0],
                offset: 0,
                taskStatuses: payload,
            };
        },
        setPriorities(state: any, { payload }: { payload: PRIORITY_TYPE[] }) {
            return {
                ...state,
                offsets: [0],
                offset: 0,
                priorityTypes: payload,
            };
        },
        /**
         * 打开或关闭个人任务详情侧边栏
         * 变更显示的侧边栏
         */
        changeFullIframeModal(
            state: State,
            {
                payload,
            }: { payload: { fullIframeModalVisible: boolean; fullIframeModalUrl: string } }
        ) {
            return {
                ...state,
                detailState: {
                    ...state.detailState,
                    ...payload,
                },
            };
        },
        /**
         * 打开或关闭个人任务详情侧边栏
         * 变更显示的侧边栏
         */
        changePersonalTaskDraw(
            state: State,
            {
                payload,
            }: {
                payload: {
                    drawVisible: boolean;
                    taskId: string;
                    forceGet: boolean;
                    activeTab: string;
                    taskComeFrom?: string;
                    chat: boolean;
                };
            }
        ) {
            const { drawVisible, taskId, activeTab, chat, taskComeFrom } = payload;
            return {
                ...state,
                detailState: {
                    drawVisible,
                    taskId,
                    chat,
                    forceGet: !!payload.forceGet,
                    activeTab: activeTab,
                    taskComeFrom,
                },
            };
        },
        /**
         * 打开或关闭个人任务详情侧边栏
         * 变更显示的侧边栏
         */
        changeEditTaskDraw(
            state: State,
            {
                payload,
            }: {
                payload: {
                    taskId: string;
                    drawEditVisible: boolean;
                    taskDetail?: NewTaskModel;
                };
            }
        ) {
            const { drawEditVisible, taskId, taskDetail } = payload;
            return {
                ...state,
                detailState: {
                    drawEditVisible,
                    taskId,
                    taskDetail,
                },
            };
        },
        /**
         * 添加列表到对应时间块
         */
        setPersonalTaskList(
            state: State,
            {
                payload,
            }: {
                payload: {
                    timeBlockId: string;
                    timeClassify: number;
                    tasks: TaskModel[];
                    total: number;
                    lastTaskId: string;
                    offset: number;
                    limit: number;
                };
            }
        ) {
            const { personalTimeBlocks } = state;
            const timeBlocks = addTaskListFromTimeBlocks({
                timeBlocks: personalTimeBlocks,
                ...payload,
            });

            return {
                ...state,
                personalTimeBlocks: timeBlocks,
            };
        },
        setTaskList(
            state: State,
            {
                payload,
            }: {
                payload: {
                    tasks: NewTaskModel[];
                    total: number;
                    offset: number;
                    limit: number;
                };
            }
        ) {
            let localList: NewTaskModel[] = [];
            const { tasks, total, offset } = payload;
            if (offset > 0) {
                // 去重
                state.taskList.concat(tasks).forEach((item) => {
                    let temp = localList.find((k) => k.taskId === item.taskId);
                    if (temp) {
                        // @ts-ignore
                        temp = { ...temp, ...item };
                    } else {
                        localList.push(item);
                    }
                });
            } else {
                localList = tasks;
            }
            return {
                ...state,
                total,
                taskList: localList,
            };
            // const { tasks, total } = payload;
            // return {
            //     ...state,
            //     total,
            //     taskList: tasks,
            // };
        },
        setTaskItem(state: State, { payload }: any) {
            const lastTaskList = state.taskList?.map((item) => {
                if (item.taskId === payload.taskId) {
                    // eslint-disable-next-line no-param-reassign
                    item = Object.assign(item, payload, {
                        priorityType: payload.priorityType || -1,
                    });
                }
                return item;
            });
            return {
                ...state,
                taskList: lastTaskList,
            };
        },
        // 清空数据
        clearData(state: State) {
            return {
                ...state,
                taskList: [],
                total: 0,
                offset: 0,
                offsets: [0], // 存储offset
                processStatuses: [],
                taskStatuses: [1],
                priorityTypes: [1, 2, 3, 4],
            };
        },
    },
    effects: {
        *getWebUserData({ payload }: any, { call, put }: any) {
            const result = payload.userData;
            yield put({
                type: 'setUserData',
                // payload: {
                //     ...result,
                //     team: result.teamUserInfo,
                //     teamUserInfoList: [result.teamUserInfo],
                //     selectedTeamId: result.teamUserInfo.teamId,
                // },
                payload: {
                    user: {
                        ...result,
                        team: result.teamUserInfo,
                        teamUserInfoList: [result.teamUserInfo],
                        selectedTeamId: result.teamUserInfo.teamId,
                    },
                },
            });
            // yield put({ type: 'setUserInfo', payload: result });
        },
        *getMyTasksByTime(
            {
                payload,
            }: {
                payload: {
                    endTimeFrom?: string;
                    endTimeTo?: string;
                    timeBlockId?: string;
                    offset: number;
                    limit: number;
                    // timeClassify?: number;
                };
            },
            { call, put }: any
        ) {
            const { endTimeFrom, endTimeTo, timeBlockId, offset, limit } = payload;
            const result = yield call(getPersonalList, payload);
            const [data, err] = result;
            if (err || !data) {
                message.error(err);
                return;
            }
            yield put({
                type: 'setPersonalTaskList',
                payload: {
                    timeBlockId,
                    ...data,
                    offset,
                    limit,
                    timeClassify: Number(timeBlockId),
                },
            });
        },
        *fetchTaskList(
            {
                payload,
            }: {
                payload: TaskListParams;
            },
            { call, put }: any
        ) {
            // TODO: 其实这里都没有offsets
            const { limit, offsets, offset, taskStatuses, processStatuses } = payload;
            if (!taskStatuses?.[0] && !processStatuses?.[0]) {
                return; // 接口会报错（仅显示‘部门任务’时）
            }
            let res: { total: number; tasks: NewTaskModel[] } = {
                total: 0,
                tasks: [],
            };
            yield put({
                type: 'setTaskLoading',
                payload: true,
            });
            if (offsets) {
                for (const offset of offsets) {
                    const [data, err] = yield call(queryTasks, { ...payload, offset });
                    if (err || !data) {
                        message.error(err || '服务器错误');
                        return;
                    }
                    const { total, tasks } = data;
                    res = { total, tasks: [...res.tasks, ...tasks] };
                }
            } else {
                const [data, err] = yield call(queryTasks, payload);
                if (err || !data) {
                    message.error(err || '服务器错误');
                    return;
                }
                res = data;
            }

            yield put({
                type: 'setTaskList',
                payload: {
                    ...res,
                    offset,
                    offsets,
                    limit,
                    tasks: res.tasks || [],
                },
            });
            yield put({
                type: 'setTaskLoading',
                payload: false,
            });
        },
        *editTaskItem({ payload }: { payload: IEditTaskItemParam }, { call, put }: any) {
            const result = yield call(editTaskItem, payload);
            const [data, err] = result;
            if (err || !data) {
                message.error(err);
                return;
            }
            yield put({
                type: 'setTaskItem',
                payload: {
                    ...data,
                },
            });
        },
        /* 以下是‘部门任务’相关 */
        *getEbookChildDept({ payload }: { payload: any }, { call, put }: any) {
            return yield call(getEbookChildDept, payload);
        },
        *getDeptTaskList({ payload }: { payload: any }, { call, put }: any) {
            const result = yield call(deptTaskList, payload);
            const [data, err] = result;
            if (err || !data) {
                message.error(err);
                return;
            }
            // eslint-disable-next-line no-debugger
            // debugger;
            yield put({
                type: 'setDepartmentalTask',
                payload: {
                    departmentalTaskList: data.tasks,
                    departmentalTotal: data.total,
                },
            });
            return data;
        },
        *getDeptTaskStat({ payload }: { payload: string }, { call, put }: any) {
            const result = yield call(statDeptTask, payload);
            const [data, err] = result;
            if (err || !data) {
                message.error(err);
                return;
            }
            return data;
        },
        *getDeptTaskAuth(a: any, { call, put }: any) {
            const result = yield call(getDeptStatAuth);
            const [data, err] = result;
            if (err || !data) {
                message.error(err);
                return;
            }
            return data;
        },
    },
};
