/**
 * 消息控制组件 已读未读，操作
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import './index.less';
import ChatState, { UserState } from '@/types/chat/State';
import {
    ChatMessage,
    Group,
    MessageStatus,
    UID,
    FileStatus,
    ImageChatMessage,
    ChatMessagePlusEmitBody,
} from '@/types/chat';
import { connect } from 'dva';
import MessageOperate from '@/baseComponents/Chat/message/Operate';
import { ClickParam } from 'antd/lib/menu';
import ImService from '@/server/ImService';
import { message as toast } from 'antd';
import IconFont from '@/components/icon';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import SessionType from '@jd/jdee.im.sdk/lib/es/enum/SessionType';
import {
    checkMessageSupport,
    employeeToUid,
    forwardLeaveWord,
    getMessageSendType,
    isCloudFileFunc,
    isEqualEmployee,
    selectedSessionsToForward,
} from '@/utils/chat/message';
import { forwardJoySpaceCard } from '@/components/chat/utils/message';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { openUserSelectionModal } from '@/utils/modals';
import get from 'lodash/get';
import isString from 'lodash/isString';
import { UserModel } from '@/models/user';
import { SessionSettingsType } from '@/types/chat/enum';
import { ChatMessageType } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import TextMessageBody from '@jd/jdee.im.sdk/lib/es/protocol/message/Text';
import { addFileDownloadRecord, getProjectDetail } from '@/api/chat';
import { isFocusEnv, FocusSDK, DownloadItem, detectOS } from '@/utils';
import {
    openCreateSchedule,
    openCreateRemind,
    html2Canvas,
    getMessageFileName,
    getFileFilter,
    downloadMessageFile,
    getPreviewImageUrl,
} from '@/utils/chat/index';
import { getSelectorSessions } from '@/utils/chat/session';
import { SelectorMode } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import Bus from '@/utils/bus';
import debounce from 'lodash/debounce';
import CopyClipboard from 'copy-to-clipboard';
import { cancelShareJoyspaceFiles, shareJoyspaceFiles } from '@/components/Joyspace/api/page';
import { deleteEmojiStr } from '@/baseComponents/Chat/message/Input/Emoji';
import config, { ConfigEnum } from '@/config/config';
import chatConfig from '@/config/chat';
import pick from 'lodash/pick';
import DeeplinkEvent, { DeeplinkPathEnum } from '@/components/DeeplinkHandler/DeeplinkEvent';
import { shareScheduleAttachment } from '@/api/calendar';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { changeEgovUrl } from '@/utils/tools';
import { InputPlacement } from '@jd/focus-desktop-comps/lib/es/UserSelectionModal/types';
import { SaveOption } from '@/utils/DownloadFileQueue';
interface MessageItemProps {
    messageNode: ReactNode;
    sendUserName: ReactNode;
    sendTime: React.ReactElement;
    headPortrait: ReactNode;
    message: ChatMessage & ChatMessagePlusEmitBody;
    index: number;
    getCurrentMessageId: Function;
    setCurrentMessageId: Function;
    unLock: Function;
    getLock: (lock?: boolean) => boolean;
    showOperateBox: boolean;
    userData: UserModel;
    changeSelectMore: Function;
    updateSessionMessageMid: Function;
    isRightClick?: boolean;
}

interface OwnState {
    showOperate: boolean;
}

interface DvaDispatchProps {
    deleteSessionMessage: (data: { message: ChatMessage }) => void;
    openCreateModal: Function;
    openCreateRemindModal: Function;
}

type IProps = Readonly<
    MessageItemProps & ChatState & DvaDispatchProps & UserState & WithTranslation
>;

function Control(props: IProps) {
    const {
        selectedSession,
        currentEmployee,
        message,
        allSessionList,
        showOperateBox,
        userData,
        deleteSessionMessage,
        changeSelectMore,
        updateSessionMessageMid,
        userInfo,
        isRightClick,
    } = props;
    const { t } = useTranslation('chat');
    const { appRuntimeEnv } = useContext<GlobalContextConfig>(GlobalContext);
    const [showOperate, setShowOperate] = useState(false);
    const [sendStatus, setSendStatus] = useState(MessageStatus.INIT);
    const [status, setStatus] = useState(FileStatus.INIT);
    // 操作更多的回调
    const operateMore = (visible: boolean) => {
        if (!visible) {
            props.unLock();
            const messageId = props.getCurrentMessageId();
            if (message.id === messageId) {
                return;
            }
            setShowOperate(false);
        } else {
            props.getLock(true);
        }
    };
    const copyOperate = (param: ClickParam) => {
        // 复制功能 --hujun
        props.unLock();
        // FocusSDK.captureScreen((data) => {
        //     console.log('data', data);
        // });
        if (message.type === ChatMessageType.TEXT) {
            let userSelection;
            let str = message.content;
            if (typeof window.getSelection === 'function') {
                userSelection = window.getSelection();
            }
            if (userSelection?.toString()) {
                str = userSelection.toString();
            }
            if ((str || '').length > (message.content || '').length) {
                str = message.content;
            }
            if (str && CopyClipboard(str)) {
                toast.success(t('copy-succeeded'));
            } else {
                toast.error(t('copy-failed'));
            }
        }
        // 复制图片 --caoxingzhuang
        if (message.type && message.type === ChatMessageType.IMAGE) {
            const Msg: any = message as ImageChatMessage;

            let img: any = document.querySelector(`[data-id="${message.id}"]`);
            let searchParams: any;
            if (img) {
                searchParams = img.cloneNode(true);
                searchParams.id = 'img-clone';
                searchParams.setAttribute('data-size', Msg.size);
                searchParams.style.zIndex = '-1';
                searchParams.width = Msg.width / 2;
                searchParams.height = Msg.height / 2;
                searchParams.src = changeEgovUrl(getPreviewImageUrl(Msg.url, true));
                document.body.appendChild(searchParams);
            }
            const timer = setTimeout(() => {
                if (searchParams) {
                    html2Canvas(searchParams)
                        .then((url) => {
                            let isOk = FocusSDK.clipboardImage(url);
                            isOk
                                ? toast.success(t('copy-succeeded'))
                                : toast.error(t('copy-failed'));
                        })
                        .catch(() => {
                            toast.error(t('copy-failed'));
                        })
                        .finally(() => {
                            document.body.removeChild(searchParams);
                            searchParams = null;
                        });
                } else {
                    toast.error(t('copy-failed'));
                }
                clearTimeout(timer);
            }, 60);
        }
    };
    const revokeOperate = async (param: ClickParam) => {
        props.unLock();
        setShowOperate(false);
        // 改为根据本地发送的时间戳计算是否符合撤回的条件
        // if (Date.now() - message.timestamp > 2 * 60 * 1000) {
        if (!message.localSendTimestamp) return;
        const expireTime =
            message.type === ChatMessageType.FILE ||
            message.type === ChatMessageType.IMAGE ||
            message.type === ChatMessageType.VIDEO
                ? chatConfig['chat-revoke-time2']
                : chatConfig['chat-revoke-time'];
        if (new Date().getTime() - message.localSendTimestamp <= 0) {
            console.log('当前时间小于消息发送的时间，可能手动改了系统时间');
            return;
        }
        if (new Date().getTime() - message.localSendTimestamp > expireTime) {
            // 不能撤回超过几分钟的消息
            toast.error(t('recall-message-reminder'));
            return;
        }
        // TODO 显示加载中
        const imService = ImService.getInstance();
        const result = await imService.revokeMessage(selectedSession, message);
        if (result) {
            // @ts-ignore
            if (message.type === ChatMessageType.JOYSPACEFILE) {
                let users: Array<any> = [];
                let groups: Array<any> = [];
                const info = selectedSession.info;
                if (!selectedSession.isGroup) {
                    users.push({
                        // @ts-ignore
                        userId: info.userId,
                        // @ts-ignore
                        teamId: info.teamId,
                        permission_type: 2,
                    });
                } else {
                    groups.push({
                        // @ts-ignore
                        gid: info?.gid || '',
                        permission_type: 2,
                    });
                }
                cancelShareJoyspaceFiles({
                    // @ts-ignore
                    page_ids: [message?.fileId || ''],
                    to_eepaas_users: users,
                    to_groups: groups,
                });
            }
            toast.success(t('withdrawal-successful'));
        } else {
            toast.error(t('withdrawal-failed'));
        }
        // TODO 取消加载中
    };

    const forwardOperate = (param?: ClickParam) => {
        props.unLock();
        const fixedUsers = getSelectorSessions(allSessionList);
        openUserSelectionModal(
            {
                title: t('select-session-contacts'),
                fixedUsers: fixedUsers,
                frozenIds: [],
                currentUser: employeeToUid(currentEmployee),
                // mode: SelectorMode.forward,
                mode: SelectorMode.user,
                tabs: config[ConfigEnum.EXTERNAL_CONTACTS_ORG]
                    ? ['recentIncludeGroup', 'org', 'groupCanBeSel', 'externalContacts']
                    : ['recentIncludeGroup', 'groupCanBeSel', 'externalContacts'],
                leaveWordInput: {
                    maxLength: chatConfig['forward-leave-max-length'],
                    placeholder: t('please-enter-forward-leave'),
                    isTextArea: true,
                },
                inputPlacement: InputPlacement.bodyRightBottom,
                inputWrapClass: 'forward-input-wrap',
                validate(data) {
                    return new Promise((resolve, reject) => {
                        const list = get(data, 'data.result', []);
                        if (list.length > chatConfig['forward-session-count']) {
                            toast.error(`最多支持选择${chatConfig['forward-session-count']}个会话`);
                            return false;
                        }
                        const forwardLeave = get(data, 'data.leaveWordInputValue', '');
                        if (
                            forwardLeave &&
                            forwardLeave.length > chatConfig['forward-leave-max-length']
                        ) {
                            toast.info(
                                t('content over maxLength', {
                                    name: '留言',
                                    maxLength: chatConfig['forward-leave-max-length'],
                                })
                            );
                            return false;
                        }
                        resolve();
                    });
                },
            },
            (data, close) => {
                doExitAndForward(data, close);
            },
            userData
        );
    };

    const exitAndForward = (options: any, list: any): Promise<Boolean> => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const imService = ImService.getInstance();
            const sessionId = selectedSession.sessionId;
            let Msg: any = message;
            message.plusList?.map((item: any) => {
                if (item.statusType === MessageStatus.SUCCESS) {
                    Msg.mid = item.messageId;
                }
            });
            // 转发文档授权
            let body = await forwardJoySpaceCard(message, options, message.type);
            // if (ChatMessageType.JOYSPACEFILE === message.type) {
            //     // @ts-ignore
            //     // @ts-ignore
            //     // const byMessage = await imService.getBySession(message.sessionId, message.mid);
            //     const pageId = (message as any).fileId;
            //     const joyspacePageIds = [pageId];
            //     let users: Array<any> = [];
            //     (options?.uids || []).forEach((user: any) => {
            //         users.push({
            //             userId: user.pin,
            //             teamId: user.teamId,
            //             // @ts-ignore
            //             permission_type: 2,
            //         });
            //     });
            //     let groups: Array<any> = [];
            //     (options?.gids || []).forEach((id: any) => {
            //         // @ts-ignore
            //         groups.push({
            //             gid: id,
            //             permission_type: 2,
            //         });
            //     });
            //     const res = await shareJoyspaceFiles({
            //         page_ids: joyspacePageIds,
            //         to_eepaas_users: users,
            //         to_groups: groups,
            //     });
            //     const isDelPage = !Array.isArray(res[pageId].page.permissions);
            //     body = {
            //         currentPermission: isDelPage ? -1 : 2,
            //         permission: isDelPage ? -1 : (message as any).permission,
            //     };
            // }
            const result = await imService.forwardMessage(sessionId, Msg, options, body);
            // console.log('Msg', Msg);
            if (isString(result) && result === SessionSettingsType.BANNED_POST) {
                // return toast.warn(t('banned_post_tip'));
                toast.warn(t('banned_post_tip'));
                resolve(false);
            }
            if (result) {
                if (Msg.template?.nativeId === 'share_link') {
                    if (Msg.data[0].sharelink.url) {
                        const parseUrl = DeeplinkEvent.parseDeeplink(Msg.data[0].sharelink.url);
                        if (
                            parseUrl?.pathname === DeeplinkPathEnum.Task_Draw ||
                            parseUrl?.pathname === DeeplinkPathEnum.Schedule_Draw
                        ) {
                            const { mparam } = parseUrl;
                            const bizId =
                                parseUrl?.pathname === DeeplinkPathEnum.Task_Draw
                                    ? mparam?.taskId
                                    : mparam?.scheduleId;
                            const bizType =
                                parseUrl?.pathname === DeeplinkPathEnum.Task_Draw ? 1 : 2;
                            shareScheduleAttachment({
                                bizId: bizId,
                                bizType: bizType,
                                shareRecipientDtos: list.map((item: any) => {
                                    if (item.isGroup) {
                                        return {
                                            recipientType: '2',
                                            recipientId: item.id,
                                            recipientName: item.name,
                                        };
                                    } else {
                                        return {
                                            recipientType: '1',
                                            recipientId: item.pin,
                                            recipientName: item.name,
                                            teamId: item.teamId,
                                            app: item.app,
                                        };
                                    }
                                }),
                            });
                        }
                    }
                }
                // 转发文档授权
                // @ts-ignore
                // if (ChatMessageType.JOYSPACEFILE === message.type) {
                //     // @ts-ignore
                //     // console.log(message, 'messagemessagemessage');
                //     const byMessage = await imService.getBySession(message.sessionId, message.mid);
                //     const joyspacePageIds = [(message as any).fileId];
                //     let users: Array<any> = [];
                //     (options?.uids || []).forEach((user: any) => {
                //         users.push({
                //             userId: user.pin,
                //             teamId: user.teamId,
                //             permission_type:
                //                 byMessage.currentPermission ||
                //                 (message as any).currentPermission ||
                //                 2,
                //         });
                //     });
                //     let groups: Array<any> = [];
                //     (options?.gids || []).forEach((id: any) => {
                //         groups.push({
                //             gid: id,
                //             permission_type:
                //                 byMessage.currentPermission ||
                //                 (message as any).currentPermission ||
                //                 2,
                //         });
                //     });
                //     shareJoyspaceFiles({
                //         page_ids: joyspacePageIds,
                //         to_eepaas_users: users,
                //         to_groups: groups,
                //     });
                // }
                // toast.success(t('forwarding-successful'));
                resolve(true);
            } else {
                resolve(false);
                // toast.error(t('forwarding-failed'));
            }
        });
    };

    const doExitAndForward = debounce(async (data: any, close) => {
        // 等待消息转发完成以后关闭窗口
        const list = get(data, 'data.result', []);
        if (!list || list.length <= 0) {
            return;
        }
        const options = await selectedSessionsToForward(list);
        // console.log('单条转发', data, options);
        const status = await exitAndForward(options, list);
        if (status) {
            const forwardLeave = get(data, 'data.leaveWordInputValue', '');
            // console.log(forwardLeave);
            forwardLeave && (await forwardLeaveWord(currentEmployee, options, forwardLeave));
            toast.success(t('forwarding-successful'));
        } else {
            toast.error(t('forwarding-failed'));
        }
        close();
    }, 500);

    const selectMoreFn = () => {
        changeSelectMore({ selectMore: true });
        props.unLock();
    };
    // 发送表情事件
    const emojiReplyOperate = (emoji: string) => {
        // changeSelectMore({ selectMore: true });
        props.unLock();
        setShowOperate(false);
        const data = {
            emoji: emoji,
        };
        // 发送事件到回复表情组件
        Bus.emit(`message:emojiReply:${selectedSession.sessionId}:${message.mid}`, data);
    };

    // 唤起日程创建
    const scheduleOperate = async (param: ClickParam) => {
        props.unLock();
        // this.setState({ showOperate: false });
        setShowOperate(false);
        const isCreateMessageType = [
            ChatMessageType.TEXT,
            ChatMessageType.FILE,
            ChatMessageType.IMAGE,
        ].includes(message.type as any);
        if (isCreateMessageType || isCloudFileFunc(message)) {
            if (selectedSession.isGroup) {
                const { businessId, business } = selectedSession.info as Group;
                if (business && business?.indexOf('project') > -1) {
                    // 专班群
                    const [data, error] = await getProjectDetail({
                        projectId: businessId,
                        summary: true,
                    });
                    if (data.calendarId) {
                        let title = (message as TextMessageBody).content;
                        title = deleteEmojiStr(title);
                        if (isFocusEnv()) {
                            openCreateSchedule({
                                title,
                                messageResource:
                                    message.type !== ChatMessageType.TEXT ? message : null,
                                calendarId: data.calendarId,
                                selectedSession: selectedSession,
                            });
                        } else {
                            props.openCreateModal({
                                visible: true,
                                title,
                                messageResource:
                                    message.type !== ChatMessageType.TEXT ? message : null,
                                frozenCalendarId: data.calendarId,
                                selectedSession: selectedSession,
                            });
                        }
                        return;
                    }
                }
            }

            let title = (message as TextMessageBody).content;
            title = deleteEmojiStr(title);
            if (isFocusEnv()) {
                openCreateSchedule({
                    title,
                    messageResource: message.type !== ChatMessageType.TEXT ? message : null,
                    calendarId: '',
                    selectedSession: selectedSession,
                });
            } else {
                props.openCreateModal({
                    visible: true,
                    title,
                    messageResource: message.type !== ChatMessageType.TEXT ? message : null,
                    frozenCalendarId: '',
                    selectedSession: selectedSession,
                });
            }
        }
    };

    // 唤起任务创建
    const taskOperate = async (param: ClickParam) => {
        props.unLock();
        // this.setState({ showOperate: false });
        setShowOperate(false);
        const isCreateMessageType = [
            ChatMessageType.TEXT,
            ChatMessageType.FILE,
            ChatMessageType.IMAGE,
        ].includes(message.type as any);
        if (isCreateMessageType || isCloudFileFunc(message)) {
            if (selectedSession.isGroup) {
                const { businessId, business, name } = selectedSession.info as Group;
                if (business && business?.indexOf('project') > -1) {
                    let title = (message as TextMessageBody).content;
                    title = deleteEmojiStr(title);
                    Bus.emit('task:open-create', {
                        title: message.type === ChatMessageType.TEXT ? title : '',
                        messageResource: message.type !== ChatMessageType.TEXT ? message : null,
                        projectId: businessId,
                        sourceName: name,
                        selectedSession,
                    });
                    return;
                }
            }
            let title = (message as TextMessageBody).content;
            title = deleteEmojiStr(title);
            Bus.emit('task:open-create', {
                title: message.type === ChatMessageType.TEXT ? title : '',
                messageResource: message.type !== ChatMessageType.TEXT ? message : null,
                projectId: '',
                sourceName: '',
                selectedSession,
            });
        }
    };

    // 唤起提醒创建
    const remindOperate = async (param: ClickParam) => {
        props.unLock();
        // this.setState({ showOperate: false });
        setShowOperate(false);
        if (message.type === ChatMessageType.TEXT) {
            let title = (message as TextMessageBody).content;
            title = deleteEmojiStr(title);
            if (isFocusEnv()) {
                openCreateRemind({
                    selectedSession: selectedSession,
                    createData: { content: title, from: 'IM' },
                });
            } else {
                props.openCreateRemindModal({
                    visible: true,
                    selectedSession: selectedSession,
                    createData: { content: title, from: 'IM' },
                });
            }
        }
    };

    const deleteOperate = (param: ClickParam) => {
        props.unLock();
        // this.setState({ showOperate: false });
        setShowOperate(false);
        // 删除提醒
        prompt({
            title: t('delete-reminder'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onOk: async () => {
                // 设用删除接口
                const imService = ImService.getInstance();
                const result = await imService.deleteMessage(message);
                if (result) {
                    // 删除 dva 中的 数据
                    deleteSessionMessage({ message });
                    toast.success(t('deletion-succeeded'));
                    // window.location.reload();
                } else {
                    toast.error(t('deletion-failed'));
                }
            },
            content: t('delete-selected-message'),
            okText: t('determine'),
            cancelText: t('cancelText'),
        });
    };

    const getDownloadOptions = ({ viewFile, saveAs, message, auto }: SaveOption) => {
        return {
            saveAs,
            onStarted: (downloadItem: DownloadItem) => {
                // console.log('文件下载', downloadItem);
            },
            onProgress: ({ percent = 0 }) => {
                setStatus(FileStatus.DOWNLOADING);
            },
            onCancel: () => {},
            onPause: () => {},
            onResume: () => {
                // console.log('onResume ==> 恢复下载');
            },
            onFinish: async (file: { path: string; filename: string }, data: any) => {
                const path = `${data.saveAs}${detectOS() === 'Win' ? '\\' : '/'}${data.filename}`;
                toast.success('下载成功');
                const needSend = !message.downLocalPath && selectedSession.isGroup;
                const imservice = ImService.getInstance();
                // 首次另存为保存路径
                if (needSend) {
                    const result = await imservice.updateLocalPath(message.sessionId, message.mid, {
                        downLocalPath: path,
                    });
                    if (result) {
                        updateSessionMessageMid({
                            sessionId: message.sessionId,
                            sendResult: { body: { ...message, downLocalPath: path } },
                        });
                    }
                }
                if (selectedSession.isGroup && !isEqualEmployee(message.sender, currentEmployee))
                    addFileDownloadRecord({
                        fileId: message.id,
                        fileName: (message as any).name,
                        groupId: message.sessionId,
                        mid: message.mid,
                        senderUserId: message.sender.userId,
                        senderTeamId: message.sender.teamId,
                    }).then((res) => {
                        console.log(res, 'addFileDownloadRecord');
                    });
            },
        };
    };

    const doDownloadMessageFile = ({
        viewFile,
        saveAs,
        message,
        auto = false,
    }: SaveOption): Promise<void> => {
        const options = getDownloadOptions({ viewFile, saveAs, message, auto });
        return downloadMessageFile({ viewFile, saveAs, message, auto }, options);
    };

    // 文件另存为
    const handleFile = (param: ClickParam) => {
        props.unLock();
        setShowOperate(false);
        if (
            message.type === ChatMessageType.FILE ||
            message.type === ChatMessageType.IMAGE ||
            message.type === ChatMessageType.VIDEO
        ) {
            // eslint-disable-next-line no-console
            if (isFocusEnv()) {
                doDownloadMessageFile({ message, saveAs: true, viewFile: false, auto: false })
                    .then()
                    .catch((e) => console.log(e));
            }
        }
    };
    const getMessageOperateNode = () => {
        // 不支持的消息类型 无法进行操作
        const isSupport = checkMessageSupport(message);
        if (!isSupport) {
            return null;
        }
        const { sessionType } = selectedSession;
        // 非单聊 群聊的会话，不支持操作功能
        if (
            ![SessionType.GROUP, SessionType.SINGLE, SessionType.SECRET_SINGLE].includes(
                sessionType
            )
        ) {
            return null;
        }

        return (
            <MessageOperate
                currentEmployee={currentEmployee}
                copyOperate={copyOperate}
                undoOperate={revokeOperate}
                forwardOperate={forwardOperate}
                deleteOperate={deleteOperate}
                onDropMenuChange={operateMore}
                scheduleOperate={scheduleOperate}
                taskOperate={taskOperate}
                remindOperate={remindOperate}
                selectMoreFn={selectMoreFn}
                message={message}
                unLock={props.unLock}
                selectedSession={selectedSession}
                emojiReplyOperate={emojiReplyOperate}
                handleFile={handleFile}
                isRightClick={isRightClick}
            />
        );
    };

    const sendType = useMemo(() => {
        return getMessageSendType(message, currentEmployee, selectedSession);
    }, [message, currentEmployee, selectedSession]);

    useEffect(() => {
        // 设置是否 显示操作控件， 鼠标 hover 为 true
        setShowOperate(showOperateBox);
    }, [showOperateBox]);

    const onSendStatus = (status: MessageStatus) => {
        // 接收控制发送消息组件的状态，只有不是INIT的状态才会接收
        if (status !== MessageStatus.INIT) {
            setSendStatus(status);
        }
    };

    useEffect(() => {
        // 如果消息不是用户发送的消息， 设置为发送状态为 SUCCESS
        if (message && message.status !== MessageStatus.INIT) {
            setSendStatus(MessageStatus.SUCCESS);
        }
    }, [message]);

    const canOperate = useMemo(() => {
        // 只有 鼠标Hover 和 发送状态为 SUCCESS 的消息 才可以进行消息操作
        return showOperate && sendStatus === MessageStatus.SUCCESS;
    }, [showOperate, sendStatus]);
    if (message.id) {
        return (
            <div className="message-item-control">
                <div className="item-operate-control">{canOperate && getMessageOperateNode()}</div>
            </div>
        );
    }
    return <div />;
}

function mapStateToProps({ chat, user: { userData } }: any) {
    const { selectedSession, currentEmployee, allSessionList } = chat as ChatState;
    return {
        selectedSession,
        currentEmployee,
        allSessionList,
        userData: userData ? userData.user : {},
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async deleteSessionMessage(data: { message: ChatMessage }) {
            await dispatch({ type: 'chat/deleteSessionMessage', payload: data });
        },
        openCreateModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
        openCreateRemindModal(data: any) {
            dispatch({ type: 'supervise/setCreateModalVisible', payload: data });
        },
        changeSelectMore(data: any) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
            dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('chat')(Control));
