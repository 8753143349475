import React from 'react';
import { filterTaskInfo, SearchGlobalContext } from '@/components/Modals/SearchGlobalModal/utils';
import throttle from 'lodash/throttle';
import ListEmptyPlaceholder from '@jd/focus-desktop-comps/lib/es/ListEmptyPlaceholder';
import { List, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import GroupTitle from '@/components/Modals/SearchGlobalModal/GroupTitle';
import lodashTrim from 'lodash/trim';
import { SearchGlobalRes, TaskInfo } from '@/services/SearchService';
import TaskPaneItem from './TaskPaneItem';
import { ListItemClickFn } from '../../Tabs';
import noContent from '@/assets/img/search-nodata.png';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { analysisLog } from '@/utils/logAnalytics';

export default function TabPaneTask({ onClick }: { onClick?: ListItemClickFn }) {
    const { onSearchGlobal, keyword, t, setTabIndex, activeKey } = React.useContext(
        SearchGlobalContext
    );
    const [result, setResult] = React.useState<SearchGlobalRes | null>(null);
    const [loading, setLoading] = React.useState(false);
    const keywordRef = React.useRef<string>();
    keywordRef.current = keyword;
    const activeKeyRef = React.useRef<SearchType>();
    activeKeyRef.current = activeKey;

    const doSearch = async () => {
        if (activeKeyRef.current !== SearchType.Task) {
            return;
        }
        if (!keywordRef.current) {
            return [];
        }
        try {
            setLoading(true);
            const res = await onSearchGlobal({
                keyword: keywordRef.current,
                searchType: SearchType.Task,
                pageNo: 1,
                pageSize: 20,
            });

            if (keywordRef.current) {
                setResult(res);
            }
            setLoading(false);
        } catch (e) {
            setResult({});
            setLoading(false);
        }
    };

    const doSearchThrottle = React.useRef(throttle(doSearch, 500)).current;

    React.useEffect(() => {
        if (!lodashTrim(keywordRef.current)) {
            setResult(null);
            return;
        }
        doSearchThrottle();
    }, [keyword, doSearchThrottle, activeKey]);

    if (!result || !result.works) {
        return <ListEmptyPlaceholder placeholder={t('search.emptyTask')} />;
    }
    const { works } = result;

    const isEmpty = works
        .map((work) => Object.values(work)[0])
        .every((item) => {
            return !item || item?.length === 0;
        });
    if (isEmpty) {
        return <Empty image={noContent} description={t('search.nodata')} />;
    }
    const worksData = filterTaskInfo(works);

    return (
        <div>
            {worksData.map(
                ({ displayName, data }: { data: TaskInfo[]; displayName: string }, idx: number) => {
                    if (!data || data.length === 0) return null;
                    return (
                        <div key={idx}>
                            <GroupTitle title={`任务-${displayName}`} />
                            <List
                                loading={
                                    loading && {
                                        indicator: <LoadingOutlined size={16} />,
                                    }
                                }
                                dataSource={data}
                                renderItem={(item: any) => (
                                    <TaskPaneItem
                                        onClick={() => {
                                            analysisLog('xtbg_home_search_1641454991771', '30');
                                            if (onClick) {
                                                onClick(SearchType.Task, item);
                                            }
                                        }}
                                        info={item}
                                    />
                                )}
                            />
                        </div>
                    );
                }
            )}
        </div>
    );
}
