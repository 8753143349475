import dayjs, { Dayjs } from 'dayjs';
import {
    getScheduleList,
    getCalendarList,
    getMultipleCalendar,
    Schedule,
    Canceled,
    RepeatMode,
    CalendarInfo,
    CalendarType,
    UserType,
    ResStatus,
} from '@/api/calendar';
import logger from '@/utils/logger';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { FocusSDK, storageLocal } from '@/utils';
import { handleScheduleList } from '@/components/Calendars/utils';
import { ScheduleMode } from '@/components/Calendars/Create';
import { MeetingPlatform } from '@/types/common';
import { getConferencePlatforms } from '@/api/meeting';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

// function getNextRepeatTime(mode: RepeatMode, beginTime: Dayjs, endTime: Dayjs) {
//     let dateList: string[] = [];
//     let nextBeginTime = beginTime;
//     let nextEndTime = endTime;
//     let diff = endTime.valueOf() - beginTime.valueOf();
//     if (mode === RepeatMode.WorkDay) {
//         for (let i = beginTime; i.isBefore(endTime); i) {
//             if (i.day() === 0 || i.day() === 6) {
//                 i = i.add(24, 'hour');
//                 continue;
//             }
//             nextBeginTime = i;
//             nextEndTime = dayjs(i.valueOf() + diff);
//         }
//     } else if (mode === RepeatMode.EveryDay) {
//         nextBeginTime = beginTime.add(24, 'hour');
//         nextEndTime = dayjs(beginTime.valueOf() + diff);
//     } else if (mode === RepeatMode.EveryWeek) {
//         nextBeginTime = beginTime.add(24 * 7, 'hour');
//         nextEndTime = dayjs(beginTime.valueOf() + diff);
//     } else if (mode === RepeatMode.EveryTwoWeeks) {
//         nextBeginTime = beginTime.add(24 * 14, 'hour');
//         nextEndTime = dayjs(beginTime.valueOf() + diff);
//     } else if (mode === RepeatMode.EveryMonth) {
//         nextBeginTime = beginTime.add(1, 'month');
//         nextEndTime = dayjs(beginTime.valueOf() + diff);
//     } else if (mode === RepeatMode.EveryYear) {
//         nextBeginTime = beginTime.add(1, 'year');
//         nextEndTime = dayjs(beginTime.valueOf() + diff);
//     }
//     return [nextBeginTime, nextEndTime];
// }

function handleCalendarList(list: any) {
    const temp: any = {};
    if (!list || list.length === 0) {
        return {};
    }
    list.forEach((l: CalendarInfo) => {
        if (l.extended) {
            try {
                const tem = JSON.parse(l.extended);
                l.extended = tem;
            } catch (error) {}
        }
        temp[l.calendarId] = l;
    });
    return temp;
}
export interface scheduleInfoDrawProp {
    visible: boolean;
    scheduleId: string;
    selectedScheduleTime: Dayjs;
    from: number;
    beginTime?: Dayjs;
    endTime?: Dayjs;
    openTime: Dayjs;
}
export interface CalendarState {
    calendarLoading: boolean;
    // 全局选中时间
    selectedTime: Dayjs;
    selectedTypes: any;
    // 左侧日程列表
    scheduleList: any[];
    calendarList: any[];
    selectedCalendarList: any[];
    selectedCalendarListMap: any;
    scheduleMap: any; // key: 2020-05-01
    calendarMap: any; // calendarId: {}
    viewTab: 'month' | 'week' | 'day';

    // 视图缩放比例
    weekViewScalePercent: number;
    dayViewScalePercent: number;

    // 查看其它联系人
    otherUser: any;
    // 我的日历
    myCalendar: CalendarInfo;
    // 已选择日历列表的 本地存储key值
    savedCalendarListkey: string;
    savedCalendarListMapkey: string;

    createModalVisible: boolean; // 创建编辑modal的显示控制
    createModalBeginTime: Dayjs | null;
    createModalEndTime: Dayjs | null;
    createModalTitle: string;
    createModalQuickCreate: boolean;
    createModalFrozenCalendarId: string;
    createModalDefaultAppointment: boolean; // 默认是否同步创建会议
    platform: MeetingPlatform; // 同步创建的会议类型
    conferencePlatforms: any[]; // 会议类型列表

    scheduleInfoDraw: scheduleInfoDrawProp;
    scheduleInfoDrawOther: scheduleInfoDrawProp;
    // 打开的日程详情页
    scheduleInfoDrawVisible: {
        visible: boolean;
        scheduleId: string;
        from: number;
    }; // 日程视图打开日程详情页
}
const defaultState = {
    calendarLoading: false,
    // 当前全局选中的时间
    selectedTime: dayjs(),
    selectedTypes: '',
    scheduleList: [],
    calendarList: [],
    calendarSelectedList: [],
    scheduleMap: {},
    calendarMap: {},
    selectedCalendarList: [],
    selectedCalendarListMap: {},
    viewTab: localStorage.getItem('calendarViewTab') || 'week',
    weekViewScalePercent: 100,
    dayViewScalePercent: 100,
    otherUser: null,
    myCalendar: {},
    savedCalendarListkey: 'savedCalendarListkey',
    savedCalendarListMapkey: '',

    // 创建及编辑组件控制
    createModalVisible: false,
    modifyScheduleContent: null,
    createModalMode: ScheduleMode.Create,
    createModalBeginTime: null,
    createModalEndTime: null,
    createModalTitle: '',
    createModalQuickCreate: false,
    createModalFrozenCalendarId: '',
    createModalDefaultAppointment: false,

    scheduleInfoDraw: {
        visible: false,
        scheduleId: '',
        selectedScheduleTime: dayjs(),
        beginTime: dayjs(),
        endTime: dayjs(),
        from: -1,
        openTime: dayjs(),
        isBusy: 0,
        busyRealName: '',
    },
    scheduleInfoDrawOther: {
        visible: false,
        scheduleId: '',
        selectedScheduleTime: dayjs(),
        beginTime: dayjs(),
        endTime: dayjs(),
        from: -1,
        openTime: dayjs(),
        isBusy: 0,
        busyRealName: '',
    },
    // 日程详情
    scheduleInfo: {
        schedule: null,
        selectedScheduleTime: dayjs(),
        content: null,
    },
    scheduleInfoDrawVisible: {
        visible: false,
        scheduleId: '',
        from: -1,
    },
    // 会议类型列表
    conferencePlatforms: [],
};
export default {
    namespace: 'calendar',
    state: { ...defaultState },
    reducers: {
        clearData(state: CalendarState, { payload }: any) {
            return { ...defaultState };
        },
        setSelectedTime(state: CalendarState, { payload }: any) {
            return { ...state, selectedTime: payload.time };
        },
        setSelectedTypes(state: CalendarState, { payload }: any) {
            return { ...state, selectedTypes: payload };
        },
        setCalendarLoading(state: CalendarState, { payload }: any) {
            return { ...state, calendarLoading: payload };
        },
        setScheduleList(state: CalendarState, { payload }: any) {
            return { ...state, scheduleList: payload.data };
        },
        setCalendarList(state: CalendarState, { payload }: any) {
            // 对日历进行排序
            // 个人日历应固定排第一个，其余日历按创建时间排序，后创建的靠前排
            // 订阅日历，按订阅时间排序，后订阅的靠前排（现取消排序，按接口返回顺序）
            // payload.data?.sort((a: CalendarInfo, b: CalendarInfo) => {
            //     if (a.userType === UserType.Creator && a.type === CalendarType.PersonalType) {
            //         return -1;
            //     }
            //     if (b.userType === UserType.Creator && b.type === CalendarType.PersonalType) {
            //         return 1;
            //     }
            //     if (a.createTime && b.createTime) {
            //         return b.createTime - a.createTime;
            //     }
            // });
            return { ...state, calendarList: payload.data };
        },
        setMyCalendar(state: CalendarState, { payload }: any) {
            return { ...state, myCalendar: payload.data };
        },
        setScheduleMap(state: CalendarState, { payload }: any) {
            const { data, beginTime, endTime } = payload;
            const { scheduleMap } = state;
            const temp = handleScheduleList(data, beginTime, endTime);
            return { ...state, scheduleMap: { ...scheduleMap, ...temp } };
        },
        clearScheduleMap(state: CalendarState) {
            return { ...state, scheduleMap: {} };
        },

        changeViewTab(state: CalendarState, { payload }: any) {
            const { viewTab } = payload;
            return {
                ...state,
                viewTab,
            };
        },
        changeViewScale(state: CalendarState, { payload }: any) {
            const { percent, viewTab } = payload;
            if (viewTab === 'week') {
                return { ...state, weekViewScalePercent: percent };
            }
            if (viewTab === 'day') {
                return { ...state, dayViewScalePercent: percent };
            }
            return state;
        },
        setCalendarMap(state: CalendarState, { payload }: any) {
            const { data } = payload;
            const temp: { [key: string]: any } = {};
            data.forEach((element: any) => {
                // 如果是全天数据，先不处理
                if (element.extended) {
                    try {
                        const tem = JSON.parse(element.extended);
                        element.extended = tem;
                    } catch (error) {}
                }
                temp[element.calendarId] = element;
            });
            // console.log('setCalendarMap------', temp);
            return { ...state, calendarMap: { ...state.calendarMap, ...temp } };
        },
        setSelectedCalendarList(state: CalendarState, { payload }: any) {
            // 本地存储的值要跟当前用户绑定 selectedTeamId 切换租户后会改变
            storageLocal.add(state.savedCalendarListkey, payload.data);
            let newMap = { ...state.selectedCalendarListMap };
            if (payload.dataMap) {
                storageLocal.add(`${state.savedCalendarListkey}-map`, payload.dataMap);
            } else {
                Object.keys(state.selectedCalendarListMap).forEach((key: string) => {
                    if (payload.data.indexOf(key) !== -1) {
                        newMap[key] = true;
                    } else {
                        newMap[key] = false;
                    }
                });
            }
            storageLocal.add(`${state.savedCalendarListkey}-map`, payload.dataMap || newMap);
            return {
                ...state,
                selectedCalendarList: payload.data,
                selectedCalendarListMap: payload.dataMap || newMap,
            };
        },

        setSavedCalendarListKey(state: CalendarState, { payload }: any) {
            return { ...state, savedCalendarListkey: payload.data };
        },

        updateMixInfo(state: CalendarState, { payload }: any) {
            const { beginTime, endTime, scheduleList, calendarList } = payload;
            const temp: any = {};
            if (scheduleList) {
                // console.log('scheduleList', scheduleList);
                temp['scheduleMap'] = {
                    ...state.scheduleMap,
                    ...handleScheduleList(scheduleList, beginTime, endTime),
                };
                // 修复退出登陆切换账号，scheduleMap未清除的问题
                if (scheduleList.length === 0) {
                    temp['scheduleMap'] = {
                        ...handleScheduleList(scheduleList, beginTime, endTime),
                    };
                }
            }
            if (calendarList) {
                temp['calendarMap'] = { ...state.calendarMap, ...handleCalendarList(calendarList) };
            }
            return {
                ...state,
                ...temp,
            };
        },
        setOtherUser(state: CalendarState, { payload }: any) {
            return { ...state, otherUser: payload.user };
        },
        openCreateModal(state: CalendarState, { payload }: any) {
            return {
                ...state,
                modifyScheduleContent: payload.content,
                createModalVisible: payload.visible,
                createModalMode: payload.content ? ScheduleMode.Modify : ScheduleMode.Create, // 在这里判断是否是编辑模式，减少外界传参
                createModalBeginTime: payload.beginTime,
                createModalEndTime: payload.endTime,
                createModalTitle: payload.title || '',
                createModalResource: payload.messageResource,
                createModalQuickCreate: payload.quickCreate,
                createModalFrozenCalendarId: payload.frozenCalendarId || '',
                createModalSelectedSession: payload.selectedSession || null,
                createModalDefaultAppointment: payload.defaultAppointment || false,
                meetingPlatform: payload.meetingPlatform,
            };
        },
        changeCreateModalVisible(state: CalendarState, { payload }: any) {
            // 如果是关闭创建日程modal， 清空所有状态
            if (!payload.visible) {
                return {
                    ...state,
                    createModalVisible: false,
                    modifyScheduleContent: null,
                    createModalMode: ScheduleMode.Create,
                    createModalBeginTime: null,
                    createModalEndTime: null,
                    createModalQuickCreate: false,
                    createModalFrozenCalendarId: '',
                    createModalDefaultAppointment: false,
                };
            }
            return { ...state, createModalVisible: payload.visible };
        },
        changeScheduleInfoDraw(state: CalendarState, { payload }: any) {
            return { ...state, scheduleInfoDraw: payload };
        },
        changeScheduleInfoDrawOther(state: CalendarState, { payload }: any) {
            return { ...state, scheduleInfoDrawOther: payload };
        },
        setScheduleInfo(state: CalendarState, { payload }: any) {
            return { ...state, scheduleInfo: payload };
        },
        setScheduleInfoDrawVisible(state: CalendarState, { payload }: any) {
            return { ...state, scheduleInfoDrawVisible: payload };
        },
        setConferencePlatforms(state: CalendarState, { payload }: any) {
            return { ...state, conferencePlatforms: payload };
        },
    },
    effects: {
        // 获取左侧日程列表
        *getScheduleList({ payload }: any, { call, put }: any) {
            try {
                const { beginTime, endTime, user, calendarId } = payload;
                const { scheduleList, userInfoList } = yield call(getScheduleList, {
                    beginDate: beginTime.valueOf(),
                    endDate: endTime.valueOf(),
                    user,
                    calendarId,
                });
                let userTemp: any = {};
                if (userInfoList) {
                    userInfoList.forEach((element: any) => {
                        userTemp[element.userId] = { ...element };
                    });
                }
                scheduleList.forEach((element: any) => {
                    element.user = userTemp[element.userId] || {};
                });
                yield put({ type: 'setScheduleList', payload: { data: scheduleList } });
                yield put({
                    type: 'setScheduleMap',
                    payload: { data: scheduleList, beginTime, endTime },
                });
            } catch (e) {
                console.log('获取日程列表异常=>', e);
            }
        },
        // 获取我的日历列表
        *getCalendarList({ payload }: any, { call, put, select }: any) {
            try {
                const { calendarInfoList } = yield call(getCalendarList, payload);
                yield put({ type: 'setCalendarList', payload: { data: calendarInfoList || [] } });
                yield put({ type: 'setCalendarMap', payload: { data: calendarInfoList || [] } });

                // 初始化 已选日历
                const selectedCalendarList = yield select(
                    ({ calendar }: any) => calendar.selectedCalendarList
                );
                const userData = yield select(({ user }: any) => user.userData);
                const savedCalendarListKey = `${userData.user.userId}-${userData.user?.team?.teamId}-savedCalendarList`;
                const savedCalendarListMapKey = `${userData.user.userId}-${userData.user?.team?.teamId}-savedCalendarList-map`;
                yield put({
                    type: 'setSavedCalendarListKey',
                    payload: { data: savedCalendarListKey },
                });

                let calendarInfoListIds = calendarInfoList.map((x: any) => x.calendarId);
                // let savedCalendarList = storageLocal.get(savedCalendarListKey) || calendarInfoListIds;
                let savedCalendarListMap =
                    storageLocal.get(savedCalendarListMapKey) ||
                    calendarInfoListIds.reduce((a: any, c: string) => {
                        a[c] = true;
                        return a;
                    }, {});
                // // 要把不存在的日历在本地存储中删掉
                // savedCalendarList = savedCalendarList.filter(
                //     (item: string) => calendarInfoListIds.indexOf(item) !== -1
                // );
                let tmpSavedCalendarListMap: any = {};
                let tmpSavedCalendarList: string[] = [];
                // 把不存在的日历 从本地存储中 删除
                Object.keys(savedCalendarListMap).forEach((key: string) => {
                    if (calendarInfoListIds.indexOf(key) !== -1) {
                        // 该日历还存在
                        tmpSavedCalendarListMap[key] = savedCalendarListMap[key];
                    }
                });
                // 新增的日历默认选中
                calendarInfoListIds.forEach((key: string) => {
                    if (tmpSavedCalendarListMap[key] !== false) {
                        tmpSavedCalendarListMap[key] = true; // 新日历该字段为undefined
                    }
                });
                // 得到savedCalendarList
                Object.keys(tmpSavedCalendarListMap).forEach((key: string) => {
                    if (tmpSavedCalendarListMap[key]) {
                        tmpSavedCalendarList.push(key);
                    }
                });
                // 是否需要更新 有新增 或 删除时需要更新
                yield put({
                    type: 'setSelectedCalendarList',
                    payload: {
                        data: tmpSavedCalendarList,
                        dataMap: tmpSavedCalendarListMap,
                    },
                });

                const myCalendarIds = calendarInfoList.filter((item: CalendarInfo, key: string) => {
                    return (
                        item.type === CalendarType.PersonalType &&
                        item.userType === UserType.Creator
                    ); // 获取我的个人日历
                });
                const myCalendar = myCalendarIds[0] || {};
                yield put({
                    type: 'setMyCalendar',
                    payload: {
                        data: myCalendar,
                    },
                });
            } catch (err) {
                console.log('获取我的日历列表异常', err);
            }
        },
        // 获取日程列表
        *getScheduleListByTime({ payload }: any, { call, put, select }: any) {
            try {
                const { beginTime, endTime, calendarId } = payload;
                let { newScheduleDTOS: scheduleList, calendarList } = yield call(getScheduleList, {
                    beginDate: beginTime.valueOf(),
                    endDate: endTime.valueOf(),
                    calendarId,
                });
                // let userTemp: any = {};
                // if (userInfoList) {
                //     userInfoList.forEach((element: any) => {
                //         userTemp[element.userId] = { ...element };
                //     });
                // }
                const calendarMap = yield select((state: any) => state.calendar.calendarMap);

                // 如果scheduleList中存在calendarMap中没有的日历信息，需要调接口获取日历信息
                let omissionCalendarIds: string[] = [];

                scheduleList?.forEach((element: any) => {
                    // element.user = userTemp[element.userId];
                    if (
                        !calendarMap[element.calendarId] &&
                        omissionCalendarIds.indexOf(element.calendarId) === -1
                    ) {
                        omissionCalendarIds.push(element.calendarId);
                    }
                });
                if (omissionCalendarIds.length) {
                    const { list } = yield call(getMultipleCalendar, {
                        calendarIds: omissionCalendarIds,
                    });
                    if (list) {
                        let newList = list.map((item: any) => {
                            return {
                                ...item,
                                notMy: true,
                            };
                        });
                        calendarList.push.apply(calendarList, newList);
                    }
                }
                yield put({
                    type: 'updateMixInfo',
                    payload: { scheduleList, calendarList, beginTime, endTime },
                });
            } catch (e) {
                console.log('获取日程列表异常', e);
            }
        },
        // 获取日程可创建会议类型的列表
        *getConferencePlatforms({ payload }: any, { call, put }: any): any {
            try {
                const [result] = yield call(getConferencePlatforms);
                yield put({
                    type: 'setConferencePlatforms',
                    payload: result.platforms || [],
                });
            } catch (e) {
                console.log('服务器异常', e);
            }
        },
    },
    subscriptions: {
        'session-event'({ dispatch }: any, onError: (reason?: any) => void) {
            FocusSDK.on('openCreateSchedule', (data: any) => {
                // console.log('openCreateSchedule ==>', data);
                dispatch({ type: 'openCreateModal', payload: data });
            });
        },
        'logout-clear-calendar-event'({ dispatch }: any, onError: (reason?: any) => void) {
            FocusSDK.on('openCreateSchedule', (data: any) => {
                // console.log('openCreateSchedule ==>', data);
                dispatch({ type: 'openCreateModal', payload: data });
            });
        },
    },
};
