import React, { useMemo } from 'react';
import IconFont from '@/components/icon';
import './index.less';
import classNames from 'classnames';
import { duccConfigI18 } from '@/utils/i18n';

interface TabToolsItem {
    pin: string;
    name: string;
}

export default function ToolsItem({
    toolsItem,
    isDragAble,
    isMine,
    provided = {},
    droppableId,
    handleToolsItemClick,
    index,
    isDragging,
}: {
    toolsItem: TabToolsItem;
    isDragAble?: boolean;
    isMine: boolean;
    provided?: any;
    droppableId: string;
    handleToolsItemClick: Function;
    index: number;
    isDragging?: boolean;
}) {
    const handleItemToggle = () => {
        if (isDragAble) {
            handleToolsItemClick(
                droppableId,
                provided.dragHandleProps['data-rbd-drag-handle-draggable-id']
            );
        }
    };

    const computedIconFont = useMemo(() => {
        if (isDragAble) {
            return isMine ? (
                <IconFont type="iconjs_ic_refuse" style={{ color: '#FC3E32' }} />
            ) : (
                <IconFont
                    type="iconjs_ic_new1"
                    style={{ color: (window as any).styleSass.primaryColor }}
                />
            );
        }
        return <IconFont type="iconjs_ic_refuse" style={{ color: '#E8EBEC' }} />;
    }, [isDragAble, isMine]);

    function getClstag() {
        if (isDragAble) {
            return `pageclick|keycount|Xtbg_Msg_AI|${isMine ? 'Filter_Delete' : 'Filter_Add'}`;
        } else {
            return undefined;
        }
    }

    return (
        <div
            className={classNames('tab-tools-tiem', {
                disabled: !isDragAble,
                mt0: index !== 0,
                'draggin-border': isDragging,
            })}
        >
            <div>
                <span className="tools-icon" onClick={handleItemToggle} clstag={getClstag()}>
                    {computedIconFont}
                </span>
                <span className="tools-name">
                    {toolsItem.pin.includes('joyspace') ? duccConfigI18.joyspace : toolsItem.name}
                </span>
            </div>
            {isDragAble && (
                <div>
                    <IconFont type="iconicon_othe" style={{ color: '#D4D4D4', cursor: 'move' }} />
                </div>
            )}
        </div>
    );
}
