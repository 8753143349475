import React, { useContext } from 'react';
import LeftNav from '@/layout/LeftNav';
import RightContent from '@/layout/RightContent';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { Loading } from '@jd/focus-desktop-comps';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import './index.less';
import config, { ConfigEnum } from '@/config/config';
import { isFocusEnv } from '@/utils';

export default function GlobalLayout({ children }: { children: any }) {
    const location = useLocation();
    const { authInfo, appRuntimeEnv, notifyDis, notifyStatus } = useContext<GlobalContextConfig>(
        GlobalContext
    );
    if (location.pathname === '/login') {
        return null;
    }
    if (!authInfo) {
        return (
            <div className="flex1 flex-center">
                <Loading />
            </div>
        );
    }
    // 是否隐藏了左侧边栏
    const leftSiderEnable = config[ConfigEnum.LEFT_SIDER_ENABLE];
    return (
        <div
            className={classnames(
                'appLayout',
                ...(appRuntimeEnv.envConfig.layoutClassNames || []),
                { 'has-notify': !isFocusEnv() && notifyStatus !== 'granted' },
                { 'is-close-notify': !notifyDis },
                leftSiderEnable ? '' : 'hide-left-sider'
            )}
        >
            {leftSiderEnable && <LeftNav />}
            <RightContent>{children}</RightContent>
        </div>
    );
}
