import { isInWhiteHosts } from './whiteHosts';
import { getXToken } from '@/utils/chat/index';

export const addXtoken = (url: string, xToken?: string) => {
    let newUrl = url;
    if (isInWhiteHosts(url) && !url.includes('x-token')) {
        const token = xToken || getXToken();
        const [partI, partII] = url.split('?');
        newUrl = `${partI}${partII ? '?' + partII + '&' : '?'}x-token=${token}`;
    }
    return newUrl;
};
