/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/**
 * 会话列表组件
 * 放弃dva
 * 实现：
 *  加载所有会话列表， 可以分类展示
 *  接收会话更新，新增，删除事件，进行移除元素，新增元素等
 *  抛出接收到的事件， 会话组件接收事件
 *  排序会话列表
 * @author sunzhiguang
 * @date 2020/6/16
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChatState, { UserState } from '@/types/chat/State';
import { useTranslation } from 'react-i18next';

import {
    MessageCategoryType,
    MessageNotifyType,
    MessageTimeCategory,
    MessageTypeCategory,
} from '@/types/chat/enum';
import { Session, SessionProps } from '@/types/chat';
import SessionCategory from '@/components/chat/SessionCategory';
import NoDataTips from './noData';
import { connect } from 'dva';
import './index.less';
import { CompDataLoadState, DefaultPageType } from '@/models';
import {
    messageNotifyTypeConditionMap,
    sessionConditionMap,
} from '@/components/chat/SessionList/common';
import Context from '@/components/chat/context';
import Bus from '@/utils/bus';
import { isAINoticeChildSession, isAINoticeSession, isSYSNoticeSession } from '../utils/session';
import { usePrevious } from '@/utils/chat/index';
interface props {
    allSessionList: SessionProps[] | null;
    selectedSession: Session;
    showSessionTopAll: any;
    reSortAllSessionList: Function;
    updatePageLoadState: Function;
    setSessionScrollIndex: Function;
}

type IChatLeftProps = Readonly<ChatState & UserState & props>;

export enum sessionItem {
    height = 60,
}

function SessionList(this: any, props: IChatLeftProps) {
    const {
        atCount,
        unReadCount,
        selectedNotifyType,
        selectedCategory,
        showSessionTopAll,
        sessionStatusMap,
        updatePageLoadState,
        loadAllSessionListEnd,
        allSessionList,
        selectedSession,
        sessionScrollIndex,
        sessionUnReadList,
        setSessionScrollIndex,
    } = props;
    const { currentEmployee } = React.useContext(Context);
    const { t } = useTranslation('chat');
    const preIndex = usePrevious(sessionScrollIndex);
    const scrollref: any = document.getElementById('chat-left-scroll')?.firstChild;
    useEffect(() => {
        if (!loadAllSessionListEnd) {
            return;
        }
        // performance.measure('db-create-time', 'db-create-start', 'db-create-end');
        // performance.measure('im-login-time', 'im-login-start', 'im-login-end');
        // performance.measure(
        //     'get-all-sessions-time',
        //     'get-all-sessions-start',
        //     'get-all-sessions-end'
        // );
        // performance.measure('get-auth-info-time', 'get-auth-info-start', 'get-auth-info-end');
        // performance.measure('im-get-instance-time', 'im-get-instance-start', 'im-get-instance-end');
        // performance.measure('total-time', 'get-auth-info-start', 'get-all-sessions-end');
        // let measures = performance.getEntriesByType('measure');

        // measures.forEach((measure) => {
        //     console.log(measure.name + ': ' + measure.duration);
        // });

        Bus.emit('app:default-page-init');
        if (selectedNotifyType === MessageNotifyType.IMPORTANT) {
            if (atCount === 0) {
                // 没有人@你
                Bus.emit('app:default-page-nodata', DefaultPageType.AT);
            }
        } else if (selectedNotifyType === MessageNotifyType.UNREAD) {
            if (unReadCount === 0) {
                // 暂无新消息
                Bus.emit('app:default-page-nodata', DefaultPageType.MESSAGE);
            }
        } else if (selectedNotifyType === MessageNotifyType.GROUPS) {
            if (unReadCount === 0) {
                Bus.emit('app:default-page-nodata', DefaultPageType.MESSAGE);
            }
        } else if (selectedNotifyType === MessageNotifyType.ALL) {
            if (allSessionList?.length === 0) {
                // 暂无会话
                Bus.emit('app:default-page-nodata', DefaultPageType.SESSION);
            }
        }
    }, [allSessionList, loadAllSessionListEnd, updatePageLoadState, selectedNotifyType]); // eslint-disable-line
    useEffect(() => {
        if (!allSessionList) {
            updatePageLoadState({
                rightLoadState: CompDataLoadState.INIT,
                rightPageType: null,
            });
            return;
        }
        if (allSessionList.length === 0) {
            // 暂无会话
            updatePageLoadState({
                rightLoadState: CompDataLoadState.NODATA,
                rightPageType: DefaultPageType.SESSION,
            });
        } else if (allSessionList.length > 0) {
            updatePageLoadState({
                rightLoadState: CompDataLoadState.INIT,
                rightPageType: null,
            });
        }
    }, [allSessionList, allSessionList.length, updatePageLoadState]);

    // const sessionNodeList = useMemo(() => {
    //     if (!allSessionList) {
    //         return [];
    //     }
    //     console.log('sessionNodeList  11==>');
    //     return allSessionList.map((s, index) => {
    //         return {
    //             session: s,
    //             SessionNode: <SessionNode session={s} index={index} last={false} />,
    //         };
    //     });
    // }, [allSessionList]);

    useEffect(() => {
        const sessionUnReadCount = sessionUnReadList.length;
        if (sessionUnReadCount === 1 && preIndex === sessionScrollIndex) {
            setSessionScrollIndex({ sessionScrollIndex: -1 });
        }

        if (sessionScrollIndex === -1 || !sessionUnReadCount || preIndex === sessionScrollIndex) {
            return;
        }
        const { id, index } = sessionUnReadList[sessionScrollIndex];
        if (!id || index === -1) {
            return;
        }
        if (scrollref) {
            scrollref.scrollTop = sessionItem.height * index;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionScrollIndex, preIndex, sessionUnReadList, setSessionScrollIndex]);

    const getCategorySessionList = useCallback(
        (type: string) => {
            if (!allSessionList || (allSessionList || []).length === 0) {
                return [];
            }
            // AI助手置顶情况下默认第一位展示
            const aiSessionIndex = allSessionList.findIndex((item: Session) =>
                isAINoticeSession(item)
            );
            if (
                aiSessionIndex > -1 &&
                ((allSessionList[aiSessionIndex].settings || {}).top || 0) > 0
            ) {
                const [aiSessionItem] = allSessionList.splice(aiSessionIndex, 1);
                // 将AI助手日期默认改为今天，以便置顶
                aiSessionItem.timestamp = Date.now();
                allSessionList.splice(0, 0, aiSessionItem);
            }
            // 先把除AI助手外的notice类型的session过滤掉
            return allSessionList
                .filter((session) => !session.isNotice || !isAINoticeChildSession(session))
                .filter((session) => {
                    const a = sessionConditionMap[type](session);
                    const b = messageNotifyTypeConditionMap[selectedNotifyType](
                        session,
                        currentEmployee
                    );
                    return a && b;
                });
        },
        [allSessionList, currentEmployee, selectedNotifyType] //eslint-disable-line
    );

    const displayCategorySessionStyle = (key: MessageCategoryType) => {
        return {
            display: selectedCategory === key ? 'block' : 'none',
        };
    };

    if (!allSessionList) {
        return null;
    }

    return (
        <div
            className="message-container"
            style={{ display: showSessionTopAll ? 'none' : 'block' }}
        >
            {selectedCategory === MessageCategoryType.TIME && (
                <div
                    style={displayCategorySessionStyle(MessageCategoryType.TIME)}
                    id="session_time"
                >
                    {getCategorySessionList(MessageTimeCategory.EARLIER).length === 0 &&
                    getCategorySessionList(MessageTimeCategory.TODAY).length === 0 ? (
                        <NoDataTips type={selectedNotifyType} />
                    ) : (
                        <SessionCategory
                            id="category_time.all"
                            title={t('category_time.all')}
                            dataList={getCategorySessionList(MessageTimeCategory.ALL)}
                            sessionHeight={sessionItem.height}
                        />
                    )}
                </div>
            )}
            {selectedCategory === MessageCategoryType.TYPE && (
                <div
                    style={displayCategorySessionStyle(MessageCategoryType.TYPE)}
                    id="session_type"
                >
                    {/* 群组消息会话 */}
                    <SessionCategory
                        id="category_type.group"
                        title={t('category_type.group')}
                        dataList={getCategorySessionList(MessageTypeCategory.GROUP)}
                        sessionHeight={sessionItem.height}
                    />
                    {/* 单聊消息会话 */}
                    <SessionCategory
                        id="category_type.single"
                        title={t('category_type.single')}
                        dataList={getCategorySessionList(MessageTypeCategory.SINGLE)}
                        sessionHeight={sessionItem.height}
                    />
                    {/* 通知消息会话 */}
                    <SessionCategory
                        id="category_type.notice"
                        title={t('category_type.notice')}
                        dataList={getCategorySessionList(MessageTypeCategory.NOTICE)}
                        sessionHeight={sessionItem.height}
                    />
                    {/* 免打扰消息会话 */}
                    <SessionCategory
                        id="category_type.no_disturbing"
                        title={t('category_type.no_disturbing')}
                        dataList={getCategorySessionList(MessageTypeCategory.NODISTURBING)}
                        sessionHeight={sessionItem.height}
                    />
                </div>
            )}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const {
        atCount,
        unReadCount,
        selectedNotifyType,
        selectedCategory,
        sessionStatusMap,
        loadAllSessionListEnd,
        selectedSession,
        sessionScrollIndex,
        sessionUnReadList,
    } = chat as ChatState;
    return {
        atCount,
        unReadCount,
        selectedNotifyType,
        selectedCategory,
        sessionStatusMap,
        loadAllSessionListEnd,
        selectedSession,
        sessionScrollIndex,
        sessionUnReadList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async reSortAllSessionList(data: { allSessionList: SessionProps[] }) {
            await dispatch({ type: 'chat/reSortAllSessionList', payload: data });
        },
        updatePageLoadState(data: {
            rightLoadState: CompDataLoadState;
            rightPageType: DefaultPageType | null;
        }) {
            setTimeout(() => {
                dispatch({ type: 'app/updatePageLoadState', payload: data });
            });
        },
        setSessionScrollIndex: (data: any) => {
            dispatch({ type: 'chat/setSessionScrollIndex', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionList);
