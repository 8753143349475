const REACT_APP_OSS_BASE_URL =
    process.env.REACT_APP_OSS_BASE_URL || 'https://focus-desktop.s3.cn-north-1.jdcloud-oss.com';
import i18n from '@/i18n';
import { isBjme } from '@/utils';
import { stickers_sets } from './stickers-config';
export const cache: any = {};
function importAll(r: any) {
    // eslint-disable-next-line no-return-assign
    r.keys().forEach((key: any) => (cache[key] = r(key)));
}
// @ts-ignore
importAll(require.context('@/assets/img/stickers/large-99'));
export const emojiMap: any = {
    '#E-s31': 'em01',
    '#E-s65': 'em02',
    '#E-s23': 'em03',
    '#E-a15': 'em04',
    '#E-s18': 'em05',
    '#E-s04': 'em06',
    '#E-s11': 'em07',
    '#E-s42': 'em08',
    '#E-s26': 'em09',
    '#E-s61': 'em10',
    '#E-s51': 'em11',
    '#E-s08': 'em12',
    '#E-s30': 'em13',
    '#E-s20': 'em14',
    '#E-s16': 'em15',
    '#E-s78': 'em16',
    '#E-a24': 'em17',
    '#E-s40': 'em18',
    '#E-s29': 'em19',
    '#E-s28': 'em20',
    '#E-s57': 'em21',
    '#E-s89': 'em22',
    '#E-s41': 'em23',
    '#E-a16': 'em24',
    '#E-s55': 'em25',
    '#E-s12': 'em26',
    '#E-s05': 'em27',
    '#E-s43': 'em28',
    '#E-s82': 'em29',
    '#E-s69': 'em30',
    '#E-s34': 'em31',
    '#E-s92': 'em32',
    '#E-s37': 'em33',
    '#E-s25': 'em34',
    '#E-s60': 'em35',
    '#E-s53': 'em36',
    '#E-s38': 'em37',
    '#E-a17': 'em38',
    '#E-s73': 'em39',
    '#E-s09': 'em40',
    '#E-s15': 'em41',
    '#E-s80': 'em42',
    '#E-s81': 'em43',
    '#E-s47': 'em44',
    '#E-s03': 'em45',
    '#E-s32': 'em46',
    '#E-a25': 'em47',
    '#E-s35': 'em48',
    '#E-s22': 'em49',
    '#E-s17': 'em50',
    '#E-a22': 'em51',
    '#E-a26': 'em52',
    '#E-a27': 'em53',
    '#E-s56': 'em54',
    '#E-a28': 'em55',
    '#E-s21': 'em56',
    '#E-s76': 'em57',
    '#E-s77': 'em58',
    '#E-s01': 'em59',
    '#E-s75': 'em60',
    '#E-s06': 'em61',
    '#E-a29': 'em62',
    '#E-a21': 'em63',
    '#E-n02': 'em64',
    '#E-s68': 'em65',
    '#E-a18': 'em66',
    '#E-s39': 'em67',
    '#E-s87': 'em68',
    '#E-s33': 'em69',
    '#E-s66': 'em70',
    '#E-a19': 'em71',
    '#E-s86': 'em72',
    '#E-n12': 'em73',
    '#E-s85': 'em74',
    '#E-s74': 'em75',
    '#E-s10': 'em76',
    '#E-a01': 'em77',
    '#E-a02': 'em78',
    '#E-a03': 'em79',
    '#E-a04': 'em80',
    '#E-a05': 'em81',
    '#E-a06': 'em82',
    '#E-a07': 'em83',
    '#E-a08': 'em84',
    '#E-a09': 'em85',
    '#E-a10': 'em86',
    '#E-a11': 'em87',
    '#E-a12': 'em88',
    '#E-a13': 'em89',
    '#E-a14': 'em90',
    '#E-a20': 'em91',
    '#E-a30': 'em92',
    '#E-a31': 'em93',
    '#E-s19': 'em94',
    '#E-a32': 'em95',
};

export function convertStringToEmoji(content: string | undefined) {
    if (!content) {
        return '';
    }
    return isBjme() ? convertStringToEmojiInBj(content) : convertStringToEmojiInME(content);
}

function convertStringToEmojiInBj(content: string) {
    let result = content;
    // eslint-disable-next-line prefer-regex-literals
    const patt = /\[.*?\]/g;
    const re = result.match(patt);
    // const re = result.split('');
    if (re && re.length > 0) {
        re.forEach((r) => {
            if (!r) {
                return;
            }
            if (!stickers_sets[r]) {
                return;
            }
            result = result.replace(
                r,
                `<img class="dd-emoji" data-id="dd-emoji-${r.slice(3)}" src="${
                    cache[`./${stickers_sets[r]}`]
                }" draggable="false">`
            );
        });
    }
    return result;
}

function convertStringToEmojiInME(content: string) {
    let result = content;
    // eslint-disable-next-line prefer-regex-literals
    const patt = /#E-[s,a,n][0-9]{2}/g;
    const re = result.match(patt);

    if (re && re.length > 0) {
        re.forEach((r) => {
            if (!r) {
                return;
            }
            if (!emojiMap[r]) {
                return;
            }
            result = result.replace(
                r,
                `<img class="dd-emoji" data-id="dd-emoji-${r.slice(
                    3
                )}" src="${REACT_APP_OSS_BASE_URL}/statics/images/ddemoji/opim_${
                    emojiMap[r]
                }.png" draggable="false">`
            );
        });
    }
    return result;
}

export function convertStringToNoticeEmoji(content: string) {
    if (!content) {
        return '';
    }
    let result = content;
    // eslint-disable-next-line prefer-regex-literals
    const patt = /#E-[s,a,n][0-9]{2}/g;
    const re = result.match(patt);

    if (re && re.length > 0) {
        re.forEach((r) => {
            if (!r) {
                return;
            }
            if (!emojiMap[r]) {
                return;
            }
            result = result.replace(r, `[${i18n.t('chat:emoji')}]`);
        });
    }
    return result;
}
