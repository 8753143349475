import React, { useContext, Dispatch, SetStateAction, useState } from 'react';
import { Input, Button, message } from 'antd';
import List from './List';
import List2 from './List/list';
import selector from './selector';
import Context from '../../context';
import * as api from '../../api';
import { ProjectRole, IMember, Project } from '../../types';
import { isSameUser } from '../../util';
import bus from '@/utils/bus';
import { TaskPartEnum } from '@/types/work';
import './index.less';

interface Props {
    project: Project;
    isZhuanbanSetting?: boolean;
    onClose?: Dispatch<SetStateAction<boolean>>;
    isUpdate?: boolean;
}

export type ProjectMember = IMember & { selected: boolean };

let requestListTotal = 0;

export default function (props: Props) {
    const {
        events,
        user = {
            ddAppId: '',
            selectedTeamId: '',
            userId: '',
        },
        t,
    } = useContext(Context);
    const { project, isZhuanbanSetting = false, onClose, isUpdate } = props;
    const [members, setMembers] = React.useState<ProjectMember[]>([]);
    const selecteds = React.useRef<ProjectMember[]>([]);
    const [loading, setLoading] = useState(false);
    function getMembers(keyword?: string) {
        const total = ++requestListTotal;
        setLoading(true);
        api.findProjectsMemberService(project.id, keyword)
            .then((data) => {
                if (data.errCode) return;
                if (requestListTotal === total && data?.projects) {
                    setMembers(
                        data.projects.map((m: any) => ({
                            name: m.userName,
                            avatar: m.headImg,
                            userId: m.userId,
                            teamId: m.teamId,
                            role: m.userRole,
                            duty: m.duty,
                            app: m.app,
                        }))
                    );
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function deleteMember() {
        const __members = selecteds.current;
        if (__members.length === 0) {
            return message.warning(t('basic_setting.no_member'));
        }
        const { userInfo } = project;
        const _members = __members.filter((m) => {
            // 不可以删除自身
            if (
                isSameUser(m, {
                    app: userInfo?.app || '',
                    teamId: userInfo?.teamId || '',
                    userId: userInfo?.userId || '',
                })
            ) {
                return false;
            }
            // 负责人可以删除所有人
            if (userInfo?.userRole === ProjectRole.OWNER) {
                return true;
            }
            // 管理员只能删除普通人
            return m.role !== ProjectRole.ADMIN;
        });
        api.deleteProjectsMember(project.id, _members).then((data) => {
            if (data.errCode) return;
            const newMembers = members.filter((m) => !_members.includes(m));
            setMembers(newMembers);
            message.success(t('batch_remove_success'));
        });
    }

    function addMembers() {
        selector(
            {
                title: t('basic_setting.select_member'),
                teamId: project.userInfo?.teamId || '',
                selected: members,
                currentUser: {
                    app: user.ddAppId,
                    pin: user.userId,
                    teamId: user.selectedTeamId,
                },
            },
            (data, close) => {
                const items = data.map((member) => ({
                    ...member,
                    userRole: ProjectRole.MEMBER,
                }));
                api.addProjectsMember(project.id, items)
                    .then((data) => {
                        if (data.errCode) return;
                        message.success(t('basic_setting.add_member_success'));
                        getMembers();
                        events.emit('members.change');
                    })
                    .finally(close);
            }
        );
    }

    React.useEffect(getMembers, [project, isUpdate]);
    React.useEffect(() => {
        function multiSync(data: any) {
            if (!data?.data) return;
            if (
                data.data.projectId === project.id &&
                data.data.part === TaskPartEnum.PROJECT_USER
            ) {
                getMembers();
            }
        }
        bus.on('chat_message_multiSync_event:joyWork', multiSync);
        return () => {
            bus.off('chat_message_multiSync_event:joyWork', multiSync);
        };
        // eslint-disable-next-line
    }, []);

    function search() {
        let timeout: NodeJS.Timeout | null = null;
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const keyword = e.target.value.replace(/\s+/g, '');
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                getMembers(keyword || undefined);
            }, 200);
        };
    }

    const editEnable =
        !project.archive &&
        (project?.userInfo?.userRole === ProjectRole.ADMIN ||
            project?.userInfo?.userRole === ProjectRole.OWNER);

    return (
        <div className="pm-mm">
            <div className="pm-mm-header">
                <div>
                    <Input
                        type="text"
                        placeholder={t('basic_setting.duty_placeholder')}
                        onChange={search()}
                    />
                </div>
                {editEnable && (
                    <div>
                        <Button onClick={deleteMember}>{t('batch_remove')}</Button>
                        <Button type="primary" onClick={addMembers}>
                            {t('basic_setting.add_member')}
                        </Button>
                    </div>
                )}
            </div>
            {members && !isZhuanbanSetting && (
                <List
                    setSelecteds={(items) => {
                        selecteds.current = items;
                    }}
                    project={project}
                    source={members}
                    onDelete={(m) => {
                        const _members = members.filter((o) => !isSameUser(o, m));
                        setMembers(_members);
                    }}
                />
            )}
            {members && isZhuanbanSetting && (
                <List2
                    setSelecteds={(items) => {
                        selecteds.current = items;
                    }}
                    project={project}
                    source={members}
                    onDelete={(m) => {
                        const _members = members.filter((o) => !isSameUser(o, m));
                        setMembers(_members);
                    }}
                    onClose={onClose}
                    loading={loading}
                />
            )}
        </div>
    );
}
