import React, { CSSProperties } from 'react';
import IconFont from '@/components/icon';
import './common.less';
import i18n from '@/i18n';
import { changeEgovUrl } from '@/utils/tools';

export function SubHeader({
    title,
    more,
    moreClickHandle,
}: {
    title: string;
    more?: boolean;
    moreClickHandle?: Function;
}) {
    return (
        <p className="dp-sub-header">
            <span
                style={{
                    width: 2,
                    height: 14,
                    marginRight: 4,
                }}
                className="dp-primary-bg"
            />
            <span style={{ flex: 1 }}>{title}</span>
            {more ? (
                <span className="dp-more" onClick={() => moreClickHandle?.()}>
                    {i18n.t('supervise:view')}
                    <IconFont
                        style={{
                            fontSize: 10,
                        }}
                        type="iconwin_right"
                    />
                </span>
            ) : null}
        </p>
    );
}
export function HeaderWithMore({
    title,
    icon,
    clickHandle,
}: {
    title: string;
    icon: any;
    clickHandle: Function;
}) {
    return (
        <div
            style={{
                display: 'flex',
                height: 36,
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 12px',
            }}
        >
            <p
                style={{
                    fontSize: 14,
                    color: '#232831',
                }}
            >
                <img src={changeEgovUrl(icon)} style={{ width: 16, marginRight: 8 }} />
                {title}
            </p>
            <p className="dp-more" onClick={() => clickHandle()}>
                {i18n.t('supervise:view')}
                <IconFont style={{ fontSize: 11 }} type="iconwin_right" />
            </p>
        </div>
    );
}
export function TextWithDot({
    label,
    value,
    colorValue,
    colorDot,
    styles,
}: {
    label: string;
    value: number;
    colorValue?: string;
    colorDot?: string;
    styles?: CSSProperties;
}) {
    const styleDot = {
        width: 6,
        height: 6,
        display: 'inline-block',
        borderRadius: '50%',
        backgroundColor: colorDot ? colorDot : '#61656D',
    };
    return (
        <p
            style={{
                color: '#61656D',
                display: 'felx',
                alignItems: 'center',
                fontSize: 0,
                ...styles,
            }}
        >
            <span style={{ marginRight: 8, ...styleDot }} />
            <span style={{ marginRight: 4, fontSize: 12 }}>{label}</span>
            <span
                style={{
                    color: colorValue ? colorValue : '#61656D',
                    fontWeight: 'bold',
                    fontSize: 14,
                }}
            >
                {value}
            </span>
        </p>
    );
}
