import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Tabs as AantdTabs } from 'antd';
import Item from '../Item';
import { joyspacePermissionType } from '@/components/Joyspace/config/permissions.config';
const { TabPane } = AantdTabs;
import './index.less';
import { Loading as LoadMoreLoading } from '@jd/focus-desktop-comps';
import { throttle } from 'lodash';
import Loading from '@/components/Loading';
import noDataPicture from '@/assets/img/no-content.png';
import { FileType, SharesMembersType } from '@/components/Joyspace/types';
interface IProps {
    list: any[];
    config: any[];
    type: any;
    defaultActiveKey?: any;
    permissionType: joyspacePermissionType;
    pagination?: Boolean;
    loading?: Boolean;
    isLoadMore?: Boolean;
    onChangeTab?: (val: any) => void;
    handleMenuClick?: (val: any, id: string) => void;
    onButtom?: () => void;
}

const Tabs = (props: IProps) => {
    const {
        config,
        defaultActiveKey,
        type,
        list,
        onChangeTab,
        pagination,
        permissionType,
        loading,
        isLoadMore,
        handleMenuClick,
        onButtom,
    } = props;
    // console.log(permissionType, 'myPermissionTypemyPermissionTypemyPermissionTypemyPermissionType');
    const scrollViewRef: any = useRef();
    const onScroll = () => {
        if (!pagination) {
            return;
        }
        const cu: any = scrollViewRef.current;
        const scrollTop = cu.scrollTop;
        const cHight = cu.clientHeight;
        const sHight = cu.scrollHeight;
        // console.log(cu, isLoadMore, !loading, scrollTop, cHight, sHight);
        if (cu && isLoadMore && !loading && scrollTop + cHight >= sHight - 10) {
            onButtom?.();
            // console.log('========================>');
        }
    };
    const getNode = useMemo(() => {
        const owner =
            list.filter(
                (item: any) =>
                    item.permissionType === joyspacePermissionType.ReadAll &&
                    item.memberType === SharesMembersType.CONTACT
            ).length === 1;
        return list.map((item: any, index: number) => {
            return (
                <Item
                    key={index}
                    {...item}
                    owner={owner}
                    permission={permissionType}
                    fileType={type}
                    handleMenuClick={handleMenuClick}
                />
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, permissionType]);
    return (
        <div>
            <AantdTabs defaultActiveKey={defaultActiveKey} onChange={onChangeTab}>
                {config.map((item) => {
                    return <TabPane tab={`${item.text}`} key={item.type} />;
                })}
            </AantdTabs>
            <div
                className="joyspace-tab-list-box"
                ref={scrollViewRef}
                onScroll={throttle(onScroll, 400)}
            >
                {!loading && list.length > 0 && getNode}
                {loading && !list.length && (
                    <div className="joyspace-tab-list--not-data">
                        <div className="list-tab-loading">
                            <Loading />
                        </div>
                    </div>
                )}
                {!loading && isLoadMore && list.length > 0 && (
                    <div className="joyspace-tab-list--not-data">
                        <div className="joyspace-tab-more">
                            <LoadMoreLoading />
                            <span style={{ whiteSpace: 'nowrap' }}>加载更多</span>
                        </div>
                    </div>
                )}
                {!loading && !list.length && (
                    <div className="joyspace-tab-list--not-data">
                        <div className="joyspace-tab--doc-nodata">
                            <img src={noDataPicture} />
                            <p>暂无数据</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tabs;
