/* eslint-disable array-callback-return */
// 组通知历史记录
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Drawer, List, Menu, Empty, message as toast, Dropdown } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@/baseComponents/Avatar';
import IconFont from '@/components/icon';
import { pageNotificationList } from '@/api/notification';
import { formate } from '@/utils/date';
import { ChatMessage } from '@/types/chat';
import NoticeDetails from '../../../GroupNotificationCard/NoticeDetails';
import noticeImg from '@/assets/img/no-content.png';
import { CalendarIcon } from '@/components/Calendars/Common';
import { getFileIconByMime, getFileUrl, downLoadFile } from '@/utils/chat/index';
import {
    buildJoyspaceIconUrl,
    buildPagePathKey,
} from '@/components/Modals/JoyspaceFileSelectorModal/utils';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { FocusSDK, isFocusEnv } from '@/utils';
import { replaceGovUrl } from '@/utils/joyspace';
import DeeplinkEvent from '@/components/DeeplinkHandler/DeeplinkEvent';
import { connect } from 'dvajs';
import { changeEgovUrl } from '@/utils/tools';
interface IPropss {
    visible: boolean;
    onClose: () => void;
    sessionId: string;
    openNoticeDetails: (data: { isvisibles: boolean; notificationId: any; type?: string }) => void;
}
type IProps = Readonly<IPropss>;
function History(props: IProps) {
    const { visible, onClose, sessionId, openNoticeDetails } = props;
    const [t] = useTranslation('chat');
    const {
        appRuntimeEnv: { joyspaceApiHost, joyspaceHost },
    } = useContext<GlobalContextConfig>(GlobalContext);
    const [notificationList, setNotificationList] = useState<any>();
    const [isvisible, setIsvisible] = useState(false);
    const listData = [];
    const getPageNotificationList = useCallback(async () => {
        const [data, err] = await pageNotificationList({
            sourceType: 1,
            sourceBizId: sessionId,
            offset: 0,
            limit: 100,
        });
        // console.log('查看历史', data, err);
        if (err) {
            toast.error(err);
            return;
        }
        if (data) {
            setNotificationList(data.notificationList);
        }
    }, [sessionId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (visible === true && sessionId) {
            getPageNotificationList();
        }
    }, [getPageNotificationList, visible, sessionId]);

    const hadleOpendel = (item: any) => {
        setIsvisible(true);
        openNoticeDetails({
            isvisibles: true,
            notificationId: item.notificationId,
            type: 'history',
        });
    };

    if (notificationList === undefined) return null;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < notificationList.length; i++) {
        listData.push({
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                        style={{
                            width: '200px',
                            // overflow: 'hidden',
                            // whiteSpace: 'nowrap',
                            // textOverflow: 'ellipsis',
                        }}
                        className="ellipsis"
                    >
                        {notificationList[i].realName}
                    </span>
                    <span
                        style={{
                            fontSize: '11px',
                            color: '#62656d',
                            height: '18px',
                            lineHeight: '18px',
                        }}
                    >
                        发布于 {formate(notificationList[i].gmtCreate)}
                    </span>
                </div>
            ),
            avatar: (
                <Avatar
                    name={`${notificationList[i].realName}`}
                    className="imgAvatar"
                    src={changeEgovUrl(notificationList[i].headImg)}
                />
            ),
            realName: `${notificationList[i].realName}`,
            notificationId: `${notificationList[i].notificationId}`,
            // description: (
            //     <div style={{ display: 'flex', alignItems: 'center' }}>
            //         {notificationList[i].attachmentList.length > 0 && (
            //             <div
            //             // style={{
            //             //     marginRight: '14px',
            //             // }}
            //             >
            //                 <Dropdown
            //                     overlay={() => {
            //                         return (
            //                             <Menu>
            //                                 {notificationList[i].attachmentList?.map(
            //                                     (files: any, index: any) => {
            //                                         return (
            //                                             <Menu.Item key={index}>
            //                                                 <div
            //                                                     onClick={() => {
            //                                                         downloadFile(files);
            //                                                     }}
            //                                                 >
            //                                                     <span>
            //                                                         <CalendarIcon
            //                                                             // className="left"
            //                                                             src={getAttachmentIcon(
            //                                                                 files
            //                                                             )}
            //                                                             width="12px"
            //                                                             height="12px"
            //                                                         />
            //                                                     </span>
            //                                                     <span style={{ marginLeft: '6px' }}>
            //                                                         {files.fileName}
            //                                                     </span>
            //                                                 </div>
            //                                             </Menu.Item>
            //                                         );
            //                                     }
            //                                 )}
            //                             </Menu>
            //                         );
            //                     }}
            //                     trigger={['hover']}
            //                 >
            //                     <a
            //                         className="ant-dropdown-link"
            //                         onClick={(e) => e.preventDefault()}
            //                     >
            //                         <IconFont type="iconapp_ic_attachment" />
            //                         <span style={{ margin: '0 2px' }}>
            //                             {notificationList[i].attachmentList === null
            //                                 ? 0
            //                                 : notificationList[i].attachmentList.length}
            //                         </span>
            //                         <IconFont
            //                             style={{ fontSize: '10px' }}
            //                             className="chat-input-more-menu-icons"
            //                             type="iconlog_down"
            //                             clstag="pageclick|keycount|focus_chat_01_1602584371145|47"
            //                         />
            //                     </a>
            //                 </Dropdown>
            //             </div>
            //         )}

            //         {/* <IconFont
            //             className="chat-input-more-menu-icon"
            //             type="iconlog_more"
            //             clstag="pageclick|keycount|focus_chat_01_1602584371145|48"
            //         /> */}
            //     </div>
            // ),
            content: (
                <div>
                    <p className="history_con" style={{ whiteSpace: 'pre-wrap', color: '#232930' }}>
                        {notificationList[i].content}
                    </p>
                    {/* <br /> */}
                    <div style={{ marginTop: '7px' }}>
                        <span style={{ color: '#8F959E', marginRight: '30px' }}>
                            已回复 {notificationList[i].repliedNum}
                        </span>
                        <span style={{ color: '#8F959E' }}>
                            未回复 {notificationList[i].unansweredNum}
                        </span>
                    </div>
                </div>
            ),
        });
    }
    return (
        <>
            <Drawer
                className="DrawerHistorybox"
                width={540}
                title={t('group_tz')}
                placement="right"
                onClose={onClose}
                visible={visible}
            >
                {notificationList.length > 0 ? (
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={listData}
                        split={false}
                        // pagination={
                        //     notificationList.length > 0 && {
                        //         onChange: (page) => {
                        //             setlimit(page);
                        //         },
                        //         pageSize: 5,
                        //     }
                        // }
                        renderItem={(item: any) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={item.avatar}
                                    title={item.title}
                                    description={item.description}
                                />
                                {item.content}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        hadleOpendel(item);
                                    }}
                                />
                            </List.Item>
                        )}
                    />
                ) : (
                    <div>
                        <Empty
                            image={noticeImg}
                            imageStyle={{
                                height: 100,
                            }}
                            description={<span style={{ color: '#62656D' }}>暂无组通知</span>}
                        />
                    </div>
                )}
            </Drawer>
            {/* {isvisible && <NoticeDetails />} */}
        </>
    );
}
function mapDispatchToProps(dispatch: any) {
    return {
        openNoticeDetails(data: { isvisibles: boolean; notificationId: any; type?: string }) {
            dispatch({ type: 'chat/openNoticeDetails', payload: data });
        },
    };
}
export default connect(undefined, mapDispatchToProps)(History);
