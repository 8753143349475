import React, { useMemo, useCallback, useState, useContext } from 'react';
import './index.less';
// import { connect } from 'dva';
import IconFont from '@/components/icon';
import { Avatar } from '@/baseComponents/Avatar';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { Tooltip, Modal, Button, Select, Dropdown } from 'antd';
const { Option } = Select;
import { WorkUserInfo } from '@/types/work';
import { PROCESS_STATUS_EXECUTORS } from '@/components/Projects/emnu';
import { TASK_STATUS } from '@/types/newWork';
import { QuestionCircleOutlined } from '@ant-design/icons';

export function number2percentum(num: number, fixed = 2, simplifyZero = true): string {
    let ret = (100 * num).toFixed(fixed);
    if (simplifyZero) {
        ret = ret.replace(/\.?0+$/, '');
    }
    return `${ret}%`;
}
export function percentum2number(numstr: string): number {
    const ratio = numstr.includes('%') ? 100 : 1;
    let num = parseFloat(numstr);
    return num / ratio;
}

const arrWeight = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function WeightSelector(defaultValue = 1, item: WorkUserInfo, disable: boolean) {
    const [val, setVal] = useState(() => {
        // 平均权重
        if (disable) {
            item.weight = defaultValue;
        }
        if (item.weight === undefined) {
            item.weight = defaultValue;
        }
        return item.weight;
    });
    const [options, setOptions] = useState(arrWeight);
    const handleChange = useCallback(
        (value) => {
            item.weight = value;
            setVal(value);
        },
        [item]
    );
    const handleSearch = useCallback(
        (value) => {
            if (!value) {
                setOptions(arrWeight);
            } else {
                setOptions([]);
                if (Number.isInteger(value) && value >= 0) item.weight = value;
            }
        },
        [item]
    );

    return (
        <div className="weight-selector">
            <Select
                showSearch={false}
                placeholder="请选填自然数"
                value={val}
                disabled={disable}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
            >
                {options.map((v) => (
                    <Option className="selector-opts-textalinecenter" value={v} key={v}>
                        {v}
                    </Option>
                ))}
            </Select>
        </div>
    );
}

function WorkUserListWeight({ weightModalConfig }: { weightModalConfig: any }) {
    const {
        close = () => {},
        userArr = [],
        complete,
        backShowUserSelectionModal,
        initialAverage = true,
    } = weightModalConfig;
    const defaultValue = 5;
    const [showExplain, setShowExplain] = useState(false);
    const [isAverage, setIsAverage] = useState(() => {
        let judge = userArr.every((v: any) => {
            return v.weight === undefined || v.weight === defaultValue;
        });
        return judge && initialAverage ? '1' : '0';
    });

    const handleChange = useCallback((value) => {
        setIsAverage(value);
    }, []);
    const alertFn = useCallback((value) => {
        setIsAverage(value);
    }, []);
    return (
        userArr.length && (
            <>
                <Modal
                    className="weight-model"
                    title="协同人管理"
                    width={480}
                    visible={true}
                    centered={true}
                    okText="完成"
                    onOk={() => {
                        if (isAverage === '1') {
                            userArr.forEach((element: any) => {
                                element.weight = defaultValue;
                            });
                        }
                        complete(userArr);
                        close();
                    }}
                    onCancel={close}
                    closeIcon={
                        <IconFont type="iconClose" style={{ fontSize: '12px', color: '#62656D' }} />
                    }
                >
                    <div
                        className={`work-user-weight-wrap ${isAverage === '1' ? 'isAverage' : ''}`}
                    >
                        <div className="weight-header">
                            <p>
                                {/* 该任务共 <span>{userArr.length}</span> 协同人 */}
                                任务权重分配
                                <QuestionCircleOutlined
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setShowExplain(true)}
                                />
                            </p>
                            <Select
                                value={isAverage}
                                style={{ width: 'auto' }}
                                onChange={handleChange}
                                optionLabelProp="label"
                                dropdownMatchSelectWidth={false}
                                dropdownClassName="weight-select-ui"
                            >
                                <Option value="1" label="平均分配权重">
                                    平均分配
                                    <p>每个协同人所占权重相同</p>
                                </Option>
                                <Option value="0" label="自定义分配权重">
                                    自定义分配
                                    <p>由任务负责人设置每个协同人所占权重</p>
                                </Option>
                            </Select>
                        </div>
                        <ul className="weight-user-list">
                            {userArr.map((v: any) => {
                                return (
                                    <li className="weight-user-li" key={v.userId}>
                                        <div className="left-personal-information">
                                            <Avatar
                                                src={getHeadImgThumbUrl(v.headImg, 150, 150)}
                                                styleObj={{
                                                    width: '32px',
                                                    height: '32px',
                                                    borderRadius: 4,
                                                    cursor: 'pointer',
                                                }}
                                                className="avatar"
                                                name={v.realName || ''}
                                            />
                                            <div className="gt-part">
                                                <p>{v.realName || ''}</p>
                                                <em>{v.titleName || ' '}</em>
                                            </div>
                                        </div>
                                        <div className="right-statement">
                                            {v.processStatus && (
                                                <span
                                                    className={`personal-process-status tag-process-status-${v.processStatus}`}
                                                >
                                                    {
                                                        PROCESS_STATUS_EXECUTORS[
                                                            v.processStatus as TASK_STATUS
                                                        ]
                                                    }
                                                </span>
                                            )}
                                            {WeightSelector(defaultValue, v, isAverage === '1')}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        {backShowUserSelectionModal && (
                            <div className="weight-buttons">
                                <Button type="link" onClick={backShowUserSelectionModal}>
                                    ＋ 添加协同人
                                </Button>
                            </div>
                        )}
                    </div>
                </Modal>
                <Modal
                    className="weight-explain-model"
                    title="协同人管理"
                    width={480}
                    visible={showExplain}
                    centered={true}
                    okText="完成"
                    onCancel={() => setShowExplain(false)}
                    closeIcon={
                        <IconFont
                            type="iconClose"
                            style={{ fontSize: '12px', color: '#62656D', marginTop: '-4px' }}
                        />
                    }
                    footer={null}
                >
                    <div className="weight-explaination">
                        <p>
                            通过任务负责人设置执行权重、协同人设置个人进度、系统自动计算任务总进度三个步骤来实现任务可视化进度管理，从而高效助力任务目标的达成。
                        </p>
                        <h4>一、如何分配任务执行权重</h4>
                        <p>
                            仅任务负责人可以调整任务协同人的执行权重。系统默认所有协同人的进度对任务总进度的影响是一致的，权重均是5，即同一任务下所有协同人执行权重占比保持相同。任务负责人可根据协同人对任务总进度的影响大小，调整协同人的权重。分为0-10一共11个档位，其中1代表该协同人的推进进度对任务总进度无影响，10代表该协同人的推进进度对任务总进度的影响最大。
                        </p>
                        <h4>二、协同人标记个人推进进度数据</h4>
                        <p>协同人可根据实际进展，从0%-100%标记推进进度。</p>
                        <h4>三、系统自动计算任务总进度数据</h4>
                        <p>
                            任务总进度=协同人1的进度*协同人1的权重占比+协同人2的进度*协同人2的权重占比+···+协同人n的进度*协同人n的权重占比。
                        </p>
                    </div>
                </Modal>
            </>
        )
    );
}

export default WorkUserListWeight;
