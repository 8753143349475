/* eslint-disable no-debugger */
/* eslint-disable max-nested-callbacks */
/* eslint-disable no-console */
/* eslint-disable complexity */
/**
 * @author sunzhiguang
 * @date 2020/7/6
 */

/**
 * 消息盒子容器组件， 所有消息记录会在这里
 * @author sunzhiguang
 * @date 2020/6/10
 */
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MessageBox from '@/components/chat/message/Box';
import Loading from '@/components/Loading';
import { MessageSendType, MessageTypeCategory } from '@/types/chat/enum';
import SimpleTime from '@/components/chat/message/Time/Simple';
import './index.less';
import ChatState, { AITabState, SessionMessageMap } from '@/types/chat/State';
import { connect } from 'dva';
import TimeLine from '@/components/chat/message/Time/Line';
import ImService from '@/server/ImService';
import { isSameDay } from '@/utils/date';
import {
    ChatMessage,
    Employee,
    LoadHistoryOptions,
    MessageEmitCategory,
    MessageReadScene,
    Session,
} from '@/types/chat';
import { usePrevious } from '@/utils/chat/index';
import {
    checkSysMessage,
    filterNoticeMassagesByType,
    isDownloadSysMesssage,
    isEqualEmployee,
    isSnapchatTimeSysMesssage,
} from '@/components/chat/utils/message';
import Context from '@/components/chat/context';
import bus from '@/utils/bus';

import CHAT_NOTICE, { OTHER_EXCLUDE_TABS_PIN } from '@/contant/chat';
import classNames from 'classnames';
import { isAINoticeSession } from '../../utils/session';
import { MessageStatus } from '@jd/jdee.im.sdk/lib/es/protocol/message/Type';
import IconFont from '@/components/icon';
import { FocusSDK, isFocusEnv } from '@/utils';
import { debounce } from 'lodash';
import GoEnd from '../GoEnd';
import throttle from 'lodash/throttle';
import aiEmpty from '@/assets/img/empty/ai-empty.png';
import aiArrows from '@/assets/img/empty/ai-arrows.png';
import aiNoData from '@/assets/img/empty/ai-no-data.png';
interface MessageBoxContainerProps {
    msgNodes: Array<ReactNode>;
    sendType: MessageSendType;
    selectedAITab: AITabState;
    sessionMessageMap: SessionMessageMap;
    storePreSelectedSession: Session;
}

interface DvaDispatchProps {
    setHistoryMessage: Function;
    updateSelectedAITab: Function;
    updatePreSelectedSession: Function;
    updatePreAISessionMsg: Function;
    initSelectedSession: Function;
}

type IMessageBoxContainerProps = Readonly<MessageBoxContainerProps & ChatState & DvaDispatchProps>;

const getSimpleTimeNode = (messages: ChatMessage[], prevMessages: ChatMessage[]): ReactNode => {
    const timestamp = messages[0].timestamp;
    let prev_timestamp = Date.now();
    if (prevMessages?.length) {
        prev_timestamp = prevMessages[prevMessages.length - 1].timestamp;
    }
    if (!isSameDay(timestamp, prev_timestamp)) {
        return (
            <div>
                <TimeLine timeStamp={timestamp} />
                <SimpleTime timeStamp={timestamp} />
            </div>
        );
    } else {
        if (timestamp - prev_timestamp > 60 * 1000) {
            return <SimpleTime timeStamp={timestamp} />;
        } else {
            return <div />;
        }
    }
};

const getMessageNode = (
    messages: ChatMessage[],
    getLock: Function,
    unLock: Function,
    getCurrentMessageId: Function,
    setCurrentMessageId: Function,
    session: Session,
    currentEmployee: Employee,
    updateSessionMessagesTimestamp: number,
    sText?: string,
    sMid?: number
    // eslint-disable-next-line max-params
) => {
    // console.log('getMessageNode', updateSessionMessagesTimestamp);
    if (!messages.length) {
        return [];
    }
    const groups: Array<ChatMessage[]> = [];
    let temp: ChatMessage[] = [];
    messages.forEach((m: ChatMessage) => {
        if (m && m.category === MessageEmitCategory.SYSTEM) {
            if (!checkSysMessage({ message: m, session })) return;
        }
        // 解决群成员可以看到本地发送失败的消息问题
        if (m.statusType === MessageStatus.FAILED && m.localSendTimestamp) {
            if (!m.sender?.userId) {
                const user = (m.belong || '').split(':') || [];
                m.sender = { ...m.sender, userId: user[0], app: user[1], teamId: user[2] };
            }
            if (!isEqualEmployee(m.sender, currentEmployee)) {
                return;
            }
        }
        if (!temp.length) {
            temp.push(m);
            return;
        }
        if (m.groupId !== temp[temp.length - 1].groupId) {
            groups.push([...temp]);
            temp = [m];
            return;
        }
        temp.push(m);
    });
    if (temp.length) {
        groups.push([...temp]);
    }
    return groups.map((messages, index) => {
        if (!messages[0].id) {
            return <div key={index} />;
        }
        return (
            <div key={`key-${messages[0].id}`} id={'id-' + messages[0].id}>
                {index > 0 ? (
                    getSimpleTimeNode(messages, groups[index - 1])
                ) : (
                    <SimpleTime timeStamp={messages[0].timestamp} />
                )}
                <MessageBox
                    key={`k-${messages[0].id}`}
                    messages={messages}
                    getLock={getLock}
                    unLock={unLock}
                    getCurrentMessageId={getCurrentMessageId}
                    setCurrentMessageId={setCurrentMessageId}
                    session={session}
                    sText={sText}
                    sMid={sMid}
                />
            </div>
        );
    });
};

let isScroll: any = {};
let keyVal = '';
function MessageBoxContainer(props: IMessageBoxContainerProps) {
    const mountedRef = useRef(false);
    const scrollRef = useRef(null);
    const wrapRef = useRef(null);
    const {
        selectedSession,
        sessionMessages,
        setHistoryMessage,
        initSelectedSession,
        mainWinShow,
        updateSelectedAITab,
        selectedAITab,
        sessionMessageMap,
        storePreSelectedSession,
        updatePreSelectedSession,
        updatePreAISessionMsg,
        prevAISessionMessages,
        sMid,
        sText,
        updateSessionMessagesTimestamp,
    } = props;
    // console.log(sMid, sText, 'sTextsTextsTextsTextsText');
    const { currentEmployee } = React.useContext(Context);
    const sessionId = selectedSession.sessionId;
    // console.log(selectedSession, 'IM主入口接口数据=----==>');
    // const meSessionMessages = [...sessionMessages];

    const [end, setEnd] = useState(0);

    const currPage = useRef(0);
    const canShortcut = useRef(true);
    const [loadingHistory, setLoadingHistory] = useState(false);

    const [isShowBackBottom, setShowBackBottom] = useState(false);

    const [isMousedown, setonMousedown] = useState(false);

    const prevSessionId = usePrevious(selectedSession?.sessionId || '');
    const prevSessionMessages = usePrevious(sessionMessages || []);
    const preSelectedAIPIN = usePrevious(selectedAITab?.pin || '');
    if (isAINoticeSession(selectedSession) && !prevAISessionMessages.length) {
        updatePreAISessionMsg({
            prevAISessionMessages: selectedSession
                ? sessionMessageMap[selectedSession.sessionId]
                : [],
        });
    }
    const preSelectSession = usePrevious(selectedSession || {});
    const preMainWinShow = usePrevious(mainWinShow);
    useEffect(() => {
        updatePreSelectedSession({ preSelectSession });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preSelectSession]);
    const scrollInToView = useCallback((options?: any) => {
        const curr: any = scrollRef.current;
        if (!curr) {
            return;
        }
        const scrollHeight = curr.scrollHeight;
        curr.scroll({ top: options?.top || scrollHeight, ...options });
    }, []);

    const currentMessageIdRef = useRef('');

    const setCurrentMessageId = useCallback((messageId) => {
        currentMessageIdRef.current = messageId;
    }, []);

    const getCurrentMessageId = useCallback(() => {
        return currentMessageIdRef.current;
    }, []);

    const lockRef = useRef(false);
    const firstRef = useRef(true);
    const bottomEnd = useRef(0);

    const getLock = useCallback((lock?: boolean): boolean => {
        if (lockRef.current) {
            return false;
        }
        if (lock) {
            lockRef.current = true;
        }
        return true;
    }, []);

    const unLock = useCallback(() => {
        lockRef.current = false;
    }, []);

    const scrollBottom = useCallback((force = true) => {
        const curr: any = scrollRef.current;
        if (!curr) {
            return;
        }
        const scrollHeight = curr.scrollHeight;
        const scrollTop = curr.scrollTop;
        const clientHeight = curr.clientHeight;
        // 超出阈值则不滚动，默认用户在自己浏览消息
        if (!force && scrollHeight - clientHeight - scrollTop > 200) {
            return;
        }
        curr.scroll({ top: scrollHeight - 10 });
    }, []);

    const handleScroll = useCallback((id: string) => {
        try {
            const wrap: any = wrapRef.current;
            let ele = wrap.querySelector(`#id-${id}`);
            if (!ele) {
                ele = wrap.querySelectorAll(`div[id^='item-${id}`);
                if (ele?.length) {
                    ele = ele[0];
                }
            }
            if (!ele) {
                return;
            }
            ele.scrollIntoView();
            setLoadingHistory(false);
        } catch (e) {}
    }, []);
    const maintainScroll = useCallback(
        (id: string, force = false) => {
            const curr: any = scrollRef.current;
            const wrap: any = wrapRef.current;
            if (!curr || !wrap) {
                return;
            }
            const scrollTop = curr.scrollTop;
            if (scrollTop > 10 && !force) {
                return;
            }
            handleScroll(id);
        },
        [handleScroll]
    );

    // 是否注册1v1阅后即焚快捷键
    useEffect(() => {
        if (isFocusEnv()) {
            if (prevSessionId && prevSessionId === selectedSession.sessionId) {
                return;
            }
            if (selectedSession.isSecret) {
                canShortcut.current = false;
                FocusSDK.captureScreenShortcut(true);
            } else if (!canShortcut.current) {
                canShortcut.current = true;
                FocusSDK.captureScreenShortcut(false);
            }
        }
    }, [selectedSession, prevSessionId]);

    // 后续这里可以优化一下，代码漏洞，在某些情况下会执行两次。
    const lastMessage = (sessionMessages || []).slice(-1)[0] || {};
    useEffect(() => {
        if (lastMessage.replyMsgs) {
            const fromMe = isEqualEmployee(lastMessage.sender, currentEmployee);
            if (fromMe) {
                scrollBottom(true);
            }
        }
    }, [lastMessage.replyMsgs, lastMessage.sender, currentEmployee, scrollBottom]);

    /**
     * 处理滚动事件
     */
    // eslint-disable-next-line complexity
    useEffect(() => {
        if (!prevSessionMessages?.length && !sessionMessages?.length) {
            return;
        }
        // 如果前置状态为空，当前状态有值，假定为页面初始化， 后续加入页面缓存之后需要变更本逻辑
        if (!prevSessionMessages?.length && sessionMessages.length) {
            scrollBottom(false);
            return;
        }
        const firstPre = (prevSessionMessages || [])[0] || {};
        const first = (sessionMessages || [])[0] || {};
        const lastPre = (prevSessionMessages || []).slice(-1)[0] || {};
        const last = (sessionMessages || []).slice(-1)[0] || {};
        // console.log(keyVal, '======', sText, '====sTextsTextsTextsText');

        // 如果消息长度没变化
        if (
            (prevSessionMessages &&
                sessionMessages &&
                prevSessionMessages.length === sessionMessages.length) ||
            (isDownloadSysMesssage(last) && isSnapchatTimeSysMesssage(last))
        ) {
            return;
        }
        // 如果前置状态头部一致尾部不一致，假定有新消息
        if (firstPre.id === first.id && lastPre.id !== last.id) {
            setTimeout(() => {
                // console.log('最后一条消息', last);
                const fromMe = isEqualEmployee(last.sender, currentEmployee);
                scrollBottom(fromMe);
            }, 10);
        }
        if (firstPre.id !== first.id) {
            maintainScroll(firstPre.id);
        }
    }, [
        maintainScroll,
        handleScroll,
        prevSessionMessages,
        scrollBottom,
        sessionMessages,
        selectedAITab,
        selectedSession,
        sMid,
        sText,
        currentEmployee,
    ]);

    // useMemo(() => {
    //     if (!prevSessionMessages?.length && !sessionMessages?.length) {
    //         return;
    //     }
    //     // 如果前置状态为空，当前状态有值，假定为页面初始化， 后续加入页面缓存之后需要变更本逻辑
    //     if (!prevSessionMessages?.length && sessionMessages.length) {
    //         scrollBottom(false);
    //         return;
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sText, sMid, handleScroll]);

    // tj: 增加loading
    const loadHistory = useCallback(
        (startMid, options = {}) => {
            const imService = ImService.getInstance();
            let sessionFilter: any = {};
            if (isAINoticeSession(selectedSession)) {
                switch (selectedAITab?.pin) {
                    case CHAT_NOTICE.ALL_PIN:
                        break;
                    case CHAT_NOTICE.OTHER_PIN:
                        sessionFilter.filterId = [...OTHER_EXCLUDE_TABS_PIN];
                        break;
                    default:
                        sessionFilter.includeId = [selectedAITab?.pin];
                }
                options.sessionFilter = sessionFilter;
            }
            return imService.loadHistory(selectedSession.sessionId, startMid, options);
        },
        [selectedSession, selectedAITab?.pin]
    );

    // 计算是否有新的ai消息
    const computeHasNewAIMessage = useCallback(
        (nextAIAllMessage: ChatMessage[]) => {
            if (isAINoticeSession(selectedSession)) {
                if (prevAISessionMessages.length !== nextAIAllMessage.length) {
                    updatePreAISessionMsg({ prevAISessionMessages: nextAIAllMessage });
                    return true;
                }
            }
            return false;
        },
        [selectedSession, prevAISessionMessages, updatePreAISessionMsg]
    );

    // 如果有新ai消息，同步更新tab
    const syncSelectedAITab = useCallback(
        (nextAIAllMessage: ChatMessage[]) => {
            if (
                computeHasNewAIMessage(nextAIAllMessage) &&
                selectedAITab.pin !== CHAT_NOTICE.ALL_PIN &&
                storePreSelectedSession?.sessionId !== selectedSession.sessionId
            ) {
                updateSelectedAITab({ selectedAITab: { pin: CHAT_NOTICE.ALL_PIN } });
            }
        },
        [
            selectedAITab,
            computeHasNewAIMessage,
            updateSelectedAITab,
            storePreSelectedSession,
            selectedSession,
        ]
    );

    // ai助手tab切换
    useEffect(() => {
        if (!isAINoticeSession(selectedSession)) {
            return;
        }
        if (preSelectedAIPIN !== selectedAITab?.pin) {
            currPage.current = 0;
            setLoadingHistory(true);
            loadHistory(-1, { cancel: true }).then(({ messages }: any) => {
                if (messages?.length > 0) {
                    setHistoryMessage({ sessionId, historyMessage: messages });
                    currPage.current += 1;
                    scrollInToView();
                }
                setLoadingHistory(false);
            });
        }
    }, [
        selectedAITab?.pin,
        preSelectedAIPIN,
        selectedSession,
        loadHistory,
        setHistoryMessage,
        sessionId,
        scrollInToView,
    ]);

    useEffect(() => {
        // 页面加载以后 加载历史消息 只有会话第一次打开以后加载历史数据
        if (sessionMessages.length > 0) {
            // 如果存在消息数据，不加载历史记录, 初始化end状态
            setEnd(0);
            scrollBottom(true);
            currPage.current = 0;
        } else {
            setLoadingHistory(true);
        }
        firstRef.current = true;
        bottomEnd.current = 0;
        const options =
            sMid === -1
                ? {
                      queryType: 2,
                      cancel: firstRef.current ? false : true,
                      count: 20,
                  }
                : { queryType: 1 };
        const _sMid = sMid > 0 ? sMid - 1 : sMid;
        loadHistory(_sMid, options).then(({ messages, end }: any) => {
            if (messages?.length > 0) {
                setHistoryMessage({ sessionId, historyMessage: messages });
                if (_sMid !== -1) {
                    const msg = messages.find((i: any) => {
                        if (i.plusList) {
                            return i.plusList.findIndex((ii: any) => ii.messageId === sMid) !== -1;
                        } else {
                            return Number(i.mid) === Number(sMid);
                        }
                    });
                    if (msg) {
                        const timer = setTimeout(() => {
                            maintainScroll(msg.id, true);
                            clearTimeout(timer);
                        }, 60);
                    }
                } else {
                    scrollInToView();
                }
                if (mountedRef.current) {
                    setEnd(end);
                }
                if (isAINoticeSession(selectedSession)) {
                    currPage.current += 1;
                }
                scrollInToView();
            } else {
                setHistoryMessage({ sessionId, historyMessage: [] });
            }
            if (sessionId.indexOf(CHAT_NOTICE.AI_PIN) > -1) {
                syncSelectedAITab(messages || []);
            }
            if (mountedRef.current) {
                setLoadingHistory(false);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, sMid, scrollInToView]);

    const onScrollEnd = (scrollTop = 0, cHight = 0, sHight = 0) => {
        const _isShowBackBottom = scrollTop + cHight >= sHight - 400;
        setShowBackBottom(!_isShowBackBottom);
        clearTimeout(isScroll);
        isScroll = setTimeout(() => {
            setShowBackBottom(false);
            clearTimeout(isScroll);
        }, 1000);
    };
    function onScroll() {
        if (!sessionMessages || sessionMessages?.length <= 0) {
            return false;
        }
        if (loadingHistory) {
            return;
        }
        const cu: any = scrollRef.current;
        if (!cu) {
            return;
        }
        const scrollTop = cu.scrollTop;
        const cHight = cu.clientHeight;
        const sHight = cu.scrollHeight;
        onScrollEnd(scrollTop, cHight, sHight);
        const options: Partial<LoadHistoryOptions> = {};
        const messages = sessionMessages.filter((i) => i.mid > 0);
        let startMid = -1;
        if (scrollTop < 10) {
            if (end > 0) {
                return;
            }
            startMid = isAINoticeSession(selectedSession)
                ? -1
                : messages[0]
                ? messages[0].mid - 1
                : -1;
            setLoadingHistory(true);
            const options = { offset: currPage.current };
            loadHistory(startMid, options).then(({ messages, end }: any) => {
                if (messages?.length > 0) {
                    setHistoryMessage({ sessionId, historyMessage: messages });
                    setEnd(end);
                    if (isAINoticeSession(selectedSession)) {
                        currPage.current += 1;
                    }
                }
                setLoadingHistory(false);
            });
        } else if (bottomEnd.current === 0 && scrollTop + cHight >= sHight - 10) {
            const lastMsg = (sessionMessages || []).slice(-1)[0] || {};
            const lastPre = (prevSessionMessages || []).slice(-1)[0] || {};
            if (firstRef.current) {
                firstRef.current = false;
                return;
            }
            if (lastPre.id !== lastMsg.id) {
                return;
            }
            if (selectedSession?.lastMsg?.id === lastMsg?.id) {
                return;
            }
            const startMid = isAINoticeSession(selectedSession)
                ? -1
                : lastMsg
                ? lastMsg.mid - 1
                : -1;
            setLoadingHistory(true);
            options.queryType = 1;
            options.offset = currPage.current;
            loadHistory(startMid, options).then(({ messages, end }: any) => {
                if (messages?.length > 0) {
                    setHistoryMessage({ sessionId, historyMessage: messages });
                    bottomEnd.current = end;
                    if (isAINoticeSession(selectedSession)) {
                        currPage.current -= 1;
                    }
                }
                setLoadingHistory(false);
            });
        }
    }
    // 优化计算
    const content = useMemo(() => {
        return getMessageNode(
            sessionMessages || [],
            getLock,
            unLock,
            getCurrentMessageId,
            setCurrentMessageId,
            selectedSession,
            currentEmployee,
            updateSessionMessagesTimestamp,
            sText,
            sMid
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sessionMessages,
        getLock,
        unLock,
        getCurrentMessageId,
        setCurrentMessageId,
        selectedSession,
        currentEmployee,
        updateSessionMessagesTimestamp,
    ]);

    const readAllMessage = (selectedSession: any, mainWinShow: boolean) => {
        if (!selectedSession.sessionId || !mainWinShow) {
            return;
        }
        const count = selectedSession.unreadCount;
        // 设用imService readAll接口
        const imService = ImService.getInstance();
        imService
            .readAllMessage(selectedSession.sessionId, MessageReadScene.ENTER, count !== 0)
            .catch((err) => console.warn('imService-readAllMessage-err', err));
    };

    const doReadAll = debounce(readAllMessage, 300);

    const onMainWinShow = useCallback(() => {
        doReadAll(selectedSession, mainWinShow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSession, mainWinShow]);

    const isMainShow = useMemo(() => {
        return preMainWinShow === false && mainWinShow;
    }, [preMainWinShow, mainWinShow]);

    const selectChanged = useMemo(() => {
        return (
            selectedSession?.sessionId && preSelectSession?.sessionId !== selectedSession?.sessionId
        );
    }, [selectedSession?.sessionId, preSelectSession?.sessionId]);

    const doOnMainWinShow = debounce(onMainWinShow, 100);

    useEffect(() => {
        if (isMainShow) {
            doOnMainWinShow();
        }
    }, [isMainShow, doOnMainWinShow]);

    const mouseDownFn = (e: any) => {
        setonMousedown(true);
        document.addEventListener('mousemove', move);
        function move() {
            setonMousedown(false);
        }

        document.addEventListener('mouseup', onMouseup);
        function onMouseup(evt: any) {
            document.onmousemove = null;
            document.onmouseup = null;
            document.removeEventListener('mousemove', move);
            document.removeEventListener('mouseup', onMouseup);
        }
    };

    useEffect(() => {
        let doc: any = wrapRef.current;
        if (selectChanged) {
            doOnMainWinShow();
        }
        if (selectChanged && doc?.className === 'message-box-container') {
            doc?.addEventListener('mousedown', mouseDownFn);
        }
        return () => {
            doc?.removeEventListener('mousedown', mouseDownFn);
        };
    }, [doOnMainWinShow, selectChanged, isMousedown]);

    const handleContainerClick = useCallback(() => {
        // 点击聊天框时关闭更多操作
        lockRef.current = false;
        bus.emit('chat:message-container-click');
        if (isMousedown === false) return;
        console.log('点击聊天框取消选中', isMousedown);
        window.getSelection()?.empty();
    }, [isMousedown]);

    // 屏蔽tab按键
    const onKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.keyCode === 9) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            return false;
        }
    }, []);
    useEffect(() => {
        // 按键处理
        window.addEventListener('keydown', onKeyDown);
        mountedRef.current = true;
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            mountedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGoEnd = useCallback(() => {
        setShowBackBottom(false);
        const messages = sessionMessages[sessionMessages.length - 1];
        if (messages.mid !== selectedSession.lastMid) {
            initSelectedSession({ selectedSession: selectedSession });
            setHistoryMessage({ sessionId, historyMessage: [] });
            loadHistory(-1, { offset: 0, queryType: 2 }).then(({ messages, end }: any) => {
                setHistoryMessage({ sessionId, historyMessage: messages });
                setEnd(1);
                scrollInToView(true);
            });
        } else {
            setEnd(1);
            scrollInToView(true);
        }
    }, [
        initSelectedSession,
        loadHistory,
        scrollInToView,
        selectedSession,
        sessionId,
        sessionMessages,
        setHistoryMessage,
    ]);

    return (
        <div className="message-box-container" onClick={handleContainerClick} ref={wrapRef}>
            <div
                className={classNames('message-box-container-scroll reply-popover')}
                id="message-box-container-scroll"
                ref={scrollRef}
                onScroll={throttle(onScroll, 150)}
            >
                <div>
                    {selectedSession.isSecret && (
                        <div className="secret-session-desc">
                            <div>
                                <IconFont type="iconic_app_im_burnafterreading_facet" />
                            </div>
                            <div>阅后即焚-沟通更安全</div>
                            <div>
                                <span>·消息支持阅后即焚</span>
                                <span>·服务器不留操作痕迹</span>
                                <span>·消息不支持转发</span>
                                <span>·会话限制截屏操作</span>
                            </div>
                        </div>
                    )}
                    {sessionMessages?.length > 0 ? (
                        <div className="today-messages-nodes" id="today-messages-nodes">
                            {end > 0 && <TimeLine timeStamp={sessionMessages[0].timestamp} />}
                            {content}
                        </div>
                    ) : isAINoticeSession(selectedSession) && !loadingHistory ? (
                        <div className="ai-empty-wrapper">
                            {/* <div className="empty-png">
                                <img src={aiEmpty} alt="" width="100%" />
                            </div>
                            <div className="arrows-png">
                                <img src={aiArrows} alt="" width="100%" />
                            </div> */}
                            <div className="no-data-png">
                                <img src={aiNoData} alt="" width="100%" />
                            </div>
                            <p className="empty-text">
                                暂无内容
                                {selectedAITab.pin !== CHAT_NOTICE.ALL_PIN && (
                                    <>
                                        <br />
                                        请切换至“全部”查看
                                    </>
                                )}
                            </p>
                        </div>
                    ) : null}
                    {loadingHistory && (
                        <div className="loading-wrap">
                            <Loading type="message" />
                        </div>
                    )}
                </div>
            </div>
            {isShowBackBottom && sessionMessages?.length > 0 && <GoEnd onGoEnd={handleGoEnd} />}
        </div>
    );
}

function mapStateToProps({ chat }: any) {
    const {
        selectedSession,
        sessionMessageMap,
        mainWinShow,
        selectedAITab,
        allSessionList,
        preSelectedSession,
        prevAISessionMessages,
        sMid,
        sText,
        updateSessionMessagesTimestamp,
    } = chat as ChatState;
    let sessionMessages: any[] = sessionMessageMap[selectedSession.sessionId] || [];
    if (isAINoticeSession(selectedSession)) {
        const selectedPin = selectedAITab.pin || CHAT_NOTICE.ALL_PIN;
        sessionMessages = filterNoticeMassagesByType(sessionMessages, selectedPin);
    }
    return {
        sessionMessages,
        selectedSession,
        mainWinShow,
        allSessionList,
        selectedAITab,
        sessionMessageMap,
        storePreSelectedSession: preSelectedSession,
        prevAISessionMessages,
        sMid,
        sText,
        updateSessionMessagesTimestamp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async setHistoryMessage(data: { sessionId: string; historyMessage: ChatMessage }) {
            await dispatch({ type: 'chat/setHistoryMessage', payload: data });
        },
        async updateSelectedAITab(data: { selectedAITab: AITabState }) {
            await dispatch({ type: 'chat/updateSelectedAITab', payload: data });
        },
        async updatePreSelectedSession(data: { preSelectedSession: Session }) {
            await dispatch({ type: 'chat/updatePreSelectedSession', payload: data });
        },
        async updatePreAISessionMsg(data: { prevAISessionMessages: ChatMessage[] }) {
            await dispatch({ type: 'chat/updatePreAISessionMsg', payload: data });
        },
        async initSelectedSession(data: { selectedSession: any }) {
            await dispatch({ type: 'chat/initSelectedSession', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoxContainer);
