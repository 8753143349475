import { promises } from 'dns';
import {
    pageTypes,
    createWPSWordInSpace,
    createPageInFile,
    createTableInFile,
    createPrivateWps,
    WPSType,
} from '../../api/createmenu';

export const PageType = {
    page: pageTypes[1], // 文档
    report: pageTypes[2], // 报告
    sheet: pageTypes[4], // 表格
    link: pageTypes[5], // link
    file: pageTypes[6], // 文件
    websheet: pageTypes[7], // websheet
    wpsWord: WPSType.word,
    wpsexcel: WPSType.excel,
};

const ApiByPageType = {
    [PageType.page]: createPageInFile,
    [PageType.sheet]: createTableInFile,
    [PageType.wpsWord]: createPrivateWps,
    [PageType.wpsexcel]: createPrivateWps,
};

/**
 * 根据类型创建文档
 */
export const createDocumentByPageType = (
    type: string,
    folderId: string,
    gid: string,
    folderName?: string
    // eslint-disable-next-line max-params
) => {
    let method = ApiByPageType[type];
    let params;
    switch (type) {
        case PageType.sheet:
        case PageType.page:
            params = { folderId, gid };
            break;
        case PageType.wpsWord:
        case PageType.wpsexcel:
            params = {
                fileType:
                    type === PageType.wpsWord ? 'application/msword' : 'application/vnd.ms-excel',
                folderId,
                fileName: type === PageType.wpsWord ? '无标题.doc' : '无标题.xls',
                gid,
            };
    }
    // @ts-ignore
    return method(params, folderName);
};
