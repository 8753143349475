import { PermissionEnum } from "../const";

// 文件
export enum folderType {
    MY = 'root', // '我的文件'
    SHARE = 'teamRoot', // 共享文件
}
// 文件
export enum folderTypeEnum {
    MY = 8, // '我的文件'
    SHARE = 12, // 共享文件
}
// 根文件权限
export enum joyspacePermissionType {
    ReadAll = 0, // 0 拥有者
    ReadOnly = 2, // 只读
    ReadWrite = 1, // 读写
    ReadDel = 99, // 删除权限
    ReadNothing = -1 // 文档被删除或者无权限
}
// 获取文件夹/文件可编辑权限
export const getJoySpaceEditPermission = function (current: any): boolean {
    if (!current && current !== 0) {
        return false;
    }
    return (
        current === joyspacePermissionType.ReadAll || current === joyspacePermissionType.ReadWrite
    );
};

export const getJoySpaceReadAllPermission = function (): joyspacePermissionType {
    return joyspacePermissionType.ReadAll;
};

export const getJoySpaceReadWritePermission = function (): joyspacePermissionType {
    return joyspacePermissionType.ReadWrite;
};

export const getJoySpaceReadOnlyPermission = function (): joyspacePermissionType {
    return joyspacePermissionType.ReadOnly;
};

export const getJoySpaceDelPermission = function (): joyspacePermissionType {
    return joyspacePermissionType.ReadDel;
};

export const getJoySpaceReadNothingPermission = function (): joyspacePermissionType {
    return joyspacePermissionType.ReadNothing;
};

export const getJoySpaceReadFolderPermission = function (permission: any): joyspacePermissionType {
    if (permission && permission.includes(PermissionEnum.FOLDER_PUBLIC_SET)) {
        return getJoySpaceReadAllPermission()
    } else if (permission && permission.includes(PermissionEnum.FOLDER_EDIT)) {
        return getJoySpaceReadWritePermission()
    } else {
        return getJoySpaceReadOnlyPermission()
    }
};

export const  getJoySpaceReadPagePermission = function(permission: any): joyspacePermissionType {
    if (permission && permission.includes(PermissionEnum.PAGE_EDIT)) {
        return getJoySpaceReadWritePermission()
    } else {
        return getJoySpaceReadOnlyPermission()
    }
};
