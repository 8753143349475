/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import ResendCode from '../ResendCode';
import { isValidVerifyCodes, LoginFormContext, LoginFormContextType } from '../utils';
import { Btn } from '../comps';
import { IconArrowLeft } from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { AuthDataRes, BizTypeEnum } from '@/types/LoginPage';
import { useTranslation } from 'react-i18next';
import './index.less';
const { REACT_APP_DEFAULT_COUNTRY_CODE: DEFAULT_COUNTRY_CODE = 86 } = process.env;
const countryCode = DEFAULT_COUNTRY_CODE;

export default function VerifyCodeForm({
    onSuccess,
    onNewUser,
    onNewTeam,
}: {
    onSuccess: (authData: AuthDataRes) => void;
    onNewUser: (authCode: string) => void;
    onNewTeam: (authData: AuthDataRes) => void;
}) {
    const { t } = useTranslation('common');
    const { onGlobalError: onLoginError, service } = useContext<GlobalContextConfig>(GlobalContext);
    const {
        mobile: receive,
        loginName,
        loginMode,
        loginType,
        teamId,
        onMobileChange,
        navigate,
        navigatePath,
        bizType,
    } = React.useContext<LoginFormContextType>(LoginFormContext);
    // const [verifyCodes, setVerifyCodes] = React.useState(new Array(6).fill(''));
    // const [verifyCodeIndex, setVerifyCodeIndex] = React.useState(0);
    const [verifyFailed, setVerifyFailed] = React.useState(false);
    const [verifyFailedTooMnyTimes, setVerifyFailedTooMnyTimes] = React.useState(false);

    const [verifyData, setVerifyData] = React.useState({
        codes: ['', '', '', '', '', ''],
        index: 0,
    });
    const [loading, setLoading] = React.useState(false);
    const verifyDataRef = React.useRef<any>();
    verifyDataRef.current = verifyData;

    const onKeyDown = (e: any) => {
        const { codes: verifyCodes, index: verifyCodeIndex } = verifyDataRef.current;
        setVerifyFailed(false);
        if (navigatePath !== 'step2') {
            return;
        }
        if (loading) {
            return;
        }
        if (verifyFailedTooMnyTimes) {
            return;
        }
        if (e.key === 'Backspace') {
            if (verifyCodeIndex === 0) {
                return;
            }
            verifyCodes[verifyCodeIndex - 1] = '';
            setVerifyData({
                codes: [...verifyCodes],
                index: verifyCodeIndex - 1,
            });
            // setVerifyCodes([...verifyCodes]);
            // setVerifyCodeIndex(verifyCodeIndex - 1);
            return;
        }
        // eslint-disable-next-line no-console
        let pressKey = e.key;
        if (isNaN(Number(pressKey))) {
            // eslint-disable-line
            pressKey = e.code.replace('Digit', '');
        }
        if (isNaN(Number(pressKey))) {
            // eslint-disable-line
            return;
        }
        if (verifyCodeIndex >= verifyCodes.length) {
            return;
        }
        verifyCodes[verifyCodeIndex] = pressKey;
        setVerifyData({
            codes: [...verifyCodes],
            index: verifyCodeIndex + 1,
        });
        if (verifyCodeIndex === 5) {
            checkVerCodesCb();
        }
        // setVerifyCodes([...verifyCodes]);
        // setVerifyCodeIndex(verifyCodeIndex + 1);
    };

    const checkVerCodesCb = React.useCallback(async () => {
        if (!verifyDataRef.current) {
            onLoginError(new Error('Can not get verify codes.'));
            return;
        }
        const { codes } = verifyDataRef.current;
        if (!isValidVerifyCodes(codes)) {
            return;
        }
        try {
            const verCode = verifyDataRef.current.codes.join('');
            setLoading(true);
            if (bizType === BizTypeEnum.SET_PASSWORD) {
                const resetParams = {
                    loginName: loginName || receive,
                    verCode: verCode,
                    loginNameType: loginType,
                };
                let content;
                if (loginMode === 'vip') {
                    content = await service.authService.exclusiveVerifyResetPwdVerCode({
                        ...resetParams,
                        teamId,
                    });
                } else {
                    content = await service.authService.verifyResetPwdVerCode(resetParams);
                }
                onSuccess(content);
            } else {
                if (loginMode === 'vip') {
                    const content = await service.authService.appExclusiveLogin({
                        loginName: loginName || receive,
                        loginTicket: verCode,
                        loginTeamId: teamId,
                        loginNameType: loginType,
                        loginType: 'VERIFY_CODE',
                    });
                    onSuccess(content);
                } else {
                    const content = await service.authService.appLoginByVerCode({
                        loginName: loginName || receive,
                        verCode,
                        receiveType: loginType,
                    });
                    if (content.loginStatus === 'NEW_USER') {
                        setLoading(false);
                        onNewUser(content.authCode);
                        return;
                    }
                    if (content.loginStatus === 'NEW_TEAM') {
                        setLoading(false);
                        onNewTeam(content as any);
                        return;
                    }
                    onSuccess(content);
                }
            }
            setLoading(false);
        } catch (error) {
            if (error.code === '10160023') {
                setVerifyFailedTooMnyTimes(true);
                setTimeout(() => {
                    setVerifyData({
                        codes: ['', '', '', '', '', ''], // defaultCodes,
                        index: 0,
                    });
                    setLoading(false);
                }, 100);
                return;
            }
            // if (error.code === '10160008') {
            setVerifyFailed(true);
            setTimeout(() => {
                setVerifyData({
                    codes: ['', '', '', '', '', ''], // defaultCodes,
                    index: 0,
                });
                setLoading(false);
            }, 100);
            return;
            // }
            // onLoginError(error);
        }
    }, [
        loginType,
        loginMode,
        loginName,
        teamId,
        onLoginError,
        onNewTeam,
        onNewUser,
        onSuccess,
        receive,
        service.authService,
        bizType,
    ]);

    // React.useEffect(() => {
    //     console.log('useEffect checkVerCodesCb', verifyData);
    //     checkVerCodesCb();
    // }, [checkVerCodesCb, verifyData]);

    React.useEffect(() => {
        if (navigatePath === 'step2') {
            document.addEventListener('keydown', onKeyDown);
        }
        return () => {
            if (navigatePath === 'step2') {
                document.removeEventListener('keydown', onKeyDown);
            }
        };
    }, [navigatePath, verifyFailedTooMnyTimes]); //eslint-disable-line

    return (
        <div className="mobileForm">
            <div
                style={{
                    margin: '10px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    className="hover-color"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setVerifyData({
                            codes: ['', '', '', '', '', ''],
                            index: 0,
                        });
                        onMobileChange('');
                        setTimeout(() => {
                            navigate('step1');
                        }, 0);
                    }}
                >
                    {/* <span
            style={{
              marginRight: 5
            }}
          >
            {IconArrowLeft}
          </span> */}
                    {IconArrowLeft}
                    <span
                        style={{
                            verticalAlign: 'middle',
                            marginLeft: 5,
                        }}
                    >
                        {t('login.back')}
                    </span>
                </div>
            </div>
            <h4 style={{ margin: 0 }}>{t('login.inputVerifyCode')}</h4>
            <div style={{ paddingBottom: 52 }} className="join-me-primary-color">
                {t('Verify Code has sent to')}
                &nbsp;
                <span>
                    {loginType === 'MOBILE' && `+${countryCode || DEFAULT_COUNTRY_CODE} `}
                    {receive}
                </span>
            </div>
            <div
                style={{
                    paddingBottom: verifyFailed ? 10 : 44,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {[...verifyData.codes.slice(0, 3), 'split', ...verifyData.codes.slice(3)].map(
                    (code, i) => {
                        if (code === 'split') {
                            return (
                                <div
                                    key="split"
                                    style={{
                                        height: 1,
                                        width: 6,
                                        margin: 5.5,
                                        backgroundColor: 'rgb(35, 41, 48)',
                                    }}
                                />
                            );
                        }
                        return (
                            <div key={i} className="verifyBox">
                                <div
                                    className={
                                        verifyFailedTooMnyTimes
                                            ? 'verifyBoxI'
                                            : [
                                                  'verifyBoxI',
                                                  verifyData.index >= i ? 'active' : '',
                                                  verifyFailed ? 'failed' : '',
                                                  i < 3 && verifyData.index === i ? 'focus' : '',
                                                  i > 3 && verifyData.index === i - 1
                                                      ? 'focus'
                                                      : '',
                                              ].join(' ')
                                    }
                                >
                                    <input defaultValue={code} disabled />
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
            {(verifyFailed || verifyFailedTooMnyTimes) && (
                <div
                    style={{
                        paddingBottom: 44,
                    }}
                    className="verifyFailedDesc"
                >
                    {verifyFailed ? t('login.verifyFailed') : t('login.verifyFailedTooMnyTimes')}
                </div>
            )}
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <Btn
                    text={t('nextStep')}
                    loading={loading}
                    disabled={!isValidVerifyCodes(verifyData.codes)}
                    onClick={() => {}}
                />
            </div>
            <ResendCode
                callback={() => {
                    setVerifyFailedTooMnyTimes(false);
                }}
            />
        </div>
    );
}
