import { connect } from 'dva';
import ChatState from '@/types/chat/State';
import { UserCardPayload } from '@/models';
import { Employee, Session, UID } from '@/types/chat';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApplyGroupState } from '@/types/chat/enum';
import ImService from '@/server/ImService';
import { employeeToUid, isEqualUid } from '@/components/chat/utils/message';
import { message as toast, Modal, Input, Button } from 'antd';
import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import Bus from '@/utils/bus';
import { UserCardType } from '@/types/UserCard';
import i18n from '@/i18n';
import { isShowOldNoticeCard } from '@/utils/chat/message';
import ComNoticeCard from '@/baseComponents/ComNoticeCard';
import { getHeadImgThumbUrl } from '@/utils/chat/index';
import { cloneDeep, omit, trimStart } from 'lodash';
import { applyAgain } from '@/api/chat';
function ApplyGroupCard({
    message,
    toggleUserCardModal,
    updateSessionMessageMid,
    selectedSession,
    currentEmployee,
}: {
    message: any;
    toggleUserCardModal: Function;
    updateSessionMessageMid: Function;
    selectedSession: Session;
    currentEmployee: Employee;
}) {
    const { t } = useTranslation('chat');
    const [invitee, setInvitee] = useState<UID>({ app: '', name: '', pin: '', teamId: '' });
    const [applyState, setApplyState] = useState(ApplyGroupState.INIT);
    const [applyAgainVisible, setApplyAgainVisible] = useState(false);
    const [applyReason, setApplyReason] = useState('');
    const [applyLoading, setApplyLoading] = useState(false);
    const inputRef = useRef(null);

    const findGroupRoster = useCallback(
        async (invitee: UID): Promise<any> => {
            const imService = ImService.getInstance();
            const rosters = await imService.getGroupRosters(message.infox.groupId, true);
            return rosters.find((item) => {
                if (!item.user) return false;
                return isEqualUid(item.user, invitee);
            });
        },
        [message.infox.groupId]
    );

    // 同意操作 直接拉用户进群
    const agreeAction = useCallback(async () => {
        if (message.infox?.groupId && invitee) {
            // 查询群成员中有没有这个成员
            const imService = ImService.getInstance();
            const roster = await findGroupRoster(invitee);
            if (roster) {
                toast.warn(
                    t('apply.apply-user-done', {
                        first: roster.info.name,
                        second: message.infox.groupName,
                    })
                );
                setApplyState(ApplyGroupState.AGREED);
                await imService.updateAddGroupApplyState(
                    selectedSession.sessionId,
                    message,
                    ApplyGroupState.AGREED
                );
                return false;
            }
            let result = await imService.applyUser(message, [invitee], '');
            if (result.success) {
                let result = await imService.updateAddGroupApplyState(
                    selectedSession.sessionId,
                    message,
                    ApplyGroupState.AGREED
                );
                if (result) {
                    setApplyState(ApplyGroupState.AGREED);
                    toast.success(t('added-successfully'));
                    return;
                }
            } else {
                const msg = result.data?.msg || '';
                toast.error(`${t('failed')}:${msg}`);
                if (result.data?.reviewStatus === 2) {
                    imService.updateAddGroupApplyState(
                        selectedSession.sessionId,
                        message,
                        ApplyGroupState.REJECTED
                    );
                    setApplyState(ApplyGroupState.REJECTED);
                }
            }
        }
    }, [invitee, t, selectedSession.sessionId, message, findGroupRoster]);

    const refuseAction = useCallback(async () => {
        const roster = await findGroupRoster(invitee);
        const imService = ImService.getInstance();
        if (roster) {
            toast.warn(
                t('apply.apply-user-done', {
                    first: roster.info.name,
                    second: message.infox.groupName,
                })
            );
            setApplyState(ApplyGroupState.AGREED);
            await imService.updateAddGroupApplyState(
                selectedSession.sessionId,
                message,
                ApplyGroupState.AGREED
            );
            return false;
        }
        const result = await imService.updateAddGroupApplyState(
            selectedSession.sessionId,
            message,
            ApplyGroupState.REJECTED
        );
        if (result) {
            const { noticeType, groupId } = message.infox;
            if (noticeType === 'application' && groupId) {
                const res = await imService.refuseUser(currentEmployee, groupId, message);
                if (res.success) {
                    toast.success(t('success'));
                } else {
                    const msg = res.data?.msg || '';
                    toast.error(`${t('failed')}:${msg}`);
                }
            }
            message.infox = {
                ...message.infox,
                applyState: ApplyGroupState.REJECTED,
            };
            // console.log('拒绝后', message);
            updateSessionMessageMid({
                sessionId: message.sessionId,
                sendResult: {
                    body: message,
                },
            });
            setApplyState(ApplyGroupState.REJECTED);
        } else {
            toast.error(t('failed'));
        }
    }, [
        selectedSession.sessionId,
        message,
        t,
        invitee,
        findGroupRoster,
        currentEmployee,
        updateSessionMessageMid,
    ]);

    const applyAgainAction = useCallback(async () => {
        // console.log('再次申请');
        setApplyReason('');
        setApplyAgainVisible(true);
    }, []);

    const applyAgainSubmit = async () => {
        console.log('message', message);
        console.log('currentEmployee', currentEmployee);
        const fields = message?.extend?.fields || [];
        const applyUserItem = fields.find((item: any) => item.key === 'applyUserName') || {};
        const infox = message.infox;
        const withParamsMsgReq = {
            infox: {
                from: JSON.stringify(employeeToUid(currentEmployee)),
                fromUsername: currentEmployee.name || applyUserItem.value || '',
                noticeType: 'application',
                reason: applyReason,
                groupId: infox.groupId,
                groupName: infox.groupName,
                status: 0,
                qrCode: infox.qrCode,
                qrUid: infox.qrUid,
                joinMode: infox.joinMode,
                deepLink: infox.deepLink || 'jdgwork://jm/page_app_tab',
            },
        };
        console.log('提交111', withParamsMsgReq);
        setApplyLoading(true);
        const [data, error] = await applyAgain({ groupId: infox.groupId, withParamsMsgReq });
        // console.log(data);
        if (data) {
            const { status, msg } = data;
            if (status === 0) {
                toast.success(msg);
            } else {
                toast.warn(msg);
            }
            setApplyAgainVisible(false);
        } else {
            toast.error(error);
        }
        setApplyLoading(false);
    };

    const handleChangeReason = (e: any) => {
        // console.log('理由：', e.target.value);
        const v = trimStart(e.target.value);
        setApplyReason(v);
    };

    // 底部button
    const modalFooter = () => {
        return (
            <div className="applyAgainBtns">
                <Button onClick={() => setApplyAgainVisible(false)}>取消</Button>
                <Button
                    type="primary"
                    disabled={!applyReason.length}
                    loading={applyLoading}
                    onClick={applyAgainSubmit}
                >
                    确定
                </Button>
            </div>
        );
    };

    useEffect(() => {
        const { applyState } = message.infox;
        if (applyState) {
            setApplyState(applyState);
        }
        const { from, noticeType } = message.infox;

        if (from) {
            const fromJson = attempt(JSON.parse, from);
            if (isError(fromJson)) {
                return;
            }
            if (noticeType !== 'reply') {
                setInvitee(fromJson);
            }
        }
    }, [message]);

    const stateNode = useMemo(() => {
        if (message.infox.noticeType === 'reply') {
            // 可以再次申请
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else if (applyState === ApplyGroupState.AGREED) {
            return <div className="agreed">{t('apply.agreed')}</div>;
        } else if (applyState === ApplyGroupState.REJECTED) {
            return <div className="agreed">{t('apply.rejected')}</div>;
        } else {
            return (
                <div className="apply-setting-action line">
                    <button className="refuse" onClick={refuseAction}>
                        {t('apply.refuse')}
                    </button>
                    <button className="agree" onClick={agreeAction}>
                        {t('apply.agree')}
                    </button>
                </div>
            );
        }
    }, [applyState, message, t, refuseAction, agreeAction]);

    const isRenderStateNodeNew = useMemo(() => {
        const { extend } = message;
        if (!extend?.btns?.length) {
            return false;
        }
        // if (extend.btns.length === 1 && extend.btns[0].action === ApplyGroupState.REAPPLY) {
        //     return false;
        // }
        return true;
    }, [message]);

    const stateNodeNew = useMemo(() => {
        const { extend } = message;
        if (!extend || !extend.btns) {
            return null;
        }
        return (
            <div className="apply-setting-action">
                {extend.btns.map((btn: any) => {
                    return btn.status === '0' ? (
                        <button key={btn.action} className="done">
                            {btn.name}
                        </button>
                    ) : (
                        <button
                            key={btn.action}
                            className={btn.action}
                            onClick={
                                btn.action === ApplyGroupState.REAPPLY
                                    ? applyAgainAction
                                    : btn.action === ApplyGroupState.REJECT
                                    ? refuseAction
                                    : agreeAction
                            }
                        >
                            {btn.name}
                        </button>
                    );
                })}
            </div>
        );
    }, [agreeAction, message, refuseAction, applyAgainAction]);

    const openUserCard = useCallback(() => {
        const from = attempt(JSON.parse, message.infox.from);
        if (isError(from)) {
            return;
        }
        Bus.emit('app:toggle-user-card-modal:show', {
            visible: true,
            type: UserCardType.User,
            userId: from?.pin,
            teamId: from?.teamId,
            appId: from?.app,
        });
        // if (isFocusEnv()) {
        //     FocusSDK.openUserCard({
        //         type: UserCardType.User,
        //         userId: from.pin,
        //         teamId: from.teamId,
        //         appId: from.app,
        //     });
        // } else {
        //     toggleUserCardModal({
        //         visible: true,
        //         userId: from.pin,
        //         teamId: from.teamId,
        //         appId: from.app,
        //     });
        // }
    }, [message.infox.from]);

    const applyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.apply-join')}</span>
            <span className="apply-details-content-link group">{message.infox.groupName}</span>
            {message.infox.reason && (
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;{t('apply.reason')}: {message.infox.reason}
                </span>
            )}
        </div>
    );

    const replyContent = (
        <div className="apply-details-content">
            <span className="apply-details-content-link invitee" onClick={openUserCard}>
                {message.infox.fromUsername}
            </span>
            <span>{i18n.t('chat:apply.refuse_you_add')}</span>
            <span>{message.infox.groupName}</span>
        </div>
    );

    const contentNode = useMemo(() => {
        if (message.infox.noticeType === 'reply') {
            return replyContent;
        } else {
            return applyContent;
        }
    }, [applyContent, message.infox.noticeType, replyContent]);
    return isShowOldNoticeCard(message) ? (
        <div className="apply-group-card">
            <div className="apply-details">
                <div className="apply-details-title">{message.title}</div>
                {contentNode}
            </div>
            {stateNode}
        </div>
    ) : (
        <>
            <ComNoticeCard
                avatarSrc={getHeadImgThumbUrl(message.sender.avatar, 150, 150)}
                type={message?.extend?.applicationName}
                contentList={message?.extend?.fields}
                title={message.title}
                loadButtonsFn={
                    isRenderStateNodeNew
                        ? () => {
                              return <div className="apply-group-card new">{stateNodeNew}</div>;
                          }
                        : null
                }
            />
            <Modal
                title="申请加组"
                width={520}
                wrapClassName="applyAgainWrapper"
                destroyOnClose={true}
                centered={true}
                visible={applyAgainVisible}
                onCancel={() => setApplyAgainVisible(false)}
                onOk={() => {
                    applyAgainSubmit();
                }}
                footer={modalFooter()}
            >
                <div style={{ position: 'relative' }}>
                    <Input.TextArea
                        ref={inputRef}
                        maxLength={90}
                        value={applyReason}
                        onChange={handleChangeReason}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        placeholder="请输入加入组的验证内容"
                        style={{ resize: 'none' }}
                    />
                    <span className="text-num">
                        <span>{applyReason.length || 0}</span>/{90}
                    </span>
                </div>
            </Modal>
        </>
    );
}

export default connect(
    function ({ chat }: { chat: ChatState }) {
        const { selectedSession, currentEmployee } = chat;
        return { selectedSession, currentEmployee };
    },
    function (dispatch: any) {
        return {
            toggleUserCardModal(payload: UserCardPayload) {
                dispatch({ type: 'app/toggleUserCardModal', payload });
            },
            updateSessionMessageMid(data: { sendResult: any; sessionId: String }) {
                dispatch({ type: 'chat/updateSessionMessageMid', payload: data });
            },
        };
    }
)(ApplyGroupCard);
