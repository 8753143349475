import React, { useEffect } from 'react';
import { FocusSDK, getClientType, isFocusEnv } from '@/utils';
import { handleMeetingSendMessage } from './utils';
import { message as toast } from 'antd';
import logger from '@/utils/logger';
import { AVSDK } from './common';
import MeetingList from './MeetingList';
import { getConferenceByCode, getConferenceDeeplink, getJRTCToken } from '@/api/meeting';
import { MeetingPlatform } from '@/types/common';
import bus from '@/utils/bus';
import GlobalContext from '@/context/GlobalContext';

// 注册监听事件，只在打开应用注册一次
export default function InitControl() {
    const { currentEmployee, authInfo = {} as any } = React.useContext(GlobalContext);

    useEffect(() => {
        const hangleSendMessage = (data: any) => {
            const {
                avsdk,
                sessionState,
                meetingTopic,
                meetingNumber,
                meetingPassword,
                toUsers,
            } = data;

            handleMeetingSendMessage({
                avsdk,
                meetingInfo: {
                    meetingNumber,
                    meetingTopic,
                    password: meetingPassword,
                },
                sessionState,
                toUsers,
            });
        };

        const handleJoinSuccess = async (data: any, retry = false) => {
            logger.debug('会议信息: ', data);
            if (!data.roomId || !data.room) {
                return;
            }
            const [res, err] = await getConferenceDeeplink({
                meetingId: data.roomId,
                meetingCode: data.room,
                platform: MeetingPlatform.JBRTC,
            });
            if (err || !res) {
                logger.error(err);
                FocusSDK.printLog(`get share link failed: ${err}`);
                if (retry) {
                    // 重试一次获取链接
                    handleJoinSuccess(data, true);
                }
                return;
            }
            if (isFocusEnv()) {
                FocusSDK.sendIpcMessage('jdmeet.setShareLink', res.shortUrl);
            } else {
                bus.emit('jdmeeting:setShareLink', res.shortUrl);
            }
        };

        const handleJoinMeetingCb = async ({
            status,
            data,
            error,
        }: {
            status: number;
            data: any;
            error?: any;
        }) => {
            if (!status) {
                toast.error(error || '加入会议失败');
            } else {
                handleJoinSuccess(data);
            }
        };

        const handleReceiveMessage = (message: any) => {
            logger.debug('handleReceiveMessage', message);
            const avsdk = message.ext.avsdk;
            if (avsdk && avsdk === AVSDK.JBRTC) {
                // sdk二次呼叫
                if (isFocusEnv()) {
                    FocusSDK.sendIpcMessage('jdmeet.setAttendeeStatus', {
                        from: message.ext.fromUser,
                        status: message.ext.sessionState,
                    });
                } else {
                    bus.emit('jdmeeting:setAttendeeStatus', {
                        from: message.ext.fromUser,
                        status: message.ext.sessionState,
                    });
                }
            }
        };

        const handleInitCb = ({ status, data, error }: any) => {
            if (status === 0) {
                if (error === 'DENIED') {
                    toast.error('请授予麦克风和摄像头权限');
                } else {
                    toast.error(error || '初始化失败');
                }
            }
        };

        const handleJoinMeeting = (opts: {
            avsdk: AVSDK;
            meetingInfo: {
                meetingNumber: string;
                meetingPassword: string;
                isvideooff: boolean;
                isaudiooff: boolean;
            };
        }) => {
            const meetingInfo = opts.meetingInfo;
            switch (opts.avsdk) {
                case AVSDK.JBRTC:
                    getJRTCToken({
                        nickname: currentEmployee.name || '',
                        unit: currentEmployee.deptName || '',
                        avatar: authInfo.headImg || '',
                        deviceType: getClientType(),
                    }).then(([jrcToken, err]) => {
                        if (err) {
                            return;
                        }
                        const {
                            ddAppId,
                            userId,
                            selectedTeamId,
                            realName,
                            headImg,
                            teamUserInfo,
                            nickName,
                        } = authInfo;

                        const userInfo = {
                            token: jrcToken.token,
                            deviceType: jrcToken.deviceType,
                            jrtcUserId: jrcToken.jrtcUserId,
                            appId: jrcToken.appId,
                            app: ddAppId,
                            pin: userId,
                            teamId: selectedTeamId,
                            name: realName || nickName,
                            avatar: headImg,
                            deptName: teamUserInfo.displayDeptName,
                        };
                        FocusSDK.sendIpcMessage('jdmeet.joinMeeting', {
                            meetingInfo: {
                                meetingNum: meetingInfo.meetingNumber,
                                password: meetingInfo.meetingPassword || '',
                                isvideooff: meetingInfo.isvideooff,
                                isaudiooff: meetingInfo.isaudiooff,
                            },
                            userInfo,
                        });
                    });

                    break;
                case AVSDK.XYLINK:
                    getConferenceByCode({
                        meetingId: meetingInfo.meetingNumber,
                        platform: MeetingPlatform.XYLINK,
                    }).then(([info, err]: any) => {
                        if (info && !err) {
                            const {
                                ddAppId,
                                userId,
                                selectedTeamId,
                                realName,
                                headImg,
                                teamUserInfo,
                                nickName,
                            } = authInfo;
                            const userInfo = {
                                app: ddAppId,
                                pin: userId,
                                teamId: selectedTeamId,
                                name: realName || nickName,
                                avatar: headImg,
                                deptName: teamUserInfo.displayDeptName,
                            };
                            FocusSDK.sendIpcMessage('xylink.joinMeeting', {
                                meetingInfo: {
                                    meetingNumber: meetingInfo.meetingNumber,
                                    controlPassword: info.controlPassword,
                                    topic: info.topic || '信创会议',
                                    password: meetingInfo.meetingPassword || '',
                                    isvideooff: meetingInfo.isvideooff,
                                    isaudiooff: meetingInfo.isaudiooff,
                                },
                                userInfo,
                            });
                        }
                    });
                    break;

                default:
                    break;
            }
        };

        FocusSDK.on('meeting:sendMessage', hangleSendMessage);
        FocusSDK.on('meeting:joinMeeting', handleJoinMeeting);
        FocusSDK.on('jdmeet:joinMeetingCb', handleJoinMeetingCb);
        bus.on('jdmeeting:joinMeetingCb', handleJoinMeetingCb);
        FocusSDK.on('jdmeet:initCb', handleInitCb);
        bus.on('meeting:receive-chat-message', handleReceiveMessage);

        return () => {
            FocusSDK.off('meeting:sendMessage', hangleSendMessage);
            FocusSDK.off('jdmeet:joinMeetingCb', handleJoinMeetingCb);
            FocusSDK.off('meeting:joinMeeting', handleJoinMeeting);
            bus.off('jdmeeting:joinMeetingCb', handleJoinMeetingCb);
            FocusSDK.off('jdmeet:initCb', handleInitCb);

            bus.off('meeting:receive-chat-message', handleReceiveMessage);
        };
    }, [authInfo, currentEmployee]);
    return <MeetingList />;
}
