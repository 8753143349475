import { labelInfoList } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { joyspacePermissionType } from '../../config/permissions.config';
export enum TabKeyType {
    All = 'All',
    RecentlyContact = 'recentlyContact',
    Contact = 'contact',
    Department = 'department',
    Groups = 'groups',
}

export const tab = [
    // { title: '全部', key: TabKeyType.All },
    { title: '最近分享', key: TabKeyType.RecentlyContact },
    { title: '组织架构', key: TabKeyType.Department },
    { title: '我的组', key: TabKeyType.Groups },
    { title: '联系人', key: TabKeyType.Contact },
];

export interface tabType {
    title: string;
    key: string;
}

export interface IProps {
    id: string;
    checked: boolean;
    avatar: string;
    name: string;
    desc: string;
    type: TabKeyType;
    typeName?: TabKeyType;
    labelInfoList?: any;
    callback?: (item: IProps) => void;
}
// 选中数组
export interface SelectType {
    id: string;
    avatar: string;
    name: string;
    desc: string;
    type: string;
    teamId?: string;
    checked?: boolean;
    permissionType: joyspacePermissionType;
}

export const getPermissionText = (permissionType: any) => {
    switch (permissionType) {
        case joyspacePermissionType.ReadAll: {
            return '所有者';
        }
        case joyspacePermissionType.ReadOnly: {
            return '阅读';
        }
        case joyspacePermissionType.ReadWrite: {
            return '编辑';
        }
        default:
            return '阅读';
    }
};
