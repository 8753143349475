import React from 'react';
import { Modal, Input, Button } from 'antd';
import { IconSearch, IconClose } from '@/components/icon';

export default function Header({
    onClose,
    onSearchValueChange,
    searchValue,
}: {
    onClose: () => void;
    onSearchValueChange: (value: string) => void;
    searchValue: string;
}) {
    return (
        <div
            className="focus-search-global-header"
            style={{
                // ...styles.layout('centerBetween', 'h'),
                height: 45,
                // borderBottom: `1px solid ${styles.color.line.light}`,
            }}
        >
            <div
                className="header-content"
                style={{
                    // ...styles.layout('centerBetween', 'h'),
                    flex: 1,
                    paddingLeft: 20,
                    paddingRight: 10,
                }}
            >
                <span
                    className="header-content-icon"
                    style={{
                        fontSize: 14,
                        // ...styles.layout('centerLeft', 'h'),
                        // color: styles.color.gray,
                        width: 20,
                    }}
                >
                    {IconSearch}
                </span>
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <Input
                        style={{
                            border: 0,
                            outline: 0,
                            boxShadow: 'none',
                        }}
                        autoFocus
                        allowClear
                        value={searchValue}
                        onChange={(e: any) => {
                            onSearchValueChange(e.target.value);
                        }}
                    />
                </div>
                {/* <span
                    style={{
                        width: 30,
                        margin: '0 10px',
                    }}
                >
                    <Button
                        className="header-button-clear"
                        size="small"
                        type="text"
                        // style={{
                        //     color: styles.color.gray,
                        // }}
                    >
                        {t('search.clear')}
                    </Button>
                </span> */}
            </div>
            <div
                className="header-tools"
                style={{
                    position: 'relative',
                    width: 50,
                    // ...styles.layout('center', 'h'),
                }}
            >
                <span
                    className="header-tools-split-line"
                    style={{
                        position: 'absolute',
                        width: 1,
                        height: 14,
                        left: 0,
                        top: 5,
                        // backgroundColor: styles.color.splitLine,
                    }}
                />
                <Button
                    className="header-tools-button-close"
                    style={{
                        fontSize: 12,
                        // color: styles.color.gray
                    }}
                    size="small"
                    type="text"
                    onClick={onClose}
                >
                    {IconClose}
                </Button>
            </div>
        </div>
    );
}
