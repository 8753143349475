import {
    AppInfo,
    ContactInfo,
    DepartmentList,
    GroupInfo,
    JoySpaceInfo,
    TaskInfo,
} from '@/services/SearchService';
import { ListItemData } from '@jd/focus-desktop-comps/lib/es/ListItem';
import { getHighlightComp } from '@/utils/search';
import React from 'react';
import { getAvatarURLFn, SearchGlobalFn } from './Tabs';
import Bus from '@/utils/bus';
import { UserCardAction, UserCardType } from '@/types/UserCard';
import styles from '@/components/styles';
import userCardIcon from '@/assets/img/user-card-icon.png';
import { IconDefualtOrg } from '@/components/icon';
import { ScheduleInfo, SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import ImService from '@/server/ImService';
import { Session } from '@/types/chat';
import { isFunction, uniqBy } from 'lodash';
import PinyinMatch from 'pinyin-match';
import { appItemMenus } from '@/components/ThirdApp/components/ThirdMenu';
import { info } from 'console';
import { getDeptPositionDesc } from '@/models/user';

const { REACT_APP_JOYSPACE_API_HOST } = process.env;

export function filterAppInfo(apps: AppInfo[]): ListItemData[] {
    return (apps || []).map((item) => {
        const { appId, appName, avatar, highlight, description = '', brief = '' } = item;
        const desc = brief || appName || '';
        return {
            id: appId,
            name: getHighlightComp(highlight, 'appName', appName),
            desc,
            avatar,
            origin: item,
            searchKey: SearchType.App,
        };
    });
}

function getJoySpaceDocIconByPageType(title: string, pageType: string, type: string) {
    return `${REACT_APP_JOYSPACE_API_HOST}/v1/common/icons?title=${title}&pageType=${pageType}&type=${type}&client=web`;
}

export function onOpenUserCard(data: any) {
    const { userId, appId, teamId } = data;
    Bus.emit('app:toggle-user-card-modal:show', {
        visible: true,
        type: UserCardType.User,
        userId,
        teamId,
        appId,
        applyInfos: [],
        actions: [UserCardAction.Chat],
    });
}

export function filterJoySpaceInfo(pages: JoySpaceInfo[]): ListItemData[] {
    return (pages || []).map((item) => {
        const { id, title, pageType, highlight, type } = item;
        return {
            id,
            name: getHighlightComp(highlight, 'title', title),
            avatar: item.icon_url || getJoySpaceDocIconByPageType(title, pageType, type),
            origin: item,
            searchKey: SearchType.JoySpace,
        };
    });
}
export function filterScheduleInfo(pages: ScheduleInfo[]): ListItemData[] {
    return (pages || []).map((item) => {
        const { title, highlight } = item;
        return {
            id: item.scheduleId,
            name: getHighlightComp(highlight, 'title', title),
            avatar: getJoySpaceDocIconByPageType(title, '1', '1'),
            origin: item,
            searchKey: SearchType.Schedule,
        };
    });
}

export function searchClientContacts(allSessionList: Session[], keyWord: string): ListItemData[] {
    const result: ListItemData[] = [];
    const list = allSessionList.filter(
        (ele: Session) => ele.isSingle && !ele.isNotice && ele.info?.name
    );
    list.forEach((item: any) => {
        const name = item?.info?.name || item.name;
        const aa: any = PinyinMatch.match(name, keyWord);
        if (aa?.length) {
            const sliceName = (item?.info?.name || item.name).slice(aa?.[0], aa?.[1] + 1);
            const descValue = getDeptPositionDesc(
                item?.info?.deptName || item?.deptName,
                item?.info?.titleName || item?.titleName
            );
            const highlight = [
                {
                    key: sliceName,
                    name: 'name',
                    value: name,
                },
                { key: keyWord, name: 'desc', value: descValue },
            ];
            const desc = getHighlightComp(highlight, 'desc', descValue);
            result.push({
                id: item?.id,
                avatar: item?.info?.avatar || item.avatar,
                displayName: item?.info?.name || item.name,
                desc,
                highlight,
                labelInfoList: item.labelInfoList || item?.info?.labelInfoList || [],
                userId: item?.info?.userId || item.userId,
                teamId: item?.info?.teamId || item.teamId,
                app: item?.info?.app || item.app,
                name: getHighlightComp(highlight, 'name', name),
                searchKey: SearchType.Contacts,
                origin: {
                    ...item,
                    userId: item?.info?.userId || item.userId,
                    teamId: item?.info?.teamId || item.teamId,
                    app: item?.info?.app || item.app,
                },
            });
        }
    });
    return result;
}

export function filterContactInfo(
    contacts: ContactInfo[],
    allSessionList: Session[],
    keyWord: string
): ListItemData[] {
    const contactsList = (contacts || []).map((item) => {
        const { userId, realName, avatar, highlight, labelInfoList } = item;
        const descValue = getDeptPositionDesc(item.deptName, item?.titleName || '');
        const descHighlight = [{ key: keyWord, name: 'desc', value: descValue }];
        const desc = getHighlightComp(descHighlight, 'desc', descValue);
        return {
            id: userId,
            name: getHighlightComp(highlight, 'realName', realName),
            avatar,
            displayName: realName,
            origin: item,
            desc,
            realDesc: descValue,
            // desc: item.deptName + '-' + item?.titleName,
            searchKey: SearchType.Contacts,
            labelInfoList: labelInfoList || [],
            userId: userId,
        };
    });
    if (!contactsList || !contactsList.length || !allSessionList || !allSessionList.length) {
        return contactsList || [];
    }
    return contactsListFilterSessionList(contactsList, allSessionList, keyWord);
}

// 搜索结果根据会话排序
export function contactsListFilterSessionList(
    contacts: ListItemData[],
    List: any[],
    keyWord: string
) {
    const allSessionList = List.filter(
        (ele: Session) => ele.isSingle && !ele.isNotice && ele.info?.name
    );
    if (!allSessionList || !allSessionList?.length) {
        return contacts || [];
    }

    let result: ListItemData[] = searchClientContacts(allSessionList, keyWord);
    // console.log('srearchClient===>', result, uniqBy([...result, ...contacts], 'userId'));
    return uniqBy([...result, ...contacts], 'userId') || [];
}

export function filterDeptsInfo(Depts: DepartmentList[]): ListItemData[] {
    return (Depts || []).map((item) => {
        const { deptId, highlight, deptName, realFullName, fullPath, fullName } = item;
        const filterHighlight: any =
            highlight.length > 0
                ? highlight.map((item) => {
                      if (item.name === 'fullName') {
                          return {
                              ...item,
                              name: 'realFullName',
                              key: item.key?.split(',')[0] || item.key,
                          };
                      }
                      return item;
                  })
                : realFullName;

        const desc =
            highlight.length === 0 || highlight.findIndex((a) => a.name === 'deptName') >= 0
                ? realFullName
                : getHighlightComp(filterHighlight, 'realFullName', item.realFullName);

        return {
            id: deptId,
            name: getHighlightComp(filterHighlight, 'deptName', deptName),
            avatar: IconDefualtOrg,
            searchKey: SearchType.Department,
            title: deptName,
            key: deptId,
            fullPath: fullPath,
            fullName: fullName,
            desc,
        };
    });
}

export function filterGroupInfo(groups: GroupInfo[]): ListItemData[] {
    return (groups || []).map((item) => {
        const { groupId, groupName, avatar, highlight } = item;
        return {
            id: groupId,
            name: getHighlightComp(highlight, 'groupName', groupName),
            displayName: groupName,
            avatar,
            origin: item,
            searchKey: SearchType.Group,
            desc: (item.groupUserCount || 0) + '人' || '',
        };
    });
}

export function filterTaskInfo(works: { [key: string]: any }[]) {
    if (!works) return {};
    const res: any = [];
    works.forEach((item, idx) => {
        res[idx] = {
            displayName: item.displayName,
            data: item.data?.map((item: TaskInfo) => {
                const { taskId, title, priorityType, highlight } = item;
                return {
                    id: taskId,
                    name: getHighlightComp(highlight, 'title', title),
                    displayName: title,
                    avatar: priorityType,
                    origin: item,
                    searchKey: SearchType.Task,
                };
            }),
        };
    });
    return res;
}

export const SearchGlobalContext = React.createContext<{
    t: any;
    onGetAvatarURL?: getAvatarURLFn;
    onSearchGlobal: SearchGlobalFn;
    keyword: string;
    activeKey: SearchType;
    setTabIndex: (activeKey: SearchType) => void;
    authData: any;
    isChange?: boolean;
}>({
    t: () => {},
    onSearchGlobal: async () => ({}),
    keyword: '',
    setTabIndex: () => {},
    activeKey: SearchType.All,
    authData: null,
});
