import React, { useRef, useCallback, useEffect, useReducer } from 'react';

import { List, Avatar, Empty, message } from 'antd';

import debounce from 'lodash/debounce';
import JEditor from '../..';
import Quill from 'quill';
import CursorPopover from '../CursorPopover';
import './index.less';
import { Employee, SessionProps } from '@/types/chat';
import { SearchEmptyImg } from '@/assets/img';
import { getUserDefaultAvatar } from '@/utils/oss';
import log from '@/utils/logger';
import { UserTag } from '@/components/Tags';
import ChatState from '@/types/chat/State';
import { connect } from 'dva';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { convertEmployeeToString, employeeToUid } from '@/components/chat/utils/message';
import InfiniteScroll from 'react-infinite-scroller';
import { searchGlobal } from '@/api/search';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { SearchResultModel } from '@/types/common';
import { changeEgovUrl } from '@/utils/tools';

const PAGE_SIZE = 10;
export interface MentionUserPluginProps {
    editor: JEditor | any;
    members: SearchResultModel[];
    handleSearch: (input: string) => SearchResultModel[];
    t: any;
}

export interface MentionUserPluginState {
    //
    users: SearchResultModel[];
    selected: string;
    text: string;
    visible: boolean;
    loading: boolean;
    hasMore: boolean;
}

class MentionUserPlugin extends React.Component<
    MentionUserPluginProps & ChatState,
    MentionUserPluginState
> {
    ref_div: React.RefObject<HTMLDivElement> = React.createRef();
    curRef: CursorPopover | null = null;
    constructor(props: any) {
        super(props);
        this.onTextInput = debounce(this.onTextInput, 300);
        this.state = {
            users: [],
            selected: '',
            text: '',
            visible: false,
            loading: false,
            hasMore: true,
        };
    }

    handleSearch = async ({ value, scrollTop = false, offset = 0 }: any) => {
        if (!value) {
            return this.setState({
                users: [],
                selected: '',
                loading: false,
                hasMore: false,
            });
        }
        const { loading, hasMore, users } = this.state;

        if (loading) {
            return;
        }
        this.setState({
            loading: true,
        });
        const pageNo = Math.floor(offset / 10) + 1;
        try {
            const result = await searchGlobal({
                keyword: value,
                searchType: SearchType.Contacts,
                pageNo,
                pageSize: PAGE_SIZE,
            });
            if (!result) {
                this.setState({
                    loading: false,
                });
                return;
            }
            const { contacts } = result;
            if (offset === 0) {
                if (scrollTop && this.ref_div.current) {
                    this.ref_div.current.scrollTop = 0;
                }
                const selected = convertEmployeeToString(contacts[0] as Employee) || '';
                const hasMore = contacts.length >= PAGE_SIZE;

                this.setState({
                    users: contacts,
                    selected,
                    loading: false,
                    hasMore,
                });
            } else {
                const hasMore = contacts.length >= PAGE_SIZE;

                this.setState({
                    users: [...users, ...contacts],
                    loading: false,
                    hasMore,
                });
            }
        } catch (error) {
            message.error(error.message);
            this.setState({
                loading: false,
            });
        }
    };

    handleInfiniteOnLoad = async () => {
        const { users, text } = this.state;
        this.handleSearch({ value: text, offset: users.length });
    };

    getContent() {
        const { selected, users } = this.state;
        const { t } = this.props;
        return (
            <div className="comment-mention-container" ref={this.ref_div}>
                <InfiniteScroll
                    initialLoad={false}
                    // pageStart={0}
                    loadMore={this.handleInfiniteOnLoad}
                    hasMore={!this.state.loading && this.state.hasMore}
                    useWindow={false}
                >
                    <div className="mention-list">
                        {users.map((user, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`mention-list-item ${
                                        convertEmployeeToString(user as any) === selected
                                            ? 'mention-list-item-selected'
                                            : ''
                                    }`}
                                    onMouseEnter={() => {
                                        this.handleMouseEnter(user);
                                    }}
                                    onClick={() => this.handleEnter(user)}
                                >
                                    <div className="avatar">
                                        {user.avatar ? (
                                            <img
                                                src={changeEgovUrl(
                                                    user.avatar || getUserDefaultAvatar()
                                                )}
                                                draggable="false"
                                            />
                                        ) : (
                                            <div className="default-avatar">
                                                {user.realName.slice(0, 1)}
                                            </div>
                                        )}
                                    </div>
                                    <div className="container">
                                        <div className="title-content">
                                            <span className="title">{user.realName}</span>
                                        </div>
                                        <div className="desc">
                                            <span className="desc-org">{user.deptName || ''}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </InfiniteScroll>
                {!users.length && (
                    <div key="no user" className="no-user">
                        <img src={changeEgovUrl(SearchEmptyImg)} />
                        <div className="desc">{t('no_relative_user')}</div>
                    </div>
                )}
            </div>
        );
    }

    handleMouseEnter = (user: SearchResultModel) => {
        this.setState({
            selected: convertEmployeeToString(user as any) || '',
        });
    };

    insertMentionLink(value: any) {
        const { editor } = this.props;
        const range = editor.getSelection();
        if (!range) return;
        const { index } = range;
        const quill = editor.getQuill();
        if (!quill) return;
        const Delta = Quill.import('delta');
        const inputLen = this.state.text.length + 1;
        quill.updateContents(
            new Delta()
                .retain(index - inputLen)
                .delete(inputLen)
                .insert({
                    'mention-link': value,
                }),
            'user'
        );
        this.setState({ text: '' });
        if (this.curRef) {
            this.curRef.hide();
        }
    }
    handleEnter(uUser?: SearchResultModel) {
        let user = uUser;
        const { selected, users } = this.state;
        if (!user && !selected) return;
        if (!user) {
            user = users.find((user) => convertEmployeeToString(user as any) === selected);
        }

        setTimeout(() => {
            if (!user) return;
            this.insertMentionLink({
                id: convertEmployeeToString(user as any),
                type: 'user',
                name: user.realName,
            });
        }, 10);
        this.props.editor.focus();
    }

    scrollList() {
        const ele = document.querySelector<HTMLDivElement>(
            '.comment-mention-container .mention-list-item-selected'
        );
        if (ele) {
            ele.scrollIntoView({
                block: 'nearest',
                // inline: 'end',
            });
        }
    }

    onKeyDown = (key: string) => {
        const { users } = this.state;
        if (!this.state.users.length) return;
        const idx = this.state.users.findIndex((user) => {
            return convertEmployeeToString(user as any) === this.state.selected;
        });
        let nIdx = idx;
        switch (key) {
            case 'ArrowUp':
                nIdx = idx - 1;
                if (nIdx < 0) {
                    nIdx = users.length - 1;
                }
                break;
            case 'ArrowDown':
                nIdx = idx + 1;
                if (nIdx > users.length - 1) {
                    nIdx = 0;
                }
                break;
            case 'Enter':
                this.handleEnter();
                break;
            default:
                break;
        }
        this.setState(
            {
                selected: convertEmployeeToString(users[nIdx] as any) || '',
            },
            () => {
                this.scrollList();
            }
        );
    };
    onTextInput = async (text: string) => {
        log.debug('onTextInput', text);
        const input = text.replace('@', '').trim();
        this.handleSearch({ value: input, offset: 0, scrollTop: true });
        this.setState({
            text: input,
        });
    };

    onVisibleChange = (visible: boolean) => {
        if (!visible) {
            this.setState({
                visible: false,
                selected: '',
                hasMore: true,
                loading: false,
            });
            setTimeout(() => {
                this.setState({ text: '', users: [] });
            }, 1000);
        } else {
            // const users = this.props.handleSearch('');
            // if (users.length > 0) {
            //     this.setState({
            //         visible: true,
            //         selected: convertEmployeeToString(users?.[0] as any) || '',
            //         users,
            //     });
            // }
        }
    };

    render() {
        const { visible } = this.state;
        return (
            <>
                {/* <div className={`chat-mention ${visible ? '' : 'chat-mention-hidden'}`}>
                    {this.getContent()}
                </div> */}
                <CursorPopover
                    ref={(ref) => {
                        this.curRef = ref;
                    }}
                    bindings={['@']}
                    editor={this.props.editor}
                    content={this.getContent()}
                    onKeyDown={this.onKeyDown}
                    onTextInput={this.onTextInput}
                    onVisibleChange={this.onVisibleChange}
                    // withoutPopover={true}
                />
            </>
        );
    }
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, currentEmployee, sessionStatusMap } = chat as ChatState;
    return { selectedSession, currentEmployee, sessionStatusMap };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MentionUserPlugin);
