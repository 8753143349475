/**
 * 大搜消息逻辑组件
 * 核心功能：初始化数据(init) 数据获取(getmessageList) 分页加载数据(loadmoreFn) 缓存数据(tmpmessageList)用于点击返回按钮是展示，不用再次请求数据。
 * 控制加载动画(loading) 是否显示加载更多数据(loadMore) 控制是否有返回按钮(isShowBackBtn)
 */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { SearchType } from '@jd/focus-desktop-comps/lib/es/UserSelection';
import { SearchMessageHistoryListUI } from './SearchMessageHistoryUI';
import { ChatMessage, ChatMessageType } from '@/types/chat';
import ImService from '@/server/ImService';
import { useHistory } from 'react-router-dom';
import bus from '@/utils/bus';
import { connect } from 'dva';
import { getChatLocationPath } from '@/utils/chat';
import { getGroupInfo } from '@/api/egroup';
import { message } from 'antd';
import { analysisLog } from '@/utils/logAnalytics';

interface Item {
    avatar: string;
    name: string;
    mid: number;
    sessionId: string;
    url: string;
    isGroup: boolean;
    children?: Item[];
    isSingle?: boolean;
    info: any;
}
interface IProps {
    keyWord: string;
    activeKey: string;
    limit?: number;
    t: any;
    clearSessionMessage?: Function;
    setHistoryMessage?: Function;
    updateMessages?: Function;
}

let currentMid = '';
let laetId: any = 0;
const SeacrchMassageHistoryList = (props: IProps) => {
    const {
        keyWord = '',
        activeKey = SearchType.MessageHistory,
        limit = 1000,
        t,
        clearSessionMessage,
        setHistoryMessage,
        updateMessages,
    } = props;
    // keyVal = keyWord;
    const [tmpmessageList, setTmpMessageList] = useState<any[]>([]); // 临时存储数据
    const [messageList, setMessageList] = useState<any[]>([]);
    const [loading, setLoaing] = useState(true);
    const [loadMore, setLoadMore] = useState(false);
    const [isShowBackBtn, setShowBackBtn] = useState(false);
    const imService = ImService.getInstance();
    const currPage = useRef(0);
    const history = useHistory();
    // 处理数据
    const handleData = (item: any) => {
        const isSingle =
            item.sessionId.indexOf(`:${process.env.REACT_APP_DD_APPID}:`) !== -1 ? true : false;
        let url = '';
        if (isSingle) {
            const { app, teamId, userId } = item.requestData;
            url = `/messages/s/${userId}?app=${app}&tid=${teamId}&top=${true}&sMid=${
                item.mid
            }&sText=${keyWord}`;
        } else {
            url = `/messages/g/${item.sessionId}?sMid=${item.mid}&top=${true}&sText=${keyWord}`;
        }
        return {
            avatar: (item.requestData && item.requestData.avatar) || '',
            name: (item.requestData && item.requestData.name) || '',
            desc: item.content,
            isGroup: false,
            mid: item.mid,
            sessionId: item.sessionId,
            url,
            isSingle,
            info: item.requestData,
        };
    };
    // 加载数据
    async function loadSingeExportMessages(searchKey = '', offset = 0) {
        const msgs = await imService.search(
            {
                sessionId: '',
                searchKey,
                searchType: ChatMessageType.TEXT,
                limit,
                queryType: 1,
                offset,
                incluedUser: false,
                excludeSecret: true,
            },
            true
        );
        msgs.sort((a: any, b: any) => b.timestamp - a.timestamp);
        console.log('消息记录处理前的数据列表', msgs);
        // 消息聚合
        const list: Item[] = [];
        for (let item of msgs) {
            // const mid = (item as any).mid || -1;
            if ((item as any).requestData && !(item as any).requestData.name) {
                continue;
            }
            const _item =
                list.find((_item: Item) => _item.sessionId === (item as any).sessionId) || null;
            if (!_item) {
                const result: Item = handleData(item);
                result.children = [];
                list.push(result);
                continue;
            }
            _item.isGroup = true;
            if (!_item.children?.length) {
                const res = { ..._item };
                res.isGroup = false;
                delete res.children;
                _item.children = [res];
            }
            _item.children?.push(handleData(item));
        }
        console.log('消息记录处理后的数据列表', list);
        return list;
    }
    const init = () => {
        currPage.current = 0;
        setLoadMore(false);
        setLoaing(true);
        setShowBackBtn(false);
        setMessageList([]);
        setTmpMessageList([]);
    };
    const getmessageList = (searchKey = '', offset = 0) => {
        laetId++;
        return async (theLastId: any) => {
            if (!searchKey) {
                setMessageList([]);
                setTmpMessageList([]);
                // setLoaing(false);
                return;
            }
            const res = await loadSingeExportMessages(searchKey, offset);
            // console.log(res, 'getmessageListgetmessageListgetmessageListgetmessageList');
            // console.log(laetId === theLastId, searchKey, offset);
            if (laetId === theLastId) {
                if (searchKey) {
                    if (offset <= 0) {
                        setMessageList(res);
                        setTmpMessageList(res);
                    } else {
                        const list = messageList.concat(res);
                        setMessageList(list);
                        setTmpMessageList(list);
                    }
                } else {
                    setMessageList([]);
                    setTmpMessageList([]);
                }
                setLoaing(false);
            }
        };
        // return async ()
        // loadSingeExportMessages(searchKey, offset)
        //     .then((res) => {
        //         if (laetId === theLastId) {
        //             if (searchKey) {
        //                 if (offset <= 0) {
        //                     setMessageList(res);
        //                     setTmpMessageList(res);
        //                 } else {
        //                     const list = messageList.concat(res);
        //                     setMessageList(list);
        //                     setTmpMessageList(list);
        //                 }
        //             }
        //         }
        //         // 控制加载更多
        //         // setLoadMore(res.length === limit ? true : false);
        //     })
        //     .finally(() => {
        //         setLoaing(false);
        //     });
    };
    useMemo(() => {
        init();
        if (activeKey !== SearchType.MessageHistory) {
            return;
        }
        getmessageList(keyWord)(laetId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyWord, activeKey]);
    // 加载更多数据
    const loadmoreFn = () => {
        // 加载
        if (!loadMore) {
            return;
        }
        const offset = (currPage.current += 1);
        // getmessageList(keyWord, offset);
    };
    const handleUrl = (item: any) => {
        // const sMid = item.url.split('sMid=')[1].split('&')[0];
        // if (currentMid === sMid) {
        //     const index = item.url.indexOf('sText=');
        //     let url = item.url.substring(0, index - 1);
        //     history.push(`${url}`);
        // } else {
        //     history.push(item.url);
        // }
        // currentMid = sMid;
        history.push(item.url);
        bus.emit('onHideGlobalSearch');
    };
    return (
        <SearchMessageHistoryListUI
            loading={loading}
            loadMore={loadMore}
            keyWord={keyWord}
            list={messageList}
            isShowBackBtn={isShowBackBtn}
            t={t}
            loadmoreFn={() => {
                loadmoreFn();
            }}
            onChangeDate={(list) => {
                analysisLog('xtbg_home_search_1641454991771', '33');
                setShowBackBtn(true);
                setLoadMore(false);
                setTmpMessageList([...messageList]);
                setMessageList(list);
            }}
            backFn={() => {
                setShowBackBtn(false);
                setLoadMore(tmpmessageList.length === limit ? true : false);
                setMessageList(tmpmessageList);
                setTmpMessageList([]);
            }}
            onOpenMessage={async (item) => {
                updateMessages?.({ messages: [] });
                if (!(item as any).isSingle) {
                    let group: any = imService.getGroup(item.sessionId);
                    if (!group?.name || !group?.flag) {
                        try {
                            const res = await getGroupInfo({
                                groupIds: [{ groupId: item.sessionId }],
                            });
                            if (res.groups[0].flag !== 3) {
                                message.error('该组已解散！');
                                return;
                            } else {
                                handleUrl(item);
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }
                } else {
                    try {
                        const res = await imService.getEmployeeDetail({
                            appId: (item as any).info.app,
                            userId: (item as any).info.userId,
                            teamId: (item as any).info.teamId,
                        });
                        setTimeout(() => {
                            handleUrl(item);
                        });
                    } catch (error) {
                        message.error('该用户已被移除');
                    }
                }
                analysisLog('xtbg_home_search_1641454991771', '33');
                // clearSessionMessage?.({ sessionId: item.sessionId });
            }}
        />
    );
};

function mapStateToProps({ chat }: any) {
    // const {
    //     selectedSession,
    //     sessionMessageMap,
    //     mainWinShow,
    //     selectedAITab,
    //     allSessionList,
    //     preSelectedSession,
    //     prevAISessionMessages,
    //     sMid,
    //     sText,
    // } = chat as ChatState;
    // let sessionMessages: any[] = sessionMessageMap[selectedSession.sessionId] || [];
    // // console.log(sessionMessages, 'sessionMessagessessionMessages==>');
    // if (isAINoticeSession(selectedSession)) {
    //     const selectedPin = selectedAITab.pin || CHAT_NOTICE.ALL_PIN;
    //     sessionMessages = filterNoticeMassagesByType(sessionMessages, selectedPin);
    // }
    // return {
    //     sessionMessages,
    //     selectedSession,
    //     mainWinShow,
    //     allSessionList,
    //     selectedAITab,
    //     sessionMessageMap,
    //     storePreSelectedSession: preSelectedSession,
    //     prevAISessionMessages,
    //     sMid,
    //     sText,
    // };
}

function mapDispatchToProps(dispatch: any) {
    return {
        async setHistoryMessage(data: { sessionId: string; historyMessage: ChatMessage }) {
            await dispatch({ type: 'chat/setHistoryMessage', payload: data });
        },
        async clearSessionMessage(data: { sessionId: string }) {
            await dispatch({ type: 'chat/clearSessionMessage', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SeacrchMassageHistoryList);
