import { IPageAddCallback } from './index';
// import { openSharedModal } from '../components/shared';
import openAddUserModal from '../contextModal/AddUserMembers';
import { lang } from '../context';
import events from '../context/events';
import { PermissionEnum } from '../const';
import {
    folderTypeEnum,
    getJoySpaceReadAllPermission,
    getJoySpaceReadFolderPermission,
    getJoySpaceReadOnlyPermission,
    getJoySpaceReadPagePermission,
    getJoySpaceReadWritePermission,
} from '../config/permissions.config';
import React, { useContext } from 'react';
import GlobalContext from '@/context/GlobalContext';
import { FileType, OpenFolderStatus } from '../types';
import { Tooltip } from 'antd';
import QuestionCircleOutlined from '@ant-design/icons';

// eslint-disable-next-line max-params
const Shared: IPageAddCallback = (props, isAllow, isTeam, context) => {
    const { data, clearSelect } = props;
    let type = data.page_type ? FileType.Page : FileType.Folder;
    let permissionType = getJoySpaceReadOnlyPermission();
    let folderType = data.type as any;
    // console.log(folderType, 'folderTypefolderTypefolderTypefolderType');
    if (folderType && folderType === folderTypeEnum.MY) {
        permissionType = getJoySpaceReadAllPermission();
    } else if (type === FileType.Page && folderType === folderTypeEnum.MY) {
        permissionType = getJoySpaceReadWritePermission();
    } else if (type === FileType.Page) {
        permissionType = getJoySpaceReadPagePermission(data.permissions);
    } else if (folderType && folderType === folderTypeEnum.SHARE && type === FileType.Folder) {
        permissionType = getJoySpaceReadFolderPermission(data.permissions);
    }

    return [
        {
            children: lang('ctx.shared.title'),
            icon: 'pc_yundocument_share',
            splitCode: 1,
            callbackOrModal: () =>
                openAddUserModal({
                    type,
                    id: data.id,
                    isModalVisible: true,
                    permissionType,
                    title: (
                        <div>
                            分享给
                            <Tooltip
                                placement="top"
                                title="如果被分享人的已有权限高于本次分享设置的权限，则该用户的权限不会被改变。如需变更用户的权限，请在“协作者管理”中设置。"
                                className="joyspace-operate-tip"
                            >
                                <QuestionCircleOutlined className="share-tip" />
                            </Tooltip>
                        </div>
                    ),
                    modalType: 'share',
                    folderType: folderType,
                    isOpenFolder:
                        data.open === OpenFolderStatus.OPEN
                            ? OpenFolderStatus.OPEN
                            : OpenFolderStatus.CLOSE,
                    onOk: () => {
                        clearSelect?.();
                        events.emit('list.reload');
                    },
                }),
            isAllow,
            type: 'shared',
        },
    ];
};

export default Shared;
