import { FocusSDK } from '@/utils';
import Cookies from 'js-cookie';
import { PageType, PageTypes } from './const';
import { changeGovUrl } from '@/utils/tools';
import { IPageBase } from './types';
// import PageService from './service/PageService';
import JoyspaceController from './controllers/JoyspaceController';
import {
    audio,
    excel,
    compressd,
    image,
    pdf,
    ppt,
    team_sheet,
    team_page,
    txt,
    unknown,
    video,
    word,
    program,
} from '@/assets/images/joyspace';
import joyspace from '@/models/joyspace';

export const time = {
    format(isostr: Date | string | number | undefined) {
        if (isostr === undefined) {
            return '';
        }
        // const dateStr = (isostr as any).substring(0, 16);
        const date = new Date(
            // (isostr instanceof Date ? isostr : new Date(isostr)).getTime() + 60 * 60 * 1000 * 8
            (isostr instanceof Date ? isostr : new Date(isostr)).getTime()
        );
        // console.log(date.valueOf(), 'data');
        // const today = new Date(new Date().toLocaleDateString().slice(0, 10));
        // console.log(today.valueOf(), 'todaytoday');
        // const tomorrow = new Date(today.getTime() + 86400000);
        const day = new Date();
        let time = '';
        // console.log(day.toLocaleDateString(), 'day.toLocaleDateString()');
        // console.log(date.toLocaleDateString(), 'date.toLocaleDateString()');

        if (day.toLocaleDateString() === date.toLocaleDateString()) {
            // if (today < date && date < tomorrow) {
            time = '今天 ' + (isostr as any).slice(11, 16).replace(/\-/g, '/');
        } else if (isostr) {
            const isoary = (isostr as any).slice(0, 16).replace(/T| /g, '-').split('-');
            if (isoary.length < 4) {
                return time;
            }
            isoary[1] = isoary[1].replace(/\b(0+)/gi, '');
            isoary[2] = isoary[2].replace(/\b(0+)/gi, '');
            // @ts-ignore
            if (day.getYear() === date.getYear()) {
                return `${isoary[1]}月${isoary[2]}日 ${isoary[3]}`;
            } else {
                return `${isoary[0]}年${isoary[1]}月${isoary[2]}日  ${isoary[3]}`;
            }
            // time = (isostr as any).slice(0, 16).replace(/\-/g, '/').replace('T', ' ');
        }
        return time;
    },
};

export const chineseTime = {
    // 2021-08-16T07:32:25.552Z
    format(isostr: Date | string | number | undefined) {
        if (isostr === undefined) {
            return '';
        }
        const date = new Date(
            (isostr instanceof Date ? isostr : new Date(isostr)).getTime() + 60 * 60 * 1000 * 8
        );
        const today = new Date(new Date().toISOString().slice(0, 10));
        const tomorrow = new Date(today.getTime() + 86400000);
        let chineseTime = '';
        if (today < date && date < tomorrow) {
            chineseTime = '今天 ' + date.toISOString().substr(11, 5);
        } else if (date.toISOString) {
            chineseTime =
                date.toISOString().substr(0, 4).replace(/\-/g, '').replace('T', '') +
                '年' +
                date.toISOString().substr(5, 2).replace(/\-/g, '').replace('T', '') +
                '月' +
                date.toISOString().substr(8, 2).replace(/\-/g, '').replace('T', '') +
                '日 ' +
                date.toISOString().substr(11, 5);
        }
        return chineseTime;
    },
};

export function openLowCode(url?: string) {
    const { REACT_APP_JOYSPACE_LOWCODE_CREATE_URL } = process.env;
    // if (REACT_APP_JOYSPACE_LOWCODE_CREATE_URL) {
    //     window.open(url ?? REACT_APP_JOYSPACE_LOWCODE_CREATE_URL);
    // }
    window.open(url ?? REACT_APP_JOYSPACE_LOWCODE_CREATE_URL);
}

export function addToken(url: string) {
    const result =
        url +
        '?focus-token=' +
        (Cookies.get('focus-token') || '') +
        '&focus-team-id=' +
        (Cookies.get('focus-team-id') || '') +
        '&focus-client=web' +
        '&token=' +
        (Cookies.get('token') || '');
    return result;
}

export function isImageType(type: string) {
    if (!type || typeof type !== 'string') {
        return false;
    }
    return type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i);
}

export function getRnRouter(pageType: number) {
    switch (pageType) {
        case 1:
            return 'document_edit';
        case 2:
            return 'report_view';
        case 3:
            return 'note_edit';
        case 4:
            return 'spreadsheet_edit';
        case 5:
            return '';
        case 6:
            return 'file_view';
        case 7:
            return 'websheet_edit';
    }
}
export function isMySpace(pathname: string): boolean {
    if (pathname.includes('myspace')) {
        return true;
    }
    return false;
}
export function moveTips(from: any, to: any, isTeam: any): string {
    if (!isTeam && to.type === 8) {
        return 'ctx.move.tips.no-change';
    }
    if (!isTeam && to.type === 12) {
        // 移动到共享成为根
        if (to.id === 'teamRoot') {
            return 'ctx.move.tips.no-change';
        }
        return 'ctx.move.tips.my-to-share';
    }
    if (isTeam && to.type === 12) {
        // 权限不变时
        if (from.space_id === to.rootId || (from.space_id === from.id && to.id === 'teamRoot')) {
            return 'ctx.move.tips.no-change';
        }
        // 移动到共享成为根，取消所有权限
        if (to.id === 'teamRoot') {
            return 'ctx.move.tips.share-new';
        }
        // 权限改变时
        return 'ctx.move.tips.share-change';
    }
    return 'ctx.move.tips.no-change';
}
export const userSelectDataType = {
    Group: 'group',
    User: 'user',
    Org: 'org',
};

// 设置icon图片地址
export const setIconUrl = (data: any) => {
    let iconUrl = '';
    if (FocusSDK.getDeviceInfo().platform === 'web') {
        let url = `${process.env.REACT_APP_JOYSPACE_API_HOST}-api`;
        let { title, page_type: pageType, type } = data || {};
        title = title || '';
        pageType = pageType || '';
        type = type || '';
        iconUrl = `${url}/v1/common/icons?title=${title}&pageType=${pageType}&type=${type}&client=web`;
    } else {
        iconUrl = data.icon_url;
    }
    return iconUrl;
};
// 匹配joyspace Url 正则 https://xtbg-wxxb.jdcloud.com:8444/joyspace/page/Sw1IAR9l0XAIsH9wh5T2
// export let legalJoyspaceUrlReg = /https?:\/\/((jdxtbg.tjjn-)?datalake.*?\.com|joyspace\.?[.a-z0-9]{3,}|[.a-z0-9]{3,}\/joyspace)/;
export const legalJoyspaceUrlReg = (url: string) => {
    try {
        return /joyspace\.?[.a-z0-9]{3,}|[.a-z0-9]{3,}\/joyspace/.test(
            new URL(url).origin + new URL(url).pathname
        );
    } catch (error) {
        return false;
    }
};

// 合法的joyspace 的地址，按理应该是通过项目的joyspace的baseurl去拼，但是因为需要兼容各环境暂时写死
export const checkJoyspaceUrl = (url: string) => {
    try {
        console.log(url, 'urlurlurlurlurl==>');
        const isJoyspaceUrl = legalJoyspaceUrlReg(url);
        console.log(isJoyspaceUrl, 'isJoyspaceUrlisJoyspaceUrlisJoyspaceUrlisJoyspaceUrl');
        if (isJoyspaceUrl) {
            const urlObj = new URL(url);
            const pathArr = urlObj.pathname.split('/').filter((item: any) => item) || '';
            console.log(pathArr, 'pathArrpathArrpathArr');
            const pageType = PageTypes[pathArr[1]] || '';
            const fileId = pathArr[2] || '';
            console.log('~~~', 'pageType', pageType, 'fileId', fileId);
            console.log(urlObj, 'urlObjurlObjurlObjurlObj');
            if (pageType && fileId) {
                return {
                    is: true,
                    pageType,
                    webUrl: changeGovUrl(url),
                    fileId,
                };
            }
        }
        return { is: false, url: changeGovUrl(url) };
    } catch {}
    return { is: false, url: changeGovUrl(url) };
};

export async function openLink(page: IPageBase, urlPrefix: string, target = '_blank') {
    let url = createPageUrl(page, urlPrefix);
    FocusSDK.printLog('newWindowDebug: joyspace: 打开文档列表 before：', url);
    window.open && FocusSDK.printLog('joyspace: 打开文档文档列表 before has window.open：');

    try {
        // if (!navigator.onLine) {
        if (page.isNew) {
            const { value } = await JoyspaceController.pageService.getFromDB(
                `base-data-${page.id}`
            );
            value.page.openTime = new Date().getTime();
            JoyspaceController.pageService.createPageInFile('apis', value.page);
            url = `${url}?isLocalCreate=1`;
        } else {
            // 离线新建的不上报
            joyaLog('d1', page.id, page.page_type, page.type, page.title);
        }
        // }
        setTimeout(() => {
            console.log(url, 'url===>2');
            window.open(url, target);
        }, 1000);
    } catch (e) {
        console.log(e, 'e=====>');
        FocusSDK.printLog('newWindowDebug: joyspace: 打开文档列表 error：', e);
    }
    FocusSDK.printLog('newWindowDebug: joyspace: 打开文档列表 end：', url);
}
const ServiceIdentification = 'xtbg_joyspace_1000001';
export const joyaLog = (typeId: string, ...res: any) => {
    setTimeout(() => {
        // @ts-ignore
        const log = window.log || null;
        console.log('joyaLog', log);
        if (!log) return;
        log(ServiceIdentification, typeId, ...res);
    }, 1000);
};
export function createPageUrl(page: IPageBase, urlPrefix: string) {
    if (Number(page.space_type) === 1 || Number(page.team_type) === 3) {
        return `${urlPrefix}/spaces/${page.id}`;
    }
    if (page.page_type === PageType.Form) {
        return page.link;
    }
    let DocType = 'page';
    if (page.page_type === PageType.Sheet) {
        DocType = 'sheet';
    }
    if (page.page_type === PageType.File) {
        DocType = 'file';
    }
    if (page.page_type === PageType.Reporting) {
        DocType = 'report';
    }
    if (page.page_type === PageType.Link) {
        if (page.origin?.page_type === PageType.Sheet) {
            DocType = 'sheet';
        }
        if (page.origin?.page_type === PageType.File) {
            DocType = 'file';
        }
        if (page.origin?.page_type === PageType.Reporting) {
            DocType = 'report';
        }
    }
    return `${urlPrefix}/${DocType}/${page.id}`;
}
// image
export function imageFileMap() {
    return [
        'image/png',
        'image/gif',
        'image/jpeg',
        'image/jpg',
        'image/jpe',
        'image/webp',
        // 'image/jfif', --> image/jpeg
        // 'image/mdi' --> text/plain,
        'image/bmp',
        'image/tiff',
        // 'image/dwg', --> text/plain
        'image/x-icon',
        // 'image/pgm', --> text/plain
        // 'image/rgb', --> text/plain
        // 'image/psd', --> text/plain
        // 'image/cmx', --> text/plain"
        'image/tiff',
        // 'image/pcx', --> text/plain
        // 'image/eps', --> application/postscript
        'application/postscript',
    ];
}
// txt
export function txtFileMap() {
    return [
        'text/plain',
        'text/css',
        'text/py',
        'text/md',
        'text/jmx',
        'text/sql',
        'text/wpd',
        'text/odt',
        'text/pages',
        'text/json',
        'text/rtf',
        'text/key',
        'text/xmind',
        'text/xml',
        'text/uot',
        'text/uof',
        'text/uos',
        'text/uop',
        'text/ofd',
        'text/lwp',
        'text/java',
        'text/js',
        'text/log',
        'text/ini',
    ];
}
// video
export function videoFileMap() {
    return [
        'video/mp4',
        'video/quicktime',
        'video/webm',
        'video/ogg',
        'video/mp4v',
        'video/mov',
        'video/asx',
        'video/flv',
        'video/asf',
        'video/fil',
        'video/mpg',
        'video/wmv',
        'video/3gp',
        'video/webm',
        'video/mkv',
        'video/ogv',
        'video/avi',
        'video/webm',
        'video/vob',
        'video/3g2',
    ];
}
// audio
export function audioFileMap() {
    return [
        'audio/mpeg',
        'audio/mp3',
        'audio/x-mp3',
        'audio/x-mpg',
        'audio/x-mpeg-3',
        'audio/mpeg3',
        'audio/x-mpeg-3',
        'audio/x-flac',
        'audio/flac',
        'application/x-ape',
        'audio/x-ape',
        'audio/wav',
        'audio/x-wav',
        'audio/amr',
        'audio/opus',
        'audio/oga',
        'audio/ra',
        'audio/midi',
        'audio/m4a',
        'audio/flac',
        'audio/aiff',
        'audio/wma',
        'audio/caf',
        'audio/au',
        'audio/aac',
        'audio/sil',
        'audio/mid',
        'audio/ram',
        'audio/ogg',
        'audio/rmi',
    ];
}
// compressd
export function compressedFileMap() {
    return [
        'application/x-compressed',
        'application/x-rar-compressed',
        'multipart/x-zip',
        'application/vnd.rar',
        'application/zip',
        'application/x-7z-compressed',
        'application/x-zip-compressed',
        'application/rar',
        'application/7z',
        'application/bz2',
        'application/gz',
        'application/jar',
    ];
}
// program
export function programFileMap() {
    return [
        'application/dll',
        'application/exe',
        'multipart/msi',
        'application/com',
        'application/apk',
        'application/class',
        'application/rpm',
        'application/dep',
    ];
}
//  wps
export function wordFileMap() {
    return [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/eio-x-doc',
        'application/eio-x-docx',
        'application/wps-office.doc',
        'application/wps-office.docx',
    ];
}
// excle
export function excleFileMap() {
    return [
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/eio-x-xls',
        'application/eio-x-xlsx',
        'application/wps-office.xls',
        'application/wps-office.xlsx',
    ];
}
// ppt
export function pptFileMap() {
    return [
        'application/vnd.ms-powerpoint',
        'application/mspowerpoint',
        'application/powerpoint',
        'application/x-mspowerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/eio-x-ppt',
        'application/eio-x-pptx',
        'application/wps-office.ppt',
        'application/wps-office.pptx',
    ];
}
// wps
export function wpsFileMap() {
    return [
        'application/wps-office.wps',
        'application/wps-office.dps',
        'application/wps-office.wpt',
        'application/wps-office.dpt',
        'application/wps-office.et',
        'application/wps-office.ett',
    ];
}
// pdf
export function pdfFileMap() {
    return [
        'application/pdf',
        'application/eio-x-pdf',
        'application/eio-x-pdfx',
        'application/wps-office.pdf',
        'application/wps-office.pdfx',
    ];
}
// audio, excel, exe, image, pdf, ppt, compressd, team_excel, team_page, txt, unknown, video, word

export function getIconUrl(type: any) {
    if (imageFileMap().includes(type)) {
        return image;
    }
    if (wordFileMap().includes(type)) {
        return word;
    }
    if (excleFileMap().includes(type) || wpsFileMap().includes(type)) {
        return excel;
    }
    if (pptFileMap().includes(type)) {
        return ppt;
    }
    if (pdfFileMap().includes(type)) {
        return pdf;
    }
    if (txtFileMap().includes(type)) {
        return txt;
    }
    if (videoFileMap().includes(type)) {
        return video;
    }
    if (audioFileMap().includes(type)) {
        return audio;
    }
    if (compressedFileMap().includes(type)) {
        return compressd;
    }
    if (programFileMap().includes(type)) {
        return program;
    }
    if (type === PageType.Page) {
        return team_page;
    }
    if (type === PageType.Sheet) {
        return team_sheet;
    }
    return unknown;
}

/**
 * 局部刷新
 * item:更改之后的数据
 * data:要被更改数据
 * key: 根据 key去查询对应的要更改的数据
 * */

// eslint-disable-next-line max-params
export function partialRefresh(item: any, data: any[], key: any) {
    if (data instanceof Array) {
        let _data = [...data];
        const index = _data.findIndex((_item: any) => _item[key] === item[key]);
        if (index > -1) {
            if (item.oldPageId) {
                item.page_id = item.oldPageId;
            }
            _data.splice(index, 1, { ..._data[index], ...item });
        }
        console.log(_data, '_data_data_data_data===>', item);
        return _data;
    }
    throw new Error('data必须为数组');
}

// 全局变量
let joyspaceDuccConfig: any = {};
// set joyspace下的ducc
export function setJoySpaceDucc(joyspaceDucc: any) {
    joyspaceDuccConfig = joyspaceDucc;
    // console.log(joyspaceDuccConfig, '===========================>');
}
// get joyspace下的ducc
export function getJoySpaceDucc() {
    return joyspaceDuccConfig;
}

export function notUseJoySpaceOffline() {
    return joyspaceDuccConfig.notUseOffline;
}
