import React from 'react';
import WorkCard from './WorkCard';
import ScheduleCard from './ScheduleCard';

export interface IProps {
    // 任务或者日程id
    businessId?: any;
    // 区分日程还是任务的type
    businessType?: string;
}

function TopNoticeCard2Chat({ businessId, businessType }: IProps) {
    if (businessType === 'schedule') {
        return <ScheduleCard businessId={businessId} />;
    } else if (businessType === 'work') {
        return <WorkCard businessId={businessId} />;
    }
    return null;
}

export default TopNoticeCard2Chat;
