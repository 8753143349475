/**
 * 文件的上传下载流程服务
 * @author sunzhiguang
 * @date 2020/7/2
 */

import { getAppId, getAuthInfo, FocusSDK, isFocusEnv, getAppSecretKey } from '@/utils';
import { getAppVersion } from '@/utils/getVersion';
import UploadFileService, { TaskOptions } from '@jd/jdee-fs-desktop';
import Task from '@jd/jdee-fs-desktop/lib/es/Task';
import AppRuntimeEnv from '@/types/AppRuntimeEnv';

const FileService: {
    uploadService: UploadFileService | null;
    downloadService: null;
} = {
    uploadService: null,
    downloadService: null,
};

const FileTaskMap = new Map<string, Task[]>();

/**
 * 处始化文件服务
 */
export const initFileUploadService = async (appRuntimeEnv: AppRuntimeEnv) => {
    const { appKey: key, hostOrigin } = appRuntimeEnv.fileService;
    let appSecretKey = '';
    if (FocusSDK.getDeviceInfo().platform === 'web') {
        appSecretKey = process.env.REACT_APP_WEB_SECRETKEY || '';
    } else {
        appSecretKey =
            appRuntimeEnv.gateway.secretKey || getAppSecretKey(appRuntimeEnv.runtimeConfig.key);
    }
    const appKey = key || process.env.REACT_APP_FILE_SERVICE_APPKEY;
    const { REACT_APP_FILE_SERVICE_HOST, REACT_APP_JANUS_HOST, NODE_ENV } = process.env;
    const fileServiceHost = hostOrigin || REACT_APP_FILE_SERVICE_HOST;
    if (!appKey) {
        throw new Error('缺少配置[appKey]');
    }
    const authInfo = await getAuthInfo();
    const clientType = (!isFocusEnv() && 'WEB') || authInfo['x-client'];

    let appId = authInfo['x-gateway-id'];
    if (FocusSDK.getDeviceInfo().platform === 'web') {
        appId = process.env.REACT_APP_WEB_APPID;
    }
    FileService.uploadService = new UploadFileService({
        appKey: appKey, // 文件服务appkey
        host: fileServiceHost,
        janus: {
            host: appRuntimeEnv.gatewayHost,
            appSecretKey,
            appId, // getAppId() || '', // janus网关appid
            // @ts-ignore
            stage: appRuntimeEnv.gateway.stage || process.env.REACT_APP_JANUS_STAGE,
            appVersion: getAppVersion(FocusSDK) || '1.9.1',
            appKey: appSecretKey,
            clientType,
        },
        auth: {
            // 认证信息
            xToken: authInfo['x-token'], // 用户登录token
            xTeamId: authInfo['x-team-id'], // 用户所在teamId
            xClient: clientType, // 客户端类型
        },
    });
};

/**
 * 创建会话上传文件服务
 * @param id
 * @param blob
 * @param options
 */
export const createUploadTask = (
    id: string,
    blob: File,
    options?: Partial<TaskOptions>
): Task | undefined => {
    const uploadService = FileService.uploadService;
    if (uploadService) {
        const task = uploadService.addTask(blob, {
            fileName: blob.name,
            fileSize: blob.size,
            fileType: blob.type,
            needAuthn: 1,
            ...options,
        });
        if (task) {
            // const tasks = FileTaskMap.get(sessionId) || [];
            // tasks.push(task);
            // FileTaskMap.set(sessionId, tasks);
            // if (tasks.length > 10) {
            //     // 上传超过10个文件，最早的文件会被清理
            //     tasks.shift();
            // }
            return task;
        }
    }
};

export const getUploadTasks = (sessionId: string): Task[] => {
    return FileTaskMap.get(sessionId) || [];
};

export const clearUploadTasks = (sessionId: string): void => {
    FileTaskMap.delete(sessionId);
};

export const getUploadTask = (sessionId: string, taskId: string): Task | undefined => {
    const tasks = FileTaskMap.get(sessionId) || [];
    const task = tasks.find((t) => t.id === taskId);
    if (task) {
        return task;
    }
};

export const removeUploadTask = (sessionId: string, task: Task | undefined): void => {
    if (!task) {
        return;
    }
    let tasks = FileTaskMap.get(sessionId) || [];
    if (tasks.length > 0) {
        tasks = tasks.filter((item) => item.id !== task.id);
        FileTaskMap.set(sessionId, tasks);
    }
};

// setTimeout(async () => {
//     try {
//         await initFileUploadService();
//     } catch (e) {
//         console.error('初始化文件服务失败 =>', e.message);
//     }
// }, 20);
