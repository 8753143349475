import React from 'react';
import ChatState from '@/types/chat/State';
import { connect } from 'dvajs';
import './index.less';
import IconFont from '@/components/icon';
import { useTranslation } from 'react-i18next';
import { ChatMessage } from '@/types/chat';
import ImService from '@/server/ImService';
import { UserModel } from '@/models/user';
import { message as toast } from 'antd';
import prompt from '@/baseComponents/ModalComponent/prompt';
import { MessageSendType } from '@/types/chat/enum';
import { getMessageSendType } from '@/utils/chat/message';
import { analysisLog } from '@/utils/logAnalytics';

interface DestroyMessgaeProps {
    messages: any[];
    math: any;
    userData: UserModel;
    clearSelectedMsgs: Function;
}
interface DvaDispatchProps {
    deleteSessionMessage: (data: { message: ChatMessage }) => void;
    changeSelectMore: Function;
    updateMessages: (data: { messages: ChatMessage[] }) => void;
}

type IProps = Readonly<ChatState & DvaDispatchProps & DestroyMessgaeProps>;

function mapStateToProps({ chat, user: { userData } }: { chat: ChatState; user: any }) {
    const {
        selectMore,
        messages,
        allSessionList,
        currentEmployee,
        selectedSession,
    } = chat as ChatState;
    return {
        selectMore,
        messages,
        allSessionList,
        currentEmployee,
        selectedSession,
        userData: userData ? userData.user : {},
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        changeSelectMore(data: { selectMore: boolean }) {
            dispatch({ type: 'chat/changeSelectMore', payload: data });
        },
        async deleteSessionMessage(data: { message: ChatMessage }) {
            await dispatch({ type: 'chat/deleteSessionMessage', payload: data });
        },
        updateMessages(data: { messages: ChatMessage[] }) {
            dispatch({ type: 'chat/updateMessages', payload: data });
        },
    };
}

function DestroyMessage(props: IProps) {
    const {
        changeSelectMore,
        messages,
        currentEmployee,
        selectedSession,
        deleteSessionMessage,
        updateMessages,
    } = props;
    const [t] = useTranslation('chat');
    const cancelSelectMore = () => {
        changeSelectMore({ selectMore: false });
        updateMessages({ messages: [] });
    };

    // 删除
    const deleteMessages = () => {
        if (messages.length < 1) {
            toast.error(t('please_select_messages'));
            return;
        }
        // 删除提醒
        prompt({
            title: t('delete-reminder'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onCancel: () => {
                analysisLog('Xtbg_Msg', 'InfoMultiDelete_Cancel');
            },
            onOk: async () => {
                // 调用删除接口
                analysisLog('Xtbg_Msg', 'InfoMultiDelete_Confirm');

                let deleteAll = true;
                for (let message of messages) {
                    const imService = ImService.getInstance();
                    const result = await imService.deleteMessage(message);
                    if (result) {
                        // 删除 dva 中的 数据
                        deleteSessionMessage({ message: message });
                    } else {
                        deleteAll = false;
                        toast.error(t('deletion-failed'));
                        return;
                    }
                }
                if (deleteAll) {
                    toast.success(t('deletion-succeeded'));
                    updateMessages({ messages: [] });
                    changeSelectMore({ selectMore: false });
                }
            },
            content: t('delete-selected-message'),
            okText: t('determine'),
            cancelText: t('cancelText'),
        });
    };

    const destroyMessages = () => {
        if (messages.length < 1) {
            toast.error(t('please_select_messages'));
            return;
        }
        // 销毁提醒
        prompt({
            title: t('destroy-tip'),
            icon: <IconFont type="iconic_failure" style={{ color: '#F96137' }} />,
            onCancel: () => {
                analysisLog('Xtbg_Msg', 'InfoMultiDestory_Cancel');
            },
            onOk: async () => {
                // 调用删除接口
                analysisLog('Xtbg_Msg', 'InfoMultiDestory_Confirm');
                let deleteAll = true;
                const ownMessages = messages.filter((message) => {
                    return (
                        getMessageSendType(message, currentEmployee, selectedSession) !==
                        MessageSendType.OTHER
                    );
                });
                // console.log('ownMessages', ownMessages);
                for (let message of ownMessages) {
                    const imService = ImService.getInstance();
                    const result = await imService.destroyMessage(message);
                    if (result) {
                        // 删除 dva 中的 数据
                        // deleteSessionMessage({ message: message });
                    } else {
                        deleteAll = false;
                        toast.error(t('destroyed-failed'));
                        return;
                    }
                }
                if (deleteAll) {
                    toast.success(t('destroyed-succeeded'));
                    updateMessages({ messages: [] });
                    changeSelectMore({ selectMore: false });
                }
            },
            content: t('destroy-selected-multiple-message'),
            okText: t('determine'),
            cancelText: t('cancelText'),
        });
    };

    return (
        <div className="destroy-messages">
            <div className="operate">
                <div className="delete">
                    <span
                        onClick={deleteMessages}
                        clstag="pageclick|keycount|Xtbg_Msg|InfoMultiDelete"
                    >
                        <IconFont className="icon" type="iconapp_ic_delete" />
                    </span>
                    <span>{t('delete')}</span>
                </div>
                <div className="destroy">
                    <span
                        onClick={destroyMessages}
                        clstag="pageclick|keycount|Xtbg_Msg|InfoMultiDestory"
                    >
                        <IconFont className="icon" type="iconic_app_im_burn" />
                    </span>
                    <span>{t('destroy')}</span>
                </div>
            </div>
            <div className="cancel">
                <div>
                    <span>
                        {t('userSelection.hasSelected')}（{messages.length || 0}）
                    </span>
                </div>
                <div
                    onClick={cancelSelectMore}
                    clstag="pageclick|keycount|Xtbg_Msg|InfoCancelMulti"
                >
                    <span>{t('cancelText')}</span>
                    <span>
                        <IconFont className="icon" type="iconapp_btn_file_cancel" />
                    </span>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(DestroyMessage);
