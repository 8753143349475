import React, { useEffect, useState } from 'react';
import log from '@/utils/logger';
import './index.less';
import App from './App';
import Bus from '@/utils/bus';

log.info('jdmeeting-web');

import { connect } from 'dvajs';

function MeetingJDHome(props: any) {
    console.log('JDHome-props:', props);
    const [isCleanProps, setIsCleanProps] = useState(false);
    const {
        isCalendarJoinMeeting,
        isQuickJoinMeeting,
        calendarJoinData,
        quickJoinData,
        routerData,
    } = props;
    useEffect(() => {
        console.log('切换到会议入口页', isCalendarJoinMeeting, calendarJoinData);
        console.log('路径参数：', routerData);
        function handleCleanProps(data: any) {
            console.log('kkk----', data);
            setIsCleanProps(data);
        }
        if (isCalendarJoinMeeting) {
            Bus.emit('jdmeeting:join-from-calendar', calendarJoinData);
        }
        if (isQuickJoinMeeting) {
            Bus.emit('jdmeeting:join-from-quickLink', quickJoinData);
        }
    }, [isCalendarJoinMeeting, isQuickJoinMeeting, calendarJoinData, quickJoinData, routerData]);

    return (
        <div className="jdmeeting-wrapper">
            {/* <App routerData={isCleanProps ? null : routerData} /> */}
            <App routerData={routerData ? routerData : null} />
        </div>
    );
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch: any) {
    return {
        openCreateScheduleModal(data: any) {
            dispatch({ type: 'calendar/openCreateModal', payload: data });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingJDHome);
