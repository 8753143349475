/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { LoginFormContext, LoginFormContextType } from '../utils';
import { Btn } from '../comps';
import { IconArrowLeft } from '@/components/icon';
import GlobalContext, { GlobalContextConfig } from '@/context/GlobalContext';
import { AuthDataRes } from '@/types/LoginPage';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import './index.less';
// @ts-ignore
import { JSEncrypt } from 'jsencrypt';

const { REACT_APP_DEFAULT_COUNTRY_CODE: DEFAULT_COUNTRY_CODE = 86 } = process.env;
const countryCode = DEFAULT_COUNTRY_CODE;

export default function VerifyPwdForm({
    onSuccess,
    onNewUser,
    onNewTeam,
    onSendVerify,
    onSendResetVerify,
}: {
    onSuccess: (authData: AuthDataRes) => void;
    onNewUser: (authCode: string) => void;
    onNewTeam: (authData: AuthDataRes) => void;
    onSendVerify: () => void;
    onSendResetVerify: () => void;
}) {
    const { t } = useTranslation('common');
    const { onGlobalError: onLoginError, service } = useContext<GlobalContextConfig>(GlobalContext);
    const {
        mobile: receive,
        loginName,
        loginMode,
        loginType,
        teamId,
        onMobileChange,
        navigate,
        accountInfo,
    } = React.useContext<LoginFormContextType>(LoginFormContext);
    const [verifyFailed, setVerifyFailed] = React.useState(false);
    const [failedTooMnyTimes, setFailedTooMnyTimes] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const checkPwdCb = React.useCallback(async () => {
        try {
            if (!password) return;
            setVerifyFailed(false);
            setFailedTooMnyTimes(false);
            setLoading(true);
            const res: { focus_public_key: string } = await service.focusService.commonConfig();
            const { focus_public_key } = res;
            const encrypt = new JSEncrypt();
            encrypt.setPublicKey(focus_public_key);
            const rsaPwd = encrypt.encrypt(password);
            if (loginMode === 'vip') {
                const content = await service.authService.appExclusiveLogin({
                    loginName: loginName || receive,
                    loginTicket: rsaPwd,
                    loginTeamId: teamId,
                    loginNameType: loginType,
                    loginType: 'PASSWORD',
                });
                onSuccess(content);
            } else {
                const content = await service.authService.appLoginByPwd({
                    loginName: loginName || receive,
                    password: rsaPwd,
                    receiveType: loginType,
                });
                if (content.loginStatus === 'NEW_USER') {
                    setLoading(false);
                    onNewUser(content.authCode);
                    return;
                }
                if (content.loginStatus === 'NEW_TEAM') {
                    setLoading(false);
                    onNewTeam(content as any);
                    return;
                }
                onSuccess(content);
            }
            setLoading(false);
        } catch (error) {
            if (error.code === '10160036') {
                console.log(error.msg);
                setVerifyFailed(true);
                setPassword('');
                setLoading(false);
                return;
            } else if (error.code === '10160035') {
                console.log(error.msg);
                setFailedTooMnyTimes(true);
                setPassword('');
                setLoading(false);
                return;
            }
            // onLoginError(error);
        }
    }, [
        password,
        loginName,
        loginType,
        loginMode,
        teamId,
        onNewTeam,
        onNewUser,
        onSuccess,
        // onLoginError,
        receive,
        service.focusService,
        service.authService,
    ]);

    return (
        <div className="mobileForm">
            <div
                style={{
                    margin: '10px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                <div
                    className="hover-color"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setPassword('');
                        onMobileChange('');
                        setTimeout(() => {
                            navigate('step1');
                        }, 0);
                    }}
                >
                    {IconArrowLeft}
                    <span
                        style={{
                            verticalAlign: 'middle',
                            marginLeft: 5,
                        }}
                    >
                        {t('login.back')}
                    </span>
                </div>
            </div>
            <h4 style={{ margin: 0 }}>{t('login.inputVerifyPwd')}</h4>
            <div style={{ paddingBottom: 52 }}>{t('login.veryifyPwdDesc')}</div>
            <div
                style={{
                    paddingBottom: verifyFailed || failedTooMnyTimes ? 10 : 44,
                }}
            >
                <Input.Password
                    placeholder={t('login.inputPwdPl')}
                    value={password}
                    onKeyDown={(e: any) => {
                        const { keyCode } = e;
                        if (keyCode === 13) {
                            checkPwdCb();
                        }
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mobileInput"
                    style={{ width: '100%' }}
                    autoFocus
                />
            </div>
            {(verifyFailed || failedTooMnyTimes) && (
                <div
                    style={{
                        paddingBottom: 44,
                    }}
                    className="verifyFailedDesc"
                >
                    {verifyFailed ? t('login.pwdFailed') : t('login.pwdFailedTooMnyTimes')}
                </div>
            )}
            <div
                style={{
                    paddingBottom: 44,
                }}
            >
                <Btn
                    text={t('nextStep')}
                    loading={loading}
                    disabled={!password}
                    onClick={() => checkPwdCb()}
                />
            </div>
            {(accountInfo.hasMobile || accountInfo.hasEmail) && (
                <div className="verifyPwdFooter">
                    <a onClick={() => onSendResetVerify()}>{t('login.forgetPwd')}</a>
                    <span>
                        <a onClick={() => onSendVerify()}>{t('login.verifyCodeLogin')}</a>
                    </span>
                </div>
            )}
        </div>
    );
}
