import { Popover, message as toast } from 'antd';
import React, { Component, ReactChild, useCallback, useContext, useEffect } from 'react';
import CopyClipboard from 'copy-to-clipboard';
import './index.less';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
interface IState {
    copyVisible: boolean;
    pasteVisible: boolean;
    copyText: string;
}
class CopyFrame extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            copyVisible: false,
            pasteVisible: false,
            copyText: '',
        };
    }
    // 关闭复制粘贴div
    closeContextMenu() {
        this.setState({
            copyVisible: false,
            pasteVisible: false,
            copyText: '',
        });
    }

    onContextMenu(e: any) {
        e.preventDefault();
        e.stopPropagation();
        const clientX = e.clientX;
        const clientY = e.clientY;
        const copyText = window.getSelection()?.toString();
        this.setState(
            {
                copyVisible: copyText ? true : false,
                pasteVisible: e.currentTarget?.querySelectorAll('input, textarea').length
                    ? true
                    : false,
                copyText: copyText || '',
            },
            () => {
                const dom: any = document.getElementsByClassName('frame-copy-paste-btn')[0] || null;
                if (dom) {
                    dom.style.top = clientY + 'px';
                    dom.style.left = clientX + 'px';
                }
            }
        );
    }
    // 复制
    doCopy() {
        const copyText = this.state.copyText;
        if (copyText && CopyClipboard(copyText)) {
            toast.success('复制成功！');
        } else {
            toast.error('复制失败！');
        }
        this.closeContextMenu();
    }
    // 粘贴
    doPaste(e: any) {
        e.stopPropagation();
        focus();
        document.execCommand('paste');
        this.closeContextMenu();
    }
    // 鼠标左键
    doMouseDown(e: any) {
        e.stopPropagation();
        if (e.button === 0) {
            this.closeContextMenu();
        }
    }
    render() {
        const child = (this.props.children as ReactChild)
            ? (this.props.children as ReactChild)
            : null;
        return (
            <span onMouseLeave={this.closeContextMenu.bind(this)}>
                {(this.state.copyVisible || this.state.pasteVisible) && (
                    <div className="frame-copy-paste-btn">
                        {this.state.copyVisible && (
                            <span className="frame-copy-btn" onClick={this.doCopy.bind(this)}>
                                复制
                            </span>
                        )}
                        {this.state.pasteVisible && (
                            <span className="frame-paste-btn" onClick={this.doPaste.bind(this)}>
                                粘贴
                            </span>
                        )}
                    </div>
                )}
                <span
                    onContextMenu={this.onContextMenu.bind(this)}
                    onMouseDown={this.doMouseDown.bind(this)}
                >
                    {child as any}
                </span>
            </span>
        );
    }
}

export default CopyFrame;
