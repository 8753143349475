import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, Modal, message } from 'antd';
import './index.less';
import { useTranslation } from 'react-i18next';
import GlobalContext from '@/context/GlobalContext';
import bus from '@/utils/bus';
import { FocusSDK } from '@/utils';
import { openUserSelectionModal } from '@/utils/modals';
import _ from 'lodash';
import { getUserSimple } from '@/api/meeting';
import IconFont from '@/components/icon';
import lodashCompact from 'lodash/compact';
import { createCloudLinkCall } from '../utils';
import Terminal from '@/components/MeetingCloudLink/Invites/Terminals';
import NPCList from '@/components/MeetingCloudLink/Invites/NPC';
import { deleteEmojiStr } from '@/components/chat/message/Input/Emoji';
import { analysisLog } from '@/utils/logAnalytics';

interface IState {
    audioDisable: boolean;
    videoDisable: boolean;
    userList: {
        realName: string;
        teamId: string;
        userId: string;
        appId: string;
        avatar: string;
    }[];
}

export default function CreateMeetingModal() {
    const [showPass, setShowPass] = useState(false);
    const [visible, setVisible] = useState(false);
    const [meetingTopic, setMeetingTopic] = useState('');
    const [pass, setPass] = useState('');
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const [npcData, setNpcData] = useState<any>({});
    const [terminalData, setTerminalData] = useState([]);
    const [connect, setConnect] = useState(false); // 会议连接状态 在按钮上显示
    const { authInfo, imService } = useContext(GlobalContext);
    const { selectedTeamId, teamUserInfo, userId } = authInfo;
    let teamId = teamUserInfo?.teamId || '';
    const [t_common] = useTranslation('common');
    const [t_chat] = useTranslation('chat');

    const [ownerInfo, setOwnerInfo] = useState<{
        deptName: string; // 部门
        superiorDeptName: string | null; // 次级部门
        titleName: string | null; // 岗位名称
        userName: string;
    } | null>(null);

    const [state, setState] = useState<IState>({
        audioDisable: true,
        videoDisable: true,
        userList: [],
    });
    const inputRef = React.useRef<Input | null>(null);
    useEffect(() => {
        function handleModalVisible(data: any = {}) {
            const { list = [] } = data;
            const { realName, ddAppId, headImg } = teamUserInfo;

            const user = {
                realName,
                userId,
                teamId: teamUserInfo?.teamId,
                appId: ddAppId,
                avatar: headImg,
            };
            if (list.length === 0) {
                setState((s: any) => ({
                    ...s,
                    userList: [user],
                }));
            } else {
                setState((s: any) => ({
                    ...s,
                    userList: list,
                }));
            }
            setVisible(true);
            (async () => {
                const [result, err] = await getUserSimple({ userId, teamId, appId: ddAppId });
                if (err || !result) {
                    return;
                }
                setOwnerInfo(result);
            })();
        }
        FocusSDK.on('cloudlink:create-show', handleModalVisible);
        bus.on('cloudlink:create-show', handleModalVisible);
        return () => {
            FocusSDK.off('cloudlink:create-show', handleModalVisible);
            bus.off('cloudlink:create-show', handleModalVisible);
        };
    }, [teamId, teamUserInfo, userId]);

    function closeModal() {
        setMeetingTopic('');
        setPass('');
        setVisible(false);
        setShowPass(false);
        setConnect(false);
        setTerminalData([]);
        setNpcData({});
        setState({
            videoDisable: true,
            audioDisable: true,
            userList: [],
        });
    }

    let messageShow = false;
    const showValidate = _.debounce(function () {
        if (messageShow) return;
        message.error(t_common('joinMeeting.Exceed the word limit'), undefined, () => {
            messageShow = false;
        });
        messageShow = true;
    }, 200);

    function onMeetingTopicChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (value.length > 30) {
            showValidate();
            return;
        }
        setMeetingTopic(deleteEmojiStr(value));
    }

    async function handleCreateMeeting() {
        setConnect(true);
        createCloudLinkCall({
            toUsers: state.userList.map((item: any) => {
                return {
                    userId: item.userId,
                    appId: item.appId,
                    teamId: item.teamId,
                    userName: item.realName,
                    avatar: item.avatar,
                };
            }),
            terminals: {
                terminalId: terminalData,
                room: npcData,
            },
            opts: {
                meetingTopic,
                isaudiooff: state.audioDisable,
                isvideooff: state.videoDisable,
                meetingPassword: pass,
            },
        })
            .then(() => {
                setConnect(false);
                closeModal();
            })
            .catch((err) => {
                setConnect(false);
                message.error(err.message || err || '创建会议失败');
            });
    }

    const isValidMeeting = () => {
        return (
            meetingTopic && state.userList.length > 0 && (pass.length === 0 || pass.length === 6)
        );
    };
    function onMeetingPassChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (connect) {
            return;
        }
        if (!/^\d{0,6}$/.test(value)) {
            return;
        }
        setPass(value);
    }
    function handlePassBlur() {
        if (!pass) {
            setShowPass(false);
        }
    }
    function onNpcChange(val: any) {
        setNpcData(val);
    }
    const onTerminalChange = function (val: any) {
        setTerminalData(val);
    };

    const passComp = showPass ? (
        <Input
            value={pass}
            placeholder="输入密码"
            autoFocus
            onChange={onMeetingPassChange}
            onBlur={handlePassBlur}
        />
    ) : (
        <div
            className="input-label"
            onClick={() => {
                setShowPass(true);
            }}
        >
            <IconFont style={{ marginRight: 5 }} type="iconic_editor" />
            设置密码
        </div>
    );

    function handleAddMember() {
        const options: any = {
            frozenIds: [],
            customTab: {
                tabName: '人大会议',
                dataList: [],
                renderer: () => {
                    return <NPCList onChange={onNpcChange} />;
                },
            },
            customTab2: {
                tabName: '会议终端',
                dataList: [],
                renderer: () => {
                    return (
                        <Terminal onSelectChange={onTerminalChange} initVal={selectedTerminals} />
                    );
                },
            },
        };

        const userInfo: any = {
            id: `${userId}:${teamUserInfo.ddAppId}:${teamUserInfo.teamId}`,
            avatar: teamUserInfo.headImg,
            name: teamUserInfo.realName,
            app: teamUserInfo.ddAppId,
            userId,
            teamId: teamUserInfo.teamId,
            pin: userId,
        };
        const members = lodashCompact(state.userList || []).map((l: any) => ({
            id: `${l.userId}:${l.appId}:${l.teamId}`,
            name: l.realName,
            avatar: l.headImg,
            checked: true,
            userId: l.userId,
            teamId: l.teamId,
            visible: true,
        }));
        options.title = t_chat('add_member');
        options.resultUsers = members;
        options.frozenIds = [userInfo.id];
        options.currentUser = {
            app: userInfo?.app,
            pin: userInfo?.pin,
            teamId: userInfo?.teamId,
        };
        options.tabs = ['org', 'group', 'custom2'];
        openUserSelectionModal(
            options,
            (data, close) => {
                try {
                    const { result } = data.data;
                    if (result.length) {
                        setState((s) => {
                            return {
                                ...s,
                                userList: result.map((r) => {
                                    const tem = r.id.split(':');
                                    return {
                                        realName: r.name,
                                        userId: tem[0],
                                        teamId: tem[2],
                                        appId: tem[1],
                                        avatar: r.avatar as string,
                                    };
                                }),
                            };
                        });
                    }

                    close();
                } catch (error) {
                    if (close) {
                        close();
                    }
                }
            },
            authInfo as any
        );
    }

    const handleMenuClick = (key: string) => {
        if (connect) {
            return;
        }
        if (key === 'audio') {
            setState((data: any) => {
                return {
                    ...data,
                    audioDisable: !data.audioDisable,
                };
            });
        }
        if (key === 'video') {
            setState((data: any) => {
                return {
                    ...data,
                    videoDisable: !data.videoDisable,
                };
            });
        }
        if (key === 'addUser') {
            handleAddMember();
        }
    };

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    function onCancel() {
        if (connect) {
            return;
        }
        analysisLog('xtbg_PCHMeeting', 'Create_Cancel');
        closeModal();
    }
    return (
        <Modal
            className="desk-me-join-meeting-modal desk-me-create-meeting-modal"
            width={780}
            visible={visible}
            onCancel={onCancel}
            bodyStyle={{
                padding: 0,
            }}
            footer={null}
            destroyOnClose
        >
            <div className="me-join-meeting-body">
                <div className="me-join-meeting-form">
                    <div className="me-join-meeting-input">
                        <Input
                            type="text"
                            value={meetingTopic}
                            ref={inputRef}
                            autoFocus
                            placeholder={t_common(
                                'joinMeeting.Please enter the topic of the meeting'
                            )}
                            onPressEnter={() => {
                                if (isValidMeeting() && !connect) {
                                    handleCreateMeeting();
                                }
                            }}
                            onChange={onMeetingTopicChange}
                        />
                    </div>
                    <div className="me-join-meeting-input">{passComp}</div>
                </div>
                <div className="me-join-meeting-actions">
                    <div className="me-join-meeting-menu">
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCHMeeting|Create_Voice"
                                className={`me-join-meeting-menu-item ${
                                    state.audioDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('audio')}
                            >
                                {state.audioDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_voice_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_voice" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.audioDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCHMeeting|Create_Video"
                                className={`me-join-meeting-menu-item ${
                                    state.videoDisable ? 'me-join-meeting-menu-item-disable' : ''
                                }`}
                                onClick={() => handleMenuClick('video')}
                            >
                                {state.videoDisable ? (
                                    <IconFont type="iconapp_btn_joymeeting_video_shut" />
                                ) : (
                                    <IconFont type="iconapp_btn_joymeeting_video" />
                                )}
                            </span>
                            <span className="label">
                                {t_common(
                                    state.videoDisable ? 'joinMeeting.closed' : 'joinMeeting.open'
                                )}
                            </span>
                        </div>
                        <div className="me-join-meeting-menu-container">
                            <span
                                clstag="pageclick|keycount|xtbg_PCHMeeting|Create_Invite"
                                className="me-join-meeting-menu-item me-join-meeting-menu-addUser"
                                onClick={() => handleMenuClick('addUser')}
                            >
                                <IconFont type="iconapp_btn_addcontacts" />
                            </span>
                            <span className="label">
                                {t_common('joinMeeting.%s person to attend').replace(
                                    '%s',
                                    state.userList.length.toString()
                                )}
                            </span>
                        </div>
                    </div>
                    <Button
                        disabled={!isValidMeeting()}
                        style={{ opacity: isValidMeeting() ? 1 : 0.5, width: 232 }}
                        type="primary"
                        onClick={handleCreateMeeting}
                        clstag="pageclick|keycount|xtbg_PCHMeeting|Create_New"
                        loading={connect}
                        className="me-join-meeting-start-button"
                    >
                        {t_common('joinMeeting.Start the meeting')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
