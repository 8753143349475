import { IPageAddCallback, api } from './index';
import { message } from 'antd';
import { lang } from '../context';
import events from '../context/events';
import { isBjme } from '@/utils';
import { FileType } from '../types';

const collect = (props: any, isAllow: any) => {
    const { data, setData } = props;
    let title;
    if (isBjme()) {
        title = data.isCollected ? '取消收藏' : '收藏';
    }
    let type = data.page_type ? FileType.Page : FileType.Folder;
    // else {
    //     title = data.isCollected
    //         ? lang('ctx.shortcut.removeTitle')
    //         : lang('ctx.shortcut.addTitle');
    // }
    const callback = () => {
        events.emit('shortcut.change');
        // reload();
        const newData = {
            ...data,
            isCollected: !data.isCollected,
        };
        events.emit('list.reload');
        events.emit('list.removeOrSetData', [newData], 'setData');

        message.success(lang('common.success'));
    };
    return [
        {
            children: title,
            splitCode: 1,
            icon: data.isCollected ? 'pc_yundocument_inshare' : 'pc_yundocument_enshrine',
            callbackOrModal: () => {
                if (data.isCollected) {
                    api.collections.remove(data.id, type).then(() => {
                        callback();
                    });
                } else {
                    api.collections.add(data.id, type).then(() => {
                        callback();
                    });
                }
            },
            isAllow,
            type: 'collect',
        },
    ];
};

export default collect;
