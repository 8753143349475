import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Button, Input, message } from 'antd';
import events from '../../context/events';
import {
    joyspacePermissionType,
    getJoySpaceEditPermission,
    getJoySpaceReadOnlyPermission,
    folderTypeEnum,
} from '../../config/permissions.config';
import openEditUserPermissionModal from '../../contextModal/EditUserPermission';
import './index.less';
import ReactDOM from 'react-dom';
import { OpenFolderStatus, FileType } from '../../types';
import addMembersModal from '../AddMembers';

interface IProps {
    id: string;
    isModalVisible: boolean;
    isRoot: boolean;
    showManageBtn?: boolean;
    permissionsType: joyspacePermissionType;
    name: string; // 文件名称
    title: string; // 弹窗标题
    description?: string;
    backgroundColor?: string;
    folderType?: folderTypeEnum;
    isOpenFolder?: OpenFolderStatus;
    onOk: any;
    removeNode?: () => void;
}

interface FormInfo {
    name: string;
    description?: string;
}

interface Attrs {
    title: string;
    isRoot: boolean;
    permissionsType: joyspacePermissionType;
    onOk?: (name: string, desc?: string) => {};
}

function SetFolderModal(ope: IProps) {
    const {
        id = '',
        title = '',
        name = '',
        isRoot = false,
        showManageBtn = true,
        permissionsType = getJoySpaceReadOnlyPermission(),
        description = '',
        backgroundColor = '#1890ff',
        isModalVisible = false,
        isOpenFolder = OpenFolderStatus.CLOSE,
        onOk,
        removeNode,
        folderType = folderTypeEnum.MY,
    } = ope;
    const [_isModalVisible, setModalVisible] = useState<boolean>(isModalVisible);
    const [formInfo, setFormInfo] = useState<FormInfo>({ name, description });
    const [loading, setLoading] = useState<boolean>(false);
    const [attrs, setAttrs] = useState<Attrs>({
        title,
        isRoot,
        permissionsType,
        onOk,
    });
    // 提交表单且数据验证成功后回调事件
    const onFinish = async () => {
        setLoading(true);
        attrs.onOk && (await attrs.onOk(formInfo.name, formInfo.description));
        setLoading(false);
        onCancel();
    };
    // 提交表单且数据验证失败后回调事件
    const onFinishFailed = (err: any) => {
        // console.log(err);
    };
    // 打开协同人管理的弹窗
    const openAddMembersModal = () => {
        addMembersModal({
            id,
            type: FileType.Folder,
            permissionType: attrs.permissionsType,
            // isVisibleModal: true,
            folderType: folderType,
            // isOpenFolder,
        });
    };
    // 关闭弹窗
    const onCancel = () => {
        setModalVisible(false);
        removeNode?.();
    };
    // 底部按钮
    const Footer = () => {
        return (
            <div className="folder-footer">
                <div>
                    {/* <span className="folder-footer-management">
                        共<span className="folder-footer-total">6</span>协作者
                    </span> */}
                    {folderType === folderTypeEnum.SHARE &&
                        showManageBtn &&
                        getJoySpaceEditPermission(attrs.permissionsType) && (
                            <span className="folder-management-btn" onClick={openAddMembersModal}>
                                管理
                            </span>
                        )}
                </div>
                <div>
                    <Button onClick={onCancel}>取消</Button>
                    <Button
                        type="primary"
                        loading={loading}
                        disabled={
                            formInfo.name.length === 0 ||
                            !getJoySpaceEditPermission(attrs.permissionsType)
                        }
                        onClick={onFinish}
                    >
                        确定
                    </Button>
                </div>
            </div>
        );
    };
    return (
        <Modal
            title={attrs.title || '文件夹设置'}
            width={480}
            onCancel={() => {
                onCancel();
            }}
            visible={_isModalVisible}
            footer={Footer()}
            className="folder-container"
        >
            <div className="form-item">
                <div className="form-item-label">
                    <span>*</span>
                    <span>名称</span>
                </div>
                <div className="form-item-input">
                    <Input
                        type="text"
                        placeholder="请输入文件夹名称"
                        disabled={!getJoySpaceEditPermission(attrs.permissionsType)}
                        value={formInfo.name || undefined}
                        maxLength={20}
                        onChange={(event) => {
                            setFormInfo({
                                name: event.target.value,
                                description: formInfo.description,
                            });
                        }}
                    />
                </div>
            </div>
            <p className="form-item-length" style={{ marginBottom: 20 }}>
                {formInfo.name.length}/20
            </p>
            {/* 只有根级目录才有描述 */}
            {attrs.isRoot && (
                <>
                    <div className="form-item">
                        <div className="form-item-label">
                            <span style={{ opacity: '0' }}>*</span>
                            <span>描述</span>
                        </div>
                        <div className="form-item-input">
                            <Input
                                type="text"
                                placeholder="添加描述"
                                value={formInfo.description}
                                maxLength={50}
                                disabled={!getJoySpaceEditPermission(attrs.permissionsType)}
                                onChange={(event) => {
                                    setFormInfo({
                                        name: formInfo.name,
                                        description: event.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <p className="form-item-length">{formInfo.description?.length || 0}/50</p>
                </>
            )}
            {/* <Form
                name={form}
                // validateMessages={validateMessages}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="名称"
                    name="title"
                    rules={[{ required: true, max: 20, message: '' }]}
                >
                    <Input maxLength={20} />
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="description"
                    rules={[{ required: false, message: '请输入描述' }]}
                >
                    <Input maxLength={50} />
                </Form.Item>
            </Form> */}
        </Modal>
    );
}

export default function openFolderModal(props: IProps) {
    const ele = document.createElement('div');
    document.body.appendChild(ele);
    function removeNode() {
        if (ele) {
            ReactDOM.unmountComponentAtNode(ele);
            ele.remove();
        }
    }
    ReactDOM.render(<SetFolderModal {...{ ...props, removeNode }} />, ele);
}
