import { isFocusEnv } from '@/utils';
import EXIF from 'exif-js';
import { lookup } from 'mime-types';
import heic2any from 'heic2any';
import chatConfig from '@/components/chat/config';

export function getExifOfImage(file: any) {
    return new Promise((resolve) => {
        EXIF.getData(file, function () {
            // console.log('getAllTags', EXIF.getAllTags(file));
            const orientation = EXIF.getTag(file, 'Orientation');
            resolve(orientation);
            return;
        });
    });
}

export function getImgData(img: any, dir: any, next: any) {
    let image = new Image();
    image.onload = function () {
        let degree = 0;
        let drawWidth;
        let drawHeight;
        let width;
        let height;
        drawWidth = image.naturalWidth;
        drawHeight = image.naturalHeight;
        // 以下改变一下图片大小
        let maxSide = Math.max(drawWidth, drawHeight);
        if (maxSide > 1024) {
            let minSide = Math.min(drawWidth, drawHeight);
            minSide = (minSide / maxSide) * 1024;
            maxSide = 1024;
            if (drawWidth > drawHeight) {
                drawWidth = maxSide;
                drawHeight = minSide;
            } else {
                drawWidth = minSide;
                drawHeight = maxSide;
            }
        }
        let canvas = document.createElement('canvas');
        canvas.width = width = drawWidth;
        canvas.height = height = drawHeight;
        let context = canvas.getContext('2d');
        // 判断图片方向，重置canvas大小，确定旋转角度，iphone默认的是home键在右方的横屏拍摄方式
        switch (dir) {
            // iphone横屏拍摄，此时home键在左侧
            case 3:
                degree = 180;
                drawWidth = -width;
                drawHeight = -height;
                break;
            // iphone竖屏拍摄，此时home键在下方(正常拿手机的方向)
            case 6:
                canvas.width = height;
                canvas.height = width;
                degree = 90;
                drawWidth = width;
                drawHeight = -height;
                break;
            // iphone竖屏拍摄，此时home键在上方
            case 8:
                canvas.width = height;
                canvas.height = width;
                degree = 270;
                drawWidth = -width;
                drawHeight = height;
                break;
        }
        // 使用canvas旋转校正
        context?.rotate((degree * Math.PI) / 180);
        context?.drawImage(image, 0, 0, drawWidth, drawHeight);
        // 返回校正图片
        next(canvas.toDataURL('image/png'));
    };
    image.src = img;
}

export function dataURLtoFile(dataurl: any, filename: string) {
    // 获取到base64编码
    const arr = dataurl.split(',');
    // 将base64编码转为字符串
    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n); // 创建初始化为0的，包含length个元素的无符号整型数组
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
        type: 'image/png',
    });
}

// 将base64转换为blob
export function dataURLtoBlob(dataurl: any) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

// 将blob转换为file
export function blobToFile(theBlob: any, fileName: string) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export function dealImagesData(file: any): Promise<any> {
    return new Promise((resolve) => {
        getExifOfImage(file).then((res) => {
            const orientation = res;
            // console.log('orientation', orientation);
            const reader = new FileReader();
            const blob: any = file.getAsFile ? file.getAsFile() : file;
            // console.log('bolb', blob);
            reader.onload = (evt) => {
                if (!evt.target) return;
                const mimeType = lookup(blob.name) as string;
                if (
                    (typeof mimeType === 'string' && mimeType.includes('image/gif')) ||
                    !orientation ||
                    orientation === 1 ||
                    !isFocusEnv()
                ) {
                    const dataUrl: any = evt.target.result;
                    const img = new Image();
                    img.src = dataUrl;
                    img.onload = () => {
                        const imgState = {
                            title: blob.name,
                            base64: dataUrl,
                            width: img.width,
                            height: img.height,
                            size: blob.size,
                        };
                        resolve({ imgState, file });
                    };
                    img.onerror = () => {
                        console.log('图片加载失败', file);
                    };
                } else {
                    getImgData(evt.target.result, orientation, function (data: any) {
                        const newfile = dataURLtoFile(data, blob.name);
                        const img = new Image();
                        img.src = data;
                        img.onload = () => {
                            const imgState = {
                                title: blob.name,
                                base64: data,
                                width: img.width,
                                height: img.height,
                                size: blob.size,
                            };
                            resolve({ imgState, file: newfile });
                        };
                        img.onerror = () => {
                            console.log('图片加载失败', file);
                        };
                    });
                }
            };
            if (blob instanceof Blob) {
                reader.readAsDataURL(blob);
            }
        });
    });
}

export function filterImages(file: any): Promise<Boolean> {
    return new Promise((resolve) => {
        if (file.type) {
            if (!file.type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)) {
                resolve(false);
                return;
            }
        } else {
            let fileName = file?.name;
            if (fileName) {
                let fileType = lookup(fileName) || '';
                if (!fileType) {
                    resolve(false);
                    return;
                } else if (
                    !fileType.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)
                ) {
                    resolve(false);
                    return;
                }
            }
        }
        if (file.size >= chatConfig['max-image-size']) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    });
}

export function filterDirectory(file: any, hasItems: boolean): Promise<Boolean> {
    return new Promise((resolve) => {
        if (hasItems) {
            // console.log('file', file);
            if (file.kind === 'file' && file.webkitGetAsEntry()?.isFile) {
                // console.log('drag file true');
                resolve(true);
                return;
            } else {
                // console.log('drag file false');
                resolve(false);
                return;
            }
        } else {
            if (file.type) {
                resolve(true);
                return;
            } else {
                try {
                    let fileName = file?.name;
                    if (fileName) {
                        let fileType = lookup(fileName);
                        // console.log('is file', fileType, file.size);
                        if (!fileType && !file.size) {
                            resolve(false);
                            return;
                        }
                    }
                    const reader = new FileReader();
                    reader.readAsDataURL(file?.slice(0, 3));
                    reader.onload = function (e) {
                        // console.log('is file true');
                        resolve(true);
                    };
                    reader.onerror = function (e) {
                        console.log(e, 'onerror，不可以上传文件夹');
                        resolve(false);
                    };
                    return;
                } catch (e) {
                    console.log(e, 'catch error，不可以上传文件夹');
                    resolve(false);
                    return;
                }
            }
        }
    });
}

export function imageMin(file: any, compressSize?: number): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        try {
            // eslint-disable-next-line
            const imageCompression = require('browser-image-compression').default;
            const compressedFile = await imageCompression(file, {
                maxSizeMB: compressSize || 1,
                maxWidthOrHeight: 1024,
                useWebWorker: false,
            });
            // console.log('compressedFile', compressedFile);
            // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
            const reader = new FileReader();
            reader.onload = (evt) => {
                if (!evt.target) return;
                const dataUrl: any = evt.target.result;
                const newfile = dataURLtoFile(dataUrl, compressedFile.name);
                const img = new Image();
                img.src = dataUrl;
                img.onload = () => {
                    const imgState = {
                        visible: true,
                        title: newfile.name,
                        base64: dataUrl,
                        width: img.width,
                        height: img.height,
                        size: newfile.size,
                    };
                    // console.log('compressedFile imgState', imgState);
                    // console.log('compressedFile newFile', newfile);
                    resolve({ imgState, file: newfile });
                };
            };
            if (compressedFile instanceof Blob) {
                reader.readAsDataURL(compressedFile);
            }
        } catch (error) {
            reject(error);
        }
    });
}

// 处理heic图像格式
export function formatFileType(files: any): Promise<any> {
    const finalFiles = files.map(async (file: any) => {
        // let tempFile = file;
        // const isHeic = await isHeicImage(tempFile);
        // if (isHeic) {
        //     tempFile = await heicToPng(tempFile);
        // }
        // console.log('tempFile', tempFile);
        // return tempFile;
        return file;
    });
    return Promise.all(finalFiles);
}

export function getNewNameOfHeic(name: string) {
    const idx = name.lastIndexOf('.');
    return name.slice(0, idx) + `.png`;
}

export function isHeicImage(file: any) {
    return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = async (evt) => {
            if (!evt.target) return;
            const result: any = evt.target.result;
            const bufferInt = new Uint8Array(result);
            const headerArr = bufferInt.slice(0, 16);
            let allHeader = '';
            for (let item of headerArr) {
                allHeader += item.toString(16);
            }
            // console.log('allHeader', headerArr, allHeader);
            if (!(allHeader.startsWith('000') && allHeader.endsWith('000'))) {
                resolve(false);
                return;
            } else {
                if (
                    allHeader.lastIndexOf('68656963') === 13 ||
                    allHeader.lastIndexOf('68656966') === 13
                ) {
                    if (file.size >= chatConfig['max-image-size']) {
                        resolve(false);
                        return;
                    } else {
                        // 满足heic图像格式且需要按照图片方式发送
                        resolve(true);
                        return;
                    }
                } else {
                    resolve(false);
                    return;
                }
            }
        };
        fileReader.readAsArrayBuffer(file);
    });
}

export function heicToPng(file: any): Promise<any> {
    return new Promise((resolve) => {
        const fileReaderBuffer = new FileReader();
        heic2any({
            blob: file,
            toType: 'image/png',
        }).then((result: any) => {
            fileReaderBuffer.onload = async (e) => {
                if (!e.target) return;
                // console.log('e.target.result', e.target.result);
                const newName = getNewNameOfHeic(file.name);
                const tempFile = dataURLtoFile(e.target.result, newName);
                // console.log('tempFile', tempFile, file.size);
                if (tempFile.size > file.size) {
                    const data = await imageMin(
                        tempFile,
                        Number((file.size / 1024 / 1024).toFixed(2))
                    );
                    // console.log('data.file', data.file);
                    resolve(data.file);
                    return;
                } else {
                    resolve(tempFile);
                    return;
                }
            };
            fileReaderBuffer.readAsDataURL(result);
        });
    });
}

// 根据判断是不是gif图片
export function isGifImage(url: string) {
    if (!url) return false;
    const [partI, partII] = url.split('?');
    const ext = partI.substring(partI.lastIndexOf('.') + 1);
    return ext === 'gif';
}
