import GlobalContext from '@/context/GlobalContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDevicesInfo, sendCloudLinkCallMessage, setCurrentCloudLinkMeetingInfo } from './utils';
import { FocusSDK } from '@/utils';
import { addParticipants, cloudlinkRegister, updateRecentLoginTime } from '@/api/meeting';
import { message, Modal } from 'antd';
import bus from '@/utils/bus';
import log from '@/utils/logger';

import { NPCRoom } from './Invites/NPC';
import cache from '@/utils/cache';

export enum CloudLinkStatusEnum {
    FREE = 0,
    BUSY = 1,
    IN_MEETING = 2,
}
export enum CloudLinkSessionStateEnum {
    Call = 0, // 被叫
    Cancel = 1, // 呼叫方取消
    Ringing = 180, // 用户振铃 这个是反向发送消息
    UserReceive = 200, // 某个端用户接受了会邀
    Busy = 400, // 用户正忙
    UserReject = 486, // 某个端用户拒绝
    TIMEOUT = 502, // 未接听
}

const isBigScreen = process.env.REACT_APP_X_FLAG === 'olympic';

let cloudLinkStatus = 0;

export function changeCloudLinkStatus(status: CloudLinkStatusEnum) {
    log.debug('changeCloudLinkStatus', status);
    cloudLinkStatus = status;
}

export function getCloudLinkStatus(): CloudLinkStatusEnum {
    return cloudLinkStatus;
}

export default function Control() {
    const { authInfo, imService } = useContext(GlobalContext);
    const [t_common] = useTranslation('common');

    const { teamUserInfo, userId } = authInfo;
    const { ddAppId, teamId } = teamUserInfo;
    const [msg, setMsg] = useState('');
    const [initing, setIniting] = useState(false);
    const [initingTimeout, setInitingTimeout] = useState<any>(null);

    const messageList = ['正在初始化信创会议', '连接中', '正在进入会议', '即将加入会议'];

    // TODO：大屏版，打开应用就初始化信创
    useEffect(() => {
        function login() {
            cloudlinkRegister({
                userIds: [userId],
            }).then(([loginInfo, err]) => {
                if (err) {
                    log.error(err);
                    return;
                }
                function handleLogin(data: any) {
                    if (data.status) {
                        cache.cloudLinkLogin = true;
                    }
                }
                FocusSDK.on('cloudlink:loginCb', handleLogin);

                FocusSDK.sendIpcMessage('cloudlink.login', {
                    loginInfo: loginInfo[0],
                    keepalive: true,
                });
            });
        }
        if (isBigScreen) {
            login();
        }
    }, [userId]);

    useEffect(() => {
        function onSendMeetingMessage({ meetingInfo, toUsers }: any) {
            if (!meetingInfo || !toUsers) return;
            sendCloudLinkCallMessage(meetingInfo, toUsers);
        }
        function handleChangeCloudLinkStatus(status: CloudLinkStatusEnum) {
            cloudLinkStatus = status;
        }

        async function handleAddParticipants(params: {
            conferenceUuid: string;
            terminalId: string[];
            room: NPCRoom;
        }) {
            const [_res, error] = await addParticipants({
                conferenceUuid: params.conferenceUuid.substring(0, 36),
                terminalId: params.terminalId,
                room: params.room,
            });
            if (error) {
                return message.error('添加人大或会议终端失败，请重试');
            }
            message.success('操作成功');
        }

        // 匹配本地记录的设备信息
        async function matChLocalDevice() {
            const localDeviceStr = localStorage.getItem('cloudlink.deviceSelected');
            if (localDeviceStr) {
                try {
                    const localInfo = JSON.parse(localDeviceStr);
                    for (const key of Object.keys(localInfo)) {
                        const deviceType = parseInt(key, 10);
                        const recordValue = localInfo[key];
                        if (recordValue) {
                            // 查到有记录值，去查询设备
                            // 这里必须用await，华为每次只能查询一种设备，否则回调可能出错，比如同时查麦克风和摄像头，返回顺序不一定
                            const data: any = await getDevicesInfo(deviceType);
                            const [list] = data;
                            const matched = list.deviceInfo.find(
                                (item: any) => item.deviceName === recordValue
                            );

                            // 匹配到相同名称的设备，切换到上次设备
                            if (matched) {
                                FocusSDK.sendIpcMessage('cloudlink.setDevicesInfo', {
                                    deviceType,
                                    device: matched,
                                });
                            }
                        }
                    }
                } catch (error) {
                    log.error(error);
                }
            }
        }
        function handleCallEnded(msg: string) {
            setCurrentCloudLinkMeetingInfo(null);
            changeCloudLinkStatus(CloudLinkStatusEnum.BUSY);
            message.warn(msg);
        }

        function successCallback() {
            // 通知服务端，这个用户开会了。
            // 放号相关，prod/pre/还有移动端账号都不相同，总共20w可用，记录活跃账号
            updateRecentLoginTime({
                userIds: [userId],
            });
            changeCloudLinkStatus(CloudLinkStatusEnum.IN_MEETING);
            // 匹配上次使用的音视频输入输出设备名
            matChLocalDevice();
        }

        function handleJoinMeetingCb({
            status,
            data,
            error,
        }: {
            status: number;
            data: any;
            error: string;
        }) {
            setIniting(false);
            if (initingTimeout) {
                clearTimeout(initingTimeout);
                setInitingTimeout(null);
            }
            if (status) {
                setCurrentCloudLinkMeetingInfo(data);
                successCallback();
            } else {
                changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                log.error('会议加入失败', error);
            }
        }

        function handleClose() {
            changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
        }

        function handleInit() {
            bus.emit('cloudlink:init');
        }

        function handleCreateMeetingCb({
            status,
            data,
            error,
        }: {
            status: number;
            data: any;
            error: string;
        }) {
            setIniting(false);
            if (initingTimeout) {
                clearTimeout(initingTimeout);
                setInitingTimeout(null);
            }
            if (status) {
                setCurrentCloudLinkMeetingInfo(data);
                successCallback();
            } else {
                changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                log.error('会议创建失败', error);
            }
        }

        FocusSDK.on('cloudlink:handleMeetingSendMessage', onSendMeetingMessage);
        FocusSDK.on('cloudlink:changeCloudLinkStatus', handleChangeCloudLinkStatus);
        FocusSDK.on('cloudlink:addParticipants', handleAddParticipants);
        FocusSDK.on('cloudlink:joinMeetingCb', handleJoinMeetingCb);
        FocusSDK.on('cloudlink:createMeetingCb', handleCreateMeetingCb);
        FocusSDK.on('cloudlink:close', handleClose);
        FocusSDK.on('cloudlink:callEnded', handleCallEnded);
        FocusSDK.on('cloudlink:init', handleInit);

        return () => {
            FocusSDK.off('cloudlink:handleMeetingSendMessage', onSendMeetingMessage);
            FocusSDK.off('cloudlink:changeCloudLinkStatus', handleChangeCloudLinkStatus);
            FocusSDK.off('cloudlink:addParticipants', handleAddParticipants);
            FocusSDK.off('cloudlink:joinMeetingCb', handleJoinMeetingCb);
            FocusSDK.off('cloudlink:createMeetingCb', handleCreateMeetingCb);
            FocusSDK.off('cloudlink:close', handleClose);
            FocusSDK.off('cloudlink:callEnded', handleCallEnded);
            FocusSDK.off('cloudlink:init', handleInit);
        };
    }, [ddAppId, imService, initingTimeout, t_common, teamId, userId]);

    useEffect(() => {
        const handleInit = () => {
            setIniting(true);
            let currentMsgIndex = 0;
            setMsg(messageList[currentMsgIndex]);
            const timeout = setTimeout(() => {
                currentMsgIndex += 1;
                if (messageList[currentMsgIndex]) {
                    setMsg(messageList[currentMsgIndex]);
                } else {
                    clearTimeout(timeout);
                }
            }, 2000);
            setInitingTimeout(
                setTimeout(() => {
                    changeCloudLinkStatus(CloudLinkStatusEnum.FREE);
                    setIniting(false);
                }, 30000)
            );
        };
        bus.on('cloudlink:init', handleInit);
        return () => {
            bus.off('cloudlink:init', handleInit);
        };
    }, [messageList]);

    return (
        <Modal
            maskClosable={false}
            centered={true}
            closable={false}
            visible={initing}
            footer={null}
            className="cloudlink-init-modal"
        >
            {msg}
        </Modal>
    );
}
