/* eslint-disable max-params */

import React, { useEffect, useState } from 'react';
import { connect } from 'dva';
import ChatState from '@/types/chat/State';

import CHAT_NOTICE, {
    ALL_NOTICE_TAB_LIST,
    UNABLE_EDIT_TABS_PIN,
    UNABLE_SHOW_TABS_PIN,
} from '@/contant/chat';
import { Modal } from 'antd';

import { RecommendedType } from '@/services/AppStore';
import ToolsItem from '../ToolsItem/index';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Employee, Session } from '@/types/chat';

import './index.less';
import { isAINoticeChildSession, isAINoticeSession } from '@/utils/chat/session';
import { filterNoticeMassagesByType } from '@/utils/chat/message';
import { analysisLog } from '@/utils/logAnalytics';

// 设置样式
/* eslint max-params: ["error", 4] */
const getItemStyle = (isDragging: any, draggableStyle: any, isPointer: any, recommended: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    position: 'relative',
    cursor: isPointer && recommended === RecommendedType.RecommendedNo ? 'move' : 'default',
    // styles we need to apply on draggables
    ...draggableStyle,
});

interface IProps {
    visible: boolean;
    handleOk: Function;
    handleCancel: Function;
    selectedTabList: any[];
    isPointer: boolean;
    selectedSession: Session;
    aiNoticeSessionList: Session[];
}

// eslint-disable-next-line complexity
function TabControllerModal(props: IProps) {
    const {
        visible,
        selectedTabList,
        aiNoticeSessionList,
        isPointer,
        handleCancel,
        handleOk,
    } = props;
    const [isDragging, setDragging] = useState(false);
    const [moreTabList, setMoreTabList] = useState<any[]>([]);
    const [mineTabList, setMineTabList] = useState<any[]>([]);

    const onDragEnd = (result: any) => {
        // eslint-disable-next-line no-console
        setDragging(false);
        if (!result.destination) {
            return;
        }
        const { index: startIndex, droppableId: originalId } = result.source;
        const { index: endIndex, droppableId: targetId } = result.destination;
        // 当没有改变排序时，不触发此方法。
        if (startIndex === endIndex && originalId === targetId) {
            return;
        }
        const targetTabListData: any = {
            mine: mineTabList,
            more: moreTabList,
        };
        updateTabList(targetTabListData, originalId, targetId, startIndex, endIndex);
    };

    /**
     * tab移动加减符号点击操作
     * @param originalId 当前点击的tab的droppableId
     * @param pin 当前tab的pin
     */
    const handleToolsItemClick = (originalId: string, pin: string) => {
        const targetTabListData: any = {
            mine: mineTabList,
            more: moreTabList,
        };
        let targetId = originalId === 'mine' ? 'more' : 'mine';
        const index = targetTabListData[originalId].findIndex((item: any) => item.pin === pin);
        updateTabList(
            targetTabListData,
            originalId,
            targetId,
            index,
            targetTabListData[targetId].length
        );
    };

    /**
     * 更新我的组合和更多组合
     * @param targetTabListData 我的分组和更多分组的tabList的对象集合
     * @param originalId 当前操作区域的droppableId
     * @param targetId 目标区域的droppableId
     * @param startIndex 起始位置
     * @param endIndex 移动到的位置
     */
    const updateTabList = (
        targetTabListData: any,
        originalId: string,
        targetId: string,
        startIndex: number,
        endIndex: number
    ) => {
        // 删除原数组中元素
        const [removed] = targetTabListData[originalId].splice(startIndex, 1);
        // 将原来的元素添加进数组
        targetTabListData[targetId].splice(endIndex, 0, removed);
        setMineTabList([...targetTabListData['mine']]);
        setMoreTabList([...targetTabListData['more']]);
    };

    useEffect(() => {
        const moreTabList: any[] = (
            aiNoticeSessionList.filter((item: Session) => {
                return !selectedTabList.find(
                    (selectedTabItem) => (item.info as Employee).userId === selectedTabItem.pin
                );
            }) || []
        ).map((item) => ({
            pin: (item.info as Employee).userId,
            name: (item.info as Employee).name,
        }));
        const mineTabList: any[] = selectedTabList.filter(
            (item) => !UNABLE_SHOW_TABS_PIN.includes(item.pin)
        );
        setMoreTabList(moreTabList);
        setMineTabList(mineTabList);
    }, [selectedTabList, aiNoticeSessionList]);

    const handleConfirm = () => {
        handleOk(mineTabList);
    };

    return (
        <Modal
            visible={visible}
            onOk={handleConfirm}
            onCancel={() => handleCancel()}
            okText="保存"
            cancelText="取消"
            title="标签栏设置"
            width="500px"
            className="controller-modal"
            bodyStyle={{ height: '402px', overflowY: 'auto' }}
        >
            <DragDropContext
                onDragEnd={onDragEnd}
                onBeforeDragStart={() => {
                    analysisLog('Xtbg_Msg_AI', 'Filter_Order');
                    setDragging(true);
                }}
            >
                <div>
                    <Droppable droppableId="mine">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <div>
                                    <h3 className="group-title">我的标签</h3>
                                    <div className="group-content">
                                        {mineTabList.map((item: any, index: number) => (
                                            <Draggable
                                                key={item.pin}
                                                isDragDisabled={UNABLE_EDIT_TABS_PIN.includes(
                                                    item.pin
                                                )}
                                                draggableId={item.pin}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                            isPointer,
                                                            item.recommended
                                                        )}
                                                        className="circular"
                                                    >
                                                        <ToolsItem
                                                            isDragAble={
                                                                !UNABLE_EDIT_TABS_PIN.includes(
                                                                    item.pin
                                                                )
                                                            }
                                                            key={item.pin}
                                                            toolsItem={item}
                                                            isMine={true}
                                                            provided={provided}
                                                            droppableId="mine"
                                                            handleToolsItemClick={
                                                                handleToolsItemClick
                                                            }
                                                            index={index}
                                                            isDragging={snapshot.isDragging}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <Droppable droppableId="more">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <div>
                                    <h3 className="group-title">更多标签</h3>
                                    <div className="group-content last">
                                        {moreTabList.map((item: any, index: number) => {
                                            return (
                                                <Draggable
                                                    key={item.pin}
                                                    draggableId={item.pin}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style,
                                                                isPointer,
                                                                item.recommended
                                                            )}
                                                            className="circular"
                                                        >
                                                            <ToolsItem
                                                                isDragAble={true}
                                                                toolsItem={item}
                                                                isMine={false}
                                                                provided={provided}
                                                                droppableId="more"
                                                                handleToolsItemClick={
                                                                    handleToolsItemClick
                                                                }
                                                                index={index}
                                                                isDragging={snapshot.isDragging}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* 解决组件react-beautiful-dnd滚动底部出现抖动问题 */}
                                {isDragging && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '79px',
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </Modal>
    );
}

function mapStateToProps({ chat }: any) {
    const { selectedSession, aiNoticeSessionList } = chat as ChatState;
    // if (isAINoticeSession(selectedSession)) {
    //     // const selectedPin = CHAT_NOTICE.ALL_PIN;
    //     // sessionMessages = filterNoticeMassagesByType(sessionMessages, selectedPin);
    //     // const selectedPin = selectedAITab.pin || CHAT_NOTICE.ALL_PIN;
    //     // sessionMessages = filterNoticeMassagesByType(sessionMessages, selectedPin);
    //     console.log(222);
    // }
    return {
        selectedSession,
        aiNoticeSessionList,
    };
}

export default connect(mapStateToProps)(TabControllerModal);
